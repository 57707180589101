import {
    DELETE_CAR_GALLERY_IMAGE_ERROR,
    DELETE_ENTRY_GALLERY_IMAGE_ERROR,
    GET_GALLERY_IMAGES_ERROR,
    SET_GALLERY_IMAGES_ERROR,
    UPDATE_CAR_GALLERY_IMAGE_ERROR,
    UPDATE_ENTRY_GALLERY_IMAGE_ERROR,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const getGalleryImagesError = createAction(
//     GET_GALLERY_IMAGES_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const getGalleryImagesError = createAction(
    GET_GALLERY_IMAGES_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const setGalleryImagesError = createAction(
//     SET_GALLERY_IMAGES_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const setGalleryImagesError = createAction(
    SET_GALLERY_IMAGES_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const updateCarGalleryImageError = createAction(
//     UPDATE_CAR_GALLERY_IMAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const updateCarGalleryImageError = createAction(
    UPDATE_CAR_GALLERY_IMAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const updateEntryGalleryImageError = createAction(
    UPDATE_ENTRY_GALLERY_IMAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const deleteCarGalleryImageError = createAction(
//     DELETE_CAR_GALLERY_IMAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const deleteCarGalleryImageError = createAction(
    DELETE_CAR_GALLERY_IMAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteEntryGalleryImageError = createAction(
    DELETE_ENTRY_GALLERY_IMAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
