import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import '../styles.css'

const formatsList = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    // 'indent',
    // 'link',
]

const modulesList = (id: string) => {
    return {
        toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                // { indent: '-1' },
                // { indent: '+1' },
            ],
            // ['link'],
            // [{ container: "none" }],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }
}

// const CustomToolbar = (id: any) => {
//   return <CutomToolBar id={id} />;
// };

type Props = {
    placeholder: string
    valuePassed: string
    handleChange: any
    idPassed: string
    readOnly: boolean
    height: string
}

class TaskNotesEditorMobile extends React.Component<Props, {}> {
    render() {
        const {
            placeholder,
            valuePassed,
            handleChange,
            idPassed,
            readOnly,
            height,
        } = this.props

        let idOfToolbar = readOnly
            ? 'toolbarHiddenMobileNotes'
            : 'toolbarVisibleMobileNotes'
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                }}
            >
                {/* <CustomToolbar id="" /> */}

                <ReactQuill
                    theme="snow"
                    onChange={handleChange}
                    value={valuePassed}
                    id={idPassed}
                    modules={modulesList(idOfToolbar)}
                    formats={formatsList}
                    bounds={'.app'}
                    placeholder={placeholder}
                    style={{
                        height: height,
                        fontFamily: 'Lato',
                        width: '100%',
                    }}
                    // readOnly={readOnly}
                />
            </div>
        )
    }
}

export default TaskNotesEditorMobile
