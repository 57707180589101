// create store for todos (basically reducer for the main final store)

import { IUsersState, IUser_sharesReceived } from 'myModels'

import * as actions from './actions/actions'

import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

export const usersReducer = (
    state: IUsersState = {
        error: null,
        loading: false,
        userLoggedIn: null,
        hasLoggedOut: false,
        isAuthLoading: false,
        tokenCheck: {
            isTokenReqInProcess: false,
            hasSessionRefreshedTokenOnce: false,
            retriesCount: 0,
        },
        sharesOwnedLoading: false,
        receivedSharesLoading: false,
    },
    action: LoadingActions | Action | ErrorActions
): IUsersState => {
    switch (action.type) {
        case loadingActions.registerRequest.type:
            return Object.assign({}, state, {
                ...state,
                // loading: true,
                isAuthLoading: true,
                error: null,
            })

        case loadingActions.socialAuthRequest.type:
            return Object.assign({}, state, {
                ...state,
                // loading: true,
                isAuthLoading: true,
                error: null,
            })

        case actions.registerSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                isAuthLoading: false,
                error: null,
            })

        case errorActions.registerError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                isAuthLoading: false,
                error: action.payload,
            })

        case loadingActions.signInUserRequest.type:
            return Object.assign({}, state, {
                ...state,
                // loading: true,
                isAuthLoading: true,
                error: null,
            })
        case actions.signInUserSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                isAuthLoading: false,
                error: null,
            })
        case errorActions.signInUserError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                isAuthLoading: false,
                error: action.payload,
            })
        case loadingActions.logoutRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case actions.logoutUserSuccess.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: null,
                hasLoggedOut: true,
                loading: false,
                error: null,
            })
        case errorActions.logoutUserError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case actions.addPhoneSuccess.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    phone: action.payload,
                },
                loading: false,
                error: null,
            })

        case loadingActions.addPhoneRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case errorActions.addPhoneError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case actions.addCategorySuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case loadingActions.addCategoriesRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case errorActions.addAcategoriesError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case actions.addAddressSuccess.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    address: action.payload,
                },
                loading: false,
                error: null,
            })
        case loadingActions.addAddressRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case errorActions.addAddressError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case errorActions.addAddressErrorNoModal.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })
        case actions.verifySuccess.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    verified: true,
                },
                loading: false,
                error: null,
            })

        case loadingActions.verifyRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case errorActions.verifyError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.sendInvitationRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case loadingActions.resetPasswordRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case actions.sendInvitationSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.sendInvitationError.type:
        case errorActions.resetPasswordError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case actions.resetPasswordSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })
        case loadingActions.verifiedResetPasswordRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case errorActions.verifiedResetPasswordError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case actions.verifiedResetPasswordSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case loadingActions.getCurrentUserDataRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case actions.getCurrentUserDataSuccess.type:
            let usr = state.userLoggedIn ? state.userLoggedIn : {}
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                userLoggedIn: {
                    ...usr,
                    ...action.payload,
                },
            })
        case errorActions.getCurrentUserDataError.type:
            return Object.assign({}, state, {
                userLoggedIn: null,
                loading: false,
                error: action.payload,
            })

        case loadingActions.updateUserGeneralInfoRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case actions.updateUserGeneralInfoSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                userLoggedIn: action.payload,
            })
        case errorActions.updateUserGeneralInfoError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case errorActions.resetErrorStateSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case actions.verifyResetPasswordCodeSuccess.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: { ...state.userLoggedIn, id: action.payload },
                loading: false,
                error: null,
            })
        case errorActions.verifyResetPasswordCodeError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case loadingActions.verifyResetPasswordCodeRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case loadingActions.refreshTokenRequest.type:
            return Object.assign({}, state, {
                ...state,
                tokenCheck: {
                    ...state.tokenCheck,
                    isTokenReqInProcess: action.payload,
                },
                loading: true,
                error: null,
            })

        case actions.refreshTokenSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tokenCheck: {
                    ...state.tokenCheck,
                    isTokenReqInProcess: action.payload,
                },
                loading: false,
                error: null,
            })
        case errorActions.refreshTokenError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.refreshTokenOnceRequest.type:
            return Object.assign({}, state, {
                ...state,
                tokenCheck: {
                    ...state.tokenCheck,
                    hasSessionRefreshedTokenOnce: action.payload,
                },
                loading: true,
                error: null,
            })

        case actions.refreshTokenOnceSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tokenCheck: {
                    ...state.tokenCheck,
                    hasSessionRefreshedTokenOnce: action.payload,
                },
                loading: false,
                error: null,
            })

        case errorActions.refreshTokenOnceError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.addToRefreshTokenRetriesCount.type:
            return Object.assign({}, state, {
                ...state,
                tokenCheck: {
                    ...state.tokenCheck,
                    retriesCount: state.tokenCheck.retriesCount + 1,
                },
                loading: false,
                error: null,
            })

        case actions.resetRefreshTokenRetriesCount.type:
            return Object.assign({}, state, {
                ...state,
                tokenCheck: {
                    ...state.tokenCheck,
                    retriesCount: 0,
                },
                loading: false,
                error: null,
            })

        case actions.addLabelsToUserSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    labels:
                        state.userLoggedIn && state.userLoggedIn.labels
                            ? [...state.userLoggedIn?.labels, ...action.payload]
                            : [...action.payload],
                },
            })

        // SHARING
        case actions.getUserDirectSharesReceivedSuccess.type:
            let data: IUser_sharesReceived =
                state.userLoggedIn && state.userLoggedIn.received_direct_shares
                    ? state.userLoggedIn.received_direct_shares
                    : {}
            if (
                action.payload.resource_type === 'car_and_garage' &&
                action.payload.cars &&
                action.payload.garages
            ) {
                data = {
                    ...data,
                    cars: {
                        shares_received_ids: [
                            ...action.payload.cars.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.cars.accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.cars.pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.cars.ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.cars.shares_received_data,
                        },
                    },
                    garages: {
                        shares_received_ids: [
                            ...action.payload.garages.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.garages
                                .accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.garages
                                .pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.garages
                                .ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.garages.shares_received_data,
                        },
                    },
                }
            } else if (
                action.payload.resource_type === 'car' &&
                action.payload.cars
            ) {
                data = {
                    ...data,
                    cars: {
                        ...data,
                        shares_received_ids: [
                            ...action.payload.cars.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.cars.accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.cars.pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.cars.ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.cars.shares_received_data,
                        },
                    },
                }
            } else if (
                action.payload.resource_type === 'garage' &&
                action.payload.garages
            ) {
                data = {
                    ...data,
                    garages: {
                        shares_received_ids: [
                            ...action.payload.garages.shares_received_ids,
                        ],
                        accepted_shares_received_ids: [
                            ...action.payload.garages
                                .accepted_shares_received_ids,
                        ],
                        pending_shares_received_ids: [
                            ...action.payload.garages
                                .pending_shares_received_ids,
                        ],
                        ignored_shares_received_ids: [
                            ...action.payload.garages
                                .ignored_shares_received_ids,
                        ],
                        shares_received_data: {
                            ...action.payload.garages.shares_received_data,
                        },
                    },
                }
            }

            return Object.assign({}, state, {
                ...state,
                receivedSharesLoading: false,
                error: null,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    received_direct_shares: {
                        ...data,
                    },
                },
            })

        case loadingActions.getUserDirectSharesReceivedRequest.type:
            return Object.assign({}, state, {
                ...state,
                receivedSharesLoading: true,
                error: null,
            })

        case errorActions.getUserDirectSharesReceivedError.type:
            return Object.assign({}, state, {
                ...state,
                receivedSharesLoading: false,
                error: action.payload,
            })

        case loadingActions.populateOwnedSharesDataRequest.type:
            return Object.assign({}, state, {
                ...state,
                sharesOwnedLoading: true,
            })

        case errorActions.populateOwnedSharesDataError.type:
            return Object.assign({}, state, {
                ...state,
                sharesOwnedLoading: false,
                error: action.payload,
            })

        case actions.populateOwnedSharesDataPopulationSuccess.type:
            return Object.assign({}, state, {
                ...state,
                sharesOwnedLoading: false,
            })

        case actions.updateShareReceivedStatusSuccess.type:
            if (action.payload.entity_type === 'garage') {
                return Object.assign({}, state, {
                    ...state,
                    loading: false,
                    error: null,
                    userLoggedIn: {
                        ...state.userLoggedIn,
                        received_direct_shares: {
                            ...state.userLoggedIn?.received_direct_shares,
                            garages: action.payload.shares_received,
                        },
                    },
                })
            } else {
                return Object.assign({}, state, {
                    ...state,
                    loading: false,
                    error: null,
                    userLoggedIn: {
                        ...state.userLoggedIn,
                        received_direct_shares: {
                            ...state.userLoggedIn?.received_direct_shares,
                            cars: action.payload.shares_received,
                        },
                    },
                })
            }

        case loadingActions.updateShareReceivedStatusRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.updateShareReceivedStatusError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.updateUserTimeZone.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    preferences: {
                        ...state.userLoggedIn?.preferences,
                        data: {
                            ...state.userLoggedIn?.preferences.data,
                            notifications: {
                                ...state.userLoggedIn?.preferences.data
                                    .notifications,
                                data: {
                                    ...state.userLoggedIn?.preferences.data
                                        .notifications.data,
                                    time_zone: {
                                        ...state.userLoggedIn?.preferences.data
                                            .notifications.data.time_zone,
                                        ids_list: action.payload.idsList,
                                        data: action.payload.data,
                                    },
                                },
                            },
                        },
                    },
                },
            })

        case actions.setUserArchivedCarsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    archived_cars: {
                        car_ids: [...action.payload.car_ids],
                        active_sort: action.payload.activeSort,
                    },
                },
            })

        case loadingActions.getUserArchivedCarsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.getUserArchivedCarsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.updateArchivedCars.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    archived_cars: {
                        ...state.userLoggedIn?.archived_cars,
                        car_ids: action.payload,
                    },
                },
            })

        case actions.updateUserTasksCountOnTasksCreation.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    total_tasks_count: state.userLoggedIn?.total_tasks_count
                        ? state.userLoggedIn?.total_tasks_count + 1
                        : 1,
                },
            })
        case actions.updateUserTasksCountOnTasksDeletion.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: {
                    ...state.userLoggedIn,
                    total_tasks_count:
                        state.userLoggedIn?.total_tasks_count &&
                        state.userLoggedIn?.total_tasks_count > 0
                            ? state.userLoggedIn?.total_tasks_count - 1
                            : 0,
                },
            })

        case loadingActions.listUserHandoversRequest.type:
            return Object.assign({}, state, {
                ...state,
                // we dont want to bloat the page with loading = true, when  its there is there
            })

        case errorActions.listUserHandoversError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
                loading: false,
            })

        case actions.listUserHandoversSuccess.type:
            return Object.assign({}, state, {
                ...state,
                userLoggedIn: state.userLoggedIn
                    ? {
                          ...state.userLoggedIn,
                          handovers: {
                              ...action.payload,
                          },
                      }
                    : null,
            })

        default:
            return state
    }
}

export default usersReducer

export type UsersState = ReturnType<typeof usersReducer>
