import { ITaskRelationsPayload } from 'entityModels'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
// base url
const base_url = process.env.REACT_APP_BASE_URL

export const unlinkTask = (
    relationTaskData: ITaskRelationsPayload
): string | Promise<any> => {
    let urlBuilt = urls.unlink_task_to_cars(relationTaskData)

    if (
        urlBuilt ===
        `${base_url}/tasks/${relationTaskData.taskid}/actions/unlink?`
    ) {
        return 'ERROR: incorrect endpoint built'
    }

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urlBuilt, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                apiSentryErrorHandler(e, 'Unlink task error')
                return Promise.reject(e)
            })
    })
}

export const linkTask = (
    relationTaskData: ITaskRelationsPayload
): string | Promise<any> => {
    let urlBuilt = urls.link_task_to_cars(relationTaskData)

    if (
        urlBuilt ===
        `${base_url}/tasks/${relationTaskData.taskid}/actions/link?`
    ) {
        return 'ERROR: incorrect endpoint built'
    }

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urlBuilt, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                apiSentryErrorHandler(e, 'Link task error')
                return Promise.reject(e)
            })
    })
}
