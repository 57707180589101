import { put, take, fork } from 'redux-saga/effects'
import { menuActions } from '../reducer'

function* watcherSetGallerySlideshow() {
    while (true) {
        const { payload } = yield take(menuActions.setGallerySlideshowRequest)

        yield put(menuActions.setGallerySlideshowSuccess(payload))
    }
}

const modals_sagas: any[] = [fork(watcherSetGallerySlideshow)]

export default modals_sagas
