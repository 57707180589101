import { removeUndefinedfromObject } from '../../helpers/objects'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export type ICreateHandoverAPI_req_data = {
    selection_option: number
    new_owner_email?: string
    new_owner_first_name?: string
    new_owner_last_name?: string
    new_owner_id?: string
}

export type ICreateHandoverAPI_req = {
    carID: string
    data: ICreateHandoverAPI_req_data
    isResend?: boolean
}

export const createHandover = (p: ICreateHandoverAPI_req): Promise<any> => {
    // run removeUndefined func here (move from down)
    let dataCleaned = removeUndefinedfromObject(p.data)
    dataCleaned.resource_type = 'CAR'

    let dataFinal: string = JSON.stringify(dataCleaned)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: dataFinal,
        credentials: 'include',
    }

    return fetch(urls.create_handover_endpoint(p.carID), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create car handover error',
                            dataFinal
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
