type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const TutorialsIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.2618 6.05532L8.00002 9.89384L0.738281 6.05532L8.00002 2.2168L15.2618 6.05532ZM8.00002 3.91346L3.94805 6.05532L8.00002 8.19717L12.052 6.05532L8.00002 3.91346Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.9927 6.89855L12.9927 11.0931L8.00002 13.7322L3.00738 11.0931L3.00738 6.89855H4.50738L4.50738 10.1893L8.00002 12.0355L11.4927 10.1893V6.89855H12.9927Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.75 9.39844V6.04267H15.25V9.39844H13.75Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default TutorialsIcon
