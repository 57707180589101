import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import AddBox from '../../../atoms/Button/addBox'
import InsuranceAddressCard from '../../../molecules/cards/insurance/addressCardDesktop'
import FadedSlower from '../../../templates/animated/FadedSlower'
import InsuranceGoBackReusableBottomBar from '../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'

import { AddressWindow } from './addressWindow'
import { device } from '../../../templates/displays/devices'
import { insuranceActions } from '../../../../redux/insuranceQuoteApplication/reducer'
import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'

const GridWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 50px;
    padding-right: 50px;
    flex-wrap: wrap;
    @media ${device.mobile_and_ipad} {
        padding-left: 16px;
        padding-right: 16px;
    }
`

const AddBoxContainer = styled.div`
    min-width: 443px;
    max-width: 443px;

    height: 165px;
    width: 50%;

    @media (min-width: 910px) and (max-width: 1200px) {
        min-width: 400px;
        max-width: 400px;
    }

    @media (min-width: 800px) and (max-width: 910px) {
        min-width: 350px;
        max-width: 350px;
    }

    @media ${device.mobile_and_ipad} {
        min-width: unset;
        max-width: unset;
        width: 100%;
    }
`

type Props = {
    userid?: string
    goBack: () => any
    onSelect?: (address: IInsuranceAddress_API) => any
    isMobile?: boolean
}

export type IAddressActiveModal =
    | {
          title?: string
          active_modal: 'edit' | 'create' | 'select'
          prevOpenedModal?: 'select'
          field_id?: string // day_park_address_id
      }
    | undefined

const InsuranceQuoteAddressesManager = (props: Props) => {
    const addresses = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.addresses
    })

    const [activeModal, toggleActiveModal] =
        useState<IAddressActiveModal>(undefined)

    const [updatedAddress, setUpdatedAddress] = useState<
        IInsuranceAddress_API | undefined
    >(undefined)

    let emptyAddress: IInsuranceAddress_API = {
        country: 'United Kingdom',
        street_1: undefined,
        street_2: undefined,
        locality: undefined,
        postcode: undefined,
    }

    let list = Object.keys(addresses).map((key: string) => {
        return addresses[key]
    })

    const dispatch = useAppDispatch()

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <FadedSlower>
                <GridWrapper>
                    <AddBoxContainer>
                        <AddBox
                            onAddClick={() => {
                                toggleActiveModal({
                                    active_modal: 'create',
                                    title: 'Add new address',
                                })
                                setUpdatedAddress(emptyAddress)
                            }}
                            borderRadius="8px"
                        />
                    </AddBoxContainer>
                    {list &&
                        list.length > 0 &&
                        list.map((item: IInsuranceAddress_API) => {
                            return (
                                <React.Fragment key={item.uid}>
                                    <InsuranceAddressCard
                                        address={item}
                                        onEdit={() => {
                                            toggleActiveModal({
                                                active_modal: 'edit',
                                                title: 'Edit address',
                                            })
                                            setUpdatedAddress(item)
                                        }}
                                        onRemove={(uid: string) => {
                                            dispatch(
                                                insuranceActions.remove_addresses(
                                                    uid
                                                )
                                            )

                                            setTimeout(() => {
                                                dispatch(
                                                    insuranceActions.submit_insuranceAddresses_request()
                                                )
                                            }, 100)
                                        }}
                                        onSelect={props.onSelect}
                                    />
                                </React.Fragment>
                            )
                        })}
                </GridWrapper>
            </FadedSlower>

            <AddressWindow
                updateInitialAddressOnCreation={setUpdatedAddress}
                initialAddressOnCreation={updatedAddress}
                activeModal={activeModal}
                toggleActiveModal={toggleActiveModal}
                onSaveForm={() => {
                    dispatch(
                        insuranceActions.submit_insuranceAddresses_request()
                    )
                }}
            />

            <InsuranceGoBackReusableBottomBar
                saveBtnTxt="Continue"
                onSave={() => {
                    props.goBack()
                }}
                autoFormSaveCopy={props.isMobile ? false : true}
                goBack={() => props.goBack()}
                isMobile={props.isMobile}
            />
        </div>
    )
}

export default InsuranceQuoteAddressesManager
