import { IHandover } from 'entityModels'
import styled from 'styled-components'
import success_img from '../../../../public/assets/icons/success_img.png'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { device } from '../../../templates/displays/devices'

const SuccessImg = styled.img`
    height: 140px;
`
const Title = styled.div`
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #1a1a1a;
    max-width: 400px;
    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 36px;
    }
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 5e-5em;
    color: #666666;
    max-width: 250px;

    @media ${device.mobile_and_ipad} {
        line-height: 30px;
    }
`

type Props = {
    onView: () => any
    handover: IHandover
}

const HandoverRequestResendSuccessModal = (props: Props) => {
    let { handover } = props
    let getName = () => {
        if (handover.new_owner_invite) {
            if (
                handover.new_owner_invite.recipient_given_name ||
                handover.new_owner_invite.recipient_family_name
            ) {
                return `${handover.new_owner_invite.recipient_given_name} ${handover.new_owner_invite.recipient_family_name}`
            } else return handover.new_owner_invite.recipient_email
        } else return handover.new_owner?.display_name
    }
    return (
        <>
            <SuccessImg src={success_img} alt="success-logo" />
            <div style={{ paddingTop: '50px' }} />
            <Title>Handover Resent</Title>
            <div style={{ paddingTop: '20px' }} />
            <Subtitle>
                Your Handover request was resent to {getName()}.
            </Subtitle>
            <div style={{ paddingTop: '50px' }} />
            <ButtonAtom
                theme="secondary"
                width="100%"
                height="48px"
                textTransform="capitalize"
                dataCyId={'showroom-car-enquire'}
                onClick={() => {
                    props.onView()
                }}
                fontSize={16}
            >
                Continue
            </ButtonAtom>
        </>
    )
}

export default HandoverRequestResendSuccessModal
