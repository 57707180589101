import { IDropdownItem } from 'entityModels'

export type IReminderData = {
    id: string
    optionsIDsList: string[]
}

export type IdropdownObject = {
    [key: string]: IDropdownItem
}

export const OReminderAllSelectTypes = {
    on_due_day: 'on_due_day',
    tomorrow: 'tomorrow',
    days_before: 'days_before',
    weeks_before: 'weeks_before',
    months_before: 'months_before',
    custom_date_copy: 'custom_date_copy',
} as const

export type ReminderAllSelectTypes =
    (typeof OReminderAllSelectTypes)[keyof typeof OReminderAllSelectTypes]

export const OReminderFormFieldTypes = {
    date_option_select: 'date_option_select',
    date_number_and_select: 'date_number_and_select',
    date_picker: 'date_picker',
} as const

export type ReminderFormFieldTypes =
    (typeof OReminderFormFieldTypes)[keyof typeof OReminderFormFieldTypes]

export type IReminder_FieldsData = {
    [key in ReminderFormFieldTypes]: IReminderData
}

export const reminder_All_optionsIDsList: ReminderAllSelectTypes[] = [
    'on_due_day',
    'tomorrow',
    'days_before',
    'weeks_before',
    'months_before',
    'custom_date_copy',
]

export const reminder_optionsData: IdropdownObject = {
    custom_date_copy: {
        uid: 'custom_date_copy',
        name: 'dd/mm/yyyy (Custom)',
    },
    months_before: {
        uid: 'months_before',
        name: 'Month(s) before',
    },
    weeks_before: {
        uid: 'weeks_before',
        name: 'Week(s) before',
    },
    days_before: {
        uid: 'days_before',
        name: 'Day(s) before',
    },
    on_due_day: {
        uid: 'on_due_day',
        name: 'On due day',
    },
    tomorrow: {
        uid: 'tomorrow',
        name: 'Tomorrow',
    },
}

export const remindersData: IReminder_FieldsData = {
    date_picker: {
        id: 'date_picker',
        optionsIDsList: ['custom_date_copy'],
    },
    date_option_select: {
        id: 'date_option_select',
        optionsIDsList: ['on_due_day', 'tomorrow'],
    },
    date_number_and_select: {
        id: 'date_number_and_select',
        optionsIDsList: ['days_before', 'weeks_before', 'months_before'],
    },
}
