import { IHighlightedFactsObject } from 'entityModels'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../../redux/store/hooks'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import placeholder from '../../atoms/skeleton/keyMomentsFacts/skeleton.jpg'
import placeholder_dark from '../../atoms/skeleton/keyMomentsFacts/skeleton_dark.png'
import useThemes from '../../../providers/theme/hooks'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    order: 1;
    align-self: stretch;
`

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 16px; */

    width: 100%;

    order: 1;
    align-self: stretch;
    position: relative;
`
const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    flex: 1;
    width: 100%;
`

const Number = styled.div`
    color: var(--primary, #5ec3ca);
    letter-spacing: 5e-5em;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    padding-right: 18px;
`

const ListContent = styled.span`
    width: 100%;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;

    color: var(--text-default, #666666);
`

const EmptyBtn = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

type EnumerableObject = {
    id: string
    text: string
    order: number
}

interface Props {
    highlighted_facts_ids: string[]
    onClick?: any
    readOnlyMode?: boolean
    isEmpty?: boolean
}

const sortList = (list: EnumerableObject[]) => {
    return list.sort(
        (a: EnumerableObject, b: EnumerableObject) => a.order - b.order
    )
}

const CarKeyMomentsFactsMobile = (props: Props) => {
    let highlighted_facts_data: IHighlightedFactsObject = useAppSelector(
        (state) => {
            return state.entities.highlightedFactsData.highlighted_facts
        }
    )
    // let isLoading: boolean | undefined = useAppSelector((state) => {
    //     return state.entities.highlightedFactsData.loading
    // })

    const [nProvided, setNProvided] = useState<number>(0)

    const [items, setItems] = useState<EnumerableObject[]>([])

    let { highlighted_facts_ids } = props
    useEffect(() => {
        if (highlighted_facts_ids) {
            let nProvidedLoc: number = 0

            for (const id of highlighted_facts_ids) {
                if (
                    highlighted_facts_data[id].text &&
                    highlighted_facts_data[id].text.length > 0
                ) {
                    nProvidedLoc = nProvidedLoc + 1
                }
            }

            setNProvided(nProvidedLoc)
        }

        let list = highlighted_facts_ids.map((id: string) => {
            return highlighted_facts_data[id]
        })

        let ordered_list = sortList(list)

        setItems(ordered_list)
    }, [highlighted_facts_data, highlighted_facts_ids])

    const { theme } = useThemes()

    return (
        <Container>
            <ListContainer>
                {props.isEmpty && !props.readOnlyMode ? (
                    <>
                        <img
                            style={{
                                width: '100%',
                                height: '100%',
                            }}
                            src={
                                theme === 'light'
                                    ? placeholder
                                    : placeholder_dark
                            }
                            alt="placeholder"
                        />

                        <EmptyBtn>
                            <ButtonAtom
                                theme={'lowercase-blue-background'}
                                fontSize={14}
                                onClick={() => props.onClick()}
                            >
                                <div
                                    style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Write up to 5 key facts about your car
                                </div>
                            </ButtonAtom>
                        </EmptyBtn>
                    </>
                ) : null}
                {!props.isEmpty &&
                    items &&
                    items.map((keyMoment: EnumerableObject, index: number) => {
                        if (index < nProvided)
                            return (
                                <ListItem key={`kmf_card_${index}_mobile`}>
                                    <Number>0{index + 1}</Number>
                                    <ListContent>{keyMoment.text}</ListContent>
                                </ListItem>
                            )
                        else return null
                    })}
            </ListContainer>
        </Container>
    )
}

export default CarKeyMomentsFactsMobile
