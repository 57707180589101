import { IExternalCar, IExternalCarsObject } from 'entityModels'
import { IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ITimelineDataByCarNormalised } from 'timelineModels'
import { RootState } from 'typesafe-actions'
import { getExternalCarsSharedWithYouREQUEST } from '../../../../redux/entities/cars/actions/loadingActions'
import { setCurrentExternalCar } from '../../../../redux/entities/cars/actions/thunks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import ReadOnlyCarHistoryFile from '../../../templates/readOnly/car/readOnlyHistoryFile'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    externalCarsData: IExternalCarsObject | undefined
    timelineData: ITimelineDataByCarNormalised | null | undefined
    setCurrentExternalCar: (carid: string) => void
    getExternalCarsSharedWithYouREQUEST: (car_id: string) => void
    setInitialLandingUrl: (str: string) => void
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentExternalCar: (car_id: string) => setCurrentExternalCar(car_id),
    getExternalCarsSharedWithYouREQUEST: (car_id: string) =>
        getExternalCarsSharedWithYouREQUEST(car_id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

type State = {
    placeholderUrl: string
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
        timelineData: state.timeline.timeline_data_normalised_by_car_id,
    }
}

class ExternalCarHistoryFile extends React.Component<Props, State> {
    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        let carid = this.props.match.params.carid
        this.props.setInitialLandingUrl(`/shared/car/${carid}/history-file`)

        this.props.setCurrentExternalCar(carid)

        this.props.getExternalCarsSharedWithYouREQUEST(carid)
    }
    render() {
        let carid: string = this.props.match.params.carid
        let currentCar: IExternalCar | undefined =
            this.props.externalCarsData && this.props.externalCarsData[carid]

        return (
            <React.Fragment>
                <ReadOnlyCarHistoryFile
                    userLoggedIn={this.props.userLoggedIn}
                    sharedCar={currentCar}
                />
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ExternalCarHistoryFile)
)
