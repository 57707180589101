import * as React from 'react'

import { connect } from 'react-redux'
import {
    ICostItem,
    ICreationTimelineSubmitConfigs,
    ITimelineActionType,
    ITimelineCategoriesObj,
    ITimelineCreationFormFieldsData,
    ITimelineCreationOrEditFormFieldItem,
    ITimelineDataToBeSubmittedObj,
    ITimelineEntityType,
    ITimelineFieldsIdsList,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'

import { RootState } from 'typesafe-actions'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'
import CreateFormTitle from '../../../atoms/typography/createFormTitle'
import CreateFormSubtitle from '../../../atoms/typography/createFormSubtitle'

import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'

import { editFormsActions } from '../../../../redux/editForms/reducer'
import { ITimelineCreateForms } from 'myModels'

import dayjs from 'dayjs'
import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'
import WindowPageTemplate from '../../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CreationTopHeaderDesktop from '../../../molecules/entryPageTopHeader/creationTopHeaderDesktop'
import IconAndTextNakedButton from '../../../atoms/Button/iconsAndTextNakedButton'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CreateFormSubSubtitleDetail from '../../../atoms/typography/createFormSubtitleDetail'
import styled from 'styled-components'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'

const { v4: uuidv4 } = require('uuid')

var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const Wrapper = styled.div`
    width: 100%;
    padding-right: 30px;
`

const SubWrapper = styled.div`
    width: 100%;
    max-width: 1400px;
`

const ButtonsSubWrapper = styled.div`
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-between;
`

const LargePadding = styled.div`
    padding-top: 40px;
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const TitlesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const ButtonsSection = styled.div`
    width: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
    border-top: 1px solid var(--border_muted);
`

const dispatchProps = {
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
}

function mapStateToProps(state: RootState) {
    return {
        fieldsList: state.timeline.formsData.fieldsList,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        steps: state.timeline.formsData.creation
            .timeline_creation_steps_object_of_indexed_list,
        steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        entryTypes_data: state.timeline.categories,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

interface Props extends RouteComponentProps<any> {
    data_to_be_submitted: ITimelineDataToBeSubmittedObj | undefined
    current_step_index: number
    step_list: string[]
    fieldsList: ITimelineFieldsIdsList
    stepFieldsData: { [key: string]: ITimelineCreationOrEditFormFieldItem }
    stepFieldIdsList: string[]
    stepTitle: string
    stepSubtitleDetail?: string
    stepSubtitle: string
    submit: ICreationTimelineSubmitConfigs
    onChange: (
        fieldID: string,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => void
    closeForm: () => void
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => void
    manageSteps: () => void
    steps: ITimelineStepsIndexedListByCreationType
    steps_data: ITimelineCreationFormFieldsData
    submitDataRequest?: () => void
    goBackOneStep?: () => void
    showStepTitleAndSubtitle?: boolean
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined
    entryTypes_data: ITimelineCategoriesObj
    userCurrency: string | undefined
    isCollapsed: boolean
}

export class AnyObj {
    [key: string]: any
}

type State = {
    isHeaderActive: boolean
    registeredSteps: any
    errorObject: AnyObj
}

class TimelineEditGenerateCreateFormDesktop extends React.Component<
    Props,
    State
> {
    state = {
        isHeaderActive: false,
        registeredSteps: {},
        // we create an obj with fieldIDs as key and whether rn there's an error ( true | false)
        errorObject: new AnyObj(),
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps: Props) {}

    determineErrors = (fieldId: string, error: boolean) => {
        const errorObject: any = this.state.errorObject
        errorObject[fieldId] = error
        this.setState({ ...this.state, errorObject: errorObject })
    }

    checkIfSavedIsDisabled = () => {
        for (const id of this.props.stepFieldIdsList) {
            let str = id as keyof AnyObj

            let form_data =
                this.props.data_to_be_submitted &&
                this.props.data_to_be_submitted[this.props.entityType] &&
                this.props.data_to_be_submitted[this.props.entityType]![id]

            if (this.state.errorObject[str] === true) {
                // error is true
                return true
            }

            if (
                form_data &&
                form_data.length === 0 &&
                this.state.errorObject[str] === undefined &&
                this.props.stepFieldsData[id].optional === false
            ) {
                // mandatory pb
                return true
            }

            if (
                !form_data &&
                this.props.stepFieldsData[id].optional === false
            ) {
                // mandatory pb
                return true
            }
        }
        // should be nope
        return false
    }

    generateFields = (fieldId: string, key: any) => {
        let { onChange, entryTypes_data, submit } = this.props

        let active_creation =
            this.props.timeline_creation_forms_current_state.active_creation
        let active_creation_steps =
            active_creation && this.props.steps[active_creation]

        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start: this.determineValueStart(fieldId),
            title:
                this.props.stepFieldsData[fieldId] &&
                this.props.stepFieldsData[fieldId].text,
            onChangeFunc: onChange,
            id: fieldId,
            unique_identifier: 'default',
            isCreation: true,
            submitCreationConfigs: {
                entityType: submit.entity,
                action: submit.action,
            },
            placeholder:
                active_creation === 'insurance'
                    ? undefined
                    : this.generatePlaceholder(fieldId),
            optionTypeID:
                fieldId === 'categoryID'
                    ? 'entryType'
                    : fieldId === 'amount'
                    ? 'currency'
                    : undefined,
        }

        let hasError = (error: boolean) => {
            this.determineErrors(fieldId, error)
        }

        switch (fieldId) {
            case 'categoryID': {
                data.isMandatory = true
                data.charLimit = [2, 70]

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    if (value === 'insurance') {
                        this.props.history.replace(
                            `/car/${this.props.carID}/history-file/create/insurance`
                        )
                    } else {
                        onChange(fieldID, value, entityType, action)
                        this.props.manageSteps()
                    }
                }

                if (data.optionTypeID) {
                    switch (data.optionTypeID) {
                        case 'entryType':
                            return (
                                <React.Fragment key={key}>
                                    <div
                                        style={{ paddingTop: '20px' }}
                                        key={key}
                                    />
                                    <FieldChoices.OptionsWithIconAndDescriptionDesktop
                                        data={data}
                                        dataCyId={
                                            active_creation_steps[
                                                this.props.current_step_index
                                            ]
                                        }
                                        onOptionClick={() => {}}
                                        selectedOption={
                                            this.props.data_to_be_submitted &&
                                            this.props.data_to_be_submitted
                                                .entry &&
                                            this.props.data_to_be_submitted
                                                .entry.categoryID
                                        }
                                        optionsData={entryTypes_data.data}
                                        optionsIDArr={entryTypes_data.ids}
                                    />
                                </React.Fragment>
                            )

                        default:
                            return <div style={{ display: 'none' }} key={key} />
                    }
                } else return <div style={{ display: 'none' }} key={key} />
            }

            case 'title': {
                data.isMandatory = true
                data.charLimit = [1, 30]
                data.unique_identifier = uuidv4()
                return this.props.stepFieldsData[fieldId].text === 'title' ? (
                    <div style={{ width: '100%' }}>
                        <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                            isentry
                            key={key}
                            data={data}
                            dataCyId={
                                active_creation_steps[
                                    this.props.current_step_index
                                ]
                            }
                            helperText={data.placeholder}
                            hasError={hasError}
                        />
                        <div style={{ paddingTop: '20px' }} />
                    </div>
                ) : (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        placeholder={data.placeholder}
                        hasError={hasError}
                    />
                )
            }

            case 'name': {
                data.isMandatory = true
                data.charLimit = [2, 30]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_broker': {
                data.isMandatory = false
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.isMandatory = true
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'company': {
                data.charLimit = [2, 70]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        hasError={hasError}
                    />
                )
            }

            case 'amount': {
                data.isMandatory = true
                data.optionName = this.props.userCurrency
                    ? this.props.userCurrency
                    : 'GBP'
                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                    />
                )
            }

            case 'date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                    />
                )

            case 'date_with_options':
                return (
                    <FieldChoices.DatePickerWithButtons
                        isDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                    />
                )

            case 'insurance_entry_policy_start_date':
                data.isMandatory = true

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    this.props.onChange(fieldID, value, entityType, action)
                    const aYearFromNow = new Date(value)
                    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)

                    this.props.onChange(
                        'insurance_entry_policy_end_date',
                        aYearFromNow,
                        entityType,
                        action
                    )
                }
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                    />
                )

            case 'insurance_entry_policy_end_date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        data={{ ...data }}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                    />
                )
            case 'short_description':
                return (
                    <FieldChoices.TextareaEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        hasError={hasError}
                    />
                )

            case 'attachment': {
                return (
                    <FieldChoices.WrapperAttachmentFormDesktop
                        data={data}
                        key={key}
                        dataCyId={
                            active_creation_steps[this.props.current_step_index]
                        }
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                    />
                )
            }

            default:
                return <div />
        }
    }

    determineValueStart = (fieldId: string) => {
        let { data_to_be_submitted, submit } = this.props

        if (data_to_be_submitted && submit.entity) {
            if (submit.entity && data_to_be_submitted[submit.entity]) {
                let obj: ITimelineItem | ICostItem = {
                    id: '',
                    ...data_to_be_submitted[submit.entity],
                }
                if (obj[fieldId]) {
                    return obj[fieldId]
                }
            }

            return this.props.stepFieldsData[fieldId].value
        }
        return this.props.stepFieldsData[fieldId].value
    }

    generatePlaceholder = (fieldId: string): string => {
        let { data_to_be_submitted, submit } = this.props

        if (data_to_be_submitted && submit.entity) {
            if (data_to_be_submitted[submit.entity]) {
                if (
                    data_to_be_submitted.entry &&
                    data_to_be_submitted.entry.categoryID &&
                    timeline_category_data[
                        data_to_be_submitted.entry.categoryID
                    ].placeholders
                ) {
                    let placeholders =
                        timeline_category_data[
                            data_to_be_submitted.entry.categoryID
                        ].placeholders

                    return placeholders ? placeholders[fieldId] : ''
                }
            }
            return ''
        }
        return ''
    }

    render() {
        let {
            step_list,
            current_step_index,
            stepFieldIdsList,
            stepTitle,
            stepSubtitle,
            stepSubtitleDetail,
            submit,
            manageSteps,
            submitDataRequest,
            closeForm,
            goBackOneStep,
            showStepTitleAndSubtitle,
            isCollapsed,
        } = this.props

        let is_category_specific =
            this.props.data_to_be_submitted?.entry?.categoryID === 'insurance'
                ? true
                : false

        let current_creation_step_id = step_list[current_step_index]

        return (
            <WindowPageTemplate
                data-attr={`wizard_step_${this.props.current_step_index}_desktop`}
                isCollapsed={isCollapsed}
                stickyHeaderContent={
                    <CreationTopHeaderDesktop
                        onClose={() => {
                            if (
                                this.props.entityType === 'cost' &&
                                this.props.activeEntry
                            ) {
                                this.props.history.push(
                                    `/car/${this.props.carID}/history-file/entry?entryid=${this.props.activeEntry.id}`
                                )
                            } else {
                                this.props.history.push(
                                    `/car/${this.props.carID}/history-file`
                                )
                            }
                            return closeForm()
                        }}
                        categoryID={
                            this.props.data_to_be_submitted?.entry?.categoryID
                        }
                        text={
                            is_category_specific
                                ? this.props.stepTitle
                                : this.props
                                      .timeline_creation_forms_current_state
                                      .active_creation === 'cost_desktop'
                                ? 'New Cost'
                                : this.props
                                      .timeline_creation_forms_current_state
                                      .active_creation ===
                                  'add_new_timeline_intem_desktop'
                                ? 'New Entry'
                                : this.props
                                      .timeline_creation_forms_current_state
                                      .active_creation === 'add_new_attachment'
                                ? 'New Attachment'
                                : ''
                        }
                    />
                }
            >
                <Wrapper>
                    <SubWrapper>
                        {showStepTitleAndSubtitle && (
                            <>
                                <TitlesContainer>
                                    <CreateFormTitle>
                                        {stepTitle}
                                    </CreateFormTitle>
                                    <CreateFormSubtitle>
                                        {stepSubtitle}
                                    </CreateFormSubtitle>
                                    {stepSubtitleDetail && (
                                        <CreateFormSubSubtitleDetail>
                                            {stepSubtitleDetail}
                                        </CreateFormSubSubtitleDetail>
                                    )}
                                </TitlesContainer>
                                <SmallPadding />
                            </>
                        )}

                        <div style={{ width: '100%' }}>
                            {stepFieldIdsList &&
                                stepFieldIdsList.map(
                                    (fieldID: string, index: number) => {
                                        return this.generateFields(
                                            fieldID,
                                            index
                                        )
                                    }
                                )}
                        </div>
                        <LargePadding />
                    </SubWrapper>

                    <ButtonsSection>
                        <ButtonsSubWrapper>
                            {!is_category_specific && (
                                <IconAndTextNakedButton
                                    icon="long_green_arrow"
                                    iconOnLeft
                                    text="Go back"
                                    onClick={goBackOneStep}
                                />
                            )}
                            {current_creation_step_id ===
                            'select_category' ? null : (
                                <StandardCtaBtn
                                    onClick={
                                        current_step_index <
                                        step_list.length - 1
                                            ? manageSteps
                                            : () => {
                                                  submitDataRequest &&
                                                      submitDataRequest()
                                                  manageSteps()
                                              }
                                    }
                                    isDisabled={this.checkIfSavedIsDisabled()}
                                    dataCyId={
                                        current_step_index <
                                        step_list.length - 1
                                            ? 'confirm_step_desktop'
                                            : submit.entity === 'cost'
                                            ? 'create_history_file_cost_desktop'
                                            : 'create_history_file_entry_desktop'
                                    }
                                >
                                    {submit.text}
                                </StandardCtaBtn>
                            )}
                        </ButtonsSubWrapper>
                    </ButtonsSection>
                </Wrapper>
            </WindowPageTemplate>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        dispatchProps
    )(TimelineEditGenerateCreateFormDesktop)
)
