import {
    isSafari,
    isMobileSafari,
    isIOS,
    isMobile,
    isTablet,
} from 'react-device-detect'

export function checkIfNotSafariOnIosDevice() {
    if ((isMobile || isTablet) && isIOS && !isSafari && !isMobileSafari) {
        return true
    } else {
        return false
    }
}
