import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions/index'
import { api } from '../../services'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IReduxError } from 'entityModels'
import posthog from 'posthog-js'

export function* verifiedResetPasswordSaga(p: {
    passwordGiven: string
    uid: string
}): any {
    try {
        yield call(api.authentication.verifiedResetPasswordApi, p)

        // Capture event
        posthog.capture('RESET PASSWORD VERIFICATION')

        yield put(actions.default.actions.verifiedResetPasswordSuccess())
        localStorage?.removeItem('refresh_token')
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(
            actions.default.errorActions.verifiedResetPasswordError(typedError)
        )
    }
}

function* watcherVerifiedResetPassword() {
    while (true) {
        const { payload } = yield take(
            actions.default.loadingActions.verifiedResetPasswordRequest
        )
        yield call(verifiedResetPasswordSaga, payload)
    }
}

const verifiedResetPassword: any[] = [fork(watcherVerifiedResetPassword)]

export default verifiedResetPassword
