const icon_1 = (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM14 8V12H18V8H14ZM14 14V24H18V14H14Z"
            fill="#333333"
        />
    </svg>
)

const icon_2 = (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C18.9745 2 21.7323 2.92763 24 4.50938"
            stroke="#F9BB4B"
            stroke-width="2"
        />
        <path
            d="M30 7L16.6667 21L10 14"
            stroke="#F9BB4B"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="square"
        />
    </svg>
)

const icon_3 = (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="14" stroke="#5EC3CA" stroke-width="2" />
        <path
            d="M20.9333 16.8L15.6 20.8C14.9408 21.2944 14 20.824 14 20V12C14 11.176 14.9408 10.7056 15.6 11.2L20.9333 15.2C21.4667 15.6 21.4667 16.4 20.9333 16.8Z"
            fill="#5EC3CA"
        />
    </svg>
)

const icon_4 = (
    <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="16" cy="16" r="14" stroke="#A5A8F2" stroke-width="2" />
        <path d="M16 8V16H23" stroke="#A5A8F2" stroke-width="2" />
    </svg>
)

const howItWorksIcons: { [key: string]: JSX.Element } = {
    '1': icon_1,
    '2': icon_2,
    '3': icon_3,
    '4': icon_4,
}

const InsuranceHowItWorksCardIcon = ({ item }: { item: number }) => {
    return howItWorksIcons[`${item}`]
}

export default InsuranceHowItWorksCardIcon
