import styled from 'styled-components'
const Wrapper = styled.div`
    padding: 5px;
    padding-left: 15px;
`
const DotElastic = styled.div`
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #5ec3caa4;
    color: #5ec3caa4;
    animation: dotElastic 1s infinite linear;

    :before,
    :after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    :before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #5ec3caa4;
        color: #5ec3caa4;
        animation: dotFlashing 1s infinite alternate;
    }

    :after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #5ec3caa4;
        color: #5ec3caa4;
        animation: dotFlashing 1s infinite alternate;
    }

    @keyframes dotFlashing {
        0% {
            background-color: #5ec3caa4;
        }
        50%,
        100% {
            background-color: #bff4f6a3;
        }
    }
`

const LoaderElasticThreeDots = (props: any) => (
    <Wrapper>
        <DotElastic />
    </Wrapper>
)

export default LoaderElasticThreeDots
