import styled from 'styled-components'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'

const Wrap = styled.div`
    height: 100dvh;
    width: 100dvw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--bg-color, #fff);
`
const Title = styled.div`
    font-size: 18px;
    line-height: 18px;
    font-family: 'Lato-semibold';
    color: var(--text-strong, #1a1a1a);
`
const Text = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato';
    color: var(--text-darker, #616161);
`

const ModalHeader = styled.div`
    display: flex;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
`
const ModalBody = styled.div`
    display: flex;
    flex: 1;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`

const ModalCtas = styled.div`
    display: flex;
    width: 100%;
    padding: 16px 24px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    background: var(--bg-color, #fff);
`

const BtnText = styled.p`
    padding: 10px 16px;
    margin: 0;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 14px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
    color: var(--primary, #5ec3ca);
`

type Props = {
    isOpen: boolean
    toggleModal: (p: boolean) => void
    draft_referral_code?: string
    saved_referral_code?: string
    onChange: (v: string) => void
    onSubmit: () => void
    isSaveDisabled?: boolean
}

const ReferralCodeModalMobile = (props: Props) => {
    let {
        isOpen,
        toggleModal,
        draft_referral_code,
        saved_referral_code,
        onChange,
        onSubmit,
        isSaveDisabled,
    } = props

    return (
        <SideSliderWithExit
            isVisible={isOpen}
            direction={'right'}
            initial="enter"
            customDuration={0.3}
            customZIndex={1000}
        >
            <Wrap>
                <ModalHeader onClick={() => toggleModal(false)}>
                    <div style={{ transform: 'rotate(90deg)' }}>
                        <ChevronDown />
                    </div>
                    <Title>Add referral code</Title>
                </ModalHeader>
                <ModalBody>
                    <Text>
                        If a friend or fellow car enthusiast already has
                        insurance with Custodian, they might have shared a
                        referral code with you.
                    </Text>
                    <InputFieldNoFormikAnimated
                        name={'Enter referral code'}
                        value={draft_referral_code ?? ''}
                        onChange={(e: any) => {
                            typeof e.target.value === 'string' &&
                                onChange(e.target.value.toUpperCase())
                        }}
                        placeholder=""
                        width={'100%'}
                        textTransform={'uppercase'}
                        placeholderStyle={`text-transform: none !important`}
                    />
                </ModalBody>
                <ModalCtas>
                    <StandardCtaBtn
                        onClick={() => {
                            saved_referral_code && onChange(saved_referral_code)
                            toggleModal(false)
                        }}
                        boder="1px solid var(--primary, #5ec3ca)"
                        bg={'transparent'}
                        customHeight="auto"
                        customWidth="auto"
                        removePaddings
                        removeShadowOnHover
                    >
                        <BtnText>Cancel</BtnText>
                    </StandardCtaBtn>
                    <StandardCtaBtn
                        onClick={onSubmit}
                        removePaddings
                        customHeight="auto"
                        customWidth="auto"
                        boder={
                            isSaveDisabled
                                ? '1px solid var(--border-muted, #e5e5e5)'
                                : '1px solid var(--primary, #5ec3ca)'
                        }
                        isDisabled={isSaveDisabled}
                    >
                        <BtnText style={{ color: 'var(--bg-color, #fff)' }}>
                            Apply code
                        </BtnText>
                    </StandardCtaBtn>
                </ModalCtas>
            </Wrap>
        </SideSliderWithExit>
    )
}

export default ReferralCodeModalMobile
