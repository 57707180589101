import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    onClick: any
    action?: 'add' | 'upload'
    height?: string
    width?: string
    backgroundColor?: string
    ipadHeight?: string
    placeholder?: string

    opacity?: number
}

type StyleProps = {
    $theme: ITheme
    $height?: string
    $width?: string
    $backgroundColor?: string
    $ipadHeight?: string
    $opacity?: number
}

const PlaceHolderStyle = styled.div<StyleProps>`
    cursor: pointer;
    width: ${(props) => (props.$width ? props.$width : '100vw')};
    height: ${(props) => (props.$height ? props.$height : '230px')};

    @media ${device.ipad} {
        height: ${(props) => (props.$ipadHeight ? props.$ipadHeight : '160px')};
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    color: ${(props) => colours[props.$theme].text_primary_muted};
    font-family: Lato;
    font-size: 14px;

    border-radius: 5px;

    background-color: ${(props) =>
        props.$backgroundColor
            ? props.$backgroundColor
            : colours[props.$theme].background_primary_soft};

    opacity: ${(props) => (props.$opacity ? props.$opacity : '1')};
`

const InfoText = styled.div`
    padding-left: 10px;
`

const CenterDiv = styled.div`
    display: none;

    @media ${device.desktop} {
        display: none;
    }

    @media ${device.mobile} {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        z-index: 0;
    }

    @media ${device.ipad} {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        z-index: 0;
    }
`

const AddInfoBannerMobile: React.FC<Props> = (props) => {
    {
        const {
            height,
            width,
            backgroundColor,
            onClick,
            ipadHeight,
            action,
            placeholder,
            opacity,
        } = props

        const { theme } = useThemes()

        return (
            <CenterDiv onClick={onClick && onClick}>
                <PlaceHolderStyle
                    $height={height && height}
                    $ipadHeight={ipadHeight && ipadHeight}
                    $width={width && width}
                    $backgroundColor={backgroundColor && backgroundColor}
                    $opacity={opacity && opacity}
                    $theme={theme}
                >
                    {action && (
                        <Icon
                            icon={
                                action === 'upload'
                                    ? 'upload_tuto_mobile'
                                    : 'add'
                            }
                        />
                    )}

                    {placeholder && <InfoText>{placeholder}</InfoText>}
                </PlaceHolderStyle>
            </CenterDiv>
        )
    }
}

export default AddInfoBannerMobile
