import * as React from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckbox from '../formField/customAnimatedCheckboxField'

export interface ICheckboxListItemProps {
    onChange?: any
    name?: any
    checked?: boolean
    onClick?: any
    isDisabled?: boolean
    text?: string
    notCapitalised?: boolean
    iconID?: 'email' | 'phone' | 'message' | string // more over time here
    borderColor?: string
    customActiveColour?: string
    customHighlightColour?: string
}

interface IStyledProps {
    checked?: boolean
    isDisabled?: boolean
    $notCapitalised?: boolean
    $customActiveColour?: string
}

const Wrapper = styled.div<IStyledProps>`
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Text = styled.div<IStyledProps>`
    font-family: 'Lato';
    font-size: 16px;
    text-transform: ${(props) =>
        props.$notCapitalised ? 'none' : 'capitalize'};
    color: ${(props) =>
        props.isDisabled
            ? 'var(--text-disabled)'
            : props.checked
            ? props.$customActiveColour ?? 'var(--primary, #5ec3ca)'
            : 'var(--text-strong, #1a1a1a)'};
`

class FilterBottomSheetItemMobile extends React.Component<
    ICheckboxListItemProps,
    {}
> {
    render() {
        const {
            onChange,
            name,
            checked,
            onClick,
            isDisabled,
            text,
            notCapitalised,
            customActiveColour,
        } = this.props
        return (
            <Wrapper>
                <Text
                    isDisabled={isDisabled}
                    checked={checked}
                    $notCapitalised={notCapitalised}
                    $customActiveColour={customActiveColour}
                >
                    {text}
                </Text>
                <CustomAnimatedCheckbox
                    onChange={onChange}
                    name={name}
                    checked={checked}
                    onClick={onClick}
                    isDisabled={isDisabled}
                    customActiveColour={customActiveColour}
                />
            </Wrapper>
        )
    }
}

export default FilterBottomSheetItemMobile
