import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import posthog from 'posthog-js'

import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'

import { showroomActions } from '../reducer'

import { IUpdateShowroomEntry_api_req } from '../types'
import { GetShowroomEntryByID } from './getShowroomEntry'
import { ValidateShowroomEntry } from './validateEntry'
import * as carsActions from '../../entities/cars/actions/actions'
import { message } from 'antd'
import { GetCarActionCheck } from '../../entities/cars/sagas/getCarActionCheck'

export type I_showroom_entry_update_req_payload = {
    uid: string
    car_id: string
    data: IUpdateShowroomEntry_api_req
}

export function* UpdateShowroomEntry(
    payload: I_showroom_entry_update_req_payload
): any {
    try {
        let res = yield call(api.showroom.updateShowroomEntry, {
            ...payload,
            data: {
                ...payload.data,
                open_to_offers:
                    payload.data.type_of_sale !== 'SET_PRICE'
                        ? undefined
                        : payload.data.open_to_offers
                        ? payload.data.open_to_offers
                        : false,
            },
        })
        yield call(GetShowroomEntryByID, {
            is_owner: true,
            entryID: payload.uid,
        })

        yield put(showroomActions.updateEntryShowroomSuccess())

        if (payload.data.state === 'SOLD') {
            yield call(GetCarActionCheck, { carid: payload.car_id })
        }

        // resetting nb of for sale results
        yield put(showroomActions.resetNumberOfForSaleResults())
        // resetting nb of sold results
        yield put(showroomActions.resetNumberOfSoldResults())

        if (payload.data.state) {
            yield put(
                carsActions.updateCarWithShowroomData({
                    carid: res.car.uid,
                    showroom_uid: res.uid,
                    type_of_sale: res.type_of_sale ? res.type_of_sale : '',
                    state: res.state,
                })
            )
        }

        if (payload.data.address) {
            message.info({ content: 'Address was saved!' })
        }

        // Capture event
        posthog.capture('UPDATE SHOWROOM ENTRY')

        yield call(ValidateShowroomEntry, payload.uid)

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: UpdateShowroomEntry,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else if (payload.data.address) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Invalid address`,
                custom_user_action_text: 'Try again',
                custom_redirect_path: `/showroom/${payload.uid}/edit`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.updateEntryShowroomError(customError))
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.updateEntryShowroomError(customError))
        }
    }
}

function* watcherUpdateShowroomEntry() {
    while (true) {
        const { payload } = yield take(
            showroomActions.updateEntryShowroomRequest
        )

        yield call(UpdateShowroomEntry, payload)
    }
}

const update_showroom_entry: any[] = [fork(watcherUpdateShowroomEntry)]

export default update_showroom_entry
