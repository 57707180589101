import * as Yup from 'yup'

export const FormikResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email not valid').required('Email is required'),
})

export const FormikVerifiedResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password needs to have at least 8 characters.'),
    passwordconfirm: Yup.string()
        .oneOf([Yup.ref('password'), null])
        .required("Passwords don't match"),
})
