import dayjs from 'dayjs'
import * as React from 'react'
import styled from 'styled-components'
import { ITimelineItem } from 'timelineModels'
import { numberWithCommas } from '../../../../helpers/numbers'
import colours, {
    history_file_colours,
} from '../../../../providers/theme/colours'

import useThemes from '../../../../providers/theme/hooks'
import { useAppSelector } from '../../../../redux/store/hooks'
import Icon from '../../../atoms/icons'
import { historyFileBaseEntry } from '../../../organisms/journey/historyFile/steps/add/entriesFormBody'
import {
    InsurerBox,
    InsurerIconWrapper,
} from '../../singleTimelineItemBody/singleTimelineItemBodyMobile'

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    min-width: 0px;
    background-color: var(--bg-color, #fff);
    box-shadow: 64px 64px 128px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 8px;
    font-family: Lato;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
`

const FlexRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
`

const ColItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`

type Props = {
    entry: ITimelineItem | undefined
    userCurrency: string | undefined
    onClick: () => any
    isReadonly?: boolean
}

const ItemTxt = styled.div`
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const NameTxt = styled.div`
    color: var(--text-muted, #b3b3b3);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const AnswerTxt = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`
const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 17px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
`

const TimelineInsuranceItemMobileJourneyCard: React.FC<Props> = (props) => {
    const { entry, isReadonly } = props

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    let placeholdertitle = `${
        userLoggedIn?.given_name && `${userLoggedIn?.given_name}'s`
    } Insurance policy`

    let item = entry ?? {
        ...historyFileBaseEntry,
        title: placeholdertitle,
    }

    const { theme } = useThemes()

    let costPrice: number | undefined =
        item.costItemsObj && item.costUIDS
            ? item.costUIDS.reduce((acc, costid) => {
                  let costAmount =
                      item.costItemsObj &&
                      costid &&
                      item.costItemsObj[costid] &&
                      item.costItemsObj[costid].amount
                  if (costAmount) {
                      return acc + costAmount
                  } else return acc
              }, 0)
            : item.costItemsObj &&
              item.costItemsObj['id'] &&
              item.costItemsObj['id'].amount

    let userCurrency =
        item.costItemsObj &&
        item.costItemsObj['id'] &&
        item.costItemsObj['id']?.currency === 'USD'
            ? '$'
            : item.costItemsObj?.['id']?.currency === 'EUR'
            ? '€'
            : '£'

    // console.log('item', item)
    return (
        <Wrapper role="button" onClick={props.onClick}>
            <Title>
                {props.entry?.title && props.entry?.title?.length !== 0
                    ? props.entry?.title
                    : placeholdertitle}
            </Title>
            <div style={{ paddingTop: '24px' }} />
            <div
                style={{
                    background: 'var(--border-muted, #E5E5E5)',
                    height: '1px',
                    width: '100%',
                }}
            />
            <div style={{ paddingTop: '24px' }} />
            <FlexRow>
                <ColItems>
                    <NameTxt>Start Date</NameTxt>
                    <ItemTxt
                        style={{
                            color:
                                item?.insurance_entry_policy_start_date ||
                                isReadonly
                                    ? colours[theme].text_darker
                                    : history_file_colours[theme].categories
                                          .insurance.primary_100,
                        }}
                    >
                        {props.entry?.insurance_entry_policy_start_date
                            ? dayjs(
                                  props.entry.insurance_entry_policy_start_date
                              ).format('DD/MM/YYYY')
                            : isReadonly
                            ? 'Missing info'
                            : ' Add Start Date'}
                    </ItemTxt>
                </ColItems>
                <ColItems>
                    <NameTxt>End Date</NameTxt>
                    <ItemTxt
                        style={{
                            color:
                                item?.insurance_entry_policy_start_date ||
                                isReadonly
                                    ? colours[theme].text_darker
                                    : history_file_colours[theme].categories
                                          .insurance.primary_100,
                        }}
                    >
                        {props.entry?.insurance_entry_policy_end_date
                            ? dayjs(
                                  props.entry.insurance_entry_policy_end_date
                              ).format('DD/MM/YYYY')
                            : isReadonly
                            ? 'Missing info'
                            : ' Add End Date'}
                    </ItemTxt>
                </ColItems>
            </FlexRow>
            <div style={{ paddingTop: '24px' }} />
            <ColItems>
                <NameTxt>Insurance cost</NameTxt>
                <ItemTxt
                    style={{
                        color:
                            costPrice || isReadonly
                                ? colours[theme].text_darker
                                : history_file_colours[theme].categories
                                      .insurance.primary_100,
                    }}
                >
                    {!costPrice
                        ? isReadonly
                            ? 'Missing info'
                            : 'Add Insurance cost'
                        : `${userCurrency ?? '£'} ${numberWithCommas({
                              numberToConvert: +costPrice,
                          })}`}
                </ItemTxt>
            </ColItems>
            <div style={{ paddingTop: '24px' }} />
            <InsurerBox>
                <InsurerIconWrapper>
                    <Icon icon="underwriter" />
                </InsurerIconWrapper>
                <ColItems style={{ gap: '6px' }}>
                    <NameTxt>Insurer</NameTxt>
                    <AnswerTxt
                        style={{
                            color:
                                item?.insurance_entry_underwriter || isReadonly
                                    ? colours[theme].text_darker
                                    : history_file_colours[theme].categories
                                          .insurance.primary_100,
                        }}
                    >
                        {item.insurance_entry_underwriter
                            ? item.insurance_entry_underwriter
                            : isReadonly
                            ? 'Missing info'
                            : 'Add Insurer'}
                    </AnswerTxt>
                </ColItems>
            </InsurerBox>
            <div style={{ paddingTop: '16px' }} />
            <InsurerBox>
                <InsurerIconWrapper>
                    <Icon icon="broker" />
                </InsurerIconWrapper>

                <ColItems style={{ gap: '6px' }}>
                    <NameTxt>Broker</NameTxt>
                    <AnswerTxt
                        style={{
                            color:
                                item?.insurance_entry_broker || isReadonly
                                    ? colours[theme].text_darker
                                    : history_file_colours[theme].categories
                                          .insurance.primary_100,
                        }}
                    >
                        {item.insurance_entry_broker
                            ? item.insurance_entry_broker
                            : isReadonly
                            ? 'Missing info'
                            : 'Add Broker'}
                    </AnswerTxt>
                </ColItems>
            </InsurerBox>
        </Wrapper>
    )
}

export default TimelineInsuranceItemMobileJourneyCard
