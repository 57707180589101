import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'

const AnimatedSvg = styled.svg<{ $draw: number; $percentage: number }>`
    transform: rotate(-90deg);
    transition: ${(props) =>
        props.$percentage < 24
            ? 'all 200ms ease-out'
            : props.$percentage < 68
            ? 'all 100ms ease-out'
            : 'all 500ms ease-out'};
    fill: none;
    stroke: var(--primary, #5ec3ca);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-dasharray: ${(props) =>
        props.$draw ? `${props.$draw} 999` : '0 999'};

    @keyframes pulse {
        0% {
            transform: rotate(-90deg) scale(1);
        }
        50% {
            transform: rotate(-90deg) scale(1.1);
        }
        100% {
            transform: rotate(-90deg) scale(1);
        }
    }

    @keyframes pulsetext {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
`

const PercentageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150px;
    width: 150px;
    border-radius: 50%;

    transition: all 200ms;
`
const Percentage = styled.h1`
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
    color: var(--primary, #5ec3ca);
    text-align: center;
    font-family: Lato;
    font-size: 24px;
    line-height: 24px; /* 100% */
    letter-spacing: 0.001px;
`
const PercentageText = styled.p`
    color: var(--text-muted, #b3b3b3);
    text-align: center;
    font-family: Lato;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
`

type Props = {
    isLoading: boolean
    size?: number
    customText?: string
    toggle?: (v: boolean) => void
}
const LoaderWithPercentageInner = (props: Props) => {
    let { isLoading, size, toggle, customText } = props

    const svgRef: React.RefObject<SVGSVGElement> | null | undefined =
        useRef(null)

    const [circleDraw, setCircleDraw] = useState(0)
    const [percentage, setPercentage] = useState(0)
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        if (isLoading) {
            setOpacity(1)
            setTimeout(startInterval, 200)
        } else {
            setPercentage(100)
            clearInterval(interval)
            savedHandlePercent.current = null
        }

        return () => {
            clearInterval(interval)
            savedHandlePercent.current = null
        }
    }, [isLoading])

    useEffect(() => {
        const svgEl = svgRef.current
        let radius = size ? size / 5 : 40
        if (svgEl && percentage) {
            setCircleDraw((percentage * 2 * radius * Math.PI) / 100)
        }

        if (percentage === 69) {
            setTimeout(() => setPercentage(78), 800)
        }
        if (percentage === 78) {
            setTimeout(() => setPercentage(98), 200)
        }

        if (percentage === 100) {
            clearInterval(interval)
            savedHandlePercent.current = null
            setTimeout(() => setOpacity(0), 800)
            setTimeout(() => {
                toggle && toggle(false)
            }, 800)
        }
        return () => {
            if (percentage === 100) {
                clearInterval(interval)
                savedHandlePercent.current = null
            }
        }
    }, [percentage])

    const savedHandlePercent: any = useRef(null)

    const handlePercent = () => {
        setPercentage((prevState) => {
            if (prevState < 24) {
                return prevState + 3
                // } else if (prevState < 25) {
                //     return prevState + 6
                // } else if (prevState < 70) {
                //     return prevState + 3
            } else if (prevState < 69) {
                return prevState + 9
            } else return prevState
        })
    }

    useEffect(() => {
        savedHandlePercent.current = handlePercent
    })

    let interval: any = null

    const startInterval = () => {
        interval = setInterval(savedHandlePercent.current, 100)
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--loader-bg, rgba(255, 255, 255, 0.95))',
                zIndex: 150,
                opacity: opacity,
                transition: opacity === 1 ? 'opacity 500ms' : 'opacity 800ms',
            }}
        >
            <AnimatedSvg
                ref={svgRef}
                viewBox={`0 0 ${size ? size / 2 : 100}  ${
                    size ? size / 2 : 100
                }`}
                width={size ?? 200}
                height={size ?? 200}
                $draw={circleDraw}
                $percentage={percentage}
                style={
                    percentage > 68 && percentage < 100
                        ? { animation: 'pulse 1000ms linear infinite' }
                        : {}
                }
            >
                <circle
                    cx={size ? size / 4 : 50}
                    cy={size ? size / 4 : 50}
                    r={size ? size / 5 : 40}
                />
            </AnimatedSvg>
            <PercentageWrapper
                style={{
                    border:
                        percentage < 69
                            ? '2px solid transparent'
                            : '2px solid rgba(94, 195, 202, 0.165)',
                    height: size ? size * 0.75 : undefined,
                    width: size ? size * 0.75 : undefined,
                }}
            >
                <Percentage
                    style={{ fontSize: size ? size * 0.12 : undefined }}
                >
                    {percentage}%
                </Percentage>
                {percentage < 100 && (
                    <PercentageText
                        style={{ fontSize: size ? size * 0.06 : undefined }}
                    >
                        {customText ?? 'Fetching your data'}
                    </PercentageText>
                )}
            </PercentageWrapper>
        </div>
    )
}

const LoaderWithPercentage = (props: Props) => {
    const [show, toggle] = useState(true)

    const location = useLocation()
    useEffect(() => {
        // execute on location change
        if (location) {
            toggle(true)
        }
        return () => {
            toggle(false)
        }
    }, [location])

    return show ? (
        <LoaderWithPercentageInner
            {...props}
            toggle={(v: boolean) => toggle(v)}
        />
    ) : (
        <div />
    )
}

export default LoaderWithPercentage
