import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const AttachmentIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13 13L3 3"
                stroke={color ?? colours[theme].text_default}
                stroke-width="1.5"
            />
            <path
                d="M8.5 12.5L7.70711 13.2929C7.25435 13.7456 6.64029 14 6 14V14C5.35971 14 4.74565 13.7456 4.29289 13.2929L2.70711 11.7071C2.25435 11.2544 2 10.6403 2 10V10C2 9.35971 2.25435 8.74565 2.70711 8.29289L3.5 7.5"
                stroke={color ?? colours[theme].text_default}
                stroke-width="1.5"
            />
            <path
                d="M7.5 3.5L8.29289 2.70711C8.74565 2.25435 9.35971 2 10 2V2C10.6403 2 11.2544 2.25435 11.7071 2.70711L13.2929 4.29289C13.7456 4.74565 14 5.35971 14 6V6C14 6.64029 13.7456 7.25435 13.2929 7.70711L12.5 8.5"
                stroke={color ?? colours[theme].text_default}
                stroke-width="1.5"
            />
        </svg>
    )
}

export default AttachmentIcon
