import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const AddBoxTextInfoText = styled.div<IHeaderProps>`
    font-family: Lato;
    text-align: center;
    font-style: none;
    font-size: ${(props) =>
        props.fontSize ? `${props.fontSize} !important` : '18px !important'};
    font-weight: 200;
    color: #5ec3ca;

    @media ${device.mobile} {
        display: none;
    }
`

export interface IHeaderProps {
    children?: React.ReactNode
    fontSize?: string
}

const AddBoxTextInfo: React.SFC<IHeaderProps> = (props) => (
    <AddBoxTextInfoText fontSize={props.fontSize && props.fontSize}>
        {props.children}
    </AddBoxTextInfoText>
)

AddBoxTextInfo.defaultProps = {}
export default AddBoxTextInfo
