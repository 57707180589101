import Decimal from 'decimal.js'
import { IAnyObject } from '../../insuranceQuoteApplication/reducer'
import { isTxtLengthValid } from '../../localdata/insuranceLocalData/fieldTypes'
import {
    isPatchAsInt_insurancefields,
    is_price_insurance_fields,
} from './api/customer'

type I_gen_draft_differing_values_to_patch_arg = {
    existing: IAnyObject
    draft: IAnyObject
}

// if root yes / no for disability for ex arent
// to be sent, check if id includes and remove it

const ids_replicating = [
    // 'has_motoring_convictions',
    // 'has_claims',
    // 'has_convictions',
    // 'has_memberships',
    // 'has_disabilities',
    // 'modifications',
    'application_custodian_cars',
    'addresses',
    'drivers',
]

const simpleTxtFields = [
    'registration_number',
    'given_name',
    'family_name',
    'nin',
    'license_number',
]

// const pattern = /_question$/

export const gen_draft_differing_values_to_patch = (
    p: I_gen_draft_differing_values_to_patch_arg
): undefined | IAnyObject => {
    let res: IAnyObject = {}
    Object.keys(p.draft).forEach((keyy) => {
        // if (!pattern.test(keyy)) {
        if (ids_replicating.indexOf(keyy) === -1) {
            let is_price =
                is_price_insurance_fields.indexOf(keyy) === -1 ? false : true

            if (!Array.isArray(p.draft[keyy])) {
                if (
                    p.draft[keyy] !== undefined &&
                    p.draft[keyy] !== null &&
                    JSON.stringify(p.draft[keyy]) !==
                        JSON.stringify(p.existing[keyy])
                ) {
                    let isPatchINT = p.draft[keyy]?.patch_as_int
                        ? true
                        : isPatchAsInt_insurancefields.indexOf(keyy) === -1
                        ? false
                        : true

                    let valuee =
                        keyy === 'make'
                            ? p.draft[keyy]?.name
                            : keyy === 'model'
                            ? p.draft[keyy]?.name
                            : `${p.draft[keyy]?.name}`.toLocaleLowerCase() ===
                              'no'
                            ? false
                            : `${p.draft[keyy]?.name}`.toLocaleLowerCase() ===
                              'yes'
                            ? true
                            : p.draft[keyy]?.uid
                            ? p.draft[keyy].uid
                            : p.draft[keyy]

                    let adjustedKey = p.draft[keyy]?.patch_field_id
                        ? p.draft[keyy]?.patch_field_id
                        : keyy

                    // console.log(
                    //     'l 45',
                    //     p.draft[keyy],
                    //     'adjustedKey',
                    //     adjustedKey
                    // )

                    res = {
                        ...res,
                        [adjustedKey]: is_price
                            ? Number(new Decimal(+valuee).times(100))
                            : isPatchINT
                            ? Math.round(+valuee)
                            : valuee,
                    }
                    if (keyy.includes('-unit')) {
                        let value_key = keyy.replace('-unit', '')
                        res = {
                            ...res,
                            [value_key]: is_price
                                ? Number(
                                      new Decimal(+p.draft[value_key]).times(
                                          100
                                      )
                                  )
                                : Math.round(+p.draft[value_key]),
                        }
                    }

                    if (keyy === 'body_type' && valuee === 'SUV / 4x4') {
                        res = {
                            ...res,
                            [keyy]: 'SUV',
                        }
                    }

                    let isSimpleTextField =
                        simpleTxtFields.indexOf(keyy) === -1 ? false : true

                    if (
                        isSimpleTextField &&
                        typeof p.draft[keyy] === 'string'
                    ) {
                        let isValidString = isTxtLengthValid(p.draft[keyy])
                            ? true
                            : false

                        if (isValidString) {
                            res = {
                                ...res,
                                [keyy]: p.draft[keyy].trim(),
                            }
                        } else {
                            delete res[keyy]
                        }
                    }
                }
            } else {
                if (keyy === 'contact_method') {
                    let draftPatchEnumGenNb: number = p.draft[keyy]?.reduce(
                        (current: string, acc: number) => {
                            return Number(acc) + Number(current)
                        },
                        0
                    )
                    let existingPatchEnumGenNb: number = p.existing[
                        keyy
                    ]?.reduce((current: string, acc: number) => {
                        return Number(acc) + Number(current)
                    }, 0)
                    if (draftPatchEnumGenNb !== existingPatchEnumGenNb) {
                        res = {
                            ...res,
                            [keyy]: draftPatchEnumGenNb,
                        }
                    }
                } else {
                    let isPatchINT =
                        isPatchAsInt_insurancefields.indexOf(keyy) === -1
                            ? false
                            : true

                    res = {
                        ...res,
                        [keyy]: is_price
                            ? Number(new Decimal(+p.draft[keyy]).times(100))
                            : isPatchINT
                            ? Math.round(+p.draft[keyy])
                            : p.draft[keyy],
                    }
                }
            }
        }
        // }
    })

    if (res && res['engine_capacity-unit']) {
        delete res['engine_capacity-unit']
    }

    if (res && res['odometer_reading-unit']) {
        delete res['odometer_reading-unit']
    }

    if (res && res['expected_annual_mileage-unit']) {
        delete res['expected_annual_mileage-unit']
    }

    if (Object.keys(res).length === 0) {
        return undefined
    } else return res
}

// these can be used in function above

type I_draft_entities_api_res_cleanup_arg = {
    entity_type: string
    draft: IAnyObject[]
    existing: IAnyObject[]
}

const gen_draft_differing_entities_to_delete = (
    p: I_draft_entities_api_res_cleanup_arg
): string[] => {
    let thingsToDelete: IAnyObject[] = p.existing.filter((existingItem) => {
        const draftItem = p.draft.find(
            (item) => item['uid'] === existingItem['uid']
        )
        return !draftItem?.uid
    })

    return thingsToDelete.map((item) => item.uid)
}

const gen_draft_differing_entities_to_create = (
    p: I_draft_entities_api_res_cleanup_arg
): IAnyObject[] => {
    let newItems = p.draft.filter((draftItem) => {
        const existingItem = p.existing.find(
            (item) => item['uid'] === draftItem['uid']
        )
        return !existingItem
    })

    let newItemsRes: IAnyObject[] = []

    for (let i = 0; i < newItems.length; i++) {
        let newItemToPopulate: IAnyObject = {}

        for (const key in newItems[i]) {
            let is_price =
                is_price_insurance_fields.indexOf(key) === -1 ? false : true

            // if (!pattern.test(key)) {
            let value =
                `${newItems[i][key]?.name}`.toLocaleLowerCase() === 'no'
                    ? false
                    : `${newItems[i][key]?.name}`.toLocaleLowerCase() === 'yes'
                    ? true
                    : newItems[i][key]?.uid
                    ? newItems[i][key].uid
                    : newItems[i][key]

            let saveAsInt = newItems[i][key]?.patch_as_int
                ? true
                : isPatchAsInt_insurancefields.indexOf(key) === -1
                ? false
                : true

            if (newItems[i][key]?.patch_field_id) {
                let newKey = newItems[i][key].patch_field_id
                // like: type: { name: x, uid: x, patch_field_id: x}

                newItemToPopulate = {
                    ...newItemToPopulate,
                    [newKey]: is_price
                        ? Number(new Decimal(+value).times(100))
                        : saveAsInt
                        ? Math.round(+value)
                        : value,
                }
            } else {
                newItemToPopulate = {
                    ...newItemToPopulate,
                    [key]: is_price
                        ? Number(new Decimal(+value).times(100))
                        : saveAsInt
                        ? Math.round(+value)
                        : value,
                }
            }

            // }
        }
        if (newItemToPopulate[`sentence`] === undefined) {
            delete newItemToPopulate[`sentence`]
        }
        if (newItemToPopulate[`driving_ban`] === undefined) {
            delete newItemToPopulate[`driving_ban`]
        }

        if (
            newItemToPopulate[`status`] &&
            newItemToPopulate[`status`].uid !== 'SETTLED' &&
            newItemToPopulate[`settled_date`] === undefined
        ) {
            delete newItemToPopulate[`settled_date`]
        }

        if (
            Object.keys(newItemToPopulate).length > 0 &&
            Object.values(newItemToPopulate).every((v) => v !== undefined)
        ) {
            newItemsRes = [...newItemsRes, newItemToPopulate]
        }
    }

    // console.log('@@@@@@@@@@@@@@ newItemsRes', newItemsRes)

    return newItemsRes
}

const gen_draft_differing_entities_to_patch = (
    p: I_draft_entities_api_res_cleanup_arg
): IAnyObject[] => {
    const updatedProperties: IAnyObject[] = []

    p.draft.forEach((draftItem) => {
        const existingItem = p.existing.find(
            (item) => item['uid'] === draftItem['uid']
        )

        if (existingItem) {
            const updatedProps: IAnyObject = {}

            for (const key in draftItem) {
                // TBC if this is correct because we may want to include ID because existing
                // key !== 'uid' &&
                // remember to check for .patch_uid and replace it as key
                let is_price =
                    is_price_insurance_fields.indexOf(key) === -1 ? false : true

                let saveAsInt = draftItem[key]?.patch_as_int
                    ? true
                    : isPatchAsInt_insurancefields.indexOf(key) === -1
                    ? false
                    : true

                if (
                    JSON.stringify(draftItem[key]) !==
                    JSON.stringify(existingItem[key])
                ) {
                    // if (!pattern.test(key)) {
                    let value =
                        `${draftItem[key]?.name}`.toLocaleLowerCase() === 'no'
                            ? false
                            : `${draftItem[key]?.name}`.toLocaleLowerCase() ===
                              'yes'
                            ? true
                            : draftItem[key]?.uid
                            ? draftItem[key].uid
                            : draftItem[key]

                    let keyy = draftItem[key]?.patch_field_id
                        ? draftItem[key]?.patch_field_id
                        : key

                    updatedProps[keyy] = is_price
                        ? Number(new Decimal(+value).times(100))
                        : saveAsInt
                        ? Math.round(+value)
                        : value
                    // }
                }
            }

            if (Object.keys(updatedProps).length > 0) {
                updatedProperties.push({
                    uid: existingItem?.uid,
                    ...updatedProps,
                })
            }
        }
    })

    return updatedProperties
}

type I_draft_entities_api_res_cleanup = {
    toDelete?: string[]
    objToPatch?: IAnyObject[]
}

export const gen_draft_differing_self_replicating_entities_single = (
    p: I_draft_entities_api_res_cleanup_arg
): I_draft_entities_api_res_cleanup => {
    let res: I_draft_entities_api_res_cleanup = {
        toDelete: undefined,
        objToPatch: undefined,
    }

    let deletionn: string[] = gen_draft_differing_entities_to_delete(p)
    let patchh: IAnyObject[] = gen_draft_differing_entities_to_create(p)
    let patchh2: IAnyObject[] = gen_draft_differing_entities_to_patch(p)
    // console.log('patchh l l132, ', patchh, 'patchh2', patchh2)

    let mergedArr: IAnyObject[] = []

    if (patchh.length > 0) {
        mergedArr = [...mergedArr, ...patchh]
    }

    if (patchh2.length > 0) {
        mergedArr = [...mergedArr, ...patchh2]
    }

    // console.log('objToPatch l 133, ', mergedArr)

    res = {
        toDelete: deletionn.length > 0 ? deletionn : undefined,
        objToPatch: mergedArr.length > 0 ? mergedArr : undefined,
    }

    // console.log('l 142', res)

    return res
}

type Igen_draft_differing_self_replicating_entities_arg_multiple = {
    draft: { [key: string]: IAnyObject[] }
    existing: { [key: string]: IAnyObject[] }
}

type IArrayOFAnyObj = {
    [key: string]: IAnyObject[]
}

type I_draft_entities_api_res_cleanup_multiple = {
    data_to_patch_by_type: IArrayOFAnyObj
    ids_to_delete_by_type?: { [key: string]: string[] }
}

export const gen_draft_differing_self_replicating_entities_multiple = (
    p: Igen_draft_differing_self_replicating_entities_arg_multiple
): I_draft_entities_api_res_cleanup_multiple => {
    let res: I_draft_entities_api_res_cleanup_multiple = {
        data_to_patch_by_type: {},
        ids_to_delete_by_type: undefined,
    }

    Object.keys(p.draft).forEach((entity_key) => {
        let entityInfo: I_draft_entities_api_res_cleanup =
            gen_draft_differing_self_replicating_entities_single({
                entity_type: entity_key,
                draft: p.draft[entity_key],
                existing: p.existing[entity_key],
            })

        if (entityInfo.objToPatch) {
            // console.log(
            //     'res l; 82',
            //     res,
            //     'l 184 entityInfo.objToPatch',
            //     entityInfo.objToPatch
            // )

            let data = {
                ...res.data_to_patch_by_type,
                [entity_key]: entityInfo.objToPatch,
            }

            // console.log('data l 194', data)

            res = {
                ...res,
                data_to_patch_by_type: { ...data },
            }
        }

        if (entityInfo.toDelete) {
            res = {
                ...res,
                ids_to_delete_by_type: res.ids_to_delete_by_type
                    ? {
                          ...res.ids_to_delete_by_type,
                          [entity_key]: [...entityInfo.toDelete],
                      }
                    : {
                          [entity_key]: [...entityInfo.toDelete],
                      },
            }
        }
    })

    // clean up
    if (res.data_to_patch_by_type) {
        let cleaned_up: IArrayOFAnyObj = res.data_to_patch_by_type

        Object.keys(res.data_to_patch_by_type).forEach((key) => {
            if (res.data_to_patch_by_type[key].length === 0) {
                delete cleaned_up[key]
            }
        })

        res.data_to_patch_by_type = cleaned_up
    }

    return res
}
