import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { useEffect, useState } from 'react'
import DoughnutChart, { IChartDoughnut } from './pie'
import colours, {
    history_file_colours,
    ITheme,
} from '../../../../providers/theme/colours'
import { useAppSelector } from '../../../../redux/store/hooks'
import { ICar, ICarInsights, ICarsObject } from 'entityModels'
import { ITimeRangeEnum } from '../../menu/carInsights/timeMenu'
import { ITimelineEntryTypes } from 'timelineModels'
import { IconSquareInsights } from '../tile'
import { useHistory } from 'react-router'
import { numberWithCommas } from '../../../../helpers/currencyConverter'
import { motion } from 'framer-motion'
import useThemes from '../../../../providers/theme/hooks'

type IStyle = { $theme: ITheme }

const Wrapper = styled.div<IStyle>`
    position: relative;

    height: 372px;

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        width: 336px;
        height: 288px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border-radius: 8px;
        background: ${(props) =>
            colours[props.$theme].background_neutral_subtle};
    }

    @media ${device.ipad} {
        display: flex;
        width: 500px;
        height: 288px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    @media ${device.mobile_and_ipad} {
        display: flex;
        padding: 24px;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        background: ${(props) =>
            colours[props.$theme].background_neutral_subtle};
        min-height: 320px;
    }

    @media (min-width: 880px) and (max-width: 1000px) {
        width: 430px;
    }

    @media (min-width: 1250px) {
        width: 30vw;
    }
`

Chart.register(CategoryScale)

const PieWrapper = styled.div`
    align-self: center;

    @media ${device.beyond_ipad_mobile} {
        width: 80%;
    }

    .chart-container {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }

    canvas {
        max-height: 194px !important;
    }
`

const Title1 = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.84px;
    text-transform: uppercase;
    align-self: flex-start;
    padding-left: 24px;

    padding-top: 16px;

    @media ${device.mobile_and_ipad} {
        align-self: center;
        padding: 0px;
    }
`

const Title2 = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-Bold;
    font-size: 18px;
    align-self: flex-start;
    padding-left: 24px;
    margin-bottom: 10px;

    @media ${device.mobile_and_ipad} {
        align-self: center;
        padding: 0px;
        margin-bottom: 25px;
    }
`

const CarInsightsPieChart = () => {
    const { theme } = useThemes()
    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    const carID = useAppSelector((state) => {
        return state.entities.carsData.currentCarID
    })
    let current: ICar | undefined = carID ? carsData[carID] : undefined
    let currURL = new URL(window.location.href)
    let params2 = new URLSearchParams(currURL.search)

    let selectedID: ITimeRangeEnum =
        params2.get('time_range') === 'year'
            ? 'year'
            : params2.get('time_range') === 'last_year'
            ? 'last_year'
            : params2.get('time_range') === 'all_time'
            ? 'all_time'
            : params2.get('time_range') === 'custom'
            ? 'custom'
            : 'year'

    let carInsights: ICarInsights | undefined = current?.insights

    let labells: ITimelineEntryTypes[] =
        selectedID &&
        carInsights &&
        carInsights[selectedID] &&
        carInsights![selectedID]!.stats &&
        carInsights![selectedID]!.stats.entries
            ? carInsights[selectedID]!.stats!.entries?.map(
                  (data) => data.car_entry_type
              )
            : []

    const [chartData, setChartDate] = useState<IChartDoughnut>({
        labels: labells,
        datasets: [
            {
                data:
                    selectedID &&
                    carInsights &&
                    carInsights[selectedID] &&
                    carInsights![selectedID]!.stats &&
                    carInsights![selectedID]!.stats.entries
                        ? carInsights[selectedID]!.stats!.entries?.map(
                              (data) => data.cost_sum / 100
                          )
                        : [],
                backgroundColor: labells.map((item) => {
                    if (
                        history_file_colours[theme].categories[item] &&
                        history_file_colours[theme].categories[item].primary_100
                    ) {
                        return history_file_colours[theme].categories[item]
                            .primary_100
                    } else return '#fff'
                }),
                borderWidth: 0,
                maintainAspectRatio: true,
                borderRadius: 3,
                spacing: 8,
            },
        ],
    })

    let isEmpty =
        carInsights?.[selectedID]?.stats?.total_sum === 0 ? true : false

    useEffect(() => {
        setChartDate({
            labels: labells,
            datasets: [
                {
                    data: isEmpty
                        ? [100]
                        : selectedID &&
                          carInsights &&
                          carInsights[selectedID] &&
                          carInsights![selectedID]!.stats &&
                          carInsights![selectedID]!.stats.entries
                        ? carInsights[selectedID]!.stats!.entries?.map(
                              (data) => data.cost_sum / 100
                          )
                        : [],
                    backgroundColor: isEmpty
                        ? ['#E5E5E5']
                        : labells.map((item) => {
                              if (
                                  history_file_colours[theme].categories[
                                      item
                                  ] &&
                                  history_file_colours[theme].categories[item]
                                      .primary_100
                              ) {
                                  return history_file_colours[theme].categories[
                                      item
                                  ].primary_100
                              } else return '#fff'
                          }),
                    borderWidth: 0,
                    maintainAspectRatio: true,
                    borderRadius:
                        isEmpty ||
                        (carInsights &&
                            carInsights[selectedID] &&
                            carInsights![selectedID]!.stats &&
                            carInsights![selectedID]!.stats.entries?.length ===
                                1)
                            ? 0
                            : 3,
                    spacing:
                        isEmpty ||
                        (carInsights &&
                            carInsights[selectedID] &&
                            carInsights![selectedID]!.stats &&
                            carInsights![selectedID]!.stats.entries?.length ===
                                1)
                            ? 0
                            : 8,
                },
            ],
        })
    }, [carInsights?.[selectedID]])

    const history = useHistory()

    return (
        <Wrapper $theme={theme}>
            {!isEmpty && (
                <motion.div
                    whileTap={{ scale: 1.3 }}
                    whileHover={{ scale: 1.1 }}
                    style={{
                        position: 'absolute',
                        right: 16,
                        top: 16,
                        display: 'flex',
                        alignItems: 'flex-end',
                        zIndex: 5,
                        cursor: 'pointer',
                    }}
                    role="button"
                    onClick={() => {
                        let currURL = new URL(window.location.href)

                        let params2 = new URLSearchParams(currURL.search)

                        params2.delete('categoryID')
                        params2.append('categoryID', 'all')

                        history.push({
                            search: params2.toString(),
                        })
                    }}
                >
                    <IconSquareInsights categoryID="all" />
                </motion.div>
            )}
            <Title1 $theme={theme}>Total expenses</Title1>
            <Title2 $theme={theme}>
                {carInsights?.[selectedID]?.stats?.currency === 'GBP'
                    ? '£'
                    : carInsights?.[selectedID]?.stats?.currency === 'USD'
                    ? '$'
                    : carInsights?.[selectedID]?.stats?.currency === 'EUR'
                    ? '€'
                    : '£'}
                {carInsights?.[selectedID]?.stats?.total_sum
                    ? numberWithCommas(
                          +carInsights![selectedID]!.stats.total_sum / 100
                      )
                    : 0}
            </Title2>
            <PieWrapper>
                <DoughnutChart isEmpty={isEmpty} chartData={chartData} />
            </PieWrapper>
        </Wrapper>
    )
}

export default CarInsightsPieChart
