import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyErrorString,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_OtherDriver,
    insuranceActions,
    IOtherDriver_DriveList,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import DriveListModal from '../../../../../molecules/insurance/modals/driveListModal'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'

type Props = {
    driver_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const DriverDriveListWizardForm = (props: Props) => {
    let { driver_id } = props

    let other_drivers = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft.other_drivers
    )
    let driver = other_drivers[driver_id]

    const carsData = useAppSelector((state) => state.entities.carsData)

    const [errorObj, setErrorObj] = useState<IAnyErrorString | undefined>(
        undefined
    )

    let {
        set_wizard_entity_id_to_skip,
        getFirstIncompleteSubsection_OtherDrivers,
        getInitialSectionWizardIds,
    } = useSetInitialInsurance()
    const dispatch: any = useAppDispatch()

    useEffect(() => {
        // to remove from entity id to skip if user click on it again though
        // this sort if stuff gets called on the last section of an entity.
        set_wizard_entity_id_to_skip(driver_id)
    }, [])
    let updateDriver = (
        id: 'drive_list' | string, // details TBC
        value: IOtherDriver_DriveList[] | any
    ) => {
        dispatch(
            insuranceActions.set_answer_other_driver_drive_list({
                id: 'drive_list',
                userid: driver_id,
                answer: value,
            })
        )
    }

    useEffect(() => {
        props.setTopWizardCheckActions(null)
    }, [])

    const frequencyErrorMessage = 'Use frequency is mandatory to fill in.'

    const isFrequenciesSaveAllowed = (): true | false => {
        let res: boolean = true
        if (other_drivers) {
            let otherDriversKeys = Object.keys(other_drivers)

            for (let i = 0; i < otherDriversKeys.length; i++) {
                let otherdriverObj: IInsuranceQuoteApplication_OtherDriver =
                    other_drivers[otherDriversKeys[i]]

                // console.log(
                //     'otherdriverObj',
                //     otherdriverObj,
                //     'other_drivers',
                //     other_drivers
                // )

                if (otherdriverObj && otherdriverObj?.drive_list) {
                    for (
                        let a = 0;
                        a < otherdriverObj?.drive_list.length;
                        a++
                    ) {
                        let drivelistItem = otherdriverObj?.drive_list[a]

                        if (!drivelistItem.use_frequency) {
                            res = false
                        }
                    }
                }
            }
        }

        setErrorObj(
            res !== true ? { use_frequency: frequencyErrorMessage } : undefined
        )

        return res
    }

    const history = useHistory()

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )
    const goNext = () => {
        let ids = getFirstIncompleteSubsection_OtherDrivers()

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return history.replace({
                search: params2.toString(),
            })
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('drivers')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else history.push(`/insurance/application/${applicationData?.id}`)
        }

        return getToAny()
        // next of the driver that is 100%, I should do the same for cars
    }
    let onSave = () => {
        if (isFrequenciesSaveAllowed() === true) {
            dispatch(
                insuranceActions.submit_otherDriver_driveList_request({
                    driver_id: `${driver_id}`,
                })
            )

            setTimeout(() => {
                goNext()
            }, 200)
        }
    }

    const goPrevious = () => {
        history.push(
            `/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=track_record&driver_id=${driver_id}`
        )
    }

    return (
        <>
            <DriveListModal
                driver_name={
                    driver
                        ? `${driver?.basic_details?.given_name} ${driver?.basic_details?.family_name}`
                        : ''
                }
                driver={driver}
                allCars={carsData.cars}
                selectedCars={driver?.drive_list}
                onSelect={(values: IOtherDriver_DriveList[]) => {
                    if (
                        driver_id &&
                        driver &&
                        driver?.drive_list_errors &&
                        driver?.drive_list_errors?.message &&
                        // if at least one car is selected (since error is if no cars at all)
                        values.length > 0
                    ) {
                        dispatch(
                            insuranceActions.reset_other_driver_drive_error(
                                driver_id
                            )
                        )
                    }
                    updateDriver('drive_list', values)
                }}
                isFromWizard={true}
                errorObj={errorObj}
            />

            <InsuranceGoBackReusableBottomBar
                isMobile
                saveBtnTxt="Save & Continue"
                isSaveDisabled={
                    !driver?.drive_list || driver?.drive_list.length === 0
                        ? true
                        : false
                }
                onSave={() => {
                    onSave()
                }}
                goBack={() => goPrevious()}
                sectionId="drivers"
            />
        </>
    )
}

export default DriverDriveListWizardForm
