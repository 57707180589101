import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/handover/handover_h_d.png'

import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import { CenterImg, ParagraphTitleJourney } from '../pastCars/memories'

import m_1 from '../../../../public/assets/images/journey/handover/handover_h_1.png'
import m_2 from '../../../../public/assets/images/journey/handover/handover_h_2.png'
import m_3 from '../../../../public/assets/images/journey/handover/handover_h_3.png'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

const JourneyWelcomeText = styled.div`
    padding: 0px;
    margin: 0px;
    color: var(--text-darker, #616161);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    width: 599px;
    max-width: 90vw;

    @media ${device.mobile_and_ipad} {
        width: 100%;
        max-width: 450px !important;
        text-align: left;
        padding: 16px 24px;
    }

    @media (max-width: 350px) {
        font-size: 14px;
    }
`

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function HandoverHowItWorksJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section['handover']
                                        .primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[`handover`]
                                        .primary_100
                                }
                            />

                            <JourneyColumnContent style={{ gap: 16 }}>
                                <StandardCtaBtn
                                    bg="transparent"
                                    customWidth="auto"
                                    customHeight="auto"
                                    removePaddings
                                    removeShadowOnHover
                                    onClick={goToPrevStep}
                                >
                                    <JourneyBtnContent $theme={theme}>
                                        <div
                                            style={{
                                                transform: 'rotate(90deg)',
                                            }}
                                        >
                                            <ChevronDown />
                                        </div>
                                        Back
                                    </JourneyBtnContent>
                                </StandardCtaBtn>
                                <JourneyStepTitle $theme={theme}>
                                    How it works
                                </JourneyStepTitle>

                                <JourneyWelcomeText
                                    style={{
                                        textAlign: 'left',
                                        width: 'auto',
                                    }}
                                >
                                    With Custodian's Car Handover feature,
                                    transitioning ownership of your car is
                                    simple and customizable, ensuring a smooth
                                    transfer of responsibility and documentation
                                    to the new owner.
                                </JourneyWelcomeText>

                                <div />

                                <JourneyColumnContent style={{ gap: 8 }}>
                                    <ParagraphTitleJourney>
                                        Inviting the New Owner
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        To initiate a Car Handover, simply
                                        select the new owner by entering their
                                        email address. If the new owner is not
                                        yet a Custodian user, they will receive
                                        an invitation to join the platform.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 8 }}>
                                    <ParagraphTitleJourney>
                                        Full Handover
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Transfer all existing data and
                                        information associated with the car to
                                        the new owner. This includes service
                                        records, maintenance logs, photos, and
                                        more.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 8 }}>
                                    <ParagraphTitleJourney>
                                        Custom Handover
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Tailor the handover to your preferences
                                        by selecting which specific data you
                                        want to transfer to the new owner. This
                                        option allows you to retain certain
                                        information while passing on others.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'handover'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'handover'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <img
                                src={image}
                                style={{
                                    objectFit: 'contain',
                                    maxWidth: '80vw',
                                    maxHeight: '82vh',
                                }}
                            />
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <JourneyPageWrapperMobile
                        allowScroll={true}
                        title={'How it works'}
                        sectionID="handover"
                        subtitle={`Give your car a permanent history for you and future owners`}
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={() => {
                            goToPrevStep()
                        }}
                        onNext={() => {
                            goToNextStep()
                        }}
                    >
                        <JourneyColumnContent
                            style={{
                                gap: 0,
                            }}
                        >
                            <JourneyWelcomeText
                                style={{
                                    paddingTop: '0px',
                                    textAlign: 'left',
                                    width: 'auto',
                                }}
                            >
                                With Custodian's Car Handover feature,
                                transitioning ownership of your car is simple
                                and customizable, ensuring a smooth transfer of
                                responsibility and documentation to the new
                                owner.
                            </JourneyWelcomeText>

                            <JourneyColumnContent
                                style={{
                                    paddingTop: '12px',
                                    gap: 4,
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }}
                            >
                                <ParagraphTitleJourney>
                                    Inviting the New Owner
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    To initiate a Car Handover, simply select
                                    the new owner by entering their email
                                    address. If the new owner is not yet a
                                    Custodian user, they will receive an
                                    invitation to join the platform.
                                </JourneyText>
                            </JourneyColumnContent>
                            <div style={{ paddingTop: '36px' }} />
                            <CenterImg
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={m_1}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '100vw',
                                        maxHeight: '60vh',
                                        alignSelf: 'center',
                                    }}
                                />
                            </CenterImg>
                            <div style={{ paddingTop: '12px' }} />
                            <JourneyColumnContent
                                style={{
                                    gap: 4,
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }}
                            >
                                <ParagraphTitleJourney>
                                    Full Handover
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Transfer all existing data and information
                                    associated with the car to the new owner.
                                    This includes service records, maintenance
                                    logs, photos, and more.
                                </JourneyText>
                            </JourneyColumnContent>

                            <div style={{ paddingTop: '12px' }} />
                            <CenterImg
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={m_2}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '90vw',
                                        maxHeight: '60vh',
                                        alignSelf: 'center',
                                        transform: 'translateX(10px)',
                                    }}
                                />
                            </CenterImg>
                            <div style={{ paddingTop: '12px' }} />
                            <JourneyColumnContent
                                style={{
                                    gap: 4,
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }}
                            >
                                <ParagraphTitleJourney>
                                    Custom Handover
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Tailor the handover to your preferences by
                                    selecting which specific data you want to
                                    transfer to the new owner. This option
                                    allows you to retain certain information
                                    while passing on others.
                                </JourneyText>
                            </JourneyColumnContent>
                            <div style={{ paddingTop: '28px' }} />
                            <CenterImg
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={m_3}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '80vw',
                                        maxHeight: '60vh',
                                        alignSelf: 'center',
                                    }}
                                />
                            </CenterImg>
                        </JourneyColumnContent>

                        <div style={{ paddingTop: '50px' }} />
                    </JourneyPageWrapperMobile>
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
