import { IJourneyHandoverStepID } from 'journeyInterfaces'
import { IUser } from 'myModels'
import { useHistory } from 'react-router'
import HandoverHowItWorksJourneyStep from '../howItWorks'
import HandoverLearnJourneyStep from '../learn'
import JourneyHandoverWelcome from '../welcome'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'

type Props = {
    stepID: IJourneyHandoverStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
}
export default function HandoverJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    user,
}: Props) {
    const history = useHistory()
    let locationState: any = history.location.state

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyHandoverWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )

        case 'learn':
            return (
                <HandoverLearnJourneyStep
                    step={1}
                    totalSteps={2}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(`/journey/handover?step=welcome`)
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/handover`,
                            search: `?step=how_it_works`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'how_it_works':
            return (
                <HandoverHowItWorksJourneyStep
                    step={2}
                    totalSteps={2}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(`/journey/handover?step=learn`)
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/handover`,
                            search: `?step=achieved`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'achieved':
            return <JourneyAchievedStep sectionID="handover" />

        default:
            return (
                <JourneyHandoverWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
