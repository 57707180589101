import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import * as Sentry from '@sentry/browser'

export type IDeleteInsuranceApplication_req = {
    entity_type:
        | 'claims'
        | 'motoring_convictions'
        | 'memberships'
        | 'convictions'
        | 'disabilities'
        | 'addresses'
        | 'application_custodian_cars'
        | 'external_everyday_cars'
        | 'everyday_custodian_cars'
        | 'deassign_named_driver'
        | 'drivers'
        | 'modifications'
        | 'delete_named_driver'
        | 'custodian_car_parking'
        | 'external_car_parking'
    entity_id: string
    appli_id: string
    parent_vehicle_id?: string // ex: car parent to a driver
    parent_customer_id?: string // ex: car parent to a driver
    parent_named_driver_id?: string
    is_customer?: boolean
}

const applications_level = [
    'motoring-convictions',
    'claims',
    'convictions',
    'application_custodian_cars',
    'deassign_named_driver',
]
const customers_level = ['memberships', 'disabilities', 'addressbook']
const driver_level = ['drivers']
const insured_vehicle_level = ['modifications']
const base_url: string = `${urls.base_url}/insurance`

export const deleteInsuranceApplicationEntity = (
    p: IDeleteInsuranceApplication_req
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }
    let url: string | undefined = undefined

    let entity_type_adjusted: string = p.entity_type

    if (entity_type_adjusted === 'motoring_convictions') {
        entity_type_adjusted = 'motoring-convictions'
    }

    if (entity_type_adjusted === 'addresses') {
        entity_type_adjusted = 'addressbook'
    }

    if (insured_vehicle_level.indexOf(entity_type_adjusted) > -1) {
        url =
            base_url +
            `/customers/vehicles/${p.parent_vehicle_id}/${entity_type_adjusted}/${p.entity_id}`
    } else if (
        driver_level.indexOf(entity_type_adjusted) > -1 &&
        p.parent_vehicle_id !== undefined
    ) {
        url =
            base_url +
            `/named-drivers/${p.entity_id}/vehicles/${p.parent_vehicle_id}`
    } else if (
        driver_level.indexOf(entity_type_adjusted) > -1 &&
        p.parent_vehicle_id === undefined
    ) {
        url =
            base_url +
            `/applications/${p.appli_id}/named-drivers/${p.entity_id}`
    } else if (insured_vehicle_level.indexOf(entity_type_adjusted) > -1) {
        url =
            base_url +
            `/applications/${p.appli_id}/vehicles/${p.parent_vehicle_id}`
    } else {
        if (applications_level.indexOf(entity_type_adjusted) > -1) {
            url =
                base_url +
                `/application/${p.appli_id}/${entity_type_adjusted}/${p.entity_id}`
        }
    }

    if (entity_type_adjusted === 'deassign_named_driver') {
        url =
            base_url +
            `/applications/${p.appli_id}/named-drivers/${p.entity_id}`
    }

    if (entity_type_adjusted === 'delete_named_driver') {
        url = base_url + `/customers/named-drivers/${p.entity_id}`
    }

    if (entity_type_adjusted === 'application_custodian_cars') {
        url = base_url + `/applications/${p.appli_id}/vehicles/${p.entity_id}`
    }

    if (entity_type_adjusted === 'external_everyday_cars') {
        url =
            base_url +
            `/applications/${p.appli_id}/external-vehicles/everyday/${p.entity_id}`
    }
    if (entity_type_adjusted === 'everyday_custodian_cars') {
        url =
            base_url +
            `/applications/${p.appli_id}/vehicles/everyday/${p.entity_id}`
    }

    if (
        customers_level.indexOf(entity_type_adjusted) > -1 &&
        !p.parent_named_driver_id
    ) {
        url = base_url + `/customers/${entity_type_adjusted}/${p.entity_id}`
    }

    if (p.is_customer === true) {
        url = base_url + `/customers/${entity_type_adjusted}/${p.entity_id}`
    }

    if (p.parent_named_driver_id) {
        url =
            base_url +
            `/named-drivers/${p.parent_named_driver_id}/${entity_type_adjusted}/${p.entity_id}`
    }

    if (p.entity_type === 'custodian_car_parking') {
        url =
            base_url +
            `/customers/vehicles/${p.parent_vehicle_id}/parking-info/${p.entity_id}`
    }
    if (p.entity_type === 'external_car_parking') {
        url =
            base_url +
            `/customers/external-vehicles/${p.parent_vehicle_id}/parking-info/${p.entity_id}`
    }

    if (!url) {
        let message = `Invalid arguments in application entity deletion: ${JSON.stringify(
            p
        )}`
        Sentry.captureMessage(message)
        throw Error(message)
    }
    return fetch(url, requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Delete application entity error',
                    JSON.stringify(p)
                )
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
