import { call, fork, put, take, select } from 'redux-saga/effects'
// WATCHER FUNCTION : watcher get car by id*

import * as actions from '../actions'
import { api } from '../../../services'
import { push } from 'connected-react-router'

import {
    IDropdownItem,
    IExternalCarDataRequestPayloadVersion2,
    IExternalDataByRegistrationNumberObject,
    IExternalDataByRegistrationNumberSingle,
    IListFromAPiType,
} from 'entityModels'
import { ConvertFromVesApiToStateFormat } from '../../../conversions/entities/externalCarDataConversions'
import posthog from 'posthog-js'

import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../../../localdata/dropdownData/reducer'
import { RootState } from 'typesafe-actions'
import Fuse from 'fuse.js'
import { setMakeDropdownDataApi } from '../../../localdata/dropdownData/sagas/setMakeDropdownData'

let getDropdownMakesDataState = (state: RootState): IDropdownItem[] | null =>
    state.localdata.dropdownData.carMakes

export function* getExternalCarDataByRegistrationNumberVersion2(
    payload: IExternalCarDataRequestPayloadVersion2
) {
    try {
        let apidata: IExternalDataByRegistrationNumberSingle = {}

        let makesList: IDropdownItem[] = yield select(getDropdownMakesDataState)

        if (!makesList) {
            makesList = yield call(setMakeDropdownDataApi, true)
        }

        if (payload.registration_number !== undefined) {
            apidata = yield call(
                api.externalCarDataByRegistrationNumber
                    .get_external_car_data_by_registration_number,
                payload.registration_number
            )
        }

        let converted_object: IListFromAPiType =
            ConvertFromVesApiToStateFormat(apidata)

        let resObject: IExternalDataByRegistrationNumberObject = {}

        resObject['new_car'] = converted_object

        let countryAnswer = {
            id: 'country',
            text: 'country',
            answer: payload.country ?? undefined,
        }

        resObject['new_car'] = {
            ...resObject['new_car'],
            country: countryAnswer,
        }

        let apiMakeName =
            apidata.make && apidata.make !== 'No details held by DVLA'
                ? apidata.make
                : null

        if (apiMakeName && makesList) {
            const fuse = new Fuse(makesList, {
                keys: ['name', 'uid'],
                threshold: 0.2,
            })

            let results: any = fuse.search(apiMakeName)

            if (results && results[0]) {
                // if (
                //     make_matched.item.name === 'Mercedes' &&
                //     make_matched.item.uid
                // ) {
                //     const md_make_matched = searchResults(
                //         make_fuse,
                //         'Mercedes-Benz',
                //         true
                //     )

                let makeName = results[0].item?.name
                let makeId = results[0].item?.uid

                if (makeName !== undefined && makeId !== undefined) {
                    yield put(dropDownActions.setTempMakeDisplay(`${makeName}`))
                    yield put(dropDownActions.setTempMakeIDSuccess(makeId))
                    yield put(
                        dropDownActions.setModelDropdownDataRequest({
                            makeName: `${makeName}`,
                        })
                    )

                    resObject['new_car'] = {
                        ...resObject['new_car'],
                        make: {
                            id: 'make',
                            text: 'make',
                            answer: makeName,
                        },
                    }
                }
            }
        }

        yield put(
            actions.actions.getExternalCarDataByRegistrationNumberSuccess(
                resObject
            )
        )

        if (payload.hydrateOnly) {
            // don't navigate
            return
        } else if (payload.isOnboarding === true) {
            yield put(
                push(
                    `/onboarding-step-2-2?carid=new_car&from_ves_api=true&reg_no=${payload.registration_number}&country=${payload.country}`
                )
            )
        } else {
            yield put(
                push(
                    `/car/new_car/confirm?from_ves_api=true&reg_no=${payload.registration_number}&country=${payload.country}`
                )
            )
        }

        // Capture event
        posthog.capture('DVLA API LOOKUP BY CAR REGISTRATION')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat:
                    getExternalCarDataByRegistrationNumberVersion2,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            yield put(
                actions.actions.getExternalCarDataByRegistrationNumberSuccess(
                    {}
                )
            )

            // let typedError: IReduxError = ConvertToReduxError(error)
            // yield put(
            //     actions.errorActions.getExternalCarDataByRegistrationNumberError(
            //         typedError
            //     )
            // )

            if (payload.hydrateOnly) {
                // don't navigate
                return
            } else if (payload.isOnboarding === true) {
                yield put(
                    push(
                        `/onboarding-step-2-2?carid=new_car&from_ves_api=false&reg_no=${payload.registration_number}`
                    )
                )
            } else {
                if (payload.country === 'United Kingdom') {
                    yield put(
                        push(
                            `/car/new_car/confirm?from_ves_api=false&country=${payload.country}&reg_no=${payload.registration_number}`
                        )
                    )
                } else
                    yield put(
                        push(
                            `/car/new_car/confirm?from_ves_api=false&foreign=true`
                        )
                    )
            }
        }
    }
}

function* watcherGetExternalCarDataByRegistrationNumberVersion2() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions
                .getExternalCarDataByRegistrationNumberRequestVersion2
        )

        yield call(getExternalCarDataByRegistrationNumberVersion2, payload)
    }
}

const get_external_car_data_by_registration_number_version2: any[] = [
    fork(watcherGetExternalCarDataByRegistrationNumberVersion2),
]

export default get_external_car_data_by_registration_number_version2
