import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import Faded from '../../templates/animated/faded'

import { WindowScroller, AutoSizer, List } from 'react-virtualized'

import { ITimelineEntryTypes, ITimelineItem } from 'timelineModels'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import TimelineItemDesktop from '../../molecules/timelineItemDesktop/timelineItemDesktop'
import { IDropdownItem, ITechnicalInformationObject } from 'entityModels'

// import TimelineItemPlaceholderDesktop from '../../molecules/timelineItemDesktop/timelineItemPlaceholder'
import FadedSlower from '../../templates/animated/FadedSlower'
import CustomIconTagQuickFilterItem from '../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import 'react-virtualized/styles.css' // only needs to be imported once
import Expander from '../../atoms/expander/expander'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
// import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import colours, { ITheme } from '../../../providers/theme/colours'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

const Container = styled.div<IStyle>`
    width: 100%;
    .ReactVirtualized__Grid__innerScrollContainer {
        margin-bottom: 100px;
    }

    .ReactVirtualized__Grid__innerScrollContainer > div:last-child {
        ${(props) => props.$lastEvenChild && 'z-index: -1;'}
    }

    .noScrollbar {
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        ::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }
`

// const TestDiv = ``
const WrapperView = styled.div`
    position: relative;
    width: 100%;
    min-width: 100%;
    max-width: 1498px;
    padding: 24px 48px;
    display: grid;
    grid-template-columns: 1fr 0px 1fr;
    z-index: 1;
`

type IVerticalLineProps = {
    $height?: string
    $fade: boolean
    $fadeReverse: boolean
    $fadeBoth: boolean
    $theme: ITheme
}

const VerticalLine = styled.div<IVerticalLineProps>`
    position: relative;
    z-index: 4;
    width: 4px;
    background: ${(props) =>
        props.$fadeBoth
            ? `linear-gradient(rgba(26, 26, 26, 0.00) 0%, ${
                  colours[props.$theme].border_muted
              } 50%, rgba(26, 26, 26, 0.00) 100%)`
            : props.$fade
            ? `linear-gradient(rgba(26, 26, 26, 0.00) 0%, ${
                  colours[props.$theme].border_muted
              } 20%, ${colours[props.$theme].border_muted} 100%)`
            : props.$fadeReverse
            ? `linear-gradient(${colours[props.$theme].border_muted} 0%, ${
                  colours[props.$theme].border_muted
              } 80%, rgba(26, 26, 26, 0.00) 100%)`
            : colours[props.$theme].border_muted};
    height: ${(props) => props.$height};
    margin: 0 auto;
`

const Wrapper = styled.div`
    width: 100%;
    max-width: 1498px;
    min-height: 100vh;
    margin: 0 auto;
`

const GreyOverWrapper = styled.div`
    width: 100%;
    /* min-height: 500px; */
    display: flex;
    justify-content: center;
`

//f5f8f8

const StickyDiv = styled.div<{ show: boolean }>`
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: ${(props) => (props.show ? 'auto' : '0px')};
    opacity: ${(props) => (props.show ? 1 : 0)};
    transition: all 200ms;
    top: 64px;
    z-index: 5;
    margin-bottom: ${(props) => (props.show ? '32px' : '0px')};
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
`

type IStyle = {
    containsPlaceholder?: boolean
    $lastEvenChild?: boolean
}

const TimelineItemWrapperLeft = styled.div<IStyle>`
    position: relative;
    max-width: 100%;
    min-width: 100%;
    transform: ${(props) =>
        props.containsPlaceholder ? 'translateY(100%)' : undefined};
    z-index: 5;
`

const TimelineItemWrapperRight = styled.div<IStyle>`
    position: relative;
    max-width: 100%;
    min-width: 100%;
    transform: ${(props) =>
        props.containsPlaceholder ? 'translate(0, 100%)' : 'translate(0, 50%)'};

    z-index: 5;
`

const SearchTagsWrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 12px 0px;
    align-items: center;
    row-gap: 16px;
    column-gap: 8px;
    flex-wrap: wrap;
    max-width: 1400px;
    padding-left: 48px;
    padding-right: 48px;
`

const CriteriaName = styled.p`
    margin: 0px;
    padding: 0px;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.84px;
    text-transform: uppercase;
    padding-left: 8px;
`

interface Props extends RouteComponentProps<any> {
    timelineItems: ITimelineItem[] | undefined
    attachmentsObj: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    dataCyId?: string
    categories?: IDropdownItem[] | null | undefined
    tags?: IDropdownItem[] | null | undefined
    withPlaceholder?: boolean
    carid?: string
    selectedCategories?: ITimelineEntryTypes[]
    selectedLabels?: string[]
    onPlaceholderClick?: any
    removeCriteria: any
    clearAll?: any
    hasSampleData?: boolean
    userCurrency: string | undefined
    readOnlyMode?: boolean
    technicalInformationData?: ITechnicalInformationObject
    getCarDataByIdRequest: (id: string) => void
    activeEntryTitleQuery?: string
}

const dispatchProps = {
    getCarDataByIdRequest: (id: string) => getCarDataByIdRequest(id),
}

function mapStateToProps(state: RootState) {
    return {
        technicalInformationData:
            state.entities.technicalInformationData.technical_information,
    }
}

const stickyActiveSelectedCriteria = (
    theme: ITheme,
    selectedCategories: ITimelineEntryTypes[],
    selectedLabels: string[],
    carid: string | undefined,
    removeCriteria: any,
    clearAll: any,
    activeEntryTitleQuery?: string
) => {
    let currentSelectedLabels: string[] = selectedLabels ? selectedLabels : []
    let currentSelectedCategories: ITimelineEntryTypes[] = selectedCategories
        ? selectedCategories
        : []

    const removeCategory = (criteria: ITimelineEntryTypes) => {
        let indexOfClickedCriteria: number =
            currentSelectedCategories.indexOf(criteria)

        if (indexOfClickedCriteria >= 0) {
            currentSelectedCategories.splice(indexOfClickedCriteria, 1)
        }

        if (currentSelectedCategories.length >= 0 && carid) {
            removeCriteria(
                currentSelectedCategories,
                selectedLabels,
                activeEntryTitleQuery
            )
        }
    }

    const removeLabels = (criteria: string) => {
        let indexOfClickedCriteria: number =
            currentSelectedLabels.indexOf(criteria)

        if (indexOfClickedCriteria >= 0) {
            currentSelectedLabels.splice(indexOfClickedCriteria, 1)
        }

        if (currentSelectedLabels.length >= 0 && carid) {
            removeCriteria(
                selectedCategories,
                currentSelectedLabels,
                activeEntryTitleQuery
            )
        }
    }

    const removeKeyword = () => {
        if (carid) {
            removeCriteria(selectedCategories, selectedLabels, undefined)
        }
    }

    return (
        <StickyDiv
            show={
                (selectedCategories && selectedCategories.length > 0) ||
                (selectedLabels && selectedLabels.length > 0) ||
                activeEntryTitleQuery
                    ? true
                    : false
            }
        >
            <Expander
                width="100%"
                height={
                    (selectedCategories && selectedCategories.length > 0) ||
                    (selectedLabels && selectedLabels.length > 0) ||
                    activeEntryTitleQuery
                        ? '100%'
                        : 0
                }
            >
                <Wrapper style={{ minHeight: 'auto' }}>
                    <SearchTagsWrapper>
                        {selectedCategories.length > 0 ? (
                            <CriteriaName>Categories</CriteriaName>
                        ) : null}
                        {selectedCategories.map(
                            (categoryName: ITimelineEntryTypes, i: number) => (
                                <div key={`${categoryName} - ${i}`}>
                                    <FadedSlower duration={0.2}>
                                        <CustomIconTagQuickFilterItem
                                            height="24px"
                                            id={categoryName}
                                            onCrossClick={() =>
                                                removeCategory(categoryName)
                                            }
                                        >
                                            {categoryName}
                                        </CustomIconTagQuickFilterItem>
                                    </FadedSlower>
                                </div>
                            )
                        )}
                        {selectedLabels.length > 0 ? (
                            <CriteriaName>Labels</CriteriaName>
                        ) : null}

                        {selectedLabels.map((label: string, i: number) => (
                            <div key={`${label} - ${i}`}>
                                <FadedSlower duration={0.2}>
                                    <CustomIconTagQuickFilterItem
                                        isActive={true}
                                        onCrossClick={() => removeLabels(label)}
                                        border={`1px solid ${colours[theme].border_muted}`}
                                        height="25px"
                                        bgcolor={
                                            colours[theme].background_default
                                        }
                                        isLabel
                                    >
                                        {label}
                                    </CustomIconTagQuickFilterItem>
                                </FadedSlower>
                            </div>
                        ))}

                        {activeEntryTitleQuery ? (
                            <CriteriaName>Keyword</CriteriaName>
                        ) : null}
                        <FadedSlower duration={0.2}>
                            {activeEntryTitleQuery ? (
                                <CustomIconTagQuickFilterItem
                                    isActive={true}
                                    onCrossClick={() => removeKeyword()}
                                    height="25px"
                                >
                                    {activeEntryTitleQuery}
                                </CustomIconTagQuickFilterItem>
                            ) : null}
                        </FadedSlower>
                    </SearchTagsWrapper>
                </Wrapper>
                {/* <div
                    style={{
                        position: 'absolute',
                        right: '20px',
                        top: '15px',
                        // transform: 'translateY(-2px)',
                    }}
                >
                    {((selectedCategories && selectedCategories.length > 0) ||
                        (selectedLabels && selectedLabels.length > 0)) && (
                        <ButtonAtom theme="naked-text" onClick={clearAll}>
                            Clear All
                        </ButtonAtom>
                    )}
                </div> */}
            </Expander>
        </StickyDiv>
    )
}

const entryPair = (
    data: ITimelineItem[],
    index: number,
    userCurrency: string | undefined,
    theme: ITheme,
    onClick?: any,
    onPlaceholderClick?: any,
    hasSampleData?: boolean,
    readOnlyMode?: boolean
) => (
    <GreyOverWrapper>
        <WrapperView>
            {data[index] ? (
                <TimelineItemWrapperLeft>
                    <TimelineItemDesktop
                        isFirst={index === 0}
                        lineOnRight
                        item={data[index]}
                        onClick={() => onClick(data[index].id)}
                        hasTootip={hasSampleData}
                        userCurrency={userCurrency}
                        hasSampleData={hasSampleData}
                    />
                </TimelineItemWrapperLeft>
            ) : null}

            {data[index] || data[index + 1] ? (
                <VerticalLine
                    $theme={theme}
                    $height={data[index + 1] ? '150%' : '100%'}
                    $fadeBoth={
                        data.length === 1 || data.length === 2 ? true : false
                    }
                    $fade={index === 0 ? true : false}
                    $fadeReverse={
                        index === data.length - 1 ||
                        index + 1 === data.length - 1
                            ? true
                            : false
                    }
                />
            ) : null}
            {data[index + 1] ? (
                <TimelineItemWrapperRight>
                    <TimelineItemDesktop
                        isFirst={index === 0}
                        lineOnLeft
                        item={data[index + 1]}
                        onClick={
                            hasSampleData
                                ? undefined
                                : () => onClick(data[index + 1].id)
                        }
                        hasTootip={hasSampleData}
                        userCurrency={userCurrency}
                        hasSampleData={hasSampleData}
                    />
                </TimelineItemWrapperRight>
            ) : null}
        </WrapperView>
    </GreyOverWrapper>
)

function cell({
    data,
    theme,
    onClick,
    index,
    style,
    onPlaceholderClick,
    hasSampleData,
    userCurrency,
    readOnlyMode,
}: {
    data: ITimelineItem[]
    theme: ITheme
    onClick?: any
    index: number
    style: any
    onPlaceholderClick?: any
    hasSampleData?: boolean
    userCurrency: string | undefined
    readOnlyMode?: boolean
}) {
    if (index % 2 === 0) {
        return (
            <div key={`pair_${index}+${index + 1}`} style={style}>
                {entryPair(
                    data,
                    index,
                    userCurrency,
                    theme,
                    onClick,
                    onPlaceholderClick,
                    hasSampleData,
                    readOnlyMode
                )}
            </div>
        )
    }
}

type State = {}
class TimelineViewManagerDesktop extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        let carid = this.props.match.params.carid
        let tech_info_in_state =
            this.props.technicalInformationData &&
            this.props.technicalInformationData[`hf-${carid}`]
        if (!tech_info_in_state && !this.props.readOnlyMode) {
            this.props.getCarDataByIdRequest(carid)
        }
    }

    getItemSize = (index: number): number => {
        if (this.props.timelineItems) {
            if (
                index === this.props.timelineItems.length - 1 ||
                index === this.props.timelineItems.length ||
                this.props.timelineItems.length === 0
            ) {
                return 330
            } else return 200
        }
        return 0
    }

    renderTimelineItems = (theme: ITheme) => {
        let data =
            this.props.timelineItems && this.props.timelineItems.length > 0
                ? this.props.timelineItems
                : []
        // let attachmentsObj = this.props.attachmentsObj
        // let costsObj = this.props.costsObj
        let carid: string | undefined = this.props.carid
        let hasSampleData = this.props.hasSampleData
        let onClick = hasSampleData
            ? () => this.props.history.push(`/car/${carid}/history-file/create`)
            : (timelineItemId: string) => {
                  this.props.timelineItems &&
                      this.props.timelineItems?.length > 0 &&
                      this.props.history.push(
                          `${this.props.match.url}/entry?entryid=${timelineItemId}`
                      )
              }

        let selectedCategories = this.props.selectedCategories
            ? this.props.selectedCategories
            : []
        let selectedLabels = this.props.selectedLabels
            ? this.props.selectedLabels
            : []
        let activeEntryTitleQuery = this.props.activeEntryTitleQuery
        let removeCriteria = this.props.removeCriteria

        let onPlaceholderClick = this.props.onPlaceholderClick
        let clearAll = this.props.clearAll
        let userCurrency = this.props.userCurrency
        let readOnlyMode = this.props.readOnlyMode
        return (
            <Faded>
                {stickyActiveSelectedCriteria(
                    theme,
                    selectedCategories,
                    selectedLabels,
                    carid,
                    removeCriteria,
                    clearAll,
                    activeEntryTitleQuery
                )}
                <Wrapper>
                    {data.length > 0 ? (
                        <WindowScroller>
                            {({ height, scrollTop }) => (
                                <div>
                                    <AutoSizer>
                                        {({ width }) => (
                                            <div
                                                style={{ position: 'relative' }}
                                            >
                                                <List
                                                    autoHeight
                                                    height={height}
                                                    width={width}
                                                    scrollTop={scrollTop}
                                                    rowHeight={({ index }) => {
                                                        return this.getItemSize(
                                                            index
                                                        )
                                                        // return 155
                                                    }}
                                                    rowRenderer={({
                                                        index,
                                                        style,
                                                    }) =>
                                                        cell({
                                                            data,
                                                            theme,
                                                            onClick,
                                                            index,
                                                            style,

                                                            onPlaceholderClick,
                                                            hasSampleData,
                                                            userCurrency,
                                                            readOnlyMode,
                                                            // attachmentsObj,
                                                            // costsObj,
                                                        })
                                                    }
                                                    overscanRowCount={20}
                                                    rowCount={
                                                        data && data.length > 0
                                                            ? !readOnlyMode
                                                                ? data.length +
                                                                  1
                                                                : data.length
                                                            : 2
                                                    }
                                                />
                                                <Wrapper
                                                    style={{
                                                        height: 100,
                                                        minHeight: 100,
                                                        backgroundColor:
                                                            '#ffffff',
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        zIndex: 100,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </AutoSizer>
                                </div>
                            )}
                        </WindowScroller>
                    ) : (
                        <NoFilterResults variant="desktop" />
                    )}
                </Wrapper>
            </Faded>
        )
    }

    render() {
        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Container
                        $lastEvenChild={
                            this.props.timelineItems &&
                            this.props.timelineItems.length % 2 === 0 &&
                            !this.props.readOnlyMode
                        }
                    >
                        {this.renderTimelineItems(theme)}
                    </Container>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TimelineViewManagerDesktop)
)
