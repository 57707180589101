import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import CarHighlightsEditFormMobile from '../editForms/carHighlightsEditForm/carHighlightsEditFormMobile'
import {
    IHighlightedFact,
    IHighlightedFactsObject,
    IUpdateHF,
} from 'entityModels'
import { updatetHighlightedFactsRequest } from '../../../redux/entities/highlighted_facts/actions/loadingActions'

const dispatchProps = {
    updatetHighlightedFactsRequest: (data: IUpdateHF) =>
        updatetHighlightedFactsRequest(data),
}

function mapStateToProps(state: RootState) {
    return {
        highlighted_facts_data:
            state.entities.highlightedFactsData.highlighted_facts,
        isLoading: state.entities.highlightedFactsData.loading,
    }
}

interface Props {
    highlighted_facts_ids: string[]
    highlighted_facts_data: IHighlightedFactsObject
    isLoading: boolean
    close: any
    updatetHighlightedFactsRequest: (data: IUpdateHF) => void
    car_id: string
}

interface IObject {
    [key: string]: IHighlightedFact
}

type State = {
    newIdsList: string[]
    adapted_data_object: IObject | null
}

class DragNSortEditableManagerMobile extends React.Component<Props, State> {
    state = {
        newIdsList: [],
        adapted_data_object: {},
    }

    componentDidMount() {}

    render() {
        const {
            isLoading,
            close,
            updatetHighlightedFactsRequest,
            highlighted_facts_ids,
            highlighted_facts_data,
            car_id,
        } = this.props

        return (
            <React.Fragment>
                {isLoading !== true &&
                    highlighted_facts_ids.length > 0 &&
                    highlighted_facts_data !== null && (
                        <CarHighlightsEditFormMobile
                            close={close}
                            data_object={highlighted_facts_data}
                            ids_list={highlighted_facts_ids}
                            submitFunc={updatetHighlightedFactsRequest}
                            carid={car_id}
                        />
                    )}
            </React.Fragment>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(DragNSortEditableManagerMobile)
