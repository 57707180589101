import { ISearchUsersResult } from 'entityModels'
import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
// import blank_user from '../../../../public/assets/icons/profile_pic_placeholder.svg'
import blank_user from '../../../../public/assets/placeholders_svgs/avatar.svg'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'
import StatefulCrossToCheck from '../../../atoms/statefulicons/statefulCrossToCheck'
import { device } from '../../../templates/displays/devices'

const Wrapper = styled.div`
    position: relative;
    background-color: var(--bg-color, #fff);
    border: 1px solid var(--);
    box-shadow: 0px 5px 15px rgb(0 0 0 / 7%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 80px;
    cursor: pointer;
    justify-content: space-between;
    padding-right: 30px;
`
const SubWrapper = styled.div<{ hasBg: boolean }>`
    all: unset;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: var(--primary_04);
    transform: ${(props) => (props.hasBg ? 'scale(1, 1)' : 'scale(0, 1)')};
    transform-origin: left;
    transition-duration: 300ms;
    z-index: 0;
`

const UserWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
`

const LeftSide = styled.div`
    display: flex;
    align-items: center;
    z-index: 2;
`

const ProfilePicWrapper = styled.div`
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--border-muted, #e5e5e5);
    margin-left: 20px;
`

const ProfilePic = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--bg-color, #fff);
`

const UserName = styled.div`
    font-size: 14px;
    font-family: Lato;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 150px;

    @media (max-width: 290px) {
        max-width: 100px;
    }

    @media ${device.ipad} {
        max-width: 300px;
    }
`
const UserHandle = styled.div`
    font-size: 12px;
    font-family: Lato;
    color: var(--text-darker, #616161);
    line-height: 22px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 150px;

    @media (max-width: 290px) {
        max-width: 100px;
    }

    @media ${device.ipad} {
        max-width: 300px;
    }
`

const AddIconWrapper = styled(motion.div)`
    height: auto;
    width: 25px;
    height: 25px;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid var(--primary_30);
    border-radius: 50%;
    background-color: var(--bg-color, #fff);
    transition: all 200;
    z-index: 2;
`

interface IItemProps {
    onSearchResultSelect: any
    owner: ISearchUsersResult
    isAdded: boolean
    isLoading: boolean
}

const SearchResultsItemMobile: React.FunctionComponent<IItemProps> = (
    props
) => {
    const { onSearchResultSelect, owner, isAdded, isLoading } = props

    return (
        <Wrapper onClick={onSearchResultSelect}>
            <SubWrapper hasBg={isLoading || isAdded} />

            <LeftSide>
                <ProfilePicWrapper>
                    <ProfilePic
                        src={
                            owner.profile_image_url
                                ? owner.profile_image_url
                                : blank_user
                        }
                    />
                </ProfilePicWrapper>
                <UserWrapper>
                    <UserName>
                        {`${owner.given_name} ${owner.family_name}`}
                    </UserName>
                    <UserHandle>{owner.display_name}</UserHandle>
                </UserWrapper>
            </LeftSide>
            {isLoading && !isAdded ? (
                <LoaderElasticThreeDots />
            ) : (
                <AddIconWrapper
                    whileTap={isAdded ? { scale: 1 } : { scale: 1.2 }}
                >
                    <StatefulCrossToCheck isChecked={isAdded} />
                </AddIconWrapper>
            )}
        </Wrapper>
    )
}

export default SearchResultsItemMobile
