import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { PropsWithChildren } from 'react'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import InfoIcon from '../../atoms/icons/infoIcon'

type IStyle = {
    $theme: ITheme
}

export const ModalTitle = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato;
    font-size: 18px;
    text-transform: capitalize;
    padding-bottom: 16px;

    @media ${device.mobile} {
        font-size: 16px;
    }
`

export const ModalTopBar = styled.div<IStyle>`
    font-family: Lato;
    width: 100%;
    max-height: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${(props) => colours[props.$theme].background_muted};
    color: var(--text-strong, #1a1a1a);
    display: flex;
    align-items: center;
    padding-left: 24px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid ${(props) => colours[props.$theme].border_muted};

    span {
        &:first-letter {
            text-transform: uppercase;
        }
    }

    @media ${device.mobile} {
        height: 40px;
        font-size: 12px;
    }

    @media ${device.beyond_ipad_mobile} {
        background-color: transparent;
        border-bottom: 1px solid
            ${(props) => colours[props.$theme].border_muted};
        padding-left: 24px;
    }
`

export const ModalContentDesktop = styled.section<IStyle>`
    display: none;
    overflow: visible;
    max-height: 90vh;
    transition: all 200;

    @media ${device.beyond_ipad_mobile} {
        margin-top: 12vh;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        width: 100%;
        max-width: 600px;
        background-color: ${(props) =>
            colours[props.$theme].modal_side_sheet_pop_over};
        color: var(--text-strong, #1a1a1a);
        border-radius: 4px;
        position: relative;
        margin-bottom: 50px;
    }

    @media ${device.large_desktop} {
        margin-top: 12vh;
    }
`

export const ModalInnerContentWrapperDesktop = styled.div`
    padding: 32px;
    width: 100%;
`

export const ModalContentMobile = styled.section<IStyle>`
    overflow: visible;
    /* margin-inline: 16px; */
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    max-width: 600px;
    /* padding-top: 8px; */
    max-height: 95vh;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    color: var(--text-strong, #1a1a1a);
    border-radius: 4px;
    position: relative;

    @media (max-width: 600px) {
        max-width: 90vw;
    }
`

export const ModalInnerContentWrapperMobile = styled.div`
    padding: 24px;
    width: 100%;
`

export const NoticeWrapper = styled.div<IStyle>`
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    align-items: flex-start;
`

const NoticeText = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 14px;
    font-family: 'Lato';
    margin-left: 16px;
    @media ${device.mobile} {
        font-size: 14px;
    }

    @media (max-width: 350px) {
        line-height: 19px;
    }
`

export const Notice = (props: PropsWithChildren<{}>) => {
    const { theme } = useThemes()
    return (
        <NoticeWrapper $theme={theme}>
            <InfoIcon size={'26'} />
            <NoticeText $theme={theme}>{props.children}</NoticeText>
        </NoticeWrapper>
    )
}
