import * as React from 'react'
import { DatePicker } from 'antd'
import styled from 'styled-components'
import './style.css'
import moment from 'moment'
import dayjs from 'dayjs'
import { RangePickerProps } from 'antd/lib/date-picker'

// interface IStyle {}

const Wrapper = styled.div<{ $additionaSlyle: string }>`
    .ant-picker {
        padding-right: 25px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.09);
        background-color: var(--bg-color, #fff);
        ${(props) => props.$additionaSlyle}
        :hover {
            border-color: var(--primary, #5ec3ca);
            border-radius: 3px;
        }
    }
    .ant-picker-focused {
        border-color: var(--primary, #5ec3ca);
        box-shadow: 0 0 0 2px rgb(0 169 175 / 20%);
    }
`

type Props = {
    onChange: (dateString: any) => any
    value?: any
    disabled?: boolean
    readOnlyMode?: boolean
    additionalStyle?: any
    suffixIcon?: React.ReactNode
    allowClear?: boolean
    clearIcon?: React.ReactNode
    customFormat?: string[]
    allowOnlyPast?: boolean
}

class DatePickerAntd extends React.Component<Props> {
    disabledDate: RangePickerProps['disabledDate'] = (current) => {
        // Can not select days after today
        return current && current > dayjs().endOf('day')
    }
    render() {
        return (
            <Wrapper $additionaSlyle={this.props.additionalStyle}>
                <DatePicker
                    popupClassName="ant_date_dropdown_custom_today_text"
                    format={
                        this.props.customFormat ?? [
                            'DD/MM/YYYY',
                            'DD/MM/YY',
                            'DD-MM-YYYY',
                            'DD-MM-YY',
                        ]
                    }
                    dateRender={(currentDate, today) => {
                        const style: any = {}

                        if (currentDate.dayOfYear() === today.dayOfYear()) {
                            style.border = '1px solid var(--primary, #5ec3ca)'
                            style.borderRadius = '2px'
                        }
                        return (
                            <div
                                className=".ant-picker-cell-inner"
                                style={style}
                            >
                                {currentDate.date()}
                            </div>
                        )
                    }}
                    disabledDate={
                        this.props.allowOnlyPast ? this.disabledDate : undefined
                    }
                    onChange={(date: any, dateStr: any) => {
                        this.props.onChange(date)
                    }}
                    value={
                        this.props.value && moment(new Date(this.props.value))
                    }
                    disabled={this.props.disabled}
                    inputReadOnly={this.props.readOnlyMode}
                    popupStyle={{
                        display: this.props.readOnlyMode ? 'none' : 'auto',
                    }}
                    suffixIcon={this.props.suffixIcon}
                    allowClear={this.props.allowClear}
                    clearIcon={this.props.clearIcon}
                />
            </Wrapper>
        )
    }
}

export default DatePickerAntd
