import { IHandover } from 'entityModels'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export type IHandoverListPayload = {
    count: number
    handovers: IHandover[]
}

export type IHandoverListPayload_req = {
    offset?: number
    states?: ('INITIATED' | 'PROCESSING' | 'COMPLETED' | 'FAILED')[] // (default is "PROCESSING", "FAILED")
    limit?: number
    as?: 'owner' | 'recipient'
}

export const ConvertListHandoversAPIReqToURL = (
    p: IHandoverListPayload_req
): string => {
    let url = `?offset=${p.offset ?? 100}&limit=${p.limit ?? 0}`
    if (p.states) {
        // (if not default is "PROCESSING", "FAILED")
        p.states.forEach(
            (item: 'INITIATED' | 'PROCESSING' | 'COMPLETED' | 'FAILED') => {
                url = url + `&states=${item}`
            }
        )
    }

    return url
}

export const list_user_handovers_api = (
    p: IHandoverListPayload_req
): Promise<IHandoverListPayload> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    let urlBuilt = ConvertListHandoversAPIReqToURL(p)

    return fetch(urls.list_handovers_endpoint(urlBuilt), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: { data: IHandoverListPayload }) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'List user handovers error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSend: IHandoverListPayload = data.data
                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
