import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { menuActions } from '../../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import React from 'react'
import InsuranceApplicationCardDesktop from '../../../molecules/cards/insurance/insuranceApplicationCardDesktop'
import InsuranceTopBarWithProgressionRate from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceGoBackReusableBottomBar from '../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceApplicationCardMobile from '../../../molecules/cards/insurance/insuranceApplicationCardMobile'
import InsuranceTopBarWithProgressionRateMobile from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import InsuranceApplicationCardContentManager from '../../../organisms/InsuranceApplicationCardContentManager/InsuranceApplicationCardContentManager'
import {
    IAnyErrorString,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_OtherDriver,
    insuranceActions,
    IOtherDriver_DriveList,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { IDriversActiveModal } from './insuranceApplicationDriversDashboard'
import RightSideModalDisplay from '../../../templates/displays/pageWrappers/rightSideModalDisplay'
import DriversModalManager from '../../../organisms/insuranceQuote/modalManagers/driversModalManager'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../redux/store'
import { IInsuranceApplicationSectionCard } from './insuranceApplicationDashboard'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type UserIDParams = {
    userid: string
}

const InsuranceApplicationOtherDriverDashboard = () => {
    const history = useHistory()

    const { userid } = useParams<UserIDParams>()

    let carsData = useAppSelector((state) => state.entities.carsData.cars)

    const other_drivers = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.other_drivers
    )

    const other_drivers_draft = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft.other_drivers
    )

    let currentDriver =
        userid && other_drivers && other_drivers[userid]
            ? other_drivers[userid]
            : undefined

    const [activeModal, toggleActiveModal] =
        useState<IDriversActiveModal>(undefined)

    const other_driver_cards: IInsuranceApplicationSectionCard[] = [
        {
            id: 'basic_details_other_driver',
            sectionID: 'drivers',
            title: 'Basic details',
            text: `Provide essential information about yourself, including your name, date of birth, and contact details. These details help us identify and communicate with you effectively throughout the insurance process.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [
                currentDriver?.basic_details_stats ?? 0,
            ],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/other_driver/${userid}/basic_details`
                )
            },
        },
        {
            id: 'driver_license_address',
            sectionID: 'drivers',
            title: `Driver licence`,
            text: `Provide your driver's licence information and address.`,
            isDisabled: false,
            btnTxt: 'Fill in details',
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [
                currentDriver?.licence_address_stats ?? 0,
            ],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/other_driver/${userid}/licence_address`
                )
            },
        },
        {
            id: 'additional_details',
            sectionID: 'drivers',
            title: `Additional details`,
            text: `Share additional information that can help us customize your insurance coverage. This may include details about homeownership, occupation, or any relevant memberships you hold.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [
                currentDriver?.additional_details_stats ?? 0,
            ],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/other_driver/${userid}/additional_details`
                )
            },
        },
        {
            id: 'track_record',
            sectionID: 'drivers',
            title: `Track record`,
            text: `Provide details about your driving history, including any previous claims, convictions, or driving penalties.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [currentDriver?.track_record_stats ?? 0],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/other_driver/${userid}/track_record`
                )
            },
        },
        {
            id: 'other_driver_drive_list',
            sectionID: 'drivers',
            title: `Drive list`,
            text: `If there are multiple cars listed on the policy, indicate which cars are assigned to the other driver. This ensures that each driver has access only to the cars they are authorized to use.`,
            isDisabled: false,
            btnTxt: `Assign cars`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [],
            onClick: () => {
                setActiveDriverID(currentDriver?.id)
                toggleActiveModal({
                    active_id: 'drive_list',
                    title: 'Drive list',
                })
            },
        },
    ]

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()
    let {
        getUserAndApplicationData,
        getInitialSectionWizardIds,
        getFirstIncompleteSubsection_OtherDriver,
    } = useSetInitialInsurance()
    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const [activeDriverID, setActiveDriverID] = useState<string | undefined>(
        undefined
    )

    const [errorObj, setErrorObj] = useState<IAnyErrorString | undefined>(
        undefined
    )

    const frequencyErrorMessage = 'Use frequency is mandatory to fill in.'

    const isFrequenciesSaveAllowed = (): true | false => {
        let res: boolean = true

        let otherdriverObj: IInsuranceQuoteApplication_OtherDriver =
            other_drivers_draft[`${activeDriverID}`]
        if (other_drivers_draft && activeDriverID && otherdriverObj) {
            if (otherdriverObj.drive_list) {
                for (let a = 0; a < otherdriverObj.drive_list.length; a++) {
                    let drivelistItem = otherdriverObj.drive_list[a]

                    if (!drivelistItem.use_frequency) {
                        res = false
                    }
                }
            }
        }

        setErrorObj(
            res !== true ? { use_frequency: frequencyErrorMessage } : undefined
        )

        return res
    }

    let vehicles_to_insure_ids_list = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data
                .vehicles_to_insure_ids_list
    )

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let getToAny = () => {
        let ids2 = getInitialSectionWizardIds()

        if (ids2) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids2.sectionID}`
            if (ids2.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids2.sub_sectionID}`
            }
            if (ids2.car_id) {
                url1 = url1 + `&car_id=${ids2.car_id}`
            }
            if (ids2.driver_id) {
                url1 = url1 + `&driver_id=${ids2.driver_id}`
            }
            history.push(url1)
        } else {
            if (ids2 === null) {
                return
                // history.push(
                //     `/insurance/application/${applicationData?.id}`
                // )
            }
        }
    }

    let goToWizard = () => {
        let ids = getFirstIncompleteSubsection_OtherDriver(userid)
        if (ids) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids.sectionID}`
            if (ids.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids.sub_sectionID}`
            }
            if (ids.car_id) {
                url1 = url1 + `&car_id=${ids.car_id}`
            }
            if (ids.driver_id) {
                url1 = url1 + `&driver_id=${ids.driver_id}`
            }
            return history.push(url1)
        } else {
        }
        return getToAny()
    }

    let isDraft = applicationData.status === 'DRAFT' ? true : false
    const { theme } = useThemes()

    return userLoggedIn ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    progressionRate={
                        applicationData?.other_drivers?.[userid]?.completion
                    }
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Named driver' },
                    ]}
                    sectionId="drivers"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    {other_driver_cards.map(
                        (card: IInsuranceApplicationSectionCard) => {
                            return (
                                <React.Fragment key={card.id}>
                                    <InsuranceApplicationCardDesktop
                                        cardProps={card}
                                    >
                                        <InsuranceApplicationCardContentManager
                                            card={card}
                                            otherDriver={currentDriver}
                                            applicationID={id}
                                        />
                                    </InsuranceApplicationCardDesktop>
                                </React.Fragment>
                            )
                        }
                    )}
                </div>
                <InsuranceGoBackReusableBottomBar
                    goBack={() => history.push(`/insurance/application/${id}`)}
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    progressionRate={
                        applicationData?.other_drivers?.[userid]?.completion
                    }
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Named driver' },
                    ]}
                    sectionId={'drivers'}
                />
                {!activeModal && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            paddingTop: 120,
                            paddingBottom: 120,
                            paddingLeft: 24,
                            paddingRight: 24,
                        }}
                    >
                        {other_driver_cards.map(
                            (card: IInsuranceApplicationSectionCard) => {
                                return (
                                    <React.Fragment key={card.id}>
                                        <InsuranceApplicationCardMobile
                                            cardProps={card}
                                        >
                                            <InsuranceApplicationCardContentManager
                                                card={card}
                                                isMobile
                                                otherDriver={currentDriver}
                                                applicationID={id}
                                            />
                                        </InsuranceApplicationCardMobile>
                                    </React.Fragment>
                                )
                            }
                        )}
                    </div>
                )}
                <InsuranceGoBackReusableBottomBar
                    isMobile
                    goBack={() => history.push(`/insurance/application/${id}`)}
                />
            </IpadAndMobileDisplay>
            {activeModal && currentDriver && (
                <RightSideModalDisplay
                    sectionId={'drivers'}
                    isOpen={activeModal.active_id ? true : false}
                    cancel={() => {
                        // remove_initialized_new_other_driver
                        let savedID = activeDriverID
                        if (savedID) {
                            dispatch(
                                insuranceActions.remove_other_driver_edits(
                                    `${savedID}`
                                )
                            )
                        }

                        if (
                            activeModal.active_id === 'drive_list' &&
                            activeDriverID
                        ) {
                            dispatch(
                                insuranceActions.reset_other_driver_drive_list({
                                    userid: activeDriverID,
                                })
                            )
                        }

                        setTimeout(() => {
                            toggleActiveModal(undefined)
                            setActiveDriverID(undefined)
                        }, 100)
                    }}
                    title={activeModal?.title}
                    footerBtn={
                        vehicles_to_insure_ids_list.length > 0
                            ? {
                                  action: () => {
                                      if (
                                          activeDriverID !== undefined &&
                                          isFrequenciesSaveAllowed() === true
                                      ) {
                                          dispatch(
                                              insuranceActions.submit_otherDriver_driveList_request(
                                                  { driver_id: activeDriverID }
                                              )
                                          )

                                          setTimeout(() => {
                                              toggleActiveModal(undefined)
                                              setActiveDriverID(undefined)
                                          }, 200)
                                      }
                                  },
                                  text: 'Add',
                              }
                            : undefined
                    }
                >
                    <DriversModalManager
                        errorObj={errorObj}
                        all_cars={carsData}
                        active_modal={activeModal.active_id}
                        driverID={activeDriverID}
                        updateDriver={(
                            id: 'drive_list' | 'details' | string,
                            value: IOtherDriver_DriveList[]
                        ) => {
                            if (id === 'drive_list' && activeDriverID) {
                                dispatch(
                                    insuranceActions.set_answer_other_driver_drive_list(
                                        {
                                            id: 'drive_list',
                                            userid: activeDriverID,
                                            answer: value,
                                        }
                                    )
                                )
                            } else if (activeDriverID) {
                                dispatch(
                                    insuranceActions.set_answer_other_driver_drive_list(
                                        {
                                            id: id,
                                            userid: activeDriverID,
                                            answer: value,
                                        }
                                    )
                                )
                            }
                        }}
                    />
                </RightSideModalDisplay>
            )}
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationOtherDriverDashboard
