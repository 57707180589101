import { call, fork, put, select, take } from 'redux-saga/effects'

import * as requestActions from '../actions/loadingActions'
import * as successActions from '../actions/actions'
import { RootState } from 'typesafe-actions'
import {
    ICar,
    IDirectShareOwnedItem,
    IDirectShareOwnedItemsData,
    ISharingPermissionIDS,
} from 'entityModels'
import { sortSharesByAlphabetical } from '../../../../helpers/sort/sortByAlphabetical'
import {
    sortSharesByCreatedAt,
    sortSharesByUpdatedAt,
} from '../../../../helpers/sort/sortByDate'
import Fuse from 'fuse.js'

let getCarsData = (state: RootState) => state.entities.carsData.cars

function* changeSortCarSharesDisplay(
    payload: requestActions.ISortCarSharesDisplay_change_request
): any {
    let carsData: { [key: string]: ICar } = yield select(getCarsData)

    let car: ICar = carsData[payload.carid]

    let currentCarShareItemsDisplay: string[] | undefined =
        car.private_shares_owned &&
        car.private_shares_owned.active_display &&
        car.private_shares_owned.active_display.item_ids_display
            ? car.private_shares_owned.active_display.item_ids_display
            : car.private_shares_owned?.entity_owned_direct_shares_ids

    // TODO do thing to sort items with currentCarShareItemsDisplay. For now:
    let items_new: string[] = currentCarShareItemsDisplay
        ? currentCarShareItemsDisplay
        : []

    if (
        payload.sortID === 'alphabetical' &&
        currentCarShareItemsDisplay &&
        car.private_shares_owned
    ) {
        items_new = sortSharesByAlphabetical(
            currentCarShareItemsDisplay,
            car.private_shares_owned.entity_owned_direct_shares_data
        )
    }

    if (
        payload.sortID === 'created_at' &&
        currentCarShareItemsDisplay &&
        car.private_shares_owned
    ) {
        items_new = sortSharesByCreatedAt(
            currentCarShareItemsDisplay,
            car.private_shares_owned.entity_owned_direct_shares_data
        )
    }

    if (
        payload.sortID === 'updated_at' &&
        currentCarShareItemsDisplay &&
        car.private_shares_owned
    ) {
        items_new = sortSharesByUpdatedAt(
            currentCarShareItemsDisplay,
            car.private_shares_owned.entity_owned_direct_shares_data
        )
    }

    let p: successActions.ISortCarSharesDisplay_change_success = {
        carid: payload.carid,
        sortID: payload.sortID,
        item_ids_display: items_new,
    }
    yield put(successActions.sortCarSharesDisplay_change_success(p))
}

function* watcherChangeSortCarShareDisplay() {
    while (true) {
        const { payload } = yield take(
            requestActions.sortCarSharesDisplay_change_request
        )

        yield call(changeSortCarSharesDisplay, payload)
    }
}

function* changeFilterCarSharesDisplay(
    payload: requestActions.IFilterCarSharesDisplay_change_request
): any {
    let carsData: { [key: string]: ICar } = yield select(getCarsData)

    let all_ids: string[] | undefined =
        carsData[payload.carid].private_shares_owned &&
        carsData[payload.carid].private_shares_owned
            ?.entity_owned_direct_shares_ids
            ? carsData[payload.carid].private_shares_owned
                  ?.entity_owned_direct_shares_ids
            : []

    // TODO do thing to sort items with currentCarShareItemsDisplay. For now:
    const current_items_ids: string[] = all_ids ? all_ids : []

    let new_items_ids: string[] = []

    const data: IDirectShareOwnedItemsData | undefined =
        carsData[payload.carid].private_shares_owned
            ?.entity_owned_direct_shares_data

    if (data && payload.filterIDs.length > 0) {
        let boolArr: boolean[] = current_items_ids.map((id: string) =>
            payload.filterIDs.some((pid: ISharingPermissionIDS) => {
                return data[id].active_permission_ids.includes(pid)
            })
        )

        if (boolArr.includes(true)) {
            boolArr.forEach((boo: boolean, index: number) => {
                if (boo === true) {
                    new_items_ids = [...new_items_ids, current_items_ids[index]]
                }
            })
        }
    } else {
        new_items_ids = all_ids ? all_ids : []
    }

    let p: successActions.IFilterCarSharesDisplay_change_success = {
        carid: payload.carid,
        filterIDs: payload.filterIDs,
        item_ids_display: new_items_ids,
    }
    yield put(successActions.filterCarSharesDisplay_change_success(p))
}

function* watcherChangeFilterCarShareDisplay() {
    while (true) {
        const { payload } = yield take(
            requestActions.filterCarSharesDisplay_change_request
        )

        yield call(changeFilterCarSharesDisplay, payload)
    }
}

function* changeSearchQueryCarSharesDisplay(
    payload: requestActions.ISearchQueryCarSharesDisplay_change_request
): any {
    let carsData: { [key: string]: ICar } = yield select(getCarsData)

    let sharesIDS =
        carsData[payload.carid].private_shares_owned
            ?.entity_owned_direct_shares_ids

    // TODO do thing to sort items with currentCarShareItemsDisplay. For now:
    let current_items: string[] = sharesIDS ? sharesIDS : []

    let items_array = current_items.map((id: string) => {
        return carsData[payload.carid].private_shares_owned
            ?.entity_owned_direct_shares_data[id]
    })

    const options = {
        includeScore: true,
        // Search in `full name` and in `email` array
        keys: ['email', 'user_name'],
    }

    const fuse = new Fuse(items_array, options)

    const result: Fuse.FuseResult<IDirectShareOwnedItem | undefined>[] =
        fuse.search(payload.searchQuery)

    current_items = result.map((obj: Fuse.FuseResult<any>) => obj.item.id)

    let p: successActions.ISearchQueryCarSharesDisplay_change_success = {
        carid: payload.carid,
        searchQuery: payload.searchQuery,
        item_ids_display: current_items,
    }
    yield put(successActions.searchQueryCarSharesDisplay_change_success(p))
}

function* watcherChangeSearchQueryCarShareDisplay() {
    while (true) {
        const { payload } = yield take(
            requestActions.searchQueryCarSharesDisplay_change_request
        )

        yield call(changeSearchQueryCarSharesDisplay, payload)
    }
}

function* resetSearchQueryCarSharesDisplay(carid: string): any {
    yield put(successActions.resetSearchQueryCarShareDisplay_success(carid))
}

function* watcherResetSearchQueryCarShareDisplay() {
    while (true) {
        const { payload } = yield take(
            requestActions.resetSearchQueryCarShareDisplay_request
        )

        yield call(resetSearchQueryCarSharesDisplay, payload)
    }
}

const handle_car_shares_display_change: any[] = [
    fork(watcherChangeSortCarShareDisplay),
    fork(watcherChangeFilterCarShareDisplay),
    fork(watcherChangeSearchQueryCarShareDisplay),
    fork(watcherResetSearchQueryCarShareDisplay),
]

export default handle_car_shares_display_change
