import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { setCurrentExternalCar } from '../../../../redux/entities/cars/actions/thunks'

import { IExternalCarsObject } from 'entityModels'
import { getExternalCarsSharedWithYouREQUEST } from '../../../../redux/entities/cars/actions/loadingActions'
import { IUser } from 'myModels'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCarGalleryImage from '../../../templates/readOnly/car/readOnlyGalleryImage'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    externalCarsData: IExternalCarsObject | undefined
    setCurrentExternalCar: (carid: string) => void
    getExternalCarsSharedWithYouREQUEST: (car_id: string) => void
    setInitialLandingUrl: (str: string) => void
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentExternalCar: (car_id: string) => setCurrentExternalCar(car_id),
    getExternalCarsSharedWithYouREQUEST: (car_id: string) =>
        getExternalCarsSharedWithYouREQUEST(car_id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
    }
}

class ExternalCarGalleryImage extends React.Component<Props, {}> {
    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        let params = new URLSearchParams(this.props.location.search)
        let image_id_param = params.get('id')
        let carid = this.props.match.params.carid

        this.props.setInitialLandingUrl(
            `/shared/car/${carid}/gallery?id=${image_id_param}`
        )

        this.props.setCurrentExternalCar(carid)
        this.props.getExternalCarsSharedWithYouREQUEST(carid)
    }

    render() {
        const { externalCarsData } = this.props
        let carid = this.props.match.params.carid

        let current = externalCarsData && carid && externalCarsData[carid]

        return <ReadOnlyCarGalleryImage sharedCar={current} />
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ExternalCarGalleryImage)
)
