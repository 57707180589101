// ApexTeamCreditsDesktop

import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'

const Wrapper = styled.div`
    font-family: Lato-light;
    color: var(--text-strong, #1a1a1a);
    font-size: 17px;
    padding-bottom: 20px;
    line-height: 32px;
    max-width: 56vw;

    @media ${device.large_desktop} {
        max-width: 38vw;
    }
`

type Props = {
    text: string
}

class ApexTeamCreditsDesktop extends React.Component<Props, {}> {
    render() {
        return <Wrapper>{this.props.text}</Wrapper>
    }
}

export default ApexTeamCreditsDesktop
