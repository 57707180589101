import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    width?: string
    height?: string
    color?: string
    strokeColor?: string
}

const DrivingObstacle = ({ width, height, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={width ?? '25'}
            height={height ?? '24'}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.0691 19.0857C4.36229 18.8434 4.75121 18.6414 5.21429 18.6414H19.7857C20.2488 18.6414 20.6377 18.8434 20.9309 19.0857C21.2236 19.3275 21.4612 19.6407 21.646 19.9577C22.0082 20.579 22.25 21.3603 22.25 22.0001C22.25 22.4143 21.9142 22.7501 21.5 22.7501H3.5C3.08579 22.7501 2.75 22.4143 2.75 22.0001C2.75 21.3603 2.99182 20.579 3.35403 19.9577C3.53882 19.6407 3.77645 19.3275 4.0691 19.0857ZM4.40284 21.2501H20.5972C20.5334 21.07 20.4506 20.8855 20.3501 20.7131C20.2247 20.498 20.093 20.3391 19.9754 20.242C19.8584 20.1453 19.7962 20.1414 19.7857 20.1414H5.21429C5.20379 20.1414 5.14163 20.1453 5.02461 20.242C4.90704 20.3391 4.77529 20.498 4.6499 20.7131C4.54941 20.8855 4.46656 21.07 4.40284 21.2501Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.4951 1.78732C10.5894 1.46866 10.882 1.25 11.2143 1.25H13.7858C14.1181 1.25 14.4108 1.46866 14.505 1.78732L19.6478 19.1786C19.7653 19.5758 19.5385 19.9931 19.1413 20.1105C18.7441 20.228 18.3269 20.0012 18.2094 19.604L13.2255 2.75H11.7747L6.7907 19.604C6.67324 20.0012 6.25601 20.228 5.8588 20.1105C5.46159 19.9931 5.23481 19.5758 5.35227 19.1786L10.4951 1.78732Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.60693 15.9131C6.60693 15.4989 6.94272 15.1631 7.35693 15.1631H17.6426C18.0569 15.1631 18.3926 15.4989 18.3926 15.9131C18.3926 16.3273 18.0569 16.6631 17.6426 16.6631H7.35693C6.94272 16.6631 6.60693 16.3273 6.60693 15.9131Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.46436 13.3044C7.46436 12.8902 7.80014 12.5544 8.21436 12.5544H16.7858C17.2 12.5544 17.5358 12.8902 17.5358 13.3044C17.5358 13.7187 17.2 14.0544 16.7858 14.0544H8.21436C7.80014 14.0544 7.46436 13.7187 7.46436 13.3044Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.75 8.95654C8.75 8.54233 9.08579 8.20654 9.5 8.20654H15.5C15.9142 8.20654 16.25 8.54233 16.25 8.95654C16.25 9.37076 15.9142 9.70654 15.5 9.70654H9.5C9.08579 9.70654 8.75 9.37076 8.75 8.95654Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.60693 6.3479C9.60693 5.93369 9.94272 5.5979 10.3569 5.5979H14.6426C15.0569 5.5979 15.3926 5.93369 15.3926 6.3479C15.3926 6.76211 15.0569 7.0979 14.6426 7.0979H10.3569C9.94272 7.0979 9.60693 6.76211 9.60693 6.3479Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default DrivingObstacle
