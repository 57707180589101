import {
    ILoginPayloadResAPI,
    IRegisterData,
    ILoginPayload,
} from 'IapiDataPayload'
import { IUser } from 'myModels'
import { call, fork, put, select, take } from 'redux-saga/effects'

import * as userActions from '../actions/index'

import { IReduxError } from 'entityModels'

import { api } from '../../services'

import { RootState } from 'typesafe-actions'

import { push } from 'connected-react-router'

import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'

import { customNavDataActions } from '../../localdata/customNav/reducer'
import { registerError, signInUserError } from '../actions/errorActions'
import { getCurrentUserDataSaga } from './getUserData'
import { ISignInPayloadReq } from 'ApiInterfaces'
import {
    generateFBC,
    generateFBP,
    get_check_cookie_name,
    setCookie,
} from '../../../helpers/cookies'
import { GetUserIPAndCallFbTracking } from '../../../helpers/ipAddress'
import { IAuthSocialRequestPayload } from './socialAuth'

export const state_select_initial_landing_url = (
    state: RootState
): string | null => state.localdata.customNavData.initial_landing_url

const state_select_initial_external_landing_url = (
    state: RootState
): string | null => state.localdata.customNavData.initial_external_landing_url

const state_select_user = (state: RootState): IUser | null =>
    state.user.userLoggedIn

export function* signInSaga(p: ISignInPayloadReq): any {
    try {
        let lowerCaseEmail = p.email.toLowerCase()

        p.email = lowerCaseEmail

        let api_payload: ILoginPayload = { ...p }

        const userData: ILoginPayloadResAPI = yield call(
            api.authentication.login,
            api_payload
        )

        // set data in localStorage
        let garage_id = userData.garage_id

        let refresh_token = userData.refresh_token

        window?.localStorage?.setItem('refresh_token', refresh_token)

        if (garage_id) {
            window?.localStorage?.setItem('garage_id', garage_id)
        }

        // Identify user with internal ID
        posthog.identify(userData.user_id)
        // Capture event
        posthog.capture('USER LOGIN')

        yield call(getCurrentUserDataSaga)

        // Set email or any other data
        if (posthog.people !== undefined) {
            let udata = yield select(state_select_user)
            posthog.people.set_once({
                email: p.email.toLowerCase(),
                given_name: udata && udata.given_name && udata.given_name,
                family_name: udata && udata.family_name && udata.family_name,
            })
        }

        let initial_landing_url: string | null = yield select(
            state_select_initial_landing_url
        )

        let initial_external_landing_url: string | null = yield select(
            state_select_initial_external_landing_url
        )
        yield put(userActions.default.actions.signInUserSuccess())

        if (initial_landing_url) {
            const url = initial_landing_url
            yield put(customNavDataActions.setInitialLandingUrl(null))

            yield put(push(url))
        } else if (initial_external_landing_url) {
            const url = initial_external_landing_url
            yield put(customNavDataActions.setInitialExternalLandingUrl(null))

            window.location.href = url
        } else {
            yield put(push('/garage'))
        }
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)

        yield put(signInUserError(typedError))
    }
}

function* watcherSignIn() {
    while (true) {
        const { payload } = yield take(
            userActions.default.loadingActions.signInUserRequest
        )

        yield call(signInSaga, payload)
    }
}

export type IRegisterPayloadReq = {
    firstName: string
    lastName: string
    email: string
    password: string
    platformConsent: boolean
    apexConsent: boolean
    fbclid: string | null
    invite_code?: string | undefined
    next_path?: string | null
}

type IRegistrationResPayload = {
    user_id: string
    refresh_token: string
}

export const sortOutFbTracking = (
    userData: IRegistrationResPayload,
    p: IRegisterPayloadReq & IAuthSocialRequestPayload,
    fbclidCookie: any,
    ip?: string | undefined
) => {
    let FBP = get_check_cookie_name('_fbp')
    let FBC = get_check_cookie_name('_fbc')

    const ua =
        !!window && window.navigator && window.navigator.userAgent
            ? window.navigator.userAgent
            : undefined

    if (
        userData &&
        (p.fbclid || FBP || FBC) &&
        process.env.REACT_APP_ENV !== 'local' &&
        process.env.REACT_APP_ENV !== 'development' &&
        process.env.REACT_APP_ENV !== 'preview'
    ) {
        const timeNow = Date.now()

        if (!FBC && p.fbclid) {
            FBC = generateFBC(p.fbclid, timeNow)
            setCookie({
                name: '_fbc',
                value: FBC,
                days: 180,
            })
        }

        if (!FBP && p.fbclid) {
            FBP = generateFBP(timeNow)
            setCookie({
                name: '_fbp',
                value: FBP,
                days: 180,
            })
        }

        let signup_obj: any = {
            event: 'sign_up',
            first_party_collection: true,
            // external_id: userData.user_id,
            event_id: userData.user_id,

            fbclid: p.fbclid
                ? p.fbclid
                : fbclidCookie
                ? fbclidCookie
                : undefined,
            fbc: FBC,
            fbp: FBP,
            user_agent: ua,
        }

        if (p.email) {
            signup_obj = {
                ...signup_obj,
                email_address: p.email ? p.email.toLowerCase() : '',
            }
        }

        if (p.firstName) {
            signup_obj = {
                ...signup_obj,
                first_name: p.firstName,
            }
        }

        if (p.lastName) {
            signup_obj = {
                ...signup_obj,
                last_name: p.lastName,
            }
        }
        window && window.dataLayer && window.dataLayer.push(signup_obj)
    } else {
        if (
            userData &&
            process.env.REACT_APP_ENV !== 'local' &&
            process.env.REACT_APP_ENV !== 'development' &&
            process.env.REACT_APP_ENV !== 'preview'
        ) {
            window &&
                window.dataLayer &&
                window.dataLayer.push({
                    event: 'sign_up',
                    first_party_collection: true,
                    // external_id: userData.user_id,
                    event_id: userData.user_id,
                    email_address: p.email?.toLowerCase(),
                    first_name: p.firstName,
                    last_name: p.lastName,
                    user_agent: ua,
                })
        }
    }
}

export const postRegisterFunc = () => {}

export function* registerSaga(p: IRegisterPayloadReq): any {
    try {
        // 1. TRIM INVITE CODE
        // if (p.invite_code !== undefined) {
        //     p.invite_code = trimInviteCode(p.invite_code)
        // }

        // 2 GET RID OF UNDEFINED (CLEAN DATA)

        let registerData: IRegisterData = {
            given_name: p.firstName,
            family_name: p.lastName,
            email: p.email.toLowerCase(),
            password: p.password,
            consent: {
                platform: p.platformConsent,
                apex: p.apexConsent,
            },
        }

        // invite_code: p.invite_code,
        const fbclidCookie: string | undefined = get_check_cookie_name('fbclid')
        if (p.fbclid !== null || fbclidCookie !== undefined) {
            registerData.tracking_codes = [
                {
                    id: 'fbclid',
                    value: p.fbclid
                        ? p.fbclid
                        : fbclidCookie
                        ? fbclidCookie
                        : undefined,
                },
            ]
        }

        const userData: IRegistrationResPayload = yield call(
            api.authentication.register,
            registerData
        )

        // START

        const sortOutFB = (ip?: string | undefined) =>
            // @ts-ignore
            sortOutFbTracking(userData, p, fbclidCookie, ip)
        GetUserIPAndCallFbTracking(sortOutFB)
        // END IS HERE

        let refresh_token = userData.refresh_token

        window.localStorage.setItem('refresh_token', refresh_token)

        // Identify user with internal ID
        posthog.identify(userData.user_id)
        // Capture event
        posthog.capture('USER REGISTRATION')

        yield call(getCurrentUserDataSaga)

        yield put(userActions.default.actions.registerSuccess())

        // Set email or any other data
        if (posthog.people !== undefined) {
            let udata = yield select(state_select_user)
            posthog.people.set_once({
                email: p.email.toLowerCase(),
                given_name: udata && udata.given_name && udata.given_name,
                family_name: udata && udata.family_name && udata.family_name,
            })
        }

        let initial_landing_url: string | null = yield select(
            state_select_initial_landing_url
        )

        if (initial_landing_url) {
            const url = initial_landing_url
            yield put(customNavDataActions.setInitialLandingUrl(null))
            yield put(push(url))
        } else {
            yield put(push(p.next_path ? p.next_path : '/onboarding-step-1'))
        }
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)

        yield put(registerError(typedError))
    }
}

function* watcherRegister() {
    while (true) {
        const { payload } = yield take(
            userActions.default.loadingActions.registerRequest
        )

        yield call(registerSaga, payload)
    }
}

const auth_sagas: any[] = [fork(watcherSignIn), fork(watcherRegister)]

export default auth_sagas
