import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { showroomActions } from '../reducer'
import { RootState } from 'typesafe-actions'
import { IShowroomEntry } from '../types'
import { GetShowroomEntryByID } from './getShowroomEntry'

export const state_select_normalised_entries = (state: RootState) =>
    state.showroom.normalisedPublicEntries

export function* RemoveEntryFromWatchlist(entryUID: string): any {
    try {
        yield call(api.showroom.removeFromWatchlist, entryUID)
        yield put(showroomActions.removeEntryFromWatchlistSuccess(entryUID))
        // here, pass the results and thats it

        let normalised_entries = yield select(state_select_normalised_entries)

        let entry_normalised: IShowroomEntry | undefined =
            normalised_entries && normalised_entries[entryUID]
                ? normalised_entries[entryUID]
                : undefined

        if (!entry_normalised) {
            yield call(GetShowroomEntryByID, {
                is_owner: false,
                entryID: entryUID,
            })
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: RemoveEntryFromWatchlist,
                payload: entryUID,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(
                showroomActions.removeEntryFromWatchlistError(customError)
            )
        }
    }
}

function* watcherRemoveEntryFromWatchlist() {
    while (true) {
        const { payload } = yield take(
            showroomActions.removeEntryFromWatchlistRequest
        )
        yield call(RemoveEntryFromWatchlist, payload)
    }
}

const remove_watchlist_entry: any[] = [fork(watcherRemoveEntryFromWatchlist)]

export default remove_watchlist_entry
