import styled from 'styled-components'
import { ICarShowroomSpecs } from '../../../pages/showroom/showroom'
import { device } from '../../../templates/displays/devices'

type Props = {
    specs: ICarShowroomSpecs
    isAdmin?: boolean
    openLocationForm?: any
    openForm?: any
}

const Container = styled.div<{ $isAdmin?: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: center;
    gap: 0.5rem;
    width: 49%;
    height: inherit;
    cursor: ${(props) => (props.$isAdmin ? 'pointer' : undefined)};

    @media ${device.smallest_laptops} {
        width: 48%;
        column-gap: 0.25rem;
    }
`

const ListItem = styled.div<{ $isAdmin?: boolean; $isUndisclosed?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    gap: 0.25rem;

    width: inherit;
    height: 80px;
    background-color: ${(props) =>
        props.$isUndisclosed ? `var(--off-bg-color, #fafafa)` : undefined};
    border: ${(props) =>
        props.$isUndisclosed
            ? 'none'
            : '1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 2px;

    flex-grow: 1;
    :hover {
        background-color: ${(props) =>
            props.$isAdmin
                ? 'var(--primary_04, rgba(94, 195, 202, 0.04))'
                : undefined};
    }
`

const ListItemContentTitle = styled.span`
    font-family: Lato-bold;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    color: var(--text-muted, #b3b3b3);
`

const ListItemContentSpec = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
`

const FactsGridSectionDesktop = (props: Props) => {
    const { specs, isAdmin, openLocationForm, openForm } = props

    return (
        <Container $isAdmin={isAdmin}>
            {specs &&
                Object.keys(specs).map((key) => (
                    <ListItem
                        $isUndisclosed={
                            specs[key as keyof typeof specs] === 'Undisclosed'
                                ? true
                                : false
                        }
                        $isAdmin={isAdmin}
                        key={`desktop-${key}`}
                        onClick={() => {
                            if (
                                isAdmin === true &&
                                openLocationForm !== undefined &&
                                openForm !== undefined
                            ) {
                                if (key === 'location') {
                                    openLocationForm()
                                } else {
                                    openForm()
                                }
                            }
                        }}
                    >
                        <ListItemContentTitle>
                            {key && key.replace(/_/g, ' ')}
                        </ListItemContentTitle>
                        <ListItemContentSpec>
                            {specs[key as keyof typeof specs]}
                        </ListItemContentSpec>
                    </ListItem>
                ))}
        </Container>
    )
}

export default FactsGridSectionDesktop
