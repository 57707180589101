import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
// import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import MultipleSelectSearchBarCarInfoDesktop from './multipleSelectSearchBarCarInfoDesktop'

const EditFormContainerDesktop = styled.section`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        margin-top: 28vh;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        width: 680px;
        padding-bottom: 30px;
        padding-top: 40px;
        max-height: 95vh;
        overflow: auto;
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        color: var(--text-strong, #1a1a1a);
        border-radius: 5px;
        position: relative;
        margin-bottom: 50px;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 50px;
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 18px;
    text-transform: capitalize;

    @media ${device.large_desktop} {
        font-size: 18px;
    }
`

const SmallPadding = styled.div`
    padding-top: 30px;
`

const LargePadding = styled.div`
    padding-top: 70px;
`
// const RegistrationNumberReminder = styled.div`
//     padding-right: 50px; ;
// `

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    multiple_values_array: any
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc?: any
    tech_info_id?: any
    optionsList: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        item,
        optionsList,
        setFieldValue,
        handleSubmit,
        // errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    return (
        <EditFormContainerDesktop>
            <InnerFormPadding>
                <FieldTitle>{item.text}</FieldTitle>
                {/* <RegistrationNumberReminder>
                    or <ButtonAtom theme="naked-text">click here</ButtonAtom> to
                    enter the car’s registration number and get data
                    automatically added.
                </RegistrationNumberReminder> */}
                <SmallPadding />
                <MultipleSelectSearchBarCarInfoDesktop
                    item={item}
                    items={optionsList}
                    handleSelectChange={(name: string) =>
                        setFieldValue('multiple_values_array', name)
                    }
                />
            </InnerFormPadding>
            <LargePadding />
            <ButtonsStandardBottomRowDesktop
                cancel={toggle}
                submit={() => {
                    handleSubmit()
                }}
            />
        </EditFormContainerDesktop>
    )
}

const CarTechInfoEditFormMultipleSelectSearchDesktop = withFormik<
    OtherProps,
    Values
>({
    mapPropsToValues: (props) => ({
        multiple_values_array: props.item.answer ? props.item.answer : '-',
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarTechInfoEditFormMultipleSelectSearchDesktop)
