import * as React from 'react'
import styled from 'styled-components'

import './styles.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { IApexHomepageSlideState } from 'apexModels'
import { device } from '../../../templates/displays/devices'

type Props = {
    slide: IApexHomepageSlideState
    height: string
}

type IHeightStyle = {
    height: string
}

const checkForIpad = () => {
    const ua = window.navigator.userAgent
    const isIPad =
        !!ua.match(/Mac/i) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
    return isIPad
}

const SlideStyle = styled.div<IHeightStyle>`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100vw;

    display: flex;
    flex-direction: column;

    height: ${(props) => props.height};
`

const SlideStyleOverlay = styled.div<IOverlayProps>`
    background: ${(props) =>
        props.position === 'top_right'
            ? 'linear-gradient(261.81deg,rgba(0, 0, 0, 0.54) 9.2%,rgba(0, 0, 0, 0) 59.6%);'
            : props.position === 'bottom_left'
            ? 'linear-gradient(49.84deg, rgba(0, 0, 0, 0.54) 10.33%, rgba(0, 0, 0, 0) 58.53%);'
            : ''};

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
`

const Title = styled.div<IOverlayProps>`
    color: white;
    font-family: Lato-Bold;
    font-size: 1.1rem;
    text-transform: uppercase;

    max-width: 95vw;

    @media screen and (max-width: 360px) {
        font-size: 1rem;
        max-width: 95vw;
    }
`

const Text = styled.div<IOverlayProps>`
    color: white;
    font-family: Lato-Bold;
    max-width: 95vw;
    font-size: 0.9rem;
    height: 100%;

    padding-right: ${(props) =>
        props.position === 'bottom_left' ? '20px' : ''};

    @media ${device.mobile} {
        padding-left: ${(props) =>
            props.position === 'bottom_left' ? '0px' : '10px'};
    }

    @media screen and (max-width: 360px) {
        padding-left: ${(props) =>
            props.position === 'top_right' ? '5px' : ''};
    }
`

const TextWrapper = styled.div<IOverlayProps>`
    z-index: 2;
    position: absolute;
    width: 100%;
    top: ${(props) =>
        props.position === 'top_right'
            ? '20px'
            : props.position === 'bottom_left'
            ? 'auto'
            : ''};

    bottom: ${(props) =>
        props.position === 'top_right'
            ? 'auto'
            : props.position === 'bottom_left'
            ? '40px'
            : ''};

    right: ${(props) =>
        props.position === 'top_right'
            ? checkForIpad()
                ? '50px'
                : '0px'
            : props.position === 'bottom_left'
            ? 'auto'
            : ''};

    left: ${(props) =>
        props.position === 'top_right'
            ? 'auto'
            : props.position === 'bottom_left'
            ? '20px'
            : ''};

    text-align: ${(props) =>
        props.position === 'top_right'
            ? 'right'
            : props.position === 'bottom_left'
            ? 'left'
            : ''};

    @media ${device.mobile} {
        right: ${(props) =>
            props.position === 'top_right'
                ? '0px'
                : props.position === 'bottom_left'
                ? 'auto'
                : ''};
    }
`

type IOverlayProps = {
    position: 'top_right' | 'top_left' | 'bottom_left' | 'bottom_right'
}

class SlideTheme2Mobile extends React.Component<Props> {
    render() {
        const { slide, height } = this.props

        return (
            <SlideStyle
                role="img"
                aria-label="fullwidth-picture"
                style={{
                    backgroundImage: `url(${slide.img ? slide.img.url : ''})`,
                }}
                height={height}
            >
                <SlideStyleOverlay
                    position={
                        slide.text_position ? slide.text_position : 'top_right'
                    }
                />

                <TextWrapper
                    position={
                        slide.text_position ? slide.text_position : 'top_right'
                    }
                >
                    <Title
                        position={
                            slide.text_position
                                ? slide.text_position
                                : 'top_right'
                        }
                    >
                        {slide.title}
                    </Title>

                    <div style={{ paddingTop: '10px' }} />

                    <Text
                        position={
                            slide.text_position
                                ? slide.text_position
                                : 'top_right'
                        }
                    >
                        {slide.blurb
                            ? slide.blurb
                            : slide.quote
                            ? slide.quote
                            : ''}
                    </Text>
                </TextWrapper>
            </SlideStyle>
        )
    }
}
export default SlideTheme2Mobile
