import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { menuActions } from '../../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import InsuranceTopBarWithProgressionRate from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceQuoteAddressesManager from '../../../organisms/insuranceQuote/addressesManager'
import InsuranceTopBarWithProgressionRateMobile from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { IInsuranceQuoteApplication } from '../../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../../redux/store'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

// type UserIDParams = {
//     userid: string
// }

const InsuranceApplicationAddressesDashboard = () => {
    const history = useHistory()

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let { getUserAndApplicationData } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Addresses Manager' },
                    ]}
                    sectionId="addresses"
                />
                <InsuranceQuoteAddressesManager
                    goBack={() => history.push(`/insurance/application/${id}`)}
                />
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Addresses Manager' },
                    ]}
                    sectionId="addresses"
                />
                <div style={{ width: '100vw' }}>
                    <InsuranceQuoteAddressesManager
                        goBack={() =>
                            history.push(`/insurance/application/${id}`)
                        }
                        isMobile
                    />
                </div>
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationAddressesDashboard
