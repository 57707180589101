import React from 'react'
import styled from 'styled-components'
import { IDropdownItem } from 'entityModels'
import CustomAnimatedCheckboxField from '../../formField/customAnimatedCheckboxField'
// import CustomIconTagQuickFilterItemMobile from '../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FilterButton from '../../Button/filterButton'

export type Props = {
    dropdownData: IDropdownItem[]
    dataCyId?: string
    isDisabled?: boolean
    buttonMinWidth?: string
    onSelected?: (value: string) => void
    currentOption?: string | null
    isSort?: boolean
}

type IStyle = {
    selected?: boolean
}

const DropdownOptions = styled.ul`
    position: absolute;
    z-index: 1000;
    margin-top: 10px;
    width: 200px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 10px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 15px;
    box-shadow: 4px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: transparent;
    padding-left: 20px;
    cursor: pointer;

    :hover {
        background-color: var(--background_neutral_soft);
    }
`

const Wrapper = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-transform: capitalize;
`

type StateSelect = {
    showDropdown: boolean
    currentSelected: string | undefined | null
}

const getOptionFronmParam = (value: string) =>
    (value.charAt(0).toUpperCase() + value.slice(1)).replace(/_/g, ' ')

class SharesViewMenuDesktop extends React.Component<Props, StateSelect> {
    state = {
        showDropdown: false,
        currentSelected: this.props.currentOption
            ? getOptionFronmParam(this.props.currentOption)
            : this.props.dropdownData
            ? this.props.dropdownData[0].name
            : undefined,
    }
    _isMounted = false

    private dropdowRef = React.createRef<HTMLDivElement>()

    handleClickOutsideDropdown(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.setState({
                showDropdown: false,
            })
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.dropdowRef.current!.focus()
        let node = this.dropdowRef.current
        document.addEventListener('mousedown', (e: any) =>
            this.handleClickOutsideDropdown(e, node)
        )
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.currentOption !== this.props.currentOption) {
            this.setState({
                ...this.state,
                currentSelected: this.props.currentOption,
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropdown
        )
        this._isMounted = false
    }

    render() {
        const {
            dataCyId,
            dropdownData,
            isDisabled,
            buttonMinWidth,
            onSelected,
            isSort,
        } = this.props

        const handleFilterButtonClick = () => {
            if (this.state.showDropdown === true) {
                this.setState({
                    showDropdown: false,
                })
            } else {
                this.setState({
                    showDropdown: true,
                })
            }
        }

        let mappedDropdownData =
            dropdownData &&
            dropdownData.map((item: IDropdownItem, index: number) => {
                if (item) {
                    return (
                        <StyledOption
                            selected={this.state.currentSelected === item.name}
                            value={item.name}
                            key={`${item.name}-${index}-desktop`}
                            aria-label={item.name}
                        >
                            <ContentRow>
                                <CustomAnimatedCheckboxField
                                    // textchangedisabled
                                    width="100%"
                                    onClick={() => {
                                        onSelected && onSelected(item.uid)
                                        this.setState({
                                            showDropdown: false,
                                            currentSelected: item.uid,
                                        })
                                    }}
                                    checked={
                                        this.state.currentSelected.toLowerCase() ===
                                        item.name.toLowerCase()
                                    }
                                >
                                    {item.name}
                                </CustomAnimatedCheckboxField>
                            </ContentRow>
                        </StyledOption>
                    )
                } else {
                    return null
                }
            })

        return (
            <Wrapper data-attr={dataCyId} ref={this.dropdowRef}>
                <FilterButton
                    text={
                        this.state.currentSelected
                            ? this.state.currentSelected
                            : dropdownData[0].name
                    }
                    onClick={
                        this.props.isDisabled
                            ? () => {}
                            : handleFilterButtonClick
                    }
                    isactive={this.state.showDropdown}
                    minWidth={buttonMinWidth}
                    isDisabled={isDisabled}
                    hasChevron={!isSort}
                    isSort={isSort}
                    capitalize
                />

                <SlideDownWrapper
                    isOpen={this.state.showDropdown}
                    initialX={-200}
                    originX={'200px'}
                >
                    <DropdownOptions>
                        {dropdownData &&
                            dropdownData.length > 0 &&
                            mappedDropdownData}
                    </DropdownOptions>
                </SlideDownWrapper>
            </Wrapper>
        )
    }
}

export default SharesViewMenuDesktop
