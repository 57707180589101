import * as React from 'react'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import SquareDuoMobile from '../../molecules/imageGrid/squareDuoMobile'
import AddImageBanner from '../../atoms/image/addImageBanner'
import Loader from '../../atoms/loader/loader'
import { IGalleryImagesObject } from 'entityModels'

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {
        imagesData: state.entities.galleriesData.images,
        isNecessaryGalleryDataLoading: state.entities.galleriesData.loading,
        isGettingCarDataLoading: state.entities.carsData.loading,
    }
}

export interface IImageProps {
    url: string
}

interface Props {
    imagesData: IGalleryImagesObject | null
    isNecessaryGalleryDataLoading: any
    ids_list: any
    openImgForm?: any
    isGettingCarDataLoading: boolean
    isExternalCar?: boolean
}

type State = {
    isEmpty: boolean | null
}

class EntryGalleryManagerMobile extends React.Component<Props, State> {
    state = {
        isEmpty: true,
    }

    componentDidMount() {
        if (this.props.ids_list && this.props.ids_list.length < 1) {
            this.setState({ isEmpty: true })
        } else {
            this.setState({ isEmpty: false })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.ids_list !== prevProps.ids_list) {
            if (this.props.ids_list && this.props.ids_list.length < 1) {
                this.setState({ isEmpty: true })
            } else {
                this.setState({ isEmpty: false })
            }
        }
    }

    render() {
        const { isEmpty } = this.state
        const {
            imagesData,
            ids_list,
            openImgForm,
            isNecessaryGalleryDataLoading,
            isGettingCarDataLoading,
        } = this.props

        return (
            <React.Fragment>
                {isEmpty !== true &&
                    !isNecessaryGalleryDataLoading &&
                    !isGettingCarDataLoading &&
                    imagesData && (
                        <SquareDuoMobile
                            images_data_object={imagesData}
                            ids_list={ids_list}
                            onAddClick={openImgForm}
                            readOnlyMode={
                                this.props.isExternalCar ? true : false
                            }
                        />
                    )}

                <Loader
                    isLoading={
                        isGettingCarDataLoading || isNecessaryGalleryDataLoading
                    }
                />

                {isEmpty === true && !this.props.isExternalCar && (
                    <AddImageBanner ipadHeight="350px" onClick={openImgForm} />
                )}
            </React.Fragment>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(EntryGalleryManagerMobile)
