import * as React from 'react'
import ButtonAtom from './ButtonAtom'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import { AddIcon } from '../statefulicons'
type Props = {
    onClick: any
    text: string
    hasError?: boolean
    custom_colour?: string
}

const IconAndTextNakedV2: React.FunctionComponent<Props> = (props: Props) => {
    let { onClick, text, hasError, custom_colour } = props

    const { theme } = useThemes()
    return (
        <ButtonAtom
            theme="naked-add-text"
            height="48px"
            fontSize={16}
            onClick={onClick}
            icon="add"
            width="80px"
            color={hasError ? colours[theme].error : custom_colour ?? undefined}
        >
            <div
                style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                }}
            >
                <AddIcon
                    onClick={() => {}}
                    colour={
                        hasError
                            ? colours[theme].error
                            : custom_colour ?? colours[theme].error
                    }
                />
                <div
                    style={{
                        fontWeight: 600,
                        letterSpacing: '0.001px',
                    }}
                >
                    {text}
                </div>
            </div>
        </ButtonAtom>
    )
}

export default IconAndTextNakedV2
