import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Title = styled.div`
    font-family: Lato-Light;
    font-size: 27px;
    color: var(--primary, #5ec3ca);
    text-transform: uppercase;
    padding-bottom: 12px;
`

const Text = styled.div`
    display: flex;
    text-align: center;
    max-width: 408px;
    font-family: Lato-Light;
    font-size: 19px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 24px;
`

type Props = {
    title: string
    text: string
}

class ApexPageHeaderDesktop extends React.Component<Props> {
    render() {
        let { title, text } = this.props

        return (
            <Container>
                <Wrapper>
                    <Title>{title}</Title>
                    <Text>{text}</Text>
                </Wrapper>
            </Container>
        )
    }
}

export default ApexPageHeaderDesktop
