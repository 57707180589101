import { ICustomErrorData, IReduxError } from 'entityModels'
import { call, fork, put, select, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { replace } from 'connected-react-router'
import { insuranceActions } from './../../reducer'
import posthog from 'posthog-js'
import {
    generateFBC,
    generateFBP,
    get_check_cookie_name,
    setCookie,
} from '../../../../helpers/cookies'
import { IUser } from 'myModels'
import { state_select_user } from '../../../user/sagas/getUserData'

export type ICreateInsuranceApplicationSagaArg = {
    mode?: 'free_flow' | 'wizard'
}

export type IsortOutCreateApplicationAdTracking = {
    user_id: string
    first_name: string
    last_name: string
    email: string
}

export const sortOutCreateApplicationTracking = (
    p: IsortOutCreateApplicationAdTracking
) => {
    let FBP = get_check_cookie_name('_fbp')
    let FBC = get_check_cookie_name('_fbc')

    const fbclidCookie: string | undefined = get_check_cookie_name('fbclid')

    const ua =
        !!window && window.navigator && window.navigator.userAgent
            ? window.navigator.userAgent
            : undefined

    const timeNow = Date.now()
    let event_id = `begin_checkout_${timeNow}`

    if (
        (fbclidCookie || FBP || FBC) &&
        process.env.REACT_APP_ENV !== 'local' &&
        process.env.REACT_APP_ENV !== 'development' &&
        process.env.REACT_APP_ENV !== 'preview'
    ) {
        const timeNow = Date.now()

        if (!FBC && fbclidCookie) {
            FBC = generateFBC(fbclidCookie, timeNow)
            setCookie({
                name: '_fbc',
                value: FBC,
                days: 180,
            })
        }

        if (!FBP && fbclidCookie) {
            FBP = generateFBP(timeNow)
            setCookie({
                name: '_fbp',
                value: FBP,
                days: 180,
            })
        }

        window &&
            window.dataLayer &&
            window.dataLayer.push({
                event: 'begin_checkout',
                first_party_collection: true,
                // external_id: p.user_id,
                event_id: event_id,
                email_address: p.email?.toLowerCase(),
                first_name: p.first_name,
                last_name: p.last_name,
                fbclid: fbclidCookie ?? undefined,
                fbc: FBC,
                fbp: FBP,
                user_agent: ua,
            })
    } else if (
        process.env.REACT_APP_ENV !== 'local' &&
        process.env.REACT_APP_ENV !== 'development' &&
        process.env.REACT_APP_ENV !== 'preview'
    ) {
        window &&
            window.dataLayer &&
            window.dataLayer.push({
                event: 'begin_checkout',
                first_party_collection: true,
                // external_id: p.user_id,
                event_id: event_id,
                email_address: p.email?.toLowerCase(),
                first_name: p.first_name,
                last_name: p.last_name,
                user_agent: ua,
            })
    }
}

export function* CreateUserInsuranceApplication(
    payload: ICreateInsuranceApplicationSagaArg
): any {
    try {
        posthog.capture('Create insurance application')
        let res = yield call(
            api.insuranceApplication.createInsuranceApplication
        )

        let appli_id = res?.uid
        // direct to dashboard

        if (payload.mode === 'wizard') {
            yield put(replace(`/insurance/application/${appli_id}/wizard`))
        } else {
            yield put(replace(`/insurance/application/${appli_id}`))
        }

        yield put(insuranceActions.create_insurance_application_success())

        let udata: IUser | null = yield select(state_select_user)

        if (udata) {
            sortOutCreateApplicationTracking({
                user_id: udata.id,
                email: `${udata.email}`,
                first_name: `${udata.given_name}`,
                last_name: `${udata.family_name}`,
            })
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: CreateUserInsuranceApplication,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.create_insurance_application_error(customError)
            )
        }
    }
}

function* Watcher_Create_UserApplication() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.create_insurance_application_request
        )

        yield call(CreateUserInsuranceApplication, payload)
    }
}

const insurance_create_user_application: any[] = [
    fork(Watcher_Create_UserApplication),
]

export default insurance_create_user_application
