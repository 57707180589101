import * as React from 'react'
import QrCodeActionBtnWrapper from '../Button/qrCodeActionBtnWrapper'
import { downloadRawQrCode } from './generatePngQRCodeRaw'
import posthog from 'posthog-js'

type Props = {
    id_of_qr_code_to_export: string
    isMobile?: boolean
    car_title: string
    dataCyId?: string
}

export const PngDownloadButton: React.FunctionComponent<Props> = (props) => {
    return (
        <>
            <QrCodeActionBtnWrapper
                onClick={() => {
                    downloadRawQrCode({
                        id_of_svg_qr_code: props.id_of_qr_code_to_export,
                        format: 'PNG',
                        title: props.car_title,
                        device: props.isMobile ? 'mobile' : 'desktop',
                    })
                    // capture qr_code raw PNG image generation/download
                    posthog.capture('raw_qr_code_png_img_download_btn clicked')
                }}
                variant={'image'}
                dataCyId={props.dataCyId}
            >
                {props.isMobile ? 'Image' : 'Save Image'}
            </QrCodeActionBtnWrapper>
        </>
    )
}

export default PngDownloadButton
