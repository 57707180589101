import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import upload_white from '../../../public/assets/icons/upload_icon.svg'
import { IGalleryImage } from 'entityModels'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import AddBoxWithSvgPlaceholderDesktop from '../placeholders/addBoxWithSvgPlaceholderDesktop'
import banner_placeholder_desktop from '../../../public/assets/placeholder_images/banner_placeholder_desktop.jpg'
import banner_placeholder_desktop_dark from '../../../public/assets/placeholder_images/banner_placeholder_desktop_dark.png'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

type IImgProps = {
    isthingloading: boolean
    showCoverPhotoOptionsMode?: boolean
}

const ImageStyle = styled.div<IImgProps>`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: ${(props) => (props.isthingloading === true ? 0 : 1)};
    transition: opacity 400ms;
    height: 100%;
    width: 100%;
`
const ImageStyleWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    cursor: pointer;
    z-index: 1;

    @media ${device.ipad} {
        height: 250px;
    }

    @media ${device.smallest_laptops} {
        height: 280px;
    }

    @media ${device.large_desktop} {
        height: 400px;
    }

    @media ${device.wide_desktop} {
        height: 450px;
    }
`

type IProps2 = {
    showCoverPhotoOptionsMode: boolean
}

const ImageOverlayStyle = styled.div<IProps2>`
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 40%;
    background: linear-gradient(rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 0.4) 70%);
    opacity: ${(props) => (props.showCoverPhotoOptionsMode ? 1 : 0)};
`

const UploadLogoStyle = styled.img`
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 18px;
    left: 18px;
`

export interface IImageProps {
    img?: IGalleryImage | undefined | null
    placeholder?: string
    toggleCarBannerImageForm?: any
    showCoverPhotoOptionsMode?: boolean
    hasSvgBackgroundImage?: boolean
    readOnlyMode?: boolean
}

type State = {
    src: any
    showBoxInfo: boolean
    showCoverPhotoOptionsMode: boolean
    isLoading: boolean
}

class CarOverviewBanner extends React.Component<IImageProps, State> {
    state = {
        src: null,
        showBoxInfo: false,
        showCoverPhotoOptionsMode: false,
        isLoading: true,
    }

    _isMounted = false

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                ...this.state,
                src: src,
                isLoading: false,
            })
    }

    componentDidMount() {
        this._isMounted = true
        const src = this.props.img && this.props.img.large_src

        if (src) {
            this.onLoad(src)
        } else {
            if (this.props.placeholder !== undefined) {
                return (
                    this._isMounted &&
                    this.setState({
                        src: this.props.placeholder,
                        isLoading: false,
                    })
                )
            }
        }
    }

    componentDidUpdate(prevProps: IImageProps) {
        if (this.props.img?.url !== prevProps.img?.url) {
            const src = this.props.img && this.props.img.large_src

            if (src) {
                return this.onLoad(src)
            } else {
                if (this.props.placeholder !== undefined) {
                    return this.setState({
                        src: this.props.placeholder,
                        isLoading: false,
                    })
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    setShowCoverPhotoOptionsModeTrue = () =>
        this.setState({ showCoverPhotoOptionsMode: true })

    setShowCoverPhotoOptionsModeFalse = () =>
        this.setState({ showCoverPhotoOptionsMode: false })

    render() {
        const { img, toggleCarBannerImageForm } = this.props
        const { showCoverPhotoOptionsMode, isLoading } = this.state

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <React.Fragment>
                        {img !== undefined ? (
                            <ImageStyleWrapper
                                style={
                                    this.props.readOnlyMode
                                        ? { cursor: 'default' }
                                        : {}
                                }
                            >
                                <SkeletonAbsolute isthingloading={isLoading} />
                                <ImageStyle
                                    role="img"
                                    aria-label="car overview banner"
                                    style={{
                                        backgroundImage: `url(${
                                            this.state.src
                                                ? this.state.src
                                                : grey_placeholder
                                                ? grey_placeholder
                                                : this.props.img
                                        })`,
                                    }}
                                    onMouseEnter={
                                        this.setShowCoverPhotoOptionsModeTrue
                                    }
                                    onMouseLeave={
                                        this.setShowCoverPhotoOptionsModeFalse
                                    }
                                    isthingloading={isLoading}
                                >
                                    {!this.props.readOnlyMode && (
                                        <ImageOverlayStyle
                                            role="img"
                                            aria-label="car overview banner"
                                            showCoverPhotoOptionsMode={
                                                showCoverPhotoOptionsMode
                                            }
                                            onClick={
                                                !this.props.readOnlyMode
                                                    ? toggleCarBannerImageForm
                                                    : () => {}
                                            }
                                        >
                                            <UploadLogoStyle
                                                src={upload_white}
                                                alt="upload"
                                            />
                                        </ImageOverlayStyle>
                                    )}
                                </ImageStyle>
                            </ImageStyleWrapper>
                        ) : (
                            <AddBoxWithSvgPlaceholderDesktop
                                onClickAdd={toggleCarBannerImageForm}
                                hooverText="Add your car's banner image"
                                readOnlyMode={this.props.readOnlyMode}
                                hasSvgBackgroundImage={
                                    this.props.hasSvgBackgroundImage
                                }
                                customPlaceholderImg={
                                    theme === 'dark'
                                        ? banner_placeholder_desktop_dark
                                        : banner_placeholder_desktop
                                }
                                height={'100px'}
                                hasAddTxtIcon={true}
                            />
                        )}
                    </React.Fragment>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default CarOverviewBanner
