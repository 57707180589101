import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import useThemes from '../../../../../../providers/theme/hooks'
import { useEffect } from 'react'
import { journey_colours } from '../../../../../../providers/theme/colours'
import FormStepProgress from '../../../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../../../atoms/icons/components/chevronDown'
import Faded from '../../../../../templates/animated/faded'
import EnterIcon from '../../../../../atoms/icons/components/enterIcon'

import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { ITimelineItem } from 'timelineModels'
import { JourneyHFInsuranceFormBody } from './insuranceFormBody'
import TimelineInsuranceItemDesktopJourneyCard from '../../../../../molecules/timelineItemDesktop/insuranceTimelineCard/desktop'
import { device } from '../../../../../templates/displays/devices'
import { message } from 'antd'

const errorInfo = (messageText: string) => {
    message.error(messageText, 5)
}

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
    entry1: ITimelineItem | undefined
    setEntry1: (p: ITimelineItem | undefined) => any
    hasInsuranceEntryWithMandatoryFields?: boolean
}

const CardImageWrapper = styled.div`
    width: 592px;
    @media ${device.smallest_laptops} {
        width: 500px;
    }
`

export default function InsuranceHistoryFileJourneyStepDesktop({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    entry1,
    setEntry1,
    hasInsuranceEntryWithMandatoryFields,
}: Props) {
    const { theme } = useThemes()

    useEffect(() => {
        const continueOnEnter = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                if (
                    entry1 &&
                    (!entry1?.insurance_entry_policy_start_date ||
                        !entry1?.insurance_entry_policy_end_date)
                ) {
                    errorInfo(
                        `Please enter the policy's start and end date. If not sure, you can pick a approximative ones.`
                    )
                    return
                }

                goToNextStep()
            }
        }

        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [entry1])

    return (
        <DesktopDisplayOnly>
            <Faded>
                <JourneySectionWrapper>
                    <JourneyLeftSide $theme={theme}>
                        <FormStepProgress
                            screen_name="desktop"
                            currentStep={step}
                            totalSteps={totalSteps}
                            activeColour={
                                journey_colours[theme].section['history_file']
                                    .primary_500
                            }
                            passiveColour={
                                journey_colours[theme].section[`history_file`]
                                    .primary_100
                            }
                        />
                        <JourneyColumnContent style={{ gap: 24 }}>
                            <JourneyColumnContent style={{ gap: 16 }}>
                                <StandardCtaBtn
                                    bg="transparent"
                                    customWidth="auto"
                                    customHeight="auto"
                                    removePaddings
                                    removeShadowOnHover
                                    onClick={goToPrevStep}
                                >
                                    <JourneyBtnContent $theme={theme}>
                                        <div
                                            style={{
                                                transform: 'rotate(90deg)',
                                            }}
                                        >
                                            <ChevronDown />
                                        </div>
                                        Back
                                    </JourneyBtnContent>
                                </StandardCtaBtn>
                                <JourneyStepTitle $theme={theme}>
                                    {hasInsuranceEntryWithMandatoryFields
                                        ? 'Nice one!'
                                        : 'Don’t forget your insurance!'}
                                </JourneyStepTitle>
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                        {hasInsuranceEntryWithMandatoryFields ? (
                            <JourneyText $theme={theme}>
                                Great job getting started with your car’s
                                digital history file!
                                <br />
                                <br />
                                Having all your documents easily searchable in
                                one place is extremely helpful both during the
                                ownership of your vehicles and especially
                                helpful should you decide to sell or handover
                                your vehicle to a friend or loved one.
                                <br />
                                <br />
                                As you might already know, Custodian is working
                                hard to create fairer and better insurance for
                                automotive enthusiasts. Our goal is to become an
                                extremely reliable source of risk for
                                underwriters allowing better pricing for a new
                                category of car owner and pricing more on how a
                                person looks after their car rather than what
                                gender, age etc they are.
                                <br />
                                <br />
                                It'll be a long journey, but its the best way to
                                make insurance better for those who look after
                                their cars, themselves, and others well so they
                                do not have to foot the bill for those who do
                                not.
                                <br />
                                <br />
                                By using Custodian, you’re helping us make
                                specialist car insurance better for all
                                enthusiasts.
                            </JourneyText>
                        ) : (
                            <>
                                <JourneyText $theme={theme}>
                                    Add an Insurance history file entry to
                                    record your policy details along with its
                                    renewal dates. Having this information
                                    easily accessible at the most unfortunate
                                    moments can bring great peace of mind.
                                    <br />
                                    <br />
                                    It also means we will be prepared to broker
                                    you the best cover should you decide to
                                    apply for Custodian Insurance.
                                </JourneyText>

                                <div />

                                <JourneyColumnContent>
                                    <JourneyHFInsuranceFormBody
                                        entry={entry1}
                                        setEntry={setEntry1}
                                    />
                                </JourneyColumnContent>
                            </>
                        )}

                        <JourneyRowContent style={{ gap: 32 }}>
                            <StandardCtaBtn
                                onClick={() => {
                                    goToNextStep()
                                }}
                                isDisabled={false}
                                bg={
                                    journey_colours[theme].section[
                                        'history_file'
                                    ].primary_500
                                }
                                customHoverColor={
                                    journey_colours[theme].section[
                                        'history_file'
                                    ].primary_600
                                }
                            >
                                Continue
                            </StandardCtaBtn>

                            <JourneyRowContent style={{ gap: 8 }}>
                                <JourneyIconWrapper $theme={theme}>
                                    <EnterIcon />
                                </JourneyIconWrapper>{' '}
                                <JourneyEnterTxt $theme={theme}>
                                    Or press Enter
                                </JourneyEnterTxt>
                            </JourneyRowContent>
                        </JourneyRowContent>
                    </JourneyLeftSide>
                    <JourneyRightSide>
                        <JourneyColumnContent
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                height: '100%',
                                overflowY: 'scroll',
                                gap: '0px',
                            }}
                        >
                            <CardImageWrapper>
                                <TimelineInsuranceItemDesktopJourneyCard
                                    userCurrency="£"
                                    entry={entry1}
                                    onClick={() => {}}
                                    isReadonly={
                                        hasInsuranceEntryWithMandatoryFields
                                    }
                                />
                            </CardImageWrapper>
                        </JourneyColumnContent>
                    </JourneyRightSide>
                </JourneySectionWrapper>
            </Faded>
        </DesktopDisplayOnly>
    )
}
