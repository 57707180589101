import useThemes from '../../../../../providers/theme/hooks'
import Faded from '../../../../templates/animated/faded'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import {
    JourneyColumnContent,
    JourneyText,
} from '../../../../templates/styledcomponents/journeyStyles'
import JourneyPageWrapperMobile from '../../../../templates/wrappers/journeyPageWrapperMobile'
import { CenterImg } from '../memories'
import image_mobile from '../../../../../public/assets/images/journey/pastCars/mobile_revisiting.png'
import grey_placeholder from '../../../../../public/assets/placeholder/grey_placeholder.svg'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function RevisitingCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    return (
        <IpadAndMobileDisplay>
            <Faded>
                <JourneyPageWrapperMobile
                    allowScroll={true}
                    title={'Revisiting Your Automotive Adventures'}
                    sectionID="past_cars"
                    subtitle={`Don't forget your past cars`}
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        goToPrevStep()
                    }}
                    onNext={() => {
                        goToNextStep()
                    }}
                >
                    <JourneyColumnContent style={{ gap: 0 }}>
                        <JourneyText
                            $theme={theme}
                            style={{
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            }}
                        >
                            With just a click, you can revisit the details of
                            your past cars, reliving the moments and experiences
                            they brought into your life.
                        </JourneyText>

                        <div style={{ paddingTop: '36px' }} />

                        <CenterImg
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Faded width={'auto'}>
                                <img
                                    placeholder={grey_placeholder}
                                    src={image_mobile}
                                    style={{
                                        objectFit: 'contain',
                                        width: '100vw',
                                        maxWidth: '550px',
                                    }}
                                />
                            </Faded>
                        </CenterImg>

                        <div style={{ paddingTop: '42px' }} />

                        <JourneyText
                            $theme={theme}
                            style={{
                                fontSize: '16px',
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            }}
                        >
                            Every car added to your "Previously Owned"
                            collection is a chapter in your automotive story,
                            waiting to be revisited whenever you desire.
                        </JourneyText>

                        <div style={{ paddingTop: '80px' }} />
                    </JourneyColumnContent>
                </JourneyPageWrapperMobile>
            </Faded>
        </IpadAndMobileDisplay>
    )
}
