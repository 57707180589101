import {
    IApexInterviewCategory,
    IApexInterviewOverviewItemState,
    ITaskNormalisedICategoryInformationObject_State,
} from 'apexModels'
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { getApexInterviewOverviewsRequest } from '../../../redux/theapex/actions/requestActions'
import ApexInterviewCategoryHeaderAtom from '../../atoms/header/apex/apexInterviewCategoryHeader'
import ApexPageHeaderMobile from '../../atoms/header/apex/apexPageHeaderMobile'
import ApexInterviewOverviewsManagerDesktop from '../../molecules/apex/reusable/interviewOverviews/desktop/apexInterviewsOverviewManagerDesktop'
import ApexInterviewsOverviewManagerMobile from '../../molecules/apex/reusable/interviewOverviews/mobile/apexInterviewsOverviewManagerMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import styled from 'styled-components'
import ApexPageHeaderDesktop from '../../atoms/header/apex/apexPageHeaderDesktop'
import { device } from '../../templates/displays/devices'
import ApexPageWrapper from '../../templates/displays/pageWrappers/apexPageWrapper'
import ApexHeaderLineSectionMobile from '../../atoms/header/apex/apexHeaderLineSectionMobile'
import ApexLatestInterviewMobile from '../../molecules/apex/interviewsoverview/mobile/apexLatestInterviewMobile'
import ApexCategoryAnchorsMobile from '../../molecules/apex/interviewsoverview/mobile/apexCategoryAnchorsMobile'
import ApexLatestInterviewDesktop from '../../molecules/apex/interviewsoverview/desktop/apexLatestInterviewDesktop'
import ApexCategoryAnchorsDesktop from '../../molecules/apex/interviewsoverview/desktop/apexCategoryAnchorsDesktop'
import LoaderBackground from '../../atoms/loader/loaderBackground'
import { IApexLocalCopyItem } from 'apexLocalCopyModels'
import { checkForIpad } from './overview'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'

type ITypedInterviewOverviewsObject = {
    [key: string]: IApexInterviewOverviewItemState[]
}

interface Props {
    getApexInterviewOverviewsRequest: any
    latest_interview?: IApexInterviewOverviewItemState
    category_ids_list?: IApexInterviewCategory[]
    categories_information_list?: ITaskNormalisedICategoryInformationObject_State
    interview_overview_objects_by_category?: ITypedInterviewOverviewsObject
    interviewsPageLocalCopy: IApexLocalCopyItem
}

function mapStateToProps(state: RootState) {
    return {
        latest_interview:
            state.apex.interviewOverviewsPageData &&
            state.apex.interviewOverviewsPageData.latest_interview,
        category_ids_list:
            state.apex.interviewOverviewsPageData &&
            state.apex.interviewOverviewsPageData.category_ids_list,

        categories_information_list:
            state.apex.interviewOverviewsPageData &&
            state.apex.interviewOverviewsPageData.categories_information_list,

        interview_overview_objects_by_category:
            state.apex.interviewOverviewsPageData &&
            state.apex.interviewOverviewsPageData
                .interview_overview_objects_by_category,

        interviewsPageLocalCopy:
            state.localdata.apex_local_copy.separatePage.interviews,
    }
}

const dispatchProps = {
    getApexInterviewOverviewsRequest: () => getApexInterviewOverviewsRequest(),
}

const MobileContentPadding = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        padding-left: 26px;
        padding-right: 10vw;
        padding-top: ${checkForIpad() ? '0px' : '40px'};
    }

    @media (min-width: 500px) {
        padding-left: 10vw;
        padding-right: 10vw;
        justify-content: center;
        align-items: center;
    }
`

const DesktopFlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

const DesktopContainerWithPaddingOnLargeScreens = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    @media ${device.large_desktop} {
        width: 1550px;
        padding-top: 0px;
    }
`

const ColumnInterviewsDesktop = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    padding-bottom: 50px;

    @media ${device.large_desktop} {
        padding-bottom: 74px;
    }
`
const InterviewsRowDesktop = styled.div`
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: start;
    align-items: start;
`

const Padding1Desktop = styled.div`
    padding-top: 67px;
    @media ${device.large_desktop} {
        padding-top: 90px;
    }
`

const Padding2Desktop = styled.div`
    padding-top: 90px;
    @media ${device.large_desktop} {
        padding-top: 150px;
    }
`
const MobileIpadPaddingInterview = styled.div`
    padding-bottom: 50px;
    @media (min-width: 500px) {
        padding-bottom: 90px;
    }
`

class ApexInterviewsOverview extends React.Component<Props, {}> {
    componentDidMount() {
        this.props.getApexInterviewOverviewsRequest()
    }

    render() {
        let {
            latest_interview,
            categories_information_list,
            category_ids_list,
            interview_overview_objects_by_category,
            interviewsPageLocalCopy,
        } = this.props

        return (
            <>
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
                <ApexPageWrapper>
                    <DesktopDisplayOnly>
                        <DesktopFlexContainer>
                            <DesktopContainerWithPaddingOnLargeScreens>
                                <ApexPageHeaderDesktop
                                    title={interviewsPageLocalCopy.title}
                                    text={interviewsPageLocalCopy.subTitle}
                                />

                                <div style={{ paddingTop: '34px' }} />

                                {categories_information_list &&
                                    category_ids_list && (
                                        <ApexCategoryAnchorsDesktop
                                            categories_information_list={
                                                categories_information_list
                                            }
                                            category_ids_list={
                                                category_ids_list
                                            }
                                        />
                                    )}
                                {!categories_information_list &&
                                    !category_ids_list && (
                                        <LoaderBackground
                                            height={'150px'}
                                            width={'100v&'}
                                        />
                                    )}

                                <Padding1Desktop />

                                {latest_interview !== undefined && (
                                    <ApexLatestInterviewDesktop
                                        latest_interview={latest_interview}
                                    />
                                )}

                                {!latest_interview && (
                                    <LoaderBackground
                                        height={'500px'}
                                        width={'100v&'}
                                    />
                                )}
                                {!category_ids_list && (
                                    <InterviewsRowDesktop>
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'50%'}
                                        />
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'50%'}
                                        />
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'50%'}
                                        />
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'50%'}
                                        />
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'50%'}
                                        />
                                    </InterviewsRowDesktop>
                                )}

                                <Padding2Desktop />

                                <InterviewsRowDesktop>
                                    {category_ids_list &&
                                        categories_information_list &&
                                        category_ids_list.map(
                                            (
                                                category_id: IApexInterviewCategory,
                                                index: number
                                            ) => {
                                                if (
                                                    categories_information_list &&
                                                    categories_information_list[
                                                        category_id
                                                    ] !== undefined
                                                ) {
                                                    return (
                                                        <ColumnInterviewsDesktop>
                                                            {categories_information_list[
                                                                category_id
                                                            ].category_name && (
                                                                <ApexInterviewCategoryHeaderAtom
                                                                    name={
                                                                        categories_information_list[
                                                                            category_id
                                                                        ]
                                                                            .category_name
                                                                    }
                                                                    css_id={`${category_id}-desktop`}
                                                                />
                                                            )}

                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '42px',
                                                                }}
                                                            />

                                                            {interview_overview_objects_by_category &&
                                                                interview_overview_objects_by_category[
                                                                    category_id
                                                                ] && (
                                                                    <ApexInterviewOverviewsManagerDesktop
                                                                        interview_overviews={
                                                                            interview_overview_objects_by_category[
                                                                                category_id
                                                                            ]
                                                                        }
                                                                    />
                                                                )}
                                                        </ColumnInterviewsDesktop>
                                                    )
                                                } else return undefined
                                            }
                                        )}
                                </InterviewsRowDesktop>
                            </DesktopContainerWithPaddingOnLargeScreens>
                        </DesktopFlexContainer>
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <MobileContentPadding>
                            <div
                                style={{
                                    paddingTop: checkForIpad()
                                        ? '65px'
                                        : '95px',
                                }}
                            />

                            <ApexPageHeaderMobile
                                title={interviewsPageLocalCopy.title}
                                text={interviewsPageLocalCopy.subTitle}
                            />

                            <div style={{ paddingTop: '20px' }} />

                            <ApexHeaderLineSectionMobile title="Latest" />

                            {latest_interview !== undefined && (
                                <ApexLatestInterviewMobile
                                    latest_interview={latest_interview}
                                />
                            )}

                            {!latest_interview && (
                                <LoaderBackground
                                    height={'300px'}
                                    width={'100%'}
                                />
                            )}

                            <div style={{ paddingTop: '26px' }} />

                            <ApexHeaderLineSectionMobile title="By Category" />

                            <div style={{ paddingTop: '10px' }} />

                            {!categories_information_list && (
                                <LoaderBackground
                                    height={'150px'}
                                    width={'100%'}
                                />
                            )}

                            {categories_information_list &&
                                category_ids_list && (
                                    <ApexCategoryAnchorsMobile
                                        categories_information_list={
                                            categories_information_list
                                        }
                                        category_ids_list={category_ids_list}
                                    />
                                )}

                            <div style={{ paddingTop: '30px' }} />

                            {!categories_information_list && (
                                <>
                                    <LoaderBackground
                                        height={'180px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'180px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'180px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'180px'}
                                        width={'100%'}
                                    />
                                </>
                            )}

                            {categories_information_list !== undefined &&
                                interview_overview_objects_by_category !==
                                    undefined &&
                                category_ids_list !== undefined && (
                                    <>
                                        {category_ids_list.map(
                                            (
                                                category_id: IApexInterviewCategory,
                                                index: number
                                            ) => {
                                                if (
                                                    categories_information_list &&
                                                    categories_information_list[
                                                        category_id
                                                    ] !== undefined
                                                ) {
                                                    return (
                                                        <MobileIpadPaddingInterview>
                                                            {categories_information_list[
                                                                category_id
                                                            ].category_name && (
                                                                <ApexInterviewCategoryHeaderAtom
                                                                    name={
                                                                        categories_information_list[
                                                                            category_id
                                                                        ]
                                                                            .category_name
                                                                    }
                                                                    css_id={`${category_id}-mobile`}
                                                                />
                                                            )}

                                                            {interview_overview_objects_by_category &&
                                                                interview_overview_objects_by_category[
                                                                    category_id
                                                                ] && (
                                                                    <ApexInterviewsOverviewManagerMobile
                                                                        interview_overviews={
                                                                            interview_overview_objects_by_category[
                                                                                category_id
                                                                            ]
                                                                        }
                                                                    />
                                                                )}
                                                        </MobileIpadPaddingInterview>
                                                    )
                                                } else return undefined
                                            }
                                        )}
                                    </>
                                )}
                            <div style={{ paddingBottom: '110px' }} />
                        </MobileContentPadding>
                    </IpadAndMobileDisplay>
                </ApexPageWrapper>
            </>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(ApexInterviewsOverview)
