type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const CompaniesIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 0.25H13.75V16.4014L11 14.5681L9 15.9014L7 14.5681L5 15.9014L3 14.5681L0.25 16.4014V0.25ZM1.75 1.75V13.5986L3 12.7653L5 14.0986L7 12.7653L9 14.0986L11 12.7653L12.25 13.5986V1.75H1.75Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.25 9.58325H7.75V11.0833H3.25V9.58325Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.04999 9.58325H10.75V11.0833H9.04999V9.58325Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 4.41675C6.30964 4.41675 5.75 4.97639 5.75 5.66675C5.75 6.3571 6.30964 6.91675 7 6.91675C7.69036 6.91675 8.25 6.3571 8.25 5.66675C8.25 4.97639 7.69036 4.41675 7 4.41675ZM4.25 5.66675C4.25 4.14796 5.48122 2.91675 7 2.91675C8.51878 2.91675 9.75 4.14796 9.75 5.66675C9.75 7.18553 8.51878 8.41675 7 8.41675C5.48122 8.41675 4.25 7.18553 4.25 5.66675Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default CompaniesIcon
