import { IMenuState, IToggleTutorialmode } from 'myModels'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

export type IManageTopSubmenuSuccessArg = {
    isRemoved: boolean
}

// later on : to retrieve usertype + questionsList from back end, so to add userPagesArray from back end

const initialState: IMenuState = {
    error: null,
    loading: false,
    isDefaultNavigationRemoved: false,
    publicMobileMenu: {
        isOpen: false,
    },
    desktopMainAppMenu: {
        isCollapsed: false,
    },
    mobileAppSubMenu: {
        isOpen: false,
    },
    slideshowGallery: {
        isOpen: false,
    },
    isAddActionsOpen: {
        isOpen: false,
    },
    isTutorialOn: {
        isOpen: false,
        id: false,
    },
    pwa: {
        hasSeenAndroidPWABanner: false,
        hasSeenIosPWABanner: false,
        isNativeAndroidPWABannerActive: false,
    },
    mobileAccountMenu: {
        isOpen: false,
    },
    mobileTimelineFilterBottomSheetMenu: {
        isOpen: false,
    },
}

export const menuReducer = createSlice({
    name: 'menus',
    initialState: initialState,
    reducers: {
        manageTopSubmenu: (
            state: IMenuState,
            action: PayloadAction<IManageTopSubmenuSuccessArg>
        ) => {
            state.isDefaultNavigationRemoved = action.payload.isRemoved
        },

        togglePublicMobileMenu: (state: IMenuState) => {
            state.publicMobileMenu.isOpen = !state.publicMobileMenu.isOpen
        },

        toggleDesktopMainAppMenu: (
            state: IMenuState,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.desktopMainAppMenu.isCollapsed =
                action.payload ?? !state.desktopMainAppMenu.isCollapsed
        },

        toggleMobileAppSubMenu: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.mobileAppSubMenu.isOpen = action.payload
        },

        toggleMobileAccountMenu: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.mobileAccountMenu.isOpen = action.payload
        },

        toggleGallerySlideshow: (state: IMenuState) => {
            state.slideshowGallery.isOpen = !state.slideshowGallery.isOpen
            state.loading = false
        },

        setGallerySlideshowRequest: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.loading = true
        },
        setGallerySlideshowSuccess: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.slideshowGallery.isOpen = action.payload
            state.loading = false
        },
        setGallerySlideshowError: (
            state: IMenuState,
            action: PayloadAction<IReduxError>
        ) => {
            state.error = action.payload
            state.loading = false
        },

        toggleAddActions: (
            state: IMenuState,
            action: PayloadAction<string | undefined>
        ) => {
            state.isAddActionsOpen.isOpen = !state.isAddActionsOpen.isOpen
            state.isAddActionsOpen.id = action.payload
        },

        toggleOnboardingMode: (
            state: IMenuState,
            action: PayloadAction<IToggleTutorialmode>
        ) => {
            state.isTutorialOn = {
                isOpen: action.payload.isIopen
                    ? action.payload.isIopen
                    : !state.isTutorialOn.isOpen,
                id: action.payload.id
                    ? action.payload.id
                    : state.isTutorialOn.id,
            }
        },

        toggleHasSeenIosPWABanner: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.pwa.hasSeenIosPWABanner = action.payload
        },

        toggleHasSeenAndroidPWABanner: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.pwa.hasSeenAndroidPWABanner = action.payload
        },

        toggleisNativeAndroidPWABannerActive: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.pwa.isNativeAndroidPWABannerActive = action.payload
        },

        toggleFilterBottomSheetMenu: (
            state: IMenuState,
            action: PayloadAction<boolean>
        ) => {
            state.mobileTimelineFilterBottomSheetMenu.isOpen = action.payload
        },
    },
})

export const menuActions = menuReducer.actions

export default menuReducer.reducer

// export const menusReducer = (
//     state: IMenuState = {
//         error: null,
//         loading: false,
//         isDefaultNavigationRemoved: false,
//         publicMobileMenu: {
//             isOpen: false,
//         },
//         desktopMainAppMenu: {
//             isCollapsed: false,
//         },
//         mobileAppSubMenu: {
//             isOpen: false,
//         },
//         slideshowGallery: {
//             isOpen: false,
//         },
//         isAddActionsOpen: {
//             isOpen: false,
//         },
//         isTutorialOn: {
//             isOpen: false,
//             id: false,
//         },
//         pwa: {
//             hasSeenAndroidPWABanner: false,
//             hasSeenIosPWABanner: false,
//             isNativeAndroidPWABannerActive: false,
//         },
//         mobileAccountMenu: {
//             isOpen: false,
//         },
//         mobileTimelineFilterBottomSheetMenu: {
//             isOpen: false,
//         },
//     },
//     action: LoadingActions | SuccessAction | ErrorActions
// ): IMenuState => {
//     switch (action.type) {
//         case successActions.manageTopSubmenuSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 isDefaultNavigationRemoved: action.payload.isRemoved,
//             })

//         //  PUBLIC WEBSITE MOBILE MENU

//         case loadingActions.togglePublicMobileMenuRequest.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: true,
//                 error: null,
//             })
//         case successActions.togglePublicMobileMenuSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 publicMobileMenu: {
//                     isOpen: !state.publicMobileMenu.isOpen,
//                 },
//                 loading: false,
//                 error: null,
//             })
//         case errorActions.togglePublicMobileMenuError.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: false,
//                 error: 'there has been an error in toggling the mobile menu.',
//             })

//         //  DESKTOP APP MAIN SIDE MENU

//         case loadingActions.toggleDesktopMainAppMenuRequest.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: true,
//                 error: null,
//             })
//         case successActions.toggleDesktopMainAppMenuSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 desktopMainAppMenu: {
//                     isCollapsed:
//                         action.payload !== undefined
//                             ? action.payload
//                             : !state.desktopMainAppMenu.isCollapsed,
//                 },
//                 loading: false,
//                 error: null,
//             })
//         case errorActions.toggleDesktopMainAppMenuError.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: false,
//                 error: 'there has been an error in toggling the desktop main app menu.',
//             })

//         //  MOBILE APP SUBMENU

//         case loadingActions.toggleMobileAppSubMenuRequest.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: true,
//                 error: null,
//             })
//         case successActions.toggleMobileAppSubMenuSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 mobileAppSubMenu: {
//                     isOpen: !state.mobileAppSubMenu.isOpen,
//                 },
//                 loading: false,
//                 error: null,
//             })
//         case errorActions.toggleMobileAppSubMenuError.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: false,
//                 error: 'there has been an error in toggling the desktop main app menu.',
//             })

//         //  GALLERY SLIDDESHOW MODAL (image menu)

//         case loadingActions.toggleGallerySlideshowRequest.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: true,
//                 error: null,
//             })

//         case successActions.setGallerySlideshowSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 slideshowGallery: {
//                     isOpen: action.payload,
//                 },
//                 loading: false,
//                 error: null,
//             })

//         case successActions.toggleGallerySlideshowSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 slideshowGallery: {
//                     isOpen: !state.slideshowGallery.isOpen,
//                 },
//                 loading: false,
//                 error: null,
//             })
//         case errorActions.toggleGallerySlideshowError.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: false,
//                 error: 'there has been an error in toggling the desktop main app menu.',
//             })

//         //  ADD ACTIONS

//         case loadingActions.toggleAddActionsRequest.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: true,
//                 error: null,
//             })

//         case successActions.toggleAddActionsSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,

//                 isAddActionsOpen: {
//                     isOpen: !state.isAddActionsOpen.isOpen,
//                     id: action.payload,
//                 },

//                 loading: true,
//                 error: null,
//             })

//         case successActions.toggleTutorialMode.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 isTutorialOn: {
//                     isOpen: action.payload
//                         ? action.payload.isIopen
//                         : !state.isTutorialOn.isOpen,
//                     id: action.payload
//                         ? action.payload.id
//                         : state.isTutorialOn.id,
//                 },

//                 loading: true,
//                 error: null,
//             })

//         // PWA
//         case successActions.toggleIsNativeAndroidPWABannerActiveSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 pwa: {
//                     ...state.pwa,
//                     isNativeAndroidPWABannerActive: action.payload,
//                 },
//             })

//         // PWA
//         case successActions.togglePWaBannerAndroidSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 pwa: {
//                     ...state.pwa,
//                     hasSeenAndroidPWABanner: action.payload,
//                 },
//             })
//         // PWA
//         case successActions.togglePWaBannerIosSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 pwa: {
//                     ...state.pwa,
//                     hasSeenIosPWABanner: action.payload,
//                 },
//             })

//         case loadingActions.toggleMobileAccountMenuRequest.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: true,
//                 error: null,
//             })
//         case successActions.toggleMobileAccountMenuSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 mobileAccountMenu: {
//                     isOpen: action.payload,
//                 },
//                 loading: false,
//                 error: null,
//             })
//         case errorActions.toggleMobileAccountMenuError.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: false,
//                 error: 'there has been an error in toggling the desktop main app menu.',
//             })

//         case loadingActions.toggleMobileFilterBottomSheetMenuRequest.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: true,
//                 error: null,
//             })
//         case successActions.toggleMobileFilterBottomSheetMenuSuccess.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 mobileTimelineFilterBottomSheetMenu: {
//                     isOpen: action.payload,
//                 },
//                 loading: false,
//                 error: null,
//             })
//         case errorActions.toggleMobileFilterBottomSheetMenuError.type:
//             return Object.assign({}, state, {
//                 ...state,
//                 loading: false,
//                 error: 'there has been an error in toggling the desktop main app menu.',
//             })

//         default:
//             return state
//     }
// }

// export default menusReducer

// export type MenusState = ReturnType<typeof menusReducer>
