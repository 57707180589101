import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import SelectSearchBarCarInfoMobile from '../../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'

interface Props extends RouteComponentProps<any> {
    timeZoneDropdownList: string[] | null
    setTimezoneDropdownData: () => void
    setSelectedTimezone: (timezone: string) => void
}

type State = {
    answer: any
}

const dispatchProps = {
    setTimezoneDropdownData: () =>
        dropDownActions.setTimeZoneDropdownDataRequest(),
    setSelectedTimezone: (payload: string) =>
        dropDownActions.setSelectedTimezone(payload),
}

const mapStateToProps = (state: RootState) => ({
    timeZoneDropdownList: state.localdata.dropdownData.timeZonesList,
})

class TimezonePicker extends React.Component<Props, State> {
    state = {
        answer: '',
    }

    componentDidMount() {
        this.props.setTimezoneDropdownData()
    }

    handleChange = (name: string) => {
        this.props.setSelectedTimezone(name)

        const params = new URLSearchParams(this.props.location.search)
        let returnTo = params.get('return_to')

        this.props.history.replace(returnTo ?? '/account/preferences')
    }

    searchOptionsData: any = {
        text: 'timezone',
        id: 'timezone',
        answer: '',
        unique_answer_id: undefined,
    }

    render() {
        return (
            <>
                <SelectSearchBarCarInfoMobile
                    handleSelectChange={(
                        name: string,
                        other?: string | undefined
                    ) => {
                        this.handleChange(name)
                    }}
                    items={this.props.timeZoneDropdownList ?? []}
                    item={this.searchOptionsData}
                    isStandalonePage={true}
                    isKeyValue={true}
                />
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TimezonePicker)
)
