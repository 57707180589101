import filestorage_sagas_presigned_urls from './presignedurls'
import filestorage_sagas_presigned_urls_entry_attachments from './presignedurlsEntryAttachments'
import filestorage_sagas_submission_car_gallery_images from './submissionCarGalleryImages'
import filestorage_sagas_presigned_urls_cost_attachments from './presignedUrlCostAttachments'
import filestorage_sagas_entry_gallery_presigned_urls from './presignedUrlsEntryGallery'
import filestorage_sagas_submission_entry_gallery from './submissionEntryGalleryImages'
import filestorage_sagas_submission_to_s3 from './submitImagesToS3'
let arr: any[] = [
    ...filestorage_sagas_presigned_urls,
    ...filestorage_sagas_submission_car_gallery_images,
    ...filestorage_sagas_presigned_urls_entry_attachments,
    ...filestorage_sagas_presigned_urls_cost_attachments,
    ...filestorage_sagas_entry_gallery_presigned_urls,
    ...filestorage_sagas_submission_entry_gallery,
    ...filestorage_sagas_submission_to_s3,
]

export default arr
