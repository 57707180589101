import { generateShowroomYearsDropdown } from '../conversions/dropdowns/showroomYears'
import { IFilterID, IShowroomFiltersState } from './types'

// const coloursOptions = [
//     {
//         name: 'Beige',
//         id: 'beige',
//     },
//     {
//         name: 'Black',
//         id: 'black',
//     },
//     {
//         name: 'Blue',
//         id: 'blue',
//     },
//     {
//         name: 'Bronze',
//         id: 'bronze',
//     },
//     {
//         name: 'Brown',
//         id: 'brown',
//     },
//     {
//         name: 'Burgundy',
//         id: 'burgundy',
//     },
//     {
//         name: 'Gold',
//         id: 'gold',
//     },
//     {
//         name: 'Green',
//         id: 'green',
//     },
//     {
//         name: 'Grey',
//         id: 'grey',
//     },
//     {
//         name: 'Indigo',
//         id: 'indigo',
//     },
//     {
//         name: 'Magenta',
//         id: 'magenta',
//     },
//     {
//         name: 'Maroon',
//         id: 'maroon',
//     },
//     {
//         name: 'Multicolour',
//         id: 'multicolour',
//     },
//     {
//         name: 'Navy',
//         id: 'navy',
//     },
//     {
//         name: 'Orange',
//         id: 'orange',
//     },
//     {
//         name: 'Pink',
//         id: 'pink',
//     },
//     {
//         name: 'Purple',
//         id: 'purple',
//     },
//     {
//         name: 'Red',
//         id: 'red',
//     },
//     {
//         name: 'Silver',
//         id: 'silver',
//     },
//     {
//         name: 'Turquoise',
//         id: 'turquoise',
//     },
//     {
//         name: 'Tan',
//         id: 'tan',
//     },
//     {
//         name: 'White',
//         id: 'white',
//     },
//     {
//         name: 'Yellow',
//         id: 'yellow',
//     },
// ]

export const filters_id_array_all: IFilterID[] = [
    'make',
    'model',
    'year',
    'price',
    'location',
    'type_of_sale',
    'keyword',
    'mileage',
    'colour',
    'body_type',
    'drive_side',
    'more_filters',
]
// 'more_filters',

export const filters_id_array_moreFilters: IFilterID[] = [
    'colour',
    'body_type',
    'drive_side',
]

export const default_showroomFilters: IShowroomFiltersState = {
    make: {
        id: 'make',
        caption: 'Available makes',
        displayValue: '',
        values: [],
        name: 'Make',
        type: 'multiselect',
        isActive: false,
    },
    model: {
        id: 'model',
        caption: 'Available models',
        displayValue: '',
        values: [],
        name: 'Model',
        type: 'multiselect',
        isActive: false,
    },
    location: {
        id: 'location',
        name: 'Location',
        caption: '',
        displayValue: '',
        values: {
            lat: undefined,
            lng: undefined,
            distance: undefined,
        },
        options: [],
        type: 'naked_single_select', // region options
        isActive: false,
    },
    year: {
        id: 'year',
        name: 'Year',
        caption: 'Choose era or enter custom date range.',
        value: undefined,
        options: generateShowroomYearsDropdown(),
        valueFrom: 0,
        valueTo: 0,
        isActive: false,
        displayValue: '',
    },
    type_of_sale: {
        id: 'type_of_sale',
        name: 'Type of sale',
        caption: '',
        value: undefined,
        options: [
            {
                id: 'set_price',
                name: 'Set price',
            },
            {
                id: 'price_range',
                name: 'Price range',
            },
            {
                id: 'PRICE_ON_ASKING',
                name: 'POA',
            },
        ],
        isActive: false,
        type: 'radio_select',
        displayValue: '',
    },
    price: {
        id: 'price',
        name: 'Price',
        caption: '',
        valueFrom: undefined,
        valueTo: undefined,
        isActive: false,
        types: 'range_value',
        displayValue: '',
    },
    keyword: {
        id: 'keyword',
        name: 'Keyword',
        caption: `Add search terms by pressing the 'Enter' key and then press Apply to search`,
        mobileCaption: `Type a word or a phrase you’re looking for and press the "next" button on your keyboard to add it as a filter.`,
        values: [],
        isActive: false,
        displayValue: '',
        type: 'text_search',
    },
    mileage: {
        id: 'mileage',
        name: 'Mileage',
        caption: '',
        options: [],
        valueFrom: undefined,
        valueTo: undefined,
        isActive: false,
        type: 'range_value',
        displayValue: '',
    },
    colour: {
        id: 'colour',
        name: 'Colour',
        caption: '',
        values: [],

        options: [],
        isActive: false,
        type: 'multiselect',
        displayValue: '',
    },
    body_type: {
        id: 'body_type',
        name: 'Body Type',
        caption: '',
        values: [],
        isActive: false,
        type: 'multiselect',
        displayValue: '',
    },
    drive_side: {
        id: 'drive_side',
        name: 'Drive Side',
        caption: '',
        value: undefined,
        options: [
            {
                id: 'Left',
                name: 'LHD (Left hand drive)',
            },
            { id: 'Right', name: 'RHD (Right hand drive)' },
        ],
        isActive: false,
        type: 'radio_select',
        displayValue: '',
    },
    more_filters: {
        id: 'more_filters',
        name: 'More Filters',
    },
}
