export const numDaysBetween = (date1: any, date2: any) => {
    let d1 = new Date(date1)
    let d2 = new Date(date2)

    var diff = Math.abs(d1.getTime() - d2.getTime())
    return diff / (1000 * 60 * 60 * 24)
}

export const isDateWithinAMonth = (d: any) => {
    let n_days = numDaysBetween(d, Date.now())
    if (n_days <= 30) {
        return true
    } else return false
}

export const hasDateExpired = (d1: any, d2?: any): boolean => {
    let date1 = new Date(d1)
    let date2 = d2 ? new Date(d2) : new Date()

    let diff = date2.getTime() - date1.getTime()
    if (diff > 0) {
        return true
    } else return false
}
