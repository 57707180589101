import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'

// import { userIdFromDecodedToken } from '../../../redux/services/authcheck/index'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

interface PrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any
    store: any
}

interface OtherProps {
    setInitialLandingUrl: any
}

// const PrivateRoute = (props: PrivateRouteProps) => {

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

class PrivateRoute extends React.Component<PrivateRouteProps & OtherProps, {}> {
    componentDidMount() {}
    render() {
        const { component: Component, store, ...rest } = this.props

        return (
            <Route
                {...rest}
                render={(routeProps) => {
                    return <Component {...routeProps} />

                    // if (authorizePath !== false) {
                    //     return <Component {...routeProps} />
                    // } else {
                    //     //  DO FUNC
                    //     this.props.setInitialLandingUrl(
                    //         window.location.pathname
                    //     )
                    //     return (
                    //         <Redirect
                    //             to={{
                    //                 pathname: '/signin',
                    //                 state: { from: routeProps.location },
                    //             }}
                    //         />
                    //     )
                    // }
                }}
            />
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(PrivateRoute)
