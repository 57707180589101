import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import GalleryOverviewItemDesktop from '../../../molecules/imageGrid/galleryOverviewItemDesktop'

import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { IGalleryImage } from 'entityModels'
import ImageTagDesktop from '../../../atoms/signs/imageSignDesktop'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import cover_image from '../../../atoms/skeleton/gallery/cover_image.jpg'
import cover_image_dark from '../../../atoms/skeleton/gallery/cover_image_dark.png'
import AddBox from '../../../atoms/Button/addBox'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'

interface StyleProps {
    isImgLoaded: boolean
    hasOpacity?: boolean
}
const WrapperSizeHighlightImg = styled.div`
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: relative;
    flex: 1;
    z-index: 1;
`

const HighlightImage = styled.div<StyleProps>`
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: ${(props) => (props.isImgLoaded === false ? 1 : 0)};
    transition: all 400ms;

    @media ${device.beyond_ipad_mobile} {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    transition: opacity 400ms;
`

const HideThis = styled.div`
    display: none;
`

const Wrapper = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex: 1;
    }
`

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100vw;
    height: 100%;
    grid-gap: 20px;
    justify-items: baseline;
    height: 30vh;
    min-height: 450px;
    max-height: 500px;

    @media ${device.beyond_ipad_mobile} {
        display: grid;
        grid-template-columns: 0.65fr auto;
        width: 100%;
        height: 100%;
        grid-gap: 20px;
        justify-items: baseline;
        height: 30vh;
        min-height: 400px;
        max-height: 500px;
    }

    @media ${device.large_desktop} {
        grid-template-columns: 0.65fr auto;
        min-height: 500px;
        max-height: 580px;
    }
`

const GridContainerCells = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
        height: 100%;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    height: 100%;
`

const ImgOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100px;
    transition: 200ms all;
    background: linear-gradient(#000 50%, #000 100%);
    z-index: 7;
`

export interface IImageProps {
    url: string
}

interface Props extends RouteComponentProps<any> {
    images_array: IGalleryImage[] | null
    coverimg: IGalleryImage | null
    onClick?: any
    history: any
    carid: string
    count: number
    readOnlyMode?: boolean
    onAddClick?: any
}

type State = {
    src: any
    addBox: boolean
    isHighlightImgLoading: boolean
    imgOverlay: boolean
}

class GalleryOverviewManagerDesktop extends React.Component<Props, State> {
    state = {
        src: null,
        addBox: false,
        isHighlightImgLoading: true,
        imgOverlay: false,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true

        if (this.props.coverimg) {
            const src: string = this.props.coverimg.url
            this._isMounted &&
                this.setState({ src, isHighlightImgLoading: true })

            this.onLoad(src)
        } else if (!this.props.coverimg) {
            this._isMounted && this.setState({ isHighlightImgLoading: false })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.coverimg !== prevProps.coverimg) {
            if (this.props.coverimg) {
                const src: string = this.props.coverimg.url
                this.setState({ src, isHighlightImgLoading: true })

                this.onLoad(src)
            } else if (!this.props.coverimg) {
                this.setState({ isHighlightImgLoading: false })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                src: src,
                isHighlightImgLoading: false,
            })
    }

    showAddBox = () => this.setState({ addBox: true })

    removeAddBox = () => this.setState({ addBox: false })

    showImageOverlay = () => this.setState({ imgOverlay: true })

    removeImageOverlay = () => this.setState({ imgOverlay: false })

    render() {
        let { isHighlightImgLoading, addBox } = this.state
        // showBoxInfo,
        let { images_array, coverimg, onAddClick, carid, count, readOnlyMode } =
            this.props

        const isEmpty = images_array && images_array.length > 0 ? false : true

        let cover_url = coverimg && coverimg.url

        let n_array = ['0', ' 1', '2', '3', '4', '5', '6']

        let imgLinkPrefix =
            this.props.match.url && this.props.match.url.includes('overview')
                ? this.props.match.url.replace('/overview', '')
                : this.props.match.url

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper>
                        {/* if some images are set as featured:  */}

                        {((readOnlyMode && !isEmpty) || !readOnlyMode) && (
                            <GridContainer>
                                <WrapperSizeHighlightImg
                                    onMouseEnter={() => {
                                        !cover_url
                                            ? this.showAddBox()
                                            : this.showImageOverlay()
                                    }}
                                    onMouseLeave={() => {
                                        !cover_url
                                            ? this.removeAddBox()
                                            : this.removeImageOverlay()
                                    }}
                                    onClick={() =>
                                        !cover_url && onAddClick
                                            ? onAddClick()
                                            : this.props.history.push(
                                                  `${imgLinkPrefix}/gallery?id=${
                                                      coverimg && coverimg.id
                                                          ? coverimg.id
                                                          : ''
                                                  }`
                                              )
                                    }
                                >
                                    <SkeletonAbsolute
                                        isthingloading={isHighlightImgLoading}
                                    />

                                    {addBox && !this.props.readOnlyMode ? (
                                        <AddBox />
                                    ) : (
                                        <>
                                            <HighlightImage
                                                role="img"
                                                aria-label="car overview banner"
                                                style={{
                                                    backgroundImage: !cover_url
                                                        ? `url(${
                                                              theme === 'light'
                                                                  ? cover_image
                                                                  : cover_image_dark
                                                          }`
                                                        : this.state.src
                                                        ? `url(${this.state.src}`
                                                        : `url(${grey_placeholder}`
                                                        ? `url(${grey_placeholder}`
                                                        : `url(${cover_url}`,
                                                }}
                                                hasOpacity={false}
                                                isImgLoaded={
                                                    isHighlightImgLoading
                                                }
                                            />
                                            <ImageTagDesktop copy="Car Profile Image" />
                                            <ImgOverlay
                                                style={{
                                                    opacity: this.state
                                                        .imgOverlay
                                                        ? 0.5
                                                        : 0,
                                                }}
                                            />
                                        </>
                                    )}
                                </WrapperSizeHighlightImg>

                                <GridContainerCells>
                                    {n_array.map(
                                        (id: string, index: number) => {
                                            if (index !== 0) {
                                                return (
                                                    <GalleryOverviewItemDesktop
                                                        key={index}
                                                        img={
                                                            images_array &&
                                                            images_array[index]
                                                                ? images_array[
                                                                      index
                                                                  ]
                                                                : undefined
                                                        }
                                                        carid={carid}
                                                        readonlyMode={
                                                            readOnlyMode
                                                        }
                                                        image_number={index}
                                                        lastItemTxt={
                                                            images_array &&
                                                            images_array.length &&
                                                            count > 6
                                                                ? `+ ${
                                                                      count - 6
                                                                  }`
                                                                : 'Gallery'
                                                        }
                                                        openImgForm={() =>
                                                            onAddClick &&
                                                            onAddClick()
                                                        }
                                                    />
                                                )
                                            } else
                                                return <HideThis key={index} />
                                        }
                                    )}
                                </GridContainerCells>
                            </GridContainer>
                        )}

                        {/* IF NO IMAGES: */}
                        {isEmpty === true && readOnlyMode ? (
                            <NoDataRectangle text="no images" height="300px" />
                        ) : null}
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(GalleryOverviewManagerDesktop)
