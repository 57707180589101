import { IGalleryImage } from 'entityModels'
import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import grey_placeholder_dark from '../../../public/assets/placeholder/grey_placeholder_dark.jpg'
import { device } from '../../templates/displays/devices'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import SimpleTag from '../tags/simpleTag'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

interface StyleProps {
    isthingloading: boolean
    $customIpadHeight?: string
    $customHeight?: string
}

const ImageSizeWrapper = styled.div<StyleProps>`
    position: relative;
    width: 100%;
    @media ${device.ipad} {
        height: ${(props: StyleProps) => props.$customIpadHeight ?? '26vh'};
    }
`

const GalleryImage = styled.img<StyleProps>`
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.isthingloading === true ? '0' : '1')};
    transition: opacity 400ms;

    height: ${(props: StyleProps) => props.$customHeight ?? '126px'};

    @media ${device.ipad} {
        height: ${(props: StyleProps) => props.$customIpadHeight ?? '26vh'};
    }
`

type State = {
    src: any
    isLoading: boolean
}

interface Props {
    img?: IGalleryImage
    placeholder?: string
    customHeight?: string
    customIpadHeight?: string
    tag?: string
}

class DuoGridItemMobile extends React.Component<Props, State> {
    state = {
        src: null,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src =
            this.props.img && this.props.img.url !== undefined
                ? this.props.img.url
                : this.props.placeholder
                ? this.props.placeholder
                : undefined

        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.img?.url !== prevProps.img?.url) {
            const src =
                this.props.img && this.props.img.url !== undefined
                    ? this.props.img.url
                    : this.props.placeholder
                    ? this.props.placeholder
                    : undefined

            if (src) {
                return this.setState({ src: src, isLoading: true })
            }
        } else if (
            this.props.img?.url === undefined &&
            this.props.placeholder !== prevProps.placeholder
        ) {
            const src = this.props.placeholder
                ? this.props.placeholder
                : undefined

            if (src) {
                return this.setState({ src: src, isLoading: true })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { img, customIpadHeight, customHeight, tag } = this.props
        const { isLoading } = this.state
        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <ImageSizeWrapper
                        isthingloading={isLoading}
                        $customIpadHeight={customIpadHeight}
                    >
                        <SkeletonAbsolute isthingloading={isLoading} />

                        <GalleryImage
                            aria-label={
                                img && img.caption ? img.caption : 'picture'
                            }
                            src={
                                this.state.src
                                    ? this.state.src
                                    : theme === 'dark'
                                    ? grey_placeholder_dark
                                    : grey_placeholder
                            }
                            srcSet={
                                img && img.small_srcset
                                    ? img.small_srcset
                                    : undefined
                            }
                            isthingloading={isLoading}
                            onLoad={() =>
                                this._isMounted &&
                                this.setState({
                                    ...this.state,
                                    isLoading: false,
                                })
                            }
                            $customHeight={customHeight}
                            $customIpadHeight={customIpadHeight}
                        />
                        {tag && (
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    bottom: 10,
                                }}
                            >
                                <SimpleTag text={tag} />
                            </div>
                        )}
                    </ImageSizeWrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default DuoGridItemMobile
