import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { AddIcon } from '../statefulicons'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const GoToBox = styled.div<{
    $variant?: 'underlay'
    $customUnderlayColour?: string
    $theme: ITheme
}>`
    background-color: ${(props) =>
        props.$variant === 'underlay'
            ? props.$customUnderlayColour ??
              colours[props.$theme].background_primary_subtle
            : colours[props.$theme].primary_16};
    outline: ${(props) =>
        props.$variant === 'underlay' ? 'none' : colours[props.$theme].primary};
    outline-offset: -1px;
    height: 100%;
    width: 100%;
    position: relative;
    box-sizing: border-box;

    @media ${device.beyond_ipad_mobile} {
        background-color: ${(props) =>
            props.$variant === 'underlay'
                ? props.$customUnderlayColour ??
                  colours[props.$theme].background_primary_subtle
                : colours[props.$theme].primary_16};

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        transition: all 300ms;
        color: ${(props) => colours[props.$theme].primary};
    }

    cursor: pointer;
    transition: all 300ms;
    color: ${(props) => colours[props.$theme].primary};
`
type Props = {
    onAddClick?: any
    borderRadius?: string
    variant?: 'underlay'
    customActiveColour?: string
    customUnderlayColour?: string
}

const AddBox = (props: Props) => {
    const { theme } = useThemes()
    return (
        <GoToBox
            $customUnderlayColour={props.customUnderlayColour}
            $theme={theme}
            $variant={props.variant}
            role="button"
            onClick={() => {
                props.onAddClick && props.onAddClick()
            }}
            style={
                props.borderRadius ? { borderRadius: props.borderRadius } : {}
            }
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-9px, -9px)',
                }}
            >
                <AddIcon
                    height="18px"
                    width="18px"
                    onClick={(e: any) => {}}
                    colour={props.customActiveColour}
                />
            </div>
        </GoToBox>
    )
}

export default AddBox
