import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// import { device } from '../../../templates/displays/devices'
import DatePickerAntd from '../../../atoms/datePickerAntd/datePickerAntd'
// import Icon from '../../../atoms/icons'
import dayjs from 'dayjs'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
// import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'

const WrapperDesktop = styled.section`
    width: 100%;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
`

const FieldTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    width: 30%;
    max-width: 180px;
    font-family: Lato-light;
    align-self: start;
    font-size: 16px;
    text-transform: capitalize;
`

const InputFieldWrapper = styled.div`
    width: 70%;
    max-width: 460px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

// const SmallPadding = styled.div`
//     padding-right: 17px;
// `

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_basic_field_desktop: string
}

interface OtherProps {
    data: IEditOrCreateModeSingleFieldsProps
    dataCyId?: string
    isDisabled?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        data,
        // errors,
        setFieldValue,
        isDisabled,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const handleAnswerChange = (dateString: any) => {
        setFieldValue(
            'single_item_edited_value_basic_field_desktop',
            dateString
        )
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                dateString,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, dateString)
        }
    }

    return (
        <WrapperDesktop>
            <FieldTitle>{data.title}</FieldTitle>
            <InputFieldWrapper>
                <DatePickerAntd
                    onChange={handleAnswerChange}
                    value={values.single_item_edited_value_basic_field_desktop}
                    disabled={isDisabled}
                />
                {/* <SmallPadding /> */}
                {/* <Icon icon="grey_cross_w_circle" /> */}
            </InputFieldWrapper>
        </WrapperDesktop>
    )
}

const BasicEditOrCreateModeSingleFieldDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_basic_field_desktop: props.data.value_start
            ? dayjs(props.data.value_start).format('YYYY-MM-DD')
            : '',
    }),
    enableReinitialize: true,
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(BasicEditOrCreateModeSingleFieldDesktop)
