export const editFormIDs = [
    'overviewCarEditForm',
    'carGalleryEditForm',
    'carExistingGalleryImageEditForm',
    'carHighlightsEditForm',
    'carDescriptionEditForm',
    'carKeyFactsMobileForm',
    'accountExternalInfoForm',
    'accountPrivateInfoForm',
    'userAccountAddressInformationForm',
    'carTechInfoEditForm',
    'addCarForm',
    'carBannerImageForm',
    'userProfileImageForm',
    'updateTaskForm',
    'createTaskForm',
    'sortBy',
    'filterBy',
    'searchMode',
]
