import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Car,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'

import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { IAddressActiveModal } from '../../../../organisms/insuranceQuote/addressesManager'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import { InsuranceFormQuestion } from '../../../../organisms/insuranceQuote/formGenerators/renderElementDesktop'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { CarParams } from '../../dashboards/insuranceApplicationCarDashboard'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import { AddressWindow } from '../../../../organisms/insuranceQuote/addressesManager/addressWindow'
import InsuranceAddressCardV2 from '../../../../molecules/cards/insurance/addressCardDesktopV2'
import {
    insurance_fields_car_mileage_location_type_if_same_as_residential,
    insurance_fields_car_mileage_parking_1,
    insurance_fields_car_mileage_parking_added_address_same_as_residential,
    insurance_fields_car_mileage_parking_day_place,
    insurance_fields_car_mileage_parking_day_place_nigth_same,
    insurance_fields_car_mileage_parking_night_place,
    insurance_fields_car_mileage_parking_added_address_different_than_residential,
    insurance_fields_car_mileage_parking_security,
    insurance_fields_parking_info,
} from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import Expander from '../../../../atoms/expander/expander'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'
import info_red from '../../../../../public/assets/icons/info_red.svg'
import colours, {
    insurance_colours,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`

const InsuranceCarMileageParkingApplicationFlow = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { carid } = useParams<CarParams>()

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid]
                ?.mileage_parking ?? {}
        )
    })

    const data_day_parking_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid] &&
            state.insuranceQuoteApplication.draft.vehicles[carid]
                .daytime_parking_info
        )
    })

    const data_night_parking_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid] &&
            state.insuranceQuoteApplication.draft.vehicles[carid]
                .eveningtime_parking_info
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[carid]
            ?.mileage_parking_errors
    })

    const eveningtime_parking_info_errors: IAnyErrorString | undefined =
        useAppSelector((state) => {
            return state.insuranceQuoteApplication.draft.vehicles[carid]
                ?.eveningtime_parking_info_errors
        })

    const daytime_parking_info_errors: IAnyErrorString | undefined =
        useAppSelector((state) => {
            return state.insuranceQuoteApplication.draft.vehicles[carid]
                ?.daytime_parking_info_errors
        })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        if (errors && errors[id]) {
            setError(id, undefined)
        }

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }
        dispatch(
            insuranceActions.set_answer_insurance_car_mileage_parking({
                id: id,
                answer: answer,
                carid: carid,
            })
        )
    }

    let onParkingInfoAnswerChange = (
        id: string,
        answer: any,
        type: 'evening' | 'day'
    ) => {
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }

        let isBoth =
            data_draft['parking_place_same_day_night'] &&
            data_draft['parking_place_same_day_night'] === true
                ? true
                : false

        if (isBoth) {
            if (id === 'has_location_security' && answer === true) {
                if (
                    data_day_parking_draft['location_security'] &&
                    data_day_parking_draft['location_security'].includes(
                        'NO_SECURITY'
                    )
                ) {
                    dispatch(
                        insuranceActions.set_answer_insurance_car_mileage_parking_info(
                            {
                                id: 'location_security',
                                answer: undefined,
                                carid: carid,
                                type: 'day',
                            }
                        )
                    )
                }
            }

            dispatch(
                insuranceActions.set_answer_insurance_car_mileage_parking_info({
                    id: id,
                    answer: answer,
                    carid: carid,
                    type: 'day',
                })
            )
            dispatch(
                insuranceActions.set_answer_insurance_car_mileage_parking_info({
                    id: id,
                    answer: answer,
                    carid: carid,
                    type: 'evening',
                })
            )
        } else {
            dispatch(
                insuranceActions.set_answer_insurance_car_mileage_parking_info({
                    id: id,
                    answer: answer,
                    carid: carid,
                    type: type,
                })
            )

            if (
                id === 'has_location_security' &&
                answer === true &&
                type === 'day'
            ) {
                if (
                    data_day_parking_draft['location_security'] &&
                    data_day_parking_draft['location_security'].includes(
                        'NO_SECURITY'
                    )
                ) {
                    dispatch(
                        insuranceActions.set_answer_insurance_car_mileage_parking_info(
                            {
                                id: 'location_security',
                                answer: undefined,
                                carid: carid,
                                type: 'day',
                            }
                        )
                    )
                }
            }

            if (
                id === 'has_location_security' &&
                answer === true &&
                type === 'evening'
            ) {
                if (
                    data_night_parking_draft['location_security'] &&
                    data_night_parking_draft['location_security'].includes(
                        'NO_SECURITY'
                    )
                ) {
                    dispatch(
                        insuranceActions.set_answer_insurance_car_mileage_parking_info(
                            {
                                id: 'location_security',
                                answer: undefined,
                                carid: carid,
                                type: 'evening',
                            }
                        )
                    )
                }
            }
        }

        if (errors && errors['is_kept_at_customer_residential_address']) {
            setError('is_kept_at_customer_residential_address', undefined)
        }

        if (
            type === 'day' &&
            daytime_parking_info_errors &&
            daytime_parking_info_errors[id]
        ) {
            setError(id, undefined, 'day')
        }

        if (
            type === 'evening' &&
            eveningtime_parking_info_errors &&
            eveningtime_parking_info_errors[id]
        ) {
            setError(id, undefined, 'night')
        }
    }

    let setError = (
        id: string,
        error: string | undefined,
        parking?: 'day' | 'night'
    ) => {
        if (!parking) {
            dispatch(
                insuranceActions.set_error_insurance_car_mileage_parking({
                    id: id,
                    error: error,
                    carid: carid,
                })
            )
        }

        if (parking === 'day') {
            dispatch(
                insuranceActions.set_error_insurance_car_mileage_parking_day({
                    id: id,
                    error: error,
                    carid: carid,
                })
            )
        }

        if (parking === 'night') {
            dispatch(
                insuranceActions.set_error_insurance_car_mileage_parking_evening(
                    {
                        id: id,
                        error: error,
                        carid: carid,
                    }
                )
            )
        }
    }

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
    }, [])

    const addresses = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.addresses
    })

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let user_residential_address_uid =
        applicationData &&
        applicationData.main_driver &&
        applicationData.main_driver.licence_address &&
        applicationData.main_driver.licence_address['residential_address']
            ? applicationData.main_driver.licence_address['residential_address']
            : undefined

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let { getUserAndApplicationData, setInitialCarMileageAndParkingDropdowns } =
        useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
        setInitialCarMileageAndParkingDropdowns(carid)
    }, [userLoggedIn])

    useEffect(() => {
        if (
            user_residential_address_uid !== undefined &&
            errors &&
            (errors['is_kept_at_customer_residential_address'] ||
                errors['travels_to_home_address'])
        ) {
            if (isSavedDisabled) {
                setIsSavedDisabled(false)
            }
            if (hasFormChanged === false) {
                setHasFormChanged(true)
            }

            setError('is_kept_at_customer_residential_address', undefined)
            if (
                data_draft['is_kept_at_customer_residential_address'] === true
            ) {
                setError('travels_to_home_address', undefined)
            }
        }
    }, [user_residential_address_uid])

    const [activeModal, toggleActiveModal] =
        useState<IAddressActiveModal>(undefined)

    let day_park_address_id = data_draft['day_park_address_id'] ?? undefined
    let night_park_address_id = data_draft['night_park_address_id'] ?? undefined

    const hasDayParkingErr = () => {
        let hasErr = false
        if (data_day_parking_draft['has_location_security']) {
            if (
                !data_day_parking_draft['location_security'] ||
                (data_day_parking_draft['location_security'] &&
                    data_day_parking_draft['location_security'].length === 0)
            ) {
                hasErr = true
                setError(
                    'location_security',
                    'Please fill in the information',
                    'day'
                )
            }
        }

        if (data_night_parking_draft['has_location_security']) {
            if (
                !data_night_parking_draft['location_security'] ||
                (data_night_parking_draft['location_security'] &&
                    data_night_parking_draft['location_security'].length === 0)
            ) {
                hasErr = true
                setError(
                    'location_security',
                    'Please fill in the information',
                    'night'
                )
            }
        }

        return hasErr
    }

    // let hasError = hasErrorFunc()

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        let hasErrHere = hasDayParkingErr()

        if (hasErrHere === true) {
            hasErr2 = true
        }

        if (data_draft['is_kept_at_customer_residential_address'] === true) {
            if (data_day_parking_draft['location_type'] === undefined) {
                hasErr2 = true
                dispatch(
                    insuranceActions.set_error_insurance_car_mileage_parking_day(
                        {
                            id: 'location_type',
                            error: 'Missing',
                            carid: carid,
                        }
                    )
                )
            }
        }

        if (
            data_draft['has_security_device_fitted'] === true ||
            data_draft['has_security_device_fitted']?.name === 'Yes'
        ) {
            if (!data_draft['security_device_type']) {
                setError('security_device_type', 'Required field.')
                hasErr2 = true
            }

            // if (!data_draft['security_device_fitted_by']) {
            //     setError('security_device_fitted_by', 'Required field.')
            //     hasErr2 = true
            // }

            // if (!data_draft['security_device_fitted_date']) {
            //     setError('security_device_fitted_date', 'Required field.')
            //     hasErr2 = true
            // }
        }
        for (
            let i = 0;
            i < insurance_fields_car_mileage_parking_1.length;
            i++
        ) {
            if (
                insurance_fields_car_mileage_parking_1[i].is_required ===
                    true &&
                data_draft[insurance_fields_car_mileage_parking_1[i].id] ===
                    undefined
            ) {
                setError(
                    insurance_fields_car_mileage_parking_1[i].id,
                    'Required field.'
                )

                hasErr2 = true
            }
        }

        if (
            data_draft['is_kept_at_customer_residential_address'] ===
                undefined ||
            data_draft['is_kept_at_customer_residential_address'] === null
        ) {
            // if user didn't answer if car is kept at residential address
            setError(
                'is_kept_at_customer_residential_address',
                'Required field.'
            )
            hasErr2 = true
        }
        if (
            data_draft['is_kept_at_customer_residential_address'] !==
                undefined &&
            data_draft['is_kept_at_customer_residential_address'] === true
        ) {
            if (user_residential_address_uid === undefined) {
                setError(
                    'is_kept_at_customer_residential_address',
                    'Please provide with the information below.'
                )
                hasErr2 = true
            }

            // if car is kept at residential address cleanup all other fields
        }

        if (
            data_draft['is_kept_at_customer_residential_address'] === false &&
            data_draft[`travels_to_home_address`] === true
        ) {
            if (user_residential_address_uid === undefined) {
                setError(
                    'travels_to_home_address',
                    'Please provide your residential address above.'
                )
                hasErr2 = true
            }
        }

        // if user dind't answer if car is not kept at residential address
        if (data_draft['is_kept_at_customer_residential_address'] !== true) {
            if (
                data_draft['parking_place_same_day_night'] === undefined ||
                data_draft['parking_place_same_day_night'] === null
            ) {
                setError('parking_place_same_day_night', 'Required field.')
                hasErr2 = true
            } else if (
                data_draft['parking_place_same_day_night'] &&
                data_draft['parking_place_same_day_night'] === true
            ) {
                // if car is kept day and night at same address

                // if no day parking address
                if (!day_park_address_id) {
                    setError('day_park_address_id', 'Required field.')
                    hasErr2 = true
                }

                if (!data_day_parking_draft['location_type']) {
                    setError(
                        'location_type',
                        'Please fill in the information',
                        'day'
                    )
                    hasErr2 = true
                }

                // if no other fields / fields missing
                for (let a = 0; a < insurance_fields_parking_info.length; a++) {
                    let fieldidd = insurance_fields_parking_info[a].id
                    if (
                        (data_day_parking_draft[fieldidd] === undefined ||
                            data_day_parking_draft[fieldidd] === null) &&
                        !insurance_fields_parking_info[a].isDeprecated
                    ) {
                        setError(
                            fieldidd,
                            'Please fill in the information',
                            'day'
                        )
                        hasErr2 = true
                    }
                }
            } else if (data_draft['parking_place_same_day_night'] === false) {
                // if car is kept day and night at same address
                if (!day_park_address_id) {
                    setError('day_park_address_id', 'Required field.')
                    hasErr2 = true
                }

                if (!night_park_address_id) {
                    setError('night_park_address_id', 'Required field.')
                    hasErr2 = true
                }

                if (!data_day_parking_draft['location_type']) {
                    setError(
                        'location_type',
                        'Please fill in the information',
                        'day'
                    )
                    hasErr2 = true
                }

                if (!data_night_parking_draft['location_type']) {
                    setError(
                        'location_type',
                        'Please fill in the information',
                        'night'
                    )
                    hasErr2 = true
                }

                for (let a = 0; a < insurance_fields_parking_info.length; a++) {
                    let fieldidd = insurance_fields_parking_info[a].id
                    if (
                        (data_day_parking_draft[fieldidd] === undefined ||
                            data_day_parking_draft[fieldidd] === null) &&
                        !insurance_fields_parking_info[a].isDeprecated
                    ) {
                        setError(
                            fieldidd,
                            'Please fill in the information',
                            'day'
                        )
                        hasErr2 = true
                    }

                    if (
                        (data_night_parking_draft[fieldidd] === undefined ||
                            data_night_parking_draft[fieldidd] === null) &&
                        !insurance_fields_parking_info[a].isDeprecated
                    ) {
                        setError(
                            fieldidd,
                            'Please fill in the information',
                            'night'
                        )
                        hasErr2 = true
                    }
                }
            }

            // if night address is different

            // check secutity fields

            for (
                let i = 0;
                i < insurance_fields_car_mileage_parking_security.length;
                i++
            ) {
                if (
                    insurance_fields_car_mileage_parking_security[i]
                        .is_required === true &&
                    data_draft[
                        insurance_fields_car_mileage_parking_security[i].id
                    ] === undefined
                ) {
                    setError(
                        insurance_fields_car_mileage_parking_security[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }
        }
        return hasErr2
    }

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carMileageParking_request({
                    carid: carid,
                })
            )
            history.push(`/insurance/application/${id}/car/${carid}`)
        } else {
            setIsSavedDisabled(true)
        }
    }

    let vehicle: IInsuranceQuoteApplication_Car | undefined =
        applicationData.vehicles[carid]

    let generateTitle = (): string => {
        if (vehicle && vehicle.tech_info) {
            if (
                vehicle.tech_info?.manufacture_year ||
                vehicle.tech_info?.make?.name ||
                vehicle.tech_info?.model?.name
            ) {
                return `${
                    vehicle.tech_info?.manufacture_year
                        ? `${vehicle.tech_info.manufacture_year.toString()} `
                        : ''
                }${
                    vehicle.tech_info?.make?.name
                        ? `${vehicle.tech_info.make.name} `
                        : ''
                }${
                    vehicle.tech_info?.model?.name
                        ? vehicle.tech_info.model.name
                        : ''
                }`
            } else {
                return 'Untitled car'
            }
        } else return ''
    }

    const { theme } = useThemes()

    return (
        <>
            {userLoggedIn && applicationData?.id && (
                <div
                    style={{
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        backgroundColor:
                            colours[theme].background_neutral_subtle,
                    }}
                >
                    <DesktopDisplayOnly>
                        <InsuranceTopBarWithProgressionRate
                            breadCrumbs={[
                                {
                                    pageName: generateTitle(),
                                    pageUrl: () =>
                                        history.push(
                                            `/insurance/application/${id}/car/${carid}`
                                        ),
                                    isCaps: true,
                                },
                                { pageName: 'Mileage & Parking' },
                            ]}
                            hasFormChanged={hasFormChanged}
                            onSave={saveForm}
                            sectionId="vehicles_to_insure"
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: '100%',
                                paddingTop: 120,
                                paddingBottom: 120,
                                maxWidth: 442,
                                minWidth: 442,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                sectionID="vehicles_to_insure"
                                list={insurance_fields_car_mileage_parking_1}
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                            />

                            <div style={{ paddingTop: '16px' }} />
                            <InsuranceQuoteFormGenerator
                                sectionID="vehicles_to_insure"
                                list={
                                    insurance_fields_car_mileage_parking_added_address_same_as_residential
                                }
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                            />

                            <div
                                style={{
                                    position: 'relative',
                                    zIndex: 101,
                                    width: '100%',
                                }}
                            >
                                <Expander
                                    height={
                                        data_draft[
                                            'is_kept_at_customer_residential_address'
                                        ] === true
                                            ? 'auto'
                                            : 0
                                    }
                                    width="100%"
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            paddingTop: 16,
                                        }}
                                    >
                                        <InsuranceQuoteFormGenerator
                                            sectionID="vehicles_to_insure"
                                            list={
                                                insurance_fields_car_mileage_location_type_if_same_as_residential
                                            }
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'day'
                                                )
                                            }}
                                            data={data_day_parking_draft}
                                            errors={daytime_parking_info_errors}
                                            setError={setError}
                                        />
                                    </div>
                                    <div style={{ paddingTop: '16px' }} />
                                </Expander>
                            </div>

                            <Expander
                                height={
                                    user_residential_address_uid ===
                                        undefined &&
                                    (data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === true ||
                                        data_draft[
                                            'travels_to_home_address'
                                        ] === true)
                                        ? 'auto'
                                        : 0
                                }
                                width="100%"
                            >
                                <>
                                    <div
                                        style={{
                                            backgroundColor:
                                                colours[theme].error_underlay,
                                            width: '100%',
                                            padding: '20px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: '10px',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            src={info_red}
                                            alt="info-red"
                                            style={{ height: '20px' }}
                                        />

                                        <InsuranceFormQuestion
                                            $theme={theme}
                                            style={{ color: '#DF6F6F' }}
                                        >
                                            Please provide your residential
                                            address as it hasn't been previously
                                            filled. You can make changes later
                                            from your addressbook.
                                        </InsuranceFormQuestion>
                                    </div>

                                    <div style={{ paddingTop: 16 }} />

                                    <ButtonAtom
                                        theme="tertiary"
                                        textTransform="none"
                                        width={'100%'}
                                        customBgColor="transparent"
                                        onClick={() => {
                                            toggleActiveModal({
                                                active_modal: 'select',
                                                field_id: 'residential_address',
                                                title: 'Select & Save residential address',
                                            })
                                        }}
                                        color={
                                            insurance_colours[theme].sections[
                                                'vehicles_to_insure'
                                            ].primary_100
                                        }
                                    >
                                        Add address
                                    </ButtonAtom>

                                    <div style={{ paddingTop: 16 }} />

                                    <Expander
                                        height={
                                            errors &&
                                            errors['day_park_address_id']
                                                ? 'auto'
                                                : 0
                                        }
                                    >
                                        <Error
                                            style={{
                                                paddingTop: '8px',
                                            }}
                                        >
                                            {`Parking during the day address is required`}
                                        </Error>
                                    </Expander>
                                </>
                            </Expander>

                            <Expander
                                height={
                                    data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === undefined ||
                                    data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === true
                                        ? 0
                                        : 'auto'
                                }
                                width="100%"
                            >
                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: 16,
                                        position: 'relative',
                                        zIndex: 101,
                                    }}
                                >
                                    <InsuranceQuoteFormGenerator
                                        sectionID="vehicles_to_insure"
                                        list={
                                            insurance_fields_car_mileage_parking_added_address_different_than_residential
                                        }
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onAnswerChange(id, answer)
                                            // if (answer === true) {
                                            dispatch(
                                                insuranceActions.reset_insurance_car_mileage_parking_info(
                                                    {
                                                        carid: carid,
                                                        type: 'evening',
                                                    }
                                                )
                                            )

                                            dispatch(
                                                insuranceActions.set_answer_insurance_car_mileage_parking(
                                                    {
                                                        id: 'night_park_address_id',
                                                        answer: null,
                                                        carid: carid,
                                                    }
                                                )
                                            )
                                            // }
                                        }}
                                        data={data_draft}
                                        errors={errors}
                                        setError={setError}
                                    />
                                </div>

                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: 16,
                                        position: 'relative',
                                        zIndex: 100,
                                    }}
                                >
                                    <InsuranceQuoteFormGenerator
                                        sectionID="vehicles_to_insure"
                                        list={
                                            data_draft[
                                                'parking_place_same_day_night'
                                            ] !== false
                                                ? insurance_fields_car_mileage_parking_day_place_nigth_same
                                                : insurance_fields_car_mileage_parking_day_place
                                        }
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'day'
                                            )
                                        }}
                                        data={data_day_parking_draft}
                                        errors={daytime_parking_info_errors}
                                        setError={setError}
                                    />
                                </div>
                                <div style={{ paddingTop: 16 }} />
                                {day_park_address_id ? (
                                    <div style={{ width: '100%' }}>
                                        <InsuranceAddressCardV2
                                            address={
                                                addresses[day_park_address_id]
                                            }
                                            onChange={() => {
                                                toggleActiveModal({
                                                    active_modal: 'select',
                                                    field_id:
                                                        'day_park_address_id',
                                                    title: 'Select address',
                                                })
                                            }}
                                            sectionID="vehicles_to_insure"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <InsuranceFormQuestion $theme={theme}>
                                            Please provide the location where
                                            the vehicle is usually kept during
                                            the day:
                                        </InsuranceFormQuestion>
                                        <div style={{ paddingTop: 16 }} />
                                        <>
                                            <ButtonAtom
                                                theme="tertiary"
                                                textTransform="none"
                                                width={'100%'}
                                                customBgColor="transparent"
                                                onClick={() => {
                                                    toggleActiveModal({
                                                        active_modal: 'select',
                                                        field_id:
                                                            'day_park_address_id',
                                                        title: 'Select address',
                                                    })
                                                }}
                                                color={
                                                    insurance_colours[theme]
                                                        .sections[
                                                        'vehicles_to_insure'
                                                    ].primary_100
                                                }
                                            >
                                                Add address
                                            </ButtonAtom>
                                            <Expander
                                                height={
                                                    errors &&
                                                    errors[
                                                        'day_park_address_id'
                                                    ]
                                                        ? 'auto'
                                                        : 0
                                                }
                                            >
                                                <Error
                                                    style={{
                                                        paddingTop: '8px',
                                                    }}
                                                >
                                                    {`Parking during the day address is required`}
                                                </Error>
                                            </Expander>
                                        </>
                                    </>
                                )}
                                <div
                                    style={{
                                        paddingTop: 16,
                                    }}
                                />
                                <InsuranceQuoteFormGenerator
                                    sectionID="vehicles_to_insure"
                                    list={insurance_fields_parking_info}
                                    onAnswerChange={(
                                        id: string,
                                        answer: any
                                    ) => {
                                        onParkingInfoAnswerChange(
                                            id,
                                            answer,
                                            'day'
                                        )
                                    }}
                                    data={data_day_parking_draft}
                                    errors={daytime_parking_info_errors}
                                    setError={setError}
                                />

                                <Expander
                                    height={
                                        data_draft[
                                            'parking_place_same_day_night'
                                        ] === undefined ||
                                        data_draft[
                                            'parking_place_same_day_night'
                                        ] === true
                                            ? 0
                                            : 'auto'
                                    }
                                    width="100%"
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            zIndex: 100,
                                        }}
                                    >
                                        <InsuranceQuoteFormGenerator
                                            sectionID="vehicles_to_insure"
                                            list={
                                                insurance_fields_car_mileage_parking_night_place
                                            }
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'evening'
                                                )
                                            }}
                                            data={data_night_parking_draft}
                                            errors={
                                                eveningtime_parking_info_errors
                                            }
                                            setError={setError}
                                        />
                                    </div>
                                    <div style={{ paddingTop: 16 }} />
                                    {night_park_address_id ? (
                                        <div style={{ width: '100%' }}>
                                            <InsuranceAddressCardV2
                                                sectionID="vehicles_to_insure"
                                                address={
                                                    addresses[
                                                        night_park_address_id
                                                    ]
                                                }
                                                onChange={() => {
                                                    toggleActiveModal({
                                                        active_modal: 'select',
                                                        field_id:
                                                            'night_park_address_id',
                                                        title: 'Select address',
                                                    })
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <InsuranceFormQuestion
                                                $theme={theme}
                                            >
                                                Please provide the location
                                                where the vehicle is usually
                                                kept during the night:
                                            </InsuranceFormQuestion>
                                            <div style={{ paddingTop: 16 }} />
                                            <>
                                                <ButtonAtom
                                                    theme="tertiary"
                                                    textTransform="none"
                                                    width={'100%'}
                                                    customBgColor="transparent"
                                                    onClick={() => {
                                                        toggleActiveModal({
                                                            active_modal:
                                                                'select',
                                                            field_id:
                                                                'night_park_address_id',
                                                            title: 'Select address',
                                                        })
                                                    }}
                                                    color={
                                                        insurance_colours[theme]
                                                            .sections[
                                                            'vehicles_to_insure'
                                                        ].primary_100
                                                    }
                                                >
                                                    Add address
                                                </ButtonAtom>
                                                <Expander
                                                    height={
                                                        errors &&
                                                        errors[
                                                            'night_park_address_id'
                                                        ]
                                                            ? 'auto'
                                                            : 0
                                                    }
                                                >
                                                    <Error
                                                        style={{
                                                            paddingTop: '8px',
                                                        }}
                                                    >
                                                        {`Parking during the night address is required`}
                                                    </Error>
                                                </Expander>
                                            </>
                                        </>
                                    )}
                                    <div style={{ paddingTop: 16 }} />
                                    <InsuranceQuoteFormGenerator
                                        sectionID="vehicles_to_insure"
                                        list={insurance_fields_parking_info}
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'evening'
                                            )
                                        }}
                                        data={data_night_parking_draft}
                                        errors={eveningtime_parking_info_errors}
                                        setError={setError}
                                    />
                                    <div style={{ paddingTop: 16 }} />
                                </Expander>
                            </Expander>

                            <InsuranceQuoteFormGenerator
                                sectionID="vehicles_to_insure"
                                list={
                                    insurance_fields_car_mileage_parking_security
                                }
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                            />

                            <div style={{ paddingTop: '200px' }} />
                        </div>

                        <InsuranceGoBackReusableBottomBar
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() =>
                                history.push(
                                    `/insurance/application/${id}/car/${carid}`
                                )
                            }
                            sectionId="vehicles_to_insure"
                        />

                        <AddressWindow
                            sectionID="vehicles_to_insure"
                            removeSideEffectSubmit={true}
                            selectedAddress={
                                activeModal?.field_id === 'residential_address'
                                    ? user_residential_address_uid
                                    : activeModal?.field_id ===
                                      'day_park_address_id'
                                    ? day_park_address_id
                                    : activeModal?.field_id ===
                                      'night_park_address_id'
                                    ? night_park_address_id
                                    : undefined
                            }
                            selectAddress={(addressid: string) => {
                                if (
                                    activeModal?.field_id ===
                                    'residential_address'
                                ) {
                                    dispatch(
                                        insuranceActions.set_answer_main_driver_licenseAddress(
                                            {
                                                id: 'residential_address',
                                                answer: addressid,
                                                userid: userLoggedIn.id,
                                            }
                                        )
                                    )
                                } else {
                                    activeModal?.field_id &&
                                        onAnswerChange(
                                            activeModal?.field_id,
                                            addressid
                                        )
                                }
                            }}
                            activeModal={activeModal}
                            toggleActiveModal={toggleActiveModal}
                            onSaveForm={() => {
                                if (
                                    activeModal?.field_id ===
                                    'residential_address'
                                ) {
                                    dispatch(
                                        insuranceActions.submit_user_residential_address_request()
                                    )
                                } else if (
                                    activeModal?.active_modal === 'create' ||
                                    activeModal?.active_modal === 'edit'
                                ) {
                                    dispatch(
                                        insuranceActions.submit_user_address_request()
                                    )
                                }
                                // else {
                                //     dispatch(
                                //         insuranceActions.submit_carMileageParking_request(
                                //             {
                                //                 carid: carid,
                                //             }
                                //         )
                                //     )
                                // }
                            }}
                        />
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <div style={{ minWidth: '100vw' }}>
                            <InsuranceTopBarWithProgressionRateMobile
                                breadCrumbs={[
                                    {
                                        pageName: generateTitle(),
                                        pageUrl: () =>
                                            history.push(
                                                `/insurance/application/${id}/car/${carid}`
                                            ),
                                        isCaps: true,
                                    },
                                    { pageName: 'Mileage & Parking' },
                                ]}
                                hasFormChanged={hasFormChanged}
                                onSave={saveForm}
                                sectionId="vehicles_to_insure"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    width: '100%',
                                    paddingTop: 120,
                                    paddingBottom: 120,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            >
                                <InsuranceQuoteFormGenerator
                                    sectionID="vehicles_to_insure"
                                    list={
                                        insurance_fields_car_mileage_parking_1
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                />

                                <div style={{ paddingTop: '16px' }} />
                                <InsuranceQuoteFormGenerator
                                    sectionID="vehicles_to_insure"
                                    list={
                                        insurance_fields_car_mileage_parking_added_address_same_as_residential
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                />
                                <div style={{ paddingTop: '16px' }} />

                                <Expander
                                    height={
                                        data_draft[
                                            'is_kept_at_customer_residential_address'
                                        ] === true
                                            ? 'auto'
                                            : 0
                                    }
                                    width="100%"
                                >
                                    <>
                                        <InsuranceQuoteFormGenerator
                                            sectionID="vehicles_to_insure"
                                            list={
                                                insurance_fields_car_mileage_location_type_if_same_as_residential
                                            }
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'day'
                                                )
                                            }}
                                            data={data_day_parking_draft}
                                            errors={daytime_parking_info_errors}
                                            setError={setError}
                                        />

                                        <div style={{ paddingTop: '16px' }} />
                                    </>
                                </Expander>

                                <Expander
                                    height={
                                        user_residential_address_uid ===
                                            undefined &&
                                        (data_draft[
                                            'is_kept_at_customer_residential_address'
                                        ] === true ||
                                            data_draft[
                                                'travels_to_home_address'
                                            ] === true)
                                            ? 'auto'
                                            : 0
                                    }
                                    width="100%"
                                >
                                    <>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    colours[theme]
                                                        .error_underlay,
                                                width: '100%',
                                                padding: '20px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px',
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                src={info_red}
                                                alt="info-red"
                                                style={{
                                                    height: '20px',
                                                }}
                                            />

                                            <InsuranceFormQuestion
                                                $theme={theme}
                                                style={{
                                                    color: colours[theme].error,
                                                }}
                                            >
                                                Please provide your residential
                                                address as it hasn't been
                                                previously filled.
                                            </InsuranceFormQuestion>
                                        </div>

                                        <div style={{ paddingTop: 16 }} />

                                        <ButtonAtom
                                            theme="tertiary"
                                            textTransform="none"
                                            width={'100%'}
                                            customBgColor="transparent"
                                            onClick={() => {
                                                toggleActiveModal({
                                                    active_modal: 'select',
                                                    field_id:
                                                        'residential_address',
                                                    title: 'Select & Save residential address',
                                                })
                                            }}
                                            color={
                                                insurance_colours[theme]
                                                    .sections[
                                                    'vehicles_to_insure'
                                                ].primary_100
                                            }
                                        >
                                            Add address
                                        </ButtonAtom>

                                        <div style={{ paddingTop: 16 }} />

                                        <Expander
                                            height={
                                                errors &&
                                                errors['day_park_address_id']
                                                    ? 'auto'
                                                    : 0
                                            }
                                        >
                                            <Error
                                                style={{
                                                    paddingTop: '8px',
                                                }}
                                            >
                                                {`Parking during the day address is required`}
                                            </Error>
                                        </Expander>
                                    </>
                                </Expander>

                                <div style={{ paddingTop: '20px' }} />

                                <Expander
                                    height={
                                        data_draft[
                                            'is_kept_at_customer_residential_address'
                                        ] === undefined ||
                                        data_draft[
                                            'is_kept_at_customer_residential_address'
                                        ] === true
                                            ? 0
                                            : 'auto'
                                    }
                                    width="100%"
                                >
                                    <InsuranceQuoteFormGenerator
                                        sectionID="vehicles_to_insure"
                                        list={
                                            insurance_fields_car_mileage_parking_added_address_different_than_residential
                                        }
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onAnswerChange(id, answer)
                                            // if (answer === true) {
                                            dispatch(
                                                insuranceActions.reset_insurance_car_mileage_parking_info(
                                                    {
                                                        carid: carid,
                                                        type: 'evening',
                                                    }
                                                )
                                            )

                                            dispatch(
                                                insuranceActions.set_answer_insurance_car_mileage_parking(
                                                    {
                                                        id: 'night_park_address_id',
                                                        answer: null,
                                                        carid: carid,
                                                    }
                                                )
                                            )
                                            // }
                                        }}
                                        data={data_draft}
                                        errors={errors}
                                        setError={setError}
                                    />

                                    <div style={{ paddingTop: 16 }} />
                                    <InsuranceQuoteFormGenerator
                                        sectionID="vehicles_to_insure"
                                        list={
                                            data_draft[
                                                'parking_place_same_day_night'
                                            ] !== false
                                                ? insurance_fields_car_mileage_parking_day_place_nigth_same
                                                : insurance_fields_car_mileage_parking_day_place
                                        }
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'day'
                                            )
                                        }}
                                        data={data_day_parking_draft}
                                        errors={daytime_parking_info_errors}
                                        setError={setError}
                                    />
                                    <div style={{ paddingTop: 16 }} />
                                    {day_park_address_id ? (
                                        <div style={{ width: '100%' }}>
                                            <InsuranceAddressCardV2
                                                sectionID="vehicles_to_insure"
                                                address={
                                                    addresses[
                                                        day_park_address_id
                                                    ]
                                                }
                                                onChange={() => {
                                                    toggleActiveModal({
                                                        active_modal: 'select',
                                                        field_id:
                                                            'day_park_address_id',
                                                        title: 'Select address',
                                                    })
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <InsuranceFormQuestion
                                                $theme={theme}
                                            >
                                                Please provide the location
                                                where the vehicle is usually
                                                kept during the day:
                                            </InsuranceFormQuestion>
                                            <div style={{ paddingTop: 16 }} />
                                            <>
                                                <ButtonAtom
                                                    theme="tertiary"
                                                    textTransform="none"
                                                    width={'100%'}
                                                    customBgColor="transparent"
                                                    onClick={() => {
                                                        toggleActiveModal({
                                                            active_modal:
                                                                'select',
                                                            field_id:
                                                                'day_park_address_id',
                                                            title: 'Select address',
                                                        })
                                                    }}
                                                    color={
                                                        insurance_colours[theme]
                                                            .sections[
                                                            'vehicles_to_insure'
                                                        ].primary_100
                                                    }
                                                >
                                                    Add address
                                                </ButtonAtom>
                                                <Expander
                                                    height={
                                                        errors &&
                                                        errors[
                                                            'day_park_address_id'
                                                        ]
                                                            ? 'auto'
                                                            : 0
                                                    }
                                                >
                                                    <Error
                                                        style={{
                                                            paddingTop: '8px',
                                                        }}
                                                    >
                                                        {`Parking during the day address is required`}
                                                    </Error>
                                                </Expander>
                                            </>
                                        </>
                                    )}
                                    <div style={{ paddingTop: 16 }} />
                                    <InsuranceQuoteFormGenerator
                                        sectionID="vehicles_to_insure"
                                        list={insurance_fields_parking_info}
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'day'
                                            )
                                        }}
                                        data={data_day_parking_draft}
                                        errors={daytime_parking_info_errors}
                                        setError={setError}
                                    />

                                    <Expander
                                        height={
                                            data_draft[
                                                'parking_place_same_day_night'
                                            ] === undefined ||
                                            data_draft[
                                                'parking_place_same_day_night'
                                            ] === true
                                                ? 0
                                                : 'auto'
                                        }
                                        width="100%"
                                    >
                                        <InsuranceQuoteFormGenerator
                                            sectionID="vehicles_to_insure"
                                            list={
                                                insurance_fields_car_mileage_parking_night_place
                                            }
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'evening'
                                                )
                                            }}
                                            data={data_night_parking_draft}
                                            errors={
                                                eveningtime_parking_info_errors
                                            }
                                            setError={setError}
                                        />
                                        <div style={{ paddingTop: 16 }} />
                                        {night_park_address_id ? (
                                            <div style={{ width: '100%' }}>
                                                <InsuranceAddressCardV2
                                                    sectionID="vehicles_to_insure"
                                                    address={
                                                        addresses[
                                                            night_park_address_id
                                                        ]
                                                    }
                                                    onChange={() => {
                                                        toggleActiveModal({
                                                            active_modal:
                                                                'select',
                                                            field_id:
                                                                'night_park_address_id',
                                                            title: 'Select address',
                                                        })
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <InsuranceFormQuestion
                                                    $theme={theme}
                                                >
                                                    Please provide the location
                                                    where the vehicle is usually
                                                    kept during the night:
                                                </InsuranceFormQuestion>
                                                <div
                                                    style={{ paddingTop: 16 }}
                                                />
                                                <>
                                                    <ButtonAtom
                                                        theme="tertiary"
                                                        textTransform="none"
                                                        width={'100%'}
                                                        customBgColor="transparent"
                                                        onClick={() => {
                                                            toggleActiveModal({
                                                                active_modal:
                                                                    'select',
                                                                field_id:
                                                                    'night_park_address_id',
                                                                title: 'Select address',
                                                            })
                                                        }}
                                                        color={
                                                            insurance_colours[
                                                                theme
                                                            ].sections[
                                                                'vehicles_to_insure'
                                                            ].primary_100
                                                        }
                                                    >
                                                        Add address
                                                    </ButtonAtom>
                                                    <Expander
                                                        height={
                                                            errors &&
                                                            errors[
                                                                'night_park_address_id'
                                                            ]
                                                                ? 'auto'
                                                                : 0
                                                        }
                                                    >
                                                        <Error
                                                            style={{
                                                                paddingTop:
                                                                    '8px',
                                                            }}
                                                        >
                                                            {`Parking during the night address is required`}
                                                        </Error>
                                                    </Expander>
                                                </>
                                            </>
                                        )}
                                        <div style={{ paddingTop: 16 }} />
                                        <InsuranceQuoteFormGenerator
                                            sectionID="vehicles_to_insure"
                                            list={insurance_fields_parking_info}
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'evening'
                                                )
                                            }}
                                            data={data_night_parking_draft}
                                            errors={
                                                eveningtime_parking_info_errors
                                            }
                                            setError={setError}
                                        />
                                        <div style={{ paddingTop: 16 }} />
                                    </Expander>
                                </Expander>

                                {/* <div style={{ paddingTop: '20px' }} /> */}

                                <InsuranceQuoteFormGenerator
                                    sectionID="vehicles_to_insure"
                                    list={
                                        insurance_fields_car_mileage_parking_security
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                />
                            </div>

                            <AddressWindow
                                sectionID="vehicles_to_insure"
                                removeSideEffectSubmit={true}
                                selectedAddress={
                                    activeModal?.field_id ===
                                    'residential_address'
                                        ? user_residential_address_uid
                                        : activeModal?.field_id ===
                                          'day_park_address_id'
                                        ? day_park_address_id
                                        : activeModal?.field_id ===
                                          'night_park_address_id'
                                        ? night_park_address_id
                                        : undefined
                                }
                                selectAddress={(addressid: string) => {
                                    if (
                                        activeModal?.field_id ===
                                        'residential_address'
                                    ) {
                                        dispatch(
                                            insuranceActions.set_answer_main_driver_licenseAddress(
                                                {
                                                    id: 'residential_address',
                                                    answer: addressid,
                                                    userid: userLoggedIn.id,
                                                }
                                            )
                                        )
                                    } else {
                                        activeModal?.field_id &&
                                            onAnswerChange(
                                                activeModal?.field_id,
                                                addressid
                                            )
                                    }
                                }}
                                activeModal={activeModal}
                                toggleActiveModal={toggleActiveModal}
                                onSaveForm={() => {
                                    if (
                                        activeModal?.field_id ===
                                        'residential_address'
                                    ) {
                                        dispatch(
                                            insuranceActions.submit_user_residential_address_request()
                                        )
                                    } else if (
                                        activeModal?.active_modal ===
                                            'create' ||
                                        activeModal?.active_modal === 'edit'
                                    ) {
                                        dispatch(
                                            insuranceActions.submit_user_address_request()
                                        )
                                    }
                                    // else {
                                    //     dispatch(
                                    //         insuranceActions.submit_carMileageParking_request(
                                    //             {
                                    //                 carid: carid,
                                    //             }
                                    //         )
                                    //     )
                                    // }
                                }}
                            />

                            <InsuranceGoBackReusableBottomBar
                                isMobile
                                saveBtnTxt="Save & Continue"
                                isSaveDisabled={isSavedDisabled ? true : false}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                goBack={() =>
                                    history.push(
                                        `/insurance/application/${id}/car/${carid}`
                                    )
                                }
                                sectionId="vehicles_to_insure"
                            />
                        </div>
                    </IpadAndMobileDisplay>
                </div>
            )}
        </>
    )
}

export default InsuranceCarMileageParkingApplicationFlow
