import React, { useEffect, Fragment } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

interface Props extends RouteComponentProps<any> {
    children?: React.ReactNode
}

function ScrollToTop(props: Props) {
    useEffect(() => {
        if (
            props.location.pathname.match(/gallery/g) === null &&
            props.location.pathname.match(/car/g) === null &&
            props.location.pathname.match(/for-sale/g) === null &&
            props.location.pathname.match(/sold/g) === null
        ) {
            const unlisten = props.history.listen(() => {
                window.scrollTo(0, 0)
            })
            return () => {
                unlisten()
            }
        }
    }, [])

    return <Fragment>{props.children}</Fragment>
}

export default withRouter(ScrollToTop)
