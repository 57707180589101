import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { IAnyObject } from '../../../../redux/insuranceQuoteApplication/reducer'
import {
    IInsuranceFieldType,
    insurance_fields_car_mileage_parking_security_subform,
    insurance_fields_main_driver_track_record_special_terms_imposed_subform,
    insurance_sub_fields_other_driver_occupation_secondary,
    voluntary_excess_subfields,
    accidental_damage_cost_subform,
    accidental_fire_cost_subform,
    accidental_glass_cost_subform,
    accidental_theft_cost_subform,
    accidental_vandalism_cost_subform,
    insurance_sub_fields_other_driver_track_record_motorconviction_date,
    insurance_fields_track_record_previous_motor_terms_refused_subform,
    insurance_fields_track_record_previous_motor_terms_cancelled_subform,
    insurance_fields_track_record_previous_motor_insurance_voided_subform,
    insurance_sub_fields_other_driver_track_record_motorconviction_fine_amount,
    insurance_sub_fields_other_driver_track_record_motorconviction_points_awarded,
    // accidental_third_party_injury_cost_subform,
    third_party_damage_property_cost_subform,
    insurance_sub_sub_fields_disabilities_rescriction_time,
    location_security_sub_field,
    written_off_subfields,
    subquestions_claims_drivers,
    subquestions_claims_customer,
    ncd_additional_fields,
    travel_to_residential_address_yes_subfields,
    has_vehicle_modifications_negative_answer_subform,
    has_vehicle_modifications_positive_answer_subform,
    is_imported_positive_answer_subfields,
    other_driver_is_uk_resident_positive_answer_subform,
    is_uk_resident_positive_answer_subform,
    other_driver_resides_in_the_uk_negative_answer_subform,
    resides_in_the_uk_negative_answer_subform,
} from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import Expander from '../../../atoms/expander/expander'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { Error } from '../../editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import { RenderInsuranceQuoteFormFieldDesktop } from './renderElementDesktop'
import { RenderInsuranceQuoteFormFieldMobile } from './renderElementMobile'
import { InsuranceQuoteSubFormGeneratorLayer2 } from './subGeneratorLayer2'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { capitalizeFirstLetter } from '../../../../helpers/strings/capitalizeFirstLetter'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    field_id: string
    onAnswerChange: (id: string, answer: any) => any
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    mobileSearchPickerSectionURL?: string
    entityTypeID?: string
    sectionID?: IInsuranceApplicationSectionId
    predefined_list?: IInsuranceFieldType[]
}

export const InsuranceQuoteSubFormGenerator = (props: Props) => {
    let {
        field_id,
        onAnswerChange,
        data,
        errors,
        setError,
        mobileSearchPickerSectionURL,
        entityTypeID,
        sectionID,
    } = props

    const history = useHistory()

    let currURL = new URL(window.location.href)
    let params2 = new URLSearchParams(currURL.search)

    let section_id = params2.get('section_id')
    let isNamedDriver =
        history.location.pathname.match(/other_driver/g) !== null ||
        window.location.pathname.match(/other_driver/g) !== null ||
        section_id === 'drivers'
            ? true
            : false

    let parentAnswer = data[field_id]?.name
    let boolParentAnswer = data[field_id]

    const [savedParentAnswer, setSavedParentAnswer] = useState(undefined)

    useEffect(() => {
        setSavedParentAnswer(parentAnswer)
        if (entityTypeID === 'claims' && list && data[list[0].id] !== 0) {
            if (parentAnswer === 'No' && savedParentAnswer === 'Yes') {
                onAnswerChange(list[0].id, 0)
            } else if (parentAnswer === 'Yes' && savedParentAnswer === 'No') {
                onAnswerChange(list[0].id, 0)
            }
        }
    }, [parentAnswer])

    let list: IInsuranceFieldType[] | undefined =
        field_id === 'has_any_previous_insurance_refusals' && isNamedDriver
            ? subquestions_claims_drivers
            : field_id === 'has_any_previous_insurance_refusals' &&
              !isNamedDriver
            ? subquestions_claims_customer
            : field_id === 'is_uk_resident'
            ? isNamedDriver
                ? other_driver_is_uk_resident_positive_answer_subform
                : is_uk_resident_positive_answer_subform
            : field_id === 'resides_in_the_uk'
            ? isNamedDriver
                ? other_driver_resides_in_the_uk_negative_answer_subform
                : resides_in_the_uk_negative_answer_subform
            : field_id === 'is_written_off'
            ? written_off_subfields
            : field_id === 'ncd_years'
            ? ncd_additional_fields
            : field_id === 'has_resulted_in_driving_ban'
            ? insurance_sub_fields_other_driver_track_record_motorconviction_date
            : field_id === 'has_resulted_in_fine'
            ? insurance_sub_fields_other_driver_track_record_motorconviction_fine_amount
            : field_id === 'has_resulted_in_penalty_points'
            ? insurance_sub_fields_other_driver_track_record_motorconviction_points_awarded
            : field_id === 'has_accidental_damage'
            ? accidental_damage_cost_subform
            : // : field_id === 'has_third_party_personal_injury'
            // ? accidental_third_party_injury_cost_subform
            field_id === 'has_third_party_property_damage'
            ? third_party_damage_property_cost_subform
            : field_id === 'has_fire_damage'
            ? accidental_fire_cost_subform
            : field_id === 'has_windscreen_damage'
            ? accidental_glass_cost_subform
            : field_id === 'has_malicious_or_vandalism_damage'
            ? accidental_vandalism_cost_subform
            : field_id === 'has_theft'
            ? accidental_theft_cost_subform
            : field_id === 'has_license_restriction'
            ? insurance_sub_sub_fields_disabilities_rescriction_time
            : field_id === 'has_location_security'
            ? location_security_sub_field
            : field_id === 'has_security_device_fitted'
            ? insurance_fields_car_mileage_parking_security_subform
            : field_id === 'has_voluntary_excess'
            ? voluntary_excess_subfields
            : field_id === 'has_occupation_secondary'
            ? insurance_sub_fields_other_driver_occupation_secondary
            : field_id === 'has_previous_special_terms_imposed'
            ? insurance_fields_main_driver_track_record_special_terms_imposed_subform
            : field_id === 'has_previous_motor_insurance_refused'
            ? insurance_fields_track_record_previous_motor_terms_refused_subform
            : field_id === 'has_previous_motor_insurance_cancelled'
            ? insurance_fields_track_record_previous_motor_terms_cancelled_subform
            : field_id === 'has_previous_motor_insurance_voided'
            ? insurance_fields_track_record_previous_motor_insurance_voided_subform
            : field_id === 'travels_to_home_address'
            ? travel_to_residential_address_yes_subfields
            : field_id === 'is_imported'
            ? is_imported_positive_answer_subfields
            : field_id === 'has_vehicle_modifications'
            ? boolParentAnswer === true
                ? has_vehicle_modifications_positive_answer_subform
                : has_vehicle_modifications_negative_answer_subform
            : props.predefined_list ?? undefined

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    const { theme } = useThemes()

    return data ? (
        <>
            {list && (
                <div style={{ width: '100%' }}>
                    {list.map((item: IInsuranceFieldType, index: number) => {
                        let prevItemHadDivider: boolean | undefined =
                            index !== 0 &&
                            list![index - 1] &&
                            list![index - 1].ends_section_with_divider

                        let item_err =
                            errors && item.id
                                ? item.type === 'dates_range'
                                    ? errors[`${item.id}_start_date`] ||
                                      errors[`${item.id}_end_date`]
                                    : errors[item.id]
                                    ? errors[item.id]
                                    : undefined
                                : undefined

                        return (
                            <div
                                style={{ width: '100%' }}
                                key={`sub-gen-${item.id}-${index}-${
                                    isMobile ? 'mobile' : 'desktop'
                                }`}
                            >
                                <Expander
                                    height={
                                        item.type === 'date'
                                            ? 'auto'
                                            : data &&
                                              data[item.id] !== undefined &&
                                              data[item.id] !== null &&
                                              data[item.id] !== '' &&
                                              item.type !== 'yes_no' &&
                                              item.type !==
                                                  'contact_medium_choice' &&
                                              prevItemHadDivider !== true
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <div
                                        style={{
                                            paddingTop: '10px',
                                        }}
                                    />
                                </Expander>
                                {item.type === 'yes_no' &&
                                    !prevItemHadDivider && (
                                        <div style={{ paddingTop: '10px' }} />
                                    )}
                                {isMobile ? (
                                    <RenderInsuranceQuoteFormFieldMobile
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={{ ...item, id: item.id }}
                                        mobileSearchPickerSectionURL={
                                            mobileSearchPickerSectionURL
                                        }
                                        sectionID={sectionID}
                                    />
                                ) : (
                                    <RenderInsuranceQuoteFormFieldDesktop
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        item={{ ...item, id: item.id }}
                                        sectionID={sectionID}
                                    />
                                )}
                                <Expander
                                    height={errors && item_err ? 'auto' : 0}
                                >
                                    <Error
                                        $theme={theme}
                                        style={{ paddingTop: '8px' }}
                                    >
                                        {errors &&
                                        item_err &&
                                        typeof item_err !== 'string' &&
                                        item_err.status
                                            ? `${
                                                  item_err.status?.toLowerCase() !==
                                                  'invalid'
                                                      ? `${capitalizeFirstLetter(
                                                            item_err.status
                                                        )} - `
                                                      : ''
                                              } ${
                                                  item_err?.message
                                                      ? `${item_err?.message}`
                                                      : ''
                                              }`
                                            : errors &&
                                              item_err &&
                                              typeof item_err === 'string'
                                            ? `${item_err}`
                                            : ''}
                                    </Error>
                                </Expander>
                                <div
                                    style={{
                                        paddingBottom: '16px',
                                    }}
                                />
                                <Expander
                                    height={
                                        (item.has_subform_if_true &&
                                            data &&
                                            (data[item.id] === true ||
                                                data[item.id]?.name ===
                                                    'Yes')) ||
                                        (item.has_subform_if_false &&
                                            data &&
                                            (data[item.id] === false ||
                                                data[item.id]?.name ===
                                                    'False'))
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <InsuranceQuoteSubFormGeneratorLayer2
                                        data={data}
                                        errors={errors}
                                        setError={setError}
                                        onAnswerChange={onAnswerChange}
                                        field_id={item.id}
                                        mobileSearchPickerSectionURL={
                                            mobileSearchPickerSectionURL
                                        }
                                        sectionID={sectionID}
                                    />
                                </Expander>
                                {item.ends_section_with_divider === true && (
                                    <div
                                        style={{
                                            marginTop: '24px',
                                            marginBottom: '40px',
                                            background:
                                                'var(--border-muted, #e5e5e5)',
                                            height: '1px',
                                        }}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
            {field_id === 'has_security_device_fitted' && isMobile ? (
                <div style={{ paddingBottom: 200 }} />
            ) : null}
        </>
    ) : (
        <div />
    )
}
