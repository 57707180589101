import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'
import DescriptionEditorDesktop from '../../editorSections/description/editorDescriptionDesktop'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import { IUpdateCarGeneralPayloadReq } from 'entityModels'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
}

export const DescriptionHeader = styled.div<IStyle>`
    padding-top: 3px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 16px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;

    @media ${device.large_desktop} {
        font-size: 17px;
    }
`

export const Error = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
    font-size: 11px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    description: string
}

interface OtherProps {
    close: () => void
    carDescription: string

    writeCarDataGeneralRequest: (payload: IUpdateCarGeneralPayloadReq) => {}
    carId: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { close, setFieldValue, values, errors } = props

    const { theme } = useThemes()

    return (
        <ModalContentDesktop
            $theme={theme}
            style={{ overflowY: 'auto', marginTop: '12vh', maxHeight: '85vh' }}
        >
            <ModalInnerContentWrapperDesktop
                style={{ paddingBottom: '24px', paddingTop: '24px' }}
            >
                <ModalTitle style={{ paddingBottom: 8 }} $theme={theme}>
                    Description
                </ModalTitle>

                <Expander
                    height={
                        values.description.length > 1 && errors.description
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error
                            $theme={theme}
                            className="animated fadeIn slower"
                        >
                            {errors.description}
                        </Error>
                    </Faded>
                </Expander>

                <DescriptionHeader $theme={theme}>
                    This is your space to document your car's history, expand on
                    your custodianship, or jot down ideas for future plans.
                </DescriptionHeader>
            </ModalInnerContentWrapperDesktop>
            <ModalInnerContentWrapperDesktop
                style={{
                    backgroundColor: colours[theme].background_neutral_subtle,
                    padding: '16px 30px',
                }}
            >
                <DescriptionEditorDesktop
                    placeholder={`Let's get started...`}
                    valuePassed={values.description}
                    handleChange={(text: any) =>
                        setFieldValue('description', text)
                    }
                    idPassed="description"
                    readOnly={false}
                />
            </ModalInnerContentWrapperDesktop>
            <ModalInnerContentWrapperDesktop>
                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={props.handleSubmit}
                    isDisabled={
                        values.description && errors.description ? true : false
                    }
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarDescriptionEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        description: props.carDescription,
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                description: Yup.string().max(
                    30000,
                    'Description must be be less than 30000 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (props.carDescription !== values.description) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carId,
                dataListForm: [
                    {
                        id: 'description',
                        text: 'description',
                        answer: values.description,
                    },
                ],
            }
            props.writeCarDataGeneralRequest(payload)
        }

        props.close()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarDescriptionEditFormDesktop)
