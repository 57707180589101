import { ICategoriesPayload, IInvitationPayload } from 'IapiDataPayload'
import { push } from 'connected-react-router'
import * as actions from './actions'
import * as loadingActions from './loadingActions'
import * as errorActions from './errorActions'
import { api } from '../../services'
import { ThunkAction } from 'redux-thunk'
import { RootAction } from 'typesafe-actions'

import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'

import { ICustomErrorData, IReduxError } from 'entityModels'
// import { setInitialLandingUrlSuccess } from '../../localdata/customNav/actions/success'
import { addCategoryUserApi_args } from 'ApiInterfaces'

interface IGetState {
    [key: string]: any
}

export const addPhone = (
    phone: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any, getState: () => IGetState): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.addPhoneRequest())

            try {
                dispatch(actions.addPhoneSuccess(phone))
                // later on , add conditional nav here
                const store = getState()
                const currentUser = store.user.userLoggedIn

                if (currentUser.address == null) {
                    dispatch(push('/enter-address'))
                } else dispatch(push('/dashboard'))

                // dispatch(push("/dashboard"));

                // here will be call to API
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.addPhoneError(typedError))
                // window.location.reload()
            }
        })
    }
}

export const addCategory = (
    categories: ICategoriesPayload
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any, getState: () => IGetState): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.addCategoriesRequest())

            try {
                const store = getState()
                const currentUserId = store.user.userLoggedIn.id

                let api_payload: addCategoryUserApi_args = {
                    id: currentUserId,
                    categories: categories,
                }

                api.userData.user_data
                    .addCategoryUserApi(api_payload)
                    .then(() => {
                        dispatch(actions.addCategorySuccess())
                        dispatch(push('/'))
                        window.scrollTo(0, 0)
                        return
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.addAcategoriesError(customError)
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                errorActions.addAcategoriesError(typedError)
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.addAcategoriesError(typedError))
                return
                // window.location.reload()
            }
        })
    }
}

export const verifyEmail = (
    verificationCode: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.verifyRequest())

            try {
                api.authentication
                    .verifyEmail(verificationCode)
                    .then(
                        () => {
                            dispatch(actions.verifySuccess())

                            return
                        },
                        (error: any) => {
                            return dispatch(errorActions.verifyError(error))
                        }
                    )
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(errorActions.verifyError(customError))
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(errorActions.verifyError(typedError))
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.verifyError(typedError))
                return
                // window.location.reload()
            }
        })
    }
}

export const sendInvitationThunk = (
    fullname: string,
    email: string,
    sent: boolean,
    message?: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any, getState: () => IGetState): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.sendInvitationRequest())

            try {
                let data: IInvitationPayload = {
                    fullname,
                    email: email.toLowerCase(),
                    message,
                }

                api.sendInvitation.sendInvitationAPI(data)

                dispatch(actions.sendInvitationSuccess())
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.sendInvitationError(typedError))
                return
                // window.location.reload()
            }
        })
    }
}

// export const addAddress = (
//     address: IUserAddressApi
// ): ThunkAction<Promise<void>, {}, {}, RootAction> => {
//     // Invoke API
//     return async (dispatch: any, getState: () => IGetState): Promise<void> => {
//         return new Promise<void>((resolve) => {
//             dispatch(loadingActions.addAddressRequest())

//             try {
//                 api.userAddress
//                     .createUserAddressApi(address)
//                     .then((data: any) => {
//                         if (data) {
//                             dispatch(actions.addAddressSuccess(address))
//                             if (
//                                 window.location.pathname &&
//                                 window.location.pathname.includes('showroom')
//                             ) {
//                                 message.info({
//                                     content: 'Contact details saved!',
//                                 })
//                             }

//                             // Capture event
//                             posthog.capture('ADD USER ADDRESS')
//                         }
//                     })
//                     .catch((error: any) => {
//                         if (error === Error) {
//                             let customErrorData: ICustomErrorData = {
//                                 custom_message: `Looks like your internet has stoped working`,
//                                 custom_message_detail:
//                                     'Check your connection and refresh',
//                                 custom_user_action_text: 'Refresh page',
//                             }

//                             let customError: IReduxError = ConvertToReduxError(
//                                 { status: '500' },
//                                 customErrorData
//                             )
//                             dispatch(errorActions.addAddressError(customError))
//                         } else {
//                             let customErrorData: ICustomErrorData = {
//                                 custom_message: `Invalid address.`,
//                                 custom_user_action_text: 'Try again',
//                                 custom_redirect_path: '/account',
//                             }
//                             let customError: IReduxError = ConvertToReduxError(
//                                 error,
//                                 customErrorData
//                             )
//                             dispatch(errorActions.addAddressError(customError))
//                         }
//                     })
//             } catch (error: any) {
//                 let customErrorData: ICustomErrorData = {
//                     custom_message: `Invalid address.`,
//                     custom_user_action_text: 'Try again',
//                     custom_redirect_path: '/account',
//                 }
//                 let customError: IReduxError = ConvertToReduxError(
//                     error,
//                     customErrorData
//                 )
//                 dispatch(errorActions.addAddressError(customError))
//                 return
//                 // window.location.reload()
//             }
//         })
//     }
// }

// export const logout = (): ThunkAction<Promise<void>, {}, {}, RootAction> => {
//     // Invoke API
//     return async (dispatch: any, getState: () => IGetState): Promise<void> => {
//         return new Promise<void>((resolve) => {
//             dispatch(loadingActions.logoutRequest())

//             api.authentication
//                 .logout()
//                 .then(() => {
//                     dispatch(actions.logoutUserSuccess())

//                     // window.location.reload()
//                     // Capture event
//                     posthog.capture('USER LOGOUT')
//                     // dispatch(push('/signin'))
//                     dispatch(setInitialLandingUrlSuccess(null))
//                     return
//                 })
//                 .then(() => {
//                     return dispatch(push('/signin'))
//                 })
//                 .catch((err: any) => {
//                     let typedError: IReduxError = ConvertToReduxError(err)
//                     dispatch(errorActions.logoutUserError(typedError))
//                     window.location.reload()
//                 })

//             // return dispatch(push('/signin'))
//         })
//     }
// }

// export const resetPasswordRequest = (
//     email: string
// ): ThunkAction<Promise<void>, {}, {}, RootAction> => {
//     // Invoke API
//     return async (dispatch: any): Promise<void> => {
//         return new Promise<void>((resolve) => {
//             dispatch(loadingActions.resetPasswordRequest())

//             try {
//                 api.authentication
//                     .resetPasswordRequestApi(email.toLowerCase())
//                     .then(
//                         () => {
//                             dispatch(actions.resetPasswordSuccess())

//                             // Capture event
//                             posthog.capture('RESET PASSWORD REQUEST')

//                             return
//                         },
//                         (error: any) => {
//                             return dispatch(
//                                 errorActions.resetPasswordError(error)
//                             )
//                         }
//                     )
//                     .catch((error: any) => {
//                         if (error === Error) {
//                             let customErrorData: ICustomErrorData = {
//                                 custom_message: `An error occurred when trying to reset your password.`,
//                                 custom_user_action_text: 'Please try again',
//                             }

//                             let customError: IReduxError = ConvertToReduxError(
//                                 { status: '500' },
//                                 customErrorData
//                             )
//                             dispatch(
//                                 errorActions.resetPasswordError(customError)
//                             )
//                         } else {
//                             let typedError: IReduxError = ConvertToReduxError(
//                                 error
//                             )
//                             dispatch(
//                                 errorActions.resetPasswordError(typedError)
//                             )
//                         }
//                     })
//             } catch (error: any) {
//                 let typedError: IReduxError = ConvertToReduxError(error)
//                 dispatch(errorActions.resetPasswordError(typedError))
//                 return
//                 // window.location.reload()
//             }
//         })
//     }
// }

// export const ResetPasswordVerifyCodeEmail = (
//     verificationCode: string
// ): ThunkAction<Promise<void>, {}, {}, RootAction> => {
//     // Invoke API
//     return async (dispatch: any): Promise<void> => {
//         return new Promise<void>((resolve) => {
//             dispatch(loadingActions.verifyResetPasswordCodeRequest())

//             try {
//                 api.authentication
//                     .resetPasswordVerifyCode(verificationCode)
//                     .then(
//                         (data: any) => {
//                             return dispatch(
//                                 actions.verifyResetPasswordCodeSuccess(
//                                     data.user_uid
//                                 )
//                             )
//                         },
//                         (error: any) => {
//                             let typedError: IReduxError = ConvertToReduxError(
//                                 error
//                             )
//                             return dispatch(
//                                 errorActions.verifiedResetPasswordError(
//                                     typedError
//                                 )
//                             )
//                         }
//                     )
//                     .catch((error: any) => {
//                         if (error === Error) {
//                             let customErrorData: ICustomErrorData = {
//                                 custom_message: `Looks like your internet has stoped working`,
//                                 custom_message_detail:
//                                     'Check your connection and refresh',
//                                 custom_user_action_text: 'Refresh page',
//                             }

//                             let customError: IReduxError = ConvertToReduxError(
//                                 { status: '500' },
//                                 customErrorData
//                             )
//                             dispatch(
//                                 errorActions.verifiedResetPasswordError(
//                                     customError
//                                 )
//                             )
//                         } else {
//                             let typedError: IReduxError = ConvertToReduxError(
//                                 error
//                             )
//                             dispatch(
//                                 errorActions.verifiedResetPasswordError(
//                                     typedError
//                                 )
//                             )
//                         }
//                     })
//             } catch (error: any) {
//                 let typedError: IReduxError = ConvertToReduxError(error)
//                 dispatch(errorActions.verifiedResetPasswordError(typedError))
//                 return
//                 // window.location.reload()
//             }
//         })
//     }
// }

// export const verifiedResetPassword = (
//     password: string,
//     uid: string
// ): ThunkAction<Promise<void>, {}, {}, RootAction> => {
//     // Invoke API
//     return async (dispatch: any): Promise<void> => {
//         return new Promise<void>((resolve) => {
//             dispatch(loadingActions.verifiedResetPasswordRequest())

//             try {
//                 api.authentication
//                     .verifiedResetPasswordApi({
//                         passwordGiven: password,
//                         uid: uid,
//                     })
//                     .then(
//                         (data?: any) => {
//                             dispatch(actions.verifiedResetPasswordSuccess())

//                             // Capture event
//                             posthog.capture('RESET PASSWORD VERIFICATION')
//                             return
//                         },
//                         (error: any) => {
//                             return dispatch(
//                                 errorActions.verifiedResetPasswordError(error)
//                             )
//                         }
//                     )
//                     .catch((error: any) => {
//                         if (error === Error) {
//                             let customErrorData: ICustomErrorData = {
//                                 custom_message: `Looks like your internet has stoped working`,
//                                 custom_message_detail:
//                                     'Check your connection and refresh',
//                                 custom_user_action_text: 'Refresh page',
//                             }

//                             let customError: IReduxError = ConvertToReduxError(
//                                 { status: '500' },
//                                 customErrorData
//                             )
//                             dispatch(
//                                 errorActions.verifiedResetPasswordError(
//                                     customError
//                                 )
//                             )
//                         } else {
//                             let typedError: IReduxError = ConvertToReduxError(
//                                 error
//                             )
//                             dispatch(
//                                 errorActions.verifiedResetPasswordError(
//                                     typedError
//                                 )
//                             )
//                         }
//                     })
//             } catch (error: any) {
//                 let typedError: IReduxError = ConvertToReduxError(error)
//                 dispatch(errorActions.verifiedResetPasswordError(typedError))
//                 return
//                 // window.location.reload()
//             }
//         })
//     }
// }

// export const register = (
//     firstName: string,
//     lastName: string,
//     email: string,
//     password: string,
//     platformConsent: boolean,
//     apexConsent: boolean,
//     fbclid: string | null,
//     invite_code?: string | undefined
// ): ThunkAction<Promise<void>, {}, {}, RootAction> => {
//     // Invoke API
//     return async (dispatch: any): Promise<void> => {
//         return new Promise<void>((resolve) => {
//             window.dataLayer = window.dataLayer || []
//             window.dataLayer.push({
//                 event: 'sign_up',
//             })

//             dispatch(loadingActions.registerRequest())

//             // 1. TRIM INVITE CODE
//             if (invite_code !== undefined) {
//                 invite_code = trimInviteCode(invite_code)
//             }

//             // 2 GET RID OF UNDEFINED (CLEAN DATA)

//             let registerData: IRegisterData = {
//                 given_name: firstName,
//                 family_name: lastName,
//                 email: email.toLowerCase(),
//                 password: password,
//                 consent: {
//                     platform: platformConsent,
//                     apex: apexConsent,
//                 },
//                 invite_code: invite_code,
//             }

//             if (fbclid !== null) {
//                 registerData.tracking_codes = [{ id: 'fbclid', value: fbclid }]
//             }
//             // 4. SEND PAYLOAD TO API
//             try {
//                 api.authentication.register(registerData).then(
//                     (res: any) => {
//                         // let user_id = res.user_id

//                         try {
//                             api.userData
//                                 .getCurrentUser()
//                                 .then((userData: any) => {
//                                     let refresh_token = data.refresh_token

//                                     window.localStorage.setItem(
//                                         'refresh_token',
//                                         refresh_token
//                                     )

//                                     dispatch(actions.signInUserSuccess(data))

//                                     // yield call(getCurrentUserDataSaga);

//                                     // dispatch(
//                                     //     actions.getCurrentUserDataSuccess(data)
//                                     // )

//                                     // window.localStorage.setItem(
//                                     //     'user_id',
//                                     //     user_id
//                                     // )

//                                     dispatch(actions.registerSuccess(data.id))

//                                     // Identify user with internal ID
//                                     posthog.identify(data.id)
//                                     if (posthog.people !== undefined) {
//                                         // Set email or any other data
//                                         posthog.people.set_once({
//                                             email: email.toLowerCase(),
//                                             given_name: firstName,
//                                             family_name: lastName,
//                                         })
//                                     }

//                                     dispatch(
//                                         push('/garage?onboarding=true&id=1')
//                                     )

//                                     // Capture event
//                                     posthog.capture('USER REGISTRATION')

//                                     // if (userData.phone === undefined) {
//                                     //   return dispatch(push("/phone-detail"));
//                                     // }

//                                     // if (userData.address === undefined) {
//                                     //   return dispatch(push("/enter-address"));
//                                     // }
//                                 })
//                                 .catch((error: any) => {
//                                     if (error === Error) {
//                                         let customErrorData: ICustomErrorData = {
//                                             custom_message: `Looks like your internet has stoped working`,
//                                             custom_message_detail:
//                                                 'Check your connection and refresh',
//                                             custom_user_action_text:
//                                                 'Refresh page',
//                                             custom_user_action_func: () =>
//                                                 window.location.reload(),
//                                         }

//                                         let customError: IReduxError = ConvertToReduxError(
//                                             { status: '500' },
//                                             customErrorData
//                                         )
//                                         dispatch(
//                                             errorActions.signInUserError(
//                                                 customError
//                                             )
//                                         )
//                                     } else {
//                                         let typedError: IReduxError = ConvertToReduxError(
//                                             error
//                                         )
//                                         console.error(error)
//                                         return dispatch(
//                                             errorActions.signInUserError(
//                                                 typedError
//                                             )
//                                         )
//                                     }
//                                 })
//                         } catch (error: any) {
//                             let typedError: IReduxError = ConvertToReduxError(
//                                 error
//                             )
//                             console.error(error)
//                             return dispatch(
//                                 errorActions.signInUserError(typedError)
//                             )
//                         }

//                         dispatch(push('/garage?onboarding=true&id=1'))
//                         dispatch(setIsFirstSessionSuccess(true))

//                         return
//                     },
//                     (error: any) => {
//                         let typedError: IReduxError = ConvertToReduxError(error)
//                         return dispatch(errorActions.registerError(typedError))
//                     }
//                 )
//             } catch (error: any) {
//                 console.error(error)
//                 let typedError: IReduxError = ConvertToReduxError(error)
//                 return dispatch(errorActions.registerError(typedError))
//             }
//         })
//     }
// }

// export const signin = (
//     email: string,
//     password: string
// ): ThunkAction<Promise<void>, {}, {}, RootAction> => {
//     // Invoke API
//     return async (dispatch: any, getState: () => IGetState): Promise<void> => {
//         return new Promise<void>((resolve) => {
//             dispatch(loadingActions.signInUserRequest())

//             let lowerCaseEmail = email.toLowerCase()

//             try {
//                 api.authentication
//                     .login(lowerCaseEmail, password)
//                     .then(
//                         (data: ILoginPayloadResAPI) => {
//                             // dispatch(actions.signInUserSuccess(data.user_id));

//                             // let user_id = data.user_id
//                             // window.localStorage.setItem('user_id', user_id);

//                             // set data in localStorage
//                             let garage_id = data.garage_id
//                             let refresh_token = data.refresh_token

//                             window.localStorage.setItem(
//                                 'refresh_token',
//                                 refresh_token
//                             )

//                             // alert(`setting RT from login: ${refresh_token}`)

//                             if (garage_id) {
//                                 window.localStorage.setItem(
//                                     'garage_id',
//                                     garage_id
//                                 )
//                             }

//                             try {
//                                 api.userData
//                                     .getCurrentUser()
//                                     .then((userData: any) => {
//                                         let data: IUser = ConvertUserApitoUserState(
//                                             userData
//                                         )

//                                         dispatch(
//                                             actions.signInUserSuccess(data)
//                                         )

//                                         // dispatch(
//                                         //     actions.getCurrentUserDataSuccess(
//                                         //         data
//                                         //     )
//                                         // )

//                                         // if (userData.user_primary_use === undefined) {
//                                         //   // return dispatch(push("/onboarding-questions"));
//                                         // } else

//                                         const store = getState()
//                                         const initial_landing_url =
//                                             store.localdata.customNavData
//                                                 .initial_landing_url

//                                         if (
//                                             initial_landing_url !== null &&
//                                             initial_landing_url !== undefined
//                                         ) {
//                                             dispatch(
//                                                 localDataCustomNavActionSuccess.setInitialLandingUrlSuccess(
//                                                     null
//                                                 )
//                                             )

//                                             dispatch(
//                                                 history.push(
//                                                     initial_landing_url
//                                                 )
//                                             )

//                                             // dispatch(push(initial_landing_url))
//                                         } else {
//                                             dispatch(history.push('/garage'))

//                                             // dispatch(push('/garage'))
//                                         }

//                                         // Identify user with internal ID
//                                         posthog.identify(data.id)

//                                         // Capture event
//                                         posthog.capture('USER LOGIN')

//                                         // Set email or any other data
//                                         if (posthog.people !== undefined) {
//                                             posthog.people.set_once({
//                                                 email: email.toLowerCase(),
//                                                 given_name:
//                                                     data.given_name &&
//                                                     data.given_name,
//                                                 family_name:
//                                                     data.family_name &&
//                                                     data.family_name,
//                                             })
//                                         }
//                                     })
//                                     .catch((error: any) => {
//                                         if (error === Error) {
//                                             let customErrorData: ICustomErrorData = {
//                                                 custom_message: `Looks like your internet has stoped working`,
//                                                 custom_message_detail:
//                                                     'Check your connection and refresh',
//                                                 custom_user_action_text:
//                                                     'Refresh page',
//                                             }

//                                             let customError: IReduxError = ConvertToReduxError(
//                                                 { status: '500' },
//                                                 customErrorData
//                                             )
//                                             dispatch(
//                                                 errorActions.signInUserError(
//                                                     customError
//                                                 )
//                                             )
//                                         } else {
//                                             let typedError: IReduxError = ConvertToReduxError(
//                                                 error
//                                             )
//                                             return dispatch(
//                                                 errorActions.signInUserError(
//                                                     typedError
//                                                 )
//                                             )
//                                         }
//                                     })
//                             } catch (error: any) {
//                                 console.error(error)
//                                 let typedError: IReduxError = ConvertToReduxError(
//                                     error
//                                 )
//                                 return dispatch(
//                                     errorActions.signInUserError(typedError)
//                                 )
//                             }
//                         },
//                         (error: any) => {
//                             let typedError: IReduxError = ConvertToReduxError(
//                                 error
//                             )
//                             return dispatch(
//                                 errorActions.signInUserError(typedError)
//                             )
//                         }
//                     )
//                     .catch((error: any) => {
//                         if (error === Error) {
//                             let customErrorData: ICustomErrorData = {
//                                 custom_message: `Looks like your internet has stoped working`,
//                                 custom_message_detail:
//                                     'Check your connection and refresh',
//                                 custom_user_action_text: 'Refresh page',
//                             }

//                             let customError: IReduxError = ConvertToReduxError(
//                                 { status: '500' },
//                                 customErrorData
//                             )
//                             dispatch(errorActions.signInUserError(customError))
//                         } else {
//                             let typedError: IReduxError = ConvertToReduxError(
//                                 error
//                             )
//                             return dispatch(
//                                 errorActions.signInUserError(typedError)
//                             )
//                         }
//                     })
//             } catch (error: any) {
//                 console.error(error)
//                 let typedError: IReduxError = ConvertToReduxError(error)
//                 return dispatch(errorActions.signInUserError(typedError))
//             }
//         })
//     }
// }
