import React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Icon from '../../atoms/icons'

interface IStyle {
    $isVertical?: boolean
}

const Wrapper = styled.div<IStyle>`
    display: flex;
    align-items: center;
    flex-direction: column;
    @media ${device.beyond_ipad_mobile} {
        flex-direction: ${(props) => (props.$isVertical ? 'column' : 'row')};
    }
`

const HeaderIconWrapper = styled.div<IStyle>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-left: 40px; */
    padding-bottom: 0px;
    padding-top: 40px;
    /* padding-right: 40px; */

    @media ${device.beyond_ipad_mobile} {
        ${(props) =>
            !props.$isVertical &&
            `width: auto;
        padding-left: 50px;
        padding-bottom: 10px;
        padding-top: 0px;
        padding-right: 30px;`}
    }
`

const HeaderIcon = styled.img<IStyle>`
    width: 73px;

    @media ${device.beyond_ipad_mobile} {
        width: ${(props) => (props.$isVertical ? '85px' : '130px')};
    }
`

const Body = styled.div<IStyle>`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 45px;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    /* height: 180px; */
    width: 100%;
    max-width: 500px;
    @media ${device.beyond_ipad_mobile} {
        align-items: ${(props) =>
            props.$isVertical ? 'center' : 'flex-start'};
    }
`

const Title = styled.div<IStyle>`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    padding-bottom: 16px;
    text-align: center;

    @media ${device.ipad} {
        font-size: 18px;
    }

    @media ${device.beyond_ipad_mobile} {
        font-size: 22px;
        text-align: ${(props) => (props.$isVertical ? 'center' : 'left')};
        ${(props) => props.$isVertical && 'line-height: 31px;'};
    }
`

const SingleText = styled.div<IStyle>`
    font-family: Lato-light;
    font-size: 15px;
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
    letter-spacing: 0.5px;

    @media ${device.ipad} {
        font-size: 18px;
    }

    @media ${device.beyond_ipad_mobile} {
        font-size: 18px;
        text-align: ${(props) => (props.$isVertical ? 'center' : 'left')};
        ${(props) => props.$isVertical && 'line-height: 31px;'};
    }
`

const SubTitle = styled.div<IStyle>`
    font-family: Lato-light;
    font-size: 13px;
    color: var(--text-strong, #1a1a1a);
    width: 90%;
    padding-bottom: 32px;
    text-align: center;

    @media ${device.ipad} {
        font-size: 15px;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        font-size: 15px;
        text-align: ${(props) => (props.$isVertical ? 'center' : 'left')};
        ${(props) =>
            props.$isVertical && 'line-height: 24px; padding-bottom: 30px;'};
    }
`

const RefreshButton = styled.button<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 35px;
    border: 1px solid var(--primary, #5ec3ca);
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 200ms;
    :hover {
        box-shadow: 2px 2px 4px var(--box-shadow);
        transition: all 200ms;
    }

    @media ${device.beyond_ipad_mobile} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const ButtonText = styled.div`
    font-family: Lato;
    font-size: 14px;
    color: var(--primary, #5ec3ca);
    padding-left: 10px;
    text-align: left;
    @media ${device.beyond_ipad_mobile} {
        font-size: 14px;
    }
`

interface Props {
    onClick: any
    singleText?: string
    title?: string
    subTitle?: string
    buttonText: string
    headIcon: string
    isModal?: boolean
    isRefresh?: boolean
}

interface State {}

// else "/garage"

// and then youd do : newUrl = onboardingUrlObject[index - 1]

export class ErrorBody extends React.Component<Props, State> {
    state = {}

    render() {
        return (
            <Wrapper $isVertical={this.props.isModal}>
                <HeaderIconWrapper $isVertical={this.props.isModal}>
                    <HeaderIcon
                        $isVertical={this.props.isModal}
                        src={this.props.headIcon}
                    />
                </HeaderIconWrapper>
                <Body $isVertical={this.props.isModal}>
                    {this.props.singleText ? (
                        <SingleText $isVertical={this.props.isModal}>
                            {this.props.singleText}
                        </SingleText>
                    ) : (
                        <>
                            <Title $isVertical={this.props.isModal}>
                                {this.props.title}
                            </Title>

                            <SubTitle $isVertical={this.props.isModal}>
                                {this.props.subTitle}
                            </SubTitle>
                        </>
                    )}

                    <RefreshButton
                        $isVertical={this.props.isModal}
                        onClick={this.props.onClick}
                    >
                        {this.props.isRefresh ? (
                            <Icon icon="reload" />
                        ) : (
                            <Icon icon="arrow_to_left" />
                        )}
                        <ButtonText>{this.props.buttonText}</ButtonText>
                    </RefreshButton>
                </Body>
            </Wrapper>
        )
    }
}

export default ErrorBody
