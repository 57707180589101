import { call, fork, put, select, take } from 'redux-saga/effects'
// WATCHER FUNCTION : watcher get car by id*

import * as actions from '../actions'
import * as timelineActions from '../../../timeline/actions'
import { api } from '../../../services'
import { ICarPayload } from 'IapiDataPayload'
import {
    ICarsObject,
    ICustomErrorData,
    IGalleryImagesObject,
    IGarageObject,
    IHighlightedFactsObject,
    IReduxError,
    ITechnicalInformationObject,
} from 'entityModels'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../../conversions/entities/conversionFromAPI'

import * as galleryActions from '../../../entities/galleries/actions/index'
import * as highlighted_facts_actions from '../../../entities/highlighted_facts/actions/index'
import * as tech_info_actions from '../../../entities/technical_information/actions/index'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'

import { state_select_garages, state_select_user } from './getCarPublicShares'
import { IUser } from 'myModels'
import { getGarageByGarageId } from '../../garages/sagas/garage'
import { dropDownActions } from '../../../localdata/dropdownData/reducer'

export function* getCarDataApiPayloadById(carid: string) {
    try {
        // resetting
        yield put(dropDownActions.reset_make_model_temps())

        const caritem: ICarPayload = yield call(
            api.entities.getData.getCarData_api,
            carid
        )

        let carObjects: ICarsObject = {}

        let galleryImagesObject: IGalleryImagesObject = {}

        let highlightedFactsObject: IHighlightedFactsObject = {}

        let technicalInformationObject: ITechnicalInformationObject = {}

        // convert to car state payload

        let car: ICarsObject = convertToCarState(caritem)

        car[carid].has_limited_garage_info = false

        let gallery_images_object: IGalleryImagesObject =
            convertToGalleryImagesState(caritem)

        let highlighted_facts_object: IHighlightedFactsObject =
            convertToHighlightedFactsState(caritem)

        let technical_information_object: ITechnicalInformationObject =
            convertToTechnicalInformationState(caritem)

        if (caritem.make_id) {
            yield put(dropDownActions.setTempMakeDisplay(caritem.make))
            yield put(dropDownActions.setTempMakeIDSuccess(caritem.make_id))
        }

        if (caritem.model_id) {
            yield put(dropDownActions.setTempModelDisplay(caritem.model))
            yield put(dropDownActions.setTempModelIDSuccess(caritem.model_id))
        }

        Object.assign(galleryImagesObject, gallery_images_object)

        Object.assign(carObjects, car)

        Object.assign(highlightedFactsObject, highlighted_facts_object)

        Object.assign(technicalInformationObject, technical_information_object)

        const results: ICarsObject = carObjects

        yield put(
            galleryActions.actions.setGalleryImagesSuccess(galleryImagesObject)
        )
        yield put(
            highlighted_facts_actions.actions.setHighlightedFactsSuccess(
                highlightedFactsObject
            )
        )
        yield put(
            tech_info_actions.actions.setTechnicalInformationSuccess(
                technicalInformationObject
            )
        )

        yield put(actions.loadingActions.getPublicSharesCarRequest(carid))

        yield put(actions.actions.getCarDataByIdSuccess(results))
        yield put(
            timelineActions.requestActions.getAllTimelineItemsByCarIDRequest({
                car_id: carid,
            })
        )

        yield put(actions.actions.setCurrentCarSuccess(carid))

        let userLoggedIn: IUser = yield select(state_select_user)
        let garage_id =
            userLoggedIn &&
            userLoggedIn.owns_garage &&
            userLoggedIn.owns_garage.uid

        if (garage_id) {
            let garages: IGarageObject = yield select(state_select_garages)
            if (
                (garage_id && !garages[garage_id]) ||
                !garages[garage_id].public_share_owned
            ) {
                yield call(getGarageByGarageId, garage_id)
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getCarDataApiPayloadById,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else if (error.status === 403) {
            let customErrorData: ICustomErrorData = {
                custom_message: `You don't have permission to view this car`,
                custom_user_action_text: 'Return to garage',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'car',
                true
            )
            yield put(actions.errorActions.getCarDataByIdError(customError))
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't get your car`,
                custom_user_action_text: 'Return to garage',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'car',
                false
            )
            yield put(actions.errorActions.getCarDataByIdError(customError))
        }
    }
}

function* watcherGetCarDataById() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.getCarDataByIdRequest
        )

        yield call(getCarDataApiPayloadById, payload)
    }
}

const get_car_sagas: any[] = [fork(watcherGetCarDataById)]

export default get_car_sagas
