import { all } from 'redux-saga/effects'
import filestorage_sagas_presigned_urls from '../filestorage/actions/sagas'
import garage_sagas from '../entities/garages/sagas'
import gallery_sagas from '../entities/galleries/sagas'
import modal_sagas from '../menus/sagas'
import user_sagas from '../user/sagas'
import cars_sagas from '../entities/cars/sagas'
import tasks_sagas from '../tasks/sagas'
import hf_sagas from '../entities/highlighted_facts/sagas'
import apex_sagas from '../theapex/sagas'
import timeline_sagas from '../timeline/sagas'
import tech_info_sagas from '../entities/technical_information/sagas'
import dropddown_data from '../localdata/dropdownData/sagas'
import search_users_sagas from '../searchUsers/sagas'
import showroom_sagas from '../showroom/sagas'
import localDataShowroomSaga from '../localdata/showroomLocalData/actions'
import insuranceApplicationSagas from '../insuranceQuoteApplication/sagas'
// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        ...filestorage_sagas_presigned_urls,
        ...garage_sagas,
        ...cars_sagas,
        ...gallery_sagas,
        ...modal_sagas,
        ...user_sagas,
        ...tasks_sagas,
        ...hf_sagas,
        ...apex_sagas,
        ...timeline_sagas,
        ...tech_info_sagas,
        ...dropddown_data,
        ...search_users_sagas,
        ...showroom_sagas,
        ...localDataShowroomSaga,
        ...insuranceApplicationSagas,
    ])
}
