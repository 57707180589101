import React from 'react'
import styled from 'styled-components'
import InsuranceEligibilityCardV2 from '../../../../molecules/cards/insurance/insuranceEligibilityCardV2'
import {
    cards_eligibility_insurance_desktop,
    cards_eligibility_insurance_mobile,
} from '../../../../pages/insurance_quote/insuranceEligibilityConfirmation'
import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'

type Props = {
    is_mobile?: boolean
}

const CardsGrid = styled.div`
    column-count: 2;
    column-gap: 8px;
    padding-top: 26px;
    padding-bottom: 26px;

    @media (max-width: 744px) {
        padding-left: 24px;
        padding-right: 24px;
    }

    @media (max-width: 538px) {
        padding-bottom: 0px;
        padding-top: 14px;
        grid-gap: 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
    }
`

export const InsuranceOnboardingEligibility = (props: Props) => {
    const size: WindowSize = useWindowSize()
    let showMobilearray = size && size.width && size.width < 539 ? true : false
    const cards_eligibility_insurance = showMobilearray
        ? cards_eligibility_insurance_mobile
        : cards_eligibility_insurance_desktop
    return (
        <CardsGrid>
            {cards_eligibility_insurance.map((card, i) => {
                return (
                    <React.Fragment
                        key={`${card.id}_${i}_${
                            props.is_mobile ? 'mobile' : 'desktop'
                        }`}
                    >
                        <InsuranceEligibilityCardV2
                            id={card.id}
                            title={card.title}
                            text={card.text}
                            is_mobile={props.is_mobile}
                        />
                    </React.Fragment>
                )
            })}
        </CardsGrid>
    )
}
