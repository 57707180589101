import * as React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import LandingPageMainButton from '../../../atoms/Button/landingPageMainButton'
import LoaderElasticThreeDots from '../../../atoms/loader/loaderElasticThreeDots'
import { device } from '../../displays/devices'
import posthog from 'posthog-js'
import { isIOS } from 'react-device-detect'

type Props = {
    isLast?: boolean
    children?: React.ReactNode
    submit: (e: any) => void
    submitDisabled?: boolean
    skip?: () => void
    skipCopy?: string
    goToWebsite?: boolean
    customSubmitText?: string
    isBtnLoading?: boolean
}

const OnboardingFormContainerDesktop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`

const PaddingUnit = styled.div<{ $times?: number }>`
    padding-top: ${(props) => (props.$times ? `${props.$times * 8}px` : '8px')};
`

const BottomBtns = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 32px;
    padding-top: 32px;

    @media ${device.large_desktop} {
        padding-top: 52px;
    }

    @media ${device.smallest_laptops} {
        padding-top: 40px;
    }
`

const Skip = styled.button`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    padding: 0px;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.1s;
    :focus {
        outline: 0;
        background-color: #5ec3ca1d;
    }
`

// const WebLink = styled.a`
//     width: 100%;
//     text-align: center;
//     font-family: Lato;
//     color: #5ec3ca;
//     font-size: 14px;
//     font-weight: 500;
//     background-color: transparent;
//     border: none;
//     padding: 0px;
//     height: 40px;
//     width: 100%;
//     border-radius: 4px;
// `

const OnboardingFormTemplateDesktop: React.FunctionComponent<Props> = (
    props: Props
) => {
    let {
        children,
        submit,
        skip,
        submitDisabled,
        goToWebsite,
        customSubmitText,
        isBtnLoading,
        skipCopy,
        isLast,
    } = props

    let history = useHistory()

    return (
        <OnboardingFormContainerDesktop>
            {children}

            <BottomBtns>
                <LandingPageMainButton
                    type="submit"
                    disabled={submitDisabled ? true : false}
                    aria-live="polite"
                    dataCyId="onboarding-step-2-next-button-mobile"
                    onClick={submit}
                    normal_height="48px"
                >
                    <span
                        style={{
                            textTransform: 'none',
                            fontFamily: 'Lato',
                            fontSize: 16,
                            letterSpacing: 0,
                        }}
                    >
                        {isBtnLoading ? (
                            <LoaderElasticThreeDots />
                        ) : (
                            customSubmitText ?? 'Continue'
                        )}
                    </span>
                </LandingPageMainButton>

                <PaddingUnit $times={2} />
                {skip && (
                    <Skip onClick={skip} data-attr="skip-step-button-mobile">
                        {skipCopy ?? 'Skip'}
                    </Skip>
                )}
                {goToWebsite && (
                    <ButtonAtom
                        height="48px"
                        width={'100%'}
                        theme="capitalize-white-background"
                        fontSize={15}
                        onClick={() => {
                            posthog.capture(
                                'garage clicked from onboarding CTA'
                            )
                            isIOS
                                ? history.push(`/garage?native_app_prompt=true`)
                                : history.push(`/garage?tutorial=true`)
                        }}
                    >
                        Go to your garage
                    </ButtonAtom>
                )}

                {isLast && (
                    <>
                        <PaddingUnit $times={2} />
                        <ButtonAtom
                            fontSize={15}
                            height="48px"
                            width={'100%'}
                            theme="capitalize-white-background"
                            onClick={() => {
                                posthog.capture(
                                    'insurance clicked from onboarding CTA'
                                )
                                history.push('/insurance')
                            }}
                        >
                            Go to insurance dashboard
                        </ButtonAtom>
                    </>
                )}
            </BottomBtns>
        </OnboardingFormContainerDesktop>
    )
}

export default OnboardingFormTemplateDesktop
