import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import { motion } from 'framer-motion'
import ScrollLock from 'react-scrolllock'
import BinIcon from '../../icons/components/binIcon'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const ModalWrapper = styled.div<IStyle>`
    @media ${device.desktop} {
        display: none;
    }
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    top: 0;
    bottom: 0;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    animation: 0.1s fadeIn both;
    animation-delay: 0s;

    z-index: ${(props) => (props.isOpen ? '10' : '-2')};
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    justify-content: flex-end;
    flex-direction: column;
    padding: 0px;
`

const MenuItemWrap = styled.div<IStyle>`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-bottom: 20px;
    padding-top: 8px;
    /* transform: translateY(100%); */

    @keyframes slideUp {
        from {
            transform: translateY(100%);
            opacity: 0;
        }

        to {
            transform: translateY(0%);
            opacity: 1;
        }
    }

    animation: slideUp 0.3s cubic-bezier(0.87, 0, 0.13, 1) both;
    animation-delay: 0.05s;

    z-index: ${(props) => (props.isOpen ? '10' : '-2')};
    border: none;

    @media ${device.ipad} {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
`

const MenuItemBox = styled(motion.div)<IStyle>`
    display: flex;
    padding: 16px 40px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background-color: ${(props) =>
        props.is_active ? 'var(--background_primary_soft)' : undefined};
`

const MenuItem = styled.div<IStyle>`
    color: ${(props) =>
        props.is_active
            ? 'var(--primary, #5ec3ca)'
            : 'var(--text-darker, #616161)'};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const Handle = styled.div`
    width: 60px;
    height: 4px;
    border-radius: 5px;
    background-color: var(--text-darker, #616161);
    align-self: center;
    margin-bottom: 8px;
`

type IStyle = {
    isOpen?: boolean
    is_last?: boolean
    is_active?: boolean
}

type IItem = {
    text: string
    id: string
    name?: string
    action: any
    icon?: string
}

interface IProps {
    toggle: any
    isOpen: boolean
    items: IItem[]
    active_item: string
}

const TasksSubMenuActions: React.SFC<IProps> = (props) => {
    const { toggle, isOpen, items, active_item } = props

    const { theme } = useThemes()

    return (
        <Faded>
            {isOpen && <ScrollLock />}
            <ModalWrapper
                isOpen={isOpen}
                onClick={(e: any) => {
                    e.preventDefault()
                    setTimeout(function () {
                        toggle()
                    }, 50)
                }}
            />

            <MenuItemWrap isOpen={isOpen}>
                <Handle />
                {items.map((item: any, index: any) => (
                    <MenuItemBox
                        onClick={(e: any) => {
                            e.preventDefault()
                            item.action && item.action(item.id)
                        }}
                        key={`tasks_sub_menu_${item.id}_${index}_mobile`}
                        whileTap={{
                            opacity: 1,
                            backgroundColor: colours[theme].border_muted,
                        }}
                    >
                        {item.id === 'delete' && (
                            <BinIcon color={colours[theme].error} />
                        )}
                        <MenuItem
                            is_active={
                                item?.text === active_item ||
                                item?.name === active_item
                                    ? true
                                    : false
                            }
                            style={
                                item.id === 'delete'
                                    ? { color: colours[theme].error }
                                    : {}
                            }
                        >
                            {item.text}
                        </MenuItem>
                    </MenuItemBox>
                ))}
            </MenuItemWrap>
        </Faded>
    )
}

export default TasksSubMenuActions
