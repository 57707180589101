import membership_sections_list from "./membership_sections";
import { IMembershipPageSection } from "myInterfaces";

export type IMembershipPageState = IMembershipPageSection[];

const initialState = membership_sections_list;

export const membership_page = (state: IMembershipPageState = initialState) =>
  state;

export default membership_page;

export type MembershipPageState = ReturnType<typeof membership_page>;
