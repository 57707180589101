import * as React from 'react'
import styled from 'styled-components'
import CrossIcon from '../../atoms/icons/crossIcon'
import badge from '../../../public/assets/logo/pcc_symbol.svg'
import { useEffect, useState } from 'react'
import { checkIfNotSafariOnIosDevice } from '../../../helpers/deviceChecks/deviceAndBrowserChecks'
import { get_check_cookie_name } from '../../../helpers/cookies'
import { isIOS } from 'react-device-detect'

const Banner = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    height: 100px;
    z-index: 200;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 24px;
    padding-left: 0px;
    box-shadow: 4px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const LeftSide = styled.div`
    display: flex;
    align-items: center;
`

const CloseBtn = styled.button`
    all: unset;
    height: 48px;
    padding-left: 16px;
    padding-right: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

const BlueBtn = styled.a`
    all: unset;
    background-color: #2f6cf6;
    padding: 4px 16px;
    border-radius: 20px;
    font-size: 14px;
    font-family: Lato-bold;
    color: var(--bg-color, #fff);
    letter-spacing: 0.01px;
`

const Badge = styled.div`
    border-radius: 10px;
    background: linear-gradient(to right, #4f9fa5, #5ec3ca);
    height: 64px;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextWrapper = styled.div`
    font-family: Lato-Light;
    padding-left: 10px;
`
const TextBlack = styled.p`
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
    line-height: 26px;
    letter-spacing: 0.02px;
`
const TextGrey = styled.p`
    margin: 0;
    padding: 0;
    width: 160px;
    font-size: 13px;
    color: var(--text-default, #616161);
    line-height: 16px;
`

const Logo = styled.img`
    width: 24px;
    height: auto;
    filter: invert(100%);
`

const InstallIosNativeAppBanner: React.FunctionComponent = () => {
    const [showBanner, toggleBanner] = useState(false)

    useEffect(() => {
        let currentUrl = window.location.href

        let isOtherBrowserThanSafari = checkIfNotSafariOnIosDevice()

        let isRegistrationPage = currentUrl.includes('register')

        let isRegisterPageWithQueryParams =
            currentUrl.includes('register') && currentUrl.includes('?')
                ? true
                : false

        const q_params = new URLSearchParams(window.location.search)
        const utm_source = q_params.get('utm_source')

        let areQueryParamsFromHandoverOrSharing =
            utm_source === 'share_invite' || utm_source === 'handover_invite'
                ? true
                : false

        const hasFbCookie = get_check_cookie_name('fbclid') ? true : false

        // only if ios
        if (isIOS) {
            // if not Safari
            if (isOtherBrowserThanSafari) {
                // if not registration page
                if (isRegistrationPage === false) {
                    toggleBanner(true)
                } else if (
                    // if (registration page AND there are no fb cookies AND there are no q_params OR it has q_params but they are from sharing or handover)
                    hasFbCookie === false &&
                    (isRegisterPageWithQueryParams === false ||
                        (isRegisterPageWithQueryParams === true &&
                            areQueryParamsFromHandoverOrSharing === true))
                ) {
                    toggleBanner(true)
                } else {
                    // else hide it
                    toggleBanner(false)
                }
            } else {
                // if on safari
                if (
                    // if (registration page AND there are no fb cookies AND there are no q_params OR it has q_params but they are from sharing or handover)
                    isRegistrationPage === true &&
                    hasFbCookie === false &&
                    (isRegisterPageWithQueryParams === false ||
                        areQueryParamsFromHandoverOrSharing === true)
                ) {
                    toggleBanner(true)
                } else {
                    toggleBanner(false)
                }
            }
        } else {
            toggleBanner(false)
        }
    }, [])

    return showBanner ? (
        <Banner>
            <LeftSide>
                <CloseBtn onClick={() => toggleBanner(false)}>
                    <CrossIcon color="#bcbcbc" size="14" />
                </CloseBtn>
                <Badge>
                    <Logo src={badge} alt="logo" />
                </Badge>
                <TextWrapper>
                    <TextBlack>Custodian</TextBlack>
                    <TextGrey>Automotive ownership made easy</TextGrey>
                </TextWrapper>
            </LeftSide>
            <BlueBtn
                href="https://apps.apple.com/gb/app/custodian/id1665962547"
                target="_blank"
                rel="noreferrer"
            >
                OPEN
            </BlueBtn>
        </Banner>
    ) : (
        <div />
    )
}

export default InstallIosNativeAppBanner
