import * as urls from '../urls'

import {
    IGaragePayload,
    ICarPayload,
    ICheckCarActionsResponse as IGetCarActionCheckApiResponse,
} from 'IapiDataPayload'
import { IPublicShareAPIRes } from 'ApiInterfaces'
import {
    IGetCarActionCheckRequest,
    IGetSortedGarageApiPayload,
} from 'entityModels'
import { ConvertGetSortedGarageUrl } from '../../conversions/entities/getSortedGarageUrl'
import { customHeader } from '../headers'
import { apiSentryErrorHandler } from '../errorHandler'

//can be used with 1 id
export const getCarData_api = (id: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.get_car_endpoint(id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: ICarPayload) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get car data error')
                        return Promise.reject(response)
                    }

                    if (response.status === 200) {
                        if (data) {
                            return data
                        }
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const getGarageData_api = (id: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.get_garage_endpoint(id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IGaragePayload) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get garage error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const getSortedGarageData_api = (
    payload: IGetSortedGarageApiPayload
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    let urlBuilt = ConvertGetSortedGarageUrl(payload)

    return fetch(urls.get_garage_endpoint(urlBuilt), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IGaragePayload) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get sorted garage error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const getGaragePublicSharesAPI = (garageid: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.garage_public_shares_endpoint(garageid),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: IPublicShareAPIRes) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get garage public shares error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const getCarPublicSharesAPI = (carid: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.car_public_shares_endpoint(carid), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IPublicShareAPIRes) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Get car public shares error'
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const getCarDirectSharesAPI = (carid: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.car_direct_shares_endpoint(carid), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Get car direct shares error'
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const getGarageDirectSharesAPI = (garageid: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.garage_direct_shares_endpoint(garageid),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get garage direct shares error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const get_car_action_check_api = (
    p: IGetCarActionCheckRequest
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.get_car_action_check_endpoint(p), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IGetCarActionCheckApiResponse) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Check car actions error')
                        return Promise.reject(response)
                    }

                    if (response.status === 200) {
                        if (data) {
                            return data
                        }
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
