import { motion } from 'framer-motion'

type Props = {
    onClick: any
    dataCyId?: string
    width?: string
    height?: string
    color?: string
}

const EditIcon = (props: Props) => (
    <motion.div
        whileTap={{ scale: 1.05 }}
        onClick={props.onClick}
        data-attr={props.dataCyId && props.dataCyId}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <svg
            width={props.width ?? '16'}
            height={props.height ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.18403 3.18396C9.47692 2.89107 9.95179 2.89107 10.2447 3.18396L12.8161 5.75539C13.109 6.04829 13.109 6.52316 12.8161 6.81605C12.5232 7.10895 12.0483 7.10895 11.7555 6.81605L9.18403 4.24462C8.89113 3.95173 8.89113 3.47686 9.18403 3.18396Z"
                fill={props.color ?? '#5EC3CA'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4286 0.939331L15.0607 4.57142L5.81202 13.8201L0.969238 15.0308L2.17993 10.188L11.4286 0.939331ZM3.53438 10.9549L3.03079 12.9692L5.04515 12.4656L12.9394 4.57142L11.4286 3.06065L3.53438 10.9549Z"
                fill={props.color ?? '#5EC3CA'}
            />
        </svg>
    </motion.div>
)

export default EditIcon
