import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

type Props = {
    direction: 'left' | 'right' | ''
    initial: false | 'enter'
    children?: React.ReactNode
}

const SideSlider = (props: Props) => {
    const variants = {
        enter: () => ({
            x:
                props.direction === 'right'
                    ? 900
                    : props.direction === 'left'
                    ? -900
                    : 0,
            opacity: 0,
        }),
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1,
        },
        exit: (direction: number) => ({
            zIndex: 0,
            x:
                props.direction === 'left'
                    ? 900
                    : props.direction === 'right'
                    ? -900
                    : 0,
            opacity: 0,
        }),
    }

    return (
        <AnimatePresence>
            <motion.div
                variants={variants}
                initial={props.initial}
                animate="center"
                exit="exit"
                transition={{
                    opacity: { duration: 0.1 },
                }}
            >
                {props.children}
            </motion.div>
        </AnimatePresence>
    )
}

export default SideSlider
