import { IShareRecipient } from 'entityModels'
import * as React from 'react'

import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

import TooltipRowBtn from '../Button/tooltipRowBtn'
import UserAccountCircle from '../user/userAccountCircle'
import checked from '../../../public/assets/icons/checked_white.svg'
import pending from '../../../public/assets/icons/pending_icon.svg'
import { shareCardConverterFormat } from '../../../redux/conversions/time'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const UserImageCircleProfile = styled.div`
    position: absolute;
    left: 30px;
    transform: translateY(-24px);
`

type IStatus = {
    $status: 'pending' | 'accepted' | 'ignored'
}
const StatusCircle = styled.div<IStatus>`
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -3px;
    margin-right: -5px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.$status === 'accepted'
            ? 'var(--primary, #5ec3ca)'
            : 'var(--border-muted, #e5e5e5)'};
    display: flex;
    align-items: center;
    justify-content: center;
`

const TableItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    position: relative;
`

const TableItemInfoCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 80px;
    position: relative;
`

const TableItemBtnsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
`

const Name = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 17px;
`

const DateSpan = styled.span`
    color: var(--text-default, #666666);
    font-size: 12px;
    font-style: italic;
    display: inline-block;
    padding-left: 8px;
`
const Email = styled.div`
    color: var(--text-darker, #616161);
    font-size: 15px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    background-color: var(--off-bg-color, #fafafa);
    border-top: 1px solid var(--border_muted);
    border-left: 1px solid var(--border_muted);
    border-right: 1px solid var(--border_muted);
    height: 85px;
    width: 40vw;
    min-width: 730px;
    padding-right: 30px;
    min-width: 730px;
    padding-right: 30px;
    @media ${device.ipad} {
        min-width: 710px;
        padding-right: 0px;
    }
`

type IQuickShareActions = {
    email: any
    // copy: any
    // settings: any
    delete: any
}

type Props = {
    recipient: IShareRecipient
    sharedOn: string
    index: number
    isLast: boolean
    actionsData: IQuickShareActions
}

export const generate_recipients_name = (recipient: IShareRecipient) => {
    if (recipient.recipient_type === 'platform') {
        return recipient.display_name
            ? recipient.display_name
            : recipient.given_name || recipient.family_name
            ? `${recipient.given_name} ${recipient.family_name}`
            : `Custodian User`
    } else {
        return recipient.given_name || recipient.family_name
            ? `${recipient.given_name} ${recipient.family_name}`
            : 'Pending Account'
    }
}

const ShareRowDesktop: React.FunctionComponent<Props> = (props: Props) => {
    let { recipient, sharedOn, isLast, index, actionsData } = props

    const { theme } = useThemes()

    return recipient ? (
        <Container
            style={{
                borderTopLeftRadius: index === 0 ? '5px' : '0px',
                borderBottomLeftRadius: isLast ? '5px' : '0px',

                borderTopRightRadius: index === 0 ? '5px' : '0px',
                borderBottomRightRadius: isLast ? '5px' : '0px',
                borderBottom: isLast ? '1px solid var(--border_muted)' : 'none',
            }}
        >
            <TableItemContainer>
                <UserImageCircleProfile>
                    <UserAccountCircle
                        size="50px"
                        url={
                            recipient.profile_picture &&
                            recipient.profile_picture.url
                                ? recipient.profile_picture.url
                                : undefined
                        }
                    />
                    <StatusCircle
                        $status={
                            recipient.approvalStatus
                                ? recipient.approvalStatus
                                : 'pending'
                        }
                    >
                        <img
                            alt=""
                            src={
                                recipient.approvalStatus === 'accepted'
                                    ? checked
                                    : pending
                            }
                            width={
                                recipient.approvalStatus === 'accepted'
                                    ? '10px'
                                    : '15px'
                            }
                        />
                    </StatusCircle>
                </UserImageCircleProfile>

                <TableItemInfoCol>
                    <Name>
                        {generate_recipients_name(recipient)}
                        <DateSpan>
                            {` Shared on ${shareCardConverterFormat(sharedOn)}`}
                        </DateSpan>
                    </Name>

                    {recipient.recipient_type === 'external' && (
                        <Email>{recipient.email}</Email>
                    )}
                </TableItemInfoCol>

                <TableItemBtnsRow>
                    {/* <TooltipRowBtn
                        copy="copy private share invitation"
                        iconHeight="18px"
                        iconWidth="15px"
                        icon="copy_clipboard"
                        onClick={() => actionsData.copy()}
                    /> */}

                    <TooltipRowBtn
                        copy="Send email reminder"
                        iconHeight="18px"
                        iconWidth="15px"
                        icon="email_icon"
                        onClick={() =>
                            actionsData.email({
                                invite_id: recipient.id,
                                recipient_email: recipient.email,
                            })
                        }
                        isDisabled={recipient.recipient_type === 'platform'}
                    />

                    {/* <TooltipRowBtn
                        copy="settings"
                        iconHeight="18px"
                        iconWidth="18px"
                        icon="cog"
                        onClick={() => actionsData.settings()}
                    /> */}

                    <TooltipRowBtn
                        copy="Remove recipient from share"
                        iconHeight="10px"
                        iconWidth="10px"
                        icon="light_red_cross"
                        onClick={() =>
                            actionsData.delete({
                                recipient_id: recipient.id,
                                recipient_type: recipient.recipient_type,
                            })
                        }
                        customColor={colours[theme].error}
                    />
                </TableItemBtnsRow>
            </TableItemContainer>
        </Container>
    ) : (
        <div />
    )
}

export default ShareRowDesktop
