import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
`

const Skip = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 14px;
    color: #838383;
    cursor: pointer;
    font-family: 'Lato-light';
    letter-spacing: 1px;

    @media ${device.mobile} {
    }
`

const NextBtn = styled.div<NextBtnProps>`
    position: absolute;
    bottom: 20px;
    color: #5ec3ca;
    cursor: pointer;
    font-size: 15px;
    font-family: lato-light;
    ${(props) =>
        props.translate
            ? 'left: 50%; transform: translate(-50%, 0);'
            : 'right: 30px;'}
`

const PrevBtn = styled.div`
    position: absolute;
    left: 30px;
    bottom: 20px;
    color: #5ec3ca;
    cursor: pointer;
    font-size: 15px;
    font-family: lato-light;
`

interface NextBtnProps {
    translate?: any
}

interface Props {
    children?: React.ReactNode
    skip?: any
    prev?: any
    prevBtnText?: string
    next?: any
    nextBtnText?: string
    translateNextBtn?: boolean
}

const TutorialWrapperMobile: React.SFC<Props> = ({
    children,
    skip,
    prev,
    prevBtnText,
    next,
    nextBtnText,
    translateNextBtn,
}) => (
    <Wrapper>
        {skip && <Skip onClick={skip}>Skip</Skip>}

        {children}

        {prev && (
            <PrevBtn onClick={prev}>
                {prevBtnText ? prevBtnText : 'prev'}
            </PrevBtn>
        )}

        {next && (
            <NextBtn onClick={next} translate={translateNextBtn}>
                {nextBtnText ? nextBtnText : 'next'}
            </NextBtn>
        )}
    </Wrapper>
)

export default TutorialWrapperMobile
