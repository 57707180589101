export const GetUserIPAndCallFbTracking = (
    functionToRunWithOptionalIP?: any
): Promise<any> => {
    return fetch('https://api.ipify.org?format=json')
        .then((response: any) => {
            if (response.ok !== true) {
                return Promise.reject(response)
            }
            return response
                .json()
                .then((data: any) => {
                    if (response.status === 200) {
                        if (data && data.ip) {
                            if (functionToRunWithOptionalIP) {
                                functionToRunWithOptionalIP(data.ip)
                            }
                            return data
                        }
                    } else if (response.ok !== true) {
                        return Promise.reject(response)
                    }
                })
                .catch((error: any) => {
                    Promise.reject(error)
                })
        })
        .catch((error: any) => {
            if (functionToRunWithOptionalIP) {
                functionToRunWithOptionalIP()
            }
            if (error.status) {
                return Promise.reject(error)
            } else throw Error
        })
}
