// import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { useState } from 'react'

interface Props {
    tabindex?: number
    linecolor?: string
    minheight?: string
    keepLabelRaised?: boolean
    fontSize?: string
    placeholderStyle?: any
}

const Wrapper = styled.div`
    width: 100%;
`

const FormWrapper = styled.div<{
    $height?: string
    $customActiveColour?: string
}>`
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    display: flex;
    border: var(--text-muted) 1px solid;
    border-radius: 4px;
    height: ${(props) => props.$height ?? '72px'};

    &:focus-within {
        border-color: ${(props) =>
            props.$customActiveColour ?? 'var(--primary, #5ec3ca)'};
    }

    & textarea:focus ~ label {
        top: 0%;
        left: 0;
        transform: translate(8px, -50%) scale(0.8);
        color: ${(props) =>
            props.$customActiveColour ?? 'var(--primary, #5ec3ca)'};
        min-width: auto;
    }
`

const StyledTextArea = styled.textarea<Props>`
    width: 100%;
    border: none;
    font-family: Lato;
    background-color: transparent;
    font-size: 14px;
    resize: none;
    height: 100%;
    border: none;
    border-radius: 4px;
    padding: 15px;
    color: var(--text-strong, #1a1a1a);

    :focus {
        outline: 0;
    }
    ::placeholder {
        ${(props) =>
            `font-size: ${props.fontSize ?? '16px'};
            ${props.placeholderStyle ?? ``}
            font-family: Lato-Light;
            color: var(--text-default, #666);
            &:first-letter {
                text-transform: uppercase;
            }`};
    }

    :focus::-webkit-input-placeholder {
        padding-top: 0px;
        transition: 0.15s;
    }

    @media ${device.mobile} {
        color: var(--text-strong, #1a1a1a);
        font-size: 16px;
        /* height: 100%; */
    }

    @media ${device.ipad} {
        color: var(--text-strong, #1a1a1a);
        font-size: 16px;
        /* height: 100%; */
    }
`

const AdornmentWrapper = styled.div`
    padding: 0 14px;
    display: flex;
    align-items: center;
    color: var(--text-muted, #b3b3b3);

    @media (max-width: 350px) {
        padding: 0 10px;
    }
`

const HelperText = styled.div`
    color: var(--text-darker, #616161);
    font-size: 12px;
    padding-top: 8px;
    padding-left: 12px;
`

const Label = styled.label<{ raise: boolean; backgroundColor?: string }>`
    position: absolute;
    ${(props) => `
        top: ${props.raise ? '0%' : '30%'};
        transform-origin: top left;
        transform: ${
            props.raise
                ? `translate(8px, -50%) scale(0.8)`
                : `translate(8px, -50%) scale(1)`
        };
        left: 0;
        min-width: 30px;
       background-color: ${
           props.backgroundColor
               ? props.backgroundColor
               : 'var(--bg-color, #fff)'
       };
    `}

    transition: 0.15s;

    color: var(--text-darker, #616161);
    font-size: 1.1em;
    padding: 0 8px;
    pointer-events: none;
    font-family: Lato;

    &:first-letter {
        text-transform: uppercase;
    }
`

interface ITextAreaProps {
    id?: string
    name: string
    value: string
    placeholder?: string
    handleChange: (e: any) => any
    tabindex?: number
    borderBottom?: boolean
    dataCyId?: string
    linecolor?: string
    minheight?: string
    height?: string
    disabled?: boolean
    helperText?: string
    keepLabelRaised?: boolean
    maxLength?: number
    rightAdornment?: React.ReactNode
    backgroundColor?: string
    furtherStyle?: React.CSSProperties | undefined
    customActiveColour?: string
}

function AnimatedTextareaShort({
    id,
    name,
    value,
    handleChange,
    placeholder,
    tabindex,
    dataCyId,
    linecolor,
    minheight,
    disabled,
    helperText,
    maxLength,
    keepLabelRaised,
    rightAdornment,
    backgroundColor,
    height,
    furtherStyle,
    customActiveColour,
}: ITextAreaProps) {
    const [isFocused, setIsFocused] = useState<boolean>(false)

    return (
        <Wrapper>
            <FormWrapper
                style={{
                    opacity: disabled ? 0.5 : 1,
                }}
                $height={height}
                $customActiveColour={customActiveColour}
            >
                <StyledTextArea
                    className="actual-textarea"
                    id={id}
                    name={name}
                    value={value}
                    placeholder={
                        isFocused && name && !placeholder ? name : placeholder
                    }
                    onChange={handleChange}
                    tabindex={tabindex}
                    // ref={elementRef}
                    data-attr={dataCyId}
                    linecolor={linecolor}
                    minheight={minheight}
                    disabled={disabled}
                    keepLabelRaised={keepLabelRaised}
                    maxLength={maxLength}
                    onFocus={() => {
                        setIsFocused(true)
                    }}
                    onBlur={() => {
                        setIsFocused(false)
                    }}
                    style={furtherStyle}
                />
                {placeholder && (
                    <Label
                        style={{ width: 'max-content' }}
                        raise={true}
                        backgroundColor={backgroundColor}
                    >
                        {name}
                    </Label>
                )}

                {!placeholder && (
                    <Label
                        style={{ width: 'max-content' }}
                        raise={!!value ? true : false}
                        backgroundColor={backgroundColor}
                    >
                        {name}
                    </Label>
                )}

                {rightAdornment && (
                    <AdornmentWrapper style={{ width: 'max-content' }}>
                        {rightAdornment}
                    </AdornmentWrapper>
                )}
            </FormWrapper>
            {helperText && <HelperText>{helperText}</HelperText>}
        </Wrapper>
    )
}

export default AnimatedTextareaShort
