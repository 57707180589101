import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import { getRelativeTimeAgoFromToday } from '../../../../helpers/time/relativeTimeAgoFromToday'

type StyledProps = {
    height?: string
    $isEntryPage?: boolean
    $device: 'mobile' | 'desktop'
}
const Container = styled(motion.div)<StyledProps>`
    background-color: ${(props) =>
        props.$device === 'desktop' ? '#f4fffe' : 'transparent'};
    height: ${(props) => (props.height ? props.height : '30px')};
    padding-left: ${(props) => (props.$device === 'desktop' ? '15px' : '7px')};
    padding-right: 15px;
    display: flex;
    align-items: ${(props) =>
        props.$device === 'desktop' ? 'center' : 'flex-start'};
    justify-content: center;
    border-radius: 6px;
    color: ${(props) => (props.$device === 'desktop' ? '#484848' : '#797979')};
    font-family: 'Lato';
    font-size: ${(props) => (props.$device === 'desktop' ? '11px' : '10px')};
    letter-spacing: 0.7px;
    width: fit-content;
    @media (max-width: 320px) {
        font-size: 8px;
        padding-left: 3px;
    }
`

type Props = {
    dataCyId?: string
    height?: string
    entry_date?: string
    device: 'mobile' | 'desktop'
}

class TimeAgoEntryTag extends React.Component<Props, {}> {
    render() {
        const { dataCyId, height, entry_date, device } = this.props

        let converted_entry_date: Date | undefined = entry_date
            ? new Date(entry_date)
            : undefined

        let relativeTime =
            converted_entry_date &&
            getRelativeTimeAgoFromToday(converted_entry_date)

        return relativeTime ? (
            <Container
                data-attr={dataCyId && dataCyId}
                height={height}
                $device={device}
            >
                {relativeTime === 'a few seconds'
                    ? 'today'
                    : relativeTime === 'a day'
                    ? '1 day ago'
                    : relativeTime === 'a month'
                    ? '1 month ago'
                    : relativeTime === 'a year'
                    ? '1 year ago'
                    : `${relativeTime} ago`}
            </Container>
        ) : (
            <div style={{ display: 'none' }} />
        )
    }
}

export default TimeAgoEntryTag
