import { calculateInsuranceStats } from '../../../helpers/calculateInsuranceStats'
import {
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplicationValidation,
} from '../../../insuranceQuoteApplication/reducer'
import {
    IApplicationNamedDriversIDs_API,
    IInsuranceApplication_API,
    IInsuranceApplication_APIErrors,
} from '../../../insuranceQuoteApplication/types'
import { convertApplicationToAddressesNormalised } from './addressesNormalised'
import { convertApplicationToReducerCarIds } from './car_ids'
import {
    convertApplicationToInsuredVehicles,
    convertApplicationToInsuredVehiclesErrors,
} from './custodianCar'
import {
    convertCustomerToReducerMainDriver,
    convertCustomerToReducerMainDriverError,
} from './customer'
import {
    convertApplicationToDriveList,
    convertApplicationToDriveListErrors,
} from './drive_list'
import {
    convertApplicationToExternalVehicles,
    convertApplicationToExternalVehiclesError,
} from './externalCar'
import { ConvertApplicationToInsuranceDetails } from './inauranceDetails'
import {
    convertnamedDriversToReducerOtherDriversErrorsNormalised,
    convertnamedDriversToReducerOtherDriversNormalised,
} from './namedDriver'

export const ConvertInsuranceApplicationToReducer = (
    application: IInsuranceApplication_API
): IInsuranceQuoteApplication => {
    let ids_obj = convertApplicationToReducerCarIds(application)
    let normalisedDriveList = convertApplicationToDriveList(application)

    let res: IInsuranceQuoteApplication = {
        id: application.uid,
        addresses: convertApplicationToAddressesNormalised(application),
        insurance_details: ConvertApplicationToInsuranceDetails(application),
        insurance_details_errors: {},
        insurance_details_stats: 0,
        other_drivers: convertnamedDriversToReducerOtherDriversNormalised(
            application,
            normalisedDriveList
        ),
        status: application.status,
        vehicles: {
            ...convertApplicationToInsuredVehicles(application),
            ...convertApplicationToExternalVehicles(application),
        },
        main_driver: { ...convertCustomerToReducerMainDriver(application) },
        vehicles_to_insure_ids_list: [...ids_obj.vehicles_to_insure_ids_list],
        everyday_car_ids: [...ids_obj.everyday_car_ids],
        external_car_ids: [...ids_obj.external_car_ids],
        selected_named_drivers: application.application_named_drivers
            ? application.application_named_drivers.map(
                  (item: IApplicationNamedDriversIDs_API) => {
                      return item.uid
                  }
              )
            : [],
    }

    return res
}

export const ConvertInsuranceApplicationErrorsToReducer = (
    validationRes: IInsuranceApplication_APIErrors
): IInsuranceQuoteApplicationValidation => {
    let normalisedDriveList = convertApplicationToDriveListErrors(validationRes)

    let res: IInsuranceQuoteApplicationValidation = {
        id: validationRes.uid,
        insurance_details_errors:
            ConvertApplicationToInsuranceDetails(validationRes),
        other_drivers: convertnamedDriversToReducerOtherDriversErrorsNormalised(
            validationRes,
            normalisedDriveList
        ),
        application_custodian_cars: validationRes.application_custodian_cars,
        vehicles: {
            ...convertApplicationToInsuredVehiclesErrors(validationRes),
            ...convertApplicationToExternalVehiclesError(validationRes),
        },
        main_driver: {
            ...convertCustomerToReducerMainDriverError(validationRes),
        },
    }

    return res
}

export const addStatsToReducer = (
    reducer: IInsuranceQuoteApplication,
    validationReducerData: IInsuranceQuoteApplicationValidation
): IInsuranceQuoteApplication => {
    let applicationReducerData = { ...reducer }

    // console.log('applicationReducerData', applicationReducerData)
    // console.log('validationReducerData', validationReducerData)

    let insurance_application_completion_stats = calculateInsuranceStats(
        applicationReducerData,
        validationReducerData
    )
    // console.log(
    //     'insurance_application_completion_stats',
    //     insurance_application_completion_stats
    // )

    // console.log(
    //     'insurance_application_completion_stats ===>>> ',
    //     insurance_application_completion_stats
    // )

    applicationReducerData.overall_completion =
        insurance_application_completion_stats?.overallApplicationStats?.percentage

    applicationReducerData.insurance_details_stats =
        insurance_application_completion_stats?.cardsObjStats.basicDetails?.percentage
    applicationReducerData.vehicles_overall_stats =
        insurance_application_completion_stats?.cardsObjStats?.vehiclesToInsure?.percentage
    applicationReducerData.everyday_car_stats =
        insurance_application_completion_stats?.cardsObjStats.everyday_vehicle.percentage
    applicationReducerData.other_drivers_overall_stats =
        insurance_application_completion_stats?.cardsObjStats?.otherDrivers?.percentage
    applicationReducerData.main_driver_overall_stats =
        insurance_application_completion_stats?.cardsObjStats?.mainPolicyHolder?.percentage

    applicationReducerData.main_driver.completion =
        insurance_application_completion_stats?.cardsObjStats?.mainPolicyHolder?.percentage

    applicationReducerData.main_driver.basic_details_stats =
        insurance_application_completion_stats?.mainPolicyHolder?.basic_details_completion_percentage

    applicationReducerData.main_driver.licence_address_stats =
        insurance_application_completion_stats?.mainPolicyHolder?.license_and_addresses_completion_percentage

    applicationReducerData.main_driver.additional_details_stats =
        insurance_application_completion_stats?.mainPolicyHolder?.additional_details_completion_percentage

    applicationReducerData.main_driver.track_record_stats =
        insurance_application_completion_stats?.mainPolicyHolder?.track_record_completion_percentage

    Object.keys(applicationReducerData.other_drivers).forEach((key) => {
        if (applicationReducerData.other_drivers[key]) {
            let other_driver_stats =
                insurance_application_completion_stats.driversCompletionObj[key]

            applicationReducerData.other_drivers[key].completion =
                other_driver_stats?.overall_completion_percentage
            applicationReducerData.other_drivers[key].basic_details_stats =
                other_driver_stats?.basic_info_completion_percentage
            applicationReducerData.other_drivers[key].licence_address_stats =
                other_driver_stats?.license_completion_percentage
            applicationReducerData.other_drivers[key].additional_details_stats =
                other_driver_stats?.additional_details_completion_percentage
            applicationReducerData.other_drivers[key].track_record_stats =
                other_driver_stats?.track_record_completion_percentage
        }
    })

    applicationReducerData.vehicles_to_insure_ids_list.forEach((key) => {
        if (applicationReducerData.vehicles[key]) {
            let insured_vehicle_stats =
                insurance_application_completion_stats.insuredVehiclesObj[key]

            applicationReducerData.vehicles[key].completion =
                insured_vehicle_stats?.overall_completion_percentage
            applicationReducerData.vehicles[key].tech_info_stats =
                insured_vehicle_stats?.tech_info_overall_completion_percentage
            applicationReducerData.vehicles[key].mileage_parking_stats =
                insured_vehicle_stats?.car_mileage_and_parking_overall_completion_percentage
            applicationReducerData.vehicles[key].ownership_stats =
                insured_vehicle_stats?.ownership_overall_completion_percentage
        }
    })

    let get_non_insured_everyday_cars_ids = () => {
        if (applicationReducerData.external_car_ids) {
            if (applicationReducerData.everyday_car_ids) {
                let everyday_not_insured_garage_cars =
                    applicationReducerData.everyday_car_ids.filter((id) => {
                        return !applicationReducerData.vehicles_to_insure_ids_list.includes(
                            id
                        )
                    })
                return [
                    ...applicationReducerData.external_car_ids,
                    ...everyday_not_insured_garage_cars,
                ]
            } else {
                return [...applicationReducerData.external_car_ids]
            }
        } else {
            if (applicationReducerData.everyday_car_ids) {
                let everyday_not_insured_garage_cars =
                    applicationReducerData.everyday_car_ids.filter((id) => {
                        return (
                            applicationReducerData.vehicles_to_insure_ids_list.includes(
                                id
                            ) === false
                        )
                    })
                return [...everyday_not_insured_garage_cars]
            } else return []
        }
    }

    let non_insured_everyday_cars_ids = get_non_insured_everyday_cars_ids()

    non_insured_everyday_cars_ids.forEach((key) => {
        if (applicationReducerData.vehicles[key]) {
            let non_insured_vehicle_stats =
                insurance_application_completion_stats.everydayVehiclesObj[key]

            applicationReducerData.vehicles[key].completion =
                non_insured_vehicle_stats?.overall_completion_percentage
            applicationReducerData.vehicles[key].tech_info_stats =
                non_insured_vehicle_stats?.tech_info_overall_completion_percentage
            applicationReducerData.vehicles[key].mileage_parking_stats =
                non_insured_vehicle_stats?.car_mileage_and_parking_overall_completion_percentage
            applicationReducerData.vehicles[key].ownership_stats =
                non_insured_vehicle_stats?.ownership_overall_completion_percentage
        }
    })

    return applicationReducerData
}

export const ValidateReducerData = (
    reducer: IInsuranceQuoteApplication,
    validationReducerData: IInsuranceQuoteApplicationValidation
) => {
    let applicationReducerData = { ...reducer }

    applicationReducerData.insurance_details_errors =
        validationReducerData?.insurance_details_errors
            ? {
                  ...validationReducerData.insurance_details_errors,
              }
            : {}

    applicationReducerData.main_driver = {
        ...applicationReducerData?.main_driver,
        basic_details_errors:
            validationReducerData?.main_driver?.basic_details_errors,
        licence_address_errors:
            validationReducerData?.main_driver?.licence_address_errors,
        additional_details_errors:
            validationReducerData?.main_driver?.additional_details_errors,
        track_record_errors:
            validationReducerData?.main_driver?.track_record_errors,
        entities: {
            ...applicationReducerData?.main_driver?.entities,
        },
        entities_error: validationReducerData?.main_driver?.entities_error && {
            ...validationReducerData?.main_driver?.entities_error,
        },
    }

    if (Object.keys(applicationReducerData.other_drivers).length > 0) {
        Object.keys(applicationReducerData.other_drivers)?.forEach((key) => {
            applicationReducerData.other_drivers[key] = {
                ...applicationReducerData.other_drivers[key],
                additional_details_errors:
                    validationReducerData.other_drivers &&
                    validationReducerData.other_drivers[key]
                        ?.additional_details_errors,
                basic_details_errors:
                    validationReducerData.other_drivers &&
                    validationReducerData.other_drivers[key]
                        ?.basic_details_errors,
                licence_address_errors:
                    validationReducerData.other_drivers &&
                    validationReducerData.other_drivers[key]
                        ?.licence_address_errors,
                track_record_errors:
                    validationReducerData.other_drivers &&
                    validationReducerData.other_drivers[key]
                        ?.track_record_errors,

                drive_list_errors:
                    validationReducerData.other_drivers &&
                    validationReducerData.other_drivers[key]?.drive_list_errors,

                entities_error: validationReducerData?.other_drivers &&
                    validationReducerData?.other_drivers[key]
                        ?.entities_error && {
                        ...validationReducerData.other_drivers[key]
                            ?.entities_error,
                    },
            }
        })
    }

    if (Object.keys(applicationReducerData.vehicles).length > 0) {
        Object.keys(applicationReducerData.vehicles).forEach((key) => {
            applicationReducerData.vehicles[key] = {
                ...applicationReducerData.vehicles[key],
                mileage_parking_errors:
                    validationReducerData.vehicles &&
                    validationReducerData.vehicles[key]?.mileage_parking_errors,
                daytime_parking_info_errors:
                    validationReducerData.vehicles &&
                    validationReducerData.vehicles[key]
                        ?.daytime_parking_info_errors,
                eveningtime_parking_info_errors:
                    validationReducerData.vehicles &&
                    validationReducerData.vehicles[key]
                        ?.eveningtime_parking_info_errors,
                tech_info_errors:
                    validationReducerData.vehicles &&
                    validationReducerData.vehicles[key]?.tech_info_errors,
                ownership_errors:
                    validationReducerData.vehicles &&
                    validationReducerData.vehicles[key]?.ownership_errors,

                entities_error: validationReducerData.vehicles &&
                    validationReducerData.vehicles[key]?.entities_error && {
                        ...validationReducerData.vehicles[key]?.entities_error,
                    },
            }
        })
    }

    return applicationReducerData
}

export const addFullStatsToNonDraftApplications = (
    reducer: IInsuranceQuoteApplication
) => {
    let applicationReducerData = { ...reducer }

    applicationReducerData.insurance_details_stats = 100
    applicationReducerData.overall_completion = 100
    applicationReducerData.main_driver_overall_stats = 100
    applicationReducerData.vehicles_overall_stats = 100
    applicationReducerData.everyday_car_stats = 100
    applicationReducerData.other_drivers_overall_stats = 100

    applicationReducerData.main_driver = {
        ...applicationReducerData?.main_driver,
        basic_details_stats: 100,
        licence_address_stats: 100,
        additional_details_stats: 100,
        track_record_stats: 100,
        completion: 100,
    }

    if (Object.keys(applicationReducerData.other_drivers).length > 0) {
        Object.keys(applicationReducerData.other_drivers)?.forEach((key) => {
            applicationReducerData.other_drivers[key] = {
                ...applicationReducerData.other_drivers[key],
                additional_details_stats: 100,
                basic_details_stats: 100,
                licence_address_stats: 100,
                track_record_stats: 100,
                completion: 100,
            }
        })
    }

    if (Object.keys(applicationReducerData.vehicles).length > 0) {
        Object.keys(applicationReducerData.vehicles).forEach((key) => {
            applicationReducerData.vehicles[key] = {
                ...applicationReducerData.vehicles[key],
                mileage_parking_stats: 100,
                tech_info_stats: 100,
                ownership_stats: 100,
                completion: 100,
            }
        })
    }

    return applicationReducerData
}
