import { motion } from 'framer-motion'
import styled from 'styled-components'

const Fab = styled(motion.div)<StyleProps>`
    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    transition: all 500ms;
    margin-top: 20px;
`

const Round = styled.div`
    background-color: #5ec3ca;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
`

type Props = {
    isOpen: boolean
    onClick: any
}

type StyleProps = {
    isOpen: boolean
}

const FabIcon = ({ isOpen, onClick }: Props) => (
    <Fab whileTap={{ scale: 1.1 }} isOpen={isOpen} onClick={onClick}>
        <Round
            style={{
                width: '56px',
                height: '56px',
            }}
        >
            <svg
                width="18"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="4" width="16" height="2.4" rx="1.2" fill="#FFFFFF" />
                <rect y="6" width="20" height="2.4" rx="1.2" fill="#FFFFFF" />
                <rect
                    x="6"
                    y="12"
                    width="14"
                    height="2.4"
                    rx="1.2"
                    fill="#FFFFFF"
                />
            </svg>
        </Round>
    </Fab>
)

export default FabIcon
