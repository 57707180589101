import {
    IDropdownItem,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import { ITimelineEditForms } from 'myModels'
import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";

import { connect } from 'react-redux'
import { ICostItem, ITimelineItem } from 'timelineModels'

import { RootState } from 'typesafe-actions'

import TimelineGenerateEditFormModeMobile from '../timelineFormGenerators/timelineGenerateEditFormModeMobile'

function mapStateToProps(state: RootState) {
    return {}
}

// const dispatchProps = {}

export interface Props {
    item: ITimelineItem | ICostItem | null
    timelineItemEditForm: ITimelineEditForms
    labels_searchable_list?: IDropdownItem[] | null | undefined
    savingEdit: any
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    onDeleteClick?: () => void
}

type State = {
    edited_item: ITimelineItem | ICostItem | null
    carMileage: {
        sendToBe: boolean
        payload: IwriteTechnicalInformationPayloadReq | null
    }
}

class TimelineEditManagerMobile extends React.Component<Props, State> {
    state = {
        edited_item: null,
        carMileage: {
            sendToBe: false,
            payload: null,
        },
    }

    editNewItem = (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => {
        let updated_item: ITimelineItem | ICostItem | null =
            this.state.edited_item !== null
                ? Object.assign({}, this.state.edited_item)
                : Object.assign({}, this.props.item)

        if (updated_item !== null) {
            if (this.props.entityType === 'cost' && fieldID === 'attachment') {
                if (newValue === null) {
                    updated_item.attachmentID = undefined
                } else {
                    updated_item.attachmentID = newValue.id
                    updated_item.attachment = newValue
                }
            } else {
                updated_item[fieldID] = newValue
            }

            this.setState({ edited_item: updated_item })
        }
    }

    componentDidMount() {
        this.setState({ edited_item: this.props.item })
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.item !== this.props.item) {
            this.setState({ edited_item: this.props.item })
        }
    }

    componentWillUnmount() {
        this.setState({ edited_item: null })
    }

    render() {
        let { edited_item, carMileage } = this.state
        let {
            item,
            timelineItemEditForm,
            labels_searchable_list,
            savingEdit,
            closeForm,
            dataCyId,
            entityID,
            carID,
            entityType,
            onDeleteClick,
        } = this.props

        const updateCarMileage = (payload: {
            updateCarMileage: boolean
            value: number
            user_distance_unit: string
        }) => {
            let payloadFoBe: IwriteTechnicalInformationPayloadReq = {
                tech_info_id: `hf-${carID}`,
                car_id: carID,
                data: {
                    id: 'mileage',
                    text: 'mileage',
                    answer: payload.value,
                },
            }

            this.setState({
                carMileage: {
                    ...this.state.carMileage,
                    sendToBe: payload.updateCarMileage,
                    payload: payloadFoBe,
                },
            })
        }

        return (
            <>
                {item && timelineItemEditForm.id && (
                    <TimelineGenerateEditFormModeMobile
                        item={item}
                        timelineItemEditForm={timelineItemEditForm}
                        edited_item={edited_item}
                        onChange={this.editNewItem}
                        onClickSave={() =>
                            savingEdit(this.state.edited_item, carMileage)
                        }
                        closeForm={closeForm}
                        dataCyId={dataCyId}
                        labels_searchable_list={labels_searchable_list}
                        entityID={entityID}
                        carID={carID}
                        entityType={entityType}
                        onDeleteClick={onDeleteClick}
                        updateCarMileage={updateCarMileage}
                    />
                )}

                <div />
            </>
        )
    }
}

export default connect(mapStateToProps, undefined)(TimelineEditManagerMobile)

// SelectSearchBarCarInfoDesktop
