import * as React from 'react'
import styled from 'styled-components'
import close_cross_blue from '../../../public/assets/icons/close_cross_blue.svg'

const ReadMoreText = styled.div`
    color: #5ec3ca;
    font-family: Lato-Light;
    padding-top: 8px;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const ExpandIcon = styled.img`
    margin-right: 9px;
    transform: rotate(45deg) translateY(-1px);
    opacity: 0.5;
    height: 9px;
`

type Props = {
    onClick: any
    isExpanded: boolean
}

class ShowMoreExpandToggleMobile extends React.Component<Props, {}> {
    render() {
        let { onClick, isExpanded } = this.props
        return (
            <ReadMoreText
                onClick={() => {
                    onClick()
                }}
            >
                <ExpandIcon src={close_cross_blue} alt="expand" />
                {isExpanded ? 'Show Less' : 'Expand'}
            </ReadMoreText>
        )
    }
}

export default ShowMoreExpandToggleMobile
