import { motion } from 'framer-motion'

type Props = {
    onClick: any
    dataCyId?: string
    width?: string
    height?: string
    colour?: string
}

const AddIcon = (props: Props) => (
    <motion.div
        whileTap={{ scale: 1.05 }}
        onClick={props.onClick}
        data-attr={props.dataCyId && props.dataCyId}
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <svg
            width={props.width ?? '14'}
            height={props.height ?? '14'}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.31134e-07 6.25L14 6.25L14 7.75L0 7.75L1.31134e-07 6.25Z"
                fill={props.colour ?? '#5EC3CA'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.25 14L6.25 0L7.75 0L7.75 14H6.25Z"
                fill={props.colour ?? '#5EC3CA'}
            />
        </svg>
    </motion.div>
)

export default AddIcon
