import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import '../styles.css'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const formatsList = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    // 'indent',
    // 'link',
]

const modulesList = (id: string) => {
    return {
        toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                // { indent: '-1' },
                // { indent: '+1' },
            ],
            // ['link'],
            // [{ container: "none" }],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }
}

// const CustomToolbar = (id: any) => {
//   return <CutomToolBar id={id} />;
// };

type Props = {
    placeholder: string
    valuePassed: string
    handleChange: any
    idPassed: string
    readOnly: boolean
    height: string
}

export default function DescriptionEditorMobile(props: Props) {
    const {
        placeholder,
        valuePassed,
        handleChange,
        idPassed,
        readOnly,
        height,
    } = props

    let idOfToolbar = readOnly
        ? 'toolbarHiddenMobileDescription'
        : 'toolbarVisibleMobileDescription'

    const { theme } = useThemes()

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: colours[theme].background_default,
            }}
        >
            {/* <CustomToolbar id="" /> */}

            <ReactQuill
                theme="snow"
                onChange={handleChange}
                value={valuePassed}
                id={idPassed}
                modules={modulesList(idOfToolbar)}
                formats={formatsList}
                bounds={'.app'}
                placeholder={placeholder}
                style={{
                    height: height,
                    fontFamily: 'Lato',
                    width: '100%',
                }}
                // readOnly={readOnly}
            />
        </div>
    )
}
