import { IApexHomepageSlideState } from 'apexModels'
import * as React from 'react'
import styled from 'styled-components'

import { device } from '../../../templates/displays/devices'
import './styles.css'

type Props = {
    slide: IApexHomepageSlideState
}

const SlideStyle = styled.div`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-direction: column;
    width: 100%;

    display: flex;
    @media ${device.beyond_ipad_mobile} {
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        text-align: left;
    }

    @media screen and (max-width: 1400px) {
        min-height: 400px;
        max-height: 400px;
    }

    @media screen and (min-width: 1400px) {
        min-height: 500px;
        max-height: 500px;
    }

    @media screen and (min-width: 1600px) {
        min-height: 600px;
        max-height: 600px;
        width: 100%;
        border-radius: 10px;
    }
`

type IOverlayProps = {
    position: 'top_right' | 'top_left' | 'bottom_left' | 'bottom_right'
}

const SlideStyleOverlay = styled.div<IOverlayProps>`
    background: ${(props) =>
        props.position === 'top_right'
            ? 'linear-gradient(261.81deg,rgba(0, 0, 0, 0.54) 9.2%,rgba(0, 0, 0, 0) 59.6%);'
            : props.position === 'bottom_left'
            ? 'linear-gradient(49.84deg, rgba(0, 0, 0, 0.54) 10.33%, rgba(0, 0, 0, 0) 58.53%);'
            : ''};

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const Text = styled.div<IOverlayProps>`
    position: absolute;
    top: ${(props) =>
        props.position === 'top_right'
            ? '40px'
            : props.position === 'bottom_left'
            ? 'auto'
            : ''};

    bottom: ${(props) =>
        props.position === 'top_right'
            ? 'auto'
            : props.position === 'bottom_left'
            ? '40px'
            : ''};
    right: ${(props) =>
        props.position === 'top_right'
            ? '90px'
            : props.position === 'bottom_left'
            ? 'auto'
            : ''};

    left: ${(props) =>
        props.position === 'top_right'
            ? 'auto'
            : props.position === 'bottom_left'
            ? '50px'
            : ''};

    text-align: ${(props) =>
        props.position === 'top_right'
            ? 'right'
            : props.position === 'bottom_left'
            ? 'left'
            : ''};
`

const Title = styled.div<IOverlayProps>`
    text-align: ${(props) =>
        props.position === 'top_right'
            ? 'right'
            : props.position === 'bottom_left'
            ? 'left'
            : ''};

    text-transform: uppercase;

    color: white;

    @media ${device.beyond_ipad_mobile} {
        font-size: 1.8rem;
        font-family: Lato-Bold;
        max-width: 600px;
    }

    @media ${device.large_desktop} {
        font-size: 2.5rem;
        font-family: Lato-Bold;
        max-width: 800px;
    }
`

const Subtext = styled.div<IOverlayProps>`
    padding-left: ${(props) =>
        props.position === 'top_right'
            ? '60px'
            : props.position === 'bottom_left'
            ? 'auto'
            : ''};

    text-align: ${(props) =>
        props.position === 'top_right'
            ? 'right'
            : props.position === 'bottom_left'
            ? 'left'
            : ''};

    font-family: Lato-Bold;
    font-size: 1rem;
    color: white;

    @media ${device.beyond_ipad_mobile} {
        max-width: 600px;
    }
    @media ${device.large_desktop} {
        font-size: 1.2rem;
        max-width: 400px;
    }

    @media ${device.smallest_laptops} {
        padding-left: ${(props) =>
            props.position === 'top_right'
                ? '350px'
                : props.position === 'bottom_left'
                ? 'auto'
                : ''};
        padding-right: ${(props) =>
            props.position === 'top_right'
                ? 'auto'
                : props.position === 'bottom_left'
                ? '350px'
                : ''};
    }
`

const Padding = styled.div`
    padding-left: 5vw;
`

class SlideTheme2Desktop extends React.Component<Props> {
    render() {
        const { slide } = this.props

        return (
            <SlideStyle
                role="img"
                aria-label="fullwidth-picture"
                style={{
                    backgroundImage: `url(${slide.img ? slide.img.url : ''})`,
                }}
            >
                <SlideStyleOverlay
                    position={
                        slide.text_position ? slide.text_position : 'top_right'
                    }
                />
                <Padding />
                <Text
                    position={
                        slide.text_position ? slide.text_position : 'top_right'
                    }
                >
                    <Title
                        position={
                            slide.text_position
                                ? slide.text_position
                                : 'top_right'
                        }
                    >
                        {slide.title ? slide.title : ''}
                    </Title>
                    <div style={{ paddingTop: '10px' }} />
                    <Subtext
                        position={
                            slide.text_position
                                ? slide.text_position
                                : 'top_right'
                        }
                    >
                        {slide.blurb
                            ? slide.blurb
                            : slide.quote
                            ? slide.quote
                            : ''}
                    </Subtext>
                </Text>
            </SlideStyle>
        )
    }
}
export default SlideTheme2Desktop
