import styled from 'styled-components'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import { motion } from 'framer-motion'
import { ITimelineEntryTypes } from 'timelineModels'
import { history_file_colours } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { renderCategoryIcon } from '../../../atoms/icons/components/historyFile/categories'

const WrapperDesktop = styled.section`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`

const Title = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    text-transform: capitalize;
`

const Description = styled.div`
    font-family: Lato;
    color: var(--text-muted);
    font-size: 14px;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    justify-self: start;
    width: 55vw;
    max-width: 240px;
`

const IconContainer = styled.div<IStyle>`
    width: 50px;
    height: 50px;
    justify-self: start;
    margin-right: 20px;
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : 'var(--primary, #5ec3ca)'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

type IStyle = {
    isactive?: boolean
    $bgColor?: string
}

const Option = styled(motion.div)<IStyle>`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid var(--primary_30);
    border-radius: 10px;
    cursor: pointer;
    background-color: ${(props) =>
        props.isactive ? 'var(--primary_08)' : 'transparent'};
`

const OptionWrapper = styled.div`
    padding-right: 20px;
    padding-bottom: 20px;
    width: 50%;
    max-width: 375px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Props {
    data: IEditOrCreateModeSingleFieldsProps
    optionsData: any
    optionsIDArr: string[]
    dataCyId?: string
    selectedOption?: string | undefined
    onOptionClick?: any
}
const OptionsWithIconAndDescriptionDesktop = (props: Props) => {
    const { data, dataCyId, optionsData, optionsIDArr, onOptionClick } = props

    const submitOption = (name: any) => {
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                name,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, name)
        }
    }

    let renderOptions = () => {
        return optionsIDArr.map((optionID: string, i: number) => {
            if (optionsData[optionID]) {
                return optionsUI(optionsData[optionID], i)
            } else
                return (
                    <div
                        style={{ display: 'none' }}
                        key={`option_${i}_none_desktop`}
                    />
                )
        })
    }

    const { theme } = useThemes()

    let optionsUI = (option: any, i: number) => {
        return (
            <OptionWrapper key={`option_${option.name}_${i}_desktop`}>
                <Option
                    isactive={props.selectedOption === option.name}
                    onClick={(e: any) => {
                        submitOption(option.name)
                        setTimeout(() => {
                            e.preventDefault()
                            onOptionClick && onOptionClick()
                        }, 50)
                    }}
                    data-attr={`option_${i + 1}`}
                >
                    <IconContainer
                        $bgColor={
                            history_file_colours[theme].categories[
                                option.id as ITimelineEntryTypes
                            ].primary_100
                        }
                    >
                        {renderCategoryIcon(
                            option.id as ITimelineEntryTypes,
                            '18'
                        )}
                    </IconContainer>
                    <TextContainer>
                        <Title>{option.name}</Title>
                        <Description>{option.description}</Description>
                    </TextContainer>
                </Option>
            </OptionWrapper>
        )
    }

    return (
        <WrapperDesktop data-attr={dataCyId}>{renderOptions()}</WrapperDesktop>
    )
}
export default connect(
    mapStateToProps,
    dispatchProps
)(OptionsWithIconAndDescriptionDesktop)
