import * as React from 'react'
import styled from 'styled-components'
import QrCodeSVG from '../../atoms/qrCodeSvg/QrCodeSvg'
import QrCodeStickerThemeBtn from '../../atoms/Button/qrCodeStickerThemeBtn'
import { useReducer, useRef, useState } from 'react'
import QrCodeActionBtnWrapper from '../../atoms/Button/qrCodeActionBtnWrapper'
import { ChromePicker } from 'react-color'
import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import { device } from '../../templates/displays/devices'
import {
    ChangeStickerTheme,
    changeStickerThemeReducer,
    generateCustomCodeFile,
    IStickerTheme,
} from './fns'
import { useOnClickOutside } from '../../templates/clickOutside'
import posthog from 'posthog-js'

type StyledProps = {
    $height?: string
    $width?: string
    $iconSize?: string
}

const OverWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 80px;
    width: 100%;
    max-width: 500px;
`

const SVGStickerWrapper = styled.div`
    width: 260px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--bg-color, #fff);
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.05);
`

type ISvgColours = {
    $colour: string
}
const SVGColouredCircle = styled.div<ISvgColours>`
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--border_muted_subtle);
    background-color: ${(props) => (props.$colour ? props.$colour : '#ffe5fb')};
`
const SVGBackgroundWrapper = styled.div`
    width: 205px;
    height: 205px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--bg-color, #fff);
`
const SVGBackground = styled.div<ISvgColours>`
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => (props.$colour ? props.$colour : '#fcf8ec')};
`

const SectionTitleWrapper = styled.div<StyledProps>`
    display: flex;
    width: 100%;
    align-items: center;
`
const HorizontalLine = styled.div<StyledProps>`
    width: 100%;
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
`

const SectionTitle = styled.div<StyledProps>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 16px;
    padding-right: 20px;
    line-height: 20px;
`
const PaddingTop20 = styled.div<StyledProps>`
    padding-top: 20px;
`
const FixedWrapper = styled.div<StyledProps>`
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    @media ${device.ipad} {
        right: 50px;
        bottom: 100px;
    }
`

type Props = {
    qr_code_link: string
    qr_code_id: string
    isBottomSheetOpen: boolean
    closeBottomSheet: any
    predefinedStickerThemes: IStickerTheme[]
    title: string
}

const QrCodeStickerBottomSheetMobile: React.FunctionComponent<Props> = (
    props
) => {
    let {
        qr_code_id,
        qr_code_link,
        predefinedStickerThemes,
        isBottomSheetOpen,
        closeBottomSheet,
        title,
    } = props

    const [state, dispatch] = useReducer(changeStickerThemeReducer, {
        colours: {
            colour_one: predefinedStickerThemes[0].colour_one,
            colour_two: predefinedStickerThemes[0].colour_two,
        },
        openColourPicker: undefined,
    })

    const [activeThemeIndex, setActiveThemeIndex] = useState<number | null>(0)

    const [count, setCount] = useState<number>(0)

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => {
        dispatch({
            type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
            payload: {
                ...state,
                openColourPicker: undefined,
            },
        })
    })

    let svg_id: string = `preview_${qr_code_id}_custom_mobile_${count}`

    return (
        <BottomSheetPage isOpen={isBottomSheetOpen}>
            <PrivateShareReturnNav
                chevron
                onChevronClick={closeBottomSheet}
                customText={`Custom QR Code Sticker`}
            />
            <div style={{ paddingTop: '60px' }} />
            <OverWrapper>
                <Wrapper>
                    <SVGStickerWrapper>
                        <SVGColouredCircle $colour={state.colours.colour_one}>
                            <SVGBackgroundWrapper>
                                <SVGBackground
                                    $colour={state.colours.colour_two}
                                >
                                    <QrCodeSVG
                                        bgColor="transparent"
                                        link={qr_code_link}
                                        size={130}
                                        withLogo
                                        id={svg_id}
                                        logoSize={32}
                                    />
                                </SVGBackground>
                            </SVGBackgroundWrapper>
                        </SVGColouredCircle>
                    </SVGStickerWrapper>

                    <PaddingTop20 />

                    <div style={{ position: 'relative', width: '100%' }}>
                        <QrCodeStickerThemeBtn
                            colourOne={state.colours.colour_one}
                            txt={'Outer Ring Colour'}
                            isNotTheme={true}
                            hasEditIcon
                            height="60px"
                            width="100%"
                            onClick={() =>
                                dispatch({
                                    type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                    payload: {
                                        ...state,
                                        openColourPicker: 'one',
                                    },
                                })
                            }
                        />
                        {state.openColourPicker === 'one' && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '70px',
                                    zIndex: 1,
                                }}
                                ref={ref}
                            >
                                <ChromePicker
                                    color={
                                        state.openColourPicker === 'one'
                                            ? state.colours.colour_one
                                            : undefined
                                    }
                                    disableAlpha
                                    onChange={(colour: any) => {
                                        setActiveThemeIndex(null)
                                        dispatch({
                                            type: ChangeStickerTheme.CHANGE_COLOUR_ONE,
                                            payload: {
                                                colours: {
                                                    ...state.colours,
                                                    colour_one: colour.hex,
                                                },
                                            },
                                        })
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <QrCodeStickerThemeBtn
                            colourOne={state.colours.colour_two}
                            txt={'background colour'}
                            height="60px"
                            width="100%"
                            isNotTheme={true}
                            hasEditIcon
                            onClick={() =>
                                dispatch({
                                    type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                    payload: {
                                        ...state,
                                        openColourPicker: 'two',
                                    },
                                })
                            }
                        />
                        {state.openColourPicker === 'two' && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '70px',
                                    zIndex: 1,
                                }}
                                ref={ref}
                            >
                                <ChromePicker
                                    color={
                                        state.openColourPicker === 'two'
                                            ? state.colours.colour_two
                                            : undefined
                                    }
                                    disableAlpha
                                    onChange={(colour: any) => {
                                        setActiveThemeIndex(null)
                                        dispatch({
                                            type: ChangeStickerTheme.CHANGE_COLOUR_TWO,
                                            payload: {
                                                colours: {
                                                    ...state.colours,
                                                    colour_two: colour.hex,
                                                },
                                            },
                                        })
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <PaddingTop20 />

                    {activeThemeIndex === null && (
                        <div
                            style={{
                                fontSize: 12,
                                color: 'rgb(63 63 63)',
                                fontFamily: 'Lato',
                                fontStyle: 'italic',
                            }}
                        >
                            <div>Note:</div> Please avoid dark colours for the
                            background as it will make the QR code unscannable.
                        </div>
                    )}

                    <PaddingTop20 />

                    <PaddingTop20 />
                    <SectionTitleWrapper>
                        <SectionTitle>Themes</SectionTitle>
                        <HorizontalLine />
                    </SectionTitleWrapper>

                    <PaddingTop20 />
                    <PaddingTop20 />
                    {predefinedStickerThemes &&
                        predefinedStickerThemes.map((theme, index) => (
                            <React.Fragment key={`qr_${theme}_${index}_mobile`}>
                                <QrCodeStickerThemeBtn
                                    key={index}
                                    index={index}
                                    colourOne={theme.colour_one}
                                    colourTwo={theme.colour_two}
                                    txt={
                                        theme.name
                                            ? theme.name
                                            : 'UnSectiontitled'
                                    }
                                    isActiveBtn={
                                        activeThemeIndex === index
                                            ? true
                                            : false
                                    }
                                    height="60px"
                                    width="100%"
                                    onClick={() => {
                                        setActiveThemeIndex(index)
                                        dispatch({
                                            type: ChangeStickerTheme.CHANGE_BOTH_COLOURS,
                                            payload: {
                                                colours: {
                                                    colour_one:
                                                        theme.colour_one,
                                                    colour_two:
                                                        theme.colour_two,
                                                },
                                            },
                                        })
                                    }}
                                />
                                <PaddingTop20 />
                            </React.Fragment>
                        ))}

                    {state.openColourPicker === undefined && (
                        <FixedWrapper>
                            <QrCodeActionBtnWrapper
                                onClick={() => {
                                    generateCustomCodeFile({
                                        colour_background:
                                            state.colours.colour_two,
                                        colour_circle: state.colours.colour_one,
                                        svg_qr_code_id: svg_id,
                                        title: title,
                                        format: 'PNG',
                                        device: 'mobile',
                                    })
                                    setCount(count + 1)

                                    // capture qr_code custom PNG image generation/download
                                    posthog.capture(
                                        'custom_qr_code_png_img_download_btn clicked'
                                    )
                                    return
                                }}
                                variant={'image'}
                                isFlexColumn={true}
                                height="50px"
                                width="120px"
                                dataCyId="download_customised_qr_code_png_mobile"
                            >
                                Image
                            </QrCodeActionBtnWrapper>

                            <div style={{ paddingTop: '10px' }} />

                            <QrCodeActionBtnWrapper
                                onClick={() => {
                                    generateCustomCodeFile({
                                        colour_background:
                                            state.colours.colour_two,
                                        colour_circle: state.colours.colour_one,
                                        svg_qr_code_id: svg_id,
                                        title: title,
                                        format: 'PDF',
                                        device: 'mobile',
                                    })
                                    setCount(count + 1)

                                    // capture qr_code custom PDF doc generation/download
                                    posthog.capture(
                                        'custom_qr_code_pdf_doc_download_btn clicked'
                                    )
                                    return
                                }}
                                isFlexColumn={true}
                                variant={'pdf'}
                                height="50px"
                                width="120px"
                                dataCyId="download_customised_qr_code_pdf_mobile"
                            >
                                A4 PDF
                            </QrCodeActionBtnWrapper>
                        </FixedWrapper>
                    )}
                </Wrapper>
            </OverWrapper>
            <div style={{ paddingTop: '60px' }} />
        </BottomSheetPage>
    )
}

export default QrCodeStickerBottomSheetMobile
