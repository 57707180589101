import { useHistory } from 'react-router'

import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import InsuranceOverviewCard from '../../molecules/cards/insurance/insuranceOverviewCard'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { MenuAdjust } from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import { device } from '../../templates/displays/devices'

import {
    IApplicationInfoObj,
    insuranceActions,
} from '../../../redux/insuranceQuoteApplication/reducer'

import { useEffect, useState } from 'react'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { IRootState } from '../../../redux/store'
import { getGarageByUserIdRequest } from '../../../redux/entities/garages/actions/loadingActions'
// import CircleLoader from '../../atoms/loader/circleLoader'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import FullWidthInfoBox from '../../atoms/info/fullWidthinfoBox'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import Expander from '../../atoms/expander/expander'
import JourneyPatternCardMobile from '../../molecules/cards/journey/patternCards/patternCardMobile'
import FadedSlower from '../../templates/animated/FadedSlower'

const Wrapper = styled.div`
    padding: 40px;
    padding-bottom: 160px;
    @media (min-width: 881px) and (max-width: 1024px) {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 140px;
    }
`

const PageTitle = styled.h1`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 32px;
    line-height: 48px; /* 150% */

    @media ${device.mobile_and_ipad} {
        font-size: 22px;
    }
`

const PageSubTitle = styled.h2`
    padding: 0;
    margin: 0;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
    padding-bottom: 16px;
`

const FlexRowSpaceBet = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`

const BtnText = styled.p`
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    /* font-weight: 600; */
    font-family: Lato-Semibold;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const Placeholder = styled.div`
    display: flex;
    width: 100%;
    padding: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--border-muted, #e5e5e5);
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
    text-align: center;
`
const AdjustedPadding = styled.div`
    padding-top: 64px;
    @media ${device.mobile_and_ipad} {
        padding-top: 50px;
    }
`

const PhoneBannerText = styled.p`
    color: var(--text-darker, #616161);
    font-size: 14px;
    font-family: 'Lato';
    padding-top: 2px;
    line-height: 20px;
    @media ${device.mobile} {
        max-width: 80%;
    }
`

const InsuranceOverview = () => {
    const history = useHistory()

    const dispatch = useAppDispatch()

    let applications_info: IApplicationInfoObj | undefined = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.applications_info
    )

    let isAllApplicationsInfoLoading = useAppSelector(
        (state: IRootState) =>
            state.insuranceQuoteApplication.isAllApplicationsInfoLoading
    )

    let isLoading = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    let carsData = useAppSelector((state) => state.entities.carsData.cars)

    let garageData = useAppSelector(
        (state) => state.entities.garagesData.garages
    )

    let showValidationSheet = useAppSelector((state) => {
        return state.insuranceQuoteApplication.showValidationSheet
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        if (showValidationSheet === true) {
            dispatch(insuranceActions.show_Validation_sheet(false))
        }
    }, [])

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        } else if (userLoggedIn) {
            if (
                userLoggedIn &&
                userLoggedIn.owns_garage?.uid &&
                !garageData[userLoggedIn.owns_garage?.uid]
            ) {
                dispatch(getGarageByUserIdRequest())
            }
            if (isLoading === false) {
                dispatch(
                    insuranceActions.get_user_insurance_applications_request({
                        withAllInfo: true,
                        withNavigation: false,
                    })
                )
            }
            // dispatch(insuranceActions.get_user_insurance_applications_request({}))
        }
    }, [userLoggedIn])

    let application_draft_ids = useAppSelector(
        (state) => state.insuranceQuoteApplication.applications_draft_ids
    )

    let applications_opened_ids = useAppSelector(
        (state) => state.insuranceQuoteApplication.applications_opened_ids
    )

    let applications_completed_ids = useAppSelector(
        (state) => state.insuranceQuoteApplication.applications_complete_ids
    )

    let isMainMenuCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )

    const [showJourneyCard, setShowJourneyCard] = useState(true)

    const hideJourneyCard = () => setShowJourneyCard(false)

    return (
        <>
            <AdjustedPadding />
            <MenuAdjust fullwidth isCollapsed={isMainMenuCollapsed}>
                <FullWidthInfoBox
                    children={
                        <PhoneBannerText>
                            We will call you on <strong>020 3545 4088</strong>{' '}
                            if we need to contact you about your application.
                            Our emails can sometimes end up in spam. If you are
                            expecting documentation from us, but haven't yet
                            received it, please check your spam prior to
                            reaching out to us.
                        </PhoneBannerText>
                    }
                    variant="info"
                    customBg="var(--background_primary_soft)"
                />
            </MenuAdjust>
            <CenteredPageWrapper removePaddingsOnAllDevices>
                <DesktopDisplayOnly>
                    <Wrapper>
                        <FlexRowSpaceBet>
                            <PageTitle>Overview</PageTitle>
                            <StandardCtaBtn
                                onClick={() => {
                                    history.push('/insurance/new')
                                }}
                                boder="1px solid var(--border-muted, #e5e5e5)"
                                bg="transparent"
                                customHeight="auto"
                            >
                                <BtnText
                                    style={{
                                        color: `var(--text-default, #666666)`,
                                    }}
                                >
                                    Get a Quote
                                </BtnText>
                            </StandardCtaBtn>
                        </FlexRowSpaceBet>
                        <div style={{ padding: 32 }} />

                        <PageSubTitle>Requires your Attention</PageSubTitle>
                        {/* map through incomplete applications */}

                        {isAllApplicationsInfoLoading || isLoading ? (
                            <div
                                style={{
                                    width: '100%',
                                    height: 96,
                                    position: 'relative',
                                }}
                            >
                                <SkeletonAbsolute isthingloading />
                            </div>
                        ) : application_draft_ids &&
                          application_draft_ids.length > 0 ? (
                            applications_info &&
                            application_draft_ids.map(
                                (id: string, index: number) => {
                                    if (
                                        applications_info &&
                                        applications_info[id]
                                    ) {
                                        return (
                                            <InsuranceOverviewCard
                                                carsData={carsData}
                                                variant="DRAFT"
                                                application_info={
                                                    applications_info[id]
                                                }
                                                key={`${id}_${index}`}
                                            />
                                        )
                                    } else return null
                                }
                            )
                        ) : (
                            <Placeholder>
                                Your draft applications will be listed here
                            </Placeholder>
                        )}

                        <div style={{ padding: 40 }} />
                        <PageSubTitle>In progress</PageSubTitle>
                        {/* map through applications in process */}

                        {isAllApplicationsInfoLoading || isLoading ? (
                            <div
                                style={{
                                    width: '100%',
                                    height: 96,
                                    position: 'relative',
                                }}
                            >
                                <SkeletonAbsolute isthingloading />
                            </div>
                        ) : applications_opened_ids &&
                          applications_opened_ids.length > 0 ? (
                            applications_info &&
                            applications_opened_ids.map(
                                (id: string, index: number) => {
                                    if (
                                        applications_info &&
                                        applications_info[id]
                                    ) {
                                        return (
                                            <InsuranceOverviewCard
                                                carsData={carsData}
                                                variant="REQUESTED"
                                                application_info={
                                                    applications_info[id]
                                                }
                                                key={`${id}_${index}`}
                                            />
                                        )
                                    } else return null
                                }
                            )
                        ) : (
                            <Placeholder>
                                Your applications under consideration will be
                                listed here
                            </Placeholder>
                        )}

                        <div style={{ padding: 40 }} />
                        <PageSubTitle>Complete</PageSubTitle>
                        {/* map through applications in process */}

                        {isAllApplicationsInfoLoading || isLoading ? (
                            <div
                                style={{
                                    width: '100%',
                                    height: 96,
                                    position: 'relative',
                                }}
                            >
                                <SkeletonAbsolute isthingloading />
                            </div>
                        ) : applications_completed_ids &&
                          applications_completed_ids.length > 0 ? (
                            applications_info &&
                            applications_completed_ids.map(
                                (id: string, index: number) => {
                                    if (
                                        applications_info &&
                                        applications_info[id]
                                    ) {
                                        return (
                                            <InsuranceOverviewCard
                                                carsData={carsData}
                                                variant="COMPLETE"
                                                application_info={
                                                    applications_info[id]
                                                }
                                                key={`${id}_${index}`}
                                            />
                                        )
                                    } else return null
                                }
                            )
                        ) : (
                            <Placeholder>
                                Your approved applications will be listed here
                            </Placeholder>
                        )}
                    </Wrapper>
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <div
                        style={{
                            padding: 24,
                            paddingBottom: 160,
                        }}
                    >
                        <FlexRowSpaceBet>
                            <PageTitle>Overview</PageTitle>
                            <StandardCtaBtn
                                onClick={() => {
                                    history.push('/insurance/new')
                                }}
                                boder="1px solid var(--border-muted, #e5e5e5)"
                                bg="transparent"
                                customHeight="auto"
                                customWidth="auto"
                            >
                                <BtnText
                                    style={{
                                        color: `var(--text-default, #666666)`,
                                    }}
                                >
                                    Get a Quote
                                </BtnText>
                            </StandardCtaBtn>
                        </FlexRowSpaceBet>
                        <div style={{ padding: 16 }} />
                        <PageSubTitle>Requires your Attention</PageSubTitle>
                        {/* map through incomplete applications */}

                        {isAllApplicationsInfoLoading || isLoading ? (
                            <div
                                style={{
                                    width: '100%',
                                    height: 96,
                                    position: 'relative',
                                }}
                            >
                                <SkeletonAbsolute isthingloading />
                            </div>
                        ) : application_draft_ids &&
                          application_draft_ids.length ? (
                            applications_info &&
                            application_draft_ids.map(
                                (id: string, index: number) => {
                                    if (
                                        applications_info &&
                                        applications_info[id]
                                    ) {
                                        return (
                                            <InsuranceOverviewCard
                                                carsData={carsData}
                                                variant="DRAFT"
                                                application_info={
                                                    applications_info[id]
                                                }
                                                isMobile
                                                key={`${id}_${index}_mobile`}
                                            />
                                        )
                                    } else return null
                                }
                            )
                        ) : (
                            <Placeholder>
                                Your draft applications will be listed here
                            </Placeholder>
                        )}

                        <Expander
                            height={showJourneyCard ? 'auto' : 0}
                            width="100%"
                        >
                            <div
                                style={{
                                    width: '100%',
                                    paddingTop: 32,
                                }}
                            >
                                <FadedSlower>
                                    <JourneyPatternCardMobile
                                        dataPH="start_insurance_journey"
                                        sectionID="insurance"
                                        copyData={journeyRedirectCardsCopy}
                                        onStartNow={() => {
                                            history.push({
                                                pathname: `/journey/insurance`,
                                                search: `?step=welcome`,
                                                state: {
                                                    prevPath: `${
                                                        history.location
                                                            .pathname
                                                    }${
                                                        history.location
                                                            .search ?? ''
                                                    }`,
                                                },
                                            })
                                        }}
                                        onDismiss={() => {
                                            hideJourneyCard()
                                        }}
                                    />
                                </FadedSlower>
                            </div>
                        </Expander>

                        <div style={{ padding: 32 }} />
                        <PageSubTitle>In progress</PageSubTitle>
                        {/* map through applications in process */}

                        {isAllApplicationsInfoLoading || isLoading ? (
                            <div
                                style={{
                                    width: '100%',
                                    height: 96,
                                    position: 'relative',
                                }}
                            >
                                <SkeletonAbsolute isthingloading />
                            </div>
                        ) : applications_opened_ids &&
                          applications_opened_ids.length > 0 ? (
                            applications_info &&
                            applications_opened_ids.map(
                                (id: string, index: number) => {
                                    if (
                                        applications_info &&
                                        applications_info[id]
                                    ) {
                                        return (
                                            <InsuranceOverviewCard
                                                carsData={carsData}
                                                variant={'REQUESTED'}
                                                application_info={
                                                    applications_info[id]
                                                }
                                                key={`${id}_${index}_mobile`}
                                                isMobile
                                            />
                                        )
                                    } else return null
                                }
                            )
                        ) : (
                            <Placeholder>
                                Your applications under consideration will be
                                listed here
                            </Placeholder>
                        )}
                        <div style={{ padding: 32 }} />
                        <PageSubTitle>Complete</PageSubTitle>
                        {isAllApplicationsInfoLoading || isLoading ? (
                            <div
                                style={{
                                    width: '100%',
                                    height: 96,
                                    position: 'relative',
                                }}
                            >
                                <SkeletonAbsolute isthingloading />
                            </div>
                        ) : applications_completed_ids &&
                          applications_completed_ids.length > 0 ? (
                            applications_info &&
                            applications_completed_ids.map(
                                (id: string, index: number) => {
                                    if (
                                        applications_info &&
                                        applications_info[id]
                                    ) {
                                        return (
                                            <InsuranceOverviewCard
                                                carsData={carsData}
                                                variant="COMPLETE"
                                                application_info={
                                                    applications_info[id]
                                                }
                                                key={`${id}_${index}`}
                                                isMobile
                                            />
                                        )
                                    } else return null
                                }
                            )
                        ) : (
                            <Placeholder>
                                Your approved applications will be listed here
                            </Placeholder>
                        )}
                    </div>
                </IpadAndMobileDisplay>
            </CenteredPageWrapper>
        </>
    )
}

export default InsuranceOverview
