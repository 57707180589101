import styled from 'styled-components'
import {
    BannerViewIcon,
    GridViewIcon,
    // banner_viewViewIcon,
} from '../../statefulicons'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
}

const ViewsContainer = styled.section<IStyle>`
    position: relative;
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 48px;
    outline: none;
    background-color: ${(props) =>
        colours[props.$theme].background_primary_switch};
    border-radius: 50px;
    transition: all 200ms;
`

const Circle = styled.div<IStyle>`
    position: absolute;
    z-index: 0;
    width: 70px;
    height: 90%;
    background: ${(props) => colours[props.$theme].background_default};
    box-shadow: 0px 1px 2px
        ${(props) => colours[props.$theme].shadow_strongest_16};
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;

    :hover {
        transition: all 250ms;
    }
`

const BtnWrapper = styled.button`
    all: unset;
    cursor: pointer;
    width: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface Props {
    activeView: string
    toggleMenu: any
}

export default function ViewsMenu(props: Props) {
    const { activeView, toggleMenu } = props
    const { theme } = useThemes()

    return (
        <ViewsContainer $theme={theme}>
            <Circle
                $theme={theme}
                style={{
                    transform:
                        activeView !== 'grid_view'
                            ? 'translateX(68px)'
                            : 'translateX(2px)',
                }}
            />
            <BtnWrapper onClick={() => toggleMenu('grid_view')}>
                <GridViewIcon
                    theme={theme}
                    isActive={activeView === 'grid_view' ? true : false}
                />
            </BtnWrapper>

            <BtnWrapper onClick={(e: any) => toggleMenu('banner_view')}>
                <BannerViewIcon
                    theme={theme}
                    isActive={activeView === 'banner_view' ? true : false}
                />
            </BtnWrapper>
        </ViewsContainer>
    )
}
