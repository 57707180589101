import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
// import TouchScrollable from 'react-scrolllock'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { motion } from 'framer-motion'
import { message } from 'antd'

import {
    getPresignedUrlsCarGalleryImagesRequest,
    submitCarGalleryImagesFilestorageRequest,
} from '../../../../redux/filestorage/actions/dispatched/loadingActions'

import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
// import Expander from "../../../atoms/expander/expander";
// import { ImagePicker } from 'antd-mobile'

import './antd.css'
import {
    IUploadAndCreateCarGalleryImageArgs,
    IUploadGalleryImagesPreSignedUrlsPayload,
    reqPresignedUrImageArgs,
} from 'myModels'
import { useState } from 'react'
import GooglePlacesLocationsDropdown from '../../../molecules/googlePlacesLocationsDropdown/googlePlacesLocationsDropdownDesktop'
import { ModalTopBar } from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'

var ImagePickerFromAntd = require('antd-mobile/lib/image-picker')

type IStyle = {
    $theme: ITheme
}

const EditFormContainerMobile = styled.section<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 30px;
    margin-bottom: 30px;
    /* padding-top: 20px; */
    height: auto;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    color: ${(props) => colours[props.$theme].text_strong};
    border-radius: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 5px;

    @media screen and (max-width: 325px) {
        max-height: 90vh;
    }

    max-height: 95vh;

    @media screen and (min-width: 330px) {
        min-height: 358px;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 15px;
    padding-bottom: 10px;
`

const SmallPadding = styled.div`
    padding-top: 10px;

    @media screen and (max-width: 330px) {
        padding-top: 5px;
    }
`

const SmallPaddingBetweenFields = styled.div`
    padding-top: 5px;
`

const BiggerPadding = styled.div`
    padding-top: 32px;
    @media screen and (max-width: 330px) {
        padding-top: 15px;
    }
`
const LargePadding = styled.div`
    padding-top: 40px;

    @media screen and (max-width: 330px) {
        padding-top: 30px;
    }
`

const TextAreaWidth = styled.div`
    width: 80vw;
    min-height: 70px;
    margin-top: 10px;
`

const ProgressBarImagesMobileChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: ${(props) => colours[props.$theme].primary};
    border-radius: 5px;
`

const CaptionText = styled.textarea<IStyle>`
    font-family: Lato;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    width: 80vw;
    color: ${(props) => colours[props.$theme].text_strong};
    min-height: 70px;
    font-size: 14px;
    border: none;
    padding-top: 20px;
    border-radius: 5px;
    border: ${(props) => colours[props.$theme].text_muted} 1px solid;
    font-size: 0.9em;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    :focus {
        outline: 0;
    }
`
export const PhotoPercentWrapper = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-bottom: 20px;
    font-size: 15px;

    font-family: Lato-light;
    padding-top: 5px;
    padding-bottom: 10px;
    color: ${(props) => colours[props.$theme].text_default};
`

const ProgressBarImagesMobileParentContainer = styled.div<IStyle>`
    border: 1px ${(props) => colours[props.$theme].primary} solid;
    display: flex;
    position: relative;
    height: 6px;
    width: 78vw;
    border-radius: 5px;
`

export const Error = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
    font-size: 11px;
`

export const CharacterCountSpan = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: lato-light;
    font-size: 11px;
    padding-left: 10px;
`

const ImagePickerStyled = styled(ImagePickerFromAntd)`
    height: 100%;
    width: 100%;
    display: flex;
`

const ImageInstructions = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 13px;
    padding-top: 10px;
    font-family: Lato-Light;
    @media screen and (min-width: 330px) {
        font-size: 14px;
    }
`

const BtnWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding-top: 16px;
    padding-bottom: 32px;
    padding-right: 20px;
`

// const HeightForBiggerDevice = styled.div`
//     @media screen and (min-width: 330px) {
//         height: 60px;
//     }
// `

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: 'row';
    margin-top: 10px;
`

const Line = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].text_default};
    width: 1px;
    height: 40px;
    margin-right: 10px;

    @media screen and (max-width: 330px) {
        margin-right: 15px;
        height: 40px;
    }
`

const TipText = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 14px;
    font-family: 'Lato';
    max-width: 70vw;

    @media screen and (max-width: 330px) {
        max-width: '100%';
        font-size: 12px;
    }

    @media screen and (min-width: 400px) {
        max-width: 300px;
    }
`

type IStyledWarning = {
    hasError?: boolean
}
const ImageInstructionsWarning = styled.div<IStyledWarning>`
    color: ${(props) =>
        props.hasError
            ? 'var(--error, #df6f6f)'
            : 'var(--text-strong, #1a1a1a)'};
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 5px;
    font-family: Lato-Light;

    @media screen and (min-width: 330px) {
        font-size: 14px;
        padding-bottom: 24px;
    }
`

const dispatchProps = {
    getPresignedUrlsCarGalleryImagesRequest: (p: reqPresignedUrImageArgs) =>
        getPresignedUrlsCarGalleryImagesRequest(p),
    submitCarGalleryImagesFilestorageRequest: (
        p: IUploadAndCreateCarGalleryImageArgs
    ) => submitCarGalleryImagesFilestorageRequest(p),
}

function mapStateToProps(state: RootState) {
    return { gallerypresignedurls: state.fileStorage.gallerypresignedurls }
}
interface Values {
    caption: string
    location: string
    credits: string
    file_url?: string
    files?: File[]
    morethanmax?: boolean
}

interface OtherProps extends RouteComponentProps<any> {
    close: any
    setImageUploaded: any
    currentImagesUploaded: any
    getPresignedUrlsCarGalleryImagesRequest: (
        p: reqPresignedUrImageArgs
    ) => void
    carid: string
    submitCarGalleryImagesFilestorageRequest: any
    gallerypresignedurls?: IUploadGalleryImagesPreSignedUrlsPayload
    progressArray: any
    isSomethingBeingUploaded: boolean
    progressState?: any
    isLastUploaded: boolean
    count: number
}

type ProgressProps = {
    progressPercent: number
    $theme: ITheme
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        setImageUploaded,
        setFieldValue,
        getPresignedUrlsCarGalleryImagesRequest,
        handleChange,
        currentImagesUploaded,
        count,

        progressState,
        errors,
        isLastUploaded,
    } = props

    const [showLocationSuggestions, toggleShowLocationSuggestion] =
        useState(false)

    const handleSuggestionSelection = (suggestion: any) => {
        setFieldValue(
            'location',
            suggestion.structured_formatting
                ? suggestion.structured_formatting.main_text
                : suggestion.description
        )
    }

    const handleFilesGiven = (files: File[]) => {
        let count = files.length

        if (count <= 20) {
            setFieldValue('morethanmax', false)
            let file_names = files.map((file) => file.name)

            count > 0 &&
                getPresignedUrlsCarGalleryImagesRequest({
                    carid: props.carid,
                    count: count,
                    file_names: file_names,
                })

            setFieldValue('files', files)

            return setImageUploaded(files)
        } else return setFieldValue('morethanmax', true)
    }

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 3,
        })
    }

    const handleNewUpload = (info: any) => {
        let newFilesList: File[] = []

        info.forEach((f: any, index: number) => {
            let file: File = f.file

            if (
                file.type === 'image/png' ||
                file.type === 'image/jpeg' ||
                file.type === 'image/heic' ||
                file.type === 'image/heif'
            ) {
                if (file.size <= 20000000 && file.size > 1) {
                    return newFilesList.push(file)
                } else return errorFileSizeWarning()
            }
        })

        return handleFilesGiven(newFilesList)
    }

    const { theme } = useThemes()

    return (
        <EditFormContainerMobile id="scrollable-content" $theme={theme}>
            <ModalTopBar $theme={theme}>Add images to your gallery</ModalTopBar>
            <InnerFormPadding>
                {!progressState && (
                    <>
                        {/* <FormTitle>Add images to your gallery</FormTitle>

                        <div style={{ paddingTop: '16px' }} /> */}
                        <div style={{ paddingTop: '24px' }} />

                        <TipText $theme={theme}>
                            Once uploaded, you can edit images and also select
                            your{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                car profile image.
                            </span>
                        </TipText>

                        <ImageInstructions $theme={theme}>
                            Image formats accepted: png, jpg, jpeg, HEIC and
                            HEIF.
                            <br />
                            Maximum file size: 20MB.
                        </ImageInstructions>
                        <ImageInstructionsWarning
                            hasError={values.morethanmax ? true : false}
                        >
                            Please upload up to 20 files.
                        </ImageInstructionsWarning>

                        <div style={{ paddingTop: '30px' }} />

                        <motion.div whileTap={{ scale: 1.05 }}>
                            <ImagePickerStyled
                                multiple={true}
                                onChange={(info: any) => {
                                    handleNewUpload(info)
                                }}
                                files={[]}
                                selectable={currentImagesUploaded < 10}
                                // selectable={currentImagesUploaded.length <= 10}
                                // showUploadList={false}
                            />
                        </motion.div>
                    </>
                )}

                {progressState && currentImagesUploaded && (
                    <PhotoPercentWrapper $theme={theme}>
                        <SmallPadding />
                        <SmallPadding />
                        {isLastUploaded ? (
                            <div
                                style={{
                                    paddingBottom: '5px',
                                }}
                            >
                                <span
                                    style={{
                                        paddingRight: '3px',
                                    }}
                                >
                                    {currentImagesUploaded.length}
                                </span>
                                file
                                {currentImagesUploaded.length > 1
                                    ? `s`
                                    : ''}{' '}
                                successfully uploaded (
                                {Math.floor(progressState.total)}% )
                            </div>
                        ) : (
                            <div>
                                uploading {count} /{' '}
                                {currentImagesUploaded.length} file
                                {currentImagesUploaded.length > 1
                                    ? `s`
                                    : ''}{' '}
                                {Math.floor(progressState.total)}%...
                            </div>
                        )}

                        <ProgressBarImagesMobileParentContainer $theme={theme}>
                            <ProgressBarImagesMobileChild
                                $theme={theme}
                                progressPercent={progressState.total}
                            />
                        </ProgressBarImagesMobileParentContainer>
                    </PhotoPercentWrapper>
                )}
                {/* {showFiles} */}

                <Expander height={progressState ? 'auto' : 0}>
                    <SmallPadding />
                    <FieldTitle style={{ paddingBottom: 0 }}>
                        Caption
                        <CharacterCountSpan $theme={theme}>
                            [up to 265 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.caption.length > 1 && errors.caption
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.caption}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPadding />
                    <TextAreaWidth id="scrollable-content">
                        {/* <TouchScrollable> */}
                        <CaptionText
                            $theme={theme}
                            id="caption"
                            name="caption"
                            placeholder="ie. Doing donuts in my Fiat Panda 4x4 in the snow"
                            aria-live="polite"
                            onChange={handleChange}
                            value={values.caption}
                        />
                        {/* </TouchScrollable> */}
                    </TextAreaWidth>
                    <SmallPadding />
                    <SmallPadding />
                    <FieldTitle>
                        Location
                        <CharacterCountSpan $theme={theme}>
                            [up to 35 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.location.length > 1 && errors.location
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.location}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPaddingBetweenFields />
                    <InputField
                        theme={theme}
                        id="location"
                        name="location"
                        placeholder="Where was this taken?"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.location}
                        onFocus={() => {
                            toggleShowLocationSuggestion(true)
                        }}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <GooglePlacesLocationsDropdown
                        valueSearched={values.location}
                        showDropdown={showLocationSuggestions}
                        toggleShowDropDown={(how: boolean) =>
                            toggleShowLocationSuggestion(how)
                        }
                        onSuggestionClick={handleSuggestionSelection}
                    />
                    <BiggerPadding />
                    <FieldTitle>
                        Credits
                        <CharacterCountSpan $theme={theme}>
                            [up to 35 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.credits.length > 1 && errors.credits
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.credits}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPaddingBetweenFields />
                    <InputField
                        theme={theme}
                        id="credits"
                        name="credits"
                        placeholder="Name of photographer (if applicable)"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.credits}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <SmallPadding />
                    <SmallPadding />
                    <Row>
                        <Line $theme={theme} />
                        <TipText $theme={theme}>
                            Once uploaded, you can edit images and also select
                            your{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                car profile image.
                            </span>
                        </TipText>
                    </Row>
                </Expander>
            </InnerFormPadding>

            {isLastUploaded ? (
                <LargePadding />
            ) : (
                <div style={{ paddingTop: '56px' }} />
            )}
            {/* <HeightForBiggerDevice /> */}
            <BtnWrap>
                <ButtonsStandardBottomRowMobile
                    cancel={close}
                    submit={props.handleSubmit}
                    confirmationtext={'upload'}
                    disabled={
                        currentImagesUploaded.length < 1 &&
                        currentImagesUploaded[0] === undefined
                            ? true
                            : !isLastUploaded
                            ? true
                            : (values.caption && errors.caption) ||
                              (values.location && errors.caption) ||
                              (values.credits && errors.credits)
                            ? true
                            : false
                    }
                    dataCyIdUpdate="upload_car_images_mobile"
                    dataCyIdCancel="cancel_upload_car_images_mobile"
                />
            </BtnWrap>
        </EditFormContainerMobile>
    )
}

const CarGalleryEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        caption: '',
        location: '',
        credits: '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                caption: Yup.string().max(
                    266,
                    'Caption must be be less than 265 characters.'
                ),
                location: Yup.string().max(
                    36,
                    'Location must be be less than 35 characters.'
                ),

                credits: Yup.string().max(
                    36,
                    'Credits must be be less than 35 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let files = props.currentImagesUploaded

        let gallerypresignedurls = props.gallerypresignedurls

        if (files !== undefined && gallerypresignedurls) {
            props.submitCarGalleryImagesFilestorageRequest({
                carid: props.carid,
                gallerypresignedurls: gallerypresignedurls,
                files: files,
                fields: {
                    caption: values.caption,
                    credit: values.credits,
                    location: values.location,
                },
                isFromQuickActions: false,
            })

            // here req thunks to upload to S3
        }
        setSubmitting(false)
    },
})(InnerForm)

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarGalleryEditFormMobile)
)
