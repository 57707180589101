import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import {
    getCarDataByIdRequest,
    getGarageCarsDataRequest,
} from '../../../redux/entities/cars/actions/loadingActions'
import {
    createGaragePublicShareRequest,
    deleteGaragePublicShareRequest,
    filterGarageSharesDisplay_change_request,
    getGarageByUserIdRequest,
    getOwnedDirectSharesGarageRequest,
    IFilterGarageSharesDisplay_change_request,
    ISortGarageSharesDisplay_change_request,
    sortGarageSharesDisplay_change_request,
} from '../../../redux/entities/garages/actions/loadingActions'
import {
    getCurrentUserDataRequest,
    populateOwnedSharesDataRequest,
} from '../../../redux/user/actions/loadingActions'
import SwitchLikeBtnDesktop from '../../atoms/Button/switchLikeBtnDesktop'
import H1V3 from '../../atoms/typography/H1V3'
import H2V3 from '../../atoms/typography/H2V3'

// import DesktopDisplay from '../../templates/displays/desktopDisplay'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ICar, IGalleryImagesObject, IGarageObject } from 'entityModels'
import { IisOpen, IUser } from 'myModels'
import Faded from '../../templates/animated/faded'
import { device } from '../../templates/displays/devices'
import CarSharedWithOthersCardMobile from '../../molecules/cards/sharing/CarSharedWithOthersCardMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import CarSharedWithOthersCardDesktop from '../../molecules/cards/sharing/carSharedWithOthersCardDesktop'
import SingleGarageShareBodyDesktop from '../../molecules/singleGarageShareBody/singleGarageShareBodyDesktop'
import SingleGarageShareBodyMobile from '../../molecules/singleGarageShareBody/singleGarageShareBodyMobile'
import * as ReactScroll from 'react-scroll'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { IDeleteShareReq } from '../../../redux/entities/cars/actions/actions'
import NoCarsInGarage from '../../atoms/noCarsInGarage/noCarsInGarage'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import ModalDisplayDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayDesktopExclusive'
import QrCodeModalMobile from '../../molecules/qrCodeModal/qrCodeModalMobile'
import QrCodeModalDesktop from '../../molecules/qrCodeModal/qrCodeModalDesktop'
import { IStickerPredefinedThemesState } from '../../../redux/localdata/stickerPredefinedThemes/reducer'
import QrCodeCustomiseStickerModalDesktop from '../../molecules/qrCodeCustomiseStickerModal/qrCodeCustomiseStickerModalDesktop'
import QrCodeStickerBottomSheetMobile from '../../molecules/qrCodeCustomiseStickerModal/qrCodeStickerBottomSheetMobile'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

function mapStateToProps(state: RootState) {
    return {
        garage_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.owns_garage &&
            state.user.userLoggedIn.owns_garage.uid,
        userLoggedIn: state.user.userLoggedIn,
        allSharesOwnedLoading: state.user.sharesOwnedLoading,
        garages_data: state.entities.garagesData.garages,
        isGarageLoading: state.entities.garagesData.loading,
        isCarLoading: state.entities.carsData.loading,
        carsData: state.entities.carsData.cars,
        isUserLoading: state.user.loading,
        imagesData: state.entities.galleriesData.images,
        qrCodeModal: state.editForms && state.editForms.formsData.qrCodeModal,
        qrCodeStickerModal:
            state.editForms && state.editForms.formsData.qrCodeStickerModal,
        predefinedQrCodeStickerThemes: state.localdata.stickerPredefinedThemes,
        isCarDataLoading: state.entities.carsData.loading,
        areAllCarSharesLoading: state.entities.carsData.carSharesLoading,
        shareDeletionConfirmation:
            state.editForms.directShareDeletionConfirmation,
    }
}

const dispatchProps = {
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    populateOwnedSharesDataRequest: () => populateOwnedSharesDataRequest(),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
    createGaragePublicShareRequest: (garageid: string) =>
        createGaragePublicShareRequest(garageid),
    deleteGaragePublicShareRequest: (p: IDeleteShareReq) =>
        deleteGaragePublicShareRequest(p),
    sortGarageSharesDisplay_change: (
        p: ISortGarageSharesDisplay_change_request
    ) => sortGarageSharesDisplay_change_request(p),
    filterGarageSharesDisplay_change: (
        p: IFilterGarageSharesDisplay_change_request
    ) => filterGarageSharesDisplay_change_request(p),
    toggleQrCodeModal: (payload: { isOpen: boolean; id: string | undefined }) =>
        editFormsActions.toggleQrCodeModal(payload),
    toggleQrCodeStickerModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => editFormsActions.toggleQrCodeStickerModal(payload),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    getOwnedDirectSharesGarageRequest: (p: string) =>
        getOwnedDirectSharesGarageRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    location: any
    garages_data: IGarageObject
    carsData: { [key: string]: ICar }
    isGarageLoading: boolean
    garage_id: string | null
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isCarLoading: boolean
    getGarageCarsDataRequest: (id: string) => void
    getUserDataRequest: () => void
    populateOwnedSharesDataRequest: () => void
    getGarageByUserIdRequest: () => void
    imagesData: IGalleryImagesObject | null
    createGaragePublicShareRequest: (garageid: string) => void
    deleteGaragePublicShareRequest: (p: IDeleteShareReq) => void
    sortGarageSharesDisplay_change: (
        p: ISortGarageSharesDisplay_change_request
    ) => void
    filterGarageSharesDisplay_change: (
        p: IFilterGarageSharesDisplay_change_request
    ) => void
    toggleQrCodeModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => void
    qrCodeModal: IisOpen
    qrCodeStickerModal: IisOpen
    toggleQrCodeStickerModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => void
    predefinedQrCodeStickerThemes: IStickerPredefinedThemesState
    getCarDataByIdRequest: (carid: string) => void
    isCarDataLoading: boolean
    areAllCarSharesLoading: boolean
    areGarageSharesLoading: boolean
    getOwnedDirectSharesGarageRequest: (p: string) => void
    allSharesOwnedLoading?: boolean | undefined
    setInitialLandingUrl: (str: string) => void
}

const PageWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    width: 100%;
    padding-top: 20px;
    min-height: 100vh;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 200px;
`
const DesktopCenteredWrap = styled.div`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        justify-self: center;
    }
`
const OverWrapperDesktop = styled.div`
    @media ${device.beyond_ipad_mobile} {
        max-width: 1400px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 100px;
        align-self: center;
        justify-self: center;
    }
`

const WhiteBgGarageShareDesktop = styled.div`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 30px;
        align-self: center;
        justify-self: center;
        background-color: var(--bg-color, #fff);
        padding-left: 100px;
        padding-right: 50px;
        max-width: 1400px;
    }
`

const WhiteBgGarageShareWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    justify-self: center;
    @media ${device.beyond_ipad_mobile} {
        padding-top: 70px;
    }
`

const WrapperDesktopAdjusted = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
    }

    /* @media ${device.ultrawide_desktop} {
        grid-gap: 70px;
    }

    @media ${device.smallest_laptops} {
        grid-gap: 20px;
    }

    @media (min-width: 1600px) and (max-width: 1720px) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    } */

    @media (max-width: 1240px) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
`

const WrapperMobileAdjusted = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 20px; */
    flex-wrap: wrap;
    padding-top: 52px;
    padding-bottom: 100px;

    @media ${device.ipad} {
        padding-top: 60px;
    }
`

const IpadAndMobileWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    padding: 15px;
    padding-top: 110px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    @media ${device.ipad} {
        padding-top: 150px;
    }
    @media (max-width: 400px) {
        padding: 5px;
        padding-top: 110px;
    }
`

// const IpadAndMobileTopPadding = styled.div`
//     padding-top: 130px;
//     @media ${device.mobile} {
//         padding-top: 110px;
//     }
// `

type State = {
    activeTabID: string
}

class SharedWithOthers extends React.Component<Props, State> {
    state = {
        activeTabID: 'cars',
    }

    getNecessaryData = () => {
        let userData = this.props.userLoggedIn
        let garageid = this.props.garage_id
        let garagesData = this.props.garages_data

        if (userData && userData.id) {
            // ask for garage data only if it's not there (usually on page reload)
            if (
                garageid &&
                (!garagesData ||
                    !garagesData[garageid] ||
                    !garagesData[garageid].cars ||
                    garagesData[garageid].cars.length === 0)
            ) {
                this.props.getGarageByUserIdRequest()
            }

            this.props.populateOwnedSharesDataRequest()
        }
    }

    componentDidMount() {
        // user stuff
        let userData = this.props.userLoggedIn
        let garagesData = this.props.garages_data
        let garageid = this.props.garage_id

        const params = new URLSearchParams(this.props.location.search)
        let entity_id = params.get('entity_id')

        entity_id && entity_id === 'garage'
            ? this.props.setInitialLandingUrl(
                  `/sharing/with-others?entity_id=garage`
              )
            : this.props.setInitialLandingUrl(`/sharing/with-others`)

        if (!userData) {
            this.props.getUserDataRequest()
        } else if (garageid) {
            if (
                !garagesData[garageid] ||
                (garagesData[garageid] &&
                    !garagesData[garageid].public_share_owned) ||
                !garagesData[garageid].cars ||
                garagesData[garageid].cars.length === 0
            ) {
                this.getNecessaryData()
            }

            this.props.getOwnedDirectSharesGarageRequest(garageid)
        }

        let action = params.get('action')

        if (entity_id === 'garage_private' || entity_id === 'garage') {
            this.setState({ activeTabID: 'garage' })
        }

        const openQrCodeGarage = () =>
            this.props.toggleQrCodeModal({ isOpen: true, id: 'garage' })

        if (entity_id === 'garage' && action === 'show_qr_code') {
            setTimeout(openQrCodeGarage, 200)
        }
    }
    componentDidUpdate(prevProps: Props) {
        // user stuff
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            this.getNecessaryData()
        }
    }

    changeTab = (tabID: string) => {
        this.setState({ activeTabID: tabID })
        this.props.history.push(`/sharing/with-others?entity_id=${tabID}`)
    }

    scrollToElement = (elementName: string) => {
        ReactScroll.scroller.scrollTo(elementName, {
            duration: 500,
            delay: 300,
            smooth: true,
            offset: 50,
        })
    }

    render() {
        let {
            userLoggedIn,
            garages_data: garage_data,
            garage_id,
            carsData,
            imagesData,
            createGaragePublicShareRequest,
            deleteGaragePublicShareRequest,
            sortGarageSharesDisplay_change,
            filterGarageSharesDisplay_change,
            toggleQrCodeModal,
            qrCodeModal,
            qrCodeStickerModal,
            toggleQrCodeStickerModal,
            predefinedQrCodeStickerThemes,
            isCarDataLoading,
            isUserLoading,
            isGarageLoading,
            areAllCarSharesLoading,
            areGarageSharesLoading,
            allSharesOwnedLoading,
        } = this.props

        let current = garage_id && garage_data && garage_data[garage_id]

        let garage_qr_code_link =
            current &&
            current.public_share_owned &&
            current.public_share_owned.qr_code_url

        const btnIdsList: string[] = ['cars', 'garage']
        const btnsData: any = {
            cars: {
                id: 'cars',
                txt: 'cars',
                action: () => this.changeTab('cars'),
                isDisabled: false,
            },
            garage: {
                id: 'garage',
                txt: 'garage',
                action: () => this.changeTab('garage'),
                isDisabled: false,
            },
        }

        let active_tab: string = this.state.activeTabID

        const params = new URLSearchParams(this.props.location.search)
        let id = params.get('id')

        if (id === 'garage_private') {
            this.scrollToElement('garage_privately_shared')
        }

        let isDataLoading: boolean =
            isCarDataLoading || isUserLoading || isGarageLoading ? true : false

        return (
            <React.Fragment>
                <FabsMenusMobile hasSubNav={true} />
                <DesktopDisplayOnly>
                    <FullPageWrapper>
                        <PageWrapper>
                            <H1V3>Shared with Others</H1V3>

                            <H2V3>
                                Allow your car(s) or garage to be visible to
                                others, either as a publicly shareable link or a
                                direct share.
                            </H2V3>

                            <div style={{ paddingBottom: '60px' }} />

                            <SwitchLikeBtnDesktop
                                btnIdsList={btnIdsList}
                                btnsData={btnsData}
                                activeID={active_tab}
                            />

                            {active_tab === 'cars' &&
                                (isDataLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '30vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={isDataLoading}
                                        />
                                    </div>
                                ) : (
                                    userLoggedIn &&
                                    current !== null &&
                                    current !== undefined &&
                                    garage_id &&
                                    carsData &&
                                    garage_data &&
                                    garage_data[garage_id] &&
                                    garage_data[garage_id].cars &&
                                    (garage_data[garage_id].cars.length > 0 ? (
                                        <Faded>
                                            <DesktopCenteredWrap>
                                                <OverWrapperDesktop>
                                                    <WrapperDesktopAdjusted>
                                                        {garage_data[
                                                            garage_id
                                                        ].cars.map(
                                                            (
                                                                id: string,
                                                                index: number
                                                            ) => {
                                                                if (
                                                                    carsData[
                                                                        id
                                                                    ] &&
                                                                    garage_id
                                                                ) {
                                                                    return (
                                                                        userLoggedIn && (
                                                                            <CarSharedWithOthersCardDesktop
                                                                                key={`${id}-carshared-desktop`}
                                                                                car={
                                                                                    carsData[
                                                                                        id
                                                                                    ]
                                                                                }
                                                                                carIndex={Object.keys(
                                                                                    carsData
                                                                                ).indexOf(
                                                                                    id
                                                                                )}
                                                                                imagesData={
                                                                                    imagesData
                                                                                }
                                                                                hasMarginRight={
                                                                                    garage_data[
                                                                                        garage_id
                                                                                    ]
                                                                                        .cars
                                                                                        .length >
                                                                                        0 &&
                                                                                    index %
                                                                                        2 ===
                                                                                        0
                                                                                }
                                                                                garage={
                                                                                    garage_data[
                                                                                        garage_id
                                                                                    ]
                                                                                }
                                                                                qrCodeRequested={(
                                                                                    qr_code_id: string
                                                                                ) =>
                                                                                    this.props.history.push(
                                                                                        `/sharing/with-others/car?carid=${id}&qr_id=${qr_code_id}`
                                                                                    )
                                                                                }
                                                                                areSharesLoading={
                                                                                    areAllCarSharesLoading ||
                                                                                    areGarageSharesLoading ||
                                                                                    allSharesOwnedLoading
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                            />
                                                                        )
                                                                    )
                                                                } else
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                        />
                                                                    )
                                                            }
                                                        )}
                                                    </WrapperDesktopAdjusted>
                                                </OverWrapperDesktop>
                                            </DesktopCenteredWrap>
                                        </Faded>
                                    ) : (
                                        <div style={{ paddingTop: '50px' }}>
                                            <NoCarsInGarage variant="desktop" />
                                        </div>
                                    ))
                                ))}

                            {active_tab === 'garage' &&
                                (isDataLoading || areAllCarSharesLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '30vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={
                                                isDataLoading ||
                                                areAllCarSharesLoading
                                            }
                                        />
                                    </div>
                                ) : userLoggedIn &&
                                  current !== null &&
                                  current !== undefined &&
                                  garage_id &&
                                  garage_data &&
                                  garage_data[garage_id] &&
                                  garage_data[garage_id].cars ? (
                                    <Faded>
                                        <WhiteBgGarageShareWrapper>
                                            <WhiteBgGarageShareDesktop>
                                                <SingleGarageShareBodyDesktop
                                                    garage={
                                                        garage_data[garage_id]
                                                    }
                                                    createGaragePublicShare={
                                                        createGaragePublicShareRequest
                                                    }
                                                    deleteGaragePublicShare={
                                                        deleteGaragePublicShareRequest
                                                    }
                                                    handleChange_shares_sort={
                                                        sortGarageSharesDisplay_change
                                                    }
                                                    handleChange_shares_filter={
                                                        filterGarageSharesDisplay_change
                                                    }
                                                    openGarageQrCodeModal={() =>
                                                        toggleQrCodeModal({
                                                            isOpen: true,
                                                            id: 'garage',
                                                        })
                                                    }
                                                    areGarageSharesLoading={
                                                        areGarageSharesLoading
                                                    }
                                                />
                                            </WhiteBgGarageShareDesktop>
                                        </WhiteBgGarageShareWrapper>
                                        <div style={{ paddingTop: '200px' }} />
                                    </Faded>
                                ) : null)}

                            {qrCodeModal.isOpen &&
                                qrCodeModal.id === 'garage' && (
                                    <ModalDisplayDesktopExclusive
                                        isOpen={
                                            qrCodeModal.isOpen &&
                                            qrCodeModal.id === 'garage'
                                                ? true
                                                : false
                                        }
                                        toggle={() => {}}
                                        modalBackgroundColor="rgba(0, 0, 0, 0.65)"
                                    >
                                        <div style={{ marginTop: '20vh' }} />
                                        <QrCodeModalDesktop
                                            qr_code_link={
                                                garage_qr_code_link
                                                    ? garage_qr_code_link
                                                    : ''
                                            }
                                            qr_code_id={
                                                'garage_qr_code_to_export_desktop'
                                            }
                                            car_title={
                                                userLoggedIn &&
                                                userLoggedIn.display_name
                                                    ? `${userLoggedIn.display_name}'s Garage`
                                                    : ''
                                            }
                                            closeModal={() =>
                                                this.props.toggleQrCodeModal({
                                                    isOpen: false,
                                                    id: undefined,
                                                })
                                            }
                                            onStickerBtnClick={() =>
                                                toggleQrCodeStickerModal({
                                                    isOpen: true,
                                                    id: qrCodeModal.id,
                                                })
                                            }
                                            isGarage
                                        />
                                    </ModalDisplayDesktopExclusive>
                                )}
                            {qrCodeStickerModal.isOpen &&
                                qrCodeModal.id === 'garage' && (
                                    <Faded>
                                        <ModalDisplayDesktopExclusive
                                            isOpen={qrCodeModal.isOpen}
                                            toggle={() => {}}
                                            modalBackgroundColor="rgba(0, 0, 0, 0)"
                                        >
                                            <div
                                                style={{
                                                    marginTop: '20vh',
                                                }}
                                            />
                                            <QrCodeCustomiseStickerModalDesktop
                                                qr_code_link={
                                                    garage_qr_code_link
                                                        ? garage_qr_code_link
                                                        : ''
                                                }
                                                qr_code_id={
                                                    'garage_qr_code_to_export_desktop'
                                                }
                                                car_title={
                                                    userLoggedIn &&
                                                    userLoggedIn.display_name
                                                        ? `${userLoggedIn.display_name}'s Garage`
                                                        : ''
                                                }
                                                closeModal={() =>
                                                    this.props.toggleQrCodeStickerModal(
                                                        {
                                                            isOpen: false,
                                                            id: undefined,
                                                        }
                                                    )
                                                }
                                                predefinedStickerThemes={
                                                    predefinedQrCodeStickerThemes
                                                }
                                                isGarage
                                            />
                                        </ModalDisplayDesktopExclusive>
                                    </Faded>
                                )}
                        </PageWrapper>{' '}
                    </FullPageWrapper>
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <Faded>
                        <IpadAndMobileWrapper>
                            <H1V3>Shared with Others</H1V3>

                            <H2V3>
                                Allow your car(s) or garage to be visible to
                                others, either as a publicly shareable link or a
                                direct private share.
                            </H2V3>

                            <div style={{ paddingBottom: '30px' }} />

                            <SwitchLikeBtnDesktop
                                btnIdsList={btnIdsList}
                                btnsData={btnsData}
                                activeID={active_tab}
                            />
                            {active_tab === 'cars' &&
                                (isDataLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '30vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={isDataLoading}
                                        />
                                    </div>
                                ) : (
                                    userLoggedIn &&
                                    userLoggedIn !== null &&
                                    current !== null &&
                                    current !== undefined &&
                                    garage_id &&
                                    carsData &&
                                    garage_data &&
                                    garage_data[garage_id] &&
                                    garage_data[garage_id].cars &&
                                    (garage_data[garage_id].cars.length > 0 ? (
                                        <Faded>
                                            <WrapperMobileAdjusted>
                                                {garage_data[
                                                    garage_id
                                                ].cars.map(
                                                    (
                                                        id: string,
                                                        index: number
                                                    ) => {
                                                        if (carsData[id]) {
                                                            return (
                                                                <CarSharedWithOthersCardMobile
                                                                    key={`${id}-carshared-mobile`}
                                                                    car={
                                                                        carsData[
                                                                            id
                                                                        ]
                                                                    }
                                                                    carIndex={Object.keys(
                                                                        carsData
                                                                    ).indexOf(
                                                                        id
                                                                    )}
                                                                    imagesData={
                                                                        imagesData
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                    garage={
                                                                        garage_data[
                                                                            garage_id
                                                                                ? garage_id
                                                                                : ''
                                                                        ]
                                                                    }
                                                                    qrCodeRequested={(
                                                                        qr_code_id: string
                                                                    ) =>
                                                                        this.props.history.push(
                                                                            `/sharing/with-others/car?carid=${id}&qr_id=${qr_code_id}`
                                                                        )
                                                                    }
                                                                    isCarDataLoading={
                                                                        areAllCarSharesLoading ||
                                                                        areGarageSharesLoading ||
                                                                        allSharesOwnedLoading
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            )
                                                        } else
                                                            return (
                                                                <div
                                                                    key={index}
                                                                />
                                                            )
                                                    }
                                                )}
                                            </WrapperMobileAdjusted>
                                        </Faded>
                                    ) : (
                                        <div style={{ paddingTop: '50px' }}>
                                            <NoCarsInGarage variant="mobile" />
                                        </div>
                                    ))
                                ))}

                            {active_tab === 'garage' &&
                                (isDataLoading || areAllCarSharesLoading ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '30vh',
                                        }}
                                    >
                                        <AdjustableLoader
                                            isLoading={
                                                isDataLoading ||
                                                areAllCarSharesLoading
                                            }
                                        />
                                    </div>
                                ) : userLoggedIn &&
                                  current !== null &&
                                  current !== undefined &&
                                  garage_id &&
                                  garage_data &&
                                  garage_data[garage_id] &&
                                  garage_data[garage_id].cars ? (
                                    <Faded>
                                        <WhiteBgGarageShareWrapper>
                                            <SingleGarageShareBodyMobile
                                                garage={garage_data[garage_id]}
                                                createGaragePublicShare={
                                                    createGaragePublicShareRequest
                                                }
                                                deleteGaragePublicShare={
                                                    deleteGaragePublicShareRequest
                                                }
                                                handleChange_shares_sort={
                                                    sortGarageSharesDisplay_change
                                                }
                                                handleChange_shares_filter={
                                                    filterGarageSharesDisplay_change
                                                }
                                                openGarageQrCodeModal={() =>
                                                    toggleQrCodeModal({
                                                        isOpen: true,
                                                        id: 'garage',
                                                    })
                                                }
                                                areGarageSharesLoading={
                                                    areGarageSharesLoading
                                                }
                                            />
                                        </WhiteBgGarageShareWrapper>
                                        <div style={{ paddingTop: '50px' }} />
                                    </Faded>
                                ) : null)}
                        </IpadAndMobileWrapper>
                    </Faded>
                    <div style={{ paddingTop: '120px' }} />
                    {qrCodeModal.isOpen && qrCodeModal.id === 'garage' && (
                        <ModalDisplay
                            isOpen={
                                qrCodeModal.isOpen &&
                                qrCodeModal.id === 'garage'
                                    ? true
                                    : false
                            }
                            toggle={() => {}}
                        >
                            <QrCodeModalMobile
                                qr_code_link={
                                    garage_qr_code_link
                                        ? garage_qr_code_link
                                        : ''
                                }
                                qr_code_id={'garage_qr_code_to_export_desktop'}
                                closeModal={() =>
                                    this.props.toggleQrCodeModal({
                                        isOpen: false,
                                        id: undefined,
                                    })
                                }
                                onStickerBtnClick={() => {
                                    toggleQrCodeStickerModal({
                                        isOpen: true,
                                        id: qrCodeModal.id,
                                    })
                                    this.props.toggleQrCodeModal({
                                        isOpen: false,
                                        id: undefined,
                                    })
                                }}
                                car_title={
                                    userLoggedIn && userLoggedIn.display_name
                                        ? `${userLoggedIn.display_name}'s Garage`
                                        : ''
                                }
                                isGarage={true}
                            />
                        </ModalDisplay>
                    )}
                    <QrCodeStickerBottomSheetMobile
                        qr_code_link={
                            garage_qr_code_link ? garage_qr_code_link : ''
                        }
                        qr_code_id={'garage_qr_code_to_export_desktop'}
                        closeBottomSheet={() =>
                            this.props.toggleQrCodeStickerModal({
                                isOpen: false,
                                id: undefined,
                            })
                        }
                        title={
                            userLoggedIn && userLoggedIn.display_name
                                ? `${userLoggedIn.display_name}'s Garage`
                                : ''
                        }
                        isBottomSheetOpen={qrCodeStickerModal.isOpen}
                        predefinedStickerThemes={predefinedQrCodeStickerThemes}
                    />
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(SharedWithOthers)
)
