export const generateBigSVG = (svg_qr_code: any): any => {
    let elem: SVGSVGElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
    )

    elem.setAttribute('viewBox', '0,0,1000,1000')

    elem.setAttribute('width', `1000`)
    elem.setAttribute('height', `1000`)

    const rec: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    rec.setAttribute('fill', 'none')

    elem.appendChild(rec)

    svg_qr_code.setAttribute('width', '100%')
    svg_qr_code.setAttribute('height', '100%')

    elem.appendChild(svg_qr_code)

    return elem
}
