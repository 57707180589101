import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

export default function KMFplaceholder() {
    const { theme } = useThemes()
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 28,
                padding: 24,
            }}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 22,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: 4,
                        backgroundColor:
                            colours[theme].base_overlays_neutral_alpha_50,
                    }}
                />
                <div
                    style={{
                        width: 28,
                        minWidth: 28,
                        height: 28,
                        backgroundColor:
                            colours[theme].base_overlays_neutral_alpha_50,
                    }}
                />
                <div
                    style={{
                        width: '100%',
                        height: 4,
                        backgroundColor:
                            colours[theme].base_overlays_neutral_alpha_50,
                    }}
                />
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                    flex: 1,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: 24,
                        backgroundColor:
                            colours[theme].base_overlays_neutral_alpha_50,
                    }}
                />
                <div
                    style={{
                        width: '100%',
                        height: 24,
                        backgroundColor:
                            colours[theme].base_overlays_neutral_alpha_50,
                    }}
                />
                <div
                    style={{
                        width: '80%',
                        height: 24,
                        backgroundColor:
                            colours[theme].base_overlays_neutral_alpha_50,
                    }}
                />
            </div>
            <div
                style={{
                    width: '100%',
                    height: 4,
                    backgroundColor:
                        colours[theme].base_overlays_neutral_alpha_50,
                }}
            />
        </div>
    )
}
