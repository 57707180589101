import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions/index'
import { api } from '../../services'
import * as Sentry from '@sentry/browser'

export function* verifyResetPasswordCodeSaga(verificationCode: string): any {
    try {
        const res = yield call(
            api.authentication.resetPasswordVerifyCode,
            verificationCode
        )

        yield put(
            actions.default.actions.verifyResetPasswordCodeSuccess(res.user_uid)
        )
    } catch (error: any) {
        if (error) {
            yield put(
                actions.default.errorActions.verifyResetPasswordCodeError({
                    default: error,
                    status_code: error.status,
                })
            )

            Sentry.captureMessage(`Reset Password link error: ${error.status}`)

            yield call(api.authentication.logout)
        }
    }
}

function* watcherVerifyResetPasswordCode() {
    while (true) {
        const { payload } = yield take(
            actions.default.loadingActions.verifyResetPasswordCodeRequest
        )
        yield call(verifyResetPasswordCodeSaga, payload)
    }
}

const verifyResetPasswordCode: any[] = [fork(watcherVerifyResetPasswordCode)]

export default verifyResetPasswordCode
