import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import Loader from '../../../atoms/loader/loader'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import { useHistory, useLocation } from 'react-router'
import AddACarDataFormOnboardingMobile from '../../../organisms/editForms/addACarForm/onboarding/addCarDataFormOnboardingMobile'
import RetrieveCarByRegNbFormMobile from '../../../organisms/editForms/addACarForm/onboarding/retrieveCarByRegNbFormMobile'
import BottomSheetPage from '../../../templates/bottomSheetPages.tsx/bottomSheetPage'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import {
    setEmptyCarRequest,
    getExternalCarDataByRegistrationNumberRequestVersion2,
    getCarDataByIdRequest,
} from '../../../../redux/entities/cars/actions/loadingActions'
import OnboardingLayoutDesktop, {
    OnboardingText,
} from '../../../templates/onboarding/desktop/onboardingLayoutDesktop'
import AddACarDataFormOnboardingDesktop from '../../../organisms/editForms/addACarForm/onboarding/addCarDataFormOnboardingDesktop'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
} from '../../../molecules/technicalInformation/modal'
import ButtonsStandardBottomRowDesktop from '../../../organisms/editForms/buttons/buttonsStandardBottomRowDesktop'
import AddACarFormOnboarding from '../../../organisms/editForms/addACarForm/onboarding/addACarFormOnboarding'
import ModalDisplayAbsolute from '../../../templates/displays/pageWrappers/modalDisplayAbsolute'
import styled from 'styled-components'
import { PaddingUnit } from '../../../templates/onboarding/onboardingFormLayoutMobile'
import { IExternalCarDataRequestPayloadVersion2 } from 'entityModels'
import useThemes from '../../../../providers/theme/hooks'

const ModalTitle = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 24px;
    line-height: 40px;
    padding-bottom: 24px;
`

const OnboardingStep_2_1 = () => {
    const dispatch = useAppDispatch()
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let carid = queryParams.get('carid')
    // let countryParam = queryParams.get('country')

    let form_open = queryParams.get('form_open')

    let onboardingStep2Data = useAppSelector(
        (state) => state.localdata.onboardingContent.content[2]
    )

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let userLoading = useAppSelector((state) => state.user.loading)

    let carsData = useAppSelector((state) => state.entities.carsData.cars)
    let carsDataLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )

    let garageDataLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )
    let countriesdropdownlist = useAppSelector(
        (state) => state.localdata.dropdownData.countriesCode
    )
    let selectedCountry = useAppSelector(
        (state) => state.localdata.dropdownData.selectedCountry
    )
    let currentCar =
        carid && carsData && carsData[carid] ? carsData[carid] : undefined

    // let country = selectedCountry
    //     ? selectedCountry
    //     : countryParam
    //     ? decodeURIComponent(countryParam)
    //     : 'United Kingdom'
    const [showAddByRegNbForm, toggleAddByRegNbForm] = useState(false)

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/onboarding-step-2-1?carid=${carid}`
            )
        )
        if (form_open === 'true') {
            setTimeout(() => toggleAddByRegNbForm(true), 50)
        }
    }, [])

    let history = useHistory()

    const [registrationNumber, setRegistrationNumber] = useState<
        string | undefined
    >(undefined)

    const searchByRegNo = () => {
        if (
            carid &&
            (!selectedCountry || selectedCountry === 'United Kingdom') &&
            registrationNumber &&
            registrationNumber.trim().length > 1
        ) {
            let reg_no = registrationNumber.trim()
            dispatch(dropDownActions.setRegistrationNumber(reg_no))

            let p: IExternalCarDataRequestPayloadVersion2 = {
                // car_id: carid,
                registration_number: reg_no,
                isOnboarding: true,
            }

            dispatch(getExternalCarDataByRegistrationNumberRequestVersion2(p))
            toggleAddByRegNbForm(false)
        } else {
            let reg_no =
                registrationNumber && registrationNumber.trim().length > 1
                    ? registrationNumber.trim()
                    : undefined

            if (reg_no) {
                history.push(
                    `/onboarding-step-2-2?carid=${carid}&from_ves_api=false&reg_no=${reg_no}`
                )
            }
            toggleAddByRegNbForm(false)
        }
    }

    useEffect(() => {
        if (userLoggedIn) {
            if (carid && !carsData[carid]) {
                if (carid === 'new_car') {
                    dispatch(setEmptyCarRequest(carid))
                } else {
                    dispatch(getCarDataByIdRequest(carid))
                }
            }
            if (!countriesdropdownlist) {
                dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
            }
        } else {
            dispatch(getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const { theme } = useThemes()

    return userLoading || carsDataLoading || garageDataLoading ? (
        <Loader isLoading />
    ) : (
        <React.Fragment>
            <DesktopDisplayOnly>
                <OnboardingLayoutDesktop
                    step={2}
                    title={onboardingStep2Data.title}
                    whiteTextTitle={onboardingStep2Data.desktopRightSideTitle}
                    whiteTextSubTitle={
                        onboardingStep2Data.desktopRightSideSubtitle
                    }
                >
                    {currentCar && (
                        <AddACarDataFormOnboardingDesktop
                            car={currentCar}
                            onRetrieveByRegNoClick={() =>
                                toggleAddByRegNbForm(true)
                            }
                            formText={onboardingStep2Data.text}
                        />
                    )}
                </OnboardingLayoutDesktop>

                {showAddByRegNbForm && countriesdropdownlist ? (
                    <ModalDisplayAbsolute
                        isOpen={showAddByRegNbForm}
                        toggle={() => {}}
                    >
                        <ModalContentDesktop
                            $theme={theme}
                            data-attr={
                                'onboarding-search-by-reg-no-desktop-modal'
                            }
                            style={{ overflowY: 'auto' }}
                        >
                            <ModalInnerContentWrapperDesktop>
                                <ModalTitle>
                                    {onboardingStep2Data.findByRegNoTitle}
                                </ModalTitle>
                                <OnboardingText>
                                    {onboardingStep2Data.findByRegNoText}
                                </OnboardingText>
                                <PaddingUnit $times={5} />
                                <AddACarFormOnboarding
                                    isDesktop
                                    countriesdropdownlist={
                                        countriesdropdownlist
                                    }
                                    userCountry={'United Kingdom'}
                                    selectedCountry={
                                        selectedCountry ?? 'United Kingdom'
                                    }
                                    registrationNumber={registrationNumber}
                                    setRegistrationNumber={(
                                        p: string | undefined
                                    ) => setRegistrationNumber(p)}
                                    removeToggle
                                />
                                <PaddingUnit $times={1} />

                                <ButtonsStandardBottomRowDesktop
                                    cancel={() => toggleAddByRegNbForm(false)}
                                    submit={searchByRegNo}
                                    firstText="Cancel"
                                    secondText={'Find my car'}
                                    dataCyIdUpdate={
                                        'onboarding-search-by-reg-no-desktop-click'
                                    }
                                    isDisabled={!registrationNumber}
                                />
                            </ModalInnerContentWrapperDesktop>
                        </ModalContentDesktop>
                    </ModalDisplayAbsolute>
                ) : null}
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                {carid ? (
                    <SideSliderWithExit
                        isVisible={
                            carid && !userLoading && !carsDataLoading
                                ? true
                                : false
                        }
                        direction={'right'}
                        initial="enter"
                        customDuration={0.5}
                        noOverflow
                    >
                        {currentCar && (
                            <AddACarDataFormOnboardingMobile
                                formStep={2}
                                formTitle={onboardingStep2Data.title}
                                formText={onboardingStep2Data.text}
                                car={currentCar}
                                onRetrieveByRegNoClick={() => {
                                    toggleAddByRegNbForm(true)
                                    // dispatch(
                                    //     dropDownActions.setSelectedMake(null)
                                    // )
                                    // dispatch(
                                    //     dropDownActions.setSelectedModel(null)
                                    // )
                                }}
                                next={() => {
                                    // history.push(
                                    //     `/onboarding-step-3?carid=${carid}`
                                    // )
                                }}
                            />
                        )}
                        <BottomSheetPage isOpen={showAddByRegNbForm}>
                            <RetrieveCarByRegNbFormMobile
                                carid={carid}
                                closeForm={() => toggleAddByRegNbForm(false)}
                            />
                        </BottomSheetPage>
                    </SideSliderWithExit>
                ) : null}
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default OnboardingStep_2_1
