import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import { IGalleryImage } from 'entityModels'
import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../skeleton/skeletonAbsolute'
import CustomAnimatedCheckboxField from '../../formField/customAnimatedCheckboxField'

const ImageCellWrap = styled.div`
    position: relative;
    z-index: 1;

    cursor: pointer;
    @media ${device.desktop} {
        height: 26vh;
    }

    @media ${device.large_desktop} {
        height: 28vh;
    }
    @media ${device.smallest_laptops} {
        height: 22vh;
    }
    @media ${device.ipad} {
        height: 18vh;
    }

    @media ${device.beyond_ipad_mobile} {
        min-height: 325px;
    }
    @media ${device.mobile_and_ipad} {
        height: 18vh;
    }
`

const ImageCell = styled.div<StyleProps>`
    background-image: ${(props) => `url(${props.url})`};
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    height: 100%;

    text-align: center;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: ${(props) => (props.$isLoading === true ? 1 : 1)};
    transition: opacity 400ms;
`

const HoverBox = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;

    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;

    background: rgba(0, 0, 0, 0.6);
    transition: 0.2s;

    :hover {
        opacity: 1 !important;
    }
`

const AbsoluteCheckbox = styled.div`
    position: absolute;
    right: 20px;
    bottom: 20px;

    @media ${device.mobile_and_ipad} {
        right: 5px;
        bottom: 10px;
    }
`

type Props = {
    image_obj: IGalleryImage
    onSelect: (id: string) => any
    isSelected: boolean
}

interface StyleProps {
    url: string
    $isLoading: boolean
}

const ShowroomSelectableImageTrioItem = (props: Props) => {
    const [src, setSrc] = React.useState<string | undefined>()
    const [isLoading, setIsLoading] = React.useState<boolean>(true)

    let { image_obj } = props

    let onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () => setSrc(src)
        setIsLoading(false)
    }

    React.useEffect(() => {
        const src = image_obj.url
        onLoad(src)
    }, [image_obj.url])

    return (
        <ImageCellWrap>
            <SkeletonAbsolute isthingloading={isLoading} />
            <ImageCell
                role="img"
                aria-label="car overview banner"
                url={src || grey_placeholder}
                $isLoading={isLoading}
                onClick={() => {
                    props.onSelect(image_obj.id)
                }}
            >
                <AbsoluteCheckbox>
                    <CustomAnimatedCheckboxField checked={props.isSelected} />
                </AbsoluteCheckbox>
                <HoverBox style={{ opacity: props.isSelected ? 1 : 0 }}>
                    <AbsoluteCheckbox onClick={(e) => e.preventDefault()}>
                        <CustomAnimatedCheckboxField
                            checked={props.isSelected}
                            onChange={() => {}}
                        />
                    </AbsoluteCheckbox>
                </HoverBox>
            </ImageCell>
        </ImageCellWrap>
    )
}

export default ShowroomSelectableImageTrioItem
