import styled from 'styled-components'
import {
    IShowroomEntriesSimilarRecommendations,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { CardSkeletonItems } from '../../../pages/showroom/showroom'
import { ISearchShowroom_API_car_item } from '../../../../redux/services/showroom/types'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import ShowroomCardDesktop from '../card/showroomCardDesktop'
import React from 'react'
import { device } from '../../../templates/displays/devices'

type Props = {
    entry?: IShowroomEntry
    recommendationsObj?: IShowroomEntriesSimilarRecommendations
    clickOnOwner: () => any
    onClickBrowseAll: () => any
    clickOnMake: () => any
    isLoading: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;
`

const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    padding-bottom: 2rem;

    @media ${device.ipad} {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        gap: 24px;
    }
`

const ContainerTop = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

const Row = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

const BtnTxt = styled.span`
    color: #5ec3ca;
    text-align: center;
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const Title = styled.h3`
    padding: 0px;
    margin: 0px;
    color: #1a1a1a;
    text-align: center;
    font-family: Lato-semibold;
    font-size: 24px;
    line-height: 40px;
    white-space: nowrap;
`

const ShowroomEntryRecommendationsDesktop = (props: Props) => {
    const {
        entry,
        recommendationsObj,
        clickOnOwner,
        isLoading,
        onClickBrowseAll,
        clickOnMake,
    } = props

    let entriesList: ISearchShowroom_API_car_item[] | undefined =
        entry?.owner?.id &&
        recommendationsObj &&
        recommendationsObj.dealers[entry.owner.id]
            ? recommendationsObj.dealers[entry.owner.id].latest?.filter(
                  (listed: ISearchShowroom_API_car_item) => {
                      return listed?.uid !== entry?.id
                  }
              )
            : []

    // to be replaced with actual data
    let dealer_total_nb_of_cars: number | undefined =
        entry?.owner?.id &&
        recommendationsObj &&
        recommendationsObj.dealers[entry.owner.id] &&
        recommendationsObj.dealers[entry.owner.id].total_count !== undefined
            ? recommendationsObj.dealers[entry.owner.id].total_count
            : undefined

    let dealer_name: string = entry?.owner?.display_name
        ? `${entry?.owner?.display_name}`
        : 'dealer'

    return (
        <Container>
            <ContainerTop>
                <Title>More cars from {dealer_name}</Title>
                <StandardCtaBtn
                    onClick={() => {
                        clickOnOwner()
                    }}
                    boder="none"
                    bg="transparent"
                    removePaddings
                >
                    <Row>
                        <BtnTxt>{`See ${dealer_total_nb_of_cars} cars from ${dealer_name}`}</BtnTxt>
                        <div style={{ transform: 'rotate(-90deg)' }}>
                            <ChevronDown color="#5ec3ca" />
                        </div>
                    </Row>
                </StandardCtaBtn>
            </ContainerTop>
            <CardsContainer>
                {isLoading ? (
                    <CardSkeletonItems
                        height="25vh"
                        width="95%"
                        paddingBottom="0px"
                        narr={[1, 2, 3]}
                    />
                ) : entriesList && entriesList.length > 0 ? (
                    entriesList.map(
                        (
                            listed: ISearchShowroom_API_car_item,
                            index: number
                        ) => {
                            if (index < 3) {
                                return (
                                    <React.Fragment
                                        key={`card_${listed.uid}_desktop`}
                                    >
                                        <ShowroomCardDesktop
                                            isRecommendation
                                            entry={listed}
                                        />
                                    </React.Fragment>
                                )
                            } else return null
                        }
                    )
                ) : null}
            </CardsContainer>
            <ContainerTop style={{ justifyContent: 'flex-end', gap: 16 }}>
                <StandardCtaBtn
                    onClick={() => {
                        onClickBrowseAll()
                    }}
                    boder="1px solid #5ec3ca"
                    bg="transparent"
                >
                    <BtnTxt>{`Browse all showroom cars`}</BtnTxt>
                </StandardCtaBtn>

                {entry && entry.car?.overview.data.make.answer && (
                    <StandardCtaBtn
                        onClick={() => {
                            clickOnMake()
                        }}
                        boder="1px solid #5ec3ca"
                        bg="transparent"
                    >
                        <BtnTxt>{`See more ${entry.car?.overview.data.make.answer}s`}</BtnTxt>
                    </StandardCtaBtn>
                )}
            </ContainerTop>
        </Container>
    )
}

export default ShowroomEntryRecommendationsDesktop
