import Decimal from 'decimal.js'
import {
    IAPIShowroomEnquiryByEntryId,
    IAPIShowroomEnquiryByEntryIdBody,
    IEnquiryStructureRequest,
} from '../../showroom/types'

const assignInfoTypeCodeNumber = (id: string) => {
    switch (id) {
        case 'history_file':
            return 1
        case 'gallery':
            return 2
        case 'viewing':
            return 4
        case 'for_sale_availability':
            return 8
        case 'price_movement':
            return 16
        case 'other':
            return 32
        default:
            return 0
    }
}

const assignMediumCodeNumber = (id: string) => {
    switch (id) {
        case 'email':
            return 1
        case 'phone':
            return 2
        case 'message':
            return 4

        default:
            return 0
    }
}

export const convertToEnquiryAPIPayload = (
    p: IEnquiryStructureRequest
): IAPIShowroomEnquiryByEntryId => {
    let info_type = p.enquiry.info?.selected_enquiry
        ? p.enquiry.info.selected_enquiry.reduce(
              (acc, val) => acc + assignInfoTypeCodeNumber(val),
              0
          )
        : 0
    let contact_mode = p.contact.medium.reduce(
        (acc, val) => acc + assignMediumCodeNumber(val),
        0
    )

    let api_payload: IAPIShowroomEnquiryByEntryId = {
        entry_id: p.entry_id,
        api_body_payload: {
            enquiry_type: p.enquiry.type === 'info' ? 'GET_INFO' : 'MAKE_OFFER',
            info_type: info_type > 0 ? info_type : undefined,
            custom_message:
                p.enquiry.type === 'info' && p.enquiry.info
                    ? p.enquiry.info.custom_message
                    : p.enquiry.type === 'offer' && p.enquiry.offer
                    ? p.enquiry.offer.custom_message
                    : undefined,
            offer_price: p.enquiry.offer?.personal_offer_amount
                ? Number(
                      new Decimal(p.enquiry.offer?.personal_offer_amount).times(
                          100
                      )
                  )
                : undefined,
            currency:
                p.enquiry.type === 'offer' && p.enquiry.offer
                    ? p.enquiry.offer?.currency
                    : undefined,
            offer_type:
                p.enquiry.type === 'offer' && p.enquiry.offer
                    ? p.enquiry.offer.selected_offer === 'asking_price_offer'
                        ? 'ASKING_PRICE'
                        : p.enquiry.offer.selected_offer ===
                          'personal_offer_amount'
                        ? 'OFFER'
                        : undefined
                    : undefined,
            buyer_name: p.contact.details.name
                ? p.contact.details.name?.toString()
                : '',
            buyer_email: p.contact.details.email
                ? p.contact.details.email.toString()
                : undefined,
            buyer_phone: p.contact.details.phone
                ? p.contact.details.phone.toString()
                : undefined,
            buyer_contact_mode: contact_mode,
        },
    }

    let cleaned_api_body = { ...api_payload.api_body_payload }

    Object.keys(cleaned_api_body).forEach(
        (key) =>
            cleaned_api_body[key as keyof IAPIShowroomEnquiryByEntryIdBody] ===
                undefined &&
            delete cleaned_api_body[
                key as keyof IAPIShowroomEnquiryByEntryIdBody
            ]
    )

    api_payload.api_body_payload = cleaned_api_body

    return api_payload
}
