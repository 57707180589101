import * as React from 'react'
import styled from 'styled-components'
import blue_arrow from '../../../public/assets/icons/arrow_to_right.svg'

interface IStyle {
    iconOnLeft?: boolean | undefined
    isUppercase?: boolean | undefined
    width?: string | undefined
    height?: string | undefined
    nobg?: boolean
    disabled?: boolean
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '52px')};
    border: transparent;
    outline: none;
    background-color: var(--bg-color, #fff);
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 5px 5px 20px 3px var(--box-shadow, rgba(0, 0, 0, 0.08));
    padding-left: 20px;
    padding-right: 20px;

    :hover {
        background-color: var(--primary_04);
        transition: all 200ms;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.disabled
            ? 'var(--text-default, #666666)'
            : 'var(--primary, #5ec3ca)'};
    font-size: 14px;
    font-family: Lato;
`

const PendingShares = styled.div<IStyle>`
    background-color: var(--primary, #5ec3ca);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bg-color, #fff);
    font-family: Lato-bold;
    font-size: 14px;
`

const LeftPartWrapper = styled.div<IStyle>`
    display: flex;
    align-items: center;
`

const BlueArrowWrapper = styled.div<IStyle>`
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BlueArrow = styled.img`
    width: 100%;
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    width?: string | undefined
    height?: string | undefined
    disabled?: boolean
    type?: 'submit' | 'reset' | 'button' | undefined
    pendingSharesNb: number
}

class MobilePendingSharesBtn extends React.Component<Props> {
    render() {
        let {
            onClick,
            text,
            width,
            height,
            dataCyId,
            disabled,
            type,
            pendingSharesNb,
        } = this.props
        return (
            <Button
                width={width}
                height={height}
                onClick={disabled ? () => {} : onClick}
                data-attr={dataCyId ? dataCyId : undefined}
                disabled={disabled}
                type={type ? type : 'button'}
            >
                <LeftPartWrapper>
                    <PendingShares>{pendingSharesNb}</PendingShares>
                    <div style={{ paddingLeft: '20px' }} />{' '}
                    <Text disabled={disabled}>{text}</Text>
                </LeftPartWrapper>
                <BlueArrowWrapper>
                    <BlueArrow alt="arrow" src={blue_arrow} />
                </BlueArrowWrapper>
            </Button>
        )
    }
}

export default MobilePendingSharesBtn
