import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
import styled from 'styled-components'
import { device } from '../templates/displays/devices'
import { verifyEmail } from '../../redux/user/actions/thunks'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import ButtonAtom from '../atoms/Button/ButtonAtom'
import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'
import Loader from '../atoms/loader/loader'
import TriangleHeader from '../atoms/header/triangleheader'
import { IReduxError } from 'entityModels'

const ValidationContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Content = styled.div`
    font-family: Lato;
    color: black;
    line-height: 2.2;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.desktop} {
        font-size: 16px;
        text-align: center;
        max-width: 400px;
    }

    @media ${device.ipad} {
        font-size: 15px;
        margin-top: 10px;
        max-width: 400px;
    }

    @media ${device.mobile} {
        font-size: 14px;
        width: 100vw;
        padding-left: 10vw;
        padding-right: 10vw;
        padding-top: 2vh;
    }
`

const PaddingTop = styled.div`
    @media ${device.desktop} {
        padding-top: 20px;
    }
    @media ${device.ipad} {
        padding-top: 20px;
    }

    @media ${device.mobile} {
        padding-top: 6px;
    }
`

const Divider = styled.div`
    @media ${device.desktop} {
        padding-top: 40px;
    }
    @media ${device.ipad} {
        padding-top: 40px;
    }

    @media ${device.mobile} {
        padding-top: 40px;
    }
`

const dispatchProps = {
    verifyEmail: (verificationcode: string) => verifyEmail(verificationcode),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        isLoading: state.user.loading,
        error: state.user.error,
    }
}

type State = {
    redirect: boolean
    isSignedIn: boolean
}

interface Props extends RouteComponentProps<any> {
    userLoggedIn: null | IUser
    isLoading: boolean
    userid: any
    verifyEmail: (verificationcode: string) => void
    error: IReduxError
}

class VerifyPage extends React.Component<Props, State> {
    state = {
        redirect: false,
        isSignedIn: false,
    }

    componentDidMount() {
        this.props.verifyEmail(this.props.match.params.activationcode)
        let userLoggedIn = this.props.userLoggedIn

        if (userLoggedIn) {
            this.setState({ isSignedIn: true })
        }
    }

    handleOnClick = () => {
        this.setState({ redirect: true })
    }

    render() {
        const error = this.props.error

        return (
            <PublicPageWrapper>
                <Loader />
                {this.state.redirect && (
                    <Redirect
                        push
                        to={this.state.isSignedIn ? '/' : '/signin'}
                    />
                )}
                <ValidationContainer>
                    <PaddingTop />

                    <TriangleHeader title="EMAIL VERIFICATION" />

                    {error ? (
                        <Content>
                            Invalid code. You can contact us at:
                            <span style={{ marginRight: '5px' }} />
                            <a
                                href="mailto:concierge@privatecollectorsclub.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                concierge@privatecollectorsclub.com
                            </a>
                            <Divider />
                            <ButtonAtom
                                theme="tertiary"
                                onClick={this.handleOnClick}
                            >
                                {this.state.isSignedIn ? 'OK' : 'SIGN IN'}
                            </ButtonAtom>
                        </Content>
                    ) : (
                        <Content>
                            Thank you. <br />
                            The email associated with your account has
                            successfully been verified.
                            <Divider />
                            <ButtonAtom
                                theme="tertiary"
                                onClick={this.handleOnClick}
                            >
                                {this.state.isSignedIn ? 'OK' : 'SIGN IN'}
                            </ButtonAtom>
                        </Content>
                    )}
                </ValidationContainer>
            </PublicPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(VerifyPage)
