import styled from 'styled-components'
import success_img from '../../../../../public/assets/icons/success_img.png'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { device } from '../../../../templates/displays/devices'

const SuccessImg = styled.img`
    height: 140px;
`
const Title = styled.div`
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 36px;
    }
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    max-width: 250px;

    @media ${device.mobile_and_ipad} {
        line-height: 30px;
    }
`

type Props = {
    entryid: string
}

const PublicationConfirmedModalDesktop = (props: Props) => {
    return (
        <>
            <SuccessImg src={success_img} alt="success-logo" />
            <div style={{ paddingTop: '50px' }} />
            <Title>Congratulations</Title>
            <div style={{ paddingTop: '20px' }} />
            <Subtitle>
                Your car has been successfully listed for sale in the Showroom!
            </Subtitle>
            <div style={{ paddingTop: '50px' }} />
            <a
                href={`https://app.custodian.club/showroom/car-details/${props.entryid}`}
                style={{ width: '100%' }}
            >
                <ButtonAtom
                    theme="secondary"
                    width="100%"
                    height="48px"
                    textTransform="capitalize"
                    dataCyId={'showroom-car-enquire'}
                    onClick={(e: any) => {
                        // e?.preventDefault()
                    }}
                    fontSize={16}
                >
                    View in Showroom
                </ButtonAtom>
            </a>
        </>
    )
}

export default PublicationConfirmedModalDesktop
