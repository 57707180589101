import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'typesafe-actions'
import {
    deleteCarDirectShareRequest,
    IgetSingleDirectShareRequest_redux,
} from '../../../redux/entities/cars/actions/loadingActions'
import { deleteGarageDirectShareRequest } from '../../../redux/entities/garages/actions/loadingActions'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import { IDirectShareDeletionConfirmation } from 'myModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'

function mapStateToProps(state: RootState) {
    return {
        directShareDeletionConfirmation:
            state.editForms.directShareDeletionConfirmation,
    }
}

const dispatchProps = {
    toggleShareDeletionConfirmation: (p: IDirectShareDeletionConfirmation) =>
        editFormsActions.toggleShareDeletionConfirmation(p),
    deleteGarageDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) =>
        deleteGarageDirectShareRequest(p),
    deleteCarDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) =>
        deleteCarDirectShareRequest(p),
}

interface Props extends RouteComponentProps<any> {
    directShareDeletionConfirmation: IDirectShareDeletionConfirmation
    toggleShareDeletionConfirmation: (
        p: IDirectShareDeletionConfirmation
    ) => void
    deleteGarageDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => void
    deleteCarDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) => void
}

type State = {}

class DirectShareDeletionConfirmation extends React.Component<Props, State> {
    state = {}

    handleDelete = (p: IDirectShareDeletionConfirmation) => {
        if (p.entity_type === 'car') {
            if (p.entity_id && p.share_id) {
                this.props.deleteCarDirectShareRequest({
                    entity_id: p.entity_id,
                    share_id: p.share_id,
                })
                this.props.history.push(
                    `/sharing/with-others/car?carid=${p.entity_id}`
                )
            }
        } else {
            if (p.entity_id && p.share_id) {
                this.props.deleteGarageDirectShareRequest({
                    entity_id: p.entity_id,
                    share_id: p.share_id,
                })

                this.props.history.push(`/sharing/with-others?entity_id=garage`)
            }
        }
    }

    render() {
        let {
            directShareDeletionConfirmation,
            toggleShareDeletionConfirmation,
        } = this.props

        return (
            <React.Fragment>
                <DeletionConfirmationBottomSheet
                    customHeight="240px"
                    isOpen={directShareDeletionConfirmation.isOpen}
                    toggle={() =>
                        setTimeout(
                            () =>
                                toggleShareDeletionConfirmation({
                                    isOpen: false,
                                    entity_id: undefined,
                                    entity_type: undefined,
                                    share_id: undefined,
                                }),
                            50
                        )
                    }
                    action={() =>
                        this.handleDelete(directShareDeletionConfirmation)
                    }
                    actionCopy="Confirm"
                    titleCopy="Are you sure?"
                    detailsCopy={
                        directShareDeletionConfirmation.entity_type === 'car'
                            ? 'All recipients will lose access to your car.'
                            : 'All recipients will lose access to your garage.'
                    }
                />
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(DirectShareDeletionConfirmation)
)
