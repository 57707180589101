// create store for todos (basically reducer for the main final store)

import { IHighlightedFactsState } from 'entityModels'

import * as actions from './actions/actions'

import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: IHighlightedFactsState = {
    highlighted_facts: {},
    error: null,
    loading: false,
}

export const highlightedFactsData = (
    state: IHighlightedFactsState = initialState,
    action: LoadingActions | Action | ErrorActions
): IHighlightedFactsState => {
    switch (action.type) {
        // GET
        case loadingActions.getHighlightedFactsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.getHighlightedFactsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS
        case actions.getHighlightedFactsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                highlighted_facts: {
                    ...state.highlighted_facts,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        // EDIT
        case loadingActions.updatetHighlightedFactsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.updatetHighlightedFactsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS
        case actions.updateHighlightedFactsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                highlighted_facts: {
                    ...state.highlighted_facts,
                    [action.payload.id]: action.payload,
                },
                loading: false,
                error: null,
            })

        // SET
        case loadingActions.setHighlightedFactsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.setHighlightedFactsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS
        case actions.setHighlightedFactsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                highlighted_facts: {
                    ...state.highlighted_facts,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case actions.cleanUpHighlightedFactsSuccess.type:
            return Object.assign({}, state, {
                highlighted_facts: {},
                error: null,
                loading: false,
            })

        default:
            return state
    }
}

export default highlightedFactsData

export type HighlightedFactsState = ReturnType<typeof highlightedFactsData>
