import React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import access_denied from '../../../public/assets/icons/access_denied.svg'
import ErrorBody from './errorBody'
import ScrollLock from 'react-scrolllock'

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    padding-top: 20vh;
    @media ${device.beyond_ipad_mobile} {
        padding-left: 20vw;
        justify-content: flex-start;
    }
`

const Container = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    @media ${device.beyond_ipad_mobile} {
        width: 50%;
        max-width: 800px;
        min-width: 600px;
    }
`

interface Props {
    onClick?: any
}

interface State {}

// else "/garage"

// and then youd do : newUrl = onboardingUrlObject[index - 1]

export class AccessDenied extends React.Component<Props, State> {
    state = {}

    render() {
        return (
            <Wrapper>
                <ScrollLock />
                <Container>
                    <ErrorBody
                        onClick={this.props.onClick}
                        title={`Oops, you don't have permission to access this page.`}
                        subTitle={'You might have typed a wrong link.'}
                        buttonText={'Go to your garage'}
                        headIcon={access_denied}
                    />
                </Container>
            </Wrapper>
        )
    }
}

export default AccessDenied
