import { IApiEntryDELETE_args } from 'ApiInterfaces'
import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'

export const deleteEntry = (p: IApiEntryDELETE_args): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.delete_entry_endpoint(p.entry_id, p.car_id),
        requestOptions
    )
        .then((response: any) => {
            if (response.ok !== true) {
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            apiSentryErrorHandler(e, 'Delete entry error')
            return Promise.reject(e)
        })
}
