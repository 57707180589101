import { IDropdownItem } from 'entityModels'
import styled from 'styled-components'

import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import Expander from '../../../atoms/expander/expander'
import { ErrorText } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import SelectSearchBarCarInfoDesktop from '../../../organisms/editForms/technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'
import { IInsuranceDropdownItem } from '../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { Select } from '../../../atoms/select'

const Wrapper = styled.div`
    width: 100%;
    padding: 24px;
    overflow-y: auto;
    padding-bottom: 200px;
`

type Props = {
    formData: IInsuranceAddress_API
    handleChange: (id: keyof IInsuranceAddress_API, value: string) => any
    isFormValid: () => boolean
    error?: { [key: string]: string | undefined } | undefined
    isCustom?: boolean
}

const SearchField = styled.input`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    height: 50px;
    min-height: 50px;
    border-radius: 5px;
    border: 1px solid var(--text-muted);
    color: var(--text-strong, #1a1a1a);
    padding-left: 15px;
    padding-right: 60px;
    width: 100%;
`

const Line = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 100%;
`

const Label = styled.label<{ raise: boolean; backgroundColor?: string }>`
    position: absolute;
    ${(props) => `
        top: ${props.raise ? '0%' : '50%'};
        transform-origin: top left;
        transform: ${
            props.raise
                ? `translate(8px, -50%) scale(0.8)`
                : `translate(8px, -50%) scale(1)`
        };
        left: 0;
        min-width: 30px;
       background-color: var(--modal-side-sheet-pop-over-bg, #fff);
       };
    `}

    transition: 0.15s;

    color: var(--text-muted);
    font-size: 1.1em;
    padding: 0 8px;
    pointer-events: none;
    font-family: Lato;

    &:first-letter {
        text-transform: uppercase;
    }
`

const ClearBtn = styled.button`
    all: unset;
    padding: 0 14px;
    display: flex;
    align-items: center;
    right: 0;
    position: absolute;
    color: var(--primary, #5ec3ca);
    cursor: pointer;
`

const GetQuoteAddressesForm = (props: Props) => {
    let { formData, handleChange, error } = props

    let dispatch = useAppDispatch()

    let usaAdminCodes: IInsuranceDropdownItem[] | null = useAppSelector(
        (state) => {
            return state.localdata.dropdownData.usaStatesCodes
        }
    )

    let countriesOptions: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    useEffect(() => {
        if (!countriesOptions) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [])

    let google_maps_input_ref: React.RefObject<HTMLInputElement> = useRef(null)

    const googleMaps = async (input: any) => {
        try {
            let autocomplete = new google.maps.places.Autocomplete(input, {
                types: ['geocode'],
            })

            autocomplete.setFields(['address_component'])

            autocomplete.addListener('place_changed', () => {
                let place: any = autocomplete.getPlace()

                let addressComponents: string[] = []

                place.address_components !== undefined &&
                    place.address_components.forEach((chunk: any) => {
                        addressComponents.push(chunk.types[0])
                        if (chunk.types[0] === 'street_number') {
                            props.handleChange('street_1', chunk.long_name)
                        }
                        if (chunk.types.includes('route')) {
                            props.handleChange('street_2', chunk.long_name)
                        }
                        if (chunk.types.includes('postal_code')) {
                            props.handleChange('postcode', chunk.long_name)
                        }
                        if (chunk.types.includes('locality')) {
                            props.handleChange('locality', chunk.long_name)
                        }
                        if (chunk.types.includes('postal_town')) {
                            props.handleChange('locality', chunk.long_name)
                        }

                        if (chunk.types.includes('country')) {
                            props.handleChange('country', chunk.long_name)
                        }
                    })

                if (!addressComponents.includes('street_number')) {
                    props.handleChange('street_1', '')
                }
                if (!addressComponents.includes('route')) {
                    props.handleChange('street_2', '')
                }
                if (!addressComponents.includes('postal_town')) {
                    props.handleChange('locality', '')
                }
            })
        } catch (e) {
            console.error(e)
        } finally {
        }
    }

    useEffect(() => {
        if (google_maps_input_ref.current) {
            googleMaps(google_maps_input_ref.current)
        }

        if (formData.country === 'United States' && !usaAdminCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest())
        }
    }, [google_maps_input_ref.current?.value])

    const [isRaised, setIsRaised] = useState<boolean>(false)

    const [isAdmin1Open, setIsAdmin1Open] = useState<boolean>(false)

    return (
        <Wrapper>
            <div
                style={{
                    paddingTop: '16px',
                    paddingBottom: '32px',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <SearchField
                        ref={google_maps_input_ref}
                        id="autocomplete_insurance_quote_address"
                        placeholder=""
                        type="text"
                        onFocus={() => {
                            setIsRaised(true)
                        }}
                        onBlur={() => {
                            !google_maps_input_ref.current?.value &&
                                setIsRaised(false)
                        }}
                    />

                    <Label
                        style={{ width: 'max-content' }}
                        raise={isRaised ? true : false}
                    >
                        Search by postcode
                    </Label>
                    <ClearBtn
                        style={{ width: 'max-content' }}
                        onClick={() => {
                            if (google_maps_input_ref.current?.value) {
                                google_maps_input_ref.current.value = ''
                                setIsRaised(false)
                            }
                        }}
                    >
                        Clear
                    </ClearBtn>
                </div>

                <div style={{ paddingTop: '32px' }} />
                <Line />
            </div>

            <InputFieldNoFormikAnimated
                name="Street number  /  House name"
                type="text"
                id="street_number"
                placeholder=""
                value={formData.street_1}
                onChange={(e: any) => {
                    props.handleChange('street_1', e.target.value)
                }}
                onEnterKeyPress={handleChange}
                tabindex={1}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['street_1'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['street_1']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="Street address"
                type="text"
                id="route"
                placeholder=""
                value={formData.street_2}
                onChange={(e: any) => {
                    props.handleChange('street_2', e.target.value)
                }}
                onEnterKeyPress={handleChange}
                tabindex={2}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['street_2'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['street_2']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="City"
                type="text"
                id="postal_town"
                placeholder=""
                value={formData.locality}
                onChange={(e: any) => {
                    props.handleChange('locality', e.target.value)
                }}
                onEnterKeyPress={handleChange}
                tabindex={3}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['locality'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['locality']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="Postcode"
                type="text"
                id="postal_code"
                placeholder=""
                value={formData.postcode}
                onChange={(e: any) => {
                    if (e?.target?.value) {
                        e.target.value = `${e.target.value}`.toUpperCase()
                    }
                    props.handleChange('postcode', e.target.value)
                }}
                onEnterKeyPress={handleChange}
                tabindex={4}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
            <Expander
                height={error && error['postcode'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['postcode']}</ErrorText>
            </Expander>

            <Expander
                height={formData.country === 'United States' ? 'auto' : 0}
                width={'100%'}
            >
                <div style={{ width: '100%', marginTop: '24px', zIndex: 16 }}>
                    <Select
                        value={formData.administrative_area}
                        options={usaAdminCodes ?? []}
                        defaultValue={formData.administrative_area}
                        placeholder={'Administrative Area Code'}
                        disabled={false}
                        onChange={(val: string) => {
                            props.handleChange('administrative_area', val)
                        }}
                        onFocusToggle={(p: boolean) => {
                            setIsAdmin1Open(p)
                        }}
                    />

                    <Expander
                        height={
                            !formData.administrative_area ||
                            formData.administrative_area?.length === 0
                                ? 'auto'
                                : 0
                        }
                        width={'100%'}
                    >
                        <ErrorText>Required</ErrorText>
                    </Expander>
                </div>
            </Expander>

            <div
                style={{
                    paddingTop: '24px',
                }}
            />

            <div
                style={{
                    width: '100%',
                    visibility: isAdmin1Open ? 'hidden' : 'visible',
                }}
            >
                <SelectSearchBarCarInfoDesktop
                    showBothDevices={true}
                    items={countriesOptions ?? []}
                    item={{
                        text: 'countries',
                        answer: formData.country ? formData.country : null,
                    }}
                    handleSelectChange={(val: string) => {
                        props.handleChange('country', val)
                        if (val === 'United States' && !usaAdminCodes) {
                            dispatch(
                                dropDownActions.setUSAStatesDropdownRequest()
                            )
                        }
                        if (
                            val !== 'United States' &&
                            val !== 'Spain' &&
                            formData.administrative_area
                        ) {
                            props.handleChange('administrative_area', '')
                        }
                    }}
                    valueUpdatedFromExternalSource={formData.country}
                    width="100%"
                    customMaxWidth="100%"
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
            </div>
        </Wrapper>
    )
}

export default GetQuoteAddressesForm
