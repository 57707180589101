import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import NoDataRectangle from '../noData/noDataRectangle'
import ButtonAtom from '../Button/ButtonAtom'
import { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IProps = {
    onClickAdd: any
    hasSvgBackgroundImage?: boolean
    height?: string
    readOnlyMode?: boolean
    noDataText?: string
    btnText?: string
    customPlaceholderImg?: string
}

const GoToBox = styled.div<{
    $hasSvgBackgroundImage?: boolean
    $customPlaceholderImg?: string
    $theme: ITheme
    $img: string
}>`
    ${(props) =>
        props.$hasSvgBackgroundImage &&
        `position: relative;
             background-size: cover;
             background-repeat: no-repeat;
             background-position: center center;
             background-image: url(${
                 props.$customPlaceholderImg
                     ? props.$customPlaceholderImg
                     : props.$img
             });`}

    @media ${device.ipad} {
        max-height: 25vh;
        min-height: 250px;
    }

    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
    background-color: var(--primary_16, #f0f8f8);
`

const AddBoxWithSvgPlaceholderMobile: React.FunctionComponent<IProps> = (
    props: IProps
) => {
    const { theme } = useThemes()
    const [placeholder, _] = React.useState(
        randomCarSvgPlaceholderFunc(undefined, theme)
    )

    return !props.readOnlyMode ? (
        <GoToBox
            style={{
                height: props.height ?? '400px',
                width: '100%',
            }}
            onClick={props.onClickAdd}
            $hasSvgBackgroundImage={props.hasSvgBackgroundImage}
            $customPlaceholderImg={props.customPlaceholderImg}
            $theme={theme}
            $img={placeholder}
        >
            <ButtonAtom theme="lowercase-blue-background" fontSize={14}>
                <div
                    style={{
                        textTransform: 'none',
                    }}
                >
                    {props.btnText ?? 'Add'}
                </div>
            </ButtonAtom>
        </GoToBox>
    ) : (
        <NoDataRectangle
            text={props.noDataText ?? 'No data'}
            height={props.height ?? '400px'}
        />
    )
}

export default AddBoxWithSvgPlaceholderMobile
