import styled from 'styled-components'

type IStyledProps = {
    size: number
    color?: string
}

const CircleSpinner = styled.div<IStyledProps>`
    display: inline-block;
    position: relative;
    width: ${(props) => `${props.size}px`};
    height: ${(props) => `${props.size}px`};

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${(props) => `${props.size * 0.8}px`};
        height: ${(props) => `${props.size * 0.8}px`};
        margin: ${(props) => `${props.size * 0.1}px`};
        border: ${(props) =>
            `${props.size * 0.1}px solid ${props.color ?? '#fff'}`};
        border-radius: 50%;
        animation: circleAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${(props) =>
            `${props.color ?? '#fff'} transparent transparent transparent`};
    }
    div:nth-child(1) {
        animation-delay: -0.45s;
    }
    div:nth-child(2) {
        animation-delay: -0.3s;
    }
    div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes circleAnimation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

type Props = {
    size: number
    color?: string
}

const CircleLoader = (props: Props) => (
    <CircleSpinner size={props.size} color={props.color}>
        <div />
        <div />
        <div />
        <div />
    </CircleSpinner>
)

export default CircleLoader
