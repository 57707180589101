import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'
import download_pdf from '../../../public/assets/icons/download_pdf.svg'
import download_sticker from '../../../public/assets/icons/download_sticker.svg'
import download_image from '../../../public/assets/icons/download_image.svg'

type StyledProps = {
    $height?: string
    $width?: string
    $iconSize?: string
    isFlexColumn?: boolean
}

const MainButton = styled.div<StyledProps>`
    display: flex;
    justify-content: ${(props) =>
        props.isFlexColumn === true ? 'flex-start' : 'center'};
    padding-left: ${(props) => (props.isFlexColumn === true ? '10px' : '0px')};

    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    height: ${(props) => (props.$height ? props.$height : '55px')};
    width: ${(props) => (props.$width ? props.$width : '100%')};
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    cursor: pointer;
    border: 1px solid var(--border-muted, #e5e5e5);
    transition: all 200ms;

    :hover {
        transition: all 200ms;
        box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.03);
        background-color: var(--primary_04);
    }

    @media ${device.beyond_ipad_mobile} {
        align-items: center !important;
        flex-direction: ${(props) => (props.isFlexColumn ? 'column' : 'row')};
        justify-content: ${(props) =>
            props.isFlexColumn ? 'center' : 'flex-start'};
        padding-left: ${(props) => (props.isFlexColumn ? '0px' : '20px')};
        height: ${(props) => (props.$height ? props.$height : '60px')};
    }
`

const Text = styled.div<StyledProps>`
    color: var(--primary, #5ec3ca);
    text-transform: capitalize;
    font-size: 16px;
    font-family: Lato;
    padding-left: 10px;
    line-height: 22px;

    @media ${device.beyond_ipad_mobile} {
        max-width: 70%;
        padding-left: ${(props) => (props.isFlexColumn ? '0px' : '12px;')};
        padding-top: ${(props) => (props.isFlexColumn ? '12px' : '0px;')};
        text-align: ${(props) =>
            props.isFlexColumn === true ? 'center' : 'left'};
    }
`
const IconWrapper = styled.div<StyledProps>`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Icon = styled.img`
    width: 100%;
    height: 100%;
`

type Props = {
    dataCyId?: string | undefined
    height?: string
    width?: string
    iconSize?: string
    variant: 'pdf' | 'sticker' | 'image'
    children: React.ReactNode
    onClick: any
    isFlexColumn?: boolean
}

const QrCodeActionBtnWrapper: React.FunctionComponent<Props> = (props) => {
    let {
        dataCyId,
        height,
        width,
        iconSize,
        variant,
        children,
        onClick,
        isFlexColumn,
    } = props

    return (
        <motion.div
            whileTap={{ scale: 1.05 }}
            style={{ width: width ? width : '100%' }}
        >
            <MainButton
                $height={height}
                $width={width}
                data-attr={dataCyId ? dataCyId : undefined}
                onClick={onClick}
                isFlexColumn={isFlexColumn}
            >
                <IconWrapper $iconSize={iconSize}>
                    <Icon
                        src={
                            variant === 'pdf'
                                ? download_pdf
                                : variant === 'sticker'
                                ? download_sticker
                                : download_image
                        }
                    />
                </IconWrapper>
                <Text isFlexColumn={isFlexColumn}>{children}</Text>
            </MainButton>
        </motion.div>
    )
}

export default QrCodeActionBtnWrapper
