import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'

import { api } from '../../../services'

import { IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    IPublicShareAPIRes,
    IUrlShortenerAPI_request_args,
    IUrlShortenerAPI_res,
} from 'ApiInterfaces'
import { IPublicShareSuccessPayloadRedux } from '../../garages/actions/actions'
import { ConvertPublicShares } from '../../../conversions/shares/convertPublicShares'
import { base_url } from '../../../services/urls'

export function* GetPublicGarageShareSaga(garageid: string) {
    try {
        let carPublicSharesAPIP: IPublicShareAPIRes = yield call(
            api.entities.getData.getGaragePublicSharesAPI,
            garageid
        )

        if (
            carPublicSharesAPIP &&
            carPublicSharesAPIP[0] &&
            carPublicSharesAPIP[0].link_identifier
        ) {
            let args: IUrlShortenerAPI_request_args = {
                url: `garage/${carPublicSharesAPIP[0].link_identifier}`,
            }

            let code_s: IUrlShortenerAPI_res = yield call(
                api.entities.updateCar.createUrlShortener,
                args
            )
            carPublicSharesAPIP = [carPublicSharesAPIP[0]]
            let convertedObj: IPublicShareSuccessPayloadRedux = ConvertPublicShares(
                {
                    id: garageid,
                    entity: 'garage',
                    api_payload: carPublicSharesAPIP,
                }
            )

            convertedObj.public_share.qr_code_url = `${base_url}/s/${code_s.code}`

            yield put(
                actions.actions.getPublicSharesGarageSuccess(convertedObj)
            )
        } else
            yield put(
                actions.actions.getPublicSharesGarageSuccess({
                    id: garageid,
                    public_share: {},
                })
            )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetPublicGarageShareSaga,
                payload: garageid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.getPublicSharesGarageError(typedError)
            )
            return
        }
    }
}

function* watcherGetPublicGarageShare() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.getPublicSharesGarageRequest
        )
        yield call(GetPublicGarageShareSaga, payload)
    }
}

const public_garage_shares_sagas: any[] = [fork(watcherGetPublicGarageShare)]

export default public_garage_shares_sagas
