import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'

import {
    ICustomErrorData,
    IGalleryImagesObject,
    IHighlightedFactsObject,
    IReduxError,
    ITechnicalInformationObject,
} from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'

import { IShowroomEntry, IShowroomEntryAPI } from '../types'
import { showroomActions } from '../reducer'
import { ConvertShowroomEntryApiToState } from '../../conversions/showroom/entry'
import { ICarPayload } from 'IapiDataPayload'
import {
    convertEntryShowroomGalleryToGalleryImagesState,
    convertToGalleryImage,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../conversions/entities/conversionFromAPI'

import * as galleryActions from '../../entities/galleries/actions/index'
import * as highlighted_facts_actions from '../../entities/highlighted_facts/actions/index'
import * as tech_info_actions from '../../entities/technical_information/actions/index'

export type ICarGridInfo = {
    year?: {
        id: 'year'
        text: 'year'
        answer: number | string
    }
    make?: {
        id: 'make'
        text: 'make'
        answer: string
    }
    model?: {
        id: 'model'
        text: 'model'
        answer: string
    }

    engine_capacity?: {
        id: 'engine_capacity'
        text: 'engine size'
        answer: number | string
    }

    transmission?: {
        id: 'transmission'
        text: 'transmission'
        answer: string
    }

    drive_side?: {
        id: 'drive_side'
        text: 'drive side'
        answer: string
    }
}

export type I_SetCarExtraneousReduxData_Overview_payload = {
    entryID: string
    data: ICarGridInfo
}

export type I_SetCarEntryExtraneousReduxData_args = {
    car: ICarPayload
    entry?: IShowroomEntryAPI
    updateGridInfo?: I_SetCarExtraneousReduxData_Overview_payload
}

export function* SetCarEntryExtraneousReduxData(
    p: I_SetCarEntryExtraneousReduxData_args
): any {
    let highlightedFactsObject: IHighlightedFactsObject = {}
    let technicalInformationObject: ITechnicalInformationObject = {}

    if (p.entry) {
        let galleryImagesObject: IGalleryImagesObject = {}
        // TBD
        let gallery_images_object: IGalleryImagesObject =
            convertEntryShowroomGalleryToGalleryImagesState(p.entry)

        if (p.entry.banner_image) {
            gallery_images_object[p.entry.banner_image.uid] =
                convertToGalleryImage(p.entry.banner_image)
        }

        if (p.entry.cover_image) {
            gallery_images_object[p.entry.cover_image.uid] =
                convertToGalleryImage(p.entry.cover_image)
        }

        if (p.entry.featured_image) {
            gallery_images_object[p.entry.featured_image.uid] =
                convertToGalleryImage(p.entry.featured_image)
        }

        Object.assign(galleryImagesObject, gallery_images_object)

        yield put(
            galleryActions.actions.setGalleryImagesSuccess(galleryImagesObject)
        )
    }

    let highlighted_facts_object: IHighlightedFactsObject =
        convertToHighlightedFactsState(p.car)
    let technical_information_object: ITechnicalInformationObject =
        convertToTechnicalInformationState(p.car)

    Object.assign(highlightedFactsObject, highlighted_facts_object)
    Object.assign(technicalInformationObject, technical_information_object)

    yield put(
        highlighted_facts_actions.actions.setHighlightedFactsSuccess(
            highlightedFactsObject
        )
    )
    yield put(
        tech_info_actions.actions.setTechnicalInformationSuccess(
            technicalInformationObject
        )
    )

    if (p.updateGridInfo) {
        yield put(showroomActions.setShowroomCarGridInfo(p.updateGridInfo))
    }
}

export type IGetShowroomByEntry = {
    is_owner: boolean
    entryID: string
}

export function* GetShowroomEntryByID(payload: IGetShowroomByEntry): any {
    try {
        let res: IShowroomEntryAPI = yield call(
            api.showroom.getShowroomEntryByID,
            payload.entryID
        )
        let res2: IShowroomEntry = ConvertShowroomEntryApiToState(res)

        if (payload.is_owner) {
            yield put(showroomActions.getShowroomEntryByIDSuccess(res2))
            yield put(
                showroomActions.getPublicLogginInShowroomEntryByIDSuccess(res2)
            )
        } else {
            yield put(
                showroomActions.getPublicLogginInShowroomEntryByIDSuccess(res2)
            )
        }

        // update key moments and facts reducer, image reducer, tech information reducer
        if (res.car) {
            yield call(SetCarEntryExtraneousReduxData, {
                car: res.car,
                entry: res,
            })
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetShowroomEntryByID,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong.`,
                custom_user_action_text: 'Go to Showroom',
                custom_redirect_path: '/showroom',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.getShowroomEntryByIDError(customError))
        }
    }
}

export function* GetPublicShowroomEntryByID(payload: string): any {
    try {
        let res: IShowroomEntryAPI = yield call(
            api.showroom.getPublicShowroomEntryByID,
            payload
        )
        let res2: IShowroomEntry = ConvertShowroomEntryApiToState(res)
        yield put(showroomActions.getPublicShowroomEntryByIDSuccess(res2))

        // update key moments and facts reducer, image reducer, tech information reducer
        if (res.car) {
            yield call(SetCarEntryExtraneousReduxData, {
                car: res.car,
                entry: res,
            })
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetPublicShowroomEntryByID,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong.`,
                custom_user_action_text: 'Go to Showroom',
                custom_redirect_path: '/showroom',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(
                showroomActions.getPublicShowroomEntryByIDError(customError)
            )
        }
    }
}

function* watcherGetShowroomEntryByID() {
    while (true) {
        const { payload } = yield take(
            showroomActions.getShowroomEntryByIDRequest
        )

        yield call(GetShowroomEntryByID, payload)
    }
}

function* watcherGetPublicShowroomEntryByID() {
    while (true) {
        const { payload } = yield take(
            showroomActions.getPublicShowroomEntryByIDRequest
        )

        yield call(GetPublicShowroomEntryByID, payload)
    }
}

const get_showroom_entry_by_id: any[] = [
    fork(watcherGetShowroomEntryByID),
    fork(watcherGetPublicShowroomEntryByID),
]

export default get_showroom_entry_by_id
