import * as Yup from 'yup'

const EmailValidationSchema = Yup.object().shape({
    item_edited: Yup.string().email('Email not valid').max(70),
})

const SurnameFieldErrorString = Yup.object().shape({
    item_edited: Yup.string()
        .required('Required Field')
        .min(2, 'Too short')
        .max(30, 'Too long'),
})

const LastNameFieldErrorString = (id: string) =>
    Yup.object().shape({
        [id]: Yup.string()
            .required('Required Field')
            .min(2, 'Too short')
            .max(30, 'Too long'),
    })

const NameFieldErrorString = (item_edited: string) =>
    Yup.object().shape({
        [item_edited]: Yup.string()
            .required('Required Field')
            .min(2, 'Name is too short')
            .max(60, 'Name is too long'),
    })

const RequiredErrorString = (item: string) =>
    Yup.object().shape({
        [item]: Yup.string().required('Required Field'),
    })

const FieldErrorString = Yup.object().shape({
    item_edited: Yup.string()
        .required('Required Field')
        .min(1, 'Too short')
        .max(30, 'Too long'),
})

const NumberFieldError = (id: string) =>
    Yup.object().shape({
        [id]: Yup.number().min(0, 'Please add a valid number.'),
    })

export const getRightSchemaAccountData = (item_id: string) => {
    if (item_id === 'email') {
        return EmailValidationSchema
    }
    if (item_id === 'family_name') {
        return SurnameFieldErrorString
    }
    if (item_id === 'last_name') {
        return LastNameFieldErrorString(item_id)
    }
    if (item_id === 'name') {
        return NameFieldErrorString(item_id)
    }
    if (item_id === 'mileage' || item_id === 'engine_size') {
        return NumberFieldError(item_id)
    }
    if (
        item_id === 'phone' ||
        item_id === 'street_number' ||
        item_id === 'route' ||
        item_id === 'postal_code' ||
        item_id === 'postal_town' ||
        item_id === 'street_1' ||
        item_id === 'street_2' ||
        item_id === 'locality' ||
        item_id === 'postcode' ||
        item_id === 'country'
    ) {
        return RequiredErrorString(item_id)
    }
    return FieldErrorString
}
