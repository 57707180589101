import React from 'react'
import styled from 'styled-components'
import Sheet from 'react-modal-sheet'
import ScrollLock from 'react-scrolllock'
import { device } from '../../../templates/displays/devices'
import {
    IMenuItem,
    MenuItem,
} from '../../../molecules/cards/insurance/driversCard'
import useThemes from '../../../../providers/theme/hooks'

type IStyledProps = {
    color?: string
    $customHeight?: string
    $ipadCustomHeight?: string
    $isSelected?: boolean
}

const CustomSheet = styled(Sheet)<IStyledProps>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '70vh !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;

        @media ${device.ipad} {
            height: ${(props) =>
                props.$ipadCustomHeight
                    ? `${props.$ipadCustomHeight} !important`
                    : props.$customHeight
                    ? `${props.$customHeight} !important`
                    : '70vh !important'};
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .react-modal-sheet-header {
        height: 30px !important;
        align-items: flex-start !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
        margin-top: 10px;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`

interface IProps {
    toggle: any
    isOpen: boolean
    menu_items?: IMenuItem[] | null | undefined
    customHeight?: string
    ipadCustomHeight?: string
}

const MobileMenuBottomSheet: React.FunctionComponent<IProps> = (props) => {
    const { isOpen, toggle, menu_items, customHeight, ipadCustomHeight } = props
    const { theme } = useThemes()

    let mappedDropdownData =
        menu_items &&
        menu_items.map((item: IMenuItem, index: number) => {
            if (item) {
                return (
                    <React.Fragment
                        key={`bottomsheet_2_menu_${item.id}_${index}_mobile`}
                    >
                        <MenuItem
                            item={item}
                            toggleMenu={toggle}
                            theme={theme}
                        />
                    </React.Fragment>
                )
            } else {
                return null
            }
        })

    return (
        <React.Fragment>
            {isOpen && <ScrollLock />}
            <CustomSheet
                isOpen={isOpen}
                onClose={toggle}
                $customHeight={customHeight}
                $ipadCustomHeight={ipadCustomHeight}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>{mappedDropdownData}</Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop
                    onTap={(e: any) => {
                        e.preventDefault()
                        setTimeout(function () {
                            toggle()
                        }, 50)
                    }}
                />
            </CustomSheet>
        </React.Fragment>
    )
}
export default MobileMenuBottomSheet
