import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const SwitchSVG = () => {
    const { theme } = useThemes()
    return (
        <svg
            width="82"
            height="30"
            viewBox="0 0 82 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.108093"
                y="0.285156"
                width="80.8784"
                height="27.7297"
                rx="13.8649"
                fill={colours[theme].modal_side_sheet_pop_over}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.0059 9.09766H21.3386V13.4304H17.0059V9.09766ZM17.8724 9.96421V12.5639H20.4721V9.96421H17.8724Z"
                fill={colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.0059 14.875H21.3386V19.2078H17.0059V14.875ZM17.8724 15.7416V18.3412H20.4721V15.7416H17.8724Z"
                fill={colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.7829 9.09766H27.1157V13.4304H22.7829V9.09766ZM23.6495 9.96421V12.5639H26.2491V9.96421H23.6495Z"
                fill={colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.7829 14.875H27.1157V19.2078H22.7829V14.875ZM23.6495 15.7416V18.3412H26.2491V15.7416H23.6495Z"
                fill={colours[theme].text_darker}
            />
            <g filter="url(#filter0_d_2681_139653)">
                <rect
                    x="38.2365"
                    y="1.44141"
                    width="41.5946"
                    height="25.4189"
                    rx="12.7095"
                    fill={colours[theme].background_default}
                />
                <path
                    d="M54.4122 13.7148H63.6555V14.5814H54.4122V13.7148Z"
                    fill={colours[theme].primary}
                />
                <path
                    d="M54.4122 10.5391H63.6555V11.4056H54.4122V10.5391Z"
                    fill={colours[theme].primary}
                />
                <path
                    d="M54.4122 16.8945H63.6555V17.7611H54.4122V16.8945Z"
                    fill={colours[theme].primary}
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2681_139653"
                    x="36.2365"
                    y="0.441406"
                    width="45.5946"
                    height="29.418"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2681_139653"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2681_139653"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default SwitchSVG
