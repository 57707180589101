import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
// import unchecked_checkbox_green from '../../../public/assets/icons/unchecked_checkbox_green.svg'
// import checked_checkbox_green from '../../../public/assets/icons/checked_checkbox_green.svg'

export interface ICustomAnimatedRadioFieldProps {
    onChange?: any
    name?: any
    id?: string | number
    checked?: boolean
    onClick?: any
    isDisabled?: boolean
    textchangedisabled?: boolean
    width?: string
    small?: boolean
    extra_small?: boolean
    labelcolor?: string
    labelfontsize?: string
    labelfontstyle?: string
    hasWhiteBg?: boolean
    rowWidth?: string
    rowHeight?: string
}

interface IStyledProps {
    checked?: boolean
    isDisabled?: boolean
    textchangedisabled?: boolean
    width?: string
    extra_small?: boolean
    small?: boolean
    labelcolor?: string
    labelfontsize?: string
    labelfontstyle?: string
    hasWhiteBg?: boolean
}

const CheckboxInput = styled.label<IStyledProps>`
    display: flex;
    align-items: ${(props) => (props.extra_small ? 'start' : 'center')};
    /* height: 30px; */
    transform: translateY(3px);
    ${(props) => props.width && `width: ${props.width};`}
    transform: ${(props) =>
        props.extra_small
            ? 'translateY(10px)'
            : props.small
            ? 'translateY(1px)'
            : 'translateY(3px)'};

    cursor: ${(props: IStyledProps) =>
        props.isDisabled ? 'not-allowed' : 'pointer'};
    line-height: 21px;

    @media ${device.mobile} {
        font-family: Lato;
        font-size: 16px;
        line-height: 17px;
        display: flex;
        align-self: flex-start;

        // careful - would fuck up register form. removed it but
        // maybe was there for a reason:
        // padding-left: 35px;
    }

    input {
        cursor: ${(props: IStyledProps) =>
            props.isDisabled ? 'not-allowed' : 'pointer'};
    }

    input[type='checkbox'] {
        display: none;
    }

    .custom-checkbox-square {
        cursor: ${(props: IStyledProps) =>
            props.isDisabled ? 'not-allowed' : 'pointer'};
        display: inline-block;
        ${(props) => props.isDisabled && 'stroke: #B9B9B9;'}/* height: 30px;
        ${(props) => props.isDisabled && 'stroke: #B9B9B9;'}/* height: 30px;
        width: 30px;
        padding-right:  ${(props) => (props.extra_small ? '0px' : '10px')};
        
        */
        /* background-color: #ffffff; */
        /* animation: tick 200ms ease-in; */
        /* :hover {
        background-color: #ccc;
    } */
    }

    /* input:checked + */
    .ripple-square {
        transform-origin: center;

        ${(props) =>
            props.checked === true && `animation: ripple 300ms ease-in-out;`}
    }

    .custom-checkbox-checked {
        transform-origin: center;
        transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
        ${(props) =>
            (props.checked === false || props.isDisabled) &&
            `transform: scale(0);`}
    }

    /* input:checked + */
    .custom-checkbox-checked {
        transform-origin: center;
        transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);

        ${(props) => props.checked && `transform: scale(0.8);`}
    }

    .checkbox-svg {
        border-radius: 5px;
        ${(props) =>
            props.hasWhiteBg === true &&
            'background-color: var(--bg-color, #fff);'}
        ${(props) =>
            props.checked === true &&
            `animation: tick 200ms ease-in-out;`}/* transform: scale(1.2);
        transition: ease-in-out 200ms; */

    @media ${device.mobile} {
            border-radius: ${(props: IStyledProps) =>
                !props.extra_small ? '10px' : 'none'};
        }
    }

    .label-text {
        margin-left: ${(props) => (props.extra_small ? '0px' : '10px')};

        transition: all 200ms;
        color: ${(props) =>
            props.labelcolor
                ? props.labelcolor
                : 'var(--text-darker, #616161)'};
        font-size: ${(props) =>
            props.labelfontsize ? props.labelfontsize : 'inherit'};
        font-style: ${(props) =>
            props.labelfontstyle ? props.labelfontstyle : 'inherit'};

        user-select: none; /* supported by Chrome and Opera */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
    }

    @media ${device.mobile} {
        transform: translateY(1px);
    }

    /* input:checked ~ */
    .label-text {
        ${(props) =>
            props.checked === true &&
            !props.textchangedisabled &&
            `color: var(--primary, #5ec3ca); 
            transition: all 200ms;`}

        ${(props) => props.isDisabled === true && `color: var(--text-muted);`}
    }

    @keyframes tick {
        0% {
            transform: scale(0.8);
        }

        50% {
            transform: scale(0.9);
        }

        100% {
            transform: scale(0.8);
        }
    }

    @keyframes ripple {
        0% {
            transform: scale(0);
            border-radius: 100;
        }

        100% {
            transform: scale(1.9);
            fill: rgba(0, 169, 175, 0.1);
        }

        /* 100% {
            transform: scale(1);
        } */
    }

    .checkbox-svg {
        cursor: ${(props: IStyledProps) =>
            props.isDisabled ? 'not-allowed' : 'pointer'};
    }
`

class CustomAnimatedRadio extends React.Component<
    ICustomAnimatedRadioFieldProps,
    {}
> {
    render() {
        const {
            children,
            small,
            labelcolor,
            labelfontsize,
            labelfontstyle,
            extra_small,
            hasWhiteBg,
        } = this.props

        return (
            <React.Fragment>
                <CheckboxInput
                    checked={this.props.checked}
                    isDisabled={this.props.isDisabled}
                    textchangedisabled={this.props.textchangedisabled}
                    width={this.props.width}
                    labelcolor={labelcolor}
                    labelfontsize={labelfontsize}
                    labelfontstyle={labelfontstyle}
                    hasWhiteBg={hasWhiteBg}
                    extra_small={extra_small}
                >
                    <input
                        type="checkbox"
                        name="name"
                        onChange={this.props.onChange}
                        onClick={this.props.onClick}
                    />
                    {/* <img className="custom-checkbox-square" src={unchecked_checkbox_green} /> */}
                    <div>
                        <svg
                            className="checkbox-svg"
                            width={extra_small ? '18' : '25'}
                            height={extra_small ? '18' : '25'}
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect
                                className="custom-checkbox-square"
                                x={small ? '1.5' : '0.5'}
                                y={small ? '1.5' : '0.5'}
                                width={small ? '22' : '24'}
                                height={small ? '22' : '24'}
                                rx="10"
                                stroke="#5EC3CA"
                            />
                            <rect
                                className="ripple-square"
                                x={small ? '1' : '0.5'}
                                y={small ? '1' : '0.5'}
                                width={small ? '22' : '24'}
                                height={small ? '22' : '24'}
                                rx="10"
                                stroke="none"
                            />
                            <rect
                                className="custom-checkbox-checked"
                                x={small ? '5' : '4'}
                                y={small ? '5' : '4'}
                                width={small ? '15' : '17'}
                                height={small ? '15' : '17'}
                                rx="10"
                                fill="#5EC3CA"
                            />
                            {/* <path
                                className="custom-checkbox-checked"
                                d="M10.6667 16.625L7 12.875L7.74776 12.1103L10.6667 14.375L17.2667 8.375L18 9.125"
                                fill="white"
                            /> */}
                        </svg>
                    </div>
                    <span className="label-text">{children}</span>
                    {/* <img className="custom-checkbox-checked" src={checked_checkbox_green} /> */}
                </CheckboxInput>
            </React.Fragment>
        )
    }
}

export default CustomAnimatedRadio
