import { call, fork, put, take } from 'redux-saga/effects'
import { updateUserTimeZone } from '../../../user/actions/actions'

import { api } from '../../../services'
import { ICustomErrorData, IKeyValue, IList, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'

export function* setTimeZonesDropdownDataApi() {
    try {
        const dataReceived: string[] =
            yield api.dropdownsData.get_time_zone_dropdown_api()

        const cleanUpName = (name: string) => {
            if (name.includes('_')) {
                return name.replace(/_/g, ' ')
            } else return name
        }

        let convertedDataForDropdown: IKeyValue = {}

        const convertedDataForUserObject: { [key: string]: IList } = {}
        let cleanedUpDataReceived: string[] = []

        for (let index in dataReceived) {
            let item = dataReceived[index]
            convertedDataForDropdown[cleanUpName(item)] = item
        }

        yield put(
            dropDownActions.setTimeZoneDropdownDataSuccess({
                data: convertedDataForDropdown,
                idsList: Object.keys(convertedDataForDropdown),
            })
        )

        yield put(
            updateUserTimeZone({
                data: convertedDataForUserObject,
                idsList: cleanedUpDataReceived,
            })
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setTimeZonesDropdownDataApi,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_user_action_text: 'Check your connection and refresh',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            yield put(dropDownActions.setTimeZoneDropdownDataError(customError))
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            yield put(dropDownActions.setTimeZoneDropdownDataError(typedError))
        }
    }
}
function* watcherSetTimezoneDropdownData() {
    while (true) {
        yield take(dropDownActions.setTimeZoneDropdownDataRequest)
        yield call(setTimeZonesDropdownDataApi)
    }
}

const set_time_zones_dropdown_data: any[] = [
    fork(watcherSetTimezoneDropdownData),
]
export default set_time_zones_dropdown_data
