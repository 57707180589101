import { RouteComponentProps, useParams, withRouter } from 'react-router'
import { showroomActions } from '../../../redux/showroom/reducer'
import ShowroomEntryBasicInfoManagerDesktop, {
    IShowroomEntryBasicDataForm,
} from '../showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IShowroomEntry } from '../../../redux/showroom/types'
import { IUpdateCarGeneralPayloadReq } from 'entityModels'

import * as carActions from '../../../redux/entities/cars/actions/loadingActions'

type Props = {
    onCancel: any
}

type EntryParams = {
    entryid: string
}

const EditShowroomEntryBasicInfoFormDesktop = (
    props: Props & RouteComponentProps
) => {
    const { entryid } = useParams<EntryParams>()

    let userShowroomEntriesData = useAppSelector((state) => {
        return state.showroom.userShowroomEntries
    })

    let currentEntry: IShowroomEntry | undefined =
        entryid && userShowroomEntriesData && userShowroomEntriesData[entryid]
            ? userShowroomEntriesData[entryid]
            : undefined

    let carid = currentEntry?.car?.id

    const dispatch = useAppDispatch()

    const submit = (formData: IShowroomEntryBasicDataForm) => {
        let carPayload: IUpdateCarGeneralPayloadReq | undefined = carid
            ? {
                  car_id: carid,
                  dataListForm: [
                      {
                          id: 'title',
                          text: 'title',
                          answer: formData.car_title,
                      },
                      {
                          id: 'short_description',
                          text: 'title',
                          answer: formData.vehicle_bio,
                      },
                  ],
              }
            : undefined
        carPayload &&
            dispatch(carActions.writeCarDataGeneralRequest(carPayload))
        carid &&
            dispatch(
                showroomActions.updateEntryShowroomRequest({
                    uid: entryid,
                    car_id: carid,
                    data: {
                        type_of_sale: formData.poa
                            ? 'PRICE_ON_ASKING'
                            : formData.set_price
                            ? 'SET_PRICE'
                            : 'PRICE_RANGE',
                        price_min:
                            formData.set_price && formData.set_price.value
                                ? formData.set_price.value
                                : formData.price_range?.valueFrom,
                        price_max:
                            formData.set_price && formData.set_price.value
                                ? formData.set_price.value
                                : formData.price_range?.valueTo,
                        currency:
                            formData.price_range &&
                            formData.price_range.currency &&
                            (formData.price_range.currency === 'USD' ||
                                formData.price_range.currency === 'EUR' ||
                                formData.price_range.currency === 'GBP')
                                ? formData.price_range.currency
                                : formData.set_price &&
                                  formData.set_price.currency &&
                                  (formData.set_price.currency === 'USD' ||
                                      formData.set_price.currency === 'EUR' ||
                                      formData.set_price.currency === 'GBP')
                                ? formData.set_price.currency
                                : 'GBP',
                        open_to_offers: formData.set_price
                            ? formData.set_price.open_to_offer
                            : undefined,
                    },
                })
            )
        setTimeout(() => {
            dispatch(
                showroomActions.validateEntryForPublicationRequest(entryid)
            )
        }, 150)

        props.onCancel()
    }

    return (
        <ShowroomEntryBasicInfoManagerDesktop
            onSubmit={submit}
            onCancel={props.onCancel}
            isEditEntry
            showroomEntry={currentEntry}
        />
    )
}

export default withRouter(EditShowroomEntryBasicInfoFormDesktop)
