import { IExternalURLApi } from 'IapiDataPayload'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const updateExternalURL = (data: IExternalURLApi): Promise<any> => {
    let rqp: string = JSON.stringify(data)
    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: rqp,
        credentials: 'include',
    }

    return fetch(
        urls.external_url_update_endpoint(data.uid),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: IExternalURLApi) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Update external url error',
                        rqp
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
