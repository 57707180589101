import { ICar, IExternalCar, IGalleryImagesObject } from 'entityModels'
import { IUser } from 'myModels'
import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ITimelineDataByCarNormalised, ITimelineItem } from 'timelineModels'
import { useAppSelector } from '../../../../redux/store/hooks'
import SectionHeader from '../../../atoms/header/section/sectionHeader'
import CarOverviewBanner from '../../../atoms/image/carOverviewBanner'
import FullWidthMobile from '../../../atoms/image/fullWidthMobile'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import { DescriptionMobile, Paragraph } from '../../../atoms/typography'
import OneLiner from '../../../atoms/typography/oneLiner'
import TitleSimpleBoldMobile from '../../../atoms/typography/titleSimpleBoldMobile'
import CarUserAndTitleBox from '../../../atoms/user/carUserAndTitleBox'
import CarKeyMomentsfactsDesktopV2 from '../../../molecules/car/carKeyMomentsFactsDesktopV2'
import OverviewGridManagerDesktop from '../../../molecules/car/OverviewGridManagerDesktop'
import GalleryOverviewDataManagerDesktop from '../../../organisms/galleryOverviewManager/desktop/galleryOverviewDataManagerDesktop'
import GalleryOverviewDataManagerMobile from '../../../organisms/galleryOverviewManager/mobile/galleryOverviewDataManagerMobile'
import TimelineOverviewManagerDesktop from '../../../organisms/timelineOverviewManager.tsx/timelineOverviewManagerDesktop'
import {
    FirstSectionCol1,
    FirstSectionWrap,
    GridSeparation,
    IpadWrapPadding,
    KeyMomNFactsHeaderWrapper,
    SectionsWrap,
} from '../../../pages/car/carOverview'
import Faded from '../../animated/faded'
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import { device } from '../../displays/devices'
import IpadAndMobileDisplay from '../../displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../displays/pageWrappers/CenteredPageWrapper'
import MobileContentBody from '../../displays/pageWrappers/MobileContentBody'
import FabsMenusMobile from '../../menus/fabsMenuMobile'
import OverviewGridManagerMobile from '../../../molecules/car/carOverviewGridManagerMobile'
import CarKeyMomentsFactsMobile from '../../../molecules/car/CarKeyMomentsFactsMobile'
import TimelineOverviewManagerMobile from '../../../organisms/timelineOverviewManager.tsx/timelineOverviewManagerMobile'
import SimpleDescriptionParagraph from '../../../atoms/typography/simpleDescriptionParagraph'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

const AdjustedPaddingTop = styled.div`
    padding-top: 45px;
    @media ${device.ipad} {
        padding-top: 73px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

type Props = {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    userLoggedIn: IUser
}
const ReadOnlyCar = (props: Props) => {
    let { sharedCar, userLoggedIn, prevOwned } = props

    let current: IExternalCar | ICar | undefined = sharedCar
        ? sharedCar
        : prevOwned

    let isOwnedCar: boolean = prevOwned ? true : false
    let isSharedCar: boolean = sharedCar ? true : false

    const default_fuel_unit_user: string | null =
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].user_choice_id

    const default_fuel_unit_user_text: string | null | undefined =
        default_fuel_unit_user &&
        userLoggedIn &&
        userLoggedIn.preferences.data['units'].data['fuel_unit'].data[
            default_fuel_unit_user
        ].short

    let isShortDescriptionLong =
        current &&
        (!current.short_description ||
            current.short_description === '' ||
            (current.short_description &&
                current.short_description.length > 180))
            ? true
            : false

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })
    let carsTimelineData: ITimelineDataByCarNormalised | null | undefined =
        useAppSelector((state) => {
            return state.timeline.timeline_data_normalised_by_car_id
        })

    let all_entries: ITimelineItem[] | null | undefined =
        current &&
        carsTimelineData &&
        current.id &&
        carsTimelineData[current.id]
            ? carsTimelineData[current.id].entries
            : []

    let attachmentsObj = useAppSelector((state) => {
        return state.attachments.attachmentsById
    })

    let history = useHistory()

    let userCurrency =
        userLoggedIn.preferences.data.units.data.default_currency.user_choice_id

    let highlighted_facts_ids = current ? current.highlighted_facts : []

    let highlightedFactsData = useAppSelector((state) => {
        return state.entities.highlightedFactsData.highlighted_facts
    })

    let noKMContent =
        highlighted_facts_ids.filter(
            (id) => highlightedFactsData[id].text.length === 0
        ).length === 5

    let user_profile_pic =
        !isOwnedCar && sharedCar && sharedCar.owner?.profile_picture?.url
            ? sharedCar.owner?.profile_picture?.url
            : userLoggedIn.profile_image_url

    let user_display_name =
        !isOwnedCar && sharedCar && sharedCar.owner?.display_name
            ? sharedCar.owner?.display_name
            : userLoggedIn.display_name

    let linkPrefix =
        history.location.pathname &&
        history.location.pathname.includes('overview')
            ? history.location.pathname.replace('/overview', '')
            : history.location.pathname

    let hasNoDescription =
        !current ||
        (current && current.description.length < 1) ||
        current.description === '<p><br></p>'
            ? true
            : false

    const { theme } = useThemes()

    return (
        <React.Fragment>
            <FabsMenusMobile hasAdd={false} hasSubNav={true} />
            {current !== null &&
                current !== undefined &&
                default_fuel_unit_user_text && (
                    <>
                        <DesktopDisplayOnly>
                            {/* DESKTOP BANNER SECTION */}
                            <CenteredPageWrapper removePaddings={true}>
                                <SectionsWrap>
                                    <div
                                        style={{
                                            paddingTop: sharedCar
                                                ? '145px'
                                                : '90px',
                                        }}
                                    />
                                    <div
                                        data-tut="second-step-car-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <CarOverviewBanner
                                            img={
                                                current.banner_url
                                                    ? current.banner_url
                                                    : undefined
                                            }
                                            hasSvgBackgroundImage
                                            readOnlyMode
                                        />
                                    </div>

                                    {/* DESKTOP INTRO SECTION */}
                                    <FirstSectionWrap>
                                        <FirstSectionCol1>
                                            <CarUserAndTitleBox
                                                url={user_profile_pic}
                                                name={user_display_name}
                                                car_title={current.title}
                                                readonlyMode
                                            />
                                            <OneLiner italic={false}>
                                                {current.short_description &&
                                                    current.short_description
                                                        .length > 1 &&
                                                    current.short_description}
                                            </OneLiner>
                                        </FirstSectionCol1>
                                        <GridSeparation>
                                            <OverviewGridManagerDesktop
                                                ids={
                                                    current.overview &&
                                                    current.overview.list
                                                        ? current.overview.list
                                                        : [
                                                              'make',
                                                              'model',
                                                              'year',
                                                              'engine_capacity',
                                                              'transmission',
                                                              'drive_side',
                                                          ]
                                                }
                                                data={
                                                    current &&
                                                    current.overview &&
                                                    current.overview.data
                                                }
                                                carid={current.id}
                                                fuel_unit_text={
                                                    default_fuel_unit_user_text
                                                        ? default_fuel_unit_user_text
                                                        : '-'
                                                }
                                                fuel_unit={
                                                    default_fuel_unit_user
                                                        ? default_fuel_unit_user
                                                        : '-'
                                                }
                                                isShortDescriptionLong={
                                                    isShortDescriptionLong
                                                }
                                                readOnlyMode
                                            />
                                        </GridSeparation>
                                    </FirstSectionWrap>
                                    {/* DESKTOP GALLERY SECTION */}
                                </SectionsWrap>
                            </CenteredPageWrapper>

                            <div
                                style={{
                                    backgroundColor:
                                        colours[theme]
                                            .background_neutral_subtle,
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <CenteredPageWrapper removePaddings={true}>
                                    <div
                                        data-tut="gallery-overview-car-tuto-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <SectionsWrap>
                                            <div
                                                style={{
                                                    paddingTop: '32px',
                                                }}
                                            />
                                            <SectionHeader
                                                title=""
                                                removeEdit
                                                customPadding="0px"
                                            />

                                            <div
                                                style={{
                                                    paddingTop: '32px',
                                                }}
                                            />

                                            <GalleryOverviewDataManagerDesktop
                                                overview_images_ids={
                                                    current.gallery.overview
                                                }
                                                cover_id={current.gallery.cover}
                                                images_ids={
                                                    current.gallery.images
                                                }
                                                carid={current.id}
                                                readOnlyMode
                                                images_data={images_data}
                                                isNecessaryGalleryDataLoading={
                                                    false
                                                }
                                            />

                                            <div
                                                style={{
                                                    paddingTop: '104px',
                                                }}
                                            />
                                        </SectionsWrap>
                                    </div>
                                </CenteredPageWrapper>
                            </div>

                            {/* DESKTOP HIGHLIGHTS SECTION */}
                            <CenteredPageWrapper
                                removePaddings={true}
                                removeHorizPaddings
                            >
                                <SectionsWrap>
                                    <div
                                        data-tut="key-moments-car-tuto-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <KeyMomNFactsHeaderWrapper>
                                            <SectionHeader
                                                sectionPadding={true}
                                                title="Key Moments & Facts"
                                                removeEdit={true}
                                            />
                                        </KeyMomNFactsHeaderWrapper>
                                        <CarKeyMomentsfactsDesktopV2
                                            highlighted_facts_ids={
                                                current.highlighted_facts
                                            }
                                            readOnlyMode
                                        />
                                    </div>
                                </SectionsWrap>
                            </CenteredPageWrapper>

                            <div style={{ paddingTop: '120px' }} />

                            <div
                                style={{
                                    backgroundColor:
                                        colours[theme]
                                            .background_neutral_subtle,
                                    width: '100%',
                                }}
                            >
                                <CenteredPageWrapper removePaddings={true}>
                                    <SectionsWrap>
                                        <div
                                            style={{
                                                maxWidth: !hasNoDescription
                                                    ? '900px'
                                                    : '100%',
                                                paddingRight: '100px',
                                                paddingLeft: '100px',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingTop: '80px',
                                                }}
                                            />

                                            <SectionHeader
                                                removeEdit
                                                sectionPadding={false}
                                                title="description"
                                                customPadding={'0px'}
                                            />
                                            <div
                                                style={{
                                                    paddingTop: '32px',
                                                }}
                                            />
                                            <SimpleDescriptionParagraph
                                                description={
                                                    current.description
                                                }
                                                // onClick={() => {}}
                                            />

                                            <div
                                                style={{
                                                    paddingTop: '80px',
                                                }}
                                            />
                                        </div>
                                    </SectionsWrap>
                                </CenteredPageWrapper>
                            </div>

                            <div style={{ paddingTop: '50px' }} />

                            <CenteredPageWrapper removePaddings={true}>
                                <SectionsWrap>
                                    <div
                                        data-tut="timeline-overview-car-tuto-desktop"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <SectionHeader
                                            title="History File"
                                            removeEdit
                                        />

                                        <TimelineOverviewManagerDesktop
                                            entries_array={all_entries}
                                            car_entries_count={
                                                current.car_entries_count
                                                    ? current.car_entries_count
                                                    : 0
                                            }
                                            attachmentsObj={attachmentsObj}
                                            // costsObj={costsObj}
                                            carid={current.id}
                                            onPlaceholderClick={() =>
                                                history.push(
                                                    `${linkPrefix}/history-file`
                                                )
                                            }
                                            userCurrency={userCurrency}
                                            readOnlyMode
                                            sharedWithYouCar={sharedCar}
                                            isOwnedCar={prevOwned}
                                        />
                                    </div>
                                </SectionsWrap>
                            </CenteredPageWrapper>

                            <div style={{ paddingTop: '300px' }} />
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <CenteredPageWrapper>
                                <AdjustedPaddingTop />
                                <Faded>
                                    {current.banner_url ? (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <FullWidthMobile
                                                img={current.banner_url}
                                                height="160px"
                                            />
                                        </div>
                                    ) : (
                                        <NoDataRectangle text="no banner" />
                                    )}

                                    {/* // IF TITLE AND NO DESC */}
                                    {current &&
                                        (!current.short_description ||
                                            (current.short_description &&
                                                current.short_description
                                                    .length < 2)) &&
                                        current.title.length > 1 && (
                                            <MobileContentBody>
                                                <TitleSimpleBoldMobile>
                                                    {current.title}
                                                </TitleSimpleBoldMobile>
                                                <div
                                                    style={{
                                                        paddingTop: 16,
                                                    }}
                                                />
                                                <CarUserAndTitleBox
                                                    url={user_profile_pic}
                                                    name={user_display_name}
                                                    car_title={current.title}
                                                    isMobile
                                                    readonlyMode
                                                />
                                            </MobileContentBody>
                                        )}

                                    {/* // IF TITLE AND DESC */}
                                    {current &&
                                        current.short_description &&
                                        current.short_description.length > 2 &&
                                        current.title.length > 1 && (
                                            <MobileContentBody>
                                                <IpadWrapPadding>
                                                    {current.title.length >
                                                        1 && (
                                                        <TitleSimpleBoldMobile>
                                                            {current.title}
                                                        </TitleSimpleBoldMobile>
                                                    )}
                                                    <div
                                                        style={{
                                                            paddingTop: 8,
                                                        }}
                                                    />

                                                    <CarUserAndTitleBox
                                                        url={user_profile_pic}
                                                        name={user_display_name}
                                                        car_title={
                                                            current.title
                                                        }
                                                        isMobile
                                                        readonlyMode
                                                    />

                                                    {current.short_description &&
                                                        current
                                                            .short_description
                                                            .length > 2 && (
                                                            <>
                                                                <div
                                                                    style={{
                                                                        paddingTop:
                                                                            '10px',
                                                                    }}
                                                                />

                                                                <Paragraph
                                                                    ipadAlign="left"
                                                                    isSmaller={
                                                                        true
                                                                    }
                                                                    align="left"
                                                                    removeIpadPadding={
                                                                        true
                                                                    }
                                                                >
                                                                    {
                                                                        current.short_description
                                                                    }
                                                                </Paragraph>
                                                            </>
                                                        )}
                                                </IpadWrapPadding>
                                            </MobileContentBody>
                                        )}

                                    <div
                                        style={{
                                            paddingTop: '40px',
                                        }}
                                    />

                                    <div
                                        data-tut="gallery-overview-car-tuto-mobile"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundColor:
                                                    colours[theme]
                                                        .background_neutral_subtle,
                                                width: '100%',
                                                display: 'flex',
                                                paddingTop: '24px',
                                                paddingBottom: '50px',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <MobileContentBody>
                                                <SectionHeader
                                                    title="Gallery"
                                                    removeEdit={true}
                                                    customPadding={'0px'}
                                                />
                                                <div
                                                    style={{
                                                        paddingTop: 24,
                                                    }}
                                                />
                                            </MobileContentBody>
                                            <GalleryOverviewDataManagerMobile
                                                overview_images_ids={
                                                    current.gallery.overview
                                                }
                                                images_data={images_data}
                                                isNecessaryGalleryDataLoading={
                                                    false
                                                }
                                                cover_id={current.gallery.cover}
                                                images_ids={
                                                    current.gallery.images
                                                }
                                                carid={current.id}
                                                readOnlyMode
                                            />
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            paddingTop: '40px',
                                        }}
                                    />

                                    <MobileContentBody>
                                        <OverviewGridManagerMobile
                                            carid={current.id}
                                            ids={
                                                current.overview &&
                                                current.overview.list
                                                    ? current.overview.list
                                                    : [
                                                          'year',
                                                          'make',
                                                          'model',
                                                          'engine_capacity',
                                                          'transmission',
                                                          'drive_side',
                                                      ]
                                            }
                                            data={
                                                current &&
                                                current.overview &&
                                                current.overview.data
                                            }
                                            fuel_unit_text={
                                                default_fuel_unit_user_text
                                                    ? default_fuel_unit_user_text
                                                    : ''
                                            }
                                            fuel_unit={
                                                default_fuel_unit_user
                                                    ? default_fuel_unit_user
                                                    : ''
                                            }
                                            readonlyMode
                                        />

                                        <div
                                            style={{
                                                paddingTop: '30px',
                                            }}
                                        />

                                        <SectionHeader
                                            removeEdit
                                            title="Key Moments & Facts"
                                            customPadding="20px"
                                        />

                                        {current.highlighted_facts &&
                                        !noKMContent ? (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            >
                                                <CarKeyMomentsFactsMobile
                                                    highlighted_facts_ids={
                                                        current.highlighted_facts
                                                    }
                                                    readOnlyMode
                                                />
                                            </div>
                                        ) : (
                                            <NoDataRectangle text="no data" />
                                        )}

                                        <div
                                            style={{
                                                paddingTop: '40px',
                                            }}
                                        />

                                        <SectionHeader
                                            removeEdit
                                            title="description"
                                            customPadding={'0px'}
                                        />

                                        <div
                                            style={{
                                                paddingTop: '30px',
                                            }}
                                        />

                                        <DescriptionMobile
                                            description={
                                                current.description
                                                    ? current.description
                                                    : ''
                                            }
                                            readOnlyMode
                                        />
                                    </MobileContentBody>
                                    <div
                                        data-tut="timeline-overview-car-tuto-mobile"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            transform: 'translateY(0px)',
                                        }}
                                    >
                                        <MobileContentBody>
                                            <SectionHeader
                                                title="History File"
                                                removeEdit
                                            />
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <TimelineOverviewManagerMobile
                                                    entries_array={all_entries}
                                                    car_entries_count={
                                                        current.car_entries_count
                                                            ? current.car_entries_count
                                                            : 0
                                                    }
                                                    carid={current.id}
                                                    onPlaceholderClick={() => {}}
                                                    userCurrency={userCurrency}
                                                    readOnlyMode
                                                    sharedWithYouCar={
                                                        isSharedCar
                                                            ? sharedCar
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    paddingBottom: '250px',
                                                }}
                                            />
                                        </MobileContentBody>
                                    </div>
                                </Faded>
                            </CenteredPageWrapper>
                        </IpadAndMobileDisplay>
                    </>
                )}
        </React.Fragment>
    )
}

export default ReadOnlyCar
