import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import AddBoxTextInfo from '../typography/addBoxTextInfo'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import NoDataRectangle from '../noData/noDataRectangle'
import { useState } from 'react'
import AddIcon from '../statefulicons/addIcon'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'

type IProps = {
    onClickAdd: any
    hasSvgBackgroundImage?: boolean
    height?: string
    readOnlyMode?: boolean
    noDataText?: string
    hooverText?: string
    customPlaceholderImg?: string
    isDisabled?: boolean
    hasAddTxtIcon?: boolean
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
`

const GoToBox = styled.div<{
    $hasSvgBackgroundImage?: boolean
    $customPlaceholderImg?: string
    $theme: ITheme
    $img: string
}>`
    background-color: ${(props) => colours[props.$theme].primary_08};
    :hover {
        background-color: ${(props) => colours[props.$theme].primary_20};
        color: var(--bg-color, #fff);
        border: 1px solid var(--primary, #5ec3ca);
        transform: scale(1.015);
        background-image: none;
    }

    ${(props) =>
        props.$hasSvgBackgroundImage &&
        `position: relative;
             background-size: cover;
             background-repeat: no-repeat;
             background-position: center center;
             background-image: url(${
                 props.$customPlaceholderImg
                     ? props.$customPlaceholderImg
                     : props.$img
             });`}

    width: 100%;
    height: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
`

const AddBoxWithSvgPlaceholderDesktop: React.FunctionComponent<IProps> = (
    props: IProps
) => {
    const [showBoxInfo, setShowBoxInfo] = useState(false)

    const { theme } = useThemes()

    const [placeholder, _] = React.useState(
        randomCarSvgPlaceholderFunc(undefined, theme)
    )

    return !props.readOnlyMode ? (
        <GoToBox
            style={{
                height: props.height ?? '400px',
                width: '100%',
            }}
            onMouseEnter={() => setShowBoxInfo(true)}
            onMouseLeave={() => setShowBoxInfo(false)}
            onClick={props.onClickAdd}
            $hasSvgBackgroundImage={props.hasSvgBackgroundImage}
            $customPlaceholderImg={props.customPlaceholderImg}
            $theme={theme}
            $img={placeholder}
        >
            {showBoxInfo === false && !props.hasSvgBackgroundImage && (
                <Icon icon="add_plus" />
            )}
            {showBoxInfo === true && (
                <Row>
                    {props.hasAddTxtIcon && (
                        <>
                            <AddIcon
                                height="18px"
                                width="18px"
                                onClick={(e: any) => {}}
                            />
                            <div style={{ paddingLeft: '10px' }} />
                        </>
                    )}

                    <AddBoxTextInfo>{props.hooverText ?? 'Add'}</AddBoxTextInfo>
                </Row>
            )}
        </GoToBox>
    ) : (
        <NoDataRectangle
            text={props.noDataText ?? 'No data'}
            height={props.height ?? '400px'}
        />
    )
}

export default AddBoxWithSvgPlaceholderDesktop
