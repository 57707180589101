import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import { IwriteMultipleTechnicalInformationPayloadReq } from 'entityModels'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import InputField from '../../../../../atoms/Inputfield/inputField'
import { IYearsValidationCopy } from 'formValidationCopyModels'
import Expander from '../../../../../atoms/expander/expander'
import { writeMultipleTechnicalInformationRequest } from '../../../../../../redux/entities/technical_information/actions/loadingActions'
import { ISetCustomItemInSpecifiedTargettedDropdownReq } from '../../../../../../redux/localdata/dropdownData/sagas/setCustomItemInSpecifiedTargettedDropdown'
import { useHistory, useLocation } from 'react-router'
import { getStandaloneSearchUrl } from '../../searchselect/mobile/standaloneSearchSelectMobile'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTitle,
    Notice,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'
import ChevronDown from '../../../../../atoms/icons/components/chevronDown'

const Padding16 = styled.div`
    padding-top: 16px;
`
const Padding24 = styled.div`
    padding-top: 24px;
`
const Padding8 = styled.div`
    padding-top: 8px;
`
// const RegistrationNumberReminder = styled.div`
//     color: grey;
//     font-family: Lato;
//     font-size: 13px;
//     padding-top: 10px;
// `

const WarningText = styled.div`
    color: var(--error, #df6f6f);
    font-size: 13px;
    padding-top: 5px;
    width: 100%;
    align-items: start;
    justify-content: flex-start;
    font-style: italic;
    font-family: Lato;
    @media (max-width: 350px) {
        padding-bottom: 5px;
    }
`

const PickerButton = styled.button`
    width: 100%;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-strong, #1a1a1a);
    border-radius: 4px;
    position: relative;

    ${(props: { noValue: boolean; disabled: boolean }) => `
        opacity: ${props.disabled ? '0.5' : '1'};

        border: ${
            props.disabled
                ? '1px solid var(--text-muted)'
                : props.noValue
                ? '1px solid var(--primary, #5ec3ca)'
                : '1px solid var(--text-muted)'
        }
    `}
`

const PickerButtonContents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 12px;
    height: 48px;
    color: var(--text-strong, #1a1a1a);

    ${(props: { disabled: boolean }) => `
        opacity: ${props.disabled ? 0.5 : 1}
    `}
`

const PickerLabel = styled.label`
    position: absolute;
    top: -8px;
    left: 8px;
    padding: 0px 8px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-darker, #616161);
`

const PickerLink = ({ children, ...props }: any) => {
    return (
        <PickerButton {...props}>
            <PickerButtonContents disabled={props.disabled}>
                {children}
                <div style={{ transform: 'rotate(-90deg' }}>
                    <ChevronDown />
                </div>
            </PickerButtonContents>
            <PickerLabel>{props.label}</PickerLabel>
        </PickerButton>
    )
}

const dispatchProps = {
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => writeMultipleTechnicalInformationRequest(p),
    setCustomItemInSpecifiedTargettedDropdown: (
        payload: ISetCustomItemInSpecifiedTargettedDropdownReq
    ) =>
        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
            payload
        ),
    setTempModelDisplay: (payload: string | undefined) =>
        dropDownActions.setTempModelDisplay(payload),
    setTempModelIDSuccess: (payload: string | undefined) =>
        dropDownActions.setTempModelIDSuccess(payload),
}

function mapStateToProps(state: RootState) {
    return {
        modelDropdownList: state.localdata.dropdownData.carModels,
        isLoading: state.localdata.dropdownData.loading,
        car_years_validation:
            state.localdata.form_validation_copy.years.car_year,
        tempModelDisplay: state.localdata.dropdownData.tempModelDisplay,
        isLoadingNewMakeOrModel:
            state.localdata.dropdownData.isLoadingNewMakeOrModel,
    }
}

interface Values {
    car_model_custom_model_mobile: string
    car_year_custom_model_mobile: any
}

interface OtherProps {
    toggle: any
    tempModelDisplay: string | undefined
    model_item: any
    year_item: any
    submitFunc?: any
    tech_info_id?: any
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => {}
    modelDropdownList: any
    setCustomItemInSpecifiedTargettedDropdown: any
    isLoading: boolean
    make_item: any
    carid: string
    car_years_validation?: IYearsValidationCopy
    setTempModelIDSuccess: (make: string | undefined) => void
    setTempModelDisplay: (make: string | undefined) => void
    isLoadingNewMakeOrModel: boolean
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        model_item,
        year_item,

        handleSubmit: onSubmit,
        values,
        errors,
        make_item,
        isLoadingNewMakeOrModel,
    } = props

    let checkIfDisabled = () => {
        if (
            !values.car_model_custom_model_mobile ||
            values.car_model_custom_model_mobile === '-'
        ) {
            return true
        } else if (
            errors.car_model_custom_model_mobile ||
            errors.car_year_custom_model_mobile
        ) {
            return true
        } else if (isLoadingNewMakeOrModel) {
            return true
        } else return false
    }

    const history = useHistory()
    const location = useLocation()

    const removeFormIdFromUrl = () => {
        const params = new URLSearchParams(location.search)
        params.delete('form_id')

        const query = params.toString()
        history.replace(`${location.pathname}?${query}`)
    }

    const handleCancel = () => {
        removeFormIdFromUrl()

        // TODO
        // setTempModelIDSuccess(undefined)

        toggle()
    }

    const handleSubmit = (...args: any) => {
        removeFormIdFromUrl()

        onSubmit(...args)
    }

    const model = props.values['car_model_custom_model_mobile']

    const modelLabel = model && model !== '-' ? model : null

    const modelPickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${props.carid}/search`,
        formId: 'model',
        fieldId: 'model',
    })

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            {model_item !== undefined && year_item !== undefined && (
                <ModalInnerContentWrapperMobile>
                    <ModalTitle $theme={theme}>Model | Year</ModalTitle>
                    <Padding8 />
                    {/* <RegistrationNumberReminder>
                        or{' '}
                        <ButtonAtom theme="naked-text">click here</ButtonAtom>{' '}
                        to enter the car’s registration number and get data
                        automatically added.
                    </RegistrationNumberReminder> */}
                    <Notice>
                        If you can't find your car's model simply type the name
                        fully, then select "
                        <span style={{ fontWeight: 'bold' }}>add</span>
                        ".
                    </Notice>
                    <Padding8 />
                    <Expander height={make_item.answer === null ? 'auto' : 0}>
                        <WarningText>Please enter the car's make.</WarningText>
                    </Expander>
                    <Padding16 />
                    <PickerLink
                        onClick={() =>
                            !!modelPickerUrl && history.push(modelPickerUrl)
                        }
                        noValue={!modelLabel}
                        disabled={!make_item.answer}
                        label="Model"
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    >
                        {modelLabel ?? 'Pick a model'}
                    </PickerLink>
                    <Padding8 />
                    <Expander
                        height={
                            (make_item.answer !== null &&
                                values['car_model_custom_model_mobile'] ===
                                    null) ||
                            values['car_model_custom_model_mobile'] === '-'
                                ? 'auto'
                                : 0
                        }
                    >
                        <WarningText>Please enter the car's model.</WarningText>
                    </Expander>
                    <Padding16 />
                    <InputField
                        theme={theme}
                        id="car_year_custom_model_mobile"
                        name="car_year_custom_model_mobile"
                        placeholder={year_item.text}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.car_year_custom_model_mobile}
                        font_family="Lato"
                        width="150px"
                        type="number"
                        disabled={
                            values['car_model_custom_model_mobile'] === null &&
                            model_item.answer === null
                                ? true
                                : false
                        }
                        onEnterKeyPress={props.handleSubmit}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <Padding8 />
                    <Expander
                        height={
                            errors.car_year_custom_model_mobile ? 'auto' : 0
                        }
                    >
                        <WarningText>
                            {errors.car_year_custom_model_mobile}
                        </WarningText>
                    </Expander>
                    <Padding24 />
                    <Padding8 />
                    <ButtonsStandardBottomRowMobile
                        cancel={handleCancel}
                        submit={handleSubmit}
                        confirmationtext="save"
                        disabled={checkIfDisabled()}
                        isSubmitLoading={isLoadingNewMakeOrModel}
                    />
                </ModalInnerContentWrapperMobile>
            )}
        </ModalContentMobile>
    )
}

const CarModelCustomFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        car_model_custom_model_mobile: props.model_item?.answer,
        car_year_custom_model_mobile: props.year_item
            ? props.year_item.answer
            : '-',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                car_year_custom_model_mobile:
                    values.car_year_custom_model_mobile &&
                    values.car_year_custom_model_mobile !== '-' &&
                    props.car_years_validation &&
                    Yup.number()
                        .min(
                            props.car_years_validation.min,
                            props.car_years_validation.warning_min
                        )
                        .max(
                            props.car_years_validation.max,
                            props.car_years_validation.warning_max
                        ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteMultipleTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: [
                {
                    id: 'model',
                    text: 'model',
                    answer: values.car_model_custom_model_mobile,
                },
                {
                    id: 'year',
                    text: 'year',
                    answer: values.car_year_custom_model_mobile,
                },
            ],
        }
        props.writeMultipleTechnicalInformationRequest(p)
        props.toggle()
    },
})(InnerForm)

export default connect(mapStateToProps, dispatchProps)(CarModelCustomFormMobile)
