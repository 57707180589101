import styled from 'styled-components'
import {
    IShowroomEntriesSimilarRecommendations,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { CardSkeletonItems } from '../../../pages/showroom/showroom'
import { ISearchShowroom_API_car_item } from '../../../../redux/services/showroom/types'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import ShowroomCardDesktop from '../card/showroomCardDesktop'

type Props = {
    entry?: IShowroomEntry
    recommendationsObj?: IShowroomEntriesSimilarRecommendations
    clickOnOwner: () => any
    onClickBrowseAll: () => any
    clickOnMake: () => any
    isLoading: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const CardsContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;
    overflow-y: scroll;
    width: 100%;
    padding-left: 1.5rem;
`

const CardWrapper = styled.div`
    width: 75vw;
    min-width: 75vw;
    max-width: 500px;
`

const ContainerTop = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

const Row = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

const BtnTxt = styled.span`
    color: #5ec3ca;
    text-align: center;
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const Title = styled.h3`
    padding: 0px;
    margin: 0px;
    color: #1a1a1a;
    text-align: center;
    font-family: Lato-semibold;
    font-size: 18px;
    line-height: 32px;
    white-space: nowrap;
`

const MobileSectionWrapper = styled.div`
    padding-right: 1.5rem;
    padding-left: 1.5rem;
`

const ShowroomEntryRecommendationsMobile = (props: Props) => {
    const {
        entry,
        recommendationsObj,
        clickOnOwner,
        isLoading,
        onClickBrowseAll,
        clickOnMake,
    } = props

    let entriesList: ISearchShowroom_API_car_item[] | undefined =
        entry?.owner?.id &&
        recommendationsObj &&
        recommendationsObj.dealers[entry.owner.id]
            ? recommendationsObj.dealers[entry.owner.id].latest?.filter(
                  (listed: ISearchShowroom_API_car_item) => {
                      return listed?.uid !== entry?.id
                  }
              )
            : []

    // to be replaced with actual data
    let dealer_total_nb_of_cars: number | undefined =
        entry?.owner?.id &&
        recommendationsObj &&
        recommendationsObj.dealers[entry.owner.id] &&
        recommendationsObj.dealers[entry.owner.id].total_count !== undefined
            ? recommendationsObj.dealers[entry.owner.id].total_count
            : undefined

    return (
        <Container
            style={{
                gap: entriesList && entriesList.length > 0 ? '32px' : '0px',
            }}
        >
            <MobileSectionWrapper>
                {entriesList && entriesList.length > 0 && (
                    <ContainerTop>
                        <Title>More cars from dealer</Title>
                        <StandardCtaBtn
                            onClick={() => {
                                clickOnOwner()
                            }}
                            boder="none"
                            bg="transparent"
                            removePaddings
                            customWidth="auto"
                        >
                            <Row>
                                <BtnTxt>{`See ${dealer_total_nb_of_cars} cars`}</BtnTxt>
                                <div style={{ transform: 'rotate(-90deg)' }}>
                                    <ChevronDown color="#5ec3ca" />
                                </div>
                            </Row>
                        </StandardCtaBtn>
                    </ContainerTop>
                )}
            </MobileSectionWrapper>
            <CardsContainer>
                {isLoading ? (
                    <CardSkeletonItems
                        height="25vh"
                        width="95%"
                        paddingBottom="0px"
                        narr={[1, 2, 3, 4, 5, 6]}
                    />
                ) : entriesList && entriesList.length > 0 ? (
                    entriesList.map(
                        (
                            entry: ISearchShowroom_API_car_item,
                            index: number
                        ) => {
                            if (index < 3) {
                                return (
                                    <CardWrapper
                                        key={`card_${entry.uid}_mobile`}
                                    >
                                        <ShowroomCardDesktop
                                            entry={entry}
                                            isRecommendation
                                        />
                                    </CardWrapper>
                                )
                            } else return null
                        }
                    )
                ) : null}
            </CardsContainer>
            <MobileSectionWrapper>
                <Container style={{ gap: 16 }}>
                    <StandardCtaBtn
                        onClick={() => {
                            onClickBrowseAll()
                        }}
                        boder="1px solid #5ec3ca"
                        bg="transparent"
                        customWidth="100%"
                    >
                        <BtnTxt>{`Browse all cars`}</BtnTxt>
                    </StandardCtaBtn>
                    {entry && entry.car?.overview.data.make.answer && (
                        <StandardCtaBtn
                            onClick={() => {
                                clickOnMake()
                            }}
                            boder="1px solid #5ec3ca"
                            bg="transparent"
                            customWidth="100%"
                        >
                            <BtnTxt>{`See more ${entry.car?.overview.data.make.answer}s`}</BtnTxt>
                        </StandardCtaBtn>
                    )}
                </Container>
            </MobileSectionWrapper>
        </Container>
    )
}

export default ShowroomEntryRecommendationsMobile
