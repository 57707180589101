import { device } from '../../templates/displays/devices'
import styled from 'styled-components'
import { Form } from 'formik'

export const FormWrapper = styled(Form)`
    display: flex;
    justify-self: center;
    align-self: center;
`

export const SubWrapper = styled.div`
    color: black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
`

export const DividerTop = styled.div`
    @media ${device.desktop} {
        padding-top: 50px;
    }

    @media ${device.mobile} {
        padding-top: 20px;
    }

    @media ${device.ipad} {
        padding-top: 20px;
    }
`

export const DividerBtn = styled.div`
    @media ${device.desktop} {
        padding-top: 50px;
    }

    @media ${device.mobile} {
        padding-top: 60px;
    }

    @media ${device.ipad} {
        padding-top: 60px;
    }
`

export const FormWrapperVerified = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100vw;
    justify-content: center;
    align-items: center;
`

export const SubWrapperVerified = styled.div`
    color: black;
    font-size: 15px;
    display: flex;
    flex-direction: column;

    @media ${device.ipad} {
        margin-top: 40px;
        justify-content: flex-start;
        align-items: start;
    }

    @media ${device.desktop} {
        margin-top: 40px;
        justify-content: flex-start;
        align-items: start;
    }
    @media ${device.mobile} {
        margin-top: 50px;
        justify-content: flex-start;
        align-items: start;
    }
`

export const PaddingVerified = styled.div`
    @media ${device.ipad} {
        padding-top: 60px;
    }

    @media ${device.desktop} {
        padding-top: 60px;
    }
    @media ${device.mobile} {
        padding-top: 60px;
    }
`

export const DividerVerified = styled.div`
    @media ${device.ipad} {
        padding-top: 10px;
    }

    @media ${device.desktop} {
        padding-top: 30px;
    }
    @media ${device.mobile} {
        padding-top: 25px;
    }
`
