import { IAttachmentAPI, IAttachmentItem } from 'attachmentModels'
import {
    AttachmentTypesEnum,
    AttachmentTypesEnumArr,
} from '../../attachments/enums'

export const ConvertAttachmentAPIToAttachmentState = (
    att: IAttachmentAPI
): IAttachmentItem => {
    let a: IAttachmentItem = {
        id: att.uid ? att.uid : '',
        url: att.presigned_download_url ? att.presigned_download_url : '',
        filename: att.title ? att.title : '',
        fileSuffix: att.title
            ? ConverFileTypeToFileSuffixEnum(att.title)
            : undefined,
        created_at: att.created_at,
    }

    return a
}
export const ConverFileTypeToFileSuffixEnum = (
    filename: string
): AttachmentTypesEnum | undefined => {
    let t: string | undefined = filename.split('.').pop()
    let t2: AttachmentTypesEnum | undefined = undefined

    if (t) {
        for (const ti of AttachmentTypesEnumArr) {
            if (ti === t.toLowerCase()) {
                t2 = ti
            }
        }
    }

    return t2
}

export const GetFileType = (filename: string): any => {
    let t: string[] = filename.split('.')
    let lastItemIndex: number = t.length - 1
    return t[lastItemIndex]
}
