import { IUser } from 'myModels'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import UserCircleProfilePage from '../../../atoms/user/userCircleProfilePage'
import { device } from '../../../templates/displays/devices'
import {
    flatOwnerAddress_Showroom,
    UserSocialMediaRow,
} from './ownerSectionMobileWithCarPic'
import user_instagram from '../../../../public/assets/icons/socialMedia/user_instagram.svg'
import user_facebook from '../../../../public/assets/icons/socialMedia/user_facebook.svg'
import user_linkedin from '../../../../public/assets/icons/socialMedia/user_linkedin.svg'
import user_twitter from '../../../../public/assets/icons/socialMedia/user_twitter.svg'
import user_website from '../../../../public/assets/icons/socialMedia/user_website.svg'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { useEffect, useState } from 'react'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'

type Props = {
    carOwner: IUser | undefined
    entryid: string
    theme?: 'border' | 'grey-bg'
    isAdmin?: boolean
    setActiveForm?: () => any
    isSold?: boolean
    isPOA?: boolean | undefined
}

type StyleProps = {
    $isTitle?: boolean
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 144px;

    transition: all 100ms;
`

const ContentContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-top: 2.5rem;
    padding-left: 2.5rem;
    padding-bottom: 2.5rem;
    padding-right: 2.5rem;

    width: 100%;
    min-height: 144px;

    @media ${device.smallest_laptops} {
        gap: 10px;
    }
`

const UserInfoContainer = styled.div`
    display: flex;
    flex-direction: row;

    width: 50%;

    min-height: 64px;
`

const UserInfoContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 4.5rem;
    width: 100%;
    height: inherit;
`

const UserInfoContent = styled.span<StyleProps>`
    width: 100%;
    height: 100%;

    font-family: 'Lato';
    font-style: normal;
    font-weight: ${(props) => (props.$isTitle ? 500 : 400)};
    font-size: ${(props) => (props.$isTitle ? '1.5rem' : '1rem')};
    line-height: ${(props) => (props.$isTitle ? '2.5rem' : '1.5rem')};
    letter-spacing: ${(props) => (props.$isTitle ? 'normal' : 'normal')};
    text-transform: capitalize;

    color: ${(props) =>
        props.$isTitle
            ? 'var(--text-strong, #1a1a1a)'
            : 'var(--text-default, #666666)'};

    @media ${device.smallest_laptops} {
        font-size: ${(props) => (props.$isTitle ? '1.3rem' : '0.95rem')};
    }

    :hover {
        color: ${(props) =>
            props.$isTitle
                ? 'var(--primary, #5EC3CA)'
                : 'var(--text-default, #666666)'};
    }
`

const Address = styled.span<StyleProps>`
    width: 80%;
    height: 100%;

    font-family: 'Lato';
    font-style: normal;
    font-weight: ${(props) => (props.$isTitle ? 500 : 400)};
    font-size: ${(props) => (props.$isTitle ? '1.5rem' : '1rem')};
    line-height: ${(props) => (props.$isTitle ? '2.5rem' : '1.5rem')};
    letter-spacing: ${(props) => (props.$isTitle ? 'normal' : 'normal')};
    text-transform: capitalize;

    color: ${(props) =>
        props.$isTitle
            ? 'var(--text-strong, #1a1a1a)'
            : 'var(--text-default, #666666)'};

    @media ${device.smallest_laptops} {
        font-size: ${(props) => (props.$isTitle ? '1.3rem' : '0.95rem')};
    }

    :hover {
        color: ${(props) =>
            props.$isTitle
                ? 'var(--primary, #5EC3CA)'
                : 'var(--text-default, #666666)'};
    }
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 48px;
    width: 50%;
`

const ImgContainer = styled.div`
    width: 1.5rem;
`

const CallButton = styled.span`
    height: 100%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    text-align: center;
    /* letter-spacing: 150%; */

    color: var(--text-strong, #1a1a1a);

    @media ${device.smallest_laptops} {
        font-size: 12px;
        min-width: 100px;
    }
`

const IconImg = styled.img`
    width: 24px;
`

const OwnerSectionDesktop = (props: Props) => {
    const { carOwner, theme, isAdmin, entryid } = props

    let displayName =
        carOwner && carOwner.display_name && carOwner.display_name.trim().length
            ? carOwner.display_name
            : 'No Name'

    const history = useHistory()
    // let dispatch = useAppDispatch()

    let hasAnySocialMedia: boolean = carOwner?.external_urls?.WEBSITE?.url
        ? true
        : carOwner?.external_urls?.FACEBOOK?.url
        ? true
        : carOwner?.external_urls?.INSTAGRAM?.url
        ? true
        : carOwner?.external_urls?.TWITTER?.url
        ? true
        : carOwner?.external_urls?.LINKEDIN?.url
        ? true
        : false

    const dispatch = useAppDispatch()

    const clickOnOwner = () => {
        if (carOwner && carOwner.id && carOwner.display_name) {
            dispatch(showroomActions.setCurrentPageNumber_sold(0))
            dispatch(showroomActions.setCurrentPageNumber_sale(0))

            dispatch(
                showroomActions.searchShowroom_setCars({
                    cars: undefined,
                    resultsFoundNumber: undefined,
                    similarSoldCarsNumber: undefined,
                    type: props.isSold ? 'sold' : 'sale',
                })
            )
            dispatch(
                showroomActions.setOwner_filter({
                    id: carOwner?.id,
                    display_name: carOwner?.display_name,
                })
            )

            let url = `/showroom`

            if (props.isSold) {
                url = url + '/sold'
            } else {
                url = url + '/for-sale'
            }

            url =
                url +
                `?owner_id=${carOwner?.id}&owner_display_name=${carOwner?.display_name}`
            url = encodeURI(url)
            history.push(url)
        }
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const isOwner =
        carOwner?.id && userLoggedIn && carOwner.id === userLoggedIn.id
            ? true
            : false

    const [ownerPhone, setOwnerPhone] = useState<string | undefined>(undefined)

    let getPhoneNumber = async () => {
        try {
            if (
                carOwner &&
                carOwner.phone &&
                carOwner.phone.trim().length > 0
            ) {
                let isNumberValid = isValidPhoneNumber(carOwner.phone)
                if (isNumberValid) {
                    let numberr = parsePhoneNumber(`${carOwner.phone}`)
                    return setOwnerPhone(numberr.formatInternational())
                } else setOwnerPhone(carOwner.phone)
            } else {
                return setOwnerPhone(undefined)
            }
        } catch (e: any) {
            return setOwnerPhone(undefined)
        }
    }

    useEffect(() => {
        getPhoneNumber().catch(console.error)
    }, [carOwner])

    return (
        <Container
            style={{
                backgroundColor:
                    theme && theme === 'border'
                        ? 'var(--bg-color, #fff)'
                        : 'var(--off-bg-color, #fafafa)',
                border:
                    theme && theme === 'border'
                        ? '1px solid var(--border-muted, #E5E5E5)'
                        : 'none',
            }}
        >
            <ContentContainer>
                <UserInfoContainer
                    style={{
                        alignItems: hasAnySocialMedia ? 'baseline' : 'normal',
                    }}
                >
                    <ImgContainer>
                        <UserCircleProfilePage
                            url={
                                carOwner?.profile_image_url?.length
                                    ? carOwner?.profile_image_url
                                    : null
                            }
                            onClick={() => {
                                if (props.isAdmin) {
                                    props.setActiveForm && props.setActiveForm()

                                    // nothing happens
                                    // dispatch(
                                    //     editFormsActions.toggleUserProfileImageForm
                                    // )
                                } else {
                                    clickOnOwner()
                                }
                            }}
                            isShowroom
                        />
                    </ImgContainer>
                    <UserInfoContentContainer>
                        <UserInfoContent
                            $isTitle
                            style={{ cursor: 'pointer' }}
                            role="button"
                            onClick={() => {
                                if (!props.isAdmin) {
                                    clickOnOwner()
                                } else if (props.isAdmin) {
                                    props.setActiveForm && props.setActiveForm()
                                }
                            }}
                        >
                            {displayName}
                        </UserInfoContent>
                        <Address
                            style={{
                                textTransform:
                                    carOwner?.address?.postcode === 'SW1A 1AA'
                                        ? 'none'
                                        : 'capitalize',
                            }}
                        >
                            {flatOwnerAddress_Showroom(carOwner?.address!)}
                        </Address>

                        <div style={{ paddingTop: '16px' }} />
                        {hasAnySocialMedia && (
                            <UserSocialMediaRow>
                                {carOwner?.external_urls?.WEBSITE?.url &&
                                carOwner?.external_urls?.WEBSITE?.url !== '' ? (
                                    <a
                                        href={`${carOwner.external_urls.WEBSITE.url}`}
                                        target="_blank"
                                    >
                                        <IconImg
                                            src={user_website}
                                            alt="website"
                                        />
                                    </a>
                                ) : null}

                                {carOwner?.external_urls?.FACEBOOK?.url &&
                                carOwner?.external_urls?.FACEBOOK?.url !==
                                    '' ? (
                                    <a
                                        href={`${carOwner.external_urls.FACEBOOK.url}`}
                                        target="_blank"
                                    >
                                        <IconImg
                                            src={user_facebook}
                                            alt="facebook"
                                        />
                                    </a>
                                ) : null}

                                {carOwner?.external_urls?.LINKEDIN?.url &&
                                carOwner?.external_urls?.LINKEDIN?.url !==
                                    '' ? (
                                    <a
                                        href={`${carOwner.external_urls.LINKEDIN.url}`}
                                        target="_blank"
                                    >
                                        <IconImg
                                            src={user_linkedin}
                                            alt="linkedin"
                                        />
                                    </a>
                                ) : null}

                                {carOwner?.external_urls?.INSTAGRAM?.url &&
                                carOwner?.external_urls?.INSTAGRAM?.url !==
                                    '' ? (
                                    <a
                                        href={`${carOwner.external_urls.INSTAGRAM.url}`}
                                        target="_blank"
                                    >
                                        <IconImg
                                            src={user_instagram}
                                            alt="instagram"
                                        />
                                    </a>
                                ) : null}

                                {carOwner?.external_urls?.TWITTER?.url &&
                                carOwner?.external_urls?.TWITTER?.url !== '' ? (
                                    <a
                                        href={`${carOwner.external_urls.TWITTER.url}`}
                                        target="_blank"
                                    >
                                        <IconImg
                                            src={user_twitter}
                                            alt="twitter"
                                        />
                                    </a>
                                ) : null}
                            </UserSocialMediaRow>
                        )}
                    </UserInfoContentContainer>
                </UserInfoContainer>

                <ActionsContainer>
                    <CallButton>
                        {/* put placeholder in case of undefined value */}
                        {ownerPhone && ownerPhone}
                    </CallButton>

                    <div style={{ paddingLeft: '40px' }} />

                    {props.isSold ? null : !isAdmin ? (
                        <>
                            <ButtonAtom
                                theme="capitalize-white-background"
                                width="150px"
                                height="48px"
                                dataCyId={'showroom-car-share'}
                                icon={''}
                                onClick={() => {
                                    if (!userLoggedIn) {
                                        history.push('/login')
                                        dispatch(
                                            customNavDataActions.setInitialLandingUrl(
                                                `/showroom/${entryid}/enquiry?tab=info`
                                            )
                                        )
                                    } else {
                                        history.push(
                                            `/showroom/${entryid}/enquiry?tab=info`
                                        )
                                    }
                                }}
                                disabled={isOwner ? true : false}
                            >
                                <span style={{ textTransform: 'none' }}>
                                    Request info
                                </span>
                            </ButtonAtom>
                            <div style={{ paddingLeft: '1rem' }} />
                            <ButtonAtom
                                theme="secondary"
                                width="150px"
                                height="48px"
                                textTransform="none"
                                dataCyId={'showroom-car-enquire'}
                                onClick={() => {
                                    if (!userLoggedIn) {
                                        history.push('/login')
                                        dispatch(
                                            customNavDataActions.setInitialLandingUrl(
                                                `/showroom/${entryid}/enquiry?tab=offer`
                                            )
                                        )
                                    } else {
                                        history.push(
                                            `/showroom/${entryid}/enquiry?tab=offer`
                                        )
                                    }
                                }}
                                disabled={isOwner || props.isPOA ? true : false}
                            >
                                Make an offer
                            </ButtonAtom>
                        </>
                    ) : (
                        <ButtonAtom
                            theme="flexible-transparent-blue-border"
                            height="48px"
                            dataCyId={'showroom-car-share'}
                            icon={''}
                            onClick={() => {
                                props.setActiveForm && props.setActiveForm()
                            }}
                        >
                            Edit contact details
                        </ButtonAtom>
                    )}
                </ActionsContainer>
            </ContentContainer>
        </Container>
    )
}

export default OwnerSectionDesktop
