// ApexSingleImageLandscapeSliceDesktop

import { IPrismicImg } from 'apexModels'
import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../../../templates/displays/devices'
import grey_placeholder from '../../../../../../../public/assets/placeholder/grey_placeholder.svg'
import ApexImageCreditsMobile from '../../../credits/apexImageCreditsMobile'

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 2px;
    @media ${device.beyond_ipad_mobile} {
    }
`

const Img = styled.img`
    object-fit: cover;
    @media ${device.ipad} {
        height: 50vw;
    }
    @media ${device.mobile} {
        height: 50vw;
    }
    @media ${device.large_desktop} {
    }
`

type Props = {
    img_obj: IPrismicImg
    image_credit?: string
}

class ApexSingleImageLandscapeSliceMobile extends React.Component<Props, {}> {
    render() {
        let { img_obj, image_credit } = this.props
        return (
            <ImageWrapper>
                <Img src={img_obj.url ? img_obj.url : grey_placeholder} />

                {image_credit && <ApexImageCreditsMobile text={image_credit} />}
            </ImageWrapper>
        )
    }
}

export default ApexSingleImageLandscapeSliceMobile
