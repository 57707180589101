// let value = 'mbalue'

import { IAnyObject } from '../../redux/insuranceQuoteApplication/reducer'

export function normalizeArray(arr: any[]) {
    const normalizedObj: IAnyObject = {}

    arr.forEach((item) => {
        const key = item.id
        normalizedObj[key] = { ...item }
    })

    return normalizedObj
}

// const result = groupKeyProperties(myObject, 'item_id')
// console.log(result)
