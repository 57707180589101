// import React from 'react'
// import { Link } from 'react-router-dom'
import { PrismicLink } from 'apollo-link-prismic'
import { ApolloClient } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
// import fragmentTypes from './fragmentTypes.json'
import { apiEndpoint } from '../../prismic-configuration'

// import fragmentTypes from './fragmentTypes.json'

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//     introspectionQueryResultData: fragmentTypes,
// })

// // Helper function to convert Prismic Rich Text links to React Link components
// export const customLink = (type, element, content, children, index) => (
//     <Link to={linkResolver(element.data)} key={index}>
//         {content}
//     </Link>
// )

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//     introspectionQueryResultData: fragmentTypes,
// })

// Client method to query documents from the Prismic repo
export const client = new ApolloClient({
    link: PrismicLink({ uri: apiEndpoint }),
    cache: new InMemoryCache(),
    // cache: new InMemoryCache({ fragmentMatcher }),
})
