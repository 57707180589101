import { IInsuranceApplicationSectionId } from '../../components/pages/insurance_quote/dashboards/insuranceApplicationDashboard'

export type IWizardSectionsID = {
    sectionID: IInsuranceApplicationSectionId | 'submit'
    // | 'insurance_details'
    // | 'vehicles_to_insure'
    // | 'everyday_vehicles'
    // | 'main_policy_holder'
    // | 'drivers'
    // | 'drivers'
    // lots lols
    sub_sectionID: string | null

    car_id?: string | null

    driver_id?: string | null
}

// in order
const everyday_vehicle_sections = {
    tech_info: {
        id: 'tech_info',
        name: 'Technical Specification',
    },
    ownership: {
        id: 'ownership',
        name: 'Ownership',
    },
    mileage_parking: {
        id: 'mileage_parking',
        name: 'Mileage & Parking',
    },
}

const vehicles_to_insure_sections = {
    is_everyday: {
        id: 'is_everyday',
        name: 'Eveyday Vehicle',
    },
    tech_info: {
        id: 'tech_info',
        name: 'Technical Specification',
    },
    mileage_parking: {
        id: 'mileage_parking',
        name: 'Mileage & Parking',
    },
    ownership: {
        id: 'ownership',
        name: 'Ownership',
    },
}

const drivers_sections = {
    basic_details: {
        id: 'basic_details',
        name: 'Basic Details',
    },
    licence_address: {
        id: 'licence_address',
        name: 'Driver licence',
    },
    additional_details: {
        id: 'additional_details',
        name: 'Additional details',
    },
    track_record: {
        id: 'track_record',
        name: 'Track record',
    },
    drive_list: {
        id: 'drive_list',
        name: 'Drive List',
    },
}

const main_policy_holder_sections = {
    basic_details: {
        id: 'basic_details',
        name: 'Basic Details',
    },
    licence_address: {
        id: 'licence_address',
        name: 'Driver licence',
    },
    additional_details: {
        id: 'additional_details',
        name: 'Additional details',
    },
    track_record: {
        id: 'track_record',
        name: 'Track record',
    },
}

type IWizardSection = {
    id: string
    name: string
    icon: string
    subsections: any
}

type IWizardSectionObj = {
    [key: string]: IWizardSection
}

export const insurance_wizard_sections: IWizardSectionObj = {
    insurance_details: {
        id: 'insurance_details',
        name: 'Insurance details',
        icon: 'icon_id_here',
        subsections: null,
    },
    vehicles_to_insure: {
        id: 'vehicles_to_insure',
        name: 'Vehicles to Insure',
        subsections: vehicles_to_insure_sections,
        icon: 'icon_id_here',
    },
    everyday_vehicles: {
        id: 'everyday_vehicles',
        name: 'Everyday Vehicle',
        subsections: everyday_vehicle_sections,
        icon: 'icon_id_here',
    },
    main_policy_holder: {
        id: 'main_policy_holder',
        name: 'Main policy Holder',
        subsections: main_policy_holder_sections,
        icon: 'icon_id_here',
    },
    drivers: {
        id: 'drivers',
        name: 'Drivers',
        subsections: drivers_sections,
        icon: 'icon_id_here',
    },
}

export const insurance_wizard_sections_order = Object.keys(
    insurance_wizard_sections
)
