import * as Yup from 'yup'
import { countDecimals } from '../../../helpers/decimalsCheck'

export let yupVar_BasicSingleField = (data: any) => {
    if (data.isMandatory) {
        return Yup.string()
            .min(data.charLimit ? data.charLimit[0] : 2, 'mandatory field')
            .max(
                data.charLimit ? data.charLimit[1] - 1 : 30,
                `Your answer must be under ${
                    data.charLimit ? data.charLimit[1] : 30
                } characters`
            )
            .required('mandatory field')
    } else
        return Yup.string()
            .min(data.charLimit ? data.charLimit[0] : 2, 'keep typing')
            .max(
                data.charLimit ? data.charLimit[1] - 1 : 30,
                `Your answer must be under ${
                    data.charLimit ? data.charLimit[1] : 30
                } characters`
            )
}

export let yupVar_NumberBasicSingleField = (value: any) => {
    return Yup.number()
        .min(0, 'Please add a valid amount')
        .test('has just 2 decimals', 'max 2 decimals', (value: any) =>
            countDecimals(parseFloat(value)) > 2 ? false : true
        )
}

export let yupVar_EmailSingleField = (value: any) => {
    return Yup.string().email('Email not valid')
}

export let yupVar_CarTitle = () =>
    Yup.string()
        .min(2, 'Please add a valid title')
        .max(40, 'Car title must be less than 40 characters.')
        .required('Required field')
