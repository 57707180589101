// this is where you define + create the actions
// using createAction for normal actions and C
// reateAsyncAction for stuff which requres usage of API / services etc - async

// you import your models here too, the ones created in the module in types.d.ts
import { IUserAddressApi } from 'IapiDataPayload'
import {
    ILabel,
    IUser,
    IUserHandoversObjByState,
    IUser_SharesReceived_byEntity,
} from 'myModels'

import {
    SIGNIN_USER_SUCCESS,
    REGISTER_USER_SUCCESS,
    LOGOUT_USER_SUCCESS,
    ADD_PHONE_SUCCESS,
    ADD_ADDRESS_SUCCESS,
    ADD_CATEGORIES_SUCCESS,
    VERIFY_SUCCESS,
    SEND_INVITATION_SUCCESS,
    RESET_PASSWORD_SUCCESS,
    VERIFY_RESET_PASSWORD_CODE_SUCCESS,
    RESET_PASSWORD_VERIFIED_SUCCESS,
    GET_CURRENT_USER_DATA_SUCCESS,
    UPDATE_USER_DATA_GENERAL_INFO_SUCCESS,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ONCE_SUCCESS,
    ADD_LABELS_TO_USER_SUCCESS,
    UPDATE_USER_TIME_ZONE,
    GET_USER_DIRECT_SHARES_RECEIVED_SUCCESS,
    POPULATE_OWNED_SHARES_DATA_SUCCESS,
    UPDATE_SHARE_RECEIVED_STATUS_SUCCESS,
    DELETE_ACCOUNT_SUCCESS,
    ADD_TO_REFRESH_TOKEN_RETRIES_COUNT,
    RESET_REFRESH_TOKEN_RETRIES_COUNT,
    SET_USER_ARCHIVED_CARS_SUCCESS,
    UPDATE_USER_TASKS_COUNT_ON_CREATION,
    UPDATE_USER_TASKS_COUNT_ON_DELETION,
    LIST_USER_HANDOVERS_SUCCESS,
    UPDATE_ARCHIVED_CARS,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IList } from 'entityModels'

export const verifySuccess = createAction(VERIFY_SUCCESS)

// export const signInUserSuccess = createAction(
//     SIGNIN_USER_SUCCESS,
//     (resolve) => (payload: string) => resolve(payload)
// )

export const signInUserSuccess = createAction(SIGNIN_USER_SUCCESS)

// export const getUserDataSuccess = createAction(
//     GET_USER_DATA_SUCCESS,
//     (resolve) => (payload: IUser) => resolve(payload)
// )

export const getCurrentUserDataSuccess = createAction(
    GET_CURRENT_USER_DATA_SUCCESS,
    function prepare(payload: IUser) {
        return {
            payload,
        }
    }
)

// export const registerSuccess = createAction(
//     REGISTER_USER_SUCCESS,
//     (resolve) => (payload: string) => resolve(payload)
// )

export const registerSuccess = createAction(REGISTER_USER_SUCCESS)

// export const addPhoneSuccess = createAction(
//     ADD_PHONE_SUCCESS,
//     (resolve) => (phone: string) => resolve(phone)
// )

export const addPhoneSuccess = createAction(
    ADD_PHONE_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const addLabelsToUserSuccess = createAction(
    ADD_LABELS_TO_USER_SUCCESS,
    function prepare(payload: ILabel[]) {
        return {
            payload,
        }
    }
)

// export const addAddressSuccess = createAction(
//     ADD_ADDRESS_SUCCESS,
//     (resolve) => (address: IUserAddressApi) => resolve(address)
// )

export const addAddressSuccess = createAction(
    ADD_ADDRESS_SUCCESS,
    function prepare(payload: IUserAddressApi) {
        return {
            payload: payload,
        }
    }
)

// export const verifyResetPasswordCodeSuccess = createAction(
//     VERIFY_RESET_PASSWORD_CODE_SUCCESS,
//     (resolve) => (uid: string) => resolve(uid)
// )

export const verifyResetPasswordCodeSuccess = createAction(
    VERIFY_RESET_PASSWORD_CODE_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const logoutUserSuccess = createAction(LOGOUT_USER_SUCCESS)

export const addCategorySuccess = createAction(ADD_CATEGORIES_SUCCESS)

export const sendInvitationSuccess = createAction(SEND_INVITATION_SUCCESS)

export const resetPasswordSuccess = createAction(RESET_PASSWORD_SUCCESS)

export const verifiedResetPasswordSuccess = createAction(
    RESET_PASSWORD_VERIFIED_SUCCESS
)

// export const updateUserGeneralInfoSuccess = createAction(
//     UPDATE_USER_DATA_GENERAL_INFO_SUCCESS,
//     (resolve) => (payload: IUser) => resolve(payload)
// )

export const updateUserGeneralInfoSuccess = createAction(
    UPDATE_USER_DATA_GENERAL_INFO_SUCCESS,
    function prepare(payload: IUser) {
        return {
            payload,
        }
    }
)

export const refreshTokenSuccess = createAction(
    REFRESH_TOKEN_SUCCESS,
    function prepare(payload: boolean) {
        return {
            payload,
        }
    }
)

export const refreshTokenOnceSuccess = createAction(
    REFRESH_TOKEN_ONCE_SUCCESS,
    function prepare(payload: boolean) {
        return {
            payload,
        }
    }
)

export const updateUserTimeZone = createAction(
    UPDATE_USER_TIME_ZONE,
    function prepare(payload: {
        idsList: string[]
        data: { [key: string]: IList }
    }) {
        return {
            payload,
        }
    }
)

export type I_getUserDirectSharesReceivedSuccess_res_payload = {
    resource_type: 'car' | 'garage' | 'car_and_garage'
    cars?: IUser_SharesReceived_byEntity
    garages?: IUser_SharesReceived_byEntity
}

export const getUserDirectSharesReceivedSuccess = createAction(
    GET_USER_DIRECT_SHARES_RECEIVED_SUCCESS,
    function prepare(
        payload: I_getUserDirectSharesReceivedSuccess_res_payload
    ) {
        return {
            payload,
        }
    }
)

export const populateOwnedSharesDataPopulationSuccess = createAction(
    POPULATE_OWNED_SHARES_DATA_SUCCESS
)

export const updateShareReceivedStatusSuccess = createAction(
    UPDATE_SHARE_RECEIVED_STATUS_SUCCESS,
    function prepare(payload: {
        entity_type: string
        shares_received: IUser_SharesReceived_byEntity
    }) {
        return {
            payload,
        }
    }
)

export const deleteAccountSuccess = createAction(DELETE_ACCOUNT_SUCCESS)

export const addToRefreshTokenRetriesCount = createAction(
    ADD_TO_REFRESH_TOKEN_RETRIES_COUNT
)

export const resetRefreshTokenRetriesCount = createAction(
    RESET_REFRESH_TOKEN_RETRIES_COUNT
)

export const setUserArchivedCarsSuccess = createAction(
    SET_USER_ARCHIVED_CARS_SUCCESS,
    // car ids
    function prepare(payload: { car_ids: string[]; activeSort: string }) {
        return {
            payload,
        }
    }
)
export const updateUserTasksCountOnTasksCreation = createAction(
    UPDATE_USER_TASKS_COUNT_ON_CREATION
)

export const updateUserTasksCountOnTasksDeletion = createAction(
    UPDATE_USER_TASKS_COUNT_ON_DELETION
)

export const listUserHandoversSuccess = createAction(
    LIST_USER_HANDOVERS_SUCCESS,
    function prepare(payload: IUserHandoversObjByState) {
        return {
            payload,
        }
    }
)

export const updateArchivedCars = createAction(
    UPDATE_ARCHIVED_CARS,
    function prepare(payload: string[] | undefined) {
        return {
            payload,
        }
    }
)
