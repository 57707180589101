/// <reference types="googlemaps" />

import ButtonAtom from '../../atoms/Button/ButtonAtom'
import styled from 'styled-components'
import InputField from '../../atoms/Inputfield/inputField'
import useThemes from '../../../providers/theme/hooks'

const DesktopAddressContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 15px;
`

const FormPaddingContainer = styled.div`
    margin-top: 50px;
    margin-left: 10vw;
`

const DesktopAddressSubContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
`

const SearchField = styled.input`
    background-color: #fbfbfb;
    height: 45px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    width: 400px;
    margin-left: 10px;
    padding-left: 15px;
`

type Props = {
    street_number: any
    postal_town: any
    route: any
    postal_code: any
    country: any
    onSubmit: any
    handleChange: any
    isFormValid: any
}

export default function AddressFormDesktop(props: Props) {
    // STEPS:

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.

    const {
        street_number,
        route,
        postal_town,
        postal_code,
        country,
        onSubmit,
        handleChange,
        isFormValid,
    } = props

    const { theme } = useThemes()
    return (
        <FormPaddingContainer>
            <SearchField
                id="autocompletedesktop"
                placeholder="Search your address"
                type="text"
            />

            <DesktopAddressContainer id="address-search-desktop">
                <DesktopAddressSubContainer>
                    <InputField
                        theme={theme}
                        name="street_number"
                        type="text"
                        placeholder="Street number  /  House name"
                        value={street_number}
                        onChange={handleChange}
                        tabindex={1}
                        isnarrow={true}
                        onEnterKeyPress={onSubmit}
                    />
                </DesktopAddressSubContainer>

                <DesktopAddressSubContainer>
                    <InputField
                        theme={theme}
                        type="text"
                        name="route"
                        placeholder="Street address"
                        value={route}
                        onChange={handleChange}
                        tabindex={2}
                        onEnterKeyPress={onSubmit}
                    />
                </DesktopAddressSubContainer>
                <DesktopAddressSubContainer>
                    <InputField
                        theme={theme}
                        type="text"
                        name="postal_town"
                        placeholder="city"
                        value={postal_town}
                        onChange={handleChange}
                        isnarrow={true}
                        tabindex={3}
                        onEnterKeyPress={onSubmit}
                    />
                </DesktopAddressSubContainer>

                <DesktopAddressSubContainer>
                    <InputField
                        theme={theme}
                        type="text"
                        placeholder="zip code"
                        name="postal_code"
                        value={postal_code}
                        onChange={handleChange}
                        isnarrow={true}
                        tabindex={4}
                        onEnterKeyPress={onSubmit}
                    />
                </DesktopAddressSubContainer>
                <DesktopAddressSubContainer>
                    <InputField
                        theme={theme}
                        type="text"
                        placeholder="country"
                        name="country"
                        value={country}
                        onChange={handleChange}
                        isnarrow={true}
                        tabindex={5}
                        onEnterKeyPress={onSubmit}
                    />
                </DesktopAddressSubContainer>
            </DesktopAddressContainer>

            <div
                style={{
                    marginTop: '50px',
                    marginLeft: '15px',
                    opacity: isFormValid() === false ? 0.5 : 1,
                }}
            >
                <ButtonAtom
                    theme="secondary"
                    icon="email"
                    type="submit"
                    onClick={(e: any) => {
                        e.preventDefault()
                        onSubmit()
                    }}
                    disabled={isFormValid() === false ? true : false}
                >
                    Add your address
                </ButtonAtom>
            </div>
        </FormPaddingContainer>
    )
}
