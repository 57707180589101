import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import Faded from '../../animated/faded'
import { device } from '../devices'

function mapStateToProps(state: RootState) {
    return {
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

interface Props {
    children: React.ReactNode
    isOpen: boolean
    toggle: any
    modalBackgroundColor?: string
    isLightMode?: boolean
    customZIndex?: number
}

interface Istyle {
    isOpen?: boolean
    backgroundColor?: string
    isLightMode?: boolean
    customZIndex?: number
}

const DesktopPageWrapperStyle = styled.div<Istyle>`
    z-index: ${(props) =>
        props.customZIndex ? `${props.customZIndex}` : '12'};
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : props.isLightMode
            ? 'var(--off-bg-color, #fafafa)'
            : 'rgba(0, 0, 0, 0.686)'};
    height: 100%;
    width: 100%;

    top: 0px;
    bottom: 0px;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    left: 0px;
    right: 0px;
`

const LeftClickBlock = styled.div`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        display: block;
        position: absolute;
        top: 0;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100vh;
        width: 100vw;
    }
`

const dispatchProps = {}

class ModalDisplayFullPageDesktopExclusive extends React.Component<Props> {
    windowSize = window && window.innerWidth

    render() {
        const {
            isOpen,
            toggle,
            children,
            modalBackgroundColor,
            isLightMode,
            customZIndex,
        } = this.props
        return (
            <DesktopDisplayOnly>
                <div style={{ display: isOpen ? 'flex' : 'none' }}>
                    <Faded>
                        <DesktopPageWrapperStyle
                            isOpen={isOpen}
                            backgroundColor={modalBackgroundColor}
                            isLightMode={isLightMode}
                            customZIndex={customZIndex}
                        >
                            <LeftClickBlock
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    setTimeout(() => {
                                        toggle()
                                    }, 50)
                                }}
                            />

                            {children}
                        </DesktopPageWrapperStyle>
                    </Faded>
                </div>
            </DesktopDisplayOnly>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(ModalDisplayFullPageDesktopExclusive)
