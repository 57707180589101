import { useRef, useState } from 'react'
import styled from 'styled-components'
import { showroomActions } from '../../../../../redux/showroom/reducer'
import {
    IShowroomFiltersState,
    IFilterID,
    IShowroomFilterRadioOptionItem,
} from '../../../../../redux/showroom/types'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import CustomAnimatedCheckboxField from '../../../../atoms/formField/customAnimatedCheckboxField'
import CustomAnimatedRadioField from '../../../../atoms/formField/customAnimatedRadioField'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import CustomTagQuickFilterItemDesktop from '../../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import RangeRowFieldsDesktop from '../../../../molecules/editOrCreateModeSingleFields/rangeRow/rangeRowDesktop'
import ShowroomFilterSearchDropdownDesktop from '../../../../molecules/showroom/dropdowns/showroomFilterSearchDropdownDesktop'
import ShowroomFilterLocationDesktopBodyForm from '../../../../molecules/showroom/filter/showroomFilterLocationDesktopBodyForm'
import { useOnClickOutside } from '../../../../templates/clickOutside'
import AreObjectsEqual from '../../../../../helpers/objChecks'
import { areArraysValuesEqual } from '../../../../../helpers/arrayChecks/are2arraysEqual'
import NoDropdownDataBox from '../../../../atoms/box/noDropdownDataBox'
import { useShowroom } from '../../../../../providers/showroom'

const Row = styled.div`
    /* position: absolute;
    bottom: 0; */
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;
    align-self: flex-end;
    height: 75px;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    padding-left: 20px;
    padding-right: 20px;
`
const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    position: relative;
`

const SubContainer = styled.div`
    width: 100%;
    display: flex;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-height: 50vh;
    overflow-y: scroll;

    min-height: 110px;

    /* overflow-x: hidden; */

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #d1d1d1;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #d1d1d1;
    }
`

const Caption = styled.div`
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
`
const ContentWrapper = styled.div`
    transform: translateY(10px);
    padding-bottom: 10px;
`

const Row2 = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: start;
    padding-right: 30px;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
`

type Props = {
    clearFunc: () => any
    handleChangeSearchEffects: () => any
}

const ShowroomFilterFormManagerDesktop = (props: Props) => {
    let editingFiltersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    let activeFiltersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.filters
    })
    let openedFilterID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    let isSearchLoading: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isSearchLoading
    })

    const [currentKeywordValue, setCurrentKeywordValue] = useState<string>('')

    const dispatch = useAppDispatch()

    const renderOptions = () => {
        return (
            <div
                style={{
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    width: '100%',
                }}
            >
                {
                    // @ts-ignore
                    editingFiltersState[openedFilterID].options &&
                        // @ts-ignore
                        editingFiltersState[openedFilterID].options!.map(
                            (
                                item: IShowroomFilterRadioOptionItem,
                                index: number
                            ) => {
                                return (
                                    <CustomAnimatedRadioField
                                        name={item.name}
                                        id={item.id}
                                        checked={
                                            // @ts-ignore
                                            editingFiltersState[openedFilterID]
                                                .value === item.id
                                                ? true
                                                : false
                                        }
                                        onChange={(id: string) => {
                                            dispatch(
                                                showroomActions.setRadioSelect_filter(
                                                    {
                                                        // @ts-ignore
                                                        id: openedFilterID,
                                                        item: item,
                                                    }
                                                )
                                            )
                                        }}
                                        rowHeight={'36px'}
                                        extra_small
                                        hasWhiteBg
                                    />
                                )
                            }
                        )
                }
            </div>
        )
    }

    const renderMultiSelectNoSearch = () => {
        return editingFiltersState && openedFilterID ? (
            <div
                style={{
                    paddingBottom: '10px',
                    width: '100%',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                }}
            >
                {!isSearchLoading &&
                    // @ts-ignore
                    (!editingFiltersState[openedFilterID].options ||
                        // @ts-ignore
                        editingFiltersState[openedFilterID].options.length ===
                            0) && (
                        <NoDropdownDataBox
                            name={
                                openedFilterID &&
                                editingFiltersState[openedFilterID].name
                                    ? `${editingFiltersState[
                                          openedFilterID
                                      ].name?.toLowerCase()}`
                                    : 'item'
                            }
                        />
                    )}
                {
                    // @ts-ignore
                    editingFiltersState[openedFilterID].options &&
                        // @ts-ignore
                        editingFiltersState[openedFilterID].options.length >
                            0 &&
                        // @ts-ignore
                        editingFiltersState[openedFilterID].options!.map(
                            (
                                item: IShowroomFilterRadioOptionItem,
                                index: number
                            ) => {
                                return (
                                    <CustomAnimatedCheckboxField
                                        name={item.name}
                                        id={item.id}
                                        checked={
                                            // @ts-ignore
                                            editingFiltersState[ // @ts-ignore
                                                openedFilterID
                                                // @ts-ignore
                                            ].values
                                                .indexOf(item.id) === -1
                                                ? false
                                                : true
                                        }
                                        onChange={() => {
                                            dispatch(
                                                showroomActions.setMultiSelect_filter(
                                                    {
                                                        // @ts-ignore
                                                        id: openedFilterID,
                                                        value: item.id,
                                                    }
                                                )
                                            )
                                        }}
                                        rowHeight={'36px'}
                                        extra_small
                                        hasWhiteBg
                                    >
                                        <div style={{ paddingLeft: '15px' }}>
                                            {item.name}
                                        </div>
                                    </CustomAnimatedCheckboxField>
                                )
                            }
                        )
                }
            </div>
        ) : null
    }

    const renderRanges = () => {
        // if filtersState[openedFilterID].type === ''
        return openedFilterID ? (
            <RangeRowFieldsDesktop
                filterid={openedFilterID}
                // @ts-ignore
                valueFrom={editingFiltersState[openedFilterID].valueFrom}
                // @ts-ignore
                valueTo={editingFiltersState[openedFilterID].valueTo}
                // @ts-ignore
                rangeMin={editingFiltersState[openedFilterID].min_backend}
                // @ts-ignore
                rangeMax={editingFiltersState[openedFilterID].max_backend}
                onChange={(
                    valueFrom: number | null | undefined,
                    valueTo: number | null | undefined
                ) => {
                    dispatch(
                        showroomActions.setRange_filter({
                            // @ts-ignore
                            id: openedFilterID,
                            valueFrom: valueFrom,
                            valueTo: valueTo,
                        })
                    )
                }}
            />
        ) : null
    }
    let renderForm = () => {
        switch (openedFilterID) {
            case 'price':
                return (
                    <ContentWrapper>
                        <div style={{ paddingTop: '10px' }} />
                        {renderRanges()}
                    </ContentWrapper>
                )
            case 'year':
                return (
                    <ContentWrapper>
                        {renderRanges()}
                        <div style={{ paddingTop: '10px' }} />
                        <Caption style={{ fontFamily: 'Lato-Bold' }}>
                            {editingFiltersState.year.caption}
                        </Caption>
                        {editingFiltersState.year.options &&
                            editingFiltersState.year.options.map(
                                (
                                    item: IShowroomFilterRadioOptionItem,
                                    index: number
                                ) => {
                                    return (
                                        <CustomAnimatedRadioField
                                            name={item.name}
                                            id={item.id}
                                            checked={
                                                editingFiltersState.year
                                                    .value === item.id
                                                    ? true
                                                    : false
                                            }
                                            onChange={(id: string) => {
                                                dispatch(
                                                    showroomActions.setRadioSelect_filter(
                                                        {
                                                            id: 'year',
                                                            item: item,
                                                        }
                                                    )
                                                )

                                                // if there's an year ranges validation error
                                                // we clean it up
                                                // if a radio option is selected
                                                if (
                                                    filterValidationError &&
                                                    filterValidationError[
                                                        'year'
                                                    ]
                                                ) {
                                                    dispatch(
                                                        showroomActions.setFilterValidationError(
                                                            {
                                                                id: 'year',
                                                                error: undefined,
                                                            }
                                                        )
                                                    )
                                                }
                                            }}
                                            rowHeight={'36px'}
                                            extra_small
                                            hasWhiteBg
                                        />
                                    )
                                }
                            )}
                    </ContentWrapper>
                )
            case 'keyword':
                return (
                    <ContentWrapper
                        style={{ maxWidth: '90%', paddingTop: '15px' }}
                        onKeyDown={(e: any) => {
                            if (e.keyCode === 13) {
                                dispatch(
                                    showroomActions.setKeyword_filter(
                                        currentKeywordValue
                                    )
                                )

                                setCurrentKeywordValue('')
                            }
                        }}
                    >
                        <InputFieldNoFormikAnimated
                            value={currentKeywordValue}
                            name="keyword"
                            placeholder="keyword"
                            onChange={(e: any) => {
                                setCurrentKeywordValue(e.target.value)
                            }}
                        />
                        <div style={{ paddingTop: '10px' }} />

                        <Caption style={{ fontFamily: 'Lato' }}>
                            {editingFiltersState.keyword.caption}
                        </Caption>

                        {editingFiltersState.keyword.values &&
                        editingFiltersState.keyword.values.length > 0 ? (
                            <Row2>
                                {
                                    // @ts-ignore
                                    editingFiltersState.keyword.values.map(
                                        (val: string, index: number) => {
                                            return (
                                                <div
                                                    key={`${index}-keyword-desktop`}
                                                    style={{
                                                        paddingBottom:
                                                            index !==
                                                            // @ts-ignore
                                                            editingFiltersState
                                                                .keyword.values
                                                                .length -
                                                                1
                                                                ? '10px'
                                                                : 'none',
                                                        // @ts-ignore
                                                        paddingRight:
                                                            // @ts-ignore
                                                            editingFiltersState
                                                                .keyword.values
                                                                .length - 1
                                                                ? '10px'
                                                                : 'none',
                                                    }}
                                                >
                                                    <CustomTagQuickFilterItemDesktop
                                                        key={`${index}-keywiord}`}
                                                        height="24px"
                                                        onCrossClick={() => {
                                                            dispatch(
                                                                showroomActions.removeMultiSelectItem_request(
                                                                    {
                                                                        //@ts-ignore
                                                                        id: 'keyword',
                                                                        value: val,
                                                                    }
                                                                )
                                                            )
                                                        }}
                                                        bgcolor={'#5EC3CA'}
                                                        hasBoxShadow={false}
                                                        isActive={true}
                                                    >
                                                        <span
                                                            style={{
                                                                color: '#fff',
                                                                paddingLeft:
                                                                    '2px',
                                                            }}
                                                        >
                                                            {val}
                                                        </span>
                                                    </CustomTagQuickFilterItemDesktop>
                                                    <div
                                                        style={{
                                                            paddingRight:
                                                                '10px',
                                                        }}
                                                    />
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </Row2>
                        ) : null}
                    </ContentWrapper>
                )
            case 'type_of_sale':
                return renderOptions()

            case 'drive_side':
                return renderOptions()

            case 'mileage':
                return (
                    <ContentWrapper>
                        <div style={{ paddingTop: '10px' }} />
                        {renderRanges()}
                    </ContentWrapper>
                )
            case 'make':
                return <ShowroomFilterSearchDropdownDesktop />
            case 'model':
                return <ShowroomFilterSearchDropdownDesktop />
            case 'body_type':
                return <ShowroomFilterSearchDropdownDesktop />
            case 'location':
                return <ShowroomFilterLocationDesktopBodyForm />
            case 'colour':
                return renderMultiSelectNoSearch()
            default:
                return <div>default form</div>
        }
    }

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => {
        if (openedFilterID !== 'location') {
            dispatch(showroomActions.setFilterOpen(undefined))
            dispatch(showroomActions.cancelShowroomFilters(undefined))
        }
    })

    const filterValidationError = useAppSelector(
        (state) => state.showroom.filterValidationError
    )

    let direct_values_ids = [
        'make',
        'model',
        'body_type',
        'colour',
        'types_of_sale',
        'keyword',
    ]

    let isDisabled = () => {
        let res = false
        if (openedFilterID) {
            let current = activeFiltersState[openedFilterID]

            let newObj = editingFiltersState[openedFilterID]

            if (direct_values_ids.includes(openedFilterID)) {
                let isArrEqual = areArraysValuesEqual(
                    // @ts-ignore
                    newObj.values,
                    // @ts-ignore
                    current.values
                )

                if (isArrEqual) {
                    res = true
                }
            } else {
                let isEqual = AreObjectsEqual(current, newObj)

                if (isEqual) {
                    res = true
                }
            }
        }

        return res
    }

    let { synchReduxSearchToQueryParam } = useShowroom()
    return (
        <Container
            ref={ref}
            onKeyDown={(e: any) => {
                if (
                    openedFilterID !== 'location' &&
                    openedFilterID !== 'keyword'
                ) {
                    if (e.keyCode === 13) {
                        props.handleChangeSearchEffects()
                        // dispatch(showroomActions.searchShowroomRequest())
                        dispatch(showroomActions.setFilterOpen(undefined))
                    }
                } else {
                    return null
                }
            }}
        >
            <SubContainer>{renderForm()}</SubContainer>

            <div style={{ paddingTop: '10px' }} />
            <Row>
                <ButtonAtom
                    fontSize={14}
                    theme="lowercase-white-background"
                    width={'55px'}
                    onClick={() => {
                        props.clearFunc()
                    }}
                >
                    <div style={{ textTransform: 'none' }}>Clear</div>
                </ButtonAtom>
                <div style={{ paddingLeft: '10px' }} />
                <ButtonAtom
                    fontSize={14}
                    theme="lowercase-blue-background"
                    width={'55px'}
                    onClick={() => {
                        if (openedFilterID === 'keyword') {
                            dispatch(
                                showroomActions.setKeyword_filter(
                                    currentKeywordValue
                                )
                            )
                            setCurrentKeywordValue('')
                        }
                        dispatch(showroomActions.setFilterOpen(undefined))

                        props.handleChangeSearchEffects()

                        setTimeout(() => {
                            synchReduxSearchToQueryParam()
                        }, 200)
                        // dispatch(showroomActions.searchShowroomRequest())
                    }}
                    disabled={
                        isDisabled() === true
                            ? true
                            : filterValidationError &&
                              Object.keys(filterValidationError).length > 0
                            ? true
                            : false
                    }
                >
                    <div style={{ textTransform: 'none' }}>Apply</div>
                </ButtonAtom>
            </Row>
        </Container>
    )
}

export default ShowroomFilterFormManagerDesktop
