import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import ChevronDown from '../icons/components/chevronDown'

type IStyle = {
    $disabled?: boolean
    $noValue?: boolean
    $height?: string
    $width?: string
    $customActiveColour?: string
}

const PickerButton = styled(motion.button)<IStyle>`
    position: relative;
    width: ${(props) => (props.$width ? props.$width : '100%')};
    height: ${(props) => (props.$height ? props.$height : 'auto')};
    min-height: 48px;
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 4px;
    ${(props) => `
        border: ${
            props.$disabled
                ? 'none'
                : props.$noValue
                ? `1px solid ${
                      props.$customActiveColour ?? 'var(--primary, #5ec3ca)'
                  }`
                : 'none'
        }
    `}
`

const PickerButtonContents = styled.div<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    color: var(--text-strong, #1a1a1a);
    font-size: 15px;
    font-family: Lato;

    ${(props) => `
        opacity: ${props.$disabled ? 0.5 : 1}
    `}
`

const PickerButtonAdornmentIcon = styled.div`
    margin-left: 4px;
    transform: rotate(-90deg);
`

const Label = styled.label<{ backgroundColor?: string }>`
    position: absolute;

    top: 0;
    transform-origin: top left;
    transform: translate(8px, -50%) scale(0.8);

    left: 0;
    min-width: 30px;
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : 'var(--bg-color, #fff)'};

    transition: 0.15s;

    color: var(--text-darker, #616161);
    font-size: 1.1em;
    padding: 0 8px;
    pointer-events: none;
    font-family: Lato;

    &:first-letter {
        text-transform: uppercase;
    }
`

type Props = {
    disabled?: boolean
    onClick?: any
    dataCyId?: string | undefined
    value?: string
    placeholder?: string
    fwArrow?: boolean
    downArrow?: boolean
    height?: string
    width?: string
    bg?: string
    capitalise?: boolean
    textTransform?: any
    customActiveColour?: string
}

const PickerBtnInsurance: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <PickerButton
            whileTap={{ scale: 1.05 }}
            data-attr={props.dataCyId}
            onClick={props.onClick}
            $noValue={!props.value ? true : false}
            $customActiveColour={props.customActiveColour}
            style={
                props.fwArrow || props.downArrow
                    ? {
                          border: 'var(--text-muted) 1px solid',
                          backgroundColor: props.bg ?? 'white',
                          textTransform: props.capitalise
                              ? 'capitalize'
                              : 'none',
                      }
                    : {
                          textTransform: props.capitalise
                              ? 'capitalize'
                              : 'none',
                      }
            }
            $height={props.height}
            $width={props.width}
        >
            <PickerButtonContents
                $disabled={props.disabled}
                style={
                    props.fwArrow || props.downArrow
                        ? {
                              paddingLeft: 10,
                          }
                        : {}
                }
            >
                {props.value && (
                    <div
                        style={{
                            textAlign: 'left',
                            maxWidth: '95%',
                            textTransform: props.textTransform ?? undefined,
                        }}
                    >{`${props.value}`}</div>
                )}
                {!props.value && (
                    <div
                        style={{
                            fontFamily: ' Lato-Light',
                            color: 'var(--text-default',
                            fontSize: '16px',
                            textAlign: 'left',
                            maxWidth: '90%',
                        }}
                    >
                        {props.placeholder ?? 'Please select ...'}
                    </div>
                )}
                <PickerButtonAdornmentIcon>
                    <ChevronDown />
                </PickerButtonAdornmentIcon>
            </PickerButtonContents>
            {!!props.value && (
                <Label
                    style={{ width: 'max-content' }}
                    backgroundColor={props.bg}
                >
                    {props.placeholder}
                </Label>
            )}
        </PickerButton>
    )
}
export default PickerBtnInsurance
