import { useEffect, useMemo, useState } from 'react'
import {
    RouteComponentProps,
    useHistory,
    useParams,
    withRouter,
} from 'react-router'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'

import ShowroomCarMainImageDesktop from '../../../atoms/image/showroom/showroomCarMainImageDesktop'

import FullWidthMobile from '../../../atoms/image/fullWidthMobile'

import DescriptionSectionDesktop from '../../../molecules/showroom/section/descriptionSectionDesktop'
import DescriptionSectionMobile from '../../../molecules/showroom/section/descriptionSectionMobile'
import FactsGridSectionMobile from '../../../molecules/showroom/section/factsGridSectionMobile'
import KeyMomentsSectionDesktop from '../../../molecules/showroom/section/keyMomentsSectionDesktop'
import KeyMomentsSectionMobile from '../../../molecules/showroom/section/keyMomentsSectionMobile'
import OverviewSectionDesktop from '../../../molecules/showroom/section/overviewSectionDesktop'
import OverviewSectionMobile from '../../../molecules/showroom/section/overviewSectionMobile'
import OwnerSectionDesktop from '../../../molecules/showroom/section/ownerSectionDesktop'
import OwnerSectionMobile from '../../../molecules/showroom/section/ownerSectionMobile'
import { device } from '../../../templates/displays/devices'
import OwnerSectionMobileWithCarPic from '../../../molecules/showroom/section/ownerSectionMobileWithCarPic'
import ShowroomOverviewGalleryDesktop from '../../../molecules/imageGrid/showroomOverviewGalleryDesktop'

import { ICar, IGalleryImage, IGalleryImagesObject } from 'entityModels'
import DuoGridItemMobile from '../../../atoms/image/duoGridItemMobile'
import ShowroomVideoEmbedMobile from '../../../atoms/video/showroomVideoEmbedMobile'
import ShowroomVideoEmbedDesktop from '../../../atoms/video/showroomVideoEmbedDesktop'
import CarShowroomTopBar from '../../../templates/bars/showroomBars/topBars/carShowroomTopBar'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { sortList } from '../../../molecules/car/CarKeyMomentsfactsDesktop'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import ModalDisplayFullPageDesktopExclusive from '../../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import CarShowroomEntryShareModal from '../../../molecules/showroom/editModals/desktop/carShowroomEntryShareModal'
import ModalDisplayMobileIpad from '../../../templates/displays/pageWrappers/modalDisplayMobileExclusive'

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ScrollIndicator from '../../../atoms/Button/scrollIndicator'
import { useCheckIfTopPage } from '../../../../helpers/hooks/checkScrollDirection'
import ShowroomBannerImageOwnerDesktop from '../../../atoms/image/showroom/bannerImageOwner'
import { generateImageSources } from '../../../../helpers/images'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import ShowroomPublicFooterMobile from '../../../templates/footer/showroomPublicFooter/showroomPublicFooterMobile'
import ShowroomPublicFooterDesktop from '../../../templates/footer/showroomPublicFooter/showroomPublicFooterDesktop'
import { ShowroomEntryRecommendationsManager } from '../../../molecules/showroom/recommendations/manager'
import JourneyRedirectCardDesktop from '../../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../../redux/localdata/journey/copyData'

const SectionWrapperWidth = styled.div`
    width: 80vw;

    @media (max-width: 1030px) {
        width: 92vw;
    }

    @media (min-width: 1600px) {
        max-width: 80vw;
    }

    @media (min-width: 1800px) {
        max-width: 80vw;
    }
    @media (min-width: 2500px) {
        max-width: 60vw;
    }
`

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const MobileSectionWrapper = styled.div`
    padding-right: 1.5rem;
    padding-left: 1.5rem;
`
const DesktopGreyWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const MobileGreyWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const DividerLineDesktop = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 80%;
`

const GalleryWrapperMobile = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    width: 100%;
`
const ShowroomModalInnerContainer = styled.div`
    height: max-content;
    width: max-content;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 2px;

    @media ${device.mobile_and_ipad} {
        width: 95vw;
    }

    @media ${device.beyond_ipad_mobile} {
        min-width: 500px;
    }

    z-index: 20;
    transition: all 100ms ease-in-out;
    padding: 24px;
`

const FixedPositionedScrollIndicatorWrapper = styled.div`
    position: fixed;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
`

type EntryParams = {
    entryid: string
}

const ShowroomCarProfile: React.FunctionComponent<RouteComponentProps> = (
    props: RouteComponentProps
) => {
    const { entryid } = useParams<EntryParams>()

    // redux hook for dispatch state actions
    const dispatch = useAppDispatch()
    /* 
    remove top sub menu from state
    TODO: (only for useSelector hook) need to gradually move to "createSelector" approach, for memoization / huge performance impact
    */
    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let normalisedPublicEntries: INormalisedShowroomEntries | undefined =
        useAppSelector((state) => {
            return state.showroom.normalisedPublicEntries
        })

    let currentEntry: IShowroomEntry | undefined =
        entryid && normalisedPublicEntries && normalisedPublicEntries[entryid]
            ? normalisedPublicEntries[entryid]
            : undefined

    let isShowroomEntryLoading: boolean | undefined = useAppSelector(
        (state) => {
            return state.showroom.isShowroomEntryLoading
        }
    )

    let carProfile: ICar | undefined = currentEntry
        ? currentEntry.car
        : undefined

    const [hasCalledRec, setHasCalledRec] = useState<boolean>(false)

    useEffect(() => {
        if (currentEntry && !hasCalledRec) {
            setHasCalledRec(true)
            dispatch(
                showroomActions.getShowroomEntryRecommendationsRequest({
                    showroomEntry: currentEntry,
                })
            )
        }
    }, [currentEntry])

    useEffect(() => {
        // if (window.matchMedia('(display-mode: standalone)').matches) {
        //     dispatch(customNavDataActions.setInitialLandingUrl(`/showroom`))
        //     dispatch(getCurrentUserDataRequest())
        // }
    }, [])

    useEffect(() => {
        if (!currentEntry) {
            let refresh_token: string | null =
                typeof localStorage !== 'undefined' && localStorage !== null
                    ? localStorage.getItem('refresh_token')
                    : null

            if (userLoggedIn) {
                dispatch(
                    showroomActions.getShowroomEntryByIDRequest({
                        is_owner: false,
                        entryID: entryid,
                    })
                )
            } else if (!userLoggedIn && refresh_token !== null) {
                dispatch(getCurrentUserDataRequest())
                dispatch(
                    showroomActions.getShowroomEntryByIDRequest({
                        is_owner: false,
                        entryID: entryid,
                    })
                )
            } else {
                dispatch(
                    showroomActions.getPublicShowroomEntryByIDRequest(entryid)
                )
            }
        }

        // trigger the function to dispatch the top sub menu remove action
        removeTopSubMenu({ isRemoved: true })
        // get current car profile image

        // set up nav back as before on page unmount
        return () => {
            removeTopSubMenu({ isRemoved: false })
        }
    }, [currentEntry])

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let car_profile_image: IGalleryImage | undefined =
        currentEntry && currentEntry.cover_image
            ? currentEntry.cover_image
            : currentEntry &&
              currentEntry.car &&
              currentEntry.car.gallery.cover &&
              images_data &&
              images_data[currentEntry.car.gallery.cover]
            ? images_data[currentEntry.car.gallery.cover]
            : undefined

    let keyMomentsFactsData = useAppSelector((state) => {
        return state.entities.highlightedFactsData.highlighted_facts
    })

    let keyMomentsFacts = useMemo((): string[] => {
        if (carProfile && carProfile.highlighted_facts && keyMomentsFactsData) {
            let objList: any[] = []

            carProfile.highlighted_facts.forEach((id: string, index) => {
                if (keyMomentsFactsData[id] && keyMomentsFactsData[id].text) {
                    objList = [...objList, keyMomentsFactsData[id]]
                }
            })
            let ordered_list = sortList(objList)

            let list: string[] = ordered_list.map((obj) => {
                return `${obj.text}`
            })
            return list
        } else return []
    }, [keyMomentsFactsData, carProfile])

    let carGalleryImages: IGalleryImage[] =
        currentEntry && currentEntry.gallery_images
            ? currentEntry.gallery_images
            : []

    const [isShareOpen, setIsShareOpen] = useState(false)

    let params = new URLSearchParams(location.search)
    let isPreview = params.get('is_preview')
    let is_watchlist = params.get('is_watchlist')

    let showScrollIndicator = useCheckIfTopPage()

    let pageN: string = params.get('page') ? `&page=${params.get('page')}` : ''

    let { width } = useWindowSize()

    let isSomethingLoading =
        isShowroomEntryLoading || !currentEntry ? true : false

    const history = useHistory()

    return (
        <>
            <DesktopDisplayOnly>
                <div style={{ paddingTop: '5em' }} />
                <ModalDisplayFullPageDesktopExclusive
                    isOpen={isShareOpen}
                    toggle={() => {
                        setIsShareOpen(false)
                        // setModalOpened(null)
                    }}
                >
                    <ShowroomModalInnerContainer>
                        <CarShowroomEntryShareModal
                            entryid={entryid}
                            close={() => {
                                setIsShareOpen(false)
                            }}
                        />
                    </ShowroomModalInnerContainer>
                </ModalDisplayFullPageDesktopExclusive>
                <CarShowroomTopBar
                    onClickEnquire={() => {
                        props.history.push(`/showroom/${entryid}/enquiry`)
                    }}
                    isMobile={false}
                    entryID={entryid}
                    isReadOnly={true}
                    is_watched={currentEntry?.is_watched ? true : false}
                    onClickShare={() => {
                        setIsShareOpen(true)
                    }}
                    isSold={currentEntry?.state === 'SOLD' ? true : false}
                    goBack={() => {
                        let params = new URLSearchParams(location.search)
                        if (isPreview) {
                            return props.history.push(
                                `/showroom/${entryid}/edit`
                            )
                        }
                        if (is_watchlist) {
                            return props.history.push(
                                `/showroom/watch-list?anchor_entry=${entryid}${pageN}`
                            )
                        }

                        let ownerIDParams = params.get('owner_id')
                        let ownerNameParams = params.get('owner_display_name')

                        if (currentEntry?.state === 'SOLD') {
                            if (ownerIDParams) {
                                return props.history.push(
                                    `/showroom/sold?anchor_entry=${entryid}${pageN}&owner_id=${ownerIDParams}&owner_display_name=${ownerNameParams}`
                                )
                            } else {
                                return props.history.push(
                                    `/showroom/sold?anchor_entry=${entryid}${pageN}`
                                )
                            }
                        } else {
                            if (ownerIDParams) {
                                return props.history.push(
                                    `/showroom/for-sale?anchor_entry=${entryid}${pageN}&owner_id=${ownerIDParams}&owner_display_name=${ownerNameParams}`
                                )
                            } else {
                                return props.history.push(
                                    `/showroom/for-sale?anchor_entry=${entryid}${pageN}`
                                )
                            }
                        }
                    }}
                    // onClose={
                    //     isPreview
                    //         ? undefined
                    //         : window.matchMedia('(display-mode: standalone)')
                    //               .matches
                    //         ? undefined
                    //         : () => {
                    //               window.close()
                    //           }
                    // }
                />
                <ShowroomCarMainImageDesktop
                    image={car_profile_image}
                    isLoading={isSomethingLoading}
                />
                <div style={{ paddingTop: '100px' }} />
                <SectionWrapper>
                    <SectionWrapperWidth>
                        <OverviewSectionDesktop
                            isLoading={isSomethingLoading}
                            entry={currentEntry}
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>
                <div style={{ paddingTop: '150px' }} />
                <SectionWrapper>
                    <SectionWrapperWidth>
                        <ShowroomBannerImageOwnerDesktop
                            src={
                                currentEntry && currentEntry.banner_image
                                    ? currentEntry.banner_image.url
                                    : 'placeholder'
                            }
                            srcSet={
                                currentEntry && currentEntry.banner_image
                                    ? generateImageSources(
                                          currentEntry.banner_image,
                                          width
                                      )
                                    : undefined
                            }
                        />
                        <OwnerSectionDesktop
                            carOwner={currentEntry?.owner ?? undefined}
                            entryid={entryid}
                            isSold={
                                currentEntry?.state === 'SOLD' ? true : false
                            }
                            isPOA={
                                currentEntry?.type_of_sale === 'PRICE_ON_ASKING'
                            }
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>
                <div style={{ paddingTop: '100px' }} />
                <SectionWrapper>
                    <SectionWrapperWidth>
                        <KeyMomentsSectionDesktop
                            keyMomentsList={keyMomentsFacts}
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>
                <div style={{ paddingTop: '150px' }} />
                <SectionWrapper>
                    <SectionWrapperWidth>
                        <DescriptionSectionDesktop
                            isLoading={isSomethingLoading}
                            currentEntry={currentEntry}
                            isAdmin={false}
                            onShowMoreClick={() =>
                                props.history.push(
                                    `/showroom/${entryid}/description`
                                )
                            }
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>
                <div style={{ paddingTop: '160px' }} />
                <DesktopGreyWrapper>
                    <div style={{ paddingTop: '80px' }} />
                    <ShowroomOverviewGalleryDesktop
                        overview_images={carGalleryImages}
                        readOnlyMode
                    />

                    {currentEntry?.video_url && (
                        <>
                            <div style={{ paddingTop: '80px' }} />
                            <DividerLineDesktop />
                            <div style={{ paddingTop: '80px' }} />
                        </>
                    )}

                    <ShowroomVideoEmbedDesktop
                        embedURL={currentEntry?.video_url}
                    />
                    <div style={{ paddingTop: '80px' }} />
                </DesktopGreyWrapper>
                <div style={{ paddingTop: '110px' }} />
                <SectionWrapper>
                    <SectionWrapperWidth>
                        <OwnerSectionDesktop
                            carOwner={currentEntry?.owner ?? undefined}
                            theme="border"
                            entryid={entryid}
                            isSold={
                                currentEntry?.state === 'SOLD' ? true : false
                            }
                            isPOA={
                                currentEntry?.type_of_sale === 'PRICE_ON_ASKING'
                            }
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>
                {currentEntry ? (
                    <SectionWrapper>
                        <div style={{ paddingTop: '110px' }} />
                        <SectionWrapperWidth>
                            <ShowroomEntryRecommendationsManager
                                entry={currentEntry}
                                isMobile={false}
                            />
                        </SectionWrapperWidth>
                    </SectionWrapper>
                ) : null}
                <div
                    style={{
                        width: '100%',
                        paddingTop: 50,
                        paddingBottom: 48,
                    }}
                >
                    <SectionWrapper>
                        <SectionWrapperWidth>
                            <JourneyRedirectCardDesktop
                                sectionID="sell_your_car"
                                copyData={journeyRedirectCardsCopy}
                                onStartNow={() => {
                                    history.push({
                                        pathname: `/journey/sell-your-car`,
                                        state: {
                                            prevPath: `${
                                                history.location.pathname
                                            }${history.location.search ?? ''}`,
                                        },
                                    })
                                }}
                                dataPH={'start_sell_your_car_journey'}
                            />
                        </SectionWrapperWidth>
                    </SectionWrapper>
                </div>
                {!userLoggedIn ? (
                    <div
                        style={{
                            paddingTop: 40,
                            width: '100%',
                        }}
                    >
                        <SectionWrapper>
                            <SectionWrapperWidth>
                                <ShowroomPublicFooterDesktop />
                            </SectionWrapperWidth>
                        </SectionWrapper>
                    </div>
                ) : (
                    <div style={{ paddingTop: '300px' }} />
                )}
                <FixedPositionedScrollIndicatorWrapper
                    style={{
                        zIndex: showScrollIndicator ? 5 : -1,
                        opacity: showScrollIndicator ? 1 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <ScrollIndicator />
                </FixedPositionedScrollIndicatorWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <ModalDisplayMobileIpad
                    isOpen={isShareOpen}
                    toggle={() => {
                        setIsShareOpen(false)
                    }}
                >
                    <ShowroomModalInnerContainer>
                        <CarShowroomEntryShareModal
                            entryid={entryid}
                            close={() => {
                                setIsShareOpen(false)
                            }}
                        />
                    </ShowroomModalInnerContainer>
                </ModalDisplayMobileIpad>
                <div style={{ paddingTop: '64px' }} />
                <CarShowroomTopBar
                    isMobile
                    onClickEnquire={() => {
                        props.history.push(`/showroom/${entryid}/enquiry`)
                    }}
                    entryID={entryid}
                    isReadOnly={true}
                    is_watched={currentEntry?.is_watched ? true : false}
                    onClickShare={() => {
                        setIsShareOpen(true)
                    }}
                    isSold={currentEntry?.state === 'SOLD' ? true : false}
                    goBack={() => {
                        if (isPreview) {
                            return props.history.push(
                                `/showroom/${entryid}/edit`
                            )
                        }
                        if (is_watchlist) {
                            return props.history.push(
                                `/showroom/watch-list?anchor_entry=${entryid}${pageN}`
                            )
                        }

                        let params = new URLSearchParams(location.search)
                        let ownerIDParams = params.get('owner_id')
                        let ownerNameParams = params.get('owner_display_name')

                        if (currentEntry?.state === 'SOLD') {
                            if (ownerIDParams) {
                                return props.history.push(
                                    `/showroom/sold?anchor_entry=${entryid}${pageN}&owner_id=${ownerIDParams}&owner_display_name=${ownerNameParams}`
                                )
                            } else {
                                return props.history.push(
                                    `/showroom/sold?anchor_entry=${entryid}${pageN}`
                                )
                            }
                        } else {
                            if (ownerIDParams) {
                                return props.history.push(
                                    `/showroom/for-sale?anchor_entry=${entryid}${pageN}&owner_id=${ownerIDParams}&owner_display_name=${ownerNameParams}`
                                )
                            } else {
                                return props.history.push(
                                    `/showroom/for-sale?anchor_entry=${entryid}${pageN}`
                                )
                            }
                        }
                    }}
                    // onClose={
                    //     isPreview
                    //         ? undefined
                    //         : window.matchMedia('(display-mode: standalone)')
                    //               .matches
                    //         ? undefined
                    //         : () => {
                    //               window.close()
                    //           }
                    // }
                />

                <FullWidthMobile
                    ipadHeight="500px"
                    img={car_profile_image}
                    isDarkTheme={false}
                />
                <div style={{ paddingTop: '24px' }} />
                <OverviewSectionMobile
                    isLoading={isSomethingLoading}
                    entry={currentEntry}
                />
                <div style={{ paddingTop: '48px' }} />
                <MobileSectionWrapper>
                    <FactsGridSectionMobile entry={currentEntry} />
                </MobileSectionWrapper>

                <div style={{ paddingTop: '64px' }} />

                <OwnerSectionMobileWithCarPic
                    carOwner={currentEntry?.owner ?? undefined}
                    car={carProfile}
                    bannerImage={
                        currentEntry && currentEntry.banner_image
                            ? currentEntry.banner_image
                            : undefined
                    }
                    entryid={entryid}
                    isSold={currentEntry?.state === 'SOLD' ? true : false}
                    isAdmin={false}
                    isPOA={currentEntry?.type_of_sale === 'PRICE_ON_ASKING'}
                />

                <div style={{ paddingTop: '64px' }} />
                <MobileSectionWrapper>
                    <KeyMomentsSectionMobile keyMomentsList={keyMomentsFacts} />
                </MobileSectionWrapper>

                <div style={{ paddingTop: '52px' }} />

                <MobileSectionWrapper>
                    <DescriptionSectionMobile
                        isLoading={isSomethingLoading}
                        currentEntry={currentEntry}
                        onShowMoreClick={() =>
                            props.history.push(
                                `/showroom/${entryid}/description`
                            )
                        }
                    />
                </MobileSectionWrapper>

                <div style={{ paddingTop: '52px' }} />

                <MobileGreyWrapper>
                    <div style={{ paddingTop: '48px' }} />

                    <GalleryWrapperMobile>
                        {carGalleryImages.map(
                            (image: IGalleryImage, index: number) => {
                                return (
                                    <Link
                                        to={{
                                            pathname: `/showroom/${entryid}/gallery`,
                                            search: `?id=${image.id}`,
                                            state: {
                                                prevPath: props.match.url,
                                            },
                                        }}
                                    >
                                        <DuoGridItemMobile
                                            customHeight="116px"
                                            img={image}
                                            // customIpadHeight="150px"
                                            //  placeholder={placeholder}
                                        />
                                    </Link>
                                )
                            }
                        )}
                    </GalleryWrapperMobile>

                    {currentEntry?.video_url && (
                        <>
                            <div style={{ paddingTop: '40px' }} />
                            <DividerLineDesktop />
                            <div style={{ paddingTop: '40px' }} />
                        </>
                    )}
                    <ShowroomVideoEmbedMobile
                        embedURL={currentEntry?.video_url}
                    />

                    <div style={{ paddingTop: '48px' }} />
                </MobileGreyWrapper>

                <div style={{ paddingTop: '64px' }} />

                <MobileSectionWrapper>
                    <OwnerSectionMobile
                        entryid={entryid}
                        carOwner={currentEntry?.owner}
                        isSold={currentEntry?.state === 'SOLD' ? true : false}
                        isAdmin={false}
                        isPOA={currentEntry?.type_of_sale === 'PRICE_ON_ASKING'}
                    />
                </MobileSectionWrapper>

                {currentEntry ? (
                    <ShowroomEntryRecommendationsManager
                        isMobile={true}
                        entry={currentEntry}
                    />
                ) : null}

                {/* <MobileSectionWrapper>
                    <JourneyPatternCardMobile
                        sectionID="sell_your_car"
                        copyData={journeyRedirectCardsCopy}
                        onDismiss={() => {}}
                    />
                </MobileSectionWrapper> */}

                {!userLoggedIn ? (
                    <div
                        style={{
                            paddingTop: 40,
                            paddingLeft: '1.5rem',
                            paddingRight: '1.5rem',
                            width: '100%',
                        }}
                    >
                        <ShowroomPublicFooterMobile />
                    </div>
                ) : (
                    <div style={{ paddingTop: '300px' }} />
                )}
            </IpadAndMobileDisplay>
        </>
    )
}

export default withRouter(ShowroomCarProfile)
