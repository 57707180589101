/* eslint-disable no-useless-escape */
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Paragraph } from '../../../../../atoms/typography'

import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    padding-top: 20px;
    height: auto;
    background-color: var(--bg-color, #fff);
    color: black;
    border-radius: 5px;
    position: relative;

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 16px;
    text-transform: capitalize;
`

const CancelBnt = styled.div`
    padding-top: 30px;
    align-self: center !important;
    justify-self: center !important;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {
        error: state.user.error,
    }
}

interface Values {}

interface OtherProps {
    toggle: any
    submitFunc: any
    setToResetPasswordClicked: any
    hasResetPasswordBeenClicked: boolean
    userLoggedIn: any
    error: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { hasResetPasswordBeenClicked, error } = props

    return (
        <EditFormContainerMobile data-attr={`change-password-modal-mobile`}>
            <InnerFormPadding>
                <FieldTitle>Update password</FieldTitle>

                {hasResetPasswordBeenClicked === true ? (
                    <div style={{ paddingTop: '30px', paddingBottom: '30px' }}>
                        {error === null ? (
                            'Thank you. An email has been sent to reset your password.'
                        ) : (
                            <Paragraph>
                                Sorry, there has been an error.{' '}
                                <div
                                    style={{
                                        paddingTop: '30px',
                                    }}
                                />
                                <ButtonAtom
                                    theme="lowercase-white-background"
                                    onClick={() => {
                                        props.setToResetPasswordClicked()
                                    }}
                                    width="80vw"
                                    height="50px"
                                >
                                    <span style={{ color: '#5EC3CA' }}>
                                        Retry
                                    </span>
                                </ButtonAtom>
                            </Paragraph>
                        )}
                    </div>
                ) : (
                    <div
                        style={{
                            paddingTop: '50px',
                        }}
                    >
                        <ButtonAtom
                            theme="lowercase-blue-background"
                            onClick={() => {
                                props.setToResetPasswordClicked()
                                props.submitFunc(props.userLoggedIn.email)
                            }}
                            width="80vw"
                            height="50px"
                        >
                            SEND ME AN EMAIL
                        </ButtonAtom>
                    </div>
                )}
            </InnerFormPadding>
            <CancelBnt>
                <ButtonAtom
                    onClick={(e: any) => {
                        e.preventDefault()
                        setTimeout(function () {
                            props.toggle()
                        }, 50)
                    }}
                    theme="naked-text"
                >
                    {hasResetPasswordBeenClicked !== true ? 'cancel' : 'return'}
                </ButtonAtom>
            </CancelBnt>

            <div style={{ paddingTop: '20px' }} />
        </EditFormContainerMobile>
    )
}

const ChangePasswordAccountFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({}),

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(ChangePasswordAccountFormMobile)
