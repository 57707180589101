import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
`

const Skip = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 12px;
    color: #8f8f8f;
    cursor: pointer;
    font-family: 'Lato-light';
    letter-spacing: 1px;

    @media ${device.mobile} {
    }
`

const NextBtn = styled.div`
    position: absolute;
    right: 30px;
    bottom: 20px;
    color: #5ec3ca;
    cursor: pointer;
    font-size: 17px;
    font-family: lato-light;
`

const PrevBtn = styled.div`
    position: absolute;
    left: 30px;
    bottom: 20px;
    color: #5ec3ca;
    cursor: pointer;
    font-size: 17px;
    font-family: lato-light;
`

interface Props {
    children?: React.ReactNode
    skip?: any
    skipText?: string
    prev?: any
    prevBtnText?: string
    next?: any
    nextBtnText?: string
}

const TutorialWrapperDesktop: React.SFC<Props> = ({
    children,
    skip,
    skipText,
    prev,
    prevBtnText,
    next,
    nextBtnText,
}) => (
    <Wrapper>
        {skip && <Skip onClick={skip}>{skipText ? skipText : 'Skip'}</Skip>}
        {/* <Skip onClick={skip}>Skip</Skip> */}

        {children}

        {prev && (
            <PrevBtn onClick={prev}>
                {prevBtnText ? prevBtnText : 'prev'}
            </PrevBtn>
        )}

        {next && (
            <NextBtn onClick={next}>
                {nextBtnText ? nextBtnText : 'next'}
            </NextBtn>
        )}
    </Wrapper>
)

export default TutorialWrapperDesktop
