import {
    additionalSubFieldsClaims,
    additionalSubFieldsConvictions,
    additionalSubFieldsDisabilities,
    additionalSubFieldsMotoringConvictionsStrings,
} from '../conversions/insuranceApplication/api/customer'
import {
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplicationValidation,
    IInsuranceQuoteApplication_Car,
    IInsuranceQuoteApplication_MainDriver,
    IInsuranceQuoteApplication_OtherDriver,
} from '../insuranceQuoteApplication/reducer'
import {
    fields_array_insurance_application_details,
    fields_array_insurance_application_car_techinfo,
    insurance_fields_car_mileage_parking_1,
    fields_array_vehicle_modif,
    insurance_fields_car_mileage_parking_security_subform,
    insurance_fields_car_ownership,
    voluntary_excess_subfields,
    insurance_fields_main_driver_basic_details,
    insurance_fields_main_driver_licence_address,
    insurance_fields_main_driver_additional_details,
    insurance_fields_main_driver_track_record,
    insurance_fields_other_driver_basic_details,
    insurance_fields_other_driver_licence_address,
    insurance_fields_other_driver_track_record,
    fields_array_insurance_application_everyday_car_techinfo,
    insurance_fields_everyday_car_ownership,
    self_replicating_claim,
    insurance_sub_fields_other_driver_track_record_conviction_replicating,
    insurance_sub_fields_other_driver_track_record_motorconviction_replicating,
    disability_self_replicating_fields,
    insurance_fields_other_driver_additional_details,
    membership_self_replicating_fields,
    insurance_fields_parking_info,
    frequency_insurance_other_driver_ownership,
    ncd_additional_fields,
    travel_to_residential_address_yes_subfields,
    residential_visit_overnight_yes_subfields,
    insurance_fields_everydaycar_mileage_parking_same_place_day_and_night,
    insurance_fields_car_mileage_parking_added_address_different_than_residential,
    insurance_fields_car_mileage_parking_security,
    insurance_fields_car_mileage_parking_added_address_same_as_residential,
    has_vehicle_modifications_negative_answer_subform,
    has_vehicle_modifications_positive_answer_subform,
    has_modification_adjusted_bhp_positive_answer_subform,
    is_imported_positive_answer_subfields,
    written_off_subfields,
    is_uk_resident_positive_answer_subform,
    is_uk_resident_since_birth_negative_answer_subform,
    resides_in_the_uk_negative_answer_subform,
    other_driver_is_uk_resident_positive_answer_subform,
    other_driver_is_uk_resident_since_birth_negative_answer_subform,
    other_driver_resides_in_the_uk_negative_answer_subform,
} from '../localdata/insuranceLocalData/fieldTypes'
import { calculatePercentage } from './calculatePercentage'

type IStatsResults = {
    overallApplicationStats: {
        max: number
        completion: number
        percentage: number
    }
    cardsObjStats: {
        [key: string]: {
            max: number
            completion: number
            percentage: number
        }
    }
    mainPolicyHolder: {
        basic_details_max: number | undefined
        basic_details_completion: number | undefined
        basic_details_completion_percentage: number | undefined
        license_and_addresses_max: number | undefined
        license_and_addresses_completion: number | undefined
        license_and_addresses_completion_percentage: number | undefined
        additional_details_max: number | undefined
        additional_details_completion: number | undefined
        additional_details_completion_percentage: number | undefined
        track_record_max: number | undefined
        track_record_completion: number | undefined
        track_record_completion_percentage: number | undefined
    }
    insuredVehiclesObj: {
        [key: string]: {
            tech_info_overall_max: number | undefined
            tech_info_overall_completion: number | undefined
            tech_info_overall_completion_percentage: number | undefined
            car_mileage_and_parking_overall_max: number | undefined
            car_mileage_and_parking_overall_completion: number | undefined
            car_mileage_and_parking_overall_completion_percentage:
                | number
                | undefined
            ownership_overall_max: number | undefined
            ownership_overall_completion: number | undefined
            ownership_overall_completion_percentage: number | undefined
            overall_max: number | undefined
            overall_completion: number | undefined
            overall_completion_percentage: number | undefined
        }
    }
    everydayVehiclesObj: {
        [key: string]: {
            tech_info_overall_max: number | undefined
            tech_info_overall_completion: number | undefined
            tech_info_overall_completion_percentage: number | undefined
            car_mileage_and_parking_overall_max: number | undefined
            car_mileage_and_parking_overall_completion: number | undefined
            car_mileage_and_parking_overall_completion_percentage:
                | number
                | undefined
            ownership_overall_max: number | undefined
            ownership_overall_completion: number | undefined
            ownership_overall_completion_percentage: number | undefined
            overall_max: number | undefined
            overall_completion: number | undefined
            overall_completion_percentage: number | undefined
        }
    }
    driversCompletionObj: {
        [key: string]: {
            basic_info_max: number | undefined
            basic_info_completion: number | undefined
            basic_info_completion_percentage: number | undefined
            license_max: number | undefined
            license_completion: number | undefined
            license_completion_percentage: number | undefined
            additional_details_max: number | undefined
            additional_details_completion: number | undefined
            additional_details_completion_percentage: number | undefined
            track_record_max: number | undefined
            track_record_completion: number | undefined
            track_record_completion_percentage: number | undefined
            overall_completion_percentage: number | undefined
            overall_max: number | undefined
            overall_completion: number | undefined
        }
    }
}

let fields_array_insurance_application_car_techinfo_enriched = [
    ...fields_array_insurance_application_car_techinfo,
]

let bareMinimumOverallPointsOfAnInsuredVehicles =
    fields_array_insurance_application_car_techinfo_enriched.length +
    insurance_fields_car_mileage_parking_1.length +
    2 +
    insurance_fields_car_ownership.length +
    1

let bareMinimumOverallPointsOfAnEverydayVehicle =
    fields_array_insurance_application_everyday_car_techinfo.length
insurance_fields_car_mileage_parking_1.length +
    2 +
    insurance_fields_everyday_car_ownership.length

export const calculateInsuranceStats = (
    reducerData: IInsuranceQuoteApplication,
    validationData: IInsuranceQuoteApplicationValidation
): IStatsResults => {
    let applicationBasicDetailsMaxPoints =
        fields_array_insurance_application_details.length

    let applicationBasicDetailsCompletionPoints =
        fields_array_insurance_application_details.length

    fields_array_insurance_application_details?.forEach((field) => {
        if (
            validationData?.insurance_details_errors &&
            validationData?.insurance_details_errors[field.id]
        ) {
            applicationBasicDetailsCompletionPoints =
                applicationBasicDetailsCompletionPoints - 1
        }
    })

    let applicationInsuredCarsPointsCompletionObj: {
        [key: string]: {
            tech_info_overall_max: number
            tech_info_overall_completion: number
            tech_info_overall_completion_percentage: number
            car_mileage_and_parking_overall_max: number
            car_mileage_and_parking_overall_completion: number
            car_mileage_and_parking_overall_completion_percentage: number
            ownership_overall_max: number
            ownership_overall_completion: number
            ownership_overall_completion_percentage: number
            overall_completion_percentage: number
            overall_max: number
            overall_completion: number
        }
    } = {}

    let applicationEverydayCarsPointsCompletionObj: {
        [key: string]: {
            tech_info_overall_max: number
            tech_info_overall_completion: number
            tech_info_overall_completion_percentage: number
            car_mileage_and_parking_overall_max: number
            car_mileage_and_parking_overall_completion: number
            car_mileage_and_parking_overall_completion_percentage: number
            ownership_overall_max: number
            ownership_overall_completion: number
            ownership_overall_completion_percentage: number
            overall_completion_percentage: number
            overall_max: number
            overall_completion: number
        }
    } = {}

    let applicationOtherDriverCompletionObj: {
        [key: string]: {
            basic_info_max: number
            basic_info_completion: number
            basic_info_completion_percentage: number
            license_max: number
            license_completion: number
            license_completion_percentage: number
            additional_details_max: number
            additional_details_completion: number
            additional_details_completion_percentage: number
            track_record_max: number
            track_record_completion: number
            track_record_completion_percentage: number
            drive_list_max: number
            drive_list_completion: number
            drive_list_completion_percentage: number

            overall_completion_percentage: number
            overall_max: number
            overall_completion: number
        }
    } = {}

    let application_custodian_cars_validation =
        validationData.application_custodian_cars

    let application_has_no_custodian_cars =
        application_custodian_cars_validation &&
        //@ts-ignore//
        application_custodian_cars_validation?.status === 'missing'
            ? true
            : false

    let applicationAllInsuredCarsMaxPoints =
        // accounting for no insured cars found in the application
        application_has_no_custodian_cars
            ? bareMinimumOverallPointsOfAnInsuredVehicles
            : // if insured cars are added to the application
            reducerData.vehicles_to_insure_ids_list &&
              reducerData.vehicles_to_insure_ids_list.length > 0
            ? reducerData.vehicles_to_insure_ids_list.reduce(
                  (accumulator, id) => {
                      let car = reducerData.vehicles[id]
                      let carErrors =
                          validationData?.vehicles &&
                          validationData?.vehicles[id]

                      let allCarStats = carInsuranceStats(car, carErrors)

                      let singleCarStatsPoints = allCarStats.overall_max

                      if (car && car.id) {
                          let singleCarStatsCompletionPoints = carErrors
                              ? allCarStats?.overall_completion
                              : allCarStats.overall_max

                          applicationInsuredCarsPointsCompletionObj = {
                              ...applicationInsuredCarsPointsCompletionObj,
                              [`${car.id}`]: {
                                  tech_info_overall_max:
                                      allCarStats.tech_info_max,
                                  tech_info_overall_completion:
                                      carErrors?.tech_info_errors
                                          ? allCarStats?.tech_info_completion
                                          : allCarStats.tech_info_max,
                                  tech_info_overall_completion_percentage:
                                      calculatePercentage(
                                          allCarStats.tech_info_completion,
                                          allCarStats.tech_info_max
                                      ),
                                  car_mileage_and_parking_overall_max:
                                      allCarStats.car_mileage_and_parking_max,
                                  car_mileage_and_parking_overall_completion:
                                      carErrors?.mileage_parking_errors
                                          ? allCarStats?.car_mileage_and_parking_completion
                                          : allCarStats.car_mileage_and_parking_max,
                                  car_mileage_and_parking_overall_completion_percentage:
                                      calculatePercentage(
                                          allCarStats.car_mileage_and_parking_completion,
                                          allCarStats.car_mileage_and_parking_max
                                      ),
                                  ownership_overall_max:
                                      allCarStats.ownership_max,
                                  ownership_overall_completion:
                                      carErrors?.ownership_errors
                                          ? allCarStats?.ownerhip_completion
                                          : allCarStats.ownership_max,
                                  ownership_overall_completion_percentage:
                                      calculatePercentage(
                                          allCarStats.ownerhip_completion,
                                          allCarStats.ownership_max
                                      ),
                                  overall_completion_percentage:
                                      calculatePercentage(
                                          singleCarStatsCompletionPoints,
                                          singleCarStatsPoints
                                      ),
                                  overall_completion:
                                      singleCarStatsCompletionPoints,
                                  overall_max: singleCarStatsPoints,
                              },
                          }
                      }

                      return accumulator + singleCarStatsPoints
                  },
                  0
              )
            : 0

    let applicationAllInsuredCarsCompletionPoints =
        // accounting for no insured cars added
        application_has_no_custodian_cars
            ? 0
            : // if insured cars are added
            reducerData.vehicles_to_insure_ids_list
            ? reducerData.vehicles_to_insure_ids_list.reduce(
                  (accumulator, id) => {
                      let carCompletion =
                          applicationInsuredCarsPointsCompletionObj[id]
                              ? applicationInsuredCarsPointsCompletionObj[id]
                                    .overall_completion
                              : 0
                      return accumulator + carCompletion
                  },
                  0
              )
            : 0

    let get_non_insured_everyday_cars_ids = (): string[] => {
        if (reducerData && reducerData.external_car_ids) {
            if (reducerData.everyday_car_ids) {
                let everyday_not_insured_garage_cars =
                    reducerData.everyday_car_ids.filter((id) => {
                        return !reducerData.vehicles_to_insure_ids_list.includes(
                            id
                        )
                    })
                return [
                    ...reducerData.external_car_ids,
                    ...everyday_not_insured_garage_cars,
                ]
            } else {
                return [...reducerData.external_car_ids]
            }
        } else {
            if (reducerData.everyday_car_ids) {
                let everyday_not_insured_garage_cars =
                    reducerData.everyday_car_ids.filter((id) => {
                        return !reducerData.vehicles_to_insure_ids_list.includes(
                            id
                        )
                    })
                return [...everyday_not_insured_garage_cars]
            } else return []
        }
    }

    // to remove duplicates => in case is external and everyday it gets added twice and to avoid other added conditions
    let non_insured_car_ids_initial = get_non_insured_everyday_cars_ids()
    let non_insured_car_ids = non_insured_car_ids_initial
        ? [...new Set(non_insured_car_ids_initial)]
        : []

    let application_everyday_vehicles_validation =
        validationData.application_everyday_vehicles

    let application_has_no_everyday_custodian_car =
        application_everyday_vehicles_validation &&
        //@ts-ignore//
        application_everyday_vehicles_validation?.status === 'missing'
            ? true
            : false

    let applicationAllEverydayCarsMaxPoints =
        application_has_no_everyday_custodian_car
            ? bareMinimumOverallPointsOfAnEverydayVehicle
            : non_insured_car_ids && non_insured_car_ids.length > 0
            ? non_insured_car_ids.reduce((accumulator, id) => {
                  let car = reducerData.vehicles && reducerData.vehicles[id]
                  let carErrors =
                      validationData.vehicles && validationData.vehicles[id]

                  let everydayCarAllStats = everydayCarInsuranceStatsPoints(
                      car,
                      carErrors
                  )

                  let singleCarStatsPoints = everydayCarAllStats.overall_max

                  let singleCarStatsCompletionPoints = carErrors
                      ? everydayCarAllStats?.overall_completion
                      : everydayCarAllStats.overall_max

                  if (car && car.id) {
                      applicationEverydayCarsPointsCompletionObj = {
                          ...applicationEverydayCarsPointsCompletionObj,
                          [`${car.id}`]: {
                              overall_max: singleCarStatsPoints,
                              overall_completion:
                                  singleCarStatsCompletionPoints,
                              overall_completion_percentage:
                                  calculatePercentage(
                                      singleCarStatsCompletionPoints,
                                      singleCarStatsPoints
                                  ),
                              tech_info_overall_max:
                                  everydayCarAllStats?.tech_info_max,
                              tech_info_overall_completion:
                                  carErrors?.tech_info_errors
                                      ? everydayCarAllStats?.tech_info_completion
                                      : everydayCarAllStats.tech_info_max,
                              tech_info_overall_completion_percentage:
                                  calculatePercentage(
                                      everydayCarAllStats.tech_info_completion,
                                      everydayCarAllStats.tech_info_max
                                  ),
                              car_mileage_and_parking_overall_max:
                                  everydayCarAllStats?.car_mileage_and_parking_max,
                              car_mileage_and_parking_overall_completion:
                                  carErrors?.mileage_parking_errors
                                      ? everydayCarAllStats?.car_mileage_and_parking_completion
                                      : everydayCarAllStats?.car_mileage_and_parking_max,
                              car_mileage_and_parking_overall_completion_percentage:
                                  calculatePercentage(
                                      everydayCarAllStats.car_mileage_and_parking_completion,
                                      everydayCarAllStats.car_mileage_and_parking_max
                                  ),
                              ownership_overall_max:
                                  everydayCarAllStats?.ownership_max,
                              ownership_overall_completion:
                                  carErrors?.ownership_errors
                                      ? everydayCarAllStats?.ownerhip_completion
                                      : everydayCarAllStats.ownership_max,
                              ownership_overall_completion_percentage:
                                  calculatePercentage(
                                      everydayCarAllStats.ownerhip_completion,
                                      everydayCarAllStats.ownership_max
                                  ),
                          },
                      }
                  }

                  return accumulator + singleCarStatsPoints
              }, 0)
            : 0
    // : bareMinimumOverallPointsOfAnEverydayVehicle

    let applicationAllEverydayCarsCompletionPoints =
        validationData.application_everyday_vehicles
            ? 0
            : non_insured_car_ids
            ? non_insured_car_ids.reduce((accumulator, id) => {
                  let carCompletion =
                      applicationEverydayCarsPointsCompletionObj[id]
                          ? applicationEverydayCarsPointsCompletionObj[id]
                                .overall_completion
                          : 0
                  return accumulator + carCompletion
              }, 0)
            : 0

    let mainPolicyHolderAllStats = mainPolicyHolderInsuranceStatsPoints(
        reducerData.main_driver,
        validationData.main_driver
    )

    let mainPolicyHolderStatMaxPoints = mainPolicyHolderAllStats.overall_max

    let mainPolicyHolderStatCompletionPoints = validationData.main_driver
        ? mainPolicyHolderAllStats.overall_completion
        : mainPolicyHolderAllStats.overall_max

    let applicationAllOtherDriversMaxPoints = reducerData.other_drivers
        ? Object.keys(reducerData.other_drivers).reduce((accumulator, key) => {
              let driver =
                  reducerData.other_drivers && reducerData.other_drivers[key]
                      ? reducerData.other_drivers[key]
                      : undefined
              if (driver?.id) {
                  let driver_errors =
                      validationData.other_drivers &&
                      validationData.other_drivers[key]
                          ? validationData.other_drivers[key]
                          : undefined

                  let driverAllStats = driverInsuranceStatsPoints(
                      driver,
                      driver_errors
                  )

                  if (driver) {
                      let singleDriverStatsPoints = driverAllStats.overall_max

                      let singleDriverStatsCompletionPoints = driver_errors
                          ? driverAllStats.overall_completion
                          : driverAllStats.overall_max

                      applicationOtherDriverCompletionObj = {
                          ...applicationOtherDriverCompletionObj,
                          [`${driver.id}`]: {
                              basic_info_max: driverAllStats.basic_info_max,
                              basic_info_completion: driver_errors
                                  ? driverAllStats.basic_info_completion
                                  : driverAllStats.basic_info_max,
                              basic_info_completion_percentage:
                                  calculatePercentage(
                                      driverAllStats.basic_info_completion,
                                      driverAllStats.basic_info_max
                                  ),
                              license_max:
                                  driverAllStats.license_and_addresses_max,
                              license_completion: driver_errors
                                  ? driverAllStats.license_and_addresses_completion
                                  : driverAllStats.license_and_addresses_max,
                              license_completion_percentage:
                                  calculatePercentage(
                                      driverAllStats.license_and_addresses_completion,
                                      driverAllStats.license_and_addresses_max
                                  ),
                              additional_details_max:
                                  driverAllStats.additional_details_max,
                              additional_details_completion: driver_errors
                                  ? driverAllStats.additional_details_completion
                                  : driverAllStats.additional_details_max,
                              additional_details_completion_percentage:
                                  calculatePercentage(
                                      driverAllStats.additional_details_completion,
                                      driverAllStats.additional_details_max
                                  ),
                              track_record_max: driverAllStats.track_record_max,
                              track_record_completion: driver_errors
                                  ? driverAllStats.track_record_completion
                                  : driverAllStats.track_record_max,
                              track_record_completion_percentage:
                                  calculatePercentage(
                                      driverAllStats.track_record_completion,
                                      driverAllStats.track_record_max
                                  ),
                              drive_list_max: driverAllStats.drive_list_max,
                              drive_list_completion:
                                  driverAllStats.drive_list_completion,
                              drive_list_completion_percentage:
                                  calculatePercentage(
                                      driverAllStats.drive_list_completion,
                                      driverAllStats.drive_list_max
                                  ),
                              overall_max: singleDriverStatsPoints,
                              overall_completion:
                                  singleDriverStatsCompletionPoints,
                              overall_completion_percentage:
                                  calculatePercentage(
                                      singleDriverStatsCompletionPoints,
                                      singleDriverStatsPoints
                                  ),
                          },
                      }
                      return accumulator + singleDriverStatsPoints
                  } else {
                      return 0
                  }
              } else {
                  return 0
              }
          }, 0)
        : 0

    let applicationAllOtherDriversCompletionPoints = reducerData.other_drivers
        ? Object.keys(reducerData.other_drivers).reduce((accumulator, key) => {
              if (
                  applicationOtherDriverCompletionObj[key] &&
                  applicationOtherDriverCompletionObj[key].overall_completion
              ) {
                  return (
                      accumulator +
                      applicationOtherDriverCompletionObj[key]
                          ?.overall_completion
                  )
              } else return 0
          }, 0)
        : 0

    let calcApplicationEverydayCarsMaxPoints = () => {
        let result = 0
        if (validationData.application_everyday_vehicles) {
            return 0
        } else if (
            reducerData.everyday_car_ids &&
            reducerData.everyday_car_ids?.length > 0
        ) {
            reducerData.everyday_car_ids.forEach((id, i) => {
                {
                    if (applicationInsuredCarsPointsCompletionObj[id]) {
                        result =
                            result +
                            applicationInsuredCarsPointsCompletionObj[id]
                                .overall_max
                    }
                }
                {
                    if (applicationEverydayCarsPointsCompletionObj[id]) {
                        result =
                            result +
                            applicationEverydayCarsPointsCompletionObj[id]
                                .overall_max
                    }
                }
            })
        } else {
            result = bareMinimumOverallPointsOfAnEverydayVehicle
        }
        return result
    }

    let applicationEverydayCarsMaxPoints =
        calcApplicationEverydayCarsMaxPoints()

    let calcApplicationEverydayCarsCompletionPoints = () => {
        let result = 0
        if (validationData.application_everyday_vehicles) {
            return 0
        } else if (
            reducerData.everyday_car_ids &&
            reducerData.everyday_car_ids?.length > 0
        ) {
            reducerData.everyday_car_ids.forEach((id, i) => {
                {
                    if (applicationInsuredCarsPointsCompletionObj[id]) {
                        result =
                            result +
                            applicationInsuredCarsPointsCompletionObj[id]
                                .overall_completion
                    }
                }
                {
                    if (applicationEverydayCarsPointsCompletionObj[id]) {
                        result =
                            result +
                            applicationEverydayCarsPointsCompletionObj[id]
                                .overall_completion
                    }
                }
            })
        }
        return result
    }

    let applicationEverydayCarsCompletionPoints =
        calcApplicationEverydayCarsCompletionPoints()

    let insuranceApplicationMaxNbOfPoints =
        applicationBasicDetailsMaxPoints +
        applicationAllInsuredCarsMaxPoints +
        applicationEverydayCarsMaxPoints +
        mainPolicyHolderStatMaxPoints +
        applicationAllOtherDriversMaxPoints

    let insuranceApplicationCompletionPoints =
        applicationBasicDetailsCompletionPoints +
        applicationAllInsuredCarsCompletionPoints +
        applicationEverydayCarsCompletionPoints +
        mainPolicyHolderStatCompletionPoints +
        applicationAllOtherDriversCompletionPoints

    let cardsObjStats = {
        basicDetails: {
            max: applicationBasicDetailsMaxPoints,
            completion: applicationBasicDetailsCompletionPoints,
            percentage: calculatePercentage(
                applicationBasicDetailsCompletionPoints,
                applicationBasicDetailsMaxPoints
            ),
        },
        vehiclesToInsure: {
            max: applicationAllInsuredCarsMaxPoints,
            completion: applicationAllInsuredCarsCompletionPoints,
            percentage: calculatePercentage(
                applicationAllInsuredCarsCompletionPoints,
                applicationAllInsuredCarsMaxPoints
            ),
        },

        non_insured_everyday_vehicles: {
            max: applicationAllEverydayCarsMaxPoints,
            completion: applicationAllEverydayCarsCompletionPoints,
            percentage: calculatePercentage(
                applicationAllEverydayCarsCompletionPoints,
                applicationAllEverydayCarsMaxPoints
            ),
        },
        mainPolicyHolder: {
            max: mainPolicyHolderStatMaxPoints,
            completion: mainPolicyHolderStatCompletionPoints,
            percentage:
                mainPolicyHolderStatMaxPoints &&
                mainPolicyHolderStatCompletionPoints
                    ? calculatePercentage(
                          mainPolicyHolderStatCompletionPoints,
                          mainPolicyHolderStatMaxPoints
                      )
                    : 0,
        },
        otherDrivers: {
            max: applicationAllOtherDriversMaxPoints,
            completion: applicationAllOtherDriversCompletionPoints,
            percentage:
                applicationAllOtherDriversMaxPoints &&
                applicationAllOtherDriversCompletionPoints
                    ? calculatePercentage(
                          applicationAllOtherDriversCompletionPoints,
                          applicationAllOtherDriversMaxPoints
                      )
                    : 0,
        },
        everyday_vehicle: {
            max: applicationEverydayCarsMaxPoints,
            completion: applicationEverydayCarsCompletionPoints,
            percentage: calculatePercentage(
                applicationEverydayCarsCompletionPoints,
                applicationEverydayCarsMaxPoints
            ),
        },
        everydayNonInsuredCarVehicle: {
            max: applicationAllEverydayCarsMaxPoints,
            completion: applicationAllEverydayCarsCompletionPoints,
            percentage: calculatePercentage(
                applicationAllEverydayCarsCompletionPoints,
                applicationAllEverydayCarsMaxPoints
            ),
        },
    }

    let mainPolicyHolderStats = {
        basic_details_max: mainPolicyHolderAllStats?.basic_info_max,
        basic_details_completion: validationData.main_driver
            ? mainPolicyHolderAllStats?.basic_info_completion
            : mainPolicyHolderAllStats.basic_info_max,
        basic_details_completion_percentage: calculatePercentage(
            mainPolicyHolderAllStats.basic_info_completion,
            mainPolicyHolderAllStats.basic_info_max
        ),
        license_and_addresses_max:
            mainPolicyHolderAllStats?.license_and_addresses_max,
        license_and_addresses_completion: validationData.main_driver
            ? mainPolicyHolderAllStats?.license_and_addresses_completion
            : mainPolicyHolderAllStats.license_and_addresses_max,
        license_and_addresses_completion_percentage: calculatePercentage(
            mainPolicyHolderAllStats.license_and_addresses_completion,
            mainPolicyHolderAllStats.license_and_addresses_max
        ),
        additional_details_max:
            mainPolicyHolderAllStats?.additional_details_max,
        additional_details_completion: validationData.main_driver
            ? mainPolicyHolderAllStats?.additional_details_completion
            : mainPolicyHolderAllStats.additional_details_max,
        additional_details_completion_percentage: calculatePercentage(
            mainPolicyHolderAllStats.additional_details_completion,
            mainPolicyHolderAllStats.additional_details_max
        ),
        track_record_max: mainPolicyHolderAllStats?.track_record_max,
        track_record_completion: validationData.main_driver
            ? mainPolicyHolderAllStats?.track_record_completion
            : mainPolicyHolderAllStats.track_record_max,
        track_record_completion_percentage: calculatePercentage(
            mainPolicyHolderAllStats.track_record_completion,
            mainPolicyHolderAllStats.track_record_max
        ),
    }

    return {
        overallApplicationStats: {
            max: insuranceApplicationMaxNbOfPoints,
            completion: insuranceApplicationCompletionPoints,
            percentage: calculatePercentage(
                insuranceApplicationCompletionPoints,
                insuranceApplicationMaxNbOfPoints
            ),
        },
        cardsObjStats: cardsObjStats,
        mainPolicyHolder: mainPolicyHolderStats,
        insuredVehiclesObj: applicationInsuredCarsPointsCompletionObj,
        everydayVehiclesObj: applicationEverydayCarsPointsCompletionObj,
        driversCompletionObj: applicationOtherDriverCompletionObj,
    }
}

export const carInsuranceStats = (
    car: IInsuranceQuoteApplication_Car,
    carErrors?: IInsuranceQuoteApplication_Car
): {
    overall_max: number
    overall_completion: number
    tech_info_max: number
    tech_info_completion: number
    car_mileage_and_parking_max: number
    car_mileage_and_parking_completion: number
    ownership_max: number
    ownerhip_completion: number
} => {
    if (
        car.tech_info &&
        (car.tech_info['has_vehicle_modifications'] === false ||
            car.tech_info['has_vehicle_modifications']?.name === 'No')
    ) {
        fields_array_insurance_application_car_techinfo_enriched = [
            ...fields_array_insurance_application_car_techinfo_enriched,
            ...has_vehicle_modifications_negative_answer_subform,
        ]
    }
    if (
        car.tech_info &&
        (car.tech_info['has_vehicle_modifications'] === true ||
            car.tech_info['has_vehicle_modifications']?.name === 'Yes')
    ) {
        fields_array_insurance_application_car_techinfo_enriched = [
            ...fields_array_insurance_application_car_techinfo_enriched,
            ...has_vehicle_modifications_positive_answer_subform,
        ]

        if (
            car.tech_info &&
            (car.tech_info['has_modification_adjusted_bhp'] === true ||
                car.tech_info['has_modification_adjusted_bhp']?.name === 'Yes')
        ) {
            fields_array_insurance_application_car_techinfo_enriched = [
                ...fields_array_insurance_application_car_techinfo_enriched,
                ...has_modification_adjusted_bhp_positive_answer_subform,
            ]
        }
    }
    //
    // 100% equivalent number of points
    let applicationCarTechInfoMaxPoints =
        fields_array_insurance_application_car_techinfo_enriched.length - 1
    // -1 stands for model_variant which is not mandatory

    // actual completion number points - we start assuming 100% equivalent and
    // if there's an error on a field, we substract 1
    let applicationCarTechInfoCompletionPoints =
        fields_array_insurance_application_car_techinfo_enriched.length - 1

    //-1 stands for model_variant which is not mandatory

    fields_array_insurance_application_car_techinfo_enriched.forEach(
        (field) => {
            if (
                carErrors?.tech_info_errors &&
                carErrors?.tech_info_errors[field.id]
            ) {
                applicationCarTechInfoCompletionPoints =
                    applicationCarTechInfoCompletionPoints - 1
            }
        }
    )

    if (car?.entities.modifications && car.entities.modifications.length > 0) {
        let numberOfModifs = car?.entities.modifications.length

        applicationCarTechInfoMaxPoints =
            applicationCarTechInfoMaxPoints +
            numberOfModifs * fields_array_vehicle_modif.length

        applicationCarTechInfoCompletionPoints =
            applicationCarTechInfoCompletionPoints +
            numberOfModifs * fields_array_vehicle_modif.length

        if (
            car?.entities_error &&
            car.entities_error.modifications &&
            car.entities_error.modifications.length > 0
        ) {
            for (
                let i = 0;
                i < car?.entities_error?.modifications.length;
                i++
            ) {
                let modifObj = car?.entities_error?.modifications[i]

                let objKeys = Object.keys(fields_array_vehicle_modif)
                for (let a = 0; a < objKeys.length; a++) {
                    if (modifObj[objKeys[a]]) {
                        applicationCarTechInfoCompletionPoints =
                            applicationCarTechInfoCompletionPoints - 1
                    }
                }
            }
        }
    }

    let applicationCarMileageMaxPoints = [
        ...insurance_fields_car_mileage_parking_1,
        ...insurance_fields_car_mileage_parking_added_address_same_as_residential,
        ...insurance_fields_car_mileage_parking_security,
    ].length

    let applicationCarMileageCompletionPoints = [
        ...insurance_fields_car_mileage_parking_1,
        ...insurance_fields_car_mileage_parking_added_address_same_as_residential,
        ...insurance_fields_car_mileage_parking_security,
    ].length

    insurance_fields_car_mileage_parking_1.forEach((field) => {
        if (
            carErrors?.mileage_parking_errors &&
            carErrors?.mileage_parking_errors[field.id]
        ) {
            applicationCarMileageCompletionPoints =
                applicationCarMileageCompletionPoints - 1
        }
    })

    if (carErrors) {
        if (
            car?.mileage_parking &&
            car.mileage_parking[`is_kept_at_customer_residential_address`] !==
                undefined &&
            car.mileage_parking[`is_kept_at_customer_residential_address`] !==
                null
        ) {
            if (
                car.mileage_parking[
                    `is_kept_at_customer_residential_address`
                ] === false
            ) {
                applicationCarMileageMaxPoints =
                    applicationCarMileageMaxPoints +
                    insurance_fields_car_mileage_parking_added_address_different_than_residential.length
                applicationCarMileageCompletionPoints =
                    applicationCarMileageCompletionPoints +
                    insurance_fields_car_mileage_parking_added_address_different_than_residential.length

                if (
                    car.mileage_parking['travels_to_home_address'] !== null &&
                    car.mileage_parking['travels_to_home_address'] !== undefined
                ) {
                    if (
                        car.mileage_parking['travels_to_home_address'] == true
                    ) {
                        applicationCarMileageMaxPoints =
                            applicationCarMileageMaxPoints +
                            travel_to_residential_address_yes_subfields.length
                        applicationCarMileageCompletionPoints =
                            applicationCarMileageCompletionPoints +
                            travel_to_residential_address_yes_subfields.length

                        if (
                            car.mileage_parking[
                                'residential_visit_overnight'
                            ] !== null &&
                            car.mileage_parking[
                                'residential_visit_overnight'
                            ] !== undefined
                        ) {
                            if (
                                car.mileage_parking[
                                    'residential_visit_overnight'
                                ] === true
                            ) {
                                applicationCarMileageMaxPoints =
                                    applicationCarMileageMaxPoints +
                                    residential_visit_overnight_yes_subfields.length
                                applicationCarMileageCompletionPoints =
                                    applicationCarMileageCompletionPoints +
                                    residential_visit_overnight_yes_subfields.length

                                if (
                                    !car.mileage_parking[
                                        'residential_visit_location'
                                    ]
                                ) {
                                    // console.log('substracts at L941')
                                    applicationCarMileageCompletionPoints =
                                        applicationCarMileageCompletionPoints -
                                        1
                                }
                            }
                        } else {
                            // console.log('substracts at L948')
                            applicationCarMileageCompletionPoints =
                                applicationCarMileageCompletionPoints - 1
                        }
                    }
                } else if (
                    carErrors?.mileage_parking_errors &&
                    carErrors?.mileage_parking_errors['travels_to_home_address']
                ) {
                    // console.log('substracts at L954')
                    // travel_to_residential_address is incomplete
                    applicationCarMileageCompletionPoints =
                        applicationCarMileageCompletionPoints - 1
                }

                if (
                    car?.mileage_parking &&
                    car.mileage_parking['parking_place_same_day_night'] !==
                        null &&
                    car.mileage_parking['parking_place_same_day_night'] !==
                        undefined
                ) {
                    {
                        applicationCarMileageMaxPoints =
                            applicationCarMileageMaxPoints +
                            insurance_fields_parking_info.length * 2 +
                            2
                        applicationCarMileageCompletionPoints =
                            applicationCarMileageCompletionPoints +
                            insurance_fields_parking_info.length * 2 +
                            2

                        // +2 account dor day and night park address ids

                        if (
                            carErrors?.mileage_parking_errors &&
                            carErrors?.mileage_parking_errors[
                                `day_park_address_id`
                            ]
                        ) {
                            // console.log('substracts at L985')
                            applicationCarMileageCompletionPoints =
                                applicationCarMileageCompletionPoints - 1
                        }

                        if (
                            carErrors?.mileage_parking_errors &&
                            carErrors?.mileage_parking_errors[
                                `night_park_address_id`
                            ]
                        ) {
                            // console.log('substracts at L996')
                            applicationCarMileageCompletionPoints =
                                applicationCarMileageCompletionPoints - 1
                        }

                        insurance_fields_parking_info.forEach((field) => {
                            if (
                                carErrors?.daytime_parking_info_errors &&
                                carErrors.daytime_parking_info_errors[field.id]
                            ) {
                                // console.log('substracts at L1006')
                                applicationCarMileageCompletionPoints =
                                    applicationCarMileageCompletionPoints - 1
                            }
                        })

                        insurance_fields_parking_info.forEach((field) => {
                            if (
                                carErrors?.eveningtime_parking_info_errors &&
                                carErrors.eveningtime_parking_info_errors[
                                    field.id
                                ]
                            ) {
                                // console.log('substracts at L1019')
                                applicationCarMileageCompletionPoints =
                                    applicationCarMileageCompletionPoints - 1
                            }
                        })
                    }
                } else {
                    // console.log('substracts at L1026')
                    // parking_place_same_day_night is incomplete
                    applicationCarMileageCompletionPoints =
                        applicationCarMileageCompletionPoints - 1
                }
            }
        } else {
            // console.log('substracts at L1032')
            // is_kept_at_customer_residential_address is incomplete
            applicationCarMileageCompletionPoints =
                applicationCarMileageCompletionPoints - 1
        }

        if (
            car?.mileage_parking &&
            car.mileage_parking[`has_security_device_fitted`] !== null &&
            car.mileage_parking[`has_security_device_fitted`] !== undefined
        ) {
            if (car?.mileage_parking[`has_security_device_fitted`] === true) {
                applicationCarMileageMaxPoints =
                    applicationCarMileageMaxPoints +
                    insurance_fields_car_mileage_parking_security_subform.length

                applicationCarMileageCompletionPoints =
                    applicationCarMileageCompletionPoints +
                    insurance_fields_car_mileage_parking_security_subform.length

                insurance_fields_car_mileage_parking_security_subform.forEach(
                    (field) => {
                        if (
                            carErrors?.mileage_parking_errors &&
                            carErrors?.mileage_parking_errors[field.id]
                        ) {
                            // console.log('substracts at L1057')
                            applicationCarMileageCompletionPoints =
                                applicationCarMileageCompletionPoints - 1
                        }
                    }
                )
            }
        } else if (
            carErrors?.mileage_parking_errors &&
            carErrors.mileage_parking_errors[`has_security_device_fitted`]
        ) {
            // console.log('substracts at L1065')
            // has_security_device_fitted is incomplete
            applicationCarMileageCompletionPoints =
                applicationCarMileageCompletionPoints - 1
        }
    }

    // calculate car ownership completion points

    let insurance_fields_car_ownership_enriched = [
        ...insurance_fields_car_ownership,
        ...frequency_insurance_other_driver_ownership,
    ]

    if (
        car.ownership &&
        (car.ownership[`is_written_off`] === true ||
            car.ownership[`is_written_off`]?.name === 'Yes')
    ) {
        insurance_fields_car_ownership_enriched = [
            ...insurance_fields_car_ownership_enriched,
            ...written_off_subfields,
        ]
    }

    if (
        car.ownership &&
        (car.ownership[`is_imported`] === true ||
            car.ownership[`is_imported`]?.name === 'Yes')
    ) {
        insurance_fields_car_ownership_enriched = [
            ...insurance_fields_car_ownership_enriched,
            ...is_imported_positive_answer_subfields,
        ]
    }
    let applicationCarOwnershipMaxPoints =
        insurance_fields_car_ownership_enriched.length

    let applicationCarOwnershipCompletionPoints =
        insurance_fields_car_ownership_enriched.length

    insurance_fields_car_ownership_enriched.forEach((field) => {
        if (
            carErrors?.ownership_errors &&
            carErrors.ownership_errors[field.id]
        ) {
            applicationCarOwnershipCompletionPoints =
                applicationCarOwnershipCompletionPoints - 1
        }
    })

    if (car?.ownership && car.ownership[`has_voluntary_excess`] === true) {
        applicationCarOwnershipMaxPoints =
            applicationCarOwnershipMaxPoints + voluntary_excess_subfields.length
        applicationCarOwnershipCompletionPoints =
            applicationCarOwnershipCompletionPoints +
            voluntary_excess_subfields.length

        voluntary_excess_subfields.forEach((field) => {
            if (
                carErrors?.ownership_errors &&
                carErrors?.ownership_errors[field.id]
            ) {
                applicationCarOwnershipCompletionPoints =
                    applicationCarOwnershipCompletionPoints - 1
            }
        })
    }

    if (car?.ownership && car.ownership[`ncd_years`] > 0) {
        applicationCarOwnershipMaxPoints =
            applicationCarOwnershipMaxPoints + ncd_additional_fields.length
        applicationCarOwnershipCompletionPoints =
            applicationCarOwnershipCompletionPoints +
            ncd_additional_fields.length

        ncd_additional_fields.forEach((field) => {
            if (
                carErrors?.ownership_errors &&
                carErrors?.ownership_errors[field.id]
            ) {
                applicationCarOwnershipCompletionPoints =
                    applicationCarOwnershipCompletionPoints - 1
            }
        })
    }

    return {
        tech_info_max: applicationCarTechInfoMaxPoints,
        tech_info_completion: applicationCarTechInfoCompletionPoints,
        car_mileage_and_parking_max: applicationCarMileageMaxPoints,
        car_mileage_and_parking_completion:
            applicationCarMileageCompletionPoints,
        ownership_max: applicationCarOwnershipMaxPoints,
        ownerhip_completion: applicationCarOwnershipCompletionPoints,
        overall_max:
            applicationCarTechInfoMaxPoints +
            applicationCarMileageMaxPoints +
            applicationCarOwnershipMaxPoints,
        overall_completion:
            applicationCarTechInfoCompletionPoints +
            applicationCarMileageCompletionPoints +
            applicationCarOwnershipCompletionPoints,
    }
}

export const everydayCarInsuranceStatsPoints = (
    car: IInsuranceQuoteApplication_Car,
    carErrors?: IInsuranceQuoteApplication_Car
): {
    overall_max: number
    overall_completion: number
    tech_info_max: number
    tech_info_completion: number
    car_mileage_and_parking_max: number
    car_mileage_and_parking_completion: number
    ownership_max: number
    ownerhip_completion: number
} => {
    let applicationEverydayCarTechInfoMaxPoints =
        fields_array_insurance_application_everyday_car_techinfo.length - 1
    //-1 stands for model_variant which is not mandatory

    let applicationEverydayCarTechInfoCompletionPoints =
        fields_array_insurance_application_everyday_car_techinfo.length - 1
    //-1 stands for model_variant which is not mandatory

    fields_array_insurance_application_everyday_car_techinfo.forEach(
        (field) => {
            if (
                carErrors?.tech_info_errors &&
                carErrors?.tech_info_errors[field.id]
            ) {
                applicationEverydayCarTechInfoCompletionPoints =
                    applicationEverydayCarTechInfoCompletionPoints - 1
            }
        }
    )

    let applicationEverydayCarMileageMaxPoints = 1
    // 1 accounts for
    // insurance_fields_car_mileage_parking_added_address_same_as_residential which is not an array of fields but a single one

    let applicationEverydayCarMileageCompletionPoints = 1

    // insurance_fields_car_mileage_parking_1.forEach((field) => {
    //     if (
    //         carErrors?.mileage_parking_errors &&
    //         carErrors?.mileage_parking_errors[field.id]
    //     ) {
    //         applicationEverydayCarMileageCompletionPoints =
    //             applicationEverydayCarMileageCompletionPoints - 1
    //     }
    // })

    if (carErrors) {
        if (
            car?.mileage_parking &&
            car.mileage_parking[`is_kept_at_customer_residential_address`] !==
                undefined &&
            car.mileage_parking[`is_kept_at_customer_residential_address`] !==
                null
        ) {
            if (
                car.mileage_parking[
                    `is_kept_at_customer_residential_address`
                ] === false
            ) {
                applicationEverydayCarMileageMaxPoints =
                    applicationEverydayCarMileageMaxPoints +
                    insurance_fields_everydaycar_mileage_parking_same_place_day_and_night.length
                applicationEverydayCarMileageCompletionPoints =
                    applicationEverydayCarMileageCompletionPoints +
                    insurance_fields_everydaycar_mileage_parking_same_place_day_and_night.length

                if (
                    car?.mileage_parking &&
                    car.mileage_parking['parking_place_same_day_night'] !==
                        null &&
                    car.mileage_parking['parking_place_same_day_night'] !==
                        undefined
                ) {
                    {
                        applicationEverydayCarMileageMaxPoints =
                            applicationEverydayCarMileageMaxPoints +
                            insurance_fields_parking_info.length * 2 +
                            2
                        applicationEverydayCarMileageCompletionPoints =
                            applicationEverydayCarMileageCompletionPoints +
                            insurance_fields_parking_info.length * 2 +
                            2

                        // +2 account dor day and night park address ids

                        if (
                            carErrors?.mileage_parking_errors &&
                            carErrors?.mileage_parking_errors[
                                `day_park_address_id`
                            ]
                        ) {
                            applicationEverydayCarMileageCompletionPoints =
                                applicationEverydayCarMileageCompletionPoints -
                                1
                        }

                        if (
                            carErrors?.mileage_parking_errors &&
                            carErrors?.mileage_parking_errors[
                                `night_park_address_id`
                            ]
                        ) {
                            applicationEverydayCarMileageCompletionPoints =
                                applicationEverydayCarMileageCompletionPoints -
                                1
                        }

                        insurance_fields_parking_info.forEach((field) => {
                            if (
                                carErrors?.daytime_parking_info_errors &&
                                carErrors.daytime_parking_info_errors[field.id]
                            ) {
                                applicationEverydayCarMileageCompletionPoints =
                                    applicationEverydayCarMileageCompletionPoints -
                                    1
                            }
                        })

                        insurance_fields_parking_info.forEach((field) => {
                            if (
                                carErrors?.eveningtime_parking_info_errors &&
                                carErrors.eveningtime_parking_info_errors[
                                    field.id
                                ]
                            ) {
                                applicationEverydayCarMileageCompletionPoints =
                                    applicationEverydayCarMileageCompletionPoints -
                                    1
                            }
                        })
                    }
                } else {
                    applicationEverydayCarMileageCompletionPoints =
                        applicationEverydayCarMileageCompletionPoints - 1
                }
            }
        } else {
            applicationEverydayCarMileageCompletionPoints =
                applicationEverydayCarMileageCompletionPoints - 1
        }
    }

    // calculate car ownership completion points

    let applicationEverydayCarOwnershipMaxPoints =
        insurance_fields_everyday_car_ownership.length

    let applicationEverydayCarOwnershipCompletionPoints =
        insurance_fields_everyday_car_ownership.length

    insurance_fields_everyday_car_ownership.forEach((field) => {
        if (
            carErrors?.ownership_errors &&
            carErrors?.ownership_errors[field.id]
        ) {
            applicationEverydayCarOwnershipCompletionPoints =
                applicationEverydayCarOwnershipCompletionPoints - 1
        }
    })

    // removed for everyday car not insured
    // if (car?.ownership && car.ownership[`ncd_years`] > 0) {
    //     applicationEverydayCarOwnershipMaxPoints =
    //         applicationEverydayCarOwnershipMaxPoints +
    //         ncd_additional_fields.length
    //     applicationEverydayCarOwnershipCompletionPoints =
    //         applicationEverydayCarOwnershipCompletionPoints +
    //         ncd_additional_fields.length

    //     ncd_additional_fields.forEach((field) => {
    //         if (
    //             carErrors?.ownership_errors &&
    //             carErrors?.ownership_errors[field.id]
    //         ) {
    //             applicationEverydayCarOwnershipCompletionPoints =
    //                 applicationEverydayCarOwnershipCompletionPoints - 1
    //         }
    //     })
    // }

    // if (car?.ownership && car?.ownership[`has_voluntary_excess`] === true) {
    //     applicationEverydayCarOwnershipMaxPoints =
    //         applicationEverydayCarOwnershipMaxPoints +
    //         voluntary_excess_subfields.length
    //     applicationEverydayCarOwnershipCompletionPoints =
    //         applicationEverydayCarOwnershipCompletionPoints +
    //         voluntary_excess_subfields.length

    //     voluntary_excess_subfields.forEach((field) => {
    //         if (
    //             carErrors?.ownership_errors &&
    //             carErrors?.ownership_errors[field.id]
    //         ) {
    //             applicationEverydayCarOwnershipCompletionPoints =
    //                 applicationEverydayCarOwnershipCompletionPoints - 1
    //         }
    //     })
    // }

    // if (car?.ownership && !car?.ownership.frequency) {
    //     applicationEverydayCarOwnershipCompletionPoints =
    //         applicationEverydayCarOwnershipCompletionPoints - 1
    // }

    return {
        tech_info_max: applicationEverydayCarTechInfoMaxPoints,
        tech_info_completion: applicationEverydayCarTechInfoCompletionPoints,
        car_mileage_and_parking_max: applicationEverydayCarMileageMaxPoints,
        car_mileage_and_parking_completion:
            applicationEverydayCarMileageCompletionPoints,
        ownership_max: applicationEverydayCarOwnershipMaxPoints,
        ownerhip_completion: applicationEverydayCarOwnershipCompletionPoints,
        overall_max:
            applicationEverydayCarTechInfoMaxPoints +
            applicationEverydayCarMileageMaxPoints +
            applicationEverydayCarOwnershipMaxPoints,
        overall_completion:
            applicationEverydayCarTechInfoCompletionPoints +
            applicationEverydayCarMileageCompletionPoints +
            applicationEverydayCarOwnershipCompletionPoints,
    }
}

export const mainPolicyHolderInsuranceStatsPoints = (
    customer: IInsuranceQuoteApplication_MainDriver,
    customer_errors: IInsuranceQuoteApplication_MainDriver | undefined
): {
    basic_info_max: number
    basic_info_completion: number
    license_and_addresses_max: number
    license_and_addresses_completion: number
    additional_details_max: number
    additional_details_completion: number
    track_record_max: number
    track_record_completion: number
    overall_max: number
    overall_completion: number
} => {
    let applicationMainDriverBasicDetailsMaxPoints =
        insurance_fields_main_driver_basic_details.length - 1

    let applicationMainDriverBasicDetailsCompletionPoints =
        insurance_fields_main_driver_basic_details.length - 1

    // -1 stands for middle name which is not mandatory

    insurance_fields_main_driver_basic_details.forEach((field) => {
        if (
            customer_errors?.basic_details_errors &&
            customer_errors?.basic_details_errors[field.id]
        ) {
            applicationMainDriverBasicDetailsCompletionPoints =
                applicationMainDriverBasicDetailsCompletionPoints - 1
        }
    })

    let insurance_fields_main_driver_licence_address_enriched = [
        ...insurance_fields_main_driver_licence_address,
    ]

    if (
        customer?.licence_address &&
        customer.licence_address['is_uk_resident'] === true
    ) {
        insurance_fields_main_driver_licence_address_enriched = [
            ...insurance_fields_main_driver_licence_address_enriched,
            ...is_uk_resident_positive_answer_subform,
        ]
    }

    if (
        customer?.licence_address &&
        customer.licence_address['is_uk_resident_since_birth'] === false
    ) {
        insurance_fields_main_driver_licence_address_enriched = [
            ...insurance_fields_main_driver_licence_address_enriched,
            ...is_uk_resident_since_birth_negative_answer_subform,
        ]
    }

    if (
        customer?.licence_address &&
        customer.licence_address['resides_in_the_uk'] === false
    ) {
        insurance_fields_main_driver_licence_address_enriched = [
            ...insurance_fields_main_driver_licence_address_enriched,
            ...resides_in_the_uk_negative_answer_subform,
        ]
    }

    let applicationMainDriverLicenceAndAddressMaxPoints =
        insurance_fields_main_driver_licence_address_enriched.length + 2
    // the +2 counts for residential_address and correspondence_addrese

    let applicationMainDriverLicenceAndAddressCompletionPoints =
        insurance_fields_main_driver_licence_address_enriched.length + 2

    insurance_fields_main_driver_licence_address.forEach((field) => {
        if (
            customer_errors?.licence_address_errors &&
            customer_errors?.licence_address_errors[field.id]
        ) {
            applicationMainDriverLicenceAndAddressCompletionPoints =
                applicationMainDriverLicenceAndAddressCompletionPoints - 1
        }
    })

    if (
        customer?.licence_address &&
        customer.licence_address['license_type'] &&
        customer.licence_address['license_type'].uid &&
        customer.licence_address['license_type'].uid !== 'NON_UK'
    ) {
        applicationMainDriverLicenceAndAddressMaxPoints =
            applicationMainDriverLicenceAndAddressMaxPoints + 1

        applicationMainDriverLicenceAndAddressCompletionPoints =
            applicationMainDriverLicenceAndAddressCompletionPoints + 1

        if (
            customer_errors?.licence_address_errors &&
            customer_errors?.licence_address_errors[`license_issue_location`]
        ) {
            applicationMainDriverLicenceAndAddressCompletionPoints =
                applicationMainDriverLicenceAndAddressCompletionPoints - 1
        }
    }

    if (customer_errors?.licence_address_errors) {
        if (customer_errors?.licence_address_errors[`residential_address`]) {
            applicationMainDriverLicenceAndAddressCompletionPoints =
                applicationMainDriverLicenceAndAddressCompletionPoints - 1
        }
        if (customer_errors?.licence_address_errors[`correspondence_address`]) {
            applicationMainDriverLicenceAndAddressCompletionPoints =
                applicationMainDriverLicenceAndAddressCompletionPoints - 1
        }
    }

    let applicationMainDriverTrackRecordMaxPoints =
        insurance_fields_main_driver_track_record.length

    let applicationMainDriverTrackRecordCompletionPoints =
        insurance_fields_main_driver_track_record.length

    insurance_fields_main_driver_track_record.forEach((field) => {
        if (
            customer_errors?.track_record_errors &&
            customer_errors?.track_record_errors[field.id]
        ) {
            applicationMainDriverTrackRecordCompletionPoints =
                applicationMainDriverTrackRecordCompletionPoints - 1
        }
    })

    if (
        customer?.entities &&
        customer?.entities.claims &&
        customer?.entities.claims.length > 0
    ) {
        let claim_array_of_fields_to_check = [
            ...self_replicating_claim,
            ...additionalSubFieldsClaims,
        ]
        applicationMainDriverTrackRecordMaxPoints =
            applicationMainDriverTrackRecordMaxPoints +
            customer?.entities.claims.length *
                claim_array_of_fields_to_check.length

        applicationMainDriverTrackRecordCompletionPoints =
            applicationMainDriverTrackRecordCompletionPoints +
            customer?.entities.claims.length *
                claim_array_of_fields_to_check.length

        if (customer_errors?.entities_error?.claims) {
            let claims_errors_obj = customer_errors?.entities_error?.claims

            for (let i = 0; i < customer.entities.claims.length; i++) {
                let claimObjId = customer.entities.claims[i]?.uid
                let obj_to_check = claims_errors_obj[`"${claimObjId}"`]

                let objKeys = Object.values(claim_array_of_fields_to_check).map(
                    (field) => field.id
                )

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationMainDriverTrackRecordCompletionPoints =
                            applicationMainDriverTrackRecordCompletionPoints - 1
                    }
                }
            }
        }
    }
    if (
        customer?.entities &&
        customer.entities.convictions &&
        customer.entities.convictions.length > 0
    ) {
        let convictions_array_of_fields_to_check = [
            ...insurance_sub_fields_other_driver_track_record_conviction_replicating,
            ...additionalSubFieldsConvictions.map((item) => {
                return { id: item }
            }),
        ]
        applicationMainDriverTrackRecordMaxPoints =
            applicationMainDriverTrackRecordMaxPoints +
            customer?.entities.convictions.length *
                convictions_array_of_fields_to_check.length

        applicationMainDriverTrackRecordCompletionPoints =
            applicationMainDriverTrackRecordCompletionPoints +
            customer?.entities.convictions.length *
                convictions_array_of_fields_to_check.length

        if (customer_errors?.entities_error?.convictions) {
            let conviction_errors_obj =
                customer_errors?.entities_error?.convictions

            for (let i = 0; i < customer.entities.convictions.length; i++) {
                let convictionObjId = customer.entities.convictions[i]?.uid
                let obj_to_check = conviction_errors_obj[`"${convictionObjId}"`]

                let objKeys = Object.values(
                    convictions_array_of_fields_to_check
                ).map((field) => field.id)

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationMainDriverTrackRecordCompletionPoints =
                            applicationMainDriverTrackRecordCompletionPoints - 1
                    }
                }
            }
        }
    }

    if (
        customer?.entities &&
        customer.entities.motoring_convictions &&
        customer.entities.motoring_convictions.length > 0
    ) {
        const motoring_convictions_array_to_check = [
            ...insurance_sub_fields_other_driver_track_record_motorconviction_replicating,
            ...additionalSubFieldsMotoringConvictionsStrings.map((item) => {
                return { id: item }
            }),
        ]
        applicationMainDriverTrackRecordMaxPoints =
            applicationMainDriverTrackRecordMaxPoints +
            customer?.entities.motoring_convictions.length *
                motoring_convictions_array_to_check.length

        applicationMainDriverTrackRecordCompletionPoints =
            applicationMainDriverTrackRecordCompletionPoints +
            customer?.entities.motoring_convictions.length *
                motoring_convictions_array_to_check.length

        if (customer_errors?.entities_error?.motoring_convictions) {
            let motor_conviction_errors_obj =
                customer_errors?.entities_error?.motoring_convictions

            for (
                let i = 0;
                i < customer.entities.motoring_convictions.length;
                i++
            ) {
                let motorConvictionObjId =
                    customer.entities.motoring_convictions[i]?.uid
                let obj_to_check =
                    motor_conviction_errors_obj[`"${motorConvictionObjId}"`]

                let objKeys = Object.values(
                    motoring_convictions_array_to_check
                ).map((field) => field.id)

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationMainDriverTrackRecordCompletionPoints =
                            applicationMainDriverTrackRecordCompletionPoints - 1
                    }
                }
            }
        }
    }

    let applicationMainDriverAdditionalDetailsMaxPoints =
        insurance_fields_main_driver_additional_details.length

    let applicationMainDriverAdditionalDetailsCompletionPoints =
        insurance_fields_main_driver_additional_details.length

    insurance_fields_main_driver_additional_details.forEach((field) => {
        if (
            customer_errors?.additional_details_errors &&
            customer_errors?.additional_details_errors[field.id]
        ) {
            applicationMainDriverAdditionalDetailsCompletionPoints =
                applicationMainDriverAdditionalDetailsCompletionPoints - 1
        }
    })

    if (
        customer?.entities &&
        customer.entities.disabilities &&
        customer.entities.disabilities.length > 0
    ) {
        const disability_array_of_fields_to_check = [
            ...disability_self_replicating_fields,
            ...additionalSubFieldsDisabilities,
        ]
        applicationMainDriverAdditionalDetailsMaxPoints =
            applicationMainDriverAdditionalDetailsMaxPoints +
            customer?.entities.disabilities.length *
                disability_array_of_fields_to_check.length

        applicationMainDriverAdditionalDetailsCompletionPoints =
            applicationMainDriverAdditionalDetailsCompletionPoints +
            customer?.entities.disabilities.length *
                disability_array_of_fields_to_check.length

        if (customer_errors?.entities_error?.disabilities) {
            let disability_errors_obj =
                customer_errors?.entities_error?.disabilities

            for (let i = 0; i < customer.entities.disabilities.length; i++) {
                let disabilityObjId = customer.entities.disabilities[i]?.uid
                let obj_to_check = disability_errors_obj[`"${disabilityObjId}"`]

                let objKeys = Object.values(
                    disability_array_of_fields_to_check
                ).map((field) => field.id)

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationMainDriverAdditionalDetailsCompletionPoints =
                            applicationMainDriverAdditionalDetailsCompletionPoints -
                            1
                    }
                }
            }
        }
    }

    if (
        customer?.entities &&
        customer.entities.memberships &&
        customer.entities.memberships.length > 0
    ) {
        applicationMainDriverAdditionalDetailsMaxPoints =
            applicationMainDriverAdditionalDetailsMaxPoints +
            customer?.entities.memberships.length *
                membership_self_replicating_fields.length

        applicationMainDriverAdditionalDetailsCompletionPoints =
            applicationMainDriverAdditionalDetailsCompletionPoints +
            customer?.entities.memberships.length *
                membership_self_replicating_fields.length

        if (customer_errors?.entities_error?.memberships) {
            let memberships_errors_obj =
                customer_errors?.entities_error?.memberships

            for (let i = 0; i < customer.entities.memberships.length; i++) {
                let membershipsObjId = customer.entities.memberships[i]?.uid
                let obj_to_check =
                    memberships_errors_obj[`"${membershipsObjId}"`]

                let objKeys = Object.values(
                    membership_self_replicating_fields
                ).map((field) => field.id)

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationMainDriverAdditionalDetailsCompletionPoints =
                            applicationMainDriverAdditionalDetailsCompletionPoints -
                            1
                    }
                }
            }
        }
    }

    let applicationMainDriverMaxPoints =
        applicationMainDriverBasicDetailsMaxPoints +
        applicationMainDriverLicenceAndAddressMaxPoints +
        applicationMainDriverTrackRecordMaxPoints +
        applicationMainDriverAdditionalDetailsMaxPoints

    let applicationMainDriverCompletionPoints =
        applicationMainDriverBasicDetailsCompletionPoints +
        applicationMainDriverLicenceAndAddressCompletionPoints +
        applicationMainDriverTrackRecordCompletionPoints +
        applicationMainDriverAdditionalDetailsCompletionPoints

    return {
        overall_max: applicationMainDriverMaxPoints,
        overall_completion: applicationMainDriverCompletionPoints,
        basic_info_max: applicationMainDriverBasicDetailsMaxPoints,
        basic_info_completion:
            applicationMainDriverBasicDetailsCompletionPoints,
        license_and_addresses_max:
            applicationMainDriverLicenceAndAddressMaxPoints,
        license_and_addresses_completion:
            applicationMainDriverLicenceAndAddressCompletionPoints,
        additional_details_max: applicationMainDriverAdditionalDetailsMaxPoints,
        additional_details_completion:
            applicationMainDriverAdditionalDetailsCompletionPoints,
        track_record_max: applicationMainDriverTrackRecordMaxPoints,
        track_record_completion:
            applicationMainDriverTrackRecordCompletionPoints,
    }
}

export const driverInsuranceStatsPoints = (
    driver: IInsuranceQuoteApplication_OtherDriver,
    driverErrors: IInsuranceQuoteApplication_OtherDriver | undefined
): {
    basic_info_max: number
    basic_info_completion: number
    license_and_addresses_max: number
    license_and_addresses_completion: number
    additional_details_max: number
    additional_details_completion: number
    track_record_max: number
    track_record_completion: number
    drive_list_max: number
    drive_list_completion: number
    overall_max: number
    overall_completion: number
} => {
    let applicationOtherDriverBasicDetailsMaxPoints =
        insurance_fields_other_driver_basic_details.length - 1

    let applicationOtherDriverBasicDetailsCompletionPoints =
        insurance_fields_other_driver_basic_details.length - 1

    // -1 stands for ,middle name which is not mandatory

    insurance_fields_other_driver_basic_details.forEach((field) => {
        if (
            driverErrors?.basic_details_errors &&
            driverErrors.basic_details_errors[field.id]
        ) {
            applicationOtherDriverBasicDetailsCompletionPoints =
                applicationOtherDriverBasicDetailsCompletionPoints - 1
        }
    })

    let insurance_fields_other_driver_licence_address_enriched = [
        ...insurance_fields_other_driver_licence_address,
    ]

    if (
        driver?.licence_address &&
        driver.licence_address['is_uk_resident'] === true
    ) {
        insurance_fields_other_driver_licence_address_enriched = [
            ...insurance_fields_other_driver_licence_address_enriched,
            ...other_driver_is_uk_resident_positive_answer_subform,
        ]
    }

    if (
        driver?.licence_address &&
        driver.licence_address['is_uk_resident_since_birth'] === false
    ) {
        insurance_fields_other_driver_licence_address_enriched = [
            ...insurance_fields_other_driver_licence_address_enriched,
            ...other_driver_is_uk_resident_since_birth_negative_answer_subform,
        ]
    }

    if (
        driver?.licence_address &&
        driver.licence_address['resides_in_the_uk'] === false
    ) {
        insurance_fields_other_driver_licence_address_enriched = [
            ...insurance_fields_other_driver_licence_address_enriched,
            ...other_driver_resides_in_the_uk_negative_answer_subform,
        ]
    }

    let applicationOtherDriverLicenceMaxPoints =
        insurance_fields_other_driver_licence_address_enriched.length

    let applicationOtherDriverLicenceCompletionPoints =
        insurance_fields_other_driver_licence_address_enriched.length

    insurance_fields_other_driver_licence_address_enriched.forEach((field) => {
        if (
            driverErrors?.licence_address_errors &&
            driverErrors.licence_address_errors[field.id]
        ) {
            applicationOtherDriverLicenceCompletionPoints =
                applicationOtherDriverLicenceCompletionPoints - 1
        }
    })

    let applicationOtherDriverAdditionalDetailsMaxPoints =
        insurance_fields_other_driver_additional_details.length

    let applicationOtherDriverAdditionalDetailsCompletionPoints =
        insurance_fields_other_driver_additional_details.length

    insurance_fields_other_driver_additional_details.forEach((field) => {
        if (
            driverErrors?.additional_details_errors &&
            driverErrors.additional_details_errors[field.id]
        ) {
            applicationOtherDriverAdditionalDetailsCompletionPoints =
                applicationOtherDriverAdditionalDetailsCompletionPoints - 1
        }
    })

    if (
        driver?.entities &&
        driver.entities.disabilities &&
        driver.entities.disabilities.length > 0
    ) {
        const disability_array_of_fields_to_check = [
            ...disability_self_replicating_fields,
            ...additionalSubFieldsDisabilities,
        ]
        applicationOtherDriverAdditionalDetailsMaxPoints =
            applicationOtherDriverAdditionalDetailsMaxPoints +
            driver?.entities.disabilities.length *
                disability_array_of_fields_to_check.length

        applicationOtherDriverAdditionalDetailsCompletionPoints =
            applicationOtherDriverAdditionalDetailsCompletionPoints +
            driver?.entities.disabilities.length *
                disability_array_of_fields_to_check.length

        if (driverErrors?.entities_error?.disabilities) {
            let disability_errors_obj =
                driverErrors?.entities_error?.disabilities

            for (let i = 0; i < driver.entities.disabilities.length; i++) {
                let disabilityObjId = driver.entities.disabilities[i]?.uid
                let obj_to_check = disability_errors_obj[`"${disabilityObjId}"`]

                let objKeys = Object.values(
                    disability_array_of_fields_to_check
                ).map((field) => field.id)

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationOtherDriverAdditionalDetailsCompletionPoints =
                            applicationOtherDriverAdditionalDetailsCompletionPoints -
                            1
                    }
                }
            }
        }
    }

    let applicationOtherDriverTrackRecordMaxPoints =
        insurance_fields_other_driver_track_record.length

    let applicationOtherDriverTrackRecordCompletionPoints =
        insurance_fields_other_driver_track_record.length

    insurance_fields_other_driver_track_record.forEach((field) => {
        if (
            driverErrors?.track_record_errors &&
            driverErrors.track_record_errors[field.id]
        ) {
            applicationOtherDriverTrackRecordCompletionPoints =
                applicationOtherDriverTrackRecordCompletionPoints - 1
        }
    })

    if (
        driver?.entities &&
        driver?.entities.claims &&
        driver?.entities.claims.length > 0
    ) {
        let claim_array_of_fields_to_check = [
            ...self_replicating_claim,
            ...additionalSubFieldsClaims,
        ]

        applicationOtherDriverTrackRecordMaxPoints =
            applicationOtherDriverTrackRecordMaxPoints +
            driver?.entities.claims.length *
                claim_array_of_fields_to_check.length

        applicationOtherDriverTrackRecordCompletionPoints =
            applicationOtherDriverTrackRecordCompletionPoints +
            driver?.entities.claims.length *
                claim_array_of_fields_to_check.length

        if (driverErrors?.entities_error?.claims) {
            let claims_errors_obj = driverErrors?.entities_error?.claims

            for (let i = 0; i < driver.entities.claims.length; i++) {
                let claimObjId = driver.entities.claims[i]?.uid
                let obj_to_check = claims_errors_obj[`"${claimObjId}"`]

                let objKeys = Object.values(claim_array_of_fields_to_check).map(
                    (field) => field.id
                )

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationOtherDriverTrackRecordCompletionPoints =
                            applicationOtherDriverTrackRecordCompletionPoints -
                            1
                    }
                }
            }
        }
    }
    if (
        driver?.entities &&
        driver.entities.convictions &&
        driver.entities.convictions.length > 0
    ) {
        let convictions_array_of_fields_to_check = [
            ...insurance_sub_fields_other_driver_track_record_conviction_replicating,
            ...additionalSubFieldsConvictions.map((item) => {
                return { id: item }
            }),
        ]

        applicationOtherDriverTrackRecordMaxPoints =
            applicationOtherDriverTrackRecordMaxPoints +
            driver?.entities.convictions.length *
                convictions_array_of_fields_to_check.length

        applicationOtherDriverTrackRecordCompletionPoints =
            applicationOtherDriverTrackRecordCompletionPoints +
            driver?.entities.convictions.length *
                convictions_array_of_fields_to_check.length

        if (driverErrors?.entities_error?.convictions) {
            let conviction_errors_obj =
                driverErrors?.entities_error?.convictions

            for (let i = 0; i < driver.entities.convictions.length; i++) {
                let convictionObjId = driver.entities.convictions[i]?.uid
                let obj_to_check = conviction_errors_obj[`"${convictionObjId}"`]

                let objKeys = Object.values(
                    convictions_array_of_fields_to_check
                ).map((field) => field.id)

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationOtherDriverTrackRecordCompletionPoints =
                            applicationOtherDriverTrackRecordCompletionPoints -
                            1
                    }
                }
            }
        }
    }

    if (
        driver?.entities &&
        driver.entities.motoring_convictions &&
        driver.entities.motoring_convictions.length > 0
    ) {
        const motoring_convictions_array_to_check = [
            ...insurance_sub_fields_other_driver_track_record_motorconviction_replicating,
            ...additionalSubFieldsMotoringConvictionsStrings.map((item) => {
                return { id: item }
            }),
        ]

        applicationOtherDriverTrackRecordMaxPoints =
            applicationOtherDriverTrackRecordMaxPoints +
            driver?.entities.motoring_convictions.length *
                motoring_convictions_array_to_check.length

        applicationOtherDriverTrackRecordCompletionPoints =
            applicationOtherDriverTrackRecordCompletionPoints +
            driver?.entities.motoring_convictions.length *
                motoring_convictions_array_to_check.length

        if (driverErrors?.entities_error?.motoring_convictions) {
            let motor_conviction_errors_obj =
                driverErrors?.entities_error?.motoring_convictions

            for (
                let i = 0;
                i < driver.entities.motoring_convictions.length;
                i++
            ) {
                let motorConvictionObjId =
                    driver.entities.motoring_convictions[i]?.uid
                let obj_to_check =
                    motor_conviction_errors_obj[`"${motorConvictionObjId}"`]

                let objKeys = Object.values(
                    motoring_convictions_array_to_check
                ).map((field) => field.id)

                for (let a = 0; a < objKeys.length; a++) {
                    let key_to_check = objKeys[a]

                    if (obj_to_check && obj_to_check[key_to_check]) {
                        applicationOtherDriverTrackRecordCompletionPoints =
                            applicationOtherDriverTrackRecordCompletionPoints -
                            1
                    }
                }
            }
        }
    }

    let applicationOtherDriverDrivelistMaxPoints = 1

    let applicationOtherDriverDrivelistCompletionPoints = 1

    if (driverErrors?.drive_list_errors) {
        applicationOtherDriverDrivelistCompletionPoints = 0
    }

    let applicationOtherDriverMaxPoints =
        applicationOtherDriverBasicDetailsMaxPoints +
        applicationOtherDriverLicenceMaxPoints +
        applicationOtherDriverTrackRecordMaxPoints +
        applicationOtherDriverAdditionalDetailsMaxPoints +
        applicationOtherDriverDrivelistMaxPoints

    let applicationOtherDriverCompletionPoints =
        applicationOtherDriverBasicDetailsCompletionPoints +
        applicationOtherDriverLicenceCompletionPoints +
        applicationOtherDriverTrackRecordCompletionPoints +
        applicationOtherDriverAdditionalDetailsCompletionPoints +
        applicationOtherDriverDrivelistCompletionPoints

    return {
        overall_max: applicationOtherDriverMaxPoints,
        overall_completion: applicationOtherDriverCompletionPoints,
        basic_info_max: applicationOtherDriverBasicDetailsMaxPoints,
        basic_info_completion:
            applicationOtherDriverBasicDetailsCompletionPoints,
        license_and_addresses_max: applicationOtherDriverLicenceMaxPoints,
        license_and_addresses_completion:
            applicationOtherDriverLicenceCompletionPoints,
        additional_details_max:
            applicationOtherDriverAdditionalDetailsMaxPoints,
        additional_details_completion:
            applicationOtherDriverAdditionalDetailsCompletionPoints,
        track_record_max: applicationOtherDriverTrackRecordMaxPoints,
        track_record_completion:
            applicationOtherDriverTrackRecordCompletionPoints,
        drive_list_max: applicationOtherDriverDrivelistMaxPoints,
        drive_list_completion: applicationOtherDriverDrivelistCompletionPoints,
    }
}
