import OverviewGridManagerDesktop from '../../car/OverviewGridManagerDesktop'
import { IUser } from 'myModels'
import { generateUserChoiceUnit } from '../../../../helpers/units/generateUserChoiceUnit'
import { IInsuranceQuoteApplication_Car } from '../../../../redux/insuranceQuoteApplication/reducer'
import { useMemo } from 'react'

type Props = {
    vehicleid?: string
    vehicle?: IInsuranceQuoteApplication_Car
    userLoggedIn: IUser
    isMobile?: boolean
}
const InsuranceTechInfoCardContent = (props: Props) => {
    let { vehicle, vehicleid, userLoggedIn, isMobile } = props

    let userEngineSizePreferences = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'fuel_unit')
        : undefined

    const default_fuel_unit_user: string | null =
        userEngineSizePreferences?.unit_id ?? null

    const default_fuel_unit_user_text: string | null | undefined =
        userEngineSizePreferences?.unit_text ?? null

    let data = useMemo(() => {
        let tempdata: {
            [key: string]: {
                id: string
                text: string
                answer: string
            }
        } = {}
        if (vehicle?.tech_info) {
            Object.keys(vehicle.tech_info).map((key) => {
                tempdata[key] = {
                    id: key,
                    text: key,
                    answer: vehicle?.tech_info
                        ? vehicle.tech_info[key]
                        : undefined,
                }
            })
        } else {
            let array_of_tech_info = [
                'drive_train',
                'make',
                'model',
                'transmission',
                'engine_size',
                'manufacture_year',
            ]
            array_of_tech_info.map((key) => {
                tempdata[key] = {
                    id: key,
                    text: key,
                    answer: vehicle?.tech_info
                        ? vehicle.tech_info[key]
                        : undefined,
                }
            })
        }

        return tempdata
    }, [vehicle])

    // console.log('data', data)

    return (
        <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
            <OverviewGridManagerDesktop
                ids={[
                    'make',
                    'model',
                    'manufacture_year',
                    'engine_capacity',
                    'transmission',
                    'drive_side',
                ]}
                data={data}
                carid={vehicleid ?? ''}
                fuel_unit_text={
                    default_fuel_unit_user_text
                        ? default_fuel_unit_user_text
                        : '-'
                }
                fuel_unit={
                    default_fuel_unit_user
                        ? default_fuel_unit_user
                        : 'cubic_centimeters'
                }
                customWidth={'100%'}
                notClickable
                readOnlyMode
                isShortDescriptionLong={isMobile}
                isInsurance
            />
        </div>
    )
}

export default InsuranceTechInfoCardContent
