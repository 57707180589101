import { IGalleryImage } from 'entityModels'
import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
// import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../skeleton/skeletonAbsolute'

const ImageSizeWrapper = styled.div<StyleProps>`
    position: relative;
    aspect-ratio: 1.33;
    width: 100%;
    height: auto;
`

const GalleryImage = styled.img<StyleProps>`
    object-fit: cover !important;
    aspect-ratio: 1.33;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.isthingloading === true ? '0' : '1')};
    transition: opacity 400ms;
`

type State = {
    src: any
    isLoading: boolean
}

interface Props {
    img?: IGalleryImage
    placeholder?: string
    isBestQuality?: boolean
}

interface StyleProps {
    isthingloading?: boolean
}

class EntryGalleryItemPreview extends React.Component<Props, State> {
    state = {
        src: null,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src =
            this.props.img && this.props.img.url !== undefined
                ? this.props.img.url
                : this.props.placeholder
                ? this.props.placeholder
                : undefined

        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.img?.url !== prevProps.img?.url) {
            const src =
                this.props.img && this.props.img.url !== undefined
                    ? this.props.img.url
                    : this.props.placeholder
                    ? this.props.placeholder
                    : undefined

            if (src) {
                return this.setState({ src: src, isLoading: true })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { img } = this.props
        const { isLoading } = this.state
        return (
            <ImageSizeWrapper>
                <SkeletonAbsolute isthingloading={isLoading} />

                <GalleryImage
                    aria-label={img && img.caption ? img.caption : 'picture'}
                    src={this.state.src || grey_placeholder}
                    srcSet={
                        this.props.isBestQuality
                            ? img && img.large_src
                                ? img.large_src
                                : undefined
                            : img && img.medium_srcset
                            ? img.medium_srcset
                            : undefined
                    }
                    isthingloading={isLoading}
                    onLoad={() =>
                        this._isMounted &&
                        this.setState({
                            ...this.state,
                            isLoading: false,
                        })
                    }
                />
            </ImageSizeWrapper>
        )
    }
}

export default EntryGalleryItemPreview
