import {
    IallApex_homepage_slideshows_api,
    IApexHomepageContentQueryAPI,
    IApexHomepageDataState,
    IApexHomepageSlideState,
    IApexPodscastState,
    IAllApex_podcastss_api,
    IApexWeeklyEventState,
    IAllApex_weeklyEventss_api,
    IApexWeeklyQuoteState,
    IAllApex_weeklyQuotess_api,
    IApexHomepageArticleState,
    IAllApex_homepage_articless_api,
    IApexCarOfTheWeekState,
    IApexWeeklyNewsHighlightState,
    IAllApexWeekly_highlightss_api,
    IApexWeeklInterviewCategoryHighlightState,
    IAllApex_interviewCategoryHighlightss_api,
    IApexHomepageContentQueryAPI_slides,
    IAllApex_podcastss_apiNode,
    IApexWeeklyEventNode,
    IApexWeeklyQuoteNode,
    IApexHomepageArticleNode,
    IApexWeeklyNewsHighlightNode,
    IApexInterviewCategoryHighlight_node_api,
    IApexInterviewOverviewItemState,
    IApexInterviewOverview_item_api,
} from 'apexModels'
import {
    convertImgPrismicWithCompression,
    convertImgPrismicWithCustomCompression,
} from './apexImgPrismicCompression'
import { ConvertPrismicApexCarOfTheWeekFromAPItoSingle } from './carsOfTheWeek'

// micro conversions

let convertPrismicApexHomepageSlideshowFromAPI = (
    data: IallApex_homepage_slideshows_api
): IApexHomepageSlideState[] => {
    let slideshow: IApexHomepageSlideState[] = []

    let slides: IApexHomepageContentQueryAPI_slides[] | undefined =
        data.edges[0] &&
        data.edges[0].node &&
        data.edges[0].node.slides &&
        data.edges[0].node.slides

    if (slides !== undefined) {
        for (const slide of slides) {
            let converted_slide: IApexHomepageSlideState = {
                title:
                    slide.title &&
                    slide.title[0] &&
                    slide.title[0] &&
                    slide.title[0].text
                        ? slide.title[0].text
                        : '',
                blurb:
                    slide.blurb &&
                    slide.blurb[0] &&
                    slide.blurb[0] &&
                    slide.blurb[0].text
                        ? slide.blurb[0].text
                        : '',

                quote:
                    slide.quote &&
                    slide.quote[0] &&
                    slide.quote[0] &&
                    slide.quote[0].text
                        ? slide.quote[0].text
                        : '',
                text_position: slide.text_position
                    ? slide.text_position
                    : 'top_right',
                img:
                    slide.img &&
                    convertImgPrismicWithCustomCompression(slide.img, '1280'),
                link: slide.link && slide.link.url ? slide.link.url : undefined,
            }

            slideshow.push(converted_slide)
        }
    }

    return slideshow
}

let convertPrismicApexPodcastFromAPI = (
    data: IAllApex_podcastss_api
): IApexPodscastState[] => {
    let podcasts: IApexPodscastState[] = data.edges.map(
        (podcastNode: any, index: any) => {
            let node: IAllApex_podcastss_apiNode = podcastNode.node

            let item: IApexPodscastState = {
                date: node.date ? node.date : '',
                name: node.name ? node.name : '',
                short_title: node.short_title ? node.short_title : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                link:
                    node.podcast_embed_link && node.podcast_embed_link.url
                        ? node.podcast_embed_link.url
                        : '',
                spotify_url:
                    node.spotify_url && node.spotify_url.url
                        ? node.spotify_url.url
                        : '',
            }

            return item
        }
    )

    return podcasts
}

let convertPrismicApexWeeklyEventsFromAPI = (
    data: IAllApex_weeklyEventss_api
): IApexWeeklyEventState[] => {
    let items: IApexWeeklyEventState[] = data.edges.map(
        (podcastNode: any, index: any) => {
            let node: IApexWeeklyEventNode = podcastNode.node

            let item: IApexWeeklyEventState = {
                date: node.date ? node.date : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                event_date_start:
                    node.event_date_start && node.event_date_start,
                event_date_end: node.event_date_end && node.event_date_end,
                link: node.link && node.link.url ? node.link.url : '',
                location: node.event_location ? node.event_location : 'Unknown',
            }

            return item
        }
    )

    return items
}

let convertPrismicApexWeeklyQuoteFromAPI = (
    data: IAllApex_weeklyQuotess_api
): IApexWeeklyQuoteState => {
    let node: IApexWeeklyQuoteNode | undefined =
        data.edges[0] && data.edges[0].node && data.edges[0].node

    let res: IApexWeeklyQuoteState = {
        date: '',
        quote: '',
        title: '',
        name: '',
    }

    if (node !== undefined) {
        res.date = node.date ? node.date : ''
        res.quote =
            node.quote && node.quote[0] && node.quote[0] && node.quote[0].text
                ? node.quote[0].text
                : ''

        res.title =
            node.title && node.title[0] && node.title[0] && node.title[0].text
                ? node.title[0].text
                : ''

        res.name = node.name ? node.name : ''

        return res
    } else return res
}

let convertPrismicApexHomepageArticlesFromAPI = (
    data: IAllApex_homepage_articless_api
): IApexHomepageArticleState[] => {
    let items: IApexHomepageArticleState[] = data.edges.map(
        (articleNode: any, index: any) => {
            let node: IApexHomepageArticleNode = articleNode.node

            let item: IApexHomepageArticleState = {
                date: node.date ? node.date : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                img:
                    node.external_cover_img &&
                    convertImgPrismicWithCompression(node.external_cover_img),
                introduction:
                    node.introduction &&
                    node.introduction[0] &&
                    node.introduction[0] &&
                    node.introduction[0].text
                        ? node.introduction[0].text
                        : '',
                name: node.name ? node.name : '',
                uid: node._meta && node._meta.uid ? node._meta.uid : '',
            }

            return item
        }
    )

    return items
}

let convertPrismicApexWeeklyNewsHighlightsFromAPI = (
    data: IAllApexWeekly_highlightss_api
): IApexWeeklyNewsHighlightState[] => {
    let items: IApexWeeklyNewsHighlightState[] = []

    for (const news of data.edges) {
        let node: IApexWeeklyNewsHighlightNode = news.node

        if (node.featured !== true) {
            let newsConverted: IApexWeeklyNewsHighlightState = {
                featured: node.featured ? node.featured : false,
                date: node.date ? node.date : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                img: node.image && convertImgPrismicWithCompression(node.image),
                description:
                    node.description &&
                    node.description[0] &&
                    node.description[0] &&
                    node.description[0].text
                        ? node.description[0].text
                        : '',
                image_credit:
                    node.image_credit &&
                    node.image_credit[0] &&
                    node.image_credit[0] &&
                    node.image_credit[0].text
                        ? node.image_credit[0].text
                        : '',
                link: node.link && node.link.url ? node.link.url : '',
            }

            items.push(newsConverted)
        }
    }

    return items
}

let convertPrismicApexHighlightedWeeklyNewsFromAPI = (
    data: IAllApexWeekly_highlightss_api
): IApexWeeklyNewsHighlightState | undefined => {
    for (const news of data.edges) {
        let node: IApexWeeklyNewsHighlightNode = news.node

        if (node.featured === true) {
            let newsConverted: IApexWeeklyNewsHighlightState = {
                featured: node.featured ? node.featured : false,
                date: node.date ? node.date : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                img: node.image && convertImgPrismicWithCompression(node.image),
                description:
                    node.description &&
                    node.description[0] &&
                    node.description[0] &&
                    node.description[0].text
                        ? node.description[0].text
                        : '',
                image_credit:
                    node.image_credit &&
                    node.image_credit[0] &&
                    node.image_credit[0] &&
                    node.image_credit[0].text
                        ? node.image_credit[0].text
                        : '',
                link: node.link && node.link.url ? node.link.url : '',
            }

            return newsConverted
        }
    }
}

let convertPrismicApexInterviewCategoryHighlightsFromAPI = (
    data: IAllApex_interviewCategoryHighlightss_api
): IApexWeeklInterviewCategoryHighlightState[] => {
    let node: IApexInterviewCategoryHighlight_node_api | undefined = data
        .edges[0]
        ? data.edges[0].node
        : undefined

    let res: IApexWeeklInterviewCategoryHighlightState[] = []

    if (node !== undefined) {
        let res_1_items: IApexInterviewOverviewItemState[] =
            node.category_1_item.map(
                (node: IApexInterviewOverview_item_api, index: number) => {
                    let item: IApexInterviewOverviewItemState = {
                        name: node.name ? node.name : '',
                        title:
                            node.title &&
                            node.title[0] &&
                            node.title[0] &&
                            node.title[0].text
                                ? node.title[0].text
                                : '',
                        date: node.date ? node.date : '',
                        uid: node.uid
                            ? node.uid
                            : node._meta
                              ? node._meta.uid
                                  ? node._meta.uid
                                  : ''
                              : '',

                        blurb: node.blurb ? node.blurb : '',
                        headshot:
                            node.headshot &&
                            convertImgPrismicWithCompression(node.headshot),
                    }

                    return item
                }
            )

        let res_2_items: IApexInterviewOverviewItemState[] =
            node.category_2_item.map(
                (node: IApexInterviewOverview_item_api, index: number) => {
                    let item: IApexInterviewOverviewItemState = {
                        name: node.name ? node.name : '',
                        title:
                            node.title &&
                            node.title[0] &&
                            node.title[0] &&
                            node.title[0].text
                                ? node.title[0].text
                                : '',
                        date: node.date ? node.date : '',
                        uid: node.uid
                            ? node.uid
                            : node._meta
                              ? node._meta.uid
                                  ? node._meta.uid
                                  : ''
                              : '',

                        blurb: node.blurb ? node.blurb : '',
                        headshot:
                            node.headshot &&
                            convertImgPrismicWithCompression(node.headshot),
                    }

                    return item
                }
            )

        let res_3_items: IApexInterviewOverviewItemState[] =
            node.category_3_item.map(
                (node: IApexInterviewOverview_item_api, index: number) => {
                    let item: IApexInterviewOverviewItemState = {
                        name: node.name ? node.name : '',
                        title:
                            node.title &&
                            node.title[0] &&
                            node.title[0] &&
                            node.title[0].text
                                ? node.title[0].text
                                : '',
                        date: node.date ? node.date : '',
                        uid: node.uid
                            ? node.uid
                            : node._meta
                              ? node._meta.uid
                                  ? node._meta.uid
                                  : ''
                              : '',
                        blurb: node.blurb ? node.blurb : '',
                        headshot:
                            node.headshot &&
                            convertImgPrismicWithCompression(node.headshot),
                    }

                    return item
                }
            )

        let res_4_items: IApexInterviewOverviewItemState[] =
            node.category_4_item.map(
                (node: IApexInterviewOverview_item_api, index: number) => {
                    let item: IApexInterviewOverviewItemState = {
                        name: node.name ? node.name : '',
                        title:
                            node.title &&
                            node.title[0] &&
                            node.title[0] &&
                            node.title[0].text
                                ? node.title[0].text
                                : '',
                        date: node.date ? node.date : '',
                        uid: node.uid
                            ? node.uid
                            : node._meta
                              ? node._meta.uid
                                  ? node._meta.uid
                                  : ''
                              : '',
                        blurb: node.blurb ? node.blurb : '',
                        headshot:
                            node.headshot &&
                            convertImgPrismicWithCompression(node.headshot),
                    }

                    return item
                }
            )

        let res_1: IApexWeeklInterviewCategoryHighlightState = {
            category: node.category_1_name ? node.category_1_name : undefined,
            items: res_1_items,
        }
        let res_2: IApexWeeklInterviewCategoryHighlightState = {
            category: node.category_2_name ? node.category_2_name : undefined,
            items: res_2_items,
        }

        let res_3: IApexWeeklInterviewCategoryHighlightState = {
            category: node.category_3_name ? node.category_3_name : undefined,
            items: res_3_items,
        }

        let res_4: IApexWeeklInterviewCategoryHighlightState = {
            category: node.category_4_name ? node.category_4_name : undefined,
            items: res_4_items,
        }

        res[0] = res_1
        res[1] = res_2
        res[2] = res_3
        res[3] = res_4

        return res
    } else return res
}

// final state conversions

export let ConvertPrismicApexHomepageDataFromAPItoLocalState = (
    data: IApexHomepageContentQueryAPI
): IApexHomepageDataState => {
    let res: IApexHomepageDataState = {}

    if (data.allApex_homepage_slideshows) {
        let slideshow: IApexHomepageSlideState[] =
            convertPrismicApexHomepageSlideshowFromAPI(
                data.allApex_homepage_slideshows
            )

        res.slideshow = slideshow
    }

    if (data.allApex_podcastss) {
        let podcasts: IApexPodscastState[] = convertPrismicApexPodcastFromAPI(
            data.allApex_podcastss
        )

        res.podcasts = podcasts
    }

    if (data.allApex_weekly_eventss) {
        let weeklyEvents: IApexWeeklyEventState[] =
            convertPrismicApexWeeklyEventsFromAPI(data.allApex_weekly_eventss)

        res.weeklyEvents = weeklyEvents
    }

    if (data.allApex_weekly_quotess) {
        let weeklyQuote: IApexWeeklyQuoteState =
            convertPrismicApexWeeklyQuoteFromAPI(data.allApex_weekly_quotess)

        res.weeklyQuote = weeklyQuote
    }

    if (data.allApex_articless) {
        let articles: IApexHomepageArticleState[] =
            convertPrismicApexHomepageArticlesFromAPI(data.allApex_articless)

        res.articles = articles
    }

    if (data.allApex_car_of_the_weeks) {
        let carOfTheWeek: IApexCarOfTheWeekState =
            ConvertPrismicApexCarOfTheWeekFromAPItoSingle(
                data.allApex_car_of_the_weeks
            )

        res.carOfTheWeek = carOfTheWeek
    }

    if (data.allApex_weekly_highlightss) {
        let weeklyNewsHighlights: IApexWeeklyNewsHighlightState[] =
            convertPrismicApexWeeklyNewsHighlightsFromAPI(
                data.allApex_weekly_highlightss
            )

        let highlightedWeeklyNews: IApexWeeklyNewsHighlightState | undefined =
            convertPrismicApexHighlightedWeeklyNewsFromAPI(
                data.allApex_weekly_highlightss
            )

        res.weeklyNewsHighlights = weeklyNewsHighlights
        res.oneNewsHighlight = highlightedWeeklyNews
    }

    if (data.allInterview_category_highlightss) {
        let interviewCategoryHighlights: IApexWeeklInterviewCategoryHighlightState[] =
            convertPrismicApexInterviewCategoryHighlightsFromAPI(
                data.allInterview_category_highlightss
            )

        res.interviewCategoryHighlights = interviewCategoryHighlights
    }

    return res
}
