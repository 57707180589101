import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const LocationIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 21C12 21 18.9231 13.3167 18.9231 9.64615C18.9231 5.97558 15.8235 3 12 3C8.17647 3 5.0769 5.97558 5.0769 9.64615C5.0769 13.3167 12 21 12 21Z"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
                stroke-linecap="square"
            />
            <circle
                cx="12"
                cy="9.55933"
                r="3"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default LocationIcon
