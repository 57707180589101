import { createAction } from '@reduxjs/toolkit'
import { INormalisedAttachmentsByID } from 'attachmentModels'

import {
    ADD_ATTACHMENTS_SUCCESS,
    CLEAN_UP_ATTACHMENTS_SUCCESS,
} from './constants'

export const addAttachmentsSuccess = createAction(
    ADD_ATTACHMENTS_SUCCESS,
    function prepare(payload: INormalisedAttachmentsByID) {
        return {
            payload,
        }
    }
)

export const cleanUpAttachmentsSuccess = createAction(
    CLEAN_UP_ATTACHMENTS_SUCCESS
)
