import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JailLock = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3097_30838)">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.75 1.25H23.25V22.75H1.75V1.25ZM3.25 2.75V21.25H21.75V2.75H3.25Z"
                    fill={color ?? colours[theme].text_darker}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.75 22V2H6.25V22H4.75Z"
                    fill={color ?? colours[theme].text_darker}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M18.75 22V2H20.25V22H18.75Z"
                    fill={color ?? colours[theme].text_darker}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.75 5V2H16.25V5H14.75ZM14.75 22V20H16.25V22H14.75Z"
                    fill={color ?? colours[theme].text_darker}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.75 5V2H10.25V5H8.75ZM8.75 22V20H10.25V22H8.75Z"
                    fill={color ?? colours[theme].text_darker}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.75 11C7.75 10.0335 8.5335 9.25 9.5 9.25H15.5C16.4665 9.25 17.25 10.0335 17.25 11V16C17.25 16.9665 16.4665 17.75 15.5 17.75H9.5C8.5335 17.75 7.75 16.9665 7.75 16V11ZM9.5 10.75C9.36193 10.75 9.25 10.8619 9.25 11V16C9.25 16.1381 9.36193 16.25 9.5 16.25H15.5C15.6381 16.25 15.75 16.1381 15.75 16V11C15.75 10.8619 15.6381 10.75 15.5 10.75H9.5Z"
                    fill={color ?? colours[theme].text_darker}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.75 9C9.75 7.48122 10.9812 6.25 12.5 6.25C14.0188 6.25 15.25 7.48122 15.25 9V10H13.75V9C13.75 8.30964 13.1904 7.75 12.5 7.75C11.8096 7.75 11.25 8.30964 11.25 9V10H9.75V9Z"
                    fill={color ?? colours[theme].text_darker}
                />
                <path
                    d="M13.5 13.5C13.5 14.0523 13.0523 14.5 12.5 14.5C11.9477 14.5 11.5 14.0523 11.5 13.5C11.5 12.9477 11.9477 12.5 12.5 12.5C13.0523 12.5 13.5 12.9477 13.5 13.5Z"
                    fill={color ?? colours[theme].text_darker}
                />
            </g>
            <defs>
                <clipPath id="clip0_3097_30838">
                    <rect
                        width={size ?? '24'}
                        height={size ?? '24'}
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default JailLock
