// import dayjs from 'dayjs'
import moment from 'moment'
import { ReminderAllSelectTypes } from '../../localdata/remindersLocalData'
// import { convertDateToBeFormat } from './dates'

var dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export const fromIsoStringToDDMMYYY = (isostring: string): string => {
    let conv: string = dayjs(isostring).format('DD MMM YYYY')

    if (conv) {
        return conv
    } else return ''
}

export const fromIsoStringToDDMMYYY_time = (isostring: string): string => {
    let conv: string = dayjs(isostring).format('DD MMM YYYY HH:mm')

    if (conv) {
        return conv
    } else return ''
}

export const fromIsoStringToDDMMYYYInTimezone = (
    isostring: string,
    timezone: string
): string => {
    let conv: string = dayjs(isostring)
        .tz(timezone.trim().split(' ').join('_'))
        .format('DD MMM YYYY')
    if (conv) {
        return conv
    } else return ''
}

export const DatePickerConverterFormat = (date: string): string => {
    let md = moment(date)
    let da = md && md.format('YYYY-MM-DD')

    if (md && da) {
        return da
    } else return ''
}

export const shareCardConverterFormat = (date: string): string => {
    let md = moment(date)
    let da = md && md.format('DD/MM/YYYY')

    if (md && da) {
        return da
    } else return ''
}

export const fromISOStringToReminderDate = (isostring: string): string => {
    let conv: string = dayjs(isostring).format('DD MMM YYYY')

    if (conv) {
        return conv
    } else return ''
}

export type IConvertReminderDateToBEPayload = {
    type: ReminderAllSelectTypes | string
    value: string
    nvalue?: number | undefined
    dueDate: string
}

export const fromReminderDateToBackEndFormat = (
    p: IConvertReminderDateToBEPayload
): string => {
    let moment_dueDate = moment(p.dueDate).endOf('day').utcOffset(0)

    moment_dueDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    let nValueP = p.nvalue

    if (!p.nvalue && p.type !== undefined) {
        nValueP = 1
    }

    switch (p.type) {
        case 'custom_date_copy':
            let d = moment(p.value).endOf('day').utcOffset(0)
            d.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            return d.toISOString()

        case 'tomorrow': {
            // return moment().local().startOf('day').add(1, 'days').toISOString()
            const ofset = dayjs().utcOffset() * 60000
            return dayjs().add(1, 'day').startOf('day').add(ofset).toISOString()
        }

        case 'on_due_day':
            return moment_dueDate.toISOString()

        case 'days_before':
            return moment_dueDate.subtract(nValueP, 'days').toISOString()

        case 'weeks_before':
            return moment_dueDate.subtract(nValueP, 'weeks').toISOString()

        case 'months_before':
            return moment_dueDate.subtract(nValueP, 'months').toISOString()
        default:
            return ''
    }
}
