import { IGarage } from 'entityModels'
import * as actions from './actions'
import * as loadingActions from './loadingActions'
// import * as errorActions from "./errorActions";

import { ThunkAction } from 'redux-thunk'
import { RootAction } from 'typesafe-actions'

interface IGetState {
    [key: string]: any
}

export const setGarageData = (
    garageToAdd: IGarage
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any, getState: () => IGetState): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.setGarageDataRequest())

            dispatch(actions.setGarageDataSuccess(garageToAdd))
        })
    }
}

export const setCurrentExternalGarage = (
    garage_id: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.setCurrentExternalGarageRequest())

            dispatch(actions.setCurrentExternalGarageSuccess(garage_id))
        })
    }
}
