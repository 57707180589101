type Props = {
    height?: string
    width?: string
    isMobile?: boolean
}

const HistoryFilePattern = ({ height, width, isMobile }: Props) => {
    return (
        <svg
            width={width ?? '1440'}
            height={height ?? '680'}
            viewBox="0 0 1440 680"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity={isMobile ? '0.05' : '0.15'}
                d="M354.836 280.229C93.1742 246.153 -149.317 347.484 -172 402.409V686H1729V0H1454.61C1408.51 93.4096 1369.43 287.627 1169.24 344.121C918.99 414.738 681.913 322.824 354.836 280.229Z"
                fill="url(#paint0_linear_2418_64763)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2418_64763"
                    x1="437.755"
                    y1="570.826"
                    x2="715.879"
                    y2="-186.543"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#67A0E3" stop-opacity="0" />
                    <stop offset="1" stop-color="#67A0E3" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default HistoryFilePattern
