import { Link, useHistory } from 'react-router-dom'
import {
    IInsuranceQuoteApplication_MainDriver,
    IInsuranceQuoteApplication_OtherDriverObj,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { IInsuranceApplicationSectionCard } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import styled from 'styled-components'
import AddBox from '../../../atoms/Button/addBox'
import { useState } from 'react'
import { device } from '../../../templates/displays/devices'
import { useAppSelector } from '../../../../redux/store/hooks'
import warning_red from '../../../../public/assets/icons/warning_red.svg'
import useThemes from '../../../../providers/theme/hooks'
import { insurance_colours } from '../../../../providers/theme/colours'

type Props = {
    card: IInsuranceApplicationSectionCard
    other_drivers: IInsuranceQuoteApplication_OtherDriverObj
    selected_named_drivers: string[] | undefined
    main_policy_holder: IInsuranceQuoteApplication_MainDriver
    isMobile?: boolean
}

const GridWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 100px;
    grid-gap: 8px;
    padding-top: 16px;
    @media ${device.mobile_and_ipad} {
        grid-template-columns: 1fr;
    }
`

const MainPolicyHolderBox = styled(Link)<{ $customHoverColour?: string }>`
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background-color: var(--off-bg-color, #fafafa);
    cursor: pointer;
    height: 100%;
    width: 100%;
    :hover {
        background: ${(props) =>
            props.$customHoverColour ?? 'rgba(94, 195, 202, 0.04)'};
    }

    @media ${device.mobile_and_ipad} {
        grid-column-start: 1;
        grid-column-end: 1;
        padding: 16px 18px;
    }
`

const FlexRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const WarningIcon = styled.img`
    width: 14px;
    margin-left: 8px;

    @media ${device.mobile_and_ipad} {
        margin-left: 4px;
    }
`

const OtherBox = styled(Link)<{ $customHoverColour?: string }>`
    all: unset;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 4px;
    cursor: pointer;
    background-color: var(--off-bg-color, #fafafa);
    :hover {
        background: ${(props) =>
            props.$customHoverColour ?? 'rgba(94, 195, 202, 0.04)'};
    }
`

const EmptyBox = styled.div`
    height: 100%;
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--off-bg-color, #fafafa);
    width: 100%;
`

const EmptyLine = styled.div`
    height: 16px;
    background: var(--garage-placeholder-gradient-1, rgba(26, 26, 26, 0.04));
`

const NameRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
const Name = styled.h3`
    all: unset;
    color: var(--text-strong, #1a1a1a);
    /* Desktop/Text/T300 Regular */

    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    /* max-width: 80%; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 186px;

    display: block;

    @media ${device.mobile_and_ipad} {
        max-width: 50vw;
    }

    @media (max-width: 400px) {
        font-size: 15px;
    }
`

const Text = styled.p`
    all: unset;
    color: var(--text-muted, #b3b3b3);
    /* Desktop/Text/T300 Regular */
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;

    @media (max-width: 400px) {
        font-size: 15px;
    }
`

// const StyledLink = styled(Link)`
//     color: var(--text-primary, #5ec3ca);
//     /* Desktop/Text/T300 Strong */
//     font-size: 16px;
//     font-family: Lato;
//     font-style: normal;
//     font-weight: 600;
//     line-height: 24px;
//     letter-spacing: 0.001px;
// `

const InsurancePolicyDriversCardContent = (props: Props) => {
    let {
        other_drivers,
        main_policy_holder,
        isMobile,
        selected_named_drivers,
    } = props

    const history = useHistory()

    let number_of_drivers = selected_named_drivers
        ? selected_named_drivers.length
        : 0
    let array_of_placeholders = isMobile
        ? number_of_drivers < 2
            ? Array.from('p'.repeat(2 - number_of_drivers))
            : []
        : number_of_drivers < 4
        ? Array.from('p'.repeat(4 - number_of_drivers))
        : []

    const [showAddBox, setshowAddBox] = useState(false)
    const [activeIndex, setActiveIndex] = useState(-1)

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    const { theme } = useThemes()

    return (
        <GridWrapper>
            <MainPolicyHolderBox
                $customHoverColour={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_04
                }
                to={`/insurance/application/${application?.id}/main_driver/${main_policy_holder.id}`}
            >
                <Name>{`${main_policy_holder.basic_details?.given_name ?? ''} ${
                    main_policy_holder.basic_details?.family_name ?? ''
                }`}</Name>

                <Text>Policy holder</Text>
            </MainPolicyHolderBox>
            {selected_named_drivers &&
                selected_named_drivers.map((driver_key, i) => {
                    let driver = other_drivers[driver_key]
                    return (
                        <OtherBox
                            $customHoverColour={
                                insurance_colours[theme].sections['drivers']
                                    .primary_04
                            }
                            to={`/insurance/application/${application?.id}/other_driver/${driver_key}`}
                            key={`${driver.id}_${i}_other_box_drivers_${
                                isMobile ? 'mobile' : 'desktop'
                            }`}
                        >
                            <FlexRow>
                                <NameRow>
                                    <Name>
                                        {`${
                                            driver.basic_details?.given_name ??
                                            ''
                                        } ${
                                            driver.basic_details?.family_name ??
                                            ''
                                        }`}
                                    </Name>
                                    {driver &&
                                        driver.drive_list_errors &&
                                        driver.drive_list_errors?.message && (
                                            <WarningIcon src={warning_red} />
                                        )}
                                </NameRow>
                                <Text>
                                    {driver?.completion
                                        ? `${driver.completion}%`
                                        : '0%'}
                                </Text>
                            </FlexRow>
                            {driver.drive_list &&
                            driver.drive_list.length > 0 ? (
                                <Text>
                                    Access to {driver.drive_list?.length} cars
                                </Text>
                            ) : (
                                <Text>No cars assigned yet</Text>
                                // <StyledLink
                                //     to={`/insurance/application/other_driver/${driver_key}`}
                                // >
                                //     No cars assigned yet
                                // </StyledLink>
                            )}
                        </OtherBox>
                    )
                })}
            {application?.status === 'DRAFT' &&
                number_of_drivers < 4 &&
                array_of_placeholders.map((nb, i) => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            opacity: i === 0 ? 1 : 1 / (i / 1.5 + 0.5),
                        }}
                        key={`empty_driver_card_${i}_${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                    >
                        <EmptyBox
                            onClick={() => {
                                history.push(
                                    `/insurance/application/${application?.id}/drivers?create=true`
                                )
                            }}
                            style={
                                showAddBox && activeIndex === i
                                    ? { padding: 0 }
                                    : {}
                            }
                            onMouseEnter={() => {
                                if (!isMobile) {
                                    setshowAddBox(true)
                                    setActiveIndex(i)
                                }
                            }}
                            onMouseLeave={() => {
                                if (!isMobile) {
                                    setshowAddBox(false)
                                    setActiveIndex(-1)
                                }
                            }}
                        >
                            {showAddBox && activeIndex === i && (
                                <div
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <AddBox
                                        variant="underlay"
                                        customActiveColour={
                                            insurance_colours[theme].sections[
                                                'drivers'
                                            ].primary_100
                                        }
                                        customUnderlayColour={
                                            insurance_colours[theme].sections[
                                                'drivers'
                                            ].primary_04
                                        }
                                        onAddClick={() =>
                                            history.push(
                                                `/insurance/application/${application?.id}/drivers?create=true`
                                            )
                                        }
                                    />
                                </div>
                            )}
                            {!(showAddBox && activeIndex === i) && (
                                <>
                                    <EmptyLine style={{ width: '80%' }} />
                                    <EmptyLine style={{ width: '50%' }} />
                                </>
                            )}
                        </EmptyBox>
                    </div>
                ))}
        </GridWrapper>
    )
}

export default InsurancePolicyDriversCardContent
