import styled from 'styled-components'
import React, { useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import grey_tip from '../../../../public/assets/icons/grey_tip.svg'
import TutorialWrapperMobile from '../tutorialWrappers/TutorialWrapperMobile'

const TourStyled = styled((props: any) => <Tour {...props} />)`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: ${(props) => (props.currentStep === 2 ? '90vw' : 'auto')};

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 16px;
    justify-content: center;
    text-align: center;

    @media ${device.mobile} {
    }
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 14px;
    line-height: 1.9;

    @media ${device.mobile} {
    }
`

const Padding = styled.div`
    padding-top: 20px;
`
const TextPadding = styled.div`
    padding-top: 8px;
`

const Tip = styled.img`
    width: 20px;
    @media ${device.mobile} {
    }
`

const TipText = styled.div`
    font-family: Lato-Light;
    padding-left: 20px;
    font-size: 14px;

    @media ${device.mobile} {
    }
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
`

export type ReactourStepPosition =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | [number, number]

let bottomPositionStrongType: ReactourStepPosition = 'bottom'

const tutorialConfigs_Car = (close: any) => [
    {
        selector: '[data-tut="tasks-tuto-mobile-1"]',
        content: ({ goTo }: any) => (
            //<Wrapper>
            <TutorialWrapperMobile
                skip={close}
                next={() => {
                    goTo(1)
                }}
            >
                <Title>Tasks, Simplified.</Title>

                <Padding />

                <Text>
                    Stay organised by keeping track of your car related tasks
                    all in one place.
                </Text>

                <Text>
                    You can set due dates and once tasks are completed, they are
                    saved so that you can keep track of what has been done to
                    your car(s) over time.
                </Text>

                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>
                        Tasks can be applied to multiple vehicles.
                    </TipText>
                </TipRow>
            </TutorialWrapperMobile>
        ),
        position: bottomPositionStrongType,
    },
    {
        selector: '[data-tut="tasks-tuto-mobile-2"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperMobile
                skip={close}
                prev={() => {
                    goTo(0)
                }}
                next={close}
                nextBtnText="close"
            >
                <Title>Sort & Filter</Title>

                <Padding />

                <Text>
                    You can filter and sort tasks by car, date created or due
                    date, and by outstanding or completed.
                </Text>

                <TextPadding />

                <Text>
                    By default, tasks are shown that are outstanding and sorted
                    by their date created.
                </Text>
            </TutorialWrapperMobile>
        ),
        position: bottomPositionStrongType,
    },
]

interface Props extends RouteComponentProps<any> {}

const TutorialConfigsTasksMobile: React.FunctionComponent<Props> = (props) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(true)

    const setCurrentStepCustom = (step: number) => {
        setCurrentStep(step)
    }

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        props.history.push(`/tasks`)
    }

    return (
        <>
            {window.innerWidth < 880 && (
                <>
                    <TourStyled
                        steps={tutorialConfigs_Car(setIsTourOpenCustom)}
                        isOpen={isTourOpen}
                        onRequestClose={() => undefined}
                        rounded={5}
                        accentColor="#5ec3ca"
                        getCurrentStep={(curr: number) => {
                            if (curr === 1) {
                                window.scrollTo(0, 200)
                            } else {
                                window.scrollTo(0, 0)
                            }
                            return setCurrentStepCustom(curr + 1)
                        }}
                        currentStep={currentStep}
                        disableInteraction={true}
                        showCloseButton={false}
                    />
                </>
            )}
        </>
    )
}

export default withRouter(TutorialConfigsTasksMobile)
