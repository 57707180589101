import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    dataCyId?: string
    size?: string
    color?: string
}

const FilterIcon = (props: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? '16'}
            height={props.size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 3.75C11.3096 3.75 10.75 4.30964 10.75 5C10.75 5.69036 11.3096 6.25 12 6.25C12.6904 6.25 13.25 5.69036 13.25 5C13.25 4.30964 12.6904 3.75 12 3.75ZM9.25 5C9.25 3.48122 10.4812 2.25 12 2.25C13.5188 2.25 14.75 3.48122 14.75 5C14.75 6.51878 13.5188 7.75 12 7.75C10.4812 7.75 9.25 6.51878 9.25 5Z"
                fill={props.color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 9.75C3.30964 9.75 2.75 10.3096 2.75 11C2.75 11.6904 3.30964 12.25 4 12.25C4.69036 12.25 5.25 11.6904 5.25 11C5.25 10.3096 4.69036 9.75 4 9.75ZM1.25 11C1.25 9.48122 2.48122 8.25 4 8.25C5.51878 8.25 6.75 9.48122 6.75 11C6.75 12.5188 5.51878 13.75 4 13.75C2.48122 13.75 1.25 12.5188 1.25 11Z"
                fill={props.color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 4.25H10V5.75H1V4.25Z"
                fill={props.color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 10.25L15 10.25L15 11.75L6 11.75L6 10.25Z"
                fill={props.color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default FilterIcon
