import styled from 'styled-components'
import { device } from '../../displays/devices'
import CrossIcon from '../../../atoms/icons/crossIcon'
import useThemes from '../../../../providers/theme/hooks'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import { motion } from 'framer-motion'
import NavHamburgerIconV2 from '../../../atoms/icons/components/navHamburgerV2'
import { useState } from 'react'
import JourneySideMenu from '../../../atoms/menu/journey/sideMenu'
import { IJourneySectionID } from 'journeyInterfaces'
import { Link, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/store/hooks'

type IStyle = {
    $theme: ITheme
}

const Container = styled.div<IStyle>`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 42px;
    z-index: 100;
    background-color: ${(props) =>
        `${colours[props.$theme].background_default}`};
    border-bottom: ${(props) =>
        `1px solid ${colours[props.$theme].border_muted}`};
    transition: all 100ms;

    @media ${device.mobile_and_ipad} {
        height: 45px;
        background-color: transparent;
        border: none;
        backdrop-filter: blur(2px);
    }
`

const SubContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
`

const BtnWrapper = styled(motion.button)`
    outline: none;
    border: none;
    display: flex;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: all 150ms;
    background-color: transparent;

    @media ${device.mobile_and_ipad} {
    }
`

const MenuWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
`

type Props = {
    closeUrl: string
}

function getJourneySectionId(pathname: string): IJourneySectionID {
    let pathname_array = pathname.replace(/^\/+|\/+$/g, '').split('/')
    // console.log('pathname_array', pathname_array)
    if (pathname_array[1] === 'car-profile') {
        return 'car_profile'
    }
    if (pathname_array[1] === 'your-profile') {
        return 'your_profile'
    }
    if (pathname_array[1] === 'history-file') {
        return 'history_file'
    }
    if (pathname_array[1] === 'insurance') {
        return 'insurance'
    }
    if (pathname_array[1] === 'previously-owned') {
        return 'past_cars'
    }
    if (pathname_array[1] === 'sell-your-car') {
        return 'sell_your_car'
    }
    if (pathname_array[1] === 'share-your-car') {
        return 'share_your_car'
    }
    if (pathname_array[1] === 'handover') {
        return 'handover'
    }

    return 'car_profile'
}

const JourneyTopBar = (props: Props) => {
    const isAddACarFormOpened = useAppSelector((state) => {
        return state.editForms.formsData.addCarForm.isOpen
    })

    let { theme } = useThemes()
    const [showSideMenu, setShowSideMenu] = useState(false)

    let params = new URLSearchParams(location.search)
    let step: string | null = params.get('step')

    const { pathname } = useLocation()

    let sectionID = getJourneySectionId(pathname)

    const showTopMenu = isAddACarFormOpened
        ? false
        : pathname.match(/journey/g) !== null
        ? true
        : false

    let carid: string | null = params.get('carid')
    // let { closeUrl } = props

    const getUrlBack = () => {
        if (pathname.match(/history-file/g) !== null) {
            if (carid) {
                return `/car/${carid}/history-file`
            }
            return `/history-files`
        }
        if (pathname.match(/share/g) !== null) {
            return `/sharing/with-you`
        }
        if (pathname.match(/previously-owned/g) !== null) {
            return `/garage/previously-owned`
        }
        if (carid) {
            return `/car/${carid}`
        }
        if (pathname.match(/insurance/g) !== null) {
            return `/insurance`
        }
        return '/garage'
    }

    return showTopMenu ? (
        <>
            <Container
                $theme={theme}
                style={
                    step === 'welcome' ? { backgroundColor: 'transparent' } : {}
                }
            >
                <SubContainer>
                    <BtnWrapper
                        onClick={() => setShowSideMenu(true)}
                        whileTap={{ scale: 1.2 }}
                        data-ph={'click_journey_menu'}
                    >
                        <MenuWrapper
                            style={{
                                background: `linear-gradient(135deg, ${journey_colours[theme].section[sectionID].primary_400} 0%, ${journey_colours[theme].section[sectionID].primary_50} 100%)`,
                            }}
                        >
                            <NavHamburgerIconV2
                                color={
                                    journey_colours[theme].section[sectionID]
                                        .primary_900
                                }
                            />
                        </MenuWrapper>
                    </BtnWrapper>

                    <Link to={getUrlBack()}>
                        <BtnWrapper
                            onClick={() => {}}
                            whileTap={{ scale: 1.5 }}
                        >
                            <CrossIcon
                                size="16"
                                color={colours[theme].text_darker}
                            />
                        </BtnWrapper>
                    </Link>
                </SubContainer>
            </Container>
            <JourneySideMenu
                isOpen={showSideMenu}
                onClose={() => setShowSideMenu(false)}
                sectionID={sectionID}
            />
        </>
    ) : (
        <div style={{ display: 'none' }} />
    )
}

export default JourneyTopBar
