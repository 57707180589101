import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../../providers/theme/colours'
import image from '../../../../../public/assets/images/journey/profile/1_desktop.png'
import grey_placeholder from '../../../../../public/assets/placeholder/grey_placeholder.svg'
import image_mob from '../../../../../public/assets/images/journey/profile/1_mobile.png'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeButtonsWrapper,
    JourneyWelcomeNewLineOnMobile,
    JourneyWelcomeSubWrapperV2,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapperV2,
} from '../../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import FadedSlower from '../../../../templates/animated/FadedSlower'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    start: () => void
    cancel: (carid: string) => void
}

const JourneyWelcomeImageWrapper = styled.div`
    display: flex;
    justify-content: center;

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        height: 100%;
    }
    @media ${device.mobile} {
        width: 100dvw;
        max-height: 46dvh;
    }
`

const JourneyWelcomeImage = styled.img`
    display: flex;
    width: auto;
    height: 45dvh;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;
    transform: translateX(-30px);
`

const CustomImageMobile = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;

    height: auto;
    width: 1000px;

    max-height: 50dvh;

    transform: translateX(-10px);

    @media (max-width: 500px) {
        max-height: 46dvh;
    }

    @media (max-width: 380px) {
        max-height: 35dvh;
    }
`

export default function JourneyUserProfileWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()
    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapperV2>
                        <JourneyWelcomeSubWrapperV2>
                            <JourneyWelcomeTextWrapper>
                                <JourneyWelcomeTitle>
                                    Look your best and complete your profile
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    As we all know, the ownership history of a
                                    car is an important factor. Get started
                                    building out your profile to both customise
                                    your Custodian experience and leave your
                                    mark in automotive history.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>

                            <div
                                style={{
                                    paddingTop: '30px',
                                }}
                            />

                            <JourneyWelcomeImageWrapper>
                                <FadedSlower isFullWidth={true}>
                                    <JourneyWelcomeImage
                                        src={image}
                                        placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapper>

                            <div
                                style={{
                                    paddingTop: '50px',
                                }}
                            />

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn
                                    onClick={start}
                                    bg={
                                        journey_colours[theme].section
                                            .your_profile.primary_600
                                    }
                                >
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: journey_colours[theme]
                                                .section.your_profile
                                                .primary_600,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapperV2>
                    </JourneyWelcomeWrapperV2>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapperV2>
                    <JourneyWelcomeSubWrapperV2>
                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '28px',
                                maxWidth: '90vw',
                                lineHeight: '38px',
                            }}
                        >
                            Look your best and <JourneyWelcomeNewLineOnMobile />
                            <span
                                style={{
                                    color: journey_colours[theme].section
                                        .your_profile.primary_900,
                                    fontSize: '28px',
                                }}
                            >
                                complete your profile
                            </span>
                        </JourneyWelcomeTitle>

                        <div
                            style={{
                                paddingTop: '2dvh',
                            }}
                        />

                        <JourneyWelcomeImageWrapper>
                            <Faded width={'auto'}>
                                <CustomImageMobile
                                    src={image_mob}
                                    placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapper>

                        <div
                            style={{
                                paddingTop: '4dvh',
                            }}
                        />

                        <JourneyWelcomeText
                            style={{
                                maxWidth: '90vw',
                            }}
                        >
                            As we all know, the ownership history of a car is an
                            important factor. Get started building out your
                            profile to both customise your Custodian experience
                            and leave your mark in automotive history.
                        </JourneyWelcomeText>

                        <div
                            style={{
                                paddingTop: '24px',
                            }}
                        />

                        <StandardCtaBtn
                            onClick={start}
                            customWidth="90vw"
                            bg={
                                journey_colours[theme].section.your_profile
                                    .primary_600
                            }
                        >
                            Let's get started
                        </StandardCtaBtn>

                        <div
                            style={{
                                paddingTop: '36px',
                            }}
                        />
                    </JourneyWelcomeSubWrapperV2>
                </JourneyWelcomeWrapperV2>
            </IpadAndMobileDisplay>
        </>
    )
}
