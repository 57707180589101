import { onboarding_content } from './data'
import { IOnboardingContentReducer } from 'myInterfaces'

const initialState = onboarding_content

export const onboardingContent = (
    state: IOnboardingContentReducer = initialState
) => state

export default onboardingContent

export type OnboardingContentState = ReturnType<typeof onboardingContent>
