import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const DriveSideIcon = ({ size, strokeColor, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30.6665 16C30.6665 23.732 24.3985 30 16.6665 30C8.93452 30 2.6665 23.732 2.6665 16C2.6665 8.26801 8.93452 2 16.6665 2C24.3985 2 30.6665 8.26801 30.6665 16ZM15.1541 25.3724C14.8099 23.1447 14.2467 21.034 13.585 19.0815C11.6325 18.4198 9.52178 17.8566 7.29414 17.5124C7.93956 21.5434 11.1231 24.7269 15.1541 25.3724ZM7.29414 14.4876C8.01842 9.96404 11.9388 6.50781 16.6665 6.50781C21.3942 6.50781 25.3146 9.96404 26.0389 14.4876C23.3961 14.0793 20.918 13.3626 18.6734 12.5391C18.0835 12.1963 17.3979 12 16.6665 12C15.9351 12 15.2495 12.1963 14.6596 12.5391C12.415 13.3626 9.93686 14.0793 7.29414 14.4876ZM26.0389 17.5124C23.8112 17.8566 21.7005 18.4198 19.748 19.0815C19.0863 21.034 18.5231 23.1447 18.1789 25.3724C22.21 24.7269 25.3934 21.5435 26.0389 17.5124ZM16.6665 14C17.7711 14 18.6665 14.8954 18.6665 16C18.6665 17.1046 17.7711 18 16.6665 18C15.5619 18 14.6665 17.1046 14.6665 16C14.6665 14.8954 15.5619 14 16.6665 14ZM11.6665 17C12.2188 17 12.6665 16.5523 12.6665 16C12.6665 15.4477 12.2188 15 11.6665 15C11.1142 15 10.6665 15.4477 10.6665 16C10.6665 16.5523 11.1142 17 11.6665 17ZM16.6665 20C17.2188 20 17.6665 20.4477 17.6665 21C17.6665 21.5523 17.2188 22 16.6665 22C16.1142 22 15.6665 21.5523 15.6665 21C15.6665 20.4477 16.1142 20 16.6665 20ZM21.6665 15C21.1142 15 20.6665 15.4477 20.6665 16C20.6665 16.5523 21.1142 17 21.6665 17C22.2188 17 22.6665 16.5523 22.6665 16C22.6665 15.4477 22.2188 15 21.6665 15ZM9.1665 16C9.1665 16.4142 8.83072 16.75 8.4165 16.75C8.00229 16.75 7.6665 16.4142 7.6665 16C7.6665 15.5858 8.00229 15.25 8.4165 15.25C8.83072 15.25 9.1665 15.5858 9.1665 16ZM17.4165 24.25C17.4165 23.8358 17.0807 23.5 16.6665 23.5C16.2523 23.5 15.9165 23.8358 15.9165 24.25C15.9165 24.6642 16.2523 25 16.6665 25C17.0807 25 17.4165 24.6642 17.4165 24.25ZM24.1665 16C24.1665 15.5858 24.5023 15.25 24.9165 15.25C25.3307 15.25 25.6665 15.5858 25.6665 16C25.6665 16.4142 25.3307 16.75 24.9165 16.75C24.5023 16.75 24.1665 16.4142 24.1665 16Z"
                fill={color ?? colours[theme].icons_neutral_250}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.6665 28.5C23.5701 28.5 29.1665 22.9036 29.1665 16C29.1665 9.09644 23.5701 3.5 16.6665 3.5C9.76294 3.5 4.1665 9.09644 4.1665 16C4.1665 22.9036 9.76294 28.5 16.6665 28.5ZM16.6665 30C24.3985 30 30.6665 23.732 30.6665 16C30.6665 8.26801 24.3985 2 16.6665 2C8.93452 2 2.6665 8.26801 2.6665 16C2.6665 23.732 8.93452 30 16.6665 30Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
        </svg>
    )
}
export default DriveSideIcon
