import gallery_sagas from './updateCarGalleryImage'
import delete_sagas from './deleteCarGalleryImage'
import delete_entry_gallery_image_sagas from './deleteEntryGalleryImage'
import update_entry_gallery_image_sagas from './updateEntryGalleryImage'

let arr: any[] = [
    ...gallery_sagas,
    ...delete_sagas,
    ...update_entry_gallery_image_sagas,
    ...delete_entry_gallery_image_sagas,
]

export default arr
