import {
    IApexSingleRichBodyState,
    IApexSingleInterviewNodeItemFromQueryAPI,
    IHybridPrismicPropsToState,
    IApexSingleArticleItemState,
    IApexSingleArticlePayloadFromQueryAPI,
} from 'apexModels'
import {
    convertIApexBodySliceFromAPItoStateSingle,
    convertIArrayHybridPrismicPropsToIHybridPrismicPropsToState,
} from './apexRichBodyReusableConversions'

export const convertPrismicApexSingleArticleFromAPIToState = (
    apiData: IApexSingleArticlePayloadFromQueryAPI
): IApexSingleArticleItemState | undefined => {
    if (apiData.allApex_articless.edges && apiData.allApex_articless.edges[0]) {
        let singleArticleNodeAPI: IApexSingleInterviewNodeItemFromQueryAPI =
            apiData.allApex_articless.edges[0].node

        let singleObject: IApexSingleArticleItemState = {
            // note: only mandatory here. Remember to fill in the non mandatory
            uid:
                singleArticleNodeAPI._meta && singleArticleNodeAPI._meta.uid
                    ? singleArticleNodeAPI._meta.uid
                    : '',
            introduction: [],
            date: singleArticleNodeAPI.date && singleArticleNodeAPI.date,
            title:
                singleArticleNodeAPI.title &&
                singleArticleNodeAPI.title[0] &&
                singleArticleNodeAPI.title[0].text
                    ? singleArticleNodeAPI.title[0].text
                    : '',
            apex_team_credits:
                singleArticleNodeAPI.apex_team_credits &&
                singleArticleNodeAPI.apex_team_credits[0] &&
                singleArticleNodeAPI.apex_team_credits[0].text
                    ? singleArticleNodeAPI.apex_team_credits[0].text
                    : '',
            body: [],
        }

        // intro hybrid props convo
        if (singleArticleNodeAPI.introduction) {
            let converted_intro: IHybridPrismicPropsToState[] = convertIArrayHybridPrismicPropsToIHybridPrismicPropsToState(
                singleArticleNodeAPI.introduction
            )

            singleObject.introduction = converted_intro
        }

        // body slices convo

        if (singleArticleNodeAPI.body) {
            let converted_body_slices_array: IApexSingleRichBodyState[] = []
            for (const slice of singleArticleNodeAPI.body) {
                let converted_slide = convertIApexBodySliceFromAPItoStateSingle(
                    slice
                )
                converted_body_slices_array.push(converted_slide)
            }
            singleObject.body = converted_body_slices_array
        }

        return singleObject
    } else return undefined
}
