import * as React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import * as unitGenerator from '../../../helpers/units/unitConversion'
import { carOverviewGridlistItems } from './OverviewGridManagerDesktop'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    flex: 1;

    position: relative;
`

const ListItem = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 45%;
    height: 80px;
    border: 1px solid ${(props) => colours[props.$theme].border_muted};
    border-radius: 2px;
    flex-grow: 1;
`

const ListItemContentTitle = styled.span<IStyle>`
    font-family: Lato-bold;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: ${(props) => colours[props.$theme].text_muted};
    padding-bottom: 4px;
    text-align: center;
`

const ListItemContentSpec = styled.span<IStyle>`
    font-family: Lato;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 5e-5em;
    color: ${(props) => colours[props.$theme].text_default};
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @media (max-width: 350px) {
        font-size: 13px;
    }
`

const ListItemAction = styled.button<IStyle>`
    all: unset;
    color: ${(props) => colours[props.$theme].primary};
    text-transform: capitalize;
`

interface Props {
    ids: string[]
    isExternalCar?: boolean
    carid: string
    data: any
    fuel_unit_text?: string
    fuel_unit?: string
    addKeyTechInfo?: () => void
    readonlyMode?: boolean
}

const CarOverviewGridManagerMobile: React.SFC<Props> = (props) => {
    let { ids, addKeyTechInfo, readonlyMode } = props
    const history = useHistory()
    let linkPrefix =
        history.location.pathname &&
        history.location.pathname.includes('overview')
            ? history.location.pathname.replace('/overview', '')
            : history.location.pathname

    const { theme } = useThemes()
    return (
        <Container>
            {ids.map((id: string, index: number) => {
                let obj =
                    id === 'engine_capacity' &&
                    props.fuel_unit &&
                    props.data[id].answer !== '-' &&
                    props.fuel_unit !== 'cubic_centimeters'
                        ? props.fuel_unit === 'liters'
                            ? {
                                  ...props.data[id],
                                  answer: unitGenerator
                                      .generateFrontEndValueFuelUnit(
                                          props.fuel_unit,
                                          props.data[id].answer
                                      )
                                      .toFixed(1),
                              }
                            : {
                                  ...props.data[id],
                                  answer: Math.round(
                                      unitGenerator.generateFrontEndValueFuelUnit(
                                          props.fuel_unit,
                                          props.data[id].answer
                                      )
                                  ),
                              }
                        : props.data[id]

                let unitText =
                    id === 'engine_capacity' &&
                    props.fuel_unit_text &&
                    props.data[id].answer !== '-'
                        ? props.fuel_unit_text
                        : ''

                return (
                    <React.Fragment
                        key={`${id}_${index}_car_overview_grid_mobile`}
                    >
                        <ListItem
                            $theme={theme}
                            onClick={
                                readonlyMode
                                    ? () =>
                                          history.push(
                                              `${linkPrefix}/technical-information`
                                          )
                                    : addKeyTechInfo && addKeyTechInfo
                            }
                        >
                            <ListItemContentTitle $theme={theme}>
                                {carOverviewGridlistItems[id].icon}
                            </ListItemContentTitle>

                            <ListItemContentSpec $theme={theme}>
                                {(obj.id === 'year' && !obj.answer) ||
                                obj.answer === undefined ||
                                obj.answer === null ||
                                obj.answer === '-' ||
                                obj.answer === '' ||
                                obj.answer === 'Unknown' ? (
                                    readonlyMode ? (
                                        '-'
                                    ) : (
                                        <ListItemAction $theme={theme}>
                                            {
                                                carOverviewGridlistItems[id]
                                                    .action_text
                                            }
                                        </ListItemAction>
                                    )
                                ) : (
                                    `${obj.answer}
                            ${unitText && unitText}`
                                )}
                            </ListItemContentSpec>
                        </ListItem>
                    </React.Fragment>
                )
            })}
        </Container>
    )
}

export default CarOverviewGridManagerMobile
