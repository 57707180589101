import {
    SIGNIN_USER_ERROR,
    REGISTER_USER_ERROR,
    LOGOUT_USER_ERROR,
    ADD_PHONE_ERROR,
    ADD_ADDRESS_ERROR,
    ADD_CATEGORIES__ERROR,
    VERIFY_ERROR,
    SEND_INVITATION_ERROR,
    RESET_PASSWORD_ERROR,
    RESET_PASSWORD_VERIFIED_ERROR,
    GET_CURRENT_USER_DATA_ERROR,
    RESET_ERROR_STATE,
    VERIFY_RESET_PASSWORD_CODE_ERROR,
    UPDATE_USER_DATA_GENERAL_INFO_ERROR,
    REFRESH_TOKEN_ERROR,
    REFRESH_TOKEN_ONCE_ERROR,
    GET_USER_DIRECT_SHARES_RECEIVED_ERROR,
    POPULATE_OWNED_SHARES_DATA_ERROR,
    UPDATE_SHARE_RECEIVED_STATUS_ERROR,
    DELETE_ACCOUNT_ERROR,
    GET_USER_ARCHIVED_CARS_ERROR,
    LIST_USER_HANDOVERS_ERROR,
    ADD_ADDRESS_ERROR_NO_MODAL,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const verifyError = createAction(
//     VERIFY_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const verifyError = createAction(
    VERIFY_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const registerError = createAction(
//     REGISTER_USER_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const registerError = createAction(
    REGISTER_USER_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const signInUserError = createAction(
//     SIGNIN_USER_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const signInUserError = createAction(
    SIGNIN_USER_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const logoutUserError = createAction(
//     LOGOUT_USER_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const logoutUserError = createAction(
    LOGOUT_USER_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const addPhoneError = createAction(
//     ADD_PHONE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const addPhoneError = createAction(
    ADD_PHONE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const addAddressError = createAction(
//     ADD_ADDRESS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const addAddressError = createAction(
    ADD_ADDRESS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addAddressErrorNoModal = createAction(
    ADD_ADDRESS_ERROR_NO_MODAL,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const addAcategoriesError = createAction(
//     ADD_CATEGORIES__ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const addAcategoriesError = createAction(
    ADD_CATEGORIES__ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const sendInvitationError = createAction(
//     SEND_INVITATION_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const sendInvitationError = createAction(
    SEND_INVITATION_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const resetPasswordError = createAction(
//     RESET_PASSWORD_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const resetPasswordError = createAction(
    RESET_PASSWORD_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const verifiedResetPasswordError = createAction(
//     RESET_PASSWORD_VERIFIED_ERROR,

//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const verifiedResetPasswordError = createAction(
    RESET_PASSWORD_VERIFIED_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getUserDataError = createAction(
//     GET_USER_DATA_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getCurrentUserDataError = createAction(
    GET_CURRENT_USER_DATA_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const verifyResetPasswordCodeError = createAction(
//     VERIFY_RESET_PASSWORD_CODE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const verifyResetPasswordCodeError = createAction(
    VERIFY_RESET_PASSWORD_CODE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const resetErrorStateSuccess = createAction(RESET_ERROR_STATE)

// export const updateUserGeneralInfoError = createAction(
//     UPDATE_USER_DATA_GENERAL_INFO_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const updateUserGeneralInfoError = createAction(
    UPDATE_USER_DATA_GENERAL_INFO_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const refreshTokenError = createAction(
    REFRESH_TOKEN_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const refreshTokenOnceError = createAction(
    REFRESH_TOKEN_ONCE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getUserDirectSharesReceivedError = createAction(
    GET_USER_DIRECT_SHARES_RECEIVED_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const populateOwnedSharesDataError = createAction(
    POPULATE_OWNED_SHARES_DATA_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const updateShareReceivedStatusError = createAction(
    UPDATE_SHARE_RECEIVED_STATUS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteAccountError = createAction(
    DELETE_ACCOUNT_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
export const getUserArchivedCarsError = createAction(
    GET_USER_ARCHIVED_CARS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const listUserHandoversError = createAction(
    LIST_USER_HANDOVERS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
