import * as React from 'react'
import styled from 'styled-components'
import { device } from '../displays/devices'

interface FormErrorProps {
    children: React.ReactNode
    customTopSpacing?: number
}

const FormContainer = styled.section`
    font-family: Lato;
    color: var(--error, #df6f6f);
    font-style: italic;
    padding-left: 12px;

    @media ${device.mobile} {
        font-size: 12px;
        margin-top: 12px;
        max-width: 90vw;
    }
    @media ${device.desktop} {
        font-size: 12px;
        margin-top: 15px;
        max-width: 70vw;
    }

    @media ${device.ipad} {
        font-size: 16px;
        margin-top: 20px;
        max-width: 70vw;
    }
`

const StyledFormError: React.FunctionComponent<FormErrorProps> = (props) => (
    <FormContainer
        style={
            props.customTopSpacing ? { marginTop: props.customTopSpacing } : {}
        }
    >
        {props.children}
    </FormContainer>
)

export default StyledFormError
