import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import SettingsIcon from '../historyFile/service'
import AccountIcon from './account'
import ContactIcon from './contact'
import FaqIcon from './faq'
import LogoutIcon from './logout'
import TutorialsIcon from './tutorials'

const AccountIcons = {
    AccountIcon,
    FaqIcon,
    ContactIcon,
    TutorialsIcon,
    LogoutIcon,
    SettingsIcon,
}

export const renderAccountIcons = (id: string, color?: string) => {
    const { theme } = useThemes()
    switch (id) {
        case 'account':
            return <AccountIcon color={color ?? colours[theme].text_darker} />
        case 'settings':
            return <SettingsIcon color={color ?? colours[theme].text_darker} />
        case 'faq':
            return <FaqIcon color={color ?? colours[theme].text_darker} />
        case 'contact':
            return <ContactIcon color={color ?? colours[theme].text_darker} />
        case 'tutorials':
            return <TutorialsIcon color={color ?? colours[theme].text_darker} />
        case 'logout':
            return <LogoutIcon color={color ?? colours[theme].text_darker} />
        default:
            return <SettingsIcon color={colours[theme].text_darker} />
    }
}

export default AccountIcons
