import * as React from 'react'
import 'antd/dist/antd.min.css'
import { Select, Spin } from 'antd'
import './antd.css'
import styled from 'styled-components'
import InputField from '../../atoms/Inputfield/inputField'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { device } from '../../templates/displays/devices'
import DesktopDisplay from '../../templates/displays/desktopDisplay'
import MobileDisplay from '../../templates/displays/mobileDisplay'
import useThemes from '../../../providers/theme/hooks'
const { Option } = Select

const PhoneLabel = styled.div`
    font-family: Lato-bold;
    @media ${device.desktop} {
        margin-bottom: 20px;
        font-size: 13px;
        color: black;
        margin-left: 5px;
        letter-spacing: 1px;
    }

    @media ${device.mobile} {
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 800;
        color: black;
    }
`

const PhoneLabelWithPadding = styled.div`
    font-family: Lato-bold;

    @media ${device.desktop} {
        margin-top: 70px;
        margin-bottom: 20px;
        font-size: 13px;
        color: black;
        margin-left: 5px;
        letter-spacing: 1px;
    }

    @media ${device.mobile} {
        margin-top: 50px;
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 800;
        color: black;
    }
`

const PhoneInputContainer = styled.div`
    @media ${device.desktop} {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
    }

    @media ${device.mobile} {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
    }
`

const PhoneValidationColumn = styled.div`
    font-family: Lato;
    @media ${device.desktop} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 60px;
        margin-top: 30px;
        font-size: 11px;
    }

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 50px;
        margin-top: 30px;
        font-size: 11px;
    }
`

const Padding = styled.div`
    @media ${device.desktop} {
        margin-left: 5px;
        margin-top: 100px;
    }
    @media ${device.mobile} {
        margin-top: 50px;
    }
`

const ContainerPadding = styled.div`
    @media ${device.desktop} {
        margin-left: 10vw;
        padding-top: 30px;
    }

    @media ${device.mobile} {
        margin-left: 8vw;
        padding-top: 20px;
        padding-right: 8vw;
    }
`

const SelectStyled = styled(Select)`
    font-family: Lato;

    @media ${device.desktop} {
        font-size: 14px !important;
        min-width: 500px;
    }
    @media ${device.mobile} {
        font-size: 13px !important;
        width: 80vw;
    }
`

interface ValidityProps {
    isvalid: boolean | undefined
}

const PhoneDisplay = styled.span<ValidityProps>`
    color: ${(props) => (props.isvalid === true ? 'green' : 'red')};
`

export type Props = {
    fetching: any
    value: any
    data: any
    fetchCountry: any
    handleChange: any
    displayInternational: any
    getCode: any
    phone: any
    isvalid: any
    savePhoneToUser: any
    handleChangeNumber: any
    changeFlag: any
    countrieslist: any
}

class PhoneInputContent extends React.Component<Props> {
    componentDidMount() {
        document.getElementsByTagName('div')[0].focus()
    }

    render() {
        const {
            fetching,
            value,
            data,
            fetchCountry,
            handleChange,
            displayInternational,
            getCode,
            phone,
            isvalid,
            savePhoneToUser,
            handleChangeNumber,
            changeFlag,
            countrieslist,
        } = this.props

        const mappedData = data.map((d: any) => (
            <Option value={d.name} key={d.name} aria-label="UK">
                <span style={{ marginLeft: '10px' }}>{d.name} </span>
            </Option>
        ))

        const mappedList = countrieslist.map((d: any) => (
            <Option value={d.name} key={d.name} aria-label="UK">
                <span style={{ marginLeft: '10px' }}>{d.name} </span>
            </Option>
        ))

        const { theme } = useThemes()

        return (
            <ContainerPadding>
                <PhoneLabel>COUNTRY</PhoneLabel>

                <SelectStyled
                    showSearch
                    value={value}
                    placeholder="Search a country"
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={fetchCountry}
                    onChange={handleChange}
                    autoClearSearchValue={true}
                >
                    {data.length > 0 ? mappedData : mappedList}
                </SelectStyled>

                <PhoneLabelWithPadding>NUMBER</PhoneLabelWithPadding>
                <PhoneInputContainer>
                    <div
                        role="img"
                        className={`flag-icon flag-icon-${
                            changeFlag() ? `${changeFlag()}` : 'gb'
                        }`}
                        aria-label={changeFlag()}
                        style={{ marginRight: '20px' }}
                    />
                    <div tabIndex={1}>
                        <InputField
                            theme={theme}
                            type="tel"
                            name="phone"
                            placeholder="phone number"
                            value={phone}
                            onChange={handleChangeNumber}
                            isnarrow={true}
                        />
                    </div>
                    <DesktopDisplay>
                        {phone.length !== 0 && (
                            <PhoneValidationColumn>
                                INTERNATIONAL :
                                <PhoneDisplay isvalid={isvalid}>
                                    {displayInternational(phone, getCode())}
                                </PhoneDisplay>
                            </PhoneValidationColumn>
                        )}
                    </DesktopDisplay>
                </PhoneInputContainer>

                <MobileDisplay>
                    {phone.length !== 0 && (
                        <PhoneValidationColumn>
                            INTERNATIONAL :
                            <PhoneDisplay isvalid={isvalid}>
                                {displayInternational(phone, getCode())}
                            </PhoneDisplay>
                        </PhoneValidationColumn>
                    )}
                </MobileDisplay>

                {isvalid && (
                    <Padding className="animated fadeIn">
                        <ButtonAtom
                            type="submit"
                            theme="secondary"
                            onClick={(e: { preventDefault: () => void }) => {
                                e.preventDefault()
                                savePhoneToUser()
                            }}
                        >
                            <div> SAVE</div>
                        </ButtonAtom>
                    </Padding>
                )}
            </ContainerPadding>
        )
    }
}

export default PhoneInputContent
