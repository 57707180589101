import { UPVOTE_SHORTLISTED_FEATURE } from './constants'
import { createAction } from '@reduxjs/toolkit'
import { IUpvoteInfo } from 'upcomingPageModels'

export const upvoteShortlistedFeature = createAction(
    UPVOTE_SHORTLISTED_FEATURE,
    function prepare(payload: IUpvoteInfo) {
        return {
            payload,
        }
    }
)
