type Props = {
    color: string
}

const DesktopLinkNavArrow = ({ color }: Props) => (
    <svg
        width="35"
        height="9"
        viewBox="0 0 35 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            x1="-4.37114e-08"
            y1="4.49988"
            x2="34"
            y2="4.49988"
            stroke={color}
        />
        <line
            y1="-0.5"
            x2="6.14636"
            y2="-0.5"
            transform="matrix(-0.650791 -0.759257 0.934488 -0.355995 35 4.66663)"
            stroke={color}
        />
        <line
            y1="-0.5"
            x2="6.14636"
            y2="-0.5"
            transform="matrix(-0.650791 0.759257 0.934488 0.355995 35 4.33325)"
            stroke={color}
        />
    </svg>
)

export default DesktopLinkNavArrow
