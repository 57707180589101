import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import TouchScrollable from 'react-scrolllock'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import DragNSortEditableMobile from '../../../molecules/dragnsort/dragNSortEditableMobile'
import {
    IHighlightedFact,
    IHighlightedFactsObject,
    IUpdateHF,
} from 'entityModels'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
}

const EditFormContainerMobile = styled.section<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 24px;
    height: 100vh;
    /* min-height: -webkit-fill-available; */
    overflow-y: auto;
    background-color: ${(props) => colours[props.$theme].background_default};
    color: ${(props) => colours[props.$theme].text_strong};
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const FieldTitle = styled.div<IStyle>`
    font-family: Lato-Semibold;
    /* font-weight: 600; */
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.005em;
    color: ${(props) => colours[props.$theme].text_strong};
    padding-bottom: 16px;
    align-self: flex-start;
    text-align: left;
`

const Text = styled.section<IStyle>`
    padding-top: 3px;
    color: ${(props) => colours[props.$theme].text_strong};
    text-align: left;
    font-family: Lato-Light;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    line-height: 22px;
    @media screen and (min-width: 420px) {
        padding-right: 30px;
    }

    @media screen and (max-width: 325px) {
        display: none;
        padding-right: 5px;
    }
`

const Subtitle = styled.div<IStyle>`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    color: ${(props) => colours[props.$theme].text_default};

    max-width: 85vw;
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
`

const Actions = styled.div<IStyle>`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    height: 100px;
    border-top: 1px solid ${(props) => colours[props.$theme].border_muted};
    left: 0;
    right: 0;
    width: 100%;
    padding-right: 30px;
    background-color: ${(props) => colours[props.$theme].background_default};
    z-index: 6;
`

const InnerSection = styled.div`
    width: 100%;
`

const LargePadding = styled.div<IStyle>`
    padding-top: 32px;
    width: 100%;
    border-top: 1px solid ${(props) => colours[props.$theme].border_muted};
`

const Line = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].border_muted};
    height: 1px;
    width: 100vw;
    margin-left: -20px;
`

// const ButtonsWrapper = styled.div`
//     width: 100%;
//     position: fixed;
//     bottom: 0;
//     bottom: env(safe-area-inset-bottom);
// `

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

// function refreshPage() {
//   window.location.reload(false);
// }

interface Values {
    [key: string]: string
    new_ordered_array?: any
}

interface OtherProps extends RouteComponentProps<any> {
    close: () => void
    data_object: IHighlightedFactsObject
    submitFunc: (data: IUpdateHF) => void
    ids_list: string[]
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        data_object,
        ids_list,
        // // errors,
        // // touched,
        handleChange,
        setFieldValue,
        // // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const { theme } = useThemes()

    return (
        <TouchScrollable>
            <EditFormContainerMobile id="scrollable-content" $theme={theme}>
                <InnerFormPadding>
                    <InnerSection>
                        <FieldTitle $theme={theme}>
                            Key Moments & Facts
                        </FieldTitle>
                        <Line $theme={theme} />

                        <div style={{ paddingTop: '30px' }} />
                        <Subtitle $theme={theme}>
                            You may add up to 5 Key Moments & Facts about your
                            car. Drag & drop items to set them in preferred
                            order.
                        </Subtitle>

                        <div style={{ paddingTop: '8px' }} />
                        <Text $theme={theme}>[up to 200 characters]</Text>

                        <div style={{ paddingTop: '10px' }} />
                    </InnerSection>
                    <DragNSortEditableMobile
                        data_object={data_object}
                        ids_list={ids_list}
                        giveNewArrayOrder={(items: any) =>
                            setFieldValue('new_ordered_array', items)
                        }
                        formikprops={{
                            values: values,
                            handleChange: handleChange,
                            setFieldValue: setFieldValue,
                        }}
                    />
                </InnerFormPadding>

                <div style={{ paddingTop: '100px' }} />
                <LargePadding style={{ border: 'none' }} $theme={theme} />
                <Actions $theme={theme}>
                    <ButtonAtom
                        theme="capitalize-white-background"
                        width="74px"
                        height="48px"
                        dataCyId={'showroom-car-share'}
                        icon={''}
                        fontSize={16}
                        onClick={close}
                    >
                        <div style={{}}>Cancel</div>
                    </ButtonAtom>

                    <ButtonAtom
                        theme="secondary"
                        width="80px"
                        height="48px"
                        textTransform="capitalize"
                        dataCyId={'showroom-car-enquire'}
                        onClick={props.handleSubmit}
                        fontSize={16}
                    >
                        Save
                    </ButtonAtom>
                </Actions>
            </EditFormContainerMobile>
        </TouchScrollable>
    )
}

const CarHighlightsEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        [props.ids_list[0]]: props.data_object[props.ids_list[0]]
            ? props.data_object[props.ids_list[0]].text
            : '',
        [props.ids_list[1]]: props.data_object[props.ids_list[1]].text
            ? props.data_object[props.ids_list[1]].text
            : '',
        [props.ids_list[2]]: props.data_object[props.ids_list[2]].text
            ? props.data_object[props.ids_list[2]].text
            : '',
        [props.ids_list[3]]: props.data_object[props.ids_list[3]].text
            ? props.data_object[props.ids_list[3]].text
            : '',
        [props.ids_list[4]]: props.data_object[props.ids_list[4]].text
            ? props.data_object[props.ids_list[4]].text
            : '',
    }),

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (values.new_ordered_array !== undefined) {
            let new_array_with_order_change: IHighlightedFact[] = []
            values.new_ordered_array.forEach(
                (item: IHighlightedFact, index: any) => {
                    let values_obj: Values = values
                    if (values_obj[item.id] !== undefined) {
                        let newItem: IHighlightedFact = {
                            id: item.id,
                            text: values[item.id]
                                ? values[item.id].length < 200
                                    ? values[item.id]
                                    : ''
                                : '',
                            order: index + 1,
                        }

                        return new_array_with_order_change.push(newItem)
                    }
                }
            )

            props.submitFunc({
                carid: props.carid,
                highlightedFacts: new_array_with_order_change,
            })
        } else {
            let new_array_without_order_change: any[] = []

            props.ids_list.forEach((id: string, index: number) => {
                let item = (props.data_object[id] = {
                    ...props.data_object[id],
                    text: values[props.ids_list[index]]
                        ? values[props.ids_list[index]]
                        : '',
                })
                new_array_without_order_change.push(item)
            })

            props.submitFunc({
                carid: props.carid,
                highlightedFacts: new_array_without_order_change,
            })
        }

        props.close()
    },
})(InnerForm)
export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarHighlightsEditFormMobile)
)
