import { ISubNavigationListItem } from 'TopSubnavigationData'

export const apex_homepage: ISubNavigationListItem[] = [
    // {
    //     name: 'events',
    //     mobile_id: 'mobile-apex-events-hp',
    // },
    {
        name: 'podcasts',
        mobile_id: 'mobile-apex-podcasts-hp',
    },
    {
        name: 'articles',
        mobile_id: 'mobile-apex-articles-hp',
    },
    {
        name: 'car of the week',
        mobile_id: 'mobile-apex-cotw-hp',
    },

    // {
    //     name: 'quote of the week',
    //     mobile_id: 'mobile-apex-qotw-hp',
    // },
    {
        name: 'news highlights',
        mobile_id: 'mobile-apex-hn-hp',
    },

    {
        name: 'interviews',
        mobile_id: 'mobile-apex-interviews-hp',
    },
]
