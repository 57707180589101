import {
    ANSWER_ONBOARDING_QA_REQUEST,
    SET_PRIMARY_ONBOARDING_USER_TYPE_REQUEST,
    SET_SECONDARY_ONBOARDING_USER_TYPE_REQUEST,
} from './constants'

import { createAction } from '@reduxjs/toolkit'

export const answerOnboardingQaRequest = createAction(
    ANSWER_ONBOARDING_QA_REQUEST
)

export const setPrimaryOnboardingQaRequest = createAction(
    SET_PRIMARY_ONBOARDING_USER_TYPE_REQUEST
)

export const setSecondaryOnboardingQaRequest = createAction(
    SET_SECONDARY_ONBOARDING_USER_TYPE_REQUEST
)
