type Props = {
    size?: string
    color?: string
}

const HamburgerIconEqualLines = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4 11.2668H20V12.7668H4V11.2668Z"
                fill={color ?? '#AA6FD8'}
            />
            <path
                d="M4 5.76685H20V7.26685H4V5.76685Z"
                fill={color ?? '#AA6FD8'}
            />
            <path
                d="M4 16.7668H20V18.2668H4V16.7668Z"
                fill={color ?? '#AA6FD8'}
            />
        </svg>
    )
}

export default HamburgerIconEqualLines
