import styled from 'styled-components'
import * as React from 'react'
import { ITabProps } from 'myInterfaces'
import { Tooltip } from 'antd'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

interface TabProps {
    isActive: boolean
}
const StyledTab = styled.button<TabProps>`
    font-size: 14px;

    outline: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    z-index: 0;
    font-family: ${(props) => (props.isActive ? 'Lato-Bold' : 'Lato')};
    font-size: 15px;
`

interface Props extends ITabProps {
    customPassiveColor?: string
}

const BtnTab: React.FunctionComponent<Props> = (props: Props) => {
    const [isTooltipOn, setIsTooltipOn] = React.useState(false)
    const { theme } = useThemes()
    return (
        <Tooltip
            title={props.disabledInfo}
            open={isTooltipOn}
            placement="top"
            color={colours[theme].text_darker}
            overlayInnerStyle={{ boxShadow: 'none' }}
        >
            <StyledTab
                onClick={(e) => {
                    e.preventDefault()
                    if (props.isDisabled) {
                        props.disabledInfo && setIsTooltipOn(true)
                        setTimeout(() => setIsTooltipOn(false), 2000)
                    } else {
                        props.onClick(props.id)
                    }
                }}
                onMouseEnter={() => {
                    if (props.disabledInfo && props.isDisabled) {
                        setIsTooltipOn(true)
                    }
                }}
                onMouseLeave={() => {
                    if (
                        props.disabledInfo &&
                        props.isDisabled &&
                        isTooltipOn === true
                    ) {
                        setIsTooltipOn(false)
                    }
                }}
                key={props.id}
                type="button"
                isActive={props.isActive}
                style={{
                    height: props.height ?? '48px',
                    width: props.width ?? '50%',
                    backgroundColor: !props.isActive
                        ? props.customPassiveColor ??
                          colours[theme].background_default
                        : colours[theme].text_strong,
                    borderColor: props.isActive
                        ? 'transparent'
                        : colours[theme].text_muted,
                    color: props.isActive
                        ? colours[theme].background_default
                        : props.isDisabled
                        ? colours[theme].text_muted
                        : colours[theme].text_default,
                    borderWidth: '1px',
                    borderTopWidth: '1px',
                    borderLeftWidth:
                        props.isMiddle || props.isLast ? '0px' : '1px',
                    borderStyle: 'solid',
                    borderTopLeftRadius: props.isMiddle
                        ? '0px'
                        : props.isLast
                        ? '0px'
                        : '4px',
                    borderTopRightRadius: props.isMiddle
                        ? '0px'
                        : props.isFirst
                        ? '0px'
                        : '4px',
                    borderBottomLeftRadius: props.isMiddle
                        ? '0px'
                        : props.isLast
                        ? '0px'
                        : '4px',
                    borderBottomRightRadius: props.isMiddle
                        ? '0px'
                        : props.isFirst
                        ? '0px'
                        : '4px',
                    cursor:
                        props.isDisabled === true ? 'not-allowed' : 'pointer',
                }}
            >
                {props.title}
            </StyledTab>
        </Tooltip>
    )
}

export default BtnTab
