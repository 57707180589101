import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { device } from '../devices'

interface Props {
    children?: React.ReactNode
}

const Wrap = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 18px;
        padding-right: 18px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
    }

    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const MobileContentBody: React.SFC<Props> = (props) => (
    <Wrap>{props.children}</Wrap>
)

export default connect()(MobileContentBody)
