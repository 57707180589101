import * as React from 'react'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import {
    IManageAddAttachmentsToEntryRequest,
    manageAddAttachmentsToEntryRequest,
} from '../../../../redux/timeline/actions/requestActions'

import { Upload, message } from 'antd'
import styled from 'styled-components'
import { AttachmentTypesEnum } from '../../../../redux/attachments/enums'
import {
    ConverFileTypeToFileSuffixEnum,
    GetFileType,
} from '../../../../redux/conversions/attachments'
import AddIcon from '../../../atoms/icons/components/addIcon'
import AttachmentIcon from '../../../atoms/icons/components/historyFile/attachment'

const dispatchProps = {
    manageAddAttachmentsToEntryRequest: (
        p: IManageAddAttachmentsToEntryRequest
    ) => manageAddAttachmentsToEntryRequest(p),
}

function mapStateToProps(state: RootState) {
    return {}
}

interface Props {
    carid: string
    uploadChildren?: React.ReactNode
    noActionIcon?: boolean
    icon?: string
    onClickOpen?: any
    entityID: string
    isBottomsheet?: boolean
    closeBottomSheet?: () => void
    manageAddAttachmentsToEntryRequest: (
        p: IManageAddAttachmentsToEntryRequest
    ) => any
}

type State = {
    filesArray: File[] | undefined[]
    rejectdedFiles:
        | {
              filename: string
              code: string
          }[]
        | undefined[]
    [key: string]: any
    renderNumber: number
    filesnb: number
}

const errorFileSizeWarning = (filename: string) => {
    message.error({
        content: `${filename} not supported. The maximum file size allowed per upload is 20MB.`,
        duration: 3,
    })
}

const errorFileTypeWarning = (filename: string) => {
    message.error({
        content: `*.${GetFileType(filename)} files are not supported`,
        duration: 2,
    })
}

const errorWarning = () => {
    message.error({
        content: 'Please upload a maximum of 20 files.',
        duration: 2,
    })
}

const StyledUpload = styled(Upload)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 4px;
    transition: all 500ms;
    background-color: var(--primary_08);
`
const StyleMenuItemdUpload = styled(Upload)`
    width: 100%;
    height: auto;
`
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 4px;
    gap: 8px;
`

const MenuItemWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px 40px;
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 16px;
    text-transform: capitalize;
`
const UploadText = styled.div`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    font-size: 16px;
    text-align: center;
`

class EntryAttachmentsFormWrapperMobile extends React.Component<Props, State> {
    state = {
        filesArray: [undefined],
        rejectdedFiles: [undefined],
        renderNumber: 0,
        filesnb: 0,
    }

    handleFilesGiven = (info: any) => {
        let newFilesList: File[] = []
        let rejectdedFiles: any[] = []

        if (info && info.fileList) {
            this.setState({
                ...this.state,
                filesnb: info.fileList.length - 1,
            })
            info.fileList.forEach((f: any, index: number) => {
                let file: File = f.originFileObj
                let fs: AttachmentTypesEnum | undefined =
                    ConverFileTypeToFileSuffixEnum(f.name)

                if (file) {
                    if (file.size > 20000000 || file.size < 1) {
                        rejectdedFiles.push({
                            filename: file.name,
                            code: 'file-size-not-supported',
                        })
                        return
                    } else if (!fs) {
                        rejectdedFiles.push({
                            filename: file.name,
                            code: 'file-type-not-supported',
                        })
                        return
                    } else return newFilesList.push(file)
                }
            })

            this.setState({
                ...this.state,
                filesArray: newFilesList,
            })

            this.setState({
                ...this.state,
                rejectdedFiles: rejectdedFiles,
            })

            newFilesList &&
                newFilesList.length > 0 &&
                newFilesList.length <= 20 &&
                (!rejectdedFiles || rejectdedFiles.length === 0) &&
                this.props.manageAddAttachmentsToEntryRequest({
                    files: newFilesList,
                    entryID: this.props.entityID,
                    carID: this.props.carid,
                })
        }
    }

    dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    checkIfWarnings = () => {
        // we want to only display warning once, when all files have been uploaded
        // because onChange re-renders component for every single file added, if we have more than 1 file
        // component will re-render as many times as the number of files
        // so if we were to check for file errors inside onChange func, error messages will display nb_of_errors x nb_of_renders
        if (
            // if there are any rejected files and
            this.state.rejectdedFiles &&
            // if all files have been uploaded
            this.state.renderNumber === this.state.filesnb
        ) {
            // check if more than 20 files
            if (this.state.filesnb > 20) {
                errorWarning()
            }

            this.state.rejectdedFiles.forEach((item: any) => {
                // check for not supported file types
                if (
                    item &&
                    item.code &&
                    item.code === 'file-type-not-supported'
                ) {
                    errorFileTypeWarning(item.filename)
                } else if (
                    // check for not supported file size
                    item &&
                    item.code &&
                    item.code === 'file-size-not-supported'
                ) {
                    errorFileSizeWarning(item.filename)
                }
            })

            // restart count for new uploads
            this.setState({
                ...this.state,
                renderNumber: -1,
                filesnb: 0,
            })
        }
    }

    render() {
        return this.props.isBottomsheet ? (
            <StyleMenuItemdUpload
                onChange={(info: any) => {
                    if (info) {
                        this.handleFilesGiven(info)
                        this.checkIfWarnings()
                        this.setState({
                            ...this.state,
                            renderNumber: this.state.renderNumber + 1,
                        })
                        this.props.closeBottomSheet &&
                            this.props.closeBottomSheet()
                    }
                }}
                customRequest={() => this.dummyRequest}
                multiple
                fileList={[]}
                accept=".pdf, .jpg, .jpeg, .png, .xlsm, .xlsx, .xls, .doc, .docx, .heif, .heic"
            >
                <MenuItemWrapper>
                    <AttachmentIcon />
                    <span>Add attachments</span>
                </MenuItemWrapper>
            </StyleMenuItemdUpload>
        ) : (
            <StyledUpload
                onChange={(info: any) => {
                    if (info) {
                        this.handleFilesGiven(info)
                        this.checkIfWarnings()
                        this.setState({
                            ...this.state,
                            renderNumber: this.state.renderNumber + 1,
                        })
                    }
                }}
                customRequest={() => this.dummyRequest}
                multiple
                fileList={[]}
                accept=".pdf, .jpg, .jpeg, .png, .xlsm, .xlsx, .xls, .doc, .docx, .heif, .heic"
            >
                <Wrapper>
                    <AddIcon size="16" colour="#5ec3ca" />
                    <UploadText>Add Files(s)</UploadText>
                </Wrapper>
            </StyledUpload>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(EntryAttachmentsFormWrapperMobile)
