import { IGalleryImage } from 'entityModels'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { generateImageSources } from '../../../../helpers/images'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import SkeletonAbsolute from '../../skeleton/skeletonAbsolute'

type Props = {
    isLoading: boolean
    image?: IGalleryImage
}

const Container = styled.div<{ $aspectRatio: number | undefined }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    transition: all 100ms;
    width: 100%;
    aspect-ratio: ${(props) =>
        props.$aspectRatio
            ? props.$aspectRatio > 1.8
                ? '1.8'
                : props.$aspectRatio
            : 'auto'};
    min-height: 85vh;
    max-height: 100vh;

    /* @media (max-width: 1000px) {
        max-height: 700px;
    }

    @media (max-width: 1300px) {
        max-height: 900px;
    }

    @media (max-width: 1400px) {
        max-height: 1000px;
    } */

    @media (min-width: 2500px) {
        min-height: 100vh;
        max-height: 130vh;
    }

    align-self: stretch;
`

const MainImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 100ms;
`

const ShowroomCarMainImageDesktop = (props: Props) => {
    const { image } = props

    const [isImageLoading, setIsImageLoading] = useState<boolean>(true)

    const [img_src, setImag_src] = useState<string | null>(null)

    const [aspectRatio, setAspectRatio] = useState<number | undefined>(
        undefined
    )

    // on loading images
    const onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src
        setImag_src(src)

        image.onload = () => setIsImageLoading(false)
    }

    useEffect(() => {
        if (image) {
            onLoad(image.url)
        } else {
            setIsImageLoading(false)
        }
    }, [image])

    useEffect(() => {
        const updateAspectRatio = () => {
            const { innerWidth, innerHeight } = window
            setAspectRatio(innerWidth / innerHeight)
        }

        updateAspectRatio()

        window.addEventListener('resize', updateAspectRatio)

        return () => {
            window.removeEventListener('resize', updateAspectRatio)
        }
    }, [])

    let { width } = useWindowSize()

    return (
        <Container $aspectRatio={aspectRatio}>
            <SkeletonAbsolute
                isthingloading={!img_src || isImageLoading}
                darkTheme={true}
            />
            <MainImage
                style={{ opacity: img_src ? 1 : 0 }}
                src={img_src ? img_src : ''}
                srcSet={image ? generateImageSources(image, width) : undefined}
            />
        </Container>
    )
}

export default ShowroomCarMainImageDesktop
