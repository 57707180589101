import {
    ICreateTaskPayload,
    IDeleteTaskPayload,
    ISetTaskRemindersEditedPayload,
    ITaskRelationsPayload,
    IUpdateTaskReq,
} from 'entityModels'

import { createAction } from '@reduxjs/toolkit'

import {
    GET_TASKS_BY_GARAGE_ID_REQUEST,
    GET_TASKS_BY_CAR_ID_REQUEST,
    GET_TASK_BY_ID_REQUEST,
    UPDATE_TASK_REQUEST,
    CREATE_TASK_REQUEST,
    UNLINK_TASK_REQUEST,
    LINK_TASK_REQUEST,
    DELETE_TASK_REQUEST,
    GET_TASK_REMINDERS_REQUEST,
    SET_TASK_REMINDERS_EDITED_REQUEST,
    ADD_REMINDER_TO_TASK_REQUEST,
} from './constants'
import { IGetTaskRemindersReq } from '../sagas/getTaskReminders'
import { IDeleteTaskReminderReqSaga } from '../sagas/deleteTaskReminder'

export const getTasksByGarageIdRequest = createAction(
    GET_TASKS_BY_GARAGE_ID_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getTasksByCarIdRequest = createAction(
    GET_TASKS_BY_CAR_ID_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getTaskByIdRequest = createAction(
    GET_TASK_BY_ID_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const updateTaskRequest = createAction(
    UPDATE_TASK_REQUEST,
    function prepare(payload: IUpdateTaskReq) {
        return {
            payload,
        }
    }
)

export const createTaskRequest = createAction(
    CREATE_TASK_REQUEST,
    function prepare(payload: ICreateTaskPayload) {
        return {
            payload,
        }
    }
)

// export const unlinkTaskRequest = createAction(
//     UNLINK_TASK_REQUEST,
//     (resolve) => (payload: ITaskRelationsPayload) => resolve(payload)
// )

export const unlinkTaskRequest = createAction(
    UNLINK_TASK_REQUEST,
    function prepare(payload: ITaskRelationsPayload) {
        return {
            payload,
        }
    }
)

// export const linkTaskRequest = createAction(
//     LINK_TASK_REQUEST,
//     (resolve) => (payload: ITaskRelationsPayload) => resolve(payload)
// )

export const linkTaskRequest = createAction(
    LINK_TASK_REQUEST,
    function prepare(payload: ITaskRelationsPayload) {
        return {
            payload,
        }
    }
)

// export const deleteTaskRequest = createAction(
//     DELETE_TASK_REQUEST,
//     (resolve) => (payload: IDeleteTaskPayload) => resolve(payload)
// )

export const deleteTaskRequest = createAction(
    DELETE_TASK_REQUEST,
    function prepare(payload: IDeleteTaskPayload) {
        return {
            payload,
        }
    }
)

export const getTaskRemindersRequest = createAction(
    GET_TASK_REMINDERS_REQUEST,
    function prepare(payload: IGetTaskRemindersReq) {
        return {
            payload,
        }
    }
)

export const setTaskRemindersEditedRequest = createAction(
    SET_TASK_REMINDERS_EDITED_REQUEST,
    function prepare(payload: ISetTaskRemindersEditedPayload) {
        return {
            payload,
        }
    }
)

export const addRemindersToTaskRequest = createAction(
    ADD_REMINDER_TO_TASK_REQUEST,
    function prepare(payload: ISetTaskRemindersEditedPayload) {
        return {
            payload,
        }
    }
)

export const deleteReminderTaskRequest = createAction(
    ADD_REMINDER_TO_TASK_REQUEST,
    function prepare(payload: IDeleteTaskReminderReqSaga) {
        return {
            payload,
        }
    }
)
