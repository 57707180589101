import {
    ConvertSearchAPIReqToURL,
    IConvertSearchAPIReqToURL_arg,
} from '../../conversions/showroom/searchURLBuilder'
import { customHeader } from '../headers'

import * as urls from '../urls'
import { ISearchShowroom_API_res } from './types'
import { apiSentryErrorHandler } from '../errorHandler'

export const searchShowroom = (
    p: IConvertSearchAPIReqToURL_arg
): Promise<ISearchShowroom_API_res> => {
    let urlBuilt = ConvertSearchAPIReqToURL(p)

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.showroom_search_with_filters(urlBuilt),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Search showroom error')
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
