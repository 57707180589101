import { ICar } from 'entityModels'
import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import { useAppSelector } from '../../../redux/store/hooks'
import { motion } from 'framer-motion'
import { Checkbox } from 'antd'
import PenIcon from '../icons/components/penIcon'
import BinIcon from '../icons/components/binIcon'

import img_1 from '../../atoms/skeleton/gallery/1.jpg'
import img_2 from '../../atoms/skeleton/gallery/2.jpg'
import img_3 from '../../atoms/skeleton/gallery/3.jpg'
import img_4 from '../../atoms/skeleton/gallery/4.jpg'
import img_5 from '../../atoms/skeleton/gallery/5.jpg'
import img_6 from '../../atoms/skeleton/gallery/6.jpg'

import img_1_dark from '../../atoms/skeleton/gallery/1_dark.png'
import img_2_dark from '../../atoms/skeleton/gallery/2_dark.png'
import img_3_dark from '../../atoms/skeleton/gallery/3_dark.png'
import img_4_dark from '../../atoms/skeleton/gallery/4_dark.png'
import img_5_dark from '../../atoms/skeleton/gallery/5_dark.png'
import img_6_dark from '../../atoms/skeleton/gallery/6_dark.png'

import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import external_car_placeholder from '../../../public/assets/placeholder/external_car_placeholder.svg'
import { IInsuranceQuoteApplication_Car } from '../../../redux/insuranceQuoteApplication/reducer'
import { IInsuranceApplicationSectionId } from '../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import ChevronDown from '../icons/components/chevronDown'

type IStyle = {
    $theme: ITheme
    $isSelected?: boolean
    $isActive?: boolean
    $isDisabled?: boolean
    $sectionId?: IInsuranceApplicationSectionId
    $bg?: string
}

const Wrap = styled.section`
    width: 100%;
    padding: 8px;
`
const SelectableWrap = styled.section<IStyle>`
    display: flex;
    padding: 16px 18px 16px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 4px;
    border: ${(props) =>
        `1px solid ${
            props.$isSelected
                ? props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_30
                    : colours[props.$theme].primary
                : colours[props.$theme].text_muted
        }`};
    background: ${(props) =>
        props.$isSelected
            ? props.$sectionId
                ? insurance_colours[props.$theme].sections[props.$sectionId]
                      .primary_04
                : colours[props.$theme].primary_04
            : props.$bg ?? colours[props.$theme].background_neutral_subtle};

    transition: all 100ms;
    :hover {
        ${(props) =>
            `1px solid ${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_100
                    : colours[props.$theme].primary
            }`};
        background: ${(props) =>
            `${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_08
                    : colours[props.$theme].primary_04
            }`};
    }

    cursor: pointer;
`

const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
`

const GreyListItemHoverable = styled.div<IStyle>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
    padding: 12px;
    padding-right: 16px;
    border-radius: 4px;
    :hover {
        background: ${(props) =>
            props.$isDisabled
                ? undefined
                : props.$sectionId
                ? insurance_colours[props.$theme].sections[props.$sectionId]
                      .primary_04
                : colours[props.$theme].primary_04};
    }
`

const Title = styled.div<IStyle>`
    color: ${(props) =>
        props.$isSelected === false
            ? colours[props.$theme].text_default
            : colours[props.$theme].text_strong};
    /* Desktop/Heading/H300 */
    font-size: 14px;
    font-family: Lato-bold;
    line-height: 20px;
    letter-spacing: 0.001px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`
const NumberPlate = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 12px;
    font-family: Lato;
    line-height: 16px;
    letter-spacing: 0.001px;
    text-transform: uppercase;
`

const GreyWrapper = styled.div<IStyle>`
    width: 100%;
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
`

const SubWrapper = styled.div<IStyle>`
    width: 100%;
    display: flex;
    height: 48px;
    padding: 8px 0px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
`
const SpaceBetweenWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const ImageWrapper = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 4px;
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
`

const BtnWrapper = styled.button`
    all: unset;
    width: fit-content;
    height: fit-content;
    cursor: pointer;
`

const EverydayCarLabel = styled.div<IStyle>`
    display: flex;
    height: 16px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${(props) => colours[props.$theme].text_neutral_default};
    color: ${(props) => colours[props.$theme].background_default};
    text-align: center;
    font-size: 10px;
    font-family: Lato-bold;
    line-height: 16px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    margin-right: 8px;
`

const CompletionPercentage = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    /* Desktop/Heading/H300 */
    font-size: 14px;
    font-family: Lato-semibold;
    line-height: 18px;
    letter-spacing: 0.001px;
`

const IconsWrapper = styled(ListItem)``

const StyledCheckbox = styled(Checkbox)<IStyle>`
    transform: translateY(-0.2em);
    .ant-checkbox-checked .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: ${(props) =>
            `1px solid ${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_100
                    : colours[props.$theme].primary
            }`};
    }
    .ant-checkbox-inner {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: ${(props) => `1px solid ${colours[props.$theme].text_muted}`};
        background-color: transparent !important;
        /* background-color: ${(props) =>
            `${
                insurance_colours[props.$theme].sections['drivers'].primary_100
            } !important`}; */
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${(props) =>
            `${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_100
                    : colours[props.$theme].primary
            } !important`};
        border: ${(props) =>
            `2px solid ${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_100
                    : colours[props.$theme].primary
            }`};
    }

    .ant-checkbox-inner:after {
        left: 24%;
    }
    .ant-checkbox-checked::after {
        border: ${(props) =>
            `2px solid ${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_100
                    : colours[props.$theme].primary
            }`};
    }
`

interface Props {
    sectionId?: IInsuranceApplicationSectionId
    garageItem?: ICar
    showGarageCarTitle?: boolean
    insuranceVehicleItem?: IInsuranceQuoteApplication_Car
    variant?:
        | 'default'
        | 'drive_list'
        | 'selectable'
        | 'everyday_car_editable'
        | 'everyday_car_card_content'

    // specific default props
    isEverydayCar?: boolean
    completion?: number
    onDefaultRemove?: () => void

    // specific selectable props
    isSelected?: boolean
    onSelect?: (id: string) => void

    // specific everyday_car_editable props
    onEdit?: () => void
    removeAsEverydayCar?: () => void

    // specific everyday_car_card_content props
    isExternalCar?: boolean
    isInsuredCar?: boolean
    onEverydayCarDelete?: () => void

    // specific drive_list props
    onDriveListDelete?: () => void
    car_id?: string
    isDisabled?: boolean
    bg?: string
}

const VehicleItemDesktop: React.FC<Props> = (props) => {
    const galleryData = useAppSelector(
        (state) => state.entities.galleriesData.images
    )

    const all_vehicles = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.vehicles
    )

    const getPlaceholderImg = (image_number: number, theme: ITheme): string => {
        switch (image_number) {
            case 1:
                return theme === 'light' ? img_1 : img_1_dark

            case 2:
                return theme === 'light' ? img_2 : img_2_dark

            case 3:
                return theme === 'light' ? img_3 : img_3_dark
            case 4:
                return theme === 'light' ? img_4 : img_4_dark
            case 5:
                return theme === 'light' ? img_5 : img_5_dark
            case 6:
                return theme === 'light' ? img_6 : img_6_dark

            default:
                return grey_placeholder
        }
    }

    const { theme } = useThemes()

    let random_placeholder = React.useMemo(
        () => getPlaceholderImg(Math.floor(Math.random() * 5), theme),
        []
    )

    let coverURL = props.garageItem
        ? galleryData && props.garageItem?.gallery?.cover
            ? galleryData[props.garageItem.gallery.cover]?.url
            : random_placeholder
        : external_car_placeholder

    let item = props.garageItem ?? props.insuranceVehicleItem ?? undefined

    let insuranceVehicle = props.insuranceVehicleItem
        ? props.insuranceVehicleItem
        : props.car_id
        ? all_vehicles[props.car_id]
        : undefined

    let generateTitle = () => {
        if (props.showGarageCarTitle && props.garageItem) {
            if (
                props.garageItem?.overview.data.year ||
                props.garageItem?.overview.data.make ||
                props.garageItem?.overview.data.model
            ) {
                if (
                    props.garageItem?.overview.data.make.answer === '-' ||
                    props.garageItem?.overview.data.model.answer === '-'
                ) {
                    return `Missing details`
                } else {
                    return `${
                        props.garageItem?.overview.data?.year.answer
                            ? `${props.garageItem?.overview.data.year.answer} `
                            : ''
                    }${
                        props.garageItem?.overview.data?.make?.answer
                            ? `${props.garageItem?.overview.data?.make?.answer} `
                            : ''
                    }${
                        props.garageItem?.overview.data?.model.answer
                            ? props.garageItem?.overview.data?.model.answer
                            : ''
                    }`
                }
            } else {
                return 'Untitled car'
            }
        } else if (insuranceVehicle && insuranceVehicle.tech_info) {
            if (
                insuranceVehicle.tech_info?.manufacture_year ||
                insuranceVehicle.tech_info?.make?.name ||
                insuranceVehicle.tech_info?.model?.name
            ) {
                if (
                    insuranceVehicle.tech_info?.make?.name === '-' ||
                    insuranceVehicle.tech_info?.model?.name === '-'
                ) {
                    return `Missing make or model details`
                } else {
                    return `${
                        insuranceVehicle.tech_info?.manufacture_year
                            ? `${insuranceVehicle.tech_info.manufacture_year} `
                            : ''
                    }${
                        insuranceVehicle.tech_info?.make?.name
                            ? `${insuranceVehicle.tech_info.make.name} `
                            : ''
                    }${
                        insuranceVehicle.tech_info?.model?.name
                            ? insuranceVehicle.tech_info.model.name
                            : ''
                    }`
                }
            } else {
                return 'Untitled car'
            }
        }
    }

    let reg_no = props.garageItem
        ? props.garageItem.car_registration_number
        : insuranceVehicle?.tech_info?.registration_number
        ? insuranceVehicle?.tech_info?.registration_number
        : undefined

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )

    let isDraft = application?.status === 'DRAFT' ? true : false

    return props.variant === 'selectable' ? (
        <SelectableWrap
            $bg={props.bg}
            $sectionId={props.sectionId}
            $theme={theme}
            $isSelected={props.isSelected ? true : false}
            onClick={() => {
                if (isDraft) {
                    props.onSelect && item && item.id && props.onSelect(item.id)
                }
            }}
        >
            <SpaceBetweenWrapper>
                <Title
                    $theme={theme}
                    $isSelected={props.isSelected ? true : false}
                >
                    {generateTitle()?.toUpperCase()}
                </Title>
                <StyledCheckbox
                    $theme={theme}
                    checked={props.isSelected}
                    $isActive={props.isSelected ? true : false}
                    onChange={() => {}}
                    $sectionId={props.sectionId}
                />
            </SpaceBetweenWrapper>
            <NumberPlate $theme={theme}>{reg_no}</NumberPlate>
        </SelectableWrap>
    ) : props.variant === 'everyday_car_editable' ? (
        <SelectableWrap $isSelected $theme={theme} $sectionId={props.sectionId}>
            <SpaceBetweenWrapper>
                <Title $theme={theme} $isSelected>
                    {generateTitle()?.toUpperCase()}
                </Title>
                <IconsWrapper>
                    <BtnWrapper onClick={props.onEdit && props.onEdit}>
                        <motion.div whileTap={{ scale: 1.1 }}>
                            <PenIcon />
                        </motion.div>
                    </BtnWrapper>
                    {isDraft && (
                        <BtnWrapper
                            onClick={
                                props.removeAsEverydayCar &&
                                props.removeAsEverydayCar
                            }
                        >
                            <motion.div whileTap={{ scale: 1.1 }}>
                                <BinIcon />
                            </motion.div>
                        </BtnWrapper>
                    )}
                </IconsWrapper>
            </SpaceBetweenWrapper>
            <NumberPlate $theme={theme}>{reg_no}</NumberPlate>
        </SelectableWrap>
    ) : props.variant === 'drive_list' ? (
        <Wrap>
            <ListItem>
                <ImageWrapper style={{ opacity: props.isDisabled ? 0.3 : 1 }}>
                    {coverURL && <Image src={coverURL} />}
                </ImageWrapper>
                <GreyWrapper
                    $theme={theme}
                    style={{ width: props.isDisabled ? '100%' : '80%' }}
                    $sectionId="drivers"
                >
                    <Title
                        $theme={theme}
                        style={{
                            maxWidth: 360,
                            opacity: props.isDisabled ? 0.5 : 1,
                        }}
                    >
                        {generateTitle()?.toUpperCase()}
                    </Title>
                    {/* {props.isDisabled
                        ? null
                        : isDraft && (
                              <BtnWrapper
                                  onClick={
                                      props.onDriveListDelete &&
                                      props.onDriveListDelete
                                  }
                              >
                                  <motion.div
                                      style={{ cursor: 'pointer' }}
                                      whileTap={{ scale: 1.1 }}
                                  >
                                      <Icon
                                          icon="grey_bin"
                                          width="16px"
                                          height="16px"
                                      />
                                  </motion.div>
                              </BtnWrapper>
                          )} */}
                </GreyWrapper>
            </ListItem>
        </Wrap>
    ) : props.variant === 'everyday_car_card_content' ? (
        <Wrap>
            <GreyListItemHoverable $theme={theme}>
                <ImageWrapper
                    style={
                        props.garageItem && props.isInsuredCar
                            ? { opacity: 0.4, filter: 'grayscale(100%)' }
                            : {}
                    }
                >
                    {coverURL && <Image src={coverURL} />}
                </ImageWrapper>
                <SubWrapper
                    style={{ width: '80%' }}
                    $theme={theme}
                    $sectionId="everyday_vehicles"
                >
                    <div style={{ maxWidth: props.isExternalCar ? 300 : 360 }}>
                        <Title
                            $theme={theme}
                            style={{
                                opacity:
                                    props.garageItem && props.isInsuredCar
                                        ? 0.4
                                        : 1,
                            }}
                        >
                            {generateTitle()?.toUpperCase()}
                        </Title>
                        {!props.isInsuredCar && (
                            <CompletionPercentage $theme={theme}>
                                Completed{' '}
                                {props.completion
                                    ? `${props.completion}%`
                                    : '0%'}
                            </CompletionPercentage>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        {props.isExternalCar ? (
                            <EverydayCarLabel $theme={theme}>
                                EXTERNAL
                            </EverydayCarLabel>
                        ) : null}

                        {isDraft &&
                            props.isInsuredCar &&
                            props.onEverydayCarDelete && (
                                <motion.div
                                    style={{
                                        cursor: 'pointer',
                                        paddingLeft: 8,
                                    }}
                                    whileTap={{ scale: 1.1 }}
                                >
                                    <BtnWrapper
                                        onClick={(e: any) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            props.onEverydayCarDelete &&
                                                props.onEverydayCarDelete()
                                        }}
                                    >
                                        <Icon
                                            icon="grey_bin"
                                            width="16px"
                                            height="16px"
                                        />
                                    </BtnWrapper>
                                </motion.div>
                            )}
                        {!props.isInsuredCar && (
                            <div
                                style={{
                                    transform: 'rotate(-90deg)',
                                }}
                            >
                                <ChevronDown />
                            </div>
                        )}
                    </div>
                </SubWrapper>
            </GreyListItemHoverable>
        </Wrap>
    ) : (
        <Wrap>
            <GreyListItemHoverable
                $theme={theme}
                style={{
                    width: '100%',
                }}
            >
                <ImageWrapper
                    style={{
                        width: '30%',
                        maxWidth: 48,
                        minWidth: 48,
                        opacity: props.isDisabled ? 0.3 : 1,
                    }}
                >
                    {coverURL && <Image src={coverURL} />}
                </ImageWrapper>
                <SubWrapper
                    $theme={theme}
                    $isDisabled={props.isDisabled}
                    style={{ width: '80%' }}
                >
                    <div style={{ maxWidth: 300 }}>
                        <Title
                            $theme={theme}
                            style={{
                                opacity: props.isDisabled ? 0.5 : 1,
                            }}
                        >
                            {generateTitle()?.toUpperCase()}
                        </Title>
                        {props.isDisabled ? null : (
                            <CompletionPercentage $theme={theme}>
                                Completed{' '}
                                {props.completion
                                    ? `${props.completion}%`
                                    : '0%'}
                            </CompletionPercentage>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        {props.isEverydayCar ? (
                            <EverydayCarLabel $theme={theme}>
                                EVERYDAY
                            </EverydayCarLabel>
                        ) : null}
                        <div
                            style={{
                                transform: 'rotate(-90deg)',
                            }}
                        >
                            <ChevronDown />
                        </div>

                        {/* {props.isDisabled
                            ? null
                            : isDraft &&
                              props.onDefaultRemove && (
                                  <motion.div
                                      style={{
                                          cursor: 'pointer',
                                          paddingLeft: 8,
                                      }}
                                      whileTap={{ scale: 1.1 }}
                                  >
                                      <BtnWrapper
                                          onClick={(e: any) => {
                                              e.preventDefault()
                                              e.stopPropagation()
                                              props.onDefaultRemove &&
                                                  props.onDefaultRemove()
                                          }}
                                      >
                                          <Icon
                                              icon="grey_bin"
                                              width="16px"
                                              height="16px"
                                          />
                                      </BtnWrapper>
                                  </motion.div>
                              )} */}
                    </div>
                </SubWrapper>
            </GreyListItemHoverable>
        </Wrap>
    )
}

export default VehicleItemDesktop
