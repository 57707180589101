import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { IAnyObject } from '../../insuranceQuoteApplication/reducer'

export type IPatchInsuranceApplication_req = {
    id: string
    data: IAnyObject
}

export const patchInsuranceApplication = (
    p: IPatchInsuranceApplication_req
): Promise<any> => {
    let jsonBodyConv: string = JSON.stringify(p.data)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }
    return fetch(urls.insurance_appli_patch(p.id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Patch insurance application error',
                            jsonBodyConv
                        )
                        return Promise.reject(
                            response.status === 503 || response.status === 400
                                ? data
                                : response
                        )
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
