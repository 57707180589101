type Props = {
    height?: string
    width?: string
}

const YourProfileBgPattern = ({ height, width }: Props) => {
    return (
        <svg
            width={width ?? '1440'}
            height={height ?? '540'}
            viewBox="0 0 1440 540"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3713_53394)">
                <path
                    opacity="0.2"
                    d="M895.451 189.508C616.319 157.088 -144.802 413.982 -169 466.238L-168.999 655H1544V0H1436.13C1404.93 138.605 1378.22 281.909 1164.66 335.658C897.703 402.845 1244.37 230.034 895.451 189.508Z"
                    fill="url(#paint0_linear_3713_53394)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_3713_53394"
                    x1="548.238"
                    y1="449.36"
                    x2="788.984"
                    y2="-173.308"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#90AEAA" stop-opacity="0" />
                    <stop offset="1" stop-color="#90AEAA" />
                </linearGradient>
                <clipPath id="clip0_3713_53394">
                    <rect width="1440" height="540" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default YourProfileBgPattern
