import {
    IApexSingleRichBodyState,
    IApexSingleInterviewItemState,
    IApexSingleInterviewNodeItemFromQueryAPI,
    IApexSingleInterviewPayloadFromQueryAPI,
    IHybridPrismicPropsToState,
} from 'apexModels'
import {
    convertIApexBodySliceFromAPItoStateSingle,
    convertIArrayHybridPrismicPropsToIHybridPrismicPropsToState,
} from './apexRichBodyReusableConversions'

export const convertPrismicApexSingleInterviewFromAPIToState = (
    apiData: IApexSingleInterviewPayloadFromQueryAPI
): IApexSingleInterviewItemState | undefined => {
    if (
        apiData.allApex_interviewss.edges &&
        apiData.allApex_interviewss.edges[0]
    ) {
        let singleInterviewNodeAPI: IApexSingleInterviewNodeItemFromQueryAPI =
            apiData.allApex_interviewss.edges[0].node

        let singleObject: IApexSingleInterviewItemState = {
            // note: only mandatory here. Remember to fill in the non mandatory
            uid:
                singleInterviewNodeAPI._meta && singleInterviewNodeAPI._meta.uid
                    ? singleInterviewNodeAPI._meta.uid
                    : '',
            introduction: [],
            date: singleInterviewNodeAPI.date && singleInterviewNodeAPI.date,
            title:
                singleInterviewNodeAPI.title &&
                singleInterviewNodeAPI.title[0] &&
                singleInterviewNodeAPI.title[0].text
                    ? singleInterviewNodeAPI.title[0].text
                    : '',
            name: singleInterviewNodeAPI.name
                ? singleInterviewNodeAPI.name
                : '',
            apex_team_credits:
                singleInterviewNodeAPI.apex_team_credits &&
                singleInterviewNodeAPI.apex_team_credits[0] &&
                singleInterviewNodeAPI.apex_team_credits[0].text
                    ? singleInterviewNodeAPI.apex_team_credits[0].text
                    : '',
            body: [],
        }

        // intro hybrid props convo
        if (singleInterviewNodeAPI.introduction) {
            let converted_intro: IHybridPrismicPropsToState[] = convertIArrayHybridPrismicPropsToIHybridPrismicPropsToState(
                singleInterviewNodeAPI.introduction
            )

            singleObject.introduction = converted_intro
        }

        // body slices convo

        if (singleInterviewNodeAPI.body) {
            let converted_body_slices_array: IApexSingleRichBodyState[] = []
            for (const slice of singleInterviewNodeAPI.body) {
                let converted_slide = convertIApexBodySliceFromAPItoStateSingle(
                    slice
                )
                converted_body_slices_array.push(converted_slide)
            }
            singleObject.body = converted_body_slices_array
        }

        return singleObject
    } else return undefined
}
