import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'typesafe-actions'

import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { ICar, IGarageObject } from 'entityModels'
import { IisOpen, IUser } from 'myModels'
import {
    createCarPublicShareRequest,
    deleteCarPublicShareRequest,
    filterCarSharesDisplay_change_request,
    getCarDataByIdRequest,
    getOwnedDirectSharesCarRequest,
    IFilterCarSharesDisplay_change_request,
    ISearchQueryCarSharesDisplay_change_request,
    ISortCarSharesDisplay_change_request,
    resetSearchQueryCarShareDisplay_request,
    searchQueryCarSharesDisplay_change_request,
    sortCarSharesDisplay_change_request,
} from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'

import SingleCarShareBodyDesktop from '../../molecules/singleCarShareBody/singleCarShareBodyDesktop'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SingleCarShareBodyMobile from '../../molecules/singleCarShareBody/singleCarShareBodyMobile'
import { getPublicSharesGarageRequest } from '../../../redux/entities/garages/actions/loadingActions'
import * as ReactScroll from 'react-scroll'
import { IDeleteShareReq } from '../../../redux/entities/cars/actions/actions'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
// import ReturnNav from '../../atoms/header/returnNav'
import { editFormsActions } from '../../../redux/editForms/reducer'
import QrCodeModalDesktop from '../../molecules/qrCodeModal/qrCodeModalDesktop'
import ModalDisplayDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayDesktopExclusive'
import Faded from '../../templates/animated/faded'
import QrCodeCustomiseStickerModalDesktop from '../../molecules/qrCodeCustomiseStickerModal/qrCodeCustomiseStickerModalDesktop'
import { IStickerPredefinedThemesState } from '../../../redux/localdata/stickerPredefinedThemes/reducer'
import QrCodeStickerBottomSheetMobile from '../../molecules/qrCodeCustomiseStickerModal/qrCodeStickerBottomSheetMobile'
import QrCodeModalMobile from '../../molecules/qrCodeModal/qrCodeModalMobile'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isCarLoading: state.entities.carsData.loading,
        carsData: state.entities.carsData.cars,
        isUserLoading: state.user.loading,
        qrCodeModal: state.editForms && state.editForms.formsData.qrCodeModal,
        garagesData: state.entities.garagesData.garages,
        qrCodeStickerModal:
            state.editForms && state.editForms.formsData.qrCodeStickerModal,
        predefinedQrCodeStickerThemes: state.localdata.stickerPredefinedThemes,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getPublicSharesGarageRequest: (garageid: string) =>
        getPublicSharesGarageRequest(garageid),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),

    sortCarSharesDisplay_change_request: (
        p: ISortCarSharesDisplay_change_request
    ) => sortCarSharesDisplay_change_request(p),

    filterCarSharesDisplay_change_request: (
        p: IFilterCarSharesDisplay_change_request
    ) => filterCarSharesDisplay_change_request(p),

    searchQueryCarSharesDisplay_change_request: (
        p: ISearchQueryCarSharesDisplay_change_request
    ) => searchQueryCarSharesDisplay_change_request(p),
    resetSearchQueryCarShareDisplay_request: (p: string) =>
        resetSearchQueryCarShareDisplay_request(p),
    createCarPublicShareRequest: (carid: string) =>
        createCarPublicShareRequest(carid),

    deleteCarPublicShareRequest: (p: IDeleteShareReq) =>
        deleteCarPublicShareRequest(p),
    toggleQrCodeModal: (payload: { isOpen: boolean; id: string | undefined }) =>
        editFormsActions.toggleQrCodeModal(payload),
    toggleQrCodeStickerModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => editFormsActions.toggleQrCodeStickerModal(payload),
    getOwnedDirectSharesCarRequest: (p: string) =>
        getOwnedDirectSharesCarRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    carsData: { [key: string]: ICar }
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isCarLoading: boolean
    getUserDataRequest: () => void
    getCarDataByIdRequest: (carid: string) => void
    setCurrentCar: (carid: string) => void
    searchQueryCarSharesDisplay_change_request: (
        p: ISearchQueryCarSharesDisplay_change_request
    ) => void
    filterCarSharesDisplay_change_request: (
        p: IFilterCarSharesDisplay_change_request
    ) => void
    sortCarSharesDisplay_change_request: (
        p: ISortCarSharesDisplay_change_request
    ) => void
    resetSearchQueryCarShareDisplay_request: (p: string) => void
    garagesData: IGarageObject
    getPublicSharesGarageRequest: (p: string) => void
    createCarPublicShareRequest: (p: string) => void
    deleteCarPublicShareRequest: (p: IDeleteShareReq) => void
    qrCodeModal: IisOpen
    qrCodeStickerModal: IisOpen
    toggleQrCodeModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => void
    toggleQrCodeStickerModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => void
    predefinedQrCodeStickerThemes: IStickerPredefinedThemesState
    getOwnedDirectSharesCarRequest: (p: string) => void
    setInitialLandingUrl: (str: string) => void
}

class CarSharing extends React.Component<Props, {}> {
    componentDidMount() {
        let carid = this.props.match.params.carid
        this.props.setInitialLandingUrl(`/car/${carid}/sharing`)
        // user stuff
        let userData = this.props.userLoggedIn
        let garageid =
            userData && userData.owns_garage && userData.owns_garage.uid
        if (!userData) {
            this.props.getUserDataRequest()
        } else {
            this.props.setCurrentCar(carid)
            this.props.getOwnedDirectSharesCarRequest(carid)

            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(this.props.match.params.carid)
            }

            if (
                // garageid && !this.props.garagesData[garageid]
                this.props.userLoggedIn &&
                garageid &&
                (!this.props.garagesData[garageid] ||
                    (!this.props.garagesData[garageid].public_share_owned &&
                        this.props.garagesData[garageid].public_share_owned !==
                            null))
            ) {
                this.props.getPublicSharesGarageRequest(garageid)
            }
        }

        const params = new URLSearchParams(this.props.location.search)
        let id = params.get('id')

        if (id === 'car_private_m') {
            this.scrollToElement('car_privately_shared_mobile')
        }

        if (id === 'car_private_d') {
            this.scrollToElement('car_privately_shared_desktop')
        }

        let qr_id = params.get('qr_id')
        let openCarQrCodeModal = () =>
            this.props.toggleQrCodeModal({
                isOpen: true,
                id: 'car_only',
            })
        let openGarageQrCodeModal = () =>
            this.props.toggleQrCodeModal({
                isOpen: true,
                id: 'car_via_garage',
            })
        if (qr_id === 'car_only') {
            setTimeout(openCarQrCodeModal, 200)
        } else if (qr_id === 'car_via_garage') {
            setTimeout(openGarageQrCodeModal, 200)
        }
    }

    componentDidUpdate(prevProps: Props) {
        // user stuff
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            let carid = this.props.match.params.carid
            let garageid =
                this.props.userLoggedIn &&
                this.props.userLoggedIn.owns_garage &&
                this.props.userLoggedIn.owns_garage.uid
            this.props.setCurrentCar(carid)
            this.props.getOwnedDirectSharesCarRequest(carid)
            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(this.props.match.params.carid)
            }
            if (
                this.props.userLoggedIn &&
                garageid &&
                (!this.props.garagesData[garageid] ||
                    (!this.props.garagesData[garageid].public_share_owned &&
                        this.props.garagesData[garageid].public_share_owned !==
                            null))
            ) {
                this.props.getPublicSharesGarageRequest(garageid)
            }
        }
    }

    scrollToElement = (elementName: string) => {
        ReactScroll.scroller.scrollTo(elementName, {
            duration: 500,
            delay: 300,
            smooth: true,
            offset: -70,
        })
    }

    render() {
        let {
            carsData,
            searchQueryCarSharesDisplay_change_request,
            filterCarSharesDisplay_change_request,
            sortCarSharesDisplay_change_request,
            resetSearchQueryCarShareDisplay_request,
            createCarPublicShareRequest,
            deleteCarPublicShareRequest,
            toggleQrCodeModal,
            qrCodeModal,
            qrCodeStickerModal,
            toggleQrCodeStickerModal,
            predefinedQrCodeStickerThemes,
        } = this.props

        let carid = this.props.match.params.carid
        let current: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        let car_only_qr_code: string | undefined =
            current &&
            current.public_share_owned &&
            current.public_share_owned?.qr_code_url
        let car_via_garage_qr_code: string | undefined =
            current &&
            current.public_share_owned &&
            current.public_share_owned?.qr_code_url_via_garage

        return (
            <React.Fragment>
                {current?.archived_on && (
                    <Redirect to={`/archives/car/${current.id}/overview`} />
                )}

                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
                <DesktopDisplayOnly>
                    <FullPagePageWrapper>
                        <div
                            style={{ paddingLeft: '50px', paddingTop: '20px' }}
                        >
                            {current &&
                                this.props.userLoggedIn &&
                                this.props.garagesData && (
                                    <SingleCarShareBodyDesktop
                                        handleChange_shares_search={
                                            searchQueryCarSharesDisplay_change_request
                                        }
                                        handleChange_shares_sort={
                                            sortCarSharesDisplay_change_request
                                        }
                                        handleChange_shares_filter={
                                            filterCarSharesDisplay_change_request
                                        }
                                        car={current}
                                        resetSearch={
                                            resetSearchQueryCarShareDisplay_request
                                        }
                                        garage={
                                            this.props.garagesData[
                                                this.props.userLoggedIn
                                                    .owns_garage.uid
                                            ]
                                        }
                                        createCarPublicShare={
                                            createCarPublicShareRequest
                                        }
                                        deletePublicLink={
                                            deleteCarPublicShareRequest
                                        }
                                        openQrCodeModal={(qr_id: string) =>
                                            toggleQrCodeModal({
                                                isOpen: true,
                                                id: qr_id,
                                            })
                                        }
                                    />
                                )}
                        </div>
                        {qrCodeModal.isOpen &&
                            (car_via_garage_qr_code || car_only_qr_code) && (
                                <Faded>
                                    <ModalDisplayDesktopExclusive
                                        isOpen={qrCodeModal.isOpen}
                                        toggle={() => {}}
                                        modalBackgroundColor="rgba(0, 0, 0, 0.65)"
                                    >
                                        <div style={{ marginTop: '20vh' }} />
                                        <QrCodeModalDesktop
                                            qr_code_link={
                                                qrCodeModal.id ===
                                                'car_via_garage'
                                                    ? car_via_garage_qr_code
                                                        ? car_via_garage_qr_code
                                                        : ''
                                                    : car_only_qr_code
                                                    ? car_only_qr_code
                                                    : ''
                                            }
                                            qr_code_id={
                                                'car_qr_code_to_export_desktop'
                                            }
                                            car_title={
                                                current && current.title
                                                    ? current.title
                                                    : 'Untitled'
                                            }
                                            closeModal={() =>
                                                this.props.toggleQrCodeModal({
                                                    isOpen: false,
                                                    id: undefined,
                                                })
                                            }
                                            isGarage={
                                                qrCodeModal.id ===
                                                'car_via_garage'
                                            }
                                            onStickerBtnClick={() =>
                                                toggleQrCodeStickerModal({
                                                    isOpen: true,
                                                    id: qrCodeModal.id,
                                                })
                                            }
                                        />
                                    </ModalDisplayDesktopExclusive>
                                    {qrCodeStickerModal.isOpen &&
                                        (car_via_garage_qr_code !== undefined ||
                                            car_only_qr_code !== undefined) && (
                                            <Faded>
                                                <ModalDisplayDesktopExclusive
                                                    isOpen={qrCodeModal.isOpen}
                                                    toggle={() => {}}
                                                    modalBackgroundColor="rgba(0, 0, 0, 0)"
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: '20vh',
                                                        }}
                                                    />
                                                    <QrCodeCustomiseStickerModalDesktop
                                                        qr_code_link={
                                                            qrCodeStickerModal.id ===
                                                            'car_via_garage'
                                                                ? car_via_garage_qr_code
                                                                    ? car_via_garage_qr_code
                                                                    : ''
                                                                : car_only_qr_code
                                                                ? car_only_qr_code
                                                                : ''
                                                        }
                                                        qr_code_id={
                                                            'car_qr_code_to_export_desktop'
                                                        }
                                                        car_title={
                                                            current &&
                                                            current.title
                                                                ? current.title
                                                                : 'Untitled'
                                                        }
                                                        closeModal={() =>
                                                            this.props.toggleQrCodeStickerModal(
                                                                {
                                                                    isOpen: false,
                                                                    id: undefined,
                                                                }
                                                            )
                                                        }
                                                        isGarage={
                                                            qrCodeStickerModal.id ===
                                                            'car_via_garage'
                                                        }
                                                        predefinedStickerThemes={
                                                            predefinedQrCodeStickerThemes
                                                        }
                                                    />
                                                </ModalDisplayDesktopExclusive>
                                            </Faded>
                                        )}
                                </Faded>
                            )}
                    </FullPagePageWrapper>
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <FullPagePageWrapper hasMobileSubNav>
                        {current &&
                            this.props.userLoggedIn &&
                            this.props.garagesData && (
                                <SingleCarShareBodyMobile
                                    noCarTitle
                                    handleChange_shares_search={
                                        searchQueryCarSharesDisplay_change_request
                                    }
                                    handleChange_shares_sort={
                                        sortCarSharesDisplay_change_request
                                    }
                                    handleChange_shares_filter={
                                        filterCarSharesDisplay_change_request
                                    }
                                    car={current}
                                    resetSearch={
                                        resetSearchQueryCarShareDisplay_request
                                    }
                                    createCarPublicShare={
                                        createCarPublicShareRequest
                                    }
                                    garage={
                                        this.props.garagesData[
                                            this.props.userLoggedIn.owns_garage
                                                .uid
                                        ]
                                    }
                                    deletePublicLink={
                                        deleteCarPublicShareRequest
                                    }
                                    openQrCodeModal={(qr_id: string) =>
                                        toggleQrCodeModal({
                                            isOpen: true,
                                            id: qr_id,
                                        })
                                    }
                                />
                            )}
                        {qrCodeModal.isOpen &&
                            (car_via_garage_qr_code !== undefined ||
                                car_only_qr_code !== undefined) && (
                                <Faded>
                                    <ModalDisplay
                                        isOpen={qrCodeModal.isOpen}
                                        toggle={() =>
                                            toggleQrCodeModal({
                                                isOpen: false,
                                                id: undefined,
                                            })
                                        }
                                    >
                                        <QrCodeModalMobile
                                            qr_code_link={
                                                qrCodeModal.id ===
                                                'car_via_garage'
                                                    ? car_via_garage_qr_code
                                                        ? car_via_garage_qr_code
                                                        : ''
                                                    : car_only_qr_code
                                                    ? car_only_qr_code
                                                    : ''
                                            }
                                            qr_code_id={
                                                'car_qr_code_to_export_desktop'
                                            }
                                            car_title={
                                                current && current.title
                                                    ? current.title
                                                    : 'Untitled'
                                            }
                                            closeModal={() =>
                                                this.props.toggleQrCodeModal({
                                                    isOpen: false,
                                                    id: undefined,
                                                })
                                            }
                                            isGarage={
                                                qrCodeModal.id ===
                                                'car_via_garage'
                                            }
                                            onStickerBtnClick={() => {
                                                toggleQrCodeStickerModal({
                                                    isOpen: true,
                                                    id: qrCodeModal.id,
                                                })
                                                this.props.toggleQrCodeModal({
                                                    isOpen: false,
                                                    id: undefined,
                                                })
                                            }}
                                        />
                                    </ModalDisplay>
                                </Faded>
                            )}

                        <QrCodeStickerBottomSheetMobile
                            qr_code_link={
                                qrCodeStickerModal.id === 'car_via_garage'
                                    ? car_via_garage_qr_code
                                        ? car_via_garage_qr_code
                                        : ''
                                    : car_only_qr_code
                                    ? car_only_qr_code
                                    : ''
                            }
                            title={
                                current && current.title
                                    ? current.title
                                    : 'Untitled'
                            }
                            qr_code_id={'car_qr_code_to_export_mobile'}
                            closeBottomSheet={() =>
                                this.props.toggleQrCodeStickerModal({
                                    isOpen: false,
                                    id: undefined,
                                })
                            }
                            isBottomSheetOpen={qrCodeStickerModal.isOpen}
                            predefinedStickerThemes={
                                predefinedQrCodeStickerThemes
                            }
                        />
                    </FullPagePageWrapper>
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CarSharing))
