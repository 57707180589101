import { useEffect, useState } from 'react'
import styled from 'styled-components'

type Props = {
    size?: string
}

type StyleProps = {
    $size?: string
    $padding?: string
    $isAnimationActive?: boolean
}

const Box = styled.div<StyleProps>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: ${(props) => (props.$size ? props.$size : '90px')};
    height: ${(props) => (props.$size ? props.$size : '95px')};
    background: rgba(26, 26, 26, 0.16);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border: none;
    overflow: hidden;

    :after {
        content: '';
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);
        background: linear-gradient(
            to right,
            rgba(26, 26, 26, 0) 0%,
            rgba(26, 26, 26, 0) 77%,
            rgba(192, 192, 192, 0.162) 92%,
            rgba(255, 255, 255, 0) 100%
        );
        ${(props) =>
            props.$isAnimationActive &&
            `opacity: 1;
            top: -30%;
            left: -30%;
             transition-property: left, top, opacity;
            transition-duration: 0.8s, 0.8s, 0.15s;
            transition-timing-function: ease; `}
    }
`

const VerticalLine = styled.div<StyleProps>`
    width: 1px;
    height: 35px;
    animation: move 1000ms ease-out infinite;
    background-color: #fff;
    transform-origin: bottom;

    @keyframes move {
        0% {
            transform: translateY(-100%) scale(1, 1);
        }
        50% {
            transform: translateY(0%) scale(1, 1);
        }
        100% {
            transform: translateY(0%) scale(1, 0);
        }
    }
`

const VerticalLineWrappeer = styled.div<StyleProps>`
    height: 35px;
    width: 3px;
    overflow-y: hidden;
`
const Text = styled.p`
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-family: Lato-bold;
    line-height: 20px;
    letter-spacing: 1.12px;
    text-transform: uppercase;
`

const ScrollIndicator = (props: Props) => {
    const [animation, toggleAnimation] = useState(false)

    useEffect(() => {
        setTimeout(() => toggleAnimation(true), 200)
    }, [])

    useEffect(() => {
        let animate = setInterval(() => {
            toggleAnimation(true)
        }, 500)
        if (animation) {
            setTimeout(() => toggleAnimation(false), 800)
        }
        return () => {
            clearInterval(animate)
        }
    }, [animation])

    return (
        <Box $isAnimationActive={animation} $size={props.size}>
            <Text>SCROLL</Text>
            <VerticalLineWrappeer>
                <VerticalLine $isAnimationActive={animation} />
            </VerticalLineWrappeer>
        </Box>
    )
}

export default ScrollIndicator
