import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const createInsuranceApplication = (): Promise<any> => {
    let api_payload = {}
    let jsonBodyConv: string = JSON.stringify(api_payload)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }

    return fetch(urls.insurance_applications_user_create, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create user insurance application error'
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
