import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import { useRef } from 'react'
// import edit_pen from '../../../public/assets/icons/v2_edit.svg'
import edit_pen from '../../../public/assets/icons/edit_pen.svg'

type StyledProps = {
    $height?: string
    $width?: string
    $colourOne?: string
    $colourTwo?: string
    $isActiveBtn?: boolean
    $isNotTheme?: boolean
}

const MainButton = styled.button<StyledProps>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    border-radius: 5px;
    height: ${(props) => (props.$height ? props.$height : '30px')};
    width: ${(props) => (props.$width ? props.$width : '100%')};
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--bg-color, #fff);
    cursor: pointer;
    border: ${(props) => (props.$colourTwo ? 'solid 1px #5EC3CA31' : 'none')};
    color: #1f1f1f;
    transition: all 200ms;

    background-color: ${(props) =>
        !props.$isActiveBtn ? 'inherit' : 'var(--primary_04)'};

    :hover {
        transition: all 200ms;
        color: black;
    }

    @media ${device.mobile_and_ipad} {
        padding-left: 20px;
    }
`

const BtnWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
`
const Text = styled.div<StyledProps>`
    color: var(--text-strong, #1a1a1a);
    text-transform: uppercase;
    font-size: 12px;
    font-family: Lato;
    padding-left: 8px;
    line-height: 16px;
    text-align: left;

    @media (min-width: 881px) and (max-width: 1200px) {
        ${(props) => props.$isNotTheme && 'max-width: 60%;'};
    }
    @media ${device.mobile_and_ipad} {
        text-transform: capitalize;
        font-size: 15px;
        padding-left: 15px;
    }
`
const ColourOne = styled.div<StyledProps>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.$colourOne ? props.$colourOne : 'var(--text-strong, #1a1a1a)'};

    border: ${(props) =>
        props.$isNotTheme === true
            ? 'solid 1px var(--primary_20)'
            : 'solid 1px var(--border-muted, #e5e5e5)'};

    :hover {
        border: solid 2px var(--primary_80);
        transform: scale(1.1);
        transition: all 200ms;
    }
`

const ColourTwo = styled.div<StyledProps>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.$colourTwo ? props.$colourTwo : 'var(--bg-color, #fff)'};
`

const EditIcon = styled.img`
    width: 15px;
    height: 15px;
    transform: translate(7px, -9px);
    @media ${device.mobile_and_ipad} {
        transform: translate(7px, -7px);
    }
`

type Props = {
    colourOne: string
    colourTwo?: string
    onClick?: any
    dataCyId?: string | undefined
    txt: string
    height?: string
    width?: string
    isActiveBtn?: boolean
    isNotTheme?: boolean
    index?: number
    hasEditIcon?: boolean
}

const QrCodeStickerThemeBtn: React.FunctionComponent<Props> = (props) => {
    let {
        onClick,
        txt,
        dataCyId,
        height,
        width,
        colourOne,
        colourTwo,
        isNotTheme,
        isActiveBtn,
        hasEditIcon,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    return (
        <BtnWrapper ref={ref}>
            <motion.div whileTap={{ scale: 1.05 }} style={{ width: '100%' }}>
                <MainButton
                    $height={height}
                    $width={width}
                    onClick={() => {
                        onClick()
                    }}
                    data-attr={dataCyId ? dataCyId : undefined}
                    $colourTwo={colourTwo}
                    $isActiveBtn={isActiveBtn}
                    $isNotTheme={isNotTheme ? true : false}
                >
                    <ColourOne
                        $isNotTheme={isNotTheme ? true : false}
                        $colourOne={colourOne}
                        $isActiveBtn={isActiveBtn}
                    >
                        {colourTwo && <ColourTwo $colourTwo={colourTwo} />}
                    </ColourOne>
                    <Text $isNotTheme={isNotTheme ? true : false}>{txt}</Text>
                    {hasEditIcon && <EditIcon src={edit_pen} alt="edit" />}
                </MainButton>
            </motion.div>
        </BtnWrapper>
    )
}

export default QrCodeStickerThemeBtn
