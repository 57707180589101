import { call, fork, put, take, select } from 'redux-saga/effects'
import * as actions from '../actions'

import { api } from '../../../services'

import { IGarage, IGarageObject, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    IPublicShareAPIRes,
    IUrlShortenerAPI_request_args,
    IUrlShortenerAPI_res,
} from 'ApiInterfaces'
import { IPublicShareSuccessPayloadRedux } from '../../garages/actions/actions'
import { ConvertPublicShares } from '../../../conversions/shares/convertPublicShares'
import {
    getGarageByGarageId,
    getUserLoggedInFromState,
} from '../../garages/sagas/garage'
import { IUser } from 'myModels'
import { RootState } from 'typesafe-actions'
import { base_url } from '../../../services/urls'

export const state_select_garages = (state: RootState) =>
    state.entities.garagesData.garages
export const state_select_user = (state: RootState) => state.user.userLoggedIn

export function* GetPublicCarShareSaga(carid: string) {
    try {
        let carPublicSharesAPIP: IPublicShareAPIRes = yield call(
            api.entities.getData.getCarPublicSharesAPI,
            carid
        )

        let convertedObj: IPublicShareSuccessPayloadRedux = {
            id: carid,
            public_share: {},
        }

        if (
            carPublicSharesAPIP &&
            carPublicSharesAPIP[0] &&
            carPublicSharesAPIP[0].link_identifier
        ) {
            let args: IUrlShortenerAPI_request_args = {
                url: `car/${carPublicSharesAPIP[0].link_identifier}/overview`,
            }
            let code_s: IUrlShortenerAPI_res = yield call(
                api.entities.updateCar.createUrlShortener,
                args
            )

            let item = carPublicSharesAPIP[0]
            carPublicSharesAPIP = [item]

            let obj: IPublicShareSuccessPayloadRedux = ConvertPublicShares({
                id: carid,
                entity: 'car',
                api_payload: carPublicSharesAPIP,
            })

            convertedObj.public_share = {
                ...obj.public_share,
                qr_code_url: `${base_url}/s/${code_s.code}`,
            }
        }

        let userLoggedIn: IUser = yield select(state_select_user)
        if (userLoggedIn.owns_garage && userLoggedIn.owns_garage.uid) {
            let garages: IGarageObject = yield select(state_select_garages)

            if (
                garages[userLoggedIn.owns_garage.uid] &&
                garages[userLoggedIn.owns_garage.uid].public_share_owned &&
                garages[userLoggedIn.owns_garage.uid].public_share_owned!
                    .link_identifier
            ) {
                let argsg: IUrlShortenerAPI_request_args = {
                    url: `garage/${
                        garages[userLoggedIn.owns_garage.uid]
                            .public_share_owned!.link_identifier
                    }/car/${carid}/overview`,
                }
                let code_g: IUrlShortenerAPI_res = yield call(
                    api.entities.updateCar.createUrlShortener,
                    argsg
                )

                convertedObj.public_share.qr_code_url_via_garage = `${base_url}/s/${code_g.code}`
            }
        }
        yield put(actions.actions.getPublicSharesCarSuccess(convertedObj))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetPublicCarShareSaga,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(actions.errorActions.getPublicSharesCarError(typedError))
            return
        }
    }
}

function* watcherGetPublicCarShare() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.getPublicSharesCarRequest
        )
        yield call(GetPublicCarShareSaga, payload)
    }
}

let getGaragesData = (state: RootState) => state.entities.garagesData.garages

function* GetPublicAllGarageCarsShareSaga(userid: string) {
    let garagesData: IGarageObject = yield select(getGaragesData)
    let userLoggedIn: IUser | null = yield select(getUserLoggedInFromState)

    if (
        userLoggedIn &&
        userLoggedIn.owns_garage &&
        userLoggedIn.owns_garage.uid &&
        garagesData
    ) {
        let garage: IGarage | undefined =
            garagesData[userLoggedIn.owns_garage.uid]

        let garageCars: string[] | undefined = garage && garage.cars

        if (!garage || !garageCars) {
            yield call(getGarageByGarageId, userLoggedIn.owns_garage.uid)
        }

        let garagesData2: IGarageObject = yield select(getGaragesData)
        let garage2: IGarage | undefined = garagesData2[
            userLoggedIn.owns_garage.uid
        ]
            ? garagesData2[userLoggedIn.owns_garage.uid]
            : undefined

        let garage_cars = garage2 && garage2.cars ? garage2.cars : []

        for (const carid of garage_cars) {
            yield call(GetPublicCarShareSaga, carid)
        }

        yield put(actions.actions.getPublicSharesAllGarageCarsSuccess())
    } else {
        // will just keep going on and wont get in a loading state eternally
        yield put(actions.actions.getPublicSharesAllGarageCarsSuccess())
    }
}

function* watcherGetPublicAllGarageCarsShare() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getPublicSharesAllUserGarageCarsRequest
        )
        yield call(GetPublicAllGarageCarsShareSaga, payload)
    }
}

const public_car_shares_sagas: any[] = [
    fork(watcherGetPublicCarShare),
    fork(watcherGetPublicAllGarageCarsShare),
]

export default public_car_shares_sagas
