import { IJourneyPastCarsStepID } from 'journeyInterfaces'
import { IUser } from 'myModels'
import { useHistory } from 'react-router'
import HowItWorksCarJourneyStep from '../howItWorks'
import MemoriesPastCarsJourneyStep from '../memories'
import JourneyPastCarsWelcome from '../welcome'
import ArchivingCarJourneyStep from '../mobileSteps/archiving'
import LegacyJourneyStep from '../mobileSteps/legacy'
import RevisitingCarJourneyStep from '../mobileSteps/revisiting'
// import { PastCarsAchievedJourney } from '../achieved'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'

type Props = {
    stepID: IJourneyPastCarsStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
    isMobile: boolean
}
export default function PastCarsJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    isMobile,
    user,
}: Props) {
    const history = useHistory()
    let locationState: any = history.location.state

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyPastCarsWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )

        case 'memories':
            if (isMobile) {
                return (
                    <MemoriesPastCarsJourneyStep
                        step={1}
                        totalSteps={isMobile ? 5 : 2}
                        goToPrevStep={() => {
                            locationState?.prevPath
                                ? history.push(locationState?.prevPath)
                                : history.push(
                                      `/journey/previously-owned?step=welcome`
                                  )
                        }}
                        goToNextStep={() => {
                            history.push({
                                pathname: `/journey/previously-owned`,
                                search: `?step=archives`,
                                state: {
                                    prevPath: `${history.location.pathname}${
                                        history.location.search ?? ''
                                    }`,
                                },
                            })
                        }}
                    />
                )
            } else
                return (
                    <MemoriesPastCarsJourneyStep
                        step={1}
                        totalSteps={isMobile ? 5 : 2}
                        goToPrevStep={() => {
                            locationState?.prevPath
                                ? history.push(locationState?.prevPath)
                                : history.push(
                                      `/journey/previously-owned?step=welcome`
                                  )
                        }}
                        goToNextStep={() => {
                            history.push({
                                pathname: `/journey/previously-owned`,
                                search: `?step=how_it_works`,
                                state: {
                                    prevPath: `${history.location.pathname}${
                                        history.location.search ?? ''
                                    }`,
                                },
                            })
                        }}
                    />
                )

        case 'how_it_works':
            return (
                <HowItWorksCarJourneyStep
                    step={5}
                    totalSteps={isMobile ? 5 : 2}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/previously-owned?step=${
                                      isMobile ? 'legacy' : 'memories'
                                  }`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/previously-owned`,
                            search: `?step=achieved`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'archives':
            return (
                <ArchivingCarJourneyStep
                    step={2}
                    totalSteps={isMobile ? 5 : 2}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/previously-owned?step=${
                                      isMobile ? 'memories' : 'how_it_works'
                                  }`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/previously-owned`,
                            search: `?step=revisit`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'revisit':
            return (
                <RevisitingCarJourneyStep
                    step={3}
                    totalSteps={isMobile ? 5 : 2}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/previously-owned?step=${
                                      isMobile ? 'archives' : 'how_it_works'
                                  }`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/previously-owned`,
                            search: `?step=legacy`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'legacy':
            return (
                <LegacyJourneyStep
                    step={4}
                    totalSteps={isMobile ? 5 : 2}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/previously-owned?step=revisit`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/previously-owned`,
                            search: `?step=how_it_works`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'achieved':
            return <JourneyAchievedStep sectionID="past_cars" />

        default:
            return (
                <JourneyPastCarsWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
