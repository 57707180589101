import { RootState } from 'typesafe-actions'
import { IUploadImagesPreSignedUrlsPayload } from 'IapiDataPayload'
import * as React from 'react'
import AddUserProfileImageFormDesktop from '../addUserProfileImageForm/addUserProfileImageFormDesktop'
import { connect } from 'react-redux'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'

let axios = require('axios')

function mapStateToProps(state: RootState) {
    return {
        presignedurls: state.fileStorage.presignedurls,
    }
}

interface Props {
    close: any
    presignedurls?: IUploadImagesPreSignedUrlsPayload
    user_id: string
}

type State = {
    imageUploadedData: any | null
    progressPercent: number | null
    changeInPresignedURL: boolean
    presignedUrlsState: any
    file: File | undefined
    [key: string]: any
    isSomethingBeingUploaded: boolean
    isImageCropperShown: boolean
}

class WrapperUserProfileImageDesktop extends React.Component<Props, State> {
    state = {
        imageUploadedData: null,
        isImageCropperShown: false,
        progressPercent: null,
        changeInPresignedURL: false,
        presignedUrlsState: undefined,
        file: undefined,
        isSomethingBeingUploaded: true,
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let urls = this.props.presignedurls

        if (urls !== prevProps.presignedurls) {
            if (urls !== null && urls !== undefined) {
                if (urls[0]) {
                    let stateFile: File | undefined = this.state.file

                    stateFile !== undefined &&
                        this.upload_presignedurl_image_to_s3(stateFile)

                    this.setState({
                        presignedUrlsState: this.props.presignedurls,
                        changeInPresignedURL: !this.state.changeInPresignedURL,
                    })
                }
            }
        }
        return
    }

    // DIRECT SETSTATE

    setImageUploaded = (imgUploadedData: any[]) => {
        this.setState({ imageUploadedData: imgUploadedData })
    }

    deleteImageUploaded = () => {
        this.setState({ file: undefined })
        this.setState({ progressPercent: null })
    }

    toggleShowImageCropper = (res?: boolean) => {
        this.setState({
            isImageCropperShown: res ? res : !this.state.isImageCropperShown,
        })
    }
    // OTHER FUNCS

    upload_presignedurl_image_to_s3 = async (files: File) => {
        let stateFile = this.state.file

        if (stateFile !== null && stateFile !== undefined) {
            if (
                stateFile !== undefined &&
                this.props.presignedurls !== undefined &&
                this.props.presignedurls &&
                this.props.presignedurls[0]
            ) {
                return await axios
                    .request({
                        method: 'put',
                        url: this.props.presignedurls[0].url,
                        data: stateFile,
                        onUploadProgress: (p: any) => {
                            this.setState({
                                progressPercent: (p.loaded / p.total) * 100,
                            })
                        },
                    })
                    .then((data: any) => {
                        this.setState({ isSomethingBeingUploaded: false })
                    })
                    .catch((e: any) => {
                        apiSentryErrorHandler(
                            e,
                            'Upload presigned url user profile image to s3 error'
                        )
                        return Promise.reject(e)
                    })
            }
        }

        // };
    }

    handleFilesGivenInWrapperComponent = (file: File | undefined) => {
        return this.setState({ file: file })
    }

    render() {
        const {
            close,

            user_id,
        } = this.props

        const { file, isSomethingBeingUploaded, isImageCropperShown } =
            this.state

        return (
            <div>
                <AddUserProfileImageFormDesktop
                    close={close}
                    deleteImageUploaded={this.deleteImageUploaded}
                    setImageUploaded={this.handleFilesGivenInWrapperComponent}
                    currentImagesUploaded={file}
                    progressPercent={this.state.progressPercent}
                    isSomethingBeingUploaded={isSomethingBeingUploaded}
                    toggleShowImageCropper={this.toggleShowImageCropper}
                    isImageCropperShown={isImageCropperShown}
                    user_id={user_id}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, {})(WrapperUserProfileImageDesktop)
