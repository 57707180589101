import registrationquestionslist from './index'
import { UserCategory } from 'myInterfaces'

export type IUserCategoriesState = UserCategory[]

const initialState = registrationquestionslist

export const registrationquestions = (
    state: IUserCategoriesState = initialState
) => state

export default registrationquestions

export type RegistrationQuestionsState = ReturnType<
    typeof registrationquestions
>
