import { IHighlightedFactsObject, IHighlightedFact } from 'entityModels'

import {
    GET_HIGHLIGHTED_FACTS_SUCCESS,
    EDIT_HIGHLIGHTED_FACTS_SUCCESS,
    SET_HIGHLIGHTED_FACTS_SUCCESS,
    CLEAN_UP_HIGHLIGHTED_FACTS_SUCCESS,
} from './constants'

import { createAction } from '@reduxjs/toolkit'

// export const getHighlightedFactsSuccess = createAction(
//     GET_HIGHLIGHTED_FACTS_SUCCESS,
//     (resolve) => (payload: IHighlightedFactsObject) =>
//         resolve(payload)
// )

export const getHighlightedFactsSuccess = createAction(
    GET_HIGHLIGHTED_FACTS_SUCCESS,
    function prepare(payload: IHighlightedFactsObject) {
        return {
            payload,
        }
    }
)

// export const setHighlightedFactsSuccess = createAction(
//     SET_HIGHLIGHTED_FACTS_SUCCESS,
//     (resolve) => (payload: IHighlightedFactsObject) =>
//         resolve(payload)
// )

export const setHighlightedFactsSuccess = createAction(
    SET_HIGHLIGHTED_FACTS_SUCCESS,
    function prepare(payload: IHighlightedFactsObject) {
        return {
            payload,
        }
    }
)

// export const updateHighlightedFactsSuccess = createAction(
//     EDIT_HIGHLIGHTED_FACTS_SUCCESS,
//     (resolve) => (payload: IHighlightedFact) => resolve(payload)
// )

export const updateHighlightedFactsSuccess = createAction(
    EDIT_HIGHLIGHTED_FACTS_SUCCESS,
    function prepare(payload: IHighlightedFact) {
        return {
            payload,
        }
    }
)

export const cleanUpHighlightedFactsSuccess = createAction(
    CLEAN_UP_HIGHLIGHTED_FACTS_SUCCESS
)
