import { useEffect, useMemo, useState } from 'react'
import { menuActions } from '../../../../redux/menus/reducer'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import styled from 'styled-components'

import CarEnquiryContactForm, {
    ICarEnquiry_Contact_FormManager_props,
} from '../../../organisms/carEnquiryManagers/carEnquiryContactForm'
import { ITabProps } from 'myInterfaces'
import BtnTabRow from '../../../molecules/tab/btnTabRow'
import CarEnquiryCustomMessageFormMobile, {
    ICarEnquiry_CustomMessage_FormManager_props,
} from '../../../organisms/carEnquiryManagers/carEnquiryCustomMessageFormMobile'
import CarEnquiryInfoForm, {
    ICarEnquiry_Info_FormManager_props,
} from '../../../organisms/carEnquiryManagers/carEnquiryInfoForm'
import CarEnquiryOfferForm, {
    ICarEnquiry_Offer_FormManager_props,
} from '../../../organisms/carEnquiryManagers/carEnquiryOfferForm'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import BottomBarBtnsStep from '../../../atoms/Button/bottomBarBtnsStep'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { IUser } from 'myModels'
import MessageModalDesktop from '../../../templates/displays/pageWrappers/nessageModalDesktop'
import EnquirySuccess from '../../../organisms/carEnquiryManagers/enquirySuccess'
import {
    IEnquiryStructureRequest,
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { useHistory, useLocation, useParams } from 'react-router'
import {
    ICar,
    IGalleryImage,
    IGalleryImagesObject,
    IReduxError,
} from 'entityModels'
import { validateEmail } from '../../../organisms/editForms/account/accountPrivateInfoForm/basic/basicAccountItemInfoFormDesktop'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import { convertShowroomEntryPriceInfoToUIString } from '../../../../helpers/typeConv/showroom'
import { PhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { message } from 'antd'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import { generateImageSources } from '../../../../helpers/images'

const MobilePageWrap = styled.div`
    height: 100vh;
    background-color: 'white';
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
`

const CarTitle = styled.div`
    font-size: 24px;
    font-family: Lato;
`

const FormTopic = styled.div`
    font-size: 14px;
    font-family: Lato-Bold;
    text-transform: uppercase;
    color: #b3b3b3;
`

const CarBgImage = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    z-index: 1;
`
const DesktopPageWrap = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
`
const FormWrapperDesktop = styled.div`
    margin-left: 10vw;
    margin-top: 3vw;

    @media screen and (max-width: 1600px) {
        width: 420px;
    }

    @media screen and (min-width: 1600px) {
        margin-top: 5vw;
    }

    width: 443px;
    min-height: 300px;
    max-height: 90vh;
    background-color: var(--bg-color, #fff);
    z-index: 5;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 16px;
    position: relative;
`

const SubFormWrapperDesktop = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 16px;

    /* width */
    ::-webkit-scrollbar {
        width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #999;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
`

type IOpacityFormStyleProps = {
    side: 'left' | 'right'
}

const FormWrapperDesktopOpacity = styled.div<IOpacityFormStyleProps>`
    position: absolute;
    top: 6%;
    bottom: 6%;
    left: ${(props: IOpacityFormStyleProps) =>
        props.side === 'left' ? '-40px' : '100%'};
    right: ${(props: IOpacityFormStyleProps) =>
        props.side === 'right' ? '-40px' : '100%'};
    height: 88%;
    width: 40px;
    background-color: var(--bg-color, #fff);
    border-top-right-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'right' ? '16px' : '0px'};
    border-bottom-right-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'right' ? '16px' : '0px'};
    border-top-left-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'left' ? '16px' : '0px'};
    border-bottom-left-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'left' ? '16px' : '0px'};
    opacity: 0.3;
    transition: all 200ms cubic-bezier(0.25, 0.25, 0, 1);
`

type EntryParams = {
    entryid: string
}

const CarEnquiry: React.FunctionComponent<any> = (props: any) => {
    const { entryid } = useParams<EntryParams>()

    const { search } = useLocation()

    let queryParams = new URLSearchParams(search)

    const [activeTab, setActiveTab] = useState<'info' | 'offer'>('info')
    const [activeStep, setActiveStep] = useState<
        'enquiry' | 'contact' | 'success'
    >('enquiry')

    let dial_code: string = useAppSelector((state) => {
        return state.editForms.user_dial_code
    })

    let userLoggedIn: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let error: IReduxError | null = useAppSelector((state) => {
        return state.showroom.error
    })

    let normalisedPublicEntries: INormalisedShowroomEntries | undefined =
        useAppSelector((state) => {
            return state.showroom.normalisedPublicEntries
        })

    let currentEntry: IShowroomEntry | undefined =
        entryid && normalisedPublicEntries && normalisedPublicEntries[entryid]
            ? normalisedPublicEntries[entryid]
            : undefined

    let set_price: string | undefined = currentEntry
        ? convertShowroomEntryPriceInfoToUIString(currentEntry).listing_price
        : undefined

    useEffect(() => {
        if (!currentEntry) {
            dispatch(showroomActions.getPublicShowroomEntryByIDRequest(entryid))
        }
    }, [])

    let carProfile: ICar | undefined = currentEntry
        ? currentEntry.car
        : undefined

    let empty_base: IEnquiryStructureRequest = useMemo(() => {
        return {
            entry_id: entryid,
            enquiry: {
                type: 'info',
                info: {
                    selected_enquiry: [],
                    custom_message: undefined,
                },
                offer: {
                    selected_offer: undefined,
                    personal_offer_amount: undefined,
                    custom_message: undefined,
                    currency: currentEntry?.listing_currency ?? 'GBP',
                },
            },
            contact: {
                details: {
                    email: userLoggedIn?.email,
                    name: !userLoggedIn
                        ? undefined
                        : `${
                              userLoggedIn?.given_name
                                  ? userLoggedIn?.given_name
                                  : ''
                          } ${
                              userLoggedIn?.family_name
                                  ? userLoggedIn?.family_name
                                  : ''
                          }`,
                    phone: undefined,
                },
                medium: [],
            },
        }
    }, [userLoggedIn])

    const [enquiryFormData, setEnquiryFormData] =
        useState<IEnquiryStructureRequest>(empty_base)

    useEffect(() => {
        setEnquiryFormData(empty_base)
        if (queryParams.get('tab') === 'offer') {
            setActiveTab('offer')
            tabs[1].onClick(1)
        }

        if (queryParams.get('tab') === 'info') {
            setActiveTab('info')
        }
    }, [userLoggedIn, empty_base])

    const [isEditorOpen, setIsEditorOpen] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        // trigger the function to dispatch the top sub menu remove action
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        // set up nav back as before on page unmount
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const handleContactDetailsChange = (
        id: string, //'email' | 'name' | 'phone',
        value: string | number | undefined
    ) => {
        let data = { ...enquiryFormData }
        if (id === 'email' || id === 'name' || id === 'phone') {
            data.contact.details[id] = value
            setEnquiryFormData({
                ...data,
            })
        }
    }

    // we already know which id should be effective in the state,
    // no need to worry about arguments further down reusable components
    const handleCustomMessageChange = (value: string | undefined) => {
        let data = { ...enquiryFormData }

        if (activeTab === 'info' && data.enquiry.info) {
            data.enquiry.info.custom_message = value
        } else if (activeTab === 'offer' && data.enquiry.offer) {
            data.enquiry.offer.custom_message = value
        }

        setEnquiryFormData({
            ...data,
        })
    }

    // we already know which id should be effective in the state,
    // no need to worry about arguments further down reusable components
    let handleSelectedIDChange = (selectedID: string) => {
        let data = { ...enquiryFormData }
        if (activeStep === 'contact') {
            let index: number = data.contact.medium.indexOf(selectedID)

            if (index === -1) {
                data.contact.medium = [...data.contact.medium, selectedID]
            } else {
                data.contact.medium = enquiryFormData.contact.medium.filter(
                    (item: string) => item !== selectedID
                )
            }
        } else if (
            activeStep === 'enquiry' &&
            activeTab === 'info' &&
            data.enquiry.info
        ) {
            let index: number =
                data.enquiry.info.selected_enquiry.indexOf(selectedID)

            if (index === -1) {
                data.enquiry.info.selected_enquiry = [
                    ...data.enquiry.info.selected_enquiry,
                    selectedID,
                ]
            } else {
                data.enquiry.info.selected_enquiry =
                    enquiryFormData.enquiry.info!.selected_enquiry.filter(
                        (item: string) => item !== selectedID
                    )
            }
        }

        setEnquiryFormData({
            ...data,
        })
    }

    const handleOfferChange = (
        id: string, //'asking_price_offer' | 'personal_offer_amount',
        amount?: number
    ) => {
        let data = { ...enquiryFormData }

        if (
            data.enquiry.offer &&
            (id === 'asking_price_offer' || id === 'personal_offer_amount')
        ) {
            data.enquiry.offer.selected_offer = id
            if (id === 'personal_offer_amount') {
                data.enquiry.offer.personal_offer_amount = amount
            } else if (id === 'asking_price_offer') {
                data.enquiry.offer.personal_offer_amount =
                    currentEntry?.listing_price_max ?? undefined
            }

            setEnquiryFormData({
                ...data,
            })
        }
    }

    const props_infoForm: ICarEnquiry_Info_FormManager_props = {
        handleSelectedIDChange,
        setIsEditorOpen,
        manageStep: setActiveStep,
        enquiryFormData,
    }

    const props_offerForm: ICarEnquiry_Offer_FormManager_props = {
        handleOfferChange,
        setIsEditorOpen,
        enquiryFormData,
        manageStep: setActiveStep,
        set_price: set_price,
        listing_currency: currentEntry?.listing_currency ?? 'GBP',
    }

    let getPhoneNumber: () => Promise<PhoneNumber | undefined> = async () => {
        try {
            let numberr = parsePhoneNumber(
                `${`${dial_code}` + enquiryFormData.contact.details.phone}`
            )

            if (numberr.isValid()) {
                return numberr
            } else {
                message.error({
                    content: `Invalid Phone Number`,
                })
                return undefined
            }
        } catch (e: any) {
            // setHasInvalidN(true)
            message.error({
                content: `Invalid Phone Number`,
            })
            return undefined
        }
    }

    const props_contactForm: ICarEnquiry_Contact_FormManager_props = {
        handleContactDetailsChange,
        handleSelectedIDChange,
        manageStep: setActiveStep,
        enquiryFormData,
        onSubmit: async () => {
            if (
                enquiryFormData.contact.details.phone &&
                `${enquiryFormData.contact.details.phone}`?.length > 0 &&
                enquiryFormData.contact.medium.includes('phone')
            ) {
                let phoneNumber: PhoneNumber | undefined =
                    await getPhoneNumber()
                if (
                    phoneNumber &&
                    phoneNumber.isValid &&
                    phoneNumber.isValid()
                ) {
                    let data = { ...enquiryFormData }

                    data.contact.details.phone =
                        dial_code + enquiryFormData.contact.details.phone
                    dispatch(
                        showroomActions.showroomEnquiryByEntryIdRequest({
                            ...data,
                        })
                    )

                    setTimeout(() => {
                        if (!error) {
                            setActiveStep('success')
                        }
                    }, 100)
                } else {
                    return
                }
            } else {
                dispatch(
                    showroomActions.showroomEnquiryByEntryIdRequest(
                        enquiryFormData
                    )
                )
                setTimeout(() => {
                    if (!error) {
                        setActiveStep('success')
                    }
                }, 100)
            }
        },
    }

    let renderForm = () => {
        if (activeStep === 'success') {
            return <EnquirySuccess entryid={entryid} />
        } else if (activeStep === 'contact') {
            return <CarEnquiryContactForm {...props_contactForm} />
        } else if (activeStep === 'enquiry')
            if (activeTab === 'info') {
                return <CarEnquiryInfoForm {...props_infoForm} />
            } else if (activeTab === 'offer') {
                return <CarEnquiryOfferForm {...props_offerForm} />
            }
    }

    let tabs: ITabProps[] = [
        {
            id: 0,
            title: 'Make enquiry',
            onClick: (arg: number) => {
                setActiveTab('info')
                setEnquiryFormData((prevState) => {
                    return {
                        ...prevState,
                        enquiry: {
                            ...prevState.enquiry,
                            type: 'info',
                            offer: {
                                currency:
                                    prevState.enquiry.offer?.currency ?? 'GBP',
                                selected_offer: undefined,
                                personal_offer_amount: undefined,
                                custom_message: undefined,
                            },
                        },
                    }
                })
            },
            isActive: activeTab === 'info' ? true : false,
        },
        {
            id: 1,
            title: 'Make an offer',
            onClick: (arg: number) => {
                setActiveTab('offer')
                setEnquiryFormData((prevState) => {
                    return {
                        ...prevState,
                        enquiry: {
                            ...prevState.enquiry,
                            type: 'offer',
                            info: {
                                selected_enquiry: [],
                                custom_message: undefined,
                            },
                        },
                    }
                })
            },
            isActive: activeTab === 'offer' ? true : false,
            isDisabled:
                currentEntry?.type_of_sale === 'PRICE_ON_ASKING' ? true : false,
            disabledInfo: 'Price on asking (POA)',
        },
    ]

    let customMessageProps: ICarEnquiry_CustomMessage_FormManager_props = {
        handleCustomMessageChange,
        setIsEditorOpen,
        initialMessage:
            activeTab === 'info'
                ? enquiryFormData.enquiry.info?.custom_message
                : enquiryFormData.enquiry.offer?.custom_message,
    }

    const checkIfEnquiryBtnIsDisabled = () => {
        if (
            activeStep === 'enquiry' &&
            enquiryFormData.enquiry.type === 'offer' &&
            enquiryFormData.enquiry.offer &&
            enquiryFormData.enquiry.offer.selected_offer === undefined &&
            enquiryFormData.enquiry.offer.personal_offer_amount === undefined
        ) {
            return true
        }
        if (
            activeStep === 'enquiry' &&
            enquiryFormData.enquiry.type === 'info' &&
            enquiryFormData.enquiry.info &&
            enquiryFormData.enquiry.info.selected_enquiry.length === 0
        ) {
            return true
        }
        return false
    }

    const isContactSubmitDisabled = () => {
        let isEmailPassingChecks =
            (enquiryFormData.contact.medium.includes('email') &&
                enquiryFormData.contact.details.email === '') ||
            !enquiryFormData.contact.details.email
                ? false
                : true

        if (
            isEmailPassingChecks === true &&
            !validateEmail(enquiryFormData.contact.details.email)
        ) {
            isEmailPassingChecks = false
        }

        let isPhonePassingChecks =
            (enquiryFormData.contact.medium.includes('phone') ||
                enquiryFormData.contact.medium.includes('message')) &&
            (enquiryFormData.contact.details.phone === undefined || // @ts-ignore
                enquiryFormData.contact.details.phone?.length === 0)
                ? false
                : true

        let isDetailPassingChecks =
            !enquiryFormData.contact.details.name ||
            (enquiryFormData.contact.details.name &&
                // @ts-ignore
                enquiryFormData.contact.details.name.length < 1)
                ? false
                : true

        let isMediumPassingChecks =
            enquiryFormData.contact.medium.length === 0 ? false : true

        if (
            isDetailPassingChecks == true &&
            isEmailPassingChecks === true &&
            isPhonePassingChecks === true &&
            isMediumPassingChecks === true
        ) {
            return false
        }
        return true
    }

    const history = useHistory()
    // let refresh_token: string | null =
    //     typeof localStorage !== 'undefined'
    //         ? localStorage.getItem('refresh_token')
    //         : null

    useEffect(() => {
        if (
            !userLoggedIn
            // && refresh_token !== null
        ) {
            dispatch(getCurrentUserDataRequest())
        }
    }, [])

    let renderBtnsDesktop = () => {
        if (activeStep === 'enquiry') {
            return (
                <BottomBarBtnsStep
                    cancel={() => {
                        history.push(`/showroom/${entryid}`)
                    }}
                    submit={() => {
                        setActiveStep('contact')
                    }}
                    confirmationtext="continue"
                    disabled={checkIfEnquiryBtnIsDisabled()}
                />
            )
        }
        if (activeStep === 'contact') {
            return (
                <BottomBarBtnsStep
                    cancel={() => {
                        history.push(`/showroom/${entryid}`)
                    }}
                    submit={async () => {
                        props_contactForm.onSubmit()

                        // dispatch(
                        //     showroomActions.showroomEnquiryByEntryIdRequest(
                        //         enquiryFormData
                        //     )
                        // )
                        // setTimeout(() => {
                        //     if (!error) {
                        //         setActiveStep('success')
                        //     }
                        // }, 100)
                    }}
                    confirmationtext="send enquiry"
                    disabled={isContactSubmitDisabled()}
                    hasGoBack={true}
                    onGoback={() => {
                        setActiveStep('enquiry')
                    }}
                />
            )
        }
    }

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let car_profile_image: IGalleryImage | undefined =
        currentEntry && currentEntry.cover_image
            ? currentEntry.cover_image
            : currentEntry &&
              currentEntry.car &&
              currentEntry.car.gallery.cover &&
              images_data &&
              images_data[currentEntry.car.gallery.cover]
            ? images_data[currentEntry.car.gallery.cover]
            : undefined

    let { width } = useWindowSize()

    return (
        <div>
            <DesktopDisplayOnly>
                {isEditorOpen === true && activeStep !== 'success' && (
                    <MessageModalDesktop
                        onClose={() => {
                            setIsEditorOpen(false)
                        }}
                        onSubmit={(text: string) => {
                            handleCustomMessageChange(text)
                            setIsEditorOpen(false)
                        }}
                        activeForm={{
                            id: 'message',
                            name: 'Write custom message',
                            value: customMessageProps.initialMessage,
                        }}
                    />
                )}
                <DesktopPageWrap>
                    <FormWrapperDesktop>
                        <SubFormWrapperDesktop>
                            {activeStep !== 'success' ? (
                                <>
                                    <div style={{ paddingTop: '40px' }} />
                                    <FormStepProgress
                                        totalSteps={2}
                                        currentStep={
                                            activeStep === 'enquiry' ? 1 : 2
                                        }
                                        screen_name="desktop"
                                    />
                                    <div style={{ paddingTop: '40px' }} />

                                    <CarTitle>{carProfile?.title}</CarTitle>
                                    <div style={{ paddingTop: '5px' }} />
                                    <FormTopic>vehicle enquiry</FormTopic>

                                    {activeStep === 'enquiry' ? (
                                        <div
                                            style={{
                                                paddingTop: '40px',
                                                width: '100%',
                                            }}
                                        >
                                            <BtnTabRow tabs={tabs} />
                                        </div>
                                    ) : null}

                                    <div style={{ paddingTop: '25px' }} />
                                </>
                            ) : null}

                            {renderForm()}
                        </SubFormWrapperDesktop>

                        {renderBtnsDesktop()}

                        <FormWrapperDesktopOpacity
                            side={
                                activeStep === 'enquiry'
                                    ? 'right'
                                    : activeStep === 'success'
                                    ? 'right'
                                    : 'left'
                            }
                        />
                    </FormWrapperDesktop>
                    <CarBgImage
                        src={car_profile_image?.url}
                        srcSet={
                            car_profile_image
                                ? generateImageSources(car_profile_image, width)
                                : undefined
                        }
                    />
                </DesktopPageWrap>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <MobilePageWrap
                    style={{
                        display: isEditorOpen === true ? 'none' : 'flex',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        paddingBottom: '100px',
                    }}
                >
                    {activeStep !== 'success' ? (
                        <>
                            <div style={{ paddingTop: '40px' }} />
                            <FormStepProgress
                                totalSteps={2}
                                currentStep={activeStep === 'enquiry' ? 1 : 2}
                                screen_name="mobile"
                            />

                            <div style={{ paddingTop: '48px' }} />

                            <CarTitle>{carProfile?.title}</CarTitle>
                            <div style={{ paddingTop: '5px' }} />
                            <FormTopic>vehicle enquiry</FormTopic>

                            {activeStep === 'enquiry' ? (
                                <div
                                    style={{
                                        paddingTop: '32px',
                                        width: '100%',
                                    }}
                                >
                                    <BtnTabRow tabs={tabs} />
                                </div>
                            ) : null}

                            <div style={{ paddingTop: '25px' }} />
                        </>
                    ) : null}

                    {renderForm()}
                </MobilePageWrap>

                <MobilePageWrap
                    style={{
                        display: isEditorOpen ? 'flex' : 'none',
                    }}
                >
                    <CarEnquiryCustomMessageFormMobile
                        {...customMessageProps}
                    />
                </MobilePageWrap>
            </IpadAndMobileDisplay>
        </div>
    )
}

export default CarEnquiry
