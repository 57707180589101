import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const EyeIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.83868 7.73739C1.77867 7.81876 1.75 7.91011 1.75 7.99922C1.75 8.08832 1.77867 8.17968 1.83868 8.26105C2.31487 8.89645 3.13103 9.85671 4.20435 10.6528C5.27916 11.45 6.56565 12.0492 8 12.0492C9.43435 12.0492 10.7208 11.45 11.7956 10.6528C12.869 9.85669 13.6852 8.8964 14.1614 8.261C14.2213 8.17964 14.25 8.08831 14.25 7.99922C14.25 7.91013 14.2213 7.8188 14.1614 7.73743C13.6852 7.10204 12.869 6.14175 11.7956 5.34563C10.7208 4.54843 9.43435 3.94922 8 3.94922C6.56565 3.94922 5.27916 4.54843 4.20435 5.34563C3.13103 6.14173 2.31487 7.10199 1.83868 7.73739ZM3.31075 4.14086C4.54283 3.22701 6.13726 2.44922 8 2.44922C9.86275 2.44922 11.4572 3.22701 12.6892 4.14086C13.9218 5.05509 14.8381 6.13892 15.3631 6.83974L15.3652 6.84256L15.3652 6.84256C15.6133 7.17707 15.75 7.58087 15.75 7.99922C15.75 8.41756 15.6133 8.82137 15.3652 9.15588L15.3631 9.1587C14.8381 9.85952 13.9218 10.9433 12.6892 11.8576C11.4572 12.7714 9.86275 13.5492 8 13.5492C6.13726 13.5492 4.54283 12.7714 3.31075 11.8576C2.07815 10.9433 1.16194 9.85952 0.636918 9.1587L0.634808 9.15588L0.634814 9.15588C0.386656 8.82137 0.25 8.41757 0.25 7.99922C0.25 7.58087 0.386656 7.17707 0.634814 6.84256L0.636911 6.83974L0.636918 6.83974C1.16194 6.13892 2.07815 5.05509 3.31075 4.14086Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8ZM8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default EyeIcon
