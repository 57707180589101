import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const DriverLicenceIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ? +size * 1.04 : '25'}
            height={size ?? '24'}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_3097_6993)">
                <path
                    d="M21.5 20H3.5C2.96957 20 2.46086 19.8127 2.08579 19.4793C1.71071 19.1459 1.5 18.6937 1.5 18.2222V5.77778C1.5 5.30628 1.71071 4.8541 2.08579 4.5207C2.46086 4.1873 2.96957 4 3.5 4H21.5C22.0304 4 22.5391 4.1873 22.9142 4.5207C23.2893 4.8541 23.5 5.30628 23.5 5.77778V18.2222C23.5 18.6937 23.2893 19.1459 22.9142 19.4793C22.5391 19.8127 22.0304 20 21.5 20Z"
                    stroke={color ?? colours[theme].text_darker}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
                <path
                    d="M13.8774 7.92139L19.9426 7.92139"
                    stroke={color ?? colours[theme].text_darker}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
                <path
                    d="M13.8774 10.4197H17.1091"
                    stroke={color ?? colours[theme].text_darker}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
                <path
                    d="M13.8774 12.9146L19.9426 12.9146"
                    stroke={color ?? colours[theme].text_darker}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
                <path
                    d="M13.8774 16.0786H16.8774"
                    stroke={color ?? colours[theme].text_darker}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
                <path
                    d="M8.34082 13.5071H6.34082C5.23615 13.5071 4.34082 14.3704 4.34082 15.4357V15.7571C4.34082 15.7571 5.38249 16.0785 7.34082 16.0785C9.29915 16.0785 10.3408 15.7571 10.3408 15.7571V15.4357C10.3408 14.3704 9.44549 13.5071 8.34082 13.5071Z"
                    stroke={color ?? colours[theme].text_darker}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
                <path
                    d="M5.62646 9.6363C5.62646 8.68932 6.39412 7.92139 7.34075 7.92139C8.28738 7.92139 9.05504 8.68932 9.05504 9.6363C9.05504 10.5833 8.28738 11.35 7.34075 11.35C6.39412 11.35 5.62646 10.5833 5.62646 9.6363Z"
                    stroke={color ?? colours[theme].text_darker}
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="square"
                />
            </g>
            <defs>
                <clipPath id="clip0_3097_6993">
                    <rect
                        width={size ?? '24'}
                        height={size ?? '24'}
                        fill={colours[theme].background_default}
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DriverLicenceIcon
