/// <reference types="googlemaps" />
import { IUserAddressApi } from 'IapiDataPayload'
import * as React from 'react'
import { connect } from 'react-redux'
import AddressFormDesktop from './addressFormDesktop'
import AddressFormMobile from './addressFormMobile'

type Props = {
    addAddress: any
    isDesktop: boolean
}

type LocalState = {
    street_number: string
    route: string
    postal_town: string
    postal_code: string
    country: string
}

class AddressForm extends React.Component<Props, LocalState> {
    state = {
        street_number: '',
        route: '',
        postal_town: '',
        postal_code: '',
        country: '',
    }

    // STEPS:

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.

    async componentDidMount() {
        let input: any = document.getElementById(
            this.props.isDesktop === true
                ? 'autocompletedesktop'
                : 'autocompletemobile'
        )

        const googleMaps = async () => {
            try {
                let autocomplete = new google.maps.places.Autocomplete(input, {
                    types: ['geocode'],
                })

                autocomplete.setFields(['address_component'])

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (
                        position
                    ) {
                        let geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        let circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy,
                        })
                        autocomplete.setBounds(circle.getBounds())
                    })
                }
                autocomplete.addListener('place_changed', () => {
                    let place: any = autocomplete.getPlace()

                    place.address_components !== undefined &&
                        place.address_components.map((chunk: any) => {
                            if (chunk.types[0] === 'street_number') {
                                return this.setState({
                                    ...this.state,
                                    street_number: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'route') {
                                return this.setState({
                                    ...this.state,
                                    route: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'postal_code') {
                                return this.setState({
                                    ...this.state,
                                    postal_code: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'locality') {
                                return this.setState({
                                    ...this.state,
                                    postal_town: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'postal_town') {
                                return this.setState({
                                    ...this.state,
                                    postal_town: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'country') {
                                return this.setState({
                                    ...this.state,
                                    country: chunk.long_name,
                                })
                            }
                            return false
                        })
                })
            } catch (e) {
                console.error(e)
            } finally {
            }
        }

        googleMaps()
    }

    handleChange: React.ReactEventHandler<HTMLInputElement> = (ev) => {
        let value = ev.currentTarget.value
        let name = ev.currentTarget.name

        if (name === 'postal_code') {
            value = value?.toUpperCase()
        }
        this.setState({
            ...this.state,
            [name]: value,
        })
    }

    isUndefined = (index: any) => {
        return typeof index === 'undefined'
    }

    onSubmit = () => {
        // convert to BE type

        let addressObj: IUserAddressApi = {
            type: 'User Address',
            street_1: `${this.state.street_number} ${this.state.route}`,
            locality: this.state.postal_town,
            postcode: this.state.postal_code,
            country: this.state.country,
        }

        this.props.addAddress(addressObj)
    }

    isFormValid = () => {
        if (
            this.state.street_number.length === 0 &&
            this.state.route.length === 0
        ) {
            return false
        } else if (
            this.state.country.length === 0 &&
            this.state.postal_code.length === 0 &&
            this.state.postal_town.length === 0
        ) {
            return false
        } else return true
    }

    render() {
        const { street_number, route, postal_town, postal_code, country } =
            this.state

        const { isDesktop } = this.props
        return (
            <React.Fragment>
                {isDesktop ? (
                    <AddressFormDesktop
                        street_number={street_number}
                        postal_town={postal_town}
                        route={route}
                        postal_code={postal_code}
                        country={country}
                        onSubmit={this.onSubmit}
                        handleChange={this.handleChange}
                        isFormValid={this.isFormValid}
                    />
                ) : (
                    <AddressFormMobile
                        street_number={street_number}
                        postal_town={postal_town}
                        route={route}
                        postal_code={postal_code}
                        country={country}
                        onSubmit={this.onSubmit}
                        handleChange={this.handleChange}
                        isFormValid={this.isFormValid}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default connect(null, null)(AddressForm)
