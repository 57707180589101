import { IPrismicImg } from 'apexModels'
import { IUpdateUrlQueryParamsProps } from 'urlQueryParamsProps'
import { editUrlQueryParams } from '../../../helpers/urlQueryParams'

export const convertImgPrismicWithCompression = (
    img: IPrismicImg
): IPrismicImg | undefined => {
    if (img && img.url) {
        let img_props: IUpdateUrlQueryParamsProps = {
            url: img.url,
            queriesToReplace: ['w'],
            valuesToMapToQueries: ['900'],
        }

        let new_url: string = editUrlQueryParams(img_props)

        return { ...img, url: new_url }
    } else return undefined
}

export const convertImgPrismicWithCustomCompression = (
    img: IPrismicImg,
    customPx: string
): IPrismicImg | undefined => {
    if (img && img.url) {
        let img_props: IUpdateUrlQueryParamsProps = {
            url: img.url,
            queriesToReplace: ['w'],
            valuesToMapToQueries: [customPx],
        }

        let new_url: string = editUrlQueryParams(img_props)

        return { ...img, url: new_url }
    } else return undefined
}
