import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import {
    Link,
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router-dom'
import {
    IEntityPrivateOwnedSharesState,
    IDirectShareOwnedItem,
    IGarage,
    ISearchUsersResult,
    IUpdateGarageDirectShareRequest,
} from 'entityModels'
import { IUser } from 'myModels'
import PrivateShareSearchFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareSearchFormMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import {
    addGarageShareRecipientRequest,
    getGarageByUserIdRequest,
    getSingleGarageDirectShareRequest,
    updateGarageDirectShareRequest,
} from '../../../redux/entities/garages/actions/loadingActions'
import { searchPlatformUsersRequest } from '../../../redux/searchUsers/actions/loadingActions'
import {
    IShareRecipientRequest_redux,
    IgetSingleDirectShareRequest_redux,
} from '../../../redux/entities/cars/actions/loadingActions'
import ReturnNav from '../../atoms/header/returnNav'
import SideSlider from '../../templates/animated/sideSlider'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { editFormsActions } from '../../../redux/editForms/reducer'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        garage_id:
            state.user.userLoggedIn && state.user.userLoggedIn.owns_garage?.uid,
        isGarageLoading: state.entities.garagesData.loading,
        garageSharesLoading: state.entities.garagesData.sharesLoading,
        garageData: state.entities.garagesData.garages,
        isUserLoading: state.user.loading,
        searchResults: state.searchUsers.list,
        searchResultsLoading: state.searchUsers.loading,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
    toggleAddShareRecipientByEmail: (payload: { isOpen: boolean }) =>
        editFormsActions.toggleAddShareRecipientByEmail(payload),
    searchPlatformUsersRequest: (value: string | undefined) =>
        searchPlatformUsersRequest(value),
    addGarageShareRecipientRequest: (p: IShareRecipientRequest_redux) =>
        addGarageShareRecipientRequest(p),
    getSingleGarageDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => getSingleGarageDirectShareRequest(p),
    updateGarageDirectShareRequest: (p: IUpdateGarageDirectShareRequest) =>
        updateGarageDirectShareRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    garage_id: string | null
    garageData: { [key: string]: IGarage }
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isGarageLoading: boolean
    getUserDataRequest: () => void
    getGarageByUserIdRequest: () => void
    searchPlatformUsersRequest: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    searchResultsLoading: boolean
    addGarageShareRecipientRequest: (p: IShareRecipientRequest_redux) => void
    getSingleGarageDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => void
    updateGarageDirectShareRequest: (p: IUpdateGarageDirectShareRequest) => void
    garageSharesLoading: boolean
    setInitialLandingUrl: (str: string) => void
}

type State = {
    share_being_edited: IDirectShareOwnedItem
    isDesktop: boolean
}

class GarageShareEdit extends React.Component<Props, State> {
    state: State = {
        share_being_edited: {
            title: '',
            entity_id: '',
            active_permission_ids: [] as any,
            id: 'share_being_edited',
            type: 'garage',
            created_at: '', // now
            updated_at: '', // now
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            owner: {
                uid: 'useruid',
                display_name: 'noname',
                location: 'none',
            },
            total_recipients: 0,
        },
        isDesktop: false,
    }

    _isMounted = false

    handleResize = () =>
        window.innerWidth > 881
            ? this.setState({ isDesktop: true })
            : this.setState({ isDesktop: false })

    setupLocalStateData = () => {
        let garageid = this.props.garage_id ? this.props.garage_id : ''
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        const garage_shares: IEntityPrivateOwnedSharesState | undefined =
            garageid &&
            this.props.garageData &&
            this.props.garageData[garageid] &&
            this.props.garageData[garageid].private_shares_owned
                ? this.props.garageData[garageid].private_shares_owned
                : undefined

        if (
            garage_shares &&
            garage_shares.entity_owned_direct_shares_data &&
            shareid &&
            garage_shares.entity_owned_direct_shares_data[shareid]
        ) {
            const share: IDirectShareOwnedItem =
                garage_shares.entity_owned_direct_shares_data[shareid]

            this.setState({
                ...this.state,
                share_being_edited: {
                    ...share,
                    active_display: {
                        item_ids_display: share.active_display?.item_ids_display
                            ? share.active_display?.item_ids_display
                            : share.all_share_recipients_ids_list,
                        activeSort: share.active_display?.activeSort
                            ? share.active_display?.activeSort
                            : 'added_at',
                    },
                },
            })
        }
    }

    componentDidMount() {
        this._isMounted = true

        // user stuff
        let userData = this.props.userLoggedIn
        let garageid = this.props.garage_id ? this.props.garage_id : ''
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        this.props.setInitialLandingUrl(
            `/sharing/with-others/garage/edit-share/recipients?shareid=${shareid}`
        )
        if (!userData) {
            this.props.getUserDataRequest()
        } else {
            if (!this.props.garageData[garageid]) {
                this.props.getGarageByUserIdRequest()
            }

            shareid &&
                this.props.getSingleGarageDirectShareRequest({
                    entity_id: garageid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        window.addEventListener('resize', this.handleResize, { passive: true })

        window.innerWidth > 881 && this.setState({ isDesktop: true })
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let garageid = this.props.garage_id ? this.props.garage_id : ''
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (!this.props.garageData[garageid]) {
                this.props.getGarageByUserIdRequest()
            }

            shareid &&
                this.props.getSingleGarageDirectShareRequest({
                    entity_id: garageid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        if (
            this.props.garageData[garageid] !== prevProps.garageData[garageid]
        ) {
            this.setupLocalStateData()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        this._isMounted = false
    }

    render() {
        let {
            searchPlatformUsersRequest,
            searchResults,
            searchResultsLoading,
            garageSharesLoading,
        } = this.props

        let { share_being_edited } = this.state

        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        return (
            <React.Fragment>
                <DesktopDisplayOnly>
                    {this.state.isDesktop === true && (
                        <Redirect
                            to={`/sharing/with-others/garage/edit-share?shareid=${shareid}`}
                        />
                    )}
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <SideSlider direction="right" initial="enter">
                        <Link
                            to={{
                                pathname: `/sharing/with-others/garage/edit-share`,
                                search: `?shareid=${shareid}`,
                                state: {
                                    prevPath:
                                        this.props.history.location.pathname,
                                },
                            }}
                        >
                            <ReturnNav />
                        </Link>
                        <PrivateShareSearchFormManagerMobile
                            share={share_being_edited}
                            searchUsers={searchPlatformUsersRequest}
                            searchResults={searchResults}
                            resultsLoading={searchResultsLoading}
                            addShareRecipient={
                                this.props.addGarageShareRecipientRequest
                            }
                            selectedUsers={
                                share_being_edited.all_share_recipients_ids_list
                            }
                            sharesLoading={garageSharesLoading}
                            goBack={() =>
                                setTimeout(
                                    () =>
                                        this.props.history.push(
                                            `/sharing/with-others/garage/edit-share?shareid=${shareid}`
                                        ),
                                    500
                                )
                            }
                        />
                    </SideSlider>
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GarageShareEdit)
)
