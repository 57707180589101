import React from "react";
import { connect } from "react-redux";
import MobileLogoHeader from "../templates/logoheader/mobilelogoheader";
import { RootState } from "typesafe-actions";
import DesktopDisplay from "../templates/displays/desktopDisplay";
import MobileDisplay from "../templates/displays/mobileDisplay";
import { IUser } from "myModels";
import DesktopLogoHeader from "../templates/logoheader/desktoplogoheader";
import LogoBg from "../templates/logobg/logobg";

const mapStateToProps = (state: RootState) => ({
  errorUser: state.user.error,
  errorreferrals: state.proposals.error,
  userLoggedIn: state.user.userLoggedIn
});

type Props = {
  errorUser?: any;
  errorreferrals?: any;
  userLoggedIn?: null | IUser;
  // redeemReferral: (recipientUserid: string, code: string) => void;
};

class ErrorPage extends React.Component<Props, {}> {
  render() {
    // const { errorUser, errorreferrals, userLoggedIn } = this.props;

    return (
      <React.Fragment>
        <DesktopDisplay>
          <React.Fragment>
            <LogoBg />

            <DesktopLogoHeader />
            <div
              style={{
                fontSize: "288px",
                color: "#E2E2E2",
                fontWeight: "bold",
                marginLeft: "8vw",
                marginTop: "8vw",
                fontFamily: "Arial"
              }}
            >
              404
              <span
                style={{
                  marginLeft: "20px",
                  fontSize: "64px",
                  color: "#E2E2E2",
                  fontWeight: "bold"
                }}
              >
                error
              </span>
            </div>
          </React.Fragment>
        </DesktopDisplay>
        <MobileDisplay>
          <div>
            <MobileLogoHeader />
            <div
              style={{
                fontSize: "96px",
                color: "#8F8F8F",
                fontWeight: "bold",
                marginLeft: "40px",
                marginTop: "8vw",
                fontFamily: "Arial"
              }}
            >
              404
            </div>
            <div
              style={{
                marginLeft: "140px",
                fontSize: "48px",
                color: "#8F8F8F",
                fontWeight: "bold",
                transform: "translateY(-20px)"
              }}
            >
              error
            </div>
          </div>
        </MobileDisplay>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(ErrorPage);
