import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'

import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { IGalleryImage } from 'entityModels'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import img_1 from '../../atoms/skeleton/gallery/1.jpg'
import img_2 from '../../atoms/skeleton/gallery/2.jpg'
import img_3 from '../../atoms/skeleton/gallery/3.jpg'
import img_4 from '../../atoms/skeleton/gallery/4.jpg'
import img_5 from '../../atoms/skeleton/gallery/5.jpg'
import img_6 from '../../atoms/skeleton/gallery/6.jpg'
import img_1_dark from '../../atoms/skeleton/gallery/1_dark.png'
import img_2_dark from '../../atoms/skeleton/gallery/2_dark.png'
import img_3_dark from '../../atoms/skeleton/gallery/3_dark.png'
import img_4_dark from '../../atoms/skeleton/gallery/4_dark.png'
import img_5_dark from '../../atoms/skeleton/gallery/5_dark.png'
import img_6_dark from '../../atoms/skeleton/gallery/6_dark.png'
import AddBox from '../../atoms/Button/addBox'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import { ITheme } from '../../../providers/theme/colours'

const CallWrapperSize = styled.div`
    position: relative;
    cursor: pointer;

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        height: 100%;
        min-height: 100px;
    }

    width: 100%;
    height: 100%;
    min-height: 100px;
    z-index: 1;
`

const CellImage = styled.div<StyleProps>`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media ${device.beyond_ipad_mobile} {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    opacity: ${(props) => (props.isthingloading === true ? '0' : '1')};
    transition: opacity 400ms;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
`
const GalleryCount = styled.div`
    @media ${device.beyond_ipad_mobile} {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;

        display: flex;
        align-items: center;
    }

    font-size: 42px;

    display: flex;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
`

const ImgOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100px;
    transition: 200ms all;
    background: linear-gradient(#000 50%, #000 100%);
    z-index: 2;
`

export interface IImageProps extends RouteComponentProps<any> {
    img: IGalleryImage | undefined
    carid: string
    readonlyMode?: boolean
    image_number: number
    lastItemTxt: string
    openImgForm?: any
}
interface StyleProps {
    isthingloading: boolean
}

type State = {
    src: any
    isLoading: boolean
    addBox: boolean
    imgOverlay: boolean
}

class GalleryOverviewItemDesktop extends React.Component<IImageProps, State> {
    state = {
        src: null,
        isLoading: true,
        addBox: false,
        imgOverlay: false,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true

        if (this.props.img) {
            const src = this.props.img.large_src
                ? this.props.img.large_src
                : this.props.img.url
            this._isMounted && this.setState({ src, isLoading: true })
            this.onLoad(src)
        } else if (!this.props.img) {
            this.setState({ isLoading: false })
        }

        return
    }

    showAddBox = () => this.setState({ addBox: true })

    removeAddBox = () => this.setState({ addBox: false })

    showImageOverlay = () => this.setState({ imgOverlay: true })

    removeImageOverlay = () => this.setState({ imgOverlay: false })

    componentDidUpdate(prevProps: IImageProps) {
        if (this.props.img) {
            if (this.props.img?.large_src !== prevProps.img?.large_src) {
                const src = this.props.img?.large_src
                    ? this.props.img?.large_src
                    : this.props.img?.url

                if (src) {
                    this._isMounted && this.setState({ src, isLoading: true })
                    this.onLoad(src)
                }
            } else if (!this.props.img) {
                this.setState({ isLoading: false })
            }
            return
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                ...this.state,
                isLoading: false,
            })
    }

    render() {
        let { src, isLoading, addBox } = this.state
        let { img, readonlyMode, image_number, lastItemTxt } = this.props

        const getPlaceholderImg = (theme: ITheme): string => {
            switch (image_number) {
                case 1:
                    return theme === 'light' ? img_1 : img_1_dark

                case 2:
                    return theme === 'light' ? img_2 : img_2_dark

                case 3:
                    return theme === 'light' ? img_3 : img_3_dark
                case 4:
                    return theme === 'light' ? img_4 : img_4_dark
                case 5:
                    return theme === 'light' ? img_5 : img_5_dark
                case 6:
                    return theme === 'light' ? img_6 : img_6_dark

                default:
                    return grey_placeholder
            }
        }

        const isLast = image_number === 6

        const determineImageLink = (img: IGalleryImage) => {
            const urlLocation = this.props.history.location.pathname

            let imgLinkPrefix =
                this.props.match.url &&
                this.props.match.url.includes('overview')
                    ? this.props.match.url.replace('/overview', '')
                    : this.props.match.url

            if (isLast) {
                return {
                    pathname: `${imgLinkPrefix}/gallery`,
                    state: { prevPath: urlLocation },
                }
            } else {
                return {
                    pathname: `${imgLinkPrefix}/gallery`,
                    search: `?id=${img.id}`,
                    state: { prevPath: urlLocation },
                }
            }
        }

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <React.Fragment>
                        {img !== undefined && (
                            <Link to={determineImageLink(img)}>
                                <CallWrapperSize
                                    onMouseEnter={() => {
                                        this.showImageOverlay()
                                    }}
                                    onMouseLeave={() => {
                                        this.removeImageOverlay()
                                    }}
                                >
                                    <SkeletonAbsolute
                                        isthingloading={isLoading}
                                    />

                                    {isLast ? (
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                position: 'relative',
                                            }}
                                        >
                                            <CellImage
                                                role="img"
                                                aria-label="car overview banner"
                                                style={{
                                                    backgroundImage: src
                                                        ? `url(${src}`
                                                        : `url(${grey_placeholder}`
                                                        ? `url(${grey_placeholder}`
                                                        : img
                                                        ? `url(${img.url}`
                                                        : `url(${grey_placeholder}`,
                                                    filter: 'brightness(30%)',
                                                }}
                                                isthingloading={isLoading}
                                            ></CellImage>

                                            <GalleryCount>
                                                {lastItemTxt}
                                            </GalleryCount>
                                        </div>
                                    ) : (
                                        <CellImage
                                            role="img"
                                            aria-label="car overview banner"
                                            style={{
                                                backgroundImage: src
                                                    ? `url(${src}`
                                                    : `url(${grey_placeholder}`
                                                    ? `url(${grey_placeholder}`
                                                    : img
                                                    ? `url(${img.url}`
                                                    : `url(${grey_placeholder}`,
                                            }}
                                            isthingloading={isLoading}
                                        />
                                    )}
                                    <ImgOverlay
                                        style={{
                                            opacity: this.state.imgOverlay
                                                ? 0.5
                                                : 0,
                                        }}
                                    />
                                </CallWrapperSize>
                            </Link>
                        )}

                        {!img && (
                            <CallWrapperSize
                                onMouseEnter={() => {
                                    this.showAddBox()
                                }}
                                onMouseLeave={() => {
                                    this.removeAddBox()
                                }}
                                onClick={() => {
                                    this.props.openImgForm()
                                }}
                            >
                                <SkeletonAbsolute isthingloading={isLoading} />
                                {!readonlyMode && addBox === true ? (
                                    <AddBox />
                                ) : (
                                    <CellImage
                                        role="img"
                                        aria-label="car overview banner"
                                        style={{
                                            backgroundImage: `url(${getPlaceholderImg(
                                                theme
                                            )}`,
                                            position: 'relative',
                                        }}
                                        isthingloading={isLoading}
                                    >
                                        {readonlyMode && (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontFamily: 'Lato',
                                                    fontSize: 16,
                                                    color: '#a4a4a4',
                                                }}
                                            >
                                                No Image
                                            </div>
                                        )}
                                    </CellImage>
                                )}
                            </CallWrapperSize>
                        )}
                    </React.Fragment>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(GalleryOverviewItemDesktop)
