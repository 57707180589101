import styled from 'styled-components'
import React from 'react'
import { device } from '../../templates/displays/devices'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import CustomIconTagQuickFilterItem from '../tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import { ITimelineEntryTypes } from 'timelineModels'
import ChevronLeft from '../icons/components/chevronLeft'
import CrossIcon from '../icons/crossIcon'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import colours from '../../../providers/theme/colours'
import ThreeDotsIcon from '../icons/components/threeDots'

type IStyledProps = {
    customTextColor?: string | undefined
    isUppercase?: boolean | undefined
    chevron?: boolean | undefined
    color?: string
}

const Wrapper = styled.div<{ $isVisible: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 25px;
    /* padding-left: 20px; */
    box-shadow: 0px 3px 6px -2px var(--box-shadow, rgba(0, 0, 0, 0.08));

    top: ${(props) => (props.$isVisible ? '0px' : '-52px')};
    transition: all 0.2s;
    left: 0px;
    right: 0px;
    height: 60px;
    position: sticky;
    /* z-index: 5; */
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    z-index: 2;
    @media (min-width: 800px) and (max-width: 881px) {
        width: 100vw;
        margin-left: -30px;
    }
    @media ${device.desktop} {
        display: none;
    }
`

const NavCaption = styled(Link)<IStyledProps>`
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
`

const NavCaptionClick = styled.div<IStyledProps>`
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
`

const Return = styled.div<IStyledProps>`
    font-family: Lato-semibold;
    justify-content: center;
    color: var(--text-strong, #1a1a1a);
    font-size: 18px;
    padding-left: 12px;
    text-transform: ${(props) =>
        props.isUppercase ? 'uppercase' : 'capitalize'};

    @media ${device.desktop} {
        display: none;
    }
`

const BtnContainer = styled.button`
    all: unset;
    border: none;
    background-color: transparent;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CategoryWrapper = styled.div`
    /* position: absolute;
    right: 15vw;
    z-index: 5; */
    padding-left: 20px;
    padding-right: 20px;
`

const ExternalCarTitleNavCaption = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const Title = styled.div`
    font-family: Lato-Light;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 200;
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    margin-top: 3px;
    letter-spacing: 1.05px;
    padding-left: 20px;
`

interface Props extends RouteComponentProps<any> {
    url?: string
    customText?: string
    customTextColor?: string
    isUppercase?: boolean
    chevron?: boolean
    backgroud_color?: string
    linkTo?: any
    linkSearch?: any
    threeDots?: boolean
    onThreeDotsClick?: any
    hasQuit?: boolean
    onQuit?: any
    onChevronClick?: any
    dataCyIdChevron?: string
    dataCyIdThreeDots?: string
    category?: ITimelineEntryTypes
    currentExternalCarTitle?: string
    isReadonly?: boolean
}

type State = {
    prevScrollpos: any
    isExternalCarTitleVisible: boolean
}

class TimelineItemReturnNav extends React.PureComponent<Props, State> {
    state = {
        prevScrollpos: window.pageYOffset,
        isExternalCarTitleVisible: true,
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        this.setState({ ...this.state })
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state

        const currentScrollPos = window.pageYOffset

        const checkvisibility = () => {
            if (currentScrollPos < 10) {
                return true
            }

            if (prevScrollpos > currentScrollPos) {
                return true
            } else {
                return false
            }
        }

        return this.setState({
            prevScrollpos: currentScrollPos,
            isExternalCarTitleVisible: checkvisibility(),
        })
    }

    render() {
        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        <Wrapper
                            $isVisible={true}
                            style={
                                this.props.isReadonly ? { paddingRight: 0 } : {}
                            }
                        >
                            {this.props.onChevronClick ? (
                                <NavCaptionClick
                                    data-attr={this.props.dataCyIdChevron}
                                    onClick={this.props.onChevronClick}
                                >
                                    <ChevronLeft
                                        color={colours[theme].text_muted}
                                    />
                                    <Return
                                        customTextColor={
                                            this.props.customTextColor
                                        }
                                        isUppercase={this.props.isUppercase}
                                    >
                                        {this.props.customText
                                            ? this.props.customText
                                            : 'Previous'}
                                    </Return>
                                </NavCaptionClick>
                            ) : (
                                <NavCaption
                                    to={{
                                        pathname: this.props.linkTo,
                                        search: this.props.linkSearch,
                                        state: {
                                            prevPath:
                                                this.props.history.location
                                                    .pathname,
                                        },
                                    }}
                                    data-attr={this.props.dataCyIdChevron}
                                >
                                    <ChevronLeft
                                        color={colours[theme].text_muted}
                                    />
                                    <Return
                                        customTextColor={
                                            this.props.customTextColor
                                        }
                                        isUppercase={this.props.isUppercase}
                                    >
                                        {this.props.customText
                                            ? this.props.customText
                                            : 'Previous'}
                                    </Return>
                                </NavCaption>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {this.props.category && (
                                    <CategoryWrapper>
                                        <CustomIconTagQuickFilterItem
                                            height="24px"
                                            id={this.props.category}
                                        >
                                            {this.props.category}
                                        </CustomIconTagQuickFilterItem>
                                    </CategoryWrapper>
                                )}
                                {this.props.threeDots && (
                                    <BtnContainer
                                        onClick={this.props.onThreeDotsClick}
                                        data-attr={this.props.dataCyIdThreeDots}
                                        style={{ transform: 'rotate(90deg)' }}
                                    >
                                        <ThreeDotsIcon
                                            color={colours[theme].text_strong}
                                        />
                                    </BtnContainer>
                                )}

                                {this.props.hasQuit && (
                                    <BtnContainer onClick={this.props.onQuit}>
                                        <CrossIcon
                                            color={colours[theme].text_muted}
                                        />
                                    </BtnContainer>
                                )}
                            </div>
                        </Wrapper>
                        {this.props.currentExternalCarTitle && (
                            <Wrapper
                                $isVisible={
                                    this.state.isExternalCarTitleVisible
                                }
                                style={{
                                    backgroundColor:
                                        colours[theme]
                                            .slider_banner_pop_over_bg,
                                    top: this.state.isExternalCarTitleVisible
                                        ? '52px'
                                        : '0px',
                                    height: '50px',
                                    zIndex: 2,
                                    borderBottom: 'none',
                                }}
                            >
                                <ExternalCarTitleNavCaption>
                                    <Title
                                        style={{
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {this.props.currentExternalCarTitle
                                            .length > 34
                                            ? `${this.props.currentExternalCarTitle.slice(
                                                  0,
                                                  34
                                              )}...`
                                            : this.props
                                                  .currentExternalCarTitle}
                                    </Title>
                                </ExternalCarTitleNavCaption>
                            </Wrapper>
                        )}
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(TimelineItemReturnNav)
