import InputField from '../../atoms/Inputfield/inputField'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Expander from '../../atoms/expander/expander'
import Faded from '../../templates/animated/faded'
import StyledFormError from '../../templates/styledcomponents/styledformerrormessage'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Paragraph from '../../atoms/typography/paragraph'
import DesktopDisplay from '../../templates/displays/desktopDisplay'
import MobileDisplay from '../../templates/displays/mobileDisplay'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { FormikResetPasswordSchema } from './validationSchema'
import { resetPasswordRequest } from '../../../redux/user/actions/loadingActions'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'

export const SubWrapper = styled.div`
    color: black;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
`

export const FormWrapper = styled(Form)`
    display: flex;
    justify-self: center;
    align-self: center;

    width: 100%;
    align-items: center;
    justify-content: center;
`

export const DividerBtn = styled.div`
    @media ${device.desktop} {
        padding-top: 20px;
    }

    @media ${device.mobile} {
        padding-top: 30px;
    }

    @media ${device.ipad} {
        padding-top: 20px;
    }
`

export const DividerTop = styled.div`
    @media ${device.desktop} {
        padding-top: 20px;
    }

    @media ${device.mobile} {
        padding-top: 20px;
    }

    @media ${device.ipad} {
        padding-top: 20px;
    }
`

const dispatchProps = {
    resetPasswordRequest: (email: string) => resetPasswordRequest(email),
}

interface OtherProps extends RouteComponentProps<any> {
    resetPasswordRequest: (email: string) => void
    setSubmit: () => void
}

interface Values {
    email: string
}

const FormikResetPassword = (props: OtherProps) => {
    const params = new URLSearchParams(props.location.search)
    let resetPasswordTestParam = params.get('test')

    const { theme } = useThemes()

    return (
        <Formik
            initialValues={{ email: '' }}
            onSubmit={(
                values: Values,
                { setSubmitting }: FormikHelpers<Values>
            ) => {
                resetPasswordTestParam === null &&
                    props.resetPasswordRequest(values.email)
                props.setSubmit()
                setSubmitting(false)
            }}
            validationSchema={FormikResetPasswordSchema}
        >
            {(formikprops: FormikProps<Values>) => (
                <FormWrapper translate="yes">
                    <SubWrapper>
                        <DesktopDisplay>
                            <Paragraph
                                align="left"
                                ipadAlign="left"
                                removeIpadPadding={true}
                            >
                                Please enter your email.
                            </Paragraph>
                        </DesktopDisplay>

                        <MobileDisplay>
                            <Paragraph align="left">
                                Please enter your email.
                            </Paragraph>
                        </MobileDisplay>

                        <DividerTop />

                        <DesktopDisplay>
                            <InputField
                                theme={theme}
                                type="email"
                                name="email"
                                placeholder={'email'}
                                formikprops={formikprops}
                                value={formikprops.values.email}
                                tabindex={1}
                                dataCyId={'reset-password-email-input-field'}
                                width={'250px'}
                            />
                        </DesktopDisplay>

                        <MobileDisplay>
                            <InputField
                                theme={theme}
                                type="email"
                                name="email"
                                placeholder={'email'}
                                formikprops={formikprops}
                                value={formikprops.values.email}
                                tabindex={1}
                                dataCyId={'reset-password-email-input-field'}
                                width={'100%'}
                            />
                        </MobileDisplay>

                        <Expander
                            height={formikprops.errors.email ? 'auto' : 0}
                            dataCyId={'reset-password-email-input-field-error'}
                        >
                            <Faded>
                                <StyledFormError>
                                    {formikprops.errors.email}
                                </StyledFormError>
                            </Faded>
                        </Expander>

                        <DividerBtn />

                        <ButtonAtom
                            theme="primary"
                            disabled={
                                formikprops.isSubmitting ||
                                formikprops.errors.email !== undefined ||
                                !!(
                                    formikprops.errors.email &&
                                    formikprops.touched.email
                                )
                            }
                            type="submit"
                            dataCyId={'submit-email-to-reset-password'}
                        >
                            SUBMIT
                        </ButtonAtom>
                    </SubWrapper>
                </FormWrapper>
            )}
        </Formik>
    )
}

export default withRouter(connect(null, dispatchProps)(FormikResetPassword))
