import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { menuActions } from '../../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import React from 'react'
import InsuranceApplicationCardDesktop from '../../../molecules/cards/insurance/insuranceApplicationCardDesktop'
import InsuranceTopBarWithProgressionRate from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceGoBackReusableBottomBar from '../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceApplicationCardContentManager from '../../../organisms/InsuranceApplicationCardContentManager/InsuranceApplicationCardContentManager'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import InsuranceApplicationCardMobile from '../../../molecules/cards/insurance/insuranceApplicationCardMobile'
import InsuranceTopBarWithProgressionRateMobile from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import RightSideModalDisplay from '../../../templates/displays/pageWrappers/rightSideModalDisplay'
import AddACarFormOnboarding from '../../../organisms/editForms/addACarForm/onboarding/addACarFormOnboarding'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import styled from 'styled-components'
import CircleLoader from '../../../atoms/loader/circleLoader'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../redux/store'
import { IInsuranceGetExternalCarDataByRegNumber } from '../../../../redux/insuranceQuoteApplication/sagas/car/registration_number_external_vehicle'
import { IInsuranceApplicationSectionCard } from './insuranceApplicationDashboard'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

const ModalText = styled.p`
    all: unset;
    color: #616161;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`
export type CarParams = {
    carid: string
}

const InsuranceApplicationNotInsuredEverydayCarDashboard = () => {
    const history = useHistory()
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let form_open = queryParams.get('form_open')

    let add_new_external_q = queryParams.get('add_new_external')

    let add_new_external = add_new_external_q === 'true'

    const { carid } = useParams<CarParams>()

    const currentVehicle = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.vehicles[carid] ??
            undefined
        )
    })

    let tech_info_stats: number = currentVehicle?.tech_info_stats ?? 0
    let mileage_and_parking_stats: number =
        currentVehicle?.mileage_parking_stats ?? 0
    let ownership_stats: number = currentVehicle?.ownership_stats ?? 0

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let makesList = useAppSelector(
        (state) => state.localdata.dropdownData.carMakes
    )

    const dispatch = useAppDispatch()

    const isCarAssigned =
        carid &&
        applicationData.everyday_car_ids &&
        applicationData.everyday_car_ids.includes(carid)
            ? true
            : false

    const car_cards: IInsuranceApplicationSectionCard[] = [
        {
            id: 'find_car',
            sectionID: 'everyday_vehicles',
            title: 'Find your car',
            text: `Want to speed things up? Enter your car's registration number. If it's registered in the UK, we'll fetch the details for you. Alternatively, you can manually fill in the information in the sections below.`,
            isDisabled: false,
            btnTxt: `Enter registration number`,
            numberOfProgressBars: 0,
            arrayOfProgressBarsValues: [],
            onClick: () => {
                if (!makesList) {
                    // since it will loop to get the make id
                    dispatch(dropDownActions.setMakeDropdownDataRequest())
                }
                toggleAddByRegNbForm(true)
            },
        },
        {
            id: 'tech_info',
            sectionID: 'everyday_vehicles',
            title: 'Technical Specifications',
            text: `Provide the technical details of your car, such as its make, model, engine size, and fuel type.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: isCarAssigned
                ? [tech_info_stats ?? 0]
                : [],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/everyday_car/${carid}/tech_info`
                )
            },
        },
        {
            id: 'mileage_parking',
            sectionID: 'everyday_vehicles',
            title: `Mileage & Parking`,
            text: `These details help us ensure accurate communication and assess the policyholder's risk profile, allowing us to tailor the coverage accordingly.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: isCarAssigned
                ? [mileage_and_parking_stats ?? 0]
                : [],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/everyday_car/${carid}/mileage_parking`
                )
            },
        },
        {
            id: 'ownership',
            sectionID: 'everyday_vehicles',
            title: `Ownership`,
            text: `Confirm your ownership status for the car, whether you own it outright, it's on finance, or leased.`,

            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: isCarAssigned
                ? [ownership_stats ?? 0]
                : [],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/everyday_car/${carid}/ownership`
                )
            },
        },
    ]

    let {
        setInitialInsurance_CarTechInfo,
        getInitialSectionWizardIds,
        getFirstIncompleteSubsection_Single_EverydayVehicle,
    } = useSetInitialInsurance()

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let { getUserAndApplicationData } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    let countriesdropdownlist = useAppSelector(
        (state) => state.localdata.dropdownData.countriesCode
    )
    let selectedCountry = useAppSelector(
        (state) => state.localdata.dropdownData.selectedCountry
    )

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    // let carsData = useAppSelector((state) => state.entities.carsData.cars)
    // let current: ICar | undefined =
    //     carsData && carid && carsData[carid] ? carsData[carid] : undefined

    useEffect(() => {
        if (add_new_external) {
            if (!countriesdropdownlist) {
                dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
            }
            if (!makesDropdowndata) {
                dispatch(dropDownActions.setMakeDropdownDataRequest())
            }
        } else {
            if (currentVehicle && currentVehicle.id) {
                let carMake: string | null = currentVehicle.tech_info?.make
                if (!modelsDropdowndata && carMake) {
                    dispatch(
                        dropDownActions.setModelDropdownDataRequest({
                            makeName: carMake,
                        })
                    )
                }

                setInitialInsurance_CarTechInfo(currentVehicle.id)
            }
        }
    }, [currentVehicle, userLoggedIn])

    const [showBtnLoadingState, toggleBtnLoadingState] = useState(false)
    const [showAddByRegNbForm, toggleAddByRegNbForm] = useState(false)

    const onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_insurance_car_techInfo({
                carid: carid,
                id: id,
                answer: answer,
            })
        )
    }

    const dvlaInfoLoading = useAppSelector((state) => {
        return state.entities.carsData.loading
    })

    const reg_nb: string | undefined = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid]?.tech_info
                ?.registration_number ?? undefined
        )
    })

    const currentDataInDraft = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid]?.tech_info ??
            undefined
        )
    })

    const findByRegistrationNumber = () => {
        if (reg_nb) {
            if (!selectedCountry || selectedCountry === 'United Kingdom') {
                let p: IInsuranceGetExternalCarDataByRegNumber = {
                    car_id: carid,
                    registration_number: reg_nb,
                }

                dispatch(
                    insuranceActions.get_external_vehicle_data_by_reg_number_request(
                        p
                    )
                )
            } else {
                let currentKeys = currentDataInDraft
                    ? Object.keys(currentDataInDraft)
                    : []

                let emptyObj: IAnyObject = {}
                currentKeys.forEach((key) => {
                    if (key !== 'registration_number') {
                        emptyObj[key] = undefined
                    }
                })

                dispatch(
                    insuranceActions.set_multiple_answers_insurance_car_techInfo(
                        {
                            carid: carid,
                            data: emptyObj,
                        }
                    )
                )
            }

            history.push(
                `/insurance/application/${applicationData.id}/everyday_car/${carid}/tech_info`
            )
        }
    }

    useEffect(() => {
        if (form_open === 'true') {
            setTimeout(() => toggleAddByRegNbForm(true), 50)
        }
    }, [])

    let getToAny = () => {
        let ids2 = getInitialSectionWizardIds()

        if (ids2) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids2.sectionID}`
            if (ids2.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids2.sub_sectionID}`
            }
            if (ids2.car_id) {
                url1 = url1 + `&car_id=${ids2.car_id}`
            }
            if (ids2.driver_id) {
                url1 = url1 + `&driver_id=${ids2.driver_id}`
            }
            return history.push(url1)
        } else {
            if (ids2 === null) {
                return
                // history.push(
                //     `/insurance/application/${applicationData?.id}`
                // )
            }
        }
    }

    let goToWizard = () => {
        let ids = getFirstIncompleteSubsection_Single_EverydayVehicle(carid)

        if (ids) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids.sectionID}`
            if (ids.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids.sub_sectionID}`
            }
            if (ids.car_id) {
                url1 = url1 + `&car_id=${ids.car_id}`
            }
            if (ids.driver_id) {
                url1 = url1 + `&driver_id=${ids.driver_id}`
            }
            return history.push(url1)
        } else {
            return getToAny()
        }
    }

    let isDraft = applicationData.status === 'DRAFT' ? true : false

    const { theme } = useThemes()

    return userLoggedIn ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        {
                            pageName: 'Everyday car',
                        },
                    ]}
                    progressionRate={
                        applicationData?.vehicles?.[carid]?.completion
                    }
                    isDisabled={!isCarAssigned}
                    disabledProgressText="Not assigned"
                    sectionId="everyday_vehicles"
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    {car_cards.map(
                        (card: IInsuranceApplicationSectionCard, i) => {
                            return !add_new_external && i === 0 ? null : (
                                <React.Fragment key={card.id}>
                                    <InsuranceApplicationCardDesktop
                                        cardProps={card}
                                    >
                                        {card.id === 'tech_info' &&
                                        dvlaInfoLoading ? (
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: 300,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <CircleLoader
                                                    size={50}
                                                    color="#5ec3ca"
                                                />
                                            </div>
                                        ) : (
                                            <InsuranceApplicationCardContentManager
                                                card={card}
                                                carDashboardID={carid}
                                                applicationID={id}
                                            />
                                        )}
                                    </InsuranceApplicationCardDesktop>
                                </React.Fragment>
                            )
                        }
                    )}
                </div>

                {showAddByRegNbForm && countriesdropdownlist && (
                    <RightSideModalDisplay
                        isOpen={showAddByRegNbForm ? true : false}
                        cancel={() => toggleAddByRegNbForm(false)}
                        footerBtn={{
                            text: 'Find your car',
                            action: () => {
                                findByRegistrationNumber()
                                toggleBtnLoadingState(true)
                                setTimeout(() => {
                                    toggleBtnLoadingState(false)
                                    toggleAddByRegNbForm(false)
                                }, 1000)
                            },
                            isDisabled: !reg_nb,
                            isLoading: showBtnLoadingState,
                        }}
                        title="Find your car"
                    >
                        <div style={{ padding: 32 }}>
                            <ModalText>
                                Changing the registration number? No problem!
                                Just enter the new one below, and we'll fetch
                                the details for you. Keep in mind that all
                                previously entered information for this car will
                                be replaced with the new data.
                            </ModalText>
                            <div style={{ paddingTop: 40 }} />
                            <AddACarFormOnboarding
                                isDesktop
                                countriesdropdownlist={countriesdropdownlist}
                                userCountry={'United Kingdom'}
                                selectedCountry={
                                    selectedCountry ?? 'United Kingdom'
                                }
                                registrationNumber={reg_nb}
                                setRegistrationNumber={(
                                    p: string | undefined
                                ) => {
                                    onAnswerChange('registration_number', p)
                                }}
                                removeToggle
                            />
                        </div>
                    </RightSideModalDisplay>
                )}

                <InsuranceGoBackReusableBottomBar
                    goBack={() => history.push(`/insurance/application/${id}`)}
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        {
                            pageName: 'Everyday car',
                        },
                    ]}
                    progressionRate={
                        applicationData?.vehicles?.[carid]?.completion
                    }
                    isDisabled={!isCarAssigned}
                    disabledProgressText="Not assigned"
                    sectionId={'everyday_vehicles'}
                />
                {!showAddByRegNbForm && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            paddingTop: 120,
                            paddingBottom: 120,
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                    >
                        {car_cards.map(
                            (card: IInsuranceApplicationSectionCard, i) => {
                                return !add_new_external && i === 0 ? null : (
                                    <React.Fragment key={card.id}>
                                        <InsuranceApplicationCardMobile
                                            cardProps={card}
                                        >
                                            {card.id === 'tech_info' &&
                                            dvlaInfoLoading ? (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        height: 140,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <CircleLoader
                                                        size={40}
                                                        color="#5ec3ca"
                                                    />
                                                </div>
                                            ) : (
                                                <InsuranceApplicationCardContentManager
                                                    card={card}
                                                    carDashboardID={carid}
                                                    isMobile
                                                    applicationID={id}
                                                />
                                            )}
                                        </InsuranceApplicationCardMobile>
                                    </React.Fragment>
                                )
                            }
                        )}
                    </div>
                )}
                {showAddByRegNbForm && countriesdropdownlist && (
                    <RightSideModalDisplay
                        isOpen={showAddByRegNbForm ? true : false}
                        cancel={() => toggleAddByRegNbForm(false)}
                        footerBtn={{
                            text: 'Find your car',
                            action: () => {
                                findByRegistrationNumber()
                                toggleBtnLoadingState(true)
                                setTimeout(() => {
                                    toggleBtnLoadingState(false)
                                    toggleAddByRegNbForm(false)
                                }, 1000)
                            },
                            isDisabled: !reg_nb,
                            isLoading: showBtnLoadingState,
                        }}
                        title="Find your car"
                    >
                        <div style={{ padding: 32 }}>
                            <ModalText>
                                Changing the registration number? No problem!
                                Just enter the new one below, and we'll fetch
                                the details for you. Keep in mind that all
                                previously entered information for this car will
                                be replaced with the new data.
                            </ModalText>
                            <div style={{ paddingTop: 40 }} />
                            <AddACarFormOnboarding
                                countriesdropdownlist={countriesdropdownlist}
                                userCountry={'United Kingdom'}
                                selectedCountry={
                                    selectedCountry ?? 'United Kingdom'
                                }
                                registrationNumber={reg_nb}
                                setRegistrationNumber={(
                                    p: string | undefined
                                ) => {
                                    onAnswerChange('registration_number', p)
                                }}
                                removeToggle
                                isInsurance
                            />
                        </div>
                    </RightSideModalDisplay>
                )}
                <InsuranceGoBackReusableBottomBar
                    isMobile
                    goBack={() => history.push(`/insurance/application/${id}`)}
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationNotInsuredEverydayCarDashboard
