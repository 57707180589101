import { ITimelineCreateForms } from 'myModels'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import {
    IActionPayloadTimelineCreationRequest,
    ITimelineDataToBeSubmittedObj,
} from 'timelineModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../redux/store/hooks'
import { manageDataToBeSubmittedTimelineCreationRequest } from '../../../redux/timeline/actions/requestActions'
import { clearDataToBeSubmittedSuccess } from '../../../redux/timeline/actions/successActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import Loader from '../../atoms/loader/loader'
import TimelineCreateManagerDesktop from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerDesktop'
import TimelineCreateManagerMobile from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerMobile'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'

type CategoryParams = {
    categoryid: string
    carid: string
}

const CreateTimelineEntryByCategory = () => {
    const { categoryid, carid } = useParams<CategoryParams>()

    // console.log(categoryid, carid)

    const dispatch = useAppDispatch()
    const dispatchThunk = useThunkAppDispatch()

    let userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let timeline_creation_forms_current_state = useAppSelector((state) => {
        return state.editForms.timelineCreationForm
    })

    let timeline_creation_steps_data = useAppSelector((state) => {
        return state.timeline.formsData.creation.timeline_creation_steps_data
    })

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let timeline_creation_steps_object_of_indexed_list = useAppSelector(
        (state) => {
            return state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list
        }
    )

    let data_to_be_submitted: ITimelineDataToBeSubmittedObj = useAppSelector(
        (state) => {
            return state.timeline.formsData.creation.data_to_be_submitted
        }
    )

    let labels_searchable_list = useAppSelector((state) => {
        return state.user.userLoggedIn?.labels
    })

    useEffect(() => {
        dispatch(
            editFormsActions.manageTimelineCreationFormState({
                isOpen: true,
                current_step_index: 1,
                active_creation: 'insurance',
            })
        )

        let payload: IActionPayloadTimelineCreationRequest = {
            entityType: 'entry',
            carid: carid,
            action: 'CREATE',
            fieldID: 'categoryID',
            fieldValue: 'insurance',
        }

        dispatch(manageDataToBeSubmittedTimelineCreationRequest(payload))

        setTimeout(() => {
            dispatch(
                manageDataToBeSubmittedTimelineCreationRequest({
                    carid: carid,
                    entityType: 'entry',
                    action: 'UPDATE',
                    fieldID: 'title',
                    fieldValue: 'Insurance Policy',
                })
            )
        }, 50)

        return () => {
            dispatch(
                editFormsActions.manageTimelineCreationFormState({
                    isOpen: false,
                    current_step_index: 0,
                    active_creation: null,
                })
            )
            dispatch(clearDataToBeSubmittedSuccess(true))
        }
    }, [])

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        }

        if (!carsData[carid]) {
            dispatch(getCarDataByIdRequest(carid))
        }

        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/car/${carid}/history-file/create/${categoryid}`
            )
        )

        dispatchThunk(setCurrentCar(carid))

        window?.scrollTo(0, 0)
    }, [userLoggedIn])

    const closeFormAndDirectToPrevPath = () => {
        dispatch(
            editFormsActions.manageTimelineCreationFormState({
                isOpen: false,
                current_step_index: 0,
                active_creation: null,
            })
        )
        dispatch(clearDataToBeSubmittedSuccess(true))
    }

    const manageTimelineCreationFormState = (obj: ITimelineCreateForms) => {
        dispatch(editFormsActions.manageTimelineCreationFormState(obj))
    }

    return (
        <Faded>
            <CenteredPageWrapper fullwidth>
                <Loader
                    isLoading={
                        timeline_creation_forms_current_state.isOpen === false
                    }
                />
                <DesktopDisplayOnly>
                    <TimelineCreateManagerDesktop
                        entityID={
                            data_to_be_submitted.entry &&
                            data_to_be_submitted.entry.id
                                ? data_to_be_submitted.entry?.id
                                : 'NEW_entryUID'
                        }
                        carID={carid}
                        entityType="entry"
                        data_to_be_submitted={data_to_be_submitted}
                        timeline_creation_forms_current_state={
                            timeline_creation_forms_current_state
                        }
                        timeline_creation_steps_data={
                            timeline_creation_steps_data
                        }
                        manageTimelineCreationFormState={
                            manageTimelineCreationFormState
                        }
                        timeline_creation_steps_object_of_indexed_list={
                            timeline_creation_steps_object_of_indexed_list
                        }
                        close_create_form={closeFormAndDirectToPrevPath}
                    />
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <BottomUpSlider
                        show={timeline_creation_forms_current_state.isOpen}
                    >
                        <TimelineCreateManagerMobile
                            data_to_be_submitted={data_to_be_submitted}
                            timeline_creation_forms_current_state={
                                timeline_creation_forms_current_state
                            }
                            is_category_specific_landing={true}
                            timeline_creation_steps_data={
                                timeline_creation_steps_data
                            }
                            manageTimelineCreationFormState={
                                manageTimelineCreationFormState
                            }
                            timeline_creation_steps_object_of_indexed_list={
                                timeline_creation_steps_object_of_indexed_list
                            }
                            close_create_form={closeFormAndDirectToPrevPath}
                            // close_create_form={close_create_form_mobile}
                            labels_searchable_list={
                                labels_searchable_list
                                    ? labels_searchable_list
                                    : []
                            }
                            entityID={
                                data_to_be_submitted.entry &&
                                data_to_be_submitted.entry.id
                                    ? data_to_be_submitted.entry?.id
                                    : 'NEW_entryUID'
                            }
                            carID={carid}
                            entityType="entry"

                            // submitDataRequest={() =>
                            //     addTimelineItemToCarRequest({
                            //         carid: carid,
                            //         item: data_to_be_submitted.entry,
                            //     })
                            // }
                        />
                    </BottomUpSlider>
                </IpadAndMobileDisplay>
            </CenteredPageWrapper>
        </Faded>
    )
}

export default CreateTimelineEntryByCategory
