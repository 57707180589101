import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import {
    AbsoluteGradient,
    JourneyCardDesktopWithScroll,
} from '../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../providers/theme/colours'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { ICarsObject, IGalleryImage, IGalleryImagesObject } from 'entityModels'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import { IUploadGalleryImagesPreSignedUrlsPayload, IUser } from 'myModels'
import { device } from '../../../templates/displays/devices'
import JourneyImagesDropzone from '../../dropzone/journeyImagesDropZone'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { submitCarGalleryImagesFilestorageRequest } from '../../../../redux/filestorage/actions/dispatched/loadingActions'
import axios from 'axios'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { generateOverviewImages } from '../../galleryOverviewManager/generateOverviewImages'
import GalleryOverviewManagerJourney from '../../galleryOverviewManager/mobile/galleryOverviewManagerJourney'
import ImageUploadBtn from '../../../atoms/Button/imageUploadBtn'
import JourneyWrapperLeftSideDesktop from '../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: (carid: string) => void
    goToNextStep: (carid: string) => void
    carsData?: ICarsObject
    garageCars?: string[]
    galleryImages?: IGalleryImagesObject | null
    user: IUser | null
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

const CardContent = styled.section`
    display: flex;
    width: 592px;
    padding: 56px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    overflow-y: auto;
    @media (max-width: 1200px) {
        width: 100%;
        padding: 24px;
    }
    @media ${device.mobile_and_ipad} {
        width: 100%;
        padding: 24px;
    }
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
`

const ImageWrapper = styled.div`
    width: 100%;
    position: relative;

    @media ${device.mobile_and_ipad} {
        width: 100%;
    }
`

const GreyWrapper = styled.div<IStyle>`
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    min-width: 0;
    border-radius: 24px;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
`

export default function GalleryStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    carsData,
    galleryImages,
}: Props) {
    const { theme } = useThemes()
    const [selectedImages, setSelectedImages] = useState<File[]>([])

    const [imagesUploadProgress, setUploadImagesProgress] = useState<{
        [key: string]: number
    }>({})

    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')

    const dispatch = useAppDispatch()

    const gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    const upload_presignedurl_image_to_s3 = (filesToSend: File[]) => {
        filesToSend.length > 0 &&
            filesToSend.forEach(async (file: File, index: number) => {
                if (
                    file !== undefined &&
                    gallerypresignedurls &&
                    gallerypresignedurls[file.name]
                ) {
                    setUploadImagesProgress((prevState) => {
                        return { ...prevState, [file.name]: 0 }
                    })

                    return await axios
                        .request({
                            method: 'put',
                            url: gallerypresignedurls[file.name].url,
                            data: file,
                            onUploadProgress: (p: any) => {
                                setUploadImagesProgress((prevState) => {
                                    return {
                                        ...prevState,
                                        [file.name]: Math.ceil(
                                            (p.loaded / p.total) * 100
                                        ),
                                    }
                                })
                            },
                        })
                        .then((data: any) => {
                            setUploadImagesProgress((prevState) => {
                                return { ...prevState, [file.name]: 100 }
                            })

                            return
                        })
                        .catch((e: any) => {
                            apiSentryErrorHandler(
                                e,
                                'Upload presigned url car image to s3 error'
                            )
                            return Promise.reject(e)
                        })
                }
                // };
            })
    }

    useEffect(() => {
        if (gallerypresignedurls && selectedImages) {
            upload_presignedurl_image_to_s3(selectedImages)
        }
    }, [gallerypresignedurls])

    const onSubmit = (
        car_id: string,
        imgs: File[],
        presignedurls: IUploadGalleryImagesPreSignedUrlsPayload
    ) => {
        if (car_id && presignedurls) {
            let imgPayload = {
                carid: car_id,
                gallerypresignedurls: presignedurls,
                files: imgs,
                fields: {
                    caption: undefined,
                    credit: undefined,
                    location: undefined,
                },
                isFromQuickActions: true,
            }

            dispatch(submitCarGalleryImagesFilestorageRequest(imgPayload))
        }
    }

    const submitAndGoToNextStep = () => {
        if (carid) {
            if (selectedImages.length > 0 && gallerypresignedurls) {
                onSubmit(carid, selectedImages, gallerypresignedurls)
            }
            goToNextStep(carid)
        }
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitAndGoToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [selectedImages, gallerypresignedurls, step, carid])

    const removeImage = (filename: string) => {
        let filteredImages = selectedImages?.filter(
            (img) => img.name !== filename
        )
        setSelectedImages(filteredImages)
        setUploadImagesProgress((prevState) => {
            let newState = { ...prevState }
            delete newState[`${filename}`]
            return newState
        })
    }

    let cover_id =
        carsData && carid && carsData[carid] && carsData[carid].gallery.cover
    let images_ids =
        carsData && carid && carsData[carid] && carsData[carid].gallery.images
            ? carsData[carid].gallery.images
            : []
    let overview_images_ids =
        carsData && carid && carsData[carid] && carsData[carid].gallery.overview
            ? carsData[carid].gallery.overview
            : []

    let cover_img: IGalleryImage | undefined =
        galleryImages && cover_id && galleryImages[cover_id]
            ? galleryImages[cover_id]
            : undefined
    let img_array: IGalleryImage[] | null = galleryImages
        ? generateOverviewImages(
              galleryImages,
              images_ids,
              overview_images_ids,
              cover_img
          )
        : null

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={'Add gallery images'}
                            sectionID="car_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                carid && goToPrevStep(carid)
                            }}
                        >
                            <ImageWrapper>
                                {selectedImages.length > 0 ? (
                                    <GreyWrapper $theme={theme}>
                                        {selectedImages.map((img) => (
                                            <ImageUploadBtn
                                                variant="image_preview"
                                                image={img}
                                                sectionID="car_profile"
                                                width="100%"
                                                height="80px"
                                                removeBg={true}
                                                removeImage={
                                                    img &&
                                                    imagesUploadProgress[
                                                        img.name
                                                    ] === 100
                                                        ? () =>
                                                              removeImage(
                                                                  img.name
                                                              )
                                                        : undefined
                                                }
                                                propgress={
                                                    imagesUploadProgress[
                                                        img.name
                                                    ]
                                                }
                                            />
                                        ))}
                                    </GreyWrapper>
                                ) : carid ? (
                                    <JourneyImagesDropzone
                                        carid={carid}
                                        handleFilesGiven={(files: File[]) => {
                                            files &&
                                                setSelectedImages(
                                                    (prevState) => {
                                                        return [
                                                            ...prevState,
                                                            ...files,
                                                        ]
                                                    }
                                                )
                                        }}
                                    />
                                ) : null}
                                {selectedImages &&
                                selectedImages.length > 0 &&
                                carid ? (
                                    <JourneyImagesDropzone
                                        carid={carid}
                                        isNakedBtn
                                        handleFilesGiven={(files: File[]) => {
                                            files &&
                                                setSelectedImages(
                                                    (prevState) => {
                                                        return [
                                                            ...prevState,
                                                            ...files,
                                                        ]
                                                    }
                                                )
                                        }}
                                    />
                                ) : null}
                            </ImageWrapper>
                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={submitAndGoToNextStep}
                                    bg={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_500
                                    }
                                    isDisabled={!carid}
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'car_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>
                                {carid ? (
                                    <RowContent style={{ gap: 8 }}>
                                        <IconWrapper $theme={theme}>
                                            <EnterIcon />
                                        </IconWrapper>{' '}
                                        <EnterTxt $theme={theme}>
                                            Or press Enter
                                        </EnterTxt>
                                    </RowContent>
                                ) : null}
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide>
                            <JourneyCardDesktopWithScroll
                                $theme={theme}
                                $customBorderRadius="24px"
                                $customWidth="600px"
                            >
                                <CardContent>
                                    {carid ? (
                                        <GalleryOverviewManagerJourney
                                            cover_img={cover_img}
                                            images_array={
                                                img_array &&
                                                img_array.length > 0
                                                    ? img_array
                                                    : null
                                            }
                                            selectedImages={selectedImages}
                                            imagesUploadProgress={
                                                imagesUploadProgress
                                            }
                                        />
                                    ) : undefined}
                                </CardContent>
                                <AbsoluteGradient />
                            </JourneyCardDesktopWithScroll>
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    title={'Add gallery images'}
                    sectionID="car_profile"
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        carid && goToPrevStep(carid)
                    }}
                    onNext={submitAndGoToNextStep}
                >
                    {carid ? (
                        <ColumnContent
                            style={{
                                paddingLeft: 24,
                                paddingRight: 24,
                            }}
                        >
                            <JourneyImagesDropzone
                                carid={carid}
                                handleFilesGiven={(files: File[]) => {
                                    files &&
                                        setSelectedImages((prevState) => {
                                            return [...prevState, ...files]
                                        })
                                }}
                                isMobile
                            />
                        </ColumnContent>
                    ) : null}
                    {carid ? (
                        <GalleryOverviewManagerJourney
                            cover_img={cover_img}
                            images_array={
                                img_array && img_array.length > 0
                                    ? img_array
                                    : null
                            }
                            selectedImages={selectedImages}
                            imagesUploadProgress={imagesUploadProgress}
                            deleteUploadedImg={removeImage}
                            isMobile
                        />
                    ) : undefined}
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
