import { Doughnut } from 'react-chartjs-2'

export type IChartDoughnut = {
    labels: string[]
    datasets: {
        label?: string
        data: number[]
        backgroundColor?: string[]
        borderColor?: string
        borderWidth?: number
        [key: string]: any
    }[]
}

type Props = {
    chartData: IChartDoughnut
    isEmpty?: boolean
}

const DoughnutChart = (props: Props) => {
    let { chartData } = props

    // innerRadius: 30,
    // outerRadius: 100,
    // paddingAngle: 5,

    return (
        <div className="chart-container">
            <Doughnut
                data={chartData}
                options={{
                    plugins: {
                        tooltip: {
                            bodyFont: { family: 'Lato' },
                            callbacks: {
                                label: function (value) {
                                    if (props.isEmpty) {
                                        return 'no data'
                                    }
                                    return value.formattedValue
                                },
                            },
                        },
                        title: {
                            display: false,
                        },
                        legend: {
                            display: false,
                        },
                    },
                }}
            />
        </div>
    )
}
export default DoughnutChart
