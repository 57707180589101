export const GET_CAR_DATA_REQUEST = '@@car/get/REQUEST'
export const GET_CAR_DATA_SUCCESS = '@@car/get/SUCCESS'
export const GET_CAR_DATA_ERROR = '@@car/get/ERROR'

export const SET_EMPTY_CAR_REQUEST = '@@car/set_empty_car/REQUEST'

export const GET_CAR_DATA_BY_ID_REQUEST = '@@car/get_by_id/REQUEST'
export const GET_CAR_DATA_BY_ID_SUCCESS = '@@car/get_by_id/SUCCESS'
export const GET_CAR_DATA_BY_ID_ERROR = '@@car/get_by_id/ERROR'

export const GET_GARAGE_CARS_DATA_REQUEST = '@@car/garage/get/REQUEST'
export const GET_GARAGE_CARS_DATA_SUCCESS = '@@car/garage/get/SUCCESS'
export const GET_GARAGE_CARS_DATA_ERROR = '@@car/garage/get/ERROR'

export const WRITE_CAR_DATA_GENERAL_REQUEST = '@@car_general/write/REQUEST'
export const WRITE_CAR_DATA_GENERAL_SUCCESS = '@@car_general/write/SUCCESS'
export const WRITE_CAR_DATA_GENERAL_ERROR = '@@car_general/write/ERROR'

export const SET_CURRENT_CAR_REQUEST = '@@car/current/set/REQUEST'
export const SET_CURRENT_CAR_SUCCESS = '@@car/current/set/SUCCESS'
export const SET_CURRENT_CAR_ERROR = '@@car/current/set/ERROR'

export const CREATE_CAR_REQUEST_VERSION2 = '@@car/create_version2/REQUEST'
export const CREATE_CAR_REQUEST = '@@car/create/REQUEST'
export const CREATE_CAR_SUCCESS = '@@car/create/SUCCESS'
export const CREATE_CAR_ERROR = '@@car/create/ERROR'

export const UPDATE_CAR_BANNER_URL_REQUEST = '@@car_banner_url/update/REQUEST'
export const UPDATE_CAR_BANNER_URL_SUCCESS = '@@car_banner_url/update/SUCCESS'
export const UPDATE_CAR_BANNER_URL_ERROR = '@@car_banner_url/update/ERROR'

export const GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_REQUEST =
    '@@car/get_external_data_by_registration_number/REQUEST'
export const GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_SUCCESS =
    '@@car/get_external_data_by_registration_number/SUCCESS'
export const GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_ERROR =
    '@@car/get_external_data_by_registration_number/ERROR'

export const GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_REQUEST_VERSION2 =
    '@@car/get_external_data_by_registration_number_version2/REQUEST'

export const GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_FOR_INSURANCE_SUCCESS =
    '@@car/get_external_data_by_registration_number_for_insurance/SUCCESS'

export const SET_CAR_TASK_IDS_REQUEST = '@@car_tasks/set/REQUEST'
export const SET_CAR_TASK_IDS_SUCCESS = '@@car_tasks/set/SUCCESS'
export const SET_CAR_TASK_IDS_ERROR = '@@car_tasks/set/ERROR'

export const DELETE_CAR_REQUEST = '@@car/delete/REQUEST'
export const DELETE_CAR_SUCCESS = '@@car/delete/SUCCESS'
export const DELETE_CAR_ERROR = '@@car/delete/ERROR'
export const REMOVE_NEW_CAR_DUPLICATE = '@@car/remove_new_car/SUCCESS'
// CAR SHARES SEARCH, FILTER, SORT

export const CHANGE_SORT_CAR_SHARE_DISPLAY_REQUEST =
    '@@car_share/display_sort/REQUEST'
export const CHANGE_SORT_CAR_SHARE_DISPLAY_SUCCESS =
    '@@car_share/display_sort/SUCCESS'

export const CHANGE_FILTER_CAR_SHARE_DISPLAY_REQUEST =
    '@@car_share/display_filter/REQUEST'
export const CHANGE_FILTER_CAR_SHARE_DISPLAY_SUCCESS =
    '@@car_share/display_filter/SUCCESS'

export const CHANGE_SEARCHQUERY_CAR_SHARE_DISPLAY_REQUEST =
    '@@car_share/display_searchquery/REQUEST'
export const CHANGE_SEARCHQUERY_CAR_SHARE_DISPLAY_SUCCESS =
    '@@car_share/display_searchquery/SUCCESS'

export const RESET_SEARCHQUERY_CAR_SHARE_DISPLAY_REQUEST =
    '@@car_share/display_searchquery_reset/REQUEST'
export const RESET_SEARCHQUERY_CAR_SHARE_DISPLAY_SUCCESS =
    '@@car_share/display_searchquery_reset/SUCCESS'

// SHARES RECIPIENTS SORT
export const CHANGE_SORT_SHARE_RECIPIENTS_DISPLAY_REQUEST =
    '@@share_recipients/display_sort/REQUEST'
export const CHANGE_SORT_SHARE_RECIPIENTS_DISPLAY_SUCCESS =
    '@@share_recipients/display_sort/SUCCESS'

export const GET_PUBLIC_SHARES_CAR_REQUEST = '@@car_public_shares/get/REQUEST'
export const GET_PUBLIC_SHARES_CAR_SUCCESS = '@@car_public_shares/get/SUCCESS'
export const GET_PUBLIC_SHARES_CAR_ERROR = '@@car_public_shares/get/ERROR'

export const GET_PUBLIC_SHARES_ALL_USER_GARAGE_CARS_REQUEST =
    '@@all_user_garage_cars_public_shares/get/REQUEST'

export const GET_PUBLIC_SHARES_ALL_USER_GARAGE_CARS_SUCCESS =
    '@@all_user_garage_cars_public_shares/get/SUCCESS'

export const CREATE_CAR_PUBLIC_SHARE_REQUEST =
    '@@car_public_share/create/REQUEST'
export const CREATE_CAR_PUBLIC_SHARE_SUCCESS =
    '@@car_public_share/create/SUCCESS'
export const CREATE_CAR_PUBLIC_SHARE_ERROR = '@@car_public_share/create/ERROR'

export const DELETE_CAR_PUBLIC_SHARE_REQUEST =
    '@@car_public_share/delete/REQUEST'
export const DELETE_CAR_PUBLIC_SHARE_SUCCESS =
    '@@car_public_share/delete/SUCCESS'
export const DELETE_CAR_PUBLIC_SHARE_ERROR = '@@car_public_share/delete/ERROR'

export const UPDATE_CAR_OVERVIEW_GRID_INFO =
    '@@car/update/car_overview_grid_info/SUCCESS'

export const UPDATE_CAR_ENTRIES_COUNT = '@@car/update/car_entries_count/SUCCESS'

export const ADD_TO_CAR_IMAGES_IDS_ARRAY =
    '@@car/add/car_images_ids_array/SUCCESS'

export const DELETE_FROM_CAR_IMAGES_IDS_ARRAY =
    '@@car/delete/car_images_ids_array/SUCCESS'

export const UPDATE_COVER_IMAGE_ID = '@@car/update/cover_img_id/success'
export const UPDATE_BANNER_IMAGE_URL = '@@car/update/banner_img/success'

export const GET_EXTERNAL_CARS_SHARED_WITH_YOU_SUCCESS =
    '@@external_cars_shared_with_you/get/SUCCESS'
export const GET_EXTERNAL_CARS_SHARED_WITH_YOU_REQUEST =
    '@@external_cars_shared_with_you/get/REQUEST'
export const GET_EXTERNAL_CARS_SHARED_WITH_YOU_ERROR =
    '@@external_cars_shared_with_you/get/ERROR'

export const SET_CURRENT_EXTERNAL_CAR_REQUEST =
    '@@external_car/current/set/REQUEST'
export const SET_CURRENT_EXTERNAL_CAR_SUCCESS =
    '@@external_car/current/set/SUCCESS'
export const SET_CURRENT_EXTERNAL_CAR_ERROR = '@@external_car/current/set/ERROR'

export const POPULATE_SINGLE_CAR_ALL_OWNED_SHARES_SUCCESS =
    '@@owned_car/all_shares/set/SUCCESS'

export const GET_OWNED_DIRECT_SHARES_CAR_REQUEST =
    '@@car_owned_shares/get/REQUEST'
export const GET_OWNED_DIRECT_SHARES_CAR_SUCCESS =
    '@@car_owned_shares/get/SUCCESS'
export const GET_OWNED_DIRECT_SHARES_CAR_ERROR = '@@car_owned_shares/get/ERROR'

export const GET_ALL_CAR_SHARES_REQUEST = '@@car_shares_all/get/REQUEST'
export const GET_ALL_CAR_SHARES_SUCCESS = '@@car_shares_all/get/SUCCESS'
export const GET_ALL_CAR_SHARES_ERROR = '@@car_shares_all/get/ERROR'

export const CREATE_CAR_DIRECT_SHARE_REQUEST =
    '@@car_direct_share/create/REQUEST'
export const CREATE_CAR_DIRECT_SHARE_SUCCESS =
    '@@car_direct_share/create/SUCCESS'
export const CREATE_CAR_DIRECT_SHARE_ERROR = '@@car_direct_share/create/ERROR'

export const UPDATE_CAR_DIRECT_SHARE_REQUEST =
    '@@car_direct_share/update/REQUEST'
export const UPDATE_CAR_DIRECT_SHARE_SUCCESS =
    '@@car_direct_share/update/SUCCESS'
export const UPDATE_CAR_DIRECT_SHARE_ERROR = '@@car_direct_share/update/ERROR'

export const GET_SINGLE_CAR_DIRECT_SHARE_REQUEST =
    '@@car_direct_share_single/get/REQUEST'
export const GET_SINGLE_CAR_DIRECT_SHARE_SUCCESS =
    '@@car_direct_share_single/get/SUCCESS'
export const GET_SINGLE_CAR_DIRECT_SHARE_ERROR =
    '@@car_direct_share_single/get/ERROR'

export const ADD_SHARE_RECIPIENT_CAR_REQUEST =
    '@@car_direct_share_recipient/add/REQUEST'
export const ADD_SHARE_RECIPIENT_CAR_ERROR =
    '@@car_direct_share_recipient/add/ERROR'
export const ADD_SHARE_RECIPIENT_CAR_SUCCESS =
    '@@car_direct_share_recipient/add/SUCCESS'

export const DELETE_SHARE_RECIPIENT_CAR_REQUEST =
    '@@car_direct_share_recipient/delete/REQUEST'
export const DELETE_SHARE_RECIPIENT_CAR_ERROR =
    '@@car_direct_share_recipient/delete/ERROR'
export const DELETE_SHARE_RECIPIENT_CAR_SUCCESS =
    '@@car_direct_share_recipient/delete/SUCCESS'

export const DELETE_CAR_DIRECT_SHARE_REQUEST =
    '@@car_direct_share/delete/REQUEST'
export const DELETE_CAR_DIRECT_SHARE_ERROR = '@@car_direct_share/delete/ERROR'
export const DELETE_CAR_DIRECT_SHARE_SUCCESS =
    '@@car_direct_share/delete/SUCCESS'

export const CLEAN_UP_CAR_DATA_ON_LOGOUT =
    '@@clean_up_car_data_on_logout/SUCCESS'

export const CREATE_CAR_SHARE_INVITE_REQUEST =
    '@@car_share_invite/create/REQUEST'
export const CREATE_CAR_SHARE_INVITE_SUCCESS =
    '@@car_share_invite/create/SUCCESS'
export const CREATE_CAR_SHARE_INVITE_ERROR = '@@car_share_invite/create/ERROR'

export const DELETE_CAR_SHARE_INVITE_REQUEST =
    '@@car_share_invite/delete/REQUEST'
export const DELETE_CAR_SHARE_INVITE_SUCCESS =
    '@@car_share_invite/delete/SUCCESS'
export const DELETE_CAR_SHARE_INVITE_ERROR = '@@car_share_invite/delete/ERROR'

export const RESEND_CAR_SHARE_INVITE_REQUEST =
    '@@car_share_invite/resend/REQUEST'
export const RESEND_CAR_SHARE_INVITE_SUCCESS =
    '@@car_share_invite/resend/SUCCESS'
export const RESEND_CAR_SHARE_INVITE_ERROR = '@@car_share_invite/resend/ERROR'

export const ACCEPT_CAR_SHARE_INVITE_REQUEST =
    '@@car_share_invite/accept/REQUEST'
export const ACCEPT_CAR_SHARE_INVITE_SUCCESS =
    '@@car_share_invite/accept/SUCCESS'
export const ACCEPT_CAR_SHARE_INVITE_ERROR = '@@car_share_invite/accept/ERROR'

export const UPDATE_CAR_WITH_SHOWROOM_DATA = '@@car/showroom_draft/update'

export const ARCHIVE_CAR_REQUEST = '@@car/archive/REQUEST'
export const ARCHIVE_CAR_SUCCESS = '@@car/archive/SUCCESS'
export const ARCHIVE_CAR_ERROR = '@@car/archive/ERROR'

export const SET_CAR_SHOWROOM_ENTRY_DELETED = '@@car/showroom_deletion/SUCCESS'

export const CREATE_HANDOVER_SUCCESS = '@@car/handover/SUCCESS'
export const CREATE_HANDOVER_REQUEST = '@@car/handover/REQUEST'
export const CREATE_HANDOVER_ERROR = '@@car/handover/ERROR'

export const DELETE_HANDOVER_SUCCESS = '@@car/delete_handover/SUCCESS'
export const DELETE_HANDOVER_REQUEST = '@@car/delete_handover/REQUEST'
export const DELETE_HANDOVER_ERROR = '@@car/delete_handover/ERROR'

export const UPDATE_CAR_TASKS_ARRAY_ON_TASKS_CREATION_OR_DELETION =
    '@@car/update/tasks_array_on_tasks_creation_or_deletion'

export const UPDATE_CAR_TITLE_ON_CREATION = '@@car/update/car_title_on_creation'

export const GET_LAST_CAR_INSURANCE_ENTRY_REQUEST =
    '@@car/get_last_insurance_entry/REQUEST'
export const GET_LAST_CAR_INSURANCE_ENTRY_SUCCESS =
    '@@car/get_last_insurance_entry/SUCCESS'
export const GET_LAST_CAR_INSURANCE_ENTRY_ERROR =
    '@@car/get_last_insurance_entry/ERROR'

export const GET_CAR_ACTION_CHECK_REQUEST = '@@car/actions_checks/get/REQUEST'
export const GET_CAR_ACTION_CHECK_SUCCESS = '@@car/actions_checks/get/SUCCESS'
export const GET_CAR_ACTION_CHECK_ERROR = '@@car/actions_checks/get/ERROR'
export const RESET_CAR_INSIGHTS = '@@car/car_insights/reset/SUCCESS'

export const GET_CAR_INSIGHTS_SUCCESS = '@@car/get_insights/SUCCESS'
export const GET_CAR_INSIGHTS_REQUEST = '@@car/get_insights/REQUEST'
export const GET_CAR_INSIGHTS_ERROR = '@@car/get_insights/ERROR'

export const GET_CATEGORY_INSIGHTS_SUCCESS =
    '@@car_category/get_insights/SUCCESS'
export const GET_CATEGORY_INSIGHTS_REQUEST =
    '@@car_category/get_insights/REQUEST'
