import { ICar } from 'entityModels'

import { editFormsActions } from '../../../redux/editForms/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import WrapperCarBannerImageFormMobile from '../editForms/imageFormWrapper/wrapperCarBannerImageFormMobile'
import ModalDisplayMobileIpad from '../../templates/displays/pageWrappers/modalDisplayMobileExclusive'
import CarOverviewEditFormMobile from '../editForms/carOverviewEditForm/carOverviewEditFormMobile'
import DragNSortEditableManagerMobile from '../dragNSortManager/dragNSortEditableManagerMobile'
import WrapperImageFormsMobile from '../../organisms/editForms/imageFormWrapper/wrapperImageFormMobile'

type Props = {
    current: ICar
}

const CarOverviewFormsBodyMobile = (props: Props) => {
    let { current } = props

    let dispatch = useAppDispatch()

    let isCarOverviewFormEditOpened: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.overviewCarEditForm.isOpen
            ? state.editForms.formsData.overviewCarEditForm.isOpen
            : false
    })

    let isCargalleryFormEditOpened: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.carGalleryEditForm.isOpen
            ? state.editForms.formsData.carGalleryEditForm.isOpen
            : false
    })

    let iscarHighlightsFormEditOpened: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.carHighlightsEditForm
            ? state.editForms.formsData.carHighlightsEditForm.isOpen
            : false
    })

    let isCarBannerImageFormOpen: boolean = useAppSelector((state) => {
        return state.editForms.formsData &&
            state.editForms.formsData.carBannerImageForm
            ? state.editForms.formsData.carBannerImageForm.isOpen
            : false
    })

    let renderForm = () => {
        if (isCarOverviewFormEditOpened) {
            return (
                <CarOverviewEditFormMobile
                    carData={current}
                    close={() => {
                        dispatch(editFormsActions.toggleCarOverviewEditForm())
                    }}
                />
            )
        } else if (iscarHighlightsFormEditOpened) {
            return (
                <DragNSortEditableManagerMobile
                    car_id={current.id}
                    close={() => {
                        dispatch(editFormsActions.toggleCarHighlightsEditForm())
                    }}
                    highlighted_facts_ids={
                        current && current.highlighted_facts
                            ? current.highlighted_facts
                            : []
                    }
                />
            )
        } else if (isCargalleryFormEditOpened) {
            return (
                <WrapperImageFormsMobile
                    close={() => {
                        dispatch(editFormsActions.toggleCarGalleryEditForm())
                    }}
                    carid={current.id}
                />
            )
        } else if (isCarBannerImageFormOpen) {
            return (
                <WrapperCarBannerImageFormMobile
                    close={() => {
                        dispatch(editFormsActions.toggleCarBannerImageForm())
                    }}
                    carid={current.id}
                />
            )
        } else return null
    }

    let isAFormOpened =
        isCarOverviewFormEditOpened ||
        iscarHighlightsFormEditOpened ||
        isCargalleryFormEditOpened ||
        isCarBannerImageFormOpen
            ? true
            : false

    return isAFormOpened ? (
        <ModalDisplayMobileIpad toggle={() => {}} isOpen={true}>
            {renderForm()}
        </ModalDisplayMobileIpad>
    ) : (
        <div style={{ display: 'none' }} />
    )
}

export default CarOverviewFormsBodyMobile
