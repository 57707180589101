import * as React from 'react'
import RegistrationFormCard from '../registrationform/registrationFormCard'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import RegistrationWrapperImage from './registrationWrapperImage'
import RegistrationNestedImage from './registrationNestedImage'
import BannerTextSVG from './bannerTextSvg'
import { RouteComponentProps, withRouter } from 'react-router'

const BannerTextWideScreenWrapper = styled.div`
    position: absolute;
    bottom: 50px;
    left: 50px;

    @media (max-width: 1599px) {
        display: none;
    }
`

const FormWrapper = styled.section`
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;

    height: 100%;

    @media (max-width: 1600px) {
        position: relative;
        justify-self: flex-end;
        border-radius: 0px;
        width: 30%;
        padding-left: 60px;
        padding-right: 60px;
    }

    @media ${device.mobile} {
        height: 100%;
        padding-top: 30px;
        padding-bottom: 20px;
        width: 100%;
        box-shadow: none;
        border-radius: 0px;
        padding-left: 32px;
        padding-right: 32px;
    }

    @media ${device.ipad} {
        height: auto;
        width: 100%;
        padding-top: 30px;
        /* padding-bottom: 30px; */
        box-shadow: none;
        border-radius: 0px;
        padding-left: 40px;
        padding-right: 40px;
    }

    @media ${device.large_desktop} {
        padding-top: 30px;
        padding-bottom: 50px;
        border-radius: 10px;
        box-shadow: 4px 6px 10px var(--box-shadow, rgba(0, 0, 0, 0.08));
        max-width: 480px;
        padding-left: 50px;
        padding-right: 50px;
        height: 100%;
        /* height: 90vh; */
        max-height: 105vh;
        overflow-y: scroll;

        /* background-color: red; */

        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */

        ::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
        transform: scale(0.85);
    }

    @media (min-width: 2150px) {
        transform: scale(0.9);
    }
`

interface Props extends RouteComponentProps<any> {
    invite_code: any
}

class RegistrationFourthPage extends React.Component<Props, {}> {
    state = {}

    render() {
        let params = new URLSearchParams(this.props.location.search)
        let email_q_parame = params.get('email')

        let landing = email_q_parame ? 'share-invite' : params.get('landing')

        switch (landing) {
            case 'landrover_old':
                return (
                    <RegistrationWrapperImage customImage="landrover_front">
                        <BannerTextWideScreenWrapper>
                            <BannerTextSVG />
                        </BannerTextWideScreenWrapper>
                        <RegistrationNestedImage customImage="landrover_front">
                            <BannerTextSVG />
                        </RegistrationNestedImage>
                        <FormWrapper>
                            <RegistrationFormCard
                                invite_code={this.props.invite_code}
                            />
                        </FormWrapper>
                    </RegistrationWrapperImage>
                )
            case 'landrover_new':
                return (
                    <RegistrationWrapperImage customImage="landrover_back">
                        <BannerTextWideScreenWrapper>
                            <BannerTextSVG />
                        </BannerTextWideScreenWrapper>
                        <RegistrationNestedImage customImage="landrover_back">
                            <BannerTextSVG />
                        </RegistrationNestedImage>
                        <FormWrapper>
                            <RegistrationFormCard
                                invite_code={this.props.invite_code}
                            />
                        </FormWrapper>
                    </RegistrationWrapperImage>
                )
            case 'porsche_new':
                return (
                    <RegistrationWrapperImage customImage="porsche_new">
                        <BannerTextWideScreenWrapper>
                            <BannerTextSVG customImage="porsche_new" />
                        </BannerTextWideScreenWrapper>
                        <RegistrationNestedImage customImage="porsche_new">
                            <BannerTextSVG customImage="porsche_new" />
                        </RegistrationNestedImage>
                        <FormWrapper>
                            <RegistrationFormCard
                                invite_code={this.props.invite_code}
                            />
                        </FormWrapper>
                    </RegistrationWrapperImage>
                )
            case 'porsche_old':
                return (
                    <RegistrationWrapperImage customImage="porsche_old">
                        <BannerTextWideScreenWrapper>
                            <BannerTextSVG />
                        </BannerTextWideScreenWrapper>
                        <RegistrationNestedImage customImage="porsche_old">
                            <BannerTextSVG />
                        </RegistrationNestedImage>
                        <FormWrapper>
                            <RegistrationFormCard
                                invite_code={this.props.invite_code}
                            />
                        </FormWrapper>
                    </RegistrationWrapperImage>
                )
            case 'bmw':
                return (
                    <RegistrationWrapperImage customImage="bmw">
                        <BannerTextWideScreenWrapper>
                            <BannerTextSVG />
                        </BannerTextWideScreenWrapper>
                        <RegistrationNestedImage customImage="bmw">
                            <BannerTextSVG />
                        </RegistrationNestedImage>
                        <FormWrapper>
                            <RegistrationFormCard
                                invite_code={this.props.invite_code}
                            />
                        </FormWrapper>
                    </RegistrationWrapperImage>
                )

            case 'share-invite':
                return (
                    <RegistrationWrapperImage customImage="share-invite">
                        <BannerTextWideScreenWrapper>
                            <BannerTextSVG />
                        </BannerTextWideScreenWrapper>
                        <RegistrationNestedImage customImage="share-invite">
                            <BannerTextSVG />
                        </RegistrationNestedImage>
                        <FormWrapper>
                            <RegistrationFormCard
                                invite_code={this.props.invite_code}
                            />
                        </FormWrapper>
                    </RegistrationWrapperImage>
                )

            default:
                return (
                    <RegistrationWrapperImage>
                        <BannerTextWideScreenWrapper>
                            <BannerTextSVG />
                        </BannerTextWideScreenWrapper>
                        <RegistrationNestedImage>
                            <BannerTextSVG />
                        </RegistrationNestedImage>
                        <FormWrapper>
                            <RegistrationFormCard
                                invite_code={this.props.invite_code}
                            />
                        </FormWrapper>
                    </RegistrationWrapperImage>
                )
        }
    }
}

export default withRouter(RegistrationFourthPage)
