import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
// import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import { IGalleryImage } from 'entityModels'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import { ITheme } from '../../../providers/theme/colours'

type IImgProps = {
    isthingloading: boolean
}
const ImageStyle = styled.div<IImgProps>`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isthingloading === false ? '1' : '0')};
    transition: opacity 400ms;
`

const ImageStyleWrapper = styled.div<IImgProps>`
    width: 100%;
    position: relative;
    margin-bottom: 3px;

    @media ${device.large_desktop} {
        height: 700px;
    }

    @media ${device.desktop} {
        height: 600px;
    }

    @media ${device.smallest_laptops} {
        height: 530px;
    }
`

export interface IImageProps {
    img?: IGalleryImage | null | undefined
    placeholder?: string
    children: any
}

type State = {
    src: any
    placeholder?: any
    isLoading: boolean
    theme: ITheme
}

class GarageCarBanner extends React.Component<IImageProps, State> {
    static contextType = ThemesContext

    state = {
        src: null,
        placeholder: null,
        isLoading: true,
        theme: this.context?.theme,
    }

    _isMounted = false

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                ...this.state,
                isLoading: false,
            })
    }

    componentDidMount() {
        this._isMounted = true

        if (this.props.img && this.props.img?.url) {
            this._isMounted &&
                this.setState({
                    ...this.state,
                    src: this.props.img.url,
                    placeholder: null,
                })
            this.onLoad(this.props.img.url)
            return
        } else {
            let i: string = randomCarSvgPlaceholderFunc(
                undefined,
                this.state.theme
            )
            this._isMounted &&
                this.setState({
                    ...this.state,
                    src: undefined,
                    placeholder: i,
                    isLoading: false,
                })
            return
        }
    }

    componentDidUpdate(prevProps: IImageProps, prevState: State) {
        if (this.props.img?.url !== prevProps.img?.url) {
            const src = this.props.img?.url

            if (src !== undefined) {
                this.setState({
                    ...this.state,
                    src: src,
                    placeholder: null,
                    isLoading: true,
                })
                this.onLoad(src)
            } else {
                let i: string = randomCarSvgPlaceholderFunc(
                    undefined,
                    this.state.theme
                )
                return this.setState({
                    ...this.state,
                    src: undefined,
                    placeholder: i,
                    isLoading: false,
                })
            }
        }

        if (this.context !== prevState.theme) {
            this.setState({ theme: this.context })
            const src = this.props.img?.url
            if (src === undefined) {
                let i: string = randomCarSvgPlaceholderFunc(
                    undefined,
                    this.context?.theme
                )
                return this.setState({
                    placeholder: i,
                })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let { isLoading, src, placeholder } = this.state

        return (
            <ImageStyleWrapper isthingloading={isLoading}>
                <SkeletonAbsolute isthingloading={isLoading} />

                <ImageStyle
                    role="img"
                    bg-srcset={
                        this.props.img && this.props.img.medium_srcset
                            ? this.props.img.medium_srcset
                            : undefined
                    }
                    aria-label="car overview banner"
                    style={{
                        backgroundImage: `url(${src || placeholder})`,
                    }}
                    isthingloading={isLoading}
                >
                    {this.props.children}
                </ImageStyle>
            </ImageStyleWrapper>
        )
    }
}

export default GarageCarBanner
