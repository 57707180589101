import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../../services'
import { ICustomErrorData, IDropdownItem, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { RootState } from 'typesafe-actions'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'

export type ISetModelDropdownDataReq = {
    makeName: string
    make_uid?: string | undefined
}

const getCarMakesState = (state: RootState): IDropdownItem[] | null =>
    state.localdata.dropdownData.carMakes

function* setModelDropdownDataApi(payload: ISetModelDropdownDataReq) {
    // console.log('setModelDropdownDataApi', payload)
    let makesList: IDropdownItem[] | null = yield select(getCarMakesState)
    let makeId: undefined | string

    makeId = payload?.make_uid

    let item: IDropdownItem

    if (!makesList) {
        makesList = yield api.dropdownsData.get_car_make_dropdown_api()

        if (makesList) {
            yield put(dropDownActions.setMakeDropdownDataSuccess(makesList))

            if (!makeId) {
                yield put(dropDownActions.setModelDropdownDataRequest(payload))
                for (const itemIndex in makesList) {
                    item = makesList[itemIndex]
                    if (
                        payload !== undefined &&
                        typeof payload.makeName === 'string' &&
                        typeof item.name === 'string'
                    ) {
                        if (
                            item.name?.toLowerCase() ===
                            payload.makeName?.toLowerCase()
                        ) {
                            if (!item.isNew) {
                                makeId = item.uid
                                break
                            }
                        }
                    }
                }
            }
        }
    }

    if (makesList && !makeId) {
        for (const objItem of makesList) {
            item = objItem

            if (item.name === payload.makeName) {
                if (!item.isNew) {
                    makeId = item.uid
                    break
                }
            }
        }
    }

    if (makeId) {
        try {
            const carModelDataReceived: IDropdownItem[] =
                yield api.dropdownsData.get_car_model_dropdown_api(makeId)
            yield put(
                dropDownActions.setModelDropdownDataSuccess(
                    carModelDataReceived
                )
            )
        } catch (error: any) {
            if (error.status === 401) {
                let p: IUnauthHandlerPayload = {
                    functionToRepeat: setModelDropdownDataApi,
                    payload: payload,
                }
                yield call(unauthHandlerSaga, p)
            } else if (error === Error) {
                let customErrorData: ICustomErrorData = {
                    custom_message: `Looks like your internet has stoped working`,
                    custom_message_detail: 'Check your connection and refresh',
                    custom_user_action_text: 'Refresh page',
                }

                let customError: IReduxError = ConvertToReduxError(
                    { status: '500' },
                    customErrorData
                )
                yield put(
                    dropDownActions.setModelDropdownDataError(customError)
                )
            } else {
                let typedError: IReduxError = ConvertToReduxError(error)
                yield put(dropDownActions.setModelDropdownDataError(typedError))
            }
        }
    } else {
        let defaultOptions = [
            {
                uid: 'nodata',
                name: '-',
            },
        ]

        yield put(dropDownActions.setModelDropdownDataSuccess(defaultOptions))
    }
}

function* watcherSetModelDropdownData() {
    while (true) {
        const { payload } = yield take(
            dropDownActions.setModelDropdownDataRequest
        )

        let p: ISetModelDropdownDataReq = {
            makeName: payload.makeName,
        }

        yield call(setModelDropdownDataApi, p)
    }
}

const set_model_dropdown_data: any[] = [fork(watcherSetModelDropdownData)]
export default set_model_dropdown_data
