import ModalDisplayFullPageDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import { RouteComponentProps, useHistory, withRouter } from 'react-router'
import { useEffect, useState } from 'react'
import CustomAnimatedRadioField from '../../atoms/formField/customAnimatedRadioField'
import BottomBarBtnsStep from '../../atoms/Button/bottomBarBtnsStep'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import warning_red from '../../../public/assets/icons/warning_red.svg'
import chevronIcon from '../../../public/assets/icons/chevron_grey_to_right.svg'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import TextArea from '../../atoms/textarea/textarea'
import { archiveCarRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { editFormsActions } from '../../../redux/editForms/reducer'

type Props = {
    isOpen: boolean
    toggle: (p: boolean) => any
    carid: string
    initialModalOpen?: 'success' | 'start' | 'reasons'
}

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 24px;

    text-align: center;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        font-size: 18px;
    }
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: flex-start;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    max-width: 420px;
`

const Question = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: flex-start;
    letter-spacing: 5e-5em;
    color: var(--text-strong, #1a1a1a);
    max-width: 400px;
`
const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media ${device.mobile_and_ipad} {
        padding-top: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--border-muted, #e5e5e5);
    }
`

const Row2 = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
`
const RowSpace = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media ${device.mobile_and_ipad} {
        padding-top: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid var(--border-muted, #e5e5e5);
    }
`

const Note = styled.div`
    font-family: Lato-Light;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: flex-start;
    letter-spacing: 5e-5em;
    color: var(--text-muted);
    max-width: 420px;

    background-color: var(--off-bg-color, #fafafa);
    border-radius: 3px;
    padding: 10px;
`

export const archiveReasonsOptions = [
    {
        id: 'stolen',
        name: 'The car was stolen',
    },
    {
        id: 'crashed',
        name: 'The car was crashed',
    },
    {
        id: 'dont_want',
        name: 'I don’t want to use Custodian anymore',
    },
    {
        id: 'other',
        name: 'Other',
    },
]

export const archiveReasonsCopyData = {
    start: {
        title: 'Are you sure?',
        p1: 'If you have sold or gifted your car please consider handing it over to it’s new owner so that they can benefit from the car’s digital history.',
        p2: 'Whether you choose to handover or archive your car it will be available to view in the “Previously owned” tab in your garage.',
        note: `Note: If your car is empty, it will simply be deleted instead.`,
    },
    reasons: {
        title: 'Archive confirmation',
        question: "What's the main reason for wanting to archive your car?",
        options: archiveReasonsOptions,
    },
    success: {
        title: 'Car archived',
        p1: 'Your car was successfully moved to Previously owned tab in your garage. You can still see it but you won’t be able to edit any of it’s details.',
        p2: 'If the car had no data like make or model, for example, it will be deleted instead of archived.',
    },
}
type PropsStart = {
    onHandoverClick: any
    onArchiveClick: any
    close: any
}
export const ModalArchiveStart = (props: PropsStart) => {
    return (
        <div style={{ width: '100%' }}>
            <RowSpace>
                <Title>{archiveReasonsCopyData.start.title}</Title>
                <Row2>
                    <img src={warning_red} alt="warning" />
                    <div style={{ paddingLeft: '10px', color: '#DF6F6F' }}>
                        Not reversible!
                    </div>
                </Row2>
            </RowSpace>

            <div style={{ paddingTop: '24px' }} />

            <Subtitle>{archiveReasonsCopyData.start.p1}</Subtitle>

            <div style={{ paddingTop: '24px' }} />

            <Subtitle>{archiveReasonsCopyData.start.p2}</Subtitle>

            <div style={{ paddingTop: '24px' }} />

            <Note>{archiveReasonsCopyData.start.note}</Note>

            <BottomBarBtnsStep
                cancel={() => {
                    props.close()
                }}
                submit={() => {
                    props.onHandoverClick()
                }}
                confirmationtext="Handover"
                disabled={false}
                hasGoBack={true}
                removeLine={false}
                onGoback={() => {
                    props.close()
                }}
                removeCancel={true}
                redAction={() => {
                    props.onArchiveClick()
                }}
                redActionCopy={'Archive'}
                customBg="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
        </div>
    )
}

type PropsReasons = {
    onSelect: (id: string) => any
    activeID: string | undefined
    message: string | undefined
    setMessage: (id: string | undefined) => any
    submitArchive: any
    onCancel: any
    isDiabled?: boolean
}

const TextAreaWidthOneLiner = styled.div`
    width: 100%;
    height: 168px;
    border: 1px solid var(--text-muted);
    border-radius: 4px;
`
const Caption = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.0001em;
    color: var(--text-darker, #616161);
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    padding: 5px;
    margin-left: 8px;
`

const Center = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
`
export const ModalArchiveReasons = (props: PropsReasons) => {
    return (
        <>
            <Row>
                <img
                    style={{ transform: 'rotate(180deg)' }}
                    src={chevronIcon}
                    alt="return"
                />
                <div style={{ paddingLeft: '20px' }} />
                <Title>{archiveReasonsCopyData.reasons.title}</Title>
            </Row>

            <div style={{ paddingTop: '24px' }} />

            <Question>{archiveReasonsCopyData.reasons.question}</Question>

            <div style={{ paddingTop: '24px' }} />

            {archiveReasonsCopyData.reasons.options.map(
                (item: any, index: number) => {
                    return (
                        <CustomAnimatedRadioField
                            name={item.name}
                            id={item.id}
                            checked={item.id === props.activeID ? true : false}
                            onChange={(id: string) => {
                                props.onSelect(id)
                            }}
                            rowHeight={'36px'}
                            extra_small
                        />
                    )
                }
            )}

            {props.activeID === 'other' && (
                <>
                    <div style={{ paddingLeft: '8px' }} />
                    <Caption style={{ transform: 'translateY(10px)' }}>
                        Please specify why (optional)
                    </Caption>
                    <TextAreaWidthOneLiner>
                        <TextArea
                            id="short_description"
                            name="short_description"
                            placeholder="Type here..."
                            tabindex={2}
                            aria-live="polite"
                            // formikprops={props}
                            handleChange={(e: any) => {
                                props.setMessage(e.target.value)
                            }}
                            value={props.message}
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />
                    </TextAreaWidthOneLiner>
                </>
            )}

            <BottomBarBtnsStep
                cancel={() => {}}
                submit={() => {}}
                removeCancel={true}
                removeMain={true}
                redAction={() => {
                    props.submitArchive()
                }}
                redActionCopy={'Archive'}
                confirmationtext=""
                disabled={props.isDiabled}
                hasGoBack={true}
                goBackText="Cancel"
                removeLine={false}
                onGoback={props.onCancel}
                customBg="var(--modal-side-sheet-pop-over-bg, #fff)"
            />
        </>
    )
}

type PropsSuccess = {
    onClose: any
}
export const ModalArchiveSuccess = (props: PropsSuccess) => {
    return (
        <Center>
            <Title style={{ textAlign: 'center', width: '100%' }}>
                {archiveReasonsCopyData.success.title}
            </Title>

            <div style={{ paddingTop: '16px' }} />

            <Subtitle style={{ textAlign: 'center', width: '100%' }}>
                {archiveReasonsCopyData.success.p1}
            </Subtitle>
            <div style={{ paddingTop: '16px' }} />

            <Subtitle style={{ textAlign: 'center', width: '100%' }}>
                {archiveReasonsCopyData.success.p2}
            </Subtitle>

            <div style={{ paddingTop: '48px' }} />

            <ButtonAtom
                theme="main-cta-onboarding"
                onClick={() => {
                    // history.replace(`/garage`)
                    props.onClose()
                }}
                disabled={false}
                dataCyId={`archivesuccess`}
                height={'48px'}
                fontSize={16}
            >
                Close
            </ButtonAtom>
        </Center>
    )
}

const ShowroomModalInnerContainer = styled.div`
    height: 100%;
    max-height: 90vh;
    width: 480px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 2px;

    @media ${device.mobile_and_ipad} {
        width: 95vw;
    }

    @media ${device.beyond_ipad_mobile} {
        min-width: 500px;
    }

    z-index: 20;
    transition: all 100ms ease-in-out;
    padding: 24px;
    position: relative;
`

const ArchiveCarDesktopFormManager = (props: Props & RouteComponentProps) => {
    let { isOpen, toggle, carid } = props

    let activeArchivingStep = useAppSelector(
        (state) => state.editForms.activeArchivingStep
    )

    useEffect(() => {
        dispatch(editFormsActions.setArchivingStep('start'))
    }, [])

    const [reason, setReason] = useState<string | undefined>(undefined)
    const [message, setMessage] = useState<string | undefined>(undefined)

    const dispatch = useAppDispatch()

    let history = useHistory()

    return (
        <ModalDisplayFullPageDesktopExclusive
            toggle={toggle}
            isLightMode={false}
            isOpen={isOpen}
        >
            <ShowroomModalInnerContainer
                style={{
                    height:
                        activeArchivingStep === 'start'
                            ? '430px'
                            : activeArchivingStep === 'reasons' &&
                              reason === 'other'
                            ? '584px'
                            : activeArchivingStep === 'success'
                            ? '312px'
                            : '400px',
                }}
            >
                {activeArchivingStep === 'start' && (
                    <ModalArchiveStart
                        onHandoverClick={() => {
                            history.push(`/handover/${props.carid}`)
                        }}
                        onArchiveClick={() => {
                            dispatch(
                                editFormsActions.setArchivingStep('reasons')
                            )
                        }}
                        close={toggle}
                    />
                )}
                {activeArchivingStep === 'reasons' && (
                    <ModalArchiveReasons
                        message={message}
                        setMessage={(msg: string | undefined) => {
                            setMessage(msg)
                        }}
                        onSelect={(id: string) => {
                            reason === id ? setReason(undefined) : setReason(id)
                        }}
                        activeID={reason}
                        submitArchive={() => {
                            dispatch(
                                archiveCarRequest({
                                    carID: carid,
                                    data: {
                                        archived_reason: 'custom',
                                        custom_reason: reason ?? undefined,
                                        custom_note: message ?? undefined,
                                    },
                                })
                            )
                            // success step will be set inside archiving saga if no error
                        }}
                        onCancel={toggle}
                        isDiabled={reason === undefined ? true : false}
                    />
                )}
                {activeArchivingStep === 'success' && (
                    <ModalArchiveSuccess
                        onClose={() => {
                            props.history.push('/garage/previously-owned')
                            // cleanup redux
                            dispatch(
                                editFormsActions.setArchivingStep(undefined)
                            )
                        }}
                    />
                )}
            </ShowroomModalInnerContainer>
        </ModalDisplayFullPageDesktopExclusive>
    )
}

export default withRouter(ArchiveCarDesktopFormManager)
