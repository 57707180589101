import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { DatePicker } from 'antd'
import moment from 'moment'
import { useHistory } from 'react-router'
import {
    convertDateToBeFormat,
    convertDateToUTCFormatWithTimeSetToZero,
} from '../../../../redux/conversions/time/dates'
import { generateCarInsightsTimeRangeBEFormat } from '../../../../helpers/time/carInsights'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { ICar, ICarsObject } from 'entityModels'
import {
    getCarCategoryInsightsRequest,
    getCarInsightsRequest,
} from '../../../../redux/entities/cars/actions/loadingActions'
import './styles.css'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type IStyle = { $theme: ITheme; $isActive?: boolean }

const Wrapper = styled.div<IStyle>`
    z-index: 1;
    scroll-behavior: smooth;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    gap: ${(props) => (props.$theme === 'light' ? '4px' : '0px')};

    border-radius: 4px;
    background: ${(props) =>
        props.$theme === 'light'
            ? colours[props.$theme].background_default
            : '#161a1a'};
    width: fit-content;

    @media ${device.mobile_and_ipad} {
        position: absolute;
        width: 100%;
        overflow-x: scroll;
        padding-right: 50px;
        top: 0px;
    }

    @media ${device.ipad} {
        width: fit-content;
        top: 10px;
    }
`

const Tile = styled.button<IStyle>`
    all: unset;
    white-space: nowrap;
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    transition: 100ms;
    background: ${(props) =>
        props.$isActive
            ? props.$theme === 'light'
                ? colours[props.$theme].background_default
                : colours[props.$theme].background_neutral_subtle
            : 'transparent'};
    box-shadow: ${(props) =>
        props.$isActive && props.$theme === 'light'
            ? `0px 4px 8px 0px ${colours[props.$theme].shadow_strongest_09}`
            : 'none'};
    cursor: pointer;

    :hover {
        background: ${(props) =>
            props.$theme === 'light'
                ? colours[props.$theme].background_default
                : colours[props.$theme].background_neutral_subtle};
        box-shadow: 0px 4px 8px 0px
            ${(props) => colours[props.$theme].shadow_strongest_09};
    }

    .ant-picker-panel-container {
        flex-direction: column !important;
        display: flex !important;
    }

    .ant-picker {
        border: none !important;
        height: 24px !important;
        min-width: 192px !important;
    }

    .ant-picker-range .ant-picker-active-bar {
        background: none !important;
    }

    .ant-picker-focused {
        box-shadow: none !important;
    }

    input {
        font-family: Lato !important;
    }
`

export type ITimeRangeEnum = 'year' | 'last_year' | 'all_time' | 'custom'

type IItem = {
    id: ITimeRangeEnum
    name: string
}
let items: IItem[] = [
    {
        id: 'year',
        name: 'This year',
    },
    {
        id: 'last_year',
        name: 'Last year',
    },
    {
        id: 'all_time',
        name: 'All time',
    },
    {
        id: 'custom',
        name: 'Custom',
    },
]

type Props = {
    hasCosts: boolean
}
export const CarInsightsTimeMenu = (props: Props) => {
    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    const carID = useAppSelector((state) => {
        return state.entities.carsData.currentCarID
    })

    let current: ICar | undefined = carID ? carsData[carID] : undefined

    let currURL = new URL(window.location.href)
    let params2 = new URLSearchParams(currURL.search)

    let categoryID: string | null | undefined = params2.get('categoryID')

    let representation: 'monthly' | 'yearly' =
        params2.get('representation') === 'yearly' ? 'yearly' : 'monthly'

    let selectedID: ITimeRangeEnum =
        params2.get('time_range') === 'year'
            ? 'year'
            : params2.get('time_range') === 'last_year'
            ? 'last_year'
            : params2.get('time_range') === 'all_time'
            ? 'all_time'
            : params2.get('time_range') === 'custom'
            ? 'custom'
            : 'year'

    const { RangePicker } = DatePicker

    let custom_start_date: moment.Moment | undefined | null = params2.get(
        'start_date'
    )
        ? moment(decodeURIComponent(params2.get('start_date')!), 'DD-MM-YYYY')
        : undefined

    let custom_end_date: moment.Moment | undefined | null = params2.get(
        'end_date'
    )
        ? moment(decodeURIComponent(params2.get('end_date')!), 'DD-MM-YYYY')
        : undefined

    const history = useHistory()

    const setSelectedID = (id: ITimeRangeEnum) => {
        params2.delete('start_date')
        params2.delete('end_date')
        params2.delete('time_range')
        params2.append('time_range', id)
        history.replace({
            search: params2.toString(),
        })
    }

    let carInsights = current?.insights

    const dispatch = useAppDispatch()

    const search = (valueID: ITimeRangeEnum) => {
        if (props.hasCosts) {
            if (carID) {
                let be_date_range: string[] | undefined = undefined

                // 1. GET TIME RANGES
                if (valueID !== 'custom') {
                    be_date_range =
                        generateCarInsightsTimeRangeBEFormat(valueID)
                } else {
                    if (params2.get('start_date')) {
                        let t1 = convertDateToBeFormat(
                            decodeURIComponent(params2.get('start_date')!)
                        )
                        let t2 = convertDateToBeFormat(
                            decodeURIComponent(params2.get('end_date')!)
                        )
                        be_date_range = [t1, t2]
                    }
                }

                if (
                    categoryID !== null &&
                    categoryID !== undefined &&
                    be_date_range &&
                    be_date_range[0] &&
                    be_date_range[1]
                ) {
                    if (valueID === 'custom') {
                        dispatch(
                            getCarCategoryInsightsRequest({
                                carid: carID,
                                categoryID: categoryID,
                                timeRange: valueID,
                                representation:
                                    representation === 'yearly'
                                        ? 'yearly'
                                        : 'monthly',
                                start_date: be_date_range[0],
                                end_date: be_date_range[1],
                            })
                        )
                    } else if (
                        !carInsights ||
                        !carInsights.categories ||
                        !carInsights.categories[categoryID] ||
                        !carInsights.categories[categoryID][valueID] ||
                        !carInsights.categories[categoryID][valueID]?.[
                            representation
                        ]
                    ) {
                        dispatch(
                            getCarCategoryInsightsRequest({
                                carid: carID,
                                categoryID: categoryID,
                                timeRange: valueID,
                                representation:
                                    representation === 'yearly'
                                        ? 'yearly'
                                        : 'monthly',
                                start_date: be_date_range[0],
                                end_date: be_date_range[1],
                            })
                        )
                    }
                } else if (
                    be_date_range &&
                    be_date_range[0] &&
                    be_date_range[1]
                ) {
                    // GENERAL SEARCH
                    if (valueID === 'custom') {
                        dispatch(
                            getCarInsightsRequest({
                                carid: carID,
                                timeRange: valueID,
                                start_date: be_date_range[0],
                                end_date: be_date_range[1],
                            })
                        )
                    } else if (
                        !carInsights ||
                        !carInsights.categories ||
                        !carInsights.categories[valueID]
                    ) {
                        dispatch(
                            getCarInsightsRequest({
                                carid: carID,
                                timeRange: valueID,
                                start_date: be_date_range[0],
                                end_date: be_date_range[1],
                            })
                        )
                    }
                }
            }
        }
    }

    const setCustomTimeRange = (
        id_start: moment.Moment,
        id_end: moment.Moment
    ) => {
        params2.delete('start_date')
        params2.delete('end_date')

        params2.append(
            'start_date',
            convertDateToUTCFormatWithTimeSetToZero(id_start.toISOString())
        )
        params2.append(
            'end_date',
            convertDateToUTCFormatWithTimeSetToZero(id_end.toISOString())
        )

        history.replace({
            search: params2.toString(),
        })
        search('custom')
    }

    useEffect(() => {
        if (userLoggedIn && carID) {
            // CATEGORY SPECIFIC
            if (categoryID) {
                if (selectedID === 'custom') {
                    search(selectedID)
                } else if (
                    !current?.insights?.categories?.[categoryID]?.[
                        selectedID
                    ] ||
                    !current?.insights?.categories?.[categoryID]?.[
                        selectedID
                    ]?.[representation]
                ) {
                    search(selectedID)
                }
            } else {
                if (selectedID === 'custom') {
                    search('custom')
                } else if (!current?.insights?.[selectedID]) {
                    search(selectedID)
                }
            }
        }
    }, [carID, userLoggedIn, representation, categoryID, selectedID])

    const { theme } = useThemes()

    return (
        <Wrapper $theme={theme}>
            {items.map((item, index) => {
                return (
                    <Tile
                        $theme={theme}
                        data-ph={item.id}
                        id={item.id}
                        $isActive={item.id === selectedID ? true : false}
                        key={item.id}
                        role="button"
                        onClick={() => {
                            if (selectedID !== item.id) {
                                setSelectedID(item.id)

                                // search(item.id)
                            }
                        }}
                    >
                        {item.name}
                    </Tile>
                )
            })}

            {selectedID === 'custom' && (
                <>
                    <Tile
                        $theme={theme}
                        $isActive={true}
                        key={'custom_ranges'}
                        data-ph={'date_ranges'}
                        style={
                            theme === 'dark'
                                ? {
                                      borderTopLeftRadius: 0,
                                      borderBottomLeftRadius: 0,
                                      transform: 'translateX(-4px)',
                                  }
                                : {}
                        }
                    >
                        <RangePicker
                            autoFocus={true}
                            format={'DD/MM/YYYY'}
                            defaultValue={
                                custom_start_date && custom_end_date
                                    ? [custom_start_date, custom_end_date]
                                    : undefined
                            }
                            allowEmpty={[false, false]}
                            onChange={(elem) => {
                                if (elem && elem[0] && elem[1]) {
                                    setCustomTimeRange(elem[0], elem[1])
                                }
                            }}
                            style={
                                theme === 'dark'
                                    ? {
                                          backgroundColor:
                                              colours[theme]
                                                  .background_neutral_subtle,
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                      }
                                    : {}
                            }
                        />
                    </Tile>

                    <div style={{ paddingLeft: '30px' }} />
                </>
            )}
        </Wrapper>
    )
}
