import { message } from 'antd'
import { IGalleryImage } from 'entityModels'
import heic2any from 'heic2any'

export const generateImageSources = (
    item: IGalleryImage | undefined,
    screenWidth: number | undefined
): string | undefined => {
    let res: string | undefined = item?.url
    if (item && screenWidth) {
        if (item.small_srcset && screenWidth < 800) {
            return item.small_srcset
        }
        if (item.medium_srcset && screenWidth < 1600) {
            return item.medium_srcset
        }
        if (item.large_src && screenWidth >= 1600) {
            return item.large_src
        }
    }
    return res
}

export const generateImagePreviewUrls = async (file: Blob | File) => {
    try {
        // Convert HEIC/HEIF to JPEG/PNG
        const conversionResult = await heic2any({
            blob: file,
            toType: 'image/jpeg',
        })

        if (conversionResult instanceof Blob) {
            // Create a URL for the converted image
            const url = URL.createObjectURL(conversionResult)
            return url
        } else {
            message.error(
                'An error occured, please try uploading your image again.'
            )
        }
    } catch (e) {
        message.error(
            'An error occured, please try uploading your image again.'
        )
    }
}
