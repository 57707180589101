import {
    ITaskNormalisedSingleInterviewItemState,
    IApexSingleInterviewItemState,
} from 'apexModels'
import React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { getSingleApexInterviewByUIDDataThunks } from '../../../redux/theapex/thunks'
import ApexTeamCreditsDesktop from '../../molecules/apex/reusable/credits/apexTeamCreditsDesktop'
import ApexTeamCreditsMobile from '../../molecules/apex/reusable/credits/apexTeamCreditsMobile'
import ApexSingleInterviewHeaderDesktop from '../../molecules/apex/singleInterview/apexSingleInterviewHeaderDesktop'
import ApexSingleInterviewHeaderMobile from '../../molecules/apex/singleInterview/apexSingleInterviewHeaderMobile'
import ApexBodyRichContentManagerDesktop from '../../organisms/richContentManager/apex/apexBodyRichContentManagerDesktop'
import ApexBodyRichContentManagerMobile from '../../organisms/richContentManager/apex/apexBodyRichContentManagerMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import ReturnNav from '../../atoms/header/returnNav'
import LoaderBackground from '../../atoms/loader/loaderBackground'
import SideSlider from '../../templates/animated/sideSlider'

interface Props extends RouteComponentProps<any> {
    singleInterviews: ITaskNormalisedSingleInterviewItemState | undefined
    getSingleApexInterviewByUIDDataThunks: any
}

function mapStateToProps(state: RootState) {
    return {
        singleInterviews: state.apex.singleInterviews,
    }
}

const dispatchProps = {
    getSingleApexInterviewByUIDDataThunks: (uid: string) =>
        getSingleApexInterviewByUIDDataThunks(uid),
}

const Wrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        padding-top: 28px;
        padding-right: 30px;
        padding-left: 30px;
    }

    @media ${device.beyond_ipad_mobile} {
        padding-right: 20vw;
        padding-left: 20vw;
        padding-top: 26px;
        width: 100%;
    }

    @media ${device.smallest_laptops} {
        padding-right: 16vw;
        padding-left: 16vw;
    }

    @media ${device.ipad} {
        padding-right: 10vw;
        padding-left: 10vw;
    }

    @media ${device.large_desktop} {
        padding-top: 26px;
        padding-right: 8vw;
        padding-left: 8vw;
    }
`

// Date

const Date = styled.div`
    @media ${device.mobile_and_ipad} {
        font-size: 15px;
    }

    color: var(--text-muted, #b3b3b3);
    font-size: 16px;
    font-family: 'Lato-Light';
`
class ApexSingleInterview extends React.Component<Props, {}> {
    componentDidMount() {
        let uid = this.props.match.params.uid

        this.props.getSingleApexInterviewByUIDDataThunks(uid)
    }

    render() {
        let { singleInterviews, match } = this.props
        let uid = match.params.uid

        let interview: IApexSingleInterviewItemState | undefined =
            singleInterviews && uid && singleInterviews[uid]

        return (
            <React.Fragment>
                <CenteredPageWrapper>
                    <DesktopDisplayOnly>
                        {!interview && (
                            <LoaderBackground height={'60vh'} width={'100%'} />
                        )}

                        {interview && (
                            <Wrapper>
                                <ApexSingleInterviewHeaderDesktop
                                    title={interview.title}
                                    name={interview.name}
                                    uid={uid}
                                />
                                <div style={{ paddingTop: '50px' }} />
                                {/* intro  */}
                                <ApexBodyRichContentManagerDesktop
                                    rich_objects_array_without_slice={
                                        interview.introduction
                                    }
                                />
                                {/* team creds  */}
                                <ApexTeamCreditsDesktop
                                    text={interview.apex_team_credits}
                                />
                                {/* body  */}
                                <ApexBodyRichContentManagerDesktop
                                    rich_slice_objects_array={interview.body}
                                />

                                <div style={{ paddingTop: '50px' }} />

                                <Date>
                                    The Apex Interviews
                                    <br />
                                    {interview.date}
                                </Date>
                                {/* intro  */}
                            </Wrapper>
                        )}
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <Link
                            to={{
                                pathname: `/apex/interviews`,
                                state: { prevPath: this.props.match.url },
                            }}
                        >
                            <ReturnNav customText="all interviews" />
                        </Link>

                        {!interview && (
                            <LoaderBackground height={'60vh'} width={'100%'} />
                        )}
                        <SideSlider direction="right" initial="enter">
                            {interview && (
                                <Wrapper>
                                    <ApexSingleInterviewHeaderMobile
                                        title={interview.title}
                                        name={interview.name}
                                        uid={uid}
                                    />
                                    <div style={{ paddingTop: '33px' }} />
                                    {/* intro  */}
                                    <ApexBodyRichContentManagerMobile
                                        rich_objects_array_without_slice={
                                            interview.introduction
                                        }
                                    />

                                    {/* body  */}
                                    <ApexBodyRichContentManagerMobile
                                        rich_slice_objects_array={
                                            interview.body
                                        }
                                    />

                                    <div style={{ paddingTop: '40px' }} />

                                    {/* team creds  */}
                                    <ApexTeamCreditsMobile
                                        text={interview.apex_team_credits}
                                    />
                                    <div style={{ paddingTop: '25px' }} />

                                    <Date>
                                        The Apex Interviews
                                        <br />
                                        {interview.date}
                                    </Date>

                                    <div style={{ paddingTop: '190px' }} />
                                    {/* intro  */}
                                </Wrapper>
                            )}
                        </SideSlider>
                    </IpadAndMobileDisplay>
                </CenteredPageWrapper>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ApexSingleInterview)
)
