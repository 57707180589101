import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const OtherIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 2.3772C4.54767 2.3772 1.75 5.17487 1.75 8.6272C1.75 10.6686 2.72755 12.4813 4.24305 13.6228H11.757C13.2725 12.4813 14.25 10.6686 14.25 8.6272C14.25 5.17487 11.4523 2.3772 8 2.3772ZM0.25 8.6272C0.25 4.34644 3.71924 0.877197 8 0.877197C12.2808 0.877197 15.75 4.34644 15.75 8.6272C15.75 11.2618 14.4352 13.5889 12.429 14.988C12.3032 15.0758 12.1534 15.1228 12 15.1228H4C3.84657 15.1228 3.69682 15.0758 3.57097 14.988C1.56483 13.5889 0.25 11.2618 0.25 8.6272Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 7.3772C7.30949 7.3772 6.75 7.93668 6.75 8.6272C6.75 9.31771 7.30949 9.8772 8 9.8772C8.69051 9.8772 9.25 9.31771 9.25 8.6272C9.25 7.93668 8.69051 7.3772 8 7.3772ZM5.25 8.6272C5.25 7.10826 6.48106 5.8772 8 5.8772C9.51894 5.8772 10.75 7.10826 10.75 8.6272C10.75 10.1461 9.51894 11.3772 8 11.3772C6.48106 11.3772 5.25 10.1461 5.25 8.6272Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 9.3772H1V7.8772H6V9.3772Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 9.3772H10V7.8772H15V9.3772Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.25 14.6272L7.25 10.6272L8.75 10.6272L8.75 14.6272L7.25 14.6272Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default OtherIcon
