import * as Yup from 'yup'
import styled from 'styled-components'
import './styles.css'
import SearchIcon from '../../../atoms/statefulicons/SearchIcon'
import grey_cross from '../../../../public/assets/icons/close_cross_grey.svg'
import { Field, FormikProps, withFormik } from 'formik'
import useDebounce from '../../../../helpers/search/useDebounce'

export type OtherProps = {
    searchFor?: any
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    value?: any
    onSearch?: any
    clearSearch?: any
    onInputChange?: any
}

type IStyledProps = {
    $issearchactive?: boolean
    grey_cross?: string
}

const CustomisedSearchField = styled(Field)<IStyledProps>`
    width: 88vw;
    border: none;
    background-color: #f8f8f8 !important;
    border-radius: 5px;
    height: ${(props) =>
        props.$issearchactive === true ? '50px !important' : '38px !important'};
    padding-left: 50px;
    padding-top: 0px;
    ::placeholder {
        color: #bdbdbd !important;
        font-family: Lato-light;
    }
`

const GreyCross = styled.div<IStyledProps>`
    width: 12px;
    height: 12px;
    position: absolute;
    background-image: ${(props) => `url(${props.grey_cross})`};
    background-size: cover;
    right: 15px;
    top: 14px;
`

const SearchIconCustom = styled.div<IStyledProps>`
    position: absolute;
    z-index: 2;
    top: 12px;
    left: 20px;
`
const Wrapper = styled.div`
    position: relative;
`

interface Values {
    single_item_search_value_mobile: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        manageissearchactive,
        dataCyId,
        issearchactive,
        onSearch,
        values,
        clearSearch,
        resetForm,
        onInputChange,
        setFieldValue,
    } = props

    const debouncedSearch = useDebounce(onSearch, 500)

    const handleKeyUp = () => {
        debouncedSearch(values.single_item_search_value_mobile)
    }

    const handleChange = (e: any) => {
        setFieldValue('single_item_search_value_mobile', e.target.value)
        onInputChange(e.target.value)
    }

    return (
        <Wrapper data-attr={dataCyId} id="custom_search_mobile_bar">
            <div>
                <SearchIconCustom
                    $issearchactive={
                        issearchactive ? issearchactive : undefined
                    }
                >
                    <SearchIcon height={16} isActive={issearchactive} />
                </SearchIconCustom>
                <CustomisedSearchField
                    id={'single_item_search_value_mobile'}
                    name={'single_item_search_value_mobile'}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.single_item_search_value_mobile}
                    data-attr={props.dataCyId}
                    onKeyUp={handleKeyUp}
                    onChange={handleChange}
                    onClick={() =>
                        manageissearchactive && manageissearchactive(true)
                    }
                />
            </div>
            {values.single_item_search_value_mobile && (
                <GreyCross
                    grey_cross={grey_cross}
                    onClick={() => {
                        resetForm({
                            values: {
                                single_item_search_value_mobile:
                                    props.searchFor,
                            },
                        })
                        clearSearch()
                    }}
                />
            )}
        </Wrapper>
    )
}

const CarShareSearchBarMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_search_value_mobile: props.searchFor ? props.searchFor : '',
    }),
    enableReinitialize: true,

    // validationSchema: {},

    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_search_value_mobile: Yup.string()
                    .max(30, 'Your answer must be under 30 characters')
                    .min(2),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
    },
})(InnerForm)

export default CarShareSearchBarMobile
