import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// desktop carousel
import {
    CarouselProvider,
    Slider,
    Slide,
    // ButtonBack,
    Dot,
} from 'pure-react-carousel'

import 'pure-react-carousel/dist/react-carousel.es.css'
import './styles.css'

import styled from 'styled-components'

import ShowroomCarSlide from './showroomCarSlide'
import { ICarouselShowroomItem } from '../../../../redux/services/showroom/getCarousel'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'

const CarouselBtnPosition = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 50px;

    @media screen and (min-width: 800px) and (max-width: 1200px) {
        bottom: 20px;
    }

    @media (max-width: 1200px) {
        bottom: 34px;
    }
`

const SkeletonWrapper = styled.div`
    width: 100%;
    position: relative;

    @media screen and (min-width: 881px) and (max-width: 1399px) {
        height: 350px;
    }
    @media screen and (min-width: 1400px) and (max-width: 1600px) {
        height: 450px;
    }

    @media screen and (min-width: 1601px) {
        height: 500px;
    }

    @media screen and (min-width: 2000px) {
        height: 600px;
    }
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

type IProps = {
    slides: ICarouselShowroomItem[] | null
}

class ShowroomCarSliderDesktop extends React.Component<IProps, {}> {
    componentDidMount() {}

    render() {
        let { slides } = this.props

        return (
            <>
                {slides ? (
                    <CarouselProvider
                        naturalSlideWidth={2}
                        naturalSlideHeight={1}
                        totalSlides={slides.length}
                        visibleSlides={1}
                        className="slider-custom-sr"
                        infinite={true}
                        isPlaying={true}
                        playDirection="forward"
                        interval={5000}
                    >
                        <Slider style={{ height: '100%', width: '100%' }}>
                            {slides.map(
                                (
                                    item: ICarouselShowroomItem,
                                    index: number
                                ) => {
                                    if (item) {
                                        return (
                                            <Slide
                                                className="slide-sr"
                                                index={index}
                                                key={`slide-${index}`}
                                            >
                                                <ShowroomCarSlide
                                                    slide={item}
                                                />
                                            </Slide>
                                        )
                                    } else return null
                                }
                            )}
                        </Slider>

                        <CarouselBtnPosition>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <div onClick={() => {}}>
                                    {slides.map(
                                        (
                                            item: ICarouselShowroomItem,
                                            index: number
                                        ) => {
                                            return (
                                                <Dot
                                                    className="custom-dot-carousel-sr"
                                                    slide={index}
                                                    key={`dot-${index}`}
                                                />
                                            )
                                        }
                                    )}
                                </div>
                            </div>
                        </CarouselBtnPosition>
                    </CarouselProvider>
                ) : (
                    <SkeletonWrapper>
                        <SkeletonAbsolute isthingloading={true} />
                    </SkeletonWrapper>
                )}
            </>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(ShowroomCarSliderDesktop)
