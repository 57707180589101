import { IReminder, ITask, ITaskNormalisedTaskInfoList } from 'entityModels'
import { OTaskFilterNSort } from '../../../ol/ol'
import { fromIsoStringToDDMMYYY } from '../time'

import { checkTaskCarLinked } from './taskTypeConversions'

export let convertToDisplayedTaskObj = (
    task: ITask
    // timezone: string
): ITaskNormalisedTaskInfoList => {
    let res: ITaskNormalisedTaskInfoList = {}

    res = {
        title: {
            id: 'title',
            text: 'Title',
            answer: task.title,
        },
        status: {
            id: 'status',
            text: 'Status',
            answer: task.status
                ? OTaskFilterNSort.filter_by.completed.name
                : OTaskFilterNSort.filter_by.outstanding.name,
        },
        created_at: {
            id: 'created_at',
            text: 'Created on',
            answer: fromIsoStringToDDMMYYY(task.created_at),
        },
        due: {
            id: 'due',
            text: 'Due date',
            // answer: task.due
            //     ? fromIsoStringToDDMMYYYInTimezone(task.due, timezone)
            //     : '-',
            answer: task.due ? fromIsoStringToDDMMYYY(task.due) : '-',
        },

        reminders: {
            id: 'reminders',
            text: 'Reminders',
            answer: undefined,
            answers:
                task.reminders && task.reminders?.length > 0
                    ? task.reminders.map(
                          (item: IReminder) => item.displayDateText
                      )
                    : undefined,
        },
        body: {
            id: 'body',
            text: 'notes',
            answer: task.body ? task.body : 'no notes',
        },
        linkedToCar: {
            id: 'linkedToCar',
            text: 'Linked to',
            answer: checkTaskCarLinked(task.linkedToCar),
        },
    }
    return res
}
