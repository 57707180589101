// create store for todos (basically reducer for the main final store)

import {
    IDirectShareOwnedItem,
    IDirectShareOwnedItemsData,
    IGarageState,
} from 'entityModels'

import * as actions from './actions/actions'

import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: IGarageState = {
    garages: {},
    directly_shared_external_garages: {},
    error: null,
    loading: false,
    sharesLoading: false,
    tasks: {},
    cars_per_page: 10,
    current_page_number: 0,
    total_cars_number_found: undefined,
}

export const garagesData = (
    state: IGarageState = initialState,
    action: LoadingActions | Action | ErrorActions
): IGarageState => {
    switch (action.type) {
        // REQUESTS
        case loadingActions.setGarageDataRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.setGarageDataError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SET PAGE NUMBER
        case actions.setCurrentGaragePageNumber.type:
            return Object.assign({}, state, {
                ...state,
                current_page_number: action.payload,
            })

        // SET TOTAL CARS COUNT NUMBER (in any page being seen)
        case actions.setTotalCarsNumberFound.type:
            return Object.assign({}, state, {
                ...state,
                total_cars_number_found: action.payload,
            })

        // SUCCESS
        case actions.setGarageDataSuccess.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.uid]: action.payload,
                },

                loading: false,
                error: null,
            })

        // REQUESTS
        case loadingActions.getGarageByUserIdRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.getGarageByUserIdError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS
        case actions.getGarageByUserIdSuccess.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.uid]: action.payload,
                },

                loading: false,
                error: null,
            })

        // TASKS IDS LIST

        case loadingActions.setGarageTaskIdsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.setGarageTaskIdsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.setGarageTaskIdsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                tasks: {
                    ...state.tasks,
                    ...action.payload.tasks_normalised_by_garage,
                },
                garages: {
                    ...state.garages,
                    [action.payload.garage_id]: {
                        ...state.garages[action.payload.garage_id],
                        tasks_garage_level:
                            action.payload.tasks_ids_garage_level_only_list,
                    },
                },
            })

        // ADD CAR TO GARAGE LIST

        case loadingActions.addCarToGarageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.addACarToGarageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.addACarToGarageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                garages: {
                    ...state.garages,
                    [action.payload.garage_id]: state.garages[
                        action.payload.garage_id
                    ]
                        ? {
                              ...state.garages[action.payload.garage_id],
                              cars: state.garages[action.payload.garage_id].cars
                                  ? [
                                        action.payload.car_id,
                                        ...state.garages[
                                            action.payload.garage_id
                                        ].cars,
                                    ].filter(function (car_id) {
                                        return car_id !== 'new_car'
                                    })
                                  : [action.payload.car_id],
                          }
                        : {
                              uid: action.payload.garage_id,
                              cars: [action.payload.car_id],
                          },
                },
            })

        // not sure I want to show loading fullpage for this one..

        case actions.getPublicSharesGarageSuccess.type:
            let hasNoShares =
                Object.keys(action.payload.public_share).length === 0
                    ? true
                    : false

            return Object.assign({}, state, {
                ...state,
                sharesLoading: false,
                loading: false,
                error: null,
                garages: {
                    ...state.garages,
                    [action.payload.id]: {
                        ...state.garages[action.payload.id],
                        public_share_owned:
                            hasNoShares === true
                                ? null
                                : { ...action.payload.public_share },
                    },
                },
            })

        case errorActions.getPublicSharesGarageError.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: false,
                error: action.payload,
            })

        case loadingActions.getPublicSharesGarageRequest.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: true,
                error: null,
            })

        case loadingActions.createGaragePublicShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: true,
                error: null,
            })

        case errorActions.createGaragePublicShareError.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: false,
                error: action.payload,
            })

        case actions.createGaragePublicShareSuccess.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.id]: {
                        ...state.garages[action.payload.id],
                        public_share_owned: {
                            ...action.payload.public_share,
                        },
                    },
                },
                loading: false,
                sharesLoading: false,
                error: null,
            })

        case loadingActions.deleteGaragePublicShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: true,
                error: null,
            })

        case errorActions.deleteGaragePublicShareError.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: false,
                error: action.payload,
            })

        case actions.deleteGaragePublicShareSuccess.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.entity_id]: {
                        ...state.garages[action.payload.entity_id],
                        public_share_owned: null,
                    },
                },
                loading: false,
                sharesLoading: false,
                error: null,
            })

        case loadingActions.filterGarageSharesDisplay_change_request.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.garageid]: {
                        ...state.garages[action.payload.garageid],
                        private_shares_owned: {
                            ...state.garages[action.payload.garageid]
                                .private_shares_owned,
                            active_display: {
                                ...state.garages[action.payload.garageid]
                                    .private_shares_owned?.active_display,
                                isLoading: true,
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.sortGarageSharesDisplay_change_request.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.garageid]: {
                        ...state.garages[action.payload.garageid],
                        private_shares_owned: {
                            ...state.garages[action.payload.garageid]
                                .private_shares_owned,
                            active_display: {
                                ...state.garages[action.payload.garageid]
                                    .private_shares_owned?.active_display,
                                isLoading: true,
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case actions.sortGarageSharesDisplay_change_success.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.garageid]: {
                        ...state.garages[action.payload.garageid],
                        private_shares_owned: {
                            ...state.garages[action.payload.garageid]
                                .private_shares_owned,
                            active_display: {
                                ...state.garages[action.payload.garageid]
                                    .private_shares_owned?.active_display,
                                activeSort: action.payload.sortID,
                                item_ids_display: [
                                    ...action.payload.item_ids_display,
                                ],
                                isLoading: false,
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case actions.filterGarageSharesDisplay_change_success.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.garageid]: {
                        ...state.garages[action.payload.garageid],
                        private_shares_owned: {
                            ...state.garages[action.payload.garageid]
                                .private_shares_owned,
                            active_display: {
                                ...state.garages[action.payload.garageid]
                                    .private_shares_owned?.active_display,
                                activeFilter: action.payload.filterIDs,
                                item_ids_display: [
                                    ...action.payload.item_ids_display,
                                ],
                                isLoading: false,
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.getExternalGaragesSharedWithYouREQUEST.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.getExternalGaragesSharedWithYouSuccess.type:
            return Object.assign({}, state, {
                ...state,
                directly_shared_external_garages: {
                    ...state.directly_shared_external_garages,
                    ...action.payload,
                },
                loading: false,
            })

        case errorActions.getExternalGaragesSharedWithYouERROR.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.setCurrentExternalGarageSuccess.type:
            return Object.assign({}, state, {
                ...state,

                directly_shared_current_external_garage_id: action.payload,
            })

        case actions.populateSingleGarageAllOwnedSharesSuccess.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.entity_id]: {
                        ...state.garages[action.payload.entity_id],
                        public_share_owned: {
                            ...action.payload.owned_shares_obj
                                .public_share_owned,
                        },
                        private_shares_owned: {
                            ...action.payload.owned_shares_obj
                                .private_shares_owned,
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.getOwnedDirectSharesGarageRequest.type:
            return Object.assign({}, state, {
                ...state,
                error: null,
                loading: true,
            })

        case actions.getOwnedDirectSharesGarageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.id]: {
                        ...state.garages[action.payload.id],
                        private_shares_owned: {
                            ...action.payload.private_shares_owned,
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case errorActions.getOwnedDirectSharesGarageError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
                loading: false,
            })

        case loadingActions.createGarageDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                error: null,
                loading: true,
            })

        case actions.createGarageDirectShareSuccess.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.id]: {
                        ...state.garages[action.payload.id],
                        private_shares_owned: {
                            ...action.payload.private_shares_owned,
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case errorActions.createGarageDirectShareError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
                loading: false,
            })

        case actions.updateGarageDirectShareSuccess.type:
            let sharing_data_for_update: IDirectShareOwnedItemsData =
                state.garages[action.payload.garage_id] &&
                state.garages[action.payload.garage_id].private_shares_owned &&
                state.garages[action.payload.garage_id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.garages[action.payload.garage_id]
                          .private_shares_owned!.entity_owned_direct_shares_data
                    : {}

            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.garage_id]: {
                        ...state.garages[action.payload.garage_id],
                        private_shares_owned: {
                            ...state.garages[action.payload.garage_id]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...sharing_data_for_update,
                                [action.payload.updated_share.id]: {
                                    ...action.payload.updated_share,
                                },
                            },
                        },
                    },
                },
                sharesLoading: false,
                error: null,
            })

        case loadingActions.updateGarageDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: true,
                error: null,
            })

        case errorActions.updateGarageDirectShareError.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: false,
                error: action.payload,
            })

        case loadingActions.getSingleGarageDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                error: null,
                loading: true,
            })

        case actions.getSingleGarageDirectShareSuccess.type:
            let sharing_ids_2: string[] =
                state.garages[action.payload.id] &&
                state.garages[action.payload.id].private_shares_owned &&
                state.garages[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.garages[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_ids
                    : []

            let new_sharing_ids: string[] = sharing_ids_2.includes(
                action.payload.direct_share.id
            )
                ? [...sharing_ids_2]
                : [...sharing_ids_2, action.payload.direct_share.id]

            let sharing_data_2: IDirectShareOwnedItemsData =
                state.garages[action.payload.id] &&
                state.garages[action.payload.id].private_shares_owned &&
                state.garages[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.garages[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_data
                    : {}

            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.id]: {
                        ...state.garages[action.payload.id],
                        private_shares_owned: {
                            entity_owned_direct_shares_ids: new_sharing_ids,
                            entity_owned_direct_shares_data: {
                                ...sharing_data_2,
                                [action.payload.direct_share.id]: {
                                    ...action.payload.direct_share,
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case actions.deleteDirectGarageShareSuccess.type:
            let garage_share_ids_filtered: string[] =
                state.garages[action.payload.entity_id].private_shares_owned &&
                state.garages[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.garages[
                          action.payload.entity_id
                      ].private_shares_owned!.entity_owned_direct_shares_ids.filter(
                          (id: string) => id !== action.payload.share_id
                      )
                    : []

            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.entity_id]: {
                        ...state.garages[action.payload.entity_id],
                        private_shares_owned: {
                            ...state.garages[action.payload.entity_id]
                                .private_shares_owned,
                            entity_owned_direct_shares_ids: [
                                ...garage_share_ids_filtered,
                            ],
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case errorActions.getSingleGarageDirectShareError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
                loading: false,
            })

        case loadingActions.deleteGarageDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                error: null,
                loading: true,
            })

        case errorActions.deleteDirectGarageShareError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
                loading: false,
            })

        case loadingActions.addGarageShareRecipientRequest.type:
            return Object.assign({}, state, {
                ...state,
                error: null,
                sharesLoading: true,
            })

        case errorActions.addGarageShareRecipientError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
                sharesLoading: false,
            })

        case actions.addGarageShareRecipientSuccess.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: false,
                error: null,
            })

        case loadingActions.deleteGarageShareRecipientRequest.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: true,
                error: null,
            })

        case errorActions.deleteGarageShareRecipientError.type:
            return Object.assign({}, state, {
                ...state,
                sharesLoading: false,
                error: action.payload,
            })

        case actions.deleteGarageShareRecipientSuccess.type:
            let current_share_2: IDirectShareOwnedItem =
                state.garages[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_share_recipients_filtered =
                current_share_2.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.recipient_id
                )

            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.entity_id]: {
                        ...state.garages[action.payload.entity_id],
                        private_shares_owned: {
                            entity_owned_direct_shares_data: {
                                ...current_share_2,
                                [action.payload.share_id]: {
                                    ...current_share_2,
                                    all_share_recipients_ids_list: [
                                        ...current_share_recipients_filtered,
                                    ],
                                },
                            },
                        },
                    },
                },
                sharesLoading: false,
                error: null,
            })

        case loadingActions.sortGarageShareRecipientsDisplay_change_request
            .type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.entityid]: {
                        ...state.garages[action.payload.entityid],
                        private_shares_owned: {
                            ...state.garages[action.payload.entityid]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...state.garages[action.payload.entityid]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data,
                                [action.payload.share.id]: {
                                    ...state.garages[action.payload.entityid]
                                        .private_shares_owned
                                        ?.entity_owned_direct_shares_data[
                                        action.payload.share.id
                                    ],
                                    active_display: {
                                        ...state.garages[
                                            action.payload.entityid
                                        ].private_shares_owned
                                            ?.entity_owned_direct_shares_data[
                                            action.payload.share.id
                                        ].active_display,
                                        isLoading: true,
                                    },
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case actions.sortGarageShareRecipientsDisplay_change_success.type:
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.entityId]: {
                        ...state.garages[action.payload.entityId],
                        private_shares_owned: {
                            ...state.garages[action.payload.entityId]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...state.garages[action.payload.entityId]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data,
                                [action.payload.shareid]: {
                                    ...state.garages[action.payload.entityId]
                                        .private_shares_owned
                                        ?.entity_owned_direct_shares_data[
                                        action.payload.shareid
                                    ],
                                    active_display: {
                                        ...state.garages[
                                            action.payload.entityId
                                        ].private_shares_owned
                                            ?.entity_owned_direct_shares_data[
                                            action.payload.shareid
                                        ].active_display,
                                        activeSort: action.payload.sortID,
                                        item_ids_display: [
                                            ...action.payload.item_ids_display,
                                        ],
                                        isLoading: false,
                                    },
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        // CREATE GARAGE SHARE INVITE

        case loadingActions.createGarageShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.createGarageShareInviteSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.createGarageShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // RESEND GARAGE SHARE INVITE

        case loadingActions.resendGarageShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.resendGarageShareInviteSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.resendGarageShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // ACCEPT GARAGE SHARE INVITE

        case loadingActions.acceptGarageShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.acceptGarageShareInviteSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.acceptGarageShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // DELETE GARAGE SHARE INVITE

        case loadingActions.deleteGarageShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.deleteGarageShareInviteSuccess.type:
            let current_share_for_invites: IDirectShareOwnedItem | undefined =
                state.garages[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_all_share_recipients_filtered_for_invite_deletion =
                current_share_for_invites.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            let current_external_share_recipients_filtered_for_invite_deletion =
                current_share_for_invites.external_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.entity_id]: {
                        ...state.garages[action.payload.entity_id],
                        private_shares_owned: {
                            ...state.garages[action.payload.entity_id]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...state.garages[action.payload.entity_id]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data,
                                [action.payload.share_id]: {
                                    ...current_share_for_invites,
                                    external_share_recipients_ids_list: [
                                        ...current_external_share_recipients_filtered_for_invite_deletion,
                                    ],
                                    all_share_recipients_ids_list: [
                                        ...current_all_share_recipients_filtered_for_invite_deletion,
                                    ],
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case errorActions.deleteGarageShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // CLEANUP GARAGE DATA ON LOGOUT

        case actions.cleanUpGaragesDataOnLogoutSuccess.type:
            return Object.assign({}, state, {
                garages: {},
                directly_shared_external_garages: {},
                error: null,
                loading: false,
                sharesLoading: false,
                tasks: {},
                cars_per_page: 10,
                current_page_number: 0,
                total_cars_number_found: undefined,
            })

        // example: on car archiving / handover success
        case actions.removeCarFromUserGarage.type:
            let new_garage_ids = state.garages[action.payload.garageID]?.cars
                ? state.garages[action.payload.garageID].cars.filter(
                      (id: string) => {
                          return id !== action.payload.carID
                      }
                  )
                : undefined
            let new_garage_sorted_ids = state.garages[
                action.payload.garageID
            ].sorted_car_ids?.filter((id: string) => {
                return id !== action.payload.carID
            })
            return Object.assign({}, state, {
                ...state,
                garages: {
                    ...state.garages,
                    [action.payload.garageID]: {
                        ...state.garages[action.payload.garageID],
                        cars: new_garage_ids
                            ? [...new_garage_ids]
                            : new_garage_sorted_ids
                            ? [...new_garage_sorted_ids]
                            : [],
                        sorted_car_ids: new_garage_sorted_ids
                            ? [...new_garage_sorted_ids]
                            : undefined,
                    },
                },
            })

        // sorting garage cars
        case loadingActions.setGarageActiveSortingRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
            })
        case actions.setGarageActiveSortingSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeSort: action.payload,
                loading: false,
            })
        case errorActions.setGarageActiveSortingError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.resetSortedCarsInGarage.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                garages: {
                    ...state.garages,
                    [action.payload]: {
                        ...state.garages[action.payload],
                        sorted_car_ids: undefined,
                    },
                },
                total_cars_number_found: undefined,
                current_page_number: 0,
            })

        default:
            return state
    }
}

export default garagesData

export type GaragesState = ReturnType<typeof garagesData>
