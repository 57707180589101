import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'
import * as entriesActions from '../../../timeline/actions/successActions'
import { api } from '../../../services'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { IDeleteEntryGalleryImagePayload, IReduxError } from 'entityModels'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'

function* deleteEntryGalleryImageApi(payload: IDeleteEntryGalleryImagePayload) {
    try {
        yield call(api.entities.mutateGallery.deleteEntryGalleryImage, payload)

        yield put(
            actions.actions.deleteEntryGalleryImageSuccess(payload.imageid)
        )

        yield put(entriesActions.deleteGalleryImagesIdFromEntrySuccess(payload))

        // Capture event
        posthog.capture('DELETE ENTRY IMAGE')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: deleteEntryGalleryImageApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.deleteEntryGalleryImageError(typedError)
            )
            return
        }
    }
}

function* watcherDeleteEntryGalleryImage() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.deleteEntryGalleryImageRequest
        )

        yield call(deleteEntryGalleryImageApi, payload)
    }
}

const delete_entry_gallery_image_sagas: any[] = [
    fork(watcherDeleteEntryGalleryImage),
]

export default delete_entry_gallery_image_sagas
