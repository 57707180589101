import { apexLocalCopyData } from './data'

import { IApexLocalCopy } from 'apexLocalCopyModels'

export type IApexCopyState = IApexLocalCopy

const initialState = apexLocalCopyData

export const apex_local_copy = (
    state: IApexCopyState = initialState
) => state

export default apex_local_copy

export type ApexLocalCopyState = ReturnType<typeof apex_local_copy>
