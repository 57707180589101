import React from 'react'
import { message } from 'antd'
import styled from 'styled-components'
import '../../antd.css'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import { Combobox } from '../../../../../atoms/select'

const SelectBarContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`

export type Props = {
    fetching: any
    value: any
    data: any
    fetchItem: any
    handleChange: any
    maxWidth?: string
    item?: any | undefined
    width?: string | undefined
    addAnItemFunc?: any | undefined
    dropdownTargetId?: string | undefined
    formCurrentValue?: string | undefined
    allowAdd?: boolean | undefined
    isDisabled?: boolean | undefined
    fontSize?: string | undefined
    placeholder?: string | undefined
    helperText?: string
    dataCyId?: string | undefined
    customPlaceholder?: string
    sendId?: boolean
    backgroundColor?: string
    field_id?: string
}

type StateSelect = {
    valueTyped: string
}

const errorWarning = (errorText: string) => {
    message.error(errorText, 2)
}

class SingleSelectOnlyMatchedMobile extends React.Component<
    Props,
    StateSelect
> {
    state = {
        valueTyped: '',
    }

    handleSearch = (valueTyped: any) => {
        return this.setState({ valueTyped: valueTyped })
    }

    render() {
        const {
            value,
            data,
            handleChange,
            fetchItem,
            item,
            addAnItemFunc,
            dropdownTargetId,
            formCurrentValue,
            allowAdd,
            isDisabled,
            placeholder,
            helperText,
            customPlaceholder,
            sendId,
            backgroundColor,
            field_id,
        } = this.props

        const { valueTyped } = this.state

        // let randomKey: any = () => {
        //     return (
        //         Math.random().toString(36).substring(2, 15) +
        //         Math.random().toString(36).substring(2, 15)
        //     )
        // }

        const options = data
            ? data.map((di: any) => {
                  let d = di.item

                  if (d) {
                      return {
                          value: sendId
                              ? d.uid
                              : d.uid &&
                                (field_id === 'make' ||
                                    field_id === 'model' ||
                                    field_id === 'car_model' ||
                                    field_id === 'car_make')
                              ? d.uid
                              : d.name,
                          label: d.name,
                      }
                  } else {
                      return {
                          value: sendId
                              ? di.uid
                              : di.uid &&
                                (field_id === 'make' ||
                                    field_id === 'model' ||
                                    field_id === 'car_model' ||
                                    field_id === 'car_make')
                              ? di.uid
                              : di.name,
                          label: di.name,
                      }
                  }
              })
            : null

        // const mappedList = optionsList.map((d: any) => (
        //     <Option key={d.name} aria-label="UK">
        //         <span style={{ marginLeft: '10px' }}>{d.name} </span>
        //     </Option>
        // ))

        return (
            <SelectBarContainer id="formmobileditscrolltechinfo">
                <Combobox
                    field_id={field_id}
                    backgroundColor={backgroundColor}
                    options={options}
                    value={value[value.length - 1]}
                    defaultValue={item.answer ? item.answer : '-'}
                    placeholder={placeholder}
                    helperText={helperText}
                    disabled={isDisabled ? isDisabled : false}
                    onInputKeyDown={(e: any) => {
                        if (data.length === 0) {
                            if (
                                addAnItemFunc !== undefined &&
                                dropdownTargetId !== undefined &&
                                allowAdd
                            ) {
                                e = e || window.event
                                if (e.keyCode === 13) {
                                    if (formCurrentValue) {
                                        if (formCurrentValue !== valueTyped) {
                                            if (valueTyped) {
                                                let trimmed_val =
                                                    valueTyped.replace(
                                                        /\s/g,
                                                        ''
                                                    )

                                                if (
                                                    trimmed_val.length >= 1 &&
                                                    trimmed_val.length < 70
                                                ) {
                                                    let trimmed_val_final =
                                                        valueTyped.trim()
                                                    handleChange(
                                                        trimmed_val_final
                                                    )

                                                    addAnItemFunc(
                                                        dropdownTargetId,
                                                        trimmed_val_final
                                                    )
                                                } else {
                                                    if (
                                                        trimmed_val.length >= 70
                                                    ) {
                                                        errorWarning(
                                                            'Name must be less than 70 characters'
                                                        )
                                                    } else {
                                                        errorWarning(
                                                            'Please enter a valid name.'
                                                        )
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }}
                    fallbackContent={
                        allowAdd &&
                        // data.length === 0 &&
                        valueTyped &&
                        valueTyped.length !== 0 &&
                        !value.includes(valueTyped) ? (
                            <ButtonAtom
                                onClick={(e: any) => {
                                    if (valueTyped) {
                                        let trimmed_val = valueTyped.replace(
                                            /\s/g,
                                            ''
                                        )
                                        if (
                                            trimmed_val.length >= 1 &&
                                            trimmed_val.length < 70
                                        ) {
                                            e.preventDefault()

                                            let trimmed_val_final =
                                                valueTyped.trim()

                                            addAnItemFunc(
                                                dropdownTargetId,
                                                trimmed_val_final
                                            )
                                            handleChange(trimmed_val_final)
                                        } else {
                                            if (trimmed_val.length >= 70) {
                                                errorWarning(
                                                    'Name must be less than 70 characters'
                                                )
                                            } else {
                                                errorWarning(
                                                    'Please enter a valid name.'
                                                )
                                            }
                                        }
                                    }
                                }}
                                theme="naked-text"
                                width="100%"
                                alignLeft={true}
                            >
                                add {valueTyped}
                            </ButtonAtom>
                        ) : (
                            <div>
                                {customPlaceholder
                                    ? customPlaceholder
                                    : `Please search your car's ${item.text}.`}
                            </div>
                        )
                    }
                    onSearch={(value: string) => {
                        fetchItem(value)
                        this.handleSearch(value)
                    }}
                    onChange={handleChange}
                />
            </SelectBarContainer>
        )
    }
}

export default SingleSelectOnlyMatchedMobile
