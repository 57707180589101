type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const OrderIcon = ({ size, strokeColor, color }: Props) => {
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0009 12.1982L14.4706 9.72852L15.5313 10.7892L12.0009 14.3195L8.47059 10.7892L9.53125 9.72852L12.0009 12.1982Z"
                fill={color ?? '#5EC3CA'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.75 3V13H11.25V3H12.75Z"
                fill={color ?? '#5EC3CA'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.001 3.81939L9.53136 6.28906L8.4707 5.2284L12.001 1.69807L15.5314 5.2284L14.4707 6.28906L12.001 3.81939Z"
                fill={color ?? '#5EC3CA'}
            />
            <rect
                x="1.66797"
                y="2.28906"
                width="4"
                height="4"
                rx="1"
                stroke={color ?? '#5EC3CA'}
                stroke-width="1.5"
            />
            <rect
                x="1.66797"
                y="9.46875"
                width="4"
                height="4"
                rx="1"
                stroke={color ?? '#5EC3CA'}
                stroke-width="1.5"
            />
        </svg>
    )
}

export default OrderIcon
