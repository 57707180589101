import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColour?: string
}

const CarIcon = ({ size, color, strokeColour }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="17.6856"
                cy="16.3943"
                r="1.43921"
                stroke={color ?? colours[theme].text_darker}
                strokeWidth="1.5"
            />
            <circle
                cx="6.97132"
                cy="16.3943"
                r="1.43921"
                stroke={color ?? colours[theme].text_darker}
                strokeWidth="1.5"
            />
            <path
                d="M5.23087 16.3028H3.32191C2.66257 16.2291 2.33297 15.8602 2.2774 15.6263C2.25997 15.5529 2.19192 15.1401 2.27757 14.9633C2.38085 14.7502 2.68711 14.8633 2.74891 14.824C2.88492 14.7377 2.72249 14.3227 2.88492 14.2054C6.4526 11.6289 8.80829 10.8898 12.255 10.7553C12.9107 10.7553 13.3564 11.1817 13.6388 11.406C13.9963 11.6899 14.4414 12.1548 14.7001 12.4006C14.8023 12.4976 14.9364 12.5473 15.077 12.5562L20.0006 12.8682C20.1227 12.8793 20.5882 12.8322 20.7213 13.3913L20.9542 13.9902L21.703 14.5393C21.8583 14.6946 22.1223 15.0551 21.9359 15.2547L21.5377 15.9327C21.403 16.162 21.1572 16.3028 20.8912 16.3028C20.5259 16.3028 20.0355 16.3028 19.5899 16.3028M15.863 16.3028H8.50888"
                stroke={color ?? colours[theme].text_darker}
                strokeWidth="1.5"
            />
            <path
                d="M18.1878 9.15331L17.5639 8.69576L17.3698 8.19662C17.2589 7.73075 16.8709 7.76995 16.7692 7.76071L12.7021 7.50301C12.5615 7.4941 12.4277 7.44383 12.3261 7.34619C12.1076 7.13619 11.754 6.76955 11.4677 6.54223C11.2324 6.35534 10.861 6 10.3145 6C7.44227 6.11209 5.47919 6.72798 2.50612 8.87507C2.37077 8.97282 2.50612 9.31864 2.39279 9.3906C2.34128 9.42329 2.08607 9.32908 2 9.50667"
                stroke={color ?? colours[theme].text_neutral_default}
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>

        // <svg
        //     width={size ?? '24'}
        //     height={size ?? '24'}
        //     viewBox="0 0 24 24"
        //     fill="none"
        //     xmlns="http://www.w3.org/2000/svg"
        // >
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M4 15.25C4.41421 15.25 4.75 15.5858 4.75 16V18.25H6.25V16C6.25 15.5858 6.58579 15.25 7 15.25C7.41421 15.25 7.75 15.5858 7.75 16V19.75H3.25V16C3.25 15.5858 3.58579 15.25 4 15.25Z"
        //         fill={color ?? '#616161'}
        //     />
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M17 15.25C17.4142 15.25 17.75 15.5858 17.75 16V18.25H19.25V16C19.25 15.5858 19.5858 15.25 20 15.25C20.4142 15.25 20.75 15.5858 20.75 16V19.75H16.25V16C16.25 15.5858 16.5858 15.25 17 15.25Z"
        //         fill={color ?? '#616161'}
        //     />
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M3.25 12C3.25 11.0335 4.0335 10.25 5 10.25H19C19.9665 10.25 20.75 11.0335 20.75 12V16.75H3.25V12ZM5 11.75C4.86193 11.75 4.75 11.8619 4.75 12V15.25H19.25V12C19.25 11.8619 19.1381 11.75 19 11.75H5Z"
        //         fill={color ?? '#616161'}
        //     />
        //     <path
        //         d="M6.98343 13.5C6.98343 13.5932 6.88799 13.75 6.66676 13.75C6.44554 13.75 6.3501 13.5932 6.3501 13.5C6.3501 13.4068 6.44554 13.25 6.66676 13.25C6.88799 13.25 6.98343 13.4068 6.98343 13.5Z"
        //         fill={color ?? '#616161'}
        //         stroke="#B3B3B3"
        //         strokeWidth="1.5"
        //     />
        //     <path
        //         d="M17.6499 13.5C17.6499 13.5932 17.5545 13.75 17.3333 13.75C17.112 13.75 17.0166 13.5932 17.0166 13.5C17.0166 13.4068 17.112 13.25 17.3333 13.25C17.5545 13.25 17.6499 13.4068 17.6499 13.5Z"
        //         fill={color ?? '#616161'}
        //         stroke="#B3B3B3"
        //         strokeWidth="1.5"
        //     />
        //     <path
        //         fill-rule="evenodd"
        //         clip-rule="evenodd"
        //         d="M7.47684 5.25L16.523 5.25C16.523 5.25 16.523 5.25 16.523 5.25C17.0235 5.24999 17.532 5.36834 17.9555 5.61193C18.3752 5.85339 18.7434 6.24177 18.8554 6.76218L19.7331 10.8423C19.8203 11.2472 19.5626 11.6461 19.1576 11.7332C18.7527 11.8203 18.3538 11.5627 18.2667 11.1577L17.3892 7.07863C17.3889 7.07872 17.366 7.00331 17.2075 6.91213C17.0434 6.81772 16.8003 6.74999 16.523 6.75H7.47715C7.47704 6.75 7.47725 6.75 7.47715 6.75C7.20016 6.75017 6.95706 6.81792 6.79319 6.91226C6.63488 7.00339 6.61202 7.07872 6.61173 7.07863C6.61172 7.07863 6.61169 7.0788 6.61173 7.07863M6.61173 7.07863L5.7331 11.1579C5.64588 11.5628 5.24692 11.8204 4.84199 11.7332C4.43707 11.646 4.17951 11.247 4.26673 10.8421L5.14547 6.76218C5.14548 6.76215 5.14547 6.76221 5.14547 6.76218C5.25746 6.24206 5.62539 5.85373 6.04483 5.61227C6.468 5.36867 6.97655 5.25021 7.47684 5.25"
        //         fill={color ?? '#616161'}
        //     />
        // </svg>
    )
}

export default CarIcon
