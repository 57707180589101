import React from 'react'
import styled from 'styled-components'
import ScrollLock from 'react-scrolllock'
import ShowroomErrorManager from '../../organisms/showroomErrorManager/showroomErrorManager'

const Wrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    left: 0;
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
`

interface Props {
    error_is: string | undefined
    onClick: any
}

interface State {}

export class ShowroomError extends React.Component<Props, State> {
    state = {}

    render() {
        return (
            <Wrapper>
                <ScrollLock />
                <ShowroomErrorManager
                    error_is={this.props.error_is}
                    onClick={this.props.onClick}
                />
            </Wrapper>
        )
    }
}

export default ShowroomError
