import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const ChevronLeft = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.5606 12.0001L16.0303 5.53039L14.9696 4.46973L7.43928 12.0001L14.9696 19.5304L16.0303 18.4697L9.5606 12.0001Z"
                fill={color ?? colours[theme].text_default}
            />
        </svg>
    )
}

export default ChevronLeft
