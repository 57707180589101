import { IAddEntryAttachmenstApiRequest } from 'ApiInterfaces'
import { IAttachmentAPI, IAttachmentRequestPayload } from 'attachmentModels'
import { IUploadImagePreSignedUrlPayloadSingle } from 'IapiDataPayload'
import { IUploadAttachmentPreSignedUrlPayloadSaga } from 'myModels'
import { fork, take, call, put } from 'redux-saga/effects'
import { AttachmentTypesEnum } from '../../attachments/enums'
import { ConverFileTypeToFileSuffixEnum } from '../../conversions/attachments'
import { getPreSignedUrlsEntryAttachments } from '../../filestorage/actions/sagas/presignedurlsEntryAttachments'

import { requestActions, actions } from '../actions'
import { IManageAddAttachmentsToEntryRequest } from '../actions/requestActions'
import {
    AddAttachmentsToEntryAPISaga,
    IAddAttachmentToEntryAwsTrackingSaga,
    UploadEntryAttachmentToAwsWithTrackingSaga,
} from './addAttachmentToEntry'

export type IobjBySuffixDataItem = {
    files: File[]
    filesConvertedForAWS: IAttachmentRequestPayload[]
    filesConvertedForApi: IAttachmentAPI[]
    count: number
    presigned_urls?: IUploadImagePreSignedUrlPayloadSingle[]
}

type IobjBySuffixData = {
    [key in AttachmentTypesEnum | string]: IobjBySuffixDataItem
}

export function* ManageAddAttachmentsToEntrySaga(
    payload: IManageAddAttachmentsToEntryRequest
): any {
    // 0. initialise the object which will manage synch
    let obj1: IobjBySuffixData = {}

    // 1. x files => separate by extension types and keep track of count

    for (let i = 0; i < payload.files.length; i++) {
        if (payload.files[i] && payload.files[i].name) {
            let file_extension: AttachmentTypesEnum | undefined =
                ConverFileTypeToFileSuffixEnum(payload.files[i].name)

            if (file_extension) {
                if (!obj1[file_extension]) {
                    obj1 = {
                        ...obj1,
                        [file_extension]: {
                            files: [],
                            count: 0,
                            filesConvertedForApi: [],
                            filesConvertedForAWS: [],
                        },
                    }
                }
                // files property
                obj1[file_extension].files = [
                    ...obj1[file_extension].files,
                    payload.files[i],
                ]

                // count property
                obj1[file_extension].count =
                    obj1[file_extension] &&
                    obj1[file_extension].count &&
                    obj1[file_extension].count
                        ? (obj1[file_extension].count += 1)
                        : 1
            }
        } else console.log('payload.files[i] doesnt pass condition')
    }

    // 2. Get presigned urls with count for each types

    let keysArr = Object.keys(obj1)

    let all_entry_attachments_to_send_api: IAttachmentAPI[] = []

    for (let i = 0; i < keysArr.length; i++) {
        let p: IUploadAttachmentPreSignedUrlPayloadSaga = {
            entityID: payload.entryID,
            carID: payload.carID,
            fileSuffix: keysArr[i],
            count: obj1[keysArr[i]].count,
            objBySuffixToPopulate: obj1[keysArr[i]],
        }

        let suffixItemObjPopulated: IobjBySuffixDataItem = yield call(
            getPreSignedUrlsEntryAttachments,
            p
        )

        // 3. Gather all the presigned urls above returned
        obj1[keysArr[i]] = {
            ...suffixItemObjPopulated,
        }

        // 4. Assign to each attachmentFile API objects a presigned url

        let suffixObjItem = obj1[keysArr[i]]

        if (suffixObjItem && suffixObjItem.files) {
            for (let i = 0; i < suffixObjItem.files.length; i++) {
                if (
                    suffixObjItem.presigned_urls &&
                    suffixObjItem.presigned_urls[i] &&
                    suffixObjItem.files
                ) {
                    let presigned = suffixObjItem.presigned_urls[i]

                    let fileItem = suffixObjItem.files[i]

                    // files converted for API property
                    suffixObjItem.filesConvertedForApi[i] = {
                        uid: '',
                        filename: presigned.filename,
                        title: fileItem.name,
                    }
                    // 5. Go through them to upload to AWS

                    // files converted for AWS property
                    suffixObjItem.filesConvertedForAWS[i] = {
                        filename: presigned.filename,
                        type: fileItem.type,
                        file: fileItem,
                        progressRateAWS: 0,
                    }

                    let aws_p: IAddAttachmentToEntryAwsTrackingSaga = {
                        fileData: suffixObjItem.filesConvertedForAWS[i],
                        presigned_url: presigned.url,
                    }

                    let res = yield call(
                        UploadEntryAttachmentToAwsWithTrackingSaga,
                        aws_p
                    )

                    if (res !== 'ok') {
                        // here remove the file from obj1 so that
                        // we dont update a faulty file to database

                        let itemAWS: IAttachmentRequestPayload = {
                            ...suffixObjItem.filesConvertedForAWS[i],
                            hasErrorAWS: res,
                        }

                        suffixObjItem.filesConvertedForAWS[i] = itemAWS
                        // this will contain the error if we want to access it
                    } else {
                        // console.log(
                        //     'theres been NO error uploading to AWS:',
                        //     res
                        // )
                        all_entry_attachments_to_send_api = [
                            ...all_entry_attachments_to_send_api,
                            suffixObjItem.filesConvertedForApi[i],
                        ]
                    }
                }
            }
        } else console.log('else: suffixObjItem didnt contain files')
    }

    let payload_api: IAddEntryAttachmenstApiRequest = {
        attachments: all_entry_attachments_to_send_api,
        carID: payload.carID,
        entryID: payload.entryID,
    }

    yield call(AddAttachmentsToEntryAPISaga, payload_api)

    yield put(actions.manageAddAttachmentsToEntrySuccess())
}

function* watcherManageAddAttachmentsToEntry() {
    while (true) {
        const { payload } = yield take(
            requestActions.manageAddAttachmentsToEntryRequest
        )
        yield call(ManageAddAttachmentsToEntrySaga, payload)
    }
}

const manage_add_attachments_to_entry: any[] = [
    fork(watcherManageAddAttachmentsToEntry),
]

export default manage_add_attachments_to_entry
