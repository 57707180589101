import { message } from 'antd'
import { push } from 'connected-react-router'
import { IReduxError } from 'entityModels'
import posthog from 'posthog-js'
import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../actions'
import {
    ICreateShareInviteRequestPayload,
    IShareInviteDeletion,
    IShareInvitResent,
} from '../actions/loadingActions'
import { GetSingleCarDirectShare } from './getAllSharesCar'

const info = (messageText: string) => {
    message.info(messageText, 5)
}

export function* CreateCarShareInviteSaga(p: ICreateShareInviteRequestPayload) {
    try {
        yield call(api.sharing.createShareInvite, p.api_payload)
        yield call(GetSingleCarDirectShare, {
            entity_id: p.entity_id,
            share_id: p.api_payload.share_id,
        })

        info(`An email has been sent to ${p.api_payload.recipient_email}.`)

        // Capture event
        posthog.capture('CREATE CAR DIRECT SHARE EMAIL INVITE')

        yield put(actions.actions.createCarShareInviteSuccess())
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateCarShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.createCarShareInviteError(typedError)
            )
            return
        }
    }
}

export function* ResendCarShareInviteSaga(p: IShareInvitResent) {
    try {
        yield call(api.sharing.resendShareInviteApi, p.invite_id)
        yield put(actions.actions.resendCarShareInviteSuccess())

        info(
            `A remider email about your share has been sent to ${p.recipient_email}.`
        )

        // Capture event
        posthog.capture('RESEND CAR DIRECT SHARE EMAIL INVITE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: ResendCarShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.resendCarShareInviteError(typedError)
            )
            return
        }
    }
}

export function* AcceptCarShareInviteSaga(p: {
    invite_code: string
    next_path: string
}) {
    try {
        yield call(api.sharing.acceptShareInviteApi, p.invite_code)
        info(`You have accepted a share invite.`)
        yield put(push(p.next_path))
        // Capture event
        posthog.capture('ACCEPT CAR DIRECT SHARE EMAIL INVITE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: AcceptCarShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else if (error.status === 403) {
            yield put(push('/invite-user-error'))
            yield put(actions.errorActions.acceptCarShareInviteError(undefined))
        } else if (error.status === 404) {
            yield put(push(p.next_path))
            yield put(actions.errorActions.acceptCarShareInviteError(undefined))
            return
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.acceptCarShareInviteError(typedError)
            )
            return
        }
    }
}

export function* DeleteCarShareInviteSaga(p: IShareInviteDeletion) {
    try {
        yield call(api.sharing.deleteShareInviteApi, p.invite_id)
        yield put(actions.actions.deleteCarShareInviteSuccess(p))

        info(`Share invite was removed.`)

        // Capture event
        posthog.capture('DELETE CAR DIRECT SHARE EMAIL INVITE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteCarShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.deleteCarShareInviteError(typedError)
            )
            return
        }
    }
}

function* watcherCreateCarShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.createCarShareInviteRequest
        )
        yield call(CreateCarShareInviteSaga, payload)
    }
}

function* watcherResendCarShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.resendCarShareInviteRequest
        )
        yield call(ResendCarShareInviteSaga, payload)
    }
}

function* watcherAcceptCarShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.acceptCarShareInviteRequest
        )
        yield call(AcceptCarShareInviteSaga, payload)
    }
}

function* watcherDeleteCarShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.deleteCarShareInviteRequest
        )
        yield call(DeleteCarShareInviteSaga, payload)
    }
}

const car_share_invite_sagas: any[] = [
    fork(watcherCreateCarShareInvite),
    fork(watcherDeleteCarShareInvite),
    fork(watcherResendCarShareInvite),
    fork(watcherAcceptCarShareInvite),
]

export default car_share_invite_sagas
