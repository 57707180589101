import { ITopWizardCheckActions } from '../../../pages/insurance_quote/wizard'
import EverydayCarSelectorWizard from './forms/everydayVehicle'
import EverydayCarInsuranceMileageParkingWizardFlow from './forms/everydayVehicle/mileageParking'
import EverydayVehicleOwnershipWizardForm from './forms/everydayVehicle/ownership'
import InsuranceEverydayCarTechInfoWizard from './forms/everydayVehicle/techInfo'
import InsuranceDetailsWizardForm from './forms/insuranceDetails'
import VehiclesToInsureListWizard from './forms/insuredVehicle'
import IsInsuredVehicleEverydayWizard from './forms/insuredVehicle/isEveryday'
import InsuredVehicleMileageParkingWizardForm from './forms/insuredVehicle/mileageParking'
import InsuredVehicleInsuranceWizardForm from './forms/insuredVehicle/ownership'
import InsuredVehicleTechInfoWizardForm from './forms/insuredVehicle/techInfo'
import InsuranceMainDriverAdditionalDetailsWizardForm from './forms/mainPolicyHolder/additionalDetails'
import MainDriverBasicDetailsInsuranceWizardFlow from './forms/mainPolicyHolder/basicDetails'
import InsuranceMainDriverLicenceAddressWizardForm from './forms/mainPolicyHolder/licenseAddress'
import InsuranceMainDriverTrackRecordApplicationFlow from './forms/mainPolicyHolder/trackRecord'
import InsuranceApplicationDriversSelectorWizard from './forms/namedDrivers'
import InsuranceOtherDriverAdditionalDetailsApplicationWizardForm from './forms/namedDrivers/additionalDetails'
import InsuranceOtherDriverBasicDetailsApplicationWizardForm from './forms/namedDrivers/basicDetails'
import DriverDriveListWizardForm from './forms/namedDrivers/driveList'
import InsuranceOtherDriverLicenceAddressApplicationWizardForm from './forms/namedDrivers/licenseAddress'
import InsuranceOtherDriverTrackRecordApplicationWizardForm from './forms/namedDrivers/trackRecord'

type Props = {
    subsection_id: string | null
    section_id: string | null
    car_id: string | null
    driver_id?: string | null
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const WizardFormsManager = (props: Props) => {
    let {
        subsection_id,
        car_id,
        section_id,
        driver_id,
        setTopWizardCheckActions,
    } = props
    // for styling
    // let sectionID = 'vehicles_to_insure'
    return (
        <div>
            {/* insurance_details */}
            {section_id === 'insurance_details' && (
                <InsuranceDetailsWizardForm
                    setTopWizardCheckActions={setTopWizardCheckActions}
                />
            )}
            {/* INSURED VEHICLES */}

            {section_id === 'vehicles_to_insure' &&
                subsection_id === 'tech_info' &&
                car_id && (
                    <InsuredVehicleTechInfoWizardForm
                        setTopWizardCheckActions={setTopWizardCheckActions}
                        car_id={car_id}
                    />
                )}
            {section_id === 'vehicles_to_insure' &&
                subsection_id === 'mileage_parking' &&
                car_id && (
                    <InsuredVehicleMileageParkingWizardForm
                        car_id={car_id}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {section_id === 'vehicles_to_insure' &&
                subsection_id === 'ownership' &&
                car_id && (
                    <InsuredVehicleInsuranceWizardForm
                        car_id={car_id}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}

            {section_id === 'vehicles_to_insure' &&
                subsection_id === 'is_everyday' &&
                car_id && (
                    <IsInsuredVehicleEverydayWizard
                        car_id={car_id}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {section_id === 'vehicles_to_insure' && !car_id && (
                <VehiclesToInsureListWizard
                    setTopWizardCheckActions={setTopWizardCheckActions}
                />
            )}
            {section_id === 'everyday_vehicles' && !car_id && (
                <EverydayCarSelectorWizard
                    setTopWizardCheckActions={setTopWizardCheckActions}
                />
            )}
            {/* EVERYDAY VEHICLES   */}
            {section_id === 'everyday_vehicles' &&
                car_id &&
                subsection_id === 'tech_info' && (
                    <InsuranceEverydayCarTechInfoWizard
                        car_id={car_id}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {section_id === 'everyday_vehicles' &&
                car_id &&
                subsection_id === 'mileage_parking' && (
                    <EverydayCarInsuranceMileageParkingWizardFlow
                        setTopWizardCheckActions={setTopWizardCheckActions}
                        car_id={car_id}
                    />
                )}
            {section_id === 'everyday_vehicles' &&
                car_id &&
                subsection_id === 'ownership' && (
                    <EverydayVehicleOwnershipWizardForm
                        car_id={car_id}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {/*  main_policy_holder   */}
            {section_id === 'main_policy_holder' &&
                subsection_id === 'basic_details' && (
                    <MainDriverBasicDetailsInsuranceWizardFlow
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {section_id === 'main_policy_holder' &&
                subsection_id === 'licence_address' && (
                    <InsuranceMainDriverLicenceAddressWizardForm
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {section_id === 'main_policy_holder' &&
                subsection_id === 'additional_details' && (
                    <InsuranceMainDriverAdditionalDetailsWizardForm
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {section_id === 'main_policy_holder' &&
                subsection_id === 'track_record' && (
                    <InsuranceMainDriverTrackRecordApplicationFlow
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {/*  other_drivers  */}
            {section_id === 'drivers' && !driver_id && (
                <InsuranceApplicationDriversSelectorWizard
                    setTopWizardCheckActions={setTopWizardCheckActions}
                />
            )}
            {section_id === 'drivers' &&
                driver_id &&
                subsection_id === 'basic_details' && (
                    <InsuranceOtherDriverBasicDetailsApplicationWizardForm
                        setTopWizardCheckActions={setTopWizardCheckActions}
                        driver_id={driver_id}
                    />
                )}

            {section_id === 'drivers' &&
                driver_id &&
                subsection_id === 'additional_details' && (
                    <InsuranceOtherDriverAdditionalDetailsApplicationWizardForm
                        setTopWizardCheckActions={setTopWizardCheckActions}
                        driver_id={driver_id}
                    />
                )}
            {section_id === 'drivers' &&
                driver_id &&
                subsection_id === 'track_record' && (
                    <InsuranceOtherDriverTrackRecordApplicationWizardForm
                        setTopWizardCheckActions={setTopWizardCheckActions}
                        driver_id={driver_id}
                    />
                )}
            {section_id === 'drivers' &&
                driver_id &&
                subsection_id === 'drive_list' && (
                    <DriverDriveListWizardForm
                        driver_id={driver_id}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                )}
            {section_id === 'drivers' &&
                driver_id &&
                subsection_id === 'licence_address' && (
                    <InsuranceOtherDriverLicenceAddressApplicationWizardForm
                        setTopWizardCheckActions={setTopWizardCheckActions}
                        driver_id={driver_id}
                    />
                )}
        </div>
    )
}

export default WizardFormsManager
