import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}
const InfoIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '20'}
            height={size ?? '20'}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM8.57143 4.28571V7.14286H11.4286V4.28571H8.57143ZM8.57143 8.57143V15.7143H11.4286V8.57143H8.57143Z"
                fill={color ?? colours[theme].text_muted}
            />
        </svg>
    )
}

export default InfoIcon
