import set_body_type_dropdownData from './setBodyTypeDropdownData'
import set_countries_code_dropdown_data from './setCountriesCodeDropdownData'
import set_custom_item_in_specified_targetted_dropdown from './setCustomItemInSpecifiedTargettedDropdown'
import set_insurance_dropdownData from './setInsuranceFieldDataByIDs'
import set_make_dropdown_data from './setMakeDropdownData'
import set_model_dropdown_data from './setModelDropdownData'
import set_time_zones_dropdown_data from './setTimeZonesDropdownData'
import set_transmission_types_dropdown_data from './setTransmissionTypesDropdownData'
import set_year_dropdown_data from './setYearDropdownData'
import set_countries_admin_codes from './usaStatesCode'

let arr: any[] = [
    ...set_make_dropdown_data,
    ...set_model_dropdown_data,
    ...set_year_dropdown_data,
    ...set_body_type_dropdownData,
    ...set_custom_item_in_specified_targetted_dropdown,
    ...set_transmission_types_dropdown_data,
    ...set_countries_code_dropdown_data,
    ...set_time_zones_dropdown_data,
    ...set_insurance_dropdownData,
    ...set_countries_admin_codes,
]

export default arr
