import {
    ICar,
    ICarsObject,
    IExternalCar,
    IExternalCarsObject,
} from 'entityModels'
import { IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ITimelineDataByCarNormalised } from 'timelineModels'
import { RootState } from 'typesafe-actions'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import HistoryFilePageManager from '../../../organisms/HistoryFile/historyFilePageManager'
import { device } from '../../displays/devices'
import CenteredPageWrapper from '../../displays/pageWrappers/CenteredPageWrapper'
import FabsMenusMobile from '../../menus/fabsMenuMobile'

const AdjustedPaddingTop = styled.div`
    padding-top: 50px;
    @media ${device.ipad} {
        padding-top: 80px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    sharedCar?: IExternalCar
    externalCarsData: IExternalCarsObject | undefined
    carsData: ICarsObject
    prevOwned?: ICar
    timelineData: ITimelineDataByCarNormalised | null | undefined
}

type State = {
    placeholderUrl: string
}

function mapStateToProps(state: RootState) {
    return {
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
        carsData: state.entities.carsData.cars,
        timelineData: state.timeline.timeline_data_normalised_by_car_id,
    }
}

class ReadOnlyCarHistoryFile extends React.Component<Props, State> {
    render() {
        let carid: string = this.props.match.params.carid
        let { sharedCar, prevOwned, externalCarsData, timelineData } =
            this.props

        return (
            <React.Fragment>
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
                <AdjustedPaddingTop />

                {sharedCar ? (
                    sharedCar.policy_statements &&
                    sharedCar.policy_statements?.includes('history file') ? (
                        <HistoryFilePageManager
                            carid={carid}
                            sharedCar={sharedCar}
                            carsData={externalCarsData}
                            carsTimelineData={timelineData}
                            readOnlyMode
                        />
                    ) : (
                        <CenteredPageWrapper>
                            <NoDataRectangle isInfoRestricted />
                        </CenteredPageWrapper>
                    )
                ) : (
                    prevOwned && (
                        <HistoryFilePageManager
                            carid={carid}
                            prevOwned={prevOwned}
                            carsData={this.props.carsData}
                            carsTimelineData={this.props.timelineData}
                            readOnlyMode
                        />
                    )
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, {})(ReadOnlyCarHistoryFile))
