import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const Subtitle = styled.h1`
    font-family: Lato-light;
    text-align: center;
    color: black;
    font-size: 16px;
    padding-top: 0px;
    margin: 0;
    line-height: 22px;
    font-style: italic;
    padding-top: 10px;

    @media ${device.desktop} {
        font-size: 16px;
        color: #616161;
        text-align: left;
        max-width: 700px;
    }

    @media ${device.ipad} {
        font-size: 16px;
        text-align: left;
    }
`

interface IProps {
    children?: React.ReactNode
}

const CreateFormSubSubtitleDetail: React.FunctionComponent<IProps> = (
    props: any
) => <Subtitle>{props.children}</Subtitle>

export default CreateFormSubSubtitleDetail
