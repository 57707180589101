import { IPublicShareAPIRes, IPublicShareAPIRes_item } from 'ApiInterfaces'
import { IPublicShare } from 'entityModels'
import { IPublicShareSuccessPayloadRedux } from '../../entities/garages/actions/actions'

export type IConvertPublicShareArg = {
    entity: 'car' | 'garage'
    id: string
    api_payload: IPublicShareAPIRes
}

export type IConvertSinglePublicShareArg = {
    entity: 'car' | 'garage'
    id: string
    api_payload: IPublicShareAPIRes_item
    url_shortener_code?: string
}

export const ConvertPublicShares = (
    p: IConvertPublicShareArg
): IPublicShareSuccessPayloadRedux => {
    let res: IPublicShareSuccessPayloadRedux = {
        id: p.id,
        public_share: {},
    }

    if (p.api_payload[0] !== undefined) {
        res.public_share = {
            isActive: p.api_payload[0].link_identifier ? true : false,
            link_identifier: p.api_payload[0].link_identifier,
            url:
                p.entity === 'car'
                    ? `https://share.custodian.club/car/${p.api_payload[0].link_identifier}/overview`
                    : `https://share.custodian.club/garage/${p.api_payload[0].link_identifier}`,
            id: p.api_payload[0].uid,
        }
    }

    return res
}

export const ConvertSinglePublicShare = (
    p: IConvertSinglePublicShareArg
): IPublicShareSuccessPayloadRedux => {
    let res: IPublicShareSuccessPayloadRedux = {
        id: p.id,
        public_share: {},
    }

    res.public_share = {
        isActive: p.api_payload.link_identifier ? true : false,
        link_identifier: p.api_payload.link_identifier,
        id: p.api_payload.uid,
        url:
            p.entity === 'car'
                ? `https://share.custodian.club/car/${p.api_payload.link_identifier}/overview`
                : `https://share.custodian.club/garage/${p.api_payload.link_identifier}`,
    }

    return res
}

export const ConvertSingleUserPublicShare = (
    p: IPublicShareAPIRes_item
): IPublicShare => {
    let res: IPublicShare = {
        isActive: p.link_identifier ? true : false,
        link_identifier: p.link_identifier,
        id: p.uid,
        url:
            p.entity_type === 'car'
                ? `https://share.custodian.club/car/${p.link_identifier}/overview`
                : `https://share.custodian.club/garage/${p.link_identifier}`,
        entity_id: p[p.entity_type === 'car' ? 'car' : 'garage']?.uid,
    }

    return res
}
