import { IStickerPredefinedTheme } from 'stickerPredefinedThemes'

export const stickerPredefinedThemesData: IStickerPredefinedTheme[] = [
    {
        name: 'Green Over Tan',
        colour_one: '#004225',
        colour_two: '#EBDECD',
    },
    {
        name: '007 Silver Birch',
        colour_one: '#A5A49B',
        colour_two: '#FCF8EC',
    },
    {
        name: 'Rosso Corsa',
        colour_one: '#D40000',
        colour_two: '#EFE7D1',
    },
    {
        name: "'88 Le Mans",
        colour_one: '#72007D',
        colour_two: '#F3F1DB',
    },
    {
        name: '917',
        colour_one: '#FC510B',
        colour_two: '#6AC5F4',
    },

    {
        name: 'Group B',
        colour_one: '#060E2E',
        colour_two: '#B8E4F1',
    },
]
