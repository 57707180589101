// ApexBodyRichContentManagerDesktop

import {
    IApexSingleRichBodyState,
    IHybridPrismicPropsToState,
    IPrismicFieldPropsToState,
} from 'apexModels'
import React from 'react'

import styled from 'styled-components'
import ApexDuoHybridImageLandscapePortraitSliceDesktop from '../../../molecules/apex/reusable/richcontent/duoImg/hybridDuoImg/desktop/apexDuoHybridImageLandscapePortraitSliceDesktop'
import ApexDuoHybridImagePortraitLandscapeSliceDesktop from '../../../molecules/apex/reusable/richcontent/duoImg/hybridDuoImg/desktop/apexDuoHybridImagePortraitLandscapeSliceDesktop'
import ApexDuoImageLandscapeSliceDesktop from '../../../molecules/apex/reusable/richcontent/duoImg/simpleDuoImg/desktop/apexDuoImageLandscapeSliceDesktop'
import ApexDuoImagePortraitSliceDesktop from '../../../molecules/apex/reusable/richcontent/duoImg/simpleDuoImg/desktop/apexDuoImagePortraitSliceDesktop'
import ApexRichTextImageInsertedDesktop from '../../../molecules/apex/reusable/richcontent/singleImg/desktop/apexRichTextImageInsertedDesktop'
import ApexSingleImageLandscapeSliceDesktop from '../../../molecules/apex/reusable/richcontent/singleImg/desktop/apexSingleImageLandscapeSliceDesktop'
import ApexSingleImagePortraitSliceDesktop from '../../../molecules/apex/reusable/richcontent/singleImg/desktop/apexSingleImagePortraitSliceDesktop'
import ApexBodyRichContentTextItemDesktop from '../../../molecules/apex/reusable/richcontent/text/desktop/apexBodyRichContentTextItemDesktop'
import { device } from '../../../templates/displays/devices'

const Wrapper = styled.div`
    @media ${device.beyond_ipad_mobile} {
    }
`

type Props = {
    rich_objects_array_without_slice?: IHybridPrismicPropsToState[]
    rich_slice_objects_array?: IApexSingleRichBodyState[]
}

type SwitchPropsNoSlice = {
    obj: IHybridPrismicPropsToState
}

type SwitchSliceProps = {
    obj: IApexSingleRichBodyState
}

const SwitchComponentNoSlice: React.FunctionComponent<SwitchPropsNoSlice> = (
    props
) => {
    let { obj } = props
    return (
        <>
            {(() => {
                switch (obj.type) {
                    case 'text':
                        return <ApexBodyRichContentTextItemDesktop item={obj} />
                    case 'image':
                        return (
                            <ApexRichTextImageInsertedDesktop img_obj={obj} />
                        )
                    default:
                        return <div />
                }
            })()}
        </>
    )
}

const SwitchSliceComponent: React.FunctionComponent<SwitchSliceProps> = (
    props
) => {
    let { obj } = props
    return (
        <>
            {(() => {
                switch (obj.slice_type) {
                    case 'body_rich_content':
                        return (
                            <>
                                {obj.text_array ? (
                                    obj.text_array.map(
                                        (obj: IPrismicFieldPropsToState) => {
                                            return (
                                                <ApexBodyRichContentTextItemDesktop
                                                    item={obj}
                                                />
                                            )
                                        }
                                    )
                                ) : (
                                    <div />
                                )}
                            </>
                        )

                    case 'single_image_portrait':
                        return (
                            <>
                                {obj.img && (
                                    <ApexSingleImagePortraitSliceDesktop
                                        img_obj={obj.img}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )

                    case 'single_image_landscape':
                        return (
                            <>
                                {obj.img && (
                                    <ApexSingleImageLandscapeSliceDesktop
                                        img_obj={obj.img}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_lansdcape_lansdcape':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoImageLandscapeSliceDesktop
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_portrait_portrait':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoImagePortraitSliceDesktop
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_hybrid_landscape_portrait':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoHybridImageLandscapePortraitSliceDesktop
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )
                    case 'duo_img_hybrid_portrait_landscape':
                        return (
                            <>
                                {obj.img_1 && obj.img_2 && (
                                    <ApexDuoHybridImagePortraitLandscapeSliceDesktop
                                        img_1={obj.img_1}
                                        img_2={obj.img_2}
                                        image_credit={
                                            obj.image_credit && obj.image_credit
                                        }
                                    />
                                )}
                            </>
                        )

                    default:
                        return <div />
                }
            })()}
        </>
    )
}

class ApexBodyRichContentManagerDesktop extends React.Component<Props, {}> {
    render() {
        let goThroughArrayWithoutSlice = (
            arr: IHybridPrismicPropsToState[]
        ) => {
            return arr.map((obj: IHybridPrismicPropsToState, index: number) => {
                return <SwitchComponentNoSlice obj={obj} />
            })
        }

        let goThroughSlicedObjects = (arr: IApexSingleRichBodyState[]) => {
            return arr.map((obj: IApexSingleRichBodyState, index: number) => {
                return <SwitchSliceComponent obj={obj} />
            })
        }

        let {
            rich_objects_array_without_slice,
            rich_slice_objects_array,
        } = this.props
        return (
            <Wrapper>
                {rich_objects_array_without_slice !== undefined &&
                    goThroughArrayWithoutSlice(
                        rich_objects_array_without_slice
                    )}

                {rich_slice_objects_array !== undefined &&
                    goThroughSlicedObjects(rich_slice_objects_array)}
            </Wrapper>
        )
    }
}

export default ApexBodyRichContentManagerDesktop
