import { IDropdownItem } from 'entityModels'
import {
    ISearchShowroom_API_res_filter,
    ISearchShowroom_API_res_filter_option,
} from '../../services/showroom/types'
import { IShowroomFilterRadioOptionItem } from '../../showroom/types'
import { generateFrontEndValueDistanceUnit } from '../../../helpers/units/unitConversion'

export type I_convertShowRoomSearchAPIOptionsToState_res = {
    make: IDropdownItem[]
    model: IDropdownItem[]
    body_type: IDropdownItem[]
    exterior_color: IShowroomFilterRadioOptionItem[]
    year?: {
        min_backend?: number
        max_backend?: number
    }
    mileage?: {
        min_backend?: number
        max_backend?: number
    }
    price?: {
        min_backend?: number
        max_backend?: number
    }
}

export const convertShowRoomSearchAPIOptionsToState = (
    p: ISearchShowroom_API_res_filter[]
): I_convertShowRoomSearchAPIOptionsToState_res => {
    let res: I_convertShowRoomSearchAPIOptionsToState_res = {
        make: [],
        model: [],
        body_type: [],
        exterior_color: [],
    }

    for (let i = 0; i < p.length; i++) {
        if (p[i].name === 'make' && p[i].details !== undefined) {
            res.make = p[i].details!.map(
                (item: ISearchShowroom_API_res_filter_option) => {
                    return {
                        uid: item.value,
                        name: item.value,
                    }
                }
            )
        }

        if (p[i].name === 'model' && p[i].details !== undefined) {
            res.model = p[i].details!.map(
                (item: ISearchShowroom_API_res_filter_option) => {
                    return {
                        uid: item.value,
                        name: item.value,
                    }
                }
            )
        }

        if (p[i].name === 'body_type' && p[i].details !== undefined) {
            res.body_type = p[i].details!.map(
                (item: ISearchShowroom_API_res_filter_option) => {
                    return {
                        uid: item.value,
                        name: item.value,
                    }
                }
            )
        }

        if (p[i].name === 'exterior_color' && p[i].details !== undefined) {
            res.exterior_color = p[i].details!.map(
                (item: ISearchShowroom_API_res_filter_option) => {
                    return {
                        id: item.value,
                        name:
                            item.value.charAt(0).toUpperCase() +
                            item.value.slice(1),
                    }
                }
            )
        }

        if (p[i].name === 'year') {
            res.year = {}
            if (p[i].min !== undefined) {
                res.year['min_backend'] = p[i].min
            }
            if (p[i].max !== undefined) {
                res.year['max_backend'] = p[i].max
            }
        }

        if (p[i].name === 'mileage') {
            res.mileage = {}
            if (p[i].min !== undefined) {
                let minn = generateFrontEndValueDistanceUnit('miles', p[i].min!)
                res.mileage['min_backend'] = minn < 0 ? 0 : minn
            }
            if (p[i].max !== undefined) {
                res.mileage['max_backend'] = generateFrontEndValueDistanceUnit(
                    'miles',
                    p[i].max!
                )
            }
        }

        if (p[i].name === 'price') {
            res.price = {}
            let num = p[i].min
            if (num !== undefined) {
                res.price['min_backend'] = num / 100
            }

            let numax = p[i].max
            if (numax !== undefined) {
                res.price['max_backend'] = numax / 100
            }
        }
    }

    return res
}
