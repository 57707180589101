import { useHistory } from 'react-router'
import { showroomActions } from '../../../../redux/showroom/reducer'
import {
    IShowroomEntriesSimilarRecommendations,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { IRootState } from '../../../../redux/store'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import ShowroomEntryRecommendationsDesktop from './entryRecommendationsDesktop'
import ShowroomEntryRecommendationsMobile from './entryRecommendationsMobile'

type Props = {
    entry?: IShowroomEntry
    isMobile: boolean
}

export const ShowroomEntryRecommendationsManager = (props: Props) => {
    const { entry, isMobile } = props

    let isLoading = useAppSelector((state) => state.showroom.isSearchLoading)

    let recommendationsObj: IShowroomEntriesSimilarRecommendations =
        useAppSelector(
            (state: IRootState) => state.showroom.similar_recommendations
        )

    const dispatch = useAppDispatch()

    const history = useHistory()

    const resetHandle = () => {
        dispatch(showroomActions.resetAllFilters())
        dispatch(showroomActions.setCurrentPageNumber_sold(0))
        dispatch(showroomActions.setCurrentPageNumber_sale(0))

        dispatch(
            showroomActions.searchShowroom_setCars({
                cars: undefined,
                resultsFoundNumber: undefined,
                similarSoldCarsNumber: undefined,
                type: 'sale',
            })
        )
    }

    const clickOnOwner = () => {
        if (
            entry &&
            entry.owner &&
            entry.owner.id &&
            entry.owner.display_name
        ) {
            resetHandle()
            dispatch(
                showroomActions.setOwner_filter({
                    id: entry.owner?.id,
                    display_name: entry.owner?.display_name,
                })
            )

            setTimeout(() => {
                let url = `/showroom/for-sale`

                url =
                    url +
                    `?owner_id=${entry.owner?.id}&owner_display_name=${entry.owner?.display_name}`
                url = encodeURI(url)
                history.push(url)
            }, 100)
        }
    }

    const clickOnMake = () => {
        if (entry?.car?.overview?.data?.make?.answer) {
            resetHandle()

            setTimeout(() => {
                dispatch(showroomActions.setFilterOpen('make'))
                dispatch(
                    showroomActions.setMultiSelect_filter({
                        id: 'make',
                        value: `${entry?.car?.overview?.data?.make?.answer}`,
                    })
                )

                dispatch(
                    showroomActions.searchShowroomRequest({
                        states: 'PUBLISHED',
                    })
                )

                dispatch(showroomActions.setFilterOpen(undefined))
            }, 50)

            setTimeout(() => {
                history.push(`/showroom/for-sale`)
            }, 200)
        }
    }

    const onClickBrowseAll = () => {
        resetHandle()
        history.push('/showroom/for-sale')
    }

    return (
        <>
            {isMobile && (
                <ShowroomEntryRecommendationsMobile
                    entry={entry}
                    recommendationsObj={recommendationsObj}
                    clickOnOwner={clickOnOwner}
                    clickOnMake={clickOnMake}
                    isLoading={isLoading ?? false}
                    onClickBrowseAll={onClickBrowseAll}
                />
            )}
            {!isMobile && (
                <ShowroomEntryRecommendationsDesktop
                    entry={entry}
                    recommendationsObj={recommendationsObj}
                    clickOnOwner={clickOnOwner}
                    clickOnMake={clickOnMake}
                    isLoading={isLoading ?? false}
                    onClickBrowseAll={onClickBrowseAll}
                />
            )}
        </>
    )
}
