import { call, fork, join, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'

import {
    gen_draft_differing_self_replicating_entities_multiple,
    gen_draft_differing_values_to_patch,
} from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { IRootState } from '../../../store'
import { api } from '../../../services'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from '../application/get'
import posthog from 'posthog-js'
import { entityDeletionSaga } from './maindriver_additionaldetails_submit'

export let insurance_application_draft_state_select = (state: IRootState) =>
    state.insuranceQuoteApplication.draft

export let insurance_application_existing_state_select = (
    state: IRootState
): IInsuranceQuoteApplication => state.insuranceQuoteApplication.submitted_data

export function* Submit_maindriver_trackRecord(): any {
    posthog.capture('Update insurance application customer track record')

    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let application_has_deletions = false
        let application_has_patch = false

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft = { ...all_draft.main_driver.track_record }
        let existing = { ...all_existing.main_driver.track_record }

        let dataToSendToPatch: IAnyObject | undefined =
            gen_draft_differing_values_to_patch({
                existing: existing,
                draft: draft,
            })

        if (all_draft.main_driver.entities) {
            let entitiies_draft = {
                motoring_convictions:
                    all_draft.main_driver.entities['motoring_convictions'],
                convictions: all_draft.main_driver.entities.convictions,
                claims: all_draft.main_driver.entities.claims,
            }

            let entitiies_existing = {
                motoring_convictions:
                    all_existing.main_driver.entities['motoring_convictions'],
                convictions: all_existing.main_driver.entities.convictions,
                claims: all_existing.main_driver.entities.claims,
            }
            let entities_info =
                gen_draft_differing_self_replicating_entities_multiple({
                    draft: entitiies_draft,
                    existing: entitiies_existing,
                })

            if (entities_info.data_to_patch_by_type) {
                dataToSendToPatch = dataToSendToPatch
                    ? {
                          ...dataToSendToPatch,
                          ...entities_info.data_to_patch_by_type,
                      }
                    : { ...entities_info.data_to_patch_by_type }
            }

            if (entities_info.ids_to_delete_by_type) {
                let arrayOfDeletionTasks: any = []
                let entity_ids = Object.keys(
                    entities_info.ids_to_delete_by_type
                )
                for (let i = 0; i < entity_ids.length; i++) {
                    let entity_type = entity_ids[i]

                    for (
                        let i = 0;
                        i <
                        entities_info.ids_to_delete_by_type[entity_type].length;
                        i++
                    ) {
                        let id_to_delete: string =
                            entities_info.ids_to_delete_by_type[entity_type][i]

                        // console.log(
                        //     'deleting entity type:',
                        //     entity_type,
                        //     'of ID:',
                        //     id_to_delete
                        // )

                        let req_del: IDeleteInsuranceApplication_req = {
                            appli_id: `${all_existing.id}`,
                            entity_id: id_to_delete,
                            // @ts-ignore
                            entity_type: entity_type,
                            parent_customer_id: all_draft.main_driver.id,
                            is_customer: true,
                        }

                        const deletionTask = yield fork(entityDeletionSaga, {
                            ...req_del,
                        })
                        arrayOfDeletionTasks.push(deletionTask)

                        // call BE with /DELETE/entity_type id =entity_id
                    }
                }
                if (arrayOfDeletionTasks && arrayOfDeletionTasks.length > 0) {
                    yield join(arrayOfDeletionTasks)
                    application_has_deletions = true
                }
            }
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // console.log('data to patch', dataToSendToPatch)
            // call api to patch as thats all the data merged we want to patch
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { customer: { ...dataToSendToPatch } },
                }
            )
            application_has_patch = true

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )
            yield put(
                insuranceActions.submit_mainDriver_trackRecord_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_mainDriver_trackRecord_success())
        }

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        return
    } catch (error: any) {
        // console.log('error', error)
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_maindriver_trackRecord,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/main_driver/${all_existing.main_driver.id}/track_record`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_mainDriver_trackRecord_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_maindriver_trackRecord() {
    while (true) {
        yield take(insuranceActions.submit_mainDriver_trackRecord_request)
        yield call(Submit_maindriver_trackRecord)
    }
}

const maindriver_trackRecord_submit: any[] = [
    fork(Watcher_Submit_maindriver_trackRecord),
]

export default maindriver_trackRecord_submit
