import styled from 'styled-components'
import { filterByTaskStatus } from '../../../../helpers/filter/filterbyTaskStatus'
import {
    sortObjectByCreatedAt,
    sortObjectByDueDate,
} from '../../../../helpers/sort/sortByDate'
import task_skeleton_mobile from '../../../atoms/skeleton/tasks/task_skeleton_mobile.png'
import task_skeleton_mobile_dark from '../../../atoms/skeleton/tasks/task_skeleton_mobile_dark.png'
import { device } from '../../../templates/displays/devices'

import TaskCardsListMobile from '../../../molecules/tasks/taskCards/taskCardsListMobile'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../../ol/ol'
import { ITasksState, IUpdateTaskReq, ICarsState } from 'entityModels'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    all_tasks: ITasksState
    all_tasks_uids: string[]
    state: {
        dropdownCars: never[]
        carSelected: string | null
        sortBy: string
        filterBy: string
        dropdownCarsWithOptions: never[]
    }
    updateTaskRequest: (payload: IUpdateTaskReq) => void
    carsData: ICarsState
    toggleCreateTaskForm: () => void
    garageCars?: any
    tasks_ids_garage_level_only?: string[]
}

const Wrap = styled.div`
    width: 100%;
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`

const Container = styled.div`
    /* min-height: 100vh; */
    background-color: var(--bg-color, #fff);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media ${device.ipad} {
        width: 100%;
    }
`

const SkeletonImg = styled.img`
    height: 252px;
    width: 100%;
    object-fit: cover;
    @media ${device.ipad} {
        height: 300px;
    }
`

const TaskListManagerMobile = (props: Props) => {
    const {
        all_tasks,
        all_tasks_uids,
        state,
        updateTaskRequest,
        carsData,
        toggleCreateTaskForm,
        garageCars,
        tasks_ids_garage_level_only,
    } = props

    const { theme } = useThemes()

    let determine_ids_to_render_mobile_all = (garageOnly?: boolean) => {
        let { sortBy, filterBy } = state

        if (
            sortBy === OTaskFilterNSort.sort_by.created_at.name ||
            sortBy === 'all'
        ) {
            let arr: string[] = []
            if (garageOnly && tasks_ids_garage_level_only) {
                arr = sortObjectByCreatedAt(
                    all_tasks.tasks,
                    tasks_ids_garage_level_only
                )
            } else {
                arr = sortObjectByCreatedAt(all_tasks.tasks, all_tasks_uids)
            }

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr2}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else {
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        garageCars={garageCars}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            }
        } else if (sortBy === OTaskFilterNSort.sort_by.due.name) {
            let arr: string[] = []

            if (garageOnly && tasks_ids_garage_level_only) {
                arr = sortObjectByDueDate(
                    all_tasks.tasks,
                    tasks_ids_garage_level_only
                )
            } else {
                arr = sortObjectByDueDate(all_tasks.tasks, all_tasks_uids)
            }

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        garageCars={garageCars}
                        tasks_ids_to_render={arr2}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else {
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            }
        } else
            return (
                <TaskCardsListMobile
                    carsData={carsData.cars}
                    all_tasks={all_tasks.tasks}
                    tasks_ids_to_render={
                        garageOnly === true
                            ? tasks_ids_garage_level_only
                                ? tasks_ids_garage_level_only
                                : []
                            : all_tasks_uids
                    }
                    updateTask={updateTaskRequest}
                    garageCars={garageCars}
                    toggleCreateTaskForm={toggleCreateTaskForm}
                />
            )
    }

    let determine_ids_to_render_mobile_car = () => {
        let { sortBy, filterBy } = state
        if (sortBy === 'all') {
            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr = filterByTaskStatus(
                    all_tasks.tasks,
                    carsData.tasks[props.state.carSelected!] !== undefined
                        ? carsData.tasks[props.state.carSelected!]
                        : [''],
                    true
                )

                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr = filterByTaskStatus(
                    all_tasks.tasks,
                    carsData.tasks[props.state.carSelected!] !== undefined
                        ? carsData.tasks[props.state.carSelected!]
                        : [''],
                    false
                )

                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        garageCars={garageCars}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else {
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={
                            carsData.tasks[props.state.carSelected!] !==
                            undefined
                                ? carsData.tasks[props.state.carSelected!]
                                : ['']
                        }
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            }
        } else if (sortBy === OTaskFilterNSort.sort_by.created_at.name) {
            let arr = sortObjectByCreatedAt(
                all_tasks.tasks,
                carsData.tasks[props.state.carSelected!] !== undefined
                    ? carsData.tasks[props.state.carSelected!]
                    : ['']
            )

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr2}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else {
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            }
        } else if (sortBy === OTaskFilterNSort.sort_by.due.name) {
            let arr = sortObjectByDueDate(
                all_tasks.tasks,
                carsData.tasks[props.state.carSelected!] !== undefined
                    ? carsData.tasks[props.state.carSelected!]
                    : ['']
            )

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr2}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else {
                return (
                    <TaskCardsListMobile
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            }
        } else
            return (
                <TaskCardsListMobile
                    carsData={carsData.cars}
                    all_tasks={all_tasks.tasks}
                    tasks_ids_to_render={
                        carsData.tasks[props.state.carSelected!] !== undefined
                            ? carsData.tasks[props.state.carSelected!]
                            : ['']
                    }
                    updateTask={updateTaskRequest}
                    toggleCreateTaskForm={toggleCreateTaskForm}
                    garageCars={garageCars}
                />
            )
    }

    let skeletons = () => (
        <Wrap>
            {[1, 2, 3].map((item) => (
                <SkeletonImg
                    src={
                        theme === 'light'
                            ? task_skeleton_mobile
                            : task_skeleton_mobile_dark
                    }
                />
            ))}
        </Wrap>
    )

    // check with carsData whether title

    let doesCarHaveAtTitle = (carid: string) => {
        let carsData = props.carsData

        if (carsData.cars[carid]) {
            if (carsData.cars[carid].title) {
                if (carsData.cars[carid].title.length > 1) {
                    return true
                } else return false
            }
        } else return true
    }

    return (
        <Container>
            {Object.keys(all_tasks.tasks).length !== 0 && (
                <Container>
                    {!props.state.carSelected &&
                        determine_ids_to_render_mobile_all()}

                    {props.state.carSelected &&
                        props.state.carSelected ===
                            OOtherTaskOptions.general_task.name &&
                        determine_ids_to_render_mobile_all(true)}

                    {props.state.carSelected &&
                        props.state.carSelected !==
                            OOtherTaskOptions.general_task.name &&
                        doesCarHaveAtTitle(props.state.carSelected) === true &&
                        determine_ids_to_render_mobile_car()}

                    {props.state.carSelected &&
                        props.state.carSelected !==
                            OOtherTaskOptions.general_task.name &&
                        doesCarHaveAtTitle(props.state.carSelected) !== true &&
                        determine_ids_to_render_mobile_all()}
                </Container>
            )}

            {Object.keys(all_tasks.tasks).length === 0 && skeletons()}
        </Container>
    )
}

export default TaskListManagerMobile
