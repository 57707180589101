import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { Select } from '../../../../../atoms/select'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_selectSearchDesktop: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc?: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id?: any
    optionsList: any
    carid: string
    placeholder?: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        item,
        optionsList,
        setFieldValue,
        handleSubmit,
        placeholder,
    } = props

    let checkIfDisabled = () => {
        if (!props.values.single_item_edited_value_selectSearchDesktop) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop $theme={theme} style={{ marginTop: '20vh' }}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperDesktop>
                <Padding5 />
                {optionsList && (
                    <Select
                        options={optionsList.map(
                            (option: { name: string }) => ({
                                value: option.name,
                                label: option.name,
                            })
                        )}
                        defaultValue={item?.answer}
                        placeholder={item.text}
                        helperText={placeholder}
                        onChange={(name) =>
                            setFieldValue(
                                'single_item_edited_value_selectSearchDesktop',
                                name
                            )
                        }
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                )}
                <Padding40 />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarTechInfoEditFormSelectSearchDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_selectSearchDesktop: props.item.answer
            ? props.item.answer
            : undefined,
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        if (props.submitFunc) {
            let payload: IwriteTechnicalInformationPayloadReq = {
                tech_info_id: props.tech_info_id,
                car_id: props.carid,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answer: values.single_item_edited_value_selectSearchDesktop,
                },
            }

            props.submitFunc(payload)
        }
        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarTechInfoEditFormSelectSearchDesktop)
