import { ITimelineCreateForms } from 'myModels'
import * as React from 'react'
import { connect } from 'react-redux'
import {
    IActionPayloadTimelineCreationRequest,
    // ICostItem,
    ITimelineActionType,
    ITimelineCreationFormFieldsData,
    ITimelineDataToBeSubmittedObj,
    ITimelineEntityType,
    ITimelineEntryTypes,
    ITimelineItem,
    // ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'

import { RootState } from 'typesafe-actions'

import { TimelineCreationTypeIdsEnum } from '../../../../redux/timeline/timelineEnum'
import { manageDataToBeSubmittedTimelineCreationRequest } from '../../../../redux/timeline/actions/requestActions'
import TimelineEditGenerateCreateFormDesktop from '../timelineFormGenerators/timelineEditGenerateCreateFormDesktop'
import { withRouter, RouteComponentProps } from 'react-router-dom'

// import { manageDataToBeSubmittedTimelineCreation } from '../../../../redux/timeline/thunks'

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {
    manageDataToBeSubmittedTimelineCreation: (
        payload: IActionPayloadTimelineCreationRequest
    ) => manageDataToBeSubmittedTimelineCreationRequest(payload),
}

export interface Props extends RouteComponentProps<any> {
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => any
    close_create_form: any
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted?: ITimelineDataToBeSubmittedObj
    manageDataToBeSubmittedTimelineCreation: (
        payload: IActionPayloadTimelineCreationRequest
    ) => void
    submitDataRequest?: any
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined
}

type State = {
    // item_being_created: ITimelineDataToBeSubmittedObj | undefined
}

class TimelineCreateManagerDesktop extends React.PureComponent<Props, State> {
    state = {}

    // storing created item into the state
    updateItemBeingCreated = (
        fieldID: any,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => {
        if (fieldID === 'categoryID' && value === 'insurance') {
            this.props.history.push(
                `/car/${this.props.carID}/history-file/create/insurance`
            )
        } else {
            this.props.manageDataToBeSubmittedTimelineCreation({
                carid: this.props.carID,
                entityType: entityType,
                action: action,
                fieldID: fieldID,
                fieldValue: value,
            })
        }
    }

    // function for going to the next step of creation
    goToNextStep = () => {
        let {
            manageTimelineCreationFormState,
            timeline_creation_forms_current_state,
            timeline_creation_steps_object_of_indexed_list,
        } = this.props

        let steps_list: string[] | null =
            timeline_creation_forms_current_state.active_creation &&
            timeline_creation_steps_object_of_indexed_list[
                timeline_creation_forms_current_state.active_creation
            ]

        let next_index: number =
            timeline_creation_forms_current_state.current_step_index + 1

        if (steps_list) {
            if (
                next_index > 0 &&
                next_index < steps_list.length &&
                steps_list.length > 1
            ) {
                return manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: next_index,
                    active_creation:
                        timeline_creation_forms_current_state.active_creation,
                })
            } else {
                return this.props.close_create_form()
            }
        }
    }

    // function for going to the previous step of creation
    goToPreviousStep = () => {
        let {
            manageTimelineCreationFormState,
            timeline_creation_forms_current_state,
        } = this.props

        let previous_index: number =
            timeline_creation_forms_current_state.current_step_index - 1

        if (previous_index >= 0) {
            return manageTimelineCreationFormState({
                isOpen: true,
                current_step_index: previous_index,
                active_creation:
                    timeline_creation_forms_current_state.active_creation,
            })
        } else {
            //closing when going back from first step
            if (this.props.entityType === 'cost' && this.props.activeEntry) {
                this.props.history.push(
                    `/car/${this.props.carID}/history-file/entry?entryid=${this.props.activeEntry.id}`
                )
            } else {
                this.props.history.push(`/car/${this.props.carID}/history-file`)
            }

            return this.props.close_create_form()
        }
    }

    componentDidMount() {
        this.setState({
            initial_active_creation:
                this.props.timeline_creation_forms_current_state
                    .active_creation,
        })
    }

    render() {
        let {
            close_create_form,
            timeline_creation_forms_current_state,
            timeline_creation_steps_data,
            timeline_creation_steps_object_of_indexed_list,
            data_to_be_submitted,
            manageDataToBeSubmittedTimelineCreation,
            activeEntry,
        } = this.props

        // let { item_being_created } = this.state

        let current_step_index: number =
            timeline_creation_forms_current_state.current_step_index

        let active_creation:
            | ITimelineEntryTypes
            | TimelineCreationTypeIdsEnum
            | null =
            this.props.timeline_creation_forms_current_state.active_creation

        let steps_list: string[] | null =
            timeline_creation_forms_current_state.active_creation &&
            timeline_creation_steps_object_of_indexed_list[
                timeline_creation_forms_current_state.active_creation
            ]

        let current_step_data =
            steps_list &&
            timeline_creation_steps_data[steps_list[current_step_index]]

        return (
            <div
                data-attr={`${
                    current_step_data && current_step_data.submit.entity
                }-creation-manager_desktop`}
            >
                {steps_list && current_step_data && (
                    <TimelineEditGenerateCreateFormDesktop
                        closeForm={close_create_form}
                        step_list={steps_list}
                        current_step_index={
                            timeline_creation_forms_current_state.current_step_index
                        }
                        stepFieldsData={current_step_data.step_fields_data}
                        stepFieldIdsList={current_step_data.step_field_ids}
                        stepTitle={current_step_data.step_title}
                        stepSubtitle={current_step_data.step_subtitle}
                        stepSubtitleDetail={
                            current_step_data.step_subtitle_detail
                        }
                        submit={current_step_data.submit}
                        data_to_be_submitted={data_to_be_submitted}
                        onChange={this.updateItemBeingCreated}
                        manageSteps={this.goToNextStep}
                        submitDataRequest={() => {
                            manageDataToBeSubmittedTimelineCreation({
                                carid: this.props.carID,
                                isReadyToSendToApi: true,
                            })
                        }}
                        goBackOneStep={this.goToPreviousStep}
                        showStepTitleAndSubtitle={
                            active_creation ===
                                TimelineCreationTypeIdsEnum.add_new_desktop &&
                            current_step_index === 0
                        }
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                        activeEntry={activeEntry}
                    />
                )}
            </div>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TimelineCreateManagerDesktop)
)
