import React, { useState } from 'react'
import styled from 'styled-components'
import replayWhiteIcon from '../../../public/assets/tutorials_replay/replay_white.svg'
import { Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'

const HelpTutorialItemStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 29%;
    height: 120px;

    @media (max-width: 340px) {
        width: 60px;
    }

    @media ${device.ipad} {
        width: auto;
    }
`

const Title = styled.div`
    font-family: 'Lato-light';
    font-size: 14px;
    font-weight: 300;
    color: #5ec3ca;
    text-align: center;
    padding-bottom: 10px;
    height: 34px;

    @media (max-width: 340px) {
        font-size: 13px;
        height: 56px;
        padding-bottom: 0px;
    }
`

const ReplayButton = styled.div<IStyleProps>`
    height: 60px;
    width: 60px;
    background: #00b0b7;
    filter: ${(props) => (props.$isDisabled ? 'grayscale(100%)' : '')};
    border-radius: 50%;
    cursor: pointer;

    @media (max-width: 340px) {
        height: 50px;
        width: 50px;
    }
`

const ReplayLink = styled(Link)<IStyleProps>`
    height: 60px;
    width: 60px;
    @media (max-width: 340px) {
        height: 50px;
        width: 50px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
`

const ReplayWhiteIcon = styled.img<IStyleProps>`
    height: 30px;

    @media (max-width: 340px) {
        height: 30px;
    }

    src: ${(props) => props.src};
`

interface IStyleProps {
    url?: string
    src?: string
    $isDisabled?: boolean
}

interface IHelpTutorialItem {
    title: string
    btnUrl: string
    link: string
    isDisabled?: boolean
    tooltipTitle?: string
}

const HelpTutorialItemMobile: React.SFC<IHelpTutorialItem> = (props) => {
    const [showTooltip, setShowTooltip] = useState(false)
    const toggleTooltip = (e: any) => {
        e.preventDefault()
        setShowTooltip(true)
        setTimeout(() => {
            setShowTooltip(false)
        }, 2000)
    }

    return (
        <HelpTutorialItemStyle>
            <Title>{props.title}</Title>
            <motion.div whileTap={{ scale: 1.2 }}>
                <ReplayButton url={props.btnUrl} $isDisabled={props.isDisabled}>
                    {props.isDisabled ? (
                        <ReplayLink to={props.link} onClick={toggleTooltip}>
                            <ReplayWhiteIcon src={replayWhiteIcon} />
                            <Tooltip
                                title={props.tooltipTitle}
                                open={showTooltip}
                                placement="bottom"
                                color="#5EC3CA"
                            />
                        </ReplayLink>
                    ) : (
                        <ReplayLink to={props.link}>
                            <ReplayWhiteIcon src={replayWhiteIcon} />
                        </ReplayLink>
                    )}
                </ReplayButton>
            </motion.div>
        </HelpTutorialItemStyle>
    )
}

export default HelpTutorialItemMobile
