import { useEffect, useMemo, useState } from 'react'
import {
    RouteComponentProps,
    useHistory,
    useLocation,
    useParams,
    withRouter,
} from 'react-router'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'

import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'

import ShowroomCarMainImageDesktop from '../../../atoms/image/showroom/showroomCarMainImageDesktop'

import FullWidthMobile from '../../../atoms/image/fullWidthMobile'

import DescriptionSectionDesktop from '../../../molecules/showroom/section/descriptionSectionDesktop'
import DescriptionSectionMobile from '../../../molecules/showroom/section/descriptionSectionMobile'
import FactsGridSectionMobile from '../../../molecules/showroom/section/factsGridSectionMobile'
import KeyMomentsSectionDesktop from '../../../molecules/showroom/section/keyMomentsSectionDesktop'
import KeyMomentsSectionMobile from '../../../molecules/showroom/section/keyMomentsSectionMobile'
import OverviewSectionDesktop from '../../../molecules/showroom/section/overviewSectionDesktop'
import OverviewSectionMobile from '../../../molecules/showroom/section/overviewSectionMobile'
import OwnerSectionDesktop from '../../../molecules/showroom/section/ownerSectionDesktop'
import OwnerSectionMobile from '../../../molecules/showroom/section/ownerSectionMobile'
import { device } from '../../../templates/displays/devices'
import OwnerSectionMobileWithCarPic from '../../../molecules/showroom/section/ownerSectionMobileWithCarPic'
import ShowroomOverviewGalleryDesktop from '../../../molecules/imageGrid/showroomOverviewGalleryDesktop'

import { ICar, IGalleryImage, IGalleryImagesObject } from 'entityModels'
import DuoGridItemMobile from '../../../atoms/image/duoGridItemMobile'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { CloseOutlined } from '@ant-design/icons'
import ModalDisplayFullPageDesktopExclusive from '../../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import ShowroomModalEditManagerDesktop from '../../../organisms/showroom/showroomModalEditManager/showroomModalEditManagerDesktop'
import CarShowroomTopBar from '../../../templates/bars/showroomBars/topBars/carShowroomTopBar'
import CarShowroomEditBottomBar from '../../../templates/bars/showroomBars/bottomBars/carShowroomEditBottomBar'
import ShowroomModalEditManagerMobile from '../../../organisms/showroom/showroomModalEditManager/showroomModalEditManagerMobile'
import OwnerContactFormMobile from '../../../organisms/showroom/contactForm/ownerContactFormMobile'
import CarOverviewFormsBodyDesktop from '../../../organisms/carOverviewForms/carOverviewFormsBodyDesktop'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { sortList } from '../../../molecules/car/CarKeyMomentsfactsDesktop'
import { editFormsActions } from '../../../../redux/editForms/reducer'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import AddBoxWithSvgPlaceholderDesktop from '../../../atoms/placeholders/addBoxWithSvgPlaceholderDesktop'
import AddBoxWithSvgPlaceholderMobile from '../../../atoms/placeholders/addBoxWithSvgPlaceholderMobile'
import CarOverviewFormsBodyMobile from '../../../organisms/carOverviewForms/carOverviewFormsBodyMobile'
import { getCarDataByIdRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import CarLocationFormDataManagerMobile from '../../../molecules/showroom/locationForm/carLocationDataManagerMobile'
import ShowroomTechInfoFormMobile from '../../../molecules/showroom/techInfoForm/showroomTechInfoFormMobile'
import { setCarShowroomEntryDeleted } from '../../../../redux/entities/cars/actions/actions'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import { Link } from 'react-router-dom'
import * as SvgIcons from '../../../atoms/icons/components'
import styled from 'styled-components'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

export type ShowroomEntryParams = {
    entryid: string
}
const SectionWrapperWidth = styled.div`
    width: 80vw;

    @media (max-width: 1030px) {
        width: 92vw;
    }

    @media (min-width: 1600px) {
        max-width: 80vw;
    }

    @media (min-width: 1800px) {
        max-width: 80vw;
    }
    @media (min-width: 2500px) {
        max-width: 60vw;
    }
`

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const BannerImageOwnerWrapper = styled.div`
    width: 100%;
    height: 48vh;
    max-height: 300px;

    @media (min-width: 1175px) {
        max-height: 400px;
    }

    @media (min-width: 1500px) {
        max-height: 450px;
    }

    @media ${device.large_desktop} {
        height: 45vh;
        max-height: 700px;
    }

    @media (min-width: 1900px) {
        height: 35vh;
        min-height: 500px;
        max-height: 500px;
    }
`
const BannerImageOwner = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
`

const MainImageContainer = styled.div`
    width: 100%;
    height: 85vh;

    @media (max-width: 1000px) {
        max-height: 700px;
    }

    @media (max-width: 1300px) {
        max-height: 900px;
    }

    @media (max-width: 1400px) {
        max-height: 1000px;
    }
`

const MobileSectionWrapper = styled.div`
    padding-right: 1.5rem;
    padding-left: 1.5rem;
`
const DesktopGreyWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const MobileGreyWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const GalleryWrapperMobile = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    width: 100%;
`

const TitleTxt = styled.div`
    font-family: Lato-bold;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile} {
        font-size: 18px;
        letter-spacing: 0.005em;
    }
`
const SubtitleTxt = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    max-width: 440px;
    color: var(--text-default, #666666);
    text-align: center;

    @media ${device.mobile} {
        max-width: 80vw;
    }
`

const VideoInput = styled.input`
    border: 1px solid var(--text-muted, #b3b3b3);
    border-radius: 4px;
    width: 50vw;
    max-width: 559px;
    height: 48px;
    background-color: transparent;
    letter-spacing: 5e-5em;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding-left: 56px;
    padding-right: 40px;

    @media ${device.mobile} {
        width: 80vw;
    }
`

const LeftIcon = styled.div<{ animate?: boolean }>`
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    transition: all 200ms;
    @keyframes scaleUp {
        0% {
            scale: 0.1;
        }

        50% {
            scale: 1.2;
        }

        100% {
            scale: 1;
        }
    }
    @keyframes wiggle {
        0% {
            transform: rotate(0deg) translateY(-50%);
        }
        30% {
            transform: rotate(-5deg) translate(-10%, -50%);
        }
        60% {
            transform: rotate(5deg) translate(10%, -50%);
        }

        100% {
            transform: rotate(0deg) translateY(-50%);
        }
    }

    ${(props) =>
        props.animate
            ? `
    animation: 0.3s scaleUp both;
   
    `
            : `
    animation: 0.5s wiggle both;
   
    `}
`

export type IEditShowroomModalOptions =
    | 'edit_basic_info'
    | 'tech_info'
    | 'car_location'
    | 'unpublish_action'
    | 'unpublished_confirmed'
    | 'publication_confirmed'
    | 'deletion_action'
    | 'removal_action'
    | 'removal_confirmed'
    // | 'mark_as_sold_action'
    | 'mark_as_sold_confirmed'
    | 'dealer_address'

const ShowroomCarProfileEdit: React.FunctionComponent<RouteComponentProps> = (
    props: RouteComponentProps
) => {
    // redux hook for dispatch state actions
    const dispatch = useAppDispatch()
    /* 
    remove top sub menu from state
    TODO: (only for useSelector hook) need to gradually move to "createSelector" approach, for memoization / huge performance impact
    */
    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    const { entryid } = useParams<ShowroomEntryParams>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let keyMomentsFactsData = useAppSelector((state) => {
        return state.entities.highlightedFactsData.highlighted_facts
    })

    let userShowroomEntriesData = useAppSelector((state) => {
        return state.showroom.userShowroomEntries
    })

    let entryError = useAppSelector((state) => {
        return state.showroom.error
    })

    let validationError = useAppSelector((state) => {
        return state.showroom.validationError
    })

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let currentEntry: IShowroomEntry | undefined =
        entryid && userShowroomEntriesData && userShowroomEntriesData[entryid]
            ? userShowroomEntriesData[entryid]
            : undefined

    let carProfile: ICar | undefined = currentEntry
        ? currentEntry.car
        : undefined

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let car_profile_image: IGalleryImage | undefined =
        currentEntry && currentEntry.cover_image
            ? currentEntry.cover_image
            : currentEntry &&
              currentEntry.car &&
              currentEntry.car.gallery.cover &&
              images_data &&
              images_data[currentEntry.car.gallery.cover]
            ? images_data[currentEntry.car.gallery.cover]
            : undefined

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        }
    }, [])
    useEffect(() => {
        if (
            userLoggedIn &&
            carProfile?.id &&
            (!carsData || (carsData && !carsData[carProfile?.id]))
        ) {
            dispatch(getCarDataByIdRequest(carProfile.id))
        }
    }, [carProfile, userLoggedIn])

    useEffect(() => {
        // trigger the function to dispatch the top sub menu remove action
        removeTopSubMenu({ isRemoved: true })

        let carid = carProfile?.id
        let isEntryDeletion =
            carsData &&
            carid &&
            carsData[carid] &&
            carsData[carid].showroom_entry === undefined
                ? true
                : false

        if (entryid && userLoggedIn && !isEntryDeletion) {
            dispatch(
                showroomActions.getShowroomEntryByIDRequest({
                    is_owner: true,
                    entryID: entryid,
                })
            )
        }
        // set up nav back as before on page unmount
        return () => {
            removeTopSubMenu({ isRemoved: false })
        }
    }, [entryid, carsData, userLoggedIn])

    useEffect(() => {
        if (currentEntry && currentEntry.video_url) {
            setVideoEmbed(currentEntry.video_url)
        }

        // cleanup validation error if it's not a draft entry
        if (currentEntry?.state !== 'DRAFT' && validationError) {
            dispatch(showroomActions.validateEntryForPublicationSuccess())
        }
    }, [currentEntry])

    useEffect(() => {
        if (
            userLoggedIn &&
            currentEntry &&
            !currentEntry.cover_image &&
            carProfile?.id &&
            car_profile_image &&
            car_profile_image.id
        ) {
            dispatch(
                showroomActions.updateEntryShowroomRequest({
                    uid: entryid,
                    car_id: carProfile.id,
                    data: {
                        cover_image_id: car_profile_image.id,
                    },
                })
            )
        }
    }, [currentEntry, userLoggedIn])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        let local_form_id = params.get('form_id')
        if (local_form_id === 'makeModelYear') {
            setActiveForm('tech_info')
        }

        // if (currentEntry && currentEntry.state === 'SOLD') {
        //     handleSetModalOpened('mark_as_sold_confirmed')
        // }

        return () => {
            setActiveForm(null)
            handleSetModalOpened(null)
        }
    }, [])

    const [activeForm, setActiveForm] = useState<
        'contact' | 'showroom_car_location' | 'tech_info' | null
    >(null)

    const [videoEmbed, setVideoEmbed] = useState<string | undefined>(undefined)

    const [modalOpened, setModalOpened] =
        useState<IEditShowroomModalOptions | null>(null)

    const handleSetModalOpened = (p: IEditShowroomModalOptions | null) => {
        if (p === 'dealer_address') {
            setActiveForm('contact')
            return
        }

        if (p === 'mark_as_sold_confirmed') {
            if (carProfile && carProfile.id && currentEntry?.state !== 'SOLD') {
                dispatch(
                    showroomActions.updateEntryShowroomRequest({
                        uid: entryid,
                        car_id: carProfile.id,
                        data: {
                            state: 'SOLD',
                        },
                    })
                )
            }

            setTimeout(() => {
                if (!entryError) {
                    setModalOpened(p)
                }
            }, 250)
            return
        }

        if (p === 'unpublished_confirmed') {
            if (carProfile && carProfile.id) {
                dispatch(
                    showroomActions.updateEntryShowroomRequest({
                        uid: entryid,
                        car_id: carProfile.id,
                        data: {
                            state: 'DRAFT',
                        },
                    })
                )

                setTimeout(() => {
                    if (!entryError) {
                        setModalOpened(p)
                    }
                }, 250)
            }
            return
        }

        if (p === 'publication_confirmed') {
            if (carProfile && carProfile.id) {
                dispatch(
                    showroomActions.updateEntryShowroomRequest({
                        uid: entryid,
                        car_id: carProfile.id,
                        data: {
                            state: 'PUBLISHED',
                        },
                    })
                )
                setTimeout(() => {
                    if (!entryError) {
                        setModalOpened(p)
                    }
                }, 250)
            }
            return
        }
        if (p === 'car_location') {
            setActiveForm('showroom_car_location')
            return
        }
        if (p === 'tech_info') {
            setActiveForm('tech_info')
            return
        }
        if (p === 'removal_confirmed') {
            if (carProfile && carProfile.id) {
                dispatch(
                    showroomActions.deleteEntryShowroomRequest({
                        entryID: entryid,
                        carID: carProfile.id,
                    })
                )

                dispatch(setCarShowroomEntryDeleted(carProfile.id))

                setTimeout(() => {
                    if (!entryError && carProfile && carProfile.id) {
                        history.replace(`/car/${carProfile.id}`)
                    }
                }, 100)
            }
            return
        }
        if (p === 'deletion_action') {
            if (carProfile && carProfile.id) {
                dispatch(
                    showroomActions.deleteEntryShowroomRequest({
                        entryID: entryid,
                        carID: carProfile.id,
                    })
                )
                dispatch(setCarShowroomEntryDeleted(carProfile.id))

                setTimeout(() => {
                    if (!entryError && carProfile && carProfile.id) {
                        history.replace(`/car/${carProfile.id}`)
                    }
                }, 100)
            }
            return
        }
        setModalOpened(p)
    }

    const isPublished =
        currentEntry && currentEntry.state === 'PUBLISHED' ? true : false

    const updateShowroomVideoURL = (url: string | undefined) => {
        if (currentEntry && currentEntry.id && carProfile) {
            dispatch(
                showroomActions.updateEntryShowroomRequest({
                    car_id: carProfile.id,
                    uid: currentEntry.id,
                    data: {
                        video_url: url ? url : '',
                    },
                })
            )
        }
    }

    const videoEmbedFormDesktop = () => {
        return (
            <>
                <div
                    style={{
                        marginTop: '80px',
                        backgroundColor: 'var(--bg-color, #fff)',
                        width: '100%',

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ paddingTop: '80px' }} />
                    <TitleTxt>Video section - optional</TitleTxt>

                    <div style={{ paddingTop: '32px' }} />

                    <SubtitleTxt>
                        Paste a YouTube or Vimeo link below and you’re good to
                        go!
                    </SubtitleTxt>

                    <div style={{ paddingTop: '32px' }} />

                    <div style={{ position: 'relative' }}>
                        <VideoInput
                            value={videoEmbed}
                            onChange={(e: any) => {
                                setVideoEmbed(e.target.value)
                            }}
                            placeholder="Paste link here..."
                            style={
                                currentEntry && currentEntry.video_url
                                    ? { borderColor: 'var(--primary, #5EC3CA)' }
                                    : {}
                            }
                        />
                        <LeftIcon
                            animate={
                                currentEntry && currentEntry.video_url
                                    ? true
                                    : false
                            }
                        >
                            {currentEntry && currentEntry.video_url ? (
                                <SvgIcons.CheckIcon />
                            ) : (
                                <SvgIcons.LinkIcon />
                            )}
                        </LeftIcon>
                        <div
                            style={{
                                position: 'absolute',
                                right: '30px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                display:
                                    currentEntry && currentEntry.video_url
                                        ? 'flex'
                                        : 'none',
                            }}
                        >
                            <CloseOutlined
                                style={{
                                    color: 'var(--text-darker, #616161)',
                                }}
                                role="button"
                                onClick={() => {
                                    setVideoEmbed('')
                                    updateShowroomVideoURL('')
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ paddingTop: '32px' }} />

                    <ButtonAtom
                        theme={'flexible-transparent-blue-border'}
                        fontSize={16}
                        onClick={() => {
                            updateShowroomVideoURL(videoEmbed)
                        }}
                        disabled={!videoEmbed}
                    >
                        <div
                            style={{
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            {currentEntry && currentEntry.video_url
                                ? 'Applied'
                                : 'Apply'}
                        </div>
                    </ButtonAtom>

                    <div style={{ paddingTop: '80px' }} />
                </div>
            </>
        )
    }

    const videoEmbedFormMobile = () => {
        return (
            <>
                <div
                    style={{
                        backgroundColor: 'var(--bg-color, #fff)',
                        width: '100%',

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ paddingTop: '40px' }} />
                    <TitleTxt>Video section - optional</TitleTxt>

                    <div style={{ paddingTop: '20px' }} />

                    <SubtitleTxt>
                        Paste a YouTube or Vimeo link below and you’re good to
                        go!
                    </SubtitleTxt>

                    <div style={{ paddingTop: '32px' }} />

                    <div style={{ position: 'relative' }}>
                        <VideoInput
                            value={videoEmbed}
                            onChange={(e: any) => {
                                setVideoEmbed(e.target.value)
                            }}
                            placeholder="Paste link here..."
                            style={
                                currentEntry && currentEntry.video_url
                                    ? { borderColor: 'var(--primary, #5EC3CA)' }
                                    : {}
                            }
                        />
                        <LeftIcon
                            animate={
                                currentEntry && currentEntry.video_url
                                    ? true
                                    : false
                            }
                        >
                            {currentEntry && currentEntry.video_url ? (
                                <SvgIcons.CheckIcon />
                            ) : (
                                <SvgIcons.LinkIcon />
                            )}
                        </LeftIcon>

                        <div
                            style={{
                                position: 'absolute',
                                right: '30px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                display:
                                    currentEntry && currentEntry.video_url
                                        ? 'flex'
                                        : 'none',
                            }}
                        >
                            <CloseOutlined
                                style={{
                                    color: 'var(--text-darker, #616161)',
                                }}
                                role="button"
                                onClick={() => {
                                    setVideoEmbed('')
                                    updateShowroomVideoURL('')
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ paddingTop: '32px' }} />

                    <ButtonAtom
                        theme={'flexible-transparent-blue-border'}
                        fontSize={16}
                        onClick={() => {
                            updateShowroomVideoURL(videoEmbed)
                        }}
                        disabled={!videoEmbed}
                    >
                        <div
                            style={{
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            {currentEntry && currentEntry.video_url
                                ? 'Applied'
                                : 'Apply'}
                        </div>
                    </ButtonAtom>

                    <div style={{ paddingTop: '50px' }} />
                </div>
            </>
        )
    }

    let keyMomentsFacts = useMemo((): string[] => {
        if (carProfile && carProfile.highlighted_facts && keyMomentsFactsData) {
            let objList = carProfile.highlighted_facts.map(
                (id: string, index) => {
                    return keyMomentsFactsData[id]
                }
            )
            let ordered_list = sortList(objList)

            let list: string[] = ordered_list.map((obj) => {
                return obj && obj.text
            })
            return list
        } else return []
    }, [keyMomentsFactsData, carProfile])

    let carGalleryImages: IGalleryImage[] =
        currentEntry && currentEntry.gallery_images
            ? currentEntry.gallery_images
            : []

    const [query, setQuery] = useState<string | null>('start')
    const history = useHistory()
    const location = useLocation()

    let params = new URLSearchParams(location.search)
    let currentParams = params.get('form')

    useEffect(() => {
        let params2 = new URLSearchParams()
        if (query === null && currentParams !== null) {
            params2.delete('form')
            history.push({ search: params2.toString() })
        } else if (query && query !== 'start' && currentParams === null) {
            params2.append('form', query)
            history.push({ search: params2.toString() })
        }
    }, [query, history])

    useEffect(() => {
        if (currentEntry?.state === 'DRAFT') {
            dispatch(
                showroomActions.validateEntryForPublicationRequest(entryid)
            )
        }
    }, [keyMomentsFactsData])

    const { theme } = useThemes()

    return (
        <>
            {/* car edits related forms  */}
            {carProfile && (
                <>
                    <DesktopDisplayOnly>
                        <CarOverviewFormsBodyDesktop
                            localFormOpened={activeForm}
                            current={carProfile}
                            setActiveLocalForm={setActiveForm}
                            entryid={entryid}
                            showroom_entry_undisclosed_mileage={
                                currentEntry?.undisclosed_mileage
                            }
                        />
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        {carProfile && (
                            <CarOverviewFormsBodyMobile current={carProfile} />
                        )}
                    </IpadAndMobileDisplay>
                </>
            )}

            {/* showroom status related forms  */}
            {modalOpened && carProfile && (
                <>
                    <ModalDisplayFullPageDesktopExclusive
                        isOpen={modalOpened !== null ? true : false}
                        toggle={() => {
                            // setModalOpened(null)
                        }}
                    >
                        <ShowroomModalEditManagerDesktop
                            activeModal={modalOpened}
                            handleSetModalOpened={handleSetModalOpened}
                            car={carProfile}
                            entryid={entryid}
                        />
                    </ModalDisplayFullPageDesktopExclusive>

                    <IpadAndMobileDisplay>
                        <ShowroomModalEditManagerMobile
                            activeModal={modalOpened}
                            handleSetModalOpened={handleSetModalOpened}
                            car={carProfile}
                            entryid={entryid}
                        />
                    </IpadAndMobileDisplay>
                </>
            )}

            {activeForm === 'contact' && (
                <>
                    <IpadAndMobileDisplay>
                        <OwnerContactFormMobile
                            close={() => setActiveForm(null)}
                        />
                    </IpadAndMobileDisplay>
                </>
            )}

            {(activeForm === 'tech_info' || currentParams !== null) &&
                currentEntry?.car && (
                    <IpadAndMobileDisplay>
                        <ShowroomTechInfoFormMobile
                            close={() => {
                                setActiveForm(null)
                                setQuery(null)
                            }}
                            car={currentEntry.car}
                            entryid={entryid}
                            showroom_entry_undisclosed_mileage={
                                currentEntry?.undisclosed_mileage
                            }
                        />
                    </IpadAndMobileDisplay>
                )}

            {activeForm === 'showroom_car_location' && carProfile && (
                <>
                    <IpadAndMobileDisplay>
                        <CarLocationFormDataManagerMobile
                            close={() => setActiveForm(null)}
                            current={carProfile}
                            entryid={entryid}
                            isMobile={true}
                        />
                    </IpadAndMobileDisplay>
                </>
            )}

            <DesktopDisplayOnly>
                <div style={{ paddingTop: '5em' }} />
                <CarShowroomTopBar
                    carID={currentEntry?.car?.id}
                    onClickEnquire={() => {
                        props.history.push(`/showroom/${entryid}/enquiry`)
                    }}
                    entryID={entryid}
                    isReadOnly={false}
                    is_watched={currentEntry?.is_watched ? true : false}
                    isMobile={false}
                    goBack={() => {
                        if (currentEntry?.car?.id) {
                            props.history.push(`/car/${currentEntry?.car?.id}`)
                        } else props.history.goBack()
                    }}
                />

                {car_profile_image ? (
                    <ShowroomCarMainImageDesktop
                        image={car_profile_image}
                        isLoading={false}
                    />
                ) : (
                    <MainImageContainer>
                        <AddBoxWithSvgPlaceholderDesktop
                            height="100%"
                            hooverText="Add your car's profile image"
                            onClickAdd={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(
                                        `/car/${carProfile.id}/gallery`
                                    )
                                }
                            }}
                        />
                    </MainImageContainer>
                )}

                <div style={{ paddingTop: '100px' }} />

                <SectionWrapper>
                    <SectionWrapperWidth>
                        <OverviewSectionDesktop
                            isLoading={false}
                            entry={currentEntry}
                            isAdmin={true}
                            openLocationForm={() => {
                                setActiveForm('showroom_car_location')
                            }}
                            openForm={() => {
                                setActiveForm('tech_info')
                            }}
                            customOpenGeneralInfoFunc={() => {
                                handleSetModalOpened('edit_basic_info')
                            }}
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>

                <div style={{ paddingTop: '150px' }} />

                <SectionWrapper>
                    <SectionWrapperWidth>
                        <BannerImageOwnerWrapper>
                            {currentEntry &&
                            currentEntry.banner_image &&
                            currentEntry.banner_image.url ? (
                                <BannerImageOwner
                                    src={currentEntry.banner_image.url}
                                />
                            ) : (
                                <AddBoxWithSvgPlaceholderDesktop
                                    height="100%"
                                    hooverText="Add your car's banner image"
                                    hasSvgBackgroundImage
                                    onClickAdd={() => {
                                        if (carProfile && carProfile.id) {
                                            props.history.push(
                                                `/car/${carProfile.id}`
                                            )
                                            dispatch(
                                                editFormsActions.toggleCarBannerImageForm()
                                            )
                                        }
                                    }}
                                />
                            )}
                        </BannerImageOwnerWrapper>
                        <OwnerSectionDesktop
                            isAdmin={true}
                            entryid={entryid}
                            carOwner={userLoggedIn ?? undefined}
                            setActiveForm={() => setActiveForm('contact')}
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>

                <div style={{ paddingTop: '100px' }} />

                <SectionWrapper>
                    <SectionWrapperWidth>
                        <KeyMomentsSectionDesktop
                            keyMomentsList={keyMomentsFacts}
                            isAdmin={true}
                            setActiveForm={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarHighlightsEditForm()
                                    )
                                }
                            }}
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>

                <div style={{ paddingTop: '150px' }} />

                <SectionWrapper>
                    <SectionWrapperWidth>
                        <DescriptionSectionDesktop
                            isLoading={false}
                            currentEntry={currentEntry}
                            isAdmin={true}
                            setActiveForm={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarDescriptionEditForm()
                                    )
                                }
                            }}
                            editDescriptionImage={() => {
                                if (
                                    carProfile &&
                                    carProfile.gallery &&
                                    carProfile.gallery.images &&
                                    carProfile.gallery.images.length > 0
                                ) {
                                    props.history.push(
                                        `/showroom/${entryid}/edit/description-image`
                                    )
                                } else if (carProfile) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarGalleryEditForm()
                                    )
                                }
                            }}
                            onShowMoreClick={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarDescriptionEditForm()
                                    )
                                }
                            }}
                            carHasNoImages={
                                carProfile &&
                                carProfile.gallery &&
                                carProfile.gallery.images &&
                                carProfile.gallery.images.length > 0
                                    ? false
                                    : true
                            }
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>
                <div style={{ paddingTop: '160px' }} />
                <DesktopGreyWrapper>
                    <div
                        style={{
                            paddingTop: '40px',
                            paddingBottom: '40px',
                            alignSelf: 'flex-end',
                        }}
                    >
                        <ButtonAtom
                            theme={'flexible-transparent-blue-border'}
                            fontSize={16}
                            onClick={() => {
                                props.history.push(
                                    `/showroom/${entryid}/edit/gallery`
                                )
                            }}
                            disabled={
                                carProfile &&
                                carProfile.gallery &&
                                carProfile.gallery.images &&
                                carProfile.gallery.images.length < 6
                                    ? true
                                    : false
                            }
                        >
                            <div
                                style={{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Manage Gallery
                            </div>
                        </ButtonAtom>
                    </div>
                    <ShowroomOverviewGalleryDesktop
                        overview_images={carGalleryImages}
                        readOnlyMode={false}
                        hasAddBox={
                            carProfile &&
                            carProfile.gallery &&
                            carProfile.gallery.images &&
                            carProfile.gallery.images.length < 6
                                ? true
                                : false
                        }
                        openImgForm={() => {
                            history.push(`/car/${carProfile?.id}`)
                            dispatch(
                                editFormsActions.toggleCarGalleryEditForm()
                            )
                        }}
                    />

                    {videoEmbedFormDesktop()}

                    <div style={{ paddingTop: '80px' }} />
                </DesktopGreyWrapper>
                <div style={{ paddingTop: '110px' }} />

                <SectionWrapper>
                    <SectionWrapperWidth>
                        <OwnerSectionDesktop
                            carOwner={userLoggedIn ?? undefined}
                            theme="border"
                            isAdmin={true}
                            entryid={entryid}
                            setActiveForm={() => {
                                setActiveForm('contact')
                            }}
                        />
                    </SectionWrapperWidth>
                </SectionWrapper>

                <div style={{ paddingTop: '300px' }} />
            </DesktopDisplayOnly>

            {/* on mobile, the contact form isnt on a modal but on its own full page layout */}

            {!activeForm && currentParams === null ? (
                <IpadAndMobileDisplay>
                    <CarShowroomTopBar
                        carID={currentEntry?.car?.id}
                        entryID={entryid}
                        is_watched={false}
                        isMobile
                        onClickEnquire={() => {
                            props.history.push(`/showroom/${entryid}/enquiry`)
                        }}
                        isReadOnly={false}
                        isGoBackMode={true}
                        goBack={() => {
                            if (currentEntry?.car?.id) {
                                props.history.push(
                                    `/car/${currentEntry?.car?.id}`
                                )
                            } else props.history.goBack()
                        }}
                    />

                    <div style={{ paddingTop: '64px' }} />

                    {car_profile_image ? (
                        <FullWidthMobile img={car_profile_image} />
                    ) : (
                        <AddBoxWithSvgPlaceholderMobile
                            onClickAdd={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(
                                        `/car/${carProfile.id}/gallery`
                                    )
                                }
                            }}
                            height="200px"
                            btnText="Add your car's profile image"
                        />
                    )}
                    <div style={{ paddingTop: '24px' }} />
                    <OverviewSectionMobile
                        isLoading={false}
                        entry={currentEntry}
                        customOpenGeneralInfoFunc={() => {
                            props.history.push(
                                `/showroom/${entryid}/edit/general`
                            )
                        }}
                        isAdmin={true}
                    />
                    <div style={{ paddingTop: '48px' }} />
                    <MobileSectionWrapper>
                        <FactsGridSectionMobile
                            isAdmin={true}
                            openLocationForm={() => {
                                setActiveForm('showroom_car_location')
                            }}
                            openForm={() => {
                                setActiveForm('tech_info')
                                setQuery('tech_info')
                            }}
                            entry={currentEntry}
                        />
                    </MobileSectionWrapper>

                    <div style={{ paddingTop: '64px' }} />

                    <OwnerSectionMobileWithCarPic
                        carOwner={userLoggedIn ?? undefined}
                        car={carProfile ?? undefined}
                        isAdmin={true}
                        setActiveForm={() => {
                            setActiveForm('contact')
                        }}
                        entryid={entryid}
                        bannerImage={
                            currentEntry && currentEntry.banner_image
                                ? currentEntry.banner_image
                                : undefined
                        }
                        onBannerAddClick={() => {
                            if (carProfile && carProfile.id) {
                                props.history.push(`/car/${carProfile.id}`)
                                dispatch(
                                    editFormsActions.toggleCarBannerImageForm()
                                )
                            }
                        }}
                    />

                    <div style={{ paddingTop: '64px' }} />
                    <MobileSectionWrapper>
                        <KeyMomentsSectionMobile
                            keyMomentsList={keyMomentsFacts}
                            setActiveForm={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarHighlightsEditForm()
                                    )
                                }
                            }}
                            isAdmin={true}
                        />
                    </MobileSectionWrapper>

                    <div style={{ paddingTop: '52px' }} />

                    <MobileSectionWrapper>
                        <DescriptionSectionMobile
                            isLoading={false}
                            currentEntry={currentEntry}
                            setActiveForm={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarDescriptionEditForm()
                                    )
                                }
                            }}
                            editDescriptionImage={() => {
                                if (
                                    carProfile &&
                                    carProfile.gallery &&
                                    carProfile.gallery.images &&
                                    carProfile.gallery.images.length > 0
                                ) {
                                    props.history.push(
                                        `/showroom/${entryid}/edit/description-image`
                                    )
                                } else if (carProfile) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarGalleryEditForm()
                                    )
                                }
                            }}
                            onShowMoreClick={() => {
                                if (carProfile && carProfile.id) {
                                    props.history.push(`/car/${carProfile.id}`)
                                    dispatch(
                                        editFormsActions.toggleCarDescriptionEditForm()
                                    )
                                }
                            }}
                            carHasNoImages={
                                carProfile &&
                                carProfile.gallery &&
                                carProfile.gallery.images &&
                                carProfile.gallery.images.length > 0
                                    ? false
                                    : true
                            }
                        />
                    </MobileSectionWrapper>

                    <div style={{ paddingTop: '52px' }} />

                    <MobileGreyWrapper>
                        <div style={{ paddingTop: '24px' }} />

                        <div
                            style={{
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 500,
                                    alignSelf: 'flex-end',
                                    justifySelf: 'flex-end',
                                }}
                            >
                                <ButtonAtom
                                    width="127px"
                                    theme="naked-text"
                                    fontSize={14}
                                    onClick={() => {
                                        props.history.push(
                                            `/showroom/${entryid}/edit/gallery`
                                        )
                                    }}
                                    disabled={
                                        carProfile &&
                                        carProfile.gallery &&
                                        carProfile.gallery.images &&
                                        carProfile.gallery.images.length < 6
                                            ? true
                                            : false
                                    }
                                >
                                    Manage Gallery
                                </ButtonAtom>
                            </div>

                            <div style={{ paddingTop: '24px' }} />

                            <GalleryWrapperMobile>
                                {carGalleryImages.map(
                                    (image: IGalleryImage, index: number) => {
                                        return (
                                            <Link
                                                to={`/showroom/${entryid}/edit/gallery`}
                                            >
                                                <DuoGridItemMobile
                                                    customHeight="116px"
                                                    img={image}
                                                    // customIpadHeight="150px"
                                                    //  placeholder={placeholder}
                                                />
                                            </Link>
                                        )
                                    }
                                )}
                            </GalleryWrapperMobile>
                        </div>
                        {carProfile &&
                            carProfile.gallery &&
                            carProfile.gallery.images &&
                            carProfile.gallery.images.length < 6 && (
                                // IF USER OWN CAR DISPLAY ADD BTN
                                <ButtonAtom
                                    theme={'lowercase-blue-background'}
                                    fontSize={14}
                                    onClick={() => {
                                        history.push(`/car/${carProfile?.id}`)
                                        dispatch(
                                            editFormsActions.toggleCarGalleryEditForm()
                                        )
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Add at least 6 images to your car
                                    </div>
                                </ButtonAtom>
                            )}

                        {(!currentEntry?.gallery_images ||
                            currentEntry.gallery_images.length === 0) &&
                            carProfile &&
                            carProfile.gallery &&
                            carProfile.gallery.images &&
                            carProfile.gallery.images.length >= 6 && (
                                // IF USER OWN CAR DISPLAY ADD BTN
                                <ButtonAtom
                                    theme={'lowercase-blue-background'}
                                    fontSize={14}
                                    onClick={() => {
                                        history.push(
                                            `/showroom/${entryid}/edit/gallery`
                                        )
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: '10px',
                                            paddingRight: '10px',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Select images from your car gallery
                                    </div>
                                </ButtonAtom>
                            )}

                        <div style={{ paddingTop: '40px' }} />

                        {videoEmbedFormMobile()}

                        <div style={{ paddingTop: '48px' }} />
                    </MobileGreyWrapper>

                    <div style={{ paddingTop: '64px' }} />

                    <MobileSectionWrapper>
                        <OwnerSectionMobile
                            entryid={entryid}
                            carOwner={userLoggedIn ?? undefined}
                            isAdmin={true}
                            setActiveForm={() => {
                                setActiveForm('contact')
                            }}
                        />
                    </MobileSectionWrapper>

                    <MobileSectionWrapper>
                        <div style={{ paddingTop: '48px' }} />
                    </MobileSectionWrapper>

                    <div style={{ paddingTop: '150px' }} />
                </IpadAndMobileDisplay>
            ) : null}

            <CarShowroomEditBottomBar
                infoTxtDesktop="Page is prefilled with data from your main Car page. If you edit any information it will also be edited in your main Car page."
                infoTxtMobile="Images on this page were filled up with featured gallery photos. Press on any photo to change it."
                showInfo={true}
                isPublished={isPublished}
                handleSetModalOpened={handleSetModalOpened}
                isMobileRemoved={
                    modalOpened !== null ||
                    activeForm !== null ||
                    currentParams !== null
                        ? true
                        : false
                }
                isSold={currentEntry?.state === 'SOLD' ? true : false}
                entryid={entryid}
            />
        </>
    )
}

export default withRouter(ShowroomCarProfileEdit)
