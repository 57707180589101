import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Icon from '../../atoms/icons/index'
import DuoGridItemDesktop from '../../atoms/image/duoGridItemDesktop'
import { Link, useHistory } from 'react-router-dom'
import { ICar } from 'entityModels'
import img_1 from '../../atoms/skeleton/garage/1.png'
import img_2 from '../../atoms/skeleton/garage/2.png'
import img_3 from '../../atoms/skeleton/garage/3.png'
import img_4 from '../../atoms/skeleton/garage/4.png'

import img_1_dark from '../../atoms/skeleton/garage/1_dark.png'
import img_2_dark from '../../atoms/skeleton/garage/2_dark.png'
import img_3_dark from '../../atoms/skeleton/garage/3_dark.png'
import img_4_dark from '../../atoms/skeleton/garage/4_dark.png'

import AddBox from '../../atoms/Button/addBox'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
}

const GridContainer = styled.div<IStyle>`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 5px;
    background-color: ${(props) => colours[props.$theme].background_default};
    width: 100%;
    height: 100%;
`

const Placeholder = styled.div<{ url: string }>`
    background-image: ${(props) => `url(${props.url})`};
    background-color: none;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    width: 100%;
`

const PlaceholderWrapper = styled.div`
    position: relative;
    cursor: pointer;
    @media ${device.desktop} {
        height: 350px;
    }

    @media ${device.large_desktop} {
        height: 28vh;
        min-height: 380px;
    }
    @media ${device.smallest_laptops} {
        height: 22vh;
        min-height: 325px;
    }
`

const GoToBox = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].primary_16};
    position: relative;
    padding-top: 0%;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;

    :hover {
        transition: all 200ms;
        background-color: ${(props) => colours[props.$theme].primary_30};
        cursor: pointer;
    }

    @media ${device.desktop} {
        height: 350px;
    }

    @media ${device.large_desktop} {
        height: 28vh;
        min-height: 380px;
    }
    @media ${device.smallest_laptops} {
        height: 22vh;
        min-height: 325px;
    }

    min-height: 325px;
`

interface Props {
    dataToMap: any[]
    carsdata: any
    onClick?: any
    imagesData: any
    isExternalGarage?: boolean
    isGarage?: boolean
    dontShowAddBox?: boolean
    dataLoading?: boolean
    dataPHAddBox?: string
    reset_make_model_temps?: () => any
}

const getPlaceholderImg = (i: number, theme: ITheme): string => {
    switch (i) {
        case 1:
            return theme === 'dark' ? img_1_dark : img_1

        case 2:
            return theme === 'dark' ? img_2_dark : img_2

        case 3:
            return theme === 'dark' ? img_3_dark : img_3
        case 4:
            return theme === 'dark' ? img_4_dark : img_4
        default:
            return ''
    }
}

export default function DuoGrid(props: Props) {
    const [showAddBox, setShowAddBox] = React.useState<number>(0)
    const history = useHistory()

    const car_cover_url = (carObj: ICar) => {
        let imageid =
            carObj &&
            carObj.gallery &&
            carObj.gallery.cover &&
            carObj.gallery.cover

        if (imageid) {
            if (props.imagesData[imageid]) {
                return props.imagesData[imageid].url
            }
        } else return undefined
    }

    const {
        onClick,
        isExternalGarage,
        dontShowAddBox,
        dataLoading,
        dataPHAddBox,
    } = props

    const { theme } = useThemes()

    let currentPath = history.location.pathname
    let isGarageEmpty =
        !props.dataToMap || (props.dataToMap && props.dataToMap.length === 0)
            ? true
            : false

    return (
        <GridContainer $theme={theme}>
            {props.dataToMap.length > 0 &&
                props.dataToMap.map((id, index) => {
                    let obj = props.carsdata[id]
                    let hasShowroomEntry: string | undefined =
                        obj &&
                        obj.showroom_entry &&
                        obj.showroom_entry?.state === 'PUBLISHED'
                            ? 'for sale'
                            : obj &&
                              obj.showroom_entry &&
                              obj.showroom_entry?.state === 'SOLD'
                            ? 'sold'
                            : undefined
                    return obj ? (
                        <Link
                            to={{
                                pathname: isExternalGarage
                                    ? `/shared/car/${obj.id}`
                                    : `/car/${obj.id}`,
                                state: {
                                    prevPath: currentPath,
                                    search: history.location.search,
                                },
                            }}
                            onClick={() => {
                                if (props.reset_make_model_temps) {
                                    props.reset_make_model_temps()
                                }
                            }}
                            key={`duo_grid_${id}_${index}_desktop`}
                        >
                            <DuoGridItemDesktop
                                object={{
                                    ...obj,
                                    url: car_cover_url(obj),
                                }}
                                tag={hasShowroomEntry}
                            />
                        </Link>
                    ) : null
                })}

            {!isExternalGarage &&
                (isGarageEmpty && !dataLoading
                    ? [1, 2, 3, 4].map((item, i) => (
                          <PlaceholderWrapper
                              key={`duo_grid_placeholder_${i}_desktop`}
                              onMouseEnter={() => {
                                  setShowAddBox(item)
                              }}
                              onMouseLeave={() => {
                                  setShowAddBox(0)
                              }}
                              onClick={() => {
                                  onClick && onClick()
                              }}
                          >
                              {showAddBox === item && (
                                  <div
                                      style={{
                                          position: 'absolute',
                                          height: '100%',
                                          width: '100%',
                                          zIndex: 1,
                                      }}
                                  >
                                      <AddBox />
                                  </div>
                              )}

                              <Placeholder
                                  url={getPlaceholderImg(item, theme)}
                              />
                          </PlaceholderWrapper>
                      ))
                    : !dontShowAddBox &&
                      !dataLoading && (
                          <GoToBox
                              $theme={theme}
                              onClick={onClick && onClick}
                              data-ph={dataPHAddBox}
                          >
                              <Icon icon="add_plus" height={'20px'} />
                          </GoToBox>
                      ))}
        </GridContainer>
    )
}
