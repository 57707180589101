import {
    IApexInterviewOverview_item_api,
    IAllApex_interviewss_overviews_list_api,
    IApexInterviewOverviewItemState,
    IAllApex_interviewss_overviews_node_wrapper_api,
} from 'apexModels'
import {
    convertImgPrismicWithCompression,
    convertImgPrismicWithCustomCompression,
} from './apexImgPrismicCompression'

export const convertPrismicInterviewsOverviewFromAPI_single = (
    apiData: IAllApex_interviewss_overviews_list_api
): IApexInterviewOverviewItemState => {
    let item: IApexInterviewOverviewItemState = {
        name: '',
        title: '',
        date: '',
        uid: '',
        blurb: '',
        headshot: undefined,
        quote: undefined,
        introduction: undefined,
    }

    if (apiData.data) {
        if (
            apiData.data.allApex_interviewss &&
            apiData.data.allApex_interviewss.edges &&
            apiData.data.allApex_interviewss.edges[0]
        ) {
            let node: IApexInterviewOverview_item_api =
                apiData.data.allApex_interviewss.edges[0].node

            item = {
                name: node.name ? node.name : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                date: node.date ? node.date : '',
                uid: node.uid
                    ? node.uid
                    : node._meta
                    ? node._meta.uid
                        ? node._meta.uid
                        : ''
                    : '',
                blurb: node.blurb ? node.blurb : '',
                headshot:
                    node.headshot &&
                    convertImgPrismicWithCompression(node.headshot),
                quote: node.quote,
                introduction:
                    node.introduction &&
                    node.introduction[0] &&
                    node.introduction[0] &&
                    node.introduction[0].text
                        ? node.introduction[0].text
                        : '',
            }
        }
    }

    return item
}

export const convertPrismicInterviewsOverviewFromAPI_array = (
    apiData: IAllApex_interviewss_overviews_list_api
): IApexInterviewOverviewItemState[] => {
    let items_array: IApexInterviewOverviewItemState[] = []

    if (
        apiData.data &&
        apiData.data.allApex_interviewss &&
        apiData.data.allApex_interviewss.edges
    ) {
        let nodes: IAllApex_interviewss_overviews_node_wrapper_api[] =
            apiData.data.allApex_interviewss.edges

        for (const nodeWrapper of nodes) {
            // remember type: nodeWrapper.node: IApexInterviewOverview_item_api[]

            let node = nodeWrapper.node
            let item = {
                name: node.name ? node.name : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                date: '',
                uid: node.uid
                    ? node.uid
                    : node._meta
                    ? node._meta.uid
                        ? node._meta.uid
                        : ''
                    : '',
                blurb: node.blurb ? node.blurb : '',
                headshot:
                    node.headshot &&
                    convertImgPrismicWithCustomCompression(
                        node.headshot,
                        '700'
                    ),
                quote: node.quote,
                introduction:
                    node.introduction &&
                    node.introduction[0] &&
                    node.introduction[0] &&
                    node.introduction[0].text
                        ? node.introduction[0].text
                        : '',
            }

            items_array.push(item)
        }
    }

    return items_array
}
