import styled from 'styled-components'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'

const Wrapper = styled.div<{ $theme: ITheme }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    height: 100%;
    width: 100%;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
    border-radius: 8px;
    height: 40px;
`

const Text = styled.p<{ $theme: ITheme }>`
    font-family: 'Lato';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 5e-5em;
    color: ${(props) => colours[props.$theme].text_default};
`
const NoMatchFoundBox = () => {
    const { theme } = useThemes()
    return (
        <Wrapper $theme={theme}>
            <Text $theme={theme}>No match found</Text>
        </Wrapper>
    )
}

export default NoMatchFoundBox
