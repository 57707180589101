import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export type IaddShareRecipientApi_arg = {
    share_id: string
    recipient_userid: string
}

export const addShareRecipientApi = (
    p: IaddShareRecipientApi_arg
): Promise<any> => {
    let payload = {
        recipients: [p.recipient_userid],
    }

    let jsonBodyConv: string = JSON.stringify(payload)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }

    return fetch(urls.add_share_recipient(p.share_id), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Add share recipient error',
                    jsonBodyConv
                )
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
