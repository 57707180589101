import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import {
    ICar,
    IExternalCar,
    IGalleryImage,
    IGalleryImagesObject,
    IUpdateCarGalleryImagePayload,
} from 'entityModels'
import './styles.css'
import ActionsMenuDots, {
    IThreeDotsActionsItems,
} from '../../atoms/menu/actionsMenu/actionsMenuDots'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { deleteCarGalleryImageRequest } from '../../../redux/entities/galleries/actions/loadingActions'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import GalleryImChevron from '../../atoms/icons/GalleryImgChevron'
import GallerySlideZoomIcon from '../../atoms/icons/GallerySlideZoomIcon'
import { LocationIcon } from '../../atoms/icons/components'

const SlideWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-strong, #1a1a1a);
    border-radius: 2px;
    height: 100%;
    max-height: 80vh;

    @media (max-width: 1180px) {
        margin-top: 160px;
        width: 68vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1180px) and (max-width: 1295px) {
        width: 64vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1295px) and (max-width: 1400px) {
        width: 55vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1400px) and (max-width: 1550px) {
        width: 54vw;
        max-width: 1100px;
        height: 75vh;
        max-height: 550px;
    }

    @media (min-width: 1550px) and (max-width: 1600px) {
        width: 1200px;
        height: 905px;
    }

    @media (min-width: 1600px) and (max-width: 1810px) {
        width: 1300px;
        height: 1000px;
    }

    @media (min-width: 1810px) {
        width: 1492px;
        height: 1100px;
        max-height: 70vh;
    }
`

const SlideImageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    background: var(--bg-color, #fff);
    position: relative;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const SlideImage = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const TextBox = styled.div`
    z-index: 6;
    width: 100%;
    height: max-content;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-family: Lato;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: max-content;
    padding-right: 30px;
    text-align: left !important;
    color: var(--text-strong, #1a1a1a);
    font-size: 13px;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const CaptionCol = styled.div`
    font-family: Lato;
    padding-right: 10px;
    min-width: 230px;
    padding-left: 20px;

    font-size: 12px;

    @media (max-width: 1450px) {
        font-size: 11px;
    }

    @media ${device.large_desktop} {
        font-size: 14px;
    }
`

const Line = styled.div`
    height: 50px;
    justify-self: center !important;
    width: 1px;
    background-color: var(--text-muted);
`

const NextBlock = styled(Link)`
    position: absolute;
    right: -130px;

    @media (max-width: 1450px) {
        right: -100px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        right: -100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    z-index: 1;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`
const NextBlockNotLink = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 10vw;
    cursor: alias;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 50px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    opacity: 0.5;
`
const AbsoluteIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`
const PrevBlock = styled(Link)`
    position: absolute;
    left: -120px;

    @media (max-width: 1450px) {
        left: -85px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        left: -94px;
    }
    height: 50px;
    width: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`
const PrevBlockNotLink = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 10vw;
    cursor: alias;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 50px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;
    opacity: 0.5;
`
const PaddingSpan = styled.span`
    padding-left: 4px;
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
`

const SpanNoPadding = styled.span`
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
`

const FeaturedTag = styled.div`
    padding-left: 10px;
    padding-right: 10px;

    text-transform: uppercase;
    height: 24px;
    background-color: var(--primary, #5ec3ca);
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* font-weight: 600; */

    font-family: Lato-Semibold;

    font-size: 12px;
    letter-spacing: 0.07em;
    color: var(--bg-color, #fff);
`

const Uploader = styled.div`
    padding-bottom: 3px;
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    font-family: Lato-semibold;
`

function mapStateToProps(state: RootState) {
    return {}
}

type Iids = {
    carid: string
    imageid: string
}
const dispatchProps = {
    deleteCarGalleryImageRequest: (ids: Iids) =>
        deleteCarGalleryImageRequest(ids),
}

interface IProps extends RouteComponentProps<any> {
    // togglePublicMobileMenu: any;
    currentImageData: IGalleryImage
    closeGallery: any
    generateRequestedSlide?: any
    carid: string
    imagesData: IGalleryImagesObject
    currentCar: ICar | IExternalCar | undefined
    onClickEdit: any
    updateImage: any
    isSomethingLoading: boolean
    deleteCarGalleryImageRequest: any
    prevUrl: string
    nextUrl: string
    readOnlyMode?: boolean
    featuredImagesCount: number
    isImgInShowroom?: boolean
}

type IState = {
    overallMenuOpenId: string | null
    isLoading: boolean
    imgLoaded?: string
    isZoomed?: boolean
}

class GallerySlide extends React.Component<IProps, IState> {
    state = {
        overallMenuOpenId: null,
        isLoading: true,
        imgLoaded: undefined,
        isZoomed: false,
    }

    _isMounted = false

    genUrl = (e?: any) => {
        if (e) {
            e = e || window.event
            if (e.keyCode === 37 && this.props.prevUrl !== '/none') {
                this.props.history.replace(this.props.prevUrl)
            } else if (e.keyCode === 39 && this.props.nextUrl !== '/none') {
                this.props.history.replace(this.props.nextUrl)
            }
        }
    }

    handleZoomChange = (shouldZoom: boolean) => {
        this.setState({
            ...this.state,
            isZoomed: shouldZoom,
        })
    }

    componentDidMount() {
        this._isMounted = true

        document.addEventListener('keydown', this.genUrl, { passive: true })

        // const { currentImageData } = this.props
        // let src =
        //     currentImageData && currentImageData.large_src
        //         ? currentImageData.large_src
        //         : grey_placeholder

        // this.onLoad(src)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.genUrl)
        this._isMounted = false
    }

    // onLoad = (src: string) => {
    //     let image = document.createElement('img')
    //     image.src = src

    //     image.onload = () => {
    //         setTimeout(() => {
    //             this.setState({
    //                 ...this.state,
    //                 imgLoaded: image.src,
    //                 isLoading: false,
    //             })
    //         }, 100)
    //     }
    // }

    manageOverallMenus = (id?: string) => {
        if (id === this.state.overallMenuOpenId) {
            return this.setState({ overallMenuOpenId: null })
        } else return this.setState({ overallMenuOpenId: id ? id : null })
    }

    render() {
        const {
            currentImageData,
            nextUrl,
            prevUrl,
            readOnlyMode,
            featuredImagesCount,
        } = this.props

        const handleOptionClickedOn = (optionClicked: string) => {
            let carid = this.props.carid

            if (
                optionClicked === 'Set as car profile image' &&
                this.props.currentImageData
            ) {
                let payload: IUpdateCarGalleryImagePayload = {
                    carid: carid,
                    imageid: this.props.currentImageData.id,
                    body: {
                        cover: true,
                    },
                }
                this.props.updateImage(payload)

                let history = this.props.history

                setTimeout(function () {
                    history.push(`/car/${carid}`)
                }, 200)
            } else if (
                optionClicked === 'Set as gallery feature' &&
                this.props.currentImageData
            ) {
                let payload: IUpdateCarGalleryImagePayload = {
                    carid: this.props.carid,
                    imageid: this.props.currentImageData.id,
                    body: {
                        featured: true,
                    },
                }
                this.props.updateImage(payload)

                // let history = this.props.history

                // setTimeout(function () {
                //     history.push(`/car/${carid}`)
                // }, 200)
            } else if (
                optionClicked === 'Remove feature' &&
                this.props.currentImageData
            ) {
                let payload: IUpdateCarGalleryImagePayload = {
                    carid: this.props.carid,
                    imageid: this.props.currentImageData.id,
                    body: {
                        featured: false,
                    },
                }
                this.props.updateImage(payload)

                // let history = this.props.history

                // setTimeout(function () {
                //     history.push(`/car/${carid}`)
                // }, 200)
            }
            return
        }

        type Iids = {
            carid: string
            imageid: string
        }

        const handleDeletion = () => {
            let ids: Iids = {
                carid: this.props.carid,
                imageid: this.props.currentImageData.id,
            }

            currentImageData && this.props.deleteCarGalleryImageRequest(ids)
            this.props.closeGallery()

            return
        }

        let actions_items = (): IThreeDotsActionsItems[] => {
            if (currentImageData) {
                if (currentImageData.featured === true) {
                    return [
                        {
                            text: 'Update image details',
                            actionOnClick: this.props.onClickEdit,
                            idRequired: false,
                        },
                        {
                            text: 'Set as car profile image',
                            actionOnClick: handleOptionClickedOn,
                            idRequired: true,
                        },
                        {
                            text: 'Remove feature',
                            actionOnClick: handleOptionClickedOn,
                            idRequired: true,
                            color: '#DF6F6F',
                        },
                        {
                            text: 'Delete',
                            actionOnClick: handleDeletion,
                            color: '#DF6F6F',
                            idRequired: true,

                            isDisabled: this.props.isImgInShowroom,
                            disabledTxt:
                                'Image is part of a published showroom entry.',
                        },
                    ]
                } else {
                    return [
                        {
                            text: 'Update image details',
                            actionOnClick: this.props.onClickEdit,
                            idRequired: false,
                        },
                        {
                            text: 'Set as car profile image',
                            actionOnClick: handleOptionClickedOn,
                            idRequired: true,
                        },
                        {
                            text: 'Set as gallery feature',
                            actionOnClick: handleOptionClickedOn,
                            idRequired: true,
                            isDisabled: featuredImagesCount >= 5 ? true : false,
                            disabledTxt:
                                'Five images already set as gallery features. Please remove at least one before setting new ones.',
                        },
                        {
                            text: 'Delete',
                            actionOnClick: handleDeletion,
                            color: '#DF6F6F',
                            idRequired: true,
                            isDisabled: this.props.isImgInShowroom,
                            disabledTxt:
                                'Image is part of a published showroom entry.',
                        },
                    ]
                }
            } else return []
        }

        const { overallMenuOpenId, imgLoaded, isLoading } = this.state

        return (
            <div style={{ position: 'fixed' }}>
                <SlideWrapper>
                    {/* this.manageOverallMenus(); */}

                    {this.props.nextUrl !== '/none' ? (
                        <NextBlock to={nextUrl}>
                            <GalleryImChevron />
                        </NextBlock>
                    ) : (
                        <NextBlockNotLink>
                            <GalleryImChevron />
                        </NextBlockNotLink>
                    )}

                    {this.props.prevUrl !== '/none' ? (
                        <PrevBlock to={prevUrl}>
                            <GalleryImChevron direction="left" />
                        </PrevBlock>
                    ) : (
                        <PrevBlockNotLink>
                            <GalleryImChevron direction="left" />
                        </PrevBlockNotLink>
                    )}

                    <SlideImageBox>
                        <ControlledZoom
                            isZoomed={this.state.isZoomed}
                            onZoomChange={this.handleZoomChange}
                            wrapStyle={{
                                outline: 'none',
                                borderRadius: '0px',
                                width: '100%',
                                height: ' 100%',
                            }}
                        >
                            <AbsoluteIcon
                                style={{
                                    display: this.state.isZoomed
                                        ? 'none'
                                        : 'block',
                                }}
                            >
                                <GallerySlideZoomIcon />
                            </AbsoluteIcon>
                            <div
                                style={{
                                    width: '100%',
                                    height: ' 100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {isLoading === true && (
                                    <SkeletonAbsolute
                                        isthingloading={isLoading}
                                        darkTheme={false}
                                    />
                                )}
                                <SlideImage
                                    src={
                                        imgLoaded ? imgLoaded : grey_placeholder
                                    }
                                    srcSet={
                                        currentImageData &&
                                        currentImageData.large_srcset
                                            ? currentImageData.large_srcset
                                            : undefined
                                    }
                                    onLoad={() =>
                                        this._isMounted &&
                                        this.setState({
                                            ...this.state,
                                            isLoading: false,
                                        })
                                    }
                                    alt="car"
                                />
                            </div>
                        </ControlledZoom>
                    </SlideImageBox>
                    <TextBox>
                        <Row>
                            <Row>
                                <Col>
                                    <Uploader>
                                        {currentImageData &&
                                        currentImageData.uploader
                                            ? currentImageData.uploader
                                            : ''}
                                    </Uploader>

                                    <Row>
                                        ©
                                        <PaddingSpan>
                                            {currentImageData &&
                                            currentImageData.credits
                                                ? currentImageData.credits
                                                : 'Unknown'}
                                        </PaddingSpan>
                                    </Row>

                                    <Row>
                                        <div
                                            style={{
                                                transform:
                                                    'translate(-1px, 1px)',
                                            }}
                                        >
                                            <LocationIcon size="14" />
                                        </div>
                                        <SpanNoPadding>
                                            {currentImageData &&
                                            currentImageData.location
                                                ? currentImageData.location
                                                : 'Unknown'}
                                        </SpanNoPadding>
                                    </Row>
                                </Col>
                            </Row>
                            <Line />
                            <CaptionCol style={{ width: '78%' }}>
                                {currentImageData && currentImageData.caption
                                    ? currentImageData.caption
                                    : ''}
                            </CaptionCol>
                        </Row>

                        <Row>
                            {currentImageData.featured ? (
                                <FeaturedTag>featured</FeaturedTag>
                            ) : null}

                            {this.props.currentCar &&
                            this.props.currentCar.gallery?.cover ===
                                currentImageData.id ? (
                                <FeaturedTag>profile image</FeaturedTag>
                            ) : null}

                            {!readOnlyMode && (
                                <div
                                    data-tut="car-gallery-image-tuto-desktop-1"
                                    style={{ paddingLeft: '20px' }}
                                >
                                    <ActionsMenuDots
                                        menuOpened={overallMenuOpenId}
                                        manageMenus={this.manageOverallMenus}
                                        menuId={
                                            currentImageData &&
                                            currentImageData.id
                                                ? currentImageData.id
                                                : ''
                                        }
                                        actions_items={actions_items()}
                                    />
                                </div>
                            )}
                        </Row>
                    </TextBox>
                </SlideWrapper>
            </div>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(GallerySlide))
