import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import {
    CarActionMenuItemsID,
    CarOptionMenuItem,
    ICarActionMenuItemsData,
} from '../../../pages/car/carOverview'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import Icon from '../../icons'
import { useRef, useState } from 'react'
import { useOnClickOutside } from '../../../templates/clickOutside'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { Spin, Tooltip } from 'antd'
import posthog from 'posthog-js'
import React from 'react'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import AddIcon from '../../icons/components/addIcon'
import PenIcon from '../../icons/components/penIcon'
import ThreeDotsIcon from '../../icons/components/threeDots'

type IStyledProps = {
    $theme: ITheme
    isOpen?: boolean
    length?: number
    isActive?: boolean
}

const Menu = styled.div<IStyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props: IStyledProps) =>
        props.isOpen
            ? props.length
                ? `${40 * (props.length + 1) + 78}px`
                : '171px'
            : '82px'};
    border: 4px solid
        ${(props) => colours[props.$theme].modal_side_sheet_pop_over};
    border-radius: 100px;
    transition: all 150ms cubic-bezier(0.735, -0.015, 0, 1.24);
    transition-timing-function: cubic-bezier(0.735, -0.015, 0, 1.24);
    background: ${(props) => colours[props.$theme].primary};
`

const Circle = styled.button<IStyledProps>`
    background: ${(props: IStyledProps) =>
        props.isActive
            ? colours[props.$theme].primary_strong_2
            : colours[props.$theme].primary};
    border-radius: 100px;
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 2px;
    border: none;

    transition: all 60ms;

    cursor: pointer;

    :hover {
        background: ${(props) => colours[props.$theme].primary_strong_2};
    }
`

const MenuCircle = styled.button<IStyledProps>`
    background: ${(props: IStyledProps) =>
        props.isActive
            ? colours[props.$theme].primary_strong_2
            : colours[props.$theme].primary};
    border-radius: 100px;
    width: 82px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 2px;
    border: none;

    transition: all 60ms;

    cursor: pointer;

    :hover {
        background: ${(props) => colours[props.$theme].primary_strong_2};
    }

    color: ${(props) => colours[props.$theme].background_default};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    font-family: 'Lato';
`

const WrapperFaded = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const Line = styled.div<IStyledProps>`
    width: 1px;
    height: 24px;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
`

const AnimWrapper = (props: any) => {
    const duration = 0.3

    const variants = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: duration,
                when: 'beforeChildren',
            },
        },
        exit: {
            opacity: 0,
            transition: { duration: duration },
        },
    }
    return (
        <AnimatePresence>
            <WrapperFaded
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {props.children}
            </WrapperFaded>
        </AnimatePresence>
    )
}

type IstyledProps2 = {
    $theme: ITheme
    direction?: 'top' | 'bottom'
    $isDisabled?: boolean | undefined
}

export const OptionsMenuItem = styled.div<IstyledProps2>`
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    width: 100%;
    color: ${(props) =>
        props.$isDisabled
            ? `${colours[props.$theme].text_disabled} !important;`
            : colours[props.$theme].text_default};
    background-color: transparent;
    border-radius: 4px;

    cursor: ${(props) => (props.$isDisabled ? 'auto' : 'pointer')};

    :hover {
        transition: all 100ms;
        background-color: ${(props) =>
            props.$isDisabled
                ? 'transparent;'
                : colours[props.$theme].primary_20};
        color: ${(props) =>
            props.$isDisabled
                ? `${colours[props.$theme].text_neutral_default} !important;`
                : colours[props.$theme].primary};
    }
    padding-left: 20px;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const MenuBox = styled.div<IstyledProps2>`
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: ${(props) => (props.direction === 'bottom' ? 'auto' : '50px')};

    top: ${(props) => (props.direction === 'bottom' ? '60px' : 'auto')};
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-size: 16px;

    background: ${(props) => colours[props.$theme].modal_side_sheet_pop_over};

    box-shadow: 0px 4px 24px
        ${(props) => colours[props.$theme].shadow_strongest_08};
    border-radius: 4px;

    height: auto;
    width: max-content;

    :hover {
        color: ${(props) => colours[props.$theme].primary};
        transition: all 100ms;
    }
`
const BoxTitle = styled.div<{ $theme: ITheme }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid ${(props) => colours[props.$theme].border_muted};
    height: 48px;
    padding-left: 20px;
    padding-right: 20px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-Semibold;
    /* font-weight: 600; */
    font-size: 12px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
`

const StyledSpin = styled(Spin)<{ $theme: ITheme }>`
    transform: scale(0.7);
    i {
        background-color: ${(props) => colours[props.$theme].primary};
    }
`
type Props = {
    isCarMenuOpened: boolean
    setIsCarMenuOpened: (p: boolean) => any
    carAtionMenuItemsData: ICarActionMenuItemsData
    activeID: CarActionMenuItemsID | undefined
}

type Props2 = {
    item: CarOptionMenuItem
    index: number
}

const OptionItem = (props: Props2) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    let { item } = props

    const { theme } = useThemes()
    return (
        <OptionsMenuItem
            $theme={theme}
            $isDisabled={item.isDisabled}
            onClick={(e: any) => {
                if (!item.isDisabled) {
                    item.action()
                }
            }}
            style={{ cursor: item.isDisabled ? 'not-allowed' : 'pointer' }}
            onMouseEnter={() => {
                if (item.tooltip) {
                    setShowTooltip(true)
                }
            }}
            onMouseLeave={() => {
                if (item.tooltip) {
                    setShowTooltip(false)
                }
            }}
        >
            {item.tooltip && (
                <Tooltip
                    open={showTooltip}
                    title={item.tooltip}
                    color="var(--text-muted)"
                />
            )}

            {item.iconLoading ? (
                <div
                    style={{
                        position: 'absolute',
                        backgroundColor:
                            colours[theme].modal_side_sheet_pop_over,
                        top: 0,
                        bottom: 0,
                        left: 16,
                        width: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 4,
                    }}
                >
                    <StyledSpin $theme={theme} />
                </div>
            ) : null}
            <div
                style={{
                    width: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Icon
                    height={'16'}
                    isDisabled={item.isDisabled}
                    width={'16'}
                    icon={item.icon}
                    color={item.customColor ?? undefined}
                />
            </div>
            <div style={{ paddingLeft: '12px' }} />
            <div
                style={{
                    color: item.customColor
                        ? item.customColor
                        : colours[theme].text_default,
                }}
            >
                {item.name}
            </div>
        </OptionsMenuItem>
    )
}

interface IStyled {
    isAnimated?: boolean
}

const AnimatedWrapper = styled.div<IStyled>`
    @keyframes scale {
        0% {
            transform: scale(0.5);
            opacity: 0.3;
        }

        50% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    ${(props) => props.isAnimated && 'animation: 0.25s scale linear'};
    animation-delay: 0.15s;
`

const CarActionMenu = (props: Props) => {
    let {
        isCarMenuOpened,
        setIsCarMenuOpened,
        carAtionMenuItemsData,
        activeID,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false
    useOnClickOutside(ref, () => !isMobile && setIsCarMenuOpened(false))

    let menuLength: number = carAtionMenuItemsData
        ? Object.values(carAtionMenuItemsData).reduce(
              (acc, item) =>
                  item.options && item.options.length > 0 ? acc + 1 : acc,
              0
          )
        : 3

    const { theme } = useThemes()

    return (
        <div>
            {!isMobile && (
                <AnimatedWrapper isAnimated={true} ref={ref}>
                    <Menu
                        $theme={theme}
                        isOpen={isCarMenuOpened}
                        length={menuLength}
                    >
                        {!isCarMenuOpened && (
                            <AnimWrapper>
                                <MenuCircle
                                    $theme={theme}
                                    onClick={() => {
                                        setIsCarMenuOpened(!isCarMenuOpened)
                                        posthog.capture('car_menu open')
                                    }}
                                >
                                    <div>Menu</div>
                                </MenuCircle>
                            </AnimWrapper>
                        )}

                        {isCarMenuOpened && (
                            <AnimWrapper>
                                <Circle
                                    $theme={theme}
                                    onClick={() => {
                                        carAtionMenuItemsData['add'].onClick()
                                    }}
                                    isActive={activeID === 'add'}
                                >
                                    <AddIcon
                                        colour={
                                            colours[theme].background_default
                                        }
                                    />
                                </Circle>

                                {carAtionMenuItemsData['edit'].options.length >
                                0 ? (
                                    <Circle
                                        $theme={theme}
                                        isActive={activeID === 'edit'}
                                        onClick={() => {
                                            carAtionMenuItemsData[
                                                'edit'
                                            ].onClick()
                                        }}
                                    >
                                        <PenIcon
                                            color={
                                                colours[theme]
                                                    .background_default
                                            }
                                        />
                                    </Circle>
                                ) : null}

                                <Circle
                                    $theme={theme}
                                    isActive={activeID === 'more'}
                                    onClick={() => {
                                        carAtionMenuItemsData['more'].onClick()
                                    }}
                                >
                                    <ThreeDotsIcon
                                        color={
                                            colours[theme].background_default
                                        }
                                    />
                                </Circle>

                                <div style={{ paddingLeft: '5px' }} />

                                <Line $theme={theme} />

                                <div style={{ paddingLeft: '5px' }} />

                                <MenuCircle
                                    $theme={theme}
                                    onClick={() => {
                                        setIsCarMenuOpened(!isCarMenuOpened)
                                    }}
                                >
                                    <div>Close</div>
                                </MenuCircle>
                            </AnimWrapper>
                        )}
                    </Menu>
                    <DesktopDisplayOnly>
                        {activeID && (
                            <Faded>
                                <MenuBox $theme={theme} direction={'bottom'}>
                                    <BoxTitle $theme={theme}>
                                        {carAtionMenuItemsData[activeID].name}
                                    </BoxTitle>

                                    <div style={{ paddingTop: '10px' }} />
                                    {carAtionMenuItemsData[
                                        activeID
                                    ].options.map((item, index) => {
                                        return (
                                            <React.Fragment
                                                key={`${item.id}_${index}`}
                                            >
                                                <OptionItem
                                                    item={item}
                                                    index={index}
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                    <div style={{ paddingTop: '10px' }} />
                                </MenuBox>
                            </Faded>
                        )}
                    </DesktopDisplayOnly>
                </AnimatedWrapper>
            )}
        </div>
    )
}

export default CarActionMenu
