import { call, fork, put, take } from 'redux-saga/effects'
import {
    IDeleteTimelineItemPayloadRequest,
    IDeleteTimelineItemPayloadSuccess,
} from 'timelineModels'
import * as successActions from '../../timeline/actions/successActions'
import * as errorActions from '../../timeline/actions/errorActions'
import { api } from '../../services'
import { push } from 'connected-react-router'

import * as requestActions from '../actions/requestActions'
import posthog from 'posthog-js'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
// import * as successActions from '../actions/successActions'
// import * as errorActions from '../actions/errorActions'

import * as carActions from '../../entities/cars/actions'
import { IApiEntryDELETE_args } from 'ApiInterfaces'

function* DeleteCarTimelineSaga(
    payload: IDeleteTimelineItemPayloadRequest
): any {
    // later on : put API TRY CATH
    // yield put(requestActions.filterTimelineItemsByCarCategoryTagIDRequest)
    // yield put(errorActions.filterTimelineItemsByCarCategoryTagIDError(error))

    try {
        // STEP 1 . call the api func
        let api_payload: IApiEntryDELETE_args = {
            entry_id: payload.itemid,
            car_id: payload.carid,
        }
        yield call(api.timeline.entries.deleteEntry.deleteEntry, api_payload)

        // STEP 2. build up payload to return to reducer
        let res: IDeleteTimelineItemPayloadSuccess = {
            itemid: payload.itemid,
            carid: payload.carid,
        }

        // STEP 3. dispatch delete action
        yield put(successActions.deleteTimelineEntrySuccess(res))

        //update car entries count
        yield put(
            carActions.actions.updatCarEntriesCount({
                carid: payload.carid,
                update: 'delete',
            })
        )

        // make it so that it gets fetched again
        yield put(
            carActions.actions.getLastCarInsuranceEntrySuccess({
                carid: payload.carid,
                timelineItem: undefined,
            })
        )

        // STEP 4. redirect to timeline page
        yield put(push(`/car/${payload.carid}/history-file`))

        // Capture event
        posthog.capture('DELETE ENTRY')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: DeleteCarTimelineSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carid}/history-file`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.deleteTimelineEntryError(customError))
        }
    }
}

function* watcherDeleteCarTimeline() {
    while (true) {
        const { payload } = yield take(
            requestActions.deleteTimelineEntryRequest
        )

        yield call(DeleteCarTimelineSaga, payload)
    }
}

const delete_car_timeline: any[] = [fork(watcherDeleteCarTimeline)]

export default delete_car_timeline
