import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'

// import UserAccountCircle from "../../atoms/user/userAccountCircle";
import { IGalleryImage } from 'entityModels'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../skeleton/skeletonAbsolute'
import Icon from '../../icons'

const ImageCellWrap = styled.div`
    position: relative;

    cursor: pointer;
    height: 250px;

    @media (max-width: 1200px) {
        height: 200px;
    }

    @media (max-width: 1000px) {
        height: 180px;
    }

    @media (min-width: 2000px) {
        height: 370px;
    }
`

const ImageCell = styled(Link)<StyleProps>`
    background-image: ${(props) => `url(${props.url})`};
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    height: 100%;

    text-align: center;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: ${(props) => (props.$isLoading === true ? 1 : 1)};
    transition: opacity 400ms;
`

const Text = styled.div`
    color: white;
    position: absolute;
    width: 90%;

    @media ${device.desktop} {
        top: 30px;
        left: 26px;
    }
    @media ${device.large_desktop} {
        top: 30px;
        left: 30px;
    }

    @media ${device.smallest_laptops} {
        top: 20px;
        left: 20px;
    }

    text-align: left;
    padding-right: 20px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    padding-bottom: 5px;
    width: 100%;
`

const Title = styled.div`
    font-family: Cinzel;
    padding-left: 10px;
    @media ${device.desktop} {
        font-size: 14px;
    }

    @media ${device.smallest_laptops} {
        font-size: 14px;
    }
`

const Location = styled.div`
    font-family: Lato;

    padding-left: 10px;
    @media ${device.desktop} {
        font-size: 13px;
    }

    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
`

const HoverBox = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;

    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;

    background: linear-gradient(
        179.47deg,
        rgba(0, 0, 0, 0.94) 0.84%,
        rgba(0, 0, 0, 0.94) 10.68%,
        rgba(0, 0, 0, 0.38) 62.87%
    );
    transition: 0.2s;

    :hover {
        opacity: 1;
    }
`

const IconPosition = styled.div`
    margin-top: -17px;
`

type State = {
    src: any
    isLoading: boolean
}

interface Props extends RouteComponentProps<any> {
    image_obj: IGalleryImage
    isPlaceholder?: boolean
}

interface StyleProps {
    url: string
    $isLoading: boolean
}

class ShowroomGalleryOverviewGridItem extends React.Component<Props, State> {
    state = {
        src: null,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src = this.props.image_obj.url
        this._isMounted && this.setState({ src })
        this._isMounted && this.onLoad(src)
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.image_obj.url !== prevProps.image_obj.url) {
            const src = this.props.image_obj.url

            this.setState({ src, isLoading: true })

            this.onLoad(src)
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                ...this.state,
                isLoading: false,
            })
    }

    render() {
        const { image_obj, isPlaceholder } = this.props

        const { isLoading } = this.state
        return (
            <ImageCellWrap>
                <SkeletonAbsolute isthingloading={isLoading} />
                <ImageCell
                    role="img"
                    aria-label="car overview banner"
                    url={this.state.src || grey_placeholder}
                    to={
                        isPlaceholder
                            ? { pathname: `${this.props.match.url}/gallery` }
                            : {
                                  pathname: `${this.props.match.url}/gallery`,
                                  search: `?id=${image_obj.id}`,
                                  state: { prevPath: this.props.match.url },
                              }
                    }
                    $isLoading={isLoading}
                >
                    {!isPlaceholder && (
                        <HoverBox>
                            <Text>
                                <Row>
                                    {/* <UserAccountCircle /> */}
                                    <Location>{image_obj.caption}</Location>
                                </Row>
                                <Row>
                                    <IconPosition>
                                        <Icon
                                            icon="location_white"
                                            width="12px"
                                        />
                                    </IconPosition>
                                    <Title>
                                        {image_obj.location
                                            ? image_obj.location
                                            : 'Unknown'}
                                    </Title>
                                </Row>
                            </Text>
                        </HoverBox>
                    )}
                </ImageCell>
            </ImageCellWrap>
        )
    }
}

export default withRouter(ShowroomGalleryOverviewGridItem)
