import { insurance_colours } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

function InsuranceDriversIcon(props?: any) {
    const { theme } = useThemes()
    return (
        <svg
            width={props?.width ?? 40}
            height={props?.width ?? 40}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect
                width={40}
                height={40}
                rx={4}
                fill={insurance_colours[theme].sections['drivers'].primary_08}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.538 10.5H8.63c-.355 0-.64.125-.819.28-.175.151-.226.304-.226.42v17.6c0 .116.05.269.226.42.18.155.464.28.82.28h22.908c.356 0 .64-.125.82-.28.175-.151.226-.304.226-.42V11.2c0-.116-.05-.269-.226-.42a1.255 1.255 0 00-.82-.28zM8.63 9h22.91c1.405 0 2.545.985 2.545 2.2v17.6c0 1.215-1.14 2.2-2.546 2.2H8.63c-1.405 0-2.545-.985-2.545-2.2V11.2c0-1.215 1.14-2.2 2.545-2.2z"
                fill={insurance_colours[theme].sections['drivers'].primary_100}
            />
            <path
                d="M10.084 13.501a1 1 0 011-1h18a1 1 0 110 2h-18a1 1 0 01-1-1zM10.084 17.501a1 1 0 011-1h18a1 1 0 110 2h-18a1 1 0 01-1-1zM19.084 21.501a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1zM19.084 25.501a1 1 0 011-1h9a1 1 0 110 2h-9a1 1 0 01-1-1z"
                fill={insurance_colours[theme].sections['drivers'].primary_24}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.657 26.998c0-1.57 1.151-3 2.75-3h2c1.6 0 2.75 1.43 2.75 3v.903l-.495.178-.255-.706.255.706h-.002l-.003.002-.005.002-.016.005a4.487 4.487 0 01-.223.067c-.148.041-.36.093-.634.143-.548.102-1.344.2-2.371.2-1.028 0-1.824-.098-2.372-.2a7.728 7.728 0 01-.807-.193 2.544 2.544 0 01-.05-.017l-.016-.005-.005-.002-.003-.001s-.002 0 .253-.706l-.254.706-.497-.18V27zm1.511-.202c.045.01.092.018.142.028.462.085 1.166.174 2.098.174a11.572 11.572 0 002.239-.202c-.087-.797-.676-1.297-1.24-1.297h-2c-.563 0-1.152.5-1.239 1.297z"
                fill={insurance_colours[theme].sections['drivers'].primary_60}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.408 20.956a1.25 1.25 0 100 2.502 1.25 1.25 0 000-2.502zm-2.75 1.251a2.75 2.75 0 115.5 0 2.75 2.75 0 01-5.5 0z"
                fill={insurance_colours[theme].sections['drivers'].primary_60}
            />
        </svg>
    )
}

export default InsuranceDriversIcon
