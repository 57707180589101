import * as actions from './successActions'
import { ThunkAction } from 'redux-thunk'
import { RootAction } from 'typesafe-actions'
import { ITasksDisplayCriteria } from 'entityModels'

export const registerTasksDisplayCriteriaThunk = (
    payload: ITasksDisplayCriteria
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(actions.registerTasksDisplayCriteriaSuccess(payload))
        })
    }
}
