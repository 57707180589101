import { ICar, IGalleryImagesObject } from 'entityModels'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { HistoryFilesIcon } from '../../../atoms/icons/components'
import LastUpdateIcon from '../../../atoms/icons/components/lastUpdatedICon'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'
import { ITheme } from '../../../../providers/theme/colours'

interface IStyle {
    hasNoEntry?: boolean
    index: number
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    height: 100%;
    padding: 24px;
    /* height: 122px;
    padding-left: 23px;
    padding-top: 20px;
    padding-bottom: 20px; */
    display: flex;
    align-items: center;
    gap: 24px;
    min-width: 100%;
    max-width: 100%;

    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border-radius: 6px;
    background-color: var(--shadow-cards-bg, #fff);

    transition: all 100ms;
    :hover {
        background: var(--primary_04);
        transition: all 100ms;
    }
`

const CarTitle = styled.h3`
    padding: 0px;
    margin: 0px;
    font-size: 17px;
    /* font-weight: 600; */
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    text-transform: uppercase;
    width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`
type IImageStyle = {
    isthingloading: boolean
}

const CarProfileImage = styled.img<IImageStyle>`
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
`

const CarImgWrapper = styled.div`
    height: 128px;
    min-width: 170px;
    max-width: 170px;
    border-radius: 8px;
`

const EntriesCount = styled.div`
    font-size: 14px;
    color: var(--text-default, #666666);
    font-family: Lato;
`
const LastUpdated = styled.div`
    font-size: 14px;
    color: var(--text-default, #666666);
    font-family: Lato;
`

function mapStateToProps(state: RootState) {
    return {
        carid: state.entities.carsData.currentCarID,
    }
}

const dispatchProps = {}

interface Props extends RouteComponentProps<any> {
    car: ICar
    garageId?: string
    index: number
    imagesData: IGalleryImagesObject | null
    carIndex: number
}

type State = { src: any; srcet: any; isLoading: boolean; theme: ITheme }
class GeneralTimelineCardDesktop extends React.Component<Props, State> {
    static contextType = ThemesContext

    state = {
        srcet: undefined,
        src: undefined,
        isLoading: true,
        theme: this.context?.theme,
    }

    _isMounted = false

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                src: src,
                isLoading: false,
            })
    }

    componentDidMount() {
        this._isMounted = true
        const src = this.get_car_cover_url(this.props.car)

        if (src.url !== undefined) {
            this.onLoad(src.url)

            if (src.srcset)
                this.setState({
                    ...this.state,
                    srcet: src.srcset,
                })
        } else {
            return this.onLoad(
                randomCarSvgPlaceholderFunc('jpg', this.state.theme)
            )
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.context !== prevState.theme) {
            this.setState({ theme: this.context })

            let i: string = randomCarSvgPlaceholderFunc(
                undefined,
                this.context?.theme
            )
            return this.setState({
                src: i,
            })
        }
    }

    get_car_cover_url = (carObj: ICar) => {
        if (carObj && carObj.gallery) {
            let imageid =
                carObj.gallery && carObj.gallery.cover && carObj.gallery.cover
                    ? carObj.gallery.cover
                    : undefined

            if (
                imageid &&
                this.props.imagesData !== undefined &&
                this.props.imagesData !== null
            ) {
                if (
                    this.props.imagesData[imageid] !== undefined &&
                    this.props.imagesData[imageid] !== null &&
                    this.props.imagesData[imageid].medium_srcset
                ) {
                    return {
                        srcset: this.props.imagesData[imageid].medium_srcset,
                        url: this.props.imagesData[imageid].url,
                    }
                } else if (
                    this.props.imagesData[imageid] !== undefined &&
                    this.props.imagesData[imageid] !== null &&
                    this.props.imagesData[imageid].url
                ) {
                    return {
                        url: this.props.imagesData[imageid].url,
                    }
                }
            }
        }

        return {
            url: undefined,
        }
    }
    render() {
        let { car, index, carIndex } = this.props
        let imageid =
            car.gallery && car.gallery.cover && car.gallery.cover
                ? car.gallery.cover
                : undefined

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        {car ? (
                            <ShadowBox
                                index={index}
                                to={{
                                    pathname: `/car/${car.id}/history-file`,
                                    state: {
                                        prevPath:
                                            this.props.history.location
                                                .pathname,
                                    },
                                }}
                                hasNoEntry={
                                    !car.car_entries_count
                                        ? true
                                        : car.car_entries_count === 0
                                        ? true
                                        : false
                                }
                            >
                                <CarImgWrapper>
                                    <SkeletonAbsolute
                                        isthingloading={this.state.isLoading}
                                    />
                                    <CarProfileImage
                                        src={
                                            this.state.src
                                                ? this.state.src
                                                : randomCarSvgPlaceholderFunc(
                                                      'jpg',
                                                      theme
                                                  )
                                        }
                                        isthingloading={this.state.isLoading}
                                        srcSet={
                                            imageid &&
                                            this.props.imagesData &&
                                            this.props.imagesData[imageid] &&
                                            this.props.imagesData[imageid]
                                                .small_srcset
                                                ? this.props.imagesData[imageid]
                                                      .small_srcset
                                                : undefined
                                        }
                                        aria-label="car profile image"
                                    />
                                </CarImgWrapper>

                                <Col style={{ minWidth: 0 }}>
                                    <CarTitle>
                                        {car.title.trim() !== ''
                                            ? car.title
                                            : `Untitled #${carIndex + 1}`}
                                    </CarTitle>

                                    <div style={{ paddingTop: '16px' }} />

                                    <Row>
                                        <HistoryFilesIcon
                                            size="18"
                                            color={
                                                'var(--text-default, #666666)'
                                            }
                                        />

                                        <EntriesCount>
                                            {car.car_entries_count
                                                ? car.car_entries_count
                                                : 0}{' '}
                                            {car.car_entries_count !==
                                                undefined &&
                                            car.car_entries_count !== 1
                                                ? 'Entries'
                                                : 'Entry'}
                                        </EntriesCount>
                                    </Row>
                                    <div style={{ paddingTop: '8px' }} />
                                    <Row>
                                        <LastUpdateIcon />

                                        <LastUpdated>
                                            Updated -{' '}
                                            {!car.car_entries_count
                                                ? 'NA'
                                                : car.car_entries_count === 0
                                                ? 'NA'
                                                : car.last_entry_updated_at_date
                                                ? car.last_entry_updated_at_date
                                                : 'NA'}
                                        </LastUpdated>
                                    </Row>
                                </Col>
                            </ShadowBox>
                        ) : (
                            <div />
                        )}
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GeneralTimelineCardDesktop)
)
