import { ITask, ITaskRelationsPayload } from 'entityModels'
import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";

import DatePickerTaskDesktop from './../datePicker/datePickerTaskDesktop'
import { connect } from 'react-redux'
import NotesEditorFormWrapperDesktop from './../notes/notesEditorFormWrapperDesktop'
import BasicTaskFieldFormDesktop from './../basic/basicTaskFieldFormDesktop'

import { RootState } from 'typesafe-actions'
import { generate_task_cars_dropdown_list } from '../../../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'

import SearchNselectCarsTaskDesktop from './../taskCars/searchNselectCarsTaskDesktop'

import {
    linkTaskRequest,
    unlinkTaskRequest,
} from '../../../../../redux/tasks/actions/requestActions'
import { OOtherTaskOptions } from '../../../../../ol/ol'

import { getGarageCarsDataRequest } from '../../../../../redux/entities/cars/actions/loadingActions'

import moment from 'moment'

const dispatchProps = {
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    unlinkTaskRequest: (payload: ITaskRelationsPayload) =>
        unlinkTaskRequest(payload),
    linkTaskRequest: (payload: ITaskRelationsPayload) =>
        linkTaskRequest(payload),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        garagesData: state.entities.garagesData.garages,
    }
}

export interface Props {
    item: any
    task: ITask
    toggle: any
    updateTask: any
    carsData: any
    userLoggedIn: any
    garagesData: any
    getGarageCarsDataRequest: any

    unlinkTaskRequest: any
    linkTaskRequest: any
    isEveryCar?: boolean
    timezone?: string
}

type State = {
    dropdownCars: any
    selectedCarNames: string[]
}
class TaskFormManagerDesktop extends React.Component<Props, State> {
    state = {
        dropdownCars: null,
        selectedCarNames: [],
    }

    componentDidMount() {
        if (this.props.item.id === 'linkedToCar') {
            if (this.props.item.answer !== undefined) {
                if (this.props.carsData[this.props.item.answer[0]]) {
                    this.props.carsData[this.props.item.answer[0]].title &&
                        this.setState({
                            selectedCarNames: [
                                this.props.carsData[this.props.item.answer[0]]
                                    .title,
                            ],
                        })
                }
            }

            if (!this.props.item.answer) {
                this.setState({
                    selectedCarNames: [OOtherTaskOptions.general_task.name],
                })
            }

            let userData = this.props.userLoggedIn

            if (userData) {
                if (userData.owns_garage && userData.owns_garage.uid) {
                    // garage stuff

                    this.props.getGarageCarsDataRequest(
                        this.props.userLoggedIn.owns_garage.uid
                    )
                }
            } else if (this.props.garagesData) {
                if (
                    this.props.garagesData[userData.owns_garage.uid] &&
                    this.props.garagesData[userData.owns_garage.uid].cars
                ) {
                    let dropdown_cars_dropdown = generate_task_cars_dropdown_list(
                        this.props.garagesData[userData.owns_garage.uid].cars,
                        this.props.carsData,
                        userData.owns_garage.uid
                    )
                    this.setState({ dropdownCars: dropdown_cars_dropdown })
                }
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let userData = this.props.userLoggedIn

        if (userData !== prevProps.userLoggedIn) {
            if (userData) {
                if (userData.owns_garage && userData.owns_garage.uid) {
                    // garage stuff

                    this.props.getGarageCarsDataRequest(
                        this.props.userLoggedIn.owns_garage.uid
                    )
                }
            }
        }

        if (this.props.garagesData !== prevProps.garagesData) {
            if (
                this.props.garagesData[userData.owns_garage.uid] &&
                this.props.garagesData[userData.owns_garage.uid].cars
            ) {
                let dropdown_cars_dropdown = generate_task_cars_dropdown_list(
                    this.props.garagesData[userData.owns_garage.uid].cars,
                    this.props.carsData,
                    userData.owns_garage.uid
                )
                this.setState({
                    dropdownCars: dropdown_cars_dropdown,
                })
            }
        }
    }

    renderForm = (item: any, task: ITask, updateTask: any, toggle: any) => {
        switch (item.id) {
            case 'due':
                return (
                    <DatePickerTaskDesktop
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                        showReminderResetWarning={
                            task.reminders && task.reminders?.length > 0
                                ? true
                                : false
                        }
                        minDate={moment().format('YYYY-MM-DD')}
                        timezone={this.props.timezone}
                    />
                )

            case 'body':
                return (
                    <NotesEditorFormWrapperDesktop
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                    />
                )

            case 'linkedToCar':
                return (
                    <>
                        {this.props.garagesData[
                            this.props.userLoggedIn.owns_garage.uid
                        ] &&
                        this.props.garagesData[
                            this.props.userLoggedIn.owns_garage.uid
                        ].cars ? (
                            <SearchNselectCarsTaskDesktop
                                item={{
                                    ...item,
                                    answer: this.state.selectedCarNames,
                                }}
                                task={task}
                                toggle={toggle}
                                dropdownData={
                                    this.state.dropdownCars
                                        ? this.state.dropdownCars
                                        : []
                                }
                                carsData={this.props.carsData}
                                garage_id={
                                    this.props.userLoggedIn.owns_garage.uid
                                }
                                unlinkTaskRequest={this.props.unlinkTaskRequest}
                                linkTaskRequest={this.props.linkTaskRequest}
                                garageCarsIds={
                                    this.props.garagesData[
                                        this.props.userLoggedIn.owns_garage.uid
                                    ].cars
                                }
                                isEveryCar={this.props.isEveryCar}
                            />
                        ) : (
                            <div />
                        )}
                    </>
                )

            default:
                return (
                    <BasicTaskFieldFormDesktop
                        submitFunc={updateTask}
                        taskid={task.id}
                        item={item}
                        toggle={toggle}
                    />
                )
        }
    }

    render() {
        let { item, task, updateTask, toggle } = this.props
        return this.renderForm(item, task, updateTask, toggle)
    }
}

export default connect(mapStateToProps, dispatchProps)(TaskFormManagerDesktop)

// SelectSearchBarCarInfoDesktop
