import { push } from 'connected-react-router'
import { IReduxError } from 'entityModels'
import { ILoginPayloadResAPI } from 'IapiDataPayload'
import { ITokenBoolState } from 'myModels'
import { call, put, select } from 'redux-saga/effects'
import { RootState } from 'typesafe-actions'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { api } from '../../services'
import { history } from '../../store'

import * as actions from '../actions/index'
import { customNavDataActions } from '../../localdata/customNav/reducer'

export const getTokenBoolChecks = (state: RootState): ITokenBoolState =>
    state.user.tokenCheck

export type IUnauthHandlerPayload = {
    functionToRepeat: any
    payload?: any
    reduxErrorActionToFire?: any[]
    redirectToLoginIfPublicUser?: boolean
    initialLandingUrlAfterLogin?: string
}

export function* unauthHandlerSaga(data: IUnauthHandlerPayload): any {
    let tokenBoolsChecks: ITokenBoolState = yield select(getTokenBoolChecks)

    if (
        // if refresh token hasn't retried for more than 5 times
        tokenBoolsChecks.isTokenReqInProcess !== true &&
        tokenBoolsChecks.retriesCount < 50
        // && !tokenBoolsChecks.hasSessionRefreshedTokenOnce
    ) {
        // say that its being fetched
        yield put(actions.default.loadingActions.refreshTokenRequest(true))

        // call refresh token
        try {
            let res: ILoginPayloadResAPI = yield call(
                api.authentication.refreshToken
            )

            // set refresh token in local storage
            window?.localStorage?.setItem('user_id', res.user_id)
            window?.localStorage?.setItem('refresh_token', res.refresh_token)

            // set refresh token success in reducer
            yield put(actions.default.actions.refreshTokenSuccess(false))
            // increment refresh token number of tries in reducer
            yield put(actions.default.actions.addToRefreshTokenRetriesCount())

            // // record RT has been refreshed once this session already
            // yield put(actions.default.actions.refreshTokenOnceSuccess(true))

            // recall action
            if (data.payload) {
                yield call(data.functionToRepeat, data.payload)
            } else {
                yield call(data.functionToRepeat)
            }
        } catch (error: any) {
            // alert('ERROR W/TOKEN:')
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(actions.default.actions.refreshTokenSuccess(false))
            // yield put(actions.default.actions.refreshTokenOnceSuccess(false))
            yield put(
                actions.default.errorActions.getCurrentUserDataError(typedError)
            )

            if (typedError?.status_code === 503) {
                return
            }

            if (data.reduxErrorActionToFire) {
                // convert error to let IReduxError = {}
                for (const fn of data.reduxErrorActionToFire) {
                    yield put(fn(error))
                }
            }

            let isResetPassPage =
                history.location.pathname.match(/reset-password/g) !== null ||
                window.location.pathname.match(/reset-password/g) !== null
                    ? true
                    : false

            let isRegisterNewLandingPage =
                history.location.pathname.match(/register/g) !== null ||
                window.location.pathname.match(/register/g) !== null
                    ? true
                    : false

            let isShowroomPublicPage =
                (history.location.pathname.match(/showroom/g) !== null ||
                    window.location.pathname.match(/showroom/g) !== null) &&
                (history.location.pathname.match(/edit/g) === null ||
                    window.location.pathname.match(/edit/g) === null)
                    ? true
                    : false

            if (data.redirectToLoginIfPublicUser) {
                if (data.initialLandingUrlAfterLogin) {
                    yield put(
                        customNavDataActions.setInitialLandingUrl(
                            data.initialLandingUrlAfterLogin
                        )
                    )
                }
                yield put(push(`/signin`))
            } else if (isShowroomPublicPage) {
                localStorage?.removeItem('refresh_token')

                if (window.matchMedia('(display-mode: standalone)').matches) {
                    // yield put(push(`/signin`))
                } else {
                    return
                    // if we're on a showroom public page and refresh_token comes back invalid
                    // we don't redirect to login and
                    // we need to remove refresh_token
                    // otherwise all subsequent calls (ex: get showroomEntry)
                    // will go for private endpoint calls and not public ones
                    // as we have separate calls (public or private) depending on whether there is any refresh_token in storage or not
                }
            } else if (
                history.location.pathname === '/login' ||
                history.location.pathname === '/signin' ||
                isResetPassPage === true ||
                isRegisterNewLandingPage === true
            ) {
                return
            } else {
                yield put(push(`/signin`))
            }

            return
        }
    } else if (tokenBoolsChecks.isTokenReqInProcess === true) {
        if (data.payload) {
            yield call(data.functionToRepeat, data.payload)
        } else {
            yield call(data.functionToRepeat)
        }
        return
    } else {
        // if refresh token is looping for any reason clean it up from local storage and push to sign in page
        localStorage?.removeItem('refresh_token')
        yield put(push(`/signin`))
    }
}
