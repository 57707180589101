import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// import IpadAndMobileDisplay from "../displays/ipadAndMobileDisplay";
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import Faded from '../../animated/faded'
// import ScrollLock from 'react-scrolllock'
// import TouchScrollable from "react-scrolllock";

import { device } from '../devices'

function mapStateToProps(state: RootState) {
    return {
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

interface Props {
    children: React.ReactNode
    isCollapsed: boolean
    isOpen: boolean
    toggle: any
    modalBackgroundColor?: string
}

interface Istyle {
    isCollapsed?: boolean
    isOpen?: boolean
    backgroundColor?: string
}

const DesktopPageWrapperStyle = styled.div<Istyle>`
    z-index: 12;
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'rgba(0, 0, 0, 0.85)'};
    height: 100%;
    width: 100%;

    top: 0px;
    bottom: 0;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.desktop} {
        left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
    @media ${device.large_desktop} {
        left: ${(props) => (props.isCollapsed ? '71px' : '271px')};
    }

    @media ${device.smallest_laptops} {
        left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
`

const MenuAdjust = styled.div<Istyle>`
    display: none;

    @media ${device.desktop} {
        margin-left: ${(props) => (props.isCollapsed ? '-45px' : '-205px')};
        height: 100%;
        width: 100%;

        transition: all 100ms;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    @media ${device.large_desktop} {
        height: 100%;
        width: 100%;

        transition: all 100ms;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    @media ${device.smallest_laptops} {
        margin-left: ${(props) => (props.isCollapsed ? '-45px' : '-205px')};
        height: 100%;
        width: 100%;

        transition: all 100ms;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`

const LeftClickBlock = styled.div`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        display: block;
        position: absolute;
        top: 0;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100vh;
        width: 100vw;
    }
`

class ModalDisplayDesktopExclusive extends React.Component<Props> {
    // componentDidMount() {
    //     if (this.props.isOpen) {
    //         document.body.style.overflow = 'hidden'
    //     }
    // }

    // componentDidUpdate(prevProps: Props) {
    //     if (this.props === prevProps) {
    //         if (this.props.isOpen === true) {
    //             document.body.style.overflow = 'hidden'
    //         }
    //     }
    // }

    // componentWillUnmount() {
    //     document.body.style.overflow = 'auto'
    // }

    render() {
        const { isCollapsed, isOpen, toggle, children, modalBackgroundColor } =
            this.props
        return (
            <DesktopDisplayOnly>
                <Faded>
                    <DesktopPageWrapperStyle
                        isCollapsed={isCollapsed}
                        isOpen={isOpen}
                        backgroundColor={modalBackgroundColor}
                    >
                        <LeftClickBlock
                            onClick={(e: any) => {
                                e.preventDefault()
                                setTimeout(() => {
                                    toggle()
                                }, 50)
                            }}
                        />
                        <MenuAdjust isCollapsed={isCollapsed}>
                            {children}
                        </MenuAdjust>
                    </DesktopPageWrapperStyle>
                </Faded>

                {/* {isOpen && <ScrollLock />} */}
            </DesktopDisplayOnly>
        )
    }
}

export default connect(mapStateToProps, {})(ModalDisplayDesktopExclusive)
