import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'typesafe-actions'

import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
    ICar,
    ICreateDirectShareRequest,
    IDirectShareOwnedItem,
    ISharingPermissionIDS,
} from 'entityModels'
import { IUser } from 'myModels'
import {
    createCarDirectShareRequest,
    getCarDataByIdRequest,
} from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import CreationTopHeaderDesktop from '../../molecules/entryPageTopHeader/creationTopHeaderDesktop'
import PrivateShareFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareFormManagerDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import PrivateShareFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareFormManagerMobile'
import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
import { IApi_CreateDirectShare_args } from 'ApiInterfaces'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isCarLoading: state.entities.carsData.loading,
        carsData: state.entities.carsData.cars,
        isUserLoading: state.user.loading,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    createCarDirectShareRequest: (p: IApi_CreateDirectShare_args) =>
        createCarDirectShareRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    carsData: { [key: string]: ICar }
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isCarLoading: boolean
    getUserDataRequest: () => void
    getCarDataByIdRequest: (carid: string) => void
    setCurrentCar: (car_id: string) => void
    createCarDirectShareRequest: (p: IApi_CreateDirectShare_args) => void
    isCollapsed: boolean
    setInitialLandingUrl: (str: string) => void
}

type State = {
    share_being_edited: IDirectShareOwnedItem
    isMobileCreationOpen: boolean
}

class CarShareCreate extends React.Component<Props, State> {
    state: State = {
        share_being_edited: {
            entity_id: '',
            title: '',
            active_permission_ids: ['car gallery'] as any,
            id: 'share_being_edited',
            type: 'car',
            created_at: '', // now
            updated_at: '', // now
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            total_recipients: 0,
        },
        isMobileCreationOpen: false,
    }

    _isMounted = false

    handleResize = () =>
        window.innerWidth < 881
            ? this.setState({ isMobileCreationOpen: true })
            : this.setState({ isMobileCreationOpen: false })

    componentDidMount() {
        this._isMounted = true
        // user stuff
        let userData = this.props.userLoggedIn
        const q_params = new URLSearchParams(this.props.location.search)
        let carid = q_params.get('carid')
        this.props.setInitialLandingUrl(
            `/sharing/with-others/car/create-share?carid=${carid}`
        )
        if (!userData) {
            this.props.getUserDataRequest()
        } else if (carid) {
            this.props.setCurrentCar(carid)
            this.props.getCarDataByIdRequest(carid)
        }

        window.addEventListener('resize', this.handleResize, { passive: true })

        window.innerWidth < 881 && this.setState({ isMobileCreationOpen: true })
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            const q_params = new URLSearchParams(this.props.location.search)
            let carid = q_params.get('carid')
            if (carid) {
                this.props.setCurrentCar(carid)
                this.props.getCarDataByIdRequest(carid)
            }
        }
    }
    componentWillUnmount() {
        this.setState({ isMobileCreationOpen: false })
        window.removeEventListener('resize', this.handleResize)
        this._isMounted = false
    }

    // handleChangeEmail = (value: string) => {
    //     return this.setState({
    //         ...this.state,
    //         share_being_edited: {
    //             ...this.state.share_being_edited,
    //             email_no_user: value,
    //         },
    //     })
    // }

    handleChangePermissions = (permissionID: ISharingPermissionIDS) => {
        let permissions_arr: ISharingPermissionIDS[] =
            this.state.share_being_edited.active_permission_ids

        if (
            this.state.share_being_edited.active_permission_ids.includes(
                permissionID
            )
        ) {
            return this.setState({
                ...this.state,
                share_being_edited: {
                    ...this.state.share_being_edited,
                    active_permission_ids: permissions_arr.filter(
                        (id) => permissionID !== id
                    ),
                },
            })
        } else {
            return this.setState({
                ...this.state,
                share_being_edited: {
                    ...this.state.share_being_edited,
                    active_permission_ids: [...permissions_arr, permissionID],
                },
            })
        }
    }

    handleSubmitToBE = () => {
        const q_params = new URLSearchParams(this.props.location.search)
        let carid = q_params.get('carid')
        let carsData = this.props.carsData
        let current: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined
        if (carid) {
            let payload: ICreateDirectShareRequest = {
                resource_id: carid,
                permissionIDS:
                    this.state.share_being_edited.active_permission_ids,
                title:
                    current && current.title
                        ? `Direct share for ${current && current.title}`
                        : 'Untitled',
                resource_type: 'car',
                share_type: 'direct_share',
            }

            this.props.createCarDirectShareRequest(payload)
        }
    }

    render() {
        let { carsData, isCollapsed } = this.props

        let { share_being_edited } = this.state

        const q_params = new URLSearchParams(this.props.location.search)
        let carid = q_params.get('carid')
        let current: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        return (
            <React.Fragment>
                <DesktopDisplayOnly>
                    <CenteredPageWrapper fullwidth>
                        {current && share_being_edited && (
                            <WindowPageTemplate
                                isCollapsed={isCollapsed}
                                stickyHeaderContent={
                                    <CreationTopHeaderDesktop
                                        onClose={() =>
                                            this.props.history.push(
                                                `/sharing/with-others/car?carid=${carid}`
                                            )
                                        }
                                        text={'Add Car Direct Share'}
                                    />
                                }
                            >
                                <PrivateShareFormManagerDesktop
                                    share_being_edited={share_being_edited}
                                    handleSubmit={this.handleSubmitToBE}
                                    // handleEmail={this.handleChangeEmail}
                                    handlePermissions={
                                        this.handleChangePermissions
                                    }
                                    formType="create"
                                />
                            </WindowPageTemplate>
                        )}
                    </CenteredPageWrapper>
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <BottomSheetPage
                        isOpen={this.state.isMobileCreationOpen}
                        toggle={() =>
                            this.setState({ isMobileCreationOpen: false })
                        }
                    >
                        <PrivateShareReturnNav
                            customText="Add car direct share"
                            chevron
                            linkTo={`/sharing/with-others/car`}
                            params={`?carid=${carid}`}
                        />
                        <div style={{ paddingTop: '60px' }} />
                        <PrivateShareFormManagerMobile
                            share_being_edited={share_being_edited}
                            handleSubmit={() => {
                                this.handleSubmitToBE()
                                this.setState({ isMobileCreationOpen: false })
                                this.props.history.push(
                                    `/sharing/with-others/car?carid=${carid}`
                                )
                            }}
                            // handleEmail={this.handleChangeEmail}
                            handlePermissions={this.handleChangePermissions}
                            formType="create"
                        />
                        <div style={{ paddingTop: '150px' }} />
                    </BottomSheetPage>
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarShareCreate)
)
