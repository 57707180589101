import { ICar, IHighlightedFactsObject } from 'entityModels'

const AreObjectsEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export default AreObjectsEqual

export const checkIfCarHasMissingJourneyInfo = (
    car: ICar,
    kmfObj: IHighlightedFactsObject
) => {
    let check = 0
    let checkObj = {
        profile_photo: false,
        key_moments_and_facts: false,
        gallery: false,
        banner: false,
        bio: false,
    }

    // profile photo
    if (!car.gallery.cover) {
        check++
        checkObj.profile_photo = true
    }

    // kmf
    if (!car.highlighted_facts || car.highlighted_facts.length === 0) {
        check++
        checkObj.key_moments_and_facts = true
    } else {
        let hasKmfMissing = true
        car.highlighted_facts.forEach((id) => {
            if (kmfObj[id] && kmfObj[id].text !== '') {
                hasKmfMissing = false
            }
        })
        if (hasKmfMissing === true) {
            check++
            checkObj.key_moments_and_facts = true
        }
    }

    // gallery images
    if (!car.gallery || !car.gallery.images || car.gallery.images.length < 2) {
        check++
        checkObj.gallery = true
    }

    // banner
    if (!car.banner_url) {
        check++
        checkObj.banner = true
    }

    // bio
    if (!car.short_description || car.short_description === '') {
        check++
        checkObj.bio = true
    }

    // console.log('check obj', checkObj)
    // console.log('check', check)

    if (check > 1) {
        return true
    } else {
        return false
    }
}
