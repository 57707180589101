import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const AdminIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '14'}
            height={size ?? '14'}
            viewBox="0 0 14 14"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.9699 1.41473L2.00479 4.52809L-0.00793457 2.41473L1.07827 1.38025L2.00479 2.35309L3.88369 0.380249L4.9699 1.41473Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.9699 5.96051L2.00479 9.07387L-0.00793457 6.96051L1.07827 5.92603L2.00479 6.89887L3.88369 4.92603L4.9699 5.96051Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0079 7.89258L7.00793 7.89258L7.00793 6.39258L14.0079 6.39258L14.0079 7.89258Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0079 3.21631L7.00793 3.21631L7.00793 1.71631L14.0079 1.71631L14.0079 3.21631Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0079 12.735L7.00793 12.735L7.00793 11.235L14.0079 11.235L14.0079 12.735Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.9699 10.5064L2.00479 13.6198L-0.00793457 11.5064L1.07827 10.4719L2.00479 11.4448L3.88369 9.47192L4.9699 10.5064Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default AdminIcon
