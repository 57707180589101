import styled from 'styled-components'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { device } from '../../../templates/displays/devices'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { useState } from 'react'
import parse from 'html-react-parser'

type Props = {
    isLoading: boolean
    currentEntry: IShowroomEntry | undefined
    isAdmin?: boolean
    setActiveForm?: () => any
    editDescriptionImage?: () => any
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    max-width: 1200px;
`

const Description = styled.div`
    font-family: Lato;
    font-style: normal;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--text-default, #666666);
    width: 100%;

    transition: all 100ms;

    @media ${device.smallest_laptops} {
        font-size: 0.9rem;
    }

    @media ${device.large_desktop} {
        font-size: 18px;
        line-height: 28px;
    }

    h2 {
        font-size: 19px !important;
        padding-bottom: 10px;
    }

    h1 {
        font-size: 20px !important;
        padding-bottom: 10px;
    }

    @media ${device.mobile} {
        font-size: 16px;

        h2 {
            font-size: 17px !important;
            padding-bottom: 10px;
        }

        h1 {
            font-size: 19px !important;
            padding-bottom: 10px;
        }
    }
`

const Title = styled.div`
    font-family: Lato;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 32px;
    color: #1a1a1a;
    padding-bottom: 8px;
    width: 100%;

    @media ${device.mobile} {
        font-size: 22px;
    }
`

const Price = styled.div`
    font-family: Lato-semibold;
    text-transform: capitalize;
    font-size: 32px;
    color: var(--text-default, #666666);
    width: 100%;
    @media ${device.mobile} {
        font-size: 22px;
    }
`

const SectionImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
`

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 100%;
    height: 400px;
    border-radius: 2px;

    @media ${device.smallest_laptops} {
        height: 350px;
    }

    @media (max-width: 1220px) {
        height: 250px;
    }

    @media ${device.large_desktop} {
        height: 450px;
    }
    @media (min-width: 5000px) {
        height: 500px;
    }
    @media ${device.mobile} {
        height: 192px;
    }
`

// container for loader skeleton
const LoaderImageContainer = styled.div`
    width: 55%;
    height: 100%;
`

const DescriptionSectionExpanded = (props: Props) => {
    const { currentEntry, isLoading, editDescriptionImage } = props

    let car = currentEntry && currentEntry.car ? currentEntry.car : undefined

    let image_1 =
        currentEntry && currentEntry.featured_image
            ? currentEntry.featured_image
            : undefined

    const [showEditBtn, setShowEditBtn] = useState(false)

    let str = car?.description ? car.description : undefined

    const convertPriceToDisplayFormat = (price: number | undefined | null) => {
        if (price) {
            return price.toLocaleString().split(',').join(' ')
        }
        return ''
    }

    return currentEntry ? (
        <Container>
            <ContentContainer>
                <Title>
                    <div>{currentEntry?.car?.title}</div>
                </Title>
                <Price>
                    {currentEntry.type_of_sale !== 'PRICE_ON_ASKING'
                        ? currentEntry?.local_currency === 'GBP'
                            ? '£'
                            : ''
                        : 'POA'}{' '}
                    {currentEntry.type_of_sale !== 'PRICE_ON_ASKING' &&
                        (currentEntry.local_price_max ===
                        currentEntry.listing_price_min
                            ? convertPriceToDisplayFormat(
                                  currentEntry.local_price_max
                              )
                            : `${convertPriceToDisplayFormat(
                                  currentEntry.local_price_min
                              )} - ${convertPriceToDisplayFormat(
                                  currentEntry.local_price_max
                              )}`)}
                </Price>
                <div style={{ paddingTop: 40 }} />

                <ImageWrapper>
                    {isLoading ? (
                        <LoaderImageContainer>
                            <SkeletonAbsolute isthingloading={isLoading} />
                        </LoaderImageContainer>
                    ) : image_1 && props.isAdmin ? (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                            }}
                        >
                            <Overlay
                                onMouseEnter={() => setShowEditBtn(true)}
                                onMouseLeave={() => setShowEditBtn(false)}
                            >
                                {showEditBtn && (
                                    <ButtonAtom
                                        theme="lowercase-blue-background"
                                        fontSize={16}
                                        onClick={editDescriptionImage}
                                    >
                                        <span style={{ textTransform: 'none' }}>
                                            Edit photo
                                        </span>
                                    </ButtonAtom>
                                )}
                            </Overlay>
                            <SectionImage
                                src={image_1.url}
                                srcSet={
                                    image_1.medium_srcset
                                        ? image_1.medium_srcset
                                        : undefined
                                }
                            />
                        </div>
                    ) : image_1 && !props.isAdmin ? (
                        <SectionImage
                            src={image_1.url}
                            srcSet={
                                image_1.medium_srcset
                                    ? image_1.medium_srcset
                                    : undefined
                            }
                        />
                    ) : null}
                </ImageWrapper>
                <div style={{ paddingTop: 40 }} />
                <Description>{str ? parse(str) : '-'} </Description>
            </ContentContainer>
        </Container>
    ) : (
        <div />
    )
}

export default DescriptionSectionExpanded
