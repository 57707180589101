import { useState } from 'react'
import { ICarHandover_structure_base } from '../../../redux/localdata/carHandoverCopy'
import UsersSearchBar from './usersSearchBar'
import { ISearchUsersResult } from 'entityModels'
import { checkUserEmailExistsRequest } from '../../../redux/searchUsers/actions/loadingActions'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
// import { createSearchableIndex } from '../../../helpers/search/search'
import IconAndTextNakedButton from '../../atoms/Button/iconsAndTextNakedButton'
import InputFieldNoFormikAnimated from '../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { getRightSchemaAccountData } from '../editForms/account/validationSchema'
import Expander from '../../atoms/expander/expander'
import styled from 'styled-components'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'

const ErrorText = styled.div`
    width: 100%;
    font-size: 12px;
    color: var(--error, #df6f6f);
    font-style: italic;
`

export type I_HandoverHandleSelectExistingOwner = {
    email: string
    display_name: string
}

export type I_handleSelectExistingOwner_handover = {
    id: string | undefined
    display_name: string | undefined
}

export type I_props_Handover_ownerForm = {
    handleUserInviteValues: (id: string, value: string | undefined) => any
    handleSelectExistingOwner: (p: I_handleSelectExistingOwner_handover) => any
    setIsInviteActive: (arg: boolean) => any
    isInviteActive: boolean
    handoverFormData: ICarHandover_structure_base
}
const CarHandoverOwnerForm = (props: I_props_Handover_ownerForm) => {
    const dispatch = useAppDispatch()

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    const [searchFor, setSearchFor] = useState<string | undefined>(undefined)

    const [typedValue, setTypedValue] = useState<string | undefined>(undefined)

    // const [searchResultsInState, setSearchResultsInState] = useState<
    //     ISearchUsersResult[] | undefined
    // >(undefined)

    let resultsLoading: boolean = useAppSelector((state) => {
        return state.searchUsers.loading
    })

    let selectedUser: string[] = props.handoverFormData.owner.existing
        ? [props.handoverFormData.owner.existing]
        : []

    let searchResults: ISearchUsersResult[] | undefined = useAppSelector(
        (state) => {
            return state.searchUsers.list
        }
    )

    const [userSelectedData, setUserSelectedData] = useState<
        ISearchUsersResult | undefined
    >(undefined)

    // useEffect(() => {
    //     if (searchResults && searchFor) {
    //         let make_fuse = searchResults
    //             ? createSearchableIndex(searchResults, [
    //                   'display_name',
    //                   'family_name',
    //                   'given_name',
    //               ])
    //             : undefined

    //         let fusedSearchResults = make_fuse
    //             ? make_fuse
    //                   .search(searchFor, { limit: 5 })
    //                   .map((item) => item.item)
    //             : undefined

    //         setSearchResultsInState(fusedSearchResults)
    //     }
    // }, [searchResults])

    let { handoverFormData, handleSelectExistingOwner } = props

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const validateForm = async (id: string, value: string) => {
        try {
            if (id === 'email') {
                await getRightSchemaAccountData(id).validate({
                    item_edited: value,
                })
                setError((prevState) => {
                    return { ...prevState, email: undefined }
                })
            } else {
                await getRightSchemaAccountData(id).validate({ [id]: value })
                setError((prevState) => {
                    return { ...prevState, [id]: undefined }
                })
            }
        } catch (err: any) {
            setError((prevState) => {
                return { ...prevState, [id]: err.errors[0] }
            })
        }
    }

    return (
        <>
            {!props.isInviteActive ? (
                <>
                    <UsersSearchBar
                        // searchResults={searchResultsInState}
                        searchResults={searchResults}
                        onSearch={(value: string) => {
                            dispatch(checkUserEmailExistsRequest(value))
                            setSearchFor(value)
                        }}
                        searchFor={searchFor}
                        clearSearch={() => {
                            handleSelectExistingOwner({
                                id: undefined,
                                display_name: undefined,
                            })
                            setUserSelectedData(undefined)
                            setSearchFor(undefined)
                            setTypedValue(undefined)
                            dispatch(checkUserEmailExistsRequest(undefined))
                        }}
                        onSearchResultSelect={(user: ISearchUsersResult) => {
                            handleSelectExistingOwner({
                                id: user.uid,
                                display_name: user.display_name,
                            })
                            setUserSelectedData(user)
                        }}
                        onInputChange={(value: string) => {
                            setUserSelectedData(undefined)
                            setTypedValue(value)
                        }}
                        loadingPlaceholder={
                            resultsLoading ||
                            (typedValue && searchFor !== typedValue)
                                ? true
                                : false
                        }
                        placeholder={
                            props.handoverFormData.owner
                                .existing_display_name ??
                            `Enter new owner's email`
                        }
                        selectedUsers={selectedUser}
                        hasInviteOption={true}
                        onClickInvite={() => {
                            props.setIsInviteActive(true)

                            props.handleUserInviteValues('email', searchFor)
                        }}
                        inviteText="Invite to Custodian"
                        selectedUserData={userSelectedData}
                        ownEmailSearchCopy="You cannot handover to yourself."
                        backgroundColor={
                            isMobile
                                ? undefined
                                : 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        }
                    />
                </>
            ) : (
                <div style={{ width: '100%' }}>
                    <InputFieldNoFormikAnimated
                        name={'email'}
                        placeholder={''}
                        value={handoverFormData.owner.new.email}
                        id={'email'}
                        onChange={(e: any) => {
                            validateForm('email', e.target.value)
                            props.handleUserInviteValues(
                                'email',
                                e.target.value
                            )
                        }}
                        backgroundColor={
                            isMobile
                                ? undefined
                                : 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        }
                    />
                    <Expander
                        height={error && error['email'] ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>{error && error['email']}</ErrorText>
                    </Expander>
                    <div style={{ paddingTop: '20px' }} />

                    <InputFieldNoFormikAnimated
                        name={'first name'}
                        placeholder={''}
                        value={handoverFormData.owner.new.first_name}
                        id={'first_name'}
                        onChange={(e: any) => {
                            validateForm('name', e.target.value)
                            props.handleUserInviteValues(
                                'first_name',
                                e.target.value
                            )
                        }}
                        backgroundColor={
                            isMobile
                                ? undefined
                                : 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        }
                    />
                    <Expander
                        height={error && error['name'] ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>{error && error['name']}</ErrorText>
                    </Expander>
                    <div style={{ paddingTop: '20px' }} />
                    <InputFieldNoFormikAnimated
                        name={'last name'}
                        placeholder={''}
                        value={handoverFormData.owner.new.last_name}
                        id={'last_name'}
                        onChange={(e: any) => {
                            validateForm('last_name', e.target.value)
                            props.handleUserInviteValues(
                                'last_name',
                                e.target.value
                            )
                        }}
                        backgroundColor={
                            isMobile
                                ? undefined
                                : 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        }
                    />
                    <Expander
                        height={error && error['last_name'] ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>{error && error['last_name']}</ErrorText>
                    </Expander>
                    <div style={{ paddingTop: '10px' }} />

                    <IconAndTextNakedButton
                        onClick={() => {
                            props.setIsInviteActive(false)
                        }}
                        text="Cancel new invite"
                        icon="red_cross"
                        color="var(--error, #DF6F6F)"
                    />

                    <div style={{ paddingTop: '100px' }} />
                </div>
            )}
        </>
    )
}

export default CarHandoverOwnerForm
