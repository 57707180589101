import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
// import blank_user from '../../../public/assets/icons/profile_pic_placeholder.svg'
// import profile from "../../../public/assets/clegg/profile.png";
// import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'

type IStyleProps = {
    hasImage?: boolean
    $isShowroom?: boolean
}

const UserImgWrapper = styled.div<IStyleProps>`
    position: absolute;

    @media ${device.desktop} {
        border-radius: 50%;
        border: ${(props) =>
            props.hasImage ? 'solid 1px #5EC3CA' : 'solid 1px #ececec'};
        height: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        width: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-40px')};
    }

    @media ${device.smallest_laptops} {
        border-radius: 50%;
        border: ${(props) =>
            props.hasImage ? 'solid 1px #5EC3CA' : 'solid 1px #ececec'};
        height: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        width: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-50px')};
    }

    @media ${device.large_desktop} {
        height: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        width: ${(props) => (props.$isShowroom ? '64px' : '122px')};
        left: 0px;
        top: ${(props) => (props.$isShowroom ? 0 : '-50px')};
    }
`
const UserImg = styled.img<IStyleProps>`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 100px;

    @media ${device.desktop} {
        margin-right: 100px;
    }
    @media ${device.large_desktop} {
        margin-right: 100px;
    }

    @media ${device.smallest_laptops} {
        margin-right: 70px;
    }
`
type Props = {
    size?: string
    url?: any
    onClick?: any
    isShowroom?: boolean
}

type State = {
    src: any
    isLoading: boolean
}

class UserCircleProfilePage extends React.Component<Props, State> {
    state = {
        src: undefined,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src =
            this.props.url !== undefined &&
            this.props.url !== false &&
            this.props.url !== null &&
            this.props.url.length > 1
                ? this.props.url
                : undefined

        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props?.url !== prevProps?.url) {
            this.setState({ ...this.state, isLoading: true })
            if (!this.props.url) {
                this.setState({ src: null, isLoading: false })
            } else {
                const src =
                    this.props.url !== undefined &&
                    this.props.url !== false &&
                    this.props.url !== null &&
                    this.props.url.length > 1
                        ? this.props.url
                        : undefined

                if (src) {
                    return this.setState({ src: src, isLoading: false })
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <Wrapper
                style={{ cursor: this.props.onClick ? 'pointer' : 'auto' }}
            >
                <UserImgWrapper
                    hasImage={this.state.src !== undefined ? true : false}
                    $isShowroom={this.props.isShowroom}
                    onClick={() => this.props.onClick && this.props.onClick()}
                >
                    <UserImg
                        src={
                            this.state.src !== undefined
                                ? this.state.src
                                : blank_user
                        }
                        onLoad={() =>
                            this._isMounted &&
                            this.setState({
                                ...this.state,
                                isLoading: false,
                            })
                        }
                    />
                    <SkeletonAbsolute
                        isRound={true}
                        isthingloading={this.state.isLoading}
                    />
                </UserImgWrapper>
            </Wrapper>
        )
    }
}

export default UserCircleProfilePage
