import React from 'react'
// import debounce from "lodash/debounce";
import Fuse from 'fuse.js'
import MultipleSelectDesktop from './multipleSelectDesktop'

export type Props = {
    items: any
    item: any
    width?: string
    handleSelectChange?: any
    isDisabled?: boolean
}

// not sure why now threw error. Removed specifications and error free.
// keeping this here in case .

// export type ItemsFuse = {
//   id: string;
//   value: string;
// };

// const options: Fuse.FuseOptions<ItemsFuse> = {
//   tokenize: true,
//   keys: ["value"]
// };

type LocalState = {
    data: any[]
    name: any[]
    fetching: boolean
}

class MultipleSelectSearchBarCarInfoDesktop extends React.Component<
    Props,
    LocalState
> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        // this.fetchItem = debounce(this.fetchItem, 50);
    }

    state = {
        data: [],
        name: [],
        fetching: false,
    }

    fuse = new Fuse(this.props.items, {
        keys: ['name'],
    })

    lastFetchId = 0

    fetchItem = (name: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
        }

        const results = this.fuse.search(name)

        return this.setState({ data: results, fetching: false })
    }

    handleChange = (name: any) => {
        this.setState({
            ...this.state,
            name: [...this.state.name, name],
            data: [],
            fetching: false,
        })
        this.props.handleSelectChange && this.props.handleSelectChange(name)
    }

    render() {
        const { fetching, name, data } = this.state
        const { item, width, isDisabled } = this.props

        return (
            <MultipleSelectDesktop
                fetching={fetching}
                value={name}
                data={data}
                fetchItem={this.fetchItem}
                handleChange={this.handleChange}
                optionsList={this.props.items}
                item={item}
                width={width && width}
                isDisabled={isDisabled && isDisabled}
            />
        )
    }
}

export default MultipleSelectSearchBarCarInfoDesktop
