import application_details_submit from './application/application_details_submit'
import insurance_create_user_application from './application/create'
import user_address_submit from './application/createOrUpdateUserAddress'
import insurance_delete_user_application from './application/delete'
import everydaycar_ids_submit from './application/everydaycar_ids_submit'
import insurance_get_application from './application/get'
import insurance_get_all_user_applications from './application/getAll'
import insuranceAddresses_submit from './application/insuranceAddresses_submit'
import application_submit from './application/submit_application'
import insurance_validate_application from './application/validateApplication'
import vehicles_to_insure_submit from './application/vehicles_to_insure_submit'
import car_mileageParking_submit from './car/carMileageParking_submit'
import car_external_creation from './car/car_external_creation'
import car_ownership_submit from './car/car_ownership_submit'
import car_techSpecifications_submit from './car/car_techSpecifications_submit'
import car_everyday_bool_submit from './car/everyday_bool_submit'
import get_insurance_external_car_data_by_registration_number from './car/registration_number_external_vehicle'
import maindriver_additionaldetails_submit from './maindriver/maindriver_additionaldetails_submit'
import maindriver_basicDetails_submit from './maindriver/maindriver_basicdetails_submit'
import maindriver_licenceAddresses_submit from './maindriver/maindriver_licenceAddress_submit'
import maindriver_trackRecord_submit from './maindriver/maindriver_trackrecord_submit'
import maindriver_updateResidential_address_submit from './maindriver/updateUserResidentialAddress'
import otherDriver_deletion from './otherdriver/delete_other_driver'
import otherDriver_drive_list from './otherdriver/drivelist_patch_delete_otherdriver'
import manage_drivers_submit from './otherdriver/manage_drivers'
import otherdriver_additionaldetails_submit from './otherdriver/otherdriver_additionaldetails_submit'
import otherdriver_basicDetails_submit from './otherdriver/otherdriver_basicdetails_submit'
import otherdriver_licenceAddress__submit from './otherdriver/otherdriver_licenceAddress_submit'
import otherDriver_trackRecord_submit from './otherdriver/otherdriver_trackrecord_submit'

let arr: any[] = [
    ...maindriver_additionaldetails_submit,
    ...otherdriver_additionaldetails_submit,

    ...maindriver_basicDetails_submit,
    ...otherdriver_basicDetails_submit,

    ...maindriver_trackRecord_submit,
    ...otherDriver_trackRecord_submit,

    ...maindriver_licenceAddresses_submit,
    ...otherdriver_licenceAddress__submit,

    ...application_details_submit,
    ...vehicles_to_insure_submit,

    ...insuranceAddresses_submit,

    ...car_mileageParking_submit,
    ...car_ownership_submit,
    ...car_techSpecifications_submit,

    ...manage_drivers_submit,
    ...otherDriver_drive_list,

    ...insurance_get_application,
    ...insurance_get_all_user_applications,

    ...insurance_create_user_application,
    ...insurance_delete_user_application,

    ...car_everyday_bool_submit,

    ...insurance_validate_application,
    ...everydaycar_ids_submit,

    ...car_external_creation,

    ...get_insurance_external_car_data_by_registration_number,
    ...otherDriver_deletion,

    ...application_submit,

    ...maindriver_updateResidential_address_submit,
    ...user_address_submit,
]

export default arr
