import React from 'react'
import styled from 'styled-components'
import ScrollLock from 'react-scrolllock'
import ShareErrorManager from '../../organisms/shareErrorManager/shareErrorManager'

const Wrapper = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: 10;
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
`

interface Props {
    error_is: string | undefined
    entity: 'car' | 'garage'
    onClick: any
}

interface State {}

export class SharedWithYouEntityError extends React.Component<Props, State> {
    state = {}

    render() {
        return (
            <Wrapper>
                <ScrollLock />
                <ShareErrorManager
                    error_is={this.props.error_is}
                    entity={this.props.entity}
                    onClick={this.props.onClick}
                />
            </Wrapper>
        )
    }
}

export default SharedWithYouEntityError
