import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

type IStyle = {
    $activeColour?: string
    $passiveColour?: string
    $isFinishedOrActive?: boolean
}

interface FormStepProgressProps {
    /**
     * index starts at 1.
     */
    currentStep: number
    /**
     * index starts at 1.
     */
    totalSteps: number
    screen_name: 'mobile' | 'desktop'
    activeColour?: string
    passiveColour?: string
}

export default function FormStepProgress(props: FormStepProgressProps) {
    return (
        <Steps>
            {[...Array(props.totalSteps)].map((_, index) => (
                <Step
                    key={`form_step_progress_${index}_${props.screen_name}`}
                    $isFinishedOrActive={index + 1 <= props.currentStep}
                    $activeColour={props.activeColour}
                    $passiveColour={props.passiveColour}
                />
            ))}
        </Steps>
    )
}

const Step = styled.div<IStyle>`
    height: 4px;
    background-color: ${({
        $isFinishedOrActive,
        $activeColour,
        $passiveColour,
    }) =>
        $isFinishedOrActive
            ? $activeColour ?? '#5EC3CA'
            : $passiveColour ?? '#E0E0E0'};
    flex: 1;
    border-radius: 4px;

    @media ${device.beyond_ipad_mobile} {
        height: 6px;
    }
`

const Steps = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
`
