/// <reference types="googlemaps" />
import * as React from 'react'
// import ButtonAtom from "../../../../atoms/Button/ButtonAtom";
import styled from 'styled-components'
import { IInsuranceDropdownItem } from '../../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import Expander from '../../../../atoms/expander/expander'
// import InputField from "../../../../atoms/Inputfield/inputField";

import InputFieldNoFormik from '../../../../atoms/Inputfield/inputFieldNoFormik'
import { Select } from '../../../../atoms/select'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import SelectSearchBarCarInfoMobile from '../../technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'

const EditFormContainerDesktop = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-bottom: 30px;
    padding-top: 20px;
    height: auto;
    background-color: none;
    color: black;
    border-radius: 5px;
    position: relative;
    margin-bottom: 50px;
`

const MobileAddressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`
const DesktopAddressSubContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
`

const SearchField = styled.input`
    background-color: var(--off-bg-color, #fafafa);
    height: 45px;
    border-radius: 5px;
    border: solid 1px var(--border-muted, #e5e5e5);
    width: 80vw;
    margin-left: 10px;
    padding-left: 15px;
    z-index: 1000;
`

export const ErrorText = styled.div`
    width: 100%;
    padding-top: 4px;
    font-size: 12px;
    color: #df6f6f;
    font-style: italic;
`

type Props = {
    street_number: any
    postal_town: any
    route: any
    postal_code: any
    country: any
    onSubmit: any
    handleChange: any
    isFormValid: any
    toggle: any
    countriesdropdownlist: any
    handleSelectCountry: any
    administrative_area_level_1?: string
    countryUpdatedFromSearchedAddress?: string | null
    error: { [key: string]: string | undefined } | undefined
    handleSelectAdmin1Code: (code: string) => any
    usaAdminCodes: IInsuranceDropdownItem[] | null
}

type State = {
    isAdminLevel1Opened: boolean
}

class AccountAddressFormMobile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            isAdminLevel1Opened: false,
        }
    }
    // STEPS:

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.

    render() {
        const {
            street_number,
            route,
            postal_town,
            postal_code,
            country,
            onSubmit,
            handleChange,
            isFormValid,
            toggle,
            countriesdropdownlist,
            handleSelectCountry,
            administrative_area_level_1,
            countryUpdatedFromSearchedAddress,
            error,
            handleSelectAdmin1Code,
        } = this.props

        const { isAdminLevel1Opened } = this.state

        return (
            <EditFormContainerDesktop>
                <InnerFormPadding>
                    <SearchField
                        id="autocompletemobileaccountaddress"
                        placeholder="Search your address"
                        type="text"
                    />

                    <MobileAddressContainer id="address-search-mobile">
                        <DesktopAddressSubContainer>
                            <InputFieldNoFormik
                                name="street_number"
                                type="text"
                                placeholder="Street number  /  House name"
                                value={street_number}
                                onChange={handleChange}
                                tabindex={1}
                                isNarrow={true}
                                onBlur={(e: any) => e.preventDefault()}
                            />
                            <Expander
                                height={
                                    error && error['street_number'] ? 'auto' : 0
                                }
                                width={'100%'}
                            >
                                <ErrorText>
                                    {error && error['street_number']}
                                </ErrorText>
                            </Expander>
                        </DesktopAddressSubContainer>

                        <DesktopAddressSubContainer>
                            <InputFieldNoFormik
                                type="text"
                                name="route"
                                placeholder="Street address"
                                value={route}
                                onChange={handleChange}
                                tabindex={2}
                            />
                            <Expander
                                height={error && error['route'] ? 'auto' : 0}
                                width={'100%'}
                            >
                                <ErrorText>{error && error['route']}</ErrorText>
                            </Expander>
                        </DesktopAddressSubContainer>
                        <DesktopAddressSubContainer>
                            <InputFieldNoFormik
                                type="text"
                                name="postal_town"
                                placeholder="city"
                                value={postal_town}
                                onChange={handleChange}
                                isNarrow={true}
                                tabindex={3}
                            />
                            <Expander
                                height={
                                    error && error['postal_town'] ? 'auto' : 0
                                }
                                width={'100%'}
                            >
                                <ErrorText>
                                    {error && error['postal_town']}
                                </ErrorText>
                            </Expander>
                        </DesktopAddressSubContainer>

                        <DesktopAddressSubContainer>
                            <InputFieldNoFormik
                                type="text"
                                placeholder="zip code"
                                name="postal_code"
                                value={postal_code}
                                onChange={handleChange}
                                isNarrow={true}
                                tabindex={4}
                            />
                            <Expander
                                height={
                                    error && error['postal_code'] ? 'auto' : 0
                                }
                                width={'100%'}
                            >
                                <ErrorText>
                                    {error && error['postal_code']}
                                </ErrorText>
                            </Expander>
                        </DesktopAddressSubContainer>

                        <Expander
                            height={country === 'United States' ? 'auto' : 0}
                        >
                            <DesktopAddressSubContainer
                                style={{ marginTop: '40px' }}
                            >
                                <Select
                                    value={administrative_area_level_1}
                                    options={this.props.usaAdminCodes ?? []}
                                    defaultValue={administrative_area_level_1}
                                    placeholder={'Administrative Area Code'}
                                    disabled={false}
                                    onChange={(val: string) => {
                                        handleSelectAdmin1Code(val)
                                    }}
                                    onFocusToggle={(p: boolean) => {
                                        this.setState({
                                            isAdminLevel1Opened: p,
                                        })
                                    }}
                                />

                                {/* <InputFieldNoFormik
                                    type="text"
                                    placeholder="admninistrative area"
                                    name="administrative_area_level_1"
                                    value={administrative_area_level_1}
                                    onChange={handleChange}
                                    isNarrow={true}
                                    tabindex={5}
                                /> */}
                            </DesktopAddressSubContainer>
                        </Expander>

                        <DesktopAddressSubContainer
                            style={{
                                zIndex: isAdminLevel1Opened ? -1 : 'auto',
                            }}
                        >
                            <div style={{ paddingTop: '16px' }} />
                            <SelectSearchBarCarInfoMobile
                                items={countriesdropdownlist}
                                item={{
                                    text: 'countries',
                                    answer: country ? country : null,
                                }}
                                handleSelectChange={handleSelectCountry}
                                valueUpdatedFromExternalSource={
                                    countryUpdatedFromSearchedAddress
                                        ? countryUpdatedFromSearchedAddress
                                        : null
                                }
                            />
                            <Expander
                                height={error && error['country'] ? 'auto' : 0}
                                width={'100%'}
                            >
                                <ErrorText>
                                    {error && error['country']}
                                </ErrorText>
                            </Expander>
                        </DesktopAddressSubContainer>
                    </MobileAddressContainer>

                    <div
                        style={{
                            marginTop: '50px',
                            opacity: isFormValid() === false ? 0.5 : 1,
                        }}
                    >
                        <ButtonsStandardBottomRowDesktop
                            cancel={toggle}
                            submit={onSubmit}
                            isDisabled={isFormValid() === false ? true : false}
                            secondText="update"
                        />
                    </div>
                </InnerFormPadding>
            </EditFormContainerDesktop>
        )
    }
}

export default AccountAddressFormMobile
