import { IUser } from 'myModels'
import posthog from 'posthog-js'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ITimelineItem } from 'timelineModels'

import insurance_skeleton_desktop from '../../../public/assets/placeholder_images/insurance_skeleton_desktop.svg'
import insurance_skeleton_desktop_dark from '../../../public/assets/placeholder_images/insurance_skeleton_desktop_dark.png'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { device } from '../../templates/displays/devices'
import { IRootState } from '../../../redux/store'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    carid: string
    insurance_timeline_item: ITimelineItem | undefined | null
    hasExpired: boolean
    isWithin30Days: boolean
}

type IStyle = {
    $theme: ITheme
}

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 40px;
    flex-direction: row;
`

const RowEnd = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    width: 100%;
    gap: 24px;
`

const Row0 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
`

const Col = styled.div<IStyle>`
    @media ${device.large_desktop} {
    }
`
const CardWrapper = styled.div<IStyle>`
    display: flex;
    width: 100%;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 8px;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
`
const Line = styled.div<IStyle>`
    width: 100%;
    height: 1px;
    background: ${(props) => colours[props.$theme].border_muted};
`
const Title = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.001px;
`
const Answer = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
`
const Name = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

export const UI_insurance_entry_card_arr = [
    {
        id: 'insurance_entry_policy_start_date',
        name: 'Start Date',
    },
    {
        id: 'insurance_entry_policy_end_date',
        name: 'End Date',
    },
    {
        id: 'insurance_entry_underwriter',
        name: 'Insurer',
    },
    {
        id: 'insurance_entry_broker',
        name: 'Broker',
    },
    {
        id: 'total_cost_amount_display',
        name: 'Insurance cost',
    },
]
export const CarInsuranceSectionBodyDesktop = (props: Props) => {
    let { insurance_timeline_item, hasExpired, isWithin30Days } = props
    let userLoggedIn: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const dispatch = useAppDispatch()

    let insuranceClick = () => {
        // window.scrollTo(0, 0)
        dispatch(
            insuranceActions.get_user_insurance_applications_request({
                withNavigation: true,
                withAllInfo: true,
            })
        )
    }

    let userCurrency =
        userLoggedIn?.preferences.data.units.data.default_currency
            .user_choice_id

    let card_title = !userLoggedIn
        ? ''
        : `${userLoggedIn.display_name}'s insurance policy`

    let history = useHistory()

    let isInsuranceLoading = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    const { theme } = useThemes()

    return (
        <div style={{ width: '100%' }}>
            {!insurance_timeline_item && (
                <img
                    style={{ width: '100%' }}
                    src={
                        theme === 'dark'
                            ? insurance_skeleton_desktop_dark
                            : insurance_skeleton_desktop
                    }
                />
            )}

            {insurance_timeline_item && (
                <CardWrapper $theme={theme}>
                    <Row0>
                        <Title $theme={theme}>{card_title}</Title>
                        <ButtonAtom
                            onClick={() => {
                                posthog.capture(
                                    'insurance entry view click from car insurance section'
                                )
                                history.push(
                                    `/car/${props.carid}/history-file/entry?entryid=${insurance_timeline_item?.id}`
                                )
                            }}
                            theme="naked-add-text"
                        >
                            See entry
                        </ButtonAtom>
                    </Row0>

                    <Line $theme={theme} />

                    <Row>
                        {UI_insurance_entry_card_arr.map((item) => {
                            let valuee =
                                (insurance_timeline_item &&
                                    insurance_timeline_item[item.id]) ??
                                undefined

                            if (
                                item.id ===
                                    'insurance_entry_policy_start_date' ||
                                item.id === 'insurance_entry_policy_end_date'
                            ) {
                                if (valuee) {
                                    let newVal = fromIsoStringToDDMMYYY(valuee)

                                    valuee = newVal
                                }
                            }

                            return (
                                <Col key={`${item.id}_desktop`} $theme={theme}>
                                    <Name $theme={theme}>{item.name}</Name>
                                    <Answer $theme={theme}>
                                        {item.id !== 'total_cost_amount_display'
                                            ? null
                                            : insurance_timeline_item &&
                                              insurance_timeline_item[
                                                  item.id
                                              ] &&
                                              userCurrency
                                            ? userCurrency === 'GBP'
                                                ? '£'
                                                : userCurrency === 'USD'
                                                ? '$'
                                                : userCurrency === 'EUR'
                                                ? '€'
                                                : '£'
                                            : '£'}
                                        {valuee ?? '-'}
                                    </Answer>
                                </Col>
                            )
                        })}
                    </Row>
                </CardWrapper>
            )}

            {!insurance_timeline_item && (
                <>
                    <div
                        style={{
                            paddingTop: '32px',
                        }}
                    />

                    <RowEnd>
                        <ButtonAtom
                            fontSize={14}
                            width={'180px'}
                            theme="lowercase-white-background"
                            onClick={() => {
                                posthog.capture(
                                    'create_insurance_entry clicked from empty car insurance section'
                                )
                                history.push(
                                    `/car/${props.carid}/history-file/create/insurance`
                                )
                            }}
                        >
                            <div
                                style={{
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Add a History File Entry
                            </div>
                        </ButtonAtom>

                        <ButtonAtom
                            fontSize={14}
                            theme="lowercase-blue-background"
                            onClick={() => {
                                posthog.capture(
                                    'get_custodian_insurance clicked from empty car insurance section'
                                )
                                insuranceClick()
                            }}
                        >
                            {isInsuranceLoading ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 144,
                                    }}
                                >
                                    <LoaderElasticThreeDots />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Get Custodian Insurance
                                </div>
                            )}
                        </ButtonAtom>
                    </RowEnd>
                </>
            )}

            {insurance_timeline_item && hasExpired === true && (
                <>
                    <div
                        style={{
                            paddingTop: '32px',
                        }}
                    />

                    <RowEnd>
                        <ButtonAtom
                            fontSize={14}
                            width={'180px'}
                            theme="lowercase-white-background"
                            onClick={() => {
                                posthog.capture(
                                    'create_insurance_entry clicked from expired car insurance section'
                                )
                                history.push(
                                    `/car/${props.carid}/history-file/create/insurance`
                                )
                            }}
                        >
                            <div
                                style={{
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Add a History File Entry
                            </div>
                        </ButtonAtom>

                        <ButtonAtom
                            fontSize={14}
                            theme="lowercase-blue-background"
                            onClick={() => {
                                posthog.capture(
                                    'get_custodian_insurance clicked from expired car insurance section'
                                )
                                insuranceClick()
                            }}
                        >
                            {isInsuranceLoading ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 144,
                                    }}
                                >
                                    <LoaderElasticThreeDots />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Get Custodian Insurance
                                </div>
                            )}
                        </ButtonAtom>
                    </RowEnd>
                </>
            )}

            {insurance_timeline_item &&
                isWithin30Days === true &&
                !hasExpired && (
                    <>
                        <div
                            style={{
                                paddingTop: '32px',
                            }}
                        />

                        <RowEnd>
                            <ButtonAtom
                                fontSize={14}
                                theme="lowercase-blue-background"
                                onClick={() => {
                                    posthog.capture(
                                        'get_custodian_insurance clicked from renewable car insurance section'
                                    )
                                    insuranceClick()
                                }}
                            >
                                {isInsuranceLoading ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 144,
                                        }}
                                    >
                                        <LoaderElasticThreeDots />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Get Custodian Insurance
                                    </div>
                                )}
                            </ButtonAtom>
                        </RowEnd>
                    </>
                )}
        </div>
    )
}
