import JourneyBottomActionBarMobile from '../../../../../templates/bars/journey/bottomActionBarMobile'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../../../providers/theme/hooks'
import { useState } from 'react'
import colours from '../../../../../../providers/theme/colours'
import Faded from '../../../../../templates/animated/faded'
import {
    JourneyColumnContent,
    JourneyStepTitle,
    JourneyText,
} from '../../../../../templates/styledcomponents/journeyStyles'
import { ITimelineItem } from 'timelineModels'
import TimelineItemMobileJourneyCard from '../../../../../molecules/timelineItemDesktop/mobileJourneyCard'
import { BottomSheetMobileHistoryFileJourney } from './bottomSheetMobile'
import { JourneyHFEntriesForm } from './entriesFormBody'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void

    entry1: ITimelineItem | undefined
    entry2: ITimelineItem | undefined
    entry3: ITimelineItem | undefined
    setEntry1: (p: ITimelineItem | undefined) => any
    setEntry2: (p: ITimelineItem | undefined) => any
    setEntry3: (p: ITimelineItem | undefined) => any
}

const SectionLeft = () => {
    const { theme } = useThemes()
    return (
        <JourneyColumnContent>
            <JourneyText $theme={theme}>
                Let's get started by adding three entries to your history file!
                As mentioned you can add as many entries as you like later on.
                Custodian has no limitations on how many history file entries
                can be stored per vehicle.
            </JourneyText>
        </JourneyColumnContent>
    )
}
export default function AddHistoryFileJourneyStepMobile({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    entry1,
    setEntry1,
    entry2,
    setEntry2,
    entry3,
    setEntry3,
}: Props) {
    const { theme } = useThemes()

    const [isOpen, setIsOpen] = useState<number | null>(null)

    return (
        <IpadAndMobileDisplay>
            <BottomSheetMobileHistoryFileJourney
                isOpen={isOpen === null ? false : true}
                close={() => {
                    setIsOpen(null)
                }}
                onConfirm={() => {}}
                title={`#${isOpen} History file entry details`}
                customHeight={'480px'}
            >
                <JourneyColumnContent
                    style={{ gap: 24, paddingLeft: 16, paddingRight: 16 }}
                >
                    <JourneyHFEntriesForm
                        entry={
                            isOpen === 1
                                ? entry1
                                : isOpen === 2
                                ? entry2
                                : isOpen === 3
                                ? entry3
                                : undefined
                        }
                        setEntry={
                            isOpen === 1
                                ? setEntry1
                                : isOpen === 2
                                ? setEntry2
                                : isOpen === 3
                                ? setEntry3
                                : () => {}
                        }
                        isMobile
                    />
                </JourneyColumnContent>
            </BottomSheetMobileHistoryFileJourney>
            <Faded>
                <div
                    style={{
                        paddingLeft: 24,
                        paddingRight: 24,
                        paddingBottom: 200,
                        paddingTop: 48,
                        overflow: 'hidden',
                    }}
                >
                    <JourneyColumnContent style={{ gap: 40 }}>
                        <JourneyColumnContent style={{ gap: 24 }}>
                            <JourneyColumnContent style={{ gap: 4 }}>
                                <JourneyStepTitle
                                    $theme={theme}
                                    style={{ paddingRight: '40px' }}
                                >
                                    As easy as 1, 2, 3
                                </JourneyStepTitle>
                                <JourneyText
                                    style={{
                                        fontSize: 14,
                                        color: colours[theme].text_muted,
                                    }}
                                    $theme={theme}
                                >
                                    Bring your car's history online
                                </JourneyText>
                            </JourneyColumnContent>

                            <SectionLeft />
                            <div />
                            <JourneyColumnContent
                                style={{
                                    gap: 16,
                                    alignSelf: 'center',
                                    width: '100%',
                                }}
                            >
                                <TimelineItemMobileJourneyCard
                                    userCurrency={
                                        entry1?.costItemsObj &&
                                        entry1.costItemsObj['id'].currency
                                            ? entry1.costItemsObj['id'].currency
                                            : '£'
                                    }
                                    entry={entry1}
                                    onClick={() => {
                                        setIsOpen(1)
                                    }}
                                    key={'1'}
                                />

                                <TimelineItemMobileJourneyCard
                                    userCurrency={
                                        entry2?.costItemsObj &&
                                        entry2.costItemsObj['id'].currency
                                            ? entry2.costItemsObj['id'].currency
                                            : '£'
                                    }
                                    entry={entry2}
                                    onClick={() => {
                                        setIsOpen(2)
                                    }}
                                    key={'2'}
                                />

                                <TimelineItemMobileJourneyCard
                                    userCurrency={
                                        entry3?.costItemsObj &&
                                        entry3.costItemsObj['id'].currency
                                            ? entry3.costItemsObj['id'].currency
                                            : '£'
                                    }
                                    entry={entry3}
                                    onClick={() => {
                                        setIsOpen(3)
                                    }}
                                    key={'3'}
                                />
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </JourneyColumnContent>
                </div>
                <JourneyBottomActionBarMobile
                    sectionID="history_file"
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={goToPrevStep}
                    onNext={() => {
                        goToNextStep()
                    }}
                />
            </Faded>
        </IpadAndMobileDisplay>
    )
}
