import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'
import { Button } from 'antd'
import colours, { ITheme } from '../../../providers/theme/colours'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

export interface IButtonProps {
    children?: React.ReactNode
    onClick?: any
    icon?: string
    orientation?: string
    theme:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'naked-icon'
        | 'naked-light'
        | 'naked-text'
        | 'naked-add-text'
        | 'naked-text-arrow'
        | 'naked-bold'
        | 'naked'
        | 'smaller-blue-on-white'
        | 'lowercase-white-background'
        | 'lowercase-white-red-background'
        | 'lowercase-white-background-smaller'
        | 'lowercase-red-background'
        | 'lowercase-blue-background'
        | 'uppercase-blue-background'
        | 'uppercase-white-background'
        | 'capitalize-white-background'
        | 'flexible-transparent-blue-border'
        | 'outline-transparent-background'
        | 'add-text'
        | 'crop_img_circle'
        | 'bin_img_circle'
        | 'img-actions'
        | 'flexible-blue-on-white'
        | 'main-cta'
        | 'main-cta-onboarding'
        | 'main-cta-onboarding-white'
        | 'help-quick-access'
        | 'wide-mobile-blue-bg'
        | 'modal-action-button-primary'
        | 'modal-action-button-red'
        | 'modal-action-button-secondary'
        | 'modal-action-button-desctructive'
        | 'add-icon-primary-text'
        | 'edit-icon-primary-text'

    action?: string
    readonly divider?: any
    readonly disabled?: boolean
    type?: 'button' | 'submit' | 'reset' | undefined
    wider?: boolean
    color?: string
    alignLeft?: boolean
    width?: string
    height?: string
    onKeyDown?: any
    fontSize?: number
    borderRadius?: number
    dataCyId?: string
    dataPH?: string
    textTransform?: string
    noIpadMinWith?: boolean
    customBgColor?: string
}

export interface IButtonStyledProps {
    uiTheme: ITheme
    readonly disabled?: boolean
    icon?: boolean
    wider?: boolean
    alignLeft?: string
    width?: string
    height?: string
    fontSize?: number
    borderRadius?: number
    textTransform?: string
    customBgColor?: string
    color?: string
}

// const Basics = styled.button<IButtonStyledProps>`
//   cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

//   transition: all 0.1s;
//   :focus {
//     outline: 0;
//   }

//   box-shadow: ${(props) =>
//`2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
//   transition: all 200ms;
//   :hover {
//     box-shadow: ${(props) =>
// `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
//     transition: all 200ms;
//   }
// `;

type ImgActionsProps = {
    uiTheme: ITheme
    action?: string
    height?: string
    width?: string
    fontSize?: number
    hasIcon?: boolean
    noIpadMinWith?: boolean
    textTransform?: string
}
const BackgroundImgActions = styled.button<ImgActionsProps>`
    background-color: ${(props) =>
        props.action === 'delete_img' ? colours[props.uiTheme].error : 'white'};
    border: ${(props) =>
        props.action === 'delete_img'
            ? 'none'
            : `1px solid ${colours[props.uiTheme].primary} `};

    border-radius: ${(props) =>
        props.action === 'delete_img' ? '25px' : '5px'};
    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    :focus {
        outline: 0;
    }

    height: ${(props) => (props.height ? props.height : 'auto')};
    width: ${(props) => (props.width ? props.width : 'auto')};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @media ${device.mobile} {
    }
    transition: all 200ms;
    cursor: pointer;

    :hover {
        background-color: ${(props) =>
            props.action === 'delete_img' ? 'red' : 'white'};
    }
`

const AddText = styled.button<IButtonStyledProps>`
    cursor: pointer;
    text-transform: lowercase;
    background-color: ${(props) => colours[props.uiTheme].primary};
    width: 160px;
    border-radius: 5px;
    border: none;
    color: ${(props) => colours[props.uiTheme].background_default};
    font-family: Lato-Bold;
    font-size: 16px;
    height: 36px;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 20px;
    align-items: center;
`

const WideMobileBlueBg = styled.button<IButtonStyledProps>`
    cursor: pointer;
    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].primary};
    border-radius: 5px;
    border: none;
    color: ${(props) => colours[props.uiTheme].background_default};
    font-family: Lato;
    font-size: 16px;
    height: ${(props) => (props.height ? props.height : '45px')};
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 20px;
    align-items: center;
    width: ${(props) => (props.width ? props.width : '100%')};
`

const LowercaseWhiteBackground = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: lowercase;

    background-color: ${(props) => colours[props.uiTheme].background_default};
    width: ${(props) => (props.width ? props.width : '120px')};
    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: 5px;

    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};

    font-family: Lato;

    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};

    align-items: center;
`
const LowercaseTransparentBackground = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.03);
    transition: all 200ms;
    :hover {
        box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.09);
        transition: all 200ms;
    }
    text-transform: lowercase;

    background-color: transparent;
    width: ${(props) => (props.width ? props.width : '120px')};
    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: 5px;

    color: ${(props) => (props.disabled ? '#afafaf' : '#5EC3CA')};

    border: ${(props) =>
        props.disabled ? '1px solid #afafaf' : '1px solid #5EC3CA'};

    font-family: Lato;

    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};

    align-items: center;
`
const ModalActionSecondary = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    :hover {
        ${(props) =>
            props.disabled
                ? ''
                : `outline:  1px solid ${colours[props.uiTheme].primary};`}
    }
    background-color: transparent;
    border-radius: 5px;
    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};
    font-family: Lato;
    font-size: 16px;
    text-transform: capitalize;
    padding-left: 16px;
    padding-right: 16px;
    height: ${(props) => (props.height ? props.height : '40px')};

    @media ${device.beyond_ipad_mobile} {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media ${device.mobile} {
        font-size: 14px;
    }

    @media (max-width: 330px) {
        height: 32px;
    }
`

const ModalActionPrimary = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    :hover {
        ${(props) =>
            props.disabled
                ? ''
                : `background-color: ${colours[props.uiTheme].primary_strong}`}
    }
    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].primary};
    border-radius: 5px;
    color: ${(props) => colours[props.uiTheme].background_default};
    border: none;
    font-family: Lato;
    font-size: 16px;
    text-transform: ${(props) =>
        props.textTransform ? props.textTransform : 'capitalize'};
    height: ${(props) => (props.height ? props.height : '40px')};
    padding-left: 16px;
    padding-right: 16px;
    width: ${(props) => (props.width ? props.width : 'auto')};

    @media ${device.beyond_ipad_mobile} {
        padding-left: 20px;
        padding-right: 20px;
    }

    @media ${device.mobile} {
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '14px'};
    }

    @media (max-width: 330px) {
        height: ${(props) => (props.height ? props.height : '32px')};
    }
`

const ModalActionRed = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    :hover {
        ${(props) =>
            props.disabled
                ? ''
                : `box-shadow: 2px 4px 7px ${
                      colours[props.uiTheme].shadow_strongest_09
                  }`}
    }
    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].error};
    border-radius: 5px;
    color: ${(props) => colours[props.uiTheme].background_default};
    border: none;
    font-family: Lato;
    font-size: 16px;
    text-transform: ${(props) =>
        props.textTransform ? props.textTransform : 'capitalize'};
    height: ${(props) => (props.height ? props.height : '40px')};
    padding-left: 16px;
    padding-right: 16px;
    width: ${(props) => (props.width ? props.width : 'auto')};

    @media ${device.mobile} {
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '14px'};
    }

    @media (max-width: 330px) {
        height: ${(props) => (props.height ? props.height : '32px')};
    }
`

const ModalActionDesctructive = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    :hover {
        ${(props) =>
            props.disabled
                ? ''
                : `box-shadow: 2px 4px 7px ${
                      colours[props.uiTheme].shadow_strongest_09
                  }`}
    }
    background-color: ${(props) => colours[props.uiTheme].background_default};
    border-radius: 5px;
    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].error};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].error}`};
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;

    flex-shrink: 0;
    text-transform: capitalize;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;

    @media ${device.mobile} {
        font-size: 14px;
    }

    @media (max-width: 330px) {
        height: 32px;
    }
`

const UppercaseWhiteBackground = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    font-weight: 500;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: uppercase;
    background-color: ${(props) => props.customBgColor ?? 'transparent'};

    width: ${(props) => (props.width ? props.width : '120px')};
    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: ${(props) =>
        props.borderRadius ? `${props.borderRadius}px !important` : '4px'};

    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : props.color ?? colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};

    font-family: Lato;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '13px')};
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};

    align-items: center;

    @media (min-width: 500px) and (max-width: 750px) {
        width: ${(props) => (props.width ? props.width : '175px')};
    }

    @media (min-width: 750px) and (max-width: 881px) {
        width: ${(props) => (props.width ? props.width : '300px')};
    }
`

const HelpQuickAccessButton = styled(UppercaseWhiteBackground)`
    width: 140px;
`

const CapitalizeWhiteBackground = styled(UppercaseWhiteBackground)`
    text-transform: capitalize;
`

const FlexibleWhiteOnBlueButton = styled.button<ImgActionsProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: capitalize;
    background-color: ${(props) => colours[props.uiTheme].background_default};
    width: ${(props) => (props.width ? props.width : '120px')};

    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: 5px;

    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};

    font-family: Lato;

    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 30px;

    align-items: center;

    @media ${device.mobile} {
        padding-left: 5vw;
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '12px'};
    }

    @media ${device.ipad} {
        height: 50px;
        font-size: 16px;
        min-width: 175px;
    }

    @media screen and (max-width: 325px) {
        padding-left: 3vw;
        font-size: 12px;
        max-width: 42vw !important;
    }
`

const FlexibleTransparentBlueBorder = styled.button<ImgActionsProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }
    width: ${(props) => (props.width ? props.width : 'max-content')};
    padding-left: 16px;
    padding-right: 16px;
    :hover {
        box-shadow: none;
        border-width: 2px;
        background-color: ${(props) =>
            colours[props.uiTheme].background_primary_soft};
        transition: border-width 50ms;
        transition: background-color 200ms;
    }

    background-color: transparent;
    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: 4px;
    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};

    font-family: Lato;
    font-weight: 500;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;

    @media ${device.mobile} {
        border: ${(props) =>
            props.disabled
                ? `1px solid ${colours[props.uiTheme].border_neutral}`
                : `1px solid ${colours[props.uiTheme].primary}`};
        font-family: Lato;
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '12px'};
    }

    @media ${device.ipad} {
        ${(props) => !props.noIpadMinWith && 'min-width: 175px'};
    }

    @media screen and (max-width: 325px) {
        ${(props) => !props.noIpadMinWith && 'min-width: 42vw'};
    }
`

const FlexibleWhiteOnRedButton = styled.button<ImgActionsProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: capitalize;
    background-color: ${(props) => colours[props.uiTheme].background_default};
    width: ${(props) => (props.width ? props.width : '120px')};

    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: 5px;

    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].error};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].error}`};

    font-family: Lato-Light;

    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};

    display: flex;

    flex-direction: row;
    justify-content: center;

    text-align: center;
    align-items: center;

    @media ${device.mobile} {
        border: ${(props) =>
            props.disabled
                ? `1px solid ${colours[props.uiTheme].border_neutral}`
                : `1px solid ${colours[props.uiTheme].error}`};
        font-family: Lato;
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '12px'};
    }

    @media ${device.ipad} {
        ${(props) => !props.noIpadMinWith && 'min-width: 175px'};
    }

    @media screen and (max-width: 325px) {
        ${(props) => !props.noIpadMinWith && 'min-width: 42vw'};
    }
`

const PaddingLeftSortAndFilterOptions = styled.span`
    padding-left: 10px;
    @media ${device.mobile} {
        padding-left: 8px;
    }

    @media ${device.desktop} {
        padding-left: 12px;
    }
`

const MainCta = styled.button<ImgActionsProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }

    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].primary};
    min-width: ${(props) => (props.width ? props.width : '120px')};
    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: 5px;
    color: ${(props) => colours[props.uiTheme].background_default};
    border: transparent;
    font-family: Lato;
    font-size: 14px;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    padding-right: 20px;
    align-items: center;
    text-transform: ${(props) =>
        props.textTransform ? props.textTransform : 'none'};

    @media ${device.ipad} {
        min-width: 160px;
        font-size: 13px;
        max-height: 42px;
        padding-right: 6px;
    }
`

const MainCtaOnboarding = styled.button<ImgActionsProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }

    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].primary};

    border-radius: 5px;
    color: ${(props) => colours[props.uiTheme].background_default};

    font-family: Lato;
    font-weight: 300;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;

    align-items: center;

    border: transparent;

    width: ${(props) => (props.width ? props.width : '300px')};
    height: ${(props) => (props.height ? props.height : '70px')};

    @media ${device.mobile} {
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '16px'};
        height: ${(props) => (props.height ? props.height : '70px')};
        border-radius: 4px;
    }

    @media screen and (max-width: 321px) {
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '16px'};
        height: 50px;
        border-radius: 5px;
    }

    @media ${device.large_desktop} {
        width: ${(props) => (props.width ? props.width : '300px')};
        height: ${(props) => (props.height ? props.height : '70px')};
    }

    @media ${device.ipad} {
        width: ${(props) => (props.width ? props.width : '30vw')};
        min-width: 100px;
        max-width: 290px;
        font-size: ${(props) =>
            props.fontSize ? `${props.fontSize}px` : '15px'};
        height: 50px;
    }

    @media ${device.beyond_ipad_mobile} {
        max-width: 100%;
    }
`
const MainCtaOnboardingWhite = styled.button<ImgActionsProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: uppercase;

    background-color: ${(props) => colours[props.uiTheme].background_default};

    border-radius: 5px;

    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};

    font-family: Lato;
    font-size: 14px;

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;

    align-items: center;

    width: ${(props) => (props.width ? props.width : '300px')};
    height: ${(props) => (props.height ? props.height : '70px')};

    @media ${device.ipad} {
        width: 200px;
        font-size: 14px;
        height: 50px;
        padding-right: 0px;
    }

    @media ${device.mobile_and_ipad} {
        width: 80vw;
        font-size: 16px;
        height: ${(props) => (props.height ? props.height : '60px')};
        padding-right: 0px;
        border-radius: 10px;
    }

    @media screen and (max-width: 321px) {
        width: 80vw;
        font-size: 15px;
        height: 50px;
        padding-right: 0px;
        border-radius: 5px;
        border-radius: 10px;
    }

    @media ${device.large_desktop} {
        width: 300px;
        height: 70px;
    }

    @media screen and (min-width: 560px) and (max-width: 879px) {
        width: 80vw;
        font-size: 16px;
        height: ${(props) => (props.height ? props.height : '60px')};
        padding-right: 0px;
        border-radius: 5px;
        border-radius: 10px;
    }
`

const LowercaseWhiteBackgroundSmaller = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }

    text-transform: lowercase;
    background-color: ${(props) => colours[props.uiTheme].background_default};

    width: ${(props) => (props.width ? props.width : '120px')};
    height: ${(props) => (props.height ? props.height : '30px')};
    border-radius: 5px;
    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};

    font-family: Lato;
    font-size: 13px;

    @media ${device.mobile} {
        font-size: 13px;
    }

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};
    align-items: center;
`

const LowercaseRedBackground = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: lowercase;

    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].error};
    transition: all 500ms;

    :hover {
        opacity: 0.8;
    }

    width: ${(props) => (props.width ? props.width : '154px')};
    border-radius: 5px;
    color: ${(props) => colours[props.uiTheme].background_default};
    border-radius: 5px;

    border: transparent;
    font-family: Lato;
    font-size: 16px;

    height: ${(props) => (props.height ? props.height : '45px')};
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};

    align-items: center;
`

const LowercaseBlueBackground = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: ${(props) => props.textTransform ?? 'lowercase'};

    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].primary};

    width: ${(props) => (props.width ? props.width : 'max-content')};

    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: 5px;
    color: ${(props) => colours[props.uiTheme].background_default};

    border: none;
    font-family: Lato;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : '16px')};
    padding-right: 16px;
    align-items: center;
`

const UppercaseBlueBackground = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: uppercase;
    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].primary};

    width: ${(props) => (props.width ? props.width : '120px')};
    height: ${(props) => (props.height ? props.height : '40px')};
    border-radius: ${(props) =>
        props.borderRadius ? `${props.borderRadius}px !important` : '5px'};
    color: ${(props) => (props.disabled ? 'white' : 'white')};

    border: none;
    font-family: Lato;
    font-size: 13px;

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};
    align-items: center;
`

const Secondary = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: ${(props) =>
        props.textTransform ? props.textTransform : 'uppercase'};

    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_muted_neutral
            : props.customBgColor
            ? props.customBgColor
            : colours[props.uiTheme].primary};
    width: ${(props) => (props.width ? props.width : '230px')};
    border-radius: 5px;
    border: none;
    color: ${(props) => colours[props.uiTheme].background_default};
    font-family: Lato;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};
    height: ${(props) =>
        props.height ? `${props.height} !important` : '47px'};
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};
    align-items: center;
`

const Tertiary = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: ${(props) =>
        props.textTransform ? props.textTransform : 'uppercase'};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_muted_neutral}`
            : `1px solid ${props.color ?? colours[props.uiTheme].primary}`};
    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : props.customBgColor
            ? props.customBgColor
            : colours[props.uiTheme].background_default};

    width: ${(props) => (props.width ? props.width : '230px')};
    border-radius: 5px;
    color: ${(props) =>
        props.color ?? colours[props.uiTheme].primary} !important;
    font-family: Lato;
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};
    height: ${(props) => (props.height ? props.height : '47px')};
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};
    align-items: center;
`

const SmallerBtnBlueOnWhite = styled.button<IButtonStyledProps>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }
    text-transform: uppercase;
    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_muted_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_muted_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};
    background-color: ${(props) => colours[props.uiTheme].background_default};
    width: ${(props) => (props.width ? props.width : 'auto')};
    min-width: 80px;
    border-radius: 5px;
    font-family: Lato;
    font-size: 13px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: ${(props) => (props.icon ? 'start' : 'center')};
    padding-left: ${(props) => (props.icon ? '20px' : 'auto')};
    align-items: center;
`

const Naked = styled.button<IButtonStyledProps>`
    background-color: transparent;
    border: none;
    padding: 0px;

    pointer: cursor;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }
`

const NakedText = styled.button<IButtonStyledProps>`
    background-color: transparent;
    border: none;
    cursor: pointer !important;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }
    width: ${(props) => (props.width ? props.width : 'auto')};

    font-family: Lato;
    text-align: ${(props) => (props.alignLeft ? 'left !important' : 'center')};
    font-size: ${(props) =>
        props.fontSize ? `${props.fontSize}px` : 'inherit'};
    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].text_muted
            : colours[props.uiTheme].primary};
    padding: none;

    @media ${device.mobile} {
        font-size: 13px !important;
    }
    :hover {
        opacity: 0.7;
    }

    display: flex;
    flex-direction: row;
`

const NakedAddText = styled.button<IButtonStyledProps>`
    background-color: transparent;
    border: none;
    font-size: ${(props) =>
        props.fontSize ? `${props.fontSize}px !important` : '16px !important'};

    /* font-style: italic; */
    cursor: pointer;
    width: auto;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    font-family: Lato;
    text-align: ${(props) => (props.alignLeft ? 'left !important' : 'center')};

    color: ${(props) =>
        props.color ? props.color : colours[props.uiTheme].primary};
    padding: none;

    @media ${device.smallest_laptops} {
        font-size: ${(props) =>
            props.fontSize
                ? `${props.fontSize}px !important`
                : '15px !important'};
    }

    @media ${device.mobile} {
        font-size: ${(props) =>
            props.fontSize
                ? `${props.fontSize}px !important`
                : '12px !important'};
    }

    :hover {
        opacity: 0.7;
    }
`

const NakedIcon = styled.button<IButtonStyledProps>`
    background-color: transparent;
    border: none;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`

const NakedBold = styled.button<IButtonStyledProps>`
    background-color: transparent;
    border: none;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    font-weight: 900;
    color: ${(props) => colours[props.uiTheme].text_strongest};
`

const NakedLight = styled.button<IButtonStyledProps>`
    background-color: transparent;
    border: none;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    font-weight: 200;
    color: ${(props) => colours[props.uiTheme].text_muted};
    font-style: italic;
    font-family: Lato;
    font-size: 14px;
`

const Primary = styled(Button)<IisDisabled>`
    text-transform: uppercase;
    font-family: Lato !important;
    border: solid 1px black;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].background_default} !important;

    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].text_disabled
            : colours[props.uiTheme].primary} !important;
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_muted}`
            : `1px solid ${colours[props.uiTheme].primary}`} !important;

    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
        transition: all 200ms;
    }

    @media ${device.desktop} {
        font-size: 12px;
        height: 50px !important;
        min-width: 120px !important;
        max-width: 400px !important;
    }

    @media ${device.ipad} {
        font-size: 12px;
        height: 50px !important;
        min-width: 120px !important;
        max-width: 400px !important;
    }

    @media ${device.mobile} {
        font-weight: 200;
        font-size: 14px;
        width: 80vw !important;
        height: 60px !important;
    }
`

type IisDisabled = {
    uiTheme: ITheme
    disabled?: boolean
}

const PrimaryWider = styled(Button)<IisDisabled>`
    font-family: Lato !important;
    border: solid 1px black;
    height: 50px !important;
    width: 350px !important;
    padding: 10px 15px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].border_neutral
            : colours[props.uiTheme].primary};
    border: ${(props) =>
        props.disabled
            ? `1px solid ${colours[props.uiTheme].border_neutral}`
            : `1px solid ${colours[props.uiTheme].primary}`};

    background-color: ${(props) =>
        props.disabled
            ? colours[props.uiTheme].background_disabled_neutral
            : colours[props.uiTheme].background_default};
    box-shadow: ${(props) =>
        `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_03}`};
    transition: all 200ms;
    :hover {
        transition: all 200ms;
        box-shadow: ${(props) =>
            `2px 4px 7px ${colours[props.uiTheme].shadow_strongest_09}`};
    }

    @media ${device.desktop} {
        font-size: 12px;
    }

    @media ${device.ipad} {
        font-size: 12px;
    }

    @media ${device.mobile} {
        font-weight: 200;
        font-size: 14px;
        width: 80vw;
        height: 60px !important;
    }
`

const PaddingLeftIcon = styled.div`
    padding-left: 6px;
`

const PaddingRightIcon = styled.div`
    padding-right: 20px;
`
const PaddingLeftText = styled.div`
    padding-left: 16px;
`

interface IProps extends IButtonProps {
    uiTheme: ITheme
}

class ButtonAtom extends React.Component<IButtonProps, {}> {
    renderButton(props: IProps) {
        switch (props.theme) {
            case 'modal-action-button-primary':
                return (
                    <ModalActionPrimary
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ?? 'button'}
                        icon={!!props.icon}
                        data-attr={props.dataCyId}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        textTransform={
                            props.textTransform && props.textTransform
                        }
                        fontSize={props.fontSize && props.fontSize}
                    >
                        {props.icon && (
                            <div style={{ paddingRight: '10px' }}>
                                <Icon icon={props.icon} color={'white'} />
                            </div>
                        )}
                        {props.children}
                    </ModalActionPrimary>
                )

            case 'modal-action-button-red':
                return (
                    <ModalActionRed
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ?? 'button'}
                        icon={!!props.icon}
                        data-attr={props.dataCyId}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        textTransform={
                            props.textTransform && props.textTransform
                        }
                        fontSize={props.fontSize && props.fontSize}
                    >
                        {props.children}
                    </ModalActionRed>
                )
            case 'modal-action-button-secondary':
                return (
                    <ModalActionSecondary
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ?? 'button'}
                        icon={!!props.icon}
                        data-attr={props.dataCyId}
                        height={props.height}
                    >
                        {props.children}
                    </ModalActionSecondary>
                )
            case 'modal-action-button-desctructive':
                return (
                    <ModalActionDesctructive
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ?? 'button'}
                        icon={!!props.icon}
                        data-attr={props.dataCyId}
                    >
                        {props.children}
                    </ModalActionDesctructive>
                )
            case 'naked-icon':
                return (
                    <NakedIcon
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={true}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.icon && (
                            <Icon
                                icon={props.icon}
                                //@ts-ignore
                                orientation={
                                    props.orientation && props.orientation
                                }
                            />
                        )}
                    </NakedIcon>
                )
            case 'naked':
                return (
                    <Naked
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </Naked>
                )
            case 'lowercase-white-background':
                return (
                    <LowercaseWhiteBackground
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        customBgColor={props.customBgColor}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        data-attr={props.dataCyId && props.dataCyId}
                        fontSize={props.fontSize && props.fontSize}
                    >
                        {props.children}
                    </LowercaseWhiteBackground>
                )

            case 'outline-transparent-background':
                return (
                    <LowercaseTransparentBackground
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        data-attr={props.dataCyId && props.dataCyId}
                        fontSize={props.fontSize && props.fontSize}
                    >
                        {props.children}
                    </LowercaseTransparentBackground>
                )

            case 'uppercase-white-background':
                return (
                    <UppercaseWhiteBackground
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        borderRadius={props.borderRadius && props.borderRadius}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </UppercaseWhiteBackground>
                )

            case 'capitalize-white-background':
                return (
                    <CapitalizeWhiteBackground
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        borderRadius={props.borderRadius && props.borderRadius}
                        data-attr={props.dataCyId && props.dataCyId}
                        customBgColor={
                            props.customBgColor && props.customBgColor
                        }
                        color={props.color && props.color}
                    >
                        {props.children}
                    </CapitalizeWhiteBackground>
                )

            case 'flexible-transparent-blue-border':
                return (
                    <FlexibleTransparentBlueBorder
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        // icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        // borderRadius={props.borderRadius && props.borderRadius}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </FlexibleTransparentBlueBorder>
                )
            case 'help-quick-access':
                return (
                    <HelpQuickAccessButton
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        borderRadius={props.borderRadius && props.borderRadius}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </HelpQuickAccessButton>
                )

            case 'lowercase-red-background':
                return (
                    <LowercaseRedBackground
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </LowercaseRedBackground>
                )

            case 'lowercase-white-red-background':
                return (
                    <FlexibleWhiteOnRedButton
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        data-attr={props.dataCyId && props.dataCyId}
                        noIpadMinWith={
                            props.noIpadMinWith && props.noIpadMinWith
                        }
                    >
                        {props.children}
                    </FlexibleWhiteOnRedButton>
                )

            case 'lowercase-blue-background':
                return (
                    <LowercaseBlueBackground
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        data-attr={props.dataCyId && props.dataCyId}
                        fontSize={props.fontSize && props.fontSize}
                        textTransform={
                            props.textTransform && props.textTransform
                        }
                    >
                        {props.children}
                    </LowercaseBlueBackground>
                )

            case 'uppercase-blue-background':
                return (
                    <UppercaseBlueBackground
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        borderRadius={props.borderRadius && props.borderRadius}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </UppercaseBlueBackground>
                )
            case 'lowercase-white-background-smaller':
                return (
                    <LowercaseWhiteBackgroundSmaller
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </LowercaseWhiteBackgroundSmaller>
                )
            case 'naked-text':
                return (
                    <NakedText
                        data-ph={props.dataPH}
                        onKeyDown={props.onKeyDown && props.onKeyDown}
                        //@ts-ignore
                        alignLeft={props.alignLeft && props.alignLeft}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        data-attr={props.dataCyId && props.dataCyId}
                        fontSize={props.fontSize && props.fontSize}
                    >
                        {props.children}
                    </NakedText>
                )

            case 'naked-add-text':
                return (
                    <NakedAddText
                        //@ts-ignore
                        alignLeft={props.alignLeft && props.alignLeft}
                        icon={props.icon ? true : false}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        fontSize={props.fontSize && props.fontSize}
                        data-attr={props.dataCyId && props.dataCyId}
                        color={props.color}
                        data-ph={props.dataPH}
                    >
                        {props.children}
                    </NakedAddText>
                )
            case 'naked-text-arrow':
                return (
                    <NakedText
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                        <span style={{ paddingLeft: '10px' }}>
                            <Icon
                                icon="desktopLinkNavigationArrow"
                                color={props.color ? props.color : 'black'}
                            />
                        </span>
                    </NakedText>
                )
            case 'naked-bold':
                return (
                    <NakedBold
                        data-ph={props.dataPH}
                        icon={props.icon ? true : false}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </NakedBold>
                )
            case 'naked-light':
                return (
                    <NakedLight
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                    </NakedLight>
                )
            case 'primary':
                return props.wider ? (
                    <PrimaryWider
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        htmlType={props.type ? props.type : 'button'}
                        data-attr={props.dataCyId && props.dataCyId}
                        type="default"
                    >
                        {props.children}
                        {props.icon && (
                            <PaddingLeftIcon>
                                <Icon icon={props.icon} />
                            </PaddingLeftIcon>
                        )}
                    </PrimaryWider>
                ) : (
                    <Primary
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        htmlType={props.type ? props.type : 'button'}
                        type="default"
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.children}
                        {props.icon && (
                            <PaddingLeftIcon>
                                <Icon icon={props.icon} />
                            </PaddingLeftIcon>
                        )}
                    </Primary>
                )
            case 'add-text':
                return (
                    <AddText
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        <Icon icon="add_plus_white" />

                        <PaddingLeftText>{props.children}</PaddingLeftText>
                    </AddText>
                )

            case 'wide-mobile-blue-bg':
                return (
                    <WideMobileBlueBg
                        data-ph={props.dataPH}
                        data-attr={props.dataCyId && props.dataCyId}
                        icon={props.icon ? true : false}
                        width={props.width}
                        height={props.height}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                    >
                        {props.children}
                    </WideMobileBlueBg>
                )

            case 'flexible-blue-on-white':
                return (
                    <FlexibleWhiteOnBlueButton
                        data-ph={props.dataPH}
                        onKeyDown={props.onKeyDown && props.onKeyDown}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        hasIcon={props.icon ? true : false}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        <Icon
                            icon={props.icon ? props.icon : 'crop_img_circle'}
                        />

                        <PaddingLeftSortAndFilterOptions>
                            {props.children}
                        </PaddingLeftSortAndFilterOptions>
                    </FlexibleWhiteOnBlueButton>
                )

            // MainCta

            case 'main-cta':
                return (
                    <MainCta
                        data-ph={props.dataPH}
                        data-attr={props.dataCyId && props.dataCyId}
                        onKeyDown={props.onKeyDown && props.onKeyDown}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        textTransform={
                            props.textTransform && props.textTransform
                        }
                    >
                        <Icon
                            icon={props.icon ? props.icon : 'add_plus_white'}
                        />
                        <span style={{ width: '10px' }} />
                        {props.children}
                    </MainCta>
                )
            // MainCta onboarding

            case 'main-cta-onboarding':
                return (
                    <motion.div
                        whileTap={{ scale: 0.95 }}
                        style={props.width ? { width: props.width } : {}}
                    >
                        <MainCtaOnboarding
                            data-ph={props.dataPH}
                            data-attr={
                                props.dataCyId ? props.dataCyId : undefined
                            }
                            onKeyDown={props.onKeyDown && props.onKeyDown}
                            onClick={props.onClick}
                            uiTheme={props.uiTheme}
                            disabled={props.disabled}
                            type={props.type ? props.type : 'button'}
                            width={props.width && props.width}
                            height={props.height && props.height}
                            fontSize={props.fontSize && props.fontSize}
                        >
                            {props.children}
                        </MainCtaOnboarding>
                    </motion.div>
                )

            case 'main-cta-onboarding-white':
                return (
                    <motion.div whileTap={{ scale: 1.05 }}>
                        <MainCtaOnboardingWhite
                            data-ph={props.dataPH}
                            data-attr={
                                props.dataCyId ? props.dataCyId : undefined
                            }
                            onKeyDown={props.onKeyDown && props.onKeyDown}
                            onClick={props.onClick}
                            uiTheme={props.uiTheme}
                            disabled={props.disabled}
                            type={props.type ? props.type : 'button'}
                            width={props.width && props.width}
                            height={props.height && props.height}
                        >
                            {props.children}
                        </MainCtaOnboardingWhite>
                    </motion.div>
                )

            case 'secondary':
                return (
                    <Secondary
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        data-attr={props.dataCyId && props.dataCyId}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        textTransform={
                            props.textTransform && props.textTransform
                        }
                        customBgColor={props.customBgColor}
                    >
                        {props.icon && (
                            <PaddingRightIcon>
                                <Icon icon={props.icon} />
                            </PaddingRightIcon>
                        )}
                        {props.children}
                    </Secondary>
                )

            case 'tertiary':
                return (
                    <Tertiary
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        data-attr={props.dataCyId && props.dataCyId}
                        width={props.width && props.width}
                        height={props.height && props.height}
                        fontSize={props.fontSize && props.fontSize}
                        textTransform={
                            props.textTransform && props.textTransform
                        }
                        customBgColor={
                            props.customBgColor && props.customBgColor
                        }
                        color={props.color && props.color}
                    >
                        {props.icon && (
                            <PaddingRightIcon>
                                <Icon icon={props.icon} />
                            </PaddingRightIcon>
                        )}
                        {props.children}
                    </Tertiary>
                )

            case 'smaller-blue-on-white':
                return (
                    <SmallerBtnBlueOnWhite
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={props.icon ? true : false}
                        width={props.width && props.width}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        {props.icon && (
                            <PaddingRightIcon>
                                <Icon icon={props.icon} />
                            </PaddingRightIcon>
                        )}
                        {props.children}
                    </SmallerBtnBlueOnWhite>
                )

            case 'img-actions':
                return (
                    <BackgroundImgActions
                        data-ph={props.dataPH}
                        height={props.height && props.height}
                        width={props.width && props.width}
                        action={props.action && props.action}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        <Icon
                            icon={
                                props.action === 'crop_img'
                                    ? 'crop_img'
                                    : 'delete_img'
                            }
                        />
                    </BackgroundImgActions>
                )

            case 'crop_img_circle':
                return (
                    <NakedIcon
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={true}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        <Icon
                            icon="crop_img_circle"
                            width={props.width && props.width}
                        />
                    </NakedIcon>
                )

            case 'bin_img_circle':
                return (
                    <NakedIcon
                        data-ph={props.dataPH}
                        onClick={props.onClick}
                        uiTheme={props.uiTheme}
                        disabled={props.disabled}
                        type={props.type ? props.type : 'button'}
                        icon={true}
                        width={props.width && props.width}
                        data-attr={props.dataCyId && props.dataCyId}
                    >
                        <Icon
                            icon="bin_img_circle"
                            width={props.width && props.width}
                        />
                    </NakedIcon>
                )

            default:
                return false
        }
    }

    render() {
        return (
            <ThemesContext.Consumer>
                {({ theme: uiTheme }) =>
                    this.renderButton({
                        ...this.props,
                        uiTheme: uiTheme,
                    })
                }
            </ThemesContext.Consumer>
        )
    }
}

export default ButtonAtom
