import {
    GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR,
    SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR,
    GET_PRESIGNED_URL_CAR_COVER_IMAGE_ERROR,
    SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_ERROR,
    GET_PRESIGNED_URL_USERPROFILE_IMAGE_ERROR,
    GET_PRESIGNED_URL_ENTRY_ATTACHMENT_ERROR,
    GET_PRESIGNED_URL_COST_ATTACHMENT_ERROR,
    GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_ERROR,
    SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_ERROR,
    SUBMIT_IMAGES_TO_S3_FILESTORAGE_ERROR,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const getPresignedUrlsCarGalleryImagesFileStorageError = createAction(
//     GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getPresignedUrlsCarGalleryImagesFileStorageError = createAction(
    GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getPresignedUrlCarCoverImageFileStorageError = createAction(
//     GET_PRESIGNED_URL_CAR_COVER_IMAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getPresignedUrlCarCoverImageFileStorageError = createAction(
    GET_PRESIGNED_URL_CAR_COVER_IMAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlEntryAttachmentFileStorageError = createAction(
    GET_PRESIGNED_URL_ENTRY_ATTACHMENT_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlCostAttachmentFileStorageError = createAction(
    GET_PRESIGNED_URL_COST_ATTACHMENT_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const submitCarGalleryImagesFilestorageError = createAction(
//     SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const submitCarGalleryImagesFilestorageError = createAction(
    SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const submitCarCoverImageFilestorageError = createAction(
//     SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const submitCarCoverImageFilestorageError = createAction(
    SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getPresignedUrlUserProfileImageError = createAction(
//     GET_PRESIGNED_URL_USERPROFILE_IMAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getPresignedUrlUserProfileImageError = createAction(
    GET_PRESIGNED_URL_USERPROFILE_IMAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlsEntryGalleryImagesFileStorageError = createAction(
    GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const submitEntryGalleryImagesFilestorageError = createAction(
    SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const submitFilesToS3Error = createAction(
    SUBMIT_IMAGES_TO_S3_FILESTORAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
