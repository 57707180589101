import { createQRLogoSvgBiggerRes_Custom } from '../../atoms/pngDownloadButton/logoQRCustomGen'

export type ICustomQRCodeGen_args = {
    colour_circle: string
    colour_background: string
    qr_svg: HTMLElement
}

export const createSvgModel = (p: ICustomQRCodeGen_args): any => {
    let elem: SVGSVGElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
    )

    // elem.setAttribute('viewBox', '0 0 283 283')
    elem.setAttribute('fill', `none`)
    elem.setAttribute('viewBox', '0,0,1000,1000')

    elem.setAttribute('width', `1000`)
    elem.setAttribute('height', `1001`)

    elem.setAttribute('stroke-width', `8`)

    const circle_1 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
    )
    circle_1.setAttribute('r', `485.15`)
    circle_1.setAttribute('cx', `500`)
    circle_1.setAttribute('cy', `500.895`)
    circle_1.setAttribute('fill', p.colour_circle)
    circle_1.setAttribute('stroke-width', `29.6964`)
    circle_1.setAttribute('stroke', `white`)
    circle_1.setAttribute('shape-rendering', 'default')
    elem.appendChild(circle_1)

    const circle_2 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
    )
    circle_2.setAttribute('r', `402.738`)
    circle_2.setAttribute('cx', `500`)
    circle_2.setAttribute('cy', `500.895`)
    circle_2.setAttribute('fill', 'white')
    circle_1.setAttribute('stroke-width', `29.6964`)
    circle_1.setAttribute('stroke', `white`)
    circle_2.setAttribute('shape-rendering', 'default')
    elem.appendChild(circle_2)

    const circle_3 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
    )
    circle_3.setAttribute('r', `378.092`)
    circle_3.setAttribute('cx', `505.301`)
    circle_3.setAttribute('cy', `502.661`)
    circle_3.setAttribute('fill', p.colour_background)
    circle_3.setAttribute('shape-rendering', 'default')
    elem.appendChild(circle_3)

    if (p.qr_svg !== null) {
        // add svg QR CODE
        let qr_code_svg = p.qr_svg
        qr_code_svg.setAttribute('shape-rendering', 'crispEdges')
        qr_code_svg.setAttribute('height', '52%')
        qr_code_svg.setAttribute('width', '52%')
        qr_code_svg.setAttribute('x', '250')
        qr_code_svg.setAttribute('y', '250')

        // height="52%" width="52%" viewBox="0 0 25 25" id="preview_car_qr_code_to_export_desktop_custom_desktop_1" x="250" y="250">

        // add svg logo
        var image = qr_code_svg.querySelector('image')

        if (image) {
            image.remove()
        }

        elem.appendChild(qr_code_svg)

        let logoSvg = createQRLogoSvgBiggerRes_Custom(p.colour_background)
        elem.appendChild(logoSvg)
    }
    return elem
}
