import {
    ANSWER_ONBOARDING_QA_ERROR,
    SET_PRIMARY_ONBOARDING_USER_TYPE__ERROR,
    SET_SECONDARY_ONBOARDING_USER_TYPE__ERROR,
} from './constants'

import { createAction } from '@reduxjs/toolkit'

// export const answerOnboardingQaError = createAction(
//     ANSWER_ONBOARDING_QA_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const answerOnboardingQaError = createAction(
    ANSWER_ONBOARDING_QA_ERROR,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

// export const setPrimaryOnboardingQaError = createAction(
//     SET_PRIMARY_ONBOARDING_USER_TYPE__ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const setPrimaryOnboardingQaError = createAction(
    SET_PRIMARY_ONBOARDING_USER_TYPE__ERROR,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

// export const setSecondaryOnboardingQaError = createAction(
//     SET_SECONDARY_ONBOARDING_USER_TYPE__ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const setSecondaryOnboardingQaError = createAction(
    SET_SECONDARY_ONBOARDING_USER_TYPE__ERROR,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)
