import React from 'react'
import styled from 'styled-components'
import Sheet from 'react-modal-sheet'
import IpadAndMobileDisplay from '../displays/ipadAndMobileDisplay'
import { device } from '../displays/devices'

type IStyle = { $customZindex?: number }

const CustomSheet = styled(Sheet)<IStyle>`
    ${(props) =>
        props.$customZindex && `z-index: ${props.$customZindex} !important`};
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: 100vh !important;
        top: 0;
        background-color: var(--bg-color, #fff) !important;
        box-shadow: rgb(0 0 0 / 5%) 0px -2px 10px !important;
    }
    .react-modal-sheet-header {
        height: 20px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`

interface IProps {
    toggle?: any
    isOpen: boolean
    children: React.ReactNode
    customZindex?: number
}

const BottomSheetPage: React.SFC<IProps> = (props) => {
    const { isOpen, toggle, children, customZindex } = props

    return (
        // <Faded>
        <IpadAndMobileDisplay>
            <CustomSheet
                isOpen={isOpen}
                onClose={() => toggle(false)}
                disableDrag
                initialSnap={1}
                $customZindex={customZindex}
            >
                {/* <ModalWrapper
                        isMobileAccountMenuOpen={isMobileAccountMenuOpen}
                        onClick={toggle}
                        /> */}
                <Sheet.Container>
                    <Sheet.Content>{children}</Sheet.Content>
                </Sheet.Container>
            </CustomSheet>
            {/* )} */}
        </IpadAndMobileDisplay>
        // </ModalWrapper>
        // </Faded>
    )
}

export default BottomSheetPage
