import {
    IDirectShareOwnedItem_Api_payload,
    IDirectShareRecipient_item_API,
} from 'ApiInterfaces'
import {
    IDirectSharesSuccessPayload_redux_arg,
    IGarageObject,
    IReduxError,
} from 'entityModels'
import { call, fork, put, select, take } from 'redux-saga/effects'
import { RootState } from 'typesafe-actions'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    ConvertSingleDirectShare_owned,
    IConvertSingleDirectShareOwnedArg,
} from '../../../conversions/shares/convertDirectShares'
import { ConvertEntityDirectSharesListToState } from '../../../conversions/shares/convertSharesOwnedToState'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IDirectShareSuccessPayloadRedux } from '../../cars/actions/actions'
import { IgetSingleDirectShareRequest_redux } from '../../cars/actions/loadingActions'
import * as actions from '../actions'

const getGarageState = (state: RootState) => state.entities.garagesData.garages

export function* GetSingleGarageShare(p: IgetSingleDirectShareRequest_redux) {
    try {
        // get direct shares
        let share_api_res: IDirectShareOwnedItem_Api_payload = yield call(
            api.sharing.getSingleShareAPI,
            {
                share_id: p.share_id,
                share_type: 'direct_share',
            }
        )

        // here get list of recipients of that share.

        let share_recipients_list: IDirectShareRecipient_item_API[] =
            yield call(api.sharing.getShareRecipientsListAPI, p.share_id)

        let p_conv: IConvertSingleDirectShareOwnedArg = {
            api_payload: share_api_res,
            recipients: share_recipients_list,
        }

        const direct_share_owned = ConvertSingleDirectShare_owned(p_conv)

        let reduxPayload: IDirectShareSuccessPayloadRedux = {
            id: p.entity_id,
            direct_share: direct_share_owned,
        }
        yield put(
            actions.actions.getSingleGarageDirectShareSuccess(reduxPayload)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetSingleGarageShare,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.getSingleGarageDirectShareError(typedError)
            )
            return
        }
    }
}

function* watcherGetSingleGarageShare() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getSingleGarageDirectShareRequest
        )
        yield call(GetSingleGarageShare, payload)
    }
}

export function* GetOwnedDirectSharesGarage(garageid: string) {
    try {
        let garageDirectSharesAPIres: IDirectShareOwnedItem_Api_payload[] =
            yield call(api.entities.getData.getGarageDirectSharesAPI, garageid)

        let convertedObj: IDirectSharesSuccessPayload_redux_arg = {
            id: garageid,
            private_shares_owned: {
                entity_owned_direct_shares_ids: [],
                entity_owned_direct_shares_data: {},
            },
        }

        let garages_in_state: IGarageObject | null = yield select(
            getGarageState
        )
        let private_shares_in_state =
            garages_in_state &&
            garageid &&
            garages_in_state[garageid] &&
            garages_in_state[garageid].private_shares_owned
        let shares_in_state =
            private_shares_in_state &&
            private_shares_in_state.entity_owned_direct_shares_data
                ? private_shares_in_state.entity_owned_direct_shares_data
                : undefined

        if (garageDirectSharesAPIres && garageDirectSharesAPIres.length > 0) {
            convertedObj = {
                ...convertedObj,
                private_shares_owned: ConvertEntityDirectSharesListToState({
                    api_shares: garageDirectSharesAPIres,
                    shares_in_state: shares_in_state,
                }),
            }
        }

        yield put(
            actions.actions.getOwnedDirectSharesGarageSuccess(convertedObj)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetOwnedDirectSharesGarage,
                payload: garageid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.getOwnedDirectSharesGarageError(typedError)
            )
            return
        }
    }
}

function* watcherGetOwnedDirectSharesGarage() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getOwnedDirectSharesGarageRequest
        )
        yield call(GetOwnedDirectSharesGarage, payload)
    }
}

const owned_garage_direct_shares: any[] = [
    fork(watcherGetOwnedDirectSharesGarage),
    fork(watcherGetSingleGarageShare),
]

export default owned_garage_direct_shares
