import { IShowroomFilterRadioOptionItem } from '../../showroom/types'

let all_options = [
    {
        id: '2020',
        name: "2020's",
    },
    {
        id: '2010',
        name: "2010's",
    },
    {
        id: '2000',
        name: "2000's",
    },
    {
        id: '1990',
        name: "1990's",
    },
    {
        id: '1980',
        name: "1980's",
    },
    {
        id: '1970',
        name: "1970's",
    },
    {
        id: '1960',
        name: "1960's",
    },
    {
        id: '1950',
        name: "1950's",
    },
    {
        id: '1940',
        name: "1940's",
    },
    {
        id: '1930',
        name: "1930's",
    },
    {
        id: '1920',
        name: "1920's",
    },
]

export const generateShowroomYearsDropdown = (
    min_backend?: number,
    max_backend?: number
): IShowroomFilterRadioOptionItem[] => {
    let res: IShowroomFilterRadioOptionItem[] = []

    all_options.forEach((item: IShowroomFilterRadioOptionItem) => {
        const decadeStartYear = parseInt(item.id)
        const decadeEndYear = decadeStartYear + 9

        if (
            (!min_backend || min_backend <= decadeEndYear) &&
            (!max_backend || max_backend >= decadeStartYear)
        ) {
            res.push(item)
        }
    })

    return res
}
