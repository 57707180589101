import React from 'react'
import qs from 'qs'
import { connect } from 'react-redux'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import styled from 'styled-components'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import CarOverviewBanner from '../../atoms/image/carOverviewBanner'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import OneLiner from '../../atoms/typography/oneLiner'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { DescriptionMobile, Paragraph } from '../../atoms/typography'
import FullWidthMobile from '../../atoms/image/fullWidthMobile'
import MobileContentBody from '../../templates/displays/pageWrappers/MobileContentBody'
import GalleryOverviewDataManagerDesktop from '../../organisms/galleryOverviewManager/desktop/galleryOverviewDataManagerDesktop'
import GalleryOverviewDataManagerMobile from '../../organisms/galleryOverviewManager/mobile/galleryOverviewDataManagerMobile'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import Loader from '../../atoms/loader/loader'

import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'

import { editFormsActions } from '../../../redux/editForms/reducer'

import CarDescriptionEditFormDesktop from '../../organisms/editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import CarDescriptionEditFormMobile from '../../organisms/editForms/carDescriptionEditForm/carDescriptionEditFormMobile'
import {
    ICar,
    IGalleryImagesObject,
    IGarageObject,
    IGetCarActionCheckRequest,
    IHighlightedFact,
    IHighlightedFactsObject,
    IReduxError,
    IUpdateCarGeneralPayloadReq,
} from 'entityModels'

import { device } from '../../templates/displays/devices'
import {
    getCarActionCheckRequest,
    getCarDataByIdRequest,
    getLastCarInsuranceEntryRequest,
    writeCarDataGeneralRequest,
} from '../../../redux/entities/cars/actions/loadingActions'
import TutorialConfigsCarMobile from '../../molecules/tutorialConfigs/car/tutorialConfigsCarMobile'
import TutorialConfigsCarDesktop from '../../molecules/tutorialConfigs/car/tutorialConfigsCarDesktop'

import { deleteCarGalleryImageRequest } from '../../../redux/entities/galleries/actions/loadingActions'

import Faded from '../../templates/animated/faded'
import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
import {
    manageUserTutorialByIdCheck,
    setIsFirstSessionSuccess,
} from '../../../redux/localdata/tutorialsCheck/actions'
import { ITutorialCheck } from '../../../redux/localdata/tutorialsCheck/types'
import { ITimelineCreateForms, IUser } from 'myModels'
import TimelineOverviewManagerDesktop from '../../organisms/timelineOverviewManager.tsx/timelineOverviewManagerDesktop'
import { ITimelineDataByCarNormalised, ITimelineItem } from 'timelineModels'
import { INormalisedAttachmentsByID } from 'attachmentModels'

import { menuActions } from '../../../redux/menus/reducer'
import TimelineOverviewManagerMobile from '../../organisms/timelineOverviewManager.tsx/timelineOverviewManagerMobile'

import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'

import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { getCookie } from '../../../redux/services/authcheck'
import OverviewGridManagerDesktop from '../../molecules/car/OverviewGridManagerDesktop'
import CarOverviewInfoRow from '../../atoms/car/profile/carOverviewInfoRow'
import CarKeyMomentsfactsDesktopV2 from '../../molecules/car/carKeyMomentsFactsDesktopV2'
import SimpleDescriptionParagraph from '../../atoms/typography/simpleDescriptionParagraph'
import short_description_skeleton from '../../atoms/skeleton/svg/short_description_skeleton.svg'
import CarKeyMomentsFactsMobile from '../../molecules/car/CarKeyMomentsFactsMobile'
import CarOverviewGridManagerMobile from '../../molecules/car/carOverviewGridManagerMobile'

import descSkeleton from '../../atoms/skeleton/description/skeleton.jpg'
import CreateShowroomEntryFormDesktop from '../../organisms/showroomForms/createShowroomEntryDesktop'
import CarOverviewFormsBodyDesktop from '../../organisms/carOverviewForms/carOverviewFormsBodyDesktop'
import CarOverviewFormsBodyMobile from '../../organisms/carOverviewForms/carOverviewFormsBodyMobile'
// import QrCodeBtn from '../../atoms/Button/qrCodeBtn'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import NativeAppPromptDesktop from '../../molecules/nativeAppPrompt/nativeAppPromptDesktop'
import NativeAppPromptMobile from '../../molecules/nativeAppPrompt/nativeAppPromptMobile'
import ArchiveCarDesktopFormManager from '../../organisms/archiveForms/archiveCarDesktopManager'
import CarActionMenu from '../../atoms/menu/carActionMenu/carActionMenu'
import CarOverviewActionsSheetMobile from '../../atoms/menu/carActionMenu/carActionsMenuDropsheet'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import CarActionMenuMobile from '../../atoms/menu/carActionMenu/carActionMobile'
import ShowroomTechInfoFormMobile from '../../molecules/showroom/techInfoForm/showroomTechInfoFormMobile'
import CarUserAndTitleBox from '../../atoms/user/carUserAndTitleBox'
import { showroomActions } from '../../../redux/showroom/reducer'
import CarRemovedConfirmationModalDesktop from '../../molecules/showroom/editModals/desktop/carRemovedConfirmationModalDesktop'
import { ShowroomModalInnerContainer } from '../../organisms/showroom/showroomModalEditManager/showroomModalEditManagerDesktop'
import { ShowroomModalContainerMobile } from '../../organisms/showroom/showroomModalEditManager/showroomModalEditManagerMobile'
import banner_placeholder_mobile from '../../../public/assets/placeholder_images/banner_placeholder_mobile.jpg'
import banner_placeholder_mobile_dark from '../../../public/assets/placeholder_images/banner_placeholder_desktop_dark.png'

import description_section_icon_desktop from '../../../public/assets/icons/description_section_icon_desktop.svg'
import insurance_section_icon_desktop from '../../../public/assets/icons/insurance_section_icon_desktop.svg'

import description_section_icon_desktop_dark from '../../../public/assets/icons/description_section_icon_desktop_dark.png'
import insurance_section_icon_desktop_dark from '../../../public/assets/icons/insurance_section_icon_desktop_dark.png'
import Icon from '../../atoms/icons'

import { CarInsuranceSectionBodyDesktop } from '../../molecules/car/carInsuranceSectionBodyDesktop'
import { CarInsuranceSectionBodyMobile } from '../../molecules/car/carInsuranceSectionMobile'
import {
    hasDateExpired,
    isDateWithinAMonth,
} from '../../../helpers/time/withinXdays'
import posthog from 'posthog-js'
import ChevronDown from '../../atoms/icons/components/chevronDown'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import JourneyRedirectCardMobile from '../../molecules/cards/journey/redirectCards/redirectCardMobile'
import FadedSlower from '../../templates/animated/FadedSlower'
import Expander from '../../atoms/expander/expander'
import { checkIfCarHasMissingJourneyInfo } from '../../../helpers/objChecks'
import CarInsightsBtn from '../../atoms/carInsights/btn'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import colours, { ITheme } from '../../../providers/theme/colours'

type Iids = {
    carid: string
    imageid: string
}

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    toggleCarOverviewEditForm: () =>
        editFormsActions.toggleCarOverviewEditForm(),
    toggleCarGalleryEditForm: () => editFormsActions.toggleCarGalleryEditForm(),
    toggleCarHighlightsEditForm: () =>
        editFormsActions.toggleCarHighlightsEditForm(),
    toggleCarDescriptionEditForm: () =>
        editFormsActions.toggleCarDescriptionEditForm(),
    toggleCarKeyFactsEditForm: () =>
        editFormsActions.toggleCarKeyFactsEditForm(),
    writeCarDataGeneralRequest: (p: IUpdateCarGeneralPayloadReq) =>
        writeCarDataGeneralRequest(p),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    toggleCarBannerImageForm: () => editFormsActions.toggleCarBannerImageForm(),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    getCarActionCheckRequest: (p: IGetCarActionCheckRequest) =>
        getCarActionCheckRequest(p),
    deleteCarGalleryImageRequest: (ids: Iids) =>
        deleteCarGalleryImageRequest(ids),
    manageUserTutorialByIdCheck: (pageId: string) =>
        manageUserTutorialByIdCheck(pageId),
    setIsFirstSession: (value: boolean) => setIsFirstSessionSuccess(value),
    toggleMobileAppSubMenu: (isOpen: boolean) =>
        menuActions.toggleMobileAppSubMenu(isOpen),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
    cleanupDeletedShowroomEntryId: (id: string | undefined) =>
        showroomActions.showroomEntryDeletionConfirmed(id),

    getLastCarInsuranceEntryRequest: (payload: string) =>
        getLastCarInsuranceEntryRequest(payload),
}

function mapStateToProps(state: RootState) {
    return {
        carsData: state.entities.carsData.cars,
        images_data: state.entities.galleriesData.images,
        isCarLoading: state.entities.carsData.loading,
        car_checks_loading: state.entities.carsData.car_checks_loading,
        carError: state.entities.carsData.error,
        isUserLoading: state.user.loading,

        highlightedFactsData:
            state.entities.highlightedFactsData.highlighted_facts,

        isNecessaryGalleryDataLoading: state.entities.galleriesData.loading,

        isCarDescriptionFormEditOpened:
            state.editForms.formsData.carDescriptionEditForm.isOpen,

        currentcarid:
            state.entities.carsData.currentCarID &&
            state.entities.carsData.currentCarID,

        userLoggedIn: state.user.userLoggedIn,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
        garage_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.owns_garage &&
            state.user.userLoggedIn.owns_garage.uid,
        garagesData: state.entities.garagesData.garages,
        tutorialsCheck:
            state.localdata.tutorials_check_state &&
            state.localdata.tutorials_check_state,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        attachmentsObj: state.attachments.attachmentsById,
        // costsObj: state.timeline.cost_items_normalised_by_cost_id,
        isMobileAppSubMenuOpen: state.menus.mobileAppSubMenu.isOpen,
        isActionsMenuOpen: state.menus.isAddActionsOpen.isOpen,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
        garage_data: state.entities.garagesData.garages,
        carShowroomEntryWasDeleted: state.showroom.deletedShowroomEntryId,
    }
}

interface Props extends RouteComponentProps<any> {
    location: any
    carsData: {
        [key: string]: ICar
    }
    writeCarDataGeneralRequest: (p: IUpdateCarGeneralPayloadReq) => {}
    getPublicSharesCarRequest: (p: string) => void
    images_data: IGalleryImagesObject | null
    isCarLoading: boolean
    car_checks_loading: boolean
    isNecessaryGalleryDataLoading: boolean

    toggleCarOverviewEditForm: () => void

    toggleCarGalleryEditForm: () => void

    toggleCarHighlightsEditForm: () => void
    toggleCarDescriptionEditForm: () => void
    isCarDescriptionFormEditOpened: boolean

    toggleCarKeyFactsEditForm: () => void
    setCurrentCar: (car_id: string) => void
    currentcarid: string | undefined
    isUserLoading: boolean

    toggleCarBannerImageForm: () => any
    getCarDataByIdRequest: (carid: string) => void
    userLoggedIn: IUser | null
    isCollapsed: boolean
    garage_id: string | null
    garagesData: IGarageObject
    deleteCarGalleryImageRequest: (ids: Iids) => void
    getUserDataRequest: () => void
    tutorialsCheck: ITutorialCheck
    setIsFirstSession: (value: boolean) => void
    manageUserTutorialByIdCheck: (pageId: string) => void
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    attachmentsObj: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    isMobileAppSubMenuOpen: boolean
    toggleMobileAppSubMenu: (isOpen: boolean) => void
    isActionsMenuOpen: boolean
    // getAllTimelineItemsByCarIDRequest: any
    userCurrency: string | undefined
    setInitialLandingUrl: (str: string) => void
    garage_data: IGarageObject
    highlightedFactsData: IHighlightedFactsObject
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
    carShowroomEntryWasDeleted: string | undefined
    cleanupDeletedShowroomEntryId: (id: string | undefined) => void
    getLastCarInsuranceEntryRequest: (payload: string) => {}
    carError: IReduxError | null
    getCarActionCheckRequest: (p: IGetCarActionCheckRequest) => void
}

export const SectionsWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const FirstSectionWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 48px;
    width: 100%;
    @media ${device.large_desktop} {
        padding-top: 64px;
        padding-bottom: 66px;
    }
`

export const GridSeparation = styled.div`
    margin-left: 2vw;
`

export const FirstSectionCol1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 100%;
`

export const StyledNavBtn = styled.div`
    align-self: end;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    justify-self: end;
    flex-direction: column;
    display: flex;
    padding-top: 16px;
    padding-right: 10px;
`

export const IpadWrapPadding = styled.div`
    @media ${device.ipad} {
        padding-left: 2vw;
    }
`

export const IpadWrapUserPadding = styled.div`
    @media ${device.ipad} {
        padding-left: 2vw;
        padding-top: 10px;
    }
`

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
`

const EmptyBtn = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

const MobilePadding = styled.div`
    @media (max-width: 500px) {
        padding-top: 10px;
    }
`

export const KeyMomNFactsHeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    @media (min-width: 2000px) {
        padding-left: 0px;
        padding-right: 0px;
    }
`

const SectionIconRowInsurance = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
    flex-direction: row;
`

const SectionIconRowDescription = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 48px;
    flex-direction: row;
`
const SectionRowPadding = styled.div`
    padding-top: 16px;

    @media ${device.smallest_laptops} {
        padding-top: 16px;
    }
`

const ChevronWrapper = styled.a<{ $theme: ITheme }>`
    cursor: pointer;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transform: rotate(-90deg);

    @media ${device.mobile_and_ipad} {
        height: 32px;
        width: 32px;
    }

    :hover {
        background-color: ${(props) =>
            colours[props.$theme].primary_underlay} !important;
        transition: background-color 200ms;
    }
`

export type CarActionMenuItemsID = 'add' | 'edit' | 'more'

type State = {
    carsDataFinished: null | {}
    showBoxInfo: boolean
    showEditImageForm: boolean
    showDeleteConfirmationMessage: boolean
    currentTutorialStep: any
    isCarMenuOpened: boolean
    activeCarActionMenuItemID?: CarActionMenuItemsID | undefined
    isShowroomPublishOpen: boolean
    isCarArchiveOpen: boolean
    activeLocalForm: 'tech_info' | null
    carActionsChecked: boolean
    showJourneyCard: boolean
}
export type CarOptionMenuItem = {
    id: string
    name: string
    icon: string
    iconLoading?: boolean
    action: any
    customColor?: string
    isDisabled?: boolean
    tooltip?: string
}
export type ICarActionMenuItemsData = {
    [key: string]: {
        id: string
        name: string
        onClick: any
        options: CarOptionMenuItem[]
    }
}

class CarOverview extends React.Component<Props, State> {
    state = {
        carsDataFinished: null,
        showBoxInfo: false,
        showEditImageForm: false,
        showDeleteConfirmationMessage: false,
        currentTutorialStep: 0,
        isCarMenuOpened: false,
        isShowroomPublishOpen: false,
        isCarArchiveOpen: false,
        activeCarActionMenuItemID: undefined,
        activeLocalForm: null,
        carActionsChecked: false,
        showJourneyCard: false,
    }

    openCarArchived = () => {
        this.setState({
            ...this.state,
            isCarArchiveOpen: true,
            isCarMenuOpened: false,
        })
    }

    hideJourneyCard = () => {
        this.setState({
            ...this.state,
            showJourneyCard: false,
        })
    }

    displayJourneyCard = () => {
        this.setState({
            ...this.state,
            showJourneyCard: true,
        })
    }

    componentDidMount() {
        this.props.setInitialLandingUrl(`/car/${this.props.match.params.carid}`)
        let userLoggedIn = this.props.userLoggedIn
        let carid = this.props.match.params.carid

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        } else if (userLoggedIn) {
            this.props.setCurrentCar(carid)

            window.scrollTo(0, 0)

            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(carid)
            }

            if (
                this.props.carsData[carid] &&
                this.props.carsData[carid].has_limited_garage_info === true
            ) {
                this.props.getCarDataByIdRequest(carid)
            }

            const params = new URLSearchParams(this.props.location.search)
            if (params.get('onboarding') === 'true') {
                this.props.setIsFirstSession(true)
            }

            if (
                params.get('onboarding') !== 'true' &&
                params.get('native_app_prompt') !== 'true' &&
                this.props.tutorialsCheck.isFirstSession === true &&
                this.props.tutorialsCheck.tutorialsCheckByPageId.car === false
            ) {
                this.props.history.push(`/car/${carid}?tutorial=true`)
            }
        }

        const params = new URLSearchParams(location.search)
        let local_form_id = params.get('form_id')
        if (local_form_id === 'makeModelYear') {
            this.setState({
                ...this.state,
                activeLocalForm: 'tech_info',
            })
        }

        if (local_form_id === 'initiate_archive') {
            this.openCarArchived()
        }

        if (
            this.props.carsData[carid] &&
            this.props.carsData[carid]?.last_insurance_entry === undefined
        ) {
            this.props.getLastCarInsuranceEntryRequest(`${carid}`)
        }

        if (
            this.props.carsData[carid] &&
            this.props.carsData[carid].has_limited_garage_info === false
        ) {
            if (
                checkIfCarHasMissingJourneyInfo(
                    this.props.carsData[carid],
                    this.props.highlightedFactsData
                )
            ) {
                this.displayJourneyCard()
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        let userLoggedIn = this.props.userLoggedIn
        let carid = this.props.match.params.carid

        if (
            this.props.userLoggedIn !== prevProps.userLoggedIn &&
            userLoggedIn
        ) {
            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(carid)
            }

            if (
                this.props.carsData[carid] &&
                this.props.carsData[carid].has_limited_garage_info === true
            ) {
                this.props.getCarDataByIdRequest(carid)
            }
        }

        if (this.props.carsData[carid] !== prevProps.carsData[carid]) {
            if (
                this.props.carsData[carid]?.last_insurance_entry ===
                    undefined &&
                this.props.carsData[carid]?.archived_on === undefined
            ) {
                this.props.getLastCarInsuranceEntryRequest(`${carid}`)
            }
        }

        if (
            this.props.carError !== prevProps.carError &&
            this.state.isCarArchiveOpen === true &&
            this.props.carError
        ) {
            this.setState({
                ...this.state,
                isCarArchiveOpen: false,
            })
        }
        if (
            this.props.carsData[carid] &&
            this.props.carsData[carid].has_limited_garage_info !==
                prevProps.carsData[carid]?.has_limited_garage_info
        ) {
            if (
                this.props.carsData[carid] &&
                this.props.carsData[carid].has_limited_garage_info === false
            ) {
                if (
                    checkIfCarHasMissingJourneyInfo(
                        this.props.carsData[carid],
                        this.props.highlightedFactsData
                    )
                ) {
                    this.displayJourneyCard()
                }
            }
        }
    }

    checkDirection = () => {
        if (this.props.location.state !== undefined) {
            if (
                this.props.location.state.prevPath ===
                '/car/technical-information'
            ) {
                return 'left'
            }
            if (this.props.location.state.prevPath === '/car/gallery') {
                return 'right'
            } else return 'right'
        } else return 'right'
    }

    setTrueBoxInfoToggle = () =>
        this.setState({ ...this.state, showBoxInfo: true })

    setFalseBoxInfoToggle = () =>
        this.setState({ ...this.state, showBoxInfo: false })

    setIsCarMenuOpened = (p: boolean) => {
        let carid = this.props.match.params.carid
        if (
            p === true &&
            this.state.carActionsChecked === false &&
            this.props.carsData[carid]
        ) {
            this.setState({
                ...this.state,
                isCarMenuOpened: p,
                carActionsChecked: true,
                activeCarActionMenuItemID: this.state.activeCarActionMenuItemID,
            })
            this.props.getCarActionCheckRequest({
                carid: carid,
            })
        } else {
            this.setState({
                ...this.state,
                isCarMenuOpened: p,
                activeCarActionMenuItemID:
                    p === false
                        ? undefined
                        : this.state.activeCarActionMenuItemID,
            })
        }
    }

    render() {
        const {
            isCarLoading,
            isUserLoading,
            isNecessaryGalleryDataLoading,
            toggleCarOverviewEditForm,
            toggleCarGalleryEditForm,
            toggleCarHighlightsEditForm,
            isCarDescriptionFormEditOpened,
            toggleCarDescriptionEditForm,
            writeCarDataGeneralRequest,
            toggleCarBannerImageForm,
            userLoggedIn,
            manageUserTutorialByIdCheck,
            carsTimelineData,
            attachmentsObj,
            images_data,
            userCurrency,
            carsData,
            location,
            manageTimelineCreationFormState,
            carShowroomEntryWasDeleted,
            cleanupDeletedShowroomEntryId,
        } = this.props

        let carid = this.props.match.params.carid
        let current: ICar | null | undefined =
            carsData && carid && carsData[carid]

        let onboardingData = this.props.match.params.onboarding
        const params = new URLSearchParams(location.search)

        const onboardingDataParsed = qs.parse(onboardingData)

        const default_fuel_unit_user: string | null =
            userLoggedIn &&
            userLoggedIn.preferences &&
            userLoggedIn.preferences.data &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'] &&
            userLoggedIn.preferences.data['units'].data['fuel_unit']
                .user_choice_id

        const default_fuel_unit_user_text: string | null | undefined =
            default_fuel_unit_user &&
            userLoggedIn &&
            userLoggedIn.preferences &&
            userLoggedIn.preferences.data &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'] &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'].data &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'].data[
                default_fuel_unit_user
            ] &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'].data[
                default_fuel_unit_user
            ].short

        let all_entries: ITimelineItem[] | null | undefined =
            current &&
            carsTimelineData &&
            current.id &&
            carsTimelineData[current.id]
                ? carsTimelineData[current.id].entries
                : []

        let isShortDescriptionLong =
            current &&
            (!current.short_description ||
                current.short_description === '' ||
                (current.short_description &&
                    current.short_description.length > 180))
                ? true
                : false

        let hasGalleryContent =
            current &&
            current.gallery?.images &&
            current.gallery.images.length > 0
                ? true
                : false

        let hasKMContentCheck = (obj: IHighlightedFact) => {
            if (!obj.text || (obj.text && obj.text.length < 1)) {
                return false
            } else return true
        }

        let hasKMContent = current?.highlighted_facts?.filter(
            (kmid: string, index: number) => {
                if (this.props.highlightedFactsData[kmid]) {
                    return hasKMContentCheck(
                        this.props.highlightedFactsData[kmid]
                    )
                } else return false
            }
        )

        let hasNoDescription =
            !current ||
            (current &&
                current.description &&
                current.description.length < 1) ||
            current?.description === '<p><br></p>'
                ? true
                : false

        let hasShowroomDraft: boolean =
            current &&
            current.showroom_entry &&
            current.showroom_entry.state === 'DRAFT'
                ? true
                : false

        let hasShowroomPublished: boolean =
            current &&
            current.showroom_entry &&
            current.showroom_entry.state === 'PUBLISHED'
                ? true
                : false

        let hasSoldShowroomEntry: boolean =
            current &&
            current.showroom_entry &&
            current.showroom_entry.state === 'SOLD'
                ? true
                : false

        // let hasMakeAndModel: boolean =
        //     current &&
        //     current.overview &&
        //     current.overview.data &&
        //     current.overview.data.make?.answer !== '-' &&
        //     current.overview.data.model?.answer !== '-'
        //         ? true
        //         : false

        const openShowroomflow = () => {
            if (
                (hasSoldShowroomEntry ||
                    hasShowroomDraft ||
                    hasShowroomPublished) &&
                current &&
                current.showroom_entry
            ) {
                this.props.history.push(
                    `/showroom/${current.showroom_entry.uid}/edit`
                )
            } else {
                this.setState({
                    ...this.state,
                    isCarMenuOpened: false,
                    isShowroomPublishOpen: true,
                })
                if (window.innerWidth < 881) {
                    this.props.history.push(`/car/${carid}/showroom/create`)
                }
            }
        }

        let currentCarHighlightedFactsIds = current?.highlighted_facts
        let currentCarHighlightedFacts =
            currentCarHighlightedFactsIds &&
            currentCarHighlightedFactsIds.filter(
                (id) => this.props.highlightedFactsData[id].text !== ''
            )

        let numberOfKeyMomAndFacts = currentCarHighlightedFacts
            ? currentCarHighlightedFacts.length
            : 0

        const generateAddOptions = (): CarOptionMenuItem[] => {
            let options: CarOptionMenuItem[] = []

            if (
                (current && !current.short_description) ||
                (current &&
                    current.short_description &&
                    current.short_description.length < 3)
            ) {
                options = [
                    ...options,
                    {
                        id: 'vehicle_bio',
                        name: 'Car Title & Bio',
                        icon: 'vehicle_bio',
                        action: (id: string) => {
                            toggleCarOverviewEditForm()
                        },
                    },
                ]
            }

            if (current && !current.banner_url) {
                options = [
                    ...options,
                    {
                        id: 'banner_image',
                        name: 'Banner Image',
                        icon: 'image',
                        action: (id: string) => {
                            toggleCarBannerImageForm()
                        },
                    },
                ]
            }

            options = [
                ...options,
                {
                    id: 'photos',
                    name: 'Photos',
                    icon: 'image',
                    action: (id: string) => {
                        toggleCarGalleryEditForm()
                    },
                },
                {
                    id: 'history_files',
                    name: 'History Files',
                    icon: 'history_file_menu',
                    action: (id: string) => {
                        this.props.history.push(
                            `/car/${carid}/history-file/create`
                        )

                        manageTimelineCreationFormState({
                            isOpen: true,
                            current_step_index: 0,
                            active_creation:
                                TimelineCreationTypeIdsEnum.add_new,
                        })
                    },
                },
                {
                    id: 'task',
                    name: 'Task',
                    icon: 'task',
                    action: (id: string) => {
                        this.props.history.push(`/car/${carid}/tasks`)
                    },
                },
            ]

            if (numberOfKeyMomAndFacts < 6) {
                options = [
                    ...options,
                    {
                        id: 'key_moments_and_facts',
                        name: 'Key Moments and Facts',
                        icon: 'key_moments_and_facts',
                        action: (id: string) => {
                            toggleCarHighlightsEditForm()
                        },
                    },
                ]
            }

            if (hasNoDescription) {
                options = [
                    ...options,
                    {
                        id: 'description',
                        name: 'Description',
                        icon: 'description',
                        action: (id: string) => {
                            toggleCarDescriptionEditForm()
                        },
                    },
                ]
            }

            return options
        }

        const generateEditOptions = (): CarOptionMenuItem[] => {
            let options: CarOptionMenuItem[] = []

            if (
                current &&
                current.short_description &&
                current.short_description.length > 3
            ) {
                options = [
                    ...options,
                    {
                        id: 'vehicle_bio',
                        name: 'Car Title & Bio',
                        icon: 'vehicle_bio',
                        action: (id: string) => {
                            toggleCarOverviewEditForm()
                        },
                    },
                ]
            }

            if (current && current.banner_url) {
                options = [
                    ...options,
                    {
                        id: 'banner_image',
                        name: 'Banner Image',
                        icon: 'image',
                        action: (id: string) => {
                            toggleCarBannerImageForm()
                        },
                    },
                ]
            }

            if (numberOfKeyMomAndFacts > 0) {
                options = [
                    ...options,
                    {
                        id: 'key_moments_and_facts',
                        name: 'Key Moments and Facts',
                        icon: 'key_moments_and_facts',
                        action: (id: string) => {
                            toggleCarHighlightsEditForm()
                        },
                    },
                ]
            }

            if (!hasNoDescription) {
                options = [
                    ...options,
                    {
                        id: 'description',
                        name: 'Description',
                        icon: 'description',
                        action: (id: string) => {
                            toggleCarDescriptionEditForm()
                        },
                    },
                ]
            }

            return options
        }

        const generateMoreOptions = (theme: ITheme): CarOptionMenuItem[] => {
            let carActionsChecks = current?.car_actions_checks
            let car_checks_loading = this.props.car_checks_loading
            let options: CarOptionMenuItem[] = []
            options = [
                {
                    id: 'share_link',
                    name:
                        current?.public_share_owned &&
                        Object.keys(current?.public_share_owned).length > 0
                            ? 'Manage Share Link'
                            : 'Create Share Link',
                    icon: 'menu_share',
                    action: (id: string) => {
                        this.props.history.push(`/car/${carid}/sharing`)
                    },
                },
                {
                    id: 'car_handover',
                    name: 'Car Handover',
                    icon: 'car_handover',
                    iconLoading: car_checks_loading,
                    action: (id: string) => {
                        if (
                            current &&
                            current.id &&
                            carActionsChecks?.handover?.allowed
                        ) {
                            this.props.history.push(`/handover/${current.id}`)
                        }
                    },
                    customColor: carActionsChecks?.handover?.allowed
                        ? undefined
                        : colours[theme].text_disabled,
                    isDisabled: carActionsChecks?.handover?.allowed
                        ? false
                        : true,
                    tooltip:
                        carActionsChecks?.handover?.allowed === false
                            ? carActionsChecks?.handover?.message ??
                              'Handover is not available.'
                            : undefined,
                },
            ]

            if (
                this.props.userLoggedIn?.role === 'Admin' ||
                this.props.userLoggedIn?.role === 'Dealer'
            ) {
                options = [
                    ...options,
                    {
                        id: 'showroom',
                        name: hasSoldShowroomEntry
                            ? 'Edit Showroom Entry'
                            : hasShowroomDraft
                            ? 'Edit Showroom Draft'
                            : hasShowroomPublished
                            ? 'Edit Showroom Entry'
                            : 'Publish to Showroom',
                        icon: 'showroom',
                        action: (id: string) => {
                            openShowroomflow()
                        },
                    },
                ]
            }

            options = [
                ...options,
                {
                    id: 'archive',
                    name: 'Archive',
                    icon: 'archive',
                    iconLoading: car_checks_loading,
                    action: (id: string) => {
                        if (carActionsChecks?.archive?.allowed) {
                            if (window.innerWidth < 881) {
                                this.props.history.push(`/car/${carid}/archive`)
                            } else this.openCarArchived()
                        }
                    },
                    customColor: carActionsChecks?.archive?.allowed
                        ? colours[theme].error
                        : colours[theme].text_disabled,

                    isDisabled: carActionsChecks?.archive?.allowed
                        ? false
                        : true,
                    tooltip:
                        carActionsChecks?.archive?.allowed === false
                            ? carActionsChecks?.archive?.message ??
                              'Car archiving is not available.'
                            : undefined,
                },
            ]
            return options
        }

        let carAtionMenuItemsData = (
            theme: ITheme
        ): ICarActionMenuItemsData => {
            return {
                add: {
                    id: 'add',
                    name: 'Add New',
                    onClick: () => {
                        this.setState({
                            ...this.state,
                            activeCarActionMenuItemID:
                                this.state.activeCarActionMenuItemID === 'add'
                                    ? undefined
                                    : 'add',
                        })
                    },
                    options: generateAddOptions(),
                },
                edit: {
                    id: 'edit',
                    name: 'Edit',
                    onClick: () => {
                        this.setState({
                            ...this.state,
                            activeCarActionMenuItemID:
                                this.state.activeCarActionMenuItemID === 'edit'
                                    ? undefined
                                    : 'edit',
                        })
                    },
                    options: generateEditOptions(),
                },
                more: {
                    id: 'more',
                    name: 'More Options',
                    onClick: () => {
                        this.setState({
                            ...this.state,
                            activeCarActionMenuItemID:
                                this.state.activeCarActionMenuItemID === 'more'
                                    ? undefined
                                    : 'more',
                        })
                    },
                    options: generateMoreOptions(theme),
                },
            }
        }

        // const menuCarOverview: IThreeDotsActionsItems[] = []

        let insurance_timeline_item: ITimelineItem | undefined | null =
            this.props.carsData[`${carid}`]?.last_insurance_entry

        let isInsuranceWithin30Days: boolean =
            insurance_timeline_item &&
            insurance_timeline_item.insurance_entry_policy_end_date
                ? isDateWithinAMonth(
                      insurance_timeline_item.insurance_entry_policy_end_date
                  )
                : false

        let hasInsuranceExpired: boolean =
            insurance_timeline_item &&
            insurance_timeline_item.insurance_entry_policy_end_date
                ? hasDateExpired(
                      insurance_timeline_item.insurance_entry_policy_end_date
                  )
                : false

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <React.Fragment>
                        {current?.archived_on &&
                            !this.state.isCarArchiveOpen && (
                                <Redirect
                                    to={`/archives/car/${current.id}/overview`}
                                />
                            )}
                        <FabsMenusMobile hasAdd={true} hasSubNav={true} />
                        <Loader
                            isLoading={
                                isCarLoading ||
                                isUserLoading ||
                                isNecessaryGalleryDataLoading
                            }
                        />

                        {params.get('tutorial') !== 'true' &&
                            !params.get('id') &&
                            getCookie('android_install_prompt') !== 'seen' && (
                                <AndroidInstallPwaInstructionsPropmt />
                            )}
                        {params.get('tutorial') === 'true' && (
                            <TutorialConfigsCarMobile
                                registerCarTutorial={() =>
                                    manageUserTutorialByIdCheck('car')
                                }
                            />
                        )}
                        {params.get('tutorial') === 'true' && (
                            <TutorialConfigsCarDesktop
                                registerCarTutorial={() =>
                                    manageUserTutorialByIdCheck('car')
                                }
                            />
                        )}

                        {this.state.isCarArchiveOpen ? (
                            <ArchiveCarDesktopFormManager
                                carid={carid}
                                isOpen={this.state.isCarArchiveOpen}
                                toggle={(p?: boolean) => {
                                    this.setState({
                                        ...this.state,
                                        isCarMenuOpened: false,
                                        isCarArchiveOpen:
                                            !this.state.isCarArchiveOpen,
                                    })
                                }}
                            />
                        ) : null}

                        {carShowroomEntryWasDeleted && (
                            <ModalDisplayAbsolute isOpen toggle={() => {}}>
                                <ShowroomModalInnerContainer
                                    style={{ height: '386px', padding: 50 }}
                                >
                                    <CarRemovedConfirmationModalDesktop
                                        continue={() => {
                                            cleanupDeletedShowroomEntryId(
                                                undefined
                                            )
                                        }}
                                    />
                                </ShowroomModalInnerContainer>
                            </ModalDisplayAbsolute>
                        )}

                        {params.get('native_app_prompt') === 'true' && (
                            <>
                                <NativeAppPromptDesktop
                                    onClose={() =>
                                        this.props.history.push(
                                            `/car/${carid}?tutorial=true`
                                        )
                                    }
                                />

                                <NativeAppPromptMobile
                                    onClose={() =>
                                        this.props.history.push(
                                            `/car/${carid}?tutorial=true`
                                        )
                                    }
                                    isOpen={
                                        params.get('native_app_prompt') ===
                                        'true'
                                    }
                                />
                            </>
                        )}

                        {this.state.isShowroomPublishOpen ? (
                            <>
                                <CreateShowroomEntryFormDesktop
                                    carid={carid}
                                    isOpen={this.state.isShowroomPublishOpen}
                                    toggle={(p: boolean) => {
                                        this.setState({
                                            ...this.state,
                                            isCarMenuOpened: false,
                                            isShowroomPublishOpen: p,
                                        })
                                    }}
                                />
                            </>
                        ) : null}

                        {!current !== null &&
                            current !== undefined &&
                            current &&
                            userLoggedIn &&
                            default_fuel_unit_user_text && (
                                <>
                                    <DesktopDisplayOnly>
                                        {/* DESKTOP BANNER SECTION */}
                                        <CenteredPageWrapper
                                            removePaddings={true}
                                        >
                                            <SectionsWrap>
                                                <div
                                                    style={{
                                                        paddingTop: '90px',
                                                    }}
                                                />
                                                {this.state.showJourneyCard ? (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            paddingBottom: 32,
                                                        }}
                                                    >
                                                        <FadedSlower>
                                                            <JourneyRedirectCardDesktop
                                                                sectionID="car_profile"
                                                                dataPH={
                                                                    'start_car_profile_journey'
                                                                }
                                                                copyData={
                                                                    journeyRedirectCardsCopy
                                                                }
                                                                onStartNow={() => {
                                                                    this.props.history.push(
                                                                        {
                                                                            pathname: `/journey/car-profile`,
                                                                            search: `?step=welcome&carid=${carid}`,
                                                                            state: {
                                                                                prevPath: `${
                                                                                    this
                                                                                        .props
                                                                                        .history
                                                                                        .location
                                                                                        .pathname
                                                                                }${
                                                                                    this
                                                                                        .props
                                                                                        .history
                                                                                        .location
                                                                                        .search ??
                                                                                    ''
                                                                                }`,
                                                                            },
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                        </FadedSlower>
                                                    </div>
                                                ) : null}
                                                <div
                                                    data-tut="second-step-car-desktop"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <CarOverviewBanner
                                                        toggleCarBannerImageForm={
                                                            toggleCarBannerImageForm
                                                        }
                                                        img={current.banner_url}
                                                        hasSvgBackgroundImage
                                                    />

                                                    <div
                                                        data-tut="car-actions-tuto-desktop"
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            bottom: '-20px',
                                                            right: '16px',
                                                            zIndex: 2,
                                                        }}
                                                    >
                                                        <CarActionMenu
                                                            isCarMenuOpened={
                                                                this.state
                                                                    .isCarMenuOpened
                                                            }
                                                            activeID={
                                                                this.state
                                                                    .activeCarActionMenuItemID
                                                            }
                                                            carAtionMenuItemsData={carAtionMenuItemsData(
                                                                theme
                                                            )}
                                                            setIsCarMenuOpened={
                                                                this
                                                                    .setIsCarMenuOpened
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                {/* <SectionHeader
                                            customFontWeight={500}
                                            title={current.title}
                                            customFontSize={'32px'}
                                            openForm={toggleCarOverviewEditForm}
                                        /> */}

                                                {/* DESKTOP INTRO SECTION */}
                                                <FirstSectionWrap>
                                                    <FirstSectionCol1>
                                                        <CarUserAndTitleBox
                                                            url={
                                                                userLoggedIn.profile_image_url
                                                            }
                                                            name={
                                                                userLoggedIn.display_name
                                                            }
                                                            car_title={
                                                                current.title
                                                            }
                                                            openForm={
                                                                toggleCarOverviewEditForm
                                                            }
                                                        />
                                                        <OneLiner
                                                            italic={false}
                                                        >
                                                            {current.short_description &&
                                                            current
                                                                .short_description
                                                                .length > 1 ? (
                                                                current.short_description
                                                            ) : (
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                        height:
                                                                            theme ===
                                                                            'light'
                                                                                ? '100%'
                                                                                : 152,
                                                                        position:
                                                                            'relative',
                                                                        backgroundColor:
                                                                            theme ===
                                                                            'dark'
                                                                                ? colours[
                                                                                      theme
                                                                                  ]
                                                                                      .background_muted
                                                                                : '',
                                                                    }}
                                                                    onClick={(
                                                                        e: any
                                                                    ) => {
                                                                        e.preventDefault()
                                                                        toggleCarOverviewEditForm()
                                                                    }}
                                                                    role="button"
                                                                >
                                                                    {theme ===
                                                                    'light' ? (
                                                                        <img
                                                                            src={
                                                                                short_description_skeleton
                                                                            }
                                                                            alt="car short description placeholder"
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                            }}
                                                                        />
                                                                    ) : null}
                                                                    <div
                                                                        style={{
                                                                            position:
                                                                                'absolute',
                                                                            left: '50%',
                                                                            top: '50%',
                                                                            transform:
                                                                                'translate(-50%, -50%)',
                                                                        }}
                                                                    >
                                                                        <ButtonAtom
                                                                            theme="lowercase-blue-background"
                                                                            fontSize={
                                                                                14
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    textTransform:
                                                                                        'none',
                                                                                }}
                                                                            >
                                                                                Add
                                                                                short
                                                                                description
                                                                            </div>
                                                                        </ButtonAtom>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </OneLiner>

                                                        <CarOverviewInfoRow
                                                            outstanding_tasks_number={
                                                                current.tasks_count
                                                            }
                                                            car_entries_count={
                                                                current.car_entries_count ??
                                                                0
                                                            }
                                                        />
                                                    </FirstSectionCol1>
                                                    <GridSeparation>
                                                        <OverviewGridManagerDesktop
                                                            ids={
                                                                current.overview &&
                                                                current.overview
                                                                    .list
                                                                    ? current
                                                                          .overview
                                                                          .list
                                                                    : [
                                                                          'make',
                                                                          'model',
                                                                          'year',
                                                                          'engine_capacity',
                                                                          'transmission',
                                                                          'drive_side',
                                                                      ]
                                                            }
                                                            data={
                                                                current &&
                                                                current.overview &&
                                                                current.overview
                                                                    .data
                                                            }
                                                            carid={carid}
                                                            fuel_unit_text={
                                                                default_fuel_unit_user_text
                                                                    ? default_fuel_unit_user_text
                                                                    : '-'
                                                            }
                                                            fuel_unit={
                                                                default_fuel_unit_user
                                                                    ? default_fuel_unit_user
                                                                    : '-'
                                                            }
                                                            isShortDescriptionLong={
                                                                isShortDescriptionLong
                                                            }
                                                            addKeyTechInfo={() =>
                                                                this.setState({
                                                                    ...this
                                                                        .state,
                                                                    activeLocalForm:
                                                                        'tech_info',
                                                                })
                                                            }
                                                        />
                                                    </GridSeparation>
                                                </FirstSectionWrap>
                                                {/* DESKTOP GALLERY SECTION */}
                                            </SectionsWrap>
                                        </CenteredPageWrapper>

                                        <div
                                            style={{
                                                backgroundColor:
                                                    colours[theme]
                                                        .background_neutral_subtle,
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <CenteredPageWrapper
                                                removePaddings={true}
                                            >
                                                <div
                                                    data-tut="gallery-overview-car-tuto-desktop"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <SectionsWrap>
                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '22px',
                                                            }}
                                                        />
                                                        <SectionHeader
                                                            openForm={
                                                                toggleCarGalleryEditForm
                                                            }
                                                            title=""
                                                            removeEdit
                                                            customPadding="0px"
                                                            rowChildren={() => (
                                                                <Row>
                                                                    <ButtonAtom
                                                                        theme={
                                                                            !hasGalleryContent
                                                                                ? 'lowercase-blue-background'
                                                                                : 'naked-add-text'
                                                                        }
                                                                        onClick={
                                                                            toggleCarGalleryEditForm
                                                                        }
                                                                        fontSize={
                                                                            16
                                                                        }
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                textTransform:
                                                                                    'none',
                                                                                cursor: 'pointer',
                                                                                minWidth: 110,
                                                                                display:
                                                                                    'flex',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            {!hasGalleryContent
                                                                                ? 'Start adding photos of your car'
                                                                                : 'Add photos'}
                                                                            <span
                                                                                style={{
                                                                                    paddingLeft: 8,
                                                                                    fontSize: 24,
                                                                                }}
                                                                            >
                                                                                +
                                                                            </span>
                                                                        </div>
                                                                    </ButtonAtom>
                                                                </Row>
                                                            )}
                                                        />

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '22px',
                                                            }}
                                                        />

                                                        <GalleryOverviewDataManagerDesktop
                                                            overview_images_ids={
                                                                current?.gallery
                                                                    ?.overview
                                                            }
                                                            images_data={
                                                                images_data
                                                            }
                                                            isNecessaryGalleryDataLoading={
                                                                isNecessaryGalleryDataLoading
                                                            }
                                                            cover_id={
                                                                current?.gallery
                                                                    ?.cover
                                                            }
                                                            onClick={
                                                                toggleCarGalleryEditForm
                                                            }
                                                            images_ids={
                                                                current?.gallery
                                                                    ?.images
                                                            }
                                                            carid={carid}
                                                            openImgForm={
                                                                toggleCarGalleryEditForm
                                                            }
                                                        />

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '90px',
                                                            }}
                                                        />
                                                    </SectionsWrap>
                                                </div>
                                            </CenteredPageWrapper>
                                        </div>

                                        {/* DESKTOP HIGHLIGHTS SECTION */}
                                        <CenteredPageWrapper
                                            removePaddings={true}
                                            removeHorizPaddings
                                        >
                                            <SectionsWrap>
                                                <div
                                                    data-tut="key-moments-car-tuto-desktop"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <KeyMomNFactsHeaderWrapper>
                                                        <SectionHeader
                                                            customPadding={
                                                                '48px'
                                                            }
                                                            openForm={
                                                                toggleCarHighlightsEditForm
                                                            }
                                                            sectionPadding={
                                                                false
                                                            }
                                                            title="Key Moments & Facts"
                                                            removeEdit={
                                                                hasKMContent &&
                                                                hasKMContent.length ===
                                                                    0
                                                                    ? true
                                                                    : false
                                                            }
                                                            rowChildren={() => {
                                                                if (
                                                                    hasKMContent &&
                                                                    hasKMContent.length ===
                                                                        0
                                                                ) {
                                                                    return (
                                                                        <Row>
                                                                            <ButtonAtom
                                                                                fontSize={
                                                                                    14
                                                                                }
                                                                                theme="lowercase-blue-background"
                                                                                onClick={() => {
                                                                                    toggleCarHighlightsEditForm()
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        textTransform:
                                                                                            'none',
                                                                                        cursor: 'pointer',
                                                                                    }}
                                                                                >
                                                                                    Write
                                                                                    up
                                                                                    to
                                                                                    5
                                                                                    key
                                                                                    facts
                                                                                    about
                                                                                    your
                                                                                    car
                                                                                </div>
                                                                            </ButtonAtom>
                                                                        </Row>
                                                                    )
                                                                } else
                                                                    return null
                                                            }}
                                                        />
                                                    </KeyMomNFactsHeaderWrapper>
                                                    <CarKeyMomentsfactsDesktopV2
                                                        highlighted_facts_ids={
                                                            current.highlighted_facts
                                                        }
                                                        onClick={() =>
                                                            toggleCarHighlightsEditForm()
                                                        }
                                                    />
                                                </div>
                                            </SectionsWrap>
                                        </CenteredPageWrapper>

                                        <div style={{ paddingTop: '20px' }} />

                                        <div
                                            style={{
                                                backgroundColor:
                                                    colours[theme]
                                                        .background_neutral_subtle,
                                                width: '100%',
                                            }}
                                        >
                                            <CenteredPageWrapper
                                                removePaddings={true}
                                            >
                                                <SectionsWrap>
                                                    <div
                                                        style={{
                                                            // maxWidth: !hasNoDescription
                                                            //     ? '900px'
                                                            //     : '100%',
                                                            // paddingRight: '100px',
                                                            // paddingLeft: '100px',
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '48px',
                                                            }}
                                                        />

                                                        <SectionIconRowDescription>
                                                            <div>
                                                                <img
                                                                    style={{
                                                                        width: '128px',
                                                                    }}
                                                                    src={
                                                                        theme ===
                                                                        'light'
                                                                            ? description_section_icon_desktop
                                                                            : description_section_icon_desktop_dark
                                                                    }
                                                                />
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <SectionHeader
                                                                    openForm={
                                                                        toggleCarDescriptionEditForm
                                                                    }
                                                                    rowChildren={() => {
                                                                        if (
                                                                            hasNoDescription
                                                                        ) {
                                                                            return (
                                                                                <Row>
                                                                                    {
                                                                                        <ButtonAtom
                                                                                            fontSize={
                                                                                                14
                                                                                            }
                                                                                            theme="lowercase-blue-background"
                                                                                            onClick={() => {
                                                                                                toggleCarDescriptionEditForm()
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    textTransform:
                                                                                                        'none',
                                                                                                    cursor: 'pointer',
                                                                                                }}
                                                                                            >
                                                                                                Add
                                                                                                car's
                                                                                                description
                                                                                            </div>
                                                                                        </ButtonAtom>
                                                                                    }
                                                                                </Row>
                                                                            )
                                                                        } else
                                                                            return (
                                                                                <ButtonAtom
                                                                                    theme="naked"
                                                                                    onClick={
                                                                                        toggleCarDescriptionEditForm
                                                                                    }
                                                                                >
                                                                                    <Icon icon="edit_pen" />
                                                                                </ButtonAtom>
                                                                            )
                                                                    }}
                                                                    customPadding={
                                                                        '0px'
                                                                    }
                                                                    title="description"
                                                                    removeEdit
                                                                />

                                                                <SimpleDescriptionParagraph
                                                                    description={
                                                                        current.description
                                                                    }
                                                                    onClick={
                                                                        toggleCarDescriptionEditForm
                                                                    }
                                                                />
                                                            </div>
                                                        </SectionIconRowDescription>

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '48px',
                                                            }}
                                                        />
                                                    </div>
                                                </SectionsWrap>
                                            </CenteredPageWrapper>
                                        </div>

                                        <CenteredPageWrapper
                                            removePaddings={true}
                                        >
                                            <SectionsWrap>
                                                <div
                                                    style={{
                                                        // maxWidth: !hasNoDescription
                                                        //     ? '900px'
                                                        //     : '100%',
                                                        // paddingRight: '100px',
                                                        // paddingLeft: '100px',
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            paddingTop: '48px',
                                                        }}
                                                    />

                                                    <SectionIconRowInsurance>
                                                        <div>
                                                            <img
                                                                style={{
                                                                    width: '128px',
                                                                }}
                                                                src={
                                                                    theme ===
                                                                    'light'
                                                                        ? insurance_section_icon_desktop
                                                                        : insurance_section_icon_desktop_dark
                                                                }
                                                            />
                                                        </div>

                                                        <SectionHeader
                                                            openForm={
                                                                toggleCarDescriptionEditForm
                                                            }
                                                            is_warning={
                                                                hasInsuranceExpired ||
                                                                isInsuranceWithin30Days
                                                                    ? true
                                                                    : false
                                                            }
                                                            ChildText={() => {
                                                                if (
                                                                    hasInsuranceExpired
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            Your
                                                                            insurance
                                                                            has
                                                                            expired.{' '}
                                                                            <br />
                                                                            Don't
                                                                            leave
                                                                            your
                                                                            vehicle
                                                                            unprotected.
                                                                            Get
                                                                            a
                                                                            personalized
                                                                            quote
                                                                            tailored
                                                                            to
                                                                            your
                                                                            needs
                                                                            and
                                                                            reinstate
                                                                            coverage
                                                                            today.
                                                                        </>
                                                                    )
                                                                } else if (
                                                                    isInsuranceWithin30Days
                                                                ) {
                                                                    return (
                                                                        <>
                                                                            Your
                                                                            insurance
                                                                            is
                                                                            due
                                                                            for
                                                                            renewal
                                                                            soon.
                                                                            <br />
                                                                            To
                                                                            ensure
                                                                            you
                                                                            continue
                                                                            enjoying
                                                                            comprehensive
                                                                            coverage,
                                                                            get
                                                                            a
                                                                            personalized
                                                                            quote
                                                                            tailored
                                                                            to
                                                                            your
                                                                            needs.
                                                                        </>
                                                                    )
                                                                } else
                                                                    return (
                                                                        <>
                                                                            Insurance
                                                                            plays
                                                                            a
                                                                            critical
                                                                            role
                                                                            in
                                                                            safeguarding
                                                                            your
                                                                            vehicle.
                                                                            If
                                                                            you
                                                                            haven't
                                                                            added
                                                                            your
                                                                            current
                                                                            insurance
                                                                            to
                                                                            your
                                                                            History
                                                                            Files
                                                                            or
                                                                            initiated
                                                                            a
                                                                            policy
                                                                            with
                                                                            Custodian
                                                                            yet,
                                                                            it's
                                                                            time
                                                                            to
                                                                            act!
                                                                        </>
                                                                    )
                                                            }}
                                                            rowChildren={() => {
                                                                return (
                                                                    <ChevronWrapper
                                                                        $theme={
                                                                            theme
                                                                        }
                                                                        href={
                                                                            'https://www.custodian.club/features/insurance'
                                                                        }
                                                                        onClick={() => {
                                                                            posthog.capture(
                                                                                'learn_more insurance clicked'
                                                                            )
                                                                        }}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <ChevronDown
                                                                            color={
                                                                                colours[
                                                                                    theme
                                                                                ]
                                                                                    .primary
                                                                            }
                                                                        />
                                                                    </ChevronWrapper>
                                                                )
                                                            }}
                                                            customPadding={
                                                                '0px'
                                                            }
                                                            title="insurance"
                                                            removeEdit
                                                        />
                                                    </SectionIconRowInsurance>
                                                </div>

                                                <SectionRowPadding
                                                    style={{
                                                        width: '100%',
                                                        paddingTop: '32px',
                                                    }}
                                                >
                                                    <CarInsuranceSectionBodyDesktop
                                                        carid={carid}
                                                        insurance_timeline_item={
                                                            insurance_timeline_item
                                                        }
                                                        hasExpired={
                                                            hasInsuranceExpired
                                                        }
                                                        isWithin30Days={
                                                            isInsuranceWithin30Days
                                                        }
                                                    />
                                                </SectionRowPadding>
                                            </SectionsWrap>

                                            <div
                                                style={{ paddingTop: '16px' }}
                                            />
                                        </CenteredPageWrapper>

                                        <CenteredPageWrapper
                                            removePaddings={true}
                                        >
                                            <SectionsWrap>
                                                <div
                                                    data-tut="timeline-overview-car-tuto-desktop"
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <SectionHeader
                                                        openForm={() => {
                                                            current &&
                                                            current.car_entries_count
                                                                ? this.props.history.push(
                                                                      `/car/${carid}/history-file/create`
                                                                  )
                                                                : this.props.history.push(
                                                                      `/car/${carid}/history-file?section_id=create`
                                                                  )
                                                        }}
                                                        title="History File"
                                                        removeEdit
                                                        addButton={
                                                            true
                                                            // current &&
                                                            // current.car_entries_count &&
                                                            // current.car_entries_count >
                                                            //     0
                                                            //     ? true
                                                            //     : false
                                                        }
                                                        rowChildren={() => {
                                                            return (
                                                                <CarInsightsBtn />
                                                            )
                                                        }}
                                                    />

                                                    <TimelineOverviewManagerDesktop
                                                        entries_array={
                                                            all_entries
                                                        }
                                                        car_entries_count={
                                                            current.car_entries_count
                                                                ? current.car_entries_count
                                                                : 0
                                                        }
                                                        attachmentsObj={
                                                            attachmentsObj
                                                        }
                                                        // costsObj={costsObj}
                                                        carid={carid}
                                                        onPlaceholderClick={() => {
                                                            current &&
                                                            current.car_entries_count
                                                                ? this.props.history.push(
                                                                      `/car/${carid}/history-file/create`
                                                                  )
                                                                : this.props.history.push(
                                                                      `/car/${carid}/history-file?section_id=create`
                                                                  )
                                                        }}
                                                        userCurrency={
                                                            userCurrency
                                                        }
                                                    />
                                                </div>
                                            </SectionsWrap>
                                        </CenteredPageWrapper>

                                        <div style={{ paddingTop: '300px' }} />
                                    </DesktopDisplayOnly>

                                    {onboardingDataParsed.id !== '5' &&
                                        params.get('id') !== '5' &&
                                        onboardingDataParsed.id !== '4' &&
                                        params.get('id') !== '4' &&
                                        onboardingDataParsed.id !== '6' &&
                                        params.get('id') !== '6' &&
                                        onboardingDataParsed.id !== '7' &&
                                        params.get('id') !== '7' && (
                                            <IpadAndMobileDisplay>
                                                <CenteredPageWrapper
                                                    hasMobileSubNav
                                                >
                                                    <Faded>
                                                        {current.banner_url && (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    position:
                                                                        'relative',
                                                                }}
                                                            >
                                                                <FullWidthMobile
                                                                    img={
                                                                        current.banner_url
                                                                    }
                                                                    threeDotsMenu={
                                                                        false
                                                                    }
                                                                    height="160px"
                                                                    onClick={() => {
                                                                        toggleCarBannerImageForm()

                                                                        if (
                                                                            this
                                                                                .state
                                                                                .isCarMenuOpened
                                                                        ) {
                                                                            this.setState(
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                    isCarMenuOpened:
                                                                                        false,
                                                                                }
                                                                            )
                                                                        }
                                                                    }}
                                                                />

                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        bottom: '-22px',
                                                                        right: '16px',
                                                                        zIndex: 2,
                                                                    }}
                                                                >
                                                                    <CarActionMenuMobile
                                                                        isCarMenuOpened={
                                                                            this
                                                                                .state
                                                                                .isCarMenuOpened
                                                                        }
                                                                        activeID={
                                                                            this
                                                                                .state
                                                                                .activeCarActionMenuItemID
                                                                        }
                                                                        carAtionMenuItemsData={carAtionMenuItemsData(
                                                                            theme
                                                                        )}
                                                                        setIsCarMenuOpened={
                                                                            this
                                                                                .setIsCarMenuOpened
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {!current.banner_url && (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    justifyContent:
                                                                        'center',
                                                                    width: '100%',
                                                                    position:
                                                                        'relative',
                                                                    height: '100%',
                                                                }}
                                                            >
                                                                <FullWidthMobile
                                                                    img={{
                                                                        id: 'banner_svg_img',
                                                                        url:
                                                                            theme ===
                                                                            'light'
                                                                                ? banner_placeholder_mobile
                                                                                : banner_placeholder_mobile_dark,
                                                                    }}
                                                                    threeDotsMenu={
                                                                        false
                                                                    }
                                                                    height="80px"
                                                                    onClick={() => {
                                                                        toggleCarBannerImageForm()
                                                                        if (
                                                                            this
                                                                                .state
                                                                                .isCarMenuOpened
                                                                        ) {
                                                                            this.setState(
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                    isCarMenuOpened:
                                                                                        false,
                                                                                }
                                                                            )
                                                                        }
                                                                    }}
                                                                />

                                                                <div
                                                                    style={{
                                                                        position:
                                                                            'absolute',
                                                                        bottom: '-20px',
                                                                        right: '16px',
                                                                        zIndex: 2,
                                                                    }}
                                                                    data-tut="car-actions-tuto-mobile"
                                                                >
                                                                    <CarActionMenuMobile
                                                                        isCarMenuOpened={
                                                                            this
                                                                                .state
                                                                                .isCarMenuOpened
                                                                        }
                                                                        activeID={
                                                                            this
                                                                                .state
                                                                                .activeCarActionMenuItemID
                                                                        }
                                                                        carAtionMenuItemsData={carAtionMenuItemsData(
                                                                            theme
                                                                        )}
                                                                        setIsCarMenuOpened={
                                                                            this
                                                                                .setIsCarMenuOpened
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}

                                                        {current?.title
                                                            ?.length < 1 &&
                                                            current
                                                                ?.short_description
                                                                ?.length <
                                                                2 && (
                                                                <MobileContentBody>
                                                                    <div
                                                                        style={{
                                                                            paddingTop:
                                                                                '20px',
                                                                            marginBottom:
                                                                                '30px',
                                                                            width: '100%',
                                                                            height:
                                                                                theme ===
                                                                                'light'
                                                                                    ? 'auto'
                                                                                    : 152,
                                                                            backgroundColor:
                                                                                theme ===
                                                                                'dark'
                                                                                    ? colours[
                                                                                          theme
                                                                                      ]
                                                                                          .background_muted
                                                                                    : '',
                                                                        }}
                                                                    >
                                                                        {theme ===
                                                                        'light' ? (
                                                                            <div>
                                                                                <img
                                                                                    src={
                                                                                        descSkeleton
                                                                                    }
                                                                                    alt="description skeleton"
                                                                                />
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </MobileContentBody>
                                                            )}

                                                        {/* // IF TITLE AND NO DESC */}
                                                        {current
                                                            ?.short_description
                                                            ?.length < 2 &&
                                                            current?.title
                                                                ?.length >
                                                                1 && (
                                                                <MobileContentBody>
                                                                    <div
                                                                        style={{
                                                                            paddingTop:
                                                                                '24px',
                                                                        }}
                                                                    />
                                                                    <SectionHeader
                                                                        customFontSize={
                                                                            '22px'
                                                                        }
                                                                        customPadding="0px"
                                                                        customFontWeight={
                                                                            500
                                                                        }
                                                                        // openForm={() => {
                                                                        //     toggleCarOverviewEditForm()
                                                                        // }}
                                                                        removeEdit
                                                                        title={
                                                                            current.title
                                                                        }
                                                                    />

                                                                    <CarUserAndTitleBox
                                                                        url={
                                                                            userLoggedIn.profile_image_url
                                                                        }
                                                                        name={
                                                                            userLoggedIn.display_name
                                                                        }
                                                                        car_title={
                                                                            current.title
                                                                        }
                                                                        isMobile={
                                                                            true
                                                                        }
                                                                    />

                                                                    <Expander
                                                                        height={
                                                                            this
                                                                                .state
                                                                                .showJourneyCard
                                                                                ? 'auto'
                                                                                : 0
                                                                        }
                                                                        width="100%"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: '100%',
                                                                                paddingTop: 24,
                                                                            }}
                                                                        >
                                                                            <FadedSlower>
                                                                                <JourneyRedirectCardMobile
                                                                                    dataPH="start_car_profile_journey"
                                                                                    sectionID="car_profile"
                                                                                    copyData={
                                                                                        journeyRedirectCardsCopy
                                                                                    }
                                                                                    onStartNow={() => {
                                                                                        this.props.history.push(
                                                                                            `/journey/car-profile?step=welcome&carid=${carid}`
                                                                                        )
                                                                                    }}
                                                                                    onDismiss={() => {
                                                                                        this.hideJourneyCard()
                                                                                    }}
                                                                                />
                                                                            </FadedSlower>
                                                                        </div>
                                                                    </Expander>

                                                                    <div
                                                                        style={{
                                                                            paddingTop:
                                                                                '24px',
                                                                            marginBottom:
                                                                                '10px',
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                width: '100%',
                                                                                position:
                                                                                    'relative',
                                                                                height:
                                                                                    theme ===
                                                                                    'light'
                                                                                        ? '100%'
                                                                                        : 150,
                                                                                backgroundColor:
                                                                                    theme ===
                                                                                    'dark'
                                                                                        ? colours[
                                                                                              theme
                                                                                          ]
                                                                                              .background_neutral_subtle
                                                                                        : '',
                                                                            }}
                                                                        >
                                                                            <EmptyBtn>
                                                                                <ButtonAtom
                                                                                    theme={
                                                                                        'lowercase-blue-background'
                                                                                    }
                                                                                    fontSize={
                                                                                        14
                                                                                    }
                                                                                    onClick={() => {
                                                                                        toggleCarOverviewEditForm()
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            textTransform:
                                                                                                'none',
                                                                                            cursor: 'pointer',
                                                                                        }}
                                                                                    >
                                                                                        Add
                                                                                        car’s
                                                                                        bio
                                                                                    </div>
                                                                                </ButtonAtom>
                                                                            </EmptyBtn>

                                                                            {theme ===
                                                                            'light' ? (
                                                                                <img
                                                                                    src={
                                                                                        descSkeleton
                                                                                    }
                                                                                    alt="description skeleton"
                                                                                    style={{
                                                                                        height: '150px',
                                                                                        width: '100%',
                                                                                    }}
                                                                                />
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </MobileContentBody>
                                                            )}
                                                        {/* // IF TITLE AND DESC */}
                                                        {current
                                                            ?.short_description
                                                            ?.length > 2 &&
                                                            current?.title
                                                                ?.length >
                                                                1 && (
                                                                <MobileContentBody>
                                                                    <IpadWrapPadding
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                paddingTop:
                                                                                    '24px',
                                                                            }}
                                                                        />
                                                                        <SectionHeader
                                                                            customFontSize={
                                                                                '19px'
                                                                            }
                                                                            customPadding="0px"
                                                                            customFontWeight={
                                                                                500
                                                                            }
                                                                            // openForm={() => {
                                                                            //     toggleCarOverviewEditForm()
                                                                            // }}
                                                                            removeEdit
                                                                            title={
                                                                                current.title
                                                                            }
                                                                        />
                                                                        <MobilePadding />
                                                                        <CarUserAndTitleBox
                                                                            url={
                                                                                userLoggedIn.profile_image_url
                                                                            }
                                                                            name={
                                                                                userLoggedIn.display_name
                                                                            }
                                                                            car_title={
                                                                                current.title
                                                                            }
                                                                            isMobile
                                                                        />
                                                                        <Expander
                                                                            height={
                                                                                this
                                                                                    .state
                                                                                    .showJourneyCard
                                                                                    ? 'auto'
                                                                                    : 0
                                                                            }
                                                                            width="100%"
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    width: '100%',
                                                                                    paddingTop: 24,
                                                                                }}
                                                                            >
                                                                                <FadedSlower>
                                                                                    <JourneyRedirectCardMobile
                                                                                        dataPH="start_car_profile_journey"
                                                                                        sectionID="car_profile"
                                                                                        copyData={
                                                                                            journeyRedirectCardsCopy
                                                                                        }
                                                                                        onStartNow={() => {
                                                                                            this.props.history.push(
                                                                                                `/journey/car-profile?step=welcome&carid=${carid}`
                                                                                            )
                                                                                        }}
                                                                                        onDismiss={() => {
                                                                                            this.hideJourneyCard()
                                                                                        }}
                                                                                    />
                                                                                </FadedSlower>
                                                                            </div>
                                                                        </Expander>

                                                                        <div
                                                                            style={{
                                                                                paddingTop:
                                                                                    '24px',
                                                                            }}
                                                                            onClick={() => {
                                                                                if (
                                                                                    this
                                                                                        .state
                                                                                        .isCarMenuOpened
                                                                                ) {
                                                                                    this.setState(
                                                                                        {
                                                                                            ...this
                                                                                                .state,
                                                                                            isCarMenuOpened:
                                                                                                false,
                                                                                        }
                                                                                    )
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Paragraph
                                                                                ipadAlign="left"
                                                                                align="left"
                                                                                removeIpadPadding={
                                                                                    true
                                                                                }
                                                                            >
                                                                                {
                                                                                    current.short_description
                                                                                }
                                                                            </Paragraph>
                                                                        </div>
                                                                    </IpadWrapPadding>
                                                                </MobileContentBody>
                                                            )}

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '16px',
                                                            }}
                                                        />

                                                        <MobileContentBody>
                                                            <IpadWrapUserPadding
                                                                onClick={() => {
                                                                    if (
                                                                        this
                                                                            .state
                                                                            .isCarMenuOpened
                                                                    ) {
                                                                        this.setState(
                                                                            {
                                                                                ...this
                                                                                    .state,
                                                                                isCarMenuOpened:
                                                                                    false,
                                                                            }
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                <CarOverviewInfoRow
                                                                    outstanding_tasks_number={
                                                                        current.tasks_count
                                                                    }
                                                                    car_entries_count={
                                                                        current.car_entries_count ??
                                                                        0
                                                                    }
                                                                />
                                                            </IpadWrapUserPadding>
                                                        </MobileContentBody>

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '40px',
                                                            }}
                                                        />

                                                        <div
                                                            data-tut="gallery-overview-car-tuto-mobile"
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        colours[
                                                                            theme
                                                                        ]
                                                                            .background_default,
                                                                    display:
                                                                        'flex',
                                                                    paddingTop:
                                                                        '16px',
                                                                    paddingBottom:
                                                                        '50px',
                                                                    flexDirection:
                                                                        'column',
                                                                }}
                                                            >
                                                                <MobileContentBody>
                                                                    <SectionHeader
                                                                        titleIcon="gallery_section"
                                                                        openForm={
                                                                            toggleCarGalleryEditForm
                                                                        }
                                                                        title="Gallery"
                                                                        removeEdit={
                                                                            true
                                                                        }
                                                                        addButton
                                                                        customPadding={
                                                                            '0px'
                                                                        }
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            paddingTop: 16,
                                                                        }}
                                                                    />
                                                                </MobileContentBody>
                                                                <GalleryOverviewDataManagerMobile
                                                                    overview_images_ids={
                                                                        current
                                                                            ?.gallery
                                                                            ?.overview
                                                                    }
                                                                    images_data={
                                                                        images_data
                                                                    }
                                                                    isNecessaryGalleryDataLoading={
                                                                        isNecessaryGalleryDataLoading
                                                                    }
                                                                    cover_id={
                                                                        current
                                                                            ?.gallery
                                                                            ?.cover
                                                                    }
                                                                    onClick={
                                                                        toggleCarGalleryEditForm
                                                                    }
                                                                    images_ids={
                                                                        current
                                                                            ?.gallery
                                                                            ?.images
                                                                    }
                                                                    carid={
                                                                        carid
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '40px',
                                                            }}
                                                        />

                                                        <MobileContentBody>
                                                            <CarOverviewGridManagerMobile
                                                                carid={carid}
                                                                ids={
                                                                    current.overview &&
                                                                    current
                                                                        .overview
                                                                        .list
                                                                        ? current
                                                                              .overview
                                                                              .list
                                                                        : [
                                                                              'year',
                                                                              'make',
                                                                              'model',
                                                                              'engine_capacity',
                                                                              'transmission',
                                                                              'drive_side',
                                                                          ]
                                                                }
                                                                data={
                                                                    current &&
                                                                    current.overview &&
                                                                    current
                                                                        .overview
                                                                        .data
                                                                }
                                                                fuel_unit_text={
                                                                    default_fuel_unit_user_text
                                                                        ? default_fuel_unit_user_text
                                                                        : ''
                                                                }
                                                                fuel_unit={
                                                                    default_fuel_unit_user
                                                                        ? default_fuel_unit_user
                                                                        : ''
                                                                }
                                                                addKeyTechInfo={() =>
                                                                    this.setState(
                                                                        {
                                                                            ...this
                                                                                .state,
                                                                            activeLocalForm:
                                                                                'tech_info',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        </MobileContentBody>
                                                        {this.state
                                                            .activeLocalForm ===
                                                            'tech_info' && (
                                                            <ModalDisplay
                                                                isOpen
                                                                toggle={() => {}}
                                                            >
                                                                <ShowroomTechInfoFormMobile
                                                                    close={() => {
                                                                        this.setState(
                                                                            {
                                                                                ...this
                                                                                    .state,
                                                                                activeLocalForm:
                                                                                    null,
                                                                            }
                                                                        )
                                                                    }}
                                                                    car={
                                                                        current
                                                                    }
                                                                />
                                                            </ModalDisplay>
                                                        )}

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '30px',
                                                            }}
                                                        />
                                                        <MobileContentBody>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                            >
                                                                <SectionHeader
                                                                    openForm={
                                                                        toggleCarHighlightsEditForm
                                                                    }
                                                                    titleIcon="kmf_section"
                                                                    title="Key Moments & Facts"
                                                                    customPadding="20px"
                                                                    removeEdit={
                                                                        hasKMContent &&
                                                                        hasKMContent.length ===
                                                                            0
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '7px',
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                                data-tut="key-moments-car-tuto-mobile"
                                                            >
                                                                <CarKeyMomentsFactsMobile
                                                                    highlighted_facts_ids={
                                                                        current.highlighted_facts
                                                                    }
                                                                    onClick={() => {
                                                                        toggleCarHighlightsEditForm()
                                                                    }}
                                                                    isEmpty={
                                                                        hasKMContent &&
                                                                        hasKMContent.length ===
                                                                            0
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '32px',
                                                                }}
                                                            />
                                                            <SectionHeader
                                                                title="description"
                                                                titleIcon="description_section"
                                                                customPadding="0px"
                                                                removeEdit={
                                                                    hasNoDescription
                                                                        ? true
                                                                        : false
                                                                }
                                                                openForm={
                                                                    toggleCarDescriptionEditForm
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '20px',
                                                                }}
                                                            />
                                                            <DescriptionMobile
                                                                description={
                                                                    current.description
                                                                }
                                                                onClick={
                                                                    toggleCarDescriptionEditForm
                                                                }
                                                            />
                                                        </MobileContentBody>

                                                        {!hasNoDescription && (
                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '48px',
                                                                }}
                                                            />
                                                        )}
                                                        <MobileContentBody>
                                                            <SectionHeader
                                                                title="insurance"
                                                                titleIcon="insurance_section"
                                                                customPadding="0px"
                                                                removeEdit={
                                                                    true
                                                                }
                                                                rowChildren={() => {
                                                                    return (
                                                                        <ChevronWrapper
                                                                            $theme={
                                                                                theme
                                                                            }
                                                                            href={
                                                                                'https://www.custodian.club/features/insurance'
                                                                            }
                                                                            onClick={() => {
                                                                                posthog.capture(
                                                                                    'learn_more insurance clicked'
                                                                                )
                                                                            }}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                        >
                                                                            <ChevronDown
                                                                                color={
                                                                                    colours[
                                                                                        theme
                                                                                    ]
                                                                                        .primary
                                                                                }
                                                                            />
                                                                        </ChevronWrapper>
                                                                    )
                                                                }}
                                                            />

                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '12px',
                                                                }}
                                                            />

                                                            <CarInsuranceSectionBodyMobile
                                                                carid={carid}
                                                                insurance_timeline_item={
                                                                    insurance_timeline_item
                                                                }
                                                                hasExpired={
                                                                    hasInsuranceExpired
                                                                }
                                                                isWithin30Days={
                                                                    isInsuranceWithin30Days
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '20px',
                                                                }}
                                                            />
                                                        </MobileContentBody>

                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '32px',
                                                            }}
                                                        />

                                                        <div
                                                            data-tut="timeline-overview-car-tuto-mobile"
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <MobileContentBody>
                                                                <SectionHeader
                                                                    titleIcon="history_file_section"
                                                                    openForm={() => {
                                                                        current &&
                                                                        current.car_entries_count
                                                                            ? this.props.history.push(
                                                                                  `/car/${carid}/history-file/create`
                                                                              )
                                                                            : this.props.history.push(
                                                                                  `/car/${carid}/history-file?section_id=create`
                                                                              )
                                                                    }}
                                                                    title="History File"
                                                                    removeEdit={
                                                                        true
                                                                    }
                                                                    addButton
                                                                    customPadding={
                                                                        '0px'
                                                                    }
                                                                />

                                                                <div
                                                                    style={{
                                                                        paddingTop:
                                                                            '30px',
                                                                    }}
                                                                />

                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    <TimelineOverviewManagerMobile
                                                                        entries_array={
                                                                            all_entries
                                                                        }
                                                                        car_entries_count={
                                                                            current.car_entries_count
                                                                                ? current.car_entries_count
                                                                                : 0
                                                                        }
                                                                        carid={
                                                                            carid
                                                                        }
                                                                        onPlaceholderClick={() => {
                                                                            current &&
                                                                            current.car_entries_count
                                                                                ? this.props.history.push(
                                                                                      `/car/${carid}/history-file/create`
                                                                                  )
                                                                                : this.props.history.push(
                                                                                      `/car/${carid}/history-file?section_id=create`
                                                                                  )
                                                                        }}
                                                                        userCurrency={
                                                                            userCurrency
                                                                        }
                                                                    />
                                                                </div>

                                                                <div
                                                                    style={{
                                                                        paddingBottom:
                                                                            '250px',
                                                                    }}
                                                                />
                                                            </MobileContentBody>
                                                        </div>
                                                    </Faded>
                                                </CenteredPageWrapper>
                                            </IpadAndMobileDisplay>
                                        )}
                                </>
                            )}
                        <DesktopDisplayOnly>
                            {current && (
                                <CarOverviewFormsBodyDesktop
                                    current={current}
                                    localFormOpened={this.state.activeLocalForm}
                                    setActiveLocalForm={() =>
                                        this.setState({
                                            ...this.state,
                                            activeLocalForm: null,
                                        })
                                    }
                                />
                            )}

                            {isCarDescriptionFormEditOpened && (
                                <ModalDisplay
                                    toggle={toggleCarDescriptionEditForm}
                                    isOpen={true}
                                >
                                    <CarDescriptionEditFormDesktop
                                        carId={
                                            current && current.id
                                                ? current.id
                                                : ''
                                        }
                                        writeCarDataGeneralRequest={
                                            writeCarDataGeneralRequest
                                        }
                                        carDescription={
                                            current && current.description
                                                ? current.description
                                                : ''
                                        }
                                        close={toggleCarDescriptionEditForm}
                                    />
                                </ModalDisplay>
                            )}
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            {isCarDescriptionFormEditOpened && (
                                <ModalDisplay
                                    toggle={toggleCarDescriptionEditForm}
                                    isOpen={true}
                                >
                                    <CarDescriptionEditFormMobile
                                        carId={
                                            current && current.id
                                                ? current.id
                                                : ''
                                        }
                                        writeCarDataGeneralRequest={
                                            writeCarDataGeneralRequest
                                        }
                                        carDescription={
                                            current && current.description
                                                ? current.description
                                                : ''
                                        }
                                        close={toggleCarDescriptionEditForm}
                                    />
                                </ModalDisplay>
                            )}

                            {current && (
                                <CarOverviewFormsBodyMobile current={current} />
                            )}

                            {carShowroomEntryWasDeleted && (
                                <ModalDisplay isOpen toggle={() => {}}>
                                    <ShowroomModalContainerMobile>
                                        <CarRemovedConfirmationModalDesktop
                                            continue={() => {
                                                cleanupDeletedShowroomEntryId(
                                                    undefined
                                                )
                                            }}
                                        />
                                    </ShowroomModalContainerMobile>
                                </ModalDisplay>
                            )}
                        </IpadAndMobileDisplay>

                        <IpadAndMobileDisplay>
                            <CarOverviewActionsSheetMobile
                                isOpen={this.state.isCarMenuOpened}
                                data={carAtionMenuItemsData(theme)}
                                toggle={() => {
                                    this.setState({
                                        ...this.state,
                                        isCarMenuOpened: false,
                                    })
                                }}
                                setActiveIDToUndefined={() => {
                                    this.setState({
                                        ...this.state,
                                        activeCarActionMenuItemID: undefined,
                                    })
                                }}
                                activeID={this.state.activeCarActionMenuItemID}
                            />
                        </IpadAndMobileDisplay>
                    </React.Fragment>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CarOverview))
