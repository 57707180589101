import React, { ReactNode } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
// import Loader from "../atoms/loader/loader";
import Faded from '../../templates/animated/faded'

import SectionHeader from '../../atoms/header/section/sectionHeader'
import UpcomingPageS1 from '../../molecules/upcomingPageS1/upcomingPageS1Desktop'

import Loader from '../../atoms/loader/loader'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'

import ExpandingItemsListDesktop from '../../molecules/expandingItemsList/expandingItemsListDesktop'
import ExpandingItemsListMobile from '../../molecules/expandingItemsList/expandingItemsListMobile'

import upVoteImg from '../../../public/assets/upcoming/thumbup.svg'
import ckeckImg from '../../../public/assets/upcoming/check.svg'
import ckeckImgCircle from '../../../public/assets/upcoming/check_with_circle.svg'

import RoadmapMobile from '../../atoms/roadmap/roadmapMobile'

import { IUpcomingPageState, IUpvoteInfo } from 'upcomingPageModels'
import { upvoteShortlistedFeatureThunks } from '../../../redux/localdata/upcoming/actions/thunks'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import NoltWidget from '../../templates/widgets/noltWidget'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'

import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import upcoming_banner from '../../../public/assets/images/upcoming_banner.jpg'
import upcoming_banner_dark from '../../../public/assets/images/upcoming_banner_dark.jpg'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import founders_word from '../../../public/assets/upcoming/founders_word.jpg'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
// const jwt = require('jsonwebtoken')
const jose = require('jose')

interface Props {
    userLoggedIn: null | IUser
    getUserDataRequest: () => void
    userid: string | null
    upcoming_data: IUpcomingPageState
    upvoteShortlistedFeatureThunks: (payload: IUpvoteInfo) => void
    setInitialLandingUrl: (str: string) => void
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        upcoming_data: state.localdata.upcoming_page,
    }
}

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    upvoteShortlistedFeatureThunks: (payload: IUpvoteInfo) =>
        upvoteShortlistedFeatureThunks(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

const SectionDescription = styled.div<IDescriptionProps>`
    display: flex;
    padding: 32px 40px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    background: var(--off-bg-color, #fafafa);
    width: 100%;
    @media ${device.mobile} {
        align-items: center;
        justify-content: center;
        padding: 32px 20px;
    }
`

const BoxFounders = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;

    flex-direction: row;

    margin-top: 50px;

    @media ${device.mobile_and_ipad} {
        flex-direction: column;

        margin: 24px;

        padding: 16px;
        background: var(--off-bg-color, #fafafa);
        border-radius: 4px;
        align-items: center;
        justify-content: center;
    }
`

const FoundersPicDesktop = styled.img`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        width: 314px;
        align-self: stretch;
        object-fit: contain;
    }
`

const FoundersPicMobile = styled.img`
    display: none;
    @media ${device.mobile_and_ipad} {
        display: flex;
        width: 100px;
        height: 100px;
        align-self: center;
        object-fit: cover;
        border-radius: 100px;
    }
`

const Textt = styled.div`
    color: var(--text-default, #616161);

    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    max-width: 458px;

    @media ${device.mobile} {
        padding-left: 20px;
    }

    @media (min-width: 500px) and (max-width: 880px) {
        padding-right: 60px;
        padding-left: 60px;
    }

    @media ${device.mobile_and_ipad} {
        font-family: Lato;
        font-size: 14px;
        max-width: 93vw;
        text-align: center;
    }
`

const IdeaTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    /* Desktop/Heading/H400 */
    font-family: Lato-Semibold;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    flex-direction: row;
    max-width: 556px;

    @media ${device.mobile} {
        max-width: 100%;
        text-align: center;
        flex-direction: column;
        font-size: 14px;
        line-height: 20px;
    }
`

const IdeaBody = styled.div`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    max-width: 80%;
    @media (max-width: 500px) {
        max-width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 20px;
    }
`

const ContactSection = styled.div<IDescriptionProps>`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;

    .email {
        color: var(--primary, #5ec3ca);
        text-decoration: none;
        cursor: pointer;
    }
    max-width: 500px;
    @media ${device.mobile} {
        width: 100%;
        font-size: 16px;
        line-height: 27px;
    }
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const FoundersTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 166.667% */

    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        width: 100%;
        text-align: center;
    }
`

const FoundersBody = styled.div`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;

    @media ${device.mobile_and_ipad} {
        text-align: center;
        width: 100%;
    }
`

const FoundersCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    @media ${device.mobile_and_ipad} {
        text-align: center;
    }
`

const DesktopContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(-60px);
`

const MobileSubContainer = styled.div<IMobileSubContainerProps>`
    padding: ${(props) => (props.padding ? props.padding : '5%')};
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
    justify-content: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : 'inherit'};
`

const DesktopSubContainer = styled.div`
    max-width: 960px;

    @media ${device.smallest_laptops} {
        max-width: 850px;
    }

    @media ${device.ipad} {
        max-width: 90%;
    }

    @media ${device.large_desktop} {
        /* max-width: 80%; */
        width: 80%;
        max-width: 100%;
    }
    align-self: center;
    justify-self: center;
`

const TopImg = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;

    @media ${device.beyond_ipad_mobile} {
        transform: translateY(-8px);
    }

    @media ${device.smallest_laptops} {
        height: auto;
    }

    @media ${device.mobile_and_ipad} {
        height: auto;
    }
`

const generateNoltToken = async (user: IUser) => {
    const payload = {
        // The ID that you use in your app for this user
        id: user.id,
        // The user's email address that
        // Nolt should use for notifications
        email: user.email ? user.email : '',
        // The display name for this user
        name: user.given_name,
    }

    const sec = 'heywearecustodiananditscoolgoplanet!!'

    const jwtt = await new jose.SignJWT({ ...payload })
        .setProtectedHeader({ alg: 'HS256' })
        .sign(new TextEncoder().encode(sec))

    return jwtt
    // return jwt.sign(payload, 'heywearecustodiananditscoolgoplanet!!', {
    //     algorithm: 'HS256',
    // })
}

type State = {
    jwt: any
}

interface IDescriptionProps {
    width?: string
    height?: string
}

interface IMobileSubContainerProps {
    padding?: string
    backgroundColor?: string
    width?: string
    alignItems?: string
}

type Props2 = {
    children: ReactNode
}
const UpcomingIdeas = (props2: Props2) => {
    const { theme } = useThemes()
    return (
        <SectionDescription>
            <IdeaTitle>
                <div style={{ width: '24px', height: '24px' }}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 2.75C8.40328 2.75 5.48755 5.66573 5.48755 9.26246C5.48755 10.7114 5.95988 12.048 6.75926 13.1294C8.65165 15.0349 9.48494 16.8115 9.74744 17.9537H14.2378C14.4215 17.0817 14.8854 16.2022 15.3654 15.4631C15.9875 14.5051 16.7265 13.6492 17.2406 13.1296C18.0401 12.0482 18.5125 10.7115 18.5125 9.26246C18.5125 5.66573 15.5967 2.75 12 2.75ZM3.98755 9.26246C3.98755 4.8373 7.57485 1.25 12 1.25C16.4252 1.25 20.0125 4.8373 20.0125 9.26246C20.0125 11.0657 19.4159 12.7318 18.4096 14.0712L18.3777 14.1135L18.3402 14.151C17.887 14.6041 17.1957 15.3989 16.6234 16.2801C16.0316 17.1913 15.655 18.063 15.655 18.7037V19.4537H8.34503V18.7037C8.34503 18.2396 7.89477 16.3866 5.65965 14.1509L5.62222 14.1135L5.59042 14.0712C4.58415 12.7318 3.98755 11.0657 3.98755 9.26246Z"
                            fill={colours[theme].text_darker}
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.28906 21.25L15.6725 21.25L15.6725 22.75L8.28906 22.75L8.28906 21.25Z"
                            fill={colours[theme].text_darker}
                        />
                    </svg>
                </div>
                <div>
                    Is there a pain point you want us to address or do you have
                    an idea for an exciting new feature?
                </div>
            </IdeaTitle>
            <div style={{ paddingTop: '20px' }} />
            <IdeaBody>
                Please let us know your suggestions by clicking the button below
                and completing the form. We are building the platform for the
                community so we really appreciate your feedback.
            </IdeaBody>

            <div style={{ paddingTop: '20px' }} />

            {props2.children}
        </SectionDescription>
    )
}

const TheFounders = () => {
    return (
        <BoxFounders>
            <FoundersCol>
                <FoundersTitle>
                    <FoundersPicMobile src={founders_word} />
                    <div>A message from our founders</div>
                </FoundersTitle>

                <FoundersBody>
                    We're dedicated to providing a seamless, transparent, and
                    empowering experience for car enthusiasts everywhere. Your
                    trust is at the heart of everything we do, and we're
                    committed to delivering the automotive services you deserve.
                    <br />
                    <div style={{ paddingTop: '10px' }} />
                    Join us on our journey to build a global automotive platform
                    that feels personal to everyone, simplifying car ownership
                    for all.
                </FoundersBody>

                <FoundersBody style={{ fontStyle: 'italic' }}>
                    - Charles, Jeremy & Nathalia
                </FoundersBody>
            </FoundersCol>

            <FoundersPicDesktop src={founders_word} />
        </BoxFounders>
    )
}

const ContaxtBoxSt = styled.div`
    display: flex;
    padding: 32px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--off-bg-color, #fafafa);

    margin-top: 40px;

    @media ${device.mobile_and_ipad} {
        margin: 20px;
    }
`

const RowContact = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 18px;
    line-height: 24px;
`
const ContactBox = () => {
    const { theme } = useThemes()
    return (
        <ContaxtBoxSt>
            <RowContact>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.66968 13.2697L20.4697 2.46973L21.5303 3.53039L10.7303 14.3304L9.66968 13.2697Z"
                            fill={colours[theme].text_darker}
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M22.2224 1.77734L14.8918 22.7219L9.65566 14.3441L1.27783 9.10795L22.2224 1.77734ZM4.72203 9.4917L10.7442 13.2556L14.5081 19.2777L19.7775 4.2223L4.72203 9.4917Z"
                            fill={colours[theme].text_darker}
                        />
                    </svg>
                </div>

                <div>Contact</div>
            </RowContact>
            <ContactSection>
                {
                    "If you'd like to get in touch or send us any feedback via direct mail, please do so at "
                }
                <a
                    href="mailto:hello@custodian.club?subject=Mail through Upcoming Page"
                    className="email"
                >
                    hello@custodian.club
                </a>
                .
            </ContactSection>
        </ContaxtBoxSt>
    )
}

class Upcoming extends React.Component<Props, State> {
    state = {
        jwt: null,
    }

    componentDidMount() {
        this.props.setInitialLandingUrl(`/upcoming`)
        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }

        if (this.props.userLoggedIn) {
            generateNoltToken(this.props.userLoggedIn).then((jwt) => {
                this.setState({ jwt })
            })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.userLoggedIn !== prevProps.userLoggedIn &&
            this.state.jwt === null
        ) {
            if (this.props.userLoggedIn) {
                generateNoltToken(this.props.userLoggedIn).then((jwt) => {
                    this.setState({ jwt })
                })
            }
        }
    }

    render() {
        const { userLoggedIn, upcoming_data } = this.props

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <FullPagePageWrapper>
                        <TopImg
                            src={
                                theme === 'dark'
                                    ? upcoming_banner_dark
                                    : upcoming_banner
                            }
                        />
                        <div>
                            {userLoggedIn !== null && (
                                <Faded>
                                    <Loader />
                                    <DesktopDisplayOnly>
                                        <DesktopContainer>
                                            <DesktopSubContainer>
                                                {/* section 1 */}
                                                <div
                                                    style={{
                                                        paddingTop: '40px',
                                                    }}
                                                />
                                                <UpcomingPageS1
                                                    // title="This is only our starting point."
                                                    milestoneList={
                                                        upcoming_data.section_one_list
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        paddingTop: '24px',
                                                    }}
                                                />
                                                {/* section 2 */}
                                                <SectionHeader
                                                    title={'Coming up next'}
                                                    removeEdit={true}
                                                    customPadding={'0px'}
                                                />
                                                <div
                                                    style={{
                                                        paddingTop: '16px',
                                                    }}
                                                />
                                                <Textt>
                                                    Based on our users'
                                                    feedback, here are some of
                                                    the features we are
                                                    currently working hard to
                                                    bring to the platform.
                                                </Textt>
                                                <div
                                                    style={{
                                                        paddingTop: '16px',
                                                    }}
                                                />
                                                <ExpandingItemsListDesktop
                                                    list={
                                                        upcoming_data.upcoming_shortlisted_features_list
                                                    }
                                                    // width="80%"
                                                    theme="upvote"
                                                    confirmationFunctionality={{
                                                        text: 'Let me know when this feature is released',
                                                        onClick: () => {},
                                                    }}
                                                />
                                                {/* section 3 */}
                                                {/* <SectionHeader
                                        title={'PROPOSE NEW FEATURES'}
                                        removeEdit={true}
                                    /> */}
                                                <div
                                                    style={{
                                                        paddingTop: '30px',
                                                    }}
                                                />
                                                <UpcomingIdeas>
                                                    {this.state.jwt !== null ? (
                                                        <NoltWidget
                                                            jwt={this.state.jwt}
                                                        >
                                                            <ButtonAtom
                                                                theme="lowercase-blue-background"
                                                                width="100%"
                                                                height="70px"
                                                                fontSize={16}
                                                                onClick={() => {}}
                                                                textTransform="none"
                                                            >
                                                                {
                                                                    "Add to community's proposed new features"
                                                                }
                                                            </ButtonAtom>
                                                        </NoltWidget>
                                                    ) : (
                                                        <ButtonAtom
                                                            theme="lowercase-blue-background"
                                                            width="100%"
                                                            height="70px"
                                                            fontSize={16}
                                                            onClick={() => {}}
                                                            disabled={true}
                                                            textTransform="none"
                                                        >
                                                            {
                                                                "Add to community's proposed new features"
                                                            }
                                                        </ButtonAtom>
                                                    )}
                                                </UpcomingIdeas>
                                                {/* section 4 */}
                                                <SectionHeader
                                                    title={
                                                        'WHAT WE HAVE BUILT SO FAR'
                                                    }
                                                    removeEdit={true}
                                                />
                                                <ExpandingItemsListDesktop
                                                    list={
                                                        upcoming_data.upcoming_what_weBuilt
                                                    }
                                                    width="100%"
                                                    theme="check"
                                                    iconData={{
                                                        icon: ckeckImg,
                                                        onClick: () => {},
                                                    }}
                                                />
                                                {/* section 5 */}
                                                <TheFounders />

                                                <ContactBox />
                                            </DesktopSubContainer>
                                        </DesktopContainer>
                                    </DesktopDisplayOnly>

                                    <IpadAndMobileDisplay>
                                        {/* <div>section 1 mobile</div> */}
                                        <MobileSubContainer padding={'8px'}>
                                            {/* <SmallPadding /> */}
                                            <RoadmapMobile
                                                milestoneList={
                                                    upcoming_data.section_one_list
                                                }
                                            />
                                            {/* <SmallPadding /> */}
                                        </MobileSubContainer>
                                        <MobileSettingsSectionHeader
                                            title={'Coming up next'}
                                        />

                                        <div style={{ paddingTop: '20px' }} />

                                        {/* <div>section 2 mobile</div> */}
                                        <Textt>
                                            Based on our users' feedback, here
                                            are some of the features we are
                                            currently working hard to bring to
                                            the platform.
                                        </Textt>

                                        <div style={{ paddingTop: '20px' }} />

                                        <MobileSubContainer backgroundColor="var(--primary_04)">
                                            <ExpandingItemsListMobile
                                                list={
                                                    upcoming_data.upcoming_shortlisted_features_list
                                                }
                                                width="80%"
                                                theme="upvote"
                                                iconData={{
                                                    icon: upVoteImg,
                                                    onClick: () => {},
                                                }}
                                                confirmationFunctionality={{
                                                    text: 'Let me know when this feature is released',
                                                    onClick: () => {},
                                                }}
                                            />
                                        </MobileSubContainer>
                                        {/* <SmallPadding />
                            <SmallPadding /> */}
                                        {/* <MobileSettingsSectionHeader title="Propose new features" /> */}
                                        {/* <div>section 3 mobile</div> */}

                                        <div style={{ padding: '24px' }}>
                                            <UpcomingIdeas>
                                                {this.state.jwt !== null ? (
                                                    <NoltWidget
                                                        jwt={this.state.jwt}
                                                    >
                                                        <ButtonAtom
                                                            theme="lowercase-blue-background"
                                                            // width="300px"
                                                            width="100%"
                                                            height="60px"
                                                            textTransform="none"
                                                            fontSize={14}
                                                            // onClick={() => {}}
                                                        >
                                                            {
                                                                "Add to community's proposed new features"
                                                            }
                                                        </ButtonAtom>
                                                    </NoltWidget>
                                                ) : (
                                                    <ButtonAtom
                                                        theme="lowercase-blue-background"
                                                        // width="300px"
                                                        width="100%"
                                                        height="60px"
                                                        fontSize={14}
                                                        disabled={true}
                                                        textTransform="none"
                                                        // onClick={() => {}}
                                                    >
                                                        {
                                                            "Add to community's proposed new features"
                                                        }
                                                    </ButtonAtom>
                                                )}
                                            </UpcomingIdeas>
                                        </div>

                                        {/* section 4 mobile */}
                                        <MobileSettingsSectionHeader title="WHAT WE HAVE BUILT SO FAR" />
                                        <SmallPadding />
                                        <ExpandingItemsListMobile
                                            list={
                                                upcoming_data.upcoming_what_weBuilt
                                            }
                                            width="100%"
                                            theme="check"
                                            iconData={{
                                                icon: ckeckImgCircle,
                                                onClick: () => {},
                                            }}
                                        />
                                        <SmallPadding />

                                        {/* section 5 mobile */}
                                        <TheFounders />

                                        <ContactBox />
                                        <div style={{ paddingTop: '150px' }} />
                                    </IpadAndMobileDisplay>
                                </Faded>
                            )}
                        </div>
                    </FullPagePageWrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(Upcoming)
