import { Tooltip } from 'antd'
import { IReminder, TaskInfoList } from 'entityModels'
import * as React from 'react'

import styled from 'styled-components'
import { OTaskFilterNSort } from '../../../ol/ol'

import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import CustomAnimatedCheckboxField from '../formField/customAnimatedCheckboxField'

const Wrapper = styled.section`
    @media ${device.ipad} {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin-bottom: 12px;
    }

    @media ${device.mobile} {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: auto;
        margin-bottom: 12px;
    }
`

const Title = styled.div`
    /* text-transform: lowercase; */

    @media ${device.mobile} {
        margin-right: 30px;
        font-size: 16px;
        font-family: Lato;
        color: var(--text-strong, #1a1a1a);
        width: 100px;
        border-right: 1px solid var(--border-muted, #e5e5e5);
    }

    @media ${device.ipad} {
        margin-right: 30px;
        font-size: 16px;
        font-family: Lato;
        color: var(--text-strong, #1a1a1a);
        width: 100px;
        border-right: 1px solid var(--border-muted, #e5e5e5);
    }
`

const Answer = styled.div<IStyleProps>`
    @media ${device.mobile} {
        font-family: Lato;
        font-size: 16px;
        color: ${(props) =>
            props.color ? props.color : 'var(--primary, #5ec3ca)'};
        max-width: 47vw;
    }

    @media ${device.ipad} {
        font-family: Lato;
        font-size: 16px;
        color: ${(props) =>
            props.color ? props.color : 'var(--primary, #5ec3ca)'};
        max-width: 47vw;
    }
`

const ClickToAdd = styled.div`
    font-family: Lato-light;
    font-style: italic;
    font-size: 15px;
    color: var(--text-default, #666666);
    max-width: 47vw;
`

interface Props {
    obj: TaskInfoList
    color?: string
    openUpdateTaskForm?: any
    updateTask: any
    taskid: any
    noDueDateSet: boolean
    createReminders: any
    viewReminders: any
    isDueDateInThePast?: boolean
}

type IStyleProps = {
    color?: string
    tooltip?: boolean
}

export default function TaskInfoListElementMobile(props: Props) {
    const [tooltip, showTooltip] = React.useState(false)

    const toggleTooltip = () => {
        showTooltip(!tooltip)

        setTimeout(() => {
            showTooltip(false)
        }, 2000)
    }

    let {
        obj,
        color,
        openUpdateTaskForm,
        updateTask,
        taskid,
        noDueDateSet,
        createReminders,
        viewReminders,
        isDueDateInThePast,
    } = props

    const { theme } = useThemes()

    const renderRemindersAnswer = (
        answers: IReminder[],
        onClick: any,
        disabled?: boolean
    ) => (
        <div onClick={disabled ? () => {} : onClick}>
            <Answer
                color={
                    disabled
                        ? colours[theme].text_muted
                        : colours[theme].text_strong
                }
            >
                {disabled ? '' : 'Next:'} {answers[0]}
            </Answer>
            {answers.length > 1 && (
                <Answer
                    color={
                        disabled
                            ? colours[theme].text_muted
                            : colours[theme].primary
                    }
                >{`${disabled ? '' : 'View'} ${
                    answers.length - 1
                } more`}</Answer>
            )}
        </div>
    )

    return (
        <Wrapper>
            <Title>{obj.text}</Title>

            {obj.id === 'status' && (
                <CustomAnimatedCheckboxField
                    small
                    fullColorOnTrue
                    borderColor={colours[theme].text_muted}
                    checked={
                        obj.answer &&
                        obj.answer === OTaskFilterNSort.filter_by.completed.name
                            ? true
                            : false
                    }
                    onChange={() => {
                        updateTask({
                            taskid: taskid,
                            dataList: [
                                {
                                    id: 'status',
                                    answer:
                                        obj.answer &&
                                        obj.answer ===
                                            OTaskFilterNSort.filter_by.completed
                                                .name
                                            ? false
                                            : true,
                                },
                            ],
                        })
                    }}
                />
            )}

            {obj.id === 'due' &&
                (isDueDateInThePast ? (
                    <ClickToAdd
                        onClick={() => {
                            toggleTooltip()
                        }}
                    >
                        <Tooltip
                            title="Due date is in the past."
                            open={tooltip}
                            placement="bottom"
                        >
                            {obj.answer}
                        </Tooltip>
                    </ClickToAdd>
                ) : (
                    <Answer
                        onClick={() => {
                            if (obj.id !== 'status') {
                                if (openUpdateTaskForm) {
                                    if (obj.id === 'linkedToCar') {
                                        openUpdateTaskForm({
                                            id: obj.id,
                                            text: obj.text,
                                            answer: obj.answers && obj.answers,
                                        })
                                    } else {
                                        openUpdateTaskForm({
                                            id: obj.id,
                                            text: obj.text,
                                            answer: obj.answer && obj.answer,
                                        })
                                    }
                                }
                            }
                        }}
                        color={color && color}
                    >
                        <div>
                            {obj.id === 'due' && obj.answer === '-' ? (
                                <ClickToAdd>Click to set a due date</ClickToAdd>
                            ) : (
                                obj.answer && obj.answer
                            )}
                        </div>
                    </Answer>
                ))}

            {obj.id !== 'reminders' && obj.id !== 'due' && (
                <Answer
                    onClick={() => {
                        if (obj.id !== 'status') {
                            if (openUpdateTaskForm) {
                                if (obj.id === 'linkedToCar') {
                                    openUpdateTaskForm({
                                        id: obj.id,
                                        text: obj.text,
                                        answer: obj.answers && obj.answers,
                                    })
                                } else {
                                    openUpdateTaskForm({
                                        id: obj.id,
                                        text: obj.text,
                                        answer: obj.answer && obj.answer,
                                    })
                                }
                            }
                        }
                    }}
                    color={color && color}
                >
                    <div
                        style={{
                            transform:
                                obj.id === 'status'
                                    ? 'translateY(2px)'
                                    : 'auto',
                        }}
                    >
                        {obj.id === 'due' && obj.answer === '-' ? (
                            <ClickToAdd>Click to set a due date</ClickToAdd>
                        ) : (
                            obj.answer && obj.answer
                        )}
                    </div>
                </Answer>
            )}
            {obj.id === 'reminders' &&
                (noDueDateSet ? (
                    <ClickToAdd
                        onClick={() => {
                            toggleTooltip()
                        }}
                    >
                        <Tooltip
                            title="Please set a due date first"
                            open={tooltip}
                            placement="bottom"
                            color={colours[theme].text_darker}
                        >
                            Click to add a reminder
                        </Tooltip>
                    </ClickToAdd>
                ) : isDueDateInThePast ? (
                    <ClickToAdd
                        onClick={() => {
                            toggleTooltip()
                        }}
                    >
                        <Tooltip
                            title="Due date is in the past."
                            open={tooltip}
                            placement="bottom"
                            color={colours[theme].text_darker}
                        >
                            {!obj.answers ? (
                                <ClickToAdd>-</ClickToAdd>
                            ) : (
                                renderRemindersAnswer(
                                    obj.answers,
                                    viewReminders,
                                    true
                                )
                            )}
                        </Tooltip>
                    </ClickToAdd>
                ) : !obj.answers ? (
                    <div onClick={createReminders} style={{ width: '50vw' }}>
                        <ClickToAdd>Click to add a reminder</ClickToAdd>
                    </div>
                ) : (
                    renderRemindersAnswer(obj.answers, viewReminders)
                ))}
        </Wrapper>
    )
}
