import { IJourneyCarProfileStepID } from 'journeyInterfaces'
import { useHistory } from 'react-router'
import { ICarsObject } from 'entityModels'
import { IUser } from 'myModels'
import { useAppSelector } from '../../../../../redux/store/hooks'
import AddPCarBannerStep from '../addCarBannerStep'
import AddPCarBioStep from '../addCarBioStep'
import AddProfilePhotoStep from '../addProfilePhotoStep'
import GalleryStep from '../galleryStep'
import JourneyCarProfileKMF from '../kmfStep'
import JourneySelectCar from '../../../../molecules/journey/selectCar'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'
import JourneyCarProfileWelcome from '../welcome'

type Props = {
    stepID: IJourneyCarProfileStepID
    startJourney: () => void
    exitJourney: () => void
    carid?: string | null
    carsData?: ICarsObject | undefined
    garageCars?: string[]
    user: IUser | null
}
export default function CarProfileJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    carid,
    carsData,
    garageCars,
    user,
}: Props) {
    const history = useHistory()
    let galleryImages = useAppSelector(
        (state) => state.entities.galleriesData.images
    )
    let locationState: any = history.location.state

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyCarProfileWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
        case 'select_car':
            return (
                <JourneySelectCar
                    sectionID="car_profile"
                    step={0}
                    totalSteps={5}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(`/journey/car-profile?step=welcome`)
                    }}
                    goToNextStep={(carid: string) => {
                        history.push({
                            pathname: `/journey/car-profile`,
                            search: `?step=profile_photo&carid=${carid}`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                    carsData={carsData}
                    garageCars={garageCars}
                    galleryImages={galleryImages}
                    user={user}
                />
            )
        case 'profile_photo':
            return (
                <AddProfilePhotoStep
                    step={1}
                    totalSteps={5}
                    goToPrevStep={(carid?: string) => {
                        if (locationState?.prevPath) {
                            history.push(locationState?.prevPath)
                        } else {
                            return history.push(
                                `/journey/car-profile?step=welcome&carid=${carid}`
                            )
                        }
                    }}
                    goToNextStep={(carid: string) => {
                        history.push({
                            pathname: `/journey/car-profile`,
                            search: `?step=key_moments_and_facts&carid=${carid}`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                    carsData={carsData}
                    garageCars={garageCars}
                    galleryImages={galleryImages}
                    user={user}
                />
            )
        case 'key_moments_and_facts':
            return (
                <JourneyCarProfileKMF
                    step={2}
                    totalSteps={5}
                    goToPrevStep={(carid: string) => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/car-profile?step=profile_photo&carid=${carid}`
                              )
                    }}
                    goToNextStep={(carid: string) => {
                        history.push({
                            pathname: `/journey/car-profile`,
                            search: `?step=gallery&carid=${carid}`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                    carsData={carsData}
                    garageCars={garageCars}
                    galleryImages={galleryImages}
                    user={user}
                />
            )
        case 'gallery':
            return (
                <GalleryStep
                    step={3}
                    totalSteps={5}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/car-profile?step=key_moments_and_facts&carid=${carid}`
                              )
                    }}
                    goToNextStep={(carid: string) => {
                        history.push({
                            pathname: `/journey/car-profile`,
                            search: `?step=banner&carid=${carid}`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                    carsData={carsData}
                    garageCars={garageCars}
                    galleryImages={galleryImages}
                    user={user}
                />
            )
        case 'banner':
            return (
                <AddPCarBannerStep
                    step={4}
                    totalSteps={5}
                    goToPrevStep={(carid?: string) => {
                        if (locationState?.prevPath) {
                            history.push(locationState?.prevPath)
                        } else {
                            return history.push(
                                `/journey/car-profile?step=gallery&carid=${carid}`
                            )
                        }
                    }}
                    goToNextStep={(carid: string) => {
                        history.push({
                            pathname: `/journey/car-profile`,
                            search: `?step=bio&carid=${carid}`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                    carsData={carsData}
                    garageCars={garageCars}
                    galleryImages={galleryImages}
                    user={user}
                />
            )
        case 'bio':
            return (
                <AddPCarBioStep
                    step={5}
                    totalSteps={5}
                    goToPrevStep={(carid?: string) => {
                        if (locationState?.prevPath) {
                            history.push(locationState?.prevPath)
                        } else {
                            return history.push(
                                `/journey/car-profile?step=banner&carid=${carid}`
                            )
                        }
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/car-profile`,
                            search: `?step=achieved`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                    carsData={carsData}
                    garageCars={garageCars}
                    galleryImages={galleryImages}
                    user={user}
                />
            )
        case 'achieved':
            return <JourneyAchievedStep sectionID="car_profile" />
        default:
            return (
                <JourneyCarProfileWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
