import { useEffect, useRef } from 'react'

/**
 * Listen for clicks outside an element.
 * Accepts a callback that will be called when a click is
 * detected outside the element. Returns a ref that can then be
 * attached to the element to listen to.
 *
 * This can be useful, for example, to dismiss
 * a popup when clicked outside of it.
 *
 * Example Usage:
 *
 * ```tsx
 * function handleClickAway() { ... }
 * const ref = useClickAway(handleClickAway)
 *
 * ...
 *
 * <div ref={ref}>
 * </div>
 * ```
 */
export const useClickAway = (onClickAway: () => void) => {
    const targetRef = useRef<HTMLElement>(null)

    useEffect(() => {
        const handler = (event: MouseEvent) => {
            if (!targetRef.current?.contains(event.target as Node)) {
                onClickAway()
            }
        }

        document.addEventListener('mousedown', handler)

        return () => document.removeEventListener('mousedown', handler)
    })

    return targetRef
}
