import { createAction } from '@reduxjs/toolkit'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import { ADD_ATTACHMENTS_ERROR } from './constants'

export const addAttachmentsError = createAction(
    ADD_ATTACHMENTS_ERROR,
    function prepare(payload: INormalisedAttachmentsByID) {
        return {
            payload,
        }
    }
)
