import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'

import { api } from '../../../services'

import { IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IDeleteShareReq } from '../../cars/actions/actions'
import posthog from 'posthog-js'
import { IDeleteShareAPi_Arg } from 'ApiInterfaces'

export function* DeleteGaragePublicShareSaga(p: IDeleteShareReq) {
    try {
        let papi: IDeleteShareAPi_Arg = {
            share_id: p.share_id,
            share_type: 'link_share',
        }

        yield call(api.sharing.deleteShareAPI, papi)

        yield put(actions.actions.deleteGaragePublicShareSuccess(p))

        // Capture event
        posthog.capture('delete garage link-share')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteGaragePublicShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.deleteGaragePublicShareError(typedError)
            )
            return
        }
    }
}

function* watcherDeletePublicGarageShare() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.deleteGaragePublicShareRequest
        )
        yield call(DeleteGaragePublicShareSaga, payload)
    }
}

export function* DeleteGarageDirectShareSaga(p: IDeleteShareReq) {
    try {
        let papi: IDeleteShareAPi_Arg = {
            share_id: p.share_id,
            share_type: 'direct_share',
        }

        yield call(api.sharing.deleteShareAPI, papi)

        yield put(actions.actions.deleteDirectGarageShareSuccess(p))

        // Capture event
        posthog.capture('DELETE GARAGE DIRECT SHARE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteGarageDirectShareSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.deleteDirectGarageShareError(typedError)
            )
            return
        }
    }
}

function* watcherDeleteDirectGarageShare() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.deleteGarageDirectShareRequest
        )
        yield call(DeleteGarageDirectShareSaga, payload)
    }
}

const delete_garage_shares_sagas: any[] = [
    fork(watcherDeletePublicGarageShare),
    fork(watcherDeleteDirectGarageShare),
]

export default delete_garage_shares_sagas
