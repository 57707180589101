// @ts-ignore
;<reference types="googlemaps" />
import styled from 'styled-components'
// import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

// import gallery_car_ex from "../../../../public/assets/car_examples/gallery_car_ex.png";

import InputField from '../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'

// import ButtonAtom from "../../../atoms/Button/ButtonAtom";
// import Faded from "../../../templates/animated/faded";
// import { getPreSignedUrlImagesCarGallery } from "../../../../redux/entities/galleries/actions/thunks";
import Accept from '../../dropzone/imagesDropzoneDesktop'
import {
    getPresignedUrlsCarGalleryImagesRequest,
    submitCarGalleryImagesFilestorageRequest,
} from '../../../../redux/filestorage/actions/dispatched/loadingActions'
import {
    IUploadAndCreateCarGalleryImageArgs,
    IUploadGalleryImagesPreSignedUrlsPayload,
    reqPresignedUrImageArgs,
} from 'myModels'
import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import { useState } from 'react'
import GooglePlacesLocationsDropdown from '../../../molecules/googlePlacesLocationsDropdown/googlePlacesLocationsDropdownDesktop'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
}

const SmallPadding = styled.div`
    padding-top: 5px;
`

const FieldPadding = styled.div`
    padding-top: 16px;
`

const LargePadding = styled.div`
    padding-top: 40px;
`

const ImgBtnRowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 16px;
`

export const Error = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-style: italic;
    font-size: 11px;
`

const Text = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 12px;
    font-family: 'Lato-Light';
    padding-top: 12px;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: 'row';
    margin-top: 24px;
`

const Line = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].text_muted};
    width: 1px;
    height: 100%;
    margin-right: 10px;
`

const TipText = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 14px;
    font-family: 'Lato';
    width: 100%;
`

//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
// `;

// const NameOfFileUploaded = styled.div`
//   color: #898989;
//   font-family: georgia;
//   font-size: 14px;
//   margin-right: 30px;
//   padding-left: 20px;
//   max-width: 360px;
//   overflow: hidden;
// `;

// const StyledInput = styled.input`
//   color: white !important;
//   border: none;

//   transition: all 0.1s;
//   :focus {
//     outline: 0;
//   }
//   width: 100px;
// `;

const dispatchProps = {
    getPresignedUrlsCarGalleryImagesRequest: (p: reqPresignedUrImageArgs) =>
        getPresignedUrlsCarGalleryImagesRequest(p),
    submitCarGalleryImagesFilestorageRequest: (
        p: IUploadAndCreateCarGalleryImageArgs
    ) => submitCarGalleryImagesFilestorageRequest(p),
}

function mapStateToProps(state: RootState) {
    return {
        gallerypresignedurls: state.fileStorage.gallerypresignedurls,
    }
}

type Cover = string | null

type Feature = string[] | null

interface Values {
    caption: string
    location: string
    credits: string
    file_url?: string
    files?: File[]
    cover?: Cover
    featured?: Feature
}

interface OtherProps extends RouteComponentProps<any> {
    close: any
    deleteImageUploaded: any
    setImageUploaded: any
    currentImagesUploaded: any
    getPresignedUrlsCarGalleryImagesRequest: (
        p: reqPresignedUrImageArgs
    ) => void
    carid: string
    submitCarGalleryImagesFilestorageRequest: (
        p: IUploadAndCreateCarGalleryImageArgs
    ) => void
    gallerypresignedurls?: IUploadGalleryImagesPreSignedUrlsPayload
    filesProgressObj?: any
    isSomethingBeingUploaded: boolean
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        setImageUploaded,
        deleteImageUploaded,
        setFieldValue,
        getPresignedUrlsCarGalleryImagesRequest,
        currentImagesUploaded,
        filesProgressObj,
        isSomethingBeingUploaded,
        errors,
    } = props

    const [showLocationSuggestions, toggleShowLocationSuggestion] =
        useState(false)

    const handleFilesGiven = (files: File[]) => {
        let count = files.length
        let file_names = files.map((file) => file.name)

        count > 0 &&
            getPresignedUrlsCarGalleryImagesRequest({
                carid: props.carid,
                count: count,
                file_names: file_names,
            })

        setFieldValue('files', files)

        setImageUploaded(files)
    }

    const handleSelectAs = (chosen: string, path: string) => {
        // check whether there is one already for error message
        if (chosen === 'cover') {
            return setFieldValue('cover', path)
        } else if (chosen === 'featured') {
            if (!values.featured) {
                return setFieldValue('featured', [path])
            }
            if (values.featured && values.featured.length <= 6) {
                let arr = values.featured && values.featured
                arr.push(path)

                return setFieldValue('featured', arr)
            }
        }
    }

    const removeSetAs = (path: string) => {
        if (values.cover === path) {
            return setFieldValue('cover', null)
        } else if (values.featured && values.featured.includes(path)) {
            if (values.featured && values.featured.length === 1) {
                return setFieldValue('featured', [null])
            } else {
                const newArray: string[] = values.featured.filter(
                    (item: string) => item !== path
                )

                return setFieldValue('featured', newArray)
            }
        }
    }

    const checkIfDisabled = (): boolean => {
        let allFilesUndefined: boolean = true

        for (let i = 0; i < currentImagesUploaded.length; i++) {
            if (currentImagesUploaded[i] !== undefined) {
                allFilesUndefined = false
            }
        }

        if (isSomethingBeingUploaded) {
            return true
        } else if (
            (values.caption && errors.caption) ||
            (values.location && errors.caption) ||
            (values.credits && errors.credits)
        ) {
            return true
        } else if (allFilesUndefined) {
            return true
        } else return false
    }

    const handleSuggestionSelection = (suggestion: any) => {
        setFieldValue(
            'location',
            suggestion.structured_formatting
                ? suggestion.structured_formatting.main_text
                : suggestion.description
        )
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop
            $theme={theme}
            id="scrollable-content"
            style={{ overflowY: 'auto', width: '500px' }}
        >
            <ModalInnerContentWrapperDesktop>
                {/* <FormTitle>Car Gallery</FormTitle> */}
                <>
                    <ModalTitle $theme={theme}>
                        Add images to your gallery
                    </ModalTitle>

                    <Text $theme={theme}>
                        Upload up to 20 images at a time.
                        <br />
                        Image formats accepted : png, jpg, jpeg, HEIC and HEIF.
                        <br />
                        Maximum file size : 20MB
                    </Text>

                    <div
                        style={
                            currentImagesUploaded &&
                            currentImagesUploaded.length > 0
                                ? { paddingTop: 24 }
                                : { paddingTop: 40 }
                        }
                    />
                    <ImgBtnRowWrapper>
                        <Accept
                            handleFilesGiven={handleFilesGiven}
                            removeFile={deleteImageUploaded}
                            imageUploadedData={currentImagesUploaded}
                            filesProgressObj={filesProgressObj}
                            handleSelectAs={handleSelectAs}
                            removeSetAs={removeSetAs}
                        />
                    </ImgBtnRowWrapper>

                    <Expander
                        height={
                            values.caption.length > 1 && errors.caption
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.caption}
                            </Error>
                        </Faded>
                    </Expander>

                    <InputField
                        theme={theme}
                        id="caption"
                        name="caption"
                        placeholder="Caption"
                        helperText="ie. Doing donuts in my Fiat Panda 4x4 in the snow"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.caption}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />

                    <FieldPadding />

                    <Expander
                        height={
                            values.location.length > 1 && errors.location
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.location}
                            </Error>
                        </Faded>
                    </Expander>

                    <SmallPadding />

                    <InputField
                        theme={theme}
                        id="location"
                        name="location"
                        placeholder="Location"
                        helperText="Where was this taken?"
                        tabindex={2}
                        aria-live="polite"
                        formikprops={props}
                        value={values.location}
                        onFocus={() => {
                            toggleShowLocationSuggestion(true)
                        }}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <GooglePlacesLocationsDropdown
                        valueSearched={values.location}
                        showDropdown={showLocationSuggestions}
                        toggleShowDropDown={(how: boolean) =>
                            toggleShowLocationSuggestion(how)
                        }
                        onSuggestionClick={handleSuggestionSelection}
                    />

                    <FieldPadding />

                    <Expander
                        height={
                            values.credits.length > 1 && errors.credits
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                $theme={theme}
                                className="animated fadeIn slower"
                            >
                                {errors.credits}
                            </Error>
                        </Faded>
                    </Expander>

                    <SmallPadding />

                    <InputField
                        theme={theme}
                        id="credits"
                        name="credits"
                        placeholder="Credits"
                        helperText="Name of photographer (if applicable)"
                        tabindex={3}
                        aria-live="polite"
                        formikprops={props}
                        value={values.credits}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />

                    <Row>
                        <Line $theme={theme} />
                        <TipText $theme={theme}>
                            Once uploaded, you can edit images and also select{' '}
                            <br />
                            your{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                car profile image.
                            </span>
                        </TipText>
                    </Row>
                </>

                <LargePadding />
                <FieldPadding />
                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={props.handleSubmit}
                    secondText={'upload'}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarGalleryEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        caption: '',
        location: '',
        credits: '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                caption: Yup.string().max(
                    266,
                    'Caption must be be less than 265 characters.'
                ),
                location: Yup.string().max(
                    100,
                    'Location must be be less than 100 characters.'
                ),

                credits: Yup.string().max(
                    36,
                    'Credits must be be less than 35 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let files = props.currentImagesUploaded

        let gallerypresignedurls = props.gallerypresignedurls

        if (files !== undefined && gallerypresignedurls) {
            props.submitCarGalleryImagesFilestorageRequest({
                carid: props.carid,
                gallerypresignedurls: gallerypresignedurls,
                files: files,
                fields: {
                    caption: values.caption,
                    credit: values.credits,
                    location: values.location,
                    cover: values.cover ? values.cover : null,
                    featured: values.featured ? values.featured : null,
                },
                isFromQuickActions: false,
            })

            // here req thunks to upload to S3
        }

        setSubmitting(false)
    },
})(InnerForm)

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarGalleryEditFormDesktop)
)

// {
//   /* <StyledInput
//               type="file"
//               id="avatar"
//               name="avatar"
//               accept="image/png, image/jpeg"
//               onChange={(event: any) => {
//                 let img = event.currentTarget.files[0];
//                 let fake_url = URL.createObjectURL(
//                   event.currentTarget.files[0]
//                 );
//                 getPreSignedUrlImagesCarGallery(props.carid, 1);
//                 setFieldValue("file_url", fake_url);
//
//                 setImageUploaded({
//                   fileTitle: img.name,
//                   url: fake_url,
//                 });
//               }}
//             /> */
// }
// {
//   /* <ButtonAtom
//                 onClick={(e: any) => {
//                   e.preventDefault();
//                   // fake function
//                   setImageUploaded({
//                     fileTitle: "this_was_the_name_of_the_file.png",
//                     url: gallery_car_ex,
//                   });
//                 }}
//                 theme="lowercase-white-background"
//               >
//                 import image
//               </ButtonAtom> */
// }

// {
//   currentImagesUploaded && currentImagesUploaded.length > 0 && (
//     <Faded>
//       <ImgBtnSideRow>
//         {/* <ButtonAtom
//                       onClick={(e: any) => {
//                         e.preventDefault();
//                         // fake function

//                         toggleShowImageCropper();
//                       }}
//                       theme="img-actions"
//                       action="crop_img"
//                       height="30px"
//                       width="30px"
//                     ></ButtonAtom>

//                     <div style={{ paddingLeft: "5px" }} /> */}

//         {currentImagesUploaded.map((file: any) => {
//           return (
//             <div>
//               <div>{file.fileTitle && file.fileTitle}</div>

//               <ButtonAtom
//                 onClick={(e: any) => {
//                   e.preventDefault();
//                   // fake function

//                   deleteImageUploaded();
//                 }}
//                 theme="img-actions"
//                 action="delete_img"
//                 height="30px"
//                 width="30px"
//               >
//                 import image
//                         </ButtonAtom>
//             </div>
//           );
//         })}
