import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const KeyIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ? +size * 1.04 : '25'}
            height={size ?? '24'}
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.866 2.39228C17.135 1.66125 16.1425 1.25 15.1087 1.25C14.0749 1.25 13.0824 1.66125 12.3514 2.39228L8.67141 6.07228C8.13673 6.60695 7.83696 7.33168 7.83696 8.08696C7.83696 8.84224 8.13673 9.56696 8.67141 10.1016L14.3984 15.8286C14.933 16.3633 15.6578 16.663 16.413 16.663C17.1683 16.663 17.893 16.3633 18.4277 15.8286L22.1077 12.1486C22.8388 11.4176 23.25 10.4251 23.25 9.3913C23.25 8.35753 22.8388 7.36505 22.1077 6.63402L17.866 2.39228ZM15.1087 2.75C15.7445 2.75 16.3555 3.0031 16.8053 3.45294L21.0471 7.69468C21.4969 8.14452 21.75 8.75551 21.75 9.3913C21.75 10.0271 21.4969 10.6381 21.0471 11.0879L17.3671 14.7679C17.1139 15.0211 16.7708 15.163 16.413 15.163C16.0553 15.163 15.7122 15.0211 15.459 14.7679L9.73207 9.04097C9.47892 8.78782 9.33696 8.44472 9.33696 8.08696C9.33696 7.72919 9.47892 7.38609 9.73207 7.13294L13.4121 3.45294C13.8619 3.0031 14.4729 2.75 15.1087 2.75Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.3986 5.56269C14.1057 5.85558 14.1057 6.33045 14.3986 6.62335L17.8769 10.1016C18.1698 10.3945 18.6447 10.3945 18.9376 10.1016C19.2304 9.80872 19.2304 9.33384 18.9376 9.04095L15.4593 5.56269C15.1664 5.26979 14.6915 5.26979 14.3986 5.56269Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.98698 9.73041C9.69409 9.43752 9.21921 9.43752 8.92632 9.73041L8.05675 10.6C7.76386 10.8929 7.76386 11.3677 8.05675 11.6606L8.39599 11.9999L1.9698 18.4261C1.82915 18.5667 1.75013 18.7575 1.75013 18.9564V21.9999C1.75013 22.4141 2.08591 22.7499 2.50013 22.7499H5.54361C5.74252 22.7499 5.93328 22.6709 6.07394 22.5302L6.72383 21.8803H7.71752C8.13173 21.8803 8.46752 21.5445 8.46752 21.1303V20.1366L10.2021 18.402H11.1958C11.61 18.402 11.9458 18.0663 11.9458 17.652V16.6584L12.5001 16.104L12.8394 16.4432C13.1323 16.7361 13.6071 16.7361 13.9 16.4432L14.7696 15.5737C15.0625 15.2808 15.0625 14.8059 14.7696 14.513C14.4767 14.2201 14.0018 14.2201 13.7089 14.513L13.3697 14.8523L13.0305 14.513C12.7376 14.2201 12.2627 14.2201 11.9698 14.513L10.6654 15.8174C10.5248 15.958 10.4458 16.1488 10.4458 16.3477V16.902H9.89143C9.69252 16.902 9.50175 16.9811 9.3611 17.1217L7.18719 19.2956C7.04654 19.4363 6.96752 19.627 6.96752 19.826V20.3803H6.41317C6.21426 20.3803 6.02349 20.4593 5.88284 20.6L5.23295 21.2499H3.25013V19.2671L9.98698 12.5302C10.2799 12.2373 10.2799 11.7624 9.98698 11.4695L9.64774 11.1303L9.98698 10.7911C10.2799 10.4982 10.2799 10.0233 9.98698 9.73041Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default KeyIcon
