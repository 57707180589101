import Fuse from 'fuse.js'

// takes a list of objects and a list of keys.
// Keys are an array of peoperties whcih you want to search on in the list of objects.

export const createSearchableIndex = (list: any[], keys: string[]) => {
    const options = {
        includeScore: true,
        keys: keys,
    }

    const fuse = new Fuse(list, options)

    return fuse
}

export const searchResults = (
    fuse: any,
    termToSearch: string,
    onlyFirstResult?: boolean
) => {
    const result = fuse.search(termToSearch)

    if (onlyFirstResult) {
        return result[0]
    } else return result
}
