import { call, fork, put, select, take } from 'redux-saga/effects'
// import { api } from '../../services'
import posthog from 'posthog-js'

import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import {
    IAPIShowroomEnquiryByEntryId,
    IEnquiryStructureRequest,
} from '../types'
import { showroomActions } from '../reducer'
import { convertToEnquiryAPIPayload } from '../../conversions/showroom/enquiry'
import { api } from '../../services'
import { RootState } from 'typesafe-actions'

let state_select_dial_code = (state: RootState) =>
    state.editForms.user_dial_code

export function* ShowroomEnquiryByEntryId(
    payload: IEnquiryStructureRequest
): any {
    try {
        let dial_code: string = yield select(state_select_dial_code)

        let apiPayload: IAPIShowroomEnquiryByEntryId =
            convertToEnquiryAPIPayload(payload)

        if (
            apiPayload.api_body_payload.buyer_phone &&
            apiPayload.api_body_payload.buyer_phone.trim()[0] !== '+'
        ) {
            apiPayload.api_body_payload.buyer_phone =
                dial_code + apiPayload.api_body_payload.buyer_phone
        }

        yield call(api.showroom.showroomEnquiryByEntryId, apiPayload)

        yield put(showroomActions.showroomEnquiryByEntryIdSuccess())

        // Capture event
        posthog.capture('SHOWROOM ENQUIRY BY ENTRY ID SENT')
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: ShowroomEnquiryByEntryId,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/showroom/${payload.entry_id}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(
                showroomActions.showroomEnquiryByEntryIdError(customError)
            )
        }
    }
}

function* watcherShowroomEnquiryByEntryId() {
    while (true) {
        const { payload } = yield take(
            showroomActions.showroomEnquiryByEntryIdRequest
        )

        yield call(ShowroomEnquiryByEntryId, payload)
    }
}

const showroom_enquiry_by_entry_id: any[] = [
    fork(watcherShowroomEnquiryByEntryId),
]

export default showroom_enquiry_by_entry_id
