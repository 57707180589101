import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<IHBStyle>`
    width: 20px;
    transition: transform 0.3s;
    transform: translate(3px, 11px);
`

type IHBStyle = {
    isChecked?: boolean
}

const Line1 = styled.div<IHBStyle>`
    height: 1.5px;
    width: 12px;
    background-color: #5ec3ca;
    transform: ${(props) =>
        props.isChecked
            ? 'rotate(45deg) translateY(3.5px) translateX(-0.5px) scale(0.5, 1);'
            : 'rotate(90deg) translateY(-1px) translateX(0px);'};
    transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    border-radius: 1px;
`

const Line2 = styled.div<IHBStyle>`
    background-color: #5ec3ca;
    height: 1.5px;
    width: 12px;
    transform: ${(props) =>
        props.isChecked
            ? 'rotate(-35deg) translateX(3px)  translateY(1.5px)'
            : 'translate(1px, -1.5px)'};
    transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    border-radius: 1px;
`

interface Props {
    isChecked: boolean
    toggle?: any
}

const StatefulCrossToCheck: React.FunctionComponent<Props> = (props) => {
    const { isChecked, toggle } = props

    return (
        <Wrapper isChecked={isChecked} onClick={toggle ? toggle : () => {}}>
            <Line1 isChecked={isChecked} />
            <Line2 isChecked={isChecked} />
        </Wrapper>
    )
}

export default StatefulCrossToCheck
