import { apiSentryErrorHandler } from '../errorHandler'
import { customHeader } from '../headers'
import * as urls from '../urls'

export const get_country_admin_area_dropdown = (
    countryCode: string
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.list_insurance_dropdowns_endpoint(
            `?name=administrative_areas&administrative_areas_country_code=${countryCode}`
        ),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Get insurance dropdowns error')
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
