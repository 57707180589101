import colours, { ITheme } from '../../../providers/theme/colours'

type Props = {
    isActive: boolean
    theme: ITheme
    height?: string
    width?: string
}

const GridViewIcon = ({ isActive, theme, width, height }: Props) => {
    return (
        <svg
            width={width ?? '18'}
            height={height ?? '18'}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 0.25H7.75V7.75H0.25V0.25ZM1.75 1.75V6.25H6.25V1.75H1.75Z"
                fill={`${
                    isActive
                        ? colours[theme].primary
                        : colours[theme].text_muted
                }`}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 10.25H7.75V17.75H0.25V10.25ZM1.75 11.75V16.25H6.25V11.75H1.75Z"
                fill={`${
                    isActive
                        ? colours[theme].primary
                        : colours[theme].text_muted
                }`}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.25 0.25H17.75V7.75H10.25V0.25ZM11.75 1.75V6.25H16.25V1.75H11.75Z"
                fill={`${
                    isActive
                        ? colours[theme].primary
                        : colours[theme].text_muted
                }`}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.25 10.25H17.75V17.75H10.25V10.25ZM11.75 11.75V16.25H16.25V11.75H11.75Z"
                fill={`${
                    isActive
                        ? colours[theme].primary
                        : colours[theme].text_muted
                }`}
            />
        </svg>
    )
}

export default GridViewIcon
