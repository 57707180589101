import styled from 'styled-components'
import { getEmbedIDFromVideoURL } from '../../../helpers/textTruncate/videoUrlTruncate'

type Props = {
    embedURL?: string
}

const IFrameStyle = styled.iframe`
    height: 192px;
    @media (min-width: 520px) {
        height: 300px;
    }
`

const ShowroomVideoEmbedMobile = (props: Props) => {
    let videoURL =
        props.embedURL &&
        props.embedURL !== '' &&
        getEmbedIDFromVideoURL(props.embedURL)
    return (
        <>
            {videoURL && (
                <div className="video-responsive" style={{ width: '90%' }}>
                    <IFrameStyle
                        width="100%"
                        src={videoURL}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Video"
                    />
                </div>
            )}
        </>
    )
}

export default ShowroomVideoEmbedMobile
