import React from 'react'
import styled from 'styled-components'
import { IDropdownItem } from 'entityModels'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import chevronIcon from '../../../../public/assets/icons/chevron_grey_to_right.svg'
import check from '../../../../public/assets/icons/checked_green.svg'
import IconButton from '../../Button/iconButton'
import { device } from '../../../templates/displays/devices'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'

export type Props = {
    dropdownData: IDropdownItem[]
    dataCyId?: string
    isDisabled?: boolean
    buttonMinWidth?: string
    onSelected?: (value: string) => void
    currentOption?: string | null
    btnHeight?: string
    dropdownWidth?: number
    hasSelectedCheck?: boolean
    iconBtn?: boolean
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

type IStyle = {
    selected?: boolean
    isActive?: boolean
    $theme: ITheme
}

const DropdownOptions = styled.ul<IStyle>`
    position: absolute;
    z-index: 110;
    margin-top: 10px;
    width: 220px;

    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    border-radius: 4px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 4px 4px 15px
        ${(props) => colours[props.$theme].shadow_strongest_04};
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: none;
    height: 40px;
    background-color: transparent;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    color: ${(props) =>
        props.selected
            ? colours[props.$theme].text_strong
            : colours[props.$theme].text_default};
    font-size: 15px;
    font-family: Lato;

    :hover {
        background-color: ${(props) =>
            colours[props.$theme].shadow_strongest_04};
    }
`

const Wrapper = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
`

const FilterItemBtn = styled.div<IStyle>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;

    width: max-content;
    padding-left: 16px;
    padding-right: 16px;

    border: 1px solid ${(props) => colours[props.$theme].border_muted};
    border-radius: 4px;
    font-size: 16px;
    font-family: Lato;
    color: ${(props) => colours[props.$theme].text_default};
    cursor: pointer;
    transition: all 150ms;
    user-select: none;
    background: ${(props: IStyle) =>
        props.isActive
            ? colours[props.$theme].shadow_strongest_04
            : colours[props.$theme].background_default};

    ${(props) =>
        props.isActive &&
        `
            background-color: ${colours[props.$theme].background_default};
            outline: 2px solid var(--border-muted, #e5e5e5);
            outline-offset: -2px;
        `}

    :hover {
        background: ${(props) => colours[props.$theme].shadow_strongest_04};
    }

    @media ${device.mobile_and_ipad} {
        font-size: 12px !important;
        height: 30px;
        padding-left: 8px;
        padding-right: 8px;
    }
`

const FilterIconWrapper = styled.div`
    padding-left: 2px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 10px;

    @media ${device.mobile_and_ipad} {
        margin-left: 4px;
    }
`

const MobileAdjust = styled.div<IStyle>`
    @media ${device.mobile_and_ipad} {
        background: ${(props) => colours[props.$theme].error};
        transform: scale(0.9);
    }
`

type StateSelect = {
    showDropdown: boolean
    currentSelected: string | undefined | null
}

const getOptionFronmParam = (value: string, items: IDropdownItem[]) => {
    let result = items.filter(
        (item) =>
            item.uid &&
            item.uid.toLocaleLowerCase() === value.toLocaleLowerCase()
    )
    if (result && result[0] && result[0].name) {
        return result[0].name
    } else return undefined
}

class SortMenuDesktop extends React.Component<Props, StateSelect> {
    state = {
        showDropdown: false,
        currentSelected: this.props.currentOption
            ? this.props.currentOption
            : this.props.dropdownData[0].name,
    }
    _isMounted = false

    private dropdowRef = React.createRef<HTMLDivElement>()

    handleClickOutsideDropdown(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.setState({
                showDropdown: false,
            })
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.dropdowRef.current!.focus()
        let node = this.dropdowRef.current
        document.addEventListener('mousedown', (e: any) =>
            this.handleClickOutsideDropdown(e, node)
        )
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.currentOption !== this.props.currentOption) {
            this.setState({
                ...this.state,
                currentSelected: this.props.currentOption,
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropdown
        )
        this._isMounted = false
    }

    render() {
        const {
            dataCyId,
            dropdownData,
            onSelected,
            btnHeight,
            dropdownWidth,
            hasSelectedCheck,
            iconBtn,
            onMouseEnter,
            onMouseLeave,
        } = this.props

        const handleFilterButtonClick = () => {
            if (this.state.showDropdown === true) {
                this.setState({
                    showDropdown: false,
                })
            } else {
                this.setState({
                    showDropdown: true,
                })
            }
        }

        let sortedBy = this.props.currentOption
            ? getOptionFronmParam(this.props.currentOption, dropdownData)
            : undefined

        let mappedDropdownData = (theme: ITheme) =>
            dropdownData &&
            dropdownData.map((item: IDropdownItem, index: number) => {
                if (item) {
                    return (
                        <StyledOption
                            $theme={theme}
                            style={btnHeight ? { height: btnHeight } : {}}
                            selected={this.state.currentSelected === item.uid}
                            value={item.name}
                            data-ph={item.uid}
                            key={`${item.name}-${index}-desktop`}
                            aria-label={item.name}
                            onClick={() => {
                                onSelected ? onSelected(item.uid) : {}
                                this.setState({
                                    showDropdown: false,
                                })
                            }}
                        >
                            <ContentRow>{item.name}</ContentRow>
                            {hasSelectedCheck &&
                                this.state.currentSelected === item.uid && (
                                    <img
                                        src={check}
                                        style={{ width: 16, height: 16 }}
                                    />
                                )}
                        </StyledOption>
                    )
                } else {
                    return null
                }
            })

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper data-attr={dataCyId} ref={this.dropdowRef}>
                        {iconBtn ? (
                            <MobileAdjust $theme={theme}>
                                <IconButton
                                    buttonheight="48px"
                                    buttonwidth="48px"
                                    bgcolor="transparent"
                                    borderColor={
                                        this.props.currentOption
                                            ? 'var(--primary, #5ec3ca)'
                                            : 'var(--border-muted, #e5e5e5)'
                                    }
                                    icon={
                                        this.props.currentOption
                                            ? 'sort-icon-blue'
                                            : 'sort-icon-grey'
                                    }
                                    onClick={
                                        this.props.isDisabled
                                            ? () => {}
                                            : handleFilterButtonClick
                                    }
                                    onMouseEnter={onMouseEnter && onMouseEnter}
                                    onMouseLeave={onMouseLeave && onMouseLeave}
                                    activestyle={
                                        this.state.showDropdown
                                            ? {
                                                  border: '2px solid var(--primary, #5ec3ca)',
                                              }
                                            : undefined
                                    }
                                />
                            </MobileAdjust>
                        ) : (
                            <FilterItemBtn
                                $theme={theme}
                                onClick={
                                    this.props.isDisabled
                                        ? () => {}
                                        : handleFilterButtonClick
                                }
                                onMouseEnter={onMouseEnter && onMouseEnter}
                                onMouseLeave={onMouseLeave && onMouseLeave}
                                isActive={this.state.showDropdown}
                                style={btnHeight ? { height: btnHeight } : {}}
                            >
                                {this.props.currentOption
                                    ? `${sortedBy ?? ''}`
                                    : 'Sort By'}

                                <FilterIconWrapper>
                                    <img
                                        alt="filter icon"
                                        src={chevronIcon}
                                        style={{
                                            transform: this.state.showDropdown
                                                ? 'rotate(270deg) translateX(0px)'
                                                : 'rotate(90deg) translateX(2px)',
                                            transition: 'all 200ms',
                                            marginLeft: 8,
                                        }}
                                    />
                                </FilterIconWrapper>
                            </FilterItemBtn>
                        )}

                        <SlideDownWrapper
                            isOpen={this.state.showDropdown}
                            initialX={dropdownWidth ? -dropdownWidth : -220}
                            originX={
                                dropdownWidth ? `${dropdownWidth}px` : '220px'
                            }
                        >
                            <DropdownOptions
                                $theme={theme}
                                style={
                                    dropdownWidth
                                        ? { width: `${dropdownWidth}px` }
                                        : {}
                                }
                            >
                                {dropdownData &&
                                    dropdownData.length > 0 &&
                                    mappedDropdownData(theme)}
                            </DropdownOptions>
                        </SlideDownWrapper>
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default SortMenuDesktop
