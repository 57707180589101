type Props = {
    size?: string
    color?: string
    bgColor?: string
}

const HeartIcon = ({ size, color, bgColor }: Props) => {
    return (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.78961 0C3.55696 0 2.32435 0.483464 1.39427 1.45818C-0.465955 3.40757 -0.463556 6.53131 1.39427 8.482L8.49494 15.9389C8.55958 16.0068 8.63731 16.0609 8.72343 16.0978C8.80955 16.1348 8.90227 16.1538 8.99596 16.1538C9.08965 16.1538 9.18236 16.1348 9.26848 16.0978C9.3546 16.0609 9.43234 16.0068 9.49698 15.9389C11.8656 13.4566 14.2362 10.9715 16.6049 8.48921C18.465 6.53978 18.465 3.41482 16.6049 1.4654C14.7447 -0.484019 11.6744 -0.48406 9.81416 1.4654L8.99956 2.30999L8.18497 1.45818C7.25486 0.483469 6.02226 0 4.78961 0Z"
                fill={bgColor ?? '#fff'}
            />
            <path
                d="M4.78961 0C3.55696 0 2.32435 0.483464 1.39427 1.45818C-0.465955 3.40757 -0.463556 6.53131 1.39427 8.482L8.49494 15.9389C8.55958 16.0068 8.63731 16.0609 8.72343 16.0978C8.80955 16.1348 8.90227 16.1538 8.99596 16.1538C9.08965 16.1538 9.18236 16.1348 9.26848 16.0978C9.3546 16.0609 9.43234 16.0068 9.49698 15.9389C11.8656 13.4566 14.2362 10.9715 16.6049 8.48921C18.465 6.53978 18.465 3.41482 16.6049 1.4654C14.7447 -0.484019 11.6744 -0.48406 9.81416 1.4654L8.99956 2.30999L8.18497 1.45818C7.25486 0.483469 6.02226 0 4.78961 0ZM4.78961 1.35712C5.65007 1.35712 6.51638 1.71218 7.19016 2.41827L8.50216 3.79704C8.5668 3.86495 8.64453 3.91903 8.73065 3.95597C8.81677 3.99291 8.90948 4.01194 9.00317 4.01194C9.09685 4.01194 9.18956 3.99291 9.27568 3.95597C9.3618 3.91903 9.43954 3.86495 9.50417 3.79704L10.809 2.42549C12.1565 1.01329 14.2553 1.01332 15.6028 2.42549C16.9504 3.83765 16.9504 6.12417 15.6028 7.53634C13.4021 9.84268 11.2003 12.1456 8.99956 14.4519L2.39628 7.52913C1.04947 6.11498 1.04875 3.8304 2.39628 2.41827C3.07003 1.71219 3.92914 1.35712 4.78961 1.35712Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default HeartIcon
