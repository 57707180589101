import { call, fork, put, take } from 'redux-saga/effects'

import { api } from '../../../services'
import { ICustomErrorData, IDropdownItem, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'

function* setTransmissionTypesDropdownDataApi() {
    try {
        const dataReceived: string[] =
            yield api.dropdownsData.get_transmission_types_dropdown_api()
        let convertedData: IDropdownItem[] = dataReceived.map(
            (item: string, index: number) => {
                return {
                    uid: `${item}`,
                    name: item,
                }
            }
        )
        yield put(
            dropDownActions.setTransmissionTypesDropdownDataSuccess(
                convertedData
            )
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setTransmissionTypesDropdownDataApi,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_message_detail: 'Check your connection and refresh',
                custom_user_action_text: 'Refresh page',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            yield put(
                dropDownActions.setTransmissionTypesDropdownDataError(
                    customError
                )
            )
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            yield put(
                dropDownActions.setTransmissionTypesDropdownDataError(
                    typedError
                )
            )
        }
    }
}
function* watcherSetMakeDropdownData() {
    while (true) {
        yield take(dropDownActions.setTransmissionTypesDropdownDataRequest)
        yield call(setTransmissionTypesDropdownDataApi)
    }
}

const set_transmission_types_dropdown_data: any[] = [
    fork(watcherSetMakeDropdownData),
]
export default set_transmission_types_dropdown_data
