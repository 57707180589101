import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

type Props = {
    direction: 'left' | 'right' | ''
    initial: false | 'enter'
    children?: React.ReactNode
    customZIndex?: number
    isVisible: boolean
    customDuration?: number
    noOverflow?: boolean
    isAbsolute?: boolean
    removeRightValue?: boolean
}

const SideSliderWithExit = (props: Props) => {
    const variants = {
        enter: () => ({
            x:
                props.direction === 'right'
                    ? 900
                    : props.direction === 'left'
                    ? -900
                    : 0,
            opacity: 0,
        }),
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1,
        },
        exit: (direction: number) => ({
            zIndex: 0,
            x:
                props.direction === 'right'
                    ? 900
                    : props.direction === 'left'
                    ? -900
                    : 0,
            opacity: 0,
        }),
    }

    return (
        <AnimatePresence>
            {props.isVisible && (
                <div
                    style={
                        props.noOverflow
                            ? {
                                  position: props.isAbsolute
                                      ? 'absolute'
                                      : 'fixed',
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: props.removeRightValue ? undefined : 0,
                                  zIndex: props.customZIndex
                                      ? props.customZIndex
                                      : 6,
                              }
                            : {
                                  position: props.isAbsolute
                                      ? 'absolute'
                                      : 'fixed',
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: props.removeRightValue ? undefined : 0,
                                  zIndex: props.customZIndex
                                      ? props.customZIndex
                                      : 6,
                                  overflowY: 'auto',
                                  overflowX: 'hidden',
                              }
                    }
                >
                    <motion.div
                        variants={variants}
                        initial={props.initial}
                        animate="center"
                        exit="exit"
                        transition={{
                            opacity: { duration: 0.6 },
                            type: 'linear',
                            duration: 0.5,
                        }}
                    >
                        {props.children}
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    )
}

export default SideSliderWithExit
