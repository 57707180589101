import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

import {
    GET_APEX_CARS_OF_THE_WEEK_ERROR,
    GET_APEX_HOMEPAGE_CONTENT_ERROR,
    GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_ERROR,
    GET_APEX_PODCASTS_ERROR,
    GET_APEX_SINGLE_INTERVIEW_BY_UID_ERROR,
    GET_WEEKLY_HIGHLIGHTS_ERROR,
    GET_APEX_SINGLE_ARTICLE_BY_UID_ERROR,
    GET_APEX_ARTICLE_OVERVIEWS_ERROR,
} from './constants'

// export const getApexHomepageContentError = createAction(
//     GET_APEX_HOMEPAGE_CONTENT_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getApexHomepageContentError = createAction(
    GET_APEX_HOMEPAGE_CONTENT_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getApexCarsOfTheWeekError = createAction(
//     GET_APEX_CARS_OF_THE_WEEK_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getApexCarsOfTheWeekError = createAction(
    GET_APEX_CARS_OF_THE_WEEK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getApexPodcastsError = createAction(
//     GET_APEX_PODCASTS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getApexPodcastsError = createAction(
    GET_APEX_PODCASTS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getWeeklyHighlightsError = createAction(
//     GET_WEEKLY_HIGHLIGHTS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getWeeklyHighlightsError = createAction(
    GET_WEEKLY_HIGHLIGHTS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getApexInterviewOverviewsError = createAction(
//     GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getApexInterviewOverviewsError = createAction(
    GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getApexSingleInterviewByUIDError = createAction(
//     GET_APEX_SINGLE_INTERVIEW_BY_UID_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getApexSingleInterviewByUIDError = createAction(
    GET_APEX_SINGLE_INTERVIEW_BY_UID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getApexSingleArticleByUIDError = createAction(
//     GET_APEX_SINGLE_ARTICLE_BY_UID_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getApexSingleArticleByUIDError = createAction(
    GET_APEX_SINGLE_ARTICLE_BY_UID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getApexArticleOverviewsError = createAction(
//     GET_APEX_ARTICLE_OVERVIEWS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getApexArticleOverviewsError = createAction(
    GET_APEX_ARTICLE_OVERVIEWS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
