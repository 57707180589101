import { IEntryCostPayloadAPI } from 'IapiDataPayload'
import { ICostItem } from 'timelineModels'

export const ConvertAPIEntryCostToEntryCostSingle = (
    entryCostAPI: IEntryCostPayloadAPI
): ICostItem => {
    // let length: number | undefined = undefined

    // if (entryCostAPI.attachments && entryCostAPI.attachments.length > 0) {
    //     length = entryCostAPI.attachments.length - 1
    // }

    let entry_cost_item: ICostItem = {
        id: entryCostAPI.uid,
        name: entryCostAPI.name,
        amount: entryCostAPI.amount ? entryCostAPI.amount / 100 : 0,
        company:
            entryCostAPI.company && entryCostAPI.company.Name
                ? entryCostAPI.company.Name
                : '',
        attachmentID:
            entryCostAPI.attachments &&
            entryCostAPI.attachments.length > 0 &&
            entryCostAPI.attachments &&
            entryCostAPI.attachments[entryCostAPI.attachments.length - 1] &&
            entryCostAPI.attachments[entryCostAPI.attachments.length - 1].uid
                ? entryCostAPI.attachments[entryCostAPI.attachments.length - 1]
                      .uid
                : undefined,
        currency: entryCostAPI.currency ? entryCostAPI.currency : 'GBP',
        user_amount: entryCostAPI.user_amount
            ? entryCostAPI.user_amount / 100
            : undefined,
        user_currency: entryCostAPI.user_currency
            ? entryCostAPI.user_currency
            : 'GBP',
    }

    return entry_cost_item
}
