import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import HelpQuickAccessItemMobile from '../../atoms/helpQuickAccessItemMobile/helpQuickAccessItemMobile'

const Wrap = styled.section`
    width: 100%;
    height: 100%;
    padding: 10px 30px 20px 30px;

    @media ${device.ipad} {
        display: flex;
        justify-content: center;
        /* gap: 20px; */
    }

    .row {
        margin: 20px 0px;
        display: flex;
        justify-content: space-around;
    }

    @media ${device.ipad} {
        .row {
            display: inline-flex;
        }
        .paddedForIpad {
            padding: 0px 20px;
        }
    }
`

interface Props {
    someProp?: any
}

const HelpQuickAccessMobile: React.SFC<Props> = (props) => (
    <Wrap>
        <div className="row">
            <HelpQuickAccessItemMobile url="#faq-mobile">
                FAQ
            </HelpQuickAccessItemMobile>
            <div className='paddedForIpad'>
                <HelpQuickAccessItemMobile url="#tutorials-mobile">
                    Tutorials
                </HelpQuickAccessItemMobile>
            </div>
        </div>
        <div className="row">
            <HelpQuickAccessItemMobile url="#contact-mobile">
                Contact
            </HelpQuickAccessItemMobile>
        </div>
    </Wrap>
)

export default HelpQuickAccessMobile
