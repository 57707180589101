import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import SelectSearchBarCarInfoDesktop from '../../searchselect/desktop/selectSearchBarCarInfoDesktop'
import { IwriteMultipleTechnicalInformationPayloadReq } from 'entityModels'
import InputField from '../../../../../atoms/Inputfield/inputField'
import { IYearsValidationCopy } from 'formValidationCopyModels'
import Expander from '../../../../../atoms/expander/expander'
import { writeMultipleTechnicalInformationRequest } from '../../../../../../redux/entities/technical_information/actions/loadingActions'
import { ISetCustomItemInSpecifiedTargettedDropdownReq } from '../../../../../../redux/localdata/dropdownData/sagas/setCustomItemInSpecifiedTargettedDropdown'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
    Notice,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

// import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'

const SmallPadding = styled.div`
    padding-top: 22px;
`

const Padding16 = styled.div`
    padding-top: 16px;
`

const WarningText = styled.div`
    color: #f08080;
    font-size: 14px;
    padding-top: 5px;
    width: 100%;
    align-items: start;
    justify-content: flex-start;
    font-style: italic;
    font-family: Lato;
`

const dispatchProps = {
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => writeMultipleTechnicalInformationRequest(p),
    setCustomItemInSpecifiedTargettedDropdown: (
        payload: ISetCustomItemInSpecifiedTargettedDropdownReq
    ) =>
        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
            payload
        ),
    setIsLoadingNewMakeOrModel: (p: boolean) =>
        dropDownActions.setIsLoadingNewMakeOrModel(p),
}

function mapStateToProps(state: RootState) {
    return {
        modelDropdownList: state.localdata.dropdownData.carModels,
        isLoading: state.localdata.dropdownData.loading,
        isLoadingNewMakeOrModel:
            state.localdata.dropdownData.isLoadingNewMakeOrModel,
        car_years_validation:
            state.localdata.form_validation_copy.years.car_year,
    }
}

interface Values {
    car_model_custom_model_desktop: string
    car_year_custom_model_desktop: any
}

interface OtherProps {
    toggle: any
    model_item: any
    year_item: any
    submitFunc?: any
    tech_info_id?: any
    writeMultipleTechnicalInformationRequest: (
        P: IwriteMultipleTechnicalInformationPayloadReq
    ) => {}
    modelDropdownList: any
    setCustomItemInSpecifiedTargettedDropdown: any
    isLoading: boolean
    make_item: any
    carid: string
    car_years_validation?: IYearsValidationCopy
    setIsLoadingNewMakeOrModel: (p: boolean) => void
    isLoadingNewMakeOrModel: boolean
}

const defaultOption = [
    {
        uid: 'nodata',
        name: '-',
    },
]

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        model_item,
        year_item,
        setFieldValue,
        handleSubmit,
        modelDropdownList,
        setCustomItemInSpecifiedTargettedDropdown,
        make_item,
        values,
        errors,
        setIsLoadingNewMakeOrModel,
        isLoadingNewMakeOrModel,
    } = props

    let checkIfDisabled = () => {
        if (
            !values.car_model_custom_model_desktop ||
            values.car_model_custom_model_desktop === '-'
        ) {
            return true
        } else if (
            errors.car_model_custom_model_desktop ||
            errors.car_year_custom_model_desktop
        ) {
            return true
        } else if (isLoadingNewMakeOrModel) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop
            $theme={theme}
            id="scrollable-content"
            style={{ marginTop: '15vh' }}
        >
            <ModalInnerContentWrapperDesktop>
                {model_item !== undefined &&
                    year_item !== undefined &&
                    make_item !== undefined && (
                        <>
                            <ModalTitle $theme={theme}>Model | Year</ModalTitle>
                            <Padding16 />
                            <Notice>
                                If you can't find your car's model, simply type
                                the model name fully, then select "
                                <span style={{ fontWeight: 'bold' }}>add</span>
                                ".
                            </Notice>
                            <Expander
                                height={make_item.answer === null ? 'auto' : 0}
                            >
                                <WarningText>
                                    Please enter the car's make.
                                </WarningText>
                            </Expander>
                            <SmallPadding />

                            <SelectSearchBarCarInfoDesktop
                                handleSelectChange={(name: string) => {
                                    setFieldValue(
                                        'car_model_custom_model_desktop',
                                        name
                                    )
                                    // setYearDropdownData(name);
                                }}
                                addAnItemFunc={(id: string, name: string) => {
                                    setIsLoadingNewMakeOrModel(true)

                                    setCustomItemInSpecifiedTargettedDropdown({
                                        dropdownTargetId: id,
                                        name: name,
                                        relevantOtherLinkedDataMake:
                                            make_item.answer,
                                    })
                                }}
                                item={model_item}
                                placeholder="Car Model"
                                dropdownTargetId="carModels"
                                items={
                                    modelDropdownList
                                        ? modelDropdownList
                                        : defaultOption
                                }
                                formCurrentValue={
                                    props.values[
                                        'car_model_custom_model_desktop'
                                    ]
                                }
                                allowAdd={true}
                                isDisabled={
                                    make_item.answer === null ? true : false
                                }
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={
                                    make_item.answer !== null &&
                                    values['car_model_custom_model_desktop'] ===
                                        null
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <WarningText>
                                    Please enter the car's model.
                                </WarningText>
                            </Expander>
                            <SmallPadding />

                            <InputField
                                theme={theme}
                                id="car_year_custom_model_desktop"
                                name="car_year_custom_model_desktop"
                                placeholder={'year'}
                                tabindex={1}
                                aria-live="polite"
                                formikprops={props}
                                value={values.car_year_custom_model_desktop}
                                font_family="Lato"
                                width="150px"
                                type="number"
                                disabled={
                                    values['car_model_custom_model_desktop'] ===
                                        null && model_item.answer === null
                                        ? true
                                        : false
                                }
                                onEnterKeyPress={props.handleSubmit}
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />

                            <Expander
                                height={
                                    errors.car_year_custom_model_desktop
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <WarningText>
                                    {errors.car_year_custom_model_desktop}
                                </WarningText>
                            </Expander>

                            {/* <SelectSearchBarYearsDesktop
              handleSelectChange={(name: string) =>
                setFieldValue("car_year_custom_model_desktop", name)
              }
              item={year_item}
              items={yearsDropdownList ? yearsDropdownList : defaultOption}
              dropdownTargetId="carYears"
              formCurrentValue={props.values["car_year_custom_model_desktop"]}
              allowAdd={false}
              isDisabled={
                !props.values["car_year_custom_model_desktop"] &&
                model_item.answer === null
                  ? true
                  : false
              }
            /> */}
                        </>
                    )}
                <SmallPadding />
                <SmallPadding />
                <ButtonsStandardBottomRowDesktop
                    isDisabled={checkIfDisabled()}
                    cancel={toggle}
                    submit={handleSubmit}
                    isSecondTextLoading={isLoadingNewMakeOrModel}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarModelCustomFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        car_model_custom_model_desktop: props.model_item
            ? props.model_item.answer
            : '-',
        car_year_custom_model_desktop: props.year_item
            ? props.year_item.answer
            : '-',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                car_year_custom_model_desktop:
                    values.car_year_custom_model_desktop &&
                    values.car_year_custom_model_desktop !== '-' &&
                    props.car_years_validation &&
                    Yup.number()
                        .min(
                            props.car_years_validation.min,
                            props.car_years_validation.warning_min
                        )
                        .max(
                            props.car_years_validation.max,
                            props.car_years_validation.warning_max
                        ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteMultipleTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: [
                {
                    id: 'model',
                    text: 'model',
                    answer: values.car_model_custom_model_desktop,
                },
                {
                    id: 'year',
                    text: 'year',
                    answer: values.car_year_custom_model_desktop,
                },
            ],
        }

        props.writeMultipleTechnicalInformationRequest(p)
        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarModelCustomFormDesktop)
