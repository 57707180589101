import { IExternalURLApi } from 'IapiDataPayload'
import { apiSentryErrorHandler } from '../errorHandler'
import { customHeader } from '../headers'
import * as urls from '../urls'

export const createExternalURL = (data: IExternalURLApi): Promise<any> => {
    let newD = {
        url: data.url,
        url_type: data.url_type,
    }

    let reqp: string = JSON.stringify(newD)
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: reqp,
        credentials: 'include',
    }

    return fetch(urls.external_url_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: IExternalURLApi) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create external url error',
                            reqp
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
