import React, { useState } from 'react'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import replayIcon from '../../../public/assets/tutorials_replay/replay.svg'
import { Link } from 'react-router-dom'

const HelpTutorialItemStyle = styled.div<IStyleProps>`
    max-width: 300px;
    opacity: ${(props) => (props.$isDisabled ? 0.8 : 1)};
`
const Title = styled.div`
    font-family: 'Lato-light';
    color: var(--text-strong, #1a1a1a);
    font-size: 18px;
    font-weight: 300;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0px 10px 0px;
`

const LinkArea = styled(Link)<IStyleProps>`
    cursor: ${(props) => props.$isDisabled && 'not-allowed'};
    height: 200px;
    width: 250px;
    color: #f1f1f1;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.2s ease-in-out;

    :hover {
        transform: ${(props) => !props.$isDisabled && 'scale(1.1)'};
        color: #f1f1f1;
    }
`

const Image = styled.div<IStyleProps>`
    height: 200px;
    width: 250px;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)),
        url(${(props) => props.url});
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    filter: ${(props) => (props.$isDisabled ? 'grayscale(100%)' : '')};
    cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

    :hover {
        background: ${(props) =>
            !props.$isDisabled &&
            `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
            url(${props.url})`};
    }
`

const ReplayIcon = styled.img<IStyleProps>`
    height: 50px;
    src: ${(props) => props.src};
`

interface IStyleProps {
    url?: string
    $isDisabled?: boolean
    src?: string
}

interface IHelpTutorialItem {
    title: string
    imgUrl: string
    link: string
    isDisabled?: boolean
    tooltipTitle?: string
}

// add IStyledProps with isDisabled option
// onMouseEnter: tooltip diplay true , onMouseLeave: tooltip diplay false
// mobile : tooltip toggle on click
// manage tooltip using useState() / react Hooks, not showing by default ( false)
// if disabled, add a filter w/ greycale on the image comp

const HelpTutorialItem: React.SFC<IHelpTutorialItem> = (props) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <HelpTutorialItemStyle $isDisabled={props.isDisabled}>
            <Title>{props.title}</Title>
            <Image
                url={props.imgUrl}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                $isDisabled={props.isDisabled}
            >
                <LinkArea to={props.link} $isDisabled={props.isDisabled}>
                    <ReplayIcon src={replayIcon} />
                    <span>Replay</span>
                    {props.isDisabled && (
                        <Tooltip
                            title={props.tooltipTitle}
                            open={showTooltip}
                            placement="left"
                            color="#5EC3CA"
                        />
                    )}
                </LinkArea>
            </Image>
        </HelpTutorialItemStyle>
    )
}

export default HelpTutorialItem
