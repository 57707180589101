import { ICostItem, ITimelineItem } from 'timelineModels'
import {
    costNumShorterFormatter,
    numberWithCommas,
} from '../../../helpers/currencyConverter'

const calculateTotalCostOnCreate = (
    entry: ITimelineItem,
    cost: ICostItem
): number => {
    let am = cost.user_amount ? cost.user_amount : cost.amount

    if (entry.total_cost_amount_raw && cost.amount && am) {
        return entry.total_cost_amount_raw + am
    } else if (!entry.total_cost_amount_raw && am) {
        return am
    } else if (entry.total_cost_amount_raw && !am) {
        return entry.total_cost_amount_raw
    } else return 0
}

const calculateTotalCostOnUpdate = (
    entry: ITimelineItem,
    cost: ICostItem,
    oldCostAmount: number | undefined
): number => {
    let am = cost.user_amount ? cost.user_amount : cost.amount

    if (
        entry.total_cost_amount_raw &&
        (cost.amount || cost.amount === 0) &&
        oldCostAmount &&
        am
    ) {
        return entry.total_cost_amount_raw + am - oldCostAmount
    } else if (entry.total_cost_amount_raw && am && !oldCostAmount) {
        return entry.total_cost_amount_raw + am
    } else if (entry.total_cost_amount_raw && !am) {
        return entry.total_cost_amount_raw
    } else return 0
}

const calculateTotalCostOnDelete = (
    entry: ITimelineItem,
    cost: ICostItem
): number => {
    let am = cost.user_amount ? cost.user_amount : cost.amount

    if (entry.total_cost_amount_raw && am) {
        return entry.total_cost_amount_raw - am
    } else if (entry.total_cost_amount_raw && !am) {
        return entry.total_cost_amount_raw
    } else return 0
}

export const UpdateActiveEntryWithCost = (
    action: 'update' | 'create' | 'delete',
    active_entry: ITimelineItem | undefined,
    cost_item: ICostItem,
    oldCostAmount?: number | undefined
): ITimelineItem | undefined => {
    switch (action) {
        case 'update': {
            if (
                active_entry &&
                active_entry.costItemsObj &&
                active_entry.costItemsObj[cost_item.id]
            ) {
                active_entry.costItemsObj[cost_item.id] = {
                    ...cost_item,
                }

                active_entry.total_cost_amount_display = numberWithCommas(
                    calculateTotalCostOnUpdate(
                        active_entry,
                        cost_item,
                        oldCostAmount
                    )
                )

                let costOnUpdate = calculateTotalCostOnUpdate(
                    active_entry,
                    cost_item,
                    oldCostAmount
                )

                active_entry.total_cost_amount_display_short =
                    costNumShorterFormatter(costOnUpdate)
            }
            return active_entry
        }
        case 'create': {
            if (active_entry) {
                let new_cost_obj = {
                    [cost_item.id]: cost_item,
                }
                active_entry.costItemsObj = active_entry.costItemsObj
                    ? {
                          ...active_entry.costItemsObj,
                          ...new_cost_obj,
                      }
                    : {
                          ...new_cost_obj,
                      }

                // get total sum of these amounts
                let rawTotalCost: number = calculateTotalCostOnCreate(
                    active_entry,
                    cost_item
                )
                // assign new raw result to entry total_cost_amount_raw
                active_entry.total_cost_amount_raw = rawTotalCost

                // assign formatted result to entry total_cost_amount_display
                active_entry.total_cost_amount_display =
                    numberWithCommas(rawTotalCost)

                // assign a shorter formatted result to entry total_cost_amount_display
                active_entry.total_cost_amount_display_short =
                    costNumShorterFormatter(rawTotalCost)

                if (active_entry.costUIDS) {
                    if (active_entry.costUIDS.indexOf(cost_item.id) < 0) {
                        active_entry.costUIDS.push(cost_item.id)
                    }
                } else {
                    active_entry.costUIDS = [cost_item.id]
                }
            }
            return active_entry
        }
        case 'delete': {
            if (active_entry && active_entry.costUIDS) {
                let index = active_entry.costUIDS.indexOf(cost_item.id)
                if (index > -1) {
                    active_entry.costUIDS.splice(index, 1)

                    // get total sum of these amounts
                    let rawTotalCost: number = calculateTotalCostOnDelete(
                        active_entry,
                        cost_item
                    )
                    // assign new raw result to entry total_cost_amount_raw
                    active_entry.total_cost_amount_raw = rawTotalCost

                    // assign formatted result to entry total_cost_amount_display
                    active_entry.total_cost_amount_display =
                        numberWithCommas(rawTotalCost)

                    // assign a shorter formatted result to entry total_cost_amount_display
                    active_entry.total_cost_amount_display_short =
                        costNumShorterFormatter(rawTotalCost)
                }
            }

            if (active_entry && active_entry.costItemsObj) {
                delete active_entry.costItemsObj[cost_item.id]
            }

            return active_entry
        }
        default:
            return active_entry
    }
}
