import {
    SIGNIN_USER_REQUEST,
    REGISTER_USER_REQUEST,
    LOGOUT_USER_REQUEST,
    ADD_PHONE_REQUEST,
    ADD_ADDRESS_REQUEST,
    ADD_CATEGORIES_REQUEST,
    VERIFY_REQUEST,
    SEND_INVITATION_REQUEST,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_VERIFIED_REQUEST,
    VERIFY_RESET_PASSWORD_CODE_REQUEST,
    GET_CURRENT_USER_DATA_REQUEST,
    UPDATE_USER_DATA_GENERAL_INFO_REQUEST,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_ONCE_REQUEST,
    GET_USER_DIRECT_SHARES_RECEIVED_REQUEST,
    POPULATE_OWNED_SHARES_DATA_REQUEST,
    UPDATE_SHARE_RECEIVED_STATUS_REQUEST,
    DELETE_ACCOUNT_REQUEST,
    GET_USER_ARCHIVED_CARS_REQUEST,
    LIST_USER_HANDOVERS_REQUEST,
    SOCIAL_AUTH_USER_REQUEST,
} from './constants'
import { IUSerGeneralInfoUpdatePayload } from 'myModels'

import { createAction } from '@reduxjs/toolkit'
import { IRegisterPayloadReq } from '../sagas/authSagas'
import {
    ISignInPayloadReq,
    IUpdateShareReveivedStatusApi_arg,
} from 'ApiInterfaces'
import { IAccDelItems, IUserAddressApi } from 'IapiDataPayload'
import { IAuthSocialRequestPayload } from '../sagas/socialAuth'

export const verifyRequest = createAction(VERIFY_REQUEST)

export const signInUserRequest = createAction(
    SIGNIN_USER_REQUEST,
    function prepare(payload: ISignInPayloadReq) {
        return {
            payload,
        }
    }
)

export const registerRequest = createAction(
    REGISTER_USER_REQUEST,
    function prepare(payload: IRegisterPayloadReq) {
        return {
            payload,
        }
    }
)

export const socialAuthRequest = createAction(
    SOCIAL_AUTH_USER_REQUEST,
    function prepare(payload: IAuthSocialRequestPayload) {
        return {
            payload,
        }
    }
)

export const logoutRequest = createAction(LOGOUT_USER_REQUEST)

export const addPhoneRequest = createAction(ADD_PHONE_REQUEST)

export type IaddAddressRequest = {
    data: IUserAddressApi
    url_to_direct_on_success?: string
}
export const addAddressRequest = createAction(
    ADD_ADDRESS_REQUEST,
    function prepare(payload: IaddAddressRequest) {
        return {
            payload,
        }
    }
)

export const addCategoriesRequest = createAction(ADD_CATEGORIES_REQUEST)

export const sendInvitationRequest = createAction(SEND_INVITATION_REQUEST)

export const resetPasswordRequest = createAction(
    RESET_PASSWORD_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getCurrentUserDataRequest = createAction(
    GET_CURRENT_USER_DATA_REQUEST,
    // context is where user is
    // ex: 'LOGIN' | 'REGISTER'
    function prepare(payload?: 'LOGIN' | 'REGISTER') {
        return {
            payload,
        }
    }
)

export const verifiedResetPasswordRequest = createAction(
    RESET_PASSWORD_VERIFIED_REQUEST,
    function prepare(payload: { passwordGiven: string; uid: string }) {
        return {
            payload,
        }
    }
)

export const verifyResetPasswordCodeRequest = createAction(
    VERIFY_RESET_PASSWORD_CODE_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const updateUserGeneralInfoRequest = createAction(
    UPDATE_USER_DATA_GENERAL_INFO_REQUEST,
    function prepare(payload: IUSerGeneralInfoUpdatePayload) {
        return {
            payload,
        }
    }
)

export const refreshTokenRequest = createAction(
    REFRESH_TOKEN_REQUEST,
    function prepare(payload: boolean) {
        return {
            payload,
        }
    }
)

export const refreshTokenOnceRequest = createAction(
    REFRESH_TOKEN_ONCE_REQUEST,
    function prepare(payload: boolean) {
        return {
            payload,
        }
    }
)

export type I_getUserDirectSharesReceivedRequest_req_payload =
    | 'car'
    | 'garage'
    | 'car_and_garage'

export const getUserDirectSharesReceivedRequest = createAction(
    GET_USER_DIRECT_SHARES_RECEIVED_REQUEST,
    function prepare(
        payload: I_getUserDirectSharesReceivedRequest_req_payload
    ) {
        return {
            payload,
        }
    }
)

export const populateOwnedSharesDataRequest = createAction(
    POPULATE_OWNED_SHARES_DATA_REQUEST
)

export const updateShareReceivedStatusRequest = createAction(
    UPDATE_SHARE_RECEIVED_STATUS_REQUEST,
    function prepare(payload: IUpdateShareReveivedStatusApi_arg) {
        return {
            payload,
        }
    }
)

export const deleteAccountRequest = createAction(
    DELETE_ACCOUNT_REQUEST,
    function prepare(payload: IAccDelItems) {
        return {
            payload,
        }
    }
)
export const getUserArchivedCarsRequest = createAction(
    GET_USER_ARCHIVED_CARS_REQUEST,
    function prepare(
        payload?:
            | 'ARCHIVED_ON_OLDEST'
            | 'ARCHIVED_ON_MOST_RECENT'
            | 'MANUFACTURE_DATE_ASCENDING'
            | 'MANUFACTURE_DATE_DESCENDING'
    ) {
        return {
            payload,
        }
    }
)

export const listUserHandoversRequest = createAction(
    LIST_USER_HANDOVERS_REQUEST,
    function prepare(
        payload?: ('INITIATED' | 'PROCESSING' | 'COMPLETED' | 'FAILED')[]
    ) {
        return {
            payload,
        }
    }
)
