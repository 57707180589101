import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import TutorialWrapperMobile from '../tutorialWrappers/TutorialWrapperMobile'
import CarActionMenuMobile from '../../../atoms/menu/carActionMenu/carActionMobile'

const TourStyled = styled((props: any) => <Tour {...props} />)`
    display: none;
    @media ${device.mobile_and_ipad} {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: ${(props) => (props.currentStep === 1 ? '70vw' : 'auto')};
    }
`

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 16px;
    justify-content: center;
    text-align: center;

    @media ${device.mobile} {
    }
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 14px;
    line-height: 1.9;

    @media ${device.mobile} {
    }
`

const BulletList = styled.ul`
    width: 100%;
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 14px;
    line-height: 1.9;
    padding-left: 25px;

    @media ${device.mobile} {
    }
`

// const InnerTextRow = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: flex-start;
// `

const Padding = styled.div`
    padding-top: 20px;
`
const TextPadding = styled.div`
    padding-top: 8px;
`

// const Bold = styled.span`
//     font-family: lato-bold;
//     padding-left: 5px;
//     padding-right: 5px;
// `

export type ReactourStepPosition =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | [number, number]

let bottomPositionStrongType: ReactourStepPosition = 'bottom'

const tutorialConfigs_Car = (close: any) => [
    {
        selector: '[data-tut="car-actions-tuto-mobile"]',
        content: ({ goTo }: any) => (
            //<Wrapper>
            <TutorialWrapperMobile
                skip={close}
                next={() => {
                    goTo(1)
                }}
            >
                <Title>Get Started</Title>
                <Padding />

                <Text>
                    The Car Menu button allows you to quickly fill in your car
                    profile, or perform actions such as a handover.
                </Text>

                <div style={{ paddingTop: '20px' }} />

                <div style={{ transform: 'scale(0.8)' }}>
                    <CarActionMenuMobile
                        isCarMenuOpened={false}
                        activeID={undefined}
                        carAtionMenuItemsData={{}}
                        setIsCarMenuOpened={(p: boolean) => {}}
                    />
                </div>
            </TutorialWrapperMobile>
        ),
    },

    {
        selector: '[data-tut="gallery-overview-car-tuto-mobile"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperMobile
                skip={close}
                prev={() => {
                    goTo(0)
                }}
                next={() => {
                    goTo(2)
                }}
            >
                <Title>
                    Image Gallery | Car Profile Image | Gallery Highlights
                </Title>

                <Padding />

                <Text>
                    You can add images of your car to your gallery from here.
                    Once uploaded, each individual image is editable. You could,
                    for example:
                </Text>

                <TextPadding />

                <BulletList>
                    <li>
                        Set it as the car's Profile Image (visible from your
                        garage).
                    </li>
                    <TextPadding />
                    <li>
                        Set it as one of the six Gallery Highlights (visible
                        from this gallery overview).
                    </li>
                </BulletList>
            </TutorialWrapperMobile>
        ),
        position: bottomPositionStrongType,
    },
    {
        selector: '[data-tut="key-moments-car-tuto-mobile"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperMobile
                skip={close}
                prev={() => {
                    goTo(1)
                }}
                next={close}
                nextBtnText="close"
            >
                <Title>Key Moments & Facts</Title>

                <Padding />

                <Text>
                    Share and rank a few interesting facts about your car's
                    history, specification or condition, and add any highlights
                    or special milestones during your ownership.
                </Text>

                <TextPadding />
                <TextPadding />

                <Text onClick={() => close()}>
                    <div style={{ color: '#5EC3CA' }}>Done</div>
                </Text>
            </TutorialWrapperMobile>
        ),
        position: bottomPositionStrongType,
    },
]

interface Props extends RouteComponentProps<any> {
    registerCarTutorial: any
}

const TutorialConfigsCarMobile: React.FunctionComponent<Props> = (props) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(true)

    const setCurrentStepCustom = (step: number) => {
        setCurrentStep(step)
    }

    let carid = props.match.params.carid

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        props.history.push(`/car/${carid}`)
    }

    useEffect(() => {
        props.registerCarTutorial()
    }, [])

    return (
        <>
            {window.innerWidth < 880 && (
                <>
                    <TourStyled
                        steps={tutorialConfigs_Car(setIsTourOpenCustom)}
                        isOpen={isTourOpen}
                        onRequestClose={() => undefined}
                        rounded={5}
                        accentColor="#5EC3CA"
                        getCurrentStep={(curr: number) => {
                            window.scrollTo(0, 0)
                            return setCurrentStepCustom(curr + 1)
                        }}
                        currentStep={currentStep}
                        disableInteraction={true}
                        showCloseButton={false}
                        scrollOffset={-20}
                    />
                </>
            )}
        </>
    )
}

export default withRouter(TutorialConfigsCarMobile)
