import { IUser } from 'myModels'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ITimelineItem } from 'timelineModels'
import insurance_skeleton_desktop from '../../../public/assets/placeholder_images/insurance_skeleton_desktop.svg'
import insurance_skeleton_desktop_dark from '../../../public/assets/placeholder_images/insurance_skeleton_desktop_dark.png'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Icon from '../../atoms/icons'
import { device } from '../../templates/displays/devices'
import { UI_insurance_entry_card_arr } from './carInsuranceSectionBodyDesktop'
import info_red from '../../../public/assets/icons/info_red.svg'
import posthog from 'posthog-js'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../redux/store'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    carid: string
    insurance_timeline_item: ITimelineItem | undefined | null
    hasExpired: boolean
    isWithin30Days: boolean
}

type IStyle = {
    $theme: ITheme
}

const EmptyBtnSkeletonOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

const Row0 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-direction: row;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 8px;
    min-width: 94px;
    width: 100%;
    @media ${device.large_desktop} {
    }
`
const CardWrapper = styled.div<IStyle>`
    display: flex;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
`

const RowWrapper = styled.div<IStyle>`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 40px;
    flex-direction: row;
    gap: 4px;
    border-radius: 8px;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
    overflow-x: scroll;
    position: relative;
    overflow-x: scroll;
    position: relative;
`
const Line = styled.div<IStyle>`
    width: 100%;
    height: 1px;
    background: ${(props) => colours[props.$theme].border_muted};
`
const Title = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};

    font-family: Lato;
    font-size: 16px;

    line-height: 24px;
    letter-spacing: 0.001px;

    text-overflow: ellipsis;
    max-width: 70%;
    white-space: nowrap;
    overflow-x: hidden;
`

const Answer = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
`
const Name = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const ChildText = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_default};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    padding-bottom: 16px;
`

const WarningIcon = styled.img`
    width: 24px;
    transform: translateY(5px);
`

const Warning = styled.div<IStyle>`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: ${(props) => colours[props.$theme].error_08};
    flex-direction: row;
    margin-top: 10px;
`

const WarningText = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

export const CarInsuranceSectionBodyMobile = (props: Props) => {
    let { insurance_timeline_item, hasExpired, isWithin30Days } = props

    let userLoggedIn: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let card_title = !userLoggedIn
        ? ''
        : `${userLoggedIn.display_name}'s insurance policy`

    let userCurrency =
        userLoggedIn?.preferences.data.units.data.default_currency
            .user_choice_id

    let history = useHistory()

    let normal_txt = `Insurance plays a critical role in safeguarding your vehicle. If
                you haven't added your current insurance to your History Files
                or initiated a policy with Custodian yet, it's time to act!`

    let warning_type = hasExpired
        ? 'expiry'
        : isWithin30Days
        ? 'renewal'
        : undefined

    const dispatch = useAppDispatch()

    let insuranceClick = () => {
        // window.scrollTo(0, 0)
        dispatch(
            insuranceActions.get_user_insurance_applications_request({
                withNavigation: true,
                withAllInfo: true,
            })
        )
    }

    let isInsuranceLoading = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    const { theme } = useThemes()

    return (
        <div style={{ width: '100%' }}>
            {!warning_type && (
                <ChildText $theme={theme}>{normal_txt}</ChildText>
            )}
            {warning_type && (
                <Warning $theme={theme}>
                    <WarningIcon src={info_red} />
                    <WarningText $theme={theme}>
                        {warning_type === 'expiry' ? (
                            <>
                                Your insurance has expired. <br />
                                Don't leave your vehicle unprotected. Get a
                                personalized quote tailored to your needs and
                                reinstate coverage today.
                            </>
                        ) : (
                            <>
                                Your insurance is due for renewal soon.
                                <br /> To ensure you continue enjoying
                                comprehensive coverage, get a personalized quote
                                tailored to your needs.
                            </>
                        )}
                    </WarningText>
                </Warning>
            )}
            {/* <a
                href={'https://www.custodian.club/features/insurance'}
                target="_blank"
                rel="noreferrer"
            >
                <ShowMoreBtn customTxt="Lean more" onClick={() => {}} />
            </a> */}

            <div style={{ paddingTop: '16px' }} />

            {!insurance_timeline_item && (
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                    }}
                >
                    <EmptyBtnSkeletonOverlay style={{ zIndex: 2 }}>
                        <ButtonAtom
                            width={'210px'}
                            theme={'lowercase-blue-background'}
                            fontSize={14}
                            onClick={() => {
                                posthog.capture(
                                    'get_custodian_insurance clicked from empty car insurance section'
                                )
                                insuranceClick()
                            }}
                            height={'40px'}
                        >
                            {isInsuranceLoading ? (
                                <LoaderElasticThreeDots />
                            ) : (
                                <div
                                    style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Get Custodian Insurance
                                </div>
                            )}
                        </ButtonAtom>

                        <div style={{ paddingTop: '16px' }} />

                        <ButtonAtom
                            width={'210px'}
                            theme={'outline-transparent-background'}
                            fontSize={14}
                            height={'40px'}
                            customBgColor="transparent"
                            onClick={() => {
                                posthog.capture(
                                    'create_insurance_entry clicked from empty car insurance section'
                                )
                                history.push(
                                    `/car/${props.carid}/history-file/create/insurance`
                                )
                            }}
                        >
                            <div
                                style={{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Add a History File Entry
                            </div>
                        </ButtonAtom>
                    </EmptyBtnSkeletonOverlay>

                    <img
                        style={{
                            width: '100%',
                            height: '176px',
                            objectFit: 'cover',
                        }}
                        src={
                            theme === 'light'
                                ? insurance_skeleton_desktop
                                : insurance_skeleton_desktop_dark
                        }
                    />
                </div>
            )}

            {insurance_timeline_item && (
                <CardWrapper $theme={theme}>
                    <Row0>
                        <Title $theme={theme}>{card_title}</Title>
                        <ButtonAtom
                            theme="naked-add-text"
                            onClick={() => {
                                posthog.capture(
                                    'insurance entry view click from car insurance section'
                                )
                                history.push(
                                    `/car/${props.carid}/history-file/entry?entryid=${insurance_timeline_item?.id}`
                                )
                            }}
                        >
                            <Icon icon={'blue_arrow_next'} height="14px" />
                        </ButtonAtom>
                    </Row0>

                    <Line $theme={theme} />

                    <RowWrapper $theme={theme}>
                        {UI_insurance_entry_card_arr.map((item) => {
                            let valuee =
                                (insurance_timeline_item &&
                                    insurance_timeline_item[item.id]) ??
                                undefined

                            if (
                                item.id ===
                                    'insurance_entry_policy_start_date' ||
                                item.id === 'insurance_entry_policy_end_date'
                            ) {
                                if (valuee) {
                                    let newVal = fromIsoStringToDDMMYYY(valuee)
                                    // console.log('newVal', newVal)
                                    valuee = newVal
                                }
                            }

                            return (
                                <Col key={`${item.id}_desktop`}>
                                    <Name $theme={theme}>{item.name}</Name>
                                    <Answer $theme={theme}>
                                        {item.id !== 'total_cost_amount_display'
                                            ? null
                                            : insurance_timeline_item &&
                                              insurance_timeline_item[
                                                  item.id
                                              ] &&
                                              userCurrency
                                            ? userCurrency === 'GBP'
                                                ? '£'
                                                : userCurrency === 'USD'
                                                ? '$'
                                                : userCurrency === 'EUR'
                                                ? '€'
                                                : '£'
                                            : '£'}
                                        {valuee ?? '-'}
                                    </Answer>
                                </Col>
                            )
                        })}
                    </RowWrapper>
                </CardWrapper>
            )}

            {insurance_timeline_item && hasExpired === true && (
                <>
                    <div style={{ paddingTop: '30px' }} />
                    <ButtonAtom
                        width={'100%'}
                        theme={'lowercase-blue-background'}
                        fontSize={14}
                        onClick={() => {
                            posthog.capture(
                                'get_custodian_insurance clicked from expired car insurance section'
                            )
                            insuranceClick()
                        }}
                        height={'48px'}
                    >
                        {isInsuranceLoading ? (
                            <LoaderElasticThreeDots />
                        ) : (
                            <div
                                style={{
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Get Custodian Insurance
                            </div>
                        )}
                    </ButtonAtom>

                    <div style={{ paddingTop: '16px' }} />

                    <ButtonAtom
                        width={'100%'}
                        theme={'outline-transparent-background'}
                        fontSize={14}
                        height={'48px'}
                        customBgColor="transparent"
                        onClick={() => {
                            posthog.capture(
                                'create_insurance_entry clicked from expired car insurance section'
                            )
                            history.push(
                                `/car/${props.carid}/history-file/create/insurance`
                            )
                        }}
                    >
                        <div
                            style={{
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                textTransform: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Add a History File Entry
                        </div>
                    </ButtonAtom>
                </>
            )}

            {insurance_timeline_item &&
                isWithin30Days === true &&
                !hasExpired && (
                    <>
                        <div style={{ paddingTop: '30px' }} />
                        <ButtonAtom
                            width={'100%'}
                            theme={'lowercase-blue-background'}
                            fontSize={14}
                            onClick={() => {
                                posthog.capture(
                                    'get_custodian_insurance clicked from renewable car insurance section'
                                )
                                insuranceClick()
                            }}
                            height={'48px'}
                        >
                            {isInsuranceLoading ? (
                                <LoaderElasticThreeDots />
                            ) : (
                                <div
                                    style={{
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Get Custodian Insurance
                                </div>
                            )}
                        </ButtonAtom>
                    </>
                )}
        </div>
    )
}
