import { ICar, IDropdownItem } from 'entityModels'
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import useThemes from '../../../../../../providers/theme/hooks'
import { getCarDataByIdRequest } from '../../../../../../redux/entities/cars/actions/loadingActions'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_OtherDriver,
    IInsuranceQuoteApplication_OtherDriverObj,
    insuranceActions,
    IOtherDriver_DriveList,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import {
    insurance_fields_car_ownership,
    frequency_insurance_other_driver_ownership,
} from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import Expander from '../../../../../atoms/expander/expander'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import { device } from '../../../../../templates/displays/devices'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import useWindowSize, {
    WindowSize,
} from '../../../../../templates/displays/windowSizeHook'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import { InsuranceFormQuestion } from '../../../formGenerators/renderElementMobile'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../../providers/theme/colours'

const DriverName = styled.div`
    font-family: 'Lato';
    font-style: normal;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    color: #666666;

    font-size: 14px;

    width: 100%;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

type Props = {
    car_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuredVehicleInsuranceWizardForm = (props: Props) => {
    let carid = props.car_id
    const history = useHistory()
    const dispatch = useAppDispatch()

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.submitted_data.vehicles[carid] &&
                state.insuranceQuoteApplication.submitted_data.vehicles[carid]
                    .ownership) ??
            {}
        )
    })

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let everyday_car_ids = applicationData?.everyday_car_ids

    let isEverydayCar = !everyday_car_ids
        ? false
        : everyday_car_ids && everyday_car_ids.indexOf(carid) !== -1
        ? true
        : false

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.draft.vehicles[carid] &&
                state.insuranceQuoteApplication.draft.vehicles[carid]
                    .ownership) ??
            {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[carid]
            ?.ownership_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_car_ownership({
                id: id,
                error: error,
                carid: carid,
            })
        )
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (let i = 0; i < insurance_fields_car_ownership.length; i++) {
            if (insurance_fields_car_ownership[i].is_required === true) {
                if (
                    data_draft[insurance_fields_car_ownership[i].id] ===
                    undefined
                ) {
                    setError(
                        insurance_fields_car_ownership[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }

            if (
                ((insurance_fields_car_ownership[i].id === 'is_written_off' &&
                    data_draft[insurance_fields_car_ownership[i].id] ===
                        true) ||
                    (insurance_fields_car_ownership[i].id ===
                        'is_written_off' &&
                        data_draft[insurance_fields_car_ownership[i].id]
                            ?.name === 'Yes')) &&
                data_draft['is_q_plated'] === undefined
            ) {
                setError('is_q_plated', 'Required field.')
                hasErr2 = true
            }

            if (
                ((insurance_fields_car_ownership[i].id === 'is_imported' &&
                    data_draft[insurance_fields_car_ownership[i].id] ===
                        true) ||
                    (insurance_fields_car_ownership[i].id === 'is_imported' &&
                        data_draft[insurance_fields_car_ownership[i].id]
                            ?.name === 'Yes')) &&
                data_draft['import_origin_country_code'] === undefined
            ) {
                setError('import_origin_country_code', 'Required field.')
                hasErr2 = true
            }

            if (
                insurance_fields_car_ownership[i].validation &&
                data_draft[insurance_fields_car_ownership[i].id] !== undefined
            ) {
                let is_valid_func = insurance_fields_car_ownership[i].validation
                let value = data_draft[insurance_fields_car_ownership[i].id]
                let error_txt = insurance_fields_car_ownership[i].error_txt

                if (is_valid_func && is_valid_func(value) !== true) {
                    setError(
                        insurance_fields_car_ownership[i].id,
                        error_txt ?? 'Invalid'
                    )
                    hasErr2 = true
                }
            }
        }

        return hasErr2
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let car: ICar | undefined = carsData[carid]

    useEffect(() => {
        if (
            (userLoggedIn && !car) ||
            (car && car.has_limited_garage_info === true)
        ) {
            dispatch(getCarDataByIdRequest(carid))
        }
    }, [car, userLoggedIn])

    let {
        setInitialCarOwnershipDropdowns,

        set_wizard_entity_id_to_skip,
        getInitialSectionWizardIds,
        getFirstIncompleteSubsection_InsuredVehicle,
    } = useSetInitialInsurance()

    useEffect(() => {
        set_wizard_entity_id_to_skip(carid)
        setInitialCarOwnershipDropdowns(carid)
    }, [userLoggedIn])

    const other_drivers_data: IInsuranceQuoteApplication_OtherDriverObj =
        useAppSelector((state) => {
            return state.insuranceQuoteApplication.draft.other_drivers
        })

    const onSelectUserFrequency = (
        userID: string,
        frequency: IDropdownItem
    ) => {
        if (other_drivers_data[userID]) {
            let finalCars = other_drivers_data[userID].drive_list?.map(
                (item) => {
                    let itemCopy = { ...item }
                    if (itemCopy.carid === carid) {
                        itemCopy.use_frequency = frequency
                    }
                    return itemCopy
                }
            )
            dispatch(
                insuranceActions.set_answer_other_driver_drive_list({
                    id: 'drive_list',
                    userid: userID,
                    answer: finalCars,
                })
            )
        }
    }

    const getOtherDriverCarData = (
        p: IInsuranceQuoteApplication_OtherDriver
    ): IOtherDriver_DriveList | undefined => {
        let foundItem: IOtherDriver_DriveList | undefined = undefined

        p.drive_list?.forEach((item) => {
            if (carid === item.carid) {
                foundItem = item
            }
        })

        return foundItem
    }

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')
    let entity = queryParams.get('entity')
    let entity_index = queryParams.get('entity_index')
    let entitiesObjArr = {} // TBD if there are entities here

    // let goNext = () => {
    //     history.push(
    //         `/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure`
    //     )
    // }

    const goNext = () => {
        // because last section
        let ids = getFirstIncompleteSubsection_InsuredVehicle()

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return history.replace({
                search: params2.toString(),
            })
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('vehicles_to_insure')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else {
                history.push(`/insurance/application/${applicationData?.id}`)
            }
        }
        return getToAny()
    }
    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carOwnership_request({
                    carid: carid,
                })
            )

            setTimeout(() => {
                goNext()
            }, 100)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { theme } = useThemes()

    let onAnswerChange = (id: string, answer: any) => {
        if (
            id === 'is_q_plated' &&
            errors &&
            errors['is_q_plated'] !== undefined
        ) {
            setError('is_q_plated', undefined)
        }

        dispatch(
            insuranceActions.set_answer_insurance_car_ownership({
                id: id,
                answer: answer,
                carid: carid,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'vehicles_to_insure',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'vehicles_to_insure',
            onSave: () => saveForm(),
            has_changes: false,
        })
    }, [])

    const FrequencyLocalComp = () => {
        return (
            <>
                <InsuranceFormQuestion $theme={theme}>
                    DRIVERS USE FREQUENCY
                </InsuranceFormQuestion>

                <div style={{ paddingTop: '12px' }} />

                <InsuranceFormQuestion $theme={theme}>
                    How frequently will you and assigned drivers be using or be
                    responsible for the vehicle?
                </InsuranceFormQuestion>

                <div style={{ paddingTop: '24px' }} />

                <DriverName>
                    {userLoggedIn?.given_name} {userLoggedIn?.family_name}
                </DriverName>

                <Expander height={data_draft['use_frequency'] ? 18 : 8} />
                <div
                    style={{ position: 'relative', zIndex: 11, width: '100%' }}
                >
                    <InsuranceQuoteFormGenerator
                        sectionID="vehicles_to_insure"
                        list={frequency_insurance_other_driver_ownership}
                        onAnswerChange={onAnswerChange}
                        data={data_draft}
                        errors={errors}
                        setError={setError}
                        customLineBottomPadding={'0px'}
                    />
                </div>

                <div style={{ paddingTop: '8px' }} />

                {other_drivers_data &&
                    Object.keys(other_drivers_data).map(
                        (driver_id: string, i) => {
                            let driver_obj: IInsuranceQuoteApplication_OtherDriver =
                                other_drivers_data[driver_id]

                            let carDriverObj:
                                | IOtherDriver_DriveList
                                | undefined = getOtherDriverCarData(driver_obj)

                            if (!carDriverObj) {
                                return null
                            } else
                                return (
                                    <div
                                        style={{
                                            position: 'relative',
                                            zIndex: 10 - i,
                                            width: '100%',
                                        }}
                                        key={`${driver_id}_${i}`}
                                    >
                                        <DriverName>
                                            <span>
                                                {`${
                                                    driver_obj.basic_details &&
                                                    driver_obj.basic_details[
                                                        'given_name'
                                                    ]
                                                        ? driver_obj
                                                              .basic_details[
                                                              'given_name'
                                                          ]
                                                        : ''
                                                }`}
                                            </span>
                                            <span>
                                                {' '}
                                                {`${
                                                    driver_obj.basic_details &&
                                                    driver_obj.basic_details[
                                                        'family_name'
                                                    ]
                                                        ? driver_obj
                                                              .basic_details[
                                                              'family_name'
                                                          ]
                                                        : ''
                                                }`}
                                            </span>
                                        </DriverName>

                                        <Expander
                                            height={
                                                carDriverObj['use_frequency']
                                                    ? 18
                                                    : 8
                                            }
                                        />
                                        <InsuranceQuoteFormGenerator
                                            sectionID="vehicles_to_insure"
                                            list={
                                                frequency_insurance_other_driver_ownership
                                            }
                                            onAnswerChange={(
                                                id: string,
                                                frequencyAnswer: any
                                            ) => {
                                                // check in case
                                                if (id === 'use_frequency') {
                                                    onSelectUserFrequency(
                                                        driver_id,
                                                        frequencyAnswer
                                                    )
                                                }
                                            }}
                                            data={{
                                                use_frequency:
                                                    carDriverObj?.use_frequency,
                                            }}
                                            errors={{}}
                                            setError={setError}
                                            mobileSearchPickerSectionURL={`/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure&subsection_id=ownership&car_id=${carid}`}
                                        />

                                        <div
                                            style={{
                                                paddingTop: '8px',
                                            }}
                                        />
                                    </div>
                                )
                        }
                    )}
            </>
        )
    }

    const goPrevious = () => {
        history.push(
            `/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure&subsection_id=mileage_parking&car_id=${carid}`
        )
    }

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        sectionID="vehicles_to_insure"
                        list={insurance_fields_car_ownership}
                        onAnswerChange={onAnswerChange}
                        data={data_draft}
                        errors={errors}
                        setError={setError}
                    />

                    {/* {isEverydayCar === true && (
                            <InsuranceQuoteFormGenerator
                                sectionID="vehicles_to_insure"
                                list={insured_vehicle_marked_as_everyday_ncd}
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                mobileSearchPickerSectionURL={`/insurance/application/${id}/car/${carid}/ownership`}
                            />
                        )} */}

                    {isEverydayCar === true && (
                        <div style={{ paddingTop: '16px' }} />
                    )}

                    {!isMobile && <FrequencyLocalComp />}
                </div>
                <div style={{ paddingTop: '200px' }} />

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    sectionId="vehicles_to_insure"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="vehicles_to_insure"
                            urlBack={`/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure&subsection_id=ownership&car_id=${carid}`}
                            draft={data_draft}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                if (entity !== null && entity_index !== null) {
                                    let roota: IAnyObject[] =
                                        entitiesObjArr &&
                                        // @ts-ignore
                                        entitiesObjArr[`${entity}`]
                                            ? // @ts-ignore
                                              [...entitiesObjArr[`${entity}`]]
                                            : []

                                    roota[+entity_index] = {
                                        ...roota[+entity_index],
                                        [`${field_id}`]: value,
                                    }

                                    dispatch(
                                        insuranceActions.set_entity_arr_car({
                                            arr: [...roota],
                                            fieldID: entity,
                                            carid: carid,
                                        })
                                    )
                                } else
                                    dispatch(
                                        insuranceActions.set_answer_insurance_car_ownership(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                carid: carid,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '88vw',
                                paddingBottom: 120,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                sectionID="vehicles_to_insure"
                                list={insurance_fields_car_ownership}
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                mobileSearchPickerSectionURL={`/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure&subsection_id=ownership&car_id=${carid}`}
                            />

                            {isEverydayCar === true && (
                                <div style={{ paddingTop: '16px' }} />
                            )}

                            {isMobile && <FrequencyLocalComp />}

                            <div style={{ paddingTop: '200px' }} />
                        </div>
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() => goPrevious()}
                            sectionId="vehicles_to_insure"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuredVehicleInsuranceWizardForm
