type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const TripIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.0167 13.1333C12.0167 13.7501 12.5166 14.25 13.1333 14.25C13.75 14.25 14.25 13.7501 14.25 13.1333C14.25 12.5166 13.75 12.0167 13.1333 12.0167C12.5166 12.0167 12.0167 12.5166 12.0167 13.1333ZM13.1333 15.75C11.6882 15.75 10.5167 14.5785 10.5167 13.1333C10.5167 11.6882 11.6882 10.5167 13.1333 10.5167C14.5785 10.5167 15.75 11.6882 15.75 13.1333C15.75 14.5785 14.5785 15.75 13.1333 15.75Z"
                fill={color ?? 'white'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.75 2.86667C1.75 3.48339 2.24995 3.98334 2.86667 3.98334C3.48338 3.98334 3.98333 3.48339 3.98333 2.86667C3.98333 2.24995 3.48338 1.75 2.86667 1.75C2.24995 1.75 1.75 2.24995 1.75 2.86667ZM2.86667 5.48334C1.42152 5.48334 0.25 4.31182 0.25 2.86667C0.25 1.42153 1.42152 0.250004 2.86667 0.250004C4.31181 0.250004 5.48333 1.42153 5.48333 2.86667C5.48333 4.31182 4.31181 5.48334 2.86667 5.48334Z"
                fill={color ?? 'white'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.75 10.5667C1.75 11.0485 1.9414 11.5106 2.28209 11.8513C2.62278 12.192 3.08486 12.3834 3.56667 12.3834L9.00782 12.3834L9.00782 13.8834L3.56667 13.8834C2.68703 13.8834 1.84342 13.5339 1.22143 12.9119C0.599434 12.2899 0.25 11.4463 0.25 10.5667C0.25 9.68706 0.599433 8.84345 1.22143 8.22146C1.84342 7.59946 2.68703 7.25003 3.56667 7.25003L12.4333 7.25003C12.9151 7.25003 13.3772 7.05863 13.7179 6.71794C14.0586 6.37725 14.25 5.91517 14.25 5.43336C14.25 4.95155 14.0586 4.48947 13.7179 4.14878C13.3772 3.80809 12.9151 3.61669 12.4333 3.61669L6.99672 3.6167L6.99672 2.1167L12.4333 2.11669C13.313 2.11669 14.1566 2.46613 14.7786 3.08812C15.4006 3.71012 15.75 4.55373 15.75 5.43336C15.75 6.313 15.4006 7.1566 14.7786 7.7786C14.1566 8.4006 13.313 8.75003 12.4333 8.75003L3.56667 8.75003C3.08486 8.75003 2.62278 8.94143 2.28209 9.28212C1.9414 9.62281 1.75 10.0849 1.75 10.5667Z"
                fill={color ?? 'white'}
            />
        </svg>
    )
}

export default TripIcon
