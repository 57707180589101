import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ITimelineItem } from 'timelineModels'
// import FatTimelineItemDesktop from '../../molecules/fatTimelineItem/fatTimelineItemDesktop'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import TimelineItemDesktop from '../../molecules/timelineItemDesktop/timelineItemDesktop'
import { ICar, IExternalCar } from 'entityModels'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import OverviewHistoryFileSkeleton from '../../atoms/car/profile/overviewHistoryFileSkeleton'
import BubbleCardWithHorizontalLine from '../../atoms/bubbleCards/bubbleCardWithHorizontalLine'
import ChevronDown from '../../atoms/icons/components/chevronDown'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import colours, { ITheme } from '../../../providers/theme/colours'

const Wrapper = styled.section`
    width: 100%;
    padding-bottom: 24px;
`

const TimelineItemsSpacing = styled.div<IStyle>`
    min-width: 24px;
    max-width: 24px;
    position: relative;
    border: none;
    background-color: none;
    margin-top: 0px;

    :before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        top: 0px;
        left: 0;
        background-color: ${(props) => colours[props.$theme].border_muted};
    }

    /* @media ${device.ipad} {
        min-width: 16px;
        max-width: 16px;
    } */
`

const GridContainer = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
    }
`

const ViewAll = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].primary};
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 18px; /* 150% */
    letter-spacing: 0.001px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding-top: 24px;
    justify-content: flex-end;
`

const ItemWrapper = styled.div<IStyle>`
    display: flex;
    flex: 1;
    min-width: 0px;
`

export interface IStyle {
    $theme: ITheme
    numberOfEntriesPerPage?: number
    width?: string
    height?: string
}

interface Props extends RouteComponentProps<any> {
    entries_array: ITimelineItem[] | undefined | null
    car_entries_count: number
    attachmentsObj?: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    onClick?: any
    history: any
    carid: string
    onPlaceholderClick: any
    userCurrency: string | undefined
    readOnlyMode?: boolean
    sharedWithYouCar?: IExternalCar
    isOwnedCar?: ICar
}

type State = {
    showBoxInfo: boolean
    numberOfEntriesPerPage: number
}

class TimelineOverviewManagerDesktop extends React.Component<Props, State> {
    state = {
        showBoxInfo: false,
        numberOfEntriesPerPage: 2,
    }

    _isMounted = false

    handleResize = () =>
        window.innerWidth > 1500
            ? this._isMounted && this.setState({ numberOfEntriesPerPage: 3 })
            : this._isMounted && this.setState({ numberOfEntriesPerPage: 2 })

    componentDidMount() {
        this._isMounted = true

        window.innerWidth > 1500
            ? this._isMounted && this.setState({ numberOfEntriesPerPage: 3 })
            : this._isMounted && this.setState({ numberOfEntriesPerPage: 2 })

        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate(prevProps: Props) {}

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        this._isMounted = false
    }

    setTrueBoxInfoToggle = () => this.setState({ showBoxInfo: true })

    setFalseBoxInfoToggle = () => this.setState({ showBoxInfo: false })

    checkIfInfoIsRestricted = () => {
        let sharedWithYouCar = this.props.sharedWithYouCar
        if (
            this.props.readOnlyMode &&
            sharedWithYouCar &&
            sharedWithYouCar.policy_statements
        ) {
            if (!sharedWithYouCar.policy_statements.includes('history file')) {
                return true
            }
        }
        return false
    }

    render() {
        const {
            entries_array,
            carid,
            attachmentsObj,
            onPlaceholderClick,
            car_entries_count,
            userCurrency,
            readOnlyMode,
            // costsObj,
        } = this.props

        const isEmpty =
            !entries_array || entries_array.length === 0 ? true : false

        let entriesN = window && window.innerWidth < 1400 ? [1, 2] : [1, 2, 3] // if window less size, have less index
        let emptyN = window && window.innerWidth < 1400 ? [1, 2] : [1, 2, 3]

        let linkPrefix =
            this.props.match.url && this.props.match.url.includes('overview')
                ? this.props.match.url.replace('/overview', '')
                : this.props.match.url

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper>
                        {/* general case */}

                        {isEmpty !== true ? (
                            <GridContainer>
                                {/* show add card when less entries than the screen type supports ( 2 for small and 3 for wide) */}

                                {entriesN.map(
                                    (indexd: number, index: number) => {
                                        let entry:
                                            | ITimelineItem
                                            | null
                                            | undefined =
                                            entries_array &&
                                            entries_array[index]
                                        if (entry) {
                                            return (
                                                <div
                                                    style={{
                                                        maxWidth: '100%',
                                                        minWidth: '100%',
                                                        display: 'flex',
                                                    }}
                                                    key={`timeline_entry_${index}_desktop`}
                                                >
                                                    <ItemWrapper $theme={theme}>
                                                        <TimelineItemDesktop
                                                            isFirst={
                                                                index === 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            isLast={
                                                                index ===
                                                                entriesN.length -
                                                                    1
                                                                    ? true
                                                                    : false
                                                            }
                                                            item={entry}
                                                            attachmentsObj={
                                                                attachmentsObj
                                                            }
                                                            // costsObj={costsObj}
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    `${linkPrefix}/history-file/entry?entryid=${entry?.id}`
                                                                )
                                                            }
                                                            isCarOverview
                                                            userCurrency={
                                                                userCurrency
                                                            }
                                                        />
                                                        {index <
                                                        entriesN.length - 1 ? (
                                                            <TimelineItemsSpacing
                                                                $theme={theme}
                                                            />
                                                        ) : null}
                                                    </ItemWrapper>
                                                </div>
                                            )
                                        } else
                                            return (
                                                <div
                                                    style={{
                                                        maxWidth: '100%',
                                                        minWidth: '100%',
                                                        display: 'flex',
                                                    }}
                                                    key={`timeline_skeleton_${index}_desktop`}
                                                >
                                                    <BubbleCardWithHorizontalLine
                                                        isFirst={
                                                            index === 0
                                                                ? true
                                                                : false
                                                        }
                                                        isLast={
                                                            index ===
                                                            entriesN.length - 1
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={
                                                            readOnlyMode
                                                                ? undefined
                                                                : () => {}
                                                        }
                                                    >
                                                        <OverviewHistoryFileSkeleton
                                                            index={index}
                                                            onClick={
                                                                onPlaceholderClick
                                                            }
                                                            reaonlyMode={
                                                                readOnlyMode
                                                            }
                                                        />
                                                    </BubbleCardWithHorizontalLine>
                                                    {index <
                                                    entriesN.length - 1 ? (
                                                        <TimelineItemsSpacing
                                                            $theme={theme}
                                                        />
                                                    ) : null}
                                                </div>
                                            )
                                    }
                                )}
                            </GridContainer>
                        ) : null}

                        {/* edge case - no entries */}

                        {isEmpty === true && this.props.readOnlyMode ? (
                            <NoDataRectangle
                                text="no history file entries"
                                isInfoRestricted={this.checkIfInfoIsRestricted()}
                            />
                        ) : null}

                        <GridContainer>
                            {isEmpty === true && !this.props.readOnlyMode
                                ? emptyN.map(
                                      (indexd: number, index: number) => {
                                          return (
                                              <div
                                                  style={{
                                                      maxWidth: '100%',
                                                      minWidth: '100%',
                                                      display: 'flex',
                                                  }}
                                                  key={`timeline_skeleton_${index}_desktop`}
                                              >
                                                  <BubbleCardWithHorizontalLine
                                                      isFirst={
                                                          index === 0
                                                              ? true
                                                              : false
                                                      }
                                                      isLast={
                                                          index ===
                                                          emptyN.length - 1
                                                              ? true
                                                              : false
                                                      }
                                                      onClick={
                                                          readOnlyMode
                                                              ? undefined
                                                              : () => {}
                                                      }
                                                  >
                                                      <OverviewHistoryFileSkeleton
                                                          index={index}
                                                          onClick={
                                                              onPlaceholderClick
                                                          }
                                                          reaonlyMode={
                                                              readOnlyMode
                                                          }
                                                      />
                                                  </BubbleCardWithHorizontalLine>
                                                  {index < emptyN.length - 1 ? (
                                                      <TimelineItemsSpacing
                                                          $theme={theme}
                                                      />
                                                  ) : null}
                                              </div>
                                          )
                                      }
                                  )
                                : null}
                        </GridContainer>

                        {/* show 'View all' if more entries than screen supports */}

                        {car_entries_count &&
                        car_entries_count > entriesN.length ? (
                            <ViewAll
                                $theme={theme}
                                role="button"
                                onClick={
                                    readOnlyMode
                                        ? onPlaceholderClick &&
                                          onPlaceholderClick
                                        : () =>
                                              this.props.history.push(
                                                  `/car/${carid}/history-file`
                                              )
                                }
                            >
                                {`${car_entries_count - entriesN.length} more`}{' '}
                                <div style={{ transform: 'rotate(-90deg)' }}>
                                    <ChevronDown
                                        color={colours[theme].primary}
                                    />
                                </div>
                            </ViewAll>
                        ) : null}
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(TimelineOverviewManagerDesktop)
