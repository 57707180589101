import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { IOnboardingList } from 'myInterfaces'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    max-width: 700px;
    @media ${device.beyond_ipad_mobile} {
        max-width: none;
        align-self: normal;
    }
`

const RowStyle = styled.div`
    display: flex;
    justify-content: center;
    /* align-items: center; */
    gap: 24px;
    margin-bottom: 24px;
    cursor: pointer;
    border-radius: 8px;

    @media ${device.beyond_ipad_mobile} {
        margin-bottom: 0px;
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    :hover {
        @media ${device.beyond_ipad_mobile} {
            background-color: var(--background_primary_soft, #f2fafb);
        }
    }
`

const RowBody = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
`

const RowIconWrapper = styled.div`
    width: 64px;
    height: 64px;
    max-height: 64px;
    max-width: 64px;
    min-width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--base-overlays-teal-alpha-200, rgba(94, 195, 202, 0.16));
`

const RowIcon = styled.img``

const Title = styled.h1`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #121212);

    /* Mobile/Text/T200 Strong */
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const Text = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-default, #616161);
    font-family: Lato;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
`

interface IProps {
    list: IOnboardingList[]
    activeIndex?: number
    setActiveIndex?: (i: number) => void
}

const OnboardingLastStepContent: React.FunctionComponent<IProps> = (props) => {
    let { list, activeIndex, setActiveIndex } = props

    return (
        <Wrapper>
            {list.map((item, i) => (
                <RowStyle
                    key={`onboarding_step_4_${i}`}
                    onClick={() => {
                        setActiveIndex && setActiveIndex(i + 1)
                    }}
                    style={
                        activeIndex && activeIndex === i + 1
                            ? { backgroundColor: 'rgba(94, 195, 202, 0.08)' }
                            : {}
                    }
                >
                    <RowIconWrapper>
                        <RowIcon src={item.icon_src} />
                    </RowIconWrapper>

                    <RowBody>
                        <Title>{item.title}</Title>
                        <Text>{item.text}</Text>
                    </RowBody>
                </RowStyle>
            ))}
        </Wrapper>
    )
}

export default OnboardingLastStepContent
