import {
    OnboardingText,
    PaddingUnit,
} from '../../../templates/onboarding/onboardingFormLayoutMobile'
import OnboardingImageUploadSubForm from './onboardingImageUploadSubForm'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import AnimatedTextareaShort from '../../../atoms/textarea/animatedTextareaShort'
import styled from 'styled-components'
import { submitCarGalleryImagesFilestorageRequest } from '../../../../redux/filestorage/actions/dispatched/loadingActions'
import { IUpdateCarGeneralPayloadReq } from 'entityModels'
import { writeCarDataGeneralRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import OnboardingFormTemplateDesktop from '../../../templates/onboarding/desktop/onboardingFormTemplateDesktop'
import { debounce } from 'lodash'
import useWindowSize from '../../../templates/displays/windowSizeHook'

const CarBioTitle = styled.h3`
    margin: 0;
    padding: 0;
    color: var(--text-darker, #616161);
    width: 100%;
    /* Desktop/Heading/H400 */
    font-family: Lato-bold;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
    transition: all 200ms;
`

type Props = {
    next: () => void
    carid: string
    setPreviewData: (p: {
        bio: string | undefined
        coverImage: File | undefined
    }) => void
    formText: string
    formMutedText: string
    carBioPlaceholder: string
}

const OnboardingGalleryImageAndBioDesktop = (props: Props) => {
    let {
        carid,
        next,
        setPreviewData,
        formText,

        carBioPlaceholder,
    } = props

    const dispatch = useAppDispatch()

    const gallerypresignedurls = useAppSelector(
        (state) => state.fileStorage.gallerypresignedurls
    )

    let storageLoading = useAppSelector((state) => state.fileStorage.loading)

    const [formData, setFormData] = useState<{
        bio: string | undefined
        coverImage: File | undefined
    }>({ bio: undefined, coverImage: undefined })

    const onSubmit = () => {
        if (formData.coverImage && gallerypresignedurls) {
            let imgFile: File[] = []
            imgFile[0] = formData.coverImage
            let imgPayload = {
                carid: carid,
                gallerypresignedurls: gallerypresignedurls,
                files: imgFile,
                fields: {
                    caption: undefined,
                    credit: undefined,
                    location: undefined,
                },
                isFromQuickActions: true,
                showNoProgress: true,
            }

            dispatch(submitCarGalleryImagesFilestorageRequest(imgPayload))
        }
        if (formData.bio) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: carid,
                isOnboarding: true,
                dataListForm: [
                    {
                        id: 'short_description',
                        text: 'Short description',
                        answer: formData.bio,
                    },
                ],
            }
            dispatch(writeCarDataGeneralRequest(payload))
        }
        next()
    }

    const update = debounce(
        (e) =>
            setPreviewData({
                bio: e.target.value,
                coverImage: formData.coverImage,
            }),
        1000
    )

    const windowHeight = useWindowSize().height

    return (
        <OnboardingFormTemplateDesktop
            skip={next}
            submit={() => {
                storageLoading === false && onSubmit()
            }}
            isBtnLoading={storageLoading ? true : false}
        >
            <PaddingUnit $times={2} />
            <OnboardingText>{formText}</OnboardingText>

            <PaddingUnit $times={4} />
            <CarBioTitle>Car bio</CarBioTitle>
            <PaddingUnit $times={2} />
            <AnimatedTextareaShort
                name={''}
                value={formData.bio ? `${formData.bio}` : ''}
                placeholder={carBioPlaceholder}
                handleChange={(e: any) => {
                    setFormData((prevState) => {
                        return {
                            ...prevState,
                            bio: e.target.value,
                        }
                    })
                    update(e)
                }}
                furtherStyle={{
                    fontSize: 16,
                    fontFamily: 'Lato',
                    lineHeight: '24px',
                    color: 'var(--text-default, #666)',
                }}
                height={windowHeight && windowHeight > 100 ? '160px' : '130px'}
                maxLength={256}
            />
            <p
                style={{
                    width: '100%',
                    textAlign: 'right',
                    fontFamily: 'Lato',
                    fontSize: 12,
                    paddingRight: 16,
                    paddingTop: 4,
                }}
            >{`${formData.bio ? formData.bio.length : 0} / 256`}</p>

            <PaddingUnit $times={2} />
            <OnboardingImageUploadSubForm
                carid={carid}
                imageToUpload={formData.coverImage}
                setImageToUpload={(file: File | undefined) => {
                    setFormData((prevState) => {
                        return {
                            ...prevState,
                            coverImage: file,
                        }
                    })

                    setPreviewData({
                        bio: formData.bio,
                        coverImage: file,
                    })
                }}
                isDesktop
            />
        </OnboardingFormTemplateDesktop>
    )
}

export default OnboardingGalleryImageAndBioDesktop
