import { IDropdownItem } from 'entityModels'

export const enumToNameFunc = (input: string): string => {
    // Split the input string by underscores and create an array of words

    const words = input.split('_')

    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )

    // Join the capitalized words with spaces to form the final transformed string
    const transformedString = capitalizedWords.join(' ')

    return transformedString
}

export const customEnumToNameFunc = (
    input: string,
    checkAgainstArray: IDropdownItem[]
): string => {
    for (let item of checkAgainstArray) {
        if (item.uid === input) {
            return item.name
        }
    }
    return ''
}
