import { useEffect } from 'react'
import { useHistory } from 'react-router'

export default function RemoveMobileSearchAnchor() {
    let params = new URLSearchParams(location.search)
    let anchor_params = params.get('anchor')
    const history = useHistory()

    const removeAnchor = () => {
        if (anchor_params) {
            let currURL = new URL(window.location.href)
            const params2 = new URLSearchParams(currURL.search)
            params2.delete('anchor')
            history.replace({ search: params2.toString() })
        }
    }

    useEffect(() => {
        if (anchor_params && anchor_params.includes(`_mobile`)) {
            setTimeout(() => {
                removeAnchor()
            }, 300)
        }
    }, [anchor_params])
    return <div style={{ display: 'none' }} />
}
