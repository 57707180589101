import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser, IUSerGeneralInfoUpdatePayload } from 'myModels'
// import Loader from "../atoms/loader/loader";
import Faded from '../../templates/animated/faded'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import SectionHeader from '../../atoms/header/section/sectionHeader'

import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import styled from 'styled-components'
import { LightTitle } from '../../atoms/typography'
import Icon from '../../atoms/icons'
import { device } from '../../templates/displays/devices'
import ListManagerDesktop from '../../molecules/list/ListManagerDesktop'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import { editFormsActions } from '../../../redux/editForms/reducer'
import AccountExternalInfoFormDesktop from '../../organisms/editForms/account/accountExternalInfoForm/accountExternalInfoFormDesktop'
import AccountPrivateInfoManagerFormDesktop from '../../organisms/editForms/account/accountPrivateInfoForm/manager/accountPrivateInfoManagerFormDesktop'
import AccountAddressFormManagerDesktop from '../../organisms/editForms/account/accountAddressForm/accountAddressFormManagerDesktop'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import UserAccountSquare from '../../atoms/user/userAccountSquare'
import ListManagerMobile from '../../molecules/list/listManagerMobile'
import ListItemMobile from '../../atoms/list/listItemMobile'
import AccountExternalInfoFormMobile from '../../organisms/editForms/account/accountExternalInfoForm/accountExternalInfoFormMobile'
import AccountPrivateInfoManagerMobileForm from '../../organisms/editForms/account/accountPrivateInfoForm/manager/accountPrivateInfoManagerMobileForm'
import AccountAddressFormManagerMobile from '../../organisms/editForms/account/accountAddressForm/accountAddressFormManagerMobile'
import WrapperUserProfileImageDesktop from '../../organisms/editForms/imageFormWrapper/wrapperUserProfileImageDesktop'
import WrapperUserProfileImageMobile from '../../organisms/editForms/imageFormWrapper/wrapperUserProfileImageMobile'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { IAccDelItems, IUserAddressApi } from 'IapiDataPayload'
import { message } from 'antd'
import '../../organisms/dropzone/antd.css'
import * as ReactScroll from 'react-scroll'
import {
    addAddressRequest,
    deleteAccountRequest,
    getCurrentUserDataRequest,
    logoutRequest,
    resetPasswordRequest,
    updateUserGeneralInfoRequest,
} from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { ICarsObject, IDropdownItem, IGarageState, IList } from 'entityModels'
import AccountDeletionManagerDesktop from '../../organisms/accountDeletionManager/accountDeletionManagerDesktop'
import AccountDeletionManagerMobile from '../../organisms/accountDeletionManager/accountDeletionManagerMobile'
import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
import { getGarageByUserIdRequest } from '../../../redux/entities/garages/actions/loadingActions'
import FadedSlower from '../../templates/animated/FadedSlower'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import UserSquareProfileImage from '../../atoms/user/userSquareProfileImage'
import { IInsuranceDropdownItem } from '../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'

const FirstSectionContentWrapDesktop = styled.div`
    padding-top: 20px;
    padding-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    padding-right: 15vw;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2px;
    padding-bottom: 2px;
    line-height: 1.7;
`

const RowText = styled.div`
    font-family: Lato-Italic;
    padding-left: 8px;
    font-size: 15px;
    color: var(--text-default, #666666);

    @media ${device.smallest_laptops} {
        font-size: 15px;
    }
`

const Text = styled.div`
    font-family: Lato !important;
    font-size: 16px;
    text-align: left;
    width: 100%;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
    line-height: 25px;
    white-space: pre-line;

    cursor: pointer;

    @media ${device.smallest_laptops} {
        font-size: 16px;
    }
`

const CenterDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    position: relative;
    z-index: 0;
`

const CenteredDiv = styled.div`
    padding-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
`

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    // TOGGLE
    toggleAccountExternalInfoEditForm: (item?: any) =>
        editFormsActions.toggleAccountExternalInfoEditForm(item),
    toggleAccountPrivateInfoEditForm: (item: any) =>
        editFormsActions.toggleAccountPrivateInfoEditForm(item),
    // UPDATE
    updateUserGeneralInfoRequest: (data: IUSerGeneralInfoUpdatePayload) =>
        updateUserGeneralInfoRequest(data),
    toggleAccounPrivateAddressEditForm: () =>
        editFormsActions.toggleAccounPrivateAddressEditForm(),
    toggleUserProfileImageForm: () =>
        editFormsActions.toggleUserProfileImageForm(),
    // LOGOUT
    logout: () => logoutRequest(),
    setCountriesCodeDropdownData: () =>
        dropDownActions.setCountriesCodeDropdownDataRequest(),
    addAddressRequest: (address: IUserAddressApi) =>
        addAddressRequest({ data: address }),
    request_reset_password_func: (email: string) => resetPasswordRequest(email),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    deleteAccountRequest: (payload: IAccDelItems) =>
        deleteAccountRequest(payload),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        username: state.user.userLoggedIn && state.user.userLoggedIn.given_name,
        email: state.user.userLoggedIn && state.user.userLoggedIn.email,
        isAccountExternalInfoFormOpened:
            state.editForms.formsData.accountExternalInfoForm.isOpen,
        isAccountPrivateInfoFormOpened:
            state.editForms.formsData.accountPrivateInfoForm.isOpen,
        currentPrivateItemEdited:
            state.editForms.formsData.accountPrivateInfoForm.item,
        currentExternalItemEdited:
            state.editForms.formsData.accountExternalInfoForm.item,
        isAccountPrivateAddressInfoOpened:
            state.editForms.formsData.userAccountAddressInformationForm.isOpen,
        isUSerProfileImageFormOpened:
            state.editForms.formsData.userProfileImageForm.isOpen,
        countriesdropdownlist: state.localdata.dropdownData.countriesCode,
        userError: state.user.error,
        carsData: state.entities.carsData.cars,
        garageData: state.entities.garagesData,
        garage_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.owns_garage &&
            state.user.userLoggedIn.owns_garage.uid,
        usaAdminCodes: state.localdata.dropdownData.usaStatesCodes,
    }
}

type State = {
    hasResetPasswordBeenClicked: boolean
    accountDeletionWizard: {
        isOpen: boolean
        step: number
        answers: IAccDelItems
    }
    showJourneyCard: boolean
}

interface Props extends RouteComponentProps<any> {
    setInitialLandingUrl: (str: string) => void
    userLoggedIn: null | IUser
    logout: () => void
    getUserDataRequest: () => void
    userid: string | null
    username: string | null | undefined
    email: string | null | undefined
    isAccountExternalInfoFormOpened: boolean
    toggleAccountExternalInfoEditForm: (item?: any) => void
    toggleAccountPrivateInfoEditForm: (item: any) => void
    toggleAccounPrivateAddressEditForm: () => void
    isAccountPrivateInfoFormOpened: boolean
    currentPrivateItemEdited: IList | undefined
    updateUserGeneralInfoRequest: (data: IUSerGeneralInfoUpdatePayload) => void
    isAccountPrivateAddressInfoOpened: boolean
    currentExternalItemEdited: IList | undefined
    toggleUserProfileImageForm: () => void
    isUSerProfileImageFormOpened: boolean
    setCountriesCodeDropdownData: () => void
    countriesdropdownlist: IDropdownItem[] | null
    addAddressRequest: (address: IUserAddressApi) => void
    userError: any
    request_reset_password_func: (email: string) => void
    deleteAccountRequest: (payload: IAccDelItems) => void
    carsData: ICarsObject
    garageData: IGarageState
    getGarageByUserIdRequest: () => void
    garage_id: string | null
    usaAdminCodes: IInsuranceDropdownItem[] | null

    // redeemReferral: (recipientUserid: string, code: string) => void;
}

class AccountPage extends React.Component<Props, State> {
    state = {
        hasResetPasswordBeenClicked: false,
        accountDeletionWizard: {
            isOpen: false,
            step: 0,
            answers: {
                deletion_agreement: false,
                reason: '',
                custom_reason_detail: '',
                rating: 0,
                feedback: '',
                further_feedback: undefined,
                confirmation: false,
                userEmail: '',
            },
        },
        showJourneyCard: true,
    }

    componentDidMount() {
        this.props.setInitialLandingUrl('/account')

        // const userId = userIDFromLocalStorage

        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        if (userLoggedIn) {
            this.props.setCountriesCodeDropdownData()
        }

        this.props.isAccountExternalInfoFormOpened === true &&
            this.props.toggleAccountExternalInfoEditForm()

        // this.props.isAccountPrivateInfoFormOpened === true &&
        //     this.props.toggleAccountPrivateInfoEditForm(undefined)

        // this.props.isAccountPrivateAddressInfoOpened === true &&
        //     this.props.toggleAccountPrivateInfoEditForm({ id: 'address' })

        this.props.isUSerProfileImageFormOpened === true &&
            this.props.toggleUserProfileImageForm()

        if (!this.props.garage_id || !this.props.garageData) {
            this.props.getGarageByUserIdRequest()
        }
    }

    errorWarning = () => {
        message.error({
            content: 'Invalid',
            duration: 2,
        })
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userError !== this.props.userError) {
            if (this.props.userError) {
                return this.errorWarning()
            }
        }

        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn) {
                this.props.setCountriesCodeDropdownData()
            }
        }
    }

    componentWillUnmount(): void {
        this.props.isAccountPrivateInfoFormOpened === true &&
            this.props.toggleAccountPrivateInfoEditForm(undefined)
        this.props.isAccountPrivateAddressInfoOpened === true &&
            this.props.toggleAccounPrivateAddressEditForm()
    }

    setToResetPasswordClicked = () => {
        this.setState({
            ...this.state,
            hasResetPasswordBeenClicked:
                !this.state.hasResetPasswordBeenClicked,
        })
    }

    render() {
        const {
            isAccountExternalInfoFormOpened,
            toggleAccountExternalInfoEditForm,
            userLoggedIn,
            toggleAccountPrivateInfoEditForm,
            toggleAccounPrivateAddressEditForm,
            isAccountPrivateInfoFormOpened,
            currentPrivateItemEdited,
            updateUserGeneralInfoRequest,
            isAccountPrivateAddressInfoOpened,
            currentExternalItemEdited,
            toggleUserProfileImageForm,
            isUSerProfileImageFormOpened,
            countriesdropdownlist,
            addAddressRequest,
            request_reset_password_func,
            deleteAccountRequest,
            carsData,
            garageData,
            usaAdminCodes,
        } = this.props

        let hasNoNames =
            userLoggedIn?.family_name === userLoggedIn?.given_name
                ? true
                : false

        let is_social_signin =
            userLoggedIn?.login_mode?.includes('google') ||
            userLoggedIn?.login_mode?.includes('apple')
                ? true
                : false

        let params = new URLSearchParams(this.props.location.search)

        let form_to_open = params.get('id')

        let accountInternalInfoList = userLoggedIn && {
            profile_image_url: userLoggedIn.profile_image_url
                ? userLoggedIn.profile_image_url
                : null,
            email: {
                id: 'email',
                text: 'email',
                answer: userLoggedIn.email,
            },
            password: {
                id: 'password',
                text: 'password',
                answer: '****************',
            },
            first_name: {
                id: 'given_name',
                text: 'given name',
                answer: hasNoNames ? undefined : userLoggedIn.given_name,
            },
            last_name: {
                id: 'family_name',
                text: 'family name',
                answer: hasNoNames ? undefined : userLoggedIn.family_name,
            },
            display_name: userLoggedIn.display_name
                ? userLoggedIn.display_name
                : userLoggedIn.given_name === userLoggedIn.family_name
                ? userLoggedIn.given_name
                : `${userLoggedIn.given_name} ${userLoggedIn.family_name}`,
            one_liner: userLoggedIn.one_liner ? userLoggedIn.one_liner : '',

            location: `${
                userLoggedIn.address && userLoggedIn.address.locality
                    ? `${userLoggedIn.address.locality}, `
                    : 'Unknown'
            } ${
                userLoggedIn.address && userLoggedIn.address.country
                    ? `${userLoggedIn.address.country}`
                    : ''
            }`,

            address: {
                id: 'address',
                text: 'address',
                answer: `${
                    userLoggedIn.address && userLoggedIn.address.street_1
                        ? userLoggedIn.address.street_1 + ','
                        : ''
                } ${
                    userLoggedIn.address && userLoggedIn.address.street_2
                        ? userLoggedIn.address.street_2 + ','
                        : ''
                } ${
                    userLoggedIn.address && userLoggedIn.address.locality
                        ? userLoggedIn.address.locality + ','
                        : ''
                } 
            ${
                userLoggedIn.address && userLoggedIn.address.postcode
                    ? userLoggedIn.address.postcode + ','
                    : ''
            } ${
                    userLoggedIn.address &&
                    userLoggedIn.address.administrative_area
                        ? userLoggedIn.address.administrative_area
                        : ''
                } ${
                    userLoggedIn.address && userLoggedIn.address.country
                        ? userLoggedIn.address.country
                        : ''
                }
            
            `,
            },
        }

        let listInternalAccountInfo = is_social_signin
            ? ['email', 'password', 'first_name', 'last_name', 'address']
            : ['email', 'first_name', 'last_name', 'address']

        const hasResetPasswordBeenClicked =
            this.state.hasResetPasswordBeenClicked

        if (
            isAccountPrivateAddressInfoOpened &&
            (isAccountExternalInfoFormOpened ||
                isAccountPrivateInfoFormOpened ||
                isUSerProfileImageFormOpened)
        ) {
            toggleAccounPrivateAddressEditForm()
        }

        let garage_id = userLoggedIn?.owns_garage.uid

        let currentGarage =
            garage_id &&
            garageData &&
            garageData.garages &&
            garageData.garages[garage_id]
                ? garageData.garages[garage_id]
                : undefined

        let garageCars =
            currentGarage && currentGarage.cars && currentGarage.cars.length > 0
                ? currentGarage.cars
                : currentGarage &&
                  currentGarage.sorted_car_ids &&
                  currentGarage.sorted_car_ids.length > 0
                ? currentGarage.sorted_car_ids
                : undefined

        let cars_no =
            garageData && garageData.total_cars_number_found
                ? garageData.total_cars_number_found
                : garageCars
                ? garageCars.length
                : 0

        let tasks_no =
            userLoggedIn && userLoggedIn.total_tasks_count
                ? userLoggedIn.total_tasks_count
                : 0

        let history_file_no =
            garageCars && carsData
                ? garageCars.reduce(
                      (acc, current) =>
                          carsData &&
                          current &&
                          carsData[current] &&
                          carsData[current].car_entries_count
                              ? acc + carsData![current].car_entries_count!
                              : acc + 0,
                      0
                  )
                : 0

        return (
            <CenteredPageWrapper hasMobileSubNav>
                {accountInternalInfoList !== null && userLoggedIn && (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            {this.state.showJourneyCard ? (
                                <div
                                    style={{
                                        width: '100%',
                                        paddingBottom: 32,
                                    }}
                                >
                                    <FadedSlower>
                                        <JourneyRedirectCardDesktop
                                            sectionID="your_profile"
                                            dataPH={
                                                'start_your_profile_journey'
                                            }
                                            copyData={journeyRedirectCardsCopy}
                                            onStartNow={() => {
                                                this.props.history.push(
                                                    `/journey/your-profile?step=welcome`
                                                )
                                            }}
                                        />
                                    </FadedSlower>
                                </div>
                            ) : null}
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title="your profile"
                                    openForm={() => {
                                        toggleAccountExternalInfoEditForm()
                                    }}
                                    customPadding={'0px'}
                                    dataCyId={`edit-profile-clickable-icon-desktop`}
                                />

                                <FirstSectionContentWrapDesktop>
                                    <div
                                        style={{
                                            height: '30px',
                                            marginLeft: '15px',
                                        }}
                                    >
                                        <UserSquareProfileImage
                                            url={
                                                accountInternalInfoList.profile_image_url &&
                                                accountInternalInfoList.profile_image_url
                                            }
                                            onClick={toggleUserProfileImageForm}
                                        />
                                        <div style={{ paddingTop: '100px' }} />
                                        <ButtonAtom
                                            theme="lowercase-white-background-smaller"
                                            onClick={toggleUserProfileImageForm}
                                        >
                                            Update image
                                        </ButtonAtom>
                                    </div>

                                    <div>
                                        <LightTitle
                                            removeIpadStyle={true}
                                            themedStyle="lightest"
                                            align="left"
                                        >
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    toggleAccountExternalInfoEditForm()
                                                }
                                            >
                                                {
                                                    accountInternalInfoList.display_name
                                                }
                                            </div>
                                        </LightTitle>

                                        <Text>
                                            <div
                                                onClick={() =>
                                                    toggleAccountExternalInfoEditForm()
                                                }
                                                style={{
                                                    minHeight: '20px',
                                                    paddingBottom: '5px',
                                                    color:
                                                        accountInternalInfoList
                                                            .one_liner.length >
                                                        1
                                                            ? 'inherit'
                                                            : '#B0B0B0',
                                                    fontFamily:
                                                        accountInternalInfoList
                                                            .one_liner.length >
                                                        1
                                                            ? 'inherit'
                                                            : 'Lato-light',
                                                }}
                                            >
                                                {accountInternalInfoList
                                                    .one_liner.length > 1
                                                    ? accountInternalInfoList.one_liner
                                                    : '+ Add bio'}
                                            </div>
                                        </Text>

                                        <ReactScroll.Link
                                            to={'user-address-section-desktop'}
                                            spy={false}
                                            smooth={true}
                                            offset={-70}
                                            duration={500}
                                            onClick={() => {
                                                toggleAccounPrivateAddressEditForm()
                                            }}
                                        >
                                            <Row>
                                                <Icon
                                                    icon="location"
                                                    width="15px"
                                                />
                                                <RowText>
                                                    {accountInternalInfoList
                                                        .location.length > 1
                                                        ? accountInternalInfoList.location
                                                        : ' Unknown '}
                                                </RowText>
                                            </Row>{' '}
                                        </ReactScroll.Link>
                                    </div>
                                </FirstSectionContentWrapDesktop>
                                <div id="user-address-section-desktop" />
                                <SectionHeader
                                    title={
                                        isAccountPrivateAddressInfoOpened
                                            ? 'Update your address'
                                            : 'Account Details'
                                    }
                                    openForm={toggleAccountPrivateInfoEditForm}
                                    removeEdit={true}
                                />

                                {isAccountPrivateAddressInfoOpened !== true && (
                                    <ListManagerDesktop
                                        toggle={(item: IList) => {
                                            item.id === 'address'
                                                ? toggleAccounPrivateAddressEditForm()
                                                : toggleAccountPrivateInfoEditForm(
                                                      item
                                                  )
                                        }}
                                        data={accountInternalInfoList}
                                        list={listInternalAccountInfo}
                                        removeBottomBorderCondition={
                                            isAccountPrivateAddressInfoOpened
                                        }
                                    />
                                )}

                                {isAccountPrivateAddressInfoOpened &&
                                    countriesdropdownlist && (
                                        <AccountAddressFormManagerDesktop
                                            countriesdropdownlist={
                                                countriesdropdownlist
                                            }
                                            usaAdminCodes={usaAdminCodes}
                                            addAddress={addAddressRequest}
                                            currentAddress={
                                                userLoggedIn.address
                                                    ? userLoggedIn.address
                                                    : null
                                            }
                                            toggleAddresForm={() => {
                                                toggleAccounPrivateAddressEditForm()
                                            }}
                                        />
                                    )}
                                <div style={{ height: '70px' }} />
                                {isAccountPrivateAddressInfoOpened !== true && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ButtonAtom
                                            theme="lowercase-red-background"
                                            onClick={this.props.logout}
                                        >
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                Logout
                                            </span>
                                        </ButtonAtom>
                                        <div style={{ paddingRight: '32px' }} />

                                        <ButtonAtom
                                            theme="naked-text"
                                            fontSize={16}
                                            onClick={() =>
                                                this.setState({
                                                    ...this.state,
                                                    accountDeletionWizard: {
                                                        ...this.state
                                                            .accountDeletionWizard,
                                                        isOpen: true,
                                                        step:
                                                            cars_no === 0
                                                                ? 2
                                                                : 1,
                                                    },
                                                })
                                            }
                                        >
                                            <span
                                                style={{
                                                    textTransform: 'capitalize',
                                                    color: '#DF6F6F',
                                                }}
                                            >
                                                Delete account
                                            </span>
                                        </ButtonAtom>
                                    </div>
                                )}
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            {/* <MobileSettingsSectionHeader title="your profile" /> */}
                            <div style={{ paddingTop: '20px' }} />
                            <CenterDiv>
                                <UserAccountSquare
                                    url={
                                        accountInternalInfoList.profile_image_url &&
                                        accountInternalInfoList.profile_image_url
                                    }
                                    onClick={toggleUserProfileImageForm}
                                />
                            </CenterDiv>
                            <CenterDiv>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '150px',
                                    }}
                                >
                                    <ButtonAtom
                                        theme="lowercase-white-background-smaller"
                                        onClick={toggleUserProfileImageForm}
                                        height={'42px'}
                                        width={'128px'}
                                    >
                                        update image
                                    </ButtonAtom>
                                </div>
                            </CenterDiv>
                            <div style={{ marginTop: '200px' }}>
                                <ListItemMobile
                                    item={{
                                        id: 'display_name',
                                        text: 'display name*',
                                        answer: accountInternalInfoList.display_name,
                                    }}
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: 'display_name',
                                            text: 'display name*',
                                            answer: accountInternalInfoList
                                                ? accountInternalInfoList.display_name
                                                : '',
                                        })
                                    }
                                    height="80px"
                                    dataCyId={'display_name-field-mobile'}
                                />
                                <div style={{ height: '10px' }} />
                                <ListItemMobile
                                    item={{
                                        id: 'one_liner',
                                        text: 'your bio*',
                                        answer: accountInternalInfoList.one_liner,
                                    }}
                                    minHeight="80px"
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: 'one_liner',
                                            text: 'your bio*',
                                            answer: accountInternalInfoList
                                                ? accountInternalInfoList.one_liner
                                                : '',
                                        })
                                    }
                                    fontStyle="Georgia"
                                    multipleLineAnswer={true}
                                />

                                <ReactScroll.Link
                                    to={'user-address-section'}
                                    spy={false}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={() => {
                                        toggleAccounPrivateAddressEditForm()
                                    }}
                                >
                                    <ListItemMobile
                                        item={{
                                            id: 'location',
                                            text: 'location',
                                            answer: accountInternalInfoList.location,
                                        }}
                                        height="80px"
                                        toggle={
                                            () => {}
                                            // toggleAccountExternalInfoEditForm({
                                            //     id: 'location',
                                            //     text: 'location *',
                                            //     answer: accountInternalInfoList
                                            //         ? accountInternalInfoList.location
                                            //         : '',
                                            // })
                                        }
                                    />
                                </ReactScroll.Link>

                                <div style={{ height: '50px' }} />

                                <MobileSettingsSectionHeader
                                    title={
                                        isAccountPrivateAddressInfoOpened !==
                                        true
                                            ? 'Account Details'
                                            : 'update your address'
                                    }
                                />
                                {!isAccountPrivateAddressInfoOpened && (
                                    <div
                                        style={{ paddingTop: '40px' }}
                                        id="user-address-section"
                                    >
                                        <ListManagerMobile
                                            toggle={(item: IList) => {
                                                item.id === 'address'
                                                    ? toggleAccounPrivateAddressEditForm()
                                                    : toggleAccountPrivateInfoEditForm(
                                                          item
                                                      )
                                            }}
                                            data={accountInternalInfoList}
                                            list={listInternalAccountInfo}
                                        />
                                        <CenteredDiv
                                            style={{ flexDirection: 'column' }}
                                        >
                                            <ButtonAtom
                                                theme="lowercase-red-background"
                                                onClick={this.props.logout}
                                            >
                                                <span
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                    }}
                                                >
                                                    Logout
                                                </span>
                                            </ButtonAtom>
                                            <div
                                                style={{ paddingTop: '40px' }}
                                            />

                                            <ButtonAtom
                                                theme="naked-text"
                                                onClick={() =>
                                                    this.setState({
                                                        ...this.state,
                                                        accountDeletionWizard: {
                                                            ...this.state
                                                                .accountDeletionWizard,
                                                            isOpen: true,
                                                            step:
                                                                cars_no === 0
                                                                    ? 2
                                                                    : 1,
                                                        },
                                                    })
                                                }
                                            >
                                                <span
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                        color: 'var(--error, #DF6F6F)',
                                                        fontSize: 14,
                                                    }}
                                                >
                                                    Delete account
                                                </span>
                                            </ButtonAtom>
                                        </CenteredDiv>
                                    </div>
                                )}
                            </div>
                        </IpadAndMobileDisplay>
                    </Faded>
                )}

                {userLoggedIn && (
                    <React.Fragment>
                        <DesktopDisplayOnly>
                            {isUSerProfileImageFormOpened && (
                                <ModalDisplay
                                    toggle={toggleUserProfileImageForm}
                                    isOpen={true}
                                    customZindex={8}
                                >
                                    <WrapperUserProfileImageDesktop
                                        close={toggleUserProfileImageForm}
                                        user_id={userLoggedIn.id}
                                    />
                                </ModalDisplay>
                            )}

                            {this.state.accountDeletionWizard.isOpen && (
                                <ModalDisplay
                                    toggle={toggleUserProfileImageForm}
                                    isOpen={true}
                                    customZindex={8}
                                >
                                    <AccountDeletionManagerDesktop
                                        step_id={
                                            this.state.accountDeletionWizard
                                                .step
                                        }
                                        user={userLoggedIn}
                                        changeSteps={(step: number) =>
                                            this.setState({
                                                ...this.state,
                                                accountDeletionWizard: {
                                                    ...this.state
                                                        .accountDeletionWizard,
                                                    step: step,
                                                },
                                            })
                                        }
                                        handleChange={(
                                            id: keyof IAccDelItems,
                                            value: any
                                        ) =>
                                            this.setState({
                                                ...this.state,
                                                accountDeletionWizard: {
                                                    ...this.state
                                                        .accountDeletionWizard,
                                                    answers: {
                                                        ...this.state
                                                            .accountDeletionWizard
                                                            .answers,
                                                        [id]: value,
                                                    },
                                                },
                                            })
                                        }
                                        activeItems={
                                            this.state.accountDeletionWizard
                                                .answers
                                        }
                                        cars_no={cars_no}
                                        history_files_no={history_file_no}
                                        tasks_no={tasks_no}
                                        onClose={() =>
                                            this.setState({
                                                ...this.state,
                                                accountDeletionWizard: {
                                                    ...this.state
                                                        .accountDeletionWizard,
                                                    isOpen: false,
                                                },
                                            })
                                        }
                                        deleteAccount={() => {
                                            deleteAccountRequest(
                                                this.state.accountDeletionWizard
                                                    .answers
                                            )
                                        }}
                                    />
                                </ModalDisplay>
                            )}

                            {form_to_open === 'profile_picture' && (
                                <ModalDisplay
                                    toggle={() => {
                                        this.props.history.push(`/account`)
                                    }}
                                    isOpen={true}
                                    customZindex={8}
                                >
                                    <WrapperUserProfileImageDesktop
                                        close={() =>
                                            this.props.history.push(`/account`)
                                        }
                                        user_id={userLoggedIn.id}
                                    />
                                </ModalDisplay>
                            )}

                            {isAccountExternalInfoFormOpened && (
                                <ModalDisplay
                                    toggle={toggleAccountExternalInfoEditForm}
                                    isOpen={true}
                                    customZindex={8}
                                >
                                    <AccountExternalInfoFormDesktop
                                        close={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        userLoggedIn={userLoggedIn}
                                        submit_func={
                                            updateUserGeneralInfoRequest
                                        }
                                    />
                                </ModalDisplay>
                            )}

                            {isAccountPrivateInfoFormOpened && (
                                <ModalDisplay
                                    toggle={toggleAccountPrivateInfoEditForm}
                                    isOpen={true}
                                    customZindex={8}
                                >
                                    <AccountPrivateInfoManagerFormDesktop
                                        toggle={
                                            toggleAccountPrivateInfoEditForm
                                        }
                                        userLoggedIn={userLoggedIn}
                                        item={currentPrivateItemEdited}
                                        submit_func={
                                            updateUserGeneralInfoRequest
                                        }
                                        setToResetPasswordClicked={
                                            this.setToResetPasswordClicked
                                        }
                                        hasResetPasswordBeenClicked={
                                            hasResetPasswordBeenClicked
                                        }
                                        request_reset_password_func={
                                            request_reset_password_func
                                        }
                                    />
                                </ModalDisplay>
                            )}
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <BottomSheetPage
                                isOpen={this.state.accountDeletionWizard.isOpen}
                            >
                                <AccountDeletionManagerMobile
                                    step_id={
                                        this.state.accountDeletionWizard.step
                                    }
                                    user={userLoggedIn}
                                    changeSteps={(step: number) =>
                                        this.setState({
                                            ...this.state,
                                            accountDeletionWizard: {
                                                ...this.state
                                                    .accountDeletionWizard,
                                                step: step,
                                            },
                                        })
                                    }
                                    handleChange={(
                                        id: keyof IAccDelItems,
                                        value: any
                                    ) =>
                                        this.setState({
                                            ...this.state,
                                            accountDeletionWizard: {
                                                ...this.state
                                                    .accountDeletionWizard,
                                                answers: {
                                                    ...this.state
                                                        .accountDeletionWizard
                                                        .answers,
                                                    [id]: value,
                                                },
                                            },
                                        })
                                    }
                                    activeItems={
                                        this.state.accountDeletionWizard.answers
                                    }
                                    cars_no={cars_no}
                                    history_files_no={history_file_no}
                                    tasks_no={tasks_no}
                                    onClose={() =>
                                        this.setState({
                                            ...this.state,
                                            accountDeletionWizard: {
                                                ...this.state
                                                    .accountDeletionWizard,
                                                isOpen: false,
                                            },
                                        })
                                    }
                                    deleteAccount={() => {
                                        deleteAccountRequest(
                                            this.state.accountDeletionWizard
                                                .answers
                                        )
                                    }}
                                />
                            </BottomSheetPage>

                            {isUSerProfileImageFormOpened && (
                                <ModalDisplay
                                    toggle={toggleUserProfileImageForm}
                                    isOpen={true}
                                >
                                    <WrapperUserProfileImageMobile
                                        close={toggleUserProfileImageForm}
                                        user_id={userLoggedIn.id}
                                    />
                                </ModalDisplay>
                            )}

                            {form_to_open === 'profile_picture' && (
                                <ModalDisplay
                                    toggle={() => {
                                        this.props.history.push(`/account`)
                                    }}
                                    isOpen={true}
                                >
                                    <WrapperUserProfileImageMobile
                                        close={() =>
                                            this.props.history.push(`/account`)
                                        }
                                        user_id={userLoggedIn.id}
                                    />
                                </ModalDisplay>
                            )}

                            {isAccountExternalInfoFormOpened &&
                                currentExternalItemEdited &&
                                currentExternalItemEdited.id !== 'location' && (
                                    <ModalDisplay
                                        toggle={
                                            toggleAccountExternalInfoEditForm
                                        }
                                        isOpen={true}
                                    >
                                        <AccountExternalInfoFormMobile
                                            toggle={
                                                toggleAccountExternalInfoEditForm
                                            }
                                            item={currentExternalItemEdited}
                                            submitFunc={
                                                updateUserGeneralInfoRequest
                                            }
                                            user={userLoggedIn}
                                        />
                                    </ModalDisplay>
                                )}

                            {isAccountPrivateInfoFormOpened && (
                                <ModalDisplay
                                    toggle={toggleAccountPrivateInfoEditForm}
                                    isOpen={true}
                                >
                                    <AccountPrivateInfoManagerMobileForm
                                        toggle={
                                            toggleAccountPrivateInfoEditForm
                                        }
                                        userLoggedIn={userLoggedIn}
                                        item={currentPrivateItemEdited}
                                        submit_func={
                                            updateUserGeneralInfoRequest
                                        }
                                        setToResetPasswordClicked={
                                            this.setToResetPasswordClicked
                                        }
                                        hasResetPasswordBeenClicked={
                                            hasResetPasswordBeenClicked
                                        }
                                        request_reset_password_func={
                                            request_reset_password_func
                                        }
                                    />
                                </ModalDisplay>
                            )}

                            {isAccountPrivateAddressInfoOpened &&
                                countriesdropdownlist && (
                                    <CenterDiv>
                                        <AccountAddressFormManagerMobile
                                            countriesdropdownlist={
                                                countriesdropdownlist
                                            }
                                            usaAdminCodes={usaAdminCodes}
                                            addAddress={addAddressRequest}
                                            currentAddress={
                                                userLoggedIn.address
                                                    ? userLoggedIn.address
                                                    : null
                                            }
                                            toggleAddresForm={() => {
                                                toggleAccounPrivateAddressEditForm()
                                            }}
                                        />
                                    </CenterDiv>
                                )}

                            <div style={{ paddingTop: '200px' }} />
                        </IpadAndMobileDisplay>
                    </React.Fragment>
                )}
            </CenteredPageWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(AccountPage))
