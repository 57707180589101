import * as React from 'react'
import CarGalleryEditFormDesktop from '../carGalleryEditForm/carGalleryEditFormDesktop'
// import OnboardingGalleryImageDesktopForm from '../carGalleryEditForm/onboardingGalleryImageDesktopForm'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import HistoryFileGalleryEditFormDesktop from '../historyFileGalleryEditForm/historyFileGalleryEditFormDesktop'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { IUploadGalleryImagesPreSignedUrlsPayload } from 'myModels'

let axios = require('axios')

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {
        gallerypresignedurls: state.fileStorage.gallerypresignedurls,
    }
}

interface Props {
    gallerypresignedurls?: IUploadGalleryImagesPreSignedUrlsPayload
    close: any
    carid: string
    entryid?: string
    isOnboarding?: boolean
    display_img_list?: string[]
    step?: number
}

type State = {
    imageUploadedData: any[] | null
    isImageCropperShown: boolean
    changeInPresignedURL: boolean
    presignedUrlsState: any
    filesArray: (File | undefined)[]
    [key: string]: any
    isSomethingBeingUploaded: boolean
    count: number
    isLastUploaded: boolean
    progressState: { [key: string]: any }
}

class WrapperImageFormsDesktop extends React.Component<Props, State> {
    state = {
        imageUploadedData: null,
        isImageCropperShown: false,
        changeInPresignedURL: false,
        presignedUrlsState: undefined,
        filesArray: [],
        isSomethingBeingUploaded: true,
        isLastUploaded: false,
        count: 0,
        progressState: {
            index: null,
            total: 0,
        },
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let urls = this.props.gallerypresignedurls

        if (urls !== prevProps.gallerypresignedurls) {
            if (urls) {
                let stateFiles: File[] = this.state.filesArray
                    ? this.state.filesArray
                    : ([] as any)

                urls && this.upload_presignedurl_image_to_s3(stateFiles)

                this.setState({
                    presignedUrlsState: this.props.gallerypresignedurls,
                    changeInPresignedURL: !this.state.changeInPresignedURL,
                })
            }
        }
        return
    }

    // DIRECT SETSTATE

    setImageUploaded = (imgUploadedData: any[]) => {
        this.setState({ imageUploadedData: imgUploadedData })
    }

    deleteImageUploaded = (index: number) => {
        let newFilesArray: (File | undefined)[] = this.state.filesArray

        newFilesArray[index] = undefined

        this.setState({ filesArray: newFilesArray })
        this.setState({
            ...this.state,
            progressState: {
                ...this.state.progressState,
                [`filesProgressObj-${index}`]: null,
            },
        })
    }

    toggleShowImageCropper = () => {
        this.setState({ isImageCropperShown: !this.state.isImageCropperShown })
    }

    // OTHER FUNCS

    upload_presignedurl_image_to_s3 = (files: File[]) => {
        let filesToSend = this.state.filesArray

        filesToSend !== null &&
            filesToSend !== undefined &&
            filesToSend.forEach(async (file: File, index: number) => {
                if (
                    file !== undefined &&
                    this.props.gallerypresignedurls &&
                    this.props.gallerypresignedurls[file.name]
                ) {
                    this.setState({
                        progressState: {
                            total: 0,
                            index: index,
                        },
                    })

                    this.setState({
                        isSomethingBeingUploaded: true,
                    })

                    return await axios
                        .request({
                            method: 'put',
                            url: this.props.gallerypresignedurls[file.name].url,
                            data: files[index],
                            onUploadProgress: (p: any) => {
                                let new_key = `filesProgressObj-${index}`

                                this.setState({
                                    ...this.state,
                                    progressState: {
                                        ...this.state.progressState,
                                        total: (p.loaded / p.total) * 100,
                                        [new_key]: (p.loaded / p.total) * 100,
                                    },
                                })
                            },
                        })
                        .then((data: any) => {
                            if (
                                this.state.progressState.index ===
                                filesToSend.length - 1
                            ) {
                                return this.setState({
                                    isLastUploaded: true,
                                    isSomethingBeingUploaded: false,
                                    count: filesToSend.length,
                                })
                            }
                            return
                        })
                        .catch((e: any) => {
                            apiSentryErrorHandler(
                                e,
                                'Upload presigned url car image to s3 error'
                            )
                            return Promise.reject(e)
                        })
                }
                // };
            })
    }

    handleFilesGivenInWrapperComponent = (filesArrayToUpload: File[]) => {
        this.setState({
            progressState: {
                index: null,
                total: 0,
            },
            isLastUploaded: false,
            count: 0,
            filesArray: filesArrayToUpload,
        })

        filesArrayToUpload.forEach((file: File, index: number) => {
            let imguploadeddata = {
                fileTitle: file.name,
                file: file,
            }

            let imgdatarray = this.state.imageUploadedData
                ? this.state.imageUploadedData
                : ([] as any)

            imgdatarray[index] = imguploadeddata

            this.setState({ imageUploadedData: imgdatarray })
            return
        })
    }

    render() {
        const { carid, entryid, close } = this.props

        const { filesArray, isSomethingBeingUploaded } = this.state

        return (
            <div>
                {!entryid && (
                    <CarGalleryEditFormDesktop
                        close={close}
                        deleteImageUploaded={this.deleteImageUploaded}
                        setImageUploaded={
                            this.handleFilesGivenInWrapperComponent
                        }
                        currentImagesUploaded={filesArray}
                        carid={carid}
                        filesProgressObj={this.state.progressState}
                        isSomethingBeingUploaded={isSomethingBeingUploaded}
                    />
                )}

                {entryid && (
                    <HistoryFileGalleryEditFormDesktop
                        carid={carid}
                        entryid={entryid}
                        close={close}
                        deleteImageUploaded={this.deleteImageUploaded}
                        setImageUploaded={
                            this.handleFilesGivenInWrapperComponent
                        }
                        currentImagesUploaded={filesArray}
                        filesProgressObj={this.state.progressState}
                        isSomethingBeingUploaded={isSomethingBeingUploaded}
                    />
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(WrapperImageFormsDesktop)
