// create store for todos (basically reducer for the main final store)

import {
    ICarsState,
    IDirectShareOwnedItem,
    IDirectShareOwnedItemsData,
} from 'entityModels'

import * as actions from './actions/actions'

import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: ICarsState = {
    cars: {},
    directly_shared_ext_cars: {},
    currentCarID: undefined,
    currentCarID_ext_directShare: undefined,
    tasks: {},
    error: null,
    loading: false,
    carSharesLoading: false,
    car_checks_loading: false,
}

export const carsData = (
    state: ICarsState = initialState,
    action: LoadingActions | Action | ErrorActions
): ICarsState => {
    switch (action.type) {
        case loadingActions.getCarDataRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.getCarDataError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.getCarDataSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case loadingActions.getExternalCarsSharedWithYouREQUEST.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.getExternalCarsSharedWithYouERROR.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.getExternalCarsSharedWithYouSuccess.type:
            return Object.assign({}, state, {
                ...state,
                directly_shared_ext_cars: {
                    ...state.directly_shared_ext_cars,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case loadingActions.getCarDataByIdRequest.type:
            return Object.assign({}, state, {
                ...state,
                // loading: true,
                error: null,
            })

        case loadingActions.setEmptyCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.getCarDataByIdError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.getCarDataByIdSuccess.type:
            let carID: string = Object.keys(action.payload)[0]
            let currentCarData = state.cars[carID]
                ? { ...state.cars[carID] }
                : {}

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [carID]: {
                        ...currentCarData,
                        ...action.payload[carID],
                    },
                },

                loading: false,
                error: null,
            })

        ///////////////

        case loadingActions.getCarActionCheckRequest.type:
            return Object.assign({}, state, {
                ...state,
                car_checks_loading: true,
                error: null,
            })

        case errorActions.getCarActionCheckError.type:
            return Object.assign({}, state, {
                ...state,
                car_checks_loading: false,
                error: action.payload,
            })

        case actions.getCarActionCheckSuccess.type:
            let checked_carid: string = action.payload.carid
            let currentCheckedCarData = state.cars[checked_carid]
                ? state.cars[checked_carid]
                : {}

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [checked_carid]: {
                        ...currentCheckedCarData,
                        car_actions_checks: {
                            ...state.cars[checked_carid].car_actions_checks,
                            ...action.payload.action_check,
                        },
                    },
                },

                car_checks_loading: false,
                error: null,
            })

        ////////

        case loadingActions.setCurrentCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.setCurrentCarSuccess.type:
            return Object.assign({}, state, {
                ...state,
                currentCarID: action.payload,
                loading: false,
                error: null,
            })

        case loadingActions.setCurrentExternalCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.setCurrentExternalCarSuccess.type:
            return Object.assign({}, state, {
                ...state,
                currentCarID_ext_directShare: action.payload,
                loading: false,
                error: null,
            })

        case loadingActions.getGarageCarsDataRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.getGarageCarsDataError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.getGarageCarsDataSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    ...action.payload,
                },

                loading: false,
                error: null,
            })

        case loadingActions.writeCarDataGeneralRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.writeCarDataGeneralError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.writeCarDataGeneralSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case loadingActions.createCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.createCarError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.createCarSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case loadingActions.updateCarBannerUrlRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.updateCarBannerUrlError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.updateCarBannerUrlSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.car_id]: {
                        ...state.cars[action.payload.car_id],
                        banner_url: action.payload.image.url,
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.getExternalCarDataByRegistrationNumberRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case loadingActions
            .getExternalCarDataByRegistrationNumberRequestVersion2.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.getExternalCarDataByRegistrationNumberError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.getExternalCarDataByRegistrationNumberSuccess.type:
            return Object.assign({}, state, {
                ...state,
                externalDataByRegistrationNumber: {
                    ...state.externalDataByRegistrationNumber,
                    ...action.payload,
                },
                cars: state.cars,
                loading: false,
                error: null,
            })

        case actions.getExternalCarDataByRegistrationNumberForInsuranceSuccess
            .type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        // TASKS IDS LIST

        case loadingActions.setCarTaskIdsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.setCarTaskIdsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.setCarTaskIdsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        // CAR ID DELETION

        case loadingActions.deleteCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.deleteCarError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.deleteCarSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case actions.sortCarSharesDisplay_change_success.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        private_shares_owned: {
                            ...state.cars[action.payload.carid]
                                .private_shares_owned,
                            active_display: {
                                ...state.cars[action.payload.carid]
                                    .private_shares_owned?.active_display,
                                activeSort: action.payload.sortID,
                                item_ids_display: [
                                    ...action.payload.item_ids_display,
                                ],
                                isLoading: false,
                            },
                        },
                    },
                },
            })

        case actions.filterCarSharesDisplay_change_success.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        private_shares_owned: {
                            ...state.cars[action.payload.carid]
                                .private_shares_owned,
                            active_display: {
                                ...state.cars[action.payload.carid]
                                    .private_shares_owned?.active_display,
                                activeFilter: action.payload.filterIDs,
                                item_ids_display: [
                                    ...action.payload.item_ids_display,
                                ],
                                isLoading: false,
                            },
                        },
                    },
                },
            })

        case actions.searchQueryCarSharesDisplay_change_success.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        private_shares_owned: {
                            ...state.cars[action.payload.carid]
                                .private_shares_owned,
                            active_display: {
                                ...state.cars[action.payload.carid]
                                    .private_shares_owned?.active_display,
                                activeSearchQuery: action.payload.searchQuery,
                                item_ids_display: [
                                    ...action.payload.item_ids_display,
                                ],
                                isLoading: false,
                            },
                        },
                    },
                },
            })

        case actions.resetSearchQueryCarShareDisplay_success.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload]: {
                        ...state.cars[action.payload],
                        private_shares_owned: {
                            ...state.cars[action.payload].private_shares_owned,
                            active_display: {
                                ...state.cars[action.payload]
                                    .private_shares_owned?.active_display,
                                activeSearchQuery: '',
                                item_ids_display:
                                    state.cars[action.payload]
                                        .private_shares_owned
                                        ?.entity_owned_direct_shares_ids,
                                isLoading: false,
                            },
                        },
                    },
                },
            })

        case actions.sortShareRecipientsDisplay_change_success.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.entityId]: {
                        ...state.cars[action.payload.entityId],
                        private_shares_owned: {
                            ...state.cars[action.payload.entityId]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...state.cars[action.payload.entityId]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data,
                                [action.payload.shareid]: {
                                    ...state.cars[action.payload.entityId]
                                        .private_shares_owned
                                        ?.entity_owned_direct_shares_data[
                                        action.payload.shareid
                                    ],
                                    active_display: {
                                        ...state.cars[action.payload.entityId]
                                            .private_shares_owned
                                            ?.entity_owned_direct_shares_data[
                                            action.payload.shareid
                                        ].active_display,
                                        activeSort: action.payload.sortID,
                                        item_ids_display: [
                                            ...action.payload.item_ids_display,
                                        ],
                                        isLoading: false,
                                    },
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.searchQueryCarSharesDisplay_change_request.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        private_shares_owned: {
                            ...state.cars[action.payload.carid]
                                .private_shares_owned,
                            active_display: {
                                ...state.cars[action.payload.carid]
                                    .private_shares_owned?.active_display,
                                isLoading: true,
                            },
                        },
                    },
                },
            })

        case loadingActions.filterCarSharesDisplay_change_request.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        private_shares_owned: {
                            ...state.cars[action.payload.carid]
                                .private_shares_owned,
                            active_display: {
                                ...state.cars[action.payload.carid]
                                    .private_shares_owned?.active_display,
                                isLoading: true,
                            },
                        },
                    },
                },
            })

        case loadingActions.sortCarSharesDisplay_change_request.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        private_shares_owned: {
                            ...state.cars[action.payload.carid]
                                .private_shares_owned,
                            active_display: {
                                ...state.cars[action.payload.carid]
                                    .private_shares_owned?.active_display,
                                isLoading: true,
                            },
                        },
                    },
                },
            })

        case loadingActions.sortShareRecipientsDisplay_change_request.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.entityid]: {
                        ...state.cars[action.payload.entityid],
                        private_shares_owned: {
                            ...state.cars[action.payload.entityid]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...state.cars[action.payload.entityid]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data,
                                [action.payload.share.id]: {
                                    ...state.cars[action.payload.entityid]
                                        .private_shares_owned
                                        ?.entity_owned_direct_shares_data[
                                        action.payload.share.id
                                    ],
                                    active_display: {
                                        ...state.cars[action.payload.entityid]
                                            .private_shares_owned
                                            ?.entity_owned_direct_shares_data[
                                            action.payload.share.id
                                        ].active_display,
                                        isLoading: true,
                                    },
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        // SHARING

        // no loading on single get public car shares

        case loadingActions.getPublicSharesCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case actions.getPublicSharesCarSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.id]: {
                        ...state.cars[action.payload.id],
                        public_share_owned: {
                            ...action.payload.public_share,
                        },
                    },
                },
                loading: false,
                carSharesLoading: false,
                error: null,
            })

        case errorActions.getPublicSharesCarError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
            })

        case loadingActions.getOwnedDirectSharesCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                error: null,
                carSharesLoading: true,
            })

        case loadingActions.getAllCarSharesRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case actions.getAllCarSharesSuccess.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: null,
            })

        case actions.getOwnedDirectSharesCarSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.id]: {
                        ...state.cars[action.payload.id],
                        private_shares_owned: {
                            ...action.payload.private_shares_owned,
                        },
                    },
                },
                carSharesLoading: false,
                error: null,
            })

        case actions.getPublicSharesAllGarageCarsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: null,
            })

        case errorActions.getAllCarSharesError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })
        case errorActions.getOwnedDirectSharesCarError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case loadingActions.getPublicSharesAllUserGarageCarsRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case loadingActions.createCarPublicShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case errorActions.createCarPublicShareError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case actions.createCarPublicShareSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.id]: {
                        ...state.cars[action.payload.id],
                        public_share_owned: {
                            ...action.payload.public_share,
                        },
                    },
                },
                carSharesLoading: false,
                error: null,
            })

        case loadingActions.deleteCarPublicShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case errorActions.deleteCarPublicShareError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case actions.deleteCarPublicShareSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.entity_id]: {
                        ...state.cars[action.payload.entity_id],
                        public_share_owned: null,
                    },
                },
                carSharesLoading: false,
                error: null,
            })

        case actions.updatCarOverviewGridInfo.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        overview: {
                            ...state.cars[action.payload.carid].overview,
                            data: {
                                ...state.cars[action.payload.carid].overview
                                    .data,
                                [action.payload.id]: action.payload.data,
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case actions.updatCarTitleOnCreation.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        title: action.payload.title,
                    },
                },
                loading: false,
                error: null,
            })

        case actions.updatCarEntriesCount.type: {
            let current_car_entries_count: number | undefined =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].car_entries_count
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        car_entries_count:
                            current_car_entries_count !== undefined
                                ? action.payload.update === 'add'
                                    ? current_car_entries_count + 1
                                    : current_car_entries_count - 1
                                : 0,
                    },
                },
                loading: false,
                error: null,
            })
        }

        case actions.addToCarImagesIdsArray.type: {
            let current_car_images_ids: string[] | undefined =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].gallery &&
                state.cars[action.payload.carid].gallery.images
            let new_images_ids_array: string[] = current_car_images_ids
                ? [...current_car_images_ids]
                : []

            for (let i = 0; i < action.payload.image_ids.length; i++) {
                let indexOfImg: number = new_images_ids_array.indexOf(
                    action.payload.image_ids[i]
                )
                if (indexOfImg < 0) {
                    new_images_ids_array.unshift(action.payload.image_ids[i])
                }
            }

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        gallery: {
                            ...state.cars[action.payload.carid].gallery,
                            images: new_images_ids_array,
                        },
                    },
                },
                loading: false,
                error: null,
            })
        }

        case actions.deleteFromCarImagesIdsArray.type: {
            let current_car_images_ids: string[] | undefined =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].gallery &&
                state.cars[action.payload.carid].gallery.images
            let new_images_ids_array: string[] | undefined =
                current_car_images_ids ? [...current_car_images_ids] : undefined

            if (new_images_ids_array) {
                let indexOfImg: number = new_images_ids_array.indexOf(
                    action.payload.imageid
                )
                if (indexOfImg > 0) {
                    new_images_ids_array.splice(indexOfImg, 1)
                }
            }

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        gallery: {
                            ...state.cars[action.payload.carid].gallery,
                            images: new_images_ids_array,
                        },
                    },
                },
                loading: false,
                error: null,
            })
        }

        case actions.updateCoverImgId.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        gallery: {
                            ...state.cars[action.payload.carid].gallery,
                            cover: action.payload.new_cover_id,
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case actions.updateBannerImage.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        banner_url: action.payload.banner_img,
                    },
                },
                loading: false,
                error: null,
            })

        case actions.populateSingleCarAllOwnedSharesSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.entity_id]: {
                        ...state.cars[action.payload.entity_id],
                        public_share_owned: {
                            ...action.payload.owned_shares_obj
                                .public_share_owned,
                        },
                        private_shares_owned: {
                            ...action.payload.owned_shares_obj
                                .private_shares_owned,
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case actions.createCarDirectShareSuccess.type:
            let sharing_ids: string[] =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_ids
                    : []

            let sharing_data: IDirectShareOwnedItemsData =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_data
                    : {}

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.id]: {
                        ...state.cars[action.payload.id],
                        private_shares_owned: {
                            entity_owned_direct_shares_ids: [
                                ...sharing_ids,
                                action.payload.direct_share.id,
                            ],
                            entity_owned_direct_shares_data: {
                                ...sharing_data,
                                [action.payload.direct_share.id]: {
                                    ...action.payload.direct_share,
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.createCarDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case errorActions.createCarDirectShareError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case actions.updateCarDirectShareSuccess.type:
            let sharing_data_for_update: IDirectShareOwnedItemsData =
                state.cars[action.payload.car_id] &&
                state.cars[action.payload.car_id].private_shares_owned &&
                state.cars[action.payload.car_id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.cars[action.payload.car_id].private_shares_owned!
                          .entity_owned_direct_shares_data
                    : {}

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.car_id]: {
                        ...state.cars[action.payload.car_id],
                        private_shares_owned: {
                            ...state.cars[action.payload.car_id]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...sharing_data_for_update,
                                [action.payload.updated_share.id]: {
                                    ...action.payload.updated_share,
                                },
                            },
                        },
                    },
                },
                carSharesLoading: false,
                loading: false,
                error: null,
            })

        case loadingActions.updateCarDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case errorActions.updateCarDirectShareError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case actions.getSingleCarDirectShareSuccess.type:
            let sharing_ids_2: string[] =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_ids
                    : []

            let new_sharing_ids: string[] = sharing_ids_2.includes(
                action.payload.direct_share.id
            )
                ? [...sharing_ids_2]
                : [...sharing_ids_2, action.payload.direct_share.id]

            let sharing_data_2: IDirectShareOwnedItemsData =
                state.cars[action.payload.id] &&
                state.cars[action.payload.id].private_shares_owned &&
                state.cars[action.payload.id].private_shares_owned!
                    .entity_owned_direct_shares_data
                    ? state.cars[action.payload.id].private_shares_owned!
                          .entity_owned_direct_shares_data
                    : {}

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.id]: {
                        ...state.cars[action.payload.id],
                        private_shares_owned: {
                            entity_owned_direct_shares_ids: new_sharing_ids,
                            entity_owned_direct_shares_data: {
                                ...sharing_data_2,
                                [action.payload.direct_share.id]: {
                                    ...action.payload.direct_share,
                                },
                            },
                        },
                    },
                },
                carSharesLoading: false,
                error: null,
            })

        case loadingActions.getSingleCarDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case errorActions.getSingleCarDirectShareError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case loadingActions.addCarShareRecipientRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case errorActions.addCarShareRecipientError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case actions.addCarShareRecipientSuccess.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                loading: false,
                error: null,
            })

        case loadingActions.deleteCarShareRecipientRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case errorActions.deleteCarShareRecipientError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case actions.deleteCarShareRecipientSuccess.type:
            let current_share: IDirectShareOwnedItem | undefined =
                state.cars[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_all_share_recipients_filtered_for_recipients_deletion =
                current_share.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.recipient_id
                )
            let current_platform_share_recipients_filtered_for_recipients_deletion =
                current_share.platform_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.recipient_id
                )

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.entity_id]: {
                        ...state.cars[action.payload.entity_id],
                        private_shares_owned: {
                            ...state.cars[action.payload.entity_id]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...state.cars[action.payload.entity_id]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data,
                                [action.payload.share_id]: {
                                    ...current_share,
                                    platform_share_recipients_ids_list: [
                                        ...current_platform_share_recipients_filtered_for_recipients_deletion,
                                    ],
                                    all_share_recipients_ids_list: [
                                        ...current_all_share_recipients_filtered_for_recipients_deletion,
                                    ],
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case errorActions.deleteDirectCarShareError.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: false,
                error: action.payload,
            })

        case loadingActions.deleteCarDirectShareRequest.type:
            return Object.assign({}, state, {
                ...state,
                carSharesLoading: true,
                error: null,
            })

        case actions.deleteDirectCarShareSuccess.type:
            let car_share_ids_filtered: string[] =
                state.cars[action.payload.entity_id].private_shares_owned &&
                state.cars[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_ids
                    ? state.cars[
                          action.payload.entity_id
                      ].private_shares_owned!.entity_owned_direct_shares_ids.filter(
                          (id: string) => id !== action.payload.share_id
                      )
                    : []

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.entity_id]: {
                        ...state.cars[action.payload.entity_id],
                        private_shares_owned: {
                            ...state.cars[action.payload.entity_id]
                                .private_shares_owned,
                            entity_owned_direct_shares_ids: [
                                ...car_share_ids_filtered,
                            ],
                        },
                    },
                },
                carSharesLoading: false,
                error: null,
            })

        // CREATE CAR SHARE INVITE

        case loadingActions.createCarShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.createCarShareInviteSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.createCarShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // RESEND CAR SHARE INVITE

        case loadingActions.resendCarShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.resendCarShareInviteSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.resendCarShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // ACCEPT CAR SHARE INVITE

        case loadingActions.acceptCarShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.acceptCarShareInviteSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.acceptCarShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // DELETE CAR SHARE INVITE

        case loadingActions.deleteCarShareInviteRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.deleteCarShareInviteSuccess.type:
            let current_share_for_invites: IDirectShareOwnedItem | undefined =
                state.cars[action.payload.entity_id].private_shares_owned!
                    .entity_owned_direct_shares_data[action.payload.share_id]

            let current_all_share_recipients_filtered_for_invite_deletion =
                current_share_for_invites.all_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            let current_external_share_recipients_filtered_for_invite_deletion =
                current_share_for_invites.external_share_recipients_ids_list.filter(
                    (id) => id !== action.payload.invite_id
                )

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.entity_id]: {
                        ...state.cars[action.payload.entity_id],
                        private_shares_owned: {
                            ...state.cars[action.payload.entity_id]
                                .private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...state.cars[action.payload.entity_id]
                                    .private_shares_owned
                                    ?.entity_owned_direct_shares_data,
                                [action.payload.share_id]: {
                                    ...current_share_for_invites,
                                    external_share_recipients_ids_list: [
                                        ...current_external_share_recipients_filtered_for_invite_deletion,
                                    ],
                                    all_share_recipients_ids_list: [
                                        ...current_all_share_recipients_filtered_for_invite_deletion,
                                    ],
                                },
                            },
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case errorActions.deleteCarShareInviteError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.updateCarWithShowroomData.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        showroom_entry: {
                            ...state.cars[action.payload.carid].showroom_entry,
                            uid: action.payload.showroom_uid,
                            type_of_sale: action.payload.type_of_sale,
                            state: action.payload.state,
                        },
                    },
                },
                loading: false,
                error: null,
            })

        // CLEAN UP CAR DATA ON LOGOUT

        case actions.cleanUpCarDataOnLogoutSuccess.type:
            return Object.assign({}, state, {
                cars: {},
                directly_shared_ext_cars: {},
                currentCarID: undefined,
                currentCarID_ext_directShare: undefined,
                tasks: {},
                error: null,
                loading: false,
                carSharesLoading: false,
            })

        // ARCHIVE CAR

        case loadingActions.archiveCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case actions.archiveCarSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })

        case errorActions.archiveCarError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SHOWROOM ENTRY REMOVED SYNC

        case actions.setCarShowroomEntryDeleted.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload]: {
                        ...state.cars[action.payload],
                        showroom_entry: undefined,
                    },
                },
                loading: false,
                error: null,
            })

        case actions.createHandoverSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.car_id]: {
                        ...state.cars[action.payload.car_id],
                        handover: action.payload.handover
                            ? { ...action.payload.handover }
                            : undefined,
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.createHandoverRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.createHandoverError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.deleteHandoverSuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload]: {
                        ...state.cars[action.payload],
                        handover: undefined,
                    },
                },
                loading: false,
                error: null,
            })

        case actions.resetCarInsights.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload]: {
                        ...state.cars[action.payload],
                        insights: undefined,
                    },
                },
                loading: false,
                error: null,
            })

        case loadingActions.deleteHandoverRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.deleteHandoverError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.updateCarTasksArrayOnTasksCreationOrDeletion.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.car_id]: action.payload.tasks_ids,
                },
                loading: false,
                error: null,
            })

        case actions.getLastCarInsuranceEntrySuccess.type:
            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...state.cars[action.payload.carid],
                        last_insurance_entry: action.payload.timelineItem,
                    },
                },
            })

        case loadingActions.getCarInsightsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case loadingActions.getCarCategoryInsightsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.getCarInsightsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case actions.getCarInsightsSuccess.type:
            let carInsightsData = state.cars[action.payload.carid]?.insights
                ? { ...state.cars[action.payload.carid].insights }
                : {}
            let carSCurr = state.cars[action.payload.carid]
                ? { ...state.cars[action.payload.carid] }
                : {
                      id: action.payload.carid,
                  }

            return Object.assign({}, state, {
                ...state,
                loading: false,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...carSCurr,
                        insights: {
                            ...carInsightsData,
                            [action.payload.timeRange]: {
                                ...action.payload.data,
                            },
                        },
                    },
                },
            })

        case actions.getCategoryInsightsSuccess.type:
            let carInsightsData2 = state.cars[action.payload.carid].insights
                ? { ...state.cars[action.payload.carid].insights }
                : {}

            let carInsightsDataCategory =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].insights &&
                state.cars[action.payload.carid].insights?.categories &&
                state.cars[action.payload.carid].insights?.categories![
                    action.payload.categoryID
                ]
                    ? {
                          ...state.cars[action.payload.carid]!.insights!
                              .categories![action.payload.categoryID]!,
                      }
                    : {}

            let carInsightsDataCategoryTimeType =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].insights &&
                state.cars[action.payload.carid].insights?.categories &&
                state.cars[action.payload.carid].insights?.categories![
                    action.payload.categoryID
                ] &&
                state.cars[action.payload.carid].insights?.categories![
                    action.payload.categoryID
                ][action.payload.timeRange]
                    ? {
                          ...state.cars[action.payload.carid].insights
                              ?.categories![action.payload.categoryID][
                              action.payload.timeRange
                          ],
                      }
                    : {}

            let carInsightsDataCategories =
                state.cars[action.payload.carid] &&
                state.cars[action.payload.carid].insights &&
                state.cars[action.payload.carid].insights?.categories &&
                state.cars[action.payload.carid].insights?.categories
                    ? {
                          ...state.cars[action.payload.carid]!.insights!
                              .categories,
                      }
                    : {}

            let carSCurr2 = state.cars[action.payload.carid]
                ? { ...state.cars[action.payload.carid] }
                : {
                      id: action.payload.carid,
                  }

            return Object.assign({}, state, {
                ...state,
                loading: false,
                cars: {
                    ...state.cars,
                    [action.payload.carid]: {
                        ...carSCurr2,
                        insights: {
                            ...carInsightsData2,
                            categories: {
                                ...carInsightsDataCategories,
                                [action.payload.categoryID]: {
                                    ...carInsightsDataCategory,
                                    [action.payload.timeRange]: {
                                        ...carInsightsDataCategoryTimeType,
                                        [action.payload.representation]: action
                                            .payload.data
                                            ? [...action.payload.data]
                                            : undefined,
                                    },
                                },
                            },
                        },
                    },
                },
            })

        // should be non blocking as more important stuff
        case loadingActions.getLastCarInsuranceEntryRequest.type:
            return Object.assign({}, state, {
                // should be non blocking as more important stuff
                ...state,
            })

        case errorActions.getLastCarInsuranceEntryError.type:
            return Object.assign({}, state, {
                ...state,
                // should be non blocking as more important stuff
            })

        case actions.removeNewCarDuplicate.type:
            let carsCopy = { ...state.cars }
            delete carsCopy['new_car']

            return Object.assign({}, state, {
                ...state,
                cars: {
                    ...carsCopy,
                },
            })

        default:
            return state
    }
}

export default carsData

export type CarsState = ReturnType<typeof carsData>
