import {
    IDirectShareOwnedItem_Api_payload,
    IDirectShareRecipient_item_API,
} from 'ApiInterfaces'
import { IDirectShareOwnedItem, IReduxError } from 'entityModels'
import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    ConvertSingleDirectShare_owned,
    IConvertSingleDirectShareOwnedArg,
} from '../../../conversions/shares/convertDirectShares'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../actions'
import { IgetSingleDirectShareRequest_redux } from '../actions/loadingActions'
import { GetOwnedDirectSharesCar } from './getCarDirectShares'
import { GetPublicCarShareSaga } from './getCarPublicShares'

export function* GetAllSharesCarSaga(carid: string) {
    try {
        // get public shares
        yield call(GetPublicCarShareSaga, carid)

        // get private shares
        yield call(GetOwnedDirectSharesCar, carid)

        // notify all good
        yield put(actions.actions.getAllCarSharesSuccess())
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetAllSharesCarSaga,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(actions.errorActions.getPublicSharesCarError(typedError))
            return
        }
    }
}

function* watcherGetAllSharesCar() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getAllCarSharesRequest
        )
        yield call(GetAllSharesCarSaga, payload)
    }
}

export function* GetSingleCarDirectShare(
    p: IgetSingleDirectShareRequest_redux
) {
    try {
        // get public shares
        let share_api_res: IDirectShareOwnedItem_Api_payload = yield call(
            api.sharing.getSingleShareAPI,
            {
                share_id: p.share_id,
                share_type: 'direct_share',
            }
        )

        // here get list of recipients of that share.

        let share_recipients_list_from_api: IDirectShareRecipient_item_API[] =
            yield call(api.sharing.getShareRecipientsListAPI, p.share_id)

        let p_conv: IConvertSingleDirectShareOwnedArg = {
            api_payload: share_api_res,
            recipients: share_recipients_list_from_api,
        }

        const direct_share_state: IDirectShareOwnedItem =
            ConvertSingleDirectShare_owned(p_conv)

        yield put(
            actions.actions.getSingleCarDirectShareSuccess({
                id: p.entity_id,
                direct_share: direct_share_state,
            })
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetAllSharesCarSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.getSingleCarDirectShareError(typedError)
            )
            return
        }
    }
}

function* watcherGetSingleCarShare() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getSingleCarDirectShareRequest
        )
        yield call(GetSingleCarDirectShare, payload)
    }
}

const all_shares_car_saga: any[] = [
    fork(watcherGetAllSharesCar),
    fork(watcherGetSingleCarShare),
]

export default all_shares_car_saga
