import { IReduxError } from 'entityModels'
import { IUploadAttachmentsPreSignedUrlsPayloadAPI } from 'IapiDataPayload'
import { IUploadAttachmentPreSignedUrlPayloadSaga } from 'myModels'
import { call, fork, put, take } from 'redux-saga/effects'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { IobjBySuffixDataItem } from '../../../timeline/sagas/manageAddAttachmentsToEntry'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../dispatched/index'

export function* getPreSignedUrlsEntryAttachments(
    payload: IUploadAttachmentPreSignedUrlPayloadSaga
) {
    try {
        const result: ResponseGenerator =
            yield api.fileStorage.get_upload_presigned_urls_entry_attachment_API(
                payload
            )

        const data: IUploadAttachmentsPreSignedUrlsPayloadAPI = result.data

        if (data) {
            if (payload.objBySuffixToPopulate) {
                let objn2: IobjBySuffixDataItem = {
                    ...payload.objBySuffixToPopulate,
                    presigned_urls: data,
                }
                return objn2
            } else if (!payload.objBySuffixToPopulate) {
                yield put(
                    actions.successActions.getPresignedUrlsEntryAttachmentsSuccess(
                        data
                    )
                )
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: getPreSignedUrlsEntryAttachments,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.getPresignedUrlEntryAttachmentFileStorageError(
                    typedError
                )
            )
        }
    }
}

function* watchGetPreSignedUrlEntryAttachment() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.getPresignedUrlsEntryAttachmentsRequest
        )

        let p: IUploadAttachmentPreSignedUrlPayloadSaga = {
            carID: payload.carID,
            fileSuffix: payload.fileSuffix,
            entityID: payload.entityID,
            count: payload.count,
        }
        yield call(getPreSignedUrlsEntryAttachments, p)
    }
}

const filestorage_sagas_presigned_urls_attachments: any[] = [
    fork(watchGetPreSignedUrlEntryAttachment),
]

export default filestorage_sagas_presigned_urls_attachments
