import styled from 'styled-components'
import { useHistory } from 'react-router'
import { IJourneySectionID } from 'journeyInterfaces'
import { journey_colours } from '../../../../providers/theme/colours'
import { journeyRedirectCardsCopy } from '../../../../redux/localdata/journey/copyData'
import { device } from '../../../templates/displays/devices'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import { JourneyColumnContent } from '../../../templates/styledcomponents/journeyStyles'
import JourneyPatternCardNextUpMobile from '../../cards/journey/patternCards/patternCardNextUpMobile'
import useThemes from '../../../../providers/theme/hooks'

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 26px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

const Wrap = styled.div`
    display: flex;
    display: flex;
    width: 90vw;
    max-width: 90vw;
    padding: 32px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    background: var(--text-strong, #1a1a1a);
    padding-bottom: 150px;
    overflow: hidden;
    @media (max-width: 500px) {
        padding-bottom: 120px;
    }
`

const Container = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`

const WrapTxt = styled.div`
    color: var(--bg-color, #fff);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 150% */
    letter-spacing: 0.001px;
    max-width: 450px;
    overflow: hidden;
`

const ImageStyle = styled.img`
    align-self: center;
    overflow: hidden;
    object-fit: contain;
    height: 100%;
    width: 100%;
`
const ImgWrap = styled.div`
    transform: translate(10px, -100px);
    align-self: center;
    overflow: hidden;

    max-width: 500px;
    width: 98vw;

    @media ${device.mobile} {
        transform: translate(20px, -100px);
    }

    @media ${device.ipad} {
        transform: translate(20px, -100px);
    }
`

const JourneySectionWrapperCustom = styled.section`
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
`

export const JourneyAchievedStepMobile = ({
    sectionID,
    nextSectionID,
    nextSectionPageName,
    image_src_mobile,
    lightTextCopy,
}: {
    lightTextCopy: string
    darkTextCopy: string
    image_src_desktop: string
    image_src_mobile: string
    sectionID: IJourneySectionID
    nextSectionID: IJourneySectionID
    nextSectionPageName: string
}) => {
    const history = useHistory()
    const { theme } = useThemes()

    return (
        <IpadAndMobileDisplay>
            <JourneySectionWrapperCustom
                style={{
                    paddingTop: '40px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                }}
            >
                <JourneyColumnContent
                    style={{
                        gap: '0x',
                        overflow: 'hidden',
                    }}
                >
                    <Container>
                        <JourneyColumnContent>
                            <Title>
                                Objective{' '}
                                <span
                                    style={{
                                        color: journey_colours[theme].section[
                                            sectionID
                                        ].primary_500,
                                        paddingLeft: '10px',
                                    }}
                                >
                                    completed!
                                </span>
                            </Title>

                            <div style={{ paddingTop: '5vh' }} />

                            <Wrap>
                                <WrapTxt>{lightTextCopy}</WrapTxt>
                            </Wrap>
                        </JourneyColumnContent>
                    </Container>

                    <ImgWrap
                        style={
                            sectionID === 'sell_your_car'
                                ? { transform: 'translate(0px, -100px)' }
                                : {}
                        }
                    >
                        <ImageStyle src={image_src_mobile} />
                    </ImgWrap>
                </JourneyColumnContent>

                <div style={{ paddingTop: '80px' }} />

                <JourneyPatternCardNextUpMobile
                    sectionID={nextSectionID}
                    copyData={journeyRedirectCardsCopy}
                    onNext={() => {
                        history.push({
                            pathname: `/journey/${nextSectionPageName}`,
                            search: `?step=welcome`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            </JourneySectionWrapperCustom>
        </IpadAndMobileDisplay>
    )
}
