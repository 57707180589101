import { useEffect, useState, useRef, RefObject } from 'react'

type ScrollDirection = 'up' | 'down' | null

const usePageScrollDirection = (): ScrollDirection => {
    const [scrollDirection, setScrollDirection] =
        useState<ScrollDirection>(null)
    const [prevScrollPos, setPrevScrollPos] = useState(0)

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos =
                window.scrollY || document.documentElement.scrollTop
            if (currentScrollPos > prevScrollPos) {
                setScrollDirection('down')
            } else if (currentScrollPos < prevScrollPos) {
                setScrollDirection('up')
            }
            setPrevScrollPos(currentScrollPos)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return scrollDirection
}

const useScrollDirectionInsideADiv = (
    ref: RefObject<HTMLDivElement>
): ScrollDirection => {
    const [scrollDirection, setScrollDirection] =
        useState<ScrollDirection>(null)
    const prevScrollPos = useRef(0)

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = ref.current?.scrollTop || 0
            if (currentScrollPos > prevScrollPos.current) {
                setScrollDirection('down')
            } else if (currentScrollPos < prevScrollPos.current) {
                setScrollDirection('up')
            }
            prevScrollPos.current = currentScrollPos
        }

        const element = ref.current
        if (element) {
            element.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (element) {
                element.removeEventListener('scroll', handleScroll)
            }
        }
    }, [ref])

    return scrollDirection
}

const useCheckIfTopPage = (customTopPos?: number): boolean => {
    const [scrollPosition, setScrollPosition] = useState<number | null>(
        window.scrollY || document.documentElement.scrollTop
    )

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos =
                window.scrollY || document.documentElement.scrollTop

            setScrollPosition(currentScrollPos)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    let pos = customTopPos ?? 0

    return typeof scrollPosition === 'number' && scrollPosition <= pos
        ? true
        : false
}

export {
    usePageScrollDirection,
    useScrollDirectionInsideADiv,
    useCheckIfTopPage,
}
