import { ActionType } from 'typesafe-actions'

import { shortlisted_features_list, section_one_copy_list, what_we_built } from './data'

import * as actions from './actions/actions'
import { IUpcomingPageState } from 'upcomingPageModels'

type Actions = ActionType<typeof actions>

const initialState: IUpcomingPageState = {
    section_one_list: section_one_copy_list,
    upcoming_shortlisted_features_list: shortlisted_features_list,
    upcoming_what_weBuilt: what_we_built,
    upvotes_normalised: {},
}

export const upcoming_page = (
    state: IUpcomingPageState = initialState,
    action: Actions
) => {
    switch (action.type) {
        case actions.upvoteShortlistedFeature.type:
            return Object.assign({}, state, {
                ...state,
                upvotes_normalised: {
                    ...state.upvotes_normalised,
                    [action.payload.id]: action.payload,
                },
            })
        default:
            return state
    }
}

export default upcoming_page

export type UpcomingPageState = ReturnType<typeof upcoming_page>
