import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IDropdownItem } from 'entityModels'
import BottomUpSliderWithExit from '../../../templates/animated/bottomUpSliderWithExit'
import ScrollLock from 'react-scrolllock'
import Icon from '../../icons'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

export type Props = {
    dropdownData: IDropdownItem[]
    dataCyId?: string
    isDisabled?: boolean
    buttonMinWidth?: string
    onSelected?: (value: string) => void
    currentOption?: string | null
    isVisible: boolean
    close: () => void
}

type IStyle = {
    $selected?: boolean
    $theme: ITheme
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    height: 56px;
    background-color: transparent;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    font-family: Lato;
    color: ${(props) => colours[props.$theme].text_default};
`

const Header = styled.div<IStyle>`
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 40px;
    font-size: 16px;
    font-family: Lato-semibold;
    border-bottom: 1px solid ${(props) => colours[props.$theme].border_muted};
    color: ${(props) => colours[props.$theme].text_strong};
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    text-transform: capitalize;
`

const Handle = styled.div<IStyle>`
    height: 4px;
    width: 60px;
    background-color: ${(props) => colours[props.$theme].text_darker};
    border-radius: 10px;
`

const SortMenuMobile: React.FunctionComponent<Props> = (props: Props) => {
    const {
        dataCyId,
        currentOption,
        dropdownData,
        onSelected,
        isVisible,
        close,
    } = props

    const [currentSelected, setCurrentSellected] = useState(
        currentOption
            ? currentOption
            : dropdownData
            ? dropdownData[0].name
            : undefined
    )

    useEffect(() => {
        setCurrentSellected(currentOption)
        return () => {
            setCurrentSellected(undefined)
        }
    }, [currentOption])

    const { theme } = useThemes()

    let mappedDropdownData =
        dropdownData &&
        dropdownData.map((item: IDropdownItem, index: number) => {
            if (item) {
                return (
                    <StyledOption
                        $theme={theme}
                        $selected={currentSelected === item.uid}
                        value={item.name}
                        key={`${item.name}-${index}-mobile`}
                        aria-label={item.name}
                        onClick={() => (onSelected ? onSelected(item.uid) : {})}
                    >
                        <ContentRow>{item.name}</ContentRow>
                        {currentSelected === item.uid ? (
                            <Icon icon="blue_check" height="10px" />
                        ) : null}
                    </StyledOption>
                )
            } else {
                return null
            }
        })

    return (
        <BottomUpSliderWithExit
            isVisible={isVisible}
            onBackdropClick={close}
            customHeight={'384px'}
            customZIndex={10}
        >
            <ScrollLock />
            <Handle $theme={theme} />
            <Header $theme={theme} onClick={(e: any) => e.stopPropagation()}>
                Sort by
            </Header>
            <Wrapper data-attr={dataCyId}>
                {dropdownData && dropdownData.length > 0 && mappedDropdownData}
            </Wrapper>
        </BottomUpSliderWithExit>
    )
}

export default SortMenuMobile
