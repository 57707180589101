import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const ParkingIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.75 21.75L2.25002 21.75L2.25002 2.25L21.75 2.25L21.75 21.75ZM20.25 20.25L20.25 3.75L3.75002 3.75L3.75002 20.25L20.25 20.25Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.75 7C8.75 6.58579 9.08579 6.25 9.5 6.25H12.0714C14.3008 6.25 16.25 7.8891 16.25 10.0769C16.25 12.2647 14.3008 13.9038 12.0714 13.9038H10.25V17C10.25 17.4142 9.91421 17.75 9.5 17.75C9.08579 17.75 8.75 17.4142 8.75 17V7ZM10.25 12.4038H12.0714C13.6292 12.4038 14.75 11.2878 14.75 10.0769C14.75 8.86607 13.6292 7.75 12.0714 7.75H10.25V12.4038Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default ParkingIcon
