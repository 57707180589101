import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import Expander from '../../../../atoms/expander/expander'
import Faded from '../../../../templates/animated/faded'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../providers/theme/hooks'
// import ButtonAtom from '../../../../atoms/Button/ButtonAtom'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 14px;
    @media ${device.ipad} {
        font-size: 12px;
    }
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_basicTechInfoDesktop: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id: string
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        handleSubmit,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let checkIfDisabled = () => {
        if (!values.single_item_edited_value_basicTechInfoDesktop) {
            return true
        } else if (
            errors.single_item_edited_value_basicTechInfoDesktop !== undefined
        ) {
            return true
        } else if (
            values.single_item_edited_value_basicTechInfoDesktop &&
            values.single_item_edited_value_basicTechInfoDesktop.trim().length <
                1
        ) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop style={{ marginTop: '20vh' }} $theme={theme}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperDesktop>
                <Padding5 />
                <InputField
                    theme={theme}
                    id="single_item_edited_value_basicTechInfoDesktop"
                    name="single_item_edited_value_basicTechInfoDesktop"
                    placeholder={item.text}
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.single_item_edited_value_basicTechInfoDesktop}
                    font_family="Lato"
                    textTransform={
                        item.id === 'registration_number' ? 'uppercase' : 'none'
                    }
                    onEnterKeyPress={props.handleSubmit}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
                <Expander
                    height={
                        values.single_item_edited_value_basicTechInfoDesktop
                            .length > 1 &&
                        errors.single_item_edited_value_basicTechInfoDesktop
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {
                                errors.single_item_edited_value_basicTechInfoDesktop
                            }
                        </Error>
                    </Faded>
                </Expander>
                <Padding40 />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const BasicTechInfoEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_basicTechInfoDesktop: props.item.answer
            ? props.item.answer
            : '',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_basicTechInfoDesktop: Yup.string()
                    .max(20, 'Your answer must be under 20 characters')
                    .min(2),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        if (
            props.submitFunc &&
            values.single_item_edited_value_basicTechInfoDesktop.trim().length >
                1
        ) {
            let payload: IwriteTechnicalInformationPayloadReq = {
                tech_info_id: props.tech_info_id,
                car_id: props.carid,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answer: values.single_item_edited_value_basicTechInfoDesktop,
                },
            }

            props.submitFunc(payload)
        }
        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(BasicTechInfoEditFormDesktop)
