import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import EditIcon from '../statefulicons/editIcon'

const BoldTitle = styled.h2`
    padding-top: 28px;
    font-family: 'Lato-Bold';
    font-size: 18px;
    text-transform: capitalize;

    @media ${device.ipad} {
        font-size: 18px;
        padding-bottom: 10px;
    }
`

const Edit = styled.div`
    padding-left: 20px;
    cursor: pointer;
`

const SectionHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

const EditSmallIcon = styled.div`
    transform: translate(-6px, 15px);
`

interface ITitleProps {
    children: React.ReactNode
    edit?: boolean
    openForm?: any
}

const TitleSimpleBoldMobile: React.SFC<ITitleProps> = (props) => (
    <SectionHeaderWrapper>
        <BoldTitle>{props.children} </BoldTitle>
        {props.edit && (
            <React.Fragment>
                <Edit>
                    <motion.div
                        whileTap={{ scale: 1.2 }}
                        onClick={(e: any) => {
                            e.preventDefault()
                            props.openForm()
                        }}
                    >
                        <EditSmallIcon>
                            <EditIcon onClick={() => {}} />
                        </EditSmallIcon>
                    </motion.div>
                </Edit>
            </React.Fragment>
        )}
    </SectionHeaderWrapper>
)

export default TitleSimpleBoldMobile
