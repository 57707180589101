import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    colour?: string
}

const LastUpdateIcon = ({ size, colour }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '17'}
            height={size ?? '17'}
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.18879 4.02222C5.43927 4.02222 3.211 6.25049 3.211 9C3.211 10.5751 3.94137 11.9788 5.0848 12.892L5.67083 13.3601L4.73473 14.5321L4.1487 14.0641C2.66413 12.8783 1.711 11.0503 1.711 9C1.711 5.42206 4.61084 2.52222 8.18879 2.52222H8.93879V4.02222H8.18879Z"
                fill={colour ?? colours[theme].text_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.55501 0.861312L10.0299 3.33619L7.55501 5.81106L6.49435 4.7504L7.90856 3.33619L6.49435 1.92197L7.55501 0.861312Z"
                fill={colour ?? colours[theme].text_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.14208 13.9778C11.8916 13.9778 14.1199 11.7495 14.1199 8.99999C14.1199 7.35907 13.327 5.90383 12.1003 4.99589L11.4974 4.5497L12.3898 3.34402L12.9927 3.79021C14.5852 4.96891 15.6199 6.86359 15.6199 8.99999C15.6199 12.5779 12.72 15.4778 9.14208 15.4778H8.39208V13.9778H9.14208Z"
                fill={colour ?? colours[theme].text_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.60301 17.1083L7.12814 14.6334L9.60301 12.1585L10.6637 13.2192L9.24946 14.6334L10.6637 16.0476L9.60301 17.1083Z"
                fill={colour ?? colours[theme].text_default}
            />
        </svg>
    )
}

export default LastUpdateIcon
