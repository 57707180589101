import styled from 'styled-components'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { IShowroomFiltersState } from '../../../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import Expander from '../../../atoms/expander/expander'
import CustomTagQuickFilterItemDesktop from '../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import { useEffect, useRef } from 'react'
import { device } from '../../../templates/displays/devices'

type IStyleProps = {
    $isMenuCollapsed?: boolean
}

const Wrap = styled.div<IStyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--off-bg-color, #fafafa);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: -1;
    transition: all 100ms;

    /* padding-left: ${(props) =>
        props.$isMenuCollapsed ? '90px' : '30px'}; */

    /* @media (min-width: 1400px) {
        padding-left: ${(props) => (props.$isMenuCollapsed ? '110px' : '50px')};
    }

    @media (min-width: 1500px) {
        padding-left: ${(props) => (props.$isMenuCollapsed ? '110px' : '50px')};
    }
    @media (min-width: 1600px) {
        padding-left: ${(props) => (props.$isMenuCollapsed ? '24px' : '85px')};
    }
    @media (min-width: 1800px) {
        padding-left: ${(props) => (props.$isMenuCollapsed ? '24px' : '110px')};
    }
    @media (min-width: 2000px) {
        padding-left: ${(props) => (props.$isMenuCollapsed ? '24px' : '120px')};
    }
    @media (min-width: 2200px) {
        padding-left: ${(props) => (props.$isMenuCollapsed ? '24px' : '145px')};
    }
    @media (min-width: 2500px) {
        padding-left: ${(props) => (props.$isMenuCollapsed ? '24px' : '180px')};
    }

    @media (min-width: 2790px) {
        padding-left: ${(props) =>
        props.$isMenuCollapsed ? '280px' : '200px'};
    } */
`

const SubWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 12px;
    @media ${device.desktop} {
        max-width: 83vw;
        padding-left: 10px;
    }

    @media ${device.large_desktop} {
        max-width: 80vw;
        padding-left: 30px;
    }

    @media screen and (min-width: 1900px) {
        max-width: 1600px;
    }
    @media screen and (min-width: 2300px) {
        max-width: 1800px;
    }

    @media ${device.smallest_laptops} {
        max-width: 80vw;
        /* padding-left: 20px; */
        padding-right: 20px;
    }
`
const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding-right: 30px;
    flex-wrap: wrap;
    padding-top: 10px;
    padding-bottom: 10px;
`

const FilterName = styled.div`
    color: #b3b3b3;
    font-size: 12px;
    font-family: Lato;
    text-transform: uppercase;
    padding-right: 10px;
`

type Props = {
    manageQueryParamRemoval: (id: string, val?: any) => any
    handleChangeSearchEffects: () => any
    page: 'sale' | 'sold'
    currentTopPadding: number
    updateTopPadding: (height: number) => void
}
const ShowroomDismissableFiltersBarDesktop = (props: Props) => {
    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.filters
    })

    let isThereActiveFilter = Object.values(filtersState).some(
        (val) => val.isActive === true
    )
    let isMenuCollapsed: boolean = useAppSelector((state) => {
        return state.menus.desktopMainAppMenu.isCollapsed
    })

    let dispatch = useAppDispatch()

    let {
        manageQueryParamRemoval,
        handleChangeSearchEffects,
        page,
        currentTopPadding,
        updateTopPadding,
    } = props

    const filterDivRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (filterDivRef.current) {
            let updatedPadding = filterDivRef.current?.offsetHeight
            if (updatedPadding !== currentTopPadding) {
                updateTopPadding(updatedPadding)
            }
        }
    }, [filtersState])

    return (
        <div style={{ width: '100%' }}>
            <Expander height={isThereActiveFilter ? 'auto' : 0}>
                <Wrap $isMenuCollapsed={isMenuCollapsed} ref={filterDivRef}>
                    <SubWrapper>
                        {Object.keys(filtersState).map(
                            (id: string, index: number) => {
                                if (
                                    id === 'keyword' &&
                                    filtersState[id].isActive === true
                                ) {
                                    return (
                                        <Row>
                                            <FilterName>
                                                {filtersState.keyword?.name}
                                            </FilterName>

                                            {
                                                // @ts-ignore
                                                filtersState.keyword.values.map(
                                                    (
                                                        val: string,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <>
                                                                <CustomTagQuickFilterItemDesktop
                                                                    key={`${index}-${id}`}
                                                                    height="24px"
                                                                    onCrossClick={() => {
                                                                        dispatch(
                                                                            showroomActions.removeMultiSelectItem_request(
                                                                                {
                                                                                    //@ts-ignore
                                                                                    id: id,
                                                                                    value: val,
                                                                                    isDirectSearchMode:
                                                                                        true,
                                                                                }
                                                                            )
                                                                        )

                                                                        manageQueryParamRemoval(
                                                                            'keyword',
                                                                            val
                                                                        )

                                                                        setTimeout(
                                                                            () => {
                                                                                handleChangeSearchEffects()
                                                                                // dispatch(
                                                                                //     showroomActions.searchShowroomRequest()
                                                                                // )
                                                                            },
                                                                            100
                                                                        )
                                                                    }}
                                                                    bgcolor={
                                                                        '#FFFFFF'
                                                                    }
                                                                    hasBoxShadow={
                                                                        true
                                                                    }
                                                                >
                                                                    {val}
                                                                </CustomTagQuickFilterItemDesktop>
                                                                <div
                                                                    style={{
                                                                        paddingRight:
                                                                            '10px',
                                                                    }}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                )
                                            }
                                        </Row>
                                    )
                                }

                                if (
                                    id === 'location' &&
                                    filtersState[id].isActive === true &&
                                    filtersState.location.displayValue &&
                                    filtersState.location.displayValue.length >
                                        0
                                ) {
                                    return (
                                        <Row>
                                            <FilterName>
                                                {filtersState.location?.name}
                                            </FilterName>

                                            {filtersState.location
                                                .displayValue &&
                                            filtersState.location.displayValue
                                                .length > 0 ? (
                                                <div
                                                    style={{
                                                        paddingRight: '10px',
                                                    }}
                                                >
                                                    <CustomTagQuickFilterItemDesktop
                                                        key={`${index}-${id}`}
                                                        height="24px"
                                                        onCrossClick={() => {
                                                            dispatch(
                                                                showroomActions.setLocation_filter(
                                                                    {
                                                                        values: undefined,
                                                                        display_value:
                                                                            '',
                                                                    }
                                                                )
                                                            )

                                                            manageQueryParamRemoval(
                                                                'location'
                                                            )

                                                            setTimeout(() => {
                                                                handleChangeSearchEffects()
                                                                // dispatch(
                                                                //     showroomActions.searchShowroomRequest()
                                                                // )
                                                            }, 100)
                                                        }}
                                                        bgcolor={'#FFFFFF'}
                                                        hasBoxShadow={true}
                                                    >
                                                        {
                                                            filtersState
                                                                .location
                                                                .displayValue
                                                        }{' '}
                                                        {filtersState.location
                                                            .values?.distance
                                                            ? ` - Distance: ${filtersState.location.values?.distance}`
                                                            : ''}
                                                    </CustomTagQuickFilterItemDesktop>
                                                </div>
                                            ) : null}
                                        </Row>
                                    )
                                } else if (
                                    id === 'owner' &&
                                    filtersState[id]?.isActive
                                ) {
                                    return (
                                        <Row>
                                            <FilterName>
                                                {
                                                    //@ts-ignore
                                                    filtersState[id]?.name
                                                }
                                            </FilterName>
                                            <CustomTagQuickFilterItemDesktop
                                                key={index}
                                                txtColor={'#fff'}
                                                height="24px"
                                                onCrossClick={() => {
                                                    manageQueryParamRemoval(
                                                        'owner'
                                                    )

                                                    dispatch(
                                                        showroomActions.removeFilterRequest(
                                                            {
                                                                //@ts-ignore
                                                                id: id,
                                                                direct_search:
                                                                    true,
                                                            }
                                                        )
                                                    )

                                                    setTimeout(() => {
                                                        handleChangeSearchEffects()
                                                        dispatch(
                                                            showroomActions.searchShowroomRequest(
                                                                {
                                                                    states:
                                                                        page ===
                                                                        'sold'
                                                                            ? 'SOLD'
                                                                            : 'PUBLISHED',
                                                                    freeze_total_count:
                                                                        true,
                                                                }
                                                            )
                                                        )
                                                    }, 100)
                                                }}
                                                bgcolor={'#000000'}
                                                hasBoxShadow={true}
                                            >
                                                {
                                                    // @ts-ignore
                                                    filtersState[id]
                                                        .displayValue
                                                }
                                            </CustomTagQuickFilterItemDesktop>
                                        </Row>
                                    )
                                } else if (
                                    // @ts-ignore
                                    filtersState[id].isActive === true &&
                                    // @ts-ignore
                                    filtersState[id].type !== 'multiselect'
                                ) {
                                    return (
                                        <Row>
                                            <FilterName>
                                                {
                                                    //@ts-ignore
                                                    filtersState[id]?.name
                                                }
                                            </FilterName>
                                            <CustomTagQuickFilterItemDesktop
                                                key={index}
                                                height="24px"
                                                onCrossClick={() => {
                                                    dispatch(
                                                        showroomActions.removeFilterRequest(
                                                            {
                                                                //@ts-ignore
                                                                id: id,
                                                                direct_search:
                                                                    true,
                                                            }
                                                        )
                                                    )

                                                    manageQueryParamRemoval(id)

                                                    setTimeout(() => {
                                                        handleChangeSearchEffects()

                                                        // dispatch(
                                                        //     showroomActions.searchShowroomRequest()
                                                        // )
                                                    }, 100)
                                                }}
                                                bgcolor={'#FFFFFF'}
                                                hasBoxShadow={true}
                                            >
                                                {
                                                    // @ts-ignore
                                                    filtersState[id]
                                                        .displayValue
                                                }
                                            </CustomTagQuickFilterItemDesktop>
                                        </Row>
                                    )
                                } else if (
                                    // @ts-ignore
                                    filtersState[id].isActive === true &&
                                    // @ts-ignore
                                    filtersState[id].type === 'multiselect' &&
                                    // @ts-ignore
                                    filtersState[id].values &&
                                    // @ts-ignore
                                    filtersState[id].values.length > 0
                                ) {
                                    return (
                                        <Row>
                                            <FilterName>
                                                {
                                                    //@ts-ignore
                                                    filtersState[id]?.name
                                                }
                                            </FilterName>

                                            {
                                                // @ts-ignore
                                                filtersState[id].values.map(
                                                    (
                                                        valID: string,
                                                        index: number
                                                    ) => {
                                                        let nameee =
                                                            // @ts-ignore
                                                            filtersState[id]
                                                                ?.nameValuesOBJ &&
                                                            Object.keys(
                                                                // @ts-ignore
                                                                filtersState[id]
                                                                    ?.nameValuesOBJ
                                                            ).length > 0
                                                                ? // @ts-ignore
                                                                  filtersState[
                                                                      id
                                                                  ]
                                                                      ?.nameValuesOBJ[
                                                                      valID
                                                                  ]?.name
                                                                : // @ts-ignore
                                                                filtersState[id]
                                                                      .values
                                                                ? // @ts-ignore
                                                                  valID
                                                                : undefined

                                                        if (nameee) {
                                                            return (
                                                                <>
                                                                    <CustomTagQuickFilterItemDesktop
                                                                        key={`${index}-${id}`}
                                                                        height="24px"
                                                                        onCrossClick={() => {
                                                                            dispatch(
                                                                                showroomActions.removeMultiSelectItem_request(
                                                                                    {
                                                                                        //@ts-ignore
                                                                                        id: id,
                                                                                        value: valID,
                                                                                        isDirectSearchMode:
                                                                                            true,
                                                                                    }
                                                                                )
                                                                            )
                                                                            manageQueryParamRemoval(
                                                                                id,
                                                                                valID
                                                                            )

                                                                            setTimeout(
                                                                                () => {
                                                                                    handleChangeSearchEffects()

                                                                                    // CHANGE HERE
                                                                                    dispatch(
                                                                                        showroomActions.searchShowroomRequest()
                                                                                    )
                                                                                },
                                                                                100
                                                                            )
                                                                        }}
                                                                        bgcolor={
                                                                            '#FFFFFF'
                                                                        }
                                                                        hasBoxShadow={
                                                                            true
                                                                        }
                                                                    >
                                                                        {nameee}
                                                                    </CustomTagQuickFilterItemDesktop>
                                                                    <div
                                                                        style={{
                                                                            paddingRight:
                                                                                '10px',
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        } else return null
                                                    }
                                                )
                                            }
                                        </Row>
                                    )
                                } else return null
                            }
                        )}
                    </SubWrapper>
                </Wrap>
            </Expander>
        </div>
    )
}

export default ShowroomDismissableFiltersBarDesktop
