import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router'

import HandoverRequestResendSuccessModal from '../../../molecules/archived/modals/handoverRequestResendSuccessModal'
import HandoverUserChangeConfoModal from '../../../molecules/archived/modals/handoverUserChangeConfoModal'
import HandoverCancellationModal from '../../../molecules/archived/modals/handoverCancellationModal'
import { IHandover } from 'entityModels'
import { useEffect } from 'react'

export const ArchivedGarageModalContainerMobile = styled.div`
    min-height: 100vh;
    width: 100vw;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`

export const ArchivedGarageModalContainerDesktop = styled.div`
    height: max-content;
    width: max-content;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 50px;
    min-width: 400px;
    z-index: 20;
    transition: all 100ms ease-in-out;
`

export type IArchiveActionsModalEnum =
    | 'handover_resend_success'
    | 'handover_change_user_confirmation'
    | 'cancel_handover_confirmation'

type Props = {
    activeModal: IArchiveActionsModalEnum
    handleSetModalOpened: (p: IArchiveActionsModalEnum | null) => any
    carID: string
    cancel_handover: () => any
    handover: IHandover
}

const ArchiveModalsManager = (props: Props & RouteComponentProps) => {
    let { handleSetModalOpened, handover } = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let renderModalBod = () => {
        switch (props.activeModal) {
            case 'handover_resend_success':
                return (
                    <HandoverRequestResendSuccessModal
                        onView={() => {
                            handleSetModalOpened(null)
                        }}
                        handover={handover}
                    />
                )

            case 'handover_change_user_confirmation':
                return (
                    <HandoverUserChangeConfoModal
                        carID={props.carID}
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                        handover={handover}
                    />
                )

            case 'cancel_handover_confirmation':
                return (
                    <HandoverCancellationModal
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                        onConfirm={props.cancel_handover}
                        handover={handover}
                    />
                )
            default:
                return <div />
        }
    }

    return renderModalBod()
}

export default withRouter(ArchiveModalsManager)
