import React from 'react'
import { connect } from 'react-redux'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import {
    RouteComponentProps,
    withRouter,
    Link,
    Redirect,
} from 'react-router-dom'
import ReturnNav from '../../atoms/header/returnNav'
import { RootState } from 'typesafe-actions'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import ModalDisplayGalleryImageDesktop from '../../templates/displays/pageWrappers/modalDisplayGalleryImageDesktop'
import CarGalleryEditExistingImageFormMobile from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormMobile'

import styled from 'styled-components'

import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'

import GallerySlide from '../../molecules/slideshow/gallerySlide'
import {
    ICar,
    IGalleryImagesObject,
    IUpdateCarGalleryImagePayload,
} from 'entityModels'
import {
    deleteCarGalleryImageRequest,
    updateCarGalleryImageRequest,
} from '../../../redux/entities/galleries/actions/loadingActions'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import TutorialConfigsCarGalleryImageDesktop from '../../molecules/tutorialConfigs/car/tutorialConfigsCarGalleryImageDesktop'
import TutorialConfigsCarGalleryImageMobile from '../../molecules/tutorialConfigs/car/tutorialConfigsCarGalleryImageMobile'
import ScrollLock from 'react-scrolllock'

import ImageSubMenuActions from '../../atoms/menu/pageSubMenuActions/imageSubMenuActions'

import { IUser } from 'myModels'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { countFeaturedImages } from '../../../helpers/numbers/countFeaturedImages'
import GalleryImageCarousel from '../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../redux/showroom/types'
import { IGetShowroomByEntry } from '../../../redux/showroom/sagas/getShowroomEntry'
import { showroomActions } from '../../../redux/showroom/reducer'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import colours from '../../../providers/theme/colours'

interface Props extends RouteComponentProps<any> {
    carsData: {
        [key: string]: ICar
    }
    isExistingcarGalleryImageFormOpened: boolean
    toggleExistingCarGalleryImageEditForm: () => {}
    getCarDataByIdRequest: (carid: string) => void
    imagesData: IGalleryImagesObject | null
    isNecessaryGalleryDataLoading: boolean
    updateCarGalleryImageRequest: (data: IUpdateCarGalleryImagePayload) => void
    deleteCarGalleryImageRequest: (ids: Iids) => void
    userLoggedIn: IUser | null
    setInitialLandingUrl: (str: string) => void
    setCurrentCar: (carid: string) => void
    getUserDataRequest: () => void
    userShowroomEntriesData: INormalisedShowroomEntries | undefined
    getShowroomEntryByIDRequest: (p: IGetShowroomByEntry) => void
}

type Iids = {
    carid: string
    imageid: string
}

const dispatchProps = {
    toggleExistingCarGalleryImageEditForm: () =>
        editFormsActions.toggleExistingCarGalleryImageEditForm(),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    updateCarGalleryImageRequest: (data: IUpdateCarGalleryImagePayload) =>
        updateCarGalleryImageRequest(data),
    deleteCarGalleryImageRequest: (ids: Iids) =>
        deleteCarGalleryImageRequest(ids),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    setCurrentCar: (id: string) => setCurrentCar(id),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getShowroomEntryByIDRequest: (p: IGetShowroomByEntry) =>
        showroomActions.getShowroomEntryByIDRequest(p),
}

function mapStateToProps(state: RootState) {
    return {
        imagesData: state.entities.galleriesData.images,
        carsData: state.entities.carsData.cars,
        isCarLoading: state.entities.carsData.loading,
        isGalleryLoading: state.entities.galleriesData.loading,
        isExistingcarGalleryImageFormOpened:
            state.editForms.formsData.carExistingGalleryImageEditForm.isOpen,
        isNecessaryGalleryDataLoading: state.entities.galleriesData.loading,
        userLoggedIn: state.user.userLoggedIn,
        userShowroomEntriesData: state.showroom.userShowroomEntries,
    }
}

type State = {
    orderedIdList: string[]
    showEditImageForm: boolean
    showDeleteConfirmationMessage: boolean
    warningPresent: boolean
    image_index?: number | undefined
}

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    padding-bottom: 5px;
    width: 100%;
    font-family: Lato;
    font-size: 14px;
    color: var(--text-muted);
    padding-bottom: 0px;
`

export const PaddingSpan = styled.span`
    padding-left: 8px;
`

export const RowWrapper = styled.div`
    display: flex;
    margin-top: 15px;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    position: relative;
    width: 100vw;
    padding-left: 15px;
    padding-right: 15px;
`

export const IconAdjust = styled.span`
    margin-top: -1px;
`

export const Container = styled.div`
    height: 70px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    background-color: var(--bg-color, #fff);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 3;
`

export const LinkStyled = styled(Link)`
    flex: 0.5;
`
// NotLinkStyled
export const NotLinkStyled = styled.div`
    flex: 0.5;
`
export const StyledIcon = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const MiddleLine = styled.div`
    height: 100%;
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

const DeleteImageConfirmationWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 88vw;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: georgia;
    z-index: 25;
`

const SuccessDeletionMessage = styled.div`
    font-size: 17px;
`

export const ReturnToGalleryLink = styled(Link)`
    color: var(--primary, #5ec3ca) !important;
    font-size: 14px;
    padding: 10px;
    width: 60vw;
    border: 1px solid var(--primary, #5ec3ca);
    border-radius: 5px;
    margin-top: 30px;
    text-align: center;
    z-index: 15;
`

const SubWrapper = styled.div`
    background-color: var(--off-bg-color, #fafafa);
`

class GalleryImageV2 extends React.Component<Props, State> {
    state = {
        orderedIdList: [],
        showEditImageForm: false,
        showDeleteConfirmationMessage: false,
        warningPresent: false,
        image_index: undefined,
    }

    setUpInitialIndex = () => {
        let params = new URLSearchParams(this.props.location.search)
        let image_id_param = params.get('id')
        if (
            this.props.carsData &&
            this.props.carsData[this.props.match.params.carid] &&
            image_id_param
        ) {
            this.setState({
                ...this.state,
                image_index:
                    this.props.carsData[
                        this.props.match.params.carid
                    ]?.gallery.images.indexOf(image_id_param),
            })
        }
    }
    manageShowroomEntryData = () => {
        let carid: string = this.props.match.params.carid

        let currentCar: ICar | undefined =
            this.props.carsData && carid && this.props.carsData[carid]
                ? this.props.carsData[carid]
                : undefined

        let isCarOwner =
            currentCar &&
            this.props.userLoggedIn &&
            currentCar.owner_id === this.props.userLoggedIn?.id

        if (isCarOwner === true) {
            let hasEntryID: string | undefined =
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'PUBLISHED') ||
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'SOLD')
                    ? currentCar.showroom_entry.uid
                    : undefined

            if (hasEntryID) {
                let currentEntry: IShowroomEntry | undefined =
                    hasEntryID &&
                    this.props.userShowroomEntriesData &&
                    this.props.userShowroomEntriesData[hasEntryID]
                        ? this.props.userShowroomEntriesData[hasEntryID]
                        : undefined

                if (!currentEntry) {
                    this.props.getShowroomEntryByIDRequest({
                        is_owner: true,
                        entryID: hasEntryID,
                    })
                }
            }
        }
    }

    componentDidMount() {
        this.props.setCurrentCar(this.props.match.params.carid)

        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }

        if (userData) {
            let params = new URLSearchParams(this.props.location.search)
            let image_id_param = params.get('id')

            this.props.setInitialLandingUrl(
                `/car/${this.props.match.params.carid}/gallery?id=${image_id_param}`
            )

            if (this.props.isExistingcarGalleryImageFormOpened === true) {
                this.props.toggleExistingCarGalleryImageEditForm()
            }

            let carid: string = this.props.match.params.carid

            this.props.getCarDataByIdRequest(carid)

            this.manageShowroomEntryData()

            this.setUpInitialIndex()
        }
    }

    componentDidUpdate(prevprops: Props) {
        if (prevprops.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn) {
                this.manageShowroomEntryData()

                let params = new URLSearchParams(this.props.location.search)
                let image_id_param = params.get('id')

                this.props.setInitialLandingUrl(
                    `/car/${this.props.match.params.carid}/gallery?id=${image_id_param}`
                )

                if (this.props.isExistingcarGalleryImageFormOpened === true) {
                    this.props.toggleExistingCarGalleryImageEditForm()
                }

                let carid: string = this.props.match.params.carid

                this.props.getCarDataByIdRequest(carid)

                if (
                    this.props.carsData &&
                    this.props.carsData[this.props.match.params.carid] &&
                    image_id_param
                ) {
                    this.setState({
                        ...this.state,
                        image_index:
                            this.props.carsData[
                                this.props.match.params.carid
                            ]?.gallery.images.indexOf(image_id_param),
                    })
                }
            }
            this.setUpInitialIndex()
        }

        if (prevprops.carsData !== this.props.carsData) {
            this.setUpInitialIndex()

            this.manageShowroomEntryData()
        }
    }

    selectMenuGalleryImageState = (id: string) => {
        let params = new URLSearchParams(this.props.location.search)
        let carid: string = this.props.match.params.carid
        let image_id_param = params.get('id')
        let carsData = this.props.carsData

        const currentcar: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            this.state.image_index &&
            currentcar &&
            currentcar?.gallery.images
                ? currentcar.gallery.images[this.state.image_index]
                : image_id_param

        if (id === 'cover' && current_image_id) {
            let payload: IUpdateCarGalleryImagePayload = {
                carid: this.props.match.params.carid,
                imageid: current_image_id,
                body: {
                    cover: true,
                    featured: false,
                },
            }
            this.props.updateCarGalleryImageRequest(payload)

            let history = this.props.history

            setTimeout(function () {
                history.push(`/car/${carid}`)
            }, 200)
        } else if (
            id === 'featured' &&
            current_image_id &&
            this.props.imagesData
        ) {
            let payload: IUpdateCarGalleryImagePayload = {
                carid: this.props.match.params.carid,
                imageid: current_image_id,
                body: {
                    featured: !this.props.imagesData[current_image_id].featured,
                    cover: false,
                },
            }
            this.props.updateCarGalleryImageRequest(payload)
            // let history = this.props.history

            // setTimeout(function () {
            //     history.push(`/car/${carid}`)
            // }, 200)
        } else if (id === 'edit' && current_image_id) {
            return this.setState({
                showEditImageForm: !this.state.showEditImageForm,
                showDeleteConfirmationMessage: false,
            })
        } else if (id === 'delete' && current_image_id) {
            this.setState({
                showEditImageForm: false,
                showDeleteConfirmationMessage: true,
            })

            let ids: Iids = {
                carid: this.props.match.params.carid,
                imageid: current_image_id,
            }
            this.props.deleteCarGalleryImageRequest(ids)
        }
        this.props.toggleExistingCarGalleryImageEditForm()
    }

    generateImageNavigationUrl = (next: string) => {
        let params = new URLSearchParams(this.props.location.search)

        let image_id_param = params.get('id')

        let carid: string = this.props.match.params.carid

        if (
            this.props.carsData &&
            carid &&
            this.props.carsData[carid] !== undefined &&
            image_id_param
        ) {
            const ids_list: string[] =
                this.props.carsData[carid].gallery &&
                this.props.carsData[carid].gallery.images
                    ? this.props.carsData[carid].gallery.images
                    : []

            const index_of_id = ids_list.indexOf(image_id_param)

            if (next === 'next') {
                const index_of_next_image = index_of_id + 1

                if (index_of_next_image === -1) {
                    return '/none'
                }

                const id_of_next_image = ids_list[index_of_next_image]

                if (id_of_next_image !== undefined) {
                    return `/car/${carid}/gallery?id=${id_of_next_image}`
                } else return '/none'
            } else {
                const index_of_previous_image = index_of_id - 1

                if (index_of_previous_image === -1) {
                    return '/none'
                }

                const id_of_previous_image = ids_list[index_of_previous_image]

                return `/car/${carid}/gallery?id=${id_of_previous_image}`
            }
        } else {
            return '/none'
        }
    }

    render() {
        const {
            carsData,
            isExistingcarGalleryImageFormOpened,
            toggleExistingCarGalleryImageEditForm,
            imagesData,
            updateCarGalleryImageRequest,
            isNecessaryGalleryDataLoading,
            userShowroomEntriesData,
        } = this.props

        let carid: string = this.props.match.params.carid

        let params = new URLSearchParams(this.props.location.search)

        let image_id_param = params.get('id')

        const { showDeleteConfirmationMessage, showEditImageForm } = this.state

        const currentcar: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        let prev_url = this.generateImageNavigationUrl('previous')
        let next_url = this.generateImageNavigationUrl('next')

        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            this.state.image_index &&
            currentcar &&
            currentcar?.gallery.images
                ? currentcar.gallery.images[this.state.image_index]
                : image_id_param

        const featuredImagesCount =
            currentcar && imagesData
                ? countFeaturedImages(currentcar, imagesData)
                : 0

        let hasEntryID: string | undefined =
            (currentcar &&
                currentcar.showroom_entry &&
                currentcar.showroom_entry.state === 'PUBLISHED') ||
            (currentcar &&
                currentcar.showroom_entry &&
                currentcar.showroom_entry.state === 'SOLD')
                ? currentcar.showroom_entry.uid
                : undefined

        let carEntry: IShowroomEntry | undefined =
            userShowroomEntriesData &&
            hasEntryID &&
            userShowroomEntriesData[hasEntryID]
                ? userShowroomEntriesData[hasEntryID]
                : undefined

        let entryImagesArray =
            carEntry && carEntry.gallery_images
                ? carEntry.gallery_images.map((item) => item.id)
                : undefined

        let hasShowroomImg: boolean =
            current_image_id &&
            entryImagesArray &&
            entryImagesArray.includes(current_image_id)
                ? true
                : false

        return imagesData ? (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        {currentcar?.archived_on && (
                            <Redirect
                                to={{
                                    pathname: `/archives/car/${currentcar.id}/gallery`,
                                    search: `?id=${image_id_param}`,
                                }}
                            />
                        )}
                        {params.get('tutorial') === 'true' && (
                            <TutorialConfigsCarGalleryImageDesktop />
                        )}

                        {params.get('tutorial') === 'true' && (
                            <TutorialConfigsCarGalleryImageMobile />
                        )}
                        <ScrollLock isActive={true} />

                        <DesktopDisplayOnly>
                            <FullPageWrapper>
                                <ModalDisplayGalleryImageDesktop
                                    toggle={() => {
                                        this.props.history.push(
                                            this.props.location.pathname
                                        )
                                    }}
                                    isOpen={true}
                                >
                                    {carsData !== undefined &&
                                        currentcar !== undefined &&
                                        current_image_id &&
                                        imagesData &&
                                        imagesData[current_image_id] && (
                                            <GallerySlide
                                                isImgInShowroom={hasShowroomImg}
                                                closeGallery={() => {
                                                    this.props.history.push(
                                                        this.props.location
                                                            .pathname
                                                    )
                                                }}
                                                currentImageData={
                                                    imagesData[current_image_id]
                                                }
                                                carid={carid}
                                                imagesData={imagesData}
                                                currentCar={currentcar}
                                                onClickEdit={
                                                    toggleExistingCarGalleryImageEditForm
                                                }
                                                updateImage={
                                                    updateCarGalleryImageRequest
                                                }
                                                isSomethingLoading={
                                                    isNecessaryGalleryDataLoading
                                                }
                                                prevUrl={prev_url}
                                                nextUrl={next_url}
                                                featuredImagesCount={
                                                    currentcar && imagesData
                                                        ? countFeaturedImages(
                                                              currentcar,
                                                              imagesData
                                                          )
                                                        : 0
                                                }
                                            />
                                        )}
                                </ModalDisplayGalleryImageDesktop>
                            </FullPageWrapper>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <SubWrapper
                                style={{
                                    display: 'flex',
                                    position: 'relative',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100vh',
                                    backgroundColor:
                                        colours[theme].background_default,
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `/car/${carid}/gallery`,
                                        state: {
                                            prevPath: this.props.match.url,
                                        },
                                    }}
                                >
                                    <ReturnNav />
                                </Link>
                                {carsData !== undefined &&
                                currentcar !== undefined &&
                                current_image_id &&
                                imagesData &&
                                imagesData[current_image_id] &&
                                this.state.image_index !== undefined &&
                                this.props.userLoggedIn &&
                                this.props.userLoggedIn.display_name ? (
                                    <GalleryImageCarousel
                                        indexOfInitialImage={
                                            this.state.image_index
                                        }
                                        image_data={imagesData}
                                        images_ids={currentcar.gallery.images}
                                        synchroniseActiveIndex={(n: number) => {
                                            this.setState({
                                                ...this.state,
                                                image_index: n,
                                            })
                                        }}
                                        openActionsSheet={() => {
                                            toggleExistingCarGalleryImageEditForm()
                                        }}
                                        cover_id={currentcar.gallery.cover}
                                    />
                                ) : (
                                    <SkeletonAbsolute isthingloading={true} />
                                )}
                                {current_image_id &&
                                    imagesData &&
                                    imagesData[current_image_id] &&
                                    currentcar && (
                                        <ImageSubMenuActions
                                            isOpen={
                                                isExistingcarGalleryImageFormOpened &&
                                                !showDeleteConfirmationMessage &&
                                                !showEditImageForm
                                            }
                                            toggle={
                                                this.selectMenuGalleryImageState
                                            }
                                            items={[
                                                {
                                                    id: 'edit',
                                                    text: 'Update image details',
                                                    action: this
                                                        .selectMenuGalleryImageState,
                                                },
                                                {
                                                    id: 'cover',
                                                    text: 'Set as car profile image',
                                                    action: this
                                                        .selectMenuGalleryImageState,
                                                },
                                                {
                                                    id: 'featured',
                                                    text:
                                                        imagesData[
                                                            current_image_id
                                                        ].featured === true
                                                            ? 'Remove feature'
                                                            : 'Set as gallery feature',
                                                    action: this
                                                        .selectMenuGalleryImageState,
                                                    isDisabled:
                                                        imagesData[
                                                            current_image_id
                                                        ].featured !== true &&
                                                        featuredImagesCount >= 5
                                                            ? true
                                                            : false,
                                                    tooltipText:
                                                        'Five images already set as gallery features. Please remove at least one before setting new ones.',
                                                },

                                                {
                                                    id: 'delete',
                                                    text: 'Delete image',
                                                    isDisabled: hasShowroomImg,
                                                    tooltipText:
                                                        'Image is part of a published showroom entry',
                                                    action: this
                                                        .selectMenuGalleryImageState,
                                                },
                                            ]}
                                        />
                                    )}
                                {isExistingcarGalleryImageFormOpened &&
                                    showDeleteConfirmationMessage === false &&
                                    showEditImageForm === true &&
                                    current_image_id && (
                                        <ModalDisplay
                                            toggle={
                                                toggleExistingCarGalleryImageEditForm
                                            }
                                            isOpen={true}
                                            customZindex={11}
                                        >
                                            <CarGalleryEditExistingImageFormMobile
                                                carid={carid}
                                                updateImage={
                                                    updateCarGalleryImageRequest
                                                }
                                                imagedata={
                                                    imagesData &&
                                                    imagesData[current_image_id]
                                                }
                                                close={() => {
                                                    toggleExistingCarGalleryImageEditForm()
                                                    this.setState({
                                                        showDeleteConfirmationMessage:
                                                            false,
                                                        showEditImageForm:
                                                            false,
                                                    })
                                                }}
                                            />
                                        </ModalDisplay>
                                    )}
                                {showDeleteConfirmationMessage &&
                                    !showEditImageForm && (
                                        <ModalDisplay
                                            toggle={() => {
                                                toggleExistingCarGalleryImageEditForm()
                                                this.props.history.push(
                                                    `/car/${carid}/gallery`
                                                )
                                            }}
                                            customZindex={11}
                                            isOpen={true}
                                        >
                                            <DeleteImageConfirmationWrapper>
                                                <SuccessDeletionMessage>
                                                    Image successfully deleted.
                                                </SuccessDeletionMessage>

                                                <ReturnToGalleryLink
                                                    to={{
                                                        pathname: `/car/${carid}/gallery`,
                                                    }}
                                                    replace
                                                >
                                                    Return to gallery
                                                </ReturnToGalleryLink>
                                            </DeleteImageConfirmationWrapper>
                                        </ModalDisplay>
                                    )}
                            </SubWrapper>
                        </IpadAndMobileDisplay>
                    </>
                )}
            </ThemesContext.Consumer>
        ) : (
            <div style={{ display: 'none' }} />
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GalleryImageV2)
)
