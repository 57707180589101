import styled from 'styled-components'
import React from 'react'
import { IDirectShareOwnedItem, ISearchUsersResult } from 'entityModels'
import { IShareRecipientRequest_redux } from '../../../../redux/entities/cars/actions/loadingActions'
import UsersSearchBar from '../../carHandoverManagers/usersSearchBar'
import IconAndTextNakedButton from '../../../atoms/Button/iconsAndTextNakedButton'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Title = styled.div`
    font-family: Lato;
    font-size: 17px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
`

const SubTitle = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-default, #666666);
`

const SearchWrapper = styled.div`
    width: 450px;
    padding-top: 30px;
`

type State = {
    searchFor: string | undefined
    typedValue: string | undefined
    // searchResultsInState: ISearchUsersResult[] | undefined
}

interface Props {
    share: IDirectShareOwnedItem
    searchUsers: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    resultsLoading: boolean
    addShareRecipient: (p: IShareRecipientRequest_redux) => void
    isGarage?: boolean
    selectedUsers: string[]
    onClickInvite: any
    inviteTxt?: string
}

class PrivateShareSearchFormManagerDesktop extends React.PureComponent<
    Props,
    State,
    {}
> {
    state = {
        searchFor: undefined,
        typedValue: undefined,
        // searchResultsInState: this.props.searchResults,
    }

    componentDidMount() {}

    // componentDidUpdate(prevProps: Readonly<Props>): void {
    //     if (this.props.searchResults !== prevProps.searchResults) {
    //         if (this.props.searchResults && this.state.searchFor) {
    //             let make_fuse = this.props.searchResults
    //                 ? createSearchableIndex(this.props.searchResults, [
    //                       'display_name',
    //                       'family_name',
    //                       'given_name',
    //                   ])
    //                 : undefined

    //             let fusedSearchResults = make_fuse
    //                 ? make_fuse
    //                       .search(this.state.searchFor, { limit: 5 })
    //                       .map((item) => item.item)
    //                 : undefined

    //             this.setState({
    //                 ...this.state,
    //                 searchResultsInState: fusedSearchResults,
    //             })
    //         }
    //     }
    // }

    render() {
        return (
            <Container>
                <Title>Choose your Share Recipient(s)</Title>
                <SubTitle>
                    {`Please search and select the recipient(s) that you would like to share your
                    ${
                        this.props.isGarage ? 'garage' : 'car'
                    } with by entering their email.`}
                </SubTitle>
                <SearchWrapper>
                    <UsersSearchBar
                        // searchResults={searchResultsInState}
                        searchResults={this.props.searchResults}
                        onSearch={(value: string) => {
                            this.props.searchUsers(value)
                            this.setState({
                                ...this.state,
                                searchFor: value,
                            })
                        }}
                        searchFor={this.state.searchFor}
                        clearSearch={() => {
                            this.setState({
                                ...this.state,
                                searchFor: undefined,
                                typedValue: undefined,
                            })
                            this.props.searchUsers(undefined)
                        }}
                        onSearchResultSelect={(user: any) => {
                            this.setState({
                                ...this.state,
                                searchFor: undefined,
                                typedValue: undefined,
                            })

                            this.props.addShareRecipient({
                                share_id: this.props.share.id,
                                entity_id: this.props.share.entity_id,
                                recipient_userid: user.uid,
                            })
                        }}
                        onInputChange={(value: string) => {
                            this.setState({
                                ...this.state,
                                typedValue: value,
                            })
                        }}
                        loadingPlaceholder={
                            this.props.resultsLoading ||
                            (this.state.typedValue &&
                                this.state.searchFor !== this.state.typedValue)
                                ? true
                                : false
                        }
                        placeholder={`Search recipient's Email`}
                        selectedUsers={this.props.selectedUsers}
                        hasInviteOption={true}
                        onClickInvite={this.props.onClickInvite}
                        inviteText={this.props.inviteTxt}
                        ownEmailSearchCopy="You cannot share to yourself."
                        // selectedUserData={}
                    />

                    {/* <CarShareSearchBarDesktop
                        // searchResults={this.state.searchResultsInState}
                        searchResults={this.props.searchResults}
                        onSearch={(value: string) => {
                            this.props.searchUsers(value)
                            this.setState({
                                ...this.state,
                                searchFor: value,
                            })
                        }}
                        onClickInvite={this.props.onClickInvite}
                        searchFor={this.state.searchFor}
                        clearSearch={() => {
                            this.setState({
                                ...this.state,
                                searchFor: undefined,
                                typedValue: undefined,
                            })
                            this.props.searchUsers(undefined)
                        }}
                        onSearchResultSelect={(user: any) => {
                            this.props.addShareRecipient({
                                share_id: this.props.share.id,
                                entity_id: this.props.share.entity_id,
                                recipient_userid: user.uid,
                            })
                        }}
                        onInputChange={(value: string) =>
                            this.setState({
                                ...this.state,
                                typedValue: value,
                            })
                        }
                        loadingPlaceholder={
                            this.props.resultsLoading ||
                            (this.state.typedValue &&
                                this.state.searchFor !== this.state.typedValue)
                                ? true
                                : false
                        }
                        selectedUsers={this.props.selectedUsers}
                        inviteTxt={this.props.inviteTxt}
                    /> */}
                </SearchWrapper>

                <div style={{ paddingTop: '18px' }} />

                {(this.props.searchResults === undefined ||
                    this.props.searchResults?.length === 0) &&
                    (this.state.typedValue === undefined ||
                        this.state.typedValue === '') && (
                        <IconAndTextNakedButton
                            onClick={() => this.props.onClickInvite()}
                            text="Invite to Custodian via Email & Share"
                            iconOnLeft
                            icon="v2_add"
                        />
                    )}
            </Container>
        )
    }
}

export default PrivateShareSearchFormManagerDesktop
