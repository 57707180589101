import { IConvertShowroomWatchAPI_arg } from '../../services/showroom/watchlist'
import { IShowroomWatchList_req_API } from '../types'
import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { showroomActions } from '../reducer'
import { RootState } from 'typesafe-actions'

let pagination_select = (state: RootState): number =>
    state.showroom.currentPageNumber_watchlist

let limit_select = (state: RootState): number =>
    state.showroom.itemsNumberPerPage

export function* GetWatchlistEntries(): any {
    try {
        let page_n: number = yield select(pagination_select)
        let offset_limit: number = yield select(limit_select)

        let p: IConvertShowroomWatchAPI_arg = {
            currency: 'GBP',
            offset: page_n,
            limit: offset_limit,
        }
        let res: IShowroomWatchList_req_API = yield call(
            api.showroom.listShowroomWatchlist,
            p
        )

        let cars = res?.entries
            ? res?.entries?.map((car) => {
                  return { ...car, is_watched: true }
              })
            : []
        yield put(
            showroomActions.getWatchlistEntriesSuccess({
                cars: cars,
                count: res.count,
            })
        )
        // here, pass the results and thats it
        // return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetWatchlistEntries,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.getWatchlistEntriesError(customError))
        }
    }
}

function* watcherGetWatchlistEntries() {
    while (true) {
        yield take(showroomActions.getWatchlistEntriesRequest)

        yield call(GetWatchlistEntries)
    }
}

const get_watchlist_entries: any[] = [fork(watcherGetWatchlistEntries)]

export default get_watchlist_entries
