import { ITaskUpdateFieldsPayload } from 'entityModels'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const createTask = (
    newTaskData: ITaskUpdateFieldsPayload
): Promise<any> => {
    let newTaskDataFinal = JSON.stringify(newTaskData)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: newTaskDataFinal,
        credentials: 'include',
    }

    return fetch(urls.create_task_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create task error',
                            newTaskDataFinal
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
