import dayjs from 'dayjs'

export const convertDateWithDayJs = (
    date: string | number | undefined
): string | undefined => {
    let isValid: boolean =
        date && typeof date === 'string' && date !== '' && dayjs(date).isValid()
            ? true
            : false

    if (isValid) {
        let convertedDate: string = dayjs(date).format('YYYY-MM-DD')
        return convertedDate
    } else {
        return undefined
    }
}

export type IDateValidationObjArg = {
    minDate?: string
    value?: string
    maxDate?: string
}

export const isDateValidMinMax = (p: IDateValidationObjArg): boolean => {
    if (!p.value) {
        // If value is not provided, consider it valid
        return true
    }

    const currentDate = new Date(p.value)

    if (isNaN(currentDate.getTime())) {
        // If value is not a valid date, consider it invalid
        return false
    }

    if (p.minDate && new Date(p.value) < new Date(p.minDate)) {
        // If value is before minDate, consider it invalid
        return false
    }

    if (p.maxDate && new Date(p.value) > new Date(p.maxDate)) {
        // If value is after maxDate, consider it invalid
        return false
    }

    // If all checks pass, consider the date valid
    return true
}
