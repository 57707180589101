import { formValidationCopyData } from './data'

import { IFormValidationCopy } from 'formValidationCopyModels'

export type IFormValidationCopyState = IFormValidationCopy

const initialState = formValidationCopyData

export const form_validation_copy = (
    state: IFormValidationCopyState = initialState
) => state

export default form_validation_copy

export type FormValidationCopyState = ReturnType<typeof form_validation_copy>
