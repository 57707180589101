import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import Sheet from 'react-modal-sheet'
import FilterBottomSheetItemMobile from '../../filterBottomSheetItem/filterBottomSheetItemMobile'
import ScrollLock from 'react-scrolllock'
import { IDropdownItem } from 'entityModels'
import { device } from '../../../templates/displays/devices'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type IStyledProps = {
    color?: string
    $customHeight?: string
    $ipadCustomHeight?: string
}

const CustomSheet = styled(Sheet)<IStyledProps>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '70vh !important'};
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;

        @media ${device.ipad} {
            height: ${(props) =>
                props.$ipadCustomHeight
                    ? `${props.$ipadCustomHeight} !important`
                    : props.$customHeight
                    ? `${props.$customHeight} !important`
                    : '70vh !important'};
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .react-modal-sheet-header {
        height: 30px !important;
        align-items: flex-start !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
        margin-top: 10px;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`

const BottomSheetCustomHeader = styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    /* padding-top: 10px; */
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const BottomSheetCustomHeaderText = styled.div<IStyledProps>`
    font-size: 16px;
    font-family: 'Lato-bold';
    color: ${(props) =>
        props.color ? props.color : 'var(--primary, #5ec3ca)'};
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

interface IProps {
    toggle: any
    isFilterBottomSheetMenuOpen: boolean
    criteriaData?: IDropdownItem[] | null | undefined
    activeCriteria: string[] | null | undefined
    sendFiltersToApi: any
    customHeight?: string
    ipadCustomHeight?: string
    notCapitalised?: boolean
}

const FilterBottomSheetMenuMobile: React.SFC<IProps> = (props) => {
    const {
        isFilterBottomSheetMenuOpen,
        toggle,
        criteriaData,
        activeCriteria,
        sendFiltersToApi,
        customHeight,
        ipadCustomHeight,
    } = props

    const { theme } = useThemes()

    let initialActiveCriteria = activeCriteria

    const [selectedCriteria, setSelectedCriteria] = useState<string[]>(
        activeCriteria ? [...activeCriteria] : []
    )

    useEffect(() => {
        activeCriteria && setSelectedCriteria(activeCriteria)

        return
    }, [activeCriteria])

    const updateSelectedCriteria = (item: string) => {
        let indexOfItem: number = selectedCriteria.indexOf(item)
        let currentSelectedCriteria: string[] = [...selectedCriteria]
        if (indexOfItem < 0) {
            currentSelectedCriteria.push(item)
            setSelectedCriteria(currentSelectedCriteria)
        } else {
            currentSelectedCriteria.splice(indexOfItem, 1)
            setSelectedCriteria(currentSelectedCriteria)
        }
    }

    let mappedCategoryData =
        criteriaData &&
        criteriaData.map((category: IDropdownItem, index: number) => {
            if (category && category.name) {
                return (
                    <React.Fragment key={`${category.uid}_${index}_mobile`}>
                        <FilterBottomSheetItemMobile
                            text={category.name}
                            key={index}
                            checked={
                                category.name &&
                                selectedCriteria.includes(
                                    category.name.toLowerCase()
                                )
                                    ? true
                                    : false
                            }
                            onClick={() =>
                                category.name &&
                                updateSelectedCriteria(
                                    category.name.toLowerCase()
                                )
                            }
                            notCapitalised={props.notCapitalised}
                        />
                    </React.Fragment>
                )
            } else {
                return null
            }
        })

    return (
        <React.Fragment>
            {isFilterBottomSheetMenuOpen && <ScrollLock />}
            <CustomSheet
                isOpen={isFilterBottomSheetMenuOpen}
                onClose={() => {
                    toggle(false)
                    activeCriteria && setSelectedCriteria(activeCriteria)
                }}
                $customHeight={customHeight}
                $ipadCustomHeight={ipadCustomHeight}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        {/* custom header */}
                        <BottomSheetCustomHeader>
                            <BottomSheetCustomHeaderText
                                color={colours[theme].error}
                                onClick={() => {
                                    toggle(false)
                                    sendFiltersToApi(initialActiveCriteria)
                                }}
                                data-attr="cancel_filter_timeline_mobile"
                            >
                                Cancel
                            </BottomSheetCustomHeaderText>
                            <BottomSheetCustomHeaderText
                                onClick={() => {
                                    toggle(false)
                                    sendFiltersToApi(selectedCriteria)
                                }}
                                data-attr="filter_timeline_mobile"
                            >
                                Filter
                            </BottomSheetCustomHeaderText>
                        </BottomSheetCustomHeader>
                        {/* item list bellow - harcoded for now */}
                        {mappedCategoryData}
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop
                    onTap={(e: any) => {
                        e.preventDefault()
                        setTimeout(function () {
                            toggle(false)
                            activeCriteria &&
                                setSelectedCriteria(activeCriteria)
                        }, 50)
                    }}
                />
            </CustomSheet>
        </React.Fragment>
    )
}

export default connect(
    mapStateToProps,
    dispatchProps
)(FilterBottomSheetMenuMobile)
