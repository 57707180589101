import { call, fork, put, take } from 'redux-saga/effects'

import * as requestActions from '../actions/loadingActions'
import * as successActions from '../actions/actions'
import { sortShareRecipientsByAlphabetical } from '../../../../helpers/sort/sortByAlphabetical'
import { sortShareRecipientsByAddedAt } from '../../../../helpers/sort/sortByDate'
import {
    ISortShareRecipientsDisplay_change_request,
    ISortShareRecipientsDisplay_change_success,
} from 'entityModels'

function* changeSortShareRecipientssDisplay(
    payload: ISortShareRecipientsDisplay_change_request
): any {
    let currentShareRecipientsItemsDisplay: string[] | undefined =
        payload.share &&
        payload.share.active_display &&
        payload.share.active_display.item_ids_display

    let items_new: string[] = currentShareRecipientsItemsDisplay
        ? [...currentShareRecipientsItemsDisplay]
        : []

    if (
        payload.sortID === 'alphabetical' &&
        currentShareRecipientsItemsDisplay &&
        payload.share &&
        payload.share.share_recipients_data
    ) {
        items_new = sortShareRecipientsByAlphabetical(
            currentShareRecipientsItemsDisplay,
            payload.share?.share_recipients_data
        )
    }

    if (
        payload.sortID === 'added_at' &&
        currentShareRecipientsItemsDisplay &&
        payload.share &&
        payload.share.share_recipients_data
    ) {
        items_new = sortShareRecipientsByAddedAt(
            currentShareRecipientsItemsDisplay,
            payload.share.share_recipients_data
        )
    }

    let p: ISortShareRecipientsDisplay_change_success = {
        entityId: payload.entityid,
        shareid: payload.share.id,
        sortID: payload.sortID,
        item_ids_display: items_new,
    }
    yield put(successActions.sortShareRecipientsDisplay_change_success(p))
}

function* watcherChangeSortShareRecipientsDisplay() {
    while (true) {
        const { payload } = yield take(
            requestActions.sortShareRecipientsDisplay_change_request
        )

        yield call(changeSortShareRecipientssDisplay, payload)
    }
}

const handle_shares_recipients_display_change: any[] = [
    fork(watcherChangeSortShareRecipientsDisplay),
]

export default handle_shares_recipients_display_change
