const DragIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1797_303321)">
                <path
                    d="M7.5 8.5C7.5 9.88071 6.38071 11 5 11C3.61929 11 2.5 9.88071 2.5 8.5C2.5 7.11929 3.61929 6 5 6C6.38071 6 7.5 7.11929 7.5 8.5Z"
                    fill="white"
                />
                <path
                    d="M7.5 15.5C7.5 16.8807 6.38071 18 5 18C3.61929 18 2.5 16.8807 2.5 15.5C2.5 14.1193 3.61929 13 5 13C6.38071 13 7.5 14.1193 7.5 15.5Z"
                    fill="white"
                />
                <path
                    d="M14.5 8.5C14.5 9.88071 13.3807 11 12 11C10.6193 11 9.5 9.88071 9.5 8.5C9.5 7.11929 10.6193 6 12 6C13.3807 6 14.5 7.11929 14.5 8.5Z"
                    fill="white"
                />
                <path
                    d="M14.5 15.5C14.5 16.8807 13.3807 18 12 18C10.6193 18 9.5 16.8807 9.5 15.5C9.5 14.1193 10.6193 13 12 13C13.3807 13 14.5 14.1193 14.5 15.5Z"
                    fill="white"
                />
                <path
                    d="M21.5 8.5C21.5 9.88071 20.3807 11 19 11C17.6193 11 16.5 9.88071 16.5 8.5C16.5 7.11929 17.6193 6 19 6C20.3807 6 21.5 7.11929 21.5 8.5Z"
                    fill="white"
                />
                <path
                    d="M21.5 15.5C21.5 16.8807 20.3807 18 19 18C17.6193 18 16.5 16.8807 16.5 15.5C16.5 14.1193 17.6193 13 19 13C20.3807 13 21.5 14.1193 21.5 15.5Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1797_303321">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DragIcon
