import styled from 'styled-components'
import React from 'react'
import { device } from '../../templates/displays/devices'
import back_circle_navigation_reverted from '../../../public/assets/navigation/back_circle_navigation_reverted.svg'
import ChevronLeft from '../icons/components/chevronLeft'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyledProps = {
    isBlueBg?: boolean | undefined
    customTextColor?: string | undefined
    isUppercase?: boolean | undefined
    chevron?: boolean | undefined
}
const NavCaption = styled.section<IStyledProps>`
    background-color: ${(props) =>
        props.isBlueBg ? '#80cccf' : 'var(--bg-color, #fff)'};
    border-bottom: 1px solid var(--border-muted, #e5e5e5);

    @media ${device.mobile} {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 50px;
        position: fixed;
        z-index: 6;
        padding-left: 16px;
    }

    @media ${device.ipad} {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 50px;
        position: fixed;
        z-index: 6;
        padding-left: 20px;
    }

    @media ${device.desktop} {
        display: none;
    }
`

const ReturnLogo = styled.img<IStyledProps>`
    @media ${device.mobile} {
        width: ${(props) => (props.chevron ? '15px' : '26px')};
        height: ${(props) => (props.chevron ? '15px' : '26px')};
        margin-right: ${(props) => (props.chevron ? '20px' : '10px')};
    }

    @media ${device.ipad} {
        width: ${(props) => (props.chevron ? '15px' : '26px')};
        height: ${(props) => (props.chevron ? '15px' : '26px')};
        margin-right: ${(props) => (props.chevron ? '20px' : '10px')};
    }

    @media ${device.desktop} {
        display: none;
    }
`

const Return = styled.div<IStyledProps>`
    color: ${(props) =>
        props.isBlueBg
            ? 'var(--bg-color, #fff)'
            : props.customTextColor
            ? props.customTextColor
            : 'var(--text-strong, #1a1a1a)'};
    transform: ${(props) =>
        props.isUppercase ? 'translateY(0px)' : 'translateY(-2px)'};
    padding-left: 8px;

    @media ${device.mobile} {
        font-family: 'Lato';
        justify-content: center;
        text-transform: ${(props) =>
            props.isUppercase ? 'uppercase' : 'none'};
        font-weight: 200;
        font-size: ${(props) => (props.isUppercase ? '14px' : '16px')};
        margin-top: 2px;
        letter-spacing: 1.05px;
    }

    @media ${device.ipad} {
        font-family: 'Lato';
        justify-content: center;
        text-transform: ${(props) =>
            props.isUppercase ? 'uppercase' : 'lowercase'};
        font-weight: 200;
        font-size: ${(props) => (props.isUppercase ? '14px' : '16px')};
        margin-top: 2px;
        letter-spacing: 1.05px;
    }

    @media ${device.desktop} {
        display: none;
    }
`

interface Props {
    url?: string
    customText?: string
    customTextColor?: string
    isBlueBg?: boolean | undefined
    isUppercase?: boolean
    chevron?: boolean
    isExternalEntity?: boolean
}

const ReturnNav: React.FunctionComponent<Props> = (props) => {
    const { theme } = useThemes()
    return (
        <NavCaption
            isBlueBg={props.isBlueBg}
            style={
                props.isExternalEntity
                    ? { borderBottom: 'none', height: 64 }
                    : {}
            }
        >
            {props.isBlueBg ? (
                <ReturnLogo
                    src={back_circle_navigation_reverted}
                    alt="go-back-logo"
                />
            ) : (
                // <ReturnLogo
                //     src={
                //         props.chevron ? chevron_to_left : back_circle_navigation
                //     }
                //     alt="go-back-logo-2"
                //     chevron={props.chevron}
                // />
                <ChevronLeft color={colours[theme].text_muted} />
            )}
            <Return
                isBlueBg={props.isBlueBg}
                customTextColor={props.customTextColor}
                isUppercase={props.isUppercase}
            >
                {props.customText ? props.customText : 'Go back'}
            </Return>
        </NavCaption>
    )
}

export default ReturnNav
