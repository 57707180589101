import styled from 'styled-components'
import SearchIcon from '../../../atoms/icons/components/searchIcon'
import { useMemo, useRef, useState } from 'react'
import { useOnClickOutside } from '../../../templates/clickOutside'
import { IDropdownItem } from 'entityModels'
import CrossIcon from '../../../atoms/icons/crossIcon'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import Expander from '../../../atoms/expander/expander'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const SearchField = styled.input`
    width: 100%;
    padding: 12px 40px;
    border-radius: 4px;
    border: 1px solid var(--border-muted, #e5e5e5);
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    background-color: var(--bg-color, #fff);
`

const SearchIconWrapper = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 17px;
    left: 16px;
`

const SearchClearWrapper = styled.button`
    all: unset;
    cursor: pointer;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 17px;
    right: 16px;
    transition: all 200ms;
`
const DropdownWrapper = styled.div`
    position: absolute;
    z-index: 7;
    left: 0px;
    top: 56px;
`
const Dropdown = styled.div`
    display: flex;
    width: 400px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    border-radius: 4px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background: var(--bg-color, #fff);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
`

const DropdownSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`

const DropdownSectionTitle = styled.h3`
    padding: 0px;
    margin: 0px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const DropdownSectionOptionTextItem = styled.p`
    padding: 0px;
    margin: 0px;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const DropdownSectionSearchForTextItem = styled.p`
    padding: 0px;
    margin: 0px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

export const Label = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid var(--border-muted, #e5e5e5);
    color: var(--text-darker, #616161);
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.84px;
    text-transform: uppercase;
`

type Props = {
    categoriesInDropdown: IDropdownItem[]
    labelsInDropdown: IDropdownItem[]
    fuseSearchToSetCategoriesAndLabelsDropdownOptions: (v: string) => void
    onOptionClick: (
        target: 'category' | 'label' | 'string',
        value: string
    ) => void
}

const renderOptions = (
    target: 'category' | 'label',
    options: IDropdownItem[],
    onClick: (target: 'category' | 'label' | 'string', value: string) => void
) =>
    options && options.length > 0 ? (
        <DropdownSection>
            {options.map((option, i) => {
                return (
                    <button
                        key={`dropdown_${option}_${i}`}
                        style={{
                            all: 'unset',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                        onClick={() => onClick(target, option.name)}
                    >
                        <DropdownSectionOptionTextItem>{`Add ${target} filter:`}</DropdownSectionOptionTextItem>
                        {target === 'category' ? (
                            <CustomIconTagQuickFilterItem id={option.name}>
                                {option.name}
                            </CustomIconTagQuickFilterItem>
                        ) : (
                            <Label>{option.name}</Label>
                        )}
                    </button>
                )
            })}
        </DropdownSection>
    ) : null

const TimelineSearchBarWDropdownDesktop: React.FC<Props> = (props) => {
    const {
        onOptionClick,
        fuseSearchToSetCategoriesAndLabelsDropdownOptions,
        categoriesInDropdown,
        labelsInDropdown,
    } = props
    const [activeString, setActiveString] = useState('')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const clearSearch = () => {
        setIsDropdownOpen(false)
        setActiveString('')
    }

    const ref = useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, clearSearch)

    const sections = ['categories', 'labels', 'string']
    const categories = useMemo(
        () =>
            renderOptions('category', categoriesInDropdown, (target, value) => {
                onOptionClick(target, value)
                clearSearch()
            }),
        [categoriesInDropdown]
    )
    const labels = useMemo(
        () =>
            renderOptions('label', labelsInDropdown, (target, value) => {
                onOptionClick(target, value)
                clearSearch()
            }),
        [labelsInDropdown]
    )

    const { theme } = useThemes()

    return (
        <div
            ref={ref}
            style={{
                width: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <SearchField
                placeholder="Search"
                type="text"
                onChange={(e) => {
                    setActiveString(e.target.value)
                    if (e.target.value) {
                        setIsDropdownOpen(true)
                    } else {
                        setIsDropdownOpen(false)
                    }
                    fuseSearchToSetCategoriesAndLabelsDropdownOptions(
                        e.target.value
                    )
                }}
                value={activeString}
            />

            <SearchIconWrapper>
                <SearchIcon color={colours[theme].text_darker} />
            </SearchIconWrapper>

            <SearchClearWrapper
                onClick={() => {
                    activeString && clearSearch()
                }}
                style={{
                    opacity: activeString ? 1 : 0,
                    cursor: activeString ? 'pointer' : 'default',
                }}
            >
                <CrossIcon />
            </SearchClearWrapper>

            <DropdownWrapper>
                <Expander
                    height={
                        isDropdownOpen && activeString?.length > 1 ? 'auto' : 0
                    }
                >
                    <Dropdown>
                        {sections.map((section, i) => {
                            return section === 'categories' && categories ? (
                                <DropdownSection key={`${section}_${i}`}>
                                    <DropdownSectionTitle>
                                        <span
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {section}
                                        </span>
                                    </DropdownSectionTitle>

                                    {categories}
                                </DropdownSection>
                            ) : section === 'labels' && labels ? (
                                <DropdownSection key={`${section}_${i}`}>
                                    <DropdownSectionTitle>
                                        <span
                                            style={{
                                                textTransform: 'capitalize',
                                            }}
                                        >
                                            {section}
                                        </span>
                                    </DropdownSectionTitle>

                                    {labels}
                                </DropdownSection>
                            ) : section === 'string' &&
                              activeString.length > 2 ? (
                                <DropdownSection key={`${section}_${i}`}>
                                    <DropdownSectionTitle>
                                        Search for
                                    </DropdownSectionTitle>

                                    <button
                                        style={{
                                            all: 'unset',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 8,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            onOptionClick(
                                                'string',
                                                activeString
                                            )
                                            clearSearch()
                                        }}
                                    >
                                        <SearchIcon />
                                        <DropdownSectionSearchForTextItem>
                                            {activeString}
                                        </DropdownSectionSearchForTextItem>
                                    </button>
                                </DropdownSection>
                            ) : null
                        })}
                    </Dropdown>
                </Expander>
            </DropdownWrapper>
        </div>
    )
}

export default TimelineSearchBarWDropdownDesktop
