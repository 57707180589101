import { IAttachmentItem } from 'attachmentModels'
import styled from 'styled-components'
import { convertToShorterString } from '../../../../helpers/longStrings/convertToShorterString'
import StandardCtaBtn from '../../Button/standardCtaBtn'
import { generateIcon } from './attachmentInItemSinglePageDesktop'
import dayjs from 'dayjs'
import ThreeDotsIcon from '../../icons/components/threeDots'
import { useState } from 'react'
import DownloadIcon from '../../icons/components/downloadIcon'
import PageSubMenuActions from '../../menu/pageSubMenuActions/pageSubMenuActions'
import BinIcon from '../../icons/components/binIcon'

interface IStyled {
    someProp?: any
}

const Container = styled.div<IStyled>`
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 10px;
    gap: 16px;
`

const FileName = styled.a<IStyled>`
    flex: 1;
    font-family: 'Lato';
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    :hover {
        color: var(--primary, #5ec3ca);
    }
`

const Added = styled.div`
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
`

type Props = {
    dataCyId?: string
    attachment: IAttachmentItem
    onClickDelete: (id: string) => any
    readOnlyMode?: boolean
}

function AttachmentInItemSinglePageMobile(props: Props) {
    const { dataCyId, attachment, onClickDelete, readOnlyMode } = props
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const toggleMenu = (v: boolean) => setIsMenuOpen(v)
    return (
        <>
            <Container data-attr={dataCyId && dataCyId}>
                {generateIcon(attachment)}
                <div style={{ width: '100%' }}>
                    <FileName
                        href={attachment.url}
                        target="_blank"
                        download
                        rel="noreferrer"
                    >
                        {attachment
                            ? attachment.filename &&
                              convertToShorterString({
                                  string: attachment.filename,
                                  maxChars: 20,
                                  isFileName: true,
                              })
                            : ''}
                    </FileName>
                    <Added>
                        Added on{' '}
                        {dayjs(attachment.created_at).format('DD.MM.YYYY')}
                    </Added>
                </div>

                {readOnlyMode ? null : (
                    <StandardCtaBtn
                        bg="transparent"
                        boder="none"
                        removeShadowOnHover
                        customWidth="32px"
                        customHeight="auto"
                        removePaddings
                        onClick={() => {
                            toggleMenu(true)
                        }}
                    >
                        <ThreeDotsIcon />
                    </StandardCtaBtn>
                )}
            </Container>

            {isMenuOpen && (
                <PageSubMenuActions
                    header={attachment.filename}
                    items={[
                        {
                            id: 'download',
                            text: 'Download attachment',
                            icon: <DownloadIcon />,
                            action: () => window.open(attachment.url, '_blank'),
                        },
                        {
                            id: 'delete',
                            text: 'Delete attachment',
                            icon: <BinIcon color="#DF6F6F" />,
                            color: '#DF6F6F',
                            action: () => onClickDelete(attachment.id),
                        },
                    ]}
                    toggle={() => setTimeout(() => toggleMenu(false), 50)}
                    isOpen={isMenuOpen}
                    dataCyPrefix="timeline-entry"
                />
            )}
        </>
    )
}

export default AttachmentInItemSinglePageMobile
