import { IDropdownItem } from 'entityModels'
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import { fields_array_insurance_application_everyday_car_techinfo } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import LoaderWithPercentage from '../../../../../atoms/loader/loaderWithPercentage'

import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import useThemes from '../../../../../../providers/theme/hooks'
import colours from '../../../../../../providers/theme/colours'

type Props = {
    car_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}
const InsuranceEverydayCarTechInfoWizard = (props: Props) => {
    let carid = props.car_id
    const dispatch = useAppDispatch()

    const history = useHistory()

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    let {
        setInitialCarTechnicalDropdowns,
        setInitialInsurance_CarTechInfo,
        getInitialSectionWizardIds,
        getFirstIncompleteSubsection_Single_EverydayVehicle,
    } = useSetInitialInsurance()

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.submitted_data.vehicles[carid] &&
                state.insuranceQuoteApplication.submitted_data.vehicles[carid]
                    .tech_info) ??
            {}
        )
    })

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.draft.vehicles[carid] &&
                state.insuranceQuoteApplication.draft.vehicles[carid]
                    .tech_info) ??
            {}
        )
    })
    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid] &&
            state.insuranceQuoteApplication.draft.vehicles[carid]
                ?.tech_info_errors
        )
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_car_techInfo({
                carid: carid,
                id: id,
                error: error,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        if (!data_draft) {
            hasErr2 = true
        }

        if (data_draft) {
            for (
                let i = 0;
                i <
                fields_array_insurance_application_everyday_car_techinfo.length;
                i++
            ) {
                if (
                    fields_array_insurance_application_everyday_car_techinfo[i]
                        .is_required === true
                ) {
                    if (
                        data_draft[
                            fields_array_insurance_application_everyday_car_techinfo[
                                i
                            ].id
                        ] === undefined ||
                        data_draft[
                            fields_array_insurance_application_everyday_car_techinfo[
                                i
                            ].id
                        ] === '' ||
                        data_draft[
                            fields_array_insurance_application_everyday_car_techinfo[
                                i
                            ].id
                        ] === null
                    ) {
                        setError(
                            fields_array_insurance_application_everyday_car_techinfo[
                                i
                            ].id,
                            'Required field.'
                        )

                        hasErr2 = true
                    }
                }

                if (
                    fields_array_insurance_application_everyday_car_techinfo[i]
                        .validation &&
                    data_draft[
                        fields_array_insurance_application_everyday_car_techinfo[
                            i
                        ].id
                    ] !== undefined
                ) {
                    let is_valid_func =
                        fields_array_insurance_application_everyday_car_techinfo[
                            i
                        ].validation
                    let value =
                        data_draft[
                            fields_array_insurance_application_everyday_car_techinfo[
                                i
                            ].id
                        ]
                    let error_txt =
                        fields_array_insurance_application_everyday_car_techinfo[
                            i
                        ].error_txt

                    if (is_valid_func && is_valid_func(value) !== true) {
                        setError(
                            fields_array_insurance_application_everyday_car_techinfo[
                                i
                            ].id,
                            error_txt ?? 'Invalid'
                        )
                        hasErr2 = true
                    }
                }
            }
        }

        return hasErr2
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let add_new_external_q = queryParams.get('add_new_external')

    let add_new_external = add_new_external_q === 'true'

    useEffect(() => {
        setInitialCarTechnicalDropdowns(carid)
        setInitialInsurance_CarTechInfo(carid)
    }, [userLoggedIn])

    useEffect(() => {
        if (typeof data_draft.make === 'string') {
            dispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: data_submitted.make,
                })
            )
        } else if (data_draft.make && data_draft.make.name) {
            dispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: data_draft.make?.name,
                    make_uid: data_draft.make?.uid,
                })
            )
        }
    }, [data_draft])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let is_loading: boolean = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    const goNext = () => {
        let ids = getFirstIncompleteSubsection_Single_EverydayVehicle(
            carid,
            'tech_info'
        )

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return history.replace({
                search: params2.toString(),
            })
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('everyday_vehicles')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else
                history.push(
                    `/insurance/application/${applicationData?.id}/wizard?section_id=everyday_vehicles`
                )
        }

        return getToAny()
    }

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carTechInfo_request({
                    carid: carid,
                })
            )
            // next step
            setTimeout(() => {
                goNext()
            }, 100)
        } else {
            setIsSavedDisabled(true)
        }
    }

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    let currUrl = `/insurance/application/${applicationData?.id}/wizard?section_id=everyday_vehicles&subsection_id=tech_info&car_id=${carid}`

    let goPrevious = () => {
        history.push(
            `/insurance/application/${applicationData?.id}/wizard?section_id=everyday_vehicles`
        )
    }

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_insurance_car_techInfo({
                carid: carid,
                id: id,
                answer: answer,
            })
        )
        // having to do it below
        // if (id === 'make') {
        //     dispatch(
        //         dropDownActions.setModelDropdownDataRequest({
        //             makeName: answer?.name,
        //         })
        //     )
        // }

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'everyday_vehicles',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'everyday_vehicles',
            onSave: () => saveForm(),
            has_changes: false,
        })
    }, [])

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <LoaderWithPercentage isLoading={is_loading} />

            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    {add_new_external && (
                        <InsuranceQuoteFormGenerator
                            sectionID="everyday_vehicles"
                            list={[
                                {
                                    id: 'registration_number',
                                    type: 'simple_txt',
                                    text: 'Car registration number',
                                    is_required: true,
                                    isMobileAnimated: true,
                                    ends_section_with_divider: true,
                                },
                            ]}
                            onAnswerChange={onAnswerChange}
                            data={data_draft}
                            errors={errors}
                            setError={setError}
                        />
                    )}
                    <InsuranceQuoteFormGenerator
                        sectionID="everyday_vehicles"
                        list={
                            fields_array_insurance_application_everyday_car_techinfo
                        }
                        onAnswerChange={onAnswerChange}
                        data={data_draft}
                        errors={errors}
                        setError={setError}
                    />
                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    isSaveLoading={isLoadingNewMakeOrModel}
                    sectionId="everyday_vehicles"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="everyday_vehicles"
                            urlBack={currUrl}
                            draft={data_draft}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                if (field_id === 'make' && value?.name) {
                                    dispatch(
                                        dropDownActions.setModelDropdownDataRequest(
                                            {
                                                makeName: value?.name,
                                                make_uid: value.uid,
                                            }
                                        )
                                    )
                                }

                                dispatch(
                                    insuranceActions.set_answer_insurance_car_techInfo(
                                        {
                                            id: `${field_id}`,
                                            answer: value,
                                            carid: carid,
                                        }
                                    )
                                )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '88vw',
                                paddingBottom: 120,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            {add_new_external && (
                                <InsuranceQuoteFormGenerator
                                    sectionID="everyday_vehicles"
                                    list={[
                                        {
                                            id: 'registration_number',
                                            type: 'simple_txt',
                                            text: 'Car registration number',
                                            is_required: true,
                                            isMobileAnimated: true,
                                            ends_section_with_divider: true,
                                        },
                                    ]}
                                    onAnswerChange={onAnswerChange}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                    mobileSearchPickerSectionURL={currUrl}
                                />
                            )}

                            <InsuranceQuoteFormGenerator
                                sectionID="everyday_vehicles"
                                list={
                                    fields_array_insurance_application_everyday_car_techinfo
                                }
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                mobileSearchPickerSectionURL={currUrl}
                            />
                            <div style={{ paddingTop: '200px' }} />
                        </div>
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() => goPrevious()}
                            isSaveLoading={isLoadingNewMakeOrModel}
                            sectionId="everyday_vehicles"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceEverydayCarTechInfoWizard
