import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { device } from '../devices'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { checkIfPwaStandalone } from '../../pwaCustom/checkIpadPwa'

function mapStateToProps(state: RootState) {
    return {
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

interface Props {
    children?: React.ReactNode
    isCollapsed: boolean
    fullwidth?: boolean
    hasMobileSubNav?: boolean
    removePaddings?: boolean
    removePaddingsOnAllDevices?: boolean
    removeHorizPaddings?: boolean
    removePaddingBottom?: boolean
}

interface Istyle {
    isCollapsed?: boolean
    fullwidth?: boolean
    isExternalPage?: boolean
    hasMobileSubNav?: boolean
    removePaddings?: boolean
    removePaddingsOnAllDevices?: boolean
    removeHorizPaddings?: boolean
}

const PageWrapper = styled.div<Istyle>`
    @media (min-width: 881px) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-top: ${(props) =>
            props.removePaddings || props.removePaddingsOnAllDevices
                ? '0px'
                : props.isExternalPage
                ? '105px'
                : '76px'};
        transition: all 100ms;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        transition: all 500ms;
        max-width: ${(props) => (props.fullwidth ? 'none' : '1400px')};
        padding-bottom: ${(props) =>
            props.removePaddings ? '0px' : props.fullwidth ? '0px' : '200px'};
    }

    @media ${device.beyond_ipad_mobile} {
        padding-left: ${(props) =>
            props.removeHorizPaddings ? '0px' : '50px'};
        padding-right: ${(props) =>
            props.removeHorizPaddings ? '0px' : '50px'};
    }

    @media (min-width: 2000px) {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media (max-width: 880px) {
        padding-top: ${(props) =>
            props.removePaddingsOnAllDevices
                ? 0
                : props.hasMobileSubNav
                ? '115px'
                : '55px'};
        width: 100%;
        height: 100%;
    }
    @media (max-width: 500px) {
        padding-top: ${(props) =>
            props.removePaddingsOnAllDevices
                ? 0
                : props.hasMobileSubNav
                ? '98px'
                : '55px'};
        width: 100%;
        height: 100%;
    }
`

export const MenuAdjust = styled.div<Istyle>`
    display: flex;
    width: 100%;
    height: 100%;

    align-items: center;
    transition: all 100ms;
    flex-direction: column;

    @media (max-width: 799px) {
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
    }

    @media ${device.desktop} {
        padding-left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
    @media ${device.large_desktop} {
        padding-left: ${(props) => (props.isCollapsed ? '71px' : '271px')};
    }

    @media ${device.smallest_laptops} {
        padding-left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }

    @media ${device.mobile_and_ipad} {
        padding-top: ${(props) =>
            props.removePaddingsOnAllDevices === true ? 0 : '18px'};
    }
`

interface Props extends RouteComponentProps<any> {}

const CenteredPageWrapper: React.SFC<Props> = (props) => {
    let currentPath = checkIfPwaStandalone()
        ? window.location.pathname
        : window.location.pathname

    let isExternalPage: boolean = currentPath.match(/shared/g) ? true : false

    return (
        <React.Fragment>
            <MenuAdjust
                isCollapsed={props.isCollapsed}
                id="menu-adjust-wrapper"
                removePaddingsOnAllDevices={props.removePaddingsOnAllDevices}
            >
                <PageWrapper
                    isExternalPage={isExternalPage}
                    fullwidth={props.fullwidth}
                    hasMobileSubNav={props.hasMobileSubNav}
                    removePaddings={props.removePaddings}
                    removePaddingsOnAllDevices={
                        props.removePaddingsOnAllDevices
                    }
                    removeHorizPaddings={props.removeHorizPaddings}
                    style={
                        props.removePaddingBottom ? { paddingBottom: 0 } : {}
                    }
                >
                    {props.children}
                </PageWrapper>
            </MenuAdjust>
        </React.Fragment>
    )
}

export default withRouter(connect(mapStateToProps, {})(CenteredPageWrapper))
