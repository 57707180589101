import { motion } from 'framer-motion'
import styled from 'styled-components'
import Faded from '../../templates/animated/faded'
import threeDots from '../../../public/assets/icons/threeDots.svg'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

type Props = {
    onClick: any
    isOpen: boolean
    width?: string
    isSolid?: boolean
    customWidth?: string
    customHeight?: string
}

const Square = styled.div`
    border-radius: 4px;
    border: 1px solid var(--primary, #5ec3ca);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    :hover {
        box-shadow: none;
        border-width: 2px;
        background-color: var(--primary_20) !important;
        transition: border-width 50ms;
        transition: background-color 200ms;
    }
`

const MenuDots = ({ onClick, isOpen, width, isSolid, customHeight }: Props) => {
    const { theme } = useThemes()
    return (
        <motion.div
            style={{
                cursor: 'pointer',
            }}
            onClick={onClick}
            whileTap={{ scale: 1.3 }}
        >
            <Square
                style={{
                    width: width ? width : '37px',
                    height: customHeight
                        ? customHeight
                        : width
                        ? width
                        : '37px',
                    backgroundColor: isOpen
                        ? colours[theme].primary_20
                        : isSolid
                        ? colours[theme].background_default
                        : 'transparent',
                }}
            >
                {isOpen && (
                    <Faded>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={threeDots}
                                style={{
                                    width: '16px',
                                }}
                                alt="car actions menu options"
                            />
                        </div>
                    </Faded>
                )}
                {!isOpen && (
                    <Faded>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <img
                                src={threeDots}
                                style={{
                                    width: '16px',
                                }}
                                alt="car actions menu options"
                            />
                        </div>
                    </Faded>
                )}
            </Square>
        </motion.div>
    )
}

export default MenuDots
