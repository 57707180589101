import styled from 'styled-components'
import { IInsuranceOnboardingProps } from '../../../pages/insurance_quote/insuranceWelcomeWithCars'

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex-direction: row;

    @media (max-width: 744px) {
        padding-top: 12px;
    }
`

type IStyledProps = {
    $is_active: boolean
}
const Bar = styled.div<IStyledProps>`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex-direction: row;
    width: 100%;
    background-color: ${(props) =>
        props.$is_active
            ? 'var(--text-strong, #1a1a1a)'
            : 'var(--border-muted, #e5e5e5)'};
    border-radius: 100px;
    width: ${(props) => (props.$is_active ? '48px' : '16px')};
    height: 8px;
    transition: all 200ms;

    @media (max-width: 744px) {
        width: ${(props) => (props.$is_active ? '36px' : '12px')};
        height: 6px;
    }
`

export const InsuranceOnboardingMenu = (props: IInsuranceOnboardingProps) => {
    return (
        <Row>
            <Bar $is_active={props.data.id === '1' ? true : false} />
            <Bar $is_active={props.data.id === '2' ? true : false} />
            <Bar $is_active={props.data.id === '3' ? true : false} />
        </Row>
    )
}
