import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/handover/learn_1_d.png'

import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import { CenterImg, ParagraphTitleJourney } from '../pastCars/memories'

import m_1 from '../../../../public/assets/images/journey/handover/learn_1_m.png'
import m_2 from '../../../../public/assets/images/journey/handover/learn_2_m.png'
import m_3 from '../../../../public/assets/images/journey/handover/learn_3_m.png'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

const JourneyWelcomeText = styled.div`
    padding: 0px;
    margin: 0px;
    color: var(--text-darker, #616161);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    width: 599px;
    max-width: 90vw;

    @media ${device.mobile_and_ipad} {
        width: 100%;
        max-width: 450px !important;
        text-align: left;
        padding: 16px 24px;
    }

    @media (max-width: 350px) {
        font-size: 14px;
    }
`

export default function HandoverLearnJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section['handover']
                                        .primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[`handover`]
                                        .primary_100
                                }
                            />

                            <JourneyColumnContent style={{ gap: 16 }}>
                                <StandardCtaBtn
                                    bg="transparent"
                                    customWidth="auto"
                                    customHeight="auto"
                                    removePaddings
                                    removeShadowOnHover
                                    onClick={goToPrevStep}
                                >
                                    <JourneyBtnContent $theme={theme}>
                                        <div
                                            style={{
                                                transform: 'rotate(90deg)',
                                            }}
                                        >
                                            <ChevronDown />
                                        </div>
                                        Back
                                    </JourneyBtnContent>
                                </StandardCtaBtn>
                                <JourneyStepTitle $theme={theme}>
                                    What is a Digital Car Handover?
                                </JourneyStepTitle>

                                <JourneyWelcomeText
                                    style={{
                                        textAlign: 'left',
                                        width: 'auto',
                                    }}
                                >
                                    It's just like when you handover the keys,
                                    and a car's physical history file - just the
                                    modern version!
                                </JourneyWelcomeText>

                                <JourneyWelcomeText
                                    style={{
                                        textAlign: 'left',
                                        width: 'auto',
                                    }}
                                >
                                    Car Handover lets you pass the digital
                                    history, images and profile of your car to a
                                    new owner to enjoy.
                                </JourneyWelcomeText>

                                <div />

                                <JourneyColumnContent style={{ gap: 8 }}>
                                    <ParagraphTitleJourney>
                                        Preserve Your Car's Legacy
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Handing over your car allows you to
                                        preserve its history and memories for
                                        future owners. By documenting its
                                        journey on Custodian, you ensure that
                                        its story lives on, creating a valuable
                                        asset for both you and the car's next
                                        owner.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 8 }}>
                                    <ParagraphTitleJourney>
                                        Seamless Transfer of Ownership
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        The Car Handover process is designed to
                                        be simple and efficient. With just a few
                                        clicks, you can transfer ownership to
                                        the new owner, ensuring a smooth
                                        transition of responsibilities and
                                        documentation.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 8 }}>
                                    <ParagraphTitleJourney>
                                        Never Forget
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        We understand how a car is more than
                                        just an object, its an emotional
                                        connection, a culmination of the
                                        adventures, trips and experiences you've
                                        had together.
                                        <br /> <br />
                                        Even when you say goodbye to your car
                                        and its physical history file, Custodian
                                        keeps a copy in your Previously Owned
                                        Garage. Take comfort that with Custodian
                                        you can take a trip down memory lane and
                                        revisit those adventure again and again.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'handover'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'handover'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <img
                                src={image}
                                style={{
                                    objectFit: 'contain',
                                    maxWidth: '80vw',
                                    maxHeight: '99vh',
                                    transform: 'translate(40px, 40px)',
                                }}
                            />
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <JourneyPageWrapperMobile
                        allowScroll={true}
                        title={'What is a Digital Car Handover?'}
                        sectionID="handover"
                        subtitle={`Give your car a permanent history for you and future owners`}
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={() => {
                            goToPrevStep()
                        }}
                        onNext={() => {
                            goToNextStep()
                        }}
                    >
                        <JourneyColumnContent
                            style={{
                                gap: 0,
                            }}
                        >
                            <JourneyWelcomeText style={{ paddingTop: '0px' }}>
                                It's just like when you handover the keys, and a
                                car's physical history file - just the modern
                                version!
                                <br />
                                <br />
                                Car Handover lets you pass the digital history,
                                images and profile of your car to a new owner to
                                enjoy.
                            </JourneyWelcomeText>

                            <JourneyColumnContent
                                style={{
                                    paddingTop: '12px',
                                    gap: 4,
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }}
                            >
                                <ParagraphTitleJourney>
                                    Preserve Your Car's Legacy
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Handing over your car allows you to preserve
                                    its history and memories for future owners.
                                    By documenting its journey on Custodian, you
                                    ensure that its story lives on, creating a
                                    valuable asset for both you and the car's
                                    next owner.
                                </JourneyText>
                            </JourneyColumnContent>
                            <div style={{ paddingTop: '36px' }} />
                            <CenterImg
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={m_1}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '100vw',
                                        maxHeight: '60vh',
                                        alignSelf: 'center',
                                    }}
                                />
                            </CenterImg>
                            <div style={{ paddingTop: '12px' }} />
                            <JourneyColumnContent
                                style={{
                                    gap: 4,
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }}
                            >
                                <ParagraphTitleJourney>
                                    Seamless Transfer of Ownership
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    The Car Handover process is designed to be
                                    simple and efficient. With just a few
                                    clicks, you can transfer ownership to the
                                    new owner, ensuring a smooth transition of
                                    responsibilities and documentation.
                                </JourneyText>
                            </JourneyColumnContent>

                            <div style={{ paddingTop: '12px' }} />
                            <CenterImg
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={m_2}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '90vw',
                                        maxHeight: '60vh',
                                        alignSelf: 'center',
                                        transform: 'translateX(10px)',
                                    }}
                                />
                            </CenterImg>
                            <div style={{ paddingTop: '12px' }} />
                            <JourneyColumnContent
                                style={{
                                    gap: 4,
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }}
                            >
                                <ParagraphTitleJourney>
                                    Maintain Transparency and Trust
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    By using Custodian's Car Handover feature,
                                    you maintain transparency and trust
                                    throughout the ownership transfer process.
                                    Future owners can access the car's detailed
                                    history, fostering confidence and peace of
                                    mind.
                                </JourneyText>
                            </JourneyColumnContent>
                            <div style={{ paddingTop: '28px' }} />
                            <CenterImg
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={m_3}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '80vw',
                                        maxHeight: '60vh',
                                        alignSelf: 'center',
                                    }}
                                />
                            </CenterImg>
                        </JourneyColumnContent>

                        <div style={{ paddingTop: '50px' }} />
                    </JourneyPageWrapperMobile>
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
