import { IAddEntryAttachmenstApiRequest } from 'ApiInterfaces'
import { removeUndefinedfromObject } from '../../../helpers/objects'
import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'

export const addEntryAttachmentsApi = (p: IAddEntryAttachmenstApiRequest) => {
    let ats: any = p.attachments

    // run removeUndefined func here (move from down);

    let arr: any[] = []

    for (let i = 0; i < ats.length; i++) {
        if (ats[i] && ats.uid) {
            delete ats.uid
        }
        arr.push(removeUndefinedfromObject(p.attachments[i]))
    }

    let attachmentsData: string = JSON.stringify(arr)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: attachmentsData,
        credentials: 'include',
    }

    return fetch(
        urls.add_entry_attachment(p.carID, p.entryID),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Add entry error',
                        attachmentsData
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
