import { insurance_colours } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    width?: number
}
function InsuranceAddressesIcon(props: Props) {
    const { theme } = useThemes()
    return (
        <svg
            width={props.width ?? 40}
            height={props.width ?? 40}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect
                width={40}
                height={40}
                rx={4}
                fill={insurance_colours[theme].sections[`addresses`].primary_08}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.912 25.443a.75.75 0 01.624-.334H17.7a.75.75 0 010 1.5h-4.764l-3.26 4.89h20.631l-3.26-4.89h-4.764a.75.75 0 110-1.5h5.165a.75.75 0 01.624.334l4.26 6.39A.75.75 0 0131.71 33H8.275a.75.75 0 01-.624-1.166l4.26-6.391z"
                fill={
                    insurance_colours[theme].sections[`addresses`].primary_100
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.449 15.207c0 4.118-7.457 13.847-7.457 13.847s-7.456-9.73-7.456-13.847a7.457 7.457 0 0114.913 0zm-4.261 0a3.195 3.195 0 11-6.391 0 3.195 3.195 0 016.39 0z"
                fill={insurance_colours[theme].sections[`addresses`].primary_24}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.992 8.5a6.707 6.707 0 00-6.706 6.707c0 .852.4 2.103 1.094 3.582.68 1.452 1.594 3.024 2.518 4.485a80.75 80.75 0 003.094 4.526 80.743 80.743 0 003.094-4.526c.924-1.46 1.838-3.033 2.519-4.485.693-1.48 1.094-2.73 1.094-3.583A6.706 6.706 0 0019.992 8.5zm0 20.554l-.595.456-.006-.008-.017-.021-.064-.085a81.31 81.31 0 01-1.093-1.49 82.258 82.258 0 01-2.587-3.83c-.94-1.487-1.89-3.119-2.608-4.65-.705-1.503-1.236-3.012-1.236-4.22a8.207 8.207 0 0116.413 0c0 1.207-.532 2.717-1.236 4.22-.718 1.531-1.668 3.163-2.609 4.65a82.33 82.33 0 01-3.68 5.32l-.064.085-.016.021-.006.008-.596-.456zm0 0l.596.456a.752.752 0 01-1.191 0l.595-.456z"
                fill={
                    insurance_colours[theme].sections[`addresses`].primary_100
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.992 12.76a2.445 2.445 0 100 4.892 2.445 2.445 0 000-4.891zm-3.946 2.446a3.945 3.945 0 117.891 0 3.945 3.945 0 01-7.89 0z"
                fill={insurance_colours[theme].sections[`addresses`].primary_60}
            />
        </svg>
    )
}

export default InsuranceAddressesIcon
