import { createAction } from '@reduxjs/toolkit'
import { ISearchUsersResult } from 'entityModels'
import {
    CHECK_USER_EMAIL_EXISTS_SUCCESS,
    CLEAN_UP_SEARCH_USERS_SUCCESS,
    SEARCH_PLATFORM_USERS_SUCCESS,
} from './constants'

export const searchPlatformUsersSuccess = createAction(
    SEARCH_PLATFORM_USERS_SUCCESS,
    function prepare(payload: ISearchUsersResult[] | undefined) {
        return {
            payload,
        }
    }
)

export const checkUserEmailExistsSuccess = createAction(
    CHECK_USER_EMAIL_EXISTS_SUCCESS,
    function prepare(payload: ISearchUsersResult[] | undefined) {
        return {
            payload,
        }
    }
)

export const cleanUpSearchUsersSuccess = createAction(
    CLEAN_UP_SEARCH_USERS_SUCCESS
)
