import { IApexCarOfTheWeekState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import external_link_green from '../../../../../public/assets/icons/external_link_green.svg'

type IStyledProps = {
    index: number
}
const Container = styled.div<IStyledProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    vertical-align: top;
    padding-bottom: 50px;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    padding-bottom: 50px;

    box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 3%);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--bg-color, #fff);
`

const Image = styled.img`
    width: 100%;
    height: 40vh;
    padding: 30px;
    object-fit: cover;
    border-radius: 36px;

    @media ${device.smallest_laptops} {
        height: 41vh;
    }
    @media ${device.large_desktop} {
        height: 350px;
    }

    @media ${device.ipad} {
        height: 300px;
    }
`

const DivWithPaddingApplied = styled.div`
    padding-left: 30px;
    padding-right: 30px;
`

const Date = styled(DivWithPaddingApplied)`
    font-family: Lato-Light;
    font-size: 12px;
`

const Name = styled(DivWithPaddingApplied)`
    font-family: Lato;
    font-size: 21px;
    padding-top: 12px;
    color: var(--text-strong, #1a1a1a);
`

const Title = styled(DivWithPaddingApplied)`
    font-family: Lato-Light;
    font-size: 19px;
    padding-top: 2px;
`

const Description = styled(DivWithPaddingApplied)`
    line-height: 26px;
    font-family: Lato-Light;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
    padding-top: 12px;
    padding-bottom: 20px;
    padding-right: 50px;
`

const ReadMore = styled.a`
    display: flex;
    font-family: Lato;
    font-size: 12px;
    color: var(--primary, #5ec3ca) !important;
    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    text-transform: uppercase;
    padding-left: 30px;
    padding-right: 30px;

    @media ${device.ipad} {
        font-size: 13px;
    }
`
const ExternalLinkIcon = styled.img`
    width: 11px;
    height: 11px;
    margin-left: 8px;
    transform: translateY(2px);
`

type Props = {
    car: IApexCarOfTheWeekState
    index: number
}

class ApexCarOfTheWeekItemDesktop extends React.Component<Props> {
    render() {
        let { car, index } = this.props

        return (
            <Container index={index}>
                <Wrapper>
                    <Image
                        src={car.img && car.img.url ? car.img.url : ''}
                        alt={car.name}
                    />
                    <Date>{car.date}</Date>
                    <Name>{car.name}</Name>
                    <Title>{car.title}</Title>

                    <Description>{car.description}</Description>
                    <ReadMore href={car.link} target="_blank">
                        Take a Closer Look
                        <ExternalLinkIcon src={external_link_green} />
                    </ReadMore>
                </Wrapper>
            </Container>
        )
    }
}

export default ApexCarOfTheWeekItemDesktop
