import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import useThemes from '../../../../../../providers/theme/hooks'
import { useEffect } from 'react'
import { journey_colours } from '../../../../../../providers/theme/colours'
import FormStepProgress from '../../../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../../../atoms/icons/components/chevronDown'
import Faded from '../../../../../templates/animated/faded'
import EnterIcon from '../../../../../atoms/icons/components/enterIcon'
import { message } from 'antd'

const errorInfo = (messageText: string) => {
    message.error(messageText, 5)
}

import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../../../templates/styledcomponents/journeyStyles'
import TimelineItemBodyDesktop from '../../../../../molecules/timelineItemDesktop/timelineItemBodyDesktop'
import { sampleData } from '../../../../../../redux/timeline/data'
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import { ITimelineItem } from 'timelineModels'
import { JourneyHFEntriesForm } from './entriesFormBody'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
    entry1: ITimelineItem | undefined
    entry2: ITimelineItem | undefined
    entry3: ITimelineItem | undefined
    setEntry1: (p: ITimelineItem | undefined) => any
    setEntry2: (p: ITimelineItem | undefined) => any
    setEntry3: (p: ITimelineItem | undefined) => any
}

const CardImageWrapper = styled.div`
    border-radius: 16px;

    background: var(--bg-color, #fff);
    box-shadow: 64px 64px 128px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    width: 550px;

    transform: scale(0.9) translateY(-10px);

    @media ${device.mobile} {
    }
`

const EntryTxt = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
`
export default function AddHistoryFileJourneyStepDesktop({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
    entry1,
    setEntry1,
    entry2,
    setEntry2,
    entry3,
    setEntry3,
}: Props) {
    const { theme } = useThemes()

    useEffect(() => {
        const continueOnEnter = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                if (
                    (entry1 && entry1.categoryID === 'insurance') ||
                    (entry2 && entry2.categoryID === 'insurance') ||
                    (entry2 && entry2.categoryID === 'insurance')
                ) {
                    if (
                        (entry1 &&
                            !entry1?.insurance_entry_policy_start_date) ||
                        (entry1 && !entry1?.insurance_entry_policy_end_date)
                    ) {
                        errorInfo(
                            `Please enter the insurance entry's dates. If not sure, you can pick some approximative ones.`
                        )
                        return
                    }
                    if (
                        (entry2 &&
                            !entry2?.insurance_entry_policy_start_date) ||
                        (entry2 && !entry2?.insurance_entry_policy_end_date)
                    ) {
                        errorInfo(
                            `Please enter the insurance entry's dates. If not sure, you can pick some approximative ones.`
                        )
                        return
                    }
                    if (
                        (entry3 &&
                            !entry3?.insurance_entry_policy_start_date) ||
                        (entry3 && !entry3?.insurance_entry_policy_end_date)
                    ) {
                        errorInfo(
                            `Please enter the insurance entry's dates. If not sure, you can pick some approximative ones.`
                        )
                        return
                    }
                } else {
                    if (entry1 && !entry1?.occurred_at) {
                        errorInfo(
                            `Please enter the first entry's date. If not sure, you can pick an approximative one.`
                        )
                        return
                    }
                    if (entry2 && !entry2?.occurred_at) {
                        errorInfo(
                            `Please enter the first entry's date. If not sure, you can pick an approximative one.`
                        )
                        return
                    }
                    if (entry2 && !entry2?.occurred_at) {
                        errorInfo(
                            `Please enter the first entry's date. If not sure, you can pick an approximative one.`
                        )
                        return
                    }
                }

                goToNextStep()
            }
        }

        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [entry1, entry2, entry3])

    return (
        <DesktopDisplayOnly>
            <Faded>
                <JourneySectionWrapper>
                    <JourneyLeftSide $theme={theme} style={{ gap: '24px' }}>
                        <FormStepProgress
                            screen_name="desktop"
                            currentStep={step}
                            totalSteps={totalSteps}
                            activeColour={
                                journey_colours[theme].section['history_file']
                                    .primary_500
                            }
                            passiveColour={
                                journey_colours[theme].section[`history_file`]
                                    .primary_100
                            }
                        />

                        <JourneyColumnContent style={{ gap: 16 }}>
                            <StandardCtaBtn
                                bg="transparent"
                                customWidth="auto"
                                customHeight="auto"
                                removePaddings
                                removeShadowOnHover
                                onClick={goToPrevStep}
                            >
                                <JourneyBtnContent $theme={theme}>
                                    <div
                                        style={{
                                            transform: 'rotate(90deg)',
                                        }}
                                    >
                                        <ChevronDown />
                                    </div>
                                    Back
                                </JourneyBtnContent>
                            </StandardCtaBtn>
                            <JourneyStepTitle $theme={theme}>
                                As easy as 1, 2, 3
                            </JourneyStepTitle>
                        </JourneyColumnContent>

                        <JourneyText $theme={theme}>
                            Let's get started by adding three entries to your
                            history file! As mentioned you can add as many
                            entries as you like later on. Custodian has no
                            limitations on how many history file entries can be
                            stored per vehicle.
                        </JourneyText>
                        <JourneyColumnContent style={{ paddingTop: '12px' }}>
                            <EntryTxt>First Entry</EntryTxt>
                            <div style={{ paddingTop: '22px' }} />
                            <JourneyColumnContent style={{ gap: 18 }}>
                                <JourneyHFEntriesForm
                                    entry={entry1}
                                    setEntry={setEntry1}
                                />
                            </JourneyColumnContent>

                            <EntryTxt>Second Entry</EntryTxt>
                            <div style={{ paddingTop: '22px' }} />
                            <JourneyColumnContent style={{ gap: 18 }}>
                                <JourneyHFEntriesForm
                                    entry={entry2}
                                    setEntry={setEntry2}
                                />
                            </JourneyColumnContent>

                            <EntryTxt>Third Entry</EntryTxt>
                            <div style={{ paddingTop: '22px' }} />

                            <JourneyColumnContent style={{ gap: 18 }}>
                                <JourneyHFEntriesForm
                                    entry={entry3}
                                    setEntry={setEntry3}
                                />
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                        <JourneyRowContent style={{ gap: 32 }}>
                            <StandardCtaBtn
                                onClick={() => {
                                    goToNextStep()
                                }}
                                isDisabled={false}
                                bg={
                                    journey_colours[theme].section[
                                        'history_file'
                                    ].primary_500
                                }
                                customHoverColor={
                                    journey_colours[theme].section[
                                        'history_file'
                                    ].primary_600
                                }
                            >
                                Continue
                            </StandardCtaBtn>

                            <JourneyRowContent style={{ gap: 8 }}>
                                <JourneyIconWrapper $theme={theme}>
                                    <EnterIcon />
                                </JourneyIconWrapper>{' '}
                                <JourneyEnterTxt $theme={theme}>
                                    Or press Enter
                                </JourneyEnterTxt>
                            </JourneyRowContent>
                        </JourneyRowContent>
                    </JourneyLeftSide>
                    <JourneyRightSide>
                        <JourneyColumnContent
                            style={{
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%',
                                maxHeight: '80vh',
                                overflowY: 'scroll',
                                gap: '0px',
                            }}
                        >
                            <CardImageWrapper>
                                <TimelineItemBodyDesktop
                                    userCurrency={
                                        entry1?.costItemsObj &&
                                        entry1.costItemsObj['id'].currency
                                            ? entry1.costItemsObj['id'].currency
                                            : '£'
                                    }
                                    item={entry1 ?? sampleData[0]}
                                    hasSampleData={entry1 ? false : true}
                                    readOnlyMode={true}
                                />
                            </CardImageWrapper>
                            <CardImageWrapper>
                                <TimelineItemBodyDesktop
                                    userCurrency={
                                        entry2?.costItemsObj &&
                                        entry2.costItemsObj['id'].currency
                                            ? entry2.costItemsObj['id'].currency
                                            : '£'
                                    }
                                    item={entry2 ?? sampleData[1]}
                                    hasSampleData={entry2 ? false : true}
                                    readOnlyMode={true}
                                />
                            </CardImageWrapper>
                            <CardImageWrapper>
                                <TimelineItemBodyDesktop
                                    userCurrency={
                                        entry3?.costItemsObj &&
                                        entry3.costItemsObj['id'].currency
                                            ? entry3.costItemsObj['id'].currency
                                            : '£'
                                    }
                                    item={entry3 ?? sampleData[1]}
                                    hasSampleData={entry3 ? false : true}
                                    readOnlyMode={true}
                                />
                            </CardImageWrapper>
                        </JourneyColumnContent>
                    </JourneyRightSide>
                </JourneySectionWrapper>
            </Faded>
        </DesktopDisplayOnly>
    )
}
