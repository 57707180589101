import pcclogo from '../../../public/assets/logo/newlogo.svg'
import styled from 'styled-components'

const DesktopLogoContainer = styled.div`
    top: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
    font-family: 'arial';
    position: fixed;
`

const PCCtitleDesktop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    font-weight: 600;
`

const PCClogoDesktop = styled.img`
    width: 46px;
    height: 58px;
    margin-left: 20px;
    margin-right: 55px;
`

const DesktopLogoHeader = () => (
    <DesktopLogoContainer>
        <PCCtitleDesktop>
            <div>PRIVATE</div>
            <div>COLLECTORS</div>
            <div>CLUB</div>
        </PCCtitleDesktop>
        <PCClogoDesktop src={pcclogo} alt="PCC" />
    </DesktopLogoContainer>
)

export default DesktopLogoHeader
