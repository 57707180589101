// create store for todos (basically reducer for the main final store)

import { IGalleriesState } from 'entityModels'

import * as actions from './actions/actions'
import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: IGalleriesState = {
    images: {},
    presignedurls: null,
    error: null,
    loading: false,
}

export const galleriesData = (
    state: IGalleriesState = initialState,
    action: LoadingActions | Action | ErrorActions
): IGalleriesState => {
    switch (action.type) {
        case actions.addToGalleryImageSuccess.type: {
            let new_images_obj = { ...state.images }
            for (let i = 0; i < action.payload.length; i++) {
                if (!new_images_obj[action.payload[i].id]) {
                    new_images_obj[action.payload[i].id] = action.payload[i]
                }
            }

            return Object.assign({}, state, {
                ...state,
                images: new_images_obj,
                loading: false,
                error: null,
            })
        }

        // REQUESTS

        case loadingActions.deleteCarGalleryImageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS

        case errorActions.deleteCarGalleryImageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS

        case actions.deleteCarGalleryImageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                images: {
                    ...state.images,
                    [action.payload]: null,
                },
                loading: false,
                error: null,
            })

        // REQUESTS

        case loadingActions.updateCarGalleryImageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS

        case errorActions.updateCarGalleryImageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS

        case actions.updateCarGalleryImageSuccess.type:
            return Object.assign({}, state, {
                ...state,

                // need to check in Thunks if not already in state
                images: {
                    ...state.images,
                    [action.payload.id]: action.payload,
                },
                loading: false,
                error: null,
            })

        // REQUESTS

        case loadingActions.getGalleryImagesRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        // ERRORS

        case errorActions.getGalleryImagesError.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: action.payload,
            })
        // SUCCESS

        case actions.getGalleryImagesSuccess.type:
            return Object.assign({}, state, {
                ...state,

                // need to check in Thunks if not already in state
                images: {
                    ...state.images,
                    ...action.payload,
                },

                loading: false,
                error: null,
            })

        case loadingActions.setGalleryImagesRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        // ERRORS

        case errorActions.setGalleryImagesError.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: action.payload,
            })
        // SUCCESS

        case actions.setGalleryImagesSuccess.type:
            return Object.assign({}, state, {
                ...state,

                // need to check in Thunks if not already in state
                images: {
                    ...state.images,
                    ...action.payload,
                },

                loading: false,
                error: null,
            })

        case loadingActions.deleteEntryGalleryImageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS

        case errorActions.deleteEntryGalleryImageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS

        case actions.deleteEntryGalleryImageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                images: {
                    ...state.images,
                    [action.payload]: null,
                },
                loading: false,
                error: null,
            })

        // REQUESTS

        case loadingActions.updateEntryGalleryImageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS

        case errorActions.updateEntryGalleryImageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS

        case actions.updateEntryGalleryImageSuccess.type:
            return Object.assign({}, state, {
                ...state,

                // need to check in Thunks if not already in state
                images: {
                    ...state.images,
                    [action.payload.id]: action.payload,
                },
                loading: false,
                error: null,
            })

        case actions.cleanUpGallerySuccess.type:
            return Object.assign({}, state, {
                images: {},
                presignedurls: null,
                error: null,
                loading: false,
            })

        // case loadingActions.getPresignedUrlsCarGalleryImagesRequest.type:
        //   return Object.assign({}, state, {
        //     ...state,
        //     presignedurls: null,
        //     loading: true,
        //     error: null,
        //   });
        // // ERRORS

        // case errorActions.getPresignedUrlsCarGalleryImagesError.type:
        //   return Object.assign({}, state, {
        //     ...state,
        //     presignedurls: null,
        //     loading: true,
        //     error: action.payload,
        //   });
        // // SUCCESS

        // case actions.getPresignedUrlsCarGalleryImagesSuccess.type:
        //   return Object.assign({}, state, {
        //     ...state,

        //     // need to check in Thunks if not already in state
        //     presignedurls: action.payload,
        //     loading: false,
        //     error: null,
        //   });

        default:
            return state
    }
}

export default galleriesData

export type GalleriesState = ReturnType<typeof galleriesData>
