import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { IJourneySectionID, IRedirectCardsCopy } from 'journeyInterfaces'
import JourneyStartIcon from '../../../../atoms/icons/components/journey/playIcon'
import { motion } from 'framer-motion'
import { RenderJourneyIcon } from '../../../../atoms/icons/components/journey'

const Wrapper = styled(motion.button)<IStyle>`
    all: unset;
    display: flex;
    width: 100%;
    padding: 8px;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    border: ${(props) =>
        `1px solid ${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    background: ${(props) =>
        `linear-gradient(89deg, ${
            journey_colours[props.$theme].section[props.$sectionID].primary_50
        } 0%, ${colours[props.$theme].background_default} 100%)`};
    transition: all 200ms;
    cursor: pointer;
    :hover {
        transform: scale(1.01);
    }
`

const IconLowOpacityWrapper = styled.div<IStyle>`
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID]
                .primary_500_16
        }`};
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
`

const Title = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    align-self: stretch;
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_900
        }`};
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 24px;
`

const Description = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    align-self: stretch;
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_900
        }`};
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
`

const StartNowButton = styled.div<IStyle>`
    all: unset;
    cursor: pointer;
    display: flex;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 0px 12px 12px 0px;
    background: ${(props) =>
        `linear-gradient(270deg, ${
            journey_colours[props.$theme].section[props.$sectionID].primary_100
        } 0%, rgba(255,255,255,0) 100%)`};
`
const ButtonContainer = styled.div<IStyle>`
    display: flex;
    align-items: center;
    gap: 8px;
    :hover {
        border-bottom: ${(props) =>
            `1px solid ${
                journey_colours[props.$theme].section[props.$sectionID]
                    .primary_600
            }`};
    }
`

const ButtonText = styled.p<IStyle>`
    margin: 0;
    padding: 0;
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_500
        }`};
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const IconWrapper = styled.div<IStyle>`
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID]
                .primary_500_50
        }`};
`

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
}

export default function JourneyRedirectCardDesktop({
    sectionID,
    copyData,
    onStartNow,
    dataPH,
}: {
    sectionID: IJourneySectionID
    copyData: IRedirectCardsCopy
    onStartNow: () => void
    dataPH: string
}) {
    const { theme } = useThemes()
    return (
        <Wrapper
            data-ph={dataPH}
            $theme={theme}
            $sectionID={sectionID}
            onClick={onStartNow}
            whileTap={{ scale: 1.1 }}
        >
            <IconLowOpacityWrapper $theme={theme} $sectionID={sectionID}>
                <IconWrapper $theme={theme} $sectionID={sectionID}>
                    <RenderJourneyIcon
                        size={'32'}
                        id={sectionID}
                        color={colours[theme].background_default}
                    />
                </IconWrapper>
            </IconLowOpacityWrapper>
            <TextWrapper>
                <Title $theme={theme} $sectionID={sectionID}>
                    {copyData[sectionID].title}
                </Title>
                <Description $theme={theme} $sectionID={sectionID}>
                    {copyData[sectionID].description}
                </Description>
            </TextWrapper>
            <StartNowButton $theme={theme} $sectionID={sectionID}>
                <ButtonContainer $theme={theme} $sectionID={sectionID}>
                    <ButtonText $theme={theme} $sectionID={sectionID}>
                        Start now
                    </ButtonText>
                    <JourneyStartIcon
                        color={
                            journey_colours[theme].section[sectionID]
                                .primary_500
                        }
                        size="16"
                    />
                </ButtonContainer>
            </StartNowButton>
        </Wrapper>
    )
}
