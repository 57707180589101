import styled from 'styled-components'
import React from 'react'
import BorderAndIconButton from '../../Button/borderAndIconButton'
import FadedSlower from '../../../templates/animated/FadedSlower'
import { device } from '../../../templates/displays/devices'
import ChevronLeft from '../../icons/components/chevronLeft'

type IStyledProps = {
    isActive?: boolean
    isInsideWindowTemplate?: boolean
    removeHorizTopPaddings?: boolean
}

const Wrapper = styled.div<IStyledProps>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 55px;
    height: 80px;
    padding-left: ${(props) => (props.removeHorizTopPaddings ? '0px' : '32px')};
    padding-right: ${(props) =>
        props.removeHorizTopPaddings ? '0px' : '32px'};
    @media ${device.ipad} {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media screen and (min-width: 881px) and (max-width: 1200px) {
        margin-top: ${(props) =>
            props.isInsideWindowTemplate ? '-20px' : '-70px'};
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
`

const SmallPadding = styled.div`
    padding-right: 20px;
`

const HeaderText = styled.div<IStyledProps>`
    font-family: 'Lato-bold';
    color: var(--text-strong, #1a1a1a);
    justify-content: center;
    text-transform: capitalise;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
`

const IconWrapper = styled.div`
    height: 20px;
    width: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
`

interface Props {
    onSave?: any
    onCancel?: any
    onDelete?: any
    headerText?: string
    isActive?: boolean
    isSaveDisabled?: boolean
    isInsideWindowTemplate?: boolean
    dataCyIdSave?: string
    dataCyIdCancel?: string
    dataCyIdDelete?: string
    noBtns?: boolean
    goBack?: any
    removeHorizTopPaddings?: boolean
}

const EditModeTopHeaderDesktop: React.FunctionComponent<Props> = (props) => {
    return (
        <Wrapper
            isInsideWindowTemplate={props.isInsideWindowTemplate}
            removeHorizTopPaddings={props.removeHorizTopPaddings}
        >
            <HeaderText>
                {props.goBack && (
                    <IconWrapper onClick={props.goBack}>
                        <ChevronLeft />
                    </IconWrapper>
                )}

                {props.headerText}
            </HeaderText>

            {!props.noBtns && (
                <ButtonsWrapper>
                    {props.onDelete && (
                        <>
                            <BorderAndIconButton
                                variant="red"
                                text="Delete"
                                icon="thick_red_bin"
                                onClick={props.onDelete}
                                buttonwidth="130px"
                                dataCyId={props.dataCyIdDelete}
                            />
                            <SmallPadding />
                            <SmallPadding />
                        </>
                    )}
                    <BorderAndIconButton
                        variant="darkGrey"
                        text={props.isActive ? 'Cancel' : 'Return'}
                        icon="grey_cross"
                        iconwidth="10px"
                        onClick={props.onCancel}
                        dataCyId={props.dataCyIdCancel}
                    />
                    <SmallPadding />
                    {props.isActive && (
                        <FadedSlower>
                            <BorderAndIconButton
                                variant="blue"
                                text="Save"
                                icon="blue_check"
                                onClick={props.onSave}
                                isDisabled={props.isSaveDisabled}
                                isDisabledIcon="light_grey_check"
                                dataCyId={props.dataCyIdSave}
                            />
                        </FadedSlower>
                    )}
                </ButtonsWrapper>
            )}
        </Wrapper>
    )
}

export default EditModeTopHeaderDesktop
