import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export type ICarouselShowroomItem = {
    id: string
    image_url: string
    owner_uid: string
    price: number
    updated_at: string
    entry_uid: string
    entry_title: string
    currency: 'USD' | 'GBP' | 'EUR'
    created_at: string
}

const env: any = process.env.REACT_APP_ENV

const anon_key =
    env === 'production'
        ? `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imphbmh3bXBpdXFib2dtb2hmbHl1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODE0ODQ3ODksImV4cCI6MTk5NzA2MDc4OX0.J4EgQa1UEyhoXCwOOlE-s9TQ_sEvpQMIZ4utT0BWV78`
        : `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJkbXBvc2h5cWZ3b3Jwc2hna3JmIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODA2MTU1NjIsImV4cCI6MTk5NjE5MTU2Mn0.zaY35O_clUYtSeYCgmrLDldXzF2XwoitXEwP-giIHX0`

export const getShowroomCarouselEntries = (): Promise<
    ICarouselShowroomItem[]
> => {
    const requestOptions: RequestInit = {
        method: 'GET',

        headers: {
            ...customHeader,
            apikey: anon_key,
            Authorization: 'Bearer ' + anon_key,
        },
        // credentials: 'include',
    }

    return fetch(urls.get_showroom_carousel, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.status === 200) {
                        if (data) {
                            return data
                        } else return
                    } else if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Get showroom carousel error'
                        )
                        return Promise.reject(response)
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
