import Switch from 'react-switch'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

type Props = {
    toggle: any
    checked: boolean
    height?: number
    width?: number
    circleSize?: number
    dataCyId?: string | undefined
    variant?: 'outline'
    bg?: string
}

export default function SwitchButton(props: Props) {
    const handleChange = (checked: boolean) => {
        props.toggle(checked)
        return
    }

    let { height, width, circleSize, dataCyId, variant, bg } = props
    const { theme } = useThemes()
    return (
        <div
            style={
                variant === 'outline' && !props.checked
                    ? {
                          display: 'flex',
                          alignItems: 'center',
                          border: `1px solid ${colours[theme].text_default}`,
                          borderRadius: '12px',
                      }
                    : {
                          display: 'flex',
                          alignItems: 'center',
                          outline: 'none',
                      }
            }
        >
            <Switch
                checked={props.checked}
                onChange={handleChange}
                onColor={colours[theme].primary}
                offColor={
                    variant === 'outline'
                        ? bg ?? colours[theme].background_default
                        : undefined
                }
                onHandleColor={bg ?? colours[theme].background_default}
                offHandleColor={
                    variant === 'outline'
                        ? colours[theme].text_default
                        : undefined
                }
                handleDiameter={circleSize ? circleSize : 14}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={
                    height
                        ? variant === 'outline' && !props.checked
                            ? height - 2
                            : height
                        : variant === 'outline' && !props.checked
                        ? 22
                        : 24
                }
                width={
                    width
                        ? variant === 'outline' && !props.checked
                            ? width - 2
                            : width
                        : variant === 'outline' && !props.checked
                        ? 48
                        : 50
                }
                data-attr={dataCyId ? dataCyId : undefined}
            />
        </div>
    )
}
