import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import Faded from '../../templates/animated/faded'
import {
    createTaskRequest,
    getTasksByCarIdRequest,
    getTasksByGarageIdRequest,
    updateTaskRequest,
} from '../../../redux/tasks/actions/requestActions'

import {
    ITasksDisplayCriteria,
    ICreateTaskPayload,
    IDropdownItem,
    IGarageObject,
    IUpdateTaskReq,
    ITasksState,
    ICarsState,
} from 'entityModels'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

import TaskViewsManagerDesktop from '../../organisms/tasksViewManager/tasksViewManagerDesktop'
import TaskViewsManagerMobile from '../../organisms/tasksViewManager/tasksViewManagerMobile'

import { generate_task_cars_dropdown_list } from '../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import CreateTaskFormDesktop from '../../organisms/editForms/taskFormManager/custom/createTaskFormDesktop'
import CreateTaskFormMobile from '../../organisms/editForms/taskFormManager/custom/createTaskFormMobile'

import TaskListManagerDesktop from '../../organisms/tasksViewManager/taskListManager/taskListManagerDesktop'
import TaskListManagerMobile from '../../organisms/tasksViewManager/taskListManager/taskListManagerMobile'
import TutorialConfigsTasksMobile from '../../molecules/tutorialConfigs/tasks/tutorialConfigsTasksMobile'
import TutorialConfigsTasksDesktop from '../../molecules/tutorialConfigs/tasks/tutorialConfigsTasksDesktop'
import { getGarageByUserIdRequest } from '../../../redux/entities/garages/actions/loadingActions'

import {
    manageUserTutorialByIdCheck,
    // setIsFirstSessionSuccess,
} from '../../../redux/localdata/tutorialsCheck/actions'
import { ITutorialCheck } from '../../../redux/localdata/tutorialsCheck/types'
import { registerTasksDisplayCriteriaThunk } from '../../../redux/tasks/actions/thunks'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../ol/ol'
import TasksSubMenuActions from '../../atoms/menu/pageSubMenuActions/tasksSubMenuActions'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'

import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { getCookie } from '../../../redux/services/authcheck'
import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
import Loader from '../../atoms/loader/loader'
import { IUser } from 'myModels'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import { loadingPlaceholders } from '../car/carTasks'
import task_skeleton_desktop from '../../atoms/skeleton/tasks/task_skeleton_desktop.png'
import task_skeleton_mobile from '../../atoms/skeleton/tasks/task_skeleton_mobile.png'

import task_skeleton_desktop_dark from '../../atoms/skeleton/tasks/task_skeleton_desktop_dark.png'
import task_skeleton_mobile_dark from '../../atoms/skeleton/tasks/task_skeleton_mobile_dark.png'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--bg-color, #fff);
        padding-left: 50px;
        padding-right: 50px;
        transform: translateY(-50px);
        min-height: 100vh;
    }
`

const PaddingViewsMenuMobileIpad = styled.div`
    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 24px;
    }
    @media ${device.ipad} {
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
`

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isUserLoading: state.user.loading,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        garage_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.owns_garage &&
            state.user.userLoggedIn.owns_garage.uid,
        all_tasks: state.tasks,
        tasksLoading: state.tasks.loading,
        carLoading: state.entities.carsData.loading,
        garageLoading: state.entities.garagesData.loading,
        remindersLoading: state.tasks.isRemindersLoading,
        // to later match with task ids lists
        carsData: state.entities.carsData,
        garage_data: state.entities.garagesData.garages,
        isCreateCarFormOpen: state.editForms.formsData.createTaskForm.isOpen,
        isFilterByOpened: state.editForms.formsData.filterBy.isOpen,
        isSortByOpened: state.editForms.formsData.sortBy.isOpen,
        tutorialsCheck:
            state.localdata.tutorials_check_state &&
            state.localdata.tutorials_check_state,
        tasksDisplayCriteria: state.tasks.tasksDisplayCriteria,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getTasksByGarageIdRequest: (garageID: string) =>
        getTasksByGarageIdRequest(garageID),
    getTasksByCarIdRequest: (carID: string) => getTasksByCarIdRequest(carID),

    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),

    updateTaskRequest: (payload: IUpdateTaskReq) => updateTaskRequest(payload),
    toggleCreateTaskForm: () => editFormsActions.toggleCreateTaskForm(),
    createTaskRequest: (payload: ICreateTaskPayload) =>
        createTaskRequest(payload),
    toggleSortByChoice: () => editFormsActions.toggleSortByChoice(),
    toggleFilterByChoice: () => editFormsActions.toggleFilterByChoice(),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
    manageUserTutorialByIdCheck: (pageId: string) =>
        manageUserTutorialByIdCheck(pageId),
    // setIsFirstSession: (value: boolean) => setIsFirstSessionSuccess(value),
    registerTasksDisplayCriteria: (payload: ITasksDisplayCriteria) =>
        registerTasksDisplayCriteriaThunk(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

type State = {
    dropdownCars: IDropdownItem[]
    dropdownCarsWithOptions: IDropdownItem[]
    carSelected: any
    sortBy: string
    filterBy: string
}

interface Props extends RouteComponentProps<any> {
    userid: string | null
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    getTasksByGarageIdRequest: (garageID: string) => void
    all_tasks: ITasksState
    tasksLoading: boolean
    getTasksByCarIdRequest: (carID: string) => void
    carsData: ICarsState
    garage_data: IGarageObject
    getGarageCarsDataRequest: (id: string) => void
    updateTaskRequest: (payload: IUpdateTaskReq) => void
    toggleCreateTaskForm: () => void
    isCreateCarFormOpen: boolean
    createTaskRequest: (payload: ICreateTaskPayload) => void
    toggleSortByChoice: () => void
    toggleFilterByChoice: () => void
    isFilterByOpened: boolean
    isSortByOpened: boolean
    garage_id: string | null
    getGarageByUserIdRequest: () => void
    tutorialsCheck: ITutorialCheck
    // setIsFirstSession: any
    manageUserTutorialByIdCheck: (pageId: string) => void
    registerTasksDisplayCriteria: (payload: ITasksDisplayCriteria) => void
    tasksDisplayCriteria: ITasksDisplayCriteria
    location: any
    setInitialLandingUrl: (str: string) => void
    isUserLoading: boolean
    carLoading: boolean
    garageLoading: boolean
    remindersLoading: boolean | null | undefined
}

class Tasks extends React.Component<Props, State> {
    state = {
        dropdownCars: [],
        carSelected: this.props.tasksDisplayCriteria.selectedCarID,
        sortBy: this.props.tasksDisplayCriteria.sortBy,
        filterBy: this.props.tasksDisplayCriteria.filteredBy,
        dropdownCarsWithOptions: [],
    }

    selectCarViewId = (name: string) => {
        if (name === OOtherTaskOptions.general_task.name) {
            return this.setState({
                ...this.state,
                carSelected: OOtherTaskOptions.general_task.name,
            })
        }
        let { carsData } = this.props
        if (carsData.cars) {
            let car_ids_list: string[] = Object.keys(carsData.cars)

            for (const carid of car_ids_list) {
                if (carsData.cars[carid].title === name) {
                    return this.setState({ ...this.state, carSelected: carid })
                } else this.setState({ ...this.state, carSelected: null })
            }
        } else return this.setState({ ...this.state, carSelected: null })
    }

    sortView = (id: string) => {
        this.setState({ ...this.state, sortBy: id })
    }

    filterView = (text: string) => {
        this.setState({ ...this.state, filterBy: text })
    }

    generate_cars_dropdown_list = (arr: string[]) => {
        let cars = this.props.carsData.cars

        if (this.props.userLoggedIn && this.props.garage_id) {
            let obj = generate_task_cars_dropdown_list(
                arr,
                cars,
                this.props.garage_id
            )

            let options = [
                {
                    uid: this.props.garage_id,
                    name: OOtherTaskOptions.all_tasks.name,
                },
                {
                    uid: OOtherTaskOptions.general_task.uid,
                    name: OOtherTaskOptions.general_task.name,
                },
            ]

            let cars_only_dropdown = [...obj]

            let cars_dropdown_with_options = [...obj]

            cars_dropdown_with_options.unshift(...options)

            this.setState({
                ...this.state,
                dropdownCars: cars_only_dropdown,
                dropdownCarsWithOptions: cars_dropdown_with_options,
            })
        }
    }

    componentWillMount() {
        //checking if prev path is a task before mount
        if (this.props.location.state !== undefined) {
            let prevPath: string = this.props.location.state.prevPath
            let taskId: string = prevPath.replace('/tasks/', '')
            let hasLettersAndNumber: any = /^[0-9a-zA-Z]+$/

            let thisIsATaskPage: boolean = taskId.match(hasLettersAndNumber)
                ? true
                : false

            if (thisIsATaskPage === false) {
                this.setState({
                    ...this.state,
                    carSelected: null,
                    sortBy: OTaskFilterNSort.sort_by.created_at.name,
                    filterBy: OTaskFilterNSort.filter_by.outstanding.name,
                })
            }
        }
    }

    componentDidMount() {
        this.props.setInitialLandingUrl('/tasks')

        let userData = this.props.userLoggedIn

        if (!userData) {
            this.props.getUserDataRequest()
        }

        // garage stuff - we only need this check as saga calls getGarageById as well if no entities

        if (
            this.props.garage_id &&
            this.props.garage_data &&
            this.props.garage_data[this.props.garage_id] &&
            !this.props.garage_data[this.props.garage_id].cars
        ) {
            this.props.getGarageByUserIdRequest()
        }

        if (userData) {
            if (this.props.garage_id) {
                //  tasks stuff
                if (
                    !this.props.all_tasks ||
                    !this.props.all_tasks.tasks ||
                    Object.keys(this.props.all_tasks.tasks).length === 0
                ) {
                    this.props.getTasksByGarageIdRequest(this.props.garage_id)
                }
            }
        }

        if (this.props.garage_data !== undefined && userData) {
            this.props.garage_id &&
                this.props.garage_data[this.props.garage_id] &&
                this.props.garage_data[this.props.garage_id].cars &&
                this.generate_cars_dropdown_list(
                    this.props.garage_data[this.props.garage_id].cars
                )
        }

        if (
            this.props.tutorialsCheck.isFirstSession === true &&
            this.props.tutorialsCheck.tutorialsCheckByPageId.tasks === false
        ) {
            this.props.manageUserTutorialByIdCheck('tasks')
            this.props.history.push('/tasks?tutorial=true')
        }
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let userData = this.props.userLoggedIn

        if (this.props.garage_data !== prevProps.garage_data && userData) {
            if (
                this.props.garage_id &&
                this.props.garage_data[this.props.garage_id] &&
                this.props.garage_data[this.props.garage_id].cars
            ) {
                this.generate_cars_dropdown_list(
                    this.props.garage_data[this.props.garage_id].cars
                )
            }
        }

        if (userData !== prevProps.userLoggedIn) {
            if (userData) {
                if (this.props.garage_id) {
                    //  tasks stuff
                    if (
                        !this.props.all_tasks ||
                        !this.props.all_tasks.tasks ||
                        Object.keys(this.props.all_tasks.tasks).length === 0
                    ) {
                        this.props.getTasksByGarageIdRequest(
                            this.props.garage_id
                        )
                    }
                }
            }
        }
        if (
            this.state.carSelected !==
                this.props.tasksDisplayCriteria.selectedCarID ||
            this.state.filterBy !==
                this.props.tasksDisplayCriteria.filteredBy ||
            this.state.sortBy !== this.props.tasksDisplayCriteria.sortBy
        ) {
            this.props.registerTasksDisplayCriteria({
                selectedCarID: this.state.carSelected,
                sortBy: this.state.sortBy,
                filteredBy: this.state.filterBy,
            })
        }
    }

    render() {
        let {
            userLoggedIn,
            garage_data,
            carsData,
            all_tasks,
            updateTaskRequest,
            toggleCreateTaskForm,
            isCreateCarFormOpen,
            createTaskRequest,
            isFilterByOpened,
            toggleFilterByChoice,
            isSortByOpened,
            toggleSortByChoice,
            isUserLoading,
            tasksLoading,
            carLoading,
            garageLoading,
            remindersLoading,
        } = this.props

        // let { carSelected } = this.state

        let all_tasks_uids = all_tasks.tasks && Object.keys(all_tasks.tasks)

        let garage_id = this.props.garage_id

        const params = new URLSearchParams(this.props.location.search)

        let isDataLoading =
            tasksLoading || carLoading || garageLoading || remindersLoading
                ? true
                : false

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <React.Fragment>
                        <Loader isLoading={isUserLoading} />
                        <FabsMenusMobile hasAdd={true} hasSubNav={false} />

                        {params.get('tutorial') === 'true' && (
                            <TutorialConfigsTasksMobile />
                        )}
                        {params.get('tutorial') === 'true' && (
                            <TutorialConfigsTasksDesktop />
                        )}

                        {/* {params.get('tutorial') !== 'true' &&
                    getCookie('ios_install_prompt') !== 'seen' && (
                        <InstallIosPropmt />
                    )} */}

                        {params.get('tutorial') !== 'true' &&
                            getCookie('android_install_prompt') !== 'seen' && (
                                <AndroidInstallPwaInstructionsPropmt />
                            )}

                        <Wrapper>
                            <FullPagePageWrapper paddingRight="24px">
                                {userLoggedIn && (
                                    <Faded>
                                        <DesktopDisplayOnly>
                                            <div
                                                style={{ paddingTop: '90px' }}
                                            />
                                            <div
                                                data-tut="tasks-tuto-desktop-2"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                        'space-between',
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                {/* <PageTitle>Tasks & Reminders</PageTitle> */}

                                                <TaskViewsManagerDesktop
                                                    dropdownCars={
                                                        this.state
                                                            .dropdownCarsWithOptions
                                                    }
                                                    selectCarViewId={
                                                        this.selectCarViewId
                                                    }
                                                    toggleCreateTaskForm={
                                                        toggleCreateTaskForm
                                                    }
                                                    sortView={this.sortView}
                                                    filterView={this.filterView}
                                                    initialValues={{
                                                        selectedCarID:
                                                            this.state
                                                                .carSelected,
                                                        sortBy: this.state
                                                            .sortBy,
                                                        filteredBy:
                                                            this.state.filterBy,
                                                    }}
                                                    selectedCarData={
                                                        this.state
                                                            .carSelected !==
                                                        null
                                                            ? carsData.cars[
                                                                  this.state
                                                                      .carSelected
                                                              ]
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                            <div
                                                style={{ paddingTop: '70px' }}
                                            />

                                            {isDataLoading ||
                                            !(
                                                garage_data &&
                                                garage_id &&
                                                garage_data[garage_id] &&
                                                garage_data[garage_id].cars
                                            ) ? (
                                                loadingPlaceholders(
                                                    6,
                                                    theme === 'dark'
                                                        ? task_skeleton_desktop_dark
                                                        : task_skeleton_desktop
                                                )
                                            ) : (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        paddingBottom: 80,
                                                        minHeight: '100vh',
                                                    }}
                                                >
                                                    <TaskListManagerDesktop
                                                        all_tasks={all_tasks}
                                                        all_tasks_uids={
                                                            all_tasks_uids
                                                        }
                                                        state={this.state}
                                                        updateTaskRequest={
                                                            updateTaskRequest
                                                        }
                                                        carsData={carsData}
                                                        toggleCreateTaskForm={
                                                            toggleCreateTaskForm
                                                        }
                                                        tasks_ids_garage_level_only={
                                                            garage_data[
                                                                garage_id
                                                            ]
                                                                ?.tasks_garage_level
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </DesktopDisplayOnly>

                                        <IpadAndMobileDisplay>
                                            <PaddingViewsMenuMobileIpad
                                                style={{ paddingBottom: 24 }}
                                            >
                                                <TaskViewsManagerMobile
                                                    dropdownCars={
                                                        this.state
                                                            .dropdownCarsWithOptions
                                                    }
                                                    selectCarViewId={
                                                        this.selectCarViewId
                                                    }
                                                    state={this.state}
                                                    selectedCarData={
                                                        this.state
                                                            .carSelected !==
                                                        null
                                                            ? carsData.cars[
                                                                  this.state
                                                                      .carSelected
                                                              ]
                                                            : undefined
                                                    }
                                                />
                                            </PaddingViewsMenuMobileIpad>

                                            {isDataLoading ||
                                            !(
                                                garage_data &&
                                                garage_id &&
                                                garage_data[garage_id] &&
                                                garage_data[garage_id].cars
                                            ) ? (
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        marginTop: '50px',
                                                        height: '20vh',
                                                    }}
                                                >
                                                    {loadingPlaceholders(
                                                        3,
                                                        theme === 'dark'
                                                            ? task_skeleton_mobile_dark
                                                            : task_skeleton_mobile
                                                    )}
                                                </div>
                                            ) : (
                                                <TaskListManagerMobile
                                                    all_tasks={all_tasks}
                                                    all_tasks_uids={
                                                        all_tasks_uids
                                                    }
                                                    state={this.state}
                                                    updateTaskRequest={
                                                        updateTaskRequest
                                                    }
                                                    carsData={carsData}
                                                    toggleCreateTaskForm={
                                                        toggleCreateTaskForm
                                                    }
                                                    tasks_ids_garage_level_only={
                                                        garage_data[garage_id]
                                                            ?.tasks_garage_level
                                                    }
                                                />
                                            )}

                                            <div
                                                style={{
                                                    paddingBottom: '100px',
                                                }}
                                            />
                                        </IpadAndMobileDisplay>
                                    </Faded>
                                )}
                            </FullPagePageWrapper>
                        </Wrapper>
                        <DesktopDisplayOnly>
                            {isCreateCarFormOpen && garage_id && (
                                <ModalDisplay
                                    toggle={toggleCreateTaskForm}
                                    isOpen={true}
                                    customZindex={11}
                                >
                                    <CreateTaskFormDesktop
                                        toggle={toggleCreateTaskForm}
                                        submitFunc={createTaskRequest}
                                        dropdownData={this.state.dropdownCars}
                                        carsData={carsData.cars}
                                        garage_id={garage_id}
                                        currentCarID={undefined}
                                        garageCarsIds={
                                            garage_data[garage_id].cars
                                        }
                                    />
                                </ModalDisplay>
                            )}
                        </DesktopDisplayOnly>
                        <IpadAndMobileDisplay>
                            {isCreateCarFormOpen && garage_id && (
                                <ModalDisplay
                                    toggle={toggleCreateTaskForm}
                                    isOpen={isCreateCarFormOpen}
                                >
                                    <CreateTaskFormMobile
                                        toggle={toggleCreateTaskForm}
                                        submitFunc={createTaskRequest}
                                        dropdownData={this.state.dropdownCars}
                                        carsData={carsData.cars}
                                        garage_id={garage_id}
                                        currentCarID={undefined}
                                        garageCarsIds={
                                            garage_data[garage_id].cars
                                        }
                                    />
                                </ModalDisplay>
                            )}

                            {isFilterByOpened && (
                                <TasksSubMenuActions
                                    isOpen={isFilterByOpened}
                                    items={[
                                        {
                                            id: OTaskFilterNSort.filter_by
                                                .outstanding.uid,
                                            text: OTaskFilterNSort.filter_by
                                                .outstanding.name,
                                            action: () => {
                                                this.filterView(
                                                    OTaskFilterNSort.filter_by
                                                        .outstanding.name
                                                )
                                                toggleFilterByChoice()
                                            },
                                        },
                                        {
                                            id: OTaskFilterNSort.filter_by
                                                .completed.uid,
                                            text: OTaskFilterNSort.filter_by
                                                .completed.name,
                                            action: () => {
                                                this.filterView(
                                                    OTaskFilterNSort.filter_by
                                                        .completed.name
                                                )
                                                toggleFilterByChoice()
                                            },
                                        },
                                        {
                                            id: OTaskFilterNSort.filter_by.all
                                                .uid,
                                            text: OTaskFilterNSort.filter_by.all
                                                .name,
                                            action: () => {
                                                this.filterView(
                                                    OTaskFilterNSort.filter_by
                                                        .all.name
                                                )
                                                toggleFilterByChoice()
                                            },
                                        },
                                    ]}
                                    toggle={toggleFilterByChoice}
                                    active_item={this.state.filterBy}
                                />
                            )}

                            {isSortByOpened && (
                                <TasksSubMenuActions
                                    isOpen={isSortByOpened}
                                    items={[
                                        {
                                            id: OTaskFilterNSort.sort_by
                                                .created_at.uid,
                                            text: OTaskFilterNSort.sort_by
                                                .created_at.text,
                                            action: () => {
                                                this.sortView(
                                                    OTaskFilterNSort.sort_by
                                                        .created_at.name
                                                )
                                                toggleSortByChoice()
                                            },
                                            name: OTaskFilterNSort.sort_by
                                                .created_at.name,
                                        },
                                        {
                                            id: OTaskFilterNSort.sort_by.due
                                                .uid,
                                            text: OTaskFilterNSort.sort_by.due
                                                .text,
                                            action: () => {
                                                this.sortView(
                                                    OTaskFilterNSort.sort_by.due
                                                        .name
                                                )
                                                toggleSortByChoice()
                                            },
                                            name: OTaskFilterNSort.sort_by.due
                                                .name,
                                        },
                                    ]}
                                    toggle={toggleSortByChoice}
                                    active_item={this.state.sortBy}
                                />
                            )}
                        </IpadAndMobileDisplay>
                    </React.Fragment>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(Tasks))
