import AreObjectsEqual from '../objChecks'

export const are2arraysEqual = (array1: any[], array2: any[]) => {
    if (array1.length === array2.length) {
        array1.every((element, index) => {
            if (element === array2[index]) {
                return true
            }

            return false
        })
    }

    return false
}

export const areArraysValuesEqual = (arr1: any, arr2: any) => {
    let res = true
    if (arr1 && arr2) {
        if (arr1.length !== arr2.length) {
            res = false
        }

        for (let i = 0; i < arr1.length; i++) {
            if (!AreObjectsEqual(arr1[i], arr2[i])) {
                res = false
            }
        }
    }
    return res
}
