import React from 'react'
import { useHistory } from 'react-router'
import { showroomActions } from '../../redux/showroom/reducer'
import {
    IShowroomFilterRadioOptionItem,
    IShowroomFiltersState,
} from '../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'

type ContextType = {
    putInitialParamsInReduxSearch: (windowLocation: Location) => any
    removeQueryParamSearchID: (id: string, val?: string) => any
    synchReduxSearchToQueryParam: () => any
}
const ShowroomInitialCtxt = React.createContext<ContextType>({} as any)

export const useShowroom = () => {
    const props = React.useContext(ShowroomInitialCtxt)

    if (!props) {
        throw new Error(
            `You must call useShowroom() inside of a <InsuranceGETProvider />`
        )
    }

    return props
}

export const ShowroomProvider = ({ children }: any) => {
    let filtersStateEdit: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    const arrMultiSelect = ['make', 'model', 'colour', 'body_type']

    const arrRanges = ['price', 'year', 'mileage']

    const arrOptions = ['type_of_sale', 'drive_side']

    const history = useHistory()

    const dispatch = useAppDispatch()

    const putInitialParamsInReduxSearch = (windowLocation: Location) => {
        // this doesnt retrigger a search, merely just keeps state of search in url
        // in case of share or page refresh
        const params = new URLSearchParams(windowLocation.search)

        const paramsObj: any = Array.from(params.keys()).reduce(
            (acc, val) => ({ ...acc, [val]: params.getAll(val) }),
            {}
        )

        if (paramsObj) {
            if (paramsObj['owner_id'] && paramsObj['owner_display_name']) {
                dispatch(
                    showroomActions.setOwner_filter({
                        id: paramsObj['owner_id'][0],
                        display_name: paramsObj['owner_display_name'][0],
                    })
                )
            }

            if (paramsObj['keyword']) {
                for (let i = 0; i < paramsObj['keyword'].length; i++) {
                    dispatch(
                        showroomActions.setKeyword_filter(
                            paramsObj['keyword'][i]
                        )
                    )
                }
            }

            if (
                paramsObj['location_lat'] &&
                paramsObj['location_lng'] &&
                paramsObj['location_display_value']
            ) {
                dispatch(
                    showroomActions.setLocation_filter({
                        values: {
                            lat:
                                paramsObj['location_lat'] &&
                                paramsObj['location_lat'][0]
                                    ? paramsObj['location_lat'][0]
                                    : undefined,
                            lng:
                                paramsObj['location_lng'] &&
                                paramsObj['location_lng'][0]
                                    ? paramsObj['location_lng'][0]
                                    : undefined,
                            distance: paramsObj['location_distance']
                                ? paramsObj['location_distance']
                                : undefined,
                        },
                        display_value:
                            paramsObj['location_display_value'] &&
                            paramsObj['location_display_value'][0]
                                ? paramsObj['location_display_value'][0]
                                : undefined,
                    })
                )
            }

            for (let a = 0; a < arrMultiSelect.length; a++) {
                let IDD = arrMultiSelect[a]
                if (paramsObj[IDD]) {
                    let valuesss: string[] = []
                    for (let i = 0; i < paramsObj[IDD].length; i++) {
                        valuesss = [...valuesss, paramsObj[IDD][i]]
                    }

                    if (valuesss.length > 0) {
                        dispatch(
                            showroomActions.setMultiSelect_filter_replace_values(
                                {
                                    // @ts-ignore
                                    id: IDD,
                                    values: valuesss,
                                }
                            )
                        )
                    }
                }
            }

            for (let b = 0; b < arrRanges.length; b++) {
                let id_name = `${arrRanges[b]}_name`
                let id_id = `${arrRanges[b]}_id`

                if (
                    arrRanges[b] === 'year' &&
                    paramsObj[id_name] &&
                    paramsObj[id_name][0] &&
                    paramsObj[id_id] &&
                    paramsObj[id_id][0]
                ) {
                    dispatch(
                        showroomActions.setRadioSelect_filter({
                            id: 'year',
                            item: {
                                id: paramsObj[id_id][0],
                                name: paramsObj[id_name][0],
                            },
                        })
                    )
                } else {
                    let id_from = `${arrRanges[b]}_valueFrom`
                    let id_to = `${arrRanges[b]}_valueTo`

                    if (paramsObj[id_from] || paramsObj[id_to]) {
                        dispatch(
                            showroomActions.setRange_filter({
                                // @ts-ignore
                                id: arrRanges[b],
                                valueFrom:
                                    paramsObj[id_from] && paramsObj[id_from][0]
                                        ? paramsObj[id_from][0]
                                        : undefined,
                                valueTo:
                                    paramsObj[id_to] && paramsObj[id_to][0]
                                        ? paramsObj[id_to][0]
                                        : undefined,
                            })
                        )
                    }
                }
            }

            for (let c = 0; c < arrOptions.length; c++) {
                let id_id = `${arrOptions[c]}_id`
                let id_name = `${arrOptions[c]}_name`

                if (
                    paramsObj[id_id] &&
                    paramsObj[id_id][0] &&
                    paramsObj[id_name] &&
                    paramsObj[id_name][0]
                ) {
                    dispatch(
                        showroomActions.setRadioSelect_filter({
                            // @ts-ignore
                            id: arrOptions[c],
                            item: {
                                name: paramsObj[id_name][0],
                                id: paramsObj[id_id][0],
                            },
                        })
                    )
                }
            }
        }
    }

    const removeQueryParamSearchID = (id: string, val?: string) => {
        // this doesnt retrigger a search, merely just keeps state of search in url
        // in case of share or page refresh

        const params = new URLSearchParams(window.location.search)

        const paramsObj: any = Array.from(params.keys()).reduce(
            (acc, val) => ({ ...acc, [val]: params.getAll(val) }),
            {}
        )

        let currURL = new URL(window.location.href)

        // owner works diff as it has name id, owner name etc
        // but! if _id, _name at the end for everyone thats cool too
        // for make and model at least.
        // mainly owner tho is different because search systems is outside of the search bar,
        // from the entries cards. so need to be handled with more conditions generally,
        //   see ownerParamID dep in the showroom page use effect as example

        if (id === 'owner') {
            currURL.searchParams.delete('owner_id')
            currURL.searchParams.delete('owner_display_name')
            window.location.replace(currURL)
        } else if (arrRanges.indexOf(id) !== -1) {
            currURL.searchParams.delete(`${id}_valueFrom`)
            currURL.searchParams.delete(`${id}_valueTo`)

            if (id === 'year') {
                currURL.searchParams.delete(`${id}_name`)
                currURL.searchParams.delete(`${id}_id`)
            }

            history.replace(currURL.pathname + currURL.search)
        } else if (arrOptions.indexOf(id) !== -1) {
            currURL.searchParams.delete(`${id}_id`)
            currURL.searchParams.delete(`${id}_name`)
            history.replace(currURL.pathname + currURL.search)
        } else if (id === 'location') {
            currURL.searchParams.delete('location_lat')
            currURL.searchParams.delete('location_lng')
            currURL.searchParams.delete('location_distance')
            currURL.searchParams.delete('location_display_value')
            history.replace(currURL.pathname + currURL.search)
        } else {
            if (paramsObj[id] && paramsObj[id][0]) {
                let currentsCopyCleanedFromVal = !val
                    ? undefined
                    : paramsObj && paramsObj[id] && paramsObj[id].length > 0
                    ? paramsObj[id].filter((valuee: string) => valuee !== val)
                    : undefined

                currURL.searchParams.delete(id)

                if (currentsCopyCleanedFromVal) {
                    for (
                        let i = 0;
                        i < currentsCopyCleanedFromVal.length;
                        i++
                    ) {
                        currURL.searchParams.append(
                            id,
                            currentsCopyCleanedFromVal[i]
                        )
                    }
                }

                history.replace(currURL.pathname + currURL.search)
            }
        }
    }

    const synchReduxSearchToQueryParam = () => {
        // mobile on apply or search click. no need for other sync there
        // to put also when ppl click on going to the showroom btw.
        // this doesnt retrigger a search, merely just keeps state of search in url
        // in case of share or page refresh

        let currURL = new URL(window.location.href)

        let keyss = Object.keys(filtersStateEdit)

        for (let i = 0; i < keyss.length; i++) {
            let idd = keyss[i]

            // @ts-ignore
            if (filtersStateEdit[idd].isActive === true) {
                if (
                    idd === 'keyword' &&
                    filtersStateEdit.keyword &&
                    filtersStateEdit.keyword?.values
                ) {
                    currURL.searchParams.delete('keyword')

                    for (
                        let a = 0;
                        a < filtersStateEdit.keyword?.values.length;
                        a++
                    ) {
                        currURL.searchParams.append(
                            'keyword',
                            filtersStateEdit.keyword?.values[a]
                        )
                    }
                }

                if (
                    arrMultiSelect.indexOf(idd) !== -1 &&
                    // @ts-ignore
                    filtersStateEdit[idd] &&
                    // @ts-ignore
                    filtersStateEdit[idd]?.values
                ) {
                    currURL.searchParams.delete(idd)

                    for (
                        let b = 0;
                        // @ts-ignore
                        b < filtersStateEdit[idd]?.values.length;
                        b++
                    ) {
                        currURL.searchParams.append(
                            idd,
                            // @ts-ignore
                            filtersStateEdit[idd]?.values[b]
                        )
                    }
                }

                if (arrRanges.indexOf(idd) !== -1) {
                    currURL.searchParams.delete(`${idd}_valueFrom`)
                    currURL.searchParams.delete(`${idd}_valueTo`)
                    currURL.searchParams.delete(`${idd}_name`)
                    currURL.searchParams.delete(`${idd}_id`)
                    if (
                        idd === 'year' &&
                        filtersStateEdit.year.value &&
                        filtersStateEdit.year.displayValue &&
                        !filtersStateEdit.year.valueFrom &&
                        !filtersStateEdit.year.valueTo
                    ) {
                        currURL.searchParams.append(
                            `${idd}_name`,
                            `${filtersStateEdit.year.displayValue}`
                        )
                        currURL.searchParams.append(
                            `${idd}_id`,
                            `${filtersStateEdit.year.value}`
                        )
                    } else if (
                        // @ts-ignore
                        filtersStateEdit[idd] &&
                        // @ts-ignore
                        (typeof filtersStateEdit[idd]?.valueFrom === 'number' ||
                            // @ts-ignore
                            typeof filtersStateEdit[idd]?.valueTo === 'number')
                    ) {
                        currURL.searchParams.append(
                            `${idd}_valueFrom`,
                            // @ts-ignore
                            filtersStateEdit[idd]?.valueFrom
                        )

                        currURL.searchParams.append(
                            `${idd}_valueTo`,
                            // @ts-ignore
                            filtersStateEdit[idd]?.valueTo
                        )
                    }
                }

                if (
                    arrOptions.indexOf(idd) !== -1 &&
                    // @ts-ignore
                    filtersStateEdit[idd] &&
                    // @ts-ignore
                    filtersStateEdit[idd]?.value &&
                    // @ts-ignore
                    filtersStateEdit[idd]?.options
                ) {
                    currURL.searchParams.delete(`${idd}_id`)
                    currURL.searchParams.delete(`${idd}_name`)

                    let itemm: IShowroomFilterRadioOptionItem | undefined =
                        // @ts-ignore
                        filtersStateEdit[idd]?.options.find(
                            (x: IShowroomFilterRadioOptionItem) =>
                                // @ts-ignore
                                x.id === filtersStateEdit[idd]?.value
                        )

                    if (itemm) {
                        currURL.searchParams.append(`${idd}_id`, itemm.id)

                        currURL.searchParams.append(`${idd}_name`, itemm.name)
                    }
                }

                if (
                    idd === 'location' &&
                    filtersStateEdit.location &&
                    filtersStateEdit.location.values?.lat &&
                    filtersStateEdit.location.values?.lng &&
                    filtersStateEdit.location.displayValue
                ) {
                    currURL.searchParams.delete('location_lat')
                    currURL.searchParams.delete('location_lng')
                    currURL.searchParams.delete('location_distance')
                    currURL.searchParams.delete('location_display_value')
                    currURL.searchParams.append(
                        'location_lat',
                        filtersStateEdit.location.values?.lat
                    )
                    currURL.searchParams.append(
                        'location_lng',
                        filtersStateEdit.location.values?.lng
                    )

                    currURL.searchParams.append(
                        'location_display_value',
                        filtersStateEdit.location.displayValue
                    )

                    if (filtersStateEdit.location.values?.distance) {
                        currURL.searchParams.append(
                            'location_distance',
                            `${filtersStateEdit.location.values?.distance}`
                        )
                    }
                }
            }
        }

        history.replace(currURL.pathname + currURL.search)
    }

    const wrapped: ContextType = {
        putInitialParamsInReduxSearch,
        removeQueryParamSearchID,
        synchReduxSearchToQueryParam,
    }

    return (
        <ShowroomInitialCtxt.Provider value={wrapped}>
            {children}
        </ShowroomInitialCtxt.Provider>
    )
}
