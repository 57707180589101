import gql from 'graphql-tag'
import { get_check_cookie_name } from '../../../helpers/cookies'

import { client } from '../../../helpers/prismicUtils/prismicHelpers'

/*
 * Apex All Podcasts
 */
const apexSingleArticleOverviewsGraphQLQuery = gql`
    query apexSingleArticleOverviewsGraphQLQuery {
        allApex_articless(sortBy: date_DESC) {
            edges {
                node {
                    date
                    cover_image
                    title
                    introduction
                    _meta {
                        uid
                    }
                }
            }
        }
    }
`

export const apexArticleOverviewsQuery = async () => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexSingleArticleOverviewsGraphQLQuery,
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}
