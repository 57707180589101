import { call, fork, take, put } from 'redux-saga/effects'
// import axios from 'axios'
import * as requestActions from '../actions/requestActions'

// import { RootState } from 'typesafe-actions'
import { IAttachmentAPI, IAttachmentRequestPayload } from 'attachmentModels'
import { api } from '../../services'
import {
    // ConverFileTypeToFileSuffixEnum,
    ConvertAttachmentAPIToAttachmentState,
} from '../../conversions/attachments'
import * as successActions from '../actions/successActions'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import * as errorActions from '../actions/errorActions'
import { addAttachmentsSuccess } from '../../attachments/actions/successActions'

// import {
//     // IUploadAttachmentPreSignedUrlPayloadSingle,
//     IUploadImagePreSignedUrlPayloadSingle,
// } from 'myModels'

import { IAddEntryAttachmenstApiRequest } from 'ApiInterfaces'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'

// let getPresignedUrls = (state: RootState) => state.fileStorage.presignedurls

export async function putToS3(fileObject: any, presignedUrl: string) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': fileObject.type,
            origin: 'custodian.club',
        },
        ContentEncoding: 'base64',
        body: fileObject.data,
    }
    const response = await fetch(presignedUrl, requestOptions)
    return await response
}

export async function putToS3Tracked(fileObject: File, presignedUrl: string) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': fileObject.type,
            origin: 'custodian.club',
        },
        ContentEncoding: 'base64',
        body: fileObject,
    }
    const response = await fetch(presignedUrl, requestOptions)
    return await response
}

// export function* UploadEntryAttachmentToAwsSaga(
//     payload: IAddAttachmentToEntrySubSaga
// ): any {
//     try {
//         yield call(putToS3, payload.fileData.file, payload.presigned_url)

//         yield call(AddAttachmentsToEntryAPISaga, payload)
//     } catch (e: any) {
//         let customErrorData: ICustomErrorData = {
//             custom_message: `Something went wrong while uploading the attachment, please try adding it again.`,
//             custom_user_action_text: 'Return',
//             custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`, //pathname to entry page possibly
//         }
//         let customError: IReduxError = ConvertToReduxError(e, customErrorData)
//         yield put(errorActions.addAttachmentToEntryError(customError))
//     }
// }

export function* UploadEntryAttachmentToAwsWithTrackingSaga(
    payload: IAddAttachmentToEntryAwsTrackingSaga
): any {
    try {
        yield call(putToS3Tracked, payload.fileData.file, payload.presigned_url)
        return 'ok'
        // next to do: update on progress ( redux centralisation)
    } catch (e: any) {
        let item_with_error_tracked: IAttachmentRequestPayload = {
            ...payload.fileData,
            hasErrorAWS: e,
        }
        return item_with_error_tracked
    }
}

export function* AddAttachmentsToEntryAPISaga(
    payload: IAddEntryAttachmenstApiRequest
): any {
    try {
        let p: IAddEntryAttachmenstApiRequest = {
            attachments: payload.attachments,
            carID: payload.carID,
            entryID: payload.entryID,
        }
        let at: IAttachmentAPI = yield call(
            api.timeline.entries.addEntryAttachments.addEntryAttachmentsApi,
            p
        )

        let converted_attac = ConvertAttachmentAPIToAttachmentState(at)

        yield put(
            successActions.addAttachmentToEntrySuccess([converted_attac.id])
        )

        yield put(
            addAttachmentsSuccess({
                [converted_attac.id]: converted_attac,
            })
        )
    } catch (e: any) {
        if (e.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: AddAttachmentsToEntryAPISaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong while uploading the attachment, please try adding it again.`,
                custom_user_action_text: 'Return',
                custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`, //pathname to entry page possibly
            }
            let customError: IReduxError = ConvertToReduxError(
                e,
                customErrorData
            )
            yield put(errorActions.addAttachmentToEntryError(customError))
        }
    }
}

// export type IAddAttachmentsToEntrySaga = {
//     fileData: IAttachmentRequestPayload
//     entryID: string
//     carID: string
// }

// export type IAddAttachmentsToEntrySaga = {
//     files_data_list: IAttachmentAPI[]
//     entryID: string
//     carID: string
// }

export type IAddAttachmentToEntrySubSaga = {
    fileData: IAttachmentRequestPayload
    presigned_url: string
    attachmentApi: IAttachmentAPI
    entryID: string
    carID: string
}

export type IAddAttachmentToEntryAwsTrackingSaga = {
    fileData: IAttachmentRequestPayload
    presigned_url: string
}

// export function* AddAttachmentsToEntrySaga(
//     payload: IAddAttachmentsToEntrySaga
// ): any {
// here, give the presigned url with the right suffix to each file before sending off
// // let urls = yield select(getPresignedUrls)
// let ats_arr: IAttachmentAPI[] = payload.files_data_list.map(
//     (a: IAttachmentRequestPayload) => {
//         return {
//             uid: '',
//             filename:
//                 payload.presigned_urls &&
//                 payload.presigned_urls[0] &&
//                 payload.presigned_urls[0].filename
//                     ? payload.presigned_urls[0].filename
//                     : undefined,
//             title: a.filename,
//         }
//     }
// )
// let p2: IUploadAttachmentPreSignedUrlPayloadSingle = {
//     fileSuffix: ConverFileTypeToFileSuffixEnum(payload.fileData.filename),
//     entityID: payload.carID,
//     carID: payload.carID,
// }
// let data: IUploadImagePreSignedUrlPayloadSingle = yield call(
//     getPreSignedUrlEntryAttachment,
//     p2
// )
// if (data) {
//     ats.filename = data.filename
//     ats.presigned_download_url = data.url
//     let p: IAddAttachmentToEntrySubSaga = {
//         fileData: payload.fileData,
//         presigned_url: data.url,
//         attachmentApi: ats,
//         carID: payload.carID,
//         entryID: payload.entryID,
//     }
//     yield call(UploadEntryAttachmentToAwsSaga, p)
// }
// }

function* watcherAddAttachmentToEntry() {
    while (true) {
        const { payload } = yield take(
            requestActions.addAttachmentsToEntryRequest
        )

        yield call(AddAttachmentsToEntryAPISaga, payload)
    }
}

const add_attachment_to_entry: any[] = [fork(watcherAddAttachmentToEntry)]

export default add_attachment_to_entry
