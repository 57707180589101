import * as React from 'react'
import styled from 'styled-components'
// import blank_user from "../../../public/assets/icons/blank_user.svg";
import { ICarsState } from 'entityModels'
import { device } from '../../templates/displays/devices'
// import blank_user from '../../../public/assets/icons/profile_pic_placeholder.svg'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import * as SvgIcons from '../../atoms/icons/components'
import tasks_user_banner from '../../../public/assets/icons/tasks_user_banner.svg'
import timelineCardEntryIcon from '../../../public/assets/icons/timelineCardEntryIcon.svg'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
// import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import { Link } from 'react-router-dom'
import colours from '../../../providers/theme/colours'

const Wrapper = styled.section<StyleProps>`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        color: var(--text-strong, #1a1a1a);
        white-space: nowrap;
        margin-top: 0px;
        padding-left: 0px;
        padding-bottom: 0px;
        width: auto;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    white-space: wrap;
    padding-left: ${(props) => (props.fullWidth ? '20px' : '0px')};
    padding-right: ${(props) => (props.fullWidth ? '10px' : '0px')};
    margin-top: ${(props) => (props.fullWidth ? '30px' : '0px')};
    padding-bottom: ${(props) => (props.fullWidth ? '24px' : '0px')};
`

const Column = styled.div<StyleProps>`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        justify-content: flex-start;
        align-items: start;
        flex-direction: column;
        padding-left: 25px;
    }
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
    padding-left: 24px;
`

const Row = styled.div<StyleProps>`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding-top: 1px;
        padding-bottom: 2px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-top: 1px;
    padding-bottom: 0px;
`

const UserImg = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
`

const RowIcon = styled.img<StyleProps>`
    @media ${device.beyond_ipad_mobile} {
        height: 15px;
        min-width: 15px;
        margin-top: 0px;
    }
    height: 15px;
    width: 14px;
    margin-top: 4px;
    margin-right: 2px;
    margin-left: 2px;
`

const Circle = styled.div<StyleProps>`
    position: relative;

    border: ${(props) =>
        props.hasImage ? 'none' : 'solid 1px var(--border-muted, #e5e5e5)'};

    @media ${device.beyond_ipad_mobile} {
        border-radius: 16px;
        width: 65px;
        height: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    border-radius: 16px;
    width: ${(props) => (props.fullWidth ? '116px' : '57px')};
    height: ${(props) => (props.fullWidth ? '116px' : '57px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 395px) {
        width: ${(props) => (props.fullWidth ? '96px' : '57px')};
        height: ${(props) => (props.fullWidth ? '96px' : '57px')};
    }
`

const FullName = styled.div<StyleProps>`
    @media ${device.beyond_ipad_mobile} {
        font-size: 0.94rem;
        font-family: Lato;
        padding-bottom: 5px;
        color: var(--text-strong, #1a1a1a);
    }

    font-size: 18px;

    @media (max-width: 395px) {
        font-size: 16px;
    }

    @media (max-width: 352px) {
        font-size: 15px;
    }

    font-family: Lato-semibold;
    padding-bottom: ${(props) => (props.fullWidth ? '8px' : '3px')};
    color: var(--text-strong, #1a1a1a);
    flex-wrap: wrap;
    @media ${device.mobile} {
        padding-bottom: 6px;
    }
`

const RowText = styled.div<StyleProps>`
    @media ${device.beyond_ipad_mobile} {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        color: var(--text-darker, #616161);
        font-size: 13px;
        /* letter-spacing: 150%; */
        padding-left: 10px;
    }
    font-family: Lato;
    color: var(--text-darker, #616161);
    font-size: ${(props) => (props.fullWidth ? '14px' : '11px')};
    letter-spacing: 0px;
    padding-left: 7px;
    flex-wrap: wrap;
    white-space: wrap;
    @media (max-width: 350px) {
        font-size: 13px;
    }
`

const MobileAdjust = styled.div`
    @media ${device.beyond_ipad_mobile} {
        transform: translate(0px, 0px);
    }
    transform: translate(0px, 0px);
`

// PaddingAdjust

const PaddingAdjust = styled.div`
    @media ${device.beyond_ipad_mobile} {
        padding-left: 3px;
    }
    padding-left: 1px;
`

const ListItemAction = styled.button`
    all: unset;
    font-size: 14px;
    color: var(--primary, #5ec3ca);
    cursor: pointer;
`

const IconWrapper = styled.div`
    display: inline-flex;
    width: 28px;
    height: 28px;
    padding: 6px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--garage_icons_bg, #fff);
    /* 8 */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
`

function mapStateToProps(state: RootState) {
    return {
        cars_data: state.entities.carsData,
    }
}

const dispatchProps = {}

type Props = {
    user: any
    fullWidth?: boolean
    car_number?: number
    car_id?: string
    garage_id?: string | null
    cars_data: ICarsState
    hideTasksCount?: any
    hideCarsCount?: any
    outstanding_tasks_number?: number
    car_entries_count?: number
    isExternalGarage?: boolean
    linkToAddingLocation?: () => void
    linkToAddingCar?: () => void
    linkToAddingTasks?: () => void
    readonlyMode?: boolean
}

// getTasksByGarageIdRequest
type StyleProps = {
    fullWidth: boolean
    hasImage?: boolean
}

type State = {
    src: undefined | null | string
    isLoading: boolean
    openImageEditForm: boolean
}

class UserTagBox extends React.Component<Props, State> {
    state = { src: undefined, isLoading: true, openImageEditForm: false }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true

        const src =
            this.props.user.profile_image_url !== undefined &&
            this.props.user.profile_image_url !== false &&
            this.props.user.profile_image_url !== null &&
            this.props.user.profile_image_url.length > 1
                ? this.props.user.profile_image_url
                : undefined

        if (src === blank_user) {
            this._isMounted && this.setState({ openImageEditForm: true })
        }

        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.user.profile_image_url !==
            prevProps.user.profile_image_url
        ) {
            this.setState({ src: null, isLoading: true })
            const src =
                this.props.user.profile_image_url !== undefined &&
                this.props.user.profile_image_url !== false &&
                this.props.user.profile_image_url !== null &&
                this.props.user.profile_image_url.length > 1
                    ? this.props.user.profile_image_url
                    : undefined

            if (src) {
                return this.setState({ src: src, isLoading: false })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let { linkToAddingCar, linkToAddingLocation, readonlyMode } = this.props

        const listItems = {
            location: {
                action_text: 'Add location',
                action: linkToAddingLocation,
            },
            cars: {
                action_text: 'Add car',
                action: linkToAddingCar,
            },
        }

        return (
            <Wrapper fullWidth={this.props.fullWidth ? true : false}>
                {this.props.user &&
                    (this.props.isExternalGarage ? (
                        <>
                            <Circle
                                fullWidth={this.props.fullWidth ? true : false}
                                hasImage={
                                    this.state.src !== undefined ? true : false
                                }
                            >
                                <UserImg
                                    src={
                                        this.state.src !== undefined
                                            ? this.state.src
                                            : blank_user
                                    }
                                    onLoad={() =>
                                        this._isMounted &&
                                        this.setState({
                                            ...this.state,
                                            isLoading: false,
                                        })
                                    }
                                />
                                <SkeletonAbsolute
                                    isthingloading={this.state.isLoading}
                                    // isRound={true}
                                />
                            </Circle>
                        </>
                    ) : (
                        <Link
                            to={
                                this.state.openImageEditForm
                                    ? `/account?id=profile_picture`
                                    : `/account`
                            }
                        >
                            <Circle
                                fullWidth={this.props.fullWidth ? true : false}
                                hasImage={
                                    this.state.src !== undefined ? true : false
                                }
                            >
                                <UserImg
                                    src={
                                        this.state.src !== undefined
                                            ? this.state.src
                                            : blank_user
                                    }
                                    onLoad={() =>
                                        this._isMounted &&
                                        this.setState({
                                            ...this.state,
                                            isLoading: false,
                                        })
                                    }
                                />
                                <div style={{ width: '100%' }}>
                                    <SkeletonAbsolute
                                        isthingloading={this.state.isLoading}
                                        // isRound={true}
                                    />
                                </div>
                            </Circle>
                        </Link>
                    ))}
                {this.props.user && (
                    <Column fullWidth={this.props.fullWidth ? true : false}>
                        <FullName
                            fullWidth={this.props.fullWidth ? true : false}
                        >
                            {this.props.user.display_name &&
                                this.props.user.display_name}{' '}
                        </FullName>
                        <Row
                            fullWidth={this.props.fullWidth ? true : false}
                            style={{ paddingTop: 4 }}
                        >
                            <IconWrapper>
                                <SvgIcons.LocationIcon
                                    color={
                                        this.props.user.location &&
                                        this.props.user.location !==
                                            'Unknown ' &&
                                        this.props.user.location.length > 1
                                            ? undefined
                                            : colours.light.primary
                                    }
                                    size="21px"
                                />
                            </IconWrapper>
                            <RowText
                                fullWidth={this.props.fullWidth ? true : false}
                                style={{ textTransform: 'capitalize' }}
                            >
                                <MobileAdjust>
                                    <PaddingAdjust>
                                        {this.props.user.location &&
                                        this.props.user.location !==
                                            'Unknown ' &&
                                        this.props.user.location.length > 1 ? (
                                            this.props.user.location
                                                ?.replace(
                                                    'United Kingdom',
                                                    'UK'
                                                )
                                                ?.replace(
                                                    'United States',
                                                    'USA'
                                                )
                                        ) : readonlyMode ? (
                                            'Unknown'
                                        ) : (
                                            <ListItemAction
                                                onClick={
                                                    listItems[`location`].action
                                                }
                                            >
                                                {
                                                    listItems[`location`]
                                                        .action_text
                                                }
                                            </ListItemAction>
                                        )}
                                    </PaddingAdjust>
                                </MobileAdjust>
                            </RowText>
                        </Row>
                        {!this.props.hideCarsCount && (
                            <Row
                                fullWidth={this.props.fullWidth ? true : false}
                                style={{ paddingTop: 4 }}
                            >
                                <IconWrapper>
                                    <SvgIcons.CarIcon
                                        color={
                                            this.props.car_number
                                                ? undefined
                                                : colours.light.primary
                                        }
                                        size="21px"
                                    />
                                </IconWrapper>

                                <RowText
                                    fullWidth={
                                        this.props.fullWidth ? true : false
                                    }
                                >
                                    <MobileAdjust>
                                        {this.props.car_number ? (
                                            this.props.car_number
                                        ) : readonlyMode ? (
                                            0
                                        ) : (
                                            <ListItemAction
                                                onClick={
                                                    listItems[`cars`].action
                                                }
                                            >
                                                {listItems[`cars`].action_text}
                                            </ListItemAction>
                                        )}
                                    </MobileAdjust>
                                </RowText>
                            </Row>
                        )}

                        {!this.props.hideTasksCount && (
                            <Row
                                fullWidth={this.props.fullWidth ? true : false}
                            >
                                <RowIcon
                                    fullWidth={
                                        this.props.fullWidth ? true : false
                                    }
                                    src={tasks_user_banner}
                                    alt="tasks user banner"
                                />

                                <RowText
                                    fullWidth={
                                        this.props.fullWidth ? true : false
                                    }
                                >
                                    <MobileAdjust>
                                        {this.props.outstanding_tasks_number
                                            ? this.props
                                                  .outstanding_tasks_number ===
                                              1
                                                ? `${this.props.outstanding_tasks_number} Task`
                                                : `${this.props.outstanding_tasks_number} Tasks`
                                            : `No Tasks`}
                                    </MobileAdjust>
                                </RowText>
                                <div
                                    style={{
                                        paddingRight: '10px',
                                        paddingLeft: '10px',
                                        color: '#4c4d4e',
                                    }}
                                >
                                    {'|'}
                                </div>
                                <RowIcon
                                    fullWidth={
                                        this.props.fullWidth ? true : false
                                    }
                                    src={timelineCardEntryIcon}
                                    alt="timeline_entries_number"
                                />

                                <RowText
                                    fullWidth={
                                        this.props.fullWidth ? true : false
                                    }
                                >
                                    <MobileAdjust>
                                        {this.props.car_entries_count
                                            ? this.props.car_entries_count === 1
                                                ? `${this.props.car_entries_count} Entries`
                                                : `${this.props.car_entries_count} Entries`
                                            : `No Entries`}
                                    </MobileAdjust>
                                </RowText>
                            </Row>
                        )}
                    </Column>
                )}
            </Wrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(UserTagBox)
