import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface Props {
    tabindex?: number
    backgroundcolor?: string
    $theme: ITheme
}

const StyledTextArea = styled.textarea<Props>`
    border: none;
    background-color: ${(props) =>
        props.backgroundcolor
            ? props.backgroundcolor
            : colours[props.$theme].background_default};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    border-radius: 5px;
    resize: none;
    color: ${(props) => colours[props.$theme].text_default};

    :focus {
        outline: 0;
    }
    ::placeholder {
        color: ${(props) => colours[props.$theme].text_darker};
    }

    @media ${device.ipad} {
        width: 100%;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;
        overflow-y: auto;
        font-size: 14px;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        padding: 30px;
        padding-bottom: 10px;
        height: 100%;
        font-size: 16px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${(props) =>
            colours[props.$theme].base_overlays_neutral_alpha_50};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${(props) => colours[props.$theme].border_muted};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${(props) => colours[props.$theme].border_muted};
    }

    @media ${device.mobile} {
        width: 100%;
        padding-top: 20px;
        padding-inline: 20px;
        height: 100%;
        overflow-y: auto;

        /* width */
        ::-webkit-scrollbar {
            width: 20px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: ${(props) =>
                colours[props.$theme].base_overlays_neutral_alpha_50};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${(props) => colours[props.$theme].border_muted};
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: ${(props) => colours[props.$theme].border_muted};
        }
    }
`

interface ITextAreaProps {
    name: string
    value?: any
    placeholder?: string
    handleChange?: (e: any) => any
    tabindex?: number
    formikprops?: any
    id?: string
    defaultValue?: string
    backgroundColor?: string
    maxLength?: number
}

function TextArea({
    name,
    placeholder,
    tabindex,
    formikprops,
    id,
    value,
    handleChange,
    defaultValue,
    backgroundColor,
    maxLength,
}: ITextAreaProps) {
    const { theme } = useThemes()
    return (
        <StyledTextArea
            $theme={theme}
            id={id}
            name={name}
            placeholder={placeholder}
            onChange={handleChange ? handleChange : formikprops.handleChange}
            tabindex={tabindex}
            value={value}
            defaultValue={defaultValue && defaultValue}
            backgroundcolor={backgroundColor}
            maxLength={maxLength}
        />
    )
}

export default TextArea
