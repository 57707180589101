import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const CrossIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.52031 12.4189L12.4198 2.51942L13.4805 3.58008L3.58097 13.4796L2.52031 12.4189Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.58011 2.52031L13.4796 12.4198L12.4189 13.4805L2.51945 3.58097L3.58011 2.52031Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default CrossIcon
