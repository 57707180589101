import { ICar } from 'entityModels'
import { useState } from 'react'
import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import GoBackTopButtonDesktop from '../../../../atoms/Button/goBackTopButtonDesktop'
import {
    IThreeDotsActionsItems,
    OptionsMenuItem,
} from '../../../../atoms/menu/actionsMenu/actionsMenuDots'
import PublishChecksManagerDesktop from '../../../../organisms/showroom/publishChecksManager/publishChecksManagerDesktop'
import { IEditShowroomModalOptions } from '../../../../pages/showroom/car/showroomCarProfileEdit'
import { IPublishValidationObject } from './carShowroomEditBottomBar'

type Props = {
    isPublished: boolean
    handleSetModalOpened: (p: IEditShowroomModalOptions) => any
    menu_items: IThreeDotsActionsItems[]
    publishValidationObject: IPublishValidationObject
    currentCar: ICar | undefined
    currentCarId: string | undefined
    isReadyToPublish: boolean
    isValidationSheetOpened: boolean
    setIsValidationSheetOpened: (p: boolean) => any
    isSold?: boolean
}

type StyleProps = {
    $role?: string
    $isMobile?: boolean
}

const WrapperDesktop = styled.div<StyleProps>`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
        props.$role === 'admin' ? 'center' : 'space-between'};
    align-items: center;
    height: 5rem;
    background-color: var(--bg-color, #fff);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;
    padding: 1rem 2rem;

    @media (max-width: 800px) {
        display: none;
    }
`

// container for action button
const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`

const MenuBox = styled.div`
    z-index: 2;
    position: absolute;
    right: 240px;
    bottom: 90px;

    top: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-size: 16px;
    /* Base/White */

    background: var(--bg-color, #fff);
    /* 24 */

    box-shadow: 0px 4px 24px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border-radius: 4px;

    height: auto;
    width: max-content;

    :hover {
        color: var(--primary, #5ec3ca);
        transition: all 100ms;
    }
`

const ReadyToPublishChecksWrapper = styled.div`
    position: fixed;
    right: 30px;
    bottom: 112px;
    z-index: 8;
    @media (max-height: 950px) {
        bottom: 90px;
    }
`

const ShowroomCarEditBottomBarDesktop = (props: Props) => {
    let {
        menu_items,
        publishValidationObject,
        currentCar,
        currentCarId,
        isReadyToPublish,
        isPublished,
        isSold,
    } = props

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    let renderItem = (item: IThreeDotsActionsItems, index: number) => {
        return (
            <OptionsMenuItem
                key={index}
                $isDisabled={item.isDisabled}
                onClick={(e: any) => {
                    e.preventDefault()

                    !item.isDisabled &&
                        item.actionOnClick &&
                        item.actionOnClick()
                    setIsMenuOpen(false)

                    return
                }}
            >
                {item.text}
            </OptionsMenuItem>
        )
    }

    let determineActionLogic = () => {
        let final: {
            copy: string
            action: any
        } = {
            copy: '',
            action: () => {},
        }

        if (isSold) {
            final.copy = 'Car sold'
        }

        if (isPublished) {
            final.copy = 'Mark as sold'
            final.action = () =>
                props.handleSetModalOpened('mark_as_sold_confirmed')
        }

        if (!isSold && !isPublished) {
            final.copy = 'Publish to Showroom'
        }

        if (!isPublished) {
            final.action = () =>
                props.handleSetModalOpened('publication_confirmed')
        }

        if (!isReadyToPublish) {
            final.action = () => props.setIsValidationSheetOpened(true)
        }

        return final
    }

    let actionLogic = determineActionLogic()
    return (
        <WrapperDesktop>
            <GoBackTopButtonDesktop isSold={props.isSold} />

            <Actions>
                <ButtonAtom
                    theme="capitalize-white-background"
                    width="100px"
                    height="48px"
                    dataCyId={'showroom-car-share'}
                    icon={''}
                    fontSize={16}
                    onClick={() => {
                        props.setIsValidationSheetOpened(false)
                        setIsMenuOpen(!isMenuOpen)
                    }}
                >
                    <div
                        style={{
                            fontWeight: isMenuOpen ? 800 : 'auto',
                            color: isMenuOpen
                                ? 'var(--primary, #5ec3ca)'
                                : 'var(--primary_stronger, #157076)',
                            fontFamily: isMenuOpen ? 'Lato-Bold' : 'Lato',
                        }}
                    >
                        Options
                    </div>
                </ButtonAtom>

                {isMenuOpen && (
                    <MenuBox>
                        {menu_items.map((item, index) => {
                            return renderItem(item, index)
                        })}
                    </MenuBox>
                )}

                <ButtonAtom
                    theme="secondary"
                    width="200px"
                    height="48px"
                    dataCyId={'showroom-car-enquire'}
                    onClick={() => {
                        // redux action to publish to showroom here later
                        // if (!isReadyToPublish) {
                        //     props.setIsValidationSheetOpened(true)
                        // } else if (!isPublished) {
                        //     props.handleSetModalOpened('publication_confirmed')
                        // } else if (isPublished) {
                        //     props.handleSetModalOpened('mark_as_sold_confirmed')
                        // }

                        actionLogic.action()
                    }}
                    fontSize={16}
                    textTransform="none"
                    customBgColor={
                        isSold
                            ? '#a9a9a9'
                            : isReadyToPublish === false
                            ? 'var(--warning, #f9bb4b)'
                            : 'var(--primary, #5ec3ca)'
                    }
                >
                    <div
                        style={{
                            cursor: isSold ? 'not-allowed' : 'pointer',
                        }}
                    >
                        {actionLogic.copy}
                    </div>
                </ButtonAtom>
                {!isSold && props.isValidationSheetOpened ? (
                    <ReadyToPublishChecksWrapper>
                        <PublishChecksManagerDesktop
                            publishChecksObject={publishValidationObject}
                            currentCar={currentCar}
                            currentCarId={currentCarId}
                            onClose={() =>
                                props.setIsValidationSheetOpened(false)
                            }
                        />
                    </ReadyToPublishChecksWrapper>
                ) : null}
            </Actions>
        </WrapperDesktop>
    )
}

export default ShowroomCarEditBottomBarDesktop
