type Props = {
    width?: string
    height?: string
    color?: string
}

const CheckIcon = ({ width, height, color }: Props) => {
    return (
        <svg
            width={width ?? '25'}
            height={height ?? '25'}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.5582 5.61041L9.16451 20.7238L1.4375 12.6104L2.52371 11.5759L9.16451 18.5488L22.472 4.57593L23.5582 5.61041Z"
                fill={color ?? '#5EC3CA'}
            />
        </svg>
    )
}

export default CheckIcon
