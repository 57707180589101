import styled from 'styled-components'
import ButtonsStandardBottomRowMobile, {
    IFormBtnRowProps,
} from '../../organisms/editForms/buttons/buttonsStandardBottomRowMobile'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import Icon from '../icons'

type IStyleProps = {
    $theme: ITheme
}

type IStyled2 = {
    removeFixed?: boolean
}
const Container = styled.div<IStyled2>`
    position: absolute;
    bottom: 0;
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media ${device.mobile_and_ipad} {
        position: ${(props) => (props.removeFixed ? 'absolute' : 'fixed')};
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media screen and (min-width: 880px) {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
    }
`
const GoBackBtn = styled.button<IStyleProps>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    background-color: transparent;
    border-radius: 5px;
    color: ${(props) => colours[props.$theme].text_default};
    border: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
    font-family: Lato;
    font-size: 16px;
    text-transform: capitalize;
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;

    @media ${device.mobile} {
        font-size: 14px;
    }

    @media (max-width: 330px) {
        height: 32px;
    }

    margin-right: 5px;
`

const ClearBtn = styled.button<IStyleProps>`
    cursor: pointer;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    transition: all 200ms;
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    color: ${(props) => colours[props.$theme].error};
    border: ${(props) => `1px solid ${colours[props.$theme].error}`};
    font-family: Lato;
    font-size: 16px;
    text-transform: capitalize;
    padding-left: 16px;
    padding-right: 16px;
    height: 40px;

    @media ${device.mobile} {
        font-size: 14px;
    }

    @media (max-width: 330px) {
        height: 32px;
    }
`

const BottomBarBtnsStep = (props: IFormBtnRowProps) => {
    const { theme } = useThemes()
    return (
        <Container
            removeFixed={props.removeFixed}
            style={{
                borderTopColor: props.removeLine
                    ? 'transparent'
                    : colours[theme].border_muted,
                backgroundColor: props.removeLine
                    ? 'transparent'
                    : props.customBg ?? colours[theme].background_default,
            }}
        >
            {props.hasGoBack && props.onGoback ? (
                <GoBackBtn
                    $theme={theme}
                    onClick={() => {
                        props.onGoback()
                    }}
                    style={{
                        height: props.height ?? '40px',
                        width: props.back_btn_width ?? undefined,
                    }}
                >
                    {props.goBackIcon && <Icon icon={props.goBackIcon} />}
                    {props.goBackIcon
                        ? null
                        : props.goBackText
                        ? props.goBackText
                        : 'back'}
                </GoBackBtn>
            ) : null}
            {props.hasClear && props.onClear ? (
                <ClearBtn
                    $theme={theme}
                    onClick={() => {
                        props.onClear()
                    }}
                >
                    clear
                </ClearBtn>
            ) : null}
            <ButtonsStandardBottomRowMobile
                // cancel={() => {
                //     props.cancel && props.cancel()
                // }}
                // submit={() => {
                //     props.submit && props.submit()
                // }}
                // confirmationtext={props.confirmationtext}
                // disabled={props.disabled}
                // removePadding={true}
                // redAction={props.redAction}
                // redActionCopy={props.redActionCopy}
                // removeCancel={props.removeCancel}
                {...props}
            />
            {props.customSidePadding && (
                <div style={{ paddingRight: props.customSidePadding }} />
            )}
        </Container>
    )
}

export default BottomBarBtnsStep
