import { IApi_SearchUsersInDirectShare_args } from 'ApiInterfaces'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const searchUsersInDirectShareApi = (
    p: IApi_SearchUsersInDirectShare_args
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.search_users_direct_share_endpoint(p),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Search users in direct share error'
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
