import { IDirectShareOwnedItem } from 'entityModels'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { shareCardConverterFormat } from '../../../redux/conversions/time'
import { device } from '../../templates/displays/devices'
import CarShareActionsMobile from '../menu/bottomSheetMenu/carShareActionsMobile'
import DirectShareInfo from './directShareInfo'
import ThreeDotsIcon from '../icons/components/threeDots'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const TableItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
`

const TableItemInfoCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0px;
    position: relative;
    max-width: 80%;
`

const Name = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 55%;
    transform: translateY(-2px);
`

const DateSpan = styled.span`
    color: var(--text-default, #666666);
    font-size: 10px;
    /* line-height: 14px; */
    font-style: italic;
    display: inline-block;

    @media ${device.ipad} {
        font-size: 12px;
        line-height: 15px;
    }
`

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--off-bg-color, #fafafa);
    border: 1px solid var(--border_muted_subtle);
    height: 100px;
    padding-left: 20px;
    padding-right: 20px;
`

const ThreeDotsContainer = styled.div`
    width: 22px;
    height: 22px;
    transform: rotate(90deg);
    border: 1px solid var(--primary_20);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary_04);
`

const SmallVertLine = styled.div`
    height: 12px;
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
    margin-left: 10px;
    margin-right: 10px;
`

type IQuickShareActions = {
    settings: any
    addUserToShare: any
    delete: any
}

type IactionsItems = {
    id: string
    text: string
    action?: any
}

type Props = {
    carid?: string
    share: IDirectShareOwnedItem
    index: number
    isLast: boolean
    actionsData: IQuickShareActions
    isGarage?: boolean
}

const DirectShareRowMobile: React.FunctionComponent<Props> = (props: Props) => {
    let { share, isLast, index, actionsData } = props

    const [isCarSharesMenuOpen, toggleCarSharesMenuOpen] = useState(false)

    const actionItems: IactionsItems[] = [
        {
            id: 'add_person',
            text: 'Add person',
            action: actionsData.addUserToShare,
        },
        {
            id: 'settings',
            text: 'Settings',
            action: actionsData.settings,
        },
        {
            id: 'delete',
            text: 'Delete share',
            action: actionsData.delete,
        },
    ]

    const { theme } = useThemes()

    return (
        <>
            <Container
                style={{
                    borderTopLeftRadius: index === 0 ? '5px' : '0px',
                    borderBottomLeftRadius: isLast ? '5px' : '0px',

                    borderTopRightRadius: index === 0 ? '5px' : '0px',
                    borderBottomRightRadius: isLast ? '5px' : '0px',
                }}
                onClick={() => toggleCarSharesMenuOpen(true)}
            >
                <TableItemInfoCol>
                    <TableItemContainer>
                        <Name>
                            {share.title ? (
                                share.title
                            ) : (
                                <span
                                    style={{
                                        color: 'grey',
                                    }}
                                >
                                    Pending Account
                                </span>
                            )}
                        </Name>
                        <SmallVertLine />
                        <DateSpan>
                            {shareCardConverterFormat(share.created_at)}
                        </DateSpan>
                    </TableItemContainer>
                    <div style={{ paddingTop: '10px' }} />
                    <DirectShareInfo share={share} />
                </TableItemInfoCol>

                <ThreeDotsContainer
                // onClick={() => toggleCarSharesMenuOpen(true)}
                >
                    <ThreeDotsIcon size={'3'} color={colours[theme].primary} />
                </ThreeDotsContainer>
            </Container>
            <CarShareActionsMobile
                toggle={() =>
                    isCarSharesMenuOpen
                        ? toggleCarSharesMenuOpen(false)
                        : toggleCarSharesMenuOpen(true)
                }
                entity={share}
                isCarSharesMenuOpen={isCarSharesMenuOpen}
                actions_items={actionItems}
            />
        </>
    )
}

export default DirectShareRowMobile
