import create_showroom_entry from './createShowroomEntry'
import get_showroom_entry_by_id from './getShowroomEntry'
import update_showroom_entry from './updateShowroomEntry'
import showroom_enquiry_by_entry_id from './showroomEnquiryByEntryId'
import delete_showroom_entry_by_id from './deleteShowroomEntry'
import search_showroom from './searchShowroom'
import validate_showroom_entry from './validateEntry'
import get_watchlist_entries from './getWatchlistEntries'
import add_watchlist_entry from './addEntryToWatchlist'
import remove_watchlist_entry from './removeEntryFromWatchlist'
import get_showroom_entry_recommendations from './getRecommendations'

let arr: any[] = [
    ...create_showroom_entry,
    ...get_showroom_entry_by_id,
    ...update_showroom_entry,
    ...showroom_enquiry_by_entry_id,
    ...delete_showroom_entry_by_id,
    ...search_showroom,
    ...validate_showroom_entry,
    ...get_watchlist_entries,
    ...add_watchlist_entry,
    ...remove_watchlist_entry,
    ...get_showroom_entry_recommendations,
]

export default arr
