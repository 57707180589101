import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const ChevronDown = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '14'}
            height={size ? `${Number(size) * 0.6}` : '9'}
            viewBox="0 0 14 9"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.99994 5.93934L12.4696 0.469666L13.5303 1.53033L6.99994 8.06066L0.469613 1.53033L1.53027 0.469666L6.99994 5.93934Z"
                fill={color ?? colours[theme].text_default}
            />
        </svg>
    )
}

export default ChevronDown
