import { IGalleryImage, IGalleryImagesObject } from 'entityModels'

import {
    GET_GALLERY_IMAGES_SUCCESS,
    SET_GALLERY_IMAGES_SUCCESS,
    UPDATE_CAR_GALLERY_IMAGE_SUCCESS,
    DELETE_CAR_GALLERY_IMAGE_SUCCESS,
    DELETE_ENTRY_GALLERY_IMAGE_SUCCESS,
    UPDATE_ENTRY_GALLERY_IMAGE_SUCCESS,
    ADD_TO_GALLERY_IMAGE_SUCCESS,
    CLEAN_UP_GALLERY_SUCCESS,
} from './constants'

import { createAction } from '@reduxjs/toolkit'

// import { IUploadImagesPreSignedUrlsPayload } from "IapiDataPayload";

// export const getGalleryImagesSuccess = createAction(
//     GET_GALLERY_IMAGES_SUCCESS,
//     (resolve) => (payload: IGalleryImagesObject) => resolve(images)
// )

export const getGalleryImagesSuccess = createAction(
    GET_GALLERY_IMAGES_SUCCESS,
    function prepare(payload: IGalleryImagesObject) {
        return {
            payload,
        }
    }
)

// export const setGalleryImagesSuccess = createAction(
//     SET_GALLERY_IMAGES_SUCCESS,
//     (resolve) => (payload: IGalleryImagesObject) => resolve(images)
// )

export const setGalleryImagesSuccess = createAction(
    SET_GALLERY_IMAGES_SUCCESS,
    function prepare(payload: IGalleryImagesObject) {
        return {
            payload,
        }
    }
)

// export const updateCarGalleryImageSuccess = createAction(
//     UPDATE_CAR_GALLERY_IMAGE_SUCCESS,
//     (resolve) => (payload: IGalleryImage) => resolve(payload)
// )

export const addToGalleryImageSuccess = createAction(
    ADD_TO_GALLERY_IMAGE_SUCCESS,
    function prepare(payload: IGalleryImage[]) {
        return {
            payload,
        }
    }
)

export const updateCarGalleryImageSuccess = createAction(
    UPDATE_CAR_GALLERY_IMAGE_SUCCESS,
    function prepare(payload: IGalleryImage) {
        return {
            payload,
        }
    }
)

export const updateEntryGalleryImageSuccess = createAction(
    UPDATE_ENTRY_GALLERY_IMAGE_SUCCESS,
    function prepare(payload: IGalleryImage) {
        return {
            payload,
        }
    }
)

// export const deleteCarGalleryImageSuccess = createAction(
//     DELETE_CAR_GALLERY_IMAGE_SUCCESS,
//     (resolve) => (payload: string) => resolve(payload)
// )

export const deleteCarGalleryImageSuccess = createAction(
    DELETE_CAR_GALLERY_IMAGE_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const deleteEntryGalleryImageSuccess = createAction(
    DELETE_ENTRY_GALLERY_IMAGE_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const cleanUpGallerySuccess = createAction(CLEAN_UP_GALLERY_SUCCESS)
