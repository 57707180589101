/* eslint-disable no-useless-escape */
import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import Paragraph from '../../../../../atoms/typography/paragraph'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'

const EditFormContainerDesktop = styled.section`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        margin-top: 28vh;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        width: 680px;
        padding-bottom: 30px;
        padding-top: 40px;
        height: 280px;
        overflow: auto;
        background-color: var(--bg-color, #fff);
        color: black;
        border-radius: 5px;
        position: relative;
        margin-bottom: 50px;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 50px;
`

const FieldTitle = styled.div`
    font-family: Lato-light;
    align-self: start;
    font-size: 21px;
    text-transform: capitalize;
`

const AbsoluteBtnCancel = styled.div`
    /* position: absolute;
    bottom: 30px;
    right: 30px; */
    align-self: flex-end;
    padding-right: 30px;
    padding-top: 30px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {
        error: state.user.error,
    }
}

interface Values {}

interface OtherProps {
    toggle: any
    submitFunc: any
    setToResetPasswordClicked: any
    hasResetPasswordBeenClicked: boolean
    userLoggedIn: any
    error: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { hasResetPasswordBeenClicked, error } = props

    return (
        <React.Fragment>
            <EditFormContainerDesktop
                data-attr={`change-password-modal-desktop`}
            >
                <InnerFormPadding>
                    <FieldTitle>Reset Password</FieldTitle>

                    {hasResetPasswordBeenClicked === true ? (
                        <div
                            style={{
                                paddingTop: '30px',
                                paddingBottom: '60px',
                            }}
                        >
                            <Paragraph>
                                {error === null ? (
                                    'Thank you. An email has been sent to reset your password.'
                                ) : (
                                    <div>
                                        Sorry, there has been an error. Please{' '}
                                        <ButtonAtom
                                            theme="naked-text"
                                            onClick={() => {
                                                props.setToResetPasswordClicked()
                                            }}
                                        >
                                            retry
                                        </ButtonAtom>
                                        .
                                    </div>
                                )}
                            </Paragraph>
                        </div>
                    ) : (
                        <div style={{ paddingTop: '50px' }}>
                            <ButtonAtom
                                theme="lowercase-blue-background"
                                onClick={() => {
                                    props.setToResetPasswordClicked()
                                    props.submitFunc(props.userLoggedIn.email)
                                }}
                                width="170px"
                                height="50px"
                            >
                                SEND ME AN EMAIL
                            </ButtonAtom>
                        </div>
                    )}
                </InnerFormPadding>

                <AbsoluteBtnCancel>
                    <ButtonAtom
                        onClick={(e: any) => {
                            e.preventDefault()
                            setTimeout(function () {
                                props.toggle()
                            }, 50)
                        }}
                        theme="lowercase-white-background"
                    >
                        {hasResetPasswordBeenClicked !== true
                            ? 'cancel'
                            : 'return'}
                    </ButtonAtom>
                </AbsoluteBtnCancel>
            </EditFormContainerDesktop>
        </React.Fragment>
    )
}

const ChangePasswordFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({}),

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(ChangePasswordFormDesktop)
