import styled from 'styled-components'
import share_icon from '../../../public/assets/icons/share_icon.svg'
import { EditIcon } from '../statefulicons'
import HeartIcon from '../icons/components/Heart'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    icon: 'watchlist' | 'share' | 'pen'
    size?: string
    padding?: string
    isDisabled?: boolean
    onClick?: any
    isActive?: boolean
}

type StyleProps = {
    $size?: string
    $padding?: string
}

// buttons with dynamic values, for supporting multi-device
const WatchListBtn = styled.button<StyleProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.$padding ? props.$padding : '16px')};
    gap: 8px;

    width: ${(props) => (props.$size ? props.$size : '48px')};
    height: ${(props) => (props.$size ? props.$size : '48px')};
    left: 291px;
    top: 68px;

    border-radius: 4px;
`

const ShareBtn = styled.button<StyleProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.$padding ? props.$padding : '12px')};
    gap: 8px;
    cursor: pointer;
    width: ${(props) => (props.$size ? props.$size : '40px')};
    height: ${(props) => (props.$size ? props.$size : '40px')};
    left: 358px;
    top: 76px;
    background: transparent;
    border: 1px solid var(--primary, #5ec3ca);
    border-radius: 4px;
`

const StandardBtn = styled.button<StyleProps>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.$padding ? props.$padding : '12px')};
    gap: 8px;
    cursor: pointer;
    width: ${(props) => (props.$size ? props.$size : '40px')};
    height: ${(props) => (props.$size ? props.$size : '40px')};
    border-radius: 4px;
    border: none;
`

const ShareIcon = styled.img``

const SquareIconBtn = (props: Props) => {
    const { theme } = useThemes()
    return (
        <>
            {props.icon === 'watchlist' && (
                <WatchListBtn
                    disabled={props.isDisabled}
                    $padding={props.padding}
                    $size={props.size}
                    onClick={props.onClick}
                    style={{
                        background: !props.isActive
                            ? 'transparent'
                            : colours[theme].error_50,
                        border: props.isDisabled
                            ? '1px solid var(--text-muted, #B3B3B3)'
                            : props.isActive
                            ? `1px solid ${colours[theme].error}`
                            : '1px solid var(--primary, #5ec3ca)',
                        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
                        filter: props.isDisabled
                            ? 'saturate(0)'
                            : 'saturate(1)',
                    }}
                >
                    <HeartIcon
                        color={
                            props.isActive
                                ? colours[theme].error
                                : colours[theme].primary
                        }
                        bgColor={
                            props.isActive
                                ? colours[theme].error
                                : colours[theme].background_default
                        }
                    />
                </WatchListBtn>
            )}

            {props.icon === 'share' && (
                <ShareBtn
                    disabled={props.isDisabled}
                    $padding={props.padding}
                    $size={props.size}
                    onClick={props.onClick}
                >
                    <ShareIcon src={share_icon} />
                </ShareBtn>
            )}
            {props.icon === 'pen' && (
                <StandardBtn
                    disabled={props.isDisabled}
                    $padding={props.padding}
                    $size={props.size}
                    onClick={props.onClick}
                    style={{
                        backgroundColor: props.isActive
                            ? 'var(--primary_stronger, #3C7D81)'
                            : 'var(--primary, #5ec3ca)',
                    }}
                >
                    <EditIcon
                        color="var(--bg-color, #fff)"
                        onClick={() => {}}
                    />
                </StandardBtn>
            )}
        </>
    )
}

export default SquareIconBtn
