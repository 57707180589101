import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneyYourProfileIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.4844 29.9992V23.5586H16.9844V29.9992H15.4844Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.5469 3.53817L20.6177 10.5039L19.1309 10.3055L20.06 3.33984L21.5469 3.53817ZM12.4195 3.3399L13.3479 10.3046L11.8611 10.5028L10.9326 3.53812L12.4195 3.3399ZM14.1516 16.3331L14.7736 20.9982L13.2867 21.1965L12.6648 16.5313L14.1516 16.3331ZM19.8135 16.5326L19.1914 21.1965L17.7046 20.9982L18.3267 16.3343L19.8135 16.5326Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.3129 19.6992L25.6485 21.5293L25.6616 21.5337C26.6018 21.8432 27.4294 22.3429 27.9862 23.3304C28.5181 24.2737 28.7503 25.5728 28.7503 27.3639L27.2503 27.3639C27.2503 25.6615 27.0216 24.6736 26.6796 24.0672C26.3645 23.5084 25.9091 23.1963 25.2052 22.9626L20.7252 21.6685C20.5196 22.4129 19.9734 23.0204 19.3118 23.4454C18.4592 23.9931 17.3268 24.3073 16.1145 24.3073C14.9022 24.3073 13.7697 23.9931 12.9172 23.4454C12.2555 23.0203 11.7091 22.4127 11.5036 21.6681L7.01468 22.9627C6.31086 23.1964 5.85548 23.5084 5.54042 24.0672C5.19847 24.6736 4.96973 25.6615 4.96973 27.3639L3.46973 27.3639C3.46973 25.5728 3.70196 24.2737 4.23382 23.3304C4.79065 22.3429 5.61827 21.8432 6.55844 21.5337L6.57173 21.5293L12.9161 19.6997V21.0207C12.9161 21.3793 13.1434 21.8078 13.7279 22.1834C14.3044 22.5537 15.1461 22.8073 16.1145 22.8073C17.0828 22.8073 17.9245 22.5537 18.501 22.1834C19.0856 21.8078 19.3129 21.3793 19.3129 21.0207V19.6992Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.2245 2.75C12.2627 2.75 9.06254 5.96135 9.06254 9.92687L9.06254 9.92876C9.05972 11.0444 9.2015 13.8441 9.77459 16.0703C10.0145 17.0021 10.7649 17.9244 11.7166 18.7276C12.646 19.5121 13.6752 20.1057 14.3288 20.4078C14.6539 20.5394 15.3465 20.7467 16.2257 20.7467H16.253C17.1321 20.7467 17.8239 20.5394 18.1491 20.4078C18.8027 20.1057 19.8319 19.5121 20.7613 18.7276C21.713 17.9244 22.4634 17.0021 22.7033 16.0703C23.2764 13.8441 23.4182 11.0444 23.4154 9.92876L23.4154 9.92687C23.4154 5.9611 20.217 2.75 16.2554 2.75H16.2245ZM7.56254 9.92592C7.56305 5.1361 11.4309 1.25 16.2245 1.25H16.2554C21.0493 1.25 24.9147 5.13623 24.9154 9.9257C24.9154 9.92609 24.9154 9.92648 24.9154 9.92687H24.1655L24.9154 9.92497C24.9154 9.92522 24.9154 9.92546 24.9154 9.9257C24.9183 11.103 24.7738 14.0442 24.1559 16.4442C23.8003 17.8258 22.7661 18.9984 21.7289 19.8739C20.6763 20.7623 19.5206 21.4284 18.761 21.7774L18.7472 21.7837L18.7331 21.7895C18.2634 21.9827 17.3707 22.2467 16.253 22.2467H16.2257C15.1081 22.2467 14.2146 21.9828 13.7448 21.7895L13.7307 21.7837L13.7169 21.7774C12.9573 21.4284 11.8016 20.7623 10.749 19.8739C9.71179 18.9984 8.67759 17.8258 8.32195 16.4442C7.70415 14.0443 7.55965 11.1033 7.56254 9.92592Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.96973 26.7227V28.1308C5.0264 28.1443 5.08753 28.1586 5.15311 28.1736C5.64461 28.2859 6.38575 28.4373 7.36885 28.5893C9.33495 28.8934 12.2692 29.1998 16.11 29.1998C19.9509 29.1998 22.8851 28.8934 24.8512 28.5893C25.8343 28.4373 26.5754 28.2859 27.0669 28.1736C27.1325 28.1586 27.1937 28.1443 27.2503 28.1308V26.7227H28.7503V29.2693L28.2158 29.4296L28.0003 28.7112C28.2158 29.4296 28.2161 29.4295 28.2158 29.4296L28.2136 29.4303L28.2099 29.4314L28.1979 29.4349L28.1558 29.4469C28.1197 29.4571 28.0676 29.4715 27.9995 29.4895C27.8634 29.5255 27.6636 29.5759 27.401 29.6359C26.8758 29.7559 26.0997 29.9141 25.0804 30.0717C23.0418 30.387 20.0309 30.6998 16.11 30.6998C12.1891 30.6998 9.17823 30.387 7.13961 30.0717C6.12035 29.9141 5.34424 29.7559 4.81908 29.6359C4.55649 29.5759 4.35665 29.5255 4.22052 29.4895C4.15245 29.4715 4.10031 29.4571 4.06422 29.4469L4.02214 29.4349L4.01015 29.4314L4.00643 29.4303L4.00513 29.4299C4.00492 29.4298 4.00422 29.4296 4.21973 28.7112L4.00513 29.4299L3.46973 29.2693V26.7227H4.96973ZM4.43454 27.9927C4.43454 27.9926 4.43454 27.9927 4.43454 27.9927V27.9927Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.06741 10.0012C9.06845 10.6364 9.10346 11.3882 9.1886 12.3807C9.36404 13.0799 10.0166 13.9857 11.1903 14.7379C12.3679 15.4927 14.0076 16.0475 16.0004 16.0475H16.5004C18.493 16.0475 20.1277 15.4928 21.3004 14.7386C22.4688 13.9871 23.1184 13.0814 23.294 12.3807C23.3792 11.3882 23.4142 10.6364 23.4152 10.0012C23.4083 9.97742 23.3983 9.95255 23.3859 9.93108C23.3695 9.9029 23.3583 9.89582 23.3582 9.89583C23.3582 9.89583 23.3582 9.89586 23.3583 9.8959C23.3508 9.89333 23.3276 9.88831 23.2792 9.89357C23.196 9.90261 23.039 9.94315 22.7956 10.0839C21.8122 10.6652 20.0573 11.5265 16.5004 11.5265H16.0004C12.4447 11.5265 10.6717 10.6659 9.68711 10.084C9.44361 9.94316 9.28669 9.90261 9.20348 9.89357C9.15499 9.8883 9.13181 9.89334 9.12431 9.89591C9.12303 9.89674 9.11213 9.90458 9.09677 9.93108C9.08433 9.95255 9.07434 9.97741 9.06741 10.0012ZM23.3586 9.89609C23.3585 9.89601 23.3584 9.89594 23.3583 9.8959L23.3586 9.89609ZM8.39134 8.5872C8.98917 8.25256 9.70765 8.36204 10.442 8.78776L10.4477 8.79105L10.4477 8.79108C11.2199 9.2478 12.7315 10.0265 16.0004 10.0265H16.5004C19.768 10.0265 21.2616 9.2485 22.035 8.79108L22.0406 8.78774L22.0406 8.78776C22.775 8.36204 23.4935 8.25256 24.0913 8.5872C24.6399 8.89429 24.8507 9.45025 24.9064 9.81093L24.9151 9.86691L24.9152 9.92355C24.917 10.6429 24.8782 11.4767 24.7837 12.5648L24.7794 12.615L24.7683 12.6641C24.4957 13.8792 23.5122 15.0994 22.1118 16.0002C20.6904 16.9143 18.7726 17.5475 16.5004 17.5475H16.0004C13.7282 17.5475 11.8064 16.9144 10.3809 16.0008C8.97685 15.1009 7.98732 13.8808 7.7143 12.6641L7.70328 12.615L7.69892 12.5648C7.6044 11.4767 7.56563 10.6429 7.56744 9.92355L7.56759 9.86691L7.57624 9.81093C7.63196 9.45025 7.84273 8.89429 8.39134 8.5872Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default JourneyYourProfileIcon
