import { ICarsObject } from 'entityModels'
import DriveListModal from '../../../molecules/insurance/modals/driveListModal'
import {
    IAnyErrorString,
    IOtherDriver_DriveList,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import QuickAddNewDriver from '../../../molecules/insurance/modals/quickAddNewDriver'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

type Props = {
    all_cars: ICarsObject
    driverID?: string
    updateDriver: (
        id: 'drive_list' | 'details' | string,
        value: IOtherDriver_DriveList[] | any
    ) => void
    active_modal: 'drive_list' | 'details' | 'new_driver'
    initialiseDriver?: () => any
    errorObj?: IAnyErrorString
}
const DriversModalManager = (props: Props) => {
    let { all_cars, driverID, updateDriver, errorObj } = props

    const dispatch: any = useAppDispatch()

    // console.log('driverID', driverID)

    let other_drivers = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft.other_drivers
    )

    let driver = driverID ? other_drivers[driverID] : undefined

    let renderModalBod = () => {
        switch (props.active_modal) {
            case 'drive_list': {
                return driverID ? (
                    <DriveListModal
                        driver_name={
                            driver
                                ? `${driver?.basic_details?.given_name} ${driver?.basic_details?.family_name}`
                                : ''
                        }
                        driver={driver}
                        allCars={all_cars}
                        selectedCars={driver?.drive_list}
                        onSelect={(values: IOtherDriver_DriveList[]) => {
                            if (
                                driverID &&
                                driver &&
                                driver.drive_list_errors &&
                                driver.drive_list_errors?.message &&
                                // if at least one car is selected (since error is if no cars at all)
                                values.length > 0
                            ) {
                                dispatch(
                                    insuranceActions.reset_other_driver_drive_error(
                                        driverID
                                    )
                                )
                            }
                            updateDriver('drive_list', values)
                        }}
                        errorObj={errorObj}
                    />
                ) : null
            }

            default:
                return (
                    <QuickAddNewDriver
                        updateDriver={updateDriver}
                        driverID={driverID}
                        initialiseDriver={props.initialiseDriver}
                    />
                )
        }
    }

    return <>{renderModalBod()}</>
}

export default DriversModalManager
