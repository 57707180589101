// ------- DISTANCE -------

//to display (retreived from BE)
export const kmToMiles = (value: number): number =>
    Math.round(value * 0.62137119)

export const generateFrontEndValueDistanceUnit = (
    id: string,
    value: number
): number => (id === 'kilometers' ? value : kmToMiles(value))

//to send to BE from form
const milesToKm = (value: number): number => Math.round(value * 1.609344)

export const generateBackEndValueDistanceUnit = (
    id: string,
    value: number
): number => (id === 'kilometers' ? value : milesToKm(value))

// ------- FUEL -------

//to display (retreived from BE)
export const cubicCentimetersToLiters = (value: number) => value * 0.001
const cubicCentimetersToCubicInches = (value: number) => value * 0.061023744

export const generateFrontEndValueFuelUnit = (id: string, value: number) =>
    id === 'cubic_centimeters'
        ? value
        : id === 'liters'
        ? cubicCentimetersToLiters(value)
        : cubicCentimetersToCubicInches(value)

//to send to BE from form

const cubicInchesToCubicCentimeters = (value: number) =>
    Math.round(value * 16.3871)
const litersToCubicCentimeters = (value: number) => Math.round(value * 1000)

export const generateBackEndValueFuelUnit = (id: string, value: number) =>
    id === 'cubic_centimeters'
        ? Math.round(value)
        : id === 'liters'
        ? litersToCubicCentimeters(value)
        : cubicInchesToCubicCentimeters(value)

export const cubicInchesToLiters = (value: number) =>
    Math.round(value * 0.0163871)
