import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const EnterIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.25 9.25L4 9.25L4 10.75L12.75 10.75L12.75 3L11.25 3L11.25 9.25Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.06066 9.99994L7.53033 12.4696L6.46967 13.5303L2.93934 9.99994L6.46967 6.46961L7.53033 7.53027L5.06066 9.99994Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default EnterIcon
