import {
    IPresignedUrlEntryImagesArgs,
    IUploadAndCreateCarCoverImageArgs,
    IUploadAndCreateCarGalleryImageArgs,
    IUploadAndCreateEntryGalleryImageArgs,
    IUploadAttachmentPreSignedUrlPayloadSaga,
    IUploadAttachmentPreSignedUrlPayloadSingle,
    reqPresignedUrImageArgs,
} from 'myModels'

import {
    GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_REQUEST,
    SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_REQUEST,
    GET_PRESIGNED_URL_CAR_COVER_IMAGE_REQUEST,
    SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_REQUEST,
    GET_PRESIGNED_URL_USERPROFILE_IMAGE_REQUEST,
    GET_PRESIGNED_URL_ENTRY_ATTACHMENT_REQUEST,
    GET_PRESIGNED_URL_COST_ATTACHMENT_REQUEST,
    GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_REQUEST,
    SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_REQUEST,
    SUBMIT_IMAGES_TO_S3_FILESTORAGE_REQUEST,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IApiUploadPresignedUrlImgsToS3_args } from 'ApiInterfaces'

// the below is equivalent to:
// export const getPresignedUrlsCarGalleryImagesRequest = (
//   payload: reqPresignedUrImageArgs
// ) => ({
//   type: GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_REQUEST,
//   payload: payload,
// });

// export const getPresignedUrlsCarGalleryImagesRequest = createAction(
//     GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_REQUEST,
//     (resolve) => (payload: reqPresignedUrImageArgs) => resolve(payload)
// )

export const getPresignedUrlsCarGalleryImagesRequest = createAction(
    GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_REQUEST,
    function prepare(payload: reqPresignedUrImageArgs) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlsEntryAttachmentsRequest = createAction(
    GET_PRESIGNED_URL_ENTRY_ATTACHMENT_REQUEST,
    function prepare(payload: IUploadAttachmentPreSignedUrlPayloadSaga) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlCostAttachmentRequest = createAction(
    GET_PRESIGNED_URL_COST_ATTACHMENT_REQUEST,
    function prepare(payload: IUploadAttachmentPreSignedUrlPayloadSingle) {
        return {
            payload,
        }
    }
)

// export const getPresignedUrlCarCoverImageRequest = createAction(
//     GET_PRESIGNED_URL_CAR_COVER_IMAGE_REQUEST,
//     (resolve) => (payload: string) => resolve(payload)
// )

export const getPresignedUrlCarCoverImageRequest = createAction(
    GET_PRESIGNED_URL_CAR_COVER_IMAGE_REQUEST,
    function prepare(payload: { carid: string; file_name: string }) {
        return {
            payload,
        }
    }
)

// export const submitCarGalleryImagesFilestorageRequest = createAction(
//     SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_REQUEST,
//     (resolve) => (payload: IUploadAndCreateCarGalleryImageArgs) =>
//         resolve(payload)
// )

export const submitCarGalleryImagesFilestorageRequest = createAction(
    SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_REQUEST,
    function prepare(payload: IUploadAndCreateCarGalleryImageArgs) {
        return {
            payload,
        }
    }
)

// export const submitCarCoverImageFilestorageRequest = createAction(
//     SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_REQUEST,
//     (resolve) => (payload: IUploadAndCreateCarCoverImageArgs) =>
//         resolve(payload)
// )

export const submitCarCoverImageFilestorageRequest = createAction(
    SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_REQUEST,
    function prepare(payload: IUploadAndCreateCarCoverImageArgs) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlUserProfileImageRequest = createAction(
    GET_PRESIGNED_URL_USERPROFILE_IMAGE_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlEntryGalleryImagesRequest = createAction(
    GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_REQUEST,
    function prepare(payload: IPresignedUrlEntryImagesArgs) {
        return {
            payload,
        }
    }
)

export const submitEntryGalleryImagesFilestorageRequest = createAction(
    SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_REQUEST,
    function prepare(payload: IUploadAndCreateEntryGalleryImageArgs) {
        return {
            payload,
        }
    }
)

export const submitFilesToS3Request = createAction(
    SUBMIT_IMAGES_TO_S3_FILESTORAGE_REQUEST,
    function prepare(payload: IApiUploadPresignedUrlImgsToS3_args) {
        return {
            payload,
        }
    }
)
