import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { ITimelineItem } from 'timelineModels'
import InsuranceHistoryFileJourneyStepDesktop from './insuranceDesktop'
import InsuranceHistoryFileJourneyStepMobile from './insuranceMobile'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import { getLastCarInsuranceEntryRequest } from '../../../../../../redux/entities/cars/actions/loadingActions'

type Props = {
    onAddInsurance: (entry1?: ITimelineItem | undefined) => any
}

export const JourneyHistoryFileInsuranceStep = (props: Props) => {
    const [entry1, setEntry1] = useState<undefined | ITimelineItem>(undefined)

    const history = useHistory()
    let locationState: any = history.location.state

    let params = new URLSearchParams(location.search)
    let carid: string | null = params.get('carid')
    const with_insurance: string | null = params.get('with_insurance')

    const carsData = useAppSelector((state) => state.entities.carsData.cars)
    const currentCar =
        carsData && carid && carsData[carid] ? carsData[carid] : undefined
    const insurance_timeline_item =
        currentCar && currentCar.last_insurance_entry
            ? currentCar.last_insurance_entry
            : undefined

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (with_insurance === 'true') {
            if (
                carid &&
                insurance_timeline_item === undefined &&
                currentCar?.archived_on === undefined
            ) {
                setTimeout(
                    () => dispatch(getLastCarInsuranceEntryRequest(`${carid}`)),
                    100
                )
            } else if (insurance_timeline_item) {
                setEntry1(insurance_timeline_item)
            }
        } else if (insurance_timeline_item) {
            setEntry1(insurance_timeline_item)
        }
    }, [currentCar])

    const hasInsuranceEntryWithMandatoryFields =
        insurance_timeline_item &&
        insurance_timeline_item.insurance_entry_policy_start_date &&
        insurance_timeline_item.insurance_entry_policy_end_date &&
        insurance_timeline_item.costUIDS &&
        insurance_timeline_item.costUIDS.length &&
        insurance_timeline_item.insurance_entry_underwriter
            ? true
            : false

    return (
        <div style={{ width: '100%' }}>
            <InsuranceHistoryFileJourneyStepDesktop
                entry1={entry1}
                setEntry1={setEntry1}
                step={3}
                totalSteps={3}
                goToPrevStep={() => {
                    locationState?.prevPath
                        ? history.push(locationState?.prevPath)
                        : history.push(
                              `/journey/history-file?step=add&carid=${carid}`
                          )
                }}
                goToNextStep={() => {
                    if (hasInsuranceEntryWithMandatoryFields) {
                        props.onAddInsurance()
                    } else {
                        props.onAddInsurance(entry1)
                    }
                }}
                hasInsuranceEntryWithMandatoryFields={
                    hasInsuranceEntryWithMandatoryFields
                }
            />
            <InsuranceHistoryFileJourneyStepMobile
                entry1={entry1}
                setEntry1={setEntry1}
                step={3}
                totalSteps={3}
                goToPrevStep={() => {
                    locationState?.prevPath
                        ? history.push(locationState?.prevPath)
                        : history.push(
                              `/journey/history-file?step=add&carid=${carid}`
                          )
                }}
                goToNextStep={() => {
                    if (hasInsuranceEntryWithMandatoryFields) {
                        props.onAddInsurance()
                    } else {
                        props.onAddInsurance(entry1)
                    }
                }}
                hasInsuranceEntryWithMandatoryFields={
                    hasInsuranceEntryWithMandatoryFields
                }
            />
        </div>
    )
}
