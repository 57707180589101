import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions/index'
import * as carActions from '../../entities/cars/actions'
import * as garageActions from '../../entities/garages/actions'
import * as timelineActions from '../../timeline/actions'
import * as tasksActions from '../../tasks/actions/successActions'
import * as technicalInformationActions from '../../entities/technical_information/actions/actions'
import * as highlightedFactsActions from '../../entities/highlighted_facts/actions/actions'
import * as galleriesActions from '../../entities/galleries/actions/actions'
import * as searchUsersActions from '../../searchUsers/actions/successActions'
import * as attachmentsActions from '../../attachments/actions/successActions'
import { showroomActions } from '../../showroom/reducer'
// import { api } from '../../services'
import posthog from 'posthog-js'
import { customNavDataActions } from '../../localdata/customNav/reducer'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IReduxError } from 'entityModels'
import { push } from 'connected-react-router'
import { api } from '../../services'
import { featureFlagsActions } from '../../featureFlags/reducer'

export function* logoutSaga(): any {
    try {
        // yield call(api.authentication.logout)

        // const domain_cookie_name =
        //     process.env.REACT_APP_ENV !== 'production'
        //         ? 'localhost'
        //         : '.custodian.club'

        // document.cookie =
        //     'jwt' +
        //     `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain_cookie_name};path=/;`

        // document.cookie =
        //     'box_timeline' +
        //     `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain_cookie_name};path=/;`

        // document.cookie =
        //     'ideas' +
        //     `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain_cookie_name};path=/;`

        // document.cookie =
        //     'android_install_prompt' +
        //     `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain_cookie_name};path=/;`

        // document.cookie =
        //     'ios_install_prompt' +
        //     `=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${domain_cookie_name};path=/;`

        yield call(api.authentication.logout)

        yield put(actions.default.actions.logoutUserSuccess())

        posthog.reset()

        // clean up Redux of all cars and garages data
        yield put(carActions.actions.cleanUpCarDataOnLogoutSuccess())
        yield put(garageActions.actions.cleanUpGaragesDataOnLogoutSuccess())
        yield put(timelineActions.actions.cleanUpTimelineDataOnLogoutSuccess())
        yield put(tasksActions.cleanUpTasksSuccess())
        yield put(
            technicalInformationActions.cleanUpTechnicalInformationSuccess()
        )
        yield put(highlightedFactsActions.cleanUpHighlightedFactsSuccess())
        yield put(galleriesActions.cleanUpGallerySuccess())
        yield put(searchUsersActions.cleanUpSearchUsersSuccess())
        yield put(attachmentsActions.cleanUpAttachmentsSuccess())
        yield put(showroomActions.cleanUpShowroom())
        yield put(featureFlagsActions.cleanupFeatureFlags())

        yield put(actions.default.actions.resetRefreshTokenRetriesCount())

        posthog.capture('USER LOGOUT')
        yield put(customNavDataActions.setInitialLandingUrl(null))

        yield put(push('/signin'))
        localStorage?.removeItem('refresh_token')
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(actions.default.errorActions.logoutUserError(typedError))
        window.location.reload()
    }
}

function* watcherLogout() {
    while (true) {
        yield take(actions.default.loadingActions.logoutRequest)
        yield call(logoutSaga)
    }
}

const logout: any[] = [fork(watcherLogout)]

export default logout
