import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const MaintenanceIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.81072 3.59314C8.40441 2.99945 9.17102 2.683 9.9494 2.64501L8.10652 4.48789L8.7824 7.21766L11.5122 7.89353L13.355 6.05065C13.317 6.82903 13.0006 7.59565 12.4069 8.18934C11.4433 9.15295 10.0237 9.38603 8.83924 8.88416L8.37377 8.68694L3.92163 13.1391C3.62873 13.432 3.15386 13.432 2.86097 13.1391C2.56807 12.8462 2.56807 12.3713 2.86097 12.0784L7.31311 7.62628L7.11589 7.16081C6.61402 5.97632 6.8471 4.55675 7.81072 3.59314ZM5.55919 7.25888C5.07413 5.64031 5.46998 3.81255 6.75006 2.53248C8.06271 1.21983 9.95162 0.837117 11.5991 1.38016C12.2215 1.58533 12.3608 2.35492 11.9314 2.78433L9.76613 4.9496L10.021 5.97904L11.0504 6.23392L13.2157 4.06865C13.6451 3.63923 14.4147 3.77854 14.6199 4.40096C15.1629 6.04843 14.7802 7.93734 13.4676 9.25C12.1875 10.5301 10.3597 10.9259 8.74117 10.4409L4.98229 14.1997C4.10361 15.0784 2.67899 15.0784 1.80031 14.1997C0.921626 13.3211 0.921627 11.8964 1.80031 11.0178L5.55919 7.25888Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default MaintenanceIcon
