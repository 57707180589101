import styled from 'styled-components'
import { useAppSelector } from '../../../../../redux/store/hooks'
import ShowroomCarTopBarDesktop from './showroomCarTopBarDesktop'
import ShowroomCarTopBarMobile from './showroomCarTopBarMobile'

type Props = {
    isReadOnly: boolean
    isMobile?: boolean
    onClickEnquire?: any
    onClickShare?: any
    isGoBackMode?: boolean
    goBack?: () => any
    onClose?: () => any
    entryID: string
    is_watched: boolean
    carID?: string
    isSold?: boolean
}

type StyleProps = {
    $role?: string
    $isMobile?: boolean
}

// parent element
const Container = styled.div<StyleProps>`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;

    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.$isMobile ? '64px' : '5rem')};
    background-color: var(--bg-color, #ffffff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
`

const CarShowroomTopBar = (props: Props) => {
    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const showroomPublicData = useAppSelector((state) => {
        return state.showroom.normalisedPublicEntries
    })

    const ownerid =
        showroomPublicData &&
        showroomPublicData[props.entryID] &&
        showroomPublicData[props.entryID].owner?.id

    const isOwner =
        ownerid && userLoggedIn && ownerid === userLoggedIn.id ? true : false

    return (
        <Container
            $role={props.isReadOnly ? 'admin' : undefined}
            $isMobile={props.isMobile}
        >
            {!props.isMobile && (
                <ShowroomCarTopBarDesktop
                    entryID={props.entryID}
                    is_watched={props.is_watched}
                    onClickEnquire={props.onClickEnquire}
                    isReadOnly={props.isReadOnly}
                    carID={props.carID}
                    onClickShare={props.onClickShare}
                    isSold={props.isSold}
                    isOwner={isOwner}
                    goBack={props.goBack}
                    onClose={props.onClose}
                />
            )}
            {props.isMobile && (
                <ShowroomCarTopBarMobile
                    entryID={props.entryID}
                    is_watched={props.is_watched}
                    onClickEnquire={props.onClickEnquire}
                    isGoBackMode={props.isGoBackMode}
                    isReadOnly={props.isReadOnly}
                    carID={props.carID}
                    onClickShare={props.onClickShare}
                    isSold={props.isSold}
                    isOwner={isOwner}
                    goBack={props.goBack}
                    onClose={props.onClose}
                />
            )}
        </Container>
    )
}

export default CarShowroomTopBar
