import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

export default function CostIcon({ size, color }: Props) {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.25 0.25H14.75V16.4014L12 14.5681L10 15.9014L8 14.5681L6 15.9014L4 14.5681L1.25 16.4014V0.25ZM2.75 1.75V13.5986L4 12.7653L6 14.0986L8 12.7653L10 14.0986L12 12.7653L13.25 13.5986V1.75H2.75Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.25 9.5835H8.75V11.0835H4.25V9.5835Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0498 9.5835H11.7498V11.0835H10.0498V9.5835Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 4.4165C7.30964 4.4165 6.75 4.97615 6.75 5.6665C6.75 6.35686 7.30964 6.9165 8 6.9165C8.69036 6.9165 9.25 6.35686 9.25 5.6665C9.25 4.97615 8.69036 4.4165 8 4.4165ZM5.25 5.6665C5.25 4.14772 6.48122 2.9165 8 2.9165C9.51878 2.9165 10.75 4.14772 10.75 5.6665C10.75 7.18529 9.51878 8.4165 8 8.4165C6.48122 8.4165 5.25 7.18529 5.25 5.6665Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}
