import * as React from 'react'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IStyle {
    $theme: ITheme
    $width?: string | undefined
    $bg?: string
    $border?: string
    $txt_color?: string
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width ?? '100%'};
    padding: 16px;
    border: ${(props) => props.$border ?? 'transparent'};
    outline: none;
    border-radius: 4px;
    background-color: ${(props) =>
        props.$bg ?? colours[props.$theme].primary_08};
    transition: all 200ms;
    cursor: pointer;
    :hover {
        background-color: ${(props) =>
            props.$bg ?? colours[props.$theme].primary_08};
        transition: all 200ms;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) => props.$txt_color ?? colours[props.$theme].primary};
    text-align: center;
    font-family: Lato-bold;
    font-size: 16px;
    letter-spacing: 0.001px;
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    width?: string | undefined
    bg?: string
    border?: string
    txt_color?: string
}

const FullWidthBtn: React.FunctionComponent<Props> = (props: Props) => {
    let { onClick, text, bg, txt_color, border, width, dataCyId } = props

    const { theme } = useThemes()

    return (
        <Button
            $theme={theme}
            $width={width}
            $bg={bg}
            $border={border}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
        >
            <Text $txt_color={txt_color} $theme={theme}>
                {text}
            </Text>
        </Button>
    )
}

export default FullWidthBtn
