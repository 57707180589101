import { IDropdownItem } from 'entityModels'

import {
    ITimelineCategoryData,
    ITimelineCurrencyData as ICurrencyData,
    ITimelineEntryTypes,
    ITimelineItem,
} from 'timelineModels'
import { CurencyUIDsEnumObj } from './timelineEnum'
import { history_file_colours } from '../../providers/theme/colours'

export const timeline_category_ids: ITimelineEntryTypes[] = [
    'service',
    'maintenance',
    'restoration',
    'admin',
    'insurance',
    'history',
    'events',
    'other',
]

// export const timeline_currency_ids: string[] = [
//     CurencyUIDsEnumObj.gbp,
//     CurencyUIDsEnumObj.usd,
//     CurencyUIDsEnumObj.eur,
// ]

export const timeline_category_data: ITimelineCategoryData = {
    service: {
        id: 'service',
        name: 'service',
        description:
            'Invoices & records related to your car’s scheduled services.',
        description_v2:
            'Document routine maintenance like oil changes and brake inspections.',
        placeholders: {
            title: 'Example: Annual Service',
            short_description:
                'Example: Details of who carried out the service, the mileage, level of service and any other helpful details.',
            description:
                'Example: Details of who carried out the service, the mileage, level of service and any other helpful details.',
        },
        pre_populated_labels: [
            'engine',
            'transmission',
            'brakes',
            'tyres',
            'battery',
        ],
        color: history_file_colours.light.categories[`service`].primary_100,
    },
    maintenance: {
        id: 'maintenance',
        name: 'maintenance',
        description_v2:
            'Keep track of general upkeep tasks such as tire rotations and fluid checks.',
        description: 'Invoices & details of all ad hoc maintenance.',
        placeholders: {
            title: 'Example: Removing Stone Chips',
            short_description:
                'Example: Details of who carried out the work and any other helpful details.',
            description:
                'Example: Details of who carried out the work and any other helpful details.',
        },
        pre_populated_labels: [
            'engine',
            'transmission',
            'bodywork',
            'brakes',
            'running gear',
        ],
        color: history_file_colours.light.categories['maintenance'].primary_100,
    },
    restoration: {
        id: 'restoration',
        name: 'restoration',
        description:
            'Photos, documentation & invoices of any restoration work.',
        description_v2:
            'Record the progress of restoration projects, detailing each step taken to revive your vehicle.',
        placeholders: {
            title: 'Example: Bodywork Restoration',
            short_description:
                'Example: Details of who carried out the work, the extent of the restoration work and any other helpful details.',
            description:
                'Example: Details of who carried out the work, the extent of the restoration work and any other helpful details.',
        },
        pre_populated_labels: [
            'bodywork',
            'interior',
            'engine',
            'transmission',
            'chassis',
        ],
        color: history_file_colours.light.categories['restoration'].primary_100,
    },
    admin: {
        id: 'admin',
        name: 'admin',
        description:
            'MOT, registration, tax, logistics, storage & other admin records.',
        description_v2:
            'Log administrative details like registration renewals and title transfers.',
        placeholders: {
            title: 'Example: MOT',
            short_description:
                'Example: Name of company which carried out the MOT, the mileage and whether there were any advisories.',
            description:
                'Example: Name of company which carried out the MOT, the mileage and whether there were any advisories.',
        },
        pre_populated_labels: [
            'MOT',
            'tax',
            'logistics',
            'storage',
            'registration',
        ],
        color: history_file_colours.light.categories[`admin`].primary_100,
    },
    insurance: {
        id: 'insurance',
        name: 'insurance',
        description: 'Insurance policy, documents & related information.',
        description_v2:
            'Capture insurance policy information, including coverage details and renewal dates.',
        placeholders: {
            title: 'Example: Insurance Policy Renewal',
            short_description:
                'Example: Insurance company name, whether comprehensive or third party cover and any other helpful details.',
            description:
                'Example: Insurance company name, whether comprehensive or third party cover and any other helpful details.',
        },
        pre_populated_labels: [
            'Insurance Policy',
            'Insurance Quotes',
            'Insurance Renewal',
            'Insurance Claims',
            'Damage',
        ],
        color: history_file_colours.light.categories[`insurance`].primary_100,
    },
    history: {
        id: 'history',
        name: 'history',
        description:
            'Historical photos & documents showcasing your car’s story.',
        description_v2: `Chronicle significant moments in your car's past, from ownership changes to major repairs.`,
        placeholders: {
            title: 'Example: Original Build Sheet',
            short_description:
                'Example: Add any helpful details about the historical photo, story or document.',
            description:
                'Example: Add any helpful details about the historical photo, story or document.',
        },
        pre_populated_labels: [
            'Period Photos',
            'Correspondence',
            'Ownership History',
            'Race History',
            'Specifications',
        ],
        color: history_file_colours.light.categories[`history`].primary_100,
    },
    events: {
        id: 'events',
        name: 'events',
        description:
            'Highlights & memories of drives & events you’ve attended.',
        description_v2:
            'Document memorable occasions such as car shows, rallies, and road trips.',
        placeholders: {
            title: 'Example: Coffee & Cars Meetup',
            short_description:
                'Example: Location and any details of the event you’ve attended.',
            description:
                'Example: Location and any details of the event you’ve attended.',
        },
        pre_populated_labels: [
            'Road Trips',
            'Drive Outs',
            'Concours',
            'Race Meetings',
            'Rallies',
        ],
        color: history_file_colours.light.categories[`events`].primary_100,
    },
    other: {
        id: 'other',
        name: 'other',
        description_v2: `Note any unique or miscellaneous details about your vehicle that don't fit into other categories.`,
        description: 'Any other documents or information relating to your car.',
        placeholders: {
            title: 'Example: Instruction Manual for Radio',
            short_description:
                'Example: Any additional details or information about the entry.',
            description:
                'Example: Any additional details or information about the entry.',
        },
        pre_populated_labels: [
            'Instructions',
            'Certificates',
            'Specifications',
            'Contacts',
            'Art',
        ],
        color: history_file_colours.light.categories[`other`].primary_100,
    },
}

export const currency_data: ICurrencyData = {
    [CurencyUIDsEnumObj.gbp]: {
        uid: CurencyUIDsEnumObj.gbp,
        name: CurencyUIDsEnumObj.gbp,
        icon: '£',
    },
    [CurencyUIDsEnumObj.usd]: {
        uid: CurencyUIDsEnumObj.usd,
        name: CurencyUIDsEnumObj.usd,
        icon: '$',
    },
    [CurencyUIDsEnumObj.eur]: {
        uid: CurencyUIDsEnumObj.eur,
        name: CurencyUIDsEnumObj.eur,
        icon: '€',
    },
}

export const currency_IDS: string[] = [
    CurencyUIDsEnumObj.gbp,
    CurencyUIDsEnumObj.usd,
    CurencyUIDsEnumObj.eur,
]

export const timeline_category_searchable_items_list_mock: IDropdownItem[] = [
    {
        uid: 'service',
        name: 'service',
        type: 'category',
    },
    {
        uid: 'maintenance',
        name: 'maintenance',
        type: 'category',
    },

    {
        uid: 'restoration',
        name: 'restoration',
        type: 'category',
    },

    {
        uid: 'admin',
        name: 'admin',
        type: 'category',
    },
    {
        uid: 'insurance',
        name: 'insurance',
        type: 'category',
    },

    {
        uid: 'history',
        name: 'history',
        type: 'category',
    },
    {
        uid: 'events',
        name: 'events',
        type: 'category',
    },

    {
        uid: 'other',
        name: 'other',
        type: 'category',
    },
]

export const timeline_currency_searchable_items_list_mock: IDropdownItem[] = [
    {
        uid: CurencyUIDsEnumObj.gbp,
        name: CurencyUIDsEnumObj.gbp,
        type: 'currency',
    },
    {
        uid: CurencyUIDsEnumObj.usd,
        name: CurencyUIDsEnumObj.usd,
        type: 'currency',
    },
    {
        uid: CurencyUIDsEnumObj.eur,
        name: CurencyUIDsEnumObj.eur,
        type: 'currency',
    },
]

export const sampleDataJourney: ITimelineItem[] = [
    {
        costUIDS: ['cost-item-1'],
        id: 'timeline-item-01',
        date: '2021-11-26',
        title: 'Regular Service',
        categoryID: 'service',
        description:
            'Scheduled 10,000-mile service and regular checks of all perishables. Windscreen wiper blades and motor replaced with refurbished unit picked up on marketplace.',
        // "This is what entries to your Digital History File will look like. Click 'Add New Entry' to get started and add your own.",
        total_cost_amount_display_short: '155.00',
        total_cost_amount_display: '155.00',
        labels: [
            { uid: 'wiper_blades', name: 'Wiper Blades' },
            { uid: 'Servicing', name: 'Servicing' },
        ],
        notes: ``,
        attachmentIDs: ['car_pic_example_1_png', 'car_pic_example_2_png'],
        costItemsObj: {
            'cost-item-1': {
                id: 'cost-item-1',
                name: 'Cost 1',
                amount: 50,
                currency: 'GBP',
                company: 'BMW Park Lane',
            },
        },
        mileage: 13468,
    },
    {
        costUIDS: ['cost-item-2'],

        id: 'timeline-item-02',
        date: '2021-11-24',
        categoryID: 'restoration',
        title: 'Undersealing',
        total_cost_amount_display_short: '300.00',
        total_cost_amount_display: '300.00',
        description:
            // 'You can add costs & invoices to entries, see total amount spent, providers, etc.',
            // 'You can add costs and invoices to entries. The total amount spent, service provide used and number of attachments will show below.',
            'Preventative maintenance of undercarriage with thorough cleaning and undersealing. Thankfully no problems discovered during - down with salty roads!',
        attachmentIDs: [
            'car_pic_example_1_png',
            'car_pic_example_1_png',
            'car_pic_example_1_png',
        ],
        costItemsObj: {
            'cost-item-2': {
                id: 'cost-item-2',
                name: 'Cost 1',
                amount: 158.6,
                currency: 'GBP',
                company: 'Longstone Tyres',
            },
        },
        mileage: 12468,
    },
    {
        costUIDS: ['cost-item-1'],
        id: 'timeline-item-01',
        date: '2021-11-26',
        title: 'Sample timeline entry 3',
        categoryID: 'events',
        description:
            'This is what entries to your Digital History File will look like.',
        // "This is what entries to your Digital History File will look like. Click 'Add New Entry' to get started and add your own.",
        total_cost_amount_display_short: '50.00',
        total_cost_amount_display: '50.00',
        labels: [
            { uid: 'Event', name: 'Event' },
            { uid: 'track_day', name: 'Track Day' },
            { uid: 'Silverstone', name: 'Silverstone' },
        ],
        notes: ``,
        costItemsObj: {
            'cost-item-1': {
                id: 'cost-item-1',
                name: 'Cost 1',
                amount: 1000,
                currency: 'GBP',
                company: 'BMW Park Lane',
            },
        },
        mileage: 10568,
    },
]

export const sampleData: ITimelineItem[] = [
    {
        costUIDS: ['cost-item-1'],
        id: 'timeline-item-01',
        date: '2021-11-26',
        title: 'Sample timeline entry 1',
        categoryID: 'service',
        description:
            'This is what entries to your Digital History File will look like.',
        // "This is what entries to your Digital History File will look like. Click 'Add New Entry' to get started and add your own.",
        total_cost_amount_display_short: '50.00',
        total_cost_amount_display: '50.00',
        labels: [
            { uid: 'engine', name: 'engine' },
            { uid: 'gearbox', name: 'gearbox' },
        ],
        notes: ``,
        attachmentIDs: ['car_pic_example_1_png', 'car_pic_example_2_png'],
        costItemsObj: {
            'cost-item-1': {
                id: 'cost-item-1',
                name: 'Cost 1',
                amount: 50,
                currency: 'GBP',
                company: 'BMW Park Lane',
            },
        },
    },
    {
        costUIDS: ['cost-item-2'],

        id: 'timeline-item-02',
        date: '2021-11-24',
        categoryID: 'maintenance',
        title: 'Sample Timeline Entry 2',
        total_cost_amount_display_short: '158.60',
        total_cost_amount_display: '158.60',
        labels: [
            { uid: 'tyres', name: 'tyres' },
            { uid: 'wheels', name: 'wheels' },
        ],
        description:
            // 'You can add costs & invoices to entries, see total amount spent, providers, etc.',
            // 'You can add costs and invoices to entries. The total amount spent, service provide used and number of attachments will show below.',
            'Add costs & invoices to entries, see total amount spent and the service providers.',
        attachmentIDs: ['car_pic_example_1_png'],
        costItemsObj: {
            'cost-item-2': {
                id: 'cost-item-2',
                name: 'Cost 1',
                amount: 158.6,
                currency: 'GBP',
                company: 'Longstone Tyres',
            },
        },
    },
    {
        costUIDS: ['cost-item-1'],
        id: 'timeline-item-01',
        date: '2021-11-26',
        title: 'Silverstone Track Day',
        categoryID: 'events',
        description:
            'This is what entries to your Digital History File will look like.',
        // "This is what entries to your Digital History File will look like. Click 'Add New Entry' to get started and add your own.",
        total_cost_amount_display_short: '80.00',
        total_cost_amount_display: '80.00',
        labels: [
            { uid: 'engine', name: 'engine' },
            { uid: 'gearbox', name: 'gearbox' },
        ],
        notes: ``,
        attachmentIDs: ['car_pic_example_1_png', 'car_pic_example_2_png'],
        costItemsObj: {
            'cost-item-1': {
                id: 'cost-item-1',
                name: 'Cost 1',
                amount: 1000,
                currency: 'GBP',
                company: 'BMW Park Lane',
            },
        },
    },
]
