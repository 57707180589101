import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PaginationNumber } from '../../atoms/pagination/paginationNumber'
import Faded from '../animated/faded'
import chevron_to_left from '../../../public/assets/navigation/chevron_to_left.svg'
import { device } from '../displays/devices'

type Props = {
    onClickNext: any
    onClickPrev: any
    isLastPage: boolean
    results?: any[]
    currentPage: number
    onClickPageNumber: (n: number) => any
    totalCount: number
    itemsPerPage: number
}

const ContainerRow = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    border: solid 1px #e5e5e5;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 300px;
    gap: 16px;

    @media ${device.mobile_and_ipad} {
        width: 95vw;
    }
`

const Padding = styled.div`
    padding-bottom: 64px;
    padding-top: 30px;
    /* position: fixed;
    bottom: 100;
    z-index: 100; */
    color: #1a1a1a;
    font-size: 12px;
    font-family: Lato;

    @media ${device.mobile_and_ipad} {
        padding-top: 20px;
        padding-bottom: 40px;
    }
`
const PrevNextBox = styled.div`
    width: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);
    height: 10px;
`
const ChevronNext = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);

    transform: rotate(180deg);
    height: 10px;
`
const BottomPaginationNavigation = (props: Props) => {
    let {
        isLastPage,
        results,
        currentPage,
        onClickNext,
        onClickPrev,
        onClickPageNumber,
        totalCount,
        itemsPerPage,
    } = props

    let isPrevDisabled =
        currentPage !== 0 && results !== undefined ? false : true

    let isNextDisabled =
        !isLastPage && results && results.length > 0 ? false : true

    const [groupOfPageNumbers, setGroupOfPageNumbers] = useState<number[]>([])

    const generatePageNumbers = (): number[] => {
        let totalPages = Math.ceil(totalCount / itemsPerPage)

        const paginationRange = 3 // Number of page numbers to show in the middle
        const pagination = []

        let currentPageN = currentPage + 1

        if (currentPageN === 1) {
            // Special case for the first page
            for (let i = 1; i <= Math.min(paginationRange, totalPages); i++) {
                pagination.push(i)
            }
        } else if (currentPageN === totalPages) {
            // Special case for the last page
            for (
                let i = Math.max(totalPages - paginationRange + 1, 1);
                i <= totalPages;
                i++
            ) {
                pagination.push(i)
            }
        } else {
            // General case
            const start = Math.max(
                currentPageN - Math.floor(paginationRange / 2),
                1
            )
            const end = Math.min(start + paginationRange - 1, totalPages)
            for (let i = start; i <= end; i++) {
                pagination.push(i)
            }
        }

        return pagination
    }

    useEffect(() => {
        setGroupOfPageNumbers(generatePageNumbers())
    }, [currentPage, totalCount])

    let totalNumberOfPages = Math.ceil(totalCount / itemsPerPage)
    let lastPageNumberInGroup =
        groupOfPageNumbers[groupOfPageNumbers.length - 1]

    return (
        <Padding>
            <Faded>
                {results && results.length > 0 && (
                    <ContainerRow>
                        <PrevNextBox
                            onClick={() => {
                                if (!isPrevDisabled) {
                                    onClickPrev()
                                }
                            }}
                            style={{
                                cursor: isPrevDisabled
                                    ? 'not-allowed'
                                    : 'pointer',
                                opacity: isPrevDisabled ? 0.5 : 1,
                            }}
                        >
                            <Chevron src={chevron_to_left} />
                            <div style={{ paddingLeft: '10px' }} />
                            <div>Prev</div>
                        </PrevNextBox>

                        {lastPageNumberInGroup > groupOfPageNumbers.length && (
                            <>
                                <PaginationNumber
                                    currentPage={currentPage}
                                    pageNumber={1}
                                    onClickPageNumber={onClickPageNumber}
                                />
                                {lastPageNumberInGroup >
                                    groupOfPageNumbers.length + 1 && <>...</>}
                            </>
                        )}
                        {groupOfPageNumbers.map((pageNumber) => {
                            return (
                                <PaginationNumber
                                    currentPage={currentPage}
                                    pageNumber={pageNumber}
                                    onClickPageNumber={onClickPageNumber}
                                />
                            )
                        })}

                        {lastPageNumberInGroup < totalNumberOfPages && (
                            <>
                                {lastPageNumberInGroup + 1 <
                                    totalNumberOfPages && <>...</>}
                                <PaginationNumber
                                    currentPage={currentPage}
                                    pageNumber={totalNumberOfPages}
                                    onClickPageNumber={onClickPageNumber}
                                />
                            </>
                        )}

                        <PrevNextBox
                            onClick={() => {
                                if (!isNextDisabled) {
                                    onClickNext()
                                }
                            }}
                            style={{
                                cursor: isNextDisabled
                                    ? 'not-allowed'
                                    : 'pointer',
                                opacity: isNextDisabled ? 0.5 : 1,
                            }}
                        >
                            <div>Next</div>
                            <div style={{ paddingLeft: '10px' }} />
                            <ChevronNext src={chevron_to_left} />
                        </PrevNextBox>
                    </ContainerRow>
                )}
            </Faded>
        </Padding>
    )
}

export default BottomPaginationNavigation
