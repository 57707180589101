import { customHeader } from '../headers'

import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import { ICarInsightsResAPI } from '../../../components/organisms/carInsights/body'
import {
    IgetCarCategoryInsightsRequest,
    IgetCarInsightsRequest,
} from '../../entities/cars/actions/loadingActions'

export const getCarInsightsAPI = (
    p: IgetCarInsightsRequest
): Promise<ICarInsightsResAPI> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }
    let urll: string = urls.url_getCarInsights(
        p.carid,
        p.start_date,
        p.end_date
    )
    return fetch(urll, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Get car insights error')
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const getCarCategoryInsightsAPI = (
    p: IgetCarCategoryInsightsRequest
): Promise<IgetCarCategoryInsightsRequest> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    let urll: string = urls.url_getCarCategoryInsights(
        p.carid,
        p.start_date,
        p.end_date,
        p.categoryID,
        p.representation
    )

    return fetch(urll, requestOptions).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get car category insights error'
                    )
                    return Promise.reject(
                        response.status === 503 ? data : response
                    )
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
