import { IDropdownItem, IDropdownListState } from 'entityModels'
import { motion } from 'framer-motion'
import moment from 'moment'
import styled from 'styled-components'
import {
    IAnyObject,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { IInsuranceFieldType } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import CheckboxWithIconRowItem from '../../../atoms/formField/checkboxWithIconRowItem'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import SelectSearchBarCarInfoDesktop from '../../editForms/technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import InputFieldTooltip from '../../../atoms/customTooltips/inputFieldTooltip'
import InfoIcon from '../../../atoms/icons/infoIcon'
import InputField from '../../../atoms/Inputfield/inputField'
import DropdownAndTexOrNumber from '../../../molecules/editOrCreateModeSingleFields/dropdownAndTextOrNumber/dropdownAndTexOrNumber'
import CalendarIcon from '../../../atoms/icons/components/calendar'
import AnimatedTextareaShort from '../../../atoms/textarea/animatedTextareaShort'
import YesNoRadioInput from '../../../molecules/editOrCreateModeSingleFields/yesOrNoRadioInput/yesOrNoRadioInput'
import { device } from '../../../templates/displays/devices'
// import { containsNumbers } from '../../searchbar/insurance/mobileInsuranceOptionsSearchManager'
import {
    InsuranceFormQuestionWithTooltip,
    LocalErrorDiv,
} from './renderElementMobile'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import InsuranceSelect from '../../../atoms/select/insuranceSelect'
import React from 'react'
import { capitalizeFirstLetter } from '../../../../helpers/strings/capitalizeFirstLetter'
import MultipleSelectDropdown from '../../editForms/technicalInformationEditForm/multipleselect'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { CarParams } from '../../../pages/insurance_quote/dashboards/insuranceApplicationCarDashboard'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../providers/theme/colours'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../../providers/theme/hooks'
import {
    createLocalDateFromISODatePart,
    createUTCDateFromLocalPicker,
} from '../../../../helpers/time/utclocal'
import Expander from '../../../atoms/expander/expander'

type IStyle = {
    $theme: ITheme
    $isActive?: boolean
    $sectionId?: IInsuranceApplicationSectionId
}

export const InsuranceFormQuestion = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_darker};
    display: flex;
    font-family: Lato;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

export const YesNoBox = styled(motion.div)<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 18px 16px 16px;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    transition: all 200ms;

    :hover {
        background-color: ${(props) =>
            `${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_04
                    : colours[props.$theme].primary_04
            }`};
        border: ${(props) =>
            `1px solid ${
                props.$sectionId
                    ? insurance_colours[props.$theme].sections[props.$sectionId]
                          .primary_100
                    : colours[props.$theme].primary
            }`};
    }

    ${(props) => `
    background: ${
        props.$isActive
            ? props.$sectionId
                ? insurance_colours[props.$theme].sections[props.$sectionId]
                      .primary_04
                : colours[props.$theme].primary_04
            : 'transparent'
    };
    border: ${
        props.$isActive
            ? `1px solid ${
                  props.$sectionId
                      ? insurance_colours[props.$theme].sections[
                            props.$sectionId
                        ].primary_100
                      : colours[props.$theme].primary
              }`
            : `1px solid ${colours[props.$theme].text_muted}`
    };



    `}

    cursor: pointer;
`

export const FieldRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
`

const ImportantIconWrapper = styled.div<IStyle>`
    width: 24px;
    height: 24px;
    border-radius: 100px;
    color: ${(props) => colours[props.$theme].text_strong};
    display: flex;
    align-items: center;
    justify-content: center;
`

type Props = {
    item: IInsuranceFieldType
    onAnswerChange: (id: string, answer: any) => any
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    onBlur?: (id: string, answer: any) => any
    sectionID?: IInsuranceApplicationSectionId
    bg?: string
}

export const RenderInsuranceQuoteFormFieldDesktop = (props: Props) => {
    let {
        item,
        onAnswerChange,
        data,
        errors,
        setError,
        onBlur,
        sectionID,
        bg,
    } = props

    let makesDropdowndata: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let bodyTypeList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carBodyTypes
    })

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    let dropdownsState: IDropdownListState = useAppSelector((state) => {
        return state.localdata.dropdownData
    })

    let dispatch = useAppDispatch()

    const { carid } = useParams<CarParams>()

    const checkCountryName = (name: string) => {
        if (name.toUpperCase() === 'GB') {
            return 'United Kingdom'
        }

        if (name.toUpperCase() === 'FR') {
            return 'France'
        }

        if (name.toUpperCase() === 'ES') {
            return 'Spain'
        }

        if (name.toUpperCase() === 'IT') {
            return 'Italy'
        }

        if (name.toUpperCase() === 'US') {
            return 'United States'
        }

        if (name.toUpperCase() === 'DE') {
            return 'Germany'
        }

        return name
    }

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    let isDisabled = application?.status === 'DRAFT' ? false : true

    const { theme } = useThemes()

    const [localNumberError, setLocalNumberError] = useState(undefined)

    let hasCountriesOptions =
        item.id === 'registration_country' ||
        item.id === 'import_origin_country_code' ||
        item.id === 'current_country_of_residence_country_code' ||
        item.id === 'country'
            ? true
            : false

    const renderField = () => {
        switch (item.type) {
            case 'multiselect':
                return (
                    <>
                        <InsuranceFormQuestionWithTooltip
                            theme={theme}
                            item={item}
                            tooltipPlacement="right"
                        />
                        <div style={{ paddingTop: 16 }} />

                        <MultipleSelectDropdown
                            item={{
                                text: item.text,
                                id: item.id,
                                answers: data[item.id],
                            }}
                            isDisabled={isDisabled}
                            optionsList={item.options ?? []}
                            handleChange={(values: string[]) => {
                                onAnswerChange(`${item.id}`, values)
                            }}
                        />
                    </>
                )
            case 'searchable_options':
                let motoring_convictions_type = data['type'] ?? undefined
                let motoring_convictions_type_code =
                    motoring_convictions_type?.code ?? undefined
                let motoring_convictions_codes_grouped_dropdown_data =
                    motoring_convictions_type_code &&
                    dropdownsState?.motoring_conviction_codes_grouped &&
                    dropdownsState.motoring_conviction_codes_grouped[
                        motoring_convictions_type_code
                    ]
                        ? dropdownsState.motoring_conviction_codes_grouped[
                              motoring_convictions_type_code
                          ]
                        : undefined
                let show_motoring_convictions_codes_grouped =
                    item.id === 'code' &&
                    item.parent_entity_id === 'motoring_convictions' &&
                    motoring_convictions_type_code &&
                    motoring_convictions_codes_grouped_dropdown_data
                        ? true
                        : false

                let options:
                    | string
                    | boolean
                    | IDropdownItem[]
                    | null
                    | undefined = !item
                    ? []
                    : hasCountriesOptions
                    ? countriesList
                    : show_motoring_convictions_codes_grouped
                    ? motoring_convictions_codes_grouped_dropdown_data
                    : item.dropdown_id
                    ? dropdownsState[item.dropdown_id] ?? []
                    : item.id === 'make'
                    ? makesDropdowndata
                    : item.id === 'model'
                    ? modelsDropdowndata
                    : item.id === 'transmission'
                    ? transmissionsList
                    : item.id === 'body_type'
                    ? bodyTypeList
                    : item.options
                    ? item.options
                    : dropdownsState[item.id]
                    ? dropdownsState[item.id]
                    : []

                let valueDisplay =
                    (item.id === 'registration_country' ||
                        item.id === 'import_origin_country_code' ||
                        item.id ===
                            'current_country_of_residence_country_code') &&
                    typeof data[item.id] === 'string'
                        ? checkCountryName(data[item.id])
                        : (item.id === 'registration_country' ||
                              item.id === 'import_origin_country_code' ||
                              item.id ===
                                  'current_country_of_residence_country_code') &&
                          data[item.id]?.name
                        ? checkCountryName(data[item.id]?.name)
                        : data[item.id]?.name

                let showEmptyBox = item.id === 'memberships' ? true : true

                return (
                    <SelectSearchBarCarInfoDesktop
                        showEmptyBox={showEmptyBox}
                        isValueLabel={item.dropdown_id ? true : false}
                        sendDropdownItem={true}
                        placeholder={item.text}
                        handleSelectChange={(option: IDropdownItem) => {
                            if (option?.uid) {
                                let optioncopy = {
                                    ...option,
                                }

                                if (item.patch_field_id) {
                                    optioncopy = {
                                        ...optioncopy,
                                        patch_field_id: item.patch_field_id,
                                    }
                                }

                                if (item.patch_as_int) {
                                    optioncopy = {
                                        ...optioncopy,
                                        patch_as_int: item.patch_as_int,
                                    }
                                }

                                if (item.is_price !== undefined) {
                                    optioncopy = {
                                        ...optioncopy,
                                        is_price: item.is_price,
                                    }
                                }

                                onAnswerChange(item.id, optioncopy)
                                if (item.id === 'make') {
                                    dispatch(
                                        dropDownActions.setModelDropdownDataRequest(
                                            {
                                                makeName: option.name,
                                                make_uid: option.uid,
                                            }
                                        )
                                    )

                                    onAnswerChange('model', undefined)
                                }

                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }
                        }}
                        addAnItemFunc={(id: string, name: string) => {
                            if (item.id === 'make' || item.id === 'model') {
                                dispatch(
                                    dropDownActions.setIsLoadingNewMakeOrModel(
                                        true
                                    )
                                )
                                dispatch(
                                    dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
                                        {
                                            dropdownTargetId:
                                                item.id === 'make'
                                                    ? 'carMakes'
                                                    : 'carModels',
                                            name: name,
                                            relevantOtherLinkedDataMakeMake:
                                                data && data['make']?.name
                                                    ? data['make']?.name
                                                    : data && data['make']
                                                    ? data['make']
                                                    : undefined,
                                        }
                                    )
                                )
                            }
                            props.onAnswerChange(item.id, {
                                uid: 'new_entry',
                                name: name,
                            })
                            if (item.id === 'make' && carid) {
                                dispatch(
                                    insuranceActions.set_answer_insurance_car_techInfo(
                                        {
                                            carid: carid,
                                            id: 'model',
                                            answer: undefined,
                                        }
                                    )
                                )
                            }
                            let hasError: boolean | string = false
                            if (!hasError && errors && errors[item.id]) {
                                setError(item.id, undefined)
                            }
                            if (hasError) {
                                if (item.error_txt) {
                                    setError(item.id, item.error_txt)
                                } else if (typeof hasError === 'string') {
                                    setError(item.id, hasError)
                                } else {
                                    setError(item.id, 'Invalid')
                                }
                            }
                        }}
                        item={{
                            answer: valueDisplay,
                            id: item.id,
                            text: item.text,
                        }}
                        valueUpdatedFromExternalSource={
                            data && data[item.id] && data[item.id].name
                                ? data[item.id].name
                                : null
                        }
                        items={options ?? []}
                        formCurrentValue={
                            data && data[item.id] && data[item.id].name
                                ? data[item.id].name
                                : undefined
                        }
                        dropdownTargetId={
                            item.id === 'make'
                                ? 'carMakes'
                                : item.id === 'model'
                                ? 'carModels'
                                : undefined
                        }
                        allowAdd={
                            item.id === 'make'
                                ? true
                                : item.id === 'model'
                                ? true
                                : false
                        }
                        // onlyShowMatch={true}
                        backgroundColor={
                            bg ?? colours[theme].background_neutral_subtle
                        }
                        isDisabled={
                            isDisabled ||
                            (item.id === 'model' &&
                                (!data || (data && !data['make'])))
                                ? true
                                : false
                        }
                        customMaxWidth="none"
                        textTransform={item.isCaps ? 'uppercase' : undefined}
                        customActiveColour={
                            sectionID
                                ? insurance_colours[theme].sections[sectionID]
                                      .primary_100
                                : undefined
                        }
                        customHighlightColour={
                            sectionID
                                ? insurance_colours[theme].sections[sectionID]
                                      .primary_08
                                : undefined
                        }
                    />
                )
            case 'simple_txt':
                return (
                    <>
                        {item.desktop_title_on_top ? (
                            <div style={{ paddingBottom: '16px' }}>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    tooltipPlacement="right"
                                    item={item}
                                />
                            </div>
                        ) : null}
                        <InputFieldNoFormikAnimated
                            name={item.desktop_title_on_top ? '' : item.text}
                            value={
                                data[item.id]
                                    ? `${data[item.id]}`
                                    : item.desktop_title_on_top
                                    ? ''
                                    : undefined
                            }
                            disabled={isDisabled}
                            onChange={(e: any) => {
                                onAnswerChange(item.id, e.target.value)

                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            placeholder={
                                item.desktop_title_on_top &&
                                item.placeholderText
                                    ? item.placeholderText
                                    : ''
                            }
                            width={'100%'}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            linecolor={
                                errors && errors[item.id]
                                    ? colours[theme].error
                                    : undefined
                            }
                            rightAdornment={
                                item.desktop_title_on_top ? undefined : item.contains_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={item.contains_tooltip_text}
                                        color={colours[theme].text_muted}
                                    >
                                        <div
                                            style={{
                                                transform: 'translateY(2px)',
                                            }}
                                        >
                                            <InfoIcon size="14" />
                                        </div>
                                    </InputFieldTooltip>
                                ) : item.contains_important_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={
                                            item.contains_important_tooltip_text
                                        }
                                        color={
                                            colours[theme]
                                                .background_neutral_active
                                        }
                                    >
                                        <ImportantIconWrapper $theme={theme}>
                                            <InfoIcon
                                                size="14"
                                                color={
                                                    colours[theme]
                                                        .background_neutral_active
                                                }
                                            />
                                        </ImportantIconWrapper>
                                    </InputFieldTooltip>
                                ) : undefined
                            }
                            textTransform={
                                item.isCaps ? 'uppercase' : undefined
                            }
                            placeholderStyle={
                                item.isCaps
                                    ? `text-transform: none !important`
                                    : undefined
                            }
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                        />
                    </>
                )

            case 'short_txt_area':
                return (
                    <>
                        <AnimatedTextareaShort
                            name={item.text}
                            value={data[item.id] ? `${data[item.id]}` : ''}
                            handleChange={(e: any) => {
                                onAnswerChange(item.id, e.target.value)

                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            disabled={isDisabled}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            rightAdornment={
                                item.contains_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={item.contains_tooltip_text}
                                        color={colours[theme].text_muted}
                                    >
                                        <div
                                            style={{
                                                transform: 'translateY(2px)',
                                            }}
                                        >
                                            <InfoIcon size="14" />
                                        </div>
                                    </InputFieldTooltip>
                                ) : item.contains_important_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={
                                            item.contains_important_tooltip_text
                                        }
                                        color={
                                            colours[theme]
                                                .background_neutral_active
                                        }
                                    >
                                        <ImportantIconWrapper $theme={theme}>
                                            <InfoIcon
                                                size="14"
                                                color={
                                                    colours[theme]
                                                        .background_neutral_active
                                                }
                                            />
                                        </ImportantIconWrapper>
                                    </InputFieldTooltip>
                                ) : undefined
                            }
                        />
                    </>
                )

            case 'date':
                let valueDate =
                    data &&
                    data[item.id] &&
                    createLocalDateFromISODatePart(data[item.id])
                        ? `${createLocalDateFromISODatePart(data[item.id])}`
                        : undefined

                return (
                    <InputField
                        theme={theme}
                        disabled={isDisabled}
                        id="date_picked_btns_form"
                        name="date_picked_btns_form"
                        custom_date_format={item?.custom_date_format}
                        placeholder={item.text}
                        customPlaceholderWhenLabelRaised={item.placeholderText}
                        // helperText="Type or select a date (yyyy/mm/dd)"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={valueDate}
                        font_family="Lato"
                        restrictFlipOfDatePopUp
                        onChange={(dateString: any) => {
                            if (
                                item.custom_date_format === 'MM/yyyy' &&
                                dateString
                            ) {
                                let lastDayOfMonth =
                                    moment(dateString).endOf('month')

                                let isoLastDayOfMonth = lastDayOfMonth
                                    ?.toISOString()
                                    ?.split('T')[0]

                                onAnswerChange(
                                    item.id,
                                    `${isoLastDayOfMonth}T00:00:00.000Z`
                                )
                            } else {
                                // let isoDateString = new Date(
                                //     dateString
                                // )?.toISOString()

                                const utcDate =
                                    createUTCDateFromLocalPicker(dateString)
                                const isoString = utcDate.toISOString()

                                onAnswerChange(item.id, isoString)
                            }

                            let hasError: boolean | string =
                                item.min !== undefined && item.max !== undefined
                                    ? !moment(dateString).isBetween(
                                          moment(new Date(item.min)),
                                          moment(new Date(item.max))
                                      )
                                    : item.min !== undefined && !item.max
                                    ? moment(dateString).isBefore(
                                          moment(new Date(item.min))
                                      )
                                        ? true
                                        : false
                                    : item.max !== undefined && !item.min
                                    ? moment(dateString).isAfter(
                                          moment(new Date(item.max))
                                      )
                                        ? true
                                        : false
                                    : false

                            if (!hasError && errors && errors[item.id]) {
                                setError(item.id, undefined)
                            }
                            if (hasError) {
                                if (item.error_txt) {
                                    setError(item.id, item.error_txt)
                                } else {
                                    setError(item.id, hasError)
                                }
                            }
                        }}
                        type="date"
                        width="100%"
                        fontSize="16px"
                        paddingLeft="0px"
                        backgroundColor={
                            bg ?? colours[theme].background_neutral_subtle
                        }
                        // dataCyId={props.dataCyId}
                        customActiveColour={
                            sectionID
                                ? insurance_colours[theme].sections[sectionID]
                                      .primary_100
                                : undefined
                        }
                        rightAdornment={
                            item.contains_tooltip_text ? (
                                <FieldRow
                                    style={{ gap: 10, alignItems: 'center' }}
                                >
                                    <CalendarIcon />
                                    <InputFieldTooltip
                                        tooltipText={item.contains_tooltip_text}
                                        color={colours[theme].text_muted}
                                    >
                                        <div
                                            style={{
                                                transform: 'translateY(2px)',
                                            }}
                                        >
                                            <InfoIcon size="14" />
                                        </div>
                                    </InputFieldTooltip>
                                </FieldRow>
                            ) : item.contains_important_tooltip_text ? (
                                <FieldRow
                                    style={{ gap: 10, alignItems: 'center' }}
                                >
                                    <CalendarIcon />
                                    <InputFieldTooltip
                                        tooltipText={
                                            item.contains_important_tooltip_text
                                        }
                                        color={
                                            colours[theme]
                                                .background_neutral_active
                                        }
                                    >
                                        <ImportantIconWrapper $theme={theme}>
                                            <InfoIcon
                                                size="14"
                                                color={
                                                    colours[theme]
                                                        .background_neutral_active
                                                }
                                            />
                                        </ImportantIconWrapper>
                                    </InputFieldTooltip>
                                </FieldRow>
                            ) : (
                                <CalendarIcon />
                            )
                        }
                    />
                )

            case 'dates_range':
                return (
                    <FieldRow>
                        <InputField
                            theme={theme}
                            disabled={isDisabled}
                            id="date_picked_btns_form"
                            name="date_picked_btns_form"
                            placeholder={`${item.text} start`}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            restrictFlipOfDatePopUp
                            value={
                                data && data[`${item.id}_start_date`]
                                    ? `${data[`${item.id}_start_date`]}`
                                    : undefined
                            }
                            font_family="Lato"
                            onChange={(dateString: any) => {
                                let isoDateString = new Date(
                                    dateString
                                )?.toISOString()

                                onAnswerChange(
                                    `${item.id}_start_date`,
                                    isoDateString
                                )

                                let hasError: boolean | string =
                                    item.min !== undefined &&
                                    item.max !== undefined
                                        ? !moment(isoDateString).isBetween(
                                              moment(new Date(item.min)),
                                              moment(new Date(item.max))
                                          )
                                        : item.min !== undefined
                                        ? !moment(isoDateString).isBefore(
                                              moment(new Date(item.min))
                                          )
                                        : item.max !== undefined
                                        ? !moment(isoDateString).isAfter(
                                              moment(new Date(item.max))
                                          )
                                        : false

                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else {
                                        setError(item.id, hasError)
                                    }
                                }
                            }}
                            type="date"
                            width="100%"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            rightAdornment={<CalendarIcon />}
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }

                            // dataCyId={props.dataCyId}
                        />
                        <InputField
                            theme={theme}
                            disabled={isDisabled}
                            id="date_picked_btns_form"
                            name="date_picked_btns_form"
                            placeholder={`${item.text} end`}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            restrictFlipOfDatePopUp
                            value={
                                data && data[`${item.id}_end_date`]
                                    ? `${data[`${item.id}_end_date`]}`
                                    : undefined
                            }
                            font_family="Lato"
                            onChange={(dateString: any) => {
                                let isoDateString = new Date(
                                    dateString
                                )?.toISOString()

                                onAnswerChange(
                                    `${item.id}_end_date`,
                                    isoDateString
                                )

                                let hasError: boolean | string =
                                    item.min !== undefined &&
                                    item.max !== undefined
                                        ? !moment(isoDateString).isBetween(
                                              moment(new Date(item.min)),
                                              moment(new Date(item.max))
                                          )
                                        : item.min !== undefined
                                        ? !moment(isoDateString).isBefore(
                                              moment(new Date(item.min))
                                          )
                                        : item.max !== undefined
                                        ? !moment(isoDateString).isAfter(
                                              moment(new Date(item.max))
                                          )
                                        : false

                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else {
                                        setError(item.id, hasError)
                                    }
                                }
                            }}
                            type="date"
                            width="100%"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                        />
                    </FieldRow>
                )

            case 'number':
                return (
                    <>
                        {item.isTextLong && (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                    tooltipPlacement="right"
                                />
                                <div style={{ paddingTop: '16px' }} />
                            </>
                        )}

                        <InputFieldNoFormikAnimated
                            disabled={isDisabled}
                            name={item.text}
                            value={
                                data && data[item.id] !== undefined
                                    ? `${data[item.id]}`
                                    : undefined
                            }
                            onChange={async (e: any) => {
                                onAnswerChange(item.id, +e.target.value)
                                if (
                                    item.is_price &&
                                    item.localOnChangeValidation
                                ) {
                                    item.localOnChangeValidation(
                                        +e.target.value
                                    )
                                        .validate(+e.target.value)
                                        .then(() => {
                                            setLocalNumberError(undefined)
                                        })
                                        .catch((err: any) => {
                                            err?.errors[0] &&
                                                setLocalNumberError(
                                                    err?.errors[0]
                                                )
                                        })
                                }
                                let hasError: boolean | string =
                                    item.min !== undefined &&
                                    e.target.value < item.min
                                        ? `Minimum value is ${item.min}`
                                        : item.max && e.target.value > item.max
                                        ? `Minimum value is ${item.max}`
                                        : false

                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else {
                                        setError(item.id, hasError)
                                    }
                                }
                            }}
                            onBlur={() => {
                                onBlur &&
                                    onBlur(
                                        item.id,
                                        data && data[item.id]
                                            ? data[item.id]
                                            : 0
                                    )
                            }}
                            removeLabel={item.isTextLong ? true : false}
                            placeholder={item.isTextLong ? 'Enter value' : ''}
                            width={'100%'}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            type="number"
                            valueStart={item.value_front_field ?? undefined}
                            rightAdornment={
                                item.isTextLong ? undefined : item.contains_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={item.contains_tooltip_text}
                                        color={colours[theme].text_muted}
                                    >
                                        <div
                                            style={{
                                                transform: 'translateY(2px)',
                                            }}
                                        >
                                            <InfoIcon size="14" />
                                        </div>
                                    </InputFieldTooltip>
                                ) : item.contains_important_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={
                                            item.contains_important_tooltip_text
                                        }
                                        color={
                                            colours[theme]
                                                .background_neutral_active
                                        }
                                    >
                                        <ImportantIconWrapper $theme={theme}>
                                            <InfoIcon
                                                size="14"
                                                color={
                                                    colours[theme]
                                                        .background_neutral_active
                                                }
                                            />
                                        </ImportantIconWrapper>
                                    </InputFieldTooltip>
                                ) : undefined
                            }
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                        />
                        <Expander height={localNumberError ? 'auto' : 0}>
                            <LocalErrorDiv>{localNumberError}</LocalErrorDiv>
                        </Expander>
                    </>
                )

            case 'phone': {
                return (
                    <InputFieldNoFormikAnimated
                        disabled={isDisabled}
                        name={item.text}
                        value={
                            data &&
                            data[item.id] &&
                            `${data[item.id]}`.includes('+44')
                                ? `${data[item.id]}`.replace('+44', '')
                                : undefined
                        }
                        onChange={(e: any) => {
                            onAnswerChange(item.id, `+44${e.target.value}`)
                            let hasError: undefined | string =
                                data &&
                                data[item.id] &&
                                isValidPhoneNumber(`+44${e.target.value}`, 'GB')
                                    ? undefined
                                    : 'Invalid phone number'

                            if (
                                hasError === undefined &&
                                errors &&
                                errors[item.id]
                            ) {
                                setError(item.id, undefined)
                            }
                            if (hasError !== undefined) {
                                if (item.error_txt) {
                                    setError(item.id, item.error_txt)
                                } else {
                                    setError(item.id, hasError)
                                }
                            }
                        }}
                        placeholder=""
                        width={'100%'}
                        backgroundColor={
                            bg ?? colours[theme].background_neutral_subtle
                        }
                        type="number"
                        valueStart={item.value_front_field ?? undefined}
                        rightAdornment={
                            item.contains_tooltip_text ? (
                                <InputFieldTooltip
                                    tooltipText={item.contains_tooltip_text}
                                    color={colours[theme].text_muted}
                                >
                                    <div
                                        style={{
                                            transform: 'translateY(2px)',
                                        }}
                                    >
                                        <InfoIcon size="14" />
                                    </div>
                                </InputFieldTooltip>
                            ) : item.contains_important_tooltip_text ? (
                                <InputFieldTooltip
                                    tooltipText={
                                        item.contains_important_tooltip_text
                                    }
                                    color={
                                        colours[theme].background_neutral_active
                                    }
                                >
                                    <ImportantIconWrapper $theme={theme}>
                                        <InfoIcon
                                            size="14"
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                        />
                                    </ImportantIconWrapper>
                                </InputFieldTooltip>
                            ) : undefined
                        }
                        customActiveColour={
                            sectionID
                                ? insurance_colours[theme].sections[sectionID]
                                      .primary_100
                                : undefined
                        }
                    />
                )
            }

            case 'email':
                return (
                    <InputFieldNoFormikAnimated
                        disabled={isDisabled}
                        name={item.text}
                        value={
                            data && data[item.id]
                                ? `${data[item.id]}`
                                : undefined
                        }
                        onChange={(e: any) => {
                            onAnswerChange(item.id, e.target.value)
                            let hasError: boolean | string =
                                item.min !== undefined &&
                                e.target.value < item.min
                                    ? `Minimum value is ${item.min}`
                                    : item.max && e.target.value > item.max
                                    ? `Minimum value is ${item.max}`
                                    : false

                            if (!hasError && errors && errors[item.id]) {
                                setError(item.id, undefined)
                            }
                            if (hasError) {
                                if (item.error_txt) {
                                    setError(item.id, item.error_txt)
                                } else {
                                    setError(item.id, hasError)
                                }
                            }
                        }}
                        placeholder=""
                        width={'100%'}
                        backgroundColor={
                            bg ?? colours[theme].background_neutral_subtle
                        }
                        type="email"
                        valueStart={item.value_front_field ?? undefined}
                        rightAdornment={
                            item.contains_tooltip_text ? (
                                <InputFieldTooltip
                                    tooltipText={item.contains_tooltip_text}
                                    color={colours[theme].text_muted}
                                >
                                    <div
                                        style={{
                                            transform: 'translateY(2px)',
                                        }}
                                    >
                                        <InfoIcon size="14" />
                                    </div>
                                </InputFieldTooltip>
                            ) : item.contains_important_tooltip_text ? (
                                <InputFieldTooltip
                                    tooltipText={
                                        item.contains_important_tooltip_text
                                    }
                                    color={
                                        colours[theme].background_neutral_active
                                    }
                                >
                                    <ImportantIconWrapper $theme={theme}>
                                        <InfoIcon
                                            size="14"
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                        />
                                    </ImportantIconWrapper>
                                </InputFieldTooltip>
                            ) : undefined
                        }
                        customActiveColour={
                            sectionID
                                ? insurance_colours[theme].sections[sectionID]
                                      .primary_100
                                : undefined
                        }
                    />
                )

            case 'yes_no':
                return (
                    <div style={{ width: '100%' }}>
                        <InsuranceFormQuestionWithTooltip
                            theme={theme}
                            item={item}
                            tooltipPlacement="right"
                        />
                        <div style={{ paddingTop: '16px' }} />
                        <YesNoRadioInput
                            isDisabled={isDisabled}
                            value={data[item.id]}
                            isYesActive={data[item.id] === true ? true : false}
                            isNoActive={
                                data[item.id] === false &&
                                data[item.id] !== undefined
                                    ? true
                                    : false
                            }
                            onChange={(isChecked: boolean) => {
                                onAnswerChange(item.id, isChecked)

                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            customPrimaryColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            customPrimaryColour_04={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_04
                                    : undefined
                            }
                        />
                    </div>
                )

            case 'dropdown':
                let all_options: any = !item
                    ? []
                    : hasCountriesOptions
                    ? countriesList
                    : item.dropdown_id
                    ? dropdownsState[item.dropdown_id]
                        ? dropdownsState[item.dropdown_id]
                        : []
                    : item.id === 'make'
                    ? makesDropdowndata
                    : item.id === 'model'
                    ? modelsDropdowndata
                    : item.id === 'transmission'
                    ? transmissionsList
                    : item.id === 'body_type'
                    ? bodyTypeList
                    : dropdownsState[item.id]
                    ? dropdownsState[item.id]
                    : item.options
                    ? item.options
                    : []

                if (
                    all_options &&
                    all_options[0] &&
                    all_options[0].value &&
                    all_options[0].label
                ) {
                    let newArr = all_options.map((item2: any) => {
                        return { uid: item2.value, name: item2.label }
                    })
                    all_options = [...newArr]
                }
                let overrideValue =
                    data && data[item.id] === true
                        ? { uid: 'Yes', name: 'Yes' }
                        : data[item.id] === false
                        ? { uid: 'No', name: 'No' }
                        : data[item.id]

                return (
                    <div>
                        {item.isTextLong && (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                    tooltipPlacement="right"
                                />
                                <div style={{ paddingTop: '16px' }} />
                            </>
                        )}

                        <InsuranceSelect
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            customOptionHighlightColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_08
                                    : undefined
                            }
                            disabled={isDisabled}
                            options={all_options}
                            defaultValue={overrideValue}
                            placeholder={
                                item.isTextLong ? 'Please select...' : item.text
                            }
                            helperText={''}
                            onChange={(option: IDropdownItem) => {
                                let optioncopy: any = { ...option }

                                if (item.patch_field_id) {
                                    optioncopy = {
                                        ...optioncopy,
                                        patch_field_id: item.patch_field_id,
                                    }
                                }

                                if (item.patch_as_int) {
                                    optioncopy = {
                                        ...optioncopy,
                                        patch_as_int: item.patch_as_int,
                                    }
                                }

                                if (item.is_price !== undefined) {
                                    optioncopy = {
                                        ...optioncopy,
                                        is_price: item.is_price,
                                    }
                                }
                                onAnswerChange(item.id, optioncopy)

                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            hasInfoWithTooltip={
                                item.isTextLong
                                    ? false
                                    : item.contains_tooltip_text ||
                                      item.contains_important_tooltip_text
                                    ? true
                                    : false
                            }
                            tooltipText={
                                item.contains_important_tooltip_text ??
                                item.contains_tooltip_text
                            }
                            tooltipColor={
                                item.contains_tooltip_text
                                    ? colours[theme].text_muted
                                    : item.contains_important_tooltip_text
                                    ? colours[theme].background_neutral_active
                                    : undefined
                            }
                            screen_name="desktop"
                        />
                    </div>
                )

            case 'text_with_dropdown': {
                let allOptions: any = item.dropdown_id
                    ? Array.isArray(dropdownsState[item.dropdown_id])
                        ? dropdownsState[item.dropdown_id]
                        : []
                    : item.options
                    ? item.options
                    : []

                if (
                    allOptions &&
                    allOptions[0] &&
                    allOptions[0].value &&
                    allOptions[0].label
                ) {
                    let newArr = allOptions.map((item2: any) => {
                        return { uid: item2.value, name: item2.label }
                    })
                    allOptions = [...newArr]
                }

                return (
                    <DropdownAndTexOrNumber
                        isDisabled={isDisabled}
                        input_placeholder={item.text}
                        options={allOptions}
                        input_id={item.id}
                        dropdown_placeholder={
                            item.contains_front_dropdown
                                ? capitalizeFirstLetter(
                                      item.contains_front_dropdown
                                  )
                                : undefined
                        }
                        dropdown_id={`${item.id}-${item.contains_front_dropdown}`}
                        dropdown_value={
                            data[`${item.id}-${item.contains_front_dropdown}`]
                                ?.name
                        }
                        input_value_string={data[item.id] ? data[item.id] : ''}
                        onChange={(id: string, value: string | number) => {
                            onAnswerChange(id, value)
                            let hasError: boolean | string = false
                            if (!hasError && errors && errors[item.id]) {
                                setError(item.id, undefined)
                            }
                            if (hasError) {
                                if (item.error_txt) {
                                    setError(item.id, item.error_txt)
                                } else if (typeof hasError === 'string') {
                                    setError(item.id, hasError)
                                } else {
                                    setError(item.id, 'Invalid')
                                }
                            }
                        }}
                        customActiveColour={
                            sectionID
                                ? insurance_colours[theme].sections[sectionID]
                                      .primary_100
                                : undefined
                        }
                        customOptionHighlightColour={
                            sectionID
                                ? insurance_colours[theme].sections[sectionID]
                                      .primary_08
                                : undefined
                        }
                        rightAdornment={
                            item.contains_tooltip_text ? (
                                <InputFieldTooltip
                                    tooltipText={item.contains_tooltip_text}
                                    color={colours[theme].text_muted}
                                >
                                    <div
                                        style={{
                                            transform: 'translateY(2px)',
                                        }}
                                    >
                                        <InfoIcon size="14" />
                                    </div>
                                </InputFieldTooltip>
                            ) : item.contains_important_tooltip_text ? (
                                <InputFieldTooltip
                                    tooltipText={
                                        item.contains_important_tooltip_text
                                    }
                                    color={
                                        colours[theme].background_neutral_active
                                    }
                                >
                                    <ImportantIconWrapper $theme={theme}>
                                        <InfoIcon
                                            size="14"
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                        />
                                    </ImportantIconWrapper>
                                </InputFieldTooltip>
                            ) : undefined
                        }
                    />
                )
            }

            case 'number_with_dropdown': {
                if (item.options) {
                    return (
                        <DropdownAndTexOrNumber
                            isDisabled={isDisabled}
                            input_placeholder={item.text}
                            options={item.options}
                            input_id={item.id}
                            dropdown_placeholder={item.contains_front_dropdown}
                            dropdown_id={`${item.id}-${item.contains_front_dropdown}`}
                            dropdown_value={
                                data[
                                    `${item.id}-${item.contains_front_dropdown}`
                                ] ?? item.default_dropdown_value
                                    ? `${item.default_dropdown_value?.name}`
                                    : undefined
                            }
                            input_value_number={
                                data[item.id] !== undefined ? data[item.id] : ''
                            }
                            onChange={(id: string, value: string | number) => {
                                onAnswerChange(
                                    id,
                                    item.patch_as_int &&
                                        id !==
                                            `${item.id}-${item.contains_front_dropdown}`
                                        ? +value
                                        : value
                                )
                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            customOptionHighlightColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_08
                                    : undefined
                            }
                            rightAdornment={
                                item.contains_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={item.contains_tooltip_text}
                                        color={colours[theme].text_muted}
                                    >
                                        <div
                                            style={{
                                                transform: 'translateY(2px)',
                                            }}
                                        >
                                            <InfoIcon size="14" />
                                        </div>
                                    </InputFieldTooltip>
                                ) : item.contains_important_tooltip_text ? (
                                    <InputFieldTooltip
                                        tooltipText={
                                            item.contains_important_tooltip_text
                                        }
                                        color={
                                            colours[theme]
                                                .background_neutral_active
                                        }
                                    >
                                        <ImportantIconWrapper $theme={theme}>
                                            <InfoIcon
                                                size="14"
                                                color={
                                                    colours[theme]
                                                        .background_neutral_active
                                                }
                                            />
                                        </ImportantIconWrapper>
                                    </InputFieldTooltip>
                                ) : undefined
                            }
                        />
                    )
                } else return null
            }

            case 'contact_medium_choice': {
                let selected = data && data[item.id] ? [...data[item.id]] : []
                const handleSelect = (id: string) => {
                    if (selected && selected.includes(id)) {
                        selected = selected.filter(
                            (selectedId) => selectedId !== id
                        )
                    } else {
                        selected = selected ? [...selected, id] : [id]
                    }
                    onAnswerChange(item.id, selected)
                }
                return (
                    <div>
                        <InsuranceFormQuestion $theme={theme}>
                            {item.text}
                        </InsuranceFormQuestion>

                        <div style={{ paddingTop: '16px' }} />
                        {item.options?.map((option: IDropdownItem, i) => {
                            return (
                                <React.Fragment
                                    key={`${option.uid}_${i}_desktop`}
                                >
                                    <CheckboxWithIconRowItem
                                        isDisabled={isDisabled}
                                        name={option.name}
                                        text={option.name}
                                        onChange={() => {
                                            if (!isDisabled) {
                                                handleSelect(option.uid)

                                                let hasError: boolean | string =
                                                    false
                                                if (
                                                    !hasError &&
                                                    errors &&
                                                    errors[item.id]
                                                ) {
                                                    setError(item.id, undefined)
                                                }
                                                if (hasError) {
                                                    if (item.error_txt) {
                                                        setError(
                                                            item.id,
                                                            item.error_txt
                                                        )
                                                    } else if (
                                                        typeof hasError ===
                                                        'string'
                                                    ) {
                                                        setError(
                                                            item.id,
                                                            hasError
                                                        )
                                                    } else {
                                                        setError(
                                                            item.id,
                                                            'Invalid'
                                                        )
                                                    }
                                                }
                                            }
                                        }}
                                        iconID={option.icon}
                                        borderColor={colours[theme].text_muted}
                                        checked={
                                            data &&
                                            data[item.id]?.includes(option.uid)
                                                ? true
                                                : false
                                        }
                                        customActiveColour={
                                            sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_100
                                                : undefined
                                        }
                                        customHighlightColour={
                                            sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_08
                                                : undefined
                                        }
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                )
            }
        }
    }

    let params = new URLSearchParams(location.search)
    let anchor_params = params.get('anchor')
    let anchoredRefDesktop: any = useRef()

    const history = useHistory()

    const removeAnchor = () => {
        // in future, can take any FilterID

        if (anchor_params) {
            let currURL = new URL(window.location.href)
            const params2 = new URLSearchParams(currURL.search)
            params2.delete('anchor')
            history.replace({ search: params2.toString() })
        }
    }

    useEffect(() => {
        if (anchor_params && anchor_params === `${item.id}_desktop`) {
            scrollIntoView(anchoredRefDesktop?.current, {
                behavior: 'smooth',
                block: 'center',
            })
            setTimeout(() => {
                removeAnchor()
            }, 300)
        }
    }, [anchor_params])

    // hideField variable is needed for handling deprecated fields on editable vs past submitted forms ie. =>
    // don't allow deprecated fields to be filed in on draft applications
    // show fields on submitted applications only if they have filled in data
    // hide later added fields so they won't show as empty for older applications

    // check if should show the license number special field or not

    let hideField =
        (!isDisabled && item.isDeprecated) || // hide deprecated fields for editable forms (draft applications)
        (isDisabled && data[item.id] === undefined) // hide fields with no data for readonly forms (submitted applications)

    return (
        <div
            ref={anchoredRefDesktop}
            id={`${item.id}_desktop`}
            style={{ width: '100%' }}
        >
            {hideField ? null : renderField()}
        </div>
    )
}
