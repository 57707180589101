import { useHistory } from 'react-router'
import styled from 'styled-components'
import { journeyRedirectCardsCopy } from '../../../../redux/localdata/journey/copyData'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import { device } from '../../../templates/displays/devices'
import {
    JourneySectionWrapperCustom,
    JourneyColumnContent,
} from '../../../templates/styledcomponents/journeyStyles'
import JourneyPatternCardNextUpDesktop from '../../cards/journey/patternCards/patternCardNextUpDesktop'
import { IJourneySectionID } from 'journeyInterfaces'

const Txt = styled.div`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    max-width: 450px;

    @media ${device.large_desktop} {
        max-width: 600px;
    }
`
const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 32px;
    font-style: normal;
    line-height: 48px; /* 150% */
    max-width: 450px;
`

const Wrap = styled.div`
    display: flex;
    width: 100%;
    padding: 32px;
    align-items: flex-start;
    gap: 10px;
    background: var(--text-strong, #1a1a1a);
    border-radius: 16px;
`

const Container = styled.div`
    width: 90vw;
    position: relative;
`

const WrapTxt = styled.div`
    color: var(--bg-color, #fff);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    max-width: 450px;

    @media (min-width: 1200px) {
        max-width: 500px;
    }
`

const ImageStyle = styled.img`
    position: absolute;
    top: 140px;
    right: 5vw;
    width: 40vw;
    min-width: 664px;
    max-height: 60vh;
    object-fit: contain;

    @media (min-width: 1056px) and (max-width: 1250px) {
        top: 140px;
        right: 0px;
        width: 45vw;
    }

    @media (max-width: 1056px) {
        top: 200px;
        right: 0px;
        width: 42vw;
    }

    @media ${device.mobile} {
    }
`

export const JourneyAchievedStepDesktop = ({
    darkTextCopy,
    lightTextCopy,
    image_src_desktop,
    sectionID,
    nextSectionID,
    nextSectionPageName,
}: {
    darkTextCopy: string
    lightTextCopy: string
    image_src_desktop: string
    image_src_mobile: string
    sectionID: IJourneySectionID
    nextSectionID: IJourneySectionID
    nextSectionPageName: string
}) => {
    const history = useHistory()

    return (
        <DesktopDisplayOnly>
            <JourneySectionWrapperCustom
                style={{ paddingTop: '100px', position: 'relative' }}
            >
                <JourneyColumnContent
                    style={{ width: '100%', alignItems: 'center' }}
                >
                    <Container>
                        <JourneyColumnContent style={{ gap: '40px' }}>
                            <Title>Objective completed!</Title>

                            <Txt>{darkTextCopy}</Txt>

                            <Wrap>
                                <WrapTxt>{lightTextCopy}</WrapTxt>
                            </Wrap>
                        </JourneyColumnContent>
                    </Container>

                    <ImageStyle src={image_src_desktop} />
                </JourneyColumnContent>

                <div style={{ paddingTop: '100px' }} />

                <JourneyPatternCardNextUpDesktop
                    sectionID={nextSectionID}
                    copyData={journeyRedirectCardsCopy}
                    onNext={() => {
                        history.push({
                            pathname: `/journey/${nextSectionPageName}`,
                            search: `?step=welcome`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            </JourneySectionWrapperCustom>
        </DesktopDisplayOnly>
    )
}
