import { enumToNameFunc } from '../../../../helpers/strings/enumToName'
import {
    IInsuranceQuoteApplication_Car,
    IAnyObject,
    IInsuranceQuoteApplication_VehiclesObj,
} from '../../../insuranceQuoteApplication/reducer'
import {
    ICustodianCar,
    IInsuranceVehicleModification,
    IInsuranceApplication_API,
    IInsuranceApplication_APIErrors,
    ICustodianCarErrors,
    IInsuranceVehicleModification_APIErrors,
    IInsuranceEverydayVehicleID,
} from '../../../insuranceQuoteApplication/types'
import {
    fields_array_insurance_application_car_techinfo,
    fields_array_vehicle_modif,
    frequency_insurance_other_driver_ownership,
    IInsuranceFieldType,
    insurance_fields_car_mileage_parking_1,
    insurance_fields_car_mileage_parking_added_address_same_as_residential,
    insurance_fields_car_mileage_parking_added_address_different_than_residential,
    insurance_fields_car_mileage_parking_security,
    insurance_fields_car_mileage_parking_security_subform,
    insurance_fields_car_ownership,
    insurance_fields_parking_info,
    ncd_additional_fields,
    // insured_vehicle_marked_as_everyday_ncd,
    voluntary_excess_subfields,
    written_off_subfields,
    travel_to_residential_address_yes_subfields,
    residential_visit_overnight_yes_subfields,
    has_vehicle_modifications_negative_answer_subform,
    has_vehicle_modifications_positive_answer_subform,
    has_modification_adjusted_bhp_positive_answer_subform,
    is_imported_positive_answer_subfields,
} from '../../../localdata/insuranceLocalData/fieldTypes'
import { getInsuranceFEValue } from './helpers'

let insurance_fields_car_ownership_enriched = [
    ...insurance_fields_car_ownership,
    // ...insured_vehicle_marked_as_everyday_ncd,
    ...written_off_subfields,
    ...ncd_additional_fields,
    ...voluntary_excess_subfields,
    ...frequency_insurance_other_driver_ownership,
    ...is_imported_positive_answer_subfields,
]

let fields_array_insurance_application_car_techinfo_enriched = [
    ...fields_array_insurance_application_car_techinfo,
    ...has_vehicle_modifications_negative_answer_subform,
    ...has_vehicle_modifications_positive_answer_subform,
    ...has_modification_adjusted_bhp_positive_answer_subform,
]

let additional_ncd_ids = [
    'was_ncd_uk_earned_on_private_policy',
    'is_ncd_in_use',
    'has_requested_ncd_protection',
]

const mileage_parking_fields = [
    ...insurance_fields_car_mileage_parking_1,
    ...insurance_fields_car_mileage_parking_added_address_same_as_residential,
    ...insurance_fields_car_mileage_parking_added_address_different_than_residential,
    ...travel_to_residential_address_yes_subfields,
    ...residential_visit_overnight_yes_subfields,
    ...insurance_fields_car_mileage_parking_security,
    ...insurance_fields_car_mileage_parking_security_subform,
]
const mileage_parking_additionalIds = [
    // 'expected_annual_mileage',
    // 'odometer_reading',

    // 'is_kept_at_customer_residential_address',

    // below needs to add to FE the FE field boolean
    // separate issue created because flow changed
    // 'parking_place_same_day_night',
    'daytime_location',
    // 'eveningtime_location',
    'night_park_address_id',

    // below needs to add to FE the FE field boolean
    // 'has_security_device_fitted',

    // 'security_device_type',
    // 'security_device_fitted_by',
    // 'security_device_fitted_date',
]

const additionalParkingFieldsIds = [
    'location_type',
    'location_security',
    'address_id',
    'uid',
]

export const convertApplicationToInsuredVehicles = (
    application: IInsuranceApplication_API
): IInsuranceQuoteApplication_VehiclesObj => {
    let res: IInsuranceQuoteApplication_VehiclesObj = {}

    let cars = application?.customer?.custodian_cars

    if (cars) {
        cars.forEach((ccar: ICustodianCar) => {
            res[`${ccar.external_id}`] = {
                ...convertCustodianCarToReducerApplicationCar(
                    ccar,
                    application
                ),
            }
        })
    }

    // console.log('RES L 93', res)

    return res
}

export const convertApplicationToInsuredVehiclesErrors = (
    application: IInsuranceApplication_APIErrors
): IInsuranceQuoteApplication_VehiclesObj => {
    let res: IInsuranceQuoteApplication_VehiclesObj = {}

    let cars = application?.customer?.custodian_cars

    // check for garage car that is insured

    let carsNCD: ICustodianCarErrors[] = []

    application &&
        Array.isArray(application.application_custodian_cars) &&
        application.application_custodian_cars?.forEach(
            (item: ICustodianCarErrors) => {
                if (item.external_id) {
                    carsNCD = [...carsNCD, item]
                }
            }
        )

    if (cars && Object.keys(cars).length > 0) {
        cars.forEach((ccar: ICustodianCarErrors) => {
            let errorsss = convertCustodianCarToReducerApplicationCarErrors(
                ccar,
                application
            )

            // console.log('errors,', errorsss)

            res[`${ccar.external_id}`] = {
                ...errorsss,
            }
        })
    }

    if (carsNCD.length > 0) {
        carsNCD.forEach((car2) => {
            let ncd_errors: ICustodianCarErrors = { ...car2 }
            delete ncd_errors['external_id']
            delete ncd_errors['uid']

            if (res[`${car2.external_id}`]) {
                res[`${car2.external_id}`] = {
                    ...res[`${car2.external_id}`],
                    ownership_errors: {
                        ...res[`${car2.external_id}`].ownership_errors,
                        ...ncd_errors,
                    },
                }
            } else {
                res[`${car2.external_id}`] = {
                    id: car2.external_id,
                    api_uid: car2.uid,
                    tech_info: {},
                    tech_info_errors: {},
                    ownership: {},
                    ownership_errors: {
                        ...ncd_errors,
                    },
                    mileage_parking: {},
                    mileage_parking_errors: {},
                    entities: {
                        modifications: [],
                    },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                }
            }
        })
    }

    // check if a garage car is not insured but is marked as everyday

    let everydayCarsNCD: IInsuranceEverydayVehicleID[] = []
    application &&
        Array.isArray(application.application_everyday_vehicles) &&
        application.application_everyday_vehicles?.forEach(
            (item: IInsuranceEverydayVehicleID) => {
                if (item.custodian_car_uid) {
                    everydayCarsNCD = [...everydayCarsNCD, item]
                }
            }
        )

    if (everydayCarsNCD.length > 0) {
        everydayCarsNCD.forEach((car2) => {
            let ncd_years_everydaycar_error = car2 && car2.ncd_years

            if (res[`${car2.external_id}`]) {
                res[`${car2.external_id}`] = {
                    ...res[`${car2.external_id}`],
                    ownership_errors: {
                        ...res[`${car2.external_id}`].ownership_errors,
                        ncd_years: ncd_years_everydaycar_error,
                    },
                }
            } else {
                res[`${car2.external_id}`] = {
                    id: car2.external_id,
                    api_uid: car2.custodian_car_uid,
                    tech_info: {},
                    tech_info_errors: {},
                    ownership: {},
                    ownership_errors: {
                        ncd_years: ncd_years_everydaycar_error,
                    },
                    mileage_parking: {},
                    mileage_parking_errors: {},
                    entities: {
                        modifications: [],
                    },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                }
            }
        })
    }

    // console.log('res is', res)

    return res
}

const convertCustodianCarToReducerApplicationCar = (
    car: ICustodianCar,
    application: IInsuranceApplication_API
): IInsuranceQuoteApplication_Car => {
    let everyday_ids =
        application?.application_everyday_vehicles &&
        application?.application_everyday_vehicles.map(
            (item) => item.custodian_car_uid
        )
    let res: IInsuranceQuoteApplication_Car = {
        is_everyday:
            everyday_ids && everyday_ids.indexOf(`${car.uid}`) !== -1
                ? true
                : false,
        id: car.external_id,
        api_uid: car.uid,
        tech_info: {},
        tech_info_errors: {},
        ownership: {},
        ownership_errors: {},
        mileage_parking: {},
        mileage_parking_errors: {},
        entities: {
            modifications: [],
        },
        daytime_parking_info: {},
        daytime_parking_info_errors: {},
        eveningtime_parking_info: {},
        eveningtime_parking_info_errors: {},
    }

    //  tech info section
    for (
        let i = 0;
        i < fields_array_insurance_application_car_techinfo_enriched.length;
        i++
    ) {
        let field_id =
            fields_array_insurance_application_car_techinfo_enriched[i].id
        // @ts-ignore
        if (car[field_id] !== undefined && car[field_id] !== null) {
            res.tech_info = {
                ...res.tech_info,
                [field_id]: getInsuranceFEValue(
                    fields_array_insurance_application_car_techinfo_enriched[i],
                    // @ts-ignore
                    car[field_id]
                ),
            }
        }
    }
    //  ownership section

    for (let i = 0; i < insurance_fields_car_ownership_enriched.length; i++) {
        let field_id = insurance_fields_car_ownership_enriched[i].id
        if (field_id === 'ncd_years' || additional_ncd_ids.includes(field_id)) {
            let everyDayCar: IInsuranceEverydayVehicleID[] | undefined | false =
                application &&
                Array.isArray(application.application_everyday_vehicles) &&
                application.application_everyday_vehicles?.filter(
                    (item: IInsuranceEverydayVehicleID) =>
                        item.custodian_car_uid === car.uid
                )
            let ncd_years_answers =
                everyDayCar && everyDayCar[0] ? everyDayCar[0] : undefined

            if (ncd_years_answers) {
                res.ownership = {
                    ...res.ownership,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_car_ownership_enriched[i],

                        ncd_years_answers[
                            field_id as keyof IInsuranceEverydayVehicleID
                        ]
                    ),
                }
            }

            let application_car:
                | IInsuranceEverydayVehicleID[]
                | undefined
                | false =
                application &&
                Array.isArray(application.application_custodian_cars) &&
                application.application_custodian_cars?.filter(
                    (item: IInsuranceEverydayVehicleID) =>
                        item.external_id === car.external_id
                )
            let ncd_years_answers2 =
                application_car && application_car[0]
                    ? application_car[0]
                    : undefined

            if (ncd_years_answers2) {
                res.ownership = {
                    ...res.ownership,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_car_ownership_enriched[i],

                        ncd_years_answers2[
                            field_id as keyof IInsuranceEverydayVehicleID
                        ]
                    ),
                }
            }
        } else {
            res.ownership = {
                ...res.ownership,
                [field_id]: getInsuranceFEValue(
                    insurance_fields_car_ownership_enriched[i],
                    //@ts-ignore
                    car[field_id]
                ),
            }
        }
    }

    if (car.daytime_parking_info) {
        for (let i = 0; i < additionalParkingFieldsIds.length; i++) {
            let field_id = additionalParkingFieldsIds[i]

            if (field_id === 'location_type') {
                res.daytime_parking_info = {
                    ...res.daytime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.daytime_parking_info[field_id]
                            ? {
                                  name: enumToNameFunc(
                                      //@ts-ignore
                                      `${car.daytime_parking_info[field_id]}`
                                  ),
                                  //@ts-ignore
                                  uid: `${car.daytime_parking_info[field_id]}`,
                              }
                            : undefined,
                }
            } else
                res.daytime_parking_info = {
                    ...res.daytime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.daytime_parking_info[field_id],
                }
        }

        for (let i = 0; i < insurance_fields_parking_info.length; i++) {
            let field_id = insurance_fields_parking_info[i].id
            if (field_id === 'has_location_security') {
                if (
                    car?.daytime_parking_info[`location_security`] !==
                        undefined &&
                    Array.isArray(
                        car?.daytime_parking_info[`location_security`]
                    ) &&
                    car.daytime_parking_info[`location_security`].includes(
                        'NO_SECURITY'
                    )
                ) {
                    res.daytime_parking_info[`has_location_security`] = false
                } else if (
                    car?.daytime_parking_info[`location_security`] !==
                        undefined &&
                    car?.daytime_parking_info[`location_security`]?.length > 0
                ) {
                    res.daytime_parking_info[`has_location_security`] = true
                }
            } else {
                res.daytime_parking_info = {
                    ...res.daytime_parking_info,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_parking_info[i],
                        //@ts-ignore
                        car.daytime_parking_info[field_id]
                    ),
                }
            }
        }
    }

    if (car.eveningtime_parking_info) {
        for (let i = 0; i < additionalParkingFieldsIds.length; i++) {
            let field_id = additionalParkingFieldsIds[i]

            if (field_id === 'location_type') {
                res.eveningtime_parking_info = {
                    ...res.eveningtime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.eveningtime_parking_info[field_id]
                            ? {
                                  name: enumToNameFunc(
                                      //@ts-ignore
                                      `${car.eveningtime_parking_info[field_id]}`
                                  ),
                                  //@ts-ignore
                                  uid: `${car.eveningtime_parking_info[field_id]}`,
                              }
                            : undefined,
                }
            } else
                res.eveningtime_parking_info = {
                    ...res.eveningtime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.eveningtime_parking_info[field_id],
                }
        }

        for (let i = 0; i < insurance_fields_parking_info.length; i++) {
            let field_id = insurance_fields_parking_info[i].id

            if (field_id === 'has_location_security') {
                if (
                    car?.eveningtime_parking_info[`location_security`] !==
                        undefined &&
                    Array.isArray(
                        car?.eveningtime_parking_info[`location_security`]
                    ) &&
                    car.eveningtime_parking_info[`location_security`].includes(
                        'NO_SECURITY'
                    )
                ) {
                    res.eveningtime_parking_info[`has_location_security`] =
                        false
                } else if (
                    car?.eveningtime_parking_info[`location_security`] !==
                        undefined &&
                    car?.eveningtime_parking_info[`location_security`]?.length >
                        0
                ) {
                    res.eveningtime_parking_info[`has_location_security`] = true
                }
            } else
                res.eveningtime_parking_info = {
                    ...res.eveningtime_parking_info,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_parking_info[i],
                        // @ts-ignore
                        car.eveningtime_parking_info[field_id]
                    ),
                }
        }
    }

    //  mileage & parking
    for (let i = 0; i < mileage_parking_fields.length; i++) {
        let field_id = mileage_parking_fields[i].id

        if (field_id === 'parking_place_same_day_night') {
            let isSameAddress: boolean | undefined = !car.daytime_parking_info
                ?.address?.uid
                ? undefined
                : car.daytime_parking_info?.address?.uid ===
                  car.eveningtime_parking_info?.address?.uid
                ? true
                : false

            res.mileage_parking = {
                ...res.mileage_parking,
                [field_id]: isSameAddress,
            }
        } else {
            res.mileage_parking = {
                ...res.mileage_parking,
                [field_id]: getInsuranceFEValue(
                    mileage_parking_fields[i],
                    //@ts-ignore
                    car[field_id]
                ),
            }
        }
    }

    for (let i = 0; i < mileage_parking_additionalIds.length; i++) {
        let field_id = mileage_parking_additionalIds[i]
        if (field_id === 'daytime_location') {
            res.mileage_parking = {
                ...res.mileage_parking,
                day_park_address_id:
                    car.daytime_parking_info &&
                    car.daytime_parking_info.address &&
                    car.daytime_parking_info.address.uid
                        ? car.daytime_parking_info.address.uid
                        : undefined,
            }
        }
        if (field_id === 'night_park_address_id') {
            res.mileage_parking = {
                ...res.mileage_parking,
                night_park_address_id:
                    car.eveningtime_parking_info &&
                    car.eveningtime_parking_info.address &&
                    car.eveningtime_parking_info.address.uid
                        ? car.eveningtime_parking_info.address.uid
                        : undefined,
            }
        }
    }

    let modifs_api: IInsuranceVehicleModification[] = car.modifications ?? []

    if (modifs_api && modifs_api.length > 0) {
        let entityArr: IAnyObject[] = []

        for (let i = 0; i < modifs_api.length; i++) {
            let modifObjApi: IInsuranceVehicleModification = modifs_api[i]
            let entityObj: IAnyObject = {
                uid: modifs_api[i].uid,
            }
            fields_array_vehicle_modif.forEach((item: IInsuranceFieldType) => {
                if (item.id === 'modification_type_uid') {
                    entityObj = {
                        ...entityObj,
                        [`${item.id}`]: getInsuranceFEValue(
                            item,
                            // @ts-ignore
                            modifObjApi['modification_name']
                        ),
                    }
                } else
                    entityObj = {
                        ...entityObj,
                        [`${item.id}`]: getInsuranceFEValue(
                            item,
                            // @ts-ignore
                            modifObjApi[`${item.id}`]
                        ),
                    }
            })
            entityArr = [...entityArr, { ...entityObj }]
        }

        res.entities.modifications = [...entityArr]
    }

    res.mileage_parking = {
        ...res.mileage_parking,
        is_kept_at_customer_residential_address:
            car.is_kept_at_customer_residential_address,
    }

    return res
}

const convertCustodianCarToReducerApplicationCarErrors = (
    car: ICustodianCarErrors,
    application: IInsuranceApplication_APIErrors,
    existing?: IInsuranceQuoteApplication_Car
): IInsuranceQuoteApplication_Car => {
    let modif_api: IInsuranceVehicleModification_APIErrors[] =
        car.modifications ?? []

    let res: IInsuranceQuoteApplication_Car = existing ?? {
        id: car.external_id,
        api_uid: car.uid,
        tech_info: {},
        tech_info_errors: {},
        ownership: {},
        ownership_errors: {},
        mileage_parking: {},
        mileage_parking_errors: {},
        entities: {
            modifications: [],
        },
        daytime_parking_info: {},
        eveningtime_parking_info: {},
    }

    let tech_info_ids_errors =
        fields_array_insurance_application_car_techinfo_enriched.map(
            (field) => field.id
        )

    //  tech info section
    for (
        let i = 0;
        i < fields_array_insurance_application_car_techinfo_enriched.length;
        i++
    ) {
        //@ts-ignore
        res.tech_info_errors[tech_info_ids_errors[i]] =
            //@ts-ignore
            car[tech_info_ids_errors[i]]
    }

    let ownership_ids_errors = insurance_fields_car_ownership_enriched.map(
        (field) => field.id
    )

    //  ownership section
    for (let i = 0; i < ownership_ids_errors.length; i++) {
        let field_id = ownership_ids_errors[i]
        if (field_id === 'ncd_years' || additional_ncd_ids.includes(field_id)) {
            let everyDayCar: IInsuranceEverydayVehicleID[] | undefined | false =
                application &&
                Array.isArray(application.application_everyday_vehicles) &&
                application.application_everyday_vehicles?.filter(
                    (item: IInsuranceEverydayVehicleID) =>
                        item.custodian_car_uid === car.uid
                )
            let ncd_years_errors =
                everyDayCar && everyDayCar[0] ? everyDayCar[0] : undefined

            let applicationCar: ICustodianCarErrors[] | undefined | false =
                application &&
                Array.isArray(application.application_custodian_cars) &&
                application.application_custodian_cars?.filter(
                    (item: ICustodianCarErrors) => item.uid === car.uid
                )
            // console.log('inside of ncd', applicationCar)

            let ncd_years_errors_2 =
                applicationCar && applicationCar[0]
                    ? applicationCar[0]
                    : undefined

            // console.log('inside of ncd_years_error_2', ncd_years_error_2)

            if (ncd_years_errors) {
                //@ts-ignore
                res.ownership_errors[field_id] =
                    ncd_years_errors[
                        field_id as keyof IInsuranceEverydayVehicleID
                    ]
            }

            if (
                ncd_years_errors_2 !== undefined &&
                ncd_years_errors_2 !== null
            ) {
                //@ts-ignore
                res.ownership_errors[field_id] =
                    ncd_years_errors_2[field_id as keyof ICustodianCarErrors]

                // console.log('placing error here', res)
            }
        } else {
            //@ts-ignore
            res.ownership_errors[field_id] =
                //@ts-ignore
                car[field_id]
            // }
        }
    }

    // console.log('res is here l 589', res)

    //  mileage & parking

    for (let i = 0; i < mileage_parking_fields.length; i++) {
        let field_id = mileage_parking_fields[i].id

        res.mileage_parking_errors = {
            ...res.mileage_parking_errors,
            [field_id]:
                //@ts-ignore
                car[field_id],
        }
        if (
            field_id === 'is_kept_at_customer_residential_address' &&
            car.daytime_parking_info &&
            car.eveningtime_parking_info
        ) {
            res.mileage_parking_errors = {
                ...res.mileage_parking_errors,
                [field_id]: {
                    status: 'Missing',
                    message:
                        'is_kept_at_customer_residential_address is required',
                },
            }
        }
    }

    for (let i = 0; i < mileage_parking_additionalIds.length; i++) {
        let field_id: string = mileage_parking_additionalIds[i]

        res.mileage_parking_errors = {
            ...res.mileage_parking_errors,
            [field_id]:
                // @ts-ignore
                car[field_id],
        }
    }

    if (car.daytime_parking_info) {
        res.daytime_parking_info_errors = {
            ...car.daytime_parking_info,
        }

        res.mileage_parking_errors = {
            ...res.mileage_parking_errors,
            is_kept_at_customer_residential_address: {
                status: 'Please fill in all the information',
                message: 'Please fill in all the information',
            },
        }
    }

    if (car.eveningtime_parking_info) {
        res.eveningtime_parking_info_errors = {
            ...car.eveningtime_parking_info,
        }

        res.mileage_parking_errors = {
            ...res.mileage_parking_errors,
            is_kept_at_customer_residential_address: {
                status: 'Please fill in all the information',
                message: 'Please fill in all the information',
            },
        }
    }

    // modifs
    if (modif_api && modif_api.length > 0) {
        let uiFieldsList = fields_array_vehicle_modif
        let entitiesErrorsObj: IAnyObject = {}

        for (let i = 0; i < modif_api.length; i++) {
            let modifObjApi: IInsuranceVehicleModification_APIErrors =
                modif_api[i]
            let entityObj: IAnyObject = {}
            uiFieldsList.forEach((item: IInsuranceFieldType) => {
                // @ts-ignore
                let valuee = modifObjApi[`${item.id}`] ?? undefined
                if (item.id === 'modification_type_uid') {
                    valuee = modifObjApi.modification_name ?? undefined
                }
                entityObj[`${modifObjApi.uid}`] = {
                    ...entityObj[`${modifObjApi.uid}`],

                    uid: [`${modifObjApi.uid}`],
                    [`${item.id}`]: valuee,
                }
            })
            entitiesErrorsObj = {
                ...entitiesErrorsObj,
                [`"${entityObj.uid}"`]: { ...entityObj },
            }
        }

        res.entities_error = {
            ...res.entities_error,
            modifications: { ...entitiesErrorsObj },
        }
    }
    // console.log('res is here l 589', res)

    return res
}
