import React, { forwardRef } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { RootState } from 'typesafe-actions'
import Faded from '../../templates/animated/faded'
import SmallTimelineItemMobile from '../../molecules/smallTimelineItem/smallTimelineItemMobile'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ITimelineItem } from 'timelineModels'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import { setActiveEntryThunk } from '../../../redux/timeline/thunks'
import JourneyStartIcon from '../../atoms/icons/components/journey/playIcon'
import { journey_colours } from '../../../providers/theme/colours'

const Container = styled.div`
    width: 100%;

    @media ${device.desktop} {
        margin-top: 10px;
    }
`
const WrapperView = styled.div`
    width: 100%;
    height: 85vh;

    @media (min-device-height: 600px) {
        height: 78vh;
    }

    @media (min-device-height: 700px) {
        height: 80vh !important;
    }

    @media (min-device-height: 800px) {
        height: 82vh !important;
    }

    @media (min-device-height: 1000px) {
        height: 85vh !important;
    }

    @media (max-device-height: 600px) {
        height: 74vh !important;
    }
`

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    margin-top: 10px;
`

interface IStyled {
    isAnimated?: boolean
}

const AnimatedWrapper = styled.div<IStyled>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    @keyframes scale {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.05);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.05);
        }
    }

    ${(props) => props.isAnimated && 'animation: 0.4s scale linear'};
    animation-delay: 0.5s;
`

interface Props extends RouteComponentProps<any> {
    timelineItems: ITimelineItem[] | undefined
    activeView: string
    attachmentsObj: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    dataCyId?: string
    setActiveEntry: any
    withPlaceholder?: boolean
    onPlaceholderClick?: any
    hasSampleData?: boolean
    userCurrency: string | undefined
    readOnlyMode?: boolean
    currentCarID?: string
}

const JourneyWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid var(--journey-history_file-primary_300);
    background: linear-gradient(
        89deg,
        var(--journey-history_file-primary_500_08) 0%,
        var(--bg-color, #fff) 100%
    );
    display: flex;
    height: 100%;
    align-items: center;
    align-self: center;
    width: 90%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
`

const JournetTxt2 = styled.div`
    color: var(--journey-history_file-primary_900, #67a0e3);
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.001px;
    max-width: 450px;
`

const JournetTxt1 = styled.div`
    color: var(--journey-history_file-primary_900, #67a0e3);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0.001px;
`

const dispatchProps = {
    setActiveEntry: (payload: ITimelineItem) => setActiveEntryThunk(payload),
}

function mapStateToProps(state: RootState) {
    return {
        currentCarID: state.entities.carsData.currentCarID,
    }
}

/** The Row component. This should be a table row, and noted that we don't use the style that regular `react-window` examples pass in.*/
function renderSmallRow({
    data,
    index,
    style,
    onClick,
    cardHeight,
    userCurrency,
    hasSampleData,
    history,
    carid,
    readOnlyMode,
}: {
    data: ITimelineItem[]
    index: number
    style: any
    onClick: any
    cardHeight: number
    userCurrency: string | undefined
    hasSampleData?: boolean
    history?: any
    carid?: string
    readOnlyMode?: boolean | undefined
}) {
    return (
        <div style={style}>
            <SmallTimelineItemMobile
                key={index}
                item={data[index]}
                onClick={() => onClick(data[index])}
                dataCyId={`small-timeline-entry-${data[index].id}`}
                userCurrency={userCurrency}
                hasSampleData={hasSampleData}
                onAddNewLabelClick={() =>
                    history.push(
                        `/car/${carid}/history-file/entry?entryid=${data[index].id}&section_id=labels`
                    )
                }
                readOnlyMode={readOnlyMode}
                height={cardHeight}
            />
        </div>
    )
}

const innerElementType = forwardRef<HTMLInputElement, { style: any }>(
    ({ style, ...rest }, ref) => (
        <div
            ref={ref}
            style={{
                ...style,
                height: `${parseFloat(style.height) + 250}px`,
            }}
            {...rest}
        />
    )
)

// /** The Row component. This should be a table row, and noted that we don't use the style that regular `react-window` examples pass in.*/
// function renderFatRow({
//     data,
//     index,
//     style,
//     onClick,
//     attachmentsObj,
// }: // costsObj,
// {
//     data: ITimelineItem[]
//     index: number
//     style: any
//     onClick: any
//     attachmentsObj: INormalisedAttachmentsByID
//     // costsObj: INormalisedCostsById
// }) {
//     return (
//         <div style={style} key={index + 10}>
//             <FatTimelineItemMobile
//                 key={index}
//                 item={data[index]}
//                 onClick={() => onClick(data[index])}
//                 attachmentsObj={attachmentsObj}
//                 // costsObj={costsObj}
//                 dataCyId={`fat-timeline-entry-${data[index].id}`}
//             />
//         </div>
//     )
// }

type State = {
    itemSize: number
    listOffsetScrollTo: number | undefined
}
class TimelineViewManagerMobile extends React.Component<Props, State> {
    state = {
        itemSize: 225,
        listOffsetScrollTo: undefined,
    }

    _isMounted = false

    private listRef = React.createRef<List>()

    handleResize = () => {
        switch (true) {
            case this.props.hasSampleData && window.innerWidth > 600:
                return this.setState({ itemSize: 200 })
            case this.props.hasSampleData && window.innerWidth < 300:
                return this.setState({ itemSize: 240 })
            case this.props.hasSampleData:
                return this.setState({ itemSize: 220 })
            case window.innerWidth < 360:
                return this.setState({ itemSize: 220 })
            case window.innerWidth >= 360:
                return this.setState({ itemSize: 220 })
            default:
                return this.setState({ itemSize: 220 })
        }
    }

    componentDidMount() {
        this._isMounted = true
        window.addEventListener('resize', this.handleResize)

        const query_params = new URLSearchParams(this.props.location.search)

        let scrolltoentryid: any = query_params.get('scrolltoid')

        if (scrolltoentryid && this.props.timelineItems) {
            const index_to_scroll_to = this.props.timelineItems.findIndex(
                (item) => item.id === scrolltoentryid
            )

            const new_list_offset_scroll =
                index_to_scroll_to && index_to_scroll_to > 0
                    ? index_to_scroll_to * this.state.itemSize
                    : undefined

            this.setState({ listOffsetScrollTo: new_list_offset_scroll })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.timelineItems !== this.props.timelineItems) {
            this.setState({
                listOffsetScrollTo: 0,
            })
            const query_params = new URLSearchParams(this.props.location.search)

            let scrolltoentryid: any = query_params.get('scrolltoid')

            if (scrolltoentryid && this.props.timelineItems) {
                const index_to_scroll_to = this.props.timelineItems.findIndex(
                    (item) => item.id === scrolltoentryid
                )

                const new_list_offset_scroll =
                    index_to_scroll_to && index_to_scroll_to > 0
                        ? index_to_scroll_to * this.state.itemSize
                        : undefined

                if (this.listRef?.current && new_list_offset_scroll) {
                    this.listRef.current.scrollTo(new_list_offset_scroll)
                }
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize)
        this._isMounted = false
    }

    // getItemSize = (index: number): number => {
    //     let entryLabelsArray: ILabel[] | undefined =
    //         this.props.timelineItems &&
    //         this.props.timelineItems[index] &&
    //         this.props.timelineItems[index].labels

    //     return entryLabelsArray && entryLabelsArray.length > 0 ? 155 : 120
    // }

    renderRightView = (activeView: string) => {
        let data: ITimelineItem[] = this.props.timelineItems
            ? this.props.timelineItems
            : []
        let carid: string = this.props.match.params.carid
        let onClick = this.props.readOnlyMode
            ? (timelineItem: ITimelineItem) => {
                  this.props.history.push(
                      `${this.props.match.url}/entry?entryid=${timelineItem.id}`
                  )
              }
            : this.props.hasSampleData
            ? () => this.props.history.push(`/car/${carid}/history-file/create`)
            : (timelineItem: ITimelineItem) => {
                  this.props.history.push(
                      `/car/${carid}/history-file/entry?entryid=${timelineItem.id}`
                  )
              }
        let userCurrency = this.props.userCurrency
        let hasSampleData = this.props.hasSampleData
        const history = this.props.history

        let cardHeight = this.state.itemSize
        let readOnlyMode = this.props.readOnlyMode

        if (activeView === 'small') {
            return (
                <Faded>
                    <WrapperView data-attr={this.props.dataCyId}>
                        <AutoSizer>
                            {({ width, height }: any) => (
                                <List
                                    ref={this.listRef}
                                    height={height}
                                    itemCount={
                                        this.props.timelineItems
                                            ? this.props.timelineItems.length
                                            : 1
                                    }
                                    // itemSize={(index) => {
                                    //     return this.getItemSize(index)
                                    // }}
                                    itemSize={this.state.itemSize}
                                    width={width}
                                    overscanCount={20}
                                    innerElementType={innerElementType}
                                    initialScrollOffset={
                                        this.state.listOffsetScrollTo ?? 0
                                    }
                                >
                                    {({ index, style }) =>
                                        renderSmallRow({
                                            data,
                                            index,
                                            style,
                                            onClick,
                                            cardHeight,
                                            userCurrency,
                                            hasSampleData,
                                            history,
                                            carid,
                                            readOnlyMode,
                                        })
                                    }
                                </List>
                            )}
                        </AutoSizer>
                    </WrapperView>
                </Faded>
            )
        } else if (activeView === 'fat') {
            return <div />
        }
    }

    render() {
        const { activeView, withPlaceholder, readOnlyMode } = this.props

        let params = new URLSearchParams(this.props.location.search)
        let section_id: string | null = params.get('section_id')

        return (
            <Container>
                {withPlaceholder && !readOnlyMode && (
                    <div
                        style={{
                            width: '100%',
                        }}
                    >
                        <AnimatedWrapper
                            isAnimated={section_id === 'create' ? true : false}
                        >
                            <JourneyWrapper
                                role="button"
                                onClick={
                                    () => {
                                        this.props.history.push({
                                            pathname: `/journey/history-file`,
                                            search: `?step=welcome&carid=${this.props.currentCarID}`,
                                            state: {
                                                prevPath: `${
                                                    this.props.history.location
                                                        .pathname
                                                }${
                                                    this.props.history.location
                                                        .search ?? ''
                                                }`,
                                            },
                                        })
                                    }
                                    // onPlaceholderClick && onPlaceholderClick
                                }
                            >
                                <JourneyStartIcon
                                    color={
                                        journey_colours.light.section
                                            .history_file.primary_500
                                    }
                                    size={'40px'}
                                />
                                <div style={{ paddingTop: '12px' }} />
                                <JournetTxt1>
                                    Bring your car's history online
                                </JournetTxt1>
                                <div style={{ paddingTop: '8px' }} />
                                <JournetTxt2>
                                    Learn how you can easily keep track of
                                    expenses associated with your car's history,
                                    including parts, labor, and other costs.
                                </JournetTxt2>
                            </JourneyWrapper>

                            {/* <AddInfoBannerMobile
                                onClick={
                                    onPlaceholderClick && onPlaceholderClick
                                }
                                action="add"
                                width="93vw"
                                height="130px"
                                placeholder="add your car’s documentation & history"
                            /> */}
                        </AnimatedWrapper>
                        <Separator />
                    </div>
                )}
                {this.renderRightView(activeView)}
            </Container>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TimelineViewManagerMobile)
)
