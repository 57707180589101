import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const MakeIcon = ({ size, strokeColor, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26 19.5043V9.06622C24 8.37748 19.2 6.2 16 3C12.8 6.2 8 8.37748 6 9.06622V19.5043C6 23.7246 12.25 27.7687 16 29C19.75 27.7687 26 23.7246 26 19.5043ZM22 18.1565V11.733C20.8 11.3092 17.92 9.96922 16 7.99998C14.08 9.96922 11.2 11.3092 10 11.733V18.1565C10 20.7536 13.75 23.2423 16 24C18.25 23.2423 22 20.7536 22 18.1565Z"
                fill={color ?? colours[theme].icons_neutral_250}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 1.93933L16.5303 2.46966C19.6173 5.55658 24.2978 7.6868 26.2442 8.35709L26.75 8.53127V19.5043C26.75 21.9667 24.9545 24.226 22.8863 25.9492C20.7831 27.7016 18.1998 29.0671 16.234 29.7125L16 29.7894L15.766 29.7125C13.8002 29.0671 11.2169 27.7016 9.11365 25.9492C7.04551 24.226 5.25 21.9667 5.25 19.5043V8.53127L5.7558 8.35709C7.70221 7.6868 12.3827 5.55658 15.4697 2.46966L16 1.93933ZM6.75 9.59183V19.5043C6.75 21.2622 8.07949 23.1351 10.0738 24.7968C11.9488 26.359 14.2425 27.5913 16 28.2079C17.7575 27.5913 20.0512 26.359 21.9262 24.7968C23.9205 23.1351 25.25 21.2622 25.25 19.5043V9.59183C23.1049 8.77855 19.0288 6.83242 16 4.03927C12.9712 6.83242 8.89512 8.77855 6.75 9.59183Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
        </svg>
    )
}

export default MakeIcon
