import { useState } from 'react'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import { useAppDispatch } from '../../../redux/store/hooks'

export const WelcomeApplicationTest = () => {
    const dispatch = useAppDispatch()

    let [appliid, setApliid] = useState<string>('')

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <button
                onClick={() => {
                    dispatch(
                        insuranceActions.create_insurance_application_request(
                            {}
                        )
                    )
                }}
            >
                create an application
            </button>

            <button
                onClick={() => {
                    dispatch(
                        insuranceActions.delete_insurance_application_request({
                            uid: appliid,
                        })
                    )
                }}
            >
                Delete your application with the id entered below:
            </button>

            <br />

            <input
                style={{ width: '80vw' }}
                value={appliid}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setApliid(e.target.value)
                }}
            />
        </div>
    )
}
