import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import EntryNotesEditorMobile from './EntryNotesEditorMobile'
import Expander from '../../../../atoms/expander/expander'
import Faded from '../../../../templates/animated/faded'

const Wrapper = styled.section`
    width: 100%;
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: red;
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    notes: string
}

interface OtherProps {
    data: any
    dataCyId?: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { setFieldValue, values, errors, dataCyId, data } = props

    const handleAnswerChange = (text: any) => {
        setFieldValue('notes', text)
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                text,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, text)
        }
    }

    return (
        <Wrapper>
            <Expander height={errors.notes ? 'auto' : 0}>
                <Faded>
                    <div style={{ paddingTop: '5px' }} />
                    <Error className="animated fadeIn slower">
                        {errors.notes}
                    </Error>
                </Faded>
            </Expander>
            <EntryNotesEditorMobile
                placeholder={'+ add your notes here'}
                valuePassed={values.notes}
                handleChange={(text: any) => handleAnswerChange(text)}
                idPassed="notes"
                readOnly={false}
                dataCyId={dataCyId}
            />
        </Wrapper>
    )
}

const EntryNotesFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        notes: props.data.value_start ? props.data.value_start : '',
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                notes: Yup.string().max(20000, 'over20k'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        // setSubmitting(false)
        // if (props.item.answer !== values.notes) {
        //     props.submitFunc({
        //         taskid: props.taskid,
        //         dataList: [
        //             {
        //                 id: 'body',
        //                 answer: values.notes,
        //             },
        //         ],
        //     })
        // }
        // props.toggle()
    },
})(InnerForm)

export default connect(mapStateToProps, dispatchProps)(EntryNotesFormMobile)
