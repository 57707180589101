import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const filterTasksByCarId = (car_id: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.filter_tasks_car_endpoint(car_id), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        return Promise.reject(response)
                    }

                    if (data) {
                        return data.data
                    }
                })
                .catch((e: any) => {
                    apiSentryErrorHandler(e, 'Filter tasks by car id error')
                    return Promise.reject(e)
                })
        }
    )
}

export const filterTasksByGarageId = (garage_id: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.filter_tasks_garage_endpoint(garage_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    return Promise.reject(response)
                }

                if (data) {
                    return data.data
                }
            })
            .catch((e: any) => {
                apiSentryErrorHandler(e, 'Filter tasks by garage id error')
                return Promise.reject(e)
            })
    })
}

export const getTaskById = (task_id: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.get_task_by_task_id_endpoint(task_id),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                apiSentryErrorHandler(e, 'Get task by id error')
                return Promise.reject(e)
            })
    })
}
