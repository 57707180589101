import * as React from 'react'
import CarGalleryEditFormMobile from '../carGalleryEditForm/carGalleryEditFormMobile'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import HistoryFileGalleryEditFormMobile from '../historyFileGalleryEditForm/historyFileGalleryEditFormMobile'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { IUploadGalleryImagesPreSignedUrlsPayload } from 'myModels'
let axios = require('axios')

function mapStateToProps(state: RootState) {
    return {
        gallerypresignedurls: state.fileStorage.gallerypresignedurls,
    }
}

interface Props {
    close: any
    carid: string
    entryid?: string
    gallerypresignedurls?: IUploadGalleryImagesPreSignedUrlsPayload
    isOnboarding?: boolean
    step?: number
}

type State = {
    imageUploadedData: any[] | null
    isImageCropperShown: boolean
    progressArray?: number[]
    changeInPresignedURL: boolean
    presignedUrlsState: any
    filesArray: (File | undefined)[]
    [key: string]: any
    isSomethingBeingUploaded: boolean
    count: number
    progressState: { [key: string]: any }
}

class WrapperImageFormsMobile extends React.Component<Props, State> {
    state = {
        imageUploadedData: null,
        isImageCropperShown: false,
        progressArray: [],
        changeInPresignedURL: false,
        presignedUrlsState: undefined,
        filesArray: [],
        isSomethingBeingUploaded: true,
        count: 0,
        progressState: {
            index: null,
            total: 0,
            isLastUploaded: false,
        },
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let urls = this.props.gallerypresignedurls

        if (urls !== prevProps.gallerypresignedurls) {
            if (urls) {
                let stateFiles: File[] = this.state.filesArray
                    ? this.state.filesArray
                    : ([] as any)

                this.upload_presignedurl_image_to_s3(stateFiles)

                this.setState({
                    presignedUrlsState: this.props.gallerypresignedurls,
                    changeInPresignedURL: !this.state.changeInPresignedURL,
                })
            }
        }

        return
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    // DIRECT SETSTATE

    setImageUploaded = (filesArrayToUpload: any[]) => {
        this.setState({
            progressState: {
                index: null,
                total: 0,
                isLastUploaded: false,
            },
            count: 0,
            filesArray: filesArrayToUpload,
        })
    }

    deleteImageUploaded = (index: number, name?: string) => {
        let newFilesArray: (File | undefined)[] = this.state.filesArray

        if (newFilesArray && newFilesArray[index]) {
            newFilesArray[index] = undefined

            name
                ? this.setState({
                      fileList: this.state.filesArray.filter(
                          (item: any) =>
                              item !== undefined && item.name !== name
                      ),
                  })
                : this.setState({ filesArray: newFilesArray })

            // this.setState({ [key]: null })
        }
    }

    toggleShowImageCropper = () => {
        this.setState({ isImageCropperShown: !this.state.isImageCropperShown })
    }

    // OTHER FUNCS

    upload_presignedurl_image_to_s3 = (files: File[]) => {
        let filesToSend = this.state.filesArray

        filesToSend !== null &&
            filesToSend !== undefined &&
            filesToSend.forEach(async (file: any, index: number) => {
                if (
                    file !== undefined &&
                    this.props.gallerypresignedurls &&
                    this.props.gallerypresignedurls[file.name]
                ) {
                    this.setState({
                        ...this.state.progressState,
                        isSomethingBeingUploaded: true,
                    })

                    this.setState({
                        ...this.state.progressState,
                        progressState: {
                            total: 0,
                            index: index,
                        },
                    })

                    return await axios
                        .request({
                            method: 'put',
                            url: this.props.gallerypresignedurls[file.name].url,
                            data: files[index],

                            onUploadProgress: (progressEvent: any) => {
                                let percentCompleted = Math.round(
                                    (progressEvent.loaded * 100) /
                                        progressEvent.total
                                )

                                this.setState({
                                    progressState: {
                                        ...this.state.progressState,
                                        total: percentCompleted,
                                        index: index,

                                        filesLength: filesToSend.length,
                                    },
                                })

                                if (percentCompleted === 100) {
                                    let count2: number = this.state.count

                                    count2 = count2 + 1

                                    this.setState({
                                        count: count2,
                                        progressState: {
                                            ...this.state.progressState,
                                        },
                                    })

                                    let cleanedFilesToSend =
                                        filesToSend &&
                                        filesToSend.filter(
                                            (file) => file !== undefined
                                        )

                                    if (count2 === cleanedFilesToSend.length) {
                                        this.setState({
                                            ...this.state,
                                            progressState: {
                                                ...this.state.progressState,
                                                isLastUploaded: true,
                                                isSomethingBeingUploaded: false,
                                            },
                                            currentFilesArray: [],
                                        })
                                    }
                                }
                            },
                        })
                        .then((data: any) => {})
                        .catch((e: any) => {
                            apiSentryErrorHandler(
                                e,
                                'Upload presigned url car image to s3 error'
                            )
                            return Promise.reject(e)
                        })
                }
            })

        return
    }

    render() {
        const { carid, entryid, close } = this.props

        const { filesArray, isSomethingBeingUploaded, count } = this.state

        return (
            <div>
                {!entryid && (
                    <CarGalleryEditFormMobile
                        close={close}
                        count={count}
                        isLastUploaded={this.state.progressState.isLastUploaded}
                        currentImagesUploaded={filesArray}
                        carid={carid}
                        progressArray={this.state}
                        isSomethingBeingUploaded={isSomethingBeingUploaded}
                        setImageUploaded={this.setImageUploaded}
                        progressState={
                            this.state.progressState.index !== null &&
                            this.state.progressState
                        }
                    />
                )}

                {entryid && (
                    <HistoryFileGalleryEditFormMobile
                        carid={carid}
                        entryid={entryid}
                        close={close}
                        count={count}
                        isLastUploaded={this.state.progressState.isLastUploaded}
                        currentImagesUploaded={filesArray}
                        progressArray={this.state}
                        isSomethingBeingUploaded={isSomethingBeingUploaded}
                        setImageUploaded={this.setImageUploaded}
                        progressState={
                            this.state.progressState.index !== null &&
                            this.state.progressState
                        }
                    />
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps)(WrapperImageFormsMobile)

//                 onUploadProgress: (p: any) => {
//

//                     let new_key = `progressArray-${index}`

//                     this.setState({
//                         progressState: {
//                             total: (p.loaded / p.total) * 100,
//                         },
//                     })

//                     this.setState({
//                         [new_key]: (p.loaded / p.total) * 100,
//                     })

//

//                     if (p.loaded === p.total) {
//

//                         this.setState({
//                             progressState: {
//                                 total: 100,
//                                 index: index,
//                                 isLastUploaded:
//                                     index + 1 === filesToSend.length
//                                         ? true
//                                         : false,
//                                 filesLength: filesToSend.length,
//                                 totalL: [
//                                     index + 1,
//                                     filesToSend.length,
//                                 ],
//                             },
//                         })
//                     }
//                 },
//             })
//             .then((data: any) => {
//                 if (
//                     index === filesToSend.length - 1 &&
//                     this.state.progressState.total === 100
//                 ) {
//                     this.setState({
//                         isSomethingBeingUploaded: false,
//                     })
//                 }

//
//             })
//     }
//     // };
// })
