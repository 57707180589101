import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

import {
    GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_ERROR,
    FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_ERROR,
    ADD_TIMELINE_ITEM_TO_CAR_ERROR,
    UPDATE_TIMELINE_ITEM_TO_CAR_ERROR,
    DELETE_TIMELINE_ITEM_TO_CAR_ERROR,
    ADD_COST_TO_TIMELINE_ITEM_ERROR,
    DELETE_TIMELINE_ITEM_COST_ERROR,
    UPDATE_TIMELINE_ITEM_COST_ERROR,
    GET_COST_ENTRY_BY_ID_ERROR,
    GET_ALL_USER_CAR_ENTRY_LABELS_ERROR,
    SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_ERROR,
    MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_ERROR,
    ADD_LABEL_TO_CAR_ENTRY_LABELS_ERROR,
    REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_ERROR,
    ADD_ATTACHMENT_TO_ENTRY_ERROR,
    ADD_ATTACHMENT_TO_COST_ERROR,
    DELETE_ENTRY_ATTACHMENT_ERROR,
    DELETE_COST_ATTACHMENT_ERROR,
    GET_TIMELINE_ITEM_BY_ID_ERROR,
    MANAGE_ADD_ATTACHMENTS_TO_ENTRY_ERROR,
    ADD_TIMELINE_ENTRY_WITH_COST_ERROR,
    UPDATE_TIMELINE_ENTRY_WITH_COST_ERROR,
} from './constants'

export const getAllTimelineItemsByCarIDError = createAction(
    GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getTimelineItemByIDError = createAction(
    GET_TIMELINE_ITEM_BY_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const filterTimelineItemsByCarCategoryIdsNLabelIdsError = createAction(
    FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addTimelineItemToCarError = createAction(
    ADD_TIMELINE_ITEM_TO_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const updateTimelineEntryError = createAction(
    UPDATE_TIMELINE_ITEM_TO_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteTimelineEntryError = createAction(
    DELETE_TIMELINE_ITEM_TO_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getCostEntryByIdError = createAction(
    GET_COST_ENTRY_BY_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addCostToTimelineItemError = createAction(
    ADD_COST_TO_TIMELINE_ITEM_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const updateTimelineItemCostError = createAction(
    UPDATE_TIMELINE_ITEM_COST_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteTimelineItemCostError = createAction(
    DELETE_TIMELINE_ITEM_COST_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getAllUserCarEntryLabelsError = createAction(
    GET_ALL_USER_CAR_ENTRY_LABELS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsError =
    createAction(
        SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_ERROR,
        function prepare(payload: IReduxError) {
            return {
                payload,
            }
        }
    )

export const manageDataToBeSubmittedTimelineCreationError = createAction(
    MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addLabelToCarEntryError = createAction(
    ADD_LABEL_TO_CAR_ENTRY_LABELS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const removeLabelToCarEntryError = createAction(
    REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addAttachmentToEntryError = createAction(
    ADD_ATTACHMENT_TO_ENTRY_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addAttachmentToCostError = createAction(
    ADD_ATTACHMENT_TO_COST_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteEntryAttachmentError = createAction(
    DELETE_ENTRY_ATTACHMENT_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteCostAttachmentError = createAction(
    DELETE_COST_ATTACHMENT_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const manageAddAttachmentsToEntryError = createAction(
    MANAGE_ADD_ATTACHMENTS_TO_ENTRY_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addTimelineItemWithCostsToCarError = createAction(
    ADD_TIMELINE_ENTRY_WITH_COST_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const updateTimelineItemWithCostsError = createAction(
    UPDATE_TIMELINE_ENTRY_WITH_COST_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
