import * as React from 'react'
import styled from 'styled-components'

import SectionHeaderV2 from '../../atoms/typography/sectionHeaderV2'
import { device } from '../../templates/displays/devices'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import copy_icon from '../../../public/assets/icons/copy_icon.svg'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
    ICar,
    IPrivateSharesActiveDisplayArgs,
    IDropdownItem,
    IGarage,
    IDirectShareOwnedItem,
    ISharingPermissionIDS,
} from 'entityModels'
// import ShareRowMobile from '../../atoms/shareRow/shareRowMobile'
// import FilterButton from '../../atoms/Button/filterButton'
import GreyIconButton from '../../atoms/Button/GreyIconButton'
// import BottomSheetPage from '../../templates/bottomSheetPages.tsx/bottomSheetPage'
// import TopHeaderFilterNavMobileCarShare from '../../organisms/smartHeadres/topHeaderFilterNav/topHeaderFilterNavMobileCarShare'
import FilterBottomSheetMenuMobile from '../../atoms/menu/bottomSheetMenu/filterMenuTimelineMobile'
import PrivateSharesSortingMobile from '../../atoms/menu/bottomSheetMenu/privateSharesSortingMobile'
import {
    IFilterCarSharesDisplay_change_request,
    ISearchQueryCarSharesDisplay_change_request,
    ISortCarSharesDisplay_change_request,
} from '../../../redux/entities/cars/actions/loadingActions'
import { sharing_permissions_ids_selectable_list_mock } from '../../../redux/entities/cars/mockSharedItems'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
import { motion } from 'framer-motion'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import { IDeleteShareReq } from '../../../redux/entities/cars/actions/actions'
import { Tooltip } from 'antd'
import DirectShareRowMobile from '../../atoms/shareRow/directShareRowMobile'
import QrCodeBtn from '../../atoms/Button/qrCodeBtn'
import { IDirectShareDeletionConfirmation, IisOpen } from 'myModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import LoaderElasticThreeDots from '../../atoms/loader/loaderElasticThreeDots'
import AddInfoBannerMobile from '../../atoms/Button/addInfoBannerMobile'

interface IStyled {
    isActive?: boolean
}

const Container = styled.div<IStyled>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Lato';
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
    @media ${device.ipad} {
        padding-top: 10px;
        padding-left: 40px;
        padding-right: 40px;
    }
`

const TitleWrapper = styled.div<IStyled>`
    max-width: 450px;
`

const Row = styled.div<IStyled>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
`

const Description = styled.div<IStyled>`
    font-family: 'Lato';
    font-size: 14px;
    overflow-wrap: break-word;
    color: var(--text-darker, #616161);
    line-height: 22px;

    @media ${device.ipad} {
        font-size: 15px;
        width: 70%;
        max-width: 450px;
    }
`

const Tag = styled.div<IStyled>`
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: ${(props) =>
        props.isActive
            ? 'var(--insurance_details_green)'
            : 'var(--border_muted_neutral)'};
    color: var(--bg-color, #fff);
    font-family: 'Lato';
    text-align: center;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 20px;
    font-size: 9px;
`

const BtnBlue = styled.a`
    color: var(--primary, #5ec3ca);
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }

    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const BtnRed = styled.button`
    color: var(--error, #df6f6f);
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: auto;
    font-family: 'Lato';
    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    :hover {
        opacity: 0.7;
    }
    padding: 0px;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const LinkWrapper = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 10px;
    width: 100%;
    transition: all 0.1s;
    cursor: pointer;
    background-color: transparent;
    :focus {
        outline: 0;
    }
    :hover {
        opacity: 0.7;
    }
`

const LinkTxt = styled.div`
    color: var(--text-darker, #616161);
    font-size: 12px;
    line-height: 20px;
    padding-right: 5px;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`
const CopyIcon = styled.img`
    height: 20px;
`

type IFilterButtonProp = {
    $content?: number | undefined
}

const FilterButtonWrapper = styled.div<IFilterButtonProp>`
    padding-left: 15px;
    position: relative;
    ::after {
        display: ${(props) => (props.content ? 'block' : 'none')};
        position: absolute;
        right: 0;
        top: 0;
        margin-right: -5px;
        margin-top: -7px;
        height: 15px;
        width: 15px;
        background-color: var(--primary, #5ec3ca);
        font-size: 10px;
        color: var(--bg-color, #fff);
        border: transparent;
        border-radius: 4px;
        text-align: center;
        content: ${(props) => (props.content ? `'${props.content}'` : '')};
    }
`

interface Props extends RouteComponentProps {
    car: ICar
    dataCyId?: string
    handleChange_shares_search: (
        p: ISearchQueryCarSharesDisplay_change_request
    ) => void
    handleChange_shares_filter: (
        p: IFilterCarSharesDisplay_change_request
    ) => void
    handleChange_shares_sort: (p: ISortCarSharesDisplay_change_request) => void
    resetSearch: (carid: string) => void
    createCarPublicShare: (carid: string) => void
    garage: IGarage
    deletePublicLink: (p: IDeleteShareReq) => void
    noCarTitle?: boolean
    openQrCodeModal: (qr_code_id: string) => void
    publicLinkDeactivationConfirmationModal: boolean
    togglePublicLinkDeactivConfModal: (p: IisOpen) => void
    areCarSharesLoading: boolean
    shareDeletionConfirmation: IDirectShareDeletionConfirmation
    toggleShareDeletionConfirmation: (
        p: IDirectShareDeletionConfirmation
    ) => void
}

const FiltersContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

const CarTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    font-family: Lato;
    font-size: 20px;
`

const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    width: 25px;
    background-color: var(--text-muted);
    margin-top: 10px;
    margin-bottom: 10px;
`

const CopyTxt = styled.div`
    color: var(--text-default, #666666);
    font-size: 12px;
    padding-right: 20px;
    font-family: 'Lato-Bold';
    padding-top: 15px;
    padding-bottom: 14px;
    text-transform: uppercase;
`

const NoCarTitle = styled.div`
    padding-top: 10px;
    @media ${device.ipad} {
        padding-top: 10px;
    }
`

function mapStateToProps(state: RootState) {
    return {
        publicLinkDeactivationConfirmationModal:
            state.editForms.formsData.publicLinkDeactivationConfirmationModal
                .isOpen,
        areCarSharesLoading: state.entities.carsData.carSharesLoading,
        shareDeletionConfirmation:
            state.editForms.directShareDeletionConfirmation,
    }
}

const dispatchProps = {
    togglePublicLinkDeactivConfModal: (p: IisOpen) =>
        editFormsActions.togglePublicLinkDeactivConfModal(p),

    toggleShareDeletionConfirmation: (p: IDirectShareDeletionConfirmation) =>
        editFormsActions.toggleShareDeletionConfirmation(p),
}

type State = {
    isSearchPageOpen: boolean
    isFilterBottomSheetOpen: boolean
    isSortBottomSheetOpen: boolean
    showCarTooltip: boolean
    showGarageTooltip: boolean
}

class SingleCarShareBodyMobile extends React.Component<Props, State> {
    state = {
        isSearchPageOpen: false,
        isFilterBottomSheetOpen: false,
        isSortBottomSheetOpen: false,
        showCarTooltip: false,
        showGarageTooltip: false,
    }

    onAddShareClick = () => {
        this.props.history.push(
            `/sharing/with-others/car/create-share?carid=${this.props.car.id}`
        )
    }

    toggleTooltip = (option: 'car' | 'garage') => {
        option === 'car'
            ? this.setState({ showCarTooltip: true })
            : this.setState({ showGarageTooltip: true })
        setTimeout(() => {
            this.setState({ showCarTooltip: false, showGarageTooltip: false })
        }, 500)
    }

    render() {
        const {
            dataCyId,
            car,
            handleChange_shares_sort,
            handleChange_shares_filter,
            // handleChange_shares_search,
            // resetSearch,
            garage,
            createCarPublicShare,
            openQrCodeModal,
            publicLinkDeactivationConfirmationModal,
            togglePublicLinkDeactivConfModal,
            deletePublicLink,
            areCarSharesLoading,
        } = this.props

        let hasCarShare: boolean =
            car && car.public_share_owned && car.public_share_owned.isActive
                ? car.public_share_owned.isActive
                : false

        let has_garage_share: boolean =
            garage &&
            garage.public_share_owned &&
            garage.public_share_owned.isActive
                ? garage.public_share_owned.isActive
                : false

        let car_link =
            car && car.public_share_owned && car.public_share_owned.url
                ? car.public_share_owned?.url
                : ''

        let garage_link =
            garage && garage.public_share_owned && garage.public_share_owned.url
                ? `${garage.public_share_owned.url}/car/${car.id}`
                : ''

        const calculate_link_width = (link: string) => {
            if (window.innerWidth > 700) {
                return link
            } else if (window.innerWidth <= 700 && window.innerWidth > 600) {
                if (link.length > 63) {
                    return `${link.slice(0, 63)}...`
                } else return link
            } else if (window.innerWidth <= 600 && window.innerWidth > 320) {
                if (link.length > 40) {
                    return `${link.slice(0, 40)}...`
                } else return link
            } else if (window.innerWidth <= 320 && window.innerWidth > 300) {
                if (link.length > 30) {
                    return `${link.slice(0, 30)}...`
                } else return link
            } else if (window.innerWidth <= 300) {
                if (link.length > 20) {
                    return `${link.slice(0, 20)}...`
                } else return link
            } else return link
        }

        let categoryData: IDropdownItem[] =
            sharing_permissions_ids_selectable_list_mock

        let sharesDisplayState: IPrivateSharesActiveDisplayArgs | undefined =
            car.private_shares_owned?.active_display

        let carPrivateSharesOwned = car && car.private_shares_owned

        // check if car has any direct shares
        let carHasDirectShares: boolean =
            carPrivateSharesOwned &&
            carPrivateSharesOwned.entity_owned_direct_shares_ids &&
            carPrivateSharesOwned.entity_owned_direct_shares_ids.length > 0
                ? true
                : false

        let isCarSharedViaGarage =
            garage &&
            garage.private_shares_owned &&
            garage.private_shares_owned?.entity_owned_direct_shares_ids &&
            garage.private_shares_owned?.entity_owned_direct_shares_ids.length >
                0
                ? true
                : false

        // function to extract shares to be displayed and check if search criteria has been activated or not
        let directSharesToDisplayExtractor = (): {
            shares: IDirectShareOwnedItem[] | undefined
            hasActiveSearchCriteria: boolean
        } => {
            // initialize array to store shares
            let sharesToDisplayArray: IDirectShareOwnedItem[] = []

            if (
                // if car has any shares
                carPrivateSharesOwned &&
                carPrivateSharesOwned.entity_owned_direct_shares_ids
            ) {
                if (
                    // if search criteria have been activated
                    carPrivateSharesOwned.active_display &&
                    carPrivateSharesOwned.active_display.item_ids_display
                ) {
                    // extract all shares that match search criteria
                    carPrivateSharesOwned.active_display.item_ids_display.map(
                        (shareID: string, index: number) =>
                            sharesToDisplayArray.push(
                                carPrivateSharesOwned!
                                    .entity_owned_direct_shares_data[shareID]
                            )
                    )
                    // return an object that contains all the shares (array of shares or undefined if array is empty) and a true boolean for active search
                    return {
                        shares:
                            sharesToDisplayArray &&
                            sharesToDisplayArray.length > 0
                                ? sharesToDisplayArray
                                : undefined,
                        hasActiveSearchCriteria: true,
                    }
                } else if (
                    carPrivateSharesOwned.active_display &&
                    carPrivateSharesOwned.active_display?.activeFilter
                ) {
                    return {
                        shares: sharesToDisplayArray
                            ? sharesToDisplayArray
                            : undefined,
                        hasActiveSearchCriteria: true,
                    }
                } else {
                    // if no search criteria, extract all car shares
                    carPrivateSharesOwned.entity_owned_direct_shares_ids.map(
                        (shareID: string, index: number) =>
                            sharesToDisplayArray.push(
                                carPrivateSharesOwned!
                                    .entity_owned_direct_shares_data[shareID]
                            )
                    )
                    // return an object that contains all the shares (array of shares or undefined if array is empty) and a false boolean for active search
                    return {
                        shares:
                            sharesToDisplayArray &&
                            sharesToDisplayArray.length > 0
                                ? sharesToDisplayArray
                                : undefined,
                        hasActiveSearchCriteria: false,
                    }
                }
                // if car has no shares
            } else return { shares: undefined, hasActiveSearchCriteria: false }
        }

        let directSharesToDisplay: IDirectShareOwnedItem[] | undefined =
            directSharesToDisplayExtractor().shares

        let hasActiveSearchCriteria: boolean =
            directSharesToDisplayExtractor().hasActiveSearchCriteria

        return (
            <>
                <Container data-attr={dataCyId && dataCyId}>
                    {!this.props.noCarTitle ? (
                        <>
                            <CarTitle>{car.title}</CarTitle>
                            <HorizontalLine />
                        </>
                    ) : (
                        <NoCarTitle />
                    )}
                    <TitleWrapper>
                        <Row>
                            <SectionHeaderV2
                                isbold
                                icon="v2_add"
                                hasActionIcon={false}
                            >
                                Shareable Link
                            </SectionHeaderV2>
                            <Tag isActive={hasCarShare || has_garage_share}>
                                {hasCarShare || has_garage_share
                                    ? 'active'
                                    : 'inactive'}
                            </Tag>
                        </Row>
                    </TitleWrapper>
                    <Description>
                        A public link allows you to share your car with anyone
                        you'd like. You can remove access to this link at any
                        time.
                    </Description>

                    <div
                        style={{
                            paddingTop: has_garage_share ? '5px' : '20px',
                        }}
                    />
                    {has_garage_share ? <CopyTxt>Car-Only</CopyTxt> : null}

                    {areCarSharesLoading ? (
                        <LoaderElasticThreeDots />
                    ) : hasCarShare ? (
                        <>
                            <LinkWrapper
                                onClick={() => {
                                    this.toggleTooltip('car')
                                    navigator.clipboard.writeText(
                                        car.public_share_owned &&
                                            car.public_share_owned.url
                                            ? car.public_share_owned.url
                                            : ''
                                    )
                                }}
                            >
                                <LinkTxt>
                                    {calculate_link_width(car_link)}
                                </LinkTxt>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CopyIcon src={copy_icon} />

                                    <Tooltip
                                        title="Copied!"
                                        open={this.state.showCarTooltip}
                                        placement="bottom"
                                        color={'#2a969ad3'}
                                    />
                                </div>
                            </LinkWrapper>
                            <div style={{ paddingTop: '20px' }} />
                            <QrCodeBtn
                                txt="qr code"
                                height="42px"
                                iconSize="22px"
                                alwaysHasText
                                width="150px"
                                onClick={() => openQrCodeModal('car_only')}
                            />
                            <div
                                style={{
                                    paddingTop: has_garage_share
                                        ? '20px'
                                        : '30px',
                                }}
                            />
                            <BtnBlue
                                href={
                                    car &&
                                    car.public_share_owned &&
                                    car.public_share_owned.url
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                Preview Car Profile
                            </BtnBlue>
                            <div style={{ paddingTop: '15px' }} />
                            <BtnRed
                                onClick={() =>
                                    togglePublicLinkDeactivConfModal({
                                        isOpen: true,
                                    })
                                }
                            >
                                Deactivate Shareable Link
                            </BtnRed>
                            <div
                                style={{
                                    paddingTop: !has_garage_share
                                        ? '30px'
                                        : '10px',
                                }}
                            />
                        </>
                    ) : (
                        <motion.div whileTap={{ scale: 1.05 }}>
                            {!hasCarShare && !has_garage_share ? (
                                <div style={{ paddingTop: '10px' }} />
                            ) : null}
                            <ButtonAtom
                                onClick={() => {
                                    createCarPublicShare(car.id)
                                }}
                                theme="lowercase-white-background"
                                height="45px"
                                width="90vw"
                                fontSize={13}
                            >
                                <div style={{ textTransform: 'capitalize' }}>
                                    Generate Car-Only Shareable Link
                                </div>
                            </ButtonAtom>

                            {!has_garage_share ? (
                                <div style={{ paddingTop: '50px' }} />
                            ) : null}
                        </motion.div>
                    )}

                    {has_garage_share ? (
                        <>
                            <div style={{ paddingTop: '10px' }} />
                            {garage &&
                            garage.public_share_owned &&
                            garage.public_share_owned.isActive ? (
                                <CopyTxt>Car Via Garage</CopyTxt>
                            ) : null}

                            <LinkWrapper
                                onClick={() => {
                                    this.toggleTooltip('garage')
                                    navigator.clipboard.writeText(
                                        garage.public_share_owned &&
                                            garage.public_share_owned.url
                                            ? `${garage.public_share_owned.url}/car/${car.id}`
                                            : ''
                                    )
                                }}
                            >
                                <LinkTxt>
                                    {garage &&
                                        garage.public_share_owned &&
                                        calculate_link_width(garage_link)}
                                </LinkTxt>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <CopyIcon src={copy_icon} />

                                    <Tooltip
                                        title="Copied!"
                                        open={this.state.showGarageTooltip}
                                        placement="bottom"
                                        color={'#2a969ad3'}
                                    />
                                </div>
                            </LinkWrapper>
                            <div style={{ paddingTop: '20px' }} />
                            <QrCodeBtn
                                txt="qr code"
                                height="42px"
                                iconSize="22px"
                                alwaysHasText
                                width="150px"
                                onClick={() =>
                                    openQrCodeModal('car_via_garage')
                                }
                            />
                            <div style={{ paddingTop: '40px' }} />
                        </>
                    ) : null}

                    <Row>
                        <SectionHeaderV2
                            isbold
                            icon="v2_add"
                            onClick={() => this.onAddShareClick()}
                            hasActionIcon={true}
                            width="100%"
                            id="car_privately_shared_mobile"
                        >
                            Direct Shares
                        </SectionHeaderV2>
                        <FiltersContainer>
                            <GreyIconButton
                                onClick={() =>
                                    this.setState({
                                        isSortBottomSheetOpen: true,
                                    })
                                }
                                variant="sort"
                                isActive={
                                    sharesDisplayState &&
                                    sharesDisplayState.activeSort &&
                                    sharesDisplayState.activeSort.length > 0
                                        ? true
                                        : false
                                }
                                sortingCriteria={
                                    sharesDisplayState &&
                                    sharesDisplayState.activeSort
                                        ? sharesDisplayState.activeSort
                                        : 'created_at'
                                }
                                isDisabled={carHasDirectShares === false}
                            />
                            <FilterButtonWrapper
                                $content={
                                    sharesDisplayState &&
                                    sharesDisplayState.activeFilter &&
                                    sharesDisplayState.activeFilter.length
                                }
                            >
                                <GreyIconButton
                                    onClick={() =>
                                        this.setState({
                                            isFilterBottomSheetOpen: true,
                                        })
                                    }
                                    variant="filter"
                                    isActive={
                                        sharesDisplayState &&
                                        sharesDisplayState.activeFilter &&
                                        sharesDisplayState.activeFilter.length >
                                            0
                                            ? true
                                            : false
                                    }
                                    isDisabled={carHasDirectShares === false}
                                />
                            </FilterButtonWrapper>
                        </FiltersContainer>
                    </Row>
                    <div style={{ paddingTop: '10px' }} />

                    <Row>
                        <Description>
                            A direct share allows you to privately share your
                            car only with the people you choose. You can delete
                            a direct share at any time.
                        </Description>
                    </Row>
                    {isCarSharedViaGarage && (
                        <>
                            <div style={{ paddingTop: '30px' }} />
                            <Row>
                                <Description>
                                    This car is directly shared via a garage
                                    share.
                                </Description>
                            </Row>
                            <div style={{ paddingTop: '10px' }} />
                            <ButtonAtom
                                theme="naked-text-arrow"
                                color="#5EC3CA"
                                fontSize={16}
                                onClick={() =>
                                    this.props.history.push(
                                        `/sharing/with-others?entity_id=garage`
                                    )
                                }
                            >
                                View Garage Direct Shares
                            </ButtonAtom>
                        </>
                    )}

                    <div style={{ paddingTop: '30px' }} />

                    {directSharesToDisplay ? (
                        directSharesToDisplay.map(
                            (share: IDirectShareOwnedItem, index: number) => (
                                <DirectShareRowMobile
                                    share={share}
                                    carid={car.id}
                                    key={`direct_share_mobile_${index}`}
                                    // navigateFn={() =>
                                    //     this.props.history.push(
                                    //         `/sharing/with-others/cars/${car.id}/edit/${shareID}`
                                    //     )
                                    // }
                                    index={index}
                                    isLast={
                                        car &&
                                        car.private_shares_owned
                                            ?.entity_owned_direct_shares_ids &&
                                        car.private_shares_owned
                                            ?.entity_owned_direct_shares_ids
                                            .length - 1
                                            ? true
                                            : false
                                    }
                                    actionsData={{
                                        addUserToShare: () =>
                                            this.props.history.push(
                                                `/sharing/with-others/car/${car.id}/edit-share?shareid=${share.id}&active_tab_id=recipients`
                                            ),

                                        settings: () =>
                                            this.props.history.push(
                                                `/sharing/with-others/car/${car.id}/edit-share?shareid=${share.id}&active_tab_id=settings`
                                            ),
                                        delete: () =>
                                            this.props.toggleShareDeletionConfirmation(
                                                {
                                                    entity_type: 'car',
                                                    entity_id: car.id,
                                                    share_id: share.id,
                                                    isOpen: true,
                                                }
                                            ),
                                    }}
                                />
                            )
                        )
                    ) : carHasDirectShares && hasActiveSearchCriteria ? (
                        <NoFilterResults
                            text="No results containing all your search terms were found."
                            variant={'desktop'}
                        />
                    ) : (
                        <>
                            <div style={{ paddingTop: '20px' }} />
                            <AddInfoBannerMobile
                                onClick={() => this.onAddShareClick()}
                                action="add"
                                width="100%"
                                height="70px"
                                placeholder="add a car-only direct share "
                            />
                        </>
                    )}

                    {directSharesToDisplay && (
                        <>
                            <div style={{ paddingTop: '20px' }} />
                            <AddInfoBannerMobile
                                onClick={() => this.onAddShareClick()}
                                action="add"
                                width="100%"
                                height="70px"
                                placeholder="add a car-only direct share"
                            />
                        </>
                    )}

                    <div style={{ paddingTop: '80px' }} />

                    <DeletionConfirmationBottomSheet
                        customHeight="340px"
                        isOpen={publicLinkDeactivationConfirmationModal}
                        toggle={() =>
                            togglePublicLinkDeactivConfModal({
                                isOpen: false,
                            })
                        }
                        action={() =>
                            deletePublicLink({
                                entity_id: car && car.id ? car.id : '',
                                share_id:
                                    car &&
                                    car.public_share_owned &&
                                    car.public_share_owned.id
                                        ? car.public_share_owned.id
                                        : '',
                            })
                        }
                        actionCopy="Confirm"
                        titleCopy="Are you sure?"
                        detailsCopy="Once deactivated, the link and associated QR code will no longer give access to your car. If you decide to share your car in the future, this will generate a new shareable link and a different QR code, with your previous QR code no longer working."
                    />
                </Container>
                <FilterBottomSheetMenuMobile
                    activeCriteria={sharesDisplayState?.activeFilter}
                    criteriaData={categoryData}
                    sendFiltersToApi={(selected: ISharingPermissionIDS[]) => {
                        handleChange_shares_filter({
                            carid: car.id,
                            filterIDs: selected,
                        })
                    }}
                    isFilterBottomSheetMenuOpen={
                        this.state.isFilterBottomSheetOpen
                    }
                    toggle={() =>
                        this.setState({ isFilterBottomSheetOpen: false })
                    }
                    customHeight="60vh"
                    ipadCustomHeight="40vh"
                    notCapitalised
                />
                <PrivateSharesSortingMobile
                    actions_items={[
                        {
                            id: 'created_at',
                            text: 'Created at',
                            action: () => {
                                handleChange_shares_sort({
                                    carid: car.id,
                                    sortID: 'created_at',
                                })
                            },
                        },
                        {
                            id: 'updated_at',
                            text: 'Updated at',
                            action: () =>
                                handleChange_shares_sort({
                                    carid: car.id,
                                    sortID: 'updated_at',
                                }),
                        },
                        {
                            id: 'alphabetical',
                            text: 'Alphabetical',
                            action: () =>
                                handleChange_shares_sort({
                                    carid: car.id,
                                    sortID: 'alphabetical',
                                }),
                        },
                    ]}
                    toggle={() =>
                        this.setState({ isSortBottomSheetOpen: false })
                    }
                    isPrivateSharesSortingOpen={
                        this.state.isSortBottomSheetOpen
                    }
                    activeSort={
                        sharesDisplayState && sharesDisplayState?.activeSort
                            ? sharesDisplayState?.activeSort
                            : ''
                    }
                />
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(SingleCarShareBodyMobile)
)
