// NOTE
// DO NOT USE dynamic string operations(like template string) as action type property.
// see more details: https://github.com/piotrwitek/typesafe-actions#--the-actions
// login
export const SIGNIN_USER_REQUEST = '@@signin/REQUEST'
export const SIGNIN_USER_SUCCESS = '@@signin/SUCCESS'
export const SIGNIN_USER_ERROR = '@@signin/ERROR'
// logout
export const LOGOUT_USER_REQUEST = '@@logout/REQUEST'
export const LOGOUT_USER_SUCCESS = '@@logout/SUCCESS'
export const LOGOUT_USER_ERROR = '@@logout/ERROR'
// register
export const REGISTER_USER_REQUEST = '@@register/REQUEST'
export const REGISTER_USER_SUCCESS = '@@register/SUCCESS'
export const REGISTER_USER_ERROR = '@@register/ERROR'

// social auth
export const SOCIAL_AUTH_USER_REQUEST = '@@social_auth/REQUEST'
// add phone
export const ADD_PHONE_REQUEST = '@@phone/add/REQUEST'
export const ADD_PHONE_SUCCESS = '@@phone/add/SUCCESS'
export const ADD_PHONE_ERROR = '@@phone/add/ERROR'

// edit account info

export const EDIT_ACCOUNT_INFO_REQUEST = '@@user_account/edit/REQUEST'
export const EDIT_ACCOUNT_INFO_SUCCESS = '@@user_account/edit/SUCCESS'
export const EDIT_ACCOUNT_INFO_ERROR = '@@user_account/edit/ERROR'

// add address
export const ADD_ADDRESS_REQUEST = '@@address/add/REQUEST'
export const ADD_ADDRESS_SUCCESS = '@@address/add/SUCCESS'
export const ADD_ADDRESS_ERROR = '@@address/add/ERROR'

export const ADD_ADDRESS_ERROR_NO_MODAL = '@@address/add/ERROR_no_modal'

// add categories
export const ADD_CATEGORIES_REQUEST = '@@category/add/REQUEST'
export const ADD_CATEGORIES_SUCCESS = '@@category/add/SUCCESS'
export const ADD_CATEGORIES__ERROR = '@@category/add/ERROR'
// verify email
export const VERIFY_REQUEST = '@@verify/REQUEST'
export const VERIFY_SUCCESS = '@@verify/SUCCESS'
export const VERIFY_ERROR = '@@verify/ERROR'
// verify code from reset password
export const VERIFY_RESET_PASSWORD_CODE_REQUEST =
    '@@reset_password/verify_code/REQUEST'
export const VERIFY_RESET_PASSWORD_CODE_SUCCESS =
    '@@reset_password/verify_code//SUCCESS'
export const VERIFY_RESET_PASSWORD_CODE_ERROR =
    '@@reset_password/verify_code//ERROR'
// send-invitation
export const SEND_INVITATION_REQUEST = '@@sendinvitation/REQUEST'
export const SEND_INVITATION_SUCCESS = '@@sendinvitation/SUCCESS'
export const SEND_INVITATION_ERROR = '@@sendinvitation/ERROR'
// reset password
export const RESET_PASSWORD_REQUEST = '@@reset_password/REQUEST'
export const RESET_PASSWORD_SUCCESS = '@@reset_password/SUCCESS'
export const RESET_PASSWORD_ERROR = '@@reset_password/ERROR'
// verified reset password
export const RESET_PASSWORD_VERIFIED_REQUEST =
    '@@reset_password/verified/REQUEST'
export const RESET_PASSWORD_VERIFIED_SUCCESS =
    '@@reset_password/verified/SUCCESS'
export const RESET_PASSWORD_VERIFIED_ERROR = '@@reset_password/verified/ERROR'

// get current user data
export const GET_CURRENT_USER_DATA_REQUEST = '@@getcurrentuserdata/REQUEST'
export const GET_CURRENT_USER_DATA_SUCCESS = '@@getcurrentuserdata/SUCCESS'
export const GET_CURRENT_USER_DATA_ERROR = '@@getcurrentuserdata/ERROR'

// reset error state
export const RESET_ERROR_STATE = '@@reseterrorstate/SUCCESS'

// update user data general info
export const UPDATE_USER_DATA_GENERAL_INFO_REQUEST =
    '@@updateuserdata_generalinfo/REQUEST'
export const UPDATE_USER_DATA_GENERAL_INFO_SUCCESS =
    '@@updateuserdata_generalinfo/SUCCESS'
export const UPDATE_USER_DATA_GENERAL_INFO_ERROR =
    '@@updateuserdata_generalinfo/ERROR'

// setting token check 1: the token is being refreshed. No need to do it again.
export const REFRESH_TOKEN_REQUEST = '@@refresh_token/REQUEST'
export const REFRESH_TOKEN_SUCCESS = '@@refresh_token/SUCCESS'
export const REFRESH_TOKEN_ERROR = '@@refresh_token/ERROR'

// setting token check 2: the token has been refreshed once already in this session.
// on this, adding a count later could handle retries.
export const REFRESH_TOKEN_ONCE_REQUEST = '@@refresh_token_once/REQUEST'
export const REFRESH_TOKEN_ONCE_SUCCESS = '@@refresh_token_once/SUCCESS'
export const REFRESH_TOKEN_ONCE_ERROR = '@@refresh_token_once/ERROR'

export const ADD_LABELS_TO_USER_SUCCESS = '@@user_labels/create/SUCCESS'
export const UPDATE_USER_TIME_ZONE = '@@user/time_zone/SUCCESS'

export const GET_USER_DIRECT_SHARES_RECEIVED_SUCCESS =
    '@@user/direct_shares_received/SUCCESS'
export const GET_USER_DIRECT_SHARES_RECEIVED_REQUEST =
    '@@user/direct_shares_received/REQUEST'
export const GET_USER_DIRECT_SHARES_RECEIVED_ERROR =
    '@@user/direct_shares_received/ERROR'

export const POPULATE_OWNED_SHARES_DATA_SUCCESS =
    '@@user/populate_owned_shares_data/SUCCESS'
export const POPULATE_OWNED_SHARES_DATA_REQUEST =
    '@@user/populate_owned_shares_data/REQUEST'
export const POPULATE_OWNED_SHARES_DATA_ERROR =
    '@@user/populate_owned_shares_data/ERROR'

export const UPDATE_SHARE_RECEIVED_STATUS_REQUEST =
    '@@user/update_share_received_status/REQUEST'
export const UPDATE_SHARE_RECEIVED_STATUS_SUCCESS =
    '@@user/update_share_received_status/SUCCESS'
export const UPDATE_SHARE_RECEIVED_STATUS_ERROR =
    '@@user/update_share_received_status/ERROR'

export const DELETE_ACCOUNT_REQUEST = '@@user/delete_account/REQUEST'
export const DELETE_ACCOUNT_SUCCESS = '@@user/delete_account/SUCCESS'
export const DELETE_ACCOUNT_ERROR = '@@user/delete_account/ERROR'

export const ADD_TO_REFRESH_TOKEN_RETRIES_COUNT =
    '@@user/add_to_refresh_token_retries_count'

export const RESET_REFRESH_TOKEN_RETRIES_COUNT =
    '@@user/reset_refresh_token_retries_count'

export const SET_USER_ARCHIVED_CARS_SUCCESS = '@@user/archives/success'
export const GET_USER_ARCHIVED_CARS_REQUEST = '@@user/archives/request'
export const GET_USER_ARCHIVED_CARS_ERROR = '@@user/archives/error'

export const UPDATE_USER_TASKS_COUNT_ON_CREATION =
    '@@user/update/tasks_count_on_creation'
export const UPDATE_USER_TASKS_COUNT_ON_DELETION =
    '@@user/update/tasks_count_on_deletion'

export const LIST_USER_HANDOVERS_REQUEST = '@@user/list_handovers/REQUEST'
export const LIST_USER_HANDOVERS_SUCCESS = '@@user/list_handovers/SUCCESS'
export const LIST_USER_HANDOVERS_ERROR = '@@user/list_handovers/ERROR'

export const UPDATE_ARCHIVED_CARS = '@@user/update_archived_cars/SUCCESS'
