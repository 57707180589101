import * as React from 'react'
import styled from 'styled-components'
import { SortableContainer } from 'react-sortable-hoc'

import { device } from '../../templates/displays/devices'

import DraggableItemEditable from '../../atoms/draggable/draggableItemEditable'
import { IHighlightedFact } from 'entityModels'

const arrayMove = require('array-move')

const ListContainer = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    overflow-y: auto;
    @media ${device.mobile} {
        overflow-y: scroll;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style-type: disc;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
    }
`

const SortableContainerCustom = SortableContainer(
    ({ children }: { children: React.ReactNode }) => (
        <ListContainer>{children}</ListContainer>
    )
)

type Props = {
    data_object: IObject
    ids_list: string[]
    formikprops: any
    giveNewArrayOrder?: any
}

interface IObject {
    [key: string]: IHighlightedFact
}

type State = {
    items: IHighlightedFact[]
}

const sortList = (list: IHighlightedFact[]) => {
    return list.sort(
        (a: IHighlightedFact, b: IHighlightedFact) => a.order - b.order
    )
}

class DragNSortEditable extends React.Component<Props, State> {
    state = {
        items: [],
    }

    componentDidMount() {
        let list = this.props.ids_list.map((id: string, index) => {
            return this.props.data_object[id]
        })

        let ordered_list = sortList(list)

        this.setState({
            items: ordered_list,
        })
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        // redux action here too
        this.setState(({ items }: any) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }))
        this.props.giveNewArrayOrder &&
            this.props.giveNewArrayOrder(this.state.items)
    }

    render() {
        const { items } = this.state
        const { formikprops } = this.props
        return (
            <React.Fragment>
                {items.length > 1 && (
                    <SortableContainerCustom
                        onSortEnd={this.onSortEnd}
                        useDragHandle
                    >
                        {items.map((item: any, index: number) => (
                            <React.Fragment
                                key={`${item?.id}_${index}_drag_n_sort_desktop`}
                            >
                                <DraggableItemEditable
                                    formikprops={formikprops}
                                    index={index}
                                    item={item}
                                    key={index}
                                />
                            </React.Fragment>
                        ))}
                    </SortableContainerCustom>
                )}
            </React.Fragment>
        )
    }
}

export default DragNSortEditable
