import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const QuestionStyled = styled.h1`
    font-family: Lato;

    @media ${device.mobile} {
        text-align: start;
        color: black;
        font-size: 16px;
        line-height: 1.8;
        margin-bottom: 20px;
        padding-left: 7vw;
        padding-right: 5vw;
    }

    @media ${device.desktop} {
        font-size: 18px;
        text-align: start;
        color: black;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
        margin-bottom: 50px;
        margin-left: 10.5vw;
        margin-top: 20px;
    }
`

const Line = styled.div`
    @media ${device.mobile} {
        display: none;
    }

    @media ${device.desktop} {
        border-bottom: 1px solid grey;
        flex: 1;
        margin-left: 30px;
        transform: translateY(-10px);
    }
`

interface ITextProps {
    children?: React.ReactNode
}

const Question: React.SFC<ITextProps> = (props) => (
    <QuestionStyled>
        {props.children} <Line />
    </QuestionStyled>
)
Question.defaultProps = {}
export default Question
