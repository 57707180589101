import { IRedirectCardsCopy } from 'journeyInterfaces'

export const journeyRedirectCardsCopy: IRedirectCardsCopy = {
    car_profile: {
        title: 'Get started bringing your car online',
        description:
            "Unlock the peace of mind and benefits of having access to all your car's information wherever you go!",
    },
    history_file: {
        title: "Bring your car's history online",
        description:
            "Create an organised and searchable system for all your car's documents, expenses and history. Access from anywhere and start protecting important service information for future generations.",
    },
    insurance: {
        title: 'Learn about Custodian Insurance',
        description:
            'Discover how Custodian is working hard to create a new type of insurance which serves enthusiast owners.',
    },
    share_your_car: {
        title: 'Car ownership is best enjoyed together ',
        description:
            'Discover how Custodian can help you share your car with others both securely and easily.',
    },
    past_cars: {
        title: "Don't forget your past cars",
        description: `Learn about how the previously owned cars section of the Custodian platform works.`,
    },
    handover: {
        title: "Preserve and pass on your car's history and provenance",
        description: 'Secondary line of text with short description',
    },
    sell_your_car: {
        title: 'Are you considering selling your car?',
        description:
            'Learn how Custodian can help you get a great price and prepare your car for the perfect handover.',
    },
    your_profile: {
        title: 'Your profile is private and secure by default',
        description:
            'Set up your Custodian profile and learn about how you are in control of your information.',
    },
}

export const journeyInsuranceBenefitsStepCopy = [
    {
        title: 'Salvage Retention',
        description:
            'Keep your cherished salvaged parts if your car is written off',
    },
    {
        title: 'Static Show Cover',
        description:
            'Take your car to a rally, show or event with peace of mind.',
    },
    {
        title: 'Personal Choice of Repairer',
        description: 'You decide who repairs your vehicle after an accident.',
    },
    {
        title: 'Agreed Valuation',
        description:
            'Protect your car from fluctuations in its market value with an agreed valuation.',
    },
    {
        title: 'Car Club Discounts',
        description:
            'Discounts if you are an active and certified member of a motoring club or society.',
    },
    {
        title: 'Limited Mileage Discounts',
        description:
            'Discounts if you are not going far or using your car often.',
    },
]

export const journeyInsuranceHowItWorksCardsStepCopy = [
    {
        title: 'Tap the info icon for help.',
        description:
            'The questions we ask are carefully curated to help us get you ideal coverage based on your circumstances. Use the info icon next to questions for guidance.',
    },
    {
        title: 'See what’s missing.',
        description:
            "At anytime during your application, click the 'Show missing information' button to be guided through all outstanding questions.",
    },
    {
        title: 'Enter step-by-step mode.',
        description:
            'You can fill in your application in any order, but if you would like a step-by-step experience, click the play button at anytime.',
    },
    {
        title: 'Pause and resume anytime',
        description:
            "Life can be unpredictable, but your insurance application doesn't have to be. Pause the application process at any point and resume it later when you have more time or information available.",
    },
]
