import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'

import DragNSortEditable from '../../../molecules/dragnsort/dragNSortEditable'
import {
    IHighlightedFact,
    IHighlightedFactsObject,
    IUpdateHF,
} from 'entityModels'
import { ModalTitle } from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
}

const EditFormContainerDesktop = styled.section<IStyle>`
    display: none;

    @media ${device.large_desktop} {
        margin-top: 18vh !important;
        max-height: 80vh;
    }

    @media ${device.desktop} {
        margin-top: 10vh !important;
    }

    @media ${device.smallest_laptops} {
        margin-top: 10vh !important;
    }

    @media ${device.beyond_ipad_mobile} {
        display: flex;
        display: -webkit-flex;
        /* flex: 1; */
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-width: 690px;
        margin-top: 15vh;
        padding-bottom: 40px;
        padding-top: 30px;
        background-color: ${(props) =>
            colours[props.$theme].modal_side_sheet_pop_over};
        color: ${(props) => colours[props.$theme].text_strong};
        border-radius: 5px;
        position: relative;
        overflow-y: auto;
        margin-bottom: 50px;
        /* width */
        ::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: ${(props) => colours[props.$theme].background_default};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${(props) => colours[props.$theme].border_muted};
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #888;
        }
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
`

const LargePadding = styled.div`
    padding-top: 40px;
`
export const DescriptionHeader = styled.div<IStyle>`
    padding-left: 3vw;
    padding-top: 3px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 15px;
    text-align: left;
    padding-right: 10vw;

    line-height: 26px;

    font-family: Lato-Light;

    @media ${device.large_desktop} {
        padding-left: 45px;
        padding-right: 60px;
    }
    padding-right: 5vw;
    padding-bottom: 38px;

    max-width: 820px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    [key: string]: string
    new_ordered_array?: any
}

interface OtherProps {
    close: () => void
    data_object: IHighlightedFactsObject
    submitFunc: (data: IUpdateHF) => void
    ids_list: string[]
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        data_object,
        ids_list,
        handleChange,
        setFieldValue,
    } = props

    const { theme } = useThemes()

    return (
        <EditFormContainerDesktop $theme={theme}>
            <InnerFormPadding>
                <DescriptionHeader $theme={theme}>
                    <ModalTitle $theme={theme}>Key Moments & Facts</ModalTitle>
                    Share a few interesting facts about your car's provenance,
                    specification or condition; highlights from your
                    custodianship; or special milestones.
                    <div
                        style={{
                            paddingTop: '8px',
                            fontStyle: 'italic',
                            fontSize: 14,
                        }}
                    >
                        [up to 200 characters]
                    </div>
                </DescriptionHeader>

                <DragNSortEditable
                    data_object={data_object}
                    ids_list={ids_list}
                    giveNewArrayOrder={(items: any) => {
                        setFieldValue('new_ordered_array', items)
                    }}
                    formikprops={{
                        values: values,
                        handleChange: handleChange,
                        setFieldValue: setFieldValue,
                    }}
                />
            </InnerFormPadding>
            <LargePadding />
            <div style={{ padding: '0 35px', width: '100%' }}>
                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={props.handleSubmit}
                />
            </div>
        </EditFormContainerDesktop>
    )
}

const CarHighlightsEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        [props.ids_list[0]]: props.data_object[props.ids_list[0]]
            ? props.data_object[props.ids_list[0]].text
            : '',
        [props.ids_list[1]]: props.data_object[props.ids_list[1]].text
            ? props.data_object[props.ids_list[1]].text
            : '',
        [props.ids_list[2]]: props.data_object[props.ids_list[2]].text
            ? props.data_object[props.ids_list[2]].text
            : '',
        [props.ids_list[3]]: props.data_object[props.ids_list[3]].text
            ? props.data_object[props.ids_list[3]].text
            : '',
        [props.ids_list[4]]: props.data_object[props.ids_list[4]].text
            ? props.data_object[props.ids_list[4]].text
            : '',
    }),

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (values.new_ordered_array !== undefined) {
            let new_array_with_order_change: IHighlightedFact[] = []
            values.new_ordered_array.forEach(
                (item: IHighlightedFact, index: any) => {
                    let values_obj: Values = values
                    if (values_obj[item.id] !== undefined) {
                        let newItem: IHighlightedFact = {
                            id: item.id,
                            text: values[item.id]
                                ? values[item.id].length < 200
                                    ? values[item.id]
                                    : ''
                                : '',
                            order: index + 1,
                        }

                        return new_array_with_order_change.push(newItem)
                    }
                }
            )

            props.submitFunc({
                carid: props.carid,
                highlightedFacts: new_array_with_order_change,
            })
        } else {
            let new_array_without_order_change: any[] = []

            props.ids_list.forEach((id: string, index: number) => {
                let item = (props.data_object[id] = {
                    ...props.data_object[id],
                    text:
                        values[props.ids_list[index]] !== undefined
                            ? values[props.ids_list[index]]
                            : '',
                })

                new_array_without_order_change.push(item)
            })

            props.submitFunc({
                carid: props.carid,
                highlightedFacts: new_array_without_order_change,
            })
        }

        props.close()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarHighlightsEditFormDesktop)
