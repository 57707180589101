import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
// import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// import SelectSearchBarCarInfoMobile from './selectSearchBarCarInfoMobile'
import BlueBorderSelectInput from '../../atoms/blueBorderedInputs/blueBorderSelectInput'
import blue_cross from '../../../public/assets/icons/blue_cross.svg'
import blue_bin from '../../../public/assets/icons/blue_bin.svg'
import BlueBorderNumberInput from '../../atoms/blueBorderedInputs/blueBorderNumberInput'
import { motion } from 'framer-motion'
import { IdropdownObject } from '../../../redux/localdata/remindersLocalData'
import * as Yup from 'yup'

import Expander from '../../atoms/expander/expander'
import StyledFormError from '../../templates/styledcomponents/styledformerrormessage'
import Faded from '../../templates/animated/faded'
import {
    IIsDateValidPayload,
    isDateValid,
} from '../../pages/tasks/taskReminders'

const RowWrapper = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`

const InputWrapper = styled.section`
    width: 85%;
    display: flex;
`

const RemovalIcon = styled(motion.img)<IStyle>`
    height: ${(props) => (props.variant === 'mobile' ? '15px' : '18px')};
    cursor: pointer;
`

interface IStyle {
    variant: string
}

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    unit_selected_value: string
    number_value: number
}

interface OtherProps {
    item: any
    dataCyId: string
    onCrossClick?: any
    variant: 'mobile' | 'desktop'
    optionsIDsList: string[]
    optionsData: IdropdownObject
    customHandleChange?: (
        p: NumberAndSelectValuesPayload,
        hasError?: boolean
    ) => any
    isNumberDisabled?: boolean
    isSelectDisabled?: boolean
    noChangeAllowed?: boolean
    minDate?: string
    maxDate?: string
}

export type NumberAndSelectValuesPayload = {
    selected?: string
    nvalue?: number
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,

        // data,
        errors,
        setFieldValue,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        onCrossClick,
        variant,
        optionsIDsList,
        optionsData,
        customHandleChange,
        isNumberDisabled,
        isSelectDisabled,
        noChangeAllowed,
        dataCyId,
    } = props

    const handleNumberAnswerChange = (e: any) => {
        if (e.target.value === typeof String) {
            setFieldValue('number_value', parseInt(e.target.value))
        } else setFieldValue('number_value', e.target.value)

        customHandleChange &&
            customHandleChange(
                { nvalue: e.target.value },
                errors && errors.number_value ? true : false
            )
    }
    const handleUnitAnswerChange = (name: any) => {
        setFieldValue('unit_selected_value', name)
        customHandleChange &&
            customHandleChange(
                { selected: name },
                errors && errors.number_value ? true : false
            )
    }

    const isNumberValid = () => {
        let payload: IIsDateValidPayload = {
            type_val: values.unit_selected_value,
            nVal: values.number_value,
            minDate: props.minDate,
            dueDate: props.maxDate ? props.maxDate : '',
        }

        return isDateValid(payload)
    }

    return (
        <>
            <RowWrapper>
                <InputWrapper>
                    <BlueBorderNumberInput
                        name="unit_selected_value"
                        width="20%"
                        height="50px"
                        value={values.number_value}
                        onChange={handleNumberAnswerChange}
                        disabled={isNumberDisabled}
                        noChangeAllowed={noChangeAllowed}
                        hasError={errors && errors.number_value ? true : false}
                        dataCyId={dataCyId}
                        minNumber={0}
                        maxNumber={
                            isNumberValid() === false
                                ? values.number_value
                                : undefined
                        }
                    />
                    <div style={{ paddingRight: '10px' }} />
                    <BlueBorderSelectInput
                        optionsIDsList={optionsIDsList}
                        optionsData={optionsData}
                        handleChange={handleUnitAnswerChange}
                        value={values.unit_selected_value}
                        isDisabled={isSelectDisabled}
                        noChangeAllowed={noChangeAllowed}
                        bg={
                            variant === 'mobile'
                                ? undefined
                                : 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        }
                    />
                </InputWrapper>
                <RemovalIcon
                    variant={variant}
                    src={variant === 'mobile' ? blue_cross : blue_bin}
                    onClick={onCrossClick}
                    whileTap={{ scale: 1.1 }}
                />
            </RowWrapper>
            <div style={{ transform: ' translateX(-10px)' }}>
                <Expander height={errors && errors.number_value ? 'auto' : 0}>
                    <Faded>
                        <StyledFormError>{errors.number_value}</StyledFormError>
                    </Faded>
                </Expander>
            </div>
        </>
    )
}

const NumberAndSelectWithRemovalIconMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        unit_selected_value: props.item.value_start_unit
            ? props.item.value_start_unit
            : props.item && props.item.type
            ? props.item.type
            : '-',
        number_value: props.item.value_start_number
            ? props.item.value_start_number
            : 1,
    }),
    enableReinitialize: true,
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                number_value: Yup.string().test(
                    'Check if date is valid',
                    reminderErrorDateCopy,
                    () => {
                        let payload: IIsDateValidPayload = {
                            type_val: values.unit_selected_value,
                            nVal: values.number_value,
                            minDate: props.minDate,
                            dueDate: props.maxDate ? props.maxDate : '',
                        }

                        return isDateValid(payload)
                    }
                ),
            })
        })
    },
    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

let reminderErrorDateCopy = "Invalid: Reminder can't be set in the past."

export default connect(
    mapStateToProps,
    dispatchProps
)(NumberAndSelectWithRemovalIconMobile)
