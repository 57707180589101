export type ICarHandover_structure_base = {
    owner: {
        existing: string | undefined // userid
        existing_display_name: undefined | string
        new: {
            email: string | undefined
            first_name: string | undefined
            last_name: string | undefined
            display_name: string | undefined
        }
    }
    handover: {
        full: boolean
        custom: {
            history_file: number[]
            gallery: number
        }
    }
}

const O_TimelineEntryTypesArray_handover_number = {
    2: {
        id: 2,
        name: 'Service',
    },
    4: {
        id: 4,
        name: 'Maintenance',
    },
    8: {
        id: 8,
        name: 'Restoration',
    },
    16: { id: 16, name: 'Admin' },
    32: { id: 32, name: 'Insurance' },
    64: { id: 64, name: 'History' },
    128: { id: 128, name: 'Events' },
    256: { id: 256, name: 'Other' },
} as const

export const total_hf_enum_n = 510

export type I_TimelineEntryTypesArray_handover =
    (typeof O_TimelineEntryTypesArray_handover_number)[keyof typeof O_TimelineEntryTypesArray_handover_number]

export const TimelineEntryHandoverTypesData: I_TimelineEntryTypesArray_handover[] =
    [
        { id: 2, name: 'Service' },
        {
            id: 4,
            name: 'Maintenance',
        },
        {
            id: 8,
            name: 'Restoration',
        },
        { id: 16, name: 'Admin' },
        { id: 32, name: 'Insurance' },
        { id: 64, name: 'History' },
        { id: 128, name: 'Events' },
        { id: 256, name: 'Other' },
    ]

// export const GalleryOptionsHandoverArray = [1536, 512, 0]

const O_GalleryOptionsArray_handover_number = {
    1536: {
        id: 1536,
        name: 'All photos',
    },
    512: {
        id: 512,
        name: 'Featured photos',
    },
    0: {
        id: 0,
        name: 'No Photos',
    },
} as const

export type I_GalleryOptionsArray_handover =
    (typeof O_GalleryOptionsArray_handover_number)[keyof typeof O_GalleryOptionsArray_handover_number]

export const GalleryOptionsHandoverArr: I_GalleryOptionsArray_handover[] = [
    { id: 1536, name: 'All photos' },
    { id: 512, name: 'Featured photos' },
    { id: 0, name: 'No Photos' },
]

export const total_photos_enum_n = 1536

export type props_handoverForm_customData = {
    handleSelectedIDHistoryFile: (selectedID: number) => any
    handleSelectedGalleryValue: (selectedID: number) => any
    setActiveStep: (s: 'owner' | 'handover' | 'success') => any
    handoverFormData: ICarHandover_structure_base
}

export const info_box_handover_copy = {
    full: 'All car data (including photos / digital history files) will be transferred to the new owner.',
    custom: 'Select data for handover.',
    new: 'Fill up some basic information about the person you want to invite',
}
