// ApexBodyRichContentTextItemDesktop

import { IPrismicFieldPropsToState } from 'apexModels'
import React from 'react'

import styled from 'styled-components'
import { device } from '../../../../../../templates/displays/devices'

const Wrapper = styled.div`
    @media ${device.beyond_ipad_mobile} {
    }
    line-height: 36px;
    color: var(--text-strong, #1a1a1a);
`
const Normal = styled.div`
    font-family: Lato;
    font-size: 19px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 20px;
    }

    padding-bottom: 20px;
`

const Strong = styled.div`
    font-family: Lato-Bold;
    font-size: 20px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 21px;
    }

    padding-bottom: 20px;
    padding-top: 20px;
`

const Header = styled.h3`
    font-family: 'Lato-Light';
    font-size: 30px;
    padding-bottom: 18px;
    padding-top: 32px;
    color: var(--text-strong, #1a1a1a);
`

const NormalSpan = styled.span`
    font-family: Lato;
    font-size: 19px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 20px;
    }

    padding-bottom: 20px;
`

const StrongSpan = styled.span`
    font-family: Lato-Bold;
    font-size: 20px;

    @media ${device.large_desktop} {
        font-size: 21px;
    }

    padding-bottom: 20px;
    padding-top: 20px;
    color: var(--text-strong, #1a1a1a);
`

const HeaderSpan = styled.span`
    font-family: 'Lato-Light';
    font-size: 30px;
    padding-bottom: 18px;
    padding-top: 32px;
    color: var(--text-strong, #1a1a1a);
`

type Props = {
    item: IPrismicFieldPropsToState
}

class ApexBodyRichContentTextItemDesktop extends React.Component<Props, {}> {
    render() {
        let { item } = this.props

        let renderRichContentElem = () => {
            if (item.url && item.text) {
                return (
                    <NormalSpan>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={item.url}
                        >{`${item.text} `}</a>
                    </NormalSpan>
                )
            } else if (
                item.text &&
                !item.text.includes('https://') &&
                !item.text.includes('http://') &&
                !item.text.includes('www.')
            ) {
                return (
                    <Wrapper>
                        {item.text &&
                            item.textStyle !== 'strong' &&
                            item.textStyle !== 'heading' &&
                            item.textStyle !== 'em' && (
                                <Normal>{item.text}</Normal>
                            )}

                        {item.text && item.textStyle === 'strong' && (
                            <Strong>{item.text}</Strong>
                        )}

                        {item.text && item.textStyle === 'heading' && (
                            <Header>{item.text}</Header>
                        )}

                        {item.text && item.textStyle === 'em' && (
                            <div style={{ fontStyle: 'italic' }}>
                                <Normal>{item.text}</Normal>
                            </div>
                        )}
                    </Wrapper>
                )
            } else {
                return (
                    <Wrapper>
                        {item.text &&
                            item.text
                                .split(/[\u202F\u00A0]/)
                                .join(' ')
                                .split(' ')
                                .map((word) =>
                                    word &&
                                    (word.includes('https://') ||
                                        word.includes('http://') ||
                                        word.includes('www.')) ? (
                                        <span>
                                            {item.textStyle !== 'strong' &&
                                            item.textStyle !== 'heading' &&
                                            item.textStyle !== 'em' ? (
                                                <NormalSpan>
                                                    <a
                                                        href={word}
                                                    >{`${word} `}</a>
                                                </NormalSpan>
                                            ) : item.textStyle === 'strong' ? (
                                                <StrongSpan>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={word}
                                                    >{`${word} `}</a>
                                                </StrongSpan>
                                            ) : item.textStyle === 'heading' ? (
                                                <HeaderSpan>
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={word}
                                                    >{`${word} `}</a>
                                                </HeaderSpan>
                                            ) : (
                                                item.textStyle === 'em' && (
                                                    <span
                                                        style={{
                                                            fontStyle: 'italic',
                                                        }}
                                                    >
                                                        <NormalSpan>
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={word}
                                                            >{`${word} `}</a>
                                                        </NormalSpan>
                                                    </span>
                                                )
                                            )}
                                        </span>
                                    ) : (
                                        <span>
                                            {item.textStyle !== 'strong' &&
                                            item.textStyle !== 'heading' &&
                                            item.textStyle !== 'em' ? (
                                                <NormalSpan>{`${word} `}</NormalSpan>
                                            ) : item.textStyle === 'strong' ? (
                                                <StrongSpan>{`${word} `}</StrongSpan>
                                            ) : item.textStyle === 'heading' ? (
                                                <HeaderSpan>{word}</HeaderSpan>
                                            ) : (
                                                item.textStyle === 'em' && (
                                                    <span
                                                        style={{
                                                            fontStyle: 'italic',
                                                        }}
                                                    >
                                                        <NormalSpan>{`${word} `}</NormalSpan>
                                                    </span>
                                                )
                                            )}
                                        </span>
                                    )
                                )}
                    </Wrapper>
                )
            }
        }

        return <>{renderRichContentElem()}</>
    }
}

export default ApexBodyRichContentTextItemDesktop
