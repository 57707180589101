import React from 'react'
import { message } from 'antd'
import styled from 'styled-components'
import '../../antd.css'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import { connect } from 'react-redux'
// import { setSearchMode } from '../../../../../../redux/editForms/reducer'
import { RootState } from 'typesafe-actions'
import { Combobox } from '../../../../../atoms/select'

interface IStyle {
    border?: string
    borderBottom?: string
    backgroundColor?: string
}

const SelectBarContainer = styled.div<IStyle>`
    display: flex;
    position: relative;
    width: 100%;
`

// const dispatchProps = {
//     setSearchMode: (set: boolean) => setSearchMode(set),
// }

function mapStateToProps(state: RootState) {
    return {}
}

export type Props = {
    fetching: any
    value: any
    data: any
    fetchItem: any
    handleChange: any
    optionsList: any
    item?: any | undefined
    width?: string | undefined
    addAnItemFunc?: any | undefined
    dropdownTargetId?: string | undefined
    formCurrentValue?: string | undefined
    allowAdd?: boolean | undefined
    isDisabled?: boolean | undefined
    fontSize?: string | undefined
    placeholder?: string | undefined
    helperText?: string
    dataCyId?: string | undefined
    theme?: 'timeline' | undefined
    sendId?: boolean
    isKeyValue?: boolean
    backgroundColor?: string
    field_id?: string
    height?: string
    borderColor?: string
}

type StateSelect = {
    valueTyped: string
}

const errorWarning = (errorText: string) => {
    message.error(errorText, 2)
}

class SingleSelectMobile extends React.Component<Props, StateSelect> {
    state = {
        valueTyped: '',
    }

    handleSearch = (valueTyped: any) => {
        return this.setState({ valueTyped: valueTyped })
    }

    render() {
        const {
            value,
            data,
            handleChange,
            fetchItem,
            optionsList,
            item,
            addAnItemFunc,
            dropdownTargetId,
            formCurrentValue,
            allowAdd,
            isDisabled,
            placeholder,
            helperText,
            sendId,
            isKeyValue,
            backgroundColor,
            field_id,
            height,
            borderColor,
            // setSearchMode,
        } = this.props

        const { valueTyped } = this.state

        const options =
            data && data.length > 0
                ? data.map((di: any) => {
                      let d = di.item

                      if (d) {
                          return {
                              value: isKeyValue
                                  ? d
                                  : sendId
                                  ? d.uid
                                  : d.uid &&
                                    (field_id === 'make' ||
                                        field_id === 'model' ||
                                        field_id === 'car_model' ||
                                        field_id === 'car_make')
                                  ? d.uid
                                  : d.name,
                              label: isKeyValue ? d : d.name,
                          }
                      } else {
                          return {
                              value: isKeyValue
                                  ? di
                                  : sendId
                                  ? di.uid
                                  : di.uid &&
                                    (field_id === 'make' ||
                                        field_id === 'model' ||
                                        field_id === 'car_model' ||
                                        field_id === 'car_make')
                                  ? di.uid
                                  : di.name,
                              label: isKeyValue ? di : di.name,
                          }
                      }
                  })
                : optionsList
                ? optionsList.map((d: any) => ({
                      value: isKeyValue
                          ? d
                          : sendId
                          ? d.uid
                          : d.uid &&
                            (field_id === 'make' ||
                                field_id === 'model' ||
                                field_id === 'car_model' ||
                                field_id === 'car_make')
                          ? d.uid
                          : d.name,
                      label: isKeyValue ? d : d.name,
                  }))
                : null

        switch (this.props.theme) {
            case 'timeline':
                return (
                    <SelectBarContainer
                        id="formmobileditscrolltechinfo"
                        border={'none'}
                        borderBottom={'1px solid #666666 '}
                    >
                        <Combobox
                            backgroundColor={backgroundColor}
                            borderColor={borderColor}
                            options={options}
                            value={value[value.length - 1]}
                            defaultValue={item.answer ? item.answer : '-'}
                            helperText={helperText}
                            placeholder={placeholder}
                            disabled={isDisabled ? isDisabled : false}
                            onInputKeyDown={(e: any) => {
                                if (data.length === 0) {
                                    if (
                                        addAnItemFunc !== undefined &&
                                        dropdownTargetId !== undefined &&
                                        allowAdd
                                    ) {
                                        e = e || window.event
                                        if (e.keyCode === 13) {
                                            if (formCurrentValue) {
                                                if (
                                                    formCurrentValue !==
                                                    valueTyped
                                                ) {
                                                    if (valueTyped) {
                                                        let trimmed_val =
                                                            valueTyped.replace(
                                                                /\s/g,
                                                                ''
                                                            )

                                                        if (
                                                            trimmed_val.length >=
                                                                1 &&
                                                            trimmed_val.length <
                                                                70
                                                        ) {
                                                            let trimmed_val_final =
                                                                valueTyped.trim()
                                                            handleChange(
                                                                trimmed_val_final
                                                            )

                                                            addAnItemFunc(
                                                                dropdownTargetId,
                                                                trimmed_val_final
                                                            )
                                                        } else {
                                                            if (
                                                                trimmed_val.length >=
                                                                70
                                                            ) {
                                                                errorWarning(
                                                                    'Name must be less than 70 characters'
                                                                )
                                                            } else {
                                                                errorWarning(
                                                                    'Please enter a valid name.'
                                                                )
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }}
                            fallbackContent={
                                allowAdd ? (
                                    <ButtonAtom
                                        onClick={(e: any) => {
                                            if (valueTyped) {
                                                let trimmed_val =
                                                    valueTyped.replace(
                                                        /\s/g,
                                                        ''
                                                    )
                                                if (
                                                    trimmed_val.length >= 1 &&
                                                    trimmed_val.length < 70
                                                ) {
                                                    e.preventDefault()

                                                    let trimmed_val_final =
                                                        valueTyped.trim()

                                                    addAnItemFunc(
                                                        dropdownTargetId,
                                                        trimmed_val_final
                                                    )
                                                    handleChange(
                                                        trimmed_val_final
                                                    )
                                                } else {
                                                    if (
                                                        trimmed_val.length >= 70
                                                    ) {
                                                        errorWarning(
                                                            'Name must be less than 70 characters'
                                                        )
                                                    } else {
                                                        errorWarning(
                                                            'Please enter a valid name.'
                                                        )
                                                    }
                                                }
                                            }
                                        }}
                                        theme="naked-text"
                                        width="100%"
                                        alignLeft={true}
                                    >
                                        add {valueTyped}
                                    </ButtonAtom>
                                ) : null
                            }
                            onSearch={(value: string) => {
                                fetchItem(value)
                                this.handleSearch(value)
                            }}
                            onChange={handleChange}
                        />
                    </SelectBarContainer>
                )
            default:
                return (
                    <SelectBarContainer id="formmobileditscrolltechinfo">
                        <Combobox
                            borderColor={borderColor}
                            field_id={field_id}
                            height={height}
                            backgroundColor={backgroundColor}
                            options={options}
                            value={value[value.length - 1]}
                            defaultValue={item.answer ? item.answer : '-'}
                            helperText={helperText}
                            placeholder={placeholder}
                            disabled={isDisabled ? isDisabled : false}
                            onInputKeyDown={(e: any) => {
                                if (data.length === 0) {
                                    if (
                                        addAnItemFunc !== undefined &&
                                        dropdownTargetId !== undefined &&
                                        allowAdd
                                    ) {
                                        e = e || window.event
                                        if (e.keyCode === 13) {
                                            if (formCurrentValue) {
                                                if (
                                                    formCurrentValue !==
                                                    valueTyped
                                                ) {
                                                    if (valueTyped) {
                                                        let trimmed_val =
                                                            valueTyped.replace(
                                                                /\s/g,
                                                                ''
                                                            )

                                                        if (
                                                            trimmed_val.length >=
                                                                1 &&
                                                            trimmed_val.length <
                                                                70
                                                        ) {
                                                            let trimmed_val_final =
                                                                valueTyped.trim()
                                                            handleChange(
                                                                trimmed_val_final
                                                            )

                                                            addAnItemFunc(
                                                                dropdownTargetId,
                                                                trimmed_val_final
                                                            )
                                                        } else {
                                                            if (
                                                                trimmed_val.length >=
                                                                70
                                                            ) {
                                                                errorWarning(
                                                                    'Name must be less than 70 characters'
                                                                )
                                                            } else {
                                                                errorWarning(
                                                                    'Please enter a valid name.'
                                                                )
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }}
                            fallbackContent={
                                allowAdd ? (
                                    <ButtonAtom
                                        onClick={(e: any) => {
                                            if (valueTyped) {
                                                let trimmed_val =
                                                    valueTyped.replace(
                                                        /\s/g,
                                                        ''
                                                    )
                                                if (
                                                    trimmed_val.length >= 1 &&
                                                    trimmed_val.length < 70
                                                ) {
                                                    e.preventDefault()

                                                    let trimmed_val_final =
                                                        valueTyped.trim()

                                                    addAnItemFunc(
                                                        dropdownTargetId,
                                                        trimmed_val_final
                                                    )
                                                    handleChange(
                                                        trimmed_val_final
                                                    )
                                                } else {
                                                    if (
                                                        trimmed_val.length >= 70
                                                    ) {
                                                        errorWarning(
                                                            'Name must be less than 70 characters'
                                                        )
                                                    } else {
                                                        errorWarning(
                                                            'Please enter a valid name.'
                                                        )
                                                        if (
                                                            trimmed_val.length >=
                                                                1 &&
                                                            trimmed_val.length <
                                                                70
                                                        ) {
                                                            e.preventDefault()

                                                            let trimmed_val_final =
                                                                valueTyped.trim()

                                                            addAnItemFunc(
                                                                dropdownTargetId,
                                                                trimmed_val_final
                                                            )
                                                            handleChange(
                                                                trimmed_val_final
                                                            )
                                                        } else {
                                                            if (
                                                                trimmed_val.length >=
                                                                70
                                                            ) {
                                                                errorWarning(
                                                                    'Name must be less than 70 characters'
                                                                )
                                                            } else {
                                                                errorWarning(
                                                                    'Please enter a valid name.'
                                                                )
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                        theme="naked-text"
                                        width="100%"
                                        alignLeft={true}
                                    >
                                        add {valueTyped}
                                    </ButtonAtom>
                                ) : null
                            }
                            onSearch={(value: string) => {
                                fetchItem(value)
                                this.handleSearch(value)
                            }}
                            onChange={handleChange}
                        />
                    </SelectBarContainer>
                )
        }
    }
}

export default connect(mapStateToProps, {})(SingleSelectMobile)
