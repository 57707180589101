// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts" />
/// <reference types="googlemaps" />
import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ButtonAtom from '../../atoms/Button/ButtonAtom'

const MobileField = styled.input`
    margin-top: 10px;
    width: 70vw;
    margin-left: 30px;
    width: 80vw;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    border-bottom: #616161 1px solid;
    background-color: transparent !important;
    height: 35px;
`

const MobileSearchField = styled.input`
    background-color: #fbfbfb;
    height: 45px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    width: 80vw;
    margin-left: 6vw;
    padding-left: 15px;
`

const MobileAddressFormContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 20px;
`

const MobileAddressFormSubContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
`

type Props = {
    street_number: any
    postal_town: any
    route: any
    postal_code: any
    country: any
    onSubmit: any
    handleChange: any
    isFormValid: any
}

class AddressFormMobile extends React.Component<Props, {}> {
    // STEPS:

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.

    componentDidMount() {
        document.getElementsByTagName('input')[0].focus()
    }

    render() {
        const {
            street_number,
            route,
            postal_town,
            postal_code,
            country,
            onSubmit,
            handleChange,
            isFormValid,
        } = this.props
        return (
            <div style={{ marginTop: '30px' }}>
                <MobileSearchField
                    id="autocompletemobile"
                    placeholder="Search your address"
                    type="text"
                />

                <MobileAddressFormContainer id="address-search-mobile">
                    <MobileAddressFormSubContainer>
                        <MobileField
                            id="street_number"
                            name="street_number"
                            type="text"
                            placeholder="Street number  /  House name"
                            value={street_number}
                            onChange={handleChange}
                            tabIndex={1}
                            onKeyPress={(e: any) =>
                                e.which === 13 && onSubmit()
                            }
                        />
                    </MobileAddressFormSubContainer>

                    <MobileAddressFormSubContainer>
                        <MobileField
                            id="route"
                            type="text"
                            name="route"
                            placeholder="Street address"
                            value={route}
                            onChange={handleChange}
                            tabIndex={2}
                            onKeyPress={(e: any) =>
                                e.which === 13 && onSubmit()
                            }
                        />
                    </MobileAddressFormSubContainer>
                    <MobileAddressFormSubContainer>
                        <MobileField
                            id="postal_town"
                            type="text"
                            name="postal_town"
                            placeholder="city"
                            value={postal_town}
                            onChange={handleChange}
                            tabIndex={3}
                            onKeyPress={(e: any) =>
                                e.which === 13 && onSubmit()
                            }
                        />
                    </MobileAddressFormSubContainer>

                    <MobileAddressFormSubContainer>
                        <MobileField
                            id="postal_code"
                            type="text"
                            placeholder="zip code"
                            name="postal_code"
                            value={postal_code}
                            onChange={handleChange}
                            tabIndex={4}
                            onKeyPress={(e: any) =>
                                e.which === 13 && onSubmit()
                            }
                        />
                    </MobileAddressFormSubContainer>
                    <MobileAddressFormSubContainer>
                        <MobileField
                            id="country"
                            type="text"
                            placeholder="country"
                            name="country"
                            value={country}
                            onChange={handleChange}
                            tabIndex={5}
                            onKeyPress={(e: any) =>
                                e.which === 13 && onSubmit()
                            }
                        />
                    </MobileAddressFormSubContainer>
                </MobileAddressFormContainer>

                <div
                    style={{
                        marginTop: '50px',
                        marginLeft: '7vw',
                        opacity: isFormValid === false ? 0.5 : 1,
                    }}
                >
                    <ButtonAtom
                        theme="secondary"
                        icon="email"
                        onClick={(e: any) => {
                            e.preventDefault()
                            onSubmit()
                        }}
                        disabled={isFormValid === false ? true : false}
                    >
                        Submit Address
                    </ButtonAtom>
                </div>
            </div>
        )
    }
}

export default connect(null, null)(AddressFormMobile)
