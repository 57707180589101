import React from 'react'
// import debounce from "lodash/debounce";
import Fuse from 'fuse.js'
import SingleSelectMobile from './singleSelectMobile'
import { debounce } from 'lodash'
import SingleSelectOnlyMatchedMobile from './singleSelectOnlyMatchedMobile'
import StandaloneSearchSelectMobile from './standaloneSearchSelectMobile'

export type Props = {
    items: any[]
    item: any
    width?: string
    maxWidth?: string
    handleSelectChange?: any
    addAnItemFunc?: any
    dropdownTargetId?: string
    formCurrentValue?: string
    allowAdd?: boolean
    isDisabled?: boolean
    fontSize?: string
    placeholder?: string
    helperText?: string
    onlyShowMatch?: boolean
    dataCyId?: string | undefined
    theme?: 'timeline' | undefined
    customPlaceholder?: string
    sendId?: boolean
    isKeyValue?: boolean
    valueUpdatedFromExternalSource?: string | null
    isStandalonePage?: boolean
    backgroundColor?: string
    field_id?: string
    height?: string
    borderColor?: string
}

// not sure why now threw error. Removed specifications and error free.
// keeping this here in case .

// export type ItemsFuse = {
//   id: string;
//   value: string;
// };

// const options: Fuse.FuseOptions<ItemsFuse> = {
//   tokenize: true,
//   keys: ["value"]
// };

type LocalState = {
    data: any[]
    name: any[]
    fetching: boolean
}

class SelectSearchBarCarInfoMobile extends React.Component<Props, LocalState> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        this.fetchItem = debounce(this.fetchItem, 300)
    }

    state = {
        data: [],
        name: [],
        fetching: false,
    }

    lastFetchId = 0

    fetchItem = (name: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
        }

        let fuse: any = {}

        if (this.props.isKeyValue === true) {
            fuse = new Fuse(this.props.items, { threshold: 0.2 })
        } else {
            fuse = new Fuse(this.props.items, {
                keys: ['name', 'uid'],
                threshold: 0.2,
            })
        }

        let results: any = fuse.search(name)

        return this.setState({ data: results, fetching: false })
    }

    handleChange = (name: any, other?: string) => {
        this.setState({
            ...this.state,
            name: [...this.state.name, name],
            data: [],
            fetching: false,
        })
        this.props.handleSelectChange &&
            this.props.handleSelectChange(name, other)
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.valueUpdatedFromExternalSource !==
            prevProps.valueUpdatedFromExternalSource
        ) {
            this.props.valueUpdatedFromExternalSource &&
                this.setState({
                    ...this.state,
                    name: [
                        ...this.state.name,
                        this.props.valueUpdatedFromExternalSource,
                    ],
                    data: [],
                    fetching: false,
                })
        }
    }

    render() {
        const { fetching, name, data } = this.state
        const {
            item,
            width,
            addAnItemFunc,
            dropdownTargetId,
            formCurrentValue,
            allowAdd,
            isDisabled,
            fontSize,
            placeholder,
            helperText,
            onlyShowMatch,
            dataCyId,
            theme,
            maxWidth,
            customPlaceholder,
            sendId,
            isKeyValue,
            isStandalonePage,
            backgroundColor,
            field_id,
            height,
            borderColor,
        } = this.props

        return (
            <>
                {isStandalonePage && (
                    <StandaloneSearchSelectMobile
                        field_id={field_id}
                        backgroundColor={backgroundColor}
                        fetching={fetching}
                        value={name}
                        data={data}
                        fetchItem={this.fetchItem}
                        handleChange={this.handleChange}
                        optionsList={this.props.items}
                        item={item}
                        width={width && width}
                        addAnItemFunc={addAnItemFunc && addAnItemFunc}
                        dropdownTargetId={dropdownTargetId && dropdownTargetId}
                        formCurrentValue={formCurrentValue && formCurrentValue}
                        allowAdd={allowAdd && allowAdd}
                        isDisabled={isDisabled && isDisabled}
                        fontSize={fontSize && fontSize}
                        placeholder={placeholder && placeholder}
                        dataCyId={dataCyId}
                        theme={theme && theme}
                        sendId={sendId}
                        isKeyValue={isKeyValue}
                    />
                )}
                {!isStandalonePage && onlyShowMatch === true ? (
                    <SingleSelectOnlyMatchedMobile
                        backgroundColor={backgroundColor}
                        field_id={field_id}
                        fetching={fetching}
                        value={name}
                        data={data}
                        fetchItem={this.fetchItem}
                        handleChange={this.handleChange}
                        item={item}
                        width={width && width}
                        addAnItemFunc={addAnItemFunc && addAnItemFunc}
                        dropdownTargetId={dropdownTargetId && dropdownTargetId}
                        formCurrentValue={formCurrentValue && formCurrentValue}
                        allowAdd={allowAdd && allowAdd}
                        isDisabled={isDisabled && isDisabled}
                        fontSize={fontSize && fontSize}
                        placeholder={placeholder && placeholder}
                        helperText={helperText}
                        dataCyId={dataCyId}
                        maxWidth={maxWidth}
                        customPlaceholder={customPlaceholder}
                        sendId={sendId}
                    />
                ) : !isStandalonePage ? (
                    <SingleSelectMobile
                        field_id={field_id}
                        backgroundColor={backgroundColor}
                        fetching={fetching}
                        value={name}
                        data={data}
                        fetchItem={this.fetchItem}
                        handleChange={this.handleChange}
                        optionsList={this.props.items}
                        item={item}
                        width={width && width}
                        addAnItemFunc={addAnItemFunc && addAnItemFunc}
                        dropdownTargetId={dropdownTargetId && dropdownTargetId}
                        formCurrentValue={formCurrentValue && formCurrentValue}
                        allowAdd={allowAdd && allowAdd}
                        isDisabled={isDisabled && isDisabled}
                        fontSize={fontSize && fontSize}
                        placeholder={placeholder && placeholder}
                        helperText={helperText}
                        dataCyId={dataCyId}
                        theme={theme && theme}
                        sendId={sendId}
                        isKeyValue={isKeyValue}
                        height={height}
                        borderColor={borderColor}
                    />
                ) : null}
            </>
        )
    }
}

export default SelectSearchBarCarInfoMobile
