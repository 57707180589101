import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'typesafe-actions'

import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ICar, IGarageObject } from 'entityModels'
import { IisOpen, IUser } from 'myModels'
import {
    createCarPublicShareRequest,
    deleteCarPublicShareRequest,
    filterCarSharesDisplay_change_request,
    getCarDataByIdRequest,
    getOwnedDirectSharesCarRequest,
    getPublicSharesAllUserGarageCarsRequest,
    getPublicSharesCarRequest,
    IFilterCarSharesDisplay_change_request,
    ISearchQueryCarSharesDisplay_change_request,
    ISortCarSharesDisplay_change_request,
    resetSearchQueryCarShareDisplay_request,
    searchQueryCarSharesDisplay_change_request,
    sortCarSharesDisplay_change_request,
} from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CarShareTopHeaderDesktop from '../../molecules/carShareTopHeader/carShareTopHeaderDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import SingleCarShareBodyDesktop from '../../molecules/singleCarShareBody/singleCarShareBodyDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SingleCarShareBodyMobile from '../../molecules/singleCarShareBody/singleCarShareBodyMobile'
import { getOwnedDirectSharesGarageRequest } from '../../../redux/entities/garages/actions/loadingActions'
import * as ReactScroll from 'react-scroll'
import { IDeleteShareReq } from '../../../redux/entities/cars/actions/actions'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import Faded from '../../templates/animated/faded'
import QrCodeModalDesktop from '../../molecules/qrCodeModal/qrCodeModalDesktop'
import QrCodeModalMobile from '../../molecules/qrCodeModal/qrCodeModalMobile'
import QrCodeCustomiseStickerModalDesktop from '../../molecules/qrCodeCustomiseStickerModal/qrCodeCustomiseStickerModalDesktop'
import { IStickerPredefinedThemesState } from '../../../redux/localdata/stickerPredefinedThemes/reducer'
import QrCodeStickerBottomSheetMobile from '../../molecules/qrCodeCustomiseStickerModal/qrCodeStickerBottomSheetMobile'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isCarLoading: state.entities.carsData.loading,
        carsData: state.entities.carsData.cars,
        isUserLoading: state.user.loading,
        garagesData: state.entities.garagesData.garages,
        qrCodeModal: state.editForms && state.editForms.formsData.qrCodeModal,
        qrCodeStickerModal:
            state.editForms && state.editForms.formsData.qrCodeStickerModal,
        predefinedQrCodeStickerThemes: state.localdata.stickerPredefinedThemes,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),

    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),

    sortCarSharesDisplay_change_request: (
        p: ISortCarSharesDisplay_change_request
    ) => sortCarSharesDisplay_change_request(p),

    filterCarSharesDisplay_change_request: (
        p: IFilterCarSharesDisplay_change_request
    ) => filterCarSharesDisplay_change_request(p),

    searchQueryCarSharesDisplay_change_request: (
        p: ISearchQueryCarSharesDisplay_change_request
    ) => searchQueryCarSharesDisplay_change_request(p),
    resetSearchQueryCarShareDisplay_request: (p: string) =>
        resetSearchQueryCarShareDisplay_request(p),
    createCarPublicShareRequest: (carid: string) =>
        createCarPublicShareRequest(carid),

    deleteCarPublicShareRequest: (p: IDeleteShareReq) =>
        deleteCarPublicShareRequest(p),
    toggleQrCodeModal: (payload: { isOpen: boolean; id: string | undefined }) =>
        editFormsActions.toggleQrCodeModal(payload),
    toggleQrCodeStickerModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => editFormsActions.toggleQrCodeStickerModal(payload),
    getPublicSharesAllUserGarageCarsRequest: (p: string) =>
        getPublicSharesAllUserGarageCarsRequest(p),
    getOwnedDirectSharesCarRequest: (p: string) =>
        getOwnedDirectSharesCarRequest(p),
    getOwnedDirectSharesGarageRequest: (p: string) =>
        getOwnedDirectSharesGarageRequest(p),
    getPublicSharesCarRequest: (p: string) => getPublicSharesCarRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    carsData: { [key: string]: ICar }
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isCarLoading: boolean
    getUserDataRequest: () => void
    getCarDataByIdRequest: any
    setCurrentCar: any
    searchQueryCarSharesDisplay_change_request: (
        p: ISearchQueryCarSharesDisplay_change_request
    ) => void
    filterCarSharesDisplay_change_request: (
        p: IFilterCarSharesDisplay_change_request
    ) => void
    sortCarSharesDisplay_change_request: (
        p: ISortCarSharesDisplay_change_request
    ) => void
    resetSearchQueryCarShareDisplay_request: (p: string) => void
    garagesData: IGarageObject
    createCarPublicShareRequest: (p: string) => void
    deleteCarPublicShareRequest: (p: IDeleteShareReq) => void
    qrCodeModal: IisOpen
    qrCodeStickerModal: IisOpen
    toggleQrCodeModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => void
    toggleQrCodeStickerModal: (payload: {
        isOpen: boolean
        id: string | undefined
    }) => void
    predefinedQrCodeStickerThemes: IStickerPredefinedThemesState

    getPublicSharesAllUserGarageCarsRequest: (p: string) => void
    getOwnedDirectSharesCarRequest: (p: string) => void
    getOwnedDirectSharesGarageRequest: (p: string) => void
    getPublicSharesCarRequest: (p: string) => void
    isCollapsed: boolean
    setInitialLandingUrl: (str: string) => void
}

class CarShares extends React.Component<Props, {}> {
    getNecessaryData = () => {
        let userData = this.props.userLoggedIn
        let garageid =
            userData && userData.owns_garage && userData.owns_garage.uid

        const q_params = new URLSearchParams(this.props.location.search)
        let carid = q_params.get('carid')

        if (carid && !this.props.carsData[carid]) {
            this.props.getCarDataByIdRequest(carid)
        }

        if (userData && carid && garageid) {
            this.props.getPublicSharesCarRequest(carid)
            this.props.getOwnedDirectSharesCarRequest(carid)
            garageid && this.props.getOwnedDirectSharesGarageRequest(garageid)
        }
    }

    componentDidMount() {
        const q_params = new URLSearchParams(this.props.location.search)
        let carid = q_params.get('carid')
        this.props.setInitialLandingUrl(
            `/sharing/with-others/car?carid=${carid}`
        )
        // user stuff
        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        } else {
            carid && this.props.setCurrentCar(carid)

            this.getNecessaryData()
        }

        let id = q_params.get('id')

        if (id === 'car_private_m') {
            this.scrollToElement('car_privately_shared_mobile')
        }

        if (id === 'car_private_d') {
            this.scrollToElement('car_privately_shared_desktop')
        }

        let qr_id = q_params.get('qr_id')
        let openCarQrCodeModal = () =>
            this.props.toggleQrCodeModal({
                isOpen: true,
                id: 'car_only',
            })

        let openGarageQrCodeModal = () =>
            this.props.toggleQrCodeModal({
                isOpen: true,
                id: 'car_via_garage',
            })
        if (qr_id === 'car_only') {
            setTimeout(openCarQrCodeModal, 200)
        } else if (qr_id === 'car_via_garage') {
            setTimeout(openGarageQrCodeModal, 200)
        }
    }

    componentDidUpdate(prevProps: Props) {
        // user stuff
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            const q_params = new URLSearchParams(this.props.location.search)
            let carid = q_params.get('carid')
            carid && this.props.setCurrentCar(carid)
            this.getNecessaryData()
        }
    }

    scrollToElement = (elementName: string) => {
        ReactScroll.scroller.scrollTo(elementName, {
            duration: 500,
            delay: 300,
            smooth: true,
            offset: -70,
        })
    }

    render() {
        let {
            carsData,
            searchQueryCarSharesDisplay_change_request,
            filterCarSharesDisplay_change_request,
            sortCarSharesDisplay_change_request,
            resetSearchQueryCarShareDisplay_request,
            createCarPublicShareRequest,
            deleteCarPublicShareRequest,
            qrCodeModal,
            toggleQrCodeModal,
            qrCodeStickerModal,
            toggleQrCodeStickerModal,
            predefinedQrCodeStickerThemes,
            isCarLoading,
            isCollapsed,
        } = this.props

        const q_params = new URLSearchParams(this.props.location.search)
        let carid = q_params.get('carid')

        let current: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        let car_only_qr_code: string | undefined =
            current &&
            current.public_share_owned &&
            current.public_share_owned.qr_code_url
                ? current.public_share_owned.qr_code_url
                : undefined

        let car_via_garage_qr_code: string | undefined =
            current &&
            current.public_share_owned &&
            current.public_share_owned.qr_code_url_via_garage
                ? current.public_share_owned.qr_code_url_via_garage
                : undefined

        return (
            <React.Fragment>
                <DesktopDisplayOnly>
                    <CenteredPageWrapper fullwidth>
                        {isCarLoading ? (
                            <div
                                style={{
                                    position: 'relative',
                                    height: '30vh',
                                }}
                            >
                                <AdjustableLoader isLoading={isCarLoading} />
                            </div>
                        ) : (
                            current &&
                            this.props.userLoggedIn &&
                            this.props.garagesData && (
                                <WindowPageTemplate
                                    hasPositionRelative
                                    isCollapsed={isCollapsed}
                                    stickyHeaderContent={
                                        <CarShareTopHeaderDesktop
                                            goBackUrl="/sharing/with-others"
                                            onDeleteClick={() => {}}
                                            goToNext={() => {}}
                                            goToPrevious={() => {
                                                this.props.history.push(
                                                    '/sharing/with-others'
                                                )
                                            }}
                                            carData={current}
                                            elementToScrollTo={null}
                                            removeUpDownNav
                                            customTitle={current.title}
                                        />
                                    }
                                >
                                    <SingleCarShareBodyDesktop
                                        handleChange_shares_search={
                                            searchQueryCarSharesDisplay_change_request
                                        }
                                        handleChange_shares_sort={
                                            sortCarSharesDisplay_change_request
                                        }
                                        handleChange_shares_filter={
                                            filterCarSharesDisplay_change_request
                                        }
                                        car={current}
                                        resetSearch={
                                            resetSearchQueryCarShareDisplay_request
                                        }
                                        garage={
                                            this.props.garagesData[
                                                this.props.userLoggedIn
                                                    .owns_garage.uid
                                            ]
                                        }
                                        createCarPublicShare={
                                            createCarPublicShareRequest
                                        }
                                        deletePublicLink={
                                            deleteCarPublicShareRequest
                                        }
                                        openQrCodeModal={(qr_id: string) =>
                                            this.props.toggleQrCodeModal({
                                                isOpen: true,
                                                id: qr_id,
                                            })
                                        }
                                    />
                                    {qrCodeModal.isOpen &&
                                        (car_via_garage_qr_code ||
                                            car_only_qr_code) && (
                                            <Faded>
                                                <ModalDisplayAbsolute
                                                    isOpen={qrCodeModal.isOpen}
                                                    toggle={() => {}}
                                                    top="10px"
                                                    left="0px"
                                                    right="20px"
                                                    bottom="10px"
                                                >
                                                    <QrCodeModalDesktop
                                                        qr_code_link={
                                                            qrCodeModal.id ===
                                                            'car_via_garage'
                                                                ? car_via_garage_qr_code
                                                                    ? car_via_garage_qr_code
                                                                    : ''
                                                                : car_only_qr_code
                                                                ? car_only_qr_code
                                                                : ''
                                                        }
                                                        qr_code_id={
                                                            'car_qr_code_to_export_desktop'
                                                        }
                                                        car_title={
                                                            current &&
                                                            current.title
                                                                ? current.title
                                                                : 'Untitled'
                                                        }
                                                        closeModal={() =>
                                                            this.props.toggleQrCodeModal(
                                                                {
                                                                    isOpen: false,
                                                                    id: undefined,
                                                                }
                                                            )
                                                        }
                                                        isGarage={
                                                            qrCodeModal.id ===
                                                            'car_via_garage'
                                                        }
                                                        onStickerBtnClick={() =>
                                                            toggleQrCodeStickerModal(
                                                                {
                                                                    isOpen: true,
                                                                    id: qrCodeModal.id,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </ModalDisplayAbsolute>
                                            </Faded>
                                        )}

                                    {qrCodeStickerModal.isOpen &&
                                        (car_via_garage_qr_code !== undefined ||
                                            car_only_qr_code !== undefined) && (
                                            <Faded>
                                                <ModalDisplayAbsolute
                                                    isOpen={
                                                        qrCodeStickerModal.isOpen
                                                    }
                                                    modalBackgroundColor="rgba(0, 0, 0, 0)"
                                                    toggle={() => {}}
                                                    top="10px"
                                                    left="0px"
                                                    right="20px"
                                                    bottom="10px"
                                                >
                                                    <QrCodeCustomiseStickerModalDesktop
                                                        qr_code_link={
                                                            qrCodeStickerModal.id ===
                                                            'car_via_garage'
                                                                ? car_via_garage_qr_code
                                                                    ? car_via_garage_qr_code
                                                                    : ''
                                                                : car_only_qr_code
                                                                ? car_only_qr_code
                                                                : ''
                                                        }
                                                        qr_code_id={
                                                            'car_qr_code_to_export_desktop'
                                                        }
                                                        car_title={
                                                            current &&
                                                            current.title
                                                                ? current.title
                                                                : 'Untitled'
                                                        }
                                                        closeModal={() =>
                                                            this.props.toggleQrCodeStickerModal(
                                                                {
                                                                    isOpen: false,
                                                                    id: undefined,
                                                                }
                                                            )
                                                        }
                                                        isGarage={
                                                            qrCodeStickerModal.id ===
                                                            'car_via_garage'
                                                        }
                                                        predefinedStickerThemes={
                                                            predefinedQrCodeStickerThemes
                                                        }
                                                    />
                                                </ModalDisplayAbsolute>
                                            </Faded>
                                        )}
                                </WindowPageTemplate>
                            )
                        )}
                    </CenteredPageWrapper>
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <PrivateShareReturnNav
                        customText="Shared with Others"
                        chevron
                        linkTo={'/sharing/with-others'}
                    />
                    <div style={{ paddingTop: '80px' }} />
                    {current &&
                        this.props.userLoggedIn &&
                        this.props.garagesData && (
                            <SingleCarShareBodyMobile
                                handleChange_shares_search={
                                    searchQueryCarSharesDisplay_change_request
                                }
                                handleChange_shares_sort={
                                    sortCarSharesDisplay_change_request
                                }
                                handleChange_shares_filter={
                                    filterCarSharesDisplay_change_request
                                }
                                car={current}
                                resetSearch={
                                    resetSearchQueryCarShareDisplay_request
                                }
                                createCarPublicShare={
                                    createCarPublicShareRequest
                                }
                                garage={
                                    this.props.garagesData[
                                        this.props.userLoggedIn.owns_garage.uid
                                    ]
                                }
                                deletePublicLink={deleteCarPublicShareRequest}
                                openQrCodeModal={(qr_id: string) =>
                                    this.props.toggleQrCodeModal({
                                        isOpen: true,
                                        id: qr_id,
                                    })
                                }
                            />
                        )}
                    {qrCodeModal.isOpen &&
                        (car_via_garage_qr_code !== undefined ||
                            car_only_qr_code !== undefined) && (
                            <Faded>
                                <ModalDisplay
                                    isOpen={qrCodeModal.isOpen}
                                    toggle={() =>
                                        toggleQrCodeModal({
                                            isOpen: false,
                                            id: undefined,
                                        })
                                    }
                                >
                                    <QrCodeModalMobile
                                        qr_code_link={
                                            qrCodeModal.id === 'car_via_garage'
                                                ? car_via_garage_qr_code
                                                    ? car_via_garage_qr_code
                                                    : ''
                                                : car_only_qr_code
                                                ? car_only_qr_code
                                                : ''
                                        }
                                        qr_code_id={
                                            'car_qr_code_to_export_desktop'
                                        }
                                        car_title={
                                            current && current.title
                                                ? current.title
                                                : 'Untitled'
                                        }
                                        closeModal={() =>
                                            this.props.toggleQrCodeModal({
                                                isOpen: false,
                                                id: undefined,
                                            })
                                        }
                                        isGarage={
                                            qrCodeModal.id === 'car_via_garage'
                                        }
                                        onStickerBtnClick={() => {
                                            toggleQrCodeStickerModal({
                                                isOpen: true,
                                                id: qrCodeModal.id,
                                            })
                                            this.props.toggleQrCodeModal({
                                                isOpen: false,
                                                id: undefined,
                                            })
                                        }}
                                    />
                                </ModalDisplay>
                            </Faded>
                        )}

                    <QrCodeStickerBottomSheetMobile
                        qr_code_link={
                            qrCodeStickerModal.id === 'car_via_garage'
                                ? car_via_garage_qr_code
                                    ? car_via_garage_qr_code
                                    : ''
                                : car_only_qr_code
                                ? car_only_qr_code
                                : ''
                        }
                        title={
                            current && current.title
                                ? current.title
                                : 'Untitled'
                        }
                        qr_code_id={'car_qr_code_to_export_mobile'}
                        closeBottomSheet={() =>
                            this.props.toggleQrCodeStickerModal({
                                isOpen: false,
                                id: undefined,
                            })
                        }
                        isBottomSheetOpen={qrCodeStickerModal.isOpen}
                        predefinedStickerThemes={predefinedQrCodeStickerThemes}
                    />
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CarShares))
