import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

export interface StyledProps {
    align: 'left' | 'center'
    isBigger?: boolean
    isItalic?: boolean
    isSmaller?: boolean
    removeIpadPadding?: boolean
    ipadAlign?: 'left' | 'center'
    fontSize?: string
}

const TextStyled = styled.div<StyledProps>`
    font-family: Lato;
    color: var(--text-default, #666666);
    width: 100%;
    font-style: ${(props) => (props.isItalic ? 'italic' : 'auto')};

    @media ${device.mobile} {
        text-align: ${(props) => (props.align ? `${props.align}` : `left`)};
        font-size: ${(props) =>
            props.fontSize
                ? props.fontSize
                : props.isBigger
                ? '17px'
                : props.isSmaller
                ? '14px'
                : '16px'};
        letter-spacing: 5e-5em;
        line-height: 24px;

        padding-left: ${(props) =>
            props.align === 'center' ? '12vw' : 'auto'};
        padding-bottom: ${(props) =>
            props.align === 'center' ? '0.2rem' : '0.5rem'};
        padding-left: ${(props) =>
            props.align === 'center' ? '12vw' : 'auto'};
        padding-right: ${(props) =>
            props.align === 'center' ? '12vw' : 'auto'};
    }

    @media ${device.desktop} {
        text-align: ${(props) => (props.align ? `${props.align}` : `left`)};
        font-size: ${(props) =>
            props.isBigger ? '1.2em' : props.isSmaller ? '0.85rem' : '1.2em'};
        margin-bottom: ${(props) =>
            props.align === 'center'
                ? '0.5rem'
                : props.isSmaller
                ? '0.3rem'
                : '0.7rem'};
    }

    @media ${device.ipad} {
        text-align: ${(props) =>
            props.ipadAlign ? `${props.ipadAlign}` : `center`};
        padding-left: ${(props) => (props.removeIpadPadding ? '0' : '10%')};
        padding-right: ${(props) => (props.removeIpadPadding ? '0' : '10%')};
        max-width: 80vw;
        font-size: 16px;
    }
`
// line-height: ${(props) => (props.isBigger ? '1.9' : 'auto')};
// line-height: ${(props) => (props.isBigger ? '1' : '1')};

interface ITextProps {
    children?: React.ReactNode
    align?: 'left' | 'center'
    isBigger?: boolean
    isSmaller?: boolean
    isItalic?: boolean
    removeIpadPadding?: boolean
    ipadAlign?: 'left' | 'center'
    fontSize?: string
}

const Paragraph: React.FunctionComponent<ITextProps> = (props) => (
    <React.Fragment>
        <TextStyled
            ipadAlign={props.ipadAlign && props.ipadAlign}
            removeIpadPadding={props.removeIpadPadding ? true : false}
            align={props.align ? props.align : 'left'}
            isBigger={props.isBigger && props.isBigger}
            isSmaller={props.isSmaller && props.isSmaller}
            isItalic={props.isItalic && props.isItalic}
            fontSize={props.fontSize && props.fontSize}
        >
            {props.children}
        </TextStyled>
    </React.Fragment>
)
Paragraph.defaultProps = {}
export default Paragraph
