import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'
import AddIcon from '../icons/components/addIcon'

interface IStyle {
    $theme: ITheme
    $isUppercase?: boolean | undefined
    $width?: string | undefined
    $height?: string
    $isactive?: boolean
    $backgroundColor?: string
}

const Button = styled.button<IStyle>`
    background-color: ${(props) =>
        props.$isactive
            ? colours[props.$theme].primary_strong
            : props.$backgroundColor
            ? props.$backgroundColor
            : colours[props.$theme].primary};
    border: none;
    outline: none;
    height: ${(props) => (props.$height ? props.$height : 'auto')};
    width: ${(props) => (props.$width ? props.$width : 'fit-content')};
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    transition: all 200ms;
    cursor: pointer;
    :hover {
        box-shadow: ${(props) =>
            ` 2px 4px 7px ${colours[props.$theme].shadow_strongest_09}`};
        transition: all 200ms;
    }

    @media ${device.mobile} {
    }
`

const Text = styled.div<IStyle>`
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${(props) => colours[props.$theme].background_default};
    @media ${device.mobile} {
    }
    font-family: 'Lato-semibold';
    font-size: 12px;
    text-transform: uppercase;
`

type Props = {
    onClick: any
    text?: string | undefined
    dataCyId?: string | undefined
    isUppercase?: boolean
    width?: string | undefined
    height?: string
    isactive?: boolean
    backgroundColor?: string
}

const AddLabelButton: React.FC<Props> = (props) => {
    let {
        onClick,
        text,
        isUppercase,
        width,
        height,
        dataCyId,
        isactive,
        backgroundColor,
    } = props

    const { theme } = useThemes()
    return (
        <Button
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            $width={width}
            $height={height}
            $isactive={isactive}
            $backgroundColor={backgroundColor}
            $theme={theme}
        >
            <Text $theme={theme} $isUppercase={isUppercase}>
                <AddIcon size="16" colour={colours[theme].background_default} />{' '}
                {text ? text : 'Add label'}
            </Text>
        </Button>
    )
}

export default AddLabelButton
