import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import chevron_to_left from '../../../public/assets/navigation/chevron_to_left.svg'
import { useHistory, useLocation } from 'react-router'
import CrossIcon from '../icons/crossIcon'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

const GoBackButton = styled.button<{ $theme: ITheme }>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    min-width: 130px;
    height: 48px;
    left: 55px;
    top: 68px;

    background-color: ${(props) => colours[props.$theme].background_default};
    color: ${(props) => colours[props.$theme].text_default};

    border: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
    border-radius: 4px;

    font-size: 16px;

    cursor: pointer;
    transition: all 150ms;
    :hover {
        background-color: ${(props) => colours[props.$theme].border_muted};
    }
`

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);
    height: 12px;
    margin-right: 6px;
`
type Props = {
    isReadOnly?: boolean
    carID?: string
    entryID?: string
    isSold?: boolean
    goBack?: () => any
    onClose?: () => any
}
const GoBackTopButtonDesktop = (props: RouteComponentProps & Props) => {
    const history = useHistory()
    const location = useLocation()

    const determineBackPath = () => {
        if (props.goBack !== undefined) {
            props.goBack()
            return
        }
        if (props.isReadOnly === true) {
            if (
                props.entryID &&
                history.location.pathname.includes(props.entryID) &&
                (history.location.pathname.includes('gallery') ||
                    history.location.pathname.includes('description'))
            ) {
                history.push(`/showroom/${props.entryID}`)
            } else {
                if (location.key) {
                    history.goBack()
                    return
                }
                if (props.isSold) {
                    history.push('/showroom/sold')
                } else history.push('/showroom')
            }
        } else if (props.carID) {
            if (location.key) {
                history.goBack()
                return
            } else history.replace(`/car/${props.carID}`)
        } else {
            if (location.key) {
                history.goBack()
                return
            } else history.goBack()
        }
    }

    const { theme } = useThemes()

    return (
        <GoBackButton
            $theme={theme}
            onClick={() => {
                if (props.onClose) {
                    props.onClose()
                } else determineBackPath()
            }}
        >
            {props.onClose ? (
                <CrossIcon size="15" />
            ) : (
                <Chevron src={chevron_to_left} />
            )}
            {props.onClose ? 'Close' : 'Go back'}
        </GoBackButton>
    )
}

export default withRouter(GoBackTopButtonDesktop)
