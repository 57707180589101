import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

export default function SystemThemeIcon({ size, color }: Props) {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.583496 2.54513C0.583496 1.46122 1.46171 0.583008 2.54562 0.583008H13.4547C14.5386 0.583008 15.4168 1.46122 15.4168 2.54513V10.4239C15.4168 11.5078 14.5386 12.386 13.4547 12.386H2.54562C1.46171 12.386 0.583496 11.5078 0.583496 10.4239V2.54513ZM2.54562 2.08301C2.29013 2.08301 2.0835 2.28965 2.0835 2.54513V10.4239C2.0835 10.6794 2.29013 10.886 2.54562 10.886H13.4547C13.7102 10.886 13.9168 10.6794 13.9168 10.4239V2.54513C13.9168 2.28965 13.7102 2.08301 13.4547 2.08301H2.54562Z"
                fill={color ?? colours[theme].icon}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.61377 14.0605C3.61377 13.6463 3.94956 13.3105 4.36377 13.3105H11.6365C12.0507 13.3105 12.3865 13.6463 12.3865 14.0605C12.3865 14.4748 12.0507 14.8105 11.6365 14.8105H4.36377C3.94956 14.8105 3.61377 14.4748 3.61377 14.0605Z"
                fill={color ?? colours[theme].icon}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.95665 11.5425C9.95664 11.5424 9.95666 11.5426 9.95665 11.5425C9.90498 11.1318 9.53027 10.8403 9.11946 10.8916C8.70844 10.943 8.4169 11.3178 8.46827 11.7288L9.21248 11.6358C8.46827 11.7288 8.46825 11.7287 8.46827 11.7288L8.46846 11.7304L8.46877 11.7328L8.46974 11.7402L8.4731 11.7652C8.47598 11.7862 8.48018 11.8157 8.48577 11.8525C8.49693 11.9261 8.51375 12.0294 8.53688 12.1528C8.5827 12.3972 8.65534 12.7311 8.76177 13.0716C8.86511 13.4023 9.01497 13.7881 9.23365 14.1062C9.44166 14.4087 9.82749 14.8101 10.4246 14.8101C10.8388 14.8101 11.1746 14.4743 11.1746 14.0601C11.1746 13.6763 10.8863 13.3598 10.5145 13.3154C10.5031 13.3022 10.4882 13.2832 10.4697 13.2564C10.3759 13.1199 10.2796 12.8996 10.1935 12.6242C10.1105 12.3587 10.0506 12.0865 10.0112 11.8764C9.99171 11.7725 9.97774 11.6865 9.96878 11.6274C9.96431 11.598 9.96111 11.5754 9.95912 11.5609L9.95702 11.5453L9.95665 11.5425ZM10.5408 13.3413C10.5408 13.3416 10.5387 13.3402 10.5345 13.3364C10.5388 13.3391 10.5409 13.341 10.5408 13.3413Z"
                fill={color ?? colours[theme].icon}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.88083 10.8916C7.29185 10.943 7.58339 11.3178 7.53201 11.7288L6.7878 11.6358C7.53201 11.7288 7.53203 11.7287 7.53201 11.7288L7.53182 11.7304L7.53151 11.7328L7.53055 11.7402L7.52719 11.7652C7.5243 11.7862 7.52011 11.8157 7.51452 11.8525C7.50335 11.9261 7.48654 12.0294 7.46341 12.1528C7.41758 12.3972 7.34494 12.7311 7.23851 13.0716C7.13518 13.4023 6.98531 13.7881 6.76663 14.1062C6.55863 14.4087 6.1728 14.8101 5.57568 14.8101C5.16147 14.8101 4.82568 14.4743 4.82568 14.0601C4.82568 13.6763 5.11395 13.3598 5.48578 13.3154C5.49715 13.3022 5.51212 13.2832 5.53057 13.2564C5.62439 13.1199 5.72073 12.8996 5.80679 12.6242C5.88976 12.3587 5.94969 12.0865 5.9891 11.8764C6.00858 11.7725 6.02254 11.6865 6.0315 11.6274C6.03597 11.598 6.03917 11.5754 6.04117 11.5609L6.04327 11.5453L6.0436 11.5428C6.09509 11.1319 6.4699 10.8402 6.88083 10.8916ZM5.45944 13.3413C5.45942 13.341 5.46152 13.3391 5.46577 13.3364C5.46159 13.3402 5.45946 13.3416 5.45944 13.3413ZM6.0436 11.5428C6.04361 11.5427 6.04362 11.5426 6.0436 11.5428V11.5428Z"
                fill={color ?? colours[theme].icon}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.4161 4.87604C7.76075 5.10581 7.85388 5.57146 7.62412 5.9161L3.62412 11.9161C3.39435 12.2608 2.9287 12.3539 2.58405 12.1241C2.23941 11.8944 2.14628 11.4287 2.37604 11.0841L6.37604 5.08405C6.60581 4.73941 7.07146 4.64628 7.4161 4.87604Z"
                fill={color ?? colours[theme].icon}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.4161 0.876042C13.7608 1.10581 13.8539 1.57146 13.6241 1.9161L9.62412 7.9161C9.39435 8.26075 8.9287 8.35388 8.58405 8.12412C8.23941 7.89435 8.14628 7.4287 8.37604 7.08405L12.376 1.08405C12.6058 0.739408 13.0715 0.646278 13.4161 0.876042Z"
                fill={color ?? colours[theme].icon}
            />
        </svg>
    )
}
