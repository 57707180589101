import {
    IApexSingleRichBodyFromQueryAPI,
    IApexSingleRichBodyRichContentObjectState,
    IApexSingleRichBodyState,
    IApexSingleRichSliceDuoImageObjectState,
    IApexSingleRichSliceSingleImageObjectState,
    IHybridPrismicProps,
    IHybridPrismicPropsToState,
    IPrismicFieldProps,
    IPrismicFieldPropsToState,
    IPrismicImg,
} from 'apexModels'
import { convertImgPrismicWithCompression } from './apexImgPrismicCompression'

export const convertIApexBodySliceFromAPItoStateSingle = (
    slice: IApexSingleRichBodyFromQueryAPI
): IApexSingleRichBodyState => {
    switch (slice.type) {
        case 'body_rich_content':
            let slice_1: IApexSingleRichBodyRichContentObjectState = {
                slice_type: 'body_rich_content',
                text_array: [],
            }

            if (slice.primary && slice.primary) {
                if (slice.primary.body_elem && slice.primary.body_elem[0]) {
                    let arr: IPrismicFieldPropsToState[] = []

                    for (const elem of slice.primary.body_elem) {
                        let converted_item: IPrismicFieldPropsToState = convertIPrismicPropsFieldAPItoPrismicFieldStateSingle(
                            elem
                        )
                        arr.push(converted_item)
                    }

                    slice_1.text_array = arr
                }
            }

            return slice_1

        case 'single_image_portrait':
            let slice_2: IApexSingleRichSliceSingleImageObjectState = {
                slice_type: 'single_image_portrait',
                img:
                    slice.primary.img &&
                    convertImgPrismicWithCompression(slice.primary.img),
                image_credit:
                    slice.primary.image_credit &&
                    slice.primary.image_credit[0].text
                        ? slice.primary.image_credit[0].text
                        : '',
            }

            return slice_2

        case 'single_image_landscape':
            let slice_3: IApexSingleRichSliceSingleImageObjectState = {
                slice_type: 'single_image_landscape',
                img:
                    slice.primary.img &&
                    convertImgPrismicWithCompression(slice.primary.img),
                image_credit:
                    slice.primary.image_credit &&
                    slice.primary.image_credit[0].text
                        ? slice.primary.image_credit[0].text
                        : '',
            }

            return slice_3

        case 'duo_img_lansdcape_lansdcape':
            let slice_4: IApexSingleRichSliceDuoImageObjectState = {
                slice_type: 'duo_img_lansdcape_lansdcape',
                img_1:
                    slice.primary.img_1 &&
                    convertImgPrismicWithCompression(slice.primary.img_1),
                img_2:
                    slice.primary.img_2 &&
                    convertImgPrismicWithCompression(slice.primary.img_2),
                image_credit:
                    slice.primary.image_credit &&
                    slice.primary.image_credit[0].text
                        ? slice.primary.image_credit[0].text
                        : '',
            }

            return slice_4

        case 'duo_img_portrait_portrait':
            let slice_5: IApexSingleRichSliceDuoImageObjectState = {
                slice_type: 'duo_img_portrait_portrait',
                img_1:
                    slice.primary.img_1 &&
                    convertImgPrismicWithCompression(slice.primary.img_1),
                img_2:
                    slice.primary.img_2 &&
                    convertImgPrismicWithCompression(slice.primary.img_2),
                image_credit:
                    slice.primary.image_credit &&
                    slice.primary.image_credit[0].text
                        ? slice.primary.image_credit[0].text
                        : '',
            }

            return slice_5

        case 'duo_img_hybrid_landscape_portrait':
            let slice_6: IApexSingleRichSliceDuoImageObjectState = {
                slice_type: 'duo_img_hybrid_landscape_portrait',
                img_1:
                    slice.primary.img_1 &&
                    convertImgPrismicWithCompression(slice.primary.img_1),
                img_2:
                    slice.primary.img_2 &&
                    convertImgPrismicWithCompression(slice.primary.img_2),
                image_credit:
                    slice.primary.image_credit &&
                    slice.primary.image_credit[0].text
                        ? slice.primary.image_credit[0].text
                        : '',
            }

            return slice_6

        case 'duo_img_hybrid_portrait_landscape':
            let slice_7: IApexSingleRichSliceDuoImageObjectState = {
                slice_type: 'duo_img_hybrid_portrait_landscape',
                img_1:
                    slice.primary.img_1 &&
                    convertImgPrismicWithCompression(slice.primary.img_1),
                img_2:
                    slice.primary.img_2 &&
                    convertImgPrismicWithCompression(slice.primary.img_2),
                image_credit:
                    slice.primary.image_credit &&
                    slice.primary.image_credit[0].text
                        ? slice.primary.image_credit[0].text
                        : '',
            }

            return slice_7

        default:
            let item: IApexSingleRichBodyRichContentObjectState = {
                slice_type: 'body_rich_content',
                text_array: [],
            }

            return item
    }
}

export const convertIPrismicPropsFieldAPItoPrismicFieldStateSingle = (
    elem: IPrismicFieldProps
): IPrismicFieldPropsToState => {
    let text_item: IPrismicFieldPropsToState = {
        type: 'text',
        text: elem.text ? elem.text : '',
        textStyle:
            elem.spans && elem.spans[0]
                ? elem.spans[0].type
                : elem.type && elem.type.match(/heading/g)
                ? 'heading'
                : undefined,
        url:
            elem.spans &&
            elem.spans[0] &&
            elem.spans[0].data &&
            elem.spans[0].type === 'hyperlink' &&
            elem.spans[0].data.url
                ? elem.spans[0].data.url
                : undefined,
    }

    return text_item
}

export const convertIArrayHybridPrismicPropsToIHybridPrismicPropsToState = (
    apiArray: IHybridPrismicProps[]
): IHybridPrismicPropsToState[] => {
    // need to check whether type = paragraph or type = image

    let final_item: IHybridPrismicPropsToState[] = []

    for (let elem of apiArray) {
        if (elem.type) {
            if (elem.type === 'image') {
                let img_item: IPrismicImg = {
                    type: 'image',
                    url: elem.url,
                }

                let new_img_item = convertImgPrismicWithCompression(img_item)

                new_img_item && final_item.push(new_img_item)
            } else {
                // assume its text-related: paragraph or heading.
                // => which contains text. So should be fine to not strict check "paragraph"
                // and just replace all those types with "text"

                let text_item: IPrismicFieldPropsToState = convertIPrismicPropsFieldAPItoPrismicFieldStateSingle(
                    elem
                )

                final_item.push(text_item)
            }
        }
    }

    return final_item
}
