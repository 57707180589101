import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { getUserArchivedCarsRequest } from '../../../../redux/user/actions/loadingActions'
import { sortArchivedGarageMenuData } from '../../../pages/archive/garageArchive'
import { BannerViewIcon, GridViewIcon } from '../../statefulicons'
import SortMenuDesktop from '../sortMenu/sortMenuDesktop'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`
const ViewsContainer = styled.section<IStyle>`
    position: relative;
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 48px;
    outline: none;
    background-color: ${(props) =>
        colours[props.$theme].background_primary_switch};
    border-radius: 50px;
    transition: all 200ms;
`

const Circle = styled.div<IStyle>`
    position: absolute;
    z-index: 0;
    width: 70px;
    height: 90%;
    background: ${(props) => colours[props.$theme].background_default};
    box-shadow: 0px 1px 2px
        ${(props) => colours[props.$theme].shadow_strongest_16};
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;

    :hover {
        transition: all 250ms;
    }
`
const BtnWrapper = styled.button`
    all: unset;
    cursor: pointer;
    width: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface Props {
    activeView: 'grid' | 'list'
    setActiveView: any
}

const ArchivedGarageViewsMenuDesktop = (props: Props) => {
    const { activeView, setActiveView } = props
    const dispatch = useAppDispatch()
    const currentOption = useAppSelector(
        (state) =>
            state.user.userLoggedIn &&
            state.user.userLoggedIn.archived_cars &&
            state.user.userLoggedIn.archived_cars.active_sort
    )

    const { theme } = useThemes()
    return (
        <Row>
            <ViewsContainer $theme={theme}>
                <Circle
                    $theme={theme}
                    style={{
                        transform:
                            activeView !== 'grid'
                                ? 'translateX(68px)'
                                : 'translateX(2px)',
                    }}
                />
                <BtnWrapper onClick={() => setActiveView('grid')}>
                    <GridViewIcon
                        theme={theme}
                        isActive={activeView === 'grid' ? true : false}
                    />
                </BtnWrapper>

                <BtnWrapper onClick={(e: any) => setActiveView('list')}>
                    <BannerViewIcon
                        theme={theme}
                        isActive={activeView === 'list' ? true : false}
                    />
                </BtnWrapper>
            </ViewsContainer>

            <SortMenuDesktop
                dropdownData={sortArchivedGarageMenuData}
                currentOption={currentOption}
                onSelected={(
                    v:
                        | 'ARCHIVED_ON_OLDEST'
                        | 'ARCHIVED_ON_MOST_RECENT'
                        | 'MANUFACTURE_DATE_ASCENDING'
                        | 'MANUFACTURE_DATE_DESCENDING'
                        | string
                ) => {
                    // @ts-ignore
                    dispatch(getUserArchivedCarsRequest(v))
                }}
            />
        </Row>
    )
}

export default ArchivedGarageViewsMenuDesktop
