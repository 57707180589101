import * as React from 'react'
import styled from 'styled-components'
import HelpTutorialItem from '../../atoms/helpTutorialItem/helpTutorialItemDesktop'
// import { Link } from 'react-router-dom'
import garageImage from '../../../public/assets/tutorials_replay/garage_screenshot_desktop.png'
import car1 from '../../../public/assets/tutorials_replay/car_1_screenshot_desktop.png'
// import car2 from '../../../public/assets/tutorials_replay/car_2_screenshot_desktop.png'
import task from '../../../public/assets/tutorials_replay/task_screenshot_desktop.png'

const Wrap = styled.section`
    width: 100%;
`

const VideoContent = styled.section`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 20px;
    gap: 20px;
`

const TextSection = styled.div`
    margin-top: 50px;
`

const TextLine = styled.div`
    font-family: 'Lato';
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    line-height: 1.7rem;
    .link {
        font-style: normal;
        padding: 0px;
        color: #5ec3ca;
    }
`

// left some commented code in case we decide
// to pass the data for each tutorial item from redux-localdata

// interface ITutorialItem {
//     title: string
//     imgUrl: string
//     link: string
// }

interface Props {
    // data: ITutorialItem[]
    carId: string | null
}

const HelpTutorialSectionDesktop: React.SFC<Props> = ({ carId }) => (
    <Wrap>
        <VideoContent>
            <HelpTutorialItem
                title="Garage"
                imgUrl={garageImage}
                link="/garage?tutorial=true"
            />
            <div style={{ paddingLeft: '30px' }} />
            <HelpTutorialItem
                title="Car Overview"
                imgUrl={car1}
                link={
                    carId
                        ? `/car/${carId}?tutorial=true`
                        : '/garage?tutorial=true'
                }
                isDisabled={carId ? false : true}
                tooltipTitle={'Please add a car'}
            />
            {/* <HelpTutorialItem
                title="Car 2. Gallery"
                imgUrl={car2}
                link={
                    carId
                        ? `/car/${carId}/gallery?tutorial=true`
                        : '/garage?tutorial=true'
                }
                isDisabled={carId ? false : true}
                tooltipTitle={'Please add a car'}
            /> */}

            <div style={{ paddingLeft: '30px' }} />
            <HelpTutorialItem
                title="Tasks"
                imgUrl={task}
                link="/tasks?tutorial=true"
            />
            {/* {
                props.data.map(item => 
                    <HelpTutorialItem 
                        title={item.title}
                        imgUrl={item.imgUrl}
                        link={item.link}
                    />
                    )
            } */}
        </VideoContent>
        <TextSection>
            <TextLine>Anything else you need help with?</TextLine>
            <TextLine>
                <a
                    className="link"
                    href={'mailto:hello@custodian.club?subject=Help'}
                    target="_blank"
                    rel="noreferrer"
                >
                    Let us know &nbsp;
                </a>
                <span>and we'll take your feedback into account.</span>
            </TextLine>
        </TextSection>
    </Wrap>
)

export default HelpTutorialSectionDesktop
