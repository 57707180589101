export const GET_TECHNICAL_INFORMATION_REQUEST =
    '@@technical_information/get/REQUEST'
export const GET_TECHNICAL_INFORMATION_SUCCESS =
    '@@technical_information/get/SUCCESS'
export const GET_TECHNICAL_INFORMATION_ERROR =
    '@@technical_information/get/ERROR'

export const SET_TECHNICAL_INFORMATION_REQUEST =
    '@@technical_information/set/REQUEST'
export const SET_TECHNICAL_INFORMATION_SUCCESS =
    '@@technical_information/set/SUCCESS'
export const SET_TECHNICAL_INFORMATION_ERROR =
    '@@technical_information/set/ERROR'

export const WRITE_TECHNICAL_INFORMATION_REQUEST =
    '@@technical_information/write/REQUEST'
export const WRITE_TECHNICAL_INFORMATION_SUCCESS =
    '@@technical_information/write/SUCCESS'
export const WRITE_TECHNICAL_INFORMATION_ERROR =
    '@@technical_information/write/ERROR'

export const WRITE_MULTIPLE_TECHNICAL_INFORMATION_REQUEST =
    '@@technical_information_multiple/write/REQUEST'

export const CLEAN_UP_TECHNICAL_INFORMATION_SUCCESS =
    '@@technical_information/clean_up/REQUEST'
