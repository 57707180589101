import * as requestActions from '../actions/requestActions'
import * as errorActions from '../actions/errorActions'
import * as successActions from '../actions/successActions'
import { call, fork, put, take, select } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'

import { api } from '../../services'
import { ITimelineItem } from 'timelineModels'
import { RootState } from 'typesafe-actions'
import { IDeleteEntryAttachmentPayloadReq } from 'ApiInterfaces'

let get_active_entry_in_state = (state: RootState) => state.timeline.activeEntry

function* DeleteEntryAttachmentSaga(
    payload: IDeleteEntryAttachmentPayloadReq
): any {
    try {
        yield call(
            api.timeline.entries.deleteEntryAttachment.deleteEntryAttachment,
            payload
        )

        let active_entry_in_state: ITimelineItem = yield select(
            get_active_entry_in_state
        )

        let ids: string[] = []

        if (
            active_entry_in_state.attachmentIDs &&
            active_entry_in_state.attachmentIDs.length > 0
        ) {
            ids = active_entry_in_state.attachmentIDs

            const index = active_entry_in_state.attachmentIDs.indexOf(
                payload.attachmentID
            )
            if (index > -1) {
                ids.splice(index, 1)
            }
        }
        yield put(successActions.deleteEntryAttachmentSuccess(ids))
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: DeleteEntryAttachmentSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carID}/history-file/entry?entryid=${payload.entryID}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.deleteEntryAttachmentError(customError))
        }
    }
}

function* watcherDeleteEntryAttachment() {
    while (true) {
        const { payload } = yield take(
            requestActions.deleteEntryAttachmentRequest
        )

        yield call(DeleteEntryAttachmentSaga, payload)
    }
}

const delete_entry_attachment: any[] = [fork(watcherDeleteEntryAttachment)]

export default delete_entry_attachment
