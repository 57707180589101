import * as React from 'react'
import styled from 'styled-components'

import Faded from '../../animated/faded'

import IpadAndMobileDisplay from '../ipadAndMobileDisplay'

interface Props {
    children: React.ReactNode
    customZindex?: number
    isOpen: boolean
    toggle: any
}

interface Istyle {
    isCollapsed?: boolean
    isOpen?: boolean
    $customZindex?: number
}

const MobilePageWrapperStyle = styled.div<Istyle>`
    z-index: ${(props) =>
        props.$customZindex ? `${props.$customZindex}` : '12'};
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

class ModalDisplayMobileIpad extends React.PureComponent<Props> {
    handleTouchMove = (e: any) => {
        let targetElement: HTMLElement | null =
            document.querySelector('#scrollable-content') &&
            document.querySelector('#scrollable-content')

        let scrollableContent: HTMLElement[] = []

        targetElement &&
            document
                .querySelectorAll('#scrollable-content *')
                .forEach((node: any) => scrollableContent.push(node))

        const targetIsElement =
            targetElement !== null && e.target.id === targetElement.id
        const modalContainsTarget = scrollableContent.includes(e.target)

        const shouldPreventDefault = !(targetIsElement || modalContainsTarget)

        if (shouldPreventDefault) e.preventDefault()
    }

    componentDidMount() {
        if (this.props.isOpen) {
            document.body.style.overflow = 'hidden'
            if (window.matchMedia('(display-mode: standalone)').matches) {
                // document.body.ontouchmove = (e) => e.preventDefault()
                window.addEventListener('touchmove', this.handleTouchMove, {
                    passive: false,
                })
            }
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
        if (window.matchMedia('(display-mode: standalone)').matches) {
            // document.body.ontouchmove = (e) => true
            window.removeEventListener('touchmove', this.handleTouchMove)
        }
    }

    render() {
        const { children, isOpen, toggle, customZindex } = this.props

        return (
            <IpadAndMobileDisplay>
                <Faded>
                    {isOpen ? (
                        <MobilePageWrapperStyle
                            onClick={() => toggle()}
                            isOpen={isOpen}
                            $customZindex={customZindex}
                        >
                            {children}
                        </MobilePageWrapperStyle>
                    ) : null}
                </Faded>
            </IpadAndMobileDisplay>
        )
    }
}

export default ModalDisplayMobileIpad
