import { IDropdownItem, IDropdownListState } from 'entityModels'
import { motion } from 'framer-motion'
import moment from 'moment'
import styled from 'styled-components'
import {
    IAnyObject,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'

import { IInsuranceFieldType } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import CheckboxWithIconRowItem from '../../../atoms/formField/checkboxWithIconRowItem'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import InputFieldTooltip from '../../../atoms/customTooltips/inputFieldTooltip'
import InfoIcon from '../../../atoms/icons/infoIcon'
import InputField from '../../../atoms/Inputfield/inputField'
import DropdownAndTexOrNumber from '../../../molecules/editOrCreateModeSingleFields/dropdownAndTextOrNumber/dropdownAndTexOrNumber'
import CalendarIcon from '../../../atoms/icons/components/calendar'
import AnimatedTextareaShort from '../../../atoms/textarea/animatedTextareaShort'
import YesNoRadioInput from '../../../molecules/editOrCreateModeSingleFields/yesOrNoRadioInput/yesOrNoRadioInput'
import MobileSelectWithBottomSheet from '../../../atoms/Inputfield/mobileSelectWithBottomSheet'
import { device } from '../../../templates/displays/devices'
import { useHistory } from 'react-router'
import { useEffect, useRef, useState } from 'react'
import PickerBtnInsurance from '../../../atoms/Button/pickerBtnInsurance'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import React from 'react'
import { capitalizeFirstLetter } from '../../../../helpers/strings/capitalizeFirstLetter'
import MultipleSelectDropdown from '../../editForms/technicalInformationEditForm/multipleselect'
import { isValidPhoneNumber } from 'libphonenumber-js'
import useThemes from '../../../../providers/theme/hooks'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../providers/theme/colours'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import {
    createLocalDateFromISODatePart,
    createUTCDateFromLocalPicker,
} from '../../../../helpers/time/utclocal'
import Expander from '../../../atoms/expander/expander'

const hasQueryParams = () => {
    let url = window.location.href
    if (url.includes('?')) {
        return true
    } else {
        return false
    }
}
type IStyle = {
    $theme: ITheme
    $isActive?: boolean
    $sectionId?: IInsuranceApplicationSectionId
}

export const InsuranceFormQuestion = styled.div<IStyle>`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 16px;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    width: 100%;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

export const YesNoBox = styled(motion.div)<{ $isActive?: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 18px 16px 16px;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    transition: all 200ms;

    :hover {
        background: var(--primary_04);
        border: 1px solid var(--primary, #5ec3ca);
    }

    ${(props) => `
    background: ${props.$isActive ? 'var(--primary_04)' : 'transparent'};
    border: ${
        props.$isActive
            ? '1px solid var(--primary, #5ec3ca)'
            : '1px solid var(--text-muted)'
    };
    `}

    cursor: pointer;
`

export const FieldRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
`

export const LocalErrorDiv = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
    padding-top: 8px;
`

const ImportantIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background-color: #1a1a1a10;
    display: flex;
    align-items: center;
    justify-content: center;
`

type Props = {
    item: IInsuranceFieldType
    onAnswerChange: (id: string, answer: any) => any
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    mobileSearchPickerSectionURL?: string
    entityIndex?: number
    onBlur?: (id: string, answer: any) => any
    sectionID?: IInsuranceApplicationSectionId
    bg?: string
}

export const InsuranceFormQuestionWithTooltip = ({
    item,
    theme,
    tooltipPlacement,
}: {
    item: IInsuranceFieldType
    theme: ITheme
    tooltipPlacement?: 'left' | 'right'
}) => {
    return (
        <InsuranceFormQuestion $theme={theme}>
            <div
                style={
                    item.contains_tooltip_text ||
                    item.contains_important_tooltip_text
                        ? { maxWidth: '90%' }
                        : {}
                }
            >
                {item.text}
            </div>
            <div
                style={{
                    width: 48,
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                {item.contains_tooltip_text ? (
                    <InputFieldTooltip
                        tooltipText={item.contains_tooltip_text}
                        color={'var(--text-muted)'}
                        tooltipPlacement={tooltipPlacement ?? 'left'}
                    >
                        <div
                            style={{
                                transform: 'translateY(2px)',
                            }}
                        >
                            <InfoIcon size="14" />
                        </div>
                    </InputFieldTooltip>
                ) : item.contains_important_tooltip_text ? (
                    <InputFieldTooltip
                        tooltipText={item.contains_important_tooltip_text}
                        color={'#333333'}
                        tooltipPlacement={tooltipPlacement ?? 'left'}
                    >
                        <ImportantIconWrapper>
                            <InfoIcon
                                size="14"
                                color="var(--background_neutral_active)"
                            />
                        </ImportantIconWrapper>
                    </InputFieldTooltip>
                ) : undefined}
            </div>
        </InsuranceFormQuestion>
    )
}

export const RenderInsuranceQuoteFormFieldMobile = (props: Props) => {
    let {
        item,
        onAnswerChange,
        data,
        errors,
        setError,
        entityIndex,
        onBlur,
        sectionID,
        bg,
    } = props

    let dispatch = useAppDispatch()

    const history = useHistory()

    let makesDropdowndata: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let bodyTypeList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.carBodyTypes
    })

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    let dropdownsState: IDropdownListState = useAppSelector((state) => {
        return state.localdata.dropdownData
    })

    const checkCountryName = (name: string) => {
        if (name.toUpperCase() === 'GB') {
            return 'United Kingdom'
        }

        if (name.toUpperCase() === 'FR') {
            return 'France'
        }

        if (name.toUpperCase() === 'ES') {
            return 'Spain'
        }

        if (name.toUpperCase() === 'IT') {
            return 'Italy'
        }

        if (name.toUpperCase() === 'US') {
            return 'United States'
        }

        if (name.toUpperCase() === 'DE') {
            return 'Germany'
        }

        return name
    }

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    let isDisabled = application?.status === 'DRAFT' ? false : true

    const { theme } = useThemes()

    const [localNumberError, setLocalNumberError] = useState(undefined)

    const renderField = () => {
        switch (item.type) {
            case 'multiselect':
                return (
                    <>
                        <InsuranceFormQuestionWithTooltip
                            theme={theme}
                            item={item}
                        />
                        <div style={{ paddingTop: 16 }} />

                        <MultipleSelectDropdown
                            isDisabled={isDisabled}
                            item={{
                                text: item.text,
                                id: item.id,
                                answers: data[item.id],
                            }}
                            optionsList={item.options ?? []}
                            handleChange={(values: string[]) => {
                                onAnswerChange(`${item.id}`, values)
                            }}
                        />
                    </>
                )

            case 'searchable_options':
                let valueDisplay =
                    (item.id === 'registration_country' ||
                        item.id === 'import_origin_country_code' ||
                        item.id ===
                            'current_country_of_residence_country_code') &&
                    typeof data[item.id] === 'string'
                        ? checkCountryName(data[item.id])
                        : (item.id === 'registration_country' ||
                              item.id === 'import_origin_country_code' ||
                              item.id ===
                                  'current_country_of_residence_country_code') &&
                          data[item.id]?.name
                        ? checkCountryName(data[item.id]?.name)
                        : data[item.id] && typeof data[item.id] === 'string'
                        ? data[item.id]
                        : data[item.id] && data[item.id].name
                        ? data[item.id].name
                        : undefined

                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}

                        <PickerBtnInsurance
                            disabled={isDisabled}
                            fwArrow
                            bg={bg ?? colours[theme].background_neutral_subtle}
                            placeholder={
                                item.isMobileAnimated ? item.text : undefined
                            }
                            onClick={() => {
                                if (!isDisabled) {
                                    dispatch(
                                        insuranceActions.set_temp_field_search_copy(
                                            {
                                                ...item,
                                                id: item.parent_entity_id
                                                    ? `${item.id}_${item.parent_entity_id}_${entityIndex}`
                                                    : item.id,
                                            }
                                        )
                                    )

                                    let qpp = hasQueryParams() ? `&` : `?`

                                    props.mobileSearchPickerSectionURL &&
                                    item.parent_entity_id
                                        ? history.push(
                                              `${props.mobileSearchPickerSectionURL}${qpp}field_id=${item.id}&entity=${item.parent_entity_id}&entity_index=${entityIndex}`
                                          )
                                        : props.mobileSearchPickerSectionURL
                                        ? history.push(
                                              `${props.mobileSearchPickerSectionURL}${qpp}field_id=${item.id}`
                                          )
                                        : alert('no necessary path given')
                                }
                            }}
                            value={valueDisplay}
                            textTransform={
                                item.isCaps ? 'uppercase' : undefined
                            }
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                        />
                    </div>
                )

            case 'simple_txt':
                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}
                        <InputFieldNoFormikAnimated
                            disabled={isDisabled}
                            name={item.isMobileAnimated ? item.text : ''}
                            value={
                                data[item.id] ? `${data[item.id]}` : undefined
                            }
                            onChange={(e: any) => {
                                onAnswerChange(item.id, e.target.value)

                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            placeholder={
                                item.isMobileAnimated
                                    ? ''
                                    : item.placeholderText ?? 'Type here ...'
                            }
                            width={'100%'}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            linecolor={
                                errors && errors[item.id]
                                    ? '#DF6F6F'
                                    : undefined
                            }
                            textTransform={
                                item.isCaps ? 'uppercase' : undefined
                            }
                            placeholderStyle={
                                item.isCaps
                                    ? `text-transform: none !important`
                                    : undefined
                            }
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            rightAdornment={
                                item.isMobileAnimated ? (
                                    item.contains_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_tooltip_text
                                            }
                                            color={colours[theme].text_muted}
                                            tooltipPlacement="left"
                                        >
                                            <div
                                                style={{
                                                    transform:
                                                        'translateY(2px)',
                                                }}
                                            >
                                                <InfoIcon size="14" />
                                            </div>
                                        </InputFieldTooltip>
                                    ) : item.contains_important_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_important_tooltip_text
                                            }
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                            tooltipPlacement="left"
                                        >
                                            <ImportantIconWrapper>
                                                <InfoIcon
                                                    size="14"
                                                    color={
                                                        colours[theme]
                                                            .background_neutral_active
                                                    }
                                                />
                                            </ImportantIconWrapper>
                                        </InputFieldTooltip>
                                    ) : undefined
                                ) : undefined
                            }
                        />
                    </div>
                )

            case 'short_txt_area':
                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}

                        <AnimatedTextareaShort
                            disabled={isDisabled}
                            name={item.isMobileAnimated ? item.text : ''}
                            value={data[item.id] ? `${data[item.id]}` : ''}
                            handleChange={(e: any) => {
                                onAnswerChange(item.id, e.target.value)

                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            placeholder={
                                item.isMobileAnimated ? '' : 'Type here ...'
                            }
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            rightAdornment={
                                item.isMobileAnimated ? (
                                    item.contains_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_tooltip_text
                                            }
                                            color={colours[theme].text_muted}
                                            tooltipPlacement="left"
                                        >
                                            <div
                                                style={{
                                                    transform:
                                                        'translateY(2px)',
                                                }}
                                            >
                                                <InfoIcon size="14" />
                                            </div>
                                        </InputFieldTooltip>
                                    ) : item.contains_important_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_important_tooltip_text
                                            }
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                            tooltipPlacement="left"
                                        >
                                            <ImportantIconWrapper>
                                                <InfoIcon
                                                    size="14"
                                                    color={
                                                        colours[theme]
                                                            .background_neutral_active
                                                    }
                                                />
                                            </ImportantIconWrapper>
                                        </InputFieldTooltip>
                                    ) : undefined
                                ) : undefined
                            }
                        />
                    </div>
                )

            case 'date':
                let valueDate =
                    data &&
                    data[item.id] &&
                    createLocalDateFromISODatePart(data[item.id])
                        ? `${createLocalDateFromISODatePart(data[item.id])}`
                        : undefined

                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}

                        <InputField
                            theme={theme}
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            disabled={isDisabled}
                            id="date_picked_btns_form_mobile"
                            name="date_picked_btns_form_mobile"
                            custom_date_format={item?.custom_date_format}
                            labelNotRaised={
                                item.isMobileAnimated &&
                                (!data || !data[item.id])
                                    ? true
                                    : undefined
                            }
                            customPlaceholderWhenLabelRaised={
                                item.placeholderText
                            }
                            placeholder={
                                item.isMobileAnimated
                                    ? !data || !data[item.id]
                                        ? item.text
                                        : data && data[item.id]
                                        ? item.text
                                        : undefined
                                    : undefined
                            }
                            // helperText="Type or select a date (yyyy/mm/dd)"
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            restrictFlipOfDatePopUp
                            value={valueDate}
                            // value={
                            //     data && data[item.id]
                            //         ? `${data[item.id]}`
                            //         : undefined
                            // }
                            font_family="Lato"
                            onChange={(dateString: any) => {
                                if (
                                    item.custom_date_format === 'MM/yyyy' &&
                                    dateString
                                ) {
                                    let lastDayOfMonth =
                                        moment(dateString).endOf('month')

                                    let isoLastDayOfMonth = lastDayOfMonth
                                        ?.toISOString()
                                        ?.split('T')[0]

                                    onAnswerChange(
                                        item.id,
                                        `${isoLastDayOfMonth}T00:00:00.000Z`
                                    )
                                } else {
                                    const utcDate =
                                        createUTCDateFromLocalPicker(dateString)
                                    const isoDateString = utcDate.toISOString()
                                    onAnswerChange(item.id, isoDateString)
                                }

                                let hasError: boolean | string =
                                    item.min !== undefined &&
                                    item.max !== undefined
                                        ? !moment(dateString).isBetween(
                                              moment(new Date(item.min)),
                                              moment(new Date(item.max))
                                          )
                                        : item.min !== undefined && !item.max
                                        ? moment(dateString).isBefore(
                                              moment(new Date(item.min))
                                          )
                                            ? true
                                            : false
                                        : item.max !== undefined && !item.min
                                        ? moment(dateString).isAfter(
                                              moment(new Date(item.max))
                                          )
                                            ? true
                                            : false
                                        : false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else {
                                        setError(item.id, hasError)
                                    }
                                }
                            }}
                            type="date"
                            width="100%"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                            // rightAdornment={
                            //     item.contains_tooltip_text ? (
                            //         <FieldRow
                            //             style={{
                            //                 gap: 10,
                            //                 alignItems: 'center',
                            //             }}
                            //         >
                            //             <CalendarIcon />
                            //             <InputFieldTooltip
                            //                 tooltipText={
                            //                     item.contains_tooltip_text
                            //                 }
                            //                 color={'#b3b3b3'}
                            //             >
                            //                 <div
                            //                     style={{
                            //                         transform:
                            //                             'translateY(2px)',
                            //                     }}
                            //                 >
                            //                     <InfoIcon size="14" />
                            //                 </div>
                            //             </InputFieldTooltip>
                            //         </FieldRow>
                            //     ) : item.contains_important_tooltip_text ? (
                            //         <FieldRow
                            //             style={{
                            //                 gap: 10,
                            //                 alignItems: 'center',
                            //             }}
                            //         >
                            //             <CalendarIcon />
                            //             <InputFieldTooltip
                            //                 tooltipText={
                            //                     item.contains_important_tooltip_text
                            //                 }
                            //                 color={'#333333'}
                            //             >
                            //                 <ImportantIconWrapper>
                            //                     <InfoIcon
                            //                         size="14"
                            //                         color="#333333"
                            //                     />
                            //                 </ImportantIconWrapper>
                            //             </InputFieldTooltip>
                            //         </FieldRow>
                            //     ) : (
                            //         <CalendarIcon />
                            //     )
                            // }
                        />
                    </div>
                )

            case 'dates_range':
                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}
                        <FieldRow style={{ paddingTop: 4 }}>
                            <InputField
                                theme={theme}
                                customActiveColour={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : undefined
                                }
                                disabled={isDisabled}
                                id="date_picked_btns_form"
                                name="date_picked_btns_form"
                                placeholder={`${item.text} start`}
                                customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                                tabindex={1}
                                aria-live="polite"
                                formikprops={props}
                                value={
                                    data && data[`${item.id}_start_date`]
                                        ? `${data[`${item.id}_start_date`]}`
                                        : undefined
                                }
                                font_family="Lato"
                                restrictFlipOfDatePopUp
                                onChange={(dateString: any) => {
                                    if (!isDisabled) {
                                        let isoDateString = new Date(
                                            dateString
                                        )?.toISOString()

                                        onAnswerChange(
                                            `${item.id}_start_date`,
                                            isoDateString
                                        )

                                        let hasError: boolean | string =
                                            item.min !== undefined &&
                                            item.max !== undefined
                                                ? !moment(
                                                      isoDateString
                                                  ).isBetween(
                                                      moment(
                                                          new Date(item.min)
                                                      ),
                                                      moment(new Date(item.max))
                                                  )
                                                : item.min !== undefined
                                                ? !moment(
                                                      isoDateString
                                                  ).isBefore(
                                                      moment(new Date(item.min))
                                                  )
                                                : item.max !== undefined
                                                ? !moment(
                                                      isoDateString
                                                  ).isAfter(
                                                      moment(new Date(item.max))
                                                  )
                                                : false

                                        if (
                                            !hasError &&
                                            errors &&
                                            errors[item.id]
                                        ) {
                                            setError(item.id, undefined)
                                        }
                                        if (hasError) {
                                            if (item.error_txt) {
                                                setError(
                                                    item.id,
                                                    item.error_txt
                                                )
                                            } else {
                                                setError(item.id, hasError)
                                            }
                                        }
                                    }
                                }}
                                type="date"
                                width="100%"
                                fontSize="16px"
                                paddingLeft="0px"
                                backgroundColor={
                                    bg ??
                                    colours[theme].background_neutral_subtle
                                }
                                rightAdornment={<CalendarIcon />}

                                // dataCyId={props.dataCyId}
                            />
                            <InputField
                                theme={theme}
                                customActiveColour={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : undefined
                                }
                                disabled={isDisabled}
                                id="date_picked_btns_form"
                                name="date_picked_btns_form"
                                placeholder={`${item.text} end`}
                                customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                                tabindex={1}
                                aria-live="polite"
                                formikprops={props}
                                value={
                                    data && data[`${item.id}_end_date`]
                                        ? `${data[`${item.id}_end_date`]}`
                                        : undefined
                                }
                                font_family="Lato"
                                onChange={(dateString: any) => {
                                    let isoDateString = new Date(
                                        dateString
                                    )?.toISOString()

                                    onAnswerChange(
                                        `${item.id}_end_date`,
                                        isoDateString
                                    )

                                    let hasError: boolean | string =
                                        item.min !== undefined &&
                                        item.max !== undefined
                                            ? !moment(isoDateString).isBetween(
                                                  moment(new Date(item.min)),
                                                  moment(new Date(item.max))
                                              )
                                            : item.min !== undefined
                                            ? !moment(isoDateString).isBefore(
                                                  moment(new Date(item.min))
                                              )
                                            : item.max !== undefined
                                            ? !moment(isoDateString).isAfter(
                                                  moment(new Date(item.max))
                                              )
                                            : false

                                    if (
                                        !hasError &&
                                        errors &&
                                        errors[item.id]
                                    ) {
                                        setError(item.id, undefined)
                                    }
                                    if (hasError) {
                                        if (item.error_txt) {
                                            setError(item.id, item.error_txt)
                                        } else {
                                            setError(item.id, hasError)
                                        }
                                    }
                                }}
                                type="date"
                                width="100%"
                                fontSize="16px"
                                paddingLeft="0px"
                                backgroundColor={
                                    bg ??
                                    colours[theme].background_neutral_subtle
                                }
                                rightAdornment={<CalendarIcon />}
                            />
                        </FieldRow>
                    </div>
                )

            case 'number':
                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}

                        <InputFieldNoFormikAnimated
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            name={item.isMobileAnimated ? item.text : ''}
                            disabled={isDisabled}
                            value={
                                data && data[item.id] !== undefined
                                    ? `${data[item.id]}`
                                    : undefined
                            }
                            onChange={async (e: any) => {
                                onAnswerChange(item.id, +e.target.value)
                                if (
                                    item.is_price &&
                                    item.localOnChangeValidation
                                ) {
                                    item.localOnChangeValidation(
                                        +e.target.value
                                    )
                                        .validate(+e.target.value)
                                        .then(() => {
                                            setLocalNumberError(undefined)
                                        })
                                        .catch((err: any) => {
                                            err?.errors[0] &&
                                                setLocalNumberError(
                                                    err?.errors[0]
                                                )
                                        })
                                }
                                let hasError: boolean | string =
                                    item.min !== undefined &&
                                    e.target.value < item.min
                                        ? `Minimum value is ${item.min}`
                                        : item.max && e.target.value > item.max
                                        ? `Minimum value is ${item.max}`
                                        : false

                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else {
                                        setError(item.id, hasError)
                                    }
                                }
                            }}
                            onBlur={() => {
                                onBlur &&
                                    onBlur(
                                        item.id,
                                        data && data[item.id]
                                            ? data[item.id]
                                            : 0
                                    )
                            }}
                            placeholder={
                                item.isMobileAnimated
                                    ? ''
                                    : item.placeholderText ?? 'Enter amount ...'
                            }
                            width={'100%'}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            type="number"
                            valueStart={item.value_front_field ?? undefined}
                            rightAdornment={
                                item.isMobileAnimated ? (
                                    item.contains_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_tooltip_text
                                            }
                                            color={colours[theme].text_muted}
                                            tooltipPlacement="left"
                                        >
                                            <div
                                                style={{
                                                    transform:
                                                        'translateY(2px)',
                                                }}
                                            >
                                                <InfoIcon size="14" />
                                            </div>
                                        </InputFieldTooltip>
                                    ) : item.contains_important_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_important_tooltip_text
                                            }
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                            tooltipPlacement="left"
                                        >
                                            <ImportantIconWrapper>
                                                <InfoIcon
                                                    size="14"
                                                    color={
                                                        colours[theme]
                                                            .background_neutral_active
                                                    }
                                                />
                                            </ImportantIconWrapper>
                                        </InputFieldTooltip>
                                    ) : undefined
                                ) : undefined
                            }
                        />
                        <Expander height={localNumberError ? 'auto' : 0}>
                            <LocalErrorDiv>{localNumberError}</LocalErrorDiv>
                        </Expander>
                    </div>
                )

            case 'phone':
                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}

                        <InputFieldNoFormikAnimated
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            disabled={isDisabled}
                            name={item.isMobileAnimated ? item.text : ''}
                            value={
                                data &&
                                data[item.id] &&
                                `${data[item.id]}`.includes('+44')
                                    ? `${data[item.id]}`.replace('+44', '')
                                    : undefined
                            }
                            onChange={(e: any) => {
                                onAnswerChange(item.id, `+44${e.target.value}`)
                                let hasError: undefined | string =
                                    data &&
                                    data[item.id] &&
                                    isValidPhoneNumber(
                                        `+44${e.target.value}`,
                                        'GB'
                                    )
                                        ? undefined
                                        : 'Invalid phone number'

                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else {
                                        setError(item.id, hasError)
                                    }
                                }
                            }}
                            placeholder={
                                item.isMobileAnimated
                                    ? ''
                                    : 'Enter phone number ...'
                            }
                            width={'100%'}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            type="number"
                            valueStart={item.value_front_field ?? undefined}
                        />
                    </div>
                )

            case 'email':
                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}

                        <InputFieldNoFormikAnimated
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            disabled={isDisabled}
                            name={item.isMobileAnimated ? item.text : ''}
                            value={
                                data && data[item.id]
                                    ? `${data[item.id]}`
                                    : undefined
                            }
                            onChange={(e: any) => {
                                onAnswerChange(item.id, e.target.value)
                                let hasError: boolean | string =
                                    item.min !== undefined &&
                                    e.target.value < item.min
                                        ? `Minimum value is ${item.min}`
                                        : item.max && e.target.value > item.max
                                        ? `Minimum value is ${item.max}`
                                        : false

                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else {
                                        setError(item.id, hasError)
                                    }
                                }
                            }}
                            placeholder={
                                item.isMobileAnimated ? '' : 'Enter email ... '
                            }
                            width={'100%'}
                            backgroundColor={
                                bg ?? colours[theme].background_neutral_subtle
                            }
                            type="email"
                            valueStart={item.value_front_field ?? undefined}
                            rightAdornment={
                                item.isMobileAnimated ? (
                                    item.contains_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_tooltip_text
                                            }
                                            color={colours[theme].text_muted}
                                            tooltipPlacement="left"
                                        >
                                            <div
                                                style={{
                                                    transform:
                                                        'translateY(2px)',
                                                }}
                                            >
                                                <InfoIcon size="14" />
                                            </div>
                                        </InputFieldTooltip>
                                    ) : item.contains_important_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_important_tooltip_text
                                            }
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                            tooltipPlacement="left"
                                        >
                                            <ImportantIconWrapper>
                                                <InfoIcon
                                                    size="14"
                                                    color={
                                                        colours[theme]
                                                            .background_neutral_active
                                                    }
                                                />
                                            </ImportantIconWrapper>
                                        </InputFieldTooltip>
                                    ) : undefined
                                ) : undefined
                            }
                        />
                    </div>
                )

            case 'yes_no':
                return (
                    <div>
                        <InsuranceFormQuestionWithTooltip
                            theme={theme}
                            item={item}
                        />

                        <div style={{ paddingTop: '16px' }} />
                        <YesNoRadioInput
                            customPrimaryColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            customPrimaryColour_04={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_04
                                    : undefined
                            }
                            isDisabled={isDisabled}
                            value={data[item.id]}
                            isYesActive={data[item.id] === true ? true : false}
                            isNoActive={data[item.id] === false ? true : false}
                            onChange={(isChecked: boolean) => {
                                if (!isDisabled) {
                                    onAnswerChange(item.id, isChecked)

                                    let hasError: boolean | string = false
                                    if (
                                        !hasError &&
                                        errors &&
                                        errors[item.id]
                                    ) {
                                        setError(item.id, undefined)
                                    }
                                    if (hasError) {
                                        if (item.error_txt) {
                                            setError(item.id, item.error_txt)
                                        } else if (
                                            typeof hasError === 'string'
                                        ) {
                                            setError(item.id, hasError)
                                        } else {
                                            setError(item.id, 'Invalid')
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                )

            case 'dropdown':
                let all_options: any = !item
                    ? []
                    : item.id === 'registration_country' ||
                      item.id === 'import_origin_country_code' ||
                      item.id === 'current_country_of_residence_country_code'
                    ? countriesList
                    : item.dropdown_id
                    ? dropdownsState[item.dropdown_id]
                        ? dropdownsState[item.dropdown_id]
                        : []
                    : item.id === 'country'
                    ? countriesList
                    : item.id === 'make'
                    ? makesDropdowndata
                    : item.id === 'model'
                    ? modelsDropdowndata
                    : item.id === 'transmission'
                    ? transmissionsList
                    : item.id === 'body_type'
                    ? bodyTypeList
                    : dropdownsState[item.id]
                    ? dropdownsState[item.id]
                    : item.options
                    ? item.options
                    : []

                if (
                    all_options &&
                    all_options[0] &&
                    all_options[0].value &&
                    all_options[0].label
                ) {
                    let newArr = all_options.map((item2: any) => {
                        return { uid: item2.value, name: item2.label }
                    })
                    all_options = [...newArr]
                }

                const cleanUpName = (name: string) => {
                    if (name?.includes('_')) {
                        return name?.replace(/_/g, ' ')?.toLocaleLowerCase()
                    } else return name?.toLocaleLowerCase()
                }

                let overrideValue =
                    data && data[item.id] === true
                        ? { uid: 'Yes', name: 'Yes' }
                        : data[item.id] === false
                        ? { uid: 'No', name: 'No' }
                        : data[item.id]
                        ? data[item.id]
                        : ''

                if (all_options) {
                    return (
                        <div style={{ width: '100%' }}>
                            {item.isMobileAnimated ? null : (
                                <>
                                    <InsuranceFormQuestionWithTooltip
                                        theme={theme}
                                        item={item}
                                    />
                                    <div style={{ paddingTop: 16 }} />
                                </>
                            )}

                            <MobileSelectWithBottomSheet
                                disabled={isDisabled}
                                placeholder={
                                    item.isMobileAnimated
                                        ? item.text
                                        : undefined
                                }
                                capitalise={
                                    typeof data[item.id] === 'string'
                                        ? true
                                        : false
                                }
                                value={
                                    overrideValue && overrideValue.name
                                        ? overrideValue.name
                                        : typeof overrideValue === 'string'
                                        ? cleanUpName(overrideValue)
                                        : ''
                                }
                                // isValueLabel={item.dropdown_id ? true : false}
                                name={item.isMobileAnimated ? item.text : ''}
                                dropdownData={all_options}
                                sendDropdownItem={true}
                                onSelect={(option: IDropdownItem) => {
                                    let optionCopy = { ...option }
                                    if (item.patch_as_int) {
                                        optionCopy = {
                                            ...optionCopy,
                                            patch_as_int: item.patch_as_int,
                                        }
                                    }

                                    if (item.patch_field_id) {
                                        optionCopy = {
                                            ...optionCopy,
                                            patch_field_id: item.patch_field_id,
                                        }
                                    }
                                    if (item.is_price !== undefined) {
                                        optionCopy = {
                                            ...optionCopy,
                                            is_price: item.is_price,
                                        }
                                    }

                                    onAnswerChange(item.id, optionCopy)
                                    // if (item.dropdown_id) {
                                    //     onAnswerChange(item.id, {
                                    //         uid: option.value,
                                    //         name: option.label,
                                    //     })
                                    // } else {
                                    //     onAnswerChange(item.id, option)
                                    // }

                                    let hasError: boolean | string = false
                                    if (
                                        !hasError &&
                                        errors &&
                                        errors[item.id]
                                    ) {
                                        setError(item.id, undefined)
                                    }
                                    if (hasError) {
                                        if (item.error_txt) {
                                            setError(item.id, item.error_txt)
                                        } else if (
                                            typeof hasError === 'string'
                                        ) {
                                            setError(item.id, hasError)
                                        } else {
                                            setError(item.id, 'Invalid')
                                        }
                                    }
                                }}
                                backgroundColor={
                                    bg ??
                                    colours[theme].background_neutral_subtle
                                }
                                allowContentScroll={
                                    all_options && all_options.length > 4
                                        ? true
                                        : false
                                }
                                customBsHeight={
                                    all_options &&
                                    (all_options.length > 4
                                        ? '80vh'
                                        : all_options.length < 3
                                        ? '30vh'
                                        : '50vh')
                                }
                                customActiveColour={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : undefined
                                }
                                customOptionHighlightColour={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_08
                                        : undefined
                                }
                            />
                        </div>
                    )
                } else return null

            case 'text_with_dropdown': {
                let allOptions: any = item.dropdown_id
                    ? Array.isArray(dropdownsState[item.dropdown_id])
                        ? dropdownsState[item.dropdown_id]
                        : []
                    : item.options
                    ? item.options
                    : []

                if (
                    allOptions &&
                    allOptions[0] &&
                    allOptions[0].value &&
                    allOptions[0].label
                ) {
                    let newArr = allOptions.map((item2: any) => {
                        return { uid: item2.value, name: item2.label }
                    })
                    allOptions = [...newArr]
                }

                return (
                    <div style={{ width: '100%' }}>
                        {item.isMobileAnimated ? null : (
                            <>
                                <InsuranceFormQuestionWithTooltip
                                    theme={theme}
                                    item={item}
                                />
                                <div style={{ paddingTop: 16 }} />
                            </>
                        )}
                        <DropdownAndTexOrNumber
                            isDisabled={isDisabled}
                            dropdownFullScreen={
                                item.dropdown_id === 'titles' ? true : false
                            }
                            input_placeholder={item.text}
                            options={allOptions}
                            input_id={item.id}
                            dropdown_placeholder={
                                item.contains_front_dropdown
                                    ? capitalizeFirstLetter(
                                          item.contains_front_dropdown
                                      )
                                    : undefined
                            }
                            dropdown_id={`${item.id}-${item.contains_front_dropdown}`}
                            dropdown_value={
                                data[
                                    `${item.id}-${item.contains_front_dropdown}`
                                ]?.name
                            }
                            input_value_string={
                                data[item.id] ? data[item.id] : ''
                            }
                            onPickerBtnClick={() => {
                                dispatch(
                                    insuranceActions.set_temp_field_search_copy(
                                        {
                                            ...item,
                                            id: `${item.id}-${item.contains_front_dropdown}`,
                                            text: item.contains_front_dropdown
                                                ? capitalizeFirstLetter(
                                                      item.contains_front_dropdown
                                                  )
                                                : '',
                                        }
                                    )
                                )

                                props.mobileSearchPickerSectionURL
                                    ? history.push(
                                          `${props.mobileSearchPickerSectionURL}?field_id=${item.id}-${item.contains_front_dropdown}`
                                      )
                                    : alert('no necessary path given')
                            }}
                            onChange={(id: string, value: string | number) => {
                                onAnswerChange(id, value)
                                let hasError: boolean | string = false
                                if (!hasError && errors && errors[item.id]) {
                                    setError(item.id, undefined)
                                }
                                if (hasError) {
                                    if (item.error_txt) {
                                        setError(item.id, item.error_txt)
                                    } else if (typeof hasError === 'string') {
                                        setError(item.id, hasError)
                                    } else {
                                        setError(item.id, 'Invalid')
                                    }
                                }
                            }}
                            customActiveColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_100
                                    : undefined
                            }
                            customOptionHighlightColour={
                                sectionID
                                    ? insurance_colours[theme].sections[
                                          sectionID
                                      ].primary_08
                                    : undefined
                            }
                            rightAdornment={
                                item.isMobileAnimated ? (
                                    item.contains_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_tooltip_text
                                            }
                                            color={colours[theme].text_muted}
                                            tooltipPlacement="left"
                                        >
                                            <div
                                                style={{
                                                    transform:
                                                        'translateY(2px)',
                                                }}
                                            >
                                                <InfoIcon size="14" />
                                            </div>
                                        </InputFieldTooltip>
                                    ) : item.contains_important_tooltip_text ? (
                                        <InputFieldTooltip
                                            tooltipText={
                                                item.contains_important_tooltip_text
                                            }
                                            color={
                                                colours[theme]
                                                    .background_neutral_active
                                            }
                                            tooltipPlacement="left"
                                        >
                                            <ImportantIconWrapper>
                                                <InfoIcon
                                                    size="14"
                                                    color={
                                                        colours[theme]
                                                            .background_neutral_active
                                                    }
                                                />
                                            </ImportantIconWrapper>
                                        </InputFieldTooltip>
                                    ) : undefined
                                ) : undefined
                            }
                        />
                    </div>
                )
            }

            case 'number_with_dropdown': {
                if (item.options) {
                    return (
                        <div style={{ width: '100%' }}>
                            {item.isMobileAnimated ? null : (
                                <>
                                    <InsuranceFormQuestionWithTooltip
                                        theme={theme}
                                        item={item}
                                    />
                                    <div style={{ paddingTop: 16 }} />
                                </>
                            )}
                            <DropdownAndTexOrNumber
                                isDisabled={isDisabled}
                                input_placeholder={item.text}
                                options={item.options}
                                input_id={item.id}
                                dropdown_placeholder={
                                    item.contains_front_dropdown
                                }
                                dropdown_id={`${item.id}-${item.contains_front_dropdown}`}
                                dropdown_value={
                                    data[
                                        `${item.id}-${item.contains_front_dropdown}`
                                    ] ?? item.default_dropdown_value
                                        ? `${item.default_dropdown_value?.name}`
                                        : undefined
                                }
                                input_value_number={
                                    data[item.id] !== undefined
                                        ? data[item.id]
                                        : ''
                                }
                                onChange={(
                                    id: string,
                                    value: string | number
                                ) => {
                                    onAnswerChange(
                                        id,
                                        item.patch_as_int &&
                                            id !==
                                                `${item.id}-${item.contains_front_dropdown}`
                                            ? +value
                                            : value
                                    )
                                    let hasError: boolean | string = false
                                    if (
                                        !hasError &&
                                        errors &&
                                        errors[item.id]
                                    ) {
                                        setError(item.id, undefined)
                                    }
                                    if (hasError) {
                                        if (item.error_txt) {
                                            setError(item.id, item.error_txt)
                                        } else if (
                                            typeof hasError === 'string'
                                        ) {
                                            setError(item.id, hasError)
                                        } else {
                                            setError(item.id, 'Invalid')
                                        }
                                    }
                                }}
                                customActiveColour={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : undefined
                                }
                                customOptionHighlightColour={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_08
                                        : undefined
                                }
                                rightAdornment={
                                    item.isMobileAnimated ? (
                                        item.contains_tooltip_text ? (
                                            <InputFieldTooltip
                                                tooltipText={
                                                    item.contains_tooltip_text
                                                }
                                                color={
                                                    colours[theme].text_muted
                                                }
                                                tooltipPlacement="left"
                                            >
                                                <div
                                                    style={{
                                                        transform:
                                                            'translateY(2px)',
                                                    }}
                                                >
                                                    <InfoIcon size="14" />
                                                </div>
                                            </InputFieldTooltip>
                                        ) : item.contains_important_tooltip_text ? (
                                            <InputFieldTooltip
                                                tooltipText={
                                                    item.contains_important_tooltip_text
                                                }
                                                color={
                                                    colours[theme]
                                                        .background_neutral_active
                                                }
                                                tooltipPlacement="left"
                                            >
                                                <ImportantIconWrapper>
                                                    <InfoIcon
                                                        size="14"
                                                        color={
                                                            colours[theme]
                                                                .background_neutral_active
                                                        }
                                                    />
                                                </ImportantIconWrapper>
                                            </InputFieldTooltip>
                                        ) : undefined
                                    ) : undefined
                                }
                            />
                        </div>
                    )
                } else return null
            }

            case 'contact_medium_choice': {
                let selected = data && data[item.id] ? [...data[item.id]] : []
                const handleSelect = (id: string) => {
                    if (selected && selected.includes(id)) {
                        selected = selected.filter(
                            (selectedId) => selectedId !== id
                        )
                    } else {
                        selected = selected ? [...selected, id] : [id]
                    }
                    onAnswerChange(item.id, selected)
                }
                return (
                    <div>
                        <InsuranceFormQuestion $theme={theme}>
                            {item.text}
                        </InsuranceFormQuestion>

                        <div style={{ paddingTop: '16px' }} />
                        {item.options?.map((option: IDropdownItem, i) => {
                            return (
                                <React.Fragment
                                    key={`${option.uid}_${i}_mobile`}
                                >
                                    <CheckboxWithIconRowItem
                                        isDisabled={isDisabled}
                                        name={option.name}
                                        text={option.name}
                                        onChange={() => {
                                            if (!isDisabled) {
                                                handleSelect(option.uid)

                                                let hasError: boolean | string =
                                                    false
                                                if (
                                                    !hasError &&
                                                    errors &&
                                                    errors[item.id]
                                                ) {
                                                    setError(item.id, undefined)
                                                }
                                                if (hasError) {
                                                    if (item.error_txt) {
                                                        setError(
                                                            item.id,
                                                            item.error_txt
                                                        )
                                                    } else if (
                                                        typeof hasError ===
                                                        'string'
                                                    ) {
                                                        setError(
                                                            item.id,
                                                            hasError
                                                        )
                                                    } else {
                                                        setError(
                                                            item.id,
                                                            'Invalid'
                                                        )
                                                    }
                                                }
                                            }
                                        }}
                                        iconID={option.icon}
                                        borderColor={'#B3B3B3'}
                                        checked={
                                            data &&
                                            data[item.id]?.includes(option.uid)
                                                ? true
                                                : false
                                        }
                                        customActiveColour={
                                            sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_100
                                                : undefined
                                        }
                                        customHighlightColour={
                                            sectionID
                                                ? insurance_colours[theme]
                                                      .sections[sectionID]
                                                      .primary_08
                                                : undefined
                                        }
                                    />
                                </React.Fragment>
                            )
                        })}
                    </div>
                )
            }
        }
    }

    let params = new URLSearchParams(location.search)
    let anchor_params = params.get('anchor')
    let anchoredRefMobile: any = useRef()

    useEffect(() => {
        if (
            anchor_params &&
            (anchor_params === `${item.id}_mobile` ||
                anchor_params ===
                    `${item.id}_${item.parent_entity_id}_${entityIndex}_mobile`)
        ) {
            scrollIntoView(anchoredRefMobile?.current, {
                behavior: 'smooth',
                block: 'center',
            })
        }
    }, [anchor_params])

    // hideField variable is needed for handling deprecated fields on editable vs past submitted forms ie. =>
    // don't allow deprecated fields to be filed in on draft applications
    // show fields on submitted applications only if they have filled in data
    // hide later added fields so they won't show as empty for older applications
    let hideField =
        (!isDisabled && item.isDeprecated) || // hide deprecated fields for editable forms (draft applications)
        (isDisabled && data[item.id] === undefined) // hide fields with no data for readonly forms (submitted applications)

    return (
        <div
            ref={anchoredRefMobile}
            id={`${item.id}_mobile`}
            style={{ width: '100%' }}
        >
            {hideField ? null : renderField()}
        </div>
    )
}
