import { IJourneyInsuranceStepID } from 'journeyInterfaces'
import { IUser } from 'myModels'
import { useHistory } from 'react-router'
import JourneyInsuranceWelcome from '../welcome'
import JourneyInsuranceJoinStep from '../join'
import JourneyInsuranceBenefitsStep from '../benefits'
import useWindowSize, {
    WindowSize,
} from '../../../../templates/displays/windowSizeHook'
import JourneyInsuranceHowItWorksStep from '../howItWorks'
import JourneyInsuranceHowItWorksExtraStepMobile from '../howItWorksExtraStepMobile'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'

type Props = {
    stepID: IJourneyInsuranceStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
}
export default function InsuranceJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
}: // user,
Props) {
    const history = useHistory()
    let locationState: any = history.location.state

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyInsuranceWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
        case 'join':
            return (
                <JourneyInsuranceJoinStep
                    step={1}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(`/journey/insurance?step=welcome`)
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/insurance`,
                            search: `?step=benefits`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )
        case 'benefits':
            return (
                <JourneyInsuranceBenefitsStep
                    step={2}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(`/journey/insurance?step=join`)
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/insurance`,
                            search: `?step=how_it_works`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'how_it_works':
            return (
                <JourneyInsuranceHowItWorksStep
                    step={3}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(`/journey/insurance?step=benefits`)
                    }}
                    goToNextStep={() => {
                        isMobile
                            ? history.push({
                                  pathname: `/journey/insurance`,
                                  search: `?step=how_it_works_mobile_extra`,
                                  state: {
                                      prevPath: `${history.location.pathname}${
                                          history.location.search ?? ''
                                      }`,
                                  },
                              })
                            : history.push({
                                  pathname: `/journey/insurance`,
                                  search: `?step=achieved`,
                                  state: {
                                      prevPath: `${history.location.pathname}${
                                          history.location.search ?? ''
                                      }`,
                                  },
                              })
                    }}
                />
            )

        case 'how_it_works_mobile_extra':
            return (
                <JourneyInsuranceHowItWorksExtraStepMobile
                    step={3}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/insurance?step=how_it_works`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/insurance`,
                            search: `?step=achieved`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'achieved':
            return <JourneyAchievedStep sectionID="insurance" />

        default:
            return (
                <JourneyInsuranceWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
