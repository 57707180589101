import { ICar, IDropdownItem } from 'entityModels'
import * as React from 'react'
import styled from 'styled-components'
import { OOtherTaskOptions } from '../../../ol/ol'
import { editFormsActions } from '../../../redux/editForms/reducer'
import SelectSearchBarCarInfoMobile from '../editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'
import { useAppDispatch } from '../../../redux/store/hooks'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import SortIcon from '../../atoms/icons/components/sortIcon'
import { device } from '../../templates/displays/devices'
import FilterIcon from '../../atoms/icons/components/filterIcon'

const TasksMenuRowManagerMobile = styled.section`
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-self: stretch;

    @media ${device.ipad} {
        padding-top: 20px;
    }
`

interface Props {
    dropdownCars: IDropdownItem[]
    selectCarViewId: (name: string) => void
    selectedCarData?: ICar | undefined
    state: {
        dropdownCars: never[]
        carSelected: string | null
        sortBy: string
        filterBy: string
        dropdownCarsWithOptions: never[]
    }
}

const TaskViewsManagerMobile: React.FC<Props> = (props) => {
    let { dropdownCars, selectCarViewId, selectedCarData } = props

    const dispatch = useAppDispatch()
    const toggleSortByChoice = () =>
        dispatch(editFormsActions.toggleSortByChoice())
    const toggleFilterByChoice = () =>
        dispatch(editFormsActions.toggleFilterByChoice())

    const { theme } = useThemes()

    let item_car_default = () => {
        if (selectedCarData) {
            return {
                id: selectedCarData.id
                    ? selectedCarData.id
                    : OOtherTaskOptions.general_task.uid,
                answer: selectedCarData.title
                    ? selectedCarData.title
                    : OOtherTaskOptions.all_tasks.name,
                text: selectedCarData.title
                    ? selectedCarData.title
                    : OOtherTaskOptions.all_tasks.name,
            }
        } else {
            return {
                id: OOtherTaskOptions.general_task.uid,
                answer: OOtherTaskOptions.all_tasks.name,
                text: OOtherTaskOptions.all_tasks.name,
            }
        }
    }

    return (
        <TasksMenuRowManagerMobile data-tut="tasks-tuto-mobile-2">
            <SelectSearchBarCarInfoMobile
                width="100%"
                handleSelectChange={(
                    name: string,
                    other?: string | undefined
                ) => selectCarViewId(name)}
                item={item_car_default()}
                items={dropdownCars}
                fontSize="16px"
                height="48px"
                borderColor={colours[theme].border_muted}
            />
            <div style={{ maxWidth: 48, minWidth: 48 }}>
                <StandardCtaBtn
                    customWidth="48px"
                    removePaddings
                    isSecondary
                    bg={'transparent'}
                    customHoverColor={colours[theme].background_neutral_subtle}
                    borderOnHover={`2px solid ${colours[theme].border_muted}`}
                    boder={`1px solid ${colours[theme].border_muted}`}
                    onClick={toggleSortByChoice}
                >
                    <SortIcon />
                </StandardCtaBtn>
            </div>
            <div style={{ maxWidth: 48, minWidth: 48 }}>
                <StandardCtaBtn
                    customWidth="48px"
                    removePaddings
                    isSecondary
                    bg={'transparent'}
                    customHoverColor={colours[theme].background_neutral_subtle}
                    borderOnHover={`2px solid ${colours[theme].border_muted}`}
                    boder={`1px solid ${colours[theme].border_muted}`}
                    onClick={toggleFilterByChoice}
                >
                    <FilterIcon />
                </StandardCtaBtn>
            </div>
        </TasksMenuRowManagerMobile>
    )
}

export default TaskViewsManagerMobile
