// import dayjs from 'dayjs'

import { DatePickerConverterFormat } from '.'

export const convertDateToBeFormat = (value: string) => {
    const event = new Date(value)
    return event.toISOString()

    // let date_res: any = dayjs(value).toISOString()
    // return date_res
}

export const convertDateToUTCFormatWithTimeSetToZero = (value: string) => {
    let convertedDate = DatePickerConverterFormat(value)
    return `${convertedDate}T00:00:00Z`
}

export const convertDateToFeFormat = (value: string) => {
    let d = new Date(value)
    d.toLocaleDateString()
    return d.toString()
}
