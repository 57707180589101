import { IDropdownItem } from 'entityModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import SelectSearchBarCarInfoMobile from '../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'

interface Props extends RouteComponentProps<any> {
    countriesDropdownList: IDropdownItem[] | null
    setCountryDropdownData: () => void
    setSelectedCountry: (country: string) => void
    toggleAddACarForm: (isOpen: boolean) => void
}

type State = {
    answer: any
}

const dispatchProps = {
    setCountryDropdownData: () =>
        dropDownActions.setCountriesCodeDropdownDataRequest(),
    setSelectedCountry: (payload: string) =>
        dropDownActions.setSelectedCountry(payload),
    toggleAddACarForm: (isOpen: boolean) =>
        editFormsActions.toggleAddACarForm(isOpen),
}

const mapStateToProps = (state: RootState) => ({
    countriesDropdownList: state.localdata.dropdownData.countriesCode,
})

class CountryPicker extends React.Component<Props, State> {
    state = {
        answer: '',
    }

    componentDidMount() {
        this.props.setCountryDropdownData()
    }

    handleChange = (name: string) => {
        this.props.setSelectedCountry(name)

        const params = new URLSearchParams(this.props.location.search)
        let returnTo = params.get('return_to')

        this.props.toggleAddACarForm(true)

        this.props.history.replace(returnTo ?? '/garage')
    }

    searchOptionsData: any = {
        text: 'country',
        id: 'country',
        answer: '',
        unique_answer_id: undefined,
    }

    render() {
        return (
            <>
                <SelectSearchBarCarInfoMobile
                    handleSelectChange={(
                        name: string,
                        other?: string | undefined
                    ) => {
                        this.handleChange(name)
                    }}
                    items={
                        this.props.countriesDropdownList?.map(
                            (country) => country.name
                        ) ?? []
                    }
                    item={this.searchOptionsData}
                    isStandalonePage={true}
                    isKeyValue={true}
                />
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CountryPicker)
)
