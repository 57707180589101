import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import facebook_share from '../../../../../public/assets/icons/facebook_share.svg'
import twitter_share from '../../../../../public/assets/icons/twitter_share.svg'
import text_share from '../../../../../public/assets/icons/text_share.svg'
import share_copy from '../../../../../public/assets/icons/share_copy.svg'
import grey_cross_v2 from '../../../../../public/assets/icons/grey_cross_v2.svg'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { Tooltip } from 'antd'
import { useState } from 'react'
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share'

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 24px;

    text-align: center;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 36px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const Row2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    align-self: stretch;
    width: 100%;
`

const Box = styled.div`
    height: 72px;
    background: var(--base-overlays-neutral-alpha-50, rgba(26, 26, 26, 0.02));
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 10px;
    cursor: pointer;
    transition: all 100ms;

    :hover {
        background: var(
            --base-overlays-neutral-alpha-100,
            rgba(26, 26, 26, 0.04)
        );
    }
`

const Icon = styled.img`
    width: 24px;
    height: 24px;
`
const CopyIcon = styled.img`
    height: 18px;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--bg-color, #fff);
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
`

type Props = {
    close: () => any
    entryid: string
}

const CarShowroomEntryShareModal = (props: Props) => {
    let share_url = `https://app.custodian.club/showroom/${props.entryid}`

    const [isTooltip, setIsTooltip] = useState<boolean>(false)

    let title = 'Custodian | Showroom'
    let hashtags = ['cars']

    let fbHashtags = '#custodian #car'

    return (
        <>
            <Row>
                <Title>Share this car</Title>

                <img
                    src={grey_cross_v2}
                    style={{ width: '13px', cursor: 'pointer' }}
                    alt="close"
                    onClick={() => {
                        props.close()
                    }}
                />
            </Row>
            <div style={{ paddingTop: '30px' }} />

            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    cursor: 'pointer !important',
                }}
                onClick={() => {
                    navigator.clipboard.writeText(share_url)
                    setIsTooltip(true)
                    setTimeout(() => {
                        setIsTooltip(false)
                    }, 1000)
                }}
            >
                <InputFieldNoFormikAnimated
                    value={share_url}
                    name={'Share link'}
                    placeholder={'Share Link'}
                    customHeight={'48px'}
                    fontSize={'14px'}
                />
                <Tooltip
                    title="Copied!"
                    open={isTooltip}
                    placement="bottomLeft"
                    color={'var(--primary_strong, #009ea4)'}
                    zIndex={20}
                />
                <IconWrapper>
                    <CopyIcon src={share_copy} alt={'copy link to clipboard'} />
                </IconWrapper>
            </div>

            <div style={{ paddingTop: '24px' }} />

            <Row2>
                <Box>
                    <FacebookShareButton
                        url={share_url}
                        quote={title}
                        hashtag={fbHashtags}
                    >
                        <Icon src={facebook_share} alt={'share on facebook'} />
                    </FacebookShareButton>
                </Box>
                <Box>
                    <TwitterShareButton
                        hashtags={hashtags}
                        title={title}
                        url={share_url}
                    >
                        <Icon src={twitter_share} alt={'share on twitter'} />
                    </TwitterShareButton>
                </Box>
                <Box>
                    <WhatsappShareButton title={title} url={share_url}>
                        <Icon src={text_share} alt={'share whatsapp'} />
                    </WhatsappShareButton>
                </Box>
            </Row2>
        </>
    )
}

export default CarShowroomEntryShareModal
