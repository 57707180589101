import { ISetTaskRemindersEditedPayload } from 'entityModels'
import { call, fork, put, take } from 'redux-saga/effects'
import * as requestActions from '../actions/requestActions'
import * as sucessActions from '../actions/successActions'

function* setTaskEditedRemindersSaga(
    payload: ISetTaskRemindersEditedPayload
): any {
    yield put(sucessActions.setTaskRemindersEditedSuccess(payload))
}

function* watcherSetTaskRemindersEdited() {
    while (true) {
        const { payload } = yield take(
            requestActions.setTaskRemindersEditedRequest
        )
        yield call(setTaskEditedRemindersSaga, payload)
    }
}

const set_task_reminders_edited: any[] = [fork(watcherSetTaskRemindersEdited)]

export default set_task_reminders_edited
