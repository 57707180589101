import * as React from 'react'
import { connect } from 'react-redux'
import BasicAccountItemInfoFormDesktop from '../basic/basicAccountItemInfoFormDesktop'
import ChangePasswordFormDesktop from '../changePasswordForm/changePasswordFormDesktop'

import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
import { IList } from 'entityModels'

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

export interface Props {
    item: IList | undefined
    toggle: any
    submit_func: any
    setToResetPasswordClicked: any
    hasResetPasswordBeenClicked: boolean
    userLoggedIn: IUser
    request_reset_password_func: any
}

class AccountPrivateInfoManagerFormDesktop extends React.Component<Props, {}> {
    hasOnlyUsername =
        this.props.userLoggedIn?.family_name ===
        this.props.userLoggedIn?.given_name
            ? true
            : false

    emptyAnswerItem: IList | undefined = this.props.item
        ? {
              ...this.props.item,
              answer: '',
          }
        : undefined

    renderForm(
        item: any,
        toggle: any,
        submit_func: any,
        setToResetPasswordClicked: any,
        hasResetPasswordBeenClicked: boolean,
        userLoggedIn: any,
        request_reset_password_func: any
    ) {
        switch (item.id) {
            case 'password':
                return (
                    <ChangePasswordFormDesktop
                        toggle={toggle}
                        submitFunc={request_reset_password_func}
                        setToResetPasswordClicked={setToResetPasswordClicked}
                        hasResetPasswordBeenClicked={
                            hasResetPasswordBeenClicked
                        }
                        userLoggedIn={userLoggedIn}
                    />
                )

            default:
                return (
                    <BasicAccountItemInfoFormDesktop
                        toggle={toggle}
                        item={
                            (item?.id === 'given_name' ||
                                item?.id === 'family_name') &&
                            this.hasOnlyUsername &&
                            this.emptyAnswerItem
                                ? { ...this.emptyAnswerItem }
                                : { ...item }
                        }
                        submitFunc={submit_func}
                        userLoggedIn={userLoggedIn}
                    />
                )
        }
    }
    render() {
        return this.renderForm(
            this.props.item,
            this.props.toggle,
            this.props.submit_func,
            this.props.setToResetPasswordClicked,
            this.props.hasResetPasswordBeenClicked,
            this.props.userLoggedIn,
            this.props.request_reset_password_func
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(AccountPrivateInfoManagerFormDesktop)
