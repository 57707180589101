import img1 from '../../public/assets/placeholders_svgs/1.svg'
import img2 from '../../public/assets/placeholders_svgs/2.svg'
import img3 from '../../public/assets/placeholders_svgs/3.svg'
import img4 from '../../public/assets/placeholders_svgs/4.svg'
import img5 from '../../public/assets/placeholders_svgs/5.svg'
import img6 from '../../public/assets/placeholders_svgs/6.svg'
import img7 from '../../public/assets/placeholders_svgs/7.svg'
import img8 from '../../public/assets/placeholders_svgs/8.svg'
import img9 from '../../public/assets/placeholders_svgs/9.svg'
import img10 from '../../public/assets/placeholders_svgs/10.svg'
import img11 from '../../public/assets/placeholders_svgs/11.svg'
import img12 from '../../public/assets/placeholders_svgs/12.svg'
import img13 from '../../public/assets/placeholders_svgs/13.svg'

import img1_dark from '../../public/assets/placeholders_svgs/1_dark.svg'
import img2_dark from '../../public/assets/placeholders_svgs/2_dark.svg'
import img3_dark from '../../public/assets/placeholders_svgs/3_dark.svg'
import img4_dark from '../../public/assets/placeholders_svgs/4_dark.svg'
import img5_dark from '../../public/assets/placeholders_svgs/5_dark.svg'
import img6_dark from '../../public/assets/placeholders_svgs/6_dark.svg'
import img7_dark from '../../public/assets/placeholders_svgs/7_dark.svg'
import img8_dark from '../../public/assets/placeholders_svgs/8_dark.svg'
import img9_dark from '../../public/assets/placeholders_svgs/9_dark.svg'
import img10_dark from '../../public/assets/placeholders_svgs/10_dark.svg'
import img11_dark from '../../public/assets/placeholders_svgs/11_dark.svg'
import img12_dark from '../../public/assets/placeholders_svgs/12_dark.svg'
import img13_dark from '../../public/assets/placeholders_svgs/13_dark.svg'

import img1_jpg from '../../public/assets/placeholders_jpgs/1.jpg'
import img2_jpg from '../../public/assets/placeholders_jpgs/2.jpg'
import img3_jpg from '../../public/assets/placeholders_jpgs/3.jpg'
import img4_jpg from '../../public/assets/placeholders_jpgs/4.jpg'
import img5_jpg from '../../public/assets/placeholders_jpgs/5.jpg'
import img6_jpg from '../../public/assets/placeholders_jpgs/6.jpg'
import img7_jpg from '../../public/assets/placeholders_jpgs/7.jpg'
import img8_jpg from '../../public/assets/placeholders_jpgs/8.jpg'
import img9_jpg from '../../public/assets/placeholders_jpgs/9.jpg'
import img10_jpg from '../../public/assets/placeholders_jpgs/10.jpg'
import img11_jpg from '../../public/assets/placeholders_jpgs/11.jpg'
import img12_jpg from '../../public/assets/placeholders_jpgs/12.jpg'
import img13_jpg from '../../public/assets/placeholders_jpgs/13.jpg'

import img1_jpg_dark from '../../public/assets/placeholders_jpgs/1_dark.png'
import img2_jpg_dark from '../../public/assets/placeholders_jpgs/2_dark.png'
import img3_jpg_dark from '../../public/assets/placeholders_jpgs/3_dark.png'
import img4_jpg_dark from '../../public/assets/placeholders_jpgs/4_dark.png'
import img5_jpg_dark from '../../public/assets/placeholders_jpgs/5_dark.png'
import img6_jpg_dark from '../../public/assets/placeholders_jpgs/6_dark.png'
import img7_jpg_dark from '../../public/assets/placeholders_jpgs/7_dark.png'
import img8_jpg_dark from '../../public/assets/placeholders_jpgs/8_dark.png'
import img9_jpg_dark from '../../public/assets/placeholders_jpgs/9_dark.png'
import img10_jpg_dark from '../../public/assets/placeholders_jpgs/10_dark.png'
import img11_jpg_dark from '../../public/assets/placeholders_jpgs/11_dark.png'
import img12_jpg_dark from '../../public/assets/placeholders_jpgs/12_dark.png'
import img13_jpg_dark from '../../public/assets/placeholders_jpgs/13_dark.png'

import { ITheme } from '../../providers/theme/colours'

let array_urls = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
]

let array_urls_dark = [
    img1_dark,
    img2_dark,
    img3_dark,
    img4_dark,
    img5_dark,
    img6_dark,
    img7_dark,
    img8_dark,
    img9_dark,
    img10_dark,
    img11_dark,
    img12_dark,
    img13_dark,
]

let array_urls_jpg = [
    img1_jpg,
    img2_jpg,
    img3_jpg,
    img4_jpg,
    img5_jpg,
    img6_jpg,
    img7_jpg,
    img8_jpg,
    img9_jpg,
    img10_jpg,
    img11_jpg,
    img12_jpg,
    img13_jpg,
]

let array_urls_jpg_dark = [
    img1_jpg_dark,
    img2_jpg_dark,
    img3_jpg_dark,
    img4_jpg_dark,
    img5_jpg_dark,
    img6_jpg_dark,
    img7_jpg_dark,
    img8_jpg_dark,
    img9_jpg_dark,
    img10_jpg_dark,
    img11_jpg_dark,
    img12_jpg_dark,
    img13_jpg_dark,
]

export const randomCarSvgPlaceholderFunc = (
    format?: 'jpg' | 'svg',
    theme?: ITheme
) => {
    let item: string =
        format === 'jpg'
            ? theme && theme === 'dark'
                ? array_urls_jpg_dark[
                      Math.floor(Math.random() * array_urls_jpg_dark.length)
                  ]
                : array_urls_jpg[
                      Math.floor(Math.random() * array_urls_jpg.length)
                  ]
            : theme && theme === 'dark'
            ? array_urls_dark[
                  Math.floor(Math.random() * array_urls_dark.length)
              ]
            : array_urls[Math.floor(Math.random() * array_urls.length)]

    return item
}
