import React from 'react'
import { connect } from 'react-redux'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ListManagerDesktop from '../../molecules/list/ListManagerDesktop'
import styled from 'styled-components'
import ListManagerMobile from '../../molecules/list/listManagerMobile'
import SideSlider from '../../templates/animated/sideSlider'
// import ReturnNav from '../../atoms/header/returnNav'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { ICar, IList, ITechnicalInformationObject } from 'entityModels'
import InfoBarMobile from '../../atoms/info/infoBarMobile'
import { editFormsActions } from '../../../redux/editForms/reducer'
import Faded from '../../templates/animated/faded'
import TechInfoFormManagerDesktop from '../../organisms/editForms/technicalInformationEditForm/manager/techInfoFormManagerDesktop'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import TechInfoFormManagerMobile from '../../organisms/editForms/technicalInformationEditForm/manager/techInfoFormManagerMobile'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import TutorialConfigsTechInfoMobile from '../../molecules/tutorialConfigs/techinfo/tutorialConfigsTechInfoMobile'
import { IUser } from 'myModels'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'

import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { device } from '../../templates/displays/devices'

const Padding = styled.div`
    padding-right: 10px;
    padding-left: 10px;
`

const IpadAndMobileWrapper = styled.div`
    z-index: 3;
    background-color: var(--bg-color, #fff);
    padding-top: 70px;
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

const dispatchProps = {
    toggleCarTechInfoEditForm: (payload: { isOpen: boolean; item?: IList }) =>
        editFormsActions.toggleCarTechInfoEditForm(payload),
    setCurrentCar: (id: string) => setCurrentCar(id),
    getCarDataByIdRequest: (id: string) => getCarDataByIdRequest(id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    getUserDataRequest: () => getCurrentUserDataRequest(),
}

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.entities.technicalInformationData.loading,
        technical_information:
            state.entities.technicalInformationData.technical_information,
        isCarTechInfoEditFormOpened:
            state.editForms.formsData.carTechInfoEditForm.isOpen,
        item_to_edit: state.editForms.formsData.carTechInfoEditForm.item,
        list: state.entities.technicalInformationData.list,
        carsData: state.entities.carsData.cars,
        userLoggedIn: state.user.userLoggedIn,
        currentcarid:
            state.entities.carsData.currentCarID &&
            state.entities.carsData.currentCarID,
    }
}

interface Props extends RouteComponentProps<any> {
    location: any
    technical_information: ITechnicalInformationObject
    list: string[]
    toggleCarTechInfoEditForm: (payload: {
        isOpen: boolean
        item?: IList
    }) => {}
    isCarTechInfoEditFormOpened: boolean
    item_to_edit?: IList | undefined
    isLoading: boolean
    setCurrentCar: (id: string) => void
    carsData: {
        [key: string]: ICar
    }
    currentcarid: string | undefined
    history: any
    userLoggedIn: IUser | null
    getCarDataByIdRequest: (id: string) => void
    setInitialLandingUrl: (str: string) => void
    getUserDataRequest: () => void
}

type State = {
    id: string
}

class TechnicalInformation extends React.Component<Props, State> {
    state = {
        id: '',
    }

    componentDidMount() {
        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/technical-information`
        )

        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }
        // get ID from params

        let carid: string = this.props.match.params.carid

        this.props.setCurrentCar(carid)

        const id = `hf-${carid}`

        if (userLoggedIn) {
            if (
                !this.props.carsData[carid] ||
                (this.props.carsData[carid] && this.props.carsData[carid])
                    .has_limited_garage_info === true
            ) {
                this.props.getCarDataByIdRequest(carid)
            }
        }

        this.setState({
            id: id,
        })

        const search = this.props.location.search
        const formId = new URLSearchParams(search).get('form_id')

        if (formId === 'makeModelYear') {
            this.props.toggleCarTechInfoEditForm({
                isOpen: true,
                item: {
                    id: 'make',
                    text: 'make',
                },
            })
        }

        if (formId === 'model') {
            this.props.toggleCarTechInfoEditForm({
                isOpen: true,
                item: {
                    id: 'model',
                    text: 'model',
                },
            })
        }
    }

    componentDidUpdate(prevprops: Props) {
        if (prevprops.userLoggedIn !== this.props.userLoggedIn) {
            let carid: string = this.props.match.params.carid

            if (this.props.userLoggedIn) {
                if (
                    !this.props.carsData[carid] ||
                    (this.props.carsData[carid] && this.props.carsData[carid])
                        .has_limited_garage_info === true
                ) {
                    this.props.getCarDataByIdRequest(carid)
                }
            }
        }
    }

    checkDirection = () => {
        if (this.props.location.state !== undefined) {
            if (
                this.props.location.state.prevPath ===
                '/car/technical-information/edit'
            ) {
                return 'left'
            }
            if (this.props.location.state.prevPath === '/car') {
                return 'right'
            } else return 'right'
        } else return 'right'
    }

    render() {
        const { id } = this.state
        const {
            technical_information,
            toggleCarTechInfoEditForm,
            item_to_edit,
            isCarTechInfoEditFormOpened,
            list,
            isLoading,
            currentcarid,
            carsData,
        } = this.props

        // const prevPath =
        //   this.props.history.location.state && this.props.location.state.prevPath;
        const params = new URLSearchParams(this.props.location.search)

        let user_fuel_units_data =
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences &&
            this.props.userLoggedIn.preferences.data &&
            this.props.userLoggedIn.preferences.data['units'] &&
            this.props.userLoggedIn.preferences.data['units'].data &&
            this.props.userLoggedIn.preferences.data['units'].data['fuel_unit']

        let user_distance_units_data =
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences &&
            this.props.userLoggedIn.preferences.data &&
            this.props.userLoggedIn.preferences.data['units'] &&
            this.props.userLoggedIn.preferences.data['units'].data &&
            this.props.userLoggedIn.preferences.data['units'].data[
                'distance_unit'
            ]

        const default_fuel_unit_user: string | null =
            user_fuel_units_data && user_fuel_units_data.user_choice_id

        const default_fuel_unit_user_text: string | null =
            default_fuel_unit_user &&
            user_fuel_units_data &&
            user_fuel_units_data.data &&
            user_fuel_units_data.data[default_fuel_unit_user] &&
            user_fuel_units_data.data[default_fuel_unit_user].text

        const default_fuel_unit_user_text_short: string | null | undefined =
            default_fuel_unit_user &&
            user_fuel_units_data &&
            user_fuel_units_data.data &&
            user_fuel_units_data.data[default_fuel_unit_user] &&
            user_fuel_units_data.data[default_fuel_unit_user].short

        const default_distance_unit_user: string | null =
            user_distance_units_data && user_distance_units_data.user_choice_id

        const default_distance_unit_user_text: string | null =
            default_distance_unit_user &&
            user_distance_units_data &&
            user_distance_units_data.data &&
            user_distance_units_data.data[default_distance_unit_user] &&
            user_distance_units_data.data[default_distance_unit_user].text

        const default_distance_unit_user_text_short: string | null | undefined =
            default_distance_unit_user &&
            default_distance_unit_user &&
            user_distance_units_data &&
            user_distance_units_data.data &&
            user_distance_units_data.data[default_distance_unit_user] &&
            user_distance_units_data.data[default_distance_unit_user].short

        let current: ICar | undefined =
            carsData && currentcarid && carsData[currentcarid]
                ? carsData[currentcarid]
                : undefined

        return (
            <FullPageWrapper>
                {current?.archived_on && (
                    <Redirect
                        to={`/archives/car/${current.id}/technical-information`}
                    />
                )}
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />

                {params.get('tutorial') === 'true' && (
                    <TutorialConfigsTechInfoMobile />
                )}

                <DesktopDisplayOnly>
                    <Padding>
                        {isLoading !== true &&
                            technical_information[id] !== undefined && (
                                <>
                                    <ListManagerDesktop
                                        toggle={(item: IList) =>
                                            toggleCarTechInfoEditForm({
                                                item: item,
                                                isOpen: true,
                                            })
                                        }
                                        data={technical_information[id]}
                                        list={list}
                                        default_fuel_unit_user_text={
                                            default_fuel_unit_user_text_short
                                                ? default_fuel_unit_user_text_short
                                                : default_fuel_unit_user_text
                                                ? default_fuel_unit_user_text
                                                : undefined
                                        }
                                        default_fuel_unit_user={
                                            default_fuel_unit_user
                                                ? default_fuel_unit_user
                                                : undefined
                                        }
                                        default_distance_unit_user_text={
                                            default_distance_unit_user_text_short
                                                ? default_distance_unit_user_text_short
                                                : default_distance_unit_user_text
                                                ? default_distance_unit_user_text
                                                : undefined
                                        }
                                        default_distance_unit_user={
                                            default_distance_unit_user
                                                ? default_distance_unit_user
                                                : undefined
                                        }
                                    />
                                </>
                            )}
                    </Padding>

                    {isCarTechInfoEditFormOpened &&
                        technical_information[id] !== undefined &&
                        currentcarid && (
                            <Faded>
                                <ModalDisplay
                                    toggle={() =>
                                        toggleCarTechInfoEditForm({
                                            isOpen: false,
                                        })
                                    }
                                    isOpen={true}
                                >
                                    <TechInfoFormManagerDesktop
                                        id={id}
                                        item={item_to_edit}
                                        toggle={() =>
                                            toggleCarTechInfoEditForm({
                                                isOpen: false,
                                            })
                                        }
                                        allTechInfodata={
                                            technical_information[id]
                                        }
                                        carid={currentcarid}
                                    />
                                </ModalDisplay>
                            </Faded>
                        )}
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <IpadAndMobileWrapper>
                        <div data-tut="tech-info-tuto-mobile">
                            <InfoBarMobile>
                                Please tap the field you wish to edit
                            </InfoBarMobile>
                        </div>

                        {/* <Link
                            replace={true}
                            to={{
                                pathname: `/car/${currentcarid}`,
                                state: {
                                    prevPath:
                                        this.props.history.location.pathname,
                                },
                            }}
                        >
                            <ReturnNav />
                        </Link> */}

                        <SideSlider direction="right" initial="enter">
                            {isLoading !== true &&
                                technical_information &&
                                id &&
                                technical_information[id] !== undefined && (
                                    <ListManagerMobile
                                        toggle={(item: IList) =>
                                            toggleCarTechInfoEditForm({
                                                item: item,
                                                isOpen: true,
                                            })
                                        }
                                        data={technical_information[id]}
                                        list={list}
                                        default_fuel_unit_user_text={
                                            default_fuel_unit_user_text_short
                                                ? default_fuel_unit_user_text_short
                                                : default_fuel_unit_user_text
                                                ? default_fuel_unit_user_text
                                                : undefined
                                        }
                                        default_fuel_unit_user={
                                            default_fuel_unit_user
                                                ? default_fuel_unit_user
                                                : undefined
                                        }
                                        default_distance_unit_user_text={
                                            default_distance_unit_user_text_short
                                                ? default_distance_unit_user_text_short
                                                : default_distance_unit_user_text
                                                ? default_distance_unit_user_text
                                                : undefined
                                        }
                                        default_distance_unit_user={
                                            default_distance_unit_user
                                                ? default_distance_unit_user
                                                : undefined
                                        }
                                    />
                                )}
                        </SideSlider>

                        {isCarTechInfoEditFormOpened &&
                            technical_information &&
                            id &&
                            technical_information[id] !== undefined &&
                            currentcarid && (
                                <Faded>
                                    <ModalDisplay
                                        toggle={() =>
                                            toggleCarTechInfoEditForm({
                                                isOpen: false,
                                            })
                                        }
                                        isOpen={true}
                                    >
                                        <TechInfoFormManagerMobile
                                            id={id}
                                            item={item_to_edit}
                                            toggle={() =>
                                                toggleCarTechInfoEditForm({
                                                    isOpen: false,
                                                })
                                            }
                                            allTechInfodata={
                                                technical_information[id]
                                            }
                                            carid={currentcarid}
                                        />
                                    </ModalDisplay>
                                </Faded>
                            )}
                    </IpadAndMobileWrapper>
                </IpadAndMobileDisplay>
            </FullPageWrapper>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TechnicalInformation)
)
