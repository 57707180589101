import { combineReducers } from 'redux'
import membership_page from './membership_page/reducer'
import usercategories from './usercategories/reducer'
import welcome_sections from './welcome_page/reducer'
import registration_questions from './registration_questions/reducer'
import dropdownData from './dropdownData/reducer'
import onboardingContent from './onboarding/reducer'
import form_validation_copy from './formValidationCopy/reducer'
import help_page_copy from './help/reducer'
import upcoming_page from './upcoming/reducer'
import subnavigation_state from './subPageNavigation/reducer'
import apex_local_copy from './apexLocalCopy/reducer'
import tutorials_check_state from './tutorialsCheck/reducer'
import customNavData from './customNav/reducer'
import stickerPredefinedThemes from './stickerPredefinedThemes/reducer'
import showroomLocalData from './showroomLocalData'

const localdataReducer = combineReducers({
    membership_page,
    usercategories,
    registration_questions,
    welcome_sections,
    dropdownData,
    onboardingContent,
    form_validation_copy,
    help_page_copy,
    upcoming_page,
    subnavigation_state,
    apex_local_copy,
    tutorials_check_state,
    customNavData,
    stickerPredefinedThemes,
    showroomLocalData,
})

export default localdataReducer
