import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import CustomAnimatedRadio, {
    ICustomAnimatedRadioFieldProps,
} from './customAnimatedRadio'
type IStyle = {
    isExtraSmall?: boolean
}

const Container = styled.div<IStyle>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
    :hover {
        background-color: var(--primary_08);
    }
    padding: 10px;

    @media ${device.mobile_and_ipad} {
        margin-bottom: ${(props: IStyle) =>
            !props.isExtraSmall ? '10px' : '12px'};

        height: ${(props: IStyle) =>
            !props.isExtraSmall ? '55px !important' : 'auto'};
    }
`

const DotContainer = styled.div<IStyle>`
    @media ${device.beyond_ipad_mobile} {
        transform: ${(props: IStyle) =>
            props.isExtraSmall ? 'translateY(-6px)' : 'none'};
    }
`

const LabelName = styled.div`
    font-family: Lato;
    font-size: 14px;
    color: var(--text-default, #666666);
`
const CustomAnimatedRadioField = (props: ICustomAnimatedRadioFieldProps) => {
    return (
        <Container
            style={{
                width: props.rowWidth ?? '100%',
                height: props.rowHeight ?? '100%',
            }}
            role="button"
            onClick={(e: any) => {
                e.preventDefault()
                props.onChange(props.id)
            }}
            isExtraSmall={props.extra_small}
            id="fake id"
        >
            <DotContainer isExtraSmall={props.extra_small}>
                <CustomAnimatedRadio
                    {...props}
                    onClick={() => {}}
                    onChange={() => {}}
                />
            </DotContainer>
            <div style={{ paddingLeft: props.extra_small ? '10px' : '7px' }} />
            <LabelName>{props.name}</LabelName>
        </Container>
    )
}

export default CustomAnimatedRadioField
