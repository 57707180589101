import { call, fork, put, select, take } from 'redux-saga/effects'
// WATCHER FUNCTION : watcher get car by id*
import { push } from 'connected-react-router'
import * as actions from '../actions'
import { api } from '../../../services'
import { ICarPayload, ICarUpdateFieldsPayload } from 'IapiDataPayload'
import {
    IAddACarInitialPayload,
    ICarsObject,
    ICustomErrorData,
    IExternalCarDataRequestPayload,
    IGalleryImagesObject,
    IHighlightedFactsObject,
    IReduxError,
    ITechnicalInformationObject,
} from 'entityModels'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../../conversions/entities/conversionFromAPI'

import * as galleryActions from '../../../entities/galleries/actions/index'
import * as highlighted_facts_actions from '../../../entities/highlighted_facts/actions/index'
import * as tech_info_actions from '../../../entities/technical_information/actions/index'
import * as garage_actions from '../../../entities/garages/actions'

import { getExternalCarDataByRegistrationNumber } from './getExternalCarDatByRegistrationNumber'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { IApiAddCar_args, IApiUpdateCar_args } from 'ApiInterfaces'
import {
    generateFBC,
    generateFBP,
    get_check_cookie_name,
    setCookie,
} from '../../../../helpers/cookies'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'

type IUpdateCarTitleAfterCreation = {
    car_id: string
    title: string
    car: ICarsObject
}

export function* updateCarTitleAfterCreationSaga(
    car_id: string,
    title: string,
    car: ICarsObject
) {
    let title_to_send: ICarUpdateFieldsPayload = {}

    title_to_send.title = title

    try {
        let api_payload: IApiUpdateCar_args = {
            car_id: car_id,
            dataToUpdate: title_to_send,
        }

        yield call(api.entities.updateCar.updateCar, api_payload)

        yield put(actions.actions.writeCarDataGeneralSuccess(car))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUpdateCarTitleAfterCreation = {
                car_id: car_id,
                title: title,
                car: car,
            }
            let p: IUnauthHandlerPayload = {
                functionToRepeat: updateCarTitleAfterCreationSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            yield put(actions.errorActions.writeCarDataGeneralError(typedError))
        }
    }
}

export type IsortOutAddACarAdTracking = {
    user_id: string
    first_name: string
    last_name: string
    email: string
}

export const sortOutAddACarAdTracking = (p: IsortOutAddACarAdTracking) => {
    let FBP = get_check_cookie_name('_fbp')
    let FBC = get_check_cookie_name('_fbc')

    const fbclidCookie: string | undefined = get_check_cookie_name('fbclid')

    const ua =
        !!window && window.navigator && window.navigator.userAgent
            ? window.navigator.userAgent
            : undefined

    const timeNow = Date.now()
    let event_id = `add_to_cart_${timeNow}`

    if (
        (fbclidCookie || FBP || FBC) &&
        process.env.REACT_APP_ENV !== 'local' &&
        process.env.REACT_APP_ENV !== 'development' &&
        process.env.REACT_APP_ENV !== 'preview'
    ) {
        const timeNow = Date.now()

        if (!FBC && fbclidCookie) {
            FBC = generateFBC(fbclidCookie, timeNow)
            setCookie({
                name: '_fbc',
                value: FBC,
                days: 180,
            })
        }

        if (!FBP && fbclidCookie) {
            FBP = generateFBP(timeNow)
            setCookie({
                name: '_fbp',
                value: FBP,
                days: 180,
            })
        }

        window &&
            window.dataLayer &&
            window.dataLayer.push({
                event: 'add_to_cart',
                first_party_collection: true,
                // external_id: p.user_id,
                event_id: event_id,
                email_address: p.email?.toLowerCase(),
                first_name: p.first_name,
                last_name: p.last_name,
                fbclid: fbclidCookie ?? undefined,
                fbc: FBC,
                fbp: FBP,
                user_agent: ua,
            })
    } else if (
        process.env.REACT_APP_ENV !== 'local' &&
        process.env.REACT_APP_ENV !== 'development' &&
        process.env.REACT_APP_ENV !== 'preview'
    ) {
        window &&
            window.dataLayer &&
            window.dataLayer.push({
                event: 'add_to_cart',
                first_party_collection: true,
                // external_id: p.user_id,
                event_id: event_id,
                email_address: p.email?.toLowerCase(),
                first_name: p.first_name,
                last_name: p.last_name,
                user_agent: ua,
            })
    }
}

const state_select_user = (state: RootState) => state.user.userLoggedIn

export function* createCarSaga(dataToSend: IAddACarInitialPayload) {
    try {
        let api_payload: IApiAddCar_args = {
            garage_id: dataToSend.garage_id,
            registration_number: dataToSend.registration_number,
        }
        let caritem: ICarPayload = yield call(
            api.entities.mutateGarage.addACar,
            api_payload
        )

        yield put(garage_actions.loadingActions.addCarToGarageRequest())

        if (dataToSend.isOnboarding === true) {
            // send datalayer stuff
            let udata: IUser | null = yield select(state_select_user)

            if (udata) {
                sortOutAddACarAdTracking({
                    user_id: udata.id,
                    email: `${udata.email}`,
                    first_name: `${udata.given_name}`,
                    last_name: `${udata.family_name}`,
                })
            }
        }

        let carObjects: ICarsObject = {}

        let galleryImagesObject: IGalleryImagesObject = {}

        let highlightedFactsObject: IHighlightedFactsObject = {}

        let technicalInformationObject: ITechnicalInformationObject = {}

        // prep titlwe

        let new_title = `Untitled #${dataToSend.numberOfCarsInGarage + 1}`

        caritem.title = new_title

        // convert to car state payload

        let car: ICarsObject = convertToCarState(caritem)

        let gallery_images_object: IGalleryImagesObject =
            convertToGalleryImagesState(caritem)

        let highlighted_facts_object: IHighlightedFactsObject =
            convertToHighlightedFactsState(caritem)

        let technical_information_object: ITechnicalInformationObject =
            convertToTechnicalInformationState(caritem)

        Object.assign(galleryImagesObject, gallery_images_object)

        Object.assign(carObjects, car)

        Object.assign(highlightedFactsObject, highlighted_facts_object)

        Object.assign(technicalInformationObject, technical_information_object)

        const results: ICarsObject = carObjects

        yield put(
            galleryActions.actions.setGalleryImagesSuccess(galleryImagesObject)
        )
        yield put(
            highlighted_facts_actions.actions.setHighlightedFactsSuccess(
                highlightedFactsObject
            )
        )
        yield put(
            tech_info_actions.actions.setTechnicalInformationSuccess(
                technicalInformationObject
            )
        )

        yield put(actions.actions.createCarSuccess(results))

        yield put(actions.actions.setCurrentCarSuccess(caritem.uid))

        // yield put(actions.loadingActions.writeCarDataGeneralRequest())

        yield call(updateCarTitleAfterCreationSaga, caritem.uid, new_title, car)

        if (dataToSend.isOnboarding) {
            if (
                dataToSend.registration_number !== undefined &&
                dataToSend.country === 'United Kingdom'
            ) {
                yield put(
                    push(
                        `/onboarding-step-2-2?carid=${caritem.uid}&from_ves_api=true&reg_no=${dataToSend.registration_number}`
                    )
                )
            } else {
                yield put(push(`/onboarding-step-2-1?carid=${caritem.uid}`))
            }
        } else {
            if (
                dataToSend.registration_number !== undefined &&
                dataToSend.country === 'United Kingdom'
            ) {
                let payload_2: IExternalCarDataRequestPayload = {
                    car_id: caritem.uid,
                    registration_number: dataToSend.registration_number,
                }

                yield call(getExternalCarDataByRegistrationNumber, payload_2)
            } else {
                {
                    if (dataToSend.country === 'United Kingdom') {
                        yield put(
                            push(
                                `/car/${caritem.uid}/confirm?from_ves_api=false`
                            )
                        )
                    } else
                        yield put(
                            push(
                                `/car/${caritem.uid}/confirm?from_ves_api=false&foreign=true`
                            )
                        )
                }
            }
        }

        // de-commented this as it's neccessary for the other pages that use garage data (ex: history file, shared with others)
        let new_car_uid = caritem.uid

        yield put(
            garage_actions.actions.addACarToGarageSuccess({
                garage_id: dataToSend.garage_id,
                car_id: new_car_uid,
            })
        )

        yield put(
            garage_actions.actions.resetSortedCarsInGarage(dataToSend.garage_id)
        )

        // Capture event
        posthog.capture('ADD CAR')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: createCarSaga,
                payload: dataToSend,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't add your car`,
                custom_user_action_text: 'Return to garage',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(
                garage_actions.errorActions.addACarToGarageError(customError)
            )
            //  yield put(actions.errorActions.getCarDataByIdError(customError))
        }
    }
}

function* watcherCreateCar() {
    while (true) {
        const { payload } = yield take(actions.loadingActions.createCarRequest)

        yield call(createCarSaga, payload)
    }
}

const create_car_sagas: any[] = [fork(watcherCreateCar)]

export default create_car_sagas
