import { call, fork, put, select, take, join } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'

import { api } from '../../../services'
import {
    entityDeletionSaga,
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from './get'
import posthog from 'posthog-js'

export function* Submit_application_details(): any {
    try {
        posthog.capture('Update insurance application insured vehicles')
        let application_has_deletions = false
        let application_has_patch = false

        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft: string[] = all_draft.vehicles_to_insure_ids_list
        let existing: string[] = all_existing.vehicles_to_insure_ids_list

        let dataToSendToPatch: IAnyObject = {}

        let carIDSToAdd: IAnyObject[] = []
        let customer_new_custodian_cars_to_add: IAnyObject[] = []

        let carIDsToUnlink: string[] = []

        existing.forEach((carID: string) => {
            if (draft.indexOf(carID) === -1) {
                // get the api_uid here

                let api_uid: string | undefined =
                    all_existing && all_existing.vehicles[carID]?.api_uid

                // console.log(
                //     'carID',
                //     carID,
                //     ' all_existing.vehicles[carID]',
                //     all_existing.vehicles[carID]
                // )

                if (api_uid) {
                    carIDsToUnlink = [...carIDsToUnlink, `${api_uid}`]
                }
            }
        })

        // console.log('carIDsToUnlink', carIDsToUnlink)

        if (carIDsToUnlink.length > 0) {
            let arrayOfDeletionTasks = []
            for (let i = 0; i < carIDsToUnlink.length; i++) {
                let req_del: IDeleteInsuranceApplication_req = {
                    appli_id: `${all_existing.id}`,
                    entity_id: carIDsToUnlink[i],
                    entity_type: 'application_custodian_cars',
                }
                const deletionTask = yield fork(entityDeletionSaga, {
                    ...req_del,
                })
                arrayOfDeletionTasks.push(deletionTask)
            }

            if (arrayOfDeletionTasks.length > 0) {
                yield join(arrayOfDeletionTasks)
                application_has_deletions = true
            }
        }

        draft.forEach((carID: string) => {
            if (existing.indexOf(carID) === -1) {
                // get the api_uid here

                let api_uid: string | undefined =
                    all_existing && all_existing.vehicles[carID]?.api_uid
                // no check here because if error user needs to know

                if (!api_uid) {
                    customer_new_custodian_cars_to_add = [
                        ...customer_new_custodian_cars_to_add,
                        { external_id: carID },
                    ]
                    carIDSToAdd = [...carIDSToAdd, { external_id: carID }]
                } else {
                    carIDSToAdd = [...carIDSToAdd, { uid: api_uid }]
                }
            }
        })

        if (carIDSToAdd.length > 0) {
            dataToSendToPatch = {
                ...dataToSendToPatch,
                application_custodian_cars: [...carIDSToAdd],
            }

            if (
                customer_new_custodian_cars_to_add &&
                customer_new_custodian_cars_to_add.length > 0
            ) {
                dataToSendToPatch = {
                    ...dataToSendToPatch,
                    customer: {
                        custodian_cars: [...customer_new_custodian_cars_to_add],
                    },
                }
            }
            // console.log('data to patch', dataToSendToPatch)
            // call api to patch as thats all the data merged we want to patch
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )
            application_has_patch = true

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_vehiclesToInsure_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_vehiclesToInsure_success())
        }

        if (carIDSToAdd.length === 0 && carIDsToUnlink.length > 0) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_application_details,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/vehicles`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_vehiclesToInsure_error(customError)
            )
        }
    }
}

function* Watcher_Submit_vehicles_to_insure() {
    while (true) {
        yield take(insuranceActions.submit_vehiclesToInsure_request)
        yield call(Submit_application_details)
    }
}

const vehicles_to_insure_submit: any[] = [
    fork(Watcher_Submit_vehicles_to_insure),
]

export default vehicles_to_insure_submit
