import * as React from 'react'
import styled from 'styled-components'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import Icon from '../../atoms/icons'
import { device } from '../../templates/displays/devices'
// import VoteUp from "../../atoms/vote/voteUp";
import bin_button_circle_blue from '../../../public/assets/icons/bin_button_circle_blue.svg'
import { motion } from 'framer-motion'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
}

const ListItem = styled.li<IStyle>`
    margin-top: 10px;
    margin-bottom: 10px;
    list-style-type: none;
    background-color: ${(props) => colours[props.$theme].primary_09};
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    box-shadow: 5px 3px 7px
        ${(props) => colours[props.$theme].shadow_strongest_03};
    height: 100%;
    @media ${device.beyond_ipad_mobile} {
        margin-top: 10px;
        margin-bottom: 10px;
        list-style-type: none;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 750px;
        border-radius: 5px;
        box-shadow: none;
    }

    @media ${device.smallest_laptops} {
        width: 750px;
    }
    opacity: 1 !important;
    visibility: visible !important;
`

const ListContentRow = styled.div`
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media ${device.beyond_ipad_mobile} {
        padding-left: 25px;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
`

// const Separation = styled.div`
//   margin-left: 15px;
//   height: 40px;

//   @media ${device.beyond_ipad_mobile} {
//     margin-left: 25px;
//     height: 40px;
//   }
// `;

const Handler = styled.div`
    width: 20px;
    margin-right: 10px;
    cursor: pointer;
    :hover {
        transform: scale(1.15);
        transition: all 300ms;
    }

    @media ${device.beyond_ipad_mobile} {
        position: absolute;
        right: 10px;
        width: 40px;
        cursor: pointer;
        margin-right: 0px;
    }
`

// const HighlightAnswer = styled.textarea`
//   border: none;
//   border-radius: 5px;
//   text-align: center;
//   font-family: Lato;
//   background-color: #faffff;
//   padding-top: 15px;
//   height: 50px;
//   width: 230px;
//   font-size: 13px;
//   :focus {
//     outline: 0;
//   }
// `;

const Text = styled.textarea<IStyle>`
    font-family: Lato;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    width: 55vw;
    color: ${(props) => colours[props.$theme].text_strong};
    border-radius: 5px;
    font-size: 0.9em;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    border: none;
    resize: none;
    :focus {
        outline: 0;
    }

    @media ${device.beyond_ipad_mobile} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        padding-bottom: 5px;
        height: auto;
        color: ${(props) => colours[props.$theme].text_strong};
        font-size: 1em;
        width: 600px;
        min-height: 80px;
    }

    @media ${device.smallest_laptops} {
        width: 600px;
        padding-right: 20px;
    }
`

const Delete = styled.img`
    width: 26px;
    height: 26px;
    cursor: pointer;
    transition: all 200ms;

    :hover {
        transform: scale(1.1);
        transition: all 200ms;
    }

    @media ${device.beyond_ipad_mobile} {
    }
`

const DragHandle = SortableHandle(() => (
    <Handler>
        <motion.div whileTap={{ scale: 1.3 }}>
            <Icon icon="handler" />
        </motion.div>
    </Handler>
))

const SortableItem = SortableElement(
    ({
        item,
        formikprops,
        index,
    }: {
        item: EnumerableObject
        formikprops: any
        index: number
    }) => {
        const { theme } = useThemes()
        return (
            <ListItem $theme={theme} key={index} style={{ zIndex: 5 }}>
                <ListContentRow>
                    {/* <VoteUp number={item.upvotes} />
        <Separation /> */}

                    <Text
                        $theme={theme}
                        id={item.id}
                        name={item.id}
                        placeholder={
                            item.order === 1
                                ? 'i.e. First purchased the car...'
                                : item.order === 2
                                ? 'i.e. Special because...'
                                : item.order === 3
                                ? 'i.e. Favourite moment behind the wheel...'
                                : item.order === 4
                                ? 'i.e. Highlight of ownership...'
                                : item.order === 5
                                ? 'i.e. Started restoration process...'
                                : ''
                        }
                        // defaultValue={
                        //     formikprops.values[item.id] !== undefined
                        //         ? formikprops.values[item.id]
                        //         : ''
                        // }
                        onChange={formikprops.handleChange}
                        value={
                            formikprops.values[item.id] !== undefined
                                ? formikprops.values[item.id]
                                : ''
                        }
                        maxLength={200}

                        // value={
                        //     formikprops.values.data_object[item.id] !== undefined
                        //         ? formikprops.values.data_object[item.id].text
                        //         : ''
                        // }
                    />
                    {(formikprops.values[item.id] ||
                        formikprops.values[item.id] !== '') && (
                        <>
                            <div style={{ paddingLeft: '22px' }} />
                            <motion.div
                                whileTap={{ scale: 1.1 }}
                                onClick={() => {
                                    formikprops.setFieldValue(item.id, '', true)
                                }}
                            >
                                <Delete src={bin_button_circle_blue} />
                            </motion.div>
                        </>
                    )}

                    {(formikprops.values[item.id] ||
                        formikprops.values[item.id] !== '') && <DragHandle />}
                </ListContentRow>
            </ListItem>
        )
    }
)

type EnumerableObject = {
    id: string
    text: string
    order: number
    formikprops: any
}

type Props = {
    item: EnumerableObject
    index: number
    formikprops: any
}

type State = {
    item: EnumerableObject
}
class DraggableItemEditable extends React.Component<Props, State> {
    state = {
        item: this.props.item,
    }

    componentDidMount() {
        this.setState({ item: this.props.item })
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props !== prevProps) {
            this.setState({ item: this.props.item })
        }
    }

    render() {
        const { index, formikprops } = this.props

        const { item } = this.state

        return (
            <>
                <SortableItem
                    key={index}
                    index={index}
                    item={item}
                    formikprops={formikprops}
                />
            </>
        )
    }
}

export default DraggableItemEditable
