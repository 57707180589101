import styled from 'styled-components'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { HistoryFilesIcon, TasksIcon } from '../../icons/components'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
`
const Text = styled.div<{ $theme: ITheme }>`
    letter-spacing: 5e-5em;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: ${(props) => colours[props.$theme].text_default};
`

type Props = {
    car_entries_count: number
    outstanding_tasks_number: number
}
const CarOverviewInfoRow = (props: Props) => {
    const { theme } = useThemes()
    return (
        <Row>
            <Row>
                <HistoryFilesIcon color={colours[theme].text_default} />
                <div style={{ paddingLeft: '10px' }} />
                <Text $theme={theme}>
                    {props.car_entries_count} History File
                    {props.car_entries_count > 0 ? 's' : ''}
                </Text>
            </Row>

            <div style={{ paddingLeft: '22px' }} />

            <Row>
                <TasksIcon color={colours[theme].text_default} />
                <div style={{ paddingLeft: '10px' }} />
                <Text $theme={theme}>
                    {props.outstanding_tasks_number} Task
                    {props.outstanding_tasks_number > 0 ? 's' : ''}
                </Text>
            </Row>
        </Row>
    )
}

export default CarOverviewInfoRow
