import * as React from 'react'
import styled from 'styled-components'
// import { device } from '../../templates/displays/devices'
import { RouteComponentProps, withRouter } from 'react-router-dom'
// import Icon from '../../atoms/icons'
import { ITimelineItem } from 'timelineModels'
// import FatTimelineItemDesktop from '../../molecules/fatTimelineItem/fatTimelineItemDesktop'
import { INormalisedAttachmentsByID } from 'attachmentModels'
// import TimelineItemDesktop from '../../molecules/timelineItemDesktop/timelineItemDesktop'
import SmallTimelineItemMobile from '../../molecules/smallTimelineItem/smallTimelineItemMobile'
import { Swiper, SwiperSlide } from 'swiper/react'
import './styles.css'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import { ICar, IExternalCar } from 'entityModels'
import skeletonMobile from '../../atoms/skeleton/historyFile/skeletonMobile.jpg'
import skeletonMobileDark from '../../atoms/skeleton/historyFile/skeletonMobileDark.png'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import CarInsightsBtn from '../../atoms/carInsights/btn'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import colours, { ITheme } from '../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    align-self: center;
    justify-self: center;
`
const OverWrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const SmallItemOverWrapper = styled.section`
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    align-self: center;
    justify-self: center;
`

// const ViewAll = styled(Link)`
//     color: #5ec3ca;
//     font-size: 14px;
//     font-family: Lato;
//     justify-self: end;
//     padding-top: 15px;
//     text-transform: lowercase;
//     align-self: flex-end;
// `

const LineContainer = styled.div`
    width: 93vw;
    position: relative;
`

const Line = styled.div<IStyle>`
    width: 100%;
    position: absolute;
    left: 0;
    top: 15px;
    height: 7px;
    background-color: ${(props) => colours[props.$theme].background_muted};
`

const Buttons = styled.div`
    position: relative;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 40px;
    padding-right: 40px;
`

// const ItemCount = styled(Link)`
//     height: 26px;
//     padding: 6px;
//     background-color: var(--bg-color, #fff);
//     color: #aeaeae;
//     font-size: 12px;
//     border: 1px solid #5ec3ca;
//     border-radius: 5px;
//     position: absolute;
//     top: 25px;
//     right: 0;
//     display: flex;
//     align-items: center;
//     padding-right: 10px;
//     padding-left: 10px;
// `
const EmptyBtn = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
`

const LineProgress = styled.div<IStyle>`
    position: relative;
    width: 22px;
    height: 36px;
    border: 7px solid ${(props) => colours[props.$theme].background_default};
    background-color: ${(props) => colours[props.$theme].border_muted};
    border-radius: 10px;
    opacity: 1;
    transform: none !important;
    left: auto !important;
`

interface Props extends RouteComponentProps<any> {
    entries_array: ITimelineItem[] | undefined | null
    attachmentsObj?: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    onClick?: any
    history: any
    carid: string
    onPlaceholderClick: any
    car_entries_count: number
    userCurrency: string | undefined
    readOnlyMode?: boolean
    sharedWithYouCar?: IExternalCar
    ownedCar?: ICar
}

type State = {
    activeIndex: number
    translateX?: number
}

class TimelineOverviewManagerMobile extends React.Component<Props, State> {
    state = {
        activeIndex: 0,
        translateX: 0,
    }

    private paginationRef = React.createRef<HTMLDivElement>()

    componentDidMount() {}

    checkIfInfoIsRestricted = () => {
        let sharedWithYouCar = this.props.sharedWithYouCar
        if (
            this.props.readOnlyMode &&
            sharedWithYouCar &&
            sharedWithYouCar.policy_statements
        ) {
            if (!sharedWithYouCar.policy_statements.includes('history file')) {
                return true
            }
        }
        return false
    }

    render() {
        const {
            entries_array,
            carid,
            onPlaceholderClick,
            car_entries_count,
            userCurrency,
            readOnlyMode,
            // attachmentsObj,
            // costsObj,
        } = this.props

        // <AddInfoBannerMobile
        //     onClick={onPlaceholderClick}
        //     action="add"
        //     height="155px"
        //     width="93vw"
        //     placeholder="add your car’s documentation & history"
        // />

        const setActiveIndex = (index: number) =>
            this.setState({ activeIndex: index })

        let linkPrefix =
            this.props.match.url && this.props.match.url.includes('overview')
                ? this.props.match.url.replace('/overview', '')
                : this.props.match.url

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <OverWrapper>
                        {/* show disabled buttons when no entries or ledd than 3 */}
                        <LineContainer>
                            <Line $theme={theme} />
                            <Buttons>
                                <div
                                    style={{
                                        position: 'absolute',
                                    }}
                                    ref={this.paginationRef}
                                    id="timeline-overview-carousel"
                                />
                                <div
                                    style={{
                                        opacity: 0,
                                        zIndex:
                                            !entries_array || !entries_array[0]
                                                ? 'auto'
                                                : -1,
                                    }}
                                >
                                    <LineProgress
                                        $theme={theme}
                                        onClick={() => {}}
                                    />
                                </div>
                                <div
                                    style={{
                                        opacity:
                                            !entries_array || !entries_array[1]
                                                ? 1
                                                : 0,
                                        zIndex:
                                            !entries_array || !entries_array[1]
                                                ? 'auto'
                                                : -1,
                                    }}
                                >
                                    <LineProgress
                                        $theme={theme}
                                        onClick={() => {}}
                                    />
                                </div>
                                <div
                                    style={{
                                        opacity: 0,
                                        // !entries_array || !entries_array[2] ? 0 : 0,
                                        zIndex:
                                            !entries_array || !entries_array[2]
                                                ? 'auto'
                                                : -1,
                                    }}
                                >
                                    <LineProgress
                                        $theme={theme}
                                        onClick={() => {}}
                                    />
                                </div>
                            </Buttons>

                            <div
                                style={{
                                    paddingTop: '50px',
                                }}
                            />

                            <Wrapper>
                                {/* no entries */}
                                {(!entries_array ||
                                    car_entries_count === 0 ||
                                    entries_array.length === 0) &&
                                    (!readOnlyMode ? (
                                        <div
                                            onClick={() => onPlaceholderClick()}
                                            style={{
                                                position: 'relative',
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            <EmptyBtn>
                                                <ButtonAtom
                                                    width={'250px'}
                                                    theme={
                                                        'lowercase-blue-background'
                                                    }
                                                    fontSize={14}
                                                    onClick={() => {}}
                                                >
                                                    <div
                                                        style={{
                                                            paddingLeft: '10px',
                                                            paddingRight:
                                                                '10px',
                                                            textTransform:
                                                                'none',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Create your car’s
                                                        digital history
                                                    </div>
                                                </ButtonAtom>
                                            </EmptyBtn>

                                            <img
                                                style={{
                                                    width: '100%',
                                                    height: '180px',
                                                }}
                                                src={
                                                    theme === 'light'
                                                        ? skeletonMobile
                                                        : skeletonMobileDark
                                                }
                                                alt="skeleton"
                                            />
                                        </div>
                                    ) : (
                                        <NoDataRectangle
                                            text="no data"
                                            isInfoRestricted={this.checkIfInfoIsRestricted()}
                                        />
                                    ))}

                                {entries_array &&
                                    entries_array.length > 0 &&
                                    car_entries_count > 0 && (
                                        <Swiper
                                            spaceBetween={50}
                                            slidesPerView={1}
                                            loop={true}
                                            direction={'horizontal'}
                                            pagination={{
                                                el: this.paginationRef.current,
                                                clickable: true,
                                                dynamicBullets: true,
                                                bulletClass:
                                                    'timeline-overview-blue-bullet-class',
                                                bulletActiveClass:
                                                    'timeline-overview-active-bullet-class',
                                            }}
                                            onBeforeInit={(swiper: any) => {
                                                swiper.params.navigation.el =
                                                    this.paginationRef.current
                                            }}
                                            scrollbar={{ draggable: true }}
                                            onSlideChange={(swiper) => {
                                                // swiper.activeIndex > 0 &&
                                                //     setCurrentEntry(swiper.activeIndex - 1)
                                                swiper.activeIndex > 0 &&
                                                    setActiveIndex(
                                                        swiper.activeIndex - 1
                                                    )
                                                swiper.allowTouchMove =
                                                    entries_array.length > 1
                                                        ? true
                                                        : false
                                            }}
                                        >
                                            {entries_array &&
                                                entries_array.length > 0 &&
                                                car_entries_count > 0 &&
                                                entries_array.map(
                                                    (
                                                        item: ITimelineItem,
                                                        index: any
                                                    ) =>
                                                        index < 3 && (
                                                            <SwiperSlide
                                                                key={index}
                                                            >
                                                                <>
                                                                    {/* <CarouselPadding /> */}
                                                                    <SmallItemOverWrapper>
                                                                        <SmallTimelineItemMobile
                                                                            item={
                                                                                item
                                                                            }
                                                                            iscaroverview
                                                                            onClick={() =>
                                                                                this.props.history.push(
                                                                                    `${linkPrefix}/history-file/entry?entryid=${item?.id}`
                                                                                )
                                                                            }
                                                                            onAddNewLabelClick={() =>
                                                                                this.props.history.push(
                                                                                    `/car/${carid}/history-file/entry?entryid=${item.id}&section_id=labels`
                                                                                )
                                                                            }
                                                                            userCurrency={
                                                                                userCurrency
                                                                            }
                                                                        />
                                                                    </SmallItemOverWrapper>
                                                                </>
                                                            </SwiperSlide>
                                                        )
                                                )}
                                        </Swiper>
                                    )}
                            </Wrapper>

                            {/* show 'View all' if more entries than 3 */}
                            {/* 
                    <ItemCount
                        to={{
                            pathname: !readOnlyMode
                                ? `/car/${carid}/history-file`
                                : `/shared/car/${carid}/history-file`,
                            state: { prevPath: this.props.match.url },
                        }}
                    >
                        {entries_array && car_entries_count > 3
                            ? `+${car_entries_count - 3}`
                            : car_entries_count}
                    </ItemCount> */}
                        </LineContainer>

                        {/* {entries_array && car_entries_count > 3 && (
                    <ViewAll
                        to={{
                            pathname: !readOnlyMode
                                ? `/car/${carid}/history-file`
                                : `/shared/car/${carid}/history-file`,
                            state: { prevPath: this.props.match.url },
                        }}
                    >
                        View all
                    </ViewAll>
                )} */}

                        <div
                            style={{
                                paddingTop: '32px',
                            }}
                        />

                        {readOnlyMode ? null : <CarInsightsBtn />}

                        <div
                            style={{
                                paddingTop: '150px',
                            }}
                        />
                    </OverWrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(TimelineOverviewManagerMobile)
