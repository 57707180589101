import styled from 'styled-components'
import React, { useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import grey_tip from '../../../../public/assets/icons/grey_tip.svg'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Scrolllock from 'react-scrolllock'
import TutorialWrapperDesktop from '../tutorialWrappers/TutorialWrapperDesktop'

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 24px;
    text-align: center;

    @media ${device.mobile} {
        display: none;
    }
`

const Text = styled.p`
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 17px;
    line-height: 1.9;

    @media ${device.mobile} {
    }
`

const Padding = styled.div`
    padding-top: 25px;

    @media ${device.mobile} {
    }
`
const TextPadding = styled.div`
    padding-top: 8px;

    @media ${device.mobile} {
    }
`

const Tip = styled.img`
    width: 20px;
    @media ${device.mobile} {
    }
`

const TipText = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    padding-left: 20px;
    font-size: 16px;

    @media ${device.mobile} {
    }
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
`

const TourStyled = styled((props: any) => <Tour {...props} />)`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: ${(props) =>
            props.currentStep && props.currentStep !== 1 ? '550px' : '400px'};
    }

    margin-top: ${(props) =>
        props.currentStep && props.currentStep === 1
            ? '6vh !important'
            : 'auto'};
`
export type ReactourStepPosition =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | [number, number]

let bottomPositionStrongType: ReactourStepPosition = 'bottom'

const tutorialConfigs_Garage = (close: any) => [
    {
        selector: '[data-tut="tasks-tuto-desktop-1"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperDesktop
                skip={close}
                next={() => {
                    goTo(1)
                }}
            >
                <Title>Tasks, Simplified.</Title>

                <Padding />

                <Text>
                    Stay organised by keeping track of your car related tasks
                    all in one place.
                </Text>

                <Text>
                    You can set due dates and once tasks are completed, they are
                    saved so that you can keep track of what has been done to
                    your car(s) over time.
                </Text>

                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>
                        Tasks can be applied to multiple vehicles.
                    </TipText>
                </TipRow>
            </TutorialWrapperDesktop>
        ),
    },
    {
        selector: '[data-tut="tasks-tuto-desktop-2"]',
        content: ({ goTo }: any) => (
            <TutorialWrapperDesktop
                skip={close}
                prev={() => {
                    goTo(0)
                }}
                next={close}
                nextBtnText="close"
            >
                <Title>Sort & Filter</Title>

                <Padding />

                <Text>You can select a car, filter and sort tasks.</Text>

                <TextPadding />

                <Text>
                    By default, all the garage tasks are shown, filtered by
                    ‘Oustanding’ and sorted by ‘Date Created.’
                </Text>

                <TextPadding />
                <TextPadding />

                <Text onClick={() => close()}>
                    <div
                        style={{
                            color: 'var(--primary, #5EC3CA)',
                            cursor: 'pointer',
                        }}
                    >
                        Got it
                    </div>
                </Text>
            </TutorialWrapperDesktop>
        ),
        position: bottomPositionStrongType,
    },
]

interface Props extends RouteComponentProps<any> {}

const TutorialConfigsTasksDesktop: React.FunctionComponent<Props> = (props) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(true)

    const setCurrentStepCustom = (step: number) => {
        setCurrentStep(step)
    }

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        props.history.push(`/tasks`)
    }

    return (
        <>
            {window.innerWidth > 880 && (
                <TourStyled
                    steps={tutorialConfigs_Garage(setIsTourOpenCustom)}
                    isOpen={isTourOpen}
                    onRequestClose={() => undefined}
                    rounded={5}
                    accentColor="#5ec3ca"
                    getCurrentStep={(curr: number) => {
                        window.scrollTo(0, 0)
                        return setCurrentStepCustom(curr + 1)
                    }}
                    disableInteraction={true}
                    showCloseButton={false}
                    currentStep={currentStep}
                />
            )}
            <Scrolllock />\
        </>
    )
}

export default withRouter(TutorialConfigsTasksDesktop)
