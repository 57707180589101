import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours from '../../../../providers/theme/colours'
import Faded from '../../../templates/animated/faded'
import image from '../../../../public/assets/images/journey/insurance/3.png'

import {
    JourneyColumnContent,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function JourneyInsuranceHowItWorksExtraStepMobile({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent
                                    style={{
                                        gap: 4,
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{ paddingRight: '40px' }}
                                    >
                                        How does it work
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Learn about Custodian Insurance
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        paddingLeft: 24,
                                        paddingRight: 24,
                                    }}
                                >
                                    Fill in your application in any preferred
                                    order, or jump to step-by-step experience.
                                    Click the play button at anytime to switch
                                    it.
                                </JourneyText>
                                <JourneyColumnContent>
                                    <img
                                        src={image}
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '100vw',
                                            maxHeight: '80vh',
                                        }}
                                    />
                                </JourneyColumnContent>
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="insurance"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
