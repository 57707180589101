import { IGalleryImage } from 'entityModels'
import * as React from 'react'

import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import { device } from '../../templates/displays/devices'
import ButtonAtom from '../Button/ButtonAtom'
import Icon from '../icons'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'

type IImgProps = {
    isthingloading?: boolean
    height?: string
    ipadHeight?: string
}
const ImageStyleWrapper = styled.div<IImgProps>`
    width: 100%;
    position: relative;
    object-fit: cover !important;
    height: ${(props) => (props.height ? props.height : '230px')};

    @media ${device.ipad} {
        height: ${(props) =>
            props.ipadHeight
                ? props.ipadHeight
                : props.height === '160px'
                ? '230px'
                : '350px'};
        /* max-width: 93vw; */
        margin: 0 auto;
    }
    z-index: 1;
`

const ImageStyle = styled.img<IImgProps>`
    width: 100%;
    height: 100%;
    object-fit: cover !important;

    opacity: ${(props) => (props.isthingloading === false ? 1 : 0)};
    transition: opacity 400ms;

    z-index: 1;
`

const Wrapper = styled.div`
    /* width: 100vw; */
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    @media ${device.ipad} {
        /* max-width: 93vw; */
        margin: 0 auto;
    }
`

const ThreeDotsEdit = styled.div`
    align-self: flex-end;
    justify-self: flex-end;
    padding-right: 30px;
    padding-left: 30px;
    transform: translateY(-50px);
    z-index: 2;
    /* background-color: red; */
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0px;
`

export interface IImageProps {
    img?: IGalleryImage
    placeholder?: string
    height?: string
    ipadHeight?: string
    onClick?: any
    threeDotsMenu?: boolean
    onLoad?: any
    isDarkTheme?: boolean
    aspectRatio?: number
}

type State = {
    src: any
    isLoading: boolean
}

class FullWidthMobile extends React.Component<IImageProps, State> {
    state = {
        src: null,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src =
            this.props.img && this.props.img.url !== undefined
                ? this.props.img.url
                : this.props.placeholder
                ? this.props.placeholder
                : undefined

        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: IImageProps) {
        if (this.props.img?.url !== prevProps.img?.url) {
            const src =
                this.props.img && this.props.img.url !== undefined
                    ? this.props.img.url
                    : this.props.placeholder
                    ? this.props.placeholder
                    : undefined

            if (src) {
                return this.setState({ src: src, isLoading: false })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        const { threeDotsMenu, onClick, height, ipadHeight, aspectRatio } =
            this.props
        const { isLoading } = this.state

        return (
            <Wrapper>
                <SkeletonAbsolute
                    mobileHeight={height && height}
                    isthingloading={isLoading}
                    darkTheme={this.props.isDarkTheme}
                />

                <ImageStyleWrapper
                    ipadHeight={ipadHeight && ipadHeight}
                    height={height && height}
                    style={
                        aspectRatio
                            ? { aspectRatio: aspectRatio, height: 'auto' }
                            : {}
                    }
                >
                    <ImageStyle
                        src={this.state.src || grey_placeholder}
                        onClick={(e: any) => {
                            onClick && !threeDotsMenu && onClick()
                        }}
                        isthingloading={isLoading}
                        srcSet={
                            this.props.img && this.props.img.small_srcset
                                ? this.props.img.small_srcset
                                : undefined
                        }
                        onLoad={() => {
                            // setTimeout(() => {
                            this._isMounted &&
                                this.setState({
                                    ...this.state,

                                    isLoading: false,
                                })
                            this.props.onLoad && this.props.onLoad(true)
                            // }, 100)
                        }}
                    />

                    {threeDotsMenu && !isLoading && (
                        <ThreeDotsEdit
                            onClick={(e: any) => {
                                e && e.preventDefault()
                                onClick && onClick()
                            }}
                        >
                            <div style={{ transform: 'translateY(10px)' }}>
                                <ButtonAtom
                                    theme="naked"
                                    // onClick={(e: any) => {
                                    //     e && e.preventDefault()
                                    //     onClick && onClick()
                                    // }}
                                >
                                    <Icon icon="three_dots_vertical" />
                                </ButtonAtom>
                            </div>
                        </ThreeDotsEdit>
                    )}
                </ImageStyleWrapper>
            </Wrapper>
        )
    }
}

export default FullWidthMobile
