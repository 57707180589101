import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import {
    ICostItem,
    IFilterTimelineByCategoryNLabelNTitleQCarIDRequest,
    IGetTimelineItemByIdPayloadRequest,
    ITimelineCreationFormFieldsData,
    ITimelineDataByCarNormalised,
    ITimelineDataToBeSubmittedObj,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'
import { ITimelineCreateForms, ITimelineEditForms, IUser } from 'myModels'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'

import { ICar, IDropdownItem } from 'entityModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'

import {
    addTimelineItemToCarRequest,
    getAllTimelineItemsByCarIDRequest,
    filterTimelineItemsByCarCategoryIdsNLabelIdsRequest,
    getTimelineItemByIDRequest,
} from '../../../redux/timeline/actions/requestActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import TimelineCreateManagerDesktop from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerDesktop'
import TimelineCreateManagerMobile from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerMobile'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import { clearDataToBeSubmittedSuccess } from '../../../redux/timeline/actions/successActions'
import Loader from '../../atoms/loader/loader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

interface Props extends RouteComponentProps<any> {
    activeEntry: ITimelineItem | undefined | null
    activeCost: ICostItem | undefined | null
    timelineItemEditForm: ITimelineEditForms
    dataCyId?: string
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    setCurrentCar: (car_id: string) => void
    carsData: {
        [key: string]: ICar
    }
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted: ITimelineDataToBeSubmittedObj
    addTimelineItemToCarRequest: (payload: {
        carid: string
        item: ITimelineItem
    }) => void
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemByIdPayloadRequest
    ) => void
    labels_searchable_list: IDropdownItem[]
    getCarDataByIdRequest: (carid: string) => {}
    clearDataToBeSubmitted: (payload: boolean) => void
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) => {}
    setInitialLandingUrl: (str: string) => void
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        carsData: state.entities.carsData.cars,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        timeline_creation_steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        timeline_creation_steps_object_of_indexed_list:
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list,
        data_to_be_submitted:
            state.timeline.formsData.creation.data_to_be_submitted,
        activeEntry: state.timeline.activeEntry,
        activeCost: state.timeline.activeCost,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
    addTimelineItemToCarRequest: (payload: {
        carid: string
        item: ITimelineItem
    }) => addTimelineItemToCarRequest(payload),
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemByIdPayloadRequest
    ) => getAllTimelineItemsByCarIDRequest(payload),
    filterTimelineItemsByCarCategoryIdsNLabelIdsRequest: (
        payload: IFilterTimelineByCategoryNLabelNTitleQCarIDRequest
    ) => filterTimelineItemsByCarCategoryIdsNLabelIdsRequest(payload),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    clearDataToBeSubmitted: (payload: boolean) =>
        clearDataToBeSubmittedSuccess(payload),
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) =>
        getTimelineItemByIDRequest(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

type State = {}

class CreateCost extends React.PureComponent<Props, State> {
    state = {}

    componentDidMount() {
        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/history-file/${this.props.match.params.entryid}/cost/create`
        )

        const userData = this.props.userLoggedIn

        if (!userData) {
            this.props.getUserDataRequest()
        }

        let carid = this.props.match.params.carid
        this.props.setCurrentCar(carid)

        if (!this.props.carsData[carid]) {
            this.props.getCarDataByIdRequest(this.props.match.params.carid)
        }

        window.scrollTo(0, 0)

        let entryid = this.props.match.params.entryid

        if (!this.props.activeEntry) {
            this.props.getEntryByIDRequest({
                entryUID: entryid,
                car_id: carid,
            })
        }

        if (window.innerWidth > 880) {
            this.props &&
                this.props.manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: 0,
                    active_creation: TimelineCreationTypeIdsEnum.cost_desktop,
                })
        } else {
            this.props &&
                this.props.manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: 0,
                    active_creation: TimelineCreationTypeIdsEnum.cost,
                })
        }
    }

    componentWillUnmount() {
        this.props.manageTimelineCreationFormState({
            isOpen: false,
            current_step_index: 0,
            active_creation: null,
        })

        this.props.clearDataToBeSubmitted(true)
    }

    render() {
        let carid = this.props.match.params.carid

        let {
            manageTimelineCreationFormState,
            timeline_creation_forms_current_state,
            timeline_creation_steps_data,
            timeline_creation_steps_object_of_indexed_list,
            data_to_be_submitted,
            activeEntry,
        } = this.props

        let close_create_form = () => {
            manageTimelineCreationFormState({
                isOpen: false,
                current_step_index: 0,
                active_creation: null,
            })
            this.props.clearDataToBeSubmitted(true)
        }

        return (
            <Faded>
                <CenteredPageWrapper fullwidth>
                    <Loader
                        isLoading={
                            timeline_creation_forms_current_state.isOpen ===
                            false
                        }
                    />
                    <DesktopDisplayOnly>
                        <TimelineCreateManagerDesktop
                            carID={carid}
                            data_to_be_submitted={data_to_be_submitted}
                            timeline_creation_forms_current_state={
                                timeline_creation_forms_current_state
                            }
                            timeline_creation_steps_data={
                                timeline_creation_steps_data
                            }
                            manageTimelineCreationFormState={
                                manageTimelineCreationFormState
                            }
                            timeline_creation_steps_object_of_indexed_list={
                                timeline_creation_steps_object_of_indexed_list
                            }
                            close_create_form={close_create_form}
                            entityID={
                                data_to_be_submitted.cost &&
                                data_to_be_submitted.cost.id
                                    ? data_to_be_submitted.cost?.id
                                    : 'NEW_entryUID'
                            }
                            entityType="cost"
                            activeEntry={activeEntry}
                        />
                    </DesktopDisplayOnly>

                    <IpadAndMobileDisplay>
                        <BottomUpSlider
                            show={
                                this.props.timeline_creation_forms_current_state
                                    .isOpen
                            }
                        >
                            <TimelineCreateManagerMobile
                                carID={carid}
                                data_to_be_submitted={data_to_be_submitted}
                                timeline_creation_forms_current_state={
                                    timeline_creation_forms_current_state
                                }
                                timeline_creation_steps_data={
                                    timeline_creation_steps_data
                                }
                                manageTimelineCreationFormState={
                                    manageTimelineCreationFormState
                                }
                                timeline_creation_steps_object_of_indexed_list={
                                    timeline_creation_steps_object_of_indexed_list
                                }
                                close_create_form={close_create_form}
                                entityID={
                                    data_to_be_submitted.cost &&
                                    data_to_be_submitted.cost.id
                                        ? data_to_be_submitted.cost?.id
                                        : 'NEW_entryUID'
                                }
                                entityType="cost"
                                activeEntry={activeEntry}
                            />
                        </BottomUpSlider>
                    </IpadAndMobileDisplay>
                </CenteredPageWrapper>
            </Faded>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CreateCost))
