import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'
import LoaderElasticThreeDots from '../loader/loaderElasticThreeDots'

const Btn = styled(motion.button)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    height: 48px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
    :hover {
        background-color: #51a8ae;
    }

    @media ${device.mobile} {
        width: 100%;
    }
`

type Props = {
    onClick?: any
    children: React.ReactNode
    isDisabled?: boolean
    isLoading?: boolean
}

const StandardCtaBtn = (props: Props) => {
    return (
        <Btn
            style={{
                backgroundColor: props.isDisabled ? '#B3B3B3' : '#5ec3ca',
                minWidth: 160,
            }}
            onClick={props.onClick}
            whileTap={{ scale: 1.1 }}
            disabled={props.isDisabled}
        >
            {props.isLoading ? <LoaderElasticThreeDots /> : props.children}
        </Btn>
    )
}

export default StandardCtaBtn
