import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

type Props = {
    isOpen: boolean
    initialX?: number
    originX?: string
    children?: React.ReactNode
}

const SlideDownWrapper = (props: Props) => {
    const variants = {
        open: {
            x: props.initialX ? props.initialX : 0,
            originX: props.originX ? props.originX : 0,
            scale: 1,
            y: 0,
        },
        closed: {
            x: props.initialX,
            originX: props.originX,
            scale: 0,
            y: '-100%',
        },
        initial: {
            x: 0,
            originX: 0,
            scale: 0,
        },
    }

    return (
        <AnimatePresence>
            <motion.div
                animate={props.isOpen ? 'open' : 'closed'}
                variants={variants}
                style={{ zIndex: 3, position: 'relative' }}
                initial={variants.initial}
            >
                {props.children}
            </motion.div>
        </AnimatePresence>
    )
}

export default SlideDownWrapper
