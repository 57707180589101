import * as React from 'react'
import styled from 'styled-components'

const PiledUpLabels = styled.div`
    position: relative;
    /* margin-left: 10px; */
    width: 30px;
    height: 22px;
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 10px;
    font-family: Lato-bold;
    font-size: 10px;
    color: var(--text-muted);
    display: flex;
    align-items: center;
    justify-content: center;

    :after {
        position: absolute;
        content: '';
        left: -6px;
        width: 30px;
        height: 22px;
        background-color: var(--bg-color, #fff);
        border-radius: 10px;
        z-index: -1;
    }

    :before {
        position: absolute;
        content: '';
        left: -12px;
        width: 30px;
        height: 22px;
        background-color: var(--off-bg-color, #fafafa);
        border-radius: 10px;
        z-index: -1;
    }
`

type Props = {
    children: React.ReactNode
}

class PiledUpLabelsMobile extends React.Component<Props> {
    render() {
        let { children } = this.props
        return <PiledUpLabels>{children}</PiledUpLabels>
    }
}

export default PiledUpLabelsMobile
