import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import DesktopDisplayOnly from '../../../displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../displays/ipadAndMobileDisplay'
import { CheckIcon, OrderIcon } from '../../../../atoms/icons/components'
import CrossIcon from '../../../../atoms/icons/crossIcon'

type Props = {
    isMobile?: boolean
    onClickSortSelected?: any
    activeStep: 'select' | 'sort'
    onSelectAll: () => any
    onDeselectAll: () => any
    isAllSelected?: boolean
    noneIsSelected?: boolean
}

type StyleProps = {
    $isMobile?: boolean
}

// parent element
const Container = styled.div<StyleProps>`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;

    z-index: 30;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 5rem;
    background-color: var(--bg-color, #ffffff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    font-family: Lato;
`

const CarGalleryShowroomEditTopBar = (props: Props) => {
    let { isAllSelected } = props
    return (
        <>
            <DesktopDisplayOnly>
                <Container>
                    <div
                        style={{
                            fontFamily: 'Lato-Bold',
                            fontSize: '16px',
                            color: 'var(--text-strong, #1a1a1a)',
                        }}
                    >
                        {props.activeStep === 'select'
                            ? 'Manage Gallery'
                            : 'Sort selected images'}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            gap: '16px',
                            zIndex: 35,
                        }}
                    >
                        <div
                            style={{
                                zIndex: 35,
                            }}
                        >
                            <ButtonAtom
                                theme="capitalize-white-background"
                                width="168px"
                                height="45px"
                                dataCyId={'showroom-car-selected'}
                                icon={''}
                                fontSize={16}
                                onClick={() => {
                                    props.onClickSortSelected()
                                }}
                                disabled={
                                    props.activeStep === 'select' &&
                                    props.noneIsSelected === true
                                        ? true
                                        : false
                                }
                            >
                                {props.activeStep === 'select' && (
                                    <div
                                        style={{
                                            paddingRight: 8,
                                            paddingTop: 2,
                                        }}
                                    >
                                        <OrderIcon
                                            color={
                                                props.activeStep === 'select' &&
                                                props.noneIsSelected === true
                                                    ? 'grey'
                                                    : 'var(--primary, #5EC3CA)'
                                            }
                                        />
                                    </div>
                                )}
                                {props.activeStep === 'select'
                                    ? 'Order Selected'
                                    : 'cancel'}
                            </ButtonAtom>
                        </div>

                        {props.activeStep === 'select' && (
                            <ButtonAtom
                                theme="capitalize-white-background"
                                width="138px"
                                height="45px"
                                dataCyId={'showroom-car-share'}
                                icon={''}
                                fontSize={16}
                                onClick={() => {
                                    if (isAllSelected) {
                                        props.onDeselectAll()
                                    }
                                    if (!isAllSelected) {
                                        props.onSelectAll()
                                    }
                                }}
                            >
                                {isAllSelected ? (
                                    <div
                                        style={{
                                            paddingRight: 8,
                                            paddingTop: 4,
                                        }}
                                    >
                                        <CrossIcon color="var(--primary, #5EC3CA)" />
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            paddingRight: 8,
                                            paddingTop: 2,
                                        }}
                                    >
                                        <CheckIcon width="16px" />
                                    </div>
                                )}

                                {!isAllSelected ? 'Select all' : 'Deselect all'}
                            </ButtonAtom>
                        )}
                    </div>
                </Container>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <Container>
                    <div
                        style={{
                            fontFamily: 'Lato-Bold',
                            fontSize: '16px',
                            color: 'var(--text-strong, #1a1a1a)',
                        }}
                    >
                        {props.activeStep === 'select'
                            ? 'Manage Gallery'
                            : 'Sort selected images'}
                    </div>

                    <ButtonAtom
                        theme="naked-add-text"
                        width="130px"
                        height="45px"
                        dataCyId={'showroom-car-share'}
                        icon={''}
                        fontSize={16}
                        onClick={() => {
                            props.onClickSortSelected()
                        }}
                    >
                        {props.activeStep === 'select' ? 'Order' : 'cancel'}
                    </ButtonAtom>
                </Container>
            </IpadAndMobileDisplay>
        </>
    )
}

export default CarGalleryShowroomEditTopBar
