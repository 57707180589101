import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { message } from 'antd'
import './antd.css'
import ImageUploadBtn from '../../atoms/Button/imageUploadBtn'
import { useAppDispatch } from '../../../redux/store/hooks'
import { getPresignedUrlsCarGalleryImagesRequest } from '../../../redux/filestorage/actions/dispatched/loadingActions'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import AddIcon from '../../atoms/icons/components/addIcon'
import styled from 'styled-components'
import ImageCropperMobile2 from '../imageCropper/imageCropperMobile2'
import { checkIfIpad } from '../../templates/pwaCustom/checkIpadPwa'
import ImageCropperDesktop from '../imageCropper/imageCropperDesktop'
import ImageCropperDesktop2 from '../imageCropper/imageCropperDesktop2'
import ModalDisplayFullPageDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import ModalDisplayMobileIpad from '../../templates/displays/pageWrappers/modalDisplayMobileExclusive'
import PortalInvisibleWrapper from '../../templates/displays/pageWrappers/portalInvisibleWrapper'

const NakedAddBtnStyle = styled.div`
    color: var(--primary, #5ec3ca);
    text-align: center;
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 8px;
`

const errorWarning = () => {
    message.error({
        content: 'Please upload one image.',
        duration: 2,
    })
}

const errorFileSizeWarning = () => {
    message.error({
        content: 'The maximum image size allowed is 20MB.',
        duration: 2,
    })
}

const errorFileTypeWarning = (filename: string, message_text: string) => {
    message.error({
        content: `${filename} is not supported, ${message_text}`,
        duration: 2,
    })
}

type Props = {
    carid: string
    isSingleImg?: boolean
    isMobile?: boolean
    isNakedBtn?: boolean
    handleFilesGiven: (files: File[]) => void
    customBorderRadius?: string
    hasCropper?: boolean
    coppedImgAspectRatio?: number
}

function JourneyImagesDropzone(props: Props) {
    const dispatch = useAppDispatch()

    const [showImageCropper, toggleShowImageCropper] = useState(false)
    const [imgToBeCropped, setImgToBeCropped] = useState<File | undefined>(
        undefined
    )

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (
                ((props.isSingleImg || props.hasCropper) &&
                    acceptedFiles.length === 1) ||
                (!props.isSingleImg && acceptedFiles.length > 0)
            ) {
                if (props.hasCropper) {
                    setImgToBeCropped(acceptedFiles[0])
                    toggleShowImageCropper(true)
                } else {
                    const fileNames = acceptedFiles.map((file) => file.name)
                    dispatch(
                        getPresignedUrlsCarGalleryImagesRequest({
                            carid: props.carid,
                            count: fileNames.length,
                            file_names: fileNames,
                        })
                    )
                    props.handleFilesGiven(acceptedFiles)
                }
            }
        },
        [props]
    )

    const onDropRejected = useCallback((fileRejections: any[]) => {
        let showTooManyFilesWarning = false
        for (let i = 0; i < fileRejections.length; i++) {
            if (fileRejections[i].errors) {
                let file = fileRejections[i].file
                let errors: any[] = fileRejections[i].errors
                for (let i = 0; i < errors.length; i++) {
                    if (errors[i].code === 'too-many-files') {
                        showTooManyFilesWarning = true
                    } else {
                        if (errors[i].code === 'file-invalid-type') {
                            errorFileTypeWarning(file.name, errors[i].message)
                        }
                        if (
                            errors[i].code === 'file-too-large' ||
                            errors[i].code === 'file-too-small'
                        ) {
                            errorFileSizeWarning()
                        }
                    }
                }
            }
        }
        if (showTooManyFilesWarning) {
            errorWarning()
        }
    }, [])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        onDropRejected,
        accept: 'image/jpeg, image/png, image/heic, image/heif',
        maxFiles: props.isSingleImg || props.hasCropper ? 1 : undefined,
        minSize: 0,
        maxSize: 20000000,
    })

    return (
        <div className="container" style={{ width: '100%' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />

                {props.isMobile ? (
                    <ImageUploadBtn
                        sectionID="car_profile"
                        variant="mobile"
                        width="100%"
                        height={props.isSingleImg ? '104px' : '80px'}
                        customBorderRadius={props.customBorderRadius}
                        isSingleImg={
                            props.isSingleImg || props.hasCropper ? true : false
                        }
                    />
                ) : props.isNakedBtn ? (
                    <StandardCtaBtn
                        onClick={() => {}}
                        bg="transparent"
                        removeShadowOnHover
                    >
                        <NakedAddBtnStyle>
                            <AddIcon colour="var(--primary, #5ec3ca)" /> Upload
                            more images
                        </NakedAddBtnStyle>
                    </StandardCtaBtn>
                ) : (
                    <ImageUploadBtn
                        sectionID="car_profile"
                        variant="desktop"
                        isSingleImg={
                            props.isSingleImg || props.hasCropper ? true : false
                        }
                        width={props.isSingleImg ? undefined : '100%'}
                        height={props.isSingleImg ? undefined : '140px'}
                        customBorderRadius={props.customBorderRadius}
                    />
                )}
            </div>
            {props.hasCropper && showImageCropper ? (
                <>
                    {props.isMobile ? (
                        <ModalDisplayMobileIpad
                            isOpen={true}
                            toggle={() => {}}
                            customZindex={102}
                        >
                            <div id={'mobile cropper'}>
                                <ImageCropperMobile2
                                    currentImagesUploaded={imgToBeCropped}
                                    close={() => toggleShowImageCropper(false)}
                                    handleFile={(file: File | undefined) => {
                                        if (file) {
                                            dispatch(
                                                getPresignedUrlsCarGalleryImagesRequest(
                                                    {
                                                        carid: props.carid,
                                                        count: 1,
                                                        file_names: [file.name],
                                                    }
                                                )
                                            )
                                            props.handleFilesGiven([file])
                                        }
                                    }}
                                    aspect={props.coppedImgAspectRatio}
                                />
                            </div>
                        </ModalDisplayMobileIpad>
                    ) : checkIfIpad() ? (
                        <PortalInvisibleWrapper customZindex={102}>
                            <ModalDisplayFullPageDesktopExclusive
                                isOpen={true}
                                toggle={() => {}}
                                customZIndex={100}
                            >
                                <ImageCropperDesktop2
                                    currentImagesUploaded={imgToBeCropped}
                                    close={() => toggleShowImageCropper(false)}
                                    handleFile={(file: File | undefined) => {
                                        if (file) {
                                            dispatch(
                                                getPresignedUrlsCarGalleryImagesRequest(
                                                    {
                                                        carid: props.carid,
                                                        count: 1,
                                                        file_names: [file.name],
                                                    }
                                                )
                                            )
                                            props.handleFilesGiven([file])
                                        }
                                    }}
                                    aspect={props.coppedImgAspectRatio}
                                />
                            </ModalDisplayFullPageDesktopExclusive>
                        </PortalInvisibleWrapper>
                    ) : (
                        <PortalInvisibleWrapper customZindex={102}>
                            <ModalDisplayFullPageDesktopExclusive
                                isOpen={true}
                                toggle={() => {}}
                                customZIndex={100}
                            >
                                <ImageCropperDesktop
                                    currentImagesUploaded={imgToBeCropped}
                                    close={() => toggleShowImageCropper(false)}
                                    handleFile={(file: File | undefined) => {
                                        if (file) {
                                            dispatch(
                                                getPresignedUrlsCarGalleryImagesRequest(
                                                    {
                                                        carid: props.carid,
                                                        count: 1,
                                                        file_names: [file.name],
                                                    }
                                                )
                                            )
                                            props.handleFilesGiven([file])
                                        }
                                    }}
                                    aspect={props.coppedImgAspectRatio}
                                />
                            </ModalDisplayFullPageDesktopExclusive>
                        </PortalInvisibleWrapper>
                    )}
                </>
            ) : null}
        </div>
    )
}

export default JourneyImagesDropzone
