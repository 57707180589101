import { IURL_user_getSharesList_arg } from 'ApiInterfaces'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const getUserSharesListAPI = (
    p: IURL_user_getSharesList_arg
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.get_user_shares_list(p), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(response, 'Get user shares error')
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
