import styled from 'styled-components'
import { motion } from 'framer-motion'
// import LoaderElasticThreeDots from '../loader/loaderElasticThreeDots'
import { IJourneySectionID } from 'journeyInterfaces'
import colours, {
    ITheme,
    journey_colours,
} from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import AddIcon from '../icons/components/addIcon'
import Ellipsis from '../loader/ellipsis'
import UploadedImagePreview from '../image/uploadedImagePreview'
import CrossIcon from '../icons/crossIcon'
import StandardCtaBtn from './standardCtaBtn'
import { Spin } from 'antd'

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
    $width?: string
    $height?: string
    $customBorderRadius?: string
    $removeBg?: boolean
}

const Wrapper = styled(motion.div)<IStyle>`
    display: flex;
    height: ${(props) => props.$height ?? '176px'};
    width: ${(props) => props.$width ?? '176px'};
    min-width: 0;
    padding: 8px;
    gap: 10px;
    border-radius: ${(props) => props.$customBorderRadius ?? '24px'};
    cursor: pointer;
    background-color: ${(props) =>
        `${
            props.$removeBg
                ? 'transparent'
                : journey_colours[props.$theme].section[props.$sectionID]
                      .primary_500_08
        }`};
`
const Btn = styled.button<IStyle>`
    outline: none;
    border: none;
    display: flex;
    flex: 1;
    min-width: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) => props.$customBorderRadius ?? '20px'};
    cursor: pointer;
    background-color: ${(props) =>
        `${
            props.$removeBg
                ? 'transparent'
                : journey_colours[props.$theme].section[props.$sectionID]
                      .primary_500_16
        }`};
    transition: background-color 200ms;
    :hover {
        background-color: ${(props) =>
            `${
                props.$removeBg
                    ? 'transparent'
                    : journey_colours[props.$theme].section[props.$sectionID]
                          .primary_500_24
            }`};
    }
`

const ElementsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1;
    min-width: 0;
`

const RowWrapper = styled.div`
    display: flex;
    padding: 12px 16px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    max-width: -webkit-fill-available;
`

const RowLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
    min-width: 0;
`
const RowLeftTxtWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    max-width: -webkit-fill-available;
    min-width: 0;
`

const RowLeftTitle = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px; /* 150% */
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
`

const RowLeftSize = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-default, #616161);
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
`

const BtnText = styled.span<IStyle>`
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_500
        }`};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px;
`

const IconWrapper = styled.div<IStyle>`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_500
        }`};
`

type Props = {
    sectionID: IJourneySectionID
    variant: 'desktop' | 'mobile' | 'image_preview'
    width?: string
    height?: string
    customBorderRadius?: string
    onClick?: any
    isDisabled?: boolean
    isLoading?: boolean
    image?: File
    removeImage?: (imageName?: string) => void
    isSingleImg?: boolean
    removeBg?: boolean
    propgress?: number
}

const formatSize = (size: number) => {
    if (size / 1000000 > 1) {
        return `${(size / 1000000).toFixed(1)} MB`
    } else {
        return `${Math.round(size / 1000)} KB`
    }
}

const ImageUploadBtn = (props: Props) => {
    let { theme } = useThemes()

    return (
        <Wrapper
            whileTap={{ scale: 1.05 }}
            $theme={theme}
            $sectionID={props.sectionID}
            $width={props.width}
            $height={props.height}
            $customBorderRadius={props.customBorderRadius}
            $removeBg={props.removeBg}
        >
            <Btn
                $theme={theme}
                $sectionID={props.sectionID}
                $customBorderRadius={props.customBorderRadius}
                $removeBg={props.removeBg}
                onClick={props.onClick}
            >
                {props.isLoading ? (
                    <div
                        style={{
                            transform: 'translate(-6px, -4px)',
                        }}
                    >
                        <Ellipsis size={10} />
                    </div>
                ) : null}
                {props.variant === 'desktop' && !props.isLoading ? (
                    <ElementsWrapper>
                        <IconWrapper
                            $theme={theme}
                            $sectionID={props.sectionID}
                        >
                            <AddIcon />
                        </IconWrapper>
                        <BtnText $theme={theme} $sectionID={props.sectionID}>
                            {props.isSingleImg
                                ? `Upload image`
                                : 'Upload images'}
                        </BtnText>
                    </ElementsWrapper>
                ) : null}
                {props.variant === 'mobile' && !props.isLoading ? (
                    <ElementsWrapper style={{ gap: 8, flexDirection: 'row' }}>
                        <AddIcon colour={colours[theme].primary} />

                        <BtnText
                            $theme={theme}
                            $sectionID={props.sectionID}
                            style={{
                                color: colours[theme].primary,
                                fontSize: 16,
                            }}
                        >
                            {props.isSingleImg
                                ? `Upload image`
                                : 'Upload images'}
                        </BtnText>
                    </ElementsWrapper>
                ) : null}
                {props.variant === 'image_preview' && props.image ? (
                    <RowWrapper>
                        <RowLeft>
                            <div
                                style={{
                                    width: 56,
                                    minWidth: 56,
                                    height: 56,
                                    borderRadius: 8,
                                }}
                            >
                                <UploadedImagePreview
                                    currentImagesUploaded={props.image}
                                    borderRadius="8px"
                                />
                            </div>
                            <RowLeftTxtWrap>
                                <RowLeftTitle>{props.image.name}</RowLeftTitle>
                                <RowLeftSize>
                                    Size: {formatSize(props.image.size)}
                                </RowLeftSize>
                            </RowLeftTxtWrap>
                        </RowLeft>
                        {props.removeImage ? (
                            <StandardCtaBtn
                                onClick={() => {
                                    props.removeImage &&
                                        props.removeImage(props.image?.name)
                                }}
                                bg="transparent"
                                removePaddings
                                customHeight="24px"
                                customWidth="24px"
                            >
                                <CrossIcon />
                            </StandardCtaBtn>
                        ) : props.propgress && props.propgress < 100 ? (
                            <Spin />
                        ) : null}
                    </RowWrapper>
                ) : null}
            </Btn>
        </Wrapper>
    )
}

export default ImageUploadBtn
