import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
    ICar,
    IEntityPrivateOwnedSharesState,
    ISharingPermissionIDS,
    ISearchUsersResult,
    IDirectShareOwnedItem,
    ISortShareRecipientsDisplay_change_request,
    IUpdateCarDirectShareRequest,
} from 'entityModels'
import { IDirectShareDeletionConfirmation, IUser } from 'myModels'
import {
    addCarShareRecipientRequest,
    deleteCarShareRecipientRequest,
    getCarDataByIdRequest,
    getSingleCarDirectShareRequest,
    IShareRecipientRequest_redux,
    IgetSingleDirectShareRequest_redux,
    sortShareRecipientsDisplay_change_request,
    updateCarDirectShareRequest,
    deleteCarDirectShareRequest,
    deleteCarShareInviteRequest,
    IShareInviteDeletion,
    resendCarShareInviteRequest,
    IShareInvitResent,
} from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import PrivateShareSearchFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareSearchFormDesktop'
import PrivateShareFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareFormManagerDesktop'
import EditModeTopHeaderDesktop from '../../atoms/header/editModeTopHeader/editModeTopHeaderDesktop'
import PrivateShareFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareFormManagerMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SwitchTabMenuDesktop from '../../atoms/Button/switchTabMenuDesktop'
import ShareRowDesktop from '../../atoms/shareRow/shareRowDesktop'
import styled from 'styled-components'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import AddShareByEmailFormDesktop from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import SwitchTabMenuMobile from '../../atoms/Button/switchTabMenuMobile'
import ShareRowMobile from '../../atoms/shareRow/shareRowMobile'
// import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import AddShareByEmailFormMobile from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormMobile'
import { device } from '../../templates/displays/devices'
import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import PrivateSharesSortingMobile from '../../atoms/menu/bottomSheetMenu/privateSharesSortingMobile'
import GreyIconButton from '../../atoms/Button/GreyIconButton'
import { checkUserEmailExistsRequest } from '../../../redux/searchUsers/actions/loadingActions'
import EditModeFooterDesktop from '../../atoms/footer/editModeFooterDesktop'
import Faded from '../../templates/animated/faded'
import { editFormsActions } from '../../../redux/editForms/reducer'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
// import ComingSoonCard from '../../atoms/comingSoon/comingSoonCard'
import NoIconDataRectangle from '../../atoms/noData/noIconDataRectangle'
import SideSlider from '../../templates/animated/sideSlider'
import SideSliderWithExit from '../../templates/animated/sideSliderWithExit'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import PrivateShareSearchManagerMobileV2 from '../../organisms/shareForms/privateShare/privateShareSearchManagerMobileV2'

const DesktopAdjustedWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`

const MobileWrapper = styled.div`
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    @media ${device.ipad} {
        max-width: 600px;
        padding-left: 30px;
        padding-right: 30px;
    }
`
const SharedWith = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-Bold';
    font-size: 17px;
    padding-bottom: 20px;
`

const Padding10 = styled.div`
    padding-top: 10px;
`
const Padding20 = styled.div`
    padding-top: 20px;
`

// const Padding30 = styled.div`
//     padding-top: 30px;
// `

const StickyWrapper = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 4;
    display: flex;
    align-items: flex-end;
    background-color: var(--bg-color, #fff);
    height: 50px;
    transform: translateY(-20px);
    margin-top: 16px;
    @media ${device.mobile_and_ipad} {
        top: 75px;
        margin-top: 15px;
    }
`

const DropdownHoverWrapper = styled.div`
    border-radius: 5px;
    height: 40px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 200ms;
`

const Title = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
`

const SubTitle = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-default, #666666);
`

const Padding24 = styled.div`
    padding-top: 24px;
`

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isCarLoading: state.entities.carsData.loading,
        sharesLoading: state.entities.carsData.carSharesLoading,
        carsData: state.entities.carsData.cars,
        isUserLoading: state.user.loading,
        addShareRecipientByEmailForm:
            state.editForms.formsData &&
            state.editForms.formsData.addShareRecipientByEmailForm &&
            state.editForms.formsData.addShareRecipientByEmailForm.isOpen,
        searchResults: state.searchUsers.list,
        searchResultsLoading: state.searchUsers.loading,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
        directShareDeletionConfirmation:
            state.editForms.directShareDeletionConfirmation,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    toggleAddShareRecipientByEmail: (payload: { isOpen: boolean }) =>
        editFormsActions.toggleAddShareRecipientByEmail(payload),
    sortShareRecipientsDisplay_change_request: (
        p: ISortShareRecipientsDisplay_change_request
    ) => sortShareRecipientsDisplay_change_request(p),
    checkUserEmailExistsRequest: (value: string | undefined) =>
        checkUserEmailExistsRequest(value),
    getSingleCarDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) =>
        getSingleCarDirectShareRequest(p),
    addCarShareRecipientRequest: (p: IShareRecipientRequest_redux) =>
        addCarShareRecipientRequest(p),
    deleteCarShareRecipientRequest: (p: IShareRecipientRequest_redux) =>
        deleteCarShareRecipientRequest(p),
    updateCarDirectShareRequest: (p: IUpdateCarDirectShareRequest) =>
        updateCarDirectShareRequest(p),
    toggleShareDeletionConfirmation: (p: IDirectShareDeletionConfirmation) =>
        editFormsActions.toggleShareDeletionConfirmation(p),
    deleteCarDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) =>
        deleteCarDirectShareRequest(p),
    deleteCarShareInviteRequest: (p: IShareInviteDeletion) =>
        deleteCarShareInviteRequest(p),
    resendCarShareInviteRequest: (p: IShareInvitResent) =>
        resendCarShareInviteRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    carsData: { [key: string]: ICar }
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isCarLoading: boolean
    getUserDataRequest: () => void
    toggleAddShareRecipientByEmail: (payload: { isOpen: boolean }) => void
    addShareRecipientByEmailForm?: boolean | undefined
    sortShareRecipientsDisplay_change_request: (
        p: ISortShareRecipientsDisplay_change_request
    ) => void
    getCarDataByIdRequest: (carid: string) => void
    setCurrentCar: (carid: string) => void
    checkUserEmailExistsRequest: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    searchResultsLoading: boolean
    getSingleCarDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => void
    isCollapsed: boolean
    addCarShareRecipientRequest: (p: IShareRecipientRequest_redux) => void
    deleteCarShareRecipientRequest: (p: IShareRecipientRequest_redux) => void
    updateCarDirectShareRequest: (p: IUpdateCarDirectShareRequest) => void

    directShareDeletionConfirmation: IDirectShareDeletionConfirmation
    toggleShareDeletionConfirmation: (
        p: IDirectShareDeletionConfirmation
    ) => void
    deleteCarDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) => void
    sharesLoading: boolean
    location: any
    deleteCarShareInviteRequest: (p: IShareInviteDeletion) => void
    resendCarShareInviteRequest: (p: IShareInvitResent) => void
    setInitialLandingUrl: (str: string) => void
}

type State = {
    share_being_edited: IDirectShareOwnedItem
    isMobileEditOpen: boolean
    activeTabId: string
    isSortBottomSheetOpen: boolean
}

class CarShareEdit extends React.Component<Props, State> {
    state: State = {
        share_being_edited: {
            entity_id: '',
            title: '',
            active_permission_ids: [] as any,
            id: 'share_being_edited',
            created_at: '', // now
            type: 'car',
            updated_at: '', // now
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            total_recipients: 0,
        },
        isMobileEditOpen: false,
        activeTabId: 'recipients',
        isSortBottomSheetOpen: false,
    }

    _isMounted = false

    changeTab = (tabID: string) => {
        this.setState({ activeTabId: tabID })
    }

    handleResize = () =>
        window.innerWidth < 881
            ? this.setState({ isMobileEditOpen: true })
            : this.setState({ isMobileEditOpen: false })

    setupLocalStateData = () => {
        let carid = this.props.match.params.carid
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')
        const car_shares: IEntityPrivateOwnedSharesState | undefined =
            carid &&
            this.props.carsData &&
            this.props.carsData[carid] &&
            this.props.carsData[carid].private_shares_owned

        if (
            car_shares &&
            car_shares.entity_owned_direct_shares_data &&
            shareid &&
            car_shares.entity_owned_direct_shares_data[shareid]
        ) {
            const share: IDirectShareOwnedItem =
                car_shares.entity_owned_direct_shares_data[shareid]

            // let permissions_ids: ISharingPermissionIDS_api[] = [
            //     'car._',
            //     'car.entry._',
            //     'car.entry.image.*',
            // ]

            // let active_permissions_ids: ISharingPermissionIDS[] =
            //     share.active_permission_ids ?
            //     ConvertSharePermissionIDsToState(share.active_permission_ids) : []

            this.setState({
                share_being_edited: {
                    ...share,
                    active_display: {
                        item_ids_display: share.active_display?.item_ids_display
                            ? share.active_display?.item_ids_display
                            : share.all_share_recipients_ids_list,
                        activeSort: share.active_display?.activeSort
                            ? share.active_display?.activeSort
                            : 'added_at',
                    },
                },
            })
        }
    }

    componentDidMount() {
        this._isMounted = true

        // user stuff
        let userData = this.props.userLoggedIn
        let carid = this.props.match.params.carid
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')
        this.props.setInitialLandingUrl(
            `/sharing/with-others/car/${carid}/edit-share?shareid=${shareid}`
        )
        if (!userData) {
            this.props.getUserDataRequest()
        } else {
            this.props.setCurrentCar(carid)

            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(carid)
            }

            shareid &&
                this.props.getSingleCarDirectShareRequest({
                    entity_id: carid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        window.addEventListener('resize', this.handleResize, { passive: true })

        window.innerWidth < 881 && this.setState({ isMobileEditOpen: true })

        let active_tab_id = q_params.get('active_tab_id')

        if (active_tab_id === 'settings') {
            this.changeTab('settings')
        }
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):
        let carid = this.props.match.params.carid
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            this.props.setCurrentCar(carid)
            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(carid)
            }

            shareid &&
                this.props.getSingleCarDirectShareRequest({
                    entity_id: carid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        if (this.props.carsData[carid] !== prevProps.carsData[carid]) {
            this.setupLocalStateData()
        }
    }

    componentWillUnmount() {
        this.setState({ isMobileEditOpen: false })
        window.removeEventListener('resize', this.handleResize)
        this.props.toggleAddShareRecipientByEmail({ isOpen: false })
        this._isMounted = false
    }

    handleChangePermissions = (permissionID: ISharingPermissionIDS) => {
        let permissions_arr: ISharingPermissionIDS[] =
            this.state.share_being_edited.active_permission_ids

        if (
            this.state.share_being_edited.active_permission_ids.includes(
                permissionID
            )
        ) {
            return this.setState({
                ...this.state,
                share_being_edited: {
                    ...this.state.share_being_edited,
                    active_permission_ids: permissions_arr.filter(
                        (id) => permissionID !== id
                    ),
                },
            })
        } else {
            return this.setState({
                ...this.state,
                share_being_edited: {
                    ...this.state.share_being_edited,
                    active_permission_ids: [...permissions_arr, permissionID],
                },
            })
        }
    }

    handleTitleChange = (id: string, title: string) => {
        return this.setState({
            ...this.state,
            share_being_edited: {
                ...this.state.share_being_edited,
                title: title,
            },
        })
    }

    handleSubmitToBE = () => {
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        let payload: IUpdateCarDirectShareRequest = {
            car_id: this.props.match.params.carid,
            share_id: shareid ? shareid : '',
            policy_statements:
                this.state.share_being_edited.active_permission_ids,
            title: this.state.share_being_edited.title,
        }

        this.props.updateCarDirectShareRequest(payload)

        return payload
    }

    handleDelete = (p: IDirectShareDeletionConfirmation) => {
        if (p.entity_id && p.share_id) {
            this.props.deleteCarDirectShareRequest({
                entity_id: p.entity_id,
                share_id: p.share_id,
            })
            this.props.history.push(
                `/sharing/with-others/car?carid=${p.entity_id}`
            )
        }
    }

    render() {
        let {
            carsData,
            toggleAddShareRecipientByEmail,
            addShareRecipientByEmailForm,
            sortShareRecipientsDisplay_change_request,
            checkUserEmailExistsRequest,
            searchResults,
            searchResultsLoading,
            isCollapsed,
            directShareDeletionConfirmation,
            toggleShareDeletionConfirmation,
            sharesLoading,
        } = this.props

        let { share_being_edited } = this.state

        let carid = this.props.match.params.carid
        let current: ICar | undefined = carsData[carid]
            ? carsData[carid]
            : undefined

        const btnIdsList: string[] = ['recipients', 'settings']
        const btnsData: any = {
            recipients: {
                id: 'recipients',
                txt: 'recipients',
                action: () => this.changeTab('recipients'),
                isDisabled: false,
            },
            settings: {
                id: 'settings',
                txt: 'settings',
                action: () => this.changeTab('settings'),
                isDisabled: false,
            },
        }

        let active_tab: string = this.state.activeTabId

        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        const car_shares: IEntityPrivateOwnedSharesState | undefined =
            carid &&
            this.props.carsData &&
            this.props.carsData[carid] &&
            this.props.carsData[carid].private_shares_owned

        const current_share: IDirectShareOwnedItem | undefined =
            car_shares &&
            car_shares.entity_owned_direct_shares_data &&
            shareid &&
            car_shares.entity_owned_direct_shares_data[shareid]
                ? car_shares.entity_owned_direct_shares_data[shareid]
                : undefined

        let hasShareChanged = (
            a: IDirectShareOwnedItem,
            b: IDirectShareOwnedItem
        ): boolean => {
            if (a.title !== b.title) {
                return true
            }
            if (a.active_permission_ids && !b.active_permission_ids) {
                return true
            }
            if (!a.active_permission_ids && b.active_permission_ids) {
                return true
            }
            if (
                a.active_permission_ids &&
                b.active_permission_ids &&
                a.active_permission_ids.length !==
                    b.active_permission_ids.length
            ) {
                return true
            }

            if (a.active_permission_ids && b.active_permission_ids) {
                const uniqueValues = new Set([
                    ...a.active_permission_ids,
                    ...b.active_permission_ids,
                ])
                for (const v of uniqueValues) {
                    const aCount = a.active_permission_ids.filter(
                        (e) => e === v
                    ).length
                    const bCount = b.active_permission_ids.filter(
                        (e) => e === v
                    ).length
                    if (aCount !== bCount) return true
                }
            }

            return false
        }

        const checkWhatIsBeingUpdated = (): string | undefined => {
            if (
                share_being_edited.title &&
                current_share &&
                share_being_edited.title !== current_share.title
            ) {
                if (
                    share_being_edited.active_permission_ids &&
                    JSON.stringify(share_being_edited.active_permission_ids) !==
                        JSON.stringify(current_share.active_permission_ids)
                ) {
                    return 'title and permisions'
                } else {
                    return 'title'
                }
            } else if (
                current_share &&
                share_being_edited.active_permission_ids &&
                JSON.stringify(share_being_edited.active_permission_ids) !==
                    JSON.stringify(current_share.active_permission_ids)
            ) {
                return 'permissions'
            }
            return undefined
        }

        return (
            <React.Fragment>
                <DesktopDisplayOnly>
                    <CenteredPageWrapper fullwidth>
                        {current && share_being_edited && (
                            <WindowPageTemplate
                                hasPositionRelative
                                isCollapsed={isCollapsed}
                                stickyHeaderContent={
                                    <EditModeTopHeaderDesktop
                                        headerText={
                                            current_share && current_share.title
                                                ? current_share.title
                                                : 'Untitled'
                                        }
                                        noBtns
                                        isInsideWindowTemplate
                                        goBack={() => {
                                            this.props.history.push(
                                                `/sharing/with-others/car?carid=${carid}`
                                            )
                                        }}
                                        removeHorizTopPaddings
                                    />
                                }
                                stickyBottomContent={
                                    <EditModeFooterDesktop
                                        onCancel={
                                            this.state.activeTabId ===
                                            'settings'
                                                ? () => {
                                                      this.props.history.push(
                                                          `/sharing/with-others/car?carid=${carid}`
                                                      )
                                                  }
                                                : undefined
                                        }
                                        onDelete={() =>
                                            setTimeout(() => {
                                                shareid &&
                                                    toggleShareDeletionConfirmation(
                                                        {
                                                            isOpen: true,
                                                            entity_id: carid,
                                                            entity_type: 'car',
                                                            share_id: shareid,
                                                        }
                                                    )
                                            })
                                        }
                                        // isActive={this.checkIfChange()}
                                        isInsideWindowTemplate
                                        isActive={
                                            current_share &&
                                            share_being_edited &&
                                            hasShareChanged(
                                                current_share,
                                                share_being_edited
                                            )
                                        }
                                        onSave={
                                            this.state.activeTabId ===
                                            'settings'
                                                ? this.handleSubmitToBE
                                                : undefined
                                        }
                                        isCollapsed={isCollapsed}
                                    />
                                }
                            >
                                <DesktopAdjustedWrapper>
                                    {addShareRecipientByEmailForm && carid && (
                                        <ModalDisplayAbsolute
                                            isOpen={
                                                addShareRecipientByEmailForm &&
                                                addShareRecipientByEmailForm
                                                    ? true
                                                    : false
                                            }
                                            toggle={() => {}}
                                            top="20px"
                                            left="0px"
                                            right="20px"
                                            bottom="20px"
                                        >
                                            <AddShareByEmailFormDesktop
                                                close={() => {
                                                    toggleAddShareRecipientByEmail(
                                                        {
                                                            isOpen: false,
                                                        }
                                                    )
                                                }}
                                                share_id={share_being_edited.id}
                                                entity_id={carid}
                                            />
                                        </ModalDisplayAbsolute>
                                    )}
                                    <StickyWrapper>
                                        <SwitchTabMenuDesktop
                                            btnIdsList={btnIdsList}
                                            btnsData={btnsData}
                                            activeID={active_tab}
                                        />
                                    </StickyWrapper>
                                    <div style={{ paddingTop: '10px' }} />
                                    {this.state.activeTabId ===
                                        'recipients' && (
                                        <Faded>
                                            <PrivateShareSearchFormManagerDesktop
                                                share={share_being_edited}
                                                searchUsers={
                                                    checkUserEmailExistsRequest
                                                }
                                                searchResults={searchResults}
                                                resultsLoading={
                                                    searchResultsLoading
                                                }
                                                addShareRecipient={
                                                    this.props
                                                        .addCarShareRecipientRequest
                                                }
                                                selectedUsers={
                                                    share_being_edited.all_share_recipients_ids_list
                                                }
                                                onClickInvite={() =>
                                                    toggleAddShareRecipientByEmail(
                                                        { isOpen: true }
                                                    )
                                                }
                                                inviteTxt="Invite to Custodian via Email & Share"
                                            />
                                            {/* <Padding30 />
                                            <IconAndTextNakedButton
                                                onClick={() =>
                                                    toggleAddShareRecipientByEmail(
                                                        { isOpen: true }
                                                    )
                                                }
                                                text="Invite to Custodian via Email & Share"
                                                iconOnLeft
                                                icon="v2_add"
                                            /> */}
                                            {/* <ComingSoonCard
                                                title={`Invite to Custodian & share`}
                                                description={`Soon you’ll be able to invite anyone you want to join Custodian and share your cars or full garage with them.`}
                                            /> */}
                                            <div
                                                style={{ paddingTop: '50px' }}
                                            />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    justifyContent:
                                                        'space-between',
                                                    minWidth: '730px',
                                                }}
                                            >
                                                <SharedWith>
                                                    Direct share recipients
                                                </SharedWith>
                                                <DropdownHoverWrapper>
                                                    <DropdownExpanderHover
                                                        width={'210px'}
                                                        fontSize="15px"
                                                        height="40px"
                                                        placeholder={`Sorted by Date added`}
                                                        placeholderWhenSelected="Sorted by"
                                                        rightAligned
                                                        items={[
                                                            {
                                                                text: 'by Date added',
                                                                action: () => {
                                                                    current &&
                                                                        sortShareRecipientsDisplay_change_request(
                                                                            {
                                                                                sortID: 'added_at',
                                                                                entityid:
                                                                                    current?.id,
                                                                                share: share_being_edited,
                                                                            }
                                                                        )
                                                                },
                                                            },
                                                            {
                                                                text: 'Alphabetical',
                                                                action: () => {
                                                                    current &&
                                                                        sortShareRecipientsDisplay_change_request(
                                                                            {
                                                                                sortID: 'alphabetical',
                                                                                entityid:
                                                                                    current?.id,
                                                                                share: share_being_edited,
                                                                            }
                                                                        )
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                </DropdownHoverWrapper>
                                            </div>

                                            <Padding20 />
                                            {share_being_edited &&
                                            share_being_edited.active_display &&
                                            share_being_edited.active_display
                                                .item_ids_display &&
                                            share_being_edited.active_display
                                                .item_ids_display.length > 0 ? (
                                                share_being_edited.share_recipients_data &&
                                                share_being_edited.active_display.item_ids_display.map(
                                                    (
                                                        userid: string,
                                                        index: number
                                                    ) => (
                                                        <Faded>
                                                            <ShareRowDesktop
                                                                recipient={
                                                                    share_being_edited!
                                                                        .share_recipients_data![
                                                                        userid
                                                                    ]
                                                                }
                                                                index={index}
                                                                isLast={
                                                                    share_being_edited.all_share_recipients_ids_list &&
                                                                    share_being_edited
                                                                        .all_share_recipients_ids_list
                                                                        .length -
                                                                        1 ===
                                                                        index
                                                                        ? true
                                                                        : false
                                                                }
                                                                actionsData={{
                                                                    email: (payload: {
                                                                        invite_id: string
                                                                        recipient_email: string
                                                                    }) =>
                                                                        this.props.resendCarShareInviteRequest(
                                                                            {
                                                                                invite_id:
                                                                                    payload.invite_id,
                                                                                recipient_email:
                                                                                    payload.recipient_email,
                                                                            }
                                                                        ),

                                                                    delete: (payload: {
                                                                        recipient_id: string
                                                                        recipient_type:
                                                                            | 'platform'
                                                                            | 'external'
                                                                    }) =>
                                                                        payload.recipient_type ===
                                                                        'external'
                                                                            ? this.props.deleteCarShareInviteRequest(
                                                                                  {
                                                                                      share_id:
                                                                                          share_being_edited.id,
                                                                                      entity_id:
                                                                                          share_being_edited.entity_id,
                                                                                      invite_id:
                                                                                          payload.recipient_id,
                                                                                  }
                                                                              )
                                                                            : this.props.deleteCarShareRecipientRequest(
                                                                                  {
                                                                                      share_id:
                                                                                          share_being_edited.id,
                                                                                      entity_id:
                                                                                          share_being_edited.entity_id,
                                                                                      recipient_userid:
                                                                                          payload.recipient_id,
                                                                                  }
                                                                              ),
                                                                }}
                                                                sharedOn={
                                                                    share_being_edited!
                                                                        .share_recipients_data![
                                                                        userid
                                                                    ]
                                                                        .date_added_to_share
                                                                }
                                                            />
                                                        </Faded>
                                                    )
                                                )
                                            ) : (
                                                <Faded>
                                                    <NoIconDataRectangle
                                                        text={`You haven’t shared this with anyone yet`}
                                                    />
                                                </Faded>
                                            )}
                                        </Faded>
                                    )}
                                    {this.state.activeTabId === 'settings' && (
                                        <Faded>
                                            <PrivateShareFormManagerDesktop
                                                share_being_edited={
                                                    share_being_edited
                                                }
                                                handleSubmit={
                                                    this.handleSubmitToBE
                                                }
                                                handlePrivateShareTitleChange={
                                                    this.handleTitleChange
                                                }
                                                handlePermissions={
                                                    this.handleChangePermissions
                                                }
                                                formType="edit"
                                            />
                                        </Faded>
                                    )}
                                </DesktopAdjustedWrapper>
                            </WindowPageTemplate>
                        )}
                    </CenteredPageWrapper>
                    <DeletionConfirmationDesktop
                        isShare
                        isEditForm
                        isOpen={directShareDeletionConfirmation.isOpen}
                        toggle={() =>
                            setTimeout(
                                () =>
                                    toggleShareDeletionConfirmation({
                                        isOpen: false,
                                        entity_id: undefined,
                                        entity_type: undefined,
                                        share_id: undefined,
                                    }),
                                50
                            )
                        }
                        action={() =>
                            this.handleDelete(directShareDeletionConfirmation)
                        }
                        actionCopy="Confirm deletion"
                        titleCopy="Are you sure?"
                        detailsCopy="All recipients will lose access to your car."
                    />
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <SideSlider
                        direction={
                            this.props.location.state &&
                            this.props.location.state.prevPath &&
                            this.props.location.state.prevPath.includes(
                                `/edit-share/recipients`
                            )
                                ? 'left'
                                : 'right'
                        }
                        initial="enter"
                    >
                        <PrivateShareReturnNav
                            customText={
                                current_share && current_share.title
                                    ? current_share.title
                                    : 'Untitled'
                            }
                            chevron
                            linkTo={`/sharing/with-others/car`}
                            params={`?carid=${carid}`}
                            noBoxShadow
                        />
                        <div style={{ paddingTop: '60px' }} />
                        <StickyWrapper>
                            <SwitchTabMenuMobile
                                btnIdsList={btnIdsList}
                                btnsData={btnsData}
                                activeID={active_tab}
                            />
                        </StickyWrapper>
                        {this.state.activeTabId === 'settings' && (
                            <PrivateShareFormManagerMobile
                                share_being_edited={share_being_edited}
                                handleSubmit={this.handleSubmitToBE}
                                handlePrivateShareTitleChange={
                                    this.handleTitleChange
                                }
                                handlePermissions={this.handleChangePermissions}
                                formType="edit"
                                isSaveActive={
                                    current_share &&
                                    share_being_edited &&
                                    hasShareChanged(
                                        current_share,
                                        share_being_edited
                                    )
                                }
                                shareChanges={checkWhatIsBeingUpdated()}
                                onDeleteShare={() =>
                                    setTimeout(
                                        () =>
                                            shareid &&
                                            toggleShareDeletionConfirmation({
                                                isOpen: true,
                                                entity_id: carid,
                                                entity_type: 'car',
                                                share_id: shareid,
                                            })
                                    )
                                }
                                sharesLoading={sharesLoading}
                            />
                        )}
                        {this.state.activeTabId === 'recipients' && (
                            <MobileWrapper>
                                <SideSliderWithExit
                                    direction={'right'}
                                    initial="enter"
                                    isVisible={
                                        addShareRecipientByEmailForm &&
                                        addShareRecipientByEmailForm
                                            ? true
                                            : false
                                    }
                                >
                                    {carid && (
                                        <AddShareByEmailFormMobile
                                            close={() => {
                                                toggleAddShareRecipientByEmail({
                                                    isOpen: false,
                                                })
                                            }}
                                            share_id={share_being_edited.id}
                                            entity_id={carid}
                                        />
                                    )}
                                </SideSliderWithExit>
                                <Container>
                                    <Title>
                                        Choose your Share Recipient(s)
                                    </Title>
                                    <SubTitle>
                                        {`Please search and select the recipient(s) that you would like to share your car with by entering their email.`}
                                    </SubTitle>
                                    <Padding24 />

                                    <PrivateShareSearchManagerMobileV2
                                        share={share_being_edited}
                                        searchUsers={
                                            checkUserEmailExistsRequest
                                        }
                                        searchResults={searchResults}
                                        resultsLoading={searchResultsLoading}
                                        addShareRecipient={
                                            this.props
                                                .addCarShareRecipientRequest
                                        }
                                        selectedUsers={
                                            share_being_edited.all_share_recipients_ids_list
                                        }
                                        onClickInvite={() =>
                                            toggleAddShareRecipientByEmail({
                                                isOpen: true,
                                            })
                                        }
                                        inviteTxt="Invite to Custodian via Email & Share"
                                    />

                                    {/* <PickerBtn
                                        onClick={() =>
                                            this.props.history.push(
                                                `/sharing/with-others/car/${carid}/edit-share/recipients?shareid=${shareid}`
                                            )
                                        }
                                        noValue
                                        text=" Search for users"
                                    /> */}

                                    <Padding24 />
                                </Container>
                                {/* <IconAndTextNakedButton
                                    onClick={() =>
                                        toggleAddShareRecipientByEmail({
                                            isOpen: true,
                                        })
                                    }
                                    text="Invite to Custodian via Email & Share"
                                    iconOnLeft
                                    icon="v2_add"
                                /> */}
                                {/* <ComingSoonCard
                                    title={`Invite to Custodian & share`}
                                    description={`Soon you’ll be able to invite anyone you want to join Custodian and share your cars or full garage with them.`}
                                /> */}
                                <div style={{ paddingTop: '40px' }} />

                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <SharedWith>
                                        Direct share recipients
                                    </SharedWith>
                                    <GreyIconButton
                                        onClick={() =>
                                            this.setState({
                                                isSortBottomSheetOpen: true,
                                            })
                                        }
                                        variant="sort"
                                        isActive={false}
                                        sortingCriteria={'by_date_added'}
                                    />
                                </div>

                                <Padding10 />
                                {share_being_edited &&
                                share_being_edited.active_display &&
                                share_being_edited.active_display
                                    .item_ids_display &&
                                share_being_edited.active_display
                                    .item_ids_display.length > 0 ? (
                                    share_being_edited.share_recipients_data &&
                                    share_being_edited.active_display.item_ids_display.map(
                                        (userid: string, index: number) => (
                                            <Faded>
                                                <ShareRowMobile
                                                    recipient={
                                                        share_being_edited!
                                                            .share_recipients_data![
                                                            userid
                                                        ]
                                                    }
                                                    index={index}
                                                    isLast={
                                                        share_being_edited.all_share_recipients_ids_list &&
                                                        share_being_edited
                                                            .all_share_recipients_ids_list
                                                            .length -
                                                            1 ===
                                                            index
                                                            ? true
                                                            : false
                                                    }
                                                    actionsData={{
                                                        email: (payload: {
                                                            recipient_id: string
                                                            recipient_email: string
                                                        }) => {
                                                            return this.props.resendCarShareInviteRequest(
                                                                {
                                                                    invite_id:
                                                                        payload.recipient_id,
                                                                    recipient_email:
                                                                        payload.recipient_email,
                                                                }
                                                            )
                                                        },

                                                        delete: (payload: {
                                                            recipient_id: string
                                                            recipient_type:
                                                                | 'platform'
                                                                | 'external'
                                                        }) => {
                                                            return payload.recipient_type ===
                                                                'external'
                                                                ? this.props.deleteCarShareInviteRequest(
                                                                      {
                                                                          share_id:
                                                                              share_being_edited.id,
                                                                          entity_id:
                                                                              share_being_edited.entity_id,
                                                                          invite_id:
                                                                              payload.recipient_id,
                                                                      }
                                                                  )
                                                                : this.props.deleteCarShareRecipientRequest(
                                                                      {
                                                                          share_id:
                                                                              share_being_edited.id,
                                                                          entity_id:
                                                                              share_being_edited.entity_id,
                                                                          recipient_userid:
                                                                              payload.recipient_id,
                                                                      }
                                                                  )
                                                        },
                                                    }}
                                                    sharedOn={
                                                        share_being_edited!
                                                            .share_recipients_data![
                                                            userid
                                                        ].date_added_to_share
                                                    }
                                                />
                                            </Faded>
                                        )
                                    )
                                ) : (
                                    <Faded>
                                        <NoIconDataRectangle
                                            text={`You haven’t shared this with anyone yet.`}
                                        />
                                    </Faded>
                                )}
                                <PrivateSharesSortingMobile
                                    actions_items={[
                                        {
                                            id: 'added_at',
                                            text: 'By date added',
                                            action: () => {
                                                current &&
                                                    sortShareRecipientsDisplay_change_request(
                                                        {
                                                            sortID: 'added_at',
                                                            entityid:
                                                                current?.id,
                                                            share: share_being_edited,
                                                        }
                                                    )
                                            },
                                        },
                                        {
                                            id: 'alphabetical',
                                            text: 'Alphabetical',
                                            action: () => {
                                                current &&
                                                    sortShareRecipientsDisplay_change_request(
                                                        {
                                                            sortID: 'alphabetical',
                                                            entityid:
                                                                current?.id,
                                                            share: share_being_edited,
                                                        }
                                                    )
                                            },
                                        },
                                    ]}
                                    toggle={() =>
                                        this.setState({
                                            ...this.state,
                                            isSortBottomSheetOpen: false,
                                        })
                                    }
                                    isPrivateSharesSortingOpen={
                                        this.state.isSortBottomSheetOpen
                                    }
                                    activeSort={
                                        share_being_edited.active_display &&
                                        share_being_edited.active_display
                                            .activeSort
                                            ? share_being_edited.active_display
                                                  .activeSort
                                            : 'added_at'
                                    }
                                />
                            </MobileWrapper>
                        )}
                        <div style={{ paddingTop: '150px' }} />
                    </SideSlider>
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CarShareEdit))
