import React from 'react'
import { Select } from 'antd'
import styled from 'styled-components'
import { IDropdownItem } from 'entityModels'

const { Option } = Select

const SelectBarContainer = styled.div<{ $customActiveColour?: string }>`
    display: flex;
    border: ${(props) =>
        props.$customActiveColour
            ? `1px solid ${props.$customActiveColour}`
            : `1px solid #5ec3ca`};
    border-radius: 2px;
    background-color: transparent;

    .ant-select-selection-item-remove {
        display: none;
    }
`

export type Props = {
    handleChange: (values: string[]) => any
    optionsList: IDropdownItem[]
    item?: any
    isDisabled?: boolean
    customActiveColour?: string
}

class MultipleSelectDropdown extends React.Component<Props> {
    render() {
        const {
            handleChange,
            optionsList,
            item,
            isDisabled,
            customActiveColour,
        } = this.props

        const mappedList = optionsList
            ? optionsList.map((d: IDropdownItem) => (
                  <Option value={d.uid} key={d.uid} aria-label="UK">
                      <span style={{ marginLeft: '10px' }}>{d.name} </span>
                  </Option>
              ))
            : null

        return (
            <SelectBarContainer $customActiveColour={customActiveColour}>
                <Select
                    mode="multiple"
                    value={item.answers}
                    placeholder={
                        item.answers
                            ? item.answers
                            : `Search through ${item && item.text}`
                    }
                    onChange={(values: string[]) => {
                        handleChange(values)
                    }}
                    autoClearSearchValue={true}
                    style={{
                        width: '100%',
                        fontFamily: 'Lato',
                    }}
                    size="large"
                    disabled={isDisabled}
                >
                    {mappedList}
                </Select>
            </SelectBarContainer>
        )
    }
}

export default MultipleSelectDropdown
