import * as React from 'react'
import styled from 'styled-components'
import add_plus_darker from '../../../public/assets/icons/add_plus_darker.svg'
import v2_edit_no_circle from '../../../public/assets/icons/v2_edit_no_circle.svg'
import v2_add from '../../../public/assets/icons/v2_add.svg'
import v2_add_disabled from '../../../public/assets/icons/v2_add_disabled.svg'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IStyle {
    $theme: ITheme
    $iconOnLeft?: boolean | undefined
    $isUppercase?: boolean | undefined
    $capitalize?: boolean
    $width?: string | undefined
    $editIcon?: boolean
    $roundAdd?: boolean
    $disabled?: boolean
}

const Button = styled(motion.div)<IStyle>`
    position: relative;
    display: flex;
    justify-content: ${(props) =>
        props.$iconOnLeft ? 'space-between' : 'center'};
    align-items: center;
    width: ${(props) => (props.$width ? props.$width : 'fit-content')};
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: ${(props) => colours[props.$theme].background_default};
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
`

const Icon = styled.img<IStyle>`
    width: ${(props) => (props.$editIcon || props.$roundAdd ? '20px' : '10px')};
    height: ${(props) =>
        props.$editIcon || props.$roundAdd ? '20px' : '10px'};
    ${(props) =>
        props.$iconOnLeft ? 'margin-right: 5px;' : 'margin-left: 5px;'}
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.$disabled
            ? colours[props.$theme].text_muted
            : colours[props.$theme].primary};
    font-family: 'Lato';
    font-size: 12px;
    letter-spacing: 0.7px;
    text-transform: ${(props) =>
        props.$isUppercase
            ? 'uppercase'
            : props.$capitalize
            ? 'capitalize'
            : 'lowercase'};
    ${(props) => props.$roundAdd && 'padding-left: 5px;'};
    @media ${device.beyond_ipad_mobile} {
        ${(props) => props.$roundAdd && 'transform: translateY(2px);'};
    }
`

type Props = {
    onClick: any
    iconOnLeft?: boolean | undefined
    iconOnRight?: boolean | undefined
    text: string | undefined
    dataCyId?: string | undefined
    isUppercase?: boolean
    width?: string | undefined
    editIcon?: boolean
    roundAdd?: boolean
    capitalize?: boolean
    disabled?: boolean
    comingSoon?: boolean
}

const AddNewNaked: React.FunctionComponent<Props> = (props: Props) => {
    let {
        onClick,
        iconOnLeft,
        iconOnRight,
        text,
        isUppercase,
        width,
        dataCyId,
        editIcon,
        roundAdd,
        capitalize,
        disabled,
        comingSoon,
    } = props

    const [isTooltipOn, setIsTooltipOn] = React.useState(false)

    if (isTooltipOn) {
        setTimeout(() => setIsTooltipOn(false), 10000)
    }

    const { theme } = useThemes()

    return (
        <Button
            $width={width}
            onMouseEnter={() => setIsTooltipOn(true)}
            onClick={!disabled ? onClick : () => setIsTooltipOn(true)}
            whileTap={{ scale: disabled ? 1 : 1.05 }}
            data-attr={dataCyId ? dataCyId : undefined}
            $disabled={disabled}
            $theme={theme}
        >
            {iconOnLeft && (
                <Icon
                    $editIcon={editIcon}
                    $iconOnLeft={iconOnLeft}
                    src={
                        editIcon
                            ? v2_edit_no_circle
                            : roundAdd
                            ? disabled
                                ? v2_add_disabled
                                : v2_add
                            : add_plus_darker
                    }
                    $roundAdd={roundAdd}
                    $theme={theme}
                ></Icon>
            )}
            <Text
                $isUppercase={isUppercase}
                $capitalize={capitalize}
                $roundAdd={roundAdd}
                $disabled={disabled}
                $theme={theme}
            >
                {text}
                {comingSoon && (
                    <span
                        style={{
                            fontStyle: 'italic',
                            textTransform: 'lowercase',
                        }}
                    >{` (coming soon)`}</span>
                )}
            </Text>

            {iconOnRight && (
                <Icon
                    $editIcon={editIcon}
                    src={
                        editIcon
                            ? v2_edit_no_circle
                            : roundAdd
                            ? v2_add
                            : add_plus_darker
                    }
                    $roundAdd={roundAdd}
                    $theme={theme}
                ></Icon>
            )}
        </Button>
    )
}

export default AddNewNaked
