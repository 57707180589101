import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

export interface Props {
    children: React.ReactNode
}

const H1Styled = styled.h2`
    font-family: Lato-Bold;
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    text-align: left;
    margin: 0;
    padding: 0;

    @media ${device.mobile} {
    }
`

const EnquiryStepTitle: React.FC<Props> = (props) => {
    return <H1Styled>{props.children}</H1Styled>
}

export default EnquiryStepTitle
