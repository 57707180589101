export const GET_HIGHLIGHTED_FACTS_REQUEST = '@@highlighted_facts/get/REQUEST'
export const GET_HIGHLIGHTED_FACTS_SUCCESS = '@@highlighted_facts/get/SUCCESS'
export const GET_HIGHLIGHTED_FACTS_ERROR = '@@highlighted_facts/get/ERROR'

export const EDIT_HIGHLIGHTED_FACTS_REQUEST = '@@highlightedfacts/edit/REQUEST'
export const EDIT_HIGHLIGHTED_FACTS_SUCCESS = '@@highlightedfacts/edit/SUCCESS'
export const EDIT_HIGHLIGHTED_FACTS_ERROR = '@@highlightedfacts/edit/ERROR'

export const SET_HIGHLIGHTED_FACTS_REQUEST = '@@highlighted_facts/set/REQUEST'
export const SET_HIGHLIGHTED_FACTS_SUCCESS = '@@highlighted_facts/set/SUCCESS'
export const SET_HIGHLIGHTED_FACTS_ERROR = '@@highlighted_facts/set/ERROR'

export const CLEAN_UP_HIGHLIGHTED_FACTS_SUCCESS =
    '@@highlighted_facts/clean_up/SUCCESS'
