import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
// import close_cross_grey from '../../../../public/assets/icons/close_cross_grey.svg'
import grey_tip from '../../../../public/assets/icons/grey_tip.svg'
import TutorialWrapperMobile from '../tutorialWrappers/TutorialWrapperMobile'

// const TourStyled = styled(Tour)`
const TourStyled = styled((props: any) => <Tour {...props} />)`
    display: none;
    @media ${device.mobile_and_ipad} {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`

// const Wrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//     padding-top: 10px;
//     padding-bottom: 10px;
// `

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 16px;
    justify-content: center;
    text-align: center;

    @media ${device.mobile} {
    }
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 14px;
    line-height: 1.9;

    @media ${device.mobile} {
    }
`

const Padding = styled.div`
    padding-top: 20px;
`
const TextPadding = styled.div`
    padding-top: 8px;
`

const Tip = styled.img`
    width: 20px;
    @media ${device.mobile} {
    }
`

const TipText = styled.div`
    font-family: Lato-Light;
    padding-left: 20px;
    font-size: 14px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile} {
    }
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
`

// const NextBtn = styled.div`
//     position: absolute;
//     right: 30px;
//     bottom: 20px;
//     color: #5EC3CA;
//     cursor: pointer;
//     font-size: 15px;
//     font-family: lato-light;
// `

const tutorialConfigs_Garage = (close: any) => [
    {
        selector: '[data-tut="first-step-garage-mobile"]',
        position: [10, 100],
        content: () => (
            <TutorialWrapperMobile next={close} nextBtnText="close">
                <Title>Welcome to your Virtual Garage</Title>
                <Padding />

                <Text>
                    Whether you’ve got one car or an entire collection, you’ll
                    be able to see them here.
                </Text>

                <TextPadding />

                <Text>
                    You can add your profile photo, contact details and
                    automotive bio on your Profile page in the account menu.
                </Text>

                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>
                        You can change your display name from the profile page.
                    </TipText>
                </TipRow>
            </TutorialWrapperMobile>
        ),
    },
]

interface Props extends RouteComponentProps<any> {
    registerGarageTutorial: any
}

const TutorialConfigsGarageMobile: React.FunctionComponent<Props> = (props) => {
    const [isTourOpen, setIsTourOpen] = useState(true)

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        props.history.push(`/garage`)
    }

    useEffect(() => {
        props.registerGarageTutorial()
    }, [])

    return (
        <>
            {window.innerWidth < 880 && (
                <TourStyled
                    steps={tutorialConfigs_Garage(setIsTourOpenCustom)}
                    isOpen={isTourOpen}
                    onRequestClose={() => undefined}
                    rounded={5}
                    accentColor="#5ec3ca"
                    disableInteraction={true}
                    showCloseButton={false}
                    showNavigation={false}
                />
            )}
        </>
    )
}

export default withRouter(TutorialConfigsGarageMobile)
