import { ReactNode } from 'react'
import styled from 'styled-components'
import InfoIcon from '../icons/infoIcon'

type Props = {
    children: ReactNode
    bg?: string
}

const WrapInfo = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
    justify-content: flex-start;
`
const InfoTxt = styled.div`
    color: #666;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

export const InfoBoxAtom = (props: Props) => {
    return (
        <WrapInfo
            style={{ backgroundColor: props.bg ?? 'rgba(26, 26, 26, 0.02)' }}
        >
            <div
                style={{
                    height: '18px',
                    width: '18px',
                    transform: 'translateY(10px)',
                }}
            >
                <InfoIcon color="#B3B3B3" size="18px" />
            </div>

            <InfoTxt>{props.children}</InfoTxt>
        </WrapInfo>
    )
}
