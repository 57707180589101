import { motion } from 'framer-motion'

const CardMotionDivWrapper = (props: any) => (
    <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ type: 'spring', duration: 0.15 }}
    >
        {props.children}
    </motion.div>
)

export default CardMotionDivWrapper
