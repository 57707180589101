import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
// import TimelineEditEntryHeader from '../../atoms/header/timelineEditEntryHeader'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import {
    ICostItem,
    IDeleteTimelineItemPayloadRequest,
    IGetTimelineItemByIdPayloadRequest,
    IGetTimelineItemsByCarIDPayloadRequest,
    ITimelineCreationFormFieldsData,
    // INormalisedCostsById,
    ITimelineDataByCarNormalised,
    ITimelineDataToBeSubmittedObj,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
    IUpdateEntryLabelPayload,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'
import {
    ILabel,
    ITimelineCreateForms,
    ITimelineEditForms,
    IUser,
} from 'myModels'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import {
    deleteTimelineEntryRequest,
    getAllTimelineItemsByCarIDRequest,
    getTimelineItemByIDRequest,
    removeLabelFromCarEntryRequest,
    updateTimelineEntryRequest,
} from '../../../redux/timeline/actions/requestActions'
import TimelineEditManagerDesktop from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerDesktop'
import TimelineEditManagerMobile from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerMobile'
import {
    IDropdownItem,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'

import {
    clearDataToBeSubmittedSuccess,
    setActiveEntrySuccess,
} from '../../../redux/timeline/actions/successActions'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { writeTechnicalInformationRequest } from '../../../redux/entities/technical_information/actions/loadingActions'
import { timeline_category_data } from '../../../redux/timeline/data'

interface Props extends RouteComponentProps<any> {
    activeEntry: ITimelineItem | undefined | null
    activeCost: ICostItem | undefined | null
    timelineItemEditForm: ITimelineEditForms
    manageTimelineItemEditForm: any
    attachmentsObj: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted: ITimelineDataToBeSubmittedObj
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) => {}
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemsByCarIDPayloadRequest
    ) => {}
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) => void
    setActiveEntry: (payload: ITimelineItem) => void
    deleteTimelineEntry: (payload: IDeleteTimelineItemPayloadRequest) => void
    user_searchable_labels_list: IDropdownItem[] | null | undefined
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    setCurrentCar: (car_id: string) => void
    clearDataToBeSubmitted: (payload: boolean) => void
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    entry_labels_list: ILabel[] | undefined
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) => void
    setInitialLandingUrl: (str: string) => void
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => void
    isCollapsed: boolean
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        carsData: state.entities.carsData.cars,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        data_to_be_submitted:
            state.timeline.formsData.creation.data_to_be_submitted,
        activeEntry: state.timeline.activeEntry,
        activeCost: state.timeline.activeCost,
        timelineItemEditForm: state.editForms.timelineItemEditForm,
        attachmentsObj: state.attachments.attachmentsById,
        // costsObj: state.timeline.cost_items_normalised_by_cost_id,
        timeline_creation_steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        timeline_creation_steps_object_of_indexed_list:
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list,
        user_searchable_labels_list:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
        entry_labels_list:
            state.timeline.activeEntry && state.timeline.activeEntry.labels,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemsByCarIDPayloadRequest
    ) => getAllTimelineItemsByCarIDRequest(payload),
    manageTimelineItemEditForm: (payload: ITimelineEditForms) =>
        editFormsActions.manageTimelineItemEditForm(payload),
    manageTimelineCreationFormState: (payload: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(payload),
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) =>
        getTimelineItemByIDRequest(payload),
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) =>
        updateTimelineEntryRequest(payload),
    deleteTimelineEntry: (payload: IDeleteTimelineItemPayloadRequest) =>
        deleteTimelineEntryRequest(payload),
    clearDataToBeSubmitted: (payload: boolean) =>
        clearDataToBeSubmittedSuccess(payload),
    setActiveEntry: (payload: ITimelineItem) => setActiveEntrySuccess(payload),
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) =>
        removeLabelFromCarEntryRequest(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => writeTechnicalInformationRequest(p),
}

type State = {
    // bottomUpSlider: boolean
    isMobileActionsSubMenuOpen: boolean
    bottomsheetTimer: number
    showConfirmDeleteEntrySheet: boolean
    showRightSide: boolean
}

class EditTimelineEntry extends React.PureComponent<Props, State> {
    state = {
        isMobileActionsSubMenuOpen: false,
        bottomsheetTimer: 500,
        showConfirmDeleteEntrySheet: false,
        showRightSide: true,
    }

    _isMounted = false

    handleResize = () =>
        window.innerWidth < 1201
            ? this._isMounted &&
              this.setState({ ...this.state, showRightSide: false })
            : this._isMounted &&
              this.setState({ ...this.state, showRightSide: true })

    navigateEntries = (direction: 'next' | 'previous') => {
        let carid = this.props.match.params.carid
        let all_entries: ITimelineItem[] | null | undefined =
            this.props.carsTimelineData &&
            this.props.carsTimelineData[carid] &&
            this.props.carsTimelineData[carid].entries

        let activeEntry = this.props.activeEntry
        let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
            function (entry: ITimelineItem) {
                return activeEntry && entry.id === activeEntry.id
            }
        )

        let nextEntry =
            all_entries &&
            (indexOFActiveEntry || indexOFActiveEntry === 0) &&
            (indexOFActiveEntry < all_entries.length - 1
                ? all_entries[indexOFActiveEntry + 1]
                : all_entries[all_entries.length - 1])

        let previousEntry =
            all_entries &&
            (indexOFActiveEntry || indexOFActiveEntry === 0) &&
            (indexOFActiveEntry > 1
                ? all_entries[indexOFActiveEntry - 1]
                : all_entries[0])

        return direction === 'next'
            ? nextEntry && this.props.setActiveEntry(nextEntry)
            : previousEntry && this.props.setActiveEntry(previousEntry)
    }

    componentDidMount() {
        const q_params = new URLSearchParams(this.props.location.search)
        let entryid_from_q_params: string | null = q_params.get('entry_id')
        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/history-file/${entryid_from_q_params}/edit`
        )

        this._isMounted = true
        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        let carid = this.props.match.params.carid
        this.props.setCurrentCar(carid)

        // start handling adding label from outside single entry page

        let all_entries: ITimelineItem[] | null | undefined =
            this.props.carsTimelineData &&
            this.props.carsTimelineData[carid] &&
            this.props.carsTimelineData[carid].entries

        if (!all_entries) {
            this.props.getAllTimelineItemsByCarIDRequest({
                car_id: carid,
            })
        }

        let indexOFActiveEntryParam: number | undefined =
            all_entries?.findIndex(function (entry: ITimelineItem) {
                return entry.id === entryid_from_q_params
            })

        let activeEntryFromParams =
            all_entries &&
            indexOFActiveEntryParam !== undefined &&
            all_entries[indexOFActiveEntryParam]
                ? all_entries[indexOFActiveEntryParam]
                : undefined

        activeEntryFromParams &&
            this.props.setActiveEntry(activeEntryFromParams)

        // end handling adding label from outside single entry page

        if (userLoggedIn) {
            if (!this.props.activeEntry && entryid_from_q_params) {
                this.props.getEntryByIDRequest({
                    entryUID: entryid_from_q_params,
                    car_id: carid,
                })
            }
        }

        let editFormId: string | null = q_params.get('section_id')

        let activeEntry: ITimelineItem | undefined | null =
            this.props.activeEntry

        activeEntry &&
            editFormId &&
            this.props.manageTimelineItemEditForm({
                id: editFormId,
                category: activeEntry.categoryID,
                isOpen: true,
            })

        window.scrollTo(0, 0)
        window.innerWidth < 1201
            ? this._isMounted &&
              this.setState({ ...this.state, showRightSide: false })
            : this._isMounted &&
              this.setState({ ...this.state, showRightSide: true })
        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate(prevProps: any) {
        let carid = this.props.match.params.carid
        let entryid = this.props.activeEntry && this.props.activeEntry.id

        const q_params = new URLSearchParams(this.props.location.search)
        let entryid_from_q_params: string | null = q_params.get('entry_id')

        if (
            this.props.activeEntry !== prevProps.activeEntry ||
            this.props.activeCost !== prevProps.activeCost ||
            prevProps.userLoggedIn !== this.props.userLoggedIn
        ) {
            // let entryid = this.props.match.params.entryid

            if (entryid) {
                this.props.getEntryByIDRequest({
                    entryUID: entryid,
                    car_id: carid,
                })
            }

            let editFormId: string | null = q_params.get('section_id')

            let activeEntry: ITimelineItem | undefined | null =
                this.props.activeEntry

            activeEntry &&
                editFormId &&
                this.props.manageTimelineItemEditForm({
                    id: editFormId,
                    category: activeEntry.categoryID,
                    isOpen: true,
                })
        }

        let all_entries: ITimelineItem[] | null | undefined =
            this.props.carsTimelineData &&
            this.props.carsTimelineData[carid] &&
            this.props.carsTimelineData[carid].entries

        let prev_all_entries: ITimelineItem[] | null | undefined =
            prevProps.carsTimelineData &&
            prevProps.carsTimelineData[carid] &&
            prevProps.carsTimelineData[carid].entries

        if (all_entries !== prev_all_entries) {
            let indexOFActiveEntryParam: number | undefined =
                all_entries?.findIndex(function (entry: ITimelineItem) {
                    return entry.id === entryid_from_q_params
                })

            let activeEntryFromParams =
                all_entries &&
                indexOFActiveEntryParam !== undefined &&
                all_entries[indexOFActiveEntryParam]
                    ? all_entries[indexOFActiveEntryParam]
                    : undefined

            activeEntryFromParams &&
                this.props.setActiveEntry(activeEntryFromParams)
        }
    }

    componentWillUnmount() {
        this.props.manageTimelineCreationFormState({
            isOpen: false,
            current_step_index: 0,
            active_creation: null,
        })

        this.props.clearDataToBeSubmitted(true)

        this.props.manageTimelineItemEditForm({
            isOpen: false,
        })
        window.removeEventListener('resize', this.handleResize)
    }

    generatePrePopulatedLabels = (): IDropdownItem[] => {
        let { activeEntry, user_searchable_labels_list } = this.props

        let user_searchable_labels_list_names =
            user_searchable_labels_list?.map((label) => label.name)

        if (activeEntry && activeEntry.categoryID) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                activeEntry.categoryID
            ].pre_populated_labels
                .filter(
                    (label) =>
                        !user_searchable_labels_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    render() {
        let carid: string = this.props.match.params.carid
        let {
            manageTimelineItemEditForm,
            timelineItemEditForm,
            activeEntry,
            updateTimelineEntry,
            user_searchable_labels_list,
            writeTechnicalInformationRequest,
            isCollapsed,
        } = this.props

        let close_edit_form = () => {
            this.props.history.push(
                `/car/${carid}/history-file/entry?entryid=${
                    activeEntry && activeEntry.id && activeEntry.id
                }`
            )
            manageTimelineItemEditForm({
                isOpen: false,
            })
        }

        return (
            <CenteredPageWrapper fullwidth>
                <Faded>
                    {activeEntry && (
                        <>
                            {timelineItemEditForm.isOpen && (
                                <DesktopDisplayOnly>
                                    <WindowPageTemplate
                                        isCollapsed={isCollapsed}
                                        rightSideContent={
                                            activeEntry &&
                                            this.state.showRightSide && (
                                                <EntryOptionsMenuBar
                                                    carid={carid}
                                                    activeEntry={activeEntry}
                                                />
                                            )
                                        }
                                    >
                                        <TimelineEditManagerDesktop
                                            item_to_edit={activeEntry}
                                            timelineItemEditForm={
                                                timelineItemEditForm
                                            }
                                            savingEdit={(
                                                edited_item: ITimelineItem
                                            ) => {
                                                updateTimelineEntry({
                                                    carid: carid,
                                                    item: edited_item,
                                                })
                                            }}
                                            closeForm={close_edit_form}
                                            dataCyId={'edit-timeline-entry'}
                                            entityID={activeEntry.id}
                                            entityType="entry"
                                            carID={carid}
                                        />
                                    </WindowPageTemplate>
                                </DesktopDisplayOnly>
                            )}

                            <IpadAndMobileDisplay>
                                {timelineItemEditForm.isOpen && (
                                    <BottomUpSlider
                                        show={timelineItemEditForm.isOpen}
                                    >
                                        <TimelineEditManagerMobile
                                            item={activeEntry}
                                            timelineItemEditForm={
                                                timelineItemEditForm
                                            }
                                            savingEdit={(
                                                editedItem: ITimelineItem,
                                                carMileage: {
                                                    sendToBe: boolean
                                                    payload: IwriteTechnicalInformationPayloadReq
                                                }
                                            ) => {
                                                updateTimelineEntry({
                                                    carid: carid,
                                                    item: editedItem,
                                                })
                                                carMileage.sendToBe &&
                                                    writeTechnicalInformationRequest(
                                                        carMileage.payload
                                                    )
                                            }}
                                            closeForm={close_edit_form}
                                            dataCyId={'edit-timeline-entry'}
                                            labels_searchable_list={
                                                user_searchable_labels_list
                                            }
                                            entityID={activeEntry.id}
                                            entityType="entry"
                                            carID={carid}
                                        />
                                    </BottomUpSlider>
                                )}
                            </IpadAndMobileDisplay>
                        </>
                    )}
                </Faded>
            </CenteredPageWrapper>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(EditTimelineEntry)
)
