import Addresses from './addresses'
import InsuranceDetails from './details'
import Drivers from './drivers'
import EverydayVehicles from './everydayCar'
import MainPolicyHolder from './mainDriver'
import InsuranceReferralCodeIcon from './referralCodeIcon'
import VehiclesToInsure from './vehicles'

const InsuranceIcons = {
    Addresses,
    InsuranceDetails,
    Drivers,
    EverydayVehicles,
    MainPolicyHolder,
    VehiclesToInsure,
    InsuranceReferralCodeIcon,
}

export default InsuranceIcons
