import './styles.css'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { isSafari } from 'react-device-detect'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IInputFieldProps {
    name: string
    value?: string | number | undefined
    placeholder: string
    handleChange?: (e: any) => any
    type?: any
    onBlur?: any
    onFocus?: any
    onChange?: any
    onEnterKeyPress?: any
    disabled?: boolean

    id?: string
    tabindex?: number | string
    isNarrow?: boolean

    font_family?: string
    width?: string
}

interface StandardInputProps {
    disabled?: boolean
    tabindex?: number | string
    isNarrow?: boolean
    font_family?: string
    width?: string
    $theme: ITheme
}

const StandardInput = styled.input<StandardInputProps>`
    z-index: 6;
    font-family: ${(props) => (props.font_family ? props.font_family : 'Lato')};
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
    border-bottom: #616161 1px solid;
    background-color: transparent !important;
    pointer-events: ${(props) => props.disabled === true && 'none !important '};
    opacity: ${(props) => (props.disabled ? '0.5' : '1')};
    padding: 12px;

    ::placeholder {
        font-family: Lato-Light;
        color: #666666;
        text-transform: uppercase;
        font-size: ${(props) => (props.isNarrow ? '12px' : '12px')};
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px
            ${(props) => colours[props.$theme].background_default} inset !important;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: ${(props) =>
            colours[props.$theme].text_strong} !important;
    }

    @media ${device.desktop} {
        font-size: ${(props) => (props.isNarrow ? '12px' : '16px')};
        width: ${(props) =>
            props.width ? props.width : props.isNarrow ? '230px' : '500px'};
        height: ${(props) => (props.isNarrow ? '30px' : '40px')};
    }

    @media ${device.ipad} {
        font-size: 16px;
        width: ${(props) => (props.isNarrow ? '200px' : '400px')};
        height: ${(props) => (props.isNarrow ? '30px' : '40px')};
        margin-top: 20px;
    }

    @media ${device.mobile} {
        font-size: 14px;
        width: ${(props) => (props.width ? props.width : '80vw')};
        padding-bottom: 10px;
        padding-top: 14px;
    }
`

function InputFieldNoFormik({
    name,
    value,
    placeholder,
    type,
    onBlur,
    onChange,
    onEnterKeyPress,
    id,
    disabled,
    tabindex,
    isNarrow,
    font_family,
    width,
}: IInputFieldProps) {
    const { theme } = useThemes()
    return (
        <StandardInput
            $theme={theme}
            id={id}
            name={name}
            type={type ? type : 'text'}
            value={value && value}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
            tabindex={tabindex}
            // isNarrow={isNarrow ? "true" : "false"}
            font_family={font_family && font_family}
            width={width && width}
            onKeyDown={(e: any) => {
                if (e?.which === 13) {
                    if (onEnterKeyPress) {
                        onEnterKeyPress()
                    } else {
                        if (isSafari) {
                            e.preventDefault()
                        }
                    }
                } else if (e?.key === 'Enter') {
                    if (onEnterKeyPress) {
                        onEnterKeyPress()
                    } else {
                        if (isSafari) {
                            e.preventDefault()
                        }
                    }
                }
            }}
        />
    )
}

export default InputFieldNoFormik
