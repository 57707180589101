import { useEffect } from 'react'
import {
    IAnyErrorString,
    IInsuranceQuoteApplication_OtherDriverObj,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_fields_quick_new_driver } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../organisms/insuranceQuote/formGenerators'
import { WindowBodyWrapper } from './driveListModal'

type Props = {
    driverID?: string
    initialiseDriver?: () => any
    updateDriver: (fieldID: string, value: any) => any
}

const QuickAddNewDriver = (props: Props) => {
    // let initial_driver_id = 'new_driver_uid'

    let { driverID } = props

    const all_data_otherdrivers: IInsuranceQuoteApplication_OtherDriverObj =
        useAppSelector((state) => {
            return state.insuranceQuoteApplication.draft.other_drivers
        })

    let data = all_data_otherdrivers[`${driverID}`]?.basic_details

    useEffect(() => {
        if (!data) {
            props.initialiseDriver && props.initialiseDriver()
        }

        // not calling this because only if person clicks outside of the save
        // we should have a cancel function at the top
        // return () => {
        //     dispatch(insuranceActions.remove_initialized_new_other_driver())
        // }
    }, [data])

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        if (driverID) {
            return state.insuranceQuoteApplication.draft.other_drivers[driverID]
                ?.basic_details_errors
        } else return undefined
    })

    const dispatch = useAppDispatch()

    let { updateDriver } = props
    // on save, id will be replaced by back end driver id
    let onAnswerChange = (id: string, answer: any) => {
        if (driverID) {
            updateDriver(id, answer)
            dispatch(
                insuranceActions.set_answer_other_driver_basicDetails({
                    id: id,
                    answer: answer,
                    userid: driverID,
                })
            )
        }
    }

    let setError = (id: string, error: string | undefined) => {
        if (driverID) {
            dispatch(
                insuranceActions.set_error_insurance_other_driver_basic_details(
                    {
                        id: id,
                        error: error,
                        userid: driverID,
                    }
                )
            )
        }
    }

    // console.log(
    //     'data',
    //     data,
    //     'driverID',
    //     driverID,
    //     all_data_otherdrivers['new_driver_uid']
    // )
    return (
        <WindowBodyWrapper>
            {data && (
                <InsuranceQuoteFormGenerator
                    sectionID="drivers"
                    list={insurance_fields_quick_new_driver}
                    onAnswerChange={onAnswerChange}
                    data={data}
                    errors={errors}
                    setError={setError}
                    bg="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
            )}
        </WindowBodyWrapper>
    )
}

export default QuickAddNewDriver
