import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { IGalleryImage } from 'entityModels'
import FullWidthMobile from '../../../atoms/image/fullWidthMobile'
import img_1 from '../../../atoms/skeleton/gallery/1.jpg'
import img_2 from '../../../atoms/skeleton/gallery/2.jpg'
import img_3 from '../../../atoms/skeleton/gallery/3.jpg'
import img_4 from '../../../atoms/skeleton/gallery/4.jpg'
import img_5 from '../../../atoms/skeleton/gallery/5.jpg'
import img_6 from '../../../atoms/skeleton/gallery/6.jpg'
import img_1_dark from '../../../atoms/skeleton/gallery/1_dark.png'
import img_2_dark from '../../../atoms/skeleton/gallery/2_dark.png'
import img_3_dark from '../../../atoms/skeleton/gallery/3_dark.png'
import img_4_dark from '../../../atoms/skeleton/gallery/4_dark.png'
import img_5_dark from '../../../atoms/skeleton/gallery/5_dark.png'
import img_6_dark from '../../../atoms/skeleton/gallery/6_dark.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import GalleryOverviewItemJourney from '../../../molecules/imageGrid/galleryOverviewItemJourney'
import useThemes from '../../../../providers/theme/hooks'
import { ITheme } from '../../../../providers/theme/colours'

interface IStyle {}

const Wrapper = styled.section<IStyle>`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    width: 100%;

    @media ${device.ipad} {
        padding-bottom: 30px;
    }
`

export interface IImageProps {
    url: string
}

interface Props {
    cover_img?: IGalleryImage
    images_array: IGalleryImage[] | null
    selectedImages: File[]
    imagesUploadProgress: { [key: string]: number }
    deleteUploadedImg?: (filename: string) => void
    isMobile?: boolean
}

export default function GalleryOverviewManagerJourney(props: Props) {
    const {
        cover_img,
        images_array,
        selectedImages,
        imagesUploadProgress,
        isMobile,
        deleteUploadedImg,
    } = props

    const getPlaceholderImg = (image_number: number, theme: ITheme): string => {
        switch (image_number) {
            case 1:
                return theme === 'light' ? img_1 : img_1_dark

            case 2:
                return theme === 'light' ? img_2 : img_2_dark

            case 3:
                return theme === 'light' ? img_3 : img_3_dark
            case 4:
                return theme === 'light' ? img_4 : img_4_dark
            case 5:
                return theme === 'light' ? img_5 : img_5_dark
            case 6:
                return theme === 'light' ? img_6 : img_6_dark

            default:
                return grey_placeholder
        }
    }

    let imagesToDisplay =
        images_array && selectedImages
            ? [...images_array, ...selectedImages]
            : selectedImages
            ? selectedImages
            : []

    let placeholderArrayNumber =
        cover_img?.id && images_array && images_array?.length > 0
            ? imagesToDisplay && imagesToDisplay?.length < 7
                ? 7 - imagesToDisplay.length
                : 0
            : imagesToDisplay && imagesToDisplay?.length < 6
            ? 6 - imagesToDisplay.length
            : 6

    let placeholdersArray = Array.from(
        {
            length: placeholderArrayNumber,
        },
        (_, i) => `${i + 1}`
    )
    const { theme } = useThemes()

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 5,
            }}
        >
            {isMobile ? null : (
                <FullWidthMobile
                    key={0}
                    img={
                        cover_img
                            ? cover_img
                            : {
                                  id: 'placeholder_0',
                                  url: getPlaceholderImg(1, theme),
                              }
                    }
                    aspectRatio={1.3}
                />
            )}
            <Wrapper>
                {selectedImages.map((img: File, index: number) => {
                    return (
                        <GalleryOverviewItemJourney
                            key={index}
                            uploadedImg={img}
                            uploadProgress={imagesUploadProgress[img.name]}
                            deleteUploaded={deleteUploadedImg}
                        />
                    )
                })}
                {images_array
                    ? images_array.map((img: IGalleryImage, index: number) => {
                          return img.id === cover_img?.id ? null : (
                              <GalleryOverviewItemJourney
                                  key={index}
                                  existingImg={img}
                              />
                          )
                      })
                    : null}

                {placeholdersArray.map((id: string, index: number) => {
                    return (
                        <GalleryOverviewItemJourney
                            key={index}
                            existingImg={{
                                id: `placeholder_${index + 1}`,
                                url: getPlaceholderImg(index + 1, theme),
                            }}
                            isPlaceholder
                        />
                    )
                })}
            </Wrapper>
        </div>
    )
}
