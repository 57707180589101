import styled from 'styled-components'
import { IDropdownItem } from 'entityModels'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import PickerBtn from '../../../../atoms/Button/pickerBtn'
import SwitchButton from '../../../../atoms/Button/switchButton'
import { PaddingUnit } from '../../../../templates/onboarding/onboardingFormLayoutMobile'
import { getStandaloneSearchUrl } from '../../technicalInformationEditForm/searchselect/mobile/standaloneSearchSelectMobile'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { useAppDispatch } from '../../../../../redux/store/hooks'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import SelectSearchBarCarInfoDesktop from '../../technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import { checkIfOnlyCharsAndNumbersAndSpaces } from '../../../../../helpers/strings/regexChecks'

const FormWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
`
type IstyledToggleItem = {
    isActive?: boolean
}
const ToggleText = styled.div<IstyledToggleItem>`
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    color: ${(props) =>
        props.isActive
            ? 'var(--primary, #5EC3CA)'
            : 'var(--text-default, #666)'};
`

interface Props {
    registrationNumber?: string | undefined
    setRegistrationNumber: (p: string | undefined) => void
    countriesdropdownlist: IDropdownItem[]
    userCountry: string | undefined
    selectedCountry?: string | null
    isDesktop?: boolean
    removeToggle?: boolean
    isInsurance?: boolean
}

const AddACarFormOnboardingMobile = (props: Props) => {
    const {
        registrationNumber,
        setRegistrationNumber,
        userCountry,
        selectedCountry,
        isDesktop,
        countriesdropdownlist,
        removeToggle,
        isInsurance,
    } = props

    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            window.scrollTo(0, 0)
        }
    }, [])

    const countryPickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}${
                  isInsurance && !isDesktop ? '&form_open=true' : ''
              }`
            : `${location.pathname}${
                  isInsurance && !isDesktop ? '?form_open=true' : ''
              }`,
        path: `/garage/country`,
        formId: 'country',
        fieldId: 'country',
    })

    const history = useHistory()
    const dispatch = useAppDispatch()

    const [hasNoRegNo, toggleHasNoRegNo] = useState(false)

    return (
        <FormWrapper>
            {isDesktop ? (
                <SelectSearchBarCarInfoDesktop
                    items={countriesdropdownlist}
                    item={{
                        text: 'countries',
                        answer:
                            selectedCountry ?? userCountry ?? 'United Kingdom',
                    }}
                    handleSelectChange={(selected: string) =>
                        dispatch(dropDownActions.setSelectedCountry(selected))
                    }
                    dataCyId="mainaddacar-form-country-input-desktop"
                    placeholder="Country"
                    customMaxWidth="100%"
                />
            ) : (
                <PickerBtn
                    onClick={() => {
                        !!countryPickerUrl && history.push(countryPickerUrl)
                    }}
                    text={selectedCountry ?? userCountry ?? 'United Kingdom'}
                    label="Country"
                    fwArrow
                    height="48px"
                />
            )}
            <PaddingUnit $times={3} />
            <div
                style={{
                    width: '100%',
                }}
            >
                <InputFieldNoFormikAnimated
                    id="registration_number"
                    name="registration number"
                    placeholder=""
                    tabindex={1}
                    aria-live="polite"
                    onChange={(e: any) => {
                        setRegistrationNumber(e.target.value)
                    }}
                    value={registrationNumber}
                    customHeight={'48px'}
                    disabled={hasNoRegNo === true ? true : false}
                    errorText={
                        registrationNumber
                            ? checkIfOnlyCharsAndNumbersAndSpaces(
                                  registrationNumber
                              )
                                ? undefined
                                : 'Special characters not allowed'
                            : undefined
                    }
                />

                <PaddingUnit $times={3} />

                {removeToggle ? null : (
                    <Row>
                        <SwitchButton
                            checked={hasNoRegNo === true ? true : false}
                            toggle={() => {
                                if (!hasNoRegNo) {
                                    setRegistrationNumber('')
                                }
                                toggleHasNoRegNo(!hasNoRegNo)
                            }}
                            width={48}
                            circleSize={16}
                            height={24}
                            variant="outline"
                        />

                        <ToggleText
                            isActive={hasNoRegNo === true ? true : false}
                        >
                            My car doesn’t have registration number
                        </ToggleText>
                    </Row>
                )}
            </div>
        </FormWrapper>
    )
}

export default AddACarFormOnboardingMobile
