import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// import IpadAndMobileDisplay from "../displays/ipadAndMobileDisplay";
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import Faded from '../../animated/faded'
// import ScrollLock from 'react-scrolllock'
// import TouchScrollable from 'react-scrolllock'
import IpadAndMobileDisplay from '../ipadAndMobileDisplay'
import { device } from '../devices'

function mapStateToProps(state: RootState) {
    return {
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

interface Props {
    children: React.ReactNode
    isCollapsed: boolean
    isOpen: boolean
    toggle: any
    customZindex?: number
    noMenuAdjust?: boolean
}

interface Istyle {
    isCollapsed?: boolean
    isOpen?: boolean
    $customZindex?: number
}

const DesktopPageWrapperStyle = styled.div<Istyle>`
    z-index: ${(props) => (props.$customZindex ? props.$customZindex : 3)};
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.85);
    height: 100%;
    width: 100%;
    top: 0px;
    bottom: 0;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${device.desktop} {
        left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
    @media ${device.large_desktop} {
        left: ${(props) => (props.isCollapsed ? '71px' : '271px')};
    }

    @media ${device.smallest_laptops} {
        left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
`

const MenuAdjust = styled.div<Istyle>`
    height: 100%;
    width: 100%;

    transition: all 100ms;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media ${device.desktop} {
        margin-left: ${(props) => (props.isCollapsed ? '-45px' : '-205px')};
    }
    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
        margin-left: ${(props) => (props.isCollapsed ? '-45px' : '-205px')};
    }
`

// const LeftClickBlock = styled.div`
//     position: absolute;
//     top: 0;
//     left: 0px;
//     right: 0px;
//     bottom: 0px;
//     background-color: none;
//     height: 100vh;
// `

const MobilePageWrapperStyle = styled.div<Istyle>`
    z-index: ${(props) => (props.$customZindex ? props.$customZindex : 5)};
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

// class ModalDisplay extends React.Component<Props> {

class ModalDisplay extends React.PureComponent<Props> {
    handleTouchMove = (e: any) => {
        let targetElement: HTMLElement | null =
            document.querySelector('#scrollable-content') &&
            document.querySelector('#scrollable-content')

        let scrollableContent: HTMLElement[] = []

        targetElement &&
            document
                .querySelectorAll('#scrollable-content *')
                .forEach((node: any) => scrollableContent.push(node))

        const targetIsElement =
            targetElement !== null && e.target.id === targetElement.id
        const modalContainsTarget = scrollableContent.includes(e.target)

        const shouldPreventDefault = !(targetIsElement || modalContainsTarget)

        if (shouldPreventDefault) e.preventDefault()
    }

    componentDidMount() {
        if (this.props.isOpen) {
            document.body.style.overflow = 'hidden'
            if (window.matchMedia('(display-mode: standalone)').matches) {
                // document.body.ontouchmove = (e) => e.preventDefault()
                window.addEventListener('touchmove', this.handleTouchMove, {
                    passive: false,
                })
            }
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'auto'
        if (window.matchMedia('(display-mode: standalone)').matches) {
            // document.body.ontouchmove = (e) => true
            window.removeEventListener('touchmove', this.handleTouchMove)
        }
    }

    render() {
        let { children, isOpen, isCollapsed, customZindex } = this.props

        return (
            <React.Fragment>
                <IpadAndMobileDisplay>
                    <Faded>
                        <MobilePageWrapperStyle
                            isOpen={isOpen}
                            $customZindex={customZindex}
                        >
                            {/* <LeftClickBlock
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    setTimeout(() => {
                                        this.props.toggle()
                                    }, 50)
                                }}
                            /> */}
                            {children}
                        </MobilePageWrapperStyle>
                    </Faded>
                </IpadAndMobileDisplay>
                <DesktopDisplayOnly>
                    <Faded>
                        <DesktopPageWrapperStyle
                            style={this.props.noMenuAdjust ? { left: 0 } : {}}
                            isCollapsed={isCollapsed}
                            isOpen={isOpen}
                            $customZindex={customZindex}
                        >
                            {/* <LeftClickBlock
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    setTimeout(() => {
                                        this.props.toggle()
                                    }, 50)
                                }}
                            /> */}
                            <MenuAdjust
                                style={
                                    this.props.noMenuAdjust
                                        ? { marginLeft: 0 }
                                        : {}
                                }
                                isCollapsed={isCollapsed}
                            >
                                {children}
                            </MenuAdjust>
                        </DesktopPageWrapperStyle>
                    </Faded>

                    {/* {isOpen && <ScrollLock />} */}
                </DesktopDisplayOnly>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, {})(ModalDisplay)
