import styled from 'styled-components'
import React from 'react'

import FadedSlower from '../../templates/animated/FadedSlower'
import BorderAndIconButton from '../Button/borderAndIconButton'
import ButtonAtom from '../Button/ButtonAtom'
import { device } from '../../templates/displays/devices'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyledProps = {
    $isActive?: boolean
    $isInsideWindowTemplate?: boolean
    $isCollapsed?: boolean
}

const Wrapper = styled.div<IStyledProps>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 80px;
    transition: all 200ms;
    background-color: var(--bg-color, #fff);
    ${(props) => props.$isActive && 'padding-right: 20px;'};
    @media screen and (min-width: 881px) and (max-width: 1200px) {
        margin-bottom: ${(props) =>
            props.$isInsideWindowTemplate ? '-20px' : '-70px'};
    }
    @media ${device.ipad} {
        width: ${(props) => (props.$isCollapsed ? '100%' : '80%')};
        height: 60px;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
`

const SmallPadding = styled.div`
    padding-right: 20px;
`

interface Props {
    onSave?: any
    onCancel?: any
    onDelete?: any
    headerText?: string
    isActive?: boolean
    isSaveDisabled?: boolean
    isInsideWindowTemplate?: boolean
    dataCyIdSave?: string
    dataCyIdCancel?: string
    dataCyIdDelete?: string
    isCollapsed?: boolean
    deletionInProgress?: boolean
}

const EditModeFooterDesktop: React.FunctionComponent<Props> = (props) => {
    const { theme } = useThemes()
    return (
        <Wrapper
            $isInsideWindowTemplate={props.isInsideWindowTemplate}
            $isActive={props.isActive}
            $isCollapsed={props.isCollapsed}
        >
            <ButtonsWrapper>
                {props.onDelete && (
                    <>
                        <BorderAndIconButton
                            variant="red"
                            text="Delete"
                            icon="thick_red_bin"
                            iconwidth="15px"
                            iconHeight="auto"
                            onClick={props.onDelete}
                            buttonwidth="130px"
                            buttonheight="40px"
                            dataCyId={props.dataCyIdDelete}
                            fontsize="15px"
                            isBtnLoading={props.deletionInProgress}
                            loadingColour={colours[theme].error_50}
                        />
                        {(props.onSave || props.onCancel) && (
                            <>
                                <SmallPadding />
                                <SmallPadding />
                            </>
                        )}
                    </>
                )}
                {props.onCancel && (
                    <>
                        <BorderAndIconButton
                            variant="darkGrey"
                            text={props.isActive ? 'Cancel' : 'Return'}
                            iconwidth="0px"
                            onClick={props.onCancel}
                            dataCyId={props.dataCyIdCancel}
                            buttonheight="40px"
                            buttonwidth="130px"
                            fontsize="15px"
                        />
                        <SmallPadding />
                    </>
                )}
                {props.isActive && props.onSave && (
                    <FadedSlower>
                        <ButtonAtom
                            theme="wide-mobile-blue-bg"
                            onClick={props.onSave}
                            disabled={props.isSaveDisabled}
                            dataCyId={props.dataCyIdSave}
                            height="40px"
                            width="130px"
                            fontSize={15}
                        >
                            Save
                        </ButtonAtom>
                    </FadedSlower>
                )}
            </ButtonsWrapper>
        </Wrapper>
    )
}

export default EditModeFooterDesktop
