import * as React from 'react'
import AddImageGrid from '../../atoms/image/addImageGrid'
import { IGalleryImagesObject } from 'entityModels'
import SquareDuoItemMobile from '../../atoms/image/squareDuoItemMobile'
import styled from 'styled-components'

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
    width: 100%;
    height: 100%;
`

export interface Props {
    images_data_object: IGalleryImagesObject
    ids_list: string[]
    onAddClick?: any
    readOnlyMode?: boolean
}

type State = {
    src: any
}

class SquareDuoMobile extends React.Component<Props, State> {
    state = {
        src: null,
    }

    componentDidMount() {}

    render() {
        const { images_data_object, ids_list, onAddClick, readOnlyMode } =
            this.props

        let ids_list_displayed: string[] =
            ids_list && ids_list.length > 0 ? [...ids_list] : []

        return (
            <Wrapper>
                {ids_list_displayed.map((id: string, index: number) => {
                    if (images_data_object[id]) {
                        return (
                            <div key={index}>
                                <SquareDuoItemMobile
                                    image_obj={images_data_object[id]}
                                />
                            </div>
                        )
                    } else {
                        return <div key={index} style={{ display: 'none' }} />
                    }
                })}

                {!readOnlyMode && (
                    <AddImageGrid
                        onClick={onAddClick && onAddClick}
                        ipadHeight="22vh"
                    />
                )}
            </Wrapper>
        )
    }
}

export default SquareDuoMobile
