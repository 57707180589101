export const convertToShorterString = (params: {
    string: string
    maxChars: number
    isFileName?: boolean
}) => {
    if (params.isFileName) {
        if (params.string.length > params.maxChars) {
            return `${params.string.slice(
                0,
                params.maxChars
            )}...${params.string.slice(
                params.string.length - 5,
                params.string.length
            )}`
        } else {
            return params.string
        }
    } else {
        if (params.string.length > params.maxChars) {
            return `${params.string.slice(0, params.maxChars)}...`
        } else {
            return params.string
        }
    }
}
