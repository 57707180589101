import useThemes from '../../../../providers/theme/hooks'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../providers/theme/colours'
import image from '../../../../public/assets/images/journey/pastCars/welcome.png'
import image_m from '../../../../public/assets/images/journey/pastCars/mobile_welcome.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeSubWrapperV2,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapperV2,
    JourneyWelcomeButtonsWrapper,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import FadedSlower from '../../../templates/animated/FadedSlower'

// const CustomJourneyWelcomeButtonsWrapper = styled(JourneyWelcomeButtonsWrapper)`
//     position: absolute;
//     bottom: 10px;
// `
type Props = {
    start: () => void
    cancel: (carid: string) => void
}

const JourneyWelcomeImageCustom = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;
    height: 40dvh;

    @media ${device.mobile} {
        height: auto;
        width: 700px;
    }
    @media (max-height: 880px) {
        max-height: 50dvh;
    }

    @media ${device.ipad} {
        height: 44dvh;
    }
`

const CustomImageMobile = styled.img`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* flex-shrink: 0; */
    object-fit: contain;
    height: 45dvh;

    @media (max-width: 400px) {
        max-height: 40dvh;
    }
`

const JourneyWelcomeImageWrapperCustom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media ${device.mobile} {
        justify-content: flex-start;
        align-items: flex-start;
        width: 100dvw;
        overflow: hidden;
    }
`

const Padding1 = styled.div`
    padding-top: 24px;

    @media (max-width: 400px) {
        padding-top: 10px;
    }
`

const Padding2 = styled.div`
    padding-top: 36px;

    @media (max-width: 400px) {
        padding-top: 24px;
    }
`

export default function JourneyPastCarsWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()

    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapperV2>
                        <JourneyWelcomeSubWrapperV2>
                            <JourneyWelcomeTextWrapper>
                                <JourneyWelcomeTitle>
                                    Never Forget!
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    We understand the emotional attachment to
                                    cars both current and previously owned.
                                    Whether its the car you drove after your
                                    wedding, the one your children grew up in or
                                    perhaps that one you finally owned after it
                                    was a poster on your wall for all those
                                    years. Never forget and relive past moments
                                    with previously owned cars.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>

                            <div
                                style={{
                                    paddingTop: '36px',
                                }}
                            />

                            <JourneyWelcomeImageWrapperCustom>
                                <FadedSlower isFullWidth={true}>
                                    <JourneyWelcomeImageCustom
                                        src={image}
                                        placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapperCustom>

                            <div
                                style={{
                                    paddingTop: '30px',
                                }}
                            />

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn
                                    onClick={start}
                                    bg={
                                        journey_colours[theme].section.past_cars
                                            .primary_600
                                    }
                                >
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: journey_colours[theme]
                                                .section.past_cars.primary_600,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapperV2>
                    </JourneyWelcomeWrapperV2>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapperV2>
                    <JourneyWelcomeSubWrapperV2 style={{ paddingTop: '0px' }}>
                        <Padding1 />

                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '26px',
                                maxWidth: '90vw',
                            }}
                        >
                            Never{' '}
                            <span
                                style={{
                                    color: journey_colours[theme].section
                                        .past_cars.primary_500,
                                    fontSize: '28px',
                                }}
                            >
                                Forget!
                            </span>
                        </JourneyWelcomeTitle>

                        <Padding2 />

                        <JourneyWelcomeImageWrapperCustom>
                            <Faded width={'auto'}>
                                <CustomImageMobile
                                    src={image_m}
                                    placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapperCustom>

                        <div
                            style={{
                                paddingTop: '2dvh',
                            }}
                        />

                        <JourneyWelcomeText>
                            We understand the emotional attachment to cars both
                            current and previously owned. Whether its the car
                            you drove after your wedding, the one your children
                            grew up in or perhaps that one you finally owned
                            after it was a poster on your wall for all those
                            years. Never forget and relive past moments with
                            previously owned cars.
                        </JourneyWelcomeText>

                        <div
                            style={{
                                paddingTop: '24px',
                            }}
                        />

                        <StandardCtaBtn
                            onClick={start}
                            customWidth="90vw"
                            bg={
                                journey_colours[theme].section.past_cars
                                    .primary_600
                            }
                        >
                            Let's get started
                        </StandardCtaBtn>

                        <div
                            style={{
                                paddingTop: '36px',
                            }}
                        />
                    </JourneyWelcomeSubWrapperV2>
                </JourneyWelcomeWrapperV2>
            </IpadAndMobileDisplay>
        </>
    )
}
