import { IApexPodscastState } from 'apexModels'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { getApexPodcastsDataThunks } from '../../../redux/theapex/thunks'
import ApexPageHeaderDesktop from '../../atoms/header/apex/apexPageHeaderDesktop'
import ApexPageHeaderMobile from '../../atoms/header/apex/apexPageHeaderMobile'
import LoaderBackground from '../../atoms/loader/loaderBackground'
import PodcastItemDesktop from '../../molecules/apex/podcasts/podcastItemDesktop'
import PodcastItemMobile from '../../molecules/apex/podcasts/podcastItemMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ApexPageWrapper from '../../templates/displays/pageWrappers/apexPageWrapper'
import { IApexLocalCopyItem } from 'apexLocalCopyModels'
import { checkForIpad } from './overview'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'

interface Props {
  apexPodcastsData: IApexPodscastState[] | undefined
  getApexPodcastsDataThunks: any
  podcastsPageLocalCopy: IApexLocalCopyItem
}

function mapStateToProps(state: RootState) {
  return {
    // apexPodcastsData from types.d.ts
    apexPodcastsData: state.apex.apexPodcastsData,
    podcastsPageLocalCopy:
      state.localdata.apex_local_copy.separatePage.podcasts,
  }
}

const PodcastItemsDesktopWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2vw;
    row-gap: 2vw;

    @media ${device.large_desktop} {
        column-gap: 100px;
    }
`
const PaddingTopDesktop = styled.div`
    padding-top: 50px;

    @media ${device.large_desktop} {
        padding-top: 0px;
        transform: translateY(-30px);
    }
`
const MobilePadding = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: ${checkForIpad() ? '65px' : '135px'};
        padding-bottom: 110px;
    }
`

const dispatchProps = {
  getApexPodcastsDataThunks: () => getApexPodcastsDataThunks(),
}

class ApexPodcasts extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.getApexPodcastsDataThunks()
  }

  render() {
    let { apexPodcastsData, podcastsPageLocalCopy } = this.props

    return (
      <>
        <FabsMenusMobile hasAdd={false} hasSubNav={true} />
        <ApexPageWrapper>
          <DesktopDisplayOnly>
            <PaddingTopDesktop />
            <ApexPageHeaderDesktop
              title={podcastsPageLocalCopy.title}
              text={podcastsPageLocalCopy.subTitle}
            />
            <div style={{ paddingTop: '80px' }} />

            {!apexPodcastsData && (
              <>
                <LoaderBackground
                  height={'600px'}
                  width={'100%'}
                />
                <LoaderBackground
                  height={'600px'}
                  width={'100%'}
                />
              </>
            )}

            {apexPodcastsData !== undefined && (
              <PodcastItemsDesktopWrapper>
                {apexPodcastsData.map(
                  (
                    podcast: IApexPodscastState,
                    index: number
                  ) => {
                    return (
                      <PodcastItemDesktop
                        index={index}
                        podcast={podcast}
                      />
                    )
                  }
                )}
              </PodcastItemsDesktopWrapper>
            )}
          </DesktopDisplayOnly>

          <IpadAndMobileDisplay>
            <MobilePadding>
              <ApexPageHeaderMobile
                title={podcastsPageLocalCopy.title}
                text={podcastsPageLocalCopy.subTitle}
              />

              <div style={{ paddingTop: '50px' }} />

              {!apexPodcastsData && (
                <>
                  <LoaderBackground
                    height={'400px'}
                    width={'100%'}
                  />
                  <LoaderBackground
                    height={'400px'}
                    width={'100%'}
                  />
                  <LoaderBackground
                    height={'400px'}
                    width={'100%'}
                  />
                </>
              )}

              {apexPodcastsData !== undefined && (
                <>
                  {apexPodcastsData.map(
                    (
                      podcast: IApexPodscastState,
                      index: number
                    ) => {
                      return (
                        <PodcastItemMobile
                          index={index}
                          podcast={podcast}
                        />
                      )
                    }
                  )}
                </>
              )}
            </MobilePadding>
          </IpadAndMobileDisplay>
        </ApexPageWrapper>
      </>
    )
  }
}

export default connect(mapStateToProps, dispatchProps)(ApexPodcasts)
