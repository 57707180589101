// import { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

interface Props {
    tabindex?: number
    linecolor?: string
    minheight?: string
    keepLabelRaised?: boolean
}

const Wrapper = styled.div`
    width: 100%;
`

const FormWrapper = styled.div`
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    display: flex;
    border: var(--text-muted) 1px solid;
    border-radius: 4px;
    height: 130px;

    &:focus-within {
        border-color: var(--primary, #5ec3ca);
    }

    & textarea:focus ~ label {
        top: 0%;
        left: 0;
        transform: translate(8px, -50%) scale(0.8);
        color: var(--primary, #5ec3ca);
        min-width: auto;
    }
`

const StyledTextArea = styled.textarea<Props>`
    width: 100%;
    color: var(--text-strong, #1a1a1a);
    border: none;
    font-family: Lato;
    background-color: transparent;
    font-size: 14px;
    resize: none;
    height: 100%;
    border: none;
    border-radius: 4px;
    padding: 15px;
    :focus {
        outline: 0;
    }
    ::placeholder {
        padding-top: ${(props) => (props.keepLabelRaised ? '0px' : '30px')};
        font-family: Lato-light;
        font-size: 14px;
        :focus {
            display: none;
        }
    }

    :focus::-webkit-input-placeholder {
        padding-top: 0px;
        transition: 0.15s;
    }

    @media ${device.mobile} {
        font-size: 16px;
        /* height: 100%; */
    }

    @media ${device.ipad} {
        width: 400px;
        font-size: 16px;
        /* height: 100%; */
    }
`

const HelperText = styled.div`
    color: var(--text-darker, #616161);
    font-size: 12px;
    padding-top: 8px;
    padding-left: 12px;
`

const Label = styled.label<{ raise: boolean; backgroundColor?: string }>`
    position: absolute;
    ${(props) => `
        top: ${props.raise ? '0%' : '20px'};
        transform-origin: top left;
        transform: ${
            props.raise
                ? `translate(8px, -50%) scale(0.8)`
                : `translate(8px, -50%) scale(1)`
        };
        left: 0;
        min-width: ${props.raise ? 'auto' : '200px'};
    `}

    transition: 0.15s;
    background-color: ${(props) =>
        props.backgroundColor ?? 'var(--bg-color, #fff)'};
    color: var(--text-darker, #616161);
    font-size: 1.1em;
    padding: 0 8px;
    pointer-events: none;
    font-family: Lato;

    &:first-letter {
        text-transform: uppercase;
    }
`

interface ITextAreaProps {
    id?: string
    name: string
    value: string
    placeholder?: string
    handleChange: (e: any) => any
    tabindex?: number
    borderBottom?: boolean
    dataCyId?: string
    linecolor?: string
    minheight?: string
    disabled?: boolean
    fieldTitle?: string
    helperText?: string
    keepLabelRaised?: boolean
    maxLength?: number
    backgroundColor?: string
}

function AnimatedTextarea({
    id,
    name,
    value,
    handleChange,
    placeholder,
    tabindex,
    dataCyId,
    linecolor,
    minheight,
    disabled,
    fieldTitle,
    helperText,
    maxLength,
    keepLabelRaised,
    backgroundColor,
}: ITextAreaProps) {
    return (
        <Wrapper>
            <FormWrapper style={{ opacity: disabled ? 0.5 : 1 }}>
                <StyledTextArea
                    className="actual-textarea"
                    id={id}
                    name={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleChange}
                    tabindex={tabindex}
                    // ref={elementRef}
                    data-attr={dataCyId}
                    linecolor={linecolor}
                    minheight={minheight}
                    disabled={disabled}
                    keepLabelRaised={keepLabelRaised}
                    maxLength={maxLength}
                />
                {fieldTitle && (
                    <Label
                        backgroundColor={backgroundColor}
                        raise={!!value || keepLabelRaised ? true : false}
                    >
                        {fieldTitle}
                    </Label>
                )}
            </FormWrapper>
            {helperText && <HelperText>{helperText}</HelperText>}
        </Wrapper>
    )
}

export default AnimatedTextarea
