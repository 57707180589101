import styled from 'styled-components'
import GoBackTopButtonDesktop from '../../../../atoms/Button/goBackTopButtonDesktop'
import useThemes from '../../../../../providers/theme/hooks'
import colours from '../../../../../providers/theme/colours'
import LeftAlignLogo from '../../../../atoms/icons/components/leftAlignLogo'

const CenteredIcon = styled.div`
    position: absolute;
    padding-top: 4px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

type StyleProps = {
    $isMobile?: boolean
}

const Container = styled.div<StyleProps>`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;

    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.$isMobile ? '64px' : '5rem')};
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
`

type Props = {
    isMobile?: boolean
    isSold?: boolean
}
const GoBackTopBar = (props: Props) => {
    const { theme } = useThemes()
    return (
        <>
            <Container $isMobile={props.isMobile}>
                <GoBackTopButtonDesktop isSold={props.isSold} />

                <CenteredIcon>
                    <LeftAlignLogo
                        size={30}
                        colour={colours[theme].text_strong}
                    />
                    {/* <PccLogo
                        src={pcc_logo_topbar}
                        style={{ marginRight: '5px' }}
                    /> */}
                    {/* <PccLogo src={pcc_logo_text_only_topbar} /> */}
                </CenteredIcon>
            </Container>
        </>
    )
}

export default GoBackTopBar
