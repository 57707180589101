import * as React from 'react'
import styled from 'styled-components'
import install_android from '../../../public/assets/icons/install_android.svg'
import close_cross_white from '../../../public/assets/icons/close_cross_white.svg'
import { useAddToHomeScreenPrompt } from './useAddToHomeScreenPrompt'
import useIsIosPwaCheck from './isIosPwaCheck'
import posthog from 'posthog-js'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { menuActions } from '../../../redux/menus/reducer'
import { device } from '../displays/devices'

const Wrapper = styled.div`
    display: none;

    @media ${device.mobile_and_ipad} {
        cursor: pointer;
        position: fixed;
        bottom: 10px;
        right: 10px;
        left: 10px;
        height: 50px;
        background-color: rgb(0, 152, 157, 0.9);
        border-radius: 5px;
        z-index: 100;
        padding-left: 38px;
        padding-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`

const InstallZone = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 76vw;
`

const CloseZone = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 24vw;
`

const Text = styled.div`
    color: white;
    font-family: Lato;
    font-size: 16px;
    padding-left: 11px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
`

const CloseIcon = styled.img`
    height: 10px;
    width: 10px;
`
const Line = styled.div`
    height: 30px;
    width: 1px;
    background-color: #ffffff52;
`

interface Props extends RouteComponentProps<any> {
    [key: string]: any
}

const InstallAndroidPropmt = withRouter((props: Props) => {
    const [prompt, promptToInstall] = useAddToHomeScreenPrompt()
    const [isVisible, setVisibleState] = React.useState(false)

    const dispatch: any = useDispatch()

    const isApple: any = useIsIosPwaCheck()

    const hide = () => setVisibleState(false)

    React.useEffect(() => {
        if (prompt) {
            dispatch(menuActions.toggleisNativeAndroidPWABannerActive(true))
            setVisibleState(true)
        }

        if (
            !!window &&
            window.matchMedia('(display-mode: standalone)').matches
        ) {
            setVisibleState(false)
            posthog.register({
                pwa: true,
            })
            posthog.people.set({ hasIntalledPWA: true })
        } else {
            posthog.register({
                pwa: false,
            })
        }

        return () => {
            dispatch(menuActions.toggleisNativeAndroidPWABannerActive(false))
        }
    }, [prompt, dispatch])

    const params = props.location.search

    return (
        <>
            {!isApple.isIOS &&
                !isApple.isSafari &&
                isVisible &&
                !params.match(/tutorial/g) &&
                !params.match(/onboarding/g) && (
                    <Wrapper>
                        <InstallZone
                            onClick={() => {
                                promptToInstall()
                                setVisibleState(false)
                                posthog.capture('INSTALL PWA ON ANDROID')
                            }}
                        />
                        <Row>
                            <img
                                style={{ height: '21px', width: '21px' }}
                                src={install_android}
                                alt="install"
                            />
                            <Text>Install App</Text>
                        </Row>
                        <CloseZone
                            onClick={() => {
                                hide()
                                dispatch(
                                    menuActions.toggleisNativeAndroidPWABannerActive(
                                        false
                                    )
                                )
                            }}
                        />

                        <Row>
                            <Line />

                            <div style={{ paddingLeft: '20px' }} />

                            <CloseIcon src={close_cross_white} alt="close" />
                        </Row>
                    </Wrapper>
                )}
            <div />
        </>
    )
})

export default InstallAndroidPropmt
