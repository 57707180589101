import {
    GalleryOptionsHandoverArr,
    props_handoverForm_customData,
    TimelineEntryHandoverTypesData,
    total_hf_enum_n,
} from '../../../redux/localdata/carHandoverCopy'
import SelectionRadioSectionWithoutToggle from '../../molecules/formFieldsGroup/selectionRadioSectionWithoutToggle'
import SelectionSectionWithToggle from '../../molecules/formFieldsGroup/selectionSectionWithToggle'

const CarHandoverCustomDataForm = (props: props_handoverForm_customData) => {
    let {
        handoverFormData,
        handleSelectedIDHistoryFile,
        handleSelectedGalleryValue,
    } = props

    let isAllHfSelected =
        handoverFormData.handover.custom.history_file.reduce(
            (a, b) => a + b,
            0
        ) === total_hf_enum_n
            ? true
            : false

    return (
        <>
            <div style={{ paddingTop: '24px' }} />
            <SelectionSectionWithToggle
                title="History Files"
                isOn={isAllHfSelected}
                toggleSwitch={() => {
                    !isAllHfSelected
                        ? handleSelectedIDHistoryFile(total_hf_enum_n)
                        : handleSelectedIDHistoryFile(0)
                }}
                selectedIDs={handoverFormData.handover.custom.history_file}
                option_data={TimelineEntryHandoverTypesData}
                onOptionClick={(id: string) => {
                    handleSelectedIDHistoryFile(+id)
                }}
                isAllSelected={isAllHfSelected}
            />

            <div style={{ paddingTop: '24px' }} />
            <SelectionRadioSectionWithoutToggle
                title="Gallery"
                isOn={
                    handoverFormData.handover.custom.gallery !== 0
                        ? true
                        : false
                }
                selectedID={handoverFormData.handover.custom.gallery}
                option_data={GalleryOptionsHandoverArr}
                onOptionClick={(id: string) => {
                    handleSelectedGalleryValue(+id)
                }}
            />
        </>
    )
}

export default CarHandoverCustomDataForm
