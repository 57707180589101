import {
    GET_HIGHLIGHTED_FACTS_ERROR,
    EDIT_HIGHLIGHTED_FACTS_ERROR,
    SET_HIGHLIGHTED_FACTS_ERROR,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const getHighlightedFactsError = createAction(
//     GET_HIGHLIGHTED_FACTS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const getHighlightedFactsError = createAction(
    GET_HIGHLIGHTED_FACTS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const updatetHighlightedFactsError = createAction(
//     EDIT_HIGHLIGHTED_FACTS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const updatetHighlightedFactsError = createAction(
    EDIT_HIGHLIGHTED_FACTS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const setHighlightedFactsError = createAction(
//     SET_HIGHLIGHTED_FACTS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const setHighlightedFactsError = createAction(
    SET_HIGHLIGHTED_FACTS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
