export const genPathname = (item: any, currentCarID?: string) => {
    if (currentCarID) {
        if (item.url === '/car/gallery') {
            return `/car/${currentCarID}/gallery`
        }

        if (item.url === '/car/technical-information') {
            return `/car/${currentCarID}/technical-information`
        }

        if (item.url === '/car/tasks') {
            return `/car/${currentCarID}/tasks`
        }

        if (item.url === '/car/history-file') {
            return `/car/${currentCarID}/history-file`
        }

        if (item.url === '/car/sharing') {
            return `/car/${currentCarID}/sharing`
        }

        if (item.url === '/car') {
            return `/car/${currentCarID}`
        }

        if (item.url === '/shared/car') {
            return `/shared/car/${currentCarID}`
        }

        if (item.url === '/shared/car/gallery') {
            return `/shared/car/${currentCarID}/gallery`
        }

        if (item.url === '/shared/car/technical-information') {
            return `/shared/car/${currentCarID}/technical-information`
        }

        if (item.url === '/shared/car/tasks') {
            return `/shared/car/${currentCarID}/tasks`
        }

        if (item.url === '/shared/car/history-file') {
            return `/shared/car/${currentCarID}/history-file`
        }

        if (item.url === '/archives/car/overview') {
            return `/archives/car/${currentCarID}/overview`
        }

        if (item.url === '/archives/car/gallery') {
            return `/archives/car/${currentCarID}/gallery`
        }

        if (item.url === '/archives/car/technical-information') {
            return `/archives/car/${currentCarID}/technical-information`
        }

        if (item.url === '/archives/car/history-file') {
            return `/archives/car/${currentCarID}/history-file`
        }

        if (item.url === '/car/insights') {
            return `/car/${currentCarID}/insights`
        }
    }

    return item.url
}
export const checkIfActivePage = (
    activepage: string,
    item_url: string,
    currentCarID?: string
) => {
    if (item_url === '/account' && activepage === `/account`) {
        return true
    }

    if (
        item_url === '/archives/car/overview' &&
        activepage === `/archives/car/${currentCarID}/overview`
    ) {
        return true
    }
    if (
        item_url === '/archives/car/technical-information' &&
        activepage === `/archives/car/${currentCarID}/technical-information`
    ) {
        return true
    }

    if (
        item_url === '/archives/car/gallery' &&
        activepage === `/archives/car/${currentCarID}/gallery`
    ) {
        return true
    }
    if (
        item_url === '/archives/car/history-file' &&
        (activepage === `/archives/car/${currentCarID}/history-file` ||
            activepage === `/archives/car/${currentCarID}/history-file/`)
    ) {
        return true
    }

    if (
        item_url === '/shared/car' &&
        activepage === `/shared/car/${currentCarID}`
    ) {
        return true
    }
    if (
        item_url === '/shared/car/technical-information' &&
        activepage === `/shared/car/${currentCarID}/technical-information`
    ) {
        return true
    }

    if (
        item_url === '/shared/car/gallery' &&
        activepage === `/shared/car/${currentCarID}/gallery`
    ) {
        return true
    }
    if (
        item_url === '/shared/car/history-file' &&
        (activepage === `/shared/car/${currentCarID}/history-file` ||
            activepage === `/shared/car/${currentCarID}/history-file/`)
    ) {
        return true
    }

    if (
        item_url === '/shared/car' &&
        activepage === `shared/car/${currentCarID}`
    ) {
        return true
    }

    if (
        item_url === '/car/gallery' &&
        (activepage === `/car/${currentCarID}/gallery` ||
            activepage === `/car/${currentCarID}/gallery/`)
    ) {
        return true
    }

    if (
        item_url === '/car/technical-information' &&
        activepage === `/car/${currentCarID}/technical-information`
    ) {
        return true
    }

    if (
        item_url === '/car/insights' &&
        activepage === `/car/${currentCarID}/insights`
    ) {
        return true
    }

    if (
        item_url === '/car/tasks' &&
        activepage.match(/car/g) !== null &&
        activepage.match(/tasks/g) !== null
    ) {
        return true
    }

    if (
        item_url === '/car/history-file' &&
        activepage.match(/history-file/g) !== null
    ) {
        return true
    }
    if (
        item_url === '/car/sharing' &&
        activepage.match(/car/g) !== null &&
        activepage.match(/sharing/g) !== null
    ) {
        return true
    }
    if (item_url === '/car' && activepage === `/car/${currentCarID}`) {
        return true
    }

    if (item_url === '/garage' && activepage === `/garage`) {
        return true
    }

    if (item_url === '/garage' && activepage === `/`) {
        return true
    }
    if (item_url === '/history-files' && activepage === `/history-files`) {
        return true
    }

    if (
        item_url === '/sharing/with-others' &&
        activepage.match(/sharing/g) !== null &&
        activepage.match(/with-others/g) !== null
    ) {
        return true
    }

    if (item_url === activepage) {
        return true
    } else return false
}
