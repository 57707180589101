import { ISearchShowroom_API_req_args } from '../../services/showroom/types'

export type IConvertSearchAPIReqToURL_arg = {
    data: ISearchShowroom_API_req_args
    offset: number
    limit: number
}
export const ConvertSearchAPIReqToURL = (
    p: IConvertSearchAPIReqToURL_arg
): string => {
    let url = `?offset=${p.offset}&limit=${p.limit}&currency=GBP`

    if (p.data.states) {
        url = url + `&states=${p.data.states}`
    }

    if (p.data.sorting) {
        url = url + `&sorting=${p.data.sorting}`
    }

    let direct_values_ids: (
        | 'makes'
        | 'models'
        | 'drive_sides'
        | 'body_types'
        | 'exterior_colors'
        | 'types_of_sale'
        | 'keywords'
    )[] = [
        'makes',
        'models',
        'drive_sides',
        'body_types',
        'exterior_colors',
        'types_of_sale',
        'keywords',
    ]

    let range_values_ids: ('year' | 'price' | 'mileage')[] = [
        'year',
        'price',
        'mileage',
    ]

    let location_values_ids: ('radius' | 'lat' | 'lng' | 'unit')[] = [
        'radius',
        'lat',
        'lng',
        'unit',
    ]

    direct_values_ids.forEach((field) => {
        if (p.data[field]) {
            p.data[field]?.forEach((val: string) => {
                // legacy
                if (val === 'poa') {
                    val = 'PRICE_ON_ASKING'
                }
                if (val === 'RHD') {
                    val = 'Right'
                }

                if (val === 'LHD') {
                    val = 'Left'
                }
                url = url + `&${field}=${val}`
            })
        }
    })

    range_values_ids.forEach((field) => {
        if (p.data[field]) {
            let min = p.data[field]!.min
            let max = p.data[field]!.max
            if (p.data[field] && max && !min) {
                min = 1
            }

            // commenting out setting max for later

            // if (p.data[field] && !max && min) {
            //     if (field === 'year') {
            //         max = new Date().getFullYear()
            //     } else if (field === 'mileage') {
            //         max = 3000000
            //     } else if (field === 'price') {
            //         max = 100000000
            //     }
            // }

            if (p.data[field] && min && max) {
                if (field === 'price') {
                    url =
                        url +
                        `&${field}.min=${
                            // @ts-ignore
                            +min * 100
                        }&${field}.max=${
                            // @ts-ignore
                            (+max + 1) * 100
                        }`
                } else url = url + `&${field}.min=${min}&${field}.max=${max}`
            }
        }
    })

    location_values_ids.forEach((id) => {
        if (p.data.location && p.data.location[id]) {
            url = url + `&location.${id}=${p.data.location[id]}`
        }
    })

    if (p.data.owner) {
        if (p.data.owner && p.data.owner.ids && p.data.owner.ids[0]) {
            url = url + `&owner.ids=${p.data.owner.ids[0]}`
        }

        if (!p.data.owner.ids) {
            if (
                p.data.owner &&
                p.data.owner.display_names &&
                p.data.owner.display_names[0]
            ) {
                url =
                    url +
                    `&owner.display_names=${p.data.owner.display_names[0]}`
            }
        }
    }

    url = encodeURI(url)

    return url
}
