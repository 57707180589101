import styled from 'styled-components'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { RangeFieldsValidationSchema } from './validationSchema'
import Expander from '../../../atoms/expander/expander'
import { ErrorText } from '../../../organisms/showroomEntryManager/showroomEntryBasicInfoManagerDesktop'
import { IFilterID } from '../../../../redux/showroom/types'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { useEffect, useState } from 'react'
import { WarningTxt } from './rangeFieldMobile'

type Props = {
    filterid: IFilterID
    valueFrom?: number | null
    valueTo?: number | null
    customWidth?: string
    onChange: (
        valueFrom: number | null | undefined,
        valueTo: number | null | undefined
    ) => any
    rangeMin?: number
    rangeMax?: number
    removeHelperText?: boolean
    backgroundColor?: string
}
const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`
const RangeRowFieldsDesktop = (props: Props) => {
    let {
        onChange,
        valueFrom,
        valueTo,
        customWidth,
        rangeMin,
        rangeMax,
        filterid,
        backgroundColor,
    } = props

    const dispatch = useAppDispatch()
    const filterValidationErrorObject = useAppSelector(
        (state) => state.showroom.filterValidationError
    )
    let error =
        filterValidationErrorObject &&
        filterid &&
        filterValidationErrorObject[filterid] &&
        filterValidationErrorObject[filterid]?.error

    const [warning, setWarning] = useState<undefined | string>(undefined)

    const validateRangeFields = async (
        min: number | null | undefined,
        max: number | null | undefined
    ) => {
        if (warning) {
            setWarning(undefined)
        }
        if (min && rangeMin && min < Math.floor(rangeMin / 1000) * 1000) {
            setWarning(
                `Warning: the minimum value currently found on the platform is ${
                    filterid === 'year'
                        ? rangeMin
                        : new Intl.NumberFormat('en-GB', {
                              style:
                                  filterid === 'price' ? 'currency' : undefined,
                              currency:
                                  filterid === 'price' ? 'GBP' : undefined,
                              maximumFractionDigits: 0,
                          }).format(Math.floor(rangeMin / 1000) * 1000)
                }`
            )
        }

        if (max && rangeMax && max > Math.ceil(rangeMax / 1000) * 1000) {
            setWarning(
                `Warning: the maximum value currently found on the platform is ${
                    filterid === 'year'
                        ? rangeMax
                        : new Intl.NumberFormat('en-GB', {
                              style:
                                  filterid === 'price' ? 'currency' : undefined,
                              currency:
                                  filterid === 'price' ? 'GBP' : undefined,
                              maximumFractionDigits: 0,
                          }).format(Math.ceil(rangeMax / 1000) * 1000)
                }`
            )
        }

        try {
            await RangeFieldsValidationSchema.validate({
                min: min,
                max: max,
            })
            dispatch(
                showroomActions.setFilterValidationError({
                    id: filterid,
                    error: undefined,
                })
            )
        } catch (err: any) {
            dispatch(
                showroomActions.setFilterValidationError({
                    id: filterid,
                    error: err.errors[0],
                })
            )
        }
    }

    useEffect(() => {
        validateRangeFields(valueFrom, valueTo)
    }, [valueFrom, valueTo, rangeMax, rangeMin])

    useEffect(() => {
        // we need to cleanup validation errors on unmount
        // because on desktop these are single fields forms
        // and values are reseted on click outside

        return () => {
            dispatch(
                showroomActions.setFilterValidationError({
                    id: filterid,
                    error: undefined,
                })
            )
        }
    }, [])

    let helperText =
        filterid === 'price'
            ? 'GBP'
            : filterid === 'mileage'
            ? 'miles'
            : undefined

    return (
        <div>
            <Row>
                <InputFieldNoFormikAnimated
                    name={'From'}
                    placeholder={
                        filterid === 'year' && rangeMin
                            ? `${rangeMin}`
                            : filterid !== 'price' &&
                              filterid !== 'year' &&
                              rangeMin
                            ? `${new Intl.NumberFormat('en-GB', {
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                              }).format(Math.ceil(rangeMin))}`
                            : rangeMin && filterid === 'price'
                            ? `${new Intl.NumberFormat('en-GB', {
                                  style: 'currency',
                                  currency: 'GBP',
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                              }).format(Math.ceil(rangeMin / 1000) * 1000)}`
                            : 'From'
                    }
                    value={
                        valueFrom ? `${valueFrom}` : ''
                        // !valueFrom && rangeMin
                        //     ? rangeMin
                        //     : valueFrom
                        //     ? valueFrom
                        //     : undefined
                    }
                    id={'valueFrom'}
                    onChange={(e: any) => {
                        onChange(
                            e.target.value !== '' ? +e.target.value : 0,
                            valueTo !== undefined
                                ? valueTo
                                : rangeMax
                                ? Math.ceil(rangeMax / 1000) * 1000
                                : undefined
                        )
                    }}
                    type="number"
                    width={customWidth ?? '120px'}
                    min={
                        rangeMin
                            ? `${Math.floor(rangeMin / 1000) * 1000}`
                            : undefined
                    }
                    helperText={
                        props.removeHelperText === true ? undefined : helperText
                    }
                    placeholderStyle={`font-size: 14px;`}
                    backgroundColor={backgroundColor}
                />

                <InputFieldNoFormikAnimated
                    name={'To'}
                    placeholder={
                        filterid === 'year' && rangeMax
                            ? `${rangeMax}`
                            : filterid !== 'price' &&
                              filterid !== 'year' &&
                              rangeMax
                            ? `${new Intl.NumberFormat('en-GB', {
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                              }).format(Math.ceil(rangeMax))}`
                            : rangeMax && filterid === 'price'
                            ? `${new Intl.NumberFormat('en-GB', {
                                  style: 'currency',
                                  currency: 'GBP',
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                              }).format(Math.ceil(rangeMax / 1000) * 1000)}`
                            : 'To'
                    }
                    value={
                        valueTo ? `${valueTo}` : ''
                        // !valueTo && rangeMax
                        //     ? rangeMax
                        //     : valueTo
                        //     ? valueTo
                        //     : undefined
                    }
                    id={'valueTo'}
                    onChange={(e: any) => {
                        onChange(
                            valueFrom !== undefined
                                ? valueFrom
                                : rangeMin
                                ? Math.floor(rangeMin / 1000) * 1000
                                : undefined,
                            e.target.value !== '' ? +e.target.value : undefined
                        )
                    }}
                    type="number"
                    width={customWidth ?? '120px'}
                    max={
                        rangeMax
                            ? `${Math.ceil(rangeMax / 1000) * 1000}`
                            : undefined
                    }
                    helperText={
                        props.removeHelperText === true ? undefined : helperText
                    }
                    placeholderStyle={`font-size: 14px;`}
                    backgroundColor={backgroundColor}
                />
            </Row>
            <Expander height={error ? 'auto' : 0} width={'100%'}>
                <ErrorText style={{ padding: 10 }}>{error}</ErrorText>
            </Expander>

            <Expander height={warning ? 'auto' : 0} width={'100%'}>
                <WarningTxt
                    style={{
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingBottom: 10,
                        paddingTop: error ? 0 : 10,
                    }}
                >
                    {warning}
                </WarningTxt>
            </Expander>
        </div>
    )
}

export default RangeRowFieldsDesktop
