import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import ModalDisplay from '../displays/pageWrappers/modalDisplay'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
    ICarsState,
    IExternalCarDataRequestPayload,
    IReduxError,
} from 'entityModels'
import { getExternalCarDataByRegistrationNumberRequest } from '../../../redux/entities/cars/actions/loadingActions'
// import { motion } from 'framer-motion'
import CarDoesNotExist from '../../molecules/errors/carDoesNotExist'
import AccessDenied from '../../molecules/errors/accessDenied'
import ErrorBody from '../../molecules/errors/errorBody'
import access_denied from '../../../public/assets/icons/access_denied.svg'
import warning from '../../../public/assets/icons/warning.svg'
import { device } from '../displays/devices'
import { checkIfPwaStandalone } from '../pwaCustom/checkIpadPwa'
import SharedWithYouEntityError from '../../molecules/errors/sharedWithYouEntityError'
import ShowroomError from '../../molecules/errors/showroomError'
import { IUser } from 'myModels'
import { PlatformErrorsState } from '.'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 0;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 1000;
    @media ${device.beyond_ipad_mobile} {
        padding-top: 20vh;
    }
`

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 260px;
    min-width: 230px;
    background-color: var(--off-bg-color, #fafafa);
    border-radius: 5px;
    @media ${device.beyond_ipad_mobile} {
        max-width: 350px;
    }
`

function mapStateToProps(state: RootState) {
    return {
        user: state.user.userLoggedIn,
        userError: state.user.error,
        garageError: state.entities.garagesData.error,
        carError: state.entities.carsData.error,
        imageError: state.entities.galleriesData.error,
        highlightedFactsError: state.entities.highlightedFactsData.error,
        technicalInformationError:
            state.entities.technicalInformationData.error,
        dropDownDataError: state.localdata.dropdownData.error,
        fileStorageError: state.fileStorage.error,
        tasksError: state.tasks.error,
        // editFormsError: state.editForms.error,
        // menuError: state.menus.error,
        timelineError: state.timeline.error,
        apexError: state.apex.error,
        showroomError: state.showroom.error,
        currentcarID: state.entities.carsData.currentCarID,
        carsData: state.entities.carsData,
        insuranceError: state.insuranceQuoteApplication.error,
    }
}

const dispatchProps = {
    getExternalCarDataByRegistrationNumberRequest: (
        payload: IExternalCarDataRequestPayload
    ) => getExternalCarDataByRegistrationNumberRequest(payload),
}

interface Props extends RouteComponentProps<any> {
    user: IUser | null
    userError: IReduxError | null
    garageError: IReduxError | null
    carError: IReduxError | null
    imageError: IReduxError | null
    highlightedFactsError: IReduxError | null
    technicalInformationError: IReduxError | null
    dropDownDataError: IReduxError | null
    fileStorageError: IReduxError | null
    tasksError: IReduxError | null
    timelineError: IReduxError | null
    apexError: IReduxError | null
    showroomError: IReduxError | null
    getExternalCarDataByRegistrationNumberRequest: any
    currentcarID: string | undefined
    carsData: ICarsState
    insuranceError: IReduxError | null
    isOnline: boolean

    platform_errors_state: PlatformErrorsState

    resetErrorState: () => any
    generateRedirect: (error: IReduxError) => string
    handleUserAction: () => any
    generateTextError: (error: IReduxError) => {
        text: string
        detail?: string | undefined
    }
    generateCustomUserActionText: (error: IReduxError) => string
}

export class BackEndError extends React.Component<Props> {
    render() {
        let pathname = checkIfPwaStandalone()
            ? window.location.pathname
            : window?.location.pathname

        let error_code = this.props.platform_errors_state?.error?.status_code

        return (
            <>
                {error_code === 503 ? null : (
                    <div
                        style={{
                            position: 'fixed',
                            width: '100%',
                            zIndex: 1000,
                            top: 0,
                            // left: 0,
                            // right: 0,
                            // bottom: 0,
                        }}
                    >
                        {pathname.match(/timeline/g) === null &&
                            pathname !== '/login' &&
                            pathname !== '/signin' &&
                            pathname !== '/register' &&
                            pathname.match(/reset-password/g) === null && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'relative',
                                    }}
                                >
                                    {this.props.platform_errors_state
                                        ?.showErrorMessage &&
                                        this.props.platform_errors_state
                                            ?.error &&
                                        (this.props.platform_errors_state?.error
                                            .entity_error === 'car' ? (
                                            <ModalDisplay
                                                isOpen={
                                                    this.props
                                                        .platform_errors_state
                                                        ?.showErrorMessage
                                                }
                                                toggle={() => {}}
                                                customZindex={15}
                                            >
                                                {this.props
                                                    .platform_errors_state
                                                    ?.error
                                                    .is_permission_denied ? (
                                                    <AccessDenied
                                                        onClick={() => {
                                                            this.props.resetErrorState()
                                                            this.props.history.push(
                                                                '/garage'
                                                            )
                                                        }}
                                                    />
                                                ) : (
                                                    <CarDoesNotExist
                                                        onClick={() => {
                                                            this.props.resetErrorState()
                                                            this.props.history.push(
                                                                '/garage'
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </ModalDisplay>
                                        ) : this.props.platform_errors_state
                                              ?.error.entity_error ===
                                              'shared_with_you_car' ||
                                          this.props.platform_errors_state
                                              ?.error.entity_error ===
                                              'shared_with_you_garage' ? (
                                            <ModalDisplay
                                                isOpen={
                                                    this.props
                                                        .platform_errors_state
                                                        ?.showErrorMessage
                                                }
                                                toggle={() => {}}
                                                customZindex={15}
                                            >
                                                <SharedWithYouEntityError
                                                    error_is={
                                                        this.props
                                                            .platform_errors_state
                                                            ?.error
                                                            .status_code === 403
                                                            ? 'invalid_entity_access'
                                                            : this.props
                                                                  .platform_errors_state
                                                                  ?.error
                                                                  .status_code ===
                                                              404
                                                            ? 'entity_does_not_exist'
                                                            : undefined
                                                    }
                                                    entity={
                                                        this.props
                                                            .platform_errors_state
                                                            ?.error
                                                            .entity_error ===
                                                        'shared_with_you_car'
                                                            ? 'car'
                                                            : 'garage'
                                                    }
                                                    onClick={() => {
                                                        this.props.resetErrorState()
                                                        this.props.history.push(
                                                            '/garage'
                                                        )
                                                    }}
                                                />
                                            </ModalDisplay>
                                        ) : this.props.platform_errors_state
                                              ?.error.entity_error ===
                                              'showroom' &&
                                          (this.props.platform_errors_state
                                              ?.error.status_code === 403 ||
                                              this.props.platform_errors_state
                                                  ?.error.status_code ===
                                                  404) ? (
                                            <ModalDisplay
                                                isOpen={
                                                    this.props
                                                        .platform_errors_state
                                                        ?.showErrorMessage
                                                }
                                                toggle={() => {}}
                                                customZindex={15}
                                            >
                                                <ShowroomError
                                                    error_is={
                                                        this.props
                                                            .platform_errors_state
                                                            ?.error
                                                            .status_code === 403
                                                            ? 'invalid_access'
                                                            : this.props
                                                                  .platform_errors_state
                                                                  ?.error
                                                                  .status_code ===
                                                              404
                                                            ? 'entry_does_not_exist'
                                                            : undefined
                                                    }
                                                    onClick={() => {
                                                        this.props.resetErrorState()
                                                        this.props.user &&
                                                        this.props.user.id
                                                            ? this.props.history.push(
                                                                  '/garage'
                                                              )
                                                            : this.props.history.push(
                                                                  '/showroom'
                                                              )
                                                    }}
                                                />
                                            </ModalDisplay>
                                        ) : (
                                            <ModalDisplay
                                                noMenuAdjust
                                                isOpen={
                                                    this.props
                                                        .platform_errors_state
                                                        ?.showErrorMessage
                                                }
                                                toggle={() => {}}
                                                customZindex={15}
                                            >
                                                <Wrapper>
                                                    <Container>
                                                        {this.props
                                                            .platform_errors_state
                                                            ?.error
                                                            .status_code ===
                                                            500 &&
                                                        this.props
                                                            .platform_errors_state
                                                            ?.error.custom &&
                                                        this.props
                                                            .platform_errors_state
                                                            ?.error.custom
                                                            .custom_message &&
                                                        this.props.platform_errors_state?.error.custom.custom_message.includes(
                                                            'internet'
                                                        ) ? (
                                                            <ErrorBody
                                                                onClick={() => {
                                                                    this.props.history.push(
                                                                        this.props.generateRedirect(
                                                                            this
                                                                                .props
                                                                                .platform_errors_state
                                                                                .error
                                                                        )
                                                                    )
                                                                    this.props.handleUserAction()
                                                                }}
                                                                title={
                                                                    'Looks like your internet has stoped working.'
                                                                }
                                                                subTitle={
                                                                    'Check your connection and refresh.'
                                                                }
                                                                buttonText={
                                                                    'Refresh page'
                                                                }
                                                                headIcon={
                                                                    access_denied
                                                                }
                                                                isRefresh
                                                                isModal
                                                            />
                                                        ) : (
                                                            <>
                                                                <ErrorBody
                                                                    onClick={
                                                                        this
                                                                            .props
                                                                            .platform_errors_state
                                                                            .error
                                                                            .custom &&
                                                                        this
                                                                            .props
                                                                            .platform_errors_state
                                                                            .error
                                                                            .custom
                                                                            .custom_redirect_path ===
                                                                            'reload_page'
                                                                            ? () =>
                                                                                  this.props.history.go(
                                                                                      0
                                                                                  )
                                                                            : () => {
                                                                                  this.props.history.push(
                                                                                      this.props.generateRedirect(
                                                                                          this
                                                                                              .props
                                                                                              .platform_errors_state
                                                                                              .error
                                                                                      )
                                                                                  )
                                                                                  this.props.handleUserAction()
                                                                              }
                                                                    }
                                                                    title={
                                                                        this.props.generateTextError(
                                                                            this
                                                                                .props
                                                                                .platform_errors_state
                                                                                .error
                                                                        ).text
                                                                    }
                                                                    subTitle={
                                                                        this.props.generateTextError(
                                                                            this
                                                                                .props
                                                                                .platform_errors_state
                                                                                .error
                                                                        ).detail
                                                                    }
                                                                    buttonText={this.props.generateCustomUserActionText(
                                                                        this
                                                                            .props
                                                                            .platform_errors_state
                                                                            .error
                                                                    )}
                                                                    headIcon={
                                                                        warning
                                                                    }
                                                                    isRefresh
                                                                    isModal
                                                                />
                                                            </>
                                                        )}
                                                    </Container>
                                                </Wrapper>
                                            </ModalDisplay>
                                        ))}
                                </div>
                            )}
                    </div>
                )}
            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(BackEndError))
