import { call, fork, put, select, take } from 'redux-saga/effects'
// WATCHER FUNCTION : watcher get car by id*
import { push } from 'connected-react-router'
import * as actions from '../actions'
import { ICarPayload } from 'IapiDataPayload'
import {
    ICarsObject,
    ICreateCarVersion2,
    ICustomErrorData,
    IDropdownItem,
    IGalleryImagesObject,
    IHighlightedFactsObject,
    IReduxError,
    ITechnicalInformationObject,
} from 'entityModels'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
    convertToTechnicalInformationState,
} from '../../../conversions/entities/conversionFromAPI'

import * as galleryActions from '../../../entities/galleries/actions/index'
import * as highlighted_facts_actions from '../../../entities/highlighted_facts/actions/index'
import * as tech_info_actions from '../../../entities/technical_information/actions/index'
import * as garage_actions from '../../../entities/garages/actions'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'

import { ICreateCarVersion2_api_req } from 'ApiInterfaces'
import { addACarVersion2 } from '../../../services/entities/mutateGarage'
import { convertDateWithDayJs } from '../../../helpers/dates'
import { message } from 'antd'
import { sortOutAddACarAdTracking } from './createCar'

const state_select_user = (state: RootState) => state.user.userLoggedIn

export function* createCarSagaVersion2(payload: ICreateCarVersion2) {
    try {
        let dataToSend: ICreateCarVersion2_api_req = {
            garage_id: payload.garage_id,
        }

        if (payload.registration_number) {
            dataToSend['registration_number'] = payload.registration_number
        }

        const getAllState = (state: RootState): RootState => state

        let store: RootState = yield select(getAllState)

        let tempMakeID = store.localdata.dropdownData.tempMakeID
        let tempModelID = store.localdata.dropdownData.tempModelID

        let tempMakeDisplay = store.localdata.dropdownData.tempMakeDisplay
        let tempModelDisplay = store.localdata.dropdownData.tempModelDisplay

        let dataList = payload.data

        if (dataList) {
            for (const data of dataList) {
                if (data.id === 'make' || data.id === 'model') {
                    const listMakes = store.localdata.dropdownData.carMakes
                    const listModels = store.localdata.dropdownData.carModels

                    if (data.id === 'make') {
                        if (data?.answer) {
                            let item: IDropdownItem

                            for (const itemIndex in listMakes) {
                                item = listMakes[+itemIndex]

                                if (
                                    typeof data?.answer === 'string' &&
                                    item?.name?.toLowerCase() ===
                                        data?.answer.toLowerCase()
                                ) {
                                    if (
                                        item.uid !== 'new_entry' &&
                                        item.name !== 'new_entry'
                                    ) {
                                        dataToSend.make_id = item.uid
                                        break
                                    }
                                }
                            }
                            // console.log('after loop 1', dataToSend)
                        }

                        if (tempMakeID) {
                            dataToSend.make_id = tempMakeID
                        }

                        // last resort
                        if (!dataToSend.make_id) {
                            dataToSend.make_id = tempMakeID
                        }
                    }

                    if (data.id === 'model') {
                        if (tempModelID) {
                            dataToSend.model_id = tempModelID
                        } else {
                            let item: IDropdownItem

                            for (const itemIndex in listModels) {
                                item = listModels[+itemIndex]

                                if (
                                    typeof data?.answer === 'string' &&
                                    item?.name?.toLowerCase() ===
                                        data?.answer?.toLowerCase()
                                ) {
                                    if (
                                        item.uid !== 'new_entry' &&
                                        item.name !== 'new_entry'
                                    ) {
                                        dataToSend.model_id = item.uid

                                        break
                                    }
                                }
                            }

                            // console.log('after loop 2', dataToSend)
                            if (!dataToSend.model_id && tempModelID) {
                                dataToSend.model_id = tempModelID
                            }
                        }
                    }
                }

                if (data.id === 'title') {
                    dataToSend.title = `${data.answer}`
                }
                if (
                    data.id === 'mileage' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.mileage = +`${data.answer}`
                }

                if (data.id === 'year' && data.answer && data.answer !== '-') {
                    dataToSend.year = data.answer
                }

                if (
                    data.id === 'transmission' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.transmission = data.answer
                }

                if (
                    data.id === 'color_exterior' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.color_exterior = data.answer
                }

                if (
                    data.id === 'fuel_type' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.fuel_type = data.answer
                }

                if (
                    data.id === 'drive_side' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    if (data.answer === 'Center') {
                        dataToSend.drive_side = 'Center'
                    } else if (data.answer === 'RHD') {
                        dataToSend.drive_side = 'Right'
                    } else if (data.answer === 'LHD') {
                        dataToSend.drive_side = 'Left'
                    } else {
                        dataToSend.drive_side = `${data.answer}`
                    }
                }

                if (
                    data.id === 'registration_date' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.registration_date = convertDateWithDayJs(
                        data.answer
                    )
                }

                if (
                    data.id === 'registration_number' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.registration_number = data.answer
                }

                if (
                    data.id === 'last_owner_change' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.last_owner_change = convertDateWithDayJs(
                        data.answer
                    )
                }

                if (
                    data.id === 'service_check_status' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.service_check_status = data.answer
                }

                if (
                    data.id === 'service_check_expiry' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.service_check_expiry = convertDateWithDayJs(
                        data.answer
                    )
                }

                if (
                    data.id === 'color_interior' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-' &&
                    typeof data.answer === 'string'
                ) {
                    dataToSend.color_interior = data.answer
                }

                if (
                    data.id === 'tax_due' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.tax_due = convertDateWithDayJs(data.answer)
                }

                if (
                    data.id === 'tax_status' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.tax_status = data.answer
                }

                if (
                    data.id === 'engine_capacity' ||
                    data.id === 'engine_size'
                ) {
                    if (
                        data.answer !== null &&
                        data.answer !== undefined &&
                        data.answer !== '-'
                    ) {
                        if (
                            (typeof data.answer === 'number' &&
                                data.answer > 100000) ||
                            (typeof data.answer === 'string' &&
                                parseInt(data.answer) > 100000)
                        ) {
                            message.error(
                                'Please add a realistic value for the engine size.'
                            )
                        } else {
                            dataToSend.engine_capacity = +data.answer
                        }
                    }
                }

                if (
                    data.id === 'custom_body_type' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.custom_body_type = data.answer
                    dataToSend.custom_body_type = data.answer
                }

                if (
                    data.id === 'body_type' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.body_type =
                        data.answer === 'SUV / 4x4' ? 'SUV' : data.answer
                }

                if (
                    data.id === 'chassis_number' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.vin = data.answer
                }

                if (
                    data.id === 'drive_train' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.drive_train =
                        data.answer && data.answer === '4WD' ? '4WD' : '2WD'
                }

                if (data.id === 'title' && data.answer) {
                    dataToSend.title = `${data.answer}`
                }
            }

            if (dataToSend.year || tempModelDisplay || tempMakeDisplay) {
                dataToSend.title = `${
                    dataToSend.year ? `${dataToSend.year.toString()} ` : ''
                }${tempMakeDisplay ? `${tempMakeDisplay} ` : ''}${
                    tempModelDisplay ? tempModelDisplay : ''
                }`
            }
        }

        // here, have the datato send

        let caritem: ICarPayload = yield call(addACarVersion2, dataToSend)

        yield put(garage_actions.loadingActions.addCarToGarageRequest())

        // send datalayer stuff
        let udata: IUser | null = yield select(state_select_user)

        if (udata) {
            sortOutAddACarAdTracking({
                user_id: udata.id,
                email: `${udata.email}`,
                first_name: `${udata.given_name}`,
                last_name: `${udata.family_name}`,
            })
        }

        let carObjects: ICarsObject = {}

        let galleryImagesObject: IGalleryImagesObject = {}

        let highlightedFactsObject: IHighlightedFactsObject = {}

        let technicalInformationObject: ITechnicalInformationObject = {}

        // convert to car state payload

        let car: ICarsObject = convertToCarState(caritem)

        car[caritem.uid].has_limited_garage_info = false

        let gallery_images_object: IGalleryImagesObject =
            convertToGalleryImagesState(caritem)

        let highlighted_facts_object: IHighlightedFactsObject =
            convertToHighlightedFactsState(caritem)

        let technical_information_object: ITechnicalInformationObject =
            convertToTechnicalInformationState(caritem)

        Object.assign(galleryImagesObject, gallery_images_object)

        Object.assign(carObjects, car)

        Object.assign(highlightedFactsObject, highlighted_facts_object)

        Object.assign(technicalInformationObject, technical_information_object)

        const results: ICarsObject = carObjects

        yield put(
            galleryActions.actions.setGalleryImagesSuccess(galleryImagesObject)
        )
        yield put(
            highlighted_facts_actions.actions.setHighlightedFactsSuccess(
                highlightedFactsObject
            )
        )
        yield put(
            tech_info_actions.actions.setTechnicalInformationSuccess(
                technicalInformationObject
            )
        )

        yield put(actions.actions.createCarSuccess(results))

        yield put(actions.actions.setCurrentCarSuccess(caritem.uid))

        // yield put(actions.loadingActions.writeCarDataGeneralRequest())

        // yield call(updateCarTitleAfterCreationSaga, caritem.uid, new_title, car)
        if (payload.isOnboarding) {
            yield put(push(`${payload.direct_url}?carid=${caritem.uid}`))
        } else {
            yield put(push(`/car/${caritem.uid}`))
        }

        // de-commented this as it's neccessary for the other pages that use garage data (ex: history file, shared with others)
        let new_car_uid = caritem.uid

        yield put(
            garage_actions.actions.addACarToGarageSuccess({
                garage_id: dataToSend.garage_id,
                car_id: new_car_uid,
                is_v2: true,
            })
        )

        // remove new_car

        yield put(
            garage_actions.actions.resetSortedCarsInGarage(dataToSend.garage_id)
        )

        // Capture event
        posthog.capture('ADD CAR')
    } catch (error: any) {
        if (error.status === 401) {
            let payload2: IUnauthHandlerPayload = {
                functionToRepeat: createCarSagaVersion2,
                payload: payload,
            }
            yield call(unauthHandlerSaga, payload2)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't add your car`,
                custom_user_action_text: 'Return to garage',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(
                garage_actions.errorActions.addACarToGarageError(customError)
            )
            //  yield put(actions.errorActions.getCarDataByIdError(customError))
        }
    }
}

function* watcherCreateCarVersion2() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.createCarRequestVersion2
        )

        yield call(createCarSagaVersion2, payload)
    }
}

const create_car_sagas_version2: any[] = [fork(watcherCreateCarVersion2)]

export default create_car_sagas_version2
