import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import { numberWithCommas } from '../../../../helpers/numbers'
import mileage from '../../../../public/assets/icons/mileage_grey.svg'
import StandardCtaBtn from '../../Button/standardCtaBtn'
import AddIcon from '../../icons/components/addIcon'

type StyledProps = {
    height?: string
    $isEntryPage?: boolean
    $readOnlyMode?: boolean
}
const Container = styled(motion.div)<StyledProps>`
    background-color: ${(props) =>
        props.$isEntryPage ? 'var(--bg-color, #fff)' : 'var(--primary_08)'};
    height: ${(props) => (props.height ? props.height : '30px')};
    padding-left: 12px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    ${(props) =>
        props.$isEntryPage && !props.$readOnlyMode && `cursor: pointer; `};
    ${(props) =>
        props.$isEntryPage && props.$readOnlyMode && `cursor: not-allowed; `};
    ${(props) =>
        props.$isEntryPage &&
        !props.$readOnlyMode &&
        `:hover {
        transition: all 100ms;
        background-color: var(--primary_08);
         border:1px solid var(--primary_20);
    }`};
`

const Title = styled.span`
    color: black;
    padding-right: 10px;
    padding-left: 10px;
    text-transform: capitalize;
    font-family: 'Lato';
    font-size: 11px;
    letter-spacing: 0.7px;
`

const CurrentMileage = styled.span<StyledProps>`
    color: ${(props) =>
        props.$isEntryPage
            ? 'var(--text-strong, #1a1a1a)'
            : 'var(--text-muted)'};
    padding-right: ${(props) => (props.$isEntryPage ? '5px' : '5px')};
    font-family: 'Lato';
    font-size: 11px;
    letter-spacing: 0.7px;
    text-transform: lowercase;
`

const MileageSince = styled.span`
    color: var(--primary, #5ec3ca);
    font-family: 'Lato-bold';
    font-size: 11px;
    letter-spacing: 0.7px;
    padding-right: 5px;
`

const IconWrapper = styled.div`
    height: 15px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

const Row = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

const BtnTxt = styled.span`
    color: var(--primary, #5ec3ca);
    text-align: center;
    font-family: Lato-semibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

type Props = {
    dataCyId?: string
    height?: string
    mileageRecorded: number | undefined
    mileageSince: number | undefined
    unit: string | null | undefined
    isEntryPage?: boolean
    onClick?: any
    hasSampleData?: boolean
    readOnlyMode?: boolean
}

class MileageTagDesktop extends React.Component<Props, {}> {
    render() {
        const {
            dataCyId,
            height,
            mileageRecorded,
            mileageSince,
            unit,
            isEntryPage,
            onClick,
            hasSampleData,
            readOnlyMode,
        } = this.props

        return isEntryPage && !mileageRecorded && !readOnlyMode ? (
            <StandardCtaBtn
                onClick={onClick}
                boder="none"
                bg="transparent"
                removePaddings
                removeShadowOnHover
                customHeight="auto"
            >
                <Row>
                    <AddIcon colour="#5ec3ca" />
                    <BtnTxt>Add mileage</BtnTxt>
                </Row>
            </StandardCtaBtn>
        ) : (
            <Container
                data-attr={dataCyId && dataCyId}
                height={height}
                $isEntryPage={isEntryPage}
                onClick={onClick}
                whileTap={isEntryPage && !readOnlyMode ? { scale: 1.05 } : {}}
                $readOnlyMode={readOnlyMode}
            >
                {isEntryPage ? (
                    <IconWrapper>
                        <img
                            src={mileage}
                            alt={'mileage'}
                            style={{ height: '100%' }}
                        />
                    </IconWrapper>
                ) : (
                    <Title>Mileage:</Title>
                )}

                <CurrentMileage $isEntryPage={isEntryPage}>
                    {hasSampleData
                        ? `3500 ${unit && unit === 'kilometers' ? 'km' : unit}`
                        : mileageRecorded
                        ? `${numberWithCommas({
                              numberToConvert: mileageRecorded,
                              decimals: 0,
                          })} ${unit && unit === 'kilometers' ? 'km' : unit}`
                        : 'unknown'}
                </CurrentMileage>
                <MileageSince>
                    {hasSampleData
                        ? `+37`
                        : mileageSince !== undefined
                        ? `+${numberWithCommas({
                              numberToConvert: mileageSince,
                              decimals: 0,
                              round: 'ceil',
                          })} `
                        : ''}
                </MileageSince>
            </Container>
        )
    }
}

export default MileageTagDesktop
