type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const AccountIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ? `${Number(size) * 0.875}` : '14'}
            height={size ?? '16'}
            viewBox="0 0 14 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 13.25C0.25 10.7197 2.42278 8.75 5 8.75H9C11.5772 8.75 13.75 10.7197 13.75 13.25V14.433L13.2155 14.5934L13 13.875C13.2155 14.5934 13.2157 14.5933 13.2155 14.5934L13.2137 14.5939L13.2111 14.5947L13.2036 14.5969L13.1794 14.6038C13.1592 14.6095 13.131 14.6173 13.0946 14.6269C13.0219 14.6462 12.917 14.6726 12.7803 14.7038C12.507 14.7663 12.1067 14.8478 11.5834 14.9287C10.5367 15.0905 8.99836 15.25 7 15.25C5.00164 15.25 3.46331 15.0905 2.41663 14.9287C1.89334 14.8478 1.49305 14.7663 1.2197 14.7038C1.08303 14.6726 0.978099 14.6462 0.905399 14.6269C0.86905 14.6173 0.840758 14.6095 0.820586 14.6038L0.796425 14.5969L0.788926 14.5947L0.786324 14.5939L0.785311 14.5936C0.785113 14.5936 0.784489 14.5934 1 13.875L0.785311 14.5936L0.25 14.433V13.25ZM1.75 13.2845C1.97757 13.3325 2.27676 13.3892 2.64587 13.4463C3.62002 13.597 5.08169 13.75 7 13.75C8.91831 13.75 10.38 13.597 11.3541 13.4463C11.7232 13.3892 12.0224 13.3325 12.25 13.2845V13.25C12.25 11.6378 10.8415 10.25 9 10.25H5C3.15855 10.25 1.75 11.6378 1.75 13.25V13.2845Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.18182 1.75C5.79711 1.75 4.75 2.79882 4.75 4.0011C4.75 5.20268 5.79638 6.25 7.18182 6.25C8.56726 6.25 9.61364 5.20268 9.61364 4.0011C9.61364 2.79882 8.56653 1.75 7.18182 1.75ZM3.25 4.0011C3.25 1.88896 5.05253 0.25 7.18182 0.25C9.31111 0.25 11.1136 1.88896 11.1136 4.0011C11.1136 6.11392 9.31038 7.75 7.18182 7.75C5.05326 7.75 3.25 6.11392 3.25 4.0011Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default AccountIcon
