import { IAttachmentItem } from 'attachmentModels'
import styled from 'styled-components'
import { convertToShorterString } from '../../../../helpers/longStrings/convertToShorterString'
import BinIcon from '../../icons/components/binIcon'
import DownloadIcon from '../../icons/components/downloadIcon'
import StandardCtaBtn from '../../Button/standardCtaBtn'
import PdfIcon from '../../icons/components/pdfIcon'
import GalleryIcon from '../../icons/components/galleryIcon'
import dayjs from 'dayjs'
import HamburgerIconEqualLines from '../../icons/components/hamburgerIconEqualLines'
import DocumentIcon from '../../icons/components/document'

interface IStyled {
    someProp?: any
}

const Container = styled.div<IStyled>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const FileName = styled.a<IStyled>`
    word-break: break-all;
    font-family: 'Lato';
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    line-height: 16px;
    max-width: 100%;
    :hover {
        color: var(--primary, #5ec3ca);
    }
`

const Row = styled.div<IStyled>`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`

type ProgressProps = {
    progressPercent?: number
}

const AnimatedUploadCompleteWrapper = styled.div<ProgressProps>`
    animation: fadeIn 300ms both;
    margin-bottom: 16px;
    padding-right: 16px;

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    :hover {
        background-color: var(--off-bg-color, #fafafa);
    }
`

const StyledA = styled.a`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const IconWrapper = styled.div`
    display: flex;
    width: 48px;
    min-width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
`

const Added = styled.div`
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
`

type Props = {
    dataCyId?: string
    attachment: IAttachmentItem
    downloadAttachment?: any
    onClickDelete: any
    noDownloadIcon?: boolean
    id?: string | number
    readOnlyMode?: boolean
}

const images = ['jpg', 'jpeg', 'png', 'HEIF', 'heif', 'HEIC', 'heic']

const docs = ['xlsm', 'docx', 'xlsx', 'xls']

export const generateIcon = (file: IAttachmentItem) => {
    return (
        <IconWrapper
            style={{
                backgroundColor:
                    file.fileSuffix === 'pdf'
                        ? 'var(--file-sufix-pdf, rgba(223, 111, 111, 0.08))'
                        : images.includes(file.fileSuffix)
                        ? 'var(--file-sufix-img, rgba(103, 160, 227, 0.08))'
                        : docs.includes(file.fileSuffix)
                        ? 'var(--file-sufix-docs, rgba(170, 111, 216, 0.08))'
                        : 'transparent',
            }}
        >
            {file.fileSuffix === 'pdf' ? (
                <PdfIcon />
            ) : images.includes(file.fileSuffix) ? (
                <GalleryIcon />
            ) : docs.includes(file.fileSuffix) ? (
                <HamburgerIconEqualLines />
            ) : (
                <DocumentIcon />
            )}
        </IconWrapper>
    )
}

function AttachmentInItemSinglePageDesktop(props: Props) {
    const {
        dataCyId,
        attachment,
        downloadAttachment,
        onClickDelete,
        noDownloadIcon,
        id,
        readOnlyMode,
    } = props

    return (
        <div id={`attachment_no_${id ? id : ''}`} style={{ width: '100%' }}>
            <AnimatedUploadCompleteWrapper>
                <Container data-attr={dataCyId && dataCyId}>
                    <Row
                        style={{
                            justifyContent: 'flex-start',
                            gap: 16,
                            minWidth: 0,
                        }}
                    >
                        {generateIcon(attachment)}
                        <div style={{ width: '100%' }}>
                            <FileName
                                href={attachment.url}
                                target="_blank"
                                download
                                rel="noreferrer"
                            >
                                {attachment
                                    ? attachment.filename &&
                                      convertToShorterString({
                                          string: attachment.filename,
                                          maxChars: 20,
                                          isFileName: true,
                                      })
                                    : ''}
                            </FileName>
                            <Added>
                                Added on{' '}
                                {dayjs(attachment.created_at).format(
                                    'DD.MM.YYYY'
                                )}
                            </Added>
                        </div>
                    </Row>

                    <Row>
                        {!noDownloadIcon && (
                            <StyledA
                                href={attachment.url}
                                target="_blank"
                                download
                                rel="noreferrer"
                                onClick={downloadAttachment}
                            >
                                <DownloadIcon />
                            </StyledA>
                        )}
                        <div style={{ paddingLeft: '10px' }} />
                        {!readOnlyMode && (
                            <StandardCtaBtn
                                bg="transparent"
                                boder="none"
                                removeShadowOnHover
                                customWidth="32px"
                                customHeight="auto"
                                removePaddings
                                onClick={onClickDelete}
                            >
                                <BinIcon />
                            </StandardCtaBtn>
                        )}
                    </Row>
                </Container>
            </AnimatedUploadCompleteWrapper>
            {/* )} */}
        </div>
    )
}

export default AttachmentInItemSinglePageDesktop
