import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import styled from 'styled-components'
import { insurance_wizard_sections } from '../../../../providers/insurance/data'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { insuranceActions } from '../../../../redux/insuranceQuoteApplication/reducer'
import { menuActions } from '../../../../redux/menus/reducer'
import { IRootState } from '../../../../redux/store'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import InsuranceWizardHeader from '../../../atoms/header/insurance/insuranceWizardHeader'
import WizardFormsManager from '../../../organisms/insuranceQuote/wizardManagers'
import InsuranceWizardSubBar from '../../../templates/bars/insurance/wizard/subBar'
import InsuranceApplicationWizardTopBarDesktop from '../../../templates/bars/insurance/wizard/topBarDesktop'
import InsuranceApplicationWizardTopBarMobile from '../../../templates/bars/insurance/wizard/topBarMobile'
import { device } from '../../../templates/displays/devices'
import { IInsuranceApplicationSectionId } from '../dashboards/insuranceApplicationDashboard'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.div`
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
`

// const SubWrapper = styled.div`
//     width: 100%;

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: flex-start;

//     @media ${device.beyond_ipad_mobile} {
//         width: 566px;
//         padding-top: 140px;
//     }
// `

const SubWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media ${device.mobile_and_ipad} {
        padding-top: 96px;
        width: 88vw;
    }

    @media ${device.beyond_ipad_mobile} {
        padding-left: 0;
        padding-right: 0;
        width: 566px;
        padding-top: 140px;
    }
`
export type ITopWizardCheckActions = {
    sectionID: IInsuranceApplicationSectionId | 'submit' | string
    onSave: () => any
    has_changes: boolean
}
const WizardPage = () => {
    let application = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const dispatch = useAppDispatch()

    const location = useLocation()

    let params = new URLSearchParams(location.search)
    let section_id:
        | IInsuranceApplicationSectionId
        | 'submit'
        | null
        | undefined
        | string = params.get('section_id')
    let subsection_id = params.get('subsection_id')
    let driver_id = params.get('driver_id')

    let car_id = params.get('car_id')

    const { id } = useParams<{ id?: string }>()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const {
        getInitialSectionWizardIds,
        getUserAndApplicationData,
        isInsuranceWindowOpened,
        reset_set_wizard_entity_id_to_skip,
    } = useSetInitialInsurance()

    const history = useHistory()

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
            reset_set_wizard_entity_id_to_skip()
        }
    }, [])

    const [hasCalledValidation, setHasCalledValidation] =
        useState<boolean>(false)

    useEffect(() => {
        if (application && application?.id && hasCalledValidation !== true) {
            dispatch(
                insuranceActions.validate_insurance_application_request({
                    application: { ...application },
                    activateValidationSheet: true,
                    isWizard: true,
                })
            )
            setHasCalledValidation(true)
        }
    }, [application])

    useEffect(() => {
        if (id && !application.id) {
            getUserAndApplicationData(id)
        }
    }, [userLoggedIn])

    useEffect(() => {
        if (application.overall_completion === 100) {
            // until stats become more reliable
            dispatch(
                insuranceActions.validate_insurance_application_request({
                    application: { ...application },
                    activateValidationSheet: true,
                    isWizard: true,
                })
            )

            // history.push(
            //     `/insurance/application/${application?.id}/submission-agreement`
            // )
            history.push(`/insurance/application/${application?.id}`)
        }
    }, [userLoggedIn, location])

    useEffect(() => {
        if (application && !section_id) {
            let ids = getInitialSectionWizardIds()
            if (ids) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids.sectionID}`)
                params2.append('subsection_id', `${ids.sub_sectionID}`)
                if (ids.car_id) {
                    params2.append('car_id', `${ids.car_id}`)
                }
                if (ids.driver_id) {
                    params2.append('driver_id', `${ids.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else {
                history.push(`/insurance/application/${id}`)
            }
        }
    }, [application])

    const [topWizardCheckActions, setTopWizardCheckActions] =
        useState<ITopWizardCheckActions | null>(null)

    const { theme } = useThemes()

    return (
        <Wrapper
            style={{
                minHeight: '100vh',
                backgroundColor: colours[theme].background_neutral_subtle,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexDirection: 'column',
            }}
        >
            <SubWrapper>
                {insurance_wizard_sections[`${section_id}`] && section_id && (
                    <InsuranceWizardHeader
                        section_id={section_id}
                        subsection_id={subsection_id}
                        car_id={car_id}
                        driver_id={driver_id}
                    />
                )}

                {section_id === 'everyday_vehicles' && !subsection_id ? (
                    <div style={{ paddingTop: '40px' }} />
                ) : section_id === 'drivers' && !subsection_id ? null : (
                    <div style={{ paddingTop: '40px' }} />
                )}

                {insurance_wizard_sections[`${section_id}`] &&
                    section_id &&
                    subsection_id &&
                    subsection_id !== 'null' &&
                    !isInsuranceWindowOpened && (
                        <InsuranceWizardSubBar
                            car_id={car_id}
                            section_id={section_id}
                            subsection_id={subsection_id}
                            driver_id={driver_id}
                        />
                    )}

                <WizardFormsManager
                    car_id={car_id}
                    setTopWizardCheckActions={setTopWizardCheckActions}
                    section_id={section_id}
                    subsection_id={subsection_id}
                    driver_id={driver_id}
                />
            </SubWrapper>

            {section_id && !isInsuranceWindowOpened && (
                <>
                    <InsuranceApplicationWizardTopBarDesktop
                        sectionID={section_id}
                        topWizardCheckActions={topWizardCheckActions}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />

                    <InsuranceApplicationWizardTopBarMobile
                        sectionID={section_id}
                        topWizardCheckActions={topWizardCheckActions}
                        setTopWizardCheckActions={setTopWizardCheckActions}
                    />
                </>
            )}
        </Wrapper>
    )
}

export default WizardPage
