import React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../../templates/displays/pageWrappers/FullPagePageWrapper'
import ScrollLock from 'react-scrolllock'
import GallerySlide from '../../../molecules/slideshow/gallerySlide'
import { ICar, IExternalCar, IGalleryImagesObject } from 'entityModels'
import ReturnNav from '../../../atoms/header/returnNav'
import ModalDisplayGalleryImageDesktop from '../../../templates/displays/pageWrappers/modalDisplayGalleryImageDesktop'
import GalleryImageCarousel from '../../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import SlidingUpOnScrollBanner from '../../../atoms/header/slidingUpOnScrollBanner'

interface Props extends RouteComponentProps<any> {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    images_data: IGalleryImagesObject | null
    isNecessaryGalleryDataLoading: boolean
}

type State = {
    image_index?: number | undefined
}

function mapStateToProps(state: RootState) {
    return {
        images_data: state.entities.galleriesData.images,
        isNecessaryGalleryDataLoading: state.entities.carsData.loading,
    }
}

class ReadOnlyCarGalleryImage extends React.Component<Props, State> {
    state = {
        image_index: undefined,
    }

    setUpInitialIndex = () => {
        let params = new URLSearchParams(this.props.location.search)
        let image_id_param = params.get('id')

        let current = this.props.sharedCar ?? this.props.prevOwned

        if (current && image_id_param) {
            this.setState({
                ...this.state,
                image_index: current?.gallery?.images.indexOf(image_id_param),
            })
        }
    }

    componentDidMount() {
        this.setUpInitialIndex()
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.sharedCar !== this.props.sharedCar) {
            this.setUpInitialIndex()
        }
    }

    generateImageNavigationUrl = (next: string) => {
        let params = new URLSearchParams(this.props.location.search)
        let image_id_param = params.get('id')
        let carid: string = this.props.match.params.carid
        let linkPrefix = this.props.sharedCar ? 'shared' : 'archives'
        let current = this.props.sharedCar ?? this.props.prevOwned

        if (current && image_id_param) {
            const ids_list =
                current.gallery && current.gallery.images
                    ? current.gallery.images
                    : []

            const index_of_id = ids_list && ids_list.indexOf(image_id_param)
            if (ids_list && index_of_id !== null) {
                if (next === 'next') {
                    const index_of_next_image = index_of_id + 1

                    if (index_of_next_image === -1) {
                        return '/none'
                    }

                    const id_of_next_image = ids_list[index_of_next_image]

                    if (id_of_next_image !== undefined) {
                        return `/${linkPrefix}/car/${carid}/gallery?id=${id_of_next_image}`
                    } else return '/none'
                } else {
                    const index_of_previous_image = index_of_id - 1

                    if (index_of_previous_image === -1) {
                        return '/none'
                    }

                    const id_of_previous_image =
                        ids_list[index_of_previous_image]

                    return `/${linkPrefix}/car/${carid}/gallery?id=${id_of_previous_image}`
                }
            } else {
                return '/none'
            }
        } else {
            return '/none'
        }
    }

    countFeaturedImages = (car: IExternalCar | ICar): number => {
        let carImagesIds: string[] | null = car.gallery.images

        let { images_data } = this.props
        let featuredImagesCount: number = 0
        if (carImagesIds) {
            for (let i = 0; i < carImagesIds.length; i++) {
                let imageId: string = carImagesIds[i]
                if (
                    images_data &&
                    images_data[imageId] &&
                    images_data[imageId].featured === true
                ) {
                    featuredImagesCount++
                }
            }
        }
        return featuredImagesCount
    }

    render() {
        const {
            isNecessaryGalleryDataLoading,
            images_data,
            sharedCar,
            prevOwned,
        } = this.props
        let params = new URLSearchParams(this.props.location.search)
        let image_id_param = params.get('id')

        const currentcar = sharedCar ?? prevOwned

        let prev_url = this.generateImageNavigationUrl('previous')
        let next_url = this.generateImageNavigationUrl('next')

        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            this.state.image_index &&
            currentcar &&
            currentcar?.gallery.images
                ? currentcar.gallery.images[this.state.image_index]
                : image_id_param

        return (
            <React.Fragment>
                <ScrollLock isActive={true} />
                {images_data && currentcar && (
                    <>
                        <DesktopDisplayOnly>
                            <DesktopDisplayOnly>
                                <FullPageWrapper>
                                    <ModalDisplayGalleryImageDesktop
                                        toggle={() => {
                                            this.props.history.push(
                                                this.props.location.pathname
                                            )
                                        }}
                                        isOpen={true}
                                    >
                                        {currentcar !== undefined &&
                                            current_image_id &&
                                            images_data &&
                                            images_data[current_image_id] && (
                                                <GallerySlide
                                                    closeGallery={() => {
                                                        this.props.history.push(
                                                            this.props.location
                                                                .pathname
                                                        )
                                                    }}
                                                    currentImageData={
                                                        images_data[
                                                            current_image_id
                                                        ]
                                                    }
                                                    carid={currentcar.id}
                                                    imagesData={images_data}
                                                    currentCar={currentcar}
                                                    isSomethingLoading={
                                                        isNecessaryGalleryDataLoading
                                                    }
                                                    prevUrl={prev_url}
                                                    nextUrl={next_url}
                                                    featuredImagesCount={
                                                        currentcar &&
                                                        images_data
                                                            ? this.countFeaturedImages(
                                                                  currentcar
                                                              )
                                                            : 0
                                                    }
                                                    readOnlyMode={true}
                                                    updateImage={() => {}}
                                                    onClickEdit={() => {}}
                                                />
                                            )}
                                    </ModalDisplayGalleryImageDesktop>
                                </FullPageWrapper>
                            </DesktopDisplayOnly>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'relative',
                                    background: 'var(--bg-color, #fafafa)',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100vh',
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `${this.props.history.location.pathname}`,
                                        state: {
                                            prevPath: this.props.match.url,
                                        },
                                    }}
                                >
                                    <ReturnNav />
                                </Link>
                                {currentcar && (
                                    <SlidingUpOnScrollBanner
                                        text={
                                            currentcar.title.length > 32
                                                ? `${currentcar.title.slice(
                                                      0,
                                                      34
                                                  )}...`
                                                : currentcar.title
                                        }
                                        customStyle={{
                                            transform: 'translateY(50px)',
                                        }}
                                    />
                                )}
                                {currentcar !== undefined &&
                                current_image_id &&
                                images_data &&
                                images_data[current_image_id] &&
                                this.state.image_index !== undefined ? (
                                    <GalleryImageCarousel
                                        indexOfInitialImage={
                                            this.state.image_index
                                        }
                                        image_data={images_data}
                                        images_ids={currentcar.gallery.images}
                                        synchroniseActiveIndex={(n: number) => {
                                            this.setState({
                                                ...this.state,
                                                image_index: n,
                                            })
                                        }}
                                        openActionsSheet={() => {}}
                                        cover_id={currentcar.gallery.cover}
                                        readOnlyMode={true}
                                    />
                                ) : (
                                    <SkeletonAbsolute isthingloading={true} />
                                )}
                            </div>
                        </IpadAndMobileDisplay>
                    </>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, {})(ReadOnlyCarGalleryImage))
