import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

export default function DarkThemeIcon({ size, color }: Props) {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M14.0951 12.4607L14.6729 12.9389C14.9073 12.6557 14.9019 12.2442 14.6599 11.9673C14.418 11.6903 14.011 11.6296 13.6989 11.8239L14.0951 12.4607ZM14.0951 3.53933L13.6989 4.1761C14.011 4.37038 14.418 4.30966 14.6599 4.03274C14.9019 3.75581 14.9073 3.34434 14.6729 3.06109L14.0951 3.53933ZM2.4502 8C2.4502 4.54822 5.24842 1.75 8.7002 1.75V0.25C4.41999 0.25 0.950195 3.71979 0.950195 8H2.4502ZM8.7002 14.25C5.24842 14.25 2.4502 11.4518 2.4502 8H0.950195C0.950195 12.2802 4.41999 15.75 8.7002 15.75V14.25ZM13.5174 11.9824C12.3698 13.3688 10.6382 14.25 8.7002 14.25V15.75C11.1039 15.75 13.2525 14.6548 14.6729 12.9389L13.5174 11.9824ZM13.6989 11.8239C13.0103 12.2524 12.1978 12.5 11.3252 12.5V14C12.486 14 13.5719 13.6696 14.4914 13.0974L13.6989 11.8239ZM11.3252 12.5C8.83991 12.5 6.8252 10.4853 6.8252 8H5.3252C5.3252 11.3137 8.01149 14 11.3252 14V12.5ZM6.8252 8C6.8252 5.51472 8.83991 3.5 11.3252 3.5V2C8.01149 2 5.3252 4.68629 5.3252 8H6.8252ZM11.3252 3.5C12.1978 3.5 13.0103 3.74763 13.6989 4.1761L14.4914 2.90255C13.5719 2.33037 12.486 2 11.3252 2V3.5ZM8.7002 1.75C10.6382 1.75 12.3698 2.6312 13.5174 4.01756L14.6729 3.06109C13.2525 1.34522 11.1039 0.25 8.7002 0.25V1.75Z"
                fill={color ?? colours[theme].icon}
            />
        </svg>
    )
}
