import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const ModelIcon = ({ size, strokeColor, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.32373 22.1357L6.62277 22.8857L4.69779 26.2199L6.8606 26.2296C7.12613 26.2308 7.37124 26.3723 7.50505 26.6017L8.59488 28.4699L10.5199 25.1357L11.8189 25.8857L9.24776 30.3391C9.11408 30.5706 8.86727 30.7135 8.59992 30.7141C8.33258 30.7147 8.08512 30.5729 7.95041 30.342L6.42533 27.7277L3.39874 27.7141C3.13139 27.7129 2.8849 27.5695 2.75175 27.3376C2.6186 27.1058 2.61892 26.8206 2.75259 26.5891L5.32373 22.1357Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.1768 25.8783L21.4758 25.1283L23.4044 28.4688L24.4931 26.5996C24.627 26.3698 24.8725 26.228 25.1384 26.227L27.3016 26.2188L25.3729 22.8783L26.6719 22.1283L29.2473 26.5889C29.381 26.8205 29.3813 27.1058 29.248 27.3376C29.1147 27.5695 28.868 27.7129 28.6006 27.7139L25.5733 27.7254L24.0497 30.3413C23.9151 30.5724 23.6676 30.7144 23.4002 30.7139C23.1327 30.7134 22.8858 30.5705 22.7521 30.3389L20.1768 25.8783Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.8557 4.89503L16.0001 3.125L14.1445 4.89503L11.7294 4.03277L10.7541 6.40452L8.19708 6.59913L8.27082 9.1625L6.014 10.3803L7.12399 12.6921L5.55762 14.7225L7.51192 16.383L6.90683 18.875L9.36753 19.597L9.82835 22.1197L12.37 21.7784L13.817 23.8955L16.0001 22.55L18.1832 23.8955L19.6302 21.7784L22.1718 22.1197L22.6327 19.597L25.0934 18.875L24.4883 16.383L26.4426 14.7225L24.8762 12.6921L25.9862 10.3803L23.7294 9.1625L23.8031 6.59913L21.2461 6.40452L20.2708 4.03277L17.8557 4.89503ZM17.0604 8.63645L16.0001 7.625L14.9397 8.63645L13.5597 8.14373L13.0024 9.49901L11.5412 9.61022L11.5834 11.075L10.2938 11.7709L10.928 13.0919L10.033 14.2522L11.1497 15.201L10.8039 16.625L12.2101 17.0376L12.4734 18.4791L13.9257 18.2841L14.7526 19.4939L16.0001 18.725L17.2476 19.4939L18.0745 18.2841L19.5268 18.4791L19.7901 17.0376L21.1962 16.625L20.8505 15.201L21.9672 14.2522L21.0722 13.0919L21.7064 11.7709L20.4168 11.075L20.459 9.61022L18.9978 9.49901L18.4405 8.14373L17.0604 8.63645Z"
                fill={color ?? colours[theme].icons_neutral_250}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.9997 1.9635L18.0384 3.90826L20.692 2.96088L21.7635 5.56676L24.5729 5.78057L24.4919 8.59698L26.9715 9.93502L25.752 12.475L27.473 14.7059L25.3257 16.5302L25.9906 19.2682L23.287 20.0615L22.7806 22.8332L19.9881 22.4582L18.3982 24.7844L15.9997 23.306L13.6011 24.7844L12.0112 22.4582L9.21868 22.8332L8.71237 20.0615L6.00877 19.2682L6.67358 16.5302L4.52637 14.7059L6.24736 12.475L5.02781 9.93502L7.5074 8.59698L7.42638 5.78057L10.2358 5.56676L11.3073 2.96088L13.9609 3.90826L15.9997 1.9635ZM15.9997 4.03648L14.3272 5.63179L12.1505 4.85465L11.2715 6.99227L8.9669 7.16767L9.03337 9.478L6.99933 10.5756L7.99974 12.6592L6.58799 14.4892L8.34938 15.9857L7.80402 18.2317L10.0218 18.8825L10.4371 21.1561L12.7279 20.8485L14.0321 22.7567L15.9997 21.544L17.9672 22.7567L19.2714 20.8485L21.5622 21.1561L21.9775 18.8825L24.1953 18.2317L23.6499 15.9857L25.4113 14.4892L23.9996 12.6592L25 10.5756L22.966 9.478L23.0324 7.16767L20.7278 6.99227L19.8488 4.85465L17.6721 5.63179L15.9997 4.03648Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
        </svg>
    )
}
export default ModelIcon
