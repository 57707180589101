import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'

import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'

import { showroomActions } from '../reducer'

export type I_showroom_entry_create_arg = {
    resource_id: string
    type_of_sale: 'SET_PRICE' | 'PRICE_RANGE' | 'PRICE_ON_ASKING'
    price_min?: number
    price_max?: number
    currency?: 'GBP' | 'USD' | 'EUR' | string
    open_to_offers?: boolean
}

export function* ValidateShowroomEntry(payload: string): any {
    try {
        let res: any = yield call(api.showroom.validateShowroomEntry, payload)

        if (res?.fields) {
            yield put(showroomActions.validateEntryForPublicationError(res))
        } else yield put(showroomActions.validateEntryForPublicationSuccess())

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: ValidateShowroomEntry,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            yield put(showroomActions.validateEntryForPublicationError(error))
        }
    }
}

function* watcherValidateShowroomEntry() {
    while (true) {
        const { payload } = yield take(
            showroomActions.validateEntryForPublicationRequest
        )

        yield call(ValidateShowroomEntry, payload)
    }
}

const validate_showroom_entry: any[] = [fork(watcherValidateShowroomEntry)]

export default validate_showroom_entry
