import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const RestorationIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.2273 10.5578C14.6415 10.5578 14.9773 10.8935 14.9773 11.3078V13.5001C14.9773 13.9143 14.6415 14.2501 14.2273 14.2501C13.8131 14.2501 13.4773 13.9143 13.4773 13.5001V11.3078C13.4773 10.8935 13.8131 10.5578 14.2273 10.5578Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0909 8.40391H6.93834V6.90391H14.0909C15.0574 6.90391 15.8409 7.68741 15.8409 8.65391V12.0578H6.21211V10.5578H14.3409V8.65391C14.3409 8.51584 14.229 8.40391 14.0909 8.40391Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                d="M13.0758 10.2116C13.5209 10.2116 13.8818 9.88442 13.8818 9.48083C13.8818 9.07724 13.5209 8.75006 13.0758 8.75006C12.6306 8.75006 12.2697 9.07724 12.2697 9.48083C12.2697 9.88442 12.6306 10.2116 13.0758 10.2116Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.6408 4.00006C7.6408 3.58585 7.97658 3.25006 8.3908 3.25006H12.4634C12.8647 3.25005 13.278 3.34147 13.628 3.53614C13.9723 3.72767 14.302 4.05078 14.4041 4.50942L15.0673 7.49105C15.1573 7.89538 14.9024 8.29607 14.4981 8.38601C14.0938 8.47596 13.6931 8.2211 13.6031 7.81677L12.9501 4.88109C12.9398 4.8726 12.9233 4.86064 12.8987 4.84695C12.8047 4.79462 12.6499 4.75006 12.4635 4.75006H8.3908C7.97658 4.75006 7.6408 4.41427 7.6408 4.00006Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.92108 4.72716C1.92108 4.07306 2.14302 3.47034 2.51642 2.99069V5.15068L4.75003 6.49773L6.98364 5.15068V2.99069C7.35704 3.47034 7.57898 4.07306 7.57898 4.72716C7.57898 5.91321 6.84885 6.93053 5.81072 7.35075L5.34213 7.54043L5.34213 13.2272C5.34213 13.5542 5.07704 13.8193 4.75003 13.8193C4.42302 13.8193 4.15792 13.5542 4.15792 13.2272L4.15792 7.54043L3.68933 7.35075C2.6512 6.93053 1.92108 5.91321 1.92108 4.72716ZM2.65792 8.51771C1.3251 7.78057 0.421082 6.36016 0.421082 4.72716C0.421082 3.04231 1.38377 1.58362 2.78562 0.868728C3.37329 0.569043 4.01642 1.01881 4.01642 1.62477V4.30364L4.75003 4.74606L5.48364 4.30364V1.62477C5.48364 1.01881 6.12677 0.569044 6.71444 0.868728C8.11629 1.58362 9.07898 3.04231 9.07898 4.72716C9.07898 6.36016 8.17496 7.78057 6.84213 8.51771L6.84213 13.2272C6.84213 14.3826 5.90547 15.3193 4.75003 15.3193C3.59459 15.3193 2.65792 14.3826 2.65792 13.2272L2.65792 8.51771Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default RestorationIcon
