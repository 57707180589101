import {
    GET_TECHNICAL_INFORMATION_REQUEST,
    WRITE_TECHNICAL_INFORMATION_REQUEST,
    SET_TECHNICAL_INFORMATION_REQUEST,
    WRITE_MULTIPLE_TECHNICAL_INFORMATION_REQUEST,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import {
    IwriteMultipleTechnicalInformationPayloadReq,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'

export const getTechnicalInformationRequest = createAction(
    GET_TECHNICAL_INFORMATION_REQUEST
)

export const writeTechnicalInformationRequest = createAction(
    WRITE_TECHNICAL_INFORMATION_REQUEST,
    function prepare(payload: IwriteTechnicalInformationPayloadReq) {
        return {
            payload,
        }
    }
)

export const writeMultipleTechnicalInformationRequest = createAction(
    WRITE_MULTIPLE_TECHNICAL_INFORMATION_REQUEST,
    function prepare(payload: IwriteMultipleTechnicalInformationPayloadReq) {
        return {
            payload,
        }
    }
)

export const setTechnicalInformationRequest = createAction(
    SET_TECHNICAL_INFORMATION_REQUEST
)
