import React from 'react'
// import debounce from "lodash/debounce";
import Fuse from 'fuse.js'

import { debounce } from 'lodash'

import FilterByMultipleWithSearchUIDesktop from './filterTimelineByMultipleWSearchUIDesktop'

export type Props = {
    user_searchable_labels_list: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    issearchactive?: boolean | undefined
    addLabelButton?: boolean
    onSave?: boolean
    hasCreateNewLabel?: boolean
    selectedFilteringLabels: string[]
    dataCyIdSave?: string
}

// not sure why now threw error. Removed specifications and error free.
// keeping this here in case .

// export type ItemsFuse = {
//   id: string;
//   value: string;
// };

// const options: Fuse.FuseOptions<ItemsFuse> = {
//   tokenize: true,
//   keys: ["value"]
// };

type LocalState = {
    data: any[]
    categoryData: any[]
    labelsDataRes: any[]
    name: any[]
    fetching: boolean
}

class FilterTimelineByMultipleWSearchWFuse extends React.Component<
    Props,
    LocalState
> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        this.fetchItem = debounce(this.fetchItem, 180)
    }

    state = {
        data: [],
        categoryData: [],
        labelsDataRes: [],
        name: [],
        fetching: false,
    }

    lastFetchId = 0

    fetchItem = (name: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
        }

        const fuseLabels = new Fuse(this.props.user_searchable_labels_list, {
            keys: ['name'],
        })

        const resultsLabel = fuseLabels.search(name, { limit: 2 })

        this.setState({
            ...this.state,
            labelsDataRes: resultsLabel,
            fetching: false,
        })

        return
    }

    handleChange = (name: any) => {
        this.setState({
            ...this.state,
            name: [name],
            data: [],
            categoryData: [],
            labelsDataRes: [],
            fetching: false,
        })
        //this.props.handleSelectChange && this.props.handleSelectChange(name)
    }

    clearField = () => {
        this.setState({
            ...this.state,
            name: [],
            data: [],
            categoryData: [],
            labelsDataRes: [],
            fetching: false,
        })
    }

    render() {
        const {
            //fetching,
            name,
            labelsDataRes,
            // categoryData,
            // data
        } = this.state
        const {
            //           allowAdd,
            //           isDisabled,
            //           placeholder,
            // addCategoryFilter,
            // addTagFilter,
            dataCyId,
            addLabelButton,
            user_searchable_labels_list,
            onSave,
            hasCreateNewLabel,
            selectedFilteringLabels,
            dataCyIdSave,
        } = this.props

        return (
            <FilterByMultipleWithSearchUIDesktop
                dataCyId={dataCyId}
                user_searchable_labels_list={user_searchable_labels_list}
                value={name}
                handleChange={this.handleChange}
                fetchItem={this.fetchItem}
                // categoryData={categoryData}
                labelsDataRes={labelsDataRes}
                addLabelButton={addLabelButton}
                onSave={onSave}
                hasCreateNewLabel={hasCreateNewLabel}
                clearField={this.clearField}
                selectedFilteringLabels={selectedFilteringLabels}
                dataCyIdSave={dataCyIdSave}
            />
        )
    }
}

export default FilterTimelineByMultipleWSearchWFuse
