export const SEARCH_PLATFORM_USERS_SUCCESS =
    '@@search_platform_users/get/SUCCESS'
export const SEARCH_PLATFORM_USERS_REQUEST =
    '@@search_platform_users/get/REQUEST'
export const SEARCH_PLATFORM_USERS_ERROR = '@@search_platform_users/get/ERROR'

export const CHECK_USER_EMAIL_EXISTS_REQUEST =
    '@@check_user_email_exists/get/REQUEST'
export const CHECK_USER_EMAIL_EXISTS_ERROR =
    '@@check_user_email_exists/get/ERROR'
export const CHECK_USER_EMAIL_EXISTS_SUCCESS =
    '@@check_user_email_exists/get/SUCCESS'

export const CLEAN_UP_SEARCH_USERS_SUCCESS = '@@search_users/clean_up/SUCCESS'
