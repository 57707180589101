import { I_getUserArchives_api_req } from '../../services/archive/getUserArchives'

export const ConvertGetUserArchivesAPIReqToURL = (
    p: I_getUserArchives_api_req
): string => {
    let url = `?offset=${p.offset}&limit=${p.limit}&sorting=${p.sorting}`
    url = encodeURI(url)

    return url
}
