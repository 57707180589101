import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const EngineSizeIcon = ({ size, strokeColor, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M28.1604 14.0995L21.0894 7.02844L16.8467 11.2711C16.4562 11.6616 16.4562 12.2948 16.8467 12.6853L17.6507 13.4893C17.8117 13.6502 18.0408 13.7231 18.2652 13.6846C20.1734 13.3571 21.8318 15.0155 21.5043 16.9237C21.4658 17.1481 21.5386 17.3772 21.6996 17.5382L22.5036 18.3422C22.8941 18.7327 23.5273 18.7327 23.9178 18.3422L28.1604 14.0995Z"
                fill={color ?? colours[theme].icons_neutral_250}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.9177 6.32133L18.2609 11.9782L18.4368 12.1541C21.0403 11.9631 23.2258 14.1485 23.0347 16.752L23.2106 16.9279L28.8675 11.2711L23.9177 6.32133ZM24.6248 4.90712C24.2343 4.51659 23.6011 4.51659 23.2106 4.90712L16.8467 11.2711C16.4561 11.6616 16.4561 12.2948 16.8467 12.6853L17.6506 13.4893C17.8116 13.6502 18.0407 13.7231 18.2651 13.6846C20.1733 13.3571 21.8317 15.0155 21.5042 16.9237C21.4657 17.1481 21.5386 17.3772 21.6995 17.5382L22.5035 18.3421C22.894 18.7327 23.5272 18.7327 23.9177 18.3421L30.2817 11.9782C30.6722 11.5877 30.6722 10.9545 30.2817 10.564L24.6248 4.90712Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2485 13.3869C19.9593 12.7171 21.0738 12.7337 21.7644 13.4243C22.455 14.1149 22.4715 15.2293 21.8017 15.9401L14.6846 23.4925C14.4129 23.7808 14.3064 24.2173 14.1554 24.9061C14.0268 25.4927 13.734 26.0509 13.2791 26.5057C12.0099 27.7749 9.95213 27.7749 8.68293 26.5057C7.41373 25.2365 7.41373 23.1787 8.68293 21.9095C9.13778 21.4547 9.69596 21.1619 10.2825 21.0333C10.9713 20.8822 11.4078 20.7758 11.6962 20.5041L19.2485 13.3869ZM20.7037 14.4849C20.5867 14.3679 20.3978 14.3651 20.2773 14.4786L12.7249 21.5957C12.1131 22.1723 11.2795 22.3524 10.7078 22.4759C10.6721 22.4836 10.6374 22.4911 10.6038 22.4985C10.2889 22.5675 9.98974 22.7241 9.74359 22.9702C9.06017 23.6536 9.06017 24.7617 9.74359 25.4451C10.427 26.1285 11.535 26.1285 12.2185 25.4451C12.4646 25.1989 12.6212 24.8997 12.6902 24.5848C12.6976 24.5513 12.7051 24.5166 12.7128 24.4809C12.8363 23.9091 13.0164 23.0755 13.5929 22.4637L20.7101 14.9114C20.8236 14.7909 20.8208 14.602 20.7037 14.4849Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5772 7.02842L4.5061 14.0995L8.74874 18.3421C9.13927 18.7327 9.77243 18.7327 10.163 18.3421L10.9669 17.5382C11.1279 17.3772 11.2007 17.1481 11.1622 16.9237C10.8348 15.0155 12.4931 13.3571 14.4014 13.6845C14.6257 13.723 14.8549 13.6502 15.0158 13.4892L15.8198 12.6853C16.2103 12.2947 16.2103 11.6616 15.8198 11.2711L11.5772 7.02842Z"
                fill={color ?? colours[theme].icons_neutral_250}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.79905 11.2711L9.45591 16.9279L9.63181 16.752C9.44078 14.1485 11.6262 11.963 14.2298 12.1541L14.4057 11.9782L8.7488 6.32131L3.79905 11.2711ZM2.38484 10.564C1.99432 10.9545 1.99432 11.5876 2.38484 11.9782L8.7488 18.3421C9.13933 18.7326 9.77249 18.7326 10.163 18.3421L10.967 17.5382C11.128 17.3772 11.2008 17.1481 11.1623 16.9237C10.8348 15.0155 12.4932 13.3571 14.4014 13.6845C14.6258 13.723 14.8549 13.6502 15.0159 13.4892L15.8199 12.6853C16.2104 12.2947 16.2104 11.6616 15.8199 11.2711L9.45591 4.9071C9.06538 4.51657 8.43222 4.51657 8.0417 4.9071L2.38484 10.564Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.8648 15.9401C10.195 15.2293 10.2115 14.1149 10.9021 13.4243C11.5927 12.7337 12.7072 12.7171 13.418 13.3869L16.8337 16.6058L15.8049 17.6974L12.3892 14.4786C12.2687 14.365 12.0798 14.3679 11.9628 14.4849C11.8457 14.602 11.8429 14.7909 11.9564 14.9114L15.0595 18.2042L13.9679 19.233L10.8648 15.9401ZM16.5066 19.4155C17.2467 18.4459 18.6634 18.3301 19.5512 19.1667L20.9703 20.5041C21.2587 20.7758 21.6952 20.8822 22.384 21.0333C22.9705 21.1619 23.5287 21.4547 23.9836 21.9095C25.2528 23.1787 25.2528 25.2365 23.9836 26.5057C22.7144 27.7749 20.6566 27.7749 19.3874 26.5057C18.9325 26.0509 18.6397 25.4927 18.5111 24.9061C18.3601 24.2173 18.2537 23.7808 17.9819 23.4925L16.6446 22.0734C15.9541 21.3407 15.8957 20.2158 16.5066 19.4155ZM18.5225 20.2584C18.2823 20.0321 17.8991 20.0634 17.6989 20.3257C17.5337 20.5421 17.5494 20.8464 17.7362 21.0446L19.0736 22.4637C19.6501 23.0755 19.8302 23.9091 19.9537 24.4809C19.9614 24.5166 19.9689 24.5513 19.9763 24.5848C20.0453 24.8997 20.2019 25.1989 20.448 25.4451C21.1315 26.1285 22.2395 26.1285 22.9229 25.4451C23.6063 24.7617 23.6063 23.6536 22.9229 22.9702C22.6768 22.724 22.3776 22.5675 22.0627 22.4985C22.0291 22.4911 21.9944 22.4836 21.9587 22.4759C21.387 22.3524 20.5534 22.1723 19.9416 21.5957L18.5225 20.2584Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
        </svg>
    )
}

export default EngineSizeIcon
