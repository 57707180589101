import { INormalisedAttachmentsByID } from 'attachmentModels'
import * as React from 'react'
import { ITimelineItem } from 'timelineModels'
import BubbleCardWithHorizontalLine from '../../atoms/bubbleCards/bubbleCardWithHorizontalLine'
import TimelineItemBodyDesktop from './timelineItemBodyDesktop'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { RootState } from 'typesafe-actions'
import { ITechnicalInformationObject } from 'entityModels'
import BubbleCard from '../../atoms/bubbleCards/bubbleCard'

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {
        currentCarID: state.entities.carsData.currentCarID,
        userDistanceUnit:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences.data.units.data.distance_unit
                .user_choice_id,
        technicalInformationData:
            state.entities.technicalInformationData.technical_information,
    }
}

interface Props extends RouteComponentProps<any> {
    currentCarID?: string
    item: ITimelineItem
    onClick?: any
    dataCyId?: string
    attachmentsObj?: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    lineOnRight?: boolean
    lineOnLeft?: boolean
    isCarOverview?: boolean
    hasTootip?: boolean
    userCurrency: string | undefined
    hasSampleData?: boolean
    technicalInformationData?: ITechnicalInformationObject
    userDistanceUnit: string | null
    isFirst?: boolean
    isLast?: boolean
}

type State = { showTooltipLeft: boolean; showTooltipRight: boolean }

class TimelineItemDesktop extends React.Component<Props, State> {
    state = { showTooltipLeft: false, showTooltipRight: false }
    toggleTooltip = (side: 'left' | 'right') => {
        if (side === 'left') {
            this.setState({ showTooltipLeft: true })
            setTimeout(() => {
                this.setState({ showTooltipLeft: false })
            }, 2000)
        } else {
            this.setState({ showTooltipRight: true })
            setTimeout(() => {
                this.setState({ showTooltipRight: false })
            }, 2000)
        }
    }
    render() {
        const {
            item,
            dataCyId,
            onClick,
            lineOnRight,
            lineOnLeft,
            userCurrency,
            hasSampleData,
            isCarOverview,
            isFirst,
            isLast,
        } = this.props

        return (
            <div
                style={{
                    display: 'flex',
                    minWidth: 0,
                    flex: isCarOverview ? 1 : undefined,
                }}
            >
                {lineOnRight ? (
                    <BubbleCard
                        bubleSide="left"
                        category={item.categoryID}
                        hasSampleData={hasSampleData}
                        key={`${item.id}-entry`}
                        dataCyId={dataCyId && dataCyId}
                        onClick={() => {
                            if (isFirst && hasSampleData) {
                                this.props.history.push({
                                    pathname: `/journey/history-file`,
                                    search: `?step=welcome&carid=${this.props.currentCarID}`,
                                    state: {
                                        prevPath: `${
                                            this.props.history.location.pathname
                                        }${
                                            this.props.history.location
                                                .search ?? ''
                                        }`,
                                    },
                                })
                            } else {
                                onClick && onClick()
                            }
                        }}
                    >
                        <TimelineItemBodyDesktop
                            item={item}
                            onLeft
                            isFirst={isFirst}
                            userCurrency={userCurrency}
                            hasSampleData={hasSampleData}
                        />
                    </BubbleCard>
                ) : lineOnLeft ? (
                    <BubbleCard
                        bubleSide="right"
                        category={item.categoryID}
                        hasSampleData={hasSampleData}
                        key={`${item.id}-entry`}
                        dataCyId={dataCyId && dataCyId}
                        onClick={() => {
                            if (hasSampleData) {
                                this.props.history.push(
                                    `/car/${this.props.currentCarID}/history-file/create`
                                )
                            } else if (onClick) {
                                onClick()
                            }
                        }}
                    >
                        <TimelineItemBodyDesktop
                            item={item}
                            onRight
                            userCurrency={userCurrency}
                            hasSampleData={hasSampleData}
                        />
                    </BubbleCard>
                ) : (
                    <BubbleCardWithHorizontalLine
                        key={`${item.id}-entry`}
                        dataCyId={dataCyId && dataCyId}
                        category={item.categoryID}
                        onClick={() => {
                            if (hasSampleData) {
                                this.props.history.push(
                                    `/car/${this.props.currentCarID}/history-file/create`
                                )
                            } else if (onClick) {
                                onClick()
                            }
                        }}
                        isFirst={isFirst}
                        isLast={isLast}
                    >
                        <TimelineItemBodyDesktop
                            item={item}
                            onRight
                            isFirst={isFirst}
                            isCarOverview
                            userCurrency={userCurrency}
                            hasSampleData={hasSampleData}
                        />
                    </BubbleCardWithHorizontalLine>
                )}
            </div>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TimelineItemDesktop)
)
