import { ITimelineEditForms } from 'myModels'
import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";

import { connect } from 'react-redux'
import {
    ICostItem,
    ITimelineFieldsIdsList,
    ITimelineItem,
} from 'timelineModels'

import { RootState } from 'typesafe-actions'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'

import { TimelineFormTypeIDsEnum } from '../../../../redux/timeline/timelineEnum'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'

import { INormalisedAttachmentsByID } from 'attachmentModels'
import EditModeTopHeaderDesktop from '../../../atoms/header/editModeTopHeader/editModeTopHeaderDesktop'
import Faded from '../../../templates/animated/faded'

import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'
import BottomFixedContainer from '../../../templates/windowPageTemplate/bottomFixedContainer'
import EditModeFooterDesktop from '../../../atoms/footer/editModeFooterDesktop'
import styled from 'styled-components'
import InputField from '../../../atoms/Inputfield/inputField'
import CalendarIcon from '../../../atoms/icons/components/calendar'
import dayjs from 'dayjs'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'
import colours, { ITheme } from '../../../../providers/theme/colours'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 150px;
`

function mapStateToProps(state: RootState) {
    return {
        fieldsList: state.timeline.formsData.fieldsList,
        attachmentsObj: state.attachments.attachmentsById,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
        deletionInProgress: state.timeline.deletionInProgress,
    }
}

// const dispatchProps = {}

export interface Props {
    item_to_edit: ITimelineItem | ICostItem
    edited_item: ITimelineItem | ICostItem | null
    timelineItemEditForm: ITimelineEditForms
    fieldsList: ITimelineFieldsIdsList
    attachmentsObj: INormalisedAttachmentsByID
    onClickSave: () => void
    onDeleteClick?: () => void
    onChange: (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => void
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    userCurrency: string | undefined
    readOnlyMode?: boolean
    isCollapsed: boolean
    deletionInProgress: boolean
}

class AnyObj {
    [key: string]: any
}

type State = {
    isHeaderActive: boolean
    errorObject: AnyObj
}

type IisMandatoryFieldObj = {
    [key: string]: boolean
}

class TimelineGenerateEditFormModeDesktop extends React.PureComponent<
    Props,
    State
> {
    state = {
        isHeaderActive: false,
        // we create an obj with fieldIDs as key and whether rn there's an error ( true | false)
        errorObject: new AnyObj(),
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        this.setState({ isHeaderActive: false })
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.edited_item !== prevProps.edited_item) {
            this.checkIfDataHasBeenChanged()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
        this.setState({ isHeaderActive: false })
    }

    checkIfDataHasBeenChanged = (): boolean => {
        let obj1 = this.props.item_to_edit
        let obj2 = this.props.edited_item

        if (obj2 == null) {
            return false
        } else {
            const obj1Length = Object.keys(obj1).length
            const obj2Length = Object.keys(obj2).length

            if (obj1Length === obj2Length) {
                let n = Object.keys(obj1).every(
                    (key) =>
                        obj2 !== null &&
                        obj2.hasOwnProperty(key) &&
                        obj2[key] === obj1[key]
                )

                this.setState({
                    ...this.state,
                    isHeaderActive: !n,
                })
                return !n
            }
            this.setState({
                ...this.state,
                isHeaderActive: true,
            })
            return true
        }
    }

    generatePlaceholder = (fieldId: string): string => {
        let { item_to_edit } = this.props

        if (
            timeline_category_data[item_to_edit.categoryID] &&
            timeline_category_data[item_to_edit.categoryID].placeholders
        ) {
            let placeholders =
                timeline_category_data[item_to_edit.categoryID].placeholders

            return placeholders ? placeholders[fieldId] : ''
        } else return ''
    }

    isMandatoryFieldObj: IisMandatoryFieldObj = {
        name: true,
        title: true,
        amount: true,
    }

    determineErrors = (fieldId: string, error: boolean) => {
        const errorObject: any = this.state.errorObject
        errorObject[fieldId] = error
        this.setState({ ...this.state, errorObject: errorObject })
    }

    generateFields = (fieldId: string, theme: ITheme) => {
        let { item_to_edit, onChange, attachmentsObj, readOnlyMode } =
            this.props

        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start:
                fieldId === 'attachment' ||
                fieldId === 'attachment_with_preview'
                    ? attachmentsObj[item_to_edit.attachmentID]
                        ? attachmentsObj[item_to_edit.attachmentID]
                        : undefined
                    : item_to_edit[fieldId],
            title: `${fieldId}`,
            onChangeFunc: onChange,
            id: fieldId,
            placeholder: this.generatePlaceholder(fieldId),
            isMandatory: this.isMandatoryFieldObj[fieldId]
                ? this.isMandatoryFieldObj[fieldId]
                : false,
        }

        let hasError = (error: boolean) => {
            this.determineErrors(fieldId, error)
        }

        switch (fieldId) {
            case 'title': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )
            }

            case 'name': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )
            }

            case 'company':
                data.charLimit = [0, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )

            case 'amount': {
                data.optionName = item_to_edit.currency
                    ? item_to_edit.currency
                    : this.props.userCurrency
                    ? this.props.userCurrency
                    : 'GBP'
                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                        isDisabled={readOnlyMode}
                    />
                )
            }

            case 'date':
                // input field type = date, name: DATE PICKER DatePickerEditModeSingleFieldMobile
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        isDisabled={readOnlyMode}
                    />
                )

            case 'description':
                data.charLimit = [0, 450]
                return (
                    <div style={{ paddingTop: '20px' }}>
                        <FieldChoices.TextareaEditOrCreateModeSingleFieldDesktop
                            key={`${fieldId}+${item_to_edit.id}`}
                            data={data}
                            dataCyId={`edit_${fieldId}_field`}
                            placeholder={data.placeholder}
                            hasError={hasError}
                            isDisabled={readOnlyMode}
                        />
                    </div>
                )

            case 'attachment':
                return (
                    <FieldChoices.WrapperAttachmentFormDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                    />
                )

            case 'notes':
                // later
                return (
                    <FieldChoices.EntryNotesFormDesktop
                        key={`${fieldId}+${item_to_edit.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        isDisabled={readOnlyMode}
                    />
                )

            case 'insurance_entry_broker': {
                data.isMandatory = false
                data.charLimit = [2, 100]
                data.title = 'Broker (if applicable)'
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={`${fieldId}+${item_to_edit.id}`}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.isMandatory = true
                data.charLimit = [2, 100]
                data.title = 'Insurer'

                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldDesktop
                        data={data}
                        key={`${fieldId}+${item_to_edit.id}`}
                        dataCyId={`edit_${fieldId}_field`}
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_policy_start_date':
                data.title = 'Policy start date'
                // input field type = date, name: DATE PICKER DatePickerEditModeSingleFieldMobile

                return (
                    <div
                        style={{
                            width: '70%',
                            maxWidth: '460px',
                            paddingTop: '30px',
                        }}
                    >
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_start_date"
                            name="Policy start date"
                            placeholder={'Policy start date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            // helperText="Type or select a date (yyyy/mm/dd)"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={data.value_start}
                            value={
                                this.props.edited_item
                                    ?.insurance_entry_policy_start_date
                            }
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                this.props.onChange(
                                    'insurance_entry_policy_start_date',
                                    dateString
                                )

                                setTimeout(() => {
                                    let newavall = dayjs(
                                        new Date(dateString)
                                    ).isValid()
                                        ? dayjs(new Date(dateString)).add(
                                              1,
                                              'year'
                                          )
                                        : null
                                    this.props.onChange(
                                        'insurance_entry_policy_end_date',
                                        newavall
                                    )
                                }, 50)
                            }}
                            type="date"
                            // width="100%"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </div>
                )

            case 'insurance_entry_policy_end_date':
                // input field type = date, name: DATE PICKER DatePickerEditModeSingleFieldMobile

                return (
                    <div
                        style={{
                            width: '70%',
                            maxWidth: '460px',
                            paddingTop: '30px',
                            paddingBottom: '10px',
                        }}
                    >
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_end_date"
                            name="'Policy end date'"
                            placeholder={'Policy end date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            // helperText="Type or select a date (yyyy/mm/dd)"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={data.value_start}
                            value={
                                this.props.edited_item
                                    ?.insurance_entry_policy_end_date
                            }
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                this.props.onChange(
                                    'insurance_entry_policy_end_date',
                                    dateString
                                )
                            }}
                            type="date"
                            // width="100%"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </div>
                )

            default:
                return null
        }
    }

    render() {
        let {
            item_to_edit,
            fieldsList,
            timelineItemEditForm,
            onClickSave,
            closeForm,
            onDeleteClick,
            dataCyId,
            isCollapsed,
            deletionInProgress,
        } = this.props

        const checkIfSaveIsDisabled = () => {
            let arr: undefined | string[] = field_ids_general_form
                ? field_ids_general_form
                : field_ids_form
                ? field_ids_form
                : undefined

            if (arr) {
                for (const id of arr) {
                    let str = id as keyof AnyObj

                    let form_field_data =
                        this.props.edited_item && this.props.edited_item[id]
                            ? this.props.edited_item[id]
                            : undefined

                    if (this.state.errorObject[str] === true) {
                        // error is true

                        return true
                    }

                    if (
                        form_field_data &&
                        form_field_data.length === 0 &&
                        this.state.errorObject[str] === undefined &&
                        this.isMandatoryFieldObj[id] === true
                    ) {
                        // mandatory pb

                        return true
                    }

                    if (
                        !form_field_data &&
                        this.isMandatoryFieldObj[id] === true
                    ) {
                        // mandatory pb

                        return true
                    }
                }

                //should be nope
                return false
            }

            return false
        }

        let field_ids_general_form: undefined | string[] =
            timelineItemEditForm.id &&
            timelineItemEditForm.id === TimelineFormTypeIDsEnum.general &&
            item_to_edit &&
            fieldsList &&
            timelineItemEditForm.category
                ? fieldsList.fieldsListByCategory[
                      timelineItemEditForm.category
                  ].map((fieldID: string, index: number) => {
                      return fieldID
                  })
                : undefined

        let field_ids_form: undefined | string[] =
            timelineItemEditForm.id &&
            timelineItemEditForm.id !== TimelineFormTypeIDsEnum.general &&
            item_to_edit &&
            fieldsList
                ? fieldsList.fieldsListByType[timelineItemEditForm.id].map(
                      (fieldID: string, index: number) => {
                          return fieldID
                      }
                  )
                : undefined

        return (
            <ThemesContext.Consumer>
                {({ theme: theme }) => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Faded>
                            <EditModeTopHeaderDesktop
                                headerText={
                                    timelineItemEditForm.id === 'general'
                                        ? 'Entry'
                                        : timelineItemEditForm.id === 'cost'
                                        ? 'Cost'
                                        : timelineItemEditForm.id === 'notes'
                                        ? 'Notepad'
                                        : timelineItemEditForm.id ===
                                          'insurance_fields'
                                        ? 'Insurance details'
                                        : ''
                                }
                                noBtns
                                removeHorizTopPaddings
                            />

                            <Wrapper data-attr={dataCyId}>
                                {/* padding left and right wrapper */}
                                {/* general category item form is below is here */}
                                {field_ids_general_form &&
                                    field_ids_general_form.map(
                                        (fieldID: string, index: number) => {
                                            return this.generateFields(
                                                fieldID,
                                                theme
                                            )
                                        }
                                    )}
                                {/* example : cost form (as of now) */}

                                {field_ids_form &&
                                    field_ids_form.map(
                                        (fieldID: string, index: number) => {
                                            return this.generateFields(
                                                fieldID,
                                                theme
                                            )
                                        }
                                    )}
                            </Wrapper>
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    backgroundColor:
                                        colours[theme].background_default,
                                }}
                            >
                                <BottomFixedContainer>
                                    <EditModeFooterDesktop
                                        onCancel={closeForm}
                                        onSave={() => {
                                            onClickSave()
                                            this.setState({
                                                ...this.state,
                                                isHeaderActive: false,
                                            })
                                        }}
                                        onDelete={onDeleteClick}
                                        isActive={this.state.isHeaderActive}
                                        isSaveDisabled={checkIfSaveIsDisabled()}
                                        dataCyIdSave={
                                            timelineItemEditForm.id === 'cost'
                                                ? 'save_edited_cost_desktop'
                                                : 'save_edited_entry_desktop'
                                        }
                                        dataCyIdCancel={
                                            timelineItemEditForm.id === 'cost'
                                                ? 'cancel_edited_cost_desktop'
                                                : 'cancel_edit_entry_desktop'
                                        }
                                        dataCyIdDelete="delete_cost_desktop"
                                        isCollapsed={isCollapsed}
                                        deletionInProgress={deletionInProgress}
                                    />
                                </BottomFixedContainer>
                            </div>
                        </Faded>
                    </div>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default connect(mapStateToProps, {})(TimelineGenerateEditFormModeDesktop)

// SelectSearchBarCarInfoDesktop
