import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'

import * as requestActions from '../actions/requestActions'
import * as successActions from '../actions/successActions'
import * as errorActions from '../actions/errorActions'

import * as userActions from '../../user/actions/actions'
import * as carActions from '../../entities/cars/actions'

import {
    ICustomErrorData,
    IDeleteTaskPayload,
    IReduxError,
    ITasksNormalisedByCar,
} from 'entityModels'

import { push } from 'connected-react-router'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { RootState } from 'typesafe-actions'

// GET TASK BY TASK ID

const getCarError = (state: RootState) => state.entities.carsData.error
const getCarsTasksDataInState = (state: RootState): ITasksNormalisedByCar =>
    state.entities.carsData.tasks

function* deleteTaskSaga(payload: IDeleteTaskPayload): any {
    // later on : put API TRY CATH

    try {
        yield call(api.tasks.deletion.deleteTask, payload.task.id)

        yield put(successActions.deleteTaskSuccess(payload.task.id))

        yield put(userActions.updateUserTasksCountOnTasksDeletion())

        if (payload.car_id) {
            yield put(push(`/car/${payload.car_id}/tasks`))
        } else {
            yield put(push(`/tasks`))
        }

        let carsTasksDataInState = yield select(getCarsTasksDataInState)

        if (payload.task.linkedToCar) {
            for (const id of payload.task.linkedToCar) {
                if (carsTasksDataInState[id]) {
                    if (carsTasksDataInState[id].includes(payload.task.id)) {
                        let new_arr = carsTasksDataInState[id].filter(
                            (taskid: string) => taskid !== payload.task.id
                        )
                        yield put(
                            carActions.actions.updateCarTasksArrayOnTasksCreationOrDeletion(
                                { car_id: id, tasks_ids: new_arr }
                            )
                        )
                    }
                }
            }
        }

        // Capture event
        posthog.capture('DELETE TASK')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: deleteTaskSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let carError: IReduxError = yield select(getCarError)
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't delete the task`,
                custom_user_action_text: 'Return to tasks',
                custom_redirect_path:
                    payload.car_id && !carError
                        ? `/car/${payload.car_id}/tasks`
                        : '/tasks',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.deleteTaskError(customError))
        }
    }
}

function* watcherDeleteTask() {
    while (true) {
        const { payload } = yield take(requestActions.deleteTaskRequest)

        yield call(deleteTaskSaga, payload)
    }
}

const delete_task: any[] = [fork(watcherDeleteTask)]

export default delete_task
