import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ICheckboxListItemProps } from '../filterBottomSheetItem/filterBottomSheetItemMobile'
import CustomAnimatedCheckboxField from './customAnimatedCheckboxField'
import email_enquiry from '../../../public/assets/icons/email_enquiry.svg'
import phone from '../../../public/assets/icons/phone.svg'
import message from '../../../public/assets/icons/message.svg'
import mobile from '../../../public/assets/icons/mobile.svg'
import platform from '../../../public/assets/icons/platform.svg'
import post from '../../../public/assets/icons/post.svg'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyled = {
    $isDisabled?: boolean
    $borderColor?: string | undefined
    $customActiveColour?: string
    $customHighlightColour?: string
    $isActive?: boolean | undefined
    $theme: ITheme
}

const Row = styled.div<IStyled>`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    background-color: ${(props: IStyled) =>
        props.$isActive && props.$borderColor
            ? props.$customHighlightColour ?? 'rgba(94, 195, 202, 0.04)'
            : colours[props.$theme].background_neutral_subtle};
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 16px;
    border: ${(props: IStyled) =>
        props.$isActive && props.$borderColor
            ? `1px solid ${
                  props.$customActiveColour ?? colours[props.$theme].primary
              }`
            : props.$borderColor
            ? `1px solid ${props.$customActiveColour ?? props.$borderColor}`
            : 'none'};
    border-radius: ${(props: IStyled) => (props.$borderColor ? `4px` : 'none')};

    :hover {
        background: ${(props: IStyled) =>
            props.$borderColor
                ? props.$customHighlightColour ?? `rgba(94, 195, 202, 0.04)`
                : 'auto'};

        /* Core/Teal/500 */
        border: ${(props: IStyled) =>
            props.$borderColor
                ? `1px solid ${
                      props.$customActiveColour ?? colours[props.$theme].primary
                  }`
                : 'none'};
    }

    cursor: pointer;
`
const InfoTxt = styled(motion.div)<IStyled>`
    font-family: Lato;
    width: 100%;
    font-size: 16px;
    padding-right: 10px;
    color: ${(props: IStyled) =>
        props.$isDisabled
            ? colours[props.$theme].text_muted
            : colours[props.$theme].text_default};

    cursor: ${(props: IStyled) =>
        props.$isDisabled ? 'not-allowed' : 'pointer'};
`

const Icon = styled.img`
    margin-left: 17px;
    margin-right: 17px;
`

export default function CheckboxWithIconRowItem(props: ICheckboxListItemProps) {
    const {
        checked,
        name,
        isDisabled,
        onChange,
        text,
        borderColor,
        customActiveColour,
        customHighlightColour,
    } = props

    const { theme } = useThemes()

    return (
        <Row
            $theme={theme}
            onClick={() => {
                onChange(name)
            }}
            $borderColor={customActiveColour ?? borderColor}
            $isActive={checked}
            $customHighlightColour={customHighlightColour}
            $customActiveColour={customActiveColour}
        >
            {props.iconID === 'email' ? (
                <Icon src={email_enquiry} alt="email" />
            ) : null}

            {props.iconID === 'phone' ? <Icon src={phone} alt="phone" /> : null}

            {props.iconID === 'message' ? (
                <Icon src={message} alt="message" />
            ) : null}
            {props.iconID === 'mobile' ? (
                <Icon src={mobile} alt="mobile" />
            ) : null}
            {props.iconID === 'platform' ? (
                <Icon src={platform} alt="platform" />
            ) : null}
            {props.iconID === 'post' ? <Icon src={post} alt="post" /> : null}

            <InfoTxt
                $theme={theme}
                style={{ transform: 'translateY(-2px)' }}
                $isDisabled={isDisabled}
            >
                {text}
            </InfoTxt>

            <CustomAnimatedCheckboxField
                small
                width="auto"
                hasWhiteBg={props.borderColor ? false : true}
                borderColor={props.borderColor}
                onChange={() => {
                    onChange(name)
                }}
                name={name}
                checked={checked}
                onClick={() => {}}
                isDisabled={isDisabled}
                customActiveColour={customActiveColour}
            ></CustomAnimatedCheckboxField>
        </Row>
    )
}
