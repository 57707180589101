import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const SharingNavIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1818 10.0834C8.57189 10.0834 7.29547 11.3554 7.29547 12.8889V16.4445C7.29547 17.978 8.57189 19.25 10.1818 19.25H18.3637C19.9736 19.25 21.25 17.978 21.25 16.4445V12.8889C21.25 12.057 20.8781 11.3065 20.2784 10.7893L19.7104 10.2995L20.6901 9.16359L21.258 9.65343C22.1692 10.4393 22.75 11.5964 22.75 12.8889V16.4445C22.75 18.8381 20.7701 20.75 18.3637 20.75H10.1818C7.77542 20.75 5.79547 18.8381 5.79547 16.4445V12.8889C5.79547 10.4954 7.77542 8.58337 10.1818 8.58337H14.5682V10.0834H10.1818Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.63636 4.75C4.02642 4.75 2.75 6.02201 2.75 7.55556V11.1111C2.75 11.943 3.12195 12.6935 3.72165 13.2107L4.2896 13.7006L3.30993 14.8364L2.74198 14.3466C1.83078 13.5607 1.25 12.4036 1.25 11.1111V7.55556C1.25 5.16199 3.22995 3.25 5.63636 3.25H13.8182C16.2246 3.25 18.2045 5.16199 18.2045 7.55556V11.1111C18.2045 13.5047 16.2246 15.4167 13.8182 15.4167H9.43182V13.9167H13.8182C15.4281 13.9167 16.7045 12.6447 16.7045 11.1111V7.55556C16.7045 6.02201 15.4281 4.75 13.8182 4.75H5.63636Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default SharingNavIcon
