import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
    IEntityPrivateOwnedSharesState,
    IDirectShareOwnedItem,
    ISharingPermissionIDS,
    IGarage,
    ISearchUsersResult,
    ISortShareRecipientsDisplay_change_request,
    IUpdateGarageDirectShareRequest,
} from 'entityModels'
import { IDirectShareDeletionConfirmation, IUser } from 'myModels'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
// import CreationTopHeaderDesktop from '../../molecules/entryPageTopHeader/creationTopHeaderDesktop'
import PrivateShareSearchFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareSearchFormDesktop'
import PrivateShareFormManagerDesktop from '../../organisms/shareForms/privateShare/privateShareFormManagerDesktop'
import EditModeTopHeaderDesktop from '../../atoms/header/editModeTopHeader/editModeTopHeaderDesktop'
// import { doArraysContainDifferentElements } from '../../../helpers/arrayChecks/do2ArrayContainDifferentElements'
import PrivateShareFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareFormManagerMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import SwitchTabMenuDesktop from '../../atoms/Button/switchTabMenuDesktop'
import ShareRowDesktop from '../../atoms/shareRow/shareRowDesktop'

// import AddNewNaked from '../../atoms/Button/addNewNaked'
import styled from 'styled-components'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import AddShareByEmailFormDesktop from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormDesktop'
import PrivateShareReturnNav from '../../atoms/header/privateShareReturnNav'
import SwitchTabMenuMobile from '../../atoms/Button/switchTabMenuMobile'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import AddShareByEmailFormMobile from '../../organisms/editForms/addShareByEmailForm/addShareByEmailFormMobile'
import { device } from '../../templates/displays/devices'
import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import PrivateSharesSortingMobile from '../../atoms/menu/bottomSheetMenu/privateSharesSortingMobile'
import GreyIconButton from '../../atoms/Button/GreyIconButton'
import {
    addGarageShareRecipientRequest,
    deleteGarageDirectShareRequest,
    deleteGarageShareInviteRequest,
    deleteGarageShareRecipientRequest,
    getGarageByUserIdRequest,
    getSingleGarageDirectShareRequest,
    resendGarageShareInviteRequest,
    sortGarageShareRecipientsDisplay_change_request,
    updateGarageDirectShareRequest,
} from '../../../redux/entities/garages/actions/loadingActions'
import ShareRowMobile from '../../atoms/shareRow/shareRowMobile'
import { checkUserEmailExistsRequest } from '../../../redux/searchUsers/actions/loadingActions'
import Faded from '../../templates/animated/faded'
import EditModeFooterDesktop from '../../atoms/footer/editModeFooterDesktop'
import {
    IShareRecipientRequest_redux,
    IgetSingleDirectShareRequest_redux,
    IShareInviteDeletion,
    IShareInvitResent,
} from '../../../redux/entities/cars/actions/loadingActions'
import { editFormsActions } from '../../../redux/editForms/reducer'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
// import ComingSoonCard from '../../atoms/comingSoon/comingSoonCard'
import NoIconDataRectangle from '../../atoms/noData/noIconDataRectangle'
import SideSlider from '../../templates/animated/sideSlider'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import PrivateShareSearchManagerMobileV2 from '../../organisms/shareForms/privateShare/privateShareSearchManagerMobileV2'

const DesktopAdjustedWrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`

const MobileWrapper = styled.div`
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    @media ${device.ipad} {
        max-width: 600px;
        padding-left: 30px;
        padding-right: 30px;
    }
`
const SharedWith = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-Bold';
    font-size: 17px;
    padding-bottom: 20px;
`

const Padding10 = styled.div`
    padding-top: 10px;
`
const Padding20 = styled.div`
    padding-top: 20px;
`

const Padding30 = styled.div`
    padding-top: 30px;
`

const Padding40 = styled.div`
    padding-top: 40px;
`

const StickyWrapper = styled.div`
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 4;
    display: flex;
    align-items: flex-end;
    background-color: var(--bg-color, #fff);
    height: 50px;
    transform: translateY(-20px);
    margin-top: 30px;
    @media ${device.mobile_and_ipad} {
        top: 75px;
        margin-top: 15px;
    }
`

const DropdownHoverWrapper = styled.div`
    border-radius: 5px;
    height: 40px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all 200ms;
`

const Title = styled.div`
    font-family: Lato;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 10px;
`

const SubTitle = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-default, #666666);
`

const Padding24 = styled.div`
    padding-top: 24px;
`

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        garage_id:
            state.user.userLoggedIn && state.user.userLoggedIn.owns_garage?.uid,
        isGarageLoading: state.entities.garagesData.loading,
        garageSharesLoading: state.entities.garagesData.sharesLoading,
        garageData: state.entities.garagesData.garages,
        isUserLoading: state.user.loading,
        addShareRecipientByEmailForm:
            state.editForms.formsData &&
            state.editForms.formsData.addShareRecipientByEmailForm &&
            state.editForms.formsData.addShareRecipientByEmailForm.isOpen,

        searchResults: state.searchUsers.list,
        searchResultsLoading: state.searchUsers.loading,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
        directShareDeletionConfirmation:
            state.editForms.directShareDeletionConfirmation,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
    toggleAddShareRecipientByEmail: (payload: { isOpen: boolean }) =>
        editFormsActions.toggleAddShareRecipientByEmail(payload),
    checkUserEmailExistsRequest: (value: string | undefined) =>
        checkUserEmailExistsRequest(value),
    addGarageShareRecipientRequest: (p: IShareRecipientRequest_redux) =>
        addGarageShareRecipientRequest(p),
    getSingleGarageDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => getSingleGarageDirectShareRequest(p),
    deleteGarageDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) =>
        deleteGarageDirectShareRequest(p),
    deleteGarageShareRecipientRequest: (p: IShareRecipientRequest_redux) =>
        deleteGarageShareRecipientRequest(p),
    sortGarageShareRecipientsDisplay_change_request: (
        p: ISortShareRecipientsDisplay_change_request
    ) => sortGarageShareRecipientsDisplay_change_request(p),
    updateGarageDirectShareRequest: (p: IUpdateGarageDirectShareRequest) =>
        updateGarageDirectShareRequest(p),
    toggleShareDeletionConfirmation: (p: IDirectShareDeletionConfirmation) =>
        editFormsActions.toggleShareDeletionConfirmation(p),
    deleteGarageShareInviteRequest: (p: IShareInviteDeletion) =>
        deleteGarageShareInviteRequest(p),
    resendGarageShareInviteRequest: (p: IShareInvitResent) =>
        resendGarageShareInviteRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    garage_id: string | null
    garageData: { [key: string]: IGarage }
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isGarageLoading: boolean
    getUserDataRequest: () => void
    getGarageByUserIdRequest: () => void
    toggleAddShareRecipientByEmail: (payload: { isOpen: boolean }) => void
    addShareRecipientByEmailForm?: boolean | undefined
    checkUserEmailExistsRequest: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    searchResultsLoading: boolean
    isCollapsed: boolean
    addGarageShareRecipientRequest: (p: IShareRecipientRequest_redux) => void
    getSingleGarageDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => void
    deleteGarageDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => void

    deleteGarageShareRecipientRequest: (p: IShareRecipientRequest_redux) => void
    sortGarageShareRecipientsDisplay_change_request: (
        p: ISortShareRecipientsDisplay_change_request
    ) => void

    updateGarageDirectShareRequest: (p: IUpdateGarageDirectShareRequest) => void

    toggleShareDeletionConfirmation: (
        p: IDirectShareDeletionConfirmation
    ) => void
    directShareDeletionConfirmation: IDirectShareDeletionConfirmation
    garageSharesLoading: boolean
    location: any
    deleteGarageShareInviteRequest: (p: IShareInviteDeletion) => void
    resendGarageShareInviteRequest: (p: IShareInvitResent) => void
    setInitialLandingUrl: (str: string) => void
}

type State = {
    share_being_edited: IDirectShareOwnedItem
    isMobileEditOpen: boolean
    activeTabId: string
    isSortBottomSheetOpen: boolean
}

class GarageShareEdit extends React.Component<Props, State> {
    state: State = {
        share_being_edited: {
            title: '',
            entity_id: '',
            active_permission_ids: [] as any,
            id: 'share_being_edited',
            type: 'garage',
            created_at: '', // now
            updated_at: '', // now
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            owner: {
                uid: 'useruid',
                display_name: 'noname',
                location: 'none',
            },
            total_recipients: 0,
        },
        isMobileEditOpen: false,
        activeTabId: 'recipients',
        isSortBottomSheetOpen: false,
    }

    _isMounted = false

    changeTab = (tabID: string) => {
        this.setState({ activeTabId: tabID })
    }

    handleResize = () =>
        window.innerWidth < 881
            ? this.setState({ isMobileEditOpen: true })
            : this.setState({ isMobileEditOpen: false })

    setupLocalStateData = () => {
        let garageid = this.props.garage_id ? this.props.garage_id : ''
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        const garage_shares: IEntityPrivateOwnedSharesState | undefined =
            garageid &&
            this.props.garageData &&
            this.props.garageData[garageid] &&
            this.props.garageData[garageid].private_shares_owned
                ? this.props.garageData[garageid].private_shares_owned
                : undefined

        if (
            garage_shares &&
            garage_shares.entity_owned_direct_shares_data &&
            shareid &&
            garage_shares.entity_owned_direct_shares_data[shareid]
        ) {
            const share: IDirectShareOwnedItem =
                garage_shares.entity_owned_direct_shares_data[shareid]

            this.setState({
                ...this.state,
                share_being_edited: {
                    ...share,
                    active_display: {
                        item_ids_display: share.active_display?.item_ids_display
                            ? share.active_display?.item_ids_display
                            : share.all_share_recipients_ids_list,
                        activeSort: share.active_display?.activeSort
                            ? share.active_display?.activeSort
                            : 'added_at',
                    },
                },
            })
        }
    }

    componentDidMount() {
        this._isMounted = true

        // user stuff
        let userData = this.props.userLoggedIn
        let garageid = this.props.garage_id ? this.props.garage_id : ''
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')
        this.props.setInitialLandingUrl(
            `/sharing/with-others/garage/edit-share?shareid=${shareid}`
        )
        if (!userData) {
            this.props.getUserDataRequest()
        } else {
            if (!this.props.garageData[garageid]) {
                this.props.getGarageByUserIdRequest()
            }

            shareid &&
                this.props.getSingleGarageDirectShareRequest({
                    entity_id: garageid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        window.addEventListener('resize', this.handleResize, { passive: true })

        window.innerWidth < 881 && this.setState({ isMobileEditOpen: true })

        const params = new URLSearchParams(this.props.location.search)
        let active_tab_id = params.get('active_tab_id')

        if (active_tab_id === 'settings') {
            this.changeTab('settings')
        }
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let garageid = this.props.garage_id ? this.props.garage_id : ''
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (!this.props.garageData[garageid]) {
                this.props.getGarageByUserIdRequest()
            }

            shareid &&
                this.props.getSingleGarageDirectShareRequest({
                    entity_id: garageid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        if (
            this.props.garageData[garageid] !== prevProps.garageData[garageid]
        ) {
            this.setupLocalStateData()
        }
    }

    componentWillUnmount() {
        this.setState({ isMobileEditOpen: false })
        window.removeEventListener('resize', this.handleResize)
        this.props.toggleAddShareRecipientByEmail({ isOpen: false })
        this._isMounted = false
    }

    handleChangePermissions = (permissionID: ISharingPermissionIDS) => {
        let permissions_arr: ISharingPermissionIDS[] =
            this.state.share_being_edited.active_permission_ids

        if (
            this.state.share_being_edited.active_permission_ids.includes(
                permissionID
            )
        ) {
            return this.setState({
                ...this.state,
                share_being_edited: {
                    ...this.state.share_being_edited,
                    active_permission_ids: permissions_arr.filter(
                        (id) => permissionID !== id
                    ),
                },
            })
        } else {
            return this.setState({
                ...this.state,
                share_being_edited: {
                    ...this.state.share_being_edited,
                    active_permission_ids: [...permissions_arr, permissionID],
                },
            })
        }
    }

    handleTitleChange = (id: string, title: string) => {
        return this.setState({
            ...this.state,
            share_being_edited: {
                ...this.state.share_being_edited,
                title: title,
            },
        })
    }

    handleSubmitToBE = () => {
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')
        let payload = {
            garage_id: this.props.garage_id ? this.props.garage_id : '',
            share_id: shareid ? shareid : '',
            policy_statements:
                this.state.share_being_edited.active_permission_ids,
            title: this.state.share_being_edited.title,
        }

        this.props.garage_id &&
            this.props.updateGarageDirectShareRequest(payload)

        return payload
    }

    handleDelete = (p: IDirectShareDeletionConfirmation) => {
        if (this.props.garage_id) {
            this.props.deleteGarageDirectShareRequest({
                entity_id: this.props.garage_id,
                share_id: this.state.share_being_edited.id,
            })

            this.props.history.push(`/sharing/with-others?entity_id=garage`)
        }
    }

    render() {
        let {
            toggleAddShareRecipientByEmail,
            addShareRecipientByEmailForm,
            checkUserEmailExistsRequest,
            searchResults,
            searchResultsLoading,
            isCollapsed,
            garage_id,
            sortGarageShareRecipientsDisplay_change_request,
            toggleShareDeletionConfirmation,
            directShareDeletionConfirmation,
            garageSharesLoading,
        } = this.props

        let { share_being_edited } = this.state

        const btnIdsList: string[] = ['recipients', 'settings']
        const btnsData: any = {
            recipients: {
                id: 'recipients',
                txt: 'recipients',
                action: () => this.changeTab('recipients'),
                isDisabled: false,
            },
            settings: {
                id: 'settings',
                txt: 'settings',
                action: () => this.changeTab('settings'),
                isDisabled: false,
            },
        }

        let active_tab: string = this.state.activeTabId

        let share_idsTo_be_displayed =
            share_being_edited &&
            share_being_edited.active_display &&
            share_being_edited.active_display.item_ids_display
                ? share_being_edited.active_display.item_ids_display
                : share_being_edited &&
                  share_being_edited.all_share_recipients_ids_list

        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        const garage_shares: IEntityPrivateOwnedSharesState | undefined =
            garage_id &&
            this.props.garageData &&
            this.props.garageData[garage_id] &&
            this.props.garageData[garage_id].private_shares_owned
                ? this.props.garageData[garage_id].private_shares_owned
                : undefined

        const current_share: IDirectShareOwnedItem | undefined =
            garage_shares &&
            garage_shares.entity_owned_direct_shares_data &&
            shareid &&
            garage_shares.entity_owned_direct_shares_data[shareid]
                ? garage_shares.entity_owned_direct_shares_data[shareid]
                : undefined

        let hasShareChanged = (
            a: IDirectShareOwnedItem,
            b: IDirectShareOwnedItem
        ): boolean => {
            if (a.title !== b.title) {
                return true
            }
            if (a.active_permission_ids && !b.active_permission_ids) {
                return true
            }
            if (!a.active_permission_ids && b.active_permission_ids) {
                return true
            }
            if (
                a.active_permission_ids &&
                b.active_permission_ids &&
                a.active_permission_ids.length !==
                    b.active_permission_ids.length
            ) {
                return true
            }

            if (a.active_permission_ids && b.active_permission_ids) {
                const uniqueValues = new Set([
                    ...a.active_permission_ids,
                    ...b.active_permission_ids,
                ])
                for (const v of uniqueValues) {
                    const aCount = a.active_permission_ids.filter(
                        (e) => e === v
                    ).length
                    const bCount = b.active_permission_ids.filter(
                        (e) => e === v
                    ).length
                    if (aCount !== bCount) return true
                }
            }

            return false
        }

        const checkWhatIsBeingUpdated = (): string | undefined => {
            if (
                share_being_edited.title &&
                current_share &&
                share_being_edited.title !== current_share.title
            ) {
                if (
                    share_being_edited.active_permission_ids &&
                    JSON.stringify(share_being_edited.active_permission_ids) !==
                        JSON.stringify(current_share.active_permission_ids)
                ) {
                    return 'title and permisions'
                } else {
                    return 'title'
                }
            } else if (
                current_share &&
                share_being_edited.active_permission_ids &&
                JSON.stringify(share_being_edited.active_permission_ids) !==
                    JSON.stringify(current_share.active_permission_ids)
            ) {
                return 'permissions'
            }
            return undefined
        }

        return (
            <React.Fragment>
                <DesktopDisplayOnly>
                    <CenteredPageWrapper fullwidth>
                        {share_being_edited && (
                            <WindowPageTemplate
                                hasPositionRelative
                                isCollapsed={isCollapsed}
                                stickyHeaderContent={
                                    <EditModeTopHeaderDesktop
                                        headerText={
                                            current_share && current_share.title
                                                ? current_share.title
                                                : 'Untitled'
                                        }
                                        noBtns
                                        isInsideWindowTemplate
                                        goBack={() => {
                                            this.props.history.push(
                                                `/sharing/with-others?entity_id=garage`
                                            )
                                        }}
                                    />
                                }
                                stickyBottomContent={
                                    <EditModeFooterDesktop
                                        onCancel={
                                            this.state.activeTabId ===
                                            'settings'
                                                ? () => {
                                                      this.props.history.push(
                                                          `/sharing/with-others?entity_id=garage`
                                                      )
                                                  }
                                                : undefined
                                        }
                                        onDelete={() =>
                                            setTimeout(
                                                () =>
                                                    garage_id &&
                                                    shareid &&
                                                    toggleShareDeletionConfirmation(
                                                        {
                                                            isOpen: true,
                                                            entity_id:
                                                                garage_id,
                                                            entity_type:
                                                                'garage',
                                                            share_id: shareid,
                                                        }
                                                    )
                                            )
                                        }
                                        // isActive={this.checkIfChange()}
                                        isInsideWindowTemplate
                                        isActive={
                                            current_share &&
                                            share_being_edited &&
                                            hasShareChanged(
                                                current_share,
                                                share_being_edited
                                            )
                                        }
                                        onSave={
                                            this.state.activeTabId ===
                                            'settings'
                                                ? this.handleSubmitToBE
                                                : undefined
                                        }
                                        isCollapsed={isCollapsed}
                                    />
                                }
                            >
                                <DesktopAdjustedWrapper>
                                    {addShareRecipientByEmailForm &&
                                        garage_id && (
                                            <ModalDisplayAbsolute
                                                isOpen={
                                                    addShareRecipientByEmailForm &&
                                                    addShareRecipientByEmailForm
                                                        ? true
                                                        : false
                                                }
                                                toggle={() => {}}
                                                top="20px"
                                                left="0px"
                                                right="20px"
                                                bottom="20px"
                                            >
                                                <AddShareByEmailFormDesktop
                                                    close={() => {
                                                        toggleAddShareRecipientByEmail(
                                                            {
                                                                isOpen: false,
                                                            }
                                                        )
                                                    }}
                                                    share_id={
                                                        share_being_edited.id
                                                    }
                                                    entity_id={garage_id}
                                                    isGarage
                                                />
                                            </ModalDisplayAbsolute>
                                        )}
                                    <StickyWrapper>
                                        <SwitchTabMenuDesktop
                                            btnIdsList={btnIdsList}
                                            btnsData={btnsData}
                                            activeID={active_tab}
                                        />
                                    </StickyWrapper>
                                    <Padding40 />
                                    {this.state.activeTabId ===
                                        'recipients' && (
                                        <Faded>
                                            <PrivateShareSearchFormManagerDesktop
                                                share={share_being_edited}
                                                searchUsers={
                                                    checkUserEmailExistsRequest
                                                }
                                                searchResults={searchResults}
                                                resultsLoading={
                                                    searchResultsLoading
                                                }
                                                addShareRecipient={
                                                    this.props
                                                        .addGarageShareRecipientRequest
                                                }
                                                isGarage
                                                selectedUsers={
                                                    share_being_edited.all_share_recipients_ids_list
                                                }
                                                onClickInvite={() =>
                                                    toggleAddShareRecipientByEmail(
                                                        { isOpen: true }
                                                    )
                                                }
                                                inviteTxt={`Invite to Custodian & share`}
                                            />
                                            {/* <Padding30 />
                                            <IconAndTextNakedButton
                                                onClick={() =>
                                                    toggleAddShareRecipientByEmail(
                                                        { isOpen: true }
                                                    )
                                                }
                                                text="Invite to Custodian via Email & Share"
                                                iconOnLeft
                                                icon="v2_add"
                                            /> */}
                                            {/* <ComingSoonCard
                                                title={`Invite to Custodian & share`}
                                                description={`Soon you’ll be able to invite anyone you want to join Custodian and share your cars or full garage with them.`}
                                            /> */}
                                            <Padding30 />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    maxWidth: '730px',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <SharedWith>
                                                    Direct share recipients
                                                </SharedWith>
                                                <DropdownHoverWrapper>
                                                    <DropdownExpanderHover
                                                        width={'210px'}
                                                        fontSize="15px"
                                                        height="40px"
                                                        placeholder={`Sorted by Date added`}
                                                        placeholderWhenSelected="Sorted by"
                                                        rightAligned
                                                        items={[
                                                            {
                                                                text: 'by Date added',
                                                                action: () => {
                                                                    garage_id &&
                                                                        sortGarageShareRecipientsDisplay_change_request(
                                                                            {
                                                                                sortID: 'added_at',
                                                                                entityid:
                                                                                    garage_id,
                                                                                share: share_being_edited,
                                                                            }
                                                                        )
                                                                },
                                                            },
                                                            {
                                                                text: 'Alphabetical',
                                                                action: () => {
                                                                    garage_id &&
                                                                        sortGarageShareRecipientsDisplay_change_request(
                                                                            {
                                                                                sortID: 'alphabetical',
                                                                                entityid:
                                                                                    garage_id,
                                                                                share: share_being_edited,
                                                                            }
                                                                        )
                                                                },
                                                            },
                                                        ]}
                                                    />
                                                </DropdownHoverWrapper>
                                            </div>

                                            <Padding20 />
                                            {share_idsTo_be_displayed &&
                                            share_idsTo_be_displayed.length >
                                                0 ? (
                                                share_being_edited.share_recipients_data &&
                                                share_idsTo_be_displayed.map(
                                                    (userid, index) => (
                                                        <ShareRowDesktop
                                                            recipient={
                                                                share_being_edited!
                                                                    .share_recipients_data![
                                                                    userid
                                                                ]
                                                            }
                                                            index={index}
                                                            isLast={
                                                                share_idsTo_be_displayed &&
                                                                share_idsTo_be_displayed.length -
                                                                    1 ===
                                                                    index
                                                                    ? true
                                                                    : false
                                                            }
                                                            actionsData={{
                                                                email: (payload: {
                                                                    invite_id: string
                                                                    recipient_email: string
                                                                }) =>
                                                                    this.props.resendGarageShareInviteRequest(
                                                                        {
                                                                            invite_id:
                                                                                payload.invite_id,
                                                                            recipient_email:
                                                                                payload.recipient_email,
                                                                        }
                                                                    ),

                                                                delete: (payload: {
                                                                    recipient_id: string
                                                                    recipient_type:
                                                                        | 'platform'
                                                                        | 'external'
                                                                }) =>
                                                                    payload.recipient_type ===
                                                                    'external'
                                                                        ? this.props.deleteGarageShareInviteRequest(
                                                                              {
                                                                                  share_id:
                                                                                      share_being_edited.id,
                                                                                  entity_id:
                                                                                      share_being_edited.entity_id,
                                                                                  invite_id:
                                                                                      payload.recipient_id,
                                                                              }
                                                                          )
                                                                        : this.props.deleteGarageShareRecipientRequest(
                                                                              {
                                                                                  share_id:
                                                                                      share_being_edited.id,
                                                                                  entity_id:
                                                                                      share_being_edited.entity_id,
                                                                                  recipient_userid:
                                                                                      payload.recipient_id,
                                                                              }
                                                                          ),
                                                            }}
                                                            sharedOn={
                                                                share_being_edited.created_at
                                                            }
                                                        />
                                                    )
                                                )
                                            ) : (
                                                <Faded>
                                                    <NoIconDataRectangle
                                                        text={`You haven’t shared this with anyone yet`}
                                                    />
                                                </Faded>
                                            )}
                                        </Faded>
                                    )}
                                    {this.state.activeTabId === 'settings' && (
                                        <Faded>
                                            <PrivateShareFormManagerDesktop
                                                share_being_edited={
                                                    share_being_edited
                                                }
                                                handleSubmit={
                                                    this.handleSubmitToBE
                                                }
                                                handlePrivateShareTitleChange={
                                                    this.handleTitleChange
                                                }
                                                handlePermissions={
                                                    this.handleChangePermissions
                                                }
                                                formType="edit"
                                                isGarageShare
                                            />
                                        </Faded>
                                    )}
                                </DesktopAdjustedWrapper>
                            </WindowPageTemplate>
                        )}
                    </CenteredPageWrapper>
                    <DeletionConfirmationDesktop
                        isShare
                        isEditForm
                        isOpen={directShareDeletionConfirmation.isOpen}
                        toggle={() =>
                            setTimeout(
                                () =>
                                    toggleShareDeletionConfirmation({
                                        isOpen: false,
                                        entity_id: undefined,
                                        entity_type: undefined,
                                        share_id: undefined,
                                    }),
                                50
                            )
                        }
                        action={() =>
                            this.handleDelete(directShareDeletionConfirmation)
                        }
                        actionCopy="Confirm deletion"
                        titleCopy="Are you sure?"
                        detailsCopy="All recipients will lose access to your garage."
                    />
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <SideSlider
                        direction={
                            this.props.location.state &&
                            this.props.location.state.prevPath &&
                            this.props.location.state.prevPath.includes(
                                `/edit-share/recipients`
                            )
                                ? 'left'
                                : 'right'
                        }
                        initial="enter"
                    >
                        <PrivateShareReturnNav
                            customText={
                                current_share && current_share.title
                                    ? current_share.title
                                    : 'Untitled'
                            }
                            chevron
                            linkTo={`/sharing/with-others`}
                            params="?entity_id=garage"
                            noBoxShadow
                        />
                        <div style={{ paddingTop: '60px' }} />
                        <StickyWrapper>
                            <SwitchTabMenuMobile
                                btnIdsList={btnIdsList}
                                btnsData={btnsData}
                                activeID={active_tab}
                            />
                        </StickyWrapper>
                        {this.state.activeTabId === 'settings' && (
                            <PrivateShareFormManagerMobile
                                share_being_edited={share_being_edited}
                                handleSubmit={this.handleSubmitToBE}
                                handlePrivateShareTitleChange={
                                    this.handleTitleChange
                                }
                                handlePermissions={this.handleChangePermissions}
                                formType="edit"
                                isSaveActive={
                                    current_share &&
                                    share_being_edited &&
                                    hasShareChanged(
                                        current_share,
                                        share_being_edited
                                    )
                                }
                                isGarageShare
                                onDeleteShare={() =>
                                    setTimeout(
                                        () =>
                                            garage_id &&
                                            shareid &&
                                            toggleShareDeletionConfirmation({
                                                isOpen: true,
                                                entity_id: garage_id,
                                                entity_type: 'garage',
                                                share_id: shareid,
                                            })
                                    )
                                }
                                shareChanges={checkWhatIsBeingUpdated()}
                                sharesLoading={garageSharesLoading}
                            />
                        )}
                        {this.state.activeTabId === 'recipients' && (
                            <MobileWrapper>
                                {addShareRecipientByEmailForm && garage_id && (
                                    <ModalDisplay
                                        isOpen={
                                            addShareRecipientByEmailForm &&
                                            addShareRecipientByEmailForm
                                                ? true
                                                : false
                                        }
                                        toggle={() => {}}
                                    >
                                        <AddShareByEmailFormMobile
                                            close={() => {
                                                toggleAddShareRecipientByEmail({
                                                    isOpen: false,
                                                })
                                            }}
                                            share_id={share_being_edited.id}
                                            entity_id={garage_id}
                                            isGarage
                                        />
                                    </ModalDisplay>
                                )}
                                <Container>
                                    <Title>
                                        Choose your Share Recipient(s)
                                    </Title>
                                    <SubTitle>
                                        {`Please search and select the recipient(s) you would like to share your garage with by entering their email.`}
                                    </SubTitle>
                                    <Padding24 />

                                    <PrivateShareSearchManagerMobileV2
                                        share={share_being_edited}
                                        searchUsers={
                                            checkUserEmailExistsRequest
                                        }
                                        searchResults={searchResults}
                                        resultsLoading={searchResultsLoading}
                                        addShareRecipient={
                                            this.props
                                                .addGarageShareRecipientRequest
                                        }
                                        isGarage
                                        selectedUsers={
                                            share_being_edited.all_share_recipients_ids_list
                                        }
                                        onClickInvite={() =>
                                            toggleAddShareRecipientByEmail({
                                                isOpen: true,
                                            })
                                        }
                                        inviteTxt={`Invite to Custodian & Share`}
                                    />

                                    {/* <PickerBtn
                                        onClick={() =>
                                            this.props.history.push(
                                                `/sharing/with-others/garage/edit-share/recipients?shareid=${shareid}`
                                            )
                                        }
                                        noValue
                                        text=" Search for users"
                                    />

                                */}

                                    <Padding24 />
                                </Container>

                                {/* <ComingSoonCard
                                    title={`Invite to Custodian & share`}
                                    description={`Soon you’ll be able to invite anyone you want to join Custodian and share your cars or full garage with them.`}
                                /> */}
                                <Padding20 />

                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <SharedWith>
                                        Direct share recipients
                                    </SharedWith>
                                    <GreyIconButton
                                        onClick={() =>
                                            this.setState({
                                                isSortBottomSheetOpen: true,
                                            })
                                        }
                                        variant="sort"
                                        isActive={false}
                                        sortingCriteria={'by_date_added'}
                                    />
                                </div>

                                <Padding10 />
                                {share_idsTo_be_displayed &&
                                share_idsTo_be_displayed.length > 0 ? (
                                    share_being_edited.share_recipients_data &&
                                    share_idsTo_be_displayed.map(
                                        (userid, index) => (
                                            <ShareRowMobile
                                                recipient={
                                                    share_being_edited!
                                                        .share_recipients_data![
                                                        userid
                                                    ]
                                                }
                                                index={index}
                                                isLast={
                                                    share_idsTo_be_displayed &&
                                                    share_idsTo_be_displayed.length -
                                                        1 ===
                                                        index
                                                        ? true
                                                        : false
                                                }
                                                actionsData={{
                                                    email: (payload: {
                                                        recipient_id: string
                                                        recipient_email: string
                                                    }) =>
                                                        this.props.resendGarageShareInviteRequest(
                                                            {
                                                                invite_id:
                                                                    payload.recipient_id,
                                                                recipient_email:
                                                                    payload.recipient_email,
                                                            }
                                                        ),

                                                    delete: (payload: {
                                                        recipient_id: string
                                                        recipient_type:
                                                            | 'platform'
                                                            | 'external'
                                                    }) =>
                                                        payload.recipient_type ===
                                                        'external'
                                                            ? this.props.deleteGarageShareInviteRequest(
                                                                  {
                                                                      share_id:
                                                                          share_being_edited.id,
                                                                      entity_id:
                                                                          share_being_edited.entity_id,
                                                                      invite_id:
                                                                          payload.recipient_id,
                                                                  }
                                                              )
                                                            : this.props.deleteGarageShareRecipientRequest(
                                                                  {
                                                                      share_id:
                                                                          share_being_edited.id,
                                                                      entity_id:
                                                                          share_being_edited.entity_id,
                                                                      recipient_userid:
                                                                          payload.recipient_id,
                                                                  }
                                                              ),
                                                }}
                                                sharedOn={
                                                    share_being_edited.created_at
                                                }
                                            />
                                        )
                                    )
                                ) : (
                                    <Faded>
                                        <NoIconDataRectangle
                                            text={`You haven’t shared this with anyone yet`}
                                        />
                                    </Faded>
                                )}
                                <PrivateSharesSortingMobile
                                    actions_items={[
                                        {
                                            id: 'added_at',
                                            text: 'By date added',
                                            action: () => {
                                                garage_id &&
                                                    sortGarageShareRecipientsDisplay_change_request(
                                                        {
                                                            sortID: 'added_at',
                                                            entityid: garage_id,
                                                            share: share_being_edited,
                                                        }
                                                    )
                                            },
                                        },
                                        {
                                            id: 'alphabetical',
                                            text: 'Alphabetical',
                                            action: () => {
                                                garage_id &&
                                                    sortGarageShareRecipientsDisplay_change_request(
                                                        {
                                                            sortID: 'alphabetical',
                                                            entityid: garage_id,
                                                            share: share_being_edited,
                                                        }
                                                    )
                                            },
                                        },
                                    ]}
                                    toggle={() =>
                                        this.setState({
                                            ...this.state,
                                            isSortBottomSheetOpen: false,
                                        })
                                    }
                                    isPrivateSharesSortingOpen={
                                        this.state.isSortBottomSheetOpen
                                    }
                                    activeSort={
                                        share_being_edited.active_display &&
                                        share_being_edited.active_display
                                            .activeSort
                                            ? share_being_edited.active_display
                                                  .activeSort
                                            : 'added_at'
                                    }
                                />
                            </MobileWrapper>
                        )}
                        <div style={{ paddingTop: '150px' }} />
                    </SideSlider>
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GarageShareEdit)
)
