import usercategorieslist from "./usercategories";
import { UserCategory } from "myInterfaces";

export type IUserCategoriesState = UserCategory[];

const initialState = usercategorieslist;

export const usercategories = (state: IUserCategoriesState = initialState) =>
  state;

export default usercategories;

export type UserCategoriesState = ReturnType<typeof usercategories>;
