import { Tooltip } from 'antd'
import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'
import { TooltipPlacement } from 'antd/lib/tooltip'

interface IStyle {
    $theme: ITheme
    $width?: string | undefined
    $height?: string | undefined
    $bgcolor?: string
    $noBorder?: boolean
    $borderColor?: string
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width ?? 'fit-content'};
    height: ${(props) => props.$height ?? '32px'};
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: ${(props) =>
        props.$noBorder
            ? 'none'
            : props.$borderColor
            ? `1px solid ${props.$borderColor}`
            : `1px solid ${colours[props.$theme].border_muted}`};
    outline: none;
    background-color: ${(props) =>
        props.$bgcolor ?? colours[props.$theme].background_neutral_soft};
    border-radius: 5px;
    transition: all 200ms;
    cursor: pointer;
    :hover {
        box-shadow: ${(props) =>
            props.$noBorder
                ? 'none'
                : `1px 1px 7px ${colours[props.$theme].shadow_strongest_09}`};
        transition: all 200ms;
    }
`

type Props = {
    onClick?: () => void
    dataCyId?: string | undefined
    buttonwidth?: string | undefined
    buttonheight?: string | undefined
    iconwidth?: string | undefined
    iconheight?: string | undefined
    icon?: string
    orientation?: 'up' | 'down'
    bgcolor?: string
    noborder?: boolean
    borderColor?: string
    hasTooltip?: boolean
    isTooltipVisible?: boolean
    tooltipColor?: string
    tooltipTextColor?: string
    tooltipText?: string
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    activestyle?: any
    tooltipPlacement?: TooltipPlacement | undefined
    dataPH?: string
}

const IconButton: React.FunctionComponent<Props> = (props: Props) => {
    let {
        onClick,
        buttonwidth,
        buttonheight,
        iconwidth,
        iconheight,
        icon,
        orientation,
        bgcolor,
        noborder,
        dataCyId,
        borderColor,
        hasTooltip,
        isTooltipVisible,
        onMouseEnter,
        onMouseLeave,
        tooltipColor,
        tooltipText,
        activestyle,
        tooltipPlacement,
        dataPH,
        tooltipTextColor,
    } = props

    const { theme } = useThemes()

    return hasTooltip ? (
        <Tooltip
            title={
                tooltipText
                    ? () => (
                          <span
                              style={
                                  tooltipTextColor
                                      ? { color: tooltipTextColor }
                                      : {}
                              }
                          >
                              {tooltipText}
                          </span>
                      )
                    : 'Click!'
            }
            open={isTooltipVisible}
            placement={tooltipPlacement ?? 'top'}
            color={tooltipColor ?? colours[theme].primary}
        >
            <Button
                data-ph={dataPH}
                $theme={theme}
                onMouseEnter={onMouseEnter && onMouseEnter}
                onMouseLeave={onMouseLeave && onMouseLeave}
                $width={buttonwidth}
                $height={buttonheight}
                onClick={onClick}
                data-attr={dataCyId ? dataCyId : undefined}
                $bgcolor={bgcolor}
                $noBorder={noborder}
                $borderColor={borderColor}
                style={activestyle ?? {}}
            >
                <Icon
                    orientation={orientation}
                    width={iconwidth}
                    height={iconheight}
                    icon={icon ? icon : 'download'}
                />
            </Button>
        </Tooltip>
    ) : (
        <Button
            $theme={theme}
            onMouseEnter={onMouseEnter && onMouseEnter}
            onMouseLeave={onMouseLeave && onMouseLeave}
            $width={buttonwidth}
            $height={buttonheight}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            $bgcolor={bgcolor}
            $noBorder={noborder}
            $borderColor={borderColor}
            style={activestyle ?? {}}
            data-ph={dataPH}
        >
            <Icon
                orientation={orientation}
                width={iconwidth}
                height={iconheight}
                icon={icon ? icon : 'download'}
            />
        </Button>
    )
}

export default IconButton
