import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
// import blank_user from '../../../public/assets/icons/profile_pic_placeholder.svg'
// import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'

type IStyleProps = {
    hasImage?: boolean
    size?: string
}

const UserImgWrapper = styled.div<IStyleProps>`
    position: absolute;
    width: 116px;
    height: 116px;
    top: -0px;
    border-radius: 16px;
    border: ${(props) => (props.hasImage ? 'none' : 'solid 1px #ececec')};

    @media ${device.desktop} {
    }
    @media ${device.smallest_laptops} {
    }
`

const UserImg = styled.img`
    height: 100%;
    border-radius: 16px;
    width: 100%;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
`

type Props = {
    url?: string | null | false | undefined
    onClick?: any
}

type State = {
    src: any
    isLoading: boolean
}

class UserAccountSquare extends React.Component<Props, State> {
    state = {
        src: undefined,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src =
            this.props.url !== undefined &&
            this.props.url !== false &&
            this.props.url !== null &&
            this.props.url.length > 1
                ? this.props.url
                : undefined
        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props?.url !== prevProps?.url) {
            this.setState({ src: null, isLoading: true })
            const src =
                this.props.url !== undefined &&
                this.props.url !== false &&
                this.props.url !== null &&
                this.props.url.length > 1
                    ? this.props.url
                    : undefined

            if (src) {
                return this.setState({ src: src, isLoading: false })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <Wrapper
                style={{
                    width: '116px',
                }}
                onClick={() => this.props.onClick && this.props.onClick()}
            >
                <UserImgWrapper
                    hasImage={this.state.src === undefined ? false : true}
                >
                    <UserImg
                        src={
                            this.state.src !== undefined
                                ? this.state.src
                                : blank_user
                        }
                        onLoad={() =>
                            this._isMounted &&
                            this.setState({
                                ...this.state,
                                isLoading: false,
                            })
                        }
                    />
                    <SkeletonAbsolute
                        isthingloading={this.state.isLoading}
                        isRound={true}
                    />
                </UserImgWrapper>
            </Wrapper>
        )
    }
}

export default UserAccountSquare
