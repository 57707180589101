import useThemes from '../../../../providers/theme/hooks'
import DarkThemeIcon from './dark'
import LightThemeIcon from './light'
import SystemThemeIcon from './system'

export default function ActiveThemeIcon({ color }: { color?: string }) {
    const { theme } = useThemes()
    switch (theme) {
        case 'light':
            return <LightThemeIcon color={color} />
        case 'dark':
            return <DarkThemeIcon color={color} />
        default:
            return <SystemThemeIcon color={color} />
    }
}
