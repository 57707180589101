import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

import {
    CHECK_USER_EMAIL_EXISTS_ERROR,
    SEARCH_PLATFORM_USERS_ERROR,
} from './constants'

export const searchPlatformUsersError = createAction(
    SEARCH_PLATFORM_USERS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const checkUserEmailExistsError = createAction(
    CHECK_USER_EMAIL_EXISTS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
