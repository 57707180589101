import {
    ITasksDisplayCriteria,
    ITask,
    ITaskNormalisedObject,
    IPayloadTaskRemindersState,
    ISetTaskRemindersEditedPayload,
    ISetAPITaskRemindersInStatePayload,
} from 'entityModels'

import { createAction } from '@reduxjs/toolkit'

import {
    GET_TASKS_BY_GARAGE_ID_SUCCESS,
    GET_TASKS_BY_CAR_ID_SUCCESS,
    GET_TASK_BY_ID_SUCCESS,
    UPDATE_TASK_SUCCESS,
    CREATE_TASK_SUCCESS,
    LINK_TASK_SUCCESS,
    UNLINK_TASK_SUCCESS,
    DELETE_TASK_SUCCESS,
    REMOVE_STORE_TASKS_SUCCESS,
    REGISTER_ALL_TASKS_DISPLAY_CONDITIONS_SUCCESS,
    GET_TASK_REMINDERS_SUCCESS,
    SET_TASK_REMINDERS_EDITED_SUCCESS,
    ADD_REMINDER_TO_TASK_SUCCESS,
    DELETE_REMINDER_TASK_SUCCESS,
    CLEAN_UP_TASKS_SUCCESS,
} from './constants'
import { IDeleteTaskReminderSuccess } from '../sagas/deleteTaskReminder'

// export const getTasksByGarageIdSuccess = createAction(
//     GET_TASKS_BY_GARAGE_ID_SUCCESS,
//     (resolve) => (normalisedTasks: ITaskNormalisedObject) =>
//         resolve(normalisedTasks)
// )

export const getTasksByGarageIdSuccess = createAction(
    GET_TASKS_BY_GARAGE_ID_SUCCESS,
    function prepare(payload: ITaskNormalisedObject) {
        return {
            payload,
        }
    }
)

// export const getTasksByCarIdSuccess = createAction(
//     GET_TASKS_BY_CAR_ID_SUCCESS,
//     (resolve) => (normalisedTasks: ITaskNormalisedObject) =>
//         resolve(normalisedTasks)
// )

export const getTasksByCarIdSuccess = createAction(
    GET_TASKS_BY_CAR_ID_SUCCESS,
    function prepare(payload: ITaskNormalisedObject) {
        return {
            payload,
        }
    }
)

// export const getTaskByIdSuccess = createAction(
//     GET_TASK_BY_ID_SUCCESS,
//     (resolve) => (normalisedTask: ITaskNormalisedObject) =>
//         resolve(normalisedTask)
// )

export const getTaskByIdSuccess = createAction(
    GET_TASK_BY_ID_SUCCESS,
    function prepare(payload: ITaskNormalisedObject) {
        return {
            payload,
        }
    }
)

// export const updateTaskSuccess = createAction(
//     UPDATE_TASK_SUCCESS,
//     (resolve) => (payload: ITask) => resolve(payload)
// )

export const updateTaskSuccess = createAction(
    UPDATE_TASK_SUCCESS,
    function prepare(payload: ITask) {
        return {
            payload,
        }
    }
)

// export const createTaskSuccess = createAction(
//     CREATE_TASK_SUCCESS,
//     (resolve) => (payload: ITaskNormalisedObject) => resolve(payload)
// )

export const createTaskSuccess = createAction(
    CREATE_TASK_SUCCESS,
    function prepare(payload: ITaskNormalisedObject) {
        return {
            payload,
        }
    }
)

// export const linkTaskSuccess = createAction(
//     LINK_TASK_SUCCESS,
//     (resolve) => (payload: ITask) => resolve(payload)
// )

export const linkTaskSuccess = createAction(
    LINK_TASK_SUCCESS,
    function prepare(payload: ITask) {
        return {
            payload,
        }
    }
)

// export const unlinkTaskSuccess = createAction(
//     UNLINK_TASK_SUCCESS,
//     (resolve) => (payload: ITask) => resolve(payload)
// )

export const unlinkTaskSuccess = createAction(
    UNLINK_TASK_SUCCESS,
    function prepare(payload: ITask) {
        return {
            payload,
        }
    }
)

// export const deleteTaskSuccess = createAction(
//     DELETE_TASK_SUCCESS,
//     (resolve) => (taskid: string) => resolve(taskid)
// )

export const deleteTaskSuccess = createAction(
    DELETE_TASK_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const removeStoreTasksSuccess = createAction(REMOVE_STORE_TASKS_SUCCESS)

export const registerTasksDisplayCriteriaSuccess = createAction(
    REGISTER_ALL_TASKS_DISPLAY_CONDITIONS_SUCCESS,
    function prepare(payload: ITasksDisplayCriteria) {
        return {
            payload,
        }
    }
)

export const getTaskRemindersSuccess = createAction(
    GET_TASK_REMINDERS_SUCCESS,
    function prepare(payload: IPayloadTaskRemindersState) {
        return {
            payload,
        }
    }
)

export const setTaskRemindersEditedSuccess = createAction(
    SET_TASK_REMINDERS_EDITED_SUCCESS,
    function prepare(payload: ISetTaskRemindersEditedPayload) {
        return {
            payload,
        }
    }
)

export const addRemindersToTaskSuccess = createAction(
    ADD_REMINDER_TO_TASK_SUCCESS,
    function prepare(payload: ISetAPITaskRemindersInStatePayload) {
        return {
            payload,
        }
    }
)

export const deleteReminderTaskSuccess = createAction(
    DELETE_REMINDER_TASK_SUCCESS,
    function prepare(payload: IDeleteTaskReminderSuccess) {
        return {
            payload,
        }
    }
)

export const cleanUpTasksSuccess = createAction(CLEAN_UP_TASKS_SUCCESS)
