import { call, fork, put, take } from 'redux-saga/effects'

import * as requestActions from '../actions/requestActions'
import * as successActions from '../actions/successActions'
import * as errorActions from '../actions/errorActions'
import {
    IAddCostItemPayloadRequest,
    IUpdateCostItemPayloadRequest,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'

import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import * as carActions from '../../entities/cars/actions'
import { message } from 'antd'
import { UpdateCarTimelineSaga } from './updateCarTimeline'
import { AddCarTimelineEntryCostSaga } from './addCarTimelineEntryCost'
import { UpdateCarTimelineEntryCostSaga } from './updateCarTimelineEntryCost'
export const insurance_history_fiel_specific_fields = [
    'insurance_entry_policy_start_date',
    'insurance_entry_policy_end_date',
    'insurance_entry_underwriter',
    'insurance_entry_broker',
]

const successInfo = (messageText: string) => {
    message.success(messageText, 1)
}

export function* UpdateCarTimelineWithCostsSaga(
    payload: IUpdateTimelineItemPayloadRequest
): any {
    try {
        yield call(UpdateCarTimelineSaga, payload)
        if (
            payload.item &&
            payload.item.costItemsObj &&
            Object.keys(payload.item.costItemsObj).length > 0
        ) {
            for (const key of Object.keys(payload.item.costItemsObj)) {
                if (key === 'id') {
                    let costPayload: IAddCostItemPayloadRequest = {
                        carid: payload.carid,
                        itemid: payload.item.id,
                        cost: payload.item.costItemsObj[key],
                    }
                    yield call(AddCarTimelineEntryCostSaga, costPayload)
                } else {
                    let costToUpdatePayload: IUpdateCostItemPayloadRequest = {
                        carid: payload.carid,
                        itemid: payload.item.id,
                        costid: key,
                        cost: payload.item.costItemsObj[key],
                    }

                    yield call(
                        UpdateCarTimelineEntryCostSaga,
                        costToUpdatePayload
                    )
                }
            }
        }
        yield put(successActions.updateTimelineItemWithCostSuccess())
        yield put(carActions.actions.resetCarInsights(payload.carid))
        yield put(successActions.resetTimelineItems(payload.carid))
        successInfo(`Information successfully updated`)
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: UpdateCarTimelineWithCostsSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try adding a timeline item again.`,
                custom_user_action_text: 'Return to timeline',
                custom_redirect_path: `/car/${payload.carid}/history-file`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(
                errorActions.updateTimelineItemWithCostsError(customError)
            )
        }
    }
}

function* watcherUpdateCarTimelineWithCost() {
    while (true) {
        const { payload } = yield take(
            requestActions.updateTimelineItemWithCostRequest
        )

        yield call(UpdateCarTimelineWithCostsSaga, payload)
    }
}

const update_car_timeline_with_costs: any[] = [
    fork(watcherUpdateCarTimelineWithCost),
]

export default update_car_timeline_with_costs
