import { message } from 'antd'
import { push } from 'connected-react-router'
import { IReduxError } from 'entityModels'
import posthog from 'posthog-js'
import { call, fork, put, take } from 'redux-saga/effects'

import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    ICreateShareInviteRequestPayload,
    IShareInvitResent,
    IShareInviteDeletion,
} from '../../cars/actions/loadingActions'
import * as actions from '../actions'
import { GetSingleGarageShare } from './getGarageDirectShares'

const info = (messageText: string) => {
    message.info(messageText, 5)
}

export function* CreateGarageShareInviteSaga(
    p: ICreateShareInviteRequestPayload
) {
    try {
        yield call(api.sharing.createShareInvite, p.api_payload)
        yield call(GetSingleGarageShare, {
            entity_id: p.entity_id,
            share_id: p.api_payload.share_id,
        })

        info(`An email has been sent to ${p.api_payload.recipient_email}.`)

        // Capture event
        posthog.capture('CREATE GARAGE DIRECT SHARE EMAIL INVITE')

        yield put(actions.actions.createGarageShareInviteSuccess())
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateGarageShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.createGarageShareInviteError(typedError)
            )
            return
        }
    }
}

export function* ResendGarageShareInviteSaga(p: IShareInvitResent) {
    try {
        yield call(api.sharing.resendShareInviteApi, p.invite_id)
        yield put(actions.actions.resendGarageShareInviteSuccess())

        info(
            `A remider email about your share has been sent to ${p.recipient_email}.`
        )

        // Capture event
        posthog.capture('RESEND GARAGE DIRECT SHARE EMAIL INVITE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: ResendGarageShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.resendGarageShareInviteError(typedError)
            )
            return
        }
    }
}

export function* AcceptGarageShareInviteSaga(p: {
    invite_code: string
    next_path: string
}) {
    try {
        yield call(api.sharing.acceptShareInviteApi, p.invite_code)
        info(`You have accepted a share invite.`)
        yield put(push(p.next_path))
        // Capture event
        posthog.capture('ACCEPT GARAGE DIRECT SHARE EMAIL INVITE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: AcceptGarageShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else if (error.status === 403) {
            yield put(push('/invite-user-error'))
            yield put(
                actions.errorActions.acceptGarageShareInviteError(undefined)
            )
        } else if (error.status === 404) {
            yield put(push(p.next_path))
            yield put(
                actions.errorActions.acceptGarageShareInviteError(undefined)
            )
            return
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.acceptGarageShareInviteError(typedError)
            )
            return
        }
    }
}

export function* DeleteGarageShareInviteSaga(p: IShareInviteDeletion) {
    try {
        yield call(api.sharing.deleteShareInviteApi, p.invite_id)
        yield put(actions.actions.deleteGarageShareInviteSuccess(p))

        info(`Share invite was removed.`)

        // Capture event
        posthog.capture('DELETE GARAGE DIRECT SHARE EMAIL INVITE')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteGarageShareInviteSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.deleteGarageShareInviteError(typedError)
            )
            return
        }
    }
}

function* watcherCreateGarageShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.createGarageShareInviteRequest
        )
        yield call(CreateGarageShareInviteSaga, payload)
    }
}

function* watcherResendGarageShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.resendGarageShareInviteRequest
        )
        yield call(ResendGarageShareInviteSaga, payload)
    }
}

function* watcherAcceptGarageShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.acceptGarageShareInviteRequest
        )
        yield call(AcceptGarageShareInviteSaga, payload)
    }
}

function* watcherDeleteGarageShareInvite() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.deleteGarageShareInviteRequest
        )
        yield call(DeleteGarageShareInviteSaga, payload)
    }
}

const garage_share_invite_sagas: any[] = [
    fork(watcherCreateGarageShareInvite),
    fork(watcherDeleteGarageShareInvite),
    fork(watcherResendGarageShareInvite),
    fork(watcherAcceptGarageShareInvite),
]

export default garage_share_invite_sagas
