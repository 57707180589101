import { IJourneySectionID } from 'journeyInterfaces'
import styled from 'styled-components'
import { ITheme, journey_colours } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { device } from '../../displays/devices'

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
}

const Wrapper = styled.div<IStyle>`
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.25;
    filter: blur(90px);
    z-index: 0;
    overflow: hidden;
`
const SubWrapper = styled.div<IStyle>`
    position: relative;
    width: 100%;
    height: 100%;
`
const RectangleTopRight = styled.div<IStyle>`
    width: 343.476px;
    height: 234.04px;
    position: absolute;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    @media ${device.mobile_and_ipad} {
        width: 135px;
        height: 186px;
    }
`
const RectangleBottomLeft = styled.div<IStyle>`
    width: 535px;
    height: 464px;
    position: absolute;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
    @media ${device.mobile_and_ipad} {
        width: 150.681px;
        height: 372.041px;
    }
`
const RectangleRight = styled.div<IStyle>`
    width: 182px;
    height: 232px;
    position: absolute;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    top: 50%;
    right: 0;
    transform: translateX(50%);
    @media ${device.mobile_and_ipad} {
        width: 116px;
        height: 186px;
    }
`
const RectangleMiddleTopLeft = styled.div<IStyle>`
    width: 279px;
    height: 253px;
    position: absolute;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    top: 10dvh;
    left: 10dvh;
    @media ${device.mobile_and_ipad} {
        width: 78.58px;
        height: 202.859px;
    }
`
const RectangleBottomRight = styled.div<IStyle>`
    width: 533px;
    height: 327px;
    position: absolute;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    @media ${device.mobile_and_ipad} {
        width: 150.118px;
        height: 262.193px;
    }
`
const RectangleTopLeft = styled.div<IStyle>`
    width: 401.412px;
    height: 287.055px;
    position: absolute;
    background-color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    @media ${device.mobile_and_ipad} {
        width: 113.057px;
        height: 230.164px;
    }
`

export default function BlurredBg({
    sectionID,
}: {
    sectionID: IJourneySectionID
}) {
    let { theme } = useThemes()
    return (
        <Wrapper $theme={theme} $sectionID={sectionID}>
            <SubWrapper $theme={theme} $sectionID={sectionID}>
                <RectangleTopRight $theme={theme} $sectionID={sectionID} />
                <RectangleBottomLeft $theme={theme} $sectionID={sectionID} />
                <RectangleRight $theme={theme} $sectionID={sectionID} />
                <RectangleMiddleTopLeft $theme={theme} $sectionID={sectionID} />
                <RectangleBottomRight $theme={theme} $sectionID={sectionID} />
                <RectangleTopLeft $theme={theme} $sectionID={sectionID} />
            </SubWrapper>
        </Wrapper>
    )
}
