import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFeatureFlagsReducer } from './types'

type IFeatureFlagsItem = 'enable_showroom' | 'enable_insurance'

const initialState: IFeatureFlagsReducer = {
    enable_showroom: true,
    enable_insurance: true,
}

export const featureFlags = createSlice({
    name: 'featureFlags',
    initialState: initialState,
    reducers: {
        setFeatureFlatToTrue: (
            state: any,
            action: PayloadAction<IFeatureFlagsItem>
        ) => {
            state[action.payload] = true
        },
        setFeatureFlatToFalse: (
            state: any,
            action: PayloadAction<IFeatureFlagsItem>
        ) => {
            state[action.payload] = false
        },
        cleanupFeatureFlags: (state: any) => {
            return initialState
        },
    },
})

export const featureFlagsActions = featureFlags.actions

export default featureFlags.reducer
