import React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { ITimelineDataByCarNormalised, ITimelineItem } from 'timelineModels'
import { IUser } from 'myModels'
import ReturnNav from '../../../atoms/header/returnNav'
import Loader from '../../../atoms/loader/loader'
import EntryOptionsMenuBar from '../../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import EntryGalleryPageTopHeaderDesktop from '../../../molecules/entryPageTopHeader/entryGalleryPageTopHeaderDesktop'
import EntryGalleryManagerDesktop from '../../../organisms/entryGalleryManager/entryGalleryManagerDesktop'
import EntryGalleryManagerMobile from '../../../organisms/entryGalleryManager/entryGalleryManagerMobile'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../../templates/displays/pageWrappers/CenteredPageWrapper'
import PortalInvisibleWrapper from '../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import WindowPageTemplate from '../../../templates/windowPageTemplate/windowPageTemplateDesktop'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { ICar, IExternalCar } from 'entityModels'
import SlidingUpOnScrollBanner from '../../../atoms/header/slidingUpOnScrollBanner'
import ExternalCarSingleEntryGalleryImage from '../../../pages/car/externalCar/externalCarSingleEntryGalleryImage'
import CarHistoryFileEntryGalleryImageArchive from '../../../pages/archive/car/historyFileEntryGalleryImage'

const IpadAndMobilePadding = styled.div`
    padding-top: 0px;
    @media ${device.ipad} {
        padding-top: 50px;
    }
`

interface Props extends RouteComponentProps<any> {
    isActiveEntryLoading: boolean
    userLoggedIn: IUser | null
    sharedCar?: IExternalCar
    prevOwned?: ICar
    timelineData: ITimelineDataByCarNormalised | null | undefined
    isCollapsed: boolean
}

function mapStateToProps(state: RootState) {
    return {
        timelineData: state.timeline.timeline_data_normalised_by_car_id,
        isActiveEntryLoading: state.timeline.loading,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

const dispatchProps = {}

type State = {}

class ReadOnlyCarHistoryFileEntryGallery extends React.PureComponent<
    Props,
    State
> {
    state = {}

    render() {
        let {
            isActiveEntryLoading,
            timelineData,
            isCollapsed,
            sharedCar,
            prevOwned,
        } = this.props

        let carid: string = this.props.match.params.carid

        const params = new URLSearchParams(this.props.location.search)

        let image_id = params.get('id')

        let entryId = this.props.match.params.entryid
        let all_entries =
            timelineData && timelineData[carid] && timelineData[carid].entries

        let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
            function (entry: ITimelineItem) {
                return entry.id === entryId
            }
        )

        let activeEntry =
            all_entries &&
            indexOFActiveEntry !== undefined &&
            all_entries[indexOFActiveEntry]

        let currentExternalCar = sharedCar ?? prevOwned
        let linkPrefix = sharedCar ? '/shared' : '/archives'

        return (
            <>
                <CenteredPageWrapper fullwidth>
                    <Loader isLoading={isActiveEntryLoading} />
                    <Faded>
                        {activeEntry && (
                            <>
                                <DesktopDisplayOnly>
                                    <Faded>
                                        <div
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            <WindowPageTemplate
                                                isCollapsed={isCollapsed}
                                                hasPositionRelative
                                                rightSideContent={
                                                    <EntryOptionsMenuBar
                                                        carid={carid}
                                                        activeEntry={
                                                            activeEntry
                                                        }
                                                        noAddLabelButton
                                                        readOnlyMode
                                                    />
                                                }
                                                isExternalCar
                                                hideOnIpad
                                                stickyHeaderContent={
                                                    <EntryGalleryPageTopHeaderDesktop
                                                        item={activeEntry}
                                                        carID={carid}
                                                        uploadImage={() => {}}
                                                        readOnlyMode
                                                        sharedCar={sharedCar}
                                                    />
                                                }
                                            >
                                                <IpadAndMobilePadding />
                                                <EntryGalleryManagerDesktop
                                                    ids_list={
                                                        activeEntry.gallery_image_ids
                                                    }
                                                    openImgForm={() => {}}
                                                    readOnlyMode
                                                />
                                            </WindowPageTemplate>
                                        </div>
                                    </Faded>
                                </DesktopDisplayOnly>

                                {!image_id && (
                                    <IpadAndMobileDisplay>
                                        <div
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <Link
                                                to={{
                                                    pathname: image_id
                                                        ? `${linkPrefix}/car/${carid}/history-file/${activeEntry.id}/gallery`
                                                        : `${linkPrefix}/car/${carid}/history-file/entry`,
                                                    state: {
                                                        prevPath:
                                                            this.props.history
                                                                .location
                                                                .pathname,
                                                    },
                                                    search: `?entryid=${activeEntry.id}`,
                                                }}
                                            >
                                                <ReturnNav />
                                            </Link>
                                            {currentExternalCar && (
                                                <SlidingUpOnScrollBanner
                                                    text={
                                                        currentExternalCar.title
                                                            .length > 32
                                                            ? `${currentExternalCar.title.slice(
                                                                  0,
                                                                  34
                                                              )}...`
                                                            : currentExternalCar.title
                                                    }
                                                    customStyle={{
                                                        transform:
                                                            'translateY(50px)',
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div style={{ paddingTop: '50px' }} />
                                        <EntryGalleryManagerMobile
                                            ids_list={
                                                activeEntry.gallery_image_ids
                                            }
                                            openImgForm={() => {}}
                                            isExternalCar
                                        />
                                    </IpadAndMobileDisplay>
                                )}
                            </>
                        )}
                    </Faded>
                </CenteredPageWrapper>
                {image_id && (
                    <PortalInvisibleWrapper
                        onClick={() => {
                            this.props.history.push(
                                this.props.location.pathname
                            )
                        }}
                    >
                        {sharedCar ? (
                            <ExternalCarSingleEntryGalleryImage />
                        ) : (
                            <CarHistoryFileEntryGalleryImageArchive />
                        )}
                    </PortalInvisibleWrapper>
                )}
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ReadOnlyCarHistoryFileEntryGallery)
)
