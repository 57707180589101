import useThemes from '../../../../providers/theme/hooks'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../providers/theme/colours'
import image from '../../../../public/assets/images/journey/sellYourCar/welcome.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeButtonsWrapper,
    JourneyWelcomeImage,
    JourneyWelcomeNewLineOnMobile,
    JourneyWelcomeSubWrapperV2,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapper,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    start: () => void
    cancel: (carid: string) => void
}

const CustomImageMobile = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;

    height: auto;
    width: 1000px;

    transform: translateX(-10px);

    @media (min-width: 500px) {
        max-height: 50dvh;
    }

    @media (max-width: 450px) {
        max-height: 50dvh;
    }

    @media (max-width: 380px) {
        max-height: 40dvh;
    }
`

const JourneyWelcomeImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    /* flex: 1; */

    @media ${device.beyond_ipad_mobile} {
        margin-top: 2dvh;
        height: 50dvh;
        transform: translateX(30px);
    }
    @media ${device.mobile} {
        /* flex: 0; */
        width: 100dvw;

        /* margin-top: 2dvh;
        min-height: 50dvh; */
        transform: translateX(30px);
    }
`

export default function JourneySellYourCarWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()

    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapper>
                        <JourneyWelcomeSubWrapperV2>
                            <JourneyWelcomeTextWrapper>
                                <div
                                    style={{
                                        // not adding padding everywhere
                                        // as pattern should stay above
                                        paddingTop: '12px',
                                    }}
                                />

                                <JourneyWelcomeTitle>
                                    One day you might want to sell your car
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    Learn how Custodian can not only help you
                                    perform the perfect handover to a new owner,
                                    but how using our platform can increase the
                                    value of your car.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>

                            <div
                                style={{
                                    paddingTop: '40px',
                                }}
                            />

                            <JourneyWelcomeImageWrapper>
                                <FadedSlower isFullWidth={true}>
                                    <JourneyWelcomeImage
                                        src={image}
                                        placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapper>

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn
                                    onClick={start}
                                    bg={
                                        journey_colours[theme].section
                                            .sell_your_car.primary_600
                                    }
                                >
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: journey_colours[theme]
                                                .section.sell_your_car
                                                .primary_600,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapperV2>
                    </JourneyWelcomeWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapper>
                    <JourneyWelcomeSubWrapperV2>
                        <div
                            style={{
                                paddingTop: '8px',
                            }}
                        />

                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '26px',
                                maxWidth: '90vw',
                            }}
                        >
                            One day you might want{' '}
                            <JourneyWelcomeNewLineOnMobile />
                            <span
                                style={{
                                    color: journey_colours[theme].section
                                        .sell_your_car.primary_500,
                                    fontSize: '28px',
                                }}
                            >
                                to sell your car{' '}
                            </span>{' '}
                        </JourneyWelcomeTitle>

                        <div
                            style={{
                                paddingTop: '36px',
                            }}
                        />

                        <JourneyWelcomeImageWrapper>
                            <Faded width={'auto'}>
                                <CustomImageMobile
                                    src={image}
                                    placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapper>
                        <JourneyWelcomeText
                            style={{
                                maxWidth: '80vw',
                            }}
                        >
                            Learn how Custodian can not only help you perform
                            the perfect handover to a new owner, but how using
                            our platform can increase the value of your car.
                        </JourneyWelcomeText>
                        <JourneyWelcomeButtonsWrapper>
                            <StandardCtaBtn
                                onClick={start}
                                customWidth="100%"
                                bg={
                                    journey_colours[theme].section.sell_your_car
                                        .primary_600
                                }
                            >
                                Let's get started
                            </StandardCtaBtn>
                        </JourneyWelcomeButtonsWrapper>
                    </JourneyWelcomeSubWrapperV2>
                </JourneyWelcomeWrapper>
            </IpadAndMobileDisplay>
        </>
    )
}
