import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import {
    IFilterTimelineByCategoryNLabelNTitleQCarIDRequest,
    IGetTimelineItemByIdPayloadRequest,
    ITimelineCreationFormFieldsData,
    ITimelineDataByCarNormalised,
    ITimelineDataToBeSubmittedObj,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'
import {
    ILabel,
    ITimelineCreateForms,
    ITimelineEditForms,
    IUser,
} from 'myModels'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'

import { editFormsActions } from '../../../redux/editForms/reducer'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'

import {
    addTimelineItemToCarRequest,
    getAllTimelineItemsByCarIDRequest,
    filterTimelineItemsByCarCategoryIdsNLabelIdsRequest,
} from '../../../redux/timeline/actions/requestActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import TimelineCreateManagerDesktop from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerDesktop'
import TimelineCreateManagerMobile from '../../organisms/timelineForms/timelineCreateManager/timelineCreateManagerMobile'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import { clearDataToBeSubmittedSuccess } from '../../../redux/timeline/actions/successActions'
import Loader from '../../atoms/loader/loader'

import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { ICar } from 'entityModels'

interface Props extends RouteComponentProps<any> {
    activeEntry: ITimelineItem | undefined | null
    timelineItemEditForm: ITimelineEditForms
    manageTimelineItemEditForm: any
    dataCyId?: string
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    setCurrentCar: any
    carsData: {
        [key: string]: ICar
    }
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted: ITimelineDataToBeSubmittedObj
    addTimelineItemToCarRequest: (payload: {
        carid: string
        item: ITimelineItem
    }) => void
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemByIdPayloadRequest
    ) => void
    labels_searchable_list: ILabel[] | null | undefined
    getCarDataByIdRequest: (carid: string) => {}
    clearDataToBeSubmitted: (payload: boolean) => void
    setInitialLandingUrl: (str: string) => void
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        carsData: state.entities.carsData.cars,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        timeline_creation_steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        timeline_creation_steps_object_of_indexed_list:
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list,
        data_to_be_submitted:
            state.timeline.formsData.creation.data_to_be_submitted,
        category_searchable_items_list:
            state.timeline.categories.category_searchable_items_list,
        userTimelineCustomTags:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
        labels_searchable_list: state.user.userLoggedIn?.labels,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
    addTimelineItemToCarRequest: (payload: {
        carid: string
        item: ITimelineItem
    }) => addTimelineItemToCarRequest(payload),
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemByIdPayloadRequest
    ) => getAllTimelineItemsByCarIDRequest(payload),
    filterTimelineItemsByCarCategoryIdsNLabelIdsRequest: (
        payload: IFilterTimelineByCategoryNLabelNTitleQCarIDRequest
    ) => filterTimelineItemsByCarCategoryIdsNLabelIdsRequest(payload),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    clearDataToBeSubmitted: (payload: boolean) =>
        clearDataToBeSubmittedSuccess(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

type State = {}

class CreateTimelineEntry extends React.PureComponent<Props, State> {
    state = {}

    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/history-file/create`
        )

        window.scrollTo(0, 0)

        let carid = this.props.match.params.carid
        this.props.setCurrentCar(carid)

        if (!this.props.carsData[carid]) {
            this.props.getCarDataByIdRequest(this.props.match.params.carid)
        }

        if (window.innerWidth > 880) {
            this.props &&
                this.props.manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: 0,
                    active_creation:
                        TimelineCreationTypeIdsEnum.add_new_desktop,
                })
        } else {
            this.props &&
                this.props.manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: 0,
                    active_creation: TimelineCreationTypeIdsEnum.add_new,
                })
        }
    }

    componentWillUnmount() {
        this.props.manageTimelineCreationFormState({
            isOpen: false,
            current_step_index: 0,
            active_creation: null,
        })

        this.props.clearDataToBeSubmitted(true)
    }

    render() {
        let carid = this.props.match.params.carid

        let {
            manageTimelineCreationFormState,
            timeline_creation_forms_current_state,
            timeline_creation_steps_data,
            timeline_creation_steps_object_of_indexed_list,
            data_to_be_submitted,
            labels_searchable_list,
        } = this.props

        let closeFormAndDirectToPrevPath = () => {
            manageTimelineCreationFormState({
                isOpen: false,
                current_step_index: 0,
                active_creation: null,
            })
            this.props.clearDataToBeSubmitted(true)
        }

        return (
            <Faded>
                <CenteredPageWrapper fullwidth>
                    <Loader
                        isLoading={
                            timeline_creation_forms_current_state.isOpen ===
                            false
                        }
                    />
                    <DesktopDisplayOnly>
                        <TimelineCreateManagerDesktop
                            entityID={
                                data_to_be_submitted.entry &&
                                data_to_be_submitted.entry.id
                                    ? data_to_be_submitted.entry?.id
                                    : 'NEW_entryUID'
                            }
                            carID={carid}
                            entityType="entry"
                            data_to_be_submitted={data_to_be_submitted}
                            timeline_creation_forms_current_state={
                                timeline_creation_forms_current_state
                            }
                            timeline_creation_steps_data={
                                timeline_creation_steps_data
                            }
                            manageTimelineCreationFormState={
                                manageTimelineCreationFormState
                            }
                            timeline_creation_steps_object_of_indexed_list={
                                timeline_creation_steps_object_of_indexed_list
                            }
                            close_create_form={closeFormAndDirectToPrevPath}
                        />
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <BottomUpSlider
                            show={
                                this.props.timeline_creation_forms_current_state
                                    .isOpen
                            }
                        >
                            <TimelineCreateManagerMobile
                                data_to_be_submitted={data_to_be_submitted}
                                timeline_creation_forms_current_state={
                                    timeline_creation_forms_current_state
                                }
                                timeline_creation_steps_data={
                                    timeline_creation_steps_data
                                }
                                manageTimelineCreationFormState={
                                    manageTimelineCreationFormState
                                }
                                timeline_creation_steps_object_of_indexed_list={
                                    timeline_creation_steps_object_of_indexed_list
                                }
                                close_create_form={closeFormAndDirectToPrevPath}
                                // close_create_form={close_create_form_mobile}
                                labels_searchable_list={
                                    labels_searchable_list
                                        ? labels_searchable_list
                                        : []
                                }
                                entityID={
                                    data_to_be_submitted.entry &&
                                    data_to_be_submitted.entry.id
                                        ? data_to_be_submitted.entry?.id
                                        : 'NEW_entryUID'
                                }
                                carID={carid}
                                entityType="entry"

                                // submitDataRequest={() =>
                                //     addTimelineItemToCarRequest({
                                //         carid: carid,
                                //         item: data_to_be_submitted.entry,
                                //     })
                                // }
                            />
                        </BottomUpSlider>
                    </IpadAndMobileDisplay>
                </CenteredPageWrapper>
            </Faded>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CreateTimelineEntry)
)
