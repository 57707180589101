import { IAddressInsuranceNormalised } from '../../../insuranceQuoteApplication/reducer'
import { IInsuranceApplication_API } from '../../../insuranceQuoteApplication/types'

// for both external vehicles + custodian cars
let car_addresses_field_ids: string[] = [
    'daytime_location',
    'eveningtime_location',
]

// named drivers + customers
let customer_addresses_field_ids: string[] = [
    'residential_address',
    'correspondence_address',
]

export const convertApplicationToAddressesNormalised = (
    application: IInsuranceApplication_API
): IAddressInsuranceNormalised => {
    let res: IAddressInsuranceNormalised = {}

    // customer

    if (application.customer) {
        for (let i = 0; i < customer_addresses_field_ids.length; i++) {
            let uidd: string | undefined =
                // @ts-ignore
                application.customer[customer_addresses_field_ids[i]] &&
                // @ts-ignore
                application.customer[customer_addresses_field_ids[i]].uid
                    ? // @ts-ignore
                      application.customer[customer_addresses_field_ids[i]].uid
                    : undefined
            if (uidd && !res[uidd]) {
                res = {
                    ...res,
                    [uidd]: {
                        // @ts-ignore
                        ...application.customer[
                            customer_addresses_field_ids[i]
                        ],
                    },
                }
            }
        }
    }

    // named drivers
    if (
        application.customer &&
        application.customer.named_drivers &&
        application.customer?.named_drivers.length > 0
    ) {
        application.customer?.named_drivers.forEach((named_driver) => {
            for (let i = 0; i < customer_addresses_field_ids.length; i++) {
                let uidd: string | undefined =
                    // @ts-ignore
                    named_driver[customer_addresses_field_ids[i]] &&
                    // @ts-ignore
                    named_driver[customer_addresses_field_ids[i]].uid
                        ? // @ts-ignore
                          named_driver[customer_addresses_field_ids[i]].uid
                        : undefined
                if (uidd && !res[uidd]) {
                    res = {
                        ...res,
                        [uidd]: {
                            // @ts-ignore
                            ...named_driver[customer_addresses_field_ids[i]],
                        },
                    }
                }
            }
        })
    }
    // car custodians

    if (
        application.customer &&
        application.customer.custodian_cars &&
        application.customer?.custodian_cars.length > 0
    ) {
        application.customer?.custodian_cars.forEach((custodian_car) => {
            for (let i = 0; i < car_addresses_field_ids.length; i++) {
                let uidd: string | undefined =
                    // @ts-ignore
                    custodian_car[car_addresses_field_ids[i]] &&
                    // @ts-ignore
                    custodian_car[car_addresses_field_ids[i]].uid
                        ? // @ts-ignore
                          custodian_car[car_addresses_field_ids[i]].uid
                        : undefined
                if (uidd && !res[uidd]) {
                    res = {
                        ...res,
                        [uidd]: {
                            // @ts-ignore
                            ...custodian_car[car_addresses_field_ids[i]],
                        },
                    }
                }
            }
        })
    }

    // external cars

    if (
        application.customer &&
        application.customer.external_vehicles &&
        application.customer?.external_vehicles.length > 0
    ) {
        application.customer?.external_vehicles.forEach((external_car) => {
            for (let i = 0; i < car_addresses_field_ids.length; i++) {
                let uidd: string | undefined =
                    // @ts-ignore
                    external_car[car_addresses_field_ids[i]] &&
                    // @ts-ignore
                    external_car[car_addresses_field_ids[i]].uid
                        ? // @ts-ignore
                          external_car[car_addresses_field_ids[i]].uid
                        : undefined
                if (uidd && !res[uidd]) {
                    res = {
                        ...res,
                        [uidd]: {
                            // @ts-ignore
                            ...external_car[car_addresses_field_ids[i]],
                        },
                    }
                }
            }
        })
    }

    // check with addresses
    if (application.customer && application.customer?.addressbook) {
        application.customer.addressbook.forEach((address) => {
            if (address.uid && !res[address.uid]) {
                res[address.uid] = { ...address }
            }
        })
    }

    return res
}
