import styled from 'styled-components'

const NbrBtn = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    cursor: pointer;

    transition: all 100ms;
    :hover {
        background: '#FAFAFA';
    }
`

type Props = {
    currentPage: number
    pageNumber: number
    onClickPageNumber: (n: number) => any
}

export const PaginationNumber = (props: Props) => {
    let { pageNumber, currentPage, onClickPageNumber } = props

    return (
        <NbrBtn
            style={{
                backgroundColor:
                    currentPage + 1 === pageNumber ? '#FAFAFA' : 'none',
            }}
            key={Math.random()}
            onClick={() => {
                onClickPageNumber(pageNumber)
            }}
        >
            {pageNumber}
        </NbrBtn>
    )
}
