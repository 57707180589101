import { call, fork, put, take } from 'redux-saga/effects'

import { api } from '../../../services'
import { ICustomErrorData, IDropdownItem, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'

export function* setMakeDropdownDataApi(returnData?: boolean) {
    try {
        const dataReceived: IDropdownItem[] =
            yield api.dropdownsData.get_car_make_dropdown_api()
        yield put(dropDownActions.setMakeDropdownDataSuccess(dataReceived))

        if (returnData) {
            return dataReceived
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setMakeDropdownDataApi,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_message_detail: 'Check your connection and refresh',
                custom_user_action_text: 'Refresh page',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            yield put(dropDownActions.setMakeDropdownDataError(customError))
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(dropDownActions.setMakeDropdownDataError(typedError))
        }
    }
}

function* watcherSetMakeDropdownData() {
    while (true) {
        yield take(dropDownActions.setMakeDropdownDataRequest)
        yield call(setMakeDropdownDataApi)
    }
}

const set_make_dropdown_data: any[] = [fork(watcherSetMakeDropdownData)]
export default set_make_dropdown_data
