import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const ThreeDotsIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ? Number(size) * 4 : '16'}
            height={size ?? '4'}
            viewBox="0 0 16 4"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 2C4 3.10457 3.10457 4 2 4C0.89543 4 -2.7141e-07 3.10457 -1.74846e-07 2C-7.8281e-08 0.895431 0.895431 7.8281e-08 2 1.74846e-07C3.10457 2.7141e-07 4 0.895431 4 2Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.895431 6.89543 7.8281e-08 8 1.74846e-07C9.10457 2.7141e-07 10 0.895431 10 2Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.895431 12.8954 7.8281e-08 14 1.74846e-07C15.1046 2.7141e-07 16 0.895431 16 2Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default ThreeDotsIcon
