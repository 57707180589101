import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'

// import Loader from "../atoms/loader/loader";

import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
    deleteCarRequest,
    getCarDataByIdRequest,
} from '../../../redux/entities/cars/actions/loadingActions'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import BlueTextHeader from '../../atoms/typography/blueTextHeader'
import CarDeletionListItemDesktop from '../../atoms/list/carDeletionListItemDesktop'
import CarDeletionFormDesktop from '../../organisms/editForms/carDeletionForm/carDeletionFormDesktop'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CarDeletionListItemMobile from '../../atoms/list/carDeletionListItemMobile'
import CarDeletionFormMobile from '../../organisms/editForms/carDeletionForm/carDeletionFormMobile'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { IUser } from 'myModels'
import { ICar } from 'entityModels'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        background-color: var(--bg-color, #fff);
        padding-left: 50px;
        padding-right: 50px;
        min-height: 100vh;
    }

    @media ${device.mobile} {
        padding-bottom: 60px;
    }
`
const HeightTop = styled.section`
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @media ${device.beyond_ipad_mobile} {
        position: relative;
        border-radius: 5px;
        background-color: var(--primary_08);
        height: 410px;
    }
    @media ${device.smallest_laptops} {
        position: relative;
        height: 360px;
    }
    @media ${device.mobile} {
        height: 230px;
        width: 100vw;
        align-items: center;
    }
    @media ${device.ipad} {
        height: 250px;
    }
`

const ImageStyle = styled.div`
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    transition: opacity 400ms;

    @media ${device.mobile} {
        height: 160px;
        width: 90vw;
        border-radius: 5px;
    }
`

const MobileColumnBody = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    padding-right: 7vw;
    padding-left: 7vw;
`

const dispatchProps = {
    deleteCarRequest: (carid: string) => deleteCarRequest(carid),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        carsData: state.entities.carsData.cars,
    }
}

type State = {}

interface Props extends RouteComponentProps<any> {
    deleteCarRequest: (carid: string) => void
    userLoggedIn: IUser | null
    userid: string | null
    carsData: {
        [key: string]: ICar
    }
    getCarDataByIdRequest: (carid: string) => void
    setInitialLandingUrl: (str: string) => void
    setCurrentCar: (carid: string) => void
    location: any
}

class CarDeletionConfirmation extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/deletion`
        )
        this.props.setCurrentCar(this.props.match.params.carid)
        this.props.getCarDataByIdRequest(this.props.match.params.carid)
    }

    // componentDidUpdate(prevProps: Props) {
    //     let carid = this.props.match.params.carid

    //     if (this.props.carsData !== prevProps.carsData) {
    //         this.props.getCarDataByIdRequest(carid)
    //     }
    // }

    deletionItemsCheckListText = [
        'all images and other assets uploaded on the platform',
        'all the car’s related Tasks',
        'all information provided such as key moments and facts, description, and technical details ',
    ]

    determineCancelPath = (carid: string) => {
        if (this.props.location.state?.prevPath.includes('previously-owned')) {
            this.props.history.push(`/garage/previously-owned`)
        } else {
            this.props.history.push(`/car/${carid}`)
        }
    }

    render() {
        let carid = this.props.match.params.carid
        let current = this.props.carsData[carid]
        let { deleteCarRequest } = this.props
        return (
            <Wrapper>
                {current !== null && current !== undefined && (
                    <CenteredPageWrapper>
                        <DesktopDisplayOnly>
                            {current.banner_url !== undefined &&
                                current.banner_url.url.length > 1 && (
                                    <HeightTop>
                                        <ImageStyle
                                            role="img"
                                            aria-label="car overview banner"
                                            style={{
                                                backgroundImage: `url(${current.banner_url.url})`,
                                            }}
                                        />
                                    </HeightTop>
                                )}
                            <div style={{ paddingTop: '50px' }} />
                            <BlueTextHeader>
                                Deleting the
                                {current.title
                                    ? ' ' + current.title + ' '
                                    : ' car '}
                                will also automatically delete...
                            </BlueTextHeader>

                            <div style={{ paddingTop: '50px' }} />

                            {this.deletionItemsCheckListText.map(
                                (item: string, index: number) => {
                                    return (
                                        <CarDeletionListItemDesktop key={index}>
                                            {item}
                                        </CarDeletionListItemDesktop>
                                    )
                                }
                            )}

                            <div style={{ paddingTop: '50px' }} />

                            <CarDeletionFormDesktop
                                carid={carid}
                                cancel={() => this.determineCancelPath(carid)}
                                submitFunc={(carid: string) => {
                                    deleteCarRequest(carid)
                                }}
                                car_title={
                                    current.title
                                        ? current.title
                                        : 'Untitled Car'
                                }
                            />
                        </DesktopDisplayOnly>
                        <IpadAndMobileDisplay>
                            {current.banner_url !== undefined &&
                                current.banner_url.url.length > 1 && (
                                    <HeightTop>
                                        <ImageStyle
                                            role="img"
                                            aria-label="car overview banner"
                                            style={{
                                                backgroundImage: `url(${current.banner_url.url})`,
                                            }}
                                        />
                                    </HeightTop>
                                )}
                            <MobileColumnBody>
                                {current.banner_url === undefined && (
                                    <div style={{ paddingTop: '50px' }} />
                                )}

                                <BlueTextHeader>
                                    Deleting the
                                    {current.title
                                        ? ' ' + current.title + ' '
                                        : ' car '}
                                    will also automatically delete...
                                </BlueTextHeader>

                                <div style={{ paddingTop: '30px' }} />

                                {this.deletionItemsCheckListText.map(
                                    (item: string, index: number) => {
                                        return (
                                            <CarDeletionListItemMobile
                                                key={index}
                                            >
                                                {item}
                                            </CarDeletionListItemMobile>
                                        )
                                    }
                                )}

                                <div style={{ paddingTop: '30px' }} />

                                <CarDeletionFormMobile
                                    carid={carid}
                                    cancel={() =>
                                        this.determineCancelPath(carid)
                                    }
                                    submitFunc={(carid: string) => {
                                        deleteCarRequest(carid)
                                    }}
                                    car_title={
                                        current.title
                                            ? current.title
                                            : 'Untitled Car'
                                    }
                                />
                            </MobileColumnBody>
                        </IpadAndMobileDisplay>
                    </CenteredPageWrapper>
                )}
            </Wrapper>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarDeletionConfirmation)
)
