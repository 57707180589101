import { createAction } from '@reduxjs/toolkit'

import {
    GET_APEX_CARS_OF_THE_WEEK_REQUEST,
    GET_APEX_HOMEPAGE_CONTENT_REQUEST,
    GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_REQUEST,
    GET_APEX_PODCASTS_REQUEST,
    GET_APEX_SINGLE_INTERVIEW_BY_UID_REQUEST,
    GET_WEEKLY_HIGHLIGHTS_REQUEST,
    GET_APEX_SINGLE_ARTICLE_BY_UID_REQUEST,
    GET_APEX_ARTICLE_OVERVIEWS_REQUEST,
} from './constants'

export const getApexHomepageContentRequest = createAction(
    GET_APEX_HOMEPAGE_CONTENT_REQUEST
)

export const getApexCarsOfTheWeekRequest = createAction(
    GET_APEX_CARS_OF_THE_WEEK_REQUEST
)

export const getApexPodcastsRequest = createAction(GET_APEX_PODCASTS_REQUEST)

export const getWeeklyHighlightsRequest = createAction(
    GET_WEEKLY_HIGHLIGHTS_REQUEST
)

export const getApexInterviewOverviewsRequest = createAction(
    GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_REQUEST
)

export const getApexSingleInterviewByUIDRequest = createAction(
    GET_APEX_SINGLE_INTERVIEW_BY_UID_REQUEST
)

export const getApexSingleArticleByUIDRequest = createAction(
    GET_APEX_SINGLE_ARTICLE_BY_UID_REQUEST
)

export const getApexArticleOverviewsRequest = createAction(
    GET_APEX_ARTICLE_OVERVIEWS_REQUEST
)
