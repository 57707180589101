import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

const InterviewCategory = styled.div`
    @media ${device.mobile_and_ipad} {
        font-family: lato;
        color: var(--text-strong, #1a1a1a);
        font-size: 18px;
        align-self: center !important;
        padding-bottom: 10px;
        text-transform: uppercase;
    }

    @media ${device.beyond_ipad_mobile} {
        font-family: lato;
        color: var(--text-strong, #1a1a1a);
        font-size: 24px;
        align-self: center;
        padding-bottom: 10px;
        text-transform: uppercase;
    }
`
const InterviewCategoryLine = styled.div`
    @media ${device.mobile_and_ipad} {
        width: 20px;
        background-color: var(--text-strong, #1a1a1a);
        height: 1px;
    }

    @media ${device.beyond_ipad_mobile} {
        width: 50px;
        background-color: var(--text-strong, #1a1a1a);
        height: 1px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export interface IHeaderProps {
    name: string
    css_id?: string
}

const ApexInterviewCategoryHeaderAtom: React.FunctionComponent<IHeaderProps> = (
    props
) => (
    <Wrapper id={props.css_id && props.css_id}>
        <InterviewCategory>{props.name}</InterviewCategory>
        <InterviewCategoryLine />
    </Wrapper>
)

export default ApexInterviewCategoryHeaderAtom
