export const numberWithCommas = (payload: {
    numberToConvert: number
    decimals?: number
    round?: 'ceil' | 'round' | 'floor'
}) => {
    if (payload.decimals) {
        if (payload.round) {
            if (payload.round === 'ceil') {
                return Math.ceil(payload.numberToConvert)
                    .toFixed(payload.decimals)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } else if (payload.round === 'floor') {
                return Math.floor(payload.numberToConvert)
                    .toFixed(payload.decimals)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            } else {
                return Math.round(payload.numberToConvert)
                    .toFixed(payload.decimals)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
        } else
            return payload.numberToConvert
                .toFixed(payload.decimals)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else
        return payload.numberToConvert
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
