import { call, fork, put, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IReduxError } from 'entityModels'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { IAccDelItems } from 'IapiDataPayload'
import { api } from '../../services'
import * as actions from '../actions/index'
import * as carsActions from '../../entities/cars/actions'
import * as garageActions from '../../entities/garages/actions'
import * as historyFileActions from '../../timeline/actions'
import * as tasksActions from '../../tasks/actions/successActions'
import * as technicalInformationActions from '../../entities/technical_information/actions/actions'
import * as highlightedFactsActions from '../../entities/highlighted_facts/actions/actions'
import * as galleriesActions from '../../entities/galleries/actions/actions'
import * as searchUsersActions from '../../searchUsers/actions/successActions'
import * as attachmentsActions from '../../attachments/actions/successActions'
import { push } from 'connected-react-router'
import posthog from 'posthog-js'
// import { push } from 'connected-react-router'

function* deleteAccountSaga(payload: IAccDelItems): any {
    try {
        // delete account
        yield api.accountDeletion.deleteAccount()

        // record deletion reasons in Xata

        let now = new Date()
        let created_at = now.toISOString()
        let xataPayload = { ...payload, created_at: created_at }

        yield api.accountDeletion.recordAccountDeletionFeedback(xataPayload)

        // clean up Redux of all cars and garages data
        yield put(carsActions.actions.cleanUpCarDataOnLogoutSuccess())
        yield put(garageActions.actions.cleanUpGaragesDataOnLogoutSuccess())
        yield put(
            historyFileActions.actions.cleanUpTimelineDataOnLogoutSuccess()
        )

        yield put(tasksActions.cleanUpTasksSuccess())
        yield put(
            technicalInformationActions.cleanUpTechnicalInformationSuccess()
        )
        yield put(highlightedFactsActions.cleanUpHighlightedFactsSuccess())
        yield put(galleriesActions.cleanUpGallerySuccess())
        yield put(searchUsersActions.cleanUpSearchUsersSuccess())
        yield put(attachmentsActions.cleanUpAttachmentsSuccess())

        localStorage?.removeItem('refresh_token')
        localStorage?.removeItem('user_id')

        yield put(actions.default.actions.deleteAccountSuccess())
        posthog.capture('USER ACCOUNT DELETED')

        yield put(push(`/account-deletion-confirmed`))

        return
    } catch (error: any) {
        if (error.status === 401) {
            let pa: IUnauthHandlerPayload = {
                functionToRepeat: deleteAccountSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, pa)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.default.errorActions.deleteAccountError(typedError)
            )
        }
    }
}

function* watcherDeleteAccount() {
    while (true) {
        const { payload } = yield take(
            actions.default.loadingActions.deleteAccountRequest.type
        )
        yield call(deleteAccountSaga, payload)
    }
}

const delete_account: any[] = [fork(watcherDeleteAccount)]

export default delete_account
