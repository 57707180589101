import * as Yup from 'yup'

export const RegisterSchema = Yup.object().shape({
    email: Yup.string().email('Email not valid').required('Email is required'),
    password: Yup.string()
        .required('Password is required')
        .min(8, 'Password needs to have at least 8 characters.'),
    passwordConfirm: Yup.string().required('Required'),
    firstName: Yup.string().test(
        'wordcount',
        'You must provide your first name',
        (val: any) => val && val.trim().length > 1
    ),
    lastName: Yup.string().test(
        'wordcount',
        'You must provide your last name',
        (val: any) => val && val.trim().length > 1
    ),

    platformConsent: Yup.bool().oneOf(
        [true],
        'You must agree to our Terms of Sevice & Privacy Policy'
    ),
})
