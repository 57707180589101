import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const PoleIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.5691 19.0857C3.86229 18.8434 4.25121 18.6414 4.71429 18.6414H19.2857C19.7488 18.6414 20.1377 18.8434 20.4309 19.0857C20.7236 19.3275 20.9612 19.6407 21.146 19.9577C21.5082 20.579 21.75 21.3603 21.75 22.0001C21.75 22.4143 21.4142 22.7501 21 22.7501H3C2.58579 22.7501 2.25 22.4143 2.25 22.0001C2.25 21.3603 2.49182 20.579 2.85403 19.9577C3.03882 19.6407 3.27645 19.3275 3.5691 19.0857ZM3.90284 21.2501H20.0972C20.0334 21.07 19.9506 20.8855 19.8501 20.7131C19.7247 20.498 19.593 20.3391 19.4754 20.242C19.3584 20.1453 19.2962 20.1414 19.2857 20.1414H4.71429C4.70379 20.1414 4.64163 20.1453 4.52461 20.242C4.40704 20.3391 4.27529 20.498 4.1499 20.7131C4.04941 20.8855 3.96656 21.07 3.90284 21.2501Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99513 1.78732C10.0894 1.46866 10.382 1.25 10.7143 1.25H13.2858C13.6181 1.25 13.9108 1.46866 14.005 1.78732L19.1478 19.1786C19.2653 19.5758 19.0385 19.9931 18.6413 20.1105C18.2441 20.228 17.8269 20.0012 17.7094 19.604L12.7255 2.75H11.2747L6.2907 19.604C6.17324 20.0012 5.75601 20.228 5.3588 20.1105C4.96159 19.9931 4.73481 19.5758 4.85227 19.1786L9.99513 1.78732Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.10693 15.9131C6.10693 15.4989 6.44272 15.1631 6.85693 15.1631H17.1426C17.5569 15.1631 17.8926 15.4989 17.8926 15.9131C17.8926 16.3273 17.5569 16.6631 17.1426 16.6631H6.85693C6.44272 16.6631 6.10693 16.3273 6.10693 15.9131Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.96436 13.3044C6.96436 12.8902 7.30014 12.5544 7.71436 12.5544H16.2858C16.7 12.5544 17.0358 12.8902 17.0358 13.3044C17.0358 13.7187 16.7 14.0544 16.2858 14.0544H7.71436C7.30014 14.0544 6.96436 13.7187 6.96436 13.3044Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.25 8.95654C8.25 8.54233 8.58579 8.20654 9 8.20654H15C15.4142 8.20654 15.75 8.54233 15.75 8.95654C15.75 9.37076 15.4142 9.70654 15 9.70654H9C8.58579 9.70654 8.25 9.37076 8.25 8.95654Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.10693 6.3479C9.10693 5.93369 9.44272 5.5979 9.85693 5.5979H14.1426C14.5569 5.5979 14.8926 5.93369 14.8926 6.3479C14.8926 6.76211 14.5569 7.0979 14.1426 7.0979H9.85693C9.44272 7.0979 9.10693 6.76211 9.10693 6.3479Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default PoleIcon
