import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
import Faded from '../../templates/animated/faded'
import SectionHeader from '../../atoms/header/section/sectionHeader'
import { getGarageByUserIdRequest } from '../../../redux/entities/garages/actions/loadingActions'

import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import HelpTutorialSectionDesktop from '../../molecules/helpTutorialsSection/helpTutorialSectionDesktop'
import HelpTutorialSectionMobile from '../../molecules/helpTutorialsSection/helpTutorialSectionMobile'

import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { IGarageObject } from 'entityModels'

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        garage_id:
            state.user.userLoggedIn && state.user.userLoggedIn.owns_garage?.uid,
        garage_data: state.entities.garagesData.garages,
    }
}

type State = {}

interface Props {
    userLoggedIn: null | IUser
    getUserDataRequest: () => void
    userid: string | null
    garage_data: IGarageObject
    getGarageCarsDataRequest: (id: string) => void
    garage_id: string | null
    setInitialLandingUrl: (str: string) => void
}

class TutorialsHelp extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        this.props.setInitialLandingUrl('/account/tutorials')

        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }
    }

    render() {
        const { userLoggedIn, garage_id, garage_data } = this.props

        let carId: string | null =
            garage_id &&
            garage_data[garage_id] &&
            garage_data[garage_id].sorted_car_ids
                ? //@ts-ignore
                  garage_data[garage_id].sorted_car_ids[
                      //@ts-ignore
                      garage_data[garage_id].sorted_car_ids.length - 1
                  ]
                : null

        return (
            <CenteredPageWrapper hasMobileSubNav>
                {userLoggedIn && (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title={'Tutorials'}
                                    removeEdit={true}
                                />

                                <div style={{ marginTop: '20px' }} />

                                <HelpTutorialSectionDesktop carId={carId} />
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            {/* <Link
                                to={{
                                    pathname: `/account`,
                                    state: {
                                        prevPath:
                                            this.props.match.url,
                                    },
                                }}
                            >
                                <ReturnNav 
                                    customText='Tutorials'
                                    customTextColor='#0c0c0c'
                                    isUppercase={true}
                                    chevron={true}
                                />                                            
                            </Link>
                            <AccountIcon to="/account">                                                                    
                                <IconItem src={account_default} alt="account" />                                
                            </AccountIcon> */}
                            {/* <MobileSettingsSectionHeader
                                id="tutorials-mobile"
                                title="Tutorials"
                            /> */}
                            <div style={{ height: '40px' }} />
                            <HelpTutorialSectionMobile carId={carId} />
                            <div style={{ height: '100px' }} />
                        </IpadAndMobileDisplay>
                    </Faded>
                )}
            </CenteredPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(TutorialsHelp)
