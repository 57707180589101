import * as React from 'react'
import styled from 'styled-components'

import no_cars_placeholder from '../skeleton/garage/1.png'
import no_cars_placeholder_dark from '../skeleton/garage/1_dark.png'
import useThemes from '../../../providers/theme/hooks'

const Wrap = styled.section`
    width: 100%;
`

const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
`

const GreyWrapper = styled.div`
    width: 100%;
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--garage-placeholder-gradient-1, rgba(26, 26, 26, 0.02));
`

const ImageWrapper = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 4px;
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
`

interface Props {}

const EmptyVehicleItem: React.FunctionComponent<Props> = (props) => {
    const { theme } = useThemes()
    return (
        <Wrap>
            <ListItem>
                <ImageWrapper>
                    <Image
                        src={
                            theme === 'dark'
                                ? no_cars_placeholder_dark
                                : no_cars_placeholder
                        }
                    />
                </ImageWrapper>
                <GreyWrapper />
            </ListItem>
        </Wrap>
    )
}

export default EmptyVehicleItem
