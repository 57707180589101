type Props = {
    height?: string
    width?: string
}

const InsuranceBgPattern = ({ height, width }: Props) => {
    return (
        <svg
            width={width ?? '520'}
            height={height ?? '520'}
            viewBox="0 0 520 520"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.06"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M60.043 376.15C60.043 201.568 201.57 60.041 376.152 60.041C550.735 60.041 692.262 201.568 692.262 376.15C692.262 550.733 550.735 692.26 376.152 692.26C201.57 692.26 60.043 550.733 60.043 376.15ZM376.152 0.0410156C168.433 0.0410156 0.0429688 168.431 0.0429688 376.15C0.0429688 583.87 168.433 752.26 376.152 752.26C583.872 752.26 752.262 583.87 752.262 376.15C752.262 168.431 583.872 0.0410156 376.152 0.0410156ZM383.326 181.986C276.092 181.986 189.162 268.916 189.162 376.15C189.162 483.384 276.092 570.314 383.326 570.314C490.56 570.314 577.49 483.384 577.49 376.15C577.49 268.916 490.56 181.986 383.326 181.986ZM129.162 376.15C129.162 235.779 242.955 121.986 383.326 121.986C523.697 121.986 637.49 235.779 637.49 376.15C637.49 516.521 523.697 630.314 383.326 630.314C242.955 630.314 129.162 516.521 129.162 376.15ZM381.532 303.931C343.136 303.931 311.107 335.761 311.107 376.15C311.107 416.538 343.136 448.368 381.532 448.368C419.929 448.368 451.958 416.538 451.958 376.15C451.958 335.761 419.929 303.931 381.532 303.931ZM251.107 376.15C251.107 303.63 309.002 243.931 381.532 243.931C454.063 243.931 511.958 303.63 511.958 376.15C511.958 448.669 454.063 508.368 381.532 508.368C309.002 508.368 251.107 448.669 251.107 376.15Z"
                fill="url(#paint0_linear_2418_64845)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2418_64845"
                    x1="-14.2036"
                    y1="644.935"
                    x2="655.321"
                    y2="172.141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.00192485"
                        stop-color="#A5A8F2"
                        stop-opacity="0"
                    />
                    <stop offset="1" stop-color="#A5A8F2" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default InsuranceBgPattern
