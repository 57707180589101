import { call, fork, put, select, take } from 'redux-saga/effects'

import * as requestActions from '../actions/requestActions'
import * as successActions from '../actions/successActions'
import * as errorActions from '../actions/errorActions'
import { api } from '../../services'

import { IReduxError, ITask, ITaskRelationsPayload } from 'entityModels'

import { ITaskPayloadAPI } from 'IapiDataPayload'
import { ConvertSingleApiTask } from '../../conversions/tasks/taskTypeConversions'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { getTasksInState } from './addReminderToTask'

// LINK TASK

function* linkTaskSaga(payload: ITaskRelationsPayload): any {
    // later on : put API TRY CATH

    try {
        const updatedTask: ITaskPayloadAPI = yield call(
            api.tasks.relations.linkTask,
            payload
        )

        let tasksInState = yield select(getTasksInState)
        let taskData: ITask | undefined = tasksInState[payload.taskid]

        let updatedTaskConverted: ITask = ConvertSingleApiTask(updatedTask)

        if (taskData?.reminders && taskData.reminders.length > 0) {
            updatedTaskConverted.reminders = [...taskData.reminders]
        }

        yield put(successActions.linkTaskSuccess(updatedTaskConverted))

        // Capture event
        posthog.capture('UPDATE TASK', {
            property: 'linking to car or garage',
        })
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: linkTaskSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(errorActions.linkTaskError(typedError))
        }
    }
}

// UNLINK TASK

function* unlinkTaskSaga(payload: ITaskRelationsPayload): any {
    // later on : put API TRY CATH

    try {
        const updatedTask: ITaskPayloadAPI = yield call(
            api.tasks.relations.unlinkTask,
            payload
        )

        let tasksInState = yield select(getTasksInState)
        let taskData: ITask | undefined = tasksInState[payload.taskid]

        let updatedTaskConverted: ITask = ConvertSingleApiTask(updatedTask)

        if (taskData?.reminders && taskData.reminders.length > 0) {
            updatedTaskConverted.reminders = [...taskData.reminders]
        }

        yield put(successActions.unlinkTaskSuccess(updatedTaskConverted))

        // Capture event
        posthog.capture('UPDATE TASK', {
            property: 'unlinking from car or garage',
        })
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: unlinkTaskSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(errorActions.unlinkTaskError(typedError))
        }
    }
}

function* watcherLinkTask() {
    while (true) {
        const { payload } = yield take(requestActions.linkTaskRequest)

        yield call(linkTaskSaga, payload)
    }
}

function* watcherUnlinkTask() {
    while (true) {
        const { payload } = yield take(requestActions.unlinkTaskRequest)

        yield call(unlinkTaskSaga, payload)
    }
}

const relate_task: any[] = [fork(watcherLinkTask), fork(watcherUnlinkTask)]

export default relate_task
