import * as successActions from '../actions/successActions'

import { ThunkAction } from 'redux-thunk'
import { RootAction } from 'typesafe-actions'
import { ITimelineItem } from 'timelineModels'

// interface IGetState {
//     [key: string]: any
// }

export const setActiveEntryThunk = (
    payload: ITimelineItem | undefined
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(successActions.setActiveEntrySuccess(payload))
        })
    }
}
