import { generateBackEndValueDistanceUnit } from '../../../helpers/units/unitConversion'
import { removeUndefinedfromObject } from '../../helpers/objects'
import { ISearchShowroom_API_req_args } from '../../services/showroom/types'
import { IShowroomFiltersState } from '../../showroom/types'

type IRange = {
    min?: number | undefined
    max?: number | undefined
    operator?: '<' | '<=' | '>' | '=' | '>=' | undefined
}
const convertYearChoiceToRange = (p: {
    id?: string
    name?: string
    caption?: string
    value?: number | string
    options?: any[]
    valueFrom?: number | null | undefined
    valueTo?: number | null | undefined
    isActive?: boolean
    displayValue?: string
}): IRange => {
    let res: IRange = {
        min: p.valueFrom ?? undefined,
        max: p.valueTo ?? undefined,
    }

    if (!p.valueFrom && !p.valueTo) {
        if (p.value) {
            res.min = +p.value
            res.max = +p.value + 9
        }
    }

    return res
}

export const ConvertSearchFiltersToAPIRes = (
    p: IShowroomFiltersState
): ISearchShowroom_API_req_args => {
    let res: ISearchShowroom_API_req_args = {
        // without subvalues
        makes: p.make.values ?? undefined,
        models: p.model.values ?? undefined,
        drive_sides: p.drive_side.value ? [p.drive_side.value] : undefined,
        body_types: p.body_type.values ?? undefined,
        exterior_colors: p.colour.values ?? undefined,
        types_of_sale: p.type_of_sale.value
            ? [p.type_of_sale.value]
            : undefined,
        keywords: p.keyword.values ?? undefined,

        //  ranges
        year: convertYearChoiceToRange(p.year),
        price: {
            min: p.price.valueFrom ?? undefined,
            max: p.price.valueTo ?? undefined,
        },
        mileage: {
            min: p.mileage.valueFrom
                ? generateBackEndValueDistanceUnit('miles', p.mileage.valueFrom)
                : undefined,
            max: p.mileage.valueTo
                ? generateBackEndValueDistanceUnit('miles', p.mileage.valueTo)
                : undefined,
        },

        location:
            p.location.values?.lng && p.location.values?.lat
                ? {
                      radius: p.location.values?.distance ?? 15,
                      lat: +p.location.values.lat,
                      lng: +p.location.values.lng,
                      unit: 'mi',
                  }
                : undefined,
        owner: {
            ids: p.owner?.owner_id ? [p.owner.owner_id] : undefined,
            display_names:
                p.owner && !p.owner.owner_id && p.owner.displayValue
                    ? [p.owner.displayValue]
                    : undefined,
        },
    }

    res = removeUndefinedfromObject(res)

    return res
}
