type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const SearchIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.5001 16.4395L23.0608 22.0001L22.0001 23.0608L16.4395 17.5001L17.5001 16.4395Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75ZM1.25 11C1.25 5.61522 5.61522 1.25 11 1.25C16.3848 1.25 20.75 5.61522 20.75 11C20.75 16.3848 16.3848 20.75 11 20.75C5.61522 20.75 1.25 16.3848 1.25 11Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default SearchIcon
