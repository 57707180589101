import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_fields_other_driver_track_record } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import PortalInvisibleWrapper from '../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceApplicationMobileSearch from '../search/mobileSearch'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IDropdownItem } from 'entityModels'
import { IRootState } from '../../../../../redux/store'
import { ISubfieldsValidationDraftCheck } from '../main_driver/insuranceMainDriverAdditionalDetailsApplicationFlow'
import {
    fields_with_date_validations_data,
    fields_with_date_validations_ids,
    isInsuranceApplicationDateValid,
    IsInsuranceApplicationDateValid_result,
} from '../../../../../redux/localdata/insuranceLocalData/datesValidation'
import RemoveMobileSearchAnchor from '../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

export type UserParams = {
    userid: string
}

const InsuranceOtherDriverTrackRecordApplicationFlow = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { userid } = useParams<UserParams>()

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.other_drivers[userid]
                ?.track_record ?? {}
        )
    })

    const entitiesObjArr = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid] &&
            state.insuranceQuoteApplication.draft.other_drivers[userid].entities
            ? state.insuranceQuoteApplication.draft.other_drivers[userid]
                  .entities
            : undefined
    })

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.other_drivers[userid]
                ?.track_record ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid]
            ?.track_record_errors
    })

    const entitiesErrNormalised = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid] &&
            state.insuranceQuoteApplication.draft.other_drivers[userid]
                .entities_error
            ? state.insuranceQuoteApplication.draft.other_drivers[userid]
                  .entities_error
            : undefined
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        if (errors && errors[id]) {
            setError(id, undefined)
        }

        if (id === 'has_any_previous_insurance_refusals' && answer === true) {
            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'has_previous_special_terms_imposed',
                    answer: undefined,
                    userid: userid,
                })
            )

            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'previous_special_terms_imposed_reason',
                    answer: undefined,
                    userid: userid,
                })
            )

            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'has_previous_motor_insurance_refused',
                    answer: undefined,
                    userid: userid,
                })
            )

            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'previous_motor_terms_refused_reason',
                    answer: undefined,
                    userid: userid,
                })
            )

            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'has_previous_motor_insurance_cancelled',
                    answer: undefined,
                    userid: userid,
                })
            )

            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'previous_motor_insurance_cancelled_reason',
                    answer: undefined,
                    userid: userid,
                })
            )

            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'has_previous_motor_insurance_voided',
                    answer: undefined,
                    userid: userid,
                })
            )

            dispatch(
                insuranceActions.set_answer_other_driver_trackRecord({
                    id: 'previous_motor_insurance_voided_reason',
                    answer: undefined,
                    userid: userid,
                })
            )
        }

        dispatch(
            insuranceActions.set_answer_other_driver_trackRecord({
                id: id,
                answer: answer,
                userid: userid,
            })
        )

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_other_driver_trackRecord({
                id: id,
                error: error,
                userid: userid,
            })
        )
    }

    const subfield_to_check_bool_and_data_obj_check: ISubfieldsValidationDraftCheck =
        {
            has_any_previous_insurance_refusals: {
                required_fields_if_true: [
                    'has_previous_special_terms_imposed',
                    'has_previous_motor_insurance_refused',
                    'has_previous_motor_insurance_cancelled',
                    'has_previous_motor_insurance_voided',
                ],
            },
            has_previous_special_terms_imposed: {
                required_fields_if_true: [
                    'previous_special_terms_imposed_reason',
                ],
            },
            has_previous_motor_insurance_refused: {
                required_fields_if_true: [
                    'previous_motor_terms_refused_reason',
                ],
            },
            has_previous_motor_insurance_cancelled: {
                required_fields_if_true: [
                    'previous_motor_insurance_cancelled_reason',
                ],
            },
            has_previous_motor_insurance_voided: {
                required_fields_if_true: [
                    'previous_motor_insurance_voided_reason',
                ],
            },

            has_motoring_convictions: {
                required_entity_if_true: {
                    name: 'motoring conviction',
                    uid: 'motoring_convictions',
                },
            },

            has_convictions: {
                required_entity_if_true: {
                    name: 'conviction',
                    uid: 'convictions',
                },
            },

            has_claims: {
                required_entity_if_true: {
                    name: 'claim',
                    uid: 'claims',
                },
            },
        }

    const validateEntities = (): boolean => {
        let hasErr = false
        let idss = Object.keys(subfield_to_check_bool_and_data_obj_check)

        for (let i = 0; i < idss.length; i++) {
            let key = idss[i]
            if (data_draft[key] === true || data_draft[key]?.uid === 'Yes') {
                if (
                    subfield_to_check_bool_and_data_obj_check[key] &&
                    subfield_to_check_bool_and_data_obj_check[key]
                        .required_fields_if_true
                ) {
                    for (
                        let a = 0;
                        a <
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_fields_if_true!.length;
                        a++
                    ) {
                        let required_field_id =
                            subfield_to_check_bool_and_data_obj_check[key]!
                                .required_fields_if_true![a]

                        if (data_draft[required_field_id] === undefined) {
                            hasErr = true
                            setError(required_field_id, 'Please specify.')
                        }
                    }
                }

                if (
                    subfield_to_check_bool_and_data_obj_check[key] &&
                    subfield_to_check_bool_and_data_obj_check[key]
                        .required_entity_if_true
                ) {
                    let entityUID =
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_entity_if_true!.uid

                    let entityName =
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_entity_if_true!.name

                    // @ts-ignore
                    if (entitiesObjArr[entityUID]?.length === 0) {
                        hasErr = true
                        setError(key, `Please add the ${entityName} below.`)
                    }

                    if (
                        // @ts-ignore
                        entitiesObjArr[entityUID] &&
                        // @ts-ignore
                        entitiesObjArr[entityUID][0]
                    ) {
                        // @ts-ignore
                        const obj = entitiesObjArr[entityUID][0]
                        let entityKeys = Object.keys(obj)

                        if (entityKeys.length === 0) {
                            hasErr = true
                            setError(key, `Please add the ${entityName} below.`)
                        }

                        if (obj[entityKeys[0]] === undefined) {
                            hasErr = true
                            setError(
                                key,
                                `Please fill in all the ${entityName} fields.`
                            )
                        }
                    }

                    if (
                        // @ts-ignore
                        entitiesObjArr[entityUID]
                    ) {
                        // @ts-ignore
                        let arrayOfObjToCheck = [...entitiesObjArr[entityUID]]

                        arrayOfObjToCheck.map((obj, i) => {
                            let objTocheck = { ...obj }

                            // date fielf check here

                            fields_with_date_validations_ids.forEach(
                                (fieldDateID) => {
                                    if (objTocheck[fieldDateID]) {
                                        let validation_depends_on_field_id =
                                            fields_with_date_validations_data[
                                                fieldDateID
                                            ]?.validation_depends_on_field_id

                                        let expected_value_of_dependable_field_for_validation =
                                            fields_with_date_validations_data[
                                                fieldDateID
                                            ]
                                                ?.expected_value_of_dependable_field_for_validation

                                        let value_of_dependable_field =
                                            validation_depends_on_field_id
                                                ? objTocheck[
                                                      validation_depends_on_field_id
                                                  ] !== undefined
                                                    ? objTocheck[
                                                          validation_depends_on_field_id
                                                      ]?.uid
                                                        ? objTocheck[
                                                              validation_depends_on_field_id
                                                          ].uid
                                                        : objTocheck[
                                                              validation_depends_on_field_id
                                                          ]
                                                    : undefined
                                                : undefined

                                        let res: IsInsuranceApplicationDateValid_result =
                                            isInsuranceApplicationDateValid({
                                                fieldID: fieldDateID,
                                                entity: objTocheck,
                                                valueToCheck:
                                                    objTocheck[fieldDateID],
                                                value_of_dependable_field:
                                                    value_of_dependable_field,
                                                expected_value_of_dependable_field_for_validation:
                                                    expected_value_of_dependable_field_for_validation !==
                                                    undefined
                                                        ? //@ts-ignore
                                                          expected_value_of_dependable_field_for_validation.uid
                                                            ? //@ts-ignore
                                                              expected_value_of_dependable_field_for_validation.uid
                                                            : expected_value_of_dependable_field_for_validation
                                                        : undefined,
                                            })
                                        if (res.isValid === false) {
                                            hasErr = true
                                            setError(
                                                key,
                                                res.error_txt ?? 'Invalid'
                                            )
                                        }
                                    }
                                }
                            )

                            if (objTocheck[`sentence`] === undefined) {
                                delete objTocheck[`sentence`]
                            }
                            if (objTocheck[`driving_ban`] === undefined) {
                                delete objTocheck[`driving_ban`]
                            }

                            if (
                                objTocheck[`status`] &&
                                objTocheck[`status`].uid !== 'SETTLED' &&
                                objTocheck[`settled_date`] === undefined
                            ) {
                                delete objTocheck[`settled_date`]
                            }

                            if (
                                Object.values(objTocheck).some(
                                    (v) => v === undefined
                                )
                            ) {
                                hasErr = true
                                setError(
                                    key,
                                    `Please fill in all the ${entityName} fields.`
                                )
                            }
                        })
                    }
                }
            }
        }

        return hasErr
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < insurance_fields_other_driver_track_record.length;
            i++
        ) {
            if (
                insurance_fields_other_driver_track_record[i].is_required ===
                true
            ) {
                if (
                    data_draft[
                        insurance_fields_other_driver_track_record[i].id
                    ] === undefined
                ) {
                    setError(
                        insurance_fields_other_driver_track_record[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }
        }
        let hasEntityErr = validateEntities()

        if (hasEntityErr === true) {
            hasErr2 = true
        }
        return hasErr2
    }

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        getUserAndApplicationData,
        setInitialInsurance_Drivers_TrackRecord_dropdowns,
    } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
        setInitialInsurance_Drivers_TrackRecord_dropdowns()
    }, [userLoggedIn])

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')
    let entity = queryParams.get('entity')
    let entity_index = queryParams.get('entity_index')

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_otherDriver_trackRecord_request({
                    driver_id: userid,
                })
            )
            history.push(`/insurance/application/${id}/other_driver/${userid}`)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const setEntityArrFunc = (
        arr: IAnyObject[],
        fieldID: string,
        has_error?: {
            entityTypeID: string
            entityUID: string
        }
    ) => {
        if (!hasFormChanged) {
            setHasFormChanged(true)
        }

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }

        if (fieldID === 'motoring_convictions') {
            setError('has_motoring_convictions', undefined)
        }

        if (fieldID === 'convictions') {
            setError('has_convictions', undefined)
        }

        if (fieldID === 'claims') {
            setError('has_claims', undefined)
        }

        dispatch(
            insuranceActions.set_entity_arr_other_driver({
                arr: arr,
                fieldID: fieldID,
                userid: userid,
                has_error,
            })
        )
    }

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Named driver',
                            pageUrl: () =>
                                history.push(
                                    `/insurance/application/${id}/other_driver/${userid}`
                                ),
                        },
                        { pageName: 'Track record' },
                    ]}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    sectionId="drivers"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '448px',
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            sectionID="drivers"
                            list={insurance_fields_other_driver_track_record}
                            onAnswerChange={onAnswerChange}
                            data={data_draft}
                            errors={errors}
                            setError={setError}
                            entitiesErrNormalised={entitiesErrNormalised}
                            entitiesObjArr={entitiesObjArr}
                            setEntityArr={(
                                arr: IAnyObject[],
                                fieldID: string,
                                has_error?: {
                                    entityTypeID: string
                                    entityUID: string
                                }
                            ) => {
                                if (isSavedDisabled) {
                                    setIsSavedDisabled(false)
                                }
                                if (hasFormChanged === false) {
                                    setHasFormChanged(true)
                                }

                                setEntityArrFunc(arr, fieldID, has_error)
                            }}
                        />
                    </div>
                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() =>
                        history.push(
                            `/insurance/application/${id}/other_driver/${userid}`
                        )
                    }
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                <div style={{ width: '100vw' }}>
                    {field_id ? (
                        <PortalInvisibleWrapper>
                            <InsuranceApplicationMobileSearch
                                sectionId="drivers"
                                urlBack={`/insurance/application/${id}/other_driver/${userid}/track_record`}
                                draft={data_draft}
                                edited_entity={
                                    entitiesObjArr &&
                                    // @ts-ignore
                                    entitiesObjArr[`${entity}`]
                                        ? // @ts-ignore
                                          [...entitiesObjArr[`${entity}`]]
                                        : []
                                }
                                entity_index={
                                    entity_index !== undefined &&
                                    entity_index !== null &&
                                    typeof +entity_index === 'number'
                                        ? +entity_index
                                        : undefined
                                }
                                submitted={data_submitted}
                                onChange={(value: IDropdownItem) => {
                                    if (hasFormChanged === false) {
                                        setHasFormChanged(true)
                                    }

                                    if (
                                        field_id &&
                                        errors &&
                                        errors[field_id]
                                    ) {
                                        setError(field_id, undefined)
                                    }

                                    if (
                                        entity !== null &&
                                        entity_index !== null
                                    ) {
                                        let roota: IAnyObject[] =
                                            entitiesObjArr &&
                                            // @ts-ignore
                                            entitiesObjArr[`${entity}`]
                                                ? [
                                                      // @ts-ignore
                                                      ...entitiesObjArr[
                                                          `${entity}`
                                                      ],
                                                  ]
                                                : []

                                        roota[+entity_index] = {
                                            ...roota[+entity_index],
                                            [`${field_id}`]: value,
                                        }

                                        dispatch(
                                            insuranceActions.set_entity_arr_other_driver(
                                                {
                                                    arr: [...roota],
                                                    fieldID: entity,
                                                    userid: userid,
                                                }
                                            )
                                        )
                                    } else
                                        dispatch(
                                            insuranceActions.set_answer_other_driver_trackRecord(
                                                {
                                                    id: `${field_id}`,
                                                    answer: value,
                                                    userid: userid,
                                                }
                                            )
                                        )
                                }}
                            />
                        </PortalInvisibleWrapper>
                    ) : (
                        <div>
                            <InsuranceTopBarWithProgressionRateMobile
                                breadCrumbs={[
                                    {
                                        pageName: 'Named driver',
                                        pageUrl: () =>
                                            history.push(
                                                `/insurance/application/${id}/other_driver/${userid}`
                                            ),
                                    },
                                    { pageName: 'Track record' },
                                ]}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                sectionId="drivers"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingTop: 120,
                                    paddingBottom: 120,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            >
                                <InsuranceQuoteFormGenerator
                                    sectionID="drivers"
                                    list={
                                        insurance_fields_other_driver_track_record
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                    mobileSearchPickerSectionURL={`/insurance/application/${id}/other_driver/${userid}/track_record`}
                                    entitiesObjArr={entitiesObjArr}
                                    entitiesErrNormalised={
                                        entitiesErrNormalised
                                    }
                                    setEntityArr={(
                                        arr: IAnyObject[],
                                        fieldID: string,
                                        has_error?: {
                                            entityTypeID: string
                                            entityUID: string
                                        }
                                    ) => {
                                        if (hasFormChanged === false) {
                                            setHasFormChanged(true)
                                        }
                                        if (isSavedDisabled) {
                                            setIsSavedDisabled(false)
                                        }

                                        setEntityArrFunc(
                                            arr,
                                            fieldID,
                                            has_error
                                        )
                                    }}
                                />

                                <div style={{ paddingTop: '200px' }} />
                            </div>
                            <InsuranceGoBackReusableBottomBar
                                isMobile
                                saveBtnTxt="Save & Continue"
                                isSaveDisabled={isSavedDisabled ? true : false}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                goBack={() =>
                                    history.push(
                                        `/insurance/application/${id}/other_driver/${userid}`
                                    )
                                }
                                sectionId="drivers"
                            />
                        </div>
                    )}
                </div>
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceOtherDriverTrackRecordApplicationFlow
