import { useState } from 'react'
import { IDirectShareOwnedItem, ISearchUsersResult } from 'entityModels'
import { IShareRecipientRequest_redux } from '../../../../redux/entities/cars/actions/loadingActions'
import UsersSearchBar from '../../carHandoverManagers/usersSearchBar'
import IconAndTextNakedButton from '../../../atoms/Button/iconsAndTextNakedButton'

interface Props {
    share: IDirectShareOwnedItem
    searchUsers: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    resultsLoading: boolean
    addShareRecipient: (p: IShareRecipientRequest_redux) => void
    isGarage?: boolean
    selectedUsers: string[]
    onClickInvite: any
    inviteTxt?: string
}

const PrivateShareSearchManagerMobileV2 = (props: Props) => {
    const [searchFor, setSearchFor] = useState<string | undefined>(undefined)

    const [typedValue, setTypedValue] = useState<string | undefined>(undefined)

    let { searchResults } = props

    return (
        <>
            <UsersSearchBar
                // searchResults={searchResultsInState}
                searchResults={searchResults}
                onSearch={(value: string) => {
                    props.searchUsers(value)
                    setSearchFor(value)
                }}
                searchFor={searchFor}
                clearSearch={() => {
                    setSearchFor(undefined)
                    setTypedValue(undefined)
                    props.searchUsers(undefined)
                }}
                onSearchResultSelect={(user: ISearchUsersResult) => {
                    props.addShareRecipient({
                        share_id: props.share.id,
                        entity_id: props.share.entity_id,
                        recipient_userid: user.uid,
                    })
                }}
                onInputChange={(value: string) => {
                    setTypedValue(value)
                }}
                loadingPlaceholder={
                    props.resultsLoading ||
                    (typedValue && searchFor !== typedValue)
                        ? true
                        : false
                }
                placeholder={`Search recipient's Email`}
                selectedUsers={props.selectedUsers}
                hasInviteOption={true}
                onClickInvite={props.onClickInvite}
                inviteText={props.inviteTxt}
                ownEmailSearchCopy="You cannot share to yourself."
                // selectedUserData={}
            />

            <div style={{ paddingTop: '24px' }} />

            {(searchResults === undefined || searchResults?.length === 0) &&
                (typedValue === undefined || typedValue === '') && (
                    <IconAndTextNakedButton
                        onClick={() => props.onClickInvite()}
                        text="Invite to Custodian via Email & Share"
                        iconOnLeft
                        icon="v2_add"
                    />
                )}
        </>
    )
}

export default PrivateShareSearchManagerMobileV2
