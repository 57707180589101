import { ICustomErrorData, IReduxError } from 'entityModels'

export const ConvertToReduxError = (
    errorPayload: any,
    customErrorData?: ICustomErrorData,
    entityError?:
        | 'car'
        | 'garage'
        | 'shared_with_you_car'
        | 'shared_with_you_garage'
        | 'showroom'
        | 'insurance',
    is_permission_denied?: boolean
): IReduxError => {
    if (errorPayload.status === 503) {
        let typedErrorHere: IReduxError = {
            default: errorPayload ? errorPayload : undefined,
            status_code: 503,
        }
        return typedErrorHere
    } else if (errorPayload.status !== 401) {
        let typedError: IReduxError = customErrorData
            ? {
                  default: errorPayload ? errorPayload : undefined,
                  status_code: errorPayload.status ? errorPayload.status : 500,
                  custom: {
                      custom_message: customErrorData.custom_message
                          ? customErrorData.custom_message
                          : undefined,
                      custom_message_detail:
                          customErrorData.custom_message_detail
                              ? customErrorData.custom_message_detail
                              : undefined,
                      custom_user_action_text:
                          customErrorData.custom_user_action_text
                              ? customErrorData.custom_user_action_text
                              : undefined,
                      // custom_user_action_func: customErrorData.custom_user_action_func
                      //     ? customErrorData.custom_user_action_func
                      //     : undefined,
                      custom_redirect_path: customErrorData.custom_redirect_path
                          ? customErrorData.custom_redirect_path
                          : '/garage',
                  },
                  entity_error: entityError,
                  is_permission_denied: is_permission_denied,
              }
            : {
                  default: errorPayload ? errorPayload : undefined,
                  status_code: errorPayload.status ? errorPayload.status : 500,
              }
        return typedError
    } else {
        return null
    }
}
