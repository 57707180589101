import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import Loader from '../../../atoms/loader/loader'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import { useHistory, useLocation } from 'react-router'
import OnboardingGalleryImageAndBioMobile from '../../../organisms/editForms/carGalleryEditForm/onboardingGalleryImageAndBioMobile'
import { getCarDataByIdRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import OnboardingLayoutDesktop from '../../../templates/onboarding/desktop/onboardingLayoutDesktop'
import OnboardingGalleryImageAndBioDesktop from '../../../organisms/editForms/carGalleryEditForm/onboardingGalleryImageAndBioDesktop'
import ImagePreviewDesktop from '../../../molecules/cards/onboarding/imagePreviewDesktop'

const OnboardingStep_3 = () => {
    const dispatch = useAppDispatch()
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let carid = queryParams.get('carid')

    let onboardingStep3Data = useAppSelector(
        (state) => state.localdata.onboardingContent.content[3]
    )

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let userLoading = useAppSelector((state) => state.user.loading)

    let carsData = useAppSelector((state) => state.entities.carsData.cars)
    let carsDataLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )
    let currentCar =
        carid && carsData && carsData[carid] ? carsData[carid] : undefined

    let techInfoLoading = useAppSelector(
        (state) => state.entities.technicalInformationData.loading
    )

    useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl(`/garage`))
    }, [])

    useEffect(() => {
        if (userLoggedIn) {
            if (carid && !carsData[carid]) {
                dispatch(getCarDataByIdRequest(carid))
            }
        } else {
            dispatch(getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    let history = useHistory()

    const [previewData, setPreviewData] = useState<{
        bio: string | undefined
        coverImage: File | undefined
    }>({ bio: undefined, coverImage: undefined })

    let ownerName = `${userLoggedIn?.given_name} ${userLoggedIn?.family_name}`

    return userLoading || carsDataLoading || techInfoLoading ? (
        <Loader isLoading />
    ) : (
        <React.Fragment>
            <DesktopDisplayOnly>
                <OnboardingLayoutDesktop
                    step={3}
                    title={onboardingStep3Data.title}
                    rightSideContent={
                        <ImagePreviewDesktop
                            title={currentCar?.title ?? ''}
                            bio={
                                previewData.bio && previewData.bio !== ''
                                    ? previewData.bio
                                    : onboardingStep3Data.carBioPlaceholder
                            }
                            ownerName={ownerName}
                            currentImagesUploaded={previewData.coverImage}
                        />
                    }
                >
                    {carid ? (
                        <OnboardingGalleryImageAndBioDesktop
                            carid={carid}
                            next={() => {
                                history.push(
                                    `/onboarding-step-4?carid=${carid}`
                                )
                            }}
                            setPreviewData={setPreviewData}
                            formText={onboardingStep3Data.text}
                            formMutedText={onboardingStep3Data.mutedText}
                            carBioPlaceholder={
                                onboardingStep3Data.carBioPlaceholder
                            }
                        />
                    ) : null}
                </OnboardingLayoutDesktop>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                {carid ? (
                    <SideSliderWithExit
                        isVisible={
                            carid &&
                            !userLoading &&
                            !carsDataLoading &&
                            !techInfoLoading
                                ? true
                                : false
                        }
                        direction={'right'}
                        initial="enter"
                        customDuration={0.5}
                        noOverflow
                    >
                        {currentCar && (
                            <OnboardingGalleryImageAndBioMobile
                                formStep={3}
                                formTitle={onboardingStep3Data.title}
                                carid={carid}
                                next={() => {
                                    history.push(
                                        `/onboarding-step-4?carid=${carid}`
                                    )
                                }}
                                formText={onboardingStep3Data.text}
                                formMutedText={onboardingStep3Data.mutedText}
                                carBioPlaceholder={
                                    onboardingStep3Data.carBioPlaceholder
                                }
                            />
                        )}
                    </SideSliderWithExit>
                ) : null}
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default OnboardingStep_3
