import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { device } from '../devices'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { checkIfPwaStandalone } from '../../pwaCustom/checkIpadPwa'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'
// import IpadAndMobileDisplay from "../displays/ipadAndMobileDisplay";

function mapStateToProps(state: RootState) {
    return {
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
        isDefaultNavigationRemoved: state.menus.isDefaultNavigationRemoved,
    }
}

interface Props {
    children?: React.ReactNode
    isCollapsed: boolean
    hasMobileSubNav?: boolean
    isOnboarding?: boolean
    isDefaultNavigationRemoved?: boolean
    paddingTop?: string
}

interface Istyle {
    $isCollapsed: boolean
    $isExternalPage?: boolean
    $hasMobileSubNav?: boolean
    $isOnboarding?: boolean
    $isDefaultNavigationRemoved?: boolean
    $paddingRight?: string
    $paddingTop?: string
}

const PageWrapperStyle = styled.div<Istyle>`
    @media (min-width: 881px) {
        position: relative;
        margin-top: ${(props) =>
            props.$isDefaultNavigationRemoved
                ? 0
                : props.$isExternalPage
                ? '75px'
                : '64px'};
        transition: all 100ms;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        transition: all 500ms;
        padding-right: ${(props) => props.$paddingRight ?? undefined};
        padding-top: ${(props) => props.$paddingTop ?? undefined};
    }

    @media (max-width: 880px) {
        padding-top: ${(props) =>
            props.$hasMobileSubNav
                ? '130px'
                : props.$isOnboarding
                ? '0px'
                : '65px'};
        width: 100%;
        height: 100%;
    }
    @media (max-width: 500px) {
        padding-top: ${(props) =>
            props.$hasMobileSubNav
                ? '115px'
                : props.$isOnboarding
                ? '0px'
                : '65px'};
        width: 100%;
        height: 100%;
    }
`

const MenuAdjust = styled.div<Istyle>`
    display: flex;
    width: 100%;
    justify-content: center;
    transition: all 100ms;

    @media (max-width: 799px) {
        display: flex;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding-bottom: 100px;
        width: 100%;
    }

    @media ${device.desktop} {
        padding-left: ${(props) => (props.$isCollapsed ? '50px' : '200px')};
    }
    @media ${device.large_desktop} {
        padding-left: ${(props) =>
            props.$isCollapsed ? '71px' : '271px'}; //. 230
    }

    @media ${device.smallest_laptops} {
        padding-left: ${(props) => (props.$isCollapsed ? '50px' : '200px')};
    }
`

interface Props extends RouteComponentProps<any> {
    hide_overflow_x?: boolean
    children?: React.ReactNode
    bg?: string
    paddingRight?: string
}

const FullPageWrapper: React.SFC<Props> = (props: Props) => {
    let currentPath = checkIfPwaStandalone()
        ? window.location.pathname
        : window.location.pathname

    let isExternalPage: boolean = currentPath.match(/shared/g) ? true : false

    let { hide_overflow_x, bg, paddingRight, paddingTop } = props

    const { theme } = useThemes()

    return (
        <React.Fragment>
            <MenuAdjust
                style={{
                    overflowX: hide_overflow_x ? 'hidden' : 'auto',
                    background: bg ?? colours[theme].background_default,
                }}
                $isCollapsed={props.isCollapsed}
            >
                <PageWrapperStyle
                    $isExternalPage={isExternalPage}
                    $isCollapsed={props.isCollapsed}
                    $hasMobileSubNav={props.hasMobileSubNav}
                    $isOnboarding={props.isOnboarding}
                    $isDefaultNavigationRemoved={
                        props.isDefaultNavigationRemoved
                    }
                    $paddingRight={paddingRight}
                    $paddingTop={paddingTop}
                >
                    {props.children}
                </PageWrapperStyle>
            </MenuAdjust>
        </React.Fragment>
    )
}

export default withRouter(connect(mapStateToProps, {})(FullPageWrapper))
