import { useHistory, useLocation, withRouter } from 'react-router'
import styled from 'styled-components'

import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { device } from '../../templates/displays/devices'
import ShowroomH1 from '../../atoms/typography/showroom/ShowroomH1'
import ShowroomFiltersBarDesktop from '../../organisms/searchbar/showroom/filterContent/showroomFiltersBarDesktop'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import ShowroomCarSliderDesktop from '../../molecules/swipeNslide/showroom/showroomCarSliderDesktop'
import { IFilterID, IShowroomFiltersState } from '../../../redux/showroom/types'
import { AnimatePresence, motion } from 'framer-motion'
import { ISearchShowroom_API_car_item } from '../../../redux/services/showroom/types'
import { useEffect, useRef, useState } from 'react'
import { showroomActions } from '../../../redux/showroom/reducer'
import IconButton from '../../atoms/Button/iconButton'
import SortMenuDesktop from '../../atoms/menu/sortMenu/sortMenuDesktop'
import SortMenuMobile from '../../atoms/menu/sortMenu/sortMenuMobile'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import ShowroomNoResultsBox from '../../atoms/showroom/showroomNoResultsBox'

import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { SkeletonStyledCompNonAbsolute } from '../../atoms/skeleton/skeletonAbsolute'
import FadedSlower from '../../templates/animated/FadedSlower'
import { ICarouselShowroomItem } from '../../../redux/services/showroom/getCarousel'
import { showroomLocalDataActions } from '../../../redux/localdata/showroomLocalData'
import ScrollToTopBtn from '../../atoms/Button/scrollToTopBtn'
import { useScrollDirectionInsideADiv } from '../../../helpers/hooks/checkScrollDirection'
import FullPageWrapperShowroom from '../../templates/displays/pageWrappers/fullPageWrapperShowroom'
import BottomPaginationNavigation from '../../templates/pagination/bottomPaginationNavigation'
import ShowroomCardsContainerDesktop from '../../organisms/showroom/cardsContainer/cardsContainerDesktop'
import ShowroomCardsContainerMobile from '../../organisms/showroom/cardsContainer/cardsContainermobile'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import ShowroomPublicFooterDesktop from '../../templates/footer/showroomPublicFooter/showroomPublicFooterDesktop'
import ShowroomPublicFooterMobile from '../../templates/footer/showroomPublicFooter/showroomPublicFooterMobile'
import {
    ICookieSetArg,
    setCookie,
    generateFBC,
    generateFBP,
} from '../../../helpers/cookies'
import { GetUserIPAndCallFbTracking } from '../../../helpers/ipAddress'
import { useShowroom } from '../../../providers/showroom'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import JourneyPatternCardMobile from '../../molecules/cards/journey/patternCards/patternCardMobile'

export interface ICarShowroomSpecs {
    make: string
    model: string
    year: string
    engine_size: string
    transmission: string
    drive_side: string
    mileage: string
    location: string
}

export const ShowroomCarContainer = styled.div`
    z-index: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.5rem;
    row-gap: 3rem;
    transition: all 100ms;
    padding-bottom: 2rem;
    width: 100%;

    height: 100%;

    overflow-y: none;

    /* @media ${device.smallest_laptops} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    } */

    @media (min-width: 881px) and (max-width: 1220px) {
        /* grid-template-columns: repeat(2, 1fr); */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
    }

    @media (min-width: 2300px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 880px) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
`

const SectionWrapper = styled.div`
    width: 100%;

    @media ${device.desktop} {
        max-width: 83vw;
        padding-left: 10px;
    }

    @media ${device.large_desktop} {
        max-width: 80vw;
        padding-left: 30px;
    }

    @media screen and (min-width: 1900px) {
        max-width: 1600px;
    }
    @media screen and (min-width: 2300px) {
        max-width: 1800px;
    }

    @media ${device.smallest_laptops} {
        max-width: 80vw;
        /* padding-left: 20px; */
        padding-right: 20px;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-self: center;

    @media ${device.mobile_and_ipad} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const PageWrapper = styled.div`
    width: 100%;

    @media (min-width: 2200px) {
        padding-top: 20px;
    }

    @media ${device.beyond_ipad_mobile} {
        height: 100%;
    }

    @media ${device.mobile_and_ipad} {
        padding-top: 112px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
`

export const PageCountsInfo = styled.div`
    color: #666666;
    font-size: 14;
    font-family: 'Lato';
    width: max-content;
`
export const PageCountsInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`
const SubSectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: -1;
    @media ${device.ipad} {
        padding-top: 20px;
    }
`

const SubSectionWrapperMobile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: -1;
    @media ${device.ipad} {
        padding-top: 20px;
    }
`
const FiltersWrapper = styled.div`
    display: flex;
`

const SelectedFilters = styled.div`
    position: absolute;
    right: -5px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #df6f6f;
    border-radius: 50%;
    color: #fff;
    height: 16px;
    width: 16px;
    font-size: 12px;
`

export type IShowroomSortOptionsData = {
    uid:
        | 'OLDEST'
        | 'MOST_RECENT'
        | 'PRICE_ASCENDING'
        | 'PRICE_DESCENDING'
        | 'MANUFACTURE_DATE_ASCENDING'
        | 'MANUFACTURE_DATE_DESCENDING'
    name: string
}

export const showroomSortDataOptions: IShowroomSortOptionsData[] = [
    {
        uid: 'MOST_RECENT',
        name: 'Most recent',
    },
    {
        uid: 'OLDEST',
        name: 'Oldest',
    },
    {
        uid: 'PRICE_ASCENDING',
        name: 'Price lowest',
    },
    {
        uid: 'PRICE_DESCENDING',
        name: 'Price highest',
    },
    {
        uid: 'MANUFACTURE_DATE_DESCENDING',
        name: 'Manufacture date: Newest',
    },
    {
        uid: 'MANUFACTURE_DATE_ASCENDING',
        name: 'Manufacture date: Oldest',
    },
]

type ISkeletonProps = {
    narr: number[]
    height: string
    width: string
    paddingBottom?: string
}

const BarMotionWrap = styled(motion.div)``

export const CardSkeletonItems = (props: ISkeletonProps) => {
    return (
        <>
            {props.narr.map((id: number, index: number) => {
                return (
                    <FadedSlower key={`${id + index}`}>
                        <div
                            style={{
                                height: props.height,
                                width: props.width,
                                paddingBottom: props.paddingBottom ?? '20px',
                            }}
                        >
                            <SkeletonStyledCompNonAbsolute
                                key={`${id}-skeletons`}
                                isthingloading={true}
                            />
                        </div>
                    </FadedSlower>
                )
            })}
        </>
    )
}

const Showroom = () => {
    // fbclid stuff

    const sortOutFbTracking = (ip?: string | undefined) => {
        if (
            process.env.REACT_APP_ENV !== 'local' &&
            process.env.REACT_APP_ENV !== 'development' &&
            process.env.REACT_APP_ENV !== 'preview'
        ) {
            const params = new URLSearchParams(location.search)
            const fbclid: string | null = params.get('fbclid')

            const timeNow = Date.now()
            let event_id = `view_item_${timeNow}`

            const ua =
                !!window && window.navigator && window.navigator.userAgent
                    ? window.navigator.userAgent
                    : undefined

            if (window && window.dataLayer === undefined) {
                window.dataLayer = window.dataLayer || []
            }

            if (fbclid) {
                const fbclidCookieArg: ICookieSetArg = {
                    name: 'fbclid',
                    value: fbclid,
                    days: 180,
                }
                setCookie(fbclidCookieArg)

                const FBC = generateFBC(fbclid, timeNow)
                setCookie({
                    name: '_fbc',
                    value: FBC,
                    days: 180,
                })

                const FBP = generateFBP(timeNow)
                setCookie({
                    name: '_fbp',
                    value: FBP,
                    days: 180,
                })

                if (ip) {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            fbclid: fbclid,
                            ip_override: ip,
                            event_id: event_id,
                            user_agent: ua,
                        })
                } else {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            fbclid: fbclid,
                            event_id: event_id,
                            user_agent: ua,
                        })
                }
            } else if (
                process.env.REACT_APP_ENV !== 'local' &&
                process.env.REACT_APP_ENV !== 'development' &&
                process.env.REACT_APP_ENV !== 'preview'
            ) {
                if (ip) {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            ip_override: ip,
                            event_id: event_id,
                            user_agent: ua,
                        })
                } else {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            event_id: event_id,
                            user_agent: ua,
                        })
                }
            }
        }
    }

    let entriesList_redux: ISearchShowroom_API_car_item[] | undefined =
        useAppSelector((state) => {
            return state.showroom.sale_results
        })

    let entriesList: ISearchShowroom_API_car_item[] | undefined =
        entriesList_redux ? entriesList_redux : undefined

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        // if (window.matchMedia('(display-mode: standalone)').matches) {
        //     dispatch(customNavDataActions.setInitialLandingUrl(`/showroom`))
        //     dispatch(getCurrentUserDataRequest())
        // }
    }, [])

    let currentPageNumberSale: number = useAppSelector((state) => {
        return state.showroom.currentPageNumber_sale
    })

    let sale_results_total_count: number | undefined = useAppSelector(
        (state) => {
            return state.showroom.sale_results_total_count
        }
    )

    useEffect(() => {
        // if (window.matchMedia('(display-mode: standalone)').matches) {
        //     dispatch(customNavDataActions.setInitialLandingUrl(`/showroom`))
        //     dispatch(getCurrentUserDataRequest())
        // }
    }, [])

    let itemsPerPage: number = useAppSelector((state) => {
        return state.showroom.itemsNumberPerPage
    })

    let isSearchLoading: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isSearchLoading
    })

    let handleChangeSearchEffectsReset = () => {
        // reset

        dispatch(showroomActions.setCurrentPageNumber_sale(0))

        dispatch(
            showroomActions.searchShowroom_setCars({
                cars: undefined,
                resultsFoundNumber: undefined,
                similarSoldCarsNumber: undefined,
                type: 'sale',
            })
        )

        setPrevPage(-1)
        // window.scrollTo({ top: 0, behavior: 'smooth' })
        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)
        params2.delete('page')
        params2.append('page', `${1}`)

        if (anchor_entry_params) {
            params2.delete('anchor_entry')
        }

        history.replace({
            search: params2.toString(),
        })

        scrollSmoothTop()
    }

    useEffect(() => {
        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (!userLoggedIn && refresh_token !== null) {
            dispatch(getCurrentUserDataRequest())
        }
        GetUserIPAndCallFbTracking(sortOutFbTracking)
            .then((data: any) => {})
            .catch((err: any) => {})
    }, [])

    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.filters
    })

    let openedFilterID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    let isMoreFiltersOpen: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isMoreFiltersOpened
    })

    let similarSoldCarsNumber: number | undefined = useAppSelector((state) => {
        return state.showroom.similarSoldCarsNumber
    })

    const location = useLocation()

    const [prevPage, setPrevPage] = useState(currentPageNumberSale - 1)

    let params = new URLSearchParams(location.search)
    let pageN = params.get('page')
    let ownerIDParams = params.get('owner_id')
    let ownerNameParams = params.get('owner_display_name')

    let anchor_entry_params = params.get('anchor_entry')

    let {
        putInitialParamsInReduxSearch,
        removeQueryParamSearchID,
        synchReduxSearchToQueryParam,
    } = useShowroom()

    function isPageValid(
        totalCount: number,
        itemsPerPage: number,
        page: number
    ): boolean {
        const totalPages = Math.ceil(totalCount / itemsPerPage)
        return page <= totalPages
    }

    const manageEntryAnchorRemoval = () => {
        // in future, can take any FilterID

        if (anchor_entry_params) {
            let currURL = new URL(window.location.href)
            const params2 = new URLSearchParams(currURL.search)
            params2.delete('anchor_entry')

            history.replace({ search: params2.toString() })
        }
    }

    // to avoid any looping edge cases
    const [hasResetN, setHasResetN] = useState<number>(0)

    useEffect(() => {
        setTimeout(() => {
            if (
                !ownerIDParams &&
                hasResetN < 3 &&
                !isSearchLoading &&
                !isThereActiveFilter &&
                sale_results_total_count !== undefined &&
                (!entriesList || entriesList?.length === 0) &&
                pageN &&
                typeof +pageN === 'number' &&
                isPageValid(sale_results_total_count, itemsPerPage, +pageN) ===
                    false
            ) {
                handleChangeSearchEffectsReset()

                setHasResetN(hasResetN + 1)
            }
        }, 100)
    }, [sale_results_total_count, isSearchLoading])

    let activeSorting:
        | 'OLDEST'
        | 'MOST_RECENT'
        | 'PRICE_ASCENDING'
        | 'PRICE_DESCENDING'
        | 'MANUFACTURE_DATE_ASCENDING'
        | 'MANUFACTURE_DATE_DESCENDING'
        | undefined = useAppSelector((state) => {
        return state.showroom.activeSort
    })

    let isThereActiveFilter = Object.values(filtersState).some(
        (val) => val.isActive === true
    )

    let nbOfSelectedfilters = Object.values(filtersState).filter(
        (val) => val.isActive === true
    ).length

    const history = useHistory()

    const [showSortActionBottomSheet, toggleSortActionBottomSheet] =
        useState(false)

    const onHandleSortingSelect = (v: string) => {
        dispatch(
            showroomActions.setActiveSorting(
                // @ts-ignore
                v
            )
        )
        setTimeout(() => {
            // reset
            handleChangeSearchEffectsReset()

            if (!isSearchLoading) {
                manageEntryAnchorRemoval()
                dispatch(
                    showroomActions.searchShowroomRequest({
                        states: 'PUBLISHED',
                    })
                )
            }
        }, 100)
    }

    const entriesListInnerRefDesktop: any = useRef()
    const entriesListInnerRefMobile: any = useRef()

    // let showroomError = useAppSelector((state) => {
    //     return state.showroom.error
    // })

    useEffect(() => {
        if (!ownerIDParams) {
            if (pageN && typeof +pageN === 'number') {
                dispatch(showroomActions.setCurrentPageNumber_sale(+pageN - 1))
                setPrevPage(+pageN)

                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('page')
                params2.append('page', `${pageN}`)

                history.replace({
                    search: params2.toString(),
                })
            } else {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('page')
                params2.append('page', `${1}`)

                history.replace({
                    search: params2.toString(),
                })
            }
        }
    }, [])

    const checkIfIsLast = () => {
        if (
            sale_results_total_count !== undefined &&
            entriesList &&
            (entriesList.length === sale_results_total_count ||
                entriesList.length > sale_results_total_count)
        ) {
            return true
        }

        if (!sale_results_total_count) {
            return false
        }

        if (!entriesList) {
            return false
        } else if (
            sale_results_total_count !== undefined &&
            entriesList &&
            entriesList.length < sale_results_total_count
        )
            return false
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        let paramsObj_copy: any = Array.from(params.keys()).reduce(
            (acc, val) => ({ ...acc, [val]: params.getAll(val) }),
            {}
        )

        delete paramsObj_copy['page']
        delete paramsObj_copy['anchor_entry']
        delete paramsObj_copy['owner_id']
        delete paramsObj_copy['owner_display_name']

        delete paramsObj_copy['utm_source']
        delete paramsObj_copy['utm_medium']
        delete paramsObj_copy['utm_term']
        delete paramsObj_copy['utm_campaign']

        // page + anchor
        if (Object.keys(paramsObj_copy).length > 0) {
            putInitialParamsInReduxSearch(window.location)
        } else {
            synchReduxSearchToQueryParam()
        }
    }, [])

    useEffect(() => {
        // this is the saga
        const fetchData = () => {
            if (
                ownerIDParams !== (undefined || null) &&
                ownerNameParams !== (undefined || null)
            ) {
                dispatch(
                    showroomActions.setOwner_filter({
                        id: ownerIDParams,
                        display_name: ownerNameParams,
                    })
                )
            }
            if (!checkIfIsLast() && !isSearchLoading) {
                dispatch(
                    showroomActions.searchShowroomRequest({
                        states: 'PUBLISHED',
                        freeze_total_count: true,
                    })
                )

                setPrevPage(currentPageNumberSale)
            }
        }

        if (prevPage !== currentPageNumberSale) {
            // call search
            fetchData()
        }
    }, [currentPageNumberSale, prevPage])

    let slides: ICarouselShowroomItem[] | null = useAppSelector((state) => {
        return state.localdata.showroomLocalData.slides
    })

    useEffect(() => {
        if (!slides) {
            dispatch(showroomLocalDataActions.getShowroomSlides_request())
        }

        // if (currentPageNumberSale !== 0) {
        //     handleChangeSearchEffectsReset()
        // }

        // return () => {
        //     // reset
        //     handleChangeSearchEffectsReset()
        // }
    }, [])

    let totalPages = Math.ceil(
        (sale_results_total_count ? sale_results_total_count : 0) / itemsPerPage
    )

    let isLastPage = currentPageNumberSale + 1 === totalPages

    let scrollSmoothTop = () => {
        if (isMobile) {
            entriesListInnerRefMobile?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        } else {
            entriesListInnerRefDesktop?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
    }

    const onClickNext = () => {
        scrollSmoothTop()
        const currOage = currentPageNumberSale

        dispatch(showroomActions.setCurrentPageNumber_sale(currOage + 1))
        setPrevPage(currOage)

        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)

        params2.delete('page')
        params2.append('page', `${currOage + 2}`)

        history.replace({
            search: params2.toString(),
        })
    }

    const onClickPrev = () => {
        scrollSmoothTop()
        let currURL = new URL(window.location.href)
        let params2 = new URLSearchParams(currURL.search)

        params2.delete('page')
        params2.append('page', `${currentPageNumberSale}`)

        history.replace({
            search: params2.toString(),
        })

        dispatch(
            showroomActions.setCurrentPageNumber_sale(currentPageNumberSale - 1)
        )
        setPrevPage(currentPageNumberSale - 2)
    }

    const [showScrollToTopBtn, setShowScrollToTopBtn] = useState(false)

    const checkIfScrollToTopBtnIsVisible = (
        ref: any,
        scrollDirection: string | null
    ) => {
        if (ref.current) {
            const { scrollTop, clientHeight } = ref.current
            if (scrollTop > clientHeight / 2 && scrollDirection === 'down') {
                setShowScrollToTopBtn(true)
            } else if (scrollTop === 0) {
                setShowScrollToTopBtn(false)
            }
        }
    }

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    const scrollDirection = useScrollDirectionInsideADiv(
        isMobile ? entriesListInnerRefMobile : entriesListInnerRefDesktop
    )

    // const manageOwnerQueryParamRemoval = (id: 'owner') => {
    //     // in future, can take any FilterID
    // }

    let isSliderEmpty = slides && slides.length === 0 ? true : false

    let showSlider = isSliderEmpty === false && isThereActiveFilter === false

    useEffect(() => {
        if (ownerIDParams) {
            scrollSmoothTop()
        }
        if (filtersState.owner?.isActive && !ownerIDParams) {
            dispatch(
                showroomActions.removeFilterRequest({
                    id: 'owner',
                    direct_search: true,
                })
            )
            dispatch(showroomActions.setCurrentPageNumber_sold(0))

            dispatch(
                showroomActions.searchShowroom_setCars({
                    cars: undefined,
                    resultsFoundNumber: undefined,
                    similarSoldCarsNumber: undefined,
                    type: 'sold',
                })
            )
        }
    }, [ownerIDParams])

    let onClickPageNumber = (n: number) => {
        scrollSmoothTop()
        let currURL = new URL(window.location.href)
        const params2 = new URLSearchParams(currURL.search)

        if (anchor_entry_params) {
            params2.delete('anchor_entry')
        }

        params2.delete('page')
        params2.append('page', `${n}`)
        history.replace({ search: params2.toString() })
        dispatch(showroomActions.setCurrentPageNumber_sale(n - 1))
        setPrevPage(n - 2)
    }

    let cardAnchoredRefDesktop: any = useRef()
    let cardAnchoredRefMobile: any = useRef()

    let anchor_id_redux: string | undefined = useAppSelector((state) => {
        return state.showroom.entry_anchor
    })

    let entryIDtoScrollTo = !anchor_id_redux
        ? undefined
        : isMobile
        ? `mobile-${anchor_id_redux}`
        : `desktop-${anchor_id_redux}`

    let entryDiv =
        entryIDtoScrollTo && document.getElementById(entryIDtoScrollTo)

    let scrollToElement = () => {
        if (entryDiv) {
            scrollIntoView(entryDiv, {
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }

    useEffect(() => {
        if (anchor_id_redux) {
            let currURL = new URL(window.location.href)
            const params2 = new URLSearchParams(currURL.search)

            params2.delete('anchor_entry')
            params2.append('anchor_entry', anchor_id_redux)
            history.replace({ search: params2.toString() })
        }

        scrollToElement()
    }, [entryDiv, anchor_id_redux])

    const [topPadding, setTopPadding] = useState(0)

    return (
        <>
            <DesktopDisplayOnly>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 100,
                        right: 80,
                        zIndex: showScrollToTopBtn ? 5 : -1,
                        opacity: showScrollToTopBtn ? 1 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <ScrollToTopBtn
                        onClick={() => {
                            entriesListInnerRefDesktop?.current?.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }}
                    />
                </div>

                <PageWrapper>
                    <div
                        style={{
                            height: '100vh',
                            overflowY: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'flex-start',
                        }}
                        key={'desktop-scroll-sale'}
                        onScroll={() => {
                            if (!isMobile) {
                                // check if we show scroll to top btn
                                checkIfScrollToTopBtnIsVisible(
                                    entriesListInnerRefDesktop,
                                    scrollDirection
                                )
                                // fetch data if necessary
                                // onScroll(entriesListInnerRefDesktop)
                            }
                        }}
                        ref={entriesListInnerRefDesktop}
                    >
                        <FullPageWrapperShowroom>
                            <div
                                style={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    // width: '100%',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    overflowX: 'hidden',
                                }}
                            >
                                {!showSlider ? (
                                    <div
                                        style={{
                                            paddingTop:
                                                isMoreFiltersOpen &&
                                                size &&
                                                size.width &&
                                                size.width < 1707
                                                    ? '172px'
                                                    : '100px',
                                        }}
                                    />
                                ) : null}

                                {!showSlider ? (
                                    <AnimatePresence>
                                        <BarMotionWrap
                                            style={{
                                                position: 'fixed',
                                                width: '100%',
                                                backgroundColor: 'white',
                                                zIndex: 10,
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                left: 0,
                                                // top: '64px',
                                            }}
                                            initial={{
                                                opacity: 0,
                                                // transform: 'translateY(100px)',
                                                top: 100,
                                            }}
                                            animate={{
                                                opacity: 1,
                                                // transform: 'translateY(0px)',
                                                top: 64,
                                            }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                opacity: { duration: 0.2 },
                                                duration: 0.15,
                                            }}
                                        >
                                            <ShowroomFiltersBarDesktop
                                                isWithinPageLimits={false}
                                                manageQueryParamRemoval={(
                                                    id: string,
                                                    val?: any
                                                ) => {
                                                    removeQueryParamSearchID(
                                                        id,
                                                        val
                                                    )
                                                }}
                                                handleChangeSearchEffects={
                                                    handleChangeSearchEffectsReset
                                                }
                                                page="sale"
                                                currentTopPadding={topPadding}
                                                updateTopPadding={(
                                                    height: number
                                                ) => setTopPadding(height)}
                                            />
                                        </BarMotionWrap>
                                    </AnimatePresence>
                                ) : null}
                                <SectionWrapper>
                                    {showSlider ? (
                                        <div
                                            style={{
                                                paddingTop: '30px',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    zIndex: -2,
                                                }}
                                            >
                                                <ShowroomCarSliderDesktop
                                                    slides={slides}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    paddingTop: '30px',
                                                }}
                                            />

                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    zIndex:
                                                        openedFilterID !==
                                                            undefined ||
                                                        isMoreFiltersOpen ===
                                                            true
                                                            ? 10
                                                            : 0,
                                                }}
                                            >
                                                <ShowroomFiltersBarDesktop
                                                    isWithinPageLimits={true}
                                                    manageQueryParamRemoval={(
                                                        id: string,
                                                        val?: any
                                                    ) => {
                                                        removeQueryParamSearchID(
                                                            id,
                                                            val
                                                        )
                                                    }}
                                                    handleChangeSearchEffects={() => {
                                                        handleChangeSearchEffectsReset()
                                                    }}
                                                    page="sale"
                                                    currentTopPadding={
                                                        topPadding
                                                    }
                                                    updateTopPadding={(
                                                        height: number
                                                    ) => setTopPadding(height)}
                                                />
                                            </div>
                                        </div>
                                    ) : null}

                                    <SubSectionWrapper
                                        style={{
                                            zIndex:
                                                openedFilterID !== undefined
                                                    ? -1
                                                    : 8,
                                            paddingTop: isThereActiveFilter
                                                ? topPadding
                                                : '20px',
                                        }}
                                    >
                                        <ShowroomH1 leftAlign>
                                            Cars offered for sale
                                        </ShowroomH1>

                                        <PageCountsInfoRow>
                                            <PageCountsInfo>
                                                {currentPageNumberSale *
                                                    itemsPerPage ===
                                                0
                                                    ? 1
                                                    : currentPageNumberSale *
                                                      itemsPerPage}{' '}
                                                -{' '}
                                                {isLastPage
                                                    ? sale_results_total_count
                                                    : itemsPerPage *
                                                          currentPageNumberSale +
                                                      itemsPerPage}{' '}
                                                of {sale_results_total_count}
                                            </PageCountsInfo>

                                            <div
                                                style={{ paddingLeft: '24px' }}
                                            />

                                            <SortMenuDesktop
                                                dropdownData={
                                                    showroomSortDataOptions
                                                }
                                                currentOption={
                                                    activeSorting ?? undefined
                                                }
                                                onSelected={(v: string) => {
                                                    onHandleSortingSelect(v)
                                                }}
                                            />
                                        </PageCountsInfoRow>
                                    </SubSectionWrapper>

                                    {!isMobile &&
                                    entriesList &&
                                    !similarSoldCarsNumber &&
                                    entriesList.length > 0 ? (
                                        <ShowroomCarContainer
                                            style={{
                                                zIndex: 1,
                                            }}
                                        >
                                            <ShowroomCardsContainerDesktop
                                                userLoggedIn={userLoggedIn}
                                                entriesList={entriesList}
                                                cardAnchoredRefDesktop={
                                                    cardAnchoredRefDesktop
                                                }
                                                anchor_entry_params={
                                                    anchor_entry_params
                                                }
                                                anchor_id_redux={
                                                    anchor_id_redux
                                                }
                                                handleChangeSearchEffectsReset={
                                                    handleChangeSearchEffectsReset
                                                }
                                            />

                                            {isSearchLoading &&
                                                !checkIfIsLast() && (
                                                    <CardSkeletonItems
                                                        height="25vh"
                                                        width="95%"
                                                        paddingBottom="0px"
                                                        narr={[
                                                            1, 2, 3, 4, 5, 6,
                                                        ]}
                                                    />
                                                )}
                                        </ShowroomCarContainer>
                                    ) : !isSearchLoading ? (
                                        <ShowroomNoResultsBox
                                            page="for sale"
                                            matchingSearchResults={
                                                similarSoldCarsNumber
                                            }
                                        />
                                    ) : (
                                        <CardSkeletonItems
                                            height="25vh"
                                            width="95%"
                                            paddingBottom="0px"
                                            narr={[1, 2, 3, 4, 5, 6]}
                                        />
                                    )}

                                    <BottomPaginationNavigation
                                        results={entriesList}
                                        currentPage={currentPageNumberSale}
                                        isLastPage={isLastPage}
                                        onClickPrev={onClickPrev}
                                        onClickNext={onClickNext}
                                        onClickPageNumber={onClickPageNumber}
                                        totalCount={
                                            sale_results_total_count ?? 0
                                        }
                                        itemsPerPage={itemsPerPage}
                                    />
                                    <div
                                        style={{
                                            width: '100%',
                                            paddingTop: 0,
                                            paddingBottom: 48,
                                            paddingRight: '40px',
                                        }}
                                    >
                                        <JourneyRedirectCardDesktop
                                            sectionID="sell_your_car"
                                            copyData={journeyRedirectCardsCopy}
                                            onStartNow={() => {
                                                history.push({
                                                    pathname: `/journey/sell-your-car`,
                                                    state: {
                                                        prevPath: `${
                                                            history.location
                                                                .pathname
                                                        }${
                                                            history.location
                                                                .search ?? ''
                                                        }`,
                                                    },
                                                })
                                            }}
                                            dataPH={
                                                'start_sell_your_car_journey'
                                            }
                                        />
                                    </div>
                                    {!userLoggedIn ? (
                                        <ShowroomPublicFooterDesktop />
                                    ) : (
                                        <div style={{ paddingBottom: 300 }} />
                                    )}
                                </SectionWrapper>
                            </div>
                        </FullPageWrapperShowroom>
                    </div>
                </PageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        position: 'fixed',
                        bottom: 100,
                        right: 16,
                        zIndex: showScrollToTopBtn ? 1 : -1,
                        opacity: showScrollToTopBtn ? 1 : 0,
                        transition: 'all 200ms',
                    }}
                >
                    <ScrollToTopBtn
                        onClick={() => {
                            entriesListInnerRefMobile?.current?.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                            })
                        }}
                    />
                </div>
                <SortMenuMobile
                    isVisible={showSortActionBottomSheet}
                    close={() => toggleSortActionBottomSheet(false)}
                    dropdownData={showroomSortDataOptions}
                    currentOption={activeSorting ?? 'MOST_RECENT'}
                    onSelected={(v: string) => {
                        onHandleSortingSelect(v)
                        toggleSortActionBottomSheet(false)
                    }}
                />

                <div style={{ position: 'fixed', width: '100%' }}>
                    <PageWrapper
                        style={{
                            height: '100vh',
                            overflowY: 'auto',
                        }}
                        key={'mobile-scroll'}
                        onScroll={() => {
                            if (isMobile) {
                                // check if we show scroll to top btn
                                checkIfScrollToTopBtnIsVisible(
                                    entriesListInnerRefMobile,
                                    scrollDirection
                                )

                                // fetch data if necessary
                                // onScroll(entriesListInnerRefMobile)
                            }
                        }}
                        ref={entriesListInnerRefMobile}
                    >
                        <SectionWrapper>
                            <SubSectionWrapperMobile style={{ zIndex: 2 }}>
                                <ShowroomH1 leftAlign>
                                    Cars offered for sale
                                </ShowroomH1>

                                <FiltersWrapper>
                                    <IconButton
                                        buttonheight="40px"
                                        buttonwidth="40px"
                                        bgcolor="transparent"
                                        borderColor={
                                            activeSorting
                                                ? '#5EC3CA'
                                                : '#e5e5e5'
                                        }
                                        icon={
                                            activeSorting
                                                ? 'sort-icon-blue'
                                                : 'sort-icon-grey'
                                        }
                                        onClick={() =>
                                            toggleSortActionBottomSheet(true)
                                        }
                                    />
                                    <div
                                        style={{
                                            paddingLeft: 16,
                                            position: 'relative',
                                        }}
                                    >
                                        <IconButton
                                            buttonheight="40px"
                                            buttonwidth="40px"
                                            bgcolor="transparent"
                                            borderColor="#5EC3CA"
                                            icon="filters_icon_green"
                                            onClick={() => {
                                                if (
                                                    ownerIDParams &&
                                                    ownerNameParams
                                                ) {
                                                    history.push(
                                                        `/showroom/filters?owner_id=${ownerIDParams}&owner_display_name=${ownerNameParams}`
                                                    )
                                                } else
                                                    history.push(
                                                        `/showroom/filters`
                                                    )
                                            }}
                                        />
                                        {nbOfSelectedfilters > 0 ? (
                                            <SelectedFilters>
                                                {nbOfSelectedfilters}
                                            </SelectedFilters>
                                        ) : null}
                                    </div>
                                </FiltersWrapper>
                            </SubSectionWrapperMobile>

                            {!similarSoldCarsNumber &&
                            entriesList &&
                            entriesList.length > 0 ? (
                                <ShowroomCarContainer key={'container-mobile'}>
                                    <ShowroomCardsContainerMobile
                                        userLoggedIn={userLoggedIn}
                                        entriesList={entriesList}
                                        cardAnchoredRefMobile={
                                            cardAnchoredRefMobile
                                        }
                                        anchor_entry_params={
                                            anchor_entry_params
                                        }
                                        anchor_id_redux={anchor_id_redux}
                                        handleChangeSearchEffectsReset={
                                            handleChangeSearchEffectsReset
                                        }
                                    />

                                    {isSearchLoading && !checkIfIsLast() && (
                                        <CardSkeletonItems
                                            height="30vh"
                                            width="100%"
                                            narr={[1, 2, 3]}
                                            paddingBottom={'0px'}
                                        />
                                    )}
                                </ShowroomCarContainer>
                            ) : !isSearchLoading ? (
                                <ShowroomNoResultsBox
                                    page="for sale"
                                    matchingSearchResults={
                                        similarSoldCarsNumber
                                    }
                                />
                            ) : (
                                <CardSkeletonItems
                                    height="25vh"
                                    width="100%"
                                    paddingBottom="0px"
                                    narr={[1, 2, 3, 4, 5, 6]}
                                />
                            )}

                            <BottomPaginationNavigation
                                results={entriesList}
                                currentPage={currentPageNumberSale}
                                isLastPage={isLastPage}
                                onClickPrev={onClickPrev}
                                onClickNext={onClickNext}
                                onClickPageNumber={onClickPageNumber}
                                totalCount={sale_results_total_count ?? 0}
                                itemsPerPage={itemsPerPage}
                            />
                            <div
                                style={{
                                    width: '100%',
                                    paddingTop: 48,
                                    paddingBottom: 48,
                                }}
                            >
                                <JourneyPatternCardMobile
                                    sectionID="sell_your_car"
                                    copyData={journeyRedirectCardsCopy}
                                    onDismiss={() => {}}
                                    onStartNow={() => {}}
                                    dataPH="start_sell_your_car_journey"
                                />
                            </div>
                            {!userLoggedIn ? (
                                <ShowroomPublicFooterMobile />
                            ) : (
                                <div style={{ paddingBottom: 300 }} />
                            )}
                        </SectionWrapper>
                    </PageWrapper>
                </div>
            </IpadAndMobileDisplay>
        </>
    )
}

export default withRouter(Showroom)
