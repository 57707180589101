import { insurance_colours } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

function InsuranceDetailsIcon(props?: any) {
    const { theme } = useThemes()
    return (
        <svg
            width={props?.width ?? 40}
            height={props?.width ?? 40}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect
                width={40}
                height={40}
                rx={4}
                fill={
                    insurance_colours[theme].sections['insurance_details']
                        .primary_08
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30 23.504V13.066C28 12.377 23.2 10.2 20 7c-3.2 3.2-8 5.377-10 6.066v10.438c0 4.22 6.25 8.265 10 9.496 3.75-1.231 10-5.275 10-9.496zm-4-1.348v-6.423c-1.2-.424-4.08-1.764-6-3.733-1.92 1.97-4.8 3.31-6 3.733v6.423c0 2.598 3.75 5.086 6 5.844 2.25-.758 6-3.247 6-5.844z"
                fill={
                    insurance_colours[theme].sections['insurance_details']
                        .primary_24
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 5.94l.53.53c3.087 3.087 7.768 5.217 9.714 5.887l.506.174v10.973c0 2.463-1.796 4.722-3.864 6.445-2.103 1.753-4.686 3.118-6.652 3.764l-.234.076-.234-.076c-1.966-.646-4.55-2.011-6.652-3.764-2.069-1.723-3.864-3.982-3.864-6.445V12.531l.506-.174c1.946-.67 6.627-2.8 9.714-5.887l.53-.53zm-9.25 7.652v9.912c0 1.758 1.33 3.631 3.324 5.293 1.875 1.562 4.168 2.794 5.926 3.41 1.758-.616 4.051-1.848 5.926-3.41 1.995-1.662 3.324-3.535 3.324-5.293v-9.912c-2.145-.813-6.221-2.76-9.25-5.553-3.029 2.793-7.105 4.74-9.25 5.553z"
                fill={
                    insurance_colours[theme].sections['insurance_details']
                        .primary_100
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 10.722l.54.558c1.906 1.971 4.813 3.342 6.019 3.771l.498.178v7.337c0 1.718-1.205 3.257-2.527 4.396-1.352 1.165-3.013 2.075-4.289 2.508l-.24.082-.242-.082c-1.276-.433-2.936-1.343-4.289-2.508-1.322-1.139-2.527-2.678-2.527-4.396v-7.337l.498-.178c1.206-.43 4.114-1.8 6.02-3.771l.54-.558zm-5.557 5.551v6.293c0 1.035.766 2.19 2.006 3.26 1.123.967 2.49 1.735 3.551 2.137 1.062-.402 2.428-1.17 3.551-2.137 1.24-1.07 2.006-2.225 2.006-3.26v-6.293c-1.362-.555-3.716-1.744-5.557-3.427-1.84 1.683-4.194 2.872-5.557 3.427z"
                fill={
                    insurance_colours[theme].sections['insurance_details']
                        .primary_60
                }
            />
        </svg>
    )
}

export default InsuranceDetailsIcon
