type Props = {
    height?: string
    width?: string
}

const HistoryFileCardPattern = ({ height, width }: Props) => {
    return (
        <svg
            width={width ?? '1200'}
            height={height ?? '200'}
            viewBox="0 0 1200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.24"
                d="M303.774 100.922C112.173 79.1648 -65.3903 143.863 -82 178.931V360H1310V-78H1109.08C1075.32 -18.3594 1046.71 105.645 900.115 141.716C716.873 186.804 543.275 128.118 303.774 100.922Z"
                fill="url(#paint0_linear_3924_19813)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3924_19813"
                    x1="549.987"
                    y1="223.125"
                    x2="647.707"
                    y2="-204.111"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default HistoryFileCardPattern
