import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'typesafe-actions'
// import Loader from "../atoms/loader/loader";
import FormikResetPassword from '../organisms/resetpasswordform/formikResetPassword'
import Faded from '../templates/animated/faded'
import { Link } from 'react-router-dom'
import Loader from '../atoms/loader/loader'
import styled from 'styled-components'
import TriangleHeader from '../atoms/header/triangleheader'
import { device } from '../templates/displays/devices'
import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Paragraph } from '../atoms/typography'
import ButtonAtom from '../atoms/Button/ButtonAtom'

const ButtonContainer = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Divider = styled.div`
    @media ${device.ipad} {
        padding-top: 100px;
    }
    @media ${device.desktop} {
        padding-top: 20px;
    }
    @media ${device.mobile} {
        margin-top: 50px;
    }
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.desktop} {
        padding-top: 20px;
        display: flex;
    }

    @media ${device.mobile} {
        padding-top: 1vh;
    }
`
const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    @media ${device.desktop} {
        padding-top: 80px;
    }

    @media ${device.ipad} {
        padding-top: 100px;
    }

    @media ${device.mobile} {
        padding-top: 70px;
    }
`

const LineHeight = styled.div`
    display: flex;
    justify-content: center;
    @media ${device.mobile} {
        line-height: 2 !important;
    }
`

const GoBackToSignIn = styled(Link)`
    font-family: 'Lato';
    font-size: 15px;
    align-self: flex-start;
    color: var(--text-darker, #616161);
    width: 500px;
    padding-top: 50px;
    align-self: center;
    .linkToSignIn {
        color: #5ec3ca;
        font-family: 'Lato';
    }
    :hover {
        color: var(--text-darker, #616161);
    }

    @media ${device.ipad} {
        width: 400px;
    }

    @media ${device.mobile} {
        width: 80vw;
    }
`

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.user.loading,
        error: state.user.error,
    }
}

const dispatchProps = {}

export type InitialState = {
    sent: boolean
}

interface Props extends RouteComponentProps<any> {
    isLoading: boolean
    error: any
    location: any
}

class ResetPasswordRequest extends React.Component<Props, InitialState> {
    state = {
        sent: false,
    }

    setSubmit = () => {
        return this.setState({
            sent: true,
        })
    }

    toggleSent = () => {
        return this.setState({
            sent: false,
        })
    }

    render() {
        return (
            <PublicPageWrapper>
                <Faded>
                    <Container>
                        <Loader />
                        <Wrapper>
                            <TriangleHeader title="reset password" />
                            <Divider />

                            {this.state.sent === true &&
                                this.props.error === null &&
                                this.props.isLoading !== true && (
                                    <Faded>
                                        <LineHeight>
                                            <Paragraph align="center">
                                                Thank you, we have sent you an
                                                email.
                                                <br></br>
                                                Please click on the link
                                                provided in order to reset your
                                                password.
                                            </Paragraph>
                                        </LineHeight>

                                        <ButtonContainer>
                                            <ButtonAtom theme="primary">
                                                <Link to="/signin">
                                                    go back
                                                </Link>
                                            </ButtonAtom>
                                        </ButtonContainer>
                                    </Faded>
                                )}

                            {this.state.sent === true &&
                                this.props.error !== null && (
                                    <Faded>
                                        <LineHeight>
                                            <Paragraph align="center">
                                                There has been an error.
                                                <br />
                                                Please try again. We apologise
                                                for the inconvenience caused.
                                            </Paragraph>
                                        </LineHeight>
                                        <ButtonContainer>
                                            <ButtonAtom
                                                theme="primary"
                                                onClick={this.toggleSent}
                                            >
                                                try again
                                            </ButtonAtom>
                                        </ButtonContainer>
                                    </Faded>
                                )}
                            {this.state.sent === false && (
                                <>
                                    <FormikResetPassword
                                        setSubmit={this.setSubmit}
                                    />
                                    {this.props.location.state !== undefined &&
                                    this.props.location.state.prevPath ===
                                        '/account' ? (
                                        <GoBackToSignIn to="/account">
                                            Go back to &nbsp;
                                            <span className="linkToSignIn">
                                                your profile
                                            </span>
                                        </GoBackToSignIn>
                                    ) : (
                                        <GoBackToSignIn to="/signin">
                                            Go back to &nbsp;
                                            <span className="linkToSignIn">
                                                Sign in
                                            </span>
                                        </GoBackToSignIn>
                                    )}
                                </>
                            )}
                        </Wrapper>
                    </Container>
                </Faded>
            </PublicPageWrapper>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ResetPasswordRequest)
)
