import {
    IGalleryImage,
    IPayloadCarGalleryImageFromApi,
    IReduxError,
    IUpdateEntryGalleryImagePayload,
} from 'entityModels'
import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'

import { api } from '../../../services'

import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertRawToNewImageEntity } from '../../../conversions/entities/ImageTypeConversions'

function* updateEntryGalleryImageApi(payload: IUpdateEntryGalleryImagePayload) {
    try {
        const result: ResponseGenerator = yield api.entities.mutateGallery.updateEntryGalleryImage(
            payload
        )
        const rawData: IPayloadCarGalleryImageFromApi = result.data[0]

        const convertedImage: IGalleryImage = yield call(
            ConvertRawToNewImageEntity,
            rawData
        )

        yield put(
            actions.actions.updateEntryGalleryImageSuccess(convertedImage)
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: updateEntryGalleryImageApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.updateEntryGalleryImageError(typedError)
            )
            return
        }
    }
}

function* watcherUpdateCarGalleryImage() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.updateEntryGalleryImageRequest
        )

        yield call(updateEntryGalleryImageApi, payload)

        // yield call(getCarDataApiPayloadById, payload.carid)

        // Capture event
        posthog.capture('UPDATE ENTRY IMAGE')
    }
}

const update_entry_gallery_image_sagas: any[] = [
    fork(watcherUpdateCarGalleryImage),
]

export default update_entry_gallery_image_sagas
