import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IDirectSharesReceivedObj, IUser } from 'myModels'
import Faded from '../../templates/animated/faded'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import {
    getCurrentUserDataRequest,
    getUserDirectSharesReceivedRequest,
    I_getUserDirectSharesReceivedRequest_req_payload,
    updateShareReceivedStatusRequest,
} from '../../../redux/user/actions/loadingActions'
import GaragesSharedWithYouCardMobile from '../../molecules/cards/sharing/garagesSharedWithYouCardMobile'
import MobilePendingSharesBtn from '../../atoms/Button/mobilePendingSharesBtn'
import { sortDirectReceivedSharesByDate } from '../../../helpers/sort/sortByDate'
import { sortReceivedSharesByAlphabetical } from '../../../helpers/sort/sortByAlphabetical'
import { IPrivateShareReceivedItemApprovalPayload } from 'entityModels'
import { IUpdateShareReveivedStatusApi_arg } from 'ApiInterfaces'
import { AnimatePresence } from 'framer-motion'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isUserLoading: state.user.loading,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getUserDirectSharesReceivedRequest: (
        p: I_getUserDirectSharesReceivedRequest_req_payload
    ) => getUserDirectSharesReceivedRequest(p),
    updateShareReceivedStatusRequest: (p: IUpdateShareReveivedStatusApi_arg) =>
        updateShareReceivedStatusRequest(p),
}

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    isUserLoading: boolean
    getUserDataRequest: () => void
    getUserDirectSharesReceivedRequest: (
        p: I_getUserDirectSharesReceivedRequest_req_payload
    ) => void
    sort_id: string
    updateShareReceivedStatusRequest: (
        p: IUpdateShareReveivedStatusApi_arg
    ) => void
}

const WrapperMobileAdjusted = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
`
const SectionLine = styled.div`
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
    width: 100%;
`

const MainDataWrapper = styled.div`
    width: 90vw;
    max-width: 460px;
`

type State = {}

class SharedWithYouGaragesManagerMobile extends React.Component<Props, State> {
    state = {}

    componentDidMount() {}

    generateSharesDisplayIds = (
        share_ids: string[],
        shares_data: IDirectSharesReceivedObj,
        sort_id: string
    ) => {
        if (sort_id === 'alphabetical') {
            return sortReceivedSharesByAlphabetical(
                share_ids,
                shares_data,
                'garage'
            )
        } else if (sort_id === 'by_oldest') {
            return sortDirectReceivedSharesByDate(
                share_ids,
                shares_data,
                'oldest'
            )
        }
        return sortDirectReceivedSharesByDate(
            share_ids,
            shares_data,
            'most_recent'
        )
    }

    render() {
        let { userLoggedIn, sort_id, updateShareReceivedStatusRequest } =
            this.props

        let garages_shared_received_obj =
            userLoggedIn &&
            userLoggedIn.received_direct_shares &&
            userLoggedIn.received_direct_shares.garages &&
            userLoggedIn.received_direct_shares.garages

        let garagesReceivedData: IDirectSharesReceivedObj | undefined =
            garages_shared_received_obj &&
            garages_shared_received_obj.shares_received_ids.length > 0
                ? garages_shared_received_obj.shares_received_data
                : undefined

        let garagesReceivedIds: string[] | undefined =
            garagesReceivedData &&
            garages_shared_received_obj &&
            garages_shared_received_obj.shares_received_ids.length > 0
                ? garages_shared_received_obj.shares_received_ids
                : undefined

        let pendingGaragesReceivedIds: string[] | undefined =
            garagesReceivedData &&
            garages_shared_received_obj &&
            garages_shared_received_obj.pending_shares_received_ids.length > 0
                ? sort_id !== ''
                    ? this.generateSharesDisplayIds(
                          garages_shared_received_obj.pending_shares_received_ids,
                          garagesReceivedData,
                          sort_id
                      )
                    : garages_shared_received_obj.pending_shares_received_ids
                : undefined

        let acceptedGaragesReceivedIds: string[] | undefined =
            garagesReceivedData &&
            garages_shared_received_obj &&
            garages_shared_received_obj.accepted_shares_received_ids.length > 0
                ? sort_id !== ''
                    ? this.generateSharesDisplayIds(
                          garages_shared_received_obj.accepted_shares_received_ids,
                          garagesReceivedData,
                          sort_id
                      )
                    : garages_shared_received_obj.accepted_shares_received_ids
                : undefined

        let ignoredGaragesReceivedIds: string[] | undefined =
            garagesReceivedData &&
            garages_shared_received_obj &&
            garages_shared_received_obj.ignored_shares_received_ids.length > 0
                ? sort_id !== ''
                    ? this.generateSharesDisplayIds(
                          garages_shared_received_obj.ignored_shares_received_ids,
                          garagesReceivedData,
                          sort_id
                      )
                    : garages_shared_received_obj.ignored_shares_received_ids
                : undefined

        let params = new URLSearchParams(this.props.location.search)
        let view_id = params.get('view_id')
        let entity_id = params.get('entity_id')

        return (
            <div style={{ width: '100%' }}>
                {userLoggedIn && (
                    <Faded>
                        <WrapperMobileAdjusted>
                            {(view_id === 'view_all' || !view_id) && (
                                <>
                                    {pendingGaragesReceivedIds &&
                                        pendingGaragesReceivedIds.length >
                                            0 && (
                                            <MainDataWrapper>
                                                <MobilePendingSharesBtn
                                                    text={`Review garages shared with you`}
                                                    onClick={() =>
                                                        entity_id
                                                            ? this.props.history.push(
                                                                  `/sharing/with-you?entity_id=${entity_id}&view_id=pending`
                                                              )
                                                            : this.props.history.push(
                                                                  `/sharing/with-you?view_id=pending`
                                                              )
                                                    }
                                                    pendingSharesNb={
                                                        pendingGaragesReceivedIds.length
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        paddingTop: '20px',
                                                    }}
                                                />
                                                <SectionLine />
                                                <div
                                                    style={{
                                                        paddingTop: '20px',
                                                    }}
                                                />
                                            </MainDataWrapper>
                                        )}

                                    {garagesReceivedData &&
                                        acceptedGaragesReceivedIds &&
                                        acceptedGaragesReceivedIds.length >
                                            0 && (
                                            <WrapperMobileAdjusted>
                                                <AnimatePresence>
                                                    {acceptedGaragesReceivedIds.map(
                                                        (
                                                            shareid: string,
                                                            index: number
                                                        ) => {
                                                            if (
                                                                garagesReceivedData![
                                                                    shareid
                                                                ]
                                                            ) {
                                                                return (
                                                                    <GaragesSharedWithYouCardMobile
                                                                        key={`accepted_view_all_garage_${shareid}_mobile`}
                                                                        share_received={
                                                                            garagesReceivedData![
                                                                                shareid
                                                                            ]
                                                                        }
                                                                        hasMarginRight
                                                                        shareApprovalAction={(
                                                                            p: IPrivateShareReceivedItemApprovalPayload
                                                                        ) =>
                                                                            updateShareReceivedStatusRequest(
                                                                                {
                                                                                    share_id:
                                                                                        p.shareId,
                                                                                    status: p.approvalId,
                                                                                    entity_type:
                                                                                        'garage',
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        }
                                                    )}
                                                </AnimatePresence>
                                            </WrapperMobileAdjusted>
                                        )}

                                    {(!garagesReceivedIds ||
                                        garagesReceivedIds.length === 0) && (
                                        <MainDataWrapper>
                                            <NoDataRectangle text="Nothing shared with you" />
                                        </MainDataWrapper>
                                    )}
                                </>
                            )}

                            {view_id === 'accepted' &&
                                (garagesReceivedData &&
                                acceptedGaragesReceivedIds &&
                                acceptedGaragesReceivedIds.length > 0 ? (
                                    <WrapperMobileAdjusted>
                                        <AnimatePresence>
                                            {acceptedGaragesReceivedIds.map(
                                                (
                                                    shareid: string,
                                                    index: number
                                                ) => {
                                                    if (
                                                        garagesReceivedData![
                                                            shareid
                                                        ]
                                                    ) {
                                                        return (
                                                            <GaragesSharedWithYouCardMobile
                                                                key={`accepted_garage_${shareid}_mobile`}
                                                                share_received={
                                                                    garagesReceivedData![
                                                                        shareid
                                                                    ]
                                                                }
                                                                hasMarginRight
                                                                shareApprovalAction={(
                                                                    p: IPrivateShareReceivedItemApprovalPayload
                                                                ) =>
                                                                    updateShareReceivedStatusRequest(
                                                                        {
                                                                            share_id:
                                                                                p.shareId,
                                                                            status: p.approvalId,
                                                                            entity_type:
                                                                                'garage',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                }
                                            )}
                                        </AnimatePresence>
                                    </WrapperMobileAdjusted>
                                ) : (
                                    <MainDataWrapper>
                                        <NoDataRectangle text="You don't have accepted shares" />
                                    </MainDataWrapper>
                                ))}

                            {view_id === 'pending' &&
                                (garagesReceivedData &&
                                pendingGaragesReceivedIds &&
                                pendingGaragesReceivedIds.length > 0 ? (
                                    <WrapperMobileAdjusted>
                                        <AnimatePresence>
                                            {pendingGaragesReceivedIds.map(
                                                (
                                                    shareid: string,
                                                    index: number
                                                ) => {
                                                    if (
                                                        garagesReceivedData![
                                                            shareid
                                                        ]
                                                    ) {
                                                        return (
                                                            <GaragesSharedWithYouCardMobile
                                                                key={`pending_garage_${shareid}_mobile`}
                                                                share_received={
                                                                    garagesReceivedData![
                                                                        shareid
                                                                    ]
                                                                }
                                                                hasMarginRight
                                                                shareApprovalAction={(
                                                                    p: IPrivateShareReceivedItemApprovalPayload
                                                                ) =>
                                                                    updateShareReceivedStatusRequest(
                                                                        {
                                                                            share_id:
                                                                                p.shareId,
                                                                            status: p.approvalId,
                                                                            entity_type:
                                                                                'garage',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                }
                                            )}
                                        </AnimatePresence>
                                    </WrapperMobileAdjusted>
                                ) : (
                                    <MainDataWrapper>
                                        <NoDataRectangle text="You don't have pending shares" />
                                    </MainDataWrapper>
                                ))}
                            {view_id === 'ignored' &&
                                (garagesReceivedData &&
                                ignoredGaragesReceivedIds &&
                                ignoredGaragesReceivedIds.length > 0 ? (
                                    <WrapperMobileAdjusted>
                                        <AnimatePresence>
                                            {ignoredGaragesReceivedIds.map(
                                                (
                                                    shareid: string,
                                                    index: number
                                                ) => {
                                                    if (
                                                        garagesReceivedData![
                                                            shareid
                                                        ]
                                                    ) {
                                                        return (
                                                            <GaragesSharedWithYouCardMobile
                                                                key={`ignored_garage_${shareid}_mobile`}
                                                                share_received={
                                                                    garagesReceivedData![
                                                                        shareid
                                                                    ]
                                                                }
                                                                hasMarginRight
                                                                shareApprovalAction={(
                                                                    p: IPrivateShareReceivedItemApprovalPayload
                                                                ) =>
                                                                    updateShareReceivedStatusRequest(
                                                                        {
                                                                            share_id:
                                                                                p.shareId,
                                                                            status: p.approvalId,
                                                                            entity_type:
                                                                                'garage',
                                                                        }
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                }
                                            )}
                                        </AnimatePresence>
                                    </WrapperMobileAdjusted>
                                ) : (
                                    <MainDataWrapper>
                                        <NoDataRectangle text="You don't have ignored shares" />
                                    </MainDataWrapper>
                                ))}
                        </WrapperMobileAdjusted>
                    </Faded>
                )}
            </div>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(SharedWithYouGaragesManagerMobile)
)
