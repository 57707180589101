import { IDropdownItem } from 'entityModels'
import styled from 'styled-components'
import { useAppSelector } from '../../../../redux/store/hooks'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Expander from '../../../atoms/expander/expander'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import SelectSearchBarCarInfoMobile from '../../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'
import { device } from '../../../templates/displays/devices'
import {
    IGooglePlacesAddress,
    IGooglePlacesAddress_string,
} from './carLocationDataManagerMobile'
import InfoIcon from '../../../atoms/icons/infoIcon'
import SwitchButton from '../../../atoms/Button/switchButton'
import { IInsuranceDropdownItem } from '../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import { Select } from '../../../atoms/select'
import { useState } from 'react'

const Container = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }

    @media ${device.mobile_and_ipad} {
        height: max-content;
        min-height: 100vh;
        width: 100%;
        background-color: var(--bg-color, #fff);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-radius: 4px;
        padding: 24px;
        padding-top: 20px;
        z-index: 20;
        transition: all 100ms ease-in-out;
        z-index: 5;
        position: relative;
    }
`

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    overflow-y: auto;
`
const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 24px;
    align-self: flex-start;
`

// container for action button
const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    height: 100px;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    left: 0;
    right: 0;
    width: 100%;
    padding-right: 30px;
    background-color: var(--bg-color, #fff);
    z-index: 6;
`

type Props = {
    close: () => any
    formData: IGooglePlacesAddress
    isCountryChangedFromSearch: boolean
    handleChange: (value: string, id: IGooglePlacesAddress_string) => any
    handleSelectCountry: (answer: string) => any
    isFormValid: () => boolean
    onSubmit: () => any
    countriesOptions: IDropdownItem[] | null
    hasDataChanged: boolean
    error?: { [key: string]: string | undefined }
    onSwitchToggle: () => void
    isAddressSameAsDealership: boolean
    isToggleVisible: boolean
}

const SearchField = styled.input`
    background-color: var(
        --base-overlays-neutral-alpha-50,
        rgba(26, 26, 26, 0.02)
    );
    height: 50px;
    min-height: 50px;
    border-radius: 5px;
    border: solid 1px var(--text-muted, #b3b3b3);
    padding-left: 15px;
    width: 100%;
`

const InfoBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: var(--background_primary_subtle, #f2fafb);
    transition: all 100ms;
    color: var(--text-default, #666666);
    font-size: 14px;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
`

const ErrorText = styled.div`
    width: 100%;
    font-size: 12px;
    color: var(--error, #df6f6f);
    font-style: italic;
`
const CarLocationFormMobile = (props: Props) => {
    let {
        formData,
        handleChange,
        handleSelectCountry,
        isCountryChangedFromSearch,
        onSubmit,
        countriesOptions,
        hasDataChanged,
        error,
        isAddressSameAsDealership,
        onSwitchToggle,
        isToggleVisible,
    } = props

    const showroomError = useAppSelector((state) => state.showroom.error)

    const showAdminAreaInput =
        formData.country === 'United States' ||
        (formData.administrative_area_level_2 &&
            formData.country === 'Spain') ||
        (showroomError?.custom &&
            showroomError.custom.custom_message === 'Invalid address' &&
            showroomError.default.Message &&
            (showroomError.default.Message.includes('AdministrativeArea') ||
                showroomError.default.Message.includes('administrative area')))
            ? true
            : false

    const showAdminAreaError =
        showroomError?.custom &&
        showroomError.custom.custom_message === 'Invalid address' &&
        showroomError.default.Message &&
        (showroomError.default.Message.includes('AdministrativeArea') ||
            showroomError.default.Message.includes('administrative area'))
            ? true
            : false

    const showPostCodeError =
        (showroomError?.custom &&
            showroomError.custom.custom_message === 'Invalid address' &&
            showroomError.default.Message &&
            showroomError.default.Message.includes('post code')) ||
        (error && error['postal_code'])
            ? true
            : false

    let usaAdminCodes: IInsuranceDropdownItem[] | null = useAppSelector(
        (state) => {
            return state.localdata.dropdownData.usaStatesCodes
        }
    )

    const [isAdmin1Open, setIsAdmin1Open] = useState<boolean>(false)

    return (
        <Wrapper>
            <Container>
                <Title>Car Location</Title>
                <Expander
                    height={
                        showroomError?.custom &&
                        showroomError.custom.custom_message ===
                            'Invalid address'
                            ? 'auto'
                            : 0
                    }
                    width={'100%'}
                >
                    <ErrorText>There's been an error</ErrorText>
                </Expander>
                <div style={{ paddingTop: '30px' }} />
                <InfoBox>
                    <div style={{ width: 40, height: 40, paddingTop: 3 }}>
                        <InfoIcon color="var(--primary, #5EC3CA)" />
                    </div>

                    <div style={{ paddingLeft: 16 }}>
                        Country and City data is the only information used for
                        search. The exact location of the car is kept private
                        and will not be accessible for security purposes.
                    </div>
                </InfoBox>
                {isToggleVisible && (
                    <div
                        style={{
                            paddingTop: '30px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <SwitchButton
                            checked={isAddressSameAsDealership}
                            toggle={onSwitchToggle}
                            width={34}
                            circleSize={12}
                            height={18}
                            dataCyId={'makeAddressSameAsDealership'}
                            variant="outline"
                        />
                        <p
                            style={{
                                paddingLeft: 8,
                                fontFamily: 'Lato',
                                fontSize: 14,
                            }}
                        >
                            Same as Dealership Address
                        </p>
                    </div>
                )}
                <div style={{ paddingTop: '30px' }} />
                <SearchField
                    id="autocomplete_car_location_mobile"
                    placeholder="Search your address"
                    type="text"
                    autoFocus
                />
                <div style={{ paddingTop: '30px' }} />
                <InputFieldNoFormikAnimated
                    name="Street number  /  House name"
                    type="text"
                    id="street_number"
                    placeholder=""
                    value={formData.street_number}
                    onChange={(e: any) => {
                        props.handleChange(e.target.value, 'street_number')
                    }}
                    onEnterKeyPress={handleChange}
                    tabindex={1}
                    isNarrow={true}
                    onBlur={(e: any) => e.preventDefault()}
                />
                <Expander
                    height={error && error['street_number'] ? 'auto' : 0}
                    width={'100%'}
                >
                    <ErrorText>{error && error['street_number']}</ErrorText>
                </Expander>
                <div style={{ paddingTop: '24px' }} />
                <InputFieldNoFormikAnimated
                    name="Street Address"
                    type="text"
                    id="route"
                    placeholder=""
                    value={formData.route}
                    onChange={(e: any) => {
                        props.handleChange(e.target.value, 'route')
                    }}
                    onEnterKeyPress={handleChange}
                    tabindex={2}
                    isNarrow={true}
                    onBlur={(e: any) => e.preventDefault()}
                />
                <Expander
                    height={error && error['route'] ? 'auto' : 0}
                    width={'100%'}
                >
                    <ErrorText>{error && error['route']}</ErrorText>
                </Expander>
                <div style={{ paddingTop: '24px' }} />
                <InputFieldNoFormikAnimated
                    name="City"
                    type="text"
                    id="postal_town"
                    placeholder=""
                    value={formData.postal_town}
                    onChange={(e: any) => {
                        props.handleChange(e.target.value, 'postal_town')
                    }}
                    onEnterKeyPress={handleChange}
                    tabindex={3}
                    isNarrow={true}
                    onBlur={(e: any) => e.preventDefault()}
                />
                <Expander
                    height={error && error['postal_town'] ? 'auto' : 0}
                    width={'100%'}
                >
                    <ErrorText>{error && error['postal_town']}</ErrorText>
                </Expander>
                <div style={{ paddingTop: '24px' }} />
                <InputFieldNoFormikAnimated
                    name="Postcode"
                    type="text"
                    id="postal_code"
                    placeholder=""
                    value={formData.postal_code}
                    onChange={(e: any) => {
                        if (e.target.value) {
                            e.target.value = `${e.target.value}`.toUpperCase()
                        }

                        props.handleChange(e.target.value, 'postal_code')
                    }}
                    onEnterKeyPress={handleChange}
                    tabindex={4}
                    isNarrow={true}
                    onBlur={(e: any) => e.preventDefault()}
                />
                <Expander
                    height={showPostCodeError ? 'auto' : 0}
                    width={'100%'}
                >
                    <ErrorText>
                        {error && error['postal_code']
                            ? error['postal_code']
                            : 'Invalid post-code'}
                    </ErrorText>
                </Expander>
                <div style={{ paddingTop: '24px' }} />
                <Expander
                    height={showAdminAreaInput ? 'auto' : 0}
                    width={'100%'}
                >
                    <div style={{ width: '100%' }}>
                        {formData.country === 'United States' ? (
                            <Select
                                value={formData.administrative_area_level_1}
                                options={usaAdminCodes ?? []}
                                defaultValue={
                                    formData.administrative_area_level_1
                                }
                                placeholder={'Administrative Area Code'}
                                disabled={false}
                                onChange={(val: string) => {
                                    props.handleChange(
                                        val,
                                        'administrative_area_level_1'
                                    )
                                }}
                                onFocusToggle={(p: boolean) => {
                                    setIsAdmin1Open(p)
                                }}
                            />
                        ) : (
                            <InputFieldNoFormikAnimated
                                type="text"
                                placeholder=""
                                name="admninistrative area"
                                value={
                                    formData.country === 'Spain'
                                        ? formData.administrative_area_level_2
                                        : formData.administrative_area_level_1
                                }
                                onChange={(e: any) => {
                                    props.handleChange(
                                        e.target.value,
                                        'administrative_area_level_1'
                                    )
                                }}
                                onEnterKeyPress={handleChange}
                                isNarrow={true}
                                tabindex={5}
                                width={'100%'}
                            />
                        )}
                        <Expander
                            height={showAdminAreaError ? 'auto' : 0}
                            width={'100%'}
                        >
                            <ErrorText>
                                {'Please add a valid administrative area.'}
                            </ErrorText>
                        </Expander>
                        <div style={{ paddingTop: '24px' }} />
                    </div>
                </Expander>

                <div
                    style={{
                        width: '100%',
                        zIndex: isAdmin1Open ? -1 : 'auto',
                    }}
                >
                    <SelectSearchBarCarInfoMobile
                        items={countriesOptions ?? []}
                        item={{
                            text: 'countries',
                            answer: formData.country ? formData.country : null,
                        }}
                        handleSelectChange={handleSelectCountry}
                        valueUpdatedFromExternalSource={
                            isCountryChangedFromSearch ? formData.country : null
                        }
                    />
                </div>
                <div style={{ paddingTop: '150px' }} />
                <Actions>
                    <ButtonAtom
                        theme="capitalize-white-background"
                        width="74px"
                        height="48px"
                        dataCyId={'showroom-car-share'}
                        icon={''}
                        fontSize={16}
                        onClick={() => {
                            props.close()
                        }}
                    >
                        <div style={{}}>Close</div>
                    </ButtonAtom>

                    <ButtonAtom
                        theme="secondary"
                        width="80px"
                        height="48px"
                        textTransform="capitalize"
                        dataCyId={'showroom-car-enquire'}
                        onClick={() => {
                            onSubmit()
                        }}
                        disabled={
                            props.isFormValid() && hasDataChanged ? false : true
                        }
                        fontSize={16}
                    >
                        Save
                    </ButtonAtom>
                </Actions>
                <div style={{ paddingTop: '20px' }} />
            </Container>
        </Wrapper>
    )
}

export default CarLocationFormMobile
