import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions/index'
import { api } from '../../services'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IHandover, IReduxError } from 'entityModels'
import { IHandoverListPayload } from '../../services/handover/listHandovers'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'

export function* listUserHandoversSaga(
    states?: ('INITIATED' | 'PROCESSING' | 'COMPLETED' | 'FAILED')[]
): any {
    try {
        let hands: IHandoverListPayload = yield call(
            api.handover.list_user_handovers_api,
            {
                states: states ?? undefined,
                offset: 100,
                limit: 0,
            }
        )

        let failed: IHandover[] = []
        let processing: IHandover[] = []

        if (hands && hands.count > 0) {
            hands.handovers.forEach((item: IHandover) => {
                if (item.handover_state === 'PROCESSING') {
                    processing = [...processing, item]
                }

                if (item.handover_state === 'FAILED') {
                    failed = [...failed, item]
                }
            })
        }
        yield put(
            actions.default.actions.listUserHandoversSuccess({
                failed: failed.length > 0 ? failed : undefined,
                processing: failed.length > 0 ? processing : undefined,
            })
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: listUserHandoversSaga,
                payload: states,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.default.errorActions.listUserHandoversError(typedError)
            )
        }
    }
}

function* watcherListUserHandovers() {
    while (true) {
        const { payload } = yield take(
            actions.default.loadingActions.listUserHandoversRequest
        )
        yield call(listUserHandoversSaga, payload)
    }
}

const list_user_handovers_sagas: any[] = [fork(watcherListUserHandovers)]

export default list_user_handovers_sagas
