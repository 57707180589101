import styled from 'styled-components'
// import TriangleHeaderLanding from '../../atoms/header/triangleHeaderLanding'
import { device } from '../../templates/displays/devices'
import FormikSignInVar2 from './FormikSignInVar2'
import center_logo from '../../../public/assets/landingPage/center_logo.svg'
import center_logo_dark from '../../../public/assets/landingPage/center_logo_dark.svg'
import LeftAlignLogo from '../../atoms/icons/components/leftAlignLogo'
import useThemes from '../../../providers/theme/hooks'

// import MobileDisplay from '../../templates/displays/mobileDisplay'

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-top: 40px;

    @media ${device.mobile} {
        width: 80vw;
        padding-top: 10px;
        padding-bottom: 50px;
        justify-content: flex-start;
    }
    @media ${device.ipad} {
        max-width: 500px;
        padding-top: 10px;
        padding-bottom: 50px;
    }
    @media ${device.desktop} {
        align-items: flex-start;
        justify-content: flex-start;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @media ${device.large_desktop} {
        padding-top: 40px;
        padding-left: 0px;
        padding-right: 0px;
    }
`

const LeftAlignLogoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media ${device.ipad} {
        width: 100%;
    }

    @media ${device.mobile} {
        display: none;
    }
`

const CenterAlignLogoWrapper = styled.div`
    display: none;

    @media ${device.mobile} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`

const MobileLogo = styled.img`
    display: none;

    @media ${device.mobile} {
        display: flex;
        height: 60px;
    }
`

const HorizontalLine = styled.div`
    display: inline;
    width: 16px;
    height: 1px;
    background-color: var(--text-strong, #1a1a1a);
    width: 40%;

    @media ${device.mobile} {
        display: none;
    }

    @media ${device.desktop} {
        width: 30%;
    }
`

const Instructions = styled.div`
    color: var(--text-strong, #1a1a1a);
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;

    @media ${device.desktop} {
        font-size: 14px;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    @media ${device.large_desktop} {
        padding-top: 30px;
        padding-bottom: 30px;
        font-size: 16px;
    }

    @media ${device.mobile} {
        font-size: 16px;
        padding-top: 22px;
        padding-bottom: 30px;
        text-align: center;
    }
`

const SecondLine = styled.div`
    display: block;
    :before {
        content: 'Enter your details below to sign in.';
    }
    @media ${device.mobile} {
        display: inline;
        :before {
            content: 'Sign in below.';
        }
    }
`

export default function SigninForm() {
    const { theme } = useThemes()
    return (
        <FormContainer>
            {/* <TriangleHeaderLanding title="CUSTODIAN" /> */}

            <LeftAlignLogoWrapper>
                <LeftAlignLogo size={32} />
                <HorizontalLine />
            </LeftAlignLogoWrapper>

            <CenterAlignLogoWrapper>
                <a
                    href="https://www.custodian.club"
                    target="_blank"
                    rel="noreferrer"
                >
                    <MobileLogo
                        src={theme === 'dark' ? center_logo_dark : center_logo}
                    />
                </a>
            </CenterAlignLogoWrapper>

            <Instructions>
                Welcome back! <SecondLine />
            </Instructions>
            <FormikSignInVar2 />
        </FormContainer>
    )
}
