import * as urls from '../urls'
import { IInvitationPayload } from 'IapiDataPayload'
import { removeUndefinedfromObject } from '../../helpers/objects'
import { customHeader } from '../headers'
import { apiSentryErrorHandler } from '../errorHandler'

export const sendInvitationAPI = (data: IInvitationPayload): Promise<any> => {
    let invitation = removeUndefinedfromObject(data)

    let rqp: string = JSON.stringify(invitation)
    const requestOptions = {
        method: 'POST',
        headers: { ...customHeader },
        body: rqp,
    }

    return fetch(urls.send_email_invitation, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (!response.ok) {
                        apiSentryErrorHandler(
                            data,
                            'Send invitation error',
                            rqp
                        )
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

// export const fn: any = { sendInvitationAPI }
