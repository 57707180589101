export const convertEnumsSumToArray = function generateCombinations(
    arr: number[],
    targetSum: number,
    currentCombination: string[] = [],
    startIndex: number = 0
): string[] {
    const result: string[] = []

    const currentSum = currentCombination.reduce(
        (acc, num) => acc + parseInt(num, 10),
        0
    )
    if (currentSum === targetSum) {
        result.push(...currentCombination)
    }

    for (let i = startIndex; i < arr.length; i++) {
        const nextElement = arr[i].toString() // Convert the number to a string

        if (currentCombination.indexOf(nextElement) === -1) {
            currentCombination.push(nextElement)
            const subCombinations = generateCombinations(
                arr,
                targetSum,
                currentCombination,
                i + 1
            )
            result.push(...subCombinations)
            currentCombination.pop()
        }
    }

    return result
}
