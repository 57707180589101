import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { normalizeArray } from '../../../../../helpers/objectRegex'
import {
    IAnyObject,
    IEntitiesObjArr,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import {
    fields_array_vehicle_modif,
    IInsuranceFieldType,
    disability_self_replicating_fields,
    membership_self_replicating_fields,
    self_replicating_claim,
    insurance_sub_fields_other_driver_track_record_motorconviction_replicating,
    insurance_sub_fields_other_driver_track_record_conviction_replicating,
    // location_security_self_replicating_fields,
} from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { useAppSelector } from '../../../../../redux/store/hooks'

import { AddIcon } from '../../../../atoms/statefulicons'

import { SelfReplicaMapEntities } from './entitiesMap'
import { IInsuranceApplicationSectionId } from '../../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import colours, {
    insurance_colours,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    field_id: string
    onAnswerChange: (id: string, answer: any) => any
    setEntityArr?: (p: IAnyObject[], fieldID: string) => any
    entitiesObjArr?: IEntitiesObjArr
    entitiesErrNormalised?: IAnyObject
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    isShown: boolean
    mobileSearchPickerSectionURL?: string
    entity_id?: string | undefined
    isMobile?: boolean
    sectionID?: IInsuranceApplicationSectionId
}

const Btn = styled.button`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 150ms;
`

export type IInsuranceFieldTypeWithAnswer = {
    defaultValue?: any
    subfield_id?: string
    id: string
    ends_section_with_divider?: boolean
    index: number
    is_last_if_false?: boolean
    is_last?: boolean
}

type IIInsuranceFieldTypeObj = {
    [key: string]: IInsuranceFieldType
}

const Title = styled.div`
    font-family: Lato-bold;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    color: var(--text-darker, #616161);
`

export const InsuranceQuoteSelfReplicatingSubFormGenerator = (props: Props) => {
    let {
        field_id,
        mobileSearchPickerSectionURL,
        isShown,
        entitiesObjArr,
        setEntityArr,
        entity_id,
        isMobile,
        sectionID,
    } = props

    const entitiesArrDraft =
        entity_id && entitiesObjArr && entitiesObjArr[entity_id]
            ? entitiesObjArr[entity_id]
            : undefined

    const [uiFieldsList, setUiFieldsList] = useState<
        IInsuranceFieldType[] | undefined
    >(undefined)

    const [titleTxt, setTitleTxt] = useState<string>('')

    let uiFieldsObj: IIInsuranceFieldTypeObj | undefined = uiFieldsList
        ? normalizeArray(uiFieldsList)
        : undefined

    let newEntityTemplate = (): IAnyObject[] => {
        let res: IAnyObject[] = []

        uiFieldsList &&
            uiFieldsList.forEach((item: IInsuranceFieldType) => {
                if (res[0]) {
                    res[0] = { ...res[0], [`${item.id}`]: undefined }
                } else res[0] = { [`${item.id}`]: undefined }
            })

        return res
    }

    type IRes = {
        data: IInsuranceFieldType[]
        title: string
    }

    let setAllCopyData = (): IRes => {
        let data: IInsuranceFieldType[] = []
        let title = ''
        if (field_id === 'has_claims') {
            data = self_replicating_claim
            title = 'Claim'
        }

        if (field_id === 'has_vehicle_modifications') {
            data = fields_array_vehicle_modif
            title = 'Modification'
        }

        if (field_id === 'has_memberships') {
            data = membership_self_replicating_fields
            title = 'Membership'
        }

        if (field_id === 'has_motoring_convictions') {
            data =
                insurance_sub_fields_other_driver_track_record_motorconviction_replicating
            title = 'Motoring conviction'
        }

        if (field_id === 'has_convictions') {
            data =
                insurance_sub_fields_other_driver_track_record_conviction_replicating
            title = 'Conviction'
        }

        if (field_id === 'has_disabilities') {
            data = disability_self_replicating_fields
            title = 'Disability'
        }

        // if (field_id.includes('parking_place_diff_than_resid_security')) {
        //     data = location_security_self_replicating_fields
        //     title = 'Security measure'
        // }

        return {
            data,
            title,
        }
    }

    useEffect(() => {
        if (isShown) {
            let all_fields = setAllCopyData()

            setUiFieldsList(all_fields.data)
            setTitleTxt(all_fields.title)
        }
    }, [field_id, isShown])

    let shouldSet =
        !props.entitiesObjArr ||
        (props.entitiesObjArr &&
            entity_id &&
            !props.entitiesObjArr[entity_id]) ||
        (props.entitiesObjArr &&
            entity_id &&
            props.entitiesObjArr[entity_id] &&
            props.entitiesObjArr[entity_id].length === 0)

    useEffect(() => {
        if (isShown && setEntityArr) {
            if (uiFieldsList) {
                if (shouldSet) {
                    // no existing data
                    let res = newEntityTemplate()
                    setEntityArr(res, `${entity_id}`)
                }
            }
        }
    }, [field_id, isShown, uiFieldsList])

    let onClickAdd = () => {
        // array copy in case of redux data immutability
        if (entitiesArrDraft && setEntityArr) {
            let newElem = newEntityTemplate()
            if (newElem) {
                setEntityArr([...entitiesArrDraft, ...newElem], `${entity_id}`)
            }
        }
    }

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    let onClickRemove = (index: number) => {
        if (application?.status === 'DRAFT') {
            if (entitiesArrDraft && setEntityArr) {
                // array copy in case of redux data immutability
                let arr2 = [...entitiesArrDraft]
                arr2.splice(index, 1)
                setEntityArr(arr2, `${entity_id}`)
            }
        }
    }

    const { theme } = useThemes()

    let AddBtn = (
        <Btn
            onClick={() => {
                onClickAdd()
            }}
            style={{
                marginTop: '20px',
                marginBottom: '30px',
            }}
        >
            <AddIcon
                onClick={() => {}}
                colour={
                    sectionID
                        ? insurance_colours[theme].sections[sectionID]
                              .primary_100
                        : colours[theme].primary
                }
            />
            <Title
                style={{
                    color: sectionID
                        ? insurance_colours[theme].sections[sectionID]
                              .primary_100
                        : colours[theme].primary,
                }}
            >
                {titleTxt !== undefined
                    ? `Add ${titleTxt.toLowerCase()}`
                    : 'Add'}
            </Title>
        </Btn>
    )

    return (
        <div key={`${field_id}-gen`} id="self gen">
            {setEntityArr && entitiesArrDraft ? (
                <div
                    style={{
                        width: '100%',
                        paddingTop: '10px',
                    }}
                >
                    <SelfReplicaMapEntities
                        mobileSearchPickerSectionURL={
                            mobileSearchPickerSectionURL
                        }
                        entitiesArrDraft={entitiesArrDraft}
                        entitiesErrNormalised={props.entitiesErrNormalised}
                        setEntityArr={setEntityArr}
                        setError={props.setError}
                        onClickRemove={onClickRemove}
                        uiFieldsObj={uiFieldsObj}
                        titleTxt={titleTxt}
                        entityTypeID={`${entity_id}`}
                        isMobile={isMobile}
                        sectionID={sectionID}
                    />
                    {application?.status === 'DRAFT' && AddBtn}
                </div>
            ) : null}
        </div>
    )
}
