import { UserCategory } from "myInterfaces";
import carclubs from "../../../public/assets/images/registration_questions/carclubs.png";
import collectors from "../../../public/assets/images/registration_questions/collectors.png";
import contentcreators from "../../../public/assets/images/registration_questions/contentcreators.png";
import dealers from "../../../public/assets/images/registration_questions/dealers.png";
import serviceproviders from "../../../public/assets/images/registration_questions/serviceproviders.png";

const registrationquestionslist: UserCategory[] = [
  {
    category: "Collector / Enthusiast",
    categoryid: "Collecting",
    imgurl: {
      landscape: collectors,
      portrait: collectors
    },
    description: [
      "Join a community of like-minded enthusiasts and collectors.",
      "Benefit from helpful collection management tooling, free marketplace and access to leading content creators.",
      "Begin building the digital provenance of your classic and collectable cars."
    ],
    imageCredits: {
      title: "Three different generations of Porsche at Ice Races GP",
      description: "Zell am See, Austria, on 1-2 February 2020. ",
      credits: "@Charlie B Photography"
    }
  },
  {
    category: "Dealer",
    categoryid: "Dealing",
    imgurl: {
      landscape: dealers,
      portrait: dealers
    },
    description: [
      "Engage with a curated community of collectors and enthusiasts (i.e. less time-wasters).",
      "Showcase your stock using the free-to-list marketplace.",
      "Update community through the chronological newsfeed."
    ],
    imageCredits: {
      title: "Special order preparation by Kremer Racing ",
      description: "o '935 Street' spec from a 1974 2.7 MFI Carrera.",
      credits: "@Charlie B Photography"
    }
  },
  {
    category: "Classic Car or Supercar Club",
    categoryid: "Club Management",
    imgurl: {
      landscape: carclubs,
      portrait: carclubs
    },
    description: [
      "Update the community with latest club news and events.",
      "Interact with existing and future members on a safe and private platform.",
      "Share expertise and knowledge with fellow owners."
    ],
    imageCredits: {
      title: "Three different generations of Ford GT",
      description: "on track at Goodwood Circuit",
      credits: "@Charlie B Photography"
    }
  },
  {
    category: "Service Provider",
    categoryid: "Service Provision",
    imgurl: {
      landscape: serviceproviders,
      portrait: serviceproviders
    },
    description: [
      "Showcase your services and high quality workmanship.",
      "Interact and engage with a curated community of collectors and enthusiasts.",
      "Build a portfolio of completed projects and an online legacy."
    ],
    imageCredits: {
      title: "Maserati 300S engine on triple Weber carburettors",
      description: "",
      credits: "@Charlie B Photography"
    }
  },
  {
    category: "Content Creator",
    categoryid: "Content Creation",
    imgurl: {
      landscape: contentcreators,
      portrait: contentcreators
    },
    description: [
      "Showcase your portfolio and build a following.",
      "Collaborate on projects and source new content.",
      "Drive commissions and source new clients."
    ],
    imageCredits: {
      title: "1958 Porsche 356A Racer at Ice Races GP",
      description: "Zell am See, Austria, on 1-2 February 2020.",
      credits: "@Charlie B Photography"
    }
  }
];

export default registrationquestionslist;
