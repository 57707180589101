import React from 'react'
import styled from 'styled-components'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import { IDropdownItem, ISharingPermissionIDS } from 'entityModels'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
// import CustomIconTagQuickFilterItemMobile from '../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FadedSlower from '../../../templates/animated/FadedSlower'
import Expander from '../../../atoms/expander/expander'
import { ITimelineEntryTypes } from 'timelineModels'
import ButtonsStandardBottomRowMobile from '../../editForms/buttons/buttonsStandardBottomRowMobile'
import colours, { ITheme } from '../../../../providers/theme/colours'
import FilterIcon from '../../../atoms/icons/components/filterIcon'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import FilterButton from '../../../atoms/Button/filterButton'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    placeholder?: string
    dataCyId?: string | undefined
    handleChange?: any
    categoryData: IDropdownItem[] | null | undefined
    selectedFilteringCategories?:
        | ITimelineEntryTypes[]
        | ISharingPermissionIDS[]
        | string[]

    selectedFilteringLabels?: string[]
    filterBySelected?: any
    customSideCopy?: string
    buttonMinWidth?: string
    isDisabled?: boolean
    entityID?: 'timeline' | 'sharing'
    customAddedCriteriaConditionsCheck?: any
    selectByElemUID?: boolean
    dataCyIdSave?: string
    notCapitalised?: boolean
    isTimeline?: boolean
}

type IStyle = {
    selected?: boolean
}

const DropdownOptions = styled.ul`
    position: absolute;
    z-index: 1000;
    margin-top: 10px;
    width: 280px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 10px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    height: 40px;
    background-color: transparent;
    padding-left: 16px;
    cursor: pointer;
    color: var(--text-strong, #1a1a1a);

    :hover {
        background-color: var(--off-bg-color, #fafafa);
    }
`

const BtnText = styled.p`
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
    color: var(--text-darker, #616161);
`

const Wrapper = styled.div`
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

const SelectedCriteriaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
`

export const AbsoluteBlueLabel = styled.div`
    position: absolute;
    right: -28px;
    top: -20px;
    display: flex;
    height: 16px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    color: var(--bg-color, #fff);
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.12px;
    background-color: var(--primary, #5ec3ca);
    border-radius: 30px;
`

type StateSelect = {
    showDropdown: boolean
    addedCriteria: ITimelineEntryTypes[] | string[]
}

class FilterByMultipleDesktop extends React.Component<Props, StateSelect> {
    state = {
        showDropdown: false,
        addedCriteria: this.props.selectedFilteringCategories
            ? this.props.selectedFilteringCategories
            : [],
    }
    _isMounted = false

    private dropdowRef = React.createRef<HTMLDivElement>()

    handleClickOutsideDropdown(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.setState({
                showDropdown: false,
                addedCriteria: this.props.selectedFilteringCategories
                    ? this.props.selectedFilteringCategories
                    : [],
            })
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.dropdowRef.current!.focus()
        let node = this.dropdowRef.current
        document.addEventListener('mousedown', (e: any) =>
            this.handleClickOutsideDropdown(e, node)
        )
    }

    componentDidUpdate(prevProps: Props) {
        if (
            prevProps.selectedFilteringCategories !==
                this.props.selectedFilteringCategories ||
            prevProps.selectedFilteringLabels !==
                this.props.selectedFilteringLabels
        ) {
            this.setState({
                addedCriteria: this.props.selectedFilteringCategories
                    ? this.props.selectedFilteringCategories
                    : [],
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropdown
        )
        this._isMounted = false
    }

    render() {
        const {
            dataCyId,
            categoryData,
            filterBySelected,
            entityID,
            customAddedCriteriaConditionsCheck,
            selectByElemUID,
            // dataCyIdSave,
        } = this.props

        const handleFilterButtonClick = () => {
            if (this.state.showDropdown === true) {
                this.setState({
                    showDropdown: false,
                })
            } else {
                this.setState({
                    showDropdown: true,
                })
            }
        }
        let currentSelectedCriteria: string[] = [...this.state.addedCriteria]
        const addOrRemoveCriteria = (criteria: string) => {
            let indexOfClickedCriteria: number =
                currentSelectedCriteria.indexOf(criteria)
            if (indexOfClickedCriteria < 0) {
                this.setState({
                    addedCriteria: [...this.state.addedCriteria, criteria],
                })
            } else {
                currentSelectedCriteria.splice(indexOfClickedCriteria, 1)
                this.setState({
                    addedCriteria: currentSelectedCriteria,
                })
            }
        }

        let addedCriteriaToDisplay = this.state.addedCriteria.map(
            (
                criteria: ITimelineEntryTypes | string | ISharingPermissionIDS,
                i: number
            ) => (
                <div style={{ padding: '3px' }} key={`${criteria} - ${i}`}>
                    <FadedSlower duration={0.2}>
                        <CustomIconTagQuickFilterItem
                            onCrossClick={() => addOrRemoveCriteria(criteria)}
                            height="24px"
                            id={this.props.isTimeline ? criteria : undefined}
                            entityID={entityID}
                        >
                            {criteria}
                        </CustomIconTagQuickFilterItem>
                    </FadedSlower>
                </div>
            )
        )

        let mappedCategoryData = (theme: ITheme) =>
            categoryData &&
            categoryData.map((category: IDropdownItem, index: number) => {
                if (category) {
                    return (
                        <StyledOption
                            selected={currentSelectedCriteria.includes(
                                category.name
                            )}
                            value={category.name}
                            key={`${category.name}-category-${index}`}
                            aria-label={category.name}
                        >
                            <ContentRow>
                                <CustomAnimatedCheckboxField
                                    // textchangedisabled
                                    width="100%"
                                    onClick={() => {
                                        selectByElemUID
                                            ? addOrRemoveCriteria(category.uid)
                                            : category.name &&
                                              addOrRemoveCriteria(
                                                  category.name.toLowerCase()
                                              )
                                    }}
                                    checked={
                                        customAddedCriteriaConditionsCheck
                                            ? customAddedCriteriaConditionsCheck(
                                                  category.uid
                                              )
                                            : category.name &&
                                              currentSelectedCriteria.includes(
                                                  category.name.toLowerCase()
                                              )
                                    }
                                    small
                                    borderColor={colours.light.text_darker}
                                >
                                    <div
                                        style={{
                                            textTransform: this.props
                                                .notCapitalised
                                                ? 'none'
                                                : 'capitalize',
                                            color: colours[theme].text_darker,
                                            paddingLeft: 8,
                                            transform: 'translateY(-3px)',
                                        }}
                                    >
                                        {category.name}
                                    </div>
                                </CustomAnimatedCheckboxField>
                            </ContentRow>
                        </StyledOption>
                    )
                } else {
                    return (
                        <StyledOption key={index} style={{ display: 'none' }} />
                    )
                }
            })

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper data-attr={dataCyId} ref={this.dropdowRef}>
                        {this.props.isTimeline ? (
                            <StandardCtaBtn
                                onClick={
                                    this.props.isDisabled
                                        ? () => {}
                                        : handleFilterButtonClick
                                }
                                boder={
                                    this.state.showDropdown
                                        ? `2px solid ${colours[theme].border_muted}`
                                        : `1px solid ${colours[theme].border_muted}`
                                }
                                bg={
                                    this.state.showDropdown
                                        ? colours[theme]
                                              .background_neutral_subtle
                                        : 'transparent'
                                }
                                customHeight="48px"
                                isDisabled={this.props.isDisabled}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 8,
                                        position: 'relative',
                                    }}
                                >
                                    <FilterIcon
                                        color={colours[theme].text_darker}
                                    />
                                    <BtnText>Categories</BtnText>
                                    {this.state.addedCriteria.length > 0 ? (
                                        <AbsoluteBlueLabel>
                                            {this.state.addedCriteria.length}
                                        </AbsoluteBlueLabel>
                                    ) : null}
                                </div>
                            </StandardCtaBtn>
                        ) : (
                            <FilterButton
                                text={
                                    this.state.addedCriteria.length > 0
                                        ? `${
                                              this.props.customSideCopy
                                                  ? this.props.customSideCopy
                                                  : `Category`
                                          } (${
                                              this.state.addedCriteria.length
                                          })`
                                        : `${
                                              this.props.customSideCopy
                                                  ? this.props.customSideCopy
                                                  : `Category`
                                          } (0)`
                                }
                                onClick={
                                    this.props.isDisabled
                                        ? () => {}
                                        : handleFilterButtonClick
                                }
                                isactive={this.state.showDropdown}
                                minWidth={this.props.buttonMinWidth}
                                isDisabled={this.props.isDisabled}
                            />
                        )}

                        <SlideDownWrapper
                            isOpen={this.state.showDropdown}
                            initialX={-280}
                            originX={'280px'}
                        >
                            <DropdownOptions>
                                <Expander
                                    height={
                                        this.state.addedCriteria.length > 0
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <SelectedCriteriaWrapper>
                                        {addedCriteriaToDisplay}
                                    </SelectedCriteriaWrapper>
                                </Expander>

                                {categoryData &&
                                    categoryData.length > 0 &&
                                    mappedCategoryData(theme)}
                                <div style={{ paddingTop: 16 }} />
                                <FadedSlower>
                                    <div
                                        style={{
                                            width: '100%',
                                            paddingTop: 16,
                                            paddingRight: 16,
                                            borderTop: `1px solid ${colours[theme].border_muted}`,
                                        }}
                                    >
                                        <ButtonsStandardBottomRowMobile
                                            removePadding
                                            cancel={() => {
                                                this.setState({
                                                    addedCriteria: [],
                                                })
                                            }}
                                            submit={() => {
                                                filterBySelected(
                                                    this.state.addedCriteria
                                                )
                                                this.setState({
                                                    showDropdown: false,
                                                })
                                            }}
                                            firstText="Clear"
                                            confirmationtext="Apply"
                                        />
                                    </div>
                                </FadedSlower>
                            </DropdownOptions>
                        </SlideDownWrapper>
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default FilterByMultipleDesktop
