import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const ApexIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.6646 20.2109L11.4177 2.10046H11.1671C11.1635 2.13836 11.1583 2.17879 11.1514 2.22175C11.0914 2.59476 10.9062 3.15899 10.5955 3.91444C10.4937 4.16212 10.3784 4.43035 10.2496 4.71914C10.1183 5.01363 9.97292 5.3295 9.81356 5.66675C9.80209 5.69102 9.79055 5.7154 9.77893 5.7399L7.27734 11.3973L9.20645 10.9996L10.1886 8.65433L12.883 15.4888L13.3553 16.6553L14.7165 20.072C14.7884 20.2403 14.833 20.4187 14.8488 20.6005C14.8528 20.6465 14.855 20.6928 14.8553 20.7392C14.8553 20.9609 14.7695 21.0811 14.6272 21.2645C14.485 21.4478 14.0706 21.4478 13.6638 21.4478H13.4132V22.1005H22.2809V21.4478H22.0303C21.9382 21.4478 21.8431 21.4501 21.7468 21.4525C21.5506 21.4573 21.3493 21.4623 21.1584 21.4478C20.9201 21.4297 20.6979 21.3812 20.5213 21.2645C20.2028 21.0551 20.0273 20.864 19.8695 20.6005C19.8013 20.4866 19.7364 20.3591 19.6646 20.2109ZM18.1939 20.6005L11.4305 5.74795C11.3394 5.94645 11.2435 6.15201 11.1428 6.36454L11.0135 6.65692L14.2759 14.9322L14.7488 16.1002L16.1041 19.5022C16.2506 19.8512 16.3343 20.223 16.3519 20.6005H18.1939Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2565 22.0994C11.4479 21.5828 10.7742 21.0826 10.2211 20.5994C7.52865 18.2474 7.6927 16.2977 9.05873 14.8102C9.12732 14.7355 9.19895 14.6619 9.27339 14.5896C9.36115 14.5043 9.45284 14.4206 9.5481 14.3386C9.58181 14.3095 9.61596 14.2807 9.65055 14.2521C9.65207 14.2508 9.65359 14.2496 9.65512 14.2483C10.0312 13.9377 10.4582 13.6515 10.9168 13.3902L10.2363 11.6437C9.61918 11.8061 9.049 11.9717 8.51979 12.144C8.51252 12.1464 8.50526 12.1487 8.498 12.1511C8.46656 12.1614 8.43527 12.1717 8.40411 12.182C7.80192 12.3814 7.25338 12.5899 6.74934 12.8127C6.52647 12.9112 6.3123 13.0124 6.10604 13.1169C4.50642 13.9274 3.38213 14.9318 2.3631 16.3371C0.533446 18.8601 1.4849 22.0994 1.4849 22.0994H5.97613C5.8383 21.9639 5.37575 21.3971 5.00997 20.5994C4.60827 19.7233 4.32328 18.5686 4.7133 17.4004C4.72627 17.3615 4.73969 17.3229 4.75355 17.2845C4.75353 17.2845 4.75356 17.2844 4.75355 17.2845C5.1965 16.0565 6.08223 15.084 6.92557 14.3842C7.47187 13.931 8.00039 13.5921 8.37929 13.3723C8.68881 13.1928 8.89848 13.0927 8.93642 13.0746C8.93807 13.0738 8.9394 13.0731 8.94039 13.0727C8.9086 13.0918 8.54438 13.3126 8.05402 13.6899C7.89645 13.8111 7.72585 13.9485 7.54907 14.1006C6.64149 14.8813 5.57099 16.0487 5.26374 17.4004C5.25068 17.4578 5.24028 17.5156 5.23241 17.5735C5.2324 17.5736 5.23242 17.5734 5.23241 17.5735C5.06103 18.8372 6.09161 20.2204 6.93861 21.1207C7.49804 21.7154 7.97737 22.0994 7.97737 22.0994H12.2565ZM3.03611 18.2514C2.80812 18.8878 2.73292 19.549 2.73783 20.1522C2.73911 20.3093 2.74585 20.4591 2.75612 20.5994H3.3931C3.15667 19.9286 2.98566 19.1225 3.03611 18.2514Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.7292 11.353C16.9367 11.3912 17.142 11.4329 17.3448 11.4777C17.7583 11.5691 18.1612 11.6735 18.5511 11.7875C20.2794 12.2929 21.7516 12.9861 22.7624 13.5663C22.7624 13.5663 20.1325 12.8221 17.2395 12.5337L16.7292 11.353Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default ApexIcon
