type Props = {
    size?: string
    color?: string
}

const GalleryIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 9.76685C15.2042 9.76685 15.3996 9.85012 15.541 9.99745L21.541 16.2474C21.8279 16.5463 21.8182 17.021 21.5194 17.3079C21.2206 17.5947 20.7458 17.5851 20.459 17.2862L15 11.5998L9.54104 17.2862C9.39961 17.4336 9.20422 17.5168 9 17.5168C8.79578 17.5168 8.60039 17.4336 8.45896 17.2862L6.6 15.3498L3.54104 18.5362C3.25419 18.8351 2.77941 18.8447 2.4806 18.5579C2.18179 18.271 2.1721 17.7963 2.45896 17.4974L6.05896 13.7474C6.20039 13.6001 6.39578 13.5168 6.6 13.5168C6.80422 13.5168 6.99961 13.6001 7.14104 13.7474L9 15.6839L14.459 9.99745C14.6004 9.85012 14.7958 9.76685 15 9.76685Z"
                fill={color ?? '#67A0E3'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.75 21.7668L2.25002 21.7668L2.25002 2.26685L21.75 2.26685L21.75 21.7668ZM20.25 20.2668L20.25 3.76685L3.75002 3.76685L3.75002 20.2668L20.25 20.2668Z"
                fill={color ?? '#67A0E3'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.67104 7.06689C7.92546 7.06689 7.32104 7.67131 7.32104 8.41689C7.32104 9.16248 7.92546 9.76689 8.67104 9.76689C9.41663 9.76689 10.021 9.16248 10.021 8.41689C10.021 7.67131 9.41663 7.06689 8.67104 7.06689ZM5.82104 8.41689C5.82104 6.84288 7.09703 5.56689 8.67104 5.56689C10.2451 5.56689 11.521 6.84288 11.521 8.41689C11.521 9.99091 10.2451 11.2669 8.67104 11.2669C7.09703 11.2669 5.82104 9.99091 5.82104 8.41689Z"
                fill={color ?? '#67A0E3'}
            />
        </svg>
    )
}

export default GalleryIcon
