import { motion } from 'framer-motion'

type Props = {
    active: boolean
    height: string
    width: string
    color?: string
}

const AccountIcon = ({ active, width, height, color }: Props) => (
    <motion.div whileTap={{ scale: 1.3 }}>
        <svg
            width={width}
            height={height}
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.4818 5.2174C14.4818 7.98971 12.2502 10.2348 9.5 10.2348C6.74991 10.2348 4.51818 7.98971 4.51818 5.2174C4.51818 2.44506 6.74991 0.2 9.5 0.2C12.2502 0.2 14.4818 2.44506 14.4818 5.2174ZM0.431816 19.8C0.305099 19.8 0.2 19.6961 0.2 19.5653V18.6958C0.2 16.981 0.76887 15.4032 1.88295 14.2552C2.9954 13.1088 4.66798 12.3739 6.90908 12.3739H12.0908C14.332 12.3739 16.0046 13.1088 17.1171 14.2552C18.2311 15.4032 18.8 16.981 18.8 18.6958V19.5653C18.8 19.6961 18.695 19.8 18.5682 19.8H0.431816Z"
                fill={active ? 'black' : 'white'}
                stroke={color ?? 'black'}
                strokeWidth="1"
            />
        </svg>
    </motion.div>
)

export default AccountIcon
