import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../atoms/Inputfield/inputField'
import TextArea from '../../../atoms/textarea/textarea'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { ICar, IUpdateCarGeneralPayloadReq } from 'entityModels'

import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
// import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { writeCarDataGeneralRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import useThemes from '../../../../providers/theme/hooks'
// import { ModalTitle } from '../../../molecules/technicalInformation/modal'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 7vh;
    height: 100vh;
    /* min-height: -webkit-fill-available; */
    overflow-y: scroll;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.mobile} {
        padding-top: 5vh;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const FieldTitle1 = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 18px;
    letter-spacing: -1px;
    color: var(--text-darker, #616161);
    padding-bottom: 10px;

    @media screen and (max-width: 325px) {
        padding-bottom: 15px;
        font-size: 18px;
    }
`

const InnerFormPadding = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    @media ${device.ipad} {
        max-width: 600px;
    }
`

const InnerSectionPadding = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--off-bg-color, #fafafa);
    @media ${device.ipad} {
        padding-bottom: 40px;
    }
`

const TopInnerSectionPadding = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: var(--off-bg-color, #fafafa);
    @media ${device.ipad} {
        padding-bottom: 40px;
        padding-left: 0px;
        padding-right: 0px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 16px;
    letter-spacing: -1px;
    color: var(--text-strong, #1a1a1a);
    /* padding-bottom: 10px; */
`

const TextAreaWidthOneLiner = styled.div`
    width: 100%;
    height: 150px;
    font-size: 12px !important;
`

export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
`

export const DescriptionHeader = styled.div`
    font-size: 14px;
    padding-top: 6px;
    font-family: Lato;
    color: var(--text-darker, #616161);
`
const ButtonsOverWrapper = styled.div`
    width: 100%;
    /* position: fixed;
    bottom: 0;
    bottom: env(safe-area-inset-bottom); */
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding-bottom: 40px;
    @media ${device.ipad} {
        max-width: 600px;
        padding-bottom: 64px;
    }
`

const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* padding-top: 32px; */
    @media ${device.mobile} {
        padding: 30px;
        padding-top: 0px;
        padding-bottom: 40px;
    }
    @media (max-width: 350px) {
        padding: 20px;
        padding-top: 0px;
    }
`

const HorizLine = styled.div`
    padding-top: 32px;
    width: 100%;
    border-top: 1px solid var(--border-muted, #e5e5e5);
`

const LargePadding = styled.div`
    padding-top: 50px;
`

const dispatchProps = {
    writeCarDataGeneralRequest: (p: IUpdateCarGeneralPayloadReq) =>
        writeCarDataGeneralRequest(p),
}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    title: string
    short_description: string
}

interface OtherProps extends RouteComponentProps<any> {
    close: () => void
    carData: ICar | null | undefined
    writeCarDataGeneralRequest: (p: IUpdateCarGeneralPayloadReq) => {}
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { values, close, handleSubmit, errors } = props

    const { theme } = useThemes()

    return (
        <>
            {/* <TouchScrollable> */}
            <EditFormContainerMobile id="scrollable-content">
                <InnerFormPadding>
                    <TopInnerSectionPadding
                        style={{
                            backgroundColor: 'transparent',
                        }}
                    >
                        <FieldTitle1>Update your car's details</FieldTitle1>
                        <Expander
                            height={
                                values.title.length > 1 && errors.title
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <Faded>
                                <div style={{ paddingTop: '5px' }} />
                                <Error className="animated fadeIn slower">
                                    {errors.title}
                                </Error>
                            </Faded>
                        </Expander>
                        <div style={{ paddingTop: '32px' }} />
                        <InputField
                            theme={theme}
                            id="title"
                            name="title"
                            placeholder="Car Title"
                            helperText="Example: year | make | model"
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={values.title}
                            onEnterKeyPress={props.handleSubmit}
                        />
                    </TopInnerSectionPadding>
                    <InnerSectionPadding>
                        <FieldTitle>Vehicle bio</FieldTitle>
                        <Expander
                            height={errors.short_description ? 'auto' : 0}
                        >
                            <Faded>
                                <div style={{ paddingTop: '5px' }} />
                                <Error className="animated fadeIn slower">
                                    {errors.short_description}
                                </Error>
                            </Faded>
                        </Expander>
                        <DescriptionHeader>
                            Share a brief overview of your car below
                        </DescriptionHeader>
                        <div style={{ paddingTop: '20px' }} />

                        <TextAreaWidthOneLiner id="scrollable-content">
                            <TextArea
                                id="short_description"
                                name="short_description"
                                placeholder="i.e An immaculate and original 1600 GTA Stradale, restored to concours standards by Alfaholics with performance enhancements for road and road/rally events."
                                tabindex={2}
                                aria-live="polite"
                                formikprops={props}
                                value={values.short_description}
                            />
                        </TextAreaWidthOneLiner>
                    </InnerSectionPadding>

                    <LargePadding />
                </InnerFormPadding>
                <ButtonsOverWrapper>
                    <HorizLine />
                    <ButtonsWrapper>
                        {/* <ButtonAtom
                            theme="modal-action-button-desctructive"
                            width="185px"
                            height="41px"
                            fontSize={14}
                            onClick={() => {
                                carData &&
                                    props.history.push(
                                        `/car/${carData.id}/deletion`
                                    )
                                close()
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Lato-Light',
                                }}
                            >
                                Delete car
                            </span>
                        </ButtonAtom> */}

                        <ButtonsStandardBottomRowMobile
                            cancel={close}
                            submit={handleSubmit}
                            confirmationtext={'save'}
                            disabled={
                                (values.title && errors.title) ||
                                (values.short_description &&
                                    errors.short_description)
                                    ? true
                                    : false
                            }
                            height="40px"
                        />
                    </ButtonsWrapper>
                </ButtonsOverWrapper>
            </EditFormContainerMobile>
            {/* </TouchScrollable> */}
        </>
    )
}

const CarOverviewEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        title: props.carData ? props.carData.title : '',
        short_description: props.carData ? props.carData.short_description : '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                title: Yup.string().max(
                    41,
                    'The title must be be less than 40 characters.'
                ),
                short_description: Yup.string().max(
                    266,
                    'The short description must be be less than 265 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (
            props.carData &&
            props.carData.title !== values.title &&
            props.carData.short_description !== values.short_description
        ) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carData.id,
                dataListForm: [
                    {
                        id: 'title',
                        text: 'title',
                        answer: values.title,
                    },
                    {
                        id: 'short_description',
                        text: 'short_description',
                        answer: values.short_description,
                    },
                ],
            }

            props.writeCarDataGeneralRequest(payload)
        } else if (
            props.carData &&
            props.carData.title !== values.title &&
            props.carData.short_description === values.short_description
        ) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carData.id,
                dataListForm: [
                    {
                        id: 'title',
                        text: 'title',
                        answer: values.title,
                    },
                ],
            }

            props.writeCarDataGeneralRequest(payload)
        } else if (
            props.carData &&
            props.carData.title === values.title &&
            props.carData.short_description !== values.short_description
        ) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carData.id,
                dataListForm: [
                    {
                        id: 'short_description',
                        text: 'title',
                        answer: values.short_description,
                    },
                ],
            }

            props.writeCarDataGeneralRequest(payload)
        }

        props.close()
    },
})(InnerForm)

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarOverviewEditFormMobile)
)
