import { ICustomErrorData, IReduxError } from 'entityModels'
import { call, fork, put, select, take } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    addFullStatsToNonDraftApplications,
    addStatsToReducer,
    ConvertInsuranceApplicationErrorsToReducer,
    ConvertInsuranceApplicationToReducer,
    ValidateReducerData,
} from '../../../conversions/insuranceApplication/api/applicationToReducer'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'

import {
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplicationValidation,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'
// import { ValidateInsuranceApplication } from './validateApplication'
import { IInsuranceApplication_API } from '../../types'
// import { validResp } from '../../backendMock'
import { ValidateInsuranceApplication } from './validateApplication'
import { IRootState } from '../../../store'

export let insurance_application_state_select = (state: IRootState) =>
    state.insuranceQuoteApplication

export function* ValidateAndAddStatsToApplication(
    application_id: string,
    res: IInsuranceApplication_API
): any {
    let applicationReducerData: IInsuranceQuoteApplication = {
        ...ConvertInsuranceApplicationToReducer(res),
    }

    let application: IInsuranceQuoteApplication_Reducer = yield select(
        insurance_application_state_select
    )

    let isValidationSheetOpen = application.showValidationSheet

    const validationres = yield call(ValidateInsuranceApplication, {
        application: applicationReducerData,
    })

    applicationReducerData['id'] = application_id

    if (validationres && Object.keys(validationres).length > 0) {
        let validationReducerData: IInsuranceQuoteApplicationValidation = {
            ...ConvertInsuranceApplicationErrorsToReducer(validationres),
        }

        // console.log('validationReducerData', validationReducerData)

        let applicationReducerDataWithStats = addStatsToReducer(
            applicationReducerData,
            validationReducerData
        )

        if (isValidationSheetOpen) {
            let validatedApplication = ValidateReducerData(
                applicationReducerDataWithStats,
                validationReducerData
            )
            return validatedApplication
        }

        return applicationReducerDataWithStats
    } else {
        let applicationReducerDataWithStats =
            addFullStatsToNonDraftApplications(applicationReducerData)

        return applicationReducerDataWithStats
    }
}

export function* GetInsuranceApplication(application_id: string): any {
    try {
        let res: IInsuranceApplication_API = yield call(
            api.insuranceApplication.getInsuranceApplication,
            application_id
        )

        if (res.status === 'DRAFT') {
            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                application_id,
                res
            )

            yield put(
                insuranceActions.get_insurance_application_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            let applicationReducerData: IInsuranceQuoteApplication = {
                ...ConvertInsuranceApplicationToReducer(res),
            }
            applicationReducerData['id'] = application_id

            let applicationReducerDataWithStats =
                addFullStatsToNonDraftApplications(applicationReducerData)
            yield put(
                insuranceActions.get_insurance_application_success(
                    applicationReducerDataWithStats
                )
            )
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetInsuranceApplication,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.get_insurance_application_error(customError)
            )
        }
    }
}

function* Watcher_Get_Application() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.get_insurance_application_request
        )
        yield call(GetInsuranceApplication, payload)
    }
}

const insurance_get_application: any[] = [fork(Watcher_Get_Application)]

export default insurance_get_application
