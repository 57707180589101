import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import file_upload from '../../../public/assets/icons/add_strong.svg'
import { message } from 'antd'
// import DropdownExpanderHover from '../../atoms/menu/dropdownExpander/dropdownExpanderHover'
import './antd.css'
import { AttachmentTypesEnumWithDotArr } from '../../../redux/attachments/enums'
import IconButton from '../../atoms/Button/iconButton'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
const StyledDropzone = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    border-radius: 5px;
    height: 40px;
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    font-size: 14px;
    cursor: pointer;
    transition: all 500ms;
    background-color: var(--primary_08);

    :hover {
        background-color: var(--primary_20);
    }
`

const AcceptContainer = styled.div`
    width: 100%;
    z-index: 2;
`

const errorWarning = () => {
    message.error({
        content: 'Please upload a maximum of 20 files.',
        duration: 2,
    })
}

const errorFileSizeWarning = () => {
    message.error({
        content: 'The maximum file size allowed per upload is 20MB.',
        duration: 2,
    })
}

const errorFileTypeWarning = (filename: string, message_text: string) => {
    message.error({
        content: `${filename} is not supported, ${message_text}`,
        duration: 2,
    })
}

type Props = {
    handleFilesGiven: any
    progressArray: any
    filesUploaded: any
    isTopPage?: boolean
    scrollToElement?: any
}

function AcceptAttachments(props: Props) {
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length > 0 && acceptedFiles.length <= 20) {
                props.handleFilesGiven && props.handleFilesGiven(acceptedFiles)
            }
        },
        [props]
    )

    const onDropRejected = useCallback((fileRejections: any[]) => {
        let showTooManyFilesWarning = false

        for (let i = 0; i < fileRejections.length; i++) {
            if (fileRejections[i].errors) {
                let file = fileRejections[i].file
                let errors: any[] = fileRejections[i].errors
                for (let i = 0; i < errors.length; i++) {
                    if (errors[i].code === 'file-invalid-type') {
                        errorFileTypeWarning(file.name, errors[i].message)
                    }
                    if (
                        errors[i].code === 'file-too-large' ||
                        errors[i].code === 'file-too-small'
                    ) {
                        errorFileSizeWarning()
                    }

                    if (errors[i].code === 'too-many-files') {
                        showTooManyFilesWarning = true
                    }
                }
            }
        }
        if (showTooManyFilesWarning) {
            errorWarning()
        }
    }, [])

    const {
        getRootProps,
        getInputProps,
        // isDragActive,
        // isDragAccept,
        // isDragReject,
    } = useDropzone({
        onDrop,
        onDropRejected,
        accept: AttachmentTypesEnumWithDotArr,
        maxFiles: 20,
        minSize: 0,
        maxSize: 20000000,
    })

    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)
    const { theme } = useThemes()

    // let fake_progress: number = 30;
    return (
        <AcceptContainer>
            <div className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />

                    {props.isTopPage ? (
                        <IconButton
                            buttonheight="48px"
                            buttonwidth="48px"
                            bgcolor="transparent"
                            borderColor={colours[theme].border_muted}
                            icon={'new_share_link_black'}
                            onClick={props.scrollToElement}
                            hasTooltip
                            tooltipColor={'#1a1a1a'}
                            tooltipText={'Add attachments'}
                            tooltipPlacement={'bottom'}
                            isTooltipVisible={tooltipVisible}
                            onMouseEnter={() => setTooltipVisible(true)}
                            onMouseLeave={() => setTooltipVisible(false)}
                        />
                    ) : (
                        <StyledDropzone>
                            <img src={file_upload} alt="upload file" />
                            <span style={{ width: '8px' }} />
                            <div>Drag & Drop file(s) or press to browse</div>
                        </StyledDropzone>
                    )}
                </div>
            </div>
        </AcceptContainer>
    )
}

export default AcceptAttachments
