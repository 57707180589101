import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import SingleTimelineItemBodyMobile from '../../molecules/singleTimelineItemBody/singleTimelineItemBodyMobile'
import TimelineItemReturnNav from '../../atoms/header/timelineItemReturnNav'
import {
    ICostItem,
    IDeleteTimelineItemPayloadRequest,
    IGetAllUserEntryLabelsRequest,
    IGetTimelineItemByIdPayloadRequest,
    ITimelineCreationFormFieldsData,
    ITimelineDataByCarNormalised,
    ITimelineDataToBeSubmittedObj,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
    IUpdateEntryLabelPayload,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'

import {
    IisOpen,
    ILabel,
    ITimelineCreateForms,
    ITimelineEditForms,
    IUser,
} from 'myModels'
import PageSubMenuActions from '../../atoms/menu/pageSubMenuActions/pageSubMenuActions'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import styled from 'styled-components'
// import {
//     manageTimelineCreationFormState,
//     manageTimelineItemEditForm,
// } from '../../../redux/editForms/reducer'
import SideSlider from '../../templates/animated/sideSlider'
import {
    addAttachmentsToEntryRequest,
    deleteTimelineEntryRequest,
    // getAllTimelineItemsByCarIDRequest,
    getAllUserCarEntryLabelsRequest,
    getTimelineItemByIDRequest,
    removeLabelFromCarEntryRequest,
    updateTimelineEntryRequest,
} from '../../../redux/timeline/actions/requestActions'

import EntryPageTopHeaderDesktop from '../../molecules/entryPageTopHeader/entryPageTopHeaderDesktop'
import SingleTimelineItemBodyDesktop from '../../molecules/singleTimelineItemBody/singleTimelineItemBodyDesktop'
import { IDropdownItem, ITechnicalInformationObject } from 'entityModels'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
import {
    setActiveCostSuccess,
    setActiveEntrySuccess,
} from '../../../redux/timeline/actions/successActions'

import Loader from '../../atoms/loader/loader'
import { timeline_category_data } from '../../../redux/timeline/data'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import { IAddEntryAttachmenstApiRequest } from 'ApiInterfaces'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import PenIcon from '../../atoms/icons/components/penIcon'
import BinIcon from '../../atoms/icons/components/binIcon'
import EntryAttachmentsFormWrapperMobile from '../../organisms/editForms/attachmentsFormWrapper/entryAttachmentsFormWrapperMobile'
import { editFormsActions } from '../../../redux/editForms/reducer'
import WrapperImageFormsDesktop from '../../organisms/editForms/imageFormWrapper/wrapperImageFormsDesktop'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import FadedSlower from '../../templates/animated/FadedSlower'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import CostIcon from '../../atoms/icons/components/historyFile/costIcon'
import colours from '../../../providers/theme/colours'

export const EntryOptionsMenuWrapper = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: var(--off-bg-color, #fafafa);
    width: 248px;
    border-radius: 16px;
    padding: 24px;
`

export const EntryTopSectionWrapper = styled.div`
    display: flex;
    width: 100%;
    padding: 24px 40px;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
`

interface Props extends RouteComponentProps<any> {
    activeEntry: ITimelineItem | undefined | null
    isActiveEntryLoading: boolean
    activeCost: ICostItem | undefined | null
    timelineItemEditForm: ITimelineEditForms
    // manageTimelineItemEditForm: any
    attachmentsObj: INormalisedAttachmentsByID
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    // manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted: ITimelineDataToBeSubmittedObj
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) => {}
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) => void
    setActiveEntry: (payload: ITimelineItem) => void
    deleteTimelineEntry: (payload: IDeleteTimelineItemPayloadRequest) => void
    user_searchable_labels_list: IDropdownItem[] | null | undefined
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    isUserLoading: boolean
    setCurrentCar: (car_id: string) => void
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    getAllUserCarEntryLabels: (payload: IGetAllUserEntryLabelsRequest) => void
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) => void
    entry_labels_list: ILabel[] | undefined
    getAllTimelineItemsByCarIDRequest: any
    addAttachmentToEntryRequest: (p: IAddEntryAttachmenstApiRequest) => any
    isEntryAttachmentLoading: boolean
    isCostAttachmentLoading: boolean
    userCurrency: string | undefined
    setInitialLandingUrl: (str: string) => void
    technicalInformationData?: ITechnicalInformationObject
    readOnlyMode?: boolean
    setActiveCost: (payload: ICostItem | undefined) => void
    isAddGalleryImageToEntryFormOpen: boolean
    toggleAddGalleryImageToEntryForm: (p: IisOpen) => void
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        carsData: state.entities.carsData.cars,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        data_to_be_submitted:
            state.timeline.formsData.creation.data_to_be_submitted,
        activeEntry: state.timeline.activeEntry,
        isActiveEntryLoading: state.timeline.loading,
        isUserLoading: state.user.loading,
        activeCost: state.timeline.activeCost,
        timelineItemEditForm: state.editForms.timelineItemEditForm,
        attachmentsObj: state.attachments.attachmentsById,
        timeline_creation_steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        timeline_creation_steps_object_of_indexed_list:
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list,
        user_searchable_labels_list:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
        entry_labels_list:
            state.timeline.activeEntry && state.timeline.activeEntry.labels,
        isEntryAttachmentLoading: state.timeline.isEntryAttachmentLoading,
        isCostAttachmentLoading: state.timeline.isCostAttachmentLoading,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
        technicalInformationData:
            state.entities.technicalInformationData.technical_information,
        isAddGalleryImageToEntryFormOpen:
            state.editForms.formsData.isAddGalleryImageToEntryFormOpen.isOpen,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    // getAllTimelineItemsByCarIDRequest: (
    //     payload: IGetTimelineItemByIdPayloadRequest
    // ) => getAllTimelineItemsByCarIDRequest(payload),
    // manageTimelineItemEditForm: (payload: ITimelineEditForms) =>
    //     manageTimelineItemEditForm(payload),
    // manageTimelineCreationFormState: (payload: ITimelineCreateForms) =>
    //     manageTimelineCreationFormState(payload),
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) =>
        getTimelineItemByIDRequest(payload),
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) =>
        updateTimelineEntryRequest(payload),
    deleteTimelineEntry: (payload: IDeleteTimelineItemPayloadRequest) =>
        deleteTimelineEntryRequest(payload),
    setActiveEntry: (payload: ITimelineItem) => setActiveEntrySuccess(payload),
    setActiveCost: (payload: ICostItem | undefined) =>
        setActiveCostSuccess(payload),
    getAllUserCarEntryLabels: (payload: IGetAllUserEntryLabelsRequest) =>
        getAllUserCarEntryLabelsRequest(payload),
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) =>
        removeLabelFromCarEntryRequest(payload),
    addAttachmentToEntryRequest: (payload: IAddEntryAttachmenstApiRequest) =>
        addAttachmentsToEntryRequest(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    toggleAddGalleryImageToEntryForm: (p: IisOpen) =>
        editFormsActions.toggleAddGalleryImageToEntryForm(p),
}

type State = {
    isMobileActionsSubMenuOpen: boolean
    bottomsheetTimer: number
    showConfirmDeleteEntrySheet: boolean
    newFile?: File
    progressPercent: number
    showJourneyCard: boolean
}

class CarTimelineSingleEntry extends React.PureComponent<Props, State> {
    state = {
        isMobileActionsSubMenuOpen: false,
        bottomsheetTimer: 500,
        showConfirmDeleteEntrySheet: false,
        newFile: undefined,
        progressPercent: 0,
        showJourneyCard: true,
    }

    hideJourneyCard = () => {
        this.setState({
            ...this.state,
            showJourneyCard: false,
        })
    }

    displayJourneyCard = () => {
        this.setState({
            ...this.state,
            showJourneyCard: true,
        })
    }

    navigateEntries = (direction: 'next' | 'previous') => {
        let carid = this.props.match.params.carid
        let all_entries: ITimelineItem[] | null | undefined =
            carid &&
            this.props.carsTimelineData &&
            this.props.carsTimelineData[carid] &&
            this.props.carsTimelineData[carid].entries

        let activeEntry = this.props.activeEntry
        let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
            function (entry: ITimelineItem) {
                return activeEntry && entry.id === activeEntry.id
            }
        )

        let nextEntry =
            all_entries &&
            (indexOFActiveEntry || indexOFActiveEntry === 0) &&
            (indexOFActiveEntry < all_entries.length - 1
                ? all_entries[indexOFActiveEntry + 1]
                : all_entries[all_entries.length - 1])

        let previousEntry =
            all_entries &&
            (indexOFActiveEntry || indexOFActiveEntry === 0) &&
            (indexOFActiveEntry > 1
                ? all_entries[indexOFActiveEntry - 1]
                : all_entries[0])

        if (direction === 'next' && nextEntry) {
            this.props.history.push(
                `/car/${carid}/history-file/entry?entryid=${nextEntry.id}`
            )
            this.props.setActiveEntry(nextEntry)
        } else if (previousEntry) {
            this.props.history.push(
                `/car/${carid}/history-file/entry?entryid=${previousEntry.id}`
            )
            this.props.setActiveEntry(previousEntry)
        } else return
    }

    componentDidMount() {
        // CAR
        let carid = this.props.match.params.carid
        this.props.setCurrentCar(carid)

        // ENTRY
        const params = new URLSearchParams(this.props.location.search)
        let entryid: string | null = params.get('entryid')

        this.props.setInitialLandingUrl(
            `/car/${carid}/history-file/entry?entryid=${entryid}`
        )

        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        if (userLoggedIn) {
            entryid &&
                this.props.getEntryByIDRequest({
                    entryUID: entryid,
                    car_id: carid,
                })
        }

        // setting active cost as undefined everytime user gets back to entry page
        // where he can see all entry costs and so no cost should be active
        // this.props.setActiveCost(undefined)
    }

    componentDidUpdate(prevProps: Props) {
        // CAR
        let carid = this.props.match.params.carid
        // ENTRY
        const params = new URLSearchParams(this.props.location.search)
        let entryid: string | null = params.get('entryid')

        if (prevProps.userLoggedIn !== this.props.userLoggedIn && entryid) {
            this.props.getEntryByIDRequest({
                entryUID: entryid,
                car_id: carid,
            })
        }

        if (this.props.timelineItemEditForm.isOpen === false) {
            this.setState({ bottomsheetTimer: 50 })
        } else this.setState({ bottomsheetTimer: 500 })

        if (
            this.props.activeEntry !== prevProps.activeEntry ||
            this.props.activeCost !== prevProps.activeCost ||
            prevProps.userLoggedIn !== this.props.userLoggedIn
        ) {
            entryid &&
                this.props.getEntryByIDRequest({
                    entryUID: entryid,
                    car_id: carid,
                })
        }

        // // keep active cost until cost attachments is uploaded
        // if (
        //     prevProps.isCostAttachmentLoading !==
        //     this.props.isCostAttachmentLoading
        // ) {
        //     if (
        //         !this.props.isCostAttachmentLoading &&
        //         !this.props.isActiveEntryLoading
        //     ) {
        //         this.props.setActiveCost(undefined)
        //     }
        // }

        // below check will always pass because labels is a separate call
        // so when timeline item is received from BE and assigned as active entry,
        // active entry has no labels at first
        // until the labels call goes out and populates activeEntry.labels
        // it always goes out first and sometimes doubles the compDidMount call
        // because it goes out too early, it overwrites what's been updated so we need to remove it
        // also we have labels reducer synced everywhere so no need for it anyway

        // if (
        //     this.props.activeEntry &&
        //     this.props.activeEntry?.labels !== prevProps.activeEntry?.labels &&
        //     entryid
        // ) {
        //     console.log(
        //         'get entry runs from L301 => cond',
        //         this.props.activeEntry?.labels !==
        //             prevProps.activeEntry?.labels,
        //         'prev props',
        //         prevProps.activeEntry?.labels,
        //         'current props',
        //         this.props.activeEntry.labels
        //     )
        //     this.props.getEntryByIDRequest({
        //         entryUID: entryid,
        //         car_id: carid,
        //     })
        // }
    }

    // handleFile = (file: File) => {
    //     let fileVal: IAttachmentRequestPayload = {
    //         filename: file.name ? file.name : 'no_file_name_received',
    //         type: file.type ? file.type : 'no_file_type_received',
    //         file: file,
    //     }
    //     // get presgined urls, and it can be a single file

    //     let fp: IAddEntryAttachmenstApiRequest = {
    //         attachments: [fileVal],
    //         carID: this.props.match.params.carid,
    //         entryID: this.props.match.params.entryid,
    //     }

    //     this.props.addAttachmentToEntryRequest(fp)

    //     return this.setState({
    //         newFile: file,
    //         progressPercent: 100,
    //     })
    // }

    componentWillUnmount() {}

    generatePrePopulatedLabels = (): IDropdownItem[] => {
        let { activeEntry, user_searchable_labels_list } = this.props

        let user_searchable_labels_list_names =
            user_searchable_labels_list?.map((label) => label.name)

        if (activeEntry && activeEntry.categoryID) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                activeEntry.categoryID
            ].pre_populated_labels
                .filter(
                    (label) =>
                        !user_searchable_labels_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    render() {
        let carid: string = this.props.match.params.carid

        let {
            // manageTimelineItemEditForm,
            activeEntry,
            attachmentsObj,
            // costsObj,
            // manageTimelineCreationFormState,
            deleteTimelineEntry,
            removeLabelFromCarEntry,
            isActiveEntryLoading,
            isUserLoading,
            isEntryAttachmentLoading,
            isCostAttachmentLoading,
            userCurrency,
            userLoggedIn,
            technicalInformationData,
        } = this.props

        const handleActionSubMenu = () => {
            this.setState({
                ...this.state,
                isMobileActionsSubMenuOpen:
                    !this.state.isMobileActionsSubMenuOpen,
            })
        }

        let attachmentSectionDiv = document.getElementById(
            'attachments-section'
        )

        const removeLabel = (labelname: string) => {
            let labelToRemove =
                activeEntry &&
                activeEntry.labels &&
                activeEntry.labels.filter((label) => label.name === labelname)

            activeEntry &&
                labelToRemove &&
                removeLabelFromCarEntry({
                    car_id: carid,
                    entry_id: activeEntry.id,
                    labels: labelToRemove,
                })
        }

        let userDistanceUnit =
            userLoggedIn &&
            userLoggedIn.preferences &&
            userLoggedIn.preferences.data &&
            userLoggedIn.preferences.data.units &&
            userLoggedIn.preferences.data.units.data &&
            userLoggedIn.preferences.data.units.data.distance_unit &&
            userLoggedIn.preferences.data.units.data.distance_unit
                .user_choice_id

        let carMileageFromState: number | string | undefined | null =
            carid &&
            technicalInformationData &&
            technicalInformationData[`hf-${carid}`] &&
            technicalInformationData[`hf-${carid}`].mileage &&
            technicalInformationData[`hf-${carid}`].mileage.answer

        let carMileage: number | undefined | null =
            typeof carMileageFromState === 'string'
                ? parseInt(carMileageFromState)
                : carMileageFromState

        const params = new URLSearchParams(this.props.location.search)
        let entryid: string | null = params.get('entryid')

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        <Loader
                            isLoading={isActiveEntryLoading || isUserLoading}
                        />

                        <Faded>
                            {activeEntry && (
                                <>
                                    <DesktopDisplayOnly>
                                        <FullPagePageWrapper>
                                            {this.state.showJourneyCard &&
                                            activeEntry.categoryID ===
                                                'insurance' ? (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        paddingBottom: 0,
                                                        paddingTop: 16,
                                                        paddingLeft: 32,
                                                        paddingRight: 48,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            maxWidth: 1200,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <FadedSlower>
                                                            <JourneyRedirectCardDesktop
                                                                sectionID="insurance"
                                                                dataPH={
                                                                    'start_car_profile_journey'
                                                                }
                                                                copyData={
                                                                    journeyRedirectCardsCopy
                                                                }
                                                                onStartNow={() => {
                                                                    this.props.history.push(
                                                                        {
                                                                            pathname: `/journey/insurance`,
                                                                            search: `?step=welcome&carid=${carid}`,
                                                                            state: {
                                                                                prevPath: `${
                                                                                    this
                                                                                        .props
                                                                                        .history
                                                                                        .location
                                                                                        .pathname
                                                                                }${
                                                                                    this
                                                                                        .props
                                                                                        .history
                                                                                        .location
                                                                                        .search ??
                                                                                    ''
                                                                                }`,
                                                                            },
                                                                        }
                                                                    )
                                                                }}
                                                            />
                                                        </FadedSlower>
                                                    </div>
                                                </div>
                                            ) : null}
                                            <Faded>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <EntryTopSectionWrapper>
                                                        <div
                                                            style={{
                                                                maxWidth: 1200,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <EntryPageTopHeaderDesktop
                                                                item={
                                                                    activeEntry
                                                                }
                                                                elementToScrollTo={
                                                                    attachmentSectionDiv
                                                                }
                                                                entityID={
                                                                    activeEntry.id
                                                                }
                                                                carID={carid}
                                                                entityType="entry"
                                                                onEditTitleClick={() => {
                                                                    this.props.history.push(
                                                                        `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=general`
                                                                    )
                                                                }}
                                                                goBackToTimeline={
                                                                    `/car/${carid}/history-file`

                                                                    // this.props.history.goBack()
                                                                }
                                                                onAddCost={() => {
                                                                    this.props.history.push(
                                                                        `/car/${carid}/history-file/${entryid}/cost/create`
                                                                    )
                                                                }}
                                                                onDeleteClick={() =>
                                                                    this.setState(
                                                                        {
                                                                            ...this
                                                                                .state,
                                                                            showConfirmDeleteEntrySheet:
                                                                                true,
                                                                        }
                                                                    )
                                                                }
                                                                goToNextEntry={() =>
                                                                    this.navigateEntries(
                                                                        'next'
                                                                    )
                                                                }
                                                                goToPreviousEntry={() =>
                                                                    this.navigateEntries(
                                                                        'previous'
                                                                    )
                                                                }
                                                                // setFileUploaded={
                                                                //     this.handleFile
                                                                // }
                                                            />
                                                        </div>
                                                    </EntryTopSectionWrapper>
                                                </div>
                                                <div
                                                    style={{ paddingTop: 32 }}
                                                />

                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        padding: '0px 40px',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            maxWidth: 1200,
                                                            position:
                                                                'relative',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <EntryOptionsMenuWrapper>
                                                            <EntryOptionsMenuBar
                                                                carid={carid}
                                                                activeEntry={
                                                                    activeEntry
                                                                }
                                                            />
                                                        </EntryOptionsMenuWrapper>
                                                        <SingleTimelineItemBodyDesktop
                                                            item={activeEntry}
                                                            entityType="entry"
                                                            carID={carid}
                                                            entityID={
                                                                activeEntry.id
                                                            }
                                                            attachmentsObj={
                                                                attachmentsObj
                                                            }
                                                            onEditTitleClick={() => {
                                                                this.props.history.push(
                                                                    `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=general`
                                                                )
                                                            }}
                                                            addNewCost={() => {
                                                                this.props.history.push(
                                                                    `/car/${carid}/history-file/${entryid}/cost/create`
                                                                )
                                                            }}
                                                            // setFileUploaded={
                                                            //     this.handleFile
                                                            // }
                                                            onEditShortDescription={() => {
                                                                this.props.history.push(
                                                                    `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=general`
                                                                )
                                                            }}
                                                            onEditNotes={() => {
                                                                this.props.history.push(
                                                                    `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=notes`
                                                                )
                                                            }}
                                                            onEditInsurer={() => {
                                                                this.props.history.push(
                                                                    `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=insurance_fields`
                                                                )
                                                            }}
                                                            onCostClick={(
                                                                costid: string
                                                            ) => {
                                                                this.props.history.push(
                                                                    `/car/${carid}/history-file/${entryid}/cost/edit?cost_id=${costid}`
                                                                )
                                                            }}
                                                            isEntryAttachmentBeingUploaded={
                                                                isEntryAttachmentLoading
                                                            }
                                                            isCostAttachmentBeingUploaded={
                                                                isCostAttachmentLoading
                                                            }
                                                            costsObj={
                                                                activeEntry.costItemsObj
                                                            }
                                                            userCurrency={
                                                                userCurrency
                                                            }
                                                            toggleAddGalleryImageToEntryForm={
                                                                this.props
                                                                    .toggleAddGalleryImageToEntryForm
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <DeletionConfirmationDesktop
                                                    isOpen={
                                                        this.state
                                                            .showConfirmDeleteEntrySheet
                                                    }
                                                    toggle={() =>
                                                        this.setState({
                                                            ...this.state,
                                                            showConfirmDeleteEntrySheet:
                                                                false,
                                                        })
                                                    }
                                                    action={() => {
                                                        this.props.history.push(
                                                            `/car/${carid}/history-file/entry?entryid=${entryid}`
                                                        )
                                                        activeEntry &&
                                                            deleteTimelineEntry(
                                                                {
                                                                    itemid: activeEntry.id,
                                                                    carid: carid,
                                                                }
                                                            )
                                                    }}
                                                    actionCopy="Confirm deletion"
                                                    titleCopy="Are you sure?"
                                                    detailsCopy="On deletion, the data will be lost."
                                                />
                                            </Faded>
                                        </FullPagePageWrapper>
                                        {this.props
                                            .isAddGalleryImageToEntryFormOpen && (
                                            <Faded>
                                                <ModalDisplayAbsolute
                                                    isOpen={
                                                        this.props
                                                            .isAddGalleryImageToEntryFormOpen
                                                    }
                                                    toggle={() => {}}
                                                >
                                                    <WrapperImageFormsDesktop
                                                        close={() =>
                                                            this.props.toggleAddGalleryImageToEntryForm(
                                                                {
                                                                    isOpen: false,
                                                                }
                                                            )
                                                        }
                                                        carid={carid}
                                                        entryid={activeEntry.id}
                                                    />
                                                </ModalDisplayAbsolute>
                                            </Faded>
                                        )}
                                    </DesktopDisplayOnly>

                                    <IpadAndMobileDisplay>
                                        <CenteredPageWrapper
                                            fullwidth
                                            removePaddingsOnAllDevices
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <TimelineItemReturnNav
                                                    chevron={true}
                                                    customText={`Entries`}
                                                    linkTo={`/car/${carid}/history-file`}
                                                    linkSearch={`?scrolltoid=${entryid}`}
                                                    threeDots={true}
                                                    onThreeDotsClick={
                                                        handleActionSubMenu
                                                    }
                                                    dataCyIdThreeDots={
                                                        'open-entry-slide-up-menu'
                                                    }
                                                    dataCyIdChevron={
                                                        'go-back-to-timeline'
                                                    }
                                                    category={
                                                        activeEntry.categoryID
                                                    }
                                                />

                                                <SideSlider
                                                    direction="right"
                                                    initial="enter"
                                                >
                                                    <SingleTimelineItemBodyMobile
                                                        item={activeEntry}
                                                        carMileage={carMileage}
                                                        userDistanceUnit={
                                                            userDistanceUnit
                                                        }
                                                        attachmentsObj={
                                                            attachmentsObj
                                                        }
                                                        onMileageEdit={() => {
                                                            this.props.history.push(
                                                                `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=mileage`
                                                            )
                                                        }}
                                                        // costsObj={costsObj}
                                                        onCreateCostClick={() => {
                                                            this.props.history.push(
                                                                `/car/${carid}/history-file/${entryid}/cost/create`
                                                            )
                                                        }}
                                                        setFileUploaded={() => {}}
                                                        entityType="entry"
                                                        carID={carid}
                                                        entityID={
                                                            activeEntry.id
                                                        }
                                                        onAddNewLabelClick={() => {
                                                            this.props.history.push(
                                                                `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=labels`
                                                            )
                                                        }}
                                                        onRemoveLabel={(
                                                            labelname: string
                                                        ) =>
                                                            removeLabel(
                                                                labelname
                                                            )
                                                        }
                                                        onEditNotes={() => {
                                                            this.props.history.push(
                                                                `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=notes`
                                                            )
                                                        }}
                                                        onEditInsurer={() => {
                                                            this.props.history.push(
                                                                `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=insurance_fields`
                                                            )
                                                        }}
                                                        onCostClick={(
                                                            costid: string
                                                        ) => {
                                                            this.props.history.push(
                                                                `/car/${carid}/history-file/${entryid}/cost/edit?cost_id=${costid}`
                                                            )
                                                        }}
                                                        isEntryAttachmentBeingUploaded={
                                                            isEntryAttachmentLoading
                                                        }
                                                        isCostAttachmentBeingUploaded={
                                                            isCostAttachmentLoading
                                                        }
                                                        costsObj={
                                                            activeEntry.costItemsObj
                                                        }
                                                        userCurrency={
                                                            userCurrency
                                                        }
                                                    />
                                                </SideSlider>

                                                {/* opens a bottomSheet menu with options to edit general entry details or delete entry */}
                                                {this.state
                                                    .isMobileActionsSubMenuOpen && (
                                                    <PageSubMenuActions
                                                        items={[
                                                            {
                                                                id: 'edit',
                                                                text: 'edit entry details',
                                                                icon: (
                                                                    <PenIcon
                                                                        color={
                                                                            colours[
                                                                                theme
                                                                            ]
                                                                                .text_default
                                                                        }
                                                                    />
                                                                ),
                                                                action: () => {
                                                                    this.props.history.push(
                                                                        `/car/${carid}/history-file/entry/edit?entry_id=${entryid}&section_id=general`
                                                                    )
                                                                },
                                                            },

                                                            {
                                                                id: 'add_cost',
                                                                text: 'add cost',
                                                                icon: (
                                                                    <CostIcon />
                                                                ),
                                                                action: () => {
                                                                    this.props.history.push(
                                                                        `/car/${carid}/history-file/${entryid}/cost/create`
                                                                    )
                                                                },
                                                            },
                                                            {
                                                                id: 'delete',
                                                                text: 'delete history file entry',
                                                                icon: (
                                                                    <BinIcon color="#DF6F6F" />
                                                                ),
                                                                action: () => {
                                                                    this.setState(
                                                                        {
                                                                            ...this
                                                                                .state,
                                                                            showConfirmDeleteEntrySheet:
                                                                                true,
                                                                        }
                                                                    )
                                                                },
                                                                color: '#DF6F6F',
                                                            },
                                                        ]}
                                                        customMenuItem={
                                                            <EntryAttachmentsFormWrapperMobile
                                                                entityID={
                                                                    activeEntry.id
                                                                }
                                                                carid={carid}
                                                                isBottomsheet
                                                                closeBottomSheet={() =>
                                                                    setTimeout(
                                                                        handleActionSubMenu,
                                                                        this
                                                                            .state
                                                                            .bottomsheetTimer
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        customMenuItemIndex={1}
                                                        toggle={() =>
                                                            setTimeout(
                                                                handleActionSubMenu,
                                                                this.state
                                                                    .bottomsheetTimer
                                                            )
                                                        }
                                                        isOpen={
                                                            this.state
                                                                .isMobileActionsSubMenuOpen
                                                        }
                                                        dataCyPrefix="timeline-entry"
                                                    />
                                                )}

                                                <DeletionConfirmationBottomSheet
                                                    isOpen={
                                                        this.state
                                                            .showConfirmDeleteEntrySheet
                                                    }
                                                    toggle={() =>
                                                        this.setState({
                                                            ...this.state,
                                                            showConfirmDeleteEntrySheet:
                                                                false,
                                                        })
                                                    }
                                                    action={() =>
                                                        activeEntry
                                                            ? deleteTimelineEntry(
                                                                  {
                                                                      itemid: activeEntry.id,
                                                                      carid: carid,
                                                                  }
                                                              )
                                                            : {}
                                                    }
                                                    actionCopy="Confirm deletion"
                                                    titleCopy="Are you sure?"
                                                    detailsCopy="On deletion, the data will be lost."
                                                />
                                            </div>{' '}
                                        </CenteredPageWrapper>
                                    </IpadAndMobileDisplay>
                                </>
                            )}
                        </Faded>
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarTimelineSingleEntry)
)
