import styled from 'styled-components'

import { withFormik, FormikProps } from 'formik'
// import TouchScrollable from 'react-scrolllock'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import { motion } from 'framer-motion'

import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'

import '../carGalleryEditForm/antd.css'
import {
    IPresignedUrlEntryImagesArgs,
    IUploadAndCreateEntryGalleryImageArgs,
    IUploadGalleryImagesPreSignedUrlsPayload,
} from 'myModels'
import {
    getPresignedUrlEntryGalleryImagesRequest,
    submitEntryGalleryImagesFilestorageRequest,
} from '../../../../redux/filestorage/actions/dispatched/loadingActions'
import { message } from 'antd'
import { ModalTopBar } from '../../../molecules/technicalInformation/modal'
import { device } from '../../../templates/displays/devices'
import useThemes from '../../../../providers/theme/hooks'

var ImagePickerFromAntd = require('antd-mobile/lib/image-picker')

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-strong, #1a1a1a);
    border-radius: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 5px;

    @media screen and (max-width: 325px) {
        max-height: 90vh;
    }

    max-height: 95vh;

    @media screen and (min-width: 330px) {
        min-height: 358px;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 15px;
    padding-bottom: 10px;
`

// const FormTitle = styled.div`
//     font-family: Lato;
//     align-self: start;
//     font-size: 16px;
//     display: flex;
//     flex-direction: column;
//     align-items: start;
//     justify-content: flex-start;

//     @media screen and (min-width: 330px) {
//         font-size: 18px;
//     }
// `

const SmallPadding = styled.div`
    padding-top: 10px;

    @media screen and (max-width: 330px) {
        padding-top: 5px;
    }
`

const SmallPaddingBetweenFields = styled.div`
    padding-top: 5px;
`

const BiggerPadding = styled.div`
    padding-top: 32px;
    @media screen and (max-width: 330px) {
        padding-top: 15px;
    }
`
const LargePadding = styled.div`
    padding-top: 40px;

    @media screen and (max-width: 330px) {
        padding-top: 30px;
    }
`

const TextAreaWidth = styled.div`
    width: 80vw;
    min-height: 70px;
    margin-top: 10px;
`

const ProgressBarImagesMobileChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: var(--primary, #5ec3ca);
    border-radius: 5px;
`

const CaptionText = styled.textarea`
    font-family: Lato;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    width: 80vw;

    min-height: 70px;
    font-size: 14px;
    border: none;
    padding-top: 20px;
    border-radius: 5px;
    color: var(--text-strong, #1a1a1a);
    border: var(--text-muted) 1px solid;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    font-size: 0.9em;

    :focus {
        outline: 0;
    }
`
export const PhotoPercentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-bottom: 20px;
    padding-bottom: 20px;
    font-size: 15px;

    font-family: Lato-light;
    padding-top: 5px;
    padding-bottom: 10px;
    color: var(--text-darker, #616161);
`

const ProgressBarImagesMobileParentContainer = styled.div`
    border: 1px solid var(--primary, #5ec3ca);
    display: flex;
    position: relative;
    height: 6px;
    width: 78vw;
    border-radius: 5px;
`

export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
`

export const CharacterCountSpan = styled.span`
    color: var(--text-strong, #1a1a1a);
    font-family: lato-light;
    font-size: 11px;
    padding-left: 10px;
`

const ImagePickerStyled = styled(ImagePickerFromAntd)`
    height: 100%;
    width: 100%;
    display: flex;
`

const ImageInstructions = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 13px;
    padding-top: 10px;
    font-family: Lato-Light;
    @media screen and (min-width: 330px) {
        font-size: 14px;
    }
`

const BtnWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    padding-top: 16px;
    padding-bottom: 32px;
    padding-right: 20px;
`

type IStyledWarning = {
    hasError?: boolean
}
const ImageInstructionsWarning = styled.div<IStyledWarning>`
    color: ${(props) =>
        props.hasError
            ? 'var(--error, #df6f6f)'
            : 'var(--text-strong, #1a1a1a)'};
    font-size: 13px;
    padding-top: 2px;
    padding-bottom: 5px;
    font-family: Lato-Light;

    @media screen and (min-width: 330px) {
        font-size: 14px;
        padding-bottom: 24px;
    }
`

const dispatchProps = {
    getPresignedUrlEntryGalleryImages: (p: IPresignedUrlEntryImagesArgs) =>
        getPresignedUrlEntryGalleryImagesRequest(p),
    submitEntryGalleryImagesFilestorage: (
        p: IUploadAndCreateEntryGalleryImageArgs
    ) => submitEntryGalleryImagesFilestorageRequest(p),
}

function mapStateToProps(state: RootState) {
    return { gallerypresignedurls: state.fileStorage.gallerypresignedurls }
}
interface Values {
    caption: string
    location: string
    credits: string
    file_url?: string
    files?: File[]
    morethanmax?: boolean
}

interface OtherProps extends RouteComponentProps<any> {
    close: any
    setImageUploaded?: any
    currentImagesUploaded?: any
    getPresignedUrlEntryGalleryImages?: (
        p: IPresignedUrlEntryImagesArgs
    ) => void
    carid: string
    entryid: string
    submitEntryGalleryImagesFilestorage: (
        p: IUploadAndCreateEntryGalleryImageArgs
    ) => void
    gallerypresignedurls?: IUploadGalleryImagesPreSignedUrlsPayload
    progressArray?: any
    isSomethingBeingUploaded?: boolean
    progressState?: any
    isLastUploaded?: boolean
    count?: number
}

type ProgressProps = {
    progressPercent: number
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        setImageUploaded,
        setFieldValue,
        handleChange,
        currentImagesUploaded,
        count,
        getPresignedUrlEntryGalleryImages,
        progressState,
        errors,
        isLastUploaded,
    } = props

    const handleFilesGiven = (files: File[]) => {
        let count = files.length

        if (count <= 20) {
            setFieldValue('morethanmax', false)
            let file_names = files.map((file) => file.name)

            count > 0 &&
                getPresignedUrlEntryGalleryImages &&
                getPresignedUrlEntryGalleryImages({
                    entryid: props.entryid,
                    carid: props.carid,
                    count: count,
                    file_names: file_names,
                })

            setFieldValue('files', files)

            return setImageUploaded(files)
        } else return setFieldValue('morethanmax', true)
    }

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 3,
        })
    }

    const errorFileTypeWarning = (filename: string, message_text: string) => {
        message.error({
            content: `${filename} is not supported, ${message_text}`,
            duration: 2,
        })
    }

    const handleNewUpload = (info: any) => {
        let newFilesList: File[] = []
        info.forEach((f: any, index: number) => {
            let file: File = f.file
            if (file.type === 'image/png' || file.type === 'image/jpeg') {
                if (file.size <= 20000000 && file.size > 1) {
                    return newFilesList.push(file)
                } else return errorFileSizeWarning()
            } else {
                errorFileTypeWarning(
                    file.name,
                    'please upload png or jpeg images.'
                )
            }
        })

        return handleFilesGiven(newFilesList)
    }

    const { theme } = useThemes()

    return (
        <EditFormContainerMobile id="scrollable-content">
            <ModalTopBar $theme={theme}>
                {' '}
                Add images to your history file entry
            </ModalTopBar>
            <InnerFormPadding>
                {!progressState && (
                    <>
                        <div style={{ paddingTop: '5px' }} />
                        <ImageInstructions>
                            Image formats accepted: png, jpg, jpeg, HEIC and
                            HEIF.
                            <br />
                            Maximum file size: 20MB.
                        </ImageInstructions>
                        <ImageInstructionsWarning
                            hasError={values.morethanmax ? true : false}
                        >
                            Please upload up to 20 files.
                        </ImageInstructionsWarning>

                        <div style={{ paddingTop: '30px' }} />

                        <motion.div whileTap={{ scale: 1.05 }}>
                            <ImagePickerStyled
                                multiple={true}
                                onChange={(info: any) => {
                                    handleNewUpload(info)
                                }}
                                files={[]}
                                selectable={
                                    true
                                    // currentImagesUploaded < 10
                                }
                                // selectable={currentImagesUploaded.length <= 10}
                                // showUploadList={false}
                                accept="image/*"
                            />
                        </motion.div>
                    </>
                )}

                {progressState && currentImagesUploaded && (
                    <PhotoPercentWrapper>
                        <SmallPadding />
                        <SmallPadding />
                        {isLastUploaded ? (
                            <div
                                style={{
                                    paddingBottom: '5px',
                                }}
                            >
                                <span
                                    style={{
                                        paddingRight: '3px',
                                    }}
                                >
                                    {currentImagesUploaded.length}
                                </span>
                                file
                                {currentImagesUploaded.length > 1
                                    ? `s`
                                    : ''}{' '}
                                successfully uploaded (
                                {Math.floor(progressState.total)}% )
                            </div>
                        ) : (
                            <div>
                                uploading {count} /{' '}
                                {currentImagesUploaded.length} file
                                {currentImagesUploaded.length > 1
                                    ? `s`
                                    : ''}{' '}
                                {Math.floor(progressState.total)}%...
                            </div>
                        )}

                        <ProgressBarImagesMobileParentContainer>
                            <ProgressBarImagesMobileChild
                                progressPercent={progressState.total}
                            />
                        </ProgressBarImagesMobileParentContainer>
                    </PhotoPercentWrapper>
                )}
                {/* {showFiles} */}

                <Expander height={progressState ? 'auto' : 0}>
                    <SmallPadding />
                    <FieldTitle style={{ paddingBottom: 0 }}>
                        Caption
                        <CharacterCountSpan>
                            [up to 265 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.caption.length > 1 && errors.caption
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {errors.caption}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPadding />
                    <TextAreaWidth id="scrollable-content">
                        {/* <TouchScrollable> */}
                        <CaptionText
                            id="caption"
                            name="caption"
                            placeholder="ie. Doing donuts in my Fiat Panda 4x4 in the snow"
                            aria-live="polite"
                            onChange={handleChange}
                            value={values.caption}
                        />
                        {/* </TouchScrollable> */}
                    </TextAreaWidth>
                    <SmallPadding />
                    <SmallPadding />
                    <FieldTitle>
                        Location
                        <CharacterCountSpan>
                            [up to 35 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.location.length > 1 && errors.location
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {errors.location}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPaddingBetweenFields />
                    <InputField
                        theme={theme}
                        id="location"
                        name="location"
                        placeholder="Where was this taken?"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.location}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <BiggerPadding />
                    <FieldTitle>
                        Credits
                        <CharacterCountSpan>
                            [up to 35 characters]
                        </CharacterCountSpan>
                    </FieldTitle>
                    <Expander
                        height={
                            values.credits.length > 1 && errors.credits
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {errors.credits}
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPaddingBetweenFields />
                    <InputField
                        theme={theme}
                        id="credits"
                        name="credits"
                        placeholder="Name of photographer (if applicable)"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.credits}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <SmallPadding />
                    <SmallPadding />
                </Expander>

                {isLastUploaded ? (
                    <LargePadding />
                ) : (
                    <div style={{ paddingTop: '60px' }} />
                )}

                <BtnWrap>
                    <ButtonsStandardBottomRowMobile
                        cancel={close}
                        submit={props.handleSubmit}
                        confirmationtext={'upload'}
                        disabled={
                            currentImagesUploaded &&
                            currentImagesUploaded.length < 1 &&
                            currentImagesUploaded[0] === undefined
                                ? true
                                : !isLastUploaded
                                ? true
                                : (values.caption && errors.caption) ||
                                  (values.location && errors.caption) ||
                                  (values.credits && errors.credits)
                                ? true
                                : false
                        }
                    />
                </BtnWrap>
            </InnerFormPadding>
        </EditFormContainerMobile>
    )
}

const HistoryFileGalleryEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        caption: '',
        location: '',
        credits: '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                caption: Yup.string().max(
                    266,
                    'Caption must be be less than 265 characters.'
                ),
                location: Yup.string().max(
                    36,
                    'Location must be be less than 35 characters.'
                ),

                credits: Yup.string().max(
                    36,
                    'Credits must be be less than 35 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let files = props.currentImagesUploaded

        let gallerypresignedurls = props.gallerypresignedurls

        if (files !== undefined && gallerypresignedurls) {
            props.submitEntryGalleryImagesFilestorage({
                carid: props.carid,
                entryid: props.entryid,
                gallerypresignedurls: gallerypresignedurls,
                files: files,
                fields: {
                    caption: values.caption,
                    credit: values.credits,
                    location: values.location,
                },
                isFromQuickActions: false,
            })

            props.close()

            // here req thunks to upload to S3
        }
        setSubmitting(false)
    },
})(InnerForm)

export default withRouter(
    connect(mapStateToProps, dispatchProps)(HistoryFileGalleryEditFormMobile)
)
