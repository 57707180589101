import { IJourneySectionID } from 'journeyInterfaces'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import { device } from '../displays/devices'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../atoms/icons/components/chevronDown'
import FormStepProgress from '../../atoms/FormStepProgress/FormStepProgress'

type IStyle = {
    $theme: ITheme
}

const LeftSide = styled.section<IStyle>`
    width: 40%;
    max-width: 600px;
    height: 100%;
    background-color: ${(props) => colours[props.$theme].background_default};
    padding: 48px 80px 80px 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex-shrink: 0;
    overflow-y: auto;

    @media ${device.smallest_laptops} {
        padding: 48px;
    }
`

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`
const Title = styled.h1<IStyle>`
    padding: 0px;
    margin: 0px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-semibold;
    font-size: 24px;
    line-height: 40px;

    @media ${device.mobile_and_ipad} {
        font-size: 22px;
        line-height: 32px;
        width: 100%;
    }
`

const BtnContent = styled.div<IStyle>`
    display: flex;
    gap: 8px;
    height: 16px;
    justify-content: center;
    align-items: center;
    color: ${(props) => colours[props.$theme].text_darker};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px;
`

export default function JourneyWrapperLeftSideDesktop({
    title,
    onBack,
    currentStep,
    totalSteps,
    sectionID,
    children,
    maxTitleWith,
}: {
    title: string
    onBack: () => void
    currentStep: number
    totalSteps: number
    sectionID: IJourneySectionID
    children: React.ReactNode
    maxTitleWith?: string
}) {
    const { theme } = useThemes()
    return (
        <LeftSide $theme={theme}>
            <FormStepProgress
                screen_name="desktop"
                currentStep={currentStep}
                totalSteps={totalSteps}
                activeColour={
                    journey_colours[theme].section[sectionID].primary_500
                }
                passiveColour={
                    journey_colours[theme].section[sectionID].primary_100
                }
            />
            <ColumnContent style={{ gap: 24 }}>
                <ColumnContent style={{ gap: 16 }}>
                    <StandardCtaBtn
                        bg="transparent"
                        customWidth="auto"
                        customHeight="auto"
                        removePaddings
                        removeShadowOnHover
                        onClick={onBack}
                    >
                        <BtnContent $theme={theme}>
                            <div
                                style={{
                                    transform: 'rotate(90deg)',
                                }}
                            >
                                <ChevronDown />
                            </div>
                            Back
                        </BtnContent>
                    </StandardCtaBtn>
                    <Title
                        $theme={theme}
                        style={{ maxWidth: maxTitleWith ?? 'auto' }}
                    >
                        {title}
                    </Title>
                </ColumnContent>
            </ColumnContent>
            {children}
        </LeftSide>
    )
}
