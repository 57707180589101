import AttachmentsIcon from './attachments'
import CategoryIcons from './categories'
import CompaniesIcon from './companies'
import CurrencyIcons from './currency'
import MileageIcon from './mileage'

const HistoryFileIcons = {
    CategoryIcons,
    CompaniesIcon,
    AttachmentsIcon,
    CurrencyIcons,
    MileageIcon,
}

export default HistoryFileIcons
