import { createAction } from '@reduxjs/toolkit'
import {
    IAddGalleryImagesIdsToEntry,
    IDeleteEntryGalleryImagePayload,
} from 'entityModels'
import {
    IActionPayloadTimelineCreationSuccess,
    IAddCostItemPayloadSuccess,
    IAddTimelineItemPayloadSuccess,
    ICostItem,
    IDeleteCostItemPayloadSuccess,
    IDeleteTimelineItemPayloadSuccess,
    IFilterTimelineByCategoryNLabelCarIDSuccess,
    IGetAllTimelineItemsByCarIDPayloadSuccess,
    IGetAllUserEntryLabelsSuccess,
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    ITimelineItem,
    IUpdateCostItemPayloadSuccess,
    IUpdateTimelineItemPayloadSuccess,
    IUpdateEntryLabelPayload,
    IAddTimelineItemsWithCostPayloadSuccess,
} from 'timelineModels'

import {
    GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_SUCCESS,
    FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_SUCCESS,
    SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_SUCCESS,
    ADD_TIMELINE_ITEM_TO_CAR_SUCCESS,
    UPDATE_TIMELINE_ITEM_TO_CAR_SUCCESS,
    DELETE_TIMELINE_ITEM_TO_CAR_SUCCESS,
    MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_SUCCESS,
    ADD_COST_TO_TIMELINE_ITEM_SUCCESS,
    UPDATE_TIMELINE_ITEM_COST_SUCCESS,
    DELETE_TIMELINE_ITEM_COST_SUCCESS,
    GET_TIMELINE_ITEM_BY_ID_SUCCESS,
    GET_COST_ENTRY_BY_ID_SUCCESS,
    GET_ALL_USER_CAR_ENTRY_LABELS_SUCCESS,
    SET_ACTIVE_ENTRY_SUCCESS,
    CLEAR_DATA_TO_BE_SUBMITTED_SUCCESS,
    ADD_LABEL_TO_CAR_ENTRY_LABELS_SUCCESS,
    REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_SUCCESS,
    SET_ACTIVE_ENTRY_COST_SUCCESS,
    ADD_ATTACHMENT_TO_ENTRY_SUCCESS,
    ADD_ATTACHMENT_TO_COST_SUCCESS,
    DELETE_ENTRY_ATTACHMENT_SUCCESS,
    DELETE_COST_ATTACHMENT_SUCCESS,
    ADD_GALLERY_IMAGES_IDS_TO_ENTRY_SUCCESS,
    DELETE_GALLERY_IMAGES_ID_FROM_ENTRY_SUCCESS,
    MANAGE_ADD_ATTACHMENTS_TO_ENTRY_SUCCESS,
    CLEAN_UP_TIMELINE_DATA_ON_LOGOUT,
    ADD_TIMELINE_ENTRY_WITH_COST_SUCCESS,
    RESET_TIMELINE_SUCCESS,
    UPDATE_TIMELINE_ENTRY_WITH_COST_SUCCESS,
} from './constants'

export const getAllTimelineItemsByCarIDSuccess = createAction(
    GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_SUCCESS,
    function prepare(payload: IGetAllTimelineItemsByCarIDPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const getTimelineItemByIDSuccess = createAction(
    GET_TIMELINE_ITEM_BY_ID_SUCCESS,
    function prepare(payload: ITimelineItem) {
        return {
            payload,
        }
    }
)

export const filterTimelineItemsByCarCategoryIdsNLabelIdsSuccess = createAction(
    FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_SUCCESS,
    function prepare(payload: IFilterTimelineByCategoryNLabelCarIDSuccess) {
        return {
            payload,
        }
    }
)

export const setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNEntryTitleQSuccess =
    createAction(
        SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_SUCCESS,
        function prepare(
            payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
        ) {
            return {
                payload,
            }
        }
    )

export const addTimelineItemToCarSuccess = createAction(
    ADD_TIMELINE_ITEM_TO_CAR_SUCCESS,
    function prepare(payload: IAddTimelineItemPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const setActiveEntrySuccess = createAction(
    SET_ACTIVE_ENTRY_SUCCESS,
    function prepare(payload: ITimelineItem | undefined) {
        return {
            payload,
        }
    }
)

export const updateTimelineEntrySuccess = createAction(
    UPDATE_TIMELINE_ITEM_TO_CAR_SUCCESS,
    function prepare(payload: IUpdateTimelineItemPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const deleteTimelineEntrySuccess = createAction(
    DELETE_TIMELINE_ITEM_TO_CAR_SUCCESS,
    function prepare(payload: IDeleteTimelineItemPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const getCostEntryByIdSuccess = createAction(
    GET_COST_ENTRY_BY_ID_SUCCESS,
    function prepare(payload: ICostItem) {
        return {
            payload,
        }
    }
)

export const addCostToTimelineItemSuccess = createAction(
    ADD_COST_TO_TIMELINE_ITEM_SUCCESS,
    function prepare(payload: IAddCostItemPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const updateTimelineItemCostSuccess = createAction(
    UPDATE_TIMELINE_ITEM_COST_SUCCESS,
    function prepare(payload: IUpdateCostItemPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const deleteTimelineItemCostSuccess = createAction(
    DELETE_TIMELINE_ITEM_COST_SUCCESS,
    function prepare(payload: IDeleteCostItemPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const setActiveCostSuccess = createAction(
    SET_ACTIVE_ENTRY_COST_SUCCESS,
    function prepare(payload: ICostItem | undefined) {
        return {
            payload,
        }
    }
)

export const manageDataToBeSubmittedTimelineCreationSuccess = createAction(
    MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_SUCCESS,
    function prepare(payload: IActionPayloadTimelineCreationSuccess) {
        return {
            payload,
        }
    }
)

export const getAllUserCarEntryLabelsSuccess = createAction(
    GET_ALL_USER_CAR_ENTRY_LABELS_SUCCESS,
    function prepare(payload: IGetAllUserEntryLabelsSuccess) {
        return {
            payload,
        }
    }
)

export const clearDataToBeSubmittedSuccess = createAction(
    CLEAR_DATA_TO_BE_SUBMITTED_SUCCESS,
    function prepare(payload: boolean) {
        return {
            payload,
        }
    }
)

export const addLabelToCarEntrySuccess = createAction(
    ADD_LABEL_TO_CAR_ENTRY_LABELS_SUCCESS,
    function prepare(payload: IUpdateEntryLabelPayload) {
        return {
            payload,
        }
    }
)

export const removeLabelFromCarEntrySuccess = createAction(
    REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_SUCCESS,
    function prepare(payload: IUpdateEntryLabelPayload) {
        return {
            payload,
        }
    }
)

export const addAttachmentToEntrySuccess = createAction(
    ADD_ATTACHMENT_TO_ENTRY_SUCCESS,
    function prepare(payload: string[]) {
        return {
            payload,
        }
    }
)

export const addAttachmentToCostSuccess = createAction(
    ADD_ATTACHMENT_TO_COST_SUCCESS,
    function prepare(payload: string[]) {
        return {
            payload,
        }
    }
)

export const deleteEntryAttachmentSuccess = createAction(
    DELETE_ENTRY_ATTACHMENT_SUCCESS,
    function prepare(payload: string[]) {
        return {
            payload,
        }
    }
)

export const deleteCostAttachmentSuccess = createAction(
    DELETE_COST_ATTACHMENT_SUCCESS
)

export const addGalleryImagesIdsToEntrySuccess = createAction(
    ADD_GALLERY_IMAGES_IDS_TO_ENTRY_SUCCESS,
    function prepare(payload: IAddGalleryImagesIdsToEntry) {
        return {
            payload,
        }
    }
)

export const deleteGalleryImagesIdFromEntrySuccess = createAction(
    DELETE_GALLERY_IMAGES_ID_FROM_ENTRY_SUCCESS,
    function prepare(payload: IDeleteEntryGalleryImagePayload) {
        return {
            payload,
        }
    }
)

export const manageAddAttachmentsToEntrySuccess = createAction(
    MANAGE_ADD_ATTACHMENTS_TO_ENTRY_SUCCESS
)

export const cleanUpTimelineDataOnLogoutSuccess = createAction(
    CLEAN_UP_TIMELINE_DATA_ON_LOGOUT
)

export const addTimelineItemWithCostToCarSuccess = createAction(
    ADD_TIMELINE_ENTRY_WITH_COST_SUCCESS,
    function prepare(payload: IAddTimelineItemsWithCostPayloadSuccess) {
        return {
            payload,
        }
    }
)

export const updateTimelineItemWithCostSuccess = createAction(
    UPDATE_TIMELINE_ENTRY_WITH_COST_SUCCESS
)

export const resetTimelineItems = createAction(
    RESET_TIMELINE_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)
