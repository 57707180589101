// NOTE
// DO NOT USE dynamic string operations(like template string) as action type property.
// see more details: https://github.com/piotrwitek/typesafe-actions#--the-actions
// answer a question
export const ANSWER_ONBOARDING_QA_REQUEST = "@@onboardingqa/REQUEST";
export const ANSWER_ONBOARDING_QA_SUCCESS = "@@onboardingqa/SUCCESS";
export const ANSWER_ONBOARDING_QA_ERROR = "@@onboardingqa/ERROR";
// set primary user type and make the consequent changes ( allocate user PagesArray)
export const SET_PRIMARY_ONBOARDING_USER_TYPE_REQUEST =
  "@@set_onboardingqa/REQUEST";
export const SET_PRIMARY_ONBOARDING_USER_TYPE__SUCCESS =
  "@@set_onboardingqa/SUCCESS";
export const SET_PRIMARY_ONBOARDING_USER_TYPE__ERROR =
  "@@set_onboardingqa/ERROR";
// set secondary user type and make the consequent changes ( allocate user PagesArray)
export const SET_SECONDARY_ONBOARDING_USER_TYPE_REQUEST =
  "@@set_onboardingqa/REQUEST";
export const SET_SECONDARY_ONBOARDING_USER_TYPE__SUCCESS =
  "@@set_onboardingqa/SUCCESS";
export const SET_SECONDARY_ONBOARDING_USER_TYPE__ERROR =
  "@@set_onboardingqa/ERROR";
