import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const UserPlainIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.25 20.875C2.25 17.3091 5.31812 14.5 9 14.5H15C18.6819 14.5 21.75 17.3091 21.75 20.875V22.3705L21.2155 22.5309L21 21.8125C21.2155 22.5309 21.2157 22.5308 21.2155 22.5309L21.2135 22.5315L21.2103 22.5324L21.2005 22.5353L21.1672 22.5448C21.139 22.5528 21.0985 22.564 21.046 22.5779C20.941 22.6056 20.7877 22.6443 20.5869 22.6902C20.1853 22.7819 19.5936 22.9025 18.8177 23.0224C17.2659 23.2624 14.9775 23.5 12 23.5C9.02248 23.5 6.73414 23.2624 5.18226 23.0224C4.40636 22.9025 3.81466 22.7819 3.41306 22.6902C3.21226 22.6443 3.05899 22.6056 2.95398 22.5779C2.90148 22.564 2.86104 22.5528 2.83276 22.5448L2.79947 22.5353L2.78969 22.5324L2.78652 22.5315L2.78536 22.5311C2.78515 22.5311 2.78449 22.5309 3 21.8125L2.78536 22.5311L2.25 22.3705V20.875ZM3.75 21.2285C4.11795 21.3125 4.67371 21.426 5.41149 21.5401C6.89086 21.7688 9.10252 22 12 22C14.8975 22 17.1091 21.7688 18.5885 21.5401C19.3263 21.426 19.882 21.3125 20.25 21.2285V20.875C20.25 18.2272 17.9461 16 15 16H9C6.05388 16 3.75 18.2272 3.75 20.875V21.2285Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2727 3.25C10.0095 3.25 8.25 4.97077 8.25 7.00165C8.25 9.03184 10.0088 10.75 12.2727 10.75C14.5367 10.75 16.2955 9.03184 16.2955 7.00165C16.2955 4.97077 14.5359 3.25 12.2727 3.25ZM6.75 7.00165C6.75 4.06091 9.26494 1.75 12.2727 1.75C15.2805 1.75 17.7955 4.06091 17.7955 7.00165C17.7955 9.94308 15.2798 12.25 12.2727 12.25C9.26567 12.25 6.75 9.94308 6.75 7.00165Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default UserPlainIcon
