import { IApiEntryCostGET_args } from 'ApiInterfaces'
import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'

export const getEntryCostById = (p: IApiEntryCostGET_args): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.get_entry_cost_by_uid_endpoint(p), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    apiSentryErrorHandler(e, 'Get cost by id  error')
                    return Promise.reject(e)
                })
        }
    )
}
