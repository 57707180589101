import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../../services'

import * as actions from '../actions'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    ICarInsightsResAPI_category,
    ICarInsightsResAPI_category_item,
    ICustomErrorData,
    IReduxError,
} from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import {
    IgetCarCategoryInsightsRequest,
    IgetCarInsightsRequest,
} from '../actions/loadingActions'
import { ICarInsightsResAPI } from '../../../../components/organisms/carInsights/body'

export function* CreateCarInsightsSaga(p: IgetCarInsightsRequest) {
    try {
        let data: ICarInsightsResAPI = yield call(
            api.carInsights.getCarInsightsAPI,
            p
        )

        if (data) {
            yield put(
                actions.actions.getCarInsightsSuccess({
                    carid: p.carid,
                    timeRange: p.timeRange,
                    data: data,
                })
            )
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateCarInsightsSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/garage`,
            }
            let typedError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(actions.errorActions.getCarInsightsError(typedError))
            return
        }
    }
}

function* WatcherCarInsights() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getCarInsightsRequest
        )
        yield call(CreateCarInsightsSaga, payload)
    }
}

type Accumulator = {
    [year: number]: {
        year: number
        month?: string
        amount: number
        currency: string
    }
}

const monthToNumber: { [key: string]: number } = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
}

export function* CreateCategoryInsightsSaga(p: IgetCarCategoryInsightsRequest) {
    try {
        let data: ICarInsightsResAPI_category = yield call(
            api.carInsights.getCarCategoryInsightsAPI,
            p
        )

        if (p.categoryID === 'all') {
            let all_categories_put_under_all: ICarInsightsResAPI_category_item[] =
                []

            if (data?.time_series) {
                let keyss = Object.keys(data.time_series)
                for (let i = 0; i < keyss.length; i++) {
                    all_categories_put_under_all = [
                        ...all_categories_put_under_all,
                        ...data.time_series[keyss[i]],
                    ]
                }
            }

            let arrr: ICarInsightsResAPI_category_item[] = Object.values(
                all_categories_put_under_all.reduce<Accumulator>(
                    (acc, { year, month, amount, currency }) => {
                        const keyee: string | number =
                            p.representation === 'yearly' && year
                                ? year
                                : `${year}-${month}`
                        // @ts-ignore
                        if (!acc[keyee]) {
                            // @ts-ignore
                            acc[keyee] = { year, month, amount, currency }
                        } else {
                            // @ts-ignore
                            acc[keyee].amount += amount
                        }
                        return acc
                    },
                    {}
                )
            )

            // Sort the result array by year and month
            arrr.sort((a, b) => {
                if (p.representation === 'monthly' && a.month && b.month) {
                    return monthToNumber[a.month] - monthToNumber[b.month]
                } else {
                    if (a.year && b.year) {
                        return a.year - b.year
                    } else {
                        return a.amount - b.amount
                    }
                }
            })

            yield put(
                actions.actions.getCategoryInsightsSuccess({
                    ...p,
                    data: [...arrr],
                })
            )
        } else {
            yield put(
                actions.actions.getCategoryInsightsSuccess({
                    ...p,
                    data: data?.time_series?.[p.categoryID]
                        ? [...data?.time_series?.[p.categoryID]]
                        : [],
                })
            )
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateCategoryInsightsSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/garage`,
            }
            let typedError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(actions.errorActions.getCarInsightsError(typedError))
            return
        }
    }
}

function* WatcherCategoryInsights() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getCarCategoryInsightsRequest
        )
        yield call(CreateCategoryInsightsSaga, payload)
    }
}

const car_insights: any[] = [
    fork(WatcherCarInsights),
    fork(WatcherCategoryInsights),
]

export default car_insights
