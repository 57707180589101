import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { editFormsActions } from '../../../../../../redux/editForms/reducer'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../../../../redux/showroom/types'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import { IThreeDotsActionsItems } from '../../../../../atoms/menu/actionsMenu/actionsMenuDots'
import { IEditShowroomModalOptions } from '../../../../../pages/showroom/car/showroomCarProfileEdit'
import { IPublishValidationObject } from '../carShowroomEditBottomBar'
import ShowroomCarEditBottomBarDesktop from '../showroomCarEditBottomBarDesktop'
import ShowroomCarEditBottomBarMobile from '../showroomCarEditBottomBarMobile'

type Props = {
    role?: string
    handleSetModalOpened: (p: IEditShowroomModalOptions) => any
    showInfo?: boolean
    isPublished: boolean
    infoTxtDesktop?: string
    infoTxtMobile?: string
    isMobileRemoved?: boolean
    entryid: string
    isSold?: boolean
}

const ShowroomValidationSheetManager = (props: Props) => {
    let { handleSetModalOpened, isMobileRemoved } = props

    const history = useHistory()

    let action_items_draft = [
        {
            text: 'Preview',
            actionOnClick: () => {
                history.push(`/showroom/${props.entryid}?is_preview=true`)
            },
        },
        {
            text: 'Remove draft',
            actionOnClick: () => {
                // alert('hey')
                handleSetModalOpened('deletion_action')
            },
        },
    ]

    let action_items_published = [
        {
            text: 'Preview',
            actionOnClick: () => {
                history.push(`/showroom/${props.entryid}?is_preview=true`)
            },
        },
        {
            text: 'Remove Showroom entry',
            actionOnClick: () => {
                handleSetModalOpened('removal_action')
            },
        },
        {
            text: 'Unpublish car from the Showroom',
            actionOnClick: () => {
                handleSetModalOpened('unpublish_action')
            },
        },

        // {
        //     text: 'Mark as sold',
        //     actionOnClick: () => {
        //         handleSetModalOpened('mark_as_sold_action')
        //     },
        // },
    ]

    let action_items_sold = [
        {
            text: 'Preview',
            actionOnClick: () => {
                history.push(`/showroom/${props.entryid}?is_preview=true`)
            },
        },
        {
            text: 'Delete entry',
            actionOnClick: () => {
                handleSetModalOpened('removal_action')
            },
        },
    ]
    let items_to_map: IThreeDotsActionsItems[] = props.isSold
        ? action_items_sold
        : props.isPublished
        ? action_items_published
        : action_items_draft

    const dispatch = useAppDispatch()

    const { entryid } = useParams<any>()

    let userData = useAppSelector((state) => state.user.userLoggedIn)

    let carsData = useAppSelector((state) => state.entities.carsData.cars)
    let showroomData: INormalisedShowroomEntries | undefined = useAppSelector(
        (state) => state.showroom.userShowroomEntries
    )

    let currentEntry: IShowroomEntry | undefined =
        showroomData && entryid && showroomData[entryid]

    let currentCarId = currentEntry && currentEntry.car && currentEntry.car.id

    let keyInfo = useAppSelector(
        (state) => state.entities.highlightedFactsData.highlighted_facts
    )

    let techInfo = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )

    let currentCar =
        carsData && currentCarId && carsData[currentCarId]
            ? carsData[currentCarId]
            : undefined

    let currentCarHighlightedFactsIds = currentCar?.highlighted_facts
    let currentCarHighlightedFacts =
        currentCarHighlightedFactsIds &&
        currentCarHighlightedFactsIds.filter((id) => keyInfo[id].text !== '')

    let hasGeneral =
        currentCar && currentCar.title && currentCar.short_description
            ? true
            : false

    let hasDescription =
        currentCar &&
        currentCar.description &&
        currentCar.description.length > 0 &&
        currentCar.description !== '<p><br></p>'
            ? true
            : false

    let hasImages =
        currentCar &&
        currentCar.gallery &&
        currentCar.gallery.images &&
        currentCar.gallery.images.length >= 6
            ? true
            : false

    let numberOfSelectedImages =
        currentEntry && currentEntry.gallery_images
            ? currentEntry.gallery_images.length
            : 0

    let numberOfKeyMomAndFacts = currentCarHighlightedFacts
        ? currentCarHighlightedFacts.length
        : 0

    let hasBanner = currentCar && currentCar.banner_url ? true : false
    let hasProfileImg =
        currentCar && currentCar.gallery && currentCar.gallery.cover
            ? true
            : false

    // to add check for description image - not sure where we store it
    let hasDescriptionImg =
        currentEntry && currentEntry.featured_image ? true : false

    let hasShowcasePhotos =
        numberOfSelectedImages < 6
            ? false
            : hasBanner === false ||
              hasProfileImg === false ||
              hasDescriptionImg === false
            ? false
            : true

    let hasKeyTechInfo =
        currentCar &&
        currentCar.overview &&
        currentCar.overview.data &&
        Object.values(currentCar.overview.data).filter(
            (item: any) =>
                item.answer && item.answer !== '' && item.answer !== '-'
        ).length === 6
            ? true
            : false

    let hasMileage =
        currentCarId &&
        techInfo[`hf-${currentCarId}`]?.mileage &&
        techInfo[`hf-${currentCarId}`]?.mileage.answer !== undefined &&
        techInfo[`hf-${currentCarId}`]?.mileage.answer !== null &&
        techInfo[`hf-${currentCarId}`]?.mileage.answer !== '' &&
        techInfo[`hf-${currentCarId}`]?.mileage.answer !== '-'
            ? true
            : false

    let hasTechInfo = currentEntry?.undisclosed_mileage
        ? hasKeyTechInfo
        : hasKeyTechInfo && hasMileage
        ? true
        : false

    let hasBasicInfo =
        userData?.address && hasGeneral && currentEntry?.address && hasTechInfo
            ? true
            : false

    let publishValidationObject: IPublishValidationObject = {
        basicInfo: {
            check: hasBasicInfo,
            text: 'Add basic details',
            action: () => {},
            subItems: {
                general: {
                    check: hasGeneral,
                    text: 'General',
                    action: () => {
                        window.innerWidth > 881
                            ? props.handleSetModalOpened('edit_basic_info')
                            : history.push(`/showroom/${entryid}/edit/general`)
                    },
                },
                car_location: {
                    check: currentEntry?.address ? true : false,
                    text: 'Car location',
                    action: () => {
                        props.handleSetModalOpened('car_location')
                    },
                },
                tech_info: {
                    check: hasTechInfo,
                    text: 'Technical information',
                    action: () => {
                        props.handleSetModalOpened('tech_info')
                    },
                },
                dealer_address: {
                    check: currentEntry?.owner?.address ? true : false,
                    text: 'Seller details',
                    action: () => {
                        props.handleSetModalOpened('dealer_address')
                    },
                },
            },
        },

        images: {
            check: hasImages,
            isTooltipRemoved:
                currentCar &&
                currentCar.gallery &&
                currentCar.gallery.images &&
                currentCar.gallery.images.length &&
                currentCar.gallery.images.length >= 6
                    ? true
                    : false,
            text: 'Add photos to car',
            hasNitems:
                currentCar &&
                currentCar.gallery &&
                currentCar.gallery.images &&
                currentCar.gallery.images.length
                    ? currentCar.gallery.images.length
                    : 0,

            minN: 6,
            tooltipText:
                "You must add at least 6 images to your car's gallery.",
            action: () => {
                history.push(`/car/${currentCarId}`)
                dispatch(editFormsActions.toggleCarGalleryEditForm())
            },
        },
        showcasePhotos: {
            check: hasShowcasePhotos,
            text: 'Select Showroom photos',
            action: () => {},
            subItems: {
                profileImg: {
                    check: hasProfileImg,
                    text: 'Profile image',
                    action: () => history.push(`/car/${currentCarId}/gallery`),
                },
                banner: {
                    check: hasBanner,
                    text: 'Banner image',
                    action: () =>
                        dispatch(editFormsActions.toggleCarBannerImageForm()),
                },

                descriptionImg: {
                    check: hasDescriptionImg,
                    text: 'Description image',
                    // will redirect to new page to select description image - issue 4074
                    action: () =>
                        history.push(
                            `/showroom/${entryid}/edit/description-image`
                        ),
                    minN: 1,
                    hasNitems: currentEntry?.featured_image ? 1 : 0,
                    isDisabled: hasImages ? false : true,
                    isTooltipRemoved: hasImages ? true : false,
                    tooltipText: 'You must first add some images to your car.',
                },

                gallery: {
                    check: numberOfSelectedImages >= 6 ? true : false,
                    text: `Gallery images`,
                    action: () =>
                        history.push(`/showroom/${entryid}/edit/gallery`),
                    isDisabled: hasImages ? false : true,
                    minN: 6,
                    hasNitems: numberOfSelectedImages,
                    isTooltipRemoved: false,
                    tooltipText: hasImages
                        ? 'You must select at least 6 images from your car gallery.'
                        : 'You must first add at least 6 images to your car.',
                },
            },
        },
        keyInfoAndFacts: {
            check: numberOfKeyMomAndFacts > 2,
            text: 'Add key moments & facts',
            hasNitems: numberOfKeyMomAndFacts,
            minN: 3,
            tooltipText:
                'You must add at least 3 key moments & facts to your car',
            action: () => {
                if (currentCar && window.innerWidth < 882) {
                    history.push(`/car/${currentCar.id}`)
                }
                dispatch(editFormsActions.toggleCarHighlightsEditForm())
            },
        },
        description: {
            check: hasDescription,
            text: 'Add description',
            action: () => {
                history.push(`/car/${currentCarId}`)
                dispatch(editFormsActions.toggleCarDescriptionEditForm())
            },
        },
    }

    let hasValidationError = useAppSelector((state) => {
        return state.showroom.validationError
    })

    const [isValidationSheetOpened, setIsValidationSheetOpened] =
        useState<boolean>(false)

    useEffect(() => {
        if (hasValidationError !== null) {
            setIsValidationSheetOpened(true)
        }

        return () => {
            setIsValidationSheetOpened(false)
        }
    }, [hasValidationError])

    return (
        <>
            {/* desktop */}
            <ShowroomCarEditBottomBarDesktop
                handleSetModalOpened={props.handleSetModalOpened}
                isPublished={props.isPublished}
                menu_items={items_to_map}
                currentCar={currentCar}
                currentCarId={currentCarId}
                publishValidationObject={publishValidationObject}
                isReadyToPublish={hasValidationError === null ? true : false}
                setIsValidationSheetOpened={setIsValidationSheetOpened}
                isValidationSheetOpened={isValidationSheetOpened}
                isSold={props.isSold}
            />

            {/* mobile */}
            <ShowroomCarEditBottomBarMobile
                handleSetModalOpened={props.handleSetModalOpened}
                isPublished={props.isPublished}
                menu_items={items_to_map}
                currentCar={currentCar}
                currentCarId={currentCarId}
                publishValidationObject={publishValidationObject}
                isReadyToPublish={hasValidationError === null ? true : false}
                setIsValidationSheetOpened={setIsValidationSheetOpened}
                isValidationSheetOpened={isValidationSheetOpened}
                isMobileRemoved={isMobileRemoved}
                isSold={props.isSold}
            />
        </>
    )
}

export default ShowroomValidationSheetManager
