export function checkIfPwaOnIpad() {
    const ua =
        !!window && window.navigator && window.navigator.userAgent
            ? window.navigator.userAgent
            : undefined
    const isIPad = ua
        ? !!ua.match(/Mac/i) &&
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2
        : undefined
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches
    if (isStandalone && isIPad) return true
    return false
}

export function checkIfIpad() {
    const ua =
        !!window && window.navigator && window.navigator.userAgent
            ? window.navigator.userAgent
            : undefined
    const isIPad = ua
        ? !!ua.match(/Mac/i) &&
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2
        : undefined
    if (isIPad) return true
    return false
}

export function checkIfPwaStandalone() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches
    if (isStandalone) return true
    return false
}
