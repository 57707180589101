import { IImagePayload, IPayloadCarGalleryImageFromApi } from 'IapiDataPayload'
import { IUser } from 'myModels'
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { prepareContent } from '../../../../helpers/textTruncate/truncate'
import { convertShowroomEntryPriceInfoToUIString } from '../../../../helpers/typeConv/showroom'
import { generateUserChoiceUnit } from '../../../../helpers/units/generateUserChoiceUnit'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import { ISearchShowroom_API_car_item } from '../../../../redux/services/showroom/types'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
    IShowroomEntryAPI,
} from '../../../../redux/showroom/types'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { device } from '../../../templates/displays/devices'
import * as SvgIcons from '../../../atoms/icons/components'
import InfoIcon from '../../../atoms/icons/infoIcon'
import { checkIfIpad } from '../../../templates/pwaCustom/checkIpadPwa'
import { numberWithCommas } from '../../../../helpers/numbers'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { scrollIntoView } from 'seamless-scroll-polyfill'
import HeartIcon from '../../../atoms/icons/components/Heart'
import colours from '../../../../providers/theme/colours'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { motion } from 'framer-motion'
import posthog from 'posthog-js'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    entry?: ISearchShowroom_API_car_item
    watchlist_entry?: IShowroomEntryAPI
    user?: IUser
    reset?: any
    refCreated?: any
    isRecommendation?: boolean
}

// container element
const Card = styled.div<{ $isRecommendation?: boolean }>`
    display: flex;
    flex-direction: column;
    transition: all 100ms;
    position: relative;
    width: 100%;
    height: 100%;
    /* background: red; */

    @media ${device.beyond_ipad_mobile} {
        max-width: 30vw;
    }

    @media ${device.smallest_laptops} {
        max-width: 30vw;
    }

    /* @media ${device.ipad} {
        width: 35vw;
        max-width: 35vw;
    } */

    @media (min-width: 880px) and (max-width: 900px) {
        width: ${(props) => (props.$isRecommendation ? '100%' : '35vw')};
        max-width: ${(props) => (props.$isRecommendation ? '100%' : '35vw')};
    }

    @media (min-width: 901px) and (max-width: 1220px) {
        width: ${(props) => (props.$isRecommendation ? '100%' : '35vw')};
        max-width: ${(props) => (props.$isRecommendation ? '100%' : '35vw')};
    }

    @media (max-width: 880px) {
        width: 100%;
        max-width: 100%;
    }

    .clickable {
        transition: 100ms;
        cursor: pointer;

        :hover {
            color: #5ec3ca !important;
        }
    }
`

// image element, covering the available size of the parent element
const Image = styled.img`
    width: 100%;

    object-fit: cover;
    border-radius: 3px;

    height: 220px;

    /* @media (max-width: 600px) {
        height: 220px;
    } */

    @media (min-width: 530px) and (max-width: 880px) {
        height: 400px;
    }

    @media ${device.large_desktop} {
        height: 300px;
        border-radius: 2px;
    }
    @media (min-width: 1900px) {
        height: 350px;
    }

    @media ${device.ultrawide_desktop} {
        height: 350px;
        border-radius: 2px;
    }

    @media (min-width: 1080px) and (max-width: 1220px) {
        height: 250px;
    }

    @media (min-width: 1220px) and (max-width: 1301px) {
        height: 220px;
    }

    @media (min-width: 1301px) and (max-width: 1400px) {
        height: 250px;
    }

    @media (min-width: 1401px) and (max-width: 1600px) {
        height: 270px;
    }

    /* @media (max-width: 600px) {
        height: 330px;
    } */

    @media (min-width: 2300px) {
        height: 280px;
    }
`

// info element, stands for car name and price
const InfoTxt = styled.div`
    font-family: Lato;
    color: #1a1a1a;
    font-size: 1.125rem;
    font-weight: 500;

    @media ${device.ipad} {
        font-size: 1rem;
    }
    line-height: 26px;
`

const InfoRow = styled.div`
    position: relative;

    max-width: 400px;
    margin-top: 0.5rem;

    @media ${device.ipad} {
        max-width: 80vw;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`

const LocalPrice = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    font-family: Lato;
    color: #b3b3b3;
    font-size: 1rem;
    font-weight: 500;
    line-height: 26px;
`
const Title = styled.span`
    font-family: Lato;
    color: #0e0e0e !important;
    font-size: 1.125rem;
    font-weight: 500;
    max-width: 400px;

    @media (min-width: 881px) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    @media ${device.ipad} {
        font-size: 1.5rem;
        max-width: 80vw;
    }
`
// details element, stands for extra car details & footer
const Desc = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    font-family: Lato;
    color: #666666;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    max-width: 400px;
    margin-top: 1rem;
    word-wrap: break-word;

    @media ${device.ipad} {
        font-size: 1.1rem;
        max-width: 80vw;
        line-height: 24px;
        margin-top: 0.6rem;
    }
`

const LastTxtTRow = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    font-family: Lato;
    color: #666666;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 26px;
    max-width: 400px;
    /* margin-top: 1rem; */
    word-wrap: break-word;

    @media ${device.ipad} {
        font-size: 1rem;
        max-width: 80vw;
        line-height: 24px;
        margin-top: 0.6rem;
    }
`

const RowTitle = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 1.5rem;
    line-height: 1.5rem;
    @media ${device.ipad} {
        margin-bottom: 5px;
    }
`
const Heart = styled(motion.button)`
    all: unset;
    padding-left: 30px;

    transform: translateY(6px);

    @media ${device.mobile_and_ipad} {
        padding-left: 10px;
    }

    transition: 100ms;

    cursor: pointer;
    :hover {
        transform: scale(1.1);
    }
`
const ShortDescription = styled.div`
    height: 60px;
    text-overflow: ellipsis;
    overflow: hidden;
`
// footer element, parent element of footer details
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
`

const GreySummary = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: #b3b3b3;
    font-family: Lato;
    font-size: 0.875rem;
    font-weight: 400;
    padding-top: 12px;

    @media ${device.ipad} {
        font-size: 1rem;
        padding-bottom: 12px;
    }

    @media ${device.smallest_laptops} {
        font-size: 0.8rem;
        padding-bottom: 12px;
    }
`

const Dot = styled.div`
    width: 4px;
    height: 4px;
    margin: 8px;
    background-color: #b3b3b3;
    border-radius: 50%;
`

const TooltipInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px;
    position: absolute;
    height: 64px;
    width: 245px;
    right: 0;
    margin-right: -180%;
    margin-bottom: -60px;
    bottom: 0;
    /* background-color: #fafafa; */
    background-color: #3c7d81;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
    /* color: #000; */
    color: #fff;
    opacity: 0;
    transition: all 200ms;

    @media ${device.mobile} {
        display: none;
    }
`

const TooltipInfoMobile = styled.div`
    display: none;

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 8px 20px;
        position: absolute;
        top: 24px;
        width: 100vw;
        margin-left: -20px;
        /* background-color: #fafafa; */
        background-color: #3c7d81;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
        font-family: Lato;
        font-size: 12px;
        line-height: 16px;
        /* color: #000; */
        color: #fff;
        opacity: 0;
        transition: all 200ms;
    }
`

// container for loader skeleton
const LoaderContainer = styled.div`
    position: relative;
    height: 15.75rem;

    @media ${device.large_desktop} {
        height: 300px;
        border-radius: 2px;
    }
    @media (min-width: 1900px) {
        height: 350px;
    }

    @media ${device.ultrawide_desktop} {
        height: 350px;
        border-radius: 2px;
    }

    @media (min-width: 1080px) and (max-width: 1301px) {
        height: 200px;
    }

    @media (min-width: 1301px) and (max-width: 1400px) {
        height: 200px;
    }

    @media (min-width: 1401px) and (max-width: 1600px) {
        height: 270px;
    }

    @media (max-width: 600px) {
        height: 300px;
    }

    /* @media (max-width: 599px) {
        height: 220px;
    } */

    @media (min-width: 2300px) {
        height: 280px;
    }
    width: 100%;
`

const ShowroomCardDesktop: React.FC<Props> = (props) => {
    const size: WindowSize = useWindowSize()
    const { theme } = useThemes()

    const location = useLocation()

    let isMobile = size && size.width && size.width < 880 ? true : false

    const [isImgLoading, setIsImgLoading] = useState<boolean>(true)
    const [img_src, setImg_src] = useState<string | null>(null)
    // props destructuring
    const { entry, watchlist_entry, user, isRecommendation } = props
    const history = useHistory()
    // on loading images
    const onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        setImg_src(src)

        image.onload = () => setIsImgLoading(false)
    }

    let car_profile_image:
        | IImagePayload
        | IPayloadCarGalleryImageFromApi
        | undefined = watchlist_entry
        ? watchlist_entry.cover_image
        : entry
        ? entry.cover_image
        : undefined

    let entry_uid = props.entry && props.entry.uid

    useEffect(() => {
        // on the image loading
        if (car_profile_image && car_profile_image.url) {
            setIsImgLoading(true)
            onLoad(car_profile_image.url)
        } else setIsImgLoading(false)
    }, [car_profile_image?.url, entry_uid])

    let price_conv = entry
        ? convertShowroomEntryPriceInfoToUIString({
              ...entry,
              local_price_min: entry.local_price_min
                  ? +entry.local_price_min / 100
                  : undefined,
              local_price_max: entry.local_price_max
                  ? +entry.local_price_max / 100
                  : undefined,
              listing_price_max: entry.listing_price_max
                  ? +entry.listing_price_max / 100
                  : undefined,
              listing_price_min: entry.listing_price_min
                  ? +entry.listing_price_min / 100
                  : undefined,
              local_currency: entry.local_currency ?? undefined,
              listing_currency: entry.listing_currency ?? undefined,
          })
        : watchlist_entry
        ? convertShowroomEntryPriceInfoToUIString({
              ...watchlist_entry,
              local_price_min: watchlist_entry.local_price_min
                  ? +watchlist_entry.local_price_min / 100
                  : undefined,
              local_price_max: watchlist_entry.local_price_max
                  ? +watchlist_entry.local_price_max / 100
                  : undefined,
              listing_price_max: watchlist_entry.listing_price_max
                  ? +watchlist_entry.listing_price_max / 100
                  : undefined,
              listing_price_min: watchlist_entry.listing_price_min
                  ? +watchlist_entry.listing_price_min / 100
                  : undefined,
              local_currency: watchlist_entry.local_currency ?? undefined,
              listing_currency: watchlist_entry.listing_currency ?? undefined,
          })
        : undefined

    let local_price: string = price_conv?.local_price ?? ''
    let listing_price: string = price_conv?.listing_price ?? ''

    let short_description =
        watchlist_entry &&
        watchlist_entry.car &&
        !watchlist_entry.car.short_description
            ? ''
            : !entry &&
              watchlist_entry?.car?.short_description &&
              watchlist_entry.car.short_description.length > 108
            ? prepareContent(watchlist_entry.car.short_description, 108)
            : !entry && watchlist_entry!.car!.short_description
            ? watchlist_entry!.car!.short_description
            : !entry?.short_description
            ? ''
            : entry &&
              entry &&
              entry.short_description &&
              entry.short_description.length > 108
            ? prepareContent(entry.short_description, 108)
            : entry.short_description

    let genURL = () => {
        let params = new URLSearchParams(location.search)
        let ownerIDParams = params.get('owner_id')
        let ownerNameParams = params.get('owner_display_name')
        let pageN: string = params.get('page')
            ? `?page=${params.get('page')}`
            : ''

        if (ownerIDParams && entry) {
            return {
                pathname: `/showroom/${entry.uid}`,
                search: `${pageN}&owner_id=${ownerIDParams}&owner_display_name=${ownerNameParams}`,
            }
        } else {
            return {
                pathname: `/showroom/${
                    entry
                        ? entry.uid
                        : watchlist_entry
                        ? watchlist_entry.uid
                        : ''
                }`,
                search: `${pageN}`,
            }
        }
    }

    let genWatchlistURL = () => {
        let params = new URLSearchParams(location.search)
        let pageParam = params.get('page')
        let pageN: string = pageParam ? `&page=${pageParam}` : ''

        return {
            pathname: `/showroom/${watchlist_entry?.uid ?? ''}`,
            search: `?is_watchlist=true${pageN}`,
        }
    }

    // let generatePrevPath = () => {
    //     let params = new URLSearchParams(location.search)
    //     let pageN: string = params.get('page')
    //         ? `?page=${params.get('page')}`
    //         : ''
    //     return `/showroom/${
    //         entry?.state === 'SOLD' ? 'sold' : 'for-sale'
    //     }?anchor_entry=${
    //         entry ? entry.uid : watchlist_entry ? watchlist_entry.uid : ''
    //     }${pageN}`
    // }

    let userEngineSizePreferences = user
        ? generateUserChoiceUnit(user, 'fuel_unit')
        : undefined
    let userMileagePreferences = user
        ? generateUserChoiceUnit(user, 'distance_unit')
        : undefined

    // let year = entry
    //     ? entry.year
    //     : watchlist_entry
    //     ? watchlist_entry.car?.year
    //     : undefined
    let mileage = entry
        ? entry.mileage
        : watchlist_entry
        ? watchlist_entry.car?.mileage
        : undefined
    let engine_size = entry
        ? entry.engine_capacity
        : watchlist_entry
        ? watchlist_entry.car?.engine_capacity
        : undefined

    let transmission = entry
        ? entry.transmission
        : watchlist_entry
        ? watchlist_entry.car?.transmission
        : undefined

    let drive_side = entry
        ? entry.drive_side
        : watchlist_entry
        ? watchlist_entry.car?.drive_side
        : undefined

    let owner_display_name = entry
        ? entry.owner_display_name
        : watchlist_entry
        ? watchlist_entry.owner?.display_name
        : undefined

    let address = entry
        ? entry.address
        : watchlist_entry
        ? watchlist_entry.address
        : undefined

    const [showTooltip, setShowTooltip] = useState(false)
    let current_entry = entry
        ? entry
        : watchlist_entry
        ? watchlist_entry
        : undefined

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const resetHandle = () => {
        dispatch(showroomActions.resetAllFilters())
        dispatch(showroomActions.setCurrentPageNumber_sale(0))

        dispatch(
            showroomActions.searchShowroom_setCars({
                cars: undefined,
                resultsFoundNumber: undefined,
                similarSoldCarsNumber: undefined,
                type: 'sale',
            })
        )
    }

    const clickOnOwner = () => {
        if (entry && entry?.owner_uid && entry?.owner_display_name) {
            let ourl = `/showroom`

            if (entry.state === 'SOLD') {
                ourl = ourl + '/sold'
            } else {
                ourl = ourl + '/for-sale'
            }

            ourl =
                ourl +
                `?owner_id=${entry?.owner_uid}&owner_display_name=${entry?.owner_display_name}&page=1`
            ourl = encodeURI(ourl)
            if (props.reset) {
                props.reset()
            } else {
                resetHandle()
            }

            history.replace(ourl)
        } else if (
            watchlist_entry &&
            watchlist_entry?.owner?.uid &&
            watchlist_entry?.owner?.display_name
        ) {
            let ourl = `/showroom/for-sale`
            ourl =
                ourl +
                `?owner_id=${watchlist_entry.owner?.uid}&owner_display_name=${watchlist_entry.owner?.display_name}&page=1`
            ourl = encodeURI(ourl)
            if (props.reset) {
                props.reset()
            } else {
                resetHandle()
            }
            history.replace(ourl)
        }
    }

    const shapeEngineSizeValue = (id: string, value: number) => {
        if (id !== 'cubic_centimeters') {
            return unitGenerator
                .generateFrontEndValueFuelUnit(id, value)
                .toFixed(1)
        } else {
            return unitGenerator.generateFrontEndValueFuelUnit(id, value)
        }
    }

    let scrollSmoothToTop = () => {
        window?.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        if (!isMobile && props.refCreated) {
            scrollIntoView(props.refCreated.current, {
                behavior: 'smooth',
                block: 'nearest',
            })
        }

        if (isMobile && props.refCreated) {
            scrollIntoView(props.refCreated.current, {
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }, [props.refCreated])

    let normalisedPublicEntries: INormalisedShowroomEntries | undefined =
        useAppSelector((state) => {
            return state.showroom.normalisedPublicEntries
        })

    let entry_normalised: IShowroomEntry | undefined =
        current_entry &&
        current_entry.uid &&
        normalisedPublicEntries &&
        normalisedPublicEntries[current_entry.uid]
            ? normalisedPublicEntries[current_entry.uid]
            : undefined

    let is_entry_watched = entry_normalised
        ? entry_normalised.is_watched
        : current_entry?.is_watched

    const onClickWatchList = () => {
        if (!userLoggedIn) {
            history.push('/login')
            current_entry?.uid &&
                dispatch(
                    customNavDataActions.setInitialLandingUrl(
                        `/showroom/${current_entry?.uid}`
                    )
                )

            posthog.capture(
                'toggle_showroom_entry_to_watchlist click from feed - user not logged in'
            )
        } else {
            if (current_entry?.uid) {
                if (is_entry_watched === true) {
                    dispatch(
                        showroomActions.removeEntryFromWatchlistRequest(
                            current_entry?.uid
                        )
                    )
                    posthog.capture(
                        'remove_showroom_entry_to_watchlist click from feed'
                    )
                } else if (!is_entry_watched && current_entry?.uid) {
                    dispatch(
                        showroomActions.addEntryToWatchlistRequest(
                            current_entry?.uid
                        )
                    )

                    posthog.capture(
                        'add_showroom_entry_to_watchlist click from feed'
                    )
                }
            }
        }
    }

    return (
        <Card
            ref={props.refCreated ?? null}
            id={
                isMobile
                    ? `mobile-${
                          watchlist_entry ? watchlist_entry.uid : entry?.uid
                      }`
                    : `desktop-${
                          watchlist_entry ? watchlist_entry.uid : entry?.uid
                      }`
            }
            $isRecommendation={isRecommendation}
        >
            {isImgLoading ? (
                <LoaderContainer>
                    <SkeletonAbsolute isthingloading={isImgLoading} />
                </LoaderContainer>
            ) : img_src ? (
                <Link
                    to={
                        watchlist_entry
                            ? {
                                  pathname: genWatchlistURL()?.pathname,
                                  search: genWatchlistURL()?.search,
                              }
                            : {
                                  pathname: genURL().pathname,
                                  search: genURL().search,
                              }
                    }
                    onClick={() => {
                        if (entry) {
                            dispatch(
                                showroomActions.set_entry_anchor(entry?.uid)
                            )
                            if (isRecommendation) {
                                scrollSmoothToTop()
                            }
                        }
                    }}
                    style={{ width: '100%', cursor: 'pointer' }}
                >
                    <Image src={img_src} />
                </Link>
            ) : null}

            <RowTitle>
                <Title
                    className={'clickable'}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <Link
                        to={
                            watchlist_entry
                                ? {
                                      pathname: genWatchlistURL()?.pathname,
                                      search: genWatchlistURL()?.search,
                                  }
                                : {
                                      pathname: genURL().pathname,
                                      search: genURL().search,
                                  }
                        }
                        onClick={() => {
                            if (entry) {
                                dispatch(
                                    showroomActions.set_entry_anchor(entry?.uid)
                                )
                                if (isRecommendation) {
                                    scrollSmoothToTop()
                                }
                            }
                        }}
                    >
                        <span style={{ color: '#0e0e0e ' }}>
                            {watchlist_entry
                                ? watchlist_entry.car?.title
                                : entry?.title
                                ? entry?.title
                                : ''}
                        </span>
                    </Link>
                </Title>
                {(entry?.state === 'PUBLISHED' || is_entry_watched) && (
                    <Heart
                        whileTap={{ scale: 1.3 }}
                        onClick={() => {
                            onClickWatchList()
                        }}
                    >
                        <HeartIcon
                            color={
                                is_entry_watched
                                    ? colours[theme].error
                                    : colours[theme].text_default
                            }
                            bgColor={
                                is_entry_watched
                                    ? colours[theme].error
                                    : colours[theme].background_default
                            }
                        />
                    </Heart>
                )}
            </RowTitle>
            {!current_entry ? null : current_entry &&
              current_entry.state === 'SOLD' ? (
                <InfoRow>
                    <InfoTxt style={{ color: '#DF6F6F' }}>Sold</InfoTxt>
                </InfoRow>
            ) : (
                current_entry?.state !== 'SOLD' && (
                    <InfoRow>
                        <InfoTxt
                            style={{ paddingRight: '8px', color: '#666666' }}
                        >
                            {listing_price}
                        </InfoTxt>

                        {current_entry?.listing_currency !==
                            current_entry?.local_currency && (
                            <LocalPrice
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                onClick={() => {
                                    if (
                                        window.innerWidth < 880 ||
                                        checkIfIpad()
                                    ) {
                                        setShowTooltip(true)
                                        setTimeout(
                                            () => setShowTooltip(false),
                                            2000
                                        )
                                    }
                                }}
                            >
                                {local_price}{' '}
                                <span style={{ paddingLeft: 4 }} />
                                <InfoIcon size="12px" color="#cccccc" />
                                <TooltipInfo
                                    style={
                                        showTooltip
                                            ? { opacity: 1, zIndex: 1 }
                                            : {}
                                    }
                                >
                                    Secondary price is an estimated conversion
                                    based on your currency setting. You can
                                    change it in profile settings.
                                </TooltipInfo>
                            </LocalPrice>
                        )}
                        <TooltipInfoMobile
                            style={showTooltip ? { opacity: 1, zIndex: 1 } : {}}
                        >
                            Secondary price is an estimated conversion based on
                            your currency setting. You can change it in profile
                            settings.
                        </TooltipInfoMobile>
                    </InfoRow>
                )
            )}
            <ShortDescription>
                <Desc>{short_description}</Desc>
            </ShortDescription>
            {entry || watchlist_entry ? (
                <GreySummary>
                    {entry?.undisclosed_mileage ||
                    watchlist_entry?.undisclosed_mileage ? (
                        `Undisclosed ${
                            userMileagePreferences?.unit_text ?? 'mi'
                        }`
                    ) : mileage !== undefined && mileage !== null ? (
                        <>
                            {userMileagePreferences
                                ? ` ${numberWithCommas({
                                      numberToConvert:
                                          unitGenerator.generateFrontEndValueDistanceUnit(
                                              userMileagePreferences?.unit_id,
                                              mileage
                                          ),
                                  })} ${userMileagePreferences.unit_text}`
                                : `${numberWithCommas({
                                      numberToConvert:
                                          unitGenerator.generateFrontEndValueDistanceUnit(
                                              'miles',
                                              mileage
                                          ),
                                  })} mi`}
                        </>
                    ) : (
                        '-'
                    )}
                    {engine_size ? (
                        <>
                            <Dot />
                            {userEngineSizePreferences
                                ? `${shapeEngineSizeValue(
                                      userEngineSizePreferences?.unit_id,
                                      engine_size
                                  )} ${userEngineSizePreferences.unit_text}`
                                : `${unitGenerator
                                      .generateFrontEndValueFuelUnit(
                                          'liters',
                                          engine_size
                                      )
                                      .toFixed(1)} liters`}
                        </>
                    ) : (
                        ''
                    )}

                    {transmission && (
                        <>
                            <Dot /> {transmission}
                        </>
                    )}
                    {drive_side && (
                        <>
                            <Dot />
                            {drive_side === 'Left'
                                ? 'LHD'
                                : drive_side === 'Right'
                                ? 'RHD'
                                : 'Center'}
                        </>
                    )}
                </GreySummary>
            ) : null}
            <Footer>
                <LastTxtTRow
                    style={{ color: '#1A1A1A', fontFamily: 'Lato-semibold' }}
                    className={isMobile ? '' : 'clickable'}
                    onClick={() => {
                        if (!isMobile) {
                            clickOnOwner()
                        }
                    }}
                >
                    {owner_display_name && owner_display_name}
                </LastTxtTRow>
                <LastTxtTRow>
                    <SvgIcons.LocationIcon size="18px" />
                    <div style={{ paddingLeft: '2px' }} />
                    {address
                        ? `${address?.locality ?? ''}, ${
                              address?.country
                                  ? address.country === 'United Kingdom'
                                      ? 'UK'
                                      : address.country === 'United States'
                                      ? 'US'
                                      : address.country
                                  : ''
                          }`
                        : 'Unknown Location'}
                </LastTxtTRow>
            </Footer>
        </Card>
    )
}

export default ShowroomCardDesktop
