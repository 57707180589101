import React from 'react'
// import SigninForm from '../organisms/signinform/SignInForm'
import { connect } from 'react-redux'
import Faded from '../templates/animated/faded'
import { device } from '../templates/displays/devices'
// import TriangleHeader from '../atoms/header/triangleheader'
import styled from 'styled-components'
// import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'
import Loader from '../atoms/loader/loader'
import { resetErrorStateSuccess } from '../../redux/user/actions/errorActions'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
// import { Redirect } from 'react-router-dom'
import backgroundImage from '../../public/assets/landingPage/zebra_resize.jpg'
import banner_text from '../../public/assets/landingPage/manage_your_car_banner_text.svg'
import banner_text_dark from '../../public/assets/landingPage/manage_your_car_banner_text_dark.svg'
import SignInFormCard from '../organisms/signinform/SignInFormCard'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { getCurrentUserDataRequest } from '../../redux/user/actions/loadingActions'
import { IReduxError } from 'entityModels'
import { customNavDataActions } from '../../redux/localdata/customNav/reducer'
import { ThemesContext } from '../../providers/theme/customThemeProvider'
import { ITheme } from '../../providers/theme/colours'

// const Wrapper = styled.section`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100vw;
//     @media ${device.desktop} {
//         padding-top: 80px;
//     }

//     @media ${device.mobile} {
//         padding-top: 50px;
//     }

//     @media ${device.ipad} {
//         padding-top: 100px;
//     }
// `

const Wrapper = styled.section<{ $theme: ITheme }>`
    width: 100%;
    display: flex;
    align-items: center;
    height: 100dvh;

    @media (max-width: 1600px) {
        justify-content: flex-end;
        padding-bottom: 30px;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        /* height: 100vh; */
        max-height: none;
        padding-bottom: 0px;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100dvh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        justify-content: flex-end;
        align-items: flex-start;
        background-image: ${(props) =>
            props.$theme === 'dark'
                ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`
                : `url(${backgroundImage})`};
        background-repeat: no-repeat;
        background-size: 120%;
        background-position: center;
        padding-right: 150px;
        padding-bottom: 150px;
        padding-top: 150px;
        animation: 0.3s fadeIn both;
        display: flex;
        align-items: center;
        overflow-y: hidden;
    }

    @media (min-width: 2200px) {
        padding-right: 300px;
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
`

const ImageDiv = styled.div<{ $theme: ITheme }>`
    background-image: ${(props) =>
        props.$theme === 'dark'
            ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`
            : `url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: cover;

    @media ${device.mobile} {
        display: none;
    }

    @media ${device.ipad} {
        display: none;
    }
    @media ${device.desktop} {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 150%;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`

const MobileAndIpadImage = styled.div<{ $theme: ITheme }>`
    background-image: ${(props) =>
        props.$theme === 'dark'
            ? `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`
            : `url(${backgroundImage})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: -50px;
    z-index: 1;

    @media ${device.mobile} {
    }

    @media ${device.ipad} {
        background-size: 150%;
        height: 70vh;
    }
    @media ${device.desktop} {
        display: none;
    }
`

const BannerTextSVG = styled.div`
    width: 80%;
    padding-bottom: 20px;
    padding-left: 20px;
    animation: 1s slideUp both;

    @media ${device.mobile} {
        display: none;
    }

    @media ${device.ipad} {
        display: none;
    }

    @media ${device.desktop} {
        position: relative;
        width: 70%;
        padding-top: 50px;
        padding-left: 60px;
    }

    @media ${device.large_desktop} {
        position: relative;
        width: 60%;
        padding-top: 100px;
        padding-left: 60px;
    }

    @media (min-width: 2200px) {
        padding-top: 50px;
        padding-left: 80px;
        width: 60%;
    }

    @keyframes slideUp {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }

        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
`

const BannerTextWideScreenWrapper = styled.div`
    position: absolute;
    top: 50px;
    left: 50px;
    @media (max-width: 1599px) {
        display: none;
    }
`

const FormWrapper = styled.section`
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    overflow-y: auto;

    @media (max-width: 1600px) {
        position: relative;
        /* max-height: 100vh; */
        justify-self: flex-end;
        border-radius: 0px;
        width: 30%;
        padding-top: 0px;
    }

    @media ${device.mobile} {
        align-items: center;
        position: relative;
        z-index: 2;
        height: 100%;
        padding-top: calc(env(safe-area-inset-bottom) + 32px);
        width: 100%;
        box-shadow: none;
        border-radius: 0px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 50px;
        background: linear-gradient(
            var(--bg-color, #fff) 85%,
            rgba(255, 255, 255, 0)
        );
        overflow-x: hidden;
    }

    @media ${device.ipad} {
        align-items: center;
        position: relative;
        z-index: 2;
        /* height: auto; */
        padding-top: 50px;
        padding-bottom: 80px;
        width: 100%;
        box-shadow: none;
        border-radius: 0px;
        background: linear-gradient(
            var(--bg-color, #fff) 85%,
            rgba(255, 255, 255, 0)
        );
    }

    @media ${device.large_desktop} {
        padding-top: 10px;
        padding-bottom: 50px;
        border-radius: 10px;
        box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.1);
        width: 400px;
        padding-left: 40px;
        padding-right: 40px;
    }

    @media (min-width: 2150px) {
        padding-left: 40px;
        padding-right: 40px;
        transform: scale(1.2);
    }
`

interface Props extends RouteComponentProps<any> {
    resetErrorState: () => void
    getUserDataRequest: (context?: 'LOGIN' | 'REGISTER') => void
    setInitialExternalLandingUrl: (v: string) => void
    userid: string | null
    userLoggedIn: null | IUser
    hasLoggedOut?: boolean
    userError: IReduxError | null
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        hasLoggedOut: state.user.hasLoggedOut,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        userError: state.user.error,
    }
}

const dispatchProps = {
    resetErrorState: resetErrorStateSuccess,
    getUserDataRequest: (context?: 'LOGIN' | 'REGISTER') =>
        getCurrentUserDataRequest(context),
    setInitialExternalLandingUrl: (v: string | null) =>
        customNavDataActions.setInitialExternalLandingUrl(v),
}

type State = {
    redirect: boolean
}

class Signin extends React.Component<Props, State> {
    state = {
        redirect: false,
    }

    componentDidMount() {
        this.props.resetErrorState()

        const q_params = new URLSearchParams(this.props.location.search)
        let external_redirect_to = q_params.get('external_redirect_to')

        if (external_redirect_to) {
            this.props.setInitialExternalLandingUrl(external_redirect_to)
        }

        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            // this.props.getUserDataRequest()

            this.props.getUserDataRequest('LOGIN')
        }

        if (this.props.userLoggedIn === null) {
            this.setState({ redirect: false })
        } else if (
            this.props.userLoggedIn &&
            this.props.userLoggedIn !== null &&
            !this.props.hasLoggedOut
        ) {
            if (
                external_redirect_to &&
                this.props.userError?.status_code !== 503
            ) {
                window.location.href = external_redirect_to
            } else {
                this.setState({ redirect: true })
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn === null) {
                this.setState({ redirect: false })
            } else if (
                this.props.userLoggedIn &&
                this.props.userLoggedIn !== null &&
                !this.props.hasLoggedOut
            ) {
                const q_params = new URLSearchParams(this.props.location.search)
                let external_redirect_to = q_params.get('external_redirect_to')

                if (
                    external_redirect_to &&
                    this.props.userError?.status_code !== 503
                ) {
                    window.location.href = external_redirect_to
                } else {
                    this.setState({ redirect: true })
                }
            }
        }
    }

    render() {
        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        {this.state.redirect === true &&
                            this.props.userError?.status_code !== 503 && (
                                <Redirect to="/garage" />
                            )}

                        {/* <InstallIosPropmt preventTogglingNextVisibility={true} /> */}
                        <Loader />
                        <Faded>
                            <Wrapper $theme={theme}>
                                <BannerTextWideScreenWrapper>
                                    <BannerTextSVG>
                                        <img
                                            style={{
                                                width: '100%',
                                            }}
                                            src={
                                                theme === 'dark'
                                                    ? banner_text_dark
                                                    : banner_text
                                            }
                                        />
                                    </BannerTextSVG>
                                </BannerTextWideScreenWrapper>
                                <ImageDiv $theme={theme}>
                                    <BannerTextSVG>
                                        <img
                                            style={{
                                                width: '100%',
                                                position: 'absolute',
                                                zIndex: 2,
                                            }}
                                            src={
                                                theme === 'dark'
                                                    ? banner_text_dark
                                                    : banner_text
                                            }
                                        />
                                    </BannerTextSVG>
                                </ImageDiv>
                                <FormWrapper>
                                    <SignInFormCard />
                                </FormWrapper>
                                <MobileAndIpadImage $theme={theme} />
                            </Wrapper>
                        </Faded>
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(Signin))
