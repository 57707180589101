export const SET_GARAGE_DATA_REQUEST = '@@garage/set/REQUEST'
export const SET_GARAGE_DATA_SUCCESS = '@@garage/set/SUCCESS'
export const SET_GARAGE_DATA_ERROR = '@@garage/set/ERROR'

export const GET_GARAGE_BY_USER_ID_REQUEST = '@@garage/get_by_userid/REQUEST'
export const GET_GARAGE_BY_USER_ID_SUCCESS = '@@garage/get_by_userid/SUCCESS'
export const GET_GARAGE_BY_USER_ID_ERROR = '@@garage/get_by_userid/ERROR'

export const SET_GARAGE_TASK_IDS_REQUEST = '@@garage_tasks/set/REQUEST'
export const SET_GARAGE_TASK_IDS_SUCCESS = '@@garage_tasks/set/SUCCESS'
export const SET_GARAGE_TASK_IDS_ERROR = '@@garage_tasks/set/ERROR'

export const ADD_A_CAR_TO_GARAGE_REQUEST = '@@garage/add/REQUEST'
export const ADD_A_CAR_TO_GARAGE_SUCCESS = '@@garage/add/SUCCESS'
export const ADD_A_CAR_TO_GARAGE_ERROR = '@@garage/add/ERROR'

export const GET_PUBLIC_SHARES_GARAGE_REQUEST =
    '@@garage_public_shares/get/REQUEST'
export const GET_PUBLIC_SHARES_GARAGE_SUCCESS =
    '@@garage_public_shares/get/SUCCESS'
export const GET_PUBLIC_SHARES_GARAGE_ERROR = '@@garage_public_shares/get/ERROR'

export const CREATE_GARAGE_PUBLIC_SHARE_REQUEST =
    '@@garage_public_share/create/REQUEST'
export const CREATE_GARAGE_PUBLIC_SHARE_SUCCESS =
    '@@garage_public_share/create/SUCCESS'
export const CREATE_GARAGE_PUBLIC_SHARE_ERROR =
    '@@garage_public_share/create/ERROR'

export const DELETE_GARAGE_PUBLIC_SHARE_REQUEST =
    '@@garage_public_share/delete/REQUEST'
export const DELETE_GARAGE_PUBLIC_SHARE_SUCCESS =
    '@@garage_public_share/delete/SUCCESS'
export const DELETE_GARAGE_PUBLIC_SHARE_ERROR =
    '@@garage_public_share/delete/ERROR'

export const CHANGE_SORT_GARAGE_SHARE_DISPLAY_REQUEST =
    '@@garage_share/display_sort/REQUEST'
export const CHANGE_SORT_GARAGE_SHARE_DISPLAY_SUCCESS =
    '@@garage_share/display_sort/SUCCESS'

export const CHANGE_FILTER_GARAGE_SHARE_DISPLAY_REQUEST =
    '@@garage_share/display_filter/REQUEST'
export const CHANGE_FILTER_GARAGE_SHARE_DISPLAY_SUCCESS =
    '@@garage_share/display_filter/SUCCESS'

export const GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_SUCCESS =
    '@@external_garages_shared_with_you/get/SUCCESS'
export const GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_REQUEST =
    '@@external_garages_shared_with_you/get/REQUEST'
export const GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_ERROR =
    '@@external_garages_shared_with_you/get/ERROR'

export const SET_CURRENT_EXTERNAL_GARAGE_REQUEST =
    '@@external_garage/current/set/REQUEST'
export const SET_CURRENT_EXTERNAL_GARAGE_SUCCESS =
    '@@external_garage/current/set/SUCCESS'
export const SET_CURRENT_EXTERNAL_GARAGE_ERROR =
    '@@external_garage/current/set/ERROR'

export const POPULATE_SINGLE_GARAGE_ALL_OWNED_SHARES_SUCCESS =
    '@@owned_garage/all_shares/set/SUCCESS'

export const GET_OWNED_DIRECT_SHARES_GARAGE_REQUEST =
    '@@garage_owned_shares/get/REQUEST'
export const GET_OWNED_DIRECT_SHARES_GARAGE_SUCCESS =
    '@@garage_owned_shares/get/SUCCESS'
export const GET_OWNED_DIRECT_SHARES_GARAGE_ERROR =
    '@@garage_owned_shares/get/ERROR'

export const CREATE_GARAGE_DIRECT_SHARE_REQUEST =
    '@@garage_direct_share/create/REQUEST'
export const CREATE_GARAGE_DIRECT_SHARE_SUCCESS =
    '@@garage_direct_share/create/SUCCESS'
export const CREATE_GARAGE_DIRECT_SHARE_ERROR =
    '@@garage_direct_share/create/ERROR'

export const UPDATE_GARAGE_DIRECT_SHARE_REQUEST =
    '@@garage_direct_share/update/REQUEST'
export const UPDATE_GARAGE_DIRECT_SHARE_SUCCESS =
    '@@garage_direct_share/update/SUCCESS'
export const UPDATE_GARAGE_DIRECT_SHARE_ERROR =
    '@@garage_direct_share/update/ERROR'

export const GET_SINGLE_GARAGE_DIRECT_SHARE_REQUEST =
    '@@garage_direct_share_single/get/REQUEST'
export const GET_SINGLE_GARAGE_DIRECT_SHARE_SUCCESS =
    '@@garage_direct_share_single/get/SUCCESS'
export const GET_SINGLE_GARAGE_DIRECT_SHARE_ERROR =
    '@@garage_direct_share_single/get/ERROR'

export const DELETE_GARAGE_DIRECT_SHARE_REQUEST =
    '@@garage_direct_share/delete/REQUEST'
export const DELETE_GARAGE_DIRECT_SHARE_ERROR =
    '@@garage_direct_share/delete/ERROR'
export const DELETE_GARAGE_DIRECT_SHARE_SUCCESS =
    '@@garage_direct_share/delete/SUCCESS'

export const ADD_SHARE_RECIPIENT_GARAGE_SHARE_REQUEST =
    '@@garage_direct_share_recipient/add/REQUEST'
export const ADD_SHARE_RECIPIENT_GARAGE_SHARE_ERROR =
    '@@garage_direct_share_recipient/add/ERROR'
export const ADD_SHARE_RECIPIENT_GARAGE_SHARE_SUCCESS =
    '@@garage_direct_share_recipient/add/SUCCESS'

export const DELETE_SHARE_RECIPIENT_GARAGE_REQUEST =
    '@@garage_direct_share_recipient/delete/REQUEST'
export const DELETE_SHARE_RECIPIENT_GARAGE_ERROR =
    '@@garage_direct_share_recipient/delete/ERROR'
export const DELETE_SHARE_RECIPIENT_GARAGE_SUCCESS =
    '@@garage_direct_share_recipient/delete/SUCCESS'

// SHARES RECIPIENTS SORT
export const CHANGE_SORT_GARAGE_SHARE_RECIPIENTS_DISPLAY_REQUEST =
    '@@garage_share_recipients/display_sort/REQUEST'
export const CHANGE_SORT_GARAGE_SHARE_RECIPIENTS_DISPLAY_SUCCESS =
    '@@garage_share_recipients/display_sort/SUCCESS'

export const CLEAN_UP_GARAGES_DATA_ON_LOGOUT =
    '@@clean_up_garages_data_on_logout/SUCCESS'

export const CREATE_GARAGE_SHARE_INVITE_REQUEST =
    '@@garage_share_invite/create/REQUEST'
export const CREATE_GARAGE_SHARE_INVITE_SUCCESS =
    '@@garage_share_invite/create/SUCCESS'
export const CREATE_GARAGE_SHARE_INVITE_ERROR =
    '@@garage_share_invite/create/ERROR'

export const DELETE_GARAGE_SHARE_INVITE_REQUEST =
    '@@garage_share_invite/delete/REQUEST'
export const DELETE_GARAGE_SHARE_INVITE_SUCCESS =
    '@@garage_share_invite/delete/SUCCESS'
export const DELETE_GARAGE_SHARE_INVITE_ERROR =
    '@@garage_share_invite/delete/ERROR'

export const RESEND_GARAGE_SHARE_INVITE_REQUEST =
    '@@garage_share_invite/resend/REQUEST'
export const RESEND_GARAGE_SHARE_INVITE_SUCCESS =
    '@@garage_share_invite/resend/SUCCESS'
export const RESEND_GARAGE_SHARE_INVITE_ERROR =
    '@@garage_share_invite/resend/ERROR'

export const ACCEPT_GARAGE_SHARE_INVITE_REQUEST =
    '@@garage_share_invite/accept/REQUEST'
export const ACCEPT_GARAGE_SHARE_INVITE_SUCCESS =
    '@@garage_share_invite/accept/SUCCESS'
export const ACCEPT_GARAGE_SHARE_INVITE_ERROR =
    '@@garage_share_invite/accept/ERROR'

export const REMOVE_CAR_FROM_USER_GARAGE = '@@garage_car/removal/SUCCESS'

export const SORT_GARAGE_CARS_REQUEST = '@@sort_garage_cars/set/REQUEST'
export const SORT_GARAGE_CARS_SUCCESS = '@@sort_garage_cars/set/SUCCESS'
export const SORT_GARAGE_CARS_ERROR = '@@sort_garage_cars/set/ERROR'

export const SET_CURRENT_GARAGE_PAGE_NUMBER =
    '@@garage_current_page_number/set/SUCCESS'

export const SET_TOTAL_CARS_NUMBER_FOUND = '@@total_cars_number/set/SUCCESS'

export const RESET_SORTED_CARS_IN_GARAGE =
    '@@sorted_cars_in_garage/reset/SUCCESS'
