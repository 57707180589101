import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from './../../reducer'
import { api } from '../../../services'
import {
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import { compareTwoAddresses_insurance } from '../../../conversions/insuranceApplication/addressObj'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
} from './get'
import posthog from 'posthog-js'

export function* Submit_insuranceAddresses(): any {
    try {
        posthog.capture('Update insurance application address')

        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft = { ...all_draft.addresses }
        let existing = { ...all_existing.addresses }

        let dataToSendToPatch: IAnyObject = {}

        let addressesToAdd: IAnyObject[] = []

        const pattern = /^new_address/

        if (Object.keys(draft).length > 0) {
            Object.keys(draft).forEach((addressUID: string) => {
                if (Object.keys(existing).indexOf(addressUID) === -1) {
                    // doesnt exist, needs to be created. So UID gen by FE should not be included in the PATCH.
                    // here can convert FE address to IAddressAPI type if need be

                    let addresss = { ...draft[addressUID] }
                    if (pattern.test(`${addressUID}`)) {
                        delete addresss['uid']
                    }

                    addressesToAdd = [...addressesToAdd, { ...addresss }]
                } else {
                    // existing address, but diff fields
                    let addresss = compareTwoAddresses_insurance({
                        draft: draft[addressUID],
                        existing: existing[addressUID],
                    })

                    if (addresss) {
                        addresss = {
                            ...addresss,
                            uid: addressUID,
                        }
                        addressesToAdd = [...addressesToAdd, { ...addresss }]
                    }
                }
            })
        }

        if (addressesToAdd.length > 0) {
            dataToSendToPatch = {
                customer: {
                    addressbook: [...addressesToAdd],
                },
            }
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // call api to patch as thats all the data merged we want to patch

            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )
            yield put(
                insuranceActions.submit_insuranceAddresses_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(
                insuranceActions.submit_insuranceAddresses_success(undefined)
            )
        }

        let addressesToDelete: string[] = []

        Object.keys(existing).forEach((addressID: string) => {
            if (Object.keys(draft).indexOf(addressID) === -1) {
                addressesToDelete = [...addressesToDelete, addressID]
            }
        })

        if (addressesToDelete.length > 0) {
            for (let i = 0; i < addressesToDelete.length; i++) {
                let req_del: IDeleteInsuranceApplication_req = {
                    appli_id: `${all_existing.id}`,
                    entity_id: addressesToDelete[i],
                    entity_type: 'addresses',
                }
                yield call(
                    api.insuranceApplication.deleteInsuranceApplicationEntity,
                    {
                        ...req_del,
                    }
                )
            }
            // need to put the new address UID inside of the reducer though

            yield put(
                insuranceActions.submit_insuranceAddresses_success(undefined)
            )
            yield put(insuranceActions.getting_insurance_info_after_change())
            yield call(GetInsuranceApplication, `${all_existing.id}`)
        } else {
            yield put(
                insuranceActions.submit_insuranceAddresses_success(undefined)
            )
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_insuranceAddresses,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'Go back to you addresses',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/addresses`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_insuranceAddresses_error(customError)
            )
        }
    }
}

function* Watcher_Submit_insuranceAddresses() {
    while (true) {
        yield take(insuranceActions.submit_insuranceAddresses_request)
        yield call(Submit_insuranceAddresses)
    }
}

const insuranceAddresses_submit: any[] = [
    fork(Watcher_Submit_insuranceAddresses),
]

export default insuranceAddresses_submit
