import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
// import Loader from "../atoms/loader/loader";
import Faded from '../../templates/animated/faded'

import SectionHeader from '../../atoms/header/section/sectionHeader'

import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import { IFAQquestionItem, IHelpPageContact } from 'helpPageCopyModels'
import ExpandingItemsListDesktop from '../../molecules/expandingItemsList/expandingItemsListDesktop'

import HelpTutorialSectionDesktop from '../../molecules/helpTutorialsSection/helpTutorialSectionDesktop'
import HelpTutorialSectionMobile from '../../molecules/helpTutorialsSection/helpTutorialSectionMobile'

import ExpandingItemsListMobile from '../../molecules/expandingItemsList/expandingItemsListMobile'
import HelpQuickAccessMobile from '../../molecules/helpQuickAccessMobile/HelpQuickAccessMobile'
import ContactFormMobile from '../../organisms/editForms/contactForm/contactFormMobile'
import ContactFormDesktop from '../../organisms/editForms/contactForm/contactFormDesktop'

import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { IGarageObject } from 'entityModels'

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        garage_id:
            state.user.userLoggedIn && state.user.userLoggedIn.owns_garage?.uid,
        garage_data: state.entities.garagesData.garages,
        // faqData: state.localdata.help_page_copy.helpPageCopy.faq,
        contactReasonsData:
            state.localdata.help_page_copy.selectedContactReason,
    }
}

type State = {}

interface Props {
    userLoggedIn: null | IUser
    getUserDataRequest: () => void
    userid: string | null
    garage_data: IGarageObject
    getGarageCarsDataRequest: (id: string) => void
    garage_id: string | null
    faqData: IFAQquestionItem[]
    contactReasonsData: IHelpPageContact
    setInitialLandingUrl: (str: string) => void
}

class Help extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        this.props.setInitialLandingUrl('/account/help')

        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }
    }

    render() {
        const {
            userLoggedIn,
            faqData,
            contactReasonsData,
            garage_id,
            garage_data,
        } = this.props

        let carId: string | null =
            garage_id && garage_data[garage_id]
                ? garage_data[garage_id].cars[
                      garage_data[garage_id].cars.length - 1
                  ]
                : null

        return (
            <CenteredPageWrapper>
                {userLoggedIn && (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title={'FAQ'}
                                    removeEdit={true}
                                />

                                {/* Add FAQ molecule here */}
                                <ExpandingItemsListDesktop list={faqData} />

                                <div style={{ marginTop: '20px' }} />

                                <SectionHeader
                                    title={'Tutorials'}
                                    removeEdit={true}
                                />

                                <div style={{ marginTop: '20px' }} />

                                <HelpTutorialSectionDesktop carId={carId} />

                                <SectionHeader
                                    title={'Contact'}
                                    removeEdit={true}
                                />

                                <ContactFormDesktop
                                    contactReasonsData={contactReasonsData}
                                />
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <MobileSettingsSectionHeader title="QUICK ACCESS" />
                            <div style={{ height: '30px' }} />
                            <HelpQuickAccessMobile />
                            <div style={{ height: '20px' }} />
                            <MobileSettingsSectionHeader
                                id="faq-mobile"
                                title="FAQ"
                            />
                            <div style={{ height: '40px' }} />
                            <ExpandingItemsListMobile list={faqData} />
                            <div style={{ height: '50px' }} />
                            <MobileSettingsSectionHeader
                                id="tutorials-mobile"
                                title="Tutorials"
                            />
                            <div style={{ height: '40px' }} />
                            <HelpTutorialSectionMobile carId={carId} />
                            <div style={{ height: '50px' }} />
                            <MobileSettingsSectionHeader
                                id="contact-mobile"
                                title="Contact"
                            />
                            <div style={{ height: '20px' }} />
                            <ContactFormMobile
                                contactReasonsData={contactReasonsData}
                            />
                        </IpadAndMobileDisplay>
                    </Faded>
                )}
            </CenteredPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(Help)
