import { call, fork, put, select, take } from 'redux-saga/effects'

import * as loadingActions from '../actions/loadingActions'
import * as successActions from '../actions/successActions'
import * as errorActions from '../actions/errorActions'
import posthog from 'posthog-js'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError, ISearchUsersResult } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IApi_SearchUsersInDirectShare_args } from 'ApiInterfaces'
import { api } from '../../services'
import { RootState } from 'typesafe-actions'

const get_current_user = (state: RootState) =>
    state.user.userLoggedIn && state.user.userLoggedIn.id

export function* GetSearchedUsersSaga(payload: string): any {
    try {
        if (payload && payload.trim().length > 3 && payload.trim() !== '') {
            let trimmedPayload = payload.trim()
            let array_from_string: string[] = trimmedPayload.split(' ')
            let filter_payload = {
                given_name: '',
                family_name: '',
                display_name: '',
            }

            if (array_from_string.length === 1) {
                filter_payload.given_name = array_from_string[0]
                filter_payload.family_name = array_from_string[0]
                filter_payload.display_name = array_from_string[0]
            } else if (array_from_string.length === 2) {
                filter_payload.given_name = array_from_string[0]
                filter_payload.family_name = array_from_string[1]
                filter_payload.display_name = trimmedPayload
            } else if (array_from_string.length > 2) {
                filter_payload.given_name = array_from_string[0]
                filter_payload.family_name = array_from_string[1]
                filter_payload.display_name = trimmedPayload
            }

            let api_payload: IApi_SearchUsersInDirectShare_args = {
                limit: 5,
                filter: filter_payload,
                accuracy: 5,
            }

            const response: { data: ISearchUsersResult[] } = yield call(
                api.sharing.searchUsersInDirectShareApi,
                api_payload
            )

            let currentUserId: string | undefined = yield select(
                get_current_user
            )

            let cleanedArray: ISearchUsersResult[] | undefined =
                response.data && currentUserId
                    ? response.data.filter(
                          (user: ISearchUsersResult) =>
                              user.uid !== currentUserId
                      )
                    : undefined

            if (cleanedArray && cleanedArray.length !== 0) {
                yield put(
                    successActions.searchPlatformUsersSuccess(cleanedArray)
                )
            } else {
                yield put(successActions.searchPlatformUsersSuccess(undefined))
            }

            posthog.capture('SEARCH USERS IN DIRECT SHARES')
        } else {
            yield put(successActions.searchPlatformUsersSuccess(undefined))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetSearchedUsersSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'Please try again',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.searchPlatformUsersError(customError))
        }
    }
}

function* watcherGetSearchedUsers() {
    while (true) {
        const { payload } = yield take(
            loadingActions.searchPlatformUsersRequest
        )

        yield call(GetSearchedUsersSaga, payload)
    }
}

const get_search_users: any[] = [fork(watcherGetSearchedUsers)]

export default get_search_users
