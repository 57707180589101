import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

// import Loader from "../atoms/loader/loader";

import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

import Faded from '../../templates/animated/faded'
import {
    deleteTaskRequest,
    getTaskByIdRequest,
    getTaskRemindersRequest,
    getTasksByCarIdRequest,
    getTasksByGarageIdRequest,
    updateTaskRequest,
} from '../../../redux/tasks/actions/requestActions'

import TaskInfoTableMobile from '../../molecules/tasks/taskInfoTable/taskInfoTableMobile'

import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import {
    ICarsState,
    IDeleteTaskPayload,
    IGarageObject,
    IList,
    ITask,
    ITasksState,
    IUpdateTaskReq,
} from 'entityModels'

import TaskHeader from '../../atoms/typography/taskHeader'

import SectionHeader from '../../atoms/header/section/sectionHeader'
import SideSlider from '../../templates/animated/sideSlider'
import TaskCarHeaderDesktop from '../../atoms/typography/taskCarHeaderDesktop'

import TaskInfoTableDesktop from '../../molecules/tasks/taskInfoTable/taskInfoTableDesktop'
import { editFormsActions } from '../../../redux/editForms/reducer'
import Icon from '../../atoms/icons'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'

import TaskFormManagerDesktop from '../../organisms/editForms/taskFormManager/manager/taskFormManagerDesktop'

import TaskFormManagerMobile from '../../organisms/editForms/taskFormManager/manager/taskFormManagerMobile'
import red_btn_bin from '../../../public/assets/icons/red_btn_bin.svg'
import AddInfoBannerMobile from '../../atoms/Button/addInfoBannerMobile'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'

import { OOtherTaskOptions } from '../../../ol/ol'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'

import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import PortalInvisibleWrapper from '../../templates/displays/pageWrappers/portalInvisibleWrapper'
import TaskReminders from './taskReminders'

import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { IUser } from 'myModels'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import parse from 'html-react-parser'
import { IGetTaskRemindersReq } from '../../../redux/tasks/sagas/getTaskReminders'
import ThreeDotsIcon from '../../atoms/icons/components/threeDots'
import ReturnNavV2 from '../../atoms/header/returnNavV2'
import TasksSubMenuActions from '../../atoms/menu/pageSubMenuActions/tasksSubMenuActions'
import colours from '../../../providers/theme/colours'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import AddIcon from '../../atoms/icons/components/addIcon'
import ChevronLeft from '../../atoms/icons/components/chevronLeft'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        min-height: 100vh;
        background-color: var(--off-bg-color, #fafafa);
        transform: translateY(-20px);
    }

    @media ${device.mobile} {
    }
`

const PaddingSideMobile = styled.section`
    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }

    @media ${device.ipad} {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
    }
`

const TaskDateMobile = styled.section`
    color: var(--text-strong, #1a1a1a);
    @media ${device.mobile} {
        width: 100%;
        text-align: right;
        font-size: 12px;
        font-family: Lato-Italic;
    }
`

const WrapperNotesDesktop = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    height: auto;
    width: 100%;
    background-color: var(--bg-color, #fff);
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    padding-right: 100px;
    padding-left: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    p,
    h1,
    h2,
    li {
        color: var(--text-darker, #616161);
    }
`

const WrapperNotesMobile = styled.section`
    width: 100%;
    p,
    h1,
    h2,
    li {
        color: var(--text-darker, #616161);
    }
`

const EditRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
`

const RowTopDesktopBtns = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const CustomReturnDesktop = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding-left: 32px;
    width: 196px;
    height: 52px;
    color: var(--bg-color, #fff);
    background-color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 22px;
    border-radius: 10px;

    transition: all 500ms;

    :hover {
        background-color: var(--primary, #5ec3ca);
        color: var(--bg-color, #fff);
        transition: all 500ms;
    }
`

const CustomDeleteBtnDesktop = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 196px;
    height: 52px;
    background-color: var(--off-bg-color, #fafafa);
    border: 1px solid var(--error, #df6f6f);
    font-family: Lato-Light;
    font-size: 18px;
    border-radius: 10px;
    color: var(--error, #df6f6f) !important;

    transition: all 500ms;
    cursor: pointer;

    :hover {
        background-color: var(--error_08);
        transition: all 500ms;
    }
`

const ThreeDotsContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    padding-right: 24px;
    padding-left: 24px;
    padding-top: 18px;
    height: 64px;
    z-index: 6;
`

const GoToBox = styled.div`
    :hover {
        border: 1px solid var(--primary, #5ec3ca);
        transform: scale(1.015);
    }

    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
    background-color: var(--primary_16);
`

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getTasksByGarageIdRequest: (garageID: string) =>
        getTasksByGarageIdRequest(garageID),
    getTasksByCarIdRequest: (carID: string) => getTasksByCarIdRequest(carID),

    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),

    getTaskByIdRequest: (id: string) => getTaskByIdRequest(id),

    updateTaskRequest: (payload: IUpdateTaskReq) => updateTaskRequest(payload),
    toggleUpdateTaskForm: (item?: IList) =>
        editFormsActions.toggleUpdateTaskForm(item),

    deleteTaskRequest: (payload: IDeleteTaskPayload) =>
        deleteTaskRequest(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    getTaskRemindersRequest: (p: IGetTaskRemindersReq) =>
        getTaskRemindersRequest(p),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        all_tasks: state.tasks,
        // to later match with task ids lists
        carsData: state.entities.carsData,
        garagesData: state.entities.garagesData.garages,
        isUpdateTaskFormOpened: state.editForms.formsData.updateTaskForm.isOpen,
        item_to_edit: state.editForms.formsData.updateTaskForm.item,
        tasksLoading: state.tasks.loading,
        carLoading: state.entities.carsData.loading,
        garageLoading: state.entities.garagesData.loading,
        remindersLoading: state.tasks.isRemindersLoading,
    }
}

type State = {
    isMobileActionsSubMenuOpen: boolean
    showConfirmDeleteTaskSheet: boolean
}

interface Props extends RouteComponentProps<any> {
    userid: string | null
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    getTasksByGarageIdRequest: (garageID: string) => void
    all_tasks: ITasksState
    getTasksByCarIdRequest: (carID: string) => void
    carsData: ICarsState
    garagesData: IGarageObject
    getGarageCarsDataRequest: (id: string) => void
    getTaskByIdRequest: (id: string) => void
    updateTaskRequest: (payload: IUpdateTaskReq) => void
    toggleUpdateTaskForm: (item?: any) => void
    isUpdateTaskFormOpened: boolean
    item_to_edit: IList | undefined
    deleteTaskRequest: (payload: IDeleteTaskPayload) => void
    setInitialLandingUrl: (str: string) => void
    tasksLoading: boolean
    carsLoading: boolean
    garagesLoading: boolean
    remindersLoading: boolean | null | undefined
    getTaskRemindersRequest: (p: IGetTaskRemindersReq) => void
}

class SingleTask extends React.Component<Props, State> {
    state = {
        isMobileActionsSubMenuOpen: false,
        showConfirmDeleteTaskSheet: false,
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search)

        let taskid: string | null = params.get('taskid')

        taskid &&
            this.props.setInitialLandingUrl(`/tasks/task?taskid=${taskid}`)

        let userData: IUser | null = this.props.userLoggedIn
        let garage_id: string | null =
            userData && userData.owns_garage && userData.owns_garage.uid
        if (!userData) {
            this.props.getUserDataRequest()
        }
        // garage stuff

        if (garage_id) {
            this.props.getGarageCarsDataRequest(garage_id)
        }

        if (
            taskid &&
            this.props.userLoggedIn &&
            this.props.all_tasks &&
            this.props.all_tasks.tasks &&
            !this.props.all_tasks.tasks[taskid]
        ) {
            this.props.getTaskByIdRequest(taskid)
        }

        if (
            taskid &&
            this.props.userLoggedIn &&
            this.props.all_tasks &&
            this.props.all_tasks.tasks &&
            this.props.all_tasks.tasks[taskid] &&
            this.props.all_tasks.tasks[taskid].due &&
            this.props.all_tasks.tasks[taskid].has_reminders === true &&
            !this.props.all_tasks.tasks[taskid].reminders
        ) {
            this.props.getTaskRemindersRequest({
                taskUID: taskid,
                dueDate: this.props.all_tasks.tasks[taskid].due,
            })
        }

        let set_due_date: string | null = params.get('setduedate')
        if (set_due_date === 'true') {
            let taskid: string | null = params.get('taskid')

            let task: ITask | undefined =
                taskid &&
                this.props.userLoggedIn &&
                this.props.all_tasks &&
                this.props.all_tasks.tasks
                    ? this.props.all_tasks.tasks[taskid]
                    : undefined
            task &&
                this.props.toggleUpdateTaskForm({
                    id: 'due',
                    text: 'Due date',
                    answer: task.due,
                })
        }
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let userData: IUser | null = this.props.userLoggedIn
        const params = new URLSearchParams(this.props.location.search)

        let taskid: string | null = params.get('taskid')
        let garage_id: string | null =
            userData && userData.owns_garage && userData.owns_garage.uid

        if (userData !== prevProps.userLoggedIn && !prevProps.userLoggedIn) {
            if (userData) {
                if (garage_id && taskid) {
                    // garage stuff
                    this.props.getGarageCarsDataRequest(garage_id)
                    //  tasks stuff
                    return this.props.getTaskByIdRequest(taskid)
                }
            }
        }
    }

    handleActionsSubMenu = () => {
        this.setState({
            ...this.state,
            isMobileActionsSubMenuOpen: !this.state.isMobileActionsSubMenuOpen,
        })
    }

    render() {
        const {
            userLoggedIn,
            garagesData,
            all_tasks,
            carsData,
            toggleUpdateTaskForm,
            updateTaskRequest,
            isUpdateTaskFormOpened,
            item_to_edit,
            deleteTaskRequest,
            carsLoading,
            tasksLoading,
            garagesLoading,
            remindersLoading,
        } = this.props

        const params = new URLSearchParams(this.props.location.search)

        let taskid: string | null = params.get('taskid')

        let task: ITask | undefined =
            all_tasks && all_tasks.tasks && taskid && all_tasks.tasks[taskid]
                ? all_tasks.tasks[taskid]
                : undefined

        let garageid =
            userLoggedIn &&
            userLoggedIn.owns_garage &&
            userLoggedIn.owns_garage.uid

        let garageData = garageid && garagesData && garagesData[garageid]

        let numberOfCars: number =
            carsData && carsData.cars ? Object.entries(carsData.cars).length : 0

        let generateLinkTitle = (): string[] => {
            let res: string[] = []
            let resFinal: string[] = []

            if (task && task.linkedToCar) {
                task.linkedToCar.forEach((carid: string, index: number) => {
                    if (carsData && carsData.cars && carid) {
                        carsData.cars[carid] &&
                            carsData.cars[carid].title &&
                            res.push(carsData.cars[carid].title)
                    }
                })
                if (res.length > 1 && res.length === numberOfCars) {
                    resFinal = ['Every Car']
                } else resFinal = [...res]
            } else {
                resFinal = [OOtherTaskOptions.general_task.name]
            }
            return resFinal
        }

        let user_prefered_timezone: string | null =
            userLoggedIn &&
            userLoggedIn.preferences &&
            userLoggedIn.preferences.data &&
            userLoggedIn.preferences.data['notifications'] &&
            userLoggedIn.preferences.data['notifications'].data &&
            userLoggedIn.preferences.data['notifications'].data['time_zone'] &&
            userLoggedIn.preferences.data['notifications'].data['time_zone']
                .user_choice_id

        let isDataLoading =
            tasksLoading || carsLoading || garagesLoading || remindersLoading
                ? true
                : false

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        <Wrapper>
                            <CenteredPageWrapper>
                                {userLoggedIn &&
                                    user_prefered_timezone &&
                                    garageid &&
                                    taskid &&
                                    all_tasks &&
                                    garagesData &&
                                    garageData &&
                                    garageData.cars && (
                                        <Faded>
                                            {isDataLoading && (
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        height: '50vh',
                                                    }}
                                                >
                                                    <AdjustableLoader
                                                        isLoading={
                                                            isDataLoading
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {task && !isDataLoading && (
                                                <DesktopDisplayOnly>
                                                    <RowTopDesktopBtns>
                                                        <CustomReturnDesktop
                                                            to={{
                                                                pathname: `/tasks`,
                                                                state: {
                                                                    prevPath:
                                                                        this
                                                                            .props
                                                                            .match
                                                                            .url,
                                                                },
                                                            }}
                                                        >
                                                            <ChevronLeft
                                                                color={
                                                                    colours[
                                                                        theme
                                                                    ]
                                                                        .background_default
                                                                }
                                                            />

                                                            <div
                                                                style={{
                                                                    paddingLeft:
                                                                        '12px',
                                                                }}
                                                            />

                                                            <div
                                                                style={{
                                                                    transform:
                                                                        'translateY(-1px)',
                                                                }}
                                                            >
                                                                all tasks
                                                            </div>
                                                        </CustomReturnDesktop>

                                                        <CustomDeleteBtnDesktop
                                                            onClick={() => {
                                                                task &&
                                                                    deleteTaskRequest(
                                                                        {
                                                                            task: task,
                                                                        }
                                                                    )
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    red_btn_bin
                                                                }
                                                                alt="delete"
                                                                style={{
                                                                    height: '16px',
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    paddingLeft:
                                                                        '12px',
                                                                }}
                                                            />

                                                            <div
                                                                style={{
                                                                    transform:
                                                                        'translateY(-2px)',
                                                                    fontFamily:
                                                                        'lato-light',
                                                                }}
                                                            >
                                                                remove
                                                            </div>
                                                        </CustomDeleteBtnDesktop>
                                                    </RowTopDesktopBtns>

                                                    <div
                                                        style={{
                                                            paddingTop: '50px',
                                                        }}
                                                    />
                                                    <EditRow>
                                                        <TaskHeader>
                                                            {task.title}
                                                        </TaskHeader>

                                                        <div
                                                            style={{
                                                                paddingLeft:
                                                                    '20px',
                                                                transform:
                                                                    'translateY(-5px)',
                                                            }}
                                                            onClick={() => {
                                                                task &&
                                                                    toggleUpdateTaskForm(
                                                                        {
                                                                            id: 'title',
                                                                            text: 'Edit task title',
                                                                            answer: task.title,
                                                                        }
                                                                    )
                                                            }}
                                                        >
                                                            <Icon
                                                                height="20px"
                                                                icon="edit_pen"
                                                            />
                                                        </div>
                                                    </EditRow>

                                                    <div
                                                        style={{
                                                            paddingTop: '10px',
                                                        }}
                                                    />

                                                    <EditRow>
                                                        <TaskCarHeaderDesktop
                                                            answers={
                                                                task.linkedToCar
                                                                    ? generateLinkTitle()
                                                                    : [
                                                                          OOtherTaskOptions
                                                                              .general_task
                                                                              .name,
                                                                      ]
                                                            }
                                                            carids={
                                                                task.linkedToCar
                                                            }
                                                            garageid={garageid}
                                                        />

                                                        <div
                                                            style={{
                                                                paddingLeft:
                                                                    '10px',
                                                                transform:
                                                                    'translateY(-5px)',
                                                            }}
                                                            onClick={() => {
                                                                task &&
                                                                    toggleUpdateTaskForm(
                                                                        {
                                                                            id: 'linkedToCar',
                                                                            text: 'Update what this Task is applied to:',
                                                                            answer: task.linkedToCar,
                                                                        }
                                                                    )
                                                            }}
                                                        >
                                                            <Icon
                                                                height="20px"
                                                                icon="edit_pen"
                                                            />
                                                        </div>
                                                    </EditRow>

                                                    <div
                                                        style={{
                                                            paddingTop: '60px',
                                                        }}
                                                    />
                                                    <TaskInfoTableDesktop
                                                        task={task}
                                                        carsData={carsData.cars}
                                                        garageId={garageid}
                                                        updateTask={
                                                            updateTaskRequest
                                                        }
                                                        toggleUpdateTaskForm={
                                                            toggleUpdateTaskForm
                                                        }
                                                    />
                                                    <div
                                                        style={{
                                                            paddingTop: '20px',
                                                        }}
                                                    />

                                                    <SectionHeader
                                                        title="Notepad"
                                                        openForm={() => {
                                                            window.scrollTo(
                                                                0,
                                                                0
                                                            )
                                                            task?.body &&
                                                                toggleUpdateTaskForm(
                                                                    {
                                                                        id: 'body',
                                                                        text: 'notes',
                                                                        answer: task.body,
                                                                    }
                                                                )
                                                        }}
                                                    />

                                                    {task?.body ? (
                                                        <WrapperNotesDesktop>
                                                            <span
                                                                style={{
                                                                    maxWidth:
                                                                        '950px',
                                                                }}
                                                            >
                                                                {task?.body
                                                                    ? parse(
                                                                          task.body
                                                                      )
                                                                    : ''}
                                                            </span>
                                                        </WrapperNotesDesktop>
                                                    ) : (
                                                        <GoToBox
                                                            onClick={() => {
                                                                window.scrollTo(
                                                                    0,
                                                                    0
                                                                )
                                                                toggleUpdateTaskForm(
                                                                    {
                                                                        id: 'body',
                                                                        text: 'notes',
                                                                        answer: task?.body,
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <AddIcon
                                                                colour={
                                                                    colours[
                                                                        theme
                                                                    ].primary
                                                                }
                                                            />
                                                        </GoToBox>
                                                    )}

                                                    {isUpdateTaskFormOpened && (
                                                        <Faded>
                                                            <ModalDisplay
                                                                toggle={
                                                                    toggleUpdateTaskForm
                                                                }
                                                                isOpen={true}
                                                            >
                                                                <TaskFormManagerDesktop
                                                                    item={
                                                                        item_to_edit
                                                                    }
                                                                    toggle={
                                                                        toggleUpdateTaskForm
                                                                    }
                                                                    task={task}
                                                                    updateTask={
                                                                        updateTaskRequest
                                                                    }
                                                                    carsData={
                                                                        carsData.cars
                                                                    }
                                                                    isEveryCar={
                                                                        generateLinkTitle()[0] ===
                                                                        'Every Car'
                                                                    }
                                                                    timezone={
                                                                        user_prefered_timezone
                                                                    }
                                                                />
                                                            </ModalDisplay>
                                                        </Faded>
                                                    )}
                                                    {params.get(
                                                        'reminders'
                                                    ) && (
                                                        <PortalInvisibleWrapper
                                                            onClick={() => {
                                                                this.props.history.push(
                                                                    this.props
                                                                        .location
                                                                        .pathname
                                                                )
                                                            }}
                                                            customZindex={5}
                                                        >
                                                            <TaskReminders />
                                                        </PortalInvisibleWrapper>
                                                    )}
                                                </DesktopDisplayOnly>
                                            )}
                                            <IpadAndMobileDisplay>
                                                <Link
                                                    to={{
                                                        pathname: `/tasks`,
                                                        state: {
                                                            prevPath:
                                                                this.props.match
                                                                    .url,
                                                        },
                                                    }}
                                                >
                                                    <ReturnNavV2 customText="Go back" />
                                                </Link>
                                                <ThreeDotsContainer
                                                    onClick={
                                                        this
                                                            .handleActionsSubMenu
                                                    }
                                                >
                                                    <ThreeDotsIcon />
                                                </ThreeDotsContainer>

                                                {task && !isDataLoading && (
                                                    <SideSlider
                                                        direction="right"
                                                        initial="enter"
                                                    >
                                                        <PaddingSideMobile>
                                                            <TaskHeader
                                                                onClick={() => {
                                                                    task &&
                                                                        toggleUpdateTaskForm(
                                                                            {
                                                                                id: 'title',
                                                                                text: 'Edit task title',
                                                                                answer: task.title,
                                                                            }
                                                                        )
                                                                }}
                                                            >
                                                                {task.title}
                                                            </TaskHeader>

                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '20px',
                                                                }}
                                                            />

                                                            <TaskInfoTableMobile
                                                                task={task}
                                                                carsData={
                                                                    carsData.cars
                                                                }
                                                                garageId={
                                                                    garageid
                                                                }
                                                                openUpdateTaskForm={
                                                                    toggleUpdateTaskForm
                                                                }
                                                                updateTask={
                                                                    updateTaskRequest
                                                                }
                                                                isEveryCar={
                                                                    generateLinkTitle()[0] ===
                                                                    'Every Car'
                                                                }
                                                            />

                                                            <SectionHeader
                                                                title="Notepad"
                                                                openForm={() => {
                                                                    task &&
                                                                        toggleUpdateTaskForm(
                                                                            {
                                                                                id: 'body',
                                                                                text: 'notes',
                                                                                answer: task?.body,
                                                                            }
                                                                        )
                                                                }}
                                                            />

                                                            {!task.body && (
                                                                <div
                                                                    style={{
                                                                        transform:
                                                                            'translateY(-20px)',
                                                                        paddingBottom:
                                                                            '10px',
                                                                    }}
                                                                >
                                                                    <AddInfoBannerMobile
                                                                        onClick={() => {
                                                                            task &&
                                                                                toggleUpdateTaskForm(
                                                                                    {
                                                                                        id: 'body',
                                                                                        text: 'notes',
                                                                                        answer: task.body,
                                                                                    }
                                                                                )
                                                                        }}
                                                                        height="90px"
                                                                        action="add"
                                                                    />
                                                                </div>
                                                            )}
                                                            <WrapperNotesMobile>
                                                                {task.body &&
                                                                    parse(
                                                                        task.body
                                                                    )}
                                                                {task.body && (
                                                                    <div
                                                                        style={{
                                                                            paddingTop:
                                                                                '20px',
                                                                        }}
                                                                    />
                                                                )}
                                                            </WrapperNotesMobile>

                                                            {task.body && (
                                                                <div
                                                                    style={{
                                                                        paddingTop:
                                                                            '20px',
                                                                    }}
                                                                />
                                                            )}
                                                            <TaskDateMobile>
                                                                created on{' '}
                                                                {fromIsoStringToDDMMYYY(
                                                                    task.created_at
                                                                )}
                                                            </TaskDateMobile>

                                                            <div
                                                                style={{
                                                                    paddingTop:
                                                                        '50px',
                                                                }}
                                                            />
                                                            {/* 
                                                <ButtonAtom
                                                    theme="flexible-blue-on-white"
                                                    icon="calendar"
                                                    width="160px"
                                                    height="48px"
                                                    fontSize={14}
                                                >
                                                    set a reminder
                                                </ButtonAtom>
                                                <div
                                                    style={{
                                                        paddingTop: '16px',
                                                    }}
                                                /> */}

                                                            {/* <CenteredWrapMobile>
                                                    <CustomDeleteBtnMobile
                                                        onClick={() => {
                                                            deleteTaskRequest({
                                                                task_id: taskid,
                                                            })
                                                        }}
                                                    >
                                                        Delete
                                                    </CustomDeleteBtnMobile>
                                                </CenteredWrapMobile> */}
                                                            <TasksSubMenuActions
                                                                active_item=""
                                                                items={[
                                                                    {
                                                                        id: 'delete',
                                                                        text: 'Delete task',
                                                                        action: () =>
                                                                            this.setState(
                                                                                {
                                                                                    ...this
                                                                                        .state,
                                                                                    showConfirmDeleteTaskSheet:
                                                                                        true,
                                                                                }
                                                                            ),
                                                                    },
                                                                ]}
                                                                toggle={
                                                                    this
                                                                        .handleActionsSubMenu
                                                                }
                                                                isOpen={
                                                                    this.state
                                                                        .isMobileActionsSubMenuOpen
                                                                }
                                                            />
                                                        </PaddingSideMobile>
                                                        <DeletionConfirmationBottomSheet
                                                            isOpen={
                                                                this.state
                                                                    .showConfirmDeleteTaskSheet
                                                            }
                                                            toggle={() =>
                                                                this.setState({
                                                                    ...this
                                                                        .state,
                                                                    showConfirmDeleteTaskSheet:
                                                                        false,
                                                                })
                                                            }
                                                            action={() => {
                                                                task &&
                                                                    deleteTaskRequest(
                                                                        {
                                                                            task: task,
                                                                        }
                                                                    )
                                                            }}
                                                            actionCopy="Confirm deletion"
                                                            titleCopy="Are you sure?"
                                                            detailsCopy="On deletion, the data will be lost."
                                                        />
                                                    </SideSlider>
                                                )}

                                                {isUpdateTaskFormOpened &&
                                                    task && (
                                                        <Faded>
                                                            <ModalDisplay
                                                                toggle={
                                                                    toggleUpdateTaskForm
                                                                }
                                                                isOpen={true}
                                                                customZindex={
                                                                    10
                                                                }
                                                            >
                                                                <TaskFormManagerMobile
                                                                    item={
                                                                        item_to_edit
                                                                    }
                                                                    toggle={
                                                                        toggleUpdateTaskForm
                                                                    }
                                                                    task={task}
                                                                    updateTask={
                                                                        updateTaskRequest
                                                                    }
                                                                    carsData={
                                                                        carsData.cars
                                                                    }
                                                                    isEveryCar={
                                                                        generateLinkTitle()[0] ===
                                                                        'Every Car'
                                                                    }
                                                                    timezone={
                                                                        user_prefered_timezone
                                                                    }
                                                                />
                                                            </ModalDisplay>
                                                        </Faded>
                                                    )}
                                            </IpadAndMobileDisplay>
                                        </Faded>
                                    )}
                            </CenteredPageWrapper>
                        </Wrapper>
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(SingleTask))
