type Props = {
    width?: string
    height?: string
    color?: string
}

const LinkIcon = ({ width, height, color }: Props) => {
    return (
        <svg
            width={width ?? '25'}
            height={height ?? '25'}
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6832 10.7195C9.07329 10.7195 7.79688 11.9915 7.79688 13.525V17.0806C7.79688 18.6141 9.07329 19.8861 10.6832 19.8861H18.8651C20.475 19.8861 21.7514 18.6141 21.7514 17.0806V13.525C21.7514 12.6931 21.3795 11.9427 20.7798 11.4254L20.2118 10.9356L21.1915 9.7997L21.7594 10.2895C22.6706 11.0754 23.2514 12.2325 23.2514 13.525V17.0806C23.2514 19.4742 21.2715 21.3861 18.8651 21.3861H10.6832C8.27682 21.3861 6.29688 19.4742 6.29688 17.0806V13.525C6.29688 11.1315 8.27682 9.21948 10.6832 9.21948H15.0696V10.7195H10.6832Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.13636 5.38623C4.52642 5.38623 3.25 6.65824 3.25 8.19179V11.7473C3.25 12.5793 3.62195 13.3297 4.22165 13.847L4.7896 14.3368L3.80993 15.4727L3.24198 14.9828C2.33078 14.197 1.75 13.0399 1.75 11.7473V8.19179C1.75 5.79822 3.72995 3.88623 6.13636 3.88623H14.3182C16.7246 3.88623 18.7045 5.79822 18.7045 8.19179V11.7473C18.7045 14.1409 16.7246 16.0529 14.3182 16.0529H9.93182V14.5529H14.3182C15.9281 14.5529 17.2045 13.2809 17.2045 11.7473V8.19179C17.2045 6.65824 15.9281 5.38623 14.3182 5.38623H6.13636Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default LinkIcon
