import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/pastCars/1.png'
import image_mobile from '../../../../public/assets/images/journey/pastCars/mobile_memories.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import { device } from '../../../templates/displays/devices'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export const ParagraphTitleJourney = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

const ImageDesktop = styled.div`
    width: 100%;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const CenterImg = styled.div`
    align-self: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.ipad} {
        justify-content: flex-start;
        align-items: flex-start;
    }
`

export default function MemoriesPastCarsJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section['past_cars']
                                        .primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[`past_cars`]
                                        .primary_100
                                }
                            />

                            <JourneyColumnContent style={{ gap: 16 }}>
                                <StandardCtaBtn
                                    bg="transparent"
                                    customWidth="auto"
                                    customHeight="auto"
                                    removePaddings
                                    removeShadowOnHover
                                    onClick={goToPrevStep}
                                >
                                    <JourneyBtnContent $theme={theme}>
                                        <div
                                            style={{
                                                transform: 'rotate(90deg)',
                                            }}
                                        >
                                            <ChevronDown />
                                        </div>
                                        Back
                                    </JourneyBtnContent>
                                </StandardCtaBtn>
                                <JourneyStepTitle $theme={theme}>
                                    A place for your previous car ownership
                                </JourneyStepTitle>
                                <JourneyColumnContent>
                                    <JourneyText
                                        $theme={theme}
                                        style={{ fontSize: '16px' }}
                                    >
                                        Our platform isn't just about the now —
                                        it's also a place to cherish the cars
                                        you've loved and owned in the past.
                                        Explore the "Previously Owned" tab in
                                        your garage to create a timeless
                                        collection of your past automotive
                                        adventures.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyColumnContent style={{ gap: 16 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <ParagraphTitleJourney>
                                        A Place for Your Memories
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                        }}
                                    >
                                        Take a stroll down memory lane as you
                                        reminisce about the cars that have been
                                        part of your automotive journey. From
                                        your first ride to your most cherished
                                        vehicles, every car has a story to tell.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <ParagraphTitleJourney>
                                        Archiving Your Past
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                        }}
                                    >
                                        When a car is no longer in your
                                        possession, archive it in your garage to
                                        preserve its memory. By archiving your
                                        past cars, you create a journal of your
                                        automotive history—a testament to your
                                        passion for cars and the memories they
                                        hold.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <ParagraphTitleJourney>
                                        Revisiting Your Automotive Adventures
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                        }}
                                    >
                                        With just a click, you can revisit the
                                        details of your past cars, reliving the
                                        moments and experiences you experienced
                                        together. Every car added to your
                                        "Previously Owned" collection is a
                                        chapter in your automotive story,
                                        waiting to be revisited whenever it
                                        takes your fancy.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <ParagraphTitleJourney>
                                        Start Building Your Automotive Legacy
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                        }}
                                    >
                                        Don't let your past cars fade into
                                        oblivion. Add them to your Custodian
                                        garage and create a legacy of automotive
                                        memories that will last a lifetime.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'past_cars'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'past_cars'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <ImageDesktop>
                                <FadedSlower>
                                    <img
                                        src={image}
                                        placeholder={grey_placeholder}
                                        style={{
                                            objectFit: 'contain',
                                            maxHeight: '95dvh',
                                            minHeight: '80dvh',
                                            alignSelf: 'center',
                                            transform: 'translate(30px, 30px)',
                                        }}
                                    />
                                </FadedSlower>
                            </ImageDesktop>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <JourneyPageWrapperMobile
                        allowScroll={true}
                        title={'A Place for Your Memories'}
                        sectionID="past_cars"
                        subtitle={`Don't forget your past cars`}
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={() => {
                            goToPrevStep()
                        }}
                        onNext={() => {
                            goToNextStep()
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 24 }}>
                            <div
                                style={{
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                }}
                            >
                                <JourneyColumnContent>
                                    <JourneyText
                                        $theme={theme}
                                        style={{ fontSize: '16px' }}
                                    >
                                        Delve into the memories of your
                                        automotive journey with Custodian! Our
                                        platform isn't just about your current
                                        rides—it's also a place to cherish the
                                        cars you've owned in the past.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </div>

                            <CenterImg
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <FadedSlower>
                                    <img
                                        src={image_mobile}
                                        placeholder={grey_placeholder}
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '100vw',
                                            maxHeight: '80vh',
                                            alignSelf: 'center',
                                        }}
                                    />{' '}
                                </FadedSlower>
                            </CenterImg>

                            <JourneyColumnContent
                                style={{
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                    gap: 4,
                                }}
                            >
                                <JourneyText
                                    $theme={theme}
                                    style={{ fontSize: '16px' }}
                                >
                                    Take a stroll down memory lane as you
                                    reminisce about the cars that have been part
                                    of your journey. From your first ride to
                                    your most cherished vehicles, every car has
                                    a story to tell.
                                </JourneyText>

                                <div style={{ paddingTop: '80px' }} />
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </JourneyPageWrapperMobile>
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
