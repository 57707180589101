import React from 'react'
// import debounce from "lodash/debounce";
import Fuse from 'fuse.js'

import { debounce } from 'lodash'

import './styles.css'
import TimelineSearchBarMobileChild from './timelineSearchBarMobileChild'

// import { IDropdownItem } from 'entityModels'

export type Props = {
    category_searchable_items_list: any
    user_searchable_labels_list: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    addCriteria?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    clearEntryTitleSearch: any
    finalSearchToApi: any
    currentSearch: string | undefined
    checkIfSelectedEntryOrLabel: any
}

// not sure why now threw error. Removed specifications and error free.
// keeping this here in case .

// export type ItemsFuse = {
//   id: string;
//   value: string;
// };

// const options: Fuse.FuseOptions<ItemsFuse> = {
//   tokenize: true,
//   keys: ["value"]
// };

type LocalState = {
    data: any[]
    categoryData: any[]
    labelsData: any[]
    name: any[]
    fetching: boolean
}

class TimelineSearchBarMobile extends React.Component<Props, LocalState> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        this.fetchItem = debounce(this.fetchItem, 180)
    }

    state = {
        data: [],
        categoryData: [],
        labelsData: [],
        name: [],
        fetching: false,
    }

    lastFetchId = 0

    fetchItem = (name: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
        }

        const fuseCategory = new Fuse(
            this.props.category_searchable_items_list,
            {
                keys: ['name'],
            }
        )

        const resultsCategory = fuseCategory.search(name, { limit: 2 })

        this.setState({
            ...this.state,
            categoryData: resultsCategory,
            fetching: false,
        })

        const fuseLabels = new Fuse(this.props.user_searchable_labels_list, {
            keys: ['name'],
        })

        const resultsLabel = fuseLabels.search(name, { limit: 2 })

        this.setState({
            ...this.state,
            labelsData: resultsLabel,
            fetching: false,
        })

        return
    }

    handleChange = (name: any) => {
        this.setState({
            ...this.state,
            name: [name],
            data: [],
            categoryData: [],
            labelsData: [],
            fetching: false,
        })
        //this.props.handleSelectChange && this.props.handleSelectChange(name)
    }

    render() {
        const {
            //fetching,
            name,
            labelsData,
            categoryData,
            // data
        } = this.state
        const {
            //           allowAdd,
            //           isDisabled,
            //           placeholder,
            // addCategoryFilter,
            // addTagFilter,
            dataCyId,
            manageissearchactive,
            addCriteria,
            issearchactive,
            clearEntryTitleSearch,
            finalSearchToApi,
            currentSearch,
            checkIfSelectedEntryOrLabel,
        } = this.props

        return (
            <TimelineSearchBarMobileChild
                manageissearchactive={manageissearchactive}
                dataCyId={dataCyId}
                issearchactive={issearchactive}
                value={name}
                handleChange={this.handleChange}
                fetchItem={this.fetchItem}
                categoryData={categoryData}
                labelsData={labelsData}
                clearEntryTitleSearch={clearEntryTitleSearch}
                addCriteria={(name: string) => addCriteria(name)}
                finalSearchToApi={finalSearchToApi}
                currentSearch={currentSearch}
                checkIfSelectedEntryOrLabel={checkIfSelectedEntryOrLabel}
            />
        )
    }
}

export default TimelineSearchBarMobile
