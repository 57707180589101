import styled from 'styled-components'
import { numberWithCommas } from '../../../../helpers/numbers'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type StyledProps = {
    height?: string
    $theme: ITheme
}
const Container = styled.div<StyledProps>`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
`

const Title = styled.span<StyledProps>`
    color: ${(props) => colours[props.$theme].text_strong};
    text-transform: capitalize;
    font-family: 'Lato';
    font-size: 11px;
    letter-spacing: 0.7px;
`

const CurrentMileage = styled.span<StyledProps>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: 'Lato';
    font-size: 11px;
    letter-spacing: 0.7px;
    text-transform: lowercase;
`

const MileageSince = styled.span<StyledProps>`
    color: ${(props) => colours[props.$theme].primary};
    font-family: 'Lato-bold';
    font-size: 11px;
    letter-spacing: 0.7px;
`

type Props = {
    dataCyId?: string
    height?: string
    mileageRecorded: number | undefined
    mileageSince: number | undefined
    unit: string | null
    hasSampleData?: boolean
}

export default function MileageNakedTagMobile(props: Props) {
    const {
        dataCyId,
        height,
        mileageRecorded,
        mileageSince,
        unit,
        hasSampleData,
    } = props

    const { theme } = useThemes()

    return (
        <Container
            data-attr={dataCyId && dataCyId}
            height={height}
            $theme={theme}
        >
            <Title $theme={theme}>Mileage:</Title>
            <CurrentMileage $theme={theme}>
                {hasSampleData
                    ? `3500 ${unit && unit === 'kilometers' ? 'km' : unit}`
                    : mileageRecorded
                    ? `${numberWithCommas({
                          numberToConvert: mileageRecorded,
                          decimals: 0,
                      })}  ${unit && unit === 'kilometers' ? 'km' : unit}`
                    : 'unknown'}
            </CurrentMileage>
            <MileageSince $theme={theme}>
                {hasSampleData
                    ? `+37`
                    : mileageSince !== undefined
                    ? `+${numberWithCommas({
                          numberToConvert: mileageSince,
                          decimals: 0,
                          round: 'ceil',
                      })} `
                    : ''}
            </MileageSince>
        </Container>
    )
}
