import styled from 'styled-components'
import React from 'react'
import { device } from '../../templates/displays/devices'
import three_dots_black_vertical from '../../../public/assets/icons/three_dots_black_vertical.svg'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import BorderLowerCaseButton from '../Button/borderLowerCaseButton'
import ChevronLeft from '../icons/components/chevronLeft'

type IStyledProps = {
    customTextColor?: string | undefined
    isUppercase?: boolean | undefined
    chevron?: boolean | undefined
    color?: string
    $noBoxShadow?: boolean
}

const Wrapper = styled.div<IStyledProps>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding-right: 20px;
    padding-left: 20px; */
    box-shadow: ${(props) =>
        props.$noBoxShadow ? 'none' : '0px 3px 6px -2px rgba(0, 0, 0, 0.2)'};

    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    position: fixed;
    /* z-index: 5; */
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    z-index: 2;

    @media ${device.desktop} {
        display: none;
    }
`

const NavCaption = styled(Link)<IStyledProps>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
`

const NavCaptionClick = styled.div<IStyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    width: 100%;
`

const Return = styled.div<IStyledProps>`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    justify-content: center;
    text-transform: ${(props) =>
        props.isUppercase ? 'uppercase' : 'capitalize'};
    font-weight: 200;
    font-size: ${(props) => (props.isUppercase ? '14px' : '15px')};
    letter-spacing: 1px;
    max-width: 80%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    @media ${device.desktop} {
        display: none;
    }
`

const ThreeDotsContainer = styled.div`
    position: absolute;
    right: 2vw;
    z-index: 5;
`

const ThreeDots = styled.img`
    @media ${device.mobile} {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }

    @media ${device.ipad} {
        width: 26px;
        height: 26px;
        margin-right: 10px;
    }

    @media ${device.desktop} {
        display: none;
    }
`

interface Props extends RouteComponentProps<any> {
    url?: string
    customText?: string
    customTextColor?: string
    isUppercase?: boolean
    chevron?: boolean
    backgroud_color?: string
    linkTo?: any
    params?: string | undefined
    threeDots?: boolean
    onThreeDotsClick?: any
    onChevronClick?: any
    dataCyIdChevron?: string
    dataCyIdThreeDots?: string
    hasQuit?: boolean
    onQuit?: any
    noBoxShadow?: boolean
}

const PrivateShareReturnNav: React.FunctionComponent<Props> = (props) => {
    return (
        <Wrapper $noBoxShadow={props.noBoxShadow}>
            {props.onChevronClick ? (
                <NavCaptionClick
                    data-attr={props.dataCyIdChevron}
                    onClick={props.onChevronClick}
                >
                    <ChevronLeft />
                    <div style={{ paddingRight: 8 }} />
                    <Return
                        customTextColor={props.customTextColor}
                        isUppercase={props.isUppercase}
                    >
                        {props.customText ? props.customText : 'Previous'}
                    </Return>
                </NavCaptionClick>
            ) : (
                <NavCaption
                    to={{
                        pathname: props.linkTo,
                        state: {
                            prevPath: props.history.location.pathname,
                        },
                        search: props.params,
                    }}
                    data-attr={props.dataCyIdChevron}
                >
                    <ChevronLeft /> <div style={{ paddingRight: 8 }} />
                    <Return
                        customTextColor={props.customTextColor}
                        isUppercase={props.isUppercase}
                    >
                        {props.customText ? props.customText : 'Previous'}
                    </Return>
                </NavCaption>
            )}

            {props.threeDots && (
                <ThreeDotsContainer
                    onClick={props.onThreeDotsClick}
                    data-attr={props.dataCyIdThreeDots}
                >
                    <ThreeDots src={three_dots_black_vertical} />
                </ThreeDotsContainer>
            )}
            {props.hasQuit && (
                <div style={{ paddingRight: '25px' }}>
                    <BorderLowerCaseButton text="exit" onClick={props.onQuit} />
                </div>
            )}
        </Wrapper>
    )
}

export default withRouter(PrivateShareReturnNav)
