import styled from 'styled-components'
import React from 'react'
import circlepccc from '../../../public/assets/logo/circlepcc.svg'
import circlepccc_dark from '../../../public/assets/logo/circlepcc_dark.svg'
import { device } from '../../templates/displays/devices'
import { Link } from 'react-router-dom'
import useThemes from '../../../providers/theme/hooks'

type StyledProps = {
    isNarrow?: boolean
}

const HeaderWrapper = styled.div`
    text-align: center;
    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }

    @media ${device.ipad} {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }

    @media ${device.desktop} {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        margin-top: 30px;
        z-index: 2;
    }
`

const Title = styled.h1`
    font-family: Lato-Light;
    justify-content: center;
    text-transform: uppercase;
    margin-bottom: 8px;
    font-weight: 200;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile} {
        margin-top: 10px;
        font-size: 16px;
    }

    @media ${device.desktop} {
        margin-top: 10px;
        font-size: 16px;
        letter-spacing: 2px;
    }

    @media ${device.ipad} {
        margin-top: 10px;
        font-size: 16px;
        letter-spacing: 2px;
    }
`

const Line = styled.div<StyledProps>`
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);

    @media ${device.mobile} {
        margin-top: 5px;
        width: 200px;
        margin-bottom: ${(props) => (props.isNarrow ? '1.5vh' : '5vh')};
    }

    @media ${device.desktop} {
        margin-top: 5px;
        width: 250px;
        margin-bottom: 3.5vh;
    }

    @media ${device.ipad} {
        margin-top: 5px;
        width: 250px;
        margin-bottom: 3vh;
    }
`

const CircleLogo = styled.img`
    @media ${device.mobile} {
        width: 35px;
        height: 35px;
    }

    @media ${device.desktop} {
        width: 35px;
        height: 35px;
    }

    @media ${device.ipad} {
        width: 35px;
        height: 35px;
    }
`

type ITriangleHeader = {
    title: null | string
    isNarrow?: boolean
}

const TriangleHeader: React.SFC<ITriangleHeader> = (props) => {
    const { theme } = useThemes()
    return (
        <HeaderWrapper>
            <Link to="/">
                <CircleLogo
                    src={theme === 'dark' ? circlepccc_dark : circlepccc}
                    alt="pcc-logo"
                />
            </Link>
            <Title> {props.title}</Title>
            <Line isNarrow={props.isNarrow ? props.isNarrow : false} />
        </HeaderWrapper>
    )
}

export default TriangleHeader
