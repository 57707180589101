import * as React from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    pdf,
} from '@react-pdf/renderer'
import saveAs from 'file-saver'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
    },
    section: {
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        height: '100%',
        width: '100%',
    },
    text: {
        margin: '0 auto',
        fontFamily: 'Helvetica',
        fontSize: 14,
        paddingTop: 30,
    },
    image: {
        width: '75mm',
        height: '75mm',
    },
})

type Props = {
    img_png: HTMLImageElement
    text: string
}

const PdfDocument: React.FunctionComponent<Props> = (props) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image style={styles.image} src={props.img_png.src} />
                    <Text style={styles.text}>{props.text} | 75mm</Text>
                </View>
            </Page>
        </Document>
    )
}

export const download_PDF_RawQRA4 = async (
    img: HTMLImageElement,
    title: string
) => {
    const doc = <PdfDocument img_png={img} text={title} />
    const asPdf = pdf(doc)
    const blob = await asPdf.toBlob()
    const fileName = `${title
        .toLowerCase()
        .replace(/ /g, '_')}_custodian_qr_code.pdf`
    saveAs(blob, fileName)
}
