import * as React from 'react'
import styled from 'styled-components'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import { motion } from 'framer-motion'
import NavDrawerBtn from '../../Button/navDrawerBtn'
import * as SvgIcons from '../../icons/components'
import { Link } from 'react-router-dom'
import CrossIcon from '../../icons/crossIcon'
import { socials } from '../../../templates/footer/showroomPublicFooter/showroomPublicFooterDesktop'
import posthog from 'posthog-js'
import { IUser } from 'myModels'
import { generateNoltToken } from '../../../templates/ideasButton/ideasButtonOnNav'
import NoltWidget from '../../../templates/widgets/noltWidget'
import ChevronDown from '../../icons/components/chevronDown'
import { device } from '../../../templates/displays/devices'
import { IFeatureFlagsReducer } from '../../../../redux/featureFlags/types'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'
import LeftAlignLogo from '../../icons/components/leftAlignLogo'

type IStyle = { $theme: ITheme }

const Wrapper = styled.div<IStyle>`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => colours[props.$theme].background_default};
    padding-bottom: 100px;
`

const FixedHeader = styled.div<IStyle>`
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${(props) => colours[props.$theme].background_default};
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    @media ${device.ipad} {
        height: 80px;
    }
`

const Padding = styled.div`
    padding-top: 80px;
    @media ${device.ipad} {
        padding-top: 88px;
    }
`

const RightArrowBtn = styled(motion.div)`
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    padding-top: 0px;
`

const SectionTitle = styled.div<IStyle>`
    width: 100%;
    padding-bottom: 16px;
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato-Semibold;
    font-size: 12px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
`

const SocialWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 72px;
    grid-gap: 8px;
`

const SocialHref = styled.a<IStyle>`
    display: flex;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
`

const GreenBox = styled.button<IStyle>`
    all: unset;
    position: relative;
    border-radius: 8px;
    padding: 16px 24px;
    width: 100%;
    background-color: #5dccd216;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const GreenBoxTitle = styled.div<IStyle>`
    font-family: Lato-semibold;
    font-size: 16px;
    padding-bottom: 8px;
    color: #4c9da4;
`
const GreenBoxContent = styled.div<IStyle>`
    font-family: Lato;
    font-size: 14px;
    color: #4c9da4;

    @media (min-width: 881px) and (max-width: 1600px) {
        font-size: 13px;
    }
`

type Props = {
    toggle: any
    isOpen: boolean
    userLoggedIn: null | IUser
    currentPath: string
    featureFlags: IFeatureFlagsReducer
}

type IMobileLongMenuNavigation_Item = {
    id: string
    pageName: string
    url: string
    icon: string
    disabled?: boolean
    hasNewFlag?: boolean
}

type IMobileLongMenuNavigation = {
    [key: string]: {
        sectionTitleCopy: string | undefined
        data: IMobileLongMenuNavigation_Item[]
        text?: string
    }
}

const navigation_data: IMobileLongMenuNavigation = {
    management: {
        sectionTitleCopy: 'management tools',
        data: [
            {
                id: 'garage',
                pageName: 'garage',
                url: '/garage',
                icon: 'garage',
            },
            {
                id: 'history-files',
                pageName: 'history files',
                url: '/history-files',
                icon: 'history_files',
            },
            {
                id: 'tasks',
                pageName: 'tasks',
                url: '/tasks',
                icon: 'tasks',
            },
            {
                id: 'showroom',
                pageName: 'showroom',
                url: 'https://app.custodian.club/showroom/for-sale',
                icon: 'showroom',
            },
            {
                id: 'insurance',
                pageName: 'insurance',
                url: '/insurance/overview',
                icon: 'insurance',
                hasNewFlag: true,
            },
        ],
    },
    community: {
        sectionTitleCopy: 'community',
        data: [
            {
                id: 'sharing',
                pageName: 'sharing',
                url: '/sharing/with-you',
                icon: 'sharing',
            },
            {
                id: 'apex',
                pageName: 'apex',
                url: '/apex',
                icon: 'apex',
            },
            {
                id: 'upcoming',
                pageName: 'upcoming',
                url: '/upcoming',
                icon: 'upcoming',
            },
        ],
    },
    find_us: {
        sectionTitleCopy: 'find us',
        data: [
            {
                id: 'instagram',
                pageName: 'instagram',
                url: 'https://www.instagram.com/custodian.club',
                icon: 'instagram',
            },
            {
                id: 'twitter',
                pageName: 'twitter',
                url: 'https://twitter.com/custodian_club',
                icon: 'twitter',
            },
            {
                id: 'facebook',
                pageName: 'facebook',
                url: 'https://www.facebook.com/custodian.club',
                icon: 'facebook',
            },
        ],
    },
    idea_btn: {
        sectionTitleCopy: 'Got an idea?',
        data: [],
        text: 'Let us know what would make our platform even better!',
    },
    settings: {
        sectionTitleCopy: undefined,
        data: [
            {
                id: 'preferences',
                pageName: 'settings',
                url: '/account/preferences',
                icon: 'settings',
            },
        ],
    },
}

const generateIcon = (icon: string, theme: ITheme) => {
    switch (icon) {
        case 'garage':
            return <SvgIcons.GarageIcon color={colours[theme].text_default} />
        case 'tasks':
            return <SvgIcons.TasksIcon color={colours[theme].text_default} />
        case 'history_files':
            return (
                <SvgIcons.HistoryFilesIcon
                    color={colours[theme].text_default}
                />
            )
        case 'upcoming':
            return <SvgIcons.UpcomingIcon color={colours[theme].text_default} />
        case 'sharing':
            return (
                <SvgIcons.SharingNavIcon color={colours[theme].text_default} />
            )
        case 'showroom':
            return <SvgIcons.ShowroomIcon color={colours[theme].text_default} />
        case 'apex':
            return <SvgIcons.ApexIcon color={colours[theme].text_default} />
        case 'settings':
            return <SvgIcons.SettingsIcon color={colours[theme].text_default} />
        case 'insurance':
            return <SvgIcons.Insurance color={colours[theme].text_default} />
        default:
            return null
    }
}

type State = {
    jwt: any
}

class DrawerMenuMobile extends React.Component<Props, State> {
    state = {
        jwt: null,
    }

    componentDidMount() {
        if (this.props.userLoggedIn) {
            generateNoltToken(this.props.userLoggedIn).then((jwt) => {
                this.setState({ jwt })
            })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.userLoggedIn !== prevProps.userLoggedIn &&
            this.state.jwt === null
        ) {
            if (this.props.userLoggedIn) {
                generateNoltToken(this.props.userLoggedIn).then((jwt) => {
                    this.setState({ jwt })
                })
                return
            }
        }
    }

    renderMenu = (toggle: any, theme: ITheme) => {
        let enable_insurance = this.props.featureFlags['enable_insurance']

        return Object.keys(navigation_data).map((section, i) =>
            section !== 'find_us' && section !== 'idea_btn' ? (
                <React.Fragment key={`${section}_${i}_section_mobile`}>
                    <SectionWrapper>
                        {navigation_data[section].sectionTitleCopy ? (
                            <SectionTitle $theme={theme}>
                                {navigation_data[section].sectionTitleCopy}
                            </SectionTitle>
                        ) : null}

                        {navigation_data[section].data.map((item, i) =>
                            item.id === 'insurance' &&
                            enable_insurance === false ? null : item.id ===
                              'showroom' ? (
                                <React.Fragment
                                    key={`${item.id}_${i}_mobile_left`}
                                >
                                    <a
                                        href={item.url}
                                        style={{ width: '100%' }}
                                    >
                                        <NavDrawerBtn
                                            bgColor={
                                                this.props.currentPath.includes(
                                                    item.id
                                                )
                                                    ? colours[theme]
                                                          .base_overlays_neutral_alpha_100
                                                    : colours[theme]
                                                          .background_neutral_subtle
                                            }
                                            text={item.pageName}
                                            onClick={() =>
                                                setTimeout(toggle, 50)
                                            }
                                            icon={generateIcon(
                                                item.icon,
                                                theme
                                            )}
                                            height="55px"
                                            iconWidth={
                                                section === 'community'
                                                    ? '16px'
                                                    : '14px'
                                            }
                                            hasNewFlag={item.hasNewFlag}
                                        />
                                    </a>
                                    <div style={{ paddingTop: '8px' }} />
                                </React.Fragment>
                            ) : (
                                <React.Fragment
                                    key={`${item.id}_${i}_mobile_left`}
                                >
                                    <Link
                                        to={item.url}
                                        style={{ width: '100%' }}
                                    >
                                        <NavDrawerBtn
                                            bgColor={
                                                this.props.currentPath.includes(
                                                    item.id
                                                )
                                                    ? colours[theme]
                                                          .base_overlays_neutral_alpha_100
                                                    : colours[theme]
                                                          .background_neutral_subtle
                                            }
                                            text={item.pageName}
                                            onClick={() =>
                                                setTimeout(toggle, 50)
                                            }
                                            icon={generateIcon(
                                                item.icon,
                                                theme
                                            )}
                                            height="55px"
                                            iconWidth={
                                                section === 'community'
                                                    ? '16px'
                                                    : '14px'
                                            }
                                            hasNewFlag={item.hasNewFlag}
                                        />
                                    </Link>
                                    <div style={{ paddingTop: '8px' }} />
                                </React.Fragment>
                            )
                        )}
                    </SectionWrapper>
                </React.Fragment>
            ) : section === 'find_us' ? (
                <React.Fragment key={`${section}_${i}_section_mobile_find_us`}>
                    <SectionWrapper>
                        <SectionTitle $theme={theme}>
                            {navigation_data[section].sectionTitleCopy}
                        </SectionTitle>
                        <SocialWrapper>
                            {socials.map((socialItem, i) =>
                                i !== 2 && i !== 4 ? (
                                    <React.Fragment key={`social_btn_${i}`}>
                                        <SocialHref
                                            $theme={theme}
                                            href={socialItem.href}
                                            target="_blank"
                                        >
                                            {socialItem.img}
                                        </SocialHref>
                                    </React.Fragment>
                                ) : null
                            )}
                        </SocialWrapper>
                    </SectionWrapper>
                </React.Fragment>
            ) : section === 'idea_btn' ? (
                <div
                    key={`${section}_${i}_section_mobile_idea`}
                    style={{
                        padding: 24,
                        paddingTop: 0,
                        width: '100%',
                        display: 'flex',
                    }}
                >
                    <GreenBox
                        $theme={theme}
                        onClick={(e: any) => e.stopPropagation()}
                    >
                        <div style={{ maxWidth: '80%' }}>
                            <GreenBoxTitle $theme={theme}>
                                Got an idea?
                            </GreenBoxTitle>
                            <GreenBoxContent $theme={theme}>
                                Let us know what would make our platform even
                                better!
                            </GreenBoxContent>
                            <div
                                id="nolt-click-surface"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    width: '100%',
                                    zIndex: 1,
                                }}
                            >
                                <NoltWidget jwt={this.state.jwt}>
                                    <div
                                        onClick={() => {
                                            posthog.capture(
                                                'ideas_button clicked'
                                            )
                                        }}
                                        id="nolt-click-surface"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        data-attr="ideas_btn_desktop"
                                    ></div>
                                </NoltWidget>
                            </div>
                        </div>
                        <div style={{ transform: 'rotate(-90deg)' }}>
                            <ChevronDown color="#4c9da4" />
                        </div>
                    </GreenBox>
                </div>
            ) : null
        )
    }

    render() {
        let { toggle, isOpen } = this.props

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <SideSliderWithExit
                        isVisible={isOpen}
                        direction={'right'}
                        initial="enter"
                        customDuration={0.5}
                    >
                        <Wrapper $theme={theme}>
                            <FixedHeader $theme={theme}>
                                <a
                                    href="https://www.custodian.club"
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                        display: 'flex',
                                        height: 45,
                                        alignItems: 'center',
                                    }}
                                >
                                    <LeftAlignLogo
                                        colour={
                                            theme === 'light'
                                                ? 'black'
                                                : 'white'
                                        }
                                        size={24}
                                    />
                                </a>

                                <RightArrowBtn
                                    onClick={() => setTimeout(toggle, 50)}
                                    whileTap={{ scale: 1.1 }}
                                >
                                    <CrossIcon />
                                </RightArrowBtn>
                            </FixedHeader>
                            <Padding />
                            {this.renderMenu(toggle, theme)}
                        </Wrapper>
                    </SideSliderWithExit>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default DrawerMenuMobile
