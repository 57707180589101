import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from 'styled-components'
import { ICostItem } from 'timelineModels'
import { IAttachmentItem } from 'attachmentModels'
import { motion } from 'framer-motion'
import { convertToShorterString } from '../../../../helpers/longStrings/convertToShorterString'
import {
    // costNumFormatter,
    numberWithCommas,
} from '../../../../helpers/currencyConverter'
import Icon from '../../icons'
import LoaderElasticThreeDots from '../../loader/loaderElasticThreeDots'

interface IStyled {
    someProp?: any
    $readOnlyMode?: any
}

function mapStateToProps(state: RootState) {
    return { activeCost: state.timeline.activeCost }
}

const dispatchProps = {}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`
const Container = styled(motion.div)<IStyled>`
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    border: ${(props) =>
        props.$readOnlyMode
            ? '1px solid var(--primary_04)'
            : '1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 8px;
`

const Row = styled.div<IStyled>`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const CompanyName = styled.div<IStyled>`
    font-family: 'Lato';
    color: var(--text-muted);
    font-size: 14px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
`

const FileName = styled.a<IStyled>`
    font-family: 'Lato';
    color: var(--text-muted);
    font-size: 14px;
    text-transform: lowercase;
    max-width: 70%;
    word-break: break-all;
    text-align: right;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
`

const CostName = styled.div<IStyled>`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 16px;
    text-transform: capitalize;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
`

const CostValue = styled.div<IStyled>`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 16px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
`

const IconWrapper = styled.div`
    transform: translateY(-2px);
    padding-right: 5px;
`

const HorizLine = styled.div`
    height: 1px;
    width: 50%;
    background-color: var(--border-muted, #e5e5e5);
    margin-top: 7px;
    margin-bottom: 15px;
`

// const Separator = styled.div<IStyled>`
//     width: 100%;
//     height: 1px;
//     background-color: #e0e0e0;
// `

interface Props {
    costItem: ICostItem
    dataCyId?: string
    unit: string
    attachment: IAttachmentItem | undefined
    onClick?: any
    isCostAttachmentBeingUploaded: boolean
    activeCost: ICostItem | null | undefined
    readOnlyMode?: boolean
    isCostAttachmentRestricted?: boolean
}

class TimelineCostItemMobile extends React.Component<Props, {}> {
    render() {
        const {
            dataCyId,
            unit,
            costItem,
            attachment,
            onClick,
            isCostAttachmentBeingUploaded,
            activeCost,
            readOnlyMode,
            isCostAttachmentRestricted,
        } = this.props

        return (
            <Wrapper
                // to={{
                //     pathname: this.props.match.url,
                //     search: `?cost=${costItem.id}`,
                //     state: { prevPath: this.props.match.url },
                // }}
                // replace={true}
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Container
                    whileTap={
                        !readOnlyMode
                            ? {
                                  backgroundColor: `#2a969a1e`,
                              }
                            : {}
                    }
                    onClick={!readOnlyMode && onClick ? onClick : () => {}}
                    $readOnlyMode={readOnlyMode}
                    data-attr={dataCyId && dataCyId}
                >
                    <Row>
                        <CostName>
                            {costItem.name ? costItem.name : ''}
                        </CostName>
                        <CostValue>
                            <IconWrapper>
                                <Icon
                                    icon={
                                        unit
                                            ? unit === 'GBP'
                                                ? `gbp_black`
                                                : unit === 'USD'
                                                ? 'dollar_black'
                                                : unit === 'EUR'
                                                ? 'euro_black'
                                                : ''
                                            : 'gbp_black'
                                    }
                                />
                            </IconWrapper>

                            {costItem.amount
                                ? numberWithCommas(costItem.amount)
                                : 0}
                        </CostValue>
                    </Row>

                    <Row
                        style={{
                            display: readOnlyMode ? 'block' : 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {readOnlyMode && <HorizLine />}
                        <CompanyName
                            style={
                                this.props.readOnlyMode
                                    ? { maxWidth: '100%' }
                                    : {}
                            }
                        >
                            {costItem.company
                                ? !readOnlyMode
                                    ? convertToShorterString({
                                          string: costItem.company,
                                          maxChars: 15,
                                          isFileName: true,
                                      })
                                    : costItem.company
                                : ''}
                        </CompanyName>
                        {readOnlyMode && <div style={{ paddingTop: '2px' }} />}
                        <FileName
                            href={
                                readOnlyMode && !isCostAttachmentRestricted
                                    ? attachment?.url
                                    : ``
                            }
                            onClick={(e: any) =>
                                !readOnlyMode ? e.preventDefault() : {}
                            }
                            target="_blank"
                            style={
                                isCostAttachmentRestricted || !attachment
                                    ? { color: '#b9b9b9', fontStyle: 'italic' }
                                    : this.props.readOnlyMode
                                    ? { color: '#5EC3CA' }
                                    : {}
                            }
                        >
                            {isCostAttachmentRestricted ? (
                                'Attachment is private'
                            ) : isCostAttachmentBeingUploaded &&
                              //   !attachment &&
                              activeCost &&
                              costItem.id === activeCost.id ? (
                                <div style={{ paddingRight: '10px' }}>
                                    <LoaderElasticThreeDots />
                                </div>
                            ) : attachment ? (
                                attachment.filename &&
                                convertToShorterString({
                                    string: attachment.filename,
                                    maxChars: this.props.readOnlyMode ? 30 : 12,
                                    isFileName: true,
                                })
                            ) : (
                                'No attachment'
                            )}
                        </FileName>
                    </Row>
                </Container>
            </Wrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(TimelineCostItemMobile)
