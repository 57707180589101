import { IStickerPredefinedThemeData } from 'stickerPredefinedThemes'
import { stickerPredefinedThemesData } from './data'

export type IStickerPredefinedThemesState = IStickerPredefinedThemeData

const initialState = stickerPredefinedThemesData

export const stickerPredefinedThemes = (
    state: IStickerPredefinedThemesState = initialState
) => state

export default stickerPredefinedThemes

export type StickerPredefinedThemes = ReturnType<typeof stickerPredefinedThemes>
