import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
// import Loader from "../atoms/loader/loader";
import Faded from '../../templates/animated/faded'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import SectionHeader from '../../atoms/header/section/sectionHeader'

import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
// import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import ContactFormDesktop from '../../organisms/editForms/contactForm/contactFormDesktop'
import ContactFormMobile from '../../organisms/editForms/contactForm/contactFormMobile'
// import account_default from '../../../public/assets/navigation/account_default.svg'

// import ReturnNav from '../../atoms/header/returnNav'
// import styled from 'styled-components'
// import { device } from '../../templates/displays/devices'
import { IHelpPageContact } from 'helpPageCopyModels'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

// const AccountIcon = styled(Link)`
//     position: fixed;
//     top: 0;
//     right: 10px;
//     padding-top: 12px;
//     height: 50px;
//     z-index: 5;
// `

// const IconItem = styled.img`
//     width: 26px;
//     height: 26px;
//     margin-right: 10px;
//     @media ${device.desktop} {
//         display: none;
//     }
// `

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        contactReasonsData:
            state.localdata.help_page_copy.selectedContactReason,
    }
}

type State = {}

interface Props extends RouteComponentProps<any> {
    setInitialLandingUrl: (str: string) => void
    userLoggedIn: null | IUser
    getUserDataRequest: () => void
    userid: string | null
    contactReasonsData: IHelpPageContact
}

class ContactHelp extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        this.props.setInitialLandingUrl('/account/contact')

        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }
    }

    render() {
        const { userLoggedIn, contactReasonsData } = this.props

        return (
            <CenteredPageWrapper hasMobileSubNav>
                {userLoggedIn && (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                <SectionHeader
                                    title={'Contact'}
                                    removeEdit={true}
                                />

                                <ContactFormDesktop
                                    contactReasonsData={contactReasonsData}
                                />
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            {/* <Link
                                to={{
                                    pathname: `/account`,
                                    state: {
                                        prevPath: this.props.match.url,
                                    },
                                }}
                            >
                                <ReturnNav
                                    customText="CONTACT"
                                    customTextColor="#0c0c0c"
                                    isUppercase={true}
                                    chevron={true}
                                />
                            </Link>
                            <AccountIcon to="/account">
                                <IconItem src={account_default} alt="account" />
                            </AccountIcon> */}

                            {/* <MobileSettingsSectionHeader
                                id="contact-mobile"
                                title="Contact"
                            /> */}
                            <div style={{ height: '10px' }} />
                            <ContactFormMobile
                                contactReasonsData={contactReasonsData}
                            />
                            <div style={{ height: '100px' }} />
                        </IpadAndMobileDisplay>
                    </Faded>
                )}
            </CenteredPageWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(ContactHelp))
