type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const PenIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.18403 3.18403C9.47692 2.89113 9.95179 2.89113 10.2447 3.18403L12.8161 5.75545C13.109 6.04835 13.109 6.52322 12.8161 6.81611C12.5232 7.10901 12.0483 7.10901 11.7555 6.81611L9.18403 4.24469C8.89113 3.95179 8.89113 3.47692 9.18403 3.18403Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4286 0.939453L15.0607 4.57154L5.81202 13.8202L0.969238 15.0309L2.17993 10.1881L11.4286 0.939453ZM3.53438 10.955L3.03079 12.9693L5.04515 12.4657L12.9394 4.57154L11.4286 3.06077L3.53438 10.955Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default PenIcon
