import * as React from 'react'
import { useRef } from 'react'
import styled from 'styled-components'
import { usePlacesAutocomplete } from '../../../helpers/location/usePlacesAutocomplete'
import Expander from '../../atoms/expander/expander'
import Icon from '../../atoms/icons'
import { useOnClickOutside } from '../../templates/clickOutside'
import { device } from '../../templates/displays/devices'

interface Props {
    valueSearched: string
    showDropdown: boolean
    toggleShowDropDown: any
    onSuggestionClick: any
}

const LocationWrapper = styled.div`
    position: relative;
    z-index: 10;
    @media ${device.desktop} {
        width: 100%;
    }

    @media ${device.ipad} {
        width: 400px;
    }

    @media ${device.mobile} {
        width: 80vw;
    }
`

const LocationDropdown = styled.div<{ $isVisible: boolean }>`
    position: absolute;
    z-index: 10;
    width: 100%;
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border: ${(props) =>
        props.$isVisible ? '1px solid var(--border-muted, #e5e5e5)' : 'none'};
    border-radius: 5px;
`
const LocationItem = styled.div`
    padding: 8px;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    font-family: Lato;
    font-size: 14px;
    display: flex;
    align-items: center;
    list-style: none;
    cursor: pointer;

    :hover {
        background-color: #cdfdfd3a;
    }
`

const LocationIconWrapepr = styled.div`
    width: 14px;
    height: 14px;
    margin-right: 3px;
    transform: translateY(-7px);
`

const GooglePlacesLocationsDropdown: React.FunctionComponent<Props> = (
    props
) => {
    const {
        onSuggestionClick,
        showDropdown,
        toggleShowDropDown,
        valueSearched,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    const suggestions = usePlacesAutocomplete(valueSearched)
    useOnClickOutside(ref, () => toggleShowDropDown(false))

    return (
        <LocationWrapper ref={ref}>
            <Expander height={showDropdown ? 'auto' : 0}>
                <LocationDropdown $isVisible={suggestions && suggestions[0]}>
                    {suggestions?.map((suggestion: any, index: number) =>
                        suggestion ? (
                            <LocationItem
                                key={suggestion?.place_id}
                                style={{
                                    borderBottom:
                                        index === suggestions.length - 1
                                            ? 'none'
                                            : '1px solid var(--border-muted, #e5e5e5)',
                                }}
                            >
                                <LocationIconWrapepr>
                                    <Icon
                                        icon="location"
                                        width="100%"
                                        height="100%"
                                    />
                                </LocationIconWrapepr>
                                <button
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        onSuggestionClick(suggestion)
                                        toggleShowDropDown(false)
                                    }}
                                    onKeyDown={(e) => {
                                        e.preventDefault()
                                        onSuggestionClick(suggestion)
                                        toggleShowDropDown(false)
                                    }}
                                >
                                    {suggestion.structured_formatting
                                        ? suggestion.structured_formatting
                                              .main_text
                                        : suggestion.description}
                                </button>
                            </LocationItem>
                        ) : (
                            <div
                                key={`google_maps_dropdown_${index}_desktop`}
                                style={{ display: 'none' }}
                            />
                        )
                    )}
                </LocationDropdown>
            </Expander>
        </LocationWrapper>
    )
}

export default GooglePlacesLocationsDropdown
