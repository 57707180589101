import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const H1Styled = styled.h1`
    font-family: Cinzel;
    text-transform: uppercase;
    text-align: center;
    color: black;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    @media ${device.mobile} {
        font-size: 10px;
        padding-top: 5px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    @media ${device.desktop} {
        font-size: 16px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-weight: 200;
    }
`

export interface IHeaderProps {
    children?: React.ReactNode
}

const Header: React.FunctionComponent<IHeaderProps> = (props) => (
    <H1Styled>{props.children}</H1Styled>
)
Header.defaultProps = {}
export default Header
