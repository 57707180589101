import { IHighlightedFactsObject } from 'entityModels'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from '../../../redux/store/hooks'
import KeyMomentItem from '../../atoms/car/profile/keyMomentItem'
import React from 'react'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`

const ListContainer1 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    grid-auto-rows: 1fr;
`
const ListContainer2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
    grid-auto-rows: 1fr;
    max-width: 70%;

    @media (min-width: 1600px) {
        max-width: 60%;
    }
`

interface Props {
    highlighted_facts_ids: string[]
    onClick?: any
    readOnlyMode?: boolean
}

type EnumerableObject = {
    id: string
    text: string
    order: number
}

export const sortList = (list: EnumerableObject[]) => {
    return list.sort(
        (a: EnumerableObject, b: EnumerableObject) => a.order - b.order
    )
}

const CarKeyMomentsfactsDesktop = (props: Props) => {
    let highlighted_facts_data: IHighlightedFactsObject = useAppSelector(
        (state) => {
            return state.entities.highlightedFactsData.highlighted_facts
        }
    )

    const [nProvided, setNProvided] = useState<number>(0)

    const [items, setItems] = useState<EnumerableObject[]>([])

    let { highlighted_facts_ids } = props
    useEffect(() => {
        if (highlighted_facts_ids) {
            let nProvidedLoc: number = 0

            for (const id of highlighted_facts_ids) {
                if (
                    highlighted_facts_data[id].text &&
                    highlighted_facts_data[id].text.length > 0
                ) {
                    nProvidedLoc = nProvidedLoc + 1
                }
            }

            setNProvided(nProvidedLoc)
        }

        let list = highlighted_facts_ids.map((id: string, index) => {
            return highlighted_facts_data[id]
        })

        let ordered_list = sortList(list)

        setItems(ordered_list)
    }, [highlighted_facts_data, highlighted_facts_ids])

    return (
        <Container>
            <ListContainer1>
                {items &&
                    items.map((obj: EnumerableObject, index: number) => {
                        return (
                            <React.Fragment key={`kmf_cont_1_${index}_desktop`}>
                                <KeyMomentItem
                                    isReadOnly={props.readOnlyMode}
                                    onClick={props.onClick}
                                    obj={obj}
                                    index={index}
                                    theme="1"
                                />
                            </React.Fragment>
                        )
                    })}
            </ListContainer1>

            <div style={{ paddingTop: '20px' }} />

            {nProvided > 3 ? (
                <ListContainer2>
                    {items &&
                        items.map((obj: EnumerableObject, index: number) => {
                            return (
                                <React.Fragment
                                    key={`kmf_cont_2_${index}_desktop`}
                                >
                                    <KeyMomentItem
                                        isReadOnly={props.readOnlyMode}
                                        onClick={props.onClick}
                                        obj={obj}
                                        index={index}
                                        theme="2"
                                    />
                                </React.Fragment>
                            )
                        })}
                </ListContainer2>
            ) : null}
        </Container>
    )
}

export default CarKeyMomentsfactsDesktop
