import { IJourneySectionID } from 'journeyInterfaces'
import ShowroomIcon from '../showroomIcon'
import JourneyPastCarsIcon from './pastCars'
import JourneySharingIcon from './sharing'
import JourneySellYourCarIcon from './sellYourCar'
import JourneyYourProfileIcon from './yourProfile'
import JourneyHistoryFileIcon from './historyFile'
import JourneyHandoverIcon from './handover'
import JourneyInsuranceIcon from './insurance'

type Props = {
    id: IJourneySectionID
    color?: string
    size?: string
}
export const RenderJourneyIcon = (props: Props) => {
    switch (props.id) {
        case 'car_profile':
            return <ShowroomIcon color={props.color} size={props.size} />
        case 'past_cars':
            return <JourneyPastCarsIcon color={props.color} size={props.size} />
        case 'share_your_car':
            return <JourneySharingIcon color={props.color} size={props.size} />
        case 'sell_your_car':
            return (
                <JourneySellYourCarIcon color={props.color} size={props.size} />
            )
        case 'insurance':
            return (
                <JourneyInsuranceIcon color={props.color} size={props.size} />
            )
        case 'your_profile':
            return (
                <JourneyYourProfileIcon color={props.color} size={props.size} />
            )
        case 'history_file':
            return (
                <JourneyHistoryFileIcon color={props.color} size={props.size} />
            )
        case 'handover':
            return <JourneyHandoverIcon color={props.color} size={props.size} />
        default:
            return <ShowroomIcon color={props.color} size={props.size} />
    }
}
