import { ICar, IHandover } from 'entityModels'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import {
    createHandoverRequest,
    deleteHandoverRequest,
    getCarActionCheckRequest,
} from '../../../redux/entities/cars/actions/loadingActions'

import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import {
    getCurrentUserDataRequest,
    getUserArchivedCarsRequest,
} from '../../../redux/user/actions/loadingActions'
import GarageArchiveInfoBar from '../../atoms/infoBars/garageArchiveInfoBar'
import { IThreeDotsActionsItems } from '../../atoms/menu/actionsMenu/actionsMenuDots'
import ArchivedGarageViewsMenuDesktop from '../../atoms/menu/archivedGarageViewsMenu/archivedGarageViewsMenuDesktop'
import ArchivedGarageViewsMenuMobile from '../../atoms/menu/archivedGarageViewsMenu/archivedGarageViewsMenuMobile'
import SortMenuMobile from '../../atoms/menu/sortMenu/sortMenuMobile'
import ArchivedGarageManagerDesktop from '../../organisms/archived/garage/archivedGarageManagerDesktop'
import ArchivedGarageManagerMobile from '../../organisms/archived/garage/archivedGarageManagerMobile'
import ArchiveModalsManager, {
    ArchivedGarageModalContainerDesktop,
    ArchivedGarageModalContainerMobile,
    IArchiveActionsModalEnum,
} from '../../organisms/archived/modals/archiveModalsManager'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import ModalDisplayFullPageDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import NoPrevOwned from './noPrevOwned'
import ScrollLock from 'react-scrolllock'
import FadedSlower from '../../templates/animated/FadedSlower'
import JourneyRedirectCardDesktop from '../../molecules/cards/journey/redirectCards/redirectCardDesktop'
import { journeyRedirectCardsCopy } from '../../../redux/localdata/journey/copyData'
import JourneyRedirectCardMobile from '../../molecules/cards/journey/redirectCards/redirectCardMobile'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

export const sortArchivedGarageMenuData = [
    {
        uid: 'ARCHIVED_ON_MOST_RECENT',
        name: 'Archive date: Most Recent',
    },
    {
        uid: 'ARCHIVED_ON_OLDEST',
        name: 'Archive date: Oldest',
    },

    {
        uid: 'MANUFACTURE_DATE_DESCENDING',
        name: 'Age: Newest',
    },
    {
        uid: 'MANUFACTURE_DATE_ASCENDING',
        name: 'Age: Oldest',
    },
]

export type IHandoverMenuItemsArgProps = {
    carID: string
    handoverData?: IHandover
}

export type menu_items_keys =
    | 'menu_items_handover_in_progress'
    | 'menu_item_handover_completed'
    | 'menu_item_archived_no_handover'
    | 'menu_item_handover_failed'
    | 'menu_item_handover_processing'

export type IArchiveCardMenuItems = {
    [key in menu_items_keys]: IThreeDotsActionsItems[]
}

let determineName = (h: IHandover): string => {
    if (h.new_owner_invite?.recipient_family_name) {
        return `${h.new_owner_invite?.recipient_given_name} ${h.new_owner_invite?.recipient_family_name}`
    } else return `${h?.new_owner?.display_name}`
}

export const determineCarArchiveCopy = (car: ICar): string => {
    if (car.handover) {
        if (car.handover.handover_state === 'INITIATED') {
            return `Handover to ${determineName(car.handover)} is in progress`
        }

        if (car.handover.handover_state === 'PROCESSING') {
            return `Handover to ${determineName(car.handover)} is processing`
        }

        if (car.handover.handover_state === 'FAILED') {
            return `Handover to ${determineName(car.handover)} failed`
        }

        if (car.handover.handover_state === 'COMPLETED') {
            return `Entrusted to ${determineName(car.handover)} on ${
                car.archived_on
            }`
        }
    }
    return `Archived on ${car.archived_on}`
}

const GarageArchive = (props: any) => {
    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    const [activeView, setActiveView] = useState<'grid' | 'list'>(
        isMobile ? 'list' : 'grid'
    )

    const [checkedCarsActions, setCheckedCarsActions] = useState<string[]>([])

    useEffect(() => {
        if (isMobile && activeView !== 'list') {
            setActiveView('list')
        } else if (!isMobile && activeView !== 'grid') {
            setActiveView('grid')
        }
    }, [isMobile])

    const [showSortActionBottomSheet, toggleSortActionBottomSheet] =
        useState(false)

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let cars: ICar[] = userLoggedIn?.archived_cars
        ? userLoggedIn?.archived_cars?.car_ids.map(
              (carID: string, index: number) => {
                  return carsData[carID]
              }
          )
        : []

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        }
    }, [])

    useEffect(() => {
        if (userLoggedIn && !userLoggedIn.archived_cars) {
            dispatch(getUserArchivedCarsRequest('ARCHIVED_ON_MOST_RECENT'))
        }
    }, [userLoggedIn])

    const userLoading = useAppSelector((state) => {
        return state.user.loading
    })

    const car_checks_loading = useAppSelector((state) => {
        return state.entities.carsData.car_checks_loading
    })

    let history = useHistory()

    const [modalOpened, setModalOpened] =
        useState<null | IArchiveActionsModalEnum>(null)

    const [handoverData, setHandoverData] =
        useState<null | IHandoverMenuItemsArgProps>(null)

    let currentPath = history.location.pathname

    const { theme } = useThemes()

    let menu_items = (car: ICar): IThreeDotsActionsItems[] => {
        return [
            {
                text: 'Resend handover request',
                isDisabled:
                    car.car_actions_checks?.handover?.allowed === false
                        ? true
                        : false,
                disabledTxt:
                    car.car_actions_checks?.handover?.allowed === false &&
                    car.car_actions_checks?.handover?.message
                        ? car.car_actions_checks?.handover?.message
                        : undefined,
                hide_item:
                    car.handover?.handover_state !== 'INITIATED' ? true : false,
                actionOnClick: (p: IHandoverMenuItemsArgProps) => {
                    // create a handover with same details
                    if (
                        p.handoverData &&
                        car.car_actions_checks?.handover?.allowed === true
                    ) {
                        setHandoverData(p)
                        if (p.handoverData.new_owner) {
                            dispatch(
                                createHandoverRequest({
                                    carID: p.carID,
                                    isResend: true,

                                    data: {
                                        selection_option:
                                            p.handoverData.selection_option,
                                        new_owner_email:
                                            p.handoverData?.new_owner?.uid !==
                                            undefined
                                                ? undefined
                                                : p.handoverData.new_owner
                                                      ?.email,
                                        new_owner_id:
                                            p.handoverData.new_owner?.uid,
                                    },
                                })
                            )
                        } else {
                            dispatch(
                                createHandoverRequest({
                                    carID: p.carID,
                                    isResend: true,
                                    data: {
                                        selection_option:
                                            p.handoverData.selection_option,
                                        new_owner_email:
                                            p.handoverData.new_owner_invite
                                                ?.recipient_email,
                                        new_owner_first_name:
                                            p.handoverData.new_owner_invite
                                                ?.recipient_given_name,
                                        new_owner_last_name:
                                            p.handoverData.new_owner_invite
                                                ?.recipient_family_name,
                                    },
                                })
                            )
                        }

                        setModalOpened('handover_resend_success')
                    }
                },
            },
            {
                text: 'Handover to a different user',
                isDisabled:
                    car.car_actions_checks?.handover?.allowed === false
                        ? true
                        : false,
                disabledTxt:
                    car.car_actions_checks?.handover?.allowed === false &&
                    car.car_actions_checks?.handover?.message
                        ? car.car_actions_checks?.handover?.message
                        : undefined,
                hide_item:
                    car.handover?.handover_state !== 'INITIATED' ? true : false,
                actionOnClick: (p: IHandoverMenuItemsArgProps) => {
                    if (car.car_actions_checks?.handover?.allowed === true) {
                        setHandoverData(p)
                        setModalOpened('handover_change_user_confirmation')
                    }
                    // history.push(`/handover/${p.carID}`)
                },
            },
            {
                text: 'Cancel handover',
                isDisabled:
                    car.car_actions_checks?.handover?.allowed === false
                        ? true
                        : false,
                disabledTxt:
                    car.car_actions_checks?.handover?.allowed === false &&
                    car.car_actions_checks?.handover?.message
                        ? car.car_actions_checks?.handover?.message
                        : undefined,
                hide_item:
                    car.handover?.handover_state !== 'INITIATED' ? true : false,
                actionOnClick: (p: IHandoverMenuItemsArgProps) => {
                    if (
                        p.handoverData &&
                        car.car_actions_checks?.handover?.allowed === true
                    ) {
                        setHandoverData(p)
                        setModalOpened('cancel_handover_confirmation')
                        // dispatch(deleteHandoverRequest(p.carID))
                    }
                },
            },
            {
                text: 'Handover',
                hide_item:
                    car.handover?.handover_state === undefined ||
                    car.handover?.handover_state === 'FAILED'
                        ? false
                        : true,
                isDisabled:
                    car.car_actions_checks?.handover?.allowed === false
                        ? true
                        : false,
                disabledTxt:
                    car.car_actions_checks?.handover?.allowed === false &&
                    car.car_actions_checks?.handover?.message
                        ? car.car_actions_checks?.handover?.message
                        : undefined,
                actionOnClick: (p: IHandoverMenuItemsArgProps) => {
                    if (car.car_actions_checks?.handover?.allowed === true) {
                        history.push({
                            pathname: `/handover/${p.carID}`,
                            state: { prevPath: history.location.pathname },
                        })
                    }
                },
                color:
                    car.car_actions_checks?.handover?.allowed === false
                        ? '#c6c6c6'
                        : undefined,
                isLoading: car_checks_loading,
            },
            {
                text: 'Remove car',
                isDisabled:
                    car.car_actions_checks?.delete?.allowed === false
                        ? true
                        : false,
                disabledTxt:
                    car.car_actions_checks?.delete?.allowed === false &&
                    car.car_actions_checks?.delete?.message
                        ? car.car_actions_checks?.delete?.message
                        : undefined,
                actionOnClick: (p: IHandoverMenuItemsArgProps) => {
                    if (car.car_actions_checks?.delete?.allowed === true) {
                        setHandoverData(p)
                        history.push({
                            state: {
                                prevPath: currentPath,
                            },
                            pathname: `/car/${p.carID}/deletion`,
                        })
                    }
                },
                color:
                    car.car_actions_checks?.delete?.allowed === false
                        ? colours[theme].text_disabled
                        : colours[theme].error,
                isLoading: car_checks_loading,
            },
        ]
    }

    return (
        <React.Fragment>
            {modalOpened && handoverData && handoverData.handoverData && (
                <>
                    <ModalDisplayFullPageDesktopExclusive
                        isOpen={modalOpened !== null ? true : false}
                        toggle={() => {
                            // setModalOpened(null)
                        }}
                    >
                        <ArchivedGarageModalContainerDesktop>
                            <ArchiveModalsManager
                                activeModal={modalOpened}
                                handleSetModalOpened={setModalOpened}
                                carID={handoverData.carID}
                                handover={handoverData.handoverData}
                                cancel_handover={() => {
                                    if (handoverData) {
                                        dispatch(
                                            deleteHandoverRequest(
                                                handoverData.carID
                                            )
                                        )
                                    }
                                }}
                            />
                        </ArchivedGarageModalContainerDesktop>
                    </ModalDisplayFullPageDesktopExclusive>

                    <IpadAndMobileDisplay>
                        <ArchivedGarageModalContainerMobile>
                            {modalOpened && <ScrollLock />}
                            <ArchiveModalsManager
                                activeModal={modalOpened}
                                handleSetModalOpened={setModalOpened}
                                carID={handoverData.carID}
                                handover={handoverData.handoverData}
                                cancel_handover={() => {
                                    if (handoverData) {
                                        dispatch(
                                            deleteHandoverRequest(
                                                handoverData.carID
                                            )
                                        )
                                    }
                                }}
                            />
                        </ArchivedGarageModalContainerMobile>
                    </IpadAndMobileDisplay>
                </>
            )}

            <DesktopDisplayOnly>
                <FullPagePageWrapper>
                    <GarageArchiveInfoBar />

                    <FadedSlower>
                        <div
                            style={{
                                paddingTop: '20px',
                                paddingLeft: '24px',
                                paddingRight: '36px',
                            }}
                        >
                            <JourneyRedirectCardDesktop
                                sectionID="past_cars"
                                dataPH={'start_past_cars_journey'}
                                copyData={journeyRedirectCardsCopy}
                                onStartNow={() => {
                                    history.push(
                                        `/journey/previously-owned?step=welcome`
                                    )
                                }}
                            />
                        </div>
                    </FadedSlower>
                </FullPagePageWrapper>
                <CenteredPageWrapper removePaddings={true}>
                    <div style={{ paddingTop: '32px' }} />

                    {cars && cars.length > 0 ? (
                        <>
                            <ArchivedGarageViewsMenuDesktop
                                activeView={activeView}
                                setActiveView={setActiveView}
                            />

                            <div style={{ paddingTop: '32px' }} />

                            <ArchivedGarageManagerDesktop
                                activeView={activeView}
                                cars={cars}
                                menu_items={menu_items}
                                onThreeDotsClick={(carid: string) => {
                                    if (
                                        checkedCarsActions.indexOf(carid) === -1
                                    ) {
                                        dispatch(
                                            getCarActionCheckRequest({
                                                carid: carid,
                                            })
                                        )
                                    }
                                    setCheckedCarsActions((prevState) => {
                                        return [...prevState, carid]
                                    })
                                }}
                            />
                        </>
                    ) : userLoading === true ? null : (
                        <NoPrevOwned />
                    )}
                </CenteredPageWrapper>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <CenteredPageWrapper hasMobileSubNav={true}>
                    <GarageArchiveInfoBar />
                    {/* <div style={{ paddingTop: '72px' }} /> */}

                    <div
                        style={{
                            paddingTop:
                                cars && cars.length > 0 ? '30px' : '14px',
                        }}
                    />

                    {cars && cars.length > 0 ? (
                        <>
                            <ArchivedGarageViewsMenuMobile
                                activeView={activeView}
                                setActiveView={setActiveView}
                                toggleSortActionBottomSheet={
                                    toggleSortActionBottomSheet
                                }
                            />
                            <div style={{ paddingTop: '24px' }} />
                            <ArchivedGarageManagerMobile
                                activeView={activeView}
                                cars={cars}
                                menu_items={menu_items}
                                onThreeDotsClick={(carid: string) => {
                                    if (
                                        checkedCarsActions.indexOf(carid) === -1
                                    ) {
                                        dispatch(
                                            getCarActionCheckRequest({
                                                carid: carid,
                                            })
                                        )
                                    }
                                    setCheckedCarsActions((prevState) => {
                                        return [...prevState, carid]
                                    })
                                }}
                            />
                            <div style={{ paddingTop: '100px' }} />
                            <SortMenuMobile
                                isVisible={showSortActionBottomSheet}
                                close={() => toggleSortActionBottomSheet(false)}
                                dropdownData={sortArchivedGarageMenuData}
                                currentOption="Manufacture date: Newest"
                                onSelected={(
                                    v:
                                        | 'ARCHIVED_ON_OLDEST'
                                        | 'ARCHIVED_ON_MOST_RECENT'
                                        | 'MANUFACTURE_DATE_ASCENDING'
                                        | 'MANUFACTURE_DATE_DESCENDING'
                                        | string
                                ) => {
                                    // @ts-ignore
                                    dispatch(getUserArchivedCarsRequest(v))
                                }}
                            />
                        </>
                    ) : userLoading === true ? null : (
                        <div>
                            <JourneyRedirectCardMobile
                                dataPH="start_past_cars_journey"
                                sectionID="past_cars"
                                copyData={journeyRedirectCardsCopy}
                                onStartNow={() => {
                                    history.push(
                                        `/journey/previously-owned?step=welcome`
                                    )
                                }}
                                onDismiss={() => {}}
                            />

                            <div style={{ paddingTop: '8px' }} />
                            <NoPrevOwned />
                            <div style={{ paddingTop: '150px' }} />
                        </div>
                    )}
                </CenteredPageWrapper>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default GarageArchive
