import { IAccDelItems } from 'IapiDataPayload'
import { IUser } from 'myModels'
import * as React from 'react'
import styled from 'styled-components'
import FormStepProgress from '../../atoms/FormStepProgress/FormStepProgress'
import Faded from '../../templates/animated/faded'
import warningIcon from '../../../public/assets/icons/full_bg_warning.svg'
import CustomAnimatedCheckbox from '../../atoms/formField/customAnimatedCheckboxField'
import ButtonsStandardBottomRowDesktop from '../editForms/buttons/buttonsStandardBottomRowDesktop'
import { Radio } from 'antd'
import { device } from '../../templates/displays/devices'
import chevron from '../../../public/assets/icons/chevron_grey_to_right.svg'
import AnimatedTextarea from '../../atoms/textarea/animatedTextarea'
import Expander from '../../atoms/expander/expander'
import { StandardInputField } from '../../atoms/Inputfield/inputField'
import StyledFormError from '../../templates/styledcomponents/styledformerrormessage'
import useThemes from '../../../providers/theme/hooks'

type IProps = {
    step_id: number
    user: IUser
    cars_no: number
    history_files_no: number
    tasks_no: number
    handleChange: any
    onClose: any
    changeSteps: any
    activeItems: IAccDelItems
    deleteAccount: any
}

type IStepProps = {
    title?: string
    subText?: string
    children: React.ReactNode
    hasProgressBar?: boolean
    onNext: any
    onCancel: any
    stepNumber: number
    goBack: any
    isNextStepDisabled: boolean
}

const deletionReasons = [
    { id: 'expectations', text: 'The product is not meeting my expectations' },
    { id: 'privacy', text: 'I have privacy concerns' },
    { id: 'better_alternative', text: 'I found a better alternative' },
    { id: 'mistake', text: 'I created an account by mistake' },
    { id: 'missing_feature', text: 'The product is missing features I need' },
    { id: 'not_what_looking', text: "It's not what I was looking for" },
    { id: 'other', text: 'Other' },
]

const deletionStepsData: {
    title: string
    subText?: string
    data?: typeof deletionReasons
}[] = [
    { title: 'Delete Custodian Account' },
    {
        title: 'Deletion reason',
        subText: "What's the main reason for wanting to delete your account?",
        data: deletionReasons,
    },
    {
        title: 'Rating',
        subText:
            'On a scale of 1-5, how would you rate your overall experience with our product?',
    },
    {
        title: 'Feedback',
        subText:
            'Can you please provide any additional feedback or comments about your experience with our product?',
    },
    {
        title: 'Further feedback',
        subText: 'Are you open to us reaching out to you for further feedback?',
    },
    { title: 'Confirm Deletion' },
]

const Wrapper = styled.div`
    margin-top: 15vh;
    min-height: 310px;
    width: 480px;
    background-color: var(--bg-color, #fff);
`

const StepWrapper = styled.div`
    padding: 24px;
    width: 100%;
    height: 100%;
    background-color: var(--bg-color, #fff);
`

const Title = styled.div`
    font-size: 24px;
    color: var(--text-strong, #1a1a1a);
    margin: 0;
    padding: 0;
    transition: all 200ms;
`

const SubText = styled.div`
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
    margin: 0;
    padding: 0;
    padding-bottom: 24px;
    padding-top: 24px;
`

const InfoBox = styled.div`
    height: 108px;
    width: 134px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--off-bg-color, #fafafa);
`
const InfoBoxText = styled.div`
    font-size: 32px;
    color: var(--text-muted, #b3b3b3);
`

const InfoBoxSubtext = styled.div`
    font-size: 12px;
    padding-top: 8px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
`
const InfoBoxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: '100%';
`
const Icon = styled.img`
    width: 73px;
`

const TextLatoBold = styled.div`
    font-family: Lato-Semibold;
`

const TextLato = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
`

const NoticeBox = styled.div`
    background-color: var(--off-bg-color, #fafafa);
    display: flex;
    padding: 20px;
    border-radius: 4px;
`

const RadioStyled = styled(Radio)`
    font-family: Lato !important;
    align-self: flex-start !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--text-default, #666666) !important;
    margin-top: 0px;
    margin-bottom: 22px;
    font-size: 14px;
    .ant-radio-inner {
        background-color: var(--bg-color, #fff) !important;
    }

    @media ${device.mobile} {
    }
`

const DeletionStep: React.FunctionComponent<IStepProps> = (
    props: IStepProps
) => {
    const {
        title,
        subText,
        hasProgressBar,
        onNext,
        onCancel,
        stepNumber,
        goBack,
        children,
        isNextStepDisabled,
    } = props

    return (
        <StepWrapper>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingBottom: 24,
                            cursor: 'pointer',
                        }}
                        onClick={stepNumber > 1 ? goBack : () => {}}
                    >
                        {stepNumber > 1 && (
                            <div
                                style={{
                                    paddingRight: 24,
                                    height: 16,
                                    width: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={chevron}
                                    alt="go_back"
                                    height={'100%'}
                                    width="auto"
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                            </div>
                        )}
                        <Title>{title}</Title>
                    </div>
                    {hasProgressBar && (
                        <FormStepProgress
                            currentStep={stepNumber - 1}
                            totalSteps={4}
                            screen_name="desktop"
                        />
                    )}
                    {subText && <SubText>{subText}</SubText>}
                    {children}
                </div>
                <div style={{ paddingTop: 24 }}>
                    <ButtonsStandardBottomRowDesktop
                        cancel={onCancel}
                        submit={onNext}
                        secondText={stepNumber < 6 ? 'Next' : 'Delete Account'}
                        isDisabled={isNextStepDisabled}
                        isDistructive={stepNumber === 6 ? true : false}
                    />
                </div>
            </div>
        </StepWrapper>
    )
}

const AccountDeletionManagerDesktop: React.FunctionComponent<IProps> = (
    props: IProps
) => {
    const {
        step_id,
        handleChange,
        changeSteps,
        activeItems,
        user,
        cars_no,
        history_files_no,
        tasks_no,
        onClose,
        deleteAccount,
    } = props

    const { theme } = useThemes()

    const checkIfDeletionNextIsDisabled = (
        id: number,
        activeItems: IAccDelItems,
        userEmail: string
    ) => {
        if (id === 1 && activeItems.deletion_agreement) {
            return false
        }
        if (id === 2 && activeItems.reason !== '') {
            if (
                (activeItems.reason === 'I found a better alternative' ||
                    activeItems.reason === 'Other') &&
                (activeItems.custom_reason_detail === '' ||
                    activeItems.custom_reason_detail.trim() === '')
            ) {
                return true
            }
            return false
        }

        if (id === 3 && activeItems.rating !== 0) {
            return false
        }

        if (id === 4) {
            return false
        }
        if (id === 5 && activeItems.further_feedback !== undefined) {
            return false
        }

        if (id === 6 && activeItems.userEmail === userEmail) {
            return false
        }
        return true
    }

    return activeItems ? (
        <Wrapper>
            <DeletionStep
                stepNumber={step_id}
                onNext={
                    step_id < 6
                        ? () => changeSteps(step_id + 1)
                        : () => deleteAccount()
                }
                goBack={() =>
                    cars_no === 0 && step_id === 2
                        ? onClose()
                        : changeSteps(step_id - 1)
                }
                onCancel={onClose}
                title={deletionStepsData[step_id - 1].title}
                subText={deletionStepsData[step_id - 1].subText}
                hasProgressBar={step_id !== 1 && step_id !== 6}
                isNextStepDisabled={
                    user.email
                        ? checkIfDeletionNextIsDisabled(
                              step_id,
                              activeItems,
                              user.email
                          )
                        : true
                }
            >
                {step_id === 1 && (
                    <Faded>
                        <NoticeBox>
                            <Icon
                                src={warningIcon}
                                style={{ width: 20, height: 23 }}
                            />
                            <div
                                style={{
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            >
                                <TextLatoBold
                                    style={{
                                        color: '#df6f6f',
                                    }}
                                >{`Deleting you account is permanent`}</TextLatoBold>
                                <TextLato
                                    style={{
                                        color: '#df6f6f',
                                    }}
                                >
                                    {`Your profile, cars, photos, history files, tasks will be permanently deleted and it’s not reversible.`}
                                </TextLato>
                            </div>
                        </NoticeBox>
                        <TextLato
                            style={{
                                paddingBottom: 16,
                                paddingTop: 24,
                                color: '#000',
                            }}
                        >
                            You will lose access to:
                        </TextLato>
                        <InfoBoxContainer>
                            <InfoBox>
                                <InfoBoxText>{cars_no}</InfoBoxText>
                                <InfoBoxSubtext>Cars</InfoBoxSubtext>
                            </InfoBox>
                            <InfoBox>
                                <InfoBoxText>{history_files_no}</InfoBoxText>
                                <InfoBoxSubtext>History Files</InfoBoxSubtext>
                            </InfoBox>
                            <InfoBox>
                                <InfoBoxText>{tasks_no}</InfoBoxText>
                                <InfoBoxSubtext>Tasks</InfoBoxSubtext>
                            </InfoBox>
                        </InfoBoxContainer>
                        <div style={{ paddingTop: 40 }} />
                        <div style={{ height: 30 }}>
                            <CustomAnimatedCheckbox
                                onChange={() => {
                                    handleChange(
                                        'deletion_agreement',
                                        !activeItems.deletion_agreement
                                    )
                                }}
                                name={'deletion agreement'}
                                checked={activeItems.deletion_agreement}
                                onClick={() => {
                                    handleChange(
                                        'deletion_agreement',
                                        !activeItems.deletion_agreement
                                    )
                                }}
                            >
                                <TextLato
                                    style={{
                                        fontSize: 14,
                                        paddingLeft: 8,
                                        color: 'var(--text-default, #666)',
                                        transform: 'translateY(-3px)',
                                    }}
                                >
                                    {
                                        'I understand that the above data will be deleted permanently'
                                    }
                                </TextLato>
                            </CustomAnimatedCheckbox>
                        </div>
                        <div style={{ paddingTop: 8 }} />
                    </Faded>
                )}
                {step_id === 2 && (
                    <Faded>
                        <Radio.Group
                            defaultValue={activeItems.reason}
                            id="account_deletion"
                        >
                            <div>
                                {deletionReasons.map(
                                    (
                                        item: { id: string; text: string },
                                        index: number
                                    ) => (
                                        <RadioStyled
                                            key={index}
                                            id={item.text}
                                            type="radio"
                                            value={item.text}
                                            name={item.text}
                                            onChange={() => {
                                                handleChange(
                                                    'reason',
                                                    item.text
                                                )
                                            }}
                                        >
                                            <div
                                                style={{
                                                    transform:
                                                        'translateY(3px)',
                                                }}
                                            >
                                                {item.text}
                                            </div>
                                        </RadioStyled>
                                    )
                                )}
                            </div>
                        </Radio.Group>

                        <Expander
                            height={
                                activeItems.reason ===
                                    'I found a better alternative' ||
                                activeItems.reason === 'Other'
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <div
                                style={{
                                    paddingTop: 8,
                                    color: 'var(--text-strong, #1a1a1a);',
                                }}
                            >
                                <AnimatedTextarea
                                    id="custom_reason_detail"
                                    name="custom_reason_detail"
                                    value={activeItems.custom_reason_detail}
                                    handleChange={(e: any) =>
                                        handleChange(
                                            'custom_reason_detail',
                                            e.target.value
                                        )
                                    }
                                    fieldTitle={'Please specify'}
                                    placeholder={'Type here... '}
                                    keepLabelRaised
                                />
                            </div>
                        </Expander>
                    </Faded>
                )}
                {step_id === 3 && (
                    <Faded>
                        {/* <div style={{ paddingTop: 24 }} /> */}
                        <InfoBoxContainer>
                            {[1, 2, 3, 4, 5].map((rating) => (
                                <InfoBox
                                    style={{
                                        height: 80,
                                        width: 74,
                                        cursor: 'pointer',
                                        backgroundColor:
                                            activeItems.rating === rating
                                                ? 'var(--primary, #5ec3ca)'
                                                : 'var(--off-bg-color, #fafafa)',
                                    }}
                                    onClick={() =>
                                        handleChange('rating', rating)
                                    }
                                >
                                    <InfoBoxText
                                        style={{
                                            color:
                                                activeItems.rating === rating
                                                    ? 'var(--bg-color, #fff)'
                                                    : 'var(--text-muted, #b3b3b3)',
                                        }}
                                    >
                                        {rating}
                                    </InfoBoxText>
                                </InfoBox>
                            ))}
                        </InfoBoxContainer>
                        <div
                            style={{
                                paddingTop: 24,
                                color: 'var(--text-default, #666666)',
                                fontFamily: 'Lato',
                                fontSize: 14,
                            }}
                        >
                            1 - very dissatisfied, 5 - very satisfied
                        </div>

                        <div style={{ paddingTop: 24 }} />
                    </Faded>
                )}
                {step_id === 4 && (
                    <div style={{ color: 'var(--text-strong, #1a1a1a)' }}>
                        <AnimatedTextarea
                            id="feedback"
                            name="feedback"
                            value={activeItems.feedback}
                            handleChange={(e: any) =>
                                handleChange('feedback', e.target.value)
                            }
                            fieldTitle={'Feedback'}
                            placeholder={'Type here... '}
                            keepLabelRaised
                        />
                    </div>
                )}
                {step_id === 5 && (
                    <Faded>
                        <Radio.Group
                            defaultValue={activeItems.further_feedback}
                            id="account_deletion"
                        >
                            <div>
                                <RadioStyled
                                    id={'yes'}
                                    type="radio"
                                    value={true}
                                    name="yes"
                                    onChange={() => {
                                        handleChange('further_feedback', true)
                                    }}
                                >
                                    <div
                                        style={{
                                            transform: 'translateY(3px)',
                                        }}
                                    >
                                        Yes
                                    </div>
                                </RadioStyled>
                                <RadioStyled
                                    id={'no'}
                                    type="radio"
                                    value={false}
                                    name="no"
                                    onChange={() => {
                                        handleChange('further_feedback', false)
                                    }}
                                >
                                    <div
                                        style={{
                                            transform: 'translateY(3px)',
                                        }}
                                    >
                                        No
                                    </div>
                                </RadioStyled>
                            </div>
                        </Radio.Group>
                    </Faded>
                )}
                {step_id === 6 && (
                    <div>
                        <div
                            style={{
                                color: 'var(--text-default, #666)',
                                fontFamily: 'Lato',
                                fontSize: 16,
                            }}
                        >
                            You are requesting to delete your Custodian account.
                            Your profile created for
                            <span
                                style={{
                                    color: 'var(--text-strong, #1a1a1a)',
                                    fontFamily: 'Lato-semibold',
                                }}
                            >
                                {` ${user.email} `}
                            </span>
                            will no longer be accessible and you will loose all
                            the data you’ve entered to the platform.
                        </div>
                        <div
                            style={{
                                paddingTop: 24,
                                paddingBottom: 24,
                                color: 'var(--text-strong, #1a1a1a)',
                                fontFamily: 'Lato-semibold',
                                fontSize: 16,
                            }}
                        >
                            Type your email to confirm deletion
                        </div>
                        <div
                            style={{
                                color: 'var(--text-strong, #1a1a1a)',
                            }}
                        >
                            <StandardInputField
                                theme={theme}
                                id={'userEmail'}
                                name={'userEmail'}
                                placeholder={'email'}
                                tabindex={1}
                                aria-live="polite"
                                formikprops={props}
                                value={activeItems.userEmail}
                                font_family="Lato"
                                paddingLeft="0px"
                                width="100%"
                                fontSize={'16px'}
                                onChange={(e: any) => {
                                    handleChange('userEmail', e.target.value)
                                }}
                                dataCyId={'user_email'}
                                linecolor="var(--text-muted, #b3b3b3)"
                                type={'email'}
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={
                                    activeItems.userEmail.length > 1 &&
                                    activeItems.userEmail !== user.email
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <Faded>
                                    <StyledFormError>
                                        Typed email doesn't match
                                    </StyledFormError>
                                </Faded>
                            </Expander>
                        </div>
                        <div style={{ paddingTop: 8 }} />
                    </div>
                )}
            </DeletionStep>
        </Wrapper>
    ) : (
        <div style={{ display: 'none' }} />
    )
}

export default AccountDeletionManagerDesktop
