import { ITimelineEditForms } from 'myModels'
import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";

import { connect } from 'react-redux'
import { ICostItem, ITimelineItem } from 'timelineModels'

import { RootState } from 'typesafe-actions'

import TimelineGenerateEditFormModeDesktop from '../timelineFormGenerators/timelineGenerateEditFormModeDesktop'

function mapStateToProps(state: RootState) {
    return {}
}

// const dispatchProps = {}

export interface Props {
    timeline_item?: ITimelineItem | undefined | null
    item_to_edit: ITimelineItem | ICostItem | null | undefined
    timelineItemEditForm: ITimelineEditForms
    savingEdit: any
    onDeleteClick?: () => void
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    readOnlyMode?: boolean
}

type State = {
    edited_item: ITimelineItem | ICostItem | null | undefined
}

class TimelineEditManagerDesktop extends React.Component<Props, State> {
    state = {
        edited_item: null,
    }

    editNewItem = (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => {
        let updated_item: ITimelineItem | ICostItem | null =
            this.state.edited_item !== null
                ? Object.assign({}, this.state.edited_item)
                : Object.assign({}, this.props.timeline_item)

        if (updated_item !== null) {
            if (this.props.entityType === 'cost' && fieldID === 'attachment') {
                if (newValue === null) {
                    updated_item.attachmentID = undefined
                } else {
                    updated_item.attachmentID = newValue.id
                    updated_item.attachment = newValue
                }
            } else {
                updated_item = { ...updated_item, [fieldID]: newValue }
            }

            this.setState({ edited_item: updated_item })
        }
    }

    componentDidMount() {
        this.setState({ edited_item: this.props.item_to_edit })
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.timeline_item !== this.props.timeline_item) {
            this.setState({ edited_item: this.props.item_to_edit })
        }

        if (prevProps.item_to_edit !== this.props.item_to_edit) {
            this.setState({ edited_item: this.props.item_to_edit })
        }
    }

    componentWillUnmount() {
        this.setState({ edited_item: null })
    }

    render() {
        let { edited_item } = this.state
        let {
            item_to_edit,
            timelineItemEditForm,
            savingEdit,
            onDeleteClick,
            closeForm,
            dataCyId,
            entityID,
            carID,
            entityType,
            readOnlyMode,
        } = this.props

        return (
            <React.Fragment>
                {item_to_edit && timelineItemEditForm.id && (
                    <TimelineGenerateEditFormModeDesktop
                        item_to_edit={item_to_edit}
                        timelineItemEditForm={timelineItemEditForm}
                        edited_item={edited_item}
                        onChange={this.editNewItem}
                        onClickSave={() => savingEdit(this.state.edited_item)}
                        onDeleteClick={onDeleteClick}
                        closeForm={closeForm}
                        dataCyId={dataCyId}
                        entityID={entityID}
                        carID={carID}
                        entityType={entityType}
                        readOnlyMode={readOnlyMode}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, undefined)(TimelineEditManagerDesktop)
