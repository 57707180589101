import pcclogo from '../../../public/assets/logo/newlogo.svg'
import styled from 'styled-components'

const MobileLogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100vw;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    margin-right: 20px;
`

const MobileItem = styled.div`
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    color: black;
`

const MobileLogo = styled.img`
    height: 40px;
`

const MobileLogoHeader = () => (
    <MobileLogoContainer>
        <MobileLogo src={pcclogo} alt="PCC" />
        <TextContainer>
            <MobileItem> Private </MobileItem>
            <MobileItem> collectors </MobileItem>
            <MobileItem> club </MobileItem>
        </TextContainer>
    </MobileLogoContainer>
)

export default MobileLogoHeader
