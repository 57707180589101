import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../public/assets/placeholders_svgs/avatar_dark.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

type IStyleProps = {
    hasImage?: boolean
}

const UserImgWrapper = styled.div<IStyleProps>`
    /* position: absolute; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--base-overlays-neutral-alpha-50);
    border-radius: 16px;
    padding: 8px;

    border: 1px solid var(--base-overlays-neutral-alpha-100);
    @media ${device.desktop} {
        height: 110px;
        width: 110px;
    }

    @media ${device.smallest_laptops} {
        height: 100px;
        width: 100px;
    }

    @media ${device.ipad} {
        height: 100px;
        width: 100px;
    }

    @media ${device.large_desktop} {
        height: 110px;
        width: 110px;
    }
`

const UserImg = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    min-width: 108px;
    margin-right: 40px;
    margin-left: 20px;

    @media ${device.smallest_laptops} {
        margin-right: 30px;
        margin-left: 20px;
    }

    @media ${device.ipad} {
        min-width: 50px;
        margin-right: 10px;
        margin-left: 10px;
    }
`
type Props = {
    size?: string
    url?: any
}

type State = {
    src: any
    isLoading: boolean
}

class UserAccountCircleLarge extends React.Component<Props, State> {
    state = {
        src: undefined,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true

        const src =
            this.props.url !== undefined &&
            this.props.url !== false &&
            this.props.url !== null &&
            this.props.url.length > 1
                ? this.props.url
                : undefined

        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props?.url !== prevProps?.url) {
            this.setState({ src: null, isLoading: true })
            const src =
                this.props.url !== undefined &&
                this.props.url !== false &&
                this.props.url !== null &&
                this.props.url.length > 1
                    ? this.props.url
                    : undefined

            if (src) {
                return this.setState({ src: src, isLoading: false })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper>
                        <UserImgWrapper
                            hasImage={
                                this.state.src === undefined ? false : true
                            }
                        >
                            <UserImg
                                src={
                                    this.state.src !== undefined
                                        ? this.state.src
                                        : theme === 'dark'
                                        ? blank_user_dark
                                        : blank_user
                                }
                                onLoad={() =>
                                    this._isMounted &&
                                    this.setState({
                                        ...this.state,
                                        isLoading: false,
                                    })
                                }
                            />
                            <SkeletonAbsolute
                                isthingloading={this.state.isLoading}
                                // isRound={true}
                            />
                        </UserImgWrapper>
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default UserAccountCircleLarge
