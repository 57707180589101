import * as urls from '../urls'

export const checkTurnstileToken = (token: string): Promise<any> => {
    let body = {
        token: token,
    }
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        credentials: 'include',
    }

    return fetch(urls.checkTurnstileTokenURL, requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                const error = response.error || response.status
                return Promise.reject(error)
            }
        })
        .catch((error: any) => Promise.reject(error))
}
