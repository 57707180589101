import { IDropdownItem } from 'entityModels'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickAway } from '../../../helpers/hooks/domHooks'
import { StandardInputField } from '../Inputfield/inputField'
import { SearchIcon } from '../statefulicons'
import Dropdown from './Dropdown'
import { useListNavigation } from './hooks'
import { Option } from './types'
import React from 'react'
import { useAppDispatch } from '../../../redux/store/hooks'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import useThemes from '../../../providers/theme/hooks'
import ChevronDown from '../icons/components/chevronDown'

interface ComboboxProps {
    name?: string
    placeholder?: string
    helperText?: string
    value?: string
    field_id?: string
    defaultValue?: string
    options: Option[]
    disabled?: boolean
    iconsSize?: string
    onChange?: (value: any, other?: string) => void
    clearValueTyped?: () => void
    sendDropdownItem?: boolean
    height?: string
    /**
     * If supplied, the internal filter mechanism
     * will be disabled.
     */
    onSearch?: (value: string) => void
    /**
     * When true, the text inside the search box
     * will be selected when clicked.
     * Ignored if `enableSearch` is false.
     *
     * Default: true
     */
    selectOnFocus?: boolean
    /**
     * Component to render when the list is empty,
     * either because the supplied `options` array
     * is empty, or the filtered list is empty.
     */
    fallbackContent?: React.ReactNode
    onInputKeyDown?: React.KeyboardEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    >
    backgroundColor?: string
    borderColor?: string
    textTransform?: string
    customActiveColour?: string
    customHighlightColour?: string
}

export default function Combobox(props: ComboboxProps) {
    const [isListOpen, setIsListOpen] = useState(false)

    const [searchBoxText, setSearchBoxText] = useState(
        props.defaultValue ?? props.value ?? ''
    )

    /**
     * If a `value` is supplied as a prop, we treat this as a controlled
     * component, in which case we react to changes to `props.value`.
     */
    useEffect(() => {
        if (typeof props.value !== 'string') return

        setSearchBoxText((prevSearchBoxText) => {
            if (props.value !== prevSearchBoxText) {
                return props.value ?? prevSearchBoxText
            } else {
                return prevSearchBoxText
            }
        })
    }, [props.value])

    const openList = () => {
        setIsListOpen(true)
    }

    const closeList = () => {
        setIsListOpen(false)
        setSearchBoxText(
            props.value
                ? props.value
                : props.defaultValue
                ? props.defaultValue
                : ''
        )
        props.clearValueTyped && props.clearValueTyped()
    }

    const textInputRef = useRef<HTMLInputElement>(null)
    const listRef = useClickAway(closeList)

    const handleSearchBoxFocus = () => {
        openList()

        const selectOnFocus = props.selectOnFocus ?? true

        if (selectOnFocus) {
            textInputRef.current?.select()
        }
    }

    let appDispatch = useAppDispatch()

    const selectOption = (option: Option) => {
        // console.log('here it is option:', option, props.field_id)
        if (
            props.field_id &&
            props.field_id === 'make' &&
            !props.sendDropdownItem
        ) {
            setSearchBoxText(option.label)
            appDispatch(dropDownActions.setTempMakeIDSuccess(option.value))
            appDispatch(dropDownActions.setTempMakeDisplay(option.label))
            props.onChange?.(option.label, option.value)
            return
        }

        if (
            props.field_id &&
            props.field_id === 'model' &&
            !props.sendDropdownItem
        ) {
            setSearchBoxText(option.label)
            // console.log('sending from l 125option.value ', option.label)
            appDispatch(dropDownActions.setTempModelIDSuccess(option.value))
            appDispatch(dropDownActions.setTempModelDisplay(option.label))
            props.onChange?.(option.label, option.value)
            return
        }

        if (
            props.field_id &&
            props.field_id === 'make' &&
            props.sendDropdownItem
        ) {
            appDispatch(dropDownActions.setTempMakeIDSuccess(option.value))
        }

        if (
            props.field_id &&
            props.field_id === 'model' &&
            props.sendDropdownItem
        ) {
            // console.log('sending this here ; 148 option.value', option.value)
            appDispatch(dropDownActions.setTempModelIDSuccess(option.value))
        }

        setIsListOpen(false)
        if (option) {
            setSearchBoxText(option.label)

            if (props.sendDropdownItem) {
                let itemm: IDropdownItem = {
                    uid: option.value,
                    name: option.label,
                    code: option?.code,
                }
                props.onChange?.(itemm)
            } else props.onChange?.(option.value)
        }
    }

    const { highlightedIndex, setHighlightedIndex, handleKeys } =
        props.options &&
        useListNavigation({
            listLength: props.options.length,
            onListItemSelect: (index) => {
                const option = props.options[index]
                if (option) {
                    selectOption(option)
                }
            },
        })

    const handleSearchBoxChange = (
        event: React.ChangeEvent<HTMLInputElement> & KeyboardEvent
    ) => {
        setHighlightedIndex(0)
        props.onSearch?.(event.target.value)

        setSearchBoxText(event.target.value)

        setIsListOpen(true)
    }

    const { theme } = useThemes()

    return (
        <FormWrapper
            onClick={() => textInputRef.current?.focus()}
            disabled={props.disabled}
        >
            <StandardInputField
                theme={theme}
                height={props.height}
                customActiveColour={props.customActiveColour}
                backgroundColor={props.backgroundColor}
                linecolor={props.borderColor}
                ref={textInputRef}
                name={props.name}
                // value={
                //     searchBoxText && searchBoxText.length > 0
                //         ? searchBoxText
                //         : props.defaultValue
                // }
                value={searchBoxText}
                defaultValue={
                    searchBoxText && searchBoxText.length > 0
                        ? searchBoxText
                        : props.defaultValue
                }
                placeholder={props.placeholder}
                helperText={props.helperText}
                disabled={props.disabled}
                onFocus={handleSearchBoxFocus}
                onClick={handleSearchBoxFocus}
                onChange={handleSearchBoxChange}
                onKeyDown={(e) => {
                    props.onInputKeyDown?.(e)
                    handleKeys(e)
                }}
                rightAdornment={
                    isListOpen ? (
                        <SearchIcon
                            height={20}
                            style={{ padding: '2px 0' }}
                            isActive={true}
                            color={props.customActiveColour}
                        />
                    ) : (
                        <ChevronDownWrapper
                            onClick={() => textInputRef.current?.focus()}
                            disabled={props.disabled}
                            style={{
                                height: props.iconsSize ?? '20px',
                                width: props.iconsSize ?? '19px',
                            }}
                            color={props.customActiveColour}
                        >
                            <ChevronDown />
                        </ChevronDownWrapper>
                    )
                }
                textTransform={
                    props.textTransform ? props.textTransform : undefined
                }
            />
            <Dropdown
                ref={listRef}
                isOpen={isListOpen}
                nudgeUp={!!props.helperText}
            >
                {props.fallbackContent && (
                    <FallbackContentWrapper>
                        {props.fallbackContent}
                    </FallbackContentWrapper>
                )}
                {props.options &&
                    props.options.length > 0 &&
                    props.options.map((option, index) => (
                        <React.Fragment key={`combobox_${props.name}_${index}`}>
                            <OptionItem
                                {...option}
                                key={option.value}
                                highlighted={index === highlightedIndex}
                                onSelect={() => selectOption(option)}
                                onHighlight={() => setHighlightedIndex(index)}
                                customHighlightColour={
                                    props.customHighlightColour
                                }
                            />
                        </React.Fragment>
                    ))}
            </Dropdown>
        </FormWrapper>
    )
}

interface OptionProps {
    value: string
    label?: string
    highlighted?: boolean
    onSelect: (value: string) => void
    onHighlight?: () => void
    customHighlightColour?: string
}

export function OptionItem(props: OptionProps) {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (props.highlighted) {
            ref.current?.scrollIntoView({ block: 'nearest' })
        }
    }, [props.highlighted])

    return (
        <ListItem
            ref={ref}
            onClick={(e) => {
                e.stopPropagation()
                props.onSelect(props.value)
            }}
            onMouseOver={props.onHighlight}
            highlighted={props.highlighted}
            customHighlightColour={props.customHighlightColour}
        >
            {props.label}
        </ListItem>
    )
}

const FormWrapper = styled.div<{ disabled?: boolean }>`
    width: 100%;
    position: relative;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
    opacity: ${(props) => (props.disabled ? '0.5' : 'initial')};
`

const ChevronDownWrapper = styled.div<{ disabled?: boolean }>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ListItem = styled.div<{
    highlighted?: boolean
    customHighlightColour?: string
}>`
    padding: 8px 16px;
    cursor: pointer;
    color: var(--text-strong, #1a1a1a);

    ${(props) => `
        background-color: ${
            props.highlighted
                ? props.customHighlightColour ?? 'var(--primary_08)'
                : 'initial'
        };
    `}
`

const FallbackContentWrapper = styled.div`
    padding: 8px 10px;
    cursor: pointer;
`
