import { useState } from 'react'
import styled from 'styled-components'

import CrossIcon from '../../../../atoms/icons/crossIcon'
import InfoIcon from '../../../../atoms/icons/infoIcon'

import { IEditShowroomModalOptions } from '../../../../pages/showroom/car/showroomCarProfileEdit'
import ShowroomValidationSheetManager from './showroom/showroomValidationSheetManager'

type Props = {
    role?: string
    handleSetModalOpened: (p: IEditShowroomModalOptions) => any
    showInfo?: boolean
    isPublished: boolean
    infoTxtDesktop?: string
    infoTxtMobile?: string
    isMobileRemoved?: boolean
    entryid: string
    isSold?: boolean
}

type StyleProps = {
    $role?: string
    $isMobile?: boolean
}
export type IValidation_subObject = {
    check: boolean
    text: string
    action: () => any
    isDisabled?: boolean
    hasNitems?: number
    minN?: number
    tooltipText?: string
    isTooltipRemoved?: boolean
}

export type IValidation_subObject_normalised = {
    [key: string]: IValidation_subObject
}
export type IPublishValidationObject = {
    [key: string]: {
        check: boolean
        text: string
        action: any
        hasNitems?: number
        minN?: number
        subItems?: IValidation_subObject_normalised
        tooltipText?: string
        isTooltipRemoved?: boolean
        isDisabled?: boolean
        isCaps?: boolean
    }
}

const WrapperDesktop = styled.div`
    @media (max-width: 800px) {
        display: none !important;
    }
`

const WrapperMobile = styled.div`
    @media (min-width: 800px) {
        display: none !important;
    }
`

const InfoBox = styled.div<StyleProps>`
    position: fixed;
    bottom: 5rem;
    left: 0px;
    right: 0px;

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 5rem;
    background-color: var(--background_primary_soft);
    transition: all 100ms;
    color: var(--text-default, #666666);
    font-size: 14px;
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    z-index: 0;
`

const CarShowroomEditBottomBar = (props: Props) => {
    const [dismissInfo, setDismissInfo] = useState<boolean>()

    return (
        <>
            <WrapperDesktop>
                {props.showInfo && dismissInfo !== true && (
                    <InfoBox>
                        <InfoIcon color="var(--primary, #5EC3CA)" />
                        <div style={{ paddingLeft: '20px' }} />

                        {props.infoTxtDesktop && props.infoTxtDesktop}

                        <div
                            style={{
                                position: 'absolute',
                                right: '50px',
                                top: '2.2rem',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setDismissInfo(true)
                            }}
                        >
                            <CrossIcon />
                        </div>
                    </InfoBox>
                )}

                <ShowroomValidationSheetManager {...props} />
            </WrapperDesktop>

            {props.isMobileRemoved !== true && (
                <WrapperMobile>
                    {props.showInfo && dismissInfo !== true && (
                        <InfoBox
                            onClick={() => {
                                setDismissInfo(true)
                            }}
                        >
                            {props.infoTxtMobile && props.infoTxtMobile}

                            <div
                                style={{
                                    position: 'absolute',
                                    right: '16px',
                                    top: '2.2rem',
                                    cursor: 'pointer',
                                }}
                            >
                                <CrossIcon />
                            </div>
                        </InfoBox>
                    )}

                    <ShowroomValidationSheetManager {...props} />
                </WrapperMobile>
            )}
        </>
    )
}

export default CarShowroomEditBottomBar
