import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import Loader from '../atoms/loader/loader'
import { IUser } from 'myModels'
import TriangleHeader from '../atoms/header/triangleheader'
import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'
import { RouteComponentProps } from 'react-router-dom'
import VerifiedFormikResetPassword from '../organisms/resetpasswordform/verifiedFormikResetPassword'
import styled from 'styled-components'
import { device } from '../templates/displays/devices'
import Faded from '../templates/animated/faded'
import { Paragraph } from '../atoms/typography'
import ButtonAtom from '../atoms/Button/ButtonAtom'
import { Link } from 'react-router-dom'
import {
    // logoutRequest,
    verifyResetPasswordCodeRequest,
} from '../../redux/user/actions/loadingActions'
import { IReduxError } from 'entityModels'

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    @media ${device.desktop} {
        padding-top: 80px;
    }

    @media ${device.ipad} {
        padding-top: 100px;
    }

    @media ${device.mobile} {
        padding-top: 20px;
    }
`

const Padding = styled.div`
    @media ${device.ipad} {
        padding-top: 1vh;
    }
    @media ${device.desktop} {
        padding-top: 2vh;
    }
    @media ${device.mobile} {
        margin-top: 0px;
    }
`
const LineHeight = styled.div`
    @media ${device.mobile} {
        line-height: 2 !important;
    }
`

const PaddingSuccess = styled.div`
    @media ${device.ipad} {
        padding-top: 50px;
    }
    @media ${device.desktop} {
        padding-top: 4vh;
    }
    @media ${device.mobile} {
        padding-top: 30px;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media ${device.ipad} {
        margin-top: 60px;
    }
    @media ${device.desktop} {
        margin-top: 40px;
    }
    @media ${device.mobile} {
        margin-top: 40px;
    }
`

const dispatchProps = {
    ResetPasswordVerifyCodeEmail: (verificationcode: string) =>
        verifyResetPasswordCodeRequest(verificationcode),

    // logout: () => logoutRequest(),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isLoading: state.user.loading,
        error: state.user.error,
    }
}

export type State = {
    sent: boolean
    uid: string | null
}

interface Props extends RouteComponentProps<any> {
    userLoggedIn: null | IUser
    ResetPasswordVerifyCodeEmail: (verificationcode: string) => void
    logout: () => void
    error: IReduxError
}

class ResetPasswordForm extends React.Component<Props, State> {
    state = {
        sent: false,
        uid: '',
    }
    setSubmit = () => {
        return this.setState({
            sent: true,
        })
    }

    toggleSent = () => {
        return this.setState({
            sent: false,
        })
    }

    componentDidMount() {
        this.props.ResetPasswordVerifyCodeEmail(
            this.props.match.params.activationcode
        )

        this.props.userLoggedIn !== null &&
            this.setState({
                uid: this.props.userLoggedIn.id,
            })
    }

    render() {
        const { sent } = this.state
        return (
            <PublicPageWrapper>
                <Loader />

                <Wrapper>
                    <TriangleHeader title="Reset Password" />
                    <Padding />
                    {sent === true && this.props.error === null && (
                        <Faded>
                            <div className="animated fadeIn faster">
                                <PaddingSuccess />
                                <LineHeight>
                                    <Paragraph align="center">
                                        Thank you, your password has
                                        successfully been reset.
                                        <br />
                                        Click the button below to sign in with
                                        your new password.
                                    </Paragraph>
                                </LineHeight>

                                <ButtonContainer>
                                    <ButtonAtom
                                        theme="primary"
                                        // onClick={() => {
                                        //     this.props.logout()
                                        //     window.location.reload()
                                        // }}
                                    >
                                        <Link to="/signin">sign in</Link>
                                    </ButtonAtom>
                                </ButtonContainer>
                            </div>
                        </Faded>
                    )}

                    {this.props.error !== null && (
                        <Faded>
                            <div className="animated fadeIn faster">
                                <PaddingSuccess />
                                <LineHeight>
                                    <Paragraph align="center">
                                        The verification code is either expired,
                                        redeemed or invalid.
                                        <br /> <br />
                                        The password reset link is only valid
                                        for 15 minutes and is single-use.
                                        <br /> <br />
                                        Please try again, we apologise for the
                                        inconvenience caused.
                                    </Paragraph>
                                </LineHeight>

                                <Link to="/reset-password-request">
                                    <ButtonContainer>
                                        <ButtonAtom
                                            theme="primary"
                                            onClick={this.toggleSent}
                                        >
                                            try again
                                        </ButtonAtom>
                                    </ButtonContainer>
                                </Link>
                            </div>
                        </Faded>
                    )}

                    {sent === false &&
                        this.props.error === null &&
                        this.props.userLoggedIn !== null && (
                            <VerifiedFormikResetPassword
                                uid={this.props.userLoggedIn.id}
                                setSubmit={this.setSubmit}
                            />
                        )}
                </Wrapper>
            </PublicPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(ResetPasswordForm)
