import { ISearchShowroom_API_car_item } from '../../redux/services/showroom/types'
import { IShowroomEntry, IShowroomEntryAPI } from '../../redux/showroom/types'

export const convertShowroomEntryPriceInfoToUIString = (
    p: IShowroomEntry | ISearchShowroom_API_car_item | IShowroomEntryAPI
) => {
    let res = {
        local_price: '',
        listing_price: '',
    }

    if (p.type_of_sale === 'PRICE_ON_ASKING') {
        res.local_price = 'POA'
        res.listing_price = 'POA'
    }

    let local_curr: string =
        p.local_currency === 'EUR'
            ? '€'
            : p.local_currency === 'USD'
            ? '$'
            : '£'

    if (
        p.type_of_sale === 'PRICE_RANGE' &&
        p.local_price_min &&
        p.local_price_max
    ) {
        res.local_price = `${local_curr}  ${(+p.local_price_min).toLocaleString()} - ${(+p.local_price_max).toLocaleString()}`
    }

    if (p.type_of_sale === 'SET_PRICE' && p.local_price_min) {
        res.local_price = `${local_curr}  ${(+p.local_price_min).toLocaleString()}`
    }

    let listing_currency: string =
        p.listing_currency === 'EUR'
            ? '€'
            : p.listing_currency === 'USD'
            ? '$'
            : '£'

    if (
        p.type_of_sale === 'PRICE_RANGE' &&
        p.listing_price_min &&
        p.listing_price_max
    ) {
        res.listing_price = `${listing_currency}  ${(+p.listing_price_min).toLocaleString()} - ${(+p.listing_price_max).toLocaleString()}`
    }

    if (p.type_of_sale === 'SET_PRICE' && p.listing_price_min) {
        res.listing_price = `${listing_currency}  ${(+p.listing_price_min).toLocaleString()}`
    }

    return res
}
