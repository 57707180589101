import { IDropdownItem, IList } from 'entityModels'
import { IExternalURLApi, IUserPayload } from 'IapiDataPayload'
import { IapiDataPayload_Normalised, IUser } from 'myModels'
// import { mockUserCustomTags } from '../../timeline/mockData'

export const ConvertUserApitoUserState = (
    userData: IUserPayload,
    timeZoneList?: IDropdownItem[] | null
): IUser => {
    // time-zone data

    const time_zone_ids_list: string[] = []

    const time_zone_data: { [key: string]: IList } = {}

    if (timeZoneList) {
        timeZoneList.forEach((item) => {
            time_zone_ids_list.push(item.name)
            time_zone_data[item.name] = {
                text: item.name,
                answer: item.name,
                id: item.name,
                name: item.name,
            }
        })
    }

    const cleanUpName = (name: string) => {
        if (name.includes('_')) {
            return name.replace(/_/g, ' ')
        } else return name
    }

    // notifications hours list and data

    const hours_ids_list: string[] = []
    for (let i = 0; i < 25; i++) {
        i < 10
            ? hours_ids_list.push(`0${i}:00`)
            : hours_ids_list.push(`${i}:00`)
    }

    const hours_data: { [key: string]: IList } = {}

    hours_ids_list.forEach((id) => {
        hours_data[id] = {
            text: id,
            answer: id,
            id: id,
            name: id,
        }
    })

    let normalised_external_links: IapiDataPayload_Normalised = {
        WEBSITE: undefined,
        FACEBOOK: undefined,
        LINKEDIN: undefined,
        INSTAGRAM: undefined,
        TWITTER: undefined,
    }

    if (userData.external_urls) {
        userData.external_urls.forEach((item: IExternalURLApi) => {
            normalised_external_links = {
                ...normalised_external_links,
                [item.url_type]: { ...item },
            }
        })
    }

    let user: IUser = {
        id: userData.uid,
        given_name: userData.given_name,
        family_name: userData.family_name,
        middle_names: userData.middle_names,
        display_name: userData.display_name
            ? userData.display_name
            : `${userData.given_name} ${userData.family_name}`,
        email: userData.email,
        profile_image_url:
            userData.profile_image_url && userData.profile_image_url,
        owns_garage: userData.owns_garage,
        one_liner: userData.bio ? userData.bio : '',
        phone: userData.phone && userData.phone,
        role:
            userData.role === 'Admin'
                ? 'Admin'
                : userData.role === 'Dealer'
                ? 'Dealer'
                : undefined,

        // cars_shared_with_user: {
        //     car_ids: ['ext_car_001', 'ext_car_002'],
        //     active_display: {
        //         activeSort: 'updated_at',
        //         activeFilter: [],
        //         item_ids_display: ['ext_car_001', 'ext_car_002'],
        //     },
        // },

        user_primary_use: userData.user_primary_use
            ? userData.user_primary_use
            : undefined,
        user_secondary_use: userData.user_secondary_use
            ? userData.user_secondary_use
            : undefined,
        labels: userData.entry_labels ? userData.entry_labels : undefined,
        verified: false,
        invite_code: userData.invite_code,
        address:
            userData.addresses &&
            userData.addresses[userData.addresses.length - 1],
        // userTimelineCustomTags: mockUserCustomTags,
        external_urls: { ...normalised_external_links },
        login_mode: userData.login_mode,
        onboarding_viewed: userData.onboarding_viewed,
        ui_appearance_mode: userData?.user_preferences?.ui_appearance_mode,
        preferences: {
            ids_list: ['notifications', 'units', 'garage'],
            data: {
                notifications: {
                    field_id: 'notifications',
                    ids_list: ['time_zone', 'preferred_notification_time'],
                    data: {
                        time_zone: {
                            user_choice_id:
                                userData.user_preferences &&
                                userData.user_preferences.time_zone
                                    ? cleanUpName(
                                          userData.user_preferences.time_zone
                                      )
                                    : 'Europe/London',
                            field_id: 'time_zone',
                            ids_list: time_zone_ids_list,
                            data: time_zone_data,
                        },
                        preferred_notification_time: {
                            user_choice_id:
                                userData.user_preferences &&
                                userData.user_preferences
                                    .preferred_notification_time
                                    ? userData.user_preferences
                                          .preferred_notification_time
                                    : '08:00',
                            ids_list: hours_ids_list,
                            field_id: 'preferred_notification_time',
                            data: hours_data,
                        },
                    },
                },

                ///////
                units: {
                    field_id: 'units',
                    ids_list: [
                        'distance_unit',
                        'fuel_unit',
                        'default_currency',
                    ],
                    data: {
                        distance_unit: {
                            user_choice_id:
                                userData.user_preferences &&
                                userData.user_preferences.distance_unit &&
                                (userData.user_preferences.distance_unit ===
                                    'miles' ||
                                    userData.user_preferences.distance_unit ===
                                        'kilometers')
                                    ? userData.user_preferences.distance_unit
                                    : 'miles',
                            field_id: 'distance_unit',
                            ids_list: ['miles', 'kilometers'],
                            data: {
                                miles: {
                                    text: 'Miles',
                                    answer: 'miles',
                                    id: 'miles',
                                    name: 'miles',
                                    short: 'mi',
                                },
                                kilometers: {
                                    text: 'Kilometres',
                                    answer: 'kilometers',
                                    id: 'kilometers',
                                    name: 'kilometers',
                                    short: 'km',
                                },
                            },
                        },
                        fuel_unit: {
                            user_choice_id:
                                userData.user_preferences &&
                                userData.user_preferences.fuel_unit &&
                                (userData.user_preferences.fuel_unit ===
                                    'liters' ||
                                    userData.user_preferences.fuel_unit ===
                                        'cubic_centimeters' ||
                                    userData.user_preferences.fuel_unit ===
                                        'cubic_inches')
                                    ? userData.user_preferences.fuel_unit
                                    : 'cubic_centimeters',
                            ids_list: [
                                'cubic_centimeters',
                                'cubic_inches',
                                'liters',
                            ],
                            field_id: 'fuel_unit',
                            data: {
                                cubic_centimeters: {
                                    text: 'Cubic centimetres (cc)',
                                    answer: 'cubic centimeters',
                                    short: 'cc',
                                    id: 'cubic_centimeters',
                                    name: 'cubic centimeters',
                                },
                                cubic_inches: {
                                    text: 'Cubic inches (ci)',
                                    answer: 'cubic inches',
                                    short: 'ci',
                                    id: 'cubic_inches',
                                    name: 'cubic inches',
                                },
                                liters: {
                                    text: 'Litres (L)',
                                    answer: 'liters',
                                    short: 'L',
                                    id: 'liters',
                                    name: 'liters',
                                },
                            },
                        },

                        default_currency: {
                            user_choice_id:
                                userData.user_preferences &&
                                userData.user_preferences.default_currency &&
                                (userData.user_preferences.default_currency ===
                                    'GBP' ||
                                    userData.user_preferences
                                        .default_currency === 'USD' ||
                                    userData.user_preferences
                                        .default_currency === 'EUR')
                                    ? userData.user_preferences.default_currency
                                    : 'GBP',
                            ids_list: ['GBP', 'USD', 'EUR'],
                            field_id: 'default_currency',
                            data: {
                                GBP: {
                                    text: 'GBP',
                                    answer: 'GBP',
                                    short: 'GBP',
                                    id: 'GBP',
                                    name: 'GBP',
                                },
                                USD: {
                                    text: 'USD',
                                    answer: 'USD',
                                    short: 'USD',
                                    id: 'USD',
                                    name: 'USD',
                                },
                                EUR: {
                                    text: 'EUR',
                                    answer: 'EUR',
                                    short: 'EUR',
                                    id: 'EUR',
                                    name: 'EUR',
                                },
                            },
                        },
                    },
                },
                garage: {
                    field_id: 'garage',
                    ids_list: ['default_garage_view'],
                    data: {
                        default_garage_view: {
                            user_choice_id:
                                userData.user_preferences &&
                                userData.user_preferences.default_garage_view &&
                                (userData.user_preferences
                                    .default_garage_view === 'grid' ||
                                    userData.user_preferences
                                        .default_garage_view === 'list')
                                    ? userData.user_preferences
                                          .default_garage_view
                                    : '',
                            ids_list: ['grid', 'list'],
                            field_id: 'default_garage_view',
                            data: {
                                grid: {
                                    text: 'Grid view',
                                    answer: 'grid',
                                    id: 'grid',
                                },
                                list: {
                                    text: 'List view',
                                    answer: 'list',
                                    id: 'list',
                                },
                            },
                        },
                    },
                },
            },
        },
        total_tasks_count: userData.total_tasks_count ?? 0,
    }

    return user
}
