// create store for todos (basically reducer for the main final store)

import { ITechnicalInformationState } from 'entityModels'

import * as actions from './actions/actions'

import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: ITechnicalInformationState = {
    technical_information: {},
    list: [
        'make',
        'model',
        'year',
        'body_type',
        'drive_side',
        'transmission',
        'mileage',
        'engine_capacity',
        'registration_number',
        'registration_date',
        'last_owner_change',
        'service_check_status',
        'service_check_expiry',
        'tax_status',
        'tax_due',
        'color_exterior',
        'color_interior',
        'fuel_type',
        'drive_train',
        'chassis_number',
        // 'condition',
        // 'features',
    ],
    error: null,
    loading: false,
}

export const technicalInformationData = (
    state: ITechnicalInformationState = initialState,
    action: LoadingActions | Action | ErrorActions
): ITechnicalInformationState => {
    switch (action.type) {
        // REQUESTS
        case loadingActions.getTechnicalInformationRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.getTechnicalInformationError.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: action.payload,
            })

        // SUCCESS
        case actions.getTechnicalInformationSuccess.type:
            return Object.assign({}, state, {
                ...state,
                technical_information: {
                    ...state.technical_information,
                    [action.payload.id]: action.payload,
                },

                loading: false,
                error: null,
            })

        case loadingActions.writeTechnicalInformationRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case loadingActions.writeMultipleTechnicalInformationRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.writeTechnicalInformationError.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: action.payload,
            })

        // SUCCESS
        case actions.writeTechnicalInformationSuccess.type:
            let newObj = {}

            if (state.technical_information[action.payload.tech_info_id]) {
                if (
                    // @ts-ignore
                    state.technical_information[action.payload.tech_info_id][
                        action.payload.id
                    ]
                ) {
                    newObj = {
                        ...state.technical_information[
                            action.payload.tech_info_id
                        ],
                        [action.payload.id]: {
                            // @ts-ignore
                            ...state.technical_information[
                                action.payload.tech_info_id
                            ][action.payload.id],
                            ...action.payload.data,
                        },
                    }
                } else {
                    newObj = {
                        ...state.technical_information[
                            action.payload.tech_info_id
                        ],
                        [action.payload.id]: {
                            ...action.payload.data,
                        },
                    }
                }
                newObj = {
                    ...state.technical_information[action.payload.tech_info_id],
                    [action.payload.id]: {
                        // @ts-ignore
                        ...state.technical_information[
                            action.payload.tech_info_id
                        ][action.payload.id],
                        ...action.payload.data,
                    },
                }
            } else {
                newObj = {
                    id: action.payload.tech_info_id,
                    [action.payload.id]: {
                        ...action.payload.data,
                    },
                }
            }

            return Object.assign({}, state, {
                ...state,
                technical_information: {
                    ...state.technical_information,
                    [action.payload.tech_info_id]: {
                        ...newObj,
                    },
                },

                loading: false,
                error: null,
            })

        case loadingActions.setTechnicalInformationRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.setTechnicalInformationError.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: action.payload,
            })

        // SUCCESS
        case actions.setTechnicalInformationSuccess.type:
            return Object.assign({}, state, {
                ...state,
                technical_information: {
                    ...state.technical_information,
                    ...action.payload,
                },

                loading: false,
                error: null,
            })

        case actions.cleanUpTechnicalInformationSuccess.type:
            return Object.assign({}, state, {
                technical_information: {},
                list: [
                    'make',
                    'model',
                    'year',
                    'body_type',
                    'drive_side',
                    'transmission',
                    'mileage',
                    'engine_capacity',
                    'registration_number',
                    'registration_date',
                    'last_owner_change',
                    'service_check_status',
                    'service_check_expiry',
                    'tax_status',
                    'tax_due',
                    'color_exterior',
                    'color_interior',
                    'fuel_type',
                    'drive_train',
                    'chassis_number',
                    // 'condition',
                    // 'features',
                ],
                error: null,
                loading: false,
            })

        default:
            return state
    }
}

export default technicalInformationData

export type TechnicalInformationState = ReturnType<
    typeof technicalInformationData
>
