import { Spin, message } from 'antd'
import heic2any from 'heic2any'
import { useState, useEffect } from 'react'

export default function ImgPreview({
    currentImagesUploaded,
}: {
    currentImagesUploaded: File
}) {
    const [imgPreviewLoading, setImgPreviewLoading] = useState(false)
    const [url, setUrl] = useState('')

    // fixing infinite blob generation - putting image inside local state to avoinf re-render onChange
    useEffect(() => {
        const getCustomFormatImgUrl = async () => {
            try {
                // Convert HEIC/HEIF to JPEG/PNG
                const conversionResult = await heic2any({
                    blob: currentImagesUploaded,
                    toType: 'image/jpeg',
                })

                if (conversionResult instanceof Blob) {
                    // Create a new filename with the .jpg extension
                    const newFileName =
                        currentImagesUploaded.name.replace(/\.[^/.]+$/, '') +
                        '.jpg'
                    // Create a new File object
                    const newFile = new File([conversionResult], newFileName, {
                        type: 'image/jpeg',
                    })

                    // Create a URL for the converted image
                    const url = URL.createObjectURL(newFile)
                    setUrl(url)
                    setImgPreviewLoading(false)
                } else {
                    message.error(
                        'An error occured, please try uploading your image again.'
                    )
                }
            } catch (e) {
                message.error(
                    'An error occured, please try uploading your image again.'
                )
            }
        }

        if (currentImagesUploaded) {
            if (
                currentImagesUploaded.type === 'image/heic' ||
                currentImagesUploaded.type === 'image/heif'
            ) {
                setImgPreviewLoading(true)
                getCustomFormatImgUrl()
            } else {
                let url = URL.createObjectURL(currentImagesUploaded)

                url && setUrl(url)
            }
        }

        return () => {}
    }, [currentImagesUploaded])
    return imgPreviewLoading ? (
        <Spin />
    ) : (
        <img
            src={url}
            alt="car gallery"
            style={{
                height: '30px',
                width: '30px',
                borderRadius: '5px',
            }}
        />
    )
}
