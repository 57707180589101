import { IPageQaName } from "myModels";

const collectorsQuestionPagesArray: IPageQaName[] = [
  "CollectorsPage1",
  "CollectorsPage2",
  "CollectorsPage3"
];
const dealersQuestionPagesArray: IPageQaName[] = [
  "DealersPage1",
  "DealersPage2"
];
const contentCreatorsQuestionPagesArray: IPageQaName[] = [
  "DealersPage1",
  "DealersPage2"
];
const serviceProvidersQuestionPagesArray: IPageQaName[] = [
  "DealersPage1",
  "DealersPage2"
];
const defaultQuestionArray: IPageQaName[] = [
  "GeneralPage1PrimaryUse",
  "GeneralPage2SecondaryUse"
];

const finalQuestionArray: IPageQaName[] = ["WhyPCC"];

export {
  collectorsQuestionPagesArray,
  dealersQuestionPagesArray,
  contentCreatorsQuestionPagesArray,
  serviceProvidersQuestionPagesArray,
  defaultQuestionArray,
  finalQuestionArray
};
