import styled from 'styled-components'
import { useAppSelector } from '../../../redux/store/hooks'
import IconButton from '../../atoms/Button/iconButton'
import SectionHeaderV2 from '../../atoms/typography/sectionHeaderV2'
import { renderCategoryIcon } from '../../atoms/icons/components/historyFile/categories'
import { ITimelineEntryTypes } from 'timelineModels'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

// interface IStyle {}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const LeftDiv = styled.div`
    /* padding-left: 10px; */
`

const RightDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    gap: 16px;
`

type Props = {
    onClose?: any
    text: string
    categoryID?: string
}

const CategoryBox = styled.div`
    display: flex;
    padding: 8px 14px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--border-muted, #e5e5e5);
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
`

type IStyle = {
    isactive?: boolean
    $bgColor?: string
}

const IconContainer = styled.div<IStyle>`
    width: 26px;
    height: 26px;
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : 'var(--primary, #5ec3ca)'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const CreationTopHeaderDesktop = (props: Props) => {
    const entryTypes_data = useAppSelector((state) => state.timeline.categories)

    let categoryData =
        props.categoryID && entryTypes_data.data[`${props.categoryID}`]
            ? entryTypes_data.data[`${props.categoryID}`]
            : null

    const { theme } = useThemes()

    return (
        <Wrapper>
            <LeftDiv>
                <SectionHeaderV2 hasActionIcon>{props.text}</SectionHeaderV2>
            </LeftDiv>
            <RightDiv>
                {categoryData && (
                    <CategoryBox>
                        <IconContainer $bgColor={categoryData.color}>
                            {renderCategoryIcon(
                                categoryData.id as ITimelineEntryTypes,
                                '14'
                            )}
                        </IconContainer>

                        <div>{props.categoryID}</div>
                    </CategoryBox>
                )}
                <IconButton
                    icon="grey_cross"
                    iconwidth="14px"
                    noborder
                    bgcolor={colours[theme].background_default}
                    onClick={props.onClose}
                />
            </RightDiv>
        </Wrapper>
    )
}

export default CreationTopHeaderDesktop
