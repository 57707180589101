import * as urls from '../urls'
import { IUploadImagesPreSignedUrlsPayload } from 'IapiDataPayload'
import {
    IUploadAttachmentPreSignedUrlPayloadSaga,
    IUploadAttachmentPreSignedUrlPayloadSingle,
    IUploadImagePreSignedUrlPayloadSingle,
} from 'myModels'

import {
    IApiGetUploadPresignedUrlCarGalleryImg_args,
    IApiUploadPresignedUrlGalleryImgsToS3_args,
    IApiUploadPresignedUrlImgsToS3_args,
} from 'ApiInterfaces'
import { customHeader } from '../headers'
import { apiSentryErrorHandler, apiSentryS3ErrorHandler } from '../errorHandler'
import { message } from 'antd'

var axios = require('axios')

// get pre signed urls to upload car gallery images tio AWS S3 bucket
export const get_upload_presigned_url_cargalleryimage_API = (
    p: IApiGetUploadPresignedUrlCarGalleryImg_args
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.upload_image_car_gallery_type_endpoint(
            p.carID,
            p.count,
            p.file_names
        ),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: IUploadImagesPreSignedUrlsPayload) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get presigned urls car gallery image error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

// get pre signed urls to upload car cover images tio AWS S3 bucket
export const get_upload_presigned_url_carcoverimage_API = (p: {
    carid: string
    file_name: string
}): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.upload_image_car_cover_type_endpoint(p),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: IUploadImagesPreSignedUrlsPayload) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get presigned url car cover image error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

// get pre signed urls to upload user profile image to AWS S3 bucket
export const get_upload_presigned_url_userprofileimage_API = (
    fileName: string
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.upload_user_profile_image_type_endpoint(fileName),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: IUploadImagesPreSignedUrlsPayload) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get presigned url user profile image error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const get_upload_presigned_urls_entry_attachment_API = (
    data: IUploadAttachmentPreSignedUrlPayloadSaga
) => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.upload_entry_attachment_presignedurls_endpoint(data),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get presigned url entry attachment error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

export const get_upload_presigned_url_cost_attachment_API = (
    data: IUploadAttachmentPreSignedUrlPayloadSingle
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.upload_cost_attachment_presignedurl_endpoint(data),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get presigned url cost attachment error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}

// get pre signed urls to upload car gallery images to AWS S3 bucket
export const upload_presignedurl_image_to_s3 = (
    p: IApiUploadPresignedUrlImgsToS3_args
) => {
    let arrayOfPromisses: any = []
    p.files.forEach((imageFile: any, index: number) => {
        if (imageFile !== undefined && p.presignedurls) {
            arrayOfPromisses.push(
                upload_single_presignedurl_image_to_s3({
                    presignedUrl: p.presignedurls[index],
                    file: imageFile,
                })
            )
        }
    })
    return Promise.allSettled(arrayOfPromisses)
        .then((data) => {})
        .catch((err) => {
            err.forEach((e: any) => Promise.reject(e))
        })
}

export const upload_presignedurl_gallery_images_to_s3 = (
    p: IApiUploadPresignedUrlGalleryImgsToS3_args
) => {
    let arrayOfPromisses: any = []
    p.files.forEach((imageFile: any, index: number) => {
        if (imageFile !== undefined && p.gallerypresignedurls[imageFile.name]) {
            let presigned_url = { ...p.gallerypresignedurls[imageFile.name] }

            delete presigned_url['original_filename']

            arrayOfPromisses.push(
                upload_single_presignedurl_image_to_s3({
                    presignedUrl: presigned_url,
                    file: imageFile,
                })
            )
        }
    })
    return Promise.allSettled(arrayOfPromisses)
        .then((data) => {})
        .catch((err) => {
            err.forEach((e: any) => Promise.reject(e))
        })
}

// upload image to AWS S3 bucket
export const upload_single_presignedurl_image_to_s3 = async (p: {
    presignedUrl: IUploadImagePreSignedUrlPayloadSingle
    file: File
}) => {
    if (p.file !== undefined && p.presignedUrl) {
        return await axios
            .request({
                method: 'put',
                url: p.presignedUrl.url,
                data: p.file,
                onUploadProgress: (p: any) => {},
            })
            .then((data: any) => {})
            .catch((e: any) => {
                apiSentryS3ErrorHandler(e, 'S3 error')
                message.error(
                    'There was an error uploading your image. Please try again.'
                )
                return Promise.reject(e)
            })
    }
}

// get pre signed urls to upload entry gallery images tio AWS S3 bucket
export const get_upload_presigned_url_entry_galleryimage_API = (
    entryid: string,
    carid: string,
    count: number,
    file_names: string[]
) => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.upload_image_entry_gallery_type_endpoint(
            entryid,
            carid,
            count,
            file_names
        ),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: IUploadImagesPreSignedUrlsPayload) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        data,
                        'Get presigned url entry gallery image error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
