import * as React from 'react'
import styled from 'styled-components'
import { IGalleryImage } from 'entityModels'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import UploadedImagePreview from '../../atoms/image/uploadedImagePreview'

const GalleryImage = styled.img`
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    display: flex;
`

const GalleryImageBackground = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 1.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

interface Props {
    existingImg?: IGalleryImage
    uploadedImg?: File
    uploadProgress?: number
    deleteUploaded?: (filename: string) => void
    isPlaceholder?: boolean
}

export default function GalleryOverviewItemJourney(props: Props) {
    let {
        existingImg,
        uploadedImg,
        uploadProgress,
        deleteUploaded,
        isPlaceholder,
    } = props
    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
        isPlaceholder && setIsLoading(false)
    }, [])

    return (
        <GalleryImageBackground role="img" aria-label="car overview banner">
            {isLoading ? <SkeletonAbsolute isthingloading /> : null}
            {uploadedImg ? (
                <UploadedImagePreview
                    currentImagesUploaded={uploadedImg}
                    uploadProgress={uploadProgress}
                    onDelete={
                        !deleteUploaded
                            ? undefined
                            : () => {
                                  deleteUploaded &&
                                      uploadedImg?.name &&
                                      deleteUploaded(uploadedImg?.name)
                              }
                    }
                />
            ) : existingImg ? (
                <GalleryImage
                    aria-label="car gallery image"
                    src={existingImg.large_src || existingImg.url}
                    srcSet={existingImg.large_srcset}
                    onLoad={() => setIsLoading(false)}
                />
            ) : null}
        </GalleryImageBackground>
    )
}
