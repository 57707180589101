import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Faded from '../../../templates/animated/faded'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import ImageCropperMobile2 from '../../imageCropper/imageCropperMobile2'
import { motion } from 'framer-motion'
import { Button, Upload, message } from 'antd'

import './antd.css'
import { IUploadImagesPreSignedUrlsPayload } from 'IapiDataPayload'
import {
    getPresignedUrlCarCoverImageRequest,
    submitCarCoverImageFilestorageRequest,
} from '../../../../redux/filestorage/actions/dispatched/loadingActions'
import Expander from '../../../atoms/expander/expander'
import { IUploadAndCreateCarCoverImageArgs } from 'myModels'
import useIsIosPwaCheck from '../../../templates/pwaCustom/isIosPwaCheck'
import { ModalTopBar } from '../../../molecules/technicalInformation/modal'
import { useCallback } from 'react'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'

type IStyle = { $theme: ITheme }

const EditFormContainerMobile = styled.section<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over};
    color: ${(props) => colours[props.$theme].text_strong};
    border-radius: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 5px;
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
`

export const DescriptionHeader = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 13px;
    font-family: Lato-Light;
`

const SmallPadding = styled.div`
    padding-top: 24px;
`

const BtnsWrapper = styled.div`
    width: 100%;
    padding: 32px;
    display: flex;
    justify-content: flex-end;
`

const ImgBtnSideRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const NameOfFileUploaded = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato;
    font-size: 14px;
    padding-right: 20px;
    padding-left: 0px;
    max-width: 50vw;
    overflow: hidden;
    padding-bottom: 10px;
`

const dispatchProps = {
    getPresignedUrlCarCoverImageRequest: (payload: {
        carid: string
        file_name: string
    }) => getPresignedUrlCarCoverImageRequest(payload),
    submitCarCoverImageFilestorageRequest: (
        p: IUploadAndCreateCarCoverImageArgs
    ) => submitCarCoverImageFilestorageRequest(p),
}

function mapStateToProps(state: RootState) {
    return {
        presignedurls: state.fileStorage.presignedurls,
    }
}

interface Values {
    file: File | null
}

interface OtherProps {
    close: () => void
    deleteImageUploaded: () => void
    setImageUploaded: (file: File | undefined) => void
    currentImagesUploaded: File | undefined
    toggleShowImageCropper: (res?: boolean | undefined) => void
    isImageCropperShown?: boolean
    car_id: string
    getPresignedUrlCarCoverImageRequest: (payload: {
        carid: string
        file_name: string
    }) => void
    presignedurls: IUploadImagesPreSignedUrlsPayload
    submitCarCoverImageFilestorageRequest: (
        payload: IUploadAndCreateCarCoverImageArgs
    ) => void
    progressPercent?: number | null
    isSomethingBeingUploaded?: boolean
}

const CustomButton = styled(Button)<IStyle>`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: 2px 4px 7px
        ${(props) => colours[props.$theme].shadow_strongest_03};
    transition: all 200ms;

    text-transform: lowercase;
    background-color: ${(props) =>
        colours[props.$theme].modal_side_sheet_pop_over} !important;

    width: 130px !important;
    height: 30px !important;
    border-radius: 5px !important;
    color: ${(props) => colours[props.$theme].primary} !important;
    border: 1px solid ${(props) => colours[props.$theme].primary} !important;
    font-family: Lato !important;
    font-size: 13px !important;

    @media ${device.mobile} {
        font-size: 15px !important;
        height: 40px !important;
    }

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const ProgressBarImagesMobileParentContainer = styled.div<IStyle>`
    border: 1px ${(props) => colours[props.$theme].primary} solid;
    display: flex;
    position: relative;
    height: 4px;
    width: 43vw;
    border-radius: 5px;
`

const FileRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 79vw;
    padding-top: 10px;
`

const FileCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
    padding-left: 10px;
    padding-right: 10px;
`

type ProgressProps = {
    progressPercent: number
    $theme: ITheme
}

const ProgressBarImagesMobileChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: ${(props) => colours[props.$theme].primary};
    border-radius: 5px;
`

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        close,
        setImageUploaded,
        currentImagesUploaded,
        deleteImageUploaded,
        toggleShowImageCropper,
        isImageCropperShown,
        setFieldValue,
        getPresignedUrlCarCoverImageRequest,
        car_id,
        progressPercent,
        values,
    } = props

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const handleCroppedFile = useCallback(
        (file: File | undefined) => {
            if (file) {
                getPresignedUrlCarCoverImageRequest({
                    carid: car_id,
                    file_name: file.name,
                })
                setFieldValue('file', file)
                let fake_url = URL.createObjectURL(file)
                setFieldValue('file_url', fake_url)
                setImageUploaded(file)
            }
            return
        },
        [values.file]
    )

    let isApple: any = useIsIosPwaCheck()
    let isIos: boolean = isApple.isIos || isApple.isSafari

    const errorFileSizeWarning = () => {
        message.error({
            content: isIos
                ? 'The maximum file size allowed per banner image upload on iOS device is 5MB.'
                : 'The maximum file size allowed per upload is 20MB.',
            duration: 2,
        })
    }

    const { theme } = useThemes()

    return (
        <div style={{ zIndex: 5 }}>
            {isImageCropperShown ? (
                <ImageCropperMobile2
                    currentImagesUploaded={currentImagesUploaded}
                    close={() => toggleShowImageCropper(false)}
                    handleFile={handleCroppedFile}
                />
            ) : (
                <EditFormContainerMobile $theme={theme}>
                    <ModalTopBar $theme={theme}>
                        Update your car's banner image
                    </ModalTopBar>
                    <InnerFormPadding>
                        <SmallPadding />
                        <DescriptionHeader $theme={theme}>
                            <strong
                                style={{ color: colours[theme].text_muted }}
                            >
                                Image formats accepted{' '}
                            </strong>
                            : png, jpg, jpeg, HEIC and HEIF.
                            <br />
                            {isIos ? (
                                <div>
                                    <strong
                                        style={{
                                            color: colours[theme].text_muted,
                                        }}
                                    >
                                        Maximum file sizes:
                                    </strong>{' '}
                                    iOS(5MB) Desktop (20MB)
                                </div>
                            ) : (
                                <div>
                                    <strong
                                        style={{
                                            color: colours[theme].text_muted,
                                        }}
                                    >
                                        Maximum file size
                                    </strong>
                                    : 20MB
                                </div>
                            )}
                        </DescriptionHeader>

                        <SmallPadding />

                        <Expander height={currentImagesUploaded ? 0 : 'auto'}>
                            <motion.div whileTap={{ scale: 1.05 }}>
                                <Upload
                                    multiple={false}
                                    showUploadList={false}
                                    onChange={(info: any) => {
                                        if (
                                            info.file &&
                                            (info.file.type === 'image/jpeg' ||
                                                info.file.type ===
                                                    'image/png' ||
                                                info.file.type ===
                                                    'image/heic' ||
                                                info.file.type === 'image/heif')
                                        ) {
                                            if (isIos) {
                                                if (
                                                    info.file.size <= 5000000 &&
                                                    info.file.size > 1
                                                ) {
                                                    getPresignedUrlCarCoverImageRequest(
                                                        {
                                                            carid: car_id,
                                                            file_name:
                                                                info.file.name,
                                                        }
                                                    )

                                                    let fileimg =
                                                        info.file.originFileObj

                                                    setFieldValue(
                                                        'file',
                                                        fileimg
                                                    )
                                                    let fake_url =
                                                        URL.createObjectURL(
                                                            fileimg
                                                        )
                                                    setFieldValue(
                                                        'file_url',
                                                        fake_url
                                                    )

                                                    setImageUploaded(fileimg)

                                                    toggleShowImageCropper(true)
                                                } else errorFileSizeWarning()
                                            } else if (
                                                info.file.size <= 20000000 &&
                                                info.file.size > 1
                                            ) {
                                                getPresignedUrlCarCoverImageRequest(
                                                    {
                                                        carid: car_id,
                                                        file_name:
                                                            info.file.name,
                                                    }
                                                )

                                                let fileimg =
                                                    info.file.originFileObj

                                                setFieldValue('file', fileimg)
                                                let fake_url =
                                                    URL.createObjectURL(fileimg)
                                                setFieldValue(
                                                    'file_url',
                                                    fake_url
                                                )

                                                setImageUploaded(fileimg)

                                                toggleShowImageCropper(true)
                                            } else errorFileSizeWarning()
                                        }
                                    }}
                                    customRequest={dummyRequest}
                                >
                                    <CustomButton $theme={theme}>
                                        import file
                                    </CustomButton>
                                </Upload>
                            </motion.div>
                        </Expander>

                        <Expander height={currentImagesUploaded ? 'auto' : 0}>
                            <FileRow>
                                <FileCol>
                                    <NameOfFileUploaded $theme={theme}>
                                        {currentImagesUploaded &&
                                            // currentImagesUploaded.new === true &&
                                            currentImagesUploaded.name !==
                                                undefined &&
                                            currentImagesUploaded.name}
                                    </NameOfFileUploaded>

                                    {currentImagesUploaded &&
                                        progressPercent && (
                                            <ProgressBarImagesMobileParentContainer
                                                $theme={theme}
                                            >
                                                <ProgressBarImagesMobileChild
                                                    $theme={theme}
                                                    progressPercent={
                                                        progressPercent
                                                            ? progressPercent
                                                            : 0
                                                    }
                                                />
                                            </ProgressBarImagesMobileParentContainer>
                                        )}
                                </FileCol>

                                {currentImagesUploaded && (
                                    <Faded>
                                        <ImgBtnSideRow>
                                            <ButtonAtom
                                                onClick={(e: any) => {
                                                    e.preventDefault()
                                                    // fake function

                                                    toggleShowImageCropper()
                                                }}
                                                theme="crop_img_circle"
                                                height="34px"
                                                width="34px"
                                            >
                                                import image
                                            </ButtonAtom>

                                            <ButtonAtom
                                                onClick={(e: any) => {
                                                    e.preventDefault()
                                                    // fake function

                                                    deleteImageUploaded()
                                                }}
                                                theme="bin_img_circle"
                                                height="34px"
                                                width="34px"
                                            >
                                                import image
                                            </ButtonAtom>
                                        </ImgBtnSideRow>
                                    </Faded>
                                )}
                            </FileRow>
                        </Expander>
                        <SmallPadding />
                    </InnerFormPadding>
                    <BtnsWrapper>
                        <ButtonsStandardBottomRowMobile
                            cancel={close}
                            submit={props.handleSubmit}
                            disabled={progressPercent === 100 ? false : true}
                            confirmationtext={'save'}
                            dataCyIdUpdate="add_or_edit_car_banner_mobile"
                            dataCyIdCancel="cancel_add_or_edit_car_banner_mobile"
                        />
                    </BtnsWrapper>
                </EditFormContainerMobile>
            )}
        </div>
    )
}

const CarBannerImageEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        file: null,
    }),

    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        let file = values.file

        if (
            file !== undefined &&
            props.presignedurls &&
            props.presignedurls[0]
        ) {
            let presignedurl = props.presignedurls[0]
            if (file !== undefined && file !== null && presignedurl) {
                props.submitCarCoverImageFilestorageRequest({
                    carid: props.car_id,
                    presignedurl: presignedurl,
                    file: file,
                })
            }

            // here req thunks to upload to S3
        }

        setSubmitting(false)
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarBannerImageEditFormMobile)
