import { IJourneyShareYourCarStepID } from 'journeyInterfaces'
import { IUser } from 'myModels'
import { useHistory } from 'react-router'
import JourneyShareYourCarWelcome from '../welcome'
import QrCodeShareYourCarJourneyStep from '../qrCode'
import PublicLinkShareYourCarJourneyStep from '../publicLinkShare'
import PrivateDirectShareYourCarJourneyStep from '../privateDirectShare'
import PrivateDirectSettingsShareYourCarJourneyStep from '../privateDirectShareSettings'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'

type Props = {
    stepID: IJourneyShareYourCarStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
}
export default function ShareYourCarJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    user,
}: Props) {
    const history = useHistory()
    let locationState: any = history.location.state

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyShareYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )

        case 'qr_code':
            return (
                <QrCodeShareYourCarJourneyStep
                    step={1}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/share-your-car?step=welcome`
                              )
                    }}
                    goToNextStep={() => {
                        history.push(
                            `/journey/share-your-car?step=public_link_share`
                        )
                    }}
                />
            )
        case 'public_link_share':
            return (
                <PublicLinkShareYourCarJourneyStep
                    step={2}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/share-your-car?step=qr_code`
                              )
                    }}
                    goToNextStep={() => {
                        history.push(
                            `/journey/share-your-car?step=private_direct_share`
                        )
                    }}
                />
            )
        case 'private_direct_share':
            return (
                <PrivateDirectShareYourCarJourneyStep
                    step={3}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/share-your-car?step=public_link_share`
                              )
                    }}
                    goToNextStep={() => {
                        history.push(
                            `/journey/share-your-car?step=private_direct_share_settings`
                        )
                    }}
                />
            )
        case 'private_direct_share_settings':
            return (
                <PrivateDirectSettingsShareYourCarJourneyStep
                    step={4}
                    totalSteps={4}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/share-your-car?step=private_direct_share`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/share-your-car`,
                            search: `?step=achieved`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )
        case 'achieved':
            return <JourneyAchievedStep sectionID="share_your_car" />

        default:
            return (
                <JourneyShareYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
