import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'

import { api } from '../../../services'
import { insurance_application_existing_state_select } from '../maindriver/maindriver_additionaldetails_submit'
import { IOtherDriverID } from './otherdriver_trackrecord_submit'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    GetInsuranceApplication,
    insurance_application_state_select,
} from '../application/get'
import posthog from 'posthog-js'

export function* Submit_otherdriver_deletion(p: IOtherDriverID): any {
    posthog.capture('Update insurance application named driver deletion')

    const { driver_id } = p
    try {
        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let req_del: IDeleteInsuranceApplication_req = {
            appli_id: `${all_existing.id}`,
            entity_id: driver_id,
            entity_type: 'delete_named_driver',
        }
        yield call(api.insuranceApplication.deleteInsuranceApplicationEntity, {
            ...req_del,
        })

        // commented out above request
        let application: IInsuranceQuoteApplication_Reducer = yield select(
            insurance_application_state_select
        )

        if (application.showValidationSheet && application.submitted_data?.id) {
            const isWizard =
                window.location.pathname.match(/wizard/g) !== null
                    ? true
                    : false

            yield put(
                insuranceActions.validate_insurance_application_request({
                    application: { ...application.submitted_data },
                    activateValidationSheet: true,
                    isWizard: isWizard,
                })
            )
        } else {
            yield put(insuranceActions.getting_insurance_info_after_change())
            yield call(GetInsuranceApplication, `${all_existing.id}`)
        }

        // here, pass the results and thats it
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_otherdriver_deletion,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/other_driver/${p.driver_id}`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(insuranceActions.delete_otherDriver_error(customError))
        }
    }
}

function* Watcher_Submit_otherdriver_deletion() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.delete_otherDriver_request
        )
        yield call(Submit_otherdriver_deletion, payload)
    }
}

const otherDriver_deletion: any[] = [fork(Watcher_Submit_otherdriver_deletion)]

export default otherDriver_deletion
