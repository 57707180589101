import {
    IApexArticleOverviewItemState,
    IApexCarOfTheWeekState,
    IApexHomepageDataState,
    IApexInterviewOverviewsPageData,
    IApexPodscastState,
    IApexWeeklyNewsHighlightState,
    ITaskNormalisedSingleArticleItemState,
    ITaskNormalisedSingleInterviewItemState,
} from 'apexModels'

import { createAction } from '@reduxjs/toolkit'

import {
    GET_APEX_CARS_OF_THE_WEEK_SUCCESS,
    GET_APEX_HOMEPAGE_CONTENT_SUCCESS,
    GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_SUCCESS,
    GET_APEX_PODCASTS_SUCCESS,
    GET_APEX_SINGLE_INTERVIEW_BY_UID_SUCCESS,
    GET_WEEKLY_HIGHLIGHTS_SUCCESS,
    GET_APEX_SINGLE_ARTICLE_BY_UID_SUCCESS,
    GET_APEX_ARTICLE_OVERVIEWS_SUCCESS,
} from './constants'

// export const getApexHomepageContentSuccess = createAction(
//     GET_APEX_HOMEPAGE_CONTENT_SUCCESS,
//     (resolve) => (payload: IApexHomepageDataState) => resolve(payload)
// )

export const getApexHomepageContentSuccess = createAction(
    GET_APEX_HOMEPAGE_CONTENT_SUCCESS,
    function prepare(payload: IApexHomepageDataState) {
        return {
            payload,
        }
    }
)

// export const getApexCarsOfTheWeekSuccess = createAction(
//     GET_APEX_CARS_OF_THE_WEEK_SUCCESS,
//     (resolve) => (carsoftheweek: IApexCarOfTheWeekState[]) =>
//         resolve(carsoftheweek)
// )

export const getApexCarsOfTheWeekSuccess = createAction(
    GET_APEX_CARS_OF_THE_WEEK_SUCCESS,
    function prepare(payload: IApexCarOfTheWeekState[]) {
        return {
            payload,
        }
    }
)

// export const getApexPodcastsSuccess = createAction(
//     GET_APEX_PODCASTS_SUCCESS,
//     (resolve) => (podcasts: IApexPodscastState[]) => resolve(podcasts)
// )

export const getApexPodcastsSuccess = createAction(
    GET_APEX_PODCASTS_SUCCESS,
    function prepare(payload: IApexPodscastState[]) {
        return {
            payload,
        }
    }
)

// export const getWeeklyHighlightsSuccess = createAction(
//     GET_WEEKLY_HIGHLIGHTS_SUCCESS,
//     (resolve) => (weeklyHighlights: IApexWeeklyNewsHighlightState[]) =>
//         resolve(weeklyHighlights)
// )

export const getWeeklyHighlightsSuccess = createAction(
    GET_WEEKLY_HIGHLIGHTS_SUCCESS,
    function prepare(payload: IApexWeeklyNewsHighlightState[]) {
        return {
            payload,
        }
    }
)

// export const getApexInterviewOverviewsSuccess = createAction(
//     GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_SUCCESS,
//     (resolve) => (interviewOverviewsContent: IApexInterviewOverviewsPageData) =>
//         resolve(interviewOverviewsContent)
// )

export const getApexInterviewOverviewsSuccess = createAction(
    GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_SUCCESS,
    function prepare(payload: IApexInterviewOverviewsPageData) {
        return {
            payload,
        }
    }
)

// export const getApexSingleInterviewByUIDSuccess = createAction(
//     GET_APEX_SINGLE_INTERVIEW_BY_UID_SUCCESS,
//     (resolve) => (interview: ITaskNormalisedSingleInterviewItemState) =>
//         resolve(interview)
// )

export const getApexSingleInterviewByUIDSuccess = createAction(
    GET_APEX_SINGLE_INTERVIEW_BY_UID_SUCCESS,
    function prepare(payload: ITaskNormalisedSingleInterviewItemState) {
        return {
            payload,
        }
    }
)

// export const getApexSingleArticleyUIDSuccess = createAction(
//     GET_APEX_SINGLE_ARTICLE_BY_UID_SUCCESS,
//     (resolve) => (article: ITaskNormalisedSingleArticleItemState) =>
//         resolve(article)
// )

export const getApexSingleArticleyUIDSuccess = createAction(
    GET_APEX_SINGLE_ARTICLE_BY_UID_SUCCESS,
    function prepare(payload: ITaskNormalisedSingleArticleItemState) {
        return {
            payload,
        }
    }
)

// export const getApexArticleOverviewsSuccess = createAction(
//     GET_APEX_ARTICLE_OVERVIEWS_SUCCESS,
//     (resolve) => (articleOverviewsContent: IApexArticleOverviewItemState[]) =>
//         resolve(articleOverviewsContent)
// )

export const getApexArticleOverviewsSuccess = createAction(
    GET_APEX_ARTICLE_OVERVIEWS_SUCCESS,
    function prepare(payload: IApexArticleOverviewItemState[]) {
        return {
            payload,
        }
    }
)
