import { IApexArticleOverviewItemState } from 'apexModels'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { getApexArticleOverviewsDataThunks } from '../../../redux/theapex/thunks'
import ApexPageHeaderDesktop from '../../atoms/header/apex/apexPageHeaderDesktop'
import ApexPageHeaderMobile from '../../atoms/header/apex/apexPageHeaderMobile'
import LoaderBackground from '../../atoms/loader/loaderBackground'
import ArticleOverviewItemDesktop from '../../molecules/apex/articleOverview/articleOverviewItemDesktop'
import ArticleOverviewItemMobile from '../../molecules/apex/articleOverview/articleOverviewItemMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import ApexPageWrapper from '../../templates/displays/pageWrappers/apexPageWrapper'
import { IApexLocalCopyItem } from 'apexLocalCopyModels'
import { checkForIpad } from './overview'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'

interface Props {
    articleOverviews: IApexArticleOverviewItemState[] | undefined
    getApexArticleOverviewsDataThunks: any
    articlesPageLocalCopy: IApexLocalCopyItem
}

function mapStateToProps(state: RootState) {
    return {
        articleOverviews: state.apex.articleOverviews,
        articlesPageLocalCopy:
            state.localdata.apex_local_copy.separatePage.articles,
    }
}

const dispatchProps = {
    getApexArticleOverviewsDataThunks: () =>
        getApexArticleOverviewsDataThunks(),
}

const DesktopContainer = styled.div`
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`

const DesktopSubContainer = styled.div`
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 850px;
    @media ${device.ipad} {
        width: 80vw;
    }
    @media ${device.large_desktop} {
        width: 1000px;
        transform: translateY(-30px);
    }
`

const MobileContentPadding = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        padding-left: 8vw;
        padding-right: 8vw;
        padding-top: ${checkForIpad() ? '65px' : '135px'};
        padding-bottom: 80px;
    }
`

class ApexArticleOverviews extends React.Component<Props, {}> {
    componentDidMount() {
        this.props.getApexArticleOverviewsDataThunks()
    }

    render() {
        let { articleOverviews, articlesPageLocalCopy } = this.props

        return (
            <>
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
                <ApexPageWrapper>
                    <DesktopDisplayOnly>
                        <DesktopContainer>
                            <DesktopSubContainer>
                                <div style={{ paddingTop: '40px' }} />

                                <ApexPageHeaderDesktop
                                    title={articlesPageLocalCopy.title}
                                    text={articlesPageLocalCopy.subTitle}
                                />
                                <div style={{ paddingTop: '90px' }} />

                                {!articleOverviews && (
                                    <>
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'100%'}
                                        />
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'100%'}
                                        />
                                        <LoaderBackground
                                            height={'500px'}
                                            width={'100%'}
                                        />
                                    </>
                                )}
                                {articleOverviews &&
                                    articleOverviews.map(
                                        (
                                            item: IApexArticleOverviewItemState,
                                            index: number
                                        ) => {
                                            return (
                                                <ArticleOverviewItemDesktop
                                                    index={index}
                                                    item={item}
                                                    key={`car_of_week_${index}_desktop`}
                                                />
                                            )
                                        }
                                    )}
                            </DesktopSubContainer>
                        </DesktopContainer>
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <MobileContentPadding>
                            <ApexPageHeaderMobile
                                title={articlesPageLocalCopy.title}
                                text={articlesPageLocalCopy.subTitle}
                            />
                            <div style={{ paddingTop: '50px' }} />

                            {!articleOverviews && (
                                <>
                                    <LoaderBackground
                                        height={'200px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'200px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'200px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'200px'}
                                        width={'100%'}
                                    />
                                    <LoaderBackground
                                        height={'200px'}
                                        width={'100%'}
                                    />
                                </>
                            )}

                            {articleOverviews &&
                                articleOverviews.map(
                                    (
                                        item: IApexArticleOverviewItemState,
                                        index: number
                                    ) => {
                                        return (
                                            <ArticleOverviewItemMobile
                                                index={index}
                                                item={item}
                                                key={`car_of_week_${index}_mobile`}
                                            />
                                        )
                                    }
                                )}
                        </MobileContentPadding>
                    </IpadAndMobileDisplay>
                </ApexPageWrapper>
            </>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(ApexArticleOverviews)
