import styled from 'styled-components'
// import { useEffect, useState } from 'react'
// import SearchIcon from '../../../atoms/icons/components/searchIcon'
import { useAppSelector } from '../../../../redux/store/hooks'
import FullWidthBtn from '../../../atoms/Button/fullWidthBtn'
// import Fuse from 'fuse.js'

import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'
// import { device } from '../../../templates/displays/devices'

import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../../providers/theme/hooks'
import { insurance_colours } from '../../../../providers/theme/colours'
import OutlinedRadioInput from '../../editOrCreateModeSingleFields/outlinedRadioInputs'

const Wrapper = styled.div`
    width: 100%;
    padding: 24px;
    overflow-y: auto;
    padding-bottom: 200px;
`

type Props = {
    selectedAddressId: string | undefined
    selectAddressId?: (addressid: string | undefined) => void
    onAddNew: () => void
    sectionID?: IInsuranceApplicationSectionId
}

// const SearchField = styled.input`
//     background-color: #fff;
//     height: 50px;
//     min-height: 50px;
//     border-radius: 5px;
//     border: 1px solid #b9b9b9;
//     padding-left: 15px;
//     padding-right: 60px;
//     width: 100%;
//     padding-left: 16px;
// `

// const Label = styled.label<{ raise: boolean; backgroundColor?: string }>`
//     position: absolute;
//     ${(props) => `
//         top: ${props.raise ? '0%' : '50%'};
//         transform-origin: top left;
//         transform: ${
//             props.raise
//                 ? `translate(8px, -50%) scale(0.8)`
//                 : `translate(8px, -50%) scale(1)`
//         };
//         left: 0;
//         min-width: 30px;
//        background-color: #fff;
//        };
//     `}

//     transition: 0.15s;
//     gap: 16px;
//     color: #b3b3b3;
//     font-size: 1.1em;
//     padding: 0 8px;
//     pointer-events: none;
//     font-family: Lato;

//     &:first-letter {
//         text-transform: uppercase;
//     }

//     @media ${device.mobile} {
//         font-size: 14px;
//         gap: 10px;
//     }
// `

const GetQuoteSearchSelectAddressForm = (props: Props) => {
    // const [isRaised, setIsRaised] = useState<boolean>(false)
    // const [value, setValue] = useState<string>('')

    let addresses = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft.addresses
    )

    let addresses_array = Object.values(addresses).map(
        (address: IInsuranceAddress_API) => {
            return {
                uid: address.uid ?? '',
                postcode: address.postcode,
                name: address
                    ? `${address.street_1 ?? ''} ${address.street_2 ?? ''}${
                          address.postcode ? `, ${address.postcode} ` : ''
                      }`
                    : '',
            }
        }
    )

    // useEffect(() => {
    //     onSearch(value)
    // }, [addresses])

    // const addressesToSearch = Object.values(addresses).map(
    //     (address: IInsuranceAddress_API) => {
    //         return {
    //             uid: address.uid,
    //             postcode: address.postcode,
    //             name: address
    //                 ? `${address.street_1 ?? ''} ${address.street_2 ?? ''}${
    //                       address.postcode ? `, ${address.postcode} ` : ''
    //                   }`
    //                 : undefined,
    //         }
    //     }
    // )

    // const [displayedOptions, setDisplayedOptions] = useState<
    //     {
    //         uid: string | undefined
    //         postcode: string | undefined
    //         name: string | undefined
    //     }[]
    // >(addressesToSearch)

    // const FuseOptions = {
    //     includeScore: true,
    //     keys: ['postcode', 'name'],
    // }

    // const fuse_data =
    //     addressesToSearch && new Fuse(addressesToSearch, FuseOptions)

    // const onSearch = (val: string) => {
    //     setValue(val)

    //     const fuse_results: Fuse.FuseResult<{
    //         uid: string | undefined
    //         postcode: string | undefined
    //         name: string | undefined
    //     }>[] = fuse_data.search(val, {
    //         limit: 5,
    //     })

    //     let res: {
    //         uid: string | undefined
    //         postcode: string | undefined
    //         name: string | undefined
    //     }[] = []

    //     fuse_results.forEach((elem) => {
    //         if (elem.item && elem.score && elem.score < 0.45) {
    //             res = [...res, elem.item]
    //         }
    //     })

    //     val ? setDisplayedOptions(res) : setDisplayedOptions(addressesToSearch)

    //     return
    // }

    const { theme } = useThemes()

    return (
        <Wrapper>
            <div
                style={{
                    width: '100%',
                }}
            >
                {/* {displayedOptions?.length > 0 ? (
                    <div
                        style={{
                            width: '100%',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <SearchField
                            placeholder=""
                            type="text"
                            onFocus={() => {
                                setIsRaised(true)
                            }}
                            onBlur={() => {
                                !value && setIsRaised(false)
                            }}
                            onChange={(e) => {
                                onSearch(e.target.value)
                            }}
                        />

                        <Label
                            style={{
                                width: 'max-content',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            raise={isRaised ? true : false}
                        >
                            {!isRaised && <SearchIcon />}
                            Search addressbook by postcode
                        </Label>
                    </div>
                ) : (
                    <div
                        style={{
                            padding: '16px 32px',
                            borderRadius: 4,
                            backgroundColor: 'rgba(26, 26, 26, 0.02)',
                            color: '#a1a1a1',
                            fontFamily: 'Lato',
                            fontStyle: 'italic',
                            fontSize: 14,
                        }}
                    >
                        You must add at least one address
                    </div>
                )} */}

                <OutlinedRadioInput
                    options={addresses_array}
                    onChange={(uid: string) =>
                        props.selectAddressId && props.selectAddressId(uid)
                    }
                    customPrimaryColour={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_100
                            : undefined
                    }
                    customPrimaryColour_04={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_04
                            : undefined
                    }
                    value={props.selectedAddressId}
                />

                {addresses_array?.length > 0 && (
                    <div style={{ paddingTop: '32px' }} />
                )}
                <FullWidthBtn
                    onClick={props.onAddNew}
                    text="Add new address"
                    bg={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_08
                            : undefined
                    }
                    txt_color={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_100
                            : undefined
                    }
                />
            </div>
        </Wrapper>
    )
}

export default GetQuoteSearchSelectAddressForm
