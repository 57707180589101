import * as React from 'react'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import AcceptAttachments from '../../dropzone/attachmentsDropzoneDesktop'

import {
    IManageAddAttachmentsToEntryRequest,
    manageAddAttachmentsToEntryRequest,
} from '../../../../redux/timeline/actions/requestActions'

const dispatchProps = {
    manageAddAttachmentsToEntryRequest: (
        p: IManageAddAttachmentsToEntryRequest
    ) => manageAddAttachmentsToEntryRequest(p),
}

function mapStateToProps(state: RootState) {
    return {}
}

interface Props {
    carid: string
    uploadChildren?: React.ReactNode
    noActionIcon?: boolean
    icon?: string
    onClickOpen?: any
    entityID: string
    isTopPage?: boolean
    scrollToElement?: any

    manageAddAttachmentsToEntryRequest: (
        p: IManageAddAttachmentsToEntryRequest
    ) => any
}

type State = {
    filesArray: File[] | undefined[]
    [key: string]: any
}

class EntryAttachmentsFormWrapperDesktop extends React.Component<Props, State> {
    state = {
        filesArray: [undefined],
    }

    componentDidUpdate(prevProps: Props) {}

    handleFilesGiven = (files: File[]) => {
        this.setState({ ...this.state, filesArray: files })

        this.props.manageAddAttachmentsToEntryRequest({
            files: files,
            entryID: this.props.entityID,
            carID: this.props.carid,
        })
    }

    render() {
        const { filesArray } = this.state

        return (
            <AcceptAttachments
                handleFilesGiven={this.handleFilesGiven}
                filesUploaded={filesArray}
                progressArray={this.state}
                isTopPage={this.props.isTopPage}
                scrollToElement={this.props.scrollToElement}
            />
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(EntryAttachmentsFormWrapperDesktop)
