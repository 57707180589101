import * as React from 'react'

import styled from 'styled-components'

const Container = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #00000099;
    height: 30px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 6;
`

const Text = styled.div`
    color: white;
    font-family: 'Lato';
    font-size: 13px;
`

type Props = { copy: string }

const ImageTagDesktop: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Container>
            <Text>{props.copy}</Text>
        </Container>
    )
}

export default ImageTagDesktop
