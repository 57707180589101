import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'
import InputField from '../../../../atoms/Inputfield/inputField'
import { IUser } from 'myModels'
import Expander from '../../../../atoms/expander/expander'
import Faded from '../../../../templates/animated/faded'
import { useState } from 'react'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../../molecules/technicalInformation/modal'
import { device } from '../../../../templates/displays/devices'
import useThemes from '../../../../../providers/theme/hooks'

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 16px;
`

const SmallPadding = styled.div`
    padding-top: 20px;
`

const Text = styled.textarea`
    font-family: Lato;
    padding-left: 0px;
    padding-right: 5px;
    border-radius: 5px;
    resize: none;
    width: 100%;
    color: var(--text-strong, #1a1a1a);

    font-size: 0.9em;
    border: none;
    background-color: var(--bg-color, #fff);
    :focus {
        outline: 0;
    }

    @media ${device.beyond_ipad_mobile} {
        font-family: Lato;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 15px;
        padding-bottom: 5px;
        height: auto;
        color: var(--text-strong, #1a1a1a);
        font-size: 1.1em;

        min-height: 120px;
        height: 100%;
    }

    @media ${device.smallest_laptops} {
        min-height: 120px;
        height: 100%;
        padding-right: 20px;
    }
`

export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    padding-bottom: 10px;
`

export const DescriptionHeader = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    display_name: string
    one_liner: string
}

interface OtherProps {
    close: any
    userLoggedIn: IUser
    submit_func: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        close,
        errors,
        // touched,
        handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        // userLoggedIn,
    } = props

    const countCharNo = (value: string) => {
        let noOfLineBreaks: number = value.split(/[\n\r]/g).length
        return setTextAreaMaxLength(451 - noOfLineBreaks * 50)
    }

    const [textAreaMaxLength, setTextAreaMaxLength] = useState(451)

    const { theme } = useThemes()

    return (
        <ModalContentDesktop
            $theme={theme}
            data-attr="edit-profile-modal-desktop"
            style={{ paddingInline: 0, overflowY: 'auto' }}
        >
            <>
                <ModalInnerContentWrapperDesktop>
                    <ModalTitle $theme={theme} style={{ marginBottom: 24 }}>
                        Edit Your Profile
                    </ModalTitle>
                    <FieldTitle>Your display name</FieldTitle>
                    <div style={{ paddingTop: '10px' }} />
                    <DescriptionHeader>
                        This is the name that will be displayed on your garage.
                        Whilst you can have your full name, you do not need to.
                    </DescriptionHeader>

                    <Expander
                        height={
                            values.display_name.length > 0 &&
                            errors.display_name
                                ? 'auto'
                                : 0
                        }
                        dataCyId={'display-name-input-field-error-desktop'}
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {errors.display_name}
                            </Error>
                        </Faded>
                    </Expander>

                    <SmallPadding />

                    <InputField
                        theme={theme}
                        id="display_name"
                        name="display_name"
                        placeholder="display name"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.display_name}
                        dataCyId="display-name-input-field-desktop"
                        onEnterKeyPress={props.handleSubmit}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />

                    <div style={{ paddingTop: '30px' }} />
                </ModalInnerContentWrapperDesktop>
                <ModalInnerContentWrapperDesktop
                    style={{
                        backgroundColor: 'var(--off-bg-color, #fafafa)',
                        paddingTop: 20,
                        paddingBottom: 24,
                    }}
                >
                    <FieldTitle>Your bio</FieldTitle>

                    <Expander height={errors.one_liner ? 'auto' : 0}>
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {errors.one_liner}
                            </Error>
                        </Faded>
                    </Expander>

                    <SmallPadding style={{ paddingTop: 16 }} />

                    <div style={{ height: 150 }}>
                        <Text
                            id="one_liner"
                            name="one_liner"
                            aria-live="polite"
                            onChange={(e: any) => {
                                handleChange(e)
                                countCharNo(e.target.value)
                            }}
                            value={values.one_liner}
                            maxLength={textAreaMaxLength}
                        />
                    </div>
                </ModalInnerContentWrapperDesktop>

                <SmallPadding />
            </>
            <SmallPadding />
            <ModalInnerContentWrapperDesktop>
                <ButtonsStandardBottomRowDesktop
                    cancel={close}
                    submit={props.handleSubmit}
                    isDisabled={
                        (values.display_name && errors.display_name) ||
                        (values.one_liner && errors.one_liner)
                            ? true
                            : false
                    }
                    secondText={'update'}
                    dataCyIdUpdate={'submit-new-profile-information-desktop'}
                    dataCyIdCancel={'cancel-new-profile-information-desktop'}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const AccountExternalInfoFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        display_name:
            props.userLoggedIn.display_name !== undefined
                ? props.userLoggedIn.display_name
                : '',
        one_liner:
            props.userLoggedIn.one_liner !== undefined
                ? props.userLoggedIn.one_liner
                : '',
    }),
    // validationSchema: Yup.object().shape({
    //     // display_name: Yup.string().test(
    //     //     'len',
    //     //     'Display name must be of at least 2 characters and of a maximum of 40 characters.',
    //     //     (val) =>
    //     //         val && val.toString().length > 1 && val.toString().length < 41
    //     // ),

    //     display_name: Yup.string().max(
    //         36,
    //         'Your display name must be be less than 35 characters.'
    //     ),

    //     one_liner: Yup.string().max(
    //         541,
    //         'Your bio must be be less than 450 characters.'
    //     ),
    // }),

    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            let noOfLineBreaks = values.one_liner
                ? values.one_liner.split(/[\n\r]/g).length
                : 0
            return Yup.object().shape({
                display_name: Yup.string().max(
                    36,
                    'Your display name must be between 2 and 35 characters.'
                ),
                one_liner: Yup.string().max(
                    451 - noOfLineBreaks * 50 - noOfLineBreaks,
                    `You've reached the limit of characters and line breaks.`
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (
            values.one_liner !== props.userLoggedIn.one_liner &&
            values.display_name !== props.userLoggedIn.display_name
        ) {
            props.submit_func({
                id: props.userLoggedIn.id,
                data: {
                    bio: values.one_liner,
                    display_name: values.display_name,
                },
            })
        } else if (
            values.one_liner !== props.userLoggedIn.one_liner &&
            values.display_name === props.userLoggedIn.display_name
        ) {
            props.submit_func({
                id: props.userLoggedIn.id,
                data: {
                    bio: values.one_liner,
                },
            })
        } else if (
            values.one_liner === props.userLoggedIn.one_liner &&
            values.display_name !== props.userLoggedIn.display_name
        ) {
            props.submit_func({
                id: props.userLoggedIn.id,
                data: {
                    display_name: values.display_name,
                },
            })
        }

        props.close()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(AccountExternalInfoFormDesktop)
