import { IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { getGarageByUserIdRequest } from '../../../redux/entities/garages/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { device } from '../../templates/displays/devices'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import GeneralTimelineCardMobile from '../../molecules/cards/timeline/generalTimelineCardMobile'
import GeneralTimelineCardDesktop from '../../molecules/cards/timeline/generalTimelineCardDesktop'
import { ICar, IGalleryImagesObject, IGarageObject } from 'entityModels'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import NoCarsInGarage from '../../atoms/noCarsInGarage/noCarsInGarage'
import AdjustableLoader from '../../atoms/loader/adjustableLoader'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'

interface Props extends RouteComponentProps<any> {
    garage_data: IGarageObject
    carsData: { [key: string]: ICar }
    isGarageLoading: boolean
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isCarLoading: boolean
    getGarageCarsDataRequest: (id: string) => void
    getUserDataRequest: () => void
    getGarageByUserIdRequest: () => {}
    imagesData: IGalleryImagesObject | null
    setInitialLandingUrl: (str: string) => void
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        garage_data: state.entities.garagesData.garages,
        isGarageLoading: state.entities.garagesData.loading,
        isCarLoading: state.entities.carsData.loading,
        carsData: state.entities.carsData.cars,
        isUserLoading: state.user.loading,
        imagesData: state.entities.galleriesData.images,
    }
}

const dispatchProps = {
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

const CenteredWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 16px;
`
const PageTitle = styled.div<{ $noCars?: boolean }>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Semibold;
    font-size: 32px;
    line-height: 48px;

    @media ${device.ipad} {
        font-size: 22px;
        line-height: 32px;
        padding-top: 0px;
    }
    @media ${device.beyond_ipad_mobile} {
        padding-top: 40px;
    }

    @media ${device.mobile} {
        font-size: 20px;
        line-height: 28px;
        padding-top: 0px;
    }
`

const PageDesc = styled.div<{ $noCars?: boolean }>`
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    max-width: 700px;
    padding-right: 16px;

    @media ${device.mobile_and_ipad} {
        padding-bottom: 8px;
    }
    @media ${device.mobile} {
        font-size: 14px;
        line-height: 20px;
    }
`

const WrapperDesktopAdjusted = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    padding-bottom: 100px;

    @media (min-width: 500px) and (max-width: 880px) {
        padding-top: 0px;
    }
    @media (min-width: 881px) and (max-width: 1200px) {
        padding-top: 32px;
    }
    @media ${device.desktop} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        padding-top: 48px;
    }
    @media (min-width: 2200px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        padding-top: 48px;
    }
    @media ${device.mobile} {
        grid-gap: 16px;
    }
`

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        padding-left: 50px;
        padding-right: 50px;
        transform: translateY(-20px);
        background-color: var(--bg-color, #fff);
    }
    @media ${device.mobile} {
        background-color: var(--bg-color, #fff);
        min-height: 100vh;
        padding-bottom: 100px;
    }
`

class GeneralTimeline extends React.Component<Props, {}> {
    getNecessaryData = () => {
        let userData = this.props.userLoggedIn
        let garageid = this.props.userLoggedIn?.owns_garage.uid
        let garagesData = this.props.garage_data

        if (userData && userData.id) {
            // ask for garage data only if it's not there (usually on page reload)
            if (
                garageid &&
                (!garagesData ||
                    !garagesData[garageid] ||
                    !garagesData[garageid].cars ||
                    garagesData[garageid].cars.length === 0)
            ) {
                this.props.getGarageByUserIdRequest()
            }
        }
    }

    componentDidMount() {
        this.props.setInitialLandingUrl(`/history-files`)

        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        if (userLoggedIn) {
            let garage_id =
                userLoggedIn &&
                userLoggedIn.owns_garage &&
                userLoggedIn.owns_garage.uid
                    ? userLoggedIn.owns_garage.uid
                    : undefined

            let garagesData = this.props.garage_data

            if (
                garage_id &&
                (!garagesData[garage_id] ||
                    !garagesData[garage_id].cars ||
                    garagesData[garage_id].cars.length === 0)
            ) {
                this.getNecessaryData()
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn) {
                this.getNecessaryData()
            }
        }

        // if (prevProps.garage_data !== this.props.garage_data) {
        //     let userLoggedIn = this.props.userLoggedIn
        //     let garage_id =
        //         userLoggedIn &&
        //         userLoggedIn.owns_garage &&
        //         userLoggedIn.owns_garage.uid
        //             ? userLoggedIn.owns_garage.uid
        //             : undefined
        //     let garageCarsData: string[] | false = !garage_id
        //         ? false
        //         : this.props.garage_data &&
        //           this.props.garage_data[garage_id] &&
        //           this.props.garage_data[garage_id].cars &&
        //           this.props.garage_data[garage_id].cars.length > 0
        //         ? this.props.garage_data[garage_id].cars
        //         : false

        //     if (!garageCarsData) {
        //         this.props.getGarageByUserIdRequest()
        //     }
        // }
    }

    render() {
        let {
            userLoggedIn,
            garage_data,
            carsData,
            imagesData,
            isGarageLoading,
            isUserLoading,
        } = this.props

        let garage_id =
            userLoggedIn &&
            userLoggedIn.owns_garage &&
            userLoggedIn.owns_garage.uid
                ? userLoggedIn.owns_garage.uid
                : undefined

        let current = garage_id && garage_data && garage_data[garage_id]

        return (
            <React.Fragment>
                <Wrapper>
                    <FullPagePageWrapper paddingRight="32px">
                        <DesktopDisplayOnly>
                            <PageTitle>Overview</PageTitle>
                            <div style={{ paddingTop: 16 }} />
                            <PageDesc
                                $noCars={
                                    !(
                                        userLoggedIn &&
                                        garage_data &&
                                        garage_id &&
                                        garage_data[garage_id] &&
                                        garage_data[garage_id].cars &&
                                        garage_data[garage_id].cars.length > 0
                                    )
                                }
                            >
                                Welcome to your Digital History File.
                                <br />
                                Anything added here will be stored securely and
                                made searchable from any Custodian application.
                            </PageDesc>
                        </DesktopDisplayOnly>
                        <IpadAndMobileDisplay>
                            <div style={{ padding: 24 }}>
                                <PageTitle>Overview</PageTitle>
                                <div style={{ paddingTop: 16 }} />
                                <PageDesc
                                    $noCars={
                                        !(
                                            userLoggedIn &&
                                            garage_data &&
                                            garage_id &&
                                            garage_data[garage_id] &&
                                            garage_data[garage_id].cars &&
                                            garage_data[garage_id].cars.length >
                                                0
                                        )
                                    }
                                >
                                    Welcome to your Digital History File.
                                    <br />
                                    Anything added here will be stored securely
                                    and made searchable from any Custodian
                                    application.
                                </PageDesc>
                            </div>
                        </IpadAndMobileDisplay>
                        <DesktopDisplayOnly>
                            <Faded>
                                <WrapperDesktopAdjusted>
                                    {isGarageLoading || isUserLoading ? (
                                        <div
                                            style={{
                                                position: 'relative',
                                                height: '20vh',
                                            }}
                                        >
                                            <AdjustableLoader
                                                isLoading={
                                                    isGarageLoading ||
                                                    isUserLoading
                                                }
                                            />
                                        </div>
                                    ) : userLoggedIn &&
                                      current !== null &&
                                      current !== undefined &&
                                      garage_id &&
                                      carsData &&
                                      garage_data &&
                                      garage_data[garage_id] &&
                                      garage_data[garage_id].cars &&
                                      garage_data[garage_id].cars.length > 0 ? (
                                        garage_data[garage_id].cars.map(
                                            (id: string, index: number) => {
                                                if (carsData[id]) {
                                                    return (
                                                        <React.Fragment
                                                            key={`${id}-general-timeline-mobile`}
                                                        >
                                                            <GeneralTimelineCardDesktop
                                                                key={`${id}-general-timeline-desktop`}
                                                                car={
                                                                    carsData[id]
                                                                }
                                                                carIndex={Object.keys(
                                                                    carsData
                                                                ).indexOf(id)}
                                                                index={index}
                                                                imagesData={
                                                                    imagesData
                                                                }
                                                            />
                                                        </React.Fragment>
                                                    )
                                                } else
                                                    return (
                                                        <div
                                                            key={`${id}-general-timeline-mobile`}
                                                        />
                                                    )
                                            }
                                        )
                                    ) : (
                                        <>
                                            <NoCarsInGarage
                                                variant="desktop"
                                                alignTextLeft
                                            />
                                        </>
                                    )}
                                </WrapperDesktopAdjusted>
                            </Faded>
                        </DesktopDisplayOnly>
                        <IpadAndMobileDisplay>
                            <Faded>
                                <CenteredWrapper>
                                    {isGarageLoading || isUserLoading ? (
                                        <div
                                            style={{
                                                position: 'relative',
                                                height: '20vh',
                                            }}
                                        >
                                            <AdjustableLoader
                                                isLoading={
                                                    isGarageLoading ||
                                                    isUserLoading
                                                }
                                            />
                                        </div>
                                    ) : userLoggedIn &&
                                      current !== null &&
                                      current !== undefined &&
                                      garage_id &&
                                      carsData &&
                                      garage_data &&
                                      garage_data[garage_id] &&
                                      garage_data[garage_id].cars &&
                                      garage_data[garage_id].cars.length > 0 ? (
                                        garage_data[garage_id].cars.map(
                                            (id: string, index: number) => {
                                                if (carsData[id]) {
                                                    return (
                                                        <React.Fragment
                                                            key={`${id}-general-timeline-mobile`}
                                                        >
                                                            <GeneralTimelineCardMobile
                                                                car={
                                                                    carsData[id]
                                                                }
                                                                carIndex={Object.keys(
                                                                    carsData
                                                                ).indexOf(id)}
                                                                imagesData={
                                                                    imagesData
                                                                }
                                                            />
                                                        </React.Fragment>
                                                    )
                                                } else
                                                    return (
                                                        <div
                                                            key={`${id}-general-timeline-mobile`}
                                                        />
                                                    )
                                            }
                                        )
                                    ) : (
                                        <NoCarsInGarage variant="mobile" />
                                    )}
                                </CenteredWrapper>
                            </Faded>
                        </IpadAndMobileDisplay>
                    </FullPagePageWrapper>
                </Wrapper>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(GeneralTimeline)
