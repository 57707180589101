import { IApexPodscastState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import spotify from '../../../../public/assets/logo/spotify.png'
import soundcloud from '../../../../public/assets/logo/soundcloud.png'
import itunes from '../../../../public/assets/logo/itunes.png'
import googlepodcast from '../../../../public/assets/logo/googlepodcast.png'
import './style.css'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    margin-bottom: 50px;

    @media ${device.mobile} {
    }
`

const PodcastElemMobile = styled.iframe`
    allow: 'encrypted-media';
    height: 152px;
    width: 100%;
`

const Name = styled.div`
    font-size: 21px;
    font-family: 'Lato-Light';
    color: var(--text-strong, #1a1a1a);
`
const Title = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    font-size: 19px;
`

const Blurb = styled.div`
    font-size: 16px;
    font-family: 'Lato-Light';
    line-height: 23px;
    padding-right: 5vw;
    color: var(--text-strong, #1a1a1a);
`

const BtnsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-right: 10px;
`
const Icon = styled.img`
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 200ms;

    :hover {
        transition: all 200ms;
        transform: scale(1.1);
    }
`

const IconGoogle = styled.img`
    width: 40px;
    margin-right: 10px;
    box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 5%);
    cursor: pointer;
    border-radius: 50%;
    transition: all 200ms;

    :hover {
        transition: all 200ms;
        transform: scale(1.1);
    }
`
const Listen = styled.div`
    font-family: 'Lato';
    text-transform: uppercase;
    font-size: 12px;
    color: var(--text-strong, #1a1a1a);
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`
const Line = styled.div`
    background-color: var(--text-muted, #b3b3b3);
    height: 1px;
    width: 100%;
`
type Props = {
    podcast: IApexPodscastState
    index: number
}

class PodcastItemMobile extends React.Component<Props> {
    render() {
        let { index, podcast } = this.props

        // This is the url structure for spotify embeds: https://open.spotify.com/embed/episode/3AnL7BmcheJH1oGLlcmPGB
        const spotifyId = podcast?.spotify_url?.split('/').pop()
        // create the spotify embed URL
        const spotifyEmbedUrl = `https://open.spotify.com/embed/episode/${spotifyId}`

        return (
            <Container key={index}>
                <Name>{podcast.name}</Name>
                <div style={{ paddingTop: '2px' }} />
                <Title>{podcast.short_title}</Title>
                <div style={{ paddingTop: '11px' }} />
                <Blurb>{podcast.blurb ? podcast.blurb : ''}</Blurb>

                <div style={{ paddingTop: '28px' }} />

                <div className="holds-the-iframe-mobile">
                    <PodcastElemMobile
                        title={podcast.name}
                        src={spotifyEmbedUrl ? spotifyEmbedUrl : ''}
                        frameBorder={0}
                        allow="encrypted-media; picture-in-picture; clipboard-write; fullscreen"
                        scrolling="no"
                        loading="lazy"
                    />
                </div>
                <div style={{ paddingTop: '0px' }} />

                <Listen>Or listen on...</Listen>
                <div style={{ paddingTop: '20px' }} />

                <Row>
                    <BtnsRow>
                        {podcast.spotify_url !== '' && (
                            <a
                                href={podcast.spotify_url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon src={spotify} />
                            </a>
                        )}

                        {podcast.soundcloud_url !== '' && (
                            <a
                                href={podcast.soundcloud_url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon src={soundcloud} />
                            </a>
                        )}

                        {podcast.apple_url !== '' && (
                            <a
                                href={podcast.apple_url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Icon src={itunes} />
                            </a>
                        )}

                        {podcast.google_podcasts_url !== '' && (
                            <a
                                href={podcast.google_podcasts_url}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <IconGoogle src={googlepodcast} />
                            </a>
                        )}
                    </BtnsRow>

                    <Line />
                </Row>
            </Container>
        )
    }
}

export default PodcastItemMobile
