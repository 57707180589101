import { call, fork, put, take, select } from 'redux-saga/effects'
// WATCHER FUNCTION : watcher get car by id*

import { api } from '../../../services'
// import { push } from 'connected-react-router'

import {
    IDropdownItem,
    IExternalDataByRegistrationNumberSingle,
} from 'entityModels'
import { ConvertFromVesApiToInsuranceStateFormat } from '../../../conversions/entities/externalCarDataConversions'
import posthog from 'posthog-js'

import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../../../localdata/dropdownData/reducer'
import { RootState } from 'typesafe-actions'

import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../reducer'
import { push } from 'connected-react-router'
import { insurance_application_existing_state_select } from './carMileageParking_submit'

let getDropdownMakesDataState = (state: RootState): IDropdownItem[] | null =>
    state.localdata.dropdownData.carMakes

export type IInsuranceGetExternalCarDataByRegNumber = {
    car_id: string
    registration_number: string
}

export function* InsuranceGetExternalCarDataByRegNumberSaga(
    payload: IInsuranceGetExternalCarDataByRegNumber
) {
    posthog.capture('Get insurance application external car details with DVLA')
    let all_existing: IInsuranceQuoteApplication = yield select(
        insurance_application_existing_state_select
    )
    try {
        const apidata: IExternalDataByRegistrationNumberSingle = yield call(
            api.externalCarDataByRegistrationNumber
                .get_external_car_data_by_registration_number,
            payload.registration_number
        )

        yield put(insuranceActions.manage_external_car_id(payload.car_id))
        let insurance_converted_object: IAnyObject =
            ConvertFromVesApiToInsuranceStateFormat(apidata)

        let extApiMakeName = insurance_converted_object.make

        let makeName = null
        let makeId = null

        let makesList: IDropdownItem[] = yield select(getDropdownMakesDataState)

        let item: IDropdownItem

        for (const itemIndex in makesList) {
            item = makesList[itemIndex]

            if (
                item?.name?.toLowerCase() === `${extApiMakeName}`.toLowerCase()
            ) {
                makeName = item.name
                makeId = item.uid
                break
            }
        }

        if (makeId) {
            insurance_converted_object['make'] = {
                uid: makeId,
                name: makeName,
            }

            yield put(dropDownActions.setTempMakeDisplay(makeName))

            if (makeId) {
                yield put(dropDownActions.setTempMakeIDSuccess(makeId))
            }

            yield put(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: makeName,
                    make_uid: makeId ?? undefined,
                })
            )
        } else {
            // else ppl click on their model to recalibrate
            delete insurance_converted_object['make']
        }

        let cleaned_insurance_converted_object: { [key: string]: string } = {}

        Object.keys(insurance_converted_object).forEach((key) => {
            if (insurance_converted_object[key] !== 'not found') {
                cleaned_insurance_converted_object = {
                    ...cleaned_insurance_converted_object,
                    [key]: insurance_converted_object[key],
                }
            }
        })

        yield put(
            insuranceActions.set_multiple_answers_insurance_car_techInfo({
                carid: payload.car_id,
                data: { ...cleaned_insurance_converted_object },
            })
        )

        yield put(
            insuranceActions.get_external_vehicle_data_by_reg_number_success()
        )

        yield push(
            `/insurance/application/${all_existing.id}/everyday_car/${payload.car_id}/tech_info?add_new_external=true`
        )

        // Capture event
        posthog.capture(
            'DVLA API LOOKUP BY EXTERNAL CAR REGISTRATION DURING INSURANCE'
        )
    } catch (error: any) {
        yield put(
            insuranceActions.get_external_vehicle_data_by_reg_number_success()
        )

        yield push(
            `/insurance/application/${all_existing.id}/everyday_car/${payload.car_id}/tech_info?add_new_external=true`
        )

        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: InsuranceGetExternalCarDataByRegNumberSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        }
    }
}

function* watcherInsuranceGetExternalCarDataByRegNumberSaga() {
    while (true) {
        const { payload } = yield take(
            insuranceActions.get_external_vehicle_data_by_reg_number_request
        )

        yield call(InsuranceGetExternalCarDataByRegNumberSaga, payload)
    }
}

const get_insurance_external_car_data_by_registration_number: any[] = [
    fork(watcherInsuranceGetExternalCarDataByRegNumberSaga),
]

export default get_insurance_external_car_data_by_registration_number
