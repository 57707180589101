import * as React from 'react'
import styled from 'styled-components'
import crossed_circle_grey from '../../../public/assets/icons/crossed_circle_grey.svg'
import circled_red_cross from '../../../public/assets/icons/circled_red_cross.svg'
import { device } from '../../templates/displays/devices'

interface IStyle {
    $height?: string
    $bgColor?: string
    $isInfoRestricted?: boolean
}

const Rectangle = styled.div<IStyle>`
    width: 100%;
    height: ${(props) => (props.$height ? props.$height : '150px')};
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : 'var(--background_neutral)'};
    border: transparent;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    @media ${device.mobile} {
        height: ${(props) => (props.$height ? props.$height : '100px')};
    }
`

const Wrapper = styled.div<IStyle>`
    width: 100%;
    display: flex;
    justify-content: ${(props) =>
        props.$isInfoRestricted ? 'flex-start' : 'center'};
    padding-left: ${(props) => (props.$isInfoRestricted ? '32px' : '0px')};
    align-items: center;
    @media ${device.mobile} {
        justify-content: center;
        padding-left: 0px;
    }
`

const Text = styled.div`
    font-size: 13px;
    color: var(--text-muted);
    padding-left: 10px;
    text-transform: uppercase;
    max-width: 70%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    @media ${device.mobile} {
        font-size: 11px;
    }
`

const RestrictedText = styled.div`
    font-size: 14px;
    color: var(--text-muted);
    @media ${device.mobile} {
        font-size: 12px;
    }
`

const Logo = styled.img`
    width: 100%;
`

const LogoWrapper = styled.div<IStyle>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => (props.$isInfoRestricted ? '40px' : '17px')};
    @media ${device.mobile} {
        width: ${(props) => (props.$isInfoRestricted ? '32px' : '15px')};
    }
`
const RestrictedDataWrapper = styled.div`
    padding-left: 24px;
    @media ${device.mobile} {
        padding-left: 16px;
    }

    @media (max-width: 330px) {
        max-width: 70vw;
    }
`

type IProps = {
    height?: string
    text?: string
    bgColor?: string
    isInfoRestricted?: boolean
}

const NoDataRectangle: React.FunctionComponent<IProps> = (props) => {
    return (
        <Rectangle $height={props.height} $bgColor={props.bgColor}>
            <Wrapper $isInfoRestricted={props.isInfoRestricted}>
                <LogoWrapper $isInfoRestricted={props.isInfoRestricted}>
                    <Logo
                        src={
                            props.isInfoRestricted
                                ? circled_red_cross
                                : crossed_circle_grey
                        }
                    />
                </LogoWrapper>
                {props.isInfoRestricted ? (
                    <RestrictedDataWrapper>
                        <RestrictedText>Information not shared.</RestrictedText>
                        <RestrictedText>
                            Only the car/garage owner can allow access.
                        </RestrictedText>
                    </RestrictedDataWrapper>
                ) : (
                    <Text>{props.text ? props.text : 'No data'}</Text>
                )}
            </Wrapper>
        </Rectangle>
    )
}

export default NoDataRectangle
