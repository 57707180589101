import { IApexInterviewOverviewItemState } from 'apexModels'
import React from 'react'

// import styled from 'styled-components'
// import { device } from '../../../../../templates/displays/devices'
import ApexInterviewOverviewItemMobile from './apexInterviewOverviewItemMobile'

// const Wrapper = styled.div`
//     @media ${device.mobile_and_ipad} {

//     }

// `

type Props = {
    interview_overviews: IApexInterviewOverviewItemState[]
}

class ApexInterviewsOverviewManagerMobile extends React.Component<Props, {}> {
    render() {
        let { interview_overviews } = this.props

        return (
            <>
                {interview_overviews.map(
                    (item: IApexInterviewOverviewItemState, index: number) => {
                        return (
                            <div key={`interview_${index}-mobile`}>
                                <ApexInterviewOverviewItemMobile
                                    interview_overview={item}
                                />
                            </div>
                        )
                    }
                )}
            </>
        )
    }
}

export default ApexInterviewsOverviewManagerMobile
