type Props = {
    height?: string
    width?: string
}

const InsuranceCardPattern = ({ height, width }: Props) => {
    return (
        <svg
            width={width ?? '131'}
            height={height ?? '164'}
            viewBox="0 0 131 164"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.24"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.4375 82.0009C29.4375 25.6328 75.1328 -20.0625 131.501 -20.0625C187.869 -20.0625 233.564 25.6328 233.564 82.0009C233.564 138.369 187.869 184.064 131.501 184.064C75.1328 184.064 29.4375 138.369 29.4375 82.0009ZM131.501 -48.8125C59.2546 -48.8125 0.6875 9.75465 0.6875 82.0009C0.6875 154.247 59.2546 212.814 131.501 212.814C203.747 212.814 262.314 154.247 262.314 82.0009C262.314 9.75465 203.747 -48.8125 131.501 -48.8125ZM133.915 20.9624C100.205 20.9624 72.877 48.2903 72.877 82.0009C72.877 115.711 100.205 143.039 133.915 143.039C167.626 143.039 194.954 115.711 194.954 82.0009C194.954 48.2903 167.626 20.9624 133.915 20.9624ZM44.127 82.0009C44.127 32.4121 84.3266 -7.7876 133.915 -7.7876C183.504 -7.7876 223.704 32.4121 223.704 82.0009C223.704 131.59 183.504 171.789 133.915 171.789C84.3266 171.789 44.127 131.59 44.127 82.0009ZM133.309 61.9873C122.828 61.9873 113.899 70.7067 113.899 82.0008C113.899 93.2949 122.828 102.014 133.309 102.014C143.79 102.014 152.72 93.2949 152.72 82.0008C152.72 70.7067 143.79 61.9873 133.309 61.9873ZM85.149 82.0008C85.149 55.3104 106.472 33.2373 133.309 33.2373C160.146 33.2373 181.47 55.3104 181.47 82.0008C181.47 108.691 160.146 130.764 133.309 130.764C106.472 130.764 85.149 108.691 85.149 82.0008Z"
                fill="url(#paint0_linear_3910_30565)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3910_30565"
                    x1="-4.26755"
                    y1="175.486"
                    x2="156.656"
                    y2="38.1563"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop
                        offset="0.00192485"
                        stop-color="white"
                        stop-opacity="0"
                    />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default InsuranceCardPattern
