import { Tooltip } from 'antd'
import { ITask, ITaskNormalisedTaskInfoList } from 'entityModels'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { OTaskFilterNSort } from '../../../../ol/ol'
import { convertToDisplayedTaskObj } from '../../../../redux/conversions/tasks/displayTaskConversions'

import { device } from '../../../templates/displays/devices'
import dayjs from 'dayjs'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: start;
        height: 90px;
        width: auto;
        background-color: var(--bg-color, #fff);
        box-shadow: 5px 4px 20px 3px rgba(0, 0, 0, 0.02);
        border: 1px solid var(--border-muted, #e5e5e5);
        border-radius: 5px;
    }

    @media ${device.mobile} {
    }
`

type IStyle = {
    grey?: boolean
}
const TaskInfoListElementDesktop = styled.button<IStyle>`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        height: auto;
        height: 100%;
        flex: 1;
        background-color: var(--bg-color, #fff);
        border-right: 1px solid var(--border-muted, #e5e5e5);
        border-left: none;
        border-top: none;
        border-bottom: none;
        padding-left: 40px;
        cursor: pointer;
        transition: all 200ms;

        :focus {
            outline: 0;
        }

        :hover {
            transition: all 200ms;
            background-color: ${(props) =>
                props.grey
                    ? 'var(--border-muted, #e5e5e5)'
                    : 'var(--off-bg-color, #fafafa)'};
        }
    }

    @media ${device.ipad} {
        padding-left: 25px;
    }
`

const TaskInfoListElementDesktopNoHover = styled.section<IStyle>`
    @media ${device.beyond_ipad_mobile} {
        cursor: ${(props) => (props.grey ? 'not-allowed' : 'auto')};

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        height: auto;
        height: 100%;
        flex: 1;
        border-right: 1px solid var(--border-muted, #e5e5e5);
        padding-left: 40px;
    }

    @media ${device.ipad} {
        padding-left: 25px;
    }
`

const Text = styled.section`
    color: var(--text-default, #666666);
    text-transform: uppercase;
    font-size: 13px;
    padding-bottom: 10px;
`

const Answer = styled.section`
    text-transform: capitalise;
    font-size: 0.92rem;
    letter-spacing: 0.08em;
    color: ${(props) => (props.color ? props.color : 'var(--text-muted)')};
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    text-align: left;
`

const StatusRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const ViewMore = styled.div`
    color: var(--primary, #5ec3ca);
    font-size: 0.85rem;
    font-style: italic;
    padding-left: 10px;
    font-family: Lato;
    @media ${device.smallest_laptops} {
        padding-left: 0px;
        padding-top: 3px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;

    @media ${device.smallest_laptops} {
        flex-direction: column;
        justify-content: start;
        align-items: start;
    }
`

const ClickToAdd = styled.span`
    font-family: Lato-light;
    font-style: italic;
    font-size: 16px;
    color: var(--text-muted);
    @media ${device.ipad} {
        font-size: 15px;
    }
`

interface Props {
    task: ITask
    carsData: any
    garageId: string
    updateTask: any
    toggleUpdateTaskForm: any
}

type CarParams = {
    carid: string
}

export default function TaskInfoTableDesktop(props: Props) {
    let {
        task,
        updateTask,
        toggleUpdateTaskForm,
        // user_timezone,
    } = props

    let all_info_ids_list: string[] = [
        'status',
        'created_at',
        'reminders',
        'due',
    ]

    let infoObj: ITaskNormalisedTaskInfoList = convertToDisplayedTaskObj(
        task
        // user_timezone
    )

    const history = useHistory()
    const { carid } = useParams<CarParams>()

    const onReminderBoxClick = (hasData: boolean) => {
        let currentPath = history.location.pathname

        let isCarPage =
            currentPath.match(/car/g) !== null && carid ? true : false

        if (hasData) {
            if (isCarPage) {
                history.push(
                    `/car/${carid}/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                )
            } else {
                history.push(
                    `/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                )
            }
        } else {
            if (isCarPage) {
                history.push(
                    `/car/${carid}/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                )
            } else {
                history.push(
                    `/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                )
            }
        }
    }

    let today = new Date()

    const isDueDateInThePast =
        infoObj['due']?.answer &&
        infoObj['due'].answer !== '-' &&
        dayjs(infoObj['due'].answer).isValid()
            ? dayjs(infoObj['due'].answer).isBefore(today)
                ? true
                : false
            : undefined

    const { theme } = useThemes()

    let renderTableList = () =>
        all_info_ids_list.map((taskElem: string, index: number) => {
            if (infoObj[taskElem]) {
                if (infoObj[taskElem].id === 'status') {
                    return (
                        <React.Fragment
                            key={`status_${taskElem}_${index}_desktop`}
                        >
                            <TaskInfoListElementDesktopNoHover key={index}>
                                <Text>{infoObj[taskElem].text}</Text>
                                <StatusRow>
                                    <CustomAnimatedCheckboxField
                                        small
                                        fullColorOnTrue
                                        borderColor={colours[theme].text_muted}
                                        checked={
                                            infoObj[taskElem].answer &&
                                            infoObj[taskElem].answer ===
                                                OTaskFilterNSort.filter_by
                                                    .completed.name
                                                ? true
                                                : false
                                        }
                                        onChange={() => {
                                            updateTask({
                                                taskid: task.id,
                                                dataList: [
                                                    {
                                                        id: 'status',
                                                        answer:
                                                            infoObj[taskElem]
                                                                .answer &&
                                                            infoObj[taskElem]
                                                                .answer ===
                                                                OTaskFilterNSort
                                                                    .filter_by
                                                                    .completed
                                                                    .name
                                                                ? false
                                                                : true,
                                                    },
                                                ],
                                            })
                                        }}
                                    />

                                    <Answer
                                        color={
                                            infoObj[taskElem].answer ===
                                            OTaskFilterNSort.filter_by.completed
                                                .name
                                                ? 'var(--insurance_details_green, #85c98b)'
                                                : infoObj[taskElem].id ===
                                                      'status' &&
                                                  infoObj[taskElem].answer ===
                                                      OTaskFilterNSort.filter_by
                                                          .outstanding.name
                                                ? 'var(--error, #df6f6f)'
                                                : undefined
                                        }
                                    >
                                        {infoObj[taskElem].answer}
                                    </Answer>
                                </StatusRow>
                            </TaskInfoListElementDesktopNoHover>
                        </React.Fragment>
                    )
                } else if (
                    infoObj[taskElem].id ===
                    OTaskFilterNSort.sort_by.created_at.uid
                ) {
                    return (
                        <React.Fragment
                            key={`${taskElem}_${index}_desktop_no_hover`}
                        >
                            <TaskInfoListElementDesktopNoHover key={index}>
                                <Text>{infoObj[taskElem].text}</Text>

                                <Answer>{infoObj[taskElem].answer}</Answer>
                            </TaskInfoListElementDesktopNoHover>
                        </React.Fragment>
                    )
                } else if (
                    infoObj[taskElem].id === 'reminders' &&
                    (!infoObj['due'].answer || infoObj['due'].answer === '-')
                ) {
                    return (
                        <React.Fragment
                            key={`${taskElem}_${index}_w_tooltip_desktop`}
                        >
                            <Tooltip
                                title={'Please set a due date first'}
                                key={index}
                                placement="bottom"
                                color={colours[theme].text_darker}
                            >
                                <TaskInfoListElementDesktopNoHover grey={true}>
                                    <Text>
                                        {infoObj[taskElem] &&
                                            infoObj[taskElem].text}
                                    </Text>

                                    <ClickToAdd>
                                        Click to add a reminder
                                    </ClickToAdd>
                                </TaskInfoListElementDesktopNoHover>
                            </Tooltip>
                        </React.Fragment>
                    )
                } else if (
                    infoObj[taskElem].id === 'reminders' &&
                    isDueDateInThePast
                ) {
                    return (
                        <React.Fragment
                            key={`${taskElem}_${index}_w_tooltip_desktop`}
                        >
                            <Tooltip
                                title={'Due date is in the past.'}
                                key={index}
                                placement="bottom"
                                color={colours[theme].text_darker}
                            >
                                <TaskInfoListElementDesktopNoHover grey={true}>
                                    <Text>
                                        {infoObj[taskElem] &&
                                            infoObj[taskElem].text}
                                    </Text>

                                    <Answer>
                                        <Row>
                                            {infoObj[taskElem].answers ===
                                            undefined ? (
                                                <ClickToAdd>-</ClickToAdd>
                                            ) : (
                                                <ClickToAdd
                                                    style={{
                                                        fontStyle: 'normal',
                                                    }}
                                                >
                                                    {`${
                                                        infoObj[taskElem]
                                                            ?.answers ? (
                                                            infoObj[taskElem]
                                                                ?.answers![0]!
                                                        ) : (
                                                            <ClickToAdd>
                                                                Click to add a
                                                                reminder
                                                            </ClickToAdd>
                                                        )
                                                    }
                                                        `}
                                                </ClickToAdd>
                                            )}
                                            {infoObj &&
                                            infoObj[taskElem] &&
                                            infoObj[taskElem].answers
                                                ? infoObj[taskElem].answers!
                                                      .length > 1 && (
                                                      <ClickToAdd
                                                          style={{
                                                              fontStyle:
                                                                  'normal',
                                                          }}
                                                      >
                                                          +
                                                          {infoObj[taskElem]
                                                              .answers!.length -
                                                              1}{' '}
                                                          more
                                                      </ClickToAdd>
                                                  )
                                                : ''}
                                        </Row>
                                    </Answer>
                                </TaskInfoListElementDesktopNoHover>
                            </Tooltip>
                        </React.Fragment>
                    )
                } else if (infoObj[taskElem].id === 'reminders') {
                    return (
                        <React.Fragment
                            key={`${taskElem}_${index}_reminder_desktop`}
                        >
                            <TaskInfoListElementDesktop
                                onClick={() =>
                                    onReminderBoxClick(
                                        infoObj[taskElem].answers ? true : false
                                    )
                                }
                            >
                                <Text>{infoObj[taskElem].text}</Text>
                                <Answer>
                                    <Row>
                                        {infoObj[taskElem].answers ===
                                        undefined ? (
                                            <ClickToAdd>
                                                Click to add a reminder
                                            </ClickToAdd>
                                        ) : (
                                            `Next: ${
                                                infoObj[taskElem]?.answers ? (
                                                    infoObj[taskElem]
                                                        ?.answers![0]!
                                                ) : (
                                                    <ClickToAdd>
                                                        Click to add a reminder
                                                    </ClickToAdd>
                                                )
                                            }`
                                        )}
                                        {infoObj &&
                                        infoObj[taskElem] &&
                                        infoObj[taskElem].answers
                                            ? infoObj[taskElem].answers!
                                                  .length > 1 && (
                                                  <ViewMore>
                                                      +
                                                      {infoObj[taskElem]
                                                          .answers!.length -
                                                          1}{' '}
                                                      more
                                                  </ViewMore>
                                              )
                                            : ''}
                                    </Row>
                                </Answer>
                            </TaskInfoListElementDesktop>
                        </React.Fragment>
                    )
                } else if (
                    infoObj[taskElem].id === 'due' &&
                    isDueDateInThePast
                ) {
                    return (
                        <React.Fragment
                            key={`${taskElem}_${index}_w_tooltip_desktop`}
                        >
                            <Tooltip
                                title={'Due date is in the past.'}
                                key={index}
                                placement="bottom"
                            >
                                <TaskInfoListElementDesktopNoHover grey={true}>
                                    <Text>
                                        {infoObj[taskElem] &&
                                            infoObj[taskElem].text}
                                    </Text>

                                    <ClickToAdd
                                        style={{
                                            fontStyle: 'normal',
                                        }}
                                    >
                                        {infoObj[taskElem].id === 'due' &&
                                        infoObj[taskElem].answer === '-' ? (
                                            <ClickToAdd>
                                                Click to set a due date
                                            </ClickToAdd>
                                        ) : (
                                            <Answer>
                                                {infoObj[taskElem] &&
                                                    infoObj[taskElem].answer}
                                            </Answer>
                                        )}
                                    </ClickToAdd>
                                </TaskInfoListElementDesktopNoHover>
                            </Tooltip>
                        </React.Fragment>
                    )
                } else
                    return (
                        <React.Fragment
                            key={`${taskElem}_${index}_list_el_desktop`}
                        >
                            <TaskInfoListElementDesktop
                                key={index}
                                onClick={() => {
                                    toggleUpdateTaskForm({
                                        id: infoObj[taskElem].id,
                                        text:
                                            infoObj[taskElem] &&
                                            infoObj[taskElem].text,
                                        answer: infoObj[taskElem].answer,
                                    })
                                }}
                            >
                                <Text>
                                    {infoObj[taskElem] &&
                                        infoObj[taskElem].text}
                                </Text>

                                {infoObj[taskElem].id === 'due' &&
                                infoObj[taskElem].answer === '-' ? (
                                    <ClickToAdd>
                                        Click to set a due date
                                    </ClickToAdd>
                                ) : (
                                    <Answer>
                                        {infoObj[taskElem] &&
                                            infoObj[taskElem].answer}
                                    </Answer>
                                )}
                            </TaskInfoListElementDesktop>
                        </React.Fragment>
                    )
            } else return <div key={index} />
        })

    return <Wrapper>{renderTableList()}</Wrapper>
}
