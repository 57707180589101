import { useEffect, useState } from 'react'
import { RouteComponentProps, useParams, withRouter } from 'react-router'
import { menuActions } from '../../../../redux/menus/reducer'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import styled from 'styled-components'

import { ITabProps } from 'myInterfaces'
import BtnTabRow from '../../../molecules/tab/btnTabRow'

import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { IUser } from 'myModels'

import {
    ICarHandover_structure_base,
    info_box_handover_copy,
    props_handoverForm_customData,
    TimelineEntryHandoverTypesData,
    total_hf_enum_n,
    total_photos_enum_n,
} from '../../../../redux/localdata/carHandoverCopy'
import {
    I_handleSelectExistingOwner_handover,
    I_props_Handover_ownerForm,
} from '../../../organisms/carHandoverManagers/carHandoverOwnerForm'
import CarHandoverOwnerForm from '../../../organisms/carHandoverManagers/carHandoverOwnerForm'
import InfoBox from '../../../atoms/info/infoBox'
import GoBackTopBar from '../../../templates/bars/showroomBars/topBars/goBackTopBar'
import CarHandoverCustomDataForm from '../../../organisms/carHandoverManagers/carHandoverCustomDataForm'
import BottomBarBtnsStep from '../../../atoms/Button/bottomBarBtnsStep'
import CarHandoverSuccess from '../../../organisms/carHandoverManagers/carHandoverSuccess'
import { device } from '../../../templates/displays/devices'
import { ICreateHandoverAPI_req } from '../../../../redux/services/handover/createHandover'
import { createHandoverRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import { IReduxError } from 'entityModels'

const MobilePageWrap = styled.div`
    max-height: 100vh;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
`

const FormTitle = styled.div`
    font-family: Lato-Semibold;
    /* font-weight: 600; */
    font-size: 24px;
    color: var(--text-strong, #1a1a1a);
`

const FormText = styled.div`
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    color: var(--text-default, #666666);
    line-height: 24px;
    padding-bottom: 6px;
`

type IStyledProps = {
    $isSuccessStep?: boolean
    $isSearchForm?: boolean
}

const DesktopPageWrap = styled.div<IStyledProps>`
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    background-color: var(--off-bg-color, #fafafa);
`

const FormWrapperDesktop = styled.div<IStyledProps>`
    margin-top: ${(props: IStyledProps) =>
        props.$isSuccessStep ? '20vh' : '100px'};

    width: ${(props: IStyledProps) =>
        props.$isSuccessStep ? '550px' : '443px'};

    @media ${device.mobile_and_ipad} {
        width: 100%;
    }

    min-height: 360px;
    max-height: 82vh;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    z-index: 3;
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: ${(props: IStyledProps) =>
        props.$isSuccessStep ? '4px' : '16px'};

    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
`

const SubFormWrapperDesktop = styled.div<IStyledProps>`
    position: relative;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 16px;
    ${(props: IStyledProps) => !props.$isSearchForm && 'overflow-y: scroll'};
    ${(props: IStyledProps) => !props.$isSearchForm && 'overflow-x: hidden;'};

    height: 100%;
    max-height: 82vh;
    overflow-y: scroll;

    /* width */
    ::-webkit-scrollbar {
        width: 0px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #999;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
`

type IOpacityFormStyleProps = {
    side: 'left' | 'right'
}

const FormWrapperDesktopOpacity = styled.div<IOpacityFormStyleProps>`
    position: absolute;
    top: 6%;
    bottom: 6%;
    left: ${(props: IOpacityFormStyleProps) =>
        props.side === 'left' ? '-40px' : '100%'};
    right: ${(props: IOpacityFormStyleProps) =>
        props.side === 'right' ? '-40px' : '100%'};
    height: 88%;
    width: 40px;
    background-color: var(--bg-color, #fff);
    border-top-right-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'right' ? '16px' : '0px'};
    border-bottom-right-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'right' ? '16px' : '0px'};
    border-top-left-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'left' ? '16px' : '0px'};
    border-bottom-left-radius: ${(props: IOpacityFormStyleProps) =>
        props.side === 'left' ? '16px' : '0px'};
    opacity: 0.5;
    transition: all 200ms cubic-bezier(0.25, 0.25, 0, 1);
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04);
    z-index: 0;
`
type I_convertHandoverFormToAPIReq = {
    data: ICarHandover_structure_base
    carID: string
}

const convertToSelectedOption = (p: ICarHandover_structure_base): number => {
    if (p.handover.full === true) {
        return total_hf_enum_n + total_photos_enum_n
    }

    let final: number = 0

    if (p.handover.custom.gallery) {
        final = final + p.handover.custom.gallery
    }

    if (p.handover.custom.history_file) {
        for (let i = 0; i < p.handover.custom.history_file.length; i++) {
            final = final + p.handover.custom.history_file[i]
        }
    }

    if (final === 0) {
        return 1
    } else return final
}

const convertHandoverFormToAPIReq = (
    p: I_convertHandoverFormToAPIReq
): ICreateHandoverAPI_req | undefined => {
    if (
        p.data.owner.existing ||
        (p.data.owner.new.first_name &&
            p.data.owner.new.last_name &&
            p.data.owner.new.email)
    ) {
        return {
            carID: p.carID,
            data: {
                selection_option: convertToSelectedOption(p.data),
                new_owner_email:
                    p.data.owner.existing !== undefined
                        ? undefined
                        : p.data.owner.new.email,
                new_owner_first_name: p.data.owner.new.first_name,
                new_owner_last_name: p.data.owner.new.last_name,
                new_owner_id: p.data.owner.existing,
            },
        }
    } else return undefined
}

type CarParams = {
    carid: string
}

interface Props extends RouteComponentProps {
    location: any
}

const CarHandover: React.FunctionComponent<RouteComponentProps> = (
    props: Props
) => {
    const { carid } = useParams<CarParams>()

    const [activeTab, setActiveTab] = useState<'full' | 'custom'>('full')
    const [activeStep, setActiveStep] = useState<
        'owner' | 'handover' | 'success'
    >('owner')

    const [isInviteActive, setIsInviteActive] = useState<boolean>(false)

    let userLoggedIn: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        }
    }, [])

    let handoverError: IReduxError = useAppSelector((state) => {
        return state.entities.carsData.error
    })

    let empty_base: ICarHandover_structure_base = {
        owner: {
            new: {
                email: undefined,
                first_name: undefined,
                last_name: undefined,
                display_name: undefined,
            },
            existing: undefined,
            existing_display_name: undefined,
        },
        handover: {
            full: true,
            custom: {
                history_file: TimelineEntryHandoverTypesData.map(
                    (item) => item.id
                ),
                gallery: total_photos_enum_n,
            },
        },
    }

    const [handoverFormData, setHandoverFormData] =
        useState<ICarHandover_structure_base>(empty_base)

    useEffect(() => {
        setHandoverFormData(empty_base)
    }, [])

    const dispatch = useAppDispatch()

    useEffect(() => {
        // trigger the function to dispatch the top sub menu remove action
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        // set up nav back as before on page unmount
        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const handleUserInviteValues = (id: string, value: string | undefined) => {
        let data = { ...handoverFormData }
        if (id === 'email' || id === 'last_name' || id === 'first_name') {
            data.owner.new[id] = value
            setHandoverFormData({
                ...data,
            })
        }
    }

    const setNewValsHF = (arr: number[]) => {
        setHandoverFormData({
            ...handoverFormData,
            handover: {
                ...handoverFormData.handover,
                full: false,
                custom: {
                    ...handoverFormData.handover.custom,
                    history_file: [...arr],
                },
            },
        })
    }

    const setNewValsGal = (id: number) => {
        setHandoverFormData({
            ...handoverFormData,
            handover: {
                ...handoverFormData.handover,
                full: false,
                custom: {
                    ...handoverFormData.handover.custom,
                    gallery: id,
                },
            },
        })
    }

    // we already know which id should be effective in the state,
    // no need to worry about arguments further down reusable components
    const handleSelectedIDHistoryFile = (selectedID: number) => {
        if (selectedID === total_hf_enum_n) {
            setNewValsHF(TimelineEntryHandoverTypesData.map((item) => item.id))
        } else if (selectedID === 0) {
            setNewValsHF([0])
        } else if (
            selectedID !== total_hf_enum_n &&
            selectedID !== 0 &&
            activeStep === 'handover' &&
            activeTab === 'custom'
        ) {
            let index: number =
                handoverFormData.handover.custom.history_file.indexOf(
                    selectedID
                )

            if (index === -1) {
                setNewValsHF([
                    ...handoverFormData.handover.custom.history_file,
                    selectedID,
                ])
            } else {
                setNewValsHF(
                    handoverFormData.handover.custom.history_file.filter(
                        (item: number) => item !== selectedID
                    )
                )
            }
        }
    }

    const handleSelectedGalleryValue = (selectedID: number) => {
        setNewValsGal(selectedID)
    }

    const handleSelectExistingOwner = (
        p: I_handleSelectExistingOwner_handover
    ) => {
        let data = { ...handoverFormData }
        data.owner.existing = p.id
        data.owner.existing_display_name = p.display_name

        setHandoverFormData({
            ...data,
        })
    }

    const props_ownerForm: I_props_Handover_ownerForm = {
        handleUserInviteValues,
        handleSelectExistingOwner,
        setIsInviteActive,
        handoverFormData,
        isInviteActive,
    }

    const props_handoverForm_customData: props_handoverForm_customData = {
        handleSelectedIDHistoryFile,
        handleSelectedGalleryValue,
        setActiveStep,
        handoverFormData,
    }

    let renderForm = () => {
        if (activeStep === 'success' && !handoverError) {
            return (
                <CarHandoverSuccess
                    new_owner_name={
                        handoverFormData.owner.existing_display_name
                            ? handoverFormData.owner.existing_display_name
                            : handoverFormData.owner.new.first_name &&
                              handoverFormData.owner.new.last_name
                            ? `${handoverFormData.owner.new.first_name}  
                              ${handoverFormData.owner.new.last_name}`
                            : 'noname'
                    }
                    onClickContinute={() => {
                        props.history.replace('/garage/previously-owned')
                    }}
                    hasInfoBox={
                        activeTab === 'full' && isInviteActive ? true : false
                    }
                />
            )
        } else if (activeStep === 'owner') {
            return <CarHandoverOwnerForm {...props_ownerForm} />
        } else if (activeStep === 'handover')
            if (activeTab === 'full') {
                return null
            } else if (activeTab === 'custom') {
                return (
                    <CarHandoverCustomDataForm
                        {...props_handoverForm_customData}
                    />
                )
            }
    }

    let tabs: ITabProps[] = [
        {
            id: 0,
            title: 'Full',
            onClick: (arg: number) => {
                setActiveTab('full')
                setNewValsHF(
                    TimelineEntryHandoverTypesData.map((item) => item.id)
                )
                handleSelectedGalleryValue(total_photos_enum_n)
            },
            isActive: activeTab === 'full' ? true : false,
        },
        {
            id: 1,
            title: 'Custom',
            onClick: (arg: number) => setActiveTab('custom'),
            isActive: activeTab === 'custom' ? true : false,
        },
    ]

    const submitHandover = () => {
        let payload = convertHandoverFormToAPIReq({
            carID: carid,
            data: handoverFormData,
        })

        if (payload) {
            dispatch(createHandoverRequest(payload))
        }
    }

    let renderBtns = () => {
        if (activeStep === 'owner') {
            return (
                <BottomBarBtnsStep
                    cancel={() => {
                        if (
                            props.location.state?.prevPath.includes(
                                'previously-owned'
                            )
                        ) {
                            props.history.replace(`/garage/previously-owned`)
                        } else {
                            props.history.replace(`/car/${carid}`)
                        }
                    }}
                    submit={() => {
                        setActiveStep('handover')
                    }}
                    confirmationtext="continue"
                    disabled={
                        handoverFormData.owner.existing ||
                        (handoverFormData.owner.new.email &&
                            handoverFormData.owner.new.first_name &&
                            handoverFormData.owner.new.last_name)
                            ? false
                            : true
                    }
                    customSidePadding={'10px'}
                    customBg="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
            )
        }
        if (activeStep === 'handover') {
            return (
                <BottomBarBtnsStep
                    cancel={() => {
                        setActiveStep('owner')
                    }}
                    firstText={'previous'}
                    submit={() => {
                        submitHandover()

                        setTimeout(() => {
                            if (!handoverError) {
                                setActiveStep('success')
                            }
                        }, 150)
                    }}
                    confirmationtext="confirm"
                    disabled={false}
                    hasGoBack={false}
                    customSidePadding={'10px'}
                    customBg="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
            )
        }
    }

    const renderInfoBox = () => {
        if (activeStep === 'handover' || isInviteActive) {
            return (
                <>
                    {activeStep === 'handover' && (
                        <div style={{ paddingTop: '24px' }} />
                    )}

                    <InfoBox
                        text={
                            isInviteActive && activeStep === 'owner'
                                ? info_box_handover_copy.new
                                : info_box_handover_copy[activeTab]
                        }
                    />
                    {activeTab !== 'custom' && (
                        <div style={{ paddingTop: '24px' }} />
                    )}
                </>
            )
        } else return null
    }

    return (
        <div>
            <DesktopDisplayOnly>
                {activeStep !== 'success' && <GoBackTopBar />}
                <DesktopPageWrap $isSuccessStep={activeStep === 'success'}>
                    <FormWrapperDesktop
                        $isSuccessStep={activeStep === 'success'}
                    >
                        {activeStep !== 'success' && (
                            <FormWrapperDesktopOpacity
                                side={activeStep === 'owner' ? 'right' : 'left'}
                            />
                        )}
                        <SubFormWrapperDesktop
                            $isSearchForm={
                                activeStep === 'owner' && !isInviteActive
                                    ? true
                                    : false
                            }
                        >
                            {activeStep !== 'success' ? (
                                <>
                                    <div style={{ paddingTop: '40px' }} />
                                    <FormStepProgress
                                        totalSteps={2}
                                        currentStep={
                                            activeStep === 'owner' ? 1 : 2
                                        }
                                        screen_name="desktop"
                                    />
                                    <div style={{ paddingTop: '40px' }} />

                                    <FormTitle>
                                        {activeStep === 'owner'
                                            ? `Select new owner`
                                            : 'Car Handover'}
                                    </FormTitle>
                                    {activeStep === 'owner' && (
                                        <div
                                            style={{
                                                paddingTop: 24,
                                                width: '100%',
                                            }}
                                        >
                                            <FormText>
                                                Welcome to the Handover Process!
                                            </FormText>
                                            <FormText>
                                                Here, you can securely transfer
                                                your car. Start by searching the
                                                new owner by their email.
                                            </FormText>
                                        </div>
                                    )}

                                    {activeStep === 'handover' ? (
                                        <>
                                            <div style={{ paddingTop: 32 }} />
                                            <BtnTabRow
                                                tabs={tabs}
                                                customPassiveColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                                            />
                                        </>
                                    ) : (
                                        <div
                                            style={{
                                                paddingTop: isInviteActive
                                                    ? '30px'
                                                    : '40px',
                                            }}
                                        />
                                    )}
                                </>
                            ) : null}

                            {renderInfoBox()}
                            {renderForm()}

                            <div
                                style={{
                                    paddingTop:
                                        activeStep === 'success'
                                            ? '50px'
                                            : '120px',
                                }}
                            />
                        </SubFormWrapperDesktop>

                        {renderBtns()}
                    </FormWrapperDesktop>
                </DesktopPageWrap>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <MobilePageWrap
                    style={{
                        display: 'flex',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        paddingBottom: '100px',
                    }}
                >
                    {activeStep !== 'success' ? (
                        <>
                            <div style={{ paddingTop: '40px' }} />
                            <FormStepProgress
                                totalSteps={2}
                                currentStep={activeStep === 'owner' ? 1 : 2}
                                screen_name="mobile"
                            />

                            <div style={{ paddingTop: '48px' }} />

                            <FormTitle>
                                {activeStep === 'owner'
                                    ? `Select new owner`
                                    : 'Car Handover'}
                            </FormTitle>
                            <div style={{ paddingTop: '5px' }} />

                            {activeStep === 'owner' && (
                                <div
                                    style={{
                                        paddingTop: 24,
                                        paddingBottom: 32,
                                        width: '100%',
                                    }}
                                >
                                    <FormText>
                                        Welcome to the Handover Process!
                                    </FormText>
                                    <FormText>
                                        Here, you can securely transfer your
                                        car. Start by searching the new owner by
                                        their email.
                                    </FormText>
                                </div>
                            )}

                            {activeStep === 'handover' ? (
                                <div
                                    style={{
                                        paddingTop: '32px',
                                        width: '100%',
                                    }}
                                >
                                    <BtnTabRow tabs={tabs} />
                                </div>
                            ) : null}
                        </>
                    ) : (
                        <div style={{ paddingTop: 16 }} />
                    )}

                    {renderInfoBox()}
                    {renderForm()}

                    {renderBtns()}
                </MobilePageWrap>
            </IpadAndMobileDisplay>
        </div>
    )
}

export default withRouter(CarHandover)
