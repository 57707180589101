import * as React from 'react'
import styled from 'styled-components'
import { ITimelineEntryTypes, ITimelineItem } from 'timelineModels'
import { calculate_entry_n_attachments } from '../../../../redux/conversions/timeline/n_entry_attachments'
import colours, {
    ITheme,
    history_file_colours,
} from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import dayjs from 'dayjs'
import { renderCategoryIcon } from '../../../atoms/icons/components/historyFile/categories'
import HistoryFileIcons from '../../../atoms/icons/components/historyFile'
import { useAppSelector } from '../../../../redux/store/hooks'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import { numberWithCommas } from '../../../../helpers/numbers'
import { historyFileBaseEntry } from '../../../organisms/journey/historyFile/steps/add/entriesFormBody'
import { AddIcon } from '../../../atoms/statefulicons'
import { costNumShorterFormatter } from '../../../../helpers/currencyConverter'

interface IStyled {
    $theme: ITheme
    $category: ITimelineEntryTypes
    $isEmpty?: boolean
}

const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 24px;
    /* gap: 24px; */
    min-width: 0px;
    background-color: var(--bg-color, #fff);
    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 8px;
    font-family: Lato;
    width: 100%;
`

const FlexRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    min-width: 0px;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    min-width: 0px;
`

const TopRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    min-width: 0px;
`

export const CategoryLabel = styled.div<IStyled>`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 99px;
    background-color: ${(props) =>
        history_file_colours[props.$theme].categories[props.$category]
            .primary_100};
    color: var(--bg-color, #fff);
    text-transform: capitalize;
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px;
`

const InfoLabel = styled.div<IStyled>`
    display: flex;
    padding-right: 12px;
    align-items: center;
    border-radius: 8px;
    background-color: ${(props) =>
        props.$isEmpty
            ? history_file_colours[props.$theme].categories[props.$category]
                  .primary_04
            : history_file_colours[props.$theme].categories[props.$category]
                  .primary_16};
    overflow: hidden;
    color: ${(props) =>
        props.$isEmpty
            ? colours[props.$theme].text_muted
            : colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.001px;
`

const ItemLabel = styled.div<IStyled>`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    border: 1px solid ${(props) => colours[props.$theme].border_muted};
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.84px;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const Title = styled.h3<IStyled>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_strong};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Lato-Semibold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const Date = styled.p<IStyled>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const Description = styled.p<IStyled>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

const LabelPlaceholder = styled.div<IStyled>`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    color: ${(props) => colours[props.$theme].text_muted};
    min-width: 87px;
    height: 26px;

    font-family: Lato-Semibold;

    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`

const IconWrapper = styled.div`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
`
type Props = {
    entry: ITimelineItem | undefined
    userCurrency: string | undefined
    onClick: () => any
}

const TimelineItemMobileJourneyCard: React.FC<Props> = (props) => {
    const { entry } = props

    let item = entry ?? historyFileBaseEntry

    let companies: string[] | undefined = item.costUIDS
        ?.filter(
            (costUID: string) =>
                item.costItemsObj &&
                item.costItemsObj[costUID] &&
                item.costItemsObj[costUID].company !== undefined
        )
        .map((costUID: string) => {
            return item.costItemsObj![costUID].company!
        })

    const { theme } = useThemes()

    let carid = useAppSelector((state) => state.entities.carsData.currentCarID)
    let technicalInformationData = useAppSelector(
        (state) => state.entities.technicalInformationData.technical_information
    )
    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    let userDistanceUnitId =
        userLoggedIn &&
        userLoggedIn.preferences.data.units.data.distance_unit.user_choice_id
    let userDistanceUnit =
        userLoggedIn &&
        userDistanceUnitId &&
        userLoggedIn.preferences.data.units.data.distance_unit.data[
            userDistanceUnitId
        ].short

    let carMileageFromState: number | string | undefined | null =
        carid &&
        technicalInformationData &&
        technicalInformationData[`hf-${carid}`] &&
        technicalInformationData[`hf-${carid}`].mileage &&
        technicalInformationData[`hf-${carid}`].mileage.answer

    let carMileage: number | undefined | null =
        typeof carMileageFromState === 'string'
            ? parseInt(carMileageFromState)
            : carMileageFromState

    let convertedCarMileage =
        carMileage && userDistanceUnitId
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnitId,
                  carMileage
              )
            : undefined

    let convertedMileageRecorded: number | undefined =
        userDistanceUnitId && item.mileage
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnitId,
                  item.mileage
              )
            : undefined

    let mileageSince =
        convertedCarMileage !== undefined &&
        convertedMileageRecorded !== undefined &&
        convertedCarMileage >= convertedMileageRecorded
            ? convertedCarMileage - convertedMileageRecorded
            : undefined

    let attach_no = calculate_entry_n_attachments(item)

    let userCurrencyPreferences =
        userLoggedIn?.preferences.data.units.data.default_currency
            .user_choice_id

    let userCurrency = props.userCurrency ?? userCurrencyPreferences

    let labels_no = 3

    return (
        <Wrapper role="button" onClick={props.onClick}>
            <Column>
                <TopRow>
                    <CategoryLabel
                        $theme={theme}
                        $category={
                            !entry
                                ? 'insurance'
                                : entry.categoryID
                                ? entry.categoryID
                                : 'maintenance'
                        }
                    >
                        {entry ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 4,
                                }}
                            >
                                {renderCategoryIcon(item.categoryID)}{' '}
                                {item.categoryID}
                            </div>
                        ) : (
                            <div
                                style={{
                                    color: colours[theme].background_default,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 4,
                                    fontSize: '12px',
                                }}
                            >
                                <AddIcon
                                    height="10px"
                                    width="10px"
                                    onClick={() => {}}
                                    colour={colours[theme].background_default}
                                />
                                Select Category
                            </div>
                        )}
                    </CategoryLabel>
                    <Date
                        $theme={theme}
                        $category={item.categoryID ?? 'maintenance'}
                    >
                        {entry &&
                        entry.categoryID === 'insurance' &&
                        entry.insurance_entry_policy_start_date ? (
                            dayjs(
                                entry.insurance_entry_policy_start_date
                            ).format('DD MMM YYYY')
                        ) : entry && item.date ? (
                            dayjs(item.date).format('DD MMM YYYY')
                        ) : (
                            <div
                                style={{
                                    color: history_file_colours[theme]
                                        .categories.insurance.primary_100,
                                }}
                            >
                                Add Date
                            </div>
                        )}
                    </Date>
                </TopRow>
                <FlexRow>
                    {item.labels && item.labels.length > 0
                        ? item.labels.map((label, i) =>
                              i < labels_no ? (
                                  <ItemLabel
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  >
                                      {label.name}
                                  </ItemLabel>
                              ) : item.labels?.length &&
                                item.labels?.length > 3 &&
                                i === labels_no ? (
                                  <ItemLabel
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  >
                                      +{item.labels?.length - labels_no}
                                  </ItemLabel>
                              ) : null
                          )
                        : [1, 2, 3].map((p) =>
                              p === 1 ? (
                                  <LabelPlaceholder
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  >
                                      <div
                                          style={{
                                              paddingRight: '5px',
                                              paddingLeft: '5px',
                                              fontSize: '10px',
                                          }}
                                      >
                                          NO LABELS
                                      </div>
                                  </LabelPlaceholder>
                              ) : (
                                  <LabelPlaceholder
                                      style={{ opacity: p === 2 ? 0.6 : 0.3 }}
                                      $theme={theme}
                                      $category={
                                          item.categoryID ?? 'maintenance'
                                      }
                                  />
                              )
                          )}
                </FlexRow>
            </Column>

            <div style={{ paddingTop: '16px' }} />
            <Title $theme={theme} $category={item.categoryID ?? 'maintenance'}>
                {item.title ? (
                    item.title
                ) : (
                    <div
                        style={{
                            color: history_file_colours[theme].categories
                                .insurance.primary_100,
                        }}
                    >
                        Add Entry Title
                    </div>
                )}
            </Title>
            <div style={{ paddingTop: '8px' }} />
            {item.description ? (
                <Description
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    style={{ transform: 'translateY(-10px)' }}
                >
                    {item.description}
                </Description>
            ) : (
                <Description
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    style={{ color: colours[theme].text_muted }}
                >
                    No description added
                </Description>
            )}

            <div style={{ paddingTop: '24px' }} />

            <FlexRow>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={companies && companies?.length > 0 ? false : true}
                >
                    <IconWrapper>
                        <HistoryFileIcons.CompaniesIcon
                            color={
                                companies && companies?.length > 0
                                    ? undefined
                                    : colours[theme].text_muted
                            }
                        />
                    </IconWrapper>
                    {companies?.length ?? 0}
                </InfoLabel>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={attach_no ? false : true}
                >
                    <IconWrapper>
                        <HistoryFileIcons.AttachmentsIcon
                            color={
                                attach_no
                                    ? undefined
                                    : colours[theme].text_muted
                            }
                        />
                    </IconWrapper>
                    {attach_no ?? 0}
                </InfoLabel>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={
                        item.total_cost_amount_display !== '0' &&
                        item.total_cost_amount_display !== '0.00'
                            ? false
                            : true
                    }
                >
                    <IconWrapper>
                        {userCurrency ? (
                            userCurrency === 'GBP' ? (
                                <HistoryFileIcons.CurrencyIcons.GbpIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? undefined
                                            : colours[theme].text_muted
                                    }
                                />
                            ) : userCurrency === 'USD' ? (
                                <HistoryFileIcons.CurrencyIcons.UsdIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? undefined
                                            : colours[theme].text_muted
                                    }
                                />
                            ) : userCurrency === 'EUR' ? (
                                <HistoryFileIcons.CurrencyIcons.EurIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? undefined
                                            : colours[theme].text_muted
                                    }
                                />
                            ) : (
                                <HistoryFileIcons.CurrencyIcons.GbpIcon
                                    color={
                                        item.total_cost_amount_display !==
                                            '0' &&
                                        item.total_cost_amount_display !==
                                            '0.00'
                                            ? undefined
                                            : colours[theme].text_muted
                                    }
                                />
                            )
                        ) : (
                            <HistoryFileIcons.CurrencyIcons.GbpIcon
                                color={
                                    item.total_cost_amount_display
                                        ? undefined
                                        : colours[theme].text_muted
                                }
                            />
                        )}
                    </IconWrapper>
                    {item.costItemsObj && item.costItemsObj['id'].amount
                        ? costNumShorterFormatter(
                              +item.costItemsObj['id'].amount
                          )
                        : '0.00'}
                </InfoLabel>
                <InfoLabel
                    $theme={theme}
                    $category={item.categoryID ?? 'maintenance'}
                    $isEmpty={item.mileage ? false : true}
                >
                    <IconWrapper>
                        <HistoryFileIcons.MileageIcon
                            color={
                                item.mileage
                                    ? undefined
                                    : colours[theme].text_muted
                            }
                        />
                    </IconWrapper>
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {convertedMileageRecorded
                            ? `${numberWithCommas({
                                  numberToConvert: convertedMileageRecorded,
                                  decimals: 0,
                              })}  ${userDistanceUnit} `
                            : 'unknown'}
                    </span>
                    <span
                        style={{
                            color: colours[theme].primary,
                            paddingLeft: 2,
                            fontFamily: 'Lato-Semibold',
                        }}
                    >
                        {mileageSince !== undefined
                            ? ` +${numberWithCommas({
                                  numberToConvert: mileageSince,
                                  decimals: 0,
                                  round: 'ceil',
                              })}`
                            : ''}
                    </span>
                    {mileageSince ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                        >
                            <path
                                d="M7.19998 5.56667C7.59998 5.03333 8.39998 5.03333 8.79998 5.56667L11.3 8.9C11.7944 9.55924 11.324 10.5 10.5 10.5L5.49998 10.5C4.67594 10.5 4.20556 9.55924 4.69998 8.9L7.19998 5.56667Z"
                                fill={colours[theme].primary}
                            />
                        </svg>
                    ) : null}
                </InfoLabel>
            </FlexRow>
        </Wrapper>
    )
}

export default TimelineItemMobileJourneyCard
