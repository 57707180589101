import { motion } from 'framer-motion'
import styled from 'styled-components'

const Fab = styled(motion.div)<StyleProps>`
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    transition: all 500ms;
`

type Props = {
    isOpen: boolean
    onClick: any
}

type StyleProps = {
    isOpen: boolean
}

const CloseActionsIcon = ({ isOpen, onClick }: Props) => (
    <Fab whileTap={{ scale: 1.1 }} isOpen={isOpen} onClick={onClick}>
        <svg
            width="40"
            height="40"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="26.3637"
                cy="26.3637"
                r="26.2637"
                fill="white"
                stroke="#5EC3CA"
                strokeWidth="0.2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.0804 31.3311L28.5933 26.7981L33.1263 22.3109C33.625 21.8173 33.6291 21.0055 33.1355 20.5069C32.6419 20.0083 31.8301 20.0041 31.3315 20.4977L26.7984 24.9848L22.3113 20.4518C21.8177 19.9532 21.0059 19.949 20.5073 20.4426C20.0086 20.9362 20.0045 21.748 20.4981 22.2467L24.9852 26.7797L20.4522 31.2668C19.9535 31.7604 19.9494 32.5722 20.443 33.0709C20.9366 33.5695 21.7484 33.5736 22.247 33.0801L26.7801 28.5929L31.2672 33.126C31.7608 33.6246 32.5726 33.6287 33.0712 33.1351C33.5699 32.6415 33.574 31.8297 33.0804 31.3311Z"
                fill="#5EC3CA"
            />
        </svg>
    </Fab>
)

export default CloseActionsIcon
