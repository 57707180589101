import styled from 'styled-components'
import React, { useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from './tutorialConfigsCarGalleryImageMobile'
import './styles.css'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import grey_tip from '../../../../public/assets/icons/grey_tip.svg'
import TutorialWrapperMobile from '../tutorialWrappers/TutorialWrapperMobile'
// import close_cross_grey from '../../../../public/assets/icons/close_cross_grey.svg'

// type StyledProps = {
//     currentStep: number
// }
// const TourStyled = styled(Tour)<StyledProps>`
const TourStyled = styled((props: any) => <Tour {...props} />)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: ${(props) => (props.currentStep === 1 ? '80vw' : 'auto')};
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

// const Wrapper = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//     height: 100%;
//     padding-top: 10px;
//     padding-bottom: 10px;
// `

const Title = styled.h1`
    color: #009a9f;
    font-family: Lato-Light;
    font-size: 16px;
    justify-content: center;
    text-align: center;

    @media ${device.mobile} {
    }
`

const Text = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
    color: black;
    align-self: start;
    justify-self: start;
    font-size: 14px;
    line-height: 1.9;
    font-family: Lato-Light;
    font-style: italic;

    @media ${device.mobile} {
    }
`

const InnerTextRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-style: normal;
`

const Padding = styled.div`
    padding-top: 20px;
`
const TextPadding = styled.div`
    padding-top: 8px;
`

const RoundWhite = styled.div`
    background-color: var(--bg-color, #fff);
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    transform: translateY(5px);
`

// const Skip = styled.div`
//     position: absolute;
//     top: 15px;
//     right: 15px;
//     font-size: 15px;
//     color: #838383;
//     cursor: pointer;
//     font-family: 'Lato-light';
//     letter-spacing: 1px;

//     @media ${device.mobile} {
//     }
// `

const Tip = styled.img`
    width: 20px;
    @media ${device.mobile} {
    }
`

const TipText = styled.div`
    font-family: Lato-Light;
    padding-left: 20px;
    font-size: 14px;

    @media ${device.mobile} {
    }
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
    align-self: start;
    justify-self: start;
`

// const NextBtn = styled.div`
//     position: absolute;
//     left: 50%;
//     transform: translate(-50%, 0);
//     bottom: 20px;
//     color: #5EC3CA;
//     cursor: pointer;
//     font-size: 15px;
//     font-family: lato-light;
// `

export type ReactourStepPosition =
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'center'
    | [number, number]

let topPositionStrongType: ReactourStepPosition = 'top'

const tutorialConfigs_Car = (close: any) => [
    {
        selector: '.car-gallery-image-tuto-mobile-1',
        content: () => (
            //<Wrapper>
            <TutorialWrapperMobile
                skip={close}
                next={close}
                nextBtnText="close"
                translateNextBtn={true}
            >
                {/* <Skip onClick={() => close()}>
                    Skip
                </Skip> */}

                <Title>Car Gallery Image</Title>
                <Padding />

                <Text>
                    <InnerTextRow>
                        <RoundWhite
                            style={{
                                width: '36px !important',
                                height: '36px !important',
                            }}
                        >
                            <svg
                                width="2"
                                height="12"
                                viewBox="0 0 3 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.00006 15.4999C3.00006 14.6715 2.32849 13.9999 1.50006 13.9999C0.671634 13.9999 6.10352e-05 14.6715 6.10352e-05 15.4999C6.10352e-05 16.3284 0.671634 16.9999 1.50006 16.9999C2.32849 16.9999 3.00006 16.3284 3.00006 15.4999Z"
                                    fill="#5EC3CA"
                                />
                                <path
                                    d="M3.00006 1.49994C3.00006 0.671512 2.32849 -6.10352e-05 1.50006 -6.10352e-05C0.671634 -6.10352e-05 6.10352e-05 0.671512 6.10352e-05 1.49994C6.10352e-05 2.32837 0.671634 2.99994 1.50006 2.99994C2.32849 2.99994 3.00006 2.32837 3.00006 1.49994Z"
                                    fill="#5EC3CA"
                                />
                                <path
                                    d="M3.00006 8.49994C3.00006 7.67151 2.32849 6.99994 1.50006 6.99994C0.671634 6.99994 6.10352e-05 7.67151 6.10352e-05 8.49994C6.10352e-05 9.32837 0.671634 9.99994 1.50006 9.99994C2.32849 9.99994 3.00006 9.32837 3.00006 8.49994Z"
                                    fill="#5EC3CA"
                                />
                            </svg>
                        </RoundWhite>

                        <div style={{ marginLeft: '10px' }}>
                            Click on the Three Dots on the bottom right of the
                            image to:
                        </div>
                    </InnerTextRow>
                </Text>
                <TextPadding />
                <Text>- select as Car Profile Image</Text>
                <Text>- select as Gallery Feature</Text>
                <Text>- update image details </Text>
                <Text>- delete the image</Text>

                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>to zoom in, click on the image.</TipText>
                </TipRow>

                {/* <NextBtn
                    onClick={() => {
                        close()
                    }}
                >
                    close
                </NextBtn> */}
            </TutorialWrapperMobile>
            //</Wrapper>
        ),
        position: topPositionStrongType,
    },
]

interface Props extends RouteComponentProps<any> {
    [key: string]: any
}

const TutorialConfigsCarGalleryImageMobile: React.FunctionComponent<Props> = (
    props
) => {
    const [currentStep, setCurrentStep] = useState(0)
    const [isTourOpen, setIsTourOpen] = useState(true)

    const setCurrentStepCustom = (step: number) => {
        setCurrentStep(step)
    }

    // need carid + imgid

    let carid: string = props.match.params.carid

    let params = new URLSearchParams(props.location.search)

    let image_id_param = params.get('id')

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        props.history.push(`/car/${carid}/gallery?id=${image_id_param}`)
    }

    return (
        <>
            {window.innerWidth < 880 && (
                <>
                    <TourStyled
                        steps={tutorialConfigs_Car(setIsTourOpenCustom)}
                        isOpen={isTourOpen}
                        onRequestClose={() => undefined}
                        rounded={5}
                        accentColor="#009a9f"
                        getCurrentStep={(curr: number) => {
                            return setCurrentStepCustom(curr + 1)
                        }}
                        currentStep={currentStep}
                        disableInteraction={true}
                        showCloseButton={false}
                        showNavigation={false}
                    />
                </>
            )}
        </>
    )
}

export default withRouter(TutorialConfigsCarGalleryImageMobile)
