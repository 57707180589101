import * as React from 'react'
import styled from 'styled-components'
import { ITimelineItem } from 'timelineModels'
import AddNewGreyButton from '../../atoms/Button/addNewGreyButton'
import { device } from '../../templates/displays/devices'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { IExternalCar } from 'entityModels'
import SectionHeaderV3 from '../../atoms/typography/sectionHeaderV3'
import NavigateSquareIcon from '../../atoms/icons/components/navigateSquareIcon'

// interface IStyle {}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @media ${device.ipad} {
        transform: translateY(-17px);
    }
    @media (min-width: 1100px) and (max-width: 1200px) {
        padding-left: 0px;
        padding-right: 30px;
    }
`

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

const LeftDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

const RightDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 48px;
`

const SmallPadding = styled.div`
    padding-left: 10px;
`

const VerticalLine = styled.div`
    margin-left: 10px;
    margin-right: 20px;
    height: 24px;
    width: 1px;
    background-color: var(--text-strong, #1a1a1a);
`

interface Props extends RouteComponentProps<any> {
    item: ITimelineItem
    goBackToEntry?: any
    uploadImage: any
    carID: string
    readOnlyMode?: boolean
    sharedCar?: IExternalCar
}

class EntryGalleryPageTopHeaderDesktop extends React.Component<Props> {
    render() {
        let readOnlyLinkPrefix = this.props.sharedCar ? '/shared' : '/archives'

        return (
            <Wrapper>
                <LeftDiv>
                    <TitleWrapper>
                        <Link
                            to={{
                                pathname: this.props.readOnlyMode
                                    ? `${readOnlyLinkPrefix}/car/${this.props.carID}/history-file/entry`
                                    : `/car/${this.props.carID}/history-file/entry`,
                                search: `?entryid=${this.props.item.id}`,
                                state: {
                                    prevPath:
                                        this.props.history.location.pathname,
                                },
                            }}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <NavigateSquareIcon />{' '}
                        </Link>
                        <SmallPadding />
                        <SmallPadding />
                        <SectionHeaderV3
                            fontsize="24px"
                            icon="v2_edit"
                            onClick={() => {}}
                            hasActionIcon={false}
                        >
                            {this.props.item.title}
                        </SectionHeaderV3>
                        <VerticalLine />{' '}
                        <span
                            style={{
                                fontFamily: 'Lato-light',
                                fontSize: '18px',
                            }}
                        >
                            Gallery
                        </span>
                    </TitleWrapper>
                </LeftDiv>
                {!this.props.readOnlyMode && (
                    <RightDiv>
                        <AddNewGreyButton
                            text="Upload image"
                            onClick={this.props.uploadImage}
                        />
                    </RightDiv>
                )}
            </Wrapper>
        )
    }
}

export default withRouter(EntryGalleryPageTopHeaderDesktop)
