import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import { useRef } from 'react'
import PenIcon from '../icons/components/penIcon'

type StyledProps = {
    $width?: string
    $colourOne?: string
    $colourTwo?: string
    $isActiveBtn?: boolean
}

const MainButton = styled.button<StyledProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border-radius: 5px;
    width: ${(props) => (props.$width ? props.$width : '100%')};
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    padding-right: 24px;
    background-color: var(--bg-color, #ffffff);
    cursor: pointer;
    border: 1px solid var(--border-muted, #e5e5e5);
    color: #1f1f1f;
    transition: all 200ms;

    background-color: ${(props) =>
        !props.$isActiveBtn ? 'inherit' : 'var(--primary_04, #f5feff)'};

    :hover {
        transition: all 200ms;
        color: var(--text-strong, #1a1a1a);
    }

    @media ${device.mobile_and_ipad} {
        padding-left: 20px;
    }
`

const BtnWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
`
const Text = styled.div<StyledProps>`
    text-transform: uppercase;
    font-size: 12px;
    font-family: Lato;
    line-height: 16px;
    text-align: left;
    color: var(--text-darker, #616161);

    @media ${device.mobile_and_ipad} {
        text-transform: capitalize;
        font-size: 16px;
    }
`
const ColourOne = styled.div<StyledProps>`
    width: 48px;
    height: 48px;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.$colourOne ? props.$colourOne : '#000000'};

    :hover {
        border: solid 2px var(--primary_80, rgba(94, 195, 202, 0.8));
        transform: scale(1.1);
        transition: all 200ms;
    }
`

const ColourTwo = styled.div<StyledProps>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) =>
        props.$colourTwo ? props.$colourTwo : 'var(--bg-color, #fff)'};
`

const ColourTwoWrapper = styled.div<StyledProps>`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: var(--bg-color, #fff);
    display: flex;
    align-items: center;
    justify-content: center;
`

type Props = {
    colourOne: string
    colourTwo?: string
    onClick?: any
    dataCyId?: string | undefined
    txt: string
    width?: string
    isActiveBtn?: boolean
    index?: number
    hasEditIcon?: boolean
}

const QrCodeStickerThemeBtnJourney: React.FunctionComponent<Props> = (
    props
) => {
    let {
        onClick,
        txt,
        dataCyId,
        width,
        colourOne,
        colourTwo,
        isActiveBtn,
        hasEditIcon,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    return (
        <BtnWrapper ref={ref}>
            <motion.div whileTap={{ scale: 1.05 }} style={{ width: '100%' }}>
                <MainButton
                    $width={width}
                    onClick={() => {
                        onClick()
                    }}
                    data-attr={dataCyId ? dataCyId : undefined}
                    $colourTwo={colourTwo}
                    $isActiveBtn={isActiveBtn}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 16,
                        }}
                    >
                        <ColourOne
                            $colourOne={colourOne}
                            $isActiveBtn={isActiveBtn}
                        >
                            {colourTwo && (
                                <ColourTwoWrapper>
                                    <ColourTwo $colourTwo={colourTwo} />
                                </ColourTwoWrapper>
                            )}
                        </ColourOne>
                        <Text>{txt}</Text>
                    </div>
                    {hasEditIcon && <PenIcon color="#666666" />}
                </MainButton>
            </motion.div>
        </BtnWrapper>
    )
}

export default QrCodeStickerThemeBtnJourney
