import {
    IShortlistedFeatureItem,
    IUpcomingPageSectionOneList,
    IWhatWeBuiltItem,
} from 'upcomingPageModels'

import section_1_icon_1 from '../../../public/assets/upcoming/section_1_icon_1.svg'
import section_1_icon_2 from '../../../public/assets/upcoming/section_1_icon_2.svg'
import section_1_icon_3 from '../../../public/assets/upcoming/section_1_icon_3.svg'

import section_1_icon_1_dark from '../../../public/assets/upcoming/section_1_icon_1_dark.svg'
import section_1_icon_2_dark from '../../../public/assets/upcoming/section_1_icon_2_dark.svg'
import section_1_icon_3_dark from '../../../public/assets/upcoming/section_1_icon_3_dark.svg'

export const section_one_copy_list: IUpcomingPageSectionOneList[] = [
    {
        id: 'u-s1-1',
        text: 'Our vision is to become the go-to platform for solving any pains of automotive ownership.',
        icon: section_1_icon_1,
        icon_dark: section_1_icon_1_dark,
    },
    {
        id: 'u-s1-2',
        text: 'We are building in public and driven by the engagement back and forth with the community.',
        icon: section_1_icon_2,
        icon_dark: section_1_icon_2_dark,
    },
    {
        id: 'u-s1-3',
        text: "Let us know what you'd like us to build: we're listening and on it.",
        icon: section_1_icon_3,
        icon_dark: section_1_icon_3_dark,
    },
]

export const shortlisted_features_list: IShortlistedFeatureItem[] = [
    {
        id: 'sf-0',
        text: 'On Display',
        answer: 'Our first social element of Custodian! Should you wish to do so (on a strict opt-in basis), it will allow you to share your digital garages and cars to other members of the community.',
    },
    {
        id: 'sf-3',
        text: 'Invoice Tracking System',
        answer: "Keep track of all your car's related invoices by keeping them organised and securely stored in one place. Tooling provides the ability to monitor your automotive costs with easy to use graphs and tables.",
    },
    {
        id: 'sf-4',
        text: 'Service Discovery',
        answer: 'Find the right people who care about your car just as much as you do. Whether you have a more mainstream car or a rare one-off, we will find the service provider with the specific skills and experience required for the job.',
    },
    {
        id: 'sf-5',
        text: 'Events Calendar',
        answer: 'Never miss an event again, with a helpful calendar of automotive events and experiences ranging from cars & coffee to concours and race meetings, along with the relevant links and details.',
    },
]

export const what_we_built: IWhatWeBuiltItem[] = [
    {
        id: 'wwb-1',
        text: 'Your Virtual Garage',
        dateFinished: 'July 2021',
        answer: "Your garage, now digital. Whether you're a first-time car buyer or a long-time collector, your virtual garage is designed to bring the joy of ownership online and to keep all your car's details in one place.",
    },

    {
        id: 'wwb-2',
        text: 'Image Galleries',
        dateFinished: 'July 2021',
        answer: 'A place to store both historical and current photos of your car, and build a record of your car’s journey, including restoration work, maintenance, road trips and events, all with associated notes, locations and credits.',
    },
    {
        id: 'wwb-3',
        text: 'Task Management System',
        dateFinished: 'July 2021',
        answer: 'Simplify your ownership experience and keep on top of all your car’s needs. With an easy to use design, ability to add due dates, notes and details, our task management system streamlines automotive admin.',
    },
    {
        id: 'wwb-4',
        text: 'Virtual Car Profiles',
        dateFinished: 'July 2021',
        answer: 'All your car’s information, history, photos, tasks and details all in one place. Build a profile of your car to secure its digital provenance and bring your ownership experience online.',
    },

    {
        id: 'wwb-5',
        text: 'Integration of The Apex Podcast & Publication',
        dateFinished: 'July 2021',
        answer: 'Our journal of podcasts with the titans and tastemakers of the automotive universe and features exploring the automotive world and all things custodianship.',
    },
    {
        id: 'wwb-03',
        text: 'Sharing systems',
        dateFinished: 'July 2021',
        answer: 'Custom QR codes matching your car vibe, public car & garage profile links, private shares... Simply set your sharing preferences and invite others to access your car or garage. Stay in control and manage sharing options at any time and from anywhere. Share cars and collaborate with friends, enthusiasts, collectors, or people interested in buying a car.',
    },
    {
        id: 'wwb-5',
        text: 'History File Digitisation',
        dateFinished: 'January 2022',
        answer: 'Digitise your car’s history file to secure and preserve its provenance. All the car’s important documentation, invoices and photos at your fingertips, safely and securely stored for posterity.',
    },
    {
        id: 'sf-1',
        text: 'Reminders System',
        dateFinished: 'March 2022',
        answer: 'Make sure your car gets what it needs, when it needs it with customisable reminders for key actions such as services, maintenance, MOTs, tax, insurance renewals, events and so on.',
    },
    {
        id: 'sf-06',
        text: 'Showroom',
        dateFinished: 'July 2023',
        answer: "A marketplace like no other! The Showroom will not only provide a great selection of cars for sale, but also the ability to request access to cars’ digital History Files. What's more, upon a sale the car’s whole profile and associated photos, invoices and documents can be transferred to the new owner's Custodian digital garage.",
    },
    {
        id: 'sf-07',
        text: 'Enthusiast Insurance',
        dateFinished: 'December 2023',
        answer: 'Driven by enthusiasm, powered by trust. Meet the insurance that rewards your automotive passion.',
    },
]
