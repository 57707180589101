import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../atoms/Inputfield/inputField'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import { yupVar_BasicSingleField } from '../yupVars'
import useThemes from '../../../../providers/theme/hooks'

const WrapperMobile = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    @media ${device.ipad} {
        padding-left: 25px;
    }
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: red;
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const SmallPadding = styled.div`
    padding-top: 30px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_basic_field_mobile: string
}

interface OtherProps {
    data: IEditOrCreateModeSingleFieldsProps
    dataCyId?: string
    placeholder: string
    hasError?: (payload: boolean) => void
    isDisabled?: boolean
    isNaked?: boolean
    fontSize?: string
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        data,
        errors,
        setFieldValue,
        hasError,
        isDisabled,
        fontSize,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const handleAnswerChange = (e: any) => {
        setFieldValue(
            'single_item_edited_value_basic_field_mobile',
            e.target.value
        )
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                e.target.value,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, e.target.value)
        }
        if (errors.single_item_edited_value_basic_field_mobile) {
            hasError && hasError(true)
        } else {
            hasError && hasError(false)
        }
    }

    const { theme } = useThemes()

    return (
        <WrapperMobile>
            <InputField
                theme={theme}
                id={'single_item_edited_value_basic_field_mobile'}
                name={'single_item_edited_value_basic_field_mobile'}
                placeholder={data.title}
                helperText={props.placeholder}
                tabindex={1}
                aria-live="polite"
                formikprops={props}
                value={values.single_item_edited_value_basic_field_mobile}
                font_family="Lato"
                paddingLeft="0px"
                width="100%"
                fontSize={fontSize ? fontSize : '16px'}
                onChange={handleAnswerChange}
                textTransform={'capitalize'}
                dataCyId={props.dataCyId}
                disabled={isDisabled}
            />
            <Expander
                height={
                    values.single_item_edited_value_basic_field_mobile.length >
                        1 && errors.single_item_edited_value_basic_field_mobile
                        ? 'auto'
                        : 0
                }
            >
                <Faded>
                    <div style={{ paddingTop: '5px' }} />
                    <Error className="animated fadeIn slower">
                        {errors.single_item_edited_value_basic_field_mobile}
                    </Error>
                </Faded>
            </Expander>
            <SmallPadding />
        </WrapperMobile>
    )
}

const BasicEditOrCreateModeSingleFieldMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_basic_field_mobile: props.data.value_start
            ? props.data.value_start
            : '',
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_basic_field_mobile:
                    yupVar_BasicSingleField(props.data),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(BasicEditOrCreateModeSingleFieldMobile)
