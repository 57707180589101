import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Button from '../Button/ButtonAtom'
import Expander from '../expander/expander'
import './styles.css'
import parse from 'html-react-parser'

const Reveal = styled.div<IStyle>`
    position: absolute;
    width: 100%;
    height: auto;
    top: 1;
    z-index: 1;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0), #ffffff 70%);
    opacity: ${(props) => (props.isOpen ? '0' : '1')};

    transition: all 300ms;
`
const Wrapper = styled.div<IStyle>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: start;
    justify-self: start;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: start;
    justify-self: start;
`

const ReadMore = styled.div<IStyle>`
    z-index: 2;
    padding-top: 30px;

    @media ${device.beyond_ipad_mobile} {
        padding-left: 5px;
    }
`

interface Props {
    notes: string
    onClick?: any
}

type State = {
    isOpen: boolean
}

interface IStyle {
    isOpen: boolean
}

class TimelineNotesDesktop extends React.Component<Props, State> {
    state = {
        isOpen: false,
    }

    toggleReveal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        const { notes } = this.props
        const { isOpen } = this.state
        let isTextShort: boolean = notes.length < 1822 ? true : false

        return (
            <Container>
                {notes.length > 1 && (
                    <Wrapper isOpen={isTextShort === true ? true : isOpen}>
                        <Reveal isOpen={isTextShort === true ? true : isOpen} />
                        <Expander
                            height={
                                isTextShort === true
                                    ? 'auto'
                                    : isOpen
                                    ? 'auto'
                                    : 200
                            }
                        >
                            {parse(notes)}
                        </Expander>

                        {!isTextShort && (
                            <ReadMore isOpen={isOpen}>
                                <Button
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        this.toggleReveal()
                                    }}
                                    theme="naked-text"
                                >
                                    {isOpen ? 'read less' : 'read more'}
                                </Button>
                            </ReadMore>
                        )}
                    </Wrapper>
                )}
            </Container>
        )
    }
}

export default TimelineNotesDesktop
