import gql from 'graphql-tag'
import { get_check_cookie_name } from '../../../helpers/cookies'
import { client } from '../../../helpers/prismicUtils/prismicHelpers'

/*
 * Apex All Cars of the Week
 */
const apexCarsOfTheWeekQuery = gql`
    query all_apex_cars_of_the_week_by_desc_date {
        allApex_car_of_the_weeks(sortBy: date_DESC, last: 100) {
            edges {
                node {
                    date
                    img
                    car_name
                    title
                    description
                    link {
                        ... on _ExternalLink {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const apexCarsoftheWeekQuery = async () => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexCarsOfTheWeekQuery,
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}
