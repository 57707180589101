import { call, fork, select, take, put } from 'redux-saga/effects'
import { RootState } from 'typesafe-actions'
import { api } from '../../../services'
import * as actions from '../actions/index'
import * as carActions from '../../../entities/cars/actions/index'
import * as galleryActions from '../../../entities/galleries/actions/index'
import * as highlighted_facts_actions from '../../../entities/highlighted_facts/actions/index'

import { IGaragePayload } from 'IapiDataPayload'
import { IUser } from 'myModels'
import {
    ICarsObject,
    ICustomErrorData,
    IGalleryImagesObject,
    IGarage,
    IGarageObject,
    IHighlightedFactsObject,
    IReduxError,
} from 'entityModels'
import {
    convertToCarState,
    convertToGalleryImagesState,
    convertToHighlightedFactsState,
} from '../../../conversions/entities/conversionFromAPI'

import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'

import { getCurrentUserDataSaga } from '../../../user/sagas/getUserData'

export const getUserLoggedInFromState = (state: RootState) =>
    state.user.userLoggedIn

const getCarsDataState = (state: RootState) => state.entities.carsData.cars

const getGalleryState = (state: RootState) =>
    state.entities.galleriesData.images

const getHighFactsState = (state: RootState) =>
    state.entities.highlightedFactsData

const getGaragesState = (state: RootState) => state.entities.garagesData.garages

export function* getGarageByGarageId(garageid: string) {
    try {
        const garagePayload: IGaragePayload = yield call(
            api.entities.getData.getGarageData_api,
            garageid
        )

        const garages_data_in_state: IGarageObject = yield select(
            getGaragesState
        )
        const garage_in_state: IGarage = garages_data_in_state[garageid]

        if (garagePayload.cars && garagePayload.cars.length > 0) {
            // cdeclare wveythiong to convert

            const carsDataState: ICarsObject = yield select(getCarsDataState)

            const galleryDataState: IGalleryImagesObject | null = yield select(
                getGalleryState
            )

            const highFactsState: IHighlightedFactsObject | null = yield select(
                getHighFactsState
            )

            let carObjects: ICarsObject = carsDataState
                ? { ...carsDataState }
                : {}

            let galleryImagesObject: IGalleryImagesObject = galleryDataState
                ? {
                      ...galleryDataState,
                  }
                : {}

            let highlightedFactsObject: IHighlightedFactsObject = highFactsState
                ? { ...highFactsState }
                : {}

            let car_ids: string[] = []
            for (const item of garagePayload.cars) {
                // add id to car ids list
                car_ids.push(item.uid)

                if (!carsDataState[item.uid]) {
                    // convert to car state payload

                    let car: ICarsObject = convertToCarState(item)

                    car[item.uid].has_limited_garage_info = true

                    let gallery_images_object: IGalleryImagesObject =
                        convertToGalleryImagesState(item)

                    let highlighted_facts_object: IHighlightedFactsObject =
                        convertToHighlightedFactsState(item)

                    Object.assign(galleryImagesObject, gallery_images_object)

                    Object.assign(carObjects, car)

                    Object.assign(
                        highlightedFactsObject,
                        highlighted_facts_object
                    )
                }
            }

            const cars_results: ICarsObject = carObjects

            yield put(carActions.actions.getCarDataSuccess(cars_results))

            yield put(
                galleryActions.actions.setGalleryImagesSuccess(
                    galleryImagesObject
                )
            )
            yield put(
                highlighted_facts_actions.actions.setHighlightedFactsSuccess(
                    highlightedFactsObject
                )
            )

            const garageToAdd: IGarage = garage_in_state
                ? {
                      ...garage_in_state,
                      cars: car_ids,
                  }
                : { uid: garageid, cars: car_ids }

            yield put(actions.actions.setGarageDataSuccess(garageToAdd))
            yield put(actions.actions.getGarageByUserIdSuccess(garageToAdd))
            yield put(
                actions.actions.setTotalCarsNumberFound(garagePayload.count)
            )
        } else {
            const garageToAdd: IGarage = garage_in_state
                ? {
                      ...garage_in_state,
                      cars: [],
                  }
                : { uid: garageid, cars: [] }

            yield put(actions.actions.setGarageDataSuccess(garageToAdd))
            yield put(actions.actions.getGarageByUserIdSuccess(garageToAdd))
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getGarageByGarageId,
                payload: garageid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Please refresh the page and try again, or check your internet connection.`,
                custom_user_action_text: 'Refresh',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(actions.errorActions.getGarageByUserIdError(customError))
            return
        }
    }
}

function* getUserDataAndGarageAssociatedSaga(userid: string): any {
    try {
        yield call(getCurrentUserDataSaga)

        let userLoggedIn: IUser | null | undefined = yield select(
            getUserLoggedInFromState
        )
        if (userLoggedIn) {
            let gi = userLoggedIn?.owns_garage.uid
                ? userLoggedIn?.owns_garage.uid
                : ''
            yield call(getGarageByGarageId, gi)
        }
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getGarageByGarageId,
                payload: userid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Please refresh the page and try again, or check your internet connection.`,
                custom_user_action_text: 'Refresh',
                custom_redirect_path: '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(actions.errorActions.getGarageByUserIdError(customError))
        }
    }
}

export function* retrieveUserGarageId(): Generator<any, any, any> {
    let userLoggedIn: IUser | null | undefined = yield select(
        getUserLoggedInFromState
    )

    if (!userLoggedIn) {
        yield call(getCurrentUserDataSaga)

        userLoggedIn = yield select(getUserLoggedInFromState)
        if (userLoggedIn && userLoggedIn.id && !userLoggedIn.email) {
            yield call(getUserDataAndGarageAssociatedSaga, userLoggedIn.id)
        } else if (userLoggedIn) {
            yield call(getUserDataAndGarageAssociatedSaga, userLoggedIn.id)
        }
    } else {
        yield call(getUserDataAndGarageAssociatedSaga, userLoggedIn.id)
    }
}

// WATCHER FUNCTION : watcherGetGarageByUserId*

function* watcherGetGarageByUserId() {
    while (true) {
        yield take(actions.loadingActions.getGarageByUserIdRequest)
        yield call(retrieveUserGarageId)
    }
}

const garage_sagas: any[] = [fork(watcherGetGarageByUserId)]

export default garage_sagas
