import { IUserAddressApi } from 'IapiDataPayload'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const createUserAddressApi = (
    address: IUserAddressApi
): Promise<any> => {
    if (address.postcode) {
        address.postcode = address.postcode?.toUpperCase()
    }

    let rpq: string = JSON.stringify(address)
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: rpq,
        credentials: 'include',
    }

    return fetch(urls.create_address_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create user address error',
                            rpq
                        )
                        if (data) {
                            return Promise.reject(data)
                        }
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data.data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
