import { ITask, ITaskNormalisedTaskInfoList } from 'entityModels'
import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../../ol/ol'
import { convertToDisplayedTaskObj } from '../../../../redux/conversions/tasks/displayTaskConversions'
import TaskInfoListElementMobile from '../../../atoms/list/taskInfoListElementMobile'

import { device } from '../../../templates/displays/devices'
import dayjs from 'dayjs'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
    }

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        height: auto;
    }

    @media ${device.ipad} {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
        height: auto;
    }
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

interface Props extends RouteComponentProps<any> {
    task: ITask
    carsData: any
    garageId: string
    openUpdateTaskForm: any
    updateTask: any
    isEveryCar?: boolean
}

type State = {}

class TaskInfoTableMobile extends React.Component<Props, State> {
    state = {}

    componentDidMount() {}

    render() {
        let {
            task,
            carsData,
            openUpdateTaskForm,
            updateTask,
            // user_timezone,
        } = this.props

        let infoObj: ITaskNormalisedTaskInfoList = convertToDisplayedTaskObj(
            task
            // user_timezone
        )

        let today = new Date()

        const isDueDateInThePast =
            task && task.due && dayjs(task.due).isValid()
                ? dayjs(task.due).isBefore(today)
                    ? true
                    : false
                : undefined

        let all_info_ids_list: string[] = [
            'linkedToCar',
            'due',
            'reminders',
            'status',
        ]

        let generateLinkTitle = () => {
            if (task.linkedToCar) {
                if (this.props.isEveryCar) {
                    return 'Every Car'
                } else if (
                    carsData[task.linkedToCar[0]] &&
                    task.linkedToCar.length === 1
                ) {
                    if (
                        carsData[task.linkedToCar[0]] &&
                        carsData[task.linkedToCar[0]].title
                    ) {
                        return `${
                            carsData[task.linkedToCar[0]].title.slice(0, 20) +
                            '...'
                        }`
                    } else return 'untitled car'
                } else if (task.linkedToCar.length > 1) {
                    if (
                        carsData[task.linkedToCar[0]] &&
                        carsData[task.linkedToCar[0]].title
                    ) {
                        return `${
                            carsData[task.linkedToCar[0]].title.slice(0, 17) +
                            '...'
                        } + ${task.linkedToCar.length - 1}`
                    } else return 'untitled car'
                }
            } else return OOtherTaskOptions.general_task.name
        }

        let renderTableList = () =>
            all_info_ids_list.map((taskElem: string, index: number) => {
                if (infoObj[taskElem]) {
                    if (taskElem === 'linkedToCar') {
                        infoObj[taskElem] = {
                            ...infoObj[taskElem],
                            answer: generateLinkTitle(),
                            answers: task.linkedToCar,
                        }
                    } else if (taskElem === 'reminder') {
                        if (task.reminders) {
                            infoObj[taskElem] = {
                                ...infoObj[taskElem],
                                answer: 'task.reminders[0]',
                                //answers: task.reminders,
                            }
                        } else {
                            infoObj[taskElem] = {
                                ...infoObj[taskElem],
                                answer: 'task.reminders',
                            }
                        }
                    }

                    let currentPath = this.props.history.location.pathname
                    let carid = this.props.match.params.carid
                    let isCarPage =
                        currentPath.match(/car/g) !== null && carid
                            ? true
                            : false

                    return (
                        <React.Fragment key={`${taskElem}_${index}_mobile`}>
                            <TaskInfoListElementMobile
                                openUpdateTaskForm={openUpdateTaskForm}
                                key={index}
                                obj={infoObj[taskElem]}
                                noDueDateSet={
                                    !infoObj['due'].answer ||
                                    infoObj['due'].answer === '-'
                                        ? true
                                        : false
                                }
                                isDueDateInThePast={isDueDateInThePast}
                                createReminders={() =>
                                    isCarPage
                                        ? this.props.history.push(
                                              `/car/${carid}/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                                          )
                                        : this.props.history.push(
                                              `/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                                          )
                                }
                                viewReminders={() =>
                                    isCarPage
                                        ? this.props.history.push(
                                              `/car/${carid}/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                                          )
                                        : this.props.history.push(
                                              `/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                                          )
                                }
                                updateTask={updateTask}
                                taskid={task.id}
                                color={
                                    infoObj[taskElem].id === 'status' &&
                                    infoObj[taskElem].answer ===
                                        OTaskFilterNSort.filter_by.completed
                                            .name
                                        ? 'green'
                                        : infoObj[taskElem].id === 'status' &&
                                          infoObj[taskElem].answer ===
                                              OTaskFilterNSort.filter_by
                                                  .outstanding.name
                                        ? '#df6f6f'
                                        : undefined
                                }
                            />
                        </React.Fragment>
                    )
                } else return <div key={index} />
            })

        return <Wrapper>{renderTableList()}</Wrapper>
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TaskInfoTableMobile)
)
