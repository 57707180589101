import { ICar, ICarsObject } from 'entityModels'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { ITimelineDataByCar, ITimelineItem } from 'timelineModels'
import { getCarDataByIdRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../../redux/entities/cars/actions/thunks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../../redux/store/hooks'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import Loader from '../../../atoms/loader/loader'
import { CarInsightsTimeMenu } from '../../../atoms/menu/carInsights/timeMenu'
import CarInsightsBody from '../../../organisms/carInsights/body'
import Faded from '../../../templates/animated/faded'
import { device } from '../../../templates/displays/devices'
import CenteredPageWrapper from '../../../templates/displays/pageWrappers/CenteredPageWrapper'
import { CarParams } from '../../insurance_quote/dashboards/insuranceApplicationCarDashboard'

const Padding = styled.div`
    padding-top: 24px;
    @media ${device.beyond_ipad_mobile} {
        padding-top: 90px;
        padding-left: 50px;
    }

    @media ${device.mobile_and_ipad} {
        padding: 24px;
        overflow-x: hidden;
    }

    margin-bottom: 100px;
`

const CarInsights = () => {
    const { carid } = useParams<CarParams>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    const timelineData = useAppSelector((state) => {
        return state.timeline.timeline_data_normalised_by_car_id
    })

    let carTimeline: ITimelineDataByCar | null | undefined =
        timelineData && carid && timelineData[carid]
            ? timelineData[carid]
            : undefined

    let current: ICar | undefined = carsData[carid]

    const dispatchThunk = useThunkAppDispatch()
    const dispatch = useAppDispatch()

    const [hasCosts, setHasCosts] = useState<boolean>(true)
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(true)

    useEffect(() => {
        // if (!current) {
        // for now always get on render ( we'll add the has_limited_garage_info check tho)
        dispatch(
            customNavDataActions.setInitialLandingUrl(`/car/${carid}/insights`)
        )
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            dispatchThunk(setCurrentCar(carid))
        }

        if (!current) {
            dispatch(getCarDataByIdRequest(carid))
        }

        // }
    }, [userLoggedIn])

    useEffect(() => {
        if (current?.car_entries_count === 0) {
            setIsLocalLoading(false)
            setHasCosts(false)
        } else if (carTimeline && carTimeline.entries) {
            const initialValue = 0

            const costsSum = carTimeline.entries.reduce(
                (accumulator: number, currentValue: ITimelineItem) =>
                    accumulator + (currentValue.total_cost_amount_raw ?? 0),
                initialValue
            )
            if (costsSum == 0) {
                setIsLocalLoading(false)
                setHasCosts(false)
            } else {
                setIsLocalLoading(false)
                setHasCosts(true)
            }
        } else if (current && (!carTimeline || !carTimeline?.entries)) {
            setIsLocalLoading(false)
            setHasCosts(false)
        }
    }, [carTimeline])

    let currURL = new URL(window.location.href)
    let params2 = new URLSearchParams(currURL.search)
    let categoryID: string | null = params2.get('categoryID')

    return (
        <React.Fragment>
            {isLocalLoading && <Loader isLoading={isLocalLoading} />}
            <CenteredPageWrapper
                removeHorizPaddings={true}
                removePaddings={true}
                hasMobileSubNav
            >
                <Faded>
                    <Padding>
                        <div style={{ position: 'relative' }}>
                            <CarInsightsTimeMenu hasCosts={hasCosts} />
                        </div>
                        <CarInsightsBody
                            hasCost={hasCosts}
                            activeCategory={categoryID}
                        />
                    </Padding>
                </Faded>
            </CenteredPageWrapper>
        </React.Fragment>
    )
}

export default CarInsights
