import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export type ICreateShareInviteApi_arg = {
    share_id: string
    recipient_email: string
    recipient_given_name?: string
    recipient_family_name?: string
}

export const createShareInvite = (
    p: ICreateShareInviteApi_arg
): Promise<any> => {
    let jsonBodyConv: string = JSON.stringify(p)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: jsonBodyConv,
    }

    return fetch(urls.create_share_invite(p.share_id), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Create share invite error',
                    jsonBodyConv
                )
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
