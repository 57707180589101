type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneySharingIconV2 = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.3615 3.31489C13.6321 3.19439 13.9482 3.24441 14.1684 3.44254L23.1211 11.5L14.1684 19.5575C13.9482 19.7556 13.6321 19.8056 13.3615 19.6851C13.091 19.5646 12.9167 19.2962 12.9167 19V15.5913C10.9794 15.634 9.40358 15.8539 7.89033 16.3757C6.18592 16.9634 4.51135 17.9514 2.46852 19.5857L1.25 20.5605V19C1.25 15.6432 3.56852 12.8795 6.13121 10.9433C8.42916 9.20705 11.0858 8.0103 12.9167 7.55806V4.00001C12.9167 3.70383 13.091 3.43539 13.3615 3.31489ZM14.4167 5.68404V8.80203L13.79 8.90648C12.2656 9.16054 9.45924 10.3088 7.03546 12.1401C5.13331 13.5773 3.59 15.3395 3.00398 17.3005C4.53052 16.2179 5.93832 15.4621 7.40134 14.9577C9.32244 14.2952 11.2891 14.0833 13.6667 14.0833H14.4167V17.316L20.8789 11.5L14.4167 5.68404Z"
                fill={color ?? '#336C74'}
            />
        </svg>
    )
}

export default JourneySharingIconV2
