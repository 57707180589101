import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
// import Loader from "../atoms/loader/loader";
import Faded from '../../templates/animated/faded'

import SectionHeader from '../../atoms/header/section/sectionHeader'

import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
// import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
import { IFAQPageCopy } from 'helpPageCopyModels'
import ExpandingItemsListDesktop from '../../molecules/expandingItemsList/expandingItemsListDesktop'
// import account_default from '../../../public/assets/navigation/account_default.svg'
import ExpandingItemsListMobile from '../../molecules/expandingItemsList/expandingItemsListMobile'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

// import ReturnNav from '../../atoms/header/returnNav'
// import styled from 'styled-components'
// import { device } from '../../templates/displays/devices'

// const AccountIcon = styled(Link)`
//     position: fixed;
//     top:0;
//     right: 10px;
//     padding-top: 12px;
//     height: 50px;
//     z-index: 5;
// `

// const IconItem = styled.img`
//     width: 26px;
//     height: 26px;
//     margin-right: 10px;
//     @media ${device.desktop} {
//         display: none;
//     }
// `

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        faqCopy: state.localdata.help_page_copy.faqPageCopy,
    }
}

type State = {}

interface Props {
    setInitialLandingUrl: (str: string) => void
    userLoggedIn: null | IUser
    getUserDataRequest: () => void
    userid: string | null
    faqCopy: IFAQPageCopy
}

class Faq extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        this.props.setInitialLandingUrl('/account/faq')
        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }
    }
    renderFAQsDesktop = this.props.faqCopy.faqSectionsIDs.map((item, index) => {
        let list = this.props.faqCopy.faqSectionsData[item].questionsIDs.map(
            (questionId: string) =>
                this.props.faqCopy.faqQuestionsData[questionId]
        )

        return (
            <React.Fragment key={`faq_${index}`}>
                <SectionHeader
                    title={this.props.faqCopy.faqSectionsData[item].name}
                    removeEdit={true}
                    key={this.props.faqCopy.faqSectionsData[item].id}
                />

                <ExpandingItemsListDesktop list={list} key={index} />
            </React.Fragment>
        )
    })

    renderFAQsMobile = this.props.faqCopy.faqSectionsIDs.map((item, index) => {
        let list = this.props.faqCopy.faqSectionsData[item].questionsIDs.map(
            (questionId: string) =>
                this.props.faqCopy.faqQuestionsData[questionId]
        )

        return (
            <React.Fragment key={`faq_${index}`}>
                <SectionHeader
                    customPadding={'20px'}
                    title={this.props.faqCopy.faqSectionsData[item].name}
                    removeEdit={true}
                    key={this.props.faqCopy.faqSectionsData[item].id}
                />
                <div
                    style={{
                        marginTop: '-20px',
                    }}
                >
                    <ExpandingItemsListMobile list={list} key={index} />
                </div>
            </React.Fragment>
        )
    })

    render() {
        const { userLoggedIn, faqCopy } = this.props

        return (
            <CenteredPageWrapper hasMobileSubNav>
                {userLoggedIn && (
                    <Faded>
                        <Loader />
                        <DesktopDisplayOnly>
                            <div
                                style={{
                                    paddingLeft: '50px',
                                    paddingRight: '50px',
                                }}
                            >
                                {faqCopy && this.renderFAQsDesktop}
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <div
                                style={{
                                    paddingLeft: '30px',
                                    paddingRight: '30px',
                                }}
                            >
                                {faqCopy && this.renderFAQsMobile}
                            </div>
                            <div style={{ height: '100px' }} />
                        </IpadAndMobileDisplay>
                    </Faded>
                )}
            </CenteredPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(Faq)
