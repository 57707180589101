import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

type IStyle = {
    $isLeftAligned?: boolean
    $textTransform?: TextTransform
}

type TextTransform = 'capitalize' | 'lowercase' | 'uppercase' | 'none'

export interface Props {
    children?: React.ReactNode
    leftAlign?: boolean
    textTransform?: TextTransform
}

const H1Styled = styled.h1<IStyle>`
    font-family: Lato-Semibold;
    color: var(--text-strong, #1a1a1a);
    text-transform: ${(props) =>
        props.$textTransform ? props.$textTransform : 'none'};
    font-size: 2rem;
    /* font-weight: 600; */
    line-height: 3rem;
    text-align: ${(props) => (props.$isLeftAligned ? 'left' : 'center')};
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media ${device.mobile} {
        font-size: 1.375rem;
        line-height: 2rem;
        margin: 1.75rem 0;
    }
`

const ShowroomH1: React.FC<Props> = (props) => {
    return (
        <H1Styled
            $isLeftAligned={props.leftAlign}
            $textTransform={props.textTransform}
        >
            {props.children}
        </H1Styled>
    )
}

export default ShowroomH1
