import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

// import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import SelectMultipleSearchBarCarInfoMobile from './selectMultipleSearchBarCarInfoMobile'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    padding-top: 20px;
    height: auto;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-strong, #1a1a1a);
    border-radius: 5px;
    position: relative;
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 18px;
    text-transform: capitalize;
`

const SmallPadding = styled.div`
    padding-top: 30px;
`

const LargePadding = styled.div`
    padding-top: 60px;
`
// const RegistrationNumberReminder = styled.div`
//     color: grey;
//     font-family: Lato;
//     font-size: 12px;
//     padding-top: 10px;
// `

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    multiple_values_array: any
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc?: any
    tech_info_id?: any
    optionsList: any
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        item,
        optionsList,
        setFieldValue,
        handleSubmit,
        // errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    return (
        <EditFormContainerMobile>
            <InnerFormPadding>
                <FieldTitle>{item.text}</FieldTitle>
                {/* <RegistrationNumberReminder>
                    or <ButtonAtom theme="naked-text">click here</ButtonAtom> to
                    enter the car’s registration number and get data
                    automatically added.
                </RegistrationNumberReminder> */}
                <SmallPadding />
                <SelectMultipleSearchBarCarInfoMobile
                    item={item}
                    items={optionsList}
                    handleSelectChange={(name: string) =>
                        setFieldValue('multiple_values_array', name)
                    }
                />
            </InnerFormPadding>
            <LargePadding />
            <ButtonsStandardBottomRowMobile
                cancel={toggle}
                submit={() => {
                    handleSubmit()
                }}
                confirmationtext="save"
            />
        </EditFormContainerMobile>
    )
}

const CarTechInfoEditFormMultipleSelectSearchMobile = withFormik<
    OtherProps,
    Values
>({
    mapPropsToValues: (props) => ({
        multiple_values_array: props.item.answer,
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (props.submitFunc) {
            let p: IwriteTechnicalInformationPayloadReq = {
                car_id: props.carid,
                tech_info_id: props.tech_info_id,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answers: values.multiple_values_array,
                },
            }

            props.submitFunc(p)
        }
        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarTechInfoEditFormMultipleSelectSearchMobile)
