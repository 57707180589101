import { removeUndefinedfromObject } from '../../helpers/objects'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export type I_archiveCar_api_req_args = {
    data: I_archiveCar_request_payload
    carID: string
}

type I_archiveCar_request_payload = {
    archived_reason: 'sold' | 'transfer' | 'custom'
    custom_reason?: string
    custom_note?: string
}

export const archiveCar = (p: I_archiveCar_api_req_args): Promise<any> => {
    let dataCleaned = removeUndefinedfromObject(p.data)
    let dataToUpdateFinal: string = JSON.stringify(dataCleaned)

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
        body: dataToUpdateFinal,
    }

    return fetch(urls.archive_car_endpoint(p.carID), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                return response.json().then((data: any) => {
                    if (data) {
                        apiSentryErrorHandler(
                            data,
                            'Archive car error',
                            dataToUpdateFinal
                        )
                        return Promise.reject(data)
                    } else {
                        return Promise.reject(response)
                    }
                })
            }

            if (response.status === 204) {
                return
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
