import { IGalleryImage, IGalleryImagesObject } from 'entityModels'
import * as React from 'react'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { generateOverviewImages } from '../generateOverviewImages'
import GalleryOverviewManagerMobile from './galleryOverviewManagerMobile'

function mapStateToProps(state: RootState) {
    return {}
}

export interface IImageProps {
    url: string
}

interface Props {
    isNecessaryGalleryDataLoading: boolean
    // given by car page
    overview_images_ids: string[]
    images_ids: string[]
    images_data: IGalleryImagesObject | null
    cover_id: string
    onClick?: any
    carid: string
    readOnlyMode?: boolean
}

type State = {}

class GalleryOverviewDataManagerMobile extends React.Component<Props, State> {
    componentDidMount() {}

    render() {
        const {
            images_data,
            images_ids,
            isNecessaryGalleryDataLoading,
            onClick,
            cover_id,
            overview_images_ids,
            carid,
            readOnlyMode,
        } = this.props

        let cover_img: IGalleryImage | undefined =
            images_data && images_data[cover_id]
                ? images_data[cover_id]
                : undefined
        let img_array: IGalleryImage[] | null =
            images_data &&
            generateOverviewImages(
                images_data,
                images_ids,
                overview_images_ids,
                cover_img
            )

        return (
            <React.Fragment>
                {isNecessaryGalleryDataLoading !== true && (
                    <GalleryOverviewManagerMobile
                        images_array={
                            img_array && img_array.length > 0 ? img_array : null
                        }
                        onClick={onClick && onClick}
                        carid={carid}
                        count={images_ids.length}
                        readOnlyMode={readOnlyMode}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, {})(GalleryOverviewDataManagerMobile)
