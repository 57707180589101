import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from '../../reducer'

import { api } from '../../../services'

import { insurance_application_existing_state_select } from '../maindriver/maindriver_additionaldetails_submit'
import { IInsuranceEverydayVehicleID } from '../../types'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from '../application/get'
import posthog from 'posthog-js'

export type IIsCarEverydayArg = {
    car_id: string
    answer: boolean
}

export function* SubmitCarEverydayBool(p: IIsCarEverydayArg): any {
    posthog.capture('Update insurance application everyday car')

    try {
        let application_has_deletions = false
        let application_has_patch = false

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        // let carData: IAnyObject = {
        //     external_id: p.car_id,
        //     // is_everyday: p.answer,
        // }

        let uidd = all_existing.vehicles[p.car_id]?.api_uid

        // if (uidd) {
        //     carData = {
        //         ...carData,
        //         uid: uidd,
        //     }
        // }

        // console.log(' l 46, ', uidd, carData)

        let data: IAnyObject = {
            // customer: {
            //     // check if external car or custodian car
            //     custodian_cars: [
            //         {
            //             ...carData,
            //         },
            //     ],
            // },
        }

        if (p.answer === true) {
            let item: IInsuranceEverydayVehicleID = {
                custodian_car_uid: uidd,
            }
            data = {
                ...data,
                application_everyday_vehicles: [{ ...item }],
            }
        } else if (p.answer === false) {
            // check if needs deleting
            // all_existing.vehicles[p.car_id]?.is_everyday
            if (all_existing.everyday_car_ids?.indexOf(p.car_id) !== -1) {
                let req_del: IDeleteInsuranceApplication_req = {
                    appli_id: `${all_existing.id}`,
                    entity_id: `${uidd}`,
                    entity_type: 'everyday_custodian_cars',
                }
                yield call(
                    api.insuranceApplication.deleteInsuranceApplicationEntity,
                    {
                        ...req_del,
                    }
                )
                application_has_deletions = true
            }
        }

        if (Object.keys(data).length > 0) {
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...data },
                }
            )
            application_has_patch = true

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_set_everyday_car_id_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_set_everyday_car_id_success())
        }

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: SubmitCarEverydayBool,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/car/${p.car_id}`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_set_everyday_car_id_error(customError)
            )
        }
    }
}

function* Watcher_Submit_car_everyday_bool() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_set_everyday_car_id_request
        )
        yield call(SubmitCarEverydayBool, payload)
    }
}

const car_everyday_bool_submit: any[] = [fork(Watcher_Submit_car_everyday_bool)]

export default car_everyday_bool_submit
