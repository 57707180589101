import { IDropdownItem } from 'entityModels'
import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import styled from 'styled-components'
import { ITimelineEntryTypes } from 'timelineModels'
import FilterByMultipleDesktop from '../../organisms/filterByMultiple/timeline/filterByMultipleDesktop'
import FilterTimelineByMultipleWSearchWFuse from '../../organisms/filterByMultiple/timeline/filterTimelineByMultipleWSearchWFuse'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import { AddIcon } from '../../atoms/statefulicons'
import TimelineSearchBarFuseWrapperDesktop from '../../organisms/searchbar/timeline/timelineSearchBarFuseWrapperDesktop'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

interface IStyled {
    imgsrc?: string
    isAnimated?: boolean
}

const Wrapper = styled.div<IStyled>`
    width: 100%;
    background-color: var(--bg-color, #fff);
    max-width: 1498px;
    padding-left: 48px;
    padding-right: 48px;
`

const Container = styled.div<IStyled>`
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    padding-bottom: 32px;
`

const FiltersContainer = styled.div`
    display: flex;
    gap: 16px;
`

const FiltersWithoutSearch = styled.div`
    position: relative;
    z-index: 7;
`
const FiltersWithSearch = styled.div`
    position: relative;
    z-index: 6;
`

const AnimatedWrapper = styled.div<IStyled>`
    @keyframes scale {
        0% {
            transform: scale(1);
        }

        25% {
            transform: scale(1.1);
        }

        50% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.1);
        }
    }

    ${(props) => props.isAnimated && 'animation: 0.4s scale linear'};
    animation-delay: 0.5s;
`

interface Props extends RouteComponentProps<any> {
    imgsrc?: any
    dataCyId?: string
    categories?: IDropdownItem[] | null | undefined
    tags?: IDropdownItem[] | null | undefined
    onAddNEwEntry?: any
    selectedFilteringLabels: string[]
    selectedFilteringCategories: ITimelineEntryTypes[]
    filterByCategories: any
    filterByLabels: any
    activeEntryTitleQuery: string | null | undefined
    sendFiltersToApi: any
    readOnlyMode?: boolean
}

const CarTimelineHeaderDesktop: React.FC<Props> = (props) => {
    const {
        tags,
        categories,
        onAddNEwEntry,
        selectedFilteringCategories,
        selectedFilteringLabels,
        filterByCategories,
        filterByLabels,
        activeEntryTitleQuery,
        sendFiltersToApi,
        readOnlyMode,
    } = props

    let params = new URLSearchParams(props.location.search)
    let section_id: string | null = params.get('section_id')

    const { theme } = useThemes()

    return (
        <Wrapper>
            {!readOnlyMode && (
                <Container>
                    <div style={{ width: 400 }}>
                        <TimelineSearchBarFuseWrapperDesktop
                            categoriesToSearch={categories ?? []}
                            labelsToSearch={tags ?? []}
                            activeFilters={{
                                categories: selectedFilteringCategories,
                                labels: selectedFilteringLabels,
                                titleQuery: activeEntryTitleQuery ?? undefined,
                            }}
                            apiSearchBySelected={sendFiltersToApi}
                        />
                    </div>

                    <FiltersContainer>
                        <FiltersWithoutSearch>
                            <FilterByMultipleDesktop
                                // dataCyId={dataCyId}
                                categoryData={categories && categories}
                                selectedFilteringCategories={
                                    selectedFilteringCategories
                                }
                                filterBySelected={filterByCategories}
                                dataCyId="load_filter_hf_by_categories_desktop"
                                dataCyIdSave="filter_hf_by_categories_desktop"
                                isTimeline
                            />
                        </FiltersWithoutSearch>
                        <FiltersWithSearch>
                            <FilterTimelineByMultipleWSearchWFuse
                                placeholder="Search for title or tag"
                                user_searchable_labels_list={tags ? tags : []}
                                selectedFilteringLabels={
                                    selectedFilteringLabels
                                }
                                onSave={filterByLabels}
                                dataCyId="load_filter_hf_by_labels_desktop"
                                dataCyIdSave="filter_hf_by_labels_desktop"
                            />
                        </FiltersWithSearch>
                        <AnimatedWrapper
                            isAnimated={section_id === 'create' ? true : false}
                        >
                            <StandardCtaBtn
                                onClick={onAddNEwEntry}
                                customWidth="48px"
                                customHeight="48px"
                                removePaddings
                            >
                                <AddIcon
                                    onClick={() => {}}
                                    colour={colours[theme].background_default}
                                />
                            </StandardCtaBtn>
                        </AnimatedWrapper>
                    </FiltersContainer>
                </Container>
            )}
        </Wrapper>
    )
}

export default withRouter(CarTimelineHeaderDesktop)
