import { SELECT_CONTACT_REASON } from './constants'
import { createAction } from '@reduxjs/toolkit'

export const selectContactReason = createAction(
    SELECT_CONTACT_REASON,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)