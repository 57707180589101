import {
    GET_HIGHLIGHTED_FACTS_REQUEST,
    EDIT_HIGHLIGHTED_FACTS_REQUEST,
    SET_HIGHLIGHTED_FACTS_REQUEST,
} from './constants'

import { IUpdateHF } from 'entityModels'

import { createAction } from '@reduxjs/toolkit'

export const getHighlightedFactsRequest = createAction(
    GET_HIGHLIGHTED_FACTS_REQUEST
)

export const setHighlightedFactsRequest = createAction(
    SET_HIGHLIGHTED_FACTS_REQUEST
)

// export const updatetHighlightedFactsRequest = createAction(
//     EDIT_HIGHLIGHTED_FACTS_REQUEST,
//     (resolve) => (payload: IUpdateHF) => resolve(payload)
// )

export const updatetHighlightedFactsRequest = createAction(
    EDIT_HIGHLIGHTED_FACTS_REQUEST,
    function prepare(payload: IUpdateHF) {
        return {
            payload,
        }
    }
)
