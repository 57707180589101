import React from 'react'
import styled from 'styled-components'
// import { device } from '../../../templates/displays/devices'
// import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
// import Faded from '../../../templates/animated/faded'
// import chevron_submenu_mobile from '../../../../public/assets/navigation/chevron_submenu_mobile.svg'
import { motion } from 'framer-motion'
// import { connect } from 'react-redux'
// import { RootState } from 'typesafe-actions'
// import { BottomSheet } from 'react-spring-bottom-sheet'
import Sheet from 'react-modal-sheet'
// import { toggleImageMenu } from '../../../../redux/menus/reducer'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
// import close_cross_grey from '../../../../public/assets/icons/close_cross_grey.svg'

import ScrollLock from 'react-scrolllock'
import Icon from '../../icons'
import { device } from '../../../templates/displays/devices'

const CustomSheet = styled(Sheet)<{ $customHeight?: string }>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '230px !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
    }
    .react-modal-sheet-header {
        height: 10px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`
const MenuItem = styled.div`
    color: var(--text-strong, #1a1a1a) !important;
    height: 50px;
    width: 100vw;
    opacity: 1 !important;
    font-family: 'Lato';
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: row;
    letter-spacing: 0.09rem;
`

const MenuItemBox = styled(motion.div)<IStyle>`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    flex-direction: row;
    color: var(--primary, #5ec3ca) !important;

    :focus {
        outline: 0;
    }
`

interface IStyle {
    is_last: boolean
}

type IactionsItems = {
    id: string
    action?: any
    text?: string
}

interface IProps {
    actions_items: IactionsItems[]
    toggle: any
    isCarSharesMenuOpen: boolean
    entity: any
    customHeight?: string
}

const CarSharesActionsMobile: React.FunctionComponent<IProps> = (props) => {
    const { actions_items, toggle, isCarSharesMenuOpen, customHeight, entity } =
        props

    const isInvite =
        entity && entity.recipient_type && entity.recipient_type === 'external'
    const isPlatformRecipient =
        entity && entity.recipient_type && entity.recipient_type === 'platform'

    const disableMenuItem = (item_id: string): boolean => {
        if (item_id === 'email' && isPlatformRecipient) {
            return true
        } else return false
    }

    return (
        <IpadAndMobileDisplay>
            {isCarSharesMenuOpen && <ScrollLock />}
            <CustomSheet
                isOpen={isCarSharesMenuOpen}
                onClose={toggle}
                $customHeight={customHeight}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <div
                        style={{
                            backgroundColor: 'var(--off-bg-color, #fafafa)',
                            paddingTop: '0px',
                        }}
                        data-attr="gallery-image-menu"
                    >
                        {actions_items !== undefined &&
                            actions_items.map(
                                (item: IactionsItems, index: any) => (
                                    <React.Fragment key={`${index}_mobile`}>
                                        <MenuItemBox
                                            is_last={
                                                index ===
                                                actions_items.length - 1
                                            }
                                            onClick={(e: any) => {
                                                if (!disableMenuItem(item.id)) {
                                                    e.preventDefault()
                                                    if (item.id === 'delete') {
                                                        item.action && isInvite
                                                            ? item.action({
                                                                  recipient_id:
                                                                      entity.id,
                                                                  recipient_type:
                                                                      'external',
                                                              })
                                                            : isPlatformRecipient
                                                            ? item.action({
                                                                  recipient_id:
                                                                      entity.id,
                                                                  recipient_type:
                                                                      'platform',
                                                              })
                                                            : item.action(
                                                                  entity.id
                                                              )
                                                    } else if (
                                                        item.id === 'email'
                                                    ) {
                                                        item.action && isInvite
                                                            ? item.action({
                                                                  recipient_id:
                                                                      entity.id,
                                                                  recipient_email:
                                                                      entity.email,
                                                              })
                                                            : item.action(
                                                                  entity.id
                                                              )
                                                    } else {
                                                        item.action &&
                                                            item.action()
                                                    }

                                                    toggle()
                                                }
                                            }}
                                            key={index + 10}
                                            whileTap={{
                                                opacity: 1,
                                                backgroundColor:
                                                    'var(--border-muted, #e5e5e5)',
                                            }}
                                        >
                                            <MenuItem>
                                                <div
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        alignItems: 'center',
                                                        transform:
                                                            item.id ===
                                                            'add_person'
                                                                ? 'translateX(3px)'
                                                                : 'none',
                                                        opacity: `${
                                                            disableMenuItem(
                                                                item.id
                                                            )
                                                                ? '40%'
                                                                : '1'
                                                        }`,
                                                    }}
                                                >
                                                    <Icon
                                                        icon={
                                                            item.id === 'copy'
                                                                ? 'copy_clipboard'
                                                                : item.id ===
                                                                  'email'
                                                                ? 'email_icon'
                                                                : item.id ===
                                                                  'settings'
                                                                ? 'blue_cog'
                                                                : item.id ===
                                                                  'delete'
                                                                ? 'light_red_cross'
                                                                : item.id ===
                                                                  'add_person'
                                                                ? 'add_user_blue'
                                                                : ''
                                                        }
                                                        height={
                                                            item.id === 'copy'
                                                                ? '21px'
                                                                : item.id ===
                                                                  'email'
                                                                ? '17px'
                                                                : item.id ===
                                                                  'settings'
                                                                ? '18'
                                                                : item.id ===
                                                                  'delete'
                                                                ? '12px'
                                                                : ''
                                                        }
                                                        width={
                                                            item.id === 'copy'
                                                                ? '18px'
                                                                : item.id ===
                                                                  'email'
                                                                ? '14px'
                                                                : item.id ===
                                                                  'settings'
                                                                ? 'auto'
                                                                : item.id ===
                                                                  'delete'
                                                                ? '12px'
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                                <span
                                                    style={{
                                                        paddingRight: '15px',
                                                    }}
                                                />

                                                <span
                                                    style={{
                                                        color:
                                                            item.id === 'delete'
                                                                ? 'var(--error, #df6f6f)'
                                                                : disableMenuItem(
                                                                      item.id
                                                                  )
                                                                ? 'var(--text-default, #666666)'
                                                                : 'var(--primary, #5ec3ca)',
                                                    }}
                                                >
                                                    {item.text}
                                                </span>
                                            </MenuItem>
                                        </MenuItemBox>
                                    </React.Fragment>
                                )
                            )}
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop
                    data-attr="car-shares-menu-backdrop-overlay"
                    onTap={toggle}
                />
            </CustomSheet>
        </IpadAndMobileDisplay>
    )
}

export default CarSharesActionsMobile
