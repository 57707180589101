import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const GarageIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.75 22.75L1.25 22.75L1.25 10.666L12 0.990977L22.75 10.666L22.75 22.75L14.25 22.75L14.25 16.75L9.75 16.75L9.75 22.75ZM8.25 16L8.25 15.25L15.75 15.25L15.75 21.25L21.25 21.25L21.25 11.334L12 3.00902L2.75 11.334L2.75 21.25L8.25 21.25L8.25 16Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default GarageIcon
