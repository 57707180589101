import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import React from 'react'
import Faded from '../templates/animated/faded'
import { UserCategory } from 'myInterfaces'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import Loader from '../atoms/loader/loader'
import { resetErrorStateSuccess } from '../../redux/user/actions/errorActions'
import { IUser } from 'myModels'
import { getCurrentUserDataRequest } from '../../redux/user/actions/loadingActions'
import {
    generateFBC,
    generateFBP,
    ICookieSetArg,
    setCookie,
} from '../../helpers/cookies'
import { GetUserIPAndCallFbTracking } from '../../helpers/ipAddress'
import { acceptCarShareInviteRequest } from '../../redux/entities/cars/actions/loadingActions'
import { acceptGarageShareInviteRequest } from '../../redux/entities/garages/actions/loadingActions'
import { message } from 'antd'
import RegistrationNewLanding from '../organisms/registerfullpagescroll/registrationNewLanding'

const info = (messageText: string) => {
    message.info(messageText, 5)
}

const dispatchProps = {
    resetErrorState: resetErrorStateSuccess,
    getUserDataRequest: () => getCurrentUserDataRequest(),
    acceptCarShareInviteRequest: (payload: {
        invite_code: string
        next_path: string
    }) => acceptCarShareInviteRequest(payload),
    acceptGarageShareInviteRequest: (payload: {
        invite_code: string
        next_path: string
    }) => acceptGarageShareInviteRequest(payload),
}

function mapStateToProps(state: RootState) {
    return {
        usercategorieslist: state.localdata.usercategories,
        isLoading: state.user.loading,
        userLoggedIn: state.user.userLoggedIn,
        hasLoggedOut: state.user.hasLoggedOut,
    }
}

interface Props extends RouteComponentProps<any> {
    usercategorieslist: UserCategory[]
    isLoading: boolean
    resetErrorState: () => void
    userLoggedIn: null | IUser
    hasLoggedOut?: boolean
    getUserDataRequest: () => void
    acceptCarShareInviteRequest: (payload: {
        invite_code: string
        next_path: string
    }) => void
    acceptGarageShareInviteRequest: (payload: {
        invite_code: string
        next_path: string
    }) => void
}

type State = {
    currentPage: null | number
    modalOpened: boolean
    category: null | UserCategory
    redirect: boolean
    hadInitialToken: boolean | null
}

class RegisterNewLandingPage extends React.Component<Props, State> {
    state = {
        currentPage: null,
        modalOpened: false,
        category: null,
        redirect: false,
        hadInitialToken: null,
    }

    sortOutFbTracking = (ip?: string | undefined) => {
        if (
            process.env.REACT_APP_ENV !== 'local' &&
            process.env.REACT_APP_ENV !== 'development' &&
            process.env.REACT_APP_ENV !== 'preview'
        ) {
            const params = new URLSearchParams(this.props.location.search)
            const fbclid: string | null = params.get('fbclid')

            const timeNow = Date.now()
            let event_id = `view_item_${timeNow}`

            const ua =
                !!window && window.navigator && window.navigator.userAgent
                    ? window.navigator.userAgent
                    : undefined

            if (window && window.dataLayer === undefined) {
                window.dataLayer = window.dataLayer || []
            }

            if (fbclid) {
                const fbclidCookieArg: ICookieSetArg = {
                    name: 'fbclid',
                    value: fbclid,
                    days: 180,
                }
                setCookie(fbclidCookieArg)

                const FBC = generateFBC(fbclid, timeNow)
                setCookie({
                    name: '_fbc',
                    value: FBC,
                    days: 180,
                })

                const FBP = generateFBP(timeNow)
                setCookie({
                    name: '_fbp',
                    value: FBP,
                    days: 180,
                })

                if (ip) {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            fbclid: fbclid,
                            ip_override: ip,
                            event_id: event_id,
                            user_agent: ua,
                        })
                } else {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            fbclid: fbclid,
                            event_id: event_id,
                            user_agent: ua,
                        })
                }
            } else if (
                process.env.REACT_APP_ENV !== 'local' &&
                process.env.REACT_APP_ENV !== 'development' &&
                process.env.REACT_APP_ENV !== 'preview'
            ) {
                if (ip) {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            ip_override: ip,
                            event_id: event_id,
                            user_agent: ua,
                        })
                } else {
                    window &&
                        window.dataLayer &&
                        window.dataLayer.push({
                            event: 'view_item',
                            first_party_collection: true,
                            event_id: event_id,
                            user_agent: ua,
                        })
                }
            }
        }
    }

    componentDidMount() {
        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (refresh_token) {
            this.setState({
                ...this.state,
                hadInitialToken: true,
            })
        }
        if (!refresh_token) {
            this.setState({
                ...this.state,
                hadInitialToken: false,
            })
        }

        this.props.resetErrorState()

        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        if (this.props.userLoggedIn === null) {
            this.setState({ redirect: false })
        } else if (
            this.props.userLoggedIn &&
            this.props.userLoggedIn !== null &&
            !this.props.hasLoggedOut
        ) {
            this.setState({ redirect: false })
        }

        GetUserIPAndCallFbTracking(this.sortOutFbTracking)
            .then((data: any) => {})
            .catch((err: any) => {})
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn === null) {
                this.setState({ redirect: false })
            } else if (
                this.props.userLoggedIn &&
                this.props.userLoggedIn !== null &&
                !this.props.hasLoggedOut
            ) {
                // if access is from an invite email and
                const q_params = new URLSearchParams(this.props.location.search)
                let invite_code = q_params.get('invite_code')
                const next_path = q_params.get('next')
                const utm_source = q_params.get('utm_source')
                if (invite_code && next_path && utm_source === 'share_invite') {
                    next_path.includes('garage')
                        ? this.props.acceptGarageShareInviteRequest({
                              invite_code: invite_code,
                              next_path: next_path,
                          })
                        : this.props.acceptCarShareInviteRequest({
                              invite_code: invite_code,
                              next_path: next_path,
                          })
                } else {
                    if (
                        invite_code &&
                        next_path &&
                        utm_source === 'handover_invite'
                    ) {
                        info(
                            `You have accepted a handover invite. You new car will show up in your garage shortly.`
                        )
                    }
                    this.setState({ redirect: true })
                }
            }
        }
    }

    openModal = () => {
        this.setState({ modalOpened: true })
    }

    closeModal = () => {
        this.setState({ category: null, modalOpened: false })
    }

    categoryClick = (category: UserCategory) => {
        this.setState({ category: category })
        this.openModal()
    }

    checkURL = (par?: string | null) => {
        let parr2 = par ? decodeURIComponent(par) : undefined
        if (parr2 === '/insurance') {
            return '/insurance'
        } else return '/garage'
    }

    render() {
        const params = new URLSearchParams(this.props.location.search)
        const invite_code = params.get('invite_code')
        const redirectq = params.get('next')
        const checkURLFInal = this.checkURL(redirectq)

        return (
            <Faded>
                {this.state.redirect === true &&
                    this.state.hadInitialToken === true && (
                        <Redirect to={checkURLFInal} />
                    )}
                <Loader />
                <RegistrationNewLanding invite_code={invite_code} />
            </Faded>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(RegisterNewLandingPage)
)
