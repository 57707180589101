import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { device } from '../../../templates/displays/devices'
import Tour from 'reactour'
import './styles.css'
import grey_tip from '../../../../public/assets/icons/grey_tip.svg'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Scrolllock from 'react-scrolllock'
import TutorialWrapperDesktop from '../tutorialWrappers/TutorialWrapperDesktop'

// const TourStyled = styled(Tour)`
const TourStyled = styled((props: any) => <Tour {...props} />)`
    @media ${device.mobile} {
        display: none;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 500px !important;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
`

const Title = styled.h1`
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 24px;

    @media ${device.mobile} {
    }
`

const Text = styled.p`
    color: var(--text-strong, #1a1a1a);
    align-self: start;
    justify-self: start;
    font-size: 17px;
    line-height: 1.9;

    @media ${device.mobile} {
    }
`

const Padding = styled.div`
    padding-top: 25px;

    @media ${device.mobile} {
    }
`
const TextPadding = styled.div`
    padding-top: 8px;

    @media ${device.mobile} {
    }
`

const Tip = styled.img`
    width: 20px;
    @media ${device.mobile} {
    }
`

const TipText = styled.div`
    font-family: Lato-Light;
    padding-left: 20px;
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile} {
    }
`

const TipRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
`

const tutorialConfigs_Garage = (close: any) => [
    {
        selector: '[data-tut="first-step-garage-desktop"]',
        position: [200, 150],
        content: () => (
            <TutorialWrapperDesktop next={close} nextBtnText="close">
                <Title>Welcome to your Virtual Garage</Title>
                <Padding />

                <Text>
                    Whether you’ve got one car or an entire collection, you’ll
                    be able to see them here.
                </Text>

                <TextPadding />

                <Text>
                    You can add your profile photo, contact details and
                    automotive bio on your Profile page in the account menu.
                </Text>

                <TipRow>
                    <Tip src={grey_tip} alt="tip" />
                    <TipText>
                        You can change your display name from the profile page.
                    </TipText>
                </TipRow>
            </TutorialWrapperDesktop>
        ),
    },
]

interface Props extends RouteComponentProps<any> {
    registerGarageTutorial: any
}

const TutorialConfigsGarageDesktop: React.FunctionComponent<Props> = (
    props
) => {
    const [isTourOpen, setIsTourOpen] = useState(true)

    const setIsTourOpenCustom = () => {
        setIsTourOpen(false)
        props.history.push(`/garage`)
    }

    useEffect(() => {
        props.registerGarageTutorial()
    }, [])

    return (
        <>
            {window.innerWidth > 880 && (
                <>
                    <TourStyled
                        steps={tutorialConfigs_Garage(setIsTourOpenCustom)}
                        isOpen={isTourOpen}
                        onRequestClose={() => undefined}
                        rounded={5}
                        accentColor="#5ec3ca"
                        disableInteraction={true}
                        showCloseButton={false}
                        showNavigation={false}
                        showNumber={false}
                    />

                    <Scrolllock />
                </>
            )}
        </>
    )
}

export default withRouter(TutorialConfigsGarageDesktop)
