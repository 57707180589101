import * as React from 'react'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import TrioGridFullpage from '../../molecules/imageGrid/trioGridFullpage'
import Loader from '../../atoms/loader/loader'
import { IGalleryImagesObject } from 'entityModels'

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

export interface IImageProps {
    url: string
}

interface Props {
    imagesData: IGalleryImagesObject | null
    isNecessaryGalleryDataLoading: boolean
    ids_list: string[] | null | undefined
    openImgForm: () => void
    readOnlyMode?: boolean
}

type State = {
    isEmpty: boolean | null
}

class CarGalleryManagerDesktop extends React.Component<Props, State> {
    state = {
        isEmpty: null,
    }

    componentDidMount() {}

    render() {
        const {
            imagesData,
            ids_list,
            isNecessaryGalleryDataLoading,
            openImgForm,
            readOnlyMode,
        } = this.props

        return (
            <React.Fragment>
                {isNecessaryGalleryDataLoading !== true && imagesData && (
                    <TrioGridFullpage
                        images_data_object={imagesData}
                        ids_list={ids_list}
                        openImgForm={openImgForm}
                        readOnlyMode={readOnlyMode}
                    />
                )}

                {isNecessaryGalleryDataLoading === true && (
                    <Loader isLoading={isNecessaryGalleryDataLoading} />
                )}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(CarGalleryManagerDesktop)
