import { useState } from 'react'
import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { IThreeDotsActionsItems } from '../../../../atoms/menu/actionsMenu/actionsMenuDots'
import { IEditShowroomModalOptions } from '../../../../pages/showroom/car/showroomCarProfileEdit'
import ShowroomOptionsBottomSheetActionsMobile from '../../../../atoms/showroom/showroomOptionsBottomSheetActionsMobile'
import ShowroomPublishChecksBottomSheetMobile from '../../../../atoms/showroom/showroomPublichChecksBottomSheet'
import { useHistory } from 'react-router'
import { ICar } from 'entityModels'
import { IPublishValidationObject } from './carShowroomEditBottomBar'
import ChevronLeft from '../../../../atoms/icons/components/chevronLeft'

type Props = {
    isPublished: boolean
    handleSetModalOpened: (p: IEditShowroomModalOptions) => any
    menu_items: IThreeDotsActionsItems[]
    publishValidationObject: IPublishValidationObject
    currentCar: ICar | undefined
    currentCarId: string | undefined
    isReadyToPublish: boolean
    isValidationSheetOpened: boolean
    setIsValidationSheetOpened: (p: boolean) => any
    isMobileRemoved?: boolean
    isSold?: boolean
}

// container for action button
const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`

const GoBackButton = styled.button`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;

    background: var(--bg-color, #fff);
    color: var(--text-default, #666666);

    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 4px;
`
type StyleProps = {
    $role?: string
    $isMobile?: boolean
}
const WrapperMobile = styled.div<StyleProps>`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
        props.$role === 'admin' ? 'center' : 'space-between'};
    align-items: center;
    height: 5rem;
    background-color: var(--bg-color, #fff);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;
    padding: 0.75rem 1.5rem;

    @media (min-width: 800px) {
        display: none;
    }
`

const ShowroomCarEditBottomBarMobile = (props: Props) => {
    let {
        menu_items,
        publishValidationObject,
        currentCar,
        currentCarId,
        isReadyToPublish,
        isValidationSheetOpened,
        setIsValidationSheetOpened,
        isMobileRemoved,
        isPublished,
        isSold,
    } = props

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

    const history = useHistory()

    return (
        <WrapperMobile>
            <GoBackButton
                onClick={() => {
                    history.goBack()
                }}
            >
                <ChevronLeft />
            </GoBackButton>

            <Actions>
                <ButtonAtom
                    theme="capitalize-white-background"
                    width="100px"
                    height="48px"
                    dataCyId={'showroom-car-share'}
                    icon={''}
                    fontSize={16}
                    onClick={() => {
                        setIsValidationSheetOpened(false)
                        setIsMenuOpen(!isMenuOpen)
                    }}
                >
                    <div
                        style={{
                            fontWeight: isMenuOpen ? 800 : 'auto',
                            color: isMenuOpen
                                ? 'var(--primary, #5ec3ca)'
                                : 'var(--primary_stronger, #157076)',
                            fontFamily: isMenuOpen ? 'Lato-Bold' : 'Lato',
                        }}
                    >
                        Options
                    </div>
                </ButtonAtom>

                <ShowroomOptionsBottomSheetActionsMobile
                    isOpen={isMenuOpen}
                    menu_items={menu_items}
                    toggle={() => setIsMenuOpen(!isMenuOpen)}
                />

                {!isSold && isValidationSheetOpened && currentCar && (
                    <ShowroomPublishChecksBottomSheetMobile
                        isOpen={
                            isMobileRemoved === true
                                ? false
                                : isValidationSheetOpened
                        }
                        publishChecksObject={publishValidationObject}
                        currentCar={currentCar}
                        currentCarId={currentCarId}
                        toggle={() => setIsValidationSheetOpened(false)}
                    />
                )}

                <ButtonAtom
                    theme="secondary"
                    width={isPublished ? '130px' : '100px'}
                    height="48px"
                    textTransform="none"
                    dataCyId={'showroom-car-enquire'}
                    onClick={() => {
                        // redux action to publish to showroom here later

                        if (!isReadyToPublish) {
                            setIsValidationSheetOpened(true)
                        } else if (!isPublished) {
                            props.handleSetModalOpened('publication_confirmed')
                        } else if (isPublished) {
                            props.handleSetModalOpened('mark_as_sold_confirmed')
                        }
                    }}
                    fontSize={16}
                    customBgColor={
                        isSold
                            ? '#a9a9a9'
                            : isReadyToPublish === false && !isPublished
                            ? 'var(--warning, #f9bb4b)'
                            : 'var(--primary, #5ec3ca)'
                    }
                >
                    {isSold ? 'Sold' : isPublished ? 'Mark as sold' : 'Publish'}
                </ButtonAtom>
            </Actions>
        </WrapperMobile>
    )
}

export default ShowroomCarEditBottomBarMobile
