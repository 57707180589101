import { IUser } from 'myModels'
import { ISearchShowroom_API_car_item } from '../../../../redux/services/showroom/types'
import ShowroomCardDesktop from '../../../molecules/showroom/card/showroomCardDesktop'

type Props = {
    entriesList: ISearchShowroom_API_car_item[]
    userLoggedIn?: IUser | null
    handleChangeSearchEffectsReset: () => any
    anchor_entry_params?: string | undefined | null
    cardAnchoredRefDesktop?: any
    anchor_id_redux?: string | undefined
}

const ShowroomCardsContainerDesktop = (props: Props) => {
    let {
        entriesList,
        userLoggedIn,
        handleChangeSearchEffectsReset,
        anchor_entry_params,
        anchor_id_redux,
        cardAnchoredRefDesktop,
    } = props

    return (
        <>
            {entriesList.map((entry: ISearchShowroom_API_car_item) => {
                return (
                    <ShowroomCardDesktop
                        entry={entry}
                        user={userLoggedIn ?? undefined}
                        reset={handleChangeSearchEffectsReset}
                        refCreated={
                            anchor_entry_params === entry.uid
                                ? cardAnchoredRefDesktop
                                : anchor_id_redux === entry.uid
                                ? cardAnchoredRefDesktop
                                : undefined
                        }
                    />
                )
            })}
        </>
    )
}

export default ShowroomCardsContainerDesktop
