import { IExternalCar, IExternalCarsObject } from 'entityModels'
import { IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { getExternalCarsSharedWithYouREQUEST } from '../../../../redux/entities/cars/actions/loadingActions'
import { setCurrentExternalCar } from '../../../../redux/entities/cars/actions/thunks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import FabsMenusMobile from '../../../templates/menus/fabsMenuMobile'
import ReadOnlyCarTechnicalInformation from '../../../templates/readOnly/car/readonlyTechnicalInformation'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    externalCarsData: IExternalCarsObject | undefined
    setCurrentExternalCar: (carid: string) => void
    setInitialLandingUrl: any
    getUserDataRequest: () => void
    getExternalCarsSharedWithYouREQUEST: (car_id: string) => void
}

const dispatchProps = {
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    setCurrentExternalCar: (id: string) => setCurrentExternalCar(id),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getExternalCarsSharedWithYouREQUEST: (car_id: string) =>
        getExternalCarsSharedWithYouREQUEST(car_id),
}

type State = {
    placeholderUrl: string
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
    }
}

class ExternalCarTechnicalInformation extends React.Component<Props, State> {
    componentDidMount() {
        let carid = this.props.match.params.carid
        this.props.setCurrentExternalCar(carid)
        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }
        this.props.setInitialLandingUrl(
            `/shared/car/${this.props.match.params.carid}/technical-information`
        )
        this.props.getExternalCarsSharedWithYouREQUEST(carid)
    }
    render() {
        let { userLoggedIn } = this.props
        let carid = this.props.match.params.carid
        let current: IExternalCar | undefined =
            this.props.externalCarsData && this.props.externalCarsData[carid]

        return (
            <React.Fragment>
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
                {userLoggedIn && (
                    <ReadOnlyCarTechnicalInformation
                        sharedCar={current}
                        userLoggedIn={userLoggedIn}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ExternalCarTechnicalInformation)
)
