import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { AbsoluteGradient } from '../../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../../providers/theme/hooks'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import Faded from '../../../../templates/animated/faded'
import EnterIcon from '../../../../atoms/icons/components/enterIcon'
import { device } from '../../../../templates/displays/devices'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import JourneyPageWrapperMobile from '../../../../templates/wrappers/journeyPageWrapperMobile'
import JourneyWrapperLeftSideDesktop from '../../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import { UserProfileJourneyUserCardMobile } from '../card/cardMobile'
import { UserProfileJourneyUserCardDesktop } from '../card/cardDesktop'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { IGooglePlacesAddress } from '../../../../molecules/showroom/ownerForm/basicDetails/ownerBasicDetailsManagerDesktop'
import { IGooglePlacesAddress_string } from '../../../../molecules/showroom/locationForm/carLocationDataManagerMobile'
import Expander from '../../../../atoms/expander/expander'
import { ErrorText } from '../../../editForms/account/accountAddressForm/accountAddressformDesktop'
import { getRightSchemaAccountData } from '../../../editForms/account/validationSchema'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import { IDropdownItem } from 'entityModels'
import { IUserAddressApi } from 'IapiDataPayload'
import SelectSearchBarCarInfoDesktop from '../../../editForms/technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import useWindowSize from '../../../../templates/displays/windowSizeHook'
import { addAddressRequest } from '../../../../../redux/user/actions/loadingActions'
import { InfoBoxAtom } from '../../../../atoms/box/infoBox'
import { IInsuranceDropdownItem } from '../../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import { Select } from '../../../../atoms/select'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`
const Line = styled.div`
    background-color: var(--border-muted, #e5e5e5);
    height: 1px;
    width: 100%;
`

type Props2 = {
    formData: IGooglePlacesAddress
    handleChange: (value: string, id: IGooglePlacesAddress_string) => any
    error:
        | {
              [key: string]: string | undefined
          }
        | undefined
    isMobile?: boolean
}

const SearchField = styled.input`
    background-color: #fbfbfb;
    height: 50px;
    min-height: 50px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    padding-left: 15px;
    width: 100%;
    font-family: Lato;
`

const RenderForms = (props2: Props2) => {
    let { formData, handleChange, error } = props2

    const addressError = useAppSelector((state) => state.user.error)

    const showAdminAreaInput =
        formData.country === 'United States' ||
        (formData.administrative_area_level_2 &&
            formData.country === 'Spain') ||
        (addressError?.custom &&
            addressError.custom.custom_message === 'Invalid address' &&
            addressError.default.Message &&
            (addressError.default.Message.includes('AdministrativeArea') ||
                addressError.default.Message.includes('administrative area')))
            ? true
            : false

    const showAdminAreaError =
        addressError?.custom &&
        addressError.custom.custom_message === 'Invalid address' &&
        addressError.default.Message &&
        (addressError.default.Message.includes('AdministrativeArea') ||
            addressError.default.Message.includes('administrative area'))
            ? true
            : false

    const showPostCodeError =
        (addressError?.custom &&
            addressError.custom.custom_message === 'Invalid address' &&
            addressError.default.Message &&
            addressError.default.Message.includes('post code')) ||
        (error && error['postal_code'])
            ? true
            : false

    let countriesOptions: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    let usaAdminCodes: IInsuranceDropdownItem[] | null = useAppSelector(
        (state) => {
            return state.localdata.dropdownData.usaStatesCodes
        }
    )

    const [isAdmin1Open, setIsAdmin1Open] = useState<boolean>(false)

    return (
        <>
            {props2.isMobile ? null : (
                <InfoBoxAtom>
                    Your address is completely private and always will be unless
                    you choose to share it explicitly. We use your address to
                    show you more appropriate cars in The Showroom and speed up
                    the process when applying for some of our services like
                    Custodian Insurance.
                </InfoBoxAtom>
            )}
            <div style={{ paddingTop: '40px' }} />
            <SearchField
                id={
                    props2.isMobile
                        ? `autocomplete_loc_mobile`
                        : `autocomplete_loc_desktop`
                }
                placeholder="Search your address"
                type="text"
                autoFocus
                style={{
                    backgroundColor: 'var(--bg-color, #fff)',
                    border: '1px solid var(--text-muted, #b3b3b3)',
                    color: 'var(--text-strong, #1a1a1a)',
                }}
            />
            <div style={{ paddingTop: '30px' }} />
            <Line />
            <div style={{ paddingTop: '30px' }} />
            <InputFieldNoFormikAnimated
                name="Street number  /  House name"
                type="text"
                id="street_number"
                placeholder=""
                value={formData.street_number}
                onChange={(e: any) => {
                    handleChange(e.target.value, 'street_number')
                }}
                onEnterKeyPress={handleChange}
                tabindex={1}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
            />
            <Expander
                height={error && error['street_number'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['street_number']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="Street Address"
                type="text"
                id="route"
                placeholder=""
                value={formData.route}
                onChange={(e: any) => {
                    handleChange(e.target.value, 'route')
                }}
                onEnterKeyPress={handleChange}
                tabindex={2}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
            />
            <Expander
                height={error && error['route'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['route']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="City"
                type="text"
                id="postal_town"
                placeholder=""
                value={formData.postal_town}
                onChange={(e: any) => {
                    handleChange(e.target.value, 'postal_town')
                }}
                onEnterKeyPress={handleChange}
                tabindex={3}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
            />
            <Expander
                height={error && error['postal_town'] ? 'auto' : 0}
                width={'100%'}
            >
                <ErrorText>{error && error['postal_town']}</ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <InputFieldNoFormikAnimated
                name="Postcode"
                type="text"
                id="postal_code"
                placeholder=""
                value={formData.postal_code}
                onChange={(e: any) => {
                    if (e.target.value) {
                        e.target.value = `${e.target.value}`.toUpperCase()
                    }
                    handleChange(e.target.value, 'postal_code')
                }}
                onEnterKeyPress={handleChange}
                tabindex={4}
                isNarrow={true}
                onBlur={(e: any) => e.preventDefault()}
            />
            <Expander height={showPostCodeError ? 'auto' : 0} width={'100%'}>
                <ErrorText>
                    {error && error['postal_code']
                        ? error['postal_code']
                        : 'Invalid post-code'}
                </ErrorText>
            </Expander>
            <div style={{ paddingTop: '24px' }} />
            <Expander height={showAdminAreaInput ? 'auto' : 0} width={'100%'}>
                <div style={{ width: '100%' }}>
                    {formData.country === 'United States' ? (
                        <Select
                            value={formData.administrative_area_level_1}
                            options={usaAdminCodes ?? []}
                            defaultValue={formData.administrative_area_level_1}
                            placeholder={'Administrative Area Code'}
                            disabled={false}
                            onChange={(val: string) => {
                                handleChange(val, 'administrative_area_level_1')
                            }}
                            onFocusToggle={(p: boolean) => {
                                setIsAdmin1Open(p)
                            }}
                        />
                    ) : (
                        <InputFieldNoFormikAnimated
                            type="text"
                            placeholder=""
                            name="admninistrative area"
                            value={
                                formData.country === 'Spain'
                                    ? formData.administrative_area_level_2
                                    : formData.administrative_area_level_1
                            }
                            onChange={(e: any) => {
                                handleChange(
                                    e.target.value,
                                    'administrative_area_level_1'
                                )
                            }}
                            onEnterKeyPress={handleChange}
                            isNarrow={true}
                            tabindex={5}
                            width={'100%'}
                        />
                    )}
                    <Expander
                        height={showAdminAreaError ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>
                            {'Please add a valid administrative area code.'}
                        </ErrorText>
                    </Expander>
                    <div style={{ paddingTop: '24px' }} />
                </div>
            </Expander>

            <div style={{ width: '100%', zIndex: isAdmin1Open ? -1 : 'auto' }}>
                <SelectSearchBarCarInfoDesktop
                    showBothDevices={true}
                    items={countriesOptions ?? []}
                    item={{
                        text: 'countries',
                        answer: formData.country
                            ? formData.country
                            : 'United Kingdom',
                    }}
                    handleSelectChange={(p: string) => {
                        handleChange(p, 'country')
                    }}
                    valueUpdatedFromExternalSource={
                        formData.country ?? null
                        //  formData.country : null
                    }
                />
            </div>
            {props2.isMobile ? (
                <>
                    <div style={{ paddingTop: '40px' }} />
                    <InfoBoxAtom>
                        Your address is completely private and always will be
                        unless you choose to share it explicitly. We use your
                        address to show you more appropriate cars in The
                        Showroom and speed up the process when applying for some
                        of our services like Custodian Insurance.
                    </InfoBoxAtom>
                </>
            ) : null}
        </>
    )
}

export default function UserProfileJourneyAddress({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const [street_number, setStreet_number] = useState<string>(
        userLoggedIn?.address?.street_1 ? userLoggedIn?.address?.street_1 : ''
    )

    const [route, setRoute] = useState<string>(
        userLoggedIn?.address?.street_2 ? userLoggedIn?.address?.street_2 : ''
    )
    const [postal_town, setPostal_town] = useState<string>(
        userLoggedIn?.address?.locality ? userLoggedIn?.address?.locality : ''
    )
    const [postal_code, setPostal_code] = useState<string>(
        userLoggedIn?.address?.postcode ? userLoggedIn?.address?.postcode : ''
    )
    const [country, setCountry] = useState<string>(
        userLoggedIn?.address?.country
            ? userLoggedIn?.address?.country
            : 'United Kingdom'
    )
    const [administrative_area_level_1, setAdministrative_area_level_1] =
        useState<string>(
            userLoggedIn?.address?.administrative_area
                ? userLoggedIn?.address?.administrative_area
                : ''
        )

    const [administrative_area_level_2, setAdministrative_area_level_2] =
        useState<string>(
            userLoggedIn?.address?.administrative_area
                ? userLoggedIn?.address?.administrative_area
                : ''
        )

    useEffect(() => {
        if (userLoggedIn) {
            setStreet_number(
                userLoggedIn?.address?.street_1
                    ? userLoggedIn?.address?.street_1
                    : ''
            )

            setRoute(
                userLoggedIn?.address?.street_2
                    ? userLoggedIn?.address?.street_2
                    : ''
            )

            setPostal_town(
                userLoggedIn?.address?.locality
                    ? userLoggedIn?.address?.locality
                    : ''
            )

            setPostal_code(
                userLoggedIn?.address?.postcode
                    ? userLoggedIn?.address?.postcode
                    : ''
            )

            setCountry(
                userLoggedIn?.address?.country
                    ? userLoggedIn?.address?.country
                    : 'United Kingdom'
            )
        }
    }, [userLoggedIn])

    let usaAdminCodes: IInsuranceDropdownItem[] | null = useAppSelector(
        (state) => {
            return state.localdata.dropdownData.usaStatesCodes
        }
    )

    const [hasInfoChange, setHasInfoChange] = useState<boolean>(false)
    const [isSavedDisabled, setIsSavedDisabled] = useState<boolean>(false)

    const dispatch: any = useAppDispatch()

    const isFormValid = (): boolean => {
        validateForm('street_number', street_number.trim())
        validateForm('route', route.trim())
        validateForm('country', country.trim())
        validateForm('postal_code', postal_code.trim())
        validateForm('postal_town', postal_town.trim())

        if (
            street_number.trim().length === 0 ||
            route.trim().length === 0 ||
            country.trim().length === 0 ||
            postal_code.trim().length === 0 ||
            postal_town.trim().length === 0
        ) {
            return false
        } else {
            return true
        }
    }

    let onSubmit = () => {
        let addressConv: IUserAddressApi = {
            locality: postal_town,
            postcode: postal_code,
            country: country,
            street_1: street_number,
            street_2: route,
            administrative_area:
                country === 'Spain'
                    ? administrative_area_level_2
                    : country === 'United States'
                    ? administrative_area_level_1
                    : undefined,
            type: 'User Address',
        }

        if (hasInfoChange === true) {
            if (isFormValid()) {
                dispatch(addAddressRequest({ data: addressConv }))
                setTimeout(() => {
                    goToNextStep()
                }, 500)
            } else {
                setIsSavedDisabled(true)
            }
        } else {
            goToNextStep()
        }
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [
        step,
        hasInfoChange,
        postal_town,
        postal_code,
        country,
        street_number,
        route,
        administrative_area_level_1,
        administrative_area_level_2,
    ])

    let countriesOptions: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    useEffect(() => {
        if (!countriesOptions) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [])

    let { width } = useWindowSize()
    let inputt = document.getElementById(
        width && width > 881
            ? `autocomplete_loc_desktop`
            : 'autocomplete_loc_mobile'
    ) as HTMLInputElement

    const validateForm = async (id: string, value: string) => {
        try {
            await getRightSchemaAccountData(id).validate({ [id]: value })
            setError((prevState) => {
                return { ...prevState, [id]: undefined }
            })
        } catch (err: any) {
            setError((prevState) => {
                return { ...prevState, [id]: err.errors[0] }
            })
        }
    }

    useEffect(() => {
        if (inputt) {
            googleMaps(inputt)
        }

        if (country === 'United States' && !usaAdminCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest())
        }
    }, [inputt])

    const googleMaps = useCallback(
        async (input: any) => {
            try {
                let autocomplete = new google.maps.places.Autocomplete(input, {
                    types: ['geocode'],
                })

                autocomplete.setFields(['address_component'])

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (
                        position
                    ) {
                        let geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        let circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy,
                        })
                        autocomplete.setBounds(circle.getBounds())
                    })
                }
                autocomplete.addListener('place_changed', () => {
                    let place: any = autocomplete.getPlace()

                    let addressComponents: string[] = []

                    place.address_components !== undefined &&
                        place.address_components.forEach((chunk: any) => {
                            addressComponents.push(chunk.types[0])
                            if (chunk.types[0] === 'street_number') {
                                setStreet_number(chunk.long_name)
                                validateForm('street_number', chunk.long_name)
                            }
                            if (chunk.types.includes('route')) {
                                setRoute(chunk.long_name)
                                validateForm('route', chunk.long_name)
                            }
                            if (chunk.types.includes('postal_code')) {
                                setPostal_code(
                                    chunk.long_name
                                        ? `${chunk.long_name}`.toUpperCase()
                                        : ''
                                )
                                validateForm(
                                    'postal_code',
                                    chunk.long_name
                                        ? `${chunk.long_name}`.toUpperCase()
                                        : ''
                                )
                            }
                            if (chunk.types.includes('locality')) {
                                setPostal_town(chunk.long_name)
                            }
                            if (chunk.types.includes('postal_town')) {
                                setPostal_town(chunk.long_name)
                                validateForm('postal_town', chunk.long_name)
                            }
                            if (
                                chunk.types.includes(
                                    'administrative_area_level_1'
                                )
                            ) {
                                setAdministrative_area_level_1(chunk.short_name)
                            }
                            if (
                                chunk.types.includes(
                                    'administrative_area_level_2'
                                )
                            ) {
                                setAdministrative_area_level_2(chunk.short_name)
                            }
                            if (chunk.types.includes('country')) {
                                setCountry(chunk.long_name)
                            }
                        })

                    if (!addressComponents.includes('street_number')) {
                        validateForm('street_number', '')
                        setStreet_number('')
                    }
                    if (!addressComponents.includes('route')) {
                        validateForm('route', '')
                        setRoute('')
                    }
                    if (!addressComponents.includes('postal_code')) {
                        validateForm('postal_code', '')
                        setPostal_code('')
                    }
                    if (!addressComponents.includes('postal_town')) {
                        validateForm('postal_town', '')
                        setPostal_town('')
                    }
                    setHasInfoChange(true)
                    setIsSavedDisabled(false)
                })
            } catch (e) {
                console.error(e)
            } finally {
            }
        },
        [inputt]
    )

    let handleSwitch = (value: string, id: IGooglePlacesAddress_string) => {
        if (!hasInfoChange) {
            setHasInfoChange(true)
        }

        switch (id) {
            case 'country':
                return setCountry(`${value}`)

            case 'administrative_area_level_1':
                return setAdministrative_area_level_1(`${value}`)
            case 'administrative_area_level_2':
                return setAdministrative_area_level_2(`${value}`)

            case 'postal_code':
                return setPostal_code(`${value}`)

            case 'postal_town':
                return setPostal_town(`${value}`)

            case 'route':
                return setRoute(`${value}`)

            case 'street_number':
                return setStreet_number(`${value}`)
        }
    }

    let handleChange = (value: string, id: IGooglePlacesAddress_string) => {
        if (
            [
                'street_number',
                'route',
                'name',
                // 'phone',
                'postal_town',
                'postal_code',
            ].includes(id)
        ) {
            validateForm(id, value)
        }

        handleSwitch(value, id)
        setIsSavedDisabled(false)

        if (id === 'country' && value === 'United States' && !usaAdminCodes) {
            dispatch(dropDownActions.setUSAStatesDropdownRequest())
        }
    }

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={
                                userLoggedIn?.address
                                    ? 'Update your address'
                                    : 'Add your address'
                            }
                            sectionID="your_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                goToPrevStep()
                            }}
                            maxTitleWith={'286px'}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <RenderForms
                                    formData={{
                                        street_number: street_number,
                                        route: route,
                                        postal_town: postal_town,
                                        postal_code: postal_code,
                                        country: country,
                                        administrative_area_level_1:
                                            administrative_area_level_1,
                                        administrative_area_level_2:
                                            administrative_area_level_2,
                                    }}
                                    handleChange={handleChange}
                                    error={error}
                                />
                            </div>

                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={onSubmit}
                                    bg={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_500
                                    }
                                    isDisabled={
                                        !userLoggedIn || isSavedDisabled
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <RowContent style={{ gap: 8 }}>
                                    <IconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </IconWrapper>{' '}
                                    <EnterTxt $theme={theme}>
                                        Or press Enter
                                    </EnterTxt>
                                </RowContent>
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide
                            style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <UserProfileJourneyUserCardDesktop
                                userWithFormInfo={
                                    userLoggedIn
                                        ? {
                                              ...userLoggedIn,
                                              location:
                                                  postal_town || country
                                                      ? `${
                                                            postal_town?.length >
                                                            0
                                                                ? postal_town
                                                                : 'London'
                                                        }, ${
                                                            country?.length > 0
                                                                ? country ===
                                                                  'United Kingdom'
                                                                    ? 'UK'
                                                                    : country
                                                                : 'UK'
                                                        }`
                                                      : undefined,
                                          }
                                        : undefined
                                }
                            />
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    allowScroll={true}
                    title={
                        userLoggedIn?.address
                            ? 'Update your address'
                            : 'Add your address'
                    }
                    sectionID="your_profile"
                    maxTitleWidth={'250px'}
                    subtitle={'Make sure you look your best'}
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        goToPrevStep()
                    }}
                    onNext={onSubmit}
                    isDisabled={!userLoggedIn || isSavedDisabled}
                >
                    <ColCenter>
                        <div style={{ paddingTop: '24px' }} />
                        <UserProfileJourneyUserCardMobile
                            withPhoto={true}
                            userWithFormInfo={
                                userLoggedIn
                                    ? {
                                          ...userLoggedIn,
                                          location:
                                              postal_town || country
                                                  ? `${
                                                        postal_town?.length > 0
                                                            ? postal_town
                                                            : 'London'
                                                    }, ${
                                                        country?.length > 0
                                                            ? country ===
                                                              'United Kingdom'
                                                                ? 'UK'
                                                                : country
                                                            : 'UK'
                                                    }`
                                                  : undefined,
                                      }
                                    : undefined
                            }
                        />

                        <div
                            style={{
                                paddingTop: '40px',
                                width: '90%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <RenderForms
                                formData={{
                                    street_number: street_number,
                                    route: route,
                                    postal_town: postal_town,
                                    postal_code: postal_code,
                                    country: country,
                                    administrative_area_level_1:
                                        administrative_area_level_1,
                                    administrative_area_level_2:
                                        administrative_area_level_2,
                                }}
                                handleChange={handleChange}
                                error={error}
                                isMobile={true}
                            />
                        </div>

                        <div style={{ paddingTop: '60px' }} />

                        <AbsoluteGradient />
                    </ColCenter>
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
