import { IAnyObject } from '../../../insuranceQuoteApplication/reducer'
import {
    ICustodianCar,
    IInsuranceApplication_API,
    IInsuranceEverydayVehicleID,
} from '../../../insuranceQuoteApplication/types'

type IconvertApplicationToReducerCarIds = {
    vehicles_to_insure_ids_list: string[]
    everyday_car_ids: string[]
    external_car_ids: string[]
}

export const convertApplicationToReducerCarIds = (
    application: IInsuranceApplication_API
): IconvertApplicationToReducerCarIds => {
    let res: IconvertApplicationToReducerCarIds = {
        vehicles_to_insure_ids_list: [],
        everyday_car_ids: [],
        external_car_ids: [],
    }

    // nomalise cars

    let carsObj: IAnyObject = {}

    if (
        application &&
        application.customer &&
        application.customer.custodian_cars &&
        application.customer.custodian_cars
    ) {
        application.customer.custodian_cars.map((carAPI: ICustodianCar) => {
            carsObj = {
                ...carsObj,
                [`${carAPI.uid}`]: {
                    ...carAPI,
                },
            }
        })
    }

    if (application && application.application_custodian_cars) {
        // added this isArray check:
        if (Array.isArray(application.application_custodian_cars)) {
            // console.log(
            //     'application?.application_custodian_cars ; 44',
            //     application?.application_custodian_cars
            // )

            application?.application_custodian_cars?.forEach((car) => {
                res.vehicles_to_insure_ids_list = [
                    ...res.vehicles_to_insure_ids_list,
                    `${car.external_id}`,
                ]
            })
        } else {
            // TODO
            // example res: object:{status: 'missing', message: 'at least one car must be associated with this application'}
        }
    }

    // if (
    //     application &&
    //     application.customer &&
    //     application.customer.custodian_cars
    // ) {
    //     application.customer.custodian_cars.forEach((car) => {
    //         if (car.is_everyday === true) {
    //             // in meantime that BE answer on this
    //             res.everyday_car_ids = [
    //                 ...res.everyday_car_ids,
    //                 `${car.external_id ?? car.uid}`,
    //             ]
    //         }
    //     })
    // }

    if (
        application &&
        application.application_everyday_vehicles &&
        application.application_everyday_vehicles.length > 0
    ) {
        res.everyday_car_ids = application.application_everyday_vehicles.map(
            (item: IInsuranceEverydayVehicleID) => {
                if (item.external_id) {
                    return `${item.external_id}`
                } else return `${item.external_vehicle_uid}`
            }
        )
    }

    if (application.customer && application.customer?.external_vehicles) {
        application.customer.external_vehicles.forEach((car) => {
            res.external_car_ids = [...res.external_car_ids, `${car.uid}`]
        })
    }

    return res
}
