import { IReduxError } from 'entityModels'
import {
    IPresignedUrlEntryImagesArgs,
    IUploadGalleryImagesPreSignedUrlsPayload,
    IUploadImagePreSignedUrlPayloadSingle,
} from 'myModels'
import { call, fork, put, take } from 'redux-saga/effects'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../dispatched/index'

function* getPreSignedUrlImagesEntryGallery(
    payload: IPresignedUrlEntryImagesArgs
) {
    try {
        const result: ResponseGenerator =
            yield api.fileStorage.get_upload_presigned_url_entry_galleryimage_API(
                payload.entryid,
                payload.carid,
                payload.count,
                payload.file_names
            )

        const data: IUploadGalleryImagesPreSignedUrlsPayload = {}

        result.data?.forEach(
            (element: IUploadImagePreSignedUrlPayloadSingle) => {
                if (element.original_filename) {
                    data[element.original_filename] = element
                }
            }
        )

        yield put(
            actions.successActions.getPresignedUrlsEntryGalleryImagesSuccess(
                data
            )
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: getPreSignedUrlImagesEntryGallery,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.getPresignedUrlsEntryGalleryImagesFileStorageError(
                    typedError
                )
            )
        }
    }
}

export function* watchGetPreSignedUrlImagesEntryGallery() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.getPresignedUrlEntryGalleryImagesRequest
        )

        let p: IPresignedUrlEntryImagesArgs = {
            entryid: payload.entryid,
            carid: payload.carid,
            count: payload.count,
            file_names: payload.file_names,
        }
        yield fork(getPreSignedUrlImagesEntryGallery, p)
    }
}

const filestorage_sagas_entry_gallery_presigned_urls: any[] = [
    fork(watchGetPreSignedUrlImagesEntryGallery),
]

export default filestorage_sagas_entry_gallery_presigned_urls
