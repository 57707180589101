import { IJourneySectionID } from 'journeyInterfaces'
import CarProfileBgPattern from './carProfile/carProfileBgPattern'
import CarProfileCardPattern from './carProfile/carProfileCard'
import HandoverPattern from './handover/bgPattern'
import HistoryFilePattern from './historyFile/pattern'
import PastCarsBgPattern from './pastCars/pastCarsBgPattern'
import SellYourCarBgPattern from './sellYourCar/bgPattern'
import SellYourCarCardPattern from './sellYourCar/sellYourCarCard'
import ShareYourCarPattern from './shareYourCar/pattern'
import ShareYourCarCardPattern from './shareYourCar/shareYourCarCard'
import YourProfileBgPattern from './yourProfile/bgPattern'
import YourProfileCardPattern from './yourProfile/yourProfileCard'
import InsuranceBgPattern from './insurance/bgPattern'
import InsuranceCardPattern from './insurance/insuranceCard'
import sell_your_car_next_up from './sellYourCar/sellYourCarNextUpCard.svg'
import car_profile_next_up from './carProfile/carProfileNextUpCard.svg'
import your_profile_next_up from './yourProfile/yourProfileNextUpCard.svg'
import history_file_next_up from './historyFile/historyFileNextUpCard.svg'
import insurance_next_up from './insurance/insuranceNextUpCard.svg'
import past_cars_next_up from './pastCars/pastCarsNextUpCard.svg'
import handover_next_up from './handover/handoverNextUpCard.svg'
import share_car_next_up from './shareYourCar/shareYourCarNextUpCard.svg'
import HandoverCardPattern from './handover/handoverCard'
import HistoryFileCardPattern from './historyFile/historyFileCard'
import PastCarsCardPattern from './pastCars/pastCarsCard'

export const generatenJourneyBgPattern = (
    sectionID: IJourneySectionID,
    width?: string,
    height?: string,
    isMobile?: boolean
) => {
    switch (sectionID) {
        case 'car_profile': {
            return <CarProfileBgPattern width={width} height={height} />
        }

        case 'your_profile':
            return <YourProfileBgPattern width={width} height={height} />

        case 'sell_your_car': {
            return <SellYourCarBgPattern width={width} height={height} />
        }

        case 'past_cars': {
            return (
                <PastCarsBgPattern
                    width={isMobile ? '500' : width}
                    height={isMobile ? '500' : height}
                />
            )
        }

        case 'history_file': {
            return (
                <HistoryFilePattern
                    isMobile={isMobile}
                    width={width}
                    height={height}
                />
            )
        }
        case 'insurance':
            return <InsuranceBgPattern width={width} height={height} />

        case 'share_your_car':
            return <ShareYourCarPattern width={width} height={height} />

        case 'handover': {
            return <HandoverPattern width={width} height={height} />
        }

        default:
            return <CarProfileBgPattern width={width} height={height} />
    }
}

export const generatenJourneyCardPattern = (
    sectionID: IJourneySectionID,
    width?: string,
    height?: string
) => {
    switch (sectionID) {
        case 'sell_your_car': {
            return <SellYourCarCardPattern width={width} height={height} />
        }
        case 'share_your_car': {
            return <ShareYourCarCardPattern width={width} height={height} />
        }
        case 'car_profile':
            return <CarProfileCardPattern width={width} height={height} />
        case 'your_profile':
            return <YourProfileCardPattern width={width} height={height} />
        case 'insurance':
            return <InsuranceCardPattern width={width} height={height} />

        case 'handover':
            return <HandoverCardPattern width={width} height={height} />
        case 'history_file':
            return <HistoryFileCardPattern width={width} height={height} />
        case 'past_cars':
            return <PastCarsCardPattern width={width} height={height} />

        default:
            return <CarProfileCardPattern width={width} height={height} />
    }
}

export const generateNextUpPattern = (sectionID: IJourneySectionID) => {
    switch (sectionID) {
        case 'sell_your_car':
            return sell_your_car_next_up
        case 'car_profile':
            return car_profile_next_up
        case 'your_profile':
            return your_profile_next_up
        case 'history_file':
            return history_file_next_up
        case 'handover':
            return handover_next_up
        case 'insurance':
            return insurance_next_up
        case 'past_cars':
            return past_cars_next_up
        case 'share_your_car':
            return share_car_next_up
        default:
            return undefined
    }
}
