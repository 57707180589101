import * as React from 'react'
import styled from 'styled-components'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import CrossIcon from '../../icons/crossIcon'

type StyledProps = {
    $isActive: boolean
    $bgcolor?: string
    $hasCross?: boolean
}
const Container = styled.div<StyledProps>`
    color: ${(props) =>
        props.$isActive
            ? 'var(--bg-color, #fff)'
            : 'var(--text-darker, #616161)'};
    background-color: ${(props) =>
        props.$bgcolor
            ? props.$bgcolor
            : props.$isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--off-bg-color, #fafafa)'};
    height: 22px;
    padding-left: 10px;
    padding-right: ${(props) => (props.$hasCross ? '3px' : '10px')};
    text-transform: uppercase;
    font-family: 'Lato-bold';
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    letter-spacing: 0.7px;
    white-space: nowrap;
    cursor: pointer;
    /* margin-right: 10px; */
`

// interface IStyled {
//     isActive?: boolean
// }

type Props = {
    children?: React.ReactNode
    dataCyId?: string
    isActive?: boolean
    bgcolor?: string
    onCrossClick?: any
}

export default function CustomTagQuickFilterItemMobile(props: Props) {
    const { children, dataCyId, isActive, bgcolor, onCrossClick } = props

    const { theme } = useThemes()

    return (
        <Container
            $bgcolor={bgcolor}
            $isActive={isActive ? isActive : false}
            data-attr={dataCyId && dataCyId}
            onClick={onCrossClick ? onCrossClick : () => {}}
            $hasCross={onCrossClick}
        >
            <div style={{ transform: 'translateY(1px)' }}>{children}</div>
            {onCrossClick && (
                <div style={{ padding: '10px' }}>
                    <CrossIcon
                        color={
                            isActive
                                ? colours[theme].background_default
                                : colours[theme].text_muted
                        }
                    />
                </div>
            )}
        </Container>
    )
}
