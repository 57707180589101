import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickAway } from '../../../helpers/hooks/domHooks'
import { StandardInputField } from '../Inputfield/inputField'
import Dropdown from './Dropdown'
import { useListNavigation } from './hooks'
import InputFieldTooltip from '../customTooltips/inputFieldTooltip'
import InfoIcon from '../icons/infoIcon'
import { IDropdownItem } from 'entityModels'
import React from 'react'
import useThemes from '../../../providers/theme/hooks'
import ChevronDown from '../icons/components/chevronDown'

const ImportantIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background-color: rgba(26, 26, 26, 0.039);
    display: flex;
    align-items: center;
    justify-content: center;
`

interface SelectProps {
    name?: string
    placeholder?: string
    helperText?: string
    value?: IDropdownItem
    defaultValue?: IDropdownItem
    options: IDropdownItem[]
    disabled?: boolean
    onChange?: (option: IDropdownItem) => void
    backgroundColor?: string
    customActiveColour?: string
    customOptionHighlightColour?: string
    hasInfoWithTooltip?: boolean
    tooltipColor?: string
    tooltipText?: string
    screen_name: 'desktop' | 'mobile'
}

export default function InsuranceSelect(props: SelectProps) {
    /**
     * If a `defaultValue` or `value` is provided, we find their
     * corresponding labels and set it as the default select label.
     */

    let defaultVal =
        typeof props.defaultValue === 'string'
            ? props.defaultValue
            : props.defaultValue?.uid

    const defaultLabel = useMemo(() => {
        const labelForDefaultValue =
            defaultVal &&
            props.options.find(
                (option) =>
                    defaultVal &&
                    typeof defaultVal === 'string' &&
                    option.uid.toLowerCase() === defaultVal?.toLowerCase()
            )

        if (labelForDefaultValue && labelForDefaultValue?.name) {
            return labelForDefaultValue.name
        }

        if (typeof props.defaultValue === 'string') {
            const labelUIDMatchForDefaultValue =
                defaultVal &&
                props.options.find(
                    (option) =>
                        defaultVal &&
                        typeof defaultVal === 'string' &&
                        option.uid.toLowerCase() === defaultVal?.toLowerCase()
                )

            if (labelUIDMatchForDefaultValue) {
                return labelUIDMatchForDefaultValue.name
            }
        }

        const labelForCurrentValue =
            props.value &&
            props.options.find(
                (optionn) =>
                    props.value &&
                    optionn?.uid?.toLowerCase() ===
                        props.value?.uid?.toLowerCase()
            )

        if (labelForCurrentValue && labelForCurrentValue?.name) {
            return labelForCurrentValue.uid
        }

        return props.defaultValue?.name ?? ''
    }, [props.options, props.defaultValue, props.value])

    const [label, setLabel] = useState(defaultLabel)

    useEffect(() => {
        setLabel(defaultLabel)
    }, [defaultLabel])

    const selectOption = (option: IDropdownItem) => {
        setLabel(option.name)
        props.onChange?.(option)
    }

    const { highlightedIndex, setHighlightedIndex, handleKeys } =
        useListNavigation({
            listLength: props.options.length,
            onListItemSelect: (index) => {
                const option = props.options[index]
                selectOption(option)
            },
        })

    const [isListOpen, setIsListOpen] = useState(false)

    const openList = () => {
        setIsListOpen(true)
    }

    const closeList = () => {
        setIsListOpen(false)
    }

    const listRef = useClickAway(closeList)

    const { theme } = useThemes()

    return (
        <FormWrapper disabled={props.disabled} onClick={openList}>
            <StandardInputField
                theme={theme}
                name={props.name}
                defaultValue={defaultVal}
                value={label}
                placeholder={props.placeholder}
                helperText={props.helperText}
                disabled={props.disabled}
                onFocus={openList}
                onClick={openList}
                onChange={props.onChange}
                onKeyDown={handleKeys}
                readOnly={true}
                rightAdornment={
                    props.hasInfoWithTooltip &&
                    props.tooltipText &&
                    props.tooltipColor ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                            }}
                        >
                            <ChevronDownIconWrapper
                                onClick={openList}
                                disabled={props.disabled}
                            >
                                <ChevronDown />
                            </ChevronDownIconWrapper>
                            <InputFieldTooltip
                                tooltipText={props.tooltipText}
                                color={props.tooltipColor}
                            >
                                <ImportantIconWrapper>
                                    <InfoIcon
                                        size="14"
                                        color={props.tooltipColor}
                                    />
                                </ImportantIconWrapper>
                            </InputFieldTooltip>
                        </div>
                    ) : (
                        <ChevronDownIconWrapper
                            onClick={openList}
                            disabled={props.disabled}
                        >
                            <ChevronDown />
                        </ChevronDownIconWrapper>
                    )
                }
                backgroundColor={props.backgroundColor}
                customActiveColour={props.customActiveColour}
            />
            <Dropdown
                ref={listRef}
                isOpen={isListOpen}
                nudgeUp={!!props.helperText}
            >
                {props.options.map((option, index) => (
                    <React.Fragment
                        key={`${option.uid}_${index}_${props.screen_name}`}
                    >
                        <OptionItem
                            {...option}
                            highlighted={index === highlightedIndex}
                            onSelect={() => {
                                selectOption(option)
                                closeList()
                            }}
                            option={option}
                            backgroundColor={props.backgroundColor}
                            onHighlight={() => setHighlightedIndex(index)}
                            customOptionHighlightColour={
                                props.customOptionHighlightColour
                            }
                        />
                    </React.Fragment>
                ))}
            </Dropdown>
        </FormWrapper>
    )
}

interface OptionProps {
    option: IDropdownItem
    highlighted?: boolean
    onSelect: (option: IDropdownItem) => void
    onHighlight?: () => void
    backgroundColor?: string
    customOptionHighlightColour?: string
}

export function OptionItem(props: OptionProps) {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (props.highlighted) {
            ref.current?.scrollIntoView({ block: 'nearest' })
        }
    }, [props.highlighted])

    return (
        <ListItem
            ref={ref}
            onClick={(e) => {
                e.stopPropagation()
                props.onSelect(props.option)
            }}
            onMouseOver={props.onHighlight}
            highlighted={props.highlighted}
            backgroundColor={props.backgroundColor}
            customOptionHighlightColour={props.customOptionHighlightColour}
        >
            {props.option.name}
        </ListItem>
    )
}

const FormWrapper = styled.div<{ disabled?: boolean }>`
    width: 100%;
    position: relative;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
    opacity: ${(props) => (props.disabled ? '0.5' : 'initial')};
`

const ChevronDownIconWrapper = styled.div<{ disabled?: boolean }>`
    padding: 5px 0;
    cursor: pointer;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ListItem = styled.div<{
    highlighted?: boolean
    backgroundColor?: string
    customOptionHighlightColour?: string
}>`
    padding: 8px 16px;
    cursor: pointer;

    color: var(--text-strong, #1a1a1a);

    ${(props) => `
        background-color: ${
            props.highlighted
                ? props.customOptionHighlightColour ?? 'var(--primary_09)'
                : props.backgroundColor
                ? props.backgroundColor
                : 'initial'
        };
    `}
`
