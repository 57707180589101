import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'

import { api } from '../../../services'

import { IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'

import { IPublicShareSuccessPayloadRedux } from '../../garages/actions/actions'
import { ConvertSinglePublicShare } from '../../../conversions/shares/convertPublicShares'
import {
    IPublicShareAPIRes_item,
    IUrlShortenerAPI_request_args,
    IUrlShortenerAPI_res,
} from 'ApiInterfaces'
import posthog from 'posthog-js'
import { base_url } from '../../../services/urls'

export function* CreateGaragePublicShareSaga(garageid: string) {
    try {
        const garagePublicShareAPIP: IPublicShareAPIRes_item = yield call(
            api.entities.mutateGarage.createGaragePublicShare,
            garageid
        )
        let args: IUrlShortenerAPI_request_args = {
            url: `garage/${garagePublicShareAPIP.link_identifier}`,
        }

        let code_s: IUrlShortenerAPI_res = yield call(
            api.entities.updateCar.createUrlShortener,
            args
        )

        let convertedObj: IPublicShareSuccessPayloadRedux = ConvertSinglePublicShare(
            {
                id: garageid,
                entity: 'garage',
                api_payload: garagePublicShareAPIP,
                url_shortener_code: code_s.code,
            }
        )

        convertedObj.public_share.qr_code_url = `${base_url}/s/${code_s.code}`

        yield put(actions.actions.createGaragePublicShareSuccess(convertedObj))

        // Capture event
        posthog.capture('create garage link-share')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateGaragePublicShareSaga,
                payload: garageid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.createGaragePublicShareError(typedError)
            )
            return
        }
    }
}

function* watcherCreatePublicGarageShare() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.createGaragePublicShareRequest
        )
        yield call(CreateGaragePublicShareSaga, payload)
    }
}

const create_public_garage_shares_sagas: any[] = [
    fork(watcherCreatePublicGarageShare),
]

export default create_public_garage_shares_sagas
