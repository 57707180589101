import * as React from 'react'
import { Menu, Dropdown } from 'antd'
import styled from 'styled-components'
import './antd.css'
import { device } from '../../../templates/displays/devices'
import { OTaskFilterNSort } from '../../../../ol/ol'
import ChevronDown from '../../icons/components/chevronDown'

const StyledMenu = styled(Menu)`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    li {
        :hover {
            background-color: var(--background_neutral);
        }
    }
`

const MenuItem = styled.button<StyleProps>`
    background-color: transparent;
    height: 100%;
    width: 100%;
    border: none;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }
    color: ${(props) =>
        props.$isDisabled
            ? 'var(--text-disabled) !important'
            : 'var(--primary, #5ec3ca) !important'};
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: ${(props) =>
        props.fontSize !== undefined ? props.fontSize : '11px'};
    font-family: Lato !important;
    cursor: pointer !important;
    text-align: start;
    padding-top: 5px;
    padding-bottom: 5px;

    @media ${device.ipad} {
        width: auto !important;
        font-size: 11px;
    }
`

const MenuPropmt = styled.button<StyleProps>`
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }
    text-align: ${(props) => (props.$rightAligned ? 'center' : 'start')};

    color: ${(props) =>
        props.$isDisabled
            ? 'var(--text-darker, #616161) !important'
            : 'var(--primary, #5ec3ca) !important'};
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '11px')};
    font-family: Lato !important;
    cursor: pointer !important;
    height: ${(props) => (props.height ? props.height : 'auto')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    @media ${device.ipad} {
        width: auto !important;
        font-size: 11px;
    }
`

const SelectedAsSpan = styled.span<ISelectedAs>`
    margin-left: 5px;
    color: ${(props) =>
        props.placeholder === 'Filter by Outstanding'
            ? 'var(--error, #df6f6f)'
            : props.selectedAs &&
              props.selectedAs === OTaskFilterNSort.filter_by.outstanding.name
            ? 'var(--error, #df6f6f)'
            : props.selectedAs &&
              props.selectedAs === OTaskFilterNSort.filter_by.completed.name
            ? 'var(--insurance_details_green)'
            : 'var(--primary, #5ec3ca) !important'};
`

type IMenuItem = {
    text: string
    action?: any
}

type Props = {
    items: IMenuItem[]
    handleSelectAs?: any
    removeSetAs?: any
    id?: string
    fontSize?: string
    height?: string
    placeholder?: string
    placeholderWhenSelected?: string
    width?: string
    rightAligned?: boolean
    isDisabled?: boolean
}

type StyleProps = {
    height?: string
    fontSize?: string
    width?: string
    selectedAs?: any
    $rightAligned?: boolean
    $isDisabled?: boolean
}

type ISelectedAs = {
    selectedAs?: any
    placeholder?: any
}
type State = {
    selectedAs: string | null
}

class DropdownExpanderHover extends React.Component<Props, State> {
    state = {
        selectedAs: null,
    }

    setAsSelected = (choice: string | null) => {
        this.setState({ selectedAs: choice })
    }

    render() {
        const {
            items,
            handleSelectAs,
            id,
            removeSetAs,
            height,
            fontSize,
            placeholder,
            placeholderWhenSelected,
            width,
            rightAligned,
            isDisabled,
        } = this.props
        const { selectedAs } = this.state

        let textBeforeAnswer = placeholderWhenSelected
            ? placeholderWhenSelected
            : ''

        const menuItems = items.map((item: IMenuItem, index: number) => {
            if (item.text === 'none') {
                return (
                    <React.Fragment key={`dropdown_expander_hover_${index}`}>
                        <Menu.Item
                            onClick={(e: any) => {
                                this.setAsSelected(item.text)

                                if (removeSetAs) {
                                    return removeSetAs(id)
                                } else if (item.action) {
                                    return item.action(item.text)
                                }

                                return
                            }}
                            key={index}
                        >
                            <MenuItem
                                fontSize={fontSize && fontSize}
                                height={height && height}
                                $isDisabled={isDisabled}
                            >
                                {item.text}
                            </MenuItem>
                        </Menu.Item>
                    </React.Fragment>
                )
            } else
                return (
                    <React.Fragment
                        key={`dropdown_expander_hover_w_text_${index}`}
                    >
                        <Menu.Item
                            onClick={(e: any) => {
                                this.setAsSelected(item.text)

                                if (handleSelectAs) {
                                    handleSelectAs(item.text, id)
                                } else if (item.action) {
                                    return item.action(item.text)
                                }

                                return
                            }}
                            key={index}
                        >
                            <MenuItem fontSize={fontSize && fontSize}>
                                {item.text}
                            </MenuItem>
                        </Menu.Item>
                    </React.Fragment>
                )
        })

        const menu = <StyledMenu>{menuItems}</StyledMenu>

        return (
            <Dropdown overlay={menu}>
                <MenuPropmt
                    height={height && height}
                    width={width && width}
                    fontSize={fontSize && fontSize}
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                    $rightAligned={rightAligned}
                    $isDisabled={isDisabled}
                >
                    {selectedAs ? (
                        <>
                            {textBeforeAnswer}
                            <SelectedAsSpan
                                selectedAs={selectedAs && selectedAs}
                                placeholder={placeholder && placeholder}
                            >
                                {selectedAs}
                            </SelectedAsSpan>
                        </>
                    ) : placeholder &&
                      placeholder !== 'Filtered by Outstanding' ? (
                        placeholder
                    ) : placeholder &&
                      placeholder === 'Filtered by Outstanding' ? (
                        <>
                            Filtered by
                            <SelectedAsSpan
                                selectedAs={
                                    OTaskFilterNSort.filter_by.outstanding.name
                                }
                            >
                                {OTaskFilterNSort.filter_by.outstanding.name}
                            </SelectedAsSpan>
                        </>
                    ) : (
                        <SelectedAsSpan>{placeholder}</SelectedAsSpan>
                    )}
                    {/* {!selectedAs && <DownOutlined />} */}

                    {placeholderWhenSelected && (
                        <div style={{ paddingLeft: 8 }}>
                            <ChevronDown size="12" />
                        </div>
                    )}
                </MenuPropmt>
            </Dropdown>
        )
    }
}

export default DropdownExpanderHover
