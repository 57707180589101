import {
    IGalleryImage,
    IReduxError,
    IUpdateCarGalleryImagePayload,
} from 'entityModels'
import { call, fork, put, take } from 'redux-saga/effects'
import * as actions from '../actions'
import * as carActions from '../../cars/actions/actions'

import { api } from '../../../services'
import { IPayloadCarGalleryImageFromApi } from 'IapiDataPayload'
import { ConvertRawToNewImageEntity } from '../../../conversions/entities/ImageTypeConversions'
// import { getCarDataApiPayloadById } from '../../cars/sagas/getcar'
import posthog from 'posthog-js'
import { ResponseGenerator } from 'sagaInterfaces'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'

export function* updateCarGalleryImageApi(
    payload: IUpdateCarGalleryImagePayload
) {
    try {
        const result: ResponseGenerator =
            yield api.entities.mutateGallery.updateCarGalleryImage(payload)
        const rawData: IPayloadCarGalleryImageFromApi = result.data[0]

        const convertedImage: IGalleryImage = yield call(
            ConvertRawToNewImageEntity,
            rawData
        )

        yield put(actions.actions.updateCarGalleryImageSuccess(convertedImage))

        if (payload.body.cover) {
            yield put(
                carActions.updateCoverImgId({
                    carid: payload.carid,
                    new_cover_id: rawData.uid,
                })
            )
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: updateCarGalleryImageApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.updateCarGalleryImageError(typedError)
            )
            return
        }
    }
}

function* watcherUpdateCarGalleryImage() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.updateCarGalleryImageRequest
        )

        yield call(updateCarGalleryImageApi, payload)

        // yield call(getCarDataApiPayloadById, payload.carid)

        // Capture event
        posthog.capture('UPDATE CAR IMAGE')
    }
}

const gallery_sagas: any[] = [fork(watcherUpdateCarGalleryImage)]

export default gallery_sagas
