import * as React from 'react'
import AnimateHeight from 'react-animate-height'

type Props = {
    children?: React.ReactNode
    height: any
    dataCyId?: string
    duration?: number | undefined
    width?: string
}

class Expander extends React.Component<Props, {}> {
    render() {
        const { children, height, dataCyId, duration, width } = this.props

        return (
            <div
                className="animated fadeIn slower"
                data-attr={dataCyId && dataCyId}
                style={{ width: width ? width : 'auto' }}
            >
                <AnimateHeight
                    duration={duration ? duration : 200}
                    height={height}
                >
                    {children}
                </AnimateHeight>
            </div>
        )
    }
}

export default Expander
