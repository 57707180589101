import { RootState } from 'typesafe-actions'
import * as React from 'react'
import AddCarCoverImageMobile from '../carGalleryEditForm/addCarCoverImageMobileForm'
import { connect } from 'react-redux'
import { apiSentryErrorHandler } from '../../../../redux/services/errorHandler'
import { IUploadGalleryImagesPreSignedUrlsPayload } from 'myModels'

let axios = require('axios')

function mapStateToProps(state: RootState) {
    return {
        gallerypresignedurls: state.fileStorage.gallerypresignedurls,
    }
}

interface Props {
    close: any
    carid: string
    gallerypresignedurls?: IUploadGalleryImagesPreSignedUrlsPayload
}

type State = {
    imageUploadedData: any | null
    progressPercent: number | null
    changeInPresignedURL: boolean
    presignedUrlsState: any
    file: File | undefined
    [key: string]: any
    isSomethingBeingUploaded: boolean
}

class WrapperCarCoverImageMobile extends React.Component<Props, State> {
    state = {
        imageUploadedData: null,
        isImageCropperShown: false,
        progressPercent: null,
        changeInPresignedURL: false,
        presignedUrlsState: undefined,
        file: undefined,
        isSomethingBeingUploaded: true,
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let urls = this.props.gallerypresignedurls

        if (urls !== prevProps.gallerypresignedurls) {
            if (urls !== null && urls !== undefined) {
                if (urls[0]) {
                    let stateFile: File | undefined = this.state.file

                    stateFile !== undefined &&
                        this.upload_presignedurl_image_to_s3(stateFile)

                    this.setState({
                        presignedUrlsState: this.props.gallerypresignedurls,
                        changeInPresignedURL: !this.state.changeInPresignedURL,
                    })
                }
            }
        }
        return
    }

    // DIRECT SETSTATE

    setImageUploaded = (imgUploadedData: any[]) => {
        this.setState({ imageUploadedData: imgUploadedData })
    }

    deleteImageUploaded = (key: string, index: number) => {
        this.setState({ file: undefined })
        this.setState({ progressPercent: null })
    }

    // OTHER FUNCS

    upload_presignedurl_image_to_s3 = async (file: File) => {
        let stateFile = this.state.file

        if (stateFile !== null && stateFile !== undefined) {
            if (stateFile !== undefined && this.props.gallerypresignedurls) {
                return await axios
                    .request({
                        method: 'put',
                        url: this.props.gallerypresignedurls[file.name].url,
                        data: stateFile,
                        onUploadProgress: (p: any) => {
                            this.setState({
                                progressPercent: (p.loaded / p.total) * 100,
                            })
                        },
                    })
                    .then((data: any) => {
                        this.setState({ isSomethingBeingUploaded: false })
                    })
                    .catch((e: any) => {
                        apiSentryErrorHandler(
                            e,
                            'Upload presigned car cover url image to s3 error'
                        )
                        return Promise.reject(e)
                    })
            }
        }
    }

    handleFilesGivenInWrapperComponent = (file: File) => {
        return this.setState({ file: file })
    }

    render() {
        const { carid, close } = this.props

        const { file, isSomethingBeingUploaded } = this.state

        return (
            <AddCarCoverImageMobile
                close={close}
                setImageUploaded={this.handleFilesGivenInWrapperComponent}
                currentImagesUploaded={file}
                car_id={carid}
                progressPercent={this.state.progressPercent}
                isSomethingBeingUploaded={isSomethingBeingUploaded}
            />
        )
    }
}

export default connect(mapStateToProps, {})(WrapperCarCoverImageMobile)
