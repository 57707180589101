import * as React from 'react'
import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
// import TouchScrollable from 'react-scrolllock'

import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowMobile from '../../buttons/buttonsStandardBottomRowMobile'
import Expander from '../../../../atoms/expander/expander'
import Faded from '../../../../templates/animated/faded'
import { useState } from 'react'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTitle,
    Notice,
} from '../../../../molecules/technicalInformation/modal'
import TextArea from '../../../../atoms/textarea/textarea'
import useThemes from '../../../../../providers/theme/hooks'

const SmallPadding = styled.div`
    padding-top: 24px;
`

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`
export const DescriptionHeader = styled.div`
    padding-top: 14px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    font-size: 14px;
    text-align: left;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_externalInfoFormMobile: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: any
    user: any
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        // touched,
        handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const countCharNo = (value: string) => {
        let noOfLineBreaks: number = value.split(/[\n\r]/g).length
        return setTextAreaMaxLength(451 - noOfLineBreaks * 50)
    }

    const [textAreaMaxLength, setTextAreaMaxLength] = useState(451)

    const { theme } = useThemes()

    return (
        <React.Fragment>
            {item !== undefined && (
                <ModalContentMobile
                    $theme={theme}
                    data-attr={`change-${item.id}-modal-mobile`}
                >
                    <ModalInnerContentWrapperMobile>
                        <ModalTitle
                            $theme={theme}
                            style={
                                item.id === 'one_liner'
                                    ? {
                                          marginBottom: 8,
                                      }
                                    : {}
                            }
                        >
                            {item.id === 'one_liner'
                                ? ' Edit your bio'
                                : item.text}
                        </ModalTitle>
                        {item.id !== 'one_liner' && <SmallPadding />}

                        <Expander
                            height={
                                errors.single_item_edited_value_externalInfoFormMobile
                                    ? 'auto'
                                    : 0
                            }
                            dataCyId={`${item.id}-input-field-error-mobile`}
                        >
                            <Faded>
                                <div style={{ paddingTop: '5px' }} />
                                <Error className="animated fadeIn slower">
                                    {
                                        errors.single_item_edited_value_externalInfoFormMobile
                                    }
                                </Error>
                            </Faded>
                        </Expander>

                        {item.id !== 'one_liner' && (
                            <InputField
                                theme={theme}
                                id="single_item_edited_value_externalInfoFormMobile"
                                name="single_item_edited_value_externalInfoFormMobile"
                                placeholder={item.text}
                                tabindex={1}
                                aria-live="polite"
                                formikprops={props}
                                value={
                                    values.single_item_edited_value_externalInfoFormMobile
                                }
                                font_family="Lato"
                                dataCyId={`${item.id}-input-field-mobile`}
                                onEnterKeyPress={props.handleSubmit}
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                        )}

                        {item.id === 'display_name' && (
                            <>
                                <SmallPadding />
                                <Notice>
                                    This is the name that will be displayed on
                                    your garage. Whilst you can have your full
                                    name, you do not need to.
                                </Notice>
                            </>
                        )}
                    </ModalInnerContentWrapperMobile>

                    {item.id === 'one_liner' && (
                        <ModalInnerContentWrapperMobile
                            style={{
                                backgroundColor: 'var(--off-bg-color, #fafafa)',
                            }}
                        >
                            <div style={{ height: 250 }}>
                                <TextArea
                                    id="single_item_edited_value_externalInfoFormMobile"
                                    name="single_item_edited_value_externalInfoFormMobile"
                                    aria-live="polite"
                                    handleChange={(e: any) => {
                                        handleChange(e)
                                        countCharNo(e.target.value)
                                    }}
                                    defaultValue={item.answer}
                                    value={
                                        values.single_item_edited_value_externalInfoFormMobile
                                    }
                                    maxLength={textAreaMaxLength}
                                />
                            </div>
                            {/* </TouchScrollable> */}
                        </ModalInnerContentWrapperMobile>
                    )}
                    {/* <SmallPadding /> */}
                    <ModalInnerContentWrapperMobile>
                        <ButtonsStandardBottomRowMobile
                            cancel={toggle}
                            submit={props.handleSubmit}
                            confirmationtext="update"
                            disabled={
                                errors.single_item_edited_value_externalInfoFormMobile
                                    ? true
                                    : false
                            }
                            dataCyIdUpdate={`submit-new-${item.id}-mobile`}
                            dataCyIdCancel={`cancel-new-${item.id}-mobile`}
                        />
                    </ModalInnerContentWrapperMobile>
                </ModalContentMobile>
            )}
        </React.Fragment>
    )
}

const AccountExternalInfoFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_externalInfoFormMobile:
            props.item !== undefined ? props.item.answer : '',
    }),

    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            let noOfLineBreaks =
                values.single_item_edited_value_externalInfoFormMobile
                    ? values.single_item_edited_value_externalInfoFormMobile.split(
                          /[\n\r]/g
                      ).length
                    : 0
            return Yup.object().shape({
                single_item_edited_value_externalInfoFormMobile:
                    props.item.id === 'display_name'
                        ? Yup.string().max(
                              36,
                              'Your display name must be between 2 and 35 characters.'
                          )
                        : props.item.id === 'one_liner'
                        ? Yup.string().max(
                              noOfLineBreaks && noOfLineBreaks > 0
                                  ? 451 - noOfLineBreaks * 50 - noOfLineBreaks
                                  : 451,
                              `You've reached the limit of characters and line breaks.`
                          )
                        : Yup.string().required(),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (
            props.item.id === 'one_liner' &&
            props.item.answer !==
                values.single_item_edited_value_externalInfoFormMobile
        ) {
            props.submitFunc({
                id: props.user.id,
                data: {
                    bio: values.single_item_edited_value_externalInfoFormMobile,
                },
            })
        } else if (
            props.item.answer !==
            values.single_item_edited_value_externalInfoFormMobile
        ) {
            props.submitFunc({
                id: props.user.id,
                data: {
                    [props.item.id]:
                        values.single_item_edited_value_externalInfoFormMobile,
                },
            })
        }

        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(AccountExternalInfoFormMobile)
