import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { connect } from 'react-redux'
import { MenuDots } from '../../statefulicons'
import Faded from '../../../templates/animated/faded'
import { Tooltip } from 'antd'

const Wrapper = styled.div`
    position: relative;
    z-index: 10;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
`

type IStyle = {
    $isDisabled: boolean | undefined
    $isActive?: boolean
    $customColour?: string
}

export const OptionsMenuItem = styled.div<IStyle>`
    position: relative;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 4px;
    cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
    width: 100%;

    color: ${(props) =>
        props.$isDisabled
            ? 'var(--text-disabled) !important'
            : props.$customColour ?? 'var(--text-default, #666666)'};

    background-color: ${(props) =>
        props.$isActive
            ? 'var(--base-overlays-neutral-alpha-50, rgba(26, 26, 26, 0.02))'
            : 'transparent'};

    :hover {
        transition: all 100ms;
        background-color: ${(props) =>
            props.$isDisabled
                ? 'transparent'
                : 'var(--base-overlays-neutral-alpha-100, rgba(26, 26, 26, 0.04)) !important'};

        color: ${(props) =>
            props.$isDisabled
                ? 'var(--text-disabled) !important'
                : 'inherited'};
    }
`

type IstyledProps = {
    direction?: 'top' | 'bottom'
}

const MenuBox = styled.div<IstyledProps>`
    z-index: 2;
    position: absolute;
    right: 0;
    bottom: ${(props: IstyledProps) =>
        props.direction === 'bottom' ? 'auto' : '50px'};

    top: ${(props: IstyledProps) =>
        props.direction === 'bottom' ? '60px' : 'auto'};
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-family: Lato;
    font-size: 16px;
    /* Base/White */

    background: var(--modal-side-sheet-pop-over-bg, #fff);
    /* 24 */

    box-shadow: 0px 4px 24px var(--box-shadow, rgba(0, 0, 0, 0.08));
    border-radius: 4px;

    height: auto;
    width: max-content;

    @media ${device.smallest_laptops} {
    }
    @media ${device.ipad} {
    }

    :hover {
        color: var(--primary, #5ec3ca);
        transition: all 100ms;
    }
`

export type IThreeDotsActionsItems = {
    id?: string
    text: string
    url?: string
    actionOnClick?: any
    color?: string
    idRequired?: boolean
    isDisabled?: boolean
    disabledTxt?: string
    hide_item?: boolean
    isLoading?: boolean
    icon?: React.ReactNode
}

interface Props {
    actions_items: IThreeDotsActionsItems[]
    menuId: string
    manageMenus?: any
    menuOpened: string | null
    customWidth?: string
    customHeight?: string
    direction?: 'top' | 'bottom'
    active_item?: IThreeDotsActionsItems
    customButton?: React.ReactFragment
}

type State = {
    isTooltipVisible: boolean
}

class ActionsMenuDots extends React.Component<Props, State> {
    state = {
        isTooltipVisible: false,
    }

    _isMounted = false

    private dropdowRef = React.createRef<HTMLDivElement>()

    componentDidMount() {
        this._isMounted = true
        this.dropdowRef.current!.focus()
        let node = this.dropdowRef.current
        document.addEventListener('mousedown', (e: any) =>
            this.handleClickOutsideDropdown(e, node)
        )
    }
    componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropdown
        )
        this._isMounted = false
    }

    handleClickOutsideDropdown(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.props.manageMenus && this.props.manageMenus(undefined)
        }
    }

    render() {
        const {
            actions_items,
            manageMenus,
            menuId,
            menuOpened,
            customWidth,
            customHeight,
            direction,
            active_item,
            customButton,
        } = this.props

        const status = menuOpened === menuId ? true : false

        if (this.state.isTooltipVisible) {
            setTimeout(() => this.setState({ isTooltipVisible: false }), 2000)
        }

        let renderItem = (item: IThreeDotsActionsItems, index: number) => {
            return (
                <OptionsMenuItem
                    key={index}
                    $isDisabled={item.isDisabled}
                    onClick={(e: any) => {
                        e.preventDefault()

                        if (!item.isDisabled) {
                            if (!item.idRequired) {
                                item.actionOnClick
                                    ? item.actionOnClick(item.text)
                                    : this.setState({
                                          isTooltipVisible: true,
                                      })
                            } else if (item.idRequired) {
                                item.actionOnClick
                                    ? item.actionOnClick(item.text)
                                    : this.setState({
                                          isTooltipVisible: true,
                                      })
                            } else
                                this.setState({
                                    isTooltipVisible: true,
                                })
                        }
                    }}
                    $isActive={item.text === active_item?.text ? true : false}
                    $customColour={item.color}
                >
                    {item.icon ?? null}
                    {item.text}
                </OptionsMenuItem>
            )
        }

        return (
            <React.Fragment>
                <Wrapper ref={this.dropdowRef} id={'id_of_wrapper'}>
                    {customButton ?? (
                        <MenuDots
                            onClick={(e: any) => {
                                e.preventDefault(e)
                                manageMenus && manageMenus(menuId)
                            }}
                            isOpen={status}
                            width={customWidth ?? '30px'}
                            customHeight={customHeight ?? undefined}
                        />
                    )}
                    {status && (
                        <Faded>
                            <MenuBox direction={direction}>
                                {actions_items.map((item, index) => {
                                    if (item.isDisabled && item.disabledTxt) {
                                        return (
                                            <React.Fragment
                                                key={`actions_menu_${index}_with_tooltip`}
                                            >
                                                <Tooltip
                                                    title={item.disabledTxt}
                                                    placement="left"
                                                    color={
                                                        'var(--error, #df6f6f)'
                                                    }
                                                >
                                                    {renderItem(item, index)}
                                                </Tooltip>
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment
                                                key={`${index}_img_menu_item`}
                                            >
                                                {renderItem(item, index)}
                                            </React.Fragment>
                                        )
                                    }
                                })}
                            </MenuBox>
                        </Faded>
                    )}
                </Wrapper>
            </React.Fragment>
        )
    }
}

export default connect()(ActionsMenuDots)
