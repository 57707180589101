import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// import IpadAndMobileDisplay from "../displays/ipadAndMobileDisplay";
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import Faded from '../../animated/faded'
// import ScrollLock from 'react-scrolllock'
// import TouchScrollable from "react-scrolllock";

import { device } from '../devices'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'

function mapStateToProps(state: RootState) {
    return {
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

interface Props {
    children: React.ReactNode
    isCollapsed: boolean
    isOpen: boolean
    toggle: any
    modalBackgroundColor?: string
    toggleCollapsed: (p?: boolean) => any
    manageTopSubmenuSuccess: (p: IManageTopSubmenuSuccessArg) => any
}

interface Istyle {
    isCollapsed?: boolean
    isOpen?: boolean
    backgroundColor?: string
}

const DesktopPageWrapperStyle = styled.div<Istyle>`
    z-index: 12;
    position: fixed;
    opacity: ${(props) => (props.isOpen ? '1' : '0')};
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : 'var(--gallery-image-bg)'};
    height: 100%;
    width: 100%;

    top: 0px;
    bottom: 0px;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    left: 0px;
    right: 0px;
`

const LeftClickBlock = styled.div`
    display: none;

    @media ${device.beyond_ipad_mobile} {
        display: block;
        position: absolute;
        top: 0;
        left: 0px;
        right: 0px;
        bottom: 0px;
        height: 100vh;
        width: 100vw;
    }
`

const dispatchProps = {
    toggleCollapsed: (p?: boolean) => menuActions.toggleDesktopMainAppMenu(),
    manageTopSubmenuSuccess: (p: IManageTopSubmenuSuccessArg) =>
        menuActions.manageTopSubmenu(p),
}

class ModalDisplayGalleryImageDesktop extends React.Component<Props> {
    windowSize = window && window.innerWidth

    componentDidMount() {
        this.props.manageTopSubmenuSuccess({
            isRemoved: true,
        })
    }

    componentWillUnmount() {
        this.props.manageTopSubmenuSuccess({
            isRemoved: false,
        })
    }

    render() {
        const { isOpen, toggle, children, modalBackgroundColor } = this.props
        return (
            <DesktopDisplayOnly>
                <Faded>
                    <DesktopPageWrapperStyle
                        isOpen={isOpen}
                        backgroundColor={modalBackgroundColor}
                    >
                        <LeftClickBlock
                            onClick={(e: any) => {
                                e.preventDefault()
                                setTimeout(() => {
                                    toggle()
                                }, 50)
                            }}
                        />

                        {children}
                    </DesktopPageWrapperStyle>
                </Faded>

                {/* {isOpen && <ScrollLock />} */}
            </DesktopDisplayOnly>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(ModalDisplayGalleryImageDesktop)
