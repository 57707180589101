import styled from 'styled-components'
import React from 'react'
import ChevronDown from '../icons/components/chevronDown'

type IStyledProps = {
    isBlueBg?: boolean | undefined
    customTextColor?: string | undefined
    isUppercase?: boolean | undefined
    chevron?: boolean | undefined
}
const NavCaption = styled.section<IStyledProps>`
    position: fixed;
    top: 0px;
    display: flex;
    width: 100%;
    height: 64px;
    padding: 12px 24px;
    align-items: center;
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    background: var(--bg-color, #fff);
`

const Return = styled.div<IStyledProps>`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 18px;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.09px;
    padding-left: 12px;
`

interface Props {
    customText?: string
}

const ReturnNavV2: React.FC<Props> = (props) => {
    return (
        <NavCaption>
            <div
                style={{
                    transform: 'rotate(90deg)',
                    paddingTop: 24,
                    paddingBottom: 24,
                }}
            >
                <ChevronDown />
            </div>

            <Return>{props.customText ? props.customText : 'return'}</Return>
        </NavCaption>
    )
}

export default ReturnNavV2
