export type IEnquiryOptionCopyItem = {
    id: string
    name: string
    iconID?: string
    inputType?: 'text' | 'number' | 'email'
}

type IEnquiryOptionCopySection = {
    id: string
    text: string
    type: 'bullet' | 'simple_checkbox' | 'icon_btn_checkbox' | 'input_field'
    options: IEnquiryOptionCopyItem[]
}

export const car_enquiry_info_copy_options: IEnquiryOptionCopyItem[] = [
    {
        id: 'history_file',
        name: 'Request history files',
    },
    {
        id: 'gallery',
        name: 'Request photo gallery',
    },
    {
        id: 'viewing',
        name: 'Request viewing',
    },
    {
        id: 'for_sale_availability',
        name: 'Is this car still available for sale?',
    },
    {
        id: 'price_movement',
        name: 'Is there any movement on price?',
    },
    {
        id: 'other',
        name: 'Anything else',
    },
]

export const car_enquiry_offer_copy_options: IEnquiryOptionCopyItem[] = [
    {
        id: 'asking_price_offer',
        name: 'Offer asking price of',
    },
    {
        id: 'personal_offer_amount',
        name: 'Make personal offer',
    },
]

export const car_enquiry_contact_copy_options: IEnquiryOptionCopyItem[] = [
    {
        id: 'email',
        name: 'Email',
        iconID: 'email',
    },
    {
        id: 'phone',
        name: 'Phone call',
        iconID: 'phone',
    },
    {
        id: 'message',
        name: 'Message',
        iconID: 'message',
    },
]

export const car_enquiry_contact_details_copy_options: IEnquiryOptionCopyItem[] =
    [
        {
            id: 'email',
            name: 'email',
            inputType: 'email',
        },
        {
            id: 'name',
            name: 'name',
            inputType: 'text',
        },
        {
            id: 'phone',
            name: 'phone',
            inputType: 'number',
        },
    ]

export const car_enquiry_info_copy: IEnquiryOptionCopySection = {
    id: 'info_copy',
    type: 'simple_checkbox',
    text: 'What would you like to do?',
    options: car_enquiry_info_copy_options,
}

export const car_enquiry_offer_copy: IEnquiryOptionCopySection = {
    id: 'offer_copy',
    type: 'bullet',
    text: 'Whats your offer?',
    options: car_enquiry_offer_copy_options,
}

export const car_enquiry_contact_copy: IEnquiryOptionCopySection = {
    id: 'contact_copy',
    type: 'icon_btn_checkbox',
    text: 'How would you like to be contacted?',
    options: car_enquiry_offer_copy_options,
}

export const car_enquiry_contact_details_copy: IEnquiryOptionCopySection = {
    id: 'contact_details_copy',
    type: 'input_field',
    text: 'Your details',
    options: car_enquiry_contact_details_copy_options,
}
