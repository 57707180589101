import {
    ICarsObject,
    ICustomErrorData,
    IDropdownItem,
    IReduxError,
    ITechnicalInformationFormPayload,
    IUpdateCarOverviewGridInfo,
    IwriteMultipleTechnicalInformationPayloadReq,
} from 'entityModels'
import { ICarPayload, ICarUpdateFieldsPayload } from 'IapiDataPayload'
import posthog from 'posthog-js'
import { call, fork, put, select, take } from 'redux-saga/effects'
import { RootState } from 'typesafe-actions'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../actions'
import * as carActions from '../../cars/actions/actions'
import { IApiUpdateCar_args } from 'ApiInterfaces'
import {
    ICarGridInfo,
    SetCarEntryExtraneousReduxData,
} from '../../../showroom/sagas/getShowroomEntry'
import { convertDateWithDayJs } from '../../../helpers/dates'
import { message } from 'antd'
import { history } from '../../../store'

const getCarError = (state: RootState) => state.entities.carsData.error
const getCarsData = (state: RootState) => state.entities.carsData.cars

export function* writeMultipleTechnicalInformationSaga(
    payload: IwriteMultipleTechnicalInformationPayloadReq
): Generator<any, any, any> {
    try {
        let dataToSend: ICarUpdateFieldsPayload = {}

        const getAllState = (state: RootState): RootState => state
        let store = yield select(getAllState)

        let tempMakeID = store.localdata.dropdownData.tempMakeID
        let tempModelID = store.localdata.dropdownData.tempModelID

        let dataList = payload.data

        let showroomCarDataUpdateObj: ICarGridInfo = {}

        let carsData: ICarsObject = yield select(getCarsData)
        let currentCar = carsData[payload.car_id]
        let carTitle = currentCar?.title
            ? currentCar.title.toLowerCase()
            : undefined

        let currentMake = currentCar?.overview?.data?.make?.answer
            ? currentCar.overview.data.make.answer.toLowerCase()
            : undefined

        let currentCarMakeID = currentCar?.overview?.data.make?.current_uid

        let currentCarModelID = currentCar?.overview?.data.model?.current_uid
        let currentModel = currentCar?.overview?.data?.model?.answer
            ? currentCar.overview.data.model.answer.toLowerCase()
            : undefined
        let currentYear = currentCar?.overview?.data?.year?.answer
            ? `${currentCar?.overview?.data?.year?.answer}`
            : undefined

        let updateCarTitle = false

        let tempMakeDisplay = store.localdata.dropdownData.tempMakeDisplay
        let tempModelDisplay = store.localdata.dropdownData.tempModelDisplay

        if (dataList) {
            for (const data of dataList) {
                const results: ITechnicalInformationFormPayload = {
                    tech_info_id: payload.tech_info_id,
                    id: data.id,
                    data: data,
                }
                if (data.id === 'make' || data.id === 'model') {
                    const listMakes = store.localdata.dropdownData.carMakes
                    const listModels = store.localdata.dropdownData.carModels

                    if (data.id === 'make') {
                        // console.log(
                        //     'inside of here',
                        //     'tempMakeID',
                        //     tempMakeID,
                        //     'currentCarMakeID',
                        //     currentCarMakeID,
                        //     'currentMake',
                        //     currentMake
                        // )

                        if (data?.answer) {
                            let item: IDropdownItem

                            for (const itemIndex in listMakes) {
                                item = listMakes[+itemIndex]

                                if (
                                    typeof data?.answer === 'string' &&
                                    item?.name?.toLowerCase() ===
                                        data?.answer.toLowerCase()
                                ) {
                                    if (
                                        item.uid !== 'new_entry' &&
                                        item.name !== 'new_entry'
                                    ) {
                                        dataToSend.make_id = item.uid
                                        data.answer = item.name
                                        data.uid_current = item.uid
                                        break
                                    }
                                }
                            }
                            // console.log('after loop 1', dataToSend)
                        }

                        if (
                            currentCarMakeID &&
                            tempMakeID &&
                            tempMakeID !== currentCarMakeID
                        ) {
                            dataToSend.make_id = tempMakeID
                            data.answer = tempMakeDisplay
                            data.uid_current = tempMakeID
                        }

                        // last resort
                        if (!dataToSend.make_id) {
                            dataToSend.make_id = tempMakeID
                            data.answer = tempMakeDisplay
                            data.uid_current = tempMakeID
                        }

                        if (
                            carTitle &&
                            currentMake &&
                            carTitle.includes(currentMake) &&
                            data?.answer
                        ) {
                            carTitle = carTitle.replace(
                                currentMake,
                                `${data?.answer}`
                            )

                            updateCarTitle = true
                        }
                    }

                    if (data.id === 'model') {
                        // console.log(
                        //     'inside of here',
                        //     'currentCarModelID',
                        //     currentCarModelID,
                        //     'tempModelID',
                        //     tempModelID,
                        //     'currentModel',
                        //     currentModel
                        // )

                        if (tempModelID && tempModelID !== currentCarModelID) {
                            dataToSend.model_id = tempModelID
                            data.answer = tempModelDisplay
                            data.uid_current = tempModelID
                        } else {
                            let item: IDropdownItem

                            for (const itemIndex in listModels) {
                                item = listModels[+itemIndex]

                                if (
                                    typeof data?.answer === 'string' &&
                                    item?.name?.toLowerCase() ===
                                        data?.answer?.toLowerCase()
                                ) {
                                    if (
                                        item.uid !== 'new_entry' &&
                                        item.name !== 'new_entry'
                                    ) {
                                        dataToSend.model_id = item.uid
                                        data.answer = item.name
                                        data.uid_current = item.uid
                                        break
                                    }
                                }
                            }

                            // console.log('after loop 2', dataToSend)
                            if (!dataToSend.model_id && tempModelID) {
                                dataToSend.model_id = tempModelID
                                data.answer = tempModelDisplay
                                data.uid_current = tempModelID
                            }
                        }

                        if (
                            carTitle &&
                            currentModel &&
                            carTitle.includes(currentModel) &&
                            data?.answer
                        ) {
                            carTitle = carTitle.replace(
                                currentModel,
                                `${data?.answer}`
                            )

                            updateCarTitle = true
                        }
                    }
                }
                if (
                    data.id === 'mileage' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.mileage = +`${data.answer}`
                }

                if (data.id === 'year' && data.answer && data.answer !== '-') {
                    dataToSend.year = data.answer
                    if (
                        carTitle &&
                        currentYear &&
                        carTitle.includes(currentYear) &&
                        data?.answer
                    ) {
                        carTitle = carTitle.replace(
                            currentYear,
                            `${data?.answer}`
                        )
                        updateCarTitle = true
                    }
                }

                if (
                    data.id === 'transmission' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.transmission = data.answer
                }

                if (
                    data.id === 'color_exterior' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.color_exterior = data.answer
                }

                if (
                    data.id === 'fuel_type' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.fuel_type = data.answer
                }

                if (
                    data.id === 'drive_side' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    if (data.answer === 'Center') {
                        dataToSend.drive_side = 'Center'
                    } else if (data.answer === 'RHD') {
                        dataToSend.drive_side = 'Right'
                    } else if (data.answer === 'LHD') {
                        dataToSend.drive_side = 'Left'
                    } else {
                        dataToSend.drive_side = `${data.answer}`
                    }
                }

                if (
                    data.id === 'registration_date' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.registration_date = convertDateWithDayJs(
                        data.answer
                    )
                }

                if (
                    data.id === 'registration_number' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.registration_number = data.answer
                }

                if (
                    data.id === 'last_owner_change' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.last_owner_change = convertDateWithDayJs(
                        data.answer
                    )
                }

                if (
                    data.id === 'service_check_status' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.service_check_status = data.answer
                }

                if (
                    data.id === 'service_check_expiry' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.service_check_expiry = convertDateWithDayJs(
                        data.answer
                    )
                }

                if (
                    data.id === 'color_interior' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-' &&
                    typeof data.answer === 'string'
                ) {
                    dataToSend.color_interior = data.answer
                }

                if (
                    data.id === 'tax_due' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.tax_due = convertDateWithDayJs(data.answer)
                }

                if (
                    data.id === 'tax_status' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    dataToSend.tax_status = data.answer
                }

                if (
                    data.id === 'engine_capacity' ||
                    data.id === 'engine_size'
                ) {
                    if (
                        data.answer !== null &&
                        data.answer !== undefined &&
                        data.answer !== '-'
                    ) {
                        if (
                            (typeof data.answer === 'number' &&
                                data.answer > 100000) ||
                            (typeof data.answer === 'string' &&
                                parseInt(data.answer) > 100000)
                        ) {
                            message.error(
                                'Please add a realistic value for the engine size.'
                            )
                        } else {
                            dataToSend.engine_capacity = +data.answer

                            showroomCarDataUpdateObj.engine_capacity = {
                                id: 'engine_capacity',
                                text: 'engine size',
                                answer: +data.answer,
                            }
                        }
                    }
                }

                if (
                    data.id === 'custom_body_type' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    results.id = 'body_type'
                    results.data.text = 'body type'
                    results.data.custom = data.answer
                    dataToSend.custom_body_type = data.answer
                    dataToSend.custom_body_type = data.answer
                }

                if (
                    data.id === 'body_type' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    results.id = 'body_type'

                    dataToSend.body_type =
                        data.answer === 'SUV / 4x4' ? 'SUV' : data.answer
                }

                if (
                    data.id === 'chassis_number' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    results.id = 'chassis_number'

                    dataToSend.vin = data.answer
                }

                if (
                    data.id === 'drive_train' &&
                    data.answer !== null &&
                    data.answer !== undefined &&
                    data.answer !== '-'
                ) {
                    results.id = 'drive_train'

                    dataToSend.drive_train =
                        data.answer && data.answer === '4WD' ? '4WD' : '2WD'
                }

                if (data.id === 'title' && data.answer) {
                    results.id = 'title'

                    dataToSend.title = `${data.answer}`
                }

                if (
                    Object.keys(dataToSend).length > 0 &&
                    Object.values(dataToSend).every(
                        (val) =>
                            val !== null && val !== undefined && val !== '-'
                    )
                ) {
                    if (
                        data.id === 'year' ||
                        data.id === 'make' ||
                        data.id === 'model' ||
                        data.id === 'engine_capacity' ||
                        data.id === 'engine_size' ||
                        data.id === 'transmission' ||
                        data.id === 'drive_side'
                    ) {
                        let payloadd: IUpdateCarOverviewGridInfo = {
                            carid: payload.car_id,
                            id:
                                data.id === 'engine_size'
                                    ? 'engine_capacity'
                                    : data.id,
                            data: data?.answer ? data : results.data,
                        }

                        yield put(carActions.updatCarOverviewGridInfo(payloadd))

                        showroomCarDataUpdateObj = {
                            ...showroomCarDataUpdateObj,
                            [data.id]: {
                                ...data,
                            },
                        }
                    }

                    if (data.id === 'title' && data.answer) {
                        yield put(
                            carActions.updatCarTitleOnCreation({
                                carid: payload.car_id,
                                title: `${data.answer}`,
                            })
                        )
                    }

                    yield put(
                        actions.actions.writeTechnicalInformationSuccess(
                            results
                        )
                    )
                } else {
                    let store: RootState = yield select(getAllState)
                    let currentData =
                        store.entities.technicalInformationData
                            .technical_information[payload.tech_info_id]
                    yield put(
                        actions.actions.writeTechnicalInformationSuccess({
                            tech_info_id: payload.tech_info_id,
                            id: data.id,
                            // @ts-ignore
                            data: currentData[data.id],
                        })
                    )
                }

                // yield put(dropDownActions.reset_make_model_temps())

                // yield put(dropDownActions.setSelectedMake(null))
                // yield put(dropDownActions.setSelectedModel(null))
            }
        }

        if (updateCarTitle && carTitle) {
            dataToSend.title = carTitle
            yield put(
                carActions.updatCarTitleOnCreation({
                    carid: payload.car_id,
                    title: carTitle,
                })
            )
        }

        let api_payload: IApiUpdateCar_args = {
            car_id: payload.car_id,
            dataToUpdate: dataToSend,
        }

        let updatedCar: ICarPayload = yield call(
            api.entities.updateCar.updateCar,
            api_payload
        )

        posthog.capture('UPDATE CAR TECHNICAL INFORMATION')

        if (payload.getShowroomEntryOnSuccess) {
            if (updatedCar) {
                yield call(SetCarEntryExtraneousReduxData, {
                    car: updatedCar,
                    updateGridInfo: {
                        data: showroomCarDataUpdateObj,
                        entryID: payload.getShowroomEntryOnSuccess.entry_id,
                    },
                })
            }
        }
    } catch (error: any) {
        if (
            error.status === 401 ||
            error.status === 404 ||
            error.status === 403
        ) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: writeMultipleTechnicalInformationSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let carError: IReduxError = yield select(getCarError)

            let isOnboarding =
                history.location.pathname.match(/onboarding/g) !== null
                    ? true
                    : false

            let customErrorData: ICustomErrorData = {
                custom_message: isOnboarding
                    ? 'An error occured.'
                    : `Something went wrong.`,
                custom_user_action_text: isOnboarding
                    ? 'OK'
                    : 'Return to technical informations',
                custom_redirect_path: isOnboarding
                    ? undefined
                    : !carError
                    ? `/car/${payload.car_id}/technical-information`
                    : '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(
                actions.errorActions.writeTechnicalInformationError(customError)
            )
        }
    }
}

function* watcherWriteMultipleTechnicalInformation() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.writeMultipleTechnicalInformationRequest
        )
        yield call(writeMultipleTechnicalInformationSaga, payload)
    }
}

const write_multiple_technical_information_saga: any[] = [
    fork(watcherWriteMultipleTechnicalInformation),
]

export default write_multiple_technical_information_saga
