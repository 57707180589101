import { call, fork, put, select, take } from 'redux-saga/effects'

import * as requestActions from '../actions/requestActions'
import * as successActions from '../actions/successActions'
import * as errorActions from '../actions/errorActions'
import {
    IGetTimelineItemByIdPayloadRequest,
    ITimelineItem,
} from 'timelineModels'
import { api } from '../../services'
import { IEntryPayloadAPI } from 'IapiDataPayload'
import { ConvertAPIEntryToTimelineItemSingle } from '../../conversions/timeline/entryToTimelineItem'
import posthog from 'posthog-js'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { IAttachmentItem, INormalisedAttachmentsByID } from 'attachmentModels'
import { ConvertAttachmentAPIToAttachmentState } from '../../conversions/attachments'
import { addAttachmentsSuccess } from '../../attachments/actions/successActions'

import { selectStateUserCurrency } from '../../user/sagas/getUserData'
import { ICurencyUIDsEnum } from '../timelineEnum'
import { RootState } from 'typesafe-actions'
import { convertEntryImagesAPIToGalleryImagesState } from '../../conversions/timeline/entryImagesToStateImages'
import * as galleryActions from '../../entities/galleries/actions/index'
import { IApiGetEntryById_args } from 'ApiInterfaces'
// import { push } from 'connected-react-router'

const getCarError = (state: RootState) => state.entities.carsData.error
const getTimelineError = (state: RootState) => state.timeline.error

export function* GetEntryByIdSaga(
    payload: IGetTimelineItemByIdPayloadRequest
): any {
    try {
        //Step 1 - call the api func and keep data returned in a const

        let user_current_curr: ICurencyUIDsEnum = yield call(
            selectStateUserCurrency
        )

        let api_payload: IApiGetEntryById_args = {
            entry_id: payload.entryUID,
            car_id: payload.car_id,
            user_currency: user_current_curr,
        }

        const entry_from_api: IEntryPayloadAPI = yield call(
            api.timeline.entries.getEntriesData.getEntryById,
            api_payload
        )
        // STEP 2 . build up payload to return to reducer
        let entry_converted: ITimelineItem =
            ConvertAPIEntryToTimelineItemSingle(entry_from_api)

        if (entry_from_api.attachments) {
            if (entry_from_api.attachments.length > 0) {
                let obj: INormalisedAttachmentsByID = {}

                for (const element of entry_from_api.attachments) {
                    let i: IAttachmentItem =
                        ConvertAttachmentAPIToAttachmentState(element)

                    obj = { ...obj, [i.id]: i }
                }

                yield put(addAttachmentsSuccess(obj))
            }
        }

        if (entry_from_api.images) {
            if (entry_from_api.images.length > 0) {
                let galleryImagesObject =
                    convertEntryImagesAPIToGalleryImagesState(entry_from_api)

                yield put(
                    galleryActions.actions.setGalleryImagesSuccess(
                        galleryImagesObject
                    )
                )
            }
        }

        if (entry_from_api.costs) {
            if (entry_from_api.costs.length > 0) {
                for (const cost of entry_from_api.costs) {
                    if (cost.attachments && cost.attachments.length > 0) {
                        let length: number = cost.attachments.length - 1
                        if (cost.attachments[length].uid) {
                            yield put(
                                addAttachmentsSuccess({
                                    [cost.attachments[length].uid]:
                                        ConvertAttachmentAPIToAttachmentState(
                                            cost.attachments[length]
                                        ),
                                })
                            )
                        }
                    }
                }
            }
        }
        // STEP 3. dispatch action to reducer
        yield put(successActions.getTimelineItemByIDSuccess(entry_converted))
        yield put(successActions.setActiveEntrySuccess(entry_converted))
        // yield put(push(`/car/${payload.car_id}/history-file/entry?entryid=${res.id}`))
        // Capture event
        posthog.capture('GET TIMELINE ITEM BY ID')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: GetEntryByIdSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let carError: IReduxError = yield select(getCarError)
            let timelineError: IReduxError = yield select(getTimelineError)
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'Return to timeline',
                custom_redirect_path:
                    !carError && !timelineError
                        ? `/car/${payload.car_id}/history-file`
                        : '/garage',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.getTimelineItemByIDError(customError))
        }
    }
}

function* watcherGetEntryById() {
    while (true) {
        const { payload } = yield take(
            requestActions.getTimelineItemByIDRequest
        )

        yield call(GetEntryByIdSaga, payload)
    }
}

const get_timeline_item_by_id: any[] = [fork(watcherGetEntryById)]

export default get_timeline_item_by_id
