import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Radio } from 'antd'

import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'
// import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'

const Padding24 = styled.div`
    padding-top: 24px;
`

const ColumnAlignStart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
`

const RadioValue = styled.span`
    padding-left: 5px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 14px;
`

const StyledRadio = styled(Radio)`
    .ant-radio-inner {
        background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    }
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_duoRadioTechMobile: string
}

interface OtherProps {
    toggle: any
    item: any
    optionsList: any
    submitFunc?: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id?: string
    carid: string
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        // errors,
        // touched,
        setFieldValue,
        handleSubmit,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        optionsList,
    } = props

    let checkIfDisabled = () => {
        if (!values.single_item_edited_value_duoRadioTechMobile) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperMobile>
                <Radio.Group
                    defaultValue={
                        values.single_item_edited_value_duoRadioTechMobile
                    }
                >
                    <ColumnAlignStart>
                        <StyledRadio
                            type="radio"
                            value={optionsList[0].name}
                            onChange={() =>
                                setFieldValue(
                                    'single_item_edited_value_duoRadioTechMobile',
                                    optionsList[0].name
                                )
                            }
                            name={optionsList[0].name}
                        >
                            <RadioValue>{optionsList[0].name}</RadioValue>
                        </StyledRadio>
                        <div style={{ paddingBottom: '25px' }} />
                        <StyledRadio
                            type="radio"
                            onChange={() =>
                                setFieldValue(
                                    'single_item_edited_value_duoRadioTechMobile',
                                    optionsList[1].name
                                )
                            }
                            value={optionsList[1].name}
                            name={optionsList[1].name}
                        >
                            <RadioValue>{optionsList[1].name}</RadioValue>
                        </StyledRadio>
                    </ColumnAlignStart>
                </Radio.Group>
                <Padding24 />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={handleSubmit}
                    confirmationtext="save"
                    disabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const DuoRadioTechInfoEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_duoRadioTechMobile: props.item.answer
            ? props.item.answer
            : undefined,
    }),
    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        if (props.submitFunc && props.tech_info_id) {
            let p: IwriteTechnicalInformationPayloadReq = {
                car_id: props.carid,
                tech_info_id: props.tech_info_id,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answer: values.single_item_edited_value_duoRadioTechMobile,
                },
            }
            props.submitFunc(p)
        }

        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(DuoRadioTechInfoEditFormMobile)
