type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const AttachmentsIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.832 1.2261C11.8967 1.40249 12.9806 1.91577 13.7011 2.63632C14.4217 3.35687 14.935 4.44079 15.1114 5.50548C15.2854 6.55575 15.157 7.78264 14.3199 8.61982L9.05498 13.8847L7.99432 12.824L13.2592 7.55916C13.6104 7.20797 13.7669 6.56763 13.6315 5.75066C13.4986 4.94811 13.1083 4.1648 12.6405 3.69698C12.1727 3.22916 11.3893 2.83889 10.5868 2.70592C9.76982 2.57057 9.12948 2.72707 8.77829 3.07826L2.75251 9.10404C2.49375 9.3628 2.35848 9.81029 2.42847 10.3787C2.49798 10.9432 2.75879 11.493 3.10252 11.8367C3.44308 12.1772 3.96597 12.4159 4.50084 12.468C5.0357 12.52 5.47961 12.3797 5.75387 12.1054L10.9188 6.94045C11.3391 6.52015 11.3391 5.83893 10.9188 5.41863C10.4985 4.99833 9.81731 4.99833 9.39701 5.41863L4.99296 9.82268L3.9323 8.76201L8.33635 4.35797C9.34244 3.35188 10.9734 3.35188 11.9795 4.35797C12.9856 5.36406 12.9856 6.99502 11.9795 8.00111L6.81453 13.1661C6.13801 13.8426 5.2013 14.0432 4.35555 13.9609C3.5098 13.8786 2.65207 13.5076 2.04186 12.8973C1.43479 12.2903 1.04522 11.4188 0.939718 10.562C0.834706 9.70917 0.999826 8.7354 1.69185 8.04338L7.71763 2.0176C8.55481 1.18042 9.78171 1.05209 10.832 1.2261Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default AttachmentsIcon
