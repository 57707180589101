import { ITimelineCreateForms } from 'myModels'
import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";
import TimelineEditGenerateCreateFormMobile from '../timelineFormGenerators/timelineEditGenerateCreateFormMobile'
import { connect } from 'react-redux'
import {
    IActionPayloadTimelineCreationRequest,
    // ICostItem,
    ITimelineActionType,
    ITimelineCreationFormFieldsData,
    ITimelineDataToBeSubmittedObj,
    ITimelineEntityType,
    ITimelineItem,
    // ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'

import { RootState } from 'typesafe-actions'

import { TimelineCreationTypeIdsEnum } from '../../../../redux/timeline/timelineEnum'
import TimelineItemReturnNav from '../../../atoms/header/timelineItemReturnNav'
import { manageDataToBeSubmittedTimelineCreationRequest } from '../../../../redux/timeline/actions/requestActions'
import { IDropdownItem } from 'entityModels'
import DeletionConfirmationBottomSheet from '../../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import { withRouter, RouteComponentProps } from 'react-router-dom'

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {
    manageDataToBeSubmittedTimelineCreation: (
        payload: IActionPayloadTimelineCreationRequest
    ) => manageDataToBeSubmittedTimelineCreationRequest(payload),
}

interface Props extends RouteComponentProps<any> {
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => any
    close_create_form: any
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted?: ITimelineDataToBeSubmittedObj
    manageDataToBeSubmittedTimelineCreation: (
        payload: IActionPayloadTimelineCreationRequest
    ) => void
    submitDataRequest?: () => void
    labels_searchable_list?: IDropdownItem[] | null | undefined
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined

    is_category_specific_landing?: boolean
}

type State = {
    showConfirmQuitCreationSheet: boolean
}

class TimelineCreateManagerMobile extends React.PureComponent<Props, State> {
    _isMounted = false
    state = {
        showConfirmQuitCreationSheet: false,
    }

    // storing created item into the state
    updateItemBeingCreated = (
        fieldID: any,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => {
        this.props.manageDataToBeSubmittedTimelineCreation({
            carid: this.props.carID,
            entityType: entityType,
            action: action,
            fieldID: fieldID,
            fieldValue: value,
        })
    }

    // function for going to the next step of creation
    goToNextStep = () => {
        let {
            manageTimelineCreationFormState,
            timeline_creation_forms_current_state,
            timeline_creation_steps_object_of_indexed_list,
            data_to_be_submitted,
        } = this.props

        let steps_list: string[] | null =
            timeline_creation_forms_current_state.active_creation &&
            timeline_creation_steps_object_of_indexed_list[
                timeline_creation_forms_current_state.active_creation
            ]

        let next_index: number =
            timeline_creation_forms_current_state.current_step_index + 1

        // when creating a timeline item
        if (
            steps_list &&
            timeline_creation_forms_current_state.active_creation ===
                TimelineCreationTypeIdsEnum.add_new &&
            data_to_be_submitted &&
            data_to_be_submitted.entry &&
            data_to_be_submitted.entry.categoryID
        ) {
            if (
                next_index === 1 ||
                (next_index > 0 && next_index < steps_list.length)
            ) {
                return manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: next_index,
                    active_creation:
                        data_to_be_submitted &&
                        data_to_be_submitted.entry &&
                        data_to_be_submitted.entry.categoryID,
                })
            } else {
                return this.props.close_create_form()
            }
        }

        // when creating any other item

        if (steps_list) {
            if (
                next_index > 0 &&
                next_index < steps_list.length &&
                steps_list.length > 1
            ) {
                return manageTimelineCreationFormState({
                    isOpen: true,
                    current_step_index: next_index,
                    active_creation:
                        timeline_creation_forms_current_state.active_creation,
                })
            } else {
                return this.props.close_create_form()
            }
        }
    }

    // function for going to the previous step of creation
    goToPreviousStep = () => {
        let {
            manageTimelineCreationFormState,
            timeline_creation_forms_current_state,
            data_to_be_submitted,
        } = this.props

        let previous_index: number =
            timeline_creation_forms_current_state.current_step_index - 1

        if (previous_index >= 0) {
            //when creating a timeline item
            if (
                timeline_creation_forms_current_state.active_creation ===
                    TimelineCreationTypeIdsEnum.add_new &&
                data_to_be_submitted &&
                data_to_be_submitted.entry &&
                data_to_be_submitted.entry.categoryID
            ) {
                if (previous_index > 0) {
                    return manageTimelineCreationFormState({
                        isOpen: true,
                        current_step_index: previous_index,
                        active_creation:
                            data_to_be_submitted &&
                            data_to_be_submitted.entry &&
                            data_to_be_submitted.entry.categoryID,
                    })
                } else {
                    return manageTimelineCreationFormState({
                        isOpen: true,
                        current_step_index: previous_index,
                        active_creation: TimelineCreationTypeIdsEnum.add_new,
                    })
                }
            }

            // when creating any other item
            return manageTimelineCreationFormState({
                isOpen: true,
                current_step_index: previous_index,
                active_creation:
                    timeline_creation_forms_current_state.active_creation,
            })
        } else {
            if (this.props.entityType === 'cost' && this.props.activeEntry) {
                this.props.history.push(
                    `/car/${this.props.carID}/history-file/entry?entryid=${this.props.activeEntry.id}`
                )
            } else {
                this.props.history.push(`/car/${this.props.carID}/history-file`)
            }
            return this.props.close_create_form()
        }
    }

    // returnToCorrespondingStep = (entity, property, value) => {
    //     let {
    //         manageTimelineCreationFormState,
    //         timeline_creation_forms_current_state,
    //         data_to_be_submitted,
    //     } = this.props

    //     let corresponding_index: number

    //     if (previous_index >= 0) {
    //         //when creating a timeline item
    //         if (
    //             this.state.initial_active_creation ===
    //                 TimelineCreationTypeIdsEnum.add_new &&
    //             data_to_be_submitted &&
    //             data_to_be_submitted.entry &&
    //             data_to_be_submitted.entry.category
    //         ) {
    //             if (previous_index > 0) {
    //                 return manageTimelineCreationFormState({
    //                     isOpen: true,
    //                     current_step_index: previous_index,
    //                     active_creation:
    //                         data_to_be_submitted &&
    //                         data_to_be_submitted.entry &&
    //                         data_to_be_submitted.entry.category,
    //                 })
    //             } else {
    //                 return manageTimelineCreationFormState({
    //                     isOpen: true,
    //                     current_step_index: previous_index,
    //                     active_creation: TimelineCreationTypeIdsEnum.add_new,
    //                 })
    //             }
    //         }

    //         // when creating any other item
    //         return manageTimelineCreationFormState({
    //             isOpen: true,
    //             current_step_index: previous_index,
    //             active_creation:
    //                 timeline_creation_forms_current_state.active_creation,
    //         })
    //     } else {
    //         //closing when going back from first step
    //         return manageTimelineCreationFormState({
    //             isOpen: false,
    //             current_step_index: 0,
    //             active_creation: null,
    //         })
    //     }
    // }

    active_creation =
        this.props.timeline_creation_forms_current_state.active_creation

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let {
            close_create_form,
            timeline_creation_forms_current_state,
            timeline_creation_steps_data,
            timeline_creation_steps_object_of_indexed_list,
            data_to_be_submitted,
            labels_searchable_list,
        } = this.props

        // let { item_being_created } = this.state

        let current_index: number =
            timeline_creation_forms_current_state.current_step_index

        let steps_list: string[] | null =
            timeline_creation_forms_current_state.active_creation &&
            timeline_creation_steps_object_of_indexed_list[
                timeline_creation_forms_current_state.active_creation
            ]

        let current_step_data =
            steps_list &&
            timeline_creation_steps_data[steps_list[current_index]]

        const showSkipButton = () => {
            if (current_step_data) {
                if (
                    current_step_data.submit.entity === 'entry' &&
                    data_to_be_submitted &&
                    data_to_be_submitted.entry &&
                    !data_to_be_submitted.entry.attachments
                ) {
                    return true
                }
            }

            return false
        }

        let isHeaderContentRemoved: boolean =
            this.props.timeline_creation_forms_current_state
                .removeHeaderContent === true
                ? true
                : false

        return (
            <div
                data-attr={`${
                    current_step_data && current_step_data.submit.entity
                }-creation-manager`}
                style={{
                    position: 'absolute',
                    width: '100%',
                    minHeight: '-webkit-fill-available',
                }}
            >
                <TimelineItemReturnNav
                    chevron
                    onChevronClick={() => {
                        if (this.props.is_category_specific_landing) {
                            this.props.history.goBack()
                        } else this.goToPreviousStep()
                    }}
                    hasQuit
                    customText={
                        this.props.is_category_specific_landing ? '' : undefined
                    }
                    onQuit={() =>
                        this.setState({ showConfirmQuitCreationSheet: true })
                    }
                    dataCyIdChevron={'go-back-one-step'}
                />

                {/* <div style={{ paddingTop: '50px' }} /> */}
                {steps_list && current_step_data && (
                    <TimelineEditGenerateCreateFormMobile
                        labels_searchable_list={labels_searchable_list}
                        closeForm={close_create_form}
                        step_list={steps_list}
                        current_step_index={
                            timeline_creation_forms_current_state.current_step_index
                        }
                        isHeaderContentRemoved={isHeaderContentRemoved}
                        stepFieldsData={current_step_data.step_fields_data}
                        stepFieldIdsList={current_step_data.step_field_ids}
                        stepTitle={current_step_data.step_title}
                        stepSubtitle={current_step_data.step_subtitle}
                        stepSubtitleDetail={
                            current_step_data.step_subtitle_detail
                        }
                        submit={current_step_data.submit}
                        data_to_be_submitted={data_to_be_submitted}
                        onChange={this.updateItemBeingCreated}
                        manageSteps={this.goToNextStep}
                        // returnToCorrespondingStep={
                        //     this.returnToCorrespondingStep
                        // }
                        showSkipButton={showSkipButton()}
                        submitDataRequest={() => {
                            this.props.manageDataToBeSubmittedTimelineCreation({
                                carid: this.props.carID,
                                isReadyToSendToApi: true,
                            })
                        }}
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                        activeEntry={this.props.activeEntry}
                    />
                )}
                <DeletionConfirmationBottomSheet
                    isOpen={this.state.showConfirmQuitCreationSheet}
                    toggle={() =>
                        this.setState({ showConfirmQuitCreationSheet: false })
                    }
                    action={() => {
                        if (
                            this.props.entityType === 'cost' &&
                            this.props.activeEntry
                        ) {
                            this.props.history.push(
                                `/car/${this.props.carID}/history-file/entry?entryid=${this.props.activeEntry.id}`
                            )
                        } else {
                            this.props.history.push(
                                `/car/${this.props.carID}/history-file`
                            )
                        }
                        return close_create_form()
                    }}
                    actionCopy="Confirm cancellation"
                    titleCopy="Are you sure?"
                    detailsCopy="Cancelling now means not creating this entry and losing the data you’ve entered."
                    redBtnDataCyId="cancel_entry_creation_mobile"
                />
            </div>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TimelineCreateManagerMobile)
)
