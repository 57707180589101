import { IExternalCarsObject } from 'entityModels'
import { IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { getExternalCarsSharedWithYouREQUEST } from '../../../../redux/entities/cars/actions/loadingActions'
import { setCurrentExternalCar } from '../../../../redux/entities/cars/actions/thunks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import ReadOnlyCarHistoryFileEntry from '../../../templates/readOnly/car/readOnlyHistoryFileEntry'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    externalCarsData: IExternalCarsObject | undefined

    setCurrentExternalCar: any
    getUserDataRequest: () => void
    getExternalCarsSharedWithYouREQUEST: (car_id: string) => void
    setInitialLandingUrl: (str: string) => void
}

const dispatchProps = {
    setCurrentExternalCar: (car_id: string) => setCurrentExternalCar(car_id),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getExternalCarsSharedWithYouREQUEST: (car_id: string) =>
        getExternalCarsSharedWithYouREQUEST(car_id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

type State = {
    placeholderUrl: string
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
    }
}

class ExternalCarHistoryFileEntry extends React.Component<Props, State> {
    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        const q_params = new URLSearchParams(this.props.location.search)
        let entryid = q_params.get('entryid')
        let carid = this.props.match.params.carid

        this.props.setInitialLandingUrl(
            `/shared/car/${carid}/history-file/${entryid}}`
        )

        this.props.setCurrentExternalCar(carid)

        this.props.getExternalCarsSharedWithYouREQUEST(carid)
    }

    render() {
        let carid = this.props.match.params.carid

        let { externalCarsData, userLoggedIn } = this.props

        let currentCar =
            externalCarsData &&
            carid &&
            externalCarsData[carid] &&
            externalCarsData[carid]

        return (
            <ReadOnlyCarHistoryFileEntry
                sharedCar={currentCar}
                userLoggedIn={userLoggedIn}
            />
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ExternalCarHistoryFileEntry)
)
