import * as React from 'react'
import styled from 'styled-components'
import HelpTutorialItemMobile from '../../atoms/helpTutorialItem/helpTutorialItemMobile'
// import { Link } from 'react-router-dom'
import { device } from '../../templates/displays/devices'
import garageImage from '../../../public/assets/tutorials_replay/garage_screenshot_desktop.png'
import car1 from '../../../public/assets/tutorials_replay/car_1_screenshot_desktop.png'
// import car2 from '../../../public/assets/tutorials_replay/car_2_screenshot_desktop.png'
import task from '../../../public/assets/tutorials_replay/task_screenshot_desktop.png'

// Added Container
const Container = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Wrap = styled.section`
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    width: 90vw;

    @media ${device.ipad} {
        width: 70vw;
        justify-content: space-between;
    }
`

const TextLine = styled.div`
    font-family: 'Lato';
    font-size: 14px;
    font-weight: 500;
    display: block;
    line-height: 1.7rem;
    color: var(--text-strong, #1a1a1a);
    .link {
        font-style: normal;
        padding: 0px;
        color: #5ec3ca;
    }
`
// prev: width: 60%

const TextSection = styled.div`
    height: 100%;
    width: 90vw;
    border-left: 1px solid var(--text-strong, #1a1a1a);
    padding: 0px;
    padding-left: 20px;
    align-self: flex-end;

    @media ${device.ipad} {
        border: none;
        padding: 20px 0px 20px 10px;
        max-width: 100%;
    }
`

// left some commented code in case we decide
// to pass the data for each tutorial item from redux-localdata

// interface ITutorialItem {
//     title: string
//     btnUrl: string
//     link: string
// }

interface Props {
    // data: ITutorialItem[]
    carId: string | null
}

const HelpTutorialSectionDesktop: React.SFC<Props> = ({ carId }) => (
    <Container>
        <Wrap>
            <HelpTutorialItemMobile
                title="Garage"
                btnUrl={garageImage}
                link="/garage?tutorial=true"
            />
            <HelpTutorialItemMobile
                title="Car Overview"
                btnUrl={car1}
                link={
                    carId
                        ? `/car/${carId}?tutorial=true`
                        : '/garage?tutorial=true'
                }
                isDisabled={carId ? false : true}
                tooltipTitle={'Please add a car'}
            />
            {/* <HelpTutorialItemMobile
                title="Car Gallery"
                btnUrl={car2}
                link={carId ? `/car/${carId}/gallery?tutorial=true` : "/garage?tutorial=true"}
                isDisabled={carId ? false : true}
                tooltipTitle={'Please add a car'}
            /> */}
            <HelpTutorialItemMobile
                title="Tasks"
                btnUrl={task}
                link="/tasks?tutorial=true"
            />
            {/* {
            props.data.map(item => 
                <HelpTutorialItemMobile 
                    title={item.title}
                    btnUrl={item.btnUrl}
                    link={item.link}
                />
                )
        } */}
            <TextSection>
                <TextLine>Anything else you need help with?&nbsp;</TextLine>
                <TextLine>
                    <a
                        className="link"
                        href={'mailto:hello@custodian.club?subject=Help'}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Let us know &nbsp;
                    </a>
                    <span>and we'll take your feedback into account.</span>
                </TextLine>
            </TextSection>
        </Wrap>
    </Container>
)

export default HelpTutorialSectionDesktop
