import * as constants from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const createProposalCodeRequestError = createAction(
//     constants.CREATE_PROPOSAL_CODE_REQUEST_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const createProposalCodeRequestError = createAction(
    constants.CREATE_PROPOSAL_CODE_REQUEST_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const redeemCodeError = createAction(
//     constants.REDEEM_CODE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const redeemCodeError = createAction(
    constants.REDEEM_CODE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
