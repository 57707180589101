import * as React from 'react'
import styled from 'styled-components'
import { SortableContainer } from 'react-sortable-hoc'
import { device } from '../../templates/displays/devices'

import DraggableItemEditableMobile from '../../atoms/draggable/draggableEditableItemMobile'
import DraggableItemEditableJourneyDesktop from '../../atoms/draggable/draggableEditableItemJourneyDesktop'
import DraggableItemEditableJourneyMobile from '../../atoms/draggable/draggableEditableItemJourneyMobile'
const arrayMove = require('array-move')

const ListContainer = styled.ul<{ $isJourney?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    list-style-type: disc;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    overflow-y: ${(props) => (props.$isJourney ? 'visible' : 'scroll')};

    @media ${device.beyond_ipad_mobile} {
        ${(props) => (!props.$isJourney ? 'display: none' : undefined)};
    }
`

const SortableContainerCustom = SortableContainer(
    ({
        children,
        isJourney,
    }: {
        children: React.ReactNode
        isJourney?: boolean
    }) => <ListContainer $isJourney={isJourney}>{children}</ListContainer>
)

type Props = {
    data_object: IObject
    ids_list: string[]
    formikprops: {
        handleChange: (e: React.ChangeEvent<any>) => void
        values: {
            [key: string]: string
        }
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined
        ) => void
    }
    giveNewArrayOrder?: any
    isJourney?: boolean
    isJourneyMobile?: boolean
}

interface IObject {
    [key: string]: EnumerableObject
}

type EnumerableObject = {
    id: string
    text: string
    order: number
}

type State = {
    items: EnumerableObject[]
}

const sortList = (list: EnumerableObject[]) => {
    return list.sort(
        (a: EnumerableObject, b: EnumerableObject) => a.order - b.order
    )
}

class DragNSortEditableMobile extends React.Component<Props, State> {
    state = {
        items: [],
    }

    componentDidMount() {
        let list = this.props.ids_list.map((id: string, index) => {
            return this.props.data_object[id]
        })

        let ordered_list = sortList(list)

        this.setState({
            items: ordered_list,
        })
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        // redux action here too
        this.setState(({ items }: any) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }))
        this.props.giveNewArrayOrder &&
            this.props.giveNewArrayOrder(this.state.items)
    }

    render() {
        const { items } = this.state
        const { formikprops, isJourney, isJourneyMobile } = this.props

        return (
            <React.Fragment>
                {items.length > 1 && (
                    <SortableContainerCustom
                        onSortEnd={this.onSortEnd}
                        useDragHandle
                        isJourney={isJourney}
                    >
                        {items.map((item: any, index) =>
                            isJourney ? (
                                <React.Fragment
                                    key={`${item?.id}_${index}_drag_n_sort_mobile`}
                                >
                                    {isJourneyMobile ? (
                                        <DraggableItemEditableJourneyMobile
                                            formikprops={formikprops}
                                            index={index}
                                            item={item}
                                            key={index}
                                        />
                                    ) : (
                                        <DraggableItemEditableJourneyDesktop
                                            formikprops={formikprops}
                                            index={index}
                                            item={item}
                                            key={index}
                                        />
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment
                                    key={`${item?.id}_${index}_drag_n_sort_mobile`}
                                >
                                    <DraggableItemEditableMobile
                                        formikprops={formikprops}
                                        index={index}
                                        item={item}
                                        key={index}
                                    />
                                </React.Fragment>
                            )
                        )}
                    </SortableContainerCustom>
                )}
            </React.Fragment>
        )
    }
}

export default DragNSortEditableMobile
