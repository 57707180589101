import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { IJourneySectionID, IRedirectCardsCopy } from 'journeyInterfaces'
import JourneyStartIcon from '../../../../atoms/icons/components/journey/playIcon'
import { motion } from 'framer-motion'
import { RenderJourneyIcon } from '../../../../atoms/icons/components/journey'
import pattern from '../../../../../public/assets/images/journey/pastCars/pattern.svg'

const Wrapper = styled.div<IStyle>`
    width: 100%;
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    align-self: stretch;
    border-radius: 16px;
    border: ${(props) =>
        `1px solid ${
            journey_colours[props.$theme].section[props.$sectionID].primary_300
        }`};
    background: ${(props) =>
        `linear-gradient(89deg, ${
            journey_colours[props.$theme].section[props.$sectionID].primary_50
        } 0%, ${colours[props.$theme].background_default} 100%)`};
`

const WrapperCustom = styled.div<IStyle>`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    border-radius: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 164px;
    width: 100%;
    position: relative;
`

const ImgCustom = styled.img`
    position: absolute;
    width: 95%;
    top: 0;
    bottom: 0;
    border-radius: 16px;
`

const StartWrapper = styled.div`
    display: flex;
    /* height: 64px; */
    align-items: center;
    gap: 16px;
    align-self: stretch;
`

const EndWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`

const IconLowOpacityWrapper = styled.div<IStyle>`
    background-color: ${(props) =>
        journey_colours[props.$theme].section[props.$sectionID].primary_500_16};
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
    gap: 5px;
`

const Title = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    align-self: stretch;
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_900
        }`};
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 375px) {
        font-size: 13px;
        line-height: 16px;
    }
`

const Description = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    align-self: stretch;
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_900
        }`};
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`

const StartNowButton = styled(motion.button)<IStyle>`
    all: unset;
    display: flex;
    padding: 12px 0px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
    background: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID]
                .primary_500_16
        }`};
`
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 1px;
`

const StartButtonText = styled.p<IStyle>`
    margin: 0;
    padding: 0;
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_600
        }`};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const IconWrapper = styled.div<IStyle>`
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 10px;
    background: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID]
                .primary_500_50
        }`};
`

const DismissButton = styled(motion.button)<IStyle>`
    all: unset;
    display: flex;
    width: 64px;
    padding: 14px 12px;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID]
                .primary_500_08
        }`};
`
const DismissButtonText = styled.p<IStyle>`
    margin: 0;
    padding: 0;
    color: ${(props) =>
        `${
            journey_colours[props.$theme].section[props.$sectionID].primary_600
        }`};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
`

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
}

export default function JourneyRedirectCardMobile({
    sectionID,
    copyData,
    onStartNow,
    onDismiss,
    dataPH,
}: {
    sectionID: IJourneySectionID
    copyData: IRedirectCardsCopy
    onStartNow: () => void
    onDismiss: () => void
    dataPH: string
}) {
    const { theme } = useThemes()

    if (sectionID === 'past_cars') {
        return (
            <WrapperCustom
                onClick={onStartNow}
                $theme={theme}
                $sectionID={sectionID}
                data-ph={dataPH}
            >
                <ImgCustom
                    style={{
                        height: '164px',
                        objectFit: 'cover',
                        zIndex: 0,
                    }}
                    src={pattern}
                />

                <div style={{ zIndex: 2 }}>
                    <JourneyStartIcon
                        color={
                            journey_colours[theme].section[sectionID]
                                .primary_600
                        }
                        size="40"
                    />
                </div>

                <div style={{ paddingTop: '12px' }} />

                <Title
                    style={{ textAlign: 'center', zIndex: 2 }}
                    $theme={theme}
                    $sectionID={sectionID}
                >
                    {copyData[sectionID].title}
                </Title>
                <div style={{ paddingTop: '8px' }} />
                <Description
                    style={{
                        textAlign: 'center',
                        zIndex: 2,
                        paddingLeft: '24px',
                        paddingRight: '24px',
                    }}
                    $theme={theme}
                    $sectionID={sectionID}
                >
                    {copyData[sectionID].description}
                </Description>
            </WrapperCustom>
        )
    } else
        return (
            <Wrapper $theme={theme} $sectionID={sectionID} data-ph={dataPH}>
                <StartWrapper>
                    <IconLowOpacityWrapper
                        id="lalala"
                        $theme={theme}
                        $sectionID={sectionID}
                    >
                        <IconWrapper $theme={theme} $sectionID={sectionID}>
                            <RenderJourneyIcon
                                size={'32'}
                                id={sectionID}
                                color={colours[theme].background_default}
                            />
                        </IconWrapper>
                    </IconLowOpacityWrapper>
                    <TextWrapper>
                        <Title $theme={theme} $sectionID={sectionID}>
                            {copyData[sectionID].title}
                        </Title>
                        <Description $theme={theme} $sectionID={sectionID}>
                            {copyData[sectionID].description}
                        </Description>
                    </TextWrapper>
                </StartWrapper>
                <EndWrapper>
                    <DismissButton
                        $theme={theme}
                        $sectionID={sectionID}
                        onClick={onDismiss}
                        whileTap={{ scale: 1.1 }}
                    >
                        <DismissButtonText
                            $theme={theme}
                            $sectionID={sectionID}
                        >
                            Dismiss
                        </DismissButtonText>
                    </DismissButton>
                    <StartNowButton
                        $theme={theme}
                        $sectionID={sectionID}
                        onClick={onStartNow}
                        whileTap={{ scale: 1.1 }}
                    >
                        <ButtonContainer>
                            <StartButtonText
                                $theme={theme}
                                $sectionID={sectionID}
                            >
                                Start now
                            </StartButtonText>
                            <JourneyStartIcon
                                color={
                                    journey_colours[theme].section[sectionID]
                                        .primary_600
                                }
                                size="16"
                            />
                        </ButtonContainer>
                    </StartNowButton>
                </EndWrapper>
            </Wrapper>
        )
}
