import './styles.css'
import styled, { css } from 'styled-components'
import { device } from '../../templates/displays/devices'
import { Field } from 'formik'
import { forwardRef, useState } from 'react'
import NativeDatePicker from '../datePicker'
import { isSafari } from 'react-device-detect'
import colours, { ITheme } from '../../../providers/theme/colours'

interface IInputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    theme: ITheme
    name?: string
    value?: string | number | undefined
    title?: string
    placeholder?: string
    helperText?: string
    handleChange?: (e: any) => any
    type?: any
    onBlur?: any
    onFocus?: any
    onChange?: any
    onEnterKeyPress?: any
    disabled?: boolean
    desktop?: boolean | string
    id?: string
    tabindex?: number
    isnarrow?: boolean
    formikprops?: any
    font_family?: string
    width?: string
    mobile_width?: string
    ipad_width?: string
    fieldId?: string
    fontSize?: string
    textTransform?: string
    dataCyId?: string
    paddingLeft?: string
    linecolor?: string
    step?: string
    min?: string
    leftAdornment?: React.ReactNode
    rightAdornment?: React.ReactNode
    isSimpleDate?: boolean
    backgroundColor?: string
    customActiveColour?: string
    labelNotRaised?: boolean
    restrictFlipOfDatePopUp?: boolean
    custom_date_format?: 'MM/yyyy' | undefined // this lowcase y for year is enforced by the library -- we can add more custom types supported in future if we want to
    customPlaceholderWhenLabelRaised?: string
}

interface StandardInputProps {
    $theme: ITheme
    disabled?: boolean
    desktop?: boolean | string
    tabindex?: number | string
    $isnarrow?: boolean
    font_family?: string
    width?: string
    height?: string | number
    mobile_width?: string
    ipad_width?: string
    fontSize?: string
    $textTransform?: string
    dataCyId?: string
    paddingleft?: string
    linecolor?: string
    backgroundColor?: string
    customActiveColour?: string
}

type ILabelStyle = {
    raise: boolean
    backgroundColor?: string
    customActiveColour?: string
    $theme: ITheme
}

const inputStyles = css<StandardInputProps>`
    border: none;
    color: ${(props) => colours[props.$theme].text_strong};
    width: ${(props) => (props.width ? props.width : '100%')};
    height: 100%;
    padding: 0 14px;
    background-color: transparent;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
    font-family: ${(props) => props.font_family ?? 'Lato'};
    text-transform: ${(props) => props.$textTransform ?? 'none'};
    flex: 1;

    ::placeholder {
        font-family: Lato-Light;
        color: ${(props) => colours[props.$theme].text_default};
        text-transform: capitalize;
        font-size: ${(props) =>
            props.$textTransform
                ? props.fontSize ?? '14px'
                : props.fontSize ?? '16px'};
    }

    @media ${device.desktop} {
        font-size: ${(props) =>
            props.$textTransform
                ? props.fontSize ?? '14px'
                : props.fontSize ?? '16px'};
    }

    @media ${device.ipad} {
        font-size: ${(props) =>
            props.$textTransform
                ? props.fontSize ?? '14px'
                : props.fontSize ?? '16px'};
        width: ${(props) => (props.ipad_width ? props.ipad_width : '100%')};
    }

    @media ${device.mobile} {
        width: ${(props) => (props.mobile_width ? props.mobile_width : '100%')};
        font-size: ${(props) =>
            props.$textTransform
                ? props.fontSize ?? '12px'
                : props.fontSize ?? '14px'};
    }
`

const Wrapper = styled.div`
    width: 100%;
`

const FormWrapper = styled.div<StandardInputProps>`
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : colours[props.$theme].background_default};
    width: ${(props) => (props.width ? props.width : '100%')};

    padding: 0;
    height: ${(props) => (props.height ? props.height : '50px')};

    padding-left: ${(props) => (props.paddingleft ? props.paddingleft : '0')};

    opacity: ${(props) => (props.disabled ? '0.8' : '1')};
    display: flex;
    align-items: center;

    position: relative;
    display: flex;
    border: ${(props) =>
        `${props.linecolor ?? colours[props.$theme].text_muted} 1px solid`};
    border-radius: 4px;

    @media ${device.mobile} {
        width: ${(props) => (props.mobile_width ? props.mobile_width : '100%')};
    }

    @media ${device.ipad} {
        width: ${(props) => (props.ipad_width ? props.ipad_width : '100%')};
    }

    &:focus-within {
        border-color: ${(props) =>
            props.customActiveColour ?? colours[props.$theme].primary};
    }

    & input:focus ~ label {
        top: 0%;
        left: 0;
        transform: translate(8px, -50%) scale(0.8);
        color: ${(props) =>
            props.customActiveColour ?? colours[props.$theme].primary};
        min-width: auto;
    }
    width: 100%;
`

const Label = styled.label<ILabelStyle>`
    position: absolute;
    ${(props) => `
        top: ${props.raise ? '0%' : '50%'};
        transform-origin: top left;
        transform: ${
            props.raise
                ? `translate(8px, -50%) scale(0.8)`
                : `translate(8px, -50%) scale(1)`
        };
        left: 0;
        min-width: ${props.raise ? 'auto' : '110px'};
        background-color:${
            props.backgroundColor
                ? props.backgroundColor
                : colours[props.$theme].background_default
        };`}

    transition: 0.15s;

    color: ${(props) => colours[props.$theme].text_default};
    font-size: 1.1em;
    padding: 0 8px;
    pointer-events: none;
    font-family: Lato;

    &:first-letter {
        text-transform: uppercase;
    }
`

const HelperText = styled.div<{ $theme: ITheme }>`
    color: ${(props) => colours[props.$theme].text_default};
    font-size: 12px;
    padding-top: 8px;
    padding-left: 12px;
`

const StandardInput = styled.input`
    ${inputStyles}
`

const FormikInput = styled(Field)`
    ${inputStyles}
`

const AdornmentWrapper = styled.div<{ $theme: ITheme }>`
    padding: 0 14px;
    display: flex;
    align-items: center;
    color: ${(props) => colours[props.$theme].text_muted};

    @media (max-width: 350px) {
        padding: 0 10px;
    }
`
const AdornmentWrapperAbsolute = styled.div<{ $theme: ITheme }>`
    padding: 0 4px;
    display: flex;
    position: absolute;
    align-items: center;
    color: ${(props) => colours[props.$theme].text_muted};
    z-index: 0;
    right: 10px;

    @media (max-width: 350px) {
        padding: 0 10px;
    }
`

export const StandardInputField = forwardRef<
    HTMLInputElement,
    IInputFieldProps
>(
    (
        {
            name,
            value,
            title,
            placeholder,
            helperText,
            type,
            onBlur,
            onChange,
            onEnterKeyPress,
            id,
            disabled,
            tabindex,
            isnarrow,
            desktop,
            onFocus,
            formikprops,
            font_family,
            width,
            height,
            fieldId,
            fontSize,
            textTransform,
            paddingLeft,
            dataCyId,
            linecolor,
            step,
            rightAdornment,
            mobile_width,
            ipad_width,
            defaultValue,
            backgroundColor,
            customActiveColour,
            theme,
            ...rest
        },
        forwardedRef
    ) => {
        return (
            <Wrapper>
                <FormWrapper
                    $theme={theme}
                    backgroundColor={backgroundColor}
                    disabled={disabled}
                    height={height ?? height}
                    linecolor={linecolor}
                    customActiveColour={customActiveColour}
                >
                    <StandardInput
                        $theme={theme}
                        ref={forwardedRef}
                        id={fieldId ? fieldId : id}
                        name={name}
                        type={type ? type : 'text'}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange ?? formikprops?.handleChange}
                        onKeyPress={(e: any) => {
                            if (e?.which === 13) {
                                if (onEnterKeyPress) {
                                    onEnterKeyPress()
                                } else {
                                    if (isSafari) {
                                        e.preventDefault()
                                    }
                                }
                            } else if (e?.key === 'Enter') {
                                if (onEnterKeyPress) {
                                    onEnterKeyPress()
                                } else {
                                    if (isSafari) {
                                        e.preventDefault()
                                    }
                                }
                            }
                        }}
                        onBlur={onBlur ?? formikprops?.handleBlur}
                        desktop={desktop}
                        disabled={disabled}
                        tabIndex={tabindex}
                        paddingleft={paddingLeft}
                        $isnarrow={isnarrow ? true : false}
                        font_family={font_family && font_family}
                        width={width && width}
                        mobile_width={mobile_width && mobile_width}
                        ipad_width={ipad_width && ipad_width}
                        fontSize={fontSize}
                        $textTransform={textTransform}
                        data-attr={dataCyId ? dataCyId : ''}
                        linecolor={linecolor}
                        enterKeyHint="done"
                        step={step}
                        onFocus={onFocus}
                        {...rest}
                    />
                    {placeholder && (
                        <Label
                            $theme={theme}
                            backgroundColor={backgroundColor}
                            raise={value === 0 ? true : !!value}
                        >
                            {placeholder}
                        </Label>
                    )}
                    {rightAdornment && (
                        <AdornmentWrapper $theme={theme}>
                            {rightAdornment}
                        </AdornmentWrapper>
                    )}
                </FormWrapper>
                {helperText && (
                    <HelperText $theme={theme}>{helperText}</HelperText>
                )}
            </Wrapper>
        )
    }
)

export const TimeInputField = forwardRef<HTMLInputElement, IInputFieldProps>(
    (
        {
            name,
            value,
            title,
            placeholder,
            helperText,
            type,
            onBlur,
            onChange,
            onEnterKeyPress,
            id,
            disabled,
            tabindex,
            isnarrow,
            desktop,
            onFocus,
            formikprops,
            font_family,
            width,
            fieldId,
            fontSize,
            textTransform,
            paddingLeft,
            dataCyId,
            linecolor,
            step,
            min,
            mobile_width,
            ipad_width,
            rightAdornment,
            isSimpleDate,
            backgroundColor,
            labelNotRaised,
            height,
            restrictFlipOfDatePopUp,
            customActiveColour,
            custom_date_format,
            customPlaceholderWhenLabelRaised,
            theme,
        },
        forwardedRef
    ) => {
        const [raiseOnBlur, setRaiseLabelOnBlur] = useState(false)

        return (
            <Wrapper>
                <FormWrapper
                    $theme={theme}
                    disabled={disabled}
                    backgroundColor={'transparent'}
                    height={height ?? height}
                    linecolor={linecolor}
                    customActiveColour={customActiveColour}
                >
                    <NativeDatePicker
                        onChange={(date: string) => {
                            if (!disabled) {
                                if (onChange !== undefined) {
                                    onChange(date)
                                }
                            }
                        }}
                        value={value}
                        disabled={disabled}
                        fontSize={fontSize}
                        font_family={font_family}
                        ipad_width={ipad_width}
                        mobile_width={mobile_width}
                        textTransform={textTransform}
                        width={width}
                        placeholder={
                            customPlaceholderWhenLabelRaised ??
                            placeholder ??
                            'DD/MM/YYYY'
                        }
                        isSimpleDate={isSimpleDate}
                        restrictFlip={restrictFlipOfDatePopUp}
                        onFocus={() => setRaiseLabelOnBlur(true)}
                        onBlur={() => setRaiseLabelOnBlur(false)}
                        custom_date_format={custom_date_format}
                    />

                    {placeholder && (
                        <Label
                            $theme={theme}
                            backgroundColor={backgroundColor}
                            raise={
                                labelNotRaised && !raiseOnBlur ? false : true
                            }
                        >
                            {placeholder}
                        </Label>
                    )}
                    {rightAdornment && (
                        <AdornmentWrapperAbsolute $theme={theme}>
                            {rightAdornment}
                        </AdornmentWrapperAbsolute>
                    )}
                </FormWrapper>
                {helperText && (
                    <HelperText $theme={theme}>{helperText}</HelperText>
                )}
            </Wrapper>
        )
    }
)

const FormikInputField = forwardRef<HTMLInputElement, IInputFieldProps>(
    (
        {
            name,
            value,
            title,
            placeholder,
            helperText,
            type,
            onBlur,
            onChange,
            onEnterKeyPress,
            id,
            disabled,
            tabindex,
            isnarrow,
            desktop,
            onFocus,
            formikprops,
            font_family,
            width,
            height,
            fieldId,
            fontSize,
            textTransform,
            paddingLeft,
            dataCyId,
            linecolor,
            step,
            rightAdornment,
            mobile_width,
            ipad_width,
            customActiveColour,
            theme,
            backgroundColor,
        },
        forwardedRef
    ) => {
        return (
            <Wrapper>
                <FormWrapper
                    style={{ opacity: disabled ? 0.5 : 1 }}
                    height={height && height}
                    customActiveColour={customActiveColour}
                    $theme={theme}
                    backgroundColor={backgroundColor}
                >
                    <FormikInput
                        className={'input_formik_styled_modal'}
                        $theme={theme}
                        ref={forwardedRef}
                        id={fieldId ? fieldId : id}
                        name={name}
                        type={type ? type : 'text'}
                        value={value && value}
                        onKeyUp={
                            onChange
                                ? onChange
                                : formikprops
                                ? formikprops.handleChange
                                : onChange
                        }
                        onKeyPress={(e: any) => {
                            if (e?.which === 13) {
                                if (onEnterKeyPress) {
                                    onEnterKeyPress()
                                } else {
                                    if (isSafari) {
                                        e.preventDefault()
                                    }
                                }
                            } else if (e?.key === 'Enter') {
                                if (onEnterKeyPress) {
                                    onEnterKeyPress()
                                } else {
                                    if (isSafari) {
                                        e.preventDefault()
                                    }
                                }
                            }
                        }}
                        onBlur={
                            onBlur
                                ? onBlur
                                : formikprops
                                ? formikprops.handleBlur
                                : onBlur
                        }
                        desktop={desktop}
                        disabled={disabled ? 'disabled' : ''}
                        tabIndex={tabindex}
                        paddingleft={paddingLeft}
                        $isnarrow={isnarrow ? true : false}
                        font_family={font_family && font_family}
                        width={width && width}
                        height={height && height}
                        mobile_width={mobile_width && mobile_width}
                        ipad_width={ipad_width && ipad_width}
                        fontSize={fontSize}
                        $textTransform={textTransform}
                        data-attr={dataCyId ? dataCyId : ''}
                        linecolor={linecolor}
                        enterKeyHint="done"
                        step={step}
                        onFocus={onFocus}
                    />
                    {placeholder && (
                        <Label
                            $theme={theme}
                            raise={
                                type === 'number' &&
                                value !== undefined &&
                                value !== null
                                    ? true
                                    : !!value
                            }
                            backgroundColor={backgroundColor}
                        >
                            {placeholder}
                        </Label>
                    )}
                    {rightAdornment && (
                        <AdornmentWrapper $theme={theme}>
                            {rightAdornment}
                        </AdornmentWrapper>
                    )}
                </FormWrapper>
                {helperText && (
                    <HelperText $theme={theme}>{helperText}</HelperText>
                )}
            </Wrapper>
        )
    }
)

function InputField(props: IInputFieldProps) {
    if (props.type === 'date') {
        return <TimeInputField {...props} />
    } else {
        return <FormikInputField {...props} />
    }
}

export default InputField
