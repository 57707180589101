import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Sheet from 'react-modal-sheet'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'

import ScrollLock from 'react-scrolllock'
import BigRedDeletionButton from '../../Button/bigRedDeletionButton'
import { device } from '../../../templates/displays/devices'

type IStyle = {
    $customHeight?: string
}

const CustomSheet = styled(Sheet)<IStyle>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '280px !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;
    }
    .react-modal-sheet-header {
        height: 10px !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 0px !important;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`
const Wrapper = styled.div`
    padding: 30px;
    padding-top: 20px;
`
const Title = styled.div`
    font-size: 24px;
    line-height: 40px;
    font-family: 'Lato-semibold';
    color: var(--text-strong, #1a1a1a);
`
const Details = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato';
    color: var(--text-default, #666666);
`

const Padding = styled.div`
    padding-top: 10px;
`

const ButtonContainer = styled(motion.div)``

interface IProps {
    action: any
    actionCopy: string
    titleCopy: string
    detailsCopy: string
    toggle: any
    isOpen: boolean
    customHeight?: string
    redBtnDataCyId?: string
}

const DeletionConfirmationBottomSheet: React.SFC<IProps> = (props) => {
    const {
        action,
        actionCopy,
        titleCopy,
        detailsCopy,
        toggle,
        isOpen,
        customHeight,
        redBtnDataCyId,
    } = props

    return (
        <IpadAndMobileDisplay>
            {isOpen && <ScrollLock />}
            <CustomSheet
                isOpen={isOpen}
                onClose={toggle}
                $customHeight={customHeight}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Wrapper data-attr="confirm-deletion-sheet">
                        <Title>{titleCopy}</Title>
                        <Padding />
                        <Details>{detailsCopy}</Details>
                        <Padding />
                        <Padding />
                        <Padding />
                        <ButtonContainer>
                            <BigRedDeletionButton
                                text={actionCopy}
                                onClick={() => {
                                    setTimeout(() => {
                                        action()
                                    }, 50)
                                    toggle()
                                }}
                                dataCyId={redBtnDataCyId}
                            />
                        </ButtonContainer>
                    </Wrapper>
                </Sheet.Container>
                <Sheet.Backdrop
                    data-attr="car-overview-menu-backdrop-overlay"
                    onTap={() => {
                        setTimeout(() => {
                            toggle()
                        }, 50)
                    }}
                />
            </CustomSheet>
        </IpadAndMobileDisplay>
    )
}

export default DeletionConfirmationBottomSheet
