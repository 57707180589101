import {
    Route,
    // Redirect,
    RouteProps,
} from 'react-router-dom'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { editFormsActions } from '../../../redux/editForms/reducer'

import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'

function mapStateToProps(state: RootState) {
    return {
        garage_id:
            state.user.userLoggedIn && state.user.userLoggedIn.owns_garage?.uid,
        userLoggedIn: state.user.userLoggedIn,
        garage_data: state.entities.garagesData.garages,
        isGarageLoading: state.entities.garagesData.loading,
        isCarLoading: state.entities.carsData.loading,
        isUserLoading: state.user.loading,
        isAddACarFormOpened: state.editForms.formsData.addCarForm.isOpen,
    }
}

const dispatchProps = {
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    toggleAddACarForm: (isOpen: boolean) =>
        editFormsActions.toggleAddACarForm(isOpen),
    getUserDataRequest: () => getCurrentUserDataRequest(),
}

interface GetGaragePrivateRouteProps extends RouteProps {
    // tslint:disable-next-line:no-any
    component: any
    store: any
}

interface OtherProps {
    garage_data: any
    isGarageLoading: any
    garage_id: string | null
    userLoggedIn: any
    isAddACarFormOpened: boolean
    toggleAddACarForm: any
    isUserLoading: boolean
    isCarLoading: boolean
    getGarageCarsDataRequest: any
    getUserDataRequest: () => void
}

const GetGaragePrivateRoute = (
    props: OtherProps & GetGaragePrivateRouteProps
) => {
    const { component: Component, store, ...rest } = props

    // const userId = userIdFromDecodedToken
    // const userLoggedIn = store.getState().user.userLoggedIn

    // const authorizePath = userId !== false || userLoggedIn !== null

    // if (userLoggedIn && userLoggedIn.owns_garage.uid) {
    //     getGarageCarsDataRequest(userLoggedIn.owns_garage.uid)
    // } else if (userId) {
    //     props.getUserDataRequest(userId)
    // }
    // userId !== false && props.getUserDataRequest(userId)

    return (
        <Route
            {...rest}
            render={
                (props) => {
                    return <Component {...rest} />
                }

                //     authorizePath !== false ? (
                //         <div>
                //             <Component {...rest} />
                //         </div>
                //     ) : (
                //         <Redirect
                //             to={{
                //                 pathname: '/signin',
                //                 state: { from: props.location },
                //             }}
                //         />
                //     )
            }
        />
    )
}

export default connect(mapStateToProps, dispatchProps)(GetGaragePrivateRoute)
