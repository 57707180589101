import styled from 'styled-components'
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import Icon from '../../../atoms/icons'
import { IDirectShareOwnedItem, ISharingPermissionIDS } from 'entityModels'
// import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import CarSharePermissionTableCheckboxesMobile from '../../../molecules/permissionTables/carShare/carSharePermissionTableCheckboxesMobile'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { motion } from 'framer-motion'
import { BasicEditOrCreateModeSingleFieldMobile } from '../../../molecules/editOrCreateModeSingleFields'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import FadedSlower from '../../../templates/animated/FadedSlower'
import BorderAndIconButton from '../../../atoms/Button/borderAndIconButton'
import { device } from '../../../templates/displays/devices'
// import { BasicEditOrCreateModeSingleFieldMobile } from '../../../molecules/editOrCreateModeSingleFields'

const Container = styled.div`
    padding: 20px;
    padding-top: 30px;
    background-color: var(--bg-color, #fff);
    @media ${device.ipad} {
        padding: 30px;
    }
`

const Title = styled.div`
    font-family: 'Lato';
    font-size: 18px;
    text-transform: capitalize;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 20px;
`

const SubTitle = styled.div`
    font-family: 'Lato';
    font-size: 15px;
    color: var(--text-darker, #616161);
    padding-bottom: 30px;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
`

const Col = styled.div`
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
`

const RoundedSquare = styled.div`
    border: 1px solid var(--text-darker, #616161);
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-muted);
    width: 23px;
    height: 23px;
`

const InfoTxt = styled.div`
    font-family: 'Lato';
    font-size: 14px;
    color: var(--text-strong, #1a1a1a);
    width: auto;
    padding-bottom: 10px;
`

const Warning = styled.div`
    font-family: 'Lato';
    font-size: 12px;
    padding-bottom: 20px;
    margin-top: -15px;
    color: var(--error, #df6f6f);
    font-style: italic;
    padding-left: 10px;
`

const AdditionalInfoTxt = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    font-family: 'Lato';
`

const AdditionalInfoTxt2 = styled.div`
    color: var(--text-darker, #616161);
    font-size: 13px;
    font-family: 'Lato-Italic';
`

const Padding = styled.div`
    padding-top: 20px;
`

const Padding4 = styled.div`
    padding-top: 40px;
`

const AddShareBtnWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const InfoTxtSmall = styled(motion.div)`
    font-family: 'Lato';
    font-size: 13px;
    padding-left: 10px;
    color: var(--text-default, #666666);

    cursor: pointer;
`

const SaveLoadingFill = styled.div<{ hasBg: boolean }>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary, #5ec3ca);
    border-radius: 5px;
    height: 55px;
    width: 85vw;
    background-color: var(--primary_08);
    transform: ${(props) => (props.hasBg ? 'scale(1, 1)' : 'scale(0, 1)')};
    transform-origin: left;
    transition-duration: 700ms;
    z-index: 1;
`
const SaveLoadingText = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 55px;
    width: 85vw;
    border: 1px solid var(--primary, #5ec3ca);
    color: var(--primary, #5ec3ca);
    z-index: 2;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

type State = { showSavedInfo: boolean; shareChanges: string; showDone: boolean }

interface Props {
    share_being_edited: IDirectShareOwnedItem
    handlePrivateShareTitleChange?: any
    handlePermissions: (p: ISharingPermissionIDS) => any
    handleSubmit: any
    formType: 'edit' | 'create' | 'readOnly'
    noPermissionDetails?: boolean
    isGarageShare?: boolean
    isSaveActive?: boolean
    onDeleteShare?: any
    shareChanges?: string
    sharesLoading?: boolean
}

class PrivateShareFormManagerMobile extends React.PureComponent<
    Props,
    State,
    {}
> {
    state = { showSavedInfo: false, shareChanges: '', showDone: false }

    componentDidMount() {}

    componentDidUpdate(prevProps: Readonly<Props>): void {
        let { sharesLoading, shareChanges } = this.props

        if (sharesLoading !== prevProps.sharesLoading) {
            // if api was called and loader is true
            if (sharesLoading === true) {
                // start animation
                this.setState({
                    showSavedInfo: true,
                    showDone: false,
                })

                // store share changes
                shareChanges && this.setState({ shareChanges: shareChanges })
            }

            // if api call ended and loader is false again
            if (sharesLoading === false) {
                // wait 800ms to remove animation
                setTimeout(
                    () =>
                        this.setState({
                            showSavedInfo: false,
                        }),
                    800
                )

                // wait 500 to start showing Done text
                setTimeout(
                    () =>
                        this.setState({
                            showDone: true,
                        }),
                    500
                )

                // wait 300 to remove Done text
                this.state.showDone &&
                    setTimeout(
                        () =>
                            this.setState({
                                showDone: false,
                            }),
                        300
                    )

                // if save is active again, clear Done
                this.props.isSaveActive &&
                    this.setState({
                        showSavedInfo: false,
                        showDone: false,
                    })
            }
        }
    }

    render() {
        let {
            share_being_edited,
            handlePrivateShareTitleChange: handleTitleChange,
            handlePermissions,
            handleSubmit,
            formType,
            noPermissionDetails,
            isGarageShare,
            isSaveActive,
            onDeleteShare,
        } = this.props

        let shareTitleData: IEditOrCreateModeSingleFieldsProps = {
            value_start: share_being_edited.title
                ? share_being_edited.title
                : '',
            title: 'Share title',
            id: 'desktop_title_share',
            onChangeFunc: (id: string, value: string) => {
                handleTitleChange(id, value)
            },
            charLimit: [2, 50],
        }

        return (
            <Container
                style={{ paddingTop: formType === 'edit' ? '0px' : '30px' }}
            >
                {formType === 'create' ? (
                    <>
                        <Title>Permissions</Title>
                        {isGarageShare && (
                            <Warning>
                                *All permissions will apply to all cars in you
                                garage.
                            </Warning>
                        )}
                        <SubTitle>
                            Please select what you would like to share. <br />
                            You can change this at any time.
                        </SubTitle>
                        <Row>
                            <RoundedSquare>
                                <Icon
                                    icon="grey_check"
                                    width="14px"
                                    height="11px"
                                />
                            </RoundedSquare>

                            <div style={{ paddingLeft: '20px' }} />
                            <Col>
                                <AdditionalInfoTxt>
                                    Car Profile *
                                </AdditionalInfoTxt>
                                <AdditionalInfoTxt2>
                                    Overview, Gallery, Technical Information
                                </AdditionalInfoTxt2>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                paddingLeft: '40px',
                                paddingTop: '10px',
                            }}
                        >
                            <CustomAnimatedCheckboxField
                                extra_small
                                onChange={() => {}}
                                name={'car_gallery'}
                                checked={
                                    share_being_edited.active_permission_ids.includes(
                                        'car gallery'
                                    )
                                        ? false
                                        : true
                                }
                                onClick={() => {
                                    this.props.handlePermissions('car gallery')
                                }}
                                isDisabled={false}
                            >
                                <InfoTxtSmall
                                    style={{
                                        transform: 'translateY(-2px)',
                                    }}
                                >
                                    Only include featured car gallery images
                                </InfoTxtSmall>
                            </CustomAnimatedCheckboxField>
                        </Row>
                        <div style={{ paddingTop: '40px' }} />
                        <InfoTxt>Additional Sharing Options</InfoTxt>
                        <Padding />
                        <CarSharePermissionTableCheckboxesMobile
                            share_being_edited={share_being_edited}
                            onChange={(val: ISharingPermissionIDS) =>
                                handlePermissions(val)
                            }
                            noPermissionDetails={noPermissionDetails}
                            isCreation
                        />
                        <Padding4 />
                        <AddShareBtnWrapper>
                            <ButtonAtom
                                width={'85vw'}
                                height="55px"
                                theme="main-cta"
                                onClick={handleSubmit}
                                disabled={false}
                                textTransform="capitalize"
                            >
                                create share
                            </ButtonAtom>
                        </AddShareBtnWrapper>
                    </>
                ) : formType === 'edit' ? (
                    <>
                        {/* <Title>Share title</Title> */}

                        <div style={{ paddingTop: '24px' }}>
                            <BasicEditOrCreateModeSingleFieldMobile
                                data={shareTitleData}
                                placeholder={''}
                                fontSize="14px"
                            />
                        </div>

                        <div style={{ paddingTop: '10px' }} />
                        <Title>{'Permissions'}</Title>
                        {isGarageShare && (
                            <>
                                <Warning>
                                    *All permissions will apply to all cars in
                                    you garage.
                                </Warning>
                                <div style={{ paddingTop: '10px' }} />
                            </>
                        )}
                        <Row>
                            <RoundedSquare>
                                <Icon
                                    icon="grey_check"
                                    width="14px"
                                    height="11px"
                                />
                            </RoundedSquare>

                            <div style={{ paddingLeft: '20px' }} />
                            <Col>
                                <AdditionalInfoTxt>
                                    Car Profile *
                                </AdditionalInfoTxt>
                                <AdditionalInfoTxt2>
                                    Overview, Gallery, Technical Information
                                </AdditionalInfoTxt2>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                paddingLeft: '40px',
                                paddingTop: '10px',
                            }}
                        >
                            <CustomAnimatedCheckboxField
                                extra_small
                                onChange={() => {}}
                                name={'car_gallery'}
                                checked={
                                    share_being_edited.active_permission_ids.includes(
                                        'car gallery'
                                    )
                                        ? false
                                        : true
                                }
                                onClick={() => {
                                    this.props.handlePermissions('car gallery')
                                }}
                                isDisabled={false}
                            >
                                <InfoTxtSmall
                                    style={{
                                        transform: 'translateY(-2px)',
                                    }}
                                >
                                    Only include featured car gallery images
                                </InfoTxtSmall>
                            </CustomAnimatedCheckboxField>
                        </Row>

                        <div style={{ paddingTop: '40px' }} />

                        <InfoTxt>Additional Sharing Options</InfoTxt>
                        <Padding />
                        <CarSharePermissionTableCheckboxesMobile
                            share_being_edited={share_being_edited}
                            onChange={(val: ISharingPermissionIDS) =>
                                handlePermissions(val)
                            }
                            noPermissionDetails={noPermissionDetails}
                        />
                        <Padding4 />

                        <FadedSlower>
                            <AddShareBtnWrapper>
                                <SaveLoadingFill
                                    hasBg={this.state.showSavedInfo}
                                />

                                <SaveLoadingText
                                    style={{
                                        opacity: this.state.showSavedInfo
                                            ? 1
                                            : 0,
                                    }}
                                >
                                    {this.state.showDone
                                        ? 'Done!'
                                        : `Saving ${this.state.shareChanges}...`}
                                </SaveLoadingText>

                                <motion.div
                                    whileTap={{ scale: 1.05 }}
                                    style={{
                                        opacity: this.state.showSavedInfo
                                            ? 0
                                            : 1,
                                        transition: 'opacity 200ms',
                                        zIndex: 3,
                                    }}
                                >
                                    <ButtonAtom
                                        width={'85vw'}
                                        height="55px"
                                        theme="wide-mobile-blue-bg"
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                        disabled={
                                            !isSaveActive &&
                                            !this.state.showSavedInfo
                                        }
                                    >
                                        Save
                                    </ButtonAtom>
                                </motion.div>
                            </AddShareBtnWrapper>
                            <Padding />
                        </FadedSlower>

                        <AddShareBtnWrapper>
                            <motion.div whileTap={{ scale: 1.05 }}>
                                <BorderAndIconButton
                                    variant="red"
                                    text="Delete share"
                                    icon="thick_red_bin"
                                    iconwidth="15px"
                                    iconHeight="auto"
                                    onClick={onDeleteShare}
                                    buttonwidth="85vw"
                                    buttonheight="55px"
                                    dataCyId={'delete-share'}
                                    fontsize="15px"
                                />
                            </motion.div>
                        </AddShareBtnWrapper>
                    </>
                ) : null}
            </Container>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(PrivateShareFormManagerMobile)
