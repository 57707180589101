import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import Sheet from 'react-modal-sheet'
import FilterBottomSheetItemMobile from '../../filterBottomSheetItem/filterBottomSheetItemMobile'
import ScrollLock from 'react-scrolllock'
import { IDropdownItem } from 'entityModels'
import { device } from '../../../templates/displays/devices'

type IStyledProps = {
    color?: string
    $customHeight?: string
    $ipadCustomHeight?: string
}

const CustomSheet = styled(Sheet)<IStyledProps>`
    .react-modal-sheet-backdrop {
        /* custom styles */
        background-color: rgba(26, 26, 26, 0.7) !important;
        border: none !important;
    }
    .react-modal-sheet-container {
        height: ${(props) =>
            props.$customHeight
                ? `${props.$customHeight} !important`
                : '70vh !important'};
        background-color: var(--modal-side-sheet-pop-over-bg, #fff) !important;
        box-shadow: var(--box-shadow, rgba(0, 0, 0, 0.08)) 0px -2px 10px !important;

        @media ${device.ipad} {
            height: ${(props) =>
                props.$ipadCustomHeight
                    ? `${props.$ipadCustomHeight} !important`
                    : props.$customHeight
                    ? `${props.$customHeight} !important`
                    : '70vh !important'};
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .react-modal-sheet-header {
        height: 30px !important;
        align-items: flex-start !important;
    }
    .react-modal-sheet-drag-indicator {
        width: 30px !important;
        margin-top: 10px;
    }
    .react-modal-sheet-content {
        /* custom styles */
    }
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

interface IProps {
    toggle: any
    isFilterBottomSheetMenuOpen: boolean
    dropdownData?: IDropdownItem[] | null | undefined
    currentOption?: string | null
    onSelected?: (value: string) => void
    customHeight?: string
    ipadCustomHeight?: string
}

const FilterMenuOnSingleCriteria: React.FunctionComponent<IProps> = (props) => {
    const {
        isFilterBottomSheetMenuOpen,
        toggle,
        dropdownData,
        currentOption,
        customHeight,
        ipadCustomHeight,
        onSelected,
    } = props

    const [currentSelected, setCurrentSelected] = useState<string | null>(
        currentOption ? currentOption : null
    )

    useEffect(() => {
        currentOption && setCurrentSelected(currentOption)

        return
    }, [currentOption])

    let mappedDropdownData =
        dropdownData &&
        dropdownData.map((item: IDropdownItem, index: number) => {
            if (item) {
                return (
                    <React.Fragment key={`${item.uid}_${index}_mobile`}>
                        <FilterBottomSheetItemMobile
                            text={item.name}
                            key={index}
                            checked={
                                item?.name?.toLowerCase() ===
                                currentSelected?.toLowerCase()
                            }
                            onClick={() => {
                                onSelected && onSelected(item.uid)
                                toggle(false)
                            }}
                            notCapitalised
                        />
                    </React.Fragment>
                )
            } else {
                return null
            }
        })

    return (
        <React.Fragment>
            {isFilterBottomSheetMenuOpen && <ScrollLock />}
            <CustomSheet
                isOpen={isFilterBottomSheetMenuOpen}
                onClose={() => {
                    toggle(false)
                }}
                $customHeight={customHeight}
                $ipadCustomHeight={ipadCustomHeight}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>{mappedDropdownData}</Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop
                    onTap={(e: any) => {
                        e.preventDefault()
                        setTimeout(function () {
                            toggle(false)
                        }, 50)
                    }}
                />
            </CustomSheet>
        </React.Fragment>
    )
}

export default connect(
    mapStateToProps,
    dispatchProps
)(FilterMenuOnSingleCriteria)
