export const GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_REQUEST =
    '@@filestorage/presinedURLs_car_gallery_images/get/REQUEST'
export const GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_SUCCESS =
    '@@filestorage/presinedURLs_car_gallery_images/get/SUCCESS'
export const GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR =
    '@@filestorage/presinedURLs_car_gallery_images/get/ERROR'

export const RESET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_SUCCESS =
    '@@filestorage/presinedURLs_car_gallery_images/reset/SUCCESS'

export const GET_PRESIGNED_URL_CAR_COVER_IMAGE_REQUEST =
    '@@filestorage/presinedURLs_car_cover_image/get/REQUEST'
export const GET_PRESIGNED_URL_CAR_COVER_IMAGE_SUCCESS =
    '@@filestorage/presinedURLs_car_cover_image/get/SUCCESS'
export const GET_PRESIGNED_URL_CAR_COVER_IMAGE_ERROR =
    '@@filestorage/presinedURLs_car_cover_image/get/ERROR'

export const SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_REQUEST =
    '@@filestorage/submit_car_gallery_images/post/REQUEST'
export const SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_SUCCESS =
    '@@filestorage/submit_car_gallery_images/post/SUCCESS'
export const SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_ERROR =
    '@@filestorage/submit_car_gallery_images/post/ERROR'

export const SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_REQUEST =
    '@@filestorage/submit_car_cover_image/post/REQUEST'
export const SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_SUCCESS =
    '@@filestorage/submit_car_cover_image/post/SUCCESS'
export const SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_ERROR =
    '@@filestorage/submit_car_cover_image/post/ERROR'

export const GET_PRESIGNED_URL_USERPROFILE_IMAGE_REQUEST =
    '@@filestorage/presinedURLs_userprofileimage/get/REQUEST'
export const GET_PRESIGNED_URL_USERPROFILE_IMAGE_SUCCESS =
    '@@filestorage/presinedURLs_userprofileimage/get/SUCCESS'
export const GET_PRESIGNED_URL_USERPROFILE_IMAGE_ERROR =
    '@@filestorage/presinedURLs_userprofileimage/get/ERROR'

export const GET_PRESIGNED_URL_ENTRY_ATTACHMENT_REQUEST =
    '@@filestorage_entry/presinedURLs_attachment/get/REQUEST'
export const GET_PRESIGNED_URL_ENTRY_ATTACHMENT_SUCCESS =
    '@@filestorage_entry/presinedURLs_attachment/get/SUCCESS'
export const GET_PRESIGNED_URL_ENTRY_ATTACHMENT_ERROR =
    '@@filestorage_entry/presinedURLs_attachment/get/ERROR'

export const GET_PRESIGNED_URL_COST_ATTACHMENT_REQUEST =
    '@@filestorage_cost/presinedURLs_attachment/get/REQUEST'
export const GET_PRESIGNED_URL_COST_ATTACHMENT_SUCCESS =
    '@@filestorage_cost/presinedURLs_attachment/get/SUCCESS'
export const GET_PRESIGNED_URL_COST_ATTACHMENT_ERROR =
    '@@filestorage_cost/presinedURLs_attachment/get/ERROR'

export const GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_REQUEST =
    '@@filestorage_entry/presinedURLs_gallery_images/get/REQUEST'
export const GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_SUCCESS =
    '@@filestorage_entry/presinedURLs_gallery_images/get/SUCCESS'
export const GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_ERROR =
    '@@filestorage_entry/presinedURLs_gallery_images/get/ERROR'

export const SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_REQUEST =
    '@@filestorage/submit_entry_gallery_images/post/REQUEST'
export const SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_SUCCESS =
    '@@filestorage/submit_entry_gallery_images/post/SUCCESS'
export const SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_ERROR =
    '@@filestorage/submit_entry_gallery_images/post/ERROR'

export const SUBMIT_IMAGES_TO_S3_FILESTORAGE_REQUEST =
    '@@filestorage/submit_images_to_s3/post/REQUEST'
export const SUBMIT_IMAGES_TO_S3_FILESTORAGE_SUCCESS =
    '@@filestorage/submit_images_to_s3/post/SUCCESS'
export const SUBMIT_IMAGES_TO_S3_FILESTORAGE_ERROR =
    '@@filestorage/submit_images_to_s3/post/ERROR'
