import { ICar } from 'entityModels'
import styled from 'styled-components'
import CarAsSoldConfirmModalDesktop from '../../../molecules/showroom/editModals/desktop/carAsSoldConfirmModalDesktop'
import CarRemovalActionModalDesktop from '../../../molecules/showroom/editModals/desktop/carRemovalActionModal'
// import CarRemovedConfirmationModalDesktop from '../../../molecules/showroom/editModals/desktop/carRemovedConfirmationModalDesktop'
import CarUnpublishActionModalDesktop from '../../../molecules/showroom/editModals/desktop/carUnpublishActionModalDesktop'
import CarUnpublishConfirmationModalDesktop from '../../../molecules/showroom/editModals/desktop/carUnpublishConfirmationModalDesktop'
// import MarkCarSoldActionModalDesktop from '../../../molecules/showroom/editModals/desktop/markCarSoldActionModalDesktop'
import PublicationConfirmedModalDesktop from '../../../molecules/showroom/editModals/desktop/publicationConfirmedModalDesktop'
import { RouteComponentProps, useParams, withRouter } from 'react-router'

import {
    IEditShowroomModalOptions,
    ShowroomEntryParams,
} from '../../../pages/showroom/car/showroomCarProfileEdit'
import ScrollLock from 'react-scrolllock'
import { useEffect } from 'react'

export const ShowroomModalContainerMobile = styled.div`
    min-height: 100vh;
    width: 100vw;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`

type Props = {
    activeModal: IEditShowroomModalOptions
    handleSetModalOpened: (p: IEditShowroomModalOptions | null) => any
    car: ICar
    entryid: string
}

const ShowroomModalEditManagerMobile = (props: Props & RouteComponentProps) => {
    const { entryid } = useParams<ShowroomEntryParams>()
    let { handleSetModalOpened, car } = props

    useEffect(() => {
        scrollTo(0, 0)
    }, [])

    let renderModalBod = () => {
        switch (props.activeModal) {
            case 'publication_confirmed':
                return <PublicationConfirmedModalDesktop entryid={entryid} />

            case 'unpublish_action':
                return (
                    <CarUnpublishActionModalDesktop
                        carTitle={car.title}
                        handleSetModalOpened={handleSetModalOpened}
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                    />
                )

            case 'unpublished_confirmed':
                return (
                    <CarUnpublishConfirmationModalDesktop
                        cancel={() => {
                            handleSetModalOpened(null)
                            // handleSetModalOpened('publication_confirmed')
                        }}
                        continue={() => {
                            handleSetModalOpened(null)
                        }}
                    />
                )

            case 'removal_action':
                return (
                    <CarRemovalActionModalDesktop
                        handleSetModalOpened={() => {
                            handleSetModalOpened('removal_confirmed')
                        }}
                        cancel={() => {
                            handleSetModalOpened(null)
                        }}
                        carTitle={props.car.title}
                    />
                )

            // case 'removal_confirmed':
            //     return (
            //         <CarRemovedConfirmationModalDesktop
            //             continue={() => {
            //                 handleSetModalOpened(null)
            //             }}
            //             cancel={() => {
            //                 // handleSetModalOpened('publication_confirmed')
            //                 handleSetModalOpened(null)
            //             }}
            //         />
            //     )

            // case 'mark_as_sold_action':
            //     return (
            //         <MarkCarSoldActionModalDesktop
            //             handleSetModalOpened={() => {
            //                 handleSetModalOpened('mark_as_sold_confirmed')
            //             }}
            //             cancel={() => {
            //                 handleSetModalOpened(null)
            //             }}
            //             entryid={entryid}
            //             carid={props.car.id}
            //         />
            //     )
            case 'mark_as_sold_confirmed':
                return (
                    <CarAsSoldConfirmModalDesktop
                        cancel={() => handleSetModalOpened(null)}
                        carID={props.car.id}
                    />
                )
            default:
                return <div />
        }
    }

    return (
        <ShowroomModalContainerMobile>
            <ScrollLock />
            {renderModalBod()}
        </ShowroomModalContainerMobile>
    )
}

export default withRouter(ShowroomModalEditManagerMobile)
