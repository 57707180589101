import moment from 'moment'

export function getRelativeTimeAgoFromToday(refferenceDate: Date): string {
    let today = new Date()
    let refferenceDateM = moment(refferenceDate).endOf('day').utcOffset(0)
    refferenceDateM.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    let todayM = moment(today).endOf('day').utcOffset(0)
    todayM.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    let momentFinalDate = todayM.from(refferenceDateM, true)

    let nReg = /\d+/g
    let matchedN = nReg.exec(momentFinalDate)

    let nValue: number | undefined = undefined

    if (matchedN) {
        nValue = parseInt(matchedN[0])
    }

    if (momentFinalDate.match(/days/g) !== null && nValue && nValue % 7 === 0) {
        return nValue / 7 === 1 ? `1 week` : `${nValue / 7} weeks`
    }

    return momentFinalDate
}
