import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from './../../reducer'

import { gen_draft_differing_values_to_patch } from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'
import {
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from './maindriver_additionaldetails_submit'
import { ValidateAndAddStatsToApplication } from '../application/get'
import posthog from 'posthog-js'

export function* Submit_mainDriver_basicDetails(): any {
    posthog.capture('Update insurance application customer basic details')
    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft = { ...all_draft.main_driver.basic_details }
        let existing = { ...all_existing.main_driver.basic_details }

        let dataToSendToPatch: IAnyObject | undefined =
            gen_draft_differing_values_to_patch({
                existing: existing,
                draft: draft,
            })

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            if (dataToSendToPatch['title']) {
                const titleSaved = dataToSendToPatch['title']
                delete dataToSendToPatch['title']

                dataToSendToPatch = {
                    ...dataToSendToPatch,
                    title_uid: titleSaved,
                }
            }
            // console.log(
            //     'data to patch',
            //     dataToSendToPatch,
            //     'all_existing',
            //     all_existing
            // )
            // call api to patch as thats all the data merged we want to patch
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { customer: { ...dataToSendToPatch } },
                }
            )

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )
            yield put(
                insuranceActions.submit_mainDriver_basicDetails_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_mainDriver_basicDetails_success())
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_mainDriver_basicDetails,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/main_driver/${all_existing.main_driver.id}/basic_details`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_mainDriver_basicDetails_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_mainDriver_basicDetails() {
    while (true) {
        yield take(insuranceActions.submit_mainDriver_basicDetails_request)
        yield call(Submit_mainDriver_basicDetails)
    }
}

const maindriver_basicDetails_submit: any[] = [
    fork(Watcher_Submit_mainDriver_basicDetails),
]

export default maindriver_basicDetails_submit
