import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'

const Btn = styled(motion.button)<{
    $isDisabled?: boolean
    $isSecondary?: boolean
    $border?: string
    $customWidth?: string
    $customHeight?: string
    $removePaddings?: boolean
    $customBg?: string
    $customHoverColor?: string
    $borderOnHover?: string
    $removeShadowOnHover?: boolean
    $isRound?: boolean
    $borderRadius?: string
}>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.$removePaddings ? '0px' : '12px 20px')};
    height: ${(props) => props.$customHeight ?? '48px'};
    gap: 8px;
    cursor: pointer;
    width: ${(props) => props.$customWidth ?? 'max-content'};
    padding-left: ${(props) => (props.$removePaddings ? '0px' : '20px')};
    padding-right: ${(props) => (props.$removePaddings ? '0px' : '20px')};
    color: var(--bg-color, #fff);
    background-color: ${(props) =>
        props.$isDisabled
            ? props.$isSecondary
                ? 'transparent'
                : 'var(--background_disabled_neutral)'
            : props.$customBg ?? '#5ec3ca'};
    border: ${(props) =>
        props.$isDisabled && props.$isSecondary
            ? '1px solid var(--border-muted, #e5e5e5)'
            : props.$border ?? 'none'};
    border-radius: ${(props) =>
        props.$isRound
            ? '999px'
            : props.$borderRadius
            ? props.$borderRadius
            : '4px'};
    font-family: Lato;
    font-size: 16px;
    cursor: pointer;
    transition: all 50ms;
    :hover {
        background-color: ${(props) =>
            props.$isDisabled
                ? props.$customBg ?? 'var(--border_muted_lighter)'
                : props.$customHoverColor ??
                  props.$customBg ??
                  'var(--primary, #5ec3ca)'};
        border: ${(props) =>
            props.$isDisabled
                ? props.$isSecondary
                    ? '1px solid var(--border-muted, #e5e5e5)'
                    : props.$border ?? 'none'
                : props.$borderOnHover ?? props.$border ?? 'none'};
        box-shadow: ${(props) =>
            props.$isDisabled || props.$removeShadowOnHover
                ? 'none'
                : '2px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08))'};
    }

    @media ${device.mobile} {
        width: ${(props) => props.$customWidth ?? '100%'};
        padding: ${(props) => (props.$removePaddings ? '0px' : '10px 16px')};
    }

    @media (max-width: 320px) {
        padding: ${(props) => (props.$removePaddings ? '0px' : '10px 14px')};
    }
`

type Props = {
    onClick?: any
    children: React.ReactNode
    isDisabled?: boolean
    bg?: string
    boder?: string
    customWidth?: string
    customHeight?: string
    removePaddings?: boolean
    isSecondary?: boolean
    customHoverColor?: string
    borderOnHover?: string
    isActive?: boolean
    removeShadowOnHover?: boolean
    isRound?: boolean
    dataCyId?: string
    borderRadius?: string
    className?: string
}

const StandardCtaBtn = (props: Props) => {
    return (
        <Btn
            onClick={props.onClick}
            whileTap={{ scale: props.isDisabled ? 1 : 1.2 }}
            $removePaddings={props.removePaddings}
            $customWidth={props.customWidth}
            $customHeight={props.customHeight}
            $customBg={props.bg}
            $isDisabled={props.isDisabled}
            $isSecondary={props.isSecondary}
            $border={props.boder}
            $borderRadius={props.borderRadius}
            $borderOnHover={props.borderOnHover}
            $customHoverColor={props.customHoverColor}
            $removeShadowOnHover={props.removeShadowOnHover}
            $isRound={props.isRound}
            data-attr={props.dataCyId && props.dataCyId}
            disabled={props.isDisabled}
            className={props.className}
        >
            {props.children}
        </Btn>
    )
}

export default StandardCtaBtn
