import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
import { setCurrentExternalCar } from '../../../../redux/entities/cars/actions/thunks'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { IExternalCarsObject } from 'entityModels'
import { getExternalCarsSharedWithYouREQUEST } from '../../../../redux/entities/cars/actions/loadingActions'

import ReadOnlyCarHistoryFileEntryGallery from '../../../templates/readOnly/car/readOnlyHistoryFileEntryGallery'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    setCurrentExternalCar: (carid: string) => void
    setInitialLandingUrl: any
    externalCarsData: IExternalCarsObject | undefined
    getExternalCarsSharedWithYouREQUEST: (car_id: string) => void
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentExternalCar: (car_id: string) => setCurrentExternalCar(car_id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    getExternalCarsSharedWithYouREQUEST: (car_id: string) =>
        getExternalCarsSharedWithYouREQUEST(car_id),
}

type State = {}

class ExternalCarSingleEntryGallery extends React.PureComponent<Props, State> {
    state = {}

    componentDidMount() {
        let carid = this.props.match.params.carid
        let entryid = this.props.match.params.entryid
        this.props.setInitialLandingUrl(
            `shared/car/${carid}/history-file/${entryid}/gallery`
        )

        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        this.props.setCurrentExternalCar(carid)

        this.props.getExternalCarsSharedWithYouREQUEST(carid)
    }

    render() {
        let { externalCarsData, userLoggedIn } = this.props

        let carid: string = this.props.match.params.carid

        let currentExternalCar = externalCarsData && externalCarsData[carid]

        return (
            <ReadOnlyCarHistoryFileEntryGallery
                sharedCar={currentExternalCar}
                userLoggedIn={userLoggedIn}
            />
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ExternalCarSingleEntryGallery)
)
