import {
    ICar,
    ICreateCarVersion2,
    IDropdownItem,
    IList,
    ITechInfoData,
} from 'entityModels'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import PickerBtn from '../../../../atoms/Button/pickerBtn'
import useWindowSize from '../../../../templates/displays/windowSizeHook'
import OnboardingFormLayoutMobile, {
    OnboardingText,
    PaddingUnit,
} from '../../../../templates/onboarding/onboardingFormLayoutMobile'
import { generateUserChoiceUnit } from '../../../../../helpers/units/generateUserChoiceUnit'
import { IUser } from 'myModels'
import {
    generateFrontEndValueFuelUnit,
    generateBackEndValueFuelUnit,
    cubicCentimetersToLiters,
    cubicInchesToLiters,
} from '../../../../../helpers/units/unitConversion'
import {
    useAppSelector,
    useAppDispatch,
} from '../../../../../redux/store/hooks'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import Expander from '../../../../atoms/expander/expander'
import { CarYearValidation } from '../../../../molecules/showroom/techInfoForm/validationSchema'
import { ErrorText } from '../../account/accountAddressForm/accountAddressformDesktop'
import { getStandaloneSearchUrl } from '../../technicalInformationEditForm/searchselect/mobile/standaloneSearchSelectMobile'
import styled from 'styled-components'
import { Select } from '../../../../atoms/select'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { createCarRequestVersion2 } from '../../../../../redux/entities/cars/actions/loadingActions'

const RowForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const AdjustPosition = styled.div`
    margin-left: 24px;
    width: 100%;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: #b3b3b3;
    font-family: Lato;
    padding-left: 8px;
`

type IOnboarding_Car_Data = {
    make: string | undefined
    model: string | undefined
    year: number | string | undefined
    engine_size: number | string | undefined
    engine_size_unit: string | undefined
    transmission: string | undefined
    drive_side: string | undefined
}

type Props = {
    formStep: number
    formTitle: string
    formText: string
    next: () => void
    car: ICar
    onRetrieveByRegNoClick: () => void
}

const AddACarDataFormOnboardingMobile = (props: Props) => {
    let windowHeight = useWindowSize().height

    let { car, next, formStep, formTitle, formText, onRetrieveByRegNoClick } =
        props
    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let appDispatch = useAppDispatch()

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let techInfoData = useAppSelector((state) => {
        return state.entities.technicalInformationData.technical_information
    })

    let tempMakeDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeDisplay
    })

    let tempModelDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelDisplay
    })

    let carModel: string | undefined = tempModelDisplay
    let carMake: string | undefined = tempMakeDisplay
    let carYear: string | number | null = car.overview.data['year'].answer
    let carDriveSide: string | null = car.overview.data['drive_side'].answer
    let carEngineSize: string | number | null =
        car.overview.data['engine_capacity'].answer

    useEffect(() => {
        if (!transmissionsList) {
            appDispatch(
                dropDownActions.setTransmissionTypesDropdownDataRequest()
            )
        }

        if (!makesDropdowndata) {
            appDispatch(dropDownActions.setMakeDropdownDataRequest())
        }

        if (!modelsDropdowndata && carMake && carMake.length > 1) {
            appDispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: carMake,
                    make_uid: car.overview.data['make'].current_uid,
                })
            )
        }
    }, [])

    let tech_info_id = car.technical_information

    let tech_car_info: ITechInfoData | undefined = techInfoData[tech_info_id]

    let transmission: string | number | null | undefined =
        tech_car_info && tech_car_info.transmission.answer

    let user_fuel_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].ids_list

    let user_fuel_unit_chosen_id = userData
        ? generateUserChoiceUnit(userData, 'fuel_unit').unit_id
        : ''

    let user_fuel_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].data

    let user_default_fuel_unit: IList | null =
        user_fuel_unit_data &&
        user_fuel_unit_chosen_id &&
        // @ts-ignore
        user_fuel_unit_data[user_fuel_unit_chosen_id]
            ? // @ts-ignore
              user_fuel_unit_data[user_fuel_unit_chosen_id]
            : null

    let fuel_units: IList[] | null =
        user_fuel_unit_data &&
        user_fuel_unit_ids_list &&
        user_fuel_unit_ids_list.map((id: string, index: number) => {
            // @ts-ignore
            return user_fuel_unit_data[id]
        })

    let init_data: IOnboarding_Car_Data = {
        make: carMake && carMake !== '-' ? carMake : undefined,
        model: carModel && carModel !== '-' ? carModel : undefined,
        year: carYear && carYear !== '-' ? carYear : undefined,
        drive_side:
            carDriveSide && carDriveSide !== '-' ? carDriveSide : undefined,
        engine_size_unit: user_default_fuel_unit?.id,
        engine_size:
            carEngineSize && carEngineSize !== '-' ? carEngineSize : undefined,
        transmission:
            transmission && transmission !== '-'
                ? `${transmission}`
                : undefined,
    }

    const [formData, setFormData] = useState<IOnboarding_Car_Data>({
        make: tempMakeDisplay ? tempMakeDisplay : init_data.make,
        model: tempModelDisplay ? tempModelDisplay : init_data.model,
        year: init_data.year,
        drive_side: init_data.drive_side,
        engine_size_unit: user_default_fuel_unit?.id,
        engine_size:
            carEngineSize && carEngineSize !== '-' && user_default_fuel_unit
                ? generateFrontEndValueFuelUnit(
                      user_default_fuel_unit.id,
                      +carEngineSize
                  )
                : undefined,
        transmission: init_data.transmission,
    })

    const handleChange = (
        id: string,
        value: string | undefined,
        uid?: string
    ) => {
        if (id === 'make') {
            appDispatch(dropDownActions.setTempMakeDisplay(value))
            appDispatch(dropDownActions.setTempMakeIDSuccess(uid))
            setFormData((prevState) => {
                return {
                    ...prevState,
                    make: value,
                    model: undefined,
                }
            })
            if (tempModelDisplay) {
                appDispatch(dropDownActions.reset_make_model_temps())
            }
        } else {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    [id]: value,
                }
            })
        }
    }

    const drive_side_options = [
        {
            name: 'LHD',
        },
        {
            name: 'RHD',
        },
        {
            name: 'Center',
        },
    ]

    let arr_form_values = [
        'drive_side',
        'make',
        'model',
        'transmission',
        'engine_size',
        'year',
    ]

    let arr_validating_form_values = ['make', 'model', 'year']

    const car_years_validation = useAppSelector(
        (state) => state.localdata.form_validation_copy.years
    )

    const isFieldValid = (id: string, value?: string | number): boolean => {
        if (id === 'year' && value !== undefined && car_years_validation) {
            if (+value < 1850) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_min,
                    }
                })
                return false
            } else if (+value > car_years_validation.car_year.max) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        year: car_years_validation.car_year.warning_max,
                    }
                })
                return false
            }
        } else if (id === 'year' && !value) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        } else if (
            ((id === 'make' || id === 'model') &&
                // @ts-ignore
                !formData[id]) ||
            // @ts-ignore
            formData[id] === '-' ||
            // @ts-ignore
            formData[id] === ''
        ) {
            setError((prevState) => {
                return { ...prevState, [id]: 'Required' }
            })
            return false
        }
        return true
    }

    const validateOnSubmit = () => {
        let res = true
        arr_validating_form_values.forEach((id: string) => {
            if (!isFieldValid(id, formData[id as keyof IOnboarding_Car_Data])) {
                res = false
            }
        })

        return res
    }

    const { search } = useLocation()

    let queryParams = new URLSearchParams(search)
    let countryParam = queryParams.get('country')

    let country = countryParam
        ? decodeURIComponent(countryParam)
        : 'United Kingdom'

    const submitData: () => void = () => {
        // let p: IwriteMultipleTechnicalInformationPayloadReq = {
        //     tech_info_id: tech_info_id,
        //     car_id: car.id,
        //     data: [],
        // }

        let p: ICreateCarVersion2 = {
            car_id: car.id,
            data: [],
            isOnboarding: true,
            country: `${country}`,
            garage_id: `${userData?.owns_garage?.uid}`,
            direct_url: `/onboarding-step-3`,
        }

        let generateAnswer = (fieldID: string) => {
            if (formData[fieldID as keyof IOnboarding_Car_Data]) {
                if (
                    fieldID === 'engine_size' &&
                    formData.engine_size_unit &&
                    formData.engine_size
                ) {
                    return generateBackEndValueFuelUnit(
                        formData.engine_size_unit,
                        parseFloat(`${formData.engine_size}`)
                    )
                } else if (fieldID === 'year' && formData.year) {
                    return +formData.year
                } else {
                    return formData[fieldID as keyof IOnboarding_Car_Data]
                }
            }
        }

        arr_form_values.forEach((id: string) => {
            if (formData[id as keyof IOnboarding_Car_Data]) {
                let newItem: IList = {
                    id: id,
                    text: id,
                    answer: generateAnswer(id),
                }
                p.data = [...p.data, newItem]
            }
        })

        let car_title = generate_car_title()

        let carTitleItem: IList = {
            id: 'title',
            text: 'Title',
            answer: car_title,
        }

        p.data = [...p.data, carTitleItem]

        if (p.data.length > 0) {
            appDispatch(createCarRequestVersion2(p))
        }

        next()
    }

    let generate_car_title = () => {
        if (formData.year || formData.make || formData.model) {
            return `${formData.year ? `${formData.year.toString()} ` : ''}${
                formData.make ? `${formData.make} ` : ''
            }${formData.model ? formData.model : ''}`
        }
    }

    const history = useHistory()

    const makePickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${props.car.id}/search`,
        formId: 'makeModelYear',
        fieldId: 'make',
    })

    const modelPickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${props.car.id}/search`,
        formId: 'makeModelYear',
        fieldId: 'model',
    })

    const [error, setError] = useState<
        { [key: string]: string | undefined } | undefined
    >(undefined)

    const validateForm = async (id: string, value?: number) => {
        if (id === 'year') {
            try {
                await CarYearValidation(car_years_validation.car_year).validate(
                    {
                        year: value,
                    }
                )
                setError((prevState) => {
                    return { ...prevState, [id]: undefined }
                })
            } catch (err: any) {
                setError((prevState) => {
                    return { ...prevState, year: err.errors[0] }
                })
            }
        }
    }

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    return (
        <OnboardingFormLayoutMobile
            step={formStep}
            title={formTitle}
            submit={() => {
                let isFormValid = validateOnSubmit()
                if (isFormValid) {
                    submitData()
                }
            }}
            isBtnLoading={isLoadingNewMakeOrModel}
        >
            <PaddingUnit $times={windowHeight && windowHeight > 700 ? 4 : 2} />
            <OnboardingText>{formText}</OnboardingText>
            <PaddingUnit $times={5} />

            <>
                <ButtonAtom
                    theme="flexible-transparent-blue-border"
                    height="48px"
                    width="100%"
                    fontSize={16}
                    onClick={onRetrieveByRegNoClick}
                >
                    Find my car by registration number
                </ButtonAtom>
                <PaddingUnit $times={5} />
            </>

            <PickerBtn
                label="Make *"
                text={tempMakeDisplay ?? undefined}
                // customTextTransform={'lowercase'}
                fwArrow
                onClick={() => {
                    !!makePickerUrl && history.push(makePickerUrl)
                }}
                height="48px"
                borderColour={error?.make ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.make ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.make}</ErrorText>
            </Expander>
            <PaddingUnit $times={3} />
            <PickerBtn
                // customTextTransform={'lowercase'}
                label="Model *"
                text={tempModelDisplay ?? undefined}
                onClick={() => {
                    !!modelPickerUrl && history.push(modelPickerUrl)
                }}
                fwArrow
                height="48px"
                borderColour={error?.model ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.model ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.model}</ErrorText>
            </Expander>
            <PaddingUnit $times={3} />
            <InputFieldNoFormikAnimated
                value={formData.year}
                name={'Year *'}
                placeholder=""
                type={'number'}
                onChange={(e: any) => {
                    handleChange('year', e.target.value)
                    setError((prevState) => {
                        return { ...prevState, year: undefined }
                    })
                }}
                linecolor={error?.year ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.year ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.year}</ErrorText>
            </Expander>
            <div style={{ paddingTop: 32 }} />
            <RowForm>
                <InputFieldNoFormikAnimated
                    name="Engine size *"
                    placeholder={'Engine size'}
                    tabindex={1}
                    aria-live="polite"
                    value={
                        formData.engine_size !== undefined &&
                        typeof formData.engine_size === 'number' &&
                        formData.engine_size_unit !== 'cubic_centimeters'
                            ? formData.engine_size.toFixed(1)
                            : formData.engine_size
                    }
                    font_family="Lato"
                    type="number"
                    isnarrow={true}
                    onChange={(e: any) => {
                        handleChange('engine_size', e.target.value)
                        e.target.value === ''
                            ? validateForm('engine_size', undefined)
                            : validateForm('engine_size', +e.target.value)
                    }}
                />

                <AdjustPosition>
                    <Select
                        defaultValue={formData.engine_size_unit}
                        onChange={(val: string | undefined) => {
                            handleChange('engine_size_unit', val)
                        }}
                        placeholder={'Unit'}
                        options={
                            fuel_units
                                ? fuel_units.map((unit: IList) => {
                                      return {
                                          value: unit?.id,
                                          label: `${unit?.short}`,
                                      }
                                  })
                                : []
                        }
                    />
                </AdjustPosition>
            </RowForm>
            <Expander height={error?.engine_size ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.engine_size}</ErrorText>
            </Expander>
            {!error?.engine_size && (
                <Expander
                    height={
                        formData.engine_size_unit !== 'liters' &&
                        formData.engine_size
                            ? 'auto'
                            : 0
                    }
                >
                    <EquivalentConverted>
                        Equal to{' '}
                        {formData.engine_size &&
                            (formData.engine_size_unit === 'cc' ||
                            formData.engine_size_unit === 'cubic_centimeters'
                                ? cubicCentimetersToLiters(
                                      +formData.engine_size
                                  ).toFixed(1)
                                : formData.engine_size_unit === 'ci' ||
                                  formData.engine_size_unit === 'cubic_inches'
                                ? cubicInchesToLiters(+formData.engine_size)
                                : null)}{' '}
                        litres
                    </EquivalentConverted>
                </Expander>
            )}
            <div style={{ paddingTop: '26px' }} />
            <Select
                defaultValue={formData.transmission}
                placeholder={
                    formData.transmission ? 'Transmission *' : 'Transmission'
                }
                onChange={(val: string) => {
                    handleChange('transmission', val)
                }}
                options={
                    transmissionsList
                        ? transmissionsList.map((unit: IDropdownItem) => ({
                              value: unit?.uid,
                              label: `${unit?.name}`,
                          }))
                        : []
                }
            />
            <PaddingUnit $times={3} />
            <Select
                options={drive_side_options.map((option: { name: string }) => ({
                    value: option.name,
                    label: option.name,
                }))}
                placeholder={
                    formData.drive_side ? 'Drive Side *' : 'Drive Side'
                }
                defaultValue={formData.drive_side}
                onChange={(name) => {
                    handleChange('drive_side', name)
                }}
            />
        </OnboardingFormLayoutMobile>
    )
}

export default AddACarDataFormOnboardingMobile
