import { call, fork, put, take } from 'redux-saga/effects'

import { api } from '../../../services'
import { ICustomErrorData, IDropdownItem, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'

export type ISetYearDropdownData = {
    modelName: string
}

function* setYearDropdownDataApi(payload?: ISetYearDropdownData) {
    try {
        const dataReceived: IDropdownItem[] =
            yield api.dropdownsData.get_car_years_dropdown_api()
        yield put(dropDownActions.setYearDropdownDataSuccess(dataReceived))
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setYearDropdownDataApi,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_message_detail: 'Check your connection and refresh',
                custom_user_action_text: 'Refresh page',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            yield put(dropDownActions.addCustomFieldToApiError(customError))
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            yield put(dropDownActions.addCustomFieldToApiError(typedError))
        }
    }
}

function* watcherSetYearDropdownData() {
    while (true) {
        const { payload } = yield take(
            dropDownActions.setYearDropdownDataRequest
        )
        if (payload) {
            let p: ISetYearDropdownData | undefined = {
                modelName: payload && payload.modelName,
            }
            yield call(setYearDropdownDataApi, p)
        } else {
            take(dropDownActions.setYearDropdownDataRequest)
            yield call(setYearDropdownDataApi)
        }
    }
}

const set_year_dropdown_data: any[] = [fork(watcherSetYearDropdownData)]
export default set_year_dropdown_data
