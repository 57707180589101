import styled from 'styled-components'
import chevron from '../../../public/assets/icons/chevronupwhite.svg'

type Props = {
    size?: string
    onClick?: any
}

type StyleProps = {
    $size?: string
    $padding?: string
}

const Btn = styled.button<StyleProps>`
    all: unset;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: ${(props) => (props.$size ? props.$size : '40px')};
    height: ${(props) => (props.$size ? props.$size : '40px')};
    background: rgba(26, 26, 26, 0.16);
    border-radius: 50%;
    border: none;
`

const Chevron = styled.img`
    height: 10px;
`

const ScrollToTopBtn = (props: Props) => {
    return (
        <Btn
            $size={props.size}
            onClick={(e: any) => {
                e.stopPropagation()
                props.onClick()
            }}
        >
            <Chevron src={chevron} />
        </Btn>
    )
}

export default ScrollToTopBtn
