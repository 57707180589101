import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneyInsuranceIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.5 15.8659V34.1326C45.5 41.5181 34.5625 48.5952 28 50.75C21.4375 48.5952 10.5 41.5181 10.5 34.1326V15.8659C14 14.6606 22.4 10.85 28 5.25C33.6 10.85 42 14.6606 45.5 15.8659Z"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M38.5 21.4079V32.6489C38.5 37.1938 31.9375 41.549 28 42.875C24.0625 41.549 17.5 37.1938 17.5 32.6489V21.4079C19.6 20.6661 24.64 18.3212 28 14.875C31.36 18.3212 36.4 20.6661 38.5 21.4079Z"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
            />
        </svg>
    )
}

export default JourneyInsuranceIcon
