import 'tslib'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import store, { history } from './redux/store/index'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'
import { InsuranceInitialProvider } from './providers/insurance/insuranceInitialProvider'
import ThemesProvider from './providers/theme/customThemeProvider'
import { ShowroomProvider } from './providers/showroom'
// import { InsuranceInitialProvider } from './providers/insurance/insuranceProviderGET'
import { ConnectedRouter } from 'connected-react-router'
import { GoogleOAuthProvider } from '@react-oauth/google'

const env: any = process.env.REACT_APP_ENV
const version: any = process.env.REACT_APP_ENV

const sentryEnv = !env ? 'local' : env
const sentryVersion = !version ? 'unknown' : version

if (env === 'production') {
    posthog.init('85WKOsCuFh0A4NK61i42WbOfL4dRGTca17mAU4CiXxY', {
        api_host: 'https://app.posthog.com',
        // capture_pageview: false,
    })
} else {
    posthog.init('lkMjyn_bOrIV-Kahkv0j8tw-ezYbsN2SVFtg-AIHBC0', {
        api_host: 'https://app.posthog.com',
        // capture_pageview: false,
    })
}

Sentry.init({
    dsn:
        sentryEnv === 'local'
            ? undefined
            : 'https://992ca82abc424f59a0e6412ca9a6fcc8@o532609.ingest.sentry.io/5651655',
    integrations: [new Sentry.Replay(), new Sentry.BrowserTracing()],
    tracePropagationTargets: ['/^https://api.clustodian.club/'],
    normalizeDepth: 10,
    debug: sentryEnv === 'production' ? false : true,
    environment: sentryEnv,
    release: 'custodian-frontend@' + sentryVersion,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
})

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <GoogleOAuthProvider clientId="1074406518526-kmhb6c4p5b44vb9tu0crtlh2motecjr3.apps.googleusercontent.com">
                <ShowroomProvider>
                    <InsuranceInitialProvider>
                        <ThemesProvider>
                            <App store={store} history={history} />
                        </ThemesProvider>
                    </InsuranceInitialProvider>
                </ShowroomProvider>
            </GoogleOAuthProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
