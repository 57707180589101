import styled from 'styled-components'

import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import blue_cross from '../../../public/assets/icons/blue_cross.svg'
import blue_bin from '../../../public/assets/icons/blue_bin.svg'
import { motion } from 'framer-motion'
import Faded from '../../templates/animated/faded'
import Expander from '../../atoms/expander/expander'
import { device } from '../../templates/displays/devices'
import { DatePickerConverterFormat } from '../../../redux/conversions/time'
import InputField from '../../atoms/Inputfield/inputField'
import useThemes from '../../../providers/theme/hooks'

const WrapperMobile = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const InputWrapper = styled.section`
    width: 85%;
`

const RemovalIcon = styled(motion.img)<IStyle>`
    height: ${(props) => (props.variant === 'mobile' ? '15px' : '18px')};
    cursor: pointer;
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

interface IStyle {
    variant: string
}

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    selected_date: any
}

interface OtherProps {
    value_start?: any
    dataCyId?: string
    onCrossClick?: any
    variant: 'mobile' | 'desktop'
    customHandleChange?: any
    isDisabled?: boolean
    noChangeAllowed?: boolean
    minDate?: string
    maxDate?: string
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,

        // data,
        errors,
        setFieldValue,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        onCrossClick,
        variant,
        customHandleChange,
        // isDisabled,
        noChangeAllowed,
        minDate,
        maxDate,
    } = props

    const handleAnswerChangeMobile = (date: string) => {
        setFieldValue('selected_date', date)
        customHandleChange && customHandleChange(date)
    }
    // const handleAnswerChangeDesktop = (date: any, dateString: any) => {
    //     setFieldValue('selected_date', dateString)
    //     customHandleChange && customHandleChange(dateString)
    // }

    const { theme } = useThemes()

    return (
        <>
            <WrapperMobile>
                <InputWrapper
                    onClick={(e: any) => {
                        noChangeAllowed && e.preventDefault()
                    }}
                >
                    <InputField
                        theme={theme}
                        id="selected_date"
                        name="selected_date"
                        onChange={handleAnswerChangeMobile}
                        placeholder={'Custom date'}
                        customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.selected_date}
                        font_family="Lato"
                        type="date"
                        min={minDate && minDate}
                        max={maxDate && maxDate}
                        restrictFlipOfDatePopUp
                        backgroundColor={
                            variant === 'mobile'
                                ? undefined
                                : 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        }
                    />
                </InputWrapper>
                <RemovalIcon
                    variant={variant}
                    src={variant === 'mobile' ? blue_cross : blue_bin}
                    onClick={onCrossClick}
                    whileTap={{ scale: 1.1 }}
                />
            </WrapperMobile>
            <Expander height={errors.selected_date ? 'auto' : 0}>
                <Faded>
                    <div style={{ paddingTop: '5px' }} />
                    <Error className="animated fadeIn slower">
                        {errors.selected_date}
                    </Error>
                </Faded>
            </Expander>
        </>
    )
}

const DateWithRemovalIconMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        selected_date: props.value_start
            ? DatePickerConverterFormat(props.value_start)
            : null,
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            let today = new Date()
            return Yup.object().shape({
                selected_date: Yup.date()
                    .typeError('Please enter a valid date.')
                    .min(today, 'Selected date cannot be today or in the past'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(DateWithRemovalIconMobile)
