import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

export default function ShareLinkIcon({ size, color }: Props) {
    const { theme } = useThemes()
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.53027 2.46973L7.53027 6.46973L6.46961 7.53039L2.46961 3.53039L3.53027 2.46973Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.75 10C2.75 10.4414 2.92534 10.8647 3.23744 11.1768L4.82322 12.7626C5.13532 13.0747 5.55862 13.25 6 13.25C6.44138 13.25 6.86468 13.0747 7.17678 12.7626L7.96967 11.9697L9.03033 13.0303L8.23744 13.8232C7.64403 14.4166 6.8392 14.75 6 14.75C5.1608 14.75 4.35597 14.4166 3.76256 13.8232L2.17678 12.2374C1.58337 11.644 1.25 10.8392 1.25 10C1.25 9.1608 1.58337 8.35597 2.17678 7.76256L2.96967 6.96967L4.03033 8.03033L3.23744 8.82322C2.92534 9.13532 2.75 9.55862 2.75 10Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.25 6C13.25 5.55862 13.0747 5.13532 12.7626 4.82322L11.1768 3.23744C10.8647 2.92534 10.4414 2.75 10 2.75C9.55862 2.75 9.13532 2.92534 8.82322 3.23744L8.03033 4.03033L6.96967 2.96967L7.76256 2.17678C8.35597 1.58337 9.1608 1.25 10 1.25C10.8392 1.25 11.644 1.58337 12.2374 2.17678L13.8232 3.76256C14.4166 4.35597 14.75 5.1608 14.75 6C14.75 6.8392 14.4166 7.64403 13.8232 8.23744L13.0303 9.03033L11.9697 7.96967L12.7626 7.17678C13.0747 6.86468 13.25 6.44138 13.25 6Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.53027 8.46973L13.5303 12.4697L12.4696 13.5304L8.46961 9.53039L9.53027 8.46973Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}
