// create store for todos (basically reducer for the main final store)

import { ITutorialCheck } from './types'

import * as actions from './actions'
// import * as loadingActions from './actions/loadingActions'
// import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
// type LoadingActions = ActionType<typeof loadingActions>
// type ErrorActions = ActionType<typeof errorActions>

export const tutorials_check_reducer = (
    state: ITutorialCheck = {
        isFirstSession: false,
        tutorialsCheckByPageId: {
            tasks: false,
            garage: false,
            car: false,
        },
    },
    // action: LoadingActions | Action | ErrorActions
    action: Action
): ITutorialCheck => {
    switch (action.type) {
        case actions.setIsFirstSessionSuccess.type:
            return Object.assign({}, state, {
                ...state,
                isFirstSession: true,
            })

        case actions.manageUserTutorialByIdCheck.type:
            return Object.assign({}, state, {
                ...state,
                tutorialsCheckByPageId: {
                    ...state.tutorialsCheckByPageId,
                    [action.payload]: true,
                },
            })

        default:
            return state
    }
}

export default tutorials_check_reducer

export type TutorialsCheckState = ReturnType<typeof tutorials_check_reducer>
