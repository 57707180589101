import styled from 'styled-components'
import IconButton from '../../../../atoms/Button/iconButton'
import { device } from '../../../displays/devices'
import { motion } from 'framer-motion'

const Container = styled.div<StyleProps>`
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.$isMobile ? '72px' : '80px')};
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    z-index: 100;
    background-color: var(--bg-color, #fff);
    border-top: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;
    z-index: 1;
    @media (max-width: 340px) {
        padding-left: 12px;
        padding-right: 12px;
    }
`

const RightSide = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 16px;
    @media (max-width: 340px) {
        transform: scale(0.9);
    }
`
const LeftSide = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: 100%;
    @media (max-width: 340px) {
        transform: scale(0.9);
    }
`

const CloseBtn = styled(motion.button)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    cursor: pointer;
    height: 48px;
    background: transparent;
    color: var(--text-default, #666666);
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
    :hover {
        border-width: 2px;
    }
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
        height: 40px;
    }
`

const GoBackBtn = styled(CloseBtn)``

const SendApplicationBtn = styled(motion.button)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    color: var(--bg-color, #fff);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
    background-color: var(--primary, #5ec3ca);
    :hover {
        background-color: var(--primary_strong);
    }

    @media ${device.mobile_and_ipad} {
        font-size: 14px;
        height: 40px;
    }
`

type StyleProps = {
    $isMobile?: boolean
}

type Props = {
    isMobile?: boolean
    onClose: () => any
    onSendApplication: () => any
    goBack: () => any
}
const InsuranceCustomBottomBarSubmission = (props: Props) => {
    let { isMobile, onClose, goBack, onSendApplication } = props
    return (
        <Container $isMobile={isMobile}>
            <LeftSide>
                <CloseBtn onClick={onClose} whileTap={{ scale: 1.05 }}>
                    Close
                </CloseBtn>
            </LeftSide>
            <RightSide>
                {isMobile ? (
                    <IconButton
                        icon="chevron_left"
                        borderColor="var(--border-muted, #e5e5e5)"
                        bgcolor="transparent"
                        buttonheight={'40px'}
                        buttonwidth={'40px'}
                        iconheight="16px"
                        iconwidth="16px"
                        onClick={goBack}
                    />
                ) : (
                    <GoBackBtn onClick={goBack} whileTap={{ scale: 1.05 }}>
                        Go back
                    </GoBackBtn>
                )}
                <SendApplicationBtn
                    whileTap={{ scale: 1.05 }}
                    onClick={onSendApplication}
                >
                    Confirm & Submit
                </SendApplicationBtn>
            </RightSide>
        </Container>
    )
}

export default InsuranceCustomBottomBarSubmission
