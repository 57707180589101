/// <reference types="googlemaps" />
import * as React from 'react'
import { connect } from 'react-redux'
import AccountAddressFormMobile from './accontAddressFormMobile'
import { IUserAddressApi } from 'IapiDataPayload'
import { getRightSchemaAccountData } from '../validationSchema'
import { IInsuranceDropdownItem } from '../../../../../redux/localdata/dropdownData/sagas/setInsuranceFieldDataByIDs'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'

type Props = {
    toggleAddresForm: any
    addAddress: any
    currentAddress: IUserAddressApi | null
    countriesdropdownlist: any
    usaAdminCodes: IInsuranceDropdownItem[] | null
    setUSAStatesDropdownRequest: () => any
}

type LocalState = {
    street_number: string
    route: string
    postal_town: string
    postal_code: string
    country: string
    administrative_area_level_1?: string
    administrative_area_level_2?: string
    countryChangedFromSearch: boolean
    error: { [key: string]: string | undefined } | undefined
}

const dispatchProps = {
    setUSAStatesDropdownRequest: () =>
        dropDownActions.setUSAStatesDropdownRequest(),
}

class AccountAddressFormManagerMobile extends React.Component<
    Props,
    LocalState
> {
    state = {
        street_number:
            this.props.currentAddress && this.props.currentAddress.street_1
                ? this.props.currentAddress.street_1
                : '',
        route:
            this.props.currentAddress && this.props.currentAddress.street_2
                ? this.props.currentAddress.street_2
                : '',
        postal_town:
            this.props.currentAddress && this.props.currentAddress.locality
                ? this.props.currentAddress.locality
                : '',
        postal_code:
            this.props.currentAddress && this.props.currentAddress.postcode
                ? this.props.currentAddress.postcode
                : '',
        country:
            this.props.currentAddress && this.props.currentAddress.country
                ? this.props.currentAddress.country
                : '',
        administrative_area_level_1:
            this.props.currentAddress &&
            this.props.currentAddress.administrative_area
                ? this.props.currentAddress.administrative_area
                : '',
        administrative_area_level_2:
            this.props.currentAddress &&
            this.props.currentAddress.administrative_area
                ? this.props.currentAddress.administrative_area
                : '',
        countryChangedFromSearch: false,
        error: undefined,
    }
    // STEPS:

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.

    async componentDidMount() {
        let input: any = document.getElementById(
            'autocompletemobileaccountaddress'
        )

        const googleMaps = async () => {
            try {
                let autocomplete = new google.maps.places.Autocomplete(input, {
                    types: ['geocode'],
                })

                autocomplete.setFields(['address_component'])

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (
                        position
                    ) {
                        let geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }

                        let circle = new google.maps.Circle({
                            center: geolocation,
                            radius: position.coords.accuracy,
                        })
                        autocomplete.setBounds(circle.getBounds())
                    })
                }
                autocomplete.addListener('place_changed', () => {
                    let place: any = autocomplete.getPlace()

                    let addressComponents: string[] = []

                    place.address_components !== undefined &&
                        place.address_components.map((chunk: any) => {
                            addressComponents.push(chunk.types[0])
                            if (chunk.types[0] === 'street_number') {
                                this.validateForm(
                                    'street_number',
                                    chunk.long_name
                                )
                                return this.setState({
                                    ...this.state,
                                    street_number: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'route') {
                                this.validateForm('route', chunk.long_name)
                                return this.setState({
                                    ...this.state,
                                    route: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'postal_code') {
                                this.validateForm(
                                    'postal_code',
                                    chunk.long_name
                                        ? `${chunk.long_name}`.toUpperCase()
                                        : ''
                                )
                                return this.setState({
                                    ...this.state,
                                    postal_code: chunk.long_name
                                        ? `${chunk.long_name}`.toUpperCase()
                                        : '',
                                })
                            } else if (chunk.types[0] === 'locality') {
                                return this.setState({
                                    ...this.state,
                                    postal_town: chunk.long_name,
                                })
                            } else if (chunk.types[0] === 'postal_town') {
                                this.validateForm(
                                    'postal_town',
                                    chunk.long_name
                                )
                                return this.setState({
                                    ...this.state,
                                    postal_town: chunk.long_name,
                                })
                            } else if (
                                chunk.types[0] === 'administrative_area_level_1'
                            ) {
                                return this.setState({
                                    ...this.state,
                                    administrative_area_level_1:
                                        chunk.short_name,
                                })
                            } else if (
                                chunk.types[0] === 'administrative_area_level_2'
                            ) {
                                return this.setState({
                                    ...this.state,
                                    administrative_area_level_2:
                                        chunk.short_name,
                                })
                            } else if (chunk.types[0] === 'country') {
                                return this.setState({
                                    ...this.state,
                                    country: chunk.long_name,
                                    countryChangedFromSearch: true,
                                })
                            }
                            return false
                        })

                    if (!addressComponents.includes('street_number')) {
                        this.validateForm('street_number', '')
                        this.setState({
                            ...this.state,
                            street_number: '',
                        })
                    }
                    if (!addressComponents.includes('route')) {
                        this.validateForm('route', '')
                        this.setState({
                            ...this.state,
                            route: '',
                        })
                    }
                    if (!addressComponents.includes('postal_code')) {
                        this.validateForm('postal_code', '')
                        this.setState({
                            ...this.state,
                            postal_code: '',
                        })
                    }
                    if (!addressComponents.includes('postal_town')) {
                        this.validateForm('postal_town', '')
                        this.setState({
                            ...this.state,
                            postal_town: '',
                        })
                    }
                })
            } catch (e) {
                console.error(e)
            } finally {
            }
        }

        googleMaps()

        if (
            this.state.country === 'United States' &&
            !this.props.usaAdminCodes
        ) {
            this.props.setUSAStatesDropdownRequest()
        }
    }

    handleSelectCountry = (answer: string) => {
        this.setState({
            ...this.state,
            administrative_area_level_1:
                answer !== 'United States' &&
                this.state.administrative_area_level_1
                    ? ''
                    : this.state.administrative_area_level_1,
            administrative_area_level_2:
                answer !== 'Spain' && this.state.administrative_area_level_2
                    ? ''
                    : this.state.administrative_area_level_2,
            country: answer,
            countryChangedFromSearch: false,
            error: this.state.error
                ? // @ts-ignore
                  { ...this.state.error, country: undefined }
                : { country: undefined },
        })

        if (answer === 'United States' && !this.props.usaAdminCodes) {
            this.props.setUSAStatesDropdownRequest()
        }
    }

    validateForm = async (id: string, value: string) => {
        try {
            await getRightSchemaAccountData(id).validate({ [id]: value })
            this.setState({
                ...this.state,
                error: this.state.error
                    ? // @ts-ignore
                      { ...this.state.error, [id]: undefined }
                    : { [id]: undefined },
            })
        } catch (err: any) {
            this.setState({
                ...this.state,
                error: this.state.error
                    ? // @ts-ignore
                      { ...this.state.error, [id]: err.errors[0] }
                    : { [id]: err.errors[0] },
            })
        }
    }

    handleChange: React.ReactEventHandler<HTMLInputElement> = (ev) => {
        let value = ev.currentTarget.value
        let name = ev.currentTarget.name

        if (name === 'postal_code') {
            value = value?.toUpperCase()
        }
        if (
            [
                'street_number',
                'route',
                'name',
                'phone',
                'postal_town',
                'postal_code',
                'country',
            ].includes(name)
        ) {
            this.validateForm(name, value)
        }

        this.setState({
            ...this.state,
            [name]: value,
        })
    }

    handleSelectAdmin1Code = (answer: string) => {
        this.setState({
            ...this.state,
            administrative_area_level_1: answer,
        })
    }

    isUndefined = (index: any) => {
        return typeof index === 'undefined'
    }

    onSubmit = () => {
        // convert to BE type

        let addressObj: IUserAddressApi = {
            type: 'User Address',
            street_1: this.state.street_number,
            street_2: this.state.route,
            locality: this.state.postal_town,
            postcode: this.state.postal_code,
            administrative_area:
                this.state.country === 'United States'
                    ? this.state.administrative_area_level_1
                    : this.state.country === 'Spain'
                    ? this.state.administrative_area_level_2
                    : undefined,
            country: this.state.country,
        }

        let check = true

        if (
            this.state.street_number.length === 0 ||
            this.state.route.length === 0 ||
            this.state.country.length === 0 ||
            this.state.postal_code.length === 0 ||
            this.state.postal_town.length === 0
        ) {
            check = false
        }

        if (check === false) {
            let arr = [
                'street_number',
                'route',
                'postal_town',
                'postal_code',
                'country',
            ]
            arr.forEach((fieldName: string) =>
                this.validateForm(
                    fieldName, //@ts-ignore
                    this.state[fieldName]
                )
            )
        }

        if (check) {
            this.props.addAddress(addressObj)
            return this.props.toggleAddresForm()
        }
    }

    isFormValid = () => {
        let check = this.state.error
            ? Object.values(this.state.error).some((val) => val !== undefined)
            : undefined
        if (check) {
            return false
        } else return true
    }

    render() {
        const {
            street_number,
            route,
            postal_town,
            postal_code,
            country,
            administrative_area_level_1,
            countryChangedFromSearch,
            error,
        } = this.state

        const { toggleAddresForm, countriesdropdownlist } = this.props

        return (
            <React.Fragment>
                <AccountAddressFormMobile
                    street_number={street_number}
                    postal_town={postal_town}
                    route={route}
                    postal_code={postal_code}
                    country={country}
                    onSubmit={this.onSubmit}
                    handleChange={this.handleChange}
                    isFormValid={this.isFormValid}
                    toggle={toggleAddresForm}
                    countriesdropdownlist={countriesdropdownlist}
                    handleSelectCountry={this.handleSelectCountry}
                    administrative_area_level_1={administrative_area_level_1}
                    countryUpdatedFromSearchedAddress={
                        countryChangedFromSearch ? country : null
                    }
                    handleSelectAdmin1Code={this.handleSelectAdmin1Code}
                    usaAdminCodes={this.props.usaAdminCodes}
                    error={error}
                />
            </React.Fragment>
        )
    }
}

export default connect(null, dispatchProps)(AccountAddressFormManagerMobile)
