/// <reference path="./types.d.ts" />;
// create store for todos (basically reducer for the main final store)

import { ActionType } from 'typesafe-actions'

import * as successActions from './actions/successActions'
import * as loadingActions from './actions/requestActions'
import * as errorActions from './actions/errorActions'
import { ApexReducerState } from 'apexModels'

type SuccessAction = ActionType<typeof successActions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: ApexReducerState = {
    homepageData: undefined,
    carsOfTheWeekData: undefined,
    apexPodcastsData: undefined,
    weeklyHighlightsData: undefined,
    singleInterviews: {},
    singleArticles: {},
    articleOverviews: undefined,
    loading: false,
    error: null,
}

export const apexReducerState = (
    state: ApexReducerState = initialState,
    action: LoadingActions | SuccessAction | ErrorActions
): ApexReducerState => {
    switch (action.type) {
        case loadingActions.getApexHomepageContentRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getApexHomepageContentSuccess.type:
            return Object.assign({}, state, {
                homepageData: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getApexHomepageContentError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getApexCarsOfTheWeekRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getApexCarsOfTheWeekSuccess.type:
            return Object.assign({}, state, {
                carsOfTheWeekData: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getApexCarsOfTheWeekError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getApexPodcastsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getApexPodcastsSuccess.type:
            return Object.assign({}, state, {
                apexPodcastsData: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getApexPodcastsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getWeeklyHighlightsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getWeeklyHighlightsSuccess.type:
            return Object.assign({}, state, {
                weeklyHighlightsData: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getWeeklyHighlightsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getApexInterviewOverviewsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getApexInterviewOverviewsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                interviewOverviewsPageData: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getApexInterviewOverviewsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getApexSingleInterviewByUIDRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getApexSingleInterviewByUIDSuccess.type:
            return Object.assign({}, state, {
                ...state,
                singleInterviews: {
                    ...state.singleInterviews,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case errorActions.getApexSingleInterviewByUIDError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getApexSingleArticleByUIDRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getApexSingleArticleyUIDSuccess.type:
            return Object.assign({}, state, {
                ...state,
                singleArticles: {
                    ...state.singleArticles,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case errorActions.getApexSingleArticleByUIDError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getApexArticleOverviewsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getApexArticleOverviewsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                articleOverviews: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getApexArticleOverviewsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        default:
            return state
    }
}

export default apexReducerState

export type ApexState = ReturnType<typeof apexReducerState>
