import { call, fork, put, take } from 'redux-saga/effects'
import { api } from '../../services'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { showroomActions } from '../reducer'

type Iarg = { entryID: string; carID: string }

export function* DeleteShowroomEntryByID(payload: Iarg): any {
    try {
        yield call(api.showroom.deleteShowroomEntry, payload.entryID)
        yield put(showroomActions.deleteEntryShowroomSuccess(payload.entryID))
        yield put(
            showroomActions.showroomEntryDeletionConfirmed(payload.entryID)
        )

        // resetting nb of for sale results
        yield put(showroomActions.resetNumberOfForSaleResults())
        // resetting nb of sold results
        yield put(showroomActions.resetNumberOfSoldResults())
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: DeleteShowroomEntryByID,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'showroom'
            )
            yield put(showroomActions.deleteEntryShowroomError(customError))
        }
    }
}

function* watcherDeleteShowroomEntryByID() {
    while (true) {
        const { payload } = yield take(
            showroomActions.deleteEntryShowroomRequest
        )

        yield call(DeleteShowroomEntryByID, payload)
    }
}

const delete_showroom_entry_by_id: any[] = [
    fork(watcherDeleteShowroomEntryByID),
]

export default delete_showroom_entry_by_id
