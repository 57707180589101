import * as actions from './actions'
import { ThunkAction } from 'redux-thunk'
import { RootAction } from 'typesafe-actions'

export const selectContactReasonThunks = (
    payload: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(actions.selectContactReason(payload))
        })
    }
}
