import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import TimelineEditManagerMobile from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerMobile'
import {
    ICostItem,
    IDeleteCostItemPayloadRequest,
    IGetCostEntryPayloadRequest,
    IGetTimelineItemByIdPayloadRequest,
    IGetTimelineItemsByCarIDPayloadRequest,
    ITimelineDataByCarNormalised,
    ITimelineItem,
    IUpdateCostItemPayloadRequest,
    IUpdateEntryLabelPayload,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'
import { ILabel, ITimelineEditForms, IUser } from 'myModels'
import {
    deleteTimelineItemCostRequest,
    getAllTimelineItemsByCarIDRequest,
    getCostEntryByIdRequest,
    getTimelineItemByIDRequest,
    removeLabelFromCarEntryRequest,
    updateTimelineEntryRequest,
    updateTimelineItemCostRequest,
} from '../../../redux/timeline/actions/requestActions'
import TimelineEditManagerDesktop from '../../organisms/timelineForms/timelineEditManager/timelineEditManagerDesktop'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import {
    setActiveCostSuccess,
    setActiveEntrySuccess,
} from '../../../redux/timeline/actions/successActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { TimelineFormTypeIDsEnum } from '../../../redux/timeline/timelineEnum'
import BottomUpSlider from '../../templates/animated/bottomUpSlider'
import WindowPageTemplate from '../../templates/windowPageTemplate/windowPageTemplateDesktop'
import DeletionConfirmationDesktop from '../../molecules/deletionConfirmationDesktop/deletionConfirmationDesktop'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import { IDropdownItem } from 'entityModels'
import DeletionConfirmationBottomSheet from '../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { timeline_category_data } from '../../../redux/timeline/data'

interface Props extends RouteComponentProps<any> {
    activeEntry: ITimelineItem | undefined | null
    activeCost: ICostItem | undefined | null
    timelineItemEditForm: ITimelineEditForms
    manageTimelineItemEditForm: (payload: ITimelineEditForms) => void
    updateTimelineItemCost: (payload: IUpdateCostItemPayloadRequest) => void
    deleteEntryCost: (payload: IDeleteCostItemPayloadRequest) => void
    attachmentsObj: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) => {}
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) => void
    setActiveEntry: (payload: ITimelineItem) => void
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    setCurrentCar: (car_id: string) => void
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    dataCyId?: string
    getCostByUid: (payload: IGetCostEntryPayloadRequest) => {}
    entry_labels_list: ILabel[] | undefined
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) => void
    user_searchable_labels_list: IDropdownItem[] | null | undefined
    setActiveCost: (payload: ICostItem) => void
    setInitialLandingUrl: (str: string) => void
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemsByCarIDPayloadRequest
    ) => {}
    isCollapsed: boolean
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        activeEntry: state.timeline.activeEntry,
        activeCost: state.timeline.activeCost,
        timelineItemEditForm: state.editForms.timelineItemEditForm,
        attachmentsObj: state.attachments.attachmentsById,
        user_searchable_labels_list:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
        entry_labels_list:
            state.timeline.activeEntry && state.timeline.activeEntry.labels,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
        // costsObj: state.timeline.cost_items_normalised_by_cost_id,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    manageTimelineItemEditForm: (payload: ITimelineEditForms) =>
        editFormsActions.manageTimelineItemEditForm(payload),
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) =>
        getTimelineItemByIDRequest(payload),
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) =>
        updateTimelineEntryRequest(payload),
    setActiveEntry: (payload: ITimelineItem) => setActiveEntrySuccess(payload),
    getCostByUid: (payload: IGetCostEntryPayloadRequest) =>
        getCostEntryByIdRequest(payload),
    updateTimelineItemCost: (payload: IUpdateCostItemPayloadRequest) =>
        updateTimelineItemCostRequest(payload),
    deleteEntryCost: (payload: IDeleteCostItemPayloadRequest) =>
        deleteTimelineItemCostRequest(payload),
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) =>
        removeLabelFromCarEntryRequest(payload),
    setActiveCost: (payload: ICostItem) => setActiveCostSuccess(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemsByCarIDPayloadRequest
    ) => getAllTimelineItemsByCarIDRequest(payload),
}

type State = {
    showConfirmDeletionDesktop: boolean
    showConfirmDeletionMobile: boolean
    showRightSide: boolean
}

class EditCost extends React.PureComponent<Props, State> {
    state = {
        showConfirmDeletionDesktop: false,
        showConfirmDeletionMobile: false,
        showRightSide: true,
    }

    _isMounted = false

    handleResize = () =>
        window.innerWidth < 1201
            ? this._isMounted &&
              this.setState({ ...this.state, showRightSide: false })
            : this._isMounted &&
              this.setState({ ...this.state, showRightSide: true })

    componentDidMount() {
        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/history-file/${this.props.match.params.entryid}/cost/edit`
        )

        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        this._isMounted = true

        const q_params = new URLSearchParams(this.props.location.search)
        let costid: string | null = q_params.get('cost_id')
        let activeEntry: ITimelineItem | undefined | null =
            this.props.activeEntry

        if (!activeEntry) {
            let carid = this.props.match.params.carid
            this.props.setCurrentCar(carid)
            let entryidFromParam = this.props.match.params.entryid

            // start handling adding label from outside single entry page

            let all_entries: ITimelineItem[] | null | undefined =
                this.props.carsTimelineData &&
                this.props.carsTimelineData[carid] &&
                this.props.carsTimelineData[carid].entries

            if (!all_entries) {
                this.props.getAllTimelineItemsByCarIDRequest({
                    car_id: carid,
                })
            }

            let indexOFActiveEntryParam: number | undefined =
                all_entries?.findIndex(function (entry: ITimelineItem) {
                    return entry.id === entryidFromParam
                })

            let activeEntryFromParams =
                all_entries &&
                indexOFActiveEntryParam !== undefined &&
                all_entries[indexOFActiveEntryParam]
                    ? all_entries[indexOFActiveEntryParam]
                    : undefined

            activeEntryFromParams &&
                this.props.setActiveEntry(activeEntryFromParams)

            // end handling adding label from outside single entry page

            if (userLoggedIn) {
                if (!this.props.activeEntry) {
                    this.props.getEntryByIDRequest({
                        entryUID: entryidFromParam,
                        car_id: carid,
                    })
                }
            }

            activeEntry = activeEntryFromParams && { ...activeEntryFromParams }
        }

        activeEntry &&
            costid &&
            activeEntry.costItemsObj &&
            activeEntry.costItemsObj[costid] &&
            this.props.setActiveCost(activeEntry.costItemsObj[costid])

        activeEntry &&
            this.props.manageTimelineItemEditForm({
                id: TimelineFormTypeIDsEnum.cost,
                category: activeEntry.categoryID,
                isOpen: true,
            })

        window.scrollTo(0, 0)
        window.innerWidth < 1201
            ? this._isMounted &&
              this.setState({ ...this.state, showRightSide: false })
            : this._isMounted &&
              this.setState({ ...this.state, showRightSide: true })
        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate(prevProps: any) {
        if (
            this.props.activeEntry !== prevProps.activeEntry ||
            this.props.activeCost !== prevProps.activeCost ||
            prevProps.userLoggedIn !== this.props.userLoggedIn ||
            prevProps.carsTimelineData !== this.props.carsTimelineData
        ) {
            let activeEntry: ITimelineItem | undefined | null =
                this.props.activeEntry

            if (!activeEntry) {
                let carid = this.props.match.params.carid
                let entryidFromParam = this.props.match.params.entryid
                let all_entries: ITimelineItem[] | null | undefined =
                    this.props.carsTimelineData &&
                    this.props.carsTimelineData[carid] &&
                    this.props.carsTimelineData[carid].entries

                let indexOFActiveEntryParam: number | null | undefined =
                    all_entries &&
                    all_entries?.findIndex(function (entry: ITimelineItem) {
                        return entry.id === entryidFromParam
                    })

                let activeEntryFromParams =
                    all_entries &&
                    indexOFActiveEntryParam !== undefined &&
                    indexOFActiveEntryParam !== null &&
                    all_entries[indexOFActiveEntryParam]
                        ? all_entries[indexOFActiveEntryParam]
                        : undefined

                activeEntryFromParams &&
                    this.props.setActiveEntry(activeEntryFromParams)

                activeEntry = activeEntryFromParams && {
                    ...activeEntryFromParams,
                }
            }

            const q_params = new URLSearchParams(this.props.location.search)
            let costid: string | null = q_params.get('cost_id')

            activeEntry &&
                costid &&
                activeEntry?.costItemsObj &&
                activeEntry?.costItemsObj[costid] &&
                this.props.setActiveCost(activeEntry?.costItemsObj[costid])

            activeEntry &&
                this.props.manageTimelineItemEditForm({
                    id: TimelineFormTypeIDsEnum.cost,
                    category: activeEntry.categoryID,
                    isOpen: true,
                })
        }
    }

    componentWillUnmount() {
        this.props.manageTimelineItemEditForm({
            isOpen: false,
        })
        this._isMounted = false
        window.removeEventListener('resize', this.handleResize)
    }

    generatePrePopulatedLabels = (): IDropdownItem[] => {
        let { activeEntry, user_searchable_labels_list } = this.props

        let user_searchable_labels_list_names =
            user_searchable_labels_list?.map((label) => label.name)

        if (activeEntry && activeEntry.categoryID) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                activeEntry.categoryID
            ].pre_populated_labels
                .filter(
                    (label) =>
                        !user_searchable_labels_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    render() {
        const q_params = new URLSearchParams(this.props.location.search)
        let costid: string | null = q_params.get('cost_id')

        let entryid: string | null = this.props.match.params.entryid
        let carid: string | null = this.props.match.params.carid

        let {
            manageTimelineItemEditForm,
            activeEntry,
            activeCost,
            timelineItemEditForm,
            updateTimelineItemCost,
            deleteEntryCost,
            isCollapsed,
        } = this.props

        let close_edit_form = () => {
            const redirectToEntryPage = () =>
                activeEntry &&
                activeEntry.id &&
                this.props.history.push(
                    `/car/${carid}/history-file/entry?entryid=${activeEntry.id}`
                )
            setTimeout(redirectToEntryPage, 50)
            manageTimelineItemEditForm({
                isOpen: false,
            })
        }

        return (
            <Faded>
                {costid && carid && entryid && (
                    <CenteredPageWrapper fullwidth>
                        <DesktopDisplayOnly>
                            <div style={{ position: 'relative' }}>
                                <WindowPageTemplate
                                    isCollapsed={isCollapsed}
                                    rightSideContent={
                                        activeEntry &&
                                        this.state.showRightSide && (
                                            <EntryOptionsMenuBar
                                                carid={carid}
                                                activeEntry={activeEntry}
                                            />
                                        )
                                    }
                                >
                                    <TimelineEditManagerDesktop
                                        timeline_item={activeEntry}
                                        item_to_edit={activeCost}
                                        timelineItemEditForm={
                                            timelineItemEditForm
                                        }
                                        savingEdit={(editedCost: ICostItem) => {
                                            if (entryid && costid && carid) {
                                                updateTimelineItemCost({
                                                    itemid: entryid,
                                                    costid: costid,
                                                    carid: carid,
                                                    cost: editedCost,
                                                })
                                            }
                                        }}
                                        closeForm={close_edit_form}
                                        dataCyId={'edit-cost-entry'}
                                        entityType="cost"
                                        entityID={costid}
                                        carID={carid}
                                        onDeleteClick={() =>
                                            this.setState({
                                                ...this.state,
                                                showConfirmDeletionDesktop:
                                                    true,
                                            })
                                        }
                                    />
                                </WindowPageTemplate>

                                <DeletionConfirmationDesktop
                                    isEditForm
                                    isOpen={
                                        this.state.showConfirmDeletionDesktop
                                    }
                                    toggle={() =>
                                        this.setState({
                                            ...this.state,
                                            showConfirmDeletionDesktop: false,
                                        })
                                    }
                                    action={() => {
                                        costid &&
                                            carid &&
                                            entryid &&
                                            deleteEntryCost({
                                                itemid: entryid,
                                                costid: costid,
                                                carid: carid,
                                            })
                                    }}
                                    actionCopy="Confirm deletion"
                                    titleCopy="Are you sure?"
                                    detailsCopy="On deletion, the data will be lost."
                                />
                            </div>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <BottomUpSlider show={timelineItemEditForm.isOpen}>
                                <TimelineEditManagerMobile
                                    item={activeCost ? activeCost : null}
                                    timelineItemEditForm={timelineItemEditForm}
                                    savingEdit={(editedCost: ICostItem) => {
                                        if (entryid && costid && carid) {
                                            updateTimelineItemCost({
                                                itemid: entryid,
                                                costid: costid,
                                                carid: carid,
                                                cost: editedCost,
                                            })
                                        }
                                    }}
                                    closeForm={close_edit_form}
                                    dataCyId={'edit-cost-entry'}
                                    entityType="cost"
                                    entityID={costid}
                                    carID={carid}
                                    onDeleteClick={() =>
                                        this.setState({
                                            ...this.state,
                                            showConfirmDeletionMobile: true,
                                        })
                                    }
                                    // readOnlyMode={readOnlyMode}
                                />
                            </BottomUpSlider>
                            <DeletionConfirmationBottomSheet
                                isOpen={this.state.showConfirmDeletionMobile}
                                toggle={() =>
                                    this.setState({
                                        ...this.state,
                                        showConfirmDeletionMobile: false,
                                    })
                                }
                                action={() => {
                                    costid &&
                                        carid &&
                                        entryid &&
                                        deleteEntryCost({
                                            itemid: entryid,
                                            costid: costid,
                                            carid: carid,
                                        })
                                }}
                                actionCopy="Confirm deletion"
                                titleCopy="Are you sure?"
                                detailsCopy="On deletion, the data will be lost."
                            />
                        </IpadAndMobileDisplay>
                    </CenteredPageWrapper>
                )}
            </Faded>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(EditCost))
