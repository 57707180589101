import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import Question from '../atoms/typography/question'
import MobileDisplay from '../templates/displays/mobileDisplay'
import Loader from '../atoms/loader/loader'
import DesktopDisplay from '../templates/displays/desktopDisplay'
import { IUser } from 'myModels'
import TriangleHeader from '../atoms/header/triangleheader'
import { addPhone } from '../../redux/user/actions/thunks'
import Faded from '../templates/animated/faded'

import PhoneInput from '../organisms/phonefield/phoneinput'
import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isLoading: state.user.loading,
    }
}

const dispatchProps = {
    addPhone: (phone: string) => addPhone(phone),
}

const InitialState = {
    user: null,
}

type State = typeof InitialState

type Props = {
    userLoggedIn: null | IUser
    addPhone: (phone: string) => void
    isLoading: boolean
    // redeemReferral: (recipientUserid: string, code: string) => void;
}

class PhoneDetail extends React.Component<Props, State> {
    render() {
        const user = this.props.userLoggedIn
        const isLoading = this.props.isLoading

        return (
            <PublicPageWrapper>
                {isLoading === true && <Loader />}
                {user === null && <Loader />}
                <Faded>
                    <TriangleHeader title="contact" />

                    <Question>Please enter your phone number:</Question>

                    <DesktopDisplay>
                        <PhoneInput
                            addPhone={this.props.addPhone}
                            isDesktop={true}
                        />
                    </DesktopDisplay>
                </Faded>

                <MobileDisplay>
                    <PhoneInput
                        addPhone={this.props.addPhone}
                        isDesktop={false}
                    />
                </MobileDisplay>
            </PublicPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(PhoneDetail)
