import styled, { keyframes } from 'styled-components'

const motion1 = () => keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const motion2 = () => keyframes`
   0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`
const motion3 = () => keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`
type IStyledProps = {
    size: number
    colour?: string
}

const EllipsisSpinner = styled.div<IStyledProps>`
    display: inline-block;
    position: relative;
    height: 100%;
    width: auto;
    left: ${(props) => `-${props.size * 2}px`};

    div {
        position: absolute;
        top: 50%;

        transform: translateY(-50%);

        width: ${(props) => `${props.size}px`};
        height: ${(props) => `${props.size}px`};
        border-radius: 50%;
        background: ${(props) => props.color ?? '#9fdbdd'};
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    div:nth-child(1) {
        left: 4px;
        animation: ${(p) => motion1()} 0.8s infinite;
    }
    div:nth-child(2) {
        left: 4px;
        animation: ${(p) => motion2()} 0.8s infinite;
    }
    div:nth-child(3) {
        left: 24px;
        animation: ${(p) => motion2()} 0.8s infinite;
    }
    div:nth-child(4) {
        left: 43px;
        animation: ${(p) => motion3()} 0.8s infinite;
    }
`

type Props = {
    size: number
    colour?: string
}

const Ellipsis = (props: Props) => (
    <EllipsisSpinner size={props.size} color={props.colour}>
        <div />
        <div />
        <div />
        <div />
    </EllipsisSpinner>
)

export default Ellipsis
