import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const List = styled.ul`
    all: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`
const Row = styled.li`
    display: flex;
    width: 100%;
    gap: 8px;
    @media ${device.ipad} {
        flex-direction: column;
    }
`

const Item = styled.div`
    display: flex;
    gap: 8px;
`

const Title = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-bold;
    font-size: 14px;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.98px;
    text-transform: uppercase;
`

const Answer = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-default, #666);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato-bold;
    font-size: 14px;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.98px;
    text-transform: uppercase;
`

interface Props {
    list: string[]
    data: any
    default_fuel_unit_user_text?: string | undefined
    default_fuel_unit_user?: string | undefined
    requiredLabel?: boolean
}

const ListItem = (p: { item1: any; item2: any; unit_txt?: string }) => {
    return (
        <Row>
            <Item>
                <Title>{p.item1?.text}</Title>
                <Answer>
                    {p.item1?.answer ?? '-'}{' '}
                    {p.item1.id === 'engine_capacity' &&
                        p.item1?.answer &&
                        (p.unit_txt ? ` ${p.unit_txt}` : ' CC')}
                </Answer>
            </Item>
            <div style={{ paddingRight: 16 }} />
            <Item>
                <Title>{p.item2?.text}</Title>
                <Answer>
                    {p.item2?.answer ?? '-'}{' '}
                    {p.item2.id === 'engine_capacity' &&
                        p.item2?.answer &&
                        (p.unit_txt ? ` ${p.unit_txt}` : ' CC')}
                </Answer>
            </Item>
        </Row>
    )
}

const ListOnboardingDVLdesktop: React.FunctionComponent<Props> = (
    props: Props
) => {
    let { list, data, default_fuel_unit_user_text } = props

    return (
        <List>
            {data &&
                list.map((id: string, index) => {
                    return index % 2 === 0 ? (
                        <React.Fragment key={`onboarding_mobile_${index}`}>
                            <ListItem
                                item1={data[id]}
                                item2={data[list[index + 1]] ?? undefined}
                                unit_txt={default_fuel_unit_user_text}
                            />
                        </React.Fragment>
                    ) : null
                })}
        </List>
    )
}

export default ListOnboardingDVLdesktop
