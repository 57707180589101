import styled from 'styled-components'
import React from 'react'
import { connect } from 'react-redux'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'

type StyledProps = {
    isMainHeaderVisible?: boolean
}

const HeaderWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--slider-banner-pop-over-bg, #fafafa);
    height: 50px;
    -webkit-box-shadow: ${(props) =>
        props.isMainHeaderVisible
            ? '0px 2px 9px -2px var(--box-shadow,rgba(0, 0, 0, 0.08))'
            : 'none'};
    -moz-box-shadow: ${(props) =>
        props.isMainHeaderVisible
            ? '0px 2px 9px -2px var(--box-shadow,rgba(0, 0, 0, 0.08))'
            : 'none'};
    box-shadow: ${(props) =>
        props.isMainHeaderVisible
            ? '0px 2px 9px -2px var(--box-shadow,rgba(0, 0, 0, 0.08))'
            : 'none'};
    padding-left: 20px;
    padding-right: 25px;
    position: fixed;
    top: ${(props) => (props.isMainHeaderVisible ? '0px' : '-52px')};
    transition: all 0.2s;
    left: 0px;
    right: 0px;
    width: 100vw;
    z-index: 3;
`

const Title = styled.div`
    font-family: Lato-Light;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 200;
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    margin-top: 3px;
    letter-spacing: 1.05px;
`

const dispatchProps = {}

function mapStateToProps() {
    return {}
}

type State = {
    prevScrollpos: any
    scrollTransitionX: number
    isMainHeaderVisible: boolean
}
interface Props {
    text: string
    customStyle: React.CSSProperties | undefined
}

class SlidingUpOnScrollBanner extends React.PureComponent<Props, State> {
    state = {
        prevScrollpos: window.pageYOffset,
        scrollTransitionX: 0,
        isMainHeaderVisible: true,
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        this.setState({ ...this.state })
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state

        const currentScrollPos = window.pageYOffset

        const checkvisibility = () => {
            if (currentScrollPos < 10) {
                return true
            }

            if (prevScrollpos > currentScrollPos) {
                return true
            } else {
                return false
            }
        }

        return this.setState({
            prevScrollpos: currentScrollPos,
            isMainHeaderVisible: checkvisibility(),
        })
    }

    render() {
        let { text, customStyle } = this.props

        return (
            <IpadAndMobileDisplay>
                <HeaderWrapper
                    isMainHeaderVisible={this.state.isMainHeaderVisible}
                    style={customStyle}
                >
                    <Title>{text}</Title>
                </HeaderWrapper>
            </IpadAndMobileDisplay>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(SlidingUpOnScrollBanner)
