import React from 'react'
import { connect } from 'react-redux'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CarGalleryManagerDesktop from '../../organisms/galleryManager/carGalleryManagerDesktop'
import { RootState } from 'typesafe-actions'
// import GallerySlide from "../../molecules/slideshow/gallerySlide";
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import SideSlider from '../../templates/animated/sideSlider'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
// import ReturnNav from '../../atoms/header/returnNav'
import CarGalleryManagerMobile from '../../organisms/galleryManager/carGalleryManagerMobile'

import {
    ICar,
    IGalleryImagesObject,
    IUpdateCarGalleryImagePayload,
} from 'entityModels'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { editFormsActions } from '../../../redux/editForms/reducer'

import WrapperImageFormsDesktop from '../../organisms/editForms/imageFormWrapper/wrapperImageFormsDesktop'
import WrapperImageFormsMobile from '../../organisms/editForms/imageFormWrapper/wrapperImageFormMobile'
import CarGalleryEditExistingImageFormDesktop from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormDesktop'
import { updateCarGalleryImageRequest } from '../../../redux/entities/galleries/actions/loadingActions'
import PortalInvisibleWrapper from '../../templates/displays/pageWrappers/portalInvisibleWrapper'

// import GalleryImage from './galleryImage'
import AndroidInstallPwaInstructionsPropmt from '../../templates/pwaCustom/androidInstallPwaInstructionsPropmt'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'

import { IUser } from 'myModels'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { getCookie } from '../../../redux/services/authcheck'
import Loader from '../../atoms/loader/loader'
import GalleryImageV2 from './galleryImagev2'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../redux/showroom/types'
import { showroomActions } from '../../../redux/showroom/reducer'
import { IGetShowroomByEntry } from '../../../redux/showroom/sagas/getShowroomEntry'

interface Props extends RouteComponentProps<any> {
    location: any
    isGalleryLoading: boolean
    isCarLoading: boolean
    carsData: {
        [key: string]: ICar
    }
    isCargalleryFormEditOpened: boolean
    toggleCarGalleryEditForm: () => any
    imagesData: IGalleryImagesObject | null
    currentcarid: string | undefined
    setCurrentCar: (id: string) => void
    history: any
    toggleExistingCarGalleryImageEditForm: () => {}
    isExistingcarGalleryImageFormOpened: boolean
    updateCarGalleryImageRequest: (data: IUpdateCarGalleryImagePayload) => void
    getCarDataByIdRequest: (id: string) => void
    userLoggedIn: IUser | null
    setInitialLandingUrl: (str: string) => void
    getUserDataRequest: () => void
    isNecessaryGalleryDataLoading: boolean
    isGettingCarDataLoading: boolean
    getShowroomEntryByIDRequest: (p: IGetShowroomByEntry) => void
    userShowroomEntriesData: INormalisedShowroomEntries | undefined
}

const dispatchProps = {
    getCarDataByIdRequest: (id: string) => getCarDataByIdRequest(id),
    toggleCarGalleryEditForm: () => editFormsActions.toggleCarGalleryEditForm(),
    setCurrentCar: (id: string) => setCurrentCar(id),
    toggleExistingCarGalleryImageEditForm: () =>
        editFormsActions.toggleExistingCarGalleryImageEditForm(),
    updateCarGalleryImageRequest: (data: IUpdateCarGalleryImagePayload) =>
        updateCarGalleryImageRequest(data),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getShowroomEntryByIDRequest: (p: IGetShowroomByEntry) =>
        showroomActions.getShowroomEntryByIDRequest(p),
}

function mapStateToProps(state: RootState) {
    return {
        carsData: state.entities.carsData.cars,
        isCarLoading: state.entities.carsData.loading,
        isGalleryLoading: state.entities.galleriesData.loading,
        isCargalleryFormEditOpened:
            state.editForms.formsData.carGalleryEditForm.isOpen,
        imagesData: state.entities.galleriesData.images,
        currentcarid:
            state.entities.carsData.currentCarID &&
            state.entities.carsData.currentCarID,
        isExistingcarGalleryImageFormOpened:
            state.editForms.formsData.carExistingGalleryImageEditForm.isOpen,
        userLoggedIn: state.user.userLoggedIn,
        isNecessaryGalleryDataLoading: state.entities.galleriesData.loading,
        isGettingCarDataLoading: state.entities.carsData.loading,
        userShowroomEntriesData: state.showroom.userShowroomEntries,
    }
}

type State = {
    imageUploadedData: any
    progressArray?: number[]
}

class Gallery extends React.Component<Props, State> {
    state = {
        imageUploadedData: null,
        progressArray: undefined,
    }

    checkDirection = () => {
        if (this.props.location.state !== undefined) {
            if (
                this.props.location.state.prevPath === '/car/gallery/image-id'
            ) {
                return 'left'
            }
            if (this.props.location.state.prevPath === '/car') {
                return 'right'
            } else return ''
        } else return ''
    }

    setImageUploaded = (imgUploadedData: any) => {
        this.setState({ imageUploadedData: imgUploadedData })
    }

    deleteImageUploaded = () => {
        this.setState({ imageUploadedData: null })
    }

    manageShowroomEntryData = () => {
        let carid: string = this.props.match.params.carid

        let currentCar: ICar | undefined =
            this.props.carsData && carid && this.props.carsData[carid]
                ? this.props.carsData[carid]
                : undefined

        let isCarOwner =
            currentCar &&
            this.props.userLoggedIn &&
            currentCar.owner_id === this.props.userLoggedIn?.id

        if (isCarOwner === true) {
            let hasEntryID: string | undefined =
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'PUBLISHED') ||
                (currentCar &&
                    currentCar.showroom_entry &&
                    currentCar.showroom_entry.state === 'SOLD')
                    ? currentCar.showroom_entry.uid
                    : undefined

            if (hasEntryID) {
                let currentEntry: IShowroomEntry | undefined =
                    hasEntryID &&
                    this.props.userShowroomEntriesData &&
                    this.props.userShowroomEntriesData[hasEntryID]
                        ? this.props.userShowroomEntriesData[hasEntryID]
                        : undefined

                if (!currentEntry) {
                    this.props.getShowroomEntryByIDRequest({
                        is_owner: true,
                        entryID: hasEntryID,
                    })
                }
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/gallery`
        )

        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }

        if (userData) {
            this.manageShowroomEntryData()

            let carid: string = this.props.match.params.carid

            if (this.props.isCargalleryFormEditOpened === true) {
                this.props.toggleCarGalleryEditForm()
            }

            this.props.setCurrentCar(this.props.match.params.carid)

            this.props.getCarDataByIdRequest(carid)
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn) {
                let carid: string = this.props.match.params.carid

                this.manageShowroomEntryData()

                if (this.props.isCargalleryFormEditOpened === true) {
                    this.props.toggleCarGalleryEditForm()
                }

                this.props.setCurrentCar(this.props.match.params.carid)

                this.props.getCarDataByIdRequest(carid)
            }
        }

        if (this.props.carsData !== prevProps.carsData) {
            this.manageShowroomEntryData()
        }
    }

    render() {
        const {
            isGalleryLoading,
            isCarLoading,
            toggleCarGalleryEditForm,
            isCargalleryFormEditOpened,
            carsData,
            // history,
            toggleExistingCarGalleryImageEditForm,
            isExistingcarGalleryImageFormOpened,
            updateCarGalleryImageRequest,
            imagesData,
            isNecessaryGalleryDataLoading,
            isGettingCarDataLoading,
        } = this.props

        let carid: string = this.props.match.params.carid
        let current: ICar | undefined =
            carsData && carid && carsData[carid] ? carsData[carid] : undefined

        const params = new URLSearchParams(this.props.location.search)

        const currentImageData =
            imagesData && params.get('id')
                ? imagesData[params.get('id')!]
                : null

        return (
            <React.Fragment>
                {current?.archived_on && (
                    <Redirect to={`/archives/car/${current.id}/gallery`} />
                )}
                <Loader isLoading={isCarLoading || isGalleryLoading} />
                {!currentImageData && (
                    <FabsMenusMobile hasAdd={false} hasSubNav={true} />
                )}

                {/* {!params.get('id') &&
                    getCookie('ios_install_prompt') !== 'seen' && (
                        <InstallIosPropmt />
                    )} */}

                {!params.get('id') &&
                    getCookie('android_install_prompt') !== 'seen' && (
                        <AndroidInstallPwaInstructionsPropmt />
                    )}
                {current !== null &&
                    current !== undefined &&
                    isGalleryLoading !== true &&
                    isCarLoading !== true && (
                        <React.Fragment>
                            <FullPageWrapper hasMobileSubNav>
                                <DesktopDisplayOnly>
                                    <CarGalleryManagerDesktop
                                        ids_list={current.gallery.images}
                                        openImgForm={() => {
                                            toggleCarGalleryEditForm()
                                            setTimeout(() => {
                                                window.scrollTo(0, 0)
                                            }, 150)
                                        }}
                                        imagesData={imagesData}
                                        isNecessaryGalleryDataLoading={
                                            isNecessaryGalleryDataLoading
                                        }
                                    />
                                </DesktopDisplayOnly>
                                {!currentImageData && (
                                    <IpadAndMobileDisplay>
                                        {/* <Link
                                            to={{
                                                pathname: `/car/${carid}`,
                                                state: {
                                                    prevPath:
                                                        this.props.history
                                                            .location.pathname,
                                                },
                                            }}
                                        >
                                            <ReturnNav />
                                        </Link> */}
                                        <SideSlider
                                            direction={this.checkDirection()}
                                            initial="enter"
                                        >
                                            <CarGalleryManagerMobile
                                                ids_list={
                                                    current.gallery.images
                                                }
                                                openImgForm={() => {
                                                    toggleCarGalleryEditForm()
                                                    setTimeout(() => {
                                                        window.scrollTo(0, 0)
                                                    }, 150)
                                                }}
                                                imagesData={imagesData}
                                                isNecessaryGalleryDataLoading={
                                                    isNecessaryGalleryDataLoading
                                                }
                                                isGettingCarDataLoading={
                                                    isGettingCarDataLoading
                                                }
                                            />
                                        </SideSlider>
                                    </IpadAndMobileDisplay>
                                )}
                            </FullPageWrapper>
                        </React.Fragment>
                    )}
                <DesktopDisplayOnly>
                    {isCargalleryFormEditOpened && (
                        <ModalDisplay
                            toggle={toggleCarGalleryEditForm}
                            isOpen={true}
                        >
                            <WrapperImageFormsDesktop
                                close={toggleCarGalleryEditForm}
                                carid={carid}
                            />
                        </ModalDisplay>
                    )}

                    {isExistingcarGalleryImageFormOpened &&
                        currentImageData && (
                            <ModalDisplay
                                toggle={toggleExistingCarGalleryImageEditForm}
                                isOpen={true}
                            >
                                <CarGalleryEditExistingImageFormDesktop
                                    close={
                                        toggleExistingCarGalleryImageEditForm
                                    }
                                    updateImage={updateCarGalleryImageRequest}
                                    carid={carid}
                                    imagedata={currentImageData}
                                />
                            </ModalDisplay>
                        )}
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    {isCargalleryFormEditOpened && (
                        <ModalDisplay
                            toggle={toggleCarGalleryEditForm}
                            isOpen={true}
                        >
                            <WrapperImageFormsMobile
                                close={toggleCarGalleryEditForm}
                                carid={carid}
                            />
                        </ModalDisplay>
                    )}
                </IpadAndMobileDisplay>
                {params.get('id') && (
                    <PortalInvisibleWrapper
                        onClick={() => {
                            this.props.history.push(
                                this.props.location.pathname
                            )
                        }}
                    >
                        <GalleryImageV2 />
                    </PortalInvisibleWrapper>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(Gallery))
