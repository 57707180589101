import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import SideSlider from '../../templates/animated/sideSlider'
import ImageListItemMobile from '../../atoms/list/ImageListItemMobile'
import GarageCarsDuoGridMobile from '../../molecules/imageGrid/garageCarsDuoGridMobile'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import { ICar, IExternalCarsObject, IGalleryImagesObject } from 'entityModels'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import img_1 from '../../atoms/skeleton/garage/1.png'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import AddImageBanner from '../../atoms/image/addImageBanner'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

const PlaceholderWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 252px;
    @media ${device.ipad} {
        height: 350px;
    }
`

const Placeholder = styled.img`
    object-fit: cover !important;
    width: 100%;
    height: 100%;
`

interface Props extends RouteComponentProps<any> {
    carsData: {
        [key: string]: ICar
    }
    activeView: string
    areCarsLoading: boolean
    carsIdList: string[]
    addCarForm: () => void
    getDropdownList: any
    isExternalGarage?: boolean
    garageid?: string
    externalCarsData: IExternalCarsObject | undefined
    areExternalCarsLoading: boolean
    imagesData: IGalleryImagesObject | null
    dontShowAddBox?: boolean
    dataLoading?: boolean

    reset_make_model_temps: () => void
}

const dispatchProps = {
    reset_make_model_temps: () => dropDownActions.reset_make_model_temps(),
}

function mapStateToProps(state: RootState) {
    return {
        carsData: state.entities.carsData.cars,
        areCarsLoading: state.entities.carsData.loading,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
        areExternalCarsLoading: state.entities.carsData.loading,
    }
}

type State = {
    carsDataFinished: null | {}
}

class MobileGarageViewManager extends React.Component<Props, State> {
    state = {
        carsDataFinished: null,
    }

    static contextType = ThemesContext

    componentDidMount() {}

    car_cover_url = (carObj: any): string | undefined => {
        const { theme } = this.context
        let imageid =
            carObj.gallery && carObj.gallery.cover && carObj.gallery.cover

        if (imageid && this.props.imagesData) {
            if (this.props.imagesData[imageid]) {
                return this.props.imagesData[imageid].url
            }
        } else return randomCarSvgPlaceholderFunc(undefined, theme)
    }

    renderRightView = (activeView: string, addCarForm: any) => {
        let carsData = this.props.carsData
        let externalCarsData = this.props.externalCarsData
        let checkIfGarageIsEmpty =
            !this.props.carsIdList || this.props.carsIdList.length === 0
                ? true
                : false

        if (activeView === 'banner_view') {
            return (
                <SideSlider direction={'left'} initial="enter">
                    {this.props.carsIdList.map((id: string, index: number) => {
                        return (
                            <Link
                                replace={
                                    this.props.isExternalGarage ? false : true
                                }
                                to={{
                                    pathname: this.props.isExternalGarage
                                        ? `/shared/car/${id}`
                                        : `/car/${id}`,
                                    state: {
                                        prevPath:
                                            this.props.history.location
                                                .pathname,
                                        search: this.props.history.location
                                            .search,
                                    },
                                }}
                                key={index}
                                onClick={() => {
                                    this.props.reset_make_model_temps()
                                }}
                            >
                                <ImageListItemMobile
                                    item={
                                        this.props.isExternalGarage &&
                                        externalCarsData
                                            ? {
                                                  ...externalCarsData[id],
                                                  url: this.car_cover_url(
                                                      externalCarsData[id]
                                                  ),
                                              }
                                            : {
                                                  ...carsData[id],
                                                  url: this.car_cover_url(
                                                      carsData[id]
                                                  ),
                                              }
                                    }
                                />
                            </Link>
                        )
                    })}

                    {!this.props.isExternalGarage &&
                        !this.props.dataLoading &&
                        (checkIfGarageIsEmpty ? (
                            <PlaceholderWrapper>
                                <Placeholder src={img_1} />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        zIndex: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ButtonAtom
                                        width={'230px'}
                                        theme={'lowercase-blue-background'}
                                        fontSize={14}
                                        onClick={() => {
                                            this.props.getDropdownList()
                                            addCarForm()
                                        }}
                                        dataPH={'add_car'}
                                    >
                                        <div
                                            style={{
                                                textTransform: 'none',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            Start adding cars to your garage
                                        </div>
                                    </ButtonAtom>
                                </div>
                            </PlaceholderWrapper>
                        ) : (
                            !this.props.dontShowAddBox && (
                                <AddImageBanner
                                    ipadHeight="350px"
                                    onClick={() => {
                                        this.props.getDropdownList()
                                        addCarForm()
                                    }}
                                    dataPH="add_car"
                                />
                            )
                        ))}
                    {this.props.isExternalGarage && checkIfGarageIsEmpty && (
                        <NoDataRectangle
                            text="This garage is empty"
                            height="200px"
                        />
                    )}
                </SideSlider>
            )
        } else if (activeView === 'grid_view') {
            return (
                <SideSlider direction={'right'} initial="enter">
                    <GarageCarsDuoGridMobile
                        reset_make_model_temps={() =>
                            this.props.reset_make_model_temps()
                        }
                        dataToMap={this.props.carsIdList}
                        carsdata={
                            this.props.isExternalGarage && externalCarsData
                                ? externalCarsData
                                : carsData
                        }
                        onClick={() => {
                            this.props.getDropdownList()
                            addCarForm()
                        }}
                        imagesData={this.props.imagesData}
                        isExternalGarage={this.props.isExternalGarage}
                        dontShowAddBox={this.props.dontShowAddBox}
                        dataLoading={this.props.dataLoading}
                        dataPHAddBox="add_car"
                    />
                </SideSlider>
            )
        }
    }

    render() {
        const { areCarsLoading, activeView, addCarForm } = this.props

        return (
            <React.Fragment>
                {areCarsLoading !== true &&
                    this.renderRightView(activeView, addCarForm)}
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(MobileGarageViewManager)
)
