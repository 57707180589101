import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import * as Yup from 'yup'

// import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { Select } from '../../../../../atoms/select'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding32 = styled.div`
    padding-top: 32px;
`
const Padding11 = styled.div`
    padding-top: 11px;
`
// const RegistrationNumberReminder = styled.div`
//     color: grey;
//     font-family: Lato;
//     font-size: 13px;
//     padding-top: 10px;
// `

// const StyledSelect = styled(Select)`
//     cursor: pointer;
//     margin-top: 10px;
//     margin-bottom: 25px;
//     padding: 5px 30px 5px 15px;
//     font-size: 14px !important;
//     background-color: var(--bg-color, #fff);
//     border-radius: 5px;
//     height: 36px;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     font-family: Lato;
//     color: #4f4f4f;

//     ::selection {
//         color: #fff;
//         background: #5EC3CA;
//     }
// `

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    transmission: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id?: any
    optionsList: any
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        optionsList,
        setFieldValue,
        handleSubmit,
        values,
        errors,
        // touched,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    function handleChangeField(value: any) {
        setFieldValue('transmission', value)
    }

    let checkIfDisabled = () => {
        if (!values.transmission) {
            return true
        } else if (errors.transmission !== undefined) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>Transmission</ModalTopBar>
            <Padding11 />
            <ModalInnerContentWrapperMobile>
                {optionsList !== null && (
                    <Select
                        placeholder="Transmission"
                        defaultValue={values.transmission}
                        onChange={handleChangeField}
                        options={optionsList.map((item: any) => ({
                            value: item.name,
                            label: item.name === 'Unknown' ? '-' : item.name,
                        }))}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                )}
                <Padding32 />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={handleSubmit}
                    confirmationtext="save"
                    disabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const CarTransmissionTypeCustomMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        transmission: props.item.answer ? props.item.answer : undefined,
    }),
    // validationSchema: {},

    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                transmission: Yup.string().oneOf(['Manual', 'Automatic']),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: {
                id: 'transmission',
                text: props.item.text,
                answer: values.transmission,
            },
        }

        props.submitFunc(p)

        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(CarTransmissionTypeCustomMobile)
