import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export type IaddShareRecipientApi_arg = {
    share_id: string
    recipient_userid: string
}

export const deleteShareRecipientApi = (
    p: IaddShareRecipientApi_arg
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.delete_share_recipient(p.share_id, p.recipient_userid),
        requestOptions
    )
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(response, 'Delete share recipient error')
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
