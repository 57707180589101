import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { AbsoluteGradient } from '../../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import Faded from '../../../../templates/animated/faded'
import EnterIcon from '../../../../atoms/icons/components/enterIcon'
import {
    IUploadImagePreSignedUrlPayloadSingle,
    IUSerGeneralInfoUpdatePayload,
} from 'myModels'
import { device } from '../../../../templates/displays/devices'
import UploadedImagePreview from '../../../../atoms/image/uploadedImagePreview'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'

import axios from 'axios'
import { apiSentryErrorHandler } from '../../../../../redux/services/errorHandler'
import JourneyPageWrapperMobile from '../../../../templates/wrappers/journeyPageWrapperMobile'
import JourneyWrapperLeftSideDesktop from '../../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import JourneyUserProfileDropzone from '../../../dropzone/journeyUserProfileImage'
import { updateUserGeneralInfoRequest } from '../../../../../redux/user/actions/loadingActions'
import { UserProfileJourneyUserCardMobile } from '../card/cardMobile'
import { UserProfileJourneyUserCardDesktop } from '../card/cardDesktop'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

const ImageWrapper = styled.div`
    height: 176px;
    width: 176px;
    position: relative;

    @media ${device.mobile_and_ipad} {
        width: 176px;
        height: 176px;
    }
`

export default function UserProfileJourneyProfilePicture({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()
    const [selectedImage, setSelectedImage] = useState<File | undefined>(
        undefined
    )

    const [imageUploadProgress, setUploadImageProgress] = useState<
        number | undefined
    >(undefined)

    const dispatch = useAppDispatch()

    const presignedUrlsState = useAppSelector(
        (state) => state.fileStorage.presignedurls
    )

    const upload_presignedurl_image_to_s3 = (filesToSend: File[]) => {
        filesToSend.length > 0 &&
            filesToSend.forEach(async (file: File, index: number) => {
                if (
                    file !== undefined &&
                    presignedUrlsState &&
                    presignedUrlsState[0] &&
                    presignedUrlsState[0].url
                ) {
                    setUploadImageProgress(0)

                    return await axios
                        .request({
                            method: 'put',
                            url: presignedUrlsState[0].url,
                            data: file,
                            onUploadProgress: (p: any) => {
                                setUploadImageProgress(
                                    Math.ceil((p.loaded / p.total) * 100)
                                )
                            },
                        })
                        .then((data: any) => {
                            setUploadImageProgress(100)
                            return
                        })
                        .catch((e: any) => {
                            apiSentryErrorHandler(
                                e,
                                'Upload presigned url user profile image to s3 error'
                            )
                            return Promise.reject(e)
                        })
                }
                // };
            })
    }

    useEffect(() => {
        if (presignedUrlsState && selectedImage && presignedUrlsState[0]?.url) {
            upload_presignedurl_image_to_s3([selectedImage])
        }
    }, [presignedUrlsState])

    const onSubmit = (
        img: File,
        presigned: IUploadImagePreSignedUrlPayloadSingle
    ) => {
        if (userLoggedIn?.id) {
            let dataToSend: IUSerGeneralInfoUpdatePayload = {
                id: userLoggedIn?.id,
                data: {
                    profile_filename: presigned.filename,
                },
            }

            dispatch(updateUserGeneralInfoRequest(dataToSend))
        }
    }

    const submitAndGoToNextStep = () => {
        if (selectedImage && presignedUrlsState && presignedUrlsState[0]) {
            onSubmit(selectedImage, presignedUrlsState[0])
        }
        goToNextStep()
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitAndGoToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [selectedImage, presignedUrlsState, step])

    const removeImage = () => {
        setSelectedImage(undefined)
        setUploadImageProgress(undefined)
    }

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    let profileImgUrl = userLoggedIn?.profile_image_url

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={
                                profileImgUrl
                                    ? 'Change your profile image'
                                    : 'Upload your profile image'
                            }
                            sectionID="your_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                goToPrevStep()
                            }}
                        >
                            <ImageWrapper>
                                {selectedImage ? (
                                    <UploadedImagePreview
                                        currentImagesUploaded={selectedImage}
                                        borderRadius="24px"
                                        onDelete={
                                            selectedImage &&
                                            imageUploadProgress === 100
                                                ? removeImage
                                                : undefined
                                        }
                                    />
                                ) : (
                                    <JourneyUserProfileDropzone
                                        handleFilesGiven={(files: any) => {
                                            files && setSelectedImage(files[0])
                                        }}
                                        isSingleImg
                                        hasCropper
                                        coppedImgAspectRatio={1}
                                    />
                                )}
                            </ImageWrapper>

                            <div style={{ paddingTop: '4px' }} />

                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={submitAndGoToNextStep}
                                    bg={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_500
                                    }
                                    isDisabled={!userLoggedIn}
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <RowContent style={{ gap: 8 }}>
                                    <IconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </IconWrapper>{' '}
                                    <EnterTxt $theme={theme}>
                                        Or press Enter
                                    </EnterTxt>
                                </RowContent>
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide
                            style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <UserProfileJourneyUserCardDesktop
                                selectedImage={selectedImage}
                                imageUploadProgress={imageUploadProgress}
                            />
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    allowScroll={true}
                    title={
                        profileImgUrl
                            ? 'Change your profile image'
                            : 'Upload your profile image'
                    }
                    sectionID="your_profile"
                    subtitle={'Make sure you look your best'}
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        goToPrevStep()
                    }}
                    onNext={submitAndGoToNextStep}
                >
                    <ColCenter>
                        <ImageWrapper>
                            {selectedImage ? (
                                <UploadedImagePreview
                                    currentImagesUploaded={selectedImage}
                                    borderRadius="24px"
                                    onDelete={
                                        selectedImage &&
                                        imageUploadProgress === 100
                                            ? removeImage
                                            : undefined
                                    }
                                />
                            ) : (
                                <JourneyUserProfileDropzone
                                    handleFilesGiven={(files: any) => {
                                        files && setSelectedImage(files[0])
                                    }}
                                    isSingleImg
                                    hasCropper
                                    coppedImgAspectRatio={1}
                                    isMobile
                                />
                            )}
                        </ImageWrapper>

                        <div style={{ paddingTop: '24px' }} />

                        <UserProfileJourneyUserCardMobile withBio={true} />

                        <AbsoluteGradient />
                    </ColCenter>
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
