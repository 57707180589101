import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import backgroundImage from '../../../public/assets/onboarding/step_2.jpg'
import RegistrationFormWrapperLandingPage from '../registrationform/registrationFormWrapperLandingPage'
import { useEffect, useState } from 'react'

const Wrapper = styled.div`
    width: 100%;
    height: 100svh;
    display: flex;

    @media ${device.mobile_and_ipad} {
        flex-direction: column-reverse;
        height: auto;
    }
`

const ImageBg = styled.div`
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
        url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 560px;
    overflow: hidden;
    gap: 32px;

    @media ${device.large_desktop} {
        gap: 40px;
    }

    @media ${device.mobile_and_ipad} {
        height: 500px;
        min-width: 100%;
        gap: 24px;
    }

    @media ${device.small_mobile} {
        height: 400px;
        min-width: 100%;
        gap: 24px;
    }
`

const NestedImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`

const NestedImgWrapper = styled.div`
    width: 100%;
    max-width: 1000px;
    @media ${device.small_mobile} {
        height: 30vh;
        max-width: 90%;
        max-height: 250px;
    }
    @media ${device.mobile} {
        height: 30vh;
        max-width: 90%;
        max-height: 250px;
    }
    @media ${device.ipad} {
        height: 300px;
    }
    @media ${device.desktop} {
        height: 40vw;
        max-width: 80%;
        max-height: 450px;
    }
    @media ${device.large_desktop} {
        height: 35vw;
        width: 80%;
        max-width: 1000px;
        max-height: 750px;
    }
`

const NestedTabs = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 40px;
    grid-row-gap: 16px;
    max-width: 800px;

    @media ${device.mobile_and_ipad} {
        max-width: 80%;
        grid-column-gap: 32px;
        grid-row-gap: 12px;
    }

    @media ${device.small_mobile} {
        grid-column-gap: 24px;
        grid-row-gap: 8px;
    }
`

const FormWrapper = styled.section`
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 24px 40px;
    height: 100svh;
    width: 100%;
    max-width: 600px;
    overflow-y: auto;

    @media (max-height: 800px) {
        justify-content: flex-start;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #dedede;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b3b3b3;
    }

    @media ${device.large_desktop} {
        padding: 40px;
    }

    @media ${device.mobile_and_ipad} {
        height: 100%;
        width: 100%;
        padding: 24px;
        justify-content: flex-start;
        max-width: none;
    }
`

const TabTitle = styled.button`
    all: unset;
    font-family: Lato-bold;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #b3b3b3;
    cursor: pointer;
    padding-bottom: 4px;
    @media ${device.desktop} {
        font-size: 20px;
        line-height: 32px;
    }
`

const AnimatedLine = styled.div`
    position: absolute;
    bottom: 0px;
    height: 3px;
    border-radius: 4px;
    width: 50vw;
    transform-origin: left;
    background-color: #5ec3ca;
    @media ${device.mobile_and_ipad} {
        width: 100vw;
    }
`

interface Props {
    invite_code: any
}

const tabs = [
    {
        text: 'Ownership tooling',
        img: 'https://assets-global.website-files.com/5fbc11d414fd9f4d24915d5a/6579bfe556032b96610dc639_Tab%201.png',
    },
    {
        text: 'Specialist car marketplace',
        img: 'https://assets-global.website-files.com/5fbc11d414fd9f4d24915d5a/6579bfe5b3db99d1bf4b5925_Tab%202.png',
    },
    {
        text: 'Enthusiast Insurance',
        img: 'https://assets-global.website-files.com/5fbc11d414fd9f4d24915d5a/65d4b603b1b1a827f227ef1c_Landing%20page%20-%20Insurance%20image.png',
    },
    {
        text: 'Community',
        img: 'https://assets-global.website-files.com/5fbc11d414fd9f4d24915d5a/6579bfe5b8e844e95d0feae8_Tab%204.png',
    },
]

const RegistrationNewLanding = (props: Props) => {
    const [activeIndex, setActiveIndex] = useState<undefined | number>(
        undefined
    )

    const [transition, setTransition] = useState(true)
    const [timeoutId, setTimeoutId] = useState<any>(undefined)

    useEffect(() => {
        setTimeout(() => setActiveIndex(0), 50)
    }, [])

    useEffect(() => {
        if (activeIndex !== undefined && activeIndex < 3) {
            setTimeoutId(
                setTimeout(() => setActiveIndex(activeIndex + 1), 4900)
            )
        } else {
            setTimeoutId(setTimeout(() => setActiveIndex(0), 4900))
        }
        return () => {}
    }, [activeIndex, transition])

    return (
        <Wrapper>
            <FormWrapper>
                <RegistrationFormWrapperLandingPage
                    invite_code={props.invite_code}
                />
            </FormWrapper>
            <ImageBg>
                <NestedTabs>
                    {tabs.map((tab, i) => (
                        <div style={{ position: 'relative' }}>
                            <TabTitle
                                onClick={() => {
                                    setTransition(false)
                                    setTransition(true)
                                    clearTimeout(timeoutId)
                                    setTimeoutId(undefined)
                                    setActiveIndex(i)
                                }}
                                key={`tab_${i}`}
                                style={
                                    activeIndex === i ? { color: '#fff' } : {}
                                }
                            >
                                {tab.text}
                            </TabTitle>
                            <AnimatedLine
                                style={{
                                    transition: transition
                                        ? 'opacity 0.1s ease-in, transform 4000ms cubic-bezier(0.85, 0, 0.15, 1)'
                                        : 'none',
                                    transform:
                                        activeIndex === i
                                            ? 'scaleX(1)'
                                            : 'scaleX(0)',
                                    opacity: activeIndex === i ? 1 : 0,
                                }}
                            />
                        </div>
                    ))}
                </NestedTabs>

                <NestedImgWrapper
                    style={{
                        position: 'relative',
                        width: '100%',
                        maxWidth: '100%',
                    }}
                >
                    {tabs.map((tab, i) => (
                        <NestedImgWrapper
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                zIndex: activeIndex === i ? 2 : 0,
                                transition: transition
                                    ? 'z-index 900ms'
                                    : 'none',
                            }}
                        >
                            <NestedImg
                                src={activeIndex ? tab.img : tabs[0].img}
                                style={{
                                    opacity: activeIndex === i ? 1 : 0.5,
                                    filter:
                                        activeIndex === i
                                            ? 'saturate(1)'
                                            : 'saturate(0)',
                                    transition: transition
                                        ? 'filter 500ms, opacity 700ms'
                                        : 'none',
                                }}
                            />
                        </NestedImgWrapper>
                    ))}
                </NestedImgWrapper>
            </ImageBg>
        </Wrapper>
    )
}

export default RegistrationNewLanding
