import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 9px;
    border-radius: 4px;

    @media ${device.mobile} {
        padding: 5px;
    }
`
const Text = styled.span`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    color: #ffffff;

    @media ${device.mobile} {
        font-size: 10px;
    }
`

interface IProps {
    text: string
    color?: string
}

const SimpleTag: React.FunctionComponent<IProps> = (props: IProps) => (
    <Container
        style={{ backgroundColor: props.color ?? 'rgba(26, 26, 26, 0.6)' }}
    >
        <Text>{props.text}</Text>
    </Container>
)

export default SimpleTag
