import React from 'react'
import styled from 'styled-components'
import no_search_results from '../../../public/assets/icons/no_search_results.svg'

const Wrapper = styled.div<IStyle>`
    width: 100%;
    padding: ${(props) => (props.variant === 'mobile' ? '20px' : '30px')};
`

const SubWrapper = styled.div`
    display: flex;
    background-color: #efefef;
    position: relative;
    border-radius: 5px;
    padding: 10px;
`

const IconWrapper = styled.div`
    min-width: 40px;
    padding: 10px;
    padding-right: 15px;
    display: flex;
    align-items: center;
`
const VerticalLine = styled.div`
    top: 0px;
    bottom: 0px;
    width: 1px;
    background-color: #e2e2e2;
`
const Text = styled.div<IStyle>`
    font-family: 'Lato';
    font-size: ${(props) => (props.variant === 'mobile' ? '13px' : '14px')};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px 10px 15px;
    color: #878787;
`
const SuggestionsWrapper = styled.ul<IStyle>`
    margin: 0;
    padding: 0;
    padding-left: ${(props) => (props.variant === 'mobile' ? '40px' : '90px')};
`
const SuggestionTitle = styled.div<IStyle>`
    font-family: 'Lato-light';
    font-size: ${(props) => (props.variant === 'mobile' ? '13px' : '14px')};
    font-style: italic;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${(props) =>
        props.variant === 'mobile'
            ? '20px 0px 10px 15px'
            : '20px 0px 10px 65px'};
    color: #878787;
`

const SuggestionText = styled.li<IStyle>`
    font-family: 'Lato-light';
    font-size: ${(props) => (props.variant === 'mobile' ? '13px' : '14px')};
    font-style: italic;
    padding: 0px;
    padding-bottom: 3px;
    margin: 0px;
    color: #878787;
`

const NoResultsIcon = styled.img`
    height: 15px;
`

interface IStyle {
    variant: 'desktop' | 'mobile'
}

interface INoFilterResultsMobile {
    text?: string
    variant: 'desktop' | 'mobile'
}

const NoFilterResults: React.SFC<INoFilterResultsMobile> = (props) => {
    return (
        <Wrapper variant={props.variant}>
            <SubWrapper>
                <IconWrapper>
                    <NoResultsIcon src={no_search_results} />
                </IconWrapper>
                <VerticalLine></VerticalLine>
                <Text variant={props.variant}>
                    {props.text
                        ? props.text
                        : 'No entries containing all your search terms were found.'}
                </Text>
            </SubWrapper>
            <SuggestionTitle variant={props.variant}>
                Suggestions:
            </SuggestionTitle>

            <SuggestionsWrapper variant={props.variant}>
                <SuggestionText variant={props.variant}>
                    Make sure all words are spelled correctly.
                </SuggestionText>
                <SuggestionText variant={props.variant}>
                    Try different or more general key words.
                </SuggestionText>
            </SuggestionsWrapper>
        </Wrapper>
    )
}

export default NoFilterResults
