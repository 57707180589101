import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import styled from 'styled-components'
import useThemes from '../../../../../../providers/theme/hooks'
// import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_datePickerDesktop: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc?: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id?: any
    carid: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        handleSubmit,
        setFieldValue,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let checkIfDisabled = () => {
        if (!values.single_item_edited_value_datePickerDesktop) {
            return true
        } else if (
            errors.single_item_edited_value_datePickerDesktop !== undefined
        ) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop style={{ marginTop: '20vh' }} $theme={theme}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperDesktop>
                <Padding5 />

                <InputField
                    theme={theme}
                    id="single_item_edited_value_datePickerDesktop"
                    name="single_item_edited_value_datePickerDesktop"
                    placeholder={item.text}
                    customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.single_item_edited_value_datePickerDesktop}
                    font_family="Lato"
                    type="date"
                    onChange={(date: string) => {
                        setFieldValue(
                            'single_item_edited_value_datePickerDesktop',
                            date
                        )
                    }}
                    isSimpleDate={true}
                    onEnterKeyPress={props.handleSubmit}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />

                <Padding40 />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const DatePickerTechInfoEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_datePickerDesktop: props.item.answer
            ? props.item.answer
            : undefined,
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_datePickerDesktop: Yup.date(),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        if (props.submitFunc) {
            let p: IwriteTechnicalInformationPayloadReq = {
                car_id: props.carid,
                tech_info_id: props.tech_info_id,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answer: values.single_item_edited_value_datePickerDesktop,
                },
            }
            props.submitFunc(p)
        }
        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(DatePickerTechInfoEditFormDesktop)
