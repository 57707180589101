import styled from 'styled-components'

import { IGalleryImagesObject } from 'entityModels'

import ShowroomSelectableImageTrioItem from '../../atoms/image/showroom/showroomSelectableImageTrioItem'
import GallerySortableManager from '../sortable/gallery/gallerySortableManager'
import { device } from '../../templates/displays/devices'

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
    @media ${device.mobile_and_ipad} {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2px;
    }
    padding-bottom: 200px;
    background-color: var(--bg-color, #fff);
    width: 100%;
    grid-auto-rows: 1fr;
`

interface Props {
    images_data_object: IGalleryImagesObject
    ids_list: string[] | null | undefined
    onSelect: (id: string) => any
    selectedImages: string[]
    activeStep: 'select' | 'sort'
    setSelectedImages: (p: string[]) => any
}

const ShowroomEditGalleryGrid = (props: Props) => {
    let {
        ids_list,
        images_data_object,
        selectedImages,
        onSelect,
        activeStep,
        setSelectedImages,
    } = props
    return (
        <>
            {activeStep === 'sort' ? (
                <GallerySortableManager
                    ids_list={selectedImages}
                    img_data={images_data_object}
                    setSelectedImages={setSelectedImages}
                />
            ) : null}

            {activeStep === 'select' ? (
                <GridContainer>
                    {ids_list &&
                        ids_list.map((id: string, index: number) => {
                            if (images_data_object[id]) {
                                if (activeStep === 'select') {
                                    return (
                                        <div key={index}>
                                            <ShowroomSelectableImageTrioItem
                                                image_obj={
                                                    images_data_object[id]
                                                }
                                                onSelect={onSelect}
                                                isSelected={selectedImages.includes(
                                                    id
                                                )}
                                            />
                                        </div>
                                    )
                                }
                            }
                            return (
                                <div key={index} style={{ display: 'none' }} />
                            )
                        })}
                </GridContainer>
            ) : null}
        </>
    )
}

export default ShowroomEditGalleryGrid
