import add_car_timeline_item from './addCarTimeline'
import update_car_timeline_item from './updateCarTimeline'
import delete_car_timeline_item from './deleteCarTimeline'
import add_car_timeline_entry_cost from './addCarTimelineEntryCost'
import delete_car_timeline_entry_cost from './deleteCarTimelineCostEntry'
import get_timeline_by_car_id from './getCarTimelineByCarId'
import get_timeline_item_by_id from './getEntryById'
import get_filtered_car_timeline_by_category_n_label_ids from './filterCarTimeline'
import update_car_timeline_cost from './updateCarTimelineEntryCost'
import get_entry_cost_by_id from './getCostById'
import manage_data_to_be_submitted_timeline_creation from './manageDataToBeSubmittedTimelineCreation'
import set_filter_timeline_by_category_and_labels from './setFilters'
import entry_labels_updates from './entryLabelsUpdates'
import add_attachment_to_entry from './addAttachmentToEntry'
import add_attachment_to_cost from './addAttachmentToCost'
import delete_entry_attachment from './deleteEntryAttachment'
import delete_cost_attachment from './deleteCostAttachment'
import manage_add_attachments_to_entry from './manageAddAttachmentsToEntry'
import add_car_timeline_with_costs from './addTimelineEntries'
import update_car_timeline_with_costs from './updateTimelineEntryWithCosts'

let arr: any[] = [
    ...add_car_timeline_item,
    ...update_car_timeline_item,
    ...delete_car_timeline_item,
    ...get_timeline_item_by_id,
    ...get_timeline_by_car_id,
    ...get_filtered_car_timeline_by_category_n_label_ids,
    ...get_entry_cost_by_id,
    ...add_car_timeline_entry_cost,
    ...update_car_timeline_cost,
    ...delete_car_timeline_entry_cost,
    ...manage_data_to_be_submitted_timeline_creation,
    ...set_filter_timeline_by_category_and_labels,
    ...entry_labels_updates,
    ...add_attachment_to_entry,
    ...add_attachment_to_cost,
    ...delete_entry_attachment,
    ...delete_cost_attachment,
    ...manage_add_attachments_to_entry,
    ...add_car_timeline_with_costs,
    ...update_car_timeline_with_costs,
]

export default arr
