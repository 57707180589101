import gql from 'graphql-tag'
import { get_check_cookie_name } from '../../../helpers/cookies'
import { client } from '../../../helpers/prismicUtils/prismicHelpers'

/*
 * Apex All Cars of the Week
 */
const apexSingleInterviewByUIDGraphQLQuery = gql`
    query apexSingleInterviewByUIDGraphQLQuery($uid: String) {
        allApex_interviewss(uid: $uid) {
            edges {
                node {
                    _meta {
                        uid
                    }
                    name
                    title
                    date
                    introduction
                    apex_team_credits
                    body {
                        ... on Apex_interviewsBodyBody_rich_content {
                            type
                            primary {
                                body_elem
                            }
                        }
                        ... on Apex_interviewsBodySingle_image_portrait {
                            type
                            primary {
                                img
                                image_credit
                            }
                        }
                        ... on Apex_interviewsBodySingle_image_landscape {
                            type
                            primary {
                                img
                                image_credit
                            }
                        }
                        ... on Apex_interviewsBodyDuo_img_portrait_portrait {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        ... on Apex_interviewsBodyDuo_img_lansdcape_lansdcape {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        ... on Apex_interviewsBodyDuo_img_hybrid_portrait_landscape {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        ... on Apex_interviewsBodyDuo_img_hybrid_landscape_portrait {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        __typename
                    }
                }
            }
        }
    }
`

export const apexSingleInterviewQuery = async (uid: string) => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexSingleInterviewByUIDGraphQLQuery,
        variables: { uid },
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}
