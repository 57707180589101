import * as React from 'react'
import styled from 'styled-components'

import useIsIosPwaCheck from './isIosPwaCheck'

import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { useDispatch, useSelector } from 'react-redux'
import { menuActions } from '../../../redux/menus/reducer'
import browser_menu from '../../../public/assets/icons/browser_menu.svg'
import browser_menu_dark from '../../../public/assets/icons/browser_menu_dark.svg'
import pwa_circle_custodian_blue from '../../../public/assets/logo/pwa_circle_custodian_blue.svg'
import { device } from '../displays/devices'
import useThemes from '../../../providers/theme/hooks'

const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;
    height: auto;
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 15px;
    z-index: 100;
    padding-left: 10px;
    padding-right: 10px;

    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Text = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 14px;
    padding-left: 11px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
`

const Line = styled.div`
    height: 30px;
    width: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

const MobilePageWrapperStyle = styled.div`
    display: none;
    @media ${device.mobile_and_ipad} {
        z-index: 50;
        position: fixed;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;
        top: 0px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`

interface Props extends RouteComponentProps<any> {
    [key: string]: any
}

const AndroidInstallPwaInstructionsPropmt = withRouter((props: Props) => {
    const [isVisible, setVisibleState] = React.useState(false)

    const hasSeenAndroidPWABanner = useSelector(
        // default: false
        (state: RootState) => state.menus.pwa.hasSeenAndroidPWABanner
    )

    const isNativeAndroidPWABannerActive = useSelector(
        // default: false
        (state: RootState) => state.menus.pwa.isNativeAndroidPWABannerActive
    )

    const dispatch: any = useDispatch()

    const isApple: any = useIsIosPwaCheck()

    React.useEffect(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            dispatch(menuActions.toggleHasSeenAndroidPWABanner(true))
            setVisibleState(false)
        }
    }, [dispatch])

    const params = props.location.search

    const { theme } = useThemes()

    return (
        <>
            {!isApple.isIOS &&
                !isApple.isSafari &&
                !isVisible &&
                !params.match(/tutorial/g) &&
                !params.match(/native_app_prompt/g) &&
                !params.match(/onboarding/g) &&
                !hasSeenAndroidPWABanner &&
                !isNativeAndroidPWABannerActive && (
                    <MobilePageWrapperStyle
                        onClick={() => {
                            document.cookie = 'android_install_prompt=seen'
                            dispatch(
                                menuActions.toggleHasSeenAndroidPWABanner(true)
                            )
                        }}
                        data-attr="android-install-propmt-mobile"
                    >
                        <Wrapper>
                            <Row>
                                <img
                                    style={{ width: '10vw' }}
                                    src={pwa_circle_custodian_blue}
                                    alt="custodian logo"
                                />
                                <div style={{ paddingLeft: '10px' }} />
                                <Line />

                                <div style={{ paddingLeft: '2px' }} />
                                <Text>
                                    To install this web-app to your homescreen
                                    open the browser option menu:
                                    <span style={{ paddingLeft: '10px' }}>
                                        <img
                                            style={{
                                                height: '21px',
                                            }}
                                            src={
                                                theme === 'dark'
                                                    ? browser_menu_dark
                                                    : browser_menu
                                            }
                                            alt="browser menu"
                                        />
                                    </span>
                                    <br />
                                    and tap on{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        Add to homescreen
                                    </span>{' '}
                                    or{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        Install app
                                    </span>
                                    .
                                </Text>
                            </Row>
                        </Wrapper>
                    </MobilePageWrapperStyle>
                )}

            <div />
        </>
    )
})

export default AndroidInstallPwaInstructionsPropmt
