import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import { IGalleryImage } from 'entityModels'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import { device } from '../../templates/displays/devices'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
// import { Link } from "react-router-dom";

type IImgProps = {
    isthingloading: boolean
}

function mapStateToProps(state: RootState) {
    return {
        currentCarID:
            state.entities.carsData.currentCarID &&
            state.entities.carsData.currentCarID,
    }
}

const Wrap = styled.div`
    height: 160px;
    width: 100%;
    position: relative;
    @media ${device.ipad} {
        height: 22vh;
    }
`

const GalleryImage = styled.img<IImgProps>`
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.isthingloading === true ? 0 : 1)};
    transition: opacity 400ms;
`

export interface IImageProps {
    url: string
}

interface Props extends RouteComponentProps<any> {
    image_obj: IGalleryImage
    currentCarID: any
}
type State = {
    src: any
    isLoading: boolean
}

class SquareDuoItemMobile extends React.Component<Props, State> {
    state = {
        src: null,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src = this.props.image_obj.large_src
            ? this.props.image_obj.large_src
            : this.props.image_obj.url

        return this._isMounted && this.setState({ src })
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.image_obj.large_src !== prevProps.image_obj.large_src) {
            const src = this.props.image_obj.large_src
                ? this.props.image_obj.large_src
                : this.props.image_obj.url

            return this.setState({ src, isLoading: true })
        }
    }

    render() {
        const { image_obj } = this.props

        const { isLoading } = this.state

        return (
            <Link
                replace={true}
                to={{
                    pathname: this.props.match.url,
                    search: `?id=${image_obj.id}`,
                    state: { prevPath: this.props.match.url },
                }}
            >
                <Wrap>
                    <SkeletonAbsolute isthingloading={isLoading} />
                    <GalleryImage
                        aria-label="car overview banner"
                        src={this.state.src || grey_placeholder}
                        isthingloading={isLoading}
                        srcSet={
                            image_obj.small_srcset
                                ? image_obj.small_srcset
                                : undefined
                        }
                        onLoad={() =>
                            this._isMounted &&
                            this.setState({
                                ...this.state,
                                isLoading: false,
                            })
                        }
                    />
                </Wrap>
            </Link>
        )
    }
}

export default withRouter(connect(mapStateToProps, {})(SquareDuoItemMobile))
