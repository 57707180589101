import DatePicker from 'react-datepicker'
import styled, { css } from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
// interface IStyle {}

const Wrapper = styled.div`
    width: 100%;
    .react-datepicker-popper {
        z-index: 5 !important;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }
`

type Props = {
    onChange?: (date: any) => any
    value?: any
    disabled?: boolean
    readOnlyMode?: boolean
    width?: string
    textTransform?: string
    fontSize?: string
    mobile_width?: string
    ipad_width?: string
    font_family?: string
    onFocus?: any
    onBlur?: any
    placeholder?: string
    isSimpleDate?: boolean
    restrictFlip?: boolean
    custom_date_format?: 'MM/yyyy' | undefined
}

type IStyledProps = {
    width?: string
    $textTransform?: string
    fontSize?: string
    mobile_width?: string
    ipad_width?: string
    disabled?: boolean
    font_family?: string
    $theme: ITheme
}

const inputStyles = css<IStyledProps>`
    border: none;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: 100%;
    padding: 0 14px;
    background-color: transparent;
    color: var(--text-strong, #1a1a1a);
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
    font-family: ${(props) => props.font_family ?? 'Lato'};
    text-transform: ${(props) => props.$textTransform ?? 'none'};
    flex: 1;

    ::placeholder {
        font-family: Lato-Light;
        color: ${(props) => colours[props.$theme].text_default};
        text-transform: capitalize;
        font-size: ${(props) => props.fontSize ?? '16px'};
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
        color: var(--text-strong, #1a1a1a);
    }

    @media ${device.desktop} {
        font-size: ${(props) => props.fontSize ?? '16px'};
    }

    @media ${device.ipad} {
        font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
        width: ${(props) => (props.ipad_width ? props.ipad_width : '100%')};
    }

    @media ${device.mobile} {
        width: ${(props) => (props.mobile_width ? props.mobile_width : '100%')};
        font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
    }

    .react-datepicker-popper {
        z-index: 5 !important;
    }
`

const DateInput = styled(DatePicker)<IStyledProps>`
    ${inputStyles}
`

export default function NativeDatePicker(props: Props) {
    let initialDate: Date | undefined =
        props.value && new Date(props.value) ? new Date(props.value) : undefined

    const { theme } = useThemes()

    return (
        <Wrapper>
            <DateInput
                $theme={theme}
                disabled={props.disabled}
                selected={initialDate}
                dateFormat={props.custom_date_format ?? 'dd/MM/yyyy'}
                showMonthYearPicker={
                    props.custom_date_format === 'MM/yyyy' ? true : false
                }
                onChange={(date) => {
                    if (props.onChange && date) {
                        if (props.isSimpleDate) {
                            props.onChange(
                                // @ts-ignore
                                new Date(date).toISOString().split('T')[0]
                            )
                        } else {
                            props.onChange(date)
                        }
                    }
                }}
                minDate={new Date('01/01/1700')}
                maxDate={new Date('01/01/2035')}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
                placeholderText={props.placeholder ?? `DD/MM/YYYY`}
                popperPlacement={
                    props.restrictFlip
                        ? props.custom_date_format === 'MM/yyyy'
                            ? 'bottom'
                            : 'bottom-start'
                        : undefined
                }
                popperModifiers={
                    props.restrictFlip
                        ? [
                              {
                                  name: 'flip',
                                  enabled: false,
                              },
                          ]
                        : undefined
                }
            />
        </Wrapper>
    )
}
