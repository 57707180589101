import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneyStartIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '40'}
            height={size ?? '40'}
            viewBox="0 0 40 40"
            fill="none"
        >
            <circle
                cx="20"
                cy="19.9922"
                r="17.5"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
            />
            <path
                d="M26.1279 21.2403L18.3321 26.4375C17.3352 27.102 16 26.3875 16 25.1894V14.795C16 13.5969 17.3352 12.8823 18.332 13.5469L26.1279 18.7441C27.0185 19.3378 27.0185 20.6465 26.1279 21.2403Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default JourneyStartIcon
