import { Fragment } from 'react'
import styled from 'styled-components'
// import { device } from '../../templates/displays/devices'
import Faded from '../../templates/animated/faded'
import { device } from '../../templates/displays/devices'
import RiveAnimation from './riveWrapper'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0px;
    left: 0px;
    right: 0;
    background-color: var(--off-bg-color, #fafafa);
    z-index: 10;
`
const LoaderWrapper = styled.div`
    width: 300px;
    height: 300px;

    @media ${device.mobile} {
        width: 200px;
        height: 200px;
    }
`

interface Props {
    isLoadingUser?: boolean
    isLoading?: boolean
}

function mapStateToProps(state: RootState) {
    return {
        isLoadingUser: state.user.loading,
    }
}

const Loader = (props: Props) => {
    return (
        <Fragment>
            {props.isLoadingUser === true || props.isLoading === true ? (
                <Faded>
                    <Wrapper>
                        <LoaderWrapper>
                            <RiveAnimation />
                        </LoaderWrapper>
                    </Wrapper>
                </Faded>
            ) : null}
        </Fragment>
    )
}

export default connect(mapStateToProps, null)(Loader)
