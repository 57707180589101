import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import {
    CarActionMenuItemsID,
    ICarActionMenuItemsData,
} from '../../../pages/car/carOverview'

import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import posthog from 'posthog-js'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import ThreeDotsIcon from '../../icons/components/threeDots'
import PenIcon from '../../icons/components/penIcon'
import AddIcon from '../../icons/components/addIcon'

type IStyledProps = {
    $theme: ITheme
    isOpen?: boolean
    isActive?: boolean
    length?: number
}

const MenuCircle = styled.button<IStyledProps>`
    background: ${(props: IStyledProps) =>
        props.isActive
            ? colours[props.$theme].primary_strong_2
            : colours[props.$theme].primary};
    border-radius: 100px;
    width: 82px;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 2px;
    border: none;

    transition: all 60ms;

    cursor: pointer;

    :hover {
        background: ${(props) => colours[props.$theme].primary_strong_2};
    }

    color: ${(props) => colours[props.$theme].background_default};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    font-family: 'Lato';
`

const Menu = styled.div<IStyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${(props: IStyledProps) =>
        props.isOpen
            ? props.length
                ? `${40 * (props.length + 1) + 78}px`
                : '171px'
            : '82px'};
    border: 3px solid ${(props) => colours[props.$theme].background_default};
    border-radius: 100px;
    transition: all 150ms cubic-bezier(0.735, -0.015, 0, 1.24);
    transition-timing-function: cubic-bezier(0.735, -0.015, 0, 1.24);
    background: ${(props) => colours[props.$theme].primary};
`

const Circle = styled.button<IStyledProps>`
    background: ${(props: IStyledProps) =>
        props.isActive
            ? colours[props.$theme].primary_strong_2
            : colours[props.$theme].primary};
    border-radius: 100px;
    width: 46px;
    height: 46px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 2px;
    border: none;

    transition: all 60ms;

    cursor: pointer;

    :hover {
        background: ${(props) => colours[props.$theme].primary_strong_2};
    }
`

const WrapperFaded = styled(motion.div)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const Line = styled.div<{ $theme: ITheme }>`
    width: 1px;
    height: 24px;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
`

const AnimWrapper = (props: any) => {
    const duration = 0.3

    const variants = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: duration,
                when: 'beforeChildren',
            },
        },
        exit: {
            opacity: 0,
            transition: { duration: duration },
        },
    }
    return (
        <AnimatePresence>
            <WrapperFaded
                variants={variants}
                initial="initial"
                animate="enter"
                exit="exit"
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {props.children}
            </WrapperFaded>
        </AnimatePresence>
    )
}

type IStyledProps2 = {
    $theme: ITheme
    $isDisabled?: boolean | undefined
}

export const OptionsMenuItem = styled.div<IStyledProps2>`
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    width: 100%;
    color: ${(props) =>
        props.$isDisabled
            ? `${colours[props.$theme].text_neutral_default} !important;`
            : colours[props.$theme].text_default};
    background-color: transparent;
    border-radius: 4px;

    cursor: ${(props) => (props.$isDisabled ? 'auto' : 'pointer')};

    :hover {
        transition: all 100ms;
        background-color: ${(props) =>
            props.$isDisabled
                ? 'transparent;'
                : colours[props.$theme].primary_20};
        color: ${(props) =>
            props.$isDisabled
                ? `${colours[props.$theme].text_neutral_default} !important;`
                : colours[props.$theme].primary};
    }
    padding-left: 20px;
    padding-right: 46px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

type Props = {
    isCarMenuOpened: boolean
    setIsCarMenuOpened: (p: boolean) => any
    carAtionMenuItemsData: ICarActionMenuItemsData
    activeID: CarActionMenuItemsID | undefined
}

interface IStyled {
    isAnimated?: boolean
}
const AnimatedWrapper = styled.div<IStyled>`
    @keyframes scale {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    ${(props) => props.isAnimated && 'animation: 0.2s scale linear'};
    animation-delay: 0.1s;
`

const CarActionMenuMobile = (props: Props) => {
    let {
        isCarMenuOpened,
        setIsCarMenuOpened,
        carAtionMenuItemsData,
        activeID,
    } = props

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    let menuLength: number = carAtionMenuItemsData
        ? Object.values(carAtionMenuItemsData).reduce(
              (acc, item) =>
                  item.options && item.options.length > 0 ? acc + 1 : acc,
              0
          )
        : 3

    const { theme } = useThemes()

    return (
        <AnimatedWrapper isAnimated={true}>
            {isMobile && (
                <Menu
                    $theme={theme}
                    isOpen={isMobile && isCarMenuOpened}
                    length={menuLength}
                >
                    {!isCarMenuOpened && (
                        <AnimWrapper>
                            <MenuCircle
                                $theme={theme}
                                onClick={() => {
                                    setIsCarMenuOpened(!isCarMenuOpened)
                                    posthog.capture('car_menu open')
                                }}
                            >
                                <div>Menu</div>
                            </MenuCircle>
                        </AnimWrapper>
                    )}

                    {isMobile && isCarMenuOpened && (
                        <AnimWrapper>
                            <Circle
                                $theme={theme}
                                onClick={() => {
                                    carAtionMenuItemsData['add'].onClick()
                                }}
                                isActive={activeID === 'add'}
                            >
                                <AddIcon
                                    colour={colours[theme].background_default}
                                />
                            </Circle>

                            {carAtionMenuItemsData['edit'].options.length >
                            0 ? (
                                <Circle
                                    $theme={theme}
                                    isActive={activeID === 'edit'}
                                    onClick={() => {
                                        carAtionMenuItemsData['edit'].onClick()
                                    }}
                                >
                                    <PenIcon
                                        color={
                                            colours[theme].background_default
                                        }
                                    />
                                </Circle>
                            ) : null}

                            <Circle
                                $theme={theme}
                                isActive={activeID === 'more'}
                                onClick={() => {
                                    carAtionMenuItemsData['more'].onClick()
                                }}
                            >
                                <ThreeDotsIcon
                                    color={colours[theme].background_default}
                                />
                            </Circle>

                            <div style={{ paddingLeft: '5px' }} />

                            <Line $theme={theme} />

                            <div style={{ paddingLeft: '5px' }} />

                            <MenuCircle
                                $theme={theme}
                                onClick={() => {
                                    setIsCarMenuOpened(!isCarMenuOpened)
                                }}
                            >
                                <div>Close</div>
                                {/* <IconStyled src={close} /> */}
                            </MenuCircle>
                        </AnimWrapper>
                    )}
                </Menu>
            )}
        </AnimatedWrapper>
    )
}

export default CarActionMenuMobile
