import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Expander from '../expander/expander'
import InfoIcon from '../icons/infoIcon'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import CrossIcon from '../icons/crossIcon'

const InfoBar = styled.div`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }

    position: relative;
    width: 100%;
    height: 58px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--border_muted_neutral);
    padding-left: 20px;
    justify-content: flex-start;
    /* margin-bottom: 20px; */
`
// #a1a1a1;
// display: ${(props) => (props.isClosed === true ? 'none' : 'auto')};

const Text = styled.div`
    display: flex;
    font-family: Lato;
    font-size: 14px;
    justify-self: start;
    color: var(--bg-color, #fff);
    padding-left: 15px;
`

const Close = styled.div`
    position: absolute;
    right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
`

export interface ILinkProps {
    children?: React.ReactNode
}

const InfoBarMobile: React.SFC<ILinkProps> = (props) => {
    const [isClosed, setIsClosed] = useState(false)
    const { theme } = useThemes()

    return (
        <Expander height={isClosed ? 0 : 'auto'}>
            <InfoBar>
                {/* <Icon icon="information_italic_white" height="18px" /> */}
                <InfoIcon color={colours[theme].background_default} />
                <Text>{props.children}</Text>
                <Close
                    onClick={(e) => {
                        e && e.preventDefault()
                        setIsClosed(true)
                    }}
                >
                    <CrossIcon color={colours[theme].background_default} />
                </Close>
            </InfoBar>
        </Expander>
    )
}

export default InfoBarMobile
