import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../services'

import * as requestActions from '../actions/requestActions'
import * as successActions from '../actions/successActions'
import * as errorActions from '../actions/errorActions'

import * as userActions from '../../user/actions/actions'
import * as carActions from '../../entities/cars/actions'

import {
    ICreateTaskPayload,
    ICustomErrorData,
    IReduxError,
    ITask,
    ITaskNormalisedObject,
    ITasksNormalisedByCar,
    IUpdateTaskReq,
} from 'entityModels'

import { ConvertSingleApiTask } from '../../conversions/tasks/taskTypeConversions'
import { push } from 'connected-react-router'
import { ITaskPayloadAPI } from 'IapiDataPayload'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import {
    addSingleReminderToTaskToAPISaga,
    I_addSingleReminderToTaskToAPISaga_Payload,
} from './addReminderToTask'
import { updateTaskSaga } from './updateTask'
import moment from 'moment'
import { RootState } from 'typesafe-actions'

const { v4: uuidv4 } = require('uuid')

const getCarsTasksDataInState = (state: RootState): ITasksNormalisedByCar =>
    state.entities.carsData.tasks

// GET TASK BY TASK ID

function* createTaskSaga(payload: ICreateTaskPayload): any {
    // later on : put API TRY CATH

    let convertedPayload = {
        title: payload.title,
        car_id: payload.car_id,
        garage_id: payload.garage_id,
        currentCarID: payload.currentCarID,
    }

    try {
        const newTask: ITaskPayloadAPI = yield call(
            api.tasks.create.createTask,
            convertedPayload
        )

        let converted_task: ITask = ConvertSingleApiTask(newTask)

        let normalised_converted_task: ITaskNormalisedObject = {}

        normalised_converted_task[converted_task.id] = converted_task

        yield put(successActions.createTaskSuccess(normalised_converted_task))
        yield put(userActions.updateUserTasksCountOnTasksCreation())

        let carsTasksDataInState = yield select(getCarsTasksDataInState)

        if (payload.car_id) {
            for (const id of payload.car_id) {
                if (carsTasksDataInState[id]) {
                    let new_arr = [...carsTasksDataInState[id]]
                    if (!new_arr.includes(converted_task.id)) {
                        new_arr.push(converted_task.id)
                    }
                    yield put(
                        carActions.actions.updateCarTasksArrayOnTasksCreationOrDeletion(
                            { car_id: id, tasks_ids: new_arr }
                        )
                    )
                } else {
                    yield put(
                        carActions.actions.updateCarTasksArrayOnTasksCreationOrDeletion(
                            { car_id: id, tasks_ids: [converted_task.id] }
                        )
                    )
                }
            }
        }

        // Capture event
        posthog.capture('ADD TASK')

        if (payload.due) {
            let updateTaskPayload: IUpdateTaskReq = {
                taskid: newTask.uid,
                dataList: [
                    {
                        id: 'due',
                        answer: payload.due,
                    },
                ],
            }

            yield call(updateTaskSaga, updateTaskPayload)

            let convertedDueDate = moment(payload.due)
            let today = moment()
            let daysDiff = moment
                .duration(convertedDueDate.diff(today))
                .asDays()

            if (daysDiff > 1) {
                let newReminderid1: string = uuidv4().toString()
                let oneDayBeforeReminderPayload: I_addSingleReminderToTaskToAPISaga_Payload =
                    {
                        taskID: newTask.uid,
                        reminder: {
                            id: newReminderid1,
                            type: 'days_before',
                            hasError: false,
                            nvalue: 1,
                        },
                    }
                yield call(
                    addSingleReminderToTaskToAPISaga,
                    oneDayBeforeReminderPayload
                )
            }

            if (daysDiff > 7) {
                let newReminderid2: string = uuidv4().toString()
                let oneWeekBeforeReminderPayload: I_addSingleReminderToTaskToAPISaga_Payload =
                    {
                        taskID: newTask.uid,
                        reminder: {
                            id: newReminderid2,
                            type: 'days_before',
                            hasError: false,
                            nvalue: 7,
                        },
                    }
                yield call(
                    addSingleReminderToTaskToAPISaga,
                    oneWeekBeforeReminderPayload
                )
            }
        }
        if (payload.currentCarID) {
            yield put(
                push(
                    `/car/${payload.currentCarID}/tasks/task?taskid=${converted_task.id}`
                )
            )
        } else yield put(push(`/tasks/task?taskid=${converted_task.id}`))
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: createTaskSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, we couldn't create your task`,
                custom_user_action_text: payload.currentCarID
                    ? 'Return to car tasks'
                    : 'Return to see all tasks',
                custom_redirect_path: payload.currentCarID
                    ? `/car/${payload.currentCarID}/tasks`
                    : '/tasks',
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.createTaskError(customError))
        }
    }
}

function* watcherCreateTask() {
    while (true) {
        const { payload } = yield take(requestActions.createTaskRequest)

        yield call(createTaskSaga, payload)
    }
}

const create_task: any[] = [fork(watcherCreateTask)]

export default create_task
