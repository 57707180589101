import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { device } from '../../../../templates/displays/devices'

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 36px;
    }
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    max-width: 400px;

    @media ${device.mobile_and_ipad} {
        line-height: 30px;
    }
`

type Props = {
    continue: () => any
}

const CarRemovedConfirmationModalDesktop = (props: Props) => {
    return (
        <>
            <Title>Car removed from the Showroom</Title>
            <div style={{ paddingTop: '20px' }} />
            <Subtitle>
                Your car was successfully removed from the Showroom. Would you
                like to transfer the car from your garage to it’s new owners?
                You can do that any time from your garage.
            </Subtitle>

            <div style={{ paddingTop: '50px' }} />

            <ButtonAtom
                theme="secondary"
                width="100%"
                height="55px"
                textTransform="capitalize"
                dataCyId={'showroom-car-enquire'}
                onClick={props.continue}
                fontSize={16}
            >
                Ok
            </ButtonAtom>
        </>
    )
}

export default CarRemovedConfirmationModalDesktop
