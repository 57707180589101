import React from 'react'
import countriescode from './countriescode.json'
import debounce from 'lodash/debounce'
import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js'
import Fuse from 'fuse.js'
import PhoneInputContent from './phoneinputcontent'

export type Props = {
    addPhone: (phone: string) => void
    isDesktop: boolean
}

// not sure why now threw error. Removed specifications and error free.
// keeping this here in case .

// type CountryCodeFuse = {
//   name: string;
//   dial_code: string;
//   code: string;
// };

// const options: Fuse.FuseOptions<CountryCodeFuse> = {
//   tokenize: true,
//   keys: ["name"]
// };
const fuse = new Fuse(countriescode, {
    keys: ['name'],
})

type LocalState = {
    data: any[]
    value: any[]
    fetching: boolean
    phone: string
}

class PhoneInput extends React.Component<Props, LocalState> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        this.fetchCountry = debounce(this.fetchCountry, 200)
    }

    state = {
        data: [],
        value: ['United Kingdom'],
        fetching: false,
        phone: '',
    }

    lastFetchId = 0

    fetchCountry = (value: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
        }

        const results = fuse.search(value)

        return this.setState({ data: results, fetching: false })
    }

    handleChange = (value: any) => {
        this.setState({
            ...this.state,
            value,
            data: [],
            fetching: false,
        })
    }

    changeFlag = () => {
        let result
        countriescode.some((country) => {
            if (country.name.toString() === this.state.value.toString()) {
                result = country.code.toLowerCase()
                return true
            }
            return false
        })
        return result
    }

    getCode = () => {
        let result
        countriescode.some((country) => {
            if (country.name.toString() === this.state.value.toString()) {
                result = country.code
                return true
            }
            return false
        })
        return result
    }

    displayNational = (number: string, country: any) => {
        const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
            number,
            country
        )
        if (phoneNumber !== undefined) {
            return phoneNumber.formatNational()
        }
    }

    displayInternational = (number: string, country: any) => {
        const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
            number,
            country
        )
        if (phoneNumber !== undefined) {
            return phoneNumber.formatInternational()
        }
    }

    displayValid = (number: string, country: any) => {
        const phoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(
            number,
            country
        )

        if (phoneNumber !== undefined) {
            return phoneNumber.isValid()
        }
    }

    handleChangeNumber: React.ReactEventHandler<HTMLInputElement> = (ev) => {
        let value = ev.currentTarget.value
        this.setState({
            ...this.state,
            phone: value,
        })

        // this.displayNumber(this.state.phone, this.getCode());
    }

    savePhoneToUser = () => {
        return this.props.addPhone(this.state.phone)
    }

    render() {
        const { fetching, value, data, phone } = this.state
        const isvalid = this.displayValid(this.state.phone, this.getCode())

        return (
            <React.Fragment>
                <PhoneInputContent
                    fetching={fetching}
                    value={value}
                    data={data}
                    fetchCountry={this.fetchCountry}
                    handleChange={this.handleChange}
                    displayInternational={this.displayInternational}
                    getCode={this.getCode}
                    phone={phone}
                    isvalid={isvalid}
                    savePhoneToUser={this.savePhoneToUser}
                    handleChangeNumber={this.handleChangeNumber}
                    changeFlag={this.changeFlag}
                    countrieslist={countriescode}
                />
            </React.Fragment>
        )
    }
}

export default PhoneInput
