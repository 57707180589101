import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";
import colors from '../../../../../redux/localdata/formslist/car/colors.json'
import vehicle_condition from '../../../../../redux/localdata/formslist/car/vehicle_condition.json'
import powertype from '../../../../../redux/localdata/formslist/car/powertype.json'
import BasicTechInfoEditFormDesktop from '../basic/basicTechInfoEditFormDesktop'
import CarTechInfoEditFormSelectSearchDesktop from '../searchselect/desktop/carTechInfoEditFormSelectSearchDesktop'
import DatePickerTechInfoEditFormDesktop from '../../technicalInformationEditForm/datepicker/desktop/datePickerTechInfoEditFormDesktop'
import NumberTechInfoEditFormDesktop from '../../technicalInformationEditForm/number/desktop/numberTechInfoEditFormDesktop'
import drive_train_options from '../../../../../redux/localdata/formslist/car/drive_train_options.json'
import DuoRadioTechInfoEditFormDesktop from '../../technicalInformationEditForm/duoradio/desktop/duoRadioTechInfoEditFormDesktop'
import CarTechInfoEditFormMultipleSelectSearchDesktop from '../../technicalInformationEditForm/multipleselect/desktop/carTechInfoEditFormMultipleSelectSearchDesktop'
import features from '../../../../../redux/localdata/formslist/car/features.json'
import CarMakeCustomFormDesktop from '../custom/carMakeCustomForm/carMakeCustomFormDesktop'
import CarModelCustomFormDesktop from '../custom/carModelCustomForm/carModelDesktopFormDesktop'
import CarYearCustomFormDesktop from '../custom/carYearCustomForm/carYearDesktopFormDesktop'
import CarBodyTypeCustomDesktop from '../custom/carBodyTypeCustom/carBodyTypeCustomDesktop'
import CarTransmissionTypeCustomDesktop from '../custom/carTransmissionTypeCustom/carTransmissionTypeCustomDesktop'

import { connect } from 'react-redux'

import {
    IDropdownItem,
    IList,
    IwriteMultipleTechnicalInformationPayloadReq,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'

import { RootState } from 'typesafe-actions'

import { IUserPreferencesDataLevel4Obj } from 'IUserPreferencesModels'

import {
    writeMultipleTechnicalInformationRequest,
    writeTechnicalInformationRequest,
} from '../../../../../redux/entities/technical_information/actions/loadingActions'
import { ISetModelDropdownDataReq } from '../../../../../redux/localdata/dropdownData/sagas/setModelDropdownData'
import { ISetYearDropdownData } from '../../../../../redux/localdata/dropdownData/sagas/setYearDropdownData'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'

export interface Props {
    item: IList | undefined
    toggle: () => void
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => {}
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => {}
    id: string
    allTechInfodata: any
    setMakeDropdownData: () => void
    setModelDropdownData: (payload: ISetModelDropdownDataReq) => void
    carid: string
    setYearDropdownData: (payload: ISetYearDropdownData | undefined) => void
    yearsdropdowndata: IDropdownItem[] | null
    body_types_list: IDropdownItem[] | null
    setBodyTypeDropdownData: () => void
    transmission_types_list: IDropdownItem[] | null
    setTransmissionTypesDropdownData: () => void
    makesdropdowndata: IDropdownItem[] | null
    user_fuel_unit_ids_list: string[] | null
    user_fuel_unit_chosen_id: string | null
    user_fuel_unit_data: IUserPreferencesDataLevel4Obj | null
    user_distance_unit_ids_list: string[] | null
    user_distance_unit_chosen_id: string | null
    user_distance_unit_data: IUserPreferencesDataLevel4Obj | null
}

function mapStateToProps(state: RootState) {
    return {
        yearsdropdowndata: state.localdata.dropdownData.carYears,
        body_types_list: state.localdata.dropdownData.carBodyTypes,
        transmission_types_list:
            state.localdata.dropdownData.carTransmissionTypes,
        makesdropdowndata: state.localdata.dropdownData.carMakes,

        // fuel unit
        user_fuel_unit_ids_list:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['fuel_unit']
                .ids_list,
        user_fuel_unit_chosen_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['fuel_unit']
                .user_choice_id,
        user_fuel_unit_data:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['fuel_unit']
                .data,
        // distance  unit
        user_distance_unit_ids_list:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .ids_list,
        user_distance_unit_chosen_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .user_choice_id,
        user_distance_unit_data:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .data,
    }
}

const dispatchProps = {
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => writeTechnicalInformationRequest(p),
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => writeMultipleTechnicalInformationRequest(p),
    setMakeDropdownData: () => dropDownActions.setMakeDropdownDataRequest(),
    setModelDropdownData: (payload: ISetModelDropdownDataReq) =>
        dropDownActions.setModelDropdownDataRequest(payload),
    setYearDropdownData: (payload: ISetYearDropdownData | undefined) =>
        dropDownActions.setYearDropdownDataRequest(payload),
    setBodyTypeDropdownData: () =>
        dropDownActions.setBodyTypeDropdownDataRequest(),
    setTransmissionTypesDropdownData: () =>
        dropDownActions.setTransmissionTypesDropdownDataRequest(),
}

// const time_units = [
//     {
//         name: 'years',
//         id: 'years',
//     },
//     {
//         name: 'months',
//         id: 'months',
//     },
//     {
//         name: 'weeks',
//         id: 'weeks',
//     },
// ]

const drive_side_options = [
    {
        name: 'LHD',
    },
    {
        name: 'RHD',
    },
    {
        name: 'Center',
    },
]

const road_tax_status_options = [
    {
        name: 'Taxed',
    },
    {
        name: 'Untaxed',
    },
    {
        name: 'SORN',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]
const mot_status_options = [
    {
        name: 'Valid',
    },
    {
        name: 'Not Valid',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]

class TechInfoFormManagerDesktop extends React.Component<Props, {}> {
    componentDidMount() {
        let {
            item,
            allTechInfodata,
            setModelDropdownData,
            setMakeDropdownData,
            yearsdropdowndata,
            setYearDropdownData,
            setBodyTypeDropdownData,
            setTransmissionTypesDropdownData,
            makesdropdowndata,
        } = this.props

        if (item) {
            if (item.id === 'make') {
                if (!makesdropdowndata) {
                    setMakeDropdownData()
                }

                if (!yearsdropdowndata) {
                    setYearDropdownData(undefined)
                }
                if (item.answer && typeof item.answer === 'string') {
                    setModelDropdownData({
                        makeName: item.answer,
                        make_uid: item.uid_current,
                    })
                }
            }
            if (item.id === 'model') {
                if (item.answer) {
                    if (allTechInfodata.make.answer) {
                        setMakeDropdownData()
                        setModelDropdownData({
                            makeName: allTechInfodata.make.answer,
                            make_uid: item.uid_current,
                        })
                    }
                }
                if (!yearsdropdowndata) {
                    setYearDropdownData(undefined)
                }
            }

            if (item.id === 'year') {
                if (!yearsdropdowndata) {
                    setYearDropdownData(undefined)
                }
            }

            if (item.id === 'body_type') {
                setBodyTypeDropdownData()
            }
            if (item.id === 'transmission') {
                setTransmissionTypesDropdownData()
            } else return
        }
    }

    // distance
    distance_units: IList[] | null =
        this.props.user_distance_unit_ids_list &&
        this.props.user_distance_unit_ids_list.map(
            (id: string, index: number) => {
                return this.props.user_distance_unit_data![id]
            }
        )

    user_default_distance_unit: IList | null =
        this.props.user_distance_unit_data &&
        this.props.user_distance_unit_chosen_id
            ? this.props.user_distance_unit_data[
                  this.props.user_distance_unit_chosen_id
              ]
            : null

    // fuel
    fuel_units: IList[] | null =
        this.props.user_fuel_unit_ids_list &&
        this.props.user_fuel_unit_ids_list.map((id: string, index: number) => {
            return this.props.user_fuel_unit_data![id]
        })

    user_default_fuel_unit: IList | null =
        this.props.user_fuel_unit_data && this.props.user_fuel_unit_chosen_id
            ? this.props.user_fuel_unit_data[
                  this.props.user_fuel_unit_chosen_id
              ]
            : null

    renderForm = (
        item: any,
        toggle: any,
        writeTechnicalInformationRequest: any,
        writeMultipleTechnicalInformationRequest: any,
        id: string,
        allTechInfodata: any,
        carid: string,
        distance_units: IList[],
        fuel_units: IList[]
    ) => {
        let distanceUnitInfo = {
            list: distance_units,
            id: 'distance_units',
            user_default_unit: this.user_default_distance_unit?.id,
        }

        let fuelUnitInfo = {
            list: fuel_units,
            id: 'fuel_units',
            user_default_unit: this.user_default_fuel_unit?.id,
        }

        switch (item.id) {
            case 'mileage':
                return (
                    <NumberTechInfoEditFormDesktop
                        isUnits={true}
                        // user_default_unit={this.user_default_distance_unit}
                        unitInfo={distanceUnitInfo}
                        unitList={distance_units}
                        item={item}
                        toggle={toggle}
                        tech_info_id={id}
                        submitFunc={writeTechnicalInformationRequest}
                        carid={carid}
                    />
                )

            case 'engine_capacity':
                return (
                    <NumberTechInfoEditFormDesktop
                        isUnits={true}
                        // user_default_unit={this.user_default_fuel_unit}
                        unitInfo={fuelUnitInfo}
                        unitList={fuel_units}
                        item={item}
                        toggle={toggle}
                        tech_info_id={id}
                        submitFunc={writeTechnicalInformationRequest}
                        carid={carid}
                    />
                )

            case 'drive_train':
                return (
                    <DuoRadioTechInfoEditFormDesktop
                        optionsList={drive_train_options}
                        item={item}
                        toggle={toggle}
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        carid={carid}
                    />
                )

            case 'transmission':
                return (
                    <CarTransmissionTypeCustomDesktop
                        optionsList={this.props.transmission_types_list}
                        item={item}
                        toggle={toggle}
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        carid={carid}
                    />
                )

            case 'make':
                return (
                    <CarMakeCustomFormDesktop
                        submitFunc={writeMultipleTechnicalInformationRequest}
                        tech_info_id={id}
                        toggle={toggle}
                        make_item={
                            allTechInfodata && allTechInfodata.make
                                ? allTechInfodata.make
                                : undefined
                        }
                        model_item={
                            allTechInfodata && allTechInfodata.model
                                ? allTechInfodata.model
                                : undefined
                        }
                        year_item={
                            allTechInfodata && allTechInfodata.year
                                ? allTechInfodata.year
                                : undefined
                        }
                        carid={carid}
                    />
                )

            case 'model':
                return (
                    <CarModelCustomFormDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        toggle={toggle}
                        model_item={
                            allTechInfodata && allTechInfodata.model
                                ? allTechInfodata.model
                                : undefined
                        }
                        year_item={
                            allTechInfodata && allTechInfodata.year
                                ? allTechInfodata.year
                                : undefined
                        }
                        make_item={
                            allTechInfodata && allTechInfodata.make
                                ? allTechInfodata.make
                                : undefined
                        }
                        carid={carid}
                    />
                )

            case 'year':
                return (
                    <CarYearCustomFormDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        toggle={toggle}
                        year_item={allTechInfodata.year}
                        model_item={allTechInfodata.model}
                        carid={carid}
                    />
                )

            case 'body_type':
                return (
                    <CarBodyTypeCustomDesktop
                        submitFunc={writeMultipleTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={this.props.body_types_list}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'color_exterior':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
            case 'features':
                return (
                    <CarTechInfoEditFormMultipleSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={features}
                        item={item}
                        toggle={toggle}
                    />
                )
            case 'condition':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={vehicle_condition}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
            case 'color_interior':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_status':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={road_tax_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'service_check_status':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={mot_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'drive_side':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={drive_side_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'fuel_type':
                return (
                    <CarTechInfoEditFormSelectSearchDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={powertype}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'registration_date':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'service_check_expiry':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_due':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'last_owner_change':
                return (
                    <DatePickerTechInfoEditFormDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            default:
                return (
                    <BasicTechInfoEditFormDesktop
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
        }
    }

    render() {
        return (
            <>
                {this.distance_units &&
                    this.fuel_units &&
                    this.renderForm(
                        this.props.item,
                        this.props.toggle,
                        this.props.writeTechnicalInformationRequest,
                        this.props.writeMultipleTechnicalInformationRequest,
                        this.props.id,
                        this.props.allTechInfodata,
                        this.props.carid,
                        this.distance_units,
                        this.fuel_units
                    )}

                <div />
            </>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(TechInfoFormManagerDesktop)

// SelectSearchBarCarInfoDesktop
