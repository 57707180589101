import { insurance_colours } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

function InsuranceReferralCodeIcon(props?: any) {
    const { theme } = useThemes()
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="40"
                height="40"
                rx="4"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_24
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0015 13.0338C14.0028 13.585 14.0324 14.238 14.104 15.0988C14.2476 15.6708 14.8115 16.4454 15.843 17.1008C16.8754 17.7567 18.2966 18.2371 19.9713 18.2371H20.0209C21.6958 18.2371 23.1223 17.7566 24.16 17.1C25.1973 16.4437 25.7641 15.669 25.9074 15.0987C25.979 14.2379 26.0087 13.585 26.0099 13.0338C26.0086 13.0296 26.0071 13.0254 26.0055 13.0214C25.9511 13.0301 25.8378 13.0621 25.6565 13.17C24.7865 13.6991 23.0561 14.4533 20.0156 14.4533H19.9861C16.9448 14.4533 15.2243 13.6987 14.3549 13.17C14.1736 13.0621 14.0604 13.0301 14.0059 13.0214C14.0044 13.0254 14.0029 13.0296 14.0015 13.0338ZM13.2523 11.7066C13.8166 11.3814 14.4817 11.4988 15.1262 11.8835L15.1319 11.8869L15.1319 11.8869C15.7605 12.2697 17.2107 12.9533 19.9861 12.9533H20.0156C22.7918 12.9533 24.2516 12.2693 24.8795 11.8869L24.8852 11.8835L24.8852 11.8835C25.5298 11.4988 26.1949 11.3814 26.7592 11.7066C27.2665 11.9989 27.4528 12.5198 27.5016 12.8452L27.5098 12.8996L27.5099 12.9546C27.5115 13.5886 27.4783 14.3225 27.3976 15.2788L27.3935 15.3276L27.3831 15.3754C27.1406 16.4879 26.2125 17.5764 24.962 18.3676C23.6858 19.1751 21.9814 19.7371 20.0209 19.7371H19.9713C18.0107 19.7371 16.3106 19.175 15.0386 18.3669C13.7918 17.5746 12.8705 16.486 12.6284 15.3754L12.618 15.3276L12.6138 15.2788C12.5332 14.3225 12.5 13.5886 12.5015 12.9546L12.5017 12.8996L12.5098 12.8452C12.5586 12.5198 12.745 11.9989 13.2523 11.7066Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_60
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.7651 21.6406V22.7404C16.7651 23.1121 16.4949 23.4206 16.1401 23.4801L11.8878 25.3351L11.8787 25.3388C11.1899 25.6179 10.703 26.0018 10.3554 26.7605C9.98271 27.5739 9.75 28.8631 9.75 31.0001C9.75 31.4144 9.41421 31.7501 9 31.7501C8.58579 31.7501 8.25 31.4144 8.25 31.0001C8.25 28.7983 8.48253 27.247 8.99171 26.1357C9.52425 24.9734 10.3324 24.3488 11.3059 23.9525L15.679 22.0447L16.7651 21.6406ZM23.2438 21.6571L27.9967 23.3475C28.3869 23.4863 28.5908 23.9152 28.452 24.3055C28.3132 24.6957 27.8843 24.8996 27.494 24.7608L23.9088 23.4857C23.5346 23.4435 23.2438 23.1259 23.2438 22.7404V21.6571Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_100
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.0781 20.481V23.853C24.0781 24.7949 23.5078 25.5573 22.7716 26.0502C22.0306 26.5464 21.0497 26.829 20.0036 26.829C18.9579 26.829 17.9259 26.5449 17.148 25.8367C16.3574 25.1169 15.9292 24.0502 15.9292 22.6936V20.481L17.1059 21.2951C17.6097 21.6436 18.0843 21.9096 18.4268 22.0728C18.6928 22.1832 19.2651 22.3595 19.9924 22.3595H20.0156C20.7427 22.3595 21.3144 22.1833 21.5804 22.0728C21.9229 21.9096 22.3976 21.6436 22.9013 21.2951L24.0781 20.481ZM17.4657 23.2681C17.5601 23.9674 17.8281 24.4273 18.1578 24.7275C18.5831 25.1147 19.2133 25.329 20.0036 25.329C20.7936 25.329 21.4749 25.1133 21.9371 24.8038C22.4041 24.4911 22.5781 24.1404 22.5781 23.853V23.2488C22.4462 23.319 22.3218 23.3815 22.2072 23.4357L22.1932 23.4423L22.1789 23.4484C21.7662 23.6231 20.9885 23.8595 20.0156 23.8595H19.9924C19.0196 23.8595 18.2412 23.6232 17.8283 23.4484L17.8141 23.4423L17.8001 23.4357C17.6961 23.3865 17.5841 23.3305 17.4657 23.2681Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_100
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.9916 6.75C16.6964 6.75 13.9976 9.50606 13.9976 12.9563L13.9976 12.9581C13.9952 13.9344 14.1159 16.3845 14.6023 18.3297C14.8009 19.124 15.4252 19.918 16.2287 20.6163C17.0116 21.2966 17.8785 21.8115 18.4272 22.0729C18.6931 22.1833 19.2654 22.3595 19.9927 22.3595H20.0159C20.7431 22.3595 21.3148 22.1833 21.5808 22.0729C22.1294 21.8115 22.9963 21.2966 23.7792 20.6163C24.5828 19.918 25.207 19.124 25.4056 18.3297C25.892 16.3845 26.0127 13.9344 26.0103 12.9581L26.0103 12.9563C26.0103 9.50581 23.313 6.75 20.018 6.75H19.9916ZM12.4976 12.9554C12.4981 8.72232 15.8238 5.25 19.9916 5.25H20.018C24.186 5.25 27.5098 8.72249 27.5103 12.9552C27.5128 13.9912 27.3895 16.5789 26.8608 18.6935C26.5518 19.9293 25.6548 20.9736 24.7631 21.7485C23.8577 22.5353 22.8637 23.1254 22.2075 23.4358L22.1935 23.4424L22.1792 23.4484C21.7665 23.6232 20.9888 23.8595 20.0159 23.8595H19.9927C19.0199 23.8595 18.2415 23.6232 17.8287 23.4484L17.8144 23.4424L17.8004 23.4358C17.1442 23.1254 16.1503 22.5353 15.2448 21.7485C14.3531 20.9736 13.4561 19.9293 13.1471 18.6935C12.6184 16.579 12.4951 13.9915 12.4976 12.9554ZM12.4976 12.9554C12.4976 12.9551 12.4976 12.9548 12.4976 12.9545L13.2476 12.9563H12.4976C12.4976 12.956 12.4976 12.9557 12.4976 12.9554ZM27.5103 12.9552C27.5103 12.9556 27.5103 12.9559 27.5103 12.9563H26.7603L27.5103 12.9545C27.5103 12.9547 27.5103 12.955 27.5103 12.9552Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_100
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.5138 6.93921L19.0353 10.3587H20.9722L21.4949 6.93913C21.0231 6.81568 20.5284 6.75 20.0177 6.75H19.9913C19.4805 6.75 18.9857 6.81571 18.5138 6.93921ZM17.4117 5.71783C18.2164 5.41501 19.0856 5.25 19.9913 5.25H20.0177C20.9234 5.25 21.7925 5.41501 22.5971 5.71785L23.1662 5.93203L22.4199 10.8146C22.3281 11.4151 21.8116 11.8587 21.204 11.8587H18.8034C18.1957 11.8587 17.6791 11.4149 17.5875 10.8142L16.8428 5.93193L17.4117 5.71783Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_100
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M29.3968 22.875H35.125V28.6032L28.963 34.7652C28.8491 34.8793 28.7137 34.9698 28.5647 35.0315C28.4157 35.0932 28.2561 35.125 28.0948 35.125C27.9335 35.125 27.7739 35.0932 27.6249 35.0315C27.4759 34.9698 27.3406 34.8793 27.2266 34.7652L23.2348 30.7734C23.1207 30.6594 23.0302 30.5241 22.9685 30.3751C22.9068 30.2261 22.875 30.0665 22.875 29.9052C22.875 29.7439 22.9068 29.5843 22.9685 29.4353C23.0302 29.2863 23.1207 29.1509 23.2348 29.037L29.3968 22.875ZM30.2151 26.5574C30.2151 27.2354 30.7646 27.7849 31.4426 27.7849C32.1205 27.7849 32.67 27.2354 32.67 26.5574C32.67 25.8795 32.1205 25.33 31.4426 25.33C30.7646 25.33 30.2151 25.8795 30.2151 26.5574Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_30
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M31.4424 26.0801C31.7061 26.0801 31.9199 26.2939 31.9199 26.5576C31.9199 26.8213 31.7061 27.035 31.4424 27.035C31.1787 27.035 30.965 26.8213 30.965 26.5576C30.965 26.2939 31.1787 26.0801 31.4424 26.0801ZM33.4199 26.5576C33.4199 25.4654 32.5346 24.5801 31.4424 24.5801C30.3503 24.5801 29.465 25.4654 29.465 26.5576C29.465 27.6497 30.3503 28.535 31.4424 28.535C32.5346 28.535 33.4199 27.6497 33.4199 26.5576Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_100
                }
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36 22.6774C36 22.3033 35.6967 22 35.3226 22H29.4096C29.2299 22 29.0576 22.0714 28.9305 22.1984L22.57 28.559C22.3893 28.7396 22.2459 28.954 22.1481 29.19C22.0503 29.426 22 29.6789 22 29.9344C22 30.1899 22.0503 30.4428 22.1481 30.6788C22.2459 30.9148 22.3893 31.1292 22.57 31.3098L26.6902 35.43C26.8708 35.6107 27.0852 35.7541 27.3212 35.8519C27.5572 35.9497 27.8101 36 28.0656 36C28.3211 36 28.574 35.9497 28.81 35.8519C29.046 35.7541 29.2604 35.6107 29.441 35.43L35.8016 29.0695C35.9286 28.9424 36 28.7701 36 28.5904V22.6774ZM34.6452 23.3548V28.3099L28.4828 34.4722L28.4827 34.4723C28.4279 34.5271 28.3629 34.5706 28.2913 34.6002C28.2198 34.6299 28.1431 34.6452 28.0656 34.6452C27.9881 34.6452 27.9114 34.6299 27.8399 34.6002C27.7683 34.5706 27.7033 34.5271 27.6485 34.4723L27.6484 34.4722L23.5278 30.3516L23.5277 30.3515C23.4729 30.2967 23.4294 30.2317 23.3998 30.1601C23.3701 30.0886 23.3548 30.0119 23.3548 29.9344C23.3548 29.8569 23.3701 29.7802 23.3998 29.7087C23.4294 29.6371 23.4729 29.5721 23.5277 29.5173L23.5278 29.5172L29.6901 23.3548H34.6452Z"
                fill={
                    insurance_colours[theme].sections['referral_code']
                        .primary_100
                }
            />
        </svg>
    )
}

export default InsuranceReferralCodeIcon
