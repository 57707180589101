import {
    SET_GARAGE_DATA_ERROR,
    GET_GARAGE_BY_USER_ID_ERROR,
    SET_GARAGE_TASK_IDS_ERROR,
    ADD_A_CAR_TO_GARAGE_ERROR,
    GET_PUBLIC_SHARES_GARAGE_ERROR,
    CREATE_GARAGE_PUBLIC_SHARE_ERROR,
    GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_ERROR,
    SET_CURRENT_EXTERNAL_GARAGE_ERROR,
    GET_OWNED_DIRECT_SHARES_GARAGE_ERROR,
    CREATE_GARAGE_DIRECT_SHARE_ERROR,
    GET_SINGLE_GARAGE_DIRECT_SHARE_ERROR,
    DELETE_GARAGE_DIRECT_SHARE_ERROR,
    ADD_SHARE_RECIPIENT_GARAGE_SHARE_ERROR,
    DELETE_SHARE_RECIPIENT_GARAGE_ERROR,
    UPDATE_GARAGE_DIRECT_SHARE_ERROR,
    CREATE_GARAGE_SHARE_INVITE_ERROR,
    DELETE_GARAGE_SHARE_INVITE_ERROR,
    RESEND_GARAGE_SHARE_INVITE_ERROR,
    ACCEPT_GARAGE_SHARE_INVITE_ERROR,
    SORT_GARAGE_CARS_ERROR,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const setGarageDataError = createAction(
//     SET_GARAGE_DATA_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const setGarageDataError = createAction(
    SET_GARAGE_DATA_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getGarageByUserIdError = createAction(
//     GET_GARAGE_BY_USER_ID_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const getGarageByUserIdError = createAction(
    GET_GARAGE_BY_USER_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const setGarageTaskIdsError = createAction(
//     SET_GARAGE_TASK_IDS_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const setGarageTaskIdsError = createAction(
    SET_GARAGE_TASK_IDS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const addACarToGarageError = createAction(
//     ADD_A_CAR_TO_GARAGE_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const addACarToGarageError = createAction(
    ADD_A_CAR_TO_GARAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getPublicSharesGarageError = createAction(
    GET_PUBLIC_SHARES_GARAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const createGaragePublicShareError = createAction(
    CREATE_GARAGE_PUBLIC_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteGaragePublicShareError = createAction(
    CREATE_GARAGE_PUBLIC_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getExternalGaragesSharedWithYouERROR = createAction(
    GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const setCurrentExternalGarageError = createAction(
    SET_CURRENT_EXTERNAL_GARAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getOwnedDirectSharesGarageError = createAction(
    GET_OWNED_DIRECT_SHARES_GARAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const createGarageDirectShareError = createAction(
    CREATE_GARAGE_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getSingleGarageDirectShareError = createAction(
    GET_SINGLE_GARAGE_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteDirectGarageShareError = createAction(
    DELETE_GARAGE_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addGarageShareRecipientError = createAction(
    ADD_SHARE_RECIPIENT_GARAGE_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteGarageShareRecipientError = createAction(
    DELETE_SHARE_RECIPIENT_GARAGE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const updateGarageDirectShareError = createAction(
    UPDATE_GARAGE_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const createGarageShareInviteError = createAction(
    CREATE_GARAGE_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteGarageShareInviteError = createAction(
    DELETE_GARAGE_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const resendGarageShareInviteError = createAction(
    RESEND_GARAGE_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const acceptGarageShareInviteError = createAction(
    ACCEPT_GARAGE_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError | undefined) {
        return {
            payload,
        }
    }
)

export const setGarageActiveSortingError = createAction(
    SORT_GARAGE_CARS_ERROR,
    function prepare(payload: IReduxError | undefined) {
        return { payload }
    }
)
