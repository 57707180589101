import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'

import './styles.css'
import { IApexPodscastState } from 'apexModels'

const PodcastElemMobile = styled.iframe`
    height: 180px;
    width: 90vw;
    display: flex !important;
    flex-direction: row !important;
    align-self: center !important;
    justify-self: center !important;
    @media ${device.large_desktop} {
    }
`
// min-height: 280px;
// max-height: 280px;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
`

// Col
const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`
// type IStyledProps = {
//     isEven?: boolean
// }

// const ColPWProps = styled.div<IStyledProps>`
//     display: flex;
//     flex-direction: column;
//     align-items: ${(props) => (!props.isEven ? 'flex-start' : 'flex-end')};
//     justify-content: ${(props) => (!props.isEven ? 'flex-start' : 'flex-end')};
//     width: 90vw;
// `

// const Name = styled.div`
//     padding-top: 10px;
//     font-family: 'Lato-Light';
//     color: black;
//     text-transform: uppercase;
//     font-size: 13px;
//     padding-left: 30px;
// `

// const Title = styled.div`
//     font-family: 'Lato-Light';
//     color: #6e6e6e;
//     font-size: 14px;
//     padding-left: 30px;
// `
// const Line2 = styled.div`
//     margin-top: 8px;
//     width: 20px;
//     height: 1px;
//     background-color: #c9c9c9;
//     margin-left: 30px;
// `

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

type IProps = {
    podcasts_list: IApexPodscastState[] | undefined
}

class ApexPodcastsHomeListMobile extends React.Component<IProps, {}> {
    componentDidMount() {}

    render() {
        let { podcasts_list } = this.props
        //create an array to store the embed urls in it
        let spotifyEmbedUrls: string[] = []

        // loop through the podcasts and get the spotify urls and create the correct spotify URL https://open.spotify.com/embed/episode/3AnL7BmcheJH1oGLlcmPGB
        podcasts_list?.forEach((podcast) => {
            // This is the url structure for spotify embeds: https://open.spotify.com/embed/episode/3AnL7BmcheJH1oGLlcmPGB

            const spotifyId = podcast?.spotify_url?.split('/').pop()

            // create the spotify embed URL
            const spotifyEmbedUrl = `https://open.spotify.com/embed/episode/${spotifyId}`
            spotifyEmbedUrls.push(spotifyEmbedUrl)
        })

        return (
            <Wrapper>
                {spotifyEmbedUrls &&
                    spotifyEmbedUrls.map((podcast: string, index: any) => {
                        if (podcast && index < 4) {
                            return (
                                <Col key={`podcast_${index}_mobile`}>
                                    <PodcastElemMobile
                                        title="podcast 1"
                                        src={podcast}
                                        frameBorder={0}
                                        allowTransparency={true}
                                        allow="encrypted-media"
                                    ></PodcastElemMobile>
                                    <div style={{ paddingTop: '15px' }} />
                                </Col>
                            )
                        } else return <div key={index} />
                    })}
            </Wrapper>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(ApexPodcastsHomeListMobile)
