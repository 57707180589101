export const GET_APEX_HOMEPAGE_CONTENT_SUCCESS = '@@apex_homepage/get/SUCCESS'
export const GET_APEX_HOMEPAGE_CONTENT_REQUEST = '@@apex_homepage/get/REQUEST'
export const GET_APEX_HOMEPAGE_CONTENT_ERROR = '@@apex_homepage/get/ERROR'

export const GET_APEX_CARS_OF_THE_WEEK_SUCCESS =
    '@@apex_carsoftheweek/get/SUCCESS'
export const GET_APEX_CARS_OF_THE_WEEK_REQUEST =
    '@@apex_carsoftheweek/get/REQUEST'
export const GET_APEX_CARS_OF_THE_WEEK_ERROR = '@@apex_carsoftheweek/get/ERROR'

export const GET_APEX_PODCASTS_SUCCESS = '@@apex_podcast/get/SUCCESS'
export const GET_APEX_PODCASTS_REQUEST = '@@apex_podcast/get/REQUEST'
export const GET_APEX_PODCASTS_ERROR = '@@apex_podcast/get/ERROR'

export const GET_WEEKLY_HIGHLIGHTS_SUCCESS =
    '@@apex_weeklyhighlights/get/SUCCESS'
export const GET_WEEKLY_HIGHLIGHTS_REQUEST =
    '@@apex_weeklyhighlights/get/REQUEST'
export const GET_WEEKLY_HIGHLIGHTS_ERROR = '@@apex_weeklyhighlights/get/ERROR'

export const GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_SUCCESS =
    '@@apex_interview_overviews/get/SUCCESS'
export const GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_REQUEST =
    '@@apex_interview_overviews/get/REQUEST'
export const GET_APEX_INTERVIEW_OVERVIEWS_CONTENT_ERROR =
    '@@apex_interview_overviews/get/ERROR'

export const GET_APEX_SINGLE_INTERVIEW_BY_UID_SUCCESS =
    '@@apex_single_interview_by_uid/get/SUCCESS'
export const GET_APEX_SINGLE_INTERVIEW_BY_UID_REQUEST =
    '@@apex_single_interview_by_uid/get/REQUEST'
export const GET_APEX_SINGLE_INTERVIEW_BY_UID_ERROR =
    '@@apex_single_interview_by_uid/get/ERROR'

export const GET_APEX_SINGLE_ARTICLE_BY_UID_SUCCESS =
    '@@apex_single_article_by_uid/get/SUCCESS'
export const GET_APEX_SINGLE_ARTICLE_BY_UID_REQUEST =
    '@@apex_single_article_by_uid/get/REQUEST'
export const GET_APEX_SINGLE_ARTICLE_BY_UID_ERROR =
    '@@apex_single_article_by_uid/get/ERROR'

export const GET_APEX_ARTICLE_OVERVIEWS_SUCCESS =
    '@@apex_article_overviews/get/SUCCESS'
export const GET_APEX_ARTICLE_OVERVIEWS_REQUEST =
    '@@apex_article_overviews/get/REQUEST'
export const GET_APEX_ARTICLE_OVERVIEWS_ERROR =
    '@@apex_article_overviews/get/ERROR'
