import { ICar, IGalleryImage } from 'entityModels'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import { useAppSelector } from '../../../../redux/store/hooks'
import DuoGridItemMobile from '../../../atoms/image/duoGridItemMobile'
import ArchivedGarageItemGridView from '../../../molecules/archived/garage/itemGridView'
import Faded from '../../../templates/animated/faded'
import FadedSlower from '../../../templates/animated/FadedSlower'

import { IThreeDotsActionsItems } from '../../../atoms/menu/actionsMenu/actionsMenuDots'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    activeView: 'grid' | 'list'
    cars: ICar[]
    menu_items: (car: ICar) => IThreeDotsActionsItems[]
    onThreeDotsClick?: (carid: string) => void
}

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
`

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    width: 100%;
    height: 100%;
`

const ArchivedGarageManagerMobile = (props: Props) => {
    let { activeView, cars, menu_items, onThreeDotsClick } = props

    const images_data = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    const returnCarProfileImg = (p: ICar): IGalleryImage | undefined => {
        if (p.gallery) {
            let cover_id = p.gallery.cover
            let cover_img: IGalleryImage | undefined =
                cover_id && images_data && images_data[cover_id]
                    ? images_data[cover_id]
                    : undefined

            return cover_img
        } else return undefined
    }

    let listView = useMemo(() => {
        return (
            <ListContainer>
                {cars.map((car: ICar, index: number) => {
                    return (
                        <ArchivedGarageItemGridView
                            menu_items={menu_items}
                            car={car}
                            onThreeDotsClick={onThreeDotsClick}
                        />
                    )
                })}
            </ListContainer>
        )
    }, [cars])

    const { theme } = useThemes()
    const placeholder = randomCarSvgPlaceholderFunc(undefined, theme)

    let gridView = useMemo(() => {
        return (
            <GridContainer>
                {cars.map((car: ICar, index: number) => {
                    return (
                        <Link
                            replace={true}
                            to={`/archives/car/${car.id}/overview`}
                            key={index}
                        >
                            <DuoGridItemMobile
                                img={returnCarProfileImg(car)}
                                placeholder={placeholder}
                            />
                        </Link>
                    )
                })}
            </GridContainer>
        )
    }, [cars])

    return (
        <>
            {activeView === 'list' && <FadedSlower>{listView}</FadedSlower>}
            {activeView === 'grid' && <Faded>{gridView}</Faded>}
        </>
    )
}

export default ArchivedGarageManagerMobile
