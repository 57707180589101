import { removeUndefinedfromObject } from '../../helpers/objects'
import { I_showroom_entry_update_req_payload } from '../../showroom/sagas/updateShowroomEntry'
import {
    IShowroomEntryAPI,
    IUpdateShowroomEntry_api_req,
} from '../../showroom/types'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'
import Decimal from 'decimal.js'

export const updateShowroomEntry = (
    p: I_showroom_entry_update_req_payload
): Promise<IShowroomEntryAPI> => {
    if (p.data.price_min) {
        let price_min_to_send = new Decimal(p.data.price_min).times(100)
        p.data.price_min = +price_min_to_send
    }

    if (p.data.price_max) {
        let price_max_to_send = new Decimal(p.data.price_max).times(100)
        p.data.price_max = +price_max_to_send
    }

    if (p.data.type_of_sale === 'PRICE_ON_ASKING') {
        p.data.currency = undefined
    }

    // run removeUndefined func here (move from down)
    let dataCleaned: IUpdateShowroomEntry_api_req = removeUndefinedfromObject(
        p.data
    )

    let dataFinal: string = JSON.stringify(dataCleaned)

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: { ...customHeader },
        body: dataFinal,
        credentials: 'include',
    }

    return fetch(urls.update_showroom_entry(p.uid), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Update showroom entry error',
                            dataFinal
                        )
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    if (data) {
                        return data
                    } else return
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
