import styled from 'styled-components'
import chevron_to_left from '../../../../../public/assets/navigation/chevron_to_left.svg'
import FadedSlower from '../../../animated/FadedSlower'
import { Popconfirm, Tooltip } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useState } from 'react'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { insuranceActions } from '../../../../../redux/insuranceQuoteApplication/reducer'
import CircleLoader from '../../../../atoms/loader/circleLoader'
import { IInsuranceApplicationSectionId } from '../../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../../../providers/theme/hooks'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../../providers/theme/colours'

import play from '../../../../../public/assets/icons/play.svg'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { motion } from 'framer-motion'
import posthog from 'posthog-js'
import WizardVisibilityAnimatedSvg from '../../../../atoms/animatedSvgs/wizardVisibility'

type IStyle = { $theme: ITheme }

const Container = styled.div<IStyle>`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 80px;
    z-index: 100;
    background-color: ${(props) => colours[props.$theme].background_default};
    border-bottom: ${(props) =>
        `1px solid ${colours[props.$theme].border_muted}`};
    transition: all 100ms;
    z-index: 101;
`

const SubContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 76px;
    width: 100%;
    padding: 1rem 2rem;
    position: relative;
`
const Row = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const BreadCrumbs = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`
const BreadCrumbsItem = styled.div`
    width: '100%';
    display: flex;
    gap: 8px;
    align-items: center;
`
const BreadCrumbsItemText = styled.p`
    all: unset;
    font-family: Lato;
    font-size: 18px;
    color: var(--text-strong, #1a1a1a);
`

const ProgressRate = styled.div<IStyle>`
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    height: 48px;
    background: ${(props) => colours[props.$theme].background_neutral_subtle};
    border-radius: 4px;
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato-bold;
    font-size: 18px;
`

const ProgressBar = styled.div`
    all: unset;
    height: 4px;
    border-radius: 4px;
    transition: all 500ms;
`

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(83%) contrast(84%);
    height: 14px;
    margin-left: 6px;
    transform: rotate(180deg);
`

const CloseBtn = styled.button<IStyle>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    background: ${(props) => colours[props.$theme].background_default};
    color: ${(props) => colours[props.$theme].text_default};
    border: ${(props) => `1px solid ${colours[props.$theme].border_muted}`};
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;

    :hover {
        background-color: ${(props) => colours[props.$theme].border_muted};
    }
`
const Title = styled.div<IStyle>`
    font-family: Lato;
    font-size: 16px;
    color: ${(props) => colours[props.$theme].text_darker};
    padding-bottom: 4px;
`

const LoaderText = styled.div<IStyle>`
    font-family: Lato-Semibold;
    font-size: 18px;
    color: ${(props) => colours[props.$theme].text_muted};
`

const AbsolutePositioning = styled.div`
    position: absolute;
    bottom: 0px;
    margin-bottom: -200px;
    right: 0px;
`

export const AtdConfWarningBox = ({
    title,
    theme,
}: {
    title: string
    theme: ITheme
}) => (
    <div
        style={{
            maxWidth: 320,
            minWidth: 240,
            paddingRight: 24,
            paddingLeft: 24,
            transform: 'translateY(-2px)',
        }}
    >
        <Title $theme={theme}>{title}</Title>
    </div>
)

type Props = {
    breadCrumbs?: { pageName: string; pageUrl?: () => void; isCaps?: boolean }[]
    progressionRate?: number
    goBack?: () => void
    onSave?: () => void
    hasFormChanged?: boolean
    isDisabled?: boolean
    disabledProgressText?: string
    sectionId?: IInsuranceApplicationSectionId
    wizard_click_play?: () => any
}
const InsuranceTopBarWithProgressionRate = (props: Props) => {
    let {
        progressionRate,
        breadCrumbs,
        goBack,
        onSave,
        hasFormChanged,
        isDisabled,
        disabledProgressText,
        sectionId,
        wizard_click_play,
    } = props

    const dispatch = useAppDispatch()

    const [showGoBackConf, toggleGoBackConf] = useState(false)
    const [breadCrumbActiveIndex, setBreadCrumbActiveIndex] = useState(-1)

    const handleOpenChangeConf = (open: boolean) => {
        if (!open) {
            toggleGoBackConf(open)
            return
        }

        if (hasFormChanged) {
            toggleGoBackConf(open)
        } else {
            goBack && goBack()
        }
    }

    const saveChanges = () => {
        toggleGoBackConf(false)
        onSave && onSave()
    }

    const destroyChanges = (func: (() => void) | undefined) => {
        toggleGoBackConf(false)
        dispatch(insuranceActions.reset_draft_insurance_application())
        func && func()
    }

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )
    let applicationLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )
    let getApplicationLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isGetLoading
    )

    let showLoadingUI =
        !application.id || applicationLoading || getApplicationLoading
            ? true
            : false

    const { theme } = useThemes()

    // let is_100 = application?.overall_completion === 100 ? true : false
    return (
        <Container $theme={theme}>
            <SubContainer>
                <div style={{ display: 'flex', gap: 24 }}>
                    {goBack && (
                        <FadedSlower duration={0.3}>
                            <CloseBtn $theme={theme} onClick={goBack}>
                                <Chevron
                                    src={chevron_to_left}
                                    style={{
                                        transform: 'rotate(0) translateX(-4px)',
                                    }}
                                />
                                Go back
                            </CloseBtn>
                        </FadedSlower>
                    )}

                    {breadCrumbs && (
                        <Popconfirm
                            title={
                                <AtdConfWarningBox
                                    theme={theme}
                                    title="You have unsaved changes."
                                />
                            }
                            open={showGoBackConf}
                            onOpenChange={handleOpenChangeConf}
                            onConfirm={saveChanges}
                            onCancel={() => {
                                let func =
                                    breadCrumbs &&
                                    breadCrumbActiveIndex >= 0 &&
                                    breadCrumbs[breadCrumbActiveIndex].pageUrl
                                        ? breadCrumbs[breadCrumbActiveIndex]
                                              .pageUrl
                                        : undefined
                                destroyChanges(func)
                            }}
                            okText="Save"
                            cancelText="Discard changes"
                            okButtonProps={{
                                style: {
                                    backgroundColor: sectionId
                                        ? insurance_colours[theme].sections[
                                              sectionId
                                          ].primary_100
                                        : colours[theme].primary,
                                    border: 'none',
                                    borderRadius: 4,
                                    height: 32,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                },
                            }}
                            cancelButtonProps={{
                                danger: true,
                                style: {
                                    borderRadius: 4,
                                    height: 32,
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                },
                            }}
                            placement="bottomLeft"
                            style={{
                                borderRadius: 4,
                                transform: 'translateY(-8px) !important',
                            }}
                            icon={
                                <ExclamationCircleFilled
                                    style={{ color: colours[theme].error }}
                                />
                            }
                        >
                            <BreadCrumbs>
                                {breadCrumbs.map((item, i) => {
                                    let isCurrentPage =
                                        i === breadCrumbs!.length - 1
                                    return (
                                        <BreadCrumbsItem
                                            onClick={() => {
                                                setBreadCrumbActiveIndex(i)
                                                if (hasFormChanged) {
                                                    toggleGoBackConf(true)
                                                } else {
                                                    !isCurrentPage &&
                                                        item.pageUrl &&
                                                        item.pageUrl()
                                                }
                                            }}
                                            key={`progress_bar_nav_${i}_desktop`}
                                        >
                                            <BreadCrumbsItemText
                                                style={{
                                                    color: isCurrentPage
                                                        ? sectionId
                                                            ? insurance_colours[
                                                                  theme
                                                              ].sections[
                                                                  sectionId
                                                              ].primary_text
                                                            : colours[theme]
                                                                  .text_strong
                                                        : colours[theme]
                                                              .text_neutral_default,
                                                    cursor: !isCurrentPage
                                                        ? 'pointer'
                                                        : 'default',
                                                    textTransform: item.isCaps
                                                        ? 'uppercase'
                                                        : undefined,
                                                    fontSize: item.isCaps
                                                        ? 16
                                                        : 18,
                                                }}
                                            >
                                                {item.pageName}
                                            </BreadCrumbsItemText>
                                            {breadCrumbs &&
                                                breadCrumbs.length > 1 &&
                                                !isCurrentPage && (
                                                    <Chevron
                                                        src={chevron_to_left}
                                                    />
                                                )}
                                        </BreadCrumbsItem>
                                    )
                                })}
                            </BreadCrumbs>
                        </Popconfirm>
                    )}
                </div>

                <Row>
                    {!showLoadingUI && progressionRate ? (
                        <ProgressRate $theme={theme}>
                            {isDisabled && disabledProgressText
                                ? disabledProgressText
                                : `${progressionRate}%`}
                        </ProgressRate>
                    ) : null}

                    {showLoadingUI && (
                        <div
                            style={{
                                display: 'flex',
                                padding: '12px 16px',
                                alignItems: 'center',
                                borderRadius: 4,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                                gap: 16,
                            }}
                        >
                            <LoaderText $theme={theme}>
                                Updating progress
                            </LoaderText>{' '}
                            <CircleLoader
                                size={24}
                                color={
                                    sectionId
                                        ? insurance_colours[theme].sections[
                                              sectionId
                                          ].primary_100
                                        : colours[theme].primary
                                }
                            />
                        </div>
                    )}

                    {showLoadingUI || !wizard_click_play ? null : (
                        <FadedSlower>
                            <AbsolutePositioning>
                                <WizardVisibilityAnimatedSvg />
                            </AbsolutePositioning>
                        </FadedSlower>
                    )}
                    {wizard_click_play && (
                        <>
                            <div style={{ paddingRight: '20px' }} />
                            <motion.div whileTap={{ scale: 1.1 }}>
                                <Tooltip title="Enter wizard">
                                    <div style={{ cursor: 'pointer' }}>
                                        <ButtonAtom
                                            theme="naked"
                                            onClick={() => {
                                                if (wizard_click_play) {
                                                    posthog.capture(
                                                        'play insurance wizard click'
                                                    )
                                                    wizard_click_play()
                                                }
                                            }}
                                        >
                                            <img
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '40px',
                                                }}
                                                src={play}
                                            />
                                        </ButtonAtom>
                                    </div>
                                </Tooltip>
                            </motion.div>
                        </>
                    )}
                </Row>
            </SubContainer>
            {progressionRate ? (
                <ProgressBar
                    style={{
                        width: `${
                            showLoadingUI || isDisabled ? '0' : progressionRate
                        }vw`,
                        backgroundColor: sectionId
                            ? insurance_colours[theme].sections[sectionId]
                                  .primary_100
                            : colours[theme].primary,
                    }}
                />
            ) : null}
        </Container>
    )
}

export default InsuranceTopBarWithProgressionRate
