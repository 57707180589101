import React from 'react'
// import debounce from "lodash/debounce";
import Fuse from 'fuse.js'
import SelectSingleDesktop from './selectSingleDesktop'
import { debounce } from 'lodash'
import SingleSelectOnlyMatchedDesktop from './singleSelectOnlyMatchedDesktop'

export type Props = {
    items: any // IDropdownItems[] ||  IInsuranceDropdownItem[]
    item: any
    height?: string
    width?: string
    handleSelectChange?: any
    addAnItemFunc?: any
    dropdownTargetId?: string
    formCurrentValue?: string
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    helperText?: string
    onlyShowMatch?: boolean
    dataCyId?: string | undefined
    customPlaceholder?: string
    sendId?: boolean
    isKeyValue?: boolean
    valueUpdatedFromExternalSource?: string | null
    clearFieldIfValueUpdatedFromExternalSource?: boolean
    customMaxWidth?: string
    showBothDevices?: boolean
    iconsSize?: string
    backgroundColor?: string
    borderColor?: string
    sendDropdownItem?: boolean
    isValueLabel?: boolean
    showEmptyBox?: boolean
    field_id?: string
    textTransform?: string
    customActiveColour?: string
    customHighlightColour?: string
}

type LocalState = {
    data: any[]
    name: any[]
    fetching: boolean
}

class SelectSearchBarCarInfoDesktop extends React.Component<Props, LocalState> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        this.fetchItem = debounce(this.fetchItem, 300)
    }

    state = {
        data: [],
        name: [],
        fetching: false,
    }

    lastFetchId = 0

    fetchItem = (name: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
        }

        let fuse: any = {}

        if (this.props.isKeyValue === true) {
            fuse = new Fuse(this.props.items, { threshold: 0.2 })
        } else {
            if (this.props.isValueLabel === true) {
                fuse = new Fuse(this.props.items, {
                    keys: ['label', 'value'],
                    threshold: 0.2,
                })
            } else
                fuse = new Fuse(this.props.items, {
                    keys: ['name', 'uid'],
                    threshold: 0.2,
                })
        }

        let results: any = fuse.search(name)

        return this.setState({ data: results ? results : [], fetching: false })
    }

    handleChange = (name: any) => {
        this.setState({
            ...this.state,
            name: [...this.state.name, name],
            data: [],
            fetching: false,
        })
        this.props.handleSelectChange && this.props.handleSelectChange(name)
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.valueUpdatedFromExternalSource !==
            prevProps.valueUpdatedFromExternalSource
        ) {
            this.props.valueUpdatedFromExternalSource &&
                this.setState({
                    ...this.state,
                    name: this.props.clearFieldIfValueUpdatedFromExternalSource
                        ? [...this.state.name, undefined]
                        : [
                              ...this.state.name,
                              this.props.valueUpdatedFromExternalSource,
                          ],
                    data: [],
                    fetching: false,
                })
        }
    }

    render() {
        const { fetching, name, data } = this.state
        const {
            item,
            width,
            height,
            addAnItemFunc,
            dropdownTargetId,
            formCurrentValue,
            allowAdd,
            isDisabled,
            placeholder,
            helperText,
            onlyShowMatch,
            dataCyId,
            customPlaceholder,
            sendId,
            isKeyValue,
            showBothDevices,
            iconsSize,
            backgroundColor,
            customMaxWidth,
            sendDropdownItem,
            borderColor,
            showEmptyBox,
            field_id,
            textTransform,
            customActiveColour,
            customHighlightColour,
        } = this.props

        return (
            <React.Fragment>
                {onlyShowMatch === true ? (
                    <SingleSelectOnlyMatchedDesktop
                        field_id={field_id}
                        sendDropdownItem={sendDropdownItem}
                        backgroundColor={backgroundColor}
                        borderColor={borderColor}
                        fetching={fetching}
                        value={name}
                        data={data}
                        fetchItem={this.fetchItem}
                        handleChange={this.handleChange}
                        item={item}
                        width={width && width}
                        height={height && height}
                        addAnItemFunc={addAnItemFunc && addAnItemFunc}
                        dropdownTargetId={dropdownTargetId && dropdownTargetId}
                        formCurrentValue={formCurrentValue && formCurrentValue}
                        allowAdd={allowAdd && allowAdd}
                        isDisabled={isDisabled && isDisabled}
                        placeholder={placeholder && placeholder}
                        helperText={helperText}
                        dataCyId={dataCyId}
                        customPlaceholder={customPlaceholder}
                        customMaxWidth={customMaxWidth}
                        sendId={sendId}
                        resetSearch={() =>
                            this.setState({
                                ...this.state,
                                data: [],
                            })
                        }
                        customActiveColour={customActiveColour}
                        customHighlightColour={customHighlightColour}
                    />
                ) : (
                    <SelectSingleDesktop
                        field_id={field_id}
                        showEmptyBox={showEmptyBox}
                        sendDropdownItem={sendDropdownItem}
                        backgroundColor={backgroundColor}
                        borderColor={borderColor}
                        fetching={fetching}
                        value={name}
                        data={data}
                        isValueLabel={this.props.isValueLabel}
                        fetchItem={this.fetchItem}
                        handleChange={this.handleChange}
                        optionsList={this.props.items}
                        item={item}
                        width={width && width}
                        height={height && height}
                        addAnItemFunc={addAnItemFunc && addAnItemFunc}
                        dropdownTargetId={dropdownTargetId && dropdownTargetId}
                        formCurrentValue={formCurrentValue && formCurrentValue}
                        allowAdd={allowAdd && allowAdd}
                        isDisabled={isDisabled && isDisabled}
                        placeholder={placeholder && placeholder}
                        helperText={helperText}
                        dataCyId={dataCyId}
                        sendId={sendId}
                        isKeyValue={isKeyValue}
                        showBothDevices={showBothDevices}
                        iconsSize={iconsSize}
                        customMaxWidth={customMaxWidth}
                        resetSearch={() =>
                            this.setState({
                                ...this.state,
                                data: [],
                            })
                        }
                        textTransform={textTransform}
                        customActiveColour={customActiveColour}
                        customHighlightColour={customHighlightColour}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default SelectSearchBarCarInfoDesktop
