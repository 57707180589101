import { IInsuranceApplication_API } from '../../insuranceQuoteApplication/types'
import { apiSentryErrorHandler } from '../errorHandler'
import { customHeader } from '../headers'
import * as urls from '../urls'

export const getInsuranceApplication = (
    p: string
): Promise<IInsuranceApplication_API> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.insurance_appli_get(p), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Get insurance application by id error',
                            `${p}`
                        )
                        return Promise.reject(data)
                    }

                    if (data) {
                        return data
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const getUserInsuranceApplications =
    (): Promise<IInsuranceApplication_API> => {
        const requestOptions: RequestInit = {
            method: 'GET',
            headers: { ...customHeader },
            credentials: 'include',
        }

        return fetch(urls.insurance_applications_user_get, requestOptions).then(
            (response: any) => {
                return response
                    .json()
                    .then((data: any) => {
                        if (response.ok !== true) {
                            apiSentryErrorHandler(
                                data,
                                'Get insurance applications error'
                            )
                            return Promise.reject(data)
                        }

                        if (data) {
                            return data
                        }
                    })
                    .catch((e: any) => {
                        return Promise.reject(e)
                    })
            }
        )
    }
