import React from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import CustomTagQuickFilterItemDesktop from '../../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FadedSlower from '../../../templates/animated/FadedSlower'
import Expander from '../../../atoms/expander/expander'
import FullWidthLightGreyBgButton from '../../../atoms/Button/fullWidthLightGreyBg'
import AddLabelButton from '../../../atoms/Button/addLabelButton'
import { IDropdownItem } from 'entityModels'
import { are2arraysEqual } from '../../../../helpers/arrayChecks/are2arraysEqual'
import ButtonsStandardBottomRowMobile from '../../editForms/buttons/buttonsStandardBottomRowMobile'
import colours, { ITheme } from '../../../../providers/theme/colours'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import LabelIcon from '../../../atoms/icons/components/labelIcon'
import { AbsoluteBlueLabel } from './filterByMultipleDesktop'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    // addCategoryFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    user_searchable_labels_list?: IDropdownItem[] | null | undefined
    value?: any
    handleChange?: any
    categoryData?: any
    fetchItem?: any
    labelsDataRes?: any
    addLabelButton?: React.ReactNode
    onSave?: any
    hasCreateNewLabel?: boolean
    clearField?: any
    selectedFilteringLabels: string[]
    dataCyIdSave?: string
}

type IStyle = {
    selected?: boolean
    issearchactive?: boolean
    top?: number
    fixedwidth?: boolean
}

const SearchFieldWrapper = styled.div`
    width: 100%;
    padding-bottom: 10px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 20px;
`

const SearchField = styled.input<IStyle>`
    border: ${(props) =>
        props.issearchactive
            ? '1px solid var(--primary, #5ec3ca)'
            : '1px solid var(--text-muted)'};
    border-radius: 5px;
    font-family: Lato;
    font-size: 16px;
    color: var(--text-darker, #616161);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    /* height: ${(props) => (props.issearchactive ? '40px' : '38px')}; */
    height: 48px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    padding: 12px 16px;

    ::placeholder {
        color: var(--text-muted);
    }
    :active {
        border: 1px solid var(--primary, #5ec3ca);
    }
`

const DropdownOptions = styled.ul`
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    width: 280px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 10px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    height: 40px;
    background-color: transparent;
    padding-left: 16px;
    cursor: pointer;
    text-transform: capitalize;

    :hover {
        background-color: var(--off-bg-color, #fafafa);
    }
`

const Wrapper = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

const Count = styled.div`
    padding-left: 20px;
    padding-top: 12px;
    font-style: italic;
    color: var(--text-muted);
    font-family: 'Lato-Light';
    font-size: 12px;
`

const SelectedCriteriaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 0px;
`

const BtnText = styled.p`
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
    color: var(--text-darker, #616161);
`

type StateSelect = {
    showDropdown: boolean
    addedCriteria: string[]
    issearchactive: boolean
}

class FilterTimelineByMultipleWithSearchUIDesktop extends React.Component<
    Props,
    StateSelect
> {
    state = {
        showDropdown: false,
        addedCriteria: this.props.selectedFilteringLabels
            ? this.props.selectedFilteringLabels
            : [],
        issearchactive: false,
    }

    _isMounted = false

    private dropdowRef = React.createRef<HTMLDivElement>()
    private searchFieldRef = React.createRef<HTMLInputElement>()

    handleClickOutsideDropdown(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.setState({
                showDropdown: false,
                addedCriteria: this.props.selectedFilteringLabels
                    ? this.props.selectedFilteringLabels
                    : [],
            })
        }
    }

    handleClickOutsideSearch(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.setState({ issearchactive: false })
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.dropdowRef.current!.focus()
        let dropdownNode = this.dropdowRef.current
        let searchNode = this.searchFieldRef.current
        document.addEventListener('mousedown', (e: any) => {
            this.handleClickOutsideDropdown(e, dropdownNode)
            this.handleClickOutsideSearch(e, searchNode)
        })
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.selectedFilteringLabels !==
                prevProps.selectedFilteringLabels &&
            this.props.selectedFilteringLabels
        ) {
            this.setState({
                addedCriteria: this.props.selectedFilteringLabels,
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e: any) => {
            this.handleClickOutsideDropdown(e)
            this.handleClickOutsideSearch(e)
        })
        this._isMounted = false
    }

    render() {
        const {
            dataCyId,
            // categoryData,
            labelsDataRes,
            handleChange,
            fetchItem,
            value,
            addLabelButton,
            user_searchable_labels_list,
            onSave,
            hasCreateNewLabel,
            clearField,
            // dataCyIdSave,
        } = this.props

        let allDropdownLabelNames =
            user_searchable_labels_list &&
            user_searchable_labels_list.map(
                (label: IDropdownItem) => label.name
            )
        let dropdownLabelNamesResults =
            labelsDataRes && labelsDataRes.map((label: any) => label.item.name)

        const handleFilterButtonClick = () => {
            if (this.state.showDropdown === true) {
                this.setState({
                    showDropdown: false,
                })
            } else {
                this.setState({
                    showDropdown: true,
                })
            }
        }
        let currentSelectedCriteria: string[] = [...this.state.addedCriteria]

        const addOrRemoveCriteria = (criteria: string) => {
            let indexOfClickedCriteria: number =
                currentSelectedCriteria.indexOf(criteria)
            if (indexOfClickedCriteria < 0) {
                this.setState({
                    addedCriteria: [...this.state.addedCriteria, criteria],
                })
            } else {
                currentSelectedCriteria.splice(indexOfClickedCriteria, 1)
                this.setState({
                    addedCriteria: currentSelectedCriteria,
                })
            }
        }

        let addedCriteria = this.state.addedCriteria.map(
            (criteria: string, i: number) => (
                <div style={{ padding: '3px' }} key={`${criteria} - ${i}`}>
                    <FadedSlower duration={0.2}>
                        <CustomTagQuickFilterItemDesktop
                            isActive={true}
                            onCrossClick={() => addOrRemoveCriteria(criteria)}
                        >
                            {criteria}
                        </CustomTagQuickFilterItemDesktop>
                    </FadedSlower>
                </div>
            )
        )

        // let mappedCategoryData =
        //     categoryData &&
        //     categoryData.map((item: any, index: number) => {
        //         let category = item.item

        //         if (category) {
        //             return (
        //                 <StyledOption
        //                     selected={currentSelectedCriteria.includes(
        //                         category.name
        //                     )}
        //                     value={category.name}
        //                     key={`${category.name}-category-${index}`}
        //                     aria-label={category.name}
        //                 >
        //                     <ContentRow>
        //                         <CustomAnimatedCheckboxField
        //                             // textchangedisabled
        //                             width="100%"
        //                             onClick={() =>
        //                                 addOrRemoveCriteria(category.name)
        //                             }
        //                             checked={currentSelectedCriteria.includes(
        //                                 category.name
        //                             )}
        //                         >
        //                             {category.name}{' '}
        //                         </CustomAnimatedCheckboxField>
        //                     </ContentRow>
        //                 </StyledOption>
        //             )
        //         } else {
        //             return (
        //                 <StyledOption key={index} style={{ display: 'none' }} />
        //             )
        //         }
        //     })

        let mappedTagsData = (theme: ITheme) =>
            labelsDataRes &&
            labelsDataRes.map((item: any, index: number) => {
                let label = item.item
                if (label && index < 3) {
                    return (
                        <StyledOption
                            selected={currentSelectedCriteria.includes(
                                label.name
                            )}
                            value={label.name}
                            key={`${label.name}-tag-${index}`}
                        >
                            <ContentRow>
                                <CustomAnimatedCheckboxField
                                    // textchangedisabled
                                    width="100%"
                                    // iconWidth="20"
                                    onClick={() =>
                                        addOrRemoveCriteria(label.name)
                                    }
                                    checked={currentSelectedCriteria.includes(
                                        label.name
                                    )}
                                    small
                                    borderColor={colours[theme].text_darker}
                                >
                                    <div
                                        style={{
                                            color: colours[theme].text_darker,
                                            paddingLeft: 8,
                                            transform: 'translateY(-3px)',
                                        }}
                                    >
                                        {label.name}
                                    </div>
                                </CustomAnimatedCheckboxField>
                            </ContentRow>
                        </StyledOption>
                    )
                } else {
                    return (
                        <StyledOption key={index} style={{ display: 'none' }} />
                    )
                }
            })

        const manageissearchactive = (isActive: boolean) => {
            this.setState({
                ...this.state,
                issearchactive: isActive,
            })
        }

        const checkIfShowAddLabel = () => {
            if (
                !allDropdownLabelNames ||
                (!allDropdownLabelNames.includes(value[0]) &&
                    !dropdownLabelNamesResults.includes(value[0]))
            ) {
                return (
                    <FadedSlower>
                        <FullWidthLightGreyBgButton
                            text={`+ Create new label: ${value[0]}`}
                            onClick={() => {
                                addOrRemoveCriteria(value[0])
                                clearField && clearField()
                            }}
                            nobg
                        />
                    </FadedSlower>
                )
            }
        }

        let show_all_options = (theme: ITheme) => {
            // later : truncate labels_searchable_list to 2 array, 1 made of first 3 items, the other of the rest
            // on clicking the +N, shows array #2 mapped below already mapped array #1

            return (
                <>
                    {user_searchable_labels_list &&
                        user_searchable_labels_list.map(
                            (label: any, index: number) => {
                                if (index < 10) {
                                    return (
                                        <StyledOption
                                            selected={currentSelectedCriteria.includes(
                                                label.name
                                            )}
                                            value={label.name}
                                            key={`${label.name}-label-${index}`}
                                        >
                                            <CustomAnimatedCheckboxField
                                                // textchangedisabled
                                                width="100%"
                                                // iconWidth="20"
                                                onClick={() =>
                                                    addOrRemoveCriteria(
                                                        label.name
                                                    )
                                                }
                                                checked={currentSelectedCriteria.includes(
                                                    label.name
                                                )}
                                                small
                                                borderColor={
                                                    colours.light.text_darker
                                                }
                                            >
                                                <div
                                                    style={{
                                                        color: colours[theme]
                                                            .text_darker,
                                                        paddingLeft: 8,
                                                        transform:
                                                            'translateY(-3px)',
                                                    }}
                                                >
                                                    {label.name}
                                                </div>
                                            </CustomAnimatedCheckboxField>
                                        </StyledOption>
                                    )
                                } else {
                                    return <div style={{ display: 'none' }} />
                                }
                            }
                        )}

                    {user_searchable_labels_list &&
                        user_searchable_labels_list.length > 10 && (
                            <Count>
                                +{10 - user_searchable_labels_list.length - 1}
                            </Count>
                        )}
                </>
            )
        }

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper
                        data-attr={dataCyId}
                        ref={this.dropdowRef}
                        fixedwidth={addLabelButton ? true : false}
                    >
                        {addLabelButton ? (
                            <AddLabelButton
                                height="24px"
                                onClick={handleFilterButtonClick}
                                isactive={this.state.showDropdown}
                            />
                        ) : (
                            <StandardCtaBtn
                                onClick={handleFilterButtonClick}
                                boder={
                                    this.state.showDropdown
                                        ? `2px solid ${colours[theme].border_muted}`
                                        : `1px solid ${colours[theme].border_muted}`
                                }
                                bg={
                                    this.state.showDropdown
                                        ? colours[theme]
                                              .background_neutral_subtle
                                        : 'transparent'
                                }
                                customHeight="48px"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 8,
                                        position: 'relative',
                                    }}
                                >
                                    <LabelIcon
                                        color={colours[theme].text_darker}
                                    />
                                    <BtnText>Labels</BtnText>
                                    {this.state.addedCriteria.length > 0 ? (
                                        <AbsoluteBlueLabel>
                                            {this.state.addedCriteria.length}
                                        </AbsoluteBlueLabel>
                                    ) : null}
                                </div>
                            </StandardCtaBtn>
                        )}

                        <SlideDownWrapper
                            isOpen={this.state.showDropdown}
                            initialX={-280}
                            originX={'280px'}
                        >
                            <DropdownOptions>
                                <Expander
                                    height={
                                        this.state.addedCriteria.length > 0
                                            ? 'auto'
                                            : 0
                                    }
                                >
                                    <SelectedCriteriaWrapper>
                                        {addedCriteria}
                                    </SelectedCriteriaWrapper>
                                </Expander>

                                {user_searchable_labels_list &&
                                user_searchable_labels_list.length > 0 ? (
                                    <>
                                        <SearchFieldWrapper
                                            onClick={() =>
                                                manageissearchactive &&
                                                manageissearchactive(true)
                                            }
                                        >
                                            <SearchField
                                                placeholder={'Search labels'}
                                                type="search"
                                                onChange={(e: any) => {
                                                    handleChange(e.target.value)
                                                    fetchItem(e.target.value)
                                                }}
                                                issearchactive={
                                                    this.state.issearchactive
                                                }
                                                value={value[0]}
                                                ref={this.searchFieldRef}
                                            />
                                        </SearchFieldWrapper>
                                        <BtnText
                                            style={{
                                                paddingLeft: 16,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                color: colours[theme]
                                                    .text_strong,
                                                textAlign: 'left',
                                                fontFamily: 'Lato-Semibold',
                                                width: '100%',
                                                fontSize: 16,
                                            }}
                                        >
                                            Labels in use
                                        </BtnText>
                                    </>
                                ) : (
                                    <BtnText
                                        style={{
                                            padding: 16,
                                            fontStyle: 'italic',
                                            color: colours[theme].text_muted,
                                            textAlign: 'left',
                                            width: '100%',
                                        }}
                                    >
                                        No labels in use
                                    </BtnText>
                                )}

                                <FadedSlower>
                                    <div
                                        style={{
                                            width: '100%',
                                            maxHeight: 280,
                                            overflowY: 'auto',
                                        }}
                                    >
                                        {labelsDataRes.length === 0 &&
                                            show_all_options(theme)}
                                        <div style={{ paddingTop: 16 }} />
                                    </div>
                                </FadedSlower>

                                {/* {categoryData &&
                            categoryData.length > 0 &&
                            mappedCategoryData} */}
                                <div
                                    style={{
                                        width: '100%',
                                        maxHeight: 280,
                                        overflowY: 'auto',
                                    }}
                                >
                                    {labelsDataRes &&
                                        labelsDataRes.length > 0 &&
                                        mappedTagsData(theme)}
                                    {labelsDataRes &&
                                        labelsDataRes.length > 0 && (
                                            <div style={{ paddingTop: 16 }} />
                                        )}
                                </div>
                                <div
                                    style={{
                                        paddingTop: hasCreateNewLabel
                                            ? '16px'
                                            : 0,
                                        paddingBottom: '0px',
                                    }}
                                >
                                    {hasCreateNewLabel &&
                                        value &&
                                        value[0] !== '' &&
                                        value &&
                                        value[0] !== undefined &&
                                        value[0].trim().length > 1 &&
                                        checkIfShowAddLabel()}

                                    {are2arraysEqual(
                                        this.props.selectedFilteringLabels,
                                        this.state.addedCriteria
                                    ) !== true && (
                                        <FadedSlower>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    paddingTop: 16,
                                                    paddingRight: 16,
                                                    borderTop: `1px solid ${colours[theme].border_muted}`,
                                                }}
                                            >
                                                <ButtonsStandardBottomRowMobile
                                                    removePadding
                                                    cancel={() => {
                                                        this.setState({
                                                            addedCriteria: [],
                                                        })
                                                    }}
                                                    submit={() => {
                                                        onSave(
                                                            currentSelectedCriteria
                                                        )
                                                        this.setState({
                                                            showDropdown: false,
                                                        })
                                                    }}
                                                    firstText="Clear"
                                                    confirmationtext="Apply"
                                                />
                                            </div>
                                        </FadedSlower>
                                    )}
                                </div>
                            </DropdownOptions>
                        </SlideDownWrapper>
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default FilterTimelineByMultipleWithSearchUIDesktop
