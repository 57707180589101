import React from 'react'
import DesktopDisplay from '../templates/displays/desktopDisplay'
import MobileDisplay from '../templates/displays/mobileDisplay'
import styled from 'styled-components'
import { device } from '../templates/displays/devices'
import ButtonAtom from '../atoms/Button/ButtonAtom'

type Props = {}

const FlexOneSubWrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20vh;
    @media ${device.mobile_and_ipad} {
        padding-left: 20px;
        padding-right: 20px;
    }
`

const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    width: 80vw;
    max-width: 676px;
    box-shadow: 0px 0px 40px 0 rgb(0 0 0 / 8%);
    border-radius: 16px;
    @media ${device.mobile_and_ipad} {
        align-items: center;
        width: 100%;
    }
`

const Title = styled.div`
    font-size: 32px;
    font-family: Lato-Semibold;
    color: #1a1a1a;

    @media ${device.smallest_laptops} {
        font-size: 24px;
    }
    @media ${device.mobile_and_ipad} {
        font-size: 18px;
        text-align: center;
    }
`

const Content = styled.div`
    font-size: 16px;
    font-family: Lato;
    color: #666666;
    @media ${device.mobile_and_ipad} {
        text-align: center;
    }
`

const SmallPadding = styled.div`
    padding-top: 16px;
`

const LargePadding = styled.div`
    padding-top: 24px;
`

class AccountDeletionConfirmed extends React.Component<Props, {}> {
    render() {
        // const { errorUser, errorreferrals, userLoggedIn } = this.props;

        return (
            <React.Fragment>
                <DesktopDisplay>
                    <React.Fragment>
                        <FlexOneSubWrapper>
                            <Title style={{ fontSize: 42 }}>
                                We’re sad to see you leave
                            </Title>
                            <div style={{ paddingTop: 56 }} />
                            <ModalWrapper>
                                <Title>Your account was deleted</Title>
                                <SmallPadding />
                                <Content>
                                    We have now permanently deleted your user
                                    account and all it’s data. You are always
                                    welcome to join Custodian again!
                                </Content>
                                <LargePadding />
                                <ButtonAtom
                                    theme="naked-text"
                                    width="fit-content"
                                    height="48px"
                                    textTransform="none"
                                    fontSize={16}
                                    onClick={() => {}}
                                >
                                    <a
                                        href="https://www.custodian.club"
                                        rel="noreferrer"
                                        style={{ color: 'inherit' }}
                                    >
                                        Got to home page
                                    </a>
                                </ButtonAtom>
                            </ModalWrapper>
                        </FlexOneSubWrapper>
                    </React.Fragment>
                </DesktopDisplay>
                <MobileDisplay>
                    <FlexOneSubWrapper>
                        <Title style={{ fontSize: 24 }}>
                            We’re sad to see you leave
                        </Title>
                        <div style={{ paddingTop: 32 }} />
                        <ModalWrapper>
                            <Title>Your account was deleted</Title>
                            <SmallPadding />
                            <Content>
                                We have now permanently deleted your user
                                account and all it’s data. You are always
                                welcome to join Custodian again!
                            </Content>
                            <LargePadding />
                            <ButtonAtom
                                theme="naked-text"
                                width="fit-content"
                                height="48px"
                                textTransform="none"
                                fontSize={16}
                                onClick={() => {}}
                            >
                                <a
                                    href="https://www.custodian.club"
                                    rel="noreferrer"
                                    style={{ color: 'inherit' }}
                                >
                                    Got to home page
                                </a>
                            </ButtonAtom>
                        </ModalWrapper>
                    </FlexOneSubWrapper>
                </MobileDisplay>
            </React.Fragment>
        )
    }
}

export default AccountDeletionConfirmed
