import React, { useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import CarGalleryManagerDesktop from '../../../organisms/galleryManager/carGalleryManagerDesktop'
import CarGalleryManagerMobile from '../../../organisms/galleryManager/carGalleryManagerMobile'
import SideSlider from '../../../templates/animated/sideSlider'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import FabsMenusMobile from '../../../templates/menus/fabsMenuMobile'
import FullPageWrapper from '../../../templates/displays/pageWrappers/FullPagePageWrapper'
import PortalInvisibleWrapper from '../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import { ICar, IExternalCar, IGalleryImagesObject } from 'entityModels'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import ExternalCarGalleryImage from '../../../pages/car/externalCar/externalCarGalleryImage'
import { IUser } from 'myModels'
import { useAppSelector } from '../../../../redux/store/hooks'
import CarGalleryImageArchive from '../../../pages/archive/car/galleryImage'

const AdjustedPaddingTop = styled.div`
    padding-top: 45px;
    @media ${device.ipad} {
        padding-top: 73px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

interface Props {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    userLoggedIn: IUser
    hasPaddingTop?: boolean
}

const ReadOnlyCarGallery = (props: Props) => {
    let { sharedCar, prevOwned, hasPaddingTop } = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    let current: IExternalCar | ICar | undefined = sharedCar
        ? sharedCar
        : prevOwned

    let history = useHistory()
    const location: any = useLocation()
    let params = new URLSearchParams(location.search)

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let isNecesarryDataLoading: boolean = useAppSelector((state) => {
        return state.entities.galleriesData.loading
    })

    const checkDirection = () => {
        if (history !== undefined && history.location.state) {
            let historyState: any = { ...history.location.state }
            let linkPrefix = sharedCar
                ? '/shared'
                : prevOwned
                ? '/archives'
                : ''
            if (historyState) {
                if (
                    historyState.prevPath ===
                    `${linkPrefix}/car/gallery/image-id`
                ) {
                    return 'left'
                }
                if (historyState.prevPath === `${linkPrefix}/car`) {
                    return 'right'
                } else return ''
            } else return ''
        } else return ''
    }

    return (
        <React.Fragment>
            {!params.get('id') && (
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
            )}
            {current && !params.get('id') && (
                <React.Fragment>
                    <FullPageWrapper>
                        <DesktopDisplayOnly>
                            {hasPaddingTop && <AdjustedPaddingTop />}
                            {
                                <CarGalleryManagerDesktop
                                    openImgForm={() => {}}
                                    ids_list={current.gallery.images}
                                    readOnlyMode
                                    imagesData={images_data}
                                    isNecessaryGalleryDataLoading={
                                        isNecesarryDataLoading
                                    }
                                />
                            }
                        </DesktopDisplayOnly>

                        {params && (
                            <IpadAndMobileDisplay>
                                {hasPaddingTop && <AdjustedPaddingTop />}
                                <SideSlider
                                    direction={checkDirection()}
                                    initial="enter"
                                >
                                    <CarGalleryManagerMobile
                                        ids_list={current.gallery.images}
                                        readOnlyMode
                                        imagesData={images_data}
                                        isNecessaryGalleryDataLoading={
                                            isNecesarryDataLoading
                                        }
                                        isGettingCarDataLoading={
                                            isNecesarryDataLoading
                                        }
                                    />
                                </SideSlider>
                            </IpadAndMobileDisplay>
                        )}
                    </FullPageWrapper>
                </React.Fragment>
            )}
            {params.get('id') && (
                <PortalInvisibleWrapper
                    onClick={() => {
                        history.push(history.location.pathname)
                    }}
                >
                    {sharedCar ? (
                        <ExternalCarGalleryImage />
                    ) : (
                        <CarGalleryImageArchive />
                    )}
                </PortalInvisibleWrapper>
            )}
        </React.Fragment>
    )
}

export default ReadOnlyCarGallery
