import styled from 'styled-components'
import * as React from 'react'
import { ITabProps } from 'myInterfaces'
import BtnTab from '../../atoms/tab/btnTab'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

type ITabBtnRowProps = {
    tabs: ITabProps[]
    customPassiveColor?: string
}

const BtnTabRow: React.FunctionComponent<ITabBtnRowProps> = (
    props: ITabBtnRowProps
) => {
    return (
        <Container>
            {props.tabs.map((item: ITabProps, index: number) => {
                return (
                    <React.Fragment key={`btn_tab_${item.id}_${index}`}>
                        <BtnTab
                            {...item}
                            isLast={
                                index === props.tabs.length - 1 ? true : false
                            }
                            isFirst={index === 0 ? true : false}
                            isMiddle={
                                index === props.tabs.length - 1
                                    ? false
                                    : index === 0
                                    ? false
                                    : true
                            }
                            isDisabled={item.isDisabled}
                            disabledInfo={item.disabledInfo}
                            customPassiveColor={props.customPassiveColor}
                        />
                    </React.Fragment>
                )
            })}
        </Container>
    )
}

export default BtnTabRow
