import styled from 'styled-components'
import chevron_to_left from '../../../public/assets/navigation/chevron_to_left.svg'

type Props = {
    name: string
    value: string | undefined
    placeholder: string
    onClick: any
    isDisabled?: boolean
    customTextTransform?:
        | 'none'
        | 'capitalize'
        | 'uppercase'
        | 'lowercase'
        | 'full-width'
        | 'full-size-kana'
}

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    height: 64px;
`

const Column = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
`
const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(0%) contrast(84%);
    transform: rotate(180deg);
    height: 13px;
`

const Name = styled.div`
    letter-spacing: 0.0001em;
    color: #b3b3b3;
    font-family: Lato;
    font-size: 12px;
`

const Placeholder = styled.div`
    letter-spacing: 5e-5em;
    font-size: 16px;
    font-family: Lato;
`

const Value = styled.div`
    letter-spacing: 5e-5em;
    color: #1a1a1a;
    font-size: 16px;
    font-family: Lato-Bold;
`
const PickerBtnV2 = (props: Props) => {
    return (
        <Row role="button" onClick={props.onClick}>
            <Column>
                <Name>{props.name}</Name>
                <div style={{ paddingTop: '5px' }} />
                {props.value ? (
                    <Value
                        style={{
                            textTransform: props.customTextTransform
                                ? props.customTextTransform
                                : 'none',
                        }}
                    >
                        {props.value}
                    </Value>
                ) : (
                    <Placeholder
                        style={{
                            color: props.isDisabled ? '#B3B3B3' : '#666666;',
                        }}
                    >
                        {props.placeholder}
                    </Placeholder>
                )}
            </Column>

            <Chevron
                style={{ opacity: props.isDisabled ? 0.5 : 1 }}
                src={chevron_to_left}
            />
        </Row>
    )
}

export default PickerBtnV2
