import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
// import Loader from "../atoms/loader/loader";
import Faded from '../../templates/animated/faded'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import SectionHeader from '../../atoms/header/section/sectionHeader'

import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
// import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'
// import account_default from '../../../public/assets/navigation/account_default.svg'
// import ReturnNav from '../../atoms/header/returnNav'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import ios_share from '../../../public/assets/icons/ios_share.svg'
import browser_menu from '../../../public/assets/icons/browser_menu.svg'

import pwa_help_step1_ios from '../../../public/assets/images/pwa_help/PWA_help_step1_ios.png'
import pwa_help_step2_ios from '../../../public/assets/images/pwa_help/PWA_help_step2_ios.png'
import pwa_help_step3_ios from '../../../public/assets/images/pwa_help/PWA_help_step3_ios.png'
import pwa_help_step4_ios from '../../../public/assets/images/pwa_help/PWA_help_step4_ios.png'

import pwa_help_step1_android from '../../../public/assets/images/pwa_help/PWA_help_step1_android.png'
import pwa_help_step2_android from '../../../public/assets/images/pwa_help/PWA_help_step2_android.png'
import pwa_help_step3_android from '../../../public/assets/images/pwa_help/PWA_help_step3_android.png'
import pwa_help_step4_android from '../../../public/assets/images/pwa_help/PWA_help_step4_android.png'

import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { checkIfIpad } from '../../templates/pwaCustom/checkIpadPwa'

// const AccountIcon = styled(Link)`
//     position: fixed;
//     top:0;
//     right: 10px;
//     padding-top: 12px;
//     height: 50px;
//     z-index: 5;
// `

// const IconItem = styled.img`
//     width: 26px;
//     height: 26px;
//     margin-right: 10px;
//     @media ${device.desktop} {
//         display: none;
//     }
// `

const ImagesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

const Image = styled.img`
    width: 40%;
    height: 100%;
    margin-bottom: 20px;
    @media ${device.desktop} {
        display: none;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
`

const Text = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-Light';
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
`

const ShareIcon = styled.img`
    height: 25px;
    transform: translateY(-3px);
`

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
    }
}

type State = {
    // displayIosInstructions: boolean
}

interface Props extends RouteComponentProps<any> {
    setInitialLandingUrl: (str: string) => void
    userLoggedIn: null | IUser
    getUserDataRequest: () => void
    userid: string | null
}

class InstallApp extends React.Component<Props, State> {
    state = {
        // displayIosInstructions: false,
    }

    componentDidMount() {
        this.props.setInitialLandingUrl('/account/install-app')

        // let userData = this.props.userLoggedIn
        // if (!userData) {
        //     this.props.getUserDataRequest()
        // }
    }

    render() {
        const { userLoggedIn } = this.props

        const ua = window.navigator.userAgent
        const isIPad = checkIfIpad()
        const isIPhone = !!ua.match(/iPhone/i)
        const isIOS = isIPad || isIPhone

        // const params = new URLSearchParams(this.props.location.search)

        return (
            <CenteredPageWrapper hasMobileSubNav>
                {userLoggedIn &&
                    (isIOS ? (
                        <Faded>
                            <Loader />
                            <DesktopDisplayOnly>
                                <div
                                    style={{
                                        paddingLeft: '50px',
                                        paddingRight: '50px',
                                    }}
                                >
                                    <SectionHeader
                                        title={'Install app'}
                                        removeEdit={true}
                                    />
                                    {/* install app component for desktop here */}
                                </div>
                            </DesktopDisplayOnly>

                            <IpadAndMobileDisplay>
                                {/* <Link
                                to={{
                                    pathname: `/account`,
                                    state: {
                                        prevPath:
                                            this.props.match.url,
                                    },
                                }}
                            >
                                <ReturnNav 
                                    customText='Install on iphone'
                                    customTextColor='#0c0c0c'
                                    isUppercase={true}
                                    chevron={true}
                                />                                            
                            </Link>
                            <AccountIcon to="/account">                                                                    
                                <IconItem src={account_default} alt="account" />                                
                            </AccountIcon> */}
                                {/* <MobileSettingsSectionHeader id="install-app-mobile" title="INSTALL APP" />
                            <div style={{ height: '20px' }} /> */}
                                <div style={{ height: '30px' }} />
                                <TextContainer>
                                    <Text>
                                        Download this app on your iPhone or
                                        iPad:{' '}
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            tap
                                            <span
                                                style={{ paddingLeft: '5px' }}
                                            >
                                                <ShareIcon
                                                    src={ios_share}
                                                    alt="share"
                                                />
                                            </span>
                                        </span>
                                        <span
                                            style={{ paddingLeft: '5px' }}
                                        ></span>
                                        and then select{' '}
                                        <span
                                            style={{
                                                fontFamily: 'Lato-bold',
                                            }}
                                        >
                                            Add to Home Screen
                                        </span>
                                    </Text>
                                </TextContainer>
                                <ImagesContainer>
                                    <Image
                                        src={pwa_help_step1_ios}
                                        alt="pwa_help_step1"
                                    />
                                    <Image
                                        src={pwa_help_step2_ios}
                                        alt="pwa_help_step2"
                                    />
                                    <Image
                                        src={pwa_help_step3_ios}
                                        alt="pwa_help_step3"
                                    />
                                    <Image
                                        src={pwa_help_step4_ios}
                                        alt="pwa_help_step4"
                                    />
                                </ImagesContainer>
                                <div style={{ height: '100px' }} />
                            </IpadAndMobileDisplay>
                        </Faded>
                    ) : (
                        // images to be added
                        <Faded>
                            <Loader />
                            <DesktopDisplayOnly>
                                <div
                                    style={{
                                        paddingLeft: '50px',
                                        paddingRight: '50px',
                                    }}
                                >
                                    <SectionHeader
                                        title={'Install app'}
                                        removeEdit={true}
                                    />
                                    {/* install app component for desktop here */}
                                </div>
                            </DesktopDisplayOnly>

                            <IpadAndMobileDisplay>
                                {/* <Link
                                to={{
                                    pathname: `/account`,
                                    state: {
                                        prevPath:
                                            this.props.match.url,
                                    },
                                }}
                            >
                                <ReturnNav 
                                    customText='Install on android'
                                    customTextColor='#0c0c0c'
                                    isUppercase={true}
                                    chevron={true}
                                />                                            
                            </Link>
                            <AccountIcon to="/account">                                                                    
                                <IconItem src={account_default} alt="account" />                                
                            </AccountIcon> */}
                                {/* <MobileSettingsSectionHeader id="install-app-mobile" title="INSTALL APP" />
                            <div style={{ height: '20px' }} /> */}
                                <div style={{ height: '30px' }} />
                                <TextContainer>
                                    <Text>
                                        To install this web-app to your
                                        homescreen open the browser option{' '}
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            menu:
                                            <span
                                                style={{
                                                    paddingLeft: '5px',
                                                    paddingRight: '5px',
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        height: '25px',
                                                    }}
                                                    src={browser_menu}
                                                    alt="browser menu"
                                                />
                                            </span>
                                            and{' '}
                                        </span>
                                        tap on{`  `}
                                        <span style={{ fontWeight: 'bold' }}>
                                            Add to home screen
                                        </span>{' '}
                                        or{' '}
                                        <span style={{ fontWeight: 'bold' }}>
                                            Install app
                                        </span>
                                        .
                                    </Text>
                                </TextContainer>
                                <ImagesContainer>
                                    <Image
                                        src={pwa_help_step1_android}
                                        alt="pwa_help_step1"
                                    />
                                    <Image
                                        src={pwa_help_step2_android}
                                        alt="pwa_help_step2"
                                    />
                                    <Image
                                        src={pwa_help_step3_android}
                                        alt="pwa_help_step3"
                                    />
                                    <Image
                                        src={pwa_help_step4_android}
                                        alt="pwa_help_step4"
                                    />
                                </ImagesContainer>
                                <div style={{ height: '100px' }} />
                            </IpadAndMobileDisplay>
                        </Faded>
                    ))}
            </CenteredPageWrapper>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(InstallApp))
