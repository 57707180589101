import { ITimelineEditForms } from 'myModels'
import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";

import { connect } from 'react-redux'
import {
    ICostItem,
    ITimelineFieldsIdsList,
    ITimelineItem,
} from 'timelineModels'

import { RootState } from 'typesafe-actions'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'

import { TimelineFormTypeIDsEnum } from '../../../../redux/timeline/timelineEnum'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'

import EditModeTopHeaderMobile from '../../../atoms/header/editModeTopHeader/editModeTopHeaderMobile'

import { INormalisedAttachmentsByID } from 'attachmentModels'
import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'
import LabelsFilterByMultipleWSearchWFuseMobile from '../../filterByMultiple/labels/labelsFilterByMultipleWSearchWFuseMobile'
import { IDropdownItem, ITechnicalInformationObject } from 'entityModels'
import { device } from '../../../templates/displays/devices'
import { RouteComponentProps, withRouter } from 'react-router'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import { getCarDataByIdRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import styled from 'styled-components'
import InputField from '../../../atoms/Inputfield/inputField'
import CalendarIcon from '../../../atoms/icons/components/calendar'
import dayjs from 'dayjs'
import { ITheme } from '../../../../providers/theme/colours'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'

const Wrapper = styled.div`
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
    min-height: 90vh;
    background-color: var(--bg-color, #fff);
    top: 50px;
    left: 0px;
    z-index: 9;
    position: absolute;
    width: 100%;
    overflow-y: scroll;
    /* height: 95vh; */
    @media ${device.ipad} {
        padding-top: 50px;
        padding-right: 50px;
    }
`

const InputWraper = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;

    @media ${device.ipad} {
        padding-left: 25px;
    }
`
function mapStateToProps(state: RootState) {
    return {
        fieldsList: state.timeline.formsData.fieldsList,
        attachmentsObj: state.attachments.attachmentsById,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
        technicalInformationData:
            state.entities.technicalInformationData.technical_information,
        userDistanceUnit:
            state.user.userLoggedIn?.preferences.data.units.data.distance_unit
                .user_choice_id,
        dataLoading: state.timeline.loading,
        deletionInProgress: state.timeline.deletionInProgress,
    }
}

const dispatchProps = {
    getCarDataByIdRequest: (id: string) => getCarDataByIdRequest(id),
}

interface Props extends RouteComponentProps<any> {
    item: ITimelineItem | ICostItem
    edited_item: ITimelineItem | ICostItem | null
    timelineItemEditForm: ITimelineEditForms
    fieldsList: ITimelineFieldsIdsList
    attachmentsObj: INormalisedAttachmentsByID
    labels_searchable_list?: IDropdownItem[] | null | undefined
    onClickSave: () => void
    onDeleteClick?: () => void
    onChange: (
        fieldID: keyof ITimelineItem | keyof ICostItem,
        newValue: any
    ) => void
    closeForm: () => void
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    userCurrency: string | undefined
    technicalInformationData?: ITechnicalInformationObject
    userDistanceUnit: string | undefined | null
    updateCarMileage?: (payload: {
        updateCarMileage: boolean
        value: number
        user_distance_unit: string
    }) => void
    getCarDataByIdRequest: (id: string) => void
    dataLoading: boolean
    deletionInProgress: boolean
}

class AnyObj {
    [key: string]: any
}

type State = {
    isHeaderActive: boolean
    errorObject: AnyObj
}

type IisMandatoryFieldObj = {
    [key: string]: boolean
}

class TimelineGenerateEditFormModeMobile extends React.PureComponent<
    Props,
    State
> {
    state = {
        isHeaderActive: false,
        // we create an obj with fieldIDs as key and whether rn there's an error ( true | false)
        errorObject: new AnyObj(),
    }

    componentDidMount() {
        window.scrollTo(0, 0)

        let carid = this.props.match.params.carid
        let tech_info_in_state =
            this.props.technicalInformationData &&
            this.props.technicalInformationData[`hf-${carid}`]
        if (!tech_info_in_state) {
            this.props.getCarDataByIdRequest(carid)
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.edited_item !== prevProps.edited_item) {
            this.checkIfDataHasBeenChanged()
        }
    }

    checkIfDataHasBeenChanged = (): boolean => {
        let obj1 = this.props.item
        let obj2 = this.props.edited_item

        if (obj2 == null) {
            return false
        } else {
            const obj1Length = Object.keys(obj1).length
            const obj2Length = Object.keys(obj2).length

            if (obj1Length === obj2Length) {
                let n = Object.keys(obj1).every(
                    (key) =>
                        obj2 !== null &&
                        obj2.hasOwnProperty(key) &&
                        obj2[key] === obj1[key]
                )

                this.setState({
                    ...this.state,
                    isHeaderActive: !n,
                })
                return !n
            }
            this.setState({
                ...this.state,
                isHeaderActive: true,
            })

            return true
        }
    }

    generatePlaceholder = (fieldId: string): string => {
        let { item } = this.props

        if (
            timeline_category_data[item.categoryID] &&
            timeline_category_data[item.categoryID].placeholders &&
            timeline_category_data[item.categoryID].placeholders
        ) {
            let placeholders =
                timeline_category_data[item.categoryID].placeholders

            return placeholders ? placeholders[fieldId] : ''
        } else return ''
    }

    determineErrors = (fieldId: string, error: boolean) => {
        const errorObject: any = this.state.errorObject
        errorObject[fieldId] = error
        this.setState({ ...this.state, errorObject: errorObject })
    }

    isMandatoryFieldObj: IisMandatoryFieldObj = {
        name: true,
        title: true,
        amount: true,
    }

    generateFields = (fieldId: string, theme: ITheme) => {
        let {
            item,
            onChange,
            attachmentsObj,
            technicalInformationData,
            userDistanceUnit,
            updateCarMileage,
        } = this.props
        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start:
                fieldId === 'attachment' ||
                fieldId === 'attachment_with_preview'
                    ? attachmentsObj[item.attachmentID]
                        ? attachmentsObj[item.attachmentID]
                        : undefined
                    : fieldId === 'labels'
                    ? undefined
                    : item[fieldId],
            title: `${fieldId}`,
            onChangeFunc: onChange,
            id: fieldId,
            placeholder: this.generatePlaceholder(fieldId),
            isMandatory: this.isMandatoryFieldObj[fieldId]
                ? this.isMandatoryFieldObj[fieldId]
                : false,
        }

        let hasError = (error: boolean) => {
            this.determineErrors(fieldId, error)
        }
        let carid = this.props.match.params.carid

        let carMileageFromState: number | string | undefined | null =
            carid &&
            technicalInformationData &&
            technicalInformationData[`hf-${carid}`] &&
            technicalInformationData[`hf-${carid}`].mileage &&
            technicalInformationData[`hf-${carid}`].mileage.answer

        let carMileage: number | undefined | null =
            typeof carMileageFromState === 'string'
                ? parseInt(carMileageFromState)
                : carMileageFromState

        let convertedCarMileage =
            carMileage !== undefined && carMileage !== null && userDistanceUnit
                ? unitGenerator.generateFrontEndValueDistanceUnit(
                      userDistanceUnit,
                      carMileage
                  )
                : undefined

        switch (fieldId) {
            case 'title': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'name': {
                data.charLimit = [2, 30]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.charLimit = [2, 100]
                data.isMandatory = true
                data.title = 'Insurer'
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_broker': {
                data.title = 'Broker (if applicable)'
                data.charLimit = [2, 100]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'company': {
                data.charLimit = [0, 70]
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'mileage': {
                data.charLimit = [0, 70]
                data.title = 'Mileage at the time of entry'
                return (
                    <FieldChoices.MileageEditOrCreateModeMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_entry_${fieldId}_field`}
                        customMileageDataCyId={
                            'edit_car_mileage_from_entry_with_custom_value'
                        }
                        hasError={hasError}
                        carMileage={convertedCarMileage}
                        userDistanceUnits={userDistanceUnit}
                        updateCarMileage={updateCarMileage}
                    />
                )
            }
            case 'amount': {
                data.optionName = item.currency
                    ? item.currency
                    : this.props.userCurrency
                    ? this.props.userCurrency
                    : 'GBP'
                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                    />
                )
            }

            case 'date':
                // input field type = date, name: DATE PICKER DatePickerEditModeSingleFieldMobile
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                    />
                )

            case 'description':
                data.charLimit = [0, 450]
                // textarea, name : TEXT AREA TextAreaEditModeSingleFieldMobile
                return (
                    <FieldChoices.TextareaEditOrCreateModeSingleFieldMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        placeholder={data.placeholder}
                        hasError={hasError}
                    />
                )

            case 'attachment':
                // later
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                    />
                )

            case 'attachment_with_preview':
                // later
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        data={data}
                        key={`${fieldId}+${item.id}`}
                        dataCyId={`edit_${fieldId}_field`}
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                    />
                )

            case 'notes':
                // later
                return (
                    <FieldChoices.EntryNotesFormMobile
                        key={`${fieldId}+${item.id}`}
                        data={data}
                        dataCyId={`edit_${fieldId}_field`}
                    />
                )
            case 'labels':
                // later

                return (
                    <LabelsFilterByMultipleWSearchWFuseMobile
                        placeholder="Search for title or tag"
                        labels_searchable_list={
                            this.props.labels_searchable_list &&
                            this.props.labels_searchable_list.length > 0
                                ? this.props.labels_searchable_list.concat(
                                      this.generatePrePopulatedLabels()
                                  )
                                : this.generatePrePopulatedLabels()
                        }
                        key={`${fieldId}+${item.id}`}
                        editData={data}
                        addToEntry={onChange}
                    />
                )

            case 'insurance_entry_policy_start_date':
                data.title = 'Policy start date'
                // input field type = date, name: DATE PICKER DatePickerEditModeSingleFieldMobile

                return (
                    <InputWraper>
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_start_date"
                            name="Policy start date"
                            placeholder={'Policy start date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            // helperText="Type or select a date (yyyy/mm/dd)"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={
                                this.props.edited_item
                                    ?.insurance_entry_policy_start_date
                            }
                            value={
                                this.props.edited_item
                                    ?.insurance_entry_policy_start_date
                            }
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                this.props.onChange(
                                    'insurance_entry_policy_start_date',
                                    dateString
                                )

                                setTimeout(() => {
                                    let newavall = dayjs(
                                        new Date(dateString)
                                    ).isValid()
                                        ? dayjs(new Date(dateString)).add(
                                              1,
                                              'year'
                                          )
                                        : null
                                    this.props.onChange(
                                        'insurance_entry_policy_end_date',
                                        newavall
                                    )
                                }, 50)
                            }}
                            type="date"
                            // width="100%"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </InputWraper>
                )

            case 'insurance_entry_policy_end_date':
                // input field type = date, name: DATE PICKER DatePickerEditModeSingleFieldMobile

                return (
                    <InputWraper>
                        <InputField
                            theme={theme}
                            disabled={false}
                            id="insurance_entry_policy_end_date"
                            name="'Policy end date'"
                            placeholder={'Policy end date'}
                            customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                            // helperText="Type or select a date (yyyy/mm/dd)"
                            tabindex={1}
                            aria-live="polite"
                            defaultValue={
                                this.props.edited_item
                                    ?.insurance_entry_policy_end_date
                            }
                            value={
                                this.props.edited_item
                                    ?.insurance_entry_policy_end_date
                            }
                            font_family="Lato"
                            restrictFlipOfDatePopUp
                            onChange={(dateString: any) => {
                                this.props.onChange(
                                    'insurance_entry_policy_end_date',
                                    dateString
                                )
                            }}
                            type="date"
                            // width="100%"
                            fontSize="16px"
                            paddingLeft="0px"
                            backgroundColor={'var(--bg-color, #ffffff)'}
                            // dataCyId={props.dataCyId}
                            rightAdornment={<CalendarIcon />}
                        />
                    </InputWraper>
                )

            default:
                return null
        }
    }

    generatePrePopulatedLabels = (): IDropdownItem[] => {
        let { edited_item, labels_searchable_list } = this.props

        let labels_searchable_list_names = labels_searchable_list?.map(
            (label) => label.name
        )

        if (edited_item && edited_item.categoryID) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                edited_item.categoryID
            ].pre_populated_labels
                .filter(
                    (label) => !labels_searchable_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    render() {
        let {
            item,
            fieldsList,
            timelineItemEditForm,
            onClickSave,
            closeForm,
            dataCyId,
            onDeleteClick,
            dataLoading,
            deletionInProgress,
        } = this.props

        let field_ids_general_form: undefined | string[] =
            timelineItemEditForm.id &&
            timelineItemEditForm.id === TimelineFormTypeIDsEnum.general &&
            item &&
            fieldsList &&
            timelineItemEditForm.category
                ? fieldsList.fieldsListByCategory[
                      timelineItemEditForm.category
                  ].map((fieldID: string, index: number) => {
                      return fieldID
                  })
                : undefined

        let field_ids_form: undefined | string[] =
            timelineItemEditForm.id &&
            timelineItemEditForm.id !== TimelineFormTypeIDsEnum.general &&
            item &&
            fieldsList &&
            fieldsList.fieldsListByType[timelineItemEditForm.id]
                ? fieldsList.fieldsListByType[timelineItemEditForm.id].map(
                      (fieldID: string, index: number) => {
                          return fieldID
                      }
                  )
                : undefined

        // console.log('field_ids_form', field_ids_form)

        const checkIfSaveIsDisabled = () => {
            let arr: undefined | string[] = field_ids_general_form
                ? field_ids_general_form
                : field_ids_form
                ? field_ids_form
                : undefined

            if (arr) {
                for (const id of arr) {
                    let str = id as keyof AnyObj

                    let form_field_data =
                        this.props.edited_item && this.props.edited_item[id]
                            ? this.props.edited_item[id]
                            : undefined

                    if (this.state.errorObject[str] === true) {
                        // error is true

                        return true
                    }

                    if (
                        form_field_data &&
                        form_field_data.length === 0 &&
                        this.state.errorObject[str] === undefined &&
                        this.isMandatoryFieldObj[id] === true
                    ) {
                        // mandatory pb

                        return true
                    }

                    if (
                        !form_field_data &&
                        this.isMandatoryFieldObj[id] === true
                    ) {
                        // mandatory pb

                        return true
                    }
                }

                //should be nope
                return false
            }

            return false
        }

        return (
            <ThemesContext.Consumer>
                {({ theme: theme }) => (
                    <>
                        {/* header is here */}
                        {/* header currently in wrapper actually here as atom
                EditModeTopHeaderMobile with props for whether edit mode =
                active (by passing isActive= checkIfDataHasBeenChanged()) + name
                property of timelineItemEditForm props, onClickSave on clicksave */}

                        <EditModeTopHeaderMobile
                            isActive={this.state.isHeaderActive}
                            editedItemId={timelineItemEditForm.id}
                            onChevronClick={closeForm}
                            onCheckedClick={() => {
                                onClickSave()
                                this.state.isHeaderActive &&
                                    this.setState({ isHeaderActive: false })
                            }}
                            onDeleteClick={onDeleteClick}
                            isSaveDisabled={checkIfSaveIsDisabled()}
                            isDataLoading={dataLoading}
                            deletionInProgress={deletionInProgress}
                        />
                        <Wrapper data-attr={dataCyId}>
                            {/* padding left and right wrapper */}
                            {/* general category item form is below is here */}
                            {field_ids_general_form &&
                                field_ids_general_form.map(
                                    (fieldID: string, index: number) => {
                                        return this.generateFields(
                                            fieldID,
                                            theme
                                        )
                                    }
                                )}
                            {/* example : cost form (as of now) */}

                            {field_ids_form &&
                                field_ids_form.map(
                                    (fieldID: string, index: number) => {
                                        return this.generateFields(
                                            fieldID,
                                            theme
                                        )
                                    }
                                )}
                        </Wrapper>
                        <div />
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TimelineGenerateEditFormModeMobile)
)

// SelectSearchBarCarInfoDesktop
