import { Bar } from 'react-chartjs-2'
import { numberWithCommas } from '../../../../helpers/currencyConverter'

export type IChartDoughnut = {
    labels: string[]
    datasets: {
        label?: string
        data: number[]
        backgroundColor?: string[]
        borderColor?: string
        borderWidth?: number
        [key: string]: any
    }[]
}

export const monthsToMap: any = {
    January: {
        id: 'January',
        name: 'Jan',
    },
    February: {
        id: 'February',
        name: 'Feb',
    },
    March: {
        id: 'March',
        name: 'March',
    },
    April: {
        id: 'April',
        name: 'Apr',
    },
    May: {
        id: 'May',
        name: 'May',
    },
    June: {
        id: 'June',
        name: 'June',
    },
    July: {
        id: 'July',
        name: 'July',
    },
    August: {
        id: 'August',
        name: 'Aug',
    },
    October: {
        id: 'October',
        name: 'Oct',
    },
    November: {
        id: 'November',
        name: 'Nov',
    },
    December: {
        id: 'December',
        name: 'Dec',
    },
}

type Props = {
    chartData: IChartDoughnut
    currency: string
}

const BarChart = (props: Props) => {
    let { chartData } = props

    // console.log('chartData', chartData)
    return (
        <Bar
            data={chartData}
            options={{
                plugins: {
                    tooltip: {
                        bodyFont: { family: 'Lato' },
                        callbacks: {
                            label: function (value) {
                                return `${
                                    props.currency +
                                    numberWithCommas(
                                        +value.formattedValue?.replace(
                                            /\,/g,
                                            ''
                                        )
                                    )
                                }`
                            },
                        },
                    },

                    title: {
                        display: false,
                    },
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            font: { family: 'Lato', weight: 200, size: 11 },
                            callback: function (value, index, ticks) {
                                let indexInChartDataVal =
                                    chartData.labels?.[index]
                                if (
                                    monthsToMap[indexInChartDataVal] &&
                                    monthsToMap[indexInChartDataVal]?.name
                                ) {
                                    return monthsToMap[indexInChartDataVal]
                                        ?.name
                                } else return indexInChartDataVal
                            },
                        },
                    },
                    y: {
                        ticks: {
                            font: { family: 'Lato' },
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return props.currency + numberWithCommas(+value)
                            },
                        },
                    },
                },
            }}
        />
    )
}

export default BarChart
