import { UserCategory } from "myInterfaces";
import collectorstype_landscape from "../../../public/assets/images/usercategories/collectorstype_landscape.png";
import collectorstype_portrait from "../../../public/assets/images/usercategories/collectorstype_portrait.png";
import privatecommunity_portrait from "../../../public/assets/images/usercategories/privatecommunity_portrait.jpg";
import privatecommunity_landscape from "../../../public/assets/images/usercategories/privatecommunity_landscape.png";
import marketplace_landscape from "../../../public/assets/images/usercategories/marketplace_landscape.png";
import marketplace_portrait from "../../../public/assets/images/usercategories/marketplace_portrait.png";
import projectmanagement_landscape from "../../../public/assets/images/usercategories/projectmanagement_landscape.png";
import projectmanagement_portrait from "../../../public/assets/images/usercategories/projectmanagement_portrait.png";

const usercategorieslist: UserCategory[] = [
  {
    category: "collection Management",
    categoryid: "collectors",
    imgurl: {
      landscape: collectorstype_landscape,
      portrait: collectorstype_portrait
    },
    description: [
      "Digitisation of vehicle history",
      "Service & maintenance logs",
      "Integrated brokerage of services",
      "Key date reminders (MOT / tax)",
      "Community event calendar"
    ],
    imageCredits: {
      title: "Race 456 - McLaren 950b",
      description:
        "XYZ location George Peterson setting off at GoodWood 2019 before his win",
      credits: "(@CharlieB Photography 2019)"
    }
  },
  {
    category: "Marketplace",
    categoryid: "dealers",
    imgurl: {
      landscape: marketplace_landscape,
      portrait: marketplace_portrait
    },
    description: [
      "Free for buyers and sellers to transact",
      "Exceptional design & functionality",
      "Powerful search tools",
      "Helpful engagement analytics",
      "Live newsfeed of latest cars on market"
    ],
    imageCredits: {
      title: "Race 456 - McLaren 950b",
      description:
        "XYZ location George Peterson setting off at GoodWood 2019 before his win",
      credits: "(@CharlieB Photography 2019)"
    }
  },
  {
    category: "Private Community",
    categoryid: "serviceproviders",
    imgurl: {
      landscape: privatecommunity_landscape,
      portrait: privatecommunity_portrait
    },
    description: [
      "Carefully curated membership policy",
      "Integrated and private social network",
      "Messaging service within community",
      "A safe place for like-minded enthusiasts",
      "Strict club rules limit bad actors"
    ],
    imageCredits: {
      title: "Race 456 - McLaren 950b",
      description:
        "XYZ location George Peterson setting off at GoodWood 2019 before his win",
      credits: "(@CharlieB Photography 2019)"
    }
  },
  {
    category: "Project Management",
    categoryid: "contentcreators",
    imgurl: {
      landscape: projectmanagement_landscape,
      portrait: projectmanagement_portrait
    },
    description: [
      "Document car related activity:",
      "i.e. restoration work, events & history",
      "Find service providers & content creators",
      "Collaborate on projects",
      "Build portfolio & an online legacy"
    ],
    imageCredits: {
      title: "Race 456 - McLaren 950b",
      description:
        "XYZ location George Peterson setting off at GoodWood 2019 before his win",
      credits: "(@CharlieB Photography 2019)"
    }
  }
];

export default usercategorieslist;
