import { IJourneySellYourCarStepID } from 'journeyInterfaces'
import { IUser } from 'myModels'
import { useHistory } from 'react-router'
import HandoversSellYourCarJourneyStep from '../handovers'
import SellYourCarIncreaseValueStep from '../increaseValueStep'
import SharingSellYourCarJourneyStep from '../sharing'
import JourneySellYourCarWelcome from '../welcome'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'

type Props = {
    stepID: IJourneySellYourCarStepID
    startJourney: () => void
    exitJourney: () => void
    user: IUser | null
}
export default function SellYourCarJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    user,
}: Props) {
    const history = useHistory()
    let locationState: any = history.location.state

    switch (stepID) {
        case 'welcome':
            return (
                <JourneySellYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )

        case 'increase_value':
            return (
                <SellYourCarIncreaseValueStep
                    step={1}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/sell-your-car?step=welcome`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/sell-your-car`,
                            search: `?step=sharing`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'sharing':
            return (
                <SharingSellYourCarJourneyStep
                    step={2}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/sell-your-car?step=increase_value`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/sell-your-car`,
                            search: `?step=handovers`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'handovers':
            return (
                <HandoversSellYourCarJourneyStep
                    step={3}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/sell-your-car?step=handovers`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/sell-your-car`,
                            search: `?step=achieved`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )
        case 'achieved':
            return <JourneyAchievedStep sectionID="sell_your_car" />

        default:
            return (
                <JourneySellYourCarWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
