import { motion } from 'framer-motion'

type Props = {
    direction?: 'left' | 'right'
    size?: string
}

const GalleryImChevron = (props: Props) => {
    return (
        <div
            style={{
                transform:
                    props.direction === 'left' ? 'rotate(180deg)' : 'none',
            }}
        >
            <motion.div whileTap={{ scale: 1.2 }}>
                <svg
                    width={props.size ?? '48'}
                    height={props.size ?? '48'}
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M29.8182 24.0002L13.4092 7.59116L16.5912 4.40918L36.1821 24.0002L16.5912 43.5912L13.4092 40.4092L29.8182 24.0002Z"
                        fill="#CCCCCC"
                    />
                </svg>
            </motion.div>
        </div>
    )
}

export default GalleryImChevron
