import { FunctionComponent, useState, useRef, useMemo } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import rotate_circle from '../../../public/assets/icons/rotate_circle_gradient.svg'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import './stylesDesktop.css'
import styled from 'styled-components'
import { motion } from 'framer-motion'
// import { device } from '../../templates/displays/devices'
import Ellipsis from '../../atoms/loader/ellipsis'

type Props = {
    currentImagesUploaded?: any
    close?: any
    handleFile: (file: File | undefined) => void
    aspect?: number
}

const FormBtnsBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    opacity: 0.95;
    padding-top: 10px;
`

type StyleProps = {
    hasLoaded?: boolean
}

const CropperWrapper = styled.div<StyleProps>`
    width: 80%;
    visibility: ${(props) => (props.hasLoaded ? 'visible' : 'hidden')};
    position: ${(props) => (props.hasLoaded ? 'static' : 'relative')};
    transform: translateY(-20px);
    z-index: 1001;
`

const CropperCustom = styled(Cropper)``

const AbsoluteDivLoader = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: black;
    height: 600px;
    width: 70vw;
    top: 12vh;
    padding-bottom: 50px;
    padding-top: 70px;
`
const Rotate = styled.img`
    /* position: absolute;
    bottom: 21vh; */
    height: 80px;
    /* right: 20px; */
`

// const gallery_car_ex =
//   "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";

const ImageCropperDesktop2: FunctionComponent<Props> = (props: any) => {
    const [hasSaveBeenClicked, setHasSaveBeenClicked] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)

    setTimeout(function () {
        setHasLoaded(true)
    }, 3000)

    const cropperRef: React.Ref<ReactCropperElement> | undefined =
        useRef<ReactCropperElement>(null)
    type IBlobFetchRes = File | undefined

    const convertToBlob = async (
        url: string,
        fileTitle: string
    ): Promise<IBlobFetchRes> => {
        let res: IBlobFetchRes = await fetch(url)
            .then((res) => res.blob()) // Gets the response and returns it as a blob
            .then((blob) => {
                // Here's where you get access to the blob
                // And you can use it for whatever you want
                // Like calling ref().put(blob)

                let newfile: File = new File([blob], fileTitle, {
                    type: 'image/jpeg',
                })

                return newfile

                // Here, I use it to make an image appear on the page
            })
            .catch((e) => {
                return undefined
            })

        return res
    }
    const onCrop = async () => {}

    const onSubmit = async () => {
        const imageElement = cropperRef?.current
        const cropper = imageElement?.cropper

        let croppedCanvas = cropper && cropper.getCroppedCanvas()

        let d: string | undefined = croppedCanvas && croppedCanvas.toDataURL()
        try {
            let finalFile = d
                ? await convertToBlob(d, props.currentImagesUploaded.name)
                : undefined

            props.handleFile(finalFile)

            props.close()
        } catch (e) {
            // console.error(e)
        }

        // props.handleFile(finalFile)
    }

    let genUrl = () => URL.createObjectURL(props.currentImagesUploaded)

    let url = props.currentImagesUploaded
        ? useMemo(genUrl, [props.currentImagesUploaded])
        : ''

    const rotateImg = () => {
        const imageElement = cropperRef?.current
        const cropper = imageElement?.cropper

        cropper && cropper.rotate(90)
    }

    return (
        <AbsoluteDivLoader>
            {(hasSaveBeenClicked === true || hasLoaded !== true) && (
                <Ellipsis size={10} />
            )}

            <CropperWrapper
                hasLoaded={hasLoaded && !hasSaveBeenClicked ? true : false}
            >
                <CropperCustom
                    src={url}
                    style={{ height: 400, width: '100%' }}
                    // Cropper.js options
                    aspectRatio={props.aspect ? props.aspect : 3.2}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                    viewMode={1}
                    autoCropArea={1}
                    checkOrientation={true}
                    cropBoxResizable={false}
                    dragMode={'none'}
                />
            </CropperWrapper>

            <FormBtnsBottomRow>
                <motion.div whileTap={{ scale: 1.05 }}>
                    <ButtonAtom
                        theme="uppercase-white-background"
                        onClick={(e: any) => {
                            props.close()
                            props.handleFile(undefined)
                        }}
                        width="150px"
                        height="60px"
                        borderRadius={5}
                    >
                        go back
                    </ButtonAtom>
                </motion.div>
                <div style={{ paddingLeft: '2vw' }} />
                <motion.div whileTap={{ scale: 1.05 }}>
                    <ButtonAtom
                        theme="uppercase-blue-background"
                        onClick={(e: any) => {
                            setHasSaveBeenClicked(true)
                            onSubmit()
                        }}
                        disabled={
                            hasSaveBeenClicked === true || hasLoaded !== true
                                ? true
                                : false
                        }
                        width="150px"
                        height="60px"
                        borderRadius={5}
                    >
                        {hasSaveBeenClicked === true || hasLoaded !== true ? (
                            <div>Please Wait...</div>
                        ) : (
                            <div>save</div>
                        )}
                    </ButtonAtom>
                </motion.div>
                <div style={{ paddingLeft: '15vw' }} />{' '}
                <ButtonAtom theme="naked" onClick={rotateImg}>
                    <Rotate src={rotate_circle} alt="rotate" />
                </ButtonAtom>
            </FormBtnsBottomRow>
        </AbsoluteDivLoader>
    )
}

export default ImageCropperDesktop2
