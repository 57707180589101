import { Component } from 'react'
import Script from 'react-load-script'

const SDK_URL = 'https://cdn.nolt.io/widgets.js'
const BOARD_URL = 'https://custodian.nolt.io/'

declare const window: any

type Props = {
    children: React.ReactNode
    jwt: any
}

class NoltWidget extends Component<Props> {
    componentDidMount() {}
    handleScriptCreate() {
        console.log('script created')
    }

    handleScriptError() {
        console.log('script error')
    }

    handleScriptLoad() {
        const { nolt } = window

        // Single Sign-On
        nolt('identify', { jwt: this.props.jwt })
    }
    //data-nolt="modal"
    render() {
        return (
            <div style={{ width: '100%', height: '100%' }}>
                {this.props.jwt !== null && (
                    <div style={{ width: '100%', height: '100%' }}>
                        <a href={BOARD_URL} target="_blank">
                            {this.props.children}
                        </a>

                        <Script
                            url={SDK_URL}
                            onCreate={this.handleScriptCreate.bind(this)}
                            onError={this.handleScriptError.bind(this)}
                            onLoad={this.handleScriptLoad.bind(this)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default NoltWidget
