import React from 'react'
import { connect } from 'react-redux'
import CenteredPageWrapper from '../../../templates/displays/pageWrappers/CenteredPageWrapper'
import UserBanner from '../../../atoms/user/userBanner'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import ViewsMenu from '../../../atoms/menu/viewsMenu/viewsMenu'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import UserTagBox from '../../../atoms/user/userTagBox'
import MobileContentBody from '../../../templates/displays/pageWrappers/MobileContentBody'
import { Paragraph } from '../../../atoms/typography'
import ViewsMenuMobile from '../../../atoms/menu/viewsMenu/viewsMenuMobile'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import Loader from '../../../atoms/loader/loader'
import DesktopGarageViewManager from '../../../organisms/garageViewManager/desktopGarageViewManager'
import { IExternalGarage, IGalleryImagesObject, IGarage } from 'entityModels'
import MobileGarageViewManager from '../../../organisms/garageViewManager/mobileGarageViewManager'
import { IUser } from 'myModels'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import { setCurrentExternalGarage } from '../../../../redux/entities/garages/actions/thunks'
import { getExternalGaragesSharedWithYouREQUEST } from '../../../../redux/entities/garages/actions/loadingActions'

const GarageMenuContainer = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 42px;
        padding-bottom: 42px;
        width: 100%;
    }
`

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        external_garage_data:
            state.entities.garagesData.directly_shared_external_garages,
        isExternalGarageLoading: state.entities.garagesData.loading,
        isExternalCarLoading: state.entities.carsData.loading,
        isUserLoading: state.user.loading,
        images_data: state.entities.galleriesData.images,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentExternalGarage: (garage_id: string) =>
        setCurrentExternalGarage(garage_id),
    getExternalGaragesSharedWithYouREQUEST: (id: string) =>
        getExternalGaragesSharedWithYouREQUEST(id),
}

interface Props extends RouteComponentProps<any> {
    external_garage_data: any
    isExternalGarageLoading: any
    garage_id: string | null
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isExternalCarLoading: boolean
    getUserDataRequest: () => void
    setCurrentExternalGarage: (garage_id: string) => void
    images_data: IGalleryImagesObject | null
    getExternalGaragesSharedWithYouREQUEST: (id: string) => void
}

interface State {
    activeView: string
    current: null | IGarage
}

class ExternalGarage extends React.Component<Props, State> {
    state = {
        activeView: 'grid_view',
        current: null,
    }

    toggleMenu = (id: string) => {
        this.setState({ activeView: id })
    }

    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }
        const q_params = new URLSearchParams(this.props.location.search)
        let garageid = q_params.get('garageid')

        if (garageid) {
            this.props.getExternalGaragesSharedWithYouREQUEST(garageid)
            this.props.setCurrentExternalGarage(garageid)
        }
    }

    componentDidUpdate(prevProps: Props) {}

    render() {
        const { activeView } = this.state
        const {
            isExternalGarageLoading,
            isUserLoading,
            isExternalCarLoading,
            external_garage_data,
            userLoggedIn,
            images_data,
        } = this.props

        const q_params = new URLSearchParams(this.props.location.search)
        let garageid = q_params.get('garageid')

        let current: IExternalGarage | undefined =
            garageid && external_garage_data && external_garage_data[garageid]
                ? external_garage_data[garageid]
                : undefined

        let numberOfCarsInGarage: number =
            current && current.cars_list ? current.cars_list.length : 0

        let garageOwner = current && current.owner

        let accountInternalInfoList = garageOwner && {
            profile_image_url: garageOwner.profile_picture
                ? garageOwner.profile_picture.url
                : null,
            display_name:
                garageOwner && garageOwner.display_name
                    ? `${garageOwner.display_name}`
                    : 'Unknown',
            one_liner: garageOwner.bio ? garageOwner.bio : '',
            location: garageOwner.location ? garageOwner.location : 'Unknown',
        }

        return (
            <React.Fragment>
                <CenteredPageWrapper>
                    {
                        <Loader
                            isLoading={
                                isExternalGarageLoading ||
                                isUserLoading ||
                                isExternalCarLoading
                            }
                        />
                    }

                    {current !== null &&
                        current !== undefined &&
                        garageid &&
                        userLoggedIn &&
                        accountInternalInfoList && (
                            <React.Fragment>
                                <DesktopDisplayOnly>
                                    <UserBanner
                                        garage_id={garageid}
                                        car_number={
                                            numberOfCarsInGarage
                                                ? numberOfCarsInGarage
                                                : 0
                                        }
                                        user={accountInternalInfoList}
                                        isExternalGarage
                                    />

                                    <GarageMenuContainer>
                                        <div data-tut="second-step-garage-desktop">
                                            <ViewsMenu
                                                toggleMenu={this.toggleMenu}
                                                activeView={activeView}
                                            />
                                        </div>
                                    </GarageMenuContainer>

                                    <DesktopGarageViewManager
                                        isExternalGarage
                                        activeView={activeView}
                                        carsIdList={
                                            current && current.cars_list
                                                ? current.cars_list
                                                : []
                                        }
                                        addCarForm={() => {}}
                                        imagesData={images_data}
                                        garageid={garageid}
                                    />
                                </DesktopDisplayOnly>

                                <IpadAndMobileDisplay>
                                    <UserTagBox
                                        fullWidth={true}
                                        user={accountInternalInfoList}
                                        car_number={numberOfCarsInGarage}
                                        hideTasksCount={true}
                                        isExternalGarage
                                        readonlyMode
                                    />

                                    <MobileContentBody>
                                        <Paragraph
                                            isSmaller={true}
                                            align="left"
                                            ipadAlign="left"
                                            removeIpadPadding={true}
                                        >
                                            {garageOwner && garageOwner.bio && (
                                                <span
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {garageOwner.bio}
                                                </span>
                                            )}
                                        </Paragraph>
                                    </MobileContentBody>
                                    <div style={{ paddingTop: '24px' }} />
                                    <div data-tut="second-step-garage-mobile">
                                        <ViewsMenuMobile
                                            toggleMenu={this.toggleMenu}
                                            activeView={activeView}
                                            isExternalGarage
                                        />
                                    </div>

                                    <div style={{ paddingTop: '32px' }} />
                                    <MobileGarageViewManager
                                        isExternalGarage
                                        garageid={garageid}
                                        activeView={activeView}
                                        addCarForm={() => {}}
                                        carsIdList={
                                            current && current.cars_list
                                                ? current.cars_list
                                                : []
                                        }
                                        getDropdownList={() => {}}
                                        imagesData={images_data}
                                    />

                                    <div style={{ paddingBottom: '200px' }} />
                                </IpadAndMobileDisplay>
                            </React.Fragment>
                        )}
                </CenteredPageWrapper>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ExternalGarage)
)
