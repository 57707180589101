import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import MobileDisplay from '../templates/displays/mobileDisplay'
import Loader from '../atoms/loader/loader'
import DesktopDisplay from '../templates/displays/desktopDisplay'
import { IUser } from 'myModels'

import Faded from '../templates/animated/faded'
import AddressForm from '../organisms/addressForm/addressForm'
import TriangleHeader from '../atoms/header/triangleheader'
import Question from '../atoms/typography/question'
import PublicPageWrapper from '../templates/displays/pageWrappers/PublicPageWrapper'
import { IUserAddressApi } from 'IapiDataPayload'
import { addAddressRequest } from '../../redux/user/actions/loadingActions'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isLoading: state.user.loading,
    }
}

const dispatchProps = {
    addAddressRequest: (address: IUserAddressApi) =>
        addAddressRequest({ data: address }),
}

const InitialState = {
    user: null,
}

type State = typeof InitialState

type Props = {
    userLoggedIn: null | IUser
    addAddressRequest: (address: IUserAddressApi) => void
    isLoading: boolean
    // redeemReferral: (recipientUserid: string, code: string) => void;
}

class EnterAddress extends React.Component<Props, State> {
    render() {
        const user = this.props.userLoggedIn
        const isLoading = this.props.isLoading

        return (
            <PublicPageWrapper>
                <Faded>
                    {isLoading === true && <Loader />}
                    {user === null && <Loader />}

                    <TriangleHeader title="contact" />
                    <Question>Please enter your address:</Question>
                    <DesktopDisplay>
                        <AddressForm
                            addAddress={this.props.addAddressRequest}
                            isDesktop={true}
                        />
                    </DesktopDisplay>
                </Faded>

                <MobileDisplay>
                    <AddressForm
                        addAddress={this.props.addAddressRequest}
                        isDesktop={false}
                    />
                </MobileDisplay>
            </PublicPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(EnterAddress)
