import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import close_cross_grey from '../../../public/assets/icons/close_cross_grey.svg'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IStyle {
    $theme: ITheme
    $isdisabled?: boolean
}

const Button = styled(motion.div)<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 32px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: ${(props) =>
        `1px solid ${colours[props.$theme].border_muted_light}`};
    outline: none;
    background-color: ${(props) => 'white'};
    border-radius: 5px;
    transition: all 200ms;
    cursor: pointer;
    :hover {
        box-shadow: ${(props) =>
            `2px 2px 4px ${colours[props.$theme].shadow_strongest_09}`};
        transition: all 200ms;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.$isdisabled
            ? colours[props.$theme].border_neutral
            : colours[props.$theme].border_neutral_strong};
    font-family: 'Lato';
    font-size: 12px;
    text-transform: lowercase;
    letter-spacing: 1px;
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    width?: string | undefined
    isdisabled?: boolean
    bordercolor?: string
    textcolor?: string
    transparent?: boolean
    disabledcolor?: string
}

const CloseButton = styled.img`
    height: 8px;
    width: 8px;
    margin-right: 8px;
    transform: translateY(0.5px);
`

const BorderLowerCaseButton: React.FunctionComponent<Props> = (
    props: Props
) => {
    let { onClick, text, isdisabled, dataCyId } = props

    const { theme } = useThemes()
    return (
        <Button
            $theme={theme}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            whileTap={{ scale: 1.1 }}
            role={'button'}
        >
            <CloseButton src={close_cross_grey} />
            <Text $theme={theme} $isdisabled={isdisabled}>
                {text}
            </Text>
        </Button>
    )
}

export default BorderLowerCaseButton
