import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    height?: string
    width?: string
    color?: string
}

const InsuranceIcon = ({ width, height, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={width ?? '17'}
            height={height ?? '23'}
            viewBox="0 0 17 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 6.66633L16 14.6956C16 17.942 11.3125 21.0529 8.5 22C5.6875 21.0529 1 17.942 1 14.6956L1 6.66633C2.5 6.13653 6.1 4.46154 8.5 2C10.9 4.46154 14.5 6.13653 16 6.66633Z"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
                stroke-linecap="round"
            />
            <path
                d="M8.5 2.76929L8.5 22.0001"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
            />
        </svg>
    )
}

export default InsuranceIcon
