import { ICar, IDropdownItem } from 'entityModels'
import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'
import { insurance_fields_everyday_car_ownership } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { CarParams } from '../../dashboards/insuranceApplicationCarDashboard'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import PortalInvisibleWrapper from '../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceApplicationMobileSearch from '../search/mobileSearch'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'
import RemoveMobileSearchAnchor from '../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

const InsuranceEverydayCarOwnershipApplicationFlow = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { carid } = useParams<CarParams>()

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid]?.ownership ??
            {}
        )
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.vehicles[carid]
                ?.ownership ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[carid]
            ?.ownership_errors
    })

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }
        dispatch(
            insuranceActions.set_answer_insurance_car_ownership({
                id: id,
                answer: answer,
                carid: carid,
            })
        )
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_car_ownership({
                id: id,
                error: error,
                carid: carid,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < insurance_fields_everyday_car_ownership.length;
            i++
        ) {
            if (
                insurance_fields_everyday_car_ownership[i].is_required === true
            ) {
                if (
                    data_draft[
                        insurance_fields_everyday_car_ownership[i].id
                    ] === undefined
                ) {
                    setError(
                        insurance_fields_everyday_car_ownership[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }

            if (
                insurance_fields_everyday_car_ownership[i].validation &&
                data_draft[insurance_fields_everyday_car_ownership[i].id] !==
                    undefined
            ) {
                let is_valid_func =
                    insurance_fields_everyday_car_ownership[i].validation
                let value =
                    data_draft[insurance_fields_everyday_car_ownership[i].id]
                let error_txt =
                    insurance_fields_everyday_car_ownership[i].error_txt

                if (is_valid_func && is_valid_func(value) !== true) {
                    setError(
                        insurance_fields_everyday_car_ownership[i].id,
                        error_txt ?? 'Invalid'
                    )
                    hasErr2 = true
                }
            }
        }

        return hasErr2
    }

    const { id } = useParams<{ id?: string }>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        // below only needed after application process?
        // return () => {
        //     dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        // }
    }, [])

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let car: ICar | undefined = carsData[carid]

    useEffect(() => {
        if (userLoggedIn && !countriesList) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [car, userLoggedIn])

    let { getUserAndApplicationData, setInitialCarOwnershipDropdowns } =
        useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
        setInitialCarOwnershipDropdowns(carid)
    }, [userLoggedIn])

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let add_new_external_q = queryParams.get('add_new_external')

    let add_new_external = add_new_external_q === 'true'

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carOwnership_request({
                    carid: carid,
                })
            )
            !add_new_external
                ? history.push(
                      `/insurance/application/${id}/everyday_car/${carid}`
                  )
                : history.push(
                      `/insurance/application/${id}/everyday_car/${carid}?add_new_external=true`
                  )
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: car?.title ?? 'Everyday car',
                            pageUrl: () =>
                                !add_new_external
                                    ? history.push(
                                          `/insurance/application/${id}/everyday_car/${carid}`
                                      )
                                    : history.push(
                                          `/insurance/application/${id}/everyday_car/${carid}?add_new_external=true`
                                      ),
                        },
                        { pageName: 'Ownership' },
                    ]}
                    hasFormChanged={hasFormChanged}
                    onSave={saveForm}
                    sectionId="everyday_vehicles"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '520px',
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            sectionID="everyday_vehicles"
                            list={insurance_fields_everyday_car_ownership}
                            onAnswerChange={onAnswerChange}
                            data={data_draft}
                            errors={errors}
                            setError={setError}
                        />
                    </div>
                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() =>
                        !add_new_external
                            ? history.push(
                                  `/insurance/application/${id}/everyday_car/${carid}`
                              )
                            : history.push(
                                  `/insurance/application/${id}/everyday_car/${carid}?add_new_external=true`
                              )
                    }
                    sectionId="everyday_vehicles"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="everyday_vehicles"
                            urlBack={`/insurance/application/${id}/everyday_car/${carid}/ownership`}
                            draft={data_draft}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                dispatch(
                                    insuranceActions.set_answer_insurance_car_ownership(
                                        {
                                            id: `${field_id}`,
                                            answer: value,
                                            carid: carid,
                                        }
                                    )
                                )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <InsuranceTopBarWithProgressionRateMobile
                            breadCrumbs={[
                                {
                                    pageName: car?.title ?? 'Everyday car',
                                    pageUrl: () =>
                                        !add_new_external
                                            ? history.push(
                                                  `/insurance/application/${id}/everyday_car/${carid}`
                                              )
                                            : history.push(
                                                  `/insurance/application/${id}/everyday_car/${carid}?add_new_external=true`
                                              ),
                                },
                                { pageName: 'Ownership' },
                            ]}
                            hasFormChanged={hasFormChanged}
                            onSave={saveForm}
                            sectionId="everyday_vehicles"
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100vw',
                                paddingTop: 120,
                                paddingBottom: 120,
                                paddingLeft: 16,
                                paddingRight: 16,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                sectionID="everyday_vehicles"
                                list={insurance_fields_everyday_car_ownership}
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                mobileSearchPickerSectionURL={`/insurance/application/${id}/everyday_car/${carid}/ownership`}
                            />
                            <div style={{ paddingTop: '200px' }} />
                        </div>
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() =>
                                !add_new_external
                                    ? history.push(
                                          `/insurance/application/${id}/everyday_car/${carid}`
                                      )
                                    : history.push(
                                          `/insurance/application/${id}/everyday_car/${carid}?add_new_external=true`
                                      )
                            }
                            sectionId="everyday_vehicles"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceEverydayCarOwnershipApplicationFlow
