import styled from 'styled-components'
import InfoIcon from '../icons/infoIcon'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyleProps = {
    $variant?: 'default' | 'info' | 'error'
    $removeBg?: boolean
    $border?: string
    $theme: ITheme
}

const Wrap = styled.div<IStyleProps>`
    background-color: ${(props) =>
        props.$variant === 'error'
            ? colours[props.$theme].error_underlay
            : props.$removeBg
            ? 'transparent'
            : colours[props.$theme].background_neutral_subtle};
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: ${(props) => props.$border ?? 'none'};
`
const Text = styled.div<IStyleProps>`
    color: ${(props) =>
        props.$variant === 'error'
            ? colours[props.$theme].error
            : props.$variant === 'info'
            ? colours[props.$theme].primary
            : colours[props.$theme].text_default};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato';
`
type Props = {
    text: string
    variant?: 'default' | 'info' | 'error'
    removeBg?: boolean
    border?: string
}

const InfoBox = (props: Props) => {
    const { theme } = useThemes()
    return (
        <Wrap
            $theme={theme}
            $variant={props.variant}
            $removeBg={props.removeBg}
            $border={props.border}
        >
            <div style={{ width: '16px', paddingTop: '5px' }}>
                <InfoIcon
                    size={'16'}
                    color={
                        props.variant === 'error'
                            ? colours[theme].error
                            : props.variant === 'info'
                            ? colours[theme].primary
                            : undefined
                    }
                />
            </div>

            <div style={{ paddingLeft: '16px' }} />
            <Text $theme={theme} $variant={props.variant}>
                {props.text}
            </Text>
        </Wrap>
    )
}

export default InfoBox
