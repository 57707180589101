import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const addToWatchlist = (entryID: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.showroom_entry_to_watchlist_endpoint(entryID),
        requestOptions
    )
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Add to showroom watchlist error'
                )
                return Promise.reject(response)
            }

            if (response.status === 204) {
                return
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
export const removeFromWatchlist = (entryID: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.showroom_entry_to_watchlist_endpoint(entryID),
        requestOptions
    )
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(
                    response,
                    'Remove from showroom watchlist error'
                )
                return Promise.reject(response)
            }

            if (response.status === 204) {
                return
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
export type IConvertShowroomWatchAPI_arg = {
    currency: 'GBP' | 'EUR' | 'USD'
    offset: number
    limit: number
}

export const ConvertWatchlistAPIReqToURL = (
    p: IConvertShowroomWatchAPI_arg
): string => {
    return `?offset=${p.offset}&limit=${p.limit}&currency=${p.currency}`
}

export const listShowroomWatchlist = (
    p: IConvertShowroomWatchAPI_arg
): Promise<any> => {
    let urlBuilt = ConvertWatchlistAPIReqToURL(p)

    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(
        urls.showroom_list_watchlist_entries(urlBuilt),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(
                        response,
                        'List showroom watchlist error'
                    )
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                } else return
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
