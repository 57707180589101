import { IDropdownItem, IList } from 'entityModels'
import { useHistory, useLocation } from 'react-router'
import PickerBtn from '../../../../atoms/Button/pickerBtn'
import {
    OnboardingText,
    PaddingUnit,
} from '../../../../templates/onboarding/onboardingFormLayoutMobile'
import { useAppSelector } from '../../../../../redux/store/hooks'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import Expander from '../../../../atoms/expander/expander'
import { ErrorText } from '../../account/accountAddressForm/accountAddressformDesktop'
import { getStandaloneSearchUrl } from '../../technicalInformationEditForm/searchselect/mobile/standaloneSearchSelectMobile'
import ListOnboardingDVLAmobile from '../../../../molecules/list/listOnboardingDVLAmobile'
import uk_flag from '../../../../../public/assets/flags/gb.png'
import us_flag from '../../../../../public/assets/flags/us.png'
import default_flag from '../../../../../public/assets/flags/default.png'
import CrossIcon from '../../../../atoms/icons/crossIcon'
import styled from 'styled-components'
import React from 'react'
import {
    cubicCentimetersToLiters,
    cubicInchesToLiters,
} from '../../../../../helpers/units/unitConversion'
import { IUser } from 'myModels'
import { Select } from '../../../../atoms/select'

const RowForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const AdjustPosition = styled.div`
    margin-left: 24px;
    width: 100%;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: #b3b3b3;
    font-family: Lato;
    padding-left: 8px;
`

const CountryIndicatorWrapper = styled.div`
    width: 24px;
    height: 46px;
    transform: translateX(-15px);
    border-radius: 4px 0px 0px 4px;
    background-color: #012169;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export type IOnboarding_Car_Data_DVLA = {
    make: string | undefined
    model: string | undefined
    year: number | string | undefined
    tax_status: number | string | undefined
    tax_due: number | string | undefined
    service_check_status: number | string | undefined
    service_check_expiry: number | string | undefined
    registration_date: number | string | undefined
    engine_capacity: number | string | undefined
    engine_capacity_unit?: string | undefined
    drive_side?: string | undefined
    transmission?: string | undefined
    fuel_type: number | string | undefined
    body_type: number | string | undefined
    color_exterior: number | string | undefined
    last_owner_change: number | string | undefined
    registration_number: number | string | undefined
}

const generateCountryIndicator = (
    selected: string | null,
    countries: IDropdownItem[] | null
) => {
    let countryCode = () =>
        countries
            ? countries.find((contry) => contry.name === selected)
            : undefined

    return (
        <CountryIndicatorWrapper>
            <img
                src={
                    !selected || selected === 'United Kingdom'
                        ? uk_flag
                        : selected === 'United States'
                        ? us_flag
                        : default_flag
                }
                style={{
                    width: '100%',
                    height: 'auto',
                }}
                alt="country-reg-no"
            />
            <div
                style={{
                    fontFamily: 'UK-Number-Plate',
                    color: '#fff',
                    fontSize: 16,
                }}
            >
                {!selected || selected === 'United Kingdom' || !countryCode
                    ? 'GB'
                    : countryCode()?.uid}
            </div>
        </CountryIndicatorWrapper>
    )
}

type Props = {
    carid: string
    formData: IOnboarding_Car_Data_DVLA
    error:
        | {
              [key: string]: string | undefined
          }
        | undefined
    handleChange: (id: string, value: string | undefined) => void
    initial_info: any
    resetAllFormDataAndReturnToPrevStep: () => void
    isFromVesApi: boolean
    dvlaText: string
    nonDvlaText: string
}

const ConfirmCarDataFormOnboardingMobile = (props: Props) => {
    let {
        formData,
        initial_info,
        handleChange,
        carid,
        resetAllFormDataAndReturnToPrevStep,
        isFromVesApi,
        error,
        dvlaText,
        nonDvlaText,
    } = props

    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let selectedCountry = useAppSelector((state) => {
        return state.localdata.dropdownData.selectedCountry
    })
    let countriesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    let transmissionsList = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let makeDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempMakeDisplay
    })

    let modelDisplay = useAppSelector((state) => {
        return state.localdata.dropdownData.tempModelDisplay
    })

    let keysToMap = [
        'tax_status',
        'tax_due',
        'service_check_status',
        'service_check_expiry',
        'registration_date',
        'engine_capacity',
        'fuel_type',
        'body_type',
        'color_exterior',
        'last_owner_change',
    ]

    const location = useLocation()
    const history = useHistory()

    const makePickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${carid}/search`,
        formId: 'makeModelYear',
        fieldId: 'make',
    })

    const modelPickerUrl = getStandaloneSearchUrl({
        returnUrl: location.search
            ? `${location.pathname}${location.search}`
            : `${location.pathname}`,
        path: `/car/${carid}/search`,
        formId: 'makeModelYear',
        fieldId: 'model',
    })

    let user_fuel_unit_ids_list =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].ids_list

    let user_fuel_unit_data =
        userData &&
        userData.preferences &&
        userData.preferences.data &&
        userData.preferences.data.units &&
        userData.preferences.data.units.data &&
        userData.preferences.data.units.data['fuel_unit'].data

    let fuel_units: IList[] | null =
        user_fuel_unit_data &&
        user_fuel_unit_ids_list &&
        user_fuel_unit_ids_list.map((id: string, index: number) => {
            // @ts-ignore
            return user_fuel_unit_data[id]
        })

    const drive_side_options = [
        {
            name: 'LHD',
        },
        {
            name: 'RHD',
        },
        {
            name: 'Center',
        },
    ]

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let countryParam = queryParams.get('country')
    let country = countryParam
        ? decodeURIComponent(countryParam)
        : 'United Kingdom'

    return (
        <React.Fragment>
            {formData.registration_number &&
                `${formData.registration_number}`?.length > 0 && (
                    <>
                        <InputFieldNoFormikAnimated
                            value={formData.registration_number}
                            name={''}
                            placeholder=""
                            readonly={true}
                            valueStart={generateCountryIndicator(
                                selectedCountry,
                                countriesDropdowndata
                            )}
                            paddingLeft="0px"
                            font_family="UK-Number-Plate"
                            fontSize="32px"
                            rightAdornment={
                                <button
                                    style={{
                                        border: 'none',
                                        backgroundColor: 'transparent',
                                        width: 40,
                                        height: 40,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                    onClick={
                                        resetAllFormDataAndReturnToPrevStep
                                    }
                                >
                                    <CrossIcon />
                                </button>
                            }
                        />
                        <PaddingUnit $times={5} />
                    </>
                )}

            {isFromVesApi ? (
                <>
                    <OnboardingText>{dvlaText}</OnboardingText>
                    {formData.registration_number &&
                        `${formData.registration_number}`?.length > 0 && (
                            <PaddingUnit $times={5} />
                        )}
                </>
            ) : country !== 'United Kingdom' ? (
                <>
                    <OnboardingText>{nonDvlaText}</OnboardingText>
                    {formData.registration_number &&
                        `${formData.registration_number}`?.length > 0 && (
                            <PaddingUnit $times={5} />
                        )}
                </>
            ) : null}

            <PickerBtn
                label="Make *"
                text={makeDisplay ?? undefined}
                // customTextTransform={'lowercase'}
                fwArrow
                onClick={() => {
                    !!makePickerUrl && history.push(makePickerUrl)
                }}
                height="48px"
                borderColour={error?.make ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.make ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.make}</ErrorText>
            </Expander>
            <PaddingUnit $times={3} />
            <PickerBtn
                // customTextTransform={'lowercase'}
                label="Model *"
                text={modelDisplay ?? undefined}
                onClick={() => {
                    !!modelPickerUrl && history.push(modelPickerUrl)
                }}
                fwArrow
                height="48px"
                borderColour={error?.model ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.model ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.model}</ErrorText>
            </Expander>
            <PaddingUnit $times={3} />
            <InputFieldNoFormikAnimated
                value={formData.year}
                name={'Year *'}
                placeholder=""
                type={'number'}
                onChange={(e: any) => {
                    handleChange('year', e.target.value)
                }}
                linecolor={error?.year ? 'rgb(223, 111, 111)' : undefined}
            />
            <Expander height={error?.year ? 'auto' : 0} width={'100%'}>
                <ErrorText>{error?.year}</ErrorText>
            </Expander>
            <div style={{ paddingTop: 32 }} />
            {initial_info && isFromVesApi ? (
                <ListOnboardingDVLAmobile
                    data={initial_info}
                    list={keysToMap}
                />
            ) : (
                <div style={{ width: '100%' }}>
                    <RowForm>
                        <InputFieldNoFormikAnimated
                            name="Engine size"
                            placeholder={'Engine size'}
                            tabindex={1}
                            aria-live="polite"
                            value={
                                formData.engine_capacity !== undefined &&
                                typeof formData.engine_capacity === 'number' &&
                                formData.engine_capacity_unit !==
                                    'cubic_centimeters'
                                    ? formData.engine_capacity.toFixed(1)
                                    : formData.engine_capacity
                            }
                            font_family="Lato"
                            type="number"
                            isnarrow={true}
                            onChange={(e: any) => {
                                handleChange('engine_capacity', e.target.value)
                            }}
                        />

                        <AdjustPosition>
                            <Select
                                defaultValue={formData.engine_capacity_unit}
                                onChange={(val: string | undefined) => {
                                    handleChange('engine_capacity_unit', val)
                                }}
                                placeholder={'Unit'}
                                options={
                                    fuel_units
                                        ? fuel_units.map((unit: IList) => {
                                              return {
                                                  value: unit?.id,
                                                  label: `${unit?.short}`,
                                              }
                                          })
                                        : []
                                }
                            />
                        </AdjustPosition>
                    </RowForm>
                    <Expander
                        height={error?.engine_capacity ? 'auto' : 0}
                        width={'100%'}
                    >
                        <ErrorText>{error?.engine_capacity}</ErrorText>
                    </Expander>
                    {!error?.engine_capacity && (
                        <Expander
                            height={
                                formData.engine_capacity_unit !== 'liters' &&
                                formData.engine_capacity
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <EquivalentConverted>
                                Equal to{' '}
                                {formData.engine_capacity &&
                                    (formData.engine_capacity_unit === 'cc' ||
                                    formData.engine_capacity_unit ===
                                        'cubic_centimeters'
                                        ? cubicCentimetersToLiters(
                                              +formData.engine_capacity
                                          ).toFixed(1)
                                        : formData.engine_capacity_unit ===
                                              'ci' ||
                                          formData.engine_capacity_unit ===
                                              'cubic_inches'
                                        ? cubicInchesToLiters(
                                              +formData.engine_capacity
                                          )
                                        : null)}{' '}
                                litres
                            </EquivalentConverted>
                        </Expander>
                    )}
                    <div style={{ paddingTop: '26px' }} />
                    <Select
                        defaultValue={formData.transmission}
                        placeholder={
                            formData.transmission
                                ? 'Transmission *'
                                : 'Transmission'
                        }
                        onChange={(val: string) => {
                            handleChange('transmission', val)
                        }}
                        options={
                            transmissionsList
                                ? transmissionsList.map(
                                      (unit: IDropdownItem) => ({
                                          value: unit?.uid,
                                          label: `${unit?.name}`,
                                      })
                                  )
                                : []
                        }
                    />
                    <PaddingUnit $times={3} />
                    <Select
                        options={drive_side_options.map(
                            (option: { name: string }) => ({
                                value: option.name,
                                label: option.name,
                            })
                        )}
                        placeholder={
                            formData.drive_side ? 'Drive Side *' : 'Drive Side'
                        }
                        defaultValue={formData.drive_side}
                        onChange={(name) => {
                            handleChange('drive_side', name)
                        }}
                    />
                </div>
            )}
        </React.Fragment>
    )
}

export default ConfirmCarDataFormOnboardingMobile
