import { IReduxError } from 'entityModels'
import { call, fork, put, take } from 'redux-saga/effects'

import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { api } from '../../../services'
import { IaddShareRecipientApi_arg } from '../../../services/sharing/addShareRecipient'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../actions'
import { IShareRecipientRequest_redux } from '../actions/loadingActions'
import { GetSingleCarDirectShare } from './getAllSharesCar'

export function* AddCarShareRecipient(p: IShareRecipientRequest_redux) {
    try {
        let api_arg: IaddShareRecipientApi_arg = {
            share_id: p.share_id,
            recipient_userid: p.recipient_userid,
        }
        yield call(api.sharing.addShareRecipientApi, api_arg)
        yield call(GetSingleCarDirectShare, {
            entity_id: p.entity_id,
            share_id: p.share_id,
        })

        yield put(actions.actions.addCarShareRecipientSuccess())
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: AddCarShareRecipient,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.addCarShareRecipientError(typedError)
            )
            return
        }
    }
}

export function* DeleteCarShareRecipient(p: IShareRecipientRequest_redux) {
    try {
        let api_arg: IaddShareRecipientApi_arg = {
            share_id: p.share_id,
            recipient_userid: p.recipient_userid,
        }

        yield call(api.sharing.deleteShareRecipientApi, api_arg)

        yield put(
            actions.actions.deleteCarShareRecipientSuccess({
                share_id: p.share_id,
                entity_id: p.entity_id,
                recipient_id: p.recipient_userid,
            })
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: DeleteCarShareRecipient,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.deleteCarShareRecipientError(typedError)
            )
            return
        }
    }
}

function* watcherAddCarShareRecipient() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.addCarShareRecipientRequest
        )
        yield call(AddCarShareRecipient, payload)
    }
}

function* watcherDeleteCarShareRecipient() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.deleteCarShareRecipientRequest
        )
        yield call(DeleteCarShareRecipient, payload)
    }
}

const car_share_recipient_sagas: any[] = [
    fork(watcherAddCarShareRecipient),
    fork(watcherDeleteCarShareRecipient),
]

export default car_share_recipient_sagas
