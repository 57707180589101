import { IDropdownItem } from 'entityModels'
import { ILabelAPI } from 'IapiDataPayload'
import { ILabel } from 'myModels'

export const ConvertLabelsFromApiToDropdownItemState = (
    labelsFromAPI: ILabelAPI[]
): IDropdownItem[] => {
    return labelsFromAPI.map((item: ILabelAPI, index: number) => {
        return {
            uid: item.uid,
            name: item.name,
        }
    })
}

export const ConvertLabelsStateToLabelsAddAPI = (
    labels: ILabel[]
): string[] => {
    return labels.map((item: ILabel, index: number) => item.name)
}

export const ConvertLabelsStateToLabelsUnlinkAPI = (
    labels: ILabel[]
): string[] => {
    return labels.map((item: ILabel, index: number) => item.uid)
}

export const ConvertLabelsFromApiToLabelState = (
    labelsFromAPI: ILabelAPI[]
): ILabel[] => {
    return labelsFromAPI.map((item: ILabelAPI, index: number) => {
        return {
            uid: item.uid,
            name: item.name,
        }
    })
}

export const ConvertAPILabelToSingleLabelDropdown = (
    labelAPI: ILabelAPI
): IDropdownItem => {
    let label_item: ILabel = {
        uid: labelAPI.uid,
        name: labelAPI.name,
    }
    return label_item
}

export const ConvertAPILabelToSingleLabel = (labelAPI: ILabelAPI): ILabel => {
    let label_item: ILabel = {
        uid: labelAPI.uid,
        name: labelAPI.name,
    }
    return label_item
}
