import styled from 'styled-components'
import info_black from '../../../../../public/assets/icons/info_black.svg'
import checklist_orange from '../../../../../public/assets/icons/checklist_orange.svg'
import play from '../../../../../public/assets/icons/play.svg'

let cards = [
    {
        id: 'info',
        title: 'Tap the info icon for help.',
        text: 'The questions we ask are carefully curated to help us get you ideal coverage based on your circumstances. Use the info icon next to questions for guidance.',
        src: info_black,
    },
    {
        id: 'checklist',
        title: `See what's missing.`,
        text: `At anytime during your application, click the 'Show missing information' button to be guided through all outstanding questions.`,
        src: checklist_orange,
    },
    {
        id: 'modes',
        title: 'Enter step-by-step mode.',
        text: 'You can fill in your application in any order, but if you would like a step-by-step experience, click the play button at anytime.',
        src: play,
    },
]

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    flex-direction: row;
    padding-top: 26px;

    @media (max-width: 1200px) {
        padding-top: 12px;
    }

    @media (max-width: 744px) {
        align-items: flex-start;
        flex-direction: column;
        padding-left: 24px;
        padding-right: 24px;
    }
`

const Card = styled.div`
    display: flex;
    padding: 24px 24px 32px 24px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 8px;
`

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    text-align: center;
    font-family: Lato-Bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const Text = styled.div`
    color: var(--text-darker, #616161);
    text-align: center;
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const Icon = styled.img`
    width: 42px;
    height: 42px;

    @media (max-width: 845px) {
        width: 30px;
        height: 38px;
    }

    @media (max-width: 445px) {
        width: 24px;
        height: 24px;
    }
`

export const InsuranceOnboardingEExplanations = () => {
    return (
        <Wrapper>
            {cards.map((item) => {
                return (
                    <Card
                        style={{
                            background:
                                item.id === 'info'
                                    ? 'var(--base-overlays-neutral-alpha-100)'
                                    : item.id === 'checklist'
                                    ? 'var( --base-overlays-yello-alpha-200)'
                                    : 'var(--base-overlays-teal-alpha-200)',
                        }}
                    >
                        <Icon src={item.src} />
                        <Title>{item.title}</Title>
                        <Text>{item.text}</Text>
                    </Card>
                )
            })}
        </Wrapper>
    )
}
