import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'
import { useAppSelector } from '../../../../redux/store/hooks'
import { device } from '../../../templates/displays/devices'
import { useHistory } from 'react-router'

type Props = {
    addressesList: IInsuranceAddress_API[]
    isMobile?: boolean
    applicationID?: string | undefined
}

const GridWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    padding-top: 16px;
    @media ${device.mobile_and_ipad} {
        grid-template-columns: 1fr;
    }
`

const OtherBox = styled(Link)`
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--off-bg-color, #fafafa);
`

const EmptyBox = styled.div`
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--off-bg-color, #fafafa);
`

const EmptyLine = styled.div`
    height: 16px;
    background: var(--garage-placeholder-gradient-1, rgba(26, 26, 26, 0.04));
`

const Text = styled.p`
    all: unset;
    color: var(--text-strong, #1a1a1a);
    /* Desktop/Text/T300 Regular */
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
`

const InsurancePolicyAddressesCardContent = (props: Props) => {
    let { addressesList, isMobile } = props

    let number_of_addresses = addressesList.length
    let array_of_placeholders =
        number_of_addresses < 2
            ? Array.from('p'.repeat(2 - number_of_addresses))
            : undefined

    let application = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    const history = useHistory()

    return (
        <GridWrapper>
            {addressesList.map((address, i) => {
                let flattenedAddress_part_3 = `
         ${address && address.locality ? `${address.locality}, ` : ''} ${
                    address && address.postcode ? `${address.postcode} ` : ''
                }`
                return i < 2 ? (
                    <OtherBox
                        key={`${address.uid}_${i}_other_box_address_${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                        style={{
                            cursor:
                                application?.status === 'DRAFT'
                                    ? 'pointer'
                                    : 'not-allowed',
                        }}
                        to={
                            application?.status === 'DRAFT'
                                ? `/insurance/application/${application?.id}/addresses`
                                : `#`
                        }
                    >
                        <Text>
                            {address && address.street_1
                                ? `${address.street_1}`
                                : ''}
                        </Text>
                        <Text>
                            {address && address.street_2
                                ? `${address.street_2}`
                                : ''}
                        </Text>
                        <Text>{flattenedAddress_part_3}</Text>
                    </OtherBox>
                ) : null
            })}
            {array_of_placeholders &&
                array_of_placeholders.map((i, index) => (
                    <EmptyBox
                        key={`empty_address_${index}_${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                        onClick={() => {
                            if (props.applicationID) {
                                return history.push(
                                    `/insurance/application/${props.applicationID}/addresses`
                                )
                            }
                        }}
                    >
                        <EmptyLine style={{ width: '80%' }} />
                        <EmptyLine style={{ width: '50%' }} />
                        <EmptyLine style={{ width: '80%' }} />
                    </EmptyBox>
                ))}
        </GridWrapper>
    )
}

export default InsurancePolicyAddressesCardContent
