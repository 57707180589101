import * as React from 'react'
import styled from 'styled-components'
import { QRCodeSVG } from 'qrcode.react'
import qr_code_logo_black from '../../../public/assets/logo/qr_code_logo_black.svg'
import qr_code_logo_green from '../../../public/assets/logo/qr_code_logo_green.svg'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

type StyledProps = {
    $variant?: string
    $hasBoder?: boolean
}

const Wrapper = styled.div<StyledProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => (props.$hasBoder ? '5px' : 'none')};
    border: ${(props) =>
        props.$hasBoder
            ? props.$variant === 'green'
                ? `1px solid var(--primary, #5ec3ca)`
                : '1px solid var(--border-muted, #e5e5e5)'
            : 'none'};
`

type Props = {
    link: string
    size: number
    logoSize?: number
    hasBorder?: boolean
    variant?: 'green'
    bgColor?: string
    fgColor?: string
    withLogo?: boolean
    id: string
}

const QrCodeSVG: React.FunctionComponent<Props> = (props) => {
    const {
        bgColor,
        fgColor,
        link,
        size,
        variant,
        withLogo,
        hasBorder,
        id,
        // logoSize,
    } = props

    const { theme } = useThemes()

    return (
        <Wrapper $variant={variant} $hasBoder={hasBorder}>
            <QRCodeSVG
                id={id}
                value={link}
                size={size}
                bgColor={bgColor ? bgColor : colours[theme].background_default}
                fgColor={
                    fgColor
                        ? fgColor
                        : variant === 'green'
                        ? colours[theme].primary
                        : '#000'
                }
                includeMargin={hasBorder}
                imageSettings={
                    withLogo
                        ? {
                              src:
                                  variant === 'green'
                                      ? qr_code_logo_green
                                      : qr_code_logo_black,
                              height: 30,
                              width: 30,
                              excavate: false,
                          }
                        : undefined
                }
            />
        </Wrapper>
    )
}

export default QrCodeSVG
