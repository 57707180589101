type Props = {
    height?: string
    width?: string
}

const YourProfileCardPattern = ({ height, width }: Props) => {
    return (
        <svg
            width={width ?? '1200'}
            height={height ?? '200'}
            viewBox="0 0 1200 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.24"
                d="M807.958 63.8577C606.879 44.851 0.431396 202.701 -17 233.336L-16.9991 344H1217V-40H1143.1C1107.67 12.1015 1097.58 125.272 943.733 156.783C751.425 196.172 1059.31 87.6159 807.958 63.8577Z"
                fill="url(#paint0_linear_3924_25900)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3924_25900"
                    x1="499.68"
                    y1="223.442"
                    x2="619.819"
                    y2="-158.371"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default YourProfileCardPattern
