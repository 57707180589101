import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import {
    IGetTimelineItemsByCarIDPayloadRequest,
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    ITimelineCreationFormFieldsData,
    ITimelineDataByCarNormalised,
    ITimelineDataToBeSubmittedObj,
    ITimelineEntryTypes,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'
import {
    getAllTimelineItemsByCarIDRequest,
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest,
} from '../../../redux/timeline/actions/requestActions'
import { ICar, IDropdownItem } from 'entityModels'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import HistoryFilePageManager, {
    redirectToFilteredPath,
} from '../../organisms/HistoryFile/historyFilePageManager'
import { ITimelineCreateForms, IUser } from 'myModels'
import FabsMenusMobile from '../../templates/menus/fabsMenuMobile'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { setActiveEntrySuccess } from '../../../redux/timeline/actions/successActions'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    setCurrentCar: (car_id: string) => void
    carsData: {
        [key: string]: ICar
    }
    toggleFilterMenu: (isOpen: boolean) => void
    isCarLoading: boolean
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    attachmentsObj: INormalisedAttachmentsByID
    // costsObj: INormalisedCostsById
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted: ITimelineDataToBeSubmittedObj
    addTimelineItemToCarRequest: (payload: {
        carid: string
        item: ITimelineItem
    }) => void
    category_searchable_items_list: IDropdownItem[] | null | undefined
    userTimelineCustomTags: IDropdownItem[] | null | undefined
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemsByCarIDPayloadRequest
    ) => {}
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds: (
        payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
    ) => {}
    getCarDataByIdRequest: (carid: string) => {}
    toggleCarBannerImageForm: () => any
    isCarBannerImageFormOpen: boolean
    isTimelineDataLoading: boolean
    isUserLoading: boolean
    userCurrency: string | undefined
    setInitialLandingUrl: (str: string) => void
    toggleFilterTimeline: (isOpen: boolean) => void
    isFilterBottomSheetMenuOpen: boolean
    setActiveEntry: (payload: ITimelineItem | undefined) => void
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    getAllTimelineItemsByCarIDRequest: (
        payload: IGetTimelineItemsByCarIDPayloadRequest
    ) => getAllTimelineItemsByCarIDRequest(payload),
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds: (
        payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
    ) =>
        setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
            payload
        ),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    toggleCarBannerImageForm: () => editFormsActions.toggleCarBannerImageForm(),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    setActiveEntry: (payload: ITimelineItem | undefined) =>
        setActiveEntrySuccess(payload),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        carsData: state.entities.carsData.cars,
        isCarLoading: state.entities.carsData.loading,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
    }
}

export enum TimelineViewEnum {
    small = 'small',
    fat = 'fat',
}

type State = {}

class CarTimeline extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        this.props.setInitialLandingUrl(
            `/car/${this.props.match.params.carid}/history-file`
        )

        // setting active entry as undefined everytime user gets back to timeline
        // where he can see all entries and so no entry should be active
        this.props.setActiveEntry(undefined)

        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }
        // get necessary args
        // CAR

        if (userLoggedIn) {
            let carid = this.props.match.params.carid
            this.props.setCurrentCar(carid)

            if (!this.props.carsData[this.props.match.params.carid]) {
                this.props.getCarDataByIdRequest(this.props.match.params.carid)
            }

            this.props.getAllTimelineItemsByCarIDRequest({
                car_id: carid,
            })

            let carsDataSearch =
                this.props.carsTimelineData &&
                this.props.carsTimelineData[carid] &&
                this.props.carsTimelineData[carid].search &&
                this.props.carsTimelineData[carid].search

            let entry_title = carsDataSearch?.activeEntryTitleQuery

            const params = new URLSearchParams(this.props.location.search)

            let labelsParam = params.getAll('label')

            let categoryParam: ITimelineEntryTypes[] | undefined =
                params.getAll('category') as ITimelineEntryTypes[]

            if (this.props.userLoggedIn) {
                this.props.getAllTimelineItemsByCarIDRequest({
                    car_id: carid,
                })
            }

            if (
                (carsDataSearch &&
                    carsDataSearch.activeCategoryIds &&
                    carsDataSearch.activeCategoryIds.length > 0) ||
                (carsDataSearch &&
                    carsDataSearch.activeCustomLabelsIds &&
                    carsDataSearch.activeCustomLabelsIds.length > 0) ||
                (carsDataSearch && carsDataSearch.activeEntryTitleQuery)
            ) {
                this.props.setFilterTimelineItemsByCarIdCategoryIdsNLabelIds({
                    car_id: carid,
                    category_ids: carsDataSearch.activeCategoryIds,
                    label_ids: carsDataSearch.activeCustomLabelsIds,
                    entry_title: entry_title,
                    sendToAPI: true,
                })
            } else if (
                (labelsParam && labelsParam.length > 0) ||
                (categoryParam && categoryParam.length > 0)
            ) {
                this.props.setFilterTimelineItemsByCarIdCategoryIdsNLabelIds({
                    car_id: carid,
                    category_ids: categoryParam,
                    label_ids: labelsParam,
                    entry_title: entry_title,
                    sendToAPI: true,
                })
            }

            if (
                labelsParam.length === 0 &&
                categoryParam.length === 0 &&
                entry_title === undefined
            ) {
                this.props.getAllTimelineItemsByCarIDRequest({
                    car_id: carid,
                })
            }

            let currentPath = this.props.history.location.pathname
            let scrolltoentryid: any = params.get('scrolltoid')

            let newPath = redirectToFilteredPath(
                currentPath,
                carsDataSearch?.activeCategoryIds,
                carsDataSearch?.activeCustomLabelsIds,
                scrolltoentryid
            )

            carsDataSearch && newPath && this.props.history.push(newPath)
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn) {
                let carid = this.props.match.params.carid
                this.props.setCurrentCar(carid)

                if (!this.props.carsData[this.props.match.params.carid]) {
                    this.props.getCarDataByIdRequest(
                        this.props.match.params.carid
                    )
                }

                this.props.getAllTimelineItemsByCarIDRequest({
                    car_id: carid,
                })

                let carsDataSearch =
                    this.props.carsTimelineData &&
                    this.props.carsTimelineData[carid] &&
                    this.props.carsTimelineData[carid].search &&
                    this.props.carsTimelineData[carid].search

                let entry_title = carsDataSearch?.activeEntryTitleQuery

                const params = new URLSearchParams(this.props.location.search)
                let scrolltoentryid: any = params.get('scrolltoid')

                let labelsParam = params.getAll('label')

                let categoryParam: ITimelineEntryTypes[] | undefined =
                    params.getAll('category') as ITimelineEntryTypes[]

                if (
                    (carsDataSearch &&
                        carsDataSearch.activeCategoryIds &&
                        carsDataSearch.activeCategoryIds.length > 0) ||
                    (carsDataSearch &&
                        carsDataSearch.activeCustomLabelsIds &&
                        carsDataSearch.activeCustomLabelsIds.length > 0) ||
                    (carsDataSearch && carsDataSearch.activeEntryTitleQuery)
                ) {
                    this.props.setFilterTimelineItemsByCarIdCategoryIdsNLabelIds(
                        {
                            car_id: carid,
                            category_ids: carsDataSearch.activeCategoryIds,
                            label_ids: carsDataSearch.activeCustomLabelsIds,
                            entry_title: entry_title,
                            sendToAPI: true,
                        }
                    )
                } else if (
                    (labelsParam && labelsParam.length > 0) ||
                    (categoryParam && categoryParam.length > 0)
                ) {
                    this.props.setFilterTimelineItemsByCarIdCategoryIdsNLabelIds(
                        {
                            car_id: carid,
                            category_ids: categoryParam,
                            label_ids: labelsParam,
                            entry_title: entry_title,
                            sendToAPI: true,
                        }
                    )
                }

                if (
                    labelsParam.length === 0 &&
                    categoryParam.length === 0 &&
                    entry_title === undefined
                ) {
                    this.props.getAllTimelineItemsByCarIDRequest({
                        car_id: carid,
                    })
                }

                let currentPath = this.props.history.location.pathname

                let newPath = redirectToFilteredPath(
                    currentPath,
                    carsDataSearch?.activeCategoryIds,
                    carsDataSearch?.activeCustomLabelsIds,
                    scrolltoentryid
                )

                carsDataSearch && newPath && this.props.history.push(newPath)
            }
        }
    }

    componentWillUnmount() {}

    render() {
        let carid = this.props.match.params.carid
        let currentCar: ICar = this.props.carsData[carid]

        let {
            carsData,
            carsTimelineData,
            setFilterTimelineItemsByCarIdCategoryIdsNLabelIds,
        } = this.props

        return (
            <React.Fragment>
                <FabsMenusMobile hasAdd={true} hasSubNav={true} />
                <HistoryFilePageManager
                    carid={carid}
                    currentOwnedCar={currentCar}
                    carsData={carsData}
                    carsTimelineData={carsTimelineData}
                    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds={
                        setFilterTimelineItemsByCarIdCategoryIdsNLabelIds
                    }
                />
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CarTimeline))
