import { IDropdownItem } from 'entityModels'

export const generate_task_cars_dropdown_list = (
    arr: string[],
    cars: any,
    garageid: string
) => {
    let dropdownCars: IDropdownItem[] = []

    for (const carIndex in arr) {
        if (cars[arr[carIndex]] !== undefined) {
            if (cars[arr[carIndex]].title.length > 1) {
                let res_obj = {
                    uid: arr[carIndex],
                    name: cars[arr[carIndex]].title,
                }
                dropdownCars.push(res_obj)
            } else {
                let res_obj = {
                    uid: arr[carIndex],
                    name: `untitled #${carIndex}`,
                }
                dropdownCars.push(res_obj)
            }
        }
    }

    return dropdownCars
}
