import * as Yup from 'yup'
import styled from 'styled-components'
import './styles.css'
import grey_cross from '../../../public/assets/icons/close_cross_grey.svg'
import { Field, FormikProps, withFormik } from 'formik'

import { ISearchUsersResult } from 'entityModels'

import { useState } from 'react'
import SearchResultsItemDesktop from '../searchbar/carShare/searchResultsItemDesktop'
import SearchLoadingPlaceholdersDesktop from '../searchbar/carShare/searchLoadingPlaceholdersDesktop'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import InviteSearchBtn from '../searchbar/inviteSearchBtn'
import { device } from '../../templates/displays/devices'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Icon from '../../atoms/icons'
import Expander from '../../atoms/expander/expander'
import StyledFormError from '../../templates/styledcomponents/styledformerrormessage'
import { useAppSelector } from '../../../redux/store/hooks'
// import SearchIcon from '../../atoms/icons/components/searchIcon'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import { SearchIcon } from '../../atoms/statefulicons'

export type OtherProps = {
    searchFor?: any
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    value?: any
    onSearch?: any
    clearSearch?: any
    isSearchOnEveryChange?: boolean
    searchResults?: ISearchUsersResult[]
    onSearchResultSelect: any
    onInputChange?: any
    loadingPlaceholder?: boolean
    selectedUsers: string[]
    selectedUserData?: ISearchUsersResult
    hasInviteOption?: boolean
    onClickInvite?: () => any
    inviteText?: string
    ownEmailSearchCopy?: string
    backgroundColor?: string
}

type IStyledProps = {
    $issearchactive?: boolean
    grey_cross?: string
    $backgroundColor?: string
}

const CustomisedSearchField = styled(Field)<IStyledProps>`
    width: 100%;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: ${(props) =>
        props.$backgroundColor ?? 'var(--bg-color, #fff) !important'};
    color: var(--text-strong, #1a1a1a);
    border-radius: 5px;
    height: 48px !important;
    font-family: Lato;
    font-size: 15px;
    ::placeholder {
        color: var(--text-darker, #616161) !important;
        font-family: Lato;
        font-size: 15px;
    }

    @media ${device.mobile} {
        font-size: 13px;
        height: 51px !important;
        ::placeholder {
            font-size: 15px;
        }
    }
`

const GreyCrossWrapper = styled.div<IStyledProps>`
    width: 22px;
    height: 22px;
    position: absolute;
    right: 10px;
    top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`

const GreyCross = styled.div<IStyledProps>`
    width: 12px;
    height: 12px;
    background-image: ${(props) => `url(${props.grey_cross})`};
    background-size: cover;
    cursor: pointer;
`

const SelectedUser = styled.div<IStyledProps>`
    width: 50px;
    height: 100%;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 16px;
    left: 15px;
`
const Wrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
`

const SubWrapper = styled.div`
    position: relative;
    width: 85%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    @media ${device.mobile} {
        width: 82%;
    }
`

const SubWrapperRow = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`

const MobileSearchTitle = styled.div`
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--text-muted);
    font-family: Lato-bold;
    font-size: 14px;
`

const ProfilePicWrapper = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--border-muted, #e5e5e5);
    transform: translateY(-3px);
`

const ProfilePic = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    background-color: var(--off-bg-color, #fafafa);
    padding: 16px;
`

const InfoTxt = styled.div`
    color: var(--text-default, #666666);
    font-family: 'Lato';
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    padding-right: 10px;
`

const Warning = styled.div`
    all: unset;
    padding-left: 8px;
    font-style: italic;
    font-family: 'Lato';
    font-size: 14px;
    color: var(--error, #df6f6f);
`

interface Values {
    single_item_search_value_desktop: string
}

const renderSearchResults = (payload: {
    searchResults: ISearchUsersResult[]
    onSearchResultSelect: any
    selectedUsers: string[]
}) =>
    payload.searchResults.map((owner, index) => {
        let isAdded = payload.selectedUsers.includes(owner.uid)
        return (
            <div key={`${index}user`} style={{ width: '100%', height: '100%' }}>
                <SearchResultsItemDesktop
                    onSearchResultSelect={() =>
                        !isAdded ? payload.onSearchResultSelect(owner) : {}
                    }
                    owner={owner}
                    isAdded={isAdded}
                />
            </div>
        )
    })

const renderLoadingPlaceholders = () =>
    [1].map((owner, index) => (
        <SearchLoadingPlaceholdersDesktop key={`${index}_placeholder`} />
    ))

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        manageissearchactive,
        dataCyId,
        // issearchactive,
        onSearch,
        values,
        errors,
        clearSearch,
        resetForm,
        searchResults,
        onSearchResultSelect,
        setFieldValue,
        onInputChange,
        loadingPlaceholder,
        selectedUsers,
        selectedUserData,
        touched,
        ownEmailSearchCopy,
        backgroundColor,
    } = props

    const [hasSearched, setHasSearched] = useState<boolean>(false)

    const userEmail = useAppSelector((state) => state.user?.userLoggedIn?.email)

    const handleChange = (e: any) => {
        setFieldValue('single_item_search_value_desktop', e.target.value)
        onInputChange(e.target.value)
        setHasSearched(false)
    }

    const isUserEmail =
        userEmail &&
        values.single_item_search_value_desktop &&
        values.single_item_search_value_desktop === userEmail
            ? true
            : false

    const { theme } = useThemes()

    return (
        <Wrapper data-cy={dataCyId} id="custom_search_mobile_bar">
            <SubWrapperRow>
                <SubWrapper>
                    <CustomisedSearchField
                        id={'single_item_search_value_desktop'}
                        name={'single_item_search_value_desktop'}
                        placeholder={props.placeholder ? props.placeholder : ''}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={
                            selectedUserData
                                ? selectedUserData.display_name
                                : values.single_item_search_value_desktop
                        }
                        dataCyId={props.dataCyId}
                        // onKeyUp={handleKeyUp}
                        onChange={handleChange}
                        onClick={() =>
                            manageissearchactive && manageissearchactive(true)
                        }
                        style={{
                            paddingLeft: selectedUserData ? '45px' : '20px',
                        }}
                        $backgroundColor={backgroundColor}
                    />

                    <SelectedUser
                        $issearchactive={
                            values.single_item_search_value_desktop &&
                            values.single_item_search_value_desktop !== ''
                                ? true
                                : undefined
                        }
                        // onClick={() =>
                        //     onSearch(values.single_item_search_value_desktop)
                        // }
                    >
                        {selectedUserData ? (
                            <ProfilePicWrapper>
                                <ProfilePic
                                    src={
                                        selectedUserData.profile_image_url
                                            ? selectedUserData.profile_image_url
                                            : blank_user
                                    }
                                />
                            </ProfilePicWrapper>
                        ) : null}
                    </SelectedUser>

                    {(props.selectedUserData ||
                        values.single_item_search_value_desktop) && (
                        <GreyCrossWrapper
                            onClick={() => {
                                resetForm({
                                    values: {
                                        single_item_search_value_desktop:
                                            props.searchFor,
                                    },
                                })
                                clearSearch()
                                // toggleResultsDropdown(false)
                                setHasSearched(false)
                            }}
                        >
                            <GreyCross grey_cross={grey_cross} />
                        </GreyCrossWrapper>
                    )}
                </SubWrapper>

                <ButtonAtom
                    theme="lowercase-blue-background"
                    height={'48px'}
                    width={'48px'}
                    onClick={() => {
                        if (!hasSearched) {
                            onSearch(values.single_item_search_value_desktop)
                            setHasSearched(true)
                        }
                    }}
                    disabled={
                        hasSearched ||
                        (errors.single_item_search_value_desktop &&
                            touched.single_item_search_value_desktop)
                            ? true
                            : false
                    }
                >
                    <SearchIcon
                        isActive
                        height={16}
                        color={colours[theme].background_default}
                    />
                </ButtonAtom>
            </SubWrapperRow>
            <Expander
                height={
                    errors.single_item_search_value_desktop &&
                    touched.single_item_search_value_desktop
                        ? 'auto'
                        : 0
                }
            >
                <StyledFormError>
                    {errors.single_item_search_value_desktop}
                </StyledFormError>
            </Expander>

            {/* <DesktopDisplayOnly>
                <SearchResultsDropdownWrapper
                    $isInvite={
                        hasFinishedTyping &&
                        (!searchResults || searchResults?.length === 0) &&
                        props.hasInviteOption === true &&
                        props.onClickInvite !== undefined
                            ? true
                            : false
                    }
                    $isVisible={resultsDropdown ? true : false}
                >
                    <Expander height={resultsDropdown ? 'auto' : 0}>
                        {!hasFinishedTyping ? (
                            renderLoadingPlaceholders()
                        ) : hasFinishedTyping &&
                          (!searchResults || searchResults?.length < 1) &&
                          props.hasInviteOption === true &&
                          props.onClickInvite !== undefined ? (
                            <InviteSearchBtn
                                onClickInvite={props.onClickInvite}
                                inviteText={props.inviteText}
                            />
                        ) : (
                            <div
                                style={
                                    {
                                        // minHeight: '200px',
                                    }
                                }
                            >
                                {renderSearchResults({
                                    searchResults: searchResults ?? [],
                                    onSearchResultSelect: (
                                        owner: ISearchUsersResult
                                    ) => {
                                        onSearchResultSelect(owner)
                                        // clearSearch()
                                        toggleResultsDropdown(false)
                                    },
                                    selectedUsers: selectedUsers,
                                })}
                            </div>
                        )}
                    </Expander>
                </SearchResultsDropdownWrapper>
            </DesktopDisplayOnly> */}

            {hasSearched && (
                <>
                    <div style={{ paddingTop: '16px' }} />

                    {values.single_item_search_value_desktop === '' &&
                    hasSearched ? (
                        <Warning>{'Please type in an email address.'}</Warning>
                    ) : isUserEmail ? (
                        loadingPlaceholder ? (
                            renderLoadingPlaceholders()
                        ) : (
                            <Warning>
                                {ownEmailSearchCopy ??
                                    'Please type in a different email than your own.'}
                            </Warning>
                        )
                    ) : (
                        <MobileSearchTitle style={{ paddingTop: '30px' }}>
                            Search results
                        </MobileSearchTitle>
                    )}
                    <div style={{ paddingTop: '20px' }} />

                    {loadingPlaceholder ? (
                        renderLoadingPlaceholders()
                    ) : (!searchResults || searchResults?.length === 0) &&
                      !isUserEmail &&
                      hasSearched &&
                      values.single_item_search_value_desktop !== '' &&
                      props.hasInviteOption === true &&
                      props.onClickInvite !== undefined ? (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <InviteSearchBtn
                                onClickInvite={props.onClickInvite}
                                inviteText={props.inviteText}
                            />

                            <div style={{ paddingTop: '24px' }} />

                            <Row>
                                <div style={{ transform: 'translateY(2px)' }}>
                                    <Icon icon="info_gray" height="20px" />
                                </div>

                                <div style={{ paddingLeft: '20px' }} />
                                <InfoTxt>
                                    This email address isn't yet associated with
                                    an existing Custodian account. If you
                                    believe the user does have an existing
                                    account, please check the email address is
                                    typed correctly.
                                </InfoTxt>
                            </Row>
                        </div>
                    ) : (
                        renderSearchResults({
                            searchResults: searchResults ?? [],
                            onSearchResultSelect: (
                                owner: ISearchUsersResult
                            ) => {
                                onSearchResultSelect(owner)
                                // clearSearch()
                                setHasSearched(false)
                                // toggleResultsDropdown(false)
                            },
                            selectedUsers: selectedUsers,
                        })
                    )}

                    {/* {searchResults ? (
                        renderSearchResults({
                            searchResults: searchResults,
                            onSearchResultSelect: (
                                owner: ISearchUsersResult
                            ) => {
                                onSearchResultSelect(owner)
                                // clearSearch()
                                toggleResultsDropdown(false)
                            },
                            selectedUsers: selectedUsers,
                        })
                    ) : loadingPlaceholder || !hasFinishedTyping ? (
                        renderLoadingPlaceholders()
                    ) : (
                        <div
                            style={{
                                paddingTop: '10px',
                                paddingLeft: '20px',
                                fontStyle: 'italic',
                            }}
                        >
                            {values.single_item_search_value_desktop.trim() ===
                            ''
                                ? 'Please type in a valid name'
                                : values.single_item_search_value_desktop.trim()
                                      .length < 4
                                ? 'Please type at least 4 characters or more'
                                : 'No user matches your search'}
                        </div>
                    )} */}
                </>
            )}
        </Wrapper>
    )
}

const UsersSearchBar = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_search_value_desktop: props.searchFor
            ? props.searchFor
            : '',
    }),
    enableReinitialize: true,

    // validationSchema: {},

    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_search_value_desktop: Yup.string()
                    .email('Invalid email')
                    .required('Required'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
    },
})(InnerForm)

export default UsersSearchBar
