import {
    IDirectShareOwnedItem_Api_payload,
    IPublicShareAPIRes_item,
} from 'ApiInterfaces'
import {
    IEntityPrivateOwnedSharesState,
    IEntitySharesOwnedData,
    IDirectShareOwnedItem,
    IPublicShare,
    IDirectShareOwnedItemsData,
} from 'entityModels'
import { ConvertSingleDirectShare_owned } from './convertDirectShares'
import { ConvertSingleUserPublicShare } from './convertPublicShares'

export type I_UserOwnedShares_ByEntityType_ConversionArg = {
    cars: {
        [key: string]: IEntitySharesOwnedData
    }
    garage: {
        [key: string]: IEntitySharesOwnedData
    }
}

export type I_UserOwnedShares_ByEntityType_ConversionArg_req = {
    owned_direct_shares: IDirectShareOwnedItem_Api_payload[]
    owned_public_shares: IPublicShareAPIRes_item[]
}

export const ConvertEntityDirectSharesListToState = (p: {
    api_shares: IDirectShareOwnedItem_Api_payload[]
    shares_in_state?: IDirectShareOwnedItemsData | undefined
}): IEntityPrivateOwnedSharesState => {
    const { api_shares, shares_in_state } = p

    let res: IEntityPrivateOwnedSharesState = {
        entity_owned_direct_shares_ids: [],
        entity_owned_direct_shares_data: {},
    }

    for (let i = 0; i < api_shares.length; i++) {
        let item: IDirectShareOwnedItem = ConvertSingleDirectShare_owned({
            api_payload: api_shares[i],
        })

        item.share_recipients_data =
            shares_in_state &&
            shares_in_state[item.id] &&
            shares_in_state[item.id].share_recipients_data
        item.all_share_recipients_ids_list =
            shares_in_state &&
            shares_in_state[item.id] &&
            shares_in_state[item.id].all_share_recipients_ids_list
                ? shares_in_state[item.id].all_share_recipients_ids_list
                : []

        res = {
            ...res,
            entity_owned_direct_shares_ids: [
                ...res.entity_owned_direct_shares_ids,
                api_shares[i].uid,
            ],
            entity_owned_direct_shares_data: {
                ...res.entity_owned_direct_shares_data,
                [api_shares[i].uid]: { ...item },
            },
        }
    }

    return res
}

export const ConvertSharesOwnedToState = (
    p: I_UserOwnedShares_ByEntityType_ConversionArg_req
): I_UserOwnedShares_ByEntityType_ConversionArg => {
    let res: I_UserOwnedShares_ByEntityType_ConversionArg = {
        cars: {},
        garage: {},
    }

    // direct shares, cars and garage population
    for (let i = 0; i < p.owned_direct_shares.length; i++) {
        if (
            (p.owned_direct_shares[i] &&
                p.owned_direct_shares[i].entity_type === 'car') ||
            p.owned_direct_shares[i].entity_type === 'garage'
        ) {
            let item: IDirectShareOwnedItem = ConvertSingleDirectShare_owned({
                api_payload: p.owned_direct_shares[i],
            })

            let entity_type: 'cars' | 'garage' =
                p.owned_direct_shares[i].entity_type === 'car'
                    ? 'cars'
                    : 'garage'

            let curr: IEntitySharesOwnedData = res[entity_type][item.entity_id]
                ? res[entity_type][item.entity_id]
                : {
                      private_shares_owned: {
                          entity_owned_direct_shares_ids: [],
                          entity_owned_direct_shares_data: {},
                      },
                  }

            res = {
                ...res,
                [entity_type]: {
                    ...res[entity_type],
                    [item.entity_id]: {
                        ...curr,
                        private_shares_owned: {
                            ...curr.private_shares_owned,
                            entity_owned_direct_shares_data: {
                                ...curr.private_shares_owned!
                                    .entity_owned_direct_shares_data,
                                [item.id]: { ...item },
                            },
                            entity_owned_direct_shares_ids: [
                                ...curr.private_shares_owned!
                                    .entity_owned_direct_shares_ids,
                                item.id,
                            ],
                        },
                    },
                },
            }
        }
    }

    // public shares, cars and garage population
    for (let i = 0; i < p.owned_public_shares.length; i++) {
        if (
            (p.owned_public_shares[i] &&
                p.owned_public_shares[i].entity_type === 'car') ||
            p.owned_public_shares[i].entity_type === 'garage'
        ) {
            let item: IPublicShare = ConvertSingleUserPublicShare(
                p.owned_public_shares[i]
            )

            let entity_type: 'cars' | 'garage' =
                p.owned_public_shares[i].entity_type === 'car'
                    ? 'cars'
                    : 'garage'

            if (item.entity_id) {
                let curr: IEntitySharesOwnedData = res[entity_type][
                    item.entity_id
                ]
                    ? res[entity_type][item.entity_id]
                    : {
                          public_share_owned: {},
                      }

                res = {
                    ...res,
                    [entity_type]: {
                        ...res[entity_type],
                        [item.entity_id]: {
                            ...curr,
                            public_share_owned: {
                                ...item,
                            },
                        },
                    },
                }
            }
        }
    }

    return res
}
