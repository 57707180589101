import { useMemo, useState } from 'react'
import { Option } from './types'
import Fuse from 'fuse.js'

interface UseFilterableListOptions {
    list: Option[]
    searchQuery: string
    /**
     * When true, the list is filtered by `searchQuery`
     * using fuse.js.
     * When false, the entire list is returned as-is.
     *
     * @default false
     */
    enableSearch?: boolean
}

export function useFilterableList(options: UseFilterableListOptions) {
    const enableSearch = options.enableSearch ?? false

    const fuse = useMemo(() => {
        if (!enableSearch) return null

        return new Fuse(options.list, { keys: ['label'], threshold: 0.2 })
    }, [enableSearch, options.list])

    const filteredList = useMemo(() => {
        if (!fuse) return options.list

        if (options.searchQuery.length < 1) return options.list
        return fuse.search(options.searchQuery).map(({ item }) => ({ ...item }))
    }, [options, fuse])

    return filteredList
}

interface UseListNavigationOptions {
    listLength: number
    onListItemSelect: (index: number) => void
}

export function useListNavigation(options: UseListNavigationOptions) {
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1)

    const handleKeys = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)) {
            event.preventDefault()
            event.stopPropagation()
        }

        if (event.key === 'ArrowUp') {
            setHighlightedIndex((currentIndex) => {
                if (currentIndex > 0) {
                    return currentIndex - 1
                } else {
                    return options.listLength - 1
                }
            })

            return
        }

        if (event.key === 'ArrowDown') {
            setHighlightedIndex((currentIndex) => {
                if (currentIndex < options.listLength - 1) {
                    return currentIndex + 1
                } else {
                    return 0
                }
            })

            return
        }

        if (event.key === 'Enter') {
            options.onListItemSelect(highlightedIndex)
            return
        }
    }

    return { highlightedIndex, setHighlightedIndex, handleKeys }
}
