import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Icon from '../../atoms/icons'
import { RootState } from 'typesafe-actions'
import GarageCarBanner from '../../atoms/image/garageCarBanner'
import { ICar, IExternalCar } from 'entityModels'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

const HoverBox = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    width: 100%;

    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;

    background: linear-gradient(
        100deg,
        rgba(0, 0, 0, 0.91) 42.27%,
        rgba(0, 0, 0, 0.56) 95%,
        rgba(0, 0, 0, 0.47) 98.84%
    );
    transition: 0.2s;

    :hover {
        opacity: 1;
    }
`

const Text = styled.div`
    color: white;
    position: absolute;

    @media ${device.desktop} {
        top: 150px;
        left: 90px;
    }
    @media ${device.large_desktop} {
        top: 150px;
        left: 90px;
    }

    @media ${device.smallest_laptops} {
        top: 150px;
        left: 90px;
    }

    text-align: left;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    padding-bottom: 5px;

    @media ${device.desktop} {
        width: 500px;
    }
    @media ${device.large_desktop} {
        width: 500px;
    }

    @media ${device.smallest_laptops} {
        width: 100%;
    }
`

const Description = styled.div`
    @media ${device.mobile} {
    }

    @media ${device.desktop} {
        font-family: Lato;
        font-size: 16px;
        line-height: 2;
        max-width: 750px;
        padding-bottom: 20px;
    }

    @media ${device.smallest_laptops} {
        font-family: Georgia;
        font-size: 16px;
        line-height: 2;
        max-width: 700px;
        padding-bottom: 20px;
    }
`

const Title = styled.div`
    font-family: Lato;
    text-transform: uppercase;
    padding-right: 14px;

    @media ${device.desktop} {
        transform: translateY(-8px);
        font-size: 24px;
    }

    @media ${device.smallest_laptops} {
        font-size: 18px;
        transform: translateY(-4px);
    }
`

interface Props extends RouteComponentProps<any> {
    carObject: ICar | IExternalCar
    onClick?: any
    isExternalGarage?: boolean

    reset_make_model_temps: () => any
}

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}
class GarageBannerView extends React.Component<Props, {}> {
    componentDidMount() {}

    render() {
        const { carObject, isExternalGarage } = this.props

        return (
            <React.Fragment>
                {carObject !== undefined && (
                    <Link
                        to={{
                            pathname: isExternalGarage
                                ? `/shared/car/${carObject.id}`
                                : `/car/${carObject.id}`,
                            state: {
                                prevPath: this.props.history.location.pathname,
                                search: this.props.history.location.search,
                            },
                        }}
                        onClick={() => {
                            this.props.reset_make_model_temps()
                        }}
                    >
                        <GarageCarBanner img={carObject.banner_url}>
                            <HoverBox>
                                <Text>
                                    <Row>
                                        <Title>{carObject.title}</Title>
                                        {/* <Icon icon="share_link" height="16px" /> */}
                                    </Row>
                                    <Description>
                                        {carObject.short_description}
                                    </Description>
                                    <Row>
                                        <Icon icon="arrow_white" width="60px" />
                                    </Row>
                                </Text>
                            </HoverBox>
                        </GarageCarBanner>
                    </Link>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GarageBannerView)
)
