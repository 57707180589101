// import saveAs from 'file-saver'
import { checkIfPwaOnIpad } from '../../templates/pwaCustom/checkIpadPwa'
import { download_PDF_RawQRA4 } from '../pdfDocument/pdfDocument'
import { generateBigSVG } from './generateBigQRSVG'

import { createQRLogoSvgBiggerRes } from './logotest2'

export type IdownloadRawQrCode_args = {
    id_of_svg_qr_code: string
    format: 'PNG' | 'PDF'
    title: string
    device: 'desktop' | 'mobile'
}

export const downloadRawQrCode = (p: IdownloadRawQrCode_args) => {
    let imgloaded = false

    const qr_code_svg_element_og: HTMLElement | null = document.getElementById(
        p.id_of_svg_qr_code
    )

    if (qr_code_svg_element_og) {
        let qr_code_svg_element: any = qr_code_svg_element_og.cloneNode(true)

        let new_svg = generateBigSVG(qr_code_svg_element)

        // add svg logo
        let image = new_svg.querySelector('image')

        if (image) {
            let logoSvg = createQRLogoSvgBiggerRes()

            new_svg.appendChild(logoSvg)

            image.remove()

            // image.replaceWith(logoSvg)
        }

        let svg_markup = new_svg
            ? new XMLSerializer().serializeToString(new_svg)
            : null

        const svg_blob =
            svg_markup &&
            new Blob([svg_markup], {
                type: 'image/svg+xml',
            })

        // of whatever resolution I want
        // create a canvas element to paint sgv image on
        const canvas = document.createElement('canvas')
        canvas.width = 1000
        canvas.height = 1000
        const ctx = canvas.getContext('2d')

        // create new image element and add the blob url to it
        const img = new Image()
        img.width = 1000
        img.height = 1000
        img.src = svg_blob ? URL.createObjectURL(svg_blob) : ''

        if (imgloaded === false) {
            // onload will never get called again:
            // on image load, paint svg and save png

            img.onload = () => {
                if (imgloaded === false) {
                    ctx && ctx.drawImage(img, 0, 0, 1000, 1000)
                    const imgURI = canvas.toDataURL('image/png', 1)
                    img.src = imgURI
                    if (p.format === 'PDF') {
                        download_PDF_RawQRA4(img, p.title)
                    } else if (p.format === 'PNG') {
                        // saveAs(imgURI, 'qr_code.png')
                        const link = document.createElement('a')
                        link.href = imgURI

                        if (p.device === 'mobile' || checkIfPwaOnIpad()) {
                            openNewImg(imgURI)
                        } else {
                            const fileName = `${p.title
                                .toLowerCase()
                                .replace(/ /g, '_')}_custodian_qr_code.png`
                            link.download = fileName
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                        }
                    }
                    imgloaded = true
                }
            }
        }

        // clean-up: removing newly created elememts
        img.remove()
        canvas.remove()
        return
    } else return
}

export const openNewImg = (imgURI: any) => {
    const base64ImageData = imgURI

    const contentType = 'image/png'

    const byteCharacters = atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
    )
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }
    const blob = new Blob(byteArrays, { type: contentType })
    const blobUrl = URL.createObjectURL(blob)

    window.location.assign(blobUrl)

    // window.open(blobUrl, '_blank')
}
