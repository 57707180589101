import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import { IGalleryImage, IGalleryImagesObject } from 'entityModels'

import './styles.css'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import GalleryImChevron from '../../atoms/icons/GalleryImgChevron'
import GallerySlideZoomIcon from '../../atoms/icons/GallerySlideZoomIcon'
import { useEffect, useState } from 'react'
import { LocationIcon } from '../../atoms/icons/components'

const SlideWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 2px;
    height: 100%;
    max-height: 80vh;

    @media (max-width: 1180px) {
        margin-top: 160px;
        width: 68vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1180px) and (max-width: 1295px) {
        width: 64vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1295px) and (max-width: 1400px) {
        width: 55vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1400px) and (max-width: 1550px) {
        width: 54vw;
        max-width: 1100px;
        height: 75vh;
        max-height: 550px;
    }

    @media (min-width: 1550px) and (max-width: 1600px) {
        width: 1200px;
        height: 905px;
    }

    @media (min-width: 1600px) and (max-width: 1810px) {
        width: 1300px;
        height: 1000px;
    }

    @media (min-width: 1810px) {
        width: 1492px;
        height: 1100px;
        max-height: 70vh;
    }
`

const SlideImageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    background: white;
    position: relative;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const SlideImage = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const TextBox = styled.div`
    z-index: 6;
    width: 100%;
    height: max-content;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-family: Lato;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: max-content;
    padding-right: 30px;
    text-align: left !important;
    color: black;
    font-size: 13px;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const CaptionCol = styled.div`
    font-family: Lato;
    padding-right: 10px;
    min-width: 230px;
    padding-left: 20px;

    font-size: 12px;

    @media (max-width: 1450px) {
        font-size: 11px;
    }

    @media ${device.large_desktop} {
        font-size: 14px;
    }
`

const Line = styled.div`
    height: 50px;
    justify-self: center !important;
    width: 1px;
    background-color: #b3b3b3;
`

const NextBlock = styled(Link)`
    position: absolute;
    right: -130px;

    @media (max-width: 1450px) {
        right: -100px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        right: -100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    z-index: 1;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`
const NextBlockNotLink = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    width: 10vw;
    cursor: alias;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 50px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    opacity: 0.5;
`
const AbsoluteIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`
const PrevBlock = styled(Link)`
    position: absolute;
    left: -120px;

    @media (max-width: 1450px) {
        left: -85px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        left: -94px;
    }
    height: 50px;
    width: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`
const PrevBlockNotLink = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 10vw;
    cursor: alias;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 50px;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;
    opacity: 0.5;
`
const PaddingSpan = styled.span`
    padding-left: 4px;
    color: #1a1a1a;
    font-size: 12px;
`

const SpanNoPadding = styled.span`
    color: #1a1a1a;
    font-size: 12px;
`

interface IProps extends RouteComponentProps<any> {
    currentImageData: IGalleryImage
    closeGallery: any
    generateRequestedSlide?: any
    imagesData: IGalleryImagesObject
    isSomethingLoading: boolean
    prevUrl: string
    nextUrl: string
}

const ShowroomGallerySlide: React.FunctionComponent<IProps> = (
    props: IProps
) => {
    const [isZoomed, setIsZoomed] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleZoomChange = (shouldZoom: boolean) => {
        setIsZoomed(shouldZoom)
    }

    const { currentImageData, nextUrl, prevUrl } = props

    const genUrl = (e?: any) => {
        if (e) {
            e = e || window.event

            if (e.keyCode === 37 && prevUrl !== '/none') {
                props.history.push(prevUrl)
            } else if (e.keyCode === 39 && nextUrl !== '/none') {
                props.history.push(nextUrl)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', genUrl, { passive: true })

        return () => {
            document.removeEventListener('keydown', genUrl)
        }
    }, [prevUrl, nextUrl])

    return (
        <div style={{ position: 'fixed' }}>
            <SlideWrapper>
                {/* this.manageOverallMenus(); */}

                {props.nextUrl !== '/none' ? (
                    <NextBlock to={nextUrl}>
                        <GalleryImChevron />
                    </NextBlock>
                ) : (
                    <NextBlockNotLink>
                        <GalleryImChevron />
                    </NextBlockNotLink>
                )}

                {props.prevUrl !== '/none' ? (
                    <PrevBlock to={prevUrl}>
                        <GalleryImChevron direction="left" />
                    </PrevBlock>
                ) : (
                    <PrevBlockNotLink>
                        <GalleryImChevron direction="left" />
                    </PrevBlockNotLink>
                )}

                <SlideImageBox>
                    <ControlledZoom
                        isZoomed={isZoomed}
                        onZoomChange={handleZoomChange}
                        wrapStyle={{
                            outline: 'none',
                            borderRadius: '0px',
                            width: '100%',
                            height: ' 100%',
                        }}
                    >
                        <AbsoluteIcon
                            style={{
                                display: isZoomed ? 'none' : 'block',
                            }}
                        >
                            <GallerySlideZoomIcon />
                        </AbsoluteIcon>
                        <div
                            style={{
                                width: '100%',
                                height: ' 100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {isLoading === true && (
                                <SkeletonAbsolute
                                    isthingloading={isLoading}
                                    darkTheme={false}
                                />
                            )}
                            <SlideImage
                                src={grey_placeholder}
                                srcSet={
                                    currentImageData &&
                                    currentImageData.large_srcset
                                        ? currentImageData.large_srcset
                                        : undefined
                                }
                                onLoad={() => setIsLoading(false)}
                                alt="car"
                            />
                        </div>
                    </ControlledZoom>
                </SlideImageBox>
                <TextBox>
                    <Row>
                        <Row>
                            <Col>
                                <div
                                    style={{
                                        paddingBottom: '3px',
                                        color: '#1A1A1A',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {currentImageData &&
                                    currentImageData.uploader
                                        ? currentImageData.uploader
                                        : ''}
                                </div>

                                <Row>
                                    ©
                                    <PaddingSpan>
                                        {currentImageData &&
                                        currentImageData.credits
                                            ? currentImageData.credits
                                            : 'Unknown'}
                                    </PaddingSpan>
                                </Row>

                                <Row>
                                    <div
                                        style={{
                                            transform: 'translate(-1px, 1px)',
                                        }}
                                    >
                                        <LocationIcon size="14" />
                                    </div>
                                    <SpanNoPadding>
                                        {currentImageData &&
                                        currentImageData.location
                                            ? currentImageData.location
                                            : 'Unknown'}
                                    </SpanNoPadding>
                                </Row>
                            </Col>
                        </Row>
                        <Line />
                        <CaptionCol style={{ width: '78%' }}>
                            {currentImageData && currentImageData.caption
                                ? currentImageData.caption
                                : ''}
                        </CaptionCol>
                    </Row>
                </TextBox>
            </SlideWrapper>
        </div>
    )
}

export default withRouter(ShowroomGallerySlide)
