import * as React from 'react'
import { connect } from 'react-redux'
import BasicAccountPrivateInfoFormMobile from '../basic/basicAccountPrivateInfoFormMobile'
import ChangePasswordAccountFormMobile from '../changePasswordForm/changePasswordFormMobile'
import { RootState } from 'typesafe-actions'

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

export interface Props {
    item: any
    toggle: any
    submit_func: any
    setToResetPasswordClicked: any
    hasResetPasswordBeenClicked: boolean
    userLoggedIn: any
    request_reset_password_func: any
}

class AccountPrivateInfoManagerMobileForm extends React.Component<Props, {}> {
    renderForm(
        item: any,
        toggle: any,
        submit_func: any,
        setToResetPasswordClicked: any,
        hasResetPasswordBeenClicked: boolean,
        userLoggedIn: any,
        request_reset_password_func: any
    ) {
        switch (item.id) {
            case 'password':
                return (
                    <ChangePasswordAccountFormMobile
                        toggle={toggle}
                        submitFunc={request_reset_password_func}
                        setToResetPasswordClicked={setToResetPasswordClicked}
                        hasResetPasswordBeenClicked={
                            hasResetPasswordBeenClicked
                        }
                        userLoggedIn={userLoggedIn}
                    />
                )

            default:
                return (
                    <BasicAccountPrivateInfoFormMobile
                        toggle={toggle}
                        item={item}
                        submitFunc={submit_func}
                        userLoggedIn={userLoggedIn}
                    />
                )
        }
    }
    render() {
        return this.renderForm(
            this.props.item,
            this.props.toggle,
            this.props.submit_func,
            this.props.setToResetPasswordClicked,
            this.props.hasResetPasswordBeenClicked,
            this.props.userLoggedIn,
            this.props.request_reset_password_func
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(AccountPrivateInfoManagerMobileForm)
