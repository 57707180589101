import { IApexInterviewOverviewItemState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import read_apex_interview from '../../../../../public/assets/icons/read_apex_interview.svg'
import listen_apex_interview from '../../../../../public/assets/icons/listen_apex_interview.svg'
import { device } from '../../../../templates/displays/devices'
import { Link } from 'react-router-dom'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: var(--off-bg-color, #fafafa);

    position: relative;
    height: 100%;
    padding: 60px;
    width: 88vw;
    max-width: 1400px;

    @media ${device.ipad} {
        padding: 40px;
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    width: 100%;
    height: 100%;
`

const ColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 27vw;
    height: 100%;
    padding-right: 30px;

    @media ${device.large_desktop} {
        padding-left: 450px;
    }

    @media ${device.ipad} {
        padding-left: 310px;
    }
`

const ImgStyle = styled.img`
    border-radius: 5px;

    @media (max-width: 390px) {
        width: 80px;
    }
    position: absolute;
    left: 60px;
    top: 60px;
    height: 80%;
    width: 22vw;
    object-fit: cover;

    @media ${device.large_desktop} {
        width: 380px;
    }
    @media ${device.ipad} {
        width: 238px;
    }
`

const Name = styled(Link)`
    font-family: 'Lato';
    padding-bottom: 5px;
    font-size: 24px;
    color: var(--text-strong, #1a1a1a);
    max-width: 200px;
    line-height: 30px;
`

const Title = styled(Link)`
    font-family: 'Lato-Light';
    font-size: 22px;
    text-transform: uppercase;
    color: var(--text-strong, #1a1a1a);
    line-height: 28px;
`

const Intro = styled.div`
    font-family: 'Lato';
    padding-top: 10px;
    line-height: 26px;
    color: var(--text-strong, #1a1a1a);
`

const QuoteRowWrapper = styled.div`
    position: relative;
    font-style: italic;
    height: 100%;
`

const VerticalQuoteLine = styled.div`
    position: absolute;
    background-color: var(--text-muted, #b3b3b3);
    width: 1px;
    height: 100%;
    left: 0px;
    top: 0px;
    bottom: 0px;
`

const Quote = styled.div`
    padding-left: 30px;
    display: flex;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.8;
    font-size: 14px;
    color: var(--text-strong, #1a1a1a);
`

const BtnRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
`

const BtnRowBtn = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--primary, #5ec3ca);
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    color: var(--primary, #5ec3ca) !important;
    :focus {
        color: var(--primary, #5ec3ca) !important;
    }
    font-family: Lato-Light;
    font-size: 14px;
    width: 130px;
    height: 40px;

    cursor: pointer;
    transition: all 200ms;

    :hover {
        transition: all 200ms;
        filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
    }
`
const TitleRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const VerticalTitleLine = styled.div`
    background-color: var(--text-strong, #1a1a1a);
    width: 1px;
    height: 24px;
    margin-right: 20px;
    margin-left: 20px;

    @media ${device.ipad} {
        height: 55px;
    }
`
type Props = {
    latest_interview: IApexInterviewOverviewItemState
}

class ApexLatestInterviewDesktop extends React.Component<Props, {}> {
    render() {
        let { latest_interview } = this.props

        return (
            <Container>
                <RowWrapper>
                    <Link to={`/apex/interviews/${latest_interview.uid}`}>
                        <ImgStyle
                            src={
                                latest_interview.headshot &&
                                latest_interview.headshot.url
                                    ? latest_interview.headshot.url
                                    : ''
                            }
                            alt={latest_interview.title}
                        />
                    </Link>
                    <ColumnWrapper>
                        <TitleRow>
                            <Name
                                to={`/apex/interviews/${latest_interview.uid}`}
                            >
                                {latest_interview.name}
                            </Name>
                            <VerticalTitleLine />

                            <Title
                                to={`/apex/interviews/${latest_interview.uid}`}
                            >
                                {latest_interview.title}
                            </Title>
                        </TitleRow>
                        <Intro>{latest_interview.introduction}</Intro>
                        <div style={{ paddingTop: '20px' }} />
                        <QuoteRowWrapper>
                            <VerticalQuoteLine />
                            <Quote>"{latest_interview.quote}"</Quote>
                        </QuoteRowWrapper>

                        <div style={{ paddingTop: '30px' }} />

                        <BtnRow>
                            <BtnRowBtn
                                to={`/apex/interviews/${latest_interview.uid}`}
                            >
                                <img
                                    style={{
                                        height: '15px',
                                        width: '15px',
                                        marginRight: '6px',
                                    }}
                                    src={read_apex_interview}
                                    alt="Read"
                                />
                                <div>Read</div>
                            </BtnRowBtn>

                            <div style={{ paddingRight: '16px' }} />

                            <BtnRowBtn
                                to={`/apex/podcasts#${latest_interview.uid}_d`}
                            >
                                <img
                                    style={{
                                        height: '15px',
                                        width: '15px',
                                        marginRight: '6px',
                                    }}
                                    src={listen_apex_interview}
                                    alt="Listen"
                                />
                                <div>Listen</div>
                            </BtnRowBtn>
                        </BtnRow>
                    </ColumnWrapper>
                </RowWrapper>
            </Container>
        )
    }
}

export default ApexLatestInterviewDesktop
