import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<IHBStyle>`
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(0.9);
    ${(props) => props.isOpen && 'transform: translateY(3px);'}
    transition: transform 0.3s;
`

type IHBStyle = {
    isOpen?: boolean
    color?: string
}

const HamburgerLine1 = styled.div<IHBStyle>`
    height: 2px;
    width: 24px;
    background-color: ${(props) => props.color ?? '#616161'};
    ${(props) =>
        props.isOpen
            ? 'transform: rotate(45deg) translateY(1px) translateX(-1px);'
            : 'transform: scale(0.6, 1) translateX(8px);'}
    transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    border-radius: 1px;
    ${(props) => !props.isOpen && 'align-self: flex-end;'}
`

const HamburgerLine2 = styled.div<IHBStyle>`
    background-color: ${(props) => props.color ?? '#616161'};
    height: 2px;
    width: 24px;
    ${(props) =>
        props.isOpen ? 'transform: scale(0)' : 'transform: scale(1)'};
    transition: transform 0.3s;

    ${(props) => !props.isOpen && 'transition-delay: 0.2s;'};
    border-radius: 1px;
`

const HamburgerLine3 = styled.div<IHBStyle>`
    background-color: ${(props) => props.color ?? '#616161'};
    height: 2px;
    width: 24px;
    ${(props) =>
        props.isOpen
            ? 'transform: rotate(-45deg) translateX(2px) translateY(-4px);'
            : 'transform: scale(0.6, 1) translateX(-8px);'}
    transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    border-radius: 1px;
`

const Padding = styled.div<IHBStyle>`
    height: 5px;
    ${(props) => props.isOpen && 'height: 0'};
    transition: height 0.3s;
`

interface Props {
    isOpen: boolean
    toggle: any
    color?: string
}

const HamburgerIcon: React.FunctionComponent<Props> = (props) => {
    const { isOpen, toggle, color } = props
    return (
        <Wrapper isOpen={isOpen} onClick={toggle} color={color}>
            <HamburgerLine1 isOpen={isOpen} color={color} />
            <Padding isOpen={isOpen} color={color} />
            <HamburgerLine2 isOpen={isOpen} color={color} />
            <Padding isOpen={isOpen} color={color} />
            <HamburgerLine3 isOpen={isOpen} color={color} />
        </Wrapper>
    )
}

export default HamburgerIcon
