import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { IReminder } from 'entityModels'

import { motion } from 'framer-motion'
import styled from 'styled-components'
import blue_cross from '../../../public/assets/icons/blue_cross.svg'

const dispatchProps = {}

const RemovalIcon = styled(motion.img)`
    height: 15px;
    cursor: pointer;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 5px;
`

const ReminderText = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    font-size: 16px;
`

function mapStateToProps(state: RootState) {
    return {}
}

type Props = {
    reminder: IReminder
    deleteReminder: any
    taskDueDate: string
}

const RemindersViewManager: React.FunctionComponent<Props> = (props) => {
    const { reminder, deleteReminder } = props

    return (
        <Row>
            <ReminderText>
                {!reminder.relativeTime && 'on'} {reminder.displayDateText}{' '}
                {reminder.relativeTime && (
                    <span
                        style={{
                            fontFamily: 'Lato-Light',
                            paddingLeft: '5px',
                        }}
                    >
                        ({reminder.date})
                    </span>
                )}
            </ReminderText>
            <RemovalIcon
                data-attr="remove_reminder"
                src={blue_cross}
                onClick={() => deleteReminder(reminder.id)}
                whileTap={{ scale: 1.1 }}
            />
        </Row>
    )
}

export default connect(mapStateToProps, dispatchProps)(RemindersViewManager)
