import { IApiCreateProposalCodes_args } from 'ApiInterfaces'
import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const createProposalCodesRequest = (p: IApiCreateProposalCodes_args) => {
    let rpq: string = JSON.stringify(p)
    const requestOptions = {
        method: 'POST',
        headers: { ...customHeader },
        body: rpq,
    }

    return fetch(urls.create_request_proposal_codes, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (!response.ok) {
                        apiSentryErrorHandler(
                            data,
                            'Create proposal code error',
                            rpq
                        )
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }

                    return data
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

// after admin approved request made and generated them

export const getProposalCodes = () => {
    const requestOptions = {
        method: 'GET',
    }
    return fetch(urls.get_proposal_codes_approved, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (!response.ok) {
                        apiSentryErrorHandler(
                            data,
                            'Create proposal codes error'
                        )
                        return Promise.reject(response)
                    }

                    return data
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

// user redeems a proposal code

export const redeemProposalCode = (proposalCode: string) => {
    const requestOptions = {
        method: 'POST',
        headers: { ...customHeader },
        body: JSON.stringify({ proposalCode }),
    }

    return fetch(urls.redeem_proposal_code(proposalCode), requestOptions)
        .then((error) => error)
        .catch((e: any) => {
            apiSentryErrorHandler(e, 'Redeem proposal code error')
            return Promise.reject(e)
        })
}

// const obj = {
//     createProposalCodesRequest,
//     getProposalCodes,
//     redeemProposalCode,
// }

// export default obj
