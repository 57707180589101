import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import chevron_to_left from '../../../../public/assets/navigation/chevron_to_left.svg'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { showroomActions } from '../../../../redux/showroom/reducer'
import {
    IShowroomFiltersState,
    IFilterID,
} from '../../../../redux/showroom/types'

import { useEffect, useState } from 'react'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import ShowroomFiltersSearchBodyMobile from '../../../organisms/searchbar/showroom/filterContent/showroomFilterSearchBodyMobile'
import ScrollToTop from '../../../templates/displays/scrollToTop'
import { areArraysValuesEqual } from '../../../../helpers/arrayChecks/are2arraysEqual'
import AreObjectsEqual from '../../../../helpers/objChecks'

const PageWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
`

const TopBar = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: 64px;
    position: fixed;
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    left: 0;
    right: 0;
    top: 0;
    z-index: 5;
    padding-left: 24px;
    padding-right: 24px;
`

const EndBtn = styled.div`
    letter-spacing: 5e-5em;
    font-family: Lato;
    font-size: 16px;
    color: #1a1a1a;
`
const ReturnBtn = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Lato;
    font-size: 18px;
`

const Chevron = styled.img`
    filter: invert(42%) sepia(4%) saturate(14%) hue-rotate(316deg)
        brightness(0%) contrast(84%);
    height: 13px;
`

const ShowroomSearchFiltersMobile: React.FunctionComponent = () => {
    const dispatch = useAppDispatch()

    let edFil: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    let openedFilterID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    let activeFiltersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.filters
    })

    let location = useLocation()

    let history = useHistory()

    const params = new URLSearchParams(location.search)

    let filterIDParan: string | null = params.get('filter')

    let soldParams: any = params.get('sold')

    const [initVal, setInitVal] = useState<string[] | undefined>(undefined)

    useEffect(() => {
        if (!openedFilterID && filterIDParan) {
            // @ts-ignore
            dispatch(showroomActions.setFilterOpen(filterIDParan))
        }
    }, [])

    useEffect(() => {
        if (
            openedFilterID &&
            // @ts-ignore
            edFil[openedFilterID].type === 'multiselect'
        ) {
            // @ts-ignore
            setInitVal(edFil[openedFilterID].values)
        }
        if (
            openedFilterID === 'make' &&
            (!edFil.make.options || edFil.make.isActive === true)
        ) {
            dispatch(
                showroomActions.searchShowroomRequest({
                    states: soldParams ? 'SOLD' : 'PUBLISHED',
                    only_for_filter_makes_results: true,
                })
            )
        }
    }, [])

    const cancelMultiSelect: any = () => {
        if (initVal && openedFilterID) {
            dispatch(
                showroomActions.setMultiSelect_filter_replace_values({
                    // @ts-ignore
                    id: openedFilterID,
                    values: initVal,
                })
            )
        } else {
            dispatch(
                showroomActions.removeFilterRequest({
                    // @ts-ignore
                    id: openedFilterID,
                })
            )
        }
    }

    let cancel = () => {
        // @ts-ignore
        if (openedFilterID && edFil[openedFilterID].type === 'multiselect') {
            cancelMultiSelect()
        }
    }

    let filterValidationError = useAppSelector(
        (state) => state.showroom.filterValidationError
    )

    let direct_values_ids = [
        'make',
        'model',
        'body_type',
        'colour',
        'types_of_sale',
        'keyword',
    ]

    let isDisabled = () => {
        let res = false
        if (openedFilterID) {
            let current = activeFiltersState[openedFilterID]

            let newObj = edFil[openedFilterID]

            if (direct_values_ids.includes(openedFilterID)) {
                let isArrEqual = areArraysValuesEqual(
                    // @ts-ignore
                    newObj.values,
                    // @ts-ignore
                    current.values
                )
                if (isArrEqual) {
                    res = true
                }
            } else {
                let isEqual = AreObjectsEqual(current, newObj)

                if (isEqual) {
                    res = true
                }
            }
        }

        return res
    }

    return (
        <>
            <ScrollToTop>
                <IpadAndMobileDisplay>
                    <PageWrapper>
                        <TopBar>
                            <ReturnBtn
                                role="button"
                                onClick={() => {
                                    cancel()
                                    // cancel
                                    // below not needed anymore
                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                    if (soldParams) {
                                        history.replace(
                                            '/showroom/filters?sold=true'
                                        )
                                    } else {
                                        history.replace('/showroom/filters')
                                    }
                                }}
                            >
                                <Chevron src={chevron_to_left} />
                                <div style={{ paddingLeft: '20px' }} />
                                <div>
                                    {openedFilterID
                                        ? edFil[openedFilterID].name
                                        : ''}
                                </div>
                            </ReturnBtn>
                            <EndBtn
                                role="button"
                                onClick={() => {
                                    openedFilterID &&
                                        dispatch(
                                            showroomActions.removeFilterRequest(
                                                {
                                                    id: openedFilterID,
                                                }
                                            )
                                        )

                                    // need to re-search
                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                }}
                            >
                                Clear
                            </EndBtn>
                        </TopBar>
                        <div style={{ paddingTop: '80px' }} />
                        <ShowroomFiltersSearchBodyMobile />

                        <div
                            style={{
                                position: 'fixed',
                                bottom: '30px',
                                left: '30px',
                                right: '30px',
                            }}
                        >
                            <ButtonAtom
                                theme="wide-mobile-blue-bg"
                                height="50px"
                                disabled={
                                    isDisabled() === true
                                        ? true
                                        : filterValidationError &&
                                          openedFilterID &&
                                          filterValidationError[openedFilterID]
                                        ? true
                                        : false
                                }
                                onClick={() => {
                                    if (soldParams) {
                                        history.replace(
                                            '/showroom/filters?sold=true'
                                        )
                                    } else {
                                        history.replace('/showroom/filters')
                                    }

                                    dispatch(
                                        showroomActions.searchShowroomRequest({
                                            states: soldParams
                                                ? 'SOLD'
                                                : 'PUBLISHED',
                                            only_for_filter_results: true,
                                        })
                                    )
                                }}
                            >
                                Apply filters
                            </ButtonAtom>
                        </div>
                    </PageWrapper>
                    <div style={{ paddingBottom: '20px' }} />
                </IpadAndMobileDisplay>
            </ScrollToTop>
        </>
    )
}

export default ShowroomSearchFiltersMobile
