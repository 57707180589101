import { IConfirmationFunctionality, IIconData } from 'expandingListPropsTypes'
import * as React from 'react'
import styled from 'styled-components'
import ExpandingListItemDesktop from '../../atoms/expandingListItem/expandingListItemDesktop'

type IStyledProps = {
    customWidth?: string
}

const Wrap = styled.section<IStyledProps>`
    width: ${(props) => (props.customWidth ? props.customWidth : '100%')};
    height: 100%;
`

interface IData {
    id: string
    text: string
    dateFinished?: string
    answer: string
    links?: string[]
}

interface Props {
    list: IData[]
    width?: string
    theme?: 'upvote' | 'check'
    iconData?: IIconData
    confirmationFunctionality?: IConfirmationFunctionality
}

const ExpandingItemsListDesktop: React.SFC<Props> = (props) => {
    // const urlRegex = /(https?:\/\/[^\s]+)/g
    // let textize = (el: string) => {

    //     return el.replace(urlRegex,  ({url}) => {
    //         return <a href={url}>{url}</a>
    //     })
    // }

    // const textize = (content: string): React.ReactNodeArray => {
    //     let n = reactStringReplace(
    //         content,
    //         /(https?:\/\/[^\s]+)/g,
    //         (match, i) => <div>{match}</div>
    //     )
    //     return n
    // }

    return (
        <Wrap customWidth={props.width ? props.width : undefined}>
            {props.list.map((item, i) => (
                <React.Fragment key={`${item.id}_${i}_desktop`}>
                    <ExpandingListItemDesktop
                        key={item.id}
                        id={item.id}
                        text={item.text}
                        dateFinished={item.dateFinished}
                        answer={item.answer}
                        links={item.links}
                        theme={props.theme && props.theme}
                        iconData={props.iconData ? props.iconData : undefined}
                        confirmationFunctionality={
                            props.confirmationFunctionality
                                ? props.confirmationFunctionality
                                : undefined
                        }
                    />
                </React.Fragment>
            ))}
        </Wrap>
    )
}

export default ExpandingItemsListDesktop
