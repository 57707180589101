export type ITheme = 'light' | 'dark'

export type IColours = {
    primary?: string
    primary_80?: string
    primary_30?: string
    primary_04?: string
    primary_08?: string
    primary_lighter_and_brighter?: string
    error?: string
    warning?: string
    text_default?: string
    text_darker?: string
    text_muted?: string
    text_strong?: string
    text_strongest?: string
    border_muted?: string
    border_muted_subtle?: string
    border_muted_lighter?: string
    border_muted_primary?: string
    border_muted_neutral?: string
    border_neutral?: string
    border_neutral_strong?: string
    background_default?: string
    background_neutral?: string
    background_neutral_subtle?: string
    background_neutral_soft?: string
    background_primary_subtle?: string
    background_primary_soft?: string
    background_neutral_subtle_light?: string
    background_neutral_subtle_lighter?: string
    background_disabled_neutral?: string
    background_neutral_active?: string
    background_primary_soft_16?: string
    background_neutral_overlay_40?: string
    background_neutral_overlay_02?: string
    background_neutral_backdrop_70?: string
    background_neutral_backdrop_97?: string
    service_label?: string
    maintenance_label?: string
    restoration_label?: string
    admin_label?: string
    insurance_label?: string
    history_label?: string
    events_label?: string
    other_label?: string
    shadow_04: string
    shavow_08: string
}

export type IThemeColours = {
    [key in ITheme]: IColours
}

const colours = {
    light: {
        primary: '#5EC3CA', // rgb name: rgb(94, 195, 202)
        primary_80: 'rgba(94, 195, 202, 0.80)',
        primary_50: 'rgba(94, 195, 202, 0.5)',
        primary_30: 'rgba(94, 195, 202, 0.3)',
        primary_01: 'rgba(94, 195, 202, 0.01)',
        primary_04: 'rgba(94, 195, 202, 0.04)',
        primary_08: 'rgba(94, 195, 202, 0.08)',
        primary_09: 'rgba(94, 195, 202, 0.09)',
        primary_16: 'rgba(94, 195, 202, 0.16)',
        primary_20: 'rgba(94, 195, 202, 0.20)',
        primary_lighter_and_brighter: '#f5feff',
        primary_strong: '#009ea4',
        primary_strong_2: '#3c7d81',
        primary_underlay: '#F2FAFB',
        error: '#DF6F6F',
        error_50: 'rgba(223, 111, 111, 0.5)',
        error_08: 'rgba(223, 111, 111, 0.08)',
        error_underlay: '#FCF3F3',
        warning: '#F9BB4B',
        text_default: '#666666',
        text_disabled: '#aaaaaa',
        text_darker: '#616161', // used on garage page for text
        text_accent: '#4f4f4f',
        text_strong: '#1a1a1a',
        text_strongest: '#000000', // used on garage page for main titles
        text_muted_light: '#b9b9b9',
        text_muted: '#b3b3b3',
        text_neutral_default: '#cccccc',
        text_neutral_default_500: '#808080',
        text_primary_muted: '#9fbfc0',
        border_muted_subtle: '#f6f6f6', // specific to car timeline bg desktop - middle line
        border_muted_lighter: '#f0f0f0',
        border_muted_light: '#ebebeb',
        border_muted: '#E5E5E5',
        border_muted_neutral: '#c0c0c0',
        border_neutral: '#a5a5a5',
        border_neutral_strong: '#8b8b8b',
        border_muted_primary: '#cedfdf',
        background_default: '#ffffff',
        background_default_20: '#ffffff33',
        background_neutral_subtle: '#FAFAFA',
        background_muted: '#f1f1f1',
        background_neutral_subtle_light: '#fafdfd', // specific to share pages
        background_neutral_subtle_lighter: '#fdfdfd', // specific to share items
        background_neutral_soft: '#F8F8F8',
        background_primary_subtle_2: '#f5fcfc',
        background_primary_subtle: '#f5fafa', // specific to car timeline bg desktop
        background_primary_soft: '#F2FAFB',
        background_primary_muted: '#e5f5f7',
        background_primary_switch: '#fafafa',
        background_neutral: '#f4f4f4',
        background_disabled_neutral: '#D9D9D9',
        background_neutral_active: '#333333',
        background_primary_soft_16: 'rgba(0, 169, 175, 0.16)', // specific to garage page active sort btn desktop
        background_neutral_overlay_40: 'rgba(26, 26, 26, 0.04)',
        background_neutral_overlay_02: 'rgba(26, 26, 26, 0.02)',
        background_neutral_backdrop_70: 'rgba(26, 26, 26, 0.70)',
        background_neutral_backdrop_97: 'rgba(250, 250, 250, 0.97)',
        gallery_image_bg: 'rgba(228, 228, 228, 0.95)',
        service_label: '#73BFA4',
        maintenance_label: '#ABAAED',
        restoration_label: '#BB6D78',
        admin_label: '#889C99',
        insurance_label: '#5D7EBF',
        history_label: '#C87FBD',
        events_label: '#FFAE53',
        other_label: '#BE8F76',
        box_shadow: 'rgba(0, 0, 0, 0.08)',
        shadow_strongest_03: 'rgba(0, 0, 0, 0.03)',
        shadow_strongest_04: 'rgba(0, 0, 0, 0.04)',
        shadow_strongest_08: 'rgba(0, 0, 0, 0.08)',
        shadow_strongest_09: 'rgba(0, 0, 0, 0.09)',
        shadow_strongest_16: 'rgba(0, 0, 0, 0.16)',
        strong_tooltip: '#4b4b4b',
        supp_Lavender_100: 'rgba(170, 111, 216, 1)',
        supp_lavender_8: '#F8F3FC',
        supp_lavender_30: '#E5D4F3',
        // insurance
        insurance_details_green: '#85C98B',
        insurance_details_green_progress_unfinished:
            'rgba(133, 201, 139, 0.16)',
        insurance_details_green_border: '#DAEFDC',
        insured_vehicles_blue: '#81D4DF',
        insured_vehicles_blue_progress_unfinished: 'rgba(129, 212, 223, 0.16)',
        insured_vehicles_blue_border: '#D9F2F5',
        everyday_vehicles_blue: '#67A0E3',
        everyday_vehicles_blue_progress_unfinished: 'rgba(103, 160, 227, 0.16)',
        everyday_vehicles_blue_border: '#D2E3F7',
        main_driver_purple: '#AA6FD8',
        main_driver_purple_progress_unfinished: 'rgba(170, 111,216,0.16)',
        main_driver_purple_border: '#E5D4F3',
        other_drivers_pink: '#E39DE4',
        other_drivers_pink_progress_unfinished: 'rgba(227,157,228, 0.16)',
        other_drivers_pink_border: '#F7E1F7',
        addresses_blue_border: '#CFEDEF',
        shadow_04: 'rgba(26, 26, 26, 0.07)',
        shavow_08: 'rgba(26, 26, 26, 0.08)',
        icons_neutral_250: '#DADADA',
        icons_neutral_300: '#B3B3B3',
        base_overlays_neutral_alpha_200: 'rgba(26, 26, 26, 0.02)',
        base_overlays_neutral_alpha_100: 'rgba(26, 26, 26, 0.04)',
        base_overlays_neutral_alpha_50: 'rgba(26, 26, 26, 0.02)',
        top_feading_overlay: 'rgb(250, 250, 250) 70%',
        top_feading_overlay_light: 'rgb(255, 255, 255) 70%',
        image_overlay_colour: 'rgba(255,255,255,0.05)',
        garage_icons_bg: '#fff',
        modal_side_sheet_pop_over: '#fff',
        slider_banner_pop_over_bg: '#fafafa',
        border_darker: '#ccc',
        icon: '#b3b3b3',
    },
    dark: {
        primary: '#5EC3CA', // rgb name: rgb(94, 195, 202)
        primary_80: 'rgba(94, 195, 202, 0.80)',
        primary_50: 'rgba(94, 195, 202, 0.5)',
        primary_30: 'rgba(94, 195, 202, 0.3)',
        primary_01: 'rgba(94, 195, 202, 0.01)',
        primary_04: 'rgba(94, 195, 202, 0.04)',
        primary_08: 'rgba(94, 195, 202, 0.08)',
        primary_09: 'rgba(94, 195, 202, 0.09)',
        primary_16: 'rgba(94, 195, 202, 0.16)',
        primary_20: 'rgba(94, 195, 202, 0.20)',
        primary_lighter_and_brighter: '#f5feff',
        primary_underlay: 'rgba(94, 195, 202, 0.16)',
        primary_strong: '#009ea4',
        primary_strong_2: '#3c7d81',
        error: '#DF6F6F',
        error_50: 'rgba(223, 111, 111, 0.5)',
        error_08: 'rgba(223, 111, 111, 0.08)',
        error_underlay: 'rgba(223, 111, 111, 0.16)',
        warning: '#F9BB4B',
        text_default: '#b3b3b3',
        text_disabled: '#4f4f4f',
        text_darker: '#b0b0b0', // used on garage page for text
        text_accent: '#4f4f4f',
        text_strong: '#fff',
        text_strongest: '#000000', // used on garage page for main titles
        text_muted_light: '#b9b9b9',
        text_muted: '#808080',
        text_neutral_default: '#8b8b8b',
        text_neutral_default_500: '#808080',
        text_primary_muted: '#9fbfc0',
        border_muted_subtle: '#333', // specific to car timeline bg desktop - middle line
        border_muted_light: '#ebebeb',
        border_muted_lighter: '#2C2C2C',
        border_muted: '#4C4C4C',
        border_muted_neutral: '#666',
        border_neutral: '#a5a5a5',
        border_neutral_strong: '#8b8b8b',
        border_muted_primary: '#cedfdf',
        background_default: '#121212',
        background_default_20: '#ffffff33',
        background_neutral_subtle: '#161A1A',
        background_neutral_subtle_light: '#fafdfd', // specific to share pages
        background_neutral_subtle_lighter: '#fdfdfd', // specific to share items
        background_neutral_soft: '#202020',
        background_primary_subtle_2: '#f5fcfc',
        background_primary_subtle: '#f5fafa', // specific to car timeline bg desktop
        background_primary_muted: '#e5f5f7',
        background_primary_soft: '#224649',
        background_primary_switch: '#224649',
        background_neutral: '#f4f4f4',
        background_muted: 'rgba(255, 255, 255, 0.08)',
        background_disabled_neutral: 'rgba(255, 255, 255, 0.08)',
        background_neutral_active: '#333333',
        background_primary_soft_16: 'rgba(0, 169, 175, 0.16)', // specific to garage page active sort btn desktop
        background_neutral_overlay_40: 'rgba(255, 255, 255, 0.08)',
        background_neutral_overlay_02: 'rgba(26, 26, 26, 0.02)',
        background_neutral_backdrop_70: 'rgba(250, 250, 250, 0.70)',
        background_neutral_backdrop_97: 'rgba(250, 250, 250, 0.97)',
        gallery_image_bg: 'rgba(26, 26, 26, 0.95)',
        service_label: '#73BFA4',
        maintenance_label: '#ABAAED',
        restoration_label: '#BB6D78',
        admin_label: '#889C99',
        insurance_label: '#5D7EBF',
        history_label: '#C87FBD',
        events_label: '#FFAE53',
        other_label: '#BE8F76',
        box_shadow: 'rgba(0, 0, 0, 0.32)',
        shadow_strongest_03: 'rgba(0, 0, 0, 0.03)',
        shadow_strongest_09: 'rgba(0, 0, 0, 0.32)',
        shadow_strongest_08: 'rgba(0, 0, 0, 0.08)',
        shadow_strongest_04: 'rgba(0, 0, 0, 0.04)',
        shadow_strongest_16: 'rgba(0, 0, 0, 0.16)',
        strong_tooltip: '#4b4b4b',
        supp_Lavender_100: 'rgba(170, 111, 216, 1)',
        supp_lavender_8: 'rgba(170, 111, 216, 0.08)',
        supp_lavender_30: '#694387',
        shadow_04: 'rgba(26, 26, 26, 0.07)',
        shavow_08: 'rgba(26, 26, 26, 0.08)',
        icons_neutral_250: '#4C4C4C',
        icons_neutral_300: '#808080',
        base_overlays_neutral_alpha_200: 'rgba(255, 255, 255, 0.16)',
        base_overlays_neutral_alpha_100: 'rgba(255, 255, 255, 0.08)',
        base_overlays_neutral_alpha_50: 'rgba(255, 255, 255, 0.04)',
        top_feading_overlay: 'rgb(34, 34, 34) 70%',
        top_feading_overlay_light: 'rgb(18, 18, 18) 70%',
        image_overlay_colour: 'rgba(0,0,0,0.7)',
        garage_icons_bg: '#2C2C2C',
        modal_side_sheet_pop_over: '#2C2C2C',
        slider_banner_pop_over_bg: '#2C2C2C',
        border_darker: '#6f6f6f',
        icon: '#ccc',
    },
}

export const insurance_colours = {
    light: {
        sections: {
            insurance_details: {
                primary_100: 'rgba(133, 201, 139, 1)',
                primary_60: '#B6DFB9',
                primary_30: '#DAEFDC',
                primary_24: '#E2F2E3',
                primary_16: 'rgba(133, 201, 139, 0.16)',
                primary_08: '#F5FBF6',
                primary_04: '#FAFDFA',
                primary_text: '#72AF77',
            },
            vehicles_to_insure: {
                primary_100: '#81D4DF',
                primary_60: '#B3E5EC',
                primary_30: '#D9F2F5',
                primary_24: '#E1F5F7',
                primary_16: 'rgba(129, 212, 223, 0.16)',
                primary_08: '#F5FCFC',
                primary_04: '#FAFDFE',
                primary_text: '#53ACB8',
            },
            everyday_vehicles: {
                primary_100: '#67A0E3',
                primary_60: '#A4C6EE',
                primary_30: '#D2E3F7',
                primary_24: '#DBE8F8',
                primary_16: 'rgba(103, 160, 227, 0.16)',
                primary_08: '#F3F7FD',
                primary_04: '#F9FBFE',
                primary_text: '#4A7FBE',
            },
            main_policy_holder: {
                primary_100: '#AA6FD8',
                primary_60: '#CCA9E8',
                primary_30: '#E5D4F3',
                primary_24: '#EBDCF6',
                primary_16: 'rgba(170, 111,216,0.16)',
                primary_08: '#F8F3FC',
                primary_04: '#FCF9FD',
                primary_text: '#AA6FD8',
            },
            drivers: {
                // primary_100: '#E39DE4',
                // primary_60: '#EEC4EF',
                // primary_30: '#F7E1F7',
                // primary_24: '#F8E7F9',
                // primary_16: 'rgba(227,157,228, 0.16)',
                // primary_08: '#FDF7FD',
                // primary_04: 'rgba(227, 157, 228, 0.04)',
                // primary_text: '#D188D2',
                primary_100: 'rgba(165, 168, 242, 1)',
                primary_60: 'rgba(165, 168, 242, 0.6)',
                primary_30: 'rgba(165, 168, 242, 0.3)',
                primary_24: 'rgba(165, 168, 242, 0.24)',
                primary_16: 'rgba(165, 168, 242, 0.16)',
                primary_08: '#F8F8FE',
                primary_04: '#FBFCFE',
                primary_text: 'rgba(165, 168, 242, 1)',
            },
            addresses: {
                primary_100: '#5EC3CA',
                primary_60: '#9EDBDF',
                primary_30: '#CFEDEF',
                primary_24: '#D8F1F2',
                primary_16: '#E5E5E5',
                primary_08: '#F2FAFB',
                primary_04: 'rgba(94, 195, 202, 0.04)',
                primary_text: '#1a1a1a',
            },
            referral_code: {
                primary_100: '#3D8374',
                primary_60: '#8BB5AC',
                primary_30: '#D0E1DE',
                primary_24: '#EFF5F4',
                primary_16: '#EFF5F4',
                primary_08: '#F2FAFB',
                primary_04: 'rgba(94, 195, 202, 0.04)',
                primary_text: '#1a1a1a',
            },
        },
    },
    dark: {
        sections: {
            insurance_details: {
                primary_100: '#85c98b',
                primary_60: '#38563B',
                primary_30: '#38563B',
                primary_24: '#4d7651',
                primary_16: 'rgba(133, 201, 139, 0.16)',
                primary_08: '#223524',
                primary_04: '#223524',
                primary_text: '#72AF77',
            },
            vehicles_to_insure: {
                primary_100: '#81D4DF',
                primary_60: '#336C74',
                primary_30: '#336C74',
                primary_24: 'rgba(129, 212, 223, 0.24)',
                primary_16: 'rgba(129, 212, 223, 0.16)',
                primary_08: '#1f4247',
                primary_04: '#1f4247',
                primary_text: '#53ACB8',
            },
            everyday_vehicles: {
                primary_100: '#67A0E3',
                primary_60: '#2E4F77',
                primary_30: '#2E4F77',
                primary_24: '#365D8C',
                primary_16: 'rgba(103, 160, 227, 0.16)',
                primary_08: '#1C314A',
                primary_04: '#1C314A',
                primary_text: '#4A7FBE',
            },
            main_policy_holder: {
                primary_100: '#AA6FD8',
                primary_60: '#694387',
                primary_30: '#694387',
                primary_24: '#794E9B',
                primary_16: 'rgba(170, 111,216,0.16)',
                primary_08: '#3B264C',
                primary_04: '#3B264C',
                primary_text: '#AA6FD8',
            },
            drivers: {
                // primary_100: '#E39DE4',
                // primary_60: '#EEC4EF',
                // primary_30: '#F7E1F7',
                // primary_24: '#F8E7F9',
                // primary_16: 'rgba(227,157,228, 0.16)',
                // primary_08: '#FDF7FD',
                // primary_04: 'rgba(227, 157, 228, 0.04)',
                // primary_text: '#D188D2',
                primary_100: 'rgba(165, 168, 242, 1)',
                primary_60: 'rgba(165, 168, 242, 0.6)',
                primary_30: '#656599',
                primary_24: '#7273AD',
                primary_16: 'rgba(165, 168, 242, 0.16)',
                primary_08: '#3c3c5c',
                primary_04: 'rgba(165, 168, 242, 0.04)',
                primary_text: 'rgba(165, 168, 242, 1)',
            },
            addresses: {
                primary_100: '#5EC3CA',
                primary_60: '#224649',
                primary_30: '#224649',
                primary_24: '#2E6063',
                primary_16: '#E5E5E5',
                primary_08: '#224649',
                primary_04: 'rgba(94, 195, 202, 0.04)',
                primary_text: '#fff',
            },
            referral_code: {
                primary_100: '#367668',
                primary_60: '#27564C',
                primary_30: '#27564C',
                primary_24: '#152E29',
                primary_16: '#EFF5F4',
                primary_08: '#F2FAFB',
                primary_04: 'rgba(94, 195, 202, 0.04)',
                primary_text: '#fff',
            },
        },
    },
}

export const history_file_colours = {
    light: {
        categories: {
            service: {
                strong: '#1F453D',
                primary_100: 'rgba(61, 131, 116, 1)',
                primary_30: 'rgba(197, 218, 213, 1)',
                primary_16: 'rgba(61, 131, 116, 0.16)',
                primary_04: 'rgba(129, 212, 223, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #EFF5F4 0%, rgba(239, 245, 244, 0.30) 100%)',
            },
            maintenance: {
                strong: '#1F453D',
                primary_100: 'rgba(165, 168, 242, 1)',
                primary_30: 'rgba(228, 229, 251, 1)',
                primary_16: 'rgba(165, 168, 242, 0.16)',
                primary_04: 'rgba(165, 168, 242, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #F8F8FE 0%, rgba(248, 248, 254, 0.30) 100%)',
            },
            restoration: {
                strong: '#1F453D',
                primary_100: 'rgba(184, 100, 150, 1)',
                primary_30: 'rgba(234, 208, 223, 1)',
                primary_16: 'rgba(184, 100, 150, 0.16)',
                primary_04: 'rgba(184, 100, 150, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #F9F3F7 0%, rgba(249, 243, 247, 0.30) 100%)',
            },
            admin: {
                strong: '#1F453D',
                primary_100: 'rgba(144, 174, 170, 1)',
                primary_30: 'rgba(222, 231, 229, 1)',
                primary_16: 'rgba(144, 174, 170, 0.16)',
                primary_04: 'rgba(165, 168, 242, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #F6F9F8 0%, rgba(246, 249, 248, 0.30) 100%)',
            },
            insurance: {
                strong: '#1F453D',
                primary_100: 'rgba(103, 160, 227, 1)',
                primary_30: 'rgba(210, 227, 247, 1)',
                primary_16: 'rgba(103, 160, 227, 0.16)',
                primary_04: 'rgba(103, 160, 227, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #F3F7FD 0%, rgba(243, 247, 253, 0.30) 100%)',
            },
            history: {
                strong: '#1F453D',
                primary_100: 'rgba(170, 111, 216, 1)',
                primary_30: 'rgba(229, 212, 243, 1)',
                primary_16: 'rgba(170, 111, 216, 0.16)',
                primary_04: 'rgba(170, 111, 216, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #F8F3FC 0%, rgba(248, 243, 252, 0.30) 100%)',
            },
            events: {
                strong: '#1F453D',
                primary_100: 'rgba(245, 162, 64, 1)',
                primary_30: 'rgba(252, 227, 198, 1)',
                primary_16: 'rgba(245, 162, 64, 0.16)',
                primary_04: 'rgba(245, 162, 64, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #FEF8F0 0%, rgba(254, 248, 240, 0.30) 100%)',
            },
            other: {
                strong: '#1F453D',
                primary_100: 'rgba(213, 131, 86, 1)',
                primary_30: 'rgba(242, 218, 204, 1)',
                primary_16: 'rgba(213, 131, 86, 0.16)',
                primary_04: 'rgba(213, 131, 86, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #FCF5F1 0%, rgba(252, 245, 241, 0.30) 100%)',
            },
            others: {
                strong: '#1F453D',
                primary_100: 'rgba(213, 131, 86, 1)',
                primary_30: 'rgba(242, 218, 204, 1)',
                primary_16: 'rgba(213, 131, 86, 0.16)',
                primary_04: 'rgba(213, 131, 86, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, #FCF5F1 0%, rgba(252, 245, 241, 0.30) 100%)',
            },
        },
    },
    dark: {
        categories: {
            service: {
                strong: '#1F453D',
                primary_100: 'rgba(61, 131, 116, 1)',
                primary_30: 'rgba(61, 131, 116, 0.3)',
                primary_16: 'rgba(61, 131, 116, 0.16)',
                primary_04: 'rgba(129, 212, 223, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(61, 131, 116, 0.16) 0%, rgba(61, 131, 116, 0.08) 100%)',
            },
            maintenance: {
                strong: '#1F453D',
                primary_100: 'rgba(165, 168, 242, 1)',
                primary_30: 'rgba(165, 168, 242, 0.3)',
                primary_16: 'rgba(165, 168, 242, 0.16)',
                primary_04: 'rgba(165, 168, 242, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(165, 168, 242, 0.16) 0%, rgba(165, 168, 242, 0.08) 100%)',
            },
            restoration: {
                strong: '#1F453D',
                primary_100: 'rgba(184, 100, 150, 1)',
                primary_30: 'rgba(184, 100, 150, 0.3)',
                primary_16: 'rgba(184, 100, 150, 0.16)',
                primary_04: 'rgba(184, 100, 150, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(184, 100, 150, 0.16) 0%, rgba(184, 100, 150, 0.08) 100%)',
            },
            admin: {
                strong: '#1F453D',
                primary_100: 'rgba(144, 174, 170, 1)',
                primary_30: 'rgba(144, 174, 170, 0.3)',
                primary_16: 'rgba(144, 174, 170, 0.16)',
                primary_04: 'rgba(165, 168, 242, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(144, 174, 170, 0.16) 0%, rgba(144, 174, 170, 0.08) 100%)',
            },
            insurance: {
                strong: '#1F453D',
                primary_100: 'rgba(103, 160, 227, 1)',
                primary_30: 'rgba(103, 160, 227, 0.3)',
                primary_16: 'rgba(103, 160, 227, 0.16)',
                primary_04: 'rgba(103, 160, 227, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(103, 160, 227, 0.16) 0%, rgba(103, 160, 227, 0.08) 100%)',
            },
            history: {
                strong: '#1F453D',
                primary_100: 'rgba(170, 111, 216, 1)',
                primary_30: 'rgba(170, 111, 216, 0.3)',
                primary_16: 'rgba(170, 111, 216, 0.16)',
                primary_04: 'rgba(170, 111, 216, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(170, 111, 216, 0.16) 0%, rgba(170, 111, 216, 0.08) 100%)',
            },
            events: {
                strong: '#1F453D',
                primary_100: 'rgba(245, 162, 64, 1)',
                primary_30: 'rgba(245, 162, 64, 0.3)',
                primary_16: 'rgba(245, 162, 64, 0.16)',
                primary_04: 'rgba(245, 162, 64, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(245, 162, 64, 0.16) 0%, rgba(245, 162, 64, 0.08) 100%)',
            },
            other: {
                strong: '#1F453D',
                primary_100: 'rgba(213, 131, 86, 1)',
                primary_30: 'rgba(213, 131, 86, 0.3)',
                primary_16: 'rgba(213, 131, 86, 0.16)',
                primary_04: 'rgba(213, 131, 86, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(213, 131, 86, 0.16) 0%, rgba(213, 131, 86, 0.08) 100%)',
            },
            others: {
                strong: '#1F453D',
                primary_100: 'rgba(213, 131, 86, 1)',
                primary_30: 'rgba(213, 131, 86, 0.3)',
                primary_16: 'rgba(213, 131, 86, 0.16)',
                primary_04: 'rgba(213, 131, 86, 0.04)',
                bg_gradient:
                    'linear-gradient(90deg, rgba(213, 131, 86, 0.16) 0%, rgba(213, 131, 86, 0.08) 100%)',
            },
        },
    },
}

export const journey_colours = {
    light: {
        section: {
            car_profile: {
                primary_50: '#F5FCFC',
                primary_100: '#EBF8FA',
                primary_200: '#E1F5F7',
                primary_300: '#D9F2F5',
                primary_400: '#B3E5EC',
                primary_500: '#81D4DF',
                primary_500_90: '#81D4DFE5',
                primary_500_80: '#81D4DFCC',
                primary_500_70: '#81D4DFB2',
                primary_500_60: '#81D4DF99',
                primary_500_50: '#81D4DF80',
                primary_500_24: '#81D4DF3D',
                primary_500_16: '#81D4DF29',
                primary_500_08: '#81D4DF14',
                primary_600: '#53ACB8',
                primary_700: '#4999A4',
                primary_900: '#336C74',
            },
            history_file: {
                primary_50: '#F3F7FD',
                primary_100: '#E7F0FB',
                primary_200: '#DBE8F8',
                primary_300: '#D2E3F7',
                primary_400: '#A4C6EE',
                primary_500: '#67A0E3',
                primary_500_90: 'rgba(103, 160, 227, 0.90)',
                primary_500_80: 'rgba(103, 160, 227, 0.80)',
                primary_500_70: 'rgba(103, 160, 227, 0.70)',
                primary_500_60: 'rgba(103, 160, 227, 0.60)',
                primary_500_50: 'rgba(103, 160, 227, 0.50)',
                primary_500_24: 'rgba(103, 160, 227, 0.24)',
                primary_500_16: '#67A0E329',
                primary_500_08: '#F3F8FD',
                primary_600: '#4A7FBE',
                primary_700: '#3F6DA4',
                primary_900: '#2E4F77',
            },
            insurance: {
                primary_50: '#F8F8FE',
                primary_100: '#F1F1FD',
                primary_200: '#E9EAFC',
                primary_300: '#E4E5FB',
                primary_400: '#C9CBF7',
                primary_500: '#A5A8F2',
                primary_500_90: '#A5A8F2E5',
                primary_500_80: '#A5A8F2CC',
                primary_500_70: '#A5A8F2B2',
                primary_500_60: '#A5A8F299',
                primary_500_50: '#A5A8F280',
                primary_500_24: '#A5A8F23D',
                primary_500_16: '#A5A8F229',
                primary_500_08: '#A5A8F214',
                primary_600: '#8E90D8',
                primary_700: '#8183C5',
                primary_900: '#656599',
            },
            share_your_car: {
                primary_50: '#F8F3FC',
                primary_100: '#F1E8F9',
                primary_200: '#EBDCF6',
                primary_300: '#E5D4F3',
                primary_400: '#CCA9E8',
                primary_500: '#AA6FD8',
                primary_500_90: '#AA6FD8E5',
                primary_500_80: '#AA6FD8CC',
                primary_500_70: '#AA6FD8B2',
                primary_500_60: '#AA6FD899',
                primary_500_50: '#AA6FD880',
                primary_500_24: '#AA6FD83D',
                primary_500_16: '#AA6FD829',
                primary_500_08: '#AA6FD814',
                primary_500_04: 'rgba(170, 111, 216, 0.04)',
                primary_600: '#9964C3',
                primary_700: '#8958AF',
                primary_900: '#694387',
            },
            past_cars: {
                primary_50: '#F9F3F7',
                primary_100: '#F4E6EE',
                primary_200: '#EEDAE6',
                primary_300: '#EAD0DF',
                primary_400: '#D4A2C0',
                primary_500: '#B86496',
                primary_500_90: 'rgba(184, 100, 150, 1)',
                primary_500_80: 'rgba(184, 100, 150, 0.80)',
                primary_500_70: 'rgba(184, 100, 150, 0.70)',
                primary_500_60: 'rgba(184, 100, 150, 0.60)',
                primary_500_50: 'rgba(184, 100, 150, 0.50)',
                primary_500_24: 'rgba(184, 100, 150, 0.24)',
                primary_500_16: 'rgba(184, 100, 150, 0.16)',
                primary_500_08: 'rgba(184, 100, 150, 0.08)',
                primary_600: '#A75B88',
                primary_700: '#945179',
                primary_900: '#733E5D',
            },
            handover: {
                primary_50: '#F5FBF6',
                primary_100: '#EBF6EC',
                primary_200: '#E2F2E3',
                primary_300: '#DAEFDC',
                primary_400: '#B6DFB9',
                primary_500: '#85C98B',
                primary_500_90: 'rgba(133, 201, 139, 0.9)',
                primary_500_80: 'rgba(133, 201, 139, 0.8)',
                primary_500_70: 'rgba(133, 201, 139, 0.7)',
                primary_500_60: 'rgba(133, 201, 139, 0.6)',
                primary_500_50: 'rgba(133, 201, 139, 0.5)',
                primary_500_24: 'rgba(133, 201, 139, 0.24)',
                primary_500_16: 'rgba(133, 201, 139, 0.16)',
                primary_500_08: 'rgba(133, 201, 139, 0.08)',
                primary_600: '#72AF77',
                primary_700: '#5C8E61',
                primary_900: '#38563B',
            },
            sell_your_car: {
                primary_50: '#EFF5F4',
                primary_100: '#E0EBE9',
                primary_200: '#D0E1DE',
                primary_300: '#C5DAD5',
                primary_400: '#8BB5AC',
                primary_500: '#3D8374',
                primary_500_90: '#3D8374E5',
                primary_500_80: '#3D8374CC',
                primary_500_70: '#3D8374B2',
                primary_500_60: '#3D837499',
                primary_500_50: '#3D837480',
                primary_500_24: '#3D83743D',
                primary_500_16: '#3D837429',
                primary_500_08: '#3D837414',
                primary_600: '#3D8374',
                primary_700: '#2F665A',
                primary_900: '#1F453D',
            },
            your_profile: {
                primary_50: '#F6F9F8',
                primary_100: '#EDF2F1',
                primary_200: '#E4ECEB',
                primary_300: '#DEE7E5',
                primary_400: '#BCCECC',
                primary_500: '#90AEAA',
                primary_500_90: 'rgba(144, 174, 170, 0.90)',
                primary_500_80: 'rgba(144, 174, 170, 0.80)',
                primary_500_70: 'rgba(144, 174, 170, 0.70)',
                primary_500_60: 'rgba(144, 174, 170, 0.60)',
                primary_500_50: 'rgba(144, 174, 170, 0.50)',
                primary_500_24: 'rgba(144, 174, 170, 0.24)',
                primary_500_16: 'rgba(144, 174, 170, 0.16)',
                primary_500_08: 'rgba(144, 174, 170, 0.08)',
                primary_600: '#7F9A97',
                primary_700: '#6F8784',
                primary_900: '#536563',
            },
        },
    },
    dark: {
        section: {
            car_profile: {
                primary_50: '#1F4247',
                primary_100: '#1F4247',
                primary_200: '#3E838C',
                primary_300: '#4999A4',
                primary_400: '#53ACB8',
                primary_500: '#81D4DF',
                primary_500_90: '#81D4DFE5',
                primary_500_80: '#81D4DFCC',
                primary_500_70: '#81D4DFB2',
                primary_500_60: '#81D4DF99',
                primary_500_50: 'rgba(129, 212, 223, 0.50)',
                primary_500_24: '#81D4DF3D',
                primary_500_16: 'rgba(129, 212, 223, 0.16)',
                primary_500_08: '#2F5359',
                primary_600: '#81D4DF',
                primary_700: '#81D4DF',
                primary_900: '#53ACB8',
            },
            history_file: {
                primary_50: '#1C314A',
                primary_100: '#1C314A',
                primary_200: '#365D8C',
                primary_300: '#2E4F77',
                primary_400: '#4A7FBE',
                primary_500: '#67A0E3',
                primary_500_90: 'rgba(103, 160, 227, 0.90)',
                primary_500_80: 'rgba(103, 160, 227, 0.80)',
                primary_500_70: 'rgba(103, 160, 227, 0.70)',
                primary_500_60: 'rgba(103, 160, 227, 0.60)',
                primary_500_50: 'rgba(103, 160, 227, 0.50)',
                primary_500_24: 'rgba(103, 160, 227, 0.24)',
                primary_500_16: 'rgba(103, 160, 227, 0.16)',
                primary_500_08: 'rgba(103, 160, 227, 0.08)',
                primary_600: '#4A7FBE',
                primary_700: '#4A7FBE',
                primary_900: '#67A0E3',
            },
            insurance: {
                primary_50: '#3C3C5C',
                primary_100: '#3C3C5C',
                primary_200: '#7273AD',
                primary_300: '#656599',
                primary_400: '#8E90D8',
                primary_500: '#A5A8F2',
                primary_500_90: '#A5A8F2E5',
                primary_500_80: '#A5A8F2CC',
                primary_500_70: '#A5A8F2B2',
                primary_500_60: '#A5A8F299',
                primary_500_50: '#A5A8F280',
                primary_500_24: '#A5A8F23D',
                primary_500_16: '#A5A8F229',
                primary_500_08: '#A5A8F214',
                primary_600: '#8E90D8',
                primary_700: '#8E90D8',
                primary_900: '#A5A8F2',
            },
            share_your_car: {
                primary_50: '#3B264C',
                primary_100: '#3B264C',
                primary_200: '#794E9B',
                primary_300: '#694387',
                primary_400: '#9964C3',
                primary_500: '#AA6FD8',
                primary_500_90: '#AA6FD8E5',
                primary_500_80: '#AA6FD8CC',
                primary_500_70: '#AA6FD8B2',
                primary_500_60: '#AA6FD899',
                primary_500_50: '#AA6FD880',
                primary_500_24: '#AA6FD83D',
                primary_500_16: '#AA6FD829',
                primary_500_08: '#AA6FD814',
                primary_500_04: 'rgba(170, 111, 216, 0.04)',
                primary_600: '#9964C3',
                primary_700: '#9964C3',
                primary_900: '#AA6FD8',
            },
            past_cars: {
                primary_50: '#512C42',
                primary_100: '#512C42',
                primary_200: '#83476B',
                primary_300: '#B86496',
                primary_400: '#A75B88',
                primary_500: '#B86496',
                primary_500_90: 'rgba(184, 100, 150, 1)',
                primary_500_80: 'rgba(184, 100, 150, 0.80)',
                primary_500_70: 'rgba(184, 100, 150, 0.70)',
                primary_500_60: 'rgba(184, 100, 150, 0.60)',
                primary_500_50: 'rgba(184, 100, 150, 0.50)',
                primary_500_24: 'rgba(184, 100, 150, 0.24)',
                primary_500_16: 'rgba(184, 100, 150, 0.16)',
                primary_500_08: 'rgba(184, 100, 150, 0.08)',
                primary_600: '#A75B88',
                primary_700: '#A75B88',
                primary_900: '#B86496',
            },
            handover: {
                primary_50: '#223524',
                primary_100: '#223524',
                primary_200: '#4D7651',
                primary_300: '#38563B',
                primary_400: '#72AF77',
                primary_500: '#85C98B',
                primary_500_90: 'rgba(133, 201, 139, 0.9)',
                primary_500_80: 'rgba(133, 201, 139, 0.8)',
                primary_500_70: 'rgba(133, 201, 139, 0.7)',
                primary_500_60: 'rgba(133, 201, 139, 0.6)',
                primary_500_50: 'rgba(133, 201, 139, 0.5)',
                primary_500_24: 'rgba(133, 201, 139, 0.24)',
                primary_500_16: 'rgba(133, 201, 139, 0.16)',
                primary_500_08: 'rgba(133, 201, 139, 0.08)',
                primary_600: '#72AF77',
                primary_700: '#72AF77',
                primary_900: '#85C98B',
            },
            sell_your_car: {
                primary_50: '#152E29',
                primary_100: '#152E29',
                primary_200: '#27564C',
                primary_300: '#1F453D',
                primary_400: '#367668',
                primary_500: '#3D8374',
                primary_500_90: '#3D8374E5',
                primary_500_80: '#3D8374CC',
                primary_500_70: '#3D8374B2',
                primary_500_60: '#3D837499',
                primary_500_50: '#3D837480',
                primary_500_24: '#3D83743D',
                primary_500_16: '#3D837429',
                primary_500_08: '#152E29',
                primary_600: '#367668',
                primary_700: '#367668',
                primary_900: '#3D8374',
            },
            your_profile: {
                primary_50: '#404E4C', //950
                primary_100: '#404E4C', //950
                primary_200: '#617674', //800
                primary_300: '#536563', //900
                primary_400: '#7F9A97', //600
                primary_500: '#90AEAA', //500
                primary_500_90: '#90AEAAE5',
                primary_500_80: '#90AEAACC',
                primary_500_70: '#90AEAAB2',
                primary_500_60: '#90AEAA99',
                primary_500_50: '#90AEAA80',
                primary_500_24: '#90AEAA3D',
                primary_500_16: '#90AEAA29',
                primary_500_08: '#90AEAA14',
                primary_600: '#7F9A97', //600
                primary_700: '#7F9A97', //600
                primary_900: '#90AEAA', //500
            },
        },
    },
}

export default colours
