import { IUserPayload } from 'IapiDataPayload'
import { IUser } from 'myModels'
import { call, fork, put, select, take } from 'redux-saga/effects'
import * as userActions from '../actions/index'
import * as garageActions from '../../entities/garages/actions'
import { IDropdownItem, IReduxError } from 'entityModels'
import { api } from '../../services'
import { ConvertUserApitoUserState } from '../../conversions/user/userConversions'
import { RootState } from 'typesafe-actions'
import { push } from 'connected-react-router'
import { resetErrorStateSuccess } from '../actions/errorActions'
import { history } from '../../store'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
// import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
import { logoutSaga } from './logout'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'
// import { setTimeZonesDropdownDataApi } from '../../localdata/dropdownData/sagas/setTimeZonesDropdownData'
import * as actions from '../actions/index'
import * as carActions from '../../entities/cars/actions'
import * as timelineActions from '../../timeline/actions'
import * as tasksActions from '../../tasks/actions/successActions'
import * as technicalInformationActions from '../../entities/technical_information/actions/actions'
import * as highlightedFactsActions from '../../entities/highlighted_facts/actions/actions'
import * as galleriesActions from '../../entities/galleries/actions/actions'
import * as searchUsersActions from '../../searchUsers/actions/successActions'
import * as attachmentsActions from '../../attachments/actions/successActions'
import { showroomActions } from '../../showroom/reducer'
import { featureFlagsActions } from '../../featureFlags/reducer'
import { customNavDataActions } from '../../localdata/customNav/reducer'
// import { GetAllUserInsuranceApplications } from '../../insuranceQuoteApplication/sagas/application/getAll'

export const state_select_user = (state: RootState) => state.user.userLoggedIn
export const time_zones_dropdowns_state = (state: RootState) =>
    state.localdata.dropdownData.timeZones

export function* selectStateUserCurrency(): any {
    let state_select_user_currency: IUser = yield select(state_select_user)

    let curr =
        state_select_user_currency &&
        state_select_user_currency.preferences.data['units'].data[
            'default_currency'
        ].user_choice_id
            ? state_select_user_currency.preferences.data['units'].data[
                  'default_currency'
              ].user_choice_id
            : undefined

    if (curr === undefined) {
        yield call(getCurrentUserDataSaga)
        return curr
    } else return curr
}

export function* selectStateUserDistanceUnit(): any {
    let state_select_user_distance_unit: IUser = yield select(state_select_user)

    let user_distance_unit =
        state_select_user_distance_unit &&
        state_select_user_distance_unit.preferences.data['units'].data[
            'distance_unit'
        ].user_choice_id
            ? state_select_user_distance_unit.preferences.data['units'].data[
                  'distance_unit'
              ].user_choice_id
            : undefined

    if (user_distance_unit === undefined) {
        yield call(getCurrentUserDataSaga)
        return user_distance_unit
    } else return user_distance_unit
}

// let state_select_insurance_applications_draft_ids = (state: RootState) =>
//     state.insuranceQuoteApplication.applications_draft_ids

export function* getCurrentUserDataSaga(context?: 'LOGIN' | 'REGISTER'): any {
    try {
        const userData: IUserPayload = yield call(
            api.userData.user_data.getCurrentUser
        )

        let time_zone_dropdown: IDropdownItem[] | null = yield select(
            time_zones_dropdowns_state
        )

        let data: IUser = ConvertUserApitoUserState(
            userData,
            time_zone_dropdown
        )

        if (data.owns_garage?.uid) {
            window?.localStorage?.setItem('garage_id', data.owns_garage?.uid)
        }
        // let insuranceDataWhichShouldNotBeUndefined: string[] | undefined =
        //     yield select(state_select_insurance_applications_draft_ids)

        // if (insuranceDataWhichShouldNotBeUndefined === undefined) {
        //     yield call(GetAllUserInsuranceApplications, {})
        // }

        yield put(userActions.default.actions.getCurrentUserDataSuccess(data))

        // Set email or any other data
        if (posthog.people !== undefined && data) {
            posthog.people.set_once({
                email: data.email && data.email.toLowerCase(),
                given_name: data && data.given_name && data.given_name,
                family_name: data && data.family_name && data.family_name,
            })
        }
    } catch (error: any) {
        if (error?.status === 503 || !error?.status) {
            let typedError: IReduxError = !error?.status
                ? ConvertToReduxError({
                      status: 503,
                  })
                : ConvertToReduxError(error)
            yield put(
                userActions.default.errorActions.getCurrentUserDataError(
                    typedError
                )
            )
            return
        } else {
            let refresh_token: string | null =
                typeof localStorage !== 'undefined' && localStorage !== null
                    ? localStorage.getItem('refresh_token')
                    : null

            let isResetPassPage =
                history.location.pathname.match(/reset-password/g) !== null ||
                window.location.pathname.match(/reset-password/g) !== null
                    ? true
                    : false

            let hasRegisterRedirect =
                history.location.search.match(/register/g) !== null ||
                window.location.search.match(/register/g) !== null
                    ? true
                    : false

            if (!isResetPassPage) {
                if (!refresh_token && hasRegisterRedirect) {
                    // if a user without a refresh token lands on /insurance?register=true from ads or website
                    yield put(push('/register'))
                } else if (error.status === 401) {
                    let payload: IUnauthHandlerPayload = {
                        functionToRepeat: getCurrentUserDataSaga,
                    }
                    yield call(unauthHandlerSaga, payload)
                } else if (error.status === 404 && refresh_token) {
                    // this is an edge case for when browser has in storage jwt and refresh token but they are not valid anymore because user doesn't exist

                    // examples:
                    // when we restart our dbs or
                    // when a user deletes his account using his phone but then tries to access the platform using a desktop where his browser storage is still in place

                    // in this case we can't call logout endpoint (so run the else case below)
                    // because it will error => we call logout with refresh token which is invalid
                    // so we cleanup all redux local state and local storage and redirect to sign in

                    // clean up Redux of all cars and garages data
                    yield put(
                        carActions.actions.cleanUpCarDataOnLogoutSuccess()
                    )
                    yield put(
                        garageActions.actions.cleanUpGaragesDataOnLogoutSuccess()
                    )
                    yield put(
                        timelineActions.actions.cleanUpTimelineDataOnLogoutSuccess()
                    )
                    yield put(tasksActions.cleanUpTasksSuccess())
                    yield put(
                        technicalInformationActions.cleanUpTechnicalInformationSuccess()
                    )
                    yield put(
                        highlightedFactsActions.cleanUpHighlightedFactsSuccess()
                    )
                    yield put(galleriesActions.cleanUpGallerySuccess())
                    yield put(searchUsersActions.cleanUpSearchUsersSuccess())
                    yield put(attachmentsActions.cleanUpAttachmentsSuccess())
                    yield put(showroomActions.cleanUpShowroom())
                    yield put(featureFlagsActions.cleanupFeatureFlags())
                    yield put(
                        actions.default.actions.resetRefreshTokenRetriesCount()
                    )
                    yield put(customNavDataActions.setInitialLandingUrl(null))

                    // clean up local storage
                    localStorage?.removeItem('refresh_token')

                    // redirect to signin

                    yield put(push('/signin'))
                } else {
                    let typedError: IReduxError | null =
                        context === 'LOGIN' || context === 'REGISTER'
                            ? null
                            : ConvertToReduxError(error)

                    yield put(
                        userActions.default.errorActions.getCurrentUserDataError(
                            typedError
                        )
                    )

                    yield put(resetErrorStateSuccess)

                    if (
                        history.location.pathname !== '/' &&
                        history.location.pathname !== '/register' &&
                        history.location.pathname !== '/login' &&
                        history.location.pathname !== '/signin' &&
                        history.location.pathname !==
                            '/share-invalid-access-public' &&
                        !isResetPassPage
                    ) {
                        yield call(logoutSaga)

                        yield put(push(`/signin`))
                    }
                }
            } else {
                yield put(
                    userActions.default.errorActions.getCurrentUserDataError(
                        null
                    )
                )
            }
        }
    }
}

function* watcherGetUserData() {
    while (true) {
        const { payload } = yield take(
            userActions.default.loadingActions.getCurrentUserDataRequest
        )
        yield call(getCurrentUserDataSaga, payload)
    }
}

const get_user_data: any[] = [fork(watcherGetUserData)]

export default get_user_data
