import styled from 'styled-components'
import { withFormik, FormikProps, Field } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../atoms/Inputfield/inputField'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import './style.css'
import { yupVar_NumberBasicSingleField } from '../yupVars'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { numberWithCommas } from '../../../../helpers/numbers'
import { useRef, useState } from 'react'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import circled_chevron_green from '../../../../public/assets/icons/circled_chevron_green.svg'
import grey_cross from '../../../../public/assets/icons/grey_cross.svg'
import FullWidthLightGreyBgButton from '../../../atoms/Button/fullWidthLightGreyBg'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import MileageTagDesktop from '../../../atoms/tags/mileageTags/mileageTagDesktop'
import { useOnClickOutside } from '../../../templates/clickOutside'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.section`
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`

const DropdownWrapper = styled.div`
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    width: 450px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--bg-color, #fff);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 4px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`
// const RegistrationNumberReminder = styled.div`
//     color: grey;
//     font-family: Lato;
//     font-size: 13px;
//     padding-top: 10px;
// `

const SmallPadding = styled.div`
    padding-top: 20px;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
`
const RowCenter = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const CustomCarMileageWrapper = styled.div`
    width: 100%;
    background-color: rgba(209, 209, 209, 0.08);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
`

const Label = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    /* transform: translateY(-3px); */

    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    font-size: 15px;
`

const CarMileage = styled.span`
    font-family: Lato-bold;
    color: var(--text-strong, #1a1a1a);
`

const UpdateCarMileage = styled.span`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    cursor: pointer;
    display: flex;
    align-items: center;
`

const CustomCarMilageInput = styled(Field)`
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--bg-color, #fff);
    font-family: Lato;
    font-size: 12px;
    border-radius: 5px;
    padding-left: 10px;
    height: 32px;
`

const IconWrapper = styled.div`
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_number_field_desktop: number | undefined
    update_car_mileage: boolean
    custom_item_edited_value_number_field_desktop: number | undefined
}

interface OtherProps {
    title: string
    value_start: number | undefined
    dataCyId?: string
    customMileageDataCyId?: string
    carMileage?: number | null | undefined
    mileageRecorded?: number | undefined
    userDistanceUnits?: string | undefined | null
    onSubmit: any
    readOnlyMode?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        errors,
        setFieldValue,
        // updateCarMileage,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        carMileage,
        userDistanceUnits,
        value_start,
        title,
        handleSubmit,
        readOnlyMode,
    } = props

    const [mileageForm, toggleMileageForm] = useState(false)
    const [customCarMileageInput, toggleCustomCarMileageInput] = useState(false)
    const [showBtnForCustomInput, toggleBtnForCustomInput] = useState(true)

    const handleAnswerChange = (e: any) => {
        setFieldValue(
            'single_item_edited_value_number_field_desktop',
            parseInt(e.target.value)
        )

        setFieldValue(
            'custom_item_edited_value_number_field_desktop',
            parseInt(e.target.value)
        )
    }

    const checkIfSubmitDisabled = (): boolean => {
        let convertedValueStart =
            userDistanceUnits &&
            value_start &&
            unitGenerator.generateFrontEndValueDistanceUnit(
                userDistanceUnits,
                value_start
            )
        let currentValue: number | undefined =
            values.single_item_edited_value_number_field_desktop

        if (
            convertedValueStart === currentValue &&
            !values.update_car_mileage
        ) {
            return true
        } else if (
            errors.single_item_edited_value_number_field_desktop !==
                undefined ||
            !values.single_item_edited_value_number_field_desktop
        ) {
            return true
        } else if (
            errors.custom_item_edited_value_number_field_desktop !== undefined
        ) {
            return true
        } else return false
    }

    const handleCustomAnswerChange = (e: any) => {
        setFieldValue(
            'custom_item_edited_value_number_field_desktop',
            parseInt(e.target.value)
        )
        setFieldValue('update_car_mileage', true)
    }

    const handleUpdatingCarMileage = (payload?: boolean) => {
        if (payload) {
            setFieldValue('update_car_mileage', payload)
        } else {
            setFieldValue('update_car_mileage', !values.update_car_mileage)
        }

        if (!showBtnForCustomInput && values.update_car_mileage) {
            toggleCustomCarMileageInput(false)
            toggleBtnForCustomInput(true)
        }
    }

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => toggleMileageForm(false))

    let convertedCarMileage =
        carMileage !== undefined && carMileage !== null && userDistanceUnits
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnits,
                  carMileage
              )
            : undefined

    let convertedMileageRecorded: number | undefined =
        userDistanceUnits && value_start !== undefined && value_start !== null
            ? unitGenerator.generateFrontEndValueDistanceUnit(
                  userDistanceUnits,
                  value_start
              )
            : undefined

    let mileageSince =
        convertedCarMileage !== undefined &&
        convertedMileageRecorded !== undefined &&
        convertedCarMileage >= convertedMileageRecorded
            ? convertedCarMileage - convertedMileageRecorded
            : undefined

    const { theme } = useThemes()

    return (
        <Wrapper ref={ref}>
            <MileageTagDesktop
                isEntryPage
                height="35px"
                mileageRecorded={convertedMileageRecorded}
                mileageSince={mileageSince}
                unit={userDistanceUnits}
                onClick={() => {
                    !readOnlyMode && toggleMileageForm(!mileageForm)
                }}
                readOnlyMode={readOnlyMode}
            />
            <SlideDownWrapper
                isOpen={mileageForm}
                initialX={-320}
                originX={'320px'}
            >
                <DropdownWrapper>
                    <div style={{ paddingTop: '8px' }} />
                    <InputField
                        theme={theme}
                        id="single_item_edited_value_number_field_desktop"
                        name="single_item_edited_value_number_field_desktop"
                        helperText={title}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={
                            values.single_item_edited_value_number_field_desktop
                        }
                        type="number"
                        font_family="Lato"
                        paddingLeft="0px"
                        width="250px"
                        isnarrow
                        fontSize="16px"
                        onChange={handleAnswerChange}
                        dataCyId={props.dataCyId}
                        placeholder="Mileage"
                    />
                    <Expander
                        height={
                            values.single_item_edited_value_number_field_desktop &&
                            errors.single_item_edited_value_number_field_desktop
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {
                                    errors.single_item_edited_value_number_field_desktop
                                }
                            </Error>
                        </Faded>
                    </Expander>
                    <SmallPadding />
                    <Label>
                        Current car mileage:{' '}
                        {convertedCarMileage !== undefined &&
                        convertedCarMileage !== null ? (
                            <span
                                style={{ fontFamily: 'Lato-bold' }}
                            >{` ${numberWithCommas({
                                numberToConvert: convertedCarMileage,
                            })} ${userDistanceUnits}`}</span>
                        ) : (
                            'unknown'
                        )}
                    </Label>
                    <SmallPadding />
                    <Expander
                        height={
                            values.single_item_edited_value_number_field_desktop &&
                            (convertedCarMileage === undefined ||
                                convertedCarMileage === null ||
                                convertedCarMileage <
                                    values.single_item_edited_value_number_field_desktop)
                                ? 'auto'
                                : 0
                        }
                    >
                        <CustomCarMileageWrapper>
                            <RowCenter>
                                <CustomAnimatedCheckboxField
                                    checked={values.update_car_mileage}
                                    onChange={() => handleUpdatingCarMileage()}
                                    hasWhiteBg
                                />

                                <div style={{ paddingLeft: '10px' }} />

                                <Label>
                                    Update my car mileage
                                    {convertedCarMileage !== undefined &&
                                        convertedCarMileage !== null && (
                                            <span>
                                                from{' '}
                                                <CarMileage>{`${numberWithCommas(
                                                    {
                                                        numberToConvert:
                                                            convertedCarMileage,
                                                    }
                                                )} ${userDistanceUnits} `}</CarMileage>
                                            </span>
                                        )}{' '}
                                    to:
                                    {showBtnForCustomInput &&
                                        !customCarMileageInput && (
                                            <Row
                                                style={{
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <CarMileage>
                                                    {values.single_item_edited_value_number_field_desktop &&
                                                        ` ${numberWithCommas({
                                                            numberToConvert:
                                                                values.single_item_edited_value_number_field_desktop,
                                                        })} ${userDistanceUnits} `}
                                                </CarMileage>
                                                <span
                                                    style={{
                                                        paddingLeft: '3px',
                                                        paddingRight: '3px',
                                                    }}
                                                >
                                                    or
                                                </span>
                                                <UpdateCarMileage
                                                    onClick={() => {
                                                        toggleCustomCarMileageInput(
                                                            true
                                                        )
                                                        toggleBtnForCustomInput(
                                                            false
                                                        )
                                                        handleUpdatingCarMileage(
                                                            true
                                                        )
                                                    }}
                                                >
                                                    set a custom value
                                                    <IconWrapper>
                                                        <img
                                                            src={
                                                                circled_chevron_green
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                marginLeft:
                                                                    '8px',
                                                            }}
                                                            alt="chev"
                                                        />
                                                    </IconWrapper>
                                                </UpdateCarMileage>{' '}
                                            </Row>
                                        )}
                                    <div style={{ paddingTop: '10px' }} />
                                    <Expander
                                        height={
                                            customCarMileageInput &&
                                            !showBtnForCustomInput &&
                                            values.update_car_mileage
                                                ? 'auto'
                                                : 0
                                        }
                                    >
                                        <Row
                                            style={{
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CustomCarMilageInput
                                                id="custom_item_edited_value_number_field_desktop"
                                                name="custom_item_edited_value_number_field_desktop"
                                                placeholder={
                                                    values.single_item_edited_value_number_field_desktop
                                                }
                                                tabindex={1}
                                                aria-live="polite"
                                                formikprops={props}
                                                value={
                                                    values.custom_item_edited_value_number_field_desktop
                                                }
                                                type="number"
                                                onChange={
                                                    handleCustomAnswerChange
                                                }
                                                data-attr={
                                                    props.customMileageDataCyId
                                                }
                                                step="0.01"
                                            />
                                            <IconWrapper
                                                onClick={() => {
                                                    toggleCustomCarMileageInput(
                                                        false
                                                    )
                                                    setFieldValue(
                                                        'custom_item_edited_value_number_field_desktop',
                                                        undefined
                                                    )
                                                    toggleBtnForCustomInput(
                                                        true
                                                    )
                                                }}
                                            >
                                                <img
                                                    src={grey_cross}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        marginLeft: '25px',
                                                    }}
                                                    alt="close"
                                                />
                                            </IconWrapper>
                                        </Row>
                                        <div
                                            style={{
                                                paddingBottom: '10px',
                                            }}
                                        />
                                    </Expander>
                                </Label>
                            </RowCenter>
                        </CustomCarMileageWrapper>
                        <SmallPadding />
                    </Expander>
                    <FullWidthLightGreyBgButton
                        text="Save"
                        onClick={(p: any) => {
                            handleSubmit(p)
                            toggleMileageForm(false)
                            toggleCustomCarMileageInput(false)
                            toggleBtnForCustomInput(true)
                        }}
                        disabled={checkIfSubmitDisabled()}
                    />
                </DropdownWrapper>
            </SlideDownWrapper>
        </Wrapper>
    )
}

const MileageEditOrCreateModeDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_number_field_desktop:
            props.value_start && props.userDistanceUnits
                ? unitGenerator.generateFrontEndValueDistanceUnit(
                      props.userDistanceUnits,
                      props.value_start
                  )
                : undefined,
        update_car_mileage: false,
        custom_item_edited_value_number_field_desktop:
            props.value_start && props.userDistanceUnits
                ? unitGenerator.generateFrontEndValueDistanceUnit(
                      props.userDistanceUnits,
                      props.value_start
                  )
                : undefined,
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: () => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                single_item_edited_value_number_field_desktop:
                    yupVar_NumberBasicSingleField(
                        values.single_item_edited_value_number_field_desktop
                    ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
        setSubmitting(false)
        if (props.userDistanceUnits) {
            let backEndValue: number | undefined =
                values.single_item_edited_value_number_field_desktop &&
                unitGenerator.generateBackEndValueDistanceUnit(
                    props.userDistanceUnits,
                    values.single_item_edited_value_number_field_desktop
                )

            let customBackEndValue: number | undefined =
                values.custom_item_edited_value_number_field_desktop &&
                unitGenerator.generateBackEndValueDistanceUnit(
                    props.userDistanceUnits,
                    values.custom_item_edited_value_number_field_desktop
                )

            props.onSubmit({
                updateCarMileage: values.update_car_mileage,
                entryMileage: backEndValue,
                customCarMileage: customBackEndValue
                    ? customBackEndValue
                    : backEndValue,
                user_distance_unit: props.userDistanceUnits,
            })

            resetForm({
                values: {
                    single_item_edited_value_number_field_desktop: undefined,
                    custom_item_edited_value_number_field_desktop: undefined,
                    update_car_mileage: false,
                },
            })
        }
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(MileageEditOrCreateModeDesktop)
