// create store for todos (basically reducer for the main final store)

import { IReduxError } from 'entityModels'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICarouselShowroomItem } from '../../services/showroom/getCarousel'

export type IShowroomLocalDataState = {
    slides: ICarouselShowroomItem[] | null
    error?: IReduxError | null
    loading?: boolean
}

const initialState: IShowroomLocalDataState = {
    slides: null,
    error: null,
    loading: false,
}

export const showroomLocalData = createSlice({
    name: 'showroomLocalData',
    initialState: initialState,
    reducers: {
        getShowroomSlides_request: (state: IShowroomLocalDataState) => {
            state.loading = true
        },

        getShowroomSlides_success: (
            state: IShowroomLocalDataState,
            action: PayloadAction<ICarouselShowroomItem[]>
        ) => {
            state.slides = action.payload
            state.loading = false
            state.error = null
        },

        getShowroomSlides_error: (
            state: IShowroomLocalDataState,
            action: PayloadAction<IReduxError>
        ) => {
            state.loading = false
            state.error = action.payload
        },
    },
})

export const showroomLocalDataActions = showroomLocalData.actions

export default showroomLocalData.reducer
