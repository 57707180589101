type Props = {
    size?: string
    color?: string
}

const UpcomingIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.81818 5.38635C3.15196 5.38635 2.75 5.85557 2.75 6.27272V19.3636C2.75 19.7808 3.15196 20.25 3.81818 20.25H20.1818C20.848 20.25 21.25 19.7808 21.25 19.3636V6.27272C21.25 5.85557 20.848 5.38635 20.1818 5.38635H3.81818ZM1.25 6.27272C1.25 4.88239 2.4761 3.88635 3.81818 3.88635H20.1818C21.5239 3.88635 22.75 4.88239 22.75 6.27272V19.3636C22.75 20.754 21.5239 21.75 20.1818 21.75H3.81818C2.4761 21.75 1.25 20.754 1.25 19.3636V6.27272Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.29564 2.25017L7.29431 7.0229L5.79431 7.02248L5.79564 2.24976L7.29564 2.25017Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.2048 2.25017L18.2034 7.0229L16.7034 7.02248L16.7048 2.24976L18.2048 2.25017Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22 10.0499H2V8.54993H22V10.0499Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default UpcomingIcon
