import { call, fork, put, select, take, join } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    ICustomErrorData,
    IList,
    IReduxError,
    IwriteMultipleTechnicalInformationPayloadReq,
} from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from '../../reducer'
import * as unitGenerator from '../../../../helpers/units/unitConversion'

import {
    gen_draft_differing_self_replicating_entities_multiple,
    gen_draft_differing_values_to_patch,
} from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'

import {
    entityDeletionSaga,
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import { ICarID } from './carMileageParking_submit'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from '../application/get'
import { writeMultipleTechnicalInformationSaga } from '../../../entities/technical_information/sagas/writeMultipleTechnicalInformation'
import posthog from 'posthog-js'
import { message } from 'antd'

let tech_info_ids = [
    'make',
    'model',
    'year',
    'engine_capacity',
    'registration_number',
    'engine_size',
    'transmission',
    'drive_side',
    'drive_train',
    'body_type',
    'fuel_type',
]

export function* Submit_car_techSpecifications(p: ICarID): any {
    posthog.capture('Update insurance application car tech info')

    let all_existing: IInsuranceQuoteApplication = yield select(
        insurance_application_existing_state_select
    )

    let external_car_ids = all_existing.external_car_ids

    let car_is_external: boolean =
        external_car_ids && external_car_ids.indexOf(p.carid) !== -1
            ? true
            : false

    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let application_has_deletions = false
        let application_has_patch = false

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let draft = {
            ...all_draft.vehicles[p.carid],
        }
        let existing = {
            ...all_existing.vehicles[p.carid],
        }

        let dataToSendToPatch: IAnyObject | undefined = undefined

        if (draft.tech_info) {
            dataToSendToPatch = gen_draft_differing_values_to_patch({
                existing: existing.tech_info ?? {},
                draft: draft.tech_info ?? {},
            })
        }

        const determine_unit = (v?: 'liters' | 'cc' | 'ci') => {
            switch (v) {
                case 'liters':
                    return 'liters'
                case 'cc':
                    return 'cubic_centimeters'
                case 'ci':
                    return 'cubic_inches'
                default:
                    return 'cubic_centimeters'
            }
        }

        if (dataToSendToPatch && dataToSendToPatch[`engine_capacity`]) {
            if (draft.tech_info) {
                let unit_for_conv = determine_unit(
                    draft.tech_info[`engine_capacity-unit`]
                )
                let answer = unitGenerator.generateBackEndValueFuelUnit(
                    unit_for_conv,
                    dataToSendToPatch[`engine_capacity`]
                )
                if (
                    (typeof answer === 'number' && answer > 100000) ||
                    (typeof answer === 'string' && parseInt(answer) > 100000)
                ) {
                    message.error(
                        'Please add a realistic value for the engine size.'
                    )
                    insuranceActions.reset_insurance_car_tech_info_engine_size({
                        carid: p.carid,
                    })
                    delete dataToSendToPatch[`engine_capacity`]
                } else {
                    dataToSendToPatch[`engine_capacity`] = answer
                }
            }
        }

        let entities_info: any = undefined

        if (draft.entities) {
            let entitiies_draft = {
                modifications: draft.entities.modifications,
            }

            let entitiies_existing = {
                modifications: existing.entities.modifications,
            }

            entities_info =
                gen_draft_differing_self_replicating_entities_multiple({
                    draft: entitiies_draft,
                    existing: entitiies_existing,
                })

            if (entities_info.data_to_patch_by_type) {
                dataToSendToPatch = dataToSendToPatch
                    ? {
                          ...dataToSendToPatch,
                          ...entities_info.data_to_patch_by_type,
                      }
                    : { ...entities_info.data_to_patch_by_type }
            }
        }

        // console.log('data to patch l 62', dataToSendToPatch)
        // call api to patch as thats all the data merged we want to patch
        let dataCarUpdateList: IList[] = []

        if (!car_is_external) {
            let ids_to_update =
                dataToSendToPatch && Object.keys(dataToSendToPatch)

            if (ids_to_update) {
                for (let a = 0; a < ids_to_update.length; a++) {
                    let field_idd = ids_to_update[a]
                    if (
                        tech_info_ids.indexOf(field_idd) !== -1 &&
                        draft &&
                        draft.tech_info &&
                        draft.tech_info[field_idd]
                    ) {
                        dataCarUpdateList = [
                            ...dataCarUpdateList,
                            {
                                id: ids_to_update[a],
                                text: '',
                                answer:
                                    field_idd === 'model' ||
                                    field_idd === 'make'
                                        ? draft.tech_info[field_idd]?.name
                                        : dataToSendToPatch
                                        ? dataToSendToPatch[field_idd]
                                        : undefined,
                            },
                        ]
                    }
                }

                // update car
                if (dataCarUpdateList.length > 0) {
                    let payload: IwriteMultipleTechnicalInformationPayloadReq =
                        {
                            data: dataCarUpdateList,
                            car_id: p.carid,
                            tech_info_id: `hf-${p.carid}`,
                        }

                    // console.log(' payload passed:', payload)
                    yield call(writeMultipleTechnicalInformationSaga, payload)
                }
            }
        }
        let carData: IAnyObject = {
            ...dataToSendToPatch,
        }

        if (!car_is_external) {
            carData = {
                ...carData,
                external_id: p.carid,
            }
        }
        let uidd = all_existing.vehicles[p.carid]?.api_uid

        if (uidd) {
            carData = {
                ...carData,
                uid: uidd,
            }
        }

        let data = {}

        if (car_is_external) {
            data = {
                customer: {
                    // check if external car or custodian car
                    external_vehicles: [
                        {
                            ...carData,
                        },
                    ],
                },
            }
        } else {
            data = {
                customer: {
                    // check if external car or custodian car
                    custodian_cars: [
                        {
                            ...carData,
                        },
                    ],
                },
            }
        }

        // console.log('l 81 data sent to patch BE', data)

        if (draft.entities) {
            if (entities_info.ids_to_delete_by_type) {
                let arrayOfDeletionTasks: any = []
                let entity_ids = Object.keys(
                    entities_info.ids_to_delete_by_type
                )
                for (let i = 0; i < entity_ids.length; i++) {
                    let entity_type = entity_ids[i]

                    for (
                        let i = 0;
                        i <
                        entities_info.ids_to_delete_by_type[entity_type].length;
                        i++
                    ) {
                        let id_to_delete: string =
                            entities_info.ids_to_delete_by_type[entity_type][i]

                        // console.log(
                        //     'deleting entity type:',
                        //     entity_type,
                        //     'of ID:',
                        //     id_to_delete
                        // )

                        let req_del: IDeleteInsuranceApplication_req = {
                            appli_id: `${all_existing.id}`,
                            entity_id: id_to_delete,
                            // @ts-ignore
                            entity_type: entity_type,
                            parent_vehicle_id:
                                all_existing.vehicles[p.carid]?.api_uid,
                        }

                        const deletionTask = yield fork(entityDeletionSaga, {
                            ...req_del,
                        })
                        arrayOfDeletionTasks.push(deletionTask)
                    }
                }
                if (arrayOfDeletionTasks.length > 0) {
                    yield join(arrayOfDeletionTasks)
                    application_has_deletions = true
                }
            }
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length > 0) {
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...data },
                }
            )

            application_has_patch = true
            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_carTechInfo_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_carTechInfo_success())
        }

        // here, pass the results and thats it

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_car_techSpecifications,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${
                    all_existing.id
                }/${car_is_external ? 'everyday_car' : 'car'}/${
                    p.carid
                }/tech_info}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(insuranceActions.submit_carTechInfo_error(customError))
        }
    }
}

function* Watcher_Submit_car_techSpecifications() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_carTechInfo_request
        )
        yield call(Submit_car_techSpecifications, payload)
    }
}

const car_techSpecifications_submit: any[] = [
    fork(Watcher_Submit_car_techSpecifications),
]

export default car_techSpecifications_submit
