import { Radio } from 'antd'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { device } from '../../../templates/displays/devices'
import colours, { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    value: boolean
    isYesActive: boolean
    isNoActive: boolean
    onChange: (payload: boolean) => void
    isDisabled?: boolean
    customPrimaryColour?: string
    customPrimaryColour_04?: string
}

type IStyle = {
    $theme: ITheme
    $isActive?: boolean
    $customPrimaryColour?: string
    $customPrimaryColour_04?: string
}

const CustomRadioGroup = styled(Radio.Group)<IStyle>`
    .ant-radio-inner {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        border: ${(props) => `1px solid ${colours[props.$theme].text_default}`};
        height: 20px !important;
        width: 20px !important;
    }

    .ant-radio-inner::before {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        height: 14px !important;
        width: 14px !important;
    }

    .ant-radio-inner::after {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        transform: scale(0.7) !important;
    }

    .ant-radio-checked .ant-radio-inner {
        background-color: ${(props) =>
            `${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
        border: ${(props) =>
            `1px solid ${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${(props) =>
            `${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
    }
`

export const YesNoBox = styled(motion.div)<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 18px 16px 16px;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    transition: all 200ms;

    :hover {
        background-color: ${(props) =>
            `${
                props.$customPrimaryColour_04 ??
                colours[props.$theme].primary_04
            }`};
        border: ${(props) =>
            `1px solid ${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`};
    }

    ${(props) => `
    background: ${
        props.$isActive
            ? props.$customPrimaryColour_04 ?? colours[props.$theme].primary_04
            : 'transparent'
    };
    border: ${
        props.$isActive
            ? `1px solid ${
                  props.$customPrimaryColour ?? colours[props.$theme].primary
              }`
            : `1px solid ${colours[props.$theme].text_muted}`
    };`}

    cursor: pointer;
`

export const FieldRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    @media ${device.mobile_and_ipad} {
        gap: 16px;
    }
`

function YesNoRadioInput({
    value,
    isYesActive,
    isNoActive,
    onChange,
    isDisabled,
    customPrimaryColour,
    customPrimaryColour_04,
}: Props) {
    const { theme } = useThemes()
    return (
        <CustomRadioGroup
            $customPrimaryColour={customPrimaryColour}
            $customPrimaryColour_04={customPrimaryColour_04}
            $theme={theme}
            value={value}
            style={{ width: '100%' }}
            disabled={isDisabled}
        >
            <FieldRow
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <YesNoBox
                    $theme={theme}
                    $customPrimaryColour={customPrimaryColour}
                    $customPrimaryColour_04={customPrimaryColour_04}
                    whileTap={{ scale: 1.05 }}
                    $isActive={isYesActive}
                    onClick={() => {
                        if (!isDisabled) {
                            onChange(true)
                        }
                    }}
                >
                    <p
                        style={{
                            color: colours[theme].text_darker,
                            zIndex: 1,
                        }}
                    >
                        Yes
                    </p>
                    <Radio
                        id={'yes'}
                        type="radio"
                        value={true}
                        name="yes"
                        style={{ margin: 0 }}
                    />
                </YesNoBox>

                <YesNoBox
                    $theme={theme}
                    $customPrimaryColour={customPrimaryColour}
                    $customPrimaryColour_04={customPrimaryColour_04}
                    whileTap={{ scale: 1.05 }}
                    $isActive={isNoActive}
                    onClick={() => {
                        if (!isDisabled) {
                            onChange(false)
                        }
                    }}
                >
                    <p
                        style={{
                            color: colours[theme].text_darker,
                            zIndex: 1,
                        }}
                    >
                        No
                    </p>
                    <Radio
                        id={'no'}
                        type="radio"
                        value={false}
                        name="no"
                        style={{ margin: 0 }}
                    />
                </YesNoBox>
            </FieldRow>
        </CustomRadioGroup>
    )
}

export default YesNoRadioInput
