import { useDrag } from '@use-gesture/react'

export type OnSwipeProps = {
    onLeft: () => any
    onRight: () => any
}

export default function useSwipe(props: OnSwipeProps) {
    let threshold = 0.3
    const bind = useDrag(({ last, movement: [vx, vy] }) => {
        if (Math.abs(vx) > Math.abs(vy)) {
            if (vx < -threshold && last) {
                props.onLeft()
            } else if (vx > threshold && last) {
                props.onRight()
            }
        }
        //else {
        // if (vy < -threshold && last) {
        //     actions.onUp()
        // } else if (vy > threshold && last) {
        //     actions.onDown()
        // }
        //}
    })

    return bind
}
