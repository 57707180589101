type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const FaqIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8C14.25 4.54822 11.4518 1.75 8 1.75ZM0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C12.2802 0.25 15.75 3.71979 15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8Z"
                fill={color ?? '#616161'}
            />
            <path
                d="M7.05152 9.58352V9.10629C7.05152 8.7603 7.09129 8.47596 7.17082 8.25325C7.25434 8.03055 7.3816 7.84364 7.5526 7.69252C7.72759 7.53742 7.95824 7.38829 8.24458 7.24512C8.49512 7.11786 8.68999 7.00054 8.82918 6.89317C8.96837 6.78182 9.06381 6.66847 9.11551 6.55315C9.17119 6.43782 9.19902 6.3026 9.19902 6.14751C9.19902 5.89696 9.09761 5.69017 8.89479 5.52711C8.69197 5.36009 8.41558 5.27657 8.06562 5.27657C7.64805 5.27657 7.32592 5.37003 7.09924 5.55694C6.87654 5.73988 6.74928 5.98445 6.71746 6.29067H5.25C5.29375 5.5987 5.54826 5.04393 6.01356 4.62636C6.48283 4.20878 7.14696 4 8.00597 4C8.57068 4 9.05785 4.09544 9.46746 4.28633C9.87708 4.47722 10.1932 4.73572 10.4159 5.06182C10.6386 5.38793 10.75 5.7538 10.75 6.15944C10.75 6.57303 10.6446 6.92299 10.4338 7.20933C10.227 7.49566 9.90293 7.75018 9.4615 7.97289C9.203 8.10412 9.00018 8.22741 8.85304 8.34273C8.70987 8.45806 8.60846 8.58532 8.54881 8.72451C8.48915 8.85973 8.45933 9.03073 8.45933 9.23753V9.58352H7.05152ZM7.79122 12.0651C7.5009 12.0651 7.26428 11.9756 7.08135 11.7966C6.90239 11.6177 6.81291 11.399 6.81291 11.1405C6.81291 10.87 6.90239 10.6453 7.08135 10.4664C7.26428 10.2834 7.49892 10.192 7.78525 10.192C8.07556 10.192 8.31616 10.2834 8.50705 10.4664C8.69794 10.6453 8.79338 10.868 8.79338 11.1345C8.79338 11.397 8.69794 11.6177 8.50705 11.7966C8.31616 11.9756 8.07755 12.0651 7.79122 12.0651Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default FaqIcon
