import { ActionType } from 'typesafe-actions'
import * as successActions from './actions/successActions'
import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'
import { IAttachmentsReducer } from 'attachmentModels'

type SuccessAction = ActionType<typeof successActions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

// later on : to retrieve usertype + questionsList from back end, so to add userPagesArray from back end

export const attachmentsReducer = (
    state: IAttachmentsReducer = {
        attachmentsById: {},
        loading: false,
        error: null,
    },
    action: LoadingActions | SuccessAction | ErrorActions
): IAttachmentsReducer => {
    switch (action.type) {
        //  PUBLIC WEBSITE MOBILE MENU

        case loadingActions.addAttachmentsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.addAttachmentsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case successActions.addAttachmentsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                attachmentsById: {
                    ...state.attachmentsById,
                    ...action.payload,
                },
                loading: false,
                error: null,
            })

        case successActions.cleanUpAttachmentsSuccess.type:
            return Object.assign({}, state, {
                attachmentsById: {},
                loading: false,
                error: null,
            })

        default:
            return state
    }
}

export default attachmentsReducer

export type IAttachmentsState = ReturnType<typeof attachmentsReducer>
