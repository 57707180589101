import * as React from 'react'
import ListItemMobile from '../../atoms/list/listItemMobile'
import * as unitGenerator from '../../../helpers/units/unitConversion'

interface State {
    openInfo: string | null
}

interface Props {
    readOnlyMode?: boolean
    toggle?: any
    list: string[]
    data: any
    default_fuel_unit_user_text?: string | undefined
    default_fuel_unit_user?: string | undefined
    default_distance_unit_user_text?: string | undefined
    default_distance_unit_user?: string | undefined
    requiredLabel?: boolean
}

class ListManagerMobile extends React.Component<Props, State> {
    state = {
        openInfo: null,
    }

    // manageMenus = (id?: string) => {
    //   if (id === this.state.menuOpenId) {
    //     this.setState({ menuOpenId: null });
    //   } else this.setState({ menuOpenId: id ? id : null });
    // };

    componentDidMount() {}

    render() {
        const {
            toggle,
            list,
            data,
            default_fuel_unit_user_text,
            default_fuel_unit_user,
            default_distance_unit_user_text,
            default_distance_unit_user,
        } = this.props

        const shapeItemAccordingToUnitPreferences = (id: any) => {
            if (id === 'mileage') {
                if (
                    default_distance_unit_user &&
                    default_distance_unit_user !== 'kilometers' &&
                    data[id].answer !== null
                ) {
                    return {
                        ...data[id],
                        answer: unitGenerator.generateFrontEndValueDistanceUnit(
                            default_distance_unit_user,
                            data[id].answer
                        ),
                    }
                } else {
                    return data[id]
                }
            }

            if (id === 'engine_capacity') {
                if (
                    data[id].answer !== null &&
                    default_fuel_unit_user &&
                    default_fuel_unit_user !== 'cubic_centimeters'
                ) {
                    if (default_fuel_unit_user === 'liters') {
                        return {
                            ...data[id],
                            answer: unitGenerator
                                .generateFrontEndValueFuelUnit(
                                    default_fuel_unit_user,
                                    data[id].answer
                                )
                                .toFixed(1),
                        }
                    } else {
                        return {
                            ...data[id],
                            answer: unitGenerator
                                .generateFrontEndValueFuelUnit(
                                    default_fuel_unit_user,
                                    data[id].answer
                                )
                                .toFixed(1),
                        }
                    }
                } else {
                    return data[id]
                }
            }
            return data[id]
        }

        return (
            <React.Fragment>
                {list.map((id: any, index) => {
                    if (id === 'address' && data[id]) {
                        return (
                            <ListItemMobile
                                height="100px"
                                toggle={toggle}
                                key={index}
                                item={data[id]}
                                requiredLabel={this.props.requiredLabel}
                            />
                        )
                    } else
                        return (
                            <ListItemMobile
                                removeEdit={
                                    this.props.readOnlyMode ||
                                    (data[id] &&
                                        data[id].id &&
                                        data[id].id === 'email')
                                        ? true
                                        : false
                                }
                                toggle={toggle}
                                key={index}
                                item={shapeItemAccordingToUnitPreferences(id)}
                                unit={
                                    id === 'engine_capacity'
                                        ? default_fuel_unit_user_text
                                        : id === 'mileage'
                                        ? default_distance_unit_user_text
                                        : undefined
                                }
                                dataCyId={`${id}-field-mobile`}
                                requiredLabel={this.props.requiredLabel}
                                readOnlyMode={this.props.readOnlyMode}
                            />
                        )
                })}
            </React.Fragment>
        )
    }
}
export default ListManagerMobile
