import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'

import { motion } from 'framer-motion'
import { Button, message, Upload } from 'antd'

import './antd.css'
import {
    getPresignedUrlCarCoverImageRequest,
    submitCarGalleryImagesFilestorageRequest,
} from '../../../../redux/filestorage/actions/dispatched/loadingActions'
import Expander from '../../../atoms/expander/expander'
import InputField from '../../../atoms/Inputfield/inputField'

import {
    IUploadAndCreateCarGalleryImageArgs,
    IUploadGalleryImagesPreSignedUrlsPayload,
} from 'myModels'
import useThemes from '../../../../providers/theme/hooks'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 20px;
    height: auto;
    background-color: var(--bg-color, #fff);
    color: black;
    border-radius: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 5px;
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
`

const FormTitle = styled.div`
    font-family: Lato;
    width: 100%;
    text-align: start;
    font-size: 18px;
    padding-bottom: 20px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 20px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 14px;
    padding-right: 10px;
    color: var(--text-darker, #616161);
`

const SmallPadding = styled.div`
    padding-top: 26px;
`

const LargePadding = styled.div`
    padding-top: 50px;
`

// const ImgBtnRowWrapper = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
//     padding-top: 30px;
// `

const ImgBtnColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
`

const NameOfFileUploaded = styled.div`
    color: #898989;
    font-family: Lato;
    font-size: 14px;
    padding-right: 20px;
    padding-left: 0px;
    max-width: 50vw;
    overflow: hidden;
    padding-bottom: 10px;
`

const dispatchProps = {
    getPresignedUrlCarCoverImageRequest: (payload: {
        carid: string
        file_name: string
    }) => getPresignedUrlCarCoverImageRequest(payload),
    submitCarGalleryImagesFilestorageRequest: (
        payload: IUploadAndCreateCarGalleryImageArgs
    ) => submitCarGalleryImagesFilestorageRequest(payload),
}

function mapStateToProps(state: RootState) {
    return {
        gallerypresignedurls: state.fileStorage.gallerypresignedurls,
    }
}

type Cover = string | null

interface Values {
    caption: string
    location: string
    credits: string
    file_url?: string
    file: File | null
    cover?: Cover
}

interface OtherProps {
    close: () => void
    setImageUploaded: (file: File) => void
    currentImagesUploaded: File | undefined
    car_id: string
    getPresignedUrlCarCoverImageRequest: (payload: {
        carid: string
        file_name: string
    }) => void
    gallerypresignedurls: IUploadGalleryImagesPreSignedUrlsPayload | undefined
    submitCarGalleryImagesFilestorageRequest: (
        payload: IUploadAndCreateCarGalleryImageArgs
    ) => void
    progressPercent?: number | null
    isSomethingBeingUploaded?: boolean
}

const BiggerPadding = styled.div`
    padding-top: 20px;
`

const CustomButton = styled(Button)`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.03);
    transition: all 200ms;
    :hover {
        box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.09);
        transition: all 200ms;
    }

    text-transform: lowercase;
    background-color: transparent !important;

    width: 130px !important;
    height: 30px !important;
    border-radius: 5px !important;
    color: #5ec3ca !important;
    border: 1px solid #5ec3ca !important;
    font-family: Lato !important;
    font-size: 13px !important;

    @media ${device.mobile} {
        font-size: 15px !important;
        height: 40px !important;
    }

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const ProgressBarImagesMobileParentContainer = styled.div`
    border: 1px #5ec3ca solid;
    display: flex;
    position: relative;
    height: 4px;
    width: 55vw;
    border-radius: 5px;
`

const FileRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 79vw;
    padding-top: 10px;
`

const FileCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
    padding-left: 10px;
    padding-right: 10px;
`

type ProgressProps = {
    progressPercent: number
}

const CompletionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const ProgressBarImagesMobileChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: #5ec3ca;
    border-radius: 5px;
`

const Completed = styled.div`
    color: #676767;
    font-size: 14px;
    font-family: Lato;
    padding-left: 20px;
    transform: translateY(-5px);
`

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        close,
        setImageUploaded,
        currentImagesUploaded,
        values,
        setFieldValue,
        getPresignedUrlCarCoverImageRequest,
        car_id,
        progressPercent,
    } = props

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const errorFileSizeWarning = () => {
        message.error({
            content: 'The maximum file size allowed per upload is 20MB.',
            duration: 2,
        })
    }

    const { theme } = useThemes()

    return (
        <div style={{ zIndex: 5 }}>
            <EditFormContainerMobile>
                <InnerFormPadding>
                    <FormTitle>Update your car's profile image</FormTitle>
                    <FieldTitle>
                        Image formats accepted: png, jpg, jpeg, HEIC and HEIF.
                    </FieldTitle>

                    <SmallPadding />

                    <Expander height={currentImagesUploaded ? 0 : 'auto'}>
                        <motion.div whileTap={{ scale: 1.05 }}>
                            <Upload
                                multiple={false}
                                showUploadList={false}
                                onChange={(info: any) => {
                                    if (
                                        info.file &&
                                        (info.file.type === 'image/jpeg' ||
                                            info.file.type === 'image/png' ||
                                            info.file.type === 'image/heic' ||
                                            info.file.type === 'image/heif')
                                    ) {
                                        if (
                                            info.file.size <= 20000000 &&
                                            info.file.size > 1
                                        ) {
                                            getPresignedUrlCarCoverImageRequest(
                                                {
                                                    carid: car_id,
                                                    file_name: info.file.name,
                                                }
                                            )

                                            let fileimg =
                                                info.file.originFileObj

                                            setFieldValue('file', fileimg)
                                            let fake_url =
                                                URL.createObjectURL(fileimg)
                                            setFieldValue('file_url', fake_url)

                                            setImageUploaded(fileimg)
                                        } else errorFileSizeWarning()
                                    }
                                }}
                                customRequest={dummyRequest}
                            >
                                <CustomButton>import file</CustomButton>
                            </Upload>
                        </motion.div>
                    </Expander>

                    <Expander height={currentImagesUploaded ? 'auto' : 0}>
                        <FileRow>
                            <FileCol>
                                <NameOfFileUploaded>
                                    {currentImagesUploaded &&
                                        // currentImagesUploaded.new === true &&
                                        currentImagesUploaded.name !==
                                            undefined &&
                                        currentImagesUploaded.name}
                                </NameOfFileUploaded>

                                {currentImagesUploaded && progressPercent && (
                                    <CompletionRow>
                                        <ProgressBarImagesMobileParentContainer>
                                            <ProgressBarImagesMobileChild
                                                progressPercent={
                                                    progressPercent
                                                        ? progressPercent
                                                        : 0
                                                }
                                            />
                                        </ProgressBarImagesMobileParentContainer>

                                        {progressPercent === 100 && (
                                            <Completed>100%</Completed>
                                        )}
                                    </CompletionRow>
                                )}
                            </FileCol>
                        </FileRow>
                    </Expander>

                    <div style={{ paddingTop: '20px' }} />

                    <ImgBtnColWrapper>
                        {/* {currentImagesUploaded &&
                            currentImagesUploaded.new !== true &&
                            currentImagesUploaded.fileTitle !== undefined && (
                                <ImgBtnRowWrapper>
                                    <NameOfFileUploaded>
                                        {currentImagesUploaded.fileTitle}
                                    </NameOfFileUploaded>
                                </ImgBtnRowWrapper>
                            )} */}
                    </ImgBtnColWrapper>

                    <BiggerPadding />
                    <FieldTitle>Caption</FieldTitle>

                    <InputField
                        theme={theme}
                        id="caption"
                        name="caption"
                        placeholder="caption"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.caption}
                    />

                    <SmallPadding />

                    <FieldTitle>Location</FieldTitle>

                    <InputField
                        theme={theme}
                        id="location"
                        name="location"
                        placeholder="location"
                        tabindex={2}
                        aria-live="polite"
                        formikprops={props}
                        value={values.location}
                    />

                    <SmallPadding />

                    <FieldTitle>Credits</FieldTitle>

                    <InputField
                        theme={theme}
                        id="credits"
                        name="credits"
                        placeholder="credits"
                        tabindex={3}
                        aria-live="polite"
                        formikprops={props}
                        value={values.credits}
                    />
                </InnerFormPadding>

                <LargePadding />
                <ButtonsStandardBottomRowMobile
                    cancel={close}
                    submit={props.handleSubmit}
                    disabled={progressPercent === 100 ? false : true}
                    confirmationtext={'save'}
                />
            </EditFormContainerMobile>
        </div>
    )
}

const AddCarCoverImageMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        caption: '',
        location: '',
        credits: '',
        file: null,
    }),

    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        let file = values.file

        if (file && props.gallerypresignedurls) {
            let newFile: any = file

            newFile.path = file.name

            props.submitCarGalleryImagesFilestorageRequest({
                carid: props.car_id,
                gallerypresignedurls: props.gallerypresignedurls,
                files: [newFile],
                fields: {
                    caption: values.caption,
                    credit: values.credits,
                    location: values.location,
                    cover: file.name,
                    featured: null,
                },
                isFromQuickActions: true,
            })

            // here req thunks to upload to S3
        }

        setSubmitting(false)
        props.close()
    },
})(InnerForm)

export default connect(mapStateToProps, dispatchProps)(AddCarCoverImageMobile)
