import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import CarOwnerBox from '../../../atoms/user/carOwnerBox'
import { device } from '../../../templates/displays/devices'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import {
    IGalleryImage,
    IPrivateShareReceivedItem,
    IPrivateShareReceivedItemApprovalPayload,
} from 'entityModels'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import CardMotionDivWrapper from '../../../templates/animated/cardMotionDivWrapper'
// import public_inactive_circle from '../../../../public/assets/icons/public_inactive_circle.svg'

interface IStyle {
    hasMarginRight?: boolean
    $hasApprovalBtns?: boolean
    $allowClick?: boolean
    $justTwoBtns?: boolean
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    display: flex;
    width: 90vw;
    /* max-width: 380px; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${(props) =>
        props.$hasApprovalBtns ? 'flex-start' : 'center'};
    font-family: Lato;
    height: ${(props) =>
        props.$hasApprovalBtns
            ? props.$justTwoBtns
                ? '280px'
                : '335px'
            : '130px'};
    ${(props) => props.$hasApprovalBtns && 'padding: 25px;'};
    border: transparent;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    background-color: var(--shadow-cards-bg, #fff);
    transition: all 100ms;
    margin-bottom: 20px;

    @media ${device.mobile} {
    }

    @media screen and (max-width: 368px) {
        max-width: 380px;
    }

    @media ${device.ipad} {
        max-width: 460px;
    }
`

const CarTitle = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 13px;
    max-width: 250px;
    height: 35px;
    display: flex;
    align-items: center;

    @media ${device.ipad} {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        max-width: 170px;
    }
    @media (max-width: 400px) {
        max-width: 150px;
    }
    @media (max-width: 320px) {
        max-width: 120px;
    }
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 110px;
    padding-left: 160px;
    @media (max-width: 400px) {
        padding-left: 135px;
    }
    @media (max-width: 300px) {
        padding-left: 125px;
    }
`

const Row = styled.div<IStyle>`
    width: 100%;
    padding-right: ${(props) => (props.$hasApprovalBtns ? '0px' : '20px')};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 280px) {
        padding-right: ${(props) => (props.$hasApprovalBtns ? '0px' : '10px')};
    }
`

const CarProfileImage = styled.img<IStyle>`
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    object-fit: cover;
    ${(props) =>
        props.$hasApprovalBtns &&
        `border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;`};
`

const CarImgWrapper = styled.div<IStyle>`
    position: absolute;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    width: 140px;
    height: ${(props) => (props.$hasApprovalBtns ? '120px' : '100%')};
    object-fit: cover;
    ${(props) =>
        props.$hasApprovalBtns &&
        `border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;`};
    @media (max-width: 400px) {
        width: 120px;
    }
    @media (max-width: 300px) {
        width: 110px;
    }
`

const HozLine = styled.div`
    background-color: var(--border_muted_subtle);
    height: 1px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 12px;
`

const ColFullWidth = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const SmallPadding = styled.div`
    padding-top: 16px;
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

interface Props extends RouteComponentProps<any> {
    share_received: IPrivateShareReceivedItem
    hasMarginRight?: boolean
    shareApprovalAction?: (
        payload: IPrivateShareReceivedItemApprovalPayload
    ) => void
}

type State = { src: any; srcet: any; isLoading: boolean }

class CarsSharedWithYouCardMobile extends React.Component<Props, State> {
    state = { srcet: undefined, src: undefined, isLoading: true }

    _isMounted = false

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                src: src,
                isLoading: false,
            })
    }

    componentDidMount() {
        this._isMounted = true
        if (this.props.share_received.car_profile_pic) {
            const src: string = this.props.share_received.car_profile_pic.url
            this._isMounted && this.setState({ src, isLoading: true })

            this.onLoad(src)
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.share_received.car_profile_pic !==
            prevProps.share_received.car_profile_pic
        ) {
            if (this.props.share_received.car_profile_pic) {
                const src: string =
                    this.props.share_received.car_profile_pic.url
                this.setState({ src, isLoading: true })

                this.onLoad(src)
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let { share_received, shareApprovalAction } = this.props

        let cover_img_id: IGalleryImage | undefined =
            share_received && share_received.car_profile_pic

        let hasApprovalBts =
            share_received.approvalStatus === 'pending' ||
            share_received.approvalStatus === 'ignored'
                ? true
                : false

        let params = new URLSearchParams(this.props.location.search)
        let view_id = params.get('view_id')

        return (
            <CardMotionDivWrapper>
                {share_received && (
                    <ShadowBox
                        to={
                            share_received.approvalStatus === 'accepted'
                                ? `/shared/car/${share_received.entity_id}`
                                : view_id
                                ? `/sharing/with-you?view_id=${view_id}`
                                : `/sharing/with-you`
                        }
                        // target="_blank"
                        rel="noreferrer"
                        $hasApprovalBtns={hasApprovalBts}
                        $allowClick={
                            share_received.approvalStatus === 'accepted'
                        }
                        $justTwoBtns={
                            share_received.approvalStatus === 'ignored'
                        }
                    >
                        <Row $hasApprovalBtns={hasApprovalBts}>
                            <CarImgWrapper $hasApprovalBtns={hasApprovalBts}>
                                {cover_img_id ? (
                                    <>
                                        <CarProfileImage
                                            src={cover_img_id.url}
                                            aria-label="shared car profile image"
                                            $hasApprovalBtns={hasApprovalBts}
                                        />
                                        <SkeletonAbsolute
                                            isthingloading={
                                                this.state.isLoading
                                            }
                                        />
                                    </>
                                ) : (
                                    <NoDataRectangle
                                        text={share_received.car_title}
                                        height="100%"
                                        bgColor="var(--off-bg-color, #fafafa)"
                                    />
                                )}
                            </CarImgWrapper>

                            <Col>
                                <CarTitle>
                                    {share_received.car_title
                                        ? share_received.car_title !== ''
                                            ? share_received.car_title
                                            : 'Untitled'
                                        : 'Untitled'}
                                </CarTitle>

                                {/* <SharedOn>shared on {data.car.sharedOn}</SharedOn> */}
                                <HozLine />
                                <CarOwnerBox
                                    owner={share_received.owner}
                                    isMobile
                                    sharedOn={share_received.created_at}
                                />
                            </Col>
                        </Row>
                        {hasApprovalBts && (
                            <Row $hasApprovalBtns={hasApprovalBts}>
                                <ColFullWidth>
                                    <SmallPadding
                                        style={{ paddingTop: '25px' }}
                                    />
                                    <ButtonAtom
                                        theme="secondary"
                                        width="100%"
                                        height="40px"
                                        textTransform="capitalize"
                                        fontSize={14}
                                        onClick={() => {
                                            if (shareApprovalAction) {
                                                shareApprovalAction({
                                                    shareId: share_received.id,
                                                    recipientId:
                                                        share_received.owner
                                                            .uid,
                                                    approvalId: 'accepted',
                                                })
                                                setTimeout(
                                                    () =>
                                                        this.props.history.push(
                                                            `/sharing/with-you?entity_id=cars&view_id=view_all`
                                                        ),
                                                    50
                                                )
                                            }
                                        }}
                                    >
                                        Accept
                                    </ButtonAtom>
                                    <SmallPadding />
                                    {share_received.approvalStatus !==
                                        'ignored' && (
                                        <>
                                            <ButtonAtom
                                                theme="tertiary"
                                                width="100%"
                                                height="40px"
                                                textTransform="capitalize"
                                                fontSize={14}
                                                onClick={() =>
                                                    shareApprovalAction
                                                        ? shareApprovalAction({
                                                              shareId:
                                                                  share_received.id,
                                                              recipientId:
                                                                  share_received
                                                                      .owner
                                                                      .uid,
                                                              approvalId:
                                                                  'ignored',
                                                          })
                                                        : {}
                                                }
                                            >
                                                Ignore
                                            </ButtonAtom>
                                            <SmallPadding />
                                        </>
                                    )}
                                    <ButtonAtom
                                        theme="lowercase-white-red-background"
                                        width="100%"
                                        height="40px"
                                        fontSize={14}
                                        onClick={() =>
                                            shareApprovalAction
                                                ? shareApprovalAction({
                                                      shareId:
                                                          share_received.id,
                                                      recipientId:
                                                          share_received.owner
                                                              .uid,
                                                      approvalId: 'rejected',
                                                  })
                                                : {}
                                        }
                                    >
                                        Decline
                                    </ButtonAtom>
                                    <SmallPadding />
                                </ColFullWidth>
                            </Row>
                        )}
                    </ShadowBox>
                )}
            </CardMotionDivWrapper>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarsSharedWithYouCardMobile)
)
