// TASKS DROPDOWN OP{TIONS OUTSIDE OF CAR NAMES
export const OOtherTaskOptions = {
    general_task: {
        name: 'General Tasks',
        uid: 'general_task',
        single: 'General Task',
    },
    all_tasks: { name: 'All Tasks' },
} as const

export type OtherTaskOptions = typeof OOtherTaskOptions[keyof typeof OOtherTaskOptions]

// TASKS DROPDOWN OP{TIONS OUTSIDE OF CAR NAMES
export const OTaskFilterNSort = {
    sort_by: {
        created_at: {
            name: 'Date Added',
            uid: 'created_at',
            text: 'Sort by date added',
        },
        due: {
            name: 'Due Date',
            uid: 'due',
            text: 'Sort by due date',
        },
    },
    filter_by: {
        outstanding: {
            name: 'Outstanding',
            uid: 'outstanding',
            text: 'Outstanding',
        },
        completed: { name: 'Completed', uid: 'completed', text: 'Completed' },
        all: { name: 'All', uid: 'all', text: 'All' },
    },
} as const

export type TaskFilterNSort = typeof OTaskFilterNSort[keyof typeof OTaskFilterNSort]
