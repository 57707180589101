import {
    IExternalDataByRegistrationNumberSingle,
    IListFromAPiType,
} from 'entityModels'
import { IAnyObject } from '../../insuranceQuoteApplication/reducer'

export const ConvertFromVesApiToStateFormat = (
    vesapipayload: IExternalDataByRegistrationNumberSingle
): IListFromAPiType => {
    let result: IListFromAPiType = {
        make: {
            id: 'make',
            text: 'make',
            answer: undefined,
            // answer:
            //     vesapipayload.make &&
            //     vesapipayload.make !== 'No details held by DVLA'
            //         ? vesapipayload.make
            //         : undefined,
        },

        year: {
            id: 'year',
            text: 'year',
            answer:
                vesapipayload.year &&
                vesapipayload.year !== 'No details held by DVLA'
                    ? vesapipayload.year
                    : undefined,
        },
        tax_status: {
            id: 'tax_status',
            text: 'Road Tax Status',
            answer:
                vesapipayload.tax_status &&
                vesapipayload.tax_status !== 'No details held by DVLA'
                    ? vesapipayload.tax_status
                    : undefined,
        },
        tax_due: {
            id: 'tax_due',
            text: 'Road Tax Due Date',
            answer:
                vesapipayload.tax_due &&
                vesapipayload.tax_due !== 'No details held by DVLA'
                    ? vesapipayload.tax_due
                    : undefined,
        },
        service_check_status: {
            id: 'service_check_status',
            text: 'MOT status',
            answer:
                vesapipayload.service_check_status &&
                vesapipayload.service_check_status !== 'No details held by DVLA'
                    ? vesapipayload.service_check_status
                    : undefined,
        },

        service_check_expiry: {
            id: 'service_check_expiry',
            text: 'MOT Expiry',
            answer:
                vesapipayload.service_check_expiry &&
                vesapipayload.service_check_expiry !== 'No details held by DVLA'
                    ? vesapipayload.service_check_expiry
                    : undefined,
        },

        registration_date: {
            id: 'registration_date',
            text: 'registration date',
            answer:
                vesapipayload.registration_date &&
                vesapipayload.registration_date !== 'No details held by DVLA'
                    ? vesapipayload.registration_date
                    : undefined,
        },

        engine_capacity: {
            id: 'engine_capacity',
            text: 'engine size',
            answer:
                vesapipayload.engine_capacity &&
                vesapipayload.engine_capacity !== 'No details held by DVLA'
                    ? vesapipayload.engine_capacity
                    : undefined,
        },

        fuel_type: {
            id: 'fuel_type',
            text: 'fuel type',
            answer:
                vesapipayload.fuel_type &&
                vesapipayload.fuel_type !== 'No details held by DVLA'
                    ? vesapipayload.fuel_type.toLowerCase()
                    : undefined,
        },

        color_exterior: {
            id: 'color_exterior',
            text: 'exterior colour',
            answer:
                vesapipayload.color_exterior &&
                vesapipayload.color_exterior !== 'No details held by DVLA'
                    ? vesapipayload.color_exterior
                    : undefined,
        },

        last_owner_change: {
            id: 'last_owner_change',
            text: 'Last Ownership Change',
            answer:
                vesapipayload.last_owner_change &&
                vesapipayload.last_owner_change !== 'No details held by DVLA'
                    ? vesapipayload.last_owner_change
                    : undefined,
        },
    }

    return result
}

export const ConvertFromVesApiToInsuranceStateFormat = (
    vesapipayload: IExternalDataByRegistrationNumberSingle
): IAnyObject => {
    let result: IAnyObject = {
        make: vesapipayload.make
            ? { uid: vesapipayload.make, name: vesapipayload.make }
            : undefined,

        manufacture_year: vesapipayload.year ? vesapipayload.year : undefined,

        // tax_status: vesapipayload.tax_status
        //     ? vesapipayload.tax_status
        //     : undefined,

        // tax_due: vesapipayload.tax_due ? vesapipayload.tax_due : undefined,

        // service_check_status: vesapipayload.service_check_status
        //     ? vesapipayload.service_check_status
        //     : undefined,

        // service_check_expiry: vesapipayload.service_check_expiry
        //     ? vesapipayload.service_check_expiry
        //     : undefined,

        // registration_date: vesapipayload.registration_date
        //     ? vesapipayload.registration_date
        //     : undefined,

        engine_capacity: vesapipayload.engine_capacity
            ? vesapipayload.engine_capacity
            : undefined,

        // engine_size: vesapipayload.engine_capacity
        //     ? vesapipayload.engine_capacity
        //     : undefined,

        engine_fuel_type: vesapipayload.fuel_type
            ? vesapipayload.fuel_type.toLowerCase()
            : undefined,

        // color_exterior: vesapipayload.color_exterior
        //     ? vesapipayload.color_exterior
        //     : undefined,

        // last_owner_change: vesapipayload.last_owner_change
        //     ? vesapipayload.last_owner_change
        //     : undefined,

        registration_number: vesapipayload.registration_number ?? undefined,
    }

    return result
}
