import { withFormik, FormikProps } from 'formik'
import { connect } from 'react-redux'
import { Select } from 'antd'
import styled from 'styled-components'
import './antd.css'

const { Option } = Select

const StyledSelect = styled(Select)<ISelectProps>`
    cursor: pointer;
    /* padding: 5px 30px 5px 15px; */
    font-size: 14px !important;
    background-color: var(--bg-color, #fff);
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 5px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: ${(props) => (props.width ? props.width : '100%')};
    min-width: 150px;
    font-family: Lato;
    color: var(--text-darker, #616161);
    text-transform: capitalize;

    ::selection {
        color: var(--bg-color, #fff);
        background: var(--primary, #5ec3ca);
    }
`

interface ISelectProps {
    width?: string
}

type IDropDownItem = {
    uid: string
    name: any
    [key: string]: any
}

interface ExternalProps {
    items: IDropDownItem[]
    initialValue: IDropDownItem
    customHandleChange?: any
    isASubmission?: boolean
    width?: string
}

type FormValues = {
    [key: string]: any
    uid: any
}

const SelectGroupFormik = (props: ExternalProps & FormikProps<FormValues>) => {
    const {
        handleSubmit,
        //  values,
        setFieldValue,
        items,
        initialValue,
        customHandleChange,
        isASubmission,
        //  errors
    } = props

    function handleChange(value: any) {
        setFieldValue('uid', value)
    }

    return (
        <>
            <StyledSelect
                width={props.width}
                defaultValue={initialValue.name}
                // style={{ width: 120 }}
                onChange={(e: any) => {
                    if (customHandleChange) {
                        customHandleChange(e)
                    } else handleChange(e)
                }}
                size="large"
            >
                {items.map((item, index) => {
                    return (
                        <Option key={`${index}_select_gr`} value={item.uid}>
                            {item.name}
                        </Option>
                    )
                })}
            </StyledSelect>

            {isASubmission === true && (
                <button onClick={() => handleSubmit()}> submit</button>
            )}
        </>
    )
}
const SelectGroup = withFormik<ExternalProps, FormValues>({
    mapPropsToValues: (props) => ({
        uid: props.initialValue.uid,
    }),
    // validationSchema: Yup.object().shape({
    //     value: Yup.array().of(Yup.object().shape({ val: Yup.boolean() })),
    // }),
    handleSubmit: (values, { props, setSubmitting }) => {
        return
        // do submitting things
    },
})(SelectGroupFormik)

export default connect(null, null)(SelectGroup)
