import * as React from 'react'
import styled from 'styled-components'
import { device } from '../displays/devices'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { menuActions } from '../../../redux/menus/reducer'

import { isAnyFormOpen } from '../../../helpers/booleanChecks/isAnyFormOpen'
import * as NavigationIcons from '../../atoms/statefulicons'
import ActionsMenuModalMobile from '../../atoms/menu/mainmenu/actionsMenuModalMobile'
import MainSubmenuBottomSheetMobile from '../../atoms/menu/bottomSheetMenu/mainSubmenuBottomSheetMobile'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { motion } from 'framer-motion'
import ScrollLock from 'react-scrolllock'
import { ITimelineCreateForms } from 'myModels'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'

const dispatchProps = {
    toggleMobileAppSubMenu: (isOpen: boolean) =>
        menuActions.toggleMobileAppSubMenu(isOpen),
    toggleAddActions: (id: string) => menuActions.toggleAddActions(id),
    setCountriesCodeDropdownData: () =>
        dropDownActions.setCountriesCodeDropdownDataRequest(),
    toggleAddACarForm: (isOpen: boolean) =>
        editFormsActions.toggleAddACarForm(isOpen),
    toggleCreateTaskForm: () => editFormsActions.toggleCreateTaskForm(),
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
}

function mapStateToProps(state: RootState) {
    return {
        isMobileAppSubMenuOpen: state.menus.mobileAppSubMenu.isOpen,
        formsData: state.editForms.formsData,
        formIDs: state.editForms.formIDs,
        isActionsMenuOpen: state.menus.isAddActionsOpen.isOpen,
    }
}

type FabProps = {
    isOpen?: boolean
}

const Fabs = styled(motion.div)<FabProps>`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }

    position: fixed;
    z-index: 5;

    right: 20px;

    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    transition: all 500ms;
`

interface IProps extends RouteComponentProps<any> {
    hasAdd?: boolean
    hasSubNav?: boolean
    isMobileAppSubMenuOpen: boolean
    toggleMobileAppSubMenu: any
    toggleAddActions: any
    formsData: any
    formIDs: any
    toggleAddACarForm: any
    setCountriesCodeDropdownData: any
    isActionsMenuOpen: boolean
    toggleCreateTaskForm: any
    manageTimelineCreationFormState: any
}

class FabsMenusMobile extends React.Component<IProps, {}> {
    render() {
        let {
            hasAdd,
            formIDs,
            formsData,
            isMobileAppSubMenuOpen,
            toggleMobileAppSubMenu,
            toggleAddACarForm,
            setCountriesCodeDropdownData,
            toggleAddActions,
            // hasSubNav,
            isActionsMenuOpen,
            toggleCreateTaskForm,
        } = this.props
        let isAnyFormOpened = isAnyFormOpen(formIDs, formsData)

        let currentPath = this.props.history.location.pathname
        const params = new URLSearchParams(this.props.location.search)

        let carid = this.props.match.params.carid

        const addACar = () => {
            setCountriesCodeDropdownData()
            toggleAddACarForm(true)
        }

        const addTimelineEntry = () => {
            this.props.history.push(`/car/${carid}/history-file/create`)
            this.props.manageTimelineCreationFormState({
                isOpen: true,
                current_step_index: 0,
                active_creation: TimelineCreationTypeIdsEnum.add_new,
            })
        }

        let activePage = this.props.history.location.pathname
        let arr = this.props.history.location.pathname.split('/')
        let overviewCarPage = (): boolean => {
            if (arr.includes('history-file')) {
                return false
            }
            if (arr.includes('tasks')) {
                return false
            }
            if (arr.includes('technical-information')) {
                return false
            }
            if (arr.includes('technical-information')) {
                return false
            }
            if (arr.includes('gallery')) {
                return false
            }
            if (arr.includes('sharing')) {
                return false
            }
            if (arr.includes('archive')) {
                return false
            } else return true
        }

        let isCarOverview = overviewCarPage()
        return (
            <>
                {isActionsMenuOpen && <ScrollLock />}
                {isMobileAppSubMenuOpen && <ScrollLock />}

                {currentPath !== '/garage' &&
                    !currentPath.match(/tasks/g) &&
                    !currentPath.match(/shared/g) && (
                        <ActionsMenuModalMobile
                            activepage={activePage}
                            isOpen={isActionsMenuOpen}
                            toggle={toggleAddActions}
                        />
                    )}
                {!isActionsMenuOpen && (
                    <MainSubmenuBottomSheetMobile
                        activepage={activePage}
                        isOpen={isMobileAppSubMenuOpen}
                        toggle={toggleMobileAppSubMenu}
                    />
                )}

                {!isAnyFormOpened && !isActionsMenuOpen && !isCarOverview && (
                    <Fabs
                        style={{
                            bottom:
                                arr.includes('history-file') ||
                                arr.includes('car')
                                    ? '50px'
                                    : '110px',
                        }}
                    >
                        {hasAdd && !params.get('onboarding') && (
                            <NavigationIcons.FabIconAdd
                                dataCyId={undefined}
                                isOpen={isMobileAppSubMenuOpen}
                                onClick={(e: any) => {
                                    e.preventDefault()
                                    if (currentPath === '/garage') {
                                        addACar()
                                    } else if (currentPath.match(/tasks/g)) {
                                        toggleCreateTaskForm()
                                    } else if (
                                        currentPath.match(/history-file/g)
                                    ) {
                                        addTimelineEntry()
                                    } else {
                                        toggleAddActions(activePage)
                                    }
                                }}
                            />
                        )}
                    </Fabs>
                )}
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(FabsMenusMobile)
)
