import {
    IAddUrlQueryParamsProps,
    IUpdateUrlQueryParamsProps,
} from 'urlQueryParamsProps'

export const editUrlQueryParams = (props: IUpdateUrlQueryParamsProps) => {
    var myurl = new URL(props.url)

    var search_params = myurl.searchParams

    for (let i = 0; i <= props.queriesToReplace.length; i++) {
        if (props.queriesToReplace[i] && props.valuesToMapToQueries[i]) {
            // note: to add additional, not set but append. Obv not what we want here but just FYI :)
            search_params.set(
                props.queriesToReplace[i],
                props.valuesToMapToQueries[i]
            )
        }
    }

    // change the search property of the main url
    myurl.search = search_params.toString()

    // the new url string
    var new_url = myurl.toString()

    return new_url
}

export const addUrlQueryParams = (props: IAddUrlQueryParamsProps) => {
    var myurl = new URL(props.url)

    var search_params = myurl.searchParams

    for (let i = 0; i <= props.queriesIDToAdd.length; i++) {
        if (props.queriesIDToAdd[i] && props.valuesToMapToQueries[i]) {
            search_params.set(
                props.queriesIDToAdd[i],
                props.valuesToMapToQueries[i]
            )
        }
    }

    // change the search property of the main url
    myurl.search = search_params.toString()

    // the new url string
    var new_url = myurl.toString()

    return new_url
}
