// import saveAs from 'file-saver'
import { downloadPPDFCustomStickerImgA4 } from '../../atoms/pdfDocument/customQRCode'
import { openNewImg } from '../../atoms/pngDownloadButton/generatePngQRCodeRaw'
import { checkIfPwaOnIpad } from '../../templates/pwaCustom/checkIpadPwa'
import { createSvgModel } from './svgModelInjection'

export type IStickerTheme = {
    name?: string
    colour_one: string
    colour_two: string
}

export type IStickerThemeState = {
    colours: IStickerTheme
    openColourPicker?: 'one' | 'two' | undefined
}

export enum ChangeStickerTheme {
    CHANGE_COLOUR_ONE = 'CHANGE_COLOUR_ONE',
    CHANGE_COLOUR_TWO = 'CHANGE_COLOUR_TWO',
    CHANGE_BOTH_COLOURS = 'CHANGE_BOTH_COLOURS',
    OPEN_COLOUR_PICKER = 'OPEN_COLOUR_PICKER',
}

export interface ChangeStickerThemeAction {
    type: ChangeStickerTheme
    payload: {
        colours: { colour_one: string; colour_two: string }
        openColourPicker?: 'one' | 'two' | undefined
    }
}

export function changeStickerThemeReducer(
    state: IStickerThemeState,
    action: ChangeStickerThemeAction
) {
    const { type, payload } = action

    switch (type) {
        case ChangeStickerTheme.CHANGE_COLOUR_ONE:
            return {
                ...state,
                colours: {
                    ...state.colours,
                    colour_one: payload.colours.colour_one,
                },
            }
        case ChangeStickerTheme.CHANGE_COLOUR_TWO:
            return {
                ...state,
                colours: {
                    ...state.colours,
                    colour_two: payload.colours.colour_two,
                },
            }
        case ChangeStickerTheme.CHANGE_BOTH_COLOURS:
            return {
                ...state,
                colours: payload.colours,
            }
        case ChangeStickerTheme.OPEN_COLOUR_PICKER:
            return {
                ...state,
                openColourPicker: payload.openColourPicker,
            }
        default:
            return state
    }
}

export type IGenerateCustomCodePng_Arg = {
    colour_circle: string
    colour_background: string
    svg_qr_code_id: string
    title: string
    format: 'PDF' | 'PNG'
    device: 'mobile' | 'desktop'
}

export const generateCustomCodeFile = (p: IGenerateCustomCodePng_Arg) => {
    let imgloaded = false

    const qr_code_svg_element_og: HTMLElement | null = document.getElementById(
        p.svg_qr_code_id
    )

    if (qr_code_svg_element_og) {
        var qr_code_svg_element: any = qr_code_svg_element_og.cloneNode(true)

        // transform markup into string and create a blob out of it
        let svg_markup_new = createSvgModel({
            colour_background: p.colour_background,
            colour_circle: p.colour_circle,
            qr_svg: qr_code_svg_element,
        })

        let svg_markup = svg_markup_new
            ? new XMLSerializer().serializeToString(svg_markup_new)
            : null

        const svg_blob =
            svg_markup &&
            new Blob([svg_markup], {
                type: 'image/svg+xml',
            })

        // of whatever resolution I want
        // create a canvas element to paint sgv image on
        const canvas = document.createElement('canvas')
        canvas.width = 1000
        canvas.height = 1000
        const ctx = canvas.getContext('2d')

        // create new image element and add the blob url to it
        const img = new Image()
        img.width = 1000
        img.height = 1000
        img.src = svg_blob ? URL.createObjectURL(svg_blob) : ''

        if (imgloaded === false) {
            // onload will never get called again:
            // on image load, paint svg and save png

            img.onload = () => {
                if (imgloaded === false) {
                    ctx && ctx.drawImage(img, 0, 0, 1000, 1000)
                    const imgURI = canvas.toDataURL('image/png', 1)
                    img.src = imgURI
                    if (p.format === 'PDF') {
                        downloadPPDFCustomStickerImgA4(img, p.title)
                    } else if (p.format === 'PNG') {
                        // saveAs(imgURI, 'qr_code.png')
                        const link = document.createElement('a')
                        link.href = imgURI
                        if (p.device === 'mobile' || checkIfPwaOnIpad()) {
                            openNewImg(imgURI)
                        } else {
                            const fileName = `custom_${p.title
                                .toLowerCase()
                                .replace(/ /g, '_')}_custodian_qr_code.png`
                            link.download = fileName
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                        }
                    }
                    imgloaded = true
                }
            }
        }

        // clean-up: removing newly created elememts
        img.remove()
        canvas.remove()
        return
    } else return
}
