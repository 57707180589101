import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
    $selected?: boolean
    $width?: string
}

const Button = styled(motion.div)<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.$width ? props.$width : '40vw')};
    padding-top: 7px;
    padding-bottom: 7px;
    border: ${(props) => `2px solid ${colours[props.$theme].primary}`};
    border-radius: 5px;
    box-shadow: 0px 2px 4px -2px rgb(0 0 0 / 20%);
    outline: none;
    background-color: ${(props) =>
        props.$selected
            ? colours[props.$theme].primary
            : colours[props.$theme].background_default};
    color: ${(props) =>
        props.$selected
            ? colours[props.$theme].background_default
            : colours[props.$theme].primary};
    cursor: pointer;
    @media ${device.ipad} {
        width: ${(props) => (props.$width ? props.$width : '30vw')};
    }
`

const Text = styled.div`
    font-family: 'Lato';
    font-size: 14px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
`

type Props = {
    onClick: any
    text?: string | undefined
    dataCyId?: string | undefined
    selected?: boolean
    width?: string
}

const DatepickerButton: React.FunctionComponent<Props> = (props: Props) => {
    let { onClick, selected, text, dataCyId, width } = props
    const { theme } = useThemes()

    return (
        <Button
            $theme={theme}
            $selected={selected}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            whileTap={{ scale: 1.05 }}
            $width={width}
        >
            <Text>{text}</Text>
        </Button>
    )
}

export default DatepickerButton
