import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from './../../reducer'

import { gen_draft_differing_values_to_patch } from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { IRootState } from '../../../store'
import { api } from '../../../services'
import { gen_address_insurance_entity_patch } from '../../../conversions/insuranceApplication/addressObj'
import { ValidateAndAddStatsToApplication } from '../application/get'
import posthog from 'posthog-js'

export let insurance_application_draft_state_select = (state: IRootState) =>
    state.insuranceQuoteApplication.draft

export let insurance_application_existing_state_select = (
    state: IRootState
): IInsuranceQuoteApplication => state.insuranceQuoteApplication.submitted_data

export function* Submit_maindriver_licenceAddresses(): any {
    posthog.capture('Update insurance application customer license address')
    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft = { ...all_draft.main_driver.licence_address }
        let existing = { ...all_existing.main_driver.licence_address }

        let dataToSendToPatch: IAnyObject | undefined =
            gen_draft_differing_values_to_patch({
                existing: existing,
                draft: draft,
            })

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            let addresses_fields = [
                'residential_address',
                'correspondence_address',
            ]
            // check if NEW address inside of draft
            // in which case we do not include UID,
            // and we include the full address object:

            // isolate the address fields.

            let addressess = gen_address_insurance_entity_patch({
                data: dataToSendToPatch,
                addresses_fields: addresses_fields,
                draft_addresses_normalised: all_draft.addresses,
            })

            if (
                addressess?.residential_address &&
                addressess?.residential_address[0]
            ) {
                const address_1 = addressess?.residential_address[0]
                delete addressess['residential_address']
                addressess = {
                    ...addressess,
                    residential_address: address_1,
                }
            }

            if (
                addressess?.correspondence_address &&
                addressess?.correspondence_address[0]
            ) {
                const address_2 = addressess?.correspondence_address[0]
                delete addressess['correspondence_address']
                addressess = {
                    ...addressess,
                    correspondence_address: address_2,
                }
            }

            dataToSendToPatch = {
                customer: { ...addressess },
            }

            if (dataToSendToPatch) {
                if (dataToSendToPatch['license_issue_location'] === '') {
                    delete dataToSendToPatch['license_issue_location']
                }
            }

            // console.log('data sent to api to patch', dataToSendToPatch)

            // call api to patch as thats all the data merged we want to patch
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing?.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )
            yield put(
                insuranceActions.submit_mainDriver_licenceAddresses_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(
                insuranceActions.submit_mainDriver_licenceAddresses_success()
            )
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_maindriver_licenceAddresses,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/main_driver/${all_existing.main_driver.id}/licence_address`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_mainDriver_licenceAddresses_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_maindriver_licenceAddresses() {
    while (true) {
        yield take(insuranceActions.submit_mainDriver_licenceAddresses_request)
        yield call(Submit_maindriver_licenceAddresses)
    }
}

const maindriver_licenceAddresses_submit: any[] = [
    fork(Watcher_Submit_maindriver_licenceAddresses),
]

export default maindriver_licenceAddresses_submit
