export const createQRLogoSvgBiggerRes = (): any => {
    let elem: SVGSVGElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'svg'
    )

    elem.setAttribute('viewBox', '0 0 801 801')
    elem.setAttribute('fill', `none`)

    elem.setAttribute('shape-rendering', 'auto')

    elem.setAttribute('x', `0`)
    elem.setAttribute('y', `0`)
    // elem.setAttribute('width', `33`)
    // elem.setAttribute('height', `33`)

    const path01: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path01.setAttribute(
        'd',
        `M407.929 441.794C428.226 441.794 444.679 425.339 444.679 405.044C444.679 384.748 428.226 368.296 407.929 368.296C387.633 368.296 371.181 384.748 371.181 405.044C371.181 425.339 387.633 441.794 407.929 441.794Z`
    )
    path01.setAttribute('fill', 'white')
    path01.setAttribute('stroke', '#000000')
    path01.setAttribute('stroke-width', '1')

    elem.appendChild(path01)

    const path02: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path02.setAttribute(
        'd',
        `M411.536 424.148C415.724 423.701 419.935 421.805 423.065 418.875L419.876 416.296C417.576 418.483 414.509 419.946 411.536 420.362V424.148Z`
    )
    path02.setAttribute('fill', 'black')

    elem.appendChild(path02)

    const path03: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path03.setAttribute(
        'd',
        `M406.706 390.78C407.149 390.751 407.596 390.737 408.046 390.737H408.072C407.613 390.737 407.157 390.751 406.706 390.78C396.535 391.393 388.48 398.701 388.478 407.596C388.478 415.902 395.566 422.764 404.776 424.146V420.456C397.958 419.127 392.786 413.853 392.788 407.596C392.788 400.963 398.527 395.462 405.962 394.568C406.208 394.537 406.456 394.513 406.706 394.495C407.164 394.458 407.629 394.44 408.098 394.44C409.086 394.44 410.061 394.521 411.015 394.679C411.244 394.718 411.472 394.761 411.697 394.807C414.016 395.287 416.178 396.23 418.007 397.565C418.166 397.681 418.322 397.803 418.477 397.925H421.143L421.145 390.259H418.408C418.408 390.259 418.16 392.355 415.78 392.08V392.082C414.264 391.525 412.663 391.135 411.015 390.924C411.015 390.924 408.493 390.621 406.706 390.78Z`
    )
    path03.setAttribute('fill', 'black')

    elem.appendChild(path03)

    const path04: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )
    path04.setAttribute(
        'd',
        `M411.536 417.537C414.066 417.096 416.037 415.997 417.781 414.324L414.617 411.751C413.696 412.67 412.849 413.184 411.536 413.556V417.537Z`
    )
    path04.setAttribute('fill', 'black')

    elem.appendChild(path04)

    const path05: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path05.setAttribute(
        'd',
        `M406.262 401.59C406.892 401.447 407.552 401.372 408.233 401.372C411.082 401.492 412.568 402.102 414.614 403.9L417.778 401.327C414.059 398.535 411.816 397.446 406.262 397.816C400.964 398.527 396.162 402.799 396.16 407.835C396.16 412.255 399.7 416.091 404.703 417.538V413.592C402.498 412.456 400.549 410.165 400.551 407.835C400.551 405.201 402.433 402.933 405.126 401.927C405.491 401.791 405.869 401.677 406.262 401.59Z`
    )
    path05.setAttribute('fill', 'black')

    elem.appendChild(path05)

    const path06: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path06.setAttribute(
        'd',
        `M408.169 400.18C407.373 400.18 406.729 399.649 406.729 398.995V397.231C406.729 396.577 407.373 396.046 408.169 396.046C408.964 396.046 409.61 396.577 409.61 397.231V398.995C409.61 399.649 408.964 400.18 408.169 400.18Z`
    )
    path06.setAttribute('fill', 'black')

    elem.appendChild(path06)

    const path07: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path07.setAttribute(
        'd',
        `M408.169 427.868C407.373 427.868 406.729 427.319 406.729 426.643V404.299C406.729 403.62 407.373 403.071 408.169 403.071C408.964 403.071 409.61 403.62 409.61 404.299V426.643C409.61 427.319 408.964 427.868 408.169 427.868Z`
    )
    path07.setAttribute('fill', 'black')

    elem.appendChild(path07)

    const path08: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path08.setAttribute(
        'd',
        `M408.169 392.741C407.373 392.741 406.729 392.32 406.729 391.802V380.041C406.729 379.523 407.373 379.102 408.169 379.102C408.964 379.102 409.61 379.523 409.61 380.041V391.802C409.61 392.32 408.964 392.741 408.169 392.741Z`
    )
    path08.setAttribute('fill', 'black')

    elem.appendChild(path08)

    const path09: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path09.setAttribute(
        'd',
        `M408.15 430.97L408.906 431.383C409.178 431.533 409.495 431.612 409.823 431.612C410.759 431.612 411.524 430.962 411.524 430.163C411.524 429.365 410.759 428.714 409.823 428.714C409.514 428.714 409.211 428.786 408.947 428.92L408.179 429.314L407.425 428.901C407.15 428.751 406.833 428.672 406.506 428.672C405.569 428.672 404.805 429.322 404.805 430.123C404.805 430.919 405.569 431.57 406.506 431.57C406.817 431.57 407.12 431.499 407.382 431.364L408.15 430.97ZM409.823 434.069C409.229 434.069 408.646 433.972 408.101 433.783C407.593 433.943 407.055 434.027 406.506 434.027C403.978 434.027 401.919 432.275 401.919 430.123C401.919 427.969 403.978 426.217 406.506 426.217C407.102 426.217 407.685 426.312 408.227 426.501C408.735 426.341 409.276 426.257 409.823 426.257C412.353 426.257 414.409 428.009 414.409 430.163C414.409 432.317 412.353 434.069 409.823 434.069Z`
    )
    path09.setAttribute('fill', 'black')

    elem.appendChild(path09)

    const path10: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path10.setAttribute(
        'd',
        `M408.169 435.309C407.373 435.309 406.729 434.699 406.729 433.949V432.535C406.729 431.783 407.373 431.176 408.169 431.176C408.964 431.176 409.61 431.783 409.61 432.535V433.949C409.61 434.699 408.964 435.309 408.169 435.309Z`
    )
    path10.setAttribute('fill', 'black')

    elem.appendChild(path10)

    const path11: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path11.setAttribute(
        'd',
        `M413.954 431.174H412.475C411.69 431.174 411.052 430.62 411.052 429.935C411.052 429.25 411.69 428.695 412.475 428.695H413.954C414.739 428.695 415.377 429.25 415.377 429.935C415.377 430.62 414.739 431.174 413.954 431.174Z`
    )
    path11.setAttribute('fill', 'black')

    elem.appendChild(path11)

    const path12: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path12.setAttribute(
        'd',
        `M403.867 431.174H402.388C401.603 431.174 400.967 430.62 400.967 429.935C400.967 429.25 401.603 428.695 402.388 428.695H403.867C404.654 428.695 405.29 429.25 405.29 429.935C405.29 430.62 404.654 431.174 403.867 431.174Z`
    )
    path12.setAttribute('fill', 'black')

    const path13: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path13.setAttribute(
        'd',
        `M413.94 387.242V388.327C413.94 388.483 414.692 388.611 415.621 388.611C416.55 388.611 417.302 388.483 417.302 388.327V387.242C417.302 387.085 416.55 386.957 415.621 386.957C414.692 386.957 413.94 387.085 413.94 387.242Z`
    )
    path13.setAttribute('fill', 'black')

    const path14: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path14.setAttribute(
        'd',
        `M413.94 379.8V380.885C413.94 381.041 414.692 381.169 415.621 381.169C416.55 381.169 417.302 381.041 417.302 380.885V379.8C417.302 379.643 416.55 379.515 415.621 379.515C414.692 379.515 413.94 379.643 413.94 379.8Z`
    )
    path14.setAttribute('fill', 'black')

    const path15: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path15.setAttribute(
        'd',
        `M413.94 380.543V379.728C413.94 379.611 414.692 379.515 415.621 379.515C416.55 379.515 417.302 379.611 417.302 379.728V380.543C417.302 380.661 416.55 380.755 415.621 380.755C414.692 380.755 413.94 380.661 413.94 380.543Z`
    )
    path15.setAttribute('fill', 'black')

    const path16: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path16.setAttribute(
        'd',
        `M413.932 384.062C413.932 384.062 413.932 382.284 413.932 381.794C413.932 381.412 413.828 381.17 413.566 381.17C413.209 381.17 413.068 381.788 412.837 382.048C412.605 382.308 412.489 382.475 412.489 384.062C412.489 385.651 412.605 385.818 412.837 386.078C413.068 386.338 413.209 386.956 413.566 386.956C413.828 386.956 413.932 386.712 413.932 386.332C413.932 385.842 413.932 384.062 413.932 384.062Z`
    )
    path16.setAttribute('fill', 'black')

    const path17: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path17.setAttribute(
        'd',
        `M413.424 380.572C413.424 380.948 413.061 381.253 412.611 381.253C412.162 381.253 411.799 380.948 411.799 380.572C411.799 380.196 412.162 379.891 412.611 379.891C413.061 379.891 413.424 380.196 413.424 380.572ZM413.424 387.552C413.424 387.928 413.061 388.233 412.611 388.233C412.162 388.233 411.799 387.928 411.799 387.552C411.799 387.176 412.162 386.872 412.611 386.872C413.061 386.872 413.424 387.176 413.424 387.552ZM411.447 380.285C411.396 380.482 411.402 380.633 411.425 380.74C411.097 380.828 410.591 380.968 410.252 381.072L410.157 381.108C409.941 381.173 409.768 381.24 409.632 381.34L409.628 381.344C409.559 381.395 409.498 381.456 409.445 381.531L409.429 381.557L409.39 381.62L409.384 381.635L409.374 381.653L409.366 381.667L409.358 381.687C409.21 382.015 409.153 382.537 409.136 383.443C409.136 383.553 409.134 383.669 409.134 383.791C409.132 383.878 409.132 383.968 409.132 384.061C409.132 386.455 409.287 386.758 410.157 387.016L410.252 387.05C410.591 387.156 411.097 387.294 411.425 387.384C411.402 387.491 411.396 387.642 411.447 387.839C411.522 388.138 411.977 388.607 413.152 388.607C414.676 388.607 415.859 387.441 415.859 386.046C415.859 384.65 415.859 384.061 415.859 384.061C415.859 384.061 415.859 383.474 415.859 382.078C415.859 380.684 414.676 379.515 413.152 379.515C411.977 379.515 411.522 379.986 411.447 380.285Z`
    )
    path17.setAttribute('fill', 'black')

    const path18: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path18.setAttribute(
        'd',
        `M414.41 388.197V379.932C414.767 380.056 415.09 380.208 415.371 380.385V387.746C415.09 387.923 414.767 388.075 414.41 388.197Z`
    )
    path18.setAttribute('fill', 'black')

    const path19: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path19.setAttribute(
        'd',
        `M414.651 387.784C414.784 387.784 414.891 387.687 414.891 387.567V380.149C414.891 380.03 414.784 379.932 414.651 379.932C414.517 379.932 414.41 380.03 414.41 380.149V387.567C414.41 387.687 414.517 387.784 414.651 387.784Z`
    )
    path19.setAttribute('fill', 'black')

    const path20: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path20.setAttribute(
        'd',
        `M413.94 386.748C413.94 386.862 414.046 386.953 414.178 386.953H415.141C415.274 386.953 415.381 386.862 415.381 386.748C415.381 386.632 415.274 386.541 415.141 386.541H414.178C414.046 386.541 413.94 386.632 413.94 386.748Z`
    )
    path20.setAttribute('fill', 'black')

    elem.appendChild(path20)

    const path21: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path21.setAttribute(
        'd',
        `M413.94 381.378C413.94 381.491 414.046 381.583 414.178 381.583H415.141C415.274 381.583 415.381 381.491 415.381 381.378C415.381 381.262 415.274 381.17 415.141 381.17H414.178C414.046 381.17 413.94 381.262 413.94 381.378Z`
    )
    path21.setAttribute('fill', 'black')

    elem.appendChild(path21)

    const path22: SVGPathElement = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
    )

    path22.setAttribute(
        'd',
        `M414.893 382.893H415.295V385.236H414.893V388.197C415.476 387.736 415.854 386.941 415.854 386.051C415.854 384.655 415.854 384.066 415.854 384.066C415.854 384.066 415.854 383.476 415.854 382.078C415.854 381.19 415.476 380.393 414.893 379.932V382.893Z`
    )
    path22.setAttribute('fill', 'black')
    elem.appendChild(path22)

    return elem
}
