import {
    IDropdownItem,
    ITechnicalInformationObject,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import { ILabel, IUser } from 'myModels'
import * as React from 'react'
import styled from 'styled-components'
import {
    ITimelineItem,
    IUpdateEntryLabelPayload,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'
// import AddLabelButton from '../../atoms/Button/addLabelButton'
import DatePickerAntd from '../../atoms/datePickerAntd/datePickerAntd'
import CustomIconTagQuickFilterItemDesktop from '../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemDesktop'
import CustomTagQuickFilterItemDesktop from '../../atoms/tags/customTagQuickFilterItem/customTagQuickFilterItemDesktop'
import FilterByMultipleWSearchWFuse from '../../organisms/filterByMultiple/labels/labelsFilterByMultipleWSearchWFuseDesktop'
import MileageEditOrCreateModeDesktop from '../editOrCreateModeSingleFields/mileageEditOrCreateMode/mileageEditOrCreateModeDesktop'
import { writeTechnicalInformationRequest } from '../../../redux/entities/technical_information/actions/loadingActions'
import {
    removeLabelFromCarEntryRequest,
    updateTimelineEntryRequest,
} from '../../../redux/timeline/actions/requestActions'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { getCarDataByIdRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { timeline_category_data } from '../../../redux/timeline/data'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import CalendarIcon from '../../atoms/icons/components/calendar'
import colours from '../../../providers/theme/colours'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

// interface IStyle {}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
`

const MenuTitle = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-muted, #b3b3b3);
    font-family: Lato-semibold;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 8px;
`

const CategoryWrapper = styled.div`
    cursor: not-allowed;
`

const LabelsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`

const ColumnWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const CategoryText = styled.div`
    color: var(--bg-color, #fff);
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
    text-transform: capitalize;
`

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        technicalInformationData:
            state.entities.technicalInformationData.technical_information,
        user_searchable_labels_list:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
        entry_labels_list:
            state.timeline.activeEntry && state.timeline.activeEntry.labels,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) =>
        updateTimelineEntryRequest(payload),
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) =>
        removeLabelFromCarEntryRequest(payload),
    getCarDataByIdRequest: (id: string) => getCarDataByIdRequest(id),
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => writeTechnicalInformationRequest(p),
}

interface Props {
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    carid: string
    activeEntry: ITimelineItem
    noAddLabelButton?: boolean
    user_searchable_labels_list?: IDropdownItem[] | null | undefined
    onAddLabels?: any
    onRemoveLabel?: any
    entry_labels_list?: ILabel[] | undefined
    onEditDate?: any
    readOnlyMode?: boolean
    userDistanceUnit?: string | null
    updateTimelineEntry: any
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => void
    technicalInformationData?: ITechnicalInformationObject
    getCarDataByIdRequest: (id: string) => void
    removeLabelFromCarEntry: (payload: IUpdateEntryLabelPayload) => void
}

class EntryOptionsMenuBar extends React.Component<Props> {
    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }
        let tech_info_in_state =
            this.props.technicalInformationData &&
            this.props.technicalInformationData[`hf-${this.props.carid}`]
        if (!tech_info_in_state && !this.props.readOnlyMode) {
            this.props.getCarDataByIdRequest(this.props.carid)
        }
    }

    generatePrePopulatedLabels = (): IDropdownItem[] => {
        let { activeEntry, user_searchable_labels_list } = this.props

        let user_searchable_labels_list_names =
            user_searchable_labels_list?.map((label) => label.name)

        if (activeEntry && activeEntry.categoryID) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                activeEntry.categoryID
            ].pre_populated_labels
                .filter(
                    (label) =>
                        !user_searchable_labels_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    render() {
        const {
            activeEntry,
            noAddLabelButton,
            user_searchable_labels_list,
            entry_labels_list,
            updateTimelineEntry,
            writeTechnicalInformationRequest,
            technicalInformationData,
            removeLabelFromCarEntry,
            carid,
            userLoggedIn,
            readOnlyMode,
        } = this.props

        const handleDateChange = (dateString: any, fieldID?: string) => {
            if (!readOnlyMode && activeEntry) {
                let editedEntry = { ...activeEntry }
                if (!fieldID) {
                    editedEntry.date = dateString
                    updateTimelineEntry({
                        carid: carid,
                        item: editedEntry,
                    })
                } else {
                    editedEntry[fieldID] = dateString
                    updateTimelineEntry({
                        carid: carid,
                        item: editedEntry,
                    })
                }
            }
        }

        const onRemoveLabel = (labelname: string) => {
            let labelToRemove =
                activeEntry &&
                activeEntry.labels?.filter((label) => label.name === labelname)

            labelToRemove &&
                removeLabelFromCarEntry({
                    car_id: carid,
                    entry_id: activeEntry && activeEntry.id,
                    labels: labelToRemove,
                })
        }

        let userDistanceUnit =
            userLoggedIn &&
            userLoggedIn.preferences.data.units.data.distance_unit
                .user_choice_id

        let carMileageFromState: number | string | undefined | null =
            carid &&
            technicalInformationData &&
            technicalInformationData[`hf-${carid}`] &&
            technicalInformationData[`hf-${carid}`].mileage &&
            technicalInformationData[`hf-${carid}`].mileage.answer

        let carMileage: number | undefined | null =
            typeof carMileageFromState === 'string'
                ? parseInt(carMileageFromState)
                : carMileageFromState

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper>
                        {activeEntry.categoryID === 'insurance' ? (
                            <ColumnWrapper>
                                <MenuTitle>
                                    {activeEntry.categoryID === 'insurance'
                                        ? 'Start Date'
                                        : 'Date'}
                                </MenuTitle>

                                <div
                                    onClick={(e: any) =>
                                        this.props.readOnlyMode
                                            ? e.preventDefault()
                                            : {}
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <DatePickerAntd
                                        onChange={(dateStr: any) => {
                                            handleDateChange(
                                                dateStr,
                                                'insurance_entry_policy_start_date'
                                            )
                                            // handleDateChange(dateStr, 'date')
                                            // const aYearFromNow = new Date(dateStr)
                                            // aYearFromNow.setFullYear(
                                            //     aYearFromNow.getFullYear() + 1
                                            // )

                                            // handleDateChange(
                                            //     aYearFromNow,
                                            //     'insurance_entry_policy_end_date'
                                            // )
                                        }}
                                        value={activeEntry.date}
                                        readOnlyMode={this.props.readOnlyMode}
                                        additionalStyle={` background: transparent; border-radius: 4px; box-shadow: none; padding: 8px; width: 100%;`}
                                        suffixIcon={<CalendarIcon />}
                                        allowClear={false}
                                        clearIcon={null}
                                        customFormat={['DD.MM.YYYY']}
                                        allowOnlyPast
                                    />
                                </div>

                                <div style={{ paddingTop: '16px' }} />

                                <MenuTitle>End Date</MenuTitle>

                                <div
                                    onClick={(e: any) =>
                                        this.props.readOnlyMode
                                            ? e.preventDefault()
                                            : {}
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <DatePickerAntd
                                        onChange={(dateStr: any) => {
                                            handleDateChange(
                                                dateStr,
                                                'insurance_entry_policy_end_date'
                                            )
                                        }}
                                        value={
                                            activeEntry.insurance_entry_policy_end_date
                                        }
                                        readOnlyMode={this.props.readOnlyMode}
                                        additionalStyle={` background: transparent; border-radius: 4px; box-shadow: none; padding: 8px; width: 100%;`}
                                        suffixIcon={<CalendarIcon />}
                                        allowClear={false}
                                        clearIcon={null}
                                        customFormat={['DD.MM.YYYY']}
                                    />
                                </div>
                            </ColumnWrapper>
                        ) : (
                            <ColumnWrapper>
                                <MenuTitle>Date</MenuTitle>

                                <div
                                    onClick={(e: any) =>
                                        this.props.readOnlyMode
                                            ? e.preventDefault()
                                            : {}
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <DatePickerAntd
                                        onChange={handleDateChange}
                                        value={activeEntry.date}
                                        readOnlyMode={this.props.readOnlyMode}
                                        additionalStyle={` background: transparent; border-radius: 4px; box-shadow: none; padding: 8px; width: 100%;`}
                                        suffixIcon={<CalendarIcon />}
                                        allowClear={false}
                                        clearIcon={null}
                                        customFormat={['DD.MM.YYYY']}
                                        allowOnlyPast
                                    />
                                </div>
                            </ColumnWrapper>
                        )}

                        <ColumnWrapper>
                            <MenuTitle>Mileage</MenuTitle>

                            <MileageEditOrCreateModeDesktop
                                title={'Mileage at time of entry'}
                                value_start={activeEntry.mileage}
                                onSubmit={(payload: {
                                    updateCarMileage: boolean
                                    entryMileage: number
                                    customCarMileage: number
                                    user_distance_unit: string
                                }) => {
                                    let editedItem = {
                                        ...activeEntry,
                                    }
                                    editedItem.mileage = payload.entryMileage

                                    updateTimelineEntry({
                                        carid: carid,
                                        item: editedItem,
                                    })

                                    let payloadFoBe:
                                        | IwriteTechnicalInformationPayloadReq
                                        | undefined = carid
                                        ? {
                                              tech_info_id: `hf-${carid}`,
                                              car_id: carid,
                                              data: {
                                                  id: 'mileage',
                                                  text: 'mileage',
                                                  answer: payload.customCarMileage,
                                              },
                                          }
                                        : undefined

                                    payload.updateCarMileage &&
                                        payloadFoBe &&
                                        writeTechnicalInformationRequest(
                                            payloadFoBe
                                        )
                                }}
                                carMileage={carMileage}
                                userDistanceUnits={userDistanceUnit}
                                dataCyId={'edit_entry_mileage'}
                                customMileageDataCyId={
                                    'edit_car_mileage_from_entry_with_custom_value'
                                }
                                readOnlyMode={readOnlyMode}
                            />
                        </ColumnWrapper>

                        <CategoryWrapper>
                            <MenuTitle>Category</MenuTitle>
                            <CustomIconTagQuickFilterItemDesktop
                                height="24px"
                                id={activeEntry.categoryID}
                            >
                                <CategoryText>
                                    {activeEntry.categoryID}
                                </CategoryText>
                            </CustomIconTagQuickFilterItemDesktop>
                        </CategoryWrapper>
                        <ColumnWrapper>
                            <MenuTitle>Labels</MenuTitle>
                            <LabelsContainer>
                                {activeEntry.labels &&
                                    activeEntry.labels.map(
                                        (label: ILabel, i: number) => (
                                            <div
                                                style={{
                                                    paddingRight: '10px',
                                                    paddingTop: '10px',
                                                }}
                                                key={`label-${label} - ${i}_desktop`}
                                            >
                                                {this.props.readOnlyMode ? (
                                                    <CustomTagQuickFilterItemDesktop
                                                        bgcolor={
                                                            colours[theme]
                                                                .background_default
                                                        }
                                                        height="24px"
                                                    >
                                                        {label.name}
                                                    </CustomTagQuickFilterItemDesktop>
                                                ) : (
                                                    <CustomTagQuickFilterItemDesktop
                                                        bgcolor={
                                                            colours[theme]
                                                                .background_default
                                                        }
                                                        onCrossClick={() =>
                                                            onRemoveLabel(
                                                                label.name
                                                            )
                                                        }
                                                        height="24px"
                                                        hasBoxShadow
                                                    >
                                                        {label.name}
                                                    </CustomTagQuickFilterItemDesktop>
                                                )}
                                            </div>
                                        )
                                    )}
                                {!noAddLabelButton && (
                                    <div
                                        style={{
                                            paddingRight: '10px',
                                            paddingTop: '10px',
                                        }}
                                    >
                                        {/* <AddLabelButton
                            height="24px"
                            onClick={this.props.onAddLabel}
                        /> */}
                                        <FilterByMultipleWSearchWFuse
                                            addLabelButton
                                            category_searchable_items_list={
                                                undefined
                                            }
                                            user_searchable_labels_list={
                                                user_searchable_labels_list
                                                    ? user_searchable_labels_list.concat(
                                                          this.generatePrePopulatedLabels()
                                                      )
                                                    : this.generatePrePopulatedLabels()
                                            }
                                            onSave={(
                                                selectedLabels: ILabel[]
                                            ) => {
                                                let editedEntry: ITimelineItem =
                                                    Object.assign(
                                                        {},
                                                        activeEntry
                                                    )
                                                editedEntry.labels =
                                                    selectedLabels
                                                updateTimelineEntry({
                                                    carid: carid,
                                                    item: editedEntry,
                                                })
                                            }}
                                            hasCreateNewLabel
                                            entry_labels_list={
                                                entry_labels_list
                                                    ? entry_labels_list
                                                    : []
                                            }
                                        />
                                    </div>
                                )}
                            </LabelsContainer>
                        </ColumnWrapper>
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(EntryOptionsMenuBar)
