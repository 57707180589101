import styled from 'styled-components'
import React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import TimelineSearchBarMobile from '../../searchbar/timeline/timelineSearchBarMobile'
import Expander from '../../../atoms/expander/expander'
import { RootState } from 'typesafe-actions'
import { setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest } from '../../../../redux/timeline/actions/requestActions'
import {
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    ITimelineEntryTypes,
} from 'timelineModels'
import BottomRowWSelectedLabels from './bottomRowWSelectedLabels'
import { device } from '../../../templates/displays/devices'
import ScrollLock from 'react-scrolllock'
import { IDropdownItem } from 'entityModels'
import { ILabel } from 'myModels'
import ChevronLeft from '../../../atoms/icons/components/chevronLeft'
import FilterIcon from '../../../atoms/icons/components/filterIcon'
import colours from '../../../../providers/theme/colours'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'

type StyledProps = {
    issearchactive?: boolean | undefined
}

const HeaderWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.issearchactive
            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
            : 'var(--bg-color, #fff)'};
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    box-shadow: ${(props) =>
        props.issearchactive
            ? 'none'
            : '0px 2px 9px -2px var(--box-shadow, rgba(0, 0, 0, 0.08))'};
    padding-left: 20px;
    padding-right: 25px;
    padding-bottom: ${(props) => (props.issearchactive ? '65px' : '30px')};
    padding-top: 20px;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 5;
    @media (min-width: 800px) and (max-width: 881px) {
        margin-left: -30px;
    }
`

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 88vw;
    padding-bottom: 20px;
`

const Return = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 18px;
    line-height: 32px;
    padding-left: 12px;

    @media ${device.desktop} {
        display: none;
    }
`

const TopLeftSide = styled(Link)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const TopRightSide = styled.button`
    border: none;
    background-color: transparent;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const BottomRowOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        var(--bg-color, #fff),
        var(--bg-color, #fff)
    );
`

const StopSearchZone = styled.div<StyledProps>`
    display: ${(props) => (!props.issearchactive ? 'none' : 'auto')};
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: var(--off-bg-color, #fafafa);
    left: 0;
    right: 0;
    z-index: 0;
    opacity: 0.7;
    z-index: 4;
`

const dispatchProps = {
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds: (
        payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
    ) =>
        setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest(
            payload
        ),
    // filterTimelineItemsByCarCategoryIdsNLabelIds: (
    //     payload: IFilterTimelineByCategoryNLabelCarIDRequest
    // ) => filterTimelineItemsByCarCategoryIdsNLabelIdsRequest(payload),
}

function mapStateToProps(state: RootState) {
    return {
        category_searchable_items_list:
            state.timeline.categories.category_searchable_items_list,
        userTimelineCustomTags:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
    }
}

type State = {
    issearchactive: boolean
    // activeCategories: string[]
    // activeLabels: string[]
}

interface Props extends RouteComponentProps<any> {
    returnUrl: string
    onFilterIconClick: (isOpen: boolean) => void
    category_searchable_items_list: IDropdownItem[]
    userTimelineCustomTags: ILabel[] | null | undefined
    changeView: () => void
    carid: string
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds: (
        payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
    ) => void
    // filterTimelineItemsByCarCategoryIdsNLabelIds: any
    activeCategoryIds: ITimelineEntryTypes[]
    activeCustomLabelsIds: string[]
    activeEntryTitleQuery: string | undefined
    sendFiltersToApi: (payload: {
        categories: ITimelineEntryTypes[]
        labels: string[]
        entry_title?: string
    }) => void
    updateIsSearching: (isSearching: boolean) => void
    currentSearch: string | undefined
}

class TopHeaderFilterNavMobile extends React.PureComponent<Props, State, {}> {
    state = {
        issearchactive: false,
    }

    manageissearchactive = (isActive: boolean) => {
        this.setState({
            ...this.state,
            issearchactive: isActive,
        })
        this.props.updateIsSearching(isActive)
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {}

    componentDidUpdate(prevProps: any) {}

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {}

    render() {
        const {
            returnUrl,
            category_searchable_items_list,
            userTimelineCustomTags,
            onFilterIconClick,
            activeCustomLabelsIds,
            activeCategoryIds,
            activeEntryTitleQuery,
            carid,
            setFilterTimelineItemsByCarIdCategoryIdsNLabelIds,
            sendFiltersToApi,
            currentSearch,
        } = this.props

        const { issearchactive } = this.state

        let currentSelectedLabels: string[] = activeCustomLabelsIds
            ? activeCustomLabelsIds
            : []
        let currentSelectedCategories: ITimelineEntryTypes[] = activeCategoryIds
            ? activeCategoryIds
            : []

        // let currentTypedActiveEntryTitleQuery: string = activeEntryTitleQuery
        //     ? activeEntryTitleQuery
        //     : ''

        const addCategory = (criteria: ITimelineEntryTypes) => {
            let indexOfClickedCriteria: number =
                currentSelectedCategories.indexOf(criteria)

            if (indexOfClickedCriteria < 0) {
                currentSelectedCategories.push(criteria)
            }

            currentSelectedCategories.length > 0 &&
                setFilterTimelineItemsByCarIdCategoryIdsNLabelIds({
                    car_id: carid,
                    label_ids: activeCustomLabelsIds,
                    category_ids: currentSelectedCategories,
                })
        }

        const addFreeTitleQuery = (q: string) => {
            setFilterTimelineItemsByCarIdCategoryIdsNLabelIds({
                car_id: carid,
                label_ids: activeCustomLabelsIds,
                category_ids: activeCategoryIds,
                entry_title: q,
            })
        }

        const removeCategory = (criteria: ITimelineEntryTypes) => {
            let indexOfClickedCriteria: number =
                currentSelectedCategories.indexOf(criteria)

            if (indexOfClickedCriteria >= 0) {
                currentSelectedCategories.splice(indexOfClickedCriteria, 1)
            }

            if (currentSelectedCategories.length >= 0) {
                sendFiltersToApi({
                    categories: currentSelectedCategories,
                    labels: activeCustomLabelsIds,
                })
            }
        }

        const addLabels = (criteria: string) => {
            let indexOfClickedCriteria: number =
                currentSelectedLabels.indexOf(criteria)
            if (indexOfClickedCriteria < 0) {
                currentSelectedLabels.push(criteria)
            }
            currentSelectedLabels.length > 0 &&
                setFilterTimelineItemsByCarIdCategoryIdsNLabelIds({
                    car_id: carid,
                    label_ids: currentSelectedLabels,
                    category_ids: activeCategoryIds,
                })
        }

        const removeLabels = (criteria: string) => {
            let indexOfClickedCriteria: number =
                currentSelectedLabels.indexOf(criteria)

            if (indexOfClickedCriteria >= 0) {
                currentSelectedLabels.splice(indexOfClickedCriteria, 1)
            }

            if (currentSelectedLabels.length >= 0) {
                sendFiltersToApi({
                    categories: activeCategoryIds,
                    labels: currentSelectedLabels,
                })
            }
        }

        let labelsArray: any[] = userTimelineCustomTags
            ? userTimelineCustomTags.map((label: { name: any }) => label.name)
            : []

        let categoriesArray: any = category_searchable_items_list
            ? category_searchable_items_list.map(
                  (category: IDropdownItem) => category.name
              )
            : []

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        <StopSearchZone
                            onClick={() => {
                                this.manageissearchactive(false)

                                // sendFiltersToApi({
                                //     categories: activeCategoryIds,
                                //     labels: activeCustomLabelsIds,
                                //     entry_title: activeEntryTitleQuery,
                                // })
                            }}
                            issearchactive={issearchactive}
                        />
                        <HeaderWrapper issearchactive={issearchactive}>
                            <Expander
                                duration={80}
                                height={!issearchactive ? 'auto' : 0}
                            >
                                <TopRow>
                                    <TopLeftSide
                                        to={{
                                            pathname: returnUrl,
                                            state: {
                                                prevPath:
                                                    this.props.history.location
                                                        .pathname,
                                            },
                                        }}
                                    >
                                        <ChevronLeft />
                                        <Return>Go back</Return>
                                    </TopLeftSide>
                                    <TopRightSide
                                        onClick={() => onFilterIconClick(true)}
                                    >
                                        <FilterIcon
                                            color={colours[theme].text_muted}
                                        />
                                    </TopRightSide>
                                </TopRow>
                            </Expander>

                            {issearchactive && <ScrollLock />}

                            <TimelineSearchBarMobile
                                placeholder="Search for title or tag"
                                currentSearch={currentSearch}
                                manageissearchactive={this.manageissearchactive}
                                issearchactive={issearchactive}
                                // cancelText=" "
                                category_searchable_items_list={
                                    category_searchable_items_list
                                }
                                user_searchable_labels_list={
                                    userTimelineCustomTags
                                        ? userTimelineCustomTags
                                        : []
                                }
                                addCriteria={(
                                    name: string | ITimelineEntryTypes
                                ) => {
                                    // if activeFreeTitleQuery === name. ex: test !== te
                                    // redux updates ==> freeTitleQuery = undefined :
                                    if (activeEntryTitleQuery !== name) {
                                        setFilterTimelineItemsByCarIdCategoryIdsNLabelIds(
                                            {
                                                car_id: carid,
                                                label_ids:
                                                    activeCustomLabelsIds,
                                                category_ids: activeCategoryIds,
                                                entry_title: undefined,
                                            }
                                        )
                                    }

                                    if (
                                        labelsArray &&
                                        labelsArray.includes(name)
                                    ) {
                                        addLabels(name)

                                        sendFiltersToApi({
                                            categories: activeCategoryIds,
                                            labels: activeCustomLabelsIds,
                                            entry_title: activeEntryTitleQuery,
                                        })

                                        this.manageissearchactive(false)
                                    } else if (
                                        categoriesArray &&
                                        categoriesArray.includes(name)
                                    ) {
                                        let m: ITimelineEntryTypes =
                                            name as ITimelineEntryTypes
                                        addCategory(m)

                                        sendFiltersToApi({
                                            categories: activeCategoryIds,
                                            labels: activeCustomLabelsIds,
                                            entry_title: activeEntryTitleQuery,
                                        })
                                        this.manageissearchactive(false)
                                    } else {
                                        // update Redux : activeFreeTitleQuery == te
                                        addFreeTitleQuery(name)
                                        sendFiltersToApi({
                                            categories: activeCategoryIds,
                                            labels: activeCustomLabelsIds,
                                            entry_title: name,
                                        })
                                        this.manageissearchactive(false)
                                    }
                                }}
                                checkIfSelectedEntryOrLabel={(name: string) => {
                                    if (
                                        labelsArray &&
                                        labelsArray.includes(name)
                                    ) {
                                        return true
                                    } else if (
                                        categoriesArray &&
                                        categoriesArray.includes(name)
                                    ) {
                                        return true
                                    } else return false
                                }}
                                clearEntryTitleSearch={() =>
                                    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds(
                                        {
                                            car_id: carid,
                                            label_ids: activeCustomLabelsIds,
                                            category_ids: activeCategoryIds,
                                            entry_title: undefined,
                                            sendToAPI: true,
                                        }
                                    )
                                }
                                finalSearchToApi={() => {
                                    this.manageissearchactive(false)

                                    sendFiltersToApi({
                                        categories: activeCategoryIds,
                                        labels: activeCustomLabelsIds,
                                        entry_title: activeEntryTitleQuery,
                                    })
                                }}
                            />
                            <div style={{ paddingTop: '10px' }} />
                            {((activeCategoryIds &&
                                activeCategoryIds.length > 0) ||
                                (activeCustomLabelsIds &&
                                    activeCustomLabelsIds.length > 0)) && (
                                <div
                                    style={{
                                        width: '100%',
                                        position: 'relative',
                                    }}
                                >
                                    <BottomRowOverlay />
                                    <BottomRowWSelectedLabels
                                        labels={activeCustomLabelsIds}
                                        categories={
                                            activeCategoryIds
                                                ? activeCategoryIds
                                                : []
                                        }
                                        onCategoryCrossClick={(
                                            item: ITimelineEntryTypes
                                        ) => removeCategory(item)}
                                        onLabelCrossClick={(item: string) =>
                                            removeLabels(item)
                                        }
                                        clearFilters={() =>
                                            setFilterTimelineItemsByCarIdCategoryIdsNLabelIds(
                                                {
                                                    car_id: carid,
                                                    label_ids: [],
                                                    category_ids: [],
                                                    entry_title:
                                                        activeEntryTitleQuery,
                                                    sendToAPI: true,
                                                    resetResults: true,
                                                }
                                            )
                                        }
                                    />
                                </div>
                            )}
                        </HeaderWrapper>
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(TopHeaderFilterNavMobile)
)
