import styled from 'styled-components'

type Props = {
    name: string
}

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border: 1px solid var(--yellow-border-default, #fac86f);
    background: var(--base-overlays-yello-alpha-200);
    padding: 26px;
    border-radius: 5px;
`

const Text = styled.p`
    letter-spacing: 0.0001em;
    color: #95702d;
    font-size: 12px;
    font-style: 'Lato';
`
const NoDropdownDataBox = (props: Props) => {
    return (
        <Wrapper>
            <Text>
                The current search results do not include any {props.name}s.
            </Text>

            <div style={{ paddingTop: '10px' }} />
            <Text>
                This is either because there are no listings, or the remaining
                listings do not have their {props.name} configured.
            </Text>
        </Wrapper>
    )
}

export default NoDropdownDataBox
