import React from 'react'
import styled from 'styled-components'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
// import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FadedSlower from '../../../templates/animated/FadedSlower'
// import FilterButton from '../../../atoms/Button/filterButton'
import Expander from '../../../atoms/expander/expander'
import SearchIcon from '../../../atoms/statefulicons/SearchIcon'
import FullWidthLightGreyBgButton from '../../../atoms/Button/fullWidthLightGreyBg'
import { motion } from 'framer-motion'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import { device } from '../../../templates/displays/devices'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { ITimelineItem } from 'timelineModels'
import { ILabel } from 'myModels'

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    labels_searchable_list?: any
    value?: any
    handleChange?: any
    fetchItem?: any
    labelsDataRes?: any
    hasInnerSaveBtn?: boolean
    addToEntry?: any
    editData?: IEditOrCreateModeSingleFieldsProps
    clearField?: any
    activeEntry: ITimelineItem | undefined
}

// bug was due to nested child component being one step back to getting updated data from parent
// and we couildn't check in compDidUpdate since when edits are made, condition will always be true
function mapStateToProps(state: RootState) {
    return {
        activeEntry: state.timeline.activeEntry,
    }
}

type IStyle = {
    selected?: boolean
    issearchactive?: boolean
    top?: number
}

const SearchFieldWrapper = styled.div`
    width: 100%;
    padding-bottom: 10px;
    padding-top: 20px;
`

const SearchField = styled.input<IStyle>`
    border: ${(props) =>
        props.issearchactive
            ? '1px solid var(--primary, #5ec3ca)'
            : '1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 5px;
    font-family: Lato;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);

    padding-left: 40px;
    ::placeholder {
        color: var(--text-muted);
    }
    :active {
        border: 1px solid var(--primary, #5ec3ca);
    }
`

const SearchIconWrapper = styled.div<IStyle>`
    position: absolute;
    z-index: 2;
    margin-top: 18px;
    margin-left: 15px;
`

const DropdownOptions = styled.ul`
    position: relative;
    z-index: 2;
    margin: 0px;
    width: 100%;
    padding: 0px;

    text-transform: capitalize;
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    padding-left: 20px;
`

const StyledOptionBtn = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: transparent;
    padding-left: 20px;
    color: var(--primary, #5ec3ca);
    font-size: 16px;
    background-color: var(--background_primary_soft);
    border-radius: 5px;
`

const Count = styled.div`
    padding-left: 20px;
    padding-top: 12px;
    font-style: italic;
    color: var(--text-muted);
    font-family: 'Lato-Light';
    font-size: 12px;
    text-transform: lowercase;
`

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    min-height: 40vh;
    z-index: 2;
    @media ${device.ipad} {
        padding-left: 25px;
    }
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

const SelectedCriteriaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 0px;
`

type StateSelect = {
    showDropdown: boolean
    addedCriteria: string[]
    issearchactive: boolean
}

class LabelsFilterByMultipleWithSearchUIMobile extends React.Component<
    Props,
    StateSelect
> {
    state = {
        showDropdown: false,
        addedCriteria: [],
        issearchactive: false,
    }

    _isMounted = false

    private dropdowRef = React.createRef<HTMLDivElement>()
    private searchFieldRef = React.createRef<HTMLInputElement>()

    handleClickOutsideDropdown(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false) {
            this.setState({ showDropdown: false })
        }
    }

    handleClickOutsideSearch(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false) {
            this.setState({ issearchactive: false })
        }
    }

    componentDidMount() {
        this._isMounted = true

        this.dropdowRef.current!.focus()
        let dropdownNode = this.dropdowRef.current
        let searchNode = this.searchFieldRef.current
        document.addEventListener('mousedown', (e: any) => {
            this.handleClickOutsideDropdown(e, dropdownNode)
            this.handleClickOutsideSearch(e, searchNode)
        })

        let valueStart =
            this.props.activeEntry &&
            this.props.activeEntry.labels &&
            this.props.activeEntry.labels.map((label: ILabel) => label.name)

        this._isMounted &&
            valueStart &&
            this.setState({ ...this.state, addedCriteria: valueStart })
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.activeEntry !== this.props.activeEntry) {
            let valueStart =
                this.props.activeEntry &&
                this.props.activeEntry.labels &&
                this.props.activeEntry.labels.map((label: ILabel) => label.name)

            valueStart &&
                this.setState({ ...this.state, addedCriteria: valueStart })
        }
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', (e: any) => {
            this.handleClickOutsideDropdown(e)
            this.handleClickOutsideSearch(e)
        })

        this._isMounted = false
    }

    render() {
        const {
            dataCyId,
            labelsDataRes,
            handleChange,
            fetchItem,
            value,
            hasInnerSaveBtn,
            labels_searchable_list,
            addToEntry,
            editData,
            clearField,
        } = this.props

        let currentSelectedCriteria: string[] = [...this.state.addedCriteria]

        const addOrRemoveCriteria = (criteria: string) => {
            let indexOfClickedCriteria: number =
                currentSelectedCriteria.indexOf(criteria)

            if (indexOfClickedCriteria < 0) {
                currentSelectedCriteria = [
                    ...this.state.addedCriteria,
                    criteria,
                ]
                this.setState({
                    addedCriteria: currentSelectedCriteria,
                })
                let submittedLabels = currentSelectedCriteria.map(
                    (criteria) => {
                        let label = {
                            name: criteria,
                        }
                        return label
                    }
                )
                editData &&
                    addToEntry(
                        editData.id,
                        submittedLabels,
                        editData.submitCreationConfigs?.entityType,
                        editData.submitCreationConfigs?.action
                    )
            } else {
                currentSelectedCriteria.splice(indexOfClickedCriteria, 1)
                this.setState({
                    addedCriteria: currentSelectedCriteria,
                })
                let submittedLabels = currentSelectedCriteria.map(
                    (criteria) => {
                        let label = {
                            name: criteria,
                        }
                        return label
                    }
                )
                editData &&
                    addToEntry(
                        editData.id,
                        submittedLabels,
                        editData.submitCreationConfigs?.entityType,
                        editData.submitCreationConfigs?.action
                    )
            }
        }

        let addedCriteria = this.state.addedCriteria.map(
            (criteria: string, i: number) => (
                <div style={{ padding: '3px' }} key={`${criteria} - ${i}`}>
                    <FadedSlower duration={0.2}>
                        <CustomIconTagQuickFilterItem
                            isActive={true}
                            onCrossClick={() => addOrRemoveCriteria(criteria)}
                            border="1px solid var(--border-muted, #e5e5e5)"
                            height="25px"
                            bgcolor="var(--bg-color, #fff)"
                            isLabel
                        >
                            {criteria}
                        </CustomIconTagQuickFilterItem>
                    </FadedSlower>
                </div>
            )
        )

        let mappedTagsData =
            labelsDataRes &&
            labelsDataRes.map((item: any, index: number) => {
                let label = item.item
                if (label && index < 3) {
                    return (
                        <StyledOption
                            selected={currentSelectedCriteria.includes(
                                label.name
                            )}
                            value={label.name}
                            key={`${label.name}-label-${index}`}
                        >
                            <ContentRow
                                style={{
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}
                            >
                                {label.name}
                                <CustomAnimatedCheckboxField
                                    onClick={() =>
                                        addOrRemoveCriteria(label.name)
                                    }
                                    checked={currentSelectedCriteria.includes(
                                        label.name
                                    )}
                                />
                            </ContentRow>
                        </StyledOption>
                    )
                } else {
                    return (
                        <StyledOption key={index} style={{ display: 'none' }} />
                    )
                }
            })

        let show_all_options = () => {
            // later : truncate labels_searchable_list to 2 array, 1 made of first 3 items, the other of the rest
            // on clicking the +N, shows array #2 mapped below already mapped array #1

            return (
                <>
                    {labels_searchable_list.map((label: any, index: number) => {
                        if (index < 10) {
                            return (
                                <React.Fragment
                                    key={`searchable-label-no-${index}`}
                                >
                                    <StyledOption
                                        selected={currentSelectedCriteria.includes(
                                            label.name
                                        )}
                                        value={label.name}
                                        key={`${label.name}-label-${index}`}
                                    >
                                        <ContentRow
                                            style={{
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            {label.name}
                                            <CustomAnimatedCheckboxField
                                                onClick={() =>
                                                    addOrRemoveCriteria(
                                                        label.name
                                                    )
                                                }
                                                checked={currentSelectedCriteria.includes(
                                                    label.name
                                                )}
                                            />
                                        </ContentRow>
                                    </StyledOption>
                                </React.Fragment>
                            )
                        } else {
                            return (
                                <div
                                    key={`no-label-${index}`}
                                    style={{ display: 'none' }}
                                />
                            )
                        }
                    })}
                    {labels_searchable_list.length > 10 && (
                        <Count>
                            +{labels_searchable_list.length - 10} more
                        </Count>
                    )}
                </>
            )
        }

        const manageissearchactive = (isActive: boolean) => {
            this.setState({
                ...this.state,
                issearchactive: isActive,
            })
        }

        let labNamesSearchableList = labels_searchable_list.map(
            (item: any, index: number) => {
                return item.name
            }
        )

        let labNamesReturnedResults = labelsDataRes.map(
            (item: any, index: number) => {
                return item.item.name
            }
        )

        const checkIfShowAddLabel = () => {
            if (
                !labNamesSearchableList.includes(value[0]) &&
                !labNamesReturnedResults.includes(value[0])
            ) {
                return (
                    <FadedSlower>
                        <motion.div
                            style={{ paddingTop: '20px' }}
                            whileTap={{ scale: 1.1 }}
                        >
                            <StyledOptionBtn
                                selected={false}
                                value={value[0]}
                                key={`${value[0]}-label-${0}`}
                                onClick={() => {
                                    addOrRemoveCriteria(value[0])
                                    clearField && clearField()
                                }}
                            >
                                <ContentRow>
                                    + Create new label: "{value[0]}"
                                </ContentRow>
                            </StyledOptionBtn>
                        </motion.div>
                    </FadedSlower>
                )
            }
        }

        return (
            <Wrapper data-attr={dataCyId} ref={this.dropdowRef}>
                <DropdownOptions>
                    <Expander
                        height={
                            this.state.addedCriteria.length > 0 ? 'auto' : 0
                        }
                    >
                        <SelectedCriteriaWrapper>
                            {addedCriteria}
                        </SelectedCriteriaWrapper>
                    </Expander>

                    <SearchFieldWrapper
                        onClick={() =>
                            manageissearchactive && manageissearchactive(true)
                        }
                    >
                        <SearchIconWrapper>
                            <SearchIcon
                                height={16}
                                isActive={this.state.issearchactive}
                            />
                        </SearchIconWrapper>
                        <SearchField
                            placeholder={
                                'Search through your labels or create new'
                            }
                            type="search"
                            onChange={(e: any) => {
                                handleChange(e.target.value)
                                fetchItem(e.target.value)
                            }}
                            issearchactive={this.state.issearchactive}
                            value={value[0]}
                            ref={this.searchFieldRef}
                        />
                    </SearchFieldWrapper>

                    <div style={{ paddingTop: '10px' }} />

                    {labelsDataRes &&
                        labelsDataRes.length > 0 &&
                        mappedTagsData}

                    <FadedSlower>
                        {labelsDataRes.length === 0 && show_all_options()}
                    </FadedSlower>

                    {value &&
                        value[0] !== '' &&
                        value &&
                        value[0] !== undefined &&
                        value[0].trim().length > 1 &&
                        checkIfShowAddLabel()}
                    {hasInnerSaveBtn && (
                        <div
                            style={{
                                padding: '20px',
                                paddingTop: '10px',
                                paddingBottom: '0px',
                            }}
                        >
                            <FullWidthLightGreyBgButton
                                text="Save"
                                onClick={() => {}}
                            />
                        </div>
                    )}
                </DropdownOptions>
            </Wrapper>
        )
    }
}

export default connect(
    mapStateToProps,
    {}
)(LabelsFilterByMultipleWithSearchUIMobile)
