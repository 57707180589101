import { IQuestion } from "myModels";

type IQuestionsList = IQuestion[];

const questionsListData: IQuestionsList = [
  {
    id: "col_q_01",
    question: "What is your favourite type of car?",
    optionsList: [
      { id: 0, value: "Race cars" },
      { id: 1, value: "rally cars" },
      { id: 2, value: "supercars" },
      { id: 3, value: "pre-war cars" },
      { id: 4, value: " modern classics" },
      { id: 5, value: "classic cars" },
      { id: 6, value: "American classics" },
      { id: 7, value: "other" }
    ],
    answer: null,
    type: "multipleselect",
    userType: "collector",
    pageDisplayed: "CollectorsPage1"
  },
  {
    id: "col_q_02",
    question: "What is your favourite era?",
    optionsList: [
      { id: 0, value: "Pre-war" },
      { id: 1, value: "1950s" },
      { id: 2, value: "1960s" },
      { id: 3, value: "1970s" },
      { id: 4, value: "1980s" },
      { id: 5, value: "1990s" },
      { id: 6, value: "2000s" },
      { id: 7, value: "post 2000s" }
    ],
    answer: null,
    type: "multipleselect",
    userType: "collector",
    pageDisplayed: "CollectorsPage2"
  },
  {
    id: "col_q_03",
    question:
      "Are you a member of any motoring-related clubs (i.e. owners club, enthusiast clubs or GRRC)? ",
    optionsList: [
      { id: "dfgkjhndfg", value: "Abarth Club of GB" },
      { id: "dfh", value: "Abarth Promozione, Belgio" },
      { id: "qwer", value: "All Wheel Driver Club" },
      { id: "dfgh456", value: "Alvis Owners' Club" },
      { id: "456dfgh", value: "Ferrari Club" },
      { id: "6ty6rth", value: "The Cambridge Oxford Owners' Club" }
    ],
    answer: null,
    type: "multipleselectsearch",
    userType: "collector",
    pageDisplayed: "CollectorsPage3"
  },

  {
    id: "d_q_01",
    question: "What type of car do you deal in?",
    optionsList: [
      { id: 0, value: "Race cars" },
      { id: 1, value: "rally cars" },
      { id: 2, value: "supercars" },
      { id: 3, value: "pre-war cars" },
      { id: 4, value: " modern classics" },
      { id: 5, value: "classic cars" },
      { id: 6, value: "American classics" },
      { id: 7, value: "other" }
    ],
    answer: null,
    type: "multipleselect",
    userType: "dealer",
    pageDisplayed: "DealersPage1"
  },
  {
    id: "d_q_02",
    question: "Is there a particular speciality to your stock?",
    answer: null,
    type: "textinput",
    userType: "dealer",
    pageDisplayed: "DealersPage2"
  },

  {
    id: "cc_q_01",
    question:
      "What type of content do you create? (i.e. photographer, writer, videographer, etc.)",
    answer: null,
    type: "textinput",
    userType: "content creator",
    pageDisplayed: "DealersPage1"
  },

  {
    id: "sp_q_01",
    question: "What is your favourite type of car?",
    optionsList: [
      { id: 0, value: "Race cars" },
      { id: 1, value: "rally cars" },
      { id: 2, value: "supercars" },
      { id: 3, value: "pre-war cars" },
      { id: 4, value: " modern classics" },
      { id: 5, value: "classic cars" },
      { id: 6, value: "American classics" },
      { id: 7, value: "other" }
    ],
    answer: null,
    type: "multipleselect",
    userType: "service provider",
    pageDisplayed: "DealersPage1"
  },

  {
    id: "def_q_01",
    question:
      "Which of the following would you be interested in using the PCC platform for? (primary use)",
    optionsList: [
      { id: 0, value: "collector" },
      { id: 1, value: "dealer" },
      { id: 2, value: "service provider" },
      { id: 3, value: "content-creator" }
    ],
    answer: null,
    type: "multipleselect",
    userType: "none",
    pageDisplayed: "GeneralPage1PrimaryUse"
  },
  {
    id: "def_q_02",
    question:
      "Which of the following would you be interested in using the PCC platform for? (secondary use)",
    optionsList: [
      { id: 0, value: "collector" },
      { id: 1, value: "dealer" },
      { id: 2, value: "service provider" },
      { id: 3, value: "content-creator" },
      { id: 3, value: "none" }
    ],
    answer: null,
    type: "multipleselect",
    userType: "none",
    pageDisplayed: "GeneralPage2SecondaryUse"
  },
  {
    id: "def_q_03",
    question:
      "Please briefly tell us why you would like to join Private Collectors Club Community.",
    answer: null,
    type: "textinput",
    userType: "none",
    pageDisplayed: "WhyPCC"
  }
];

export default questionsListData;
