type Props = {
    size?: string
    color?: string
}

const CalendarIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.27273 3.29541C1.94185 3.29541 1.75 3.53785 1.75 3.74541V13.3454C1.75 13.553 1.94185 13.7954 2.27273 13.7954H13.7273C14.0582 13.7954 14.25 13.553 14.25 13.3454V3.74541C14.25 3.53785 14.0582 3.29541 13.7273 3.29541H2.27273ZM0.25 3.74541C0.25 2.62749 1.19779 1.79541 2.27273 1.79541H13.7273C14.8022 1.79541 15.75 2.62749 15.75 3.74541V13.3454C15.75 14.4633 14.8022 15.2954 13.7273 15.2954H2.27273C1.19779 15.2954 0.25 14.4633 0.25 13.3454V3.74541Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.93174 0.704539L4.93066 4.38636L3.43066 4.38592L3.43174 0.704102L4.93174 0.704539Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.5685 0.704539L12.5674 4.38636L11.0674 4.38592L11.0685 0.704102L12.5685 0.704539Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 6.4043H1V4.9043H15V6.4043Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default CalendarIcon
