import { enumToNameFunc } from '../../../../helpers/strings/enumToName'
import {
    IInsuranceQuoteApplication_Car,
    IInsuranceQuoteApplication_VehiclesObj,
} from '../../../insuranceQuoteApplication/reducer'
import {
    ICustodianCar,
    ICustodianCarErrors,
    IExternalInsuranceVehicle,
    IExternalInsuranceVehicleErrors,
    IInsuranceApplication_API,
    IInsuranceApplication_APIErrors,
    IInsuranceEverydayVehicleID,
} from '../../../insuranceQuoteApplication/types'
import {
    fields_array_insurance_application_everyday_car_techinfo,
    insurance_fields_everyday_car_ownership,
    insurance_fields_car_mileage_parking_added_address_same_as_residential,
    insurance_fields_car_mileage_parking_added_address_different_than_residential,
    IInsuranceFieldType,
    insurance_fields_parking_info,
    ncd_additional_fields,
} from '../../../localdata/insuranceLocalData/fieldTypes'
import { getInsuranceFEValue } from './helpers'

const tech_info_fields_list: IInsuranceFieldType[] = [
    {
        id: 'registration_number',
        type: 'simple_txt',
        text: 'Registration Number',
        is_required: false,
        isMobileAnimated: true,
    },
    ...fields_array_insurance_application_everyday_car_techinfo,
]

const mileage_parking_fields = [
    ...insurance_fields_car_mileage_parking_added_address_same_as_residential,
    ...insurance_fields_car_mileage_parking_added_address_different_than_residential,
]
const mileage_parking_additionalIds = [
    'daytime_location',
    'night_park_address_id',
]

const additionalParkingFieldsIds = [
    'location_type',
    'location_security',
    'address_id',
    'uid',
]

const insurance_fields_everyday_car_ownership_enriched = [
    ...insurance_fields_everyday_car_ownership,
    ...ncd_additional_fields,
]

// additional_ncd_fields removed for not insured everyday => hence external cars
// but leaving array here in case we need to add others eventually
let additional_ncd_ids: string[] = [
    // 'was_ncd_uk_earned_on_private_policy',
    // 'is_ncd_in_use',
    // 'has_requested_ncd_protection',
]

// const ids_which_are_obj_in_fe = [
//     'make',
//     'model',
//     'body_type',
//     'transmission',
//     'drive_train',
//     'doors',
//     'seats',
// ]

const convertExtermalVehicleToReducerApplicationCar = (
    car: IExternalInsuranceVehicle,
    application: IInsuranceApplication_API
): IInsuranceQuoteApplication_Car => {
    let everyday_ids =
        application?.application_everyday_vehicles &&
        application?.application_everyday_vehicles.map(
            (item) => item.external_vehicle_uid
        )

    let res: IInsuranceQuoteApplication_Car = {
        id: car.external_id ?? car.uid,
        api_uid: car.uid,
        is_everyday:
            everyday_ids && everyday_ids.indexOf(`${car.uid}`) !== -1
                ? true
                : false,
        tech_info: {},
        tech_info_errors: {},
        ownership: {},
        ownership_errors: {},
        mileage_parking: {},
        mileage_parking_errors: {},
        entities: {
            modifications: [],
        },
        is_external: true,
        daytime_parking_info: {},
        eveningtime_parking_info: {},
    }

    //  tech info section
    for (let i = 0; i < tech_info_fields_list.length; i++) {
        let field_id = tech_info_fields_list[i]?.id
        res.tech_info = {
            ...res.tech_info,
            [field_id]: getInsuranceFEValue(
                tech_info_fields_list[i],
                // @ts-ignore
                car[field_id]
            ),
        }
    }

    //  ownership section
    for (
        let i = 0;
        i < insurance_fields_everyday_car_ownership_enriched.length;
        i++
    ) {
        let field_id = insurance_fields_everyday_car_ownership_enriched[i].id

        if (field_id === 'ncd_years' || additional_ncd_ids.includes(field_id)) {
            let everyDayCar: IInsuranceEverydayVehicleID[] | undefined | false =
                application &&
                Array.isArray(application.application_everyday_vehicles) &&
                application.application_everyday_vehicles?.filter(
                    (item: IInsuranceEverydayVehicleID) =>
                        item.external_vehicle_uid === car.uid
                )
            let ncd_years_answers =
                everyDayCar && everyDayCar[0] ? everyDayCar[0] : undefined

            if (ncd_years_answers) {
                res.ownership = {
                    ...res.ownership,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_everyday_car_ownership_enriched[i],
                        ncd_years_answers[
                            field_id as keyof IInsuranceEverydayVehicleID
                        ]
                    ),
                }
            }
        } else {
            res.ownership = {
                ...res.ownership,
                [field_id]: getInsuranceFEValue(
                    insurance_fields_everyday_car_ownership_enriched[i],
                    //@ts-ignore
                    car[field_id]
                ),
            }
        }
    }

    if (car.daytime_parking_info) {
        for (let i = 0; i < additionalParkingFieldsIds.length; i++) {
            let field_id = additionalParkingFieldsIds[i]

            if (field_id === 'location_type') {
                res.daytime_parking_info = {
                    ...res.daytime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.daytime_parking_info[field_id]
                            ? {
                                  name: enumToNameFunc(
                                      //@ts-ignore
                                      `${car.daytime_parking_info[field_id]}`
                                  ),
                                  //@ts-ignore
                                  uid: `${car.daytime_parking_info[field_id]}`,
                              }
                            : undefined,
                }
            } else
                res.daytime_parking_info = {
                    ...res.daytime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.daytime_parking_info[field_id],
                }
        }

        for (let i = 0; i < insurance_fields_parking_info.length; i++) {
            let field_id = insurance_fields_parking_info[i].id
            if (field_id === 'has_location_security') {
                if (
                    car?.daytime_parking_info[`location_security`] !==
                        undefined &&
                    Array.isArray(
                        car?.daytime_parking_info[`location_security`]
                    ) &&
                    car.daytime_parking_info[`location_security`].includes(
                        'NO_SECURITY'
                    )
                ) {
                    res.daytime_parking_info[`has_location_security`] = false
                } else if (
                    car?.daytime_parking_info[`location_security`] !==
                        undefined &&
                    car?.daytime_parking_info[`location_security`]?.length > 0
                ) {
                    res.daytime_parking_info[`has_location_security`] = true
                }
            } else {
                res.daytime_parking_info = {
                    ...res.daytime_parking_info,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_parking_info[i],
                        //@ts-ignore
                        car.daytime_parking_info[field_id]
                    ),
                }
            }
        }
    }

    if (car.eveningtime_parking_info) {
        for (let i = 0; i < additionalParkingFieldsIds.length; i++) {
            let field_id = additionalParkingFieldsIds[i]

            if (field_id === 'location_type') {
                res.eveningtime_parking_info = {
                    ...res.eveningtime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.eveningtime_parking_info[field_id]
                            ? {
                                  name: enumToNameFunc(
                                      //@ts-ignore
                                      `${car.eveningtime_parking_info[field_id]}`
                                  ),
                                  //@ts-ignore
                                  uid: `${car.eveningtime_parking_info[field_id]}`,
                              }
                            : undefined,
                }
            } else
                res.eveningtime_parking_info = {
                    ...res.eveningtime_parking_info,
                    [field_id]:
                        //@ts-ignore
                        car.eveningtime_parking_info[field_id],
                }
        }

        for (let i = 0; i < insurance_fields_parking_info.length; i++) {
            let field_id = insurance_fields_parking_info[i].id

            if (field_id === 'has_location_security') {
                if (
                    car?.eveningtime_parking_info[`location_security`] !==
                        undefined &&
                    Array.isArray(
                        car?.eveningtime_parking_info[`location_security`]
                    ) &&
                    car.eveningtime_parking_info[`location_security`].includes(
                        'NO_SECURITY'
                    )
                ) {
                    res.eveningtime_parking_info[`has_location_security`] =
                        false
                } else if (
                    car?.eveningtime_parking_info[`location_security`] !==
                        undefined &&
                    car?.eveningtime_parking_info[`location_security`]?.length >
                        0
                ) {
                    res.eveningtime_parking_info[`has_location_security`] = true
                }
            } else
                res.eveningtime_parking_info = {
                    ...res.eveningtime_parking_info,
                    [field_id]: getInsuranceFEValue(
                        insurance_fields_parking_info[i],
                        // @ts-ignore
                        car.eveningtime_parking_info[field_id]
                    ),
                }
        }
    }

    //  mileage & parking
    for (let i = 0; i < mileage_parking_fields.length; i++) {
        let field_id = mileage_parking_fields[i].id

        if (field_id === 'parking_place_same_day_night') {
            let isSameAddress: boolean | undefined = !car.daytime_parking_info
                ?.address?.uid
                ? undefined
                : car.daytime_parking_info?.address?.uid ===
                  car.eveningtime_parking_info?.address?.uid
                ? true
                : false

            res.mileage_parking = {
                ...res.mileage_parking,
                [field_id]: isSameAddress,
            }
        } else {
            res.mileage_parking = {
                ...res.mileage_parking,
                [field_id]: getInsuranceFEValue(
                    mileage_parking_fields[i],
                    //@ts-ignore
                    car[field_id]
                ),
            }
        }
    }

    for (let i = 0; i < mileage_parking_additionalIds.length; i++) {
        if (mileage_parking_additionalIds[i] === 'daytime_location') {
            res.mileage_parking = {
                ...res.mileage_parking,
                day_park_address_id:
                    car.daytime_parking_info &&
                    car.daytime_parking_info.address &&
                    car.daytime_parking_info.address.uid
                        ? car.daytime_parking_info.address.uid
                        : undefined,
            }
        }
        if (mileage_parking_additionalIds[i] === 'night_park_address_id') {
            res.mileage_parking = {
                ...res.mileage_parking,
                night_park_address_id:
                    car.eveningtime_parking_info &&
                    car.eveningtime_parking_info.address &&
                    car.eveningtime_parking_info.address.uid
                        ? car.eveningtime_parking_info.address.uid
                        : undefined,
            }
        }
    }

    res.mileage_parking = {
        ...res.mileage_parking,
        is_kept_at_customer_residential_address:
            car.is_kept_at_customer_residential_address,
    }

    return res
}

export const convertApplicationToExternalVehicles = (
    application: IInsuranceApplication_API
): IInsuranceQuoteApplication_VehiclesObj => {
    let res: IInsuranceQuoteApplication_VehiclesObj = {}

    let cars = application?.customer?.external_vehicles

    if (cars) {
        cars.forEach((ccar: ICustodianCar) => {
            res[`${ccar.external_id ?? ccar.uid}`] = {
                ...convertExtermalVehicleToReducerApplicationCar(
                    ccar,
                    application
                ),
            }
        })
    }

    return res
}

const convertExtermalVehicleToReducerApplicationCarError = (
    car: IExternalInsuranceVehicleErrors,
    application: IInsuranceApplication_APIErrors,
    existing?: IInsuranceQuoteApplication_Car
): IInsuranceQuoteApplication_Car => {
    let res: IInsuranceQuoteApplication_Car = existing ?? {
        id: car.external_id,
        api_uid: car.uid,
        tech_info: {},
        tech_info_errors: {},
        ownership: {},
        ownership_errors: {},
        mileage_parking: {},
        mileage_parking_errors: {},
        entities: {
            modifications: [],
        },
        daytime_parking_info: {},
        eveningtime_parking_info: {},
    }

    let tech_info_ids_errors =
        fields_array_insurance_application_everyday_car_techinfo.map(
            (field) => field.id
        )

    //  tech info section
    for (
        let i = 0;
        i < fields_array_insurance_application_everyday_car_techinfo.length;
        i++
    ) {
        //@ts-ignore
        res.tech_info_errors[tech_info_ids_errors[i]] =
            //@ts-ignore
            car[tech_info_ids_errors[i]]
    }

    let ownership_ids_errors =
        insurance_fields_everyday_car_ownership_enriched.map(
            (field) => field.id
        )

    //  ownership section
    for (let i = 0; i < ownership_ids_errors.length; i++) {
        let field_id = ownership_ids_errors[i]
        if (field_id === 'ncd_years' || additional_ncd_ids.includes(field_id)) {
            let everyDayCar: IInsuranceEverydayVehicleID[] | undefined | false =
                application &&
                Array.isArray(application.application_everyday_vehicles) &&
                application.application_everyday_vehicles?.filter(
                    (item: IInsuranceEverydayVehicleID) =>
                        item.external_vehicle_uid === car.uid
                )

            let ncd_years_errors =
                everyDayCar && everyDayCar[0] ? everyDayCar[0] : undefined

            if (
                ncd_years_errors &&
                ncd_years_errors[field_id as keyof IInsuranceEverydayVehicleID]
            ) {
                //@ts-ignore
                res.ownership_errors[field_id] =
                    ncd_years_errors[
                        field_id as keyof IInsuranceEverydayVehicleID
                    ]
            }
        } else {
            //@ts-ignore
            res.ownership_errors[field_id] =
                //@ts-ignore
                car[field_id]
        }
    }

    //  mileage & parking

    if (car.daytime_parking_info) {
        res.daytime_parking_info_errors = {
            ...car.daytime_parking_info,
        }
    }

    if (car.eveningtime_parking_info) {
        res.eveningtime_parking_info_errors = {
            ...car.eveningtime_parking_info,
        }
    }

    for (let i = 0; i < mileage_parking_fields.length; i++) {
        let field_id = mileage_parking_fields[i].id

        res.mileage_parking_errors = {
            ...res.mileage_parking_errors,
            [field_id]:
                //@ts-ignore
                car[field_id],
        }
        if (
            field_id === 'is_kept_at_customer_residential_address' &&
            car.daytime_parking_info &&
            car.eveningtime_parking_info
        ) {
            res.mileage_parking_errors = {
                ...res.mileage_parking_errors,
                [field_id]: {
                    status: 'missing',
                    message:
                        'is_kept_at_customer_residential_address is required',
                },
            }
        }
    }

    for (let i = 0; i < mileage_parking_additionalIds.length; i++) {
        let field_id: string = mileage_parking_additionalIds[i]

        res.mileage_parking_errors = {
            ...res.mileage_parking_errors,
            [field_id]:
                // @ts-ignore
                car[field_id],
        }
    }

    return res
}

export const convertApplicationToExternalVehiclesError = (
    application: IInsuranceApplication_APIErrors
): IInsuranceQuoteApplication_VehiclesObj => {
    let res: IInsuranceQuoteApplication_VehiclesObj = {}

    let cars = application?.customer?.external_vehicles

    let carsNCD: ICustodianCarErrors[] = []

    application &&
        Array.isArray(application.application_everyday_vehicles) &&
        application.application_everyday_vehicles?.forEach(
            (item: ICustodianCarErrors) => {
                if (item.external_vehicle_uid) {
                    carsNCD = [...carsNCD, item]
                }
            }
        )

    if (cars) {
        cars.forEach((ccar: ICustodianCarErrors) => {
            res[`${ccar.external_id ?? ccar.uid}`] = {
                ...convertExtermalVehicleToReducerApplicationCarError(
                    ccar,
                    application
                ),
            }
        })
    }

    if (carsNCD.length > 0) {
        carsNCD.forEach((car2) => {
            let ncd_errors: ICustodianCarErrors = { ...car2 }
            delete ncd_errors['external_id']
            delete ncd_errors['uid']

            if (res[`${car2.external_vehicle_uid}`]) {
                res[`${car2.external_vehicle_uid}`] = {
                    ...res[`${car2.external_vehicle_uid}`],
                    ownership_errors: {
                        ...res[`${car2.external_vehicle_uid}`].ownership_errors,
                        ...ncd_errors,
                    },
                }
            } else {
                res[`${car2.external_vehicle_uid}`] = {
                    id: car2.external_vehicle_uid,
                    api_uid: car2.external_vehicle_uid,
                    tech_info: {},
                    tech_info_errors: {},
                    ownership: {},
                    ownership_errors: {
                        ...ncd_errors,
                    },
                    mileage_parking: {},
                    mileage_parking_errors: {},
                    entities: {
                        modifications: [],
                    },
                    daytime_parking_info: {},
                    eveningtime_parking_info: {},
                }
            }
        })
    }

    return res
}
