import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../atoms/Inputfield/inputField'
import TextArea from '../../../atoms/textarea/textarea'

import ButtonsStandardBottomRowDesktop from '../buttons/buttonsStandardBottomRowDesktop'

import Expander from '../../../atoms/expander/expander'
import Faded from '../../../templates/animated/faded'
import { writeCarDataGeneralRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import { ICar, IUpdateCarGeneralPayloadReq } from 'entityModels'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 16px;
    padding-bottom: 4px;
`

const Padding16 = styled.div`
    padding-top: 16px;
`

const Padding24 = styled.div`
    padding-top: 24px;
`

const TextAreaWidthOneLiner = styled.div`
    width: 100%;
    height: 168px;
`

export const Error = styled.div`
    font-style: italic;
    font-size: 11px;
`
export const DescriptionHeader = styled.div`
    padding-top: 4px;
    font-size: 14px;
    text-align: left;
    padding-right: 5vw;
    font-family: Lato-Light;
`

export const ButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px;
    width: 100%;

    @media (max-height: 800px) {
        padding-top: 0px;
    }
`
const dispatchProps = {
    writeCarDataGeneralRequest: (payload: IUpdateCarGeneralPayloadReq) =>
        writeCarDataGeneralRequest(payload),
}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    title: string
    short_description: string
}

interface OtherProps extends RouteComponentProps<any> {
    close: () => void
    writeCarDataGeneralRequest: (payload: IUpdateCarGeneralPayloadReq) => {}
    carData: ICar | null | undefined
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { values, close, errors } = props

    const { theme } = useThemes()

    return (
        <ModalContentDesktop
            $theme={theme}
            style={{ paddingInline: 0, maxWidth: 700 }}
        >
            <>
                <ModalInnerContentWrapperDesktop>
                    <ModalTitle $theme={theme} style={{ marginBottom: 16 }}>
                        Update your car's details
                    </ModalTitle>
                    <Expander
                        height={
                            values.title.length > 1 && errors.title ? 'auto' : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {errors.title}
                            </Error>
                        </Faded>
                    </Expander>
                    <Padding16 />
                    <InputField
                        theme={theme}
                        id="title"
                        name="title"
                        placeholder="Car Title"
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.title}
                        onEnterKeyPress={props.handleSubmit}
                        helperText="Example: year | make | model"
                        backgroundColor="var(--modal-side-sheet-pop-over-bg)"
                    />
                </ModalInnerContentWrapperDesktop>

                <ModalInnerContentWrapperDesktop
                    style={{
                        backgroundColor:
                            colours[theme].background_neutral_subtle,
                        paddingBlock: 16,
                    }}
                >
                    <FieldTitle style={{ color: colours[theme].text_strong }}>
                        Vehicle bio
                    </FieldTitle>
                    <Expander
                        height={
                            values.short_description.length > 1 &&
                            errors.short_description
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error
                                className="animated fadeIn slower"
                                style={{
                                    color: colours[theme].error,
                                }}
                            >
                                {errors.short_description}
                            </Error>
                        </Faded>
                    </Expander>
                    <DescriptionHeader
                        style={{ color: colours[theme].text_strong }}
                    >
                        Share a brief overview of your car below
                    </DescriptionHeader>
                    <Padding24 />
                    <TextAreaWidthOneLiner>
                        <TextArea
                            id="short_description"
                            name="short_description"
                            placeholder="i.e An immaculate and original 1600 GTA Stradale, restored to concours standards by Alfaholics with performance enhancements for road and road/rally events."
                            tabindex={2}
                            aria-live="polite"
                            formikprops={props}
                            value={values.short_description}
                        />
                    </TextAreaWidthOneLiner>
                </ModalInnerContentWrapperDesktop>
                {/* <LargePadding /> */}
                {/* 
                <FieldTitle>Car Deletion</FieldTitle>

                <DescriptionHeader>
                    If you delete your car, there is no way back. You will lose
                    all the assets -images, documents, descriptions...
                </DescriptionHeader> */}
                {/* <SmallPadding />

                <SmallPadding /> */}
            </>

            <ModalInnerContentWrapperDesktop>
                <ButtonsRow>
                    {/* <ButtonAtom
                        theme="modal-action-button-desctructive"
                        width="185px"
                        height="41px"
                        fontSize={14}
                        onClick={() => {
                            carData &&
                                props.history.push(
                                    `/car/${carData.id}/deletion`
                                )
                            close()
                        }}
                    >
                        <span
                            style={{
                                fontFamily: 'Lato-Light',
                            }}
                        >
                            Delete car
                        </span>
                    </ButtonAtom> */}
                    <ButtonsStandardBottomRowDesktop
                        cancel={close}
                        submit={props.handleSubmit}
                        isDisabled={
                            (values.title && errors.title) ||
                            (values.short_description &&
                                errors.short_description)
                                ? true
                                : false
                        }
                    />
                </ButtonsRow>
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarOverviewEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        title: props.carData ? props.carData.title : '',
        short_description: props.carData ? props.carData.short_description : '',
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                // title: Yup.string().test(
                //     'len',
                //     'The title must be be less than 40 characters.',
                //     (val) => val && val.toString().length < 41
                // ),
                title: Yup.string().max(
                    41,
                    'The title must be be less than 40 characters.'
                ),
                short_description: Yup.string().max(
                    266,
                    'The short description must be be less than 265 characters.'
                ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (
            props.carData &&
            props.carData.title !== values.title &&
            props.carData.short_description !== values.short_description
        ) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carData.id,
                dataListForm: [
                    {
                        id: 'title',
                        text: 'title',
                        answer: values.title,
                    },
                    {
                        id: 'short_description',
                        text: 'title',
                        answer: values.short_description,
                    },
                ],
            }
            props.writeCarDataGeneralRequest(payload)
        } else if (
            props.carData &&
            props.carData.title !== values.title &&
            props.carData.short_description === values.short_description
        ) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carData.id,
                dataListForm: [
                    {
                        id: 'title',
                        text: 'title',
                        answer: values.title,
                    },
                ],
            }

            props.writeCarDataGeneralRequest(payload)
        } else if (
            props.carData &&
            props.carData.title === values.title &&
            props.carData.short_description !== values.short_description
        ) {
            let payload: IUpdateCarGeneralPayloadReq = {
                car_id: props.carData.id,
                dataListForm: [
                    {
                        id: 'short_description',
                        text: 'title',
                        answer: values.short_description,
                    },
                ],
            }

            props.writeCarDataGeneralRequest(payload)
        }

        props.close()
    },
})(InnerForm)

export default withRouter(
    connect(mapStateToProps, dispatchProps)(CarOverviewEditFormDesktop)
)
