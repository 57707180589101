import styled from 'styled-components'

import { withFormik, FormikProps } from 'formik'

import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import AnimatedTextarea from '../../../atoms/textarea/animatedTextarea'
import { yupVar_BasicSingleField } from '../yupVars'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
// import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'

const WrapperDesktop = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
`

// const FieldTitle = styled.div`
//     width: 30%;
//     max-width: 180px;
//     font-family: Lato-light;
//     align-self: start;
//     font-size: 16px;
//     text-transform: capitalize;
//     padding-bottom: 8px;
// `

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const InputFieldWrapper = styled.div`
    width: 70%;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_text_field_desktop: string
}

interface OtherProps {
    data: any
    dataCyId?: string
    placeholder?: string
    hasError?: (payload: boolean) => void
    isDisabled?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        data,
        errors,
        setFieldValue,
        placeholder,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        hasError,
        isDisabled,
    } = props

    const handleAnswerChange = (e: any) => {
        setFieldValue(
            'single_item_edited_value_text_field_desktop',
            e.target.value
        )
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                e.target.value,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, e.target.value)
        }

        if (errors.single_item_edited_value_text_field_desktop !== undefined) {
            return hasError && hasError(true)
        } else {
            return hasError && hasError(false)
        }
    }

    const { theme } = useThemes()

    return (
        <WrapperDesktop>
            {/* <FieldTitle>{data.title}</FieldTitle> */}
            <InputFieldWrapper>
                <AnimatedTextarea
                    id="single_item_edited_value_text_field_desktop"
                    name="single_item_edited_value_text_field_desktop"
                    tabindex={1}
                    aria-live="polite"
                    handleChange={handleAnswerChange}
                    value={values.single_item_edited_value_text_field_desktop}
                    dataCyId={props.dataCyId}
                    linecolor={colours[theme].text_muted}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    fieldTitle={data.title}
                    // minheight="60px"
                />

                <Expander
                    height={
                        values.single_item_edited_value_text_field_desktop
                            .length > 1 &&
                        errors.single_item_edited_value_text_field_desktop
                            ? 'auto'
                            : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.single_item_edited_value_text_field_desktop}
                        </Error>
                    </Faded>
                </Expander>
            </InputFieldWrapper>
        </WrapperDesktop>
    )
}

const TextareaEditOrCreateModeSingleFieldDesktop = withFormik<
    OtherProps,
    Values
>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_text_field_desktop: props.data.value_start
            ? props.data.value_start
            : '',
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_text_field_desktop:
                    yupVar_BasicSingleField(props.data),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(TextareaEditOrCreateModeSingleFieldDesktop)
