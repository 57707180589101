import { insurance_colours } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

function InsuranceEverydayCarIcon(props?: any) {
    const { theme } = useThemes()
    return (
        <svg
            width={props?.width ?? 40}
            height={props?.width ?? 40}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect
                width={40}
                height={40}
                rx={4}
                fill={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_08
                }
            />
            <path
                d="M7.27 20.025v2.896l6.42.252v-1.26l-.504-1.048-1.762-.252-4.154-.588zM32.637 20.025v2.896l-6.42.252v-1.26l.504-1.048 1.762-.252 4.154-.588zM13.606 11.508l-2.518 4.783 3.735-.378 4.783-.461 5.078.461 3.315.378.923-.378-2.182-3.524-.797-.881-3.525-.378h-6.924l-1.888.378z"
                fill={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_24
                }
            />
            <path
                d="M20 15.478c-4.362 0-8.143.636-9.043 1.13"
                stroke={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_100
                }
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 22.826s6.971.565 13 .565"
                stroke={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_60
                }
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7 25.652v2.261c0 .624.506 1.13 1.13 1.13h2.261a1.13 1.13 0 001.13-1.13v-1.13M11.522 15.478H9.26M20 15.478c4.362 0 8.142.636 9.044 1.13"
                stroke={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_100
                }
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33 22.826s-6.971.565-13 .565"
                stroke={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_60
                }
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33 25.652v2.261a1.13 1.13 0 01-1.13 1.13h-2.261a1.13 1.13 0 01-1.13-1.13v-1.13M28.478 15.478h2.261"
                stroke={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_100
                }
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.565 20s3.78.565 5.087.565 1.13 2.261 1.13 2.261M32.443 20s-3.78.565-5.087.565-1.13 2.261-1.13 2.261M16.044 21.13h7.912"
                stroke={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_60
                }
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 10.957c-1.648 0-5.676.235-6.217.565-.542.33-2.661 4.569-2.826 5.087-.166.517-1.909.07-2.261.565-.354.494-.566.965-1.13 1.13C7 18.47 7 20.824 7 22.261c0 1.437.236 3.781.565 3.956.353.19 6.453.566 12.435.566M20 10.957c1.648 0 5.676.235 6.217.565.542.33 2.661 4.569 2.827 5.087.165.517 1.908.07 2.26.565.354.494.566.965 1.13 1.13.566.165.566 2.52.566 3.957 0 1.437-.236 3.781-.565 3.956-.353.19-6.453.566-12.435.566"
                stroke={
                    insurance_colours[theme].sections['everyday_vehicles']
                        .primary_100
                }
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default InsuranceEverydayCarIcon
