import styled from 'styled-components'

import GalleryOverviewItemMobile from '../../../molecules/imageGrid/galleryOverviewItemMobile'
import { device } from '../../../templates/displays/devices'
import { IGalleryImage } from 'entityModels'
import NoDataRectangle from '../../../atoms/noData/noDataRectangle'
import FullWidthMobile from '../../../atoms/image/fullWidthMobile'
import img_1 from '../../../atoms/skeleton/gallery/1.jpg'
import img_2 from '../../../atoms/skeleton/gallery/2.jpg'
import img_3 from '../../../atoms/skeleton/gallery/3.jpg'
import img_4 from '../../../atoms/skeleton/gallery/4.jpg'
import img_5 from '../../../atoms/skeleton/gallery/5.jpg'
import img_6 from '../../../atoms/skeleton/gallery/6.jpg'
import img_1_dark from '../../../atoms/skeleton/gallery/1_dark.png'
import img_2_dark from '../../../atoms/skeleton/gallery/2_dark.png'
import img_3_dark from '../../../atoms/skeleton/gallery/3_dark.png'
import img_4_dark from '../../../atoms/skeleton/gallery/4_dark.png'
import img_5_dark from '../../../atoms/skeleton/gallery/5_dark.png'
import img_6_dark from '../../../atoms/skeleton/gallery/6_dark.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import ImageSignMobile from '../../../atoms/signs/imageSignMobile'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { ITheme } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

interface IStyle {}

const Wrapper = styled.section<IStyle>`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
    width: 100%;

    @media ${device.ipad} {
        padding-bottom: 30px;
    }
`
const EmptyBtn = styled.div`
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
`

export interface IImageProps {
    url: string
}

interface Props extends RouteComponentProps {
    images_array: IGalleryImage[] | null
    count: number
    onClick?: any
    carid: string
    readOnlyMode?: boolean
}

function GalleryOverviewManagerMobile(props: Props) {
    const { images_array, count, carid, readOnlyMode, onClick, history } = props

    const isEmpty = !images_array || images_array.length === 0 ? true : false

    const getPlaceholderImg = (image_number: number, theme: ITheme): string => {
        switch (image_number) {
            case 1:
                return theme === 'light' ? img_1 : img_1_dark

            case 2:
                return theme === 'light' ? img_2 : img_2_dark

            case 3:
                return theme === 'light' ? img_3 : img_3_dark
            case 4:
                return theme === 'light' ? img_4 : img_4_dark
            case 5:
                return theme === 'light' ? img_5 : img_5_dark
            case 6:
                return theme === 'light' ? img_6 : img_6_dark

            default:
                return grey_placeholder
        }
    }

    let n_array = ['0', ' 1', '2', '3', '4', '5', '6']

    let imgLinkPrefix =
        props.match.url && props.match.url.includes('overview')
            ? props.match.url.replace('/overview', '')
            : props.match.url

    const urlLocation = history.location.pathname

    const { theme } = useThemes()

    return (
        <>
            {!readOnlyMode && (!images_array || !images_array[0]) ? (
                <>
                    <FullWidthMobile
                        key={0}
                        img={
                            images_array && images_array[0]
                                ? images_array[0]
                                : {
                                      id: 'placeholder_0',
                                      url: getPlaceholderImg(1, theme),
                                  }
                        }
                    />
                    <div style={{ paddingTop: '5px' }} />
                </>
            ) : images_array && images_array[0] ? (
                <Link
                    to={{
                        pathname: `${imgLinkPrefix}/gallery`,
                        search: `?id=${images_array[0].id}`,
                        state: { prevPath: urlLocation },
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        <FullWidthMobile
                            key={0}
                            img={
                                images_array && images_array[0]
                                    ? images_array[0]
                                    : {
                                          id: 'placeholder_0',
                                          url: getPlaceholderImg(1, theme),
                                      }
                            }
                        />
                        <ImageSignMobile />
                        <div style={{ paddingTop: '5px' }} />
                    </div>
                </Link>
            ) : null}

            <Wrapper>
                {isEmpty && !readOnlyMode ? (
                    <EmptyBtn>
                        <ButtonAtom
                            width={'230px'}
                            theme={'lowercase-blue-background'}
                            fontSize={14}
                            onClick={() => onClick && onClick()}
                        >
                            <div
                                style={{
                                    textTransform: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Start adding photos of your car
                            </div>
                        </ButtonAtom>
                    </EmptyBtn>
                ) : null}

                {n_array.map((id: string, index: number) => {
                    if (isEmpty && index > 0 && !readOnlyMode) {
                        return (
                            <GalleryOverviewItemMobile
                                key={index}
                                isLast={false}
                                isFirst={false}
                                count={null}
                                carid={carid}
                                item={{
                                    id: `placeholder_${index + 1}`,
                                    url: getPlaceholderImg(index, theme),
                                }}
                                isUnique={false}
                                isPlaceholder
                            />
                        )
                    } else if (index === 0) {
                        return null
                    } else if (images_array && images_array[index]) {
                        return (
                            <GalleryOverviewItemMobile
                                key={index}
                                isLast={
                                    index ===
                                    (images_array &&
                                        images_array.length === 7 &&
                                        images_array.length - 1)
                                        ? true
                                        : false
                                }
                                isFirst={false}
                                count={
                                    images_array && count > 7 ? count - 7 : null
                                }
                                carid={carid}
                                item={images_array[index]}
                                isUnique={false}
                            />
                        )
                    } else return null
                })}
            </Wrapper>
            {/* IF NO IMAGES: */}
            {isEmpty === true && readOnlyMode ? (
                <NoDataRectangle text="no images" />
            ) : null}
        </>
    )
}

export default withRouter(GalleryOverviewManagerMobile)
