import {
    IIAllApex_carOfTheWeeks_api,
    IApexCarOfTheWeekNode,
    IApexCarOfTheWeekState,
} from 'apexModels'
import {
    convertImgPrismicWithCompression,
    convertImgPrismicWithCustomCompression,
} from './apexImgPrismicCompression'

export const ConvertPrismicApexCarOfTheWeekFromAPItoSingle = (
    data: IIAllApex_carOfTheWeeks_api
): IApexCarOfTheWeekState => {
    let singleObject: IApexCarOfTheWeekState = {
        date: ',',
        title: ',',
        description: ',',
        name: ',',
        link: ',',
        img: undefined,
    }

    let node: IApexCarOfTheWeekNode | undefined =
        data.edges[0] && data.edges[0].node && data.edges[0].node

    if (node !== undefined) {
        singleObject.date = node.date ? node.date : ''
        singleObject.title = node.title ? node.title : ''
        singleObject.description =
            node.description && node.description[0] && node.description[0].text
                ? node.description[0].text
                : ''

        singleObject.name = node.car_name ? node.car_name : ''
        singleObject.link = node.link && node.link.url ? node.link.url : ''
        singleObject.img =
            node.img && convertImgPrismicWithCompression(node.img)

        return singleObject
    } else return singleObject
}

export const ConvertPrismicApexCarOfTheWeekFromAPIToArray = (
    data: IIAllApex_carOfTheWeeks_api
): IApexCarOfTheWeekState[] => {
    let itemsArray: IApexCarOfTheWeekState[] = []

    for (const item of data.edges) {
        let node: IApexCarOfTheWeekNode = item.node

        let res: IApexCarOfTheWeekState = {
            date: ',',
            title: ',',
            description: ',',
            name: ',',
            link: ',',
            img: undefined,
        }

        if (node !== undefined) {
            res.date = node.date ? node.date : ''
            res.title = node.title ? node.title : ''
            res.description =
                node.description &&
                node.description[0] &&
                node.description[0].text
                    ? node.description[0].text
                    : ''

            res.name = node.car_name ? node.car_name : ''
            res.link = node.link && node.link.url ? node.link.url : ''
            res.img =
                node.img &&
                convertImgPrismicWithCustomCompression(node.img, '700')

            itemsArray.push(res)
        }
    }

    return itemsArray
}
