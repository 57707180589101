import * as React from 'react'
import { connect } from 'react-redux'
import './styles.css'

import { RootState } from 'typesafe-actions'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import 'swiper/swiper-bundle.css'

import SlideTheme2Mobile from '../../../atoms/slider/theme2/slideTheme2Mobile'

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

type IProps = {
    slides: any
}

class SliderApexHomepageMobile extends React.Component<IProps, {}> {
    componentDidMount() {}

    render() {
        let { slides } = this.props

        return (
            <Swiper
                spaceBetween={50}
                slidesPerView={1}
                navigation={{
                    nextEl: '.swiper-button-next-custom',
                    prevEl: '.swiper-button-prev-custom',
                }}
                loop={true}
                direction={'horizontal'}
                pagination={{ clickable: true, dynamicBullets: true }}
                scrollbar={{ draggable: true }}
            >
                {slides.map((item: number, index: any) => {
                    return (
                        <SwiperSlide key={index}>
                            <SlideTheme2Mobile
                                height={'36vh'}
                                slide={slides[index]}
                            />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(SliderApexHomepageMobile)
