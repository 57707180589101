import {
    IApexInterviewCategory,
    ITaskNormalisedICategoryInformationObject_State,
} from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import AnchorLink from 'react-anchor-link-smooth-scroll'

type Props = {
    categories_information_list: ITaskNormalisedICategoryInformationObject_State
    category_ids_list: IApexInterviewCategory[]
}

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;

    @media (min-width: 500px) {
        padding-bottom: 60px;
    }
`
const Anchor = styled(AnchorLink)`
    padding-bottom: 16px;
`

class ApexCategoryAnchorsMobile extends React.Component<Props> {
    render() {
        let { categories_information_list, category_ids_list } = this.props

        return (
            <ButtonsWrapper>
                {category_ids_list !== undefined && (
                    <>
                        {category_ids_list.map(
                            (
                                category_id: IApexInterviewCategory,
                                index: number
                            ) => {
                                if (
                                    categories_information_list &&
                                    categories_information_list[category_id] !==
                                        undefined
                                ) {
                                    return (
                                        <Anchor
                                            href={`#${category_id}-mobile`}
                                            key={`category_${index}-mobile`}
                                        >
                                            {categories_information_list[
                                                category_id
                                            ].category_name && (
                                                <ButtonAtom
                                                    theme="uppercase-white-background"
                                                    width="35vw"
                                                    height="50px"
                                                    fontSize={11}
                                                    borderRadius={5}
                                                >
                                                    {
                                                        categories_information_list[
                                                            category_id
                                                        ].category_name
                                                    }
                                                </ButtonAtom>
                                            )}
                                        </Anchor>
                                    )
                                } else return undefined
                            }
                        )}
                    </>
                )}
            </ButtonsWrapper>
        )
    }
}

export default ApexCategoryAnchorsMobile
