import { enumToNameFunc } from '../../../../helpers/strings/enumToName'
import { IOtherDriver_DriveList } from '../../../insuranceQuoteApplication/reducer'
import {
    ICustodianCar,
    ICustodianCarErrors,
    IInsuranceApplication_API,
    IInsuranceApplication_APIErrors,
    IInsuranceDriver_API,
    IInsuranceDriver_APIErrors,
} from '../../../insuranceQuoteApplication/types'

export type IDriveListNormalised = {
    [key: string]: IOtherDriver_DriveList[]
}

export const convertApplicationToDriveList = (
    application: IInsuranceApplication_API
): IDriveListNormalised => {
    let res: IDriveListNormalised = {}

    // since frequency use has to be answered at the same time, should be only necessary
    // to do below vs adding the car.drivers loop to cross check.
    if (application.customer && application.customer?.named_drivers) {
        // TODO : loop here not for each

        for (let a = 0; a < application.customer.named_drivers.length; a++) {
            let driver: IInsuranceDriver_API =
                application.customer.named_drivers[a]

            let driverID = `${driver.uid}`
            if (driver.custodian_cars !== undefined) {
                // TODO : loop here not for each
                for (let i = 0; i < driver.custodian_cars.length; i++) {
                    let driverCar: ICustodianCar = driver.custodian_cars[i]
                    let carID = `${driverCar.external_id}`

                    let obj: IOtherDriver_DriveList = {
                        carid: carID,
                    }

                    if (driverCar.use_frequency) {
                        obj = {
                            ...obj,
                            use_frequency: {
                                name: enumToNameFunc(
                                    `${driverCar.use_frequency}`
                                ),
                                uid: `${driverCar.use_frequency}`,
                            },
                        }
                    }

                    if (res[driverID]) {
                        res[driverID] = [...res[driverID], obj]
                    } else {
                        res = {
                            ...res,
                            [driverID]: [obj],
                        }
                    }
                }
            }
        }
    }

    return res
}

export const convertApplicationToDriveListErrors = (
    application: IInsuranceApplication_APIErrors
): IDriveListNormalised => {
    let res: IDriveListNormalised = {}

    // since frequency use has to be answered at the same time, should be only necessary
    // to do below vs adding the car.drivers loop to cross check.
    if (application.customer && application.customer?.named_drivers) {
        // TODO : loop here not for each

        for (let a = 0; a < application.customer.named_drivers.length; a++) {
            let driver: IInsuranceDriver_APIErrors =
                application.customer.named_drivers[a]

            let driverID = `${driver.uid}`
            if (driver.custodian_cars !== undefined) {
                // TODO : loop here not for each
                for (let i = 0; i < driver.custodian_cars.length; i++) {
                    let driverCar: ICustodianCarErrors =
                        driver.custodian_cars[i]
                    let carID = `${driverCar.external_id}`

                    let obj: IOtherDriver_DriveList = {
                        carid: carID,
                    }

                    if (
                        driverCar.use_frequency &&
                        typeof driverCar.use_frequency !== 'string'
                    ) {
                        obj = {
                            ...obj,
                            use_frequency_error: driverCar.use_frequency,
                        }
                    }

                    if (res[driverID]) {
                        res[driverID] = [...res[driverID], obj]
                    } else {
                        res = {
                            ...res,
                            [driverID]: [obj],
                        }
                    }
                }
            }
        }
    }

    return res
}
