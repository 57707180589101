import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    width?: string
    height?: string
    color?: string
    strokeColor?: string
}

const DocumentIcon = ({ width, height, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? '20'}
            height={height ?? '22'}
            viewBox="0 0 20 22"
            fill="none"
        >
            <path
                d="M5.15381 15.666H14.8461"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.15381 11.666H14.8461"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.15381 7.66602H7.92304"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.0769 1H1V21H19V7.66667L12.0769 1Z"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                d="M12.0771 1V7.66667H19.0002"
                stroke={color ?? colours[theme].text_darker}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default DocumentIcon
