import { IUploadAttachmentPreSignedUrlPayloadSaga } from 'myModels'

import {
    IFilterEntriesPayloadAPIReq,
    IDeleteTaskReminderAPIReq,
    IDeleteEntryAttachmentPayloadReq,
    IApiEntryCostDELETE_args,
    IApiEntryCostGET_args,
    IDeleteCostAttachmentPayloadReq,
    IURL_user_getSharesList_arg,
    IApi_SearchUsersInDirectShare_args,
    IGetSingleShareAPI_Arg,
    IDeleteShareAPi_Arg,
} from 'ApiInterfaces'
import { IGetCarActionCheckRequest, ITaskRelationsPayload } from 'entityModels'
import { IUploadAttachmentPreSignedUrlPayloadSingle } from 'myModels'
import { ICurencyUIDsEnum } from '../../timeline/timelineEnum'

// base url
export const base_url = process.env.REACT_APP_BASE_URL

const env: any = process.env.REACT_APP_ENV

const supabase_url =
    env === 'production'
        ? `https://janhwmpiuqbogmohflyu.supabase.co/rest/v1`
        : `https://bdmposhyqfworpshgkrf.supabase.co/rest/v1`

export const get_showroom_carousel = `${supabase_url}/featured_showroom_entries?select=*&order=order`

export const checkTurnstileTokenURL = `${base_url}/public/cloudflare/turnstile`

//  authentication
export const register = `${base_url}/register`

//  social aith
export const social_auth_url = `${base_url}/social/callback`

export const login = `${base_url}/login`

export const logout = `${base_url}/logout`

export const refreshToken = `${base_url}/refresh`

// verification
export const verifyEmail = (code: string) => `${base_url}/verify?code=${code}`

// forgot password
export const reset_password_request = `${base_url}/reset-password`
export const reset_password_verify_code = (code: string) =>
    `${base_url}/reset-password?code=${code}`
export const reset_password_verified = (UID: string) =>
    `${base_url}/users/${UID}/actions/update-password`

// invitations
export const send_email_invitation = `${base_url}/send-invitation`

// user data
export const user_endpoint = (id: string) => `${base_url}/users/${id}`

export const external_url_endpoint = `${base_url}/external-urls`
export const external_url_update_endpoint = (id: string) =>
    `${base_url}/external-urls/${id}`

// current user data
export const current_user_endpoint = `${base_url}/user`

// referals
export const create_request_proposal_codes = `${base_url}/proposal-code-requests`
export const get_proposal_codes_approved = `${base_url}/proposal-codes`
export const redeem_proposal_code = (id: string) =>
    `${base_url}/proposal-codes/${id}/actions/redeem`

// getGarage
export const get_garage_endpoint = (urlBuilt: string) =>
    `${base_url}/garage/${urlBuilt}`

// cars
export const cars_endpoint = `${base_url}/cars`
// getCar
export const get_car_endpoint = (id: string) => `${base_url}/cars/${id}`

// check car actions
export const get_car_action_check_endpoint = (p: IGetCarActionCheckRequest) =>
    `${base_url}/cars/${p.carid}/actions/checkAction${
        p.action ? `?action=${p.action}` : ''
    }`

// make
export const list_car_make_endpoint = `${base_url}/assets/cars/makes`

// model by make
export const list_car_models_endpoint = (makeId: string) =>
    `${base_url}/assets/cars/models?make=${makeId}`

// body type
export const list_car_body_type_endpoint = `${base_url}/assets/cars/bodytypes`

// insurance
export const list_insurance_dropdowns_endpoint = (qparams: string) =>
    `${base_url}/dropdowns${qparams}`

// transmission type
export const list_transmission_type_endpoint = `${base_url}/assets/cars/transmissiontypes`

// update car endpoint
export const update_car_endpoint = (carID: string) =>
    `${base_url}/cars/${carID}`

// create car image endpoint
export const create_car_image_endpoint = (carID: string) =>
    `${base_url}/cars/${carID}/images/`

// update car image endpoint
export const update_car_image_endpoint = (carID: string, imageID: string) =>
    `${base_url}/cars/${carID}/images/${imageID}`

// update car image endpoint
export const delete_car_image_endpoint = (carID: string, imageID: string) =>
    `${base_url}/cars/${carID}/images/${imageID}`

// create make
export const create_make_endpoint = `${base_url}/assets/cars/makes`

// create model
export const create_model_endpoint = `${base_url}/assets/cars/models`
// years
export const list_car_years_endpoint = `${base_url}/assets/cars/years`
// images UPLOAD
// car gallery type presigned url
export const upload_image_car_gallery_type_endpoint = (
    carID: string,
    count: number,
    file_names: string[]
) => {
    let url = file_names.reduce((acc, fileName) => {
        const encodedFileName = encodeURIComponent(fileName)
        return acc + `&file_names=${encodedFileName}`
    }, `${base_url}/storage/upload/car?type=image_car_gallery&car_id=${carID}`)
    return url
}

// cost attachments
export const upload_cost_attachment_presignedurl_endpoint = (
    p: IUploadAttachmentPreSignedUrlPayloadSingle
) =>
    `${base_url}/storage/upload/cost?file_suffix=${p.fileSuffix}&car_id=${p.carID}&cost_id=${p.entityID}&count=1`

// entry attachments
// export const upload_entry_attachment_presignedurls_endpoint = (
//     p: IUploadAttachmentPreSignedUrlPayloadSaga
// ) =>
//     `${base_url}/storage/upload/entry?file_suffix=${p.fileSuffix}&car_id=${p.carID}&entry_id=${p.entityID}&count=${p.count}`
export const upload_entry_attachment_presignedurls_endpoint = (
    p: IUploadAttachmentPreSignedUrlPayloadSaga
) =>
    `${base_url}/storage/upload/entries?file_suffix=${p.fileSuffix}&car_id=${p.carID}&entry_id=${p.entityID}&count=${p.count}`

// user profile image
export const upload_user_profile_image_type_endpoint = (fileName: string) => {
    const encodedFileName = encodeURIComponent(fileName)
    return `${base_url}/storage/upload/profile?file_name=${encodedFileName}`
}

// car cover image type :
export const upload_image_car_cover_type_endpoint = (p: {
    carid: string
    file_name: string
}) => {
    const encodedFileName = encodeURIComponent(p.file_name)
    return `${base_url}/storage/upload/car?type=image_car_cover&car_id=${p.carid}&file_names=${encodedFileName}`
}

// create / add  address
export const create_address_endpoint = `${base_url}/addresses`

// get countries dropdown  for address- data, uid
export const list_countries_dropdown_endpoint = `${base_url}/assets/address/countries`

// get car data given registration number
export const get_car_data_by_registration_number_endpoint = (
    registration_number: string
) =>
    `${base_url}/cars/actions/getDataFromRegistrationNumber/${registration_number}`

// get task by task id :
export const get_task_by_task_id_endpoint = (taskID: string) =>
    `${base_url}/tasks/${taskID}`

// task reminders endpoint:
export const task_reminders_endpoint = (taskID: string) =>
    `${base_url}/tasks/${taskID}/reminders`

// filter tasks endpoints:
export const filter_tasks_car_id_endpoint = (car_id: string) =>
    `${base_url}/storage/upload/car?car_id=${car_id}`

// filter tasks by garage endpoint:
export const filter_tasks_garage_endpoint = (garage_id: string) =>
    `${base_url}/tasks?garage_id=${garage_id}`

// filter tasks by car endpoint:
export const filter_tasks_car_endpoint = (car_id: string) =>
    `${base_url}/tasks?car_id=${car_id}`

// update task by task id :
export const update_task_by_task_id_endpoint = (taskID: string) =>
    `${base_url}/tasks/${taskID}`

// create task :
export const create_task_endpoint = `${base_url}/tasks`

// delete Tasl
export const delete_task_endpoint = (taskID: string) =>
    `${base_url}/tasks/${taskID}`

// delete Reminder Task
export const delete_task_reminder_endpoint = (p: IDeleteTaskReminderAPIReq) =>
    `${base_url}/tasks/${p.taskUID}/reminders/${p.reminderID}`

// link cars to task :
export const link_task_to_cars = (payload: ITaskRelationsPayload) => {
    let ur_built: string = `${base_url}/tasks/${payload.taskid}/actions/link?`

    // payload.car_id.forEach((carid: string, index: number) => {

    for (const carid of payload.car_id) {
        if (carid === payload.car_id[0]) {
            ur_built = ur_built.concat(`car_id=${carid}`)
        } else {
            ur_built = ur_built.concat(`&car_id=${carid}`)
        }
    }

    return ur_built
}

// unlink cars to task :
export const unlink_task_to_cars = (payload: ITaskRelationsPayload) => {
    let ur_built: string = `${base_url}/tasks/${payload.taskid}/actions/unlink?`

    // payload.car_id.forEach((carid: string, index: number) => {

    for (const carid of payload.car_id) {
        if (carid === payload.car_id[0]) {
            ur_built = ur_built.concat(`car_id=${carid}`)
        } else {
            ur_built = ur_built.concat(`&car_id=${carid}`)
        }
    }

    return ur_built
}

// create task :
export const delete_car_endpoint = (carid: string) =>
    `${base_url}/cars/${carid}`

// update_car_highlighted_facts_endpoint

export const update_car_highlighted_facts_endpoint = (carID: string) =>
    `${base_url}/cars/${carID}/facts`

// Entries ===========================

// create entry

export const create_entry_endpoint = (car_id: string) =>
    `${base_url}/entries?car_id=${car_id}`

export const add_entry_attachment = (car_id: string, entryUID: string) =>
    `${base_url}/entries/${entryUID}/attachments?car_id=${car_id}&multi=true`

export const add_cost_attachment = (
    car_id: string,
    entryUID: string,
    costUID: string
) =>
    `${base_url}/entries/${entryUID}/costs/${costUID}/attachments?car_id=${car_id}`

// get all entries by carid

export const get_all_entries_by_carid_endpoint = (
    car_id: string,
    user_currency: ICurencyUIDsEnum
) => `${base_url}/entries?car_id=${car_id}&user_currency=${user_currency}`

// get all entries by carid and category and labelIDs

export const get_all_entries_by_carid_and_category_and_labelsIds_and_TitleQ_endpoint =
    (p: IFilterEntriesPayloadAPIReq) => {
        let url_built: string = `${base_url}/entries?car_id=${p.car_id}&user_currency=${p.user_currency}`
        if (p.entry_type_ids) {
            for (const entry_type_id of p.entry_type_ids) {
                url_built = url_built.concat(`&entry_type=${entry_type_id}`)
            }
        }
        if (p.labels_ids) {
            for (const label_id of p.labels_ids) {
                url_built = url_built.concat(`&label_uid=${label_id}`)
            }
        }
        if (p.entry_title) {
            url_built = url_built.concat(`&title_query=${p.entry_title}`)
        }
        return url_built
    }

// get entry by UID

export const get_entry_by_uid_endpoint = (
    entryUID: string,
    car_id: string,
    user_currency: ICurencyUIDsEnum
) =>
    `${base_url}/entries/${entryUID}?car_id=${car_id}&user_currency=${user_currency}`

// update entry

export const update_entry_endpoint = (entryUID: string, car_id: string) =>
    `${base_url}/entries/${entryUID}?car_id=${car_id}`

// delete entry

export const delete_entry_endpoint = (entryUID: string, car_id: string) =>
    `${base_url}/entries/${entryUID}?car_id=${car_id}`

// add cost entry

export const add_cost_to_entry_endpoint = (entryUID: string, car_id: string) =>
    `${base_url}/entries/${entryUID}/costs?car_id=${car_id}`

// get cost by UID

export const get_entry_cost_by_uid_endpoint = (p: IApiEntryCostGET_args) =>
    `${base_url}/entries/${p.entry_id}/costs/${p.cost_id}?car_id=${p.car_id}&user_currency=${p.user_currency}`

// update cost

export const update_entry_cost_endpoint = (
    entryUID: string,
    costUID: string,
    car_id: string
) => `${base_url}/entries/${entryUID}/costs/${costUID}?car_id=${car_id}`

// delete cost

export const delete_entry_cost_endpoint = (p: IApiEntryCostDELETE_args) =>
    `${base_url}/entries/${p.entry_id}/costs/${p.cost_id}?car_id=${p.car_id}`

export const get_user_car_entry_labels_endpoint = (UID: string) =>
    `${base_url}/users/${UID}/labels/car_entries`

export const unlink_label_from_entry_endpoint = (
    entryUID: string,
    car_id: string
) => `${base_url}/entries/${entryUID}/labels?car_id=${car_id}`

export const add_label_to_entry_endpoint = (entryUID: string, car_id: string) =>
    `${base_url}/entries/${entryUID}/labels?car_id=${car_id}`

export const delete_cost_attachment_endpoint = (
    p: IDeleteCostAttachmentPayloadReq
) =>
    `${base_url}/entries/${p.entryID}/costs/${p.costID}/attachments/${p.attachmentID}/?car_id=${p.carID}`

export const delete_entry_attachment_endpoint = (
    p: IDeleteEntryAttachmentPayloadReq
) =>
    `${base_url}/entries/${p.entryID}/attachments/${p.attachmentID}/?car_id=${p.carID}`

export const list_time_zones_endpoint = `${base_url}/assets/timezones/`

// SHARES

// GARAGE PUBLIC SHARES
export const garage_public_shares_endpoint = (garageid: string) =>
    `${base_url}/garage/${garageid}/shares?share_type=link_share`

// GARAGE DIRECT SHARES
export const garage_direct_shares_endpoint = (garageid: string) =>
    `${base_url}/garage/${garageid}/shares?share_type=direct_share`

// CAR PUBLIC SHARES
export const car_public_shares_endpoint = (carid: string) =>
    `${base_url}/cars/${carid}/shares?share_type=link_share`

// CAR DIRECT SHARES
export const car_direct_shares_endpoint = (carid: string) =>
    `${base_url}/cars/${carid}/shares?share_type=direct_share`

//  CREATE PUBLIC AND DIRECT SHARES
export const create_share_endpoint = `${base_url}/shares`

// DELETE SHARES
export const delete_share_endpoint = (p: IDeleteShareAPi_Arg) =>
    `${base_url}/shares/${p.share_id}?share_type=${p.share_type}`

// LIST SHARE RECIPIENTS
export const list_share_recipients = (share_id: string) =>
    `${base_url}/shares/${share_id}/recipients`

// ADD RECIPIENT To SHARE
export const add_share_recipient = (share_id: string) =>
    `${base_url}/shares/${share_id}/recipients`

// DELETE RECIPIENT

export const delete_share_recipient = (
    share_id: string,
    recipient_id: string
) => {
    return `${base_url}/shares/${share_id}/recipients/${recipient_id}`
}

// CREATE SHARE INVITE
export const create_share_invite = (share_id: string) =>
    `${base_url}/shares/${share_id}/actions/invite`

// RESEND SHARE INVITE
export const resend_share_invite = (invite_uid: string) => {
    return `${base_url}/invites/${invite_uid}/actions/resend`
}

// ACCEPT SHARE INVITE
export const accept_share_invite = (invite_uid: string) => {
    return `${base_url}/invites/${invite_uid}/actions/accept`
}

// DELETE SHARE INVITE
export const delete_share_invite = (invite_uid: string) => {
    return `${base_url}/invites/${invite_uid}`
}

// GET SHARES
export const get_share_endpoint = (p: IGetSingleShareAPI_Arg) =>
    `${base_url}/shares/${p.share_id}?type=${p.share_type}`

// GET USER'S DIRECT SHARES LIST
export const get_user_shares_list = (arg: IURL_user_getSharesList_arg) =>
    `${base_url}/shares?share_type=${arg.share_type}&resource_type=${arg.resource_type}&selected_type=${arg.selected_type}`

// UPDATE RECEIVED SHARES
export const update_received_share_status = (share_id: string) =>
    `${base_url}/shares/${share_id}`

//  CREATE SHARING URL SHORTENER
export const create_sharing_url_shortener = `${base_url}/shares/short_url`

// entries gallery

export const upload_image_entry_gallery_type_endpoint = (
    entryid: string,
    carid: string,
    count: number,
    file_names: string[]
) => {
    let url = file_names.reduce((acc, fileName) => {
        const encodedFileName = encodeURIComponent(fileName)
        return acc + `&file_names=${encodedFileName}`
    }, `${base_url}/storage/upload/entry/images?entry_id=${entryid}&car_id=${carid}`)
    return url
}

export const create_entry_image_endpoint = (entryid: string, carid: string) =>
    `${base_url}/entries/${entryid}/images?car_id=${carid}`

export const update_entry_image_endpoint = (
    entryid: string,
    carid: string,
    imageid: string
) => `${base_url}/entries/${entryid}/images/${imageid}?car_id=${carid}`

export const delete_entry_image_endpoint = (
    entryid: string,
    carid: string,
    imageid: string
) => `${base_url}/entries/${entryid}/images/${imageid}?car_id=${carid}`

export const search_users_direct_share_endpoint = (
    p: IApi_SearchUsersInDirectShare_args
) => {
    let url_built: string = `${base_url}/search/users?given_name=${p.filter.given_name}&family_name=${p.filter.family_name}&display_name=${p.filter.display_name}`
    if (p.limit) {
        url_built = url_built.concat(`&limit=${p.limit}`)
    }
    if (p.offset) {
        url_built = url_built.concat(`&offset=${p.offset}`)
    }
    if (p.accuracy) {
        url_built = url_built.concat(`&accuracy=${p.accuracy}`)
    }
    return url_built
}

// showroom

export const create_showroom_entry_endpoint = `${base_url}/showroom`
export const delete_showroom_entry_endpoint = (uid: string) =>
    `${base_url}/showroom/${uid}`

export const get_showroom_entry_by_id_endpoint = (uid: string) =>
    `${base_url}/showroom/${uid}?currency=GBP`

export const validate_showroom_entry_by_id_endpoint = (uid: string) =>
    `${base_url}/showroom/${uid}/validate`

export const showroom_entry_to_watchlist_endpoint = (uid: string) =>
    `${base_url}/showroom/${uid}/watchlist`

export const showroom_entry_to_watchlist_isWatching_endpoint = (uid: string) =>
    `${base_url}/showroom/${uid}/watchlist/is_watching`

export const get_public_showroom_entry_by_id_endpoint = (uid: string) =>
    `${base_url}/public/showroom/${uid}?currency=GBP`

export const update_showroom_entry = (uid: string) =>
    `${base_url}/showroom/${uid}`

export const showroom_enquiry_by_entry_id_endpoint = (uid: string) =>
    `${base_url}/showroom/${uid}/enquiry`

export const showroom_search_with_filters = (urlBuilt: string) =>
    `${base_url}/public/search/showroom${urlBuilt}`

export const showroom_list_watchlist_entries = (urlBuilt: string) =>
    `${base_url}/showroom/watchlist${urlBuilt}`

export const archive_car_endpoint = (uid: string) =>
    `${base_url}/cars/${uid}/archive`

export const user_archives_endpoint = (urlBuilt: string) =>
    `${base_url}/user/archives${urlBuilt}`

// HANDOVER

export const create_handover_endpoint = (uid: string) =>
    `${base_url}/handovers/${uid}`

export const delete_handover_endpoint = (uid: string) =>
    `${base_url}/handovers/${uid}`

export const list_handovers_endpoint = (urlBuilt: string) =>
    `${base_url}/handovers/${urlBuilt}`

export const check_user_exists_by_email = (email: string) =>
    `${base_url}/users/actions/exists?email=${email}`

export const insurance_appli_patch = (appli_id: string) =>
    `${base_url}/insurance/applications/${appli_id}`

export const insurance_appli_get = (appli_id: string) =>
    `${base_url}/insurance/applications/${appli_id}`

export const insurance_applications_user_get = `${base_url}/insurance/applications`

export const insurance_applications_user_create = `${base_url}/insurance/applications`

export const insurance_applications_user_delete = (appli_id: string) =>
    `${base_url}/insurance/applications/${appli_id}`
export const insurance_applications_user_validate = (appli_id: string) =>
    `${base_url}/insurance/applications/${appli_id}/validate`

export const insurance_applications_publish = (appli_id: string) =>
    `${base_url}/insurance/applications/${appli_id}/publish`

export const url_getCarInsights = (
    carID: string,
    start_date: string,
    end_date: string
): string => {
    if (`${start_date}` === '1850-01-01T00:00:00.000Z') {
        return `${base_url}/cars/${carID}/analytics?query=STATS&end_date=${end_date}`
    } else
        return `${base_url}/cars/${carID}/analytics?query=STATS&start_date=${start_date}&end_date=${end_date}`
}

export const url_getCarCategoryInsights = (
    carID: string,
    start_date: string,
    end_date: string,
    category: string,
    representation: string
): string => {
    let entry_types_params =
        category !== 'all' ? `&entry_types=${category}` : ''

    if (`${start_date}` === '1850-01-01T00:00:00.000Z') {
        return `${base_url}/cars/${carID}/analytics?query=TIME_SERIES&end_date=${end_date}${entry_types_params}&rollup_by=${
            representation?.toLowerCase() === 'monthly' ? 'MONTH' : 'YEAR'
        }`
    } else
        return `${base_url}/cars/${carID}/analytics?query=TIME_SERIES&start_date=${start_date}&end_date=${end_date}${entry_types_params}&rollup_by=${
            representation?.toLowerCase() === 'monthly' ? 'MONTH' : 'YEAR'
        }`
}
