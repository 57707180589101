import { IApexLocalCopy } from 'apexLocalCopyModels'

export const apexLocalCopyData: IApexLocalCopy  = {
        overviewPage: {
            latestPodcasts: {
                title: 'Latest Podcasts',
                subTitle: 'Our latest, weekly conversations with the titans and tastemakers of the automotive universe.'
            },
            latestArticles: {
                title: 'Latest Articles',
                subTitle: 'Original stories and retrospectives about cars, people, and the automotive moments that inspire.'
            },
            carOfTheWeek: {
                title: 'Car of the Week',
                subTitle: 'A collection of our favourite cars on the market we think you should know about.'
            },
            newsHighlights: {
                title: 'News Highlights',
                subTitle: 'Curated news highlights and events from around the car world.'
            },
            timelessInterviews: {
                title: 'Timeless Interviews',
                subTitle: 'Insights and images from industry insiders, motorsport legends, collectors and more.'
            },
        },
    separatePage: {
        podcasts: {
            title: 'Podcasts',
            subTitle: 'Our latest, weekly conversations with the titans and tastemakers of the automotive universe.'
        },
        articles: {
            title: 'Articles',
            subTitle: 'Original stories and retrospectives about cars, people, and the automotive moments that inspire.'
        },
        carOfTheWeek: {
            title: 'Car of the Week',
            subTitle: 'A collection of our favourite cars on the market we think you should know about.'
        },
        newsHighlights: {
            title: 'News Highlights',
            subTitle: 'Curated news highlights and events from around the car world.'
        },
        interviews: {
            title: 'Interviews',
            subTitle: 'Insights and images from industry insiders, motorsport legends, collectors and more.'
        },
    }
}