type Props = {
    width?: string
    height?: string
    color?: string
}

const LightbulbIcon = ({ width, height, color }: Props) => {
    return (
        <svg
            width={width ?? '24'}
            height={height ?? '25'}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2.85059C8.40328 2.85059 5.48755 5.76631 5.48755 9.36305C5.48755 10.812 5.95988 12.1486 6.75926 13.2299C8.65165 15.1355 9.48494 16.912 9.74744 18.0542H14.2378C14.4215 17.1822 14.8854 16.3028 15.3654 15.5637C15.9875 14.6057 16.7265 13.7497 17.2406 13.2302C18.0401 12.1488 18.5125 10.8121 18.5125 9.36305C18.5125 5.76631 15.5967 2.85059 12 2.85059ZM3.98755 9.36305C3.98755 4.93789 7.57485 1.35059 12 1.35059C16.4252 1.35059 20.0125 4.93789 20.0125 9.36305C20.0125 11.1663 19.4159 12.8324 18.4096 14.1717L18.3777 14.2141L18.3402 14.2516C17.887 14.7047 17.1957 15.4995 16.6234 16.3807C16.0316 17.2918 15.655 18.1636 15.655 18.8042V19.5542H8.34503V18.8042C8.34503 18.3402 7.89477 16.4872 5.65965 14.2515L5.62222 14.2141L5.59042 14.1717C4.58415 12.8324 3.98755 11.1663 3.98755 9.36305Z"
                fill={color ?? '#5EC3CA'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.28906 21.3506L15.6725 21.3506L15.6725 22.8506L8.28906 22.8506L8.28906 21.3506Z"
                fill={color ?? '#5EC3CA'}
            />
        </svg>
    )
}

export default LightbulbIcon
