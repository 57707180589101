import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

type Props = {
    children: React.ReactNode
    id?: string
}

type IStyled = {
    onboarding_id?: string
}
const Container = styled.div`
    width: 100lvw;
    height: 100lvh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    justify-content: flex-start;
    padding-top: 8vh;
    /* @media ${device.large_desktop} {
        justify-content: flex-start;
    } */
`
const Wrapper = styled.div<IStyled>`
    display: flex;
    width: 880px;
    /* min-height: 680px; */
    min-height: ${(props) =>
        `${props.onboarding_id}` === '1' ? '668px' : '614px'};
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    background: var(--bg-color, #fff);
    box-shadow: 0px 24px 32px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
    padding: 40px;
    padding-top: 24px;
    position: relative;

    overflow-x: hidden;

    transition: all 100ms;

    @media (max-width: 1200px) {
        width: 800px;
        min-height: ${(props) =>
            `${props.onboarding_id}` === '1' ? '628px' : '625px'};
    }

    @media (max-width: 845px) {
        width: 704px;
        min-height: ${(props) =>
            `${props.onboarding_id}` === '1' ? '622px' : '655px'};
    }
`

export const InsuranceOnboardingWindowTemplate = (props: Props) => {
    return (
        <Container>
            <Wrapper onboarding_id={props.id}>{props.children}</Wrapper>
        </Container>
    )
}
