import * as React from 'react'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import SquareDuoMobile from '../../molecules/imageGrid/squareDuoMobile'
import AddImageBanner from '../../atoms/image/addImageBanner'
import Loader from '../../atoms/loader/loader'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import { IGalleryImagesObject } from 'entityModels'

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

export interface IImageProps {
    url: string
}

interface Props {
    imagesData: IGalleryImagesObject | null
    isNecessaryGalleryDataLoading: boolean
    ids_list: string[]
    openImgForm?: () => void
    isGettingCarDataLoading: boolean
    readOnlyMode?: boolean
}

type State = {
    isEmpty: boolean | null
}

class CarGalleryManagerMobile extends React.Component<Props, State> {
    state = {
        isEmpty: true,
    }

    componentDidMount() {
        if (!this.props.ids_list || this.props.ids_list.length < 1) {
            this.setState({ isEmpty: true })
        } else {
            this.setState({ isEmpty: false })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.ids_list && this.props.ids_list !== prevProps.ids_list) {
            if (this.props.ids_list.length < 1) {
                this.setState({ isEmpty: true })
            } else {
                this.setState({ isEmpty: false })
            }
        }
    }

    render() {
        const { isEmpty } = this.state
        const {
            imagesData,
            ids_list,
            openImgForm,
            isNecessaryGalleryDataLoading,
            isGettingCarDataLoading,
            readOnlyMode,
        } = this.props

        return (
            <React.Fragment>
                {isEmpty !== true &&
                    !isNecessaryGalleryDataLoading &&
                    !isGettingCarDataLoading &&
                    imagesData && (
                        <SquareDuoMobile
                            images_data_object={imagesData}
                            ids_list={ids_list}
                            onAddClick={openImgForm}
                            readOnlyMode={readOnlyMode}
                        />
                    )}

                <Loader
                    isLoading={
                        isGettingCarDataLoading || isNecessaryGalleryDataLoading
                    }
                />

                {isEmpty === true &&
                    (readOnlyMode ? (
                        <>
                            <div style={{ paddingTop: '20px' }} />
                            <NoDataRectangle text="no images" />
                        </>
                    ) : (
                        <AddImageBanner
                            ipadHeight="350px"
                            onClick={openImgForm}
                        />
                    ))}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(CarGalleryManagerMobile)
