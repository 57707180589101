import * as React from "react";

import styled from "styled-components";
import pcclogo from "../../../public/assets/logo/newlogo.svg";

const LogoImg = styled.img`
  position: absolute;
  top: 30px;
  right: 30px;
  height: 100vh;
  maxheight: 1500px;
  opacity: 0.02;
  overflow: hidden;
`;

const LogoBg: React.SFC<{}> = () => <LogoImg src={pcclogo} alt="PCC" />;

export default LogoBg;
