import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const deleteHandover = (carID: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.delete_handover_endpoint(carID), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                apiSentryErrorHandler(response, 'Delete car handover error')
                return Promise.reject(response)
            }
            return
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
