import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { setCurrentExternalCar } from '../../../../redux/entities/cars/actions/thunks'
import {
    IExternalCar,
    IExternalCarsObject,
    IGalleryImagesObject,
} from 'entityModels'
import { getExternalCarsSharedWithYouREQUEST } from '../../../../redux/entities/cars/actions/loadingActions'
import { IUser } from 'myModels'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import ReadOnlyCarGallery from '../../../templates/readOnly/car/readOnlyGallery'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    externalCarsData: IExternalCarsObject | undefined
    images_data: IGalleryImagesObject | null
    setCurrentExternalCar: (carid: string) => void
    location: any
    isNecesarryDataLoading: boolean
    getExternalCarsSharedWithYouREQUEST: (car_id: string) => void
    setInitialLandingUrl: (str: string) => void
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentExternalCar: (car_id: string) => setCurrentExternalCar(car_id),
    getExternalCarsSharedWithYouREQUEST: (car_id: string) =>
        getExternalCarsSharedWithYouREQUEST(car_id),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

type State = {
    placeholderUrl: string
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
        images_data: state.entities.galleriesData.images,
        isNecesarryDataLoading: state.entities.carsData.loading,
    }
}

class ExternalCarGallery extends React.Component<Props, State> {
    componentDidMount() {
        window.scrollTo(0, 0)
        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        let carid = this.props.match.params.carid
        this.props.setInitialLandingUrl(`/shared/car/${carid}/gallery`)
        this.props.setCurrentExternalCar(carid)

        this.props.getExternalCarsSharedWithYouREQUEST(carid)
    }

    render() {
        let carid = this.props.match.params.carid
        let current: IExternalCar | undefined =
            this.props.externalCarsData && this.props.externalCarsData[carid]

        let userLoggedIn = this.props.userLoggedIn

        return (
            <React.Fragment>
                {current && userLoggedIn && (
                    <ReadOnlyCarGallery
                        sharedCar={current}
                        userLoggedIn={userLoggedIn}
                        hasPaddingTop
                    />
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ExternalCarGallery)
)
