import posthog from 'posthog-js'
import * as React from 'react'

import QrCodeActionBtnWrapper from '../Button/qrCodeActionBtnWrapper'
import { downloadRawQrCode } from '../pngDownloadButton/generatePngQRCodeRaw'

type Props = {
    id_of_qr_code_to_export: string
    qr_code_title_inside_pdf: string
    isMobile?: boolean
    dataCyId?: string
}

const PdfDownloadButton: React.FunctionComponent<Props> = (props) => {
    const {
        id_of_qr_code_to_export,
        qr_code_title_inside_pdf,
        isMobile,
        dataCyId,
    } = props

    return (
        <QrCodeActionBtnWrapper
            onClick={() => {
                downloadRawQrCode({
                    id_of_svg_qr_code: id_of_qr_code_to_export,
                    format: 'PDF',
                    title: qr_code_title_inside_pdf,
                    device: isMobile ? 'mobile' : 'desktop',
                })
                // capture qr_code raw PDF doc generation/download
                posthog.capture('raw_qr_code_pdf_doc_download_btn clicked')
            }}
            variant={'pdf'}
            dataCyId={dataCyId}
        >
            {isMobile ? 'PDF' : 'Save PDF'}
        </QrCodeActionBtnWrapper>
    )
}

export default PdfDownloadButton
