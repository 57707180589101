import { ICar, IGalleryImage, IGalleryImagesObject } from 'entityModels'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useParams, withRouter } from 'react-router'
import { getCarDataByIdRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import { IRootState } from '../../../../redux/store'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import Loader from '../../../atoms/loader/loader'
import ShowroomEditGalleryGrid from '../../../molecules/imageGrid/showroomEditGalleryGrid'
import CarGalleryShowroomEditBottomBar from '../../../templates/bars/showroomBars/bottomBars/carGalleryShowroomEditBottomBar'
import CarGalleryShowroomEditTopBar from '../../../templates/bars/showroomBars/topBars/carGalleryShowroomEditTopBar'

type EntryParams = {
    entryid: string
}

const CarGalleryShowroomEdit: React.FunctionComponent<RouteComponentProps> = (
    props: RouteComponentProps
) => {
    const { entryid } = useParams<EntryParams>()

    const [selectedImages, setSelectedImages] = useState<string[]>([])

    let userShowroomEntriesData = useAppSelector((state) => {
        return state.showroom.userShowroomEntries
    })

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let currentEntry: IShowroomEntry | undefined =
        entryid && userShowroomEntriesData && userShowroomEntriesData[entryid]
            ? userShowroomEntriesData[entryid]
            : undefined

    let carProfile: ICar | undefined = currentEntry
        ? currentEntry.car
        : undefined

    let carid = carProfile?.id

    let dispatch = useAppDispatch()
    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    const userLoggedIn = useAppSelector((state: IRootState) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        }
    }, [])

    useEffect(() => {
        // trigger the function to dispatch the top sub menu remove action
        removeTopSubMenu({ isRemoved: true })

        if (userLoggedIn) {
            if (
                carProfile?.id &&
                (!carsData || (carsData && !carsData[carProfile?.id]))
            ) {
                dispatch(getCarDataByIdRequest(carProfile.id))
            }

            if (
                (entryid && !userShowroomEntriesData) ||
                (userShowroomEntriesData && !userShowroomEntriesData[entryid])
            ) {
                dispatch(
                    showroomActions.getShowroomEntryByIDRequest({
                        is_owner: true,
                        entryID: entryid,
                    })
                )
            }

            if (
                userShowroomEntriesData &&
                userShowroomEntriesData[entryid] &&
                userShowroomEntriesData[entryid].gallery_images
            ) {
                setSelectedImages(
                    userShowroomEntriesData[entryid].gallery_images!.map(
                        (img: IGalleryImage) => img.id
                    )
                )
            }
        }
        // set up nav back as before on page unmount
        return () => {
            removeTopSubMenu({ isRemoved: false })
        }
    }, [entryid, userShowroomEntriesData, userLoggedIn])

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let carGalleryIds =
        carid && carsData[carid]
            ? [...carsData[carid].gallery.images]
            : undefined

    let isAllSelected =
        carGalleryIds && selectedImages.length === carGalleryIds.length
            ? true
            : false

    let isNecessaryGalleryDataLoading: boolean = useAppSelector((state) => {
        return state.entities.galleriesData.loading
    })

    let isGettingCarDataLoading = useAppSelector((state) => {
        return state.entities.carsData.loading
    })

    let onSelect = (id: string) => {
        let indexOfItem: number = selectedImages.indexOf(id)
        let currentSelectedCriteria: string[] = [...selectedImages]
        if (indexOfItem < 0) {
            currentSelectedCriteria.push(id)
            setSelectedImages(currentSelectedCriteria)
        } else {
            currentSelectedCriteria.splice(indexOfItem, 1)
            setSelectedImages(currentSelectedCriteria)
        }
    }

    let onSelectAll = () => {
        if (carid) {
            setSelectedImages([...carsData[carid].gallery.images])
        }
    }

    let onDeselectAll = () => {
        setSelectedImages([])
    }

    const [activeStep, setActiveStep] = useState<'select' | 'sort'>('select')

    return (
        <React.Fragment>
            <CarGalleryShowroomEditTopBar
                onClickSortSelected={() => {
                    setActiveStep(activeStep === 'select' ? 'sort' : 'select')
                }}
                activeStep={activeStep}
                isMobile={false}
                onSelectAll={onSelectAll}
                onDeselectAll={onDeselectAll}
                isAllSelected={isAllSelected}
                noneIsSelected={selectedImages?.length === 0}
            />

            <div style={{ paddingTop: '80px' }} />

            {images_data &&
                !isGettingCarDataLoading &&
                carProfile &&
                !isNecessaryGalleryDataLoading &&
                images_data && (
                    <ShowroomEditGalleryGrid
                        images_data_object={images_data}
                        ids_list={carProfile.gallery.images}
                        onSelect={onSelect}
                        selectedImages={selectedImages}
                        activeStep={activeStep}
                        setSelectedImages={setSelectedImages}
                    />
                )}

            {isNecessaryGalleryDataLoading === true ||
                (isGettingCarDataLoading === true && (
                    <Loader isLoading={isNecessaryGalleryDataLoading} />
                ))}

            <CarGalleryShowroomEditBottomBar
                txt={
                    activeStep === 'select'
                        ? 'Selected images will be displayed in Showroom Car gallery. Please select a minimum of 6 images.'
                        : 'Drag & Drop images in prefered order.'
                }
                cancel={() => {
                    // props.history.goBack()
                    props.history.replace(`/showroom/${entryid}/edit`)
                }}
                isDisabled={selectedImages.length < 6 ? true : false}
                isMobile={false}
                save={() => {
                    if (
                        currentEntry &&
                        currentEntry.id &&
                        carProfile &&
                        carProfile.id
                    ) {
                        dispatch(
                            showroomActions.updateEntryShowroomRequest({
                                data: {
                                    gallery_image_ids: selectedImages,
                                },
                                car_id: carProfile.id,
                                uid: currentEntry.id,
                            })
                        )
                        props.history.replace(`/showroom/${entryid}/edit`)
                    }
                }}
                activeStep={activeStep}
                onSelectAll={onSelectAll}
                onDeselectAll={onDeselectAll}
                isAllSelected={isAllSelected}
                noneIsSelected={selectedImages?.length === 0}
            />
        </React.Fragment>
    )
}

export default withRouter(CarGalleryShowroomEdit)
