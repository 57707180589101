import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type Props = {
    active: boolean
}

const SharingAttachmentIcon = ({ active }: Props) => {
    const { theme } = useThemes()
    return (
        <div
            style={{
                transform: 'scale(1.3)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <svg
                width="13"
                height="13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.236 5.45a.25.25 0 0 0-.376-.331l-5.047 5.733a3.3 3.3 0 0 1-2.293 1.123 3.301 3.301 0 0 1-2.411-.838A3.375 3.375 0 0 1 1.822 6.4l4.304-4.89C6.518 1.063 7.06.8 7.65.762a2.191 2.191 0 0 1 1.601.557c.915.815 1 2.227.19 3.147L5.73 8.683c-.194.22-.46.35-.752.368a1.083 1.083 0 0 1-.791-.275 1.109 1.109 0 0 1-.095-1.556l2.673-3.036a.25.25 0 0 0-.376-.33L3.717 6.889a1.61 1.61 0 0 0 .137 2.26c.32.285.73.427 1.155.401a1.58 1.58 0 0 0 1.096-.537l3.711-4.215A2.743 2.743 0 0 0 9.583.946 2.689 2.689 0 0 0 7.619.264a2.688 2.688 0 0 0-1.868.915l-4.305 4.89a3.855 3.855 0 0 0-.953 2.789c.06.985.492 1.948 1.283 2.653a3.797 3.797 0 0 0 2.775.963 3.798 3.798 0 0 0 2.638-1.292l5.047-5.733Z"
                    fill={
                        active
                            ? colours[theme].text_default
                            : colours[theme].border_muted
                    }
                />
            </svg>
        </div>
    )
}

export default SharingAttachmentIcon
