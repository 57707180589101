import { IDirectShareOwnedItem_Api_payload } from 'ApiInterfaces'
import {
    ICarsObject,
    IDirectSharesSuccessPayload_redux_arg,
    IReduxError,
} from 'entityModels'
import { call, fork, put, select, take } from 'redux-saga/effects'
import { RootState } from 'typesafe-actions'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { ConvertEntityDirectSharesListToState } from '../../../conversions/shares/convertSharesOwnedToState'
import { api } from '../../../services'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import * as actions from '../actions'

const getCars = (state: RootState) => state.entities.carsData.cars

export function* GetOwnedDirectSharesCar(carid: string) {
    try {
        let carDirectSharesAPIres: IDirectShareOwnedItem_Api_payload[] =
            yield call(api.entities.getData.getCarDirectSharesAPI, carid)

        let convertedObj: IDirectSharesSuccessPayload_redux_arg = {
            id: carid,
            private_shares_owned: {
                entity_owned_direct_shares_ids: [],
                entity_owned_direct_shares_data: {},
            },
        }

        let cars_in_state: ICarsObject | null = yield select(getCars)
        let private_shares_in_state =
            cars_in_state &&
            carid &&
            cars_in_state[carid] &&
            cars_in_state[carid].private_shares_owned

        let shares_in_state =
            private_shares_in_state &&
            private_shares_in_state.entity_owned_direct_shares_data
                ? private_shares_in_state.entity_owned_direct_shares_data
                : undefined

        if (carDirectSharesAPIres && carDirectSharesAPIres.length > 0) {
            convertedObj = {
                ...convertedObj,
                private_shares_owned: ConvertEntityDirectSharesListToState({
                    api_shares: carDirectSharesAPIres,
                    shares_in_state: shares_in_state,
                }),
            }
        }

        yield put(actions.actions.getOwnedDirectSharesCarSuccess(convertedObj))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: GetOwnedDirectSharesCar,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                actions.errorActions.getOwnedDirectSharesCarError(typedError)
            )
            return
        }
    }
}

function* watcherGetOwnedDirectSharesCar() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.getOwnedDirectSharesCarRequest
        )
        yield call(GetOwnedDirectSharesCar, payload)
    }
}

const owned_car_direct_shares: any[] = [fork(watcherGetOwnedDirectSharesCar)]

export default owned_car_direct_shares
