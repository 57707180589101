import * as React from 'react'
import styled from 'styled-components'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import { device } from '../../templates/displays/devices'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { IGalleryImage } from 'entityModels'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import ImageSignMobile from '../../atoms/signs/imageSignMobile'

const LinkWrap = styled(Link)<IImgProps>`
    width: ${(props) => (props.$isunique === true ? '50vw' : '100%')};
    height: 126px;
    position: relative;
    z-index: 1;

    @media ${device.ipad} {
        height: 22vh;
    }
`
const GalleryImage = styled.img<IImgProps>`
    object-fit: cover !important;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: ${(props) => (props.$isthingloading === true ? 0 : 1)};
    transition: opacity 400ms;
`

const GalleryCount = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 141.9%;
    display: flex;
    align-items: center;
    color: white;
`
const GalleryImageBackground = styled.div<IImgProps>`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: ${(props) => (props.$isunique === true ? '50vw' : '100%')};
    height: 126px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: ${(props) => (props.$isthingloading === true ? '1' : '1')};
    transition: opacity 400ms;
    @media ${device.ipad} {
        height: 22vh;
    }
`

interface Props extends RouteComponentProps<any> {
    isLast: boolean
    isFirst: boolean
    item: IGalleryImage
    count: number | null
    cover?: IGalleryImage
    carid: string
    isUnique?: boolean
    isPlaceholder?: boolean
}
type IImgProps = {
    $isthingloading: boolean
    $isunique?: boolean
}

type State = {
    src: any
    isLoading: boolean
}

class GalleryOverviewItemMobile extends React.Component<Props, State> {
    state = {
        src: null,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true

        if (this.props.cover && this.props.cover.large_src) {
            const src = this.props.cover.large_src

            if (src) {
                this._isMounted && this.setState({ src, isLoading: true })
                this.onLoad(src)
                return
            }
        } else {
            if (this.props.item) {
                const src = this.props.item.large_src
                    ? this.props.item.large_src
                    : this.props.item.url
                this._isMounted && this.setState({ src, isLoading: true })
                this.onLoad(src)
                return
            }
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.cover !== prevProps.cover) {
            if (this.props.cover && this.props.cover.large_src) {
                const src = this.props.cover.large_src

                if (src) {
                    this.setState({ src, isLoading: true })
                    this.onLoad(src)
                    return
                }
            }
        } else {
            if (this.props.item !== prevProps.item) {
                const src = this.props.item.large_src
                    ? this.props.item.large_src
                    : this.props.item.url
                this.setState({ src, isLoading: true })
                this.onLoad(src)
                return
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    generateSrcset = (): string | undefined => {
        if (this.props.cover) {
            if (this.props.cover.small_srcset) {
                return this.props.cover.small_srcset
            } else return undefined
        } else if (this.props.item) {
            if (this.props.item.small_srcset) {
                return this.props.item.small_srcset
            } else return undefined
        } else return undefined
    }

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                ...this.state,
                isLoading: false,
            })
    }

    render() {
        const {
            isLast,
            count,
            history,
            item,
            isUnique,
            isFirst,
            isPlaceholder,
        } = this.props

        const urlLocation = history.location.pathname

        const { src, isLoading } = this.state

        let imgLinkPrefix =
            this.props.match.url && this.props.match.url.includes('overview')
                ? this.props.match.url.replace('/overview', '')
                : this.props.match.url

        return (
            <React.Fragment>
                {!isLast ? (
                    <LinkWrap
                        $isunique={isUnique && isUnique}
                        $isthingloading={isLoading}
                        to={{
                            pathname: `${imgLinkPrefix}/gallery`,
                            search: `?id=${item.id}`,
                            state: { prevPath: urlLocation },
                        }}
                        style={isPlaceholder ? { pointerEvents: 'none' } : {}}
                    >
                        {isFirst && <ImageSignMobile />}

                        <SkeletonAbsolute isthingloading={isLoading} />
                        <GalleryImage
                            $isunique={isUnique && isUnique}
                            aria-label="car overview banner"
                            src={src || grey_placeholder}
                            $isthingloading={isLoading}
                            srcSet={this.generateSrcset()}
                            onLoad={() =>
                                this._isMounted &&
                                this.setState({
                                    ...this.state,
                                    isLoading: false,
                                })
                            }
                        />
                    </LinkWrap>
                ) : (
                    <Link
                        to={{
                            pathname: `${imgLinkPrefix}/gallery`,
                            state: { prevPath: urlLocation },
                        }}
                    >
                        <GalleryImageBackground
                            role="img"
                            aria-label="car overview banner"
                            $isthingloading={true}
                            style={{
                                backgroundImage:
                                    src !== null
                                        ? `linear-gradient(to bottom,  rgba(00, 00, 00, 0.73), rgba(00, 00, 00, 0.73)), url(${
                                              src || grey_placeholder
                                          })`
                                        : `url(${grey_placeholder})`,
                            }}
                        >
                            <GalleryCount>
                                {count ? (
                                    `+ ${count}`
                                ) : (
                                    <span
                                        style={{
                                            fontFamily: 'Lato-Light',
                                            fontSize: '18px',
                                        }}
                                    >
                                        see all
                                    </span>
                                )}
                            </GalleryCount>
                        </GalleryImageBackground>
                    </Link>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(GalleryOverviewItemMobile)
