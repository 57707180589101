import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import EditIcon from '../../../atoms/statefulicons/editIcon'
import { ICarShowroomSpecs } from '../../../pages/showroom/showroom'
import FactsGridSectionDesktop from './factsGridSectionDesktop'
import { editFormsActions } from '../../../../redux/editForms/reducer'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import { convertShowroomEntryPriceInfoToUIString } from '../../../../helpers/typeConv/showroom'
import { useMemo } from 'react'
import { generateUserChoiceUnit } from '../../../../helpers/units/generateUserChoiceUnit'
import {
    generateFrontEndValueFuelUnit,
    generateFrontEndValueDistanceUnit,
} from '../../../../helpers/units/unitConversion'
import { device } from '../../../templates/displays/devices'
import { numberWithCommas } from '../../../../helpers/numbers'

type Props = {
    isLoading: boolean
    entry: IShowroomEntry | undefined
    isAdmin?: boolean
    setActiveForm?: () => any
    openLocationForm?: any
    openForm?: any
    customOpenGeneralInfoFunc?: () => any
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 100ms;
    width: 100%;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;
    flex-wrap: wrap;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    width: 40%;
    height: 100%;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 0;

    width: 100%;
`

const Price = styled.div`
    font-family: 'Lato';

    font-size: 30px;

    color: var(--text-default, #666666);
    line-height: 42px;

    @media ${device.smallest_laptops} {
        font-size: 24px;
    }

    padding-right: 15px;

    @media (max-width: 920px) {
        padding-right: 15px;
    }

    @media (min-width: 1730px) {
        padding-right: 25px;
        font-size: 40px;
    }
`
const Title = styled.div`
    font-family: Lato;
    font-size: 42px;
    color: var(--text-strong, #1a1a1a);
    width: 100%;

    @media (min-width: 881px) and (max-width: 1730px) {
        font-size: 30px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const Description = styled.div`
    font-family: Lato;
    font-size: 20px;
    line-height: 32px;
    color: var(--text-default, #666666);
    width: 100%;

    @media (min-width: 881px) and (max-width: 1730px) {
        font-size: 18px;
    }
`
const Rectangle = styled.div`
    width: 80px;
    height: 2px;
    background: var(--primary, #5ec3ca);
    transform: translateX(-20px);
`

const LocalPrice = styled.div`
    font-family: Lato;
    color: var(--text-muted, #b3b3b3);

    font-weight: 400;
    font-size: 17px;
    line-height: 42px;
    @media (max-width: 920px) {
        font-size: 16px;
    }

    @media (min-width: 1730px) {
        font-size: 24px;
    }
`

const OverviewSectionDesktop = (props: Props) => {
    let dispatch = useAppDispatch()
    let { entry, isAdmin } = props

    let userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })
    const default_fuel_unit_user: string | null = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'fuel_unit').unit_id
        : 'liters'

    const default_fuel_unit_user_text: string | null | undefined = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'fuel_unit').unit_text
        : 'liters'

    const default_distance_unit_user: string | null = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'distance_unit').unit_id
        : 'miles'

    const default_distance_unit_user_text: string | null | undefined =
        userLoggedIn
            ? generateUserChoiceUnit(userLoggedIn, 'distance_unit').unit_text
            : 'miles'

    const techInfoData = useAppSelector((state) => {
        return state.entities.technicalInformationData.technical_information
    })
    const convertCarSpecsToShowroom = useMemo(() => {
        let res: ICarShowroomSpecs = {
            make: '',
            model: '',
            year: '',
            engine_size: '',
            transmission: '',
            drive_side: '',
            mileage: '',
            location: '',
        }

        let car = entry?.car
        if (car) {
            let data = car.overview && car.overview.data

            let fuel_unit_text: string = default_fuel_unit_user_text
                ? default_fuel_unit_user_text
                : ''

            let distance_unit_text: string =
                default_distance_unit_user_text ?? ''

            let formatedEngineSize =
                default_fuel_unit_user &&
                car.technical_information &&
                techInfoData[car.technical_information] &&
                techInfoData[car.technical_information].engine_capacity &&
                techInfoData[car.technical_information].engine_capacity.answer
                    ? generateFrontEndValueFuelUnit(
                          default_fuel_unit_user,
                          // @ts-ignore
                          +techInfoData[car.technical_information]
                              .engine_capacity.answer
                      )
                    : undefined

            res.make = data.make.answer
            res.model = data.model.answer
            res.drive_side = data.drive_side.answer
            res.year = `${data.year.answer ? data.year.answer : '-'}`
            res.engine_size = `${
                formatedEngineSize
                    ? default_fuel_unit_user !== 'cubic_centimeters'
                        ? formatedEngineSize.toFixed(1)
                        : formatedEngineSize
                    : '-'
            } ${
                techInfoData[car.technical_information]?.engine_capacity?.answer
                    ? fuel_unit_text
                    : ''
            }`
            res.transmission = `${data.transmission.answer}`
            res.location =
                entry?.address?.postcode === 'SW1A 1AA'
                    ? 'Address not publicly disclosed, please contact the dealership'
                    : entry?.address?.locality
                    ? entry.address.locality
                    : '-'
            res.mileage = entry?.undisclosed_mileage
                ? 'Undisclosed'
                : techInfoData &&
                  car.technical_information &&
                  techInfoData[car.technical_information] &&
                  techInfoData[car.technical_information].mileage &&
                  techInfoData[car.technical_information].mileage.answer !==
                      undefined &&
                  techInfoData[car.technical_information].mileage.answer !==
                      null
                ? `${
                      default_distance_unit_user &&
                      techInfoData[car.technical_information].mileage.answer &&
                      numberWithCommas({
                          numberToConvert: generateFrontEndValueDistanceUnit(
                              default_distance_unit_user,
                              // @ts-ignore
                              techInfoData[car.technical_information].mileage
                                  .answer
                          ),
                      })
                  } ${distance_unit_text}`
                : '-'
        }
        return res
    }, [entry, techInfoData, default_fuel_unit_user_text])

    let specs: ICarShowroomSpecs = convertCarSpecsToShowroom

    let price: string =
        entry?.state === 'SOLD'
            ? 'SOLD'
            : entry
            ? convertShowroomEntryPriceInfoToUIString(entry).listing_price
            : '£'

    let price_local: string =
        entry?.state === 'SOLD'
            ? 'SOLD'
            : entry
            ? convertShowroomEntryPriceInfoToUIString(entry).local_price
            : '£'

    return (
        <Container>
            {entry?.car ? (
                <ContentContainer>
                    <TitleContainer>
                        <Title>
                            <div>
                                {entry.car.title ? entry.car.title : 'Untitled'}{' '}
                            </div>

                            {isAdmin && (
                                <div
                                    style={{
                                        transform: 'translateY(-5px)',
                                        paddingLeft: '20px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <EditIcon
                                        onClick={() => {
                                            if (
                                                props.customOpenGeneralInfoFunc ===
                                                undefined
                                            ) {
                                                dispatch(
                                                    editFormsActions.toggleCarOverviewEditForm()
                                                )
                                            } else {
                                                props.customOpenGeneralInfoFunc()
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </Title>
                        <Row>
                            <Price>{price}</Price>

                            {entry?.state !== 'SOLD' &&
                                entry.listing_currency !==
                                    entry.local_currency && (
                                    <LocalPrice>{price_local}</LocalPrice>
                                )}
                        </Row>
                    </TitleContainer>
                    <Rectangle />
                    <Description>{entry.car.short_description}</Description>
                </ContentContainer>
            ) : null}
            <FactsGridSectionDesktop
                specs={specs}
                openLocationForm={props.openLocationForm}
                openForm={props.openForm}
                isAdmin={isAdmin}
            />
        </Container>
    )
}

export default OverviewSectionDesktop
