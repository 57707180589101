import * as Yup from 'yup'

export const CarYearValidation = (p: {
    min: number
    warning_min: string
    max: number
    warning_max: string
}) =>
    Yup.object().shape({
        year: Yup.number().min(p.min, p.warning_min).max(p.max, p.warning_max),
    })
