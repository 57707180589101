import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneyHandoverIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '56'}
            height={size ?? '56'}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7 45.4077H12.4868C12.4868 45.4077 15.7332 35.4802 16.1447 30.7762C14.6961 29.4045 7 28.9473 7 28.9473V45.4077V45.4077Z"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M36.263 40.8347L43.2697 39.0825C45.5869 38.5028 47.9975 39.6111 49.0656 41.7491L39.0064 46.3215C31.3853 49.4453 30.747 49.5221 27.1184 48.1504L13.4014 43.5781"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M16.1445 32.6055L32.6049 36.2633C35.0466 36.8742 36.2628 38.0301 36.2628 40.5467V40.8357L33.801 41.4502C33.0128 41.6477 32.1916 41.6678 31.396 41.5087L23.4603 39.9212"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.25 16.2826C19.25 17.2322 19.6278 18.1442 20.2995 18.8158L24.4855 23.0018C24.9335 23.4498 25.5409 23.7011 26.1739 23.7011C26.807 23.7011 27.4143 23.4498 27.8624 23.0018L34.3768 16.4874C34.8248 16.0393 35.0761 15.432 35.0761 14.7989C35.0761 14.1659 34.8248 13.5585 34.3768 13.1105L30.1908 8.92447C29.5192 8.25285 28.6072 7.875 27.6576 7.875C26.708 7.875 25.7961 8.25285 25.1244 8.92447L20.2995 13.7494C19.6278 14.4211 19.25 15.333 19.25 16.2826Z"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M23.9062 16.4878L25.8845 14.5095L27.8628 12.5312"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M34.0867 17.2715L35.0758 18.2606L34.0867 19.2497L41.9997 27.1628V30.6247H38.5378L37.5487 29.6356H36.065V28.1519L33.5921 25.6791H32.1084V24.1954L30.6247 22.7117L29.6356 23.7008L28.6465 22.7117"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default JourneyHandoverIcon
