import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

const Title = styled.h1`
    font-family: Lato;
    text-align: center;
    color: var(--text-strong, #1a1a1a);
    font-size: 24px;
    padding-bottom: 15px;
    padding-top: 0px;
    margin: 0;
    line-height: 34px;

    @media ${device.desktop} {
        text-align: left;
        font-size: 18px;
    }

    @media ${device.ipad} {
        font-size: 18px;
        text-align: left;
    }
`

interface IProps {
    children?: React.ReactNode
}

const CreateFormTitle: React.FunctionComponent<IProps> = (props: any) => (
    <Title>{props.children}</Title>
)

export default CreateFormTitle
