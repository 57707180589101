import { IUserHandoversObjByState } from 'myModels'
import styled from 'styled-components'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

import Icon from '../../../atoms/icons'
import { device } from '../../displays/devices'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media ${device.desktop} {
    }

    @media (max-width: 881px) {
        margin-top: 80px;
    }

    @media (max-width: 499px) {
        margin-top: 50px;
    }
    font-family: 'Lato';

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
`

const Banner = styled.div`
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 32px;
    padding-left: 32px;
    gap: 16px;
    width: 100%;
    justify-content: space-between;

    ${device.ipad} {
    }
    @media ${device.desktop} {
    }
    @media ${device.mobile} {
        gap: 5px;
        padding-right: 10px;
        padding-left: 32px;
        padding-bottom: 26px;
    }
`

const Txt = styled.p`
    max-width: 800px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 5e-5em;
    color: #666666;
`
const Title = styled.p`
    color: #1a1a1a;
    /* font-weight: 600; */
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 5e-5em;
    font-family: Lato-Semibold;
`

type Props = {
    handoversObj: IUserHandoversObjByState
}

type Props2 = {
    state: 'FAILED' | 'PROCESSING'
    count: number
}
const HandoverStateBannerItem = (props: Props2) => {
    let dispatch = useAppDispatch()
    return (
        <Banner
            style={{
                background: props.state === 'FAILED' ? '#FCF3F3' : '#F2FAFB',
                marginBottom: props.state === 'PROCESSING' ? '5px' : '0px',
            }}
            className="row"
        >
            <div className="col">
                <div className="row">
                    <Icon
                        icon={
                            props.state === 'FAILED' ? 'info-red' : 'info-blue'
                        }
                    />

                    <div style={{ paddingLeft: '10px' }} />

                    <Title>
                        {props.state === 'FAILED'
                            ? `${
                                  props.count && props.count > 1
                                      ? props.count + ' '
                                      : ''
                              }Handover${
                                  props.count && props.count > 1 ? 's' : ''
                              } failed`
                            : `${
                                  props.count && props.count > 1
                                      ? props.count + ' '
                                      : ''
                              }Handover${
                                  props.count && props.count > 1 ? 's' : ''
                              } in progress`}
                    </Title>
                </div>

                <div style={{ paddingTop: '8px' }} />

                <Txt>
                    {props.state === 'FAILED'
                        ? `We're sorry, but a handover process has failed. Please contact our support team for assistance in resolving this issue.`
                        : `You have received ownership of a new car. Our system is currently processing the transfer of all the necessary information, which may take a few minutes. Please check back soon to see your new car(s) in Garage.`}
                </Txt>
            </div>

            <div
                style={{ padding: '20px', cursor: 'pointer' }}
                onClick={() => {
                    dispatch(
                        customNavDataActions.hideHandoverBanner(props.state)
                    )
                }}
            >
                <Icon icon="grey_cross" />
            </div>
        </Banner>
    )
}

const HandoverStatesBanner = (props: Props) => {
    let { handoversObj } = props

    let isFailedHandoverHidden: boolean | undefined = useAppSelector(
        (state) => {
            return state.localdata.customNavData.hide_handovers_failed
        }
    )

    let isProcessingHandoverHidden: boolean | undefined = useAppSelector(
        (state) => {
            return state.localdata.customNavData.hide_handovers_processing
        }
    )

    return (
        <Wrapper>
            {!isProcessingHandoverHidden &&
                handoversObj.processing &&
                handoversObj.processing.length > 0 && (
                    <HandoverStateBannerItem
                        state="PROCESSING"
                        count={handoversObj.processing.length}
                    />
                )}

            {!isFailedHandoverHidden &&
                handoversObj.failed &&
                handoversObj.failed.length > 0 && (
                    <HandoverStateBannerItem
                        count={handoversObj.failed.length}
                        state="FAILED"
                    />
                )}
        </Wrapper>
    )
}

export default HandoverStatesBanner
