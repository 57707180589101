import JourneyBottomActionBarMobile from '../../../templates/bars/journey/bottomActionBarMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/sellYourCar/2.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

const SectionLeft = () => {
    const { theme } = useThemes()
    return (
        <JourneyColumnContent>
            <JourneyText $theme={theme}>
                Create a public profile of your car which shows it in it's best
                light, allowing for quick easy sharing on marketplaces, forums
                and chat applications.
                <br />
                <br />
                It'll also let you generate a QR code with matching colour-way
                for you tax disk holder or window should want an easy way to
                share your car profile at car meets!
                <br />
                <br />
                Lightening fast loads, no adverts, just your car, curated by
                you.
            </JourneyText>
        </JourneyColumnContent>
    )
}
export default function SharingSellYourCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section[
                                        'sell_your_car'
                                    ].primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[
                                        `sell_your_car`
                                    ].primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        A beautiful public profile for your car
                                        which you control.
                                    </JourneyStepTitle>
                                </JourneyColumnContent>
                                <SectionLeft />
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'sell_your_car'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'sell_your_car'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    src={image}
                                    placeholder={grey_placeholder}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '56vw',
                                        maxHeight: '90vh',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <div
                        style={{
                            paddingLeft: 24,
                            paddingRight: 24,
                            paddingBottom: 200,
                            paddingTop: 48,
                            overflow: 'hidden',
                        }}
                    >
                        <JourneyColumnContent style={{ gap: 40 }}>
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <JourneyStepTitle
                                        $theme={theme}
                                        style={{ paddingRight: '40px' }}
                                    >
                                        Public sharing and putting links in
                                        marketplace listings
                                    </JourneyStepTitle>
                                    <JourneyText
                                        style={{
                                            fontSize: 14,
                                            color: colours[theme].text_muted,
                                        }}
                                        $theme={theme}
                                    >
                                        Car ownership is best enjoyed together
                                    </JourneyText>
                                </JourneyColumnContent>

                                <SectionLeft />

                                <div style={{ alignSelf: 'flex-start' }}>
                                    <FadedSlower>
                                        <img
                                            src={image}
                                            placeholder={grey_placeholder}
                                            style={{
                                                objectFit: 'contain',
                                                maxWidth: '100vw',
                                                maxHeight: '80vh',
                                                alignSelf: 'flex-start',
                                            }}
                                        />
                                    </FadedSlower>
                                </div>
                            </JourneyColumnContent>
                        </JourneyColumnContent>
                    </div>
                    <JourneyBottomActionBarMobile
                        sectionID="sell_your_car"
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={goToPrevStep}
                        onNext={() => {
                            goToNextStep()
                        }}
                    />
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
