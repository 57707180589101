import { motion } from 'framer-motion'
import styled from 'styled-components'

const Fab = styled(motion.div)<StyleProps>`
    opacity: ${(props) => (props.$isOpen ? '0' : '1')};
    transition: all 500ms;
    position: relative;
`

const Round = styled.div`
    background-color: #5ec3ca;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 1;
    /* box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25); */

    @keyframes pulsing {
        to {
            box-shadow: 0 0 0 12px rgba(232, 76, 61, 0);
        }
    }

    box-shadow: 0 0 0 0 #9be1e6;
    -webkit-animation: pulsing 1s infinite cubic-bezier(0.66, 0, 0, 1);
    -moz-animation: pulsing 1s infinite cubic-bezier(0.66, 0, 0, 1);
    -ms-animation: pulsing 1s infinite cubic-bezier(0.66, 0, 0, 1);
    animation: pulsing 1s infinite cubic-bezier(0.66, 0, 0, 1);
`

const PulseSquare = styled.div`
    position: absolute;
    left: -8px;
    top: -8px;
    position: absolute;
    background-color: #9de1e616;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;

    /* animation: pulse 700ms infinite cubic-bezier(0.17, 1.17, 0.63, 1.27); */
`
type Props = {
    isOpen: boolean
    onClick: any
    dataCyId?: string | undefined
}

type StyleProps = {
    $isOpen: boolean
}

const FabIconAdd = ({ isOpen, onClick, dataCyId }: Props) => (
    <Fab
        data-attr={dataCyId ? dataCyId : undefined}
        whileTap={{ scale: 1.1 }}
        $isOpen={isOpen}
        onClick={onClick}
    >
        <PulseSquare />
        <Round
            style={{
                width: '48px',
                height: '48px',
            }}
        >
            <svg
                width="13"
                height="13"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.4976 5.30659L7.07545 5.30659V0.884431C7.07545 0.397994 6.67745 0 6.19102 0C5.70458 0 5.30659 0.397994 5.30659 0.884431V5.30659H0.884431C0.397994 5.30659 0 5.70458 0 6.19102C0 6.67745 0.397994 7.07545 0.884431 7.07545H5.30659L5.30659 11.4976C5.30659 11.984 5.70458 12.382 6.19102 12.382C6.67745 12.382 7.07545 11.984 7.07545 11.4976L7.07545 7.07545L11.4976 7.07545C11.984 7.07545 12.382 6.67745 12.382 6.19102C12.382 5.70458 11.984 5.30659 11.4976 5.30659Z"
                    fill="white"
                />
            </svg>
        </Round>
    </Fab>
)

export default FabIconAdd
