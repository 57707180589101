import { ICar, ICarsObject } from 'entityModels'
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { getCarDataByIdRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../../redux/entities/cars/actions/thunks'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import {
    useAppDispatch,
    useAppSelector,
    useThunkAppDispatch,
} from '../../../../redux/store/hooks'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'

import ReadOnlyCar from '../../../templates/readOnly/car/readOnlyCar'

type CarParams = {
    carid: string
}

const CarOverviewArchive = () => {
    const { carid } = useParams<CarParams>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const carsData: ICarsObject = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let current: ICar | undefined = carsData[carid]

    const dispatch = useAppDispatch()
    const dispatchThunk = useThunkAppDispatch()

    useEffect(() => {
        // if (!current) {
        // for now always get on render ( we'll add the has_limited_garage_info check tho)
        dispatch(
            customNavDataActions.setInitialLandingUrl(
                `/archives/car/${carid}/overview`
            )
        )
        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        }

        if (userLoggedIn) {
            dispatchThunk(setCurrentCar(carid))
            dispatch(getCarDataByIdRequest(carid))
        }

        // }
    }, [userLoggedIn])

    return (
        <React.Fragment>
            {current && userLoggedIn && (
                <ReadOnlyCar prevOwned={current} userLoggedIn={userLoggedIn} />
            )}
        </React.Fragment>
    )
}

export default CarOverviewArchive
