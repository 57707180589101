import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const ShowroomIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6345 20.1814C17.2444 20.1814 16.9281 20.4976 16.9281 20.8877C16.9281 21.2778 17.2444 21.5941 17.6345 21.5941C18.0246 21.5941 18.3408 21.2778 18.3408 20.8877C18.3408 20.4976 18.0246 20.1814 17.6345 20.1814ZM15.4281 20.8877C15.4281 19.6692 16.4159 18.6814 17.6345 18.6814C18.853 18.6814 19.8408 19.6692 19.8408 20.8877C19.8408 22.1063 18.853 23.0941 17.6345 23.0941C16.4159 23.0941 15.4281 22.1063 15.4281 20.8877Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.79189 20.1814C6.40178 20.1814 6.08554 20.4976 6.08554 20.8877C6.08554 21.2778 6.40178 21.5941 6.79189 21.5941C7.18199 21.5941 7.49823 21.2778 7.49823 20.8877C7.49823 20.4976 7.18199 20.1814 6.79189 20.1814ZM4.58554 20.8877C4.58554 19.6692 5.57335 18.6814 6.79189 18.6814C8.01042 18.6814 8.99823 19.6692 8.99823 20.8877C8.99823 22.1063 8.01042 23.0941 6.79189 23.0941C5.57335 23.0941 4.58554 22.1063 4.58554 20.8877Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.1244 14.4316H12.139C13.0256 14.4316 13.6619 14.9653 13.9319 15.1917C13.9607 15.2159 13.9854 15.2366 14.0057 15.2527C14.3051 15.4905 14.661 15.842 14.9119 16.0898C14.9752 16.1525 15.0319 16.2084 15.0793 16.2543C15.0839 16.2559 15.0942 16.2588 15.1112 16.2598L20.0345 16.5718L20.0447 16.5727C19.9658 16.5655 20.3306 16.5656 20.6166 16.6803C20.9776 16.8252 21.2881 17.1307 21.4212 17.6187L21.5616 17.9795L22.1892 18.4398L22.2298 18.4803C22.3473 18.5979 22.5161 18.7995 22.6278 19.0413C22.7142 19.2284 22.888 19.727 22.5516 20.1659L22.2488 20.6815C21.9345 21.2165 21.3605 21.5452 20.7399 21.5452H19.5612V20.0452H20.7399C20.8285 20.0452 20.9105 19.9983 20.9554 19.9218L21.1716 19.5537L20.322 18.9306L19.9884 18.0728L19.988 18.0711L19.9743 18.0706C19.9632 18.0702 19.9444 18.0694 19.9225 18.0677L15.0164 17.7568C14.6819 17.7357 14.3257 17.612 14.042 17.3378C13.9692 17.2675 13.8924 17.1917 13.8135 17.1139C13.5675 16.8714 13.3012 16.6087 13.0729 16.4274C13.0408 16.4019 13.0097 16.377 12.9795 16.3528C12.6747 16.1085 12.4598 15.9362 12.1514 15.9317C10.4653 15.9986 9.08225 16.2117 7.69641 16.7068C6.36353 17.183 4.99396 17.9322 3.33027 19.1134C3.32966 19.1622 3.32721 19.2179 3.32069 19.2714C3.31248 19.3389 3.29399 19.4443 3.24282 19.5584C3.18852 19.6796 3.09042 19.8249 2.92171 19.932C2.90399 19.9432 2.88648 19.9533 2.86935 19.9624C2.93192 19.9948 3.022 20.0275 3.14626 20.0452H5.03124V21.5452H3.05776L3.01622 21.5405C2.1291 21.4413 1.46212 20.9119 1.31292 20.2839C1.29326 20.2011 1.26605 20.0356 1.25507 19.8616C1.2494 19.7716 1.2468 19.6613 1.2563 19.5473C1.2648 19.4453 1.28726 19.279 1.36786 19.1127C1.47715 18.8872 1.65339 18.7349 1.84364 18.6489C1.84699 18.6474 1.85034 18.6459 1.85368 18.6444C1.88421 18.4967 1.96768 18.2457 2.21826 18.0648C4.05825 16.736 5.62021 15.8557 7.19176 15.2943C8.76766 14.7313 10.3158 14.5022 12.1098 14.4322L12.1244 14.4316ZM15.7899 21.5452H8.34827V20.0452H15.7899V21.5452Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.3825 5.13794C18.7967 5.13794 19.1325 5.47373 19.1325 5.88794C19.1325 7.49761 19.3358 8.18065 19.7128 8.55761C20.0898 8.93457 20.7728 9.13794 22.3825 9.13794C22.7967 9.13794 23.1325 9.47373 23.1325 9.88794C23.1325 10.3022 22.7967 10.6379 22.3825 10.6379C20.7728 10.6379 20.0898 10.8413 19.7128 11.2183C19.3358 11.5952 19.1325 12.2783 19.1325 13.8879C19.1325 14.3022 18.7967 14.6379 18.3825 14.6379C17.9682 14.6379 17.6325 14.3022 17.6325 13.8879C17.6325 12.2783 17.4291 11.5952 17.0521 11.2183C16.6752 10.8413 15.9921 10.6379 14.3825 10.6379C13.9682 10.6379 13.6325 10.3022 13.6325 9.88794C13.6325 9.47373 13.9682 9.13794 14.3825 9.13794C15.9921 9.13794 16.6752 8.93457 17.0521 8.55761C17.4291 8.18065 17.6325 7.49761 17.6325 5.88794C17.6325 5.47373 17.9682 5.13794 18.3825 5.13794ZM18.3825 9.30484C18.3013 9.41488 18.2116 9.51941 18.1128 9.61827C18.0139 9.71712 17.9094 9.80674 17.7994 9.88794C17.9094 9.96914 18.0139 10.0588 18.1128 10.1576C18.2116 10.2565 18.3013 10.361 18.3825 10.471C18.4637 10.361 18.5533 10.2565 18.6521 10.1576C18.751 10.0588 18.8555 9.96914 18.9656 9.88794C18.8555 9.80674 18.751 9.71712 18.6521 9.61827C18.5533 9.51941 18.4637 9.41488 18.3825 9.30484Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.5 5.73035C4.91421 5.73035 5.25 6.06613 5.25 6.48035C5.25 7.49001 5.38467 7.80436 5.53033 7.95002C5.67599 8.09568 5.99033 8.23035 7 8.23035C7.41421 8.23035 7.75 8.56613 7.75 8.98035C7.75 9.39456 7.41421 9.73035 7 9.73035C5.99033 9.73035 5.67599 9.86502 5.53033 10.0107C5.38467 10.1563 5.25 10.4707 5.25 11.4803C5.25 11.8946 4.91421 12.2303 4.5 12.2303C4.08579 12.2303 3.75 11.8946 3.75 11.4803C3.75 10.4707 3.61533 10.1563 3.46967 10.0107C3.32401 9.86502 3.00967 9.73035 2 9.73035C1.58579 9.73035 1.25 9.39456 1.25 8.98035C1.25 8.56613 1.58579 8.23035 2 8.23035C3.00967 8.23035 3.32401 8.09568 3.46967 7.95002C3.61533 7.80436 3.75 7.49001 3.75 6.48035C3.75 6.06613 4.08579 5.73035 4.5 5.73035Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.405 1.64795C11.8193 1.64795 12.155 1.98374 12.155 2.39795C12.155 2.67885 12.1722 2.85289 12.1984 2.96461C12.2106 3.01649 12.2224 3.04416 12.2288 3.05672C12.2319 3.06282 12.234 3.06579 12.2346 3.06667L12.2354 3.06762L12.2363 3.06837C12.2372 3.06901 12.2402 3.07105 12.2463 3.07417C12.2588 3.08057 12.2865 3.09239 12.3384 3.10458C12.4501 3.13084 12.6241 3.14795 12.905 3.14795C13.3193 3.14795 13.655 3.48374 13.655 3.89795C13.655 4.31216 13.3193 4.64795 12.905 4.64795C12.6241 4.64795 12.4501 4.66506 12.3384 4.69132C12.2865 4.70351 12.2588 4.71533 12.2463 4.72173C12.2402 4.72484 12.2372 4.72689 12.2363 4.72752L12.2354 4.72828L12.2346 4.72923C12.234 4.73011 12.2319 4.73307 12.2288 4.73918C12.2224 4.75174 12.2106 4.77941 12.1984 4.83129C12.1722 4.94301 12.155 5.11705 12.155 5.39795C12.155 5.81216 11.8193 6.14795 11.405 6.14795C10.9908 6.14795 10.655 5.81216 10.655 5.39795C10.655 5.11705 10.6379 4.94301 10.6117 4.83129C10.5995 4.77941 10.5877 4.75174 10.5813 4.73918C10.5781 4.73307 10.5761 4.73011 10.5755 4.72923L10.5747 4.72828L10.5738 4.72752C10.5729 4.72689 10.5699 4.72484 10.5638 4.72173C10.5513 4.71533 10.5236 4.70351 10.4717 4.69132C10.36 4.66506 10.1859 4.64795 9.90504 4.64795C9.49083 4.64795 9.15504 4.31216 9.15504 3.89795C9.15504 3.48374 9.49083 3.14795 9.90504 3.14795C10.1859 3.14795 10.36 3.13084 10.4717 3.10458C10.5236 3.09239 10.5513 3.08057 10.5638 3.07417C10.5699 3.07105 10.5729 3.06901 10.5738 3.06837L10.5747 3.06762L10.5755 3.06667C10.5761 3.06579 10.5781 3.06282 10.5813 3.05672C10.5877 3.04416 10.5995 3.01649 10.6117 2.96461C10.6379 2.85289 10.655 2.67885 10.655 2.39795C10.655 1.98374 10.9908 1.64795 11.405 1.64795Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default ShowroomIcon
