// create store for todos (basically reducer for the main final store)

import { ISearchUsersState } from 'entityModels'

import * as actions from './actions/successActions'

import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type Action = ActionType<typeof actions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: ISearchUsersState = {
    list: undefined,
    loading: false,
    error: null,
}

export const searchUsersData = (
    state: ISearchUsersState = initialState,
    action: LoadingActions | Action | ErrorActions
): ISearchUsersState => {
    switch (action.type) {
        // REQUESTS
        case loadingActions.searchPlatformUsersRequest.type:
            return Object.assign({}, state, {
                ...state,

                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.searchPlatformUsersError.type:
            return Object.assign({}, state, {
                ...state,

                loading: false,
                error: action.payload,
            })

        // SUCCESS
        case actions.searchPlatformUsersSuccess.type:
            return Object.assign({}, state, {
                ...state,

                list: action.payload,
                loading: false,
                error: null,
            })

        case loadingActions.checkUserEmailExistsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // ERRORS
        case errorActions.checkUserEmailExistsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SUCCESS
        case actions.checkUserEmailExistsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                list: action.payload,
                loading: false,
                error: null,
            })

        case actions.cleanUpSearchUsersSuccess.type:
            return Object.assign({}, state, {
                list: undefined,
                loading: false,
                error: null,
            })

        default:
            return state
    }
}

export default searchUsersData

export type SearchUsersState = ReturnType<typeof searchUsersData>
