type Props = {
    height?: string
    width?: string
}

const ShareYourCarCardPattern = ({ height, width }: Props) => {
    return (
        <svg
            width={width ?? '294'}
            height={height ?? '200'}
            viewBox="0 0 294 200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.16"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M314.284 -164L19.7819 -24.5406L0.699293 -15.5041L19.7807 -6.46535L127.791 44.6986L19.8666 94.575L0.175076 103.675L19.8839 112.738L127.45 162.2L19.8839 211.663L0 220.806L19.9275 229.854L314.431 363.567L322.7 345.356L48.1233 220.691L155.564 171.286L175.322 162.2L155.564 153.115L47.9483 103.63L155.581 53.888L174.982 44.9223L155.667 35.7731L47.4241 -15.5012L322.843 -145.924L314.284 -164ZM388.788 -163.999L94.2858 -24.5396L75.2032 -15.5032L94.2846 -6.46437L202.295 44.6996L94.3705 94.576L74.679 103.676L94.3878 112.739L201.954 162.201L94.3878 211.664L74.5039 220.807L94.4314 229.855L388.935 363.568L397.204 345.357L122.627 220.692L230.068 171.287L249.826 162.201L230.068 153.116L122.452 103.631L230.085 53.8889L249.486 44.9232L230.171 35.7741L121.928 -15.5003L397.347 -145.923L388.788 -163.999ZM168.79 -24.5396L463.292 -163.999L471.851 -145.923L196.432 -15.5003L304.675 35.7741L323.99 44.9232L304.589 53.8889L196.956 103.631L304.572 153.116L324.33 162.201L304.572 171.287L197.131 220.692L471.708 345.357L463.439 363.568L168.935 229.855L149.008 220.807L168.892 211.664L276.458 162.201L168.892 112.739L149.183 103.676L168.874 94.576L276.799 44.6996L168.789 -6.46437L149.707 -15.5032L168.79 -24.5396ZM535.292 -163.999L240.79 -24.5396L221.707 -15.5032L240.789 -6.46437L348.799 44.6996L240.874 94.576L221.183 103.676L240.892 112.739L348.458 162.201L240.892 211.664L221.008 220.807L240.935 229.855L535.439 363.568L543.708 345.357L269.131 220.692L376.572 171.287L396.33 162.201L376.572 153.116L268.956 103.631L376.589 53.8889L395.99 44.9232L376.675 35.7741L268.432 -15.5003L543.851 -145.923L535.292 -163.999Z"
                fill="url(#paint0_linear_3924_23100)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3924_23100"
                    x1="300.437"
                    y1="-24.5"
                    x2="-50.4648"
                    y2="98.5918"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default ShareYourCarCardPattern
