import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const EventsIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ? `${Number(size) * 0.75}` : '12'}
            viewBox="0 0 16 12"
            fill="none"
        >
            <path
                d="M10.099 1.80859L11.8667 3.57636L10.4525 4.99057L8.68475 3.22281L10.099 1.80859Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                d="M13.6346 5.34412L15.4023 7.11188L13.9881 8.5261L12.2203 6.75833L13.6346 5.34412Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                d="M10.4525 4.9906L12.2203 6.75837L10.8061 8.17258L9.03833 6.40481L10.4525 4.9906Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                d="M7.27054 4.63708L9.03831 6.40485L7.62409 7.81907L5.85632 6.0513L7.27054 4.63708Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                d="M10.8061 8.17261L12.5738 9.94037L11.1596 11.3546L9.39185 9.58682L10.8061 8.17261Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.938 1.06066L1.65838 10.3403L0.597717 9.2796L9.87732 0L10.938 1.06066Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default EventsIcon
