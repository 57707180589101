import * as errorActions from '../actions/errorActions'
import * as requestActions from '../actions/requestActions'
import * as successActions from '../actions/successActions'

import { ThunkAction } from 'redux-thunk'
import { RootAction } from 'typesafe-actions'
import {
    IIAllApex_carOfTheWeeks_api,
    IApexCarOfTheWeekState,
    IApexHomepageContentQueryAPI,
    IApexHomepageDataState,
    IAllApex_podcastss_api,
    IApexPodscastState,
    IApexWeeklyNewsHighlightState,
    ITaskNormalisedSingleInterviewItemState,
    IApexSingleInterviewPayloadFromQueryAPI,
    IApexSingleInterviewItemState,
    IApexSingleArticlePayloadFromQueryAPI,
    IApexSingleArticleItemState,
    ITaskNormalisedSingleArticleItemState,
    IApexArticleOverviewItemState,
    IAllApex_articless_overviews_api,
} from 'apexModels'

import { api } from '../../services'
import { ConvertPrismicApexHomepageDataFromAPItoLocalState } from '../../conversions/apex/homepage'
import { ConvertPrismicApexCarOfTheWeekFromAPIToArray } from '../../conversions/apex/carsOfTheWeek'
import { convertPrismicApexPodcastFromAPIToArray } from '../../conversions/apex/apexPodcast'
import { IAllApexWeekly_highlightss_api } from 'apexModels'
import { convertPrismicWeeklyHighlightsFromAPIToArray } from '../../conversions/apex/weeklyHighlights'
import { convertPrismicApexSingleInterviewFromAPIToState } from '../../conversions/apex/apexSingleInterview'
import { convertPrismicApexSingleArticleFromAPIToState } from '../../conversions/apex/apexSingleArticle'
import { convertPrismicArticleOverviewsFromAPI } from '../../conversions/apex/apexArticleOverviews'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { ICustomErrorData, IReduxError } from 'entityModels'

export const getApexHomepageDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(requestActions.getApexHomepageContentRequest())

            try {
                api.apexQuery.apexHomepageQuery
                    .queryApexHomepageContent()
                    .then((apexData: any) => {
                        if (apexData.data) {
                            let apiRes: IApexHomepageContentQueryAPI =
                                apexData.data

                            if (apiRes) {
                                let res: IApexHomepageDataState =
                                    ConvertPrismicApexHomepageDataFromAPItoLocalState(
                                        apiRes
                                    )

                                dispatch(
                                    successActions.getApexHomepageContentSuccess(
                                        res
                                    )
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.getApexHomepageContentError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)

                            dispatch(
                                errorActions.getApexHomepageContentError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.getApexHomepageContentError(typedError))
            }
        })
    }
}

export const getApexCarsOfTheWeekDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(requestActions.getApexCarsOfTheWeekRequest())

            try {
                api.apexQuery.apexCarsoftheWeekQuery
                    .apexCarsoftheWeekQuery()
                    .then((apexData: any) => {
                        // apexData.data is of type IApexCarsOfTheWeekQueryAPI

                        if (apexData.data !== undefined) {
                            let apiRes: IIAllApex_carOfTheWeeks_api =
                                apexData.data.allApex_car_of_the_weeks

                            if (apiRes) {
                                let res: IApexCarOfTheWeekState[] =
                                    ConvertPrismicApexCarOfTheWeekFromAPIToArray(
                                        apiRes
                                    )

                                dispatch(
                                    successActions.getApexCarsOfTheWeekSuccess(
                                        res
                                    )
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.getApexCarsOfTheWeekError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                errorActions.getApexCarsOfTheWeekError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                console.error(error)
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.getApexCarsOfTheWeekError(typedError))
            }
        })
    }
}

export const getApexPodcastsDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(requestActions.getApexPodcastsRequest())

            try {
                api.apexQuery.apexPodcastQuery
                    .apexAllpodcastsQuery()
                    .then((apexData: any) => {
                        // apexData.data is of type IApexPodcastsQueryAPI

                        if (apexData.data !== undefined) {
                            let apiRes: IAllApex_podcastss_api =
                                apexData.data.allApex_podcastss

                            if (apiRes) {
                                let res: IApexPodscastState[] =
                                    convertPrismicApexPodcastFromAPIToArray(
                                        apiRes
                                    )

                                dispatch(
                                    successActions.getApexPodcastsSuccess(res)
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.getApexPodcastsError(customError)
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                errorActions.getApexPodcastsError(typedError)
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.getApexPodcastsError(typedError))
            }
        })
    }
}

export const getWeeklyHighlightsDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(requestActions.getWeeklyHighlightsRequest())

            try {
                api.apexQuery.weeklyHighlightsQuery
                    .allApexWeeklyhighlightsQuery()
                    .then((apexData: any) => {
                        if (apexData.data !== undefined) {
                            let apiRes: IAllApexWeekly_highlightss_api =
                                apexData.data.allApex_weekly_highlightss

                            if (apiRes) {
                                let res: IApexWeeklyNewsHighlightState[] =
                                    convertPrismicWeeklyHighlightsFromAPIToArray(
                                        apiRes
                                    )

                                dispatch(
                                    successActions.getWeeklyHighlightsSuccess(
                                        res
                                    )
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.getWeeklyHighlightsError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                errorActions.getWeeklyHighlightsError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.getWeeklyHighlightsError(typedError))
            }
        })
    }
}

export const getSingleApexInterviewByUIDDataThunks = (
    uid: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(requestActions.getApexSingleInterviewByUIDRequest())

            try {
                api.apexQuery.apexSingleInterviewQuery
                    .apexSingleInterviewQuery(uid)
                    .then((apexData: any) => {
                        if (apexData.data) {
                            let apiRes: IApexSingleInterviewPayloadFromQueryAPI =
                                apexData.data

                            if (apiRes.allApex_interviewss) {
                                let converted_interview:
                                    | IApexSingleInterviewItemState
                                    | undefined =
                                    convertPrismicApexSingleInterviewFromAPIToState(
                                        apiRes
                                    )

                                if (converted_interview !== undefined) {
                                    let normalised_interview: ITaskNormalisedSingleInterviewItemState =
                                        {}

                                    normalised_interview[uid] =
                                        converted_interview

                                    dispatch(
                                        successActions.getApexSingleInterviewByUIDSuccess(
                                            normalised_interview
                                        )
                                    )
                                } else
                                    dispatch(
                                        errorActions.getApexSingleInterviewByUIDError(
                                            {
                                                default:
                                                    'error, no interview match this UID',
                                            }
                                        )
                                    )
                            } else
                                dispatch(
                                    errorActions.getApexSingleInterviewByUIDError(
                                        {
                                            default:
                                                'error, no interview match this UID',
                                        }
                                    )
                                )
                        } else
                            dispatch(
                                errorActions.getApexSingleInterviewByUIDError({
                                    default:
                                        'error, no interview match this UID',
                                })
                            )
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.getApexSingleInterviewByUIDError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                errorActions.getApexSingleInterviewByUIDError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(
                    errorActions.getApexSingleInterviewByUIDError(typedError)
                )
            }
        })
    }
}

export const getSingleApexArticleByUIDDataThunks = (
    uid: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(requestActions.getApexSingleArticleByUIDRequest())

            try {
                api.apexQuery.apexSingleArticleQuery
                    .apexSingleArticleQuery(uid)
                    .then((apexData: any) => {
                        if (apexData.data) {
                            let apiRes: IApexSingleArticlePayloadFromQueryAPI =
                                apexData.data

                            if (apiRes.allApex_articless) {
                                let converted_article:
                                    | IApexSingleArticleItemState
                                    | undefined =
                                    convertPrismicApexSingleArticleFromAPIToState(
                                        apiRes
                                    )

                                if (converted_article !== undefined) {
                                    let normalised_article: ITaskNormalisedSingleArticleItemState =
                                        {}

                                    normalised_article[uid] = converted_article

                                    dispatch(
                                        successActions.getApexSingleArticleyUIDSuccess(
                                            normalised_article
                                        )
                                    )
                                } else
                                    dispatch(
                                        errorActions.getApexSingleArticleByUIDError(
                                            {
                                                default:
                                                    'error, no article match this UID',
                                            }
                                        )
                                    )
                            } else
                                dispatch(
                                    errorActions.getApexSingleArticleByUIDError(
                                        {
                                            default:
                                                'error, no article match this UID',
                                        }
                                    )
                                )
                        } else
                            dispatch(
                                errorActions.getApexSingleArticleByUIDError({
                                    default: 'error, no article match this UID',
                                })
                            )
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.getApexSingleArticleByUIDError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                errorActions.getApexSingleArticleByUIDError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error: any) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(
                    errorActions.getApexSingleArticleByUIDError(typedError)
                )
            }
        })
    }
}

export const getApexArticleOverviewsDataThunks = (): ThunkAction<
    Promise<void>,
    {},
    {},
    RootAction
> => {
    return async (
        dispatch: any
        // getState: () => IGetState
    ): Promise<void> => {
        return new Promise<void>(() => {
            dispatch(requestActions.getApexArticleOverviewsRequest())

            try {
                api.apexQuery.apexArticleOverviewsQuery
                    .apexArticleOverviewsQuery()
                    .then((apexData: any) => {
                        if (apexData.data !== undefined) {
                            let apiRes: IAllApex_articless_overviews_api =
                                apexData.data.allApex_articless

                            if (apiRes) {
                                let res: IApexArticleOverviewItemState[] =
                                    convertPrismicArticleOverviewsFromAPI(
                                        apiRes
                                    )

                                dispatch(
                                    successActions.getApexArticleOverviewsSuccess(
                                        res
                                    )
                                )
                            }
                        }
                    })
                    .catch((error: any) => {
                        if (error === Error) {
                            let customErrorData: ICustomErrorData = {
                                custom_message: `Looks like your internet has stoped working`,
                                custom_message_detail:
                                    'Check your connection and refresh',
                                custom_user_action_text: 'Refresh page',
                            }

                            let customError: IReduxError = ConvertToReduxError(
                                { status: '500' },
                                customErrorData
                            )
                            dispatch(
                                errorActions.getWeeklyHighlightsError(
                                    customError
                                )
                            )
                        } else {
                            let typedError: IReduxError =
                                ConvertToReduxError(error)
                            dispatch(
                                errorActions.getWeeklyHighlightsError(
                                    typedError
                                )
                            )
                        }
                    })
            } catch (error) {
                let typedError: IReduxError = ConvertToReduxError(error)
                dispatch(errorActions.getWeeklyHighlightsError(typedError))
            }
        })
    }
}
