import colours, { ITheme } from '../../../providers/theme/colours'

type Props = {
    isActive: boolean
    height?: string
    width?: string
    theme: ITheme
}

const BannerViewIcon = ({ isActive, theme, width, height }: Props) => {
    return (
        <svg
            width={`${width ?? '24'}`}
            height={`${height ?? '24'}`}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 11.25H20V12.75H4V11.25Z"
                fill={`${
                    isActive
                        ? colours[theme].primary
                        : colours[theme].text_muted
                }`}
            />
            <path
                d="M4 5.75H20V7.25H4V5.75Z"
                fill={`${
                    isActive
                        ? colours[theme].primary
                        : colours[theme].text_muted
                }`}
            />
            <path
                d="M4 16.75H20V18.25H4V16.75Z"
                fill={`${
                    isActive
                        ? colours[theme].primary
                        : colours[theme].text_muted
                }`}
            />
        </svg>
    )
}

export default BannerViewIcon
