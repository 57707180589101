import { Tooltip } from 'antd'

import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'

type Props = {
    copy: string
    icon: string
    onClick: any
    iconHeight: string
    iconWidth: string
    size?: string
    customColor?: string
    isDisabled?: boolean
}

const BtnShareIcon = styled.button`
    height: 36px;
    width: 39px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid var(--primary_20);
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 0.1s;
    cursor: pointer;

    :focus {
        outline: 0;
    }
    :hover {
        transform: scale(1.05);
    }
`

const TooltipRowBtn: React.FunctionComponent<Props> = (props: Props) => {
    let {
        copy,
        icon,
        iconHeight,
        iconWidth,
        onClick,
        customColor,
        isDisabled,
    } = props
    const [isTooltipOn, setIsTooltipOn] = React.useState(false)

    return (
        <BtnShareIcon
            onMouseEnter={() => setIsTooltipOn(true)}
            onMouseLeave={(e: any) => {
                e.preventDefault()
                setIsTooltipOn(false)
            }}
            onClick={isDisabled ? () => {} : onClick}
            style={isDisabled ? { opacity: '30%' } : {}}
        >
            {!isDisabled && (
                <Tooltip
                    title={copy}
                    open={isTooltipOn}
                    placement="top"
                    color={customColor ? customColor : '#5EC3CA'}
                    overlayStyle={{ fontFamily: 'Lato' }}
                />
            )}
            <Icon height={iconHeight} width={iconWidth} icon={icon} />
        </BtnShareIcon>
    )
}

export default TooltipRowBtn
