import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import { menuActions } from '../../../redux/menus/reducer'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'

import TopHeaderFilterNavMobile from '../../organisms/smartHeadres/topHeaderFilterNav/topHeaderFilterNavMobile'
import FilterBottomSheetMenuMobile from '../../atoms/menu/bottomSheetMenu/filterMenuTimelineMobile'

import TimelineViewManagerMobile from '../../organisms/timelineViewManager/timelineViewManagerMobile'
import {
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    ITimelineCreationFormFieldsData,
    ITimelineDataByCarNormalised,
    ITimelineDataToBeSubmittedObj,
    ITimelineEntryTypes,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'
import { INormalisedAttachmentsByID } from 'attachmentModels'
import styled from 'styled-components'
import { ITimelineCreateForms, IUser } from 'myModels'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { TimelineCreationTypeIdsEnum } from '../../../redux/timeline/timelineEnum'
import TimelineViewManagerDesktop from '../../organisms/timelineViewManager/timelineViewManagerDesktop'
import { sampleData } from '../../../redux/timeline/data'
import {
    ICar,
    ICarsObject,
    IDropdownItem,
    IExternalCar,
    IExternalCarsObject,
} from 'entityModels'
import HistoryFilePageManagerHeaderDesktop from '../../molecules/carTimelineHeader/carTimelineHeaderDesktop'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import WrapperCarBannerImageFormDesktop from '../../organisms/editForms/imageFormWrapper/wrapperCarBannerImageFormDesktop'
import Loader from '../../atoms/loader/loader'
import { device } from '../../templates/displays/devices'
import NoFilterResults from '../../atoms/noFilterResults/noFilterResults'
import NoDataRectangle from '../../atoms/noData/noDataRectangle'
import { clearDataToBeSubmittedSuccess } from '../../../redux/timeline/actions/successActions'
import { addTimelineItemToCarRequest } from '../../../redux/timeline/actions/requestActions'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

interface Props extends RouteComponentProps<any> {
    // external passed props
    // we're passing props for getting / filtering entries
    // since we'll have different endpoints to call depending if own car or external car
    carid: string
    currentOwnedCar?: ICar
    prevOwned?: ICar
    sharedCar?: IExternalCar | undefined
    carsData: ICarsObject | IExternalCarsObject | undefined
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    setFilterTimelineItemsByCarIdCategoryIdsNLabelIds?: (
        payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
    ) => {}
    readOnlyMode?: boolean

    //mapped props through redux
    location: any
    userLoggedIn: IUser | null
    toggleCarBannerImageForm: any
    isCarBannerImageFormOpen: boolean
    attachmentsObj: INormalisedAttachmentsByID
    timeline_creation_steps_data: ITimelineCreationFormFieldsData
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
    timeline_creation_steps_object_of_indexed_list: ITimelineStepsIndexedListByCreationType
    data_to_be_submitted: ITimelineDataToBeSubmittedObj
    addTimelineItemToCarRequest: any
    category_searchable_items_list: IDropdownItem[] | null | undefined
    userTimelineCustomTags: IDropdownItem[] | null | undefined
    labels_searchable_list: IDropdownItem[]
    clearDataToBeSubmitted: any
    isTimelineDataLoading: boolean
    isUserLoading: boolean
    userCurrency: string | undefined
    toggleFilterTimeline: any
    isFilterBottomSheetMenuOpen: boolean
}

// organism still makes api calls but only for entries creation/editing/deletion
// since all these 3 operations would be made always to user's own entries

const dispatchProps = {
    toggleFilterTimeline: (isOpen: boolean) =>
        menuActions.toggleFilterBottomSheetMenu(isOpen),
    toggleCarBannerImageForm: () => editFormsActions.toggleCarBannerImageForm(),
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
    addTimelineItemToCarRequest: (payload: {
        carid: string
        item: ITimelineItem
    }) => addTimelineItemToCarRequest(payload),
    clearDataToBeSubmitted: (payload: boolean) =>
        clearDataToBeSubmittedSuccess(payload),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isCarBannerImageFormOpen:
            state.editForms.formsData.carBannerImageForm.isOpen,
        attachmentsObj: state.attachments.attachmentsById,
        // costsObj: state.timeline.cost_items_normalised_by_cost_id,
        timeline_creation_steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        timeline_creation_steps_object_of_indexed_list:
            state.timeline.formsData.creation
                .timeline_creation_steps_object_of_indexed_list,
        data_to_be_submitted:
            state.timeline.formsData.creation.data_to_be_submitted,
        userTimelineCustomTags:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
        labels_searchable_list: state.timeline.labels.list,
        category_searchable_items_list:
            state.timeline.categories.category_searchable_items_list,
        isTimelineDataLoading: state.timeline.loading,
        isUserLoading: state.user.loading,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
        isFilterBottomSheetMenuOpen:
            state.menus.mobileTimelineFilterBottomSheetMenu.isOpen,
    }
}

export enum TimelineViewEnum {
    small = 'small',
    fat = 'fat',
}

const PageColumnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`

type IStyle = {
    show: boolean
}

const SmallPadding = styled.div<IStyle>`
    padding-top: ${(props) => (props.show ? '45px' : '0px')};
`

type State = {
    placeholderUrl: string
    activeView: TimelineViewEnum
    isSearching?: boolean
}

//    categoryIcon: iconMaintenance,
//    categoryIcon: iconTrip

const WrapperHeaderStyle = styled.div`
    width: 80vw;
    max-width: 1500px;
    padding-bottom: 20px;

    @media ${device.beyond_ipad_mobile} {
        width: 78vw;
    }
    @media ${device.smallest_laptops} {
        width: 81vw;
    }
`

export const redirectToFilteredPath = (
    currentPath: string | undefined,
    categories: string[] | undefined,
    labels: string[] | undefined,
    scrolltoentryid?: string
): string | undefined => {
    let newPath = currentPath

    if (!categories || (categories && categories.length === 0)) {
        labels &&
            labels.forEach((label: string, i: number) => {
                if (i === 0) {
                    newPath = newPath + `?label=${label}`
                } else {
                    newPath = newPath + `&label=${label}`
                }
            })
    } else {
        categories.forEach((category: string, i: number) => {
            if (i === 0) {
                newPath = newPath + `?category=${category}`
            } else {
                newPath = newPath + `&category=${category}`
            }
        })

        labels &&
            labels.length > 0 &&
            labels.forEach((label: string, i: number) => {
                newPath = newPath + `&label=${label}`
            })
    }

    if (scrolltoentryid) {
        newPath = newPath?.includes('?')
            ? newPath + `&scrolltoid=${scrolltoentryid}`
            : newPath + `?scrolltoid=${scrolltoentryid}`
    }

    return newPath
}

class HistoryFilePageManager extends React.Component<Props, State> {
    state = {
        activeView: TimelineViewEnum.small,
        placeholderUrl: '',
        isSearching: false,
    }

    static contextType = ThemesContext

    changeView = () => {
        return this.setState({
            activeView:
                this.state.activeView === 'small'
                    ? TimelineViewEnum.fat
                    : TimelineViewEnum.small,
        })
    }

    componentDidMount() {
        const { theme } = this.context
        let placeholderUrl = randomCarSvgPlaceholderFunc(undefined, theme)
        this.setState({ placeholderUrl })
        // window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps: Props) {}

    componentWillUnmount() {
        this.props.manageTimelineCreationFormState({
            isOpen: false,
            current_step_index: 0,
            active_creation: null,
        })
    }

    updateIsSearching = (isSearching: boolean) => {
        this.setState({ ...this.state, isSearching: isSearching })
    }

    render() {
        let {
            carid,
            currentOwnedCar,
            sharedCar,
            prevOwned,
            carsTimelineData,
            attachmentsObj,
            manageTimelineCreationFormState,
            category_searchable_items_list,
            userTimelineCustomTags,
            toggleCarBannerImageForm,
            isCarBannerImageFormOpen,
            isUserLoading,
            isTimelineDataLoading,
            userCurrency,
            toggleFilterTimeline,
            isFilterBottomSheetMenuOpen,
            readOnlyMode,
        } = this.props

        let carsDataSearch =
            this.props.carsTimelineData &&
            this.props.carsTimelineData[carid] &&
            this.props.carsTimelineData[carid].search &&
            this.props.carsTimelineData[carid].search

        let activeCategories: ITimelineEntryTypes[] =
            carsDataSearch && carsDataSearch.activeCategoryIds
                ? carsDataSearch.activeCategoryIds
                : []

        let activeLabels: string[] =
            carsDataSearch && carsDataSearch.activeCustomLabelsIds
                ? carsDataSearch.activeCustomLabelsIds
                : []

        let activeEntryTitleQuery: string =
            carsDataSearch && carsDataSearch.activeEntryTitleQuery
                ? carsDataSearch.activeEntryTitleQuery
                : ''

        let currentPath = this.props.history.location.pathname

        let currentCar = sharedCar
            ? sharedCar
            : prevOwned
            ? prevOwned
            : currentOwnedCar ?? undefined

        const filterTimelineAndAddQueryParams = (
            payload: {
                categories: ITimelineEntryTypes[]
                labels: string[]
                entry_title?: string
            },
            resetResults?: boolean
        ) => {
            this.props.setFilterTimelineItemsByCarIdCategoryIdsNLabelIds &&
                (payload.entry_title && payload.entry_title !== ''
                    ? this.props.setFilterTimelineItemsByCarIdCategoryIdsNLabelIds(
                          {
                              car_id: carid,
                              category_ids: payload.categories,
                              label_ids: payload.labels,
                              entry_title: payload.entry_title,
                              sendToAPI: true,
                              resetResults: resetResults,
                          }
                      )
                    : this.props.setFilterTimelineItemsByCarIdCategoryIdsNLabelIds(
                          {
                              car_id: carid,
                              category_ids: payload.categories,
                              label_ids: payload.labels,
                              entry_title: undefined,
                              sendToAPI: true,
                              resetResults: resetResults,
                          }
                      ))

            let newPath = redirectToFilteredPath(
                currentPath,
                payload.categories,
                payload.labels
            )
            newPath && this.props.history.push(newPath)
        }

        let displayedEntries = () => {
            if (carsDataSearch && carsDataSearch.results) {
                return carsDataSearch.results
            } else {
                if (
                    currentCar &&
                    carsTimelineData &&
                    currentCar.id &&
                    carsTimelineData[currentCar.id] &&
                    carsTimelineData[currentCar.id].entries &&
                    carsTimelineData[currentCar.id].entries.length > 0
                ) {
                    return carsTimelineData[currentCar.id].entries
                } else return undefined
            }
        }

        const returnUrlForMobileTopNav = () => {
            if (sharedCar) {
                return `/shared/car/${carid}`
            } else if (prevOwned) {
                return `/archives/car/${carid}`
            } else {
                return this.props.location.state &&
                    this.props.location.state.prevPath === '/history-files'
                    ? '/history-files'
                    : `/car/${carid}`
            }
        }

        return (
            <React.Fragment>
                <Loader isLoading={isTimelineDataLoading || isUserLoading} />
                <Faded>
                    <DesktopDisplayOnly>
                        <CenteredPageWrapper
                            fullwidth={true}
                            removeHorizPaddings
                        >
                            <PageColumnWrapper>
                                <HistoryFilePageManagerHeaderDesktop
                                    categories={category_searchable_items_list}
                                    tags={userTimelineCustomTags}
                                    onAddNEwEntry={() => {
                                        this.props.history.push(
                                            `/car/${carid}/history-file/create`
                                        )
                                        manageTimelineCreationFormState({
                                            isOpen: true,
                                            current_step_index: 0,
                                            active_creation:
                                                TimelineCreationTypeIdsEnum.add_new_desktop,
                                        })
                                    }}
                                    selectedFilteringCategories={
                                        activeCategories
                                    }
                                    selectedFilteringLabels={activeLabels}
                                    activeEntryTitleQuery={
                                        carsDataSearch &&
                                        carsDataSearch.activeEntryTitleQuery
                                            ? carsDataSearch.activeEntryTitleQuery
                                            : ''
                                    }
                                    filterByCategories={(
                                        selectedCategories: ITimelineEntryTypes[]
                                    ) =>
                                        filterTimelineAndAddQueryParams({
                                            categories: selectedCategories,
                                            labels: activeLabels,
                                            entry_title: activeEntryTitleQuery,
                                        })
                                    }
                                    filterByLabels={(
                                        selectedLabels: string[]
                                    ) =>
                                        filterTimelineAndAddQueryParams({
                                            categories: activeCategories,
                                            labels: selectedLabels,
                                            entry_title: activeEntryTitleQuery,
                                        })
                                    }
                                    sendFiltersToApi={(payload: {
                                        categories: ITimelineEntryTypes[]
                                        labels: string[]
                                        entry_title?: string
                                    }) =>
                                        filterTimelineAndAddQueryParams({
                                            categories: payload.categories,
                                            labels: payload.labels,
                                            entry_title: payload.entry_title,
                                        })
                                    }
                                    readOnlyMode={
                                        readOnlyMode === true ? true : false
                                    }
                                />

                                {displayedEntries() !== undefined &&
                                    !isTimelineDataLoading && (
                                        <TimelineViewManagerDesktop
                                            carid={carid}
                                            timelineItems={displayedEntries()}
                                            attachmentsObj={attachmentsObj}
                                            categories={
                                                category_searchable_items_list
                                            }
                                            tags={userTimelineCustomTags}
                                            selectedCategories={
                                                activeCategories
                                            }
                                            selectedLabels={activeLabels}
                                            activeEntryTitleQuery={
                                                activeEntryTitleQuery
                                            }
                                            withPlaceholder={
                                                currentCar &&
                                                carsTimelineData &&
                                                currentCar.id &&
                                                carsTimelineData[
                                                    currentCar.id
                                                ] &&
                                                carsTimelineData[currentCar.id]
                                                    .entries &&
                                                carsTimelineData[currentCar.id]
                                                    .entries.length > 0
                                                    ? false
                                                    : true
                                            }
                                            onPlaceholderClick={() => {
                                                this.props.history.push(
                                                    `/car/${carid}/history-file/create`
                                                )
                                                manageTimelineCreationFormState(
                                                    {
                                                        isOpen: true,
                                                        current_step_index: 0,
                                                        active_creation:
                                                            TimelineCreationTypeIdsEnum.add_new_desktop,
                                                    }
                                                )
                                            }}
                                            removeCriteria={(
                                                categories: ITimelineEntryTypes[],
                                                labels: string[],
                                                queryString?: string
                                            ) =>
                                                filterTimelineAndAddQueryParams(
                                                    {
                                                        categories: categories,
                                                        labels: labels,
                                                        entry_title:
                                                            queryString,
                                                    }
                                                )
                                            }
                                            clearAll={() =>
                                                filterTimelineAndAddQueryParams(
                                                    {
                                                        categories: [],
                                                        labels: [],
                                                        entry_title: undefined,
                                                    },
                                                    true
                                                )
                                            }
                                            userCurrency={userCurrency}
                                            readOnlyMode={readOnlyMode}
                                        />
                                    )}
                                {displayedEntries() === undefined &&
                                    !isTimelineDataLoading &&
                                    (!readOnlyMode ? (
                                        <TimelineViewManagerDesktop
                                            carid={carid}
                                            timelineItems={
                                                sampleData && sampleData
                                            }
                                            attachmentsObj={attachmentsObj}
                                            categories={
                                                category_searchable_items_list
                                            }
                                            tags={userTimelineCustomTags}
                                            selectedCategories={
                                                activeCategories
                                            }
                                            selectedLabels={activeLabels}
                                            activeEntryTitleQuery={
                                                activeEntryTitleQuery
                                            }
                                            withPlaceholder={
                                                currentCar &&
                                                carsTimelineData &&
                                                currentCar.id &&
                                                carsTimelineData[
                                                    currentCar.id
                                                ] &&
                                                carsTimelineData[currentCar.id]
                                                    .entries &&
                                                carsTimelineData[currentCar.id]
                                                    .entries.length > 0
                                                    ? false
                                                    : true
                                            }
                                            onPlaceholderClick={() => {
                                                this.props.history.push(
                                                    `/car/${carid}/history-file/create`
                                                )
                                                manageTimelineCreationFormState(
                                                    {
                                                        isOpen: true,
                                                        current_step_index: 0,
                                                        active_creation:
                                                            TimelineCreationTypeIdsEnum.add_new_desktop,
                                                    }
                                                )
                                            }}
                                            removeCriteria={(
                                                categories: ITimelineEntryTypes[],
                                                labels: string[],
                                                queryString?: string
                                            ) =>
                                                filterTimelineAndAddQueryParams(
                                                    {
                                                        categories: categories,
                                                        labels: labels,
                                                        entry_title:
                                                            queryString,
                                                    }
                                                )
                                            }
                                            hasSampleData
                                            userCurrency={userCurrency}
                                        />
                                    ) : (
                                        <WrapperHeaderStyle>
                                            <NoDataRectangle text="no entries in history file" />
                                        </WrapperHeaderStyle>
                                    ))}

                                {isCarBannerImageFormOpen && (
                                    <ModalDisplay
                                        toggle={toggleCarBannerImageForm}
                                        isOpen={true}
                                        customZindex={20}
                                    >
                                        <WrapperCarBannerImageFormDesktop
                                            close={toggleCarBannerImageForm}
                                            carid={carid}
                                        />
                                    </ModalDisplay>
                                )}
                            </PageColumnWrapper>
                        </CenteredPageWrapper>
                    </DesktopDisplayOnly>

                    <CenteredPageWrapper>
                        <IpadAndMobileDisplay>
                            {!readOnlyMode && (
                                <TopHeaderFilterNavMobile
                                    carid={carid}
                                    returnUrl={returnUrlForMobileTopNav()}
                                    onFilterIconClick={
                                        this.props.toggleFilterTimeline
                                    }
                                    changeView={this.changeView}
                                    currentSearch={activeEntryTitleQuery}
                                    activeCategoryIds={
                                        carsDataSearch &&
                                        carsDataSearch.activeCategoryIds
                                            ? carsDataSearch.activeCategoryIds
                                            : []
                                    }
                                    activeCustomLabelsIds={
                                        carsDataSearch &&
                                        carsDataSearch.activeCustomLabelsIds
                                            ? carsDataSearch.activeCustomLabelsIds
                                            : []
                                    }
                                    activeEntryTitleQuery={
                                        carsDataSearch &&
                                        carsDataSearch.activeEntryTitleQuery
                                            ? carsDataSearch.activeEntryTitleQuery
                                            : ''
                                    }
                                    sendFiltersToApi={(payload: {
                                        categories: ITimelineEntryTypes[]
                                        labels: string[]
                                        entry_title?: string
                                    }) =>
                                        filterTimelineAndAddQueryParams({
                                            categories: payload.categories,
                                            labels: payload.labels,
                                            entry_title: payload.entry_title,
                                        })
                                    }
                                    updateIsSearching={this.updateIsSearching}
                                />
                            )}
                            <SmallPadding
                                show={
                                    carsDataSearch &&
                                    ((carsDataSearch.activeCustomLabelsIds &&
                                        carsDataSearch.activeCustomLabelsIds
                                            .length > 0) ||
                                        (carsDataSearch.activeCategoryIds &&
                                            carsDataSearch.activeCategoryIds
                                                .length > 0))
                                        ? true
                                        : false
                                }
                            />

                            {!readOnlyMode && (
                                <FilterBottomSheetMenuMobile
                                    toggle={(isOpen: boolean) =>
                                        toggleFilterTimeline(isOpen)
                                    }
                                    isFilterBottomSheetMenuOpen={
                                        isFilterBottomSheetMenuOpen
                                    }
                                    activeCriteria={
                                        carsDataSearch &&
                                        carsDataSearch.activeCategoryIds
                                    }
                                    criteriaData={
                                        category_searchable_items_list &&
                                        category_searchable_items_list
                                    }
                                    sendFiltersToApi={(
                                        categories: ITimelineEntryTypes[]
                                    ) =>
                                        filterTimelineAndAddQueryParams({
                                            categories: categories,
                                            labels: activeLabels,
                                            entry_title: activeEntryTitleQuery,
                                        })
                                    }
                                />
                            )}

                            {!readOnlyMode && (
                                <div style={{ paddingBottom: '93px' }} />
                            )}

                            {!this.state.isSearching &&
                            displayedEntries() !== undefined &&
                            !isTimelineDataLoading &&
                            displayedEntries()?.length === 0 &&
                            (activeCategories.length > 0 ||
                                activeLabels.length > 0 ||
                                activeEntryTitleQuery !== '') ? (
                                <NoFilterResults variant="mobile" />
                            ) : (
                                displayedEntries() !== undefined &&
                                !isTimelineDataLoading && (
                                    <>
                                        <TimelineViewManagerMobile
                                            activeView={this.state.activeView}
                                            timelineItems={displayedEntries()}
                                            attachmentsObj={attachmentsObj}
                                            // costsObj={costsObj}
                                            dataCyId="timeline-view-manager"
                                            withPlaceholder={
                                                currentCar &&
                                                carsTimelineData &&
                                                currentCar.id &&
                                                carsTimelineData[
                                                    currentCar.id
                                                ] &&
                                                carsTimelineData[currentCar.id]
                                                    .entries &&
                                                carsTimelineData[currentCar.id]
                                                    .entries.length > 0
                                                    ? false
                                                    : true
                                            }
                                            onPlaceholderClick={() => {
                                                this.props.history.push(
                                                    `/car/${carid}/history-file/create`
                                                )
                                                manageTimelineCreationFormState(
                                                    {
                                                        isOpen: true,
                                                        current_step_index: 0,
                                                        active_creation:
                                                            TimelineCreationTypeIdsEnum.add_new,
                                                    }
                                                )
                                            }}
                                            userCurrency={userCurrency}
                                            readOnlyMode={
                                                readOnlyMode === true
                                                    ? true
                                                    : false
                                            }
                                        />
                                    </>
                                )
                            )}

                            {displayedEntries() === undefined &&
                                !isTimelineDataLoading &&
                                (!readOnlyMode ? (
                                    <TimelineViewManagerMobile
                                        activeView={this.state.activeView}
                                        timelineItems={sampleData && sampleData}
                                        attachmentsObj={attachmentsObj}
                                        // costsObj={costsObj}
                                        dataCyId="timeline-view-manager"
                                        withPlaceholder
                                        onPlaceholderClick={() => {
                                            this.props.history.push(
                                                `/car/${carid}/history-file/create`
                                            )
                                            manageTimelineCreationFormState({
                                                isOpen: true,
                                                current_step_index: 0,
                                                active_creation:
                                                    TimelineCreationTypeIdsEnum.add_new,
                                            })
                                        }}
                                        hasSampleData
                                        userCurrency={userCurrency}
                                    />
                                ) : (
                                    <NoDataRectangle text="no entries in history file" />
                                ))}
                        </IpadAndMobileDisplay>
                    </CenteredPageWrapper>
                </Faded>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(HistoryFilePageManager)
)
