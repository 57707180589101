import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../reducer'
import { push } from 'connected-react-router'

import { api } from '../../../services'

import { insurance_application_existing_state_select } from '../maindriver/maindriver_additionaldetails_submit'
import { ValidateAndAddStatsToApplication } from '../application/get'
import { IInsuranceApplication_API } from '../../types'
import posthog from 'posthog-js'
import { Submit_everydaycar_ids } from '../application/everydaycar_ids_submit'

export function* ExternalCarCreationSaga(p: IAnyObject): any {
    try {
        posthog.capture(
            'Update insurance application by creating an external car'
        )
        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let all_cars_ids = all_existing.external_car_ids

        let dataToSend = { ...p }

        if (dataToSend['navigateTo']) {
            delete dataToSend['navigateTo']
        }

        if (dataToSend['autoSelect']) {
            delete dataToSend['autoSelect']
        }

        if (dataToSend['fromWizard']) {
            delete dataToSend['fromWizard']
        }

        let data = {}

        if (Object.keys(dataToSend).length !== 0) {
            data = {
                customer: {
                    external_vehicles: [
                        {
                            ...dataToSend,
                        },
                    ],
                },
            }
        } else
            data = {
                customer: {
                    external_vehicles: [{}],
                },
            }

        let res: IInsuranceApplication_API = yield call(
            api.insuranceApplication.patchInsuranceApplication,
            {
                id: `${all_existing.id}`,
                data: { ...data },
            }
        )
        let applicationReducerDataWithStats = yield call(
            ValidateAndAddStatsToApplication,
            `${all_existing.id}`,
            res
        )

        yield put(
            insuranceActions.create_external_vehicle_success(
                applicationReducerDataWithStats
            )
        )

        let uidd: string | undefined = undefined

        if (res && res.customer?.external_vehicles) {
            if (all_cars_ids) {
                const notInArray2Alt = res.customer?.external_vehicles.filter(
                    (item) =>
                        item.uid && all_cars_ids!.indexOf(`${item.uid}`) === -1
                )

                if (notInArray2Alt && notInArray2Alt[0]) {
                    uidd = notInArray2Alt[0].uid
                }
            } else if (!all_cars_ids) {
                if (res.customer?.external_vehicles[0]?.uid) {
                    uidd = res.customer?.external_vehicles[0]?.uid
                }
            }
        }

        if (p.autoSelect && uidd) {
            yield call(Submit_everydaycar_ids, [uidd])
        }

        // console.log(' uidd', uidd)

        if (uidd) {
            if (p.fromWizard) {
                push(
                    `/insurance/application/${all_existing?.id}/wizard?section_id=everyday_vehicles&subsection_id=tech_info&car_id=${uidd}`
                )
            } else if (p.navigateTo) {
                yield put(
                    push(
                        `/insurance/application/${all_existing.id}/everyday_car/${uidd}/${p.navigateTo}`
                    )
                )
            } else {
                yield put(
                    push(
                        `/insurance/application/${all_existing.id}/everyday_car/${uidd}?add_new_external=true`
                    )
                )
            }
        }
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: ExternalCarCreationSaga,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.create_external_vehicle_error(customError)
            )
        }
    }
}

function* Watcher_car_external_creation() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.create_external_vehicle_request
        )
        yield call(ExternalCarCreationSaga, payload)
    }
}

const car_external_creation: any[] = [fork(Watcher_car_external_creation)]

export default car_external_creation
