type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const ContactIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M14 2L6.80005 9.2"
                stroke={color ?? '#616161'}
                stroke-width="1.5"
                stroke-miterlimit="10"
            />
            <path
                d="M14 2L9.8 14L6.8 9.2L2 6.2L14 2Z"
                stroke={color ?? '#616161'}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="square"
            />
        </svg>
    )
}

export default ContactIcon
