import { call, fork, put, take, select } from 'redux-saga/effects'
import {
    ICostItem,
    IDeleteCostItemPayloadRequest,
    IDeleteCostItemPayloadSuccess,
    ITimelineItem,
} from 'timelineModels'
import * as successActions from '../../timeline/actions/successActions'
import * as errorActions from '../../timeline/actions/errorActions'
import { api } from '../../services'
// import { push } from 'connected-react-router'

import * as requestActions from '../actions/requestActions'
import posthog from 'posthog-js'

import { RootState } from 'typesafe-actions'
// import { ConvertAPIEntryCostToEntryCostSingle } from '../../conversions/timeline/entryCostToCostItem'
import { UpdateActiveEntryWithCost } from '../../conversions/timeline/updateActiveEntryWithCost'
import { ICarsObject, ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { IApiEntryCostDELETE_args } from 'ApiInterfaces'
import * as carActions from '../../entities/cars/actions'
import { push } from 'connected-react-router'

const get_active_entry_in_state = (state: RootState) =>
    state.timeline.activeEntry

const get_cars_in_state = (state: RootState) => state.entities.carsData.cars

function* DeleteCarTimelineEntryCostSaga(
    payload: IDeleteCostItemPayloadRequest
): any {
    try {
        // STEP 1 . call the api func
        let api_payload: IApiEntryCostDELETE_args = {
            entry_id: payload.itemid,
            cost_id: payload.costid,
            car_id: payload.carid,
        }
        yield call(
            api.timeline.costs.deleteEntryCost.deleteEntryCost,
            api_payload
        )

        yield put(
            push(
                `/car/${payload.carid}/history-file/entry?entryid=${payload.itemid}`
            )
        )

        // let convertedCost = ConvertAPIEntryCostToEntryCostSingle(api_res)

        let active_entry_in_state: ITimelineItem = yield select(
            get_active_entry_in_state
        )

        let deletedCost: ICostItem =
            active_entry_in_state.costItemsObj! &&
            active_entry_in_state.costItemsObj[payload.costid]

        let active_entry_updated_with_updated_cost: ITimelineItem | undefined =
            UpdateActiveEntryWithCost(
                'delete',
                active_entry_in_state,
                deletedCost
            )

        // STEP 2. build up payload to return to reducer
        let res: IDeleteCostItemPayloadSuccess = {
            entry: active_entry_updated_with_updated_cost,
            // cost: convertedCost,
        }

        // STEP 3. dispatch delete action
        yield put(successActions.deleteTimelineItemCostSuccess(res))

        let cars_in_state: ICarsObject = yield select(get_cars_in_state)

        let carAffected = cars_in_state[payload.carid]

        if (carAffected?.last_insurance_entry?.id === payload.itemid) {
            yield put(
                carActions.actions.getLastCarInsuranceEntrySuccess({
                    carid: payload.carid,
                    timelineItem: undefined,
                })
            )
        }

        // Capture event
        posthog.capture('DELETE ENTRY COST')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: DeleteCarTimelineEntryCostSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carid}/history-file/entry?entryid=${payload.itemid}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.deleteTimelineItemCostError(customError))
        }
    }
}

function* watcherDeleteCarTimelineEntryCost() {
    while (true) {
        const { payload } = yield take(
            requestActions.deleteTimelineItemCostRequest
        )

        yield call(DeleteCarTimelineEntryCostSaga, payload)
    }
}

const delete_car_timeline_entry_cost: any[] = [
    fork(watcherDeleteCarTimelineEntryCost),
]

export default delete_car_timeline_entry_cost
