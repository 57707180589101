import * as React from 'react'
import styled from 'styled-components'
import { BannerViewIcon, GridViewIcon } from '../../statefulicons'
import IconButton from '../../Button/iconButton'
import SortMenuMobile from '../sortMenu/sortMenuMobile'
import { IDropdownItem } from 'entityModels'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'

type IStyle = {
    $theme: ITheme
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
`
const ViewsContainer = styled.section<IStyle>`
    position: relative;
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 48px;
    outline: none;
    background-color: ${(props) =>
        colours[props.$theme].background_primary_switch};
    border-radius: 50px;
    transition: all 200ms;
`

const Circle = styled.div<IStyle>`
    position: absolute;
    z-index: 0;
    width: 70px;
    height: 90%;
    background: ${(props) => colours[props.$theme].background_default};
    box-shadow: 0px 1px 2px
        ${(props) => colours[props.$theme].shadow_strongest_16};
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 200ms;

    :hover {
        transition: all 250ms;
    }
`

const BtnWrapper = styled.button`
    all: unset;
    cursor: pointer;
    width: 70px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface Props {
    activeView: string
    toggleMenu: any
    addACar?: () => any
    sortData?: IDropdownItem[]
    currentSortOption?: string
    onSort?: any
    isExternalGarage?: boolean
}

export default function ViewsMenuMobile(props: Props) {
    const {
        activeView,
        toggleMenu,
        addACar,
        sortData,
        currentSortOption,
        onSort,
        isExternalGarage,
    } = props

    const [showSortActionBottomSheet, setShowSortActionBottomSheet] =
        React.useState(false)

    const toggleSortActionBottomSheet = (show: boolean) =>
        setShowSortActionBottomSheet(show)

    const { theme } = useThemes()

    return (
        <>
            <Row>
                <ViewsContainer $theme={theme}>
                    <Circle
                        $theme={theme}
                        style={{
                            transform:
                                activeView !== 'grid_view'
                                    ? 'translateX(68px)'
                                    : 'translateX(2px)',
                        }}
                    />
                    <BtnWrapper onClick={() => toggleMenu('grid_view')}>
                        <GridViewIcon
                            theme={theme}
                            isActive={activeView === 'grid_view' ? true : false}
                        />
                    </BtnWrapper>

                    <BtnWrapper onClick={(e: any) => toggleMenu('banner_view')}>
                        <BannerViewIcon
                            theme={theme}
                            isActive={
                                activeView === 'banner_view' ? true : false
                            }
                        />
                    </BtnWrapper>
                </ViewsContainer>
                {isExternalGarage ? (
                    <div />
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                            buttonheight="40px"
                            buttonwidth="40px"
                            bgcolor="transparent"
                            borderColor={colours[theme].primary}
                            icon="sort-icon-blue"
                            onClick={() => toggleSortActionBottomSheet(true)}
                        />

                        {addACar !== undefined && (
                            <div
                                style={{
                                    paddingLeft: 16,
                                    position: 'relative',
                                }}
                                role="button"
                                onClick={() => {
                                    addACar()
                                }}
                            >
                                <IconButton
                                    buttonheight="40px"
                                    buttonwidth="40px"
                                    bgcolor={colours[theme].primary}
                                    borderColor={colours[theme].primary}
                                    icon="add_plus_white"
                                    dataPH={'add_car'}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Row>
            {sortData && !isExternalGarage && (
                <SortMenuMobile
                    isVisible={showSortActionBottomSheet}
                    close={() => toggleSortActionBottomSheet(false)}
                    dropdownData={sortData}
                    currentOption={currentSortOption}
                    onSelected={onSort}
                />
            )}
        </>
    )
}
