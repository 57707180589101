import { ITimelineItem } from 'timelineModels'

export const calculate_entry_n_attachments = (item: ITimelineItem): number => {
    let num: number
    num = item.attachmentIDs ? item.attachmentIDs.length : 0

    if (item.costUIDS) {
        for (const costUID of item.costUIDS) {
            if (
                item.costItemsObj &&
                item.costItemsObj[costUID] &&
                item.costItemsObj[costUID].attachmentID
            ) {
                num = num + 1
            }
        }
    }

    return num
}
