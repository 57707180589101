import * as React from 'react'
// import styled from 'styled-components'
// import { device } from '../../../templates/displays/devices'
import { RouteComponentProps, withRouter } from 'react-router-dom'
// import Faded from '../../../templates/animated/faded'
import { actionsObject } from './mainMenuData'
// import { motion } from 'framer-motion'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// import Icon from '../../icons'

import WrapperCarBannerImageFormMobile from '../../../organisms/editForms/imageFormWrapper/wrapperCarBannerImageFormMobile'
import ModalDisplay from '../../../templates/displays/pageWrappers/modalDisplay'
import { editFormsActions } from '../../../../redux/editForms/reducer'

import {
    ICreateTaskPayload,
    IList,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import NumberTechInfoEditFormMobile from '../../../organisms/editForms/technicalInformationEditForm/number/mobile/numberTechInfoEditFormMobile'
// import ButtonAtom from '../../Button/ButtonAtom'

import { generate_task_cars_dropdown_list } from '../../../../redux/conversions/dropdowns/taskCarsDropdownGenerator'
import CreateTaskFormMobile from '../../../organisms/editForms/taskFormManager/custom/createTaskFormMobile'
import WrapperCarCoverImageMobile from '../../../organisms/editForms/imageFormWrapper/wrapperCarCoverImageMobile'
import { createTaskRequest } from '../../../../redux/tasks/actions/requestActions'
import { IUserPreferencesDataLevel4Obj } from 'IUserPreferencesModels'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import CarOverviewActionsMobile from '../bottomSheetMenu/carOverviewActionsMobile'
import { getGarageCarsDataRequest } from '../../../../redux/entities/cars/actions/loadingActions'
import { writeTechnicalInformationRequest } from '../../../../redux/entities/technical_information/actions/loadingActions'
import { ITimelineCreateForms } from 'myModels'
import { TimelineCreationTypeIdsEnum } from '../../../../redux/timeline/timelineEnum'
import { checkIfPwaStandalone } from '../../../templates/pwaCustom/checkIpadPwa'

const dispatchProps = {
    toggleCarGalleryEditForm: () => editFormsActions.toggleCarGalleryEditForm(),
    toggleCarHighlightsEditForm: () =>
        editFormsActions.toggleCarHighlightsEditForm(),
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => writeTechnicalInformationRequest(p),
    createTaskRequest: (payload: ICreateTaskPayload) =>
        createTaskRequest(payload),
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
}

function mapStateToProps(state: RootState) {
    return {
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,

        currentCarID:
            state.entities.carsData.currentCarID &&
            state.entities.carsData.currentCarID,

        technical_information:
            state.entities.technicalInformationData.technical_information,

        garageid: state.user.userLoggedIn
            ? state.user.userLoggedIn.owns_garage?.uid
            : undefined,

        carsData: state.entities.carsData.cars,

        garagesData: state.entities.garagesData.garages,

        // mileage
        user_distance_unit_ids_list:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data[
                'distance_unit'
            ] &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .ids_list,
        user_distance_unit_chosen_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data[
                'distance_unit'
            ] &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .user_choice_id,
        user_distance_unit_data:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data[
                'distance_unit'
            ] &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .data,
    }
}

interface IProps extends RouteComponentProps<any> {
    isOpen: boolean
    toggle: any
    activepage: string
    currentCarID: any
    history: any
    toggleCarGalleryEditForm: any
    toggleCarHighlightsEditForm: any
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => {}
    technical_information: any
    garageid: any
    carsData: any
    createTaskRequest: any
    garagesData: any
    getGarageCarsDataRequest: any
    user_distance_unit_ids_list: string[] | null
    user_distance_unit_chosen_id: string | null
    user_distance_unit_data: IUserPreferencesDataLevel4Obj | null
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) => {}
}

type IUnitInfo = {
    // data in the list :
    list: IList[] | null
    // id : distance_unit or fuel_unit
    id?: string
    // user chosen unit:
    user_default_unit?: string
}

type State = {
    action: string | null
}

class ActionsMenuModalMobile extends React.Component<IProps, State> {
    state = {
        action: null,
    }

    listOfActions = (): string[] => {
        let currentPath = checkIfPwaStandalone()
            ? window.location.pathname
            : window.location.pathname

        let { currentCarID } = this.props

        if (currentPath === '/garage') {
            let actions = actionsObject.garage

            return actions
        } else if (currentPath === `/car/${currentCarID}`) {
            let actions = actionsObject.car_overview

            return actions
        } else return []
    }

    generate_cars_dropdown_list = (arr: string[]) => {
        let { carsData, garageid } = this.props

        if (garageid) {
            let obj = generate_task_cars_dropdown_list(arr, carsData, garageid)

            return obj
        } else return []
    }

    componentDidMount() {
        if (
            !this.props.garagesData[this.props.garageid] &&
            this.props.garageid
        ) {
            return this.props.getGarageCarsDataRequest(this.props.garageid)
        }
    }

    render() {
        const {
            isOpen,
            toggle,
            toggleCarGalleryEditForm,
            currentCarID,
            toggleCarHighlightsEditForm,
            technical_information,
            writeTechnicalInformationRequest,
            garageid,
            carsData,
            createTaskRequest,
            garagesData,
            manageTimelineCreationFormState,
        } = this.props

        let actionsList = this.listOfActions()

        let garageCarIds = garagesData[garageid] && garagesData[garageid].cars

        // mileage
        let distance_units = (): IList[] | null => {
            if (this.props.user_distance_unit_ids_list) {
                return this.props.user_distance_unit_ids_list.map(
                    (id: string, index: number) => {
                        return this.props.user_distance_unit_data![id]
                    }
                )
            } else return null
        }

        let user_default_distance_unit: IList | null =
            this.props.user_distance_unit_data &&
            this.props.user_distance_unit_chosen_id
                ? this.props.user_distance_unit_data[
                      this.props.user_distance_unit_chosen_id
                  ]
                : null

        let distanceUnitInfo = (): IUnitInfo => {
            return {
                list: distance_units(),
                id: 'distance_units',
                user_default_unit: user_default_distance_unit?.id,
            }
        }
        let user_default_unit: string | undefined =
            distanceUnitInfo().user_default_unit
        let carid = this.props.match.params.carid

        let quickMenuActionItems: {
            id: string
            action?: any
        }[] = actionsList.map((item) => {
            return {
                id: item,
                action: () => {
                    if (item === "Update your car's banner image") {
                        this.setState({
                            action: 'editBannerImage',
                        })
                    } else if (item === 'Add Image(s)') {
                        toggleCarGalleryEditForm()
                        toggle()
                    } else if (item === 'add car highlight') {
                        toggleCarHighlightsEditForm()
                        toggle()
                    } else if (item === 'Update Mileage') {
                        this.setState({
                            action: 'mileage',
                        })
                    } else if (item === 'Add a Task') {
                        this.setState({
                            action: 'task',
                        })
                    } else if (item === 'Update Car Profile Image') {
                        this.setState({
                            action: 'cover',
                        })
                    } else if (item === 'Add to History File') {
                        this.props.history.push(
                            `/car/${carid}/history-file/create`
                        )
                        manageTimelineCreationFormState({
                            isOpen: true,
                            current_step_index: 0,
                            active_creation:
                                TimelineCreationTypeIdsEnum.add_new,
                        })
                        toggle()
                    }
                },
            }
        })

        return (
            <div style={{ zIndex: 10 }}>
                {this.state.action === 'editBannerImage' && (
                    <ModalDisplay
                        toggle={() => {
                            this.setState({ action: null })
                            toggle()
                        }}
                        isOpen={true}
                    >
                        <WrapperCarBannerImageFormMobile
                            close={() => {
                                this.setState({ action: null })
                                toggle()
                            }}
                            carid={currentCarID}
                        />
                    </ModalDisplay>
                )}

                {this.state.action === 'mileage' &&
                    technical_information[`hf-${currentCarID}`] && (
                        <ModalDisplay
                            toggle={() => {
                                this.setState({ action: null })
                                toggle()
                            }}
                            isOpen={true}
                        >
                            <NumberTechInfoEditFormMobile
                                tech_info_id={`hf-${currentCarID}`}
                                submitFunc={writeTechnicalInformationRequest}
                                isUnits={true}
                                unitInfo={distanceUnitInfo()}
                                unitList={
                                    distance_units()
                                        ? distance_units()
                                        : undefined
                                }
                                item={
                                    user_default_unit
                                        ? user_default_unit === 'kilometers'
                                            ? technical_information[
                                                  `hf-${currentCarID}`
                                              ].mileage
                                            : {
                                                  ...technical_information[
                                                      `hf-${currentCarID}`
                                                  ].mileage,
                                                  answer:
                                                      distanceUnitInfo() &&
                                                      distanceUnitInfo()
                                                          .user_default_unit &&
                                                      unitGenerator.generateFrontEndValueDistanceUnit(
                                                          user_default_unit,
                                                          technical_information[
                                                              `hf-${currentCarID}`
                                                          ].mileage.answer
                                                      ),
                                              }
                                        : technical_information[
                                              `hf-${currentCarID}`
                                          ].mileage
                                }
                                toggle={() => {
                                    this.setState({ action: null })
                                    toggle()
                                }}
                                carid={currentCarID}
                            />
                        </ModalDisplay>
                    )}

                {this.state.action === 'task' && garageCarIds && (
                    <ModalDisplay
                        toggle={() => {
                            this.setState({ action: null })
                            toggle()
                        }}
                        isOpen={true}
                    >
                        <CreateTaskFormMobile
                            toggle={() => {
                                this.setState({ action: null })
                                toggle()
                            }}
                            submitFunc={createTaskRequest}
                            dropdownData={this.generate_cars_dropdown_list(
                                garageCarIds
                            )}
                            carsData={carsData}
                            garage_id={garageid}
                            currentCarID={currentCarID}
                            garageCarsIds={garageCarIds}
                        />
                    </ModalDisplay>
                )}
                {this.state.action === 'cover' && (
                    <ModalDisplay
                        toggle={() => {
                            this.setState({ action: null })
                            toggle()
                        }}
                        isOpen={true}
                    >
                        <WrapperCarCoverImageMobile
                            close={() => {
                                this.setState({ action: null })
                                toggle()
                            }}
                            carid={currentCarID}
                        />
                    </ModalDisplay>
                )}

                <CarOverviewActionsMobile
                    actions_items={quickMenuActionItems}
                    toggle={() => {
                        setTimeout(function () {
                            toggle()
                        }, 50)
                    }}
                    isCarOverviewQuickMenuOpen={
                        this.state.action === null ? isOpen : false
                    }
                />
            </div>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ActionsMenuModalMobile)
)
