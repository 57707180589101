// create store for todos (basically reducer for the main final store)

import { IProposal, IProposalsState, IProposalRequest } from 'myModels'
// import { combineReducers } from "redux";

import * as successActions from './actions/successactions'
import * as errorActions from './actions/errorActions'
import * as loadingActions from './actions/loadingActions'

import { ActionType } from 'typesafe-actions'

type SuccessActions = ActionType<typeof successActions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

// MOCK DATA TO TEST

const request1: IProposalRequest = {
    id: 'request1',
    ownerid: 'userid',
    code: 'code',
    message: 'Hi I would like to invite my friends',
    number: 2,
    proposals: ['proposal3', 'proposals4'],
    created_at: 'date',
    updated_at: 'date',
}

const request2: IProposalRequest = {
    id: 'request1',
    ownerid: 'userid',
    code: 'code',
    message: 'Hi I would like to invite my friends',
    number: 2,
    proposals: ['proposal3', 'proposals4'],
    created_at: 'date',
    updated_at: 'date',
}

const proposal1: IProposal = {
    id: 'proposal1id',
    ownerid: 'userid',
    code: 'code',
    created_at: 'date',
    updated_at: 'date',
    redeemed: false,
}

const proposal2: IProposal = {
    id: 'proposal2id',
    ownerid: 'userid',
    code: 'code',
    created_at: 'date',
    updated_at: 'date',
    redeemed: false,
}
const proposal3: IProposal = {
    id: 'proposal3id',
    ownerid: 'userid',
    code: 'code',
    created_at: 'date',
    updated_at: 'date',
    redeemed: true,
}

const proposal4: IProposal = {
    id: 'proposal4id',
    ownerid: 'userid',
    code: 'code',
    created_at: 'date',
    updated_at: 'date',
    redeemed: false,
}

export const referralsReducer = (
    state: IProposalsState = {
        proposalrequests: {
            allIds: ['request1id', 'request2id'],
            byId: {
                request1id: request1,
                request2id: request2,
            },
        },
        proposalcodes: {
            allIds: [
                'proposal1id',
                'proposal2id',
                'proposal3id',
                'proposal4id',
            ],
            byId: {
                proposal1id: proposal1,
                proposal2id: proposal2,
                proposal3id: proposal3,
                proposal4id: proposal4,
            },
        },
        error: null,
        loading: false,
    },
    action: LoadingActions | SuccessActions | ErrorActions
): IProposalsState => {
    switch (action.type) {
        case loadingActions.createProposalCodeRequestRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case loadingActions.redeemCodeRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case successActions.createProposalCodeRequestSuccess.type:
            return Object.assign({}, state, {
                ...state,

                loading: false,
                error: null,
            })
        case successActions.redeemCodeSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
            })
        case errorActions.createProposalCodeRequestError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case errorActions.redeemCodeError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        default:
            return state
    }
}

// to use combine reducers, have the name back to referrals up there:
// const referralsReducer = combineReducers({ referrals });

export default referralsReducer

export type ReferralsState = ReturnType<typeof referralsReducer>
