import {
    IAllApex_podcastss_api,
    IAllApex_podcastss_apiNode,
    IApexPodscastState,
} from 'apexModels'

export const convertPrismicApexPodcastFromAPIToSingle = (
    data: IAllApex_podcastss_api
): IApexPodscastState => {
    let singleObject: IApexPodscastState = {
        date: '',
        title: '',
        link: '',
        name: '',
        short_title: '',
        blurb: '',
        google_podcasts_url: '',
        apple_url: '',
        soundcloud_url: '',
        spotify_url: '',
    }

    let node: IAllApex_podcastss_apiNode | undefined =
        data.edges[0] && data.edges[0].node

    if (node !== undefined) {
        singleObject.date = node.date ? node.date : ''
        singleObject.title = node.title ? node.date : ''
        singleObject.link =
            node.podcast_embed_link && node.podcast_embed_link.url
                ? node.podcast_embed_link.url
                : ''
        singleObject.name = node.name ? node.name : ''
        singleObject.short_title = node.short_title ? node.short_title : ''
        singleObject.blurb = node.blurb ? node.blurb : ''
        // apple
        singleObject.apple_url =
            node.apple_url && node.apple_url.url ? node.apple_url.url : ''
        // google
        singleObject.google_podcasts_url =
            node.google_podcasts_url && node.google_podcasts_url.url
                ? node.google_podcasts_url.url
                : ''
        // spotify
        singleObject.spotify_url =
            node.spotify_url && node.spotify_url.url ? node.spotify_url.url : ''
        // soundcloud
        singleObject.soundcloud_url =
            node.soundcloud_url && node.soundcloud_url.url
                ? node.soundcloud_url.url
                : ''
        return singleObject
    } else {
        return singleObject
    }
}

export const convertPrismicApexPodcastFromAPIToArray = (
    data: IAllApex_podcastss_api
): IApexPodscastState[] => {
    let itemsArray: IApexPodscastState[] = []

    if (data !== undefined) {
        for (const item of data.edges) {
            let node: IAllApex_podcastss_apiNode = item.node

            let res: IApexPodscastState = {
                date: ',',
                title: ',',
                link: ',',
                name: ',',
                short_title: ',',
                blurb: '',
                google_podcasts_url: '',
                apple_url: '',
                soundcloud_url: '',
                spotify_url: '',
            }

            if (node !== undefined) {
                res.date = node.date ? node.date : ''
                res.title =
                    node.title && node.title[0] && node.title[0].text
                        ? node.title[0].text
                        : ''
                res.link =
                    node.podcast_embed_link && node.podcast_embed_link.url
                        ? node.podcast_embed_link.url
                        : ''
                res.name = node.name ? node.name : ''
                res.short_title = node.short_title ? node.short_title : ''
                res.blurb = node.blurb ? node.blurb : ''
                // apple
                res.apple_url =
                    node.apple_url && node.apple_url.url
                        ? node.apple_url.url
                        : ''
                // google
                res.google_podcasts_url =
                    node.google_podcasts_url && node.google_podcasts_url.url
                        ? node.google_podcasts_url.url
                        : ''
                // spotify
                res.spotify_url =
                    node.spotify_url && node.spotify_url.url
                        ? node.spotify_url.url
                        : ''
                // soundcloud
                res.soundcloud_url =
                    node.soundcloud_url && node.soundcloud_url.url
                        ? node.soundcloud_url.url
                        : ''
                itemsArray.push(res)
            }
        }
    }

    return itemsArray
}
