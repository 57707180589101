import { welcome_sections_list } from "./welcome_sections";
import { IWelcomeSections } from "myInterfaces";

export type IWelcomeSectionsState = IWelcomeSections[];

const initialState = welcome_sections_list;

export const welcome_sections = (state: IWelcomeSectionsState = initialState) =>
  state;

export default welcome_sections;

export type WelcomeSectionsState = ReturnType<typeof welcome_sections>;
