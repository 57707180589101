import { IGetSortedGarageApiPayload } from 'entityModels'

export const ConvertGetSortedGarageUrl = (
    payload: IGetSortedGarageApiPayload
) => {
    let url_built: string = `${payload.garageid}`

    if (payload.q_params) {
        if (payload.q_params.length === 1) {
            return (url_built = url_built.concat(
                `?${Object.keys(payload.q_params[0])}=${Object.values(
                    payload.q_params[0]
                )}`
            ))
        } else if (payload.q_params.length === 2) {
            url_built = url_built.concat(
                `?${Object.keys(payload.q_params[0])}=${Object.values(
                    payload.q_params[0]
                )}&${Object.keys(payload.q_params[1])}=${Object.values(
                    payload.q_params[1]
                )}`
            )
        } else if (payload.q_params.length === 3) {
            url_built = url_built.concat(
                `?${Object.keys(payload.q_params[0])}=${Object.values(
                    payload.q_params[0]
                )}&${Object.keys(payload.q_params[1])}=${Object.values(
                    payload.q_params[1]
                )}&${Object.keys(payload.q_params[2])}=${Object.values(
                    payload.q_params[2]
                )}`
            )
        }
    }
    return encodeURI(url_built)
}
