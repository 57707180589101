import * as React from 'react'
import styled from 'styled-components'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
import { IInsuranceQuoteApplication_MainDriver } from '../../../redux/insuranceQuoteApplication/reducer'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../providers/theme/colours'
import { IInsuranceApplicationSectionId } from '../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $theme: ITheme
    $isSelected?: boolean
    $sectionId?: IInsuranceApplicationSectionId
}

const Wrap = styled.section`
    width: 100%;
    padding: 8px;
    padding-top: 24px;
`
const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    cursor: pointer;
`

const Name = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
`

const GreyWrapper = styled.div<{ $customHoverColour?: string }>`
    width: 100%;
    display: flex;
    height: 48px;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 4px;
    background: var(--core-neutral-darken-50, rgba(26, 26, 26, 0.02));
    :hover {
        background: ${(props) =>
            props.$customHoverColour ?? 'rgba(94, 195, 202, 0.04)'};
    }
`

const WrapMobile = styled.section`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: row;
    background: var(--core-neutral-darken-50, rgba(26, 26, 26, 0.02));
    border-radius: 4px;
    gap: 16px;
    margin-top: 24px;
`

const SubWrapperMobile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
`
const ImageWrapper = styled.div<IStyle>`
    height: 48px;
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    border-radius: 50%;
    border: ${(props) =>
        `1px solid ${
            props.$sectionId
                ? insurance_colours[props.$theme].sections[props.$sectionId]
                      .primary_30
                : colours[props.$theme].primary
        }`};
`

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 4px;
    border-radius: 50%;
`

const CompletionPercentage = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    font-size: 16px;
    font-family: Lato-bold;
    line-height: 24px;
    letter-spacing: 0.001px;
`

interface Props {
    policy_holder: IInsuranceQuoteApplication_MainDriver
    completion: number
    onClick?: () => any
    isMobile?: boolean
    sectionID?: IInsuranceApplicationSectionId
}

const PolicyHolderItem: React.FunctionComponent<Props> = (props) => {
    const { theme } = useThemes()

    return props.isMobile ? (
        <WrapMobile
            onClick={() => {
                if (props.onClick && props.policy_holder) {
                    props.onClick()
                }
            }}
        >
            <ImageWrapper $theme={theme} $sectionId={props.sectionID}>
                {props.policy_holder &&
                props.policy_holder.basic_details?.profile_image_url ? (
                    <Image
                        src={
                            props.policy_holder.basic_details?.profile_image_url
                        }
                    />
                ) : (
                    <Image src={blank_user} />
                )}
            </ImageWrapper>
            <SubWrapperMobile style={{ width: '70%' }}>
                <Name $theme={theme} style={{ width: '80%' }}>{`${
                    props.policy_holder.basic_details?.given_name
                        ? props.policy_holder.basic_details.given_name
                        : ''
                } ${
                    props.policy_holder.basic_details?.family_name
                        ? props.policy_holder.basic_details.family_name
                        : ''
                }`}</Name>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8,
                    }}
                >
                    <CompletionPercentage $theme={theme}>
                        {props.completion ? `${props.completion}%` : '0%'}
                    </CompletionPercentage>
                </div>
            </SubWrapperMobile>
        </WrapMobile>
    ) : (
        <Wrap>
            <ListItem
                onClick={() => {
                    if (props.onClick && props.policy_holder) {
                        props.onClick()
                    }
                }}
            >
                <ImageWrapper $theme={theme} $sectionId={props.sectionID}>
                    {props.policy_holder &&
                    props.policy_holder.basic_details?.profile_image_url ? (
                        <Image
                            src={
                                props.policy_holder.basic_details
                                    ?.profile_image_url
                            }
                        />
                    ) : (
                        <Image src={blank_user} />
                    )}
                </ImageWrapper>
                <GreyWrapper
                    $customHoverColour={
                        props.sectionID
                            ? insurance_colours[theme].sections[props.sectionID]
                                  .primary_04
                            : undefined
                    }
                >
                    <Name $theme={theme} style={{ maxWidth: '70%' }}>{`${
                        props.policy_holder.basic_details?.given_name ?? ''
                    } ${
                        props.policy_holder.basic_details?.family_name ?? ''
                    }`}</Name>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 8,
                        }}
                    >
                        <CompletionPercentage $theme={theme}>
                            {props.completion ? `${props.completion}%` : '0%'}
                        </CompletionPercentage>
                    </div>
                </GreyWrapper>
            </ListItem>
        </Wrap>
    )
}

export default PolicyHolderItem
