import * as React from 'react'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

interface IStyle {
    $theme: ITheme
    iconOnLeft?: boolean | undefined
    isUppercase?: boolean | undefined
    width?: string | undefined
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: transparent;
    outline: none;
    background-color: ${(props) => colours[props.$theme].error};
    border-radius: 5px;
    cursor: pointer;
`

const Text = styled.div<IStyle>`
    color: ${(props) => colours[props.$theme].background_default};
    font-family: 'Lato';
    font-size: 15px;
    text-transform: ${(props) =>
        props.isUppercase ? 'uppercase' : 'capitalize'};
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    isUppercase?: boolean
    width?: string | undefined
}

const BigRedDeletionButton: React.FunctionComponent<Props> = (props: Props) => {
    let { onClick, text, isUppercase, width, dataCyId } = props
    const { theme } = useThemes()
    return (
        <Button
            width={width}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            $theme={theme}
        >
            <Text $theme={theme} isUppercase={isUppercase}>
                {text}
            </Text>
        </Button>
    )
}

export default BigRedDeletionButton
