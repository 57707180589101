import { AutoComplete } from 'antd'
import styled from 'styled-components'
import './styles.css'
import SearchIcon from '../../../atoms/statefulicons/SearchIcon'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import grey_cross from '../../../../public/assets/icons/close_cross_grey.svg'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'
import CrossIcon from '../../../atoms/icons/crossIcon'

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    addCriteria?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    value: any
    handleChange: any
    categoryData: any
    fetchItem: any
    labelsData: any
    clearEntryTitleSearch: any
    finalSearchToApi: any
    currentSearch: string | undefined
    checkIfSelectedEntryOrLabel: any
}

type IStyledProps = {
    $issearchactive?: boolean
    grey_cross?: string
}

const CustomisedAutocomplete = styled(AutoComplete)<IStyledProps>`
    width: 88vw;
    border: none;
    background-color: var(--off-bg-color, #fafafa) !important;

    border-radius: 5px;
    height: ${(props) =>
        props.$issearchactive === true ? '50px !important' : '38px !important'};
    padding-left: 40px;

    padding-top: 0px;
    .ant-select-selector input {
        color: var(--text-strong, #1a1a1a) !important;
        height: ${(props) =>
            props.$issearchactive === true
                ? '50px !important'
                : '38px !important'};
    }
`

const GreyCross = styled.div<IStyledProps>`
    width: 12px;
    height: 12px;
    position: absolute;
    background-size: cover;
    right: 15px;
    top: ${(props) => (props.$issearchactive ? '18px' : '11px')};
`

const Option = AutoComplete.Option

const StyledOptionWrapper = styled(Option)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const SearchIconCustom = styled.div<IStyledProps>`
    position: absolute;
    z-index: 2;
    top: ${(props) => (props.$issearchactive === true ? '17px' : '12px')};
    left: 20px;
`
const Wrapper = styled.div`
    position: relative;
`

const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`

export default function TimelineSearchBarMobileChild(props: Props) {
    const {
        addCriteria,
        manageissearchactive,
        dataCyId,
        issearchactive,
        value,
        handleChange,
        categoryData,
        fetchItem,
        labelsData,
        finalSearchToApi,
        checkIfSelectedEntryOrLabel,
        currentSearch,
    } = props

    const { theme } = useThemes()

    let mappedCategoryData = categoryData.map((di: any, index: number) => {
        let i = di.item

        if (i) {
            return (
                <StyledOptionWrapper
                    className="custom-category-filter-option-mobile"
                    value={i.name}
                    key={`${i.name}-category-${index}`}
                    aria-label={i.name}
                >
                    <div className="custom-option-label-timeline-mobile">
                        Add category filter:
                    </div>
                    <ContentRow>
                        {/* map here results of tags search  */}
                        <CustomIconTagQuickFilterItem
                            id={i.name}
                            // isActive={true}
                            height="24px"
                        >
                            {i.name}
                        </CustomIconTagQuickFilterItem>
                    </ContentRow>
                </StyledOptionWrapper>
            )
        } else {
            return (
                <StyledOptionWrapper key={index} style={{ display: 'none' }} />
            )
        }
    })

    let mappedTagsData = labelsData.map((di: any, index: number) => {
        let i = di.item

        if (i) {
            return (
                <StyledOptionWrapper
                    className="custom-tag-filter-option-mobile"
                    value={i.name}
                    key={`${i.name}-tag-${index}`}
                >
                    <div className="custom-option-label-timeline-mobile">
                        Add label filter:
                    </div>
                    <ContentRow>
                        {/* map here results of tags search  */}
                        <CustomIconTagQuickFilterItem
                            isActive={true}
                            border={`1px solid ${colours[theme].border_muted}`}
                            height="25px"
                            bgcolor={colours[theme].background_default}
                            isLabel
                        >
                            {i.name}
                        </CustomIconTagQuickFilterItem>
                    </ContentRow>
                </StyledOptionWrapper>
            )
        } else {
            return (
                <StyledOptionWrapper key={index} style={{ display: 'none' }} />
            )
        }
    })

    const onSelectFunc: any = (optionValue: string): any => {
        addCriteria(optionValue)
        if (checkIfSelectedEntryOrLabel(optionValue)) {
            handleChange('')
        }
    }

    return (
        <Wrapper data-attr={dataCyId} id="custom_search_mobile_bar">
            <div>
                <SearchIconCustom
                    $issearchactive={
                        issearchactive ? issearchactive : undefined
                    }
                >
                    <SearchIcon
                        height={16}
                        isActive={
                            issearchactive !== undefined
                                ? issearchactive
                                : false
                        }
                    />
                </SearchIconCustom>
                <CustomisedAutocomplete
                    onSelect={onSelectFunc}
                    onFocus={() => {
                        manageissearchactive(true)
                    }}
                    defaultValue={currentSearch}
                    onInputKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleChange(value)
                            addCriteria(value)
                            finalSearchToApi()
                        }
                    }}
                    onSearch={(value: string) => {
                        handleChange(value)
                        fetchItem(value)
                        issearchactive === false && manageissearchactive(true)
                    }}
                    popupClassName="custom-search-timeline-bar-mobile"
                    $issearchactive={
                        issearchactive ? issearchactive : undefined
                    }
                    value={value[0]}
                >
                    {categoryData.length > 0 && mappedCategoryData}

                    {labelsData.length > 0 && mappedTagsData}

                    <StyledOptionWrapper
                        className="custom-searchfor-filter-option-mobile"
                        value={value[0]}
                        key={value[0]}
                    >
                        <div className="custom-option-label-timeline-mobile">
                            Search for:
                        </div>

                        <div style={{ color: colours[theme].text_strong }}>
                            {value[0]}
                        </div>
                    </StyledOptionWrapper>
                </CustomisedAutocomplete>{' '}
            </div>
            <GreyCross
                grey_cross={(value[0] || currentSearch) && grey_cross}
                $issearchactive={issearchactive ? issearchactive : undefined}
                onClick={() => {
                    handleChange('')
                    props.clearEntryTitleSearch()
                }}
            >
                <CrossIcon color={colours[theme].text_muted} />
            </GreyCross>
        </Wrapper>
    )
}
