import { getEmbedIDFromVideoURL } from '../../../helpers/textTruncate/videoUrlTruncate'

type Props = {
    embedURL?: string
}

const ShowroomVideoEmbedDesktop = (props: Props) => {
    let videoURL =
        props.embedURL &&
        props.embedURL !== '' &&
        getEmbedIDFromVideoURL(props.embedURL)
    return (
        <>
            {videoURL && (
                <div className="video-responsive">
                    <iframe
                        width="853"
                        height="480"
                        src={videoURL}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Video"
                    />
                </div>
            )}
        </>
    )
}

export default ShowroomVideoEmbedDesktop
