import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import GoBackTopButtonDesktop from '../../../../atoms/Button/goBackTopButtonDesktop'
import SquareIconBtn from '../../../../atoms/Button/squareIconBtn'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { showroomActions } from '../../../../../redux/showroom/reducer'
import { useHistory } from 'react-router'
import { Tooltip } from 'antd'
import { useState } from 'react'
import { customNavDataActions } from '../../../../../redux/localdata/customNav/reducer'
import posthog from 'posthog-js'
import LeftAlignLogo from '../../../../atoms/icons/components/leftAlignLogo'

type Props = {
    isReadOnly: boolean
    onClickEnquire?: any
    entryID: string
    is_watched: boolean
    carID?: string
    onClickShare?: any
    isSold?: boolean
    isOwner?: boolean
    goBack?: () => any
    onClose?: () => any
}

// container for action button
const Actions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`

const CenteredIcon = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`

const ShowroomCarTopBarDesktop = (props: Props) => {
    let dispatch: any = useAppDispatch()
    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let history = useHistory()

    const [showTooltip, setIsShowTooltip] = useState<boolean>(false)
    return (
        <>
            <GoBackTopButtonDesktop
                carID={props.carID}
                entryID={props.entryID}
                isReadOnly={props.isReadOnly}
                isSold={props.isSold}
                goBack={props.goBack}
                onClose={props.onClose}
            />

            <CenteredIcon>
                <LeftAlignLogo size={30} />
            </CenteredIcon>

            <Actions>
                {props.isSold && !props.is_watched ? null : (
                    <Tooltip
                        title={
                            !props.is_watched
                                ? 'Add to Watch List'
                                : 'Remove fom Watch List'
                        }
                        open={showTooltip}
                        placement="bottom"
                    >
                        <div
                            onMouseEnter={() => {
                                setIsShowTooltip(true)
                            }}
                            onMouseLeave={() => {
                                setIsShowTooltip(false)
                            }}
                        >
                            <SquareIconBtn
                                isActive={
                                    props.isReadOnly ? props.is_watched : false
                                }
                                icon="watchlist"
                                isDisabled={props.isReadOnly ? false : true}
                                onClick={() => {
                                    if (!userLoggedIn) {
                                        history.push('/login')
                                        dispatch(
                                            customNavDataActions.setInitialLandingUrl(
                                                `/showroom/${props.entryID}`
                                            )
                                        )
                                        posthog.capture(
                                            'toggle_showroom_entry_to_watchlist click from feed - user not logged in'
                                        )
                                    } else if (props.is_watched === true) {
                                        dispatch(
                                            showroomActions.removeEntryFromWatchlistRequest(
                                                props.entryID
                                            )
                                        )
                                        posthog.capture(
                                            'remove_showroom_entry_to_watchlist click from entry'
                                        )
                                    } else if (!props.is_watched) {
                                        dispatch(
                                            showroomActions.addEntryToWatchlistRequest(
                                                props.entryID
                                            )
                                        )
                                        posthog.capture(
                                            'add_showroom_entry_to_watchlist click from entry'
                                        )
                                    }
                                }}
                            />
                        </div>
                    </Tooltip>
                )}

                <ButtonAtom
                    theme="capitalize-white-background"
                    width="85px"
                    height="48px"
                    dataCyId={'showroom-car-share'}
                    icon={''}
                    disabled={props.isReadOnly ? false : true}
                    onClick={() => {
                        props.onClickShare && props.onClickShare()
                    }}
                >
                    share
                </ButtonAtom>

                {!props.isSold && (
                    <ButtonAtom
                        theme="secondary"
                        width="95px"
                        height="48px"
                        textTransform="capitalize"
                        dataCyId={'showroom-car-enquire'}
                        onClick={() => {
                            if (!userLoggedIn) {
                                history.push('/login')
                                dispatch(
                                    customNavDataActions.setInitialLandingUrl(
                                        `/showroom/${props.entryID}/enquiry`
                                    )
                                )
                            } else {
                                props.onClickEnquire && props.onClickEnquire()
                            }
                        }}
                        disabled={
                            props.isReadOnly && !props.isOwner ? false : true
                        }
                    >
                        enquire
                    </ButtonAtom>
                )}
            </Actions>
        </>
    )
}

export default ShowroomCarTopBarDesktop
