import { customHeader } from '../headers'
import * as urls from '../urls'
import { apiSentryErrorHandler } from '../errorHandler'

export const publishInsuranceApplication = (p: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.insurance_applications_publish(p), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                return response.json().then((data: any) => {
                    if (data) {
                        apiSentryErrorHandler(
                            data,
                            'Publish insurance apli err',
                            data
                        )
                        return Promise.reject(data)
                    } else {
                        return Promise.reject(
                            response.status === 503 ? data : response
                        )
                    }
                })
            }
        })
        .catch((e: any) => {
            return Promise.reject(e)
        })
}
