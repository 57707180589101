import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import greySearch from '../../../public/assets/icons/grey_thin_search.svg'
import sort_by_created_green from '../../../public/assets/icons/sort_by_created_green.svg'
import sort_alphabetical_green from '../../../public/assets/icons/sort_alphabetical_green.svg'
import sort_by_updated_green from '../../../public/assets/icons/sort_by_updated_green.svg'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import FilterIcon from '../icons/components/filterIcon'

type IStyle = {
    $theme: ITheme
    $isMobile?: boolean
    $isActive?: boolean
    $isDisabled?: boolean
}

const SearchIconWrapper = styled.div<IStyle>`
    width: ${(props) => (props.$isMobile ? '30px' : '34px')};
    height: ${(props) => (props.$isMobile ? '30px' : '34px')};
    cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_soft};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: ${(props) =>
        props.$isActive
            ? `1px solid ${colours[props.$theme].primary_30}`
            : `1px solid ${colours[props.$theme].border_muted_subtle}`};
    opacity: ${(props) => (props.$isDisabled ? 0.6 : 1)};
`

const Icon = styled(motion.img)<IStyle>`
    width: ${(props) => (props.$isMobile ? '12px' : '15px')};
`

type Props = {
    onClick: any
    isMobile?: boolean
    variant?: 'search' | 'filter' | 'sort'
    isActive?: boolean
    isDisabled?: boolean
    sortingCriteria?:
        | 'updated_at'
        | 'alphabetical'
        | 'created_at'
        | 'by_date_added'
}

const GreyIconButton: React.FunctionComponent<Props> = (props: Props) => {
    let { onClick, isMobile, variant, isActive, sortingCriteria, isDisabled } =
        props

    const { theme } = useThemes()

    return (
        <SearchIconWrapper
            $theme={theme}
            $isActive={isActive}
            onClick={isDisabled ? () => {} : onClick}
            $isMobile={isMobile}
            $isDisabled={isDisabled}
        >
            {variant === 'filter' ? (
                <FilterIcon
                    size="14"
                    color={
                        isActive
                            ? colours[theme].primary
                            : colours[theme].text_muted
                    }
                />
            ) : (
                <Icon
                    $theme={theme}
                    src={
                        variant === 'sort' &&
                        (sortingCriteria === 'created_at' ||
                            sortingCriteria === 'by_date_added')
                            ? sort_by_created_green
                            : variant === 'sort' &&
                              sortingCriteria === 'updated_at'
                            ? sort_by_updated_green
                            : variant === 'sort' &&
                              sortingCriteria === 'alphabetical'
                            ? sort_alphabetical_green
                            : greySearch
                    }
                    style={{
                        height:
                            sortingCriteria === 'alphabetical'
                                ? '15px'
                                : 'auto',
                    }}
                    alt={variant === 'sort' ? 'sort icon' : 'search icon'}
                    whileTap={{ scale: isDisabled ? 1 : 1.5 }}
                    $isMobile={isMobile}
                />
            )}
        </SearchIconWrapper>
    )
}

export default GreyIconButton
