import { IGalleryImage, IGalleryImagesObject } from 'entityModels'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useParams, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import ReturnNav from '../../../atoms/header/returnNav'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import ModalDisplayGalleryImageDesktop from '../../../templates/displays/pageWrappers/modalDisplayGalleryImageDesktop'
import FullPageWrapper from '../../../templates/displays/pageWrappers/FullPagePageWrapper'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'
import { showroomActions } from '../../../../redux/showroom/reducer'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import ShowroomGallerySlide from '../../../molecules/slideshow/showroomGallerySlide'
import GalleryImageCarousel from '../../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import ScrollLock from 'react-scrolllock'

type EntryParams = {
    entryid: string
}

const ShowroomCarGalleryImage: React.FunctionComponent<RouteComponentProps> = (
    props: RouteComponentProps
) => {
    const { entryid } = useParams<EntryParams>()

    const [image_index, setImage_index] = useState<undefined | number>(
        undefined
    )

    const params = new URLSearchParams(props.location.search)
    let image_id_q_param = params.get('id')

    // redux hook for dispatch state actions
    const dispatch = useAppDispatch()
    /* 
    remove top sub menu from state
    TODO: (only for useSelector hook) need to gradually move to "createSelector" approach, for memoization / huge performance impact
    */
    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let normalisedPublicEntries: INormalisedShowroomEntries | undefined =
        useAppSelector((state) => {
            return state.showroom.normalisedPublicEntries
        })

    let isNecessaryGalleryDataLoading = useAppSelector(
        (state) => state.showroom.loading
    )

    let currentEntry: IShowroomEntry | undefined =
        entryid && normalisedPublicEntries && normalisedPublicEntries[entryid]
            ? normalisedPublicEntries[entryid]
            : undefined

    // let carProfile: ICar | undefined = currentEntry
    //     ? currentEntry.car
    //     : undefined

    useEffect(() => {
        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (userLoggedIn) {
            dispatch(
                showroomActions.getShowroomEntryByIDRequest({
                    is_owner: false,
                    entryID: entryid,
                })
            )
        } else if (!userLoggedIn && refresh_token !== null) {
            dispatch(getCurrentUserDataRequest())
            dispatch(
                showroomActions.getShowroomEntryByIDRequest({
                    is_owner: false,
                    entryID: entryid,
                })
            )
        } else {
            dispatch(showroomActions.getPublicShowroomEntryByIDRequest(entryid))
        }

        // because ModalDisplayGalleryImageDesktop manages removing top menu (and on unmount it sets top menu back on) - reused throughout other galleries (car, history file)
        // on leaving showroom entry image page,
        // we need to keep the top menu removed as we're showing showroom entry gallery page
        // which has to have top menu removed as well
        return () => {
            removeTopSubMenu({ isRemoved: true })
        }
    }, [])

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let carGalleryImages: IGalleryImage[] =
        currentEntry && currentEntry.gallery_images
            ? currentEntry.gallery_images
            : []

    let images_ids: string[] = carGalleryImages.map((image) => image.id)

    const generateImageNavigationUrl = (next: string) => {
        if (entryid && image_id_q_param) {
            const index_of_id = images_ids.indexOf(image_id_q_param)

            if (next === 'next') {
                const index_of_next_image = index_of_id + 1

                if (index_of_next_image === -1) {
                    return '/none'
                }

                const id_of_next_image = images_ids[index_of_next_image]

                if (id_of_next_image !== undefined) {
                    return `/showroom/${entryid}/gallery?id=${id_of_next_image}`
                } else return '/none'
            } else {
                const index_of_previous_image = index_of_id - 1

                if (index_of_previous_image === -1) {
                    return '/none'
                }

                const id_of_previous_image = images_ids[index_of_previous_image]

                return `/showroom/${entryid}/gallery?id=${id_of_previous_image}`
            }
        } else {
            return '/none'
        }
    }

    let prev_url = generateImageNavigationUrl('previous')
    let next_url = generateImageNavigationUrl('next')

    const setUpInitialIndex = () => {
        if (images_data && image_id_q_param && currentEntry && images_ids) {
            setImage_index(images_ids.indexOf(image_id_q_param))
        }
    }

    useEffect(() => {
        setUpInitialIndex()
    }, [currentEntry, images_data, userLoggedIn])

    return images_data && image_id_q_param ? (
        <>
            <ScrollLock isActive={true} />

            <DesktopDisplayOnly>
                <FullPageWrapper>
                    <ModalDisplayGalleryImageDesktop
                        toggle={() => {
                            props.history.push(props.location.pathname)
                        }}
                        isOpen={true}
                    >
                        {currentEntry && (
                            <ShowroomGallerySlide
                                closeGallery={() => {
                                    props.history.push(props.location.pathname)
                                }}
                                currentImageData={images_data[image_id_q_param]}
                                imagesData={images_data}
                                isSomethingLoading={
                                    isNecessaryGalleryDataLoading
                                }
                                prevUrl={prev_url}
                                nextUrl={next_url}
                            />
                        )}
                    </ModalDisplayGalleryImageDesktop>
                </FullPageWrapper>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <div
                    style={{
                        display: 'flex',
                        position: 'relative',
                        background: '#fafafa',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        height: '100vh',
                    }}
                >
                    <Link
                        to={{
                            pathname: `/showroom/${entryid}/gallery`,
                            state: { prevPath: props.match.url },
                        }}
                    >
                        <ReturnNav />
                    </Link>
                    {currentEntry !== undefined &&
                    image_id_q_param &&
                    images_data &&
                    images_data[image_id_q_param] &&
                    image_index !== undefined &&
                    currentEntry.car?.gallery?.images &&
                    images_ids ? (
                        <GalleryImageCarousel
                            indexOfInitialImage={image_index}
                            image_data={images_data}
                            images_ids={images_ids}
                            synchroniseActiveIndex={(n: number) => {
                                setImage_index(n)
                            }}
                            openActionsSheet={() => {}}
                            cover_id={currentEntry?.car?.gallery?.cover}
                            readOnlyMode={true}
                        />
                    ) : (
                        <SkeletonAbsolute isthingloading={true} />
                    )}
                </div>
            </IpadAndMobileDisplay>
        </>
    ) : (
        <div style={{ display: 'none' }} />
    )
}

export default withRouter(ShowroomCarGalleryImage)
