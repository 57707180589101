import styled from 'styled-components'
import { showroomActions } from '../../../../../redux/showroom/reducer'
import {
    IShowroomFiltersState,
    IFilterID,
    IShowroomFilterRadioOptionItem,
} from '../../../../../redux/showroom/types'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import RadioRowItem from '../../../../atoms/formField/radioRowItem'
import RangeRowFieldsMobile from '../../../../molecules/editOrCreateModeSingleFields/rangeRow/rangeFieldMobile'
import ShowroomFilterSearchDropdownMobile from '../../../../molecules/showroom/dropdowns/showroomFilterSearchDropdownMobile'

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
`

const Caption = styled.div`
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
`

const ContentWrapper = styled.div`
    transform: translateY(10px);
    padding-bottom: 10px;
`

const ShowroomFiltersSearchBodyMobile = () => {
    let filtersState: IShowroomFiltersState = useAppSelector((state) => {
        return state.showroom.editingFilters
    })

    let elemID: IFilterID | undefined = useAppSelector((state) => {
        return state.showroom.openedFilterID
    })

    let filterValidationError = useAppSelector(
        (state) => state.showroom.filterValidationError
    )

    const dispatch = useAppDispatch()

    const renderOptions = () => {
        return (
            <div
                style={{
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    width: '100%',
                }}
            >
                {
                    // @ts-ignore
                    filtersState[elemID].options &&
                        // @ts-ignore
                        filtersState[elemID].options!.map(
                            (
                                item: IShowroomFilterRadioOptionItem,
                                index: number
                            ) => {
                                return (
                                    <RadioRowItem
                                        name={item.id}
                                        text={item.name}
                                        onChange={(id: string) => {
                                            dispatch(
                                                showroomActions.setRadioSelect_filter(
                                                    {
                                                        // @ts-ignore
                                                        id: elemID,
                                                        item: item,
                                                    }
                                                )
                                            )
                                        }}
                                        checked={
                                            // @ts-ignore
                                            filtersState[elemID].value ===
                                            item.id
                                                ? true
                                                : false
                                        }
                                    />
                                )
                            }
                        )
                }
            </div>
        )
    }

    const renderRanges = () => {
        // if filtersState[elemID].type === ''
        return (
            <div style={{ width: '100%' }}>
                {elemID && (
                    <RangeRowFieldsMobile
                        filterid={elemID}
                        customWidth="90%"
                        valueFrom={
                            // @ts-ignore
                            filtersState[elemID].valueFrom
                                ? // @ts-ignore
                                  filtersState[elemID].valueFrom
                                : undefined
                        }
                        valueTo={
                            // @ts-ignore
                            filtersState[elemID].valueTo
                                ? // @ts-ignore
                                  filtersState[elemID].valueTo
                                : undefined
                        }
                        onChange={(
                            valueFrom: number | null | undefined,
                            valueTo: number | null | undefined
                        ) => {
                            dispatch(
                                showroomActions.setRange_filter({
                                    // @ts-ignore
                                    id: elemID,
                                    valueFrom: valueFrom,
                                    valueTo: valueTo,
                                })
                            )
                        }}
                        labelFrom={
                            elemID === 'price'
                                ? 'Price from'
                                : elemID === 'mileage'
                                ? 'Mileage from'
                                : 'from'
                        }
                        labelTo={
                            elemID === 'price'
                                ? 'Price to'
                                : elemID === 'mileage'
                                ? 'Mileage to'
                                : 'to'
                        }
                        rangeMin={
                            elemID === 'year' &&
                            filtersState[elemID].min_backend
                                ? filtersState[elemID].min_backend
                                : undefined
                        }
                        rangeMax={
                            elemID === 'year' &&
                            filtersState[elemID].max_backend
                                ? filtersState[elemID].max_backend
                                : undefined
                        }
                    />
                )}
            </div>
        )
    }

    let renderForm = () => {
        switch (elemID) {
            case 'year':
                return (
                    <ContentWrapper>
                        {renderRanges()}
                        <div style={{ paddingTop: '10px' }} />
                        <Caption style={{ fontFamily: 'Lato-Bold' }}>
                            {filtersState.year.caption}
                        </Caption>
                        <div style={{ paddingTop: '20px' }} />
                        {filtersState.year.options &&
                            filtersState.year.options.map(
                                (
                                    item: IShowroomFilterRadioOptionItem,
                                    index: number
                                ) => {
                                    return (
                                        <RadioRowItem
                                            name={item.id}
                                            text={item.name}
                                            onChange={(id: string) => {
                                                dispatch(
                                                    showroomActions.setRadioSelect_filter(
                                                        {
                                                            id: 'year',
                                                            item: item,
                                                        }
                                                    )
                                                )

                                                // if there's an year ranges validation error
                                                // we clean it up
                                                // if a radio option is selected

                                                if (
                                                    filterValidationError &&
                                                    filterValidationError[
                                                        'year'
                                                    ]
                                                ) {
                                                    dispatch(
                                                        showroomActions.setFilterValidationError(
                                                            {
                                                                id: 'year',
                                                                error: undefined,
                                                            }
                                                        )
                                                    )
                                                }
                                            }}
                                            checked={
                                                filtersState.year.value ===
                                                item.id
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )
                                }
                            )}
                    </ContentWrapper>
                )
            case 'type_of_sale':
                return renderOptions()

            case 'drive_side':
                return renderOptions()

            case 'mileage':
                return <ContentWrapper>{renderRanges()}</ContentWrapper>
            case 'make':
                return (
                    <ShowroomFilterSearchDropdownMobile
                        name={'make'}
                        placeholder="Search make(s)"
                    />
                )
            case 'model':
                return (
                    <ShowroomFilterSearchDropdownMobile
                        name={'model'}
                        placeholder="Search model(s)"
                    />
                )
            case 'body_type':
                return (
                    <ShowroomFilterSearchDropdownMobile
                        name={'body type'}
                        placeholder="Search body type(s)"
                    />
                )
            case 'location':
                return (
                    <ShowroomFilterSearchDropdownMobile
                        name={'location'}
                        placeholder="Search location(s)"
                        customIsCheckedFunction={(id: string) => {
                            if (filtersState.location.values?.lat === id) {
                                return true
                            } else return false
                        }}
                        // customOnChangeFunction={(idSelected: string) => {
                        //     dispatch(
                        //         showroomActions.setLocation_filter({
                        //             id: 'lat',
                        //             value: idSelected,
                        //         })
                        //     )
                        // }}
                        type="single"
                    />
                )
            case 'colour':
                return (
                    <ShowroomFilterSearchDropdownMobile
                        name={'colour'}
                        customIsCheckedFunction={(idpassed: string) => {
                            if (
                                filtersState.colour &&
                                filtersState.colour?.values &&
                                filtersState.colour?.values.indexOf(
                                    idpassed
                                ) === -1
                            ) {
                                return false
                            } else return true
                        }}
                        placeholder="Search colour(s)"
                    />
                )

            default:
                return <div>default form</div>
        }
    }

    return <Container>{renderForm()}</Container>
}

export default ShowroomFiltersSearchBodyMobile
