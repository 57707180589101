import styled from 'styled-components'
import DesktopDisplayOnly from '../../../displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../displays/ipadAndMobileDisplay'

type Props = {
    isMobile?: boolean
}

type StyleProps = {
    $isMobile?: boolean
}

// parent element
const Container = styled.div<StyleProps>`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    display: flex;
    align-items: center;
    height: 5rem;
    background-color: var(--bg-color, #fff);
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    transition: all 100ms;
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    font-family: Lato;
`

const DescriptionImageShowroomEditTopBar = (props: Props) => {
    return (
        <>
            <DesktopDisplayOnly>
                <Container>
                    <div
                        style={{
                            fontFamily: 'Lato-Bold',
                            fontSize: '16px',
                            color: 'var(--text-strong, #1a1a1a)',
                        }}
                    >
                        Select description image
                    </div>
                </Container>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <Container>
                    <div
                        style={{
                            fontFamily: 'Lato-Bold',
                            fontSize: '16px',
                            color: 'var(--text-strong, #1a1a1a)',
                        }}
                    >
                        Select description image
                    </div>
                </Container>
            </IpadAndMobileDisplay>
        </>
    )
}

export default DescriptionImageShowroomEditTopBar
