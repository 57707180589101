import {
    IPrivateSharesActiveDisplayArgs,
    IDropdownItem,
    // IShareItemsData,
} from 'entityModels'

// export const mockSharedItems: IShareItemsData = {
//     car_share_001: {
//         id: 'car_share_001',
//         title: 'Direct share #1',
//         active_permission_ids: ['history file'],
//         created_at: '',
//         updated_at: '',
//         userIds: ['user001'],
//     },
//     car_share_002: {
//         id: 'car_share_002',
//         title: 'Direct share #2',
//         active_permission_ids: [
//             'history file',
//             'entry attachments',
//             'entry costs',
//             'entry cost attachments',
//         ],
//         created_at: '',
//         updated_at: '',
//         userIds: ['user001', 'user002'],
//     },
// }

export const defaultActiveCarSharedItemsDisplay: IPrivateSharesActiveDisplayArgs =
    {
        activeSort: 'created_at',
        activeFilter: [],
        activeSearchQuery: '',
        item_ids_display: [],
    }

export const defaultActiveGarageSharedItemsDisplay: IPrivateSharesActiveDisplayArgs =
    {
        activeSort: 'created_at',
        activeFilter: [],
        activeSearchQuery: '',
        item_ids_display: [],
    }

export const sharing_permissions_ids_selectable_list_mock: IDropdownItem[] = [
    {
        uid: 'history_file',
        name: 'History file',
        type: 'permission',
    },
    {
        uid: 'entry_attachments',
        name: 'Entry attachments',
        type: 'permission',
    },

    {
        uid: 'entry_costs',
        name: 'Entry costs',
        type: 'permission',
    },
    {
        uid: 'entry_cost_attachments',
        name: 'Entry cost attachments',
        type: 'permission',
    },
]
