import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
// import * as Sentry from "@sentry/react";
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
// import from module created in types.d.ts gets imported
// utils
// material for the store:
import rootReducer from './root-reducer'
import thunk from 'redux-thunk'
import rootSaga from './root-saga'

// this is a decent way to check if you are in standalone mode
const isStandalone = window.matchMedia('(display-mode: standalone)').matches

export const history = isStandalone
    ? // hash history is required for standalone mode
      createBrowserHistory()
    : // Browser history for non standalone mode is fine
      createBrowserHistory()

// onfigure middlewares

const sagaMiddleware = createSagaMiddleware()
const appRouterMiddleware = routerMiddleware(history)
const middlewares = [appRouterMiddleware, thunk, sagaMiddleware]

// const store = createStore(rootReducer(history), initialState, enhancer)

const store = configureStore({
    reducer: rootReducer(history),
    middleware: middlewares,
})

sagaMiddleware.run(rootSaga)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type IRootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
