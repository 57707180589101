import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
    ICarsState,
    IExternalCarDataRequestPayload,
    IReduxError,
} from 'entityModels'
import { getExternalCarDataByRegistrationNumberRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { checkIfPwaStandalone } from '../pwaCustom/checkIpadPwa'
import { IUser } from 'myModels'
import { MaintenanceScreen } from './maintenance'
import BackEndError from './backEndError'

function mapStateToProps(state: RootState) {
    return {
        user: state.user.userLoggedIn,
        userError: state.user.error,
        garageError: state.entities.garagesData.error,
        carError: state.entities.carsData.error,
        imageError: state.entities.galleriesData.error,
        highlightedFactsError: state.entities.highlightedFactsData.error,
        technicalInformationError:
            state.entities.technicalInformationData.error,
        dropDownDataError: state.localdata.dropdownData.error,
        fileStorageError: state.fileStorage.error,
        tasksError: state.tasks.error,
        // editFormsError: state.editForms.error,
        // menuError: state.menus.error,
        timelineError: state.timeline.error,
        apexError: state.apex.error,
        showroomError: state.showroom.error,
        currentcarID: state.entities.carsData.currentCarID,
        carsData: state.entities.carsData,
        insuranceError: state.insuranceQuoteApplication.error,
    }
}

const dispatchProps = {
    getExternalCarDataByRegistrationNumberRequest: (
        payload: IExternalCarDataRequestPayload
    ) => getExternalCarDataByRegistrationNumberRequest(payload),
}

interface Props extends RouteComponentProps<any> {
    user: IUser | null
    userError: IReduxError | null
    garageError: IReduxError | null
    carError: IReduxError | null
    imageError: IReduxError | null
    highlightedFactsError: IReduxError | null
    technicalInformationError: IReduxError | null
    dropDownDataError: IReduxError | null
    fileStorageError: IReduxError | null
    tasksError: IReduxError | null
    timelineError: IReduxError | null
    apexError: IReduxError | null
    showroomError: IReduxError | null
    getExternalCarDataByRegistrationNumberRequest: any
    currentcarID: string | undefined
    carsData: ICarsState
    insuranceError: IReduxError | null
    isOnline: boolean
    location: any
}

export interface PlatformErrorsState {
    showErrorMessage: boolean
    error: IReduxError | null
    errorStack: {
        offline: IReduxError | null
        userError: IReduxError | null
        garageError: IReduxError | null
        carError: IReduxError | null
        imageError: IReduxError | null
        highlightedFactsError: IReduxError | null
        technicalInformationError: IReduxError | null
        dropDownDataError: IReduxError | null
        fileStorageError: IReduxError | null
        tasksError: IReduxError | null
        timelineError: IReduxError | null
        appexError: IReduxError | null
        showroomError: IReduxError | null
        insuranceError: IReduxError | null
    }
}

// else "/garage"

// and then youd do : newUrl = onboardingUrlObject[index - 1]

class PlatformErrorManager extends React.Component<Props, PlatformErrorsState> {
    state = {
        showErrorMessage: false,
        error: {
            default: undefined,
            status_code: undefined,
            custom: {
                custom_message: '',
                custom_message_detail: '',
                custom_user_action_text: undefined,
                custom_user_action_func: undefined,
                custom_redirect_path: '',
            },
            entity_error: undefined,
            is_permission_denied: false,
        },
        errorStack: {
            offline: null,
            userError: null,
            garageError: null,
            carError: null,
            imageError: null,
            highlightedFactsError: null,
            technicalInformationError: null,
            dropDownDataError: null,
            fileStorageError: null,
            tasksError: null,
            timelineError: null,
            appexError: null,
            showroomError: null,
            insuranceError: null,
        },
    }

    // getOnboardingData(params: URLSearchParams) {
    //     let current_step_index: string | null = params.get('id')
    //     let previous_step_index: string | null = current_step_index
    //         ? (parseInt(current_step_index) - 1).toString()
    //         : null
    //     return {
    //         current_step_index: current_step_index,
    //         previous_step_index: previous_step_index,
    //     }
    // }

    generateTextError(error: IReduxError): {
        text: string
        detail?: string | undefined
    } {
        // const params = new URLSearchParams(this.props?.location.search)
        // let onboardingData = this.getOnboardingData(params)
        // if (params.get('onboarding') === 'true') {
        //     if (
        //         onboardingData.previous_step_index &&
        //         ['3', '4', '5'].includes(onboardingData.previous_step_index)
        //     ) {
        //         if (this.props.currentcarID) {
        //             if (onboardingData.current_step_index === '3') {
        //                 return { text: `Something went wrong.` }
        //             }
        //             return {
        //                 text: `Something went wrong.`,
        //                 detail: `Let's return to the previous step.`,
        //             }
        //         } else return { text: `Something went wrong` }
        //     }
        //     return {
        //         text: `Something went wrong.`,
        //         detail: `Let's return to the previous step.`,
        //     }
        // }
        if (error && error.default === Error) {
            return {
                text: 'Looks like your internet has stoped working.',
                detail: 'Please check your connection and try again.',
            }
        }
        if (error && error.custom && error.custom.custom_message) {
            return {
                text: error.custom.custom_message,
                detail: error.custom.custom_message_detail,
            }
        } else
            return {
                text: 'Something went wrong',
                detail: 'Please check your internet connection and try again.',
            }
    }

    generateRedirect(error: IReduxError): string {
        if (
            error &&
            error.custom &&
            error.custom.custom_redirect_path &&
            error.custom.custom_redirect_path !== ''
        ) {
            return error.custom.custom_redirect_path
        } else {
            return this.props?.history?.location?.search
                ? `${this.props.history.location.pathname}${this.props.history.location.search}`
                : this.props?.history?.location.pathname
        }
    }

    generateCustomUserActionText(error: IReduxError) {
        // const params = new URLSearchParams(this.props.location.search)
        // let onboardingData = this.getOnboardingData(params)
        // if (params.get('onboarding') === 'true') {
        //     if (
        //         onboardingData.previous_step_index &&
        //         ['3', '4', '5'].includes(onboardingData.previous_step_index)
        //     ) {
        //         if (this.props.currentcarID) {
        //             if (onboardingData.current_step_index === '3') {
        //                 return `Let's try again`
        //             }
        //             return `Go back`
        //         } else return `Let's try again`
        //     }
        //     return 'Go to previous step'
        // }
        if (error && error.default === Error) {
            return 'Refresh page'
        }
        if (error && error.custom && error.custom.custom_user_action_text) {
            return error.custom.custom_user_action_text
        } else {
            return 'Refresh page'
        }
    }

    // generateCustomActionFunc(error: IReduxError) {
    //     if (error.custom && error.custom.custom_user_action_func) {
    //         return error.custom.custom_user_action_func
    //     } else {
    //         return () => window.location.reload()
    //     }
    // }

    checkIfInSafariOrChromeBrowser = () => {
        const ua = window.navigator.userAgent
        let chromeAgent = ua.indexOf('Chrome') > -1
        let safariAgent = ua.indexOf('Safari') > -1
        return chromeAgent || safariAgent
    }

    handleSafariOrChromeNOffline = () => {
        if (!this.props.isOnline) {
            let offlineError: IReduxError = {
                default: { status: '500' },
                custom: {
                    custom_message:
                        'Looks like your are offline. Connect to the internet and try again.',
                    custom_user_action_text: `I'm connected`,
                    custom_redirect_path: this.props?.history?.location?.search
                        ? `${this.props.history.location.pathname}${this.props.history.location.search}`
                        : this.props?.history?.location?.pathname,
                },
            }
            this.setState({
                showErrorMessage: true,
                error: offlineError,
                errorStack: {
                    ...this.state?.errorStack,
                    offline: offlineError,
                },
            })
        } else {
            this.setState({ showErrorMessage: false })
        }
    }

    checkAndHandleAnyOtherError = () => {
        let anyOtherError: boolean =
            this.props?.userError ||
            this.props?.garageError ||
            this.props?.carError ||
            this.props?.imageError ||
            this.props?.highlightedFactsError ||
            this.props?.technicalInformationError ||
            this.props?.dropDownDataError ||
            this.props?.fileStorageError ||
            this.props?.tasksError ||
            this.props?.timelineError ||
            this.props?.apexError ||
            this.props?.showroomError ||
            this.props?.insuranceError
                ? true
                : false
        if (anyOtherError && this.state.errorStack) {
            this.setState({
                showErrorMessage: true,
                error:
                    (this.props?.userError && this.props.userError) ||
                    (this.props?.garageError && this.props.garageError) ||
                    (this.props?.carError && this.props.carError) ||
                    (this.props?.imageError && this.props.imageError) ||
                    (this.props?.highlightedFactsError &&
                        this.props.highlightedFactsError) ||
                    (this.props?.technicalInformationError &&
                        this.props.technicalInformationError) ||
                    (this.props?.dropDownDataError &&
                        this.props.dropDownDataError) ||
                    (this.props?.fileStorageError &&
                        this.props?.fileStorageError) ||
                    (this.props?.tasksError && this.props.tasksError) ||
                    (this.props?.timelineError && this.props.timelineError) ||
                    (this.props?.apexError && this.props.apexError) ||
                    (this.props?.showroomError && this.props.showroomError) ||
                    (this.props?.insuranceError && this.props.insuranceError),
                errorStack: {
                    ...this.state.errorStack,
                    userError: this.props?.userError,
                    garageError: this.props?.garageError,
                    carError: this.props?.carError,
                    imageError: this.props?.imageError,
                    highlightedFactsError: this.props?.highlightedFactsError,
                    technicalInformationError:
                        this.props?.technicalInformationError,
                    dropDownDataError: this.props?.dropDownDataError,
                    fileStorageError: this.props?.fileStorageError,
                    tasksError: this.props?.tasksError,
                    timelineError: this.props?.timelineError,
                    appexError: this.props?.apexError,
                    showroomError: this.props?.showroomError,
                    insuranceError: this.props?.insuranceError,
                },
            })
        } else {
            this.setState({ showErrorMessage: false })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props?.isOnline !== prevProps.isOnline &&
            this.checkIfInSafariOrChromeBrowser() &&
            !this.props?.isOnline
        ) {
            this.handleSafariOrChromeNOffline()
        } else if (
            // this.props.isOnline &&
            this.props?.userError !== prevProps.userError ||
            this.props?.garageError !== prevProps.garageError ||
            this.props?.carError !== prevProps.carError ||
            this.props?.imageError !== prevProps.imageError ||
            this.props?.highlightedFactsError !==
                prevProps.highlightedFactsError ||
            this.props?.technicalInformationError !==
                prevProps.technicalInformationError ||
            this.props?.dropDownDataError !== prevProps.dropDownDataError ||
            this.props?.fileStorageError !== prevProps.fileStorageError ||
            this.props?.tasksError !== prevProps.tasksError ||
            this.props?.timelineError !== prevProps.timelineError ||
            this.props?.apexError !== prevProps.apexError ||
            this.props?.showroomError !== prevProps.showroomError ||
            this.props?.insuranceError !== prevProps.insuranceError
        ) {
            this.checkAndHandleAnyOtherError()
        }
    }

    handleUserAction = () => {
        if (this.checkIfInSafariOrChromeBrowser() && !window.navigator.onLine) {
            this.setState({
                error: {
                    ...this.state.error,
                    custom: {
                        custom_message: 'It looks like you are still offline',
                        custom_user_action_text: 'Try now',
                    },
                },
            })
        } else {
            this.setState({
                showErrorMessage: false,
                error: null,
                errorStack: {
                    // ...this.state.errorStack,
                    userError: null,
                    garageError: null,
                    carError: null,
                    imageError: null,
                    highlightedFactsError: null,
                    technicalInformationError: null,
                    dropDownDataError: null,
                    fileStorageError: null,
                    tasksError: null,
                    timelineError: null,
                    appexError: null,
                    showroomError: null,
                    insuranceError: null,
                    offline: null,
                },
            })
        }
    }

    resetErrorState = () => {
        this.setState({
            showErrorMessage: false,
            error: null,
            errorStack: {
                // ...this.state.errorStack,
                userError: null,
                garageError: null,
                carError: null,
                imageError: null,
                highlightedFactsError: null,
                technicalInformationError: null,
                dropDownDataError: null,
                fileStorageError: null,
                tasksError: null,
                timelineError: null,
                appexError: null,
                showroomError: null,
                insuranceError: null,
                offline: null,
            },
        })
    }

    render() {
        let pathname = checkIfPwaStandalone()
            ? window.location.pathname
            : window?.location?.pathname

        let isResetPassPage =
            this.props?.history?.location?.pathname.match(/reset-password/g) !==
                null ||
            window?.location?.pathname?.match(/reset-password/g) !== null
                ? true
                : false

        return (
            <>
                {this.state.error?.status_code === 503 && (
                    <MaintenanceScreen error={this.state.error} />
                )}

                {this.state.error?.status_code !== 503 &&
                    pathname !== '/' &&
                    pathname !== '/login' &&
                    pathname !== '/signin' &&
                    pathname !== '/register' &&
                    pathname !== '/account-deletion-confirmed' &&
                    isResetPassPage === false && (
                        <BackEndError
                            resetErrorState={this.resetErrorState}
                            generateRedirect={this.generateRedirect}
                            handleUserAction={this.handleUserAction}
                            generateTextError={this.generateTextError}
                            generateCustomUserActionText={
                                this.generateCustomUserActionText
                            }
                            isOnline={this.props.isOnline}
                            platform_errors_state={this.state}
                        />
                    )}
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(PlatformErrorManager)
)
