export const get_check_cookie_name = (name: string): string | undefined => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
    if (match) {
        const c1 = match[2]
        if (!c1 || (c1 && c1.length < 0)) {
            return undefined
        } else return c1
    } else {
        return undefined
    }
}

export type ICookieSetArg = {
    name: string
    value: string
    days: number
}

export const setCookie = (p: ICookieSetArg) => {
    var expires = ''
    if (p.days) {
        var date = new Date()
        date.setTime(date.getTime() + p.days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toUTCString()
    }
    document.cookie = p.name + '=' + (p.value || '') + expires + '; path=/'
}

export const generateFBC = (fbclid: string, time: number): string => {
    const version = 'fb'
    const subdomainIndex = 1
    return `${version}.${subdomainIndex}.${time}.${fbclid}`
}

export const generateFBP = (time: number): string => {
    const version = 'fb'
    const subdomainIndex = 1
    let randomN = Math.floor(100000000 + Math.random() * 900000000)
    return `${version}.${subdomainIndex}.${time}.${randomN}`
}
