export function logIdentity<T>(elem: T): T {
  return elem;
}

// type valuesof<T> = T[keyof T]

type GenericObject = { [key: string]: any };

export const removeUndefinedfromObject = (
  obj: GenericObject
): GenericObject => {
  Object.keys(obj).forEach(key => obj[key] === undefined && delete obj[key]);
  return obj;
};
