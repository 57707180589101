import { IApiEntryCostDELETE_args } from 'ApiInterfaces'
import { customHeader } from '../../headers'
import * as urls from '../../urls'
import { apiSentryErrorHandler } from '../../errorHandler'

export const deleteEntryCost = (p: IApiEntryCostDELETE_args): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.delete_entry_cost_endpoint(p), requestOptions)
        .then((response: any) => {
            if (response.ok !== true) {
                return Promise.reject(response)
            }
        })
        .catch((e: any) => {
            apiSentryErrorHandler(e, 'Delete cost entry error')
            return Promise.reject(e)
        })
}
