import {
    ICar,
    IExternalCar,
    ITechInfoData,
    ITechnicalInformationObject,
} from 'entityModels'
import { IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import ListManagerDesktop from '../../../molecules/list/ListManagerDesktop'
import ListManagerMobile from '../../../molecules/list/listManagerMobile'
import SideSlider from '../../animated/sideSlider'
import DesktopDisplayOnly from '../../displays/desktopDisplayOnly'
import { device } from '../../displays/devices'
import IpadAndMobileDisplay from '../../displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../displays/pageWrappers/FullPagePageWrapper'
import FabsMenusMobile from '../../menus/fabsMenuMobile'

const Padding = styled.div`
    padding-right: 10px;
    padding-left: 10px;
`

const AdjustedPaddingTop = styled.div`
    padding-top: 45px;
    @media ${device.ipad} {
        padding-top: 73px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

interface Props extends RouteComponentProps<any> {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    userLoggedIn: IUser
    technical_information: ITechnicalInformationObject
    list: string[]
}

const dispatchProps = {}

type State = {
    placeholderUrl: string
}

function mapStateToProps(state: RootState) {
    return {
        technical_information:
            state.entities.technicalInformationData.technical_information,
        list: state.entities.technicalInformationData.list,
    }
}

class ReadOnlyCarTechnicalInformation extends React.Component<Props, State> {
    render() {
        let { list, technical_information, sharedCar, prevOwned } = this.props
        let current: IExternalCar | ICar | undefined = sharedCar
            ? sharedCar
            : prevOwned

        const default_fuel_unit_user: string | null =
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences.data['units'].data['fuel_unit']
                .user_choice_id

        const default_fuel_unit_user_text: string | null =
            default_fuel_unit_user &&
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences.data['units'].data['fuel_unit']
                .data[default_fuel_unit_user].text

        const default_fuel_unit_user_text_short: string | null | undefined =
            default_fuel_unit_user &&
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences.data['units'].data['fuel_unit']
                .data[default_fuel_unit_user].short

        const default_distance_unit_user: string | null =
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences.data['units'].data[
                'distance_unit'
            ].user_choice_id

        const default_distance_unit_user_text: string | null =
            default_distance_unit_user &&
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences.data['units'].data[
                'distance_unit'
            ].data[default_distance_unit_user].text

        const default_distance_unit_user_text_short: string | null | undefined =
            default_distance_unit_user &&
            this.props.userLoggedIn &&
            this.props.userLoggedIn.preferences.data['units'].data[
                'distance_unit'
            ].data[default_distance_unit_user].short

        let currentCarTechInfoId: string | undefined =
            current && current.id && `hf-${current.id}`

        let currentCarTechInfo: ITechInfoData | undefined =
            technical_information &&
            currentCarTechInfoId &&
            technical_information[currentCarTechInfoId]
                ? technical_information[currentCarTechInfoId]
                : undefined

        return (
            <React.Fragment>
                <FabsMenusMobile hasAdd={false} hasSubNav={true} />
                {current && technical_information && currentCarTechInfo && (
                    <FullPageWrapper>
                        <DesktopDisplayOnly>
                            {sharedCar && (
                                <div style={{ paddingTop: '50px' }} />
                            )}
                            <Padding>
                                {currentCarTechInfo !== undefined && (
                                    <>
                                        <ListManagerDesktop
                                            readOnlyMode
                                            data={currentCarTechInfo}
                                            list={list}
                                            default_fuel_unit_user_text={
                                                default_fuel_unit_user_text_short
                                                    ? default_fuel_unit_user_text_short
                                                    : default_fuel_unit_user_text
                                                    ? default_fuel_unit_user_text
                                                    : undefined
                                            }
                                            default_fuel_unit_user={
                                                default_fuel_unit_user
                                                    ? default_fuel_unit_user
                                                    : undefined
                                            }
                                            default_distance_unit_user_text={
                                                default_distance_unit_user_text_short
                                                    ? default_distance_unit_user_text_short
                                                    : default_distance_unit_user_text
                                                    ? default_distance_unit_user_text
                                                    : undefined
                                            }
                                            default_distance_unit_user={
                                                default_distance_unit_user
                                                    ? default_distance_unit_user
                                                    : undefined
                                            }
                                        />
                                    </>
                                )}
                            </Padding>
                        </DesktopDisplayOnly>
                        <IpadAndMobileDisplay>
                            <div
                                style={{
                                    zIndex: 3,
                                    backgroundColor: 'var(--bg-color, #fff)',
                                }}
                            >
                                <AdjustedPaddingTop />

                                <SideSlider direction="right" initial="enter">
                                    {currentCarTechInfo !== undefined && (
                                        <ListManagerMobile
                                            readOnlyMode
                                            data={currentCarTechInfo}
                                            list={list}
                                            default_fuel_unit_user_text={
                                                default_fuel_unit_user_text_short
                                                    ? default_fuel_unit_user_text_short
                                                    : default_fuel_unit_user_text
                                                    ? default_fuel_unit_user_text
                                                    : undefined
                                            }
                                            default_fuel_unit_user={
                                                default_fuel_unit_user
                                                    ? default_fuel_unit_user
                                                    : undefined
                                            }
                                            default_distance_unit_user_text={
                                                default_distance_unit_user_text_short
                                                    ? default_distance_unit_user_text_short
                                                    : default_distance_unit_user_text
                                                    ? default_distance_unit_user_text
                                                    : undefined
                                            }
                                            default_distance_unit_user={
                                                default_distance_unit_user
                                                    ? default_distance_unit_user
                                                    : undefined
                                            }
                                        />
                                    )}
                                </SideSlider>
                            </div>
                        </IpadAndMobileDisplay>
                    </FullPageWrapper>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ReadOnlyCarTechnicalInformation)
)
