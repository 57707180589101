import {
    ITechnicalInformationPayload,
    ITechnicalInformationFormPayload,
    ITechnicalInformationObject,
} from 'entityModels'

import {
    GET_TECHNICAL_INFORMATION_SUCCESS,
    WRITE_TECHNICAL_INFORMATION_SUCCESS,
    SET_TECHNICAL_INFORMATION_SUCCESS,
    CLEAN_UP_TECHNICAL_INFORMATION_SUCCESS,
} from './constants'

import { createAction } from '@reduxjs/toolkit'

// export const getTechnicalInformationSuccess = createAction(
//     GET_TECHNICAL_INFORMATION_SUCCESS,
//     (resolve) => (payload: ITechnicalInformationPayload) => resolve(payload)
// )

export const getTechnicalInformationSuccess = createAction(
    GET_TECHNICAL_INFORMATION_SUCCESS,
    function prepare(payload: ITechnicalInformationPayload) {
        return {
            payload,
        }
    }
)

// export const writeTechnicalInformationSuccess = createAction(
//     WRITE_TECHNICAL_INFORMATION_SUCCESS,
//     (resolve) => (payload: ITechnicalInformationFormPayload) => resolve(payload)
// )

export const writeTechnicalInformationSuccess = createAction(
    WRITE_TECHNICAL_INFORMATION_SUCCESS,
    function prepare(payload: ITechnicalInformationFormPayload) {
        return {
            payload,
        }
    }
)

// export const setTechnicalInformationSuccess = createAction(
//     SET_TECHNICAL_INFORMATION_SUCCESS,
//     (resolve) => (payload: ITechnicalInformationObject) => resolve(payload)
// )

export const setTechnicalInformationSuccess = createAction(
    SET_TECHNICAL_INFORMATION_SUCCESS,
    function prepare(payload: ITechnicalInformationObject) {
        return {
            payload,
        }
    }
)

export const cleanUpTechnicalInformationSuccess = createAction(
    CLEAN_UP_TECHNICAL_INFORMATION_SUCCESS
)
