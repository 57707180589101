import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import styled from 'styled-components'
import SelectGroup from '../../../molecules/formFieldsGroup/selectGroup'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { IHelpPageContact } from 'helpPageCopyModels'
import { selectContactReasonThunks } from '../../../../redux/localdata/help/actions/thunks'

const Wrap = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 150px;
`

const SmallPadding = styled.div`
    padding-top: 20px;
`
const SmallInLinePadding = styled.div`
    padding-right: 20px;
`
const Text = styled.div`
    font-family: 'Lato-light';
    font-size: 16px;
    color: var(--text-strong, #1a1a1a);
`

const InputSection = styled.div`
    display: flex;
    align-items: center;
`

const ContactDetails = styled.div`
    width: 100%;
    margin: 0px;
    font-family: 'Lato-bold';
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-strong, #1a1a1a);
    .contact {
        white-space: nowrap;
    }
`

const EmailsSection = styled.div`
    width: 100%;
`

const EmailText = styled.div`
    font-family: 'Lato-light';
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    padding-bottom: 6px;
`

const MailAddress = styled.a`
    color: var(--primary, #5ec3ca);
    font-family: 'Lato';
`

const dispatchProps = {
    selectContactReasonThunks: (value: string) =>
        selectContactReasonThunks(value),
}

function mapStateToProps(state: RootState) {}

interface Props {
    selectContactReasonThunks: (value: string) => void
    contactReasonsData: IHelpPageContact
}

class ContactFormDesktop extends React.Component<Props> {
    render() {
        const { contactReasonsData, selectContactReasonThunks } = this.props

        return (
            <Wrap>
                <Text>
                    Please select the main reason why you would like to get in
                    touch:
                </Text>
                <SmallPadding />
                <SmallPadding />

                <InputSection>
                    <SelectGroup
                        items={contactReasonsData.list}
                        initialValue={contactReasonsData.list[0]}
                        customHandleChange={selectContactReasonThunks}
                        width="350px"
                    />
                    <SmallInLinePadding />
                    <SmallInLinePadding />
                    <ButtonAtom
                        theme="wide-mobile-blue-bg"
                        width="200px"
                        height="41px"
                    >
                        <a
                            style={{ color: 'var(--bg-color, #fff)' }}
                            href={
                                'mailto:hello@custodian.club?subject=' +
                                contactReasonsData.list[
                                    parseInt(contactReasonsData.selected)
                                ].name
                            }
                            target="_blank"
                            rel="noreferrer"
                        >
                            Send an Email
                        </a>
                    </ButtonAtom>
                </InputSection>

                <SmallPadding />
                <SmallPadding />

                <ContactDetails>
                    <span className="contact">Contact us</span>
                </ContactDetails>
                <SmallPadding />
                <EmailsSection>
                    <EmailText>
                        General equiries -{' '}
                        <MailAddress
                            href={'mailto:hello@custodian.club'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            hello@custodian.club
                        </MailAddress>
                    </EmailText>
                    <EmailText>
                        Careers -{' '}
                        <MailAddress
                            href={'mailto:careers@custodian.club?'}
                            target="_blank"
                            rel="noreferrer"
                        >
                            careers@custodian.club
                        </MailAddress>
                    </EmailText>
                </EmailsSection>
            </Wrap>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(ContactFormDesktop)
