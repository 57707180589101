import React from 'react'
import { createPortal } from 'react-dom'

type Props = {
    customZindex?: number
    onClick?: any
}

export default class PortalInvisibleWrapper extends React.Component<
    Props,
    any
> {
    render() {
        return createPortal(
            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    right: 0,
                    zIndex: this.props.customZindex
                        ? this.props.customZindex
                        : 2,
                }}
            >
                {this.props.children}
            </div>,
            document.getElementById('modal_root') as HTMLElement
        )
    }
}
