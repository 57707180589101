import { Radio } from 'antd'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import Expander from '../../atoms/expander/expander'

type Props = {
    value: string | undefined
    options: {
        uid: string
        name: string
        [key: string]: string | undefined
    }[]
    onChange: (payload: string) => void
    isDisabled?: boolean
    customPrimaryColour?: string
    customPrimaryColour_04?: string
}

type IStyle = {
    $theme: ITheme
    $isActive?: boolean
    $customPrimaryColour?: string
    $customPrimaryColour_04?: string
}

const CustomRadioGroup = styled(Radio.Group)<IStyle>`
    .ant-radio-inner {
        background-color: transparent !important;
        border: ${(props) => `1px solid ${colours[props.$theme].text_default}`};
        height: 20px !important;
        width: 20px !important;
    }

    .ant-radio-inner::before {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        height: 14px !important;
        width: 14px !important;
    }

    .ant-radio-inner::after {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        transform: scale(0.7) !important;
    }

    .ant-radio-checked .ant-radio-inner {
        background-color: ${(props) =>
            `${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
        border: ${(props) =>
            `1px solid ${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${(props) =>
            `${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
    }
`

const Option = styled.button`
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    margin-top: 16px;
    border-radius: 8px;
    background-color: transparent;

    :hover {
        background-color: var(
            --base-overlays-neutral-alpha-100,
            rgba(26, 26, 26, 0.04)
        );
    }
`

const OptionText = styled.p`
    color: var(--text-default, #666);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Desktop/Text/T300 Regular */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 12px;
`

export const YesNoBox = styled(motion.div)<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 18px 16px 16px;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    transition: all 200ms;

    :hover {
        background-color: ${(props) =>
            `${
                props.$customPrimaryColour_04 ??
                colours[props.$theme].primary_04
            }`};
        border: ${(props) =>
            `1px solid ${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`};
    }

    ${(props) => `
    background: ${
        props.$isActive
            ? props.$customPrimaryColour_04 ?? colours[props.$theme].primary_04
            : 'transparent'
    };
    border: ${
        props.$isActive
            ? `1px solid ${
                  props.$customPrimaryColour ?? colours[props.$theme].primary
              }`
            : `1px solid ${colours[props.$theme].text_muted}`
    };`}

    cursor: pointer;
`

function OutlinedRadioInput({
    options,
    value,
    onChange,
    isDisabled,
    customPrimaryColour,
    customPrimaryColour_04,
}: Props) {
    const { theme } = useThemes()
    return (
        <CustomRadioGroup
            $customPrimaryColour={customPrimaryColour}
            $customPrimaryColour_04={customPrimaryColour_04}
            $theme={theme}
            value={value}
            style={{ width: '100%' }}
            disabled={isDisabled}
        >
            {options.map((option, index) => {
                return (
                    <Expander
                        key={`${option.uid}-${index}`}
                        height={option ? 'auto' : 0}
                    >
                        <Option
                            key={`${option.uid}-${index}-child`}
                            style={{
                                backgroundColor:
                                    value && value === option.uid
                                        ? customPrimaryColour_04 ??
                                          colours[theme].primary_04
                                        : undefined,
                                border:
                                    value && value === option.uid
                                        ? `1px solid ${
                                              customPrimaryColour ??
                                              colours[theme].primary
                                          }`
                                        : `1px solid var(--border-muted, #e5e5e5)`,
                            }}
                            onClick={() => {
                                onChange(option.uid)
                            }}
                        >
                            <Radio
                                id={option.uid}
                                type="radio"
                                value={option.uid}
                                name={option.name}
                                style={{ margin: 0 }}
                            />
                            <OptionText>{option.name}</OptionText>
                        </Option>
                    </Expander>
                )
            })}
        </CustomRadioGroup>
    )
}

export default OutlinedRadioInput
