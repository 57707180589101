import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowMobile from '../../buttons/buttonsStandardBottomRowMobile'
import MultipleSelectSearchBarCarInfoMobile from '../../technicalInformationEditForm/multipleselect/mobile/selectMultipleSearchBarCarInfoMobile'

import SwitchButton from '../../../../atoms/Button/switchButton'
import { OOtherTaskOptions } from '../../../../../ol/ol'
// import BlueBorderDateInputMobile from '../../../../atoms/blueBorderedInputs/blueBorderDateInput/blueBorderDateInputMobile'
import moment from 'moment'
import Expander from '../../../../atoms/expander/expander'
import Faded from '../../../../templates/animated/faded'
import useThemes from '../../../../../providers/theme/hooks'
import colours from '../../../../../providers/theme/colours'
// import TouchScrollable from 'react-scrolllock'

const EditFormContainerDesktop = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 7vh;
    height: 100vh;
    overflow-y: scroll;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    position: relative;
    animation: fadeIn 0.2s both;
    animation-delay: 0.15s;
    opacity: 1;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @keyframes fadeIn {
        from {
            opacity: 0.8;
        }

        to {
            opacity: 1;
        }
    }

    @media ${device.mobile} {
        padding-top: 5vh;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 25px;
    padding-right: 20px;
    @media ${device.ipad} {
        padding-left: 0px;
        padding-right: 0px;
    }
`

const FieldTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 15px;
    color: var(--text-strong, #1a1a1a);
`

const FormTitle = styled.div`
    font-family: Lato;
    align-self: start;
    font-size: 18px;
    letter-spacing: -1px;
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 24px;

    @media screen and (max-width: 325px) {
        padding-bottom: 15px;
        font-size: 18px;
    }
`
type IstyledToggleItem = {
    isActive?: boolean
}
const ToggleText = styled.div<IstyledToggleItem>`
    padding-left: 16px;
    text-transform: capitalize;
    font-family: lato;
    font-size: 14px;
    letter-spacing: 0px;
    transform: translateY(-3px);
    color: ${(props) =>
        props.isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--text-default, #666666)'};
    @media (max-width: 350px) {
        padding-left: 10px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
`

const ToggleWrapper = styled.div`
    width: 100%;
    max-width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
`

// const DueDateWrapper = styled.div`
//     width: 100%;
//     max-width: 80vw;
//     @media ${device.ipad} {
//         max-width: 250px;
//     }
// `
const ButtonsWrapper = styled.div`
    width: 100%;
    /* position: fixed;
    bottom: 0;
    bottom: env(safe-area-inset-bottom); */
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    padding-top: 40px;
    padding-bottom: 64px;
    @media ${device.ipad} {
        max-width: 600px;
    }
`

const HorizLine = styled.div`
    padding-top: 32px;
    width: 100%;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    @media ${device.mobile} {
        display: none;
    }
`
export const Error = styled.div`
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 11px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    title: string
    linkedToCar: string[]
    multiple_car_names_values_array?: string[] | undefined
    linkedToAll?: string
    due_date: string | undefined
}

interface OtherProps {
    toggle: any
    submitFunc: any
    dropdownData: any
    carsData: any
    garage_id: string
    currentCarID?: string | null
    garageCarsIds: string[]
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        setFieldValue,
        dropdownData,
        currentCarID,
        carsData,
        errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let checkIfDisabled = () => {
        if (!values.title) {
            return true
        } else if (errors.title !== undefined) {
            return true
        } else if (
            !values.multiple_car_names_values_array &&
            !values.linkedToAll
        ) {
            return true
        } else if (
            values.multiple_car_names_values_array &&
            values.multiple_car_names_values_array[0] === undefined &&
            !values.linkedToAll
        ) {
            return true
        } else return false
    }

    const handleDueDateAnswerChange = (date: string) => {
        setFieldValue('due_date', date)
    }

    const { theme } = useThemes()

    return (
        // <TouchScrollable>
        <EditFormContainerDesktop>
            <InnerFormPadding id="scrollable-content">
                <FormTitle>Create a Task</FormTitle>

                <FieldTitle>
                    What needs to get done?
                    <span style={{ color: colours[theme].text_default }}>
                        *
                    </span>
                </FieldTitle>
                <Expander
                    height={
                        values.title.length > 1 && errors.title ? 'auto' : 0
                    }
                >
                    <Faded>
                        <div style={{ paddingTop: '5px' }} />
                        <Error className="animated fadeIn slower">
                            {errors.title}
                        </Error>
                    </Faded>
                </Expander>

                <div style={{ paddingTop: '20px' }} />
                <InputField
                    theme={theme}
                    id="title"
                    name="title"
                    placeholder={'Task Title'}
                    helperText="[up to 70 characters]"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.title}
                    font_family="Lato"
                    type="text"
                    onEnterKeyPress={props.handleSubmit}
                />

                <div style={{ paddingTop: '30px' }} />

                <FieldTitle>
                    What does it apply to?
                    <span style={{ color: colours[theme].text_default }}>
                        *
                    </span>
                </FieldTitle>
                <div style={{ paddingTop: '5px' }} />
                <div
                    style={{
                        color: colours[theme].text_strong,
                        fontFamily: 'lato-light',
                        fontSize: '14px',
                    }}
                >
                    Search specific car(s) or select what applies:
                </div>
                <div style={{ paddingTop: '20px' }} />

                <MultipleSelectSearchBarCarInfoMobile
                    item={{
                        id: 'linkedToCar',
                        text: 'your cars',
                        answer: undefined,
                        answers:
                            currentCarID && carsData[currentCarID]
                                ? carsData[currentCarID].title
                                : undefined,
                    }}
                    items={dropdownData}
                    handleSelectChange={(name: string) =>
                        setFieldValue('multiple_car_names_values_array', name)
                    }
                    isDisabled={values.linkedToAll ? true : false}
                />

                <div style={{ paddingTop: '32px' }} />
                <ToggleWrapper>
                    <Row>
                        <SwitchButton
                            checked={
                                values.linkedToAll ===
                                OOtherTaskOptions.general_task.uid
                                    ? true
                                    : false
                            }
                            toggle={() => {
                                if (
                                    values.linkedToAll !==
                                    OOtherTaskOptions.general_task.uid
                                ) {
                                    setFieldValue(
                                        'linkedToAll',
                                        OOtherTaskOptions.general_task.uid
                                    )
                                } else {
                                    setFieldValue('linkedToAll', undefined)
                                }
                            }}
                            width={34}
                            circleSize={12}
                            height={18}
                        />

                        <ToggleText
                            isActive={
                                values.linkedToAll ===
                                OOtherTaskOptions.general_task.uid
                                    ? true
                                    : false
                            }
                        >
                            {OOtherTaskOptions.general_task.single}
                        </ToggleText>
                    </Row>
                    <div style={{ paddingTop: '10px' }} />
                    <Row>
                        <SwitchButton
                            checked={
                                values.linkedToAll === 'all_cars' ? true : false
                            }
                            toggle={() => {
                                if (values.linkedToAll !== 'all_cars') {
                                    setFieldValue('linkedToAll', 'all_cars')
                                } else {
                                    setFieldValue('linkedToAll', undefined)
                                }
                            }}
                            circleSize={12}
                            height={18}
                            width={34}
                        />

                        <ToggleText
                            isActive={
                                values.linkedToAll === 'all_cars' ? true : false
                            }
                        >
                            Every car
                        </ToggleText>
                    </Row>
                </ToggleWrapper>
                <div style={{ paddingTop: '30px' }} />

                <FieldTitle>
                    What is the due date?
                    <span style={{ color: colours[theme].text_default }}>
                        *
                    </span>
                </FieldTitle>
                <div style={{ paddingTop: '5px' }} />
                <div
                    style={{
                        color: 'black',
                        fontFamily: 'lato-light',
                        fontSize: '14px',
                    }}
                >
                    You will be notified via email ahead of the task's due date.
                </div>
                <div style={{ paddingTop: '22px' }} />
                <InputField
                    theme={theme}
                    id="selected_date"
                    name="selected_date"
                    placeholder={'Due date'}
                    customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                    tabindex={1}
                    aria-live="polite"
                    onChange={handleDueDateAnswerChange}
                    value={values.due_date}
                    font_family="Lato"
                    type="date"
                    onEnterKeyPress={props.handleSubmit}
                    min={moment().add(1, 'days').format('YYYY-MM-DD')}
                />
            </InnerFormPadding>

            <ButtonsWrapper>
                <HorizLine />
                <InnerFormPadding>
                    <ButtonsStandardBottomRowMobile
                        cancel={toggle}
                        submit={props.handleSubmit}
                        confirmationtext="create"
                        disabled={checkIfDisabled()}
                        height="40px"
                        dataCyIdUpdate="create_task_mobile"
                        dataCyIdCancel="cancel_task_creation_mobile"
                    />
                </InnerFormPadding>
            </ButtonsWrapper>
        </EditFormContainerDesktop>
        // </TouchScrollable>
    )
}

const CreateTaskFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        title: '',
        linkedToCar: [
            props.currentCarID && props.carsData[props.currentCarID]
                ? props.carsData[props.currentCarID].title
                : '',
        ],

        multiple_car_names_values_array:
            props.currentCarID && props.carsData[props.currentCarID]
                ? [props.carsData[props.currentCarID].title]
                : undefined,
        due_date: undefined,
    }),
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                // title: Yup.string().test(
                //     'len',
                //     'Display name must be of at least 2 characters and of a maximum of 70 characters.',
                //     (val: any) =>
                //         val &&
                //         val.toString().length > 1 &&
                //         val.toString().length < 71
                // ),
                title: Yup.string().max(70, 'over70').min(2, 'under2'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        let generateCarIds = (names: string[]) => {
            let car_ids: string[] = []

            let { carsData } = props

            let all_car_ids_list: string[] = Object.keys(carsData).filter(
                (id) => id !== 'new_car'
            )

            for (const carid of all_car_ids_list) {
                if (names.includes(carsData[carid].title)) {
                    car_ids.push(carid)
                }
            }
            return car_ids
        }

        setSubmitting(false)

        if (
            props.submitFunc &&
            (values.multiple_car_names_values_array || values.linkedToAll)
        ) {
            let obj = {
                title: values.title,
                car_id:
                    values.linkedToAll === 'all_cars'
                        ? props.garageCarsIds
                        : values.linkedToAll ===
                          OOtherTaskOptions.general_task.uid
                        ? undefined
                        : values.multiple_car_names_values_array
                        ? generateCarIds(values.multiple_car_names_values_array)
                        : undefined,
                garage_id: [props.garage_id],
                currentCarID: props.currentCarID
                    ? props.currentCarID
                    : undefined,
                due:
                    values.due_date && values.due_date
                        ? values.due_date
                        : undefined,
            }
            props.submitFunc(obj)
            props.toggle()
        }
    },
})(InnerForm)

export default connect(mapStateToProps, dispatchProps)(CreateTaskFormMobile)
