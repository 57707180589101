// Function to create a UTC date from the local date selected via date picker
export function createUTCDateFromLocalPicker(dateString: any) {
    // Parse the local date. The input dateString should be in the format "YYYY-MM-DD" as typically provided by date inputs
    const localDate = new Date(dateString)

    // Extract the day, month, and year from the local date
    const day = localDate.getDate() // Day of the month (1-31)
    const month = localDate.getMonth() // Month (0-11, where January is 0 and December is 11)
    const year = localDate.getFullYear() // Full year (YYYY)

    // Create a new date in UTC using the extracted components.
    // Note: JavaScript's Date.UTC() method takes month index 0-11, so no adjustment is needed for the month
    const utcDate = new Date(Date.UTC(year, month, day))

    return utcDate
}

// Function to create a local date from the date part of an ISO string
export function createLocalDateFromISODatePart(isoString: any) {
    // Extract the date part (before the 'T') from the ISO string
    const datePart = isoString.split('T')[0] // this could potentially be all you need for react-datepicker as it will assume you are using local time zone and do the next couple of lines itself

    // Create a new Date object using just the date part
    const localDate = new Date(datePart)

    return localDate
}
