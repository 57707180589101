// create store for todos (basically reducer for the main final store)

import * as successActions from './actions/successActions'
import * as requestActions from './actions/requestActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'
import { ITimelineItem, ITimelineState } from 'timelineModels'
import {
    timeline_category_data,
    timeline_category_ids,
    timeline_category_searchable_items_list_mock,
} from './data'

import {
    create_entry_label_steps_list,
    create_timeline_add_new_desktop_steps_list,
    create_timeline_add_new_steps_list,
    create_timeline_attachment_steps_list,
    create_timeline_cost_item_desktop_steps_list,
    create_timeline_cost_item_steps_list,
    create_timeline_service_steps_list,
    create_timeline_maintenance_steps_list,
    create_timeline_restoration_steps_list,
    create_timeline_admin_steps_list,
    create_timeline_insurance_steps_list,
    create_timeline_history_steps_list,
    create_timeline_events_steps_list,
    create_timeline_others_steps_list,
    timeline_attachment_fields_list,
    timeline_cost_fields_list,
    timeline_fields_data_per_step,
    // timeline_events_general_fields_list,
    timeline_general_fields_list,
    timeline_notes_fields_list,
    timeline_labels_fields_list,
    timeline_mileage_fields_list,
    timeline_insurance_general_fields_list,
    timeline_insurance_fields_list,
} from './timelineFieldsList'
import {
    TimelineCreationTypeIdsEnum,
    TimelineFormTypeIDsEnum,
} from './timelineEnum'
import { ILabel } from 'myModels'
import { IDropdownItem } from 'entityModels'

type SuccessActions = ActionType<typeof successActions>
type RequestActions = ActionType<typeof requestActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: ITimelineState = {
    timeline_data_normalised_by_car_id: {},

    categories: {
        ids: timeline_category_ids,
        data: timeline_category_data,
        category_searchable_items_list:
            timeline_category_searchable_items_list_mock,
    },
    labels: {
        data: {},
        list: [],
    },
    activeEntry: undefined,
    activeCost: undefined,
    formsData: {
        creation: {
            timeline_creation_steps_data: timeline_fields_data_per_step,
            timeline_creation_steps_object_of_indexed_list: {
                [TimelineCreationTypeIdsEnum.add_new_desktop]:
                    create_timeline_add_new_desktop_steps_list,
                [TimelineCreationTypeIdsEnum.cost]:
                    create_timeline_cost_item_steps_list,
                [TimelineCreationTypeIdsEnum.cost_desktop]:
                    create_timeline_cost_item_desktop_steps_list,
                [TimelineCreationTypeIdsEnum.attachment]:
                    create_timeline_attachment_steps_list,
                [TimelineCreationTypeIdsEnum.label]:
                    create_entry_label_steps_list,
                [TimelineCreationTypeIdsEnum.add_new]:
                    create_timeline_add_new_steps_list,
                service: create_timeline_service_steps_list,
                maintenance: create_timeline_maintenance_steps_list,
                restoration: create_timeline_restoration_steps_list,
                admin: create_timeline_admin_steps_list,
                insurance: create_timeline_insurance_steps_list,
                history: create_timeline_history_steps_list,
                events: create_timeline_events_steps_list,
                other: create_timeline_others_steps_list,
            },
            data_to_be_submitted: {},
        },
        fieldsList: {
            fieldsListByCategory: {
                service: timeline_general_fields_list,
                maintenance: timeline_general_fields_list,
                restoration: timeline_general_fields_list,
                admin: timeline_general_fields_list,
                insurance: timeline_insurance_general_fields_list,
                history: timeline_general_fields_list,
                events: timeline_general_fields_list,
                other: timeline_general_fields_list,
            },
            fieldsListByType: {
                [TimelineFormTypeIDsEnum.cost]: timeline_cost_fields_list,
                [TimelineFormTypeIDsEnum.attachment]:
                    timeline_attachment_fields_list,
                [TimelineFormTypeIDsEnum.notes]: timeline_notes_fields_list,
                [TimelineFormTypeIDsEnum.labels]: timeline_labels_fields_list,
                [TimelineFormTypeIDsEnum.mileage]: timeline_mileage_fields_list,
                [TimelineFormTypeIDsEnum.insurance_fields]:
                    timeline_insurance_fields_list,
            },
        },
    },

    error: null,
    loading: false,
    deletionInProgress: false,
    isEntryAttachmentLoading: false,
    isCostAttachmentLoading: false,
}

export const timelineReducer = (
    state: ITimelineState = initialState,
    action: RequestActions | SuccessActions | ErrorActions
): ITimelineState => {
    switch (action.type) {
        // GET ALL TIMELINE DATA AROUND A CAR ========================

        // send request to api
        case requestActions.getAllTimelineItemsByCarIDRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga on success
        case successActions.getAllTimelineItemsByCarIDSuccess.type:
            return Object.assign({}, state, {
                ...state,
                timeline_data_normalised_by_car_id: {
                    ...state.timeline_data_normalised_by_car_id,
                    [action.payload.car_id]: {
                        ...(state.timeline_data_normalised_by_car_id &&
                            state.timeline_data_normalised_by_car_id[
                                action.payload.car_id
                            ] &&
                            state.timeline_data_normalised_by_car_id[
                                action.payload.car_id
                            ]),
                        entries: action.payload.entries,
                    },
                },

                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.getAllTimelineItemsByCarIDError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // GET TIMELINE ITEM BY ID ========================

        // send request to api
        case requestActions.getTimelineItemByIDRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga on success
        case successActions.getTimelineItemByIDSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: action.payload,
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.getTimelineItemByIDError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // GET ALL TIMELINE DATA AROUND A CATEGORY AND A CAR ============

        // send request to api
        case requestActions.filterTimelineItemsByCarCategoryIdsNLabelIdsRequest
            .type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga on success

        case successActions.filterTimelineItemsByCarCategoryIdsNLabelIdsSuccess
            .type:
            return Object.assign({}, state, {
                ...state,
                timeline_data_normalised_by_car_id: {
                    ...state.timeline_data_normalised_by_car_id,
                    [action.payload.car_id]: {
                        ...(state.timeline_data_normalised_by_car_id &&
                            state.timeline_data_normalised_by_car_id[
                                action.payload.car_id
                            ]),
                        search: {
                            ...(state.timeline_data_normalised_by_car_id &&
                                state.timeline_data_normalised_by_car_id[
                                    action.payload.car_id
                                ] &&
                                state.timeline_data_normalised_by_car_id[
                                    action.payload.car_id
                                ].search &&
                                state.timeline_data_normalised_by_car_id[
                                    action.payload.car_id
                                ].search),
                            results: action.payload.results,
                        },
                    },
                },
                loading: false,
                error: null,
            })
        // from saga on error
        case errorActions.filterTimelineItemsByCarCategoryIdsNLabelIdsError
            .type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SET FILTERS ON FE - Just success action as no BE involved

        case requestActions
            .setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest
            .type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions
            .setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNEntryTitleQSuccess
            .type:
            return Object.assign({}, state, {
                ...state,
                timeline_data_normalised_by_car_id: {
                    ...state.timeline_data_normalised_by_car_id,
                    [action.payload.car_id]: {
                        ...(state.timeline_data_normalised_by_car_id &&
                            state.timeline_data_normalised_by_car_id[
                                action.payload.car_id
                            ]),
                        search: {
                            ...(state.timeline_data_normalised_by_car_id &&
                                state.timeline_data_normalised_by_car_id[
                                    action.payload.car_id
                                ] &&
                                state.timeline_data_normalised_by_car_id[
                                    action.payload.car_id
                                ].search &&
                                state.timeline_data_normalised_by_car_id[
                                    action.payload.car_id
                                ].search),
                            activeCategoryIds: action.payload.category_ids,
                            activeCustomLabelsIds: action.payload.label_ids,
                            activeEntryTitleQuery: action.payload.entry_title,
                            results: action.payload.resetResults && undefined,
                        },
                    },
                },
                loading: false,
                error: null,
            })

        case errorActions.setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsError
            .type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // ADD TIMELINE ITEM TO A CAR ========================

        // request sent to API
        case requestActions.addTimelineItemToCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga, on success
        case successActions.addTimelineItemToCarSuccess.type: {
            let entries_with_new_item_added: ITimelineItem[] =
                state.timeline_data_normalised_by_car_id &&
                state.timeline_data_normalised_by_car_id[
                    action.payload.carid
                ] &&
                state.timeline_data_normalised_by_car_id[action.payload.carid]
                    .entries
                    ? state.timeline_data_normalised_by_car_id[
                          action.payload.carid
                      ].entries
                    : []

            entries_with_new_item_added.push(action.payload.item)

            return Object.assign({}, state, {
                ...state,
                timeline_data_normalised_by_car_id:
                    state.timeline_data_normalised_by_car_id
                        ? {
                              ...state.timeline_data_normalised_by_car_id,
                              [action.payload.carid]: state
                                  .timeline_data_normalised_by_car_id[
                                  action.payload.carid
                              ]
                                  ? {
                                        ...state
                                            .timeline_data_normalised_by_car_id[
                                            action.payload.carid
                                        ],
                                        entries: [
                                            ...entries_with_new_item_added,
                                        ],
                                    }
                                  : {
                                        entries: [
                                            ...entries_with_new_item_added,
                                        ],
                                    },
                          }
                        : {
                              [action.payload.carid]: {
                                  entries: [...entries_with_new_item_added],
                              },
                          },

                activeEntry: action.payload.item,
                loading: false,
                error: null,
            })
        }

        // from saga on error
        case errorActions.addTimelineItemToCarError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // SET ACTIVE ENTRY ========================

        case successActions.setActiveEntrySuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: action.payload,
                loading: false,
                error: null,
            })

        case successActions.setActiveCostSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeCost: action.payload,
                loading: false,
                error: null,
            })

        // CLEAR DATA TO BE SUBMITTED

        case successActions.clearDataToBeSubmittedSuccess.type:
            return Object.assign({}, state, {
                ...state,
                formsData: {
                    ...state.formsData,
                    creation: {
                        ...state.formsData.creation,
                        data_to_be_submitted: {},
                    },
                },

                loading: false,
            })

        // UPDATE TIMELINE ITEM ========================

        // request sent to API
        case requestActions.updateTimelineEntryRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga, on success
        case successActions.updateTimelineEntrySuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: action.payload.item,
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.updateTimelineEntryError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        //DELETE TIMELINE ITEM ========================

        // request sent to API
        case requestActions.deleteTimelineEntryRequest.type:
            return Object.assign({}, state, {
                ...state,
                deletionInProgress: true,
                error: null,
            })

        // from saga, on success
        case successActions.deleteTimelineEntrySuccess.type:
            return Object.assign({}, state, {
                ...state,
                deletionInProgress: false,
                loading: false,
                error: null,
            })

        // send deletion error
        case errorActions.deleteTimelineEntryError.type:
            return Object.assign({}, state, {
                ...state,
                deletionInProgress: false,
                loading: false,
                error: action.payload,
            })

        // GET COST ITEM BY ID ========================

        // send request to api
        case requestActions.getCostEntryByIdRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga on success
        case successActions.getCostEntryByIdSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeCost: action.payload,
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.getCostEntryByIdError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // ADD COST ITEM TO ENTRY ========================

        // send request to api
        case requestActions.addCostToTimelineItemRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga on success
        case successActions.addCostToTimelineItemSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: action.payload.entry,
                activeCost: action.payload.cost,
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.addCostToTimelineItemError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // UPDATE COST ITEM TO ENTRY ========================

        // send request to api
        case requestActions.updateTimelineItemCostRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga on success
        case successActions.updateTimelineItemCostSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: action.payload.entry,
                activeCost: action.payload.cost,
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.updateTimelineItemCostError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // DELETE COST ITEM TO ENTRY ========================

        // send request to api
        case requestActions.deleteTimelineItemCostRequest.type:
            return Object.assign({}, state, {
                ...state,
                deletionInProgress: true,
                error: null,
            })

        // from saga on success
        case successActions.deleteTimelineItemCostSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: action.payload.entry,
                loading: false,
                deletionInProgress: false,
                error: null,
            })

        // from saga on error
        case errorActions.deleteTimelineItemCostError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                deletionInProgress: false,
                error: action.payload,
            })

        // MANAGE DATA TO BE SUBMITTED TO API ========================

        // on request

        case requestActions.manageDataToBeSubmittedTimelineCreationRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        //  on success
        case successActions.manageDataToBeSubmittedTimelineCreationSuccess.type:
            return Object.assign({}, state, {
                ...state,
                formsData: {
                    ...state.formsData,
                    creation: {
                        ...state.formsData.creation,
                        data_to_be_submitted: {
                            ...state.formsData.creation.data_to_be_submitted,
                            [action.payload.entityType]: {
                                ...state.formsData.creation
                                    .data_to_be_submitted[
                                    action.payload.entityType
                                ],
                                ...action.payload.entityObj,
                            },
                        },
                    },
                },

                loading: false,
            })

        // GET ALL USER CAR ENTRY LABELS ========================

        // send request to api
        case requestActions.getAllUserCarEntryLabelsRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga on success
        case successActions.getAllUserCarEntryLabelsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                labels: {
                    data: action.payload.data,
                    list: action.payload.list,
                },
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.getAllUserCarEntryLabelsError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // from saga on success
        case successActions.addLabelToCarEntrySuccess.type:
            let current_labels_ids = state.labels.list.map(
                (i: IDropdownItem, index: number) => {
                    return i.uid
                }
            )
            let new_labels: ILabel[] = action.payload.labels.filter(
                (label: ILabel, index: number) => {
                    return !current_labels_ids.includes(label.uid)
                }
            )

            return Object.assign({}, state, {
                ...state,
                labels: {
                    ...state.labels,
                    list: [...state.labels.list, ...new_labels],
                },
                activeEntry: {
                    ...state.activeEntry,
                    labels:
                        state.activeEntry && state.activeEntry?.labels
                            ? [
                                  ...state.activeEntry.labels,
                                  ...action.payload.labels,
                              ]
                            : [...action.payload.labels],
                },
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.addLabelToCarEntryError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // from saga on success
        case successActions.removeLabelFromCarEntrySuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: {
                    ...state.activeEntry,
                    labels: action.payload.labels,
                },
                loading: false,
                error: null,
            })

        // send request to api
        case requestActions.addAttachmentsToEntryRequest.type:
            return Object.assign({}, state, {
                ...state,
                // loading: true,
                isEntryAttachmentLoading: true,
                error: null,
            })

        // from saga on error
        case errorActions.addAttachmentToEntryError.type:
            return Object.assign({}, state, {
                ...state,
                // loading: false,
                isEntryAttachmentLoading: false,
                error: action.payload,
            })

        // from saga on success
        case successActions.addAttachmentToEntrySuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeEntry: {
                    ...state.activeEntry,
                    attachmentIDs: state.activeEntry?.attachmentIDs && [
                        ...action.payload,
                        ...state.activeEntry?.attachmentIDs,
                    ],
                },
                // loading: still true, will become false when attachment manager finishes,
                isEntryAttachmentLoading: true,
                error: null,
            })

        case requestActions.addAttachmentToCostRequest.type:
            return Object.assign({}, state, {
                ...state,
                // loading: true,
                isCostAttachmentLoading: true,
                error: null,
            })

        // from saga on error
        case errorActions.addAttachmentToCostError.type:
            return Object.assign({}, state, {
                ...state,
                // loading: false,
                isCostAttachmentLoading: false,
                error: action.payload,
            })

        // from saga on success
        case successActions.addAttachmentToCostSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeCost: {
                    ...state.activeCost,
                    attachmentID: action.payload,
                },
                // loading: false,
                isCostAttachmentLoading: false,
                error: null,
            })

        // from saga on success
        case successActions.deleteCostAttachmentSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeCost: {
                    ...state.activeEntry,
                    attachmentIDs: undefined,
                },
                loading: false,
                error: null,
            })

        // from saga on success
        case successActions.deleteEntryAttachmentSuccess.type:
            return Object.assign({}, state, {
                ...state,
                activeCost: {
                    ...state.activeEntry,
                    attachmentIDs: action.payload,
                },
                loading: false,
                error: null,
            })

        // from saga on error
        case errorActions.deleteEntryAttachmentError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // from saga on error
        case errorActions.deleteCostAttachmentError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case requestActions.deleteCostAttachmentRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case requestActions.deleteEntryAttachmentRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga, on success
        case successActions.addGalleryImagesIdsToEntrySuccess.type: {
            let existingids: string[] =
                state.activeEntry && state.activeEntry.gallery_image_ids
                    ? state.activeEntry.gallery_image_ids
                    : []

            return Object.assign({}, state, {
                ...state,
                activeEntry: {
                    ...state.activeEntry,
                    gallery_image_ids: [
                        ...existingids,
                        ...action.payload.image_ids,
                    ],
                },
                loading: false,
                error: null,
            })
        }

        case successActions.deleteGalleryImagesIdFromEntrySuccess.type: {
            let active_entry_images: string[] | undefined =
                state.activeEntry && state.activeEntry.gallery_image_ids
                    ? state.activeEntry.gallery_image_ids
                    : undefined

            let new_images_ids_array: string[] | undefined = active_entry_images
                ? [...active_entry_images]
                : undefined

            if (new_images_ids_array) {
                let indexOfImg: number = new_images_ids_array.indexOf(
                    action.payload.imageid
                )
                if (indexOfImg > 0) {
                    new_images_ids_array.splice(indexOfImg, 1)
                }
            }

            return Object.assign({}, state, {
                ...state,
                activeEntry: {
                    ...state.activeEntry,
                    gallery_image_ids: new_images_ids_array,
                },
                loading: false,
                error: null,
            })
        }

        case requestActions.manageAddAttachmentsToEntryRequest.type:
            return Object.assign({}, state, {
                ...state,
                isEntryAttachmentLoading: true,
                error: null,
            })

        case errorActions.manageAddAttachmentsToEntryError.type:
            return Object.assign({}, state, {
                ...state,
                isEntryAttachmentLoading: false,
                error: action.payload,
            })

        case successActions.manageAddAttachmentsToEntrySuccess.type:
            return Object.assign({}, state, {
                ...state,
                isEntryAttachmentLoading: false,
            })

        case successActions.cleanUpTimelineDataOnLogoutSuccess.type:
            return Object.assign({}, state, {
                timeline_data_normalised_by_car_id: {},

                categories: {
                    ids: timeline_category_ids,
                    data: timeline_category_data,
                    category_searchable_items_list:
                        timeline_category_searchable_items_list_mock,
                },
                labels: {
                    data: {},
                    list: [],
                },
                activeEntry: undefined,
                activeCost: undefined,
                formsData: {
                    creation: {
                        timeline_creation_steps_data:
                            timeline_fields_data_per_step,
                        timeline_creation_steps_object_of_indexed_list: {
                            [TimelineCreationTypeIdsEnum.add_new_desktop]:
                                create_timeline_add_new_desktop_steps_list,
                            [TimelineCreationTypeIdsEnum.cost]:
                                create_timeline_cost_item_steps_list,
                            [TimelineCreationTypeIdsEnum.cost_desktop]:
                                create_timeline_cost_item_desktop_steps_list,
                            [TimelineCreationTypeIdsEnum.attachment]:
                                create_timeline_attachment_steps_list,
                            [TimelineCreationTypeIdsEnum.label]:
                                create_entry_label_steps_list,
                            [TimelineCreationTypeIdsEnum.add_new]:
                                create_timeline_add_new_steps_list,
                            service: create_timeline_service_steps_list,
                            maintenance: create_timeline_maintenance_steps_list,
                            restoration: create_timeline_restoration_steps_list,
                            admin: create_timeline_admin_steps_list,
                            insurance: create_timeline_insurance_steps_list,
                            history: create_timeline_history_steps_list,
                            events: create_timeline_events_steps_list,
                            other: create_timeline_others_steps_list,
                        },
                        data_to_be_submitted: {},
                    },
                    fieldsList: {
                        fieldsListByCategory: {
                            service: timeline_general_fields_list,
                            maintenance: timeline_general_fields_list,
                            restoration: timeline_general_fields_list,
                            admin: timeline_general_fields_list,
                            insurance: timeline_general_fields_list,
                            history: timeline_general_fields_list,
                            events: timeline_general_fields_list,
                            other: timeline_general_fields_list,
                        },
                        fieldsListByType: {
                            [TimelineFormTypeIDsEnum.cost]:
                                timeline_cost_fields_list,
                            [TimelineFormTypeIDsEnum.attachment]:
                                timeline_attachment_fields_list,
                            [TimelineFormTypeIDsEnum.notes]:
                                timeline_notes_fields_list,
                            [TimelineFormTypeIDsEnum.labels]:
                                timeline_labels_fields_list,
                            [TimelineFormTypeIDsEnum.mileage]:
                                timeline_mileage_fields_list,
                        },
                    },
                },

                error: null,
                loading: false,
                isEntryAttachmentLoading: false,
                isCostAttachmentLoading: false,
            })

        // request sent to API
        case requestActions.addTimelineItemWithCostsToCarRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        // from saga, on success
        case successActions.resetTimelineItems.type: {
            return Object.assign({}, state, {
                ...state,
                timeline_data_normalised_by_car_id: undefined,
                activeEntry: undefined,
                loading: false,
                error: null,
            })
        }
        default:
            return state
    }
}

export default timelineReducer

export type TimelineReducer = ReturnType<typeof timelineReducer>

// case successActions.filterTimelineItemsByCarCategoryTagIDSuccess.type:
//     return Object.assign({}, state, {
//         ...state,
//         timeline_data_normalised_by_car_id: {
//             ...state.timeline_data_normalised_by_car_id,
//             [action.payload.carid]: {
//                 ...(state.timeline_data_normalised_by_car_id &&
//                     state.timeline_data_normalised_by_car_id[
//                         action.payload.carid
//                     ]),
//                 items_ids_by_normalised_category: {
//                     ...(state.timeline_data_normalised_by_car_id &&
//                         state.timeline_data_normalised_by_car_id
//                             .items_ids_by_normalised_category),
//                     [action.payload.categoryId]:
//                         action.payload.carItemIdsByCategory,
//                 },
//             },
//         },
//         all_items_normalised_by_id: {
//             ...state.all_items_normalised_by_id,
//             ...action.payload.timeline_items_normalised_by_id,
//         },

//         all_item_ids: action.payload.itemIdsCleanedUpFromDuplicate,
//         loading: false,
//         error: null,
//     })
