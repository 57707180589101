import { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { fields_array_insurance_application_details } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { ITopWizardCheckActions } from '../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceQuoteFormGenerator from '../../formGenerators'
import useThemes from '../../../../../providers/theme/hooks'
import colours from '../../../../../providers/theme/colours'

type Props = {
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuranceDetailsWizardForm = (props: Props) => {
    const { theme } = useThemes()
    const history = useHistory()
    const dispatch = useAppDispatch()

    const data: IAnyObject = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.insurance_details
    })

    const errors: IAnyErrorString = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.insurance_details_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insuranceDetails({
                id: id,
                error: error,
            })
        )
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < fields_array_insurance_application_details.length;
            i++
        ) {
            if (
                fields_array_insurance_application_details[i].is_required ===
                true
            ) {
                if (
                    data[fields_array_insurance_application_details[i].id] ===
                    undefined
                ) {
                    setError(
                        fields_array_insurance_application_details[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }
        }

        if (
            hasFormChanged &&
            data.contact_method &&
            data.contact_method.length === 0
        ) {
            setError('contact_method', 'Required field.')
            hasErr2 = true
        }

        return hasErr2
    }

    const { id } = useParams<{ id?: string }>()

    let { getInitialSectionWizardIds, set_wizard_entity_id_to_skip } =
        useSetInitialInsurance()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.draft
    )

    useEffect(() => {
        set_wizard_entity_id_to_skip('insurance_details')
    }, [applicationData])

    const goNext = () => {
        setTimeout(() => {
            // because first page
            let ids = getInitialSectionWizardIds()
            if (ids) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids.sectionID}`)
                params2.append('subsection_id', `${ids.sub_sectionID}`)
                if (ids.car_id) {
                    params2.append('car_id', `${ids.car_id}`)
                }
                if (ids.driver_id) {
                    params2.append('driver_id', `${ids.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else history.push(`/insurance/application/${applicationData?.id}`)
        }, 100)
    }

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_insuranceDetailsApplication_request()
            )
            goNext()
        } else {
            setIsSavedDisabled(true)
        }
    }

    let onAnswerChange = (id: string, answer: any) => {
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'insurance_details',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }

        if (
            id === 'application_referral_code' &&
            answer &&
            typeof answer === 'string'
        ) {
            answer = answer.toUpperCase()
        }

        dispatch(
            insuranceActions.set_answer_insuranceDetails({
                id: id,
                answer: answer,
            })
        )
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'insurance_details',
            onSave: () => saveForm(),
            has_changes: false,
        })
    }, [])

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        list={fields_array_insurance_application_details}
                        onAnswerChange={onAnswerChange}
                        data={data}
                        errors={errors}
                        setError={setError}
                        sectionID="insurance_details"
                    />
                </div>

                <div style={{ paddingTop: '200px' }} />

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    goBack={() => history.push(`/insurance/application/${id}`)}
                    hasFormChanged={hasFormChanged}
                    sectionId="insurance_details"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '88vw',
                        paddingBottom: 120,
                        backgroundColor:
                            colours[theme].background_neutral_subtle,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        list={fields_array_insurance_application_details}
                        onAnswerChange={onAnswerChange}
                        data={data}
                        errors={errors}
                        setError={setError}
                        mobileSearchPickerSectionURL={`/insurance/application/${id}/wizard?section_id=insurance_details`}
                        sectionID="insurance_details"
                    />
                </div>
                <InsuranceGoBackReusableBottomBar
                    isMobile
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    goBack={() => history.push(`/insurance/application/${id}`)}
                    hasFormChanged={hasFormChanged}
                    sectionId="insurance_details"
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceDetailsWizardForm
