import { ActionType } from 'typesafe-actions'
import { IQuestion } from 'myModels'
import questionsListData from './data/questions'
import { IOnboardingQaState } from 'myModels'
import * as pages from './data/totalPagesArray'
import * as successActions from './actions/successActions'
import * as loadingActions from './actions/loadingActions'
import * as errorActions from './actions/errorActions'

type SuccessAction = ActionType<typeof successActions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const questionsListInitial = questionsListData
// later on : to retrieve usertype + questionsList from back end, so to add userPagesArray from back end

export const onboardingQaReducer = (
    state: IOnboardingQaState = {
        error: null,
        loading: false,
        questionsList: questionsListInitial,
        totalPagesArray: {
            collectors: pages.collectorsQuestionPagesArray,
            dealers: pages.dealersQuestionPagesArray,
            content_creators: pages.dealersQuestionPagesArray,
            service_providers: pages.serviceProvidersQuestionPagesArray,
            default: pages.defaultQuestionArray,
            final: pages.finalQuestionArray,
        },
        userType: {
            primary: 'none',
            secondary: 'none',
        },
        userPagesArray: pages.defaultQuestionArray,
        userPagesSubmitted: [],
    },
    action: LoadingActions | SuccessAction | ErrorActions
): IOnboardingQaState => {
    switch (action.type) {
        case loadingActions.setPrimaryOnboardingQaRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case loadingActions.setSecondaryOnboardingQaRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })
        case loadingActions.answerOnboardingQaRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.setPrimaryOnboardingQaError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case errorActions.setSecondaryOnboardingQaError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })
        case errorActions.answerOnboardingQaError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case successActions.setPrimaryOnboardingQaSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: false,
                userType: {
                    ...state.userType,
                    primary: action.payload.answer,
                },
                userPagesArray: action.payload.pages,

                userPagesSubmitted: state.questionsList
                    .filter((question: IQuestion) => {
                        return question.answer !== null
                    })
                    .map((question: IQuestion) => {
                        return question.pageDisplayed
                    }),
            })
        case successActions.setSecondaryOnboardingQaSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: false,
                userType: {
                    ...state.userType,
                    primary: action.payload.answer,
                },
                userPagesArray: action.payload.pages,
            })
        case successActions.answerOnboardingQaSuccess.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                questionsList: questionsListData.map((question: IQuestion) => {
                    if (question.id === action.payload.qa_id) {
                        return (question.answer = action.payload.answer)
                    }
                    return question
                }),
                userPagesSubmitted: [
                    state.userPagesSubmitted,
                    state.questionsList
                        .filter((question: IQuestion) => {
                            return question.id === action.payload.qa_id
                        })
                        .map((question: IQuestion) => {
                            return question.pageDisplayed
                        }),
                ],
            })

        default:
            return state
    }
}

export default onboardingQaReducer

export type OnboardingQaState = ReturnType<typeof onboardingQaReducer>
