import * as React from 'react'
import styled from 'styled-components'
import blank_user from '../../../public/assets/placeholders_svgs/avatar.svg'
// import profile from "../../../public/assets/clegg/profile.png";
// import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import colours, { ITheme } from '../../../providers/theme/colours'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

type IStyleProps = {
    $theme: ITheme
    hasImage?: boolean
}

const UserImgWrapper = styled.div<IStyleProps>`
    border-radius: 50%;
    border: ${(props) =>
        props.hasImage
            ? `solid 0px ${colours[props.$theme].primary}`
            : `1px solid ${colours[props.$theme].border_muted}`};
`

const UserImg = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
`
type Props = {
    size?: string
    url?: any
}

type State = {
    src: any
    isLoading: boolean
}

class CarUserCircle extends React.Component<Props, State> {
    state = {
        src: undefined,
        isLoading: true,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true

        const src =
            this.props.url !== undefined &&
            this.props.url !== false &&
            this.props.url !== null &&
            this.props.url.length > 1
                ? this.props.url
                : undefined

        if (src) {
            return this._isMounted && this.setState({ src })
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props?.url !== prevProps?.url) {
            this.setState({ src: null, isLoading: true })
            const src =
                this.props.url !== undefined &&
                this.props.url !== false &&
                this.props.url !== null &&
                this.props.url.length > 1
                    ? this.props.url
                    : undefined

            if (src) {
                return this.setState({ src: src, isLoading: false })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Wrapper>
                        <UserImgWrapper
                            $theme={theme}
                            hasImage={
                                this.state.src === undefined ? false : true
                            }
                            style={{
                                width: this.props.size ?? 64,
                                height: this.props.size ?? 64,
                            }}
                        >
                            <UserImg
                                src={
                                    this.state.src !== undefined
                                        ? this.state.src
                                        : blank_user
                                }
                                onLoad={() =>
                                    this._isMounted &&
                                    this.setState({
                                        ...this.state,
                                        isLoading: false,
                                    })
                                }
                            />
                            <SkeletonAbsolute
                                isthingloading={this.state.isLoading}
                                isRound={true}
                            />
                        </UserImgWrapper>
                    </Wrapper>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default CarUserCircle
