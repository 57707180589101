import React from 'react'
import { connect } from 'react-redux'
import FullPageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import ReturnNav from '../../atoms/header/returnNav'
import { RootState } from 'typesafe-actions'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import CarGalleryEditExistingImageFormMobile from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormMobile'
import styled from 'styled-components'
import {
    deleteEntryGalleryImageRequest,
    updateEntryGalleryImageRequest,
} from '../../../redux/entities/galleries/actions/loadingActions'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import ScrollLock from 'react-scrolllock'

import { IUser } from 'myModels'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import EntryGallerySlide from '../../molecules/slideshow/entryGallerySlide'
import { ITimelineDataByCarNormalised, ITimelineItem } from 'timelineModels'
import CarGalleryEditExistingImageFormDesktop from '../../organisms/editForms/carGalleryEditForm/carGalleryEditExistingImageFormDesktop'
import {
    IDeleteEntryGalleryImagePayload,
    IUpdateEntryGalleryImagePayload,
} from 'entityModels'
import ImageSubMenuActions from '../../atoms/menu/pageSubMenuActions/imageSubMenuActions'
import { countEntryFeaturedImages } from '../../../helpers/numbers/countFeaturedImages'
import ModalDisplayGalleryImageDesktop from '../../templates/displays/pageWrappers/modalDisplayGalleryImageDesktop'
import GalleryImageCarousel from '../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import colours from '../../../providers/theme/colours'

interface Props extends RouteComponentProps<any> {
    activeEntry: ITimelineItem | undefined
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    isExistingcarGalleryImageFormOpened: boolean
    toggleExistingCarGalleryImageEditForm: any
    imagesData: any
    isGalleryLoading: any
    userLoggedIn: IUser | null
    setInitialLandingUrl: any
    setCurrentCar: any
    getUserDataRequest: () => void
    deleteEntryGalleryImage: (ids: IDeleteEntryGalleryImagePayload) => {}
    updateEntryGalleryImage: (payload: IUpdateEntryGalleryImagePayload) => void
}

const dispatchProps = {
    toggleExistingCarGalleryImageEditForm: () =>
        editFormsActions.toggleExistingCarGalleryImageEditForm(),
    deleteEntryGalleryImage: (ids: IDeleteEntryGalleryImagePayload) =>
        deleteEntryGalleryImageRequest(ids),
    updateEntryGalleryImage: (payload: IUpdateEntryGalleryImagePayload) =>
        updateEntryGalleryImageRequest(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    setCurrentCar: (id: string) => setCurrentCar(id),
    getUserDataRequest: () => getCurrentUserDataRequest(),
}

function mapStateToProps(state: RootState) {
    return {
        imagesData: state.entities.galleriesData.images,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        activeEntry: state.timeline.activeEntry,
        isActiveEntryLoading: state.timeline.loading,
        isGalleryLoading: state.entities.galleriesData.loading,
        isExistingcarGalleryImageFormOpened:
            state.editForms.formsData.carExistingGalleryImageEditForm.isOpen,
        userLoggedIn: state.user.userLoggedIn,
    }
}

type State = {
    orderedIdList: string[]
    showEditImageForm: boolean
    showDeleteConfirmationMessage: boolean
    warningPresent: boolean
    image_index?: number
}

const DeleteImageConfirmationWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    width: 88vw;
    padding-top: 50px;
    padding-bottom: 50px;
    font-family: georgia;
    z-index: 25;
`

const SuccessDeletionMessage = styled.div`
    font-size: 17px;
`

const ReturnToGalleryLink = styled(Link)`
    color: var(--primary, #5ec3ca) !important;
    font-size: 14px;
    padding: 10px;
    width: 60vw;
    border: 1px solid var(--primary, #5ec3ca);
    border-radius: 5px;
    margin-top: 30px;
    text-align: center;
    z-index: 15;
`

class SingleEntryGalleryImage extends React.Component<Props, State> {
    state = {
        orderedIdList: [],
        showEditImageForm: false,
        showDeleteConfirmationMessage: false,
        warningPresent: false,
        image_index: 0,
    }

    setUpInitialIndex = () => {
        let params = new URLSearchParams(this.props.location.search)
        let image_id_param = params.get('id')
        if (
            this.props.activeEntry &&
            this.props.activeEntry.gallery_image_ids &&
            image_id_param
        ) {
            let numberr =
                this.props.activeEntry.gallery_image_ids.indexOf(image_id_param)
            this.setState({
                ...this.state,
                image_index: numberr,
            })
        }
    }

    componentDidMount() {
        let carid: string = this.props.match.params.carid
        let entryid = this.props.match.params.entryid
        this.props.setCurrentCar(carid)

        let userData = this.props.userLoggedIn
        if (!userData) {
            this.props.getUserDataRequest()
        }

        if (userData) {
            let params = new URLSearchParams(this.props.location.search)
            let image_id_param = params.get('id')

            this.props.setInitialLandingUrl(
                `/car/${carid}/history-file/${entryid}/gallery?id=${image_id_param}`
            )

            // change with equivalent form for entry gallery image
            if (this.props.isExistingcarGalleryImageFormOpened === true) {
                this.props.toggleExistingCarGalleryImageEditForm()
            }

            this.setUpInitialIndex()
        }
    }

    componentDidUpdate(prevprops: Props) {
        if (prevprops.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn) {
                let params = new URLSearchParams(this.props.location.search)
                let image_id_param = params.get('id')

                let carid: string = this.props.match.params.carid
                let entryid = this.props.match.params.entryid

                this.props.setInitialLandingUrl(
                    `/car/${carid}/history-file/${entryid}/gallery?id=${image_id_param}`
                )

                // change with equivalent form for entry gallery image
                if (this.props.isExistingcarGalleryImageFormOpened === true) {
                    this.props.toggleExistingCarGalleryImageEditForm()
                }
            }
            this.setUpInitialIndex()
        }

        if (prevprops.activeEntry !== this.props.activeEntry) {
            this.setUpInitialIndex()
        }
    }

    selectMenuGalleryImageState = (id: string) => {
        let params = new URLSearchParams(this.props.location.search)
        let image_id_param = params.get('id')

        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            this.state.image_index &&
            this.props.activeEntry &&
            this.props.activeEntry.gallery_image_ids
                ? this.props.activeEntry.gallery_image_ids[
                      this.state.image_index
                  ]
                : image_id_param

        if (id === 'edit' && current_image_id) {
            return this.setState({
                showEditImageForm: !this.state.showEditImageForm,
                showDeleteConfirmationMessage: false,
            })
        } else if (id === 'delete' && current_image_id) {
            this.setState({
                showEditImageForm: false,
                showDeleteConfirmationMessage: true,
            })

            // change with equivalent action for entry gallery image
            this.props.deleteEntryGalleryImage({
                carid: this.props.match.params.carid,
                entryid: this.props.match.params.entryid,
                imageid: current_image_id,
            })
        } else if (
            id === 'featured' &&
            current_image_id &&
            this.props.imagesData
        ) {
            let payload: IUpdateEntryGalleryImagePayload = {
                carid: this.props.match.params.carid,
                entryid: this.props.match.params.entryid,
                imageid: current_image_id,
                body: {
                    featured: !this.props.imagesData[current_image_id].featured,
                },
            }
            this.props.updateEntryGalleryImage(payload)
        }
        // change with equivalent action for entry gallery image
        this.props.toggleExistingCarGalleryImageEditForm()
    }

    generateImageNavigationUrl = (next: string) => {
        let params = new URLSearchParams(this.props.location.search)

        let image_id_param = params.get('id')

        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            this.state.image_index &&
            this.props.activeEntry &&
            this.props.activeEntry.gallery_image_ids
                ? this.props.activeEntry.gallery_image_ids[
                      this.state.image_index
                  ]
                : image_id_param
        let carid: string = this.props.match.params.carid
        let entryid = this.props.match.params.entryid

        if (
            this.props.carsTimelineData &&
            this.props.carsTimelineData[carid] !== undefined &&
            current_image_id
        ) {
            // change with equivalent for entry gallery image
            const ids_list =
                this.props.activeEntry &&
                this.props.activeEntry.gallery_image_ids

            if (ids_list) {
                const index_of_id = ids_list.indexOf(current_image_id)

                if (next === 'next') {
                    const index_of_next_image = index_of_id + 1

                    if (index_of_next_image === -1) {
                        return '/none'
                    }

                    const id_of_next_image = ids_list[index_of_next_image]

                    if (id_of_next_image !== undefined) {
                        return `/car/${carid}/history-file/${entryid}/gallery?id=${id_of_next_image}`
                    } else return '/none'
                } else {
                    const index_of_previous_image = index_of_id - 1

                    if (index_of_previous_image === -1) {
                        return '/none'
                    }

                    const id_of_previous_image =
                        ids_list[index_of_previous_image]

                    return `/car/${carid}/history-file/${entryid}/gallery?id=${id_of_previous_image}`
                }
            } else {
                return '/none'
            }
        } else {
            return '/none'
        }
    }

    render() {
        const {
            activeEntry,
            isExistingcarGalleryImageFormOpened,
            toggleExistingCarGalleryImageEditForm,
            imagesData,
            isGalleryLoading,
            deleteEntryGalleryImage,
            updateEntryGalleryImage,
        } = this.props

        let carid: string = this.props.match.params.carid
        let entryid = this.props.match.params.entryid

        let params = new URLSearchParams(this.props.location.search)

        let image_id_param = params.get('id')

        let { showDeleteConfirmationMessage, showEditImageForm, image_index } =
            this.state

        let prev_url = this.generateImageNavigationUrl('previous')
        let next_url = this.generateImageNavigationUrl('next')

        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            image_index &&
            activeEntry &&
            activeEntry.gallery_image_ids
                ? activeEntry.gallery_image_ids[image_index]
                : image_id_param

        let currentImageData = current_image_id
            ? imagesData[current_image_id]
            : null

        const featuredImagesCount =
            activeEntry && imagesData
                ? countEntryFeaturedImages(activeEntry, imagesData)
                : 0

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        <ScrollLock isActive={true} />
                        <DesktopDisplayOnly>
                            <FullPageWrapper>
                                <ModalDisplayGalleryImageDesktop
                                    toggle={() => {
                                        this.props.history.push(
                                            this.props.location.pathname
                                        )
                                    }}
                                    isOpen={true}
                                    modalBackgroundColor={
                                        colours[theme].gallery_image_bg
                                    }
                                >
                                    <EntryGallerySlide
                                        closeGallery={() => {
                                            this.props.history.push(
                                                this.props.location.pathname
                                            )
                                        }}
                                        currentImageData={
                                            this.props.imagesData[
                                                current_image_id!
                                            ]
                                        }
                                        carid={carid}
                                        entryid={entryid}
                                        imagesData={this.props.imagesData}
                                        deleteImage={(
                                            ids: IDeleteEntryGalleryImagePayload
                                        ) => deleteEntryGalleryImage(ids)}
                                        isSomethingLoading={isGalleryLoading}
                                        prevUrl={prev_url}
                                        nextUrl={next_url}
                                        onClickEdit={() =>
                                            toggleExistingCarGalleryImageEditForm()
                                        }
                                        updateEntryGalleryImage={
                                            updateEntryGalleryImage
                                        }
                                        featuredImagesCount={
                                            activeEntry &&
                                            imagesData &&
                                            countEntryFeaturedImages(
                                                activeEntry,
                                                imagesData
                                            )
                                        }
                                    />

                                    {isExistingcarGalleryImageFormOpened &&
                                        currentImageData && (
                                            <ModalDisplayAbsolute
                                                toggle={() => {}}
                                                isOpen={true}
                                            >
                                                <CarGalleryEditExistingImageFormDesktop
                                                    close={
                                                        toggleExistingCarGalleryImageEditForm
                                                    }
                                                    updateImage={
                                                        updateEntryGalleryImage
                                                    }
                                                    carid={carid}
                                                    imagedata={currentImageData}
                                                    entryid={entryid}
                                                />
                                            </ModalDisplayAbsolute>
                                        )}
                                </ModalDisplayGalleryImageDesktop>
                            </FullPageWrapper>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'relative',
                                    background:
                                        colours[theme].background_default,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    height: '100vh',
                                }}
                            >
                                <Link
                                    to={{
                                        pathname: `/car/${carid}/history-file/${entryid}/gallery`,
                                        state: {
                                            prevPath: this.props.match.url,
                                        },
                                    }}
                                >
                                    <ReturnNav />
                                </Link>

                                {this.props.activeEntry &&
                                this.props.activeEntry.gallery_image_ids &&
                                current_image_id &&
                                this.props.imagesData &&
                                this.props.imagesData[current_image_id] &&
                                image_index !== undefined ? (
                                    <GalleryImageCarousel
                                        indexOfInitialImage={image_index}
                                        image_data={this.props.imagesData}
                                        images_ids={
                                            this.props.activeEntry
                                                .gallery_image_ids
                                        }
                                        synchroniseActiveIndex={(n: number) => {
                                            this.setState({
                                                ...this.state,
                                                image_index: n,
                                            })
                                        }}
                                        openActionsSheet={() => {
                                            toggleExistingCarGalleryImageEditForm()
                                        }}
                                        cover_id={''}
                                    />
                                ) : (
                                    <SkeletonAbsolute isthingloading={true} />
                                )}

                                {current_image_id &&
                                    imagesData[current_image_id] && (
                                        <ImageSubMenuActions
                                            isOpen={
                                                isExistingcarGalleryImageFormOpened &&
                                                !showDeleteConfirmationMessage &&
                                                !showEditImageForm
                                            }
                                            toggle={
                                                this.selectMenuGalleryImageState
                                            }
                                            items={[
                                                {
                                                    id: 'edit',
                                                    text: 'Update image details',
                                                    action: this
                                                        .selectMenuGalleryImageState,
                                                },
                                                // {
                                                //     id: 'featured',
                                                //     text:
                                                //         imagesData[current_image_id]
                                                //             .featured === true
                                                //             ? 'Remove feature'
                                                //             : 'Set as gallery feature',
                                                //     action: this
                                                //         .selectMenuGalleryImageState,
                                                // },
                                                {
                                                    id: 'delete',
                                                    text: 'Delete image',
                                                    action: this
                                                        .selectMenuGalleryImageState,

                                                    isDisabled:
                                                        imagesData[
                                                            current_image_id
                                                        ].featured !== true &&
                                                        featuredImagesCount >= 5
                                                            ? true
                                                            : false,
                                                    tooltipText:
                                                        'Five images already set as gallery features. Please remove at least one before setting new ones.',
                                                },
                                            ]}
                                        />
                                    )}

                                {isExistingcarGalleryImageFormOpened &&
                                    showDeleteConfirmationMessage === false &&
                                    showEditImageForm === true &&
                                    current_image_id && (
                                        <ModalDisplay
                                            toggle={
                                                toggleExistingCarGalleryImageEditForm
                                            }
                                            isOpen={true}
                                            customZindex={8}
                                        >
                                            <div
                                                style={{ paddingTop: '20px' }}
                                            />
                                            <CarGalleryEditExistingImageFormMobile
                                                carid={carid}
                                                entryid={entryid}
                                                updateImage={
                                                    updateEntryGalleryImage
                                                }
                                                imagedata={
                                                    imagesData[current_image_id]
                                                }
                                                close={() => {
                                                    toggleExistingCarGalleryImageEditForm()
                                                    this.setState({
                                                        showDeleteConfirmationMessage:
                                                            false,
                                                        showEditImageForm:
                                                            false,
                                                    })
                                                }}
                                            />
                                        </ModalDisplay>
                                    )}

                                {showDeleteConfirmationMessage &&
                                    !showEditImageForm && (
                                        <ModalDisplay
                                            toggle={() => {
                                                toggleExistingCarGalleryImageEditForm()
                                                this.props.history.push(
                                                    `/car/${carid}/history-file/${entryid}/gallery`
                                                )
                                            }}
                                            isOpen={true}
                                        >
                                            <DeleteImageConfirmationWrapper>
                                                <SuccessDeletionMessage>
                                                    Image successfully deleted.
                                                </SuccessDeletionMessage>

                                                <ReturnToGalleryLink
                                                    to={{
                                                        pathname: `/car/${carid}/history-file/${entryid}/gallery`,
                                                    }}
                                                    replace
                                                >
                                                    Return to gallery
                                                </ReturnToGalleryLink>
                                            </DeleteImageConfirmationWrapper>
                                        </ModalDisplay>
                                    )}
                            </div>
                        </IpadAndMobileDisplay>
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(SingleEntryGalleryImage)
)
