import * as React from 'react'
import styled from 'styled-components'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import Icon from '../../atoms/icons'
import { device } from '../../templates/displays/devices'
import { motion } from 'framer-motion'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = { $theme: ITheme }

const ListItem = styled.li<IStyle>`
    margin-top: 10px;
    margin-bottom: 10px;
    list-style-type: none;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    padding-top: 20px;
    padding-bottom: 20px;
    width: 84vw;
    height: 100%;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const ListContentRow = styled.div`
    padding-left: 15px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const Handler = styled.div`
    width: 26px;
    height: 148px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const Text = styled.textarea<IStyle>`
    font-family: Lato;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    width: 69vw;
    min-height: 148px;
    font-size: 14px;
    border: none;
    padding-top: 20px;
    border-radius: 5px;
    resize: none;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 5e-5em;

    /* Text/Default */

    color: ${(props) => colours[props.$theme].text_default};

    height: 100%;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    :focus {
        outline: 0;
    }

    @media screen and (max-width: 350px) {
        width: 66vw;
    }
`

const Delete = styled.div<IStyle>`
    padding-left: 20px;
    padding-top: 8px;
    color: ${(props) => colours[props.$theme].error};
    /* font-weight: 600; */
    font-size: 14px;
    font-family: Lato-Semibold;
    @media ${device.beyond_ipad_mobile} {
    }
`

const DragHandle = SortableHandle(() => (
    <motion.div whileTap={{ scale: 1.2 }}>
        <Handler>
            <Icon icon="handler" />
        </Handler>
    </motion.div>
))

const SortableItem = SortableElement(
    ({
        item,
        formikprops,
        index,
        theme,
    }: {
        item: EnumerableObject
        formikprops: any
        index: number
        theme: ITheme
    }) => (
        <ListItem key={index} style={{ zIndex: 5 }} $theme={theme}>
            <ListContentRow>
                {/* <VoteUp number={item.upvotes} />
                <Separation /> */}

                <Text
                    $theme={theme}
                    id={item.id}
                    name={item.id}
                    placeholder={
                        item.order === 1
                            ? 'i.e. First purchased the car...'
                            : item.order === 2
                            ? 'i.e. Special because...'
                            : item.order === 3
                            ? 'i.e. Favourite moment behind the wheel...'
                            : item.order === 4
                            ? 'i.e. Highlight of ownership...'
                            : item.order === 5
                            ? 'i.e. Started restoration process...'
                            : ''
                    }
                    onChange={formikprops.handleChange}
                    // defaultValue={item.text}
                    value={
                        formikprops.values[item.id] !== undefined
                            ? formikprops.values[item.id]
                            : ''
                    }
                    maxLength={200}
                />

                {(formikprops.values[item.id] ||
                    formikprops.values[item.id] !== '') && <DragHandle />}
            </ListContentRow>

            {(formikprops.values[item.id] ||
                formikprops.values[item.id] !== '') && (
                <motion.div
                    whileTap={{ scale: 1.1 }}
                    onClick={() => {
                        formikprops.setFieldValue(item.id, '', true)
                    }}
                >
                    <Delete $theme={theme}>
                        <div>Remove</div>
                        {/* <ButtonAtom theme={'naked-text'}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DeleteIcon
                                    alt="delete"
                                    src={bin_button_circle_blue}
                                />
                                <Separation />
                                <span>Remove</span>
                            </div>
                        </ButtonAtom> */}
                    </Delete>
                </motion.div>
            )}
        </ListItem>
    )
)

type EnumerableObject = {
    id: string
    text: string
    order: number
    formikprops: any
}

type Props = {
    item: EnumerableObject
    index: number
    formikprops: any
}

export default function DraggableItemEditableMobile(props: Props) {
    const { index, formikprops, item } = props
    const [localItem, setLocalItem] = React.useState<EnumerableObject>(item)

    React.useEffect(() => {
        setLocalItem(item)
    }, [item])

    const { theme } = useThemes()

    return (
        <SortableItem
            theme={theme}
            key={index}
            index={index}
            item={localItem}
            formikprops={formikprops}
        />
    )
}
