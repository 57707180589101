import { RootAction } from 'typesafe-actions'
import * as actions from './actions'
import * as loadingActions from './loadingActions'
import { ThunkAction } from 'redux-thunk'

export const setCurrentCar = (
    car_id: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any, getState: () => IGetState): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.setCurrentCarRequest())

            dispatch(actions.setCurrentCarSuccess(car_id))
        })
    }
}

interface IGetState {
    [key: string]: any
}

export const setCurrentExternalCar = (
    car_id: string
): ThunkAction<Promise<void>, {}, {}, RootAction> => {
    // Invoke API
    return async (dispatch: any): Promise<void> => {
        return new Promise<void>((resolve) => {
            dispatch(loadingActions.setCurrentExternalCarRequest())

            dispatch(actions.setCurrentExternalCarSuccess(car_id))
        })
    }
}
