import React from 'react'
// import debounce from "lodash/debounce";
import Fuse from 'fuse.js'

import { debounce } from 'lodash'
import LabelsFilterByMultipleWithSearchUIMobile from './labelsFilterByMultipleWSearchUIMobile'
import { IDropdownItem } from 'entityModels'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'

export type Props = {
    labels_searchable_list: IDropdownItem[]
    searchFor?: any
    addTagFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    issearchactive?: boolean | undefined
    hasInnerSaveBtn?: boolean
    addToEntry?: any
    editData?: IEditOrCreateModeSingleFieldsProps
}

type LocalState = {
    data: any[]
    labelsDataRes: any[]
    name: any[]
    fetching: boolean
}

class LabelsFilterByMultipleWSearchWFuseMobile extends React.Component<
    Props,
    LocalState
> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        this.fetchItem = debounce(this.fetchItem, 180)
    }

    state = {
        data: [],
        labelsDataRes: [],
        name: [],
        fetching: false,
    }

    lastFetchId = 0

    fetchItem = (name: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            return
        }

        const fuseLabels = new Fuse(this.props.labels_searchable_list, {
            keys: ['name'],
        })

        const resultsLabel = fuseLabels.search(name, { limit: 2 })

        this.setState({
            ...this.state,
            labelsDataRes: resultsLabel,
            fetching: false,
        })

        return
    }

    handleChange = (value: any) => {
        this.setState({
            ...this.state,
            name: [value],
            data: [],
            labelsDataRes: [],
            fetching: false,
        })
        //this.props.handleSelectChange && this.props.handleSelectChange(name)
    }

    clearField = () => {
        this.setState({
            ...this.state,
            name: [],
            data: [],
            labelsDataRes: [],
            fetching: false,
        })
    }

    render() {
        const { name, labelsDataRes } = this.state
        const { dataCyId, addToEntry, labels_searchable_list, editData } =
            this.props

        return (
            <LabelsFilterByMultipleWithSearchUIMobile
                dataCyId={dataCyId}
                labels_searchable_list={labels_searchable_list}
                value={name}
                handleChange={this.handleChange}
                fetchItem={this.fetchItem}
                labelsDataRes={labelsDataRes}
                addToEntry={addToEntry}
                editData={editData}
                clearField={this.clearField}
            />
        )
    }
}

export default LabelsFilterByMultipleWSearchWFuseMobile
