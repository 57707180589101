import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import bg from '../../../../public/assets/placeholder/carInsights/desktop_bg.jpg'
import bg_dark from '../../../../public/assets/placeholder/carInsights/desktop_bg_dark.jpg'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { useHistory } from 'react-router'
import { useAppSelector } from '../../../../redux/store/hooks'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.div`
    display: none;
    @media ${device.beyond_ipad_mobile} {
        width: 100%;
        display: flex;
        position: relative;
    }
`

const BgImg = styled.img`
    width: 100%;
    position: absolute;
    top: 10px;
    z-index: 0;
`
const WrapBtn = styled.div`
    display: flex;
    width: 400px;
    padding: 48px 40px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 8px;
    background: var(--bg-color, #fff);
    box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04);
    z-index: 2;

    position: absolute;
    top: 50px;
    left: 50px;

    @media (max-width: 1350px) {
        transform: scale(0.8);
        top: 0px;
        left: -0px;
    }

    @media (max-width: 1100px) {
        top: 0px;
        left: -10px;
    }
`

const Title = styled.div`
    color: var(--text-darker, #616161);
    font-family: Lato;
    font-size: 24px;
`

const Paragraph = styled.div`
    color: var(--text-darker, #616161);
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const CarInsightsDesktopPlaceholder = () => {
    const history = useHistory()
    const carID = useAppSelector((state) => {
        return state.entities.carsData.currentCarID
    })

    const { theme } = useThemes()

    return (
        <Wrapper>
            <BgImg src={theme === 'dark' ? bg_dark : bg} />

            <WrapBtn>
                <Title>No Expenses to Show Yet</Title>
                <Paragraph>
                    Looks like we don't have any expense data for this car yet.
                    Once you've added some detailed history file entries with
                    costs included, you'll see a comprehensive breakdown of your
                    expenses here.
                </Paragraph>
                <ButtonAtom
                    theme="lowercase-blue-background"
                    textTransform="none"
                    height="48px"
                    onClick={() => {
                        history.push(`/car/${carID}/history-file/create`)
                    }}
                >
                    Create History File Now
                </ButtonAtom>
            </WrapBtn>
        </Wrapper>
    )
}

export default CarInsightsDesktopPlaceholder
