import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import * as NavigationIcons from '../../statefulicons'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'
import Icon from '../../icons'
import info_red from '../../../../public/assets/icons/info_red.svg'

const SectionHeaderWrapper = styled.div<PropsStyle>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding-bottom: ${(props) =>
        props.$customPadding ? props.$customPadding : '40px'};
    padding-top: ${(props) =>
        props.$sectionPadding === true
            ? '80px'
            : props.$customPadding
            ? props.$customPadding
            : '50px'};
    padding-right: 10px;
    @media ${device.beyond_ipad_mobile} {
        align-items: flex-start;
        padding-top: ${(props) =>
            props.$sectionPadding === true
                ? '80px'
                : props.$customPadding
                ? props.$customPadding
                : '50px'};
        padding-right: 0px;
    }
`

const RowStart = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
    justify-content: space-between;
    @media ${device.mobile} {
        height: auto;
    }
    @media ${device.beyond_ipad_mobile} {
        justify-content: flex-start;
    }
    width: 100%;
`

const Text = styled.div<PropsStyle>`
    font-size: ${(props: PropsStyle) => props.$customFontSize ?? '18px'};
    text-transform: capitalize;
    font-family: Lato;
    font-style: normal;
    font-weight: ${(props) =>
        props.$customFontWeight ? props.$customFontWeight : 500};
    padding-right: 16px;

    @media ${device.mobile_and_ipad} {
        max-width: 92vw;
        line-height: initial;
    }
    @media ${device.mobile} {
        line-height: 28px;
    }
    @media ${device.beyond_ipad_mobile} {
        font-size: ${(props) =>
            props.$customFontSize
                ? props.$customFontSize
                : props.$smallerFont
                ? '18px'
                : '24px'};

        font-weight: ${(props) =>
            props.$customFontWeight ? props.$customFontWeight : 600};
        text-transform: capitalize;
        font-family: Lato;
        white-space: nowrap;
        padding-right: 16px;
    }
    color: ${(props: PropsStyle) => colours[props.$theme].text_strong};
    word-wrap: break-word;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
const Edit = styled.div<PropsStyle>`
    cursor: pointer;
    height: 100%;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    :hover {
        background-color: ${(props: PropsStyle) =>
            `${colours[props.$theme].background_primary_soft} !important`};
        transition: background-color 200ms;
    }

    @media ${device.ipad} {
        padding-left: 10px;
    }
`

interface IProps {
    title: string
    sectionPadding?: boolean
    openForm?: any
    removeEdit?: boolean
    customPadding?: string
    dataCyId?: string
    addButton?: boolean
    smallerFont?: boolean
    rowChildren?: () => React.ReactNode
    customFontSize?: string
    customFontWeight?: number
    ChildText?: () => any
    titleIcon?: string
    is_warning?: boolean
}

interface PropsStyle {
    $theme: ITheme
    $sectionPadding?: boolean
    $customPadding?: string
    $smallerFont?: boolean
    $customFontSize?: string
    $customFontWeight?: number
}

const ChildTextStyle = styled.div<PropsStyle>`
    color: ${(props) => colours[props.$theme].text_default};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
    max-width: 744px;
    padding-top: 22px;
    padding-bottom: 22px;

    @media ${device.smallest_laptops} {
        padding-top: 16px;
        padding-bottom: 16px;
    }
`

const WarningIcon = styled.img`
    width: 24px;
    transform: translateY(5px);
`

const Warning = styled.div<PropsStyle>`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: ${(props) => colours[props.$theme].error_08};
    flex-direction: row;
    margin-top: 20px;
`

const WarningText = styled.div<PropsStyle>`
    color: ${(props) => colours[props.$theme].error};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

export default function SectionHeader(props: IProps) {
    const {
        title,
        sectionPadding,
        openForm,
        removeEdit,
        customPadding,
        addButton,
        smallerFont,
        rowChildren,
        customFontSize,
        customFontWeight,
        ChildText,
        titleIcon,
        is_warning,
    } = props

    const { theme } = useThemes()

    return (
        <SectionHeaderWrapper
            $sectionPadding={sectionPadding && sectionPadding}
            $customPadding={customPadding && customPadding}
            $theme={theme}
        >
            <RowStart>
                <Col>
                    <Text
                        $customFontWeight={customFontWeight}
                        $customFontSize={customFontSize}
                        $smallerFont={smallerFont}
                        $theme={theme}
                    >
                        {titleIcon && <Icon height="32px" icon={titleIcon} />}
                        {titleIcon && <div style={{ paddingLeft: '8px' }} />}
                        <div>{title}</div>
                    </Text>
                    {!is_warning && ChildText && (
                        <ChildTextStyle $theme={theme}>
                            <ChildText />
                        </ChildTextStyle>
                    )}
                    {is_warning && ChildText && (
                        <Warning $theme={theme}>
                            <WarningIcon src={info_red} />
                            <WarningText $theme={theme}>
                                <ChildText />
                            </WarningText>
                        </Warning>
                    )}
                </Col>

                {removeEdit !== true && (
                    <Edit $theme={theme}>
                        <NavigationIcons.EditIcon
                            onClick={(e: any) => {
                                e.preventDefault()

                                openForm && openForm()
                            }}
                            dataCyId={props.dataCyId && props.dataCyId}
                        />
                    </Edit>
                )}
                {addButton === true && (
                    <Edit $theme={theme}>
                        <NavigationIcons.AddIcon
                            onClick={(e: any) => {
                                e.preventDefault()

                                openForm && openForm()
                            }}
                            dataCyId={props.dataCyId && props.dataCyId}
                        />
                    </Edit>
                )}
            </RowStart>

            {rowChildren && (
                <div style={{ paddingLeft: '20px' }}> {rowChildren()}</div>
            )}
        </SectionHeaderWrapper>
    )
}
