import * as React from 'react'
// import makes from "../../../../../redux/localdata/formslist/car/makes.json";

import { connect } from 'react-redux'
import {
    ICostItem,
    ICreationTimelineSubmitConfigs,
    ITimelineActionType,
    ITimelineCategoriesObj,
    ITimelineCreationFormFieldsData,
    ITimelineCreationOrEditFormFieldItem,
    ITimelineDataToBeSubmittedObj,
    ITimelineEntityType,
    ITimelineFieldsIdsList,
    ITimelineItem,
    ITimelineStepsIndexedListByCreationType,
} from 'timelineModels'

import { RootState } from 'typesafe-actions'
import * as FieldChoices from '../../../molecules/editOrCreateModeSingleFields'
import CreateFormTitle from '../../../atoms/typography/createFormTitle'
import CreateFormSubtitle from '../../../atoms/typography/createFormSubtitle'
import AddNewNaked from '../../../atoms/Button/addNewNaked'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'

import { editFormsActions } from '../../../../redux/editForms/reducer'
import { ILabel, ITimelineCreateForms } from 'myModels'

import dayjs from 'dayjs'
import {
    timeline_category_data,
    currency_data,
    currency_IDS,
} from '../../../../redux/timeline/data'

import LabelsFilterByMultipleWSearchWFuseMobile from '../../filterByMultiple/labels/labelsFilterByMultipleWSearchWFuseMobile'
import { IDropdownItem } from 'entityModels'

import { device } from '../../../templates/displays/devices'
import CreateFormSubSubtitleDetail from '../../../atoms/typography/createFormSubtitleDetail'
import styled from 'styled-components'
import { renderCategoryIcon } from '../../../atoms/icons/components/historyFile/categories'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'

const { v4: uuidv4 } = require('uuid')

var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

type IStyled = {
    isHeaderContentRemoved?: boolean
}

const Wrapper = styled.section<IStyled>`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--bg-color, #fff);
    color: var(--text-strong, #1a1a1a);
    padding-top: ${(props) => (props.isHeaderContentRemoved ? '0px' : '20px')};
    padding-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    overflow-y: scroll;
    /* height: 100vh; */
    min-height: -webkit-fill-available;
`
const InnerFormPadding = styled.div`
    width: 100%;
    /* padding-left: 20px;
    padding-right: 20px; */
    padding-bottom: 50px;
    padding-top: 10px;
`

const LargePadding = styled.div`
    padding-top: 40px;
`

const TitlesContainer = styled.div`
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ButtonsSection = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 30px;
    @media ${device.ipad} {
        justify-content: flex-end;
        padding-right: 30px;
    }
`

// const SummaryItemWrapper = styled(motion.div)`

// width: 100%,
// margin-left: -30px;
// padding-left: 30px;
// padding-right: 30px;

//     @media ${device.ipad} {
//         padding-left: 25px;
//     }
// `

const dispatchProps = {
    manageTimelineCreationFormState: (obj: ITimelineCreateForms) =>
        editFormsActions.manageTimelineCreationFormState(obj),
}

function mapStateToProps(state: RootState) {
    return {
        fieldsList: state.timeline.formsData.fieldsList,
        timeline_creation_forms_current_state:
            state.editForms.timelineCreationForm,
        steps: state.timeline.formsData.creation
            .timeline_creation_steps_object_of_indexed_list,
        steps_data:
            state.timeline.formsData.creation.timeline_creation_steps_data,
        entryTypes_data: state.timeline.categories,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
    }
}

interface Props extends RouteComponentProps<any> {
    data_to_be_submitted: ITimelineDataToBeSubmittedObj | undefined
    current_step_index: number
    step_list: string[]
    fieldsList: ITimelineFieldsIdsList
    stepFieldsData: { [key: string]: ITimelineCreationOrEditFormFieldItem }
    stepFieldIdsList: string[]
    stepTitle: string
    stepSubtitle: string
    stepSubtitleDetail?: string
    submit: ICreationTimelineSubmitConfigs
    onChange: (
        fieldID: string,
        value: any,
        entityType: ITimelineEntityType,
        action: ITimelineActionType
    ) => void
    closeForm: () => void
    timeline_creation_forms_current_state: ITimelineCreateForms
    manageTimelineCreationFormState: any
    manageSteps: () => void
    // returnToCorrespondingStep: any
    showSkipButton: boolean
    steps: ITimelineStepsIndexedListByCreationType
    steps_data: ITimelineCreationFormFieldsData
    labels_searchable_list?: IDropdownItem[] | null | undefined
    submitDataRequest?: () => void
    isHeaderContentRemoved?: boolean
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    activeEntry?: ITimelineItem | null | undefined
    entryTypes_data: ITimelineCategoriesObj
    userCurrency: string | undefined
}

class AnyObj {
    [key: string]: any
}

type IStyle = {
    isactive?: boolean
    $bgColor?: string
}

const IconContainer = styled.div<IStyle>`
    width: 42px;
    height: 42px;
    background-color: ${(props) =>
        props.$bgColor ? props.$bgColor : 'var(--primary, #5ec3ca)'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;
`

type State = {
    isHeaderActive: boolean
    registeredSteps: any
    errorObject: AnyObj
}

class TimelineEditGenerateCreateFormMobile extends React.Component<
    Props,
    State
> {
    state = {
        isHeaderActive: false,
        registeredSteps: {},
        // we create an obj with fieldIDs as key and whether rn there's an error ( true | false)
        errorObject: new AnyObj(),
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps: Props) {}

    determineErrors = (fieldId: string, error: boolean) => {
        const errorObject: any = this.state.errorObject

        errorObject[fieldId] = error
        this.setState({ ...this.state, errorObject: errorObject })
    }

    active_creation =
        this.props.timeline_creation_forms_current_state.active_creation

    active_creation_steps =
        this.active_creation && this.props.steps[this.active_creation]

    generateFields = (fieldId: string, key: any) => {
        let { onChange, entryTypes_data, submit } = this.props

        let data: IEditOrCreateModeSingleFieldsProps = {
            value_start: this.determineValueStart(fieldId),
            title:
                this.props.stepFieldsData[fieldId] &&
                this.props.stepFieldsData[fieldId].text,
            onChangeFunc: onChange,
            id: fieldId,
            unique_identifier: 'default',
            isCreation: true,
            submitCreationConfigs: {
                entityType: submit.entity,
                action: submit.action,
            },
            placeholder:
                this.active_creation === 'insurance'
                    ? undefined
                    : this.generatePlaceholder(fieldId),
            optionTypeID:
                fieldId === 'categoryID'
                    ? 'entryType'
                    : fieldId === 'amount'
                    ? 'currency'
                    : undefined,
        }

        let hasError = (error: boolean) => {
            this.determineErrors(fieldId, error)
        }

        switch (fieldId) {
            case 'categoryID': {
                data.isMandatory = true
                data.charLimit = [2, 70]

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    if (value === 'insurance') {
                        this.props.history.replace(
                            `/car/${this.props.carID}/history-file/create/insurance`
                        )
                    } else {
                        onChange(fieldID, value, entityType, action)
                        setTimeout(() => {
                            this.props.manageSteps()
                        }, 50)
                    }
                }

                if (data.optionTypeID) {
                    switch (data.optionTypeID) {
                        case 'entryType':
                            return (
                                <FieldChoices.OptionsWithIconAndDescription
                                    data={data}
                                    key={key}
                                    onOptionClick={() => {}}
                                    dataCyId={
                                        this.active_creation_steps[
                                            this.props.current_step_index
                                        ]
                                    }
                                    optionsData={entryTypes_data.data}
                                    optionsIDArr={entryTypes_data.ids}
                                    selectedOption={
                                        this.props.data_to_be_submitted &&
                                        this.props.data_to_be_submitted.entry &&
                                        this.props.data_to_be_submitted.entry
                                            .categoryID
                                    }
                                />
                            )

                        default:
                            return <div style={{ display: 'none' }} key={key} />
                    }
                } else return <div style={{ display: 'none' }} key={key} />
            }

            case 'title': {
                data.isMandatory = true
                data.charLimit = [2, 30]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        placeholder={data.placeholder ? data.placeholder : ''}
                        hasError={hasError}
                    />
                )
            }

            case 'name': {
                data.isMandatory = true
                data.charLimit = [2, 30]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        placeholder={''}
                        hasError={hasError}
                    />
                )
            }

            case 'company': {
                data.charLimit = [0, 70]

                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        placeholder=""
                        hasError={hasError}
                    />
                )
            }

            case 'amount': {
                data.isMandatory = true
                data.optionName = this.props.userCurrency
                    ? this.props.userCurrency
                    : 'GBP'

                return (
                    <FieldChoices.NumberEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        optionsIDArr={currency_IDS}
                        optionsData={currency_data}
                        optionsFieldID="currency"
                        hasError={hasError}
                    />
                )
            }

            case 'date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                    />
                )

            case 'insurance_entry_policy_start_date':
                data.isMandatory = true

                data.onChangeFunc = (
                    fieldID: any,
                    value: any,
                    entityType: ITimelineEntityType,
                    action: ITimelineActionType
                ) => {
                    this.props.onChange(fieldID, value, entityType, action)

                    const aYearFromNow = new Date(value)
                    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)

                    this.props.onChange(
                        'insurance_entry_policy_end_date',
                        aYearFromNow,
                        entityType,
                        action
                    )
                }
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        removePadding={true}
                    />
                )

            case 'insurance_entry_policy_end_date':
                return (
                    <FieldChoices.DatepickerEditOrCreateModeSingleFieldMobile
                        data={{ ...data }}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        removePadding={true}
                    />
                )

            case 'date_with_options':
                return (
                    <FieldChoices.DatePickerWithButtons
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                    />
                )

            case 'insurance_entry_broker': {
                data.isMandatory = false
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        placeholder={''}
                        hasError={hasError}
                    />
                )
            }

            case 'insurance_entry_underwriter': {
                data.isMandatory = true
                data.charLimit = [2, 100]
                data.unique_identifier = uuidv4()
                return (
                    <FieldChoices.BasicEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        placeholder={''}
                        hasError={hasError}
                    />
                )
            }

            case 'short_description':
                data.charLimit = [0, 70]
                return (
                    <FieldChoices.TextareaEditOrCreateModeSingleFieldMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        placeholder={data.placeholder ? data.placeholder : ''}
                        hasError={hasError}
                    />
                )

            case 'attachments': {
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                    />
                )
            }

            case 'attachment': {
                return (
                    <FieldChoices.WrapperAttachmentFormMobile
                        data={data}
                        key={key}
                        dataCyId={
                            this.active_creation_steps[
                                this.props.current_step_index
                            ]
                        }
                        entityID={this.props.entityID}
                        carID={this.props.carID}
                        entityType={this.props.entityType}
                    />
                )
            }

            case 'image':
                // later
                return <div />

            case 'labels':
                // later

                return (
                    <LabelsFilterByMultipleWSearchWFuseMobile
                        placeholder="Search for title or tag"
                        labels_searchable_list={
                            this.props.labels_searchable_list &&
                            this.props.labels_searchable_list.length > 0
                                ? this.props.labels_searchable_list.concat(
                                      this.generatePrePopulatedLabels()
                                  )
                                : this.generatePrePopulatedLabels()
                        }
                        key={key}
                        editData={data}
                        addToEntry={onChange}
                    />
                )

            // case 'summary':
            //     return (
            //         <section
            //             data-attr={
            //                  this.active_creation_steps[this.props.current_step_index]
            //             }
            //         >
            //             {this.generateSummary()}
            //         </section>
            //     )
            default:
                return <div />
        }
    }

    determineValueStart = (fieldId: string) => {
        let { data_to_be_submitted, submit } = this.props

        if (data_to_be_submitted && submit.entity) {
            if (submit.entity && data_to_be_submitted[submit.entity]) {
                let obj: ITimelineItem | ICostItem = {
                    id: '',
                    ...data_to_be_submitted[submit.entity],
                }
                if (obj[fieldId]) {
                    if (fieldId === 'labels')
                        return obj[fieldId].map((label: ILabel) => label.name)
                    return obj[fieldId]
                }
                // else if (data_to_be_submitted.entry) {
                //     if (submit.entity === 'entry' && fieldId === 'title')
                //         return ` ${data_to_be_submitted.entry.category} Entry`
                //     if (submit.entity === 'cost' && fieldId === 'title')
                //         return `${data_to_be_submitted.entry.category} Entry Cost`
                // }
            }

            return this.props.stepFieldsData[fieldId].value
        }
        return this.props.stepFieldsData[fieldId].value
    }

    generatePlaceholder = (fieldId: string): string => {
        let { data_to_be_submitted, submit } = this.props

        if (data_to_be_submitted && submit.entity) {
            if (data_to_be_submitted[submit.entity]) {
                if (
                    data_to_be_submitted.entry &&
                    data_to_be_submitted.entry.categoryID &&
                    timeline_category_data[
                        data_to_be_submitted.entry.categoryID
                    ].placeholders
                ) {
                    let placeholders =
                        timeline_category_data[
                            data_to_be_submitted.entry.categoryID
                        ].placeholders

                    return placeholders ? placeholders[fieldId] : ''
                }
            }
            return ''
        }
        return ''
    }

    generatePrePopulatedLabels = (): IDropdownItem[] => {
        let { data_to_be_submitted, labels_searchable_list } = this.props

        let labels_searchable_list_names = labels_searchable_list?.map(
            (label) => label.name
        )

        if (
            data_to_be_submitted &&
            data_to_be_submitted.entry &&
            data_to_be_submitted.entry.categoryID
        ) {
            let pre_populated_labels: IDropdownItem[] = timeline_category_data[
                data_to_be_submitted.entry.categoryID
            ].pre_populated_labels
                .filter(
                    (label) => !labels_searchable_list_names?.includes(label)
                )
                .map((label: string) => ({
                    uid: label,
                    name: label,
                }))
            return pre_populated_labels
        }
        return []
    }

    render() {
        let {
            step_list,
            current_step_index,
            stepFieldIdsList,
            stepTitle,
            stepSubtitle,
            submit,
            manageSteps,
            showSkipButton,
            submitDataRequest,
            isHeaderContentRemoved,
            stepSubtitleDetail,
            // manageTimelineCreationFormState,
        } = this.props

        let current_creation_step_id = step_list[current_step_index]

        let checkIfSavedIsDisabled = () => {
            for (const id of this.props.stepFieldIdsList) {
                let str = id as keyof AnyObj

                let form_data =
                    this.props.data_to_be_submitted &&
                    this.props.data_to_be_submitted[this.props.entityType] &&
                    this.props.data_to_be_submitted[this.props.entityType]![id]

                if (this.state.errorObject[str] === true) {
                    // error is true
                    return true
                }

                if (
                    form_data &&
                    form_data.length === 0 &&
                    this.state.errorObject[str] === undefined &&
                    this.props.stepFieldsData[id].optional === false
                ) {
                    // mandatory pb
                    return true
                }

                if (
                    !form_data &&
                    this.props.stepFieldsData[id].optional === false
                ) {
                    // mandatory pb
                    return true
                }
            }

            // should be nope
            return false
        }

        const checkIfNoValueEntered = () => {
            let noValueEntered: boolean = true
            stepFieldIdsList.forEach((id: string) => {
                if (this.determineValueStart(id)) {
                    noValueEntered = false
                }
            })

            return noValueEntered
        }

        const stepsWithSkipButton = () => {
            if (
                (current_creation_step_id === 'attachment' ||
                    (current_creation_step_id === 'entry_labels' &&
                        checkIfNoValueEntered()) ||
                    current_creation_step_id === 'cost_step_2' ||
                    (submit.entity === 'cost' &&
                        current_creation_step_id === 'cost_step_1' &&
                        checkIfNoValueEntered())) &&
                current_step_index < step_list.length - 1
            )
                return true
        }

        let is_insurance_specific =
            this.active_creation === 'insurance' ? true : false

        return (
            <Wrapper
                data-attr={`wizard_step_${this.props.current_step_index}`}
                isHeaderContentRemoved={isHeaderContentRemoved}
            >
                <InnerFormPadding>
                    {isHeaderContentRemoved !== true && (
                        <>
                            <TitlesContainer>
                                {is_insurance_specific && (
                                    <IconContainer
                                        $bgColor={
                                            this.props.entryTypes_data.data
                                                .insurance.color
                                        }
                                    >
                                        {renderCategoryIcon('insurance')}
                                    </IconContainer>
                                )}

                                <CreateFormTitle>{stepTitle}</CreateFormTitle>

                                <CreateFormSubtitle>
                                    {stepSubtitle}
                                </CreateFormSubtitle>
                                {stepSubtitleDetail && (
                                    <CreateFormSubSubtitleDetail>
                                        {stepSubtitleDetail}
                                    </CreateFormSubSubtitleDetail>
                                )}
                            </TitlesContainer>
                            <LargePadding />
                        </>
                    )}

                    <div style={{ width: '100%' }}>
                        {stepFieldIdsList &&
                            stepFieldIdsList.map(
                                (fieldID: string, index: number) => {
                                    return this.generateFields(fieldID, index)
                                }
                            )}
                    </div>
                    <LargePadding />

                    {stepsWithSkipButton() ? (
                        <ButtonsSection>
                            {showSkipButton ? (
                                <AddNewNaked
                                    text={'skip'}
                                    isUppercase={true}
                                    onClick={manageSteps}
                                    width={'100px'}
                                    dataCyId={'skip_step_mobile'}
                                />
                            ) : (
                                <StandardCtaBtn
                                    onClick={manageSteps}
                                    dataCyId={'confirm_step_mobile'}
                                    isDisabled={checkIfSavedIsDisabled()}
                                    customWidth="100%"
                                >
                                    {submit.text}
                                </StandardCtaBtn>
                            )}
                        </ButtonsSection>
                    ) : current_creation_step_id ===
                      'select_category' ? null : (
                        <ButtonsSection>
                            <StandardCtaBtn
                                onClick={
                                    current_step_index < step_list.length - 1
                                        ? manageSteps
                                        : () => {
                                              submitDataRequest &&
                                                  submitDataRequest()
                                              manageSteps()
                                          }
                                }
                                isDisabled={checkIfSavedIsDisabled()}
                                dataCyId={
                                    current_step_index < step_list.length - 1
                                        ? 'confirm_step_mobile'
                                        : submit.entity === 'cost'
                                        ? 'create_history_file_cost_mobile'
                                        : 'create_history_file_entry_mobile'
                                }
                                customWidth="100%"
                            >
                                {current_step_index < step_list.length - 1
                                    ? submit.text
                                    : submit.entity === 'cost'
                                    ? 'Create cost'
                                    : 'Create entry'}
                            </StandardCtaBtn>
                        </ButtonsSection>
                    )}
                </InnerFormPadding>
            </Wrapper>
        )
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        dispatchProps
    )(TimelineEditGenerateCreateFormMobile)
)

// generateSummary = () => {
//     let active_creation = this.props.timeline_creation_forms_current_state
//         .active_creation

//     let active_creation_steps =
//         active_creation && this.props.steps[active_creation]

//     return active_creation_steps.map((stepId: any, i: number) => (
//         <div
//             onClick={() =>
//                 this.props.manageTimelineCreationFormState({
//                     isOpen: true,
//                     current_step_index: i,
//                     active_creation: active_creation,
//                 })
//             }
//             key={`step_no_${i}`}
//         >
//             {this.props.steps_data[stepId].step_field_ids.map(
//                 (field: any) => {
//                     let entity: string = this.props.steps_data[stepId]
//                         .submit.entity
//                     let submitted_data =
//                         this.props.data_to_be_submitted &&
//                         this.props.data_to_be_submitted[entity]

//                     return (
//                         this.props.steps_data &&
//                         active_creation &&
//                         this.props.data_to_be_submitted &&
//                         field !== 'summary' && (
//                             <SummaryItemWrapper
//                                 whileTap={{
//                                     backgroundColor: `#2a969a1e`,
//                                 }}
//                                 key={`field_data_${i}`}
//                             >
//                                 <CreateFormSummaryItemTitle>
//                                     {
//                                         this.props.steps_data[stepId]
//                                             .step_fields_data[field].text
//                                     }
//                                 </CreateFormSummaryItemTitle>
//                                 <CreateFormSummaryItemValue>
//                                     {submitted_data && submitted_data[field]
//                                         ? entity === 'entry' &&
//                                           field === 'title' &&
//                                           submitted_data[field]
//                                             ? submitted_data[
//                                                   field
//                                               ].trim() !== ''
//                                                 ? submitted_data[
//                                                       field
//                                                   ].trim()
//                                                 : `${submitted_data.category} Entry`
//                                             : field === 'date_with_options'
//                                             ? fromIsoStringToDDMMYYY(
//                                                   submitted_data[field]
//                                               )
//                                             : field === 'amount'
//                                             ? `£ ${submitted_data[field]}`
//                                             : field === 'company'
//                                             ? convertToShorterString({
//                                                   string:
//                                                       submitted_data[field],
//                                                   maxChars: 20,
//                                               })
//                                             : field === 'attachment'
//                                             ? submitted_data[field] &&
//                                               submitted_data[field]
//                                                   .filename &&
//                                               convertToShorterString({
//                                                   string:
//                                                       submitted_data[field]
//                                                           .filename,
//                                                   maxChars: 20,
//                                                   isFileName: true,
//                                               })
//                                             : field === 'labels'
//                                             ? submitted_data[field] &&
//                                               submitted_data[field].map(
//                                                   (item: ILabel) => (
//                                                       <>
//                                                           <CustomTagQuickFilterItemMobile
//                                                               isActive={
//                                                                   true
//                                                               }
//                                                           >
//                                                               {item.name}
//                                                           </CustomTagQuickFilterItemMobile>
//                                                           <div
//                                                               style={{
//                                                                   paddingRight:
//                                                                       '10px',
//                                                               }}
//                                                           />
//                                                       </>
//                                                   )
//                                               )
//                                             : submitted_data[field].trim()
//                                         : entity === 'entry' &&
//                                           field === 'title'
//                                         ? `${submitted_data?.category} Entry`
//                                         : 'Not provided'}
//                                 </CreateFormSummaryItemValue>
//                                 <div
//                                     style={{
//                                         width: '100%',
//                                         height: '1px',
//                                         backgroundColor: '#f4f4f4',
//                                     }}
//                                 />
//                             </SummaryItemWrapper>
//                         )
//                     )
//                 }
//             )}
//         </div>
//     ))
// }
