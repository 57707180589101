import { ICar } from 'entityModels'
import { useMemo } from 'react'
import styled from 'styled-components'
import ArchivedGarageItemGridView from '../../../molecules/archived/garage/itemGridView'
import ArchivedGarageItemListView from '../../../molecules/archived/garage/itemListView'
import Faded from '../../../templates/animated/faded'
import FadedSlower from '../../../templates/animated/FadedSlower'

import { IThreeDotsActionsItems } from '../../../atoms/menu/actionsMenu/actionsMenuDots'

type Props = {
    activeView: 'grid' | 'list'
    cars: ICar[]
    menu_items: (car: ICar) => IThreeDotsActionsItems[]
    onThreeDotsClick?: (carid: string) => void
}

const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    gap: 24px;
`
const GridContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
`

const ArchivedGarageManagerDesktop = (props: Props) => {
    let { activeView, menu_items, onThreeDotsClick } = props

    let listView = useMemo(() => {
        return (
            <ListContainer>
                {props.cars.map((car: any, index: number) => {
                    return (
                        <ArchivedGarageItemListView
                            menu_items={menu_items}
                            car={car}
                            onThreeDotsClick={onThreeDotsClick}
                        />
                    )
                })}
            </ListContainer>
        )
    }, [props.cars])

    let gridView = useMemo(() => {
        return (
            <GridContainer>
                {props.cars.map((car: any, index: number) => {
                    return (
                        <ArchivedGarageItemGridView
                            menu_items={menu_items}
                            car={car}
                            onThreeDotsClick={onThreeDotsClick}
                        />
                    )
                })}
            </GridContainer>
        )
    }, [props.cars])
    return (
        <>
            {activeView === 'list' && <FadedSlower>{listView}</FadedSlower>}
            {activeView === 'grid' && <Faded>{gridView}</Faded>}
        </>
    )
}

export default ArchivedGarageManagerDesktop
