import styled from 'styled-components'
import colours, { ITheme } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { useAppSelector } from '../../../../../redux/store/hooks'
import { device } from '../../../../templates/displays/devices'
import { LocationIcon } from '../../../../atoms/icons/components'
import blank_user from '../../../../../public/assets/placeholders_svgs/avatar.svg'
import blank_user_dark from '../../../../../public/assets/placeholders_svgs/avatar_dark.svg'
import { JourneyCardDesktop } from '../../../../templates/styledcomponents/journeyStyles'
import UploadedImagePreview from '../../../../atoms/image/uploadedImagePreview'
import { IUser } from 'myModels'

type IStyle = {
    $theme: ITheme
}

const CardContent = styled.section`
    display: flex;
    width: 592px;
    padding: 56px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    @media (max-width: 1200px) {
        width: 100%;
        padding: 24px;
    }
    @media ${device.mobile_and_ipad} {
        width: 100%;
        padding: 24px;
    }
`

const CardSubtitle = styled.p<IStyle>`
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    font-family: Lato;
    line-height: 20px;
`

const Image = styled.img`
    width: 124px;
    height: 124px;
    object-fit: cover;
    border-radius: 16px;
`

const Text = styled.span<IStyle>`
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    @media ${device.mobile_and_ipad} {
        font-size: 16px;
        line-height: 24px;
    }
`

const ColumnContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`

const DisplayName = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Bold;
    font-size: 24px;
    line-height: 40px; /* 166.667% */
`

type Props = {
    selectedImage?: File | undefined
    imageUploadProgress?: number
    userWithFormInfo?: IUser
}

export const UserProfileJourneyUserCardDesktop = (props: Props) => {
    const { selectedImage, imageUploadProgress, userWithFormInfo } = props
    const { theme } = useThemes()

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    let user = userWithFormInfo ?? userLoggedIn

    return (
        <JourneyCardDesktop
            $theme={theme}
            $customBorderRadius="24px"
            $customWidth="600px"
        >
            <CardContent
                style={{
                    minHeight: '470px',
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <ColumnContent
                    style={{
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {selectedImage ? (
                        <div
                            style={{
                                width: 124,
                                height: 124,
                                borderRadius: 12,
                            }}
                        >
                            <UploadedImagePreview
                                currentImagesUploaded={selectedImage}
                                uploadProgress={imageUploadProgress ?? 0}
                                borderRadius="12px"
                            />
                        </div>
                    ) : user?.profile_image_url ? (
                        <Image src={user?.profile_image_url} />
                    ) : (
                        <Image
                            src={
                                theme === 'dark' ? blank_user_dark : blank_user
                            }
                        />
                    )}

                    <div style={{ paddingTop: '24px' }} />

                    <DisplayName>{user?.display_name ?? 'No Name'}</DisplayName>
                    <div style={{ paddingTop: '8px' }} />
                    <CardSubtitle $theme={theme}>
                        <LocationIcon
                            size="24px"
                            color={
                                user?.location
                                    ? colours[theme].text_default
                                    : colours[theme].text_muted
                            }
                        />
                        {user?.location ?? 'London, UK'}
                    </CardSubtitle>

                    <div style={{ paddingTop: '24px' }} />

                    <Text
                        $theme={theme}
                        style={{
                            fontSize: '18px',
                            textAlign: 'center',
                            lineHeight: '30px',
                            color:
                                user?.one_liner && user?.one_liner?.length > 0
                                    ? colours[theme].text_default
                                    : colours[theme].text_muted,
                        }}
                    >
                        {user?.one_liner && user?.one_liner?.length > 0
                            ? user?.one_liner
                            : 'Passionate car enthusiast with a deep love for performance vehicles, classic cars, and motorsports. Enjoys attending car shows, restoring classics, and sharing driving tips.'}
                    </Text>
                </ColumnContent>
            </CardContent>
        </JourneyCardDesktop>
    )
}
