import {
    GET_TECHNICAL_INFORMATION_ERROR,
    WRITE_TECHNICAL_INFORMATION_ERROR,
    SET_TECHNICAL_INFORMATION_ERROR,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const getTechnicalInformationError = createAction(
//     GET_TECHNICAL_INFORMATION_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const getTechnicalInformationError = createAction(
    GET_TECHNICAL_INFORMATION_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const writeTechnicalInformationError = createAction(
//     WRITE_TECHNICAL_INFORMATION_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const writeTechnicalInformationError = createAction(
    WRITE_TECHNICAL_INFORMATION_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const setTechnicalInformationError = createAction(
//     SET_TECHNICAL_INFORMATION_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(error)
// )

export const setTechnicalInformationError = createAction(
    SET_TECHNICAL_INFORMATION_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
