export const GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_SUCCESS =
    '@@timelineItems_all_byCarID/get/SUCCESS'
export const GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_REQUEST =
    '@@timelineItems_all_byCarID/get/REQUEST'
export const GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_ERROR =
    '@@timelineItems_all_byCarID/get/ERROR'

export const FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_SUCCESS =
    '@@filter_timeline_by_category_n_labels_n_CarID/get/SUCCESS'
export const FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_REQUEST =
    '@@filter_timeline_by_category_n_labels_n_CarID/get/REQUEST'
export const FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_ERROR =
    '@@filter_timeline_by_category_n_labels_n_CarID/get/ERROR'

// only successes below because no back end involved so no loading or potential errors
export const SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_REQUEST =
    '@@timelineItems_car_set_filter/add/REQUEST'
export const SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_SUCCESS =
    '@@timelineItems_car_set_filter/add/SUCCESS'
export const SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_ERROR =
    '@@timelineItems_car_set_filter/add/ERROR'

export const GET_TIMELINE_ITEM_BY_ID_SUCCESS =
    '@@timelineItem_by_id/get/SUCCESS'
export const GET_TIMELINE_ITEM_BY_ID_REQUEST =
    '@@timelineItem_by_id/get/REQUEST'
export const GET_TIMELINE_ITEM_BY_ID_ERROR = '@@timelineItem_by_id/get/ERROR'

export const ADD_TIMELINE_ITEM_TO_CAR_SUCCESS =
    '@@timelineItem_car_add/add/SUCCESS'
export const ADD_TIMELINE_ITEM_TO_CAR_REQUEST =
    '@@timelineItem_car_add/add/REQUEST'
export const ADD_TIMELINE_ITEM_TO_CAR_ERROR = '@@timelineItem_car_add/add/ERROR'

export const DELETE_TIMELINE_ITEM_TO_CAR_SUCCESS =
    '@@timelineItem_car/delete/SUCCESS'
export const DELETE_TIMELINE_ITEM_TO_CAR_REQUEST =
    '@@timelineItem_car/delete/REQUEST'
export const DELETE_TIMELINE_ITEM_TO_CAR_ERROR =
    '@@timelineItem_car/delete/ERROR'

export const UPDATE_TIMELINE_ITEM_TO_CAR_SUCCESS =
    '@@timelineItem_car_add/update/SUCCESS'
export const UPDATE_TIMELINE_ITEM_TO_CAR_REQUEST =
    '@@timelineItem_car_add/update/REQUEST'
export const UPDATE_TIMELINE_ITEM_TO_CAR_ERROR =
    '@@timelineItem_car_add/update/ERROR'

export const GET_COST_ENTRY_BY_ID_SUCCESS = '@@timelineItem_cost/get/SUCCESS'
export const GET_COST_ENTRY_BY_ID_REQUEST = '@@timelineItem_cost/get/REQUEST'
export const GET_COST_ENTRY_BY_ID_ERROR = '@@timelineItem_cost/get/ERROR'

export const ADD_COST_TO_TIMELINE_ITEM_SUCCESS =
    '@@timelineItem_cost/add/SUCCESS'
export const ADD_COST_TO_TIMELINE_ITEM_REQUEST =
    '@@timelineItem_cost/add/REQUEST'
export const ADD_COST_TO_TIMELINE_ITEM_ERROR = '@@timelineItem_cost/add/ERROR'

export const DELETE_TIMELINE_ITEM_COST_SUCCESS =
    '@@timelineItem_cost/delete/SUCCESS'
export const DELETE_TIMELINE_ITEM_COST_REQUEST =
    '@@timelineItem_cost/delete/REQUEST'
export const DELETE_TIMELINE_ITEM_COST_ERROR =
    '@@timelineItem_cost/delete/ERROR'

export const UPDATE_TIMELINE_ITEM_COST_SUCCESS =
    '@@timelineItem_cost/update/SUCCESS'
export const UPDATE_TIMELINE_ITEM_COST_REQUEST =
    '@@timelineItem_cost/update/REQUEST'
export const UPDATE_TIMELINE_ITEM_COST_ERROR =
    '@@timelineItem_cost/update/ERROR'

export const GET_ALL_USER_CAR_ENTRY_LABELS_SUCCESS =
    '@@carEntry_labels/get/SUCCESS'
export const GET_ALL_USER_CAR_ENTRY_LABELS_REQUEST =
    '@@carEntry_labels/get/REQUEST'
export const GET_ALL_USER_CAR_ENTRY_LABELS_ERROR = '@@carEntry_labels/get/ERROR'

export const ADD_LABEL_TO_CAR_ENTRY_LABELS_SUCCESS =
    '@@carEntry_labels/update_add/SUCCESS'
export const ADD_LABEL_TO_CAR_ENTRY_LABELS_REQUEST =
    '@@carEntry_labels/update_add/REQUEST'
export const ADD_LABEL_TO_CAR_ENTRY_LABELS_ERROR =
    '@@carEntry_labels/update_add/ERROR'

export const REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_SUCCESS =
    '@@carEntry_labels/update_rmv/SUCCESS'
export const REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_REQUEST =
    '@@carEntry_labels/update_rmv/REQUEST'
export const REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_ERROR =
    '@@carEntry_labels/update_rmv/ERROR'

export const MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_REQUEST =
    '@@timelineItems_local/addDataToBeSubmitted/REQUEST'
export const MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_SUCCESS =
    '@@timelineItems_local/addDataToBeSubmitted/SUCCESS'
export const MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_ERROR =
    '@@timelineItems_local/addDataToBeSubmitted/ERROR'

export const SET_ACTIVE_ENTRY_REQUEST =
    '@@timelineItems_local/setActiveEntry/REQUEST'

export const SET_ACTIVE_ENTRY_SUCCESS =
    '@@timelineItems_local/setActiveEntry/SUCCESS'

export const SET_ACTIVE_ENTRY_ERROR =
    '@@timelineItems_local/setActiveEntry/ERROR'

export const CLEAR_DATA_TO_BE_SUBMITTED_SUCCESS =
    '@@timelineItems_local/clearDataToBeSubmitted/SUCCESS'

export const SET_ACTIVE_ENTRY_COST_SUCCESS =
    '@@timelineItems_local_set_active_COST/set/SUCCESS'

export const ADD_ATTACHMENT_TO_ENTRY_SUCCESS =
    '@@entry_attachment/create/SUCCESS'

export const ADD_ATTACHMENT_TO_ENTRY_REQUEST =
    '@@entry_attachment/create/REQUEST'

export const ADD_ATTACHMENT_TO_ENTRY_ERROR = '@@entry_attachment/create/ERROR'

export const ADD_ATTACHMENT_TO_COST_SUCCESS = '@@cost_attachment/create/SUCCESS'

export const ADD_ATTACHMENT_TO_COST_REQUEST = '@@cost_attachment/create/REQUEST'

export const ADD_ATTACHMENT_TO_COST_ERROR = '@@cost_attachment/create/ERROR'

export const DELETE_ENTRY_ATTACHMENT_SUCCESS =
    '@@entry_attachment/delete/SUCCESS'
export const DELETE_ENTRY_ATTACHMENT_REQUEST =
    '@@entry_attachment/delete/REQUEST'
export const DELETE_ENTRY_ATTACHMENT_ERROR = '@@entry_attachment/delete/ERROR'

export const DELETE_COST_ATTACHMENT_SUCCESS = '@@cost_attachment/delete/SUCCESS'
export const DELETE_COST_ATTACHMENT_REQUEST = '@@cost_attachment/delete/REQUEST'
export const DELETE_COST_ATTACHMENT_ERROR = '@@cost_attachment/delete/ERROR'

export const ADD_GALLERY_IMAGES_IDS_TO_ENTRY_SUCCESS =
    '@@entry_gallery_images_ids/add/SUCCESS'

export const DELETE_GALLERY_IMAGES_ID_FROM_ENTRY_SUCCESS =
    '@@entry_gallery_images_id/delete/SUCCESS'
export const MANAGE_ADD_ATTACHMENTS_TO_ENTRY_SUCCESS =
    '@@entry_attachments/add/SUCCESS'

export const MANAGE_ADD_ATTACHMENTS_TO_ENTRY_REQUEST =
    '@@entry_attachments/add/REQUEST'

export const MANAGE_ADD_ATTACHMENTS_TO_ENTRY_ERROR =
    '@@entry_attachments/add/ERROR'

export const CLEAN_UP_TIMELINE_DATA_ON_LOGOUT =
    '@@clean_up_timeline_data_on_logout/SUCCESS'

export const ADD_TIMELINE_ENTRY_WITH_COST_SUCCESS =
    '@@entry_with_cost/add/SUCCESS'
export const ADD_TIMELINE_ENTRY_SUCCESS = '@@entry_attachments/add/SUCCESS'

export const ADD_TIMELINE_ENTRY_WITH_COST_REQUEST =
    '@@entry_with_cost/add/REQUEST'

export const ADD_TIMELINE_ENTRY_WITH_COST_ERROR = '@@entry_with_cost/add/ERROR'

export const RESET_TIMELINE_SUCCESS = '@@entries/reset/SUCCESS'

export const UPDATE_TIMELINE_ENTRY_WITH_COST_SUCCESS =
    '@@entry_with_cost/update/SUCCESS'

export const UPDATE_TIMELINE_ENTRY_WITH_COST_REQUEST =
    '@@entry_with_cost/update/REQUEST'

export const UPDATE_TIMELINE_ENTRY_WITH_COST_ERROR =
    '@@entry_with_cost/update/ERROR'
