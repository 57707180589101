import create_garage_direct_share_sagas from './createGarageDirectShare'
import create_public_garage_shares_sagas from './createGaragePublisShare'
import delete_garage_shares_sagas from './deleteGarageShare'
import garage_sagas from './garage'
import garage_share_invite_sagas from './garageShareInvites'
import garage_share_recipient_sagas from './garageShareRecipients'
import handle_Garage_shares_display_change from './garageSharesDisplayChange'
import directly_shared_with_you_external_garage_sagas from './getExternalGarage'
import owned_garage_direct_shares from './getGarageDirectShares'
import public_garage_shares_sagas from './getPublicSharesGarage'
import handle_garage_shares_recipients_display_change from './recipientsDisplayChange'
import set_garage_active_sort from './sortGarageCars'
import update_garage_direct_share_sagas from './updateGarageDirectShare'

let arr: any[] = [
    ...garage_sagas,
    ...public_garage_shares_sagas,
    ...create_public_garage_shares_sagas,
    ...delete_garage_shares_sagas,
    ...handle_Garage_shares_display_change,
    ...owned_garage_direct_shares,
    ...create_garage_direct_share_sagas,
    ...garage_share_recipient_sagas,
    ...handle_garage_shares_recipients_display_change,
    ...update_garage_direct_share_sagas,
    ...directly_shared_with_you_external_garage_sagas,
    ...garage_share_invite_sagas,
    ...set_garage_active_sort,
]

export default arr
