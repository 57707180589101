import styled from 'styled-components'
import { insurance_wizard_sections } from '../../../../../providers/insurance/data'
import colours, {
    insurance_colours,
    ITheme,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { device } from '../../../displays/devices'
import { useMemo } from 'react'
import { IInsuranceQuoteApplication } from '../../../../../redux/insuranceQuoteApplication/reducer'
import { useAppSelector } from '../../../../../redux/store/hooks'

type Props = {
    section_id: string
    subsection_id?: string | null | undefined
    car_id?: string | null | undefined
    driver_id?: string | null | undefined
}

const Wrapper = styled.div`
    position: relative;
    display: flex;
    width: 88vw;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;

    @media ${device.beyond_ipad_mobile} {
        margin-bottom: 48px;
        width: 556px;
    }
`

type IStyle = {
    $theme: ITheme
    $sectionId: string
}

const BubbleTxt = styled.div<IStyle>`
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 100px;

    border: ${(props) =>
        `1px solid ${
            // @ts-ignore
            insurance_colours[props.$theme].sections[props.$sectionId]
                .primary_100
        }`};

    background: ${(props) =>
        // @ts-ignore
        insurance_colours[props.$theme].sections[props.$sectionId].primary_08};

    color: ${(props) =>
        // @ts-ignore
        insurance_colours[props.$theme].sections[props.$sectionId]
            .primary_text};

    text-align: center;
    font-family: Lato;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
`

const Row = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    height: 24px;
`

const Line = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    z-index: 0;
    top: 12px;
`

const FilledCircle = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50px;
`

type Props2 = {
    sectionID: string
    theme: string
}

const CircleWithDot = (props: Props2) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <circle
                cx="8"
                cy="8"
                r="7.5"
                stroke={
                    // @ts-ignore
                    insurance_colours[props.theme].sections[props.sectionID]
                        .primary_100
                }
                fill={
                    // @ts-ignore
                    colours[props.theme].background_neutral_subtle
                }
            />
            <circle
                cx="8"
                cy="8"
                r="2"
                fill={
                    // @ts-ignore
                    insurance_colours[props.theme].sections[props.sectionID]
                        .primary_100
                }
            />
        </svg>
    )
}

const EmptyCircle = (props: Props2) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <circle
                cx="8"
                cy="8"
                r="7.5"
                stroke={
                    // @ts-ignore
                    insurance_colours[props.theme].sections[props.sectionID]
                        .primary_100
                }
                fill={
                    // @ts-ignore
                    colours[props.theme].background_neutral_subtle
                }
            />
        </svg>
    )
}

const CompleteCircle = (props: Props2) => {
    const { theme } = useThemes()
    return (
        <FilledCircle
            style={{
                backgroundColor:
                    // @ts-ignore
                    insurance_colours[props.theme].sections[props.sectionID]
                        .primary_100,
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.0601 5.87393L6.9998 11.1873L3.93945 7.97393L5.02566 6.93945L6.9998 9.01229L10.9739 4.83945L12.0601 5.87393Z"
                    fill={colours[theme].background_default}
                />
            </svg>
        </FilledCircle>
    )
}

const InsuranceWizardSubBar = (props: Props) => {
    let { section_id, subsection_id, car_id, driver_id } = props
    const { theme } = useThemes()

    let listObj = insurance_wizard_sections[section_id].subsections ?? {}

    let submitted: IInsuranceQuoteApplication = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    const app_section = useMemo(() => {
        if (
            car_id &&
            (section_id === 'vehicles_to_insure' ||
                section_id === 'everyday_vehicles')
        ) {
            return submitted.vehicles[car_id]
        } else if (driver_id && section_id === 'drivers') {
            return submitted.other_drivers[driver_id]
        } else if (section_id === 'main_policy_holder') {
            return submitted.main_driver
        }
    }, [section_id, subsection_id, driver_id, car_id, submitted])

    return (
        <Wrapper>
            <Row style={{ zIndex: 10 }}>
                {Object.keys(listObj).map((ID) => {
                    if (subsection_id === listObj[ID]?.id) {
                        return (
                            <BubbleTxt
                                key={listObj[ID]?.id}
                                $theme={theme}
                                $sectionId={section_id}
                            >
                                {listObj[ID]?.name}
                            </BubbleTxt>
                        )
                    } else {
                        let sub_step_completion =
                            app_section && listObj[ID]?.id
                                ? listObj[ID]?.id === 'is_everyday' //@ts-ignore
                                    ? app_section.is_everyday !== undefined
                                        ? 100
                                        : 0 //@ts-ignore
                                    : app_section[`${listObj[ID]?.id}_stats`]
                                : undefined
                        return (
                            <div
                                key={listObj[ID]?.id}
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {sub_step_completion === 0 ? (
                                    <EmptyCircle
                                        sectionID={section_id}
                                        theme={theme}
                                    />
                                ) : sub_step_completion === 100 ? (
                                    <CompleteCircle
                                        sectionID={section_id}
                                        theme={theme}
                                    />
                                ) : (
                                    <CircleWithDot
                                        sectionID={section_id}
                                        theme={theme}
                                    />
                                )}
                            </div>
                        )
                    }
                })}
            </Row>
            <Line
                style={{
                    background:
                        // @ts-ignore
                        insurance_colours[theme].sections[section_id]
                            .primary_100,
                }}
            />
        </Wrapper>
    )
}

export default InsuranceWizardSubBar
