type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const LogoutIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ? `${Number(size) * 0.875}` : '14'}
            height={size ?? '16'}
            viewBox="0 0 14 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.9356 7.87506L7.40527 5.40539L6.34461 4.34473L2.81428 7.87506L6.34461 11.4054L7.40527 10.3447L4.9356 7.87506Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.875 7.125L3.875 7.125L3.875 8.625L13.875 8.625L13.875 7.125Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.09497 14.125L1.62497 14.125L1.62497 1.625L7.09497 1.625L7.09497 0.125001L0.874969 0.125001C0.460755 0.125001 0.124969 0.460787 0.124969 0.875001L0.124968 14.875C0.124968 15.2892 0.460754 15.625 0.874968 15.625L7.09497 15.625L7.09497 14.125Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default LogoutIcon
