type Props = {
    size?: string
}

const GallerySlideZoomIcon = (props: Props) => {
    return (
        <svg
            width={props.size ?? '32'}
            height={props.size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="32" height="32" fill="black" fill-opacity="0.42" />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.25 10.75H16V9.25H22.75V16H21.25V10.75Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.4697 14.4697L21.4697 9.46973L22.5304 10.5304L17.5304 15.5304L16.4697 14.4697Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.46973 21.4697L14.4697 16.4697L15.5304 17.5304L10.5304 22.5304L9.46973 21.4697Z"
                fill="white"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.75 21.25L16 21.25L16 22.75L9.25 22.75L9.25 16L10.75 16L10.75 21.25Z"
                fill="white"
            />
        </svg>
    )
}

export default GallerySlideZoomIcon
