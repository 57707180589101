// @ts-ignore
// import jwt_decode from 'jwt-decode'

export const getCookie = (c_name: string) => {
    var i,
        x,
        y,
        ARRcookies = document.cookie.split(';')
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='))
        y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1)
        x = x.replace(/^\s+|\s+$/g, '')
        if (x === c_name) {
            return unescape(y)
        }
    }
}

export const getUserIdFromToken = (): string | false => {
    let u: string | null =
        typeof localStorage !== 'undefined' && localStorage !== null
            ? localStorage.getItem('user_id')
            : null

    if (u) {
        return u
    } else {
        return false
    }
    // const isSigned = getCookie('jwt')

    // if (isSigned !== undefined) {
    //     const decodedToken: any = jwt_decode(isSigned)

    //     let userid: any = decodedToken.user_id
    //     return userid
    // } else return false
}

// export const userIDFromLocalStorage =
//     localStorage.getItem('user_id') !== null &&
//     localStorage.getItem('user_id') !== undefined
//         ? localStorage.getItem('user_id')
//         : false

// @ts-ignore
// import jwt_decode from 'jwt-decode'

// export const getCookie = (c_name: string) => {
//     var i,
//         x,
//         y,
//         ARRcookies = document.cookie.split(';')
//     for (i = 0; i < ARRcookies.length; i++) {
//         x = ARRcookies[i].substring(0, ARRcookies[i].indexOf('='))
//         y = ARRcookies[i].substring(ARRcookies[i].indexOf('=') + 1)
//         x = x.replace(/^\s+|\s+$/g, '')
//         if (x === c_name) {
//             return decodeURI(y)
//         }
//     }
// }
