import { createAction } from '@reduxjs/toolkit'

import {
    SET_IS_FIRST_SESSION_SUCCESS,
    MANAGE_USER_TUTORIAL_BY_ID_CHECK_SUCCESS,
} from './constants'

export const setIsFirstSessionSuccess = createAction(
    SET_IS_FIRST_SESSION_SUCCESS,
    function prepare(payload: boolean) {
        return {
            payload,
        }
    }
)

export const manageUserTutorialByIdCheck = createAction(
    MANAGE_USER_TUTORIAL_BY_ID_CHECK_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)
