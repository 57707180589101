import { IUser } from 'myModels'

export const generateUserChoiceUnit = (
    user: IUser,
    item: 'distance_unit' | 'fuel_unit' | 'default_currency'
) => {
    let default_unit_user: string | null =
        user &&
        user.preferences &&
        user.preferences.data &&
        user.preferences.data.units &&
        user.preferences.data.units.data &&
        user.preferences.data.units.data[item] &&
        user.preferences.data.units.data[item].user_choice_id

    let default_unit_user_text: string | null | undefined =
        user &&
        user.preferences &&
        user.preferences.data &&
        user.preferences.data.units &&
        user.preferences.data.units.data &&
        user.preferences.data.units.data[item] &&
        user.preferences.data.units.data[item].data &&
        user.preferences.data.units.data[item].data[default_unit_user] &&
        user.preferences.data.units.data[item].data[default_unit_user].short

    return {
        unit_id: default_unit_user,
        unit_text: default_unit_user_text,
    }
}
