import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const TasksIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.25 2.25H21.75V17.75H6.25V2.25ZM7.75 3.75V16.25H20.25V3.75H7.75Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.0604 6.97418L12.6667 13.6875L8.9397 9.77418L10.0259 8.7397L12.6667 11.5125L17.9742 5.9397L19.0604 6.97418Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.75 20.25V7H2.25V21.75H17V20.25H3.75Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default TasksIcon
