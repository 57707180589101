// ApexBodyRichContentTextItemDesktop

import React from 'react'
// import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'

import podcast_circle_btn_blue from '../../../../public/assets/navigation/podcast_circle_btn_blue.svg'
import { motion } from 'framer-motion'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    @media ${device.beyond_ipad_mobile} {
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
`

const Header = styled.div`
    font-family: lato-light;
    font-size: 23px;
    text-transform: uppercase;
    width: 490px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 26px;
        width: 450px;
    }
`

const Name = styled.div`
    font-size: 42px;
    font-family: lato-light;
    color: var(--text-strong, #1a1a1a);

    padding-top: 51px;

    @media ${device.large_desktop} {
        font-size: 42px;
        padding-top: 51px;
    }
`
const Title = styled.div`
    font-family: Lato-Light;
    font-size: 32px;
    color: var(--text-strong, #1a1a1a);

    @media ${device.large_desktop} {
        font-size: 32px;
    }
`
const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

const PodcastCircleIconLink = styled.img`
    width: 50px;
    transition: all 200ms;

    :hover {
        box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.09);
    }
    border-radius: 50%;
`

type Props = {
    name: string
    title: string
    uid: string
}

class ApexSingleInterviewHeaderDesktop extends React.Component<Props, {}> {
    render() {
        let { name, title, uid } = this.props

        return (
            <Wrapper>
                <Row>
                    <Header>The Apex Interviews</Header>
                    <Line />

                    <div style={{ paddingRight: '40px' }} />

                    <motion.div whileTap={{ scale: 1.3 }}>
                        <a href={`apex/podcasts#${uid}`}>
                            <PodcastCircleIconLink
                                src={podcast_circle_btn_blue}
                                alt="podcast"
                            />
                        </a>
                    </motion.div>
                </Row>

                <Name>{name}</Name>
                <Title>{title}</Title>
            </Wrapper>
        )
    }
}

export default ApexSingleInterviewHeaderDesktop
