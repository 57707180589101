import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const HistoryIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ? `${Number(size) * 1.06}` : '17'}
            viewBox="0 0 16 17"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.55103 11.0321L3.85006 11.7821L2.99638 13.2607L3.86016 13.2646L4.29542 14.0107L5.1491 12.5321L6.44814 13.2821L4.30214 16.9991L2.99637 14.7607L0.405021 14.7491L2.55103 11.0321Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.54889 13.278L10.8479 12.528L11.7035 14.0099L12.1384 13.2632L13.0026 13.2599L12.147 11.778L13.446 11.028L15.595 14.75L13.0026 14.7599L11.6978 17L9.54889 13.278Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99976 0.752197L9.06656 1.7698L10.455 1.27408L11.0157 2.63761L12.4857 2.74949L12.4434 4.22318L13.7408 4.92331L13.1027 6.25235L14.0032 7.41967L12.8796 8.37426L13.2275 9.80693L11.8128 10.222L11.5479 11.6723L10.0867 11.4761L9.25482 12.6933L7.99976 11.9197L6.7447 12.6933L5.91279 11.4761L4.4516 11.6723L4.18667 10.222L2.77201 9.80693L3.11987 8.37426L1.99634 7.41967L2.89685 6.25235L2.25872 4.92331L3.55617 4.22318L3.51377 2.74949L4.98382 2.63761L5.5445 1.27408L6.93296 1.7698L7.99976 0.752197ZM7.99976 2.82518L7.29931 3.49332L6.38766 3.16784L6.01952 4.06312L5.0543 4.13658L5.08213 5.1042L4.23024 5.5639L4.64923 6.43653L4.05796 7.20299L4.79567 7.82976L4.56726 8.77044L5.49612 9.04298L5.67007 9.99523L6.62947 9.86641L7.1757 10.6656L7.99976 10.1577L8.82382 10.6656L9.37005 9.86641L10.3295 9.99523L10.5034 9.04298L11.4323 8.77044L11.2039 7.82976L11.9416 7.20299L11.3503 6.43653L11.7693 5.5639L10.9174 5.1042L10.9452 4.13658L9.98 4.06312L9.61187 3.16784L8.70021 3.49332L7.99976 2.82518Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default HistoryIcon
