import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { device } from '../../../templates/displays/devices'
// import profile_blank from '../../../../public/assets/icons/profile_pic_placeholder.svg'
import profile_blank from '../../../../public/assets/placeholders_svgs/avatar.svg'
import Icon from '../../../atoms/icons'
import {
    IPrivateShareReceivedItem,
    IPrivateShareReceivedItemApprovalPayload,
} from 'entityModels'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import CardMotionDivWrapper from '../../../templates/animated/cardMotionDivWrapper'

interface IStyle {
    hasMarginRight?: boolean
    $hasApprovalBtns?: boolean
    $allowClick?: boolean
    $justTwoBtns?: boolean
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    display: flex;
    width: 90vw;
    /* max-width: 380px; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: Lato;
    padding-left: 20px;
    ${(props) => props.$justTwoBtns && 'padding-top: 10px;'};
    height: ${(props) =>
        props.$hasApprovalBtns
            ? props.$justTwoBtns
                ? '260px'
                : '325px'
            : '130px'};
    border: transparent;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    background-color: var(--shadow-cards-bg, #fff);
    transition: all 100ms;
    margin-bottom: 20px;

    @media ${device.mobile} {
    }

    @media screen and (max-width: 368px) {
        max-width: 380px;
    }

    @media screen and (max-width: 320px) {
        padding-left: 15px;
    }

    @media ${device.ipad} {
        max-width: 440px;
    }
`

const OwnerName = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 13px;
    max-width: 250px;
    @media (max-width: 500px) {
        max-width: 220px;
    }
    @media (max-width: 400px) {
        max-width: 200px;
    }
    @media (max-width: 320px) {
        max-width: 150px;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100px;
    padding-left: 100px;
    @media ${device.ipad} {
        padding-left: 120px;
    }
    @media (max-width: 400px) {
        padding-left: 100px;
    }
    @media (max-width: 280px) {
        padding-left: 100px;
    }
`

const ColFullWidth = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 7px;
    padding-right: 7px;
`

const CarProfileImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
`

const CarImgWrapper = styled.div`
    position: absolute;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;

    @media ${device.ipad} {
        width: 100px;
        height: 100px;
    }
`

const HozLine = styled.div`
    background-color: var(--border_muted_subtle);
    height: 1px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 12px;
`

const FlexRow = styled.div`
    width: 100%;
    display: flex;
`

const Row = styled.div`
    width: 100%;
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 280px) {
        padding-right: 10px;
    }
`

const SharedOn = styled.div`
    font-family: Lato-light;
    font-size: 11px;
    font-style: italic;
    color: var(--text-darker, #616161);
    @media ${device.ipad} {
        font-size: 12px;
    }
`

const Details = styled.div`
    font-family: Lato;
    font-style: italic;
    font-size: 12px;
    color: var(--text-default, #666666);
    padding-left: 7px;
    @media ${device.ipad} {
        font-size: 12px;
    }
`

const SmallPadding = styled.div`
    padding-top: 16px;
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

interface Props extends RouteComponentProps<any> {
    share_received: IPrivateShareReceivedItem
    hasMarginRight?: boolean
    shareApprovalAction?: (
        payload: IPrivateShareReceivedItemApprovalPayload
    ) => void
}

type State = { src: any; srcet: any; isLoading: boolean }

class GaragesSharedWithYouCardMobile extends React.Component<Props, State> {
    state = { srcet: undefined, src: undefined, isLoading: true }

    _isMounted = false

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                src: src,
                isLoading: false,
            })
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentDidUpdate(prevProps: Props) {}

    render() {
        let { share_received, hasMarginRight, shareApprovalAction } = this.props

        let hasApprovalBts =
            share_received.approvalStatus === 'pending' ||
            share_received.approvalStatus === 'ignored'
                ? true
                : false

        let params = new URLSearchParams(this.props.location.search)
        let view_id = params.get('view_id')

        return (
            <CardMotionDivWrapper>
                {share_received && (
                    <ShadowBox
                        to={
                            // share_received.approvalStatus === 'accepted'
                            //     ? `/shared/garage?garageid=${share_received.entity_id}`
                            //     : view_id
                            //     ? `/sharing/with-you?view_id=${view_id}`
                            //     : `/sharing/with-you`
                            {
                                pathname:
                                    share_received.approvalStatus === 'accepted'
                                        ? `/shared/garage`
                                        : `/sharing/with-you`,
                                search:
                                    share_received.approvalStatus === 'accepted'
                                        ? `?garageid=${share_received.entity_id}`
                                        : view_id
                                        ? `?view_id=${view_id}`
                                        : ``,
                                state: {
                                    prevPath:
                                        this.props.history.location.pathname,
                                    search: this.props.history.location.search,
                                },
                            }
                        }
                        rel="noreferrer"
                        hasMarginRight={hasMarginRight}
                        $hasApprovalBtns={hasApprovalBts}
                        $allowClick={
                            share_received.approvalStatus === 'accepted'
                        }
                        $justTwoBtns={
                            share_received.approvalStatus === 'ignored'
                        }
                    >
                        <Row>
                            <CarImgWrapper
                                style={{
                                    border:
                                        share_received &&
                                        share_received.owner &&
                                        share_received.owner.profile_picture
                                            ? '1px solid #00a9af'
                                            : '1px solid var(--border-muted, #e5e5e5)',
                                }}
                            >
                                <CarProfileImage
                                    src={
                                        share_received.owner.profile_picture
                                            ? share_received.owner
                                                  .profile_picture.url
                                            : profile_blank
                                    }
                                    aria-label="garage owner profile image"
                                />
                            </CarImgWrapper>

                            <Col>
                                <OwnerName>
                                    {share_received.owner.display_name
                                        ? share_received.owner.display_name
                                        : 'Private user'}
                                </OwnerName>

                                <SharedOn>
                                    shared on {share_received.created_at}
                                </SharedOn>
                                <HozLine />
                                {/* <FlexRow>
                                    <Icon icon="car_listed" width="15px" />
                                    <Details>{`${0} cars`}</Details>
                                </FlexRow> */}
                                <div style={{ paddingTop: '5px' }} />
                                <FlexRow>
                                    <Icon icon="location" width="15px" />
                                    <Details
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {share_received.owner.location}
                                    </Details>
                                </FlexRow>
                            </Col>
                        </Row>
                        {hasApprovalBts && (
                            <Row>
                                <ColFullWidth>
                                    <SmallPadding />
                                    <ButtonAtom
                                        theme="secondary"
                                        width="100%"
                                        height="40px"
                                        textTransform="capitalize"
                                        fontSize={14}
                                        onClick={() => {
                                            if (shareApprovalAction) {
                                                shareApprovalAction({
                                                    shareId: share_received.id,
                                                    recipientId:
                                                        share_received.owner
                                                            .uid,
                                                    approvalId: 'accepted',
                                                })
                                                setTimeout(
                                                    () =>
                                                        this.props.history.replace(
                                                            `/sharing/with-you?entity_id=garage&view_id=view_all`
                                                        ),
                                                    50
                                                )
                                            }
                                        }}
                                    >
                                        Accept
                                    </ButtonAtom>
                                    <SmallPadding />
                                    {share_received.approvalStatus !==
                                        'ignored' && (
                                        <>
                                            <ButtonAtom
                                                theme="tertiary"
                                                width="100%"
                                                height="40px"
                                                textTransform="capitalize"
                                                fontSize={14}
                                                onClick={() =>
                                                    shareApprovalAction
                                                        ? shareApprovalAction({
                                                              shareId:
                                                                  share_received.id,
                                                              recipientId:
                                                                  share_received
                                                                      .owner
                                                                      .uid,
                                                              approvalId:
                                                                  'ignored',
                                                          })
                                                        : {}
                                                }
                                            >
                                                Ignore
                                            </ButtonAtom>
                                            <SmallPadding />
                                        </>
                                    )}
                                    <ButtonAtom
                                        theme="lowercase-white-red-background"
                                        width="100%"
                                        height="40px"
                                        fontSize={14}
                                        onClick={() =>
                                            shareApprovalAction
                                                ? shareApprovalAction({
                                                      shareId:
                                                          share_received.id,
                                                      recipientId:
                                                          share_received.owner
                                                              .uid,
                                                      approvalId: 'rejected',
                                                  })
                                                : {}
                                        }
                                    >
                                        Decline
                                    </ButtonAtom>
                                    <SmallPadding />
                                </ColFullWidth>
                            </Row>
                        )}
                    </ShadowBox>
                )}
            </CardMotionDivWrapper>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GaragesSharedWithYouCardMobile)
)
