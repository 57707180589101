import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useClickAway } from '../../../helpers/hooks/domHooks'
import { StandardInputField } from '../Inputfield/inputField'
import Dropdown from './Dropdown'
import { useListNavigation } from './hooks'
import { Option } from './types'
import InputFieldTooltip from '../customTooltips/inputFieldTooltip'
import InfoIcon from '../icons/infoIcon'
import useThemes from '../../../providers/theme/hooks'
import ChevronDown from '../icons/components/chevronDown'

const ImportantIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background-color: var(--off-bg-color, #fafafa);
    display: flex;
    align-items: center;
    justify-content: center;
`

interface SelectProps {
    name?: string
    placeholder?: string
    helperText?: string
    value?: string
    defaultValue?: string
    options: Option[]
    disabled?: boolean
    onChange?: (value: string) => void
    backgroundColor?: string
    hasInfoWithTooltip?: boolean
    tooltipColor?: string
    tooltipText?: string
    tooltipPlacement?: 'left' | 'right'
    customActiveColour?: string
    customOptionHighlightColour?: string
    maxWidth?: string
    onFocusToggle?: (p: boolean) => any
}

export default function Select(props: SelectProps) {
    /**
     * If a `defaultValue` or `value` is provided, we find their
     * corresponding labels and set it as the default select label.
     */
    const defaultLabel = useMemo(() => {
        const labelForDefaultValue =
            props.defaultValue &&
            props.options.find(
                (option) =>
                    props.defaultValue &&
                    option.value?.toLowerCase() ===
                        props.defaultValue?.toLowerCase()
            )

        if (labelForDefaultValue && labelForDefaultValue?.label)
            return labelForDefaultValue.label

        const labelForCurrentValue =
            props.value &&
            props.options.find(
                (option) =>
                    props.value &&
                    option.value.toLowerCase() === props.value.toLowerCase()
            )

        if (labelForCurrentValue && labelForCurrentValue?.label)
            return labelForCurrentValue.label

        return ''
    }, [props.options, props.defaultValue, props.value])

    const [label, setLabel] = useState(defaultLabel)

    useEffect(() => {
        setLabel(defaultLabel)
    }, [defaultLabel])

    const selectOption = (option: Option) => {
        setLabel(option.label)
        props.onChange?.(option.value)
    }

    const { highlightedIndex, setHighlightedIndex, handleKeys } =
        useListNavigation({
            listLength: props.options.length,
            onListItemSelect: (index) => {
                const option = props.options[index]
                selectOption(option)
            },
        })

    const [isListOpen, setIsListOpen] = useState(false)

    const openList = () => {
        setIsListOpen(true)

        if (props.onFocusToggle) {
            props.onFocusToggle(true)
        }
    }

    const closeList = () => {
        setIsListOpen(false)

        if (props.onFocusToggle) {
            props.onFocusToggle(false)
        }
    }

    const listRef = useClickAway(closeList)
    const { theme } = useThemes()

    return (
        <FormWrapper
            disabled={props.disabled}
            onClick={props.disabled ? () => {} : openList}
            style={{ maxWidth: props.maxWidth ?? 'auto' }}
        >
            <StandardInputField
                theme={theme}
                name={props.name}
                defaultValue={props.defaultValue}
                value={label}
                placeholder={props.placeholder}
                helperText={props.helperText}
                disabled={props.disabled}
                onFocus={props.disabled ? () => {} : openList}
                onClick={props.disabled ? () => {} : openList}
                onChange={props.onChange}
                onKeyDown={handleKeys}
                readOnly={true}
                rightAdornment={
                    props.hasInfoWithTooltip &&
                    props.tooltipText &&
                    props.tooltipColor ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 10,
                            }}
                        >
                            <ChevronDownWrapper
                                onClick={props.disabled ? () => {} : openList}
                                disabled={props.disabled}
                            >
                                <ChevronDown />
                            </ChevronDownWrapper>
                            <InputFieldTooltip
                                tooltipText={props.tooltipText}
                                color={props.tooltipColor}
                                tooltipPlacement={props.tooltipPlacement}
                            >
                                <ImportantIconWrapper>
                                    <InfoIcon
                                        size="14"
                                        color={props.tooltipColor}
                                    />
                                </ImportantIconWrapper>
                            </InputFieldTooltip>
                        </div>
                    ) : (
                        <ChevronDownWrapper
                            onClick={props.disabled ? () => {} : openList}
                            disabled={props.disabled}
                        >
                            <ChevronDown />
                        </ChevronDownWrapper>
                    )
                }
                backgroundColor={props.backgroundColor}
                customActiveColour={props.customActiveColour}
            />
            <Dropdown
                ref={listRef}
                isOpen={isListOpen}
                nudgeUp={!!props.helperText}
            >
                {props.options.map((option, index) => (
                    <OptionItem
                        {...option}
                        key={`select_option_${option.value}_${index}`}
                        highlighted={index === highlightedIndex}
                        onSelect={() => {
                            selectOption(option)
                            closeList()
                        }}
                        backgroundColor={props.backgroundColor}
                        onHighlight={() => setHighlightedIndex(index)}
                        customOptionHighlightColour={
                            props.customOptionHighlightColour
                        }
                    />
                ))}
            </Dropdown>
        </FormWrapper>
    )
}

interface OptionProps {
    value: string
    label?: string
    highlighted?: boolean
    onSelect: (value: string) => void
    onHighlight?: () => void
    backgroundColor?: string
    customOptionHighlightColour?: string
}

export function OptionItem(props: OptionProps) {
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (props.highlighted) {
            ref.current?.scrollIntoView({ block: 'nearest' })
        }
    }, [props.highlighted])

    return (
        <ListItem
            ref={ref}
            onClick={(e) => {
                e.stopPropagation()
                props.onSelect(props.value)
            }}
            onMouseOver={props.onHighlight}
            highlighted={props.highlighted}
            backgroundColor={props.backgroundColor}
            customOptionHighlightColour={props.customOptionHighlightColour}
        >
            {props.label}
        </ListItem>
    )
}

const FormWrapper = styled.div<{ disabled?: boolean }>`
    width: 100%;
    position: relative;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'initial')};
    opacity: ${(props) => (props.disabled ? '0.5' : 'initial')};
`

const ChevronDownWrapper = styled.div<{ disabled?: boolean }>`
    height: 20px;
    width: 19px;

    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const ListItem = styled.div<{
    highlighted?: boolean
    backgroundColor?: string
    customOptionHighlightColour?: string
}>`
    padding: 8px 16px;
    cursor: pointer;
    color: var(--text-strong, #1a1a1a);

    ${(props) => `
        background-color: ${
            props.highlighted
                ? props.customOptionHighlightColour ?? 'var(--primary_09)'
                : props.backgroundColor
                ? props.backgroundColor
                : 'initial'
        };
    `}
`
