/* eslint-disable no-useless-escape */
import * as React from 'react'
import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import Expander from '../../../../../atoms/expander/expander'
import Faded from '../../../../../templates/animated/faded'
import StyledFormError from '../../../../../templates/styledcomponents/styledformerrormessage'
import { getRightSchemaAccountData } from '../../validationSchema'
import { IUSerGeneralInfoUpdatePayload } from 'myModels'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const SmallPadding = styled.div`
    padding-top: 30px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    item_edited: string
    email?: any
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: any
    userLoggedIn: any
}

// export const validateEmail = (emailField: any) => {
//     var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

//     if (reg.test(emailField) === false) {
//         return false
//     }

//     return true
// }
export const validateEmail = (emailField: any) => {
    var reg = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

    if (reg.test(emailField) === false) {
        return false
    }

    return true
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        touched,
        // handleChange,
        isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let isEmailValid = true

    if (item.id === 'email') {
        isEmailValid = validateEmail(values.item_edited)
    }

    const { theme } = useThemes()

    return (
        <React.Fragment>
            <ModalContentDesktop
                $theme={theme}
                data-attr={`change-${
                    item.id === 'given_name'
                        ? 'first_name'
                        : item.id === 'family_name'
                        ? 'last_name'
                        : item.id
                }-modal-desktop`}
            >
                <ModalInnerContentWrapperDesktop>
                    <>
                        <ModalTitle $theme={theme}>
                            {item.text && item.text.replace('*', '')}
                        </ModalTitle>
                        <InputField
                            theme={theme}
                            type={item.id === 'email' ? 'email' : 'text'}
                            fieldId="item_edited"
                            name="item_edited"
                            placeholder={item.text}
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={values.item_edited}
                            font_family="Lato"
                            dataCyId={`${
                                item.id === 'given_name'
                                    ? 'first_name'
                                    : item.id === 'family_name'
                                    ? 'last_name'
                                    : item.id
                            }-input-field-desktop`}
                            onEnterKeyPress={props.handleSubmit}
                            backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                        />

                        {item.id === 'email' && isEmailValid === false ? (
                            <Expander
                                height={isEmailValid === false ? 'auto' : 0}
                            >
                                <Faded>
                                    <StyledFormError>
                                        Email not valid
                                    </StyledFormError>
                                </Faded>
                            </Expander>
                        ) : (
                            <Expander
                                height={errors.item_edited ? 'auto' : 0}
                                dataCyId={`${
                                    item.id === 'given_name'
                                        ? 'first_name'
                                        : item.id === 'family_name'
                                        ? 'last_name'
                                        : item.id
                                }-input-field-error-desktop`}
                            >
                                <Faded>
                                    <StyledFormError>
                                        {errors.item_edited}
                                    </StyledFormError>
                                </Faded>
                            </Expander>
                        )}
                    </>

                    <SmallPadding />
                    <ButtonsStandardBottomRowDesktop
                        cancel={toggle}
                        submit={props.handleSubmit}
                        isDisabled={
                            isSubmitting ||
                            errors.item_edited !== undefined ||
                            isEmailValid === false ||
                            !!(errors.email && touched.email)
                        }
                        dataCyIdUpdate={`submit-new-${
                            item.id === 'given_name'
                                ? 'first_name'
                                : item.id === 'family_name'
                                ? 'last_name'
                                : item.id
                        }-desktop`}
                        dataCyIdCancel={`cancel-new-${
                            item.id === 'given_name'
                                ? 'first_name'
                                : item.id === 'family_name'
                                ? 'last_name'
                                : item.id
                        }-desktop`}
                    />
                </ModalInnerContentWrapperDesktop>
            </ModalContentDesktop>
        </React.Fragment>
    )
}

const BasicAccountItemInfoFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        item_edited: props.item.answer,
    }),
    validationSchema: (props: any) => getRightSchemaAccountData(props.item.id),

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let answer: any

        if (props.item.answer !== values.item_edited) {
            if (props.item.id === 'email') {
                answer = values.item_edited.toLocaleLowerCase()
            } else {
                answer = values.item_edited
            }

            let final: IUSerGeneralInfoUpdatePayload = {
                id: props.userLoggedIn.id,
                data: {},
            }

            if (props.item.id === 'given_name' && final && final.data) {
                final.data.given_name = answer
            }

            if (props.item.id === 'family_name' && final && final.data) {
                final.data.family_name = answer
            }

            props.submitFunc && props.submitFunc(final)
        }

        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(BasicAccountItemInfoFormDesktop)
