import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    squareColor?: string
    chevronColor?: string
}

export default function NavigateSquareIcon({
    size,
    squareColor,
    chevronColor,
}: Props) {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '36'}
            height={size ?? '36'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 6.5a6 6 0 0 1 6-6h24a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6v-24Z"
                fill={squareColor ?? colours[theme].primary}
            />
            <path
                d="M22.166 9.333 13 18.5l9.166 9.167"
                stroke={chevronColor ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
