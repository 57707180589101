type Props = {
    height?: string
    width?: string
}

const ShareYourCarPattern = ({ height, width }: Props) => {
    return (
        <svg
            width={width ?? '557'}
            height={height ?? '673'}
            viewBox="0 0 557 673"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.06"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M69.5961 207.967L568.668 0.25293L591.723 55.6467L159.187 235.668L308.422 297.8L375.768 325.838L308.181 353.29L161.014 413.066L308.133 472.524L376.955 500.338L308.133 528.153L161.624 587.364L591.318 758.833L569.08 814.56L70.0047 615.404L0.623047 587.717L69.8824 559.726L216.829 500.338L69.8824 440.95L1.2328 413.206L69.8339 385.341L218.016 325.153L69.5929 263.359L3.05975 235.659L69.5961 207.967ZM297.534 207.967L796.605 0.253658L819.66 55.6474L387.125 235.669L536.36 297.8L603.705 325.839L536.119 353.291L388.952 413.067L536.07 472.525L604.893 500.339L536.07 528.153L389.562 587.365L819.255 758.833L797.017 814.56L297.942 615.405L228.561 587.718L297.82 559.727L444.766 500.339L297.82 440.951L229.17 413.206L297.772 385.342L445.953 325.153L297.531 263.36L230.997 235.66L297.534 207.967ZM1024.54 0.253658L525.468 207.967L458.932 235.66L525.465 263.36L673.888 325.153L525.706 385.342L457.105 413.206L525.754 440.951L672.701 500.339L525.754 559.727L456.495 587.718L525.877 615.405L1024.95 814.56L1047.19 758.833L617.496 587.365L764.005 528.153L832.827 500.339L764.005 472.525L616.886 413.067L764.053 353.291L831.64 325.839L764.294 297.8L615.059 235.669L1047.59 55.6474L1024.54 0.253658Z"
                fill="url(#paint0_linear_2418_64921)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2418_64921"
                    x1="702.887"
                    y1="157.68"
                    x2="-47.2032"
                    y2="327.348"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#AD74D9" />
                    <stop offset="1" stop-color="#AA6FD8" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default ShareYourCarPattern
