import { IUserType, IPageQaName } from 'myModels'

import {
    ANSWER_ONBOARDING_QA_SUCCESS,
    SET_PRIMARY_ONBOARDING_USER_TYPE__SUCCESS,
    SET_SECONDARY_ONBOARDING_USER_TYPE__SUCCESS,
} from './constants'

import { createAction } from '@reduxjs/toolkit'

type Ianswerpayload = {
    qa_id: string | number
    answer: string | string[]
}

type IUserTypeAnswerpayload = {
    qa_id: string | number
    answer: IUserType
    pages: IPageQaName[]
}

// export const answerOnboardingQaSuccess = createAction(
//     ANSWER_ONBOARDING_QA_SUCCESS,
//     (resolve) => (payload: Ianswerpayload) => resolve(payload)
// )

export const answerOnboardingQaSuccess = createAction(
    ANSWER_ONBOARDING_QA_SUCCESS,
    function prepare(payload: Ianswerpayload) {
        return {
            payload,
        }
    }
)

// export const setPrimaryOnboardingQaSuccess = createAction(
//     SET_PRIMARY_ONBOARDING_USER_TYPE__SUCCESS,
//     (resolve) => (payload: IUserTypeAnswerpayload) => resolve(payload)
// )

export const setPrimaryOnboardingQaSuccess = createAction(
    SET_PRIMARY_ONBOARDING_USER_TYPE__SUCCESS,
    function prepare(payload: IUserTypeAnswerpayload) {
        return {
            payload,
        }
    }
)

// export const setSecondaryOnboardingQaSuccess = createAction(
//     SET_SECONDARY_ONBOARDING_USER_TYPE__SUCCESS,
//     (resolve) => (payload: IUserTypeAnswerpayload) => resolve(payload)
// )

export const setSecondaryOnboardingQaSuccess = createAction(
    SET_SECONDARY_ONBOARDING_USER_TYPE__SUCCESS,
    function prepare(payload: IUserTypeAnswerpayload) {
        return {
            payload,
        }
    }
)
