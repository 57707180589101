import { ITasksState, IUpdateTaskReq, ICarsState } from 'entityModels'
import * as React from 'react'
import styled from 'styled-components'
import { filterByTaskStatus } from '../../../../helpers/filter/filterbyTaskStatus'
import {
    sortObjectByCreatedAt,
    sortObjectByDueDate,
} from '../../../../helpers/sort/sortByDate'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../../ol/ol'
import TaskCardsListDesktop from '../../../molecules/tasks/taskCards/taskCardsListDesktop'
import task_skeleton_desktop from '../../../atoms/skeleton/tasks/task_skeleton_desktop.png'
import task_skeleton_desktop_dark from '../../../atoms/skeleton/tasks/task_skeleton_desktop_dark.png'
import { device } from '../../../templates/displays/devices'
import AddIcon from '../../../atoms/statefulicons/addIcon'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    all_tasks: ITasksState
    all_tasks_uids: string[]
    state: {
        dropdownCars: never[]
        carSelected: string | null
        sortBy: string
        filterBy: string
        dropdownCarsWithOptions: never[]
    }
    updateTaskRequest: (payload: IUpdateTaskReq) => void
    carsData: ICarsState
    toggleCreateTaskForm: () => void
    garageCars?: any
    tasks_ids_garage_level_only?: string[]
}

const GoToBox = styled.div`
    :hover {
        border: 1px solid var(--primary, #5ec3ca);
        transform: scale(1.015);
    }

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
    background-color: var(--primary_16);
`

const Wrap = styled.div`
    display: grid;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    @media ${device.wide_desktop} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

const TaskListManagerDesktop = (props: Props) => {
    const {
        all_tasks,
        all_tasks_uids,
        state,
        updateTaskRequest,
        carsData,
        toggleCreateTaskForm,
        garageCars,
        tasks_ids_garage_level_only,
    } = props

    const { theme } = useThemes()

    let determine_ids_to_render_desktop_all = (garageOnly?: boolean) => {
        let { sortBy, filterBy } = state

        if (sortBy === OTaskFilterNSort.sort_by.created_at.name) {
            let arr: string[] = []
            if (garageOnly && tasks_ids_garage_level_only) {
                arr = sortObjectByCreatedAt(
                    all_tasks.tasks,
                    tasks_ids_garage_level_only
                )
            } else {
                arr = sortObjectByCreatedAt(all_tasks.tasks, all_tasks_uids)
            }

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr2}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else {
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        garageCars={garageCars}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            }
        } else if (sortBy === OTaskFilterNSort.sort_by.due.name) {
            let arr: string[] = []

            if (garageOnly && tasks_ids_garage_level_only) {
                arr = sortObjectByDueDate(
                    all_tasks.tasks,
                    tasks_ids_garage_level_only
                )
            } else {
                arr = sortObjectByDueDate(all_tasks.tasks, all_tasks_uids)
            }

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        garageCars={garageCars}
                        tasks_ids_to_render={arr2}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else {
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            }
        } else
            return (
                <TaskCardsListDesktop
                    carsData={carsData.cars}
                    all_tasks={all_tasks.tasks}
                    tasks_ids_to_render={
                        garageOnly === true
                            ? tasks_ids_garage_level_only
                                ? tasks_ids_garage_level_only
                                : []
                            : all_tasks_uids
                    }
                    updateTask={updateTaskRequest}
                    garageCars={garageCars}
                    toggleCreateTaskForm={toggleCreateTaskForm}
                />
            )
    }

    let determine_ids_to_render_desktop_car = () => {
        let { sortBy, filterBy } = state
        if (sortBy === 'all') {
            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr = filterByTaskStatus(
                    all_tasks.tasks,
                    carsData.tasks[props.state.carSelected!] !== undefined
                        ? carsData.tasks[props.state.carSelected!]
                        : [''],
                    true
                )

                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr = filterByTaskStatus(
                    all_tasks.tasks,
                    carsData.tasks[props.state.carSelected!] !== undefined
                        ? carsData.tasks[props.state.carSelected!]
                        : [''],
                    false
                )

                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        garageCars={garageCars}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            } else {
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={
                            carsData.tasks[props.state.carSelected!] !==
                            undefined
                                ? carsData.tasks[props.state.carSelected!]
                                : ['']
                        }
                        garageCars={garageCars}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                    />
                )
            }
        } else if (sortBy === OTaskFilterNSort.sort_by.created_at.name) {
            let arr = sortObjectByCreatedAt(
                all_tasks.tasks,
                carsData.tasks[props.state.carSelected!] !== undefined
                    ? carsData.tasks[props.state.carSelected!]
                    : ['']
            )

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr2}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else {
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            }
        } else if (sortBy === OTaskFilterNSort.sort_by.due.name) {
            let arr = sortObjectByDueDate(
                all_tasks.tasks,
                carsData.tasks[props.state.carSelected!] !== undefined
                    ? carsData.tasks[props.state.carSelected!]
                    : ['']
            )

            if (filterBy === OTaskFilterNSort.filter_by.completed.name) {
                let arr1 = filterByTaskStatus(all_tasks.tasks, arr, true)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr1}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else if (
                filterBy === OTaskFilterNSort.filter_by.outstanding.name
            ) {
                let arr2 = filterByTaskStatus(all_tasks.tasks, arr, false)
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr2}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            } else {
                return (
                    <TaskCardsListDesktop
                        carsData={carsData.cars}
                        all_tasks={all_tasks.tasks}
                        tasks_ids_to_render={arr}
                        updateTask={updateTaskRequest}
                        toggleCreateTaskForm={toggleCreateTaskForm}
                        garageCars={garageCars}
                    />
                )
            }
        } else
            return (
                <TaskCardsListDesktop
                    carsData={carsData.cars}
                    all_tasks={all_tasks.tasks}
                    tasks_ids_to_render={
                        carsData.tasks[props.state.carSelected!] !== undefined
                            ? carsData.tasks[props.state.carSelected!]
                            : ['']
                    }
                    updateTask={updateTaskRequest}
                    toggleCreateTaskForm={toggleCreateTaskForm}
                    garageCars={garageCars}
                />
            )
    }

    let skeletons = () => (
        <Wrap>
            <GoToBox
                onClick={() => {
                    toggleCreateTaskForm()
                }}
            >
                <AddIcon colour={colours[theme].primary} onClick={() => {}} />
            </GoToBox>
            {[1, 2, 3, 4, 5].map((item) => (
                <img
                    src={
                        theme === 'light'
                            ? task_skeleton_desktop
                            : task_skeleton_desktop_dark
                    }
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                />
            ))}
        </Wrap>
    )

    // check with carsData whether title

    let doesCarHaveAtTitle = (carid: string) => {
        if (carid === OOtherTaskOptions.general_task.name) {
            return true
        }
        let carsData = props.carsData

        if (carsData.cars[carid]) {
            if (carsData.cars[carid].title) {
                if (carsData.cars[carid].title.length > 1) {
                    return true
                } else return false
            }
        } else {
            return false
        }
    }

    return (
        <React.Fragment>
            {Object.keys(all_tasks.tasks).length !== 0 && (
                <>
                    {!props.state.carSelected &&
                        determine_ids_to_render_desktop_all()}

                    {props.state.carSelected ===
                        OOtherTaskOptions.general_task.name &&
                        determine_ids_to_render_desktop_all(true)}

                    {props.state.carSelected &&
                        props.state.carSelected !==
                            OOtherTaskOptions.general_task.name &&
                        doesCarHaveAtTitle(props.state.carSelected) === true &&
                        determine_ids_to_render_desktop_car()}

                    {props.state.carSelected &&
                        props.state.carSelected !==
                            OOtherTaskOptions.general_task.name &&
                        doesCarHaveAtTitle(props.state.carSelected) !== true &&
                        determine_ids_to_render_desktop_all()}
                </>
            )}

            {Object.keys(all_tasks.tasks).length === 0 && skeletons()}
        </React.Fragment>
    )
}

export default TaskListManagerDesktop
