import { useRive, Fit, Alignment, Layout } from '@rive-app/react-canvas-lite'

type Props = {
    url?: string
}

const RiveAnimation = (props: Props) => {
    const { RiveComponent } = useRive({
        src:
            props.url ??
            'https://public.rive.app/hosted/291004/130339/XhhljagMXUKZ6NmY0WWosw.riv',
        autoplay: true,
        // This is optional.Provides additional layout control.
        layout: new Layout({
            fit: Fit.Contain, // Change to: rive.Fit.Contain, or Cover
            alignment: Alignment.Center,
        }),
    })
    return <RiveComponent />
}

export default RiveAnimation
