export const costNumShorterFormatter = (num: number) => {
    if (num > 9999 && num < 1000001) {
        return (
            (num / 1000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'K'
        ) // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (
            (num / 1000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            'M'
        ) // convert to M for number from > 1 million
    } else return numberWithCommas(num)
}

export const numberWithCommas = (x: number) => {
    if (x > 0) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
        return '0.00'
    }
}

export const doSumRawTotalCosts = (amountsArray: number[]): number => {
    return amountsArray.reduce((acc, amount) => acc + amount, 0)
}
