import { ICarsObject, IDropdownItem } from 'entityModels'
import styled from 'styled-components'
import {
    IAnyErrorString,
    IOtherDriver_DriveList,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { frequency_insurance_other_driver } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { useAppSelector } from '../../../../redux/store/hooks'
import Expander from '../../../atoms/expander/expander'
import VehicleItemDesktop from '../../../atoms/list/vehicleItemDesktop'
import VehicleItemMobile from '../../../atoms/list/vehicleItemMobile'
import InsuranceQuoteFormGenerator from '../../../organisms/insuranceQuote/formGenerators'
import LandingPageMainButton from '../../../atoms/Button/landingPageMainButton'
import { useHistory } from 'react-router'
import { InsuranceFormQuestion } from '../../../organisms/insuranceQuote/formGenerators/renderElementMobile'
import info_red from '../../../../public/assets/icons/info_red.svg'
import useThemes from '../../../../providers/theme/hooks'
import colours, { insurance_colours } from '../../../../providers/theme/colours'

export const WindowBodyWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-bottom: 200px;
`
const Text = styled.p`
    width: 100%;
    padding: 0;
    margin: 0;
    color: var(--text-default, #666);
    font-size: 16px;
    font-family: Lato;
    line-height: 24px;
    letter-spacing: 0.001px;
`
const Buttons = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Cards = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Btn = styled.button`
    all: unset;
    color: var(--core-teal-500, #5ec3ca);
    text-align: center;
    font-size: 16px;
    font-family: Lato-bold;
    line-height: 24px;
    letter-spacing: 0.001px;
    cursor: pointer;
`

type Props = {
    driver_name: string
    allCars: ICarsObject
    selectedCars?: IOtherDriver_DriveList[]
    onSelect: (vals: IOtherDriver_DriveList[]) => any
    isMobile?: boolean
    errorObj?: IAnyErrorString
    driver?: any
    isFromWizard?: boolean
}

const DriveListModal = (props: Props) => {
    const {
        allCars,
        driver_name,
        selectedCars,
        onSelect,
        isMobile,
        errorObj,
        driver,
        isFromWizard,
    } = props

    const onSelectCar = (id: string) => {
        if (
            !selectedCars ||
            (selectedCars !== undefined && selectedCars.length === 0)
        ) {
            return onSelect([{ carid: id, use_frequency: undefined }])
        }

        if (selectedCars) {
            let selected_idss: string[] = selectedCars.map((item) => {
                return item.carid
            })

            let finalCars: IOtherDriver_DriveList[] = selected_idss.includes(id)
                ? selectedCars.filter((item) => item.carid !== id)
                : [...selectedCars, { carid: id, use_frequency: undefined }]

            onSelect(finalCars)
        }
    }

    const onSelectFrequency = (carID: string, frequency: IDropdownItem) => {
        if (selectedCars) {
            let finalCars = selectedCars.map((item) => {
                let itemcop = { ...item }
                if (itemcop.carid === carID) {
                    itemcop.use_frequency = frequency
                }
                return itemcop
            })

            onSelect(finalCars)
        }
    }

    const getSelectedItem = (
        loopCarID: string
    ): IOtherDriver_DriveList | undefined => {
        let foundItem: IOtherDriver_DriveList | undefined = undefined

        selectedCars?.forEach((item) => {
            if (loopCarID === item.carid) {
                foundItem = item
            }
        })

        return foundItem
    }

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )

    let vehicles_to_insure_ids_list = application.vehicles_to_insure_ids_list

    let history = useHistory()

    const { theme } = useThemes()

    return (
        <WindowBodyWrapper
            style={{
                padding: isFromWizard ? '0px' : '32px',
                paddingBottom: 240,
            }}
        >
            {vehicles_to_insure_ids_list.length > 0 ? (
                <>
                    <Text>
                        Please select which of the cars in the policy driver{' '}
                        {<strong>{driver_name}</strong>} has access to use. This
                        will be included in policy and may be changed later on
                        as part of midterm adjustment.
                    </Text>

                    {driver &&
                        driver.drive_list_errors &&
                        driver.drive_list_errors.message && (
                            <div
                                style={{
                                    backgroundColor: '#FCF3F3',
                                    width: '100%',
                                    padding: '14px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 20,
                                }}
                            >
                                <img
                                    src={info_red}
                                    alt="info-red"
                                    style={{ height: '12px' }}
                                />

                                <InsuranceFormQuestion
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].error,
                                        fontSize: '12px',
                                    }}
                                >
                                    {driver.drive_list_errors.message}
                                </InsuranceFormQuestion>
                            </div>
                        )}

                    <div
                        style={{
                            paddingTop:
                                driver &&
                                driver.drive_list_errors &&
                                driver.drive_list_errors.message
                                    ? 30
                                    : 40,
                        }}
                    />

                    <Buttons>
                        <Btn
                            onClick={() => {
                                let car_idss = Object.keys(allCars)
                                let objj: IOtherDriver_DriveList[] = []
                                for (let i = 0; i < car_idss.length; i++) {
                                    objj = [
                                        ...objj,
                                        {
                                            carid: car_idss[i],
                                            use_frequency: undefined,
                                        },
                                    ]
                                }
                                onSelect(objj)
                            }}
                            style={{
                                color: insurance_colours[theme].sections[
                                    'drivers'
                                ].primary_100,
                            }}
                        >
                            Select all
                        </Btn>
                        <Btn
                            style={{
                                color: insurance_colours[theme].sections[
                                    'drivers'
                                ].primary_100,
                            }}
                            onClick={() => onSelect([])}
                        >
                            Clear
                        </Btn>
                    </Buttons>
                </>
            ) : (
                <>
                    <Text>
                        Before assigning cars to this driver, select the cars
                        from your garage that you want to insure.
                    </Text>
                    <div style={{ paddingBottom: 32 }} />
                    <LandingPageMainButton
                        onClick={() => {
                            history.push(
                                `/insurance/application/${application.id}/vehicles`
                            )
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                                textTransform: 'none',
                                fontFamily: 'Lato',
                                letterSpacing: 'normal',
                            }}
                        >
                            Select cars to insure
                        </div>
                    </LandingPageMainButton>
                </>
            )}
            <div style={{ paddingTop: 16 }} />
            <Cards>
                {vehicles_to_insure_ids_list.map((loopCarID, index) => {
                    let isSelectedItem: IOtherDriver_DriveList | undefined =
                        getSelectedItem(loopCarID)

                    let car = allCars[loopCarID]

                    return (
                        <div
                            style={{ width: '100%', zIndex: 50 - index }}
                            key={`${car.id}_${index}_${
                                isMobile ? 'mobile' : 'desktop'
                            }`}
                        >
                            {isMobile ? (
                                <>
                                    <VehicleItemMobile
                                        car_id={car.id}
                                        garageItem={car}
                                        variant="selectable"
                                        isSelected={
                                            isSelectedItem !== undefined
                                                ? true
                                                : false
                                        }
                                        onSelect={(id: string) => {
                                            onSelectCar(id)
                                        }}
                                        sectionId="drivers"
                                        bg="var(--modal-side-sheet-pop-over-bg, #fff)"
                                    />
                                </>
                            ) : (
                                <VehicleItemDesktop
                                    car_id={car.id}
                                    garageItem={car}
                                    variant="selectable"
                                    isSelected={
                                        isSelectedItem !== undefined
                                            ? true
                                            : false
                                    }
                                    onSelect={(id: string) => {
                                        onSelectCar(id)
                                    }}
                                    sectionId="drivers"
                                    bg="var(--modal-side-sheet-pop-over-bg, #fff)"
                                />
                            )}
                            {isSelectedItem && (
                                <>
                                    <Expander
                                        height={
                                            isSelectedItem?.use_frequency
                                                ? 24
                                                : 16
                                        }
                                    />
                                    <InsuranceQuoteFormGenerator
                                        sectionID="drivers"
                                        customLineBottomPadding="8px"
                                        list={frequency_insurance_other_driver}
                                        onAnswerChange={(
                                            id: string,
                                            frequencyAnswer: any
                                        ) => {
                                            // check in case
                                            if (id === 'use_frequency') {
                                                onSelectFrequency(
                                                    loopCarID,
                                                    frequencyAnswer
                                                )
                                            }
                                        }}
                                        data={{
                                            use_frequency:
                                                isSelectedItem?.use_frequency,
                                        }}
                                        errors={
                                            isSelectedItem &&
                                            isSelectedItem.use_frequency
                                                ? undefined
                                                : errorObj &&
                                                  errorObj['use_frequency']
                                                ? errorObj
                                                : undefined
                                        }
                                        setError={(
                                            id: string,
                                            error: any
                                        ) => {}}
                                    />

                                    <div style={{ paddingTop: '24px' }} />
                                </>
                            )}
                            {!isSelectedItem && (
                                <div style={{ paddingTop: '16px' }} />
                            )}
                        </div>
                    )
                })}
            </Cards>
        </WindowBodyWrapper>
    )
}

export default DriveListModal
