import { IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import ScrollLock from 'react-scrolllock'
import styled from 'styled-components'
import { ITimelineDataByCarNormalised, ITimelineItem } from 'timelineModels'
import { RootState } from 'typesafe-actions'
import { countEntryFeaturedImages } from '../../../../helpers/numbers/countFeaturedImages'
import ReturnNav from '../../../atoms/header/returnNav'
import EntryGallerySlide from '../../../molecules/slideshow/entryGallerySlide'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import FullPageWrapper from '../../../templates/displays/pageWrappers/FullPagePageWrapper'
import { ICar, IExternalCar, IGalleryImagesObject } from 'entityModels'
import SlidingUpOnScrollBanner from '../../../atoms/header/slidingUpOnScrollBanner'
import { device } from '../../../templates/displays/devices'
import ModalDisplayGalleryImageDesktop from '../../displays/pageWrappers/modalDisplayGalleryImageDesktop'
import GalleryImageCarousel from '../../../organisms/galleryCarousel'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'

interface Props extends RouteComponentProps<any> {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    timelineData: ITimelineDataByCarNormalised | null | undefined
    imagesData: IGalleryImagesObject | null
    isGalleryLoading: any
    userLoggedIn: IUser | null
    isActiveEntryLoading: boolean
}

function mapStateToProps(state: RootState) {
    return {
        timelineData: state.timeline.timeline_data_normalised_by_car_id,
        imagesData: state.entities.galleriesData.images,
        isActiveEntryLoading: state.timeline.loading,
        isGalleryLoading: state.entities.galleriesData.loading,
    }
}

type State = {
    orderedIdList: string[]
    showEditImageForm: boolean
    showDeleteConfirmationMessage: boolean
    warningPresent: boolean
    image_index?: number
}

const AdjustedPaddingTop = styled.div`
    @media ${device.ipad} {
        padding-top: 60px;
    }
    @media ${device.mobile} {
        padding-top: 50px;
    }
`

class ReadOnlyCarHistoryFileEntryGalleryImage extends React.Component<
    Props,
    State
> {
    state = {
        orderedIdList: [],
        showEditImageForm: false,
        showDeleteConfirmationMessage: false,
        warningPresent: false,
        image_index: undefined,
    }

    setUpInitialIndex = () => {
        let params = new URLSearchParams(this.props.location.search)

        let image_id_param = params.get('id')

        let carid: string = this.props.match.params.carid
        let entryid = this.props.match.params.entryid

        let all_entries =
            this.props.timelineData &&
            this.props.timelineData[carid] &&
            this.props.timelineData[carid].entries

        let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
            function (entry: ITimelineItem) {
                return entry.id === entryid
            }
        )

        let activeEntry =
            all_entries &&
            indexOFActiveEntry !== undefined &&
            all_entries[indexOFActiveEntry]
        if (activeEntry && activeEntry.gallery_image_ids && image_id_param) {
            let numberr = activeEntry.gallery_image_ids.indexOf(image_id_param)
            this.setState({
                ...this.state,
                image_index: numberr,
            })
        }
    }

    componentDidMount() {
        this.setUpInitialIndex()
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            if (this.props.userLoggedIn) {
                this.setUpInitialIndex()
            }
        }

        if (prevProps.timelineData !== this.props.timelineData) {
            this.setUpInitialIndex()
        }
    }

    generateImageNavigationUrl = (next: string) => {
        let params = new URLSearchParams(this.props.location.search)

        let image_id_param = params.get('id')

        let carid: string = this.props.match.params.carid
        let entryid = this.props.match.params.entryid

        if (image_id_param) {
            // change with equivalent for entry gallery image
            let all_entries =
                this.props.timelineData &&
                this.props.timelineData[carid] &&
                this.props.timelineData[carid].entries

            let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
                function (entry: ITimelineItem) {
                    return entry.id === entryid
                }
            )

            let activeEntry =
                all_entries &&
                indexOFActiveEntry !== undefined &&
                all_entries[indexOFActiveEntry]
            const ids_list = activeEntry && activeEntry.gallery_image_ids

            if (ids_list) {
                const index_of_id = ids_list.indexOf(image_id_param)

                if (next === 'next') {
                    const index_of_next_image = index_of_id + 1

                    if (index_of_next_image === -1) {
                        return '/none'
                    }

                    const id_of_next_image = ids_list[index_of_next_image]

                    if (id_of_next_image !== undefined) {
                        return `${this.props.history.location.pathname}?id=${id_of_next_image}`
                    } else return '/none'
                } else {
                    const index_of_previous_image = index_of_id - 1

                    if (index_of_previous_image === -1) {
                        return '/none'
                    }

                    const id_of_previous_image =
                        ids_list[index_of_previous_image]

                    return `${this.props.history.location.pathname}?id=${id_of_previous_image}`
                }
            } else {
                return '/none'
            }
        } else {
            return '/none'
        }
    }

    render() {
        const {
            isGalleryLoading,
            timelineData,
            imagesData,
            sharedCar,
            prevOwned,
        } = this.props

        let carid: string = this.props.match.params.carid
        let entryid = this.props.match.params.entryid

        let params = new URLSearchParams(this.props.location.search)

        let entryId = this.props.match.params.entryid
        let all_entries =
            timelineData && timelineData[carid] && timelineData[carid].entries

        let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
            function (entry: ITimelineItem) {
                return entry.id === entryId
            }
        )

        let activeEntry =
            all_entries &&
            indexOFActiveEntry !== undefined &&
            all_entries[indexOFActiveEntry]

        let image_id_p = params.get('id')

        let prev_url = this.generateImageNavigationUrl('previous')
        let next_url = this.generateImageNavigationUrl('next')

        let current = sharedCar ?? prevOwned
        let windowSize = window.innerWidth

        let current_image_id =
            windowSize < 800 &&
            this.state.image_index &&
            activeEntry &&
            activeEntry.gallery_image_ids
                ? activeEntry.gallery_image_ids[this.state.image_index]
                : image_id_p

        return (
            <>
                <ScrollLock isActive={true} />
                <DesktopDisplayOnly>
                    <FullPageWrapper>
                        <ModalDisplayGalleryImageDesktop
                            toggle={() => {
                                this.props.history.push(
                                    this.props.location.pathname
                                )
                            }}
                            isOpen={true}
                        >
                            {imagesData && (
                                <EntryGallerySlide
                                    closeGallery={() => {
                                        this.props.history.push(
                                            this.props.location.pathname
                                        )
                                    }}
                                    currentImageData={
                                        imagesData &&
                                        imagesData[params.get('id')!]
                                    }
                                    carid={carid}
                                    entryid={entryid}
                                    imagesData={imagesData}
                                    deleteImage={() => {}}
                                    isSomethingLoading={isGalleryLoading}
                                    prevUrl={prev_url}
                                    nextUrl={next_url}
                                    onClickEdit={() => {}}
                                    updateEntryGalleryImage={() => {}}
                                    featuredImagesCount={
                                        activeEntry && imagesData
                                            ? countEntryFeaturedImages(
                                                  activeEntry,
                                                  imagesData
                                              )
                                            : 0
                                    }
                                    isExternalCar
                                />
                            )}
                        </ModalDisplayGalleryImageDesktop>
                    </FullPageWrapper>
                </DesktopDisplayOnly>

                <IpadAndMobileDisplay>
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                            background: 'var(--bg-color, #fafafa)',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            height: '100vh',
                        }}
                    >
                        <Link
                            to={{
                                pathname: this.props.history.location.pathname,
                                state: { prevPath: this.props.match.url },
                            }}
                        >
                            <ReturnNav />
                        </Link>
                        {current && (
                            <SlidingUpOnScrollBanner
                                text={
                                    current.title.length > 32
                                        ? `${current.title.slice(0, 34)}...`
                                        : current.title
                                }
                                customStyle={{
                                    transform: 'translateY(50px)',
                                }}
                            />
                        )}
                        <AdjustedPaddingTop />

                        {activeEntry &&
                        current_image_id &&
                        activeEntry.gallery_image_ids &&
                        imagesData &&
                        imagesData[current_image_id] &&
                        this.state.image_index !== undefined ? (
                            <GalleryImageCarousel
                                indexOfInitialImage={this.state.image_index}
                                image_data={this.props.imagesData}
                                images_ids={activeEntry.gallery_image_ids}
                                synchroniseActiveIndex={(n: number) => {
                                    this.setState({
                                        ...this.state,
                                        image_index: n,
                                    })
                                }}
                                openActionsSheet={() => {}}
                                cover_id={''}
                                readOnlyMode={true}
                            />
                        ) : (
                            <SkeletonAbsolute isthingloading={true} />
                        )}
                    </div>
                </IpadAndMobileDisplay>
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, {})(ReadOnlyCarHistoryFileEntryGalleryImage)
)
