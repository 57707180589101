import { call, fork, join, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'

import { api } from '../../../services'
import {
    entityDeletionSaga,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import {
    IInsuranceApplication_API,
    IInsuranceEverydayVehicleID,
} from '../../types'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from './get'
import posthog from 'posthog-js'

type IVehicleObj = {
    uid?: string
    external_id?: string
    is_everyday: boolean
}

export function* Submit_everydaycar_ids(draftArray: string[]): any {
    try {
        posthog.capture('Update insurance application everyday car')

        let application_has_deletions = false
        let application_has_patch = false

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let existing: string[] = all_existing.everyday_car_ids ?? []

        let externalCarIds = all_existing.external_car_ids ?? []
        let dataToSendToPatch: IAnyObject = {}

        let carIDSToUpdateAsEveryday: IVehicleObj[] = []

        let externalCarIDsToUpdateAsEveryday: IVehicleObj[] = []

        draftArray.forEach((carID: string) => {
            // check its not already as everyday because then no need to update
            if (existing.indexOf(carID) === -1) {
                // check its not external car
                if (externalCarIds?.indexOf(carID) === -1) {
                    let api_uid: string | undefined =
                        all_existing && all_existing.vehicles[carID]?.api_uid

                    // console.log('carID', carID)
                    // console.log('all_existing vehicles', all_existing.vehicles)
                    // console.log(
                    //     'all_existing vehicles car',
                    //     all_existing.vehicles[carID]
                    // )
                    // console.log('api_uid', api_uid)

                    if (!api_uid) {
                        carIDSToUpdateAsEveryday = [
                            ...carIDSToUpdateAsEveryday,
                            { external_id: carID, is_everyday: true },
                        ]
                    } else {
                        carIDSToUpdateAsEveryday = [
                            ...carIDSToUpdateAsEveryday,
                            { uid: api_uid, is_everyday: true },
                        ]
                    }
                }

                // if external

                if (externalCarIds?.indexOf(carID) !== -1) {
                    externalCarIDsToUpdateAsEveryday = [
                        ...externalCarIDsToUpdateAsEveryday,
                        { uid: carID, is_everyday: true },
                    ]
                }
            }
        })

        let carIDsToSetAsNonEveryday: IAnyObject[] = []
        let externalcarIDsToSetAsNonEveryday: IAnyObject[] = []

        existing.forEach((carID: string) => {
            if (draftArray.indexOf(carID) === -1) {
                // check if external car ( yes)
                if (externalCarIds?.indexOf(carID) !== -1) {
                    externalcarIDsToSetAsNonEveryday = [
                        ...externalcarIDsToSetAsNonEveryday,
                        { uid: `${carID}`, is_everyday: false },
                    ]
                } else {
                    // ccustodian_car
                    let api_uid: string | undefined =
                        all_existing && all_existing.vehicles[carID]?.api_uid

                    if (api_uid) {
                        carIDsToSetAsNonEveryday = [
                            ...carIDsToSetAsNonEveryday,
                            { uid: `${api_uid}`, is_everyday: false },
                        ]
                    } else if (carID) {
                        carIDsToSetAsNonEveryday = [
                            ...carIDsToSetAsNonEveryday,
                            { external_id: `${carID}`, is_everyday: false },
                        ]
                    }
                }
            }
        })

        let finaCarlArr = [
            ...carIDSToUpdateAsEveryday,
            ...carIDsToSetAsNonEveryday,
        ]

        let externalCarsFinalArr = [
            ...externalCarIDsToUpdateAsEveryday,
            ...externalcarIDsToSetAsNonEveryday,
        ]

        let newEverydayGarageCarsToCreate: IInsuranceEverydayVehicleID[] = []
        let newEverydayGarageCarsToCreateExternalIDs: string[] = []

        if (finaCarlArr.length > 0 || externalCarsFinalArr.length > 0) {
            // dataToSendToPatch = {
            //     customer: {
            //         custodian_cars: [...finaCarlArr],
            //         external_vehicles: [...externalCarsFinalArr],
            //     },
            // }

            if (
                (externalcarIDsToSetAsNonEveryday &&
                    externalcarIDsToSetAsNonEveryday.length > 0) ||
                (carIDsToSetAsNonEveryday &&
                    carIDsToSetAsNonEveryday.length > 0)
            ) {
                let arrayOfDeletionTasks = []

                if (
                    externalcarIDsToSetAsNonEveryday &&
                    externalcarIDsToSetAsNonEveryday.length > 0
                ) {
                    // call deassign endpoint

                    for (
                        let i = 0;
                        i < externalcarIDsToSetAsNonEveryday.length;
                        i++
                    ) {
                        let req_del: IDeleteInsuranceApplication_req = {
                            appli_id: `${all_existing.id}`,
                            entity_id: externalcarIDsToSetAsNonEveryday[i].uid,
                            entity_type: 'external_everyday_cars',
                        }
                        const deletionTask = yield fork(entityDeletionSaga, {
                            ...req_del,
                        })
                        arrayOfDeletionTasks.push(deletionTask)
                    }
                }

                if (
                    carIDsToSetAsNonEveryday &&
                    carIDsToSetAsNonEveryday.length > 0
                ) {
                    // call deassign endpoint

                    for (let i = 0; i < carIDsToSetAsNonEveryday.length; i++) {
                        let req_del: IDeleteInsuranceApplication_req = {
                            appli_id: `${all_existing.id}`,
                            entity_id: carIDsToSetAsNonEveryday[i].uid,
                            entity_type: 'everyday_custodian_cars',
                        }
                        const deletionTask = yield fork(entityDeletionSaga, {
                            ...req_del,
                        })
                        arrayOfDeletionTasks.push(deletionTask)
                    }
                }

                if (arrayOfDeletionTasks.length > 0) {
                    yield join(arrayOfDeletionTasks)
                    application_has_deletions = true
                }
            }

            let everyday_ids: IInsuranceEverydayVehicleID[] = []

            if (
                externalCarIDsToUpdateAsEveryday &&
                externalCarIDsToUpdateAsEveryday.length > 0
            ) {
                let arr1: IInsuranceEverydayVehicleID[] = []

                externalCarIDsToUpdateAsEveryday.forEach((item) => {
                    let rr: IInsuranceEverydayVehicleID = {
                        external_vehicle_uid: item.uid,
                    }
                    arr1 = [...arr1, rr]
                })

                everyday_ids = [...everyday_ids, ...arr1]
            }

            // console.log('carIDSToUpdateAsEveryday', carIDSToUpdateAsEveryday)

            if (
                carIDSToUpdateAsEveryday &&
                carIDSToUpdateAsEveryday.length > 0
            ) {
                let arr2: IInsuranceEverydayVehicleID[] = []

                carIDSToUpdateAsEveryday.forEach((item) => {
                    if (item.uid) {
                        let rr: IInsuranceEverydayVehicleID = {
                            custodian_car_uid: item.uid,
                        }
                        arr2 = [...arr2, rr]
                    }
                    // this is for garage cars not part of the app - it fails
                    else if (item.external_id && !item.uid) {
                        let rr: IInsuranceEverydayVehicleID = {
                            external_id: item.external_id,
                        }

                        newEverydayGarageCarsToCreateExternalIDs = [
                            ...newEverydayGarageCarsToCreateExternalIDs,
                            item.external_id,
                        ]
                        newEverydayGarageCarsToCreate = [
                            ...newEverydayGarageCarsToCreate,
                            rr,
                        ]
                    }
                })

                everyday_ids = [...everyday_ids, ...arr2]

                if (newEverydayGarageCarsToCreate.length > 0) {
                    dataToSendToPatch = {
                        ...dataToSendToPatch,
                        customer: {
                            custodian_cars: [...newEverydayGarageCarsToCreate],
                        },
                    }
                }
            }

            dataToSendToPatch = {
                ...dataToSendToPatch,
                application_everyday_vehicles: [...everyday_ids],
            }

            // console.log('data to patch', dataToSendToPatch)
            // call api to patch as thats all the data merged we want to patch
            let res: IInsuranceApplication_API = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            application_has_patch = true

            if (newEverydayGarageCarsToCreate.length > 0) {
                let new_ids_to_add_as_everyday: IInsuranceEverydayVehicleID[] =
                    []

                if (
                    res.customer?.custodian_cars &&
                    res.customer?.custodian_cars.length > 0
                ) {
                    for (
                        let i = 0;
                        i < res.customer?.custodian_cars.length;
                        i++
                    ) {
                        let idd = res.customer?.custodian_cars[i].external_id
                        if (
                            idd &&
                            newEverydayGarageCarsToCreateExternalIDs.indexOf(
                                `${idd}`
                            ) !== -1
                        ) {
                            new_ids_to_add_as_everyday = [
                                ...new_ids_to_add_as_everyday,
                                {
                                    custodian_car_uid:
                                        res.customer?.custodian_cars[i].uid,
                                },
                            ]
                        }
                    }
                }

                if (new_ids_to_add_as_everyday.length > 0) {
                    let newDataTopatch = {
                        application_everyday_vehicles: [
                            ...new_ids_to_add_as_everyday,
                        ],
                    }
                    res = yield call(
                        api.insuranceApplication.patchInsuranceApplication,
                        {
                            id: `${all_existing.id}`,
                            data: { ...newDataTopatch },
                        }
                    )
                }
            }
            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_everyday_car_ids_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(insuranceActions.submit_everyday_car_ids_success())
        }

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_everydaycar_ids,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )

            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_everyday_car_ids_error(customError)
            )
        }
    }
}

function* WatcherSubmit_everydayids() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_everyday_car_ids_request
        )
        yield call(Submit_everydaycar_ids, payload)
    }
}

const everydaycar_ids_submit: any[] = [fork(WatcherSubmit_everydayids)]

export default everydaycar_ids_submit
