import { IFormValidationCopy } from 'formValidationCopyModels'

export const formValidationCopyData: IFormValidationCopy = {
    years: {
        car_year: {
            min: 1800,
            max: new Date().getFullYear() + 5,
            warning_min: 'Please enter a year from 1800.',
            warning_max: `Please enter a year before ${
                new Date().getFullYear() + 5
            }.`,
        },
    },
}
