function getVimeoVideoId(url: string) {
    const match = url.match(/vimeo\.com\/(\d+)/)
    if (match) {
        return match[1]
    } else {
        return null
    }
}

export const getEmbedIDFromVideoURL = (url: string): string | undefined => {
    if (/^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=[\w-]{11}/.test(url)) {
        let video_id = url.split('v=')[1]

        if (video_id) {
            let ampersandPosition = video_id?.indexOf('&')
            if (ampersandPosition != -1) {
                video_id = video_id?.substring(0, ampersandPosition)
            }

            return `https://www.youtube.com/embed/${video_id}`
        }
    } else if (/^(https?:\/\/)?youtu\.be\/[\w-]{11}/.test(url)) {
        let video_id = url.split('/')[3]

        if (video_id) {
            let qparam = video_id?.indexOf('?')
            if (qparam != -1) {
                video_id = video_id?.substring(0, qparam)
            }
            return `https://www.youtube.com/embed/${video_id}`
        }
    } else if (/^(https?:\/\/)?(www\.)?vimeo\.com\/\d{8}/.test(url)) {
        let video_id = getVimeoVideoId(url)

        return `https://player.vimeo.com/video/${video_id}`
    }
}
