import { ActionType } from 'typesafe-actions'

import { faqPageCopyData, helpContactReasonsData } from './data'

import { IFAQPageCopy, IHelpPageContact } from 'helpPageCopyModels'
import * as actions from './actions/actions'

export type IHelpPageState = {
    faqPageCopy: IFAQPageCopy
    selectedContactReason: IHelpPageContact
}

type Actions = ActionType<typeof actions>

const initialState = {
    faqPageCopy: faqPageCopyData,
    selectedContactReason: helpContactReasonsData,
}

export const help_page_copy = (
    state: IHelpPageState = initialState,
    action: Actions
) => {
    switch (action.type) {
        case actions.selectContactReason.type:
            return Object.assign({}, state, {
                ...state,
                selectedContactReason: {
                    ...state.selectedContactReason,
                    selected: action.payload,
                },
            })
        default:
            return state
    }
}

export default help_page_copy

export type HelpPageState = ReturnType<typeof help_page_copy>
