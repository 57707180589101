import { IDropdownItem } from 'entityModels'
import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { IInsuranceFieldType } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { useAppSelector } from '../../../../redux/store/hooks'
import NoDropdownDataBox from '../../../atoms/box/noDropdownDataBox'
import NoMatchFoundBox from '../../../atoms/box/noMatchFoundBox'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { insurance_colours } from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
`

type IStyled = {
    isChecked?: boolean
    customCheckedColor?: string
}

const OptionWrap = styled.div<IStyled>`
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 28px;
    padding-right: 24px;
    margin-bottom: 2px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background-color: ${(props: IStyled) =>
        props.isChecked
            ? props.customCheckedColor ?? 'var(--primary_04)'
            : 'var(--bg-color, #fff)'};
    color: var(--text-default, #666666);
    font-size: 16px;
    font-family: Lato;

    line-height: 24px;

    transition: all 100ms;

    cursor: pointer;
`

type Props = {
    placeholder?: string
    customIsCheckedFunction?: (item: IDropdownItem) => boolean
    value?: any
    UIcopy: IInsuranceFieldType
    onChange: (value: IDropdownItem) => void
    isValueLabel?: boolean
    allowAdd?: boolean
    addAnItemFunc?: (id: string, name: string) => any
    sectionId?: IInsuranceApplicationSectionId
}

const MobileInsuranceOptionsSearchChild = (props: Props) => {
    let { isValueLabel, allowAdd, sectionId } = props

    // const dispatch = useAppDispatch()
    const [valueTyped, setValueTyped] = useState<string>('')

    const [localOptions, setLocalOptions] = useState<IDropdownItem[]>([])

    let all_options: IDropdownItem[] | undefined = props.UIcopy.options

    useEffect(() => {
        if (all_options) {
            // @ts-ignore
            setLocalOptions(all_options)
        }
    }, [all_options])

    const FuseOptions = {
        includeScore: true,
        keys: isValueLabel ? ['label'] : ['name'],
    }

    const fuse_data = all_options && new Fuse(all_options, FuseOptions)

    const onSearch = (val: string) => {
        setValueTyped(val)

        if (fuse_data) {
            const fuse_results: Fuse.FuseResult<IDropdownItem>[] =
                fuse_data.search(val, {
                    limit: 5,
                })

            let res: IDropdownItem[] = []

            fuse_results.forEach((elem: Fuse.FuseResult<IDropdownItem>) => {
                if (
                    elem.item &&
                    elem.score &&
                    elem.score < 0.45
                    // &&
                    // !activeCategories.includes(elem.item.uid)
                ) {
                    res = [...res, elem.item]
                }
            })

            setLocalOptions(res)
        }

        return
    }

    let optionsToRender: IDropdownItem[] | undefined =
        localOptions && localOptions.length > 0
            ? localOptions
            : valueTyped && localOptions.length === 0
            ? []
            : all_options && all_options.length > 0
            ? all_options
            : undefined
    let isSearchLoading: boolean | undefined = useAppSelector((state) => {
        return state.showroom.isSearchLoading
    })

    const { theme } = useThemes()
    return (
        <Wrapper>
            {!isSearchLoading && !optionsToRender && !allowAdd && (
                <div
                    style={{ width: '100%', paddingLeft: 24, paddingRight: 24 }}
                >
                    {' '}
                    <NoDropdownDataBox name={props.UIcopy.text.toLowerCase()} />
                </div>
            )}

            {optionsToRender !== undefined && (
                <div
                    style={{
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        width: '100%',
                    }}
                >
                    <InputFieldNoFormikAnimated
                        disabled={false}
                        id={'insurance_fields_mobile_search'}
                        // name={props.UIcopy.text}
                        name=""
                        placeholder={
                            props.placeholder ? props.placeholder : 'Search'
                        }
                        // tabIndex={1}
                        aria-live="polite"
                        value={valueTyped}
                        onChange={(e: any) => {
                            onSearch(e.target.value)
                        }}
                        width={'100%'}
                        data-attr={'insurance_fields_mobile_search'}
                        placeholderStyle={'text-transform: none;'}
                        customActiveColour={
                            sectionId
                                ? insurance_colours[theme].sections[sectionId]
                                      .primary_100
                                : undefined
                        }
                    />
                </div>
            )}

            {optionsToRender === undefined && allowAdd && (
                <div
                    style={{
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        width: '100%',
                    }}
                >
                    <InputFieldNoFormikAnimated
                        disabled={false}
                        id={'insurance_fields_mobile_add'}
                        // name={props.UIcopy.text}
                        name=""
                        placeholder={
                            props.placeholder ? props.placeholder : 'Search'
                        }
                        // tabIndex={1}
                        aria-live="polite"
                        value={valueTyped}
                        onChange={(e: any) => {
                            setValueTyped(e.target.value)
                            // onSearch(e.target.value)
                        }}
                        width={'100%'}
                        data-attr={'insurance_fields_mobile_add'}
                        placeholderStyle={'text-transform: none;'}
                        customActiveColour={
                            sectionId
                                ? insurance_colours[theme].sections[sectionId]
                                      .primary_100
                                : undefined
                        }
                    />
                </div>
            )}

            {!isSearchLoading &&
                optionsToRender &&
                optionsToRender.length === 0 &&
                allowAdd && (
                    <div
                        style={{
                            width: '100%',
                            paddingTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ButtonAtom
                            onClick={(e: any) => {
                                if (valueTyped) {
                                    let trimmed_val = valueTyped.replace(
                                        /\s/g,
                                        ''
                                    )
                                    if (
                                        trimmed_val.length >= 1 &&
                                        trimmed_val.length < 70
                                    ) {
                                        e.preventDefault()

                                        let trimmed_val_final =
                                            valueTyped.trim()

                                        if (isValueLabel) {
                                            props.onChange({
                                                uid: trimmed_val_final,
                                                value: trimmed_val_final,
                                                label: trimmed_val_final,
                                            })
                                        } else
                                            props.onChange({
                                                uid: trimmed_val_final,
                                                name: trimmed_val_final,
                                            })

                                        if (props.addAnItemFunc) {
                                            props.onChange({
                                                uid: 'new_entry',
                                                name: trimmed_val_final,
                                            })

                                            props.addAnItemFunc(
                                                `${props.UIcopy?.id}`,
                                                trimmed_val_final
                                            )
                                        }
                                    }
                                }
                            }}
                            theme="secondary"
                            width="90%"
                            alignLeft={true}
                            customBgColor={
                                sectionId
                                    ? insurance_colours[theme].sections[
                                          sectionId
                                      ].primary_100
                                    : undefined
                            }
                        >
                            add {valueTyped}
                        </ButtonAtom>
                    </div>
                )}

            <div style={{ paddingTop: '20px' }} />

            {!isSearchLoading &&
                optionsToRender &&
                optionsToRender.length === 0 &&
                !allowAdd && (
                    <div
                        style={{
                            paddingLeft: '24px',
                            paddingRight: '24px',
                            width: '100%',
                        }}
                    >
                        <NoMatchFoundBox />
                    </div>
                )}

            <div style={{ paddingTop: '10px' }} />
            {optionsToRender !== undefined &&
                optionsToRender?.length > 0 &&
                optionsToRender.map((item: IDropdownItem, index: number) => {
                    if (item.name !== '-') {
                        // TODO better is selected check
                        let isChecked: boolean =
                            props.value &&
                            (props.value === item.uid ||
                                (props.value?.uid &&
                                    (props.value?.uid === item.uid ||
                                        props.value?.uid === item.value)) ||
                                // @ts-ignore
                                props.value === item.id ||
                                props.value === item.value)
                                ? true
                                : false

                        return (
                            <OptionWrap
                                key={item.uid ?? item.value}
                                role="button"
                                onClick={() => {
                                    props.onChange(item)
                                }}
                                isChecked={isChecked}
                                customCheckedColor={
                                    sectionId
                                        ? insurance_colours[theme].sections[
                                              sectionId
                                          ].primary_04
                                        : undefined
                                }
                            >
                                {item.name ?? item.label}
                            </OptionWrap>
                        )
                    } else return null
                })}
        </Wrapper>
    )
}

export default MobileInsuranceOptionsSearchChild
