import { IDropdownItem } from 'entityModels'
import { ICostItem, ITimelineItem } from 'timelineModels'
import {
    currency_data,
    currency_IDS,
    timeline_category_data,
} from '../../../../../../redux/timeline/data'
import InputFieldNoFormikAnimated from '../../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import InsuranceSelect from '../../../../../atoms/select/insuranceSelect'
import { Select } from '../../../../../atoms/select'
import { FieldRow } from '../../../../insuranceQuote/formGenerators/renderElementMobile'
import dayjs from 'dayjs'
import InputField from '../../../../../atoms/Inputfield/inputField'
import { historyFileBaseEntryInsurance } from '../insurance/insuranceFormBody'
import CalendarIcon from '../../../../../atoms/icons/components/calendar'
import useThemes from '../../../../../../providers/theme/hooks'

type Props = {
    entry: undefined | ITimelineItem
    setEntry: (p: ITimelineItem | undefined) => any
    isMobile?: boolean
}

export const historyFileBaseEntry: ITimelineItem = {
    costUIDS: undefined,
    id: '',
    occurred_at: undefined,
    title: undefined,
    categoryID: 'other',
    description: undefined,
    total_cost_amount_display_short: '0.00',
    total_cost_amount_display: '0.00',
    labels: [],
    notes: undefined,
    attachmentIDs: undefined,
    costItemsObj: undefined,
}

export const JourneyHFEntriesForm = (props: Props) => {
    const optionss: IDropdownItem[] = Object.keys(timeline_category_data).map(
        (idd: string) => {
            return {
                uid:
                    timeline_category_data[idd].id.charAt(0).toUpperCase() +
                    timeline_category_data[idd].id.slice(1),
                name:
                    timeline_category_data[idd].name.charAt(0).toUpperCase() +
                    timeline_category_data[idd].name.slice(1),
            }
        }
    )

    let costt: ICostItem | undefined =
        props.entry?.costItemsObj && props.entry?.costItemsObj['id']

    // console.log('entry', props.entry)

    const { theme } = useThemes()

    return (
        <>
            <InsuranceSelect
                screen_name={'desktop'}
                name={'category'}
                value={
                    props.entry?.categoryID
                        ? {
                              uid: props.entry?.categoryID?.toUpperCase(),
                              name: props.entry?.categoryID?.toUpperCase(),
                          }
                        : undefined
                }
                options={optionss}
                placeholder={'category'}
                onChange={(elem: IDropdownItem) => {
                    if (props.entry) {
                        props.setEntry({
                            ...props.entry,
                            // @ts-ignore
                            categoryID: elem.uid?.toLocaleLowerCase(),
                        })
                    } else {
                        props.setEntry({
                            ...historyFileBaseEntry,
                            // @ts-ignore
                            categoryID: elem.uid?.toLocaleLowerCase(),
                        })
                    }
                }}
                backgroundColor={
                    props.isMobile
                        ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        : 'var(--bg-color, #ffffff)'
                }
            />

            {props.entry?.categoryID === 'insurance' ? (
                <>
                    <InputField
                        theme={theme}
                        disabled={false}
                        id="insurance_entry_policy_start_date"
                        name="Policy start date"
                        placeholder={'Policy start date'}
                        customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                        // helperText="Type or select a date (yyyy/mm/dd)"
                        tabindex={1}
                        aria-live="polite"
                        defaultValue={
                            props.entry?.insurance_entry_policy_start_date
                        }
                        value={props.entry?.insurance_entry_policy_start_date}
                        font_family="Lato"
                        restrictFlipOfDatePopUp
                        onChange={(dateString: any) => {
                            let newavall = dayjs(new Date(dateString)).isValid()
                                ? dayjs(new Date(dateString)).add(1, 'year')
                                : undefined

                            if (props.entry) {
                                props.setEntry({
                                    ...props.entry,
                                    insurance_entry_policy_start_date:
                                        dateString?.toString(),
                                    insurance_entry_policy_end_date:
                                        newavall?.toString(),
                                })
                            } else {
                                props.setEntry({
                                    ...historyFileBaseEntryInsurance,
                                    insurance_entry_policy_start_date:
                                        dateString?.toString(),
                                    insurance_entry_policy_end_date:
                                        newavall?.toString(),
                                })
                            }
                        }}
                        type="date"
                        // width="100%"
                        fontSize="16px"
                        paddingLeft="0px"
                        backgroundColor={
                            props.isMobile
                                ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                                : 'var(--bg-color, #ffffff)'
                        }
                        // dataCyId={props.dataCyId}
                        rightAdornment={<CalendarIcon />}
                    />
                    <InputField
                        theme={theme}
                        disabled={false}
                        id="insurance_entry_policy_end_date"
                        name="'Policy end date'"
                        placeholder={'Policy end date'}
                        customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                        // helperText="Type or select a date (yyyy/mm/dd)"
                        aria-live="polite"
                        defaultValue={
                            props.entry?.insurance_entry_policy_end_date
                        }
                        value={props.entry?.insurance_entry_policy_end_date}
                        font_family="Lato"
                        restrictFlipOfDatePopUp
                        onChange={(dateString: any) => {
                            if (props.entry) {
                                props.setEntry({
                                    ...props.entry,
                                    insurance_entry_policy_end_date:
                                        dateString?.toString(),
                                })
                            } else {
                                props.setEntry({
                                    ...historyFileBaseEntryInsurance,
                                    insurance_entry_policy_end_date:
                                        dateString?.toString(),
                                })
                            }
                        }}
                        type="date"
                        // width="100%"
                        fontSize="16px"
                        paddingLeft="0px"
                        backgroundColor={
                            props.isMobile
                                ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                                : 'var(--bg-color, #ffffff)'
                        }
                        // dataCyId={props.dataCyId}
                        rightAdornment={<CalendarIcon />}
                    />

                    <InputFieldNoFormikAnimated
                        name={'insurer'}
                        value={
                            props.entry?.insurance_entry_underwriter ??
                            undefined
                        }
                        placeholder={''}
                        onChange={(e: any) => {
                            if (props.entry) {
                                props.setEntry({
                                    ...props.entry,
                                    insurance_entry_underwriter: e.target.value,
                                })
                            } else {
                                props.setEntry({
                                    ...historyFileBaseEntry,
                                    insurance_entry_underwriter: e.target.value,
                                })
                            }
                        }}
                    />
                    <InputFieldNoFormikAnimated
                        name={'broker (if applicable)'}
                        value={props.entry?.insurance_entry_broker ?? undefined}
                        placeholder={''}
                        onChange={(e: any) => {
                            if (props.entry) {
                                props.setEntry({
                                    ...props.entry,
                                    insurance_entry_broker: e.target.value,
                                })
                            } else {
                                props.setEntry({
                                    ...historyFileBaseEntry,
                                    insurance_entry_broker: e.target.value,
                                })
                            }
                        }}
                        backgroundColor={
                            props.isMobile
                                ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                                : 'var(--bg-color, #ffffff)'
                        }
                    />
                </>
            ) : (
                <InputField
                    theme={theme}
                    disabled={false}
                    id="occurred_at"
                    tabIndex={1}
                    name={'occurred at'}
                    placeholder={'Occurred At'}
                    customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                    // helperText="Type or select a date (yyyy/mm/dd)"
                    aria-live="polite"
                    defaultValue={props.entry?.occurred_at}
                    value={props.entry?.occurred_at ?? undefined}
                    font_family="Lato"
                    restrictFlipOfDatePopUp
                    onChange={(dateString: any) => {
                        if (props.entry) {
                            props.setEntry({
                                ...props.entry,
                                occurred_at: dateString,
                            })
                        } else {
                            props.setEntry({
                                ...historyFileBaseEntry,
                                occurred_at: dateString,
                            })
                        }
                    }}
                    type="date"
                    // width="100%"
                    fontSize="16px"
                    paddingLeft="0px"
                    backgroundColor={
                        props.isMobile
                            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                            : 'var(--bg-color, #ffffff)'
                    }
                    // dataCyId={props.dataCyId}
                    rightAdornment={<CalendarIcon />}
                />
            )}

            <InputFieldNoFormikAnimated
                name={'title'}
                value={props.entry?.title ?? undefined}
                placeholder={''}
                onChange={(e: any) => {
                    if (props.entry) {
                        props.setEntry({
                            ...props.entry,
                            title: e.target.value,
                        })
                    } else {
                        props.setEntry({
                            ...historyFileBaseEntry,
                            title: e.target.value,
                        })
                    }
                }}
                backgroundColor={
                    props.isMobile
                        ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                        : 'var(--bg-color, #ffffff)'
                }
            />

            <FieldRow style={{ width: '100%' }}>
                <InputFieldNoFormikAnimated
                    width="100%"
                    type={'number'}
                    name={'cost amount'}
                    value={costt?.amount ?? undefined}
                    placeholder={''}
                    onChange={(e: any) => {
                        let replaceEntry = props.entry ?? historyFileBaseEntry
                        let replaceCost = costt ?? {
                            id: 'id',
                            amount: e.target.value ?? undefined,
                            currency: 'GBP',
                        }
                        props.setEntry({
                            ...replaceEntry,
                            costItemsObj: {
                                id: {
                                    ...replaceCost,
                                    amount: e.target.value ?? undefined,
                                },
                            },
                        })
                    }}
                    backgroundColor={
                        props.isMobile
                            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                            : 'var(--bg-color, #ffffff)'
                    }
                />
                <Select
                    maxWidth="130px"
                    value={costt?.currency ?? 'GBP'}
                    defaultValue={costt?.currency ?? 'GBP'}
                    onChange={(val: string) => {
                        let replaceEntry = props.entry ?? historyFileBaseEntry
                        let replaceCost = costt ?? {
                            id: 'id',
                            amount: undefined,
                            currency: val,
                        }

                        props.setEntry({
                            ...replaceEntry,
                            costItemsObj: {
                                id: {
                                    ...replaceCost,
                                    currency: val,
                                },
                            },
                        })
                    }}
                    options={
                        currency_IDS?.map((optionID: string, index: number) => {
                            let option: IDropdownItem | undefined =
                                currency_data[optionID]
                            return {
                                value: option?.name.toUpperCase(),
                                label: option?.name.toUpperCase(),
                            }
                        }) ?? []
                    }
                    backgroundColor={
                        props.isMobile
                            ? 'var(--modal-side-sheet-pop-over-bg, #fff)'
                            : 'var(--bg-color, #ffffff)'
                    }
                />
            </FieldRow>
            <div style={{ paddingTop: 28 }} />
        </>
    )
}
