import * as React from 'react'
import styled from 'styled-components'
import bannerText from '../../../public/assets/landingPage/ownership_simplified_banner_text.svg'
// import bannerText_black from '../../../public/assets/landingPage/ownership_simplified_banner_text_black.svg'
import { device } from '../../templates/displays/devices'

const BannerTextSVGDefault = styled.img`
    width: 80%;
    padding-bottom: 20px;
    padding-left: 20px;
    animation: 1s slideUp both;

    @media ${device.mobile} {
        width: 57%;
        padding-bottom: 20px;
        padding-left: 20px;
    }

    @media (max-width: 320px) {
        padding-bottom: 20px;
        padding-left: 20px;
    }

    @media ${device.ipad} {
        width: 35%;
        padding-bottom: 30px;
        padding-left: 40px;
    }

    @media (min-width: 1180px) and (max-width: 1200px) {
        width: 35%;
    }

    @media ${device.desktop} {
        position: relative;
        /* width: 45%; */
        width: 40%;

        padding-bottom: 50px;
        padding-left: 60px;
    }

    @media ${device.large_desktop} {
        position: relative;
        width: 50%;
        padding-bottom: 40px;
        padding-left: 100px;
    }

    @media (min-width: 1920px) {
        position: relative;

        /* width: 70%; */
        width: 60%;

        padding-bottom: 20px;
        padding-left: 60px;
    }

    @media (min-width: 2200px) {
        padding-bottom: 50px;
        padding-left: 80px;
        /* width: 100%; */
        width: 70%;
    }

    @keyframes slideUp {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }

        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
`
const BannerTextSVGPorscheNew = styled.img`
    position: absolute;
    top: 50px;
    left: 50px;
    width: 80%;
    animation: 1s slideUp both;

    @media ${device.mobile} {
        width: 57%;
        top: 30px;
        left: 30px;
    }

    @media (max-width: 320px) {
    }

    @media ${device.ipad} {
        width: 35%;
    }

    @media ${device.desktop} {
        position: fixed;
        width: 35%;
        top: 50px;
        left: 50px;
        padding-top: 50px;
        padding-left: 60px;
    }

    @media ${device.large_desktop} {
        padding-top: 100px;
        padding-left: 60px;
    }

    @media (min-width: 2200px) {
        padding-top: 50px;
        padding-left: 80px;
        width: 30%;
    }

    @keyframes slideUp {
        0% {
            opacity: 0;
            transform: translateY(50px);
        }

        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    }
`

type Props = {
    customImage?:
        | 'landrover_front'
        | 'landrover_back'
        | 'porsche_new'
        | 'porsche_old'
        | 'bmw'
}

class BannerTextSVG extends React.Component<Props, {}> {
    state = {}

    render() {
        switch (this.props.customImage) {
            case 'porsche_new':
                return <BannerTextSVGPorscheNew src={bannerText} />
            default:
                return <BannerTextSVGDefault src={bannerText} />
        }
    }
}

export default BannerTextSVG
