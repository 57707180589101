import * as React from 'react'
import DesktopDisplayOnly from '../displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../displays/ipadAndMobileDisplay'
import MainMenuDesktop from '../../atoms/menu/mainmenu/mainMenuDesktop'
import MainMenuMobile from '../../atoms/menu/mainmenu/mainMenuMobile'
import { IUser } from 'myModels'
import { History } from 'history'
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import posthog from 'posthog-js'
import { useAppDispatch } from '../../../redux/store/hooks'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import { IGetAllUserInsuranceApplicationsProps } from '../../../redux/insuranceQuoteApplication/sagas/application/getAll'

interface ITextProps extends RouteComponentProps<any> {
    history: History
    user: IUser | null
    // activepage: "/welcome" | "/news" | "/membership" | "/proposals" | "/account";
}

const MainMenu: React.SFC<ITextProps> = (props) => {
    // const trimmedTitle = props.activepage.replace(/[^\w\s]/gi, "");

    const dispatch = useAppDispatch()

    // React.useEffect(() => {
    //     {
    //         posthog.onFeatureFlags(function () {
    //             // feature flags should be available at this point
    //             if (posthog.isFeatureEnabled('enable_showroom') === false) {
    //                 // do something
    //                 dispatch(
    //                     featureFlagsActions.setFeatureFlatToFalse(
    //                         'enable_showroom'
    //                     )
    //                 )
    //             }

    //             // if (posthog.isFeatureEnabled('enable_insurance') === false) {
    //             //     // do something
    //             //     dispatch(
    //             //         featureFlagsActions.setFeatureFlatToFalse(
    //             //             'enable_insurance'
    //             //         )
    //             //     )
    //             // } else {
    //             //     // do something
    //             //     dispatch(
    //             //         featureFlagsActions.setFeatureFlatToTrue(
    //             //             'enable_insurance'
    //             //         )
    //             //     )
    //             // }
    //         })
    //     }
    // }, [props.user])

    const isDefaultNavigationRemoved = useSelector(
        (state: RootState): boolean => state.menus.isDefaultNavigationRemoved
    )

    const insuranceQuoteApplicationState = useSelector(
        (state: RootState) => state.insuranceQuoteApplication
    )

    let location = useLocation() // new

    React.useEffect(() => {
        // new
        posthog.capture('$pageview')
    }, [location])

    React.useEffect(() => {
        if (insuranceQuoteApplicationState.submitted_data?.id !== undefined) {
            if (props.history.location.pathname.match(/insurance/g) === null) {
                dispatch(
                    insuranceActions.reset_insurance_application_to_none_active()
                )
            }
        }
    }, [props.history.location])

    let determineIfMainMenu = () => {
        if (isDefaultNavigationRemoved === true) {
            return false
        }

        let user: IUser | null = props.user

        let isGaragePage: boolean =
            props.history.location.pathname.match(/garage/g) !== null

        let isGaragePageV2: boolean =
            props.history.location.pathname === '/' && user && user.id
                ? true
                : false

        let isTheRightcarPage: boolean =
            props.history.location.pathname.match(/car/g) !== null ||
            props.history.location.pathname.match(/confirm/g) !== null

        let isAccountDeletionConfirmed: boolean =
            props.history.location.pathname === `/account-deletion-confirmed`
                ? true
                : false

        let isOtherMainPage: boolean =
            props.history.location.pathname.match(/upcoming/g) !== null ||
            props.history.location.pathname.match(/sharing/g) !== null ||
            props.history.location.pathname.match(/showroom/g) !== null ||
            props.history.location.pathname === '/apply' ||
            props.history.location.pathname.match(/apex/g) !== null ||
            props.history.location.pathname === '/history-files' ||
            props.history.location.pathname.match(/tasks/g) !== null ||
            props.history.location.pathname.match(/account/g) !== null

        let iaTheRightCarArchivedPage: boolean =
            props.history.location.pathname.match(/car/g) !== null &&
            props.history.location.pathname.match(/archives/g) !== null

        let isInsuranceOverview: boolean =
            props.history.location.pathname === '/insurance/overview'

        if (
            !isAccountDeletionConfirmed &&
            (isGaragePage ||
                isGaragePageV2 ||
                isTheRightcarPage ||
                iaTheRightCarArchivedPage ||
                isOtherMainPage ||
                isInsuranceOverview)
        ) {
            return true
        } else return false
    }

    return (
        <React.Fragment>
            {determineIfMainMenu() === true && (
                <>
                    <DesktopDisplayOnly>
                        <MainMenuDesktop
                            activepage={props.history.location.pathname}
                            get_user_insurance_applications_request={(
                                p: IGetAllUserInsuranceApplicationsProps
                            ) => {
                                dispatch(
                                    insuranceActions.get_user_insurance_applications_request(
                                        p
                                    )
                                )
                            }}
                        />
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <MainMenuMobile
                            activepage={props.history.location.pathname}
                            user={props.user}
                            get_user_insurance_applications_request={(
                                p: IGetAllUserInsuranceApplicationsProps
                            ) => {
                                dispatch(
                                    insuranceActions.get_user_insurance_applications_request(
                                        p
                                    )
                                )
                            }}
                        />
                    </IpadAndMobileDisplay>
                </>
            )}
        </React.Fragment>
    )
}

export default withRouter(MainMenu)
