import styled from 'styled-components'
import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import CarShareSearchBarMobile from '../../searchbar/carShare/carShareSearchBarMobile'
import SearchResultsItemMobile from '../../searchbar/carShare/searchResultsItemMobile'
import Expander from '../../../atoms/expander/expander'
import { IDirectShareOwnedItem, ISearchUsersResult } from 'entityModels'
import SearchLoadingPlaceholdersMobile from '../../searchbar/carShare/searchLoadingPlaceholdersMobile'
import { IShareRecipientRequest_redux } from '../../../../redux/entities/cars/actions/loadingActions'
import { createSearchableIndex } from '../../../../helpers/search/search'

const Padding = styled.div`
    padding-top: 20px;
`
const Wrapper = styled.div`
    position: fixed;
    z-index: 5;
    top: 50px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.07);
    background-color: #ffffff;
`

const SearchResultsDropdownWrapper = styled.div<{ $isVisible: boolean }>`
    padding-top: 180px;
    opacity: ${(props) => (props.$isVisible ? '1' : '0')};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
`

const SearchForWrapper = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    padding-left: 30px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

const renderSearchResults = (payload: {
    share: IDirectShareOwnedItem
    searchResults: ISearchUsersResult[] | undefined
    onSearchResultSelect: (p: IShareRecipientRequest_redux) => void
    selectedUsers: string[]
    setClickedIndex: (index: number) => void
    clickedIndex: number
    redirect?: any
}) =>
    payload.searchResults &&
    payload.searchResults.map((owner: ISearchUsersResult, index) => {
        let isAdded = payload.selectedUsers.includes(owner.uid)
        return (
            <div style={{ paddingBottom: '20px' }} key={`${index}user`}>
                <SearchResultsItemMobile
                    onSearchResultSelect={() => {
                        !isAdded &&
                            payload.onSearchResultSelect({
                                share_id: payload.share.id,
                                recipient_userid: owner.uid,
                                entity_id: payload.share.entity_id,
                            })
                        !isAdded && payload.setClickedIndex(index)
                        payload.redirect()
                    }}
                    owner={owner}
                    isAdded={isAdded}
                    isLoading={
                        payload.clickedIndex >= 0 &&
                        index === payload.clickedIndex
                    }
                />
            </div>
        )
    })

const renderLoadingPlaceholders = () =>
    [1, 2, 3, 4, 5].map((owner, index) => (
        <div style={{ paddingBottom: '20px' }} key={`${index}user`}>
            <SearchLoadingPlaceholdersMobile />
        </div>
    ))

type State = {
    searchResultsInState: ISearchUsersResult[] | undefined
    searchFor: string | undefined
    isSearchOpen: boolean
    isSearchActive: boolean
    typedValue: string
    clickedIndex: number
}

interface Props {
    share: IDirectShareOwnedItem
    searchUsers: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    resultsLoading: boolean
    addShareRecipient: (p: IShareRecipientRequest_redux) => void
    selectedUsers: string[]
    sharesLoading?: boolean
    goBack?: any
}

class PrivateShareSearchFormManagerMobile extends React.PureComponent<
    Props,
    State,
    {}
> {
    state = {
        searchResultsInState: this.props.searchResults,
        searchFor: undefined,
        isSearchOpen: false,
        isSearchActive: false,
        typedValue: '',
        clickedIndex: -1,
    }

    componentDidMount() {}

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.searchResults !== prevProps.searchResults) {
            if (this.props.searchResults && this.state.searchFor) {
                let make_fuse = this.props.searchResults
                    ? createSearchableIndex(this.props.searchResults, [
                          'display_name',
                          'family_name',
                          'given_name',
                      ])
                    : undefined

                let fusedSearchResults = make_fuse
                    ? make_fuse
                          .search(this.state.searchFor, { limit: 5 })
                          .map((item) => item.item)
                    : undefined

                this.setState({
                    ...this.state,
                    searchResultsInState: fusedSearchResults,
                })
            }
        }
    }

    render() {
        let showSearchResultsLoader =
            this.state.typedValue &&
            this.state.searchFor !== this.state.typedValue
                ? true
                : false

        let setClickedIndex = (index: number) =>
            this.setState({ ...this.state, clickedIndex: index })

        if (this.state.clickedIndex >= 0) {
            setTimeout(
                () =>
                    !this.props.sharesLoading &&
                    this.setState({
                        searchResultsInState: undefined,
                        searchFor: undefined,
                        isSearchOpen: false,
                        isSearchActive: false,
                        typedValue: '',
                        clickedIndex: -1,
                    }),
                800
            )
        }

        return (
            <>
                <Wrapper>
                    <CarShareSearchBarMobile
                        onSearch={(value: string) => {
                            this.setState({
                                ...this.state,
                                searchFor: value,
                            })
                            this.props.searchUsers(value)
                        }}
                        searchFor={this.state.searchFor}
                        clearSearch={() => {
                            this.setState({
                                ...this.state,
                                searchFor: undefined,
                                isSearchActive: false,
                                typedValue: '',
                            })
                            this.props.searchUsers(undefined)
                        }}
                        manageissearchactive={(value: boolean) =>
                            this.setState({
                                ...this.state,
                                isSearchActive: value,
                            })
                        }
                        issearchactive={this.state.isSearchActive}
                        onInputChange={(value: string) =>
                            this.setState({
                                ...this.state,
                                typedValue: value,
                            })
                        }
                    />

                    <Padding />
                    {this.state.isSearchActive && (
                        <Expander
                            height={this.state.isSearchActive ? 'auto' : 0}
                            width="100%"
                        >
                            <SearchForWrapper>
                                <span
                                    style={{
                                        color: '#b9b9b9',
                                        fontFamily: 'Lato',
                                        paddingRight: '5px',
                                    }}
                                >
                                    search for:
                                </span>
                                <span
                                    style={{
                                        color: '#000000',
                                        fontFamily: 'Lato',
                                    }}
                                >
                                    {this.state.searchFor}
                                </span>
                            </SearchForWrapper>
                        </Expander>
                    )}
                </Wrapper>
                <SearchResultsDropdownWrapper
                    $isVisible={this.state.typedValue ? true : false}
                >
                    <Expander height={this.state.typedValue ? 'auto' : 0}>
                        {this.state.searchResultsInState
                            ? renderSearchResults({
                                  share: this.props.share,
                                  searchResults:
                                      this.state.searchResultsInState,

                                  onSearchResultSelect:
                                      this.props.addShareRecipient,

                                  selectedUsers: this.props.selectedUsers
                                      ? this.props.selectedUsers
                                      : [],
                                  setClickedIndex: setClickedIndex,
                                  clickedIndex: this.state.clickedIndex,
                                  redirect: this.props.goBack,
                              })
                            : showSearchResultsLoader ||
                              this.props.resultsLoading
                            ? renderLoadingPlaceholders()
                            : this.state.typedValue.trim() === ''
                            ? 'Please type in a valid name'
                            : this.state.typedValue.trim().length < 4
                            ? 'Please type at least 4 characters or more'
                            : 'No user matches your search'}
                        <div style={{ paddingTop: '80px' }} />
                    </Expander>
                </SearchResultsDropdownWrapper>
            </>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(PrivateShareSearchFormManagerMobile)
