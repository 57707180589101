import { useHistory } from 'react-router'
// import JourneyTopBar from '../../templates/bars/journey/topBar'
import { JourneyPageBackground } from '../../templates/windowPageTemplate/journey/pageBackground'

import { IJourneyHandoverStepID } from 'journeyInterfaces'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { useEffect, useState } from 'react'
import useWindowSize, {
    WindowSize,
} from '../../templates/displays/windowSizeHook'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import HandoverJourneyStepsManager from '../../organisms/journey/handover/stepManager'

export default function HandoverJourney() {
    const history = useHistory()
    let params = new URLSearchParams(location.search)
    let step: string | null = params.get('step')

    useEffect(() => {
        window?.scrollTo(0, 0)
    }, [step])

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const [originPath, setOriginPath] = useState<string>('')

    useEffect(() => {
        dispatch(customNavDataActions.setInitialLandingUrl('/journey/handover'))

        let locationState: any = history.location.state

        if (locationState?.prevPath) {
            if (locationState?.prevPath.includes(`step=achieved`)) {
                setOriginPath(`/garage`)
            } else {
                setOriginPath(locationState?.prevPath)
            }
        } else {
            setOriginPath(`/garage`)
        }
    }, [])

    useEffect(() => {
        if (userLoggedIn) {
        } else {
            dispatch(getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    return (
        <JourneyPageBackground
            sectionID="handover"
            hasBlurredBg={step === 'welcome' || isMobile ? true : false}
            hasLinearBgColour={
                true
                // isMobile ? true : step && step === 'welcome' ? false : true
            }
            hasPattern={isMobile ? (step === 'welcome' ? true : false) : true}
        >
            {/* <JourneyTopBar
                sectionID="handover"
                onClose={() => {
                    return history.push(`/garage`)
                }}
            /> */}
            <HandoverJourneyStepsManager
                stepID={step as IJourneyHandoverStepID}
                startJourney={() => {
                    return history.push({
                        pathname: `/journey/handover`,
                        search: `?step=learn`,
                        state: {
                            prevPath: `${history.location.pathname}${
                                history.location.search ?? ''
                            }`,
                        },
                    })
                }}
                exitJourney={() => {
                    return history.push(originPath)
                }}
                user={userLoggedIn}
            />
        </JourneyPageBackground>
    )
}
