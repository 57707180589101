import { call, fork, put, take } from 'redux-saga/effects'

import { dropDownActions } from '../reducer'

import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { IInsuranceDropdowns } from './setInsuranceFieldDataByIDs'
import { get_country_admin_area_dropdown } from '../../../services/dropdowns/dropdowns'

function* setUsaAdminStateCodes() {
    try {
        let dataReceived: IInsuranceDropdowns =
            yield get_country_admin_area_dropdown('US')

        yield put(
            dropDownActions.setUSAStatesDropdownSuccess(
                dataReceived?.administrative_areas ?? []
            )
        )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: setUsaAdminStateCodes,
            }
            yield call(unauthHandlerSaga, p)
        } else if (error === Error) {
            let customErrorData: ICustomErrorData = {
                custom_message: `Looks like your internet has stoped working`,
                custom_message_detail: 'Check your connection and refresh',
                custom_user_action_text: 'Refresh page',
            }

            let customError: IReduxError = ConvertToReduxError(
                { status: '500' },
                customErrorData
            )
            yield put(dropDownActions.setUSAStatesDropdownError(customError))
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)

            yield put(dropDownActions.setUSAStatesDropdownError(typedError))
        }
    }
}

function* watcherSetUsaAdminCodes() {
    while (true) {
        yield take(dropDownActions.setUSAStatesDropdownRequest)
        yield call(setUsaAdminStateCodes)
    }
}

const set_countries_admin_codes: any[] = [fork(watcherSetUsaAdminCodes)]
export default set_countries_admin_codes
