import React from 'react'
import styled from 'styled-components'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'

const Wrapper = styled.div`
    background-color: #ffffff;
    border: 1px solid #f6f6f6;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 7%);
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 80px;
`

const SubWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 20px;
`

const ProfilePicWrapper = styled.div`
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 20px;
    position: relative;
`

const UserName = styled.div`
    position: relative;
    height: 12px;
    width: 40vw;
    max-width: 200px;
`
const UserHandle = styled.div`
    position: relative;
    height: 12px;
    width: 30vw;
    max-width: 100px;
`

interface IItemProps {}

const SearchLoadingPlaceholdersMobile: React.FunctionComponent<IItemProps> = (
    props
) => {
    return (
        <Wrapper>
            <ProfilePicWrapper>
                <SkeletonAbsolute isthingloading={true} isRound />
            </ProfilePicWrapper>
            <SubWrapper>
                <div style={{ paddingTop: '3px' }} />
                <UserName>
                    <SkeletonAbsolute isthingloading={true} />
                </UserName>
                <div style={{ paddingTop: '10px' }} />
                <UserHandle>
                    <SkeletonAbsolute isthingloading={true} />
                </UserHandle>
            </SubWrapper>
        </Wrapper>
    )
}

export default SearchLoadingPlaceholdersMobile
