import * as React from 'react'
import styled from 'styled-components'
import backgroundImage from '../../../public/assets/landingPage/fiat500_resize.jpg'
import landrover_front from '../../../public/assets/landingPage/landrover_old.jpg'
import landrover_front_reversed from '../../../public/assets/landingPage/landrover_old_flipped.jpg'
import landrover_back from '../../../public/assets/landingPage/landrover_new.jpg'
import porsche_new from '../../../public/assets/landingPage/porsche_new.jpg'
import porsche_new_flipped from '../../../public/assets/landingPage/porsche_new_flipped.jpg'
import porsche_old_flipped from '../../../public/assets/landingPage/porsche_old_flipped.jpg'
import porsche_old from '../../../public/assets/landingPage/porsche_old.jpg'
import bmw from '../../../public/assets/landingPage/bmw.jpg'
import { device } from '../../templates/displays/devices'
import { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = { $theme: ITheme }

const FixedGradient = styled.div<IStyle>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) =>
        props.$theme === 'dark'
            ? `linear-gradient(to bottom, rgba(18, 18, 18, 0.349), rgba(18,18,18,0.85))`
            : undefined};
`

const DefaultImage = styled.div`
    position: relative;
    background-image: url(${backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;

    @media ${device.mobile} {
        background-size: 125%;
        height: 38vh;
        width: 100%;
    }

    @media ${device.ipad} {
        height: 40vh;
        width: 100%;
    }

    @media ${device.smallest_laptops} {
        height: 50vh;
        width: 100%;
    }

    @media ${device.desktop} {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`
const LanndRoverFrontImageDiv = styled.div`
    background-image: url(${landrover_front});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -50px -320px;
    background-size: cover;

    display: flex;
    align-items: flex-end;

    @media ${device.mobile} {
        background-image: url(${landrover_front_reversed});
        background-size: 140%;
        height: 38vh;
        width: 100%;
    }

    @media ${device.ipad} {
        background-image: url(${landrover_front_reversed});
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center -600px;
    }

    @media (height: 720px) {
        background-image: url(${landrover_front_reversed});
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center -450px;
    }
    @media (max-width: 280px) {
        background-image: url(${landrover_front_reversed});
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center -150px;
    }

    @media ${device.smallest_laptops} {
        background-image: url(${landrover_front_reversed});
        height: 50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1179px) {
        background-image: url(${landrover_front_reversed});
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center -950px;
    }

    @media (min-width: 1180px) and (max-width: 1200px) {
        background-image: url(${landrover_front_reversed});
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center -1100px;
    }

    @media ${device.desktop} {
        background-image: url(${landrover_front});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 140%;
        background-position: 0px -400px;
    }

    @media (min-width: 1366px) {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 140%;
        background-position: 0px -700px;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`

const LanndRoverBackImageDiv = styled.div`
    background-image: url(${landrover_back});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px -220px;
    background-size: cover;

    display: flex;
    align-items: flex-end;

    @media ${device.mobile} {
        background-size: 100%;
        height: 38vh;
        width: 100%;
    }

    @media ${device.ipad} {
        background-size: 100%;
        height: 40vh;
        width: 100%;
        background-position: center -420px;
    }

    @media (height: 720px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center -450px;
    }
    @media (max-width: 280px) {
        background-size: 100%;
        height: 40vh;
        width: 100%;
        background-position: center -130px;
    }

    @media ${device.smallest_laptops} {
        height: 50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1179px) {
        background-size: 100%;
        height: 40vh;
        width: 100%;
        background-position: center -550px;
    }

    @media (min-width: 1180px) and (max-width: 1200px) {
        background-size: 100%;
        height: 40vh;
        width: 100%;
        background-position: center -800px;
    }

    @media ${device.desktop} {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 140%;
        background-position: 0px -500px;
    }

    @media (min-width: 1366px) {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 100%;
        background-position: center;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`

const PorscheNew = styled.div`
    background-image: url(${porsche_new});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0px -330px;
    background-size: cover;
    display: flex;
    align-items: flex-end;

    @media ${device.mobile} {
        background-size: 120%;
        height: 38vh;
        width: 100%;
    }

    @media ${device.ipad} {
        background-size: 110%;
        height: 40vh;
        width: 100%;
        background-position: center -680px;
    }

    @media (height: 720px) {
        background-size: 110%;
        height: 40vh;
        width: 100%;
        background-position: center -450px;
    }
    @media (max-width: 280px) {
        background-size: 110%;
        height: 40vh;
        width: 100%;
        background-position: center -150px;
    }

    @media ${device.smallest_laptops} {
        height: 50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1179px) {
        background-size: 110%;
        height: 40vh;
        width: 100%;
        background-position: 0px -900px;
    }

    @media (min-width: 1180px) and (max-width: 1200px) {
        background-size: 100%;
        height: 40vh;
        width: 100%;
        background-position: center -1150px;
    }

    @media ${device.desktop} {
        background-image: url(${porsche_new_flipped});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 150%;
        background-position: center -700px;
    }

    @media (min-width: 1366px) {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 140%;
        background-position: -200px -950px;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`
const PorscheOld = styled.div`
    background-image: url(${porsche_old});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-size: cover;

    display: flex;
    align-items: flex-end;

    @media ${device.mobile} {
        background-size: 140%;
        height: 38vh;
        width: 100%;
    }

    @media ${device.ipad} {
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center;
    }

    @media (height: 720px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center;
    }
    @media (max-width: 280px) {
        background-size: 150%;
        height: 40vh;
        width: 100%;
        background-position: center;
    }

    @media ${device.smallest_laptops} {
        height: 50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1179px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: center;
    }

    @media (min-width: 1180px) and (max-width: 1200px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: -50px -250px;
    }

    @media ${device.desktop} {
        background-image: url(${porsche_old_flipped});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 180%;
        background-position: -200px 0px;
    }

    @media (min-width: 1366px) {
        background-image: url(${porsche_old_flipped});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 165%;
        background-position: -100px 0px;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`
const Bmw = styled.div`
    background-image: url(${bmw});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    background-size: cover;

    display: flex;
    align-items: flex-end;

    @media ${device.mobile} {
        background-size: 140%;
        height: 38vh;
        width: 100%;
    }

    @media ${device.ipad} {
        background-size: 130%;
        height: 40vh;
        width: 100%;
    }

    @media (height: 720px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
    }
    @media (max-width: 280px) {
        background-size: 150%;
        height: 40vh;
        width: 100%;
    }

    @media ${device.smallest_laptops} {
        height: 50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1179px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
    }

    @media (min-width: 1180px) and (max-width: 1200px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: 0px -350px;
    }

    @media ${device.desktop} {
        background-image: url(${bmw});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 185%;
        background-position: -200px 0px;
    }

    @media (min-width: 1366px) {
        background-image: url(${bmw});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 190%;
        background-position: -150px -100px;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`

const ShareInvite = styled.div`
    background-image: url(${bmw});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    background-size: cover;

    display: flex;
    align-items: flex-end;

    @media ${device.mobile} {
        background-size: 140%;
        height: 38vh;
        width: 100%;
    }

    @media ${device.ipad} {
        background-size: 130%;
        height: 40vh;
        width: 100%;
    }

    @media (height: 720px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
    }
    @media (max-width: 280px) {
        background-size: 150%;
        height: 40vh;
        width: 100%;
    }

    @media ${device.smallest_laptops} {
        height: 50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1179px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
    }

    @media (min-width: 1180px) and (max-width: 1200px) {
        background-size: 130%;
        height: 40vh;
        width: 100%;
        background-position: 0px -350px;
    }

    @media ${device.desktop} {
        background-image: url(${bmw});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 185%;
        background-position: -200px 0px;
    }

    @media (min-width: 1366px) {
        background-image: url(${bmw});
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 70%;
        background-size: 190%;
        background-position: -150px -100px;
    }

    @media ${device.large_desktop} {
        display: none;
    }
`

type Props = {
    customImage?:
        | 'landrover_front'
        | 'landrover_back'
        | 'porsche_new'
        | 'porsche_old'
        | 'bmw'
        | 'share-invite'
    children: React.ReactNode
}

export default function RegistrationNestedImage(props: Props) {
    const { theme } = useThemes()
    switch (props.customImage) {
        case 'landrover_front':
            return (
                <LanndRoverFrontImageDiv>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </LanndRoverFrontImageDiv>
            )
        case 'landrover_back':
            return (
                <LanndRoverBackImageDiv>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </LanndRoverBackImageDiv>
            )
        case 'porsche_new':
            return (
                <PorscheNew>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </PorscheNew>
            )
        case 'porsche_old':
            return (
                <PorscheOld>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </PorscheOld>
            )
        case 'bmw':
            return (
                <Bmw>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </Bmw>
            )
        case 'share-invite':
            return (
                <ShareInvite>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </ShareInvite>
            )

        default:
            return (
                <DefaultImage>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </DefaultImage>
            )
    }
}
