import * as React from 'react'
import styled from 'styled-components'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import Icon from '../icons'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'

const ListItem = styled.li`
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 24px;
    list-style-type: none;
    background-color: var(--primary_16, rgba(129, 212, 223, 0.16));
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
`

const ListContentRow = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
`

const Handler = styled.div`
    width: 26px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
`

const AbsoluteNumber = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -8px;
    margin-top: -8px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bg-color, #fff);
    background-color: var(--journey-car_profile-primary_500, #81d4df);
    border-radius: 4px;
    font-family: Lato-semibold;
    font-size: 16px;
    z-index: 6;
`

const TextWrapper = styled.div`
    position: relative;
    color: transparent;
    padding-top: 4px;
    padding-left: 16px;
`

const TextArea = styled.textarea`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-family: Lato;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    resize: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 5e-5em;
    color: var(--text-strong, #1a1a1a);
    background-color: transparent;
    vertical-align: center;

    @media ${device.ipad} {
        font-size: 14px;
        line-height: 20px;
    }

    :focus {
        outline: 0;
    }
    ::placeholder {
        color: var(--text-muted, #b3b3b3);
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--bg-color, #fff);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--border-muted, #e5e5e5);
    }
    /* width */
    ::-webkit-scrollbar {
        width: 4px;
        border-radius: 20px;
    }
`

const DragHandle = SortableHandle(() => (
    <motion.div whileTap={{ scale: 1.2 }}>
        <Handler>
            <Icon icon="handler" />
        </Handler>
    </motion.div>
))

const SortableItem = SortableElement(
    ({
        item,
        formikprops,
        index,
    }: {
        item: EnumerableObject

        formikprops: {
            handleChange: (e: React.ChangeEvent<any>) => void

            values: {
                [key: string]: string
            }

            setFieldValue: (
                field: string,
                value: any,
                shouldValidate?: boolean | undefined
            ) => void
        }

        index: number
    }) => {
        const newOrderArray = formikprops.values['new_ordered_array']
        const order =
            newOrderArray && newOrderArray[0] //@ts-ignore
                ? newOrderArray?.findIndex((k: any) => item.id === k.id)
                : item.order
        return (
            <ListItem key={index} style={{ zIndex: 5 }}>
                <AbsoluteNumber>0{order + 1}</AbsoluteNumber>
                <ListContentRow>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <TextWrapper>
                            {formikprops.values[item.id]
                                ? formikprops.values[item.id]
                                : item.order === 1
                                ? 'i.e. This is one of only 400 Heritage Editions produced in both long and short wheelbase form to mark the ending of original Defender production.'
                                : item.order === 2
                                ? 'i.e. Finished in the beautifull N2 Orange colour'
                                : item.order === 3
                                ? 'i.e. Features many nods to models past including the Almond cloth upholstery, painted steel wheels and HUE 166 badging, this being the registration number of the first Land Rover prototype.'
                                : ''}

                            <TextArea
                                id={item.id}
                                name={item.id}
                                placeholder={
                                    item.order === 1
                                        ? 'i.e. This is one of only 400 Heritage Editions produced in both long and short wheelbase form to mark the ending of original Defender production.'
                                        : item.order === 2
                                        ? 'i.e. Finished in the beautifull N2 Orange colour'
                                        : item.order === 3
                                        ? 'i.e. Features many nods to models past including the Almond cloth upholstery, painted steel wheels and HUE 166 badging, this being the registration number of the first Land Rover prototype.'
                                        : ''
                                }
                                onChange={formikprops.handleChange}
                                value={
                                    formikprops.values[item.id] !== undefined
                                        ? formikprops.values[item.id]
                                        : ''
                                }
                                maxLength={200}
                            />
                        </TextWrapper>
                    </div>
                    <DragHandle />
                </ListContentRow>
            </ListItem>
        )
    }
)

type EnumerableObject = {
    id: string
    text: string
    order: number
    formikprops: any
}

type Props = {
    item: EnumerableObject
    index: number
    formikprops: any
    isJourney?: boolean
}

type State = {
    item: EnumerableObject
}

class DraggableItemEditableJourneyMobile extends React.Component<Props, State> {
    state = {
        item: this.props.item,
    }

    componentDidMount() {
        this.setState({ item: this.props.item })
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props !== prevProps) {
            this.setState({ item: this.props.item })
        }
    }

    render() {
        const { index, formikprops } = this.props
        const { item } = this.state

        return (
            <SortableItem
                key={index}
                index={index}
                item={item}
                formikprops={formikprops}
            />
        )
    }
}

export default DraggableItemEditableJourneyMobile
