// create store for todos (basically reducer for the main final store)

import { ITasksState } from 'entityModels'

import * as successActions from './actions/successActions'
import * as requestActions from './actions/requestActions'
import * as errorActions from './actions/errorActions'

import { ActionType } from 'typesafe-actions'

type SuccessActions = ActionType<typeof successActions>
type RequestActions = ActionType<typeof requestActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: ITasksState = {
    tasks: {},
    tasksDisplayCriteria: {
        selectedCarID: null,
        sortBy: 'Date Added',
        filteredBy: 'Outstanding',
    },
    error: null,
    loading: false,
    isRemindersLoading: false,
}

export const taskReducer = (
    state: ITasksState = initialState,
    action: RequestActions | SuccessActions | ErrorActions
): ITasksState => {
    switch (action.type) {
        // REQUESTS

        // GET TASKS BY GARAGE ID

        case requestActions.getTasksByGarageIdRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getTasksByGarageIdSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: { ...state.tasks, ...action.payload },
                loading: false,
                error: null,
            })

        case errorActions.getTasksByGarageIdError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // GET TASKS BY CAR ID

        case requestActions.getTasksByCarIdRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getTasksByCarIdSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: { ...state.tasks, ...action.payload },
                loading: false,
                error: null,
            })

        case errorActions.getTasksByCarIdError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // GET TASKS BY TASK ID

        case requestActions.getTaskByIdRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.getTaskByIdSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: { ...state.tasks, ...action.payload },
                loading: false,
                error: null,
            })

        case errorActions.getTaskByIdError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // UPDATE TASK

        case requestActions.updateTaskRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.updateTaskSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.id]: {
                        ...state.tasks[action.payload.id],
                        ...action.payload,
                    },
                },
                loading: false,
                error: null,
            })

        case successActions.setTaskRemindersEditedSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.taskID]: {
                        ...state.tasks[action.payload.taskID],
                        reminders_being_added: action.payload.reminders,
                    },
                },
                isRemindersLoading: false,
                loading: false,
                error: null,
            })

        case errorActions.updateTaskError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // CREATE TASK

        case requestActions.createTaskRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.createTaskSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: { ...state.tasks, ...action.payload },
                loading: false,
                error: null,
            })

        case errorActions.createTaskError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // LINK TASK

        case requestActions.linkTaskRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.linkTaskSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.id]: action.payload,
                },
                loading: false,
                error: null,
            })

        case errorActions.linkTaskError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // UNLINK TASK

        case requestActions.unlinkTaskRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.unlinkTaskSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.id]: action.payload,
                },
                loading: false,
                error: null,
            })

        case errorActions.unlinkTaskError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // DELETE TASK

        case requestActions.deleteTaskRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case successActions.deleteTaskSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload]: undefined,
                },
                loading: false,
                error: null,
            })

        case errorActions.deleteTaskError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        // REMOVE TASKS

        case successActions.removeStoreTasksSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {},
                loading: false,
                error: null,
            })

        case successActions.registerTasksDisplayCriteriaSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasksDisplayCriteria: action.payload,
            })

        // GET TASK REMINDERS

        case requestActions.getTaskRemindersRequest.type:
            return Object.assign({}, state, {
                ...state,
                isRemindersLoading: true,
                error: null,
            })

        case errorActions.getTaskRemindersError.type:
            return Object.assign({}, state, {
                ...state,
                error: action.payload,
                isRemindersLoading: false,
            })

        case successActions.getTaskRemindersSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.taskUID]: {
                        ...state.tasks[action.payload.taskUID],
                        reminders: action.payload.reminders,
                    },
                },

                error: null,
                isRemindersLoading: false,
            })

        // ADD REMINDER

        case requestActions.addRemindersToTaskRequest.type:
            return Object.assign({}, state, {
                ...state,
                error: null,
                isRemindersLoading: true,
            })

        case successActions.addRemindersToTaskSuccess.type:
            let arr =
                state.tasks &&
                state.tasks[action.payload.taskID] &&
                state.tasks[action.payload.taskID].reminders !== undefined
                    ? state.tasks[action.payload.taskID].reminders
                    : []
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.taskID]: {
                        ...state.tasks[action.payload.taskID],
                        reminders: [...arr!, ...action.payload.reminders],
                        has_reminders: true,
                    },
                },
                loading: false,
                error: null,
                isRemindersLoading: false,
            })

        case errorActions.addRemindersToTaskError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
                isRemindersLoading: false,
            })

        // DELETE REMINDER

        case requestActions.deleteReminderTaskRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: null,
                isRemindersLoading: true,
            })

        case successActions.deleteReminderTaskSuccess.type:
            return Object.assign({}, state, {
                ...state,
                tasks: {
                    ...state.tasks,
                    [action.payload.taskUID]: {
                        ...state.tasks[action.payload.taskUID],
                        reminders: [...action.payload.reminders],
                        has_reminders: action.payload.has_reminders,
                    },
                },
                loading: false,
                error: null,
                isRemindersLoading: false,
            })

        case errorActions.deleteReminderTaskError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
                isRemindersLoading: false,
            })

        case successActions.cleanUpTasksSuccess.type:
            return Object.assign({}, state, {
                tasks: {},
                tasksDisplayCriteria: {
                    selectedCarID: null,
                    sortBy: 'Date Added',
                    filteredBy: 'Outstanding',
                },
                error: null,
                loading: false,
                isRemindersLoading: false,
            })

        default:
            return state
    }
}

export default taskReducer

export type TasksState = ReturnType<typeof taskReducer>
