import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import Loader from '../../../../../atoms/loader/loader'
import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
import SelectSearchBarCarInfoDesktop from '../../searchselect/desktop/selectSearchBarCarInfoDesktop'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import InputField from '../../../../../atoms/Inputfield/inputField'
import { IYearsValidationCopy } from 'formValidationCopyModels'
import Expander from '../../../../../atoms/expander/expander'
import { IwriteMultipleTechnicalInformationPayloadReq } from 'entityModels'
import { ISetModelDropdownDataReq } from '../../../../../../redux/localdata/dropdownData/sagas/setModelDropdownData'
import { ISetCustomItemInSpecifiedTargettedDropdownReq } from '../../../../../../redux/localdata/dropdownData/sagas/setCustomItemInSpecifiedTargettedDropdown'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
    Notice,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const SmallPadding = styled.div`
    padding-top: 32px;

    @media (max-height: 800px) {
        padding-top: 26px;
    }
`

const Padding16 = styled.div`
    padding-top: 16px;
`

const WarningText = styled.div`
    color: #f08080;
    font-size: 14px;
    padding-top: 5px;
    width: 100%;
    align-items: start;
    justify-content: flex-start;
    font-style: italic;
    font-family: Lato;
`

const dispatchProps = {
    setModelDropdownData: (payload: ISetModelDropdownDataReq) =>
        dropDownActions.setModelDropdownDataRequest(payload),

    setTempMakeIDSuccess: (payload: string | undefined) =>
        dropDownActions.setTempMakeIDSuccess(payload),

    setTempMakeDisplay: (payload: string | undefined) =>
        dropDownActions.setTempMakeDisplay(payload),

    setTempModelIDSuccess: (payload: string | undefined) =>
        dropDownActions.setTempModelIDSuccess(payload),

    setTempModelDisplay: (payload: string | undefined) =>
        dropDownActions.setTempModelDisplay(payload),

    setCustomItemInSpecifiedTargettedDropdown: (
        payload: ISetCustomItemInSpecifiedTargettedDropdownReq
    ) =>
        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
            payload
        ),
    setIsLoadingNewMakeOrModel: (p: boolean) =>
        dropDownActions.setIsLoadingNewMakeOrModel(p),
}

function mapStateToProps(state: RootState) {
    return {
        makeDropDownList: state.localdata.dropdownData.carMakes,
        modelDropdownList: state.localdata.dropdownData.carModels,
        isLoading: state.localdata.dropdownData.loading,
        car_years_validation:
            state.localdata.form_validation_copy.years.car_year,
        isLoadingNewMakeOrModel:
            state.localdata.dropdownData.isLoadingNewMakeOrModel,
    }
}

interface Values {
    car_make_custom_make_desktop: any
    car_model_custom_make_desktop: any
    car_year_custom_make_desktop: any
}

interface OtherProps {
    toggle: any
    make_item: any
    model_item: any
    year_item: any
    submitFunc: (p: IwriteMultipleTechnicalInformationPayloadReq) => {}
    tech_info_id?: any
    makeDropDownList: any
    modelDropdownList: any
    setModelDropdownData: any
    setCustomItemInSpecifiedTargettedDropdown: any
    isLoading: boolean
    carid: string
    car_years_validation?: IYearsValidationCopy

    setTempModelDisplay: (id: string | undefined) => void
    setTempMakeDisplay: (id: string | undefined) => void
    setTempMakeIDSuccess: (id: string | undefined) => void
    setTempModelIDSuccess: (id: string | undefined) => void

    setIsLoadingNewMakeOrModel: (p: boolean) => void
    isLoadingNewMakeOrModel: boolean
}

const defaultOption = [
    {
        uid: 'nodata',
        name: '-',
    },
]

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        toggle,
        make_item,
        model_item,
        year_item,
        makeDropDownList,
        setFieldValue,
        handleSubmit,
        modelDropdownList,
        setModelDropdownData,
        setCustomItemInSpecifiedTargettedDropdown,
        isLoading,
        values,
        errors,
        setIsLoadingNewMakeOrModel,
        isLoadingNewMakeOrModel,

        // setTempMakeDisplay,
        // setTempMakeIDSuccess,

        // setTempModelDisplay,
        // setTempModelIDSuccess,
    } = props

    let checkIfDisabled = () => {
        if (
            !values.car_make_custom_make_desktop ||
            !values.car_model_custom_make_desktop ||
            values.car_model_custom_make_desktop === '-'
        ) {
            return true
        } else if (
            errors.car_make_custom_make_desktop ||
            errors.car_model_custom_make_desktop ||
            errors.car_year_custom_make_desktop
        ) {
            return true
        } else if (isLoadingNewMakeOrModel) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop id="scrollable-content" $theme={theme}>
            <ModalInnerContentWrapperDesktop>
                <Loader isLoading={isLoading} />

                {make_item !== undefined &&
                    model_item !== undefined &&
                    year_item !== undefined && (
                        <>
                            <ModalTitle $theme={theme}>
                                Make | Model | Year
                            </ModalTitle>
                            <Padding16 />
                            <Notice>
                                If you can't find your car's model or make
                                simply type the name fully, then select "
                                <span style={{ fontWeight: 'bold' }}>add</span>
                                ".
                            </Notice>

                            <SmallPadding />

                            <SelectSearchBarCarInfoDesktop
                                placeholder="Make"
                                field_id="make"
                                handleSelectChange={(
                                    name: string,
                                    other?: string
                                ) => {
                                    // console.log('OTHER', other)
                                    setFieldValue(
                                        'car_make_custom_make_desktop',
                                        name
                                    )
                                    setModelDropdownData({
                                        makeName: name,
                                        make_uid: other,
                                    })
                                    setFieldValue(
                                        'car_model_custom_make_desktop',
                                        null
                                    )

                                    // setTempMakeDisplay(name)
                                    // setTempMakeIDSuccess(other)
                                }}
                                addAnItemFunc={(id: string, name: string) => {
                                    setIsLoadingNewMakeOrModel(true)
                                    setCustomItemInSpecifiedTargettedDropdown({
                                        dropdownTargetId: id,
                                        name: name,
                                    })
                                }}
                                item={make_item}
                                items={
                                    makeDropDownList
                                        ? makeDropDownList
                                        : defaultOption
                                }
                                formCurrentValue={
                                    values['car_make_custom_make_desktop']
                                }
                                dropdownTargetId="carMakes"
                                allowAdd={true}
                                onlyShowMatch={true}
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={
                                    values['car_make_custom_make_desktop'] ===
                                    null
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <WarningText>
                                    Please enter the car's make.
                                </WarningText>
                            </Expander>

                            <SmallPadding />

                            <SelectSearchBarCarInfoDesktop
                                placeholder="Model"
                                field_id="model"
                                handleSelectChange={(
                                    name: string,
                                    other?: string
                                ) => {
                                    setFieldValue(
                                        'car_model_custom_make_desktop',
                                        name
                                    )

                                    // setTempModelDisplay(name)
                                    // setTempModelIDSuccess(other)

                                    // TODO DISPATCH HERE APP
                                    // setYearDropdownData(name);
                                }}
                                addAnItemFunc={(id: string, name: string) => {
                                    setIsLoadingNewMakeOrModel(true)
                                    setCustomItemInSpecifiedTargettedDropdown({
                                        dropdownTargetId: id,
                                        name: name,
                                        relevantOtherLinkedDataMake:
                                            values.car_make_custom_make_desktop,
                                    })
                                }}
                                item={{
                                    id: model_item.id,
                                    text: model_item.text,
                                    answer: values[
                                        'car_model_custom_make_desktop'
                                    ],
                                }}
                                dropdownTargetId="carModels"
                                items={
                                    modelDropdownList
                                        ? modelDropdownList
                                        : defaultOption
                                }
                                formCurrentValue={
                                    values['car_model_custom_make_desktop']
                                }
                                allowAdd={true}
                                isDisabled={
                                    !values['car_make_custom_make_desktop'] &&
                                    make_item.answer === null
                                        ? true
                                        : false
                                }
                                valueUpdatedFromExternalSource={
                                    modelDropdownList ? modelDropdownList : null
                                }
                                clearFieldIfValueUpdatedFromExternalSource
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />

                            <Expander
                                height={
                                    values['car_make_custom_make_desktop'] !==
                                        null &&
                                    (values['car_model_custom_make_desktop'] ===
                                        null ||
                                        values[
                                            'car_model_custom_make_desktop'
                                        ] === '-')
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <WarningText>
                                    Please enter the car's model.
                                </WarningText>
                            </Expander>

                            <SmallPadding />

                            <InputField
                                theme={theme}
                                id="car_year_custom_make_desktop"
                                name="car_year_custom_make_desktop"
                                placeholder={'year'}
                                tabindex={1}
                                aria-live="polite"
                                formikprops={props}
                                value={values.car_year_custom_make_desktop}
                                font_family="Lato"
                                width="150px"
                                type="number"
                                disabled={
                                    values['car_model_custom_make_desktop'] ===
                                        null && model_item.answer === null
                                        ? true
                                        : false
                                }
                                onEnterKeyPress={props.handleSubmit}
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                            <Expander
                                height={
                                    errors.car_year_custom_make_desktop
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <WarningText>
                                    {errors.car_year_custom_make_desktop}
                                </WarningText>
                            </Expander>

                            {/* <SelectSearchBarYearsDesktop
              handleSelectChange={(name: string) =>
                setFieldValue("car_year_custom_make_desktop", name)
              }
              item={year_item}
              items={yearsDropdownList ? yearsDropdownList : defaultOption}
              dropdownTargetId="carYears"

              formCurrentValue={props.values["car_year_custom_make_desktop"]}
              allowAdd={false}
              isDisabled={
                !props.values["car_model_custom_make_desktop"] &&
                model_item.answer === null
                  ? true
                  : false
              }
            /> */}
                        </>
                    )}
                <SmallPadding />
                <SmallPadding />

                <ButtonsStandardBottomRowDesktop
                    isDisabled={checkIfDisabled()}
                    cancel={toggle}
                    submit={handleSubmit}
                    isSecondTextLoading={isLoadingNewMakeOrModel}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const CarMakeCustomFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        car_make_custom_make_desktop: props.make_item
            ? props.make_item.answer
            : '-',
        car_model_custom_make_desktop: props.model_item
            ? props.model_item.answer
            : '-',
        car_year_custom_make_desktop: props.year_item
            ? props.year_item.answer
            : '-',
    }),
    // validationSchema: {},
    enableReinitialize: true,
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                car_year_custom_make_desktop:
                    values.car_year_custom_make_desktop &&
                    values.car_year_custom_make_desktop !== '-' &&
                    props.car_years_validation &&
                    Yup.number()
                        .min(
                            props.car_years_validation.min,
                            props.car_years_validation.warning_min
                        )
                        .max(
                            props.car_years_validation.max,
                            props.car_years_validation.warning_max
                        ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteMultipleTechnicalInformationPayloadReq = {
            car_id: props.carid,
            tech_info_id: props.tech_info_id,
            data: [
                {
                    id: 'make',
                    text: 'make',
                    answer: values.car_make_custom_make_desktop,
                    // unique_answer_id:
                    //     props.make_item && props.make_item.unique_answer_id
                    //         ? props.make_item.unique_answer_id
                    //         : undefined,
                },

                {
                    id: 'model',
                    text: 'model',
                    answer: values.car_model_custom_make_desktop,
                },
                {
                    id: 'year',
                    text: 'year',
                    answer: values.car_year_custom_make_desktop,
                },
            ],
        }

        props.submitFunc(p)
        props.toggle()
    },
})(InnerForm)

export default connect(mapStateToProps, dispatchProps)(CarMakeCustomFormDesktop)
