import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const TransmissionIcon = ({ size, strokeColor, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30ZM16 25.4922C21.2424 25.4922 25.4922 21.2424 25.4922 16C25.4922 10.7576 21.2424 6.50781 16 6.50781C10.7576 6.50781 6.50781 10.7576 6.50781 16C6.50781 21.2424 10.7576 25.4922 16 25.4922Z"
                fill={color ?? colours[theme].icons_neutral_250}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.5 10.8597C11.9142 10.8597 12.25 11.1955 12.25 11.6097V15.3597H15.25V11.6097C15.25 11.1955 15.5858 10.8597 16 10.8597C16.4142 10.8597 16.75 11.1955 16.75 11.6097V15.3597H19.75V11.6097C19.75 11.1955 20.0858 10.8597 20.5 10.8597C20.9142 10.8597 21.25 11.1955 21.25 11.6097V16.1097C21.25 16.524 20.9142 16.8597 20.5 16.8597H16.75V20.6097C16.75 21.024 16.4142 21.3597 16 21.3597C15.5858 21.3597 15.25 21.024 15.25 20.6097V16.8597H12.25V20.6097C12.25 21.024 11.9142 21.3597 11.5 21.3597C11.0858 21.3597 10.75 21.024 10.75 20.6097V16.1097V11.6097C10.75 11.1955 11.0858 10.8597 11.5 10.8597Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16 28.5C22.9036 28.5 28.5 22.9036 28.5 16C28.5 9.09644 22.9036 3.5 16 3.5C9.09644 3.5 3.5 9.09644 3.5 16C3.5 22.9036 9.09644 28.5 16 28.5ZM16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
        </svg>
    )
}
export default TransmissionIcon
