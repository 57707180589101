import * as React from "react";
import styled from "styled-components";
import { device } from "../../../templates/displays/devices";

interface Props {
  children?: React.ReactNode;
  withPadding?: boolean;
}

const PublicPageWrapperStyle = styled.div<Props>`
  @media ${device.mobile} {
    margin-top: ${(props) => (props.withPadding === true ? "5vh" : "-25px")} 
    padding-bottom: 150px;
  }
  @media ${device.desktop} {
    padding-top: 10px;
    margin-bottom: 200px;
  }
  @media ${device.ipad} {
    margin-top: 10px;
    margin-bottom: 200px;
  }
`;

const PublicPageWrapper: React.SFC<Props> = (props) => (
  <PublicPageWrapperStyle withPadding={props.withPadding && props.withPadding}>
    {props.children}
  </PublicPageWrapperStyle>
);

export default PublicPageWrapper;
