import * as React from 'react'
import { connect } from 'react-redux'
import BasicTechInfoEditFormMobile from '../basic/basicTechInfoEditFormMobile'
import CarTechInfoEditFormSelectSearchMobile from '../searchselect/mobile/carTechInfoEditFormSelectSearchMobile'
import colors from '../../../../../redux/localdata/formslist/car/colors.json'
import vehicle_condition from '../../../../../redux/localdata/formslist/car/vehicle_condition.json'
import powertype from '../../../../../redux/localdata/formslist/car/powertype.json'
import DatePickerTechInfoEditFormMobile from '../../technicalInformationEditForm/datepicker/mobile/datePickerTechInfoEditFormMobile'
import NumberTechInfoEditFormMobile from '../../technicalInformationEditForm/number/mobile/numberTechInfoEditFormMobile'
import DuoRadioTechInfoEditFormMobile from '../../technicalInformationEditForm/duoradio/mobile/duoRadioTechInfoEditFormMobile'
import drive_train_options from '../../../../../redux/localdata/formslist/car/drive_train_options.json'
import features from '../../../../../redux/localdata/formslist/car/features.json'
import CarTechInfoEditFormMultipleSelectSearchMobile from '../multipleselect/mobile/carTechInfoEditFormMultipleSelectSearchMobile'
import {
    IDropdownItem,
    IList,
    IwriteMultipleTechnicalInformationPayloadReq,
    IwriteTechnicalInformationPayloadReq,
} from 'entityModels'
import { RootState } from 'typesafe-actions'
import CarMakeCustomFormMobile from '../custom/carMakeCustomForm/carMakeCustomFormMobile'
import CarModelCustomFormMobile from '../custom/carModelCustomForm/carModelMobileFormCustom'
import CarTransmissionTypeCustomMobile from '../custom/carTransmissionTypeCustom/carTransmissionTypeCustomMobile'
import CarYearCustomFormMobile from '../custom/carYearCustomForm/carYearMobileFormCustom'
import CarBodyTypeCustomMobile from '../custom/carBodyTypeCustom/carBodyTypeCustomMobile'
import { IUserPreferencesDataLevel4Obj } from 'IUserPreferencesModels'

import {
    writeMultipleTechnicalInformationRequest,
    writeTechnicalInformationRequest,
} from '../../../../../redux/entities/technical_information/actions/loadingActions'
import { ISetModelDropdownDataReq } from '../../../../../redux/localdata/dropdownData/sagas/setModelDropdownData'
import { ISetYearDropdownData } from '../../../../../redux/localdata/dropdownData/sagas/setYearDropdownData'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'

export interface Props {
    item: IList | undefined
    toggle: () => void
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => {}
    id: string
    allTechInfodata: any
    makesdropdowndata: IDropdownItem[] | null
    setMakeDropdownData: () => void
    setModelDropdownData: (payload: ISetModelDropdownDataReq) => void
    setYearDropdownData: (payload: ISetYearDropdownData | undefined) => void
    yearsdropdowndata: IDropdownItem[] | null
    carid: string
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => {}
    body_types_list: IDropdownItem[] | null
    setBodyTypeDropdownData: () => void
    setTransmissionTypesDropdownData: () => void
    transmission_types_list: IDropdownItem[] | null
    user_fuel_unit_ids_list: string[] | null
    user_fuel_unit_chosen_id: string | null
    user_fuel_unit_data: IUserPreferencesDataLevel4Obj | null
    user_distance_unit_ids_list: string[] | null
    user_distance_unit_chosen_id: string | null
    user_distance_unit_data: IUserPreferencesDataLevel4Obj | null

    tempMakeID: string | undefined
    tempModelID: string | undefined
    tempMakeDisplay: string | undefined
    tempModelDisplay: string | undefined
}

function mapStateToProps(state: RootState) {
    return {
        tempMakeID: state.localdata.dropdownData.tempMakeID,
        tempModelID: state.localdata.dropdownData.tempModelID,
        tempMakeDisplay: state.localdata.dropdownData.tempMakeDisplay,
        tempModelDisplay: state.localdata.dropdownData.tempModelDisplay,
        makesdropdowndata: state.localdata.dropdownData.carMakes,
        yearsdropdowndata: state.localdata.dropdownData.carYears,
        body_types_list: state.localdata.dropdownData.carBodyTypes,
        transmission_types_list:
            state.localdata.dropdownData.carTransmissionTypes,
        // fuel unit
        user_fuel_unit_ids_list:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['fuel_unit']
                .ids_list,
        user_fuel_unit_chosen_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['fuel_unit']
                .user_choice_id,
        user_fuel_unit_data:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['fuel_unit']
                .data,
        // distance  unit
        user_distance_unit_ids_list:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .ids_list,
        user_distance_unit_chosen_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .user_choice_id,
        user_distance_unit_data:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.preferences &&
            state.user.userLoggedIn.preferences.data &&
            state.user.userLoggedIn.preferences.data.units &&
            state.user.userLoggedIn.preferences.data.units.data &&
            state.user.userLoggedIn.preferences.data.units.data['distance_unit']
                .data,
    }
}

const dispatchProps = {
    writeTechnicalInformationRequest: (
        p: IwriteTechnicalInformationPayloadReq
    ) => writeTechnicalInformationRequest(p),
    writeMultipleTechnicalInformationRequest: (
        p: IwriteMultipleTechnicalInformationPayloadReq
    ) => writeMultipleTechnicalInformationRequest(p),
    setMakeDropdownData: () => dropDownActions.setMakeDropdownDataRequest(),
    setModelDropdownData: (payload: ISetModelDropdownDataReq) =>
        dropDownActions.setModelDropdownDataRequest(payload),
    setYearDropdownData: (payload: ISetYearDropdownData | undefined) =>
        dropDownActions.setYearDropdownDataRequest(payload),
    setBodyTypeDropdownData: () =>
        dropDownActions.setBodyTypeDropdownDataRequest(),
    setTransmissionTypesDropdownData: () =>
        dropDownActions.setTransmissionTypesDropdownDataRequest(),
}

// const time_units = [
//     {
//         name: 'years',
//         id: 'years',
//     },
//     {
//         name: 'months',
//         id: 'months',
//     },
//     {
//         name: 'weeks',
//         id: 'weeks',
//     },
// ]

const drive_side_options = [
    {
        name: 'LHD',
    },
    {
        name: 'RHD',
    },
    {
        name: 'Center',
    },
]

const road_tax_status_options = [
    {
        name: 'Taxed',
    },
    {
        name: 'Untaxed',
    },
    {
        name: 'SORN',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]

const mot_status_options = [
    {
        name: 'Valid',
    },
    {
        name: 'Not Valid',
    },
    {
        name: 'NA',
    },
    {
        name: '-',
    },
]

class TechInfoFormManagerMobile extends React.Component<Props, {}> {
    componentDidMount() {
        let {
            item,
            allTechInfodata,
            setModelDropdownData,
            setMakeDropdownData,
            yearsdropdowndata,
            setYearDropdownData,
            body_types_list,
            setBodyTypeDropdownData,
            setTransmissionTypesDropdownData,
            makesdropdowndata,
        } = this.props

        if (item) {
            if (item.id === 'make') {
                if (!makesdropdowndata) {
                    // console.log('getting car makes')
                    setMakeDropdownData()
                }

                if (!yearsdropdowndata) {
                    setYearDropdownData(undefined)
                }
                if (item.answer && typeof item.answer === 'string') {
                    setModelDropdownData({
                        makeName: item.answer,
                        make_uid: item.uid_current,
                    })
                }
            }

            if (item.id === 'model') {
                if (item.answer) {
                    if (allTechInfodata.make.answer) {
                        setMakeDropdownData()
                        setModelDropdownData({
                            makeName: allTechInfodata.make.answer,
                            make_uid: item.uid_current,
                        })
                    }
                }
            }

            if (item.id === 'body_type') {
                if (!body_types_list) {
                    setBodyTypeDropdownData()
                }
            }
            if (item.id === 'transmission') {
                setTransmissionTypesDropdownData()
            } else return
        }
    }

    // distance
    distance_units: IList[] | null =
        this.props.user_distance_unit_ids_list &&
        this.props.user_distance_unit_ids_list.map(
            (id: string, index: number) => {
                return this.props.user_distance_unit_data![id]
            }
        )

    user_default_distance_unit: IList | null =
        this.props.user_distance_unit_data &&
        this.props.user_distance_unit_chosen_id
            ? this.props.user_distance_unit_data[
                  this.props.user_distance_unit_chosen_id
              ]
            : null

    // fuel
    fuel_units: IList[] | null =
        this.props.user_fuel_unit_ids_list && this.props.user_fuel_unit_data
            ? this.props.user_fuel_unit_ids_list.map(
                  (id: string, index: number) => {
                      return this.props.user_fuel_unit_data![id]
                  }
              )
            : null

    user_default_fuel_unit: IList | null =
        this.props.user_fuel_unit_data && this.props.user_fuel_unit_chosen_id
            ? this.props.user_fuel_unit_data[
                  this.props.user_fuel_unit_chosen_id
              ]
            : null

    renderForm = (
        item: any,
        toggle: any,
        writeTechnicalInformationRequest: any,
        writeMultipleTechnicalInformationRequest: any,
        id: string,
        allTechInfodata: any,
        carid: string,
        distance_units: IList[],
        fuel_units: IList[]
    ) => {
        let distanceUnitInfo = {
            list: distance_units,
            id: 'distance_units',
            user_default_unit: this.user_default_distance_unit?.id,
        }

        let fuelUnitInfo = {
            list: fuel_units,
            id: 'fuel_units',
            user_default_unit: this.user_default_fuel_unit?.id,
        }

        switch (item.id) {
            case 'make':
                return (
                    <CarMakeCustomFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        make_item={{
                            id: 'make',
                            answer: this.props.tempMakeDisplay,
                        }}
                        model_item={{
                            id: 'model',
                            answer: this.props.tempModelDisplay,
                        }}
                        year_item={allTechInfodata.year}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'model':
                return (
                    <CarModelCustomFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        toggle={toggle}
                        model_item={{
                            id: 'model',
                            answer: this.props.tempModelDisplay,
                        }}
                        year_item={allTechInfodata.year}
                        make_item={{
                            id: 'make',
                            answer: this.props.tempMakeDisplay,
                        }}
                        carid={carid}
                    />
                )

            case 'year':
                return (
                    <CarYearCustomFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        toggle={toggle}
                        model_item={{
                            id: 'model',
                            answer: this.props.tempModelDisplay,
                        }}
                        year_item={allTechInfodata.year}
                        carid={carid}
                    />
                )

            case 'body_type':
                return (
                    <CarBodyTypeCustomMobile
                        submitFunc={writeMultipleTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={this.props.body_types_list}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'transmission':
                return (
                    <CarTransmissionTypeCustomMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={this.props.transmission_types_list}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'drive_train':
                return (
                    <DuoRadioTechInfoEditFormMobile
                        optionsList={drive_train_options}
                        item={item}
                        toggle={toggle}
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        carid={carid}
                    />
                )

            case 'features':
                return (
                    <CarTechInfoEditFormMultipleSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={features}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'previous_owners_number':
                return (
                    <NumberTechInfoEditFormMobile
                        tech_info_id={id}
                        submitFunc={writeTechnicalInformationRequest}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'engine_capacity':
                return (
                    <NumberTechInfoEditFormMobile
                        isUnits={true}
                        // default_unit={this.default_fuel_unit}
                        unitInfo={fuelUnitInfo}
                        unitList={fuel_units}
                        item={item}
                        toggle={toggle}
                        tech_info_id={id}
                        submitFunc={writeTechnicalInformationRequest}
                        carid={carid}
                    />
                )

            case 'mileage':
                return (
                    <NumberTechInfoEditFormMobile
                        tech_info_id={id}
                        submitFunc={writeTechnicalInformationRequest}
                        isUnits={true}
                        // default_unit={this.default_distance_unit}
                        unitInfo={distanceUnitInfo}
                        unitList={distance_units}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'fuel_type':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={powertype}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
            case 'color_interior':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'color_exterior':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={colors}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'drive_side':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={drive_side_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'condition':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={vehicle_condition}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_status':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={road_tax_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                        // placeholder={`Select a status`}
                    />
                )

            case 'service_check_status':
                return (
                    <CarTechInfoEditFormSelectSearchMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        optionsList={mot_status_options}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                        // placeholder={`Select a status`}
                    />
                )

            case 'last_owner_change':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'tax_due':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'service_check_expiry':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            case 'registration_date':
                return (
                    <DatePickerTechInfoEditFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )

            default:
                return (
                    <BasicTechInfoEditFormMobile
                        submitFunc={writeTechnicalInformationRequest}
                        tech_info_id={id}
                        item={item}
                        toggle={toggle}
                        carid={carid}
                    />
                )
        }
    }
    render() {
        return (
            // <TouchScrollable>
            <>
                {this.distance_units &&
                    this.fuel_units &&
                    this.renderForm(
                        this.props.item,
                        this.props.toggle,
                        this.props.writeTechnicalInformationRequest,
                        this.props.writeMultipleTechnicalInformationRequest,
                        this.props.id,
                        this.props.allTechInfodata,
                        this.props.carid,
                        this.distance_units,
                        this.fuel_units
                    )}
                <div />
            </>
            // </TouchScrollable>
        )
    }
}

export default connect(
    mapStateToProps,
    dispatchProps
)(TechInfoFormManagerMobile)
