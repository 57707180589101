import { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import RiveAnimation from './riveWrapper'
import { MenuAdjust } from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import FadeWithExit from '../../templates/animated/fadeWithExit'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0px;
    left: 0px;
    right: 0;
    background-color: var(--loader-bg, rgba(255, 255, 255, 0.95));
    z-index: 110;
    opacity: 0.9;
`

const LoaderStyled = styled.div`
    width: 500px;
    height: 500px;

    @media ${device.mobile} {
        width: 300px;
        height: 300px;
    }
`

const array_of_rive_animations = [
    'https://public.rive.app/hosted/291004/133524/UtKtkGHYMESb9iF0TecYTQ.riv',
    'https://public.rive.app/hosted/291004/133525/SpYfN0ggjk_ueSJwYyhLuA.riv',
    'https://public.rive.app/hosted/291004/133526/jFdPXxa4R0uQdj2P3VWr_A.riv',
    'https://public.rive.app/hosted/291004/133562/D0mhMbOAA0qJVtvqbbHNjQ.riv',
]

const getIndexOfRandomRiveAnimation = () => {
    return Math.floor(Math.random() * array_of_rive_animations.length)
}

const LoaderCarOutline = ({ isOpen }: { isOpen: boolean }) => {
    let [animationIndexToUse, setAnimationIndexToUse] = useState(
        getIndexOfRandomRiveAnimation()
    )
    useEffect(() => {
        if (!isOpen) {
            let newRandomAnimIndex = getIndexOfRandomRiveAnimation()
            setAnimationIndexToUse(newRandomAnimIndex)
        }
    }, [isOpen])

    return (
        <Fragment>
            <FadeWithExit isVisible={isOpen} initial="enter">
                <Wrapper>
                    <DesktopDisplayOnly>
                        <MenuAdjust
                            style={{
                                height: '100vh',
                                justifyContent: 'center',
                            }}
                        >
                            <LoaderStyled>
                                <RiveAnimation
                                    url={
                                        array_of_rive_animations[
                                            animationIndexToUse
                                        ]
                                    }
                                />
                            </LoaderStyled>
                        </MenuAdjust>
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <LoaderStyled>
                            <RiveAnimation
                                url={
                                    array_of_rive_animations[
                                        animationIndexToUse
                                    ]
                                }
                            />
                        </LoaderStyled>
                    </IpadAndMobileDisplay>
                </Wrapper>
            </FadeWithExit>
        </Fragment>
    )
}

export default LoaderCarOutline
