import add_user_address from './addressSaga'
import auth_sagas from './authSagas'
import delete_account from './deleteAccount'
import get_shares_received_watcher from './getSharesReceived'
import get_user_archives from './getUserArchives'
import get_user_data from './getUserData'
import list_user_handovers_sagas from './listUserHandovers'
import logout from './logout'
import populate_user_shares_owned from './populateSharesOwned'
import resetPassword from './resetPassword'
import social_auth_sagas from './socialAuth'
import update_share_received_status_sagas from './updateShareReceivedStatus'
import update_user_general from './updateUserGeneralData'
import verifiedResetPassword from './verifiedResetPassword'
import verifyResetPassword from './verifyResetPasswordCode'

let arr: any[] = [
    ...update_user_general,
    ...get_user_data,
    ...auth_sagas,
    ...logout,
    ...resetPassword,
    ...verifyResetPassword,
    ...verifiedResetPassword,
    ...get_shares_received_watcher,
    ...populate_user_shares_owned,
    ...update_share_received_status_sagas,
    ...delete_account,
    ...get_user_archives,
    ...list_user_handovers_sagas,
    ...add_user_address,
    ...social_auth_sagas,
]

export default arr
