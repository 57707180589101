import { IHandover } from 'entityModels'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import { device } from '../../../templates/displays/devices'

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #1a1a1a;
    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 24px;
    }
    max-width: 400px;
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 5e-5em;
    color: #666666;
    max-width: 400px;

    @media ${device.mobile_and_ipad} {
        line-height: 30px;
    }
`

type Props = {
    cancel: () => any
    carID: string
    handover: IHandover
}

const HandoverUserChangeConfoModal = (props: Props) => {
    let history = useHistory()
    let { handover } = props
    let getName = () => {
        if (handover.new_owner_invite) {
            if (
                handover.new_owner_invite.recipient_given_name ||
                handover.new_owner_invite.recipient_family_name
            ) {
                return `${handover.new_owner_invite.recipient_given_name} ${handover.new_owner_invite.recipient_family_name}`
            } else return handover.new_owner_invite.recipient_email
        } else return handover.new_owner?.display_name
    }
    return (
        <>
            <Title>Handover this car to a different user</Title>
            <div style={{ paddingTop: '20px' }} />
            <Subtitle>
                By continuing with a new handover process, your previous
                handover to {getName()} will be canceled and all associated
                information will be deleted. This means that any progress made
                in the previous handover process will be lost and you will need
                to start again.
            </Subtitle>

            <div style={{ paddingTop: '50px' }} />

            <ButtonAtom
                theme="secondary"
                width="100%"
                height="48px"
                textTransform="capitalize"
                dataCyId={'handover-user-change'}
                onClick={() => {
                    // redux dispatch from here later\
                    history.push({
                        pathname: `/handover/${props.carID}`,
                        state: { prevPath: history.location.pathname },
                    })
                }}
                fontSize={16}
            >
                Confirm and Start Again
            </ButtonAtom>

            <div style={{ paddingTop: '30px' }} />
            <ButtonAtom
                theme="naked-text"
                height="48px"
                dataCyId={'handover-user-change-cancel'}
                icon={''}
                fontSize={16}
                onClick={() => {
                    props.cancel()
                }}
            >
                Cancel
            </ButtonAtom>
        </>
    )
}

export default HandoverUserChangeConfoModal
