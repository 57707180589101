import {
    ACCEPT_GARAGE_SHARE_INVITE_REQUEST,
    ADD_A_CAR_TO_GARAGE_REQUEST,
    ADD_SHARE_RECIPIENT_GARAGE_SHARE_REQUEST,
    CHANGE_FILTER_GARAGE_SHARE_DISPLAY_REQUEST,
    CHANGE_SORT_GARAGE_SHARE_DISPLAY_REQUEST,
    CHANGE_SORT_GARAGE_SHARE_RECIPIENTS_DISPLAY_REQUEST,
    CREATE_GARAGE_DIRECT_SHARE_REQUEST,
    CREATE_GARAGE_PUBLIC_SHARE_REQUEST,
    CREATE_GARAGE_SHARE_INVITE_REQUEST,
    DELETE_GARAGE_DIRECT_SHARE_REQUEST,
    DELETE_GARAGE_PUBLIC_SHARE_REQUEST,
    DELETE_GARAGE_SHARE_INVITE_REQUEST,
    DELETE_SHARE_RECIPIENT_GARAGE_REQUEST,
    GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_REQUEST,
    GET_GARAGE_BY_USER_ID_REQUEST,
    GET_OWNED_DIRECT_SHARES_GARAGE_REQUEST,
    GET_PUBLIC_SHARES_GARAGE_REQUEST,
    GET_SINGLE_GARAGE_DIRECT_SHARE_REQUEST,
    RESEND_GARAGE_SHARE_INVITE_REQUEST,
    SET_CURRENT_EXTERNAL_GARAGE_REQUEST,
    SET_GARAGE_DATA_REQUEST,
    SET_GARAGE_TASK_IDS_REQUEST,
    SORT_GARAGE_CARS_REQUEST,
    UPDATE_GARAGE_DIRECT_SHARE_REQUEST,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IDeleteShareReq } from '../../cars/actions/actions'
import {
    ICreateDirectShareRequest,
    IPrivateShares_Sort_IDS,
    ISetGarageActiveSort,
    ISharingPermissionIDS,
    ISortShareRecipientsDisplay_change_request,
    IUpdateGarageDirectShareRequest,
} from 'entityModels'
import {
    ICreateShareInviteRequestPayload,
    IgetSingleDirectShareRequest_redux,
    IShareInviteDeletion,
    IShareInvitResent,
    IShareRecipientRequest_redux,
} from '../../cars/actions/loadingActions'

export const setGarageDataRequest = createAction(SET_GARAGE_DATA_REQUEST)

export const getGarageByUserIdRequest = createAction(
    GET_GARAGE_BY_USER_ID_REQUEST
)

export const setGarageTaskIdsRequest = createAction(SET_GARAGE_TASK_IDS_REQUEST)

export const addCarToGarageRequest = createAction(ADD_A_CAR_TO_GARAGE_REQUEST)

export const getPublicSharesGarageRequest = createAction(
    GET_PUBLIC_SHARES_GARAGE_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const createGaragePublicShareRequest = createAction(
    CREATE_GARAGE_PUBLIC_SHARE_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const deleteGaragePublicShareRequest = createAction(
    DELETE_GARAGE_PUBLIC_SHARE_REQUEST,
    function prepare(payload: IDeleteShareReq) {
        return {
            payload,
        }
    }
)

export type ISortGarageSharesDisplay_change_request = {
    garageid: string
    sortID: IPrivateShares_Sort_IDS
}

export const sortGarageSharesDisplay_change_request = createAction(
    CHANGE_SORT_GARAGE_SHARE_DISPLAY_REQUEST,
    function prepare(payload: ISortGarageSharesDisplay_change_request) {
        return {
            payload,
        }
    }
)

export type IFilterGarageSharesDisplay_change_request = {
    garageid: string
    filterIDs: ISharingPermissionIDS[]
}

export const filterGarageSharesDisplay_change_request = createAction(
    CHANGE_FILTER_GARAGE_SHARE_DISPLAY_REQUEST,
    function prepare(payload: IFilterGarageSharesDisplay_change_request) {
        return {
            payload,
        }
    }
)

export const getExternalGaragesSharedWithYouREQUEST = createAction(
    GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_REQUEST,
    function prepare(payload: any) {
        return {
            payload,
        }
    }
)
export const setCurrentExternalGarageRequest = createAction(
    SET_CURRENT_EXTERNAL_GARAGE_REQUEST
)

export const getOwnedDirectSharesGarageRequest = createAction(
    GET_OWNED_DIRECT_SHARES_GARAGE_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const createGarageDirectShareRequest = createAction(
    CREATE_GARAGE_DIRECT_SHARE_REQUEST,
    function prepare(payload: ICreateDirectShareRequest) {
        return {
            payload,
        }
    }
)

export const getSingleGarageDirectShareRequest = createAction(
    GET_SINGLE_GARAGE_DIRECT_SHARE_REQUEST,
    function prepare(payload: IgetSingleDirectShareRequest_redux) {
        return {
            payload,
        }
    }
)

export const deleteGarageDirectShareRequest = createAction(
    DELETE_GARAGE_DIRECT_SHARE_REQUEST,
    function prepare(payload: IgetSingleDirectShareRequest_redux) {
        return {
            payload,
        }
    }
)

export const addGarageShareRecipientRequest = createAction(
    ADD_SHARE_RECIPIENT_GARAGE_SHARE_REQUEST,
    function prepare(payload: IShareRecipientRequest_redux) {
        return {
            payload,
        }
    }
)
export const deleteGarageShareRecipientRequest = createAction(
    DELETE_SHARE_RECIPIENT_GARAGE_REQUEST,
    function prepare(payload: IShareRecipientRequest_redux) {
        return {
            payload,
        }
    }
)

export const sortGarageShareRecipientsDisplay_change_request = createAction(
    CHANGE_SORT_GARAGE_SHARE_RECIPIENTS_DISPLAY_REQUEST,
    function prepare(payload: ISortShareRecipientsDisplay_change_request) {
        return {
            payload,
        }
    }
)

export const updateGarageDirectShareRequest = createAction(
    UPDATE_GARAGE_DIRECT_SHARE_REQUEST,
    function prepare(payload: IUpdateGarageDirectShareRequest) {
        return {
            payload,
        }
    }
)

export const createGarageShareInviteRequest = createAction(
    CREATE_GARAGE_SHARE_INVITE_REQUEST,
    function prepare(payload: ICreateShareInviteRequestPayload) {
        return {
            payload,
        }
    }
)

export const deleteGarageShareInviteRequest = createAction(
    DELETE_GARAGE_SHARE_INVITE_REQUEST,
    function prepare(payload: IShareInviteDeletion) {
        return {
            payload,
        }
    }
)

export const resendGarageShareInviteRequest = createAction(
    RESEND_GARAGE_SHARE_INVITE_REQUEST,
    function prepare(payload: IShareInvitResent) {
        return {
            payload,
        }
    }
)

export const acceptGarageShareInviteRequest = createAction(
    ACCEPT_GARAGE_SHARE_INVITE_REQUEST,
    function prepare(payload: { invite_code: string; next_path: string }) {
        return {
            payload,
        }
    }
)

export const setGarageActiveSortingRequest = createAction(
    SORT_GARAGE_CARS_REQUEST,
    function prepare(payload: ISetGarageActiveSort) {
        return { payload }
    }
)
