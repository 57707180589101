import {
    AsYouType,
    isValidPhoneNumber,
    parsePhoneNumber,
} from 'libphonenumber-js'
import * as React from 'react'
import styled from 'styled-components'
import { editFormsActions } from '../../../redux/editForms/reducer'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import InputFieldNoFormikAnimated from '../../atoms/Inputfield/inputFieldNoFormikAnimated'
import SelectSearchBarCarInfoDesktop from '../editForms/technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import country_codes, { CountryCodeObj } from './countryCodes'
import Expander from '../../atoms/expander/expander'
import { ErrorText } from '../editForms/account/accountAddressForm/accountAddressformDesktop'

type Props = {
    value: any
    handleChangeNumber: any
    runError?: boolean
    isPhoneInvalid_parent_check?: boolean
    backgroundColor?: string
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
`

const PhoneInputNew = (props: Props) => {
    let code: string | undefined = useAppSelector((state) => {
        return state.editForms.user_dial_code
    })
    let country: string | undefined = useAppSelector((state) => {
        return state.editForms.user_dial_country
    })

    const { value, handleChangeNumber, isPhoneInvalid_parent_check } = props

    const [displayV, setDisplayV] = React.useState<string>(value)
    const [hasCountrySet, setHasCountrySet] = React.useState<boolean>(false)
    const [isPhoneInvalid, setIsPhoneInvalid] = React.useState<boolean>(false)

    const dispatch = useAppDispatch()

    let setDisplayValue = (value: string, country: string, code: string) => {
        if (value && value?.length > 3 && country !== undefined) {
            // @ts-ignore
            let display = new AsYouType(country).input(code + value)

            let codeRemoved = display.replace(code, '')

            if (codeRemoved.length === 0) {
                setDisplayV(value)
            } else {
                setDisplayV(codeRemoved)
            }
        } else {
            setDisplayV(value)
        }
    }

    React.useEffect(() => {
        if (value && value?.length > 3) {
            let isPhoneNbValid = isValidPhoneNumber(
                value,
                // @ts-ignore
                country ? country : 'GB'
            )

            if (isPhoneNbValid) {
                let phoneNumber = parsePhoneNumber(
                    `${value}`,
                    // @ts-ignore
                    country ? country : 'GB'
                )

                if (phoneNumber) {
                    // handleChangeNumber(`${phoneNumber.nationalNumber}`)
                    if (phoneNumber?.country) {
                        dispatch(
                            editFormsActions.setUserDialCountry(
                                `${phoneNumber.country}`
                            )
                        )

                        country_codes.some((country: CountryCodeObj) => {
                            if (country.uid === `${phoneNumber.country}`) {
                                dispatch(
                                    editFormsActions.setUserDialCode(
                                        country.code
                                    )
                                )
                                dispatch(
                                    editFormsActions.setUserDialCountry(
                                        `${country.uid}`
                                    )
                                )

                                setDisplayValue(
                                    `${phoneNumber.nationalNumber}`,
                                    country.uid,
                                    country.code
                                )

                                setHasCountrySet(true)
                            }
                        })
                    } else {
                        dispatch(editFormsActions.setUserDialCode('+44'))
                        setDisplayValue(
                            `${phoneNumber.nationalNumber}`,
                            'GB',
                            '+44'
                        )
                        setHasCountrySet(true)
                    }
                } else {
                    dispatch(editFormsActions.setUserDialCode('+44'))
                    setDisplayValue(value, 'GB', '+44')
                    setHasCountrySet(true)
                }
            } else {
                setIsPhoneInvalid(true)
                dispatch(editFormsActions.setUserDialCode('+44'))
                setDisplayValue(value, 'GB', '+44')
                setHasCountrySet(true)
            }
        } else {
            setHasCountrySet(true)
        }
    }, [])

    React.useEffect(() => {
        if (isPhoneInvalid_parent_check) {
            let isPhoneNbValid = isValidPhoneNumber(
                `${value}`,
                // @ts-ignore
                country ? country : 'GB'
            )
            setIsPhoneInvalid(isPhoneNbValid ? false : true)
        }
    }, [isPhoneInvalid_parent_check, value])

    return (
        <React.Fragment>
            <Container>
                {hasCountrySet && (
                    <SelectSearchBarCarInfoDesktop
                        items={country_codes}
                        item={{
                            text: 'countries',
                            answer: country,
                        }}
                        handleSelectChange={(selected: string) => {
                            dispatch(
                                editFormsActions.setUserDialCountry(selected)
                            )
                            country_codes.some((country: CountryCodeObj) => {
                                if (
                                    country.uid?.toString() ===
                                    selected.toString()
                                ) {
                                    dispatch(
                                        editFormsActions.setUserDialCode(
                                            country.code
                                        )
                                    )
                                }
                            })

                            if (value?.length > 3 && code !== undefined) {
                                // @ts-ignore
                                let display = new AsYouType(code).input(
                                    code + value
                                )

                                var ret = display.replace(code, '')

                                setDisplayV(ret)
                            }
                        }}
                        placeholder="Country"
                        width={'150px'}
                        formCurrentValue={country}
                        onlyShowMatch={false}
                        showBothDevices={true}
                        iconsSize={'15px'}
                        backgroundColor={props.backgroundColor}
                    />
                )}
                <div style={{ paddingLeft: '20px' }} />

                <InputFieldNoFormikAnimated
                    name="phone"
                    placeholder="phone"
                    onChange={(e: any) => {
                        handleChangeNumber(e.target.value)
                        setIsPhoneInvalid(false)
                        if (e.target.value?.length > 3 && code) {
                            // @ts-ignore
                            let display = new AsYouType(`${code}`).input(
                                code + e.target.value
                            )

                            var ret = display.replace(code, '')

                            setDisplayV(ret)
                        } else {
                            setDisplayV(e.target.value)
                        }
                    }}
                    value={displayV}
                    width={'100%'}
                    valueStart={code}
                    isPhone={true}
                    backgroundColor={props.backgroundColor}
                />
            </Container>

            <Expander height={isPhoneInvalid ? 'auto' : 0}>
                <ErrorText
                    style={{
                        paddingTop: 10,
                    }}
                >
                    Your phone number is invalid.
                </ErrorText>
            </Expander>

            {/* <div> international : `{phoneNumber?.nationalNumber}` </div>
            <div> national : `{phoneNumber?.formatInternational}`</div> */}
        </React.Fragment>
    )
}

export default PhoneInputNew
