import { FC } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
    GallerySortableItem,
    IGallerySortableItemProps,
} from './gallerySortableManager'

const SortableImageItem: FC<IGallerySortableItemProps> = (props) => {
    const {
        isDragging,
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || undefined,
    }

    return (
        <GallerySortableItem
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            {...props}
            {...attributes}
            {...listeners}
        />
    )
}

export default SortableImageItem
