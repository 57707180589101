import { createAction } from '@reduxjs/toolkit'
import {
    CHECK_USER_EMAIL_EXISTS_REQUEST,
    SEARCH_PLATFORM_USERS_REQUEST,
} from './constants'

export const searchPlatformUsersRequest = createAction(
    SEARCH_PLATFORM_USERS_REQUEST,
    function prepare(payload: string | undefined) {
        return {
            payload,
        }
    }
)

export const checkUserEmailExistsRequest = createAction(
    CHECK_USER_EMAIL_EXISTS_REQUEST,
    function prepare(payload: string | undefined) {
        return {
            payload,
        }
    }
)
