import React from 'react'
import { connect } from 'react-redux'

import { RootState } from 'typesafe-actions'

import { IUser } from 'myModels'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import RemindersFieldsManager from '../../organisms/remindersFieldsManager'

import {
    addRemindersToTaskRequest,
    deleteReminderTaskRequest,
    getTaskByIdRequest,
    getTaskRemindersRequest,
    setTaskRemindersEditedRequest,
} from '../../../redux/tasks/actions/requestActions'
import {
    IGarageObject,
    IPayloadTaskRemindersState,
    IReminder,
    IReminderEdited,
    IReminderEditedData,
    ISetTaskRemindersEditedPayload,
    ITask,
    ITaskNormalisedObject,
} from 'entityModels'
import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { getGarageByUserIdRequest } from '../../../redux/entities/garages/actions/loadingActions'
import ButtonsStandardBottomRowMobile from '../../organisms/editForms/buttons/buttonsStandardBottomRowMobile'
import styled from 'styled-components'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import RemindersTopText from '../../atoms/typography/remindersTopText'
import ButtonsStandardBottomRowDesktop from '../../organisms/editForms/buttons/buttonsStandardBottomRowDesktop'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import ModalDisplayDesktopExclusive from '../../templates/displays/pageWrappers/modalDisplayDesktopExclusive'
import { getTaskRemindersSuccess } from '../../../redux/tasks/actions/successActions'
import RemindersViewManager from '../../organisms/remindersViewManager/remindersViewManager'
import Expander from '../../atoms/expander/expander'
import { IDeleteTaskReminderReqSaga } from '../../../redux/tasks/sagas/deleteTaskReminder'
import moment from 'moment'
import {
    // DatePickerConverterFormat,
    fromReminderDateToBackEndFormat,
    IConvertReminderDateToBEPayload,
} from '../../../redux/conversions/time'

import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
} from '../../molecules/technicalInformation/modal'
import { IGetTaskRemindersReq } from '../../../redux/tasks/sagas/getTaskReminders'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

const { v4: uuidv4 } = require('uuid')

export type IIsDateValidPayload = {
    type_val: string
    nVal: number
    dueDate?: string
    minDate?: string
    maxDate?: string
}

export const isDateValid = (payload: IIsDateValidPayload): boolean => {
    let p: IConvertReminderDateToBEPayload = {
        type: payload.type_val,
        value: '',
        nvalue: payload.nVal,
        dueDate: payload.dueDate
            ? payload.dueDate
            : payload.maxDate
            ? payload.maxDate
            : '',
    }

    let be_date: string = fromReminderDateToBackEndFormat(p)

    if (payload.maxDate) {
        let isTooLate: boolean = moment(be_date).isAfter(payload.maxDate)
        if (isTooLate === true) {
            return false
        }
    }

    if (payload.minDate) {
        let isTooEarly: boolean =
            moment(be_date).isBefore(payload.minDate) ||
            moment(be_date).isSame(payload.minDate)

        if (isTooEarly === true) {
            return false
        }
    }
    return true
}

interface Props extends RouteComponentProps<any> {
    userLoggedIn?: null | IUser
    setTaskRemindersEditedRequest: (
        payload: ISetTaskRemindersEditedPayload
    ) => any
    tasksData: ITaskNormalisedObject
    getGarageCarsDataRequest: (id: string) => void
    getTaskByIdRequest: (id: string) => void
    getGarageByUserIdRequest: () => void
    getUserDataRequest: () => void
    garage_id: string | null
    garage_data: IGarageObject
    getTaskReminders: (payload: IPayloadTaskRemindersState) => any
    deleteReminderTaskRequest: (p: IDeleteTaskReminderReqSaga) => any
    addRemindersToTaskRequest: (p: ISetTaskRemindersEditedPayload) => any
    setInitialLandingUrl: (str: string) => void
    getTaskRemindersRequest: (p: IGetTaskRemindersReq) => void
}

const mapStateToProps = (state: RootState) => ({
    userLoggedIn: state.user.userLoggedIn,
    tasksData: state.tasks.tasks,
    garage_data: state.entities.garagesData.garages,
    garage_id:
        state.user.userLoggedIn && state.user.userLoggedIn.owns_garage?.uid,
})

const dispatchToProps = {
    setTaskRemindersEditedRequest: (payload: ISetTaskRemindersEditedPayload) =>
        setTaskRemindersEditedRequest(payload),
    getTaskReminders: (payload: IPayloadTaskRemindersState) =>
        getTaskRemindersSuccess(payload),
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    getTaskByIdRequest: (id: string) => getTaskByIdRequest(id),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageByUserIdRequest: () => getGarageByUserIdRequest(),
    deleteReminderTaskRequest: (payload: IDeleteTaskReminderReqSaga) =>
        deleteReminderTaskRequest(payload),
    addRemindersToTaskRequest: (p: ISetTaskRemindersEditedPayload) =>
        addRemindersToTaskRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    getTaskRemindersRequest: (p: IGetTaskRemindersReq) =>
        getTaskRemindersRequest(p),
}

let taskReminderEditedEmpty = (): IReminderEdited => {
    return {
        id: uuidv4(),
        type: undefined,
        datePicked: undefined,
        hasError: false,
    }
}

export type ISetEditedReminderChangeInnerPayload = {
    reminder_id: string
    changeDataType?: 'type' | 'datePicked' | 'nvalue'
    val?: string | number
    isDeletion?: boolean
    hasError?: boolean
}

const MobileWrapper = styled.div`
    background-color: var(--bg-color, #fff);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 60px;
    position: absolute;
    top: 0px;
    bottom: 100px;
    overflow-y: auto;
    left: 0;
    right: 0;
    z-index: 5;
    /* max-width: 450px; */
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
`

const MobileButtonsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 60px;
`

const DesktopButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 80px;
`
const MicroPaddingTop = styled.div`
    padding-top: 10px;
`
const SmallPaddingTop = styled.div`
    padding-top: 20px;
`

type State = {
    isDesktop: boolean
    existingRemindersBeingDeleted: string[]
    savedClicked?: boolean
}

class TaskReminders extends React.Component<Props, State> {
    state = {
        isDesktop: false,
        existingRemindersBeingDeleted: [] as any,
        savedClicked: false,
        // need to disable saving if reminder set is in past
    }

    componentDidMount() {
        let currentPath = this.props.history.location.pathname
        let carid = this.props.match.params.carid

        let params = new URLSearchParams(this.props.location.search)

        let taskid = params.get('taskid') || this.props.match.params.taskid
        if (currentPath.match(/car/g) !== null && carid && taskid) {
            this.props.setInitialLandingUrl(
                `/car/${carid}/tasks/${taskid}/reminders`
            )
        } else {
            taskid &&
                this.props.setInitialLandingUrl(
                    `/tasks/task?taskid=${taskid}/reminders`
                )
        }

        let view_id = params.get('viewid')
        let garageid = this.props.garage_id

        if (taskid && this.props.tasksData && this.props.tasksData[taskid]) {
            if (view_id === 'create') {
                let new_item: IReminderEdited = taskReminderEditedEmpty()
                this.props.setTaskRemindersEditedRequest({
                    taskID: taskid,
                    reminders: {
                        [new_item.id]: new_item,
                    },
                })
            }
        }

        let { getGarageByUserIdRequest } = this.props

        let userData = this.props.userLoggedIn

        if (!userData) {
            this.props.getUserDataRequest()
        }

        if (
            !garageid ||
            (this.props.garage_data && !this.props.garage_data[garageid])
        ) {
            getGarageByUserIdRequest()
        }

        if (window.innerWidth > 881) {
            this.setState({ isDesktop: true })
        } else {
            this.setState({ isDesktop: false })
        }

        if (
            taskid &&
            this.props.userLoggedIn &&
            this.props.tasksData &&
            this.props.tasksData[taskid] &&
            this.props.tasksData[taskid].due &&
            this.props.tasksData[taskid].has_reminders === true &&
            !this.props.tasksData[taskid].reminders
        ) {
            this.props.getTaskRemindersRequest({
                taskUID: taskid,
                dueDate: this.props.tasksData[taskid].due,
            })
        }
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):

        let params = new URLSearchParams(this.props.location.search)
        let taskid = params.get('taskid') || this.props.match.params.taskid
        let userData = this.props.userLoggedIn
        let garageid = this.props.garage_id

        if (userData !== prevProps.userLoggedIn) {
            if (userData) {
                if (garageid && taskid) {
                    // garage stuff

                    this.props.getGarageCarsDataRequest(garageid)
                    //  tasks stuff
                    return this.props.getTaskByIdRequest(taskid)
                }
            }
        }

        if (this.props.tasksData !== prevProps.tasksData) {
            if (
                taskid &&
                this.props.tasksData &&
                this.props.tasksData[taskid] &&
                !this.props.tasksData[taskid].reminders_being_added
            ) {
                let params = new URLSearchParams(this.props.location.search)
                let view_id = params.get('viewid')
                if (view_id === 'create') {
                    let new_item: IReminderEdited = taskReminderEditedEmpty()
                    this.props.setTaskRemindersEditedRequest({
                        taskID: taskid,
                        reminders: {
                            [new_item.id]: new_item,
                        },
                    })
                }
            }
        }
    }

    render() {
        let params = new URLSearchParams(this.props.location.search)
        let view_id = params.get('viewid')

        let taskid = params.get('taskid') || this.props.match.params.taskid

        let taskItem: ITask | undefined =
            this.props.tasksData && taskid && this.props.tasksData[taskid]
                ? this.props.tasksData[taskid]
                : undefined

        let new_item: IReminderEdited = taskReminderEditedEmpty()

        let taskRemindersBeingEdited: IReminderEditedData | undefined =
            taskItem && taskItem.reminders_being_added
                ? taskItem.reminders_being_added
                : view_id === 'create'
                ? {
                      [new_item.id]: new_item,
                  }
                : undefined

        const addRemindersToBeEdited = () => {
            let new_item_to_be_edited: IReminderEdited =
                taskReminderEditedEmpty()
            taskid &&
                this.props.setTaskRemindersEditedRequest({
                    taskID: taskid,
                    reminders: {
                        ...taskRemindersBeingEdited,
                        [new_item_to_be_edited.id]: new_item_to_be_edited,
                    },
                })
        }

        let taskRemindersBeingEditedIdsList: string[] | undefined = !taskItem
            ? undefined
            : taskItem.reminders_being_added
            ? Object.keys(taskItem.reminders_being_added)
            : undefined

        let currentReminders: IReminder[] | undefined = !taskItem
            ? undefined
            : taskItem.reminders

        let handleChange = (p: ISetEditedReminderChangeInnerPayload) => {
            let new_reminders_edited_object_: IReminderEditedData = {
                ...taskRemindersBeingEdited,
            }

            if (p.changeDataType) {
                new_reminders_edited_object_ = {
                    ...new_reminders_edited_object_,
                    [p.reminder_id]: {
                        ...new_reminders_edited_object_[p.reminder_id],
                        [p.changeDataType]:
                            p.changeDataType === 'nvalue' &&
                            p.val === typeof String
                                ? parseInt(p.val)
                                : p.val,
                        hasError: p.hasError === true ? true : false,
                    },
                }
            }

            if (p.isDeletion) {
                delete new_reminders_edited_object_[p.reminder_id]
            }

            setTimeout(() => {
                taskid &&
                    this.props.setTaskRemindersEditedRequest({
                        taskID: taskid,
                        reminders: new_reminders_edited_object_,
                    })
            }, 300)
        }

        let handleDeletionOfExistingReminderBeforeSave = (
            reminderUID: string
        ) => {
            let existingTaskRemindersList: string[] | undefined =
                taskItem && taskItem.reminders
                    ? taskItem.reminders.map(
                          (reminder: IReminder) => reminder.id
                      )
                    : undefined

            if (
                existingTaskRemindersList &&
                existingTaskRemindersList.includes(reminderUID)
            )
                this.setState({
                    ...this.state,
                    existingRemindersBeingDeleted: [
                        ...this.state.existingRemindersBeingDeleted,
                        reminderUID,
                    ],
                })
        }

        let minDate = moment().format('YYYY-MM-DD')
        // let maxDate = DatePickerConverterFormat(taskItem?.due)

        let checkIfSavedDisabled = (): boolean | undefined => {
            let hasErrorV: boolean = false
            if (taskRemindersBeingEditedIdsList && taskRemindersBeingEdited) {
                for (const reminderID of taskRemindersBeingEditedIdsList) {
                    if (
                        taskRemindersBeingEdited[reminderID] &&
                        taskRemindersBeingEdited[reminderID].type &&
                        taskRemindersBeingEdited[reminderID].nvalue
                    ) {
                        let nVal = taskRemindersBeingEdited[reminderID].nvalue

                        let ntype = taskRemindersBeingEdited[reminderID].type

                        if (nVal && ntype) {
                            let payload: IIsDateValidPayload = {
                                nVal: nVal,
                                type_val: ntype,
                                minDate: minDate,
                                dueDate: taskItem?.due,
                            }
                            let isValid = isDateValid(payload)

                            if (isValid === false) {
                                hasErrorV = true
                            }
                        }
                    }
                    if (
                        taskRemindersBeingEdited[reminderID].nvalue ===
                            undefined &&
                        taskRemindersBeingEdited[reminderID].type ===
                            undefined &&
                        taskRemindersBeingEdited[reminderID].datePicked ===
                            undefined
                    ) {
                        hasErrorV = true
                    }

                    if (
                        taskRemindersBeingEdited[reminderID].datePicked !==
                            undefined &&
                        moment(
                            taskRemindersBeingEdited[reminderID].datePicked
                        ).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')
                    ) {
                        hasErrorV = true
                    }
                    if (
                        taskRemindersBeingEdited[reminderID].type ===
                            'custom_date_copy' &&
                        taskRemindersBeingEdited[reminderID].datePicked ===
                            undefined
                    ) {
                        hasErrorV = true
                    }
                }
            }

            return hasErrorV
        }

        let onSaveClick = () => {
            this.setState({ ...this.state, savedClicked: true })
            if (taskItem) {
                if (
                    taskid &&
                    this.state.existingRemindersBeingDeleted.length > 0
                ) {
                    this.props.deleteReminderTaskRequest({
                        taskUID: taskid,
                        reminderIDs: this.state.existingRemindersBeingDeleted
                            ? this.state.existingRemindersBeingDeleted
                            : [],
                    })
                }
                if (taskItem && taskid && taskItem.reminders_being_added) {
                    this.props.addRemindersToTaskRequest({
                        taskID: taskid,
                        reminders: taskRemindersBeingEdited,
                        readyToSendToApi: true,
                    })
                }
            }

            this.props.setTaskRemindersEditedRequest({
                taskID: taskid,
                reminders: undefined,
            })

            setTimeout(() => {
                this.props.history.goBack()
            }, 150)
        }

        let currentPath = this.props.history.location.pathname
        let carid = this.props.match.params.carid
        const isCarPage =
            currentPath.match(/car/g) !== null && carid ? true : false

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        <React.Fragment>
                            <DesktopDisplayOnly>
                                {this.state.isDesktop === true && (
                                    <Redirect
                                        to={
                                            taskItem?.has_reminders
                                                ? isCarPage
                                                    ? `/car/${carid}/tasks/task?taskid=${taskid}&reminders=true&viewid=view`
                                                    : `/tasks/task?taskid=${taskid}&reminders=true&viewid=view`
                                                : isCarPage
                                                ? `/car/${carid}/tasks/task?taskid=${taskid}&reminders=true&viewid=create`
                                                : `/tasks/task?taskid=${taskid}&reminders=true&viewid=create`
                                        }
                                    />
                                )}
                                <ModalDisplayDesktopExclusive
                                    toggle={() => {
                                        isCarPage
                                            ? this.props.history.push(
                                                  `/car/${carid}/tasks/task?taskid=${taskid}`
                                              )
                                            : this.props.history.push(
                                                  `/tasks/task?taskid=${taskid}`
                                              )
                                    }}
                                    isOpen={true}
                                >
                                    <ModalContentDesktop $theme={theme}>
                                        <ModalInnerContentWrapperDesktop
                                            style={{ paddingBottom: 40 }}
                                        >
                                            <>
                                                <RemindersTopText
                                                    task={taskItem}
                                                    isCreation={
                                                        view_id === 'create'
                                                            ? true
                                                            : false
                                                    }
                                                    isView={
                                                        view_id === 'view'
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <div
                                                    style={{
                                                        paddingTop: '30px',
                                                    }}
                                                />

                                                {view_id === 'create' && (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {taskRemindersBeingEditedIdsList &&
                                                        taskRemindersBeingEdited !==
                                                            undefined &&
                                                        taskRemindersBeingEditedIdsList.length >
                                                            0 &&
                                                        this.state
                                                            .savedClicked !==
                                                            true ? (
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                {taskRemindersBeingEdited !==
                                                                    undefined &&
                                                                    taskRemindersBeingEditedIdsList.map(
                                                                        (
                                                                            reminder_id: string,
                                                                            index: number
                                                                        ) => {
                                                                            if (
                                                                                taskRemindersBeingEdited &&
                                                                                taskRemindersBeingEdited[
                                                                                    reminder_id
                                                                                ]
                                                                            ) {
                                                                                return (
                                                                                    <div
                                                                                        key={`create_${reminder_id}_${index}_desktop`}
                                                                                        style={{
                                                                                            position:
                                                                                                'relative',
                                                                                            zIndex:
                                                                                                50 -
                                                                                                index,
                                                                                        }}
                                                                                    >
                                                                                        <RemindersFieldsManager
                                                                                            handleChange={
                                                                                                handleChange
                                                                                            }
                                                                                            item={
                                                                                                taskRemindersBeingEdited[
                                                                                                    reminder_id
                                                                                                ]
                                                                                            }
                                                                                            dueDate={
                                                                                                taskItem?.due
                                                                                                    ? taskItem?.due
                                                                                                    : moment()
                                                                                            }
                                                                                            minDate={
                                                                                                minDate
                                                                                            }
                                                                                            // maxDate={
                                                                                            //     maxDate
                                                                                            // }
                                                                                            variant="desktop"
                                                                                        />
                                                                                        <MicroPaddingTop />
                                                                                    </div>
                                                                                )
                                                                            } else
                                                                                return (
                                                                                    <div
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                    />
                                                                                )
                                                                        }
                                                                    )}

                                                                <SmallPaddingTop />

                                                                <div
                                                                    style={{
                                                                        display:
                                                                            'unset',
                                                                    }}
                                                                >
                                                                    <ButtonAtom
                                                                        theme="naked-text"
                                                                        fontSize={
                                                                            18
                                                                        }
                                                                        onClick={() =>
                                                                            addRemindersToBeEdited()
                                                                        }
                                                                        disabled={checkIfSavedDisabled()}
                                                                        dataCyId="add_another_reminder_desktop"
                                                                    >
                                                                        Add
                                                                        another
                                                                        reminder
                                                                    </ButtonAtom>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <ButtonAtom
                                                                theme="capitalize-white-background"
                                                                width="100%"
                                                                onClick={() =>
                                                                    addRemindersToBeEdited()
                                                                }
                                                                dataCyId="add_first_reminder_mobile"
                                                            >
                                                                Create Reminder
                                                            </ButtonAtom>
                                                        )}
                                                    </div>
                                                )}
                                                {view_id === 'view' && (
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {currentReminders &&
                                                            currentReminders.map(
                                                                (
                                                                    item: IReminder,
                                                                    key: number
                                                                ) => (
                                                                    <React.Fragment
                                                                        key={`view_${item.id}_${key}_desktop`}
                                                                    >
                                                                        <Expander
                                                                            height={
                                                                                item &&
                                                                                this.state.existingRemindersBeingDeleted.includes(
                                                                                    item.id
                                                                                )
                                                                                    ? 0
                                                                                    : 'auto'
                                                                            }
                                                                        >
                                                                            <RemindersViewManager
                                                                                reminder={
                                                                                    item
                                                                                }
                                                                                deleteReminder={(
                                                                                    reminderUID: string
                                                                                ) =>
                                                                                    handleDeletionOfExistingReminderBeforeSave(
                                                                                        reminderUID
                                                                                    )
                                                                                }
                                                                                taskDueDate={
                                                                                    taskItem?.due
                                                                                }
                                                                            />
                                                                            <MicroPaddingTop />
                                                                            {currentReminders &&
                                                                                key ===
                                                                                    currentReminders?.length -
                                                                                        1 && (
                                                                                    <div
                                                                                        style={{
                                                                                            paddingBottom:
                                                                                                '16px',
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                        </Expander>
                                                                    </React.Fragment>
                                                                )
                                                            )}
                                                        {taskRemindersBeingEditedIdsList &&
                                                            taskRemindersBeingEdited &&
                                                            this.state
                                                                .savedClicked !==
                                                                true && (
                                                                <div
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                >
                                                                    {taskRemindersBeingEdited !==
                                                                        undefined &&
                                                                        taskRemindersBeingEditedIdsList.map(
                                                                            (
                                                                                reminder_id: string,
                                                                                index: number
                                                                            ) => {
                                                                                if (
                                                                                    taskRemindersBeingEdited &&
                                                                                    taskRemindersBeingEdited[
                                                                                        reminder_id
                                                                                    ]
                                                                                ) {
                                                                                    return (
                                                                                        <div
                                                                                            key={`edit_${reminder_id}_${index}_desktop`}
                                                                                            style={{
                                                                                                position:
                                                                                                    'relative',
                                                                                                zIndex:
                                                                                                    50 -
                                                                                                    index,
                                                                                            }}
                                                                                        >
                                                                                            <RemindersFieldsManager
                                                                                                handleChange={
                                                                                                    handleChange
                                                                                                }
                                                                                                item={
                                                                                                    taskRemindersBeingEdited[
                                                                                                        reminder_id
                                                                                                    ]
                                                                                                }
                                                                                                dueDate={
                                                                                                    taskItem?.due
                                                                                                        ? taskItem?.due
                                                                                                        : moment()
                                                                                                }
                                                                                                minDate={
                                                                                                    minDate
                                                                                                }
                                                                                                // maxDate={
                                                                                                //     maxDate
                                                                                                // }
                                                                                                variant="desktop"
                                                                                            />
                                                                                            <MicroPaddingTop />
                                                                                        </div>
                                                                                    )
                                                                                } else
                                                                                    return (
                                                                                        <div
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        />
                                                                                    )
                                                                            }
                                                                        )}
                                                                </div>
                                                            )}
                                                        <SmallPaddingTop />
                                                        <div
                                                            style={{
                                                                transform:
                                                                    currentReminders !==
                                                                        undefined &&
                                                                    currentReminders.length >
                                                                        0 &&
                                                                    (taskRemindersBeingEdited ===
                                                                        undefined ||
                                                                        Object.keys(
                                                                            taskRemindersBeingEdited
                                                                        )
                                                                            .length <
                                                                            1)
                                                                        ? 'translateY(-20px)'
                                                                        : 'translateY(0px)',
                                                                display:
                                                                    'unset',
                                                            }}
                                                        >
                                                            <ButtonAtom
                                                                theme="naked-text"
                                                                fontSize={18}
                                                                onClick={() =>
                                                                    addRemindersToBeEdited()
                                                                }
                                                                disabled={checkIfSavedDisabled()}
                                                                dataCyId="add_another_reminder_desktop"
                                                            >
                                                                Add another
                                                                reminder
                                                            </ButtonAtom>
                                                        </div>
                                                    </div>
                                                )}
                                            </>

                                            <DesktopButtonsWrapper>
                                                <ButtonsStandardBottomRowDesktop
                                                    cancel={() => {
                                                        isCarPage
                                                            ? this.props.history.push(
                                                                  `/car/${carid}/tasks/task?taskid=${taskid}`
                                                              )
                                                            : this.props.history.push(
                                                                  `/tasks/task?taskid=${taskid}`
                                                              )
                                                    }}
                                                    submit={() => onSaveClick()}
                                                    isDisabled={checkIfSavedDisabled()}
                                                    dataCyIdUpdate="create_multiple_reminders_desktop"
                                                    dataCyIdCancel="cancel_creation_of_multiple_reminders_desktop"
                                                />
                                            </DesktopButtonsWrapper>
                                        </ModalInnerContentWrapperDesktop>
                                    </ModalContentDesktop>
                                </ModalDisplayDesktopExclusive>
                            </DesktopDisplayOnly>
                            <IpadAndMobileDisplay>
                                <MobileWrapper>
                                    <RemindersTopText
                                        task={taskItem}
                                        isCreation={
                                            view_id === 'create' ? true : false
                                        }
                                        isView={
                                            view_id === 'view' ? true : false
                                        }
                                    />
                                    <div style={{ paddingTop: '34px' }} />

                                    {view_id === 'create' && (
                                        <>
                                            {taskRemindersBeingEditedIdsList &&
                                            taskRemindersBeingEdited &&
                                            taskRemindersBeingEditedIdsList.length >
                                                0 ? (
                                                <>
                                                    {taskRemindersBeingEdited &&
                                                        taskRemindersBeingEditedIdsList.map(
                                                            (
                                                                reminder_id: string,
                                                                index: number
                                                            ) => {
                                                                if (
                                                                    taskRemindersBeingEdited &&
                                                                    taskRemindersBeingEdited[
                                                                        reminder_id
                                                                    ]
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={`create_${reminder_id}_${index}_mobile`}
                                                                            style={{
                                                                                position:
                                                                                    'relative',
                                                                                zIndex:
                                                                                    50 -
                                                                                    index,
                                                                            }}
                                                                        >
                                                                            <RemindersFieldsManager
                                                                                handleChange={
                                                                                    handleChange
                                                                                }
                                                                                item={
                                                                                    taskRemindersBeingEdited[
                                                                                        reminder_id
                                                                                    ]
                                                                                }
                                                                                dueDate={
                                                                                    taskItem?.due
                                                                                        ? taskItem?.due
                                                                                        : moment()
                                                                                }
                                                                                minDate={
                                                                                    minDate
                                                                                }
                                                                                // maxDate={
                                                                                //     maxDate
                                                                                // }
                                                                                variant="mobile"
                                                                            />
                                                                            <MicroPaddingTop />
                                                                        </div>
                                                                    )
                                                                } else
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                index
                                                                            }
                                                                        />
                                                                    )
                                                            }
                                                        )}

                                                    <SmallPaddingTop />

                                                    <div
                                                        style={{
                                                            display: 'unset',
                                                        }}
                                                    >
                                                        <ButtonAtom
                                                            theme="naked-text"
                                                            fontSize={18}
                                                            onClick={() =>
                                                                addRemindersToBeEdited()
                                                            }
                                                            disabled={checkIfSavedDisabled()}
                                                            dataCyId="add_another_reminder_mobile"
                                                        >
                                                            Add another reminder
                                                        </ButtonAtom>
                                                    </div>
                                                </>
                                            ) : (
                                                <ButtonAtom
                                                    theme="capitalize-white-background"
                                                    width="100%"
                                                    onClick={() =>
                                                        addRemindersToBeEdited()
                                                    }
                                                    dataCyId="add_first_reminder_mobile"
                                                >
                                                    Create Reminder
                                                </ButtonAtom>
                                            )}
                                        </>
                                    )}
                                    {view_id === 'view' && (
                                        <>
                                            {currentReminders &&
                                                currentReminders.map(
                                                    (
                                                        item: IReminder,
                                                        index: number
                                                    ) => (
                                                        <React.Fragment
                                                            key={`view_${item.id}_${index}_mobile`}
                                                        >
                                                            <Expander
                                                                height={
                                                                    item &&
                                                                    this.state.existingRemindersBeingDeleted.includes(
                                                                        item.id
                                                                    )
                                                                        ? 0
                                                                        : 'auto'
                                                                }
                                                            >
                                                                <RemindersViewManager
                                                                    reminder={
                                                                        item
                                                                    }
                                                                    deleteReminder={(
                                                                        reminderUID: string
                                                                    ) =>
                                                                        handleDeletionOfExistingReminderBeforeSave(
                                                                            reminderUID
                                                                        )
                                                                    }
                                                                    taskDueDate={
                                                                        taskItem?.due
                                                                    }
                                                                />
                                                                <MicroPaddingTop />
                                                                {currentReminders &&
                                                                    index ===
                                                                        currentReminders?.length -
                                                                            1 && (
                                                                        <div
                                                                            style={{
                                                                                paddingBottom:
                                                                                    '16px',
                                                                            }}
                                                                        />
                                                                    )}
                                                            </Expander>
                                                        </React.Fragment>
                                                    )
                                                )}

                                            {taskRemindersBeingEditedIdsList &&
                                                taskRemindersBeingEdited &&
                                                taskRemindersBeingEditedIdsList.length >
                                                    0 && (
                                                    <>
                                                        {taskRemindersBeingEdited &&
                                                            taskRemindersBeingEditedIdsList.map(
                                                                (
                                                                    reminder_id: string,
                                                                    index: number
                                                                ) => {
                                                                    if (
                                                                        taskRemindersBeingEdited &&
                                                                        taskRemindersBeingEdited[
                                                                            reminder_id
                                                                        ]
                                                                    ) {
                                                                        return (
                                                                            <div
                                                                                key={`edit_${reminder_id}_${index}_mobile`}
                                                                                style={{
                                                                                    position:
                                                                                        'relative',
                                                                                    zIndex:
                                                                                        50 -
                                                                                        index,
                                                                                }}
                                                                            >
                                                                                <RemindersFieldsManager
                                                                                    handleChange={
                                                                                        handleChange
                                                                                    }
                                                                                    item={
                                                                                        taskRemindersBeingEdited[
                                                                                            reminder_id
                                                                                        ]
                                                                                    }
                                                                                    dueDate={
                                                                                        taskItem?.due
                                                                                            ? taskItem?.due
                                                                                            : moment()
                                                                                    }
                                                                                    minDate={
                                                                                        minDate
                                                                                    }
                                                                                    // maxDate={
                                                                                    //     maxDate
                                                                                    // }
                                                                                    variant="mobile"
                                                                                />
                                                                                <MicroPaddingTop />
                                                                            </div>
                                                                        )
                                                                    } else
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    index
                                                                                }
                                                                            />
                                                                        )
                                                                }
                                                            )}
                                                    </>
                                                )}
                                            <SmallPaddingTop />
                                            <div
                                                style={{
                                                    transform:
                                                        currentReminders !==
                                                            undefined &&
                                                        currentReminders.length >
                                                            0 &&
                                                        (taskRemindersBeingEdited ===
                                                            undefined ||
                                                            Object.keys(
                                                                taskRemindersBeingEdited
                                                            ).length < 1)
                                                            ? 'translateY(-20px)'
                                                            : 'translateY(0px)',
                                                    display: 'unset',
                                                }}
                                            >
                                                <ButtonAtom
                                                    theme="naked-text"
                                                    fontSize={18}
                                                    onClick={() =>
                                                        addRemindersToBeEdited()
                                                    }
                                                    disabled={checkIfSavedDisabled()}
                                                >
                                                    Add another reminder
                                                </ButtonAtom>
                                            </div>
                                        </>
                                    )}

                                    <MobileButtonsWrapper>
                                        <ButtonsStandardBottomRowMobile
                                            cancel={() =>
                                                isCarPage
                                                    ? this.props.history.push(
                                                          `/car/${carid}/tasks/task?taskid=${taskid}`
                                                      )
                                                    : this.props.history.push(
                                                          `/tasks/task?taskid=${taskid}`
                                                      )
                                            }
                                            submit={() => onSaveClick()}
                                            confirmationtext={'save'}
                                            disabled={checkIfSavedDisabled()}
                                            height="40px"
                                            dataCyIdUpdate="create_multiple_reminders_mobile"
                                            dataCyIdCancel="cancel_creation_of_multiple_reminders_mobile"
                                        />
                                    </MobileButtonsWrapper>
                                </MobileWrapper>
                            </IpadAndMobileDisplay>
                        </React.Fragment>
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchToProps)(TaskReminders)
)
