export type CountryCodeObj = {
    uid: string
    name: string
    code: string
}

const country_codes: CountryCodeObj[] = [
    {
        uid: 'GB',
        name: 'GB',
        code: '+44',
    },
    {
        uid: 'AF',
        name: 'AF',
        code: '+93',
    },
    {
        uid: 'AL',
        name: 'AL',
        code: '+355',
    },
    {
        uid: 'DZ',
        name: 'DZ',
        code: '+213',
    },
    {
        uid: 'AS',
        name: 'AS',
        code: '+1 684',
    },
    {
        uid: 'AD',
        name: 'AD',
        code: '+376',
    },
    {
        uid: 'AO',
        name: 'AO',
        code: '+244',
    },
    {
        uid: 'AI',
        name: 'AI',
        code: '+1 264',
    },
    {
        uid: 'AG',
        name: 'AG',
        code: '+1268',
    },
    {
        uid: 'AR',
        name: 'AR',
        code: '+54',
    },
    {
        uid: 'AM',
        name: 'AM',
        code: '+374',
    },
    {
        uid: 'AW',
        name: 'AW',
        code: '+297',
    },
    {
        uid: 'AU',
        name: 'AU',
        code: '+61',
    },
    {
        uid: 'AT',
        name: 'AT',
        code: '+43',
    },
    {
        uid: 'AZ',
        name: 'AZ',
        code: '+994',
    },
    {
        uid: 'BS',
        name: 'BS',
        code: '+1 242',
    },
    {
        uid: 'BH',
        name: 'BH',
        code: '+973',
    },
    {
        uid: 'BD',
        name: 'BD',
        code: '+880',
    },
    {
        uid: 'BB',
        name: 'BB',
        code: '+1 246',
    },
    {
        uid: 'BY',
        name: 'BY',
        code: '+375',
    },
    {
        uid: 'BE',
        name: 'BE',
        code: '+32',
    },
    {
        uid: 'BZ',
        name: 'BZ',
        code: '+501',
    },
    {
        uid: 'BJ',
        name: 'BJ',
        code: '+229',
    },
    {
        uid: 'BM',
        name: 'BM',
        code: '+1 441',
    },
    {
        uid: 'BT',
        name: 'BT',
        code: '+975',
    },
    {
        uid: 'BA',
        name: 'BA',
        code: '+387',
    },
    {
        uid: 'BW',
        name: 'BW',
        code: '+267',
    },
    {
        uid: 'BR',
        name: 'BR',
        code: '+55',
    },
    {
        uid: 'IO',
        name: 'IO',
        code: '+246',
    },
    {
        uid: 'BG',
        name: 'BG',
        code: '+359',
    },
    {
        uid: 'BF',
        name: 'BF',
        code: '+226',
    },
    {
        uid: 'BI',
        name: 'BI',
        code: '+257',
    },
    {
        uid: 'KH',
        name: 'KH',
        code: '+855',
    },
    {
        uid: 'CM',
        name: 'CM',
        code: '+237',
    },
    {
        uid: 'CA',
        name: 'CA',
        code: '+1',
    },
    {
        uid: 'CV',
        name: 'CV',
        code: '+238',
    },
    {
        uid: 'KY',
        name: 'KY',
        code: '+ 345',
    },
    {
        uid: 'CF',
        name: 'CF',
        code: '+236',
    },
    {
        uid: 'TD',
        name: 'TD',
        code: '+235',
    },
    {
        uid: 'CL',
        name: 'CL',
        code: '+56',
    },
    {
        uid: 'CN',
        name: 'CN',
        code: '+86',
    },
    {
        uid: 'CX',
        name: 'CX',
        code: '+61',
    },
    {
        uid: 'CO',
        name: 'CO',
        code: '+57',
    },
    {
        uid: 'KM',
        name: 'KM',
        code: '+269',
    },
    {
        uid: 'CG',
        name: 'CG',
        code: '+242',
    },
    {
        uid: 'CK',
        name: 'CK',
        code: '+682',
    },
    {
        uid: 'CR',
        name: 'CR',
        code: '+506',
    },
    {
        uid: 'HR',
        name: 'HR',
        code: '+385',
    },
    {
        uid: 'CU',
        name: 'CU',
        code: '+53',
    },
    {
        uid: 'CY',
        name: 'CY',
        code: '+537',
    },
    {
        uid: 'CZ',
        name: 'CZ',
        code: '+420',
    },
    {
        uid: 'DK',
        name: 'DK',
        code: '+45',
    },
    {
        uid: 'DJ',
        name: 'DJ',
        code: '+253',
    },
    {
        uid: 'DM',
        name: 'DM',
        code: '+1 767',
    },
    {
        uid: 'DO',
        name: 'DO',
        code: '+1 849',
    },
    {
        uid: 'EC',
        name: 'EC',
        code: '+593',
    },
    {
        uid: 'EG',
        name: 'EG',
        code: '+20',
    },
    {
        uid: 'SV',
        name: 'SV',
        code: '+503',
    },
    {
        uid: 'GQ',
        name: 'GQ',
        code: '+240',
    },
    {
        uid: 'ER',
        name: 'ER',
        code: '+291',
    },
    {
        uid: 'EE',
        name: 'EE',
        code: '+372',
    },
    {
        uid: 'ET',
        name: 'ET',
        code: '+251',
    },
    {
        uid: 'FO',
        name: 'FO',
        code: '+298',
    },
    {
        uid: 'FJ',
        name: 'FJ',
        code: '+679',
    },
    {
        uid: 'FI',
        name: 'FI',
        code: '+358',
    },
    {
        uid: 'FR',
        name: 'FR',
        code: '+33',
    },
    {
        uid: 'GF',
        name: 'GF',
        code: '+594',
    },
    {
        uid: 'PF',
        name: 'PF',
        code: '+689',
    },
    {
        uid: 'GA',
        name: 'GA',
        code: '+241',
    },
    {
        uid: 'GM',
        name: 'GM',
        code: '+220',
    },
    {
        uid: 'GE',
        name: 'GE',
        code: '+995',
    },
    {
        uid: 'DE',
        name: 'DE',
        code: '+49',
    },
    {
        uid: 'GH',
        name: 'GH',
        code: '+233',
    },
    {
        uid: 'GI',
        name: 'GI',
        code: '+350',
    },
    {
        uid: 'GR',
        name: 'GR',
        code: '+30',
    },
    {
        uid: 'GL',
        name: 'GL',
        code: '+299',
    },
    {
        uid: 'GD',
        name: 'GD',
        code: '+1 473',
    },
    {
        uid: 'GP',
        name: 'GP',
        code: '+590',
    },
    {
        uid: 'GU',
        name: 'GU',
        code: '+1 671',
    },
    {
        uid: 'GT',
        name: 'GT',
        code: '+502',
    },
    {
        uid: 'GN',
        name: 'GN',
        code: '+224',
    },
    {
        uid: 'GW',
        name: 'GW',
        code: '+245',
    },
    {
        uid: 'GY',
        name: 'GY',
        code: '+595',
    },
    {
        uid: 'HT',
        name: 'HT',
        code: '+509',
    },
    {
        uid: 'HN',
        name: 'HN',
        code: '+504',
    },
    {
        uid: 'HU',
        name: 'HU',
        code: '+36',
    },
    {
        uid: 'IS',
        name: 'IS',
        code: '+354',
    },
    {
        uid: 'IN',
        name: 'IN',
        code: '+91',
    },
    {
        uid: 'ID',
        name: 'ID',
        code: '+62',
    },
    {
        uid: 'IQ',
        name: 'IQ',
        code: '+964',
    },
    {
        uid: 'IE',
        name: 'IE',
        code: '+353',
    },
    {
        uid: 'IL',
        name: 'IL',
        code: '+972',
    },
    {
        uid: 'IT',
        name: 'IT',
        code: '+39',
    },
    {
        uid: 'JM',
        name: 'JM',
        code: '+1 876',
    },
    {
        uid: 'JP',
        name: 'JP',
        code: '+81',
    },
    {
        uid: 'JO',
        name: 'JO',
        code: '+962',
    },
    {
        uid: 'KZ',
        name: 'KZ',
        code: '+7 7',
    },
    {
        uid: 'KE',
        name: 'KE',
        code: '+254',
    },
    {
        uid: 'KI',
        name: 'KI',
        code: '+686',
    },
    {
        uid: 'KW',
        name: 'KW',
        code: '+965',
    },
    {
        uid: 'KG',
        name: 'KG',
        code: '+996',
    },
    {
        uid: 'LV',
        name: 'LV',
        code: '+371',
    },
    {
        uid: 'LB',
        name: 'LB',
        code: '+961',
    },
    {
        uid: 'LS',
        name: 'LS',
        code: '+266',
    },
    {
        uid: 'LR',
        name: 'LR',
        code: '+231',
    },
    {
        uid: 'LI',
        name: 'LI',
        code: '+423',
    },
    {
        uid: 'LT',
        name: 'LT',
        code: '+370',
    },
    {
        uid: 'LU',
        name: 'LU',
        code: '+352',
    },
    {
        uid: 'MG',
        name: 'MG',
        code: '+261',
    },
    {
        uid: 'MW',
        name: 'MW',
        code: '+265',
    },
    {
        uid: 'MY',
        name: 'MY',
        code: '+60',
    },
    {
        uid: 'MV',
        name: 'MV',
        code: '+960',
    },
    {
        uid: 'ML',
        name: 'ML',
        code: '+223',
    },
    {
        uid: 'MT',
        name: 'MT',
        code: '+356',
    },
    {
        uid: 'MH',
        name: 'MH',
        code: '+692',
    },
    {
        uid: 'MQ',
        name: 'MQ',
        code: '+596',
    },
    {
        uid: 'MR',
        name: 'MR',
        code: '+222',
    },
    {
        uid: 'MU',
        name: 'MU',
        code: '+230',
    },
    {
        uid: 'YT',
        name: 'YT',
        code: '+262',
    },
    {
        uid: 'MX',
        name: 'MX',
        code: '+52',
    },
    {
        uid: 'MC',
        name: 'MC',
        code: '+377',
    },
    {
        uid: 'MN',
        name: 'MN',
        code: '+976',
    },
    {
        uid: 'ME',
        name: 'ME',
        code: '+382',
    },
    {
        uid: 'MS',
        name: 'MS',
        code: '+1664',
    },
    {
        uid: 'MA',
        name: 'MA',
        code: '+212',
    },
    {
        uid: 'MM',
        name: 'MM',
        code: '+95',
    },
    {
        uid: 'NA',
        name: 'NA',
        code: '+264',
    },
    {
        uid: 'NR',
        name: 'NR',
        code: '+674',
    },
    {
        uid: 'NP',
        name: 'NP',
        code: '+977',
    },
    {
        uid: 'NL',
        name: 'NL',
        code: '+31',
    },
    {
        uid: 'AN',
        name: 'AN',
        code: '+599',
    },
    {
        uid: 'NC',
        name: 'NC',
        code: '+687',
    },
    {
        uid: 'NZ',
        name: 'NZ',
        code: '+64',
    },
    {
        uid: 'NI',
        name: 'NI',
        code: '+505',
    },
    {
        uid: 'NE',
        name: 'NE',
        code: '+227',
    },
    {
        uid: 'NG',
        name: 'NG',
        code: '+234',
    },
    {
        uid: 'NU',
        name: 'NU',
        code: '+683',
    },
    {
        uid: 'NF',
        name: 'NF',
        code: '+672',
    },
    {
        uid: 'MP',
        name: 'MP',
        code: '+1 670',
    },
    {
        uid: 'NO',
        name: 'NO',
        code: '+47',
    },
    {
        uid: 'OM',
        name: 'OM',
        code: '+968',
    },
    {
        uid: 'PK',
        name: 'PK',
        code: '+92',
    },
    {
        uid: 'PW',
        name: 'PW',
        code: '+680',
    },
    {
        uid: 'PA',
        name: 'PA',
        code: '+507',
    },
    {
        uid: 'PG',
        name: 'PG',
        code: '+675',
    },
    {
        uid: 'PY',
        name: 'PY',
        code: '+595',
    },
    {
        uid: 'PE',
        name: 'PE',
        code: '+51',
    },
    {
        uid: 'PH',
        name: 'PH',
        code: '+63',
    },
    {
        uid: 'PL',
        name: 'PL',
        code: '+48',
    },
    {
        uid: 'PT',
        name: 'PT',
        code: '+351',
    },
    {
        uid: 'PR',
        name: 'PR',
        code: '+1 939',
    },
    {
        uid: 'QA',
        name: 'QA',
        code: '+974',
    },
    {
        uid: 'RO',
        name: 'RO',
        code: '+40',
    },
    {
        uid: 'RW',
        name: 'RW',
        code: '+250',
    },
    {
        uid: 'WS',
        name: 'WS',
        code: '+685',
    },
    {
        uid: 'SM',
        name: 'SM',
        code: '+378',
    },
    {
        uid: 'SA',
        name: 'SA',
        code: '+966',
    },
    {
        uid: 'SN',
        name: 'SN',
        code: '+221',
    },
    {
        uid: 'RS',
        name: 'RS',
        code: '+381',
    },
    {
        uid: 'SC',
        name: 'SC',
        code: '+248',
    },
    {
        uid: 'SL',
        name: 'SL',
        code: '+232',
    },
    {
        uid: 'SG',
        name: 'SG',
        code: '+65',
    },
    {
        uid: 'SK',
        name: 'SK',
        code: '+421',
    },
    {
        uid: 'SI',
        name: 'SI',
        code: '+386',
    },
    {
        uid: 'SB',
        name: 'SB',
        code: '+677',
    },
    {
        uid: 'ZA',
        name: 'ZA',
        code: '+27',
    },
    {
        uid: 'GS',
        name: 'GS',
        code: '+500',
    },
    {
        uid: 'ES',
        name: 'ES',
        code: '+34',
    },
    {
        uid: 'LK',
        name: 'LK',
        code: '+94',
    },
    {
        uid: 'SD',
        name: 'SD',
        code: '+249',
    },
    {
        uid: 'SR',
        name: 'SR',
        code: '+597',
    },
    {
        uid: 'SZ',
        name: 'SZ',
        code: '+268',
    },
    {
        uid: 'SE',
        name: 'SE',
        code: '+46',
    },
    {
        uid: 'CH',
        name: 'CH',
        code: '+41',
    },
    {
        uid: 'TJ',
        name: 'TJ',
        code: '+992',
    },
    {
        uid: 'TH',
        name: 'TH',
        code: '+66',
    },
    {
        uid: 'TG',
        name: 'TG',
        code: '+228',
    },
    {
        uid: 'TK',
        name: 'TK',
        code: '+690',
    },
    {
        uid: 'TO',
        name: 'TO',
        code: '+676',
    },
    {
        uid: 'TT',
        name: 'TT',
        code: '+1 868',
    },
    {
        uid: 'TN',
        name: 'TN',
        code: '+216',
    },
    {
        uid: 'TR',
        name: 'TR',
        code: '+90',
    },
    {
        uid: 'TM',
        name: 'TM',
        code: '+993',
    },
    {
        uid: 'TC',
        name: 'TC',
        code: '+1 649',
    },
    {
        uid: 'TV',
        name: 'TV',
        code: '+688',
    },
    {
        uid: 'UG',
        name: 'UG',
        code: '+256',
    },
    {
        uid: 'UA',
        name: 'UA',
        code: '+380',
    },
    {
        uid: 'AE',
        name: 'AE',
        code: '+971',
    },
    {
        uid: 'US',
        name: 'US',
        code: '+1',
    },
    {
        uid: 'UY',
        name: 'UY',
        code: '+598',
    },
    {
        uid: 'UZ',
        name: 'UZ',
        code: '+998',
    },
    {
        uid: 'VU',
        name: 'VU',
        code: '+678',
    },
    {
        uid: 'WF',
        name: 'WF',
        code: '+681',
    },
    {
        uid: 'YE',
        name: 'YE',
        code: '+967',
    },
    {
        uid: 'ZM',
        name: 'ZM',
        code: '+260',
    },
    {
        uid: 'ZW',
        name: 'ZW',
        code: '+263',
    },
    {
        uid: 'AX',
        name: 'AX',
        code: '',
    },
    {
        uid: 'AQ',
        name: 'AQ',
        code: '',
    },
    {
        uid: 'BO',
        name: 'BO',
        code: '+591',
    },
    {
        uid: 'BN',
        name: 'BN',
        code: '+673',
    },
    {
        uid: 'CC',
        name: 'CC',
        code: '+61',
    },
    {
        uid: 'CD',
        name: 'CD',
        code: '+243',
    },
    {
        uid: 'CI',
        name: 'CI',
        code: '+225',
    },
    {
        uid: 'FK',
        name: 'FK',
        code: '+500',
    },
    {
        uid: 'GG',
        name: 'GG',
        code: '+44',
    },
    {
        uid: 'VA',
        name: 'VA',
        code: '+379',
    },
    {
        uid: 'HK',
        name: 'HK',
        code: '+852',
    },
    {
        uid: 'IR',
        name: 'IR',
        code: '+98',
    },
    {
        uid: 'IM',
        name: 'IM',
        code: '+44',
    },
    {
        uid: 'JE',
        name: 'JE',
        code: '+44',
    },
    {
        uid: 'KP',
        name: 'KP',
        code: '+850',
    },
    {
        uid: 'KR',
        name: 'KR',
        code: '+82',
    },
    {
        uid: 'LA',
        name: 'LA',
        code: '+856',
    },
    {
        uid: 'LY',
        name: 'LY',
        code: '+218',
    },
    {
        uid: 'MO',
        name: 'MO',
        code: '+853',
    },
    {
        uid: 'MK',
        name: 'MK',
        code: '+389',
    },
    {
        uid: 'FM',
        name: 'FM',
        code: '+691',
    },
    {
        uid: 'MD',
        name: 'MD',
        code: '+373',
    },
    {
        uid: 'MZ',
        name: 'MZ',
        code: '+258',
    },
    {
        uid: 'PS',
        name: 'PS',
        code: '+970',
    },
    {
        uid: 'PN',
        name: 'PN',
        code: '+872',
    },
    {
        uid: 'RE',
        name: 'RE',
        code: '+262',
    },
    {
        uid: 'RU',
        name: 'RU',
        code: '+7',
    },
    {
        uid: 'BL',
        name: 'BL',
        code: '+590',
    },
    {
        uid: 'SH',
        name: 'SH',
        code: '+290',
    },
    {
        uid: 'KN',
        name: 'KN',
        code: '+1 869',
    },
    {
        uid: 'LC',
        name: 'LC',
        code: '+1 758',
    },
    {
        uid: 'MF',
        name: 'MF',
        code: '+590',
    },
    {
        uid: 'PM',
        name: 'PM',
        code: '+508',
    },
    {
        uid: 'VC',
        name: 'VC',
        code: '+1 784',
    },
    {
        uid: 'ST',
        name: 'ST',
        code: '+239',
    },
    {
        uid: 'SO',
        name: 'SO',
        code: '+252',
    },
    {
        uid: 'SJ',
        name: 'SJ',
        code: '+47',
    },
    {
        uid: 'SY',
        name: 'SY',
        code: '+963',
    },
    {
        uid: 'TW',
        name: 'TW',
        code: '+886',
    },
    {
        uid: 'TZ',
        name: 'TZ',
        code: '+255',
    },
    {
        uid: 'TL',
        name: 'TL',
        code: '+670',
    },
    {
        uid: 'VE',
        name: 'VE',
        code: '+58',
    },
    {
        uid: 'VN',
        name: 'VN',
        code: '+84',
    },
    {
        uid: 'VG',
        name: 'VG',
        code: '+1 284',
    },
    {
        uid: 'VI',
        name: 'VI',
        code: '+1 340',
    },
]

export default country_codes
