import { apex_homepage } from './data'

import { ISubNavigationState } from 'TopSubnavigationData'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ISubNavigationState = {
    apex_homepage: apex_homepage,
    currentID: '',
}

export const subnavigation_state = createSlice({
    name: 'subnavigation_state',
    initialState: initialState,
    reducers: {
        setCurrentVisitedSectionID: (
            state: ISubNavigationState,
            action: PayloadAction<string>
        ) => {
            state.currentID = action.payload
        },
    },
})

export const subNavigationStateActions = subnavigation_state.actions

export default subnavigation_state.reducer
