import { ITasksDisplayCriteria, IDropdownItem, ICar } from 'entityModels'
import * as React from 'react'
import styled from 'styled-components'
import { OOtherTaskOptions, OTaskFilterNSort } from '../../../ol/ol'
import SelectSearchBarCarInfoDesktop from '../editForms/technicalInformationEditForm/searchselect/desktop/selectSearchBarCarInfoDesktop'
import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import { AddIcon } from '../../atoms/statefulicons'
import ActionsMenuDots from '../../atoms/menu/actionsMenu/actionsMenuDots'
import { useState } from 'react'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'
import SortIcon from '../../atoms/icons/components/sortIcon'
import { useClickAway } from '../../../helpers/hooks/domHooks'
import FilterIcon from '../../atoms/icons/components/filterIcon'

const TasksMenuRow = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
`

interface Props {
    dropdownCars?: IDropdownItem[]
    selectCarViewId: (name: string) => void
    toggleCreateTaskForm: () => void
    sortView: (id: string) => void
    filterView: (text: string) => void
    selectedCarData: ICar | undefined
    initialValues: ITasksDisplayCriteria
    isCarTasksPage?: boolean
}

const TaskViewsManagerDesktop: React.FC<Props> = (props) => {
    let {
        dropdownCars,
        selectCarViewId,
        toggleCreateTaskForm,
        sortView,
        filterView,
        selectedCarData,
        initialValues,
        // isCarTasksPage,
    } = props

    let item_car_default = () => {
        if (selectedCarData) {
            return {
                id: selectedCarData.id
                    ? selectedCarData.id
                    : OOtherTaskOptions.general_task.uid,
                answer: selectedCarData.title
                    ? selectedCarData.title
                    : OOtherTaskOptions.all_tasks.name,
                text: selectedCarData.title
                    ? selectedCarData.title
                    : OOtherTaskOptions.all_tasks.name,
            }
        } else {
            return {
                id: OOtherTaskOptions.general_task.uid,
                answer: OOtherTaskOptions.all_tasks.name,
                text: OOtherTaskOptions.all_tasks.name,
            }
        }
    }

    const [menu_open, set_menu_open] = useState<undefined | 'sort' | 'filter'>(
        undefined
    )

    const { theme } = useThemes()

    const manageMenus = (id?: undefined | 'sort' | 'filter') => {
        set_menu_open(id ? id : undefined)
    }

    const dropdown_ref_1: any = useClickAway(() => {
        menu_open === 'sort' && manageMenus()
    })
    const dropdown_ref_2: any = useClickAway(() => {
        menu_open === 'filter' && manageMenus()
    })

    return (
        <TasksMenuRow
            style={
                { width: '100%', justifyContent: 'space-between' }
                // isCarTasksPage
                //     ? { width: '100%', justifyContent: 'space-between' }
                //     : {}
            }
        >
            <SelectSearchBarCarInfoDesktop
                height="80px"
                width="280px"
                handleSelectChange={(name: string) => selectCarViewId(name)}
                item={item_car_default()}
                items={dropdownCars}
                borderColor={colours[theme].border_muted}
            />
            <TasksMenuRow>
                <div ref={dropdown_ref_1}>
                    <ActionsMenuDots
                        direction="bottom"
                        customButton={
                            <StandardCtaBtn
                                customWidth="48px"
                                removePaddings
                                isSecondary
                                bg={'transparent'}
                                customHoverColor={
                                    colours[theme].background_neutral_subtle
                                }
                                borderOnHover={`2px solid ${colours[theme].border_muted}`}
                                boder={`1px solid ${colours[theme].border_muted}`}
                                onClick={() => manageMenus('sort')}
                                removeShadowOnHover
                            >
                                <SortIcon />
                            </StandardCtaBtn>
                        }
                        active_item={{ text: initialValues.sortBy }}
                        menuOpened={menu_open as string}
                        menuId={'sort'}
                        actions_items={[
                            {
                                text: OTaskFilterNSort.sort_by.created_at.name,
                                actionOnClick: (id: string) => {
                                    sortView(id)
                                    manageMenus()
                                },
                            },

                            {
                                text: OTaskFilterNSort.sort_by.due.name,
                                actionOnClick: (id: string) => {
                                    sortView(id)
                                    manageMenus()
                                },
                            },
                        ]}
                    />
                </div>
                <div ref={dropdown_ref_2}>
                    <ActionsMenuDots
                        direction="bottom"
                        customButton={
                            <StandardCtaBtn
                                customWidth="48px"
                                removePaddings
                                isSecondary
                                bg={'transparent'}
                                customHoverColor={
                                    colours[theme].background_neutral_subtle
                                }
                                borderOnHover={`2px solid ${colours[theme].border_muted}`}
                                boder={`1px solid ${colours[theme].border_muted}`}
                                onClick={() => manageMenus('filter')}
                                removeShadowOnHover
                            >
                                <FilterIcon />
                            </StandardCtaBtn>
                        }
                        menuOpened={menu_open as string}
                        menuId={'filter'}
                        active_item={{ text: initialValues.filteredBy }}
                        actions_items={[
                            {
                                text: OTaskFilterNSort.filter_by.outstanding
                                    .name,
                                actionOnClick: (id: string) => {
                                    filterView(id)
                                    manageMenus()
                                },
                            },
                            {
                                text: OTaskFilterNSort.filter_by.completed.name,
                                actionOnClick: (id: string) => {
                                    filterView(id)
                                    manageMenus()
                                },
                            },
                            {
                                text: OTaskFilterNSort.filter_by.all.name,
                                actionOnClick: (id: string) => {
                                    filterView(id)
                                    manageMenus()
                                },
                            },
                        ]}
                    />
                </div>

                <StandardCtaBtn
                    onClick={() => toggleCreateTaskForm()}
                    customWidth="48px"
                    removePaddings
                >
                    <AddIcon
                        onClick={() => {}}
                        colour={colours[theme].background_default}
                    />
                </StandardCtaBtn>
            </TasksMenuRow>
        </TasksMenuRow>
    )
}

export default TaskViewsManagerDesktop
