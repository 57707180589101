export const GET_TASKS_BY_GARAGE_ID_SUCCESS = '@@tasks_garageid/get/SUCCESS'
export const GET_TASKS_BY_GARAGE_ID_REQUEST = '@@tasks_garageid/get/REQUEST'
export const GET_TASKS_BY_GARAGE_ID_ERROR = '@@tasks_garageid/get/ERROR'

export const GET_TASKS_BY_CAR_ID_SUCCESS = '@@tasks_carid/get/SUCCESS'
export const GET_TASKS_BY_CAR_ID_REQUEST = '@@tasks_carid/get/REQUEST'
export const GET_TASKS_BY_CAR_ID_ERROR = '@@tasks_carid/get/ERROR'

export const GET_TASK_BY_ID_SUCCESS = '@@task_id/get/SUCCESS'
export const GET_TASK_BY_ID_REQUEST = '@@task_id/get/REQUEST'
export const GET_TASK_BY_ID_ERROR = '@@task_id/get/ERROR'

export const UPDATE_TASK_SUCCESS = '@@task/update/SUCCESS'
export const UPDATE_TASK_REQUEST = '@@task/update/REQUEST'
export const UPDATE_TASK_ERROR = '@@task/update/ERROR'

export const CREATE_TASK_SUCCESS = '@@task/create/SUCCESS'
export const CREATE_TASK_REQUEST = '@@task/create/REQUEST'
export const CREATE_TASK_ERROR = '@@task/create/ERROR'

export const LINK_TASK_SUCCESS = '@@task/link/SUCCESS'
export const LINK_TASK_REQUEST = '@@task/link/REQUEST'
export const LINK_TASK_ERROR = '@@task/link/ERROR'

export const UNLINK_TASK_SUCCESS = '@@task/unlink/SUCCESS'
export const UNLINK_TASK_REQUEST = '@@task/unlink/REQUEST'
export const UNLINK_TASK_ERROR = '@@task/unlink/ERROR'

export const DELETE_TASK_SUCCESS = '@@task/delete/SUCCESS'
export const DELETE_TASK_REQUEST = '@@task/delete/REQUEST'
export const DELETE_TASK_ERROR = '@@task/delete/ERROR'

export const REMOVE_STORE_TASKS_SUCCESS = '@@task/removeStore/SUCCESS'
export const REGISTER_ALL_TASKS_DISPLAY_CONDITIONS_SUCCESS =
    '@@tasks/registerTasksDisplayCriteria/SUCCESS'

export const GET_TASK_REMINDERS_SUCCESS = '@@task_reminders/get/SUCCESS'
export const GET_TASK_REMINDERS_REQUEST = '@@task_reminders/get/REQUEST'
export const GET_TASK_REMINDERS_ERROR = '@@task_reminders/get/ERROR'

export const SET_TASK_REMINDERS_EDITED_SUCCESS = '@@task_reminders/set/SUCCESS'
export const SET_TASK_REMINDERS_EDITED_REQUEST = '@@task_reminders/set/REQUEST'

export const ADD_REMINDER_TO_TASK_SUCCESS = '@@task_reminders/create/SUCCESS'
export const ADD_REMINDER_TO_TASK_REQUEST = '@@task_reminders/create/REQUEST'
export const ADD_REMINDER_TO_TASK_ERROR = '@@task_reminders/create/ERROR'

export const DELETE_REMINDER_TASK_SUCCESS = '@@task_reminders/delete/SUCCESS'
export const DELETE_REMINDER_TASK_REQUEST = '@@task_reminders/delete/REQUEST'
export const DELETE_REMINDER_TASK_ERROR = '@@task_reminders/delete/ERROR'

export const CLEAN_UP_TASKS_SUCCESS = '@@tasks/clean_up/SUCCESS'
