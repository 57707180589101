import * as userData from './user_data'
import * as userAddress from './user_data/userAddress'
import * as authentication from './authentication/authentication'
import * as proposalCodes from './proposal_codes/proposalsapi'
import * as sendInvitation from './invitation'
import * as authCheck from './authcheck/index'
import * as dropdownsData from './dropdowns'
import * as entitiesData from './entities'
import * as fileStorage from './filestorage'
import * as externalCarDataByRegistrationNumber from './databyregistrationnumber'
import * as tasks from './tasks'
import * as apexQuery from './theapex'
import * as timeline from './timeline'
import * as sharing from './sharing'
import * as accountDeletion from './user_data/accountDeletion'
import * as showroom from './showroom'
import * as external_urls from './externalUrls'
import * as archive from './archive'
import * as handover from './handover'
import * as insuranceApplication from './insuranceApplication'
import * as carInsights from './carInsights'

export const api = {
    sharing: sharing,
    authentication: authentication,
    userData: userData,
    userAddress: userAddress,
    accountDeletion: accountDeletion,
    proposalCodes: proposalCodes,
    sendInvitation: sendInvitation,
    authCheck: authCheck,
    entities: entitiesData,
    dropdownsData: dropdownsData,
    fileStorage: fileStorage,
    externalCarDataByRegistrationNumber: externalCarDataByRegistrationNumber,
    tasks: tasks,
    apexQuery: apexQuery,
    timeline: timeline,
    showroom: showroom,
    external_urls: external_urls,
    archive: archive,
    handover: handover,
    insuranceApplication: insuranceApplication,
    carInsights: carInsights,
}
