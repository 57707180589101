import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import Faded from '../../../templates/animated/faded'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import ImageCropperMobile2 from '../../imageCropper/imageCropperMobile2'
import { motion } from 'framer-motion'
import { Button, message, Upload } from 'antd'

// import './antd.css'
import { IUploadImagesPreSignedUrlsPayload } from 'IapiDataPayload'
import { getPresignedUrlUserProfileImageRequest } from '../../../../redux/filestorage/actions/dispatched/loadingActions'
import { updateUserGeneralInfoRequest } from '../../../../redux/user/actions/loadingActions'

import Expander from '../../../atoms/expander/expander'
import { IUSerGeneralInfoUpdatePayload } from 'myModels'
import useIsIosPwaCheck from '../../../templates/pwaCustom/isIosPwaCheck'
import { ModalTopBar } from '../../../molecules/technicalInformation/modal'
import useThemes from '../../../../providers/theme/hooks'

const EditFormContainerMobile = styled.section`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 90vw;
    margin-top: 30px;
    margin-bottom: 30px;
    height: auto;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: black;
    border-radius: 5px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 5px;
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

const InnerFormPadding = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 32px;
    @media ${device.ipad} {
        padding-left: 30px;
        padding-right: 30px;
    }
`

// const FormTitle = styled.div`
//     font-family: Lato;
//     width: 100%;
//     text-align: left;
//     align-self: start;
//     font-size: 18px;

//     @media ${device.large_desktop} {
//         font-size: 20px;
//     }
// `

const FieldTitle = styled.div`
    font-size: 14px;
    font-family: 'Lato-Light';
    color: var(--text-strong, #1a1a1a);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-bottom: 15px;
    padding-top: 24px;
`

const SmallPadding = styled.div`
    padding-top: 24px;
`

const LargePadding = styled.div`
    padding-top: 32px;
`

// const ImgBtnRowWrapper = styled.div`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
//     padding-top: 30px;
// `

const ImgBtnColWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    width: 100%;
`

const ImgBtnSideRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const NameOfFileUploaded = styled.div`
    color: var(--text-darker, #616161);
    font-family: georgia;
    font-size: 14px;
    padding-right: 20px;
    padding-left: 0px;
    max-width: 50vw;
    overflow: hidden;
    padding-bottom: 10px;
    transform: translateY(10px);
`

const dispatchProps = {
    getPresignedUrlUserProfileImageRequest: (fileName: string) =>
        getPresignedUrlUserProfileImageRequest(fileName),
    // UPDATE
    updateUserGeneralInfoRequest: (data: IUSerGeneralInfoUpdatePayload) =>
        updateUserGeneralInfoRequest(data),
}

function mapStateToProps(state: RootState) {
    return {
        presignedurls: state.fileStorage.presignedurls,
    }
}

interface Values {
    file: File | null
}

interface OtherProps {
    close: () => void
    deleteImageUploaded: () => void
    setImageUploaded: (file: File) => void
    currentImagesUploaded: File | undefined
    toggleShowImageCropper: (res?: boolean | undefined) => void
    isImageCropperShown?: boolean
    user_id: string
    presignedurls: IUploadImagesPreSignedUrlsPayload
    progressPercent?: number | null
    isSomethingBeingUploaded?: boolean
    getPresignedUrlUserProfileImageRequest: (fileName: string) => void
    updateUserGeneralInfoRequest: (data: IUSerGeneralInfoUpdatePayload) => void
}

const CustomButton = styled(Button)`
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    padding-right: 20px;
    padding-left: 20px;

    transition: all 0.1s;
    :focus {
        outline: 0;
    }

    box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.03);
    transition: all 200ms;
    :hover {
        box-shadow: 2px 4px 7px rgba(0, 0, 0, 0.09);
        transition: all 200ms;
    }

    text-transform: lowercase;
    background-color: transparent !important;

    width: 200px !important;
    height: 56px !important;
    border-radius: 5px !important;
    color: var(--primary, #5ec3ca) !important;
    border: 1px solid var(--primary, #5ec3ca) !important;
    font-family: Lato !important;
    font-size: 13px !important;

    @media ${device.mobile} {
    }

    display: flex;
    text-align: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const ProgressBarImagesMobileParentContainer = styled.div`
    border: 1px var(--primary, #5ec3ca) solid;
    display: flex;
    position: relative;
    height: 4px;
    width: 43vw;
    border-radius: 5px;
`

const FileRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 79vw;
`

const FileCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
    padding-left: 10px;
    padding-right: 10px;
`

type ProgressProps = {
    progressPercent: number
}

const ProgressBarImagesMobileChild = styled.div<ProgressProps>`
    display: flex;
    position: relative;
    height: 100%;
    width: ${(props) => `${props.progressPercent}%`};
    background-color: var(--primary, #5ec3ca);
    border-radius: 5px;
`

const Completed = styled.div`
    color: var(--text-darker, #616161);
    font-size: 14px;
    font-family: Lato;
    padding-left: 20px;
    transform: translateY(-5px);
`

const CompletionRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        close,
        setImageUploaded,
        currentImagesUploaded,
        deleteImageUploaded,
        toggleShowImageCropper,
        isImageCropperShown,
        setFieldValue,
        getPresignedUrlUserProfileImageRequest,
        progressPercent,
    } = props

    const dummyRequest = ({ file, onSuccess }: any) => {
        setTimeout(() => {
            onSuccess('ok')
        }, 0)
    }

    const handleCroppedFile = (file: File | undefined) => {
        if (file) {
            getPresignedUrlUserProfileImageRequest(file.name)
            setFieldValue('file', file)
            let fake_url = URL.createObjectURL(file)
            setFieldValue('file_url', fake_url)
            setImageUploaded(file)
        }

        return
    }

    let isApple: any = useIsIosPwaCheck()
    let isIos: boolean = isApple.isIos || isApple.isSafari

    const errorFileSizeWarning = () => {
        message.error({
            content: isIos
                ? 'The maximum file size allowed per image profile upload on iOS device is 5MB.'
                : 'The maximum file size allowed per upload is 20MB.',
            duration: 2,
        })
    }

    const { theme } = useThemes()

    return (
        <>
            {isImageCropperShown ? (
                <ImageCropperMobile2
                    currentImagesUploaded={currentImagesUploaded}
                    close={() => toggleShowImageCropper(false)}
                    handleFile={handleCroppedFile}
                    aspect={1 / 1}
                />
            ) : (
                <EditFormContainerMobile>
                    <ModalTopBar $theme={theme}>
                        Update Profile Picture
                    </ModalTopBar>
                    <InnerFormPadding>
                        <FieldTitle>
                            <div>
                                <strong
                                    style={{
                                        color: 'var(--text-darker, #616161)',
                                    }}
                                >
                                    Image formats accepted:
                                </strong>{' '}
                                png, jpg, jpeg, HEIC and HEIF
                            </div>
                            <div>
                                {isIos ? (
                                    <div>
                                        <strong
                                            style={{
                                                color: 'var(--text-darker, #616161)',
                                            }}
                                        >
                                            Maximum file sizes:
                                        </strong>{' '}
                                        iOS(5MB) Desktop (20MB)
                                    </div>
                                ) : (
                                    <div>
                                        <strong
                                            style={{
                                                color: 'var(--text-darker, #616161)',
                                            }}
                                        >
                                            Maximum file size
                                        </strong>
                                        : 20MB
                                    </div>
                                )}
                            </div>
                        </FieldTitle>

                        <SmallPadding />

                        <Expander height={currentImagesUploaded ? 0 : 'auto'}>
                            <motion.div whileTap={{ scale: 1.05 }}>
                                <Upload
                                    multiple={false}
                                    showUploadList={false}
                                    onChange={(info: any) => {
                                        if (
                                            info.file &&
                                            (info.file.type === 'image/jpeg' ||
                                                info.file.type ===
                                                    'image/png' ||
                                                info.file.type ===
                                                    'image/heic' ||
                                                info.file.type === 'image/heif')
                                        ) {
                                            if (isIos) {
                                                if (
                                                    info.file.size <= 5000000 &&
                                                    info.file.size > 1
                                                ) {
                                                    getPresignedUrlUserProfileImageRequest(
                                                        info.file.name
                                                    )

                                                    let fileimg =
                                                        info.file.originFileObj

                                                    setFieldValue(
                                                        'file',
                                                        fileimg
                                                    )
                                                    let fake_url =
                                                        URL.createObjectURL(
                                                            fileimg
                                                        )
                                                    setFieldValue(
                                                        'file_url',
                                                        fake_url
                                                    )

                                                    setImageUploaded(fileimg)

                                                    toggleShowImageCropper(true)
                                                } else errorFileSizeWarning()
                                            } else if (
                                                info.file.size <= 20000000 &&
                                                info.file.size > 1
                                            ) {
                                                getPresignedUrlUserProfileImageRequest(
                                                    info.file.name
                                                )

                                                let fileimg =
                                                    info.file.originFileObj

                                                setFieldValue('file', fileimg)
                                                let fake_url =
                                                    URL.createObjectURL(fileimg)
                                                setFieldValue(
                                                    'file_url',
                                                    fake_url
                                                )

                                                setImageUploaded(fileimg)

                                                toggleShowImageCropper(true)
                                            } else errorFileSizeWarning()
                                        }
                                    }}
                                    customRequest={dummyRequest}
                                >
                                    <CustomButton>upload image</CustomButton>
                                </Upload>
                            </motion.div>
                        </Expander>

                        <Expander height={currentImagesUploaded ? 'auto' : 0}>
                            <FileRow>
                                <FileCol>
                                    <NameOfFileUploaded>
                                        {currentImagesUploaded &&
                                            // currentImagesUploaded.new === true &&
                                            currentImagesUploaded.name !==
                                                undefined &&
                                            currentImagesUploaded.name}
                                    </NameOfFileUploaded>

                                    {currentImagesUploaded &&
                                        progressPercent && (
                                            <CompletionRow>
                                                <ProgressBarImagesMobileParentContainer>
                                                    <ProgressBarImagesMobileChild
                                                        progressPercent={
                                                            progressPercent
                                                                ? progressPercent
                                                                : 0
                                                        }
                                                    />
                                                </ProgressBarImagesMobileParentContainer>

                                                {progressPercent === 100 && (
                                                    <Completed>100%</Completed>
                                                )}
                                            </CompletionRow>
                                        )}
                                </FileCol>

                                {currentImagesUploaded && (
                                    <Faded>
                                        <ImgBtnSideRow>
                                            <ButtonAtom
                                                onClick={(e: any) => {
                                                    e.preventDefault()
                                                    // fake function

                                                    deleteImageUploaded()
                                                }}
                                                theme="bin_img_circle"
                                                height="34px"
                                                width="34px"
                                            >
                                                upload image
                                            </ButtonAtom>
                                        </ImgBtnSideRow>
                                    </Faded>
                                )}
                            </FileRow>
                        </Expander>

                        <div style={{ paddingTop: '20px' }} />

                        <ImgBtnColWrapper>
                            {
                                // currentImagesUploaded &&
                                //     currentImagesUploaded.new !== true &&
                                //     currentImagesUploaded.fileTitle !==
                                //         undefined && (
                                //         <ImgBtnRowWrapper>
                                //             <NameOfFileUploaded>
                                //                 {currentImagesUploaded.fileTitle}
                                //             </NameOfFileUploaded>
                                //             <Faded>
                                //                 <ImgBtnSideRow>
                                //                     {progressPercent === 100 && (
                                //                         <Completed>100%</Completed>
                                //                     )}
                                //                     {/* <ButtonAtom
                                //                         onClick={(e: any) => {
                                //                             e.preventDefault()
                                //                             // fake function
                                //                             toggleShowImageCropper()
                                //                         }}
                                //                         theme="img-actions"
                                //                         action="crop_img"
                                //                         height="36px"
                                //                         width="36px"
                                //                     />
                                //                     <div
                                //                         style={{
                                //                             paddingLeft: '10px',
                                //                         }}
                                //                     /> */}
                                //                     <ButtonAtom
                                //                         onClick={(e: any) => {
                                //                             e.preventDefault()
                                //                             // fake function
                                //                             deleteImageUploaded()
                                //                         }}
                                //                         theme="img-actions"
                                //                         action="delete_img"
                                //                         height="36px"
                                //                         width="36px"
                                //                     >
                                //                         upload image
                                //                     </ButtonAtom>
                                //                 </ImgBtnSideRow>
                                //             </Faded>
                                //         </ImgBtnRowWrapper>
                                //     )
                            }
                        </ImgBtnColWrapper>

                        <LargePadding />
                        <ButtonsStandardBottomRowMobile
                            cancel={close}
                            submit={props.handleSubmit}
                            confirmationtext={'save'}
                            disabled={progressPercent === 100 ? false : true}
                        />
                    </InnerFormPadding>
                </EditFormContainerMobile>
            )}
        </>
    )
}

const AddUserProfileImageFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        file: null,
    }),

    // validationSchema: {},

    handleSubmit: (values, { props, setSubmitting }) => {
        let file = values.file

        if (
            file !== undefined &&
            props.presignedurls &&
            props.presignedurls[0]
        ) {
            let presignedurl = props.presignedurls[0]
            let dataToSend: IUSerGeneralInfoUpdatePayload = {
                id: props.user_id,
                data: {
                    profile_filename: presignedurl.filename,
                },
            }
            props.updateUserGeneralInfoRequest(dataToSend)
            // here req thunks to upload to S3

            props.close()
        }

        setSubmitting(false)
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(AddUserProfileImageFormMobile)
