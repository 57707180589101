import React from 'react'
import { connect } from 'react-redux'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import Loader from '../../atoms/loader/loader'
import { IUser } from 'myModels'
import {
    getCurrentUserDataRequest,
    logoutRequest,
} from '../../../redux/user/actions/loadingActions'
import styled from 'styled-components'
import left_align_logo from '../../../public/assets/landingPage/left_align_logo.svg'
import ErrorCardDesktop from '../../molecules/errorCards/errorCardDesktop'
import { device } from '../../templates/displays/devices'
import ErrorCardMobile from '../../molecules/errorCards/errorCardMobile'

const LeftAlignLogoWrapper = styled.div`
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -20px;

    @media ${device.smallest_laptops} {
        height: 48px;
    }

    @media ${device.mobile} {
        height: 40px;
    }
`

const Logo = styled.img`
    width: auto;
    height: 100%;
`

const SubWrapper = styled.div`
    padding: 80px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${device.mobile_and_ipad} {
        padding: 80px 0px;
    }
    @media ${device.mobile} {
        padding: 50px 0px;
    }
`

const FlexOneSubWrapper = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
`

interface Props extends RouteComponentProps<any> {
    userLoggedIn: IUser | null
    isUserLoading: boolean
    getUserDataRequest: () => void
    logout: () => void
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isUserLoading: state.user.loading,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    logout: () => logoutRequest(),
}

interface State {}

class InviteShareError extends React.Component<Props, State> {
    state = {}

    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }
    }

    componentDidUpdate(prevProps: Props) {}

    render() {
        const { userLoggedIn, isUserLoading, logout } = this.props

        return (
            <React.Fragment>
                {<Loader isLoading={isUserLoading} />}

                {userLoggedIn && (
                    <React.Fragment>
                        <DesktopDisplayOnly>
                            <SubWrapper>
                                <LeftAlignLogoWrapper>
                                    <Logo src={left_align_logo} />
                                </LeftAlignLogoWrapper>
                                <FlexOneSubWrapper>
                                    <ErrorCardDesktop
                                        title="Invalid access"
                                        text="You do not have permissions to access details of this page. Log in or register with the email that has been granted access."
                                        main_btn_text="Go to your garage"
                                        onClick={() =>
                                            this.props.history.push('/garage')
                                        }
                                        secondary_btn_text="Logout"
                                        secondary_btn_color="#df6f6f"
                                        onSecondaryBtnClick={logout}
                                    />
                                </FlexOneSubWrapper>
                            </SubWrapper>
                        </DesktopDisplayOnly>

                        <IpadAndMobileDisplay>
                            <SubWrapper>
                                <LeftAlignLogoWrapper>
                                    <Logo src={left_align_logo} />
                                </LeftAlignLogoWrapper>
                                <FlexOneSubWrapper>
                                    <ErrorCardMobile
                                        title="Invalid access"
                                        text="You do not have permissions to access details of this page. Log in or register with the email that has been granted access."
                                        main_btn_text="Go to your garage"
                                        onClick={() =>
                                            this.props.history.push('/garage')
                                        }
                                        secondary_btn_text="Logout"
                                        secondary_btn_color="#df6f6f"
                                        onSecondaryBtnClick={logout}
                                    />
                                </FlexOneSubWrapper>
                            </SubWrapper>
                        </IpadAndMobileDisplay>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(InviteShareError)
)
