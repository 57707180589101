import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { IUser } from 'myModels'
import { IDropdownItem, IReduxError } from 'entityModels'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import SelectSearchBarCarInfoMobile from '../../organisms/editForms/technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'
import { ISetModelDropdownDataReq } from '../../../redux/localdata/dropdownData/sagas/setModelDropdownData'
import { ISetCustomItemInSpecifiedTargettedDropdownReq } from '../../../redux/localdata/dropdownData/sagas/setCustomItemInSpecifiedTargettedDropdown'
import queryString from 'query-string'

interface Props extends RouteComponentProps<any> {
    userLoggedIn: null | IUser
    setMakeDropdownData: () => void
    getUserDataRequest: () => void
    makeDropDownList: IDropdownItem[] | null
    modelDropDownList: IDropdownItem[] | null
    isDropddownDataLoading: boolean
    isApiError: IReduxError
    setModelDropdownData: (payload: ISetModelDropdownDataReq) => void
    setCustomItemInSpecifiedTargettedDropdown: (
        payload: ISetCustomItemInSpecifiedTargettedDropdownReq
    ) => void

    setIsLoadingNewMakeOrModel: (p: boolean) => void

    setTempMakeIDSuccess: (payload: string | undefined) => void
    setTempMakeDisplay: (payload: string | undefined) => void

    setTempModelIDSuccess: (payload: string | undefined) => void
    setTempModelDisplay: (payload: string | undefined) => void

    tempMakeDisplay: string | undefined
    tempModelDisplay: string | undefined
}

type State = {
    answer: any
}

const dispatchProps = {
    setMakeDropdownData: () => dropDownActions.setMakeDropdownDataRequest(),
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setModelDropdownData: (payload: ISetModelDropdownDataReq) =>
        dropDownActions.setModelDropdownDataRequest(payload),
    setCustomItemInSpecifiedTargettedDropdown: (
        payload: ISetCustomItemInSpecifiedTargettedDropdownReq
    ) =>
        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
            payload
        ),
    setTempMakeIDSuccess: (payload: string | undefined) =>
        dropDownActions.setTempMakeIDSuccess(payload),
    setTempMakeDisplay: (payload: string | undefined) =>
        dropDownActions.setTempMakeDisplay(payload),

    setTempModelIDSuccess: (payload: string | undefined) =>
        dropDownActions.setTempModelIDSuccess(payload),
    setTempModelDisplay: (payload: string | undefined) =>
        dropDownActions.setTempModelDisplay(payload),
    setIsLoadingNewMakeOrModel: (p: boolean) =>
        dropDownActions.setIsLoadingNewMakeOrModel(p),
}

const mapStateToProps = (state: RootState) => ({
    userLoggedIn: state.user.userLoggedIn,
    makeDropDownList: state.localdata.dropdownData.carMakes,
    modelDropDownList: state.localdata.dropdownData.carModels,
    isDropddownDataLoading: state.localdata.dropdownData.loading,
    isApiError: state.entities.carsData.error,
    tempMakeID: state.localdata.dropdownData.tempMakeID,
    tempModelID: state.localdata.dropdownData.tempModelID,
    tempMakeDisplay: state.localdata.dropdownData.tempMakeDisplay,
    tempModelDisplay: state.localdata.dropdownData.tempModelDisplay,
})

class CarSearch extends React.Component<Props, State> {
    state = {
        answer: '',
    }

    getNecessaryData = () => {
        // getCarData
        const params = new URLSearchParams(this.props.location.search)

        let field_id = params.get('field_id')

        if (field_id === 'make' && !this.props.makeDropDownList) {
            this.props.setMakeDropdownData()
        }
    }

    componentDidMount() {
        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        } else {
            this.getNecessaryData()
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.userLoggedIn !== prevProps.userLoggedIn) {
            if (this.props.userLoggedIn) {
                this.getNecessaryData()
            }
        }
    }

    handleChange = (name: string, uid?: string) => {
        const params = new URLSearchParams(this.props.location.search)

        let field_id = params.get('field_id')

        this.setState({ answer: name })

        const getReturnUrl = () => {
            let returnTo = params.get('return_to') as string
            const formId = params.get('form_id')

            const returnUrl = queryString.stringifyUrl({
                url: returnTo,
                query: { form_id: formId },
            })

            return returnUrl
        }

        if (field_id === 'make') {
            this.props.setModelDropdownData({ makeName: name, make_uid: uid })
            this.props.setTempMakeDisplay(name)
            this.props.setTempMakeIDSuccess(uid)
            this.props.setTempModelDisplay('-')
            this.props.history.replace(getReturnUrl())
        }

        if (field_id === 'model') {
            this.props.setTempModelDisplay(name)
            this.props.setTempModelIDSuccess(uid)
            this.props.history.replace(getReturnUrl())
        }
    }

    params = new URLSearchParams(this.props.location.search)
    field_id: string = this.params.get('field_id') ?? ''

    getItems = () => {
        if (this.field_id === 'make') {
            return this.props.makeDropDownList ?? []
        }

        if (this.field_id === 'model') {
            return this.props.modelDropDownList ?? []
        }

        return []
    }

    searchOptionsData: any = {
        make: {
            text: 'make',
            id: 'make',
            answer: '',
            unique_answer_id: undefined,
        },
        model: {
            text: 'model',
            id: 'model',
            answer: '',
            unique_answer_id: undefined,
        },
    }

    render() {
        return (
            <>
                {this.props.userLoggedIn && (
                    <SelectSearchBarCarInfoMobile
                        handleSelectChange={(name: string, other?: string) => {
                            this.handleChange(name, other)
                        }}
                        field_id={this.field_id}
                        items={this.getItems()}
                        item={this.searchOptionsData[this.field_id]}
                        isStandalonePage={true}
                        dropdownTargetId={
                            { make: 'carMakes', model: 'carModels' }[
                                this.field_id
                            ]
                        }
                        allowAdd={true}
                        addAnItemFunc={(id: string, name: string) => {
                            this.props.setIsLoadingNewMakeOrModel(true)
                            this.props.setCustomItemInSpecifiedTargettedDropdown(
                                {
                                    dropdownTargetId: id,
                                    name: name,
                                }
                            )
                        }}
                    />
                )}
            </>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CarSearch))
