import gql from 'graphql-tag'
import { get_check_cookie_name } from '../../../helpers/cookies'
import { client } from '../../../helpers/prismicUtils/prismicHelpers'

/*
 * Specific Apex Article
 */
const apexSingleArticleByUIDGraphQLQuery = gql`
    query apexSingleArticleByUIDGraphQLQuery($uid: String) {
        allApex_articless(uid: $uid) {
            edges {
                node {
                    _meta {
                        uid
                    }
                    title
                    date
                    introduction
                    apex_team_credits
                    body {
                        ... on Apex_articlesBodyBody_rich_content {
                            type
                            primary {
                                body_elem
                            }
                        }
                        ... on Apex_articlesBodySingle_image_portrait {
                            type
                            primary {
                                img
                                image_credit
                            }
                        }
                        ... on Apex_articlesBodySingle_image_landscape {
                            type
                            primary {
                                img
                                image_credit
                            }
                        }
                        ... on Apex_articlesBodyDuo_img_portrait_portrait {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        ... on Apex_articlesBodyDuo_img_lansdcape_lansdcape {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        ... on Apex_articlesBodyDuo_img_hybrid_portrait_landscape {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        ... on Apex_articlesBodyDuo_img_hybrid_landscape_portrait {
                            type
                            primary {
                                img_1
                                img_2
                                image_credit
                            }
                        }
                        __typename
                    }
                }
            }
        }
    }
`

export const apexSingleArticleQuery = async (uid: string) => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexSingleArticleByUIDGraphQLQuery,
        variables: { uid },
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}
