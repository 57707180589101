import { IMembershipPageSection } from "myInterfaces";

import membership_1_1_mobile from "../../../public/assets/images/membership_page/membership_1_1_mobile.jpg";
import membership_2_1_mobile from "../../../public/assets/images/membership_page/membership_2_1_mobile.png";
import membership_3_1_mobile from "../../../public/assets/images/membership_page/membership_3_1_mobile.png";

import membership_1_1 from "../../../public/assets/images/membership_page/membership_1_1.jpg";
import membership_1_2 from "../../../public/assets/images/membership_page/membership_1_2.png";
import membership_1_3 from "../../../public/assets/images/membership_page/membership_1_3.jpg";

import membership_2_1 from "../../../public/assets/images/membership_page/membership_2_1.png";
import membership_2_2 from "../../../public/assets/images/membership_page/membership_2_2.png";
import membership_2_3 from "../../../public/assets/images/membership_page/membership_2_3.png";

import membership_3_1 from "../../../public/assets/images/membership_page/membership_3_1.jpg";
import membership_3_2 from "../../../public/assets/images/membership_page/membership_3_2.jpg";
import membership_3_3 from "../../../public/assets/images/membership_page/membership_3_3.jpg";

const membership_sections_list: IMembershipPageSection[] = [
  {
    title: "PRIVACY FIRST",
    sectionid: "membership_page_section_1",
    mobile_image: {
      image_url: membership_1_1_mobile,
      credits: {
        title: "‘Wiesbaden Commission’ (Singer Vehicle Design)",
        description: "Ice Races GP in Zell am See, Austria, Feb 2020",

        credits: "@Charlie B Photography"
      }
    },
    images: [
      {
        image_url: membership_1_1,
        credits: {
          title: "‘Wiesbaden Commission’ (Singer Vehicle Design)",
          description: "Ice Races GP in Zell am See, Austria, Feb 2020",

          credits: "@Charlie B Photography"
        }
      },
      {
        image_url: membership_1_2,
        credits: {
          title: "1948 Delahaye 175 S Grand Luxe Chapron",
          description: "at 2019 Hampton Court Concours of Elegance.",

          credits: "@Charlie B Photography"
        }
      },
      {
        image_url: membership_1_3,
        credits: {
          title: "Porsche 356 at Ice Races GP",
          description: " Zell am See, Austria, on 1-2 February 2020.",

          credits: "@Charlie B Photography"
        }
      }
    ],
    paragraphs: [
      "Peoples' collections are often deeply personal and very private.",

      "Through carefully curated membership and a private platform, you can have your own space away from the usual online hustle, constant advertising and spam. "
    ]
  },
  {
    title: "CURATED COMMUNITY",
    sectionid: "membership_page_section_1",
    mobile_image: {
      image_url: membership_2_1_mobile,

      credits: {
        title: "1997 McLaren F1",
        description:
          "at Hampton Court Concours of Elegance following a fully restoration by McLaren Special Operations (MSO).",
        credits: "@Charlie B Photography"
      }
    },
    images: [
      {
        image_url: membership_2_1,

        credits: {
          title: "1997 McLaren F1",
          description:
            "at Hampton Court Concours of Elegance following a fully restoration by McLaren Special Operations (MSO).",
          credits: "@Charlie B Photography"
        }
      },
      {
        image_url: membership_2_2,

        credits: {
          title: "1954 Ferrari 500 Mondial",
          description: " 2019 Hampton Court Concours of Elegance.",

          credits: "@Charlie B Photography"
        }
      },
      {
        image_url: membership_2_3,
        credits: {
          title: "Jaguar XJR-15b",
          description: " Goodwood Motor Circuit in August 2019.",

          credits: "@Charlie B Photography"
        }
      }
    ],
    paragraphs: [
      "Accountability - given each member has been carefully curated, there is no hiding behind an anonymous handle.",

      "Our Ambassadors made sure that all the key areas of the classic and collector car community are represented on the platform, as we build an online community as vibrant and fascinating as its real world counterpart."
    ]
  },
  {
    title: "MEMBERSHIP PROCESS",
    sectionid: "membership_page_section_1",
    mobile_image: {
      image_url: membership_3_1_mobile,
      credits: {
        title: "Aston Martin DB6 with Peking-to-Paris ready roof rack",
        description: "2019 Hampton Court Concours of Elegance",
        credits: "@CharlieB Photography"
      }
    },
    images: [
      {
        image_url: membership_3_1,
        credits: {
          title: "Aston Martin DB6 with Peking-to-Paris ready roof rack",
          description: "2019 Hampton Court Concours of Elegance",
          credits: "@CharlieB Photography"
        }
      },
      {
        image_url: membership_3_2,
        credits: {
          title:
            "ex-Paris-Dakar Rally 1984 Porsche 911 (953) 4x4 entered by the Porsche Museum",
          description:
            "at Ice Races GP in Zell am See, Austria, on 1-2 February 2020.",

          credits: "@Charlie B Photography"
        }
      },
      {
        image_url: membership_3_3,
        credits: {
          title:
            "A young enthusiast preparing to compete in the Settrington Cup",
          description: "at the 2019 Goodwood Revival.",

          credits: " @Charlie B Photography"
        }
      }
    ],
    paragraphs: [
      "You will be notified when membership applications open in Summer 2020.",
      "Whilst membership is fully at the discretion of the Club, applicants proposed by existing Private Collectors Club Members and Ambassadors will receive preferential treatment in the membership application process."
    ]
  }
];

export default membership_sections_list;
