import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import {
    Link,
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router-dom'
import {
    ICar,
    IEntityPrivateOwnedSharesState,
    ISearchUsersResult,
    IDirectShareOwnedItem,
    IUpdateCarDirectShareRequest,
} from 'entityModels'
import { IUser } from 'myModels'
import {
    addCarShareRecipientRequest,
    getCarDataByIdRequest,
    getSingleCarDirectShareRequest,
    IShareRecipientRequest_redux,
    IgetSingleDirectShareRequest_redux,
    updateCarDirectShareRequest,
} from '../../../redux/entities/cars/actions/loadingActions'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import PrivateShareSearchFormManagerMobile from '../../organisms/shareForms/privateShare/privateShareSearchFormMobile'
import { searchPlatformUsersRequest } from '../../../redux/searchUsers/actions/loadingActions'
import ReturnNav from '../../atoms/header/returnNav'
import SideSlider from '../../templates/animated/sideSlider'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        isCarLoading: state.entities.carsData.loading,
        sharesLoading: state.entities.carsData.carSharesLoading,
        carsData: state.entities.carsData.cars,
        isUserLoading: state.user.loading,
        searchResults: state.searchUsers.list,
        searchResultsLoading: state.searchUsers.loading,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getCarDataByIdRequest: (carid: string) => getCarDataByIdRequest(carid),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    searchPlatformUsersRequest: (value: string | undefined) =>
        searchPlatformUsersRequest(value),
    getSingleCarDirectShareRequest: (p: IgetSingleDirectShareRequest_redux) =>
        getSingleCarDirectShareRequest(p),
    addCarShareRecipientRequest: (p: IShareRecipientRequest_redux) =>
        addCarShareRecipientRequest(p),
    updateCarDirectShareRequest: (p: IUpdateCarDirectShareRequest) =>
        updateCarDirectShareRequest(p),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

interface Props extends RouteComponentProps<any> {
    carsData: { [key: string]: ICar }
    userLoggedIn: IUser | null
    isUserLoading: boolean
    isCarLoading: boolean
    getUserDataRequest: () => void
    getCarDataByIdRequest: (carid: string) => void
    setCurrentCar: (carid: string) => void
    searchPlatformUsersRequest: (value: string | undefined) => void
    searchResults: ISearchUsersResult[] | undefined
    searchResultsLoading: boolean
    getSingleCarDirectShareRequest: (
        p: IgetSingleDirectShareRequest_redux
    ) => void
    addCarShareRecipientRequest: (p: IShareRecipientRequest_redux) => void
    updateCarDirectShareRequest: (p: IUpdateCarDirectShareRequest) => void
    sharesLoading: boolean
    setInitialLandingUrl: (str: string) => void
}

type State = {
    share_being_edited: IDirectShareOwnedItem
    isDesktop: boolean
}

class CarShareEdit extends React.Component<Props, State> {
    state: State = {
        share_being_edited: {
            entity_id: '',
            title: '',
            active_permission_ids: [] as any,
            id: 'share_being_edited',
            created_at: '', // now
            type: 'car',
            updated_at: '', // now
            all_share_recipients_ids_list: [],
            external_share_recipients_ids_list: [],
            platform_share_recipients_ids_list: [],
            share_recipients_data: {},
            total_recipients: 0,
        },
        isDesktop: false,
    }

    _isMounted = false

    handleResize = () =>
        window.innerWidth > 881
            ? this.setState({ isDesktop: true })
            : this.setState({ isDesktop: false })

    setupLocalStateData = () => {
        let carid = this.props.match.params.carid
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')
        const car_shares: IEntityPrivateOwnedSharesState | undefined =
            carid &&
            this.props.carsData &&
            this.props.carsData[carid] &&
            this.props.carsData[carid].private_shares_owned

        if (
            car_shares &&
            car_shares.entity_owned_direct_shares_data &&
            shareid &&
            car_shares.entity_owned_direct_shares_data[shareid]
        ) {
            const share: IDirectShareOwnedItem =
                car_shares.entity_owned_direct_shares_data[shareid]

            this.setState({
                share_being_edited: {
                    ...share,
                    active_display: {
                        item_ids_display: share.active_display?.item_ids_display
                            ? share.active_display?.item_ids_display
                            : share.all_share_recipients_ids_list,
                        activeSort: share.active_display?.activeSort
                            ? share.active_display?.activeSort
                            : 'added_at',
                    },
                },
            })
        }
    }

    componentDidMount() {
        this._isMounted = true

        // user stuff
        let userData = this.props.userLoggedIn
        let carid = this.props.match.params.carid
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')
        this.props.setInitialLandingUrl(
            `/sharing/with-others/car/${carid}/edit-share/recipients?shareid=${shareid}`
        )
        if (!userData) {
            this.props.getUserDataRequest()
        } else {
            this.props.setCurrentCar(carid)

            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(carid)
            }

            shareid &&
                this.props.getSingleCarDirectShareRequest({
                    entity_id: carid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        window.addEventListener('resize', this.handleResize, { passive: true })

        window.innerWidth > 881 && this.setState({ isDesktop: true })
    }

    componentDidUpdate(prevProps: Props) {
        // Typical usage (don't forget to compare props):
        let carid = this.props.match.params.carid
        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            this.props.setCurrentCar(carid)
            if (!this.props.carsData[carid]) {
                this.props.getCarDataByIdRequest(carid)
            }

            shareid &&
                this.props.getSingleCarDirectShareRequest({
                    entity_id: carid,
                    share_id: shareid,
                })
            this.setupLocalStateData()
        }

        if (this.props.carsData[carid] !== prevProps.carsData[carid]) {
            this.setupLocalStateData()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
        window.removeEventListener('resize', this.handleResize)
    }

    render() {
        let {
            searchPlatformUsersRequest,
            searchResults,
            searchResultsLoading,
            sharesLoading,
        } = this.props

        let { share_being_edited } = this.state

        let carid = this.props.match.params.carid

        const q_params = new URLSearchParams(this.props.location.search)
        let shareid = q_params.get('shareid')

        return (
            <React.Fragment>
                <DesktopDisplayOnly>
                    {this.state.isDesktop === true && (
                        <Redirect
                            to={`/sharing/with-others/car/${carid}/edit-share?shareid=${shareid}`}
                        />
                    )}
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <SideSlider direction="right" initial="enter">
                        <Link
                            to={{
                                pathname: `/sharing/with-others/car/${carid}/edit-share`,
                                search: `?shareid=${shareid}`,
                                state: {
                                    prevPath:
                                        this.props.history.location.pathname,
                                },
                            }}
                        >
                            <ReturnNav />
                        </Link>
                        <PrivateShareSearchFormManagerMobile
                            share={share_being_edited}
                            addShareRecipient={
                                this.props.addCarShareRecipientRequest
                            }
                            searchUsers={searchPlatformUsersRequest}
                            searchResults={searchResults}
                            resultsLoading={searchResultsLoading}
                            selectedUsers={
                                share_being_edited.all_share_recipients_ids_list
                            }
                            sharesLoading={sharesLoading}
                            goBack={() =>
                                setTimeout(
                                    () =>
                                        this.props.history.push(
                                            `/sharing/with-others/car/${carid}/edit-share?shareid=${shareid}`
                                        ),
                                    500
                                )
                            }
                        />
                    </SideSlider>
                </IpadAndMobileDisplay>
            </React.Fragment>
        )
    }
}

export default withRouter(connect(mapStateToProps, dispatchProps)(CarShareEdit))
