import { Link } from 'react-router-dom'
import styled from 'styled-components'
import left_align_logo from '../../../../public/assets/landingPage/left_align_logo.svg'
import { useState } from 'react'
import Expander from '../../../atoms/expander/expander'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import {
    publicShowroomFooretContent,
    socials,
} from './showroomPublicFooterDesktop'
import posthog from 'posthog-js'

const Wrapper = styled.section`
    display: flex;
    width: 100%;
    padding: 0.5rem;
    padding-bottom: 150px;
    padding-top: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
`

const Banner = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    padding-bottom: 2rem;
    background-color: #fafafa;
    border-radius: 16px;
`
const BannerTitle = styled.h1`
    padding: 0px;
    margin: 0px;
    color: var(--text-strong, #1a1a1a);
    text-align: center;
    font-family: Lato-semibold;
    font-size: 1.375rem;
    line-height: 2rem;
    padding-bottom: 1rem;
`

const BannerSubtitle = styled.p`
    padding: 0px;
    margin: 0px;
    color: var(--text-strong, #1a1a1a);
    text-align: center;
    font-family: Lato;
    line-height: 1.5rem; /* 150% */
    letter-spacing: 0.00006rem;
    padding-bottom: 1.5rem;
`

const ButtonLink = styled(Link)`
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--core-teal-500, #5ec3ca);
`

const ButtonLinkText = styled.p`
    padding: 0px;
    margin: 0px;
    color: var(--base-white, #fff);
    text-align: center;
    font-family: Lato-semibold;
    font-size: 1rem;
    font-style: normal;
    letter-spacing: 0.001px;
`

const Logo = styled.img`
    height: 2.5rem;
    width: auto;
`
const LogoWrapper = styled.div`
    width: 100%;
`
const Table = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
`

const ExpandableBtn = styled.button`
    width: 100%;
    all: unset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`

const ColTitle = styled.p`
    color: var(--text-muted, #b3b3b3);
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    letter-spacing: 0.00006rem;
`
// const ColLinkText = styled(Link)`
//     color: var(--text-default, #666);
//     font-family: Lato;
//     font-size: 1rem;
//     font-style: normal;
//     letter-spacing: 0.00006rem;
//     :active {
//         color: var(--core-teal-500, #5ec3ca);
//     }
//     :hover {
//         color: var(--core-teal-500, #5ec3ca);
//     }
// `

const ColHrefText = styled.a`
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    letter-spacing: 0.00006rem;
    :active {
        color: var(--core-teal-500, #5ec3ca);
    }
    :hover {
        color: var(--core-teal-500, #5ec3ca);
    }
`

const Socials = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`

const CopyrightDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #b3b3b3;
`

const CopyrightText = styled.p`
    color: var(--text-default, #666);
    font-family: Lato;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.00006rem;
`

const ShowroomPublicFooterMobile = () => {
    const [openSection, setOpenSection] = useState({
        section1: false,
        section2: false,
    })

    const handleOpenSection = (i: number) => {
        if (i === 0) {
            if (openSection.section1 === true) {
                setOpenSection({ ...openSection, section1: false })
            } else {
                setOpenSection({ ...openSection, section1: true })
            }
        }
        if (i === 1) {
            if (openSection.section2 === true) {
                setOpenSection({ ...openSection, section2: false })
            } else {
                setOpenSection({ ...openSection, section2: true })
            }
        }
    }
    return (
        <Wrapper>
            <Banner>
                <BannerTitle>
                    Start managing your car with Custodian today
                </BannerTitle>
                <BannerSubtitle>
                    Join a community of 40,000+ automotive enthusiasts
                </BannerSubtitle>
                <ButtonLink to={'/register'}>
                    <ButtonLinkText>Get started now</ButtonLinkText>
                </ButtonLink>
            </Banner>
            <LogoWrapper>
                <Logo src={left_align_logo} />
            </LogoWrapper>
            <Table>
                {publicShowroomFooretContent.map((item, i) => (
                    <Col>
                        <ExpandableBtn onClick={() => handleOpenSection(i)}>
                            <ColTitle>{item.title}</ColTitle>
                            <div
                                style={{
                                    transform:
                                        i === 0 && openSection.section1 === true
                                            ? 'rotate(180deg)'
                                            : i === 1 &&
                                              openSection.section2 === true
                                            ? 'rotate(180deg)'
                                            : 'rotate(0deg)',
                                    transition: 'all 200ms',
                                }}
                            >
                                <ChevronDown />
                            </div>
                        </ExpandableBtn>
                        <Expander
                            height={
                                i === 0 && openSection.section1 === true
                                    ? 'auto'
                                    : i === 1 && openSection.section2 === true
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <Col>
                                {item.links.map((subitem) => (
                                    <ColHrefText
                                        href={subitem.href}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            posthog.capture(
                                                `public_user clicks footer_link ${subitem.text}`
                                            )
                                            setTimeout(
                                                () =>
                                                    item.title === 'Essentials'
                                                        ? window.open(
                                                              subitem.href,
                                                              '_self'
                                                          )
                                                        : window.open(
                                                              subitem.href
                                                          ),
                                                100
                                            )
                                        }}
                                    >
                                        {subitem.text}
                                    </ColHrefText>
                                ))}
                            </Col>
                        </Expander>
                    </Col>
                ))}
                <Col>
                    <ColTitle>Follow us</ColTitle>
                    <Socials>
                        {socials.map((socialItem) => (
                            <a href={socialItem.href} target="_blank">
                                {socialItem.img}
                            </a>
                        ))}
                    </Socials>
                </Col>
            </Table>
            <CopyrightDiv>
                <CopyrightText>
                    © Copyright 2023. All Rights Reserved.
                </CopyrightText>

                <a href={`https://custodian.club/privacy`} target="_blank">
                    <CopyrightText>Privacy Policy</CopyrightText>
                </a>
                <a
                    href="https://www.custodian.club/terms-of-service"
                    target="_blank"
                >
                    <CopyrightText>Terms of Service</CopyrightText>
                </a>
            </CopyrightDiv>
        </Wrapper>
    )
}
export default ShowroomPublicFooterMobile
