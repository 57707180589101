import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const DownloadIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99994 10.0812L10.4696 7.61157L11.5303 8.67223L7.99994 12.2026L4.46961 8.67223L5.53027 7.61157L7.99994 10.0812Z"
                fill={color ?? colours[theme].text_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.75 1.14185V11.1418H7.25V1.14185H8.75Z"
                fill={color ?? colours[theme].text_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.75 7.92188V13.3919H14.25V7.92188H15.75V14.1419C15.75 14.5561 15.4142 14.8919 15 14.8919H1C0.585786 14.8919 0.25 14.5561 0.25 14.1419V7.92188H1.75Z"
                fill={color ?? colours[theme].text_default}
            />
        </svg>
    )
}

export default DownloadIcon
