import { useState } from 'react'
import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowDesktop from '../../../buttons/buttonsStandardBottomRowDesktop'
// import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import * as unitGenerator from '../../../../../../helpers/units/unitConversion'

import { IList, IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { Select } from '../../../../../atoms/select'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import Expander from '../../../../../atoms/expander/expander'
import {
    cubicCentimetersToLiters,
    cubicInchesToLiters,
} from '../../../../../../helpers/units/unitConversion'
import * as Yup from 'yup'
import { yupVar_NumberBasicSingleField } from '../../../../../molecules/editOrCreateModeSingleFields/yupVars'
import { device } from '../../../../../templates/displays/devices'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding40 = styled.div`
    padding-top: 40px;
`
const Padding5 = styled.div`
    padding-top: 5px;
`

const RowForm = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`

const AdjustPosition = styled.div`
    margin-left: 20px;
    width: 100%;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    padding-left: 8px;
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    padding-top: 4px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_numberTechInfoDesktop: number
    unit?: string
}

type IUnitInfo = {
    // data in the list :
    list: IList[] | null
    // id : distance_unit or fuel_unit
    id?: string
    // user chosen unit:
    user_default_unit?: string
}

interface OtherProps {
    toggle: any
    item: any
    isUnits?: boolean
    unitList?: any[]
    // user_default_unit?: any
    submitFunc: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id?: any
    carid: string
    unitInfo: IUnitInfo
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        isUnits,
        unitList,
        // user_default_unit,
        setFieldValue,
        unitInfo,
        handleSubmit,
        errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    const handleUnitChange = (value: string) => {
        setFieldValue('unit', value)
    }

    const [isDisabled, setIsDisabled] = useState(
        values.single_item_edited_value_numberTechInfoDesktop ? true : false
    )

    const handleAnswerChange = (e: any) => {
        setFieldValue(
            'single_item_edited_value_numberTechInfoDesktop',
            e.target.value
        )
        if (!e.target.value || e.target.value.length > 20) {
            setIsDisabled(true)
        } else if (errors.single_item_edited_value_numberTechInfoDesktop) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop $theme={theme} style={{ marginTop: '20vh' }}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperDesktop>
                <Padding5 />
                <RowForm>
                    <InputField
                        theme={theme}
                        id="single_item_edited_value_numberTechInfoDesktop"
                        name="single_item_edited_value_numberTechInfoDesktop"
                        placeholder={item.text}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={
                            values.single_item_edited_value_numberTechInfoDesktop
                        }
                        font_family="Lato"
                        type="number"
                        isnarrow={true}
                        onChange={handleAnswerChange}
                        onEnterKeyPress={props.handleSubmit}
                        width="300px"
                        ipad_width="300px"
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    {isUnits && (
                        <AdjustPosition>
                            <Select
                                defaultValue={unitInfo?.user_default_unit ?? ''}
                                onChange={handleUnitChange}
                                options={
                                    unitList?.map((unit) => ({
                                        value: unit.id,
                                        label: unit.name,
                                    })) ?? []
                                }
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                        </AdjustPosition>
                    )}
                </RowForm>
                {errors.single_item_edited_value_numberTechInfoDesktop && (
                    <Expander
                        height={
                            errors.single_item_edited_value_numberTechInfoDesktop
                                ? 'auto'
                                : 0
                        }
                    >
                        <Error>
                            {
                                errors.single_item_edited_value_numberTechInfoDesktop
                            }
                        </Error>
                    </Expander>
                )}
                {!errors.single_item_edited_value_numberTechInfoDesktop && (
                    <Expander
                        height={
                            item.id === 'mileage' &&
                            values.unit !== 'miles' &&
                            values.single_item_edited_value_numberTechInfoDesktop
                                ? 'auto'
                                : 0
                        }
                    >
                        <EquivalentConverted>
                            Equal to{' '}
                            {values.single_item_edited_value_numberTechInfoDesktop &&
                                unitGenerator.kmToMiles(
                                    +values.single_item_edited_value_numberTechInfoDesktop
                                )}{' '}
                            miles
                        </EquivalentConverted>
                    </Expander>
                )}

                {!errors.single_item_edited_value_numberTechInfoDesktop && (
                    <Expander
                        height={
                            item.id === 'engine_capacity' &&
                            values.unit !== 'liters' &&
                            values.single_item_edited_value_numberTechInfoDesktop
                                ? 'auto'
                                : 0
                        }
                    >
                        <EquivalentConverted>
                            Equal to{' '}
                            {values.single_item_edited_value_numberTechInfoDesktop &&
                                (values.unit === 'cc' ||
                                values.unit === 'cubic_centimeters'
                                    ? cubicCentimetersToLiters(
                                          +values.single_item_edited_value_numberTechInfoDesktop
                                      ).toFixed(1)
                                    : values.unit === 'ci' ||
                                      values.unit === 'cubic_inches'
                                    ? cubicInchesToLiters(
                                          +values.single_item_edited_value_numberTechInfoDesktop
                                      ).toFixed(1)
                                    : null)}{' '}
                            litres
                        </EquivalentConverted>
                    </Expander>
                )}

                <Padding40 />
                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={handleSubmit}
                    isDisabled={isDisabled}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const NumberTechInfoEditFormDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_numberTechInfoDesktop:
            props.item.answer !== undefined && props.item.answer !== null
                ? props.item.answer
                : undefined,
        unit: props.unitInfo.user_default_unit,
    }),
    validationSchema: () => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                single_item_edited_value_numberTechInfoDesktop:
                    yupVar_NumberBasicSingleField(
                        values.single_item_edited_value_numberTechInfoDesktop
                    ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        // let finalAnswer =
        //     values.single_item_edited_value_numberTechInfoDesktop * 1

        // if (props.unitList && values.unit && values.unit === 'miles') {
        //     finalAnswer = finalAnswer * 1.609
        //
        // }

        // finalAnswer = Math.round(finalAnswer)

        const valuesToSend = (
            unit_from_modal_form: string,
            value: number
        ): number => {
            switch (unit_from_modal_form) {
                //distance
                case 'miles':
                    return unitGenerator.generateBackEndValueDistanceUnit(
                        unit_from_modal_form,
                        value
                    )
                //fuel
                case 'cubic_inches':
                    return Math.round(
                        unitGenerator.generateBackEndValueFuelUnit(
                            unit_from_modal_form,
                            value
                        )
                    )
                case 'liters':
                    return Math.round(
                        unitGenerator.generateBackEndValueFuelUnit(
                            unit_from_modal_form,
                            value
                        )
                    )
                case 'cubic_centimeters':
                    return Math.round(value)
                //default
                default:
                    return Math.round(value)
            }
        }

        if (props.submitFunc) {
            let converted_val: number = values.unit
                ? valuesToSend(
                      values.unit,
                      values.single_item_edited_value_numberTechInfoDesktop
                  )
                : values.single_item_edited_value_numberTechInfoDesktop

            let payload: IwriteTechnicalInformationPayloadReq = {
                tech_info_id: props.tech_info_id,
                car_id: props.carid,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answer: converted_val,
                },
            }

            props.submitFunc(payload)
            props.toggle()
        }
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(NumberTechInfoEditFormDesktop)
