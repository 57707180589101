import { debounce } from 'lodash'
import { useEffect, useMemo } from 'react'

function useDebounce(callback: (value: string) => void, delay: number) {
    const debounceCallback = useMemo(
        () => debounce(callback, delay),
        [callback, delay]
    )
    useEffect(() => {
        return () => {
            debounceCallback.cancel()
        }
    }, [debounceCallback])

    return debounceCallback
}

export default useDebounce
