import * as React from 'react'
import styled from 'styled-components'
// import CustomDeleteButtonDesktop from '../../atoms/Button/customDeleteButtonDesktop'
import IconButton from '../../atoms/Button/iconButton'
import SectionHeaderV2 from '../../atoms/typography/sectionHeaderV2'
import { device } from '../../templates/displays/devices'
import { Link } from 'react-router-dom'
import { ICar } from 'entityModels'
import NavigateSquareIcon from '../../atoms/icons/components/navigateSquareIcon'

// interface IStyle {}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.ipad} {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media (min-width: 1000px) and (max-width: 1100px) {
        padding-right: 70px;
    }
`

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    @media ${device.ipad} {
        display: none;
    }
`

const ArrowButtonsWrapper = styled.div`
    display: none;
    @media ${device.ipad} {
        display: flex;
        align-items: center;
    }
`

const LeftDiv = styled.div`
    /* width: 100%; */
    display: flex;
    align-items: center;
`

// const RightDiv = styled.div`
//     /* width: 100%; */
//     display: flex;
//     justify-content: flex-end;
// `

const SmallPadding = styled.div`
    padding-left: 10px;
`

const IPadCarTitle = styled.h1`
    font-family: Lato-bold;
    font-size: 16px;
    text-transform: capitalize;
    padding-right: 10px;
    padding-left: 20px;
`

interface Props {
    elementToScrollTo: HTMLElement | null
    goBackUrl: string
    onDeleteClick: any
    goToNext?: any
    goToPrevious?: any
    carData: ICar
    customTitle?: string
    removeUpDownNav?: boolean
}

class CarShareTopHeaderDesktop extends React.Component<Props> {
    scrollToElement = () =>
        this.props.elementToScrollTo &&
        this.props.elementToScrollTo.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        })

    render() {
        let {
            carData,
            goBackUrl,
            goToNext,
            goToPrevious,
            customTitle,
            removeUpDownNav,
        } = this.props

        return (
            <Wrapper>
                <LeftDiv>
                    <ArrowButtonsWrapper>
                        <Link to={`${goBackUrl}`}>
                            <IconButton
                                icon="grey_cross"
                                iconwidth="14px"
                                noborder
                                bgcolor="white"
                            />
                        </Link>
                        {!removeUpDownNav && (
                            <>
                                <SmallPadding />
                                <IconButton
                                    icon="arrow"
                                    onClick={() => goToPrevious()}
                                />
                                <SmallPadding />
                                <IconButton
                                    icon="arrow"
                                    orientation="down"
                                    onClick={goToNext}
                                />
                            </>
                        )}
                        {removeUpDownNav && customTitle && (
                            <IPadCarTitle>
                                {customTitle ? customTitle : carData.title}
                            </IPadCarTitle>
                        )}
                    </ArrowButtonsWrapper>
                    <TitleWrapper>
                        <Link
                            to={`${goBackUrl}`}
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <NavigateSquareIcon />
                        </Link>
                        <SmallPadding />
                        <SmallPadding />
                        <SectionHeaderV2
                            fontsize="24px"
                            icon="v2_edit"
                            hasActionIcon={false}
                        >
                            {customTitle ? customTitle : carData.title}
                        </SectionHeaderV2>
                    </TitleWrapper>
                </LeftDiv>
                {/* <RightDiv>
                    <CustomDeleteButtonDesktop
                        onClick={this.props.onDeleteClick}
                        customText="Delete All Shares"
                        width="170px"
                    />
                </RightDiv> */}
            </Wrapper>
        )
    }
}

export default CarShareTopHeaderDesktop
