import { useMemo } from 'react'
import styled from 'styled-components'
import { generateUserChoiceUnit } from '../../../../helpers/units/generateUserChoiceUnit'
import {
    generateFrontEndValueDistanceUnit,
    generateFrontEndValueFuelUnit,
} from '../../../../helpers/units/unitConversion'
import { IShowroomEntry } from '../../../../redux/showroom/types'
import { useAppSelector } from '../../../../redux/store/hooks'
import { ICarShowroomSpecs } from '../../../pages/showroom/showroom'
import { numberWithCommas } from '../../../../helpers/numbers'

type Props = {
    entry: IShowroomEntry | undefined
    isAdmin?: boolean
    openLocationForm?: () => any
    openForm?: () => any
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    flex: 1;

    position: relative;
`

const ListItem = styled.div<{ $isUndisclosed?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 24px;
    padding-left: 24px;
    gap: 4px;

    width: 45%;
    height: 80px;

    background-color: ${(props) =>
        props.$isUndisclosed ? `var(--off-bg-color, #fafafa)` : undefined};
    border: ${(props) =>
        props.$isUndisclosed
            ? 'none'
            : '1px solid var(--border-muted, #e5e5e5)'};
    border-radius: 2px;

    flex-grow: 1;
`

const ListItemContentTitle = styled.span`
    font-style: normal;
    /* font-weight: 600; */
    font-size: 12px;
    line-height: 16px;
    font-family: Lato-Semibold;

    display: flex;
    align-items: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    color: var(--text-muted, #b3b3b3);
    text-align: center;
`

const ListItemContentSpec = styled.span`
    font-family: Lato-bold;
    font-size: 16px;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

    @media (max-width: 400px) {
        font-size: 14px;
    }
`

const FactsGridSectionMobile = (props: Props) => {
    const { entry } = props

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const default_fuel_unit_user: string | null = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'fuel_unit').unit_id
        : 'liters'

    const default_fuel_unit_user_text: string | null | undefined = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'fuel_unit').unit_text
        : 'liters'

    const default_distance_unit_user: string | null = userLoggedIn
        ? generateUserChoiceUnit(userLoggedIn, 'distance_unit').unit_id
        : 'miles'

    const default_distance_unit_user_text: string | null | undefined =
        userLoggedIn
            ? generateUserChoiceUnit(userLoggedIn, 'distance_unit').unit_text
            : 'miles'

    const techInfoData = useAppSelector((state) => {
        return state.entities.technicalInformationData.technical_information
    })
    const convertCarSpecsToShowroom = useMemo(() => {
        let res: ICarShowroomSpecs = {
            make: '',
            model: '',
            year: '',
            engine_size: '',
            transmission: '',
            drive_side: '',
            mileage: '',
            location: '',
        }

        let car = entry?.car
        if (car) {
            let data = car.overview && car.overview.data

            let fuel_unit_text: string = default_fuel_unit_user_text
                ? default_fuel_unit_user_text
                : ''

            let distance_unit_text: string =
                default_distance_unit_user_text ?? ''

            let formatedEngineSize =
                default_fuel_unit_user &&
                car.technical_information &&
                techInfoData[car.technical_information] &&
                techInfoData[car.technical_information].engine_capacity &&
                techInfoData[car.technical_information].engine_capacity.answer
                    ? generateFrontEndValueFuelUnit(
                          default_fuel_unit_user,
                          // @ts-ignore
                          +techInfoData[car.technical_information]
                              .engine_capacity.answer
                      )
                    : undefined

            res.make = data.make.answer
            res.model = data.model.answer
            res.drive_side = data.drive_side.answer
            res.year = `${data.year.answer ? data.year.answer : '-'}`
            res.engine_size = `${
                formatedEngineSize
                    ? default_fuel_unit_user !== 'cubic_centimeters'
                        ? formatedEngineSize.toFixed(1)
                        : formatedEngineSize
                    : '-'
            } ${
                techInfoData[car.technical_information]?.engine_capacity?.answer
                    ? fuel_unit_text
                    : ''
            }`
            res.transmission = `${data.transmission.answer}`
            res.location = entry?.address?.locality
                ? entry.address.locality
                : '-'
            res.mileage = entry?.undisclosed_mileage
                ? 'Undisclosed'
                : techInfoData &&
                  car.technical_information &&
                  techInfoData[car.technical_information] &&
                  techInfoData[car.technical_information].mileage &&
                  techInfoData[car.technical_information].mileage.answer !==
                      undefined &&
                  techInfoData[car.technical_information].mileage.answer !==
                      null
                ? `${
                      default_distance_unit_user &&
                      techInfoData[car.technical_information].mileage.answer &&
                      numberWithCommas({
                          numberToConvert: generateFrontEndValueDistanceUnit(
                              default_distance_unit_user,
                              // @ts-ignore
                              techInfoData[car.technical_information].mileage
                                  .answer
                          ),
                      })
                  } ${distance_unit_text}`
                : '-'
        }
        return res
    }, [entry, techInfoData, default_fuel_unit_user_text])

    const specs: ICarShowroomSpecs = convertCarSpecsToShowroom

    return (
        <Container>
            {specs &&
                Object.keys(specs).map((key) => (
                    <ListItem
                        $isUndisclosed={
                            specs[key as keyof typeof specs] === 'Undisclosed'
                                ? true
                                : false
                        }
                        key={`mobile-${key}`}
                        onClick={() => {
                            if (
                                props.isAdmin &&
                                props.openForm !== undefined &&
                                props.openLocationForm !== undefined
                            ) {
                                if (key === 'location') {
                                    props.openLocationForm()
                                } else props.openForm()
                            }
                        }}
                    >
                        <ListItemContentTitle>
                            {key && key.replace(/_/g, ' ')}
                        </ListItemContentTitle>
                        <ListItemContentSpec>
                            {specs[key as keyof typeof specs]}
                        </ListItemContentSpec>
                    </ListItem>
                ))}
        </Container>
    )
}

export default FactsGridSectionMobile
