export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
export function getMonthsUntilToday(): string[] {
    const today = new Date()
    const currentMonth = today.getMonth() // getMonth() returns 0 for January, 1 for February, etc.

    return monthNames.slice(0, currentMonth + 1)
}

export function getMonthsBetweenDates(
    startDateStr: string,
    endDateStr: string,
    isCustom?: boolean
): string[] {
    const parseDate = (dateStr: string): Date => {
        const [day, month, year] = dateStr.split('-').map(Number)
        return new Date(year, month - 1, day)
    }

    const startDate = parseDate(startDateStr)

    const endDate = parseDate(endDateStr)

    const result: string[] = []

    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()

    for (let year = startYear; year <= endYear; year++) {
        const startMonth = year === startYear ? startDate.getMonth() : 0
        const endMonth = year === endYear ? endDate.getMonth() : 11

        for (let month = startMonth; month <= endMonth; month++) {
            if (isCustom) {
                result.push(`${monthNames[month]} ${year}`)
            } else {
                result.push(monthNames[month])
            }
        }
    }

    return result
}

export function getYearsBetweenDates(
    startDateStr: string,
    endDateStr: string
): string[] {
    const parseDate = (dateStr: string): Date => {
        const [day, month, year] = dateStr.split('-').map(Number)
        return new Date(year, month - 1, day)
    }

    const startDate = parseDate(startDateStr)
    const endDate = parseDate(endDateStr)

    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()

    const years: string[] = []

    for (let year = startYear; year <= endYear; year++) {
        years.push(`${year}`)
    }

    return years
}

// // Example usage:
// const start = '01-03-2023'
// const end = '01-07-2024'
// const yearsBetween = getYearsBetweenDates(start, end)
