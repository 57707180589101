import { IJourneyUserProfileStepID } from 'journeyInterfaces'
import { useHistory } from 'react-router'
// import { UserProfileAchievedJourney } from '../achieved'
import UserProfileJourneyAddress from '../address'
import UserProfileJourneyBio from '../bio'
import UserProfileJourneyNames from '../names'
import UserProfileJourneyProfilePicture from '../profilePicture'

import JourneyUserProfileWelcome from '../steps/welcome'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'

type Props = {
    stepID: IJourneyUserProfileStepID
    startJourney: () => void
    exitJourney: () => void
}
export default function UserProfileJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
}: Props) {
    const history = useHistory()

    switch (stepID) {
        case 'welcome':
            return (
                <JourneyUserProfileWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )

        case 'profile_photo':
            return (
                <UserProfileJourneyProfilePicture
                    step={1}
                    totalSteps={4}
                    goToPrevStep={() => {
                        return history.push(
                            `/journey/your-profile?step=welcome`
                        )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/your-profile`,
                            search: `?step=bio`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'bio':
            return (
                <UserProfileJourneyBio
                    step={2}
                    totalSteps={4}
                    goToPrevStep={() => {
                        history.push(`/journey/your-profile?step=profile_photo`)
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/your-profile`,
                            search: `?step=names`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'names':
            return (
                <UserProfileJourneyNames
                    step={3}
                    totalSteps={4}
                    goToPrevStep={() => {
                        history.push(`/journey/your-profile?step=bio`)
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/your-profile`,
                            search: `?step=address`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'address':
            return (
                <UserProfileJourneyAddress
                    step={4}
                    totalSteps={4}
                    goToPrevStep={() => {
                        history.push(`/journey/your-profile?step=names`)
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/your-profile`,
                            search: `?step=achieved`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )

        case 'achieved':
            return <JourneyAchievedStep sectionID="your_profile" />
        default:
            return (
                <JourneyUserProfileWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
