import AppleSignin from 'react-apple-signin-auth'
import styled from 'styled-components'
import { useAppDispatch } from '../../../../../redux/store/hooks'
import { socialAuthRequest } from '../../../../../redux/user/actions/loadingActions'
import { device } from '../../../../templates/displays/devices'
import '../styles.css'
import * as Sentry from '@sentry/browser'
import { IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../../../redux/conversions/errors/convertToReduxError'
import {
    resetErrorStateSuccess,
    signInUserError,
} from '../../../../../redux/user/actions/errorActions'

type IStyle = {
    normal_height?: string | undefined
}

type IAppleRes = {
    authorization: {
        code: string
        id_token: string
        state: string
    }
    user?: {
        email?: string
        name?: {
            firstName?: string
            lastName?: string
        }
    }
}

export const SocialSignContainer = styled.div<IStyle>`
    /* height: 56px; */
    width: 100%;

    /* height: ${(props) =>
        props.normal_height ? props.normal_height : '56px'}; */

    @media ${device.desktop} {
        /* height: 45px; */
    }

    @media ${device.large_desktop} {
        /* height: 48px; */
    }
`

const generateNonce = (length: number) => {
    let text = ''
    const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return text
}

type Props = {
    isValidToken?: boolean
    platform_consent?: boolean
    apex_consent?: boolean
    fbclid?: string
    next_path?: string
    is_registration: boolean
    normal_height?: string
    from_landing?: boolean
}

const AppleSignInButton = (props: Props) => {
    const nonce = generateNonce(16)

    let {
        is_registration,
        platform_consent,
        apex_consent,
        fbclid,
        next_path,
        from_landing,
    } = props

    let urll =
        process.env.REACT_APP_ENV === 'production'
            ? `https://app.custodian.club/social-login/apple`
            : `https://pcc-frontend-git-apple.dev.custodian.club/social-login/apple`

    const dispatch = useAppDispatch()

    const callbackFunc = (response: IAppleRes) => {
        if (is_registration) {
            dispatch(
                socialAuthRequest({
                    platformConsent: platform_consent ? true : false,
                    apexConsent: apex_consent ? true : false,
                    fbclid: fbclid ? `${fbclid}` : null,
                    authorization_code:
                        response.authorization.code ?? undefined,
                    id_token: response.authorization.id_token ?? undefined,
                    next_path: next_path,
                    provider: 'apple',
                    is_registration: true,
                    from_landing: from_landing ? true : false,
                    given_name: response?.user?.name?.firstName ?? undefined,
                    family_name: response?.user?.name?.lastName ?? undefined,
                })
            )
        } else {
            dispatch(
                socialAuthRequest({
                    platformConsent: platform_consent ? true : false,
                    apexConsent: apex_consent ? true : false,
                    fbclid: fbclid ? `${fbclid}` : null,
                    authorization_code:
                        response.authorization.code ?? undefined,
                    next_path: next_path,
                    provider: 'apple',
                    is_registration: false,
                    from_landing: false,
                    id_token: response.authorization.id_token ?? undefined,
                    given_name: response?.user?.name?.firstName ?? undefined,
                    family_name: response?.user?.name?.lastName ?? undefined,
                })
            )
        }
    }

    return (
        <SocialSignContainer normal_height={props.normal_height ?? undefined}>
            <AppleSignin
                data-ph={'continue_with_apple'}
                /** Auth options passed to AppleID.auth.init() */
                authOptions={{
                    /** Client ID - eg: 'com.example.com' */
                    clientId: 'club.custodian.auth',
                    /** Requested scopes, seperated by spaces - eg: 'email name' */
                    scope: 'email name',
                    /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                    redirectURI: urll,
                    /** State string that is returned with the apple response */
                    state: 'state',
                    /** Nonce */
                    nonce: nonce,

                    usePopup: true,
                }} // REQUIRED
                /** General props */
                uiType="dark"
                /** className */
                className="apple-auth-btn"
                /** Removes default style tag */
                noDefaultStyle={false}
                /** Allows to change the button's children, eg: for changing the button text */
                buttonExtraChildren="Continue with Apple"
                /** Extra controlling props */
                /** Called upon signin success in case authOptions.usePopup = true -- which means auth is handled client side */
                onSuccess={(response: IAppleRes) => {
                    dispatch(resetErrorStateSuccess())
                    callbackFunc(response)
                }}
                /** Called upon signin error */
                onError={(error: any) => {
                    Sentry.captureMessage(
                        `Apple error ${JSON.stringify(error)}`
                    )

                    let typedError: IReduxError = ConvertToReduxError(
                        { status: 400 },
                        {
                            custom_message: 'Apple signin error',
                        }
                    )
                    dispatch(signInUserError(typedError))
                }} // default = undefined
                /** Skips loading the apple script if true */
                skipScript={false} // default = undefined
            />
        </SocialSignContainer>
    )
}

export default AppleSignInButton
