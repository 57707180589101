import { useEffect, useState } from 'react'
import { RouteComponentProps, useParams, withRouter } from 'react-router'
import {
    IManageTopSubmenuSuccessArg,
    menuActions,
} from '../../../../redux/menus/reducer'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import styled from 'styled-components'
import { IGalleryImage, IGalleryImagesObject } from 'entityModels'
import CarShowroomTopBar from '../../../templates/bars/showroomBars/topBars/carShowroomTopBar'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import {
    INormalisedShowroomEntries,
    IShowroomEntry,
} from '../../../../redux/showroom/types'
import { showroomActions } from '../../../../redux/showroom/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import CarShowroomEntryShareModal from '../../../molecules/showroom/editModals/desktop/carShowroomEntryShareModal'
import ModalDisplayFullPageDesktopExclusive from '../../../templates/displays/pageWrappers/modalDisplayFullPageDesktopExclusive'
import ModalDisplayMobileIpad from '../../../templates/displays/pageWrappers/modalDisplayMobileExclusive'
import { device } from '../../../templates/displays/devices'
import CarGalleryManagerDesktop from '../../../organisms/galleryManager/carGalleryManagerDesktop'
import CarGalleryManagerMobile from '../../../organisms/galleryManager/carGalleryManagerMobile'
import PortalInvisibleWrapper from '../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import ShowroomCarGalleryImage from './showroomCarGalleryImage'

const MobileSectionWrapper = styled.div`
    padding-right: 1.5rem;
    padding-left: 1.5rem;
`

const ShowroomModalInnerContainer = styled.div`
    height: max-content;
    width: max-content;
    background-color: var(--bg-color, #fff);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 2px;

    @media ${device.mobile_and_ipad} {
        width: 95vw;
    }

    @media ${device.beyond_ipad_mobile} {
        min-width: 500px;
    }

    z-index: 20;
    transition: all 100ms ease-in-out;
    padding: 24px;
`

type EntryParams = {
    entryid: string
}

const ShowroomCarGallery: React.FunctionComponent<RouteComponentProps> = (
    props: RouteComponentProps
) => {
    const { entryid } = useParams<EntryParams>()

    // redux hook for dispatch state actions
    const dispatch = useAppDispatch()

    /* 
    remove top sub menu from state
    TODO: (only for useSelector hook) need to gradually move to "createSelector" approach, for memoization / huge performance impact
    */
    const removeTopSubMenu = (status: IManageTopSubmenuSuccessArg) =>
        dispatch(menuActions.manageTopSubmenu(status))

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let normalisedPublicEntries: INormalisedShowroomEntries | undefined =
        useAppSelector((state) => {
            return state.showroom.normalisedPublicEntries
        })

    let isNecessaryGalleryDataLoading = useAppSelector(
        (state) => state.showroom.loading
    )

    let currentEntry: IShowroomEntry | undefined =
        entryid && normalisedPublicEntries && normalisedPublicEntries[entryid]
            ? normalisedPublicEntries[entryid]
            : undefined

    // let carProfile: ICar | undefined = currentEntry
    //     ? currentEntry.car
    //     : undefined

    useEffect(() => {
        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        if (userLoggedIn) {
            dispatch(
                showroomActions.getShowroomEntryByIDRequest({
                    is_owner: false,
                    entryID: entryid,
                })
            )
        } else if (!userLoggedIn && refresh_token !== null) {
            dispatch(getCurrentUserDataRequest())
            dispatch(
                showroomActions.getShowroomEntryByIDRequest({
                    is_owner: false,
                    entryID: entryid,
                })
            )
        } else {
            dispatch(showroomActions.getPublicShowroomEntryByIDRequest(entryid))
        }

        // trigger the function to dispatch the top sub menu remove action
        removeTopSubMenu({ isRemoved: true })
        // get current car profile image

        // set up nav back as before on page unmount
        return () => {
            removeTopSubMenu({ isRemoved: false })
        }
    }, [])

    let images_data: IGalleryImagesObject | null = useAppSelector((state) => {
        return state.entities.galleriesData.images
    })

    let carGalleryImages: IGalleryImage[] =
        currentEntry && currentEntry.gallery_images
            ? currentEntry.gallery_images
            : []

    let images_ids: string[] = carGalleryImages.map((image) => image.id)

    const [isShareOpen, setIsShareOpen] = useState(false)

    const params = new URLSearchParams(props.location.search)

    return (
        <>
            <DesktopDisplayOnly>
                <ModalDisplayFullPageDesktopExclusive
                    isOpen={isShareOpen}
                    toggle={() => {
                        setIsShareOpen(false)
                        // setModalOpened(null)
                    }}
                >
                    <ShowroomModalInnerContainer>
                        <CarShowroomEntryShareModal
                            entryid={entryid}
                            close={() => {
                                setIsShareOpen(false)
                            }}
                        />
                    </ShowroomModalInnerContainer>
                </ModalDisplayFullPageDesktopExclusive>
                <div style={{ paddingTop: '5em' }} />
                {!params.get('id') && (
                    <CarShowroomTopBar
                        onClickEnquire={() => {
                            props.history.push(`/showroom/${entryid}/enquiry`)
                        }}
                        isMobile={false}
                        entryID={entryid}
                        isReadOnly={true}
                        is_watched={currentEntry?.is_watched ? true : false}
                        onClickShare={() => {
                            setIsShareOpen(true)
                        }}
                    />
                )}
                <CarGalleryManagerDesktop
                    ids_list={images_ids}
                    openImgForm={() => {}}
                    imagesData={images_data}
                    isNecessaryGalleryDataLoading={
                        isNecessaryGalleryDataLoading
                    }
                    readOnlyMode
                />
                <div style={{ paddingTop: '300px' }} />
            </DesktopDisplayOnly>

            {!params.get('id') && (
                <IpadAndMobileDisplay>
                    <ModalDisplayMobileIpad
                        isOpen={isShareOpen}
                        toggle={() => {
                            setIsShareOpen(false)
                        }}
                    >
                        <ShowroomModalInnerContainer>
                            <CarShowroomEntryShareModal
                                entryid={entryid}
                                close={() => {
                                    setIsShareOpen(false)
                                }}
                            />
                        </ShowroomModalInnerContainer>
                    </ModalDisplayMobileIpad>
                    <div style={{ paddingTop: 63 }} />
                    <CarShowroomTopBar
                        isMobile
                        onClickEnquire={() => {
                            props.history.push(`/showroom/${entryid}/enquiry`)
                        }}
                        entryID={entryid}
                        isReadOnly={true}
                        is_watched={currentEntry?.is_watched ? true : false}
                        onClickShare={() => {
                            setIsShareOpen(true)
                        }}
                    />
                    <CarGalleryManagerMobile
                        ids_list={images_ids}
                        openImgForm={() => {}}
                        imagesData={images_data}
                        isNecessaryGalleryDataLoading={
                            isNecessaryGalleryDataLoading
                        }
                        isGettingCarDataLoading={isNecessaryGalleryDataLoading}
                        readOnlyMode
                    />

                    <MobileSectionWrapper>
                        <div style={{ paddingTop: '48px' }} />
                    </MobileSectionWrapper>
                </IpadAndMobileDisplay>
            )}
            {params.get('id') && (
                <PortalInvisibleWrapper
                    onClick={() => {
                        props.history.push(props.location.pathname)
                    }}
                >
                    <ShowroomCarGalleryImage />
                </PortalInvisibleWrapper>
            )}
        </>
    )
}

export default withRouter(ShowroomCarGallery)
