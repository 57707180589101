import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

export default function LightThemeIcon({ size, color }: Props) {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="4.75"
                y="4.75"
                width="6.5"
                height="6.5"
                rx="3.25"
                stroke={color ?? colours[theme].icon}
                stroke-width="1.5"
            />
            <rect
                x="7.25"
                width="1.5"
                height="3"
                rx="0.75"
                fill={color ?? colours[theme].icon}
            />
            <rect
                x="7.25"
                y="13"
                width="1.5"
                height="3"
                rx="0.75"
                fill={color ?? colours[theme].icon}
            />
            <rect
                x="16"
                y="7.25"
                width="1.5"
                height="3"
                rx="0.75"
                transform="rotate(90 16 7.25)"
                fill={color ?? colours[theme].icon}
            />
            <rect
                x="3"
                y="7.25"
                width="1.5"
                height="3"
                rx="0.75"
                transform="rotate(90 3 7.25)"
                fill={color ?? colours[theme].icon}
            />
            <rect
                x="14.187"
                y="13.127"
                width="1.5"
                height="3"
                rx="0.75"
                transform="rotate(135 14.187 13.127)"
                fill={color ?? colours[theme].icon}
            />
            <rect
                x="4.99463"
                y="3.93457"
                width="1.5"
                height="3"
                rx="0.75"
                transform="rotate(135 4.99463 3.93457)"
                fill={color ?? colours[theme].icon}
            />
            <rect
                x="2.87354"
                y="14.1875"
                width="1.5"
                height="3"
                rx="0.75"
                transform="rotate(-135 2.87354 14.1875)"
                fill={color ?? colours[theme].icon}
            />
            <rect
                x="12.0659"
                y="4.99512"
                width="1.5"
                height="3"
                rx="0.75"
                transform="rotate(-135 12.0659 4.99512)"
                fill={color ?? colours[theme].icon}
            />
        </svg>
    )
}
