import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_thick_chevron from '../../../public/assets/icons/grey_thick_chevron.svg'

type Props = {
    children?: React.ReactNode
}

type LocalState = {}

const Wrapper = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 6px;
`

const StyledContent = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }

    color: var(--text-strong, #1a1a1a);
    font-family: Lato-Light;
    font-size: 18px;
    padding-left: 12px;

    transform: translateY(-10px);
`
const StyledSvg = styled.img`
    transform: rotate(90deg);
    width: 11px;
`

class CarDeletionListItemMobile extends React.Component<Props, LocalState> {
    state = {}

    render() {
        let { children } = this.props
        return (
            <Wrapper>
                <StyledSvg src={grey_thick_chevron} alt="chevron" />

                <StyledContent>{children}</StyledContent>
            </Wrapper>
        )
    }
}

export default CarDeletionListItemMobile
