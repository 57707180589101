import { IApexHomepageArticleState } from 'apexModels'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LoaderBackground from '../../../../atoms/loader/loaderBackground'
import { device } from '../../../../templates/displays/devices'

const WrapperofWrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        display: flex;
        flex-direction: column;
        /* width: 100vw; */
        width: 100%;
        align-items: center;
        justify-content: center;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: flex-start;
    justify-content: flex-start;
`

const ImgStyle = styled.div`
    height: 224px;

    @media (max-width: 330px) {
        height: 175px;
    }

    width: 100%;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    flex-direction: column;
    border-radius: 10px;

    @media (min-width: 500px) {
        height: 300px;
    }
`
const Title = styled(Link)`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 10px;

    @media (min-width: 500px) {
        font-size: 17px;
    }
`

const Text = styled.div`
    line-height: 23px;
    font-family: Lato-Light;
    font-size: 14px;
    color: var(--text-strong, #1a1a1a);

    @media (min-width: 500px) {
        font-size: 15px;
        line-height: 26px;
    }
`

const ReadMoreText = styled(Link)`
    color: var(--primary, #5ec3ca);
    font-family: Lato-Light;
    padding-top: 10px;
    font-size: 14px;
`

type Props = {
    article: IApexHomepageArticleState | undefined
}

class ApexHomepageArticleMobile extends React.Component<Props, {}> {
    render() {
        let { article } = this.props
        return (
            <>
                {!article && (
                    <LoaderBackground height={'400px'} width={'100vw'} />
                )}
                {article && (
                    <WrapperofWrapper key={article.uid}>
                        <Wrapper>
                            <Link
                                to={`/apex/articles/${article.uid}`}
                                style={{ width: '100%' }}
                            >
                                <ImgStyle
                                    role="img"
                                    aria-label="fullwidth-picture"
                                    style={{
                                        backgroundImage: `url(${
                                            article.img ? article.img.url : ''
                                        })`,
                                    }}
                                />
                            </Link>
                            <Title to={`/apex/articles/${article.uid}`}>
                                {article.title}
                            </Title>
                            <Text>{article.introduction}</Text>
                            <ReadMoreText to={`/apex/articles/${article.uid}`}>
                                Read More
                            </ReadMoreText>
                        </Wrapper>
                    </WrapperofWrapper>
                )}
            </>
        )
    }
}

export default ApexHomepageArticleMobile
