import React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'

const WrapContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 16px;
    padding-right: 30px;
`

const Wrap = styled.div`
    width: 92%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const Step = styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 14px;
    padding-bottom: 14px;

    @media ${device.ipad} {
        align-items: center;
    }

    @media (max-width: 325px) {
    }
`

const MilestoneIcon = styled.img`
    height: 45px;
    width: 45px;
    margin-top: 2px;
    @media (max-width: 325px) {
        height: 42px;
    }
`

const Text = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato';
    font-size: 14px;
    line-height: 20px;
    @media (max-width: 600px) {
        max-width: 354px;
    }
`

interface IMilestoneProps {
    id: string
    text: string
    icon: string
    icon_dark: string
}

export interface IRoadmapProps {
    milestoneList: IMilestoneProps[]
}

const RoadmapMobile: React.SFC<IRoadmapProps> = (props) => {
    const { theme } = useThemes()
    return (
        <WrapContainer>
            <Wrap>
                {props.milestoneList.map((item, i) => (
                    <Step key={`${item.id}-${i}-mobile`} id={item.id}>
                        <div style={{ width: '65px', paddingRight: '20px' }}>
                            <MilestoneIcon
                                src={
                                    theme === 'dark'
                                        ? item.icon_dark
                                        : item.icon
                                }
                            />
                        </div>
                        <Text>{item.text}</Text>
                    </Step>
                ))}
            </Wrap>
        </WrapContainer>
    )
}

export default RoadmapMobile
