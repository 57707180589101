import { IExternalURLApi } from 'IapiDataPayload'
import { IUser } from 'myModels'
import { useState } from 'react'
import styled from 'styled-components'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import { updateUserGeneralInfoRequest } from '../../../../../redux/user/actions/loadingActions'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import InputFieldNoFormikAnimated from '../../../../atoms/Inputfield/inputFieldNoFormikAnimated'
import { OwnerShowroomFormActions } from '../../../../organisms/showroom/contactForm/ownerContactFormDesktop'
import { device } from '../../../../templates/displays/devices'

const Wrapper = styled.div`
    @media ${device.mobile_and_ipad} {
        width: 100%;
        height: 100%;
    }

    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

type ISocialMediaFieldNames =
    | 'WEBSITE'
    | 'FACEBOOK'
    | 'LINKEDIN'
    | 'INSTAGRAM'
    | 'TWITTER'

export type ISocialMediaFormNames = {
    [key in ISocialMediaFieldNames]: string
}

const names: ISocialMediaFormNames = {
    WEBSITE: 'website link',
    FACEBOOK: 'facebook',
    LINKEDIN: 'linkedin',
    INSTAGRAM: 'instagram',
    TWITTER: 'twitter',
}

const placeholders: any = {
    WEBSITE: 'https://www.dealer_dealing_deals.com',
    FACEBOOK: 'https://www.facebook.com/dealer/',
    LINKEDIN: 'https://www.linkedin.com/dealer/',
    INSTAGRAM: 'https://www.instagram.com/dealer/',
    TWITTER: 'https://www.twitter.com/dealer/',
}

type Props = {
    close: () => any
    hasSMChange: boolean
    setHasSMChange: (p: boolean) => any
}

const ShowroomOwnerSocialMediaFormMobile = (props: Props) => {
    let userData: IUser | null = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const [socialMedia, setSocialMedia] = useState<ISocialMediaFormNames>({
        WEBSITE: userData?.external_urls?.WEBSITE
            ? userData.external_urls.WEBSITE.url
            : '',
        FACEBOOK: userData?.external_urls?.FACEBOOK
            ? userData.external_urls.FACEBOOK.url
            : '',
        LINKEDIN: userData?.external_urls?.LINKEDIN
            ? userData.external_urls.LINKEDIN.url
            : '',
        INSTAGRAM: userData?.external_urls?.INSTAGRAM
            ? userData.external_urls.INSTAGRAM.url
            : '',
        TWITTER: userData?.external_urls?.TWITTER
            ? userData.external_urls.TWITTER.url
            : '',
    })

    let dispatch = useAppDispatch()

    const { hasSMChange, setHasSMChange } = props

    let onSubmit = () => {
        if (userData && userData.id) {
            let data1: IExternalURLApi[] = []
            // @ts-ignore
            Object.keys(names).forEach((key: ISocialMediaFieldNames) => {
                let currUrl =
                    userData &&
                    userData.external_urls &&
                    userData.external_urls[key] &&
                    userData.external_urls[key]!.url
                        ? userData.external_urls[key]!.url
                        : 'nope'

                if (
                    currUrl !== socialMedia[key] &&
                    socialMedia[key] !== '' &&
                    socialMedia[key] &&
                    socialMedia[key].length > 0
                ) {
                    data1 = [
                        ...data1,
                        {
                            url: socialMedia[key],
                            uid:
                                userData &&
                                userData.external_urls &&
                                userData.external_urls[key] &&
                                userData.external_urls[key]!.uid
                                    ? userData.external_urls[key]!.uid
                                    : 'new',
                            url_type: key,
                        },
                    ]
                }
            })

            if (data1.length > 0) {
                dispatch(
                    updateUserGeneralInfoRequest({
                        id: userData.id,
                        external_urls: data1,
                    })
                )
            }

            setHasSMChange(false)
        }
    }

    return (
        <Wrapper>
            {Object.keys(names).map(
                // @ts-ignore
                (key: ISocialMediaFieldNames) => {
                    return (
                        <div
                            style={{
                                paddingTop: '20px',
                                width: '100%',
                            }}
                        >
                            <InputFieldNoFormikAnimated
                                value={socialMedia[key]}
                                name={names[key]}
                                placeholder={placeholders[key] ?? undefined}
                                type={'text'}
                                onChange={(e: any) => {
                                    if (!hasSMChange) {
                                        setHasSMChange(true)
                                    }
                                    setSocialMedia({
                                        ...socialMedia,
                                        [key]: e.target.value,
                                    })
                                }}
                                placeholderStyle={'text-transform: none;'}
                            />
                        </div>
                    )
                }
            )}

            <div style={{ paddingTop: '100px' }} />

            <OwnerShowroomFormActions>
                <ButtonAtom
                    theme="capitalize-white-background"
                    width="74px"
                    height="48px"
                    dataCyId={'showroom-car-share'}
                    icon={''}
                    fontSize={16}
                    onClick={() => {
                        props.close()
                    }}
                >
                    <div style={{}}>Close</div>
                </ButtonAtom>

                <ButtonAtom
                    theme="secondary"
                    width="220px"
                    height="48px"
                    textTransform="capitalize"
                    dataCyId={'showroom-car-enquire'}
                    onClick={() => {
                        onSubmit()
                    }}
                    fontSize={16}
                    disabled={!hasSMChange ? true : false}
                >
                    Save Social Media Details
                </ButtonAtom>
            </OwnerShowroomFormActions>

            <div style={{ paddingTop: '20px' }} />
        </Wrapper>
    )
}

export default ShowroomOwnerSocialMediaFormMobile
