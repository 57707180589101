import {
    IAddressInsuranceNormalised,
    IAnyObject,
} from '../../insuranceQuoteApplication/reducer'
import { IInsuranceAddress_API } from '../../insuranceQuoteApplication/types'
import { gen_draft_differing_values_to_patch } from './convertDraftToAPIPatchObject'

type Igen_address_insurance_entity_patch_arg = {
    data: IAnyObject
    addresses_fields: string[]
    draft_addresses_normalised: IAddressInsuranceNormalised
}

const pattern = /^new_address/

type IcompareTwoAddresses_insurance_arg = {
    draft: IInsuranceAddress_API
    existing: IInsuranceAddress_API
}

export const compareTwoAddresses_insurance = (
    p: IcompareTwoAddresses_insurance_arg
) => {
    return gen_draft_differing_values_to_patch(p)
}

export const gen_address_insurance_entity_patch = (
    p: Igen_address_insurance_entity_patch_arg
) => {
    let dataToSendToPatch = { ...p.data }

    p.addresses_fields.forEach((field_id: string) => {
        if (dataToSendToPatch && dataToSendToPatch[field_id]) {
            let newAddressData = p.draft_addresses_normalised[
                dataToSendToPatch[field_id]
            ]
                ? {
                      ...p.draft_addresses_normalised[
                          dataToSendToPatch[field_id]
                      ],
                  }
                : undefined

            // console.log(
            //     'newAddressData',
            //     newAddressData,
            //     pattern.test(`${newAddressData?.uid}`)
            // )

            if (newAddressData) {
                if (pattern.test(`${newAddressData.uid}`)) {
                    // console.log('inside of 36')
                    // new address data in
                    delete newAddressData['uid']

                    {
                        dataToSendToPatch = {
                            ...dataToSendToPatch,
                            [field_id]: [
                                {
                                    ...newAddressData,
                                },
                            ],
                        }
                    }
                } else {
                    // just existing address UID
                    dataToSendToPatch = {
                        ...dataToSendToPatch,
                        [field_id]: [
                            {
                                uid: dataToSendToPatch[field_id],
                            },
                        ],
                    }
                }
            }
        }
    })

    return { ...dataToSendToPatch }
}
