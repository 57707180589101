import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'

type StyleProps = {
    isBigger?: boolean
    align?: string
    themedStyle: 'lightest' | 'default'
    removeIpadStyle?: boolean
}

const H1Styled = styled.h1<StyleProps>`
    font-family: Lato-Light;
    text-transform: ${(props) =>
        props.themedStyle === 'lightest' ? 'capitalize' : 'uppercase'};
    font-weight: 200;
    color: ${(props) =>
        props.themedStyle === 'lightest'
            ? 'var(--text-strong, #1a1a1a)'
            : 'var(--text-default, #666)'};
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    @media ${device.mobile} {
        font-size: ${(props) => (props.isBigger ? '1.2rem' : '12px')};
        padding-top: 5px;
    }
    @media ${device.ipad} {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 10px;
        text-align: ${(props) => (props.removeIpadStyle ? 'left' : 'center')};
    }

    @media ${device.desktop} {
        font-size: 21px;
        padding-top: 15px;
        padding-bottom: 10px;
        text-align: ${(props) => (props.align === 'left' ? 'left' : 'center')};
    }
`

export interface IHeaderProps {
    children?: React.ReactNode
    isBigger?: boolean
    align?: string
    themedStyle?: 'lightest' | 'default'
    removeIpadStyle?: boolean
}

const LightTitle: React.SFC<IHeaderProps> = (props) => (
    <H1Styled
        isBigger={props.isBigger && props.isBigger}
        align={props.align && props.align}
        themedStyle={props.themedStyle ? 'lightest' : 'default'}
        removeIpadStyle={props.removeIpadStyle && props.removeIpadStyle}
    >
        {props.children}
    </H1Styled>
)
LightTitle.defaultProps = {}
export default LightTitle
