import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    dataCyId?: string
    size?: string
    color?: string
}

const SortIcon = (props: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.size ?? '16'}
            height={props.size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.9999 11.9393L14.4696 9.46967L15.5303 10.5303L11.9999 14.0607L8.46961 10.5303L9.53027 9.46967L11.9999 11.9393Z"
                fill={props.color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.75 3V13H11.25V3H12.75Z"
                fill={props.color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.75 3V13H3.25V3H4.75Z"
                fill={props.color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.00006 4.06066L1.53039 6.53033L0.469727 5.46967L4.00006 1.93934L7.53039 5.46967L6.46973 6.53033L4.00006 4.06066Z"
                fill={props.color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default SortIcon
