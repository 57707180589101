import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_OtherDriver,
    insuranceActions,
} from './../../reducer'

import { gen_draft_differing_values_to_patch } from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'
import {
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import { ValidateAndAddStatsToApplication } from '../application/get'
import { IInsuranceApplication_API } from '../../types'
import posthog from 'posthog-js'

export type IDriverBasicDetailsSumitPayload = {
    driver_id: string
    autoSelect?: boolean
}

export function* Submit_otherdriver_basicDetails(
    p: IDriverBasicDetailsSumitPayload
): any {
    posthog.capture('Update insurance application named driver basic details')

    const { driver_id, autoSelect } = p
    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft: IInsuranceQuoteApplication_OtherDriver = {
            ...all_draft.other_drivers[driver_id],
        }
        let existing: IInsuranceQuoteApplication_OtherDriver = {
            ...all_existing.other_drivers[driver_id],
        }

        let dataToSendToPatch: IAnyObject | undefined = undefined

        if (draft.basic_details) {
            dataToSendToPatch = gen_draft_differing_values_to_patch({
                existing: existing.basic_details ?? {},
                draft: draft.basic_details ?? {},
            })
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            if (dataToSendToPatch['title']) {
                const titleSaved = dataToSendToPatch['title']
                delete dataToSendToPatch['title']

                dataToSendToPatch = {
                    ...dataToSendToPatch,
                    title_uid: titleSaved,
                }
            }

            // call api to patch as thats all the data merged we want to patch

            const pattern = /^new_driver/

            if (pattern.test(`${driver_id}`)) {
                delete dataToSendToPatch['uid']
            } else {
                dataToSendToPatch = {
                    uid: driver_id,
                    ...dataToSendToPatch,
                }
            }

            // check if 4 ppl selected or not

            dataToSendToPatch = {
                customer: {
                    named_drivers: [
                        {
                            ...dataToSendToPatch,
                        },
                    ],
                },
            }

            if (Object.keys(dataToSendToPatch).length > 0) {
                let ress: IInsuranceApplication_API | undefined = yield call(
                    api.insuranceApplication.patchInsuranceApplication,
                    {
                        id: `${all_existing.id}`,
                        data: { ...dataToSendToPatch },
                    }
                )

                if (!autoSelect && ress) {
                    let applicationReducerDataWithStats = yield call(
                        ValidateAndAddStatsToApplication,
                        `${all_existing.id}`,
                        ress
                    )

                    yield put(
                        insuranceActions.submit_otherDriver_basicDetails_success(
                            applicationReducerDataWithStats
                        )
                    )
                }

                // auto selection on new driver

                if (
                    autoSelect &&
                    all_existing.selected_named_drivers &&
                    all_existing.selected_named_drivers.length < 4
                ) {
                    dataToSendToPatch = {
                        ...dataToSendToPatch,
                        application_named_drivers: [{ uid: driver_id }],
                    }

                    let previous_all_drivers_ids = all_existing.other_drivers
                        ? Object.keys(all_existing.other_drivers)
                        : []
                    let new_driver_uid: string | undefined

                    if (
                        ress?.customer?.named_drivers &&
                        ress?.customer?.named_drivers.length > 0
                    ) {
                        for (
                            let i = 0;
                            i < ress?.customer?.named_drivers.length;
                            i++
                        ) {
                            if (ress?.customer?.named_drivers[i]?.uid) {
                                if (
                                    previous_all_drivers_ids.indexOf(
                                        `${ress?.customer?.named_drivers[i]?.uid}`
                                    ) === -1
                                ) {
                                    new_driver_uid = `${ress?.customer?.named_drivers[i]?.uid}`
                                }
                            }
                        }
                    }

                    if (new_driver_uid) {
                        let otherDataToSendToPatch = {
                            application_named_drivers: [
                                { uid: new_driver_uid },
                            ],
                        }

                        let res = yield call(
                            api.insuranceApplication.patchInsuranceApplication,
                            {
                                id: `${all_existing.id}`,
                                data: { ...otherDataToSendToPatch },
                            }
                        )

                        let applicationReducerDataWithStats = yield call(
                            ValidateAndAddStatsToApplication,
                            `${all_existing.id}`,
                            res
                        )

                        yield put(
                            insuranceActions.submit_otherDriver_basicDetails_success(
                                applicationReducerDataWithStats
                            )
                        )
                        return
                    }
                }
            } else
                yield put(
                    insuranceActions.submit_otherDriver_basicDetails_success()
                )
        } else
            yield put(
                insuranceActions.submit_otherDriver_basicDetails_success()
            )
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_otherdriver_basicDetails,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/other_driver/${p.driver_id}/basic_details`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_otherDriver_basicDetails_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_otherdriver_basicDetails() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_otherDriver_basicDetails_request
        )
        yield call(Submit_otherdriver_basicDetails, payload)
    }
}

const otherdriver_basicDetails_submit: any[] = [
    fork(Watcher_Submit_otherdriver_basicDetails),
]

export default otherdriver_basicDetails_submit
