import { device } from '../../templates/displays/devices'
import { Form } from 'formik'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const FormContainer = styled(Form)`
    width: 100%;
    display: flex;
    @media ${device.desktop} {
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }

    @media ${device.mobile} {
        /* margin-top: 15px; */

        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
`
export const FormContainerNoFormik = styled.form`
    width: 100%;
    display: flex;
    @media ${device.desktop} {
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }

    @media ${device.mobile} {
        /* margin-top: 15px; */

        flex-direction: column;
        justify-content: center;
        align-items: start;
    }
`

export const BottomSection = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-family: Lato;
    transform: translateY(-1px);
    font-weight: 200;
    padding-top: 30px;
    color: var(--text-default, #666);

    @media ${device.mobile} {
        font-size: 12px;
        padding-top: 40px;
    }
`

export const BottomSectionSignIn = styled(BottomSection)`
    padding-top: 40px;

    @media ${device.ipad} {
        padding-top: 40px;
    }
    @media ${device.mobile} {
        padding-top: 20px;
    }
`

export const LinksSection = styled.section`
    @media ${device.desktop} {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: start;
    }

    @media ${device.ipad} {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: start;
    }

    @media ${device.mobile} {
        padding-right: 10vw;
        padding-top: 30px;
    }
`

export const Padding = styled.div`
    width: 100%;
    @media ${device.mobile} {
        margin-top: 10px;
    }

    @media ${device.desktop} {
        margin-top: 5px;
    }
    @media ${device.large_desktop} {
        margin-top: 10px;
    }
`

export const CheckboxSection = styled.div`
    width: 100%;
    padding-top: 10px;
`

export const AlreadyRegistered = styled.div`
    padding-top: 30px;
    font-size: 14px;

    @media ${device.desktop} {
        font-size: 11px;
    }
    @media ${device.mobile} {
        font-size: 12px;
    }
    @media ${device.large_desktop} {
        font-size: 14px;
    }
`

export const CheckboxRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;

    @media ${device.mobile} {
        padding-top: 15px;
    }

    @media ${device.ipad} {
        padding-top: 15px;
    }

    @media ${device.desktop} {
        padding-top: 15px;
    }

    @media ${device.large_desktop} {
        padding-top: 15px;
    }
`

export const CheckboxText = styled.div`
    width: 100%;
    font-family: Lato;
    color: var(--text-darker, #616161);
    padding-left: 5px;
    font-size: 14px;
    @media ${device.mobile} {
        font-size: 14px;
    }

    @media ${device.desktop} {
        font-size: 11px;
    }

    @media ${device.large_desktop} {
        transform: translateY(1px);
        font-size: 14px;
    }
`
export const LinkedText = styled.a`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
`

export const LinkedTextTo = styled(Link)`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
`

export const BlueSpan = styled.span`
    font-family: Lato;
    color: var(--primary, #5ec3ca);
`

export const NotRegisteredDiv = styled.section`
    font-family: Lato;
    transform: translateY(-1px);
    font-weight: 200;
    text-align: right;

    @media ${device.mobile} {
        font-size: 12px;
        color: var(--text-darker, #616161);
        padding-top: 20px;
    }

    @media ${device.ipad} {
        transform: translateY(-1px);
        padding-top: 20px;
        color: var(--text-darker, #616161);
        font-weight: 200;
    }

    @media ${device.desktop} {
        font-size: 12px;
        transform: translateY(-1px);
        padding-top: 20px;
        color: var(--text-darker, #616161);
        font-weight: 200;
    }

    @media ${device.large_desktop} {
        padding-top: 30px;
        font-size: 13px;
    }
`

export const ForgotPasswordDiv = styled.section`
    font-family: Lato;
    transform: translateY(-1px);
    text-align: right;

    font-weight: 200;
    @media ${device.desktop} {
        transform: translateY(-1px);
        margin-top: 50px;
        color: var(--text-darker, #616161);
        font-size: 10px;
        font-weight: 200;
    }

    @media ${device.ipad} {
        transform: translateY(-1px);
        margin-top: 50px;
        color: grey;
        font-weight: 200;
    }

    @media ${device.mobile} {
        font-size: 12px;
        color: grey;
        margin-top: 20px;
        align-self: end !important;
    }
`

export const ApplyDiv = styled.section`
    font-family: Lato;
    @media ${device.desktop} {
        font-size: 15px;
        color: var(--primary, #5ec3ca);
        margin-top: 5px;
        font-weight: 200;
    }

    @media ${device.ipad} {
        font-size: 15px;
        color: var(--primary, #5ec3ca);
        margin-top: 5px;
        font-weight: 200;
    }

    @media ${device.mobile} {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-items: flex-start;
        line-height: 1.2;
        margin-top: 5px;
        font-size: 15px;
        color: var(--primary, #5ec3ca);
    }
`
export const Divider = styled.div`
    @media ${device.desktop} {
        margin-top: 50px;
    }

    @media ${device.ipad} {
        margin-top: 50px;
    }
    @media ${device.mobile} {
        margin-top: 40px;
    }
`
