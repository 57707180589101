import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const ServiceIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.01159 0.25H9.98842L10.2946 2.5932C10.7999 2.79298 11.2755 3.05785 11.7081 3.38105L13.9819 2.46307L15.9983 5.80501L14.0175 7.24022C14.0521 7.49143 14.0697 7.74477 14.0702 7.99862V8.00138C14.0697 8.25523 14.0521 8.50857 14.0175 8.75978L15.9983 10.195L13.9819 13.5369L11.7081 12.619C11.2755 12.9422 10.7999 13.207 10.2946 13.4068L9.98842 15.75H6.01159L5.70541 13.4068C5.20009 13.207 4.72451 12.9422 4.29195 12.619L2.0181 13.5369L0.00167859 10.195L1.98194 8.76018C1.91226 8.25562 1.91226 7.74438 1.98194 7.23982L0.00167847 5.80501L2.0181 2.46307L4.29195 3.38105C4.72451 3.05785 5.2001 2.79298 5.70541 2.5932L6.01159 0.25ZM7.32834 1.75L7.07048 3.72343L6.60906 3.86482C5.97754 4.05833 5.39979 4.37991 4.91485 4.80539L4.5677 5.10999L2.64199 4.33257L1.99833 5.39935L3.64815 6.59474L3.53539 7.07975C3.39445 7.68596 3.39445 8.31404 3.53539 8.92025L3.64815 9.40526L1.99833 10.6007L2.64199 11.6674L4.5677 10.89L4.91485 11.1946C5.39979 11.6201 5.97754 11.9417 6.60906 12.1352L7.07048 12.2766L7.32834 14.25H8.67167L8.92953 12.2766L9.39096 12.1352C10.0225 11.9417 10.6002 11.6201 11.0852 11.1946L11.4323 10.89L13.358 11.6674L14.0017 10.6006L12.3528 9.40594L12.4643 8.92181C12.534 8.61887 12.5695 8.30985 12.5702 8C12.5695 7.69015 12.534 7.38113 12.4643 7.07819L12.3528 6.59406L14.0017 5.39935L13.358 4.33257L11.4323 5.10999L11.0852 4.80539C10.6002 4.37991 10.0225 4.05833 9.39096 3.86482L8.92953 3.72343L8.67167 1.75H7.32834Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 7C7.44772 7 7 7.44772 7 8C7 8.55228 7.44772 9 8 9C8.55228 9 9 8.55228 9 8C9 7.44772 8.55228 7 8 7ZM5.5 8C5.5 6.61929 6.61929 5.5 8 5.5C9.38071 5.5 10.5 6.61929 10.5 8C10.5 9.38071 9.38071 10.5 8 10.5C6.61929 10.5 5.5 9.38071 5.5 8Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default ServiceIcon
