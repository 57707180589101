import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import AddACarFormOnboarding from '../../../organisms/editForms/addACarForm/onboarding/addACarFormOnboarding'
import { customNavDataActions } from '../../../../redux/localdata/customNav/reducer'
import { dropDownActions } from '../../../../redux/localdata/dropdownData/reducer'
import { getCurrentUserDataRequest } from '../../../../redux/user/actions/loadingActions'
import Loader from '../../../atoms/loader/loader'
import SideSliderWithExit from '../../../templates/animated/sideSliderWithExit'
import OnboardingFormLayoutMobile, {
    OnboardingText,
    PaddingUnit,
} from '../../../templates/onboarding/onboardingFormLayoutMobile'
import { isIOS } from 'react-device-detect'
import { useHistory } from 'react-router'
import useWindowSize from '../../../templates/displays/windowSizeHook'
import OnboardingLayoutDesktop from '../../../templates/onboarding/desktop/onboardingLayoutDesktop'
import OnboardingFormTemplateDesktop from '../../../templates/onboarding/desktop/onboardingFormTemplateDesktop'
import { checkIfOnlyCharsAndNumbersAndSpaces } from '../../../../helpers/strings/regexChecks'

const OnboardingStep_1 = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()

    let windowHeight = useWindowSize().height

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    let userLoading = useAppSelector((state) => state.user.loading)
    let onboardingStep1Data = useAppSelector(
        (state) => state.localdata.onboardingContent.content[1]
    )
    let countriesDataLoading = useAppSelector(
        (state) => state.localdata.dropdownData.loading
    )
    let countriesdropdownlist = useAppSelector(
        (state) => state.localdata.dropdownData.countriesCode
    )

    let selectedCountry = useAppSelector(
        (state) => state.localdata.dropdownData.selectedCountry
    )

    let carsDataLoading = useAppSelector(
        (state) => state.entities.carsData.loading
    )

    let garageDataLoading = useAppSelector(
        (state) => state.entities.garagesData.loading
    )

    let garageid = userLoggedIn?.owns_garage?.uid

    useEffect(() => {
        dispatch(
            customNavDataActions.setInitialLandingUrl('/onboarding-step-1')
        )
    }, [])

    useEffect(() => {
        if (userLoggedIn) {
            dispatch(dropDownActions.reset_make_model_temps())
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
            dispatch(dropDownActions.setMakeDropdownDataRequest())
        } else {
            dispatch(getCurrentUserDataRequest())
        }
    }, [userLoggedIn])

    const [registrationNumber, setRegistrationNumber] = useState<
        string | undefined
    >(undefined)

    const submit = () => {
        if (garageid) {
            let countryy = selectedCountry ?? 'United Kingdom'
            // let p: IExternalCarDataRequestPayloadVersion2 = {
            //     // garage_id: garageid,
            //     registration_number:
            //         registrationNumber && registrationNumber !== ''
            //             ? registrationNumber
            //             : undefined,
            //     country: selectedCountry ?? 'United Kingdom',
            //     isOnboarding: true,
            //     // numberOfCarsInGarage: 0,
            // }

            if (
                registrationNumber &&
                registrationNumber !== '' &&
                countryy === 'United Kingdom'
            ) {
                history.push(
                    `/onboarding-step-2-2?carid=new_car&from_ves_api=true&reg_no=${registrationNumber}&country=${countryy}`
                )
                return
            } else if (registrationNumber && registrationNumber !== '') {
                history.push(
                    `/onboarding-step-2-2?carid=new_car&from_ves_api=false&reg_no=${registrationNumber}&country=${countryy}`
                )
            } else {
                history.push(
                    `/onboarding-step-2-1?carid=new_car&from_ves_api=false&country=${countryy}`
                )
            }

            // dispatch(getExternalCarDataByRegistrationNumberRequestVersion2(p))
        } else {
        }
    }

    return userLoading || countriesDataLoading ? (
        <Loader isLoading />
    ) : (
        <React.Fragment>
            <DesktopDisplayOnly>
                <OnboardingLayoutDesktop
                    step={1}
                    title={onboardingStep1Data.title}
                    whiteTextTitle={onboardingStep1Data.desktopRightSideTitle}
                    whiteTextSubTitle={
                        onboardingStep1Data.desktopRightSideSubtitle
                    }
                >
                    {countriesdropdownlist && garageid && (
                        <OnboardingFormTemplateDesktop
                            isBtnLoading={
                                garageDataLoading || carsDataLoading
                                    ? true
                                    : false
                            }
                            submit={submit}
                            submitDisabled={
                                registrationNumber
                                    ? checkIfOnlyCharsAndNumbersAndSpaces(
                                          registrationNumber
                                      )
                                        ? false
                                        : true
                                    : false
                            }
                            skip={() => {
                                dispatch(
                                    dropDownActions.setSelectedCountry(null)
                                )
                                setTimeout(function () {
                                    history.push(
                                        `/garage?native_app_prompt=true`
                                    )
                                }, 200)
                            }}
                        >
                            <PaddingUnit $times={4} />
                            <OnboardingText>
                                {onboardingStep1Data.text}
                            </OnboardingText>
                            <PaddingUnit $times={5} />
                            <AddACarFormOnboarding
                                isDesktop
                                countriesdropdownlist={countriesdropdownlist}
                                userCountry={'United Kingdom'}
                                selectedCountry={
                                    selectedCountry ?? 'United Kingdom'
                                }
                                registrationNumber={registrationNumber}
                                setRegistrationNumber={(
                                    p: string | undefined
                                ) => setRegistrationNumber(p)}
                            />
                            <PaddingUnit $times={5} />
                        </OnboardingFormTemplateDesktop>
                    )}
                </OnboardingLayoutDesktop>
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay>
                <SideSliderWithExit
                    isVisible={
                        garageid && !userLoading && !countriesDataLoading
                            ? true
                            : false
                    }
                    direction={'right'}
                    initial="enter"
                    customDuration={0.5}
                    noOverflow
                >
                    {countriesdropdownlist && garageid && (
                        <OnboardingFormLayoutMobile
                            step={1}
                            title={onboardingStep1Data.title}
                            isBtnLoading={
                                garageDataLoading || carsDataLoading
                                    ? true
                                    : false
                            }
                            submit={submit}
                            skip={() => {
                                dispatch(
                                    dropDownActions.setSelectedCountry(null)
                                )
                                setTimeout(function () {
                                    isIOS
                                        ? history.push(
                                              `/garage?native_app_prompt=true`
                                          )
                                        : history.push(`/garage?tutorial=true`)
                                }, 200)
                            }}
                        >
                            <PaddingUnit
                                $times={
                                    windowHeight && windowHeight > 700 ? 4 : 2
                                }
                            />
                            <OnboardingText>
                                {onboardingStep1Data.text}
                            </OnboardingText>
                            <PaddingUnit $times={2} />
                            {/* <OnboardingText>
                                Let's get your car set up on our platform!
                            </OnboardingText> */}
                            <PaddingUnit $times={5} />
                            <AddACarFormOnboarding
                                countriesdropdownlist={countriesdropdownlist}
                                userCountry={'United Kingdom'}
                                selectedCountry={selectedCountry}
                                registrationNumber={registrationNumber}
                                setRegistrationNumber={(
                                    p: string | undefined
                                ) => setRegistrationNumber(p)}
                            />
                            <PaddingUnit $times={5} />
                        </OnboardingFormLayoutMobile>
                    )}
                </SideSliderWithExit>
            </IpadAndMobileDisplay>
        </React.Fragment>
    )
}

export default OnboardingStep_1
