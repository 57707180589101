import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

function MissingInfoAnimatedSvg(props?: any) {
    const { theme } = useThemes()
    return (
        <svg
            width="115"
            height="83"
            viewBox="0 0 115 83"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50.1299 3L43.3766 10.0909L40 6.54545"
                stroke={colours[theme].border_muted}
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="square"
            />
            <path
                d="M31.1299 26.1172L24.3766 33.2081L21 29.6626"
                stroke={colours[theme].border_muted}
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="square"
            />
            <line
                x1="65.4333"
                y1="29.0547"
                x2="43.6152"
                y2="29.0547"
                stroke={colours[theme].border_muted}
                stroke-width="3"
                stroke-linecap="square"
            />
            <line
                x1="84.4333"
                y1="5.47656"
                x2="62.6152"
                y2="5.47656"
                stroke={colours[theme].border_muted}
                stroke-width="3"
                stroke-linecap="square"
            />
            <line
                x1="47.4333"
                y1="51.2266"
                x2="25.6152"
                y2="51.2266"
                stroke={colours[theme].border_muted}
                stroke-width="3"
                stroke-linecap="square"
            />
            <path
                d="M13.1299 47.2344L6.37662 54.3253L3 50.7798"
                stroke={colours[theme].border_muted}
                stroke-width="3"
                stroke-miterlimit="10"
                stroke-linecap="square"
            />
            <path
                d="M114.495 2.1189C114.561 1.29308 113.945 0.570387 113.119 0.50472C112.293 0.439053 111.57 1.05528 111.505 1.8811L114.495 2.1189ZM51.5736 76.5359C51.3173 77.3237 51.7482 78.1701 52.5359 78.4264L65.3737 82.6029C66.1614 82.8592 67.0078 82.4283 67.2641 81.6406C67.5204 80.8528 67.0896 80.0064 66.3018 79.7501L54.8905 76.0376L58.6029 64.6263C58.8592 63.8386 58.4283 62.9922 57.6406 62.7359C56.8528 62.4796 56.0064 62.9104 55.7501 63.6982L51.5736 76.5359ZM111.505 1.8811C110.317 16.8188 96.8951 52.9717 52.3195 75.6632L53.6805 78.3368C99.2101 55.1596 113.227 18.0692 114.495 2.1189L111.505 1.8811Z"
                fill={colours[theme].border_muted}
            />
        </svg>
    )
}

export default MissingInfoAnimatedSvg
