import {
    RouteComponentProps,
    useHistory,
    useParams,
    withRouter,
} from 'react-router'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    ModalArchiveReasons,
    ModalArchiveStart,
    ModalArchiveSuccess,
} from '../../organisms/archiveForms/archiveCarDesktopManager'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { archiveCarRequest } from '../../../redux/entities/cars/actions/loadingActions'
import { editFormsActions } from '../../../redux/editForms/reducer'

const MobilePageWrap = styled.div`
    height: 100vh;
    background-color: 'white';
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: scroll;
`
type CarParams = {
    carid: string
}
const ArchiveCarFlowMobile = (props: RouteComponentProps) => {
    let activeArchivingStep = useAppSelector(
        (state) => state.editForms.activeArchivingStep
    )
    useEffect(() => {
        dispatch(editFormsActions.setArchivingStep('start'))
    }, [])

    const [reason, setReason] = useState<string | undefined>(undefined)
    const [message, setMessage] = useState<string | undefined>(undefined)

    const dispatch = useAppDispatch()

    const { carid } = useParams<CarParams>()

    const history = useHistory()

    return (
        <IpadAndMobileDisplay>
            <MobilePageWrap
                style={{
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '100px',
                }}
            >
                {activeArchivingStep === 'start' && (
                    <ModalArchiveStart
                        onHandoverClick={() => {
                            history.push(`/handover/${carid}`)
                        }}
                        onArchiveClick={() => {
                            dispatch(
                                editFormsActions.setArchivingStep('reasons')
                            )
                        }}
                        close={() => {
                            props.history.goBack()
                            // direct
                        }}
                    />
                )}
                {activeArchivingStep === 'reasons' && (
                    <ModalArchiveReasons
                        message={message}
                        setMessage={(msg: string | undefined) => {
                            setMessage(msg)
                        }}
                        onSelect={(id: string) => {
                            reason === id ? setReason(undefined) : setReason(id)
                        }}
                        activeID={reason}
                        submitArchive={() => {
                            dispatch(
                                archiveCarRequest({
                                    carID: carid,
                                    data: {
                                        archived_reason: 'custom',
                                        custom_reason: reason ?? undefined,
                                        custom_note: message ?? undefined,
                                    },
                                })
                            )

                            // success step will be set inside archiving saga if no error
                        }}
                        onCancel={() => {
                            props.history.push(`/car/${carid}`)
                        }}
                        isDiabled={reason === undefined ? true : false}
                    />
                )}
                {activeArchivingStep === 'success' && (
                    <ModalArchiveSuccess
                        onClose={() => {
                            props.history.push('/garage/previously-owned')
                            // cleanup redux
                            dispatch(
                                editFormsActions.setArchivingStep(undefined)
                            )
                        }}
                    />
                )}
            </MobilePageWrap>
        </IpadAndMobileDisplay>
    )
}

export default withRouter(ArchiveCarFlowMobile)
