import * as React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { device } from '../../../templates/displays/devices'
// import profile_blank from '../../../../public/assets/icons/profile_pic_placeholder.svg'
import profile_blank from '../../../../public/assets/placeholders_svgs/avatar.svg'
import Icon from '../../../atoms/icons'
import {
    IPrivateShareReceivedItem,
    IPrivateShareReceivedItemApprovalPayload,
} from 'entityModels'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import CardMotionDivWrapper from '../../../templates/animated/cardMotionDivWrapper'

interface IStyle {
    hasMarginRight?: boolean
    $hasApprovalBtns?: boolean
    $allowClick?: boolean
}

const ShadowBox = styled(Link)<IStyle>`
    @media ${device.desktop} {
        padding: 15px;
        padding-left: 30px;
        padding-right: 30px;
    }
    @media (min-width: 1241px) and (max-width: 1310px) {
        margin-right: ${(props) => (props.hasMarginRight ? '20px' : '0px')};
    }
    @media (min-width: 1311px) and (max-width: 1450px) {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: ${(props) => (props.hasMarginRight ? '30px' : '0px')};
    }
    @media ${device.large_desktop} {
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: ${(props) => (props.hasMarginRight ? '30px' : '0px')};
        padding-left: 30px;
        padding-right: 30px;
        width: 540px;
    }
    @media ${device.ultrawide_desktop} {
    }
    /* @media ${device.smallest_laptops} {
        padding: 20px;
        height: 140px;
        width: 450px;
    } */
    height: ${(props) => (props.$hasApprovalBtns ? '225px' : '140px')};
    margin-top: 10px;
    margin-bottom: 10px;
    width: 500px;
    margin-right: ${(props) => (props.hasMarginRight ? '20px' : '0px')};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: Lato;
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border: transparent;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    background-color: var(--bg-color, #fff);
    transition: all 100ms;
    ${(props) =>
        props.$allowClick &&
        ` :hover {
        background-color: var(--primary_04) !important;
        border: 1px solid var(--primary_08);
        transition: all 100ms;
    }`}
`

const CarProfileImage = styled.img`
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
`

const CarImgWrapper = styled.div`
    position: absolute;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    object-fit: cover;
`

// const Icon = styled.img`
//     height: 100%;
//     width: 100%;
//     object-fit: cover;
// `

// const IconWrapper = styled.div`
//     width: 20px;
//     height: 20px;
//     object-fit: cover;
// `

const OwnerName = styled.div`
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 2px;
    @media ${device.desktop} {
        font-size: 16px;
        font-family: 'Lato-Bold';
    }
    @media ${device.large_desktop} {
        font-size: 17px;
        font-family: 'Lato';
    }
    @media ${device.smallest_laptops} {
        font-family: 'Lato-Bold';
        font-size: 15px;
        line-height: 20px;
        overflow: hidden;
    }
`

const HozLine = styled.div`
    background-color: var(--border_muted_subtle);
    height: 1px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 10px;
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 130px;
    width: 100%;
`

const FlexRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`

const SharedOn = styled.div`
    font-family: Lato-light;
    font-size: 11px;
    font-style: italic;
    color: var(--text-darker, #616161);
    @media ${device.ipad} {
        font-size: 12px;
    }
`

const Details = styled.div`
    font-family: Lato;
    font-style: italic;
    font-size: 12px;
    color: var(--text-default, #666666);
    padding-left: 7px;
    @media ${device.ipad} {
        font-size: 12px;
    }
`
const SmallPaddingH = styled.div`
    padding-right: 16px;
`
const SmallPaddingV = styled.div`
    padding-top: 30px;
`
const SmallPaddingV2 = styled.div`
    padding-top: 5px;
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

interface Props extends RouteComponentProps<any> {
    share_received: IPrivateShareReceivedItem
    hasMarginRight?: boolean
    shareApprovalAction?: (
        payload: IPrivateShareReceivedItemApprovalPayload
    ) => void
}

type State = { src: any; srcet: any; isLoading: boolean }

class GaragesSharedWithYouCardDesktop extends React.Component<Props, State> {
    state = { srcet: undefined, src: undefined, isLoading: true }

    _isMounted = false

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                src: src,
                isLoading: false,
            })
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentDidUpdate(prevProps: Props) {}

    render() {
        let { share_received, hasMarginRight, shareApprovalAction } = this.props

        let garage_owner_display_name =
            share_received &&
            share_received.owner &&
            share_received.owner.display_name
                ? share_received.owner.display_name
                : 'Car Owner'

        let hasApprovalBts =
            share_received.approvalStatus === 'pending' ||
            share_received.approvalStatus === 'ignored'
                ? true
                : false

        let params = new URLSearchParams(this.props.location.search)
        let view_id = params.get('view_id')

        return (
            <CardMotionDivWrapper>
                {share_received && (
                    <ShadowBox
                        to={
                            share_received.approvalStatus === 'accepted'
                                ? `/shared/garage?garageid=${share_received.entity_id}`
                                : view_id
                                ? `/sharing/with-you?view_id=${view_id}`
                                : `/sharing/with-you`
                        }
                        // target="_blank"
                        rel="noreferrer"
                        hasMarginRight={hasMarginRight}
                        $hasApprovalBtns={hasApprovalBts}
                        $allowClick={
                            share_received.approvalStatus === 'accepted'
                        }
                    >
                        <FlexRow>
                            <CarImgWrapper
                                style={{
                                    border:
                                        share_received &&
                                        share_received.owner &&
                                        share_received.owner.profile_picture
                                            ? '1px solid #00a9af'
                                            : '1px solid var(--border-muted, #e5e5e5)',
                                }}
                            >
                                <CarProfileImage
                                    src={
                                        share_received &&
                                        share_received.owner &&
                                        share_received.owner.profile_picture
                                            ? share_received.owner
                                                  .profile_picture.url
                                            : profile_blank
                                    }
                                    aria-label="garage owner profile image"
                                />
                            </CarImgWrapper>
                            <Col>
                                <OwnerName>
                                    {garage_owner_display_name
                                        ? garage_owner_display_name.length > 27
                                            ? `${garage_owner_display_name.slice(
                                                  0,
                                                  27
                                              )}...`
                                            : garage_owner_display_name.trim() !==
                                              ''
                                            ? garage_owner_display_name
                                            : 'Private user'
                                        : 'Private user'}
                                </OwnerName>
                                <SharedOn>
                                    shared on {share_received.created_at}
                                </SharedOn>
                                <HozLine />
                                {/* <FlexRow>
                                    <Icon icon="car_listed" width="15px" />
                                    <Details>{`${0} cars`}</Details>
                                </FlexRow> */}
                                <div style={{ paddingTop: '5px' }} />
                                <FlexRow>
                                    <Icon icon="location" width="15px" />
                                    <Details
                                        style={{ textTransform: 'capitalize' }}
                                    >
                                        {share_received.owner.location}
                                    </Details>
                                </FlexRow>
                            </Col>
                        </FlexRow>
                        {hasApprovalBts && (
                            <>
                                <SmallPaddingV />
                                <FlexRow>
                                    <ButtonAtom
                                        theme="secondary"
                                        width="100%"
                                        height="40px"
                                        textTransform="capitalize"
                                        fontSize={14}
                                        onClick={() => {
                                            if (shareApprovalAction) {
                                                shareApprovalAction({
                                                    shareId: share_received.id,
                                                    recipientId:
                                                        share_received.owner
                                                            .uid,
                                                    approvalId: 'accepted',
                                                })
                                                setTimeout(
                                                    () =>
                                                        this.props.history.replace(
                                                            `/sharing/with-you?entity_id=garage&view_id=view_all`
                                                        ),
                                                    50
                                                )
                                            }
                                        }}
                                    >
                                        Accept
                                    </ButtonAtom>
                                    <SmallPaddingH />
                                    {share_received.approvalStatus !==
                                        'ignored' && (
                                        <>
                                            <ButtonAtom
                                                theme="tertiary"
                                                width="100%"
                                                height="40px"
                                                textTransform="capitalize"
                                                fontSize={14}
                                                onClick={() =>
                                                    shareApprovalAction
                                                        ? shareApprovalAction({
                                                              shareId:
                                                                  share_received.id,
                                                              recipientId:
                                                                  share_received
                                                                      .owner
                                                                      .uid,
                                                              approvalId:
                                                                  'ignored',
                                                          })
                                                        : {}
                                                }
                                            >
                                                Ignore
                                            </ButtonAtom>
                                            <SmallPaddingH />
                                        </>
                                    )}
                                    <ButtonAtom
                                        theme="lowercase-white-red-background"
                                        width="100%"
                                        height="40px"
                                        fontSize={14}
                                        noIpadMinWith
                                        onClick={() =>
                                            shareApprovalAction
                                                ? shareApprovalAction({
                                                      shareId:
                                                          share_received.id,
                                                      recipientId:
                                                          share_received.owner
                                                              .uid,
                                                      approvalId: 'rejected',
                                                  })
                                                : {}
                                        }
                                    >
                                        Decline
                                    </ButtonAtom>
                                </FlexRow>
                                <SmallPaddingV2 />
                            </>
                        )}
                    </ShadowBox>
                )}
            </CardMotionDivWrapper>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GaragesSharedWithYouCardDesktop)
)
