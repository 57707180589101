import {
    GET_CAR_DATA_REQUEST,
    WRITE_CAR_DATA_GENERAL_REQUEST,
    GET_GARAGE_CARS_DATA_REQUEST,
    SET_CURRENT_CAR_REQUEST,
    CREATE_CAR_REQUEST,
    UPDATE_CAR_BANNER_URL_REQUEST,
    GET_CAR_DATA_BY_ID_REQUEST,
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_REQUEST,
    SET_CAR_TASK_IDS_REQUEST,
    DELETE_CAR_REQUEST,
    CHANGE_SORT_CAR_SHARE_DISPLAY_REQUEST,
    CHANGE_FILTER_CAR_SHARE_DISPLAY_REQUEST,
    CHANGE_SEARCHQUERY_CAR_SHARE_DISPLAY_REQUEST,
    RESET_SEARCHQUERY_CAR_SHARE_DISPLAY_REQUEST,
    GET_PUBLIC_SHARES_CAR_REQUEST,
    GET_PUBLIC_SHARES_ALL_USER_GARAGE_CARS_REQUEST,
    CREATE_CAR_PUBLIC_SHARE_REQUEST,
    DELETE_CAR_PUBLIC_SHARE_REQUEST,
    CHANGE_SORT_SHARE_RECIPIENTS_DISPLAY_REQUEST,
    GET_EXTERNAL_CARS_SHARED_WITH_YOU_REQUEST,
    SET_CURRENT_EXTERNAL_CAR_REQUEST,
    GET_OWNED_DIRECT_SHARES_CAR_REQUEST,
    GET_ALL_CAR_SHARES_REQUEST,
    CREATE_CAR_DIRECT_SHARE_REQUEST,
    GET_SINGLE_CAR_DIRECT_SHARE_REQUEST,
    ADD_SHARE_RECIPIENT_CAR_REQUEST,
    DELETE_SHARE_RECIPIENT_CAR_REQUEST,
    DELETE_CAR_DIRECT_SHARE_REQUEST,
    UPDATE_CAR_DIRECT_SHARE_REQUEST,
    CREATE_CAR_SHARE_INVITE_REQUEST,
    DELETE_CAR_SHARE_INVITE_REQUEST,
    RESEND_CAR_SHARE_INVITE_REQUEST,
    ACCEPT_CAR_SHARE_INVITE_REQUEST,
    ARCHIVE_CAR_REQUEST,
    CREATE_HANDOVER_REQUEST,
    DELETE_HANDOVER_REQUEST,
    GET_LAST_CAR_INSURANCE_ENTRY_REQUEST,
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_REQUEST_VERSION2,
    SET_EMPTY_CAR_REQUEST,
    CREATE_CAR_REQUEST_VERSION2,
    GET_CAR_ACTION_CHECK_REQUEST,
    GET_CATEGORY_INSIGHTS_REQUEST,
    GET_CAR_INSIGHTS_REQUEST,
} from './constants'

import {
    IAddACarInitialPayload,
    IPrivateShares_Sort_IDS,
    IExternalCarDataRequestPayload,
    ISharingPermissionIDS,
    IUpdateCarGeneralPayloadReq,
    ISortShareRecipientsDisplay_change_request,
    ICreateDirectShareRequest,
    IUpdateCarDirectShareRequest,
    IExternalCarDataRequestPayloadVersion2,
    ICreateCarVersion2,
    IGetCarActionCheckRequest,
} from 'entityModels'

import { createAction } from '@reduxjs/toolkit'
import { IDeleteShareReq } from './actions'
import { ICreateShareInviteApi_arg } from '../../../services/sharing/createShareInvite'
import { I_archiveCar_api_req_args } from '../../../services/archive/archiveCar'
import { ICreateHandoverAPI_req } from '../../../services/handover/createHandover'
import { ITimeRangeEnum } from '../../../../components/atoms/menu/carInsights/timeMenu'
import { ITimelineEntryTypes } from 'timelineModels'

export const getCarDataRequest = createAction(
    GET_CAR_DATA_REQUEST,
    function prepare(payload: string[]) {
        return {
            payload,
        }
    }
)

export const getCarDataByIdRequest = createAction(
    GET_CAR_DATA_BY_ID_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const setEmptyCarRequest = createAction(
    SET_EMPTY_CAR_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const writeCarDataGeneralRequest = createAction(
    WRITE_CAR_DATA_GENERAL_REQUEST,
    function prepare(payload: IUpdateCarGeneralPayloadReq) {
        return {
            payload,
        }
    }
)

export const getGarageCarsDataRequest = createAction(
    GET_GARAGE_CARS_DATA_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const setCurrentCarRequest = createAction(SET_CURRENT_CAR_REQUEST)

// export const createCarRequest = createAction(
//     CREATE_CAR_REQUEST,
//     (resolve) => (dataToSend: IAddACarInitialPayload) => resolve(dataToSend)
// )

export const createCarRequest = createAction(
    CREATE_CAR_REQUEST,
    function prepare(payload: IAddACarInitialPayload) {
        return {
            payload,
        }
    }
)

export const createCarRequestVersion2 = createAction(
    CREATE_CAR_REQUEST_VERSION2,
    function prepare(payload: ICreateCarVersion2) {
        return {
            payload,
        }
    }
)

export const updateCarBannerUrlRequest = createAction(
    UPDATE_CAR_BANNER_URL_REQUEST
)

// export const getExternalCarDataByRegistrationNumberRequest = createAction(
//     GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_REQUEST,
//     (resolve) => (payload: IExternalCarDataRequestPayload) => resolve(payload)
// )

export const getExternalCarDataByRegistrationNumberRequest = createAction(
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_REQUEST,
    function prepare(payload: IExternalCarDataRequestPayload) {
        return {
            payload,
        }
    }
)

export const getExternalCarDataByRegistrationNumberRequestVersion2 =
    createAction(
        GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_REQUEST_VERSION2,
        function prepare(payload: IExternalCarDataRequestPayloadVersion2) {
            return {
                payload,
            }
        }
    )

export const setCarTaskIdsRequest = createAction(SET_CAR_TASK_IDS_REQUEST)

// export const deleteCarRequest = createAction(
//     DELETE_CAR_REQUEST,
//     (resolve) => (payload: string) => resolve(carid)
// )

export const deleteCarRequest = createAction(
    DELETE_CAR_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export type ISortCarSharesDisplay_change_request = {
    carid: string
    sortID: IPrivateShares_Sort_IDS
}

export const sortCarSharesDisplay_change_request = createAction(
    CHANGE_SORT_CAR_SHARE_DISPLAY_REQUEST,
    function prepare(payload: ISortCarSharesDisplay_change_request) {
        return {
            payload,
        }
    }
)

export type IFilterCarSharesDisplay_change_request = {
    carid: string
    filterIDs: ISharingPermissionIDS[]
}

export const filterCarSharesDisplay_change_request = createAction(
    CHANGE_FILTER_CAR_SHARE_DISPLAY_REQUEST,
    function prepare(payload: IFilterCarSharesDisplay_change_request) {
        return {
            payload,
        }
    }
)

export type ISearchQueryCarSharesDisplay_change_request = {
    carid: string
    searchQuery: string
}

export const searchQueryCarSharesDisplay_change_request = createAction(
    CHANGE_SEARCHQUERY_CAR_SHARE_DISPLAY_REQUEST,
    function prepare(payload: ISearchQueryCarSharesDisplay_change_request) {
        return {
            payload,
        }
    }
)

export const resetSearchQueryCarShareDisplay_request = createAction(
    RESET_SEARCHQUERY_CAR_SHARE_DISPLAY_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const sortShareRecipientsDisplay_change_request = createAction(
    CHANGE_SORT_SHARE_RECIPIENTS_DISPLAY_REQUEST,
    function prepare(payload: ISortShareRecipientsDisplay_change_request) {
        return {
            payload,
        }
    }
)

export const getPublicSharesCarRequest = createAction(
    GET_PUBLIC_SHARES_CAR_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getOwnedDirectSharesCarRequest = createAction(
    GET_OWNED_DIRECT_SHARES_CAR_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getAllCarSharesRequest = createAction(
    GET_ALL_CAR_SHARES_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getPublicSharesAllUserGarageCarsRequest = createAction(
    GET_PUBLIC_SHARES_ALL_USER_GARAGE_CARS_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const createCarPublicShareRequest = createAction(
    CREATE_CAR_PUBLIC_SHARE_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const deleteCarPublicShareRequest = createAction(
    DELETE_CAR_PUBLIC_SHARE_REQUEST,
    function prepare(payload: IDeleteShareReq) {
        return {
            payload,
        }
    }
)

export const getExternalCarsSharedWithYouREQUEST = createAction(
    GET_EXTERNAL_CARS_SHARED_WITH_YOU_REQUEST,
    function prepare(payload: any) {
        return {
            payload,
        }
    }
)

export const setCurrentExternalCarRequest = createAction(
    SET_CURRENT_EXTERNAL_CAR_REQUEST
)

export const createCarDirectShareRequest = createAction(
    CREATE_CAR_DIRECT_SHARE_REQUEST,
    function prepare(payload: ICreateDirectShareRequest) {
        return {
            payload,
        }
    }
)

export const updateCarDirectShareRequest = createAction(
    UPDATE_CAR_DIRECT_SHARE_REQUEST,
    function prepare(payload: IUpdateCarDirectShareRequest) {
        return {
            payload,
        }
    }
)

export type IgetSingleDirectShareRequest_redux = {
    entity_id: string
    share_id: string
}

export const getSingleCarDirectShareRequest = createAction(
    GET_SINGLE_CAR_DIRECT_SHARE_REQUEST,
    function prepare(payload: IgetSingleDirectShareRequest_redux) {
        return {
            payload,
        }
    }
)

export type IShareRecipientRequest_redux = {
    share_id: string
    entity_id: string
    recipient_userid: string
}

export const addCarShareRecipientRequest = createAction(
    ADD_SHARE_RECIPIENT_CAR_REQUEST,
    function prepare(payload: IShareRecipientRequest_redux) {
        return {
            payload,
        }
    }
)

export const deleteCarShareRecipientRequest = createAction(
    DELETE_SHARE_RECIPIENT_CAR_REQUEST,
    function prepare(payload: IShareRecipientRequest_redux) {
        return {
            payload,
        }
    }
)

export const deleteCarDirectShareRequest = createAction(
    DELETE_CAR_DIRECT_SHARE_REQUEST,
    function prepare(payload: IgetSingleDirectShareRequest_redux) {
        return {
            payload,
        }
    }
)

export type ICreateShareInviteRequestPayload = {
    entity_id: string
    api_payload: ICreateShareInviteApi_arg
}

export const createCarShareInviteRequest = createAction(
    CREATE_CAR_SHARE_INVITE_REQUEST,
    function prepare(payload: ICreateShareInviteRequestPayload) {
        return {
            payload,
        }
    }
)

export type IShareInviteDeletion = {
    share_id: string
    entity_id: string
    invite_id: string
}

export const deleteCarShareInviteRequest = createAction(
    DELETE_CAR_SHARE_INVITE_REQUEST,
    function prepare(payload: IShareInviteDeletion) {
        return {
            payload,
        }
    }
)

export type IShareInvitResent = {
    invite_id: string
    recipient_email: string
}

export const resendCarShareInviteRequest = createAction(
    RESEND_CAR_SHARE_INVITE_REQUEST,
    function prepare(payload: IShareInvitResent) {
        return {
            payload,
        }
    }
)

export const acceptCarShareInviteRequest = createAction(
    ACCEPT_CAR_SHARE_INVITE_REQUEST,
    function prepare(payload: { invite_code: string; next_path: string }) {
        return {
            payload,
        }
    }
)

export const archiveCarRequest = createAction(
    ARCHIVE_CAR_REQUEST,
    function prepare(payload: I_archiveCar_api_req_args) {
        return {
            payload,
        }
    }
)

export const createHandoverRequest = createAction(
    CREATE_HANDOVER_REQUEST,
    function prepare(payload: ICreateHandoverAPI_req) {
        return {
            payload,
        }
    }
)

export const deleteHandoverRequest = createAction(
    DELETE_HANDOVER_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getLastCarInsuranceEntryRequest = createAction(
    GET_LAST_CAR_INSURANCE_ENTRY_REQUEST,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const getCarActionCheckRequest = createAction(
    GET_CAR_ACTION_CHECK_REQUEST,
    function prepare(payload: IGetCarActionCheckRequest) {
        return {
            payload,
        }
    }
)

export type IgetCarInsightsRequest = {
    carid: string
    start_date: string
    end_date: string
    timeRange: ITimeRangeEnum
}

export const getCarInsightsRequest = createAction(
    GET_CAR_INSIGHTS_REQUEST,
    function prepare(payload: IgetCarInsightsRequest) {
        return {
            payload,
        }
    }
)

export type IgetCarCategoryInsightsRequest = {
    carid: string
    start_date: string
    end_date: string
    timeRange: ITimeRangeEnum
    categoryID: ITimelineEntryTypes | string
    representation: 'monthly' | 'yearly'
}

export const getCarCategoryInsightsRequest = createAction(
    GET_CATEGORY_INSIGHTS_REQUEST,
    function prepare(payload: IgetCarCategoryInsightsRequest) {
        return {
            payload,
        }
    }
)
