type Props = {
    size?: string
    color?: string
}

const CarIconV2 = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 15.25C4.41421 15.25 4.75 15.5858 4.75 16V18.25H6.25V16C6.25 15.5858 6.58579 15.25 7 15.25C7.41421 15.25 7.75 15.5858 7.75 16V19.75H3.25V16C3.25 15.5858 3.58579 15.25 4 15.25Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17 15.25C17.4142 15.25 17.75 15.5858 17.75 16V18.25H19.25V16C19.25 15.5858 19.5858 15.25 20 15.25C20.4142 15.25 20.75 15.5858 20.75 16V19.75H16.25V16C16.25 15.5858 16.5858 15.25 17 15.25Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.25 12C3.25 11.0335 4.0335 10.25 5 10.25H19C19.9665 10.25 20.75 11.0335 20.75 12V16.75H3.25V12ZM5 11.75C4.86193 11.75 4.75 11.8619 4.75 12V15.25H19.25V12C19.25 11.8619 19.1381 11.75 19 11.75H5Z"
                fill={color ?? '#616161'}
            />
            <path
                d="M6.66667 14.5C7.25577 14.5 7.73333 14.0523 7.73333 13.5C7.73333 12.9477 7.25577 12.5 6.66667 12.5C6.07756 12.5 5.6 12.9477 5.6 13.5C5.6 14.0523 6.07756 14.5 6.66667 14.5Z"
                fill={color ?? '#616161'}
            />
            <path
                d="M17.3333 14.5C17.9224 14.5 18.4 14.0523 18.4 13.5C18.4 12.9477 17.9224 12.5 17.3333 12.5C16.7442 12.5 16.2667 12.9477 16.2667 13.5C16.2667 14.0523 16.7442 14.5 17.3333 14.5Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.47692 5.25L16.5231 5.25C16.5231 5.25 16.5231 5.25 16.5231 5.25C17.0236 5.24999 17.5321 5.36834 17.9556 5.61193C18.3753 5.85339 18.7435 6.24177 18.8555 6.76218L19.7332 10.8423C19.8203 11.2472 19.5627 11.6461 19.1577 11.7332C18.7528 11.8203 18.3539 11.5627 18.2668 11.1577L17.3893 7.07863C17.389 7.07872 17.3661 7.00331 17.2076 6.91213C17.0435 6.81772 16.8003 6.74999 16.5231 6.75H7.47724C7.47713 6.75 7.47734 6.75 7.47724 6.75C7.20025 6.75017 6.95715 6.81792 6.79328 6.91226C6.63496 7.00339 6.61211 7.07872 6.61182 7.07863C6.61178 7.0788 6.61181 7.07863 6.61182 7.07863M6.61182 7.07863L5.73319 11.1579C5.64597 11.5628 5.24701 11.8204 4.84208 11.7332C4.43715 11.646 4.1796 11.247 4.26681 10.8421L5.14556 6.76218C5.14555 6.76221 5.14557 6.76215 5.14556 6.76218C5.25755 6.24206 5.62548 5.85373 6.04492 5.61227C6.46809 5.36867 6.97664 5.25021 7.47692 5.25"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default CarIconV2
