import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { IJourneySectionID, IRedirectCardsCopy } from 'journeyInterfaces'
import { generateNextUpPattern } from '../../../../atoms/patterns/journey'
import JourneyStartIcon from '../../../../atoms/icons/components/journey/playIcon'

const Wrapper = styled.div<IStyle>`
    position: absolute;
    bottom: 0px;
    overflow: hidden;
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 16px 16px 0px 0px;
    width: 95vw;
    height: 160px;
    object-fit: cover;
    background-size: cover;
`

const StartWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1 0 0;
`

const Title = styled.h3<IStyle>`
    padding: 0;
    margin: 0;
    align-self: flex-start;
    color: ${(props) => `${colours[props.$theme].background_default}`};
    font-family: Lato;
    font-size: 18px;
    letter-spacing: 0.09px;
`

const StartNowBtn = styled.div`
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--bg-color, #fff);
`

type IStyle = {
    $theme: ITheme
    $sectionID: IJourneySectionID
}

export default function JourneyPatternCardNextUpMobile({
    sectionID,
    copyData,
    onNext,
}: {
    sectionID: IJourneySectionID
    copyData: IRedirectCardsCopy
    onNext: () => void
}) {
    const { theme } = useThemes()
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
            role={'button'}
            onClick={() => {
                onNext()
            }}
        >
            <Wrapper
                $theme={theme}
                $sectionID={sectionID}
                style={{
                    backgroundImage: `url(${generateNextUpPattern(
                        sectionID
                    )}), linear-gradient(259deg, ${
                        journey_colours['light'].section[sectionID].primary_500
                    } 0%, ${
                        journey_colours['light'].section[sectionID].primary_400
                    } 100%)`,
                }}
            >
                <StartWrapper>
                    <TextWrapper>
                        <Title $theme={theme} $sectionID={sectionID}>
                            {copyData[sectionID].title}
                        </Title>
                    </TextWrapper>
                </StartWrapper>

                <StartNowBtn>
                    <JourneyStartIcon
                        color={
                            journey_colours['light'].section[sectionID]
                                .primary_500
                        }
                        size="18"
                    />
                    <div
                        style={{
                            color: journey_colours['light'].section[sectionID]
                                .primary_500,
                            fontFamily: 'Lato',
                            fontSize: '16px',
                        }}
                    >
                        Start now
                    </div>
                </StartNowBtn>
            </Wrapper>
        </div>
    )
}
