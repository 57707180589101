import { IApexPodscastState } from 'apexModels'
import React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import spotify from '../../../../public/assets/logo/spotify.png'
import soundcloud from '../../../../public/assets/logo/soundcloud.png'
import itunes from '../../../../public/assets/logo/itunes.png'
import googlepodcast from '../../../../public/assets/logo/googlepodcast.png'
import './style.css'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px solid var(--border-muted, #e5e5e5);
    border-radius: 10px;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 30px;
    padding-top: 30px;
`

const PodcastElemDesktop = styled.iframe`
    @media ${device.large_desktop} {
        width: 30vw;
    }
`

const Name = styled.div`
    font-size: 24px;
    font-family: 'Lato-Light';
    padding-right: 40px;
    color: var(--text-strong, #1a1a1a);
`
const Title = styled.div`
    font-family: Lato;
    color: var(--text-strong, #1a1a1a);
    font-size: 23px;
    padding-right: 40px;
`

const Blurb = styled.div`
    display: flex;
    flex-grow: 1;
    font-size: 18px;
    font-family: 'Lato-Light';
    line-height: 23px;
    padding-right: 40px;
    color: var(--text-strong, #1a1a1a);
`

const BtnsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
`
const Icon = styled.img`
    width: 40px;
    margin-right: 10px;
    border-radius: 50%;
    transition: all 200ms;

    :hover {
        transition: all 200ms;
        transform: scale(1.1);
    }
`

const IconGoogle = styled.img`
    width: 40px;
    margin-right: 10px;
    box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 5%);
    cursor: pointer;
    border-radius: 50%;
    transition: all 200ms;

    :hover {
        transition: all 200ms;
        transform: scale(1.1);
    }
`
const Listen = styled.div`
    font-family: 'Lato';
    text-transform: uppercase;
    font-size: 12px;
    color: var(--text-strong, #1a1a1a);
`
type Props = {
    podcast: IApexPodscastState
    index: number
}

class PodcastItemDesktop extends React.Component<Props> {
    render() {
        let { index, podcast } = this.props
        // This is the url structure for spotify embeds: https://open.spotify.com/embed/episode/3AnL7BmcheJH1oGLlcmPGB
        const spotifyId = podcast?.spotify_url?.split('/').pop()
        // create the spotify embed URL
        const spotifyEmbedUrl = `https://open.spotify.com/embed/episode/${spotifyId}`

        return (
            <Container key={index}>
                <Name>{podcast.name}</Name>
                <div style={{ paddingTop: '2px' }} />
                <Title>{podcast.short_title}</Title>
                <div style={{ paddingTop: '20px' }} />
                <Blurb>{podcast.blurb ? podcast.blurb : ''}</Blurb>

                <div style={{ paddingTop: '40px' }} />
                <div className="holds-the-iframe-desktop">
                    <PodcastElemDesktop
                        title={podcast.name}
                        src={spotifyEmbedUrl ? spotifyEmbedUrl : ''}
                        width="100%"
                        height="152"
                        frameBorder={0}
                        loading="lazy"
                        allow="encrypted-media; encrypted-media; picture-in-picture; fullscreen"
                        scrolling="no"
                    />
                </div>
                <div style={{ paddingTop: '20px' }} />

                <Listen>Or listen on...</Listen>
                <div style={{ paddingTop: '20px' }} />
                <BtnsRow>
                    {podcast.spotify_url !== '' && (
                        <a
                            href={podcast.spotify_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon src={spotify} />
                        </a>
                    )}

                    {podcast.soundcloud_url !== '' && (
                        <a
                            href={podcast.soundcloud_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon src={soundcloud} />
                        </a>
                    )}

                    {podcast.apple_url !== '' && (
                        <a
                            href={podcast.apple_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon src={itunes} />
                        </a>
                    )}

                    {podcast.google_podcasts_url !== '' && (
                        <a
                            href={podcast.google_podcasts_url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IconGoogle src={googlepodcast} />
                        </a>
                    )}
                </BtnsRow>
            </Container>
        )
    }
}

export default PodcastItemDesktop
