import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneySharingIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '32'}
            height={size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6949 6.31489C17.9654 6.19439 18.2816 6.24441 18.5017 6.44254L29.1211 16L18.5017 25.5575C18.2816 25.7556 17.9654 25.8056 17.6949 25.6851C17.4243 25.5646 17.25 25.2962 17.25 25V20.7565C14.8456 20.7996 12.8947 21.0624 11.0195 21.709C8.95258 22.4218 6.92802 23.6181 4.46852 25.5857L3.25 26.5605V25C3.25 21.0432 5.98519 17.7656 9.04788 15.4516C11.8412 13.3411 15.0749 11.9019 17.25 11.3877V7.00001C17.25 6.70383 17.4243 6.43539 17.6949 6.31489ZM18.75 8.68404V12.6354L18.1233 12.7398C16.2656 13.0494 12.8759 14.4393 9.95212 16.6484C7.52989 18.4785 5.56499 20.7641 4.95035 23.3282C6.90682 21.9028 8.68343 20.9279 10.5305 20.291C12.8141 19.5035 15.1558 19.25 18 19.25H18.75V23.316L26.8789 16L18.75 8.68404Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.689 1.84223C12.9596 1.72173 13.2757 1.77175 13.4959 1.96989L15.3672 3.65408L14.3637 4.76902L13.7441 4.21138V10.0126H12.2441V2.52736C12.2441 2.23117 12.4184 1.96273 12.689 1.84223ZM12.2441 23.8604H13.7441V27.7904L14.3637 27.2327L15.3672 28.3477L13.4959 30.0318C13.2757 30.23 12.9596 30.28 12.689 30.1595C12.4184 30.039 12.2441 29.7706 12.2441 29.4744V23.8604Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default JourneySharingIcon
