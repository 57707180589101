import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { fields_array_insurance_application_details } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

const InsuranceDetailsApplicationFlow = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const data: IAnyObject = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.insurance_details
    })

    const errors: IAnyErrorString = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.insurance_details_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }

        if (
            id === 'application_referral_code' &&
            answer &&
            typeof answer === 'string'
        ) {
            answer = answer.toUpperCase()
        }

        dispatch(
            insuranceActions.set_answer_insuranceDetails({
                id: id,
                answer: answer,
            })
        )
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insuranceDetails({
                id: id,
                error: error,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     for (const [_, value] of Object.entries(errors)) {
    //         if (value !== undefined) {
    //             hasErr = true
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < fields_array_insurance_application_details.length;
            i++
        ) {
            if (
                fields_array_insurance_application_details[i].is_required ===
                true
            ) {
                if (
                    data[fields_array_insurance_application_details[i].id] ===
                    undefined
                ) {
                    setError(
                        fields_array_insurance_application_details[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }
        }

        if (data.contact_method && data.contact_method.length === 0) {
            setError('contact_method', 'Required field.')
            hasErr2 = true
        }

        return hasErr2
    }

    const { id } = useParams<{ id?: string }>()

    let { getUserAndApplicationData } = useSetInitialInsurance()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        // below only needed after application process?
        // return () => {
        //     dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        // }
    }, [])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.draft
    )

    // useEffect(() => {
    //     if (
    //         applicationData &&
    //         !applicationData.insurance_details['payment_term']
    //     ) {
    //         onAnswerChange('payment_term', 'UPFRONT')
    //     }
    // }, [applicationData])

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_insuranceDetailsApplication_request()
            )
            history.push(`/insurance/application/${id}`)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Insurance details' },
                    ]}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    sectionId="insurance_details"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '448px',
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            list={fields_array_insurance_application_details}
                            onAnswerChange={onAnswerChange}
                            data={data}
                            errors={errors}
                            setError={setError}
                            sectionID="insurance_details"
                        />
                    </div>

                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    goBack={() => history.push(`/insurance/application/${id}`)}
                    hasFormChanged={hasFormChanged}
                    sectionId="insurance_details"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <div style={{ minWidth: '100vw' }}>
                    <InsuranceTopBarWithProgressionRateMobile
                        breadCrumbs={[
                            {
                                pageName: 'Your application',
                                pageUrl: () => {
                                    history.push(`/insurance/application/${id}`)
                                },
                            },
                            { pageName: 'Insurance details' },
                        ]}
                        onSave={saveForm}
                        hasFormChanged={hasFormChanged}
                        sectionId="insurance_details"
                    />
                    <div
                        style={{
                            minHeight: '100vh',
                            width: '100%',
                            backgroundColor:
                                colours[theme].background_neutral_subtle,
                            paddingTop: 120,
                            paddingBottom: 120,
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            list={fields_array_insurance_application_details}
                            onAnswerChange={onAnswerChange}
                            data={data}
                            errors={errors}
                            setError={setError}
                            mobileSearchPickerSectionURL={
                                '/insurance/application/insurance_details/search'
                            }
                            sectionID="insurance_details"
                        />
                    </div>
                    <InsuranceGoBackReusableBottomBar
                        isMobile
                        saveBtnTxt="Save & Continue"
                        isSaveDisabled={isSavedDisabled ? true : false}
                        onSave={saveForm}
                        goBack={() =>
                            history.push(`/insurance/application/${id}`)
                        }
                        hasFormChanged={hasFormChanged}
                        sectionId="insurance_details"
                    />
                </div>
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceDetailsApplicationFlow
