import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'
import '../styles.css'
const formatsList = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    // 'indent',
    // 'link',
]

const modulesList = (id: string) => {
    return {
        toolbar: [
            [{ header: '1' }, { header: '2' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                // { indent: '-1' },
                // { indent: '+1' },
            ],
            // ['link'],
            // [{ container: "none" }],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }
}

// const CustomToolbar = (id: any) => {
//   return <CutomToolBar id={id} />;
// };

const Wrapper = styled.div`
    width: 100%;
    padding-right: 95px;
    // padding-top: 20px;
`

type Props = {
    placeholder: string
    valuePassed: string
    handleChange: any
    idPassed: string
    readOnly: boolean
    dataCyId?: string
    isDisabled?: boolean
}

class EntryNotesEditorDesktop extends React.Component<Props, {}> {
    render() {
        const {
            placeholder,
            valuePassed,
            handleChange,
            idPassed,
            readOnly,
            dataCyId,
            isDisabled,
        } = this.props

        let idOfToolbar = readOnly
            ? 'toolbarHiddenDesktopNote'
            : 'toolbarVisibleDesktopNote'
        return (
            <Wrapper data-attr={dataCyId} id="timeline_entry_notes">
                {/* <CustomToolbar id="" /> */}

                <ReactQuill
                    theme="snow"
                    onChange={handleChange}
                    value={valuePassed}
                    id={idPassed}
                    modules={modulesList(idOfToolbar)}
                    formats={formatsList}
                    bounds={'.app'}
                    placeholder={placeholder}
                    style={{
                        height: '100%',
                        fontFamily: 'Lato',
                        width: '100%',
                        maxWidth: '1400px',
                    }}
                    readOnly={isDisabled}
                />
            </Wrapper>
        )
    }
}

export default EntryNotesEditorDesktop
