import { IInsuranceApplicationSectionCard } from '../../../components/pages/insurance_quote/dashboards/insuranceApplicationDashboard'

export const submission_agreement_cards: IInsuranceApplicationSectionCard[] = [
    {
        id: '1',
        title: 'Before you submit',
        removeBtn: true,
        text: 'By proceeding, you are agreeing to several important points that affect your insurance application. Please take a moment to review and acknowledge the following information.',
    },
    {
        id: '1',
        text_items: [
            {
                title: 'Consent to be contacted by Telephone',
                text: 'In order for us to progress your insurance application we may need to call you. By submitting your application you consent to being contacted on the telephone number/s you have provided to discuss your Custodian Insurance application.',
            },
            {
                title: 'Data Permissions',
                text: 'By submitting your application you will be giving permission to process your data and share it with third parties in order to be able to provide you with an insurance quote. Information on how we process your data can be found on our website, or our terms of business.',
            },
            {
                title: 'Non-Advice Service',
                text: 'Custodian works with a panel of select specialist motor insurers. We work hard to find insurance cover which meets your demands & needs as outlined by yourself. Custodian does not provide advice. After searching across our panel of insurers for suitable products & covers we will provide you with sufficient information so that you can come to an informed decision.',
            },
            {
                title: 'Remuneration Disclosure',
                text: 'We receive a commission from our insurers when setting up your policy and when any adjustments are made to your policy mid term which result in an increase in premium. We may also charge you brokerage fees which can include a charge for our time and costs when setting up your policy, making changes to your policy, cancelling your policy, or requesting an agreed valuation on your vehicle/s.',
            },
            {
                title: 'Documentation (Durable Medium)',
                text: 'For a comprehensive understanding of the services we offer, including our terms and conditions, and a breakdown of our fees, please refer to our Consumer Terms of Business Agreement which can be found on our website, www.custodian.club, and will be included in all document packs sent to you through the course of your relationship with us.',
            },
            {
                title: 'Misrepresentation',
                text: `You have a duty to take reasonable care in answering all of our questions honestly and to the best of your knowledge. If you deliberately or carelessly misinform the insurers, this could result in your insurers imposing different terms on your insurance policy, charging you a higher premium, cancelling your insurance policy or, in some circumstances, treating your insurance policy as if it never existed.`,
            },
        ],
    },
]
