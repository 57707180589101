type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const TagIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ?? '16'}
            height={size ?? '16'}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 1C0.25 0.585786 0.585786 0.25 1 0.25H7.54657C7.74548 0.25 7.93624 0.329018 8.0769 0.46967L15.119 7.51173C15.319 7.71166 15.4777 7.94905 15.586 8.21033C15.6943 8.47162 15.75 8.75167 15.75 9.03451C15.75 9.31734 15.6943 9.5974 15.586 9.85868C15.4777 10.12 15.319 10.3574 15.119 10.5573L10.5573 15.119C10.3574 15.319 10.12 15.4777 9.85868 15.586C9.5974 15.6943 9.31734 15.75 9.03451 15.75C8.75167 15.75 8.47162 15.6943 8.21033 15.586C7.94905 15.4777 7.71166 15.319 7.51173 15.119L0.46967 8.0769C0.329018 7.93624 0.25 7.74548 0.25 7.54657V1ZM1.75 1.75V7.23591L8.57257 14.0585L8.57273 14.0586C8.63336 14.1193 8.70535 14.1674 8.78458 14.2003C8.86382 14.2331 8.94874 14.25 9.03451 14.25C9.12027 14.25 9.2052 14.2331 9.28443 14.2003C9.36367 14.1674 9.43565 14.1193 9.49628 14.0586L9.49645 14.0585L14.0585 9.49645L14.0586 9.49628C14.1193 9.43565 14.1674 9.36367 14.2003 9.28443C14.2331 9.2052 14.25 9.12027 14.25 9.03451C14.25 8.94874 14.2331 8.86382 14.2003 8.78458C14.1674 8.70535 14.1193 8.63336 14.0586 8.57273L14.0585 8.57257L7.23591 1.75H1.75Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.2085 4.55566C4.84795 4.55566 4.55566 4.84795 4.55566 5.2085C4.55566 5.56905 4.84795 5.86134 5.2085 5.86134C5.56905 5.86134 5.86134 5.56905 5.86134 5.2085C5.86134 4.84795 5.56905 4.55566 5.2085 4.55566ZM3.05566 5.2085C3.05566 4.01952 4.01952 3.05566 5.2085 3.05566C6.39748 3.05566 7.36134 4.01952 7.36134 5.2085C7.36134 6.39748 6.39748 7.36134 5.2085 7.36134C4.01952 7.36134 3.05566 6.39748 3.05566 5.2085Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default TagIcon
