import { IDropdownItem } from 'entityModels'
import powertype from '../formslist/car/powertype.json'
import transmission from '../formslist/car/transmission.json'
import drive_train from '../formslist/car/drive_train_options.json'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { validateEmail } from '../../../components/organisms/editForms/account/accountPrivateInfoForm/basic/basicAccountItemInfoFormDesktop'
import {
    IClaim_API,
    ICustodianCar,
    IInsuranceAddress_API,
    IInsuranceApplication_API,
    IInsuranceConviction_API,
    IInsuranceDisability_API,
    IInsuranceDriver_API,
    IInsuranceMotoringConviction_API,
    IInsuranceNonMotoringConviction_API,
    IInsuranceOption_API,
    IInsuranceVehicleModification,
    IVehicleUseFrequency_API,
    InsuranceCustomer_API,
} from '../../insuranceQuoteApplication/types'
import { countDecimals } from '../../../helpers/decimalsCheck'
import * as Yup from 'yup'

export type IInsuranceFieldType = {
    id:
        | keyof ICustodianCar
        | keyof InsuranceCustomer_API
        | keyof IInsuranceDisability_API
        | keyof IInsuranceDriver_API
        | keyof IInsuranceApplication_API
        | keyof IInsuranceAddress_API
        | keyof IInsuranceOption_API
        | keyof IInsuranceVehicleModification
        | keyof IInsuranceMotoringConviction_API
        | keyof IInsuranceConviction_API
        | keyof IInsuranceNonMotoringConviction_API
        | keyof IClaim_API
        | keyof IVehicleUseFrequency_API
        | string

    text: string

    type:
        | 'date'
        | 'dates_range'
        | 'dropdown'
        | 'multiselect'
        | 'searchable_options'
        | 'simple_txt'
        | 'short_txt_area'
        | 'number'
        | 'email'
        | 'phone'
        | 'number_with_dropdown'
        | 'text_with_dropdown'
        | 'yes_no'
        | 'contact_medium_choice'

    isMobileAnimated?: boolean
    contains_front_dropdown?: 'currency' | 'unit' | 'title'
    options?: IDropdownItem[]
    contains_tooltip_text?: string | undefined
    contains_important_tooltip_text?: string | undefined
    ends_section_with_divider?: boolean
    starts_section_with_header?: string
    min?: any
    max?: any
    error_txt?: string
    is_required?: boolean
    has_subform_if_true?: boolean
    has_subform_if_false?: boolean
    has_subform_if_values?: string[]
    subform?: IInsuranceFieldType[]
    has_self_replicating_subform_if_true?: boolean
    is_additional_fields_end?: boolean
    value_front_field?: string
    validation?: (value: any) => any
    localOnChangeValidation?: (value: any) => any
    parent_entity_id?: string
    isTextLong?: boolean
    patch_field_id?: string
    dropdown_id?: string
    default_dropdown_value?: { uid: string; name: string | number }
    isCaps?: boolean
    placeholderText?: string
    patch_as_int?: boolean
    is_price?: boolean
    isDeprecated?: boolean //this will be set to true so that it won't be visible for fill-in but only for already submitted with data there
    custom_date_format?: 'MM/yyyy' | undefined // this lowcase y for year is enforced by the library so setting placeholder with all caps as a separate property in the objects below
    desktop_title_on_top?: boolean
}

export const isTxtLengthValid = (value: string): boolean => {
    if (typeof value === 'string') {
        if (value.trim().length === 0) {
            return false
        }
    }
    return true
}

export const validatePrice = (value: any) => {
    return Yup.number()
        .min(0, 'Please add a valid amount')
        .test('has just 2 decimals', 'max 2 decimals', (value: any) =>
            countDecimals(parseFloat(value)) > 2 ? false : true
        )
}

// this will show for selection in the dropdown
export const inspection_frequency_options: IDropdownItem[] = [
    { uid: 'DAILY', name: 'Daily' },
    { uid: 'WEEKLY', name: 'Once per week' },
    { uid: 'TWICE_WEEKLY', name: 'A few times per week' },
    { uid: 'MONTHLY', name: 'Monthly' },
    { uid: 'TWICE_MONTHLY', name: 'A few times per month' },
    { uid: 'YEARLY', name: 'Yearly' },
]

// this is for UI not showing empty for customers with applications in progress that have already selected BIWEEKLY so UI doesn't show empty
export const inspection_frequency_options_with_deprecated_fields: IDropdownItem[] =
    [
        { uid: 'DAILY', name: 'Daily' },
        { uid: 'WEEKLY', name: 'Once per week' },
        { uid: 'TWICE_WEEKLY', name: 'A few times per week' },
        { uid: 'BIWEEKLY', name: 'Bi-Weekly' }, // deprecated
        { uid: 'MONTHLY', name: 'Monthly' },
        { uid: 'TWICE_MONTHLY', name: 'A few times per month' },
        { uid: 'BIMONTHLY', name: 'Bi-Monthly' }, // deprecated
        { uid: 'YEARLY', name: 'Yearly' },
    ]

export const license_type_options: IDropdownItem[] = [
    // has prefills
    { uid: 'FULL', name: `Full UK` },
    // has prefills
    {
        uid: 'MEDICALLY_RESTRICTED',
        name: 'Medically restricted UK',
    },
    // has prefills
    { uid: 'PROVISIONAL', name: 'Provisional UK' },
    { uid: 'OTHER', name: 'Other UK' },
    { uid: 'NON_UK', name: 'Non UK' },
]

export const location_type_options = [
    { uid: 'CARPORT', name: 'Carport' },
    { uid: 'LOCKED_COMPOUND', name: 'Locked compound' },
    { uid: 'LOCKED_GARAGE', name: 'Locked Garage' },
    { uid: 'WORK_CAR_PARK', name: 'Work Car Park' },
    { uid: 'DRIVEWAY', name: 'Driveway' },
    { uid: 'STREET_AWAY_FROM_HOME', name: 'Street away from home' },
    { uid: 'STREET_NEAR_HOME', name: 'Street near home' },
    { uid: 'OPEN_PUBLIC_CAR_PARK', name: 'Open public car park' },
    { uid: 'SECURE_PUBLIC_CAR_PARK', name: 'Secure public car park' },
    { uid: 'THIRD_PARTY_PREMISES', name: 'Third party premises' },
    { uid: 'UNLOCKED_COMPOUND', name: 'Unlocked compound' },
    { uid: 'UNLOCKED_GARAGE', name: 'Unlocked garage' },
]

export const fields_array_insurance_application_details: IInsuranceFieldType[] =
    [
        {
            id: 'policy_requested_start_date',
            type: 'date',
            text: 'When would you like your policy to start?',
            min: new Date(),
            // max: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            // max: new Date(new Date().setDate(new Date().getDate() + 30)),
            // error_txt: `Policy start date cannot be more than 30 days after the application is created.`,
            error_txt: 'invalid',
            is_required: false,
            contains_important_tooltip_text:
                'We can only provide quotations if your policy is due within the next 30 days. If your insurance is not due yet, please still apply by setting your policy start date to your renewal date and, we will get back in touch with you closer to the time to resume your application.',
        },

        {
            id: 'payment_term',
            type: 'dropdown',
            text: 'How would you like to pay for your policy?',
            options: [
                // { uid: 'UNKNOWN', name: 'Unknown' },
                // { uid: 'MONTHLY', name: 'Monthly' },
                { uid: 'UPFRONT', name: 'Upfront' },
            ],
            ends_section_with_divider: true,
            contains_important_tooltip_text:
                'Sorry, we can only take payment in full right now',
        },
        {
            id: 'application_referral_code',
            type: 'simple_txt',
            text: 'Do you have a referral code?',
            isCaps: true,
            placeholderText: "Leave blank if you don't have one",
            desktop_title_on_top: true,
            ends_section_with_divider: true,
            contains_important_tooltip_text:
                'If a friend or fellow car enthusiast already has insurance with Custodian, they might have shared a referral code with you.',
        },
        {
            id: 'contact_method',
            type: 'contact_medium_choice',
            text: 'How would you like to be contacted?',
            options: [
                { name: 'Email', uid: '1', icon: 'email' },
                { name: 'Home phone', uid: '2', icon: 'phone' },
                { name: 'Mobile phone', uid: '4', icon: 'mobile' },
                // { name: 'Custodian platform', uid: '8', icon: 'platform' },
                { name: 'Post', uid: '16', icon: 'post' },
            ],
            error_txt: 'You need to select at least on contact method.',
        },
    ]

export const fields_array_insurance_application_car_techinfo: IInsuranceFieldType[] =
    [
        {
            id: 'registration_number',
            type: 'simple_txt',
            text: 'Registration Number',
            is_required: false,
            isMobileAnimated: true,
            isCaps: true,
            validation: (val) => isTxtLengthValid(val),
        },
        {
            id: 'make',
            type: 'searchable_options',
            text: 'Make',
            is_required: false,
            isMobileAnimated: true,
            isCaps: true,
        },
        {
            id: 'model',
            type: 'searchable_options',
            text: 'Model',
            is_required: false,
            isMobileAnimated: true,
            isCaps: true,
        },
        {
            id: 'model_variant',
            type: 'simple_txt',
            text: 'Edition / Series',
            is_required: false,
            isMobileAnimated: true,
        },
        {
            id: 'manufacture_year',
            type: 'number',
            text: 'Year',
            is_required: false,
            isMobileAnimated: true,
            placeholderText: 'Enter year...',
        },
        {
            id: 'body_type',
            type: 'searchable_options',
            text: 'Body type',
            is_required: false,
            isMobileAnimated: true,
        },
        {
            id: 'engine_capacity',
            type: 'number_with_dropdown',
            text: 'Engine size',
            is_required: false,
            options: [
                { uid: 'liters', name: 'liters' },
                { uid: 'cubic_centimeters', name: 'cc' },
                { uid: 'cubic_inches', name: 'ci' },
            ],
            contains_front_dropdown: 'unit',
            default_dropdown_value: { uid: 'cubic_centimeters', name: 'cc' },
            isMobileAnimated: true,
            patch_as_int: true,
        },
        {
            id: 'engine_fuel_type',
            type: 'dropdown',
            text: 'Fuel type',
            is_required: false,
            options: powertype,
            isMobileAnimated: true,
        },
        {
            id: 'transmission',
            type: 'searchable_options',
            text: 'Transmission',
            is_required: false,
            options: transmission,
            isMobileAnimated: true,
        },
        {
            id: 'drive_train',
            type: 'dropdown',
            text: 'Drive train',
            is_required: false,
            options: drive_train,
            isMobileAnimated: true,
        },
        {
            id: 'doors',
            type: 'dropdown',
            text: 'Number of doors',
            options: [
                { uid: '1', name: '1' },
                { uid: '2', name: '2' },
                { uid: '3', name: '3' },
                { uid: '4', name: '4' },
                { uid: '5', name: '5' },
                { uid: '6', name: '6' },
            ],
            is_required: false,
            isMobileAnimated: true,
            patch_as_int: true,
        },
        {
            id: 'seats',
            type: 'dropdown',
            text: 'Number of seats',
            options: [
                { uid: '1', name: '1' },
                { uid: '2', name: '2' },
                { uid: '3', name: '3' },
                { uid: '4', name: '4' },
                { uid: '5', name: '5' },
                { uid: '6', name: '6' },
                { uid: '7', name: '7' },
                { uid: '8', name: '8' },
                { uid: '9', name: '9' },
                { uid: '10', name: '10+' },
            ],
            is_required: false,
            isMobileAnimated: true,
            patch_as_int: true,
        },
        {
            id: 'drive_side',
            type: 'dropdown',
            text: 'Drive Side',
            options: [
                { uid: 'Left', name: 'Left' },
                { uid: 'Right', name: 'Right' },
                { uid: 'Center', name: 'Center' },
            ],
            isMobileAnimated: true,
            is_required: false,
            ends_section_with_divider: true,
        },
        {
            id: 'has_vehicle_modifications',
            type: 'yes_no',
            text: 'Is the vehicle modified?',
            parent_entity_id: 'modifications',
            has_self_replicating_subform_if_true: true,
            has_subform_if_false: true,
            has_subform_if_true: true,
            contains_important_tooltip_text:
                "We class any change made away from the manufacturer's standard specification as a modification. This could include things like changes to bodywork, suspension or brakes. Please be aware all changes to the manufactuer's standard specification needs to be disclosed",
        },
    ]

export const has_vehicle_modifications_negative_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'is_planned_on_getting_modified',
            type: 'yes_no',
            text: 'Do you intend on modifying the vehicle within the next 12 months?',
            is_required: false,
        },
    ]

export const has_vehicle_modifications_positive_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'has_modification_adjusted_bhp',
            type: 'yes_no',
            text: 'Have any of your modifications adjusted your brake horsepower BHP?',
            is_required: false,
            has_subform_if_true: true,
        },
    ]

export const has_modification_adjusted_bhp_positive_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'modified_bhp',
            type: 'number',
            isTextLong: true,
            text: 'What is the current brake horse power (BHP) of the vehicle after accounting for these modifications?',
            contains_important_tooltip_text:
                'Please give as accurate a value as possible.',
            is_required: false,
        },
    ]

export const fields_array_insurance_application_everyday_car_techinfo: IInsuranceFieldType[] =
    [
        // adked somehwre else.
        {
            id: 'registration_number',
            type: 'simple_txt',
            text: 'Registration Number',
            is_required: false,
            isMobileAnimated: true,
            validation: (val) => isTxtLengthValid(val),
            isCaps: true,
        },
        {
            id: 'make',
            type: 'searchable_options',
            text: 'Make',
            is_required: false,
            isMobileAnimated: true,
            isCaps: true,
        },
        {
            id: 'model',
            type: 'searchable_options',
            text: 'Model',
            is_required: false,
            isMobileAnimated: true,
            isCaps: true,
        },
        {
            id: 'model_variant',
            type: 'simple_txt',
            text: 'Edition / Series',
            is_required: false,
            isMobileAnimated: true,
        },
        {
            id: 'manufacture_year',
            type: 'number',
            text: 'Year',
            is_required: false,
            isMobileAnimated: true,
            placeholderText: 'Enter year...',
        },

        {
            id: 'body_type',
            type: 'searchable_options',
            text: 'Body type',
            is_required: false,
            isMobileAnimated: true,
        },
        {
            id: 'engine_capacity',
            type: 'number_with_dropdown',
            text: 'Engine size',
            is_required: false,
            options: [
                { uid: 'liters', name: 'liters' },
                { uid: 'cubic_centimeters', name: 'cc' },
                { uid: 'cubic_inches', name: 'ci' },
            ],
            default_dropdown_value: { uid: 'cubic_centimeters', name: 'cc' },
            contains_front_dropdown: 'unit',
            patch_as_int: true,
        },
        {
            id: 'engine_fuel_type',
            type: 'dropdown',
            text: 'Fuel type',
            is_required: false,
            options: powertype,
            isMobileAnimated: true,
        },
        {
            id: 'transmission',
            type: 'searchable_options',
            text: 'Transmission',
            is_required: false,
            options: transmission,
            isMobileAnimated: true,
        },
        {
            id: 'drive_train',
            type: 'dropdown',
            text: 'Drive train',
            is_required: false,
            options: drive_train,
            isMobileAnimated: true,
        },
        {
            id: 'doors',
            type: 'dropdown',
            text: 'Number of doors',
            options: [
                { uid: '1', name: '1' },
                { uid: '2', name: '2' },
                { uid: '3', name: '3' },
                { uid: '4', name: '4' },
                { uid: '5', name: '5' },
                { uid: '6', name: '6' },
            ],
            is_required: false,
            isMobileAnimated: true,
            patch_as_int: true,
        },
        {
            id: 'seats',
            type: 'dropdown',
            text: 'Number of seats',
            options: [
                { uid: '1', name: '1' },
                { uid: '2', name: '2' },
                { uid: '3', name: '3' },
                { uid: '4', name: '4' },
                { uid: '5', name: '5' },
                { uid: '6', name: '6' },
                { uid: '7', name: '7' },
                { uid: '8', name: '8' },
                { uid: '9', name: '9' },
                { uid: '10', name: '10+' },
            ],
            patch_as_int: true,
            is_required: false,
            isMobileAnimated: true,
        },
        {
            id: 'drive_side',
            type: 'dropdown',
            text: 'Drive Side',
            options: [
                { uid: 'Left', name: 'Left' },
                { uid: 'Right', name: 'Right' },
                { uid: 'Center', name: 'Center' },
            ],
            is_required: false,
        },
    ]

export const fields_array_vehicle_modif: IInsuranceFieldType[] = [
    {
        id: 'modification_type_uid',
        type: 'searchable_options',
        text: 'What is the modification?',
        is_required: false,
        options: [],
        parent_entity_id: 'modifications',
        dropdown_id: 'vehicle_modification_types',
    },
    // {
    //     id: 'modification_date',
    //     type: 'date',
    //     text: 'When was vehicle modified?',
    //     is_required: false,
    //     parent_entity_id: 'modifications',
    // },
    // {
    //     id: 'value',
    //     type: 'number',
    //     text: 'Value of the modification?',
    //     is_required: false,
    //     value_front_field: '£',
    //     is_additional_fields_end: true,
    //     parent_entity_id: 'modifications',
    // },
]

export const insurance_fields_everyday_car_ownership: IInsuranceFieldType[] = [
    {
        id: 'owner',
        type: 'dropdown',
        text: 'Who is the Legal Owner of the vehicle?',
        is_required: false,
        options: [
            { uid: 'PROPOSER', name: 'Proposer' },
            { uid: 'CHILD', name: 'Child' },
            { uid: 'GRAND_CHILD', name: 'Grand child' },
            { uid: 'GRAND_PARENT', name: 'Grand parent' },
            {
                uid: 'PARENT',
                name: 'Parent',
            },
            {
                uid: 'CIVIL_PARTNER',
                name: 'Civil Partner',
            },
            {
                uid: 'PARTNER',
                name: 'Partner',
            },
            {
                uid: 'SIBLING',
                name: 'Sibling',
            },
            {
                uid: 'CIVIL_SPOUSE',
                name: 'Civil Spouse',
            },
            {
                uid: 'SPOUSE',
                name: 'Spouse',
            },
            {
                uid: 'UNRELATED',
                name: 'Unrelated',
            },
            {
                uid: 'OTHER_FAMILY_MEMBER',
                name: 'Other family member',
            },
            {
                uid: 'VEHICLE_LEASING_COMPANY',
                name: 'Vehicle leasing company',
            },
        ],
    },
    {
        id: 'registered_keeper',
        type: 'dropdown',
        text: 'Who is the Registered Keeper of the vehicle?',
        is_required: false,
        options: [
            { uid: 'PROPOSER', name: 'Proposer' },
            { uid: 'CHILD', name: 'Child' },
            { uid: 'GRAND_CHILD', name: 'Grand child' },
            { uid: 'GRAND_PARENT', name: 'Grand parent' },
            {
                uid: 'PARENT',
                name: 'Parent',
            },
            {
                uid: 'CIVIL_PARTNER',
                name: 'Civil Partner',
            },
            {
                uid: 'PARTNER',
                name: 'Partner',
            },
            {
                uid: 'SIBLING',
                name: 'Sibling',
            },
            {
                uid: 'CIVIL_SPOUSE',
                name: 'Civil Spouse',
            },
            {
                uid: 'SPOUSE',
                name: 'Spouse',
            },
            {
                uid: 'UNRELATED',
                name: 'Unrelated',
            },
            {
                uid: 'OTHER_FAMILY_MEMBER',
                name: 'Other family member',
            },
        ],
    },
    {
        id: 'registration_country',
        type: 'searchable_options',
        text: 'Which country is the vehicle registered in?',
        is_required: false,
    },
    {
        id: 'purchase_date',
        type: 'date',
        text: 'When did you buy or start to lease this vehicle?',
        is_required: false,
        custom_date_format: 'MM/yyyy',
        placeholderText: 'MM/YYYY',
    },
    {
        id: 'class_of_use',
        type: 'dropdown',
        options: [
            { uid: 'HIRE_REWARD', name: 'Hire & Reward' },
            { uid: 'MOVIE_HIRE', name: 'Movie Hire' },
            { uid: 'CLASS_ONE_BUSINESS_USE', name: 'Class One Business Use' },
            { uid: 'CLASS_TWO_BUSINESS_USE', name: 'Class Two Business Use' },
            {
                uid: 'CLASS_THREE_BUSINESS_USE',
                name: 'Class Three Business Use',
            },
            {
                uid: 'SOCIAL_DOMESTIC_PLEASURE_ONLY',
                name: 'Social, Domestic & Pleasure Only',
            },
            {
                uid: 'SOCIAL_DOMESTIC_PLEASURE_AND_COMMUTING',
                name: 'Social, Domestic, Pleasure & Commuting',
            },
            {
                uid: 'WEDDING_HIRE',
                name: 'Wedding Hire',
            },
        ],
        text: 'What do you use the vehicle for?',
        is_required: false,
    },
    {
        id: 'ncd_years',
        text: 'How many years no claims discount will you have available to use on this vehicle?',
        is_required: false,
        type: 'number',
        patch_as_int: true,
        placeholderText: 'Enter number of years...',
        contains_important_tooltip_text: `Please be aware we cannot accept no claims discount which will be in use elsewhere when the policy starts. The no claims discount must also have accrued in your name. Where the no claims bonus is included on the policy we will request proof of your no claims discount entitlement as a condition of purchasing the policy.`,
    },
]

// export const insured_vehicle_marked_as_everyday_ncd: IInsuranceFieldType[] = [
//     {
//         id: 'ncd_years',
//         text: 'NCD (No Claims Discount) years',
//         is_required: false,
//         type: 'number',
//         placeholderText: 'Enter number of years...',
//         patch_as_int: true,
//         contains_important_tooltip_text: `The number of years you have not claimed insurance on this vehicle. You should be able to find this value within your existing insurance documents. If your document says 9+ years please estimate the actual number of years you haven't claimed.`,
//     },
// ]

export const insurance_fields_car_ownership: IInsuranceFieldType[] = [
    {
        id: 'owner',
        type: 'dropdown',
        text: 'Who is the Legal Owner of the vehicle?',
        is_required: false,
        options: [
            { uid: 'PROPOSER', name: 'Proposer' },
            { uid: 'CHILD', name: 'Child' },
            { uid: 'GRAND_CHILD', name: 'Grand child' },
            { uid: 'GRAND_PARENT', name: 'Grand parent' },
            {
                uid: 'PARENT',
                name: 'Parent',
            },
            {
                uid: 'CIVIL_PARTNER',
                name: 'Civil Partner',
            },
            {
                uid: 'PARTNER',
                name: 'Partner',
            },
            {
                uid: 'SIBLING',
                name: 'Sibling',
            },
            {
                uid: 'CIVIL_SPOUSE',
                name: 'Civil Spouse',
            },
            {
                uid: 'SPOUSE',
                name: 'Spouse',
            },
            {
                uid: 'UNRELATED',
                name: 'Unrelated',
            },
            {
                uid: 'OTHER_FAMILY_MEMBER',
                name: 'Other family member',
            },
            {
                uid: 'VEHICLE_LEASING_COMPANY',
                name: 'Vehicle leasing company',
            },
        ],
    },
    {
        id: 'registered_keeper',
        type: 'dropdown',
        text: 'Who is the Registered Keeper of the vehicle?',
        is_required: false,
        options: [
            { uid: 'PROPOSER', name: 'Proposer' },
            { uid: 'CHILD', name: 'Child' },
            { uid: 'GRAND_CHILD', name: 'Grand child' },
            { uid: 'GRAND_PARENT', name: 'Grand parent' },
            {
                uid: 'PARENT',
                name: 'Parent',
            },
            {
                uid: 'CIVIL_PARTNER',
                name: 'Civil Partner',
            },
            {
                uid: 'PARTNER',
                name: 'Partner',
            },
            {
                uid: 'SIBLING',
                name: 'Sibling',
            },
            {
                uid: 'CIVIL_SPOUSE',
                name: 'Civil Spouse',
            },
            {
                uid: 'SPOUSE',
                name: 'Spouse',
            },
            {
                uid: 'UNRELATED',
                name: 'Unrelated',
            },
            {
                uid: 'OTHER_FAMILY_MEMBER',
                name: 'Other family member',
            },
        ],
    },
    {
        id: 'registration_country',
        type: 'searchable_options',
        text: 'Which country is the vehicle registered in?',
        is_required: false,
    },
    {
        id: 'is_written_off',
        type: 'dropdown',
        text: 'Has the vehicle previously been written off?',
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
        is_required: false,
        has_subform_if_true: true,
    },
    {
        id: 'is_imported',
        type: 'dropdown',
        text: 'Is this vehicle an import?',
        contains_important_tooltip_text:
            'Was this vehicle sold from manufacture in a different country?',
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
        is_required: false,
        has_subform_if_true: true,
    },
    {
        id: 'purchase_date',
        type: 'date',
        text: 'When did you buy or start to lease this vehicle?',
        is_required: false,
        custom_date_format: 'MM/yyyy',
        placeholderText: 'MM/YYYY',
    },
    {
        id: 'purchased_value',
        type: 'number',
        text: 'How much did you pay for the vehicle?',
        is_required: false,
        value_front_field: '£',
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
        patch_as_int: true,
    },
    {
        id: 'estimated_value',
        type: 'number',
        text: 'Estimated current vehicle value',
        is_required: false,
        value_front_field: '£',
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
        patch_as_int: true,
        contains_important_tooltip_text:
            'This is the cost of replacing your vehicle with another of the same make, specification (for example, the level of equipment found in your vehicle), model, age, mileage and condition as your vehicle was. Please note that the value you quote is not necessarily the amount of pay-out you will receive in the event of a claim.',
    },
    {
        id: 'is_requesting_agreed_valuation',
        text: 'Would you like an Agreed Valuation on this vehicle?',
        is_required: false,
        type: 'dropdown',
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
    },
    {
        id: 'class_of_use',
        type: 'dropdown',
        options: [
            { uid: 'HIRE_REWARD', name: 'Hire & Reward' },
            { uid: 'MOVIE_HIRE', name: 'Movie Hire' },
            { uid: 'CLASS_ONE_BUSINESS_USE', name: 'Class One Business Use' },
            { uid: 'CLASS_TWO_BUSINESS_USE', name: 'Class Two Business Use' },
            {
                uid: 'CLASS_THREE_BUSINESS_USE',
                name: 'Class Three Business Use',
            },
            {
                uid: 'SOCIAL_DOMESTIC_PLEASURE_ONLY',
                name: 'Social, Domestic & Pleasure Only',
            },
            {
                uid: 'SOCIAL_DOMESTIC_PLEASURE_AND_COMMUTING',
                name: 'Social, Domestic, Pleasure & Commuting',
            },
            {
                uid: 'WEDDING_HIRE',
                name: 'Wedding Hire',
            },
        ],

        text: 'What do you use the vehicle for?',
        is_required: false,
        ends_section_with_divider: true,
    },

    {
        id: 'cover_type',
        text: 'What level of cover would you like on the vehicle?',
        is_required: false,
        type: 'dropdown',
        options: [
            { uid: 'COMPREHENSIVE', name: 'Comprehensive' },
            { uid: 'LAID_UP', name: 'Laid Up' },
        ],
        contains_important_tooltip_text:
            "Select the level of insurance cover for your vehicle. 'Comprehensive' provides full protection, including accidents, theft, and third-party liability—ideal for vehicles in regular use. 'Laid Up' is for vehicles stored and not currently in use, covering only theft and damage while off-road. Consider your vehicle's usage and risk exposure to choose the appropriate cover.",
    },
    // {
    //     id: 'has_voluntary_excess',
    //     type: 'yes_no',
    //     text: 'Would you like to add a voluntary excess to this vehicle?',
    //     has_subform_if_true: true,
    //     contains_tooltip_text:
    //         "Voluntary excess is the amount you choose to pay towards a claim on top of the compulsory excess. Opting for voluntary excess can lower your premium but means you'll pay more out of pocket if you make a claim. Consider your financial comfort in the event of a claim when deciding on adding voluntary excess.",
    // },
    {
        id: 'ncd_years',
        text: 'How many years no claims discount will you have available to use on this vehicle?',
        is_required: false,
        type: 'number',
        patch_as_int: true,
        placeholderText: 'Enter number of years...',
        contains_important_tooltip_text: `Please be aware we cannot accept no claims discount which will be in use elsewhere when the policy starts. The no claims discount must also have accrued in your name. Where the no claims bonus is included on the policy we will request proof of your no claims discount entitlement as a condition of purchasing the policy.`,
        ends_section_with_divider: true,
        has_subform_if_true: true,
    },
]

export const is_imported_positive_answer_subfields: IInsuranceFieldType[] = [
    {
        id: 'import_origin_country_code',
        type: 'searchable_options',
        text: 'Where was this vehicle originally sold?',
    },
]

export const ncd_additional_fields: IInsuranceFieldType[] = [
    {
        id: 'was_ncd_uk_earned_on_private_policy',
        isTextLong: true,
        type: 'dropdown',
        text: 'Was the no claims discount earned in the UK on a private car policy?',
        is_required: false,
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
    },
    {
        id: 'is_ncd_in_use',
        type: 'dropdown',
        isTextLong: true,
        text: 'Will the no claims discount be in use on a different policy elsewhere after the start of your Custodian insurance policy?',
        is_required: false,
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
    },
    {
        id: 'has_requested_ncd_protection',
        isTextLong: true,
        type: 'dropdown',
        text: 'Would you like to protect your no claims discount?',
        is_required: false,
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
    },
]

export const written_off_subfields: IInsuranceFieldType[] = [
    {
        id: 'is_q_plated',
        type: 'dropdown',
        text: 'Is the vehicle Q Plated?',
        is_required: false,
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
        isMobileAnimated: true,
    },
]

export const voluntary_excess_subfields: IInsuranceFieldType[] = [
    {
        id: 'voluntary_excess',
        type: 'simple_txt',
        text: 'What amount of voluntary excess would you like?',
        is_required: false,
        is_additional_fields_end: true,
        patch_as_int: true,
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
        contains_important_tooltip_text:
            'Enter the amount of voluntary excess you’re willing to pay in the event of a claim. This is in addition to any compulsory excess. A higher excess can reduce your premium, but you should choose an amount you can afford to pay if you need to make a claim. Common excess amounts range from £250 to £1000.',
    },
]

export const insurance_fields_other_driver_basic_details: IInsuranceFieldType[] =
    [
        {
            id: 'title',
            type: 'searchable_options',
            text: 'Title',
            is_required: false,
            isMobileAnimated: true,
            dropdown_id: 'titles',
        },
        {
            id: 'given_name',
            type: 'simple_txt',
            text: 'Given Name',
            is_required: false,
            isMobileAnimated: true,
            validation: (val) => isTxtLengthValid(val),
            error_txt: 'Invalid',
        },
        {
            id: 'middle_name',
            type: 'simple_txt',
            text: 'Middle name',
            is_required: false,
            isMobileAnimated: true,
        },
        {
            id: 'family_name',
            type: 'simple_txt',
            text: 'Family name',
            is_required: false,
            isMobileAnimated: true,
            validation: (val) => isTxtLengthValid(val),
            error_txt: 'Invalid',
        },
        {
            id: 'dob',
            type: 'date',
            text: 'Date of birth',
            is_required: false,
            isMobileAnimated: true,
            placeholderText: 'DD/MM/YYY',
        },
        {
            id: 'gender',
            type: 'dropdown',
            text: 'Gender as recorded on their driving licence',
            is_required: false,
            options: [
                { uid: 'MALE', name: 'Male' },
                { uid: 'FEMALE', name: 'Female' },
            ],
            isMobileAnimated: true,
        },
        {
            id: 'relationship',
            type: 'dropdown',
            text: 'Relationship status',
            is_required: false,
            options: [
                // { uid: 'UNKNOWN', name: `Unknown` },
                { uid: 'CIVIL_PARTNERSHIP', name: 'Civil Partnership' },
                { uid: 'COMMON_LAW', name: 'Common Law' },
                { uid: 'DIVORCED', name: 'Divorced' },
                { uid: 'MARRIED', name: 'Married' },
                { uid: 'PARTNER', name: 'Partner' },
                { uid: 'SEPARATED', name: 'Separated' },
                { uid: 'SINGLE', name: 'Single' },
                { uid: 'WIDOWED', name: 'Widowed' },
            ],
            ends_section_with_divider: true,
            isMobileAnimated: true,
        },
        {
            id: 'relation_to_customer',
            type: 'dropdown',
            text: 'What is their relationship to you?',
            is_required: false,
            options: [
                // { uid: 'UNKNOWN', name: `Unknown` },
                { uid: 'CHILD', name: 'Child' },
                { uid: 'GRAND_CHILD', name: 'Grandchild' },
                { uid: 'GRAND_PARENT', name: 'Grandparent' },
                { uid: 'PARENT', name: 'Parent' },
                { uid: 'CIVIL_PARTNER', name: 'Civil Partner' },
                { uid: 'PARTNER', name: 'Partner' },
                { uid: 'SIBLING', name: 'Sibling' },
                { uid: 'CIVIL_SPOUSE', name: 'Civil Spouse' },
                { uid: 'SPOUSE', name: 'Spouse' },
                { uid: 'UNRELATED', name: 'Unrelated' },
            ],
            // ends_section_with_divider: true,
        },
        // {
        //     id: 'email',
        //     type: 'email',
        //     text: 'Email',
        //     is_required: false,
        //     error_txt: 'Email is invalid',
        //     validation: (val) => validateEmail(val),
        //     isMobileAnimated: true,
        // },
        // {
        //     id: 'phone_primary',
        //     type: 'phone',
        //     text: 'Phone number',
        //     is_required: false,
        //     value_front_field: '+44',
        //     error_txt: 'Phone number invalid',
        //     validation: (val) => isValidPhoneNumber(`${val}`),
        //     isMobileAnimated: true,
        // },
    ]

export const insurance_fields_other_driver_licence_address: IInsuranceFieldType[] =
    [
        // {
        //     id: 'license_number',
        //     type: 'simple_txt',
        //     text: 'Driving Licence Number',
        //     is_required: false,
        // },
        {
            id: 'license_date',
            type: 'date',
            text: 'Driving Licence Date',
            is_required: false,
            isMobileAnimated: false,
            custom_date_format: 'MM/yyyy',
            placeholderText: 'MM/YYYY',
        },
        {
            id: 'license_type',
            type: 'dropdown',
            text: 'Type of driving license',
            is_required: false,
            options: license_type_options,
            ends_section_with_divider: true,
            isMobileAnimated: false,
        },
        {
            id: 'is_uk_resident',
            type: 'yes_no',
            text: 'Are they a legal permanent resident in the UK?',
            is_required: false,
            has_subform_if_true: true,
            starts_section_with_header: 'Residency',
        },
        {
            id: 'resides_in_the_uk',
            type: 'yes_no',
            text: 'Do they currently reside in the UK?',
            has_subform_if_false: true,
        },
    ]

export const other_driver_is_uk_resident_positive_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'is_uk_resident_since_birth',
            type: 'yes_no',
            text: 'Have they been a legal UK resident since birth?',
            is_required: false,
            has_subform_if_false: true,
        },
    ]

export const other_driver_is_uk_resident_since_birth_negative_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'residency_date',
            type: 'date',
            text: 'When did they become a legal UK resident?',
            is_required: false,
            has_subform_if_false: true,
        },
    ]

export const other_driver_resides_in_the_uk_negative_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'current_country_of_residence_country_code',
            type: 'searchable_options',
            text: 'Where do they currently reside?',
            is_required: false,
        },
    ]

export const insurance_fields_other_driver_additional_details: IInsuranceFieldType[] =
    [
        {
            id: 'family_vehicles_count',
            type: 'number',
            text: 'How many vehicles in their household?',
            is_required: false,
            starts_section_with_header: 'Car ownership',
            patch_as_int: true,
            placeholderText: 'Enter number of vehicles...',
        },
        {
            id: 'vehicles_driven_count',
            type: 'number',
            text: 'How many vehicles do they have access to?',
            is_required: false,
            patch_as_int: true,
            placeholderText: 'Enter number of vehicles...',
        },
        {
            id: 'vehicles_owned_count',
            type: 'number',
            text: 'How many vehicles do they own?',
            is_required: false,
            patch_as_int: true,
            placeholderText: 'Enter number of vehicles...',
        },
        {
            id: 'has_regular_use_vehicle',
            type: 'yes_no',
            text: 'Do they have access to a everyday car for daily use?',
            is_required: false,
            ends_section_with_divider: true,
        },

        {
            id: 'is_homeowner',
            type: 'dropdown',
            text: 'Are they a homeowner?',
            is_required: false,
            options: [
                { uid: 'Yes', name: `Yes` },
                { uid: 'No', name: `No` },
            ],
            starts_section_with_header: 'Home ownership',
        },
        // {
        //     id: 'abode_type',
        //     type: 'dropdown',
        //     text: 'What type of abode do they live in?',
        //     is_required: false,
        //     options: [
        //         // { uid: 'UNKNOWN', name: `Unknown` },
        //         { uid: 'FLAT', name: `Flat` },
        //         { uid: 'HOUSE', name: 'House' },

        //         { uid: 'BOAT', name: 'Boat' },
        //         { uid: 'CARAVAN_STATIC', name: 'Caravan ( static )' },
        //         { uid: 'CARAVAN_MOBILE', name: 'Caravan ( mobile )' },
        //         { uid: 'LICENSED_PREMISES', name: 'Licensed premises' },
        //         { uid: 'OTHER', name: 'Other' },
        //     ],
        //     ends_section_with_divider: true,
        // },

        {
            id: 'occupation_primary',
            type: 'searchable_options',
            text: 'What is their primary occupation?',
            is_required: false,
            options: [],
            starts_section_with_header: 'Occupation',
            patch_field_id: 'occupation_primary_uid',
            dropdown_id: 'occupations',
        },
        {
            id: 'industry_primary',
            type: 'searchable_options',
            text: 'What industry is their primary occupation?',
            is_required: false,
            options: [],
            patch_field_id: 'industry_primary_uid',
            dropdown_id: 'industries',
        },

        {
            id: 'employment_type',
            patch_field_id: 'employment_type_uid',
            type: 'dropdown',
            text: 'What is their employment type?',
            is_required: false,

            options: [],
            dropdown_id: 'employment_types',
        },

        {
            id: 'has_occupation_secondary',
            type: 'dropdown',
            options: [
                { uid: 'No', name: `No` },
                { uid: 'Yes', name: `Yes` },
            ],
            text: 'Do they have a secondary occupation?',
            is_required: false,
            has_subform_if_true: true,
            ends_section_with_divider: true,
        },
        {
            id: 'has_disabilities',
            type: 'yes_no',
            text: 'Do they have any medical disabilities which are disclosable to the DVLA?',
            is_required: false,
            has_self_replicating_subform_if_true: true,

            parent_entity_id: 'disabilities',
        },
    ]

export const membership_self_replicating_fields: IInsuranceFieldType[] = [
    {
        id: 'motoring_club_name',
        type: 'searchable_options',
        text: 'Car club/association name',
        is_required: false,
        is_additional_fields_end: true,
        options: [],
        parent_entity_id: 'memberships',
        dropdown_id: 'motoring_clubs',
        patch_field_id: 'motoring_club_uid',
    },
]
export const insurance_sub_fields_other_driver_occupation_secondary: IInsuranceFieldType[] =
    [
        {
            id: 'occupation_secondary',
            type: 'searchable_options',
            text: 'What is the secondary occupation?',
            is_required: false,
            options: [],
            starts_section_with_header: 'Occupation',
            dropdown_id: 'occupations',
            patch_field_id: 'occupation_secondary_uid',
        },
        {
            id: 'industry_secondary',
            type: 'searchable_options',
            text: 'What industry is the secondary occupation in?',
            is_required: false,
            options: [],
            dropdown_id: 'industries',
            patch_field_id: 'industry_secondary_uid',
        },
    ]

export const subquestions_claims_drivers: IInsuranceFieldType[] = [
    {
        id: 'has_previous_special_terms_imposed',
        type: 'yes_no',
        text: 'Have they previously had any special terms imposed on a motor insurance policy?',
        is_required: false,
        has_subform_if_true: true,
        contains_important_tooltip_text: `Driver requires a special steering wheel, or special parking restrictions for a particularly valuable vehicle in an unsafe area.`,
    },

    {
        id: 'has_previous_motor_insurance_refused',
        type: 'yes_no',
        text: 'Have they previously had any motor insurance refused by your insurer?',
        is_required: false,
        has_subform_if_true: true,
    },
    {
        id: 'has_previous_motor_insurance_cancelled',
        type: 'yes_no',
        text: 'Have they previously had any motor insurance cancelled by your insurer?',
        is_required: false,
        has_subform_if_true: true,
    },

    {
        id: 'has_previous_motor_insurance_voided',
        type: 'yes_no',
        text: 'Have they previously had any motor insurance policy voided by your insurer?',
        is_required: false,
        has_subform_if_true: true,
    },
]

export const subquestions_claims_customer: IInsuranceFieldType[] = [
    {
        id: 'has_previous_special_terms_imposed',
        type: 'yes_no',
        text: 'Have you previously had any special terms imposed on a motor insurance policy?',
        is_required: false,
        has_subform_if_true: true,
        contains_important_tooltip_text: `Driver requires a special steering wheel' or 'special parking restrictions for a particularly valuable vehicle in an unsafe area.`,
    },

    {
        id: 'has_previous_motor_insurance_refused',
        type: 'yes_no',
        text: 'Have you previously had any motor insurance refused by your insurer?',
        is_required: false,
        has_subform_if_true: true,
    },
    {
        id: 'has_previous_motor_insurance_cancelled',
        type: 'yes_no',
        text: 'Have you previously had any motor insurance cancelled by your insurer?',
        is_required: false,
        has_subform_if_true: true,
    },

    {
        id: 'has_previous_motor_insurance_voided',
        type: 'yes_no',
        text: 'Have you previously had any motor insurance policy voided by your insurer?',
        is_required: false,
        has_subform_if_true: true,
    },
]

export const insurance_fields_other_driver_track_record: IInsuranceFieldType[] =
    [
        {
            id: 'has_any_previous_insurance_refusals',
            type: 'yes_no',
            text: 'Have they previously had insurance refused, cancelled, voided or had any special terms imposed?',
            is_required: false,
            has_subform_if_true: true,
            contains_important_tooltip_text:
                'Special terms are bespoke terms added to a policy which are outside of the typical terms offered by the insurance company. This can include removal of certain cover benefits due to your claims history or medical condition, or specific endorsements requiring certain alterations to the vehicle where have a disability affecting your mobility.',
        },
        {
            id: 'has_motoring_convictions',
            type: 'yes_no',
            text: 'Have they had any motoring convictions in the last 5 years?',
            is_required: false,
            has_self_replicating_subform_if_true: true,
            parent_entity_id: 'motoring_convictions',
        },
        {
            id: 'has_convictions',
            type: 'yes_no',
            text: 'Do they have any unspent criminal convictions?',
            is_required: false,
            has_self_replicating_subform_if_true: true,
            parent_entity_id: 'convictions',
            contains_important_tooltip_text:
                'A “spent conviction” is a conviction which, under the terms of the Rehabilitation of Offenders Act 1974, can be effectively ignored after a specified amount of time. If, however, you have received a prison sentence of four years or more, your convictions will never become spent.',
        },
        {
            id: 'has_claims',
            type: 'yes_no',
            text: 'Have they had any motor accidents or claims in the last 5 years?',
            is_required: false,
            parent_entity_id: 'claims',
            has_self_replicating_subform_if_true: true,
            contains_important_tooltip_text:
                'We need to know details of all types of damage and accidents, such as fire, theft, glass, malicious damage; including details of all incidents where you were not at fault or were driving a different vehicle.',
        },
    ]

export const disability_self_replicating_fields: IInsuranceFieldType[] = [
    {
        id: 'dvla_disability',
        type: 'searchable_options',
        text: 'What is the disability?',
        is_required: false,
        isMobileAnimated: false,
        parent_entity_id: 'disabilities',
        dropdown_id: 'disability_types',
        patch_field_id: 'dvla_disability_uid',
    },
    {
        id: 'dvla_has_notified_disability',
        type: 'dropdown',
        text: 'Was the DVLA notified?',
        is_required: false,
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
        has_subform_if_true: false,
        parent_entity_id: 'disabilities',
    },
    {
        id: 'has_license_restriction',
        type: 'dropdown',
        text: 'Did the condition result in a UK license restriction?',
        is_required: false,
        options: [
            { uid: 'No', name: `No` },
            { uid: 'Yes', name: `Yes` },
        ],
        has_subform_if_true: true,
        parent_entity_id: 'disabilities',
    },
]

// export const insurance_sub_sub_fields_disabilities_rescriction: IInsuranceFieldType[] =
//     [
//         {
//             id: 'has_disability_license_question',
//             type: 'dropdown',
//             text: 'Did the condition result in a UK license restriction?',
//             is_required: false,
//             has_subform_if_true: true,
//             options: [
//                 { uid: 'No', name: `No` },
//                 { uid: 'Yes', name: `Yes` },
//             ],
//             parent_entity_id: 'disabilities',
//         },
//     ]

export const insurance_sub_sub_fields_disabilities_rescriction_time: IInsuranceFieldType[] =
    [
        {
            id: 'dvla_disability_license_restriction_length',
            type: 'dropdown',
            text: 'How long was the UK License restriction in place?',
            is_required: false,
            options: [
                { uid: '1', name: `1 year` },
                { uid: '2', name: `2 years` },
                { uid: '3', name: `3 years` },
                { uid: '4', name: `4 years` },
            ],
            parent_entity_id: 'disabilities',
            patch_as_int: true,
        },
    ]

export const insurance_sub_fields_other_driver_track_record_conviction_replicating: IInsuranceFieldType[] =
    [
        {
            id: 'type',
            type: 'dropdown',
            text: 'What type of offence was committed?',
            is_required: false,
            options: [],
            parent_entity_id: 'convictions',
            patch_field_id: 'type_uid',
            dropdown_id: 'conviction_types',
        },
        {
            id: 'sentence_type',
            type: 'dropdown',
            text: 'What was the sentence type?',
            is_required: false,
            options: [],
            parent_entity_id: 'convictions',
            patch_field_id: 'sentence_type_uid',
            dropdown_id: 'conviction_sentence_types',
        },
        {
            id: 'offence_date',
            type: 'date',
            text: 'What date did the offence occur?',
            is_required: false,
            parent_entity_id: 'convictions',
        },

        {
            id: 'sentence',
            type: 'dates_range',
            text: 'Sentence',
            is_required: false,
            parent_entity_id: 'convictions',
        },
        {
            id: 'release_type',
            type: 'dropdown',
            text: 'What was the release type?',
            is_required: false,
            options: [
                // { uid: 'UNKNOWN', name: `Unknown` },
                { uid: 'EARLY_RELEASE', name: `Early Release` },
                { uid: 'FULL_TERM', name: `Full Term` },
            ],
            parent_entity_id: 'convictions',
        },
        {
            id: 'early_sentence_release_date',
            type: 'date',
            text: 'When were they released?',
            is_required: false,
            parent_entity_id: 'convictions',
        },
    ]

export const insurance_sub_fields_other_driver_track_record_motorconviction_replicating: IInsuranceFieldType[] =
    [
        {
            id: 'type',
            type: 'searchable_options',
            text: 'What was the conviction type?',
            is_required: false,
            options: [],
            parent_entity_id: 'motoring_convictions',
            patch_field_id: 'type_uid',
            dropdown_id: 'motoring_conviction_types',
        },
        {
            id: 'code',
            type: 'searchable_options',
            text: 'What was the conviction code?',
            is_required: false,
            options: [],
            parent_entity_id: 'motoring_convictions',
            patch_field_id: 'code_uid',
            dropdown_id: 'motoring_conviction_codes',
        },
        {
            id: 'offence_date',
            type: 'date',
            text: 'What date did the offence occur?',
            is_required: false,
            parent_entity_id: 'motoring_convictions',
        },
        // {
        //     id: 'conviction_date',
        //     type: 'date',
        //     text: 'On what date was the conviction for the offence handed down?',
        //     is_required: false,
        //     parent_entity_id: 'convictions',
        // },
        {
            id: 'has_resulted_in_driving_ban',
            type: 'dropdown',
            text: 'Did the conviction result in a driving ban?',
            is_required: false,
            options: [
                { uid: 'No', name: `No` },
                { uid: 'Yes', name: `Yes` },
            ],
            has_subform_if_true: true,
            parent_entity_id: 'motoring_convictions',
        },

        {
            id: 'has_resulted_in_penalty_points',
            type: 'dropdown',
            text: 'Did the conviction result in penalty points being issued?',
            is_required: false,
            options: [
                { uid: 'No', name: `No` },
                { uid: 'Yes', name: `Yes` },
            ],
            parent_entity_id: 'motoring_convictions',
            has_subform_if_true: true,
        },

        {
            id: 'has_resulted_in_fine',
            type: 'dropdown',
            text: 'Did the conviction result in a fine?',
            is_required: false,
            options: [
                { uid: 'No', name: `No` },
                { uid: 'Yes', name: `Yes` },
            ],
            parent_entity_id: 'motoring_convictions',
            has_subform_if_true: true,
        },
    ]

export const insurance_sub_fields_other_driver_track_record_motorconviction_date: IInsuranceFieldType[] =
    [
        {
            // TODO for GET
            id: 'driving_ban',
            // driving_ban_end_date
            // prev was   id: 'motoring_convictions-drivingban',
            type: 'dates_range',
            text: 'Disqualification',
            is_required: false,
            parent_entity_id: 'motoring_convictions',
        },
    ]
export const insurance_sub_fields_other_driver_track_record_motorconviction_fine_amount: IInsuranceFieldType[] =
    [
        {
            // TODO
            id: 'fine_amount',
            type: 'number',
            text: 'Fine amount',
            is_required: false,
            parent_entity_id: 'motoring_convictions',
            is_price: true,
            localOnChangeValidation: (val) => validatePrice(val),

            patch_as_int: true,
        },
    ]

export const insurance_sub_fields_other_driver_track_record_motorconviction_points_awarded: IInsuranceFieldType[] =
    [
        {
            // TODO
            id: 'points_awarded',
            type: 'number',
            text: 'Points awarded',
            is_required: false,
            parent_entity_id: 'motoring_convictions',
            patch_as_int: true,
            placeholderText: 'Enter number of points...',
        },
    ]

export const insurance_fields_main_driver_additional_details: IInsuranceFieldType[] =
    [
        {
            id: 'family_vehicles_count',
            type: 'number',
            text: 'How many vehicles in your household?',
            is_required: false,
            starts_section_with_header: 'Car ownership',
            patch_as_int: true,
            placeholderText: 'Enter number of vehicles...',
        },
        {
            id: 'vehicles_driven_count',
            type: 'number',
            text: 'How many vehicles do you have access to?',
            is_required: false,
            patch_as_int: true,
            placeholderText: 'Enter number of vehicles...',
        },
        {
            id: 'vehicles_owned_count',
            type: 'number',
            text: 'How many vehicles do you own?',
            is_required: false,
            patch_as_int: true,
            placeholderText: 'Enter number of vehicles...',
            ends_section_with_divider: true,
        },
        {
            id: 'is_homeowner',
            type: 'dropdown',
            text: 'Are you a homeowner?',
            is_required: false,
            options: [
                { uid: 'Yes', name: `Yes` },
                { uid: 'No', name: `No` },
            ],
            starts_section_with_header: 'Home ownership',
        },
        {
            id: 'abode_type',
            type: 'dropdown',
            text: 'What type of property do you live in?',
            is_required: false,
            options: [
                // { uid: 'UNKNOWN', name: `Unknown` },
                { uid: 'FLAT', name: `Flat` },
                { uid: 'HOUSE', name: 'House' },
                { uid: 'BOAT', name: 'Boat' },
                { uid: 'CARAVAN_STATIC', name: 'Caravan ( static )' },
                { uid: 'CARAVAN_MOBILE', name: 'Caravan ( mobile )' },
                { uid: 'LICENSED_PREMISES', name: 'Licensed premises' },
                { uid: 'OTHER', name: 'Other' },
            ],
            ends_section_with_divider: true,
        },

        {
            id: 'occupation_primary',
            type: 'searchable_options',
            text: 'What is your primary occupation?',
            is_required: false,
            options: [],
            starts_section_with_header: 'Occupation',
            patch_field_id: 'occupation_primary_uid',
            dropdown_id: 'occupations',
        },
        {
            id: 'industry_primary',
            type: 'searchable_options',
            text: 'What industry is your primary occupation?',
            is_required: false,
            options: [],
            patch_field_id: 'industry_primary_uid',
            dropdown_id: 'industries',
        },
        {
            id: 'employment_type',
            type: 'dropdown',
            text: 'What is your employment type?',
            is_required: false,
            patch_field_id: 'employment_type_uid',
            options: [],
            dropdown_id: 'employment_types',
        },

        {
            id: 'has_occupation_secondary',
            type: 'dropdown',
            text: 'Do you have a secondary occupation?',
            is_required: false,
            options: [
                { uid: 'No', name: `No` },
                { uid: 'Yes', name: `Yes` },
            ],
            has_subform_if_true: true,
            ends_section_with_divider: true,
        },
        {
            id: 'has_disabilities',
            type: 'yes_no',
            text: 'Do you have any medical disabilities which are disclosable to the DVLA?',
            is_required: false,
            has_self_replicating_subform_if_true: true,
            ends_section_with_divider: true,
            starts_section_with_header: 'Disabilities',
            parent_entity_id: 'disabilities',
        },
        {
            id: 'has_memberships',
            type: 'dropdown',
            text: 'Are you the member of any motoring or car club/association?',
            is_required: false,
            options: [
                { uid: 'No', name: `No` },
                { uid: 'Yes', name: `Yes` },
            ],
            has_self_replicating_subform_if_true: true,
            starts_section_with_header: 'Memberships',
            parent_entity_id: 'memberships',
        },
    ]

export const frequency_insurance_other_driver: IInsuranceFieldType[] = [
    {
        id: 'use_frequency',
        type: 'dropdown',
        text: 'Use frequency',
        is_required: false,
        options: [
            { uid: 'EXCLUDED', name: 'Excluded' },
            { uid: 'FREQUENT', name: 'Frequent' },
            { uid: 'INFREQUENT', name: 'Infrequent' },
            { uid: 'MAIN_USER', name: 'Main user' },
            { uid: 'NOT_DRIVING', name: 'Not driving' },
            { uid: 'RARELY', name: 'Rarely' },
            { uid: 'TEMPORARY', name: 'Temporarily' },
        ],
        ends_section_with_divider: true,
        isMobileAnimated: true,
        contains_important_tooltip_text:
            'You, as the proposer, do not need to be the main user of the vehicle',
    },
]

export const frequency_insurance_other_driver_ownership: IInsuranceFieldType[] =
    [
        {
            id: 'use_frequency',
            type: 'dropdown',
            text: 'Use frequency',
            is_required: true,
            options: [
                { uid: 'EXCLUDED', name: 'Excluded' },
                { uid: 'FREQUENT', name: 'Frequent' },
                { uid: 'INFREQUENT', name: 'Infrequent' },
                { uid: 'MAIN_USER', name: 'Main user' },
                { uid: 'NOT_DRIVING', name: 'Not driving' },
                { uid: 'RARELY', name: 'Rarely' },
                { uid: 'TEMPORARY', name: 'Temporarily' },
            ],
            isMobileAnimated: true,
            contains_important_tooltip_text:
                'You, as the proposer, do not need to be the main user of the vehicle',
        },
    ]
export const insurance_fields_quick_new_driver: IInsuranceFieldType[] = [
    // {
    //     id: 'title',
    //     type: 'searchable_options',
    //     text: 'Title',
    //     is_required: false,
    //     isMobileAnimated: true,
    //     dropdown_id: 'titles',
    // },
    {
        id: 'given_name',
        type: 'simple_txt',
        text: 'Given Name',
        is_required: false,
        validation: (val) => isTxtLengthValid(val),
        error_txt: 'Invalid',
    },
    {
        id: 'middle_name',
        type: 'simple_txt',
        text: 'Middle name',
        is_required: false,
    },
    {
        id: 'family_name',
        type: 'simple_txt',
        text: 'Family name',
        is_required: false,
        validation: (val) => isTxtLengthValid(val),
        error_txt: 'Invalid',
    },
]
export const insurance_fields_main_driver_basic_details: IInsuranceFieldType[] =
    [
        {
            id: 'title',
            type: 'searchable_options',
            text: 'Title',
            is_required: false,
            isMobileAnimated: true,
            dropdown_id: 'titles',
        },
        {
            id: 'given_name',
            type: 'simple_txt',
            text: 'Given Name',
            is_required: false,
            isMobileAnimated: true,
            validation: (val) => isTxtLengthValid(val),
            error_txt: 'Invalid',
        },
        {
            id: 'middle_name',
            type: 'simple_txt',
            isMobileAnimated: true,
            text: 'Middle name',
            is_required: false,
        },
        {
            id: 'family_name',
            type: 'simple_txt',
            isMobileAnimated: true,
            text: 'Last name',
            is_required: false,
            validation: (val) => isTxtLengthValid(val),
            error_txt: 'Invalid',
        },
        {
            id: 'dob',
            type: 'date',
            isMobileAnimated: true,
            text: 'Date of birth',
            is_required: false,
            placeholderText: 'DD/MM/YYYY',
        },
        {
            id: 'gender',
            type: 'dropdown',
            text: 'Gender as recorded on your driving licence',
            is_required: false,
            isMobileAnimated: true,
            options: [
                { uid: 'MALE', name: 'Male' },
                { uid: 'FEMALE', name: 'Female' },
            ],
        },
        {
            id: 'relationship',
            type: 'dropdown',
            text: 'Relationship status',
            is_required: false,
            isMobileAnimated: true,
            options: [
                // { uid: 'UNKNOWN', name: `Unknown` },
                { uid: 'CIVIL_PARTNERSHIP', name: 'Civil Partnership' },
                { uid: 'COMMON_LAW', name: 'Common Law' },
                { uid: 'DIVORCED', name: 'Divorced' },
                { uid: 'MARRIED', name: 'Married' },
                { uid: 'PARTNER', name: 'Partner' },
                { uid: 'SEPARATED', name: 'Separated' },
                { uid: 'SINGLE', name: 'Single' },
                { uid: 'WIDOWED', name: 'Widowed' },
            ],
            ends_section_with_divider: true,
        },
        {
            id: 'nin',
            type: 'simple_txt',
            text: 'National insurance number',
            is_required: false,
            isMobileAnimated: true,
            validation: (val) => isTxtLengthValid(val),
            error_txt: 'Invalid',
            contains_important_tooltip_text:
                'We use your National insurance number to validate your submitted information against your DVLA records.',
        },
        {
            id: 'email',
            type: 'email',
            text: 'Email',
            is_required: false,
            error_txt: 'Email is invalid',
            validation: (val) => validateEmail(val),
            isMobileAnimated: true,
        },
        {
            id: 'phone_primary',
            type: 'phone',
            text: 'Phone number',
            is_required: false,
            value_front_field: '+44',
            error_txt: 'Phone number invalid',
            validation: (val) => isValidPhoneNumber(`${val}`),
            isMobileAnimated: true,
        },
    ]

export const licence_number_subfields: IInsuranceFieldType[] = [
    {
        id: 'license_issue_location',
        type: 'dropdown',
        text: 'Where was your licence issued?',
        is_required: false,
        patch_field_id: 'license_issue_location_id',
        dropdown_id: 'license_issue_locations',
    },
]

export const insurance_fields_main_driver_licence_address: IInsuranceFieldType[] =
    [
        {
            id: 'license_type',
            type: 'dropdown',
            text: 'Type of driving license',
            is_required: false,
            options: license_type_options,
            has_subform_if_values: [
                'FULL',
                'MEDICALLY_RESTRICTED',
                'PROVISIONAL',
                'OTHER',
            ],
            subform: licence_number_subfields,
        },
        {
            id: 'license_number',
            type: 'simple_txt',
            text: 'Driving Licence Number',
            is_required: false,
            validation: (val) => isTxtLengthValid(val),
            error_txt: 'Invalid',
        },
        {
            id: 'license_date',
            type: 'date',
            text: 'What date did you pass driving test?',
            is_required: false,
            custom_date_format: 'MM/yyyy',
            placeholderText: 'MM/YYYY',
            ends_section_with_divider: true,
        },
        // {
        //     id: 'license_postcode',
        //     type: 'simple_txt',
        //     validation: (val) => isTxtLengthValid(val),
        //     text: 'Drivers licence postcode',
        //     is_required: false,
        //     isMobileAnimated: false,
        //     ends_section_with_divider: true,
        // },
        {
            id: 'is_uk_resident',
            type: 'yes_no',
            text: 'Are you a legal permanent resident in the UK?',
            starts_section_with_header: 'Residency',
            has_subform_if_true: true,
        },
        {
            id: 'resides_in_the_uk',
            type: 'yes_no',
            text: 'Do you predominantly live in the UK?',
            has_subform_if_false: true,
            ends_section_with_divider: true,
        },
    ]

export const is_uk_resident_positive_answer_subform: IInsuranceFieldType[] = [
    {
        id: 'is_uk_resident_since_birth',
        type: 'yes_no',
        text: 'Have you been a UK resident since birth?',
        is_required: false,
        has_subform_if_false: true,
    },
]

export const is_uk_resident_since_birth_negative_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'residency_date',
            type: 'date',
            text: 'When did you become a legal UK resident?',
            is_required: false,
            has_subform_if_false: true,
        },
    ]

export const resides_in_the_uk_negative_answer_subform: IInsuranceFieldType[] =
    [
        {
            id: 'current_country_of_residence_country_code',
            type: 'searchable_options',
            text: 'Where do you currently reside?',
            is_required: false,
        },
    ]

export const insurance_fields_main_driver_track_record: IInsuranceFieldType[] =
    [
        {
            id: 'has_any_previous_insurance_refusals',
            type: 'yes_no',
            text: 'Have you previously had insurance refused, cancelled, voided or had any special terms imposed?',
            is_required: false,
            has_subform_if_true: true,
            contains_important_tooltip_text:
                'Special terms are bespoke terms added to a policy which are outside of the typical terms offered by the insurance company. This can include removal of certain cover benefits due to your claims history or medical condition, or specific endorsements requiring certain alterations to the vehicle where have a disability affecting your mobility.',
        },

        {
            id: 'has_motoring_convictions',
            type: 'yes_no',
            text: 'Have you had any motoring convictions in the last 5 years?',
            is_required: false,
            has_self_replicating_subform_if_true: true,
            has_subform_if_true: true,
            parent_entity_id: 'motoring_convictions',
        },
        {
            id: 'has_convictions',
            type: 'yes_no',
            text: 'Do you have any unspent criminal convictions?',
            is_required: false,
            has_self_replicating_subform_if_true: true,
            contains_important_tooltip_text:
                'A “spent conviction” is a conviction which, under the terms of the Rehabilitation of Offenders Act 1974, can be effectively ignored after a specified amount of time. If, however, you have received a prison sentence of four years or more, your convictions will never become spent.',
            parent_entity_id: 'convictions',
        },
        {
            id: 'has_claims',
            type: 'yes_no',
            text: 'Have you had any motor accidents or claims in the last 5 years?',
            is_required: false,
            has_self_replicating_subform_if_true: true,
            contains_important_tooltip_text:
                'We need to know details of all types of damage and accidents, such as fire, theft, glass, malicious damage; including details of all incidents where you were not at fault or were driving a different vehicle.',
            parent_entity_id: 'claims',
        },
    ]

export const self_replicating_claim: IInsuranceFieldType[] = [
    {
        id: 'incident_date',
        type: 'date',
        text: 'When did the incident occur?',
        is_required: true,
        parent_entity_id: 'claims',
    },
    // {
    //     id: 'reported_date',
    //     type: 'date',
    //     text: 'What date was the claim reported to your insurer?',
    //     is_required: true,
    // },
    {
        id: 'type',
        type: 'dropdown',
        text: 'What was the type of claim?',
        is_required: true,
        options: [],
        parent_entity_id: 'claims',
        patch_field_id: 'type_uid',
        dropdown_id: 'claim_types',
    },
    {
        id: 'liability',
        type: 'dropdown',
        text: 'What is the liability status of the claim?',
        is_required: true,
        options: [
            // { uid: 'UNKNOWN', name: 'Unknown' },
            { uid: 'FAULT', name: 'Fault' },
            { uid: 'NON_FAULT', name: 'Non fault' },
            { uid: 'PARTIAL', name: 'Partial' },
        ],
        parent_entity_id: 'claims',
    },

    {
        id: 'status',
        type: 'dropdown',
        text: 'What is the status of the claim?',
        is_required: true,
        options: [
            // { uid: 'UNKNOWN', name: 'Unknown' },
            { uid: 'OPEN', name: 'Open' },
            { uid: 'SETTLED', name: 'Settled' },
        ],
        parent_entity_id: 'claims',
    },
    // {
    //     id: 'settled_date',
    //     type: 'date',
    //     text: 'What date was the claim settled?',
    //     is_required: true,
    //     parent_entity_id: 'claims',
    // },
    // {
    //     id: 'has_accidental_damage',
    //     type: 'dropdown',
    //     text: 'Were there any accidental damage costs?',
    //     is_required: true,
    //     options: [
    //         { uid: 'Yes', name: 'Yes' },
    //         { uid: 'No', name: 'No' },
    //     ],
    //     has_subform_if_true: true,
    //     parent_entity_id: 'claims',
    // },

    {
        id: 'has_third_party_personal_injury',
        type: 'dropdown',
        text: 'Were there any third party personal injury costs?',
        is_required: true,
        options: [
            { uid: 'Yes', name: 'Yes' },
            { uid: 'No', name: 'No' },
        ],
        has_subform_if_true: true,
        parent_entity_id: 'claims',
    },
    // {
    //     id: 'has_third_party_property_damage',
    //     type: 'dropdown',
    //     text: 'Were there any third party property damage costs?',
    //     is_required: true,
    //     options: [
    //         { uid: 'Yes', name: 'Yes' },
    //         { uid: 'No', name: 'No' },
    //     ],
    //     has_subform_if_true: true,
    //     parent_entity_id: 'claims',
    // },
    // {
    //     id: 'has_fire_damage',
    //     type: 'dropdown',
    //     text: 'Were there any fire damage costs?',
    //     is_required: true,
    //     options: [
    //         { uid: 'Yes', name: 'Yes' },
    //         { uid: 'No', name: 'No' },
    //     ],
    //     has_subform_if_true: true,
    //     parent_entity_id: 'claims',
    // },
    // {
    //     id: 'has_theft',
    //     type: 'dropdown',
    //     text: 'Were there any theft costs?',
    //     is_required: true,
    //     options: [
    //         { uid: 'Yes', name: 'Yes' },
    //         { uid: 'No', name: 'No' },
    //     ],
    //     has_subform_if_true: true,
    //     parent_entity_id: 'claims',
    // },
    // {
    //     id: 'has_malicious_or_vandalism_damage',
    //     type: 'dropdown',
    //     text: 'Were there any malicious damage or vandalism costs?',
    //     is_required: true,
    //     options: [
    //         { uid: 'Yes', name: 'Yes' },
    //         { uid: 'No', name: 'No' },
    //     ],
    //     has_subform_if_true: true,
    //     parent_entity_id: 'claims',
    // },
    // {
    //     id: 'has_windscreen_damage',
    //     type: 'dropdown',
    //     text: 'Were there any windscreen or glass damage costs?',
    //     is_required: true,
    //     options: [
    //         { uid: 'Yes', name: 'Yes' },
    //         { uid: 'No', name: 'No' },
    //     ],
    //     has_subform_if_true: true,
    //     parent_entity_id: 'claims',
    // },
    {
        id: 'total_cost',
        type: 'number',
        text: 'What were the total claim costs?',
        is_required: true,
        contains_important_tooltip_text: `It's a combined value of previous claim costs.`,
        value_front_field: '£',
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
        patch_as_int: true,
    },
]

export const accidental_damage_cost_subform: IInsuranceFieldType[] = [
    {
        id: 'accidental_damage_cost',
        type: 'number',
        text: 'What were the accidental damage costs?',
        is_required: true,
        patch_as_int: true,
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
    },
]

// export const accidental_third_party_injury_cost_subform: IInsuranceFieldType[] =
//     [
//         {
//             id: 'third_party_personal_injury_cost',
//             type: 'number',
//             text: 'What were the third party personal injury costs?',
//             is_required: true,
//             patch_as_int: true,
//             is_price: true,
//         },
//     ]
export const third_party_damage_property_cost_subform: IInsuranceFieldType[] = [
    {
        id: 'third_party_property_damage_cost',
        type: 'number',
        text: 'What were the third party property damage costs?',
        is_required: true,
        patch_as_int: true,
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
    },
]

export const accidental_fire_cost_subform: IInsuranceFieldType[] = [
    {
        id: 'fire_cost',
        type: 'number',
        text: 'What were the fire damage costs?',
        is_required: true,
        is_price: true,
        patch_as_int: true,
        localOnChangeValidation: (val) => validatePrice(val),
    },
]
export const accidental_theft_cost_subform: IInsuranceFieldType[] = [
    {
        id: 'theft_cost',
        type: 'number',
        text: 'What were the theft costs?',
        is_required: true,
        patch_as_int: true,
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
    },
]

export const accidental_vandalism_cost_subform: IInsuranceFieldType[] = [
    {
        id: 'vandalism_damage_cost',
        type: 'number',
        text: 'What were the malicious damage or vandalism costs?',
        is_required: true,
        patch_as_int: true,
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
    },
]

export const accidental_glass_cost_subform: IInsuranceFieldType[] = [
    {
        id: 'windscreen_damage_cost',
        type: 'number',
        text: 'What were the windscreen cost?',
        is_required: true,
        patch_as_int: true,
        is_price: true,
        localOnChangeValidation: (val) => validatePrice(val),
    },
]

export const insurance_fields_main_driver_track_record_special_terms_imposed_subform: IInsuranceFieldType[] =
    [
        {
            id: 'previous_special_terms_imposed_reason',
            type: 'short_txt_area',
            text: 'What was the reason for this? ',
            is_required: true,
        },
    ]

export const insurance_fields_track_record_previous_motor_terms_refused_subform: IInsuranceFieldType[] =
    [
        {
            id: 'previous_motor_terms_refused_reason',
            type: 'short_txt_area',
            text: 'What was the reason for this? ',
            is_required: true,
        },
    ]

export const insurance_fields_track_record_previous_motor_terms_cancelled_subform: IInsuranceFieldType[] =
    [
        {
            id: 'previous_motor_insurance_cancelled_reason',
            type: 'short_txt_area',
            text: 'What was the reason for this? ',
            is_required: true,
        },
    ]

export const insurance_fields_track_record_previous_motor_insurance_voided_subform: IInsuranceFieldType[] =
    [
        {
            id: 'previous_motor_insurance_voided_reason',
            type: 'short_txt_area',
            text: 'What was the reason for this? ',
            is_required: true,
        },
    ]

export const insurance_fields_car_mileage_parking_1: IInsuranceFieldType[] = [
    {
        id: 'odometer_reading',
        type: 'number_with_dropdown',
        text: 'Current mileage',
        is_required: false,
        options: [
            { uid: 'miles', name: 'Miles' },
            { uid: 'km', name: 'Kilometers' },
        ],
        default_dropdown_value: { uid: 'miles', name: 'Miles' },
        contains_front_dropdown: 'unit',
        patch_as_int: true,
        isMobileAnimated: true,
    },
    {
        id: 'expected_annual_mileage',
        type: 'number_with_dropdown',
        text: 'Expected annual mileage',
        is_required: false,
        options: [
            { uid: 'miles', name: 'Miles' },
            { uid: 'km', name: 'Kilometers' },
        ],
        default_dropdown_value: { uid: 'miles', name: 'Miles' },
        contains_front_dropdown: 'unit',
        ends_section_with_divider: true,
        patch_as_int: true,
        isMobileAnimated: true,
        // contains_important_tooltip_text:
        //     "The expected annual mileage impacts the level of coverage you qualify for. You may ask for laid up coverage whilst you're not driving on public roads, ie. expected annual mileage is zero. If expected mileage is larger than zero, you will only qualify for comprehensive cover.",
    },
]

export const insurance_fields_car_mileage_parking_added_address_same_as_residential: IInsuranceFieldType[] =
    [
        {
            id: 'is_kept_at_customer_residential_address',
            type: 'yes_no',
            text: 'Is the car kept at your residential address?',
            is_required: false,
            contains_important_tooltip_text: `If the location is different from your residence we will need to ask you few additional questions.`,
        },
    ]

export const insurance_fields_car_mileage_location_type_if_same_as_residential: IInsuranceFieldType[] =
    [
        {
            id: 'location_type',
            type: 'dropdown',
            text: 'Where is the vehicle usually kept?',
            is_required: false,
            options: location_type_options,
        },
    ]

export const insurance_fields_everydaycar_mileage_parking_same_place_day_and_night: IInsuranceFieldType[] =
    [
        {
            id: 'parking_place_same_day_night',
            type: 'yes_no',
            text: 'Is the car kept in the same place during day and night?',
            is_required: false,
            contains_important_tooltip_text:
                'If the location is different from day and night we will need to ask you few additional questions.',
        },
    ]

export const insurance_fields_car_mileage_parking_added_address_different_than_residential: IInsuranceFieldType[] =
    [
        {
            id: 'travels_to_home_address',
            type: 'yes_no',
            text: 'Does the vehicle ever travel to or kept at your residential address?',
            is_required: false,
            has_subform_if_true: true,
        },
        {
            id: 'parking_place_same_day_night',
            type: 'yes_no',
            text: 'Is the car kept in the same place during day and night?',
            is_required: false,
            contains_important_tooltip_text:
                'If the location is different from day and night we will need to ask you few additional questions.',
        },
    ]

export const travel_to_residential_address_yes_subfields: IInsuranceFieldType[] =
    [
        {
            id: 'residential_visit_overnight',
            type: 'yes_no',
            text: 'Will the vehicle ever be kept overnight at your residential address?',
            is_required: false,
            has_subform_if_true: true,
        },
    ]

export const residential_visit_overnight_yes_subfields: IInsuranceFieldType[] =
    [
        {
            id: 'residential_visit_location',
            type: 'dropdown',
            text: 'Where is the vehicle parked when kept overnight at your residential address?',
            options: location_type_options,
            isTextLong: true,
            is_required: false,
        },
    ]

export const insurance_fields_car_mileage_parking_day_place_nigth_same: IInsuranceFieldType[] =
    [
        {
            id: 'location_type',
            type: 'dropdown',
            text: 'Where is the vehicle usually kept when not in use?',
            is_required: false,
            options: location_type_options,
            starts_section_with_header: 'Parking Location',
        },
    ]

export const insurance_fields_car_mileage_parking_day_place: IInsuranceFieldType[] =
    [
        {
            id: 'location_type',
            type: 'dropdown',
            text: 'Where is the vehicle usually kept during the day?',
            is_required: false,
            options: location_type_options,
            starts_section_with_header: 'Day Location',
        },
    ]

export const insurance_fields_car_mileage_parking_night_place: IInsuranceFieldType[] =
    [
        {
            id: 'location_type',
            type: 'dropdown',
            text: 'Where is the vehicle usually kept at night?',
            is_required: false,
            options: location_type_options,
            starts_section_with_header: 'Night Location',
        },
    ]

export const insurance_fields_parking_info: IInsuranceFieldType[] = [
    {
        id: 'has_other_person_access',
        type: 'yes_no',
        text: 'Does anyone else who is not to be named on this policy have access to your vehicle at this address?',
        is_required: false,
        contains_important_tooltip_text:
            'This could include storage management staff, car park staff, or your parents if the car is kept at their house.',
    },
    {
        id: 'inspection_frequency',
        type: 'dropdown',
        text: 'How frequently is the vehicle checked on or inspected when kept at this address?',
        contains_important_tooltip_text:
            'This is how frequently you, a named driver, or the designated keeper inspect the car. Please pick the option which best describes how often the car is inspected.',
        isTextLong: true,
        is_required: false,
        options: inspection_frequency_options,
    },
    {
        id: 'has_other_vehicles_present',
        type: 'yes_no',
        text: 'Are there other vehicles, whether owned by yourself or not, kept at this address?',
        is_required: false,
    },
    {
        id: 'vehicle_travels_to_home_address',
        type: 'yes_no',
        text: 'Does the vehicle ever travel to or is kept at your residential address?',
        is_required: false,
        isDeprecated: true,
    },
    {
        id: 'has_location_security',
        type: 'yes_no',
        text: 'Is there any security in place at this address?',
        is_required: false,
        has_subform_if_true: true,
        ends_section_with_divider: true,
    },
]

export const insurance_fields_car_mileage_parking_security: IInsuranceFieldType[] =
    [
        {
            id: 'has_security_device_fitted',
            type: 'yes_no',
            text: 'Does the vehicle have a fitted security device?',
            is_required: false,
            has_subform_if_true: true,
        },
    ]

// export const location_security_self_replicating_fields: IInsuranceFieldType[] = [
//     {
//         id: 'location_security',
//         type: 'dropdown',
//         text: 'Security measure',
//         is_required: false,
//         options: [
//             { uid: 'LockedGates', name: 'Locked Gates' },
//             { uid: 'measure_2', name: 'Measure two' },
//         ],
//         parent_entity_id: 'location_security',
//     },
// ]

export const location_security_sub_field: IInsuranceFieldType[] = [
    {
        id: 'location_security',
        type: 'multiselect',
        text: 'Security measure(s)',
        is_required: true,
        contains_important_tooltip_text: 'Please select all that apply',
        options: [
            { uid: 'LOCKED_GATES', name: 'Locked Gates' },
            { uid: 'CCTV', name: 'CCTV' },
            { uid: 'ALARM_SYSTEM', name: 'Alarm System' },
            { uid: 'FIRE_SPRINKLER_SYSTEM', name: 'Fire Sprinkler System' },
            { uid: 'ANTI_THEFT_PAINT', name: 'Anti Theft Paint' },
            { uid: 'ON_SITE_SECURITY_TEAM', name: 'On Site Security Team' },
            { uid: 'ELECTRONIC_KEY_FOB', name: 'Electronic Key Fob' },
            {
                uid: 'ALLTIME_MANNED_SECURITY',
                name: 'All Time Manned Security',
            },
        ],
    },
]

export const insurance_fields_car_mileage_parking_security_subform: IInsuranceFieldType[] =
    [
        {
            id: 'security_device_type',
            type: 'dropdown',
            text: 'What type of security device is fitted?',
            is_required: false,
            options: [
                {
                    uid: 'THATCHAM_APPROVED_FACTORY_FITTED_ALARM',
                    name: 'Thatcham Approved Factory Fitted Alarm',
                },
                {
                    uid: 'THATCHAM_APPROVED_FACTORY_FITTED_IMMOBILISER',
                    name: 'Thatcham Approved Factory Fitted Immobiliser',
                },
                {
                    uid: 'THATCHAM_APPROVED_FACTORY_FITTED_TRACKER',
                    name: 'Thatcham Approved Factory Fitted Tracker',
                },
                {
                    uid: 'THATCHAM_APPROVED_AFTER_MARKET_ALARM',
                    name: 'Thatcham Approved After Market Alarm',
                },
                {
                    uid: 'THATCHAM_APPROVED_AFTER_MARKET_IMMOBILISER',
                    name: 'Thatcham Approved After Market Immobiliser',
                },
                {
                    uid: 'THATCHAM_APPROVED_AFTER_MARKET_TRACKER',
                    name: 'Thatcham Approved After Market Tracker',
                },
                {
                    uid: 'NON_THATCHAM_APPROVED_FACTORY_FITTED_ALARM',
                    name: 'Non Thatcham Approved Factory Fitted Alarm',
                },
                {
                    uid: 'NON_THATCHAM_APPROVED_FACTORY_FITTED_IMMOBILISER',
                    name: 'Non Thatcham Approved Factory Fitted Immobiliser',
                },
                {
                    uid: 'NON_THATCHAM_APPROVED_FACTORY_FITTED_TRACKER',
                    name: 'Non Thatcham Approved Factory Fitted Tracker',
                },
                {
                    uid: 'NON_THATCHAM_APPROVED_AFTER_MARKET_ALARM',
                    name: 'Non Thatcham Approved After Market Alarm',
                },
                {
                    uid: 'NON_THATCHAM_APPROVED_AFTER_MARKET_IMMOBILISER',
                    name: 'Non Thatcham Approved After Market Immobiliser',
                },
                {
                    uid: 'NON_THATCHAM_APPROVED_AFTER_MARKET_TRACKER',
                    name: 'Non Thatcham Approved After Market Tracker',
                },
            ],
        },
        // {
        //     id: 'security_device_fitted_by',
        //     type: 'simple_txt',
        //     text: 'Who fitted the security device?',
        //     is_required: false,
        // },
        // {
        //     id: 'security_device_fitted_date',
        //     type: 'date',
        //     text: 'When was the security device fitted?',
        // },
    ]
