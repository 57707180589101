import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneyHistoryFileIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '56'}
            height={size ?? '56'}
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="7"
                y="21.875"
                width="42"
                height="12.25"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
            />
            <path
                d="M31.9375 28H19.25"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M44.625 28H36.75"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <circle
                cx="13.125"
                cy="28"
                r="1.75"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
            />
            <line
                x1="12.375"
                y1="15.625"
                x2="43.625"
                y2="15.625"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="square"
            />
            <line
                x1="18.5"
                y1="9.5"
                x2="37.5"
                y2="9.5"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="square"
            />
            <line
                x1="12.375"
                y1="40.125"
                x2="43.625"
                y2="40.125"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="square"
            />
            <line
                x1="18.5"
                y1="46.25"
                x2="37.5"
                y2="46.25"
                stroke={color ?? colours[theme].background_default}
                stroke-width="2"
                stroke-linecap="square"
            />
        </svg>
    )
}

export default JourneyHistoryFileIcon
