import { IEntryPayloadAPI, IUpdateEntryPayloadAPI } from 'IapiDataPayload'
import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    ITimelineItem,
    IUpdateEntryLabelPayload,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'
import { ConvertAPIEntryToTimelineItemSingle } from '../../conversions/timeline/entryToTimelineItem'
import { api } from '../../services'
import * as successActions from '../../timeline/actions/successActions'
import * as errorActions from '../../timeline/actions/errorActions'
import * as carActions from '../../entities/cars/actions'
import * as requestActions from '../actions/requestActions'
import posthog from 'posthog-js'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'

import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import {
    AddLabelToEntrySaga,
    RemoveLabelFromEntrySaga,
} from './entryLabelsUpdates'
import { RootState } from 'typesafe-actions'
import { ILabel } from 'myModels'
import { convertDateToBeFormat } from '../../conversions/time/dates'
import { IApiEntryUPDATE_args } from 'ApiInterfaces'

// import { GetEntryByIdSaga } from './getEntryById'
// import { push } from 'connected-react-router'
// import * as successActions from '../actions/successActions'
// import * as errorActions from '../actions/errorActions'

export const insurance_history_fiel_specific_fields = [
    'insurance_entry_policy_start_date',
    'insurance_entry_policy_end_date',
    'insurance_entry_underwriter',
    'insurance_entry_broker',
]
const get_active_entry_in_state = (state: RootState) =>
    state.timeline.activeEntry

export function* UpdateCarTimelineSaga(
    payload: IUpdateTimelineItemPayloadRequest
): any {
    // later on : put API TRY CATH
    // yield put(requestActions.filterTimelineItemsByCarCategoryTagIDRequest)
    // yield put(errorActions.filterTimelineItemsByCarCategoryTagIDError(error))

    // STEP 1 . build up correct payload to send to api => this is the JSON body

    try {
        if (payload.item.date) {
            payload.item.date = convertDateToBeFormat(payload.item.date)
        }
        let api_payload_req_entry: IUpdateEntryPayloadAPI = {
            title: payload.item.title ? payload.item.title : undefined,
            description: payload.item.description
                ? payload.item.description
                : undefined,
            notes: payload.item.notes ? payload.item.notes : undefined,
            // labels: payload.item.labels ? payload.item.labels : undefined,
            occurred_at: payload.item.date ? payload.item.date : undefined,
            entry_mileage: payload.item.mileage
                ? payload.item.mileage
                : undefined,

            // etc
        }

        let active_entry_in_state: ITimelineItem | undefined = yield select(
            get_active_entry_in_state
        )

        let fields: string[] | undefined = undefined

        if (payload.item.categoryID === 'insurance') {
            api_payload_req_entry.occurred_at =
                payload.item.insurance_entry_policy_start_date
            fields = [...insurance_history_fiel_specific_fields]
        }

        if (fields) {
            for (let i = 0; i < fields.length; i++) {
                if (payload.item[fields[i]]) {
                    api_payload_req_entry = {
                        ...api_payload_req_entry,
                        [fields[i]]: payload.item[fields[i]],
                    }
                }
            }
        }

        if (
            (payload.item.labels && payload.item.labels.length > 0) ||
            payload.item.labels !== active_entry_in_state?.labels ||
            (payload.item.labels &&
                payload.item.labels.length !==
                    active_entry_in_state?.labels?.length)
        ) {
            // payload.item.labels.length > 0
            let labelNamesInState: string[] | undefined =
                active_entry_in_state?.labels?.map(
                    (label: ILabel) => label.name
                )

            let payloadLabelNames: string[] | undefined

            let labelsToAdd: ILabel[] | undefined

            if (payload.item.labels) {
                payloadLabelNames = payload.item.labels.map(
                    (label: ILabel) => label.name
                )

                labelsToAdd = payload.item.labels.filter(
                    (label: ILabel) => !labelNamesInState?.includes(label.name)
                )
            }

            let labelsToAddApiPayload: IUpdateEntryLabelPayload = {
                car_id: payload.carid,
                entry_id: payload.item.id,
                labels: labelsToAdd ? labelsToAdd : [],
            }
            let labelsToRemove: ILabel[] | undefined =
                active_entry_in_state?.labels &&
                active_entry_in_state?.labels.filter(
                    (label: ILabel) => !payloadLabelNames?.includes(label.name)
                )

            let labelsToRemoveApiPayload: IUpdateEntryLabelPayload = {
                car_id: payload.carid,
                entry_id: payload.item.id,
                labels: labelsToRemove ? labelsToRemove : [],
            }

            // only call API if there's something to add
            if (labelsToAdd && labelsToAdd.length > 0) {
                yield call(AddLabelToEntrySaga, labelsToAddApiPayload)
            }

            // only call API if there's something to remove
            if (labelsToRemove && labelsToRemove.length > 0) {
                yield call(RemoveLabelFromEntrySaga, labelsToRemoveApiPayload)
            }
        }
        // STEP 2 . call the api func and keep data returned in a const
        let api_payload: IApiEntryUPDATE_args = {
            entry_id: payload.item.id,
            car_id: payload.carid,
            dataToUpdate: api_payload_req_entry,
        }

        const api_res = yield call(
            api.timeline.entries.updateEntry.updateEntry,
            api_payload
        )

        // STEP 3 . convert API data to reducer data type
        // check what back end returns on console log or network window inspector:
        // sometimes theres a nested object where you get the item after .data,
        // but sometimes it returns straight on the item returned, depends on the function

        const updatedEntry: IEntryPayloadAPI = { ...api_res }

        let updatedEntryTimelineItem: ITimelineItem =
            ConvertAPIEntryToTimelineItemSingle(updatedEntry)
        // STEP 4 . build up payload to return to reducer
        let res: IUpdateTimelineItemPayloadRequest = {
            carid: payload.carid,
            item: updatedEntryTimelineItem,
        }
        // STEP 5 . send success action with payload above to redux
        yield put(successActions.updateTimelineEntrySuccess(res))
        // yield put(successActions.updateTimelineItemToCarSuccess(res))
        yield put(successActions.setActiveEntrySuccess(res.item))
        // Capture event

        if (active_entry_in_state?.categoryID === 'insurance') {
            // make it so that fetches again
            yield put(
                carActions.actions.getLastCarInsuranceEntrySuccess({
                    carid: payload.carid,
                    timelineItem: undefined,
                })
            )
        }

        posthog.capture('UPDATE CAR ENTRY')
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: UpdateCarTimelineSaga,
                payload: payload,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/car/${payload.carid}/history-file/entry?entryid=${payload.item.id}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(errorActions.updateTimelineEntryError(customError))
        }
    }
}

function* watcherUpdateCarTimeline() {
    while (true) {
        const { payload } = yield take(
            requestActions.updateTimelineEntryRequest
        )

        yield call(UpdateCarTimelineSaga, payload)
    }
}

const update_car_timeline_item: any[] = [fork(watcherUpdateCarTimeline)]

export default update_car_timeline_item
