import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
// import Icon from '../icons'
// import { message, Upload } from 'antd'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
// import { errorFileTypeWarning } from '../../molecules/editOrCreateModeSingleFields/attachment/desktop/addAttachmentDesktop'
// import { AttachmentTypesEnum } from '../../../redux/attachments/enums'
// import { ConverFileTypeToFileSuffixEnum } from '../../../redux/conversions/attachments'

const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`

const Text = styled.h1<IStyle>`
    font-family: ${(props) => (props.isbold ? 'Lato-bold' : 'Lato')};
    font-size: ${(props) => (props.fontsize ? props.fontsize : '18px')};
    text-transform: capitalize;
    padding-right: 10px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    @media ${device.smallest_laptops} {
        font-size: 22px;
    }

    padding-top: 15px;
    padding-bottom: 15px;
    font-weight: 200;
`

// const IconWrapper = styled.div`
//     cursor: pointer;
//     z-index: 3;
// `

type IStyle = {
    fontsize?: string
    isbold?: boolean
}

interface Props {
    children?: React.ReactNode
    icon?: string
    fontsize?: string
    isbold?: boolean
    onClick?: any
    id?: string
    // setFileUploaded: any
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
    noActionIcon?: boolean
    uploadChildren?: React.ReactNode
}

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {
        presignedurls: state.fileStorage.presignedurls,
    }
}

const SectionHeaderAttachment: React.FunctionComponent<Props> = (props) => {
    // let { setFileUploaded } = props

    // const dummyRequest = ({ file, onSuccess }: any) => {
    //     setTimeout(() => {
    //         onSuccess('ok')
    //     }, 0)
    // }

    // const errorFileSizeWarning = () => {
    //     message.error({
    //         content: 'The maximum file size allowed per upload is 20MB.',
    //         duration: 2,
    //     })
    // }

    // function fileToArrayBuffer(file: any) {
    //     return new Promise(function (resolve, reject) {
    //         const reader = new FileReader()
    //         const readFile = function (event: any) {
    //             const buffer = reader.result
    //             resolve(buffer)
    //         }
    //         reader.addEventListener('load', readFile)
    //         reader.readAsArrayBuffer(file)
    //     })
    // }

    // let handleChange = async (file: any) => {
    //     try {
    //         const fileContents: any = await fileToArrayBuffer(file)

    //         file.data = new Blob([fileContents])

    //         setFileUploaded(file)
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    return (
        <Wrapper id={props.id}>
            <Text fontsize={props.fontsize} isbold={props.isbold}>
                {props.children}
            </Text>

            {/* <EntryAttachmentsFormWrapperDesktop carid={props.carID} /> */}

            {/* <IconWrapper onClick={props.onClick}>
                <Upload
                    multiple={true}
                    showUploadList={true}
                    beforeUpload={() => false}
                    onChange={(info: any) => {
                        if (info.file.size <= 20000000) {
                            let fs:
                                | AttachmentTypesEnum
                                | undefined = ConverFileTypeToFileSuffixEnum(
                                info.file.name
                            )

                            if (fs) {
                                handleChange(info.file)
                            } else errorFileTypeWarning()
                        } else errorFileSizeWarning()
                    }}
                    customRequest={dummyRequest}
                >
                    {props.uploadChildren && props.uploadChildren}
                    {props.icon && !props.noActionIcon && (
                        <Icon icon={props.icon}></Icon>
                    )}
                </Upload>
            </IconWrapper> */}
        </Wrapper>
    )
}

export default connect(mapStateToProps, dispatchProps)(SectionHeaderAttachment)
