import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import useThemes from '../../../../providers/theme/hooks'
import { useEffect } from 'react'
import colours, { journey_colours } from '../../../../providers/theme/colours'
import FormStepProgress from '../../../atoms/FormStepProgress/FormStepProgress'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import ChevronDown from '../../../atoms/icons/components/chevronDown'
import Faded from '../../../templates/animated/faded'
import EnterIcon from '../../../atoms/icons/components/enterIcon'
import image from '../../../../public/assets/images/journey/pastCars/how_it_works.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import {
    JourneyBtnContent,
    JourneyColumnContent,
    JourneyEnterTxt,
    JourneyIconWrapper,
    JourneyLeftSide,
    JourneyRightSide,
    JourneyRowContent,
    JourneySectionWrapper,
    JourneyStepTitle,
    JourneyText,
} from '../../../templates/styledcomponents/journeyStyles'
import { CenterImg, ParagraphTitleJourney } from './memories'
import JourneyPageWrapperMobile from '../../../templates/wrappers/journeyPageWrapperMobile'
import m_1 from '../../../../public/assets/images/journey/pastCars/mobile_1.png'
import m_2 from '../../../../public/assets/images/journey/pastCars/mobile_2.png'
import m_3 from '../../../../public/assets/images/journey/pastCars/mobile_3.png'
import m_4 from '../../../../public/assets/images/journey/pastCars/mobile_4.png'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

export default function HowItWorksCarJourneyStep({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            goToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneySectionWrapper>
                        <JourneyLeftSide $theme={theme}>
                            <FormStepProgress
                                screen_name="desktop"
                                currentStep={step}
                                totalSteps={totalSteps}
                                activeColour={
                                    journey_colours[theme].section['past_cars']
                                        .primary_500
                                }
                                passiveColour={
                                    journey_colours[theme].section[`past_cars`]
                                        .primary_100
                                }
                            />
                            <JourneyColumnContent style={{ gap: 24 }}>
                                <JourneyColumnContent style={{ gap: 16 }}>
                                    <StandardCtaBtn
                                        bg="transparent"
                                        customWidth="auto"
                                        customHeight="auto"
                                        removePaddings
                                        removeShadowOnHover
                                        onClick={goToPrevStep}
                                    >
                                        <JourneyBtnContent $theme={theme}>
                                            <div
                                                style={{
                                                    transform: 'rotate(90deg)',
                                                }}
                                            >
                                                <ChevronDown />
                                            </div>
                                            Back
                                        </JourneyBtnContent>
                                    </StandardCtaBtn>
                                    <JourneyStepTitle $theme={theme}>
                                        How it works
                                    </JourneyStepTitle>
                                </JourneyColumnContent>

                                <JourneyText $theme={theme}>
                                    When it's time to bid farewell to your car,
                                    Custodian makes it easy to preserve its
                                    memory. Saying goodbye to a car doesn't mean
                                    saying goodbye to its memories. Preserve the
                                    stories and experiences of your past cars by
                                    utilising our previously owned feature.
                                </JourneyText>

                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <ParagraphTitleJourney>
                                        Archive Your Car
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        If you're no longer the owner of a car
                                        but want to keep its memory alive,
                                        simply archive it in your garage.
                                        Archiving a car moves it to the
                                        "Previously Owned" tab, where you can
                                        revisit its details and reminisce about
                                        the journeys you shared.
                                    </JourneyText>
                                </JourneyColumnContent>

                                <JourneyColumnContent style={{ gap: 4 }}>
                                    <ParagraphTitleJourney>
                                        Handover to New Owner
                                    </ParagraphTitleJourney>
                                    <JourneyText
                                        $theme={theme}
                                        style={{
                                            color: colours[theme].text_default,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        When it's time to entrust your car to
                                        another, use the "Handover" feature to
                                        transfer the car's ownership seamlessly.
                                        By handing over the car, you ensure that
                                        its legacy continues while making space
                                        for new adventures in your garage.
                                    </JourneyText>
                                </JourneyColumnContent>
                            </JourneyColumnContent>

                            <JourneyRowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={() => {
                                        goToNextStep()
                                    }}
                                    isDisabled={false}
                                    bg={
                                        journey_colours[theme].section[
                                            'past_cars'
                                        ].primary_500
                                    }
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'past_cars'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <JourneyRowContent style={{ gap: 8 }}>
                                    <JourneyIconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </JourneyIconWrapper>{' '}
                                    <JourneyEnterTxt $theme={theme}>
                                        Or press Enter
                                    </JourneyEnterTxt>
                                </JourneyRowContent>
                            </JourneyRowContent>
                        </JourneyLeftSide>
                        <JourneyRightSide>
                            <FadedSlower>
                                <img
                                    placeholder={grey_placeholder}
                                    src={image}
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '60vw',
                                        maxHeight: '96vh',
                                        transform: 'translateX(40px)',
                                    }}
                                />
                            </FadedSlower>
                        </JourneyRightSide>
                    </JourneySectionWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <Faded>
                    <JourneyPageWrapperMobile
                        allowScroll={true}
                        title={'How it works'}
                        sectionID="past_cars"
                        subtitle={`Don't forget your past cars`}
                        currentStep={step}
                        totalSteps={totalSteps}
                        onBack={() => {
                            goToPrevStep()
                        }}
                        onNext={() => {
                            goToNextStep()
                        }}
                    >
                        <JourneyColumnContent
                            style={{
                                gap: 0,
                                paddingLeft: '24px',
                                paddingRight: '24px',
                            }}
                        >
                            <JourneyColumnContent style={{ gap: 4 }}>
                                <ParagraphTitleJourney>
                                    Previously Owned Cars
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Access previously owned cars tabs from the
                                    top bar of your garage. All the cars you've
                                    ever archived or handovered will be stored
                                    there.
                                </JourneyText>
                            </JourneyColumnContent>

                            <div style={{ paddingTop: '16px' }} />

                            <CenterImg>
                                <FadedSlower>
                                    <img
                                        placeholder={grey_placeholder}
                                        src={m_1}
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '100vw',
                                            maxHeight: '60vh',
                                            alignSelf: 'center',
                                        }}
                                    />
                                </FadedSlower>
                            </CenterImg>

                            <JourneyColumnContent
                                style={{
                                    gap: 4,
                                    transform: 'translateY(-20px)',
                                }}
                            >
                                <ParagraphTitleJourney>
                                    Menu Action Button
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    On your car page, expand Menu Action Button
                                    to access additional options.
                                </JourneyText>
                            </JourneyColumnContent>

                            <CenterImg>
                                <FadedSlower>
                                    <img
                                        src={m_2}
                                        placeholder={grey_placeholder}
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '90vw',
                                            maxHeight: '60vh',
                                            alignSelf: 'center',
                                            transform: 'translateX(10px)',
                                        }}
                                    />
                                </FadedSlower>
                            </CenterImg>

                            <JourneyColumnContent style={{ gap: 4 }}>
                                <ParagraphTitleJourney>
                                    Handover
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Handing over your car on Custodian isn't
                                    just about transferring ownership—it's about
                                    preserving its legacy and ensuring a
                                    seamless transition for both you and future
                                    owners.
                                </JourneyText>
                            </JourneyColumnContent>

                            <div style={{ paddingTop: '12px' }} />

                            <CenterImg>
                                <FadedSlower>
                                    <img
                                        src={m_3}
                                        placeholder={grey_placeholder}
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '80vw',
                                            maxHeight: '60vh',
                                            alignSelf: 'center',
                                        }}
                                    />
                                </FadedSlower>
                            </CenterImg>
                            <div style={{ paddingTop: '12px' }} />
                            <JourneyColumnContent style={{ gap: 4 }}>
                                <ParagraphTitleJourney>
                                    Archiving
                                </ParagraphTitleJourney>
                                <JourneyText
                                    $theme={theme}
                                    style={{
                                        color: colours[theme].text_default,
                                        fontSize: '14px',
                                        lineHeight: '20px',
                                    }}
                                >
                                    If the car is no longer in your custody but
                                    you don't know it's new owner or the car
                                    doesn't have one anymore you can just simply
                                    archive it.
                                </JourneyText>
                            </JourneyColumnContent>

                            <div style={{ paddingTop: '12px' }} />

                            <CenterImg>
                                <FadedSlower>
                                    <img
                                        src={m_4}
                                        placeholder={grey_placeholder}
                                        style={{
                                            objectFit: 'contain',
                                            maxWidth: '90vw',
                                            maxHeight: '60vh',
                                            alignSelf: 'center',
                                        }}
                                    />
                                </FadedSlower>
                            </CenterImg>
                        </JourneyColumnContent>

                        <div style={{ paddingTop: '50px' }} />
                    </JourneyPageWrapperMobile>
                </Faded>
            </IpadAndMobileDisplay>
        </div>
    )
}
