import { IWelcomeSections } from "myInterfaces";

import section1 from "../../../public/assets/images/welcome/section1.jpg";
import section2 from "../../../public/assets/images/welcome/section2.jpg";
import section3 from "../../../public/assets/images/welcome/section3.jpg";
import section4 from "../../../public/assets/images/welcome/section4.jpg";

export const welcome_sections_list: IWelcomeSections[] = [
  {
    title: "Collection Management",
    sectionid: "membership_page_section_2",
    image_desktop: {
      image_url: section1,

      credits: {
        title:
          "Ferrari 166MM Barchettas at 2019 Hampton Court Concours of Elegance",
        description: "",
        credits: "@CharlieB Photography",
      },
    },
    mobile_image: {
      image_url: section1,
      credits: {
        title: "Ferrari 166MM Barchettas ",
        description: "2019 Hampton Court Concours of Elegance",
        credits: "@CharlieB Photography",
      },
    },
    paragraphs: [
      "The platform provides tooling to help collectors manage their collections.",
      "It includes digitisation of vehicle history, service and maintenance logs, key date reminders (MOT / tax) and a community event calendar.",
      "In addition, integrated brokerage of key services allows users to save time and money.",
    ],
    // links: [
    //   {
    //     url: "/membership",
    //     caption: "see Membership section"
    //   }
    // ]
  },
  {
    title: "Marketplace",
    sectionid: "membership_page_section_3",
    image_desktop: {
      image_url: section2,
      credits: {
        title: " Porsche 918 Spyder ",
        description: "Belgravia, London",
        credits: "@CharlieB Photography",
      },
    },
    mobile_image: {
      image_url: section2,
      credits: {
        title: " Porsche 918 Spyder ",
        description: "Belgravia, London",
        credits: "@CharlieB Photography",
      },
    },
    paragraphs: [
      "Unlike other platforms, Private Collectors Club's marketplace is free for both buyers and sellers to transact on, regardless of whether a private individual or a dealer. ",
      " Exceptional design and functionality is combined with powerful search tools and helpful engagement analytics.",
    ],
    // links: [
    //   {
    //     url: "/membership",
    //     caption: "see Membership section"
    //   }
    // ]
  },
  {
    title: "Private Curated Community",
    sectionid: "membership_page_section_4",
    image_desktop: {
      image_url: section3,
      credits: {
        title:
          "Line up of 11 Jaguar XJR-15s at Goodwood Motor Circuit in August 2019",
        description:
          "organised by Valentine Lindsay, a collector and member of the BRDC.",
        credits: "(@CharlieB Photography 2020)",
      },
    },
    mobile_image: {
      image_url: section3,
      credits: {
        title: "Line up of 11 Jaguar XJR-15s",
        description: "Goodwood Motor Circuit in August 2019",
        credits: "@CharlieB Photography",
      },
    },
    paragraphs: [
      "A carefully curated membership policy and integrated social network mechanics within the private community, provide a safe and enjoyable place for like-minded enthusiasts.",
      "An internal messaging service allows easy communication within the community, whilst strict club rules ensure members treat each other with dignity and respect. ",
    ],
    links: [
      {
        url: "/membership",
        caption: "About Membership",
      },
    ],
  },
  {
    title: "Project Management",
    sectionid: "membership_page_section_4",
    image_desktop: {
      image_url: section4,
      credits: {
        title: "Alfa Romeo 8C 2300",
        description:
          "at Ice Races GP in Zell am See, Austria, on 1-2 February 2020.",
        credits: "@CharlieB Photography",
      },
    },
    mobile_image: {
      image_url: section4,
      credits: {
        title: "Alfa Romeo 8C 2300",
        description:
          "at Ice Races GP in Zell am See, Austria, on 1-2 February 2020.",
        credits: "@CharlieB Photography",
      },
    },
    paragraphs: [
      "Private Collectors Club enables users to document and showcase car related activity including restoration work, rallies, events and historical research. ",
      "Tooling also helps streamline and centralise project management and reporting (i.e restoration work) with different collaborators, whilst also helping the search process for the best service providers and content creators.",
    ],
    // links: [
    //   {
    //     url: "/membership",
    //     caption: "About Membership"
    //   }
    // ]
  },
];
