import { useState } from 'react'
import AddBox from '../../Button/addBox'
import skeletonhf from '../../skeleton/historyFile/history_file_new_placeholder.png'
import skeletonhfw from '../../skeleton/historyFile/history_file_new_placeholder_white.png'
import skeletonDarkMode from '../../skeleton/historyFile/history_file_new_placeholder_dark_mode.png'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    onClick?: any
    index?: number
    isWhite?: boolean
    reaonlyMode?: boolean
}
const OverviewHistoryFileSkeleton = (props: Props) => {
    const [isAddShow, setIsAddShow] = useState<boolean>(false)

    const { theme } = useThemes()

    return (
        <div
            onMouseEnter={() => setIsAddShow(true)}
            onMouseLeave={() => setIsAddShow(false)}
            style={{
                width: '100%',
                height: '264px',
                cursor: 'pointer',
            }}
            onClick={props.onClick && props.onClick}
        >
            {props.reaonlyMode ? (
                <img
                    style={{ width: '100%', height: '264px' }}
                    src={
                        theme === 'dark'
                            ? skeletonDarkMode
                            : props.isWhite
                            ? skeletonhfw
                            : skeletonhf
                    }
                    alt="skeleton"
                />
            ) : !isAddShow ? (
                <img
                    style={{ width: '100%', height: '264px' }}
                    src={
                        theme === 'dark'
                            ? skeletonDarkMode
                            : props.isWhite
                            ? skeletonhfw
                            : skeletonhf
                    }
                    alt="skeleton"
                />
            ) : (
                <AddBox borderRadius="8px" />
            )}
        </div>
    )
}

export default OverviewHistoryFileSkeleton
