import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const BinIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '14'}
            height={size ?? '14'}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.90096 4.75667C2.31154 4.70192 2.68876 4.99038 2.74351 5.40096L3.6278 12.0331C3.64436 12.1573 3.7503 12.2501 3.8756 12.2501H10.1246C10.2499 12.2501 10.3558 12.1573 10.3724 12.0331L11.2567 5.40096C11.3114 4.99038 11.6886 4.70192 12.0992 4.75667C12.5098 4.81141 12.7983 5.18863 12.7435 5.59921L11.8592 12.2314C11.7433 13.1008 11.0017 13.7501 10.1246 13.7501H3.8756C2.9985 13.7501 2.25688 13.1008 2.14095 12.2314L1.25667 5.59921C1.20192 5.18863 1.49038 4.81141 1.90096 4.75667Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.25 2.25H13.75V3.75H0.25V2.25Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.182 1.75H4.81836V0.25H9.182V1.75Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default BinIcon
