import * as React from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'
import AddIcon from '../icons/components/addIcon'

interface IStyle {
    $theme: ITheme
    $iconOnLeft?: boolean | undefined
    $isUppercase?: boolean | undefined
    $width?: string | undefined
    $color?: string
}

const Button = styled.button<IStyle>`
    display: flex;
    justify-content: ${(props) =>
        props.$iconOnLeft ? 'space-between' : 'center'};
    align-items: center;
    width: ${(props) => (props.$width ? props.$width : 'fit-content')};
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.$color ? props.$color : colours[props.$theme].primary};
    font-family: 'Lato';
    font-size: 14px;
    letter-spacing: 5e-5em;
    text-transform: ${(props) => (props.$isUppercase ? 'uppercase' : 'none')};
    padding-left: 8px;
`

type Props = {
    onClick: any
    iconOnLeft?: boolean | undefined
    iconOnRight?: boolean | undefined
    text: string | undefined
    dataCyId?: string | undefined
    isUppercase?: boolean
    width?: string | undefined
    iconwidth?: string | undefined
    icon?: string
    color?: string
}

const IconAndTextNakedButton: React.FunctionComponent<Props> = (
    props: Props
) => {
    let {
        onClick,
        iconOnLeft,
        iconOnRight,
        text,
        isUppercase,
        width,
        dataCyId,
        icon,
        iconwidth,
        color,
    } = props

    const { theme } = useThemes()
    return (
        <Button
            $theme={theme}
            $iconOnLeft={iconOnLeft}
            $width={width}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
        >
            {icon &&
                (icon === 'themed_add' ? (
                    <AddIcon />
                ) : (
                    <Icon width={iconwidth} icon={icon ? icon : 'add'}></Icon>
                ))}
            <Text $theme={theme} $color={color} $isUppercase={isUppercase}>
                {text}
            </Text>
            {iconOnRight && (
                <Icon
                    width={iconwidth}
                    color={color}
                    icon={icon ? icon : 'add'}
                ></Icon>
            )}
        </Button>
    )
}

export default IconAndTextNakedButton
