import * as React from 'react'

import styled from 'styled-components'

import Icon from '../icons'

const Container = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #00000060;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 2;
`

type Props = { tooltipCopy?: string }

const ImageSignMobile: React.FunctionComponent<Props> = (props: Props) => {
    // const [showTooltip, setShowTooltip] = React.useState(false)

    // const toggleTooltip = (e: any) => {
    //     e.preventDefault()
    //     setShowTooltip(true)
    //     setTimeout(() => {
    //         setShowTooltip(false)
    //     }, 2000)
    // }

    return (
        <Container>
            <Icon icon="car_profile_image" />
            {/* <Tooltip
                title={props.tooltipCopy}
                visible={showTooltip}
                placement="bottom"
                color="#5EC3CA"
            /> */}
        </Container>
    )
}

export default ImageSignMobile
