import { RootState } from 'typesafe-actions'
import { useState } from 'react'
import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import InputField from '../../../../../atoms/Inputfield/inputField'
import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import * as unitGenerator from '../../../../../../helpers/units/unitConversion'
import { connect } from 'react-redux'
import { IList, IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { Select } from '../../../../../atoms/select'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import Expander from '../../../../../atoms/expander/expander'
import { yupVar_NumberBasicSingleField } from '../../../../../molecules/editOrCreateModeSingleFields/yupVars'
import * as Yup from 'yup'
import { device } from '../../../../../templates/displays/devices'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding32 = styled.div`
    padding-top: 32px;
`
const Padding11 = styled.div`
    padding-top: 11px;
`

// const RegistrationNumberReminder = styled.div`
//     color: grey;
//     font-family: Lato;
//     font-size: 13px;
//     padding-top: 10px;
// `

const RowForm = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`

const AdjustPosition = styled.div`
    margin-left: 24px;
    width: 100%;
`

const EquivalentConverted = styled.div`
    font-size: 12px;
    color: var(--text-muted);
    font-family: Lato;
    padding-left: 8px;
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: var(--error, #df6f6f);
    font-style: italic;
    font-size: 12px;
    padding-top: 4px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_numberTechInfoMobile: number
    unit?: string
}

type IUnitInfo = {
    // data in the list :
    list: IList[] | null
    // id : distance_unit or fuel_unit
    id?: string
    // user chosen unit:
    user_default_unit?: string
}

interface OtherProps {
    toggle: any
    item: any
    isUnits?: boolean
    unitList?: IList[] | null
    default_unit?: any
    submitFunc: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id?: any
    carid: string
    unitInfo?: IUnitInfo
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        isUnits,
        unitList,
        // default_unit,
        setFieldValue,
        errors,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        unitInfo,
    } = props

    const handleUnitChange = (value: string) => {
        setFieldValue('unit', value)
    }

    const [isDisabled, setIsDisabled] = useState(
        !values.single_item_edited_value_numberTechInfoMobile ? true : false
    )

    const handleAnswerChange = (e: any) => {
        setFieldValue(
            'single_item_edited_value_numberTechInfoMobile',
            e.target.value
        )
        if (!e.target.value || e.target.value.length > 20) {
            setIsDisabled(true)
        } else if (errors.single_item_edited_value_numberTechInfoMobile) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>
                <span>{item.text}</span>
            </ModalTopBar>
            <ModalInnerContentWrapperMobile>
                <Padding11 />
                <RowForm>
                    <InputField
                        theme={theme}
                        id="single_item_edited_value_numberTechInfoMobile"
                        name="single_item_edited_value_numberTechInfoMobile"
                        placeholder={item.text}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={
                            values.single_item_edited_value_numberTechInfoMobile
                        }
                        font_family="Lato"
                        mobile_width={'40vw'}
                        ipad_width={'45vw'}
                        type="number"
                        isnarrow={true}
                        onChange={handleAnswerChange}
                        onEnterKeyPress={props.handleSubmit}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    {isUnits && (
                        <AdjustPosition>
                            <Select
                                defaultValue={unitInfo?.user_default_unit ?? ''}
                                onChange={handleUnitChange}
                                options={
                                    unitList?.map((unit) => ({
                                        value: unit.id,
                                        label:
                                            unit.short ??
                                            unit.text ??
                                            unit.name ??
                                            '',
                                    })) ?? []
                                }
                                backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                            />
                        </AdjustPosition>
                    )}
                </RowForm>
                {errors.single_item_edited_value_numberTechInfoMobile && (
                    <Expander
                        height={
                            errors.single_item_edited_value_numberTechInfoMobile
                                ? 'auto'
                                : 0
                        }
                    >
                        <Error>
                            {
                                errors.single_item_edited_value_numberTechInfoMobile
                            }
                        </Error>
                    </Expander>
                )}
                {!errors.single_item_edited_value_numberTechInfoMobile && (
                    <Expander
                        height={
                            item.id === 'mileage' &&
                            values.unit !== 'miles' &&
                            values.single_item_edited_value_numberTechInfoMobile
                                ? 'auto'
                                : 0
                        }
                    >
                        <EquivalentConverted>
                            Equal to{' '}
                            {values.single_item_edited_value_numberTechInfoMobile &&
                                unitGenerator.kmToMiles(
                                    +values.single_item_edited_value_numberTechInfoMobile
                                )}{' '}
                            miles
                        </EquivalentConverted>
                    </Expander>
                )}
                {!errors.single_item_edited_value_numberTechInfoMobile && (
                    <Expander
                        height={
                            item.id === 'engine_capacity' &&
                            values.unit !== 'liters' &&
                            values.single_item_edited_value_numberTechInfoMobile
                                ? 'auto'
                                : 0
                        }
                    >
                        <EquivalentConverted>
                            Equal to{' '}
                            {values.single_item_edited_value_numberTechInfoMobile &&
                                (values.unit === 'cc' ||
                                values.unit === 'cubic_centimeters'
                                    ? unitGenerator
                                          .cubicCentimetersToLiters(
                                              +values.single_item_edited_value_numberTechInfoMobile
                                          )
                                          .toFixed(1)
                                    : values.unit === 'ci' ||
                                      values.unit === 'cubic_inches'
                                    ? unitGenerator
                                          .cubicInchesToLiters(
                                              +values.single_item_edited_value_numberTechInfoMobile
                                          )
                                          .toFixed(1)
                                    : null)}{' '}
                            litres
                        </EquivalentConverted>
                    </Expander>
                )}
                <Padding32 />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={props.handleSubmit}
                    confirmationtext="save"
                    disabled={isDisabled}
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const NumberTechInfoEditFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_numberTechInfoMobile:
            props.item.answer !== undefined && props.item.answer !== null
                ? props.item.answer
                : undefined,
        unit: props.unitInfo && props.unitInfo.user_default_unit,
    }),
    validationSchema: () => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                single_item_edited_value_numberTechInfoMobile:
                    yupVar_NumberBasicSingleField(
                        values.single_item_edited_value_numberTechInfoMobile
                    ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        // let finalAnswer =
        //     values.single_item_edited_value_numberTechInfoMobile * 1

        // if (props.unitList && values.unit && values.unit === 'miles') {
        //     finalAnswer = finalAnswer * 1.609
        //
        // }

        const valuesToSend = (
            unit_from_modal_form: string,
            value: number
        ): number => {
            switch (unit_from_modal_form) {
                //distance
                case 'miles':
                    return unitGenerator.generateBackEndValueDistanceUnit(
                        unit_from_modal_form,
                        value
                    )

                //fuel
                case 'cubic_inches':
                    return Math.round(
                        unitGenerator.generateBackEndValueFuelUnit(
                            unit_from_modal_form,
                            value
                        )
                    )
                case 'liters':
                    return Math.round(
                        unitGenerator.generateBackEndValueFuelUnit(
                            unit_from_modal_form,
                            value
                        )
                    )
                case 'cubic_centimeters':
                    return Math.round(value)
                //default
                default:
                    return Math.round(value)
            }
        }

        if (props.submitFunc) {
            let converted_val: number = values.unit
                ? valuesToSend(
                      values.unit,
                      values.single_item_edited_value_numberTechInfoMobile
                  )
                : values.single_item_edited_value_numberTechInfoMobile

            let payload: IwriteTechnicalInformationPayloadReq = {
                tech_info_id: props.tech_info_id,
                car_id: props.carid,
                data: {
                    id: props.item.id,
                    text: props.item.text,
                    answer: converted_val,
                },
            }

            props.submitFunc(payload)
            props.toggle()
        }
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(NumberTechInfoEditFormMobile)
