import styled from 'styled-components'
import {
    IInsuranceApplicationSectionCard,
    IInsuranceApplicationSectionId,
} from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import React from 'react'
import InfoIcon from '../../../atoms/icons/infoIcon'
import { Tooltip } from 'antd'
import SwitchButton from '../../../atoms/Button/switchButton'
import FormMultipleStepsProgress from '../../../atoms/FormStepProgress/FormMultipleStepsProgress'
import { useAppSelector } from '../../../../redux/store/hooks'
import ShimmerOverlay from '../../../atoms/skeleton/shimmerOveraly'
import CircleLoader from '../../../atoms/loader/circleLoader'
import colours, {
    ITheme,
    insurance_colours,
} from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'
import { motion } from 'framer-motion'
import InsuranceIcons from '../../../atoms/icons/components/insurance'

type IStyle = {
    $theme: ITheme
    $sectionID?: IInsuranceApplicationSectionId
    $outlinedBtns?: boolean
    $isDisabled?: boolean
    $isApplicationSubmitted?: boolean
}

const Card = styled.div<IStyle>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    min-width: 600px;
    max-width: 700px;
    padding: 40px;
    border-radius: 8px;
    border: ${(props) =>
        `1px solid ${
            props.$sectionID
                ? insurance_colours[props.$theme].sections[props.$sectionID]
                      .primary_30
                : colours[props.$theme].border_muted
        }`};
    box-shadow: ${(props) =>
        `10px 6px 24px ${colours[props.$theme].shadow_strongest_04}`};
    background-color: var(--bg-color, #fff);
`

const CardTopSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
`

const Title = styled.p`
    all: unset;
    font-family: Lato-bold;
    font-size: 18px;
    color: var(--text-strong, #1a1a1a);
`

const HeaderBtn = styled(motion.button)<IStyle>`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    background-color: ${(props) =>
        props.$outlinedBtns || props.$isApplicationSubmitted
            ? colours[props.$theme].background_default
            : props.$isDisabled
            ? colours[props.$theme].background_disabled_neutral
            : props.$sectionID
            ? insurance_colours[props.$theme].sections[props.$sectionID]
                  .primary_100
            : colours[props.$theme].primary};
    color: ${(props) =>
        props.$outlinedBtns || props.$isApplicationSubmitted
            ? props.$isDisabled
                ? colours[props.$theme].text_muted
                : props.$sectionID
                ? insurance_colours[props.$theme].sections[props.$sectionID]
                      .primary_100
                : colours[props.$theme].primary
            : colours[props.$theme].background_default};
    border: ${(props) =>
        props.$outlinedBtns
            ? `1px solid ${
                  props.$sectionID
                      ? insurance_colours[props.$theme].sections[
                            props.$sectionID
                        ].primary_100
                      : colours[props.$theme].primary
              }`
            : 'none'};
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
    :hover {
        opacity: 0.8;
    }
`

const Text = styled.p<IStyle>`
    all: unset;
    width: 100%;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${(props) => colours[props.$theme].text_default};
    /* padding-bottom: 24px; */
`

type Props = {
    cardProps: IInsuranceApplicationSectionCard
    isApplicationSubmitted?: boolean
    outlinedBtns?: boolean
    children: React.ReactNode
    hasIcon?: boolean
}

export const generatensuranceSectionIcon = (
    sectionID: IInsuranceApplicationSectionId,
    width?: number
) => {
    switch (sectionID) {
        case 'addresses': {
            return <InsuranceIcons.Addresses width={width} />
        }
        case 'drivers': {
            return <InsuranceIcons.Drivers width={width} />
        }
        case 'everyday_vehicles': {
            return <InsuranceIcons.EverydayVehicles width={width} />
        }
        case 'insurance_details': {
            return <InsuranceIcons.InsuranceDetails width={width} />
        }
        case 'main_policy_holder': {
            return <InsuranceIcons.MainPolicyHolder width={width} />
        }
        case 'vehicles_to_insure': {
            return <InsuranceIcons.VehiclesToInsure width={width} />
        }
        case 'referral_code': {
            return <InsuranceIcons.InsuranceReferralCodeIcon width={width} />
        }
    }
}

const InsuranceApplicationCardDesktop = (props: Props) => {
    let {
        title,
        text,
        btnTxt,
        onClick,
        isDisabled,
        numberOfProgressBars,
        arrayOfProgressBarsValues,
        hasTooltip,
        tooltipTxt,
        hasToggle,
        onToggle,
        isToggled,
        id,
        sectionID,
    } = props.cardProps

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )
    let applicationLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )

    let isDraft = application?.status === 'DRAFT' ? true : false

    let showLoadingUI = !application.id || applicationLoading ? true : false

    const loadingArray = arrayOfProgressBarsValues
        ? arrayOfProgressBarsValues.map(() => 0)
        : [0]

    const { theme } = useThemes()

    return (
        <Card
            style={{ marginBottom: 24 }}
            $theme={theme}
            $sectionID={sectionID}
        >
            {title || text ? (
                <CardTopSection>
                    {title ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {props.hasIcon && sectionID ? (
                                <div
                                    style={{
                                        paddingRight: 16,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {generatensuranceSectionIcon(sectionID)}
                                </div>
                            ) : null}
                            <Title>{title}</Title>
                            {hasTooltip && (
                                <div
                                    style={{
                                        transform: 'translateY(5px)',
                                        paddingLeft: 8,
                                    }}
                                >
                                    <Tooltip
                                        title={tooltipTxt}
                                        placement="right"
                                        color={'#4b4b4b'}
                                        overlayInnerStyle={{
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <InfoIcon size="16" />{' '}
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    ) : null}
                    {id === 'addresses' &&
                    (!isDraft ||
                        props.isApplicationSubmitted) ? null : hasToggle &&
                      isToggled !== undefined ? (
                        <SwitchButton
                            checked={isToggled}
                            toggle={(v: boolean) => {
                                onToggle && onToggle(v)
                            }}
                            dataCyId={'makeAddressSameAsDealership'}
                            variant="outline"
                        />
                    ) : !props.cardProps.removeBtn &&
                      props.cardProps.onClick ? (
                        showLoadingUI ? null : (
                            <HeaderBtn
                                whileTap={{ scale: 1.1 }}
                                $isDisabled={isDisabled}
                                onClick={onClick}
                                $theme={theme}
                                $sectionID={sectionID}
                                $isApplicationSubmitted={
                                    props.isApplicationSubmitted
                                }
                                $outlinedBtns={props.outlinedBtns}
                            >
                                {!isDraft || props.isApplicationSubmitted
                                    ? 'See details'
                                    : btnTxt}
                            </HeaderBtn>
                        )
                    ) : null}
                    {showLoadingUI && (
                        <div
                            style={{
                                width: 48,
                                height: 40,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircleLoader
                                size={24}
                                color={
                                    sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : colours[theme].primary
                                }
                            />
                        </div>
                    )}
                </CardTopSection>
            ) : null}
            {arrayOfProgressBarsValues &&
            numberOfProgressBars &&
            numberOfProgressBars > 0 ? (
                <FormMultipleStepsProgress
                    arrayOfProgressBarsValues={
                        showLoadingUI ? loadingArray : arrayOfProgressBarsValues
                    }
                    progress_base_colour={
                        sectionID
                            ? insurance_colours[theme].sections[sectionID]
                                  .primary_16
                            : undefined
                    }
                    progress_filled_colour={
                        sectionID
                            ? insurance_colours[theme].sections[sectionID]
                                  .primary_100
                            : undefined
                    }
                />
            ) : null}
            {text ? (
                <Text $theme={theme} $sectionID={sectionID}>
                    {text}
                </Text>
            ) : null}
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}
            >
                {showLoadingUI && (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <ShimmerOverlay isthingloading />
                    </div>
                )}
                {props.children}
            </div>
        </Card>
    )
}

export default InsuranceApplicationCardDesktop
