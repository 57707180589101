import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const CheckCircleIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '17'}
            height={size ?? '16'}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 1.75C4.54822 1.75 1.75 4.54822 1.75 8C1.75 11.4518 4.54822 14.25 8 14.25C11.4518 14.25 14.25 11.4518 14.25 8H15.75C15.75 12.2802 12.2802 15.75 8 15.75C3.71979 15.75 0.25 12.2802 0.25 8C0.25 3.71979 3.71979 0.25 8 0.25C9.6456 0.25 11.1735 0.76373 12.4291 1.63955L11.5709 2.86983C10.5589 2.1639 9.3289 1.75 8 1.75Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.0603 3.47413L8.33333 11.5875L3.93965 6.97413L5.02586 5.93965L8.33333 9.4125L14.9741 2.43965L16.0603 3.47413Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default CheckCircleIcon
