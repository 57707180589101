import {
    IContactReason,
    IHelpPageContact,
    IFAQquestionsData,
    IFaqSectionsData,
    IFAQPageCopy,
} from 'helpPageCopyModels'

const faqSectionsIDs: string[] = [
    'data_privacy',
    'setting_up_your_garage',
    'user_profile',
    'tasks',
    'the_apex',
    'settings',
    'miscellaneous',
]

const faqSectionsData: IFaqSectionsData = {
    data_privacy: {
        id: 'data_privacy',
        name: 'data privacy',
        questionsIDs: ['faq-1', 'faq-2'],
    },
    setting_up_your_garage: {
        id: 'setting_up_your_garage',
        name: 'setting up your garage',
        questionsIDs: [
            'faq-3',
            'faq-4',
            'faq-5',
            'faq-6',
            'faq-7',
            'faq-8',
            'faq-9',
            'faq-10',
            'faq-11',
            'faq-12',
        ],
    },
    user_profile: {
        id: 'user_profile',
        name: 'setting up your user profile',
        questionsIDs: ['faq-13', 'faq-14', 'faq-15', 'faq-16'],
    },
    tasks: {
        id: 'tasks',
        name: 'tasks',
        questionsIDs: ['faq-17', 'faq-18', 'faq-19', 'faq-20'],
    },
    the_apex: {
        id: 'the_apex',
        name: 'the apex',
        questionsIDs: ['faq-21', 'faq-22'],
    },
    settings: {
        id: 'settings',
        name: 'settings',
        questionsIDs: [
            'faq-23',
            'faq-24',
            'faq-25',
            'faq-26',
            'faq-27',
            'faq-28',
        ],
    },
    miscellaneous: {
        id: 'miscellaneous',
        name: 'miscellaneous',
        questionsIDs: ['faq-29', 'faq-30', 'faq-31'],
    },
}

const faqQuestionsData: IFAQquestionsData = {
    'faq-1': {
        id: 'faq-1',
        text: 'Is my data secure?',
        answer: 'Custodian takes data privacy extremely seriously. All data entered into Custodian is encrypted both in transit and at rest where it is securely stored on servers located in Europe. Our platform has been built to comply with GDPR and our systems ensure that no third party can access or use your data without your prior consent. If you decide to stop using Custodian and delete your account, all related data will be irretrievably deleted.',
        links: ['https://custodian.club/privacy'],
    },
    'faq-2': {
        id: 'faq-2',
        text: 'I do not want to use Custodian anymore. How do I delete my account and data?',
        answer: 'If you choose to stop using Custodian and want to delete your account, you can do so from your profile page.',
        links: undefined,
    },
    'faq-3': {
        id: 'faq-3',
        text: 'Some of my car details are missing or incorrect. How do I edit my car’s details?',
        answer: 'To edit your car’s details, go to the car ‘Overview’ page and then select ‘Technical Information’ in the submenu (on the top menu on desktop and the circular green button with three lines on mobile). You can then click or tap on any of the details in order to change them. Make sure to save any changes you make!',
        links: undefined,
    },
    'faq-4': {
        id: 'faq-4',
        text: 'How do I delete a car from my Custodian Garage?',
        answer: 'To delete a car profile, first go to your Garage and click on the car profile you want to delete. To the right of the car’s title (under the car banner image) you will see a pencil icon, which you need to click. At the bottom of the page that opens, click the button that says ‘Delete car’ and follow the instructions on the page that appears. Be warned that deleting a car profile is a permanent action. You will not be able to recover the profile and associated tasks, photos and documents once you delete it.',
        links: undefined,
    },
    'faq-5': {
        id: 'faq-5',
        text: 'I have now sold one of the cars in my garage. What should I do?',
        answer: 'Whilst we do not currently have specific functionality for selling a car, we will be launching a ‘Previously Owned’ feature in the future, as well as the ability to transfer a car profile along with all associated information and photos to a new owner through Custodian. In the interim, we suggest either keeping your sold car in your Garage and marking the title as previously owned or deleting the car from your account. Be warned though that deleting a car profile is a permanent action. You will not be able to recover the profile and associated tasks, photos and documents once you delete it.',
        links: undefined,
    },
    'faq-6': {
        id: 'faq-6',
        text: 'My car is not road registered or is registered outside of the U.K.. Can I still add it to Custodian?',
        answer: 'Even if your car is not road registered or is registered outside of the U.K., you can still add your car to your Custodian garage. Simply click the ‘No Registration Number’ button on the first step on adding your car and you can then enter your car’s details manually.',
        links: undefined,
    },
    'faq-7': {
        id: 'faq-7',
        text: "My make/model is not in the list of suggestions. Does this mean I can't add my car?",
        answer: 'You can manually add a Make or Model if it does not already exist in the list of suggestions. Our system automatically adds new Makes and Models to the platform though we keep the list of new makes and models curated to avoid incorrect entries. However, on occasion incorrect entries can exist, so if you see an incorrect Make or Model please let us know at hello@custodian.club.',
        links: undefined,
    },
    'faq-8': {
        id: 'faq-8',
        text: 'When I delete a car from my garage, does it delete all the tasks and photos associated with the car?',
        answer: 'Yes, deleting a car from your garage deletes all associated assets, including images, tasks related to that car and all information provided such as Key Moments and Facts, Description and Technical Information. Deleting a car profile is a permanent action. You will not be able to recover the profile and associated assets once you delete it.',
        links: undefined,
    },
    'faq-9': {
        id: 'faq-9',
        text: 'How do I change the car profile image and gallery featured images for a car? ',
        answer: 'To change a car profile image or gallery featured images, go to the car Gallery and click on the image you would like to use. Then click on the button with three dots in the bottom-right corner under the image and select the action from the menu. If you already have six gallery featured images, you may have to remove one from being featured by going through the same steps as above, before you can choose a replacement.',
        links: undefined,
    },
    'faq-10': {
        id: 'faq-10',
        text: 'How do I delete or edit a photo from my gallery?',
        answer: 'To delete a photo or edit a photo, go to the car Gallery and click on the image you would like to delete or edit. Then click on the button with three dots in the bottom-right corner under the image and select the action from the menu. Be warned that deleting an image is a permanent action. You will not be able to recover the image once you delete it.',
        links: undefined,
    },
    'faq-11': {
        id: 'faq-11',
        text: 'I have photos of my car saved on my computer. Can I add them directly from my computer as well as from my mobile?',
        answer: 'Absolutely! Custodian works across all devices, including computers, tablets and mobiles. Simply log in to Custodian on your desktop at: https://app.custodian.club/ and upload the photos from there. Anything you upload on the web version of Custodian will automatically be available in the mobile and tablet version as well.',
        links: undefined,
    },
    'faq-12': {
        id: 'faq-12',
        text: 'How do I attach documents or invoices to my car profile?',
        answer: 'We are currently building a feature which allows documents and invoices to be added to a car profile on Custodian. This is currently work in progress and will be added soon, so keep an eye out for the new feature when we launch it.',
        links: undefined,
    },
    'faq-13': {
        id: 'faq-13',
        text: 'How do I change my personal details?',
        answer: 'To change your personal details, go to the ‘Account’ menu by clicking the profile icon in the top right of the application, and then go to ‘Your profile’. Once there, you can make any changes to your personal details and save the changes.',
        links: undefined,
    },
    'faq-14': {
        id: 'faq-14',
        text: 'How do I add or change my user profile photo?',
        answer: 'To change your profile image, go to the ‘Account’ menu by clicking the profile icon in the top right of the application, and then go to ‘Your profile’. Once there, click on the circular profile image to update it. You can also update your profile photo by clicking on the profile image directly on your Garage page.',
        links: undefined,
    },
    'faq-15': {
        id: 'faq-15',
        text: 'How do I add a bio to my Custodian profile?',
        answer: 'To add a bio to your profile, go to the ‘Account’ menu by clicking the profile icon in the top right of the application, and then go to ‘Your profile’. Once there, you can click on ‘Your Bio’ box or on desktop click the pencil icon button in line with the ‘Your Profile’ title to add or change your profile bio. For users that do not have an existing bio, you are able to click the grey bio box under your name on the Garage page.',
        links: undefined,
    },
    'faq-16': {
        id: 'faq-16',
        text: 'Is my Custodian user profile visible to other users?',
        answer: 'Custodian takes data privacy extremely seriously and by default all user information and data is not visible to any other users. Similarly our systems ensure that no third party can access or use your data without your prior consent. We are, nonetheless, planning on launching a community element to Custodian where users will be able to share certain details about their digital garages and cars should they wish to, but this shall be on a strict opt-in basis and with your explicit consent.',
        links: undefined,
    },
    'faq-17': {
        id: 'faq-17',
        text: 'How do I delete a task associated with one of my cars?',
        answer: "To delete a task, go to 'Tasks' and click on the desired task you want to delete. On the desktop application click on the ‘Remove’ button in the top right of the task, and on mobile click the three dots on the top right and then click ‘Delete task’. Be warned that deleting a task is a permanent action. You will not be able to recover the task once you delete it.",
        links: undefined,
    },
    'faq-18': {
        id: 'faq-18',
        text: 'I have completed a task. What should I do?',
        answer: 'Great! Once you have completed a task, click on the check box next to ‘Outstanding’. This will re-categorise the task as being completed and remove it from your outstanding task list. Once completed you can also use the filters to view the completed tasks all in one place.',
        links: undefined,
    },
    'faq-19': {
        id: 'faq-19',
        text: 'What happens to tasks that have been completed?',
        answer: 'Completed tasks are still stored in the Task Management System so that you can keep track of what has been completed. You can view the completed tasks by selecting ‘Completed’ on the filter system.',
        links: undefined,
    },
    'faq-20': {
        id: 'faq-20',
        text: 'How do I set reminders for tasks?',
        answer: 'We have not added the Reminders system yet but are currently working on it. The feature will be added soon, so keep an eye out for when we launch it.',
        links: undefined,
    },
    'faq-21': {
        id: 'faq-21',
        text: 'Can I listen to the Apex podcast outside of the Custodian platform?',
        answer: 'Yes! The Apex podcast by Custodian is available on most of the main podcasting platforms including Spotify, Google Podcasts, Soundcloud, Apple Podcasts and Amazon Podcasts.',
        links: undefined,
    },
    'faq-22': {
        id: 'faq-22',
        text: 'I would like to propose a guest for the Apex podcast. How do I get in touch?',
        answer: 'We would love to hear any suggestions for Apex podcast guests. Please get in touch via email at hello@custodian.club.',
        links: undefined,
    },
    'faq-23': {
        id: 'faq-23',
        text: 'Does Custodian work across my different devices, i.e. mobile, tablet and desktop computer?',
        answer: 'Absolutely! Custodian works across all devices, including computers, tablets and mobiles. Simply log in to Custodian on your desktop at: https://app.custodian.club/ and upload the photos from there. Anything you upload on the web version of Custodian will automatically be available in the mobile and tablet version as well.',
        links: undefined,
    },
    'faq-24': {
        id: 'faq-24',
        text: 'How do I change my email address?',
        answer: 'Given that your email address is also your username for login, we do not support changing your email address directly on the platform. Therefore either you can set up a new account with your updated email address and re-upload your car(s) and details, or please send us an email at hello@custodian.club and we will look into updating your email address on our systems.',
        links: undefined,
    },
    'faq-25': {
        id: 'faq-25',
        text: 'I forgot my password. How can I reset it?',
        answer: "Click on 'Forgotten password' below the sign in button on the login page. Then enter the email address you used to register for your Custodian account, and we will email you with instructions for changing your password.",
        links: undefined,
    },
    'faq-26': {
        id: 'faq-26',
        text: 'How do I add or update my address?',
        answer: 'To add or update your address, go to the ‘Account’ menu by clicking the profile icon in the top right of the application, and then go to ‘Your profile’. Once there, click on the ‘Address’ section of the ‘Account Details’ to update your address.',
        links: undefined,
    },
    'faq-27': {
        id: 'faq-27',
        text: 'How do I change the units being displayed (i.e. cc, litres, ci)?',
        answer: 'To change the units displayed on the Custodian platform, on mobile go to the ‘Account’ menu by clicking the profile icon in the top right of the application, and then select ‘Preferences’. On desktop, click the cog icon in the top right corner to take you directly to the ‘Preferences’ page.',
        links: undefined,
    },
    'faq-28': {
        id: 'faq-28',
        text: 'I prefer the grid view of my garage, instead of the list view. How do I choose the default view?',
        answer: 'You can choose the default view of your digital garage in ‘Preferences’. On mobile go to the ‘Account’ menu by clicking the profile icon in the top right of the application, and then select ‘Preferences’. On desktop, click the cog icon in the top right corner to take you directly to the ‘Preferences’ page.',
        links: undefined,
    },
    'faq-29': {
        id: 'faq-29',
        text: 'Can I share my car profile with others?',
        answer: 'Currently you are unable to share your car details with other people through Custodian. We are, nonetheless, planning on launching a community element to Custodian in the future where users will be able to share certain details about their digital garages and cars should they wish to. This shall be on a strict opt-in basis and with your explicit consent. This is currently work in progress and will be added soon, so keep an eye out for the new feature when we launch it.',
        links: undefined,
    },
    'faq-30': {
        id: 'faq-30',
        text: 'Am I able to connect with fellow car enthusiasts on Custodian?',
        answer: 'Currently you are unable to connect with other enthusiasts on Custodian. We are, nonetheless, planning on launching a community element to Custodian in the future where users will be able to share certain details about their digital garages and cars should they wish to. This is currently work in progress and will be added soon, so keep an eye out for the new feature when we launch it.',
        links: undefined,
    },
    'faq-31': {
        id: 'faq-31',
        text: 'How can I request a feature which I think would be useful for me and others?',
        answer: 'We would love to hear your feedback and ideas for new features. Please reach out to us via hello@custodian.club to share all your great ideas with us. We are looking forward to hearing from you!',
        links: undefined,
    },
}

const contactReasons: IContactReason[] = [
    {
        uid: '0',
        name: 'General Enquiry',
        subject: 'General Enquiry - Custodian App',
    },
    {
        uid: '1',
        name: 'Feedback',
        subject: 'Feedback - Custodian App',
    },
    {
        uid: '2',
        name: 'Help',
        subject: 'Help - Custodian App',
    },
    {
        uid: '3',
        name: 'Media',
        subject: 'Media - Custodian App',
    },
    {
        uid: '4',
        name: 'Partnership',
        subject: 'Partnership - Custodian App',
    },
    {
        uid: '5',
        name: 'Other',
        subject: 'Other - Custodian App',
    },
]

export const faqPageCopyData: IFAQPageCopy = {
    faqSectionsIDs: faqSectionsIDs,
    faqSectionsData: faqSectionsData,
    faqQuestionsData: faqQuestionsData,
}

export const helpContactReasonsData: IHelpPageContact = {
    selected: '0',
    list: contactReasons,
}
