import gql from 'graphql-tag'
import { get_check_cookie_name } from '../../../helpers/cookies'
import { client } from '../../../helpers/prismicUtils/prismicHelpers'

/*
 * Apex All Podcasts
 */
const apexAllPodcastsQuery = gql`
    query allApex_podcastss {
        allApex_podcastss(sortBy: date_DESC, last: 100) {
            edges {
                node {
                    date
                    name
                    short_title
                    title
                    blurb
                    spotify_url {
                        ... on _ExternalLink {
                            url
                        }
                    }
                    google_podcasts_url {
                        ... on _ExternalLink {
                            url
                        }
                    }
                    apple_url {
                        ... on _ExternalLink {
                            url
                        }
                    }
                    soundcloud_url {
                        ... on _ExternalLink {
                            url
                        }
                    }

                    podcast_embed_link {
                        ... on _ExternalLink {
                            url
                        }
                    }
                }
            }
        }
    }
`

export const apexAllpodcastsQuery = async () => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexAllPodcastsQuery,
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}
