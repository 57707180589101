import * as React from 'react'
import styled from 'styled-components'
import QrCodeActionBtnWrapper from '../../atoms/Button/qrCodeActionBtnWrapper'
import PdfDownloadButton from '../../atoms/pdfDocument/pdfDownloadButton'
import PngDownloadButton from '../../atoms/pngDownloadButton/pngDownloadButton'
import QrCodeSVG from '../../atoms/qrCodeSvg/QrCodeSvg'
import CrossIcon from '../../atoms/icons/crossIcon'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

type StyledProps = {
    $height?: string
    $width?: string
    $iconSize?: string
}

const OverWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5px;
    border: none;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
`

const Close = styled.div<StyledProps>`
    position: absolute;
    right: 5px;
    top: 0;
    padding: 16px;
    cursor: pointer;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-top: 25px;
    padding-bottom: 20px;
`
const SVGWrapper = styled.div`
    width: 300px;
`
const BtnsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    width: 200px;
    padding: 20px;
`
const Details = styled.div`
    width: 100%;
    background-color: var(--background_neutral);
    display: flex;
    padding: 10px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
`
const VerticalLine = styled.div`
    width: 1px;
    background-color: var(--text-darker, #616161);
    height: 20px;
`

const CarTitle = styled.div<StyledProps>`
    padding-left: 10px;
    color: var(--text-darker, #616161);
    font-family: Lato-bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const ShareSource = styled.div<StyledProps>`
    color: var(--text-muted);
    text-transform: uppercase;
    padding-right: 10px;
`

const PaddingTop = styled.div<StyledProps>`
    padding-top: 20px;
`

type Props = {
    qr_code_link: string
    qr_code_id: string
    car_title?: string | undefined
    isGarage?: boolean
    closeModal: any
    onStickerBtnClick?: any
}

const QrCodeModalDesktop: React.FunctionComponent<Props> = (props) => {
    let {
        qr_code_id,
        qr_code_link,
        car_title,
        isGarage,
        closeModal,
        onStickerBtnClick,
    } = props

    const { theme } = useThemes()

    return (
        <OverWrapper>
            <Close onClick={closeModal}>
                <CrossIcon />
            </Close>
            <Wrapper>
                <SVGWrapper>
                    <QrCodeSVG
                        link={qr_code_link}
                        size={300}
                        hasBorder
                        withLogo
                        id={qr_code_id}
                        fgColor={colours[theme].text_strong}
                        bgColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                </SVGWrapper>
                <BtnsWrapper>
                    <QrCodeActionBtnWrapper
                        onClick={onStickerBtnClick}
                        variant={'sticker'}
                        isFlexColumn={true}
                        height="140px"
                        dataCyId="load_qr_code_customisation_desktop"
                    >
                        Customise QR Code Sticker
                    </QrCodeActionBtnWrapper>
                    <PaddingTop />
                    <PdfDownloadButton
                        id_of_qr_code_to_export={qr_code_id}
                        qr_code_title_inside_pdf={
                            car_title ? car_title : 'garage'
                        }
                        dataCyId="download_qr_code_pdf_desktop"
                    />
                    <PaddingTop />
                    <PngDownloadButton
                        id_of_qr_code_to_export={qr_code_id}
                        car_title={car_title ? car_title : ''}
                        dataCyId="download_qr_code_png_desktop"
                    />
                </BtnsWrapper>
            </Wrapper>
            <Details>
                {isGarage ? (
                    <>
                        <ShareSource>Garage</ShareSource>
                        <VerticalLine />
                    </>
                ) : (
                    <>
                        <ShareSource>Car</ShareSource>
                        <VerticalLine />
                    </>
                )}
                <CarTitle>{car_title}</CarTitle>
            </Details>
        </OverWrapper>
    )
}

export default QrCodeModalDesktop
