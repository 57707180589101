import { IDropdownItem, IDropdownNormalised } from 'entityModels'
import {
    IFilterID,
    IShowroomFilterRadioOptionItem,
    IShowroomFiltersState,
} from '../../showroom/types'

export type IArgFiltersDisplayVal = {
    state: IShowroomFiltersState
    id: IFilterID
    optionsListToMatch?: IDropdownItem[] | IShowroomFilterRadioOptionItem[]
    values?: string[]
}

export const convertMobileFilterSelectBtnDisplayTxt = (
    p: IArgFiltersDisplayVal
): string => {
    let finalRes: string = ''

    // @ts-ignore
    let idstoShow = p.state[p.id].values

    if (
        idstoShow?.length === 0 &&
        (!p.values || (p.values && p.values.length === 0))
    ) {
        finalRes = ''
        return finalRes
    }

    if (p.optionsListToMatch) {
        let namesMatch: string[] = []

        for (let i = 0; i < p.optionsListToMatch.length; i++) {
            if (
                // @ts-ignore
                (p.optionsListToMatch[i].id &&
                    // @ts-ignore
                    idstoShow?.includes(p.optionsListToMatch[i].id)) ||
                // @ts-ignore
                (p.optionsListToMatch[i].uid &&
                    // @ts-ignore
                    idstoShow?.includes(p.optionsListToMatch[i].uid))
            ) {
                namesMatch = [...namesMatch, p.optionsListToMatch[i].name]
            }
        }

        if (namesMatch.length > 0) {
            if (namesMatch.length === 2) {
                finalRes = `${namesMatch[0]}${
                    namesMatch[1] ? `, ${namesMatch[1]}` : ''
                }`
            } else if (namesMatch.length > 2) {
                let namesMatchToShow: string[] = []

                for (let i = 0; i < namesMatch.length; i++) {
                    if (i < 2) {
                        namesMatchToShow = [...namesMatchToShow, namesMatch[i]]
                    }
                }
                finalRes = `${namesMatch[0]}${
                    namesMatch[1] ? `, ${namesMatch[1]}` : ''
                }${namesMatch[2] ? `, ${namesMatch[2]}` : ''} +${
                    // @ts-ignore
                    p.state[p.id].values?.length - 2
                }`
            } else {
                finalRes = `${namesMatch[0]}${
                    namesMatch[1] ? `, ${namesMatch[1]}` : ''
                }${namesMatch[2] ? `, ${namesMatch[2]}` : ''}${
                    namesMatch[3] ? `, ${namesMatch[3]}` : ''
                }`
            }
        }
    }

    if (finalRes.split('').length == 0 && p.values) {
        finalRes = 'all the makes'
    }
    return finalRes
}

export const convertFiltersArrayDisplayTxt = (
    p: IArgFiltersDisplayVal
): IDropdownNormalised => {
    let finalRes: IDropdownNormalised = {}
    // @ts-ignore
    if (p.optionsListToMatch && p.state[p.id] && p.state[p.id].values) {
        // @ts-ignore
        let idstoShow: string[] = p.state[p.id].values

        for (let i = 0; i < p.optionsListToMatch.length; i++) {
            if (
                // @ts-ignore
                (p.optionsListToMatch[i].id &&
                    // @ts-ignore
                    idstoShow?.includes(p.optionsListToMatch[i].id)) ||
                // @ts-ignore
                (p.optionsListToMatch[i].uid &&
                    // @ts-ignore
                    idstoShow?.includes(p.optionsListToMatch[i].uid))
            ) {
                // @ts-ignore
                let idd: string | undefined = p.optionsListToMatch[i].id
                    ? // @ts-ignore
                      p.optionsListToMatch[i].id
                    : // @ts-ignore
                    p.optionsListToMatch[i].uid
                    ? // @ts-ignore
                      p.optionsListToMatch[i].uid
                    : undefined

                if (idd) {
                    finalRes = {
                        ...finalRes,
                        [`${idd}`]: {
                            uid: `${idd}`,
                            name: p.optionsListToMatch[i].name,
                        },
                    }
                }
            }
        }
        // @ts-ignore
    }
    return finalRes
}

export const convertFiltersDisplayTxt = (p: IArgFiltersDisplayVal): string => {
    let finalRes: string = ''

    // @ts-ignore
    switch (p.state[p.id].type) {
        case 'multiselect':
            return convertMobileFilterSelectBtnDisplayTxt(p)

        case 'range_value':
            if (p.id === 'year' && p.state.year.value) {
                finalRes = `${p.state.year.value}`
            } else if (p.state[p.id]) {
                finalRes = `${
                    // @ts-ignore
                    p.state[p.id].valueFrom ? p.state[p.id].valueFrom : ''
                    // @ts-ignore
                } - ${p.state[p.id].valueTo ? p.state[p.id].valueTo : ''}`
            }

            return finalRes

        case 'radio_select':
            // @ts-ignore
            if (p.state[p.id].options) {
                // @ts-ignore
                let arr = p.state[p.id].options.filter(
                    // @ts-ignore
                    (valID: string) => valID === p.state[p.id].value
                )

                finalRes = arr ? arr[0] : ''
            }

            return finalRes

        default:
            return finalRes
    }
}
