import * as React from 'react'
import styled from 'styled-components'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import Icon from '../icons'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = { $theme: ITheme }

const ListItem = styled.li<IStyle>`
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 24px 16px;
    list-style-type: none;
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`

const ListContentRow = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
`

const Handler = styled.div`
    width: 26px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
`

const TextWrapper = styled.div`
    position: relative;
    color: transparent;
    padding-top: 4px;
    padding-left: 16px;
`

const Text = styled.textarea<IStyle>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-family: Lato;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    resize: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 5e-5em;
    color: ${(props) => colours[props.$theme].text_default};
    background-color: ${(props) =>
        colours[props.$theme].background_neutral_subtle};
    vertical-align: center;

    @media ${device.ipad} {
        font-size: 14px;
        line-height: 20px;
    }

    :focus {
        outline: 0;
    }
    ::placeholder {
        color: ${(props) => colours[props.$theme].text_muted};
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: ${(props) => colours[props.$theme].background_default};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${(props) => colours[props.$theme].border_muted};
    }
    /* width */
    ::-webkit-scrollbar {
        width: 4px;
        border-radius: 20px;
    }
`

const DragHandle = SortableHandle(() => (
    <motion.div whileTap={{ scale: 1.2 }}>
        <Handler>
            <Icon icon="handler" />
        </Handler>
    </motion.div>
))

const SortableItem = SortableElement(
    ({
        item,
        formikprops,
        index,
    }: {
        item: EnumerableObject

        formikprops: {
            handleChange: (e: React.ChangeEvent<any>) => void

            values: {
                [key: string]: string
            }

            setFieldValue: (
                field: string,
                value: any,
                shouldValidate?: boolean | undefined
            ) => void
        }

        index: number
    }) => {
        const { theme } = useThemes()

        return (
            <ListItem key={index} style={{ zIndex: 5 }} $theme={theme}>
                <ListContentRow>
                    <DragHandle />
                    <div style={{ position: 'relative', width: '100%' }}>
                        <TextWrapper>
                            {formikprops.values[item.id]
                                ? formikprops.values[item.id]
                                : item.order === 1
                                ? 'i.e. This is one of only 400 Heritage Editions produced in both long and short wheelbase form to mark the ending of original Defender production.'
                                : item.order === 2
                                ? 'i.e. Finished in the beautifull N2 Orange colour'
                                : item.order === 3
                                ? 'i.e. Features many nods to models past including the Almond cloth upholstery, painted steel wheels and HUE 166 badging, this being the registration number of the first Land Rover prototype.'
                                : ''}

                            <Text
                                $theme={theme}
                                id={item.id}
                                name={item.id}
                                placeholder={
                                    item.order === 1
                                        ? 'i.e. This is one of only 400 Heritage Editions produced in both long and short wheelbase form to mark the ending of original Defender production.'
                                        : item.order === 2
                                        ? 'i.e. Finished in the beautifull N2 Orange colour'
                                        : item.order === 3
                                        ? 'i.e. Features many nods to models past including the Almond cloth upholstery, painted steel wheels and HUE 166 badging, this being the registration number of the first Land Rover prototype.'
                                        : ''
                                }
                                onChange={formikprops.handleChange}
                                value={
                                    formikprops.values[item.id] !== undefined
                                        ? formikprops.values[item.id]
                                        : ''
                                }
                                maxLength={200}
                            />
                        </TextWrapper>
                    </div>
                </ListContentRow>
            </ListItem>
        )
    }
)

type EnumerableObject = {
    id: string
    text: string
    order: number
    formikprops: any
}

type Props = {
    item: EnumerableObject
    index: number
    formikprops: any
    isJourney?: boolean
}

type State = {
    item: EnumerableObject
}

class DraggableItemEditableJourneyDesktop extends React.Component<
    Props,
    State
> {
    state = {
        item: this.props.item,
    }

    componentDidMount() {
        this.setState({ item: this.props.item })
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props !== prevProps) {
            this.setState({ item: this.props.item })
        }
    }

    render() {
        const { index, formikprops } = this.props
        const { item } = this.state
        return (
            <SortableItem
                key={index}
                index={index}
                item={item}
                formikprops={formikprops}
            />
        )
    }
}

export default DraggableItemEditableJourneyDesktop
