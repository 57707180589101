import { call, fork, put, take } from 'redux-saga/effects'
import * as userActions from '../actions/index'
import { I_getUserDirectSharesReceivedRequest_req_payload } from '../actions/loadingActions'
import { api } from '../../services'
import { IURL_user_getSharesList_arg } from 'ApiInterfaces'
import { I_getUserDirectSharesReceivedSuccess_res_payload } from '../actions/actions'
import { IUser_SharesReceived_byEntity } from 'myModels'
import { ConvertUserDirectSharesReceived_toObj } from '../../conversions/shares/convertDirectShares'
import { IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { IUnauthHandlerPayload, unauthHandlerSaga } from './unauthHandler'

export function* getUserDirectSharesReceived(
    p: I_getUserDirectSharesReceivedRequest_req_payload
): any {
    try {
        let final_cars: undefined | IUser_SharesReceived_byEntity = undefined
        let final_garages: undefined | IUser_SharesReceived_byEntity = undefined

        if (p === 'car' || p === 'car_and_garage') {
            const apiArgs: IURL_user_getSharesList_arg = {
                share_type: 'direct_share',
                resource_type: 'car',
                selected_type: 'received',
            }

            let api_res_cars: any = yield call(
                api.sharing.getUserDirectSharesListAPI,
                apiArgs
            ) // IListUserShares_API_res

            let cars_res = api_res_cars.shares_received
            final_cars = {
                shares_received_data: {},
                shares_received_ids: [],
                accepted_shares_received_ids: [],
                pending_shares_received_ids: [],
                ignored_shares_received_ids: [],
            }

            if (cars_res && cars_res.length) {
                let cars_shares_data =
                    ConvertUserDirectSharesReceived_toObj(cars_res)

                let cars_shares_ids = Object.keys(cars_shares_data)
                let accepted_cars_shares_ids: string[] = cars_shares_ids
                    ? cars_shares_ids.filter(
                          (id) =>
                              cars_shares_data &&
                              cars_shares_data[id].approvalStatus === 'accepted'
                      )
                    : []
                let pending_cars_shares_ids: string[] = cars_shares_ids
                    ? cars_shares_ids.filter(
                          (id) =>
                              cars_shares_data &&
                              cars_shares_data[id].approvalStatus === 'pending'
                      )
                    : []
                let ignored_cars_shares_ids: string[] = cars_shares_ids
                    ? cars_shares_ids.filter(
                          (id) =>
                              cars_shares_data &&
                              cars_shares_data[id].approvalStatus === 'ignored'
                      )
                    : []

                final_cars = {
                    ...final_cars,
                    shares_received_data: cars_shares_data,
                    shares_received_ids: cars_shares_ids,
                    accepted_shares_received_ids: accepted_cars_shares_ids,
                    pending_shares_received_ids: pending_cars_shares_ids,
                    ignored_shares_received_ids: ignored_cars_shares_ids,
                }
            }
        }

        if (p === 'garage' || p === 'car_and_garage') {
            const apiArgs: IURL_user_getSharesList_arg = {
                share_type: 'direct_share',
                resource_type: 'garage',
                selected_type: 'received',
            }

            const api_res_garages: any = yield call(
                api.sharing.getUserDirectSharesListAPI,
                apiArgs
            )
            // IListUserShares_API_res
            let garages_res = api_res_garages.shares_received

            final_garages = {
                shares_received_data: {},
                shares_received_ids: [],
                accepted_shares_received_ids: [],
                pending_shares_received_ids: [],
                ignored_shares_received_ids: [],
            }

            if (garages_res && garages_res.length > 0) {
                let garages_shares_data =
                    ConvertUserDirectSharesReceived_toObj(garages_res)
                let garages_shares_ids = [...Object.keys(garages_shares_data)]
                let accepted_garages_shares_ids: string[] = garages_shares_ids
                    ? garages_shares_ids.filter(
                          (id) =>
                              garages_shares_data &&
                              garages_shares_data[id].approvalStatus ===
                                  'accepted'
                      )
                    : []
                let pending_garages_shares_ids: string[] = garages_shares_ids
                    ? garages_shares_ids.filter(
                          (id) =>
                              garages_shares_data &&
                              garages_shares_data[id].approvalStatus ===
                                  'pending'
                      )
                    : []
                let ignored_garages_shares_ids: string[] = garages_shares_ids
                    ? garages_shares_ids.filter(
                          (id) =>
                              garages_shares_data &&
                              garages_shares_data[id].approvalStatus ===
                                  'ignored'
                      )
                    : []

                final_garages = {
                    shares_received_data: garages_shares_data,
                    shares_received_ids: garages_shares_ids,
                    accepted_shares_received_ids: accepted_garages_shares_ids,
                    pending_shares_received_ids: pending_garages_shares_ids,
                    ignored_shares_received_ids: ignored_garages_shares_ids,
                }
            }
        }

        let successArg: I_getUserDirectSharesReceivedSuccess_res_payload = {
            resource_type: p,
            cars: final_cars,
            garages: final_garages,
        }

        yield put(
            userActions.default.actions.getUserDirectSharesReceivedSuccess(
                successArg
            )
        )
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: getUserDirectSharesReceived,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(
                userActions.default.errorActions.getUserDirectSharesReceivedError(
                    typedError
                )
            )
        }
    }
}

function* watcherGetSharesReceived() {
    while (true) {
        const { payload } = yield take(
            userActions.default.loadingActions
                .getUserDirectSharesReceivedRequest
        )

        yield call(getUserDirectSharesReceived, payload)
    }
}

const get_shares_received_watcher: any[] = [fork(watcherGetSharesReceived)]

export default get_shares_received_watcher
