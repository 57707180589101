import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const InsuranceIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size ? `${Number(size) * 0.75}` : '12'}
            height={size ?? '16'}
            viewBox="0 0 12 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0.574951L6.53033 1.10528C8.01725 2.5922 10.2978 3.63368 11.2442 3.95959L11.75 4.13377V9.88778C11.75 11.2951 10.7357 12.5214 9.68322 13.3983C8.59564 14.3045 7.2623 15.0105 6.23397 15.3482L6 15.425L5.76603 15.3482C4.7377 15.0105 3.40436 14.3045 2.31678 13.3983C1.26426 12.5214 0.25 11.2951 0.25 9.88778V4.13377L0.755797 3.95959C1.70221 3.63368 3.98275 2.5922 5.46967 1.10528L6 0.574951ZM1.75 5.18729V9.88778C1.75 10.5906 2.29824 11.4304 3.27697 12.2459C4.13642 12.962 5.18082 13.5343 6 13.8407C6.81918 13.5343 7.86358 12.962 8.72303 12.2459C9.70176 11.4304 10.25 10.5906 10.25 9.88778V5.18729C9.17112 4.75158 7.41547 3.87909 6 2.6576C4.58453 3.87909 2.82888 4.75158 1.75 5.18729Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 14.6356V2.13562H6.75V14.6356H5.25Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default InsuranceIcon
