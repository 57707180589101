import * as React from 'react'
import styled from 'styled-components'
import QrCodeSVG from '../../../atoms/qrCodeSvg/QrCodeSvg'
import { useRef } from 'react'
import { ChromePicker } from 'react-color'
import { ChangeStickerTheme, IStickerTheme } from '../fns'
import { useOnClickOutside } from '../../../templates/clickOutside'
import QrCodeStickerThemeBtnJourney from '../../../atoms/Button/qrCodeStickerThemeBtnJourney'
import { device } from '../../../templates/displays/devices'

type StyledProps = {
    $height?: string
    $width?: string
    $iconSize?: string
}

const OverWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 80px;
    width: 100%;
`

const SVGStickerWrapper = styled.div`
    width: 260px;
    height: 260px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 5px 5px 20px 3px rgba(0, 0, 0, 0.05);
`

type ISvgColours = {
    $colour: string
}
const SVGColouredCircle = styled.div<ISvgColours>`
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => (props.$colour ? props.$colour : '#ffe5fb')};
`
const SVGBackgroundWrapper = styled.div`
    width: 205px;
    height: 205px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffffff;
`
const SVGBackground = styled.div<ISvgColours>`
    width: 190px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: ${(props) => (props.$colour ? props.$colour : '#fcf8ec')};
`

const SectionTitleWrapper = styled.div<StyledProps>`
    display: flex;
    width: 100%;
    align-items: center;
    padding-bottom: 24px;
    padding-top: 40px;
`

const SectionTitle = styled.div<StyledProps>`
    color: var(--text-strong, #121212);
    font-family: Lato-Semibold;
    font-size: 18px;
`
const PaddingTop20 = styled.div<StyledProps>`
    padding-top: 20px;
`

const BtnRefWrapp = styled.div`
    position: absolute;
    top: 70px;
    z-index: 1;
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
`

type Props = {
    qr_code_link: string
    predefinedStickerThemes: IStickerTheme[]
    state: {
        colours: {
            colour_one: string
            colour_two: string
        }
        openColourPicker?: 'one' | 'two' | undefined
    }
    dispatch: any
    activeThemeIndex: number | null
    setActiveThemeIndex: any
}

const QrCodeStickerJourneyFormMobile: React.FunctionComponent<Props> = (
    props
) => {
    let {
        qr_code_link,
        predefinedStickerThemes,
        state,
        dispatch,
        activeThemeIndex,
        setActiveThemeIndex,
    } = props

    const ref = useRef<HTMLDivElement>(null)

    useOnClickOutside(ref, () => {
        if (ref.current) {
            dispatch({
                type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                payload: {
                    ...state,
                    openColourPicker: undefined,
                },
            })
        }
    })

    return (
        <OverWrapper>
            <Wrapper>
                <SVGStickerWrapper>
                    <SVGColouredCircle $colour={state.colours.colour_one}>
                        <SVGBackgroundWrapper>
                            <SVGBackground $colour={state.colours.colour_two}>
                                <QrCodeSVG
                                    bgColor="transparent"
                                    link={qr_code_link}
                                    size={130}
                                    withLogo
                                    id={'showcase_journey_svg_id'}
                                    logoSize={32}
                                />
                            </SVGBackground>
                        </SVGBackgroundWrapper>
                    </SVGColouredCircle>
                </SVGStickerWrapper>

                <SectionTitleWrapper>
                    <SectionTitle>Play with custom colours</SectionTitle>
                </SectionTitleWrapper>

                <div style={{ position: 'relative', width: '100%' }}>
                    <QrCodeStickerThemeBtnJourney
                        colourOne={state.colours.colour_one}
                        txt={'Outer Ring Colour'}
                        width="100%"
                        hasEditIcon
                        onClick={() =>
                            dispatch({
                                type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                payload: {
                                    ...state,
                                    openColourPicker: 'one',
                                },
                            })
                        }
                    />
                    {state.openColourPicker === 'one' && (
                        <BtnRefWrapp ref={ref}>
                            <ChromePicker
                                color={
                                    state.openColourPicker === 'one'
                                        ? state.colours.colour_one
                                        : undefined
                                }
                                disableAlpha
                                onChange={(colour: any) => {
                                    setActiveThemeIndex(null)
                                    dispatch({
                                        type: ChangeStickerTheme.CHANGE_COLOUR_ONE,
                                        payload: {
                                            colours: {
                                                ...state.colours,
                                                colour_one: colour.hex,
                                            },
                                        },
                                    })
                                }}
                            />
                        </BtnRefWrapp>
                    )}
                </div>
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        paddingTop: 16,
                    }}
                >
                    <QrCodeStickerThemeBtnJourney
                        colourOne={state.colours.colour_two}
                        txt={'background colour'}
                        width="100%"
                        hasEditIcon
                        onClick={() =>
                            dispatch({
                                type: ChangeStickerTheme.OPEN_COLOUR_PICKER,
                                payload: {
                                    ...state,
                                    openColourPicker: 'two',
                                },
                            })
                        }
                    />
                    {state.openColourPicker === 'two' && (
                        <BtnRefWrapp ref={ref}>
                            <ChromePicker
                                color={
                                    state.openColourPicker === 'two'
                                        ? state.colours.colour_two
                                        : undefined
                                }
                                disableAlpha
                                onChange={(colour: any) => {
                                    setActiveThemeIndex(null)
                                    dispatch({
                                        type: ChangeStickerTheme.CHANGE_COLOUR_TWO,
                                        payload: {
                                            colours: {
                                                ...state.colours,
                                                colour_two: colour.hex,
                                            },
                                        },
                                    })
                                }}
                            />
                        </BtnRefWrapp>
                    )}
                </div>
                <PaddingTop20 />

                {activeThemeIndex === null && (
                    <div
                        style={{
                            fontSize: 12,
                            color: 'rgb(63 63 63)',
                            fontFamily: 'Lato',
                            fontStyle: 'italic',
                        }}
                    >
                        <div>Note:</div> Please avoid dark colours for the
                        background as it will make the QR code unscannable.
                    </div>
                )}

                <SectionTitleWrapper>
                    <SectionTitle>Check our themes</SectionTitle>
                </SectionTitleWrapper>

                {predefinedStickerThemes &&
                    predefinedStickerThemes.map((theme, index) => (
                        <React.Fragment key={`qr_${theme}_${index}_mobile`}>
                            <QrCodeStickerThemeBtnJourney
                                key={index}
                                index={index}
                                colourOne={theme.colour_one}
                                colourTwo={theme.colour_two}
                                txt={
                                    theme.name ? theme.name : 'UnSectiontitled'
                                }
                                isActiveBtn={
                                    activeThemeIndex === index ? true : false
                                }
                                width="100%"
                                onClick={() => {
                                    setActiveThemeIndex(index)
                                    dispatch({
                                        type: ChangeStickerTheme.CHANGE_BOTH_COLOURS,
                                        payload: {
                                            colours: {
                                                colour_one: theme.colour_one,
                                                colour_two: theme.colour_two,
                                            },
                                        },
                                    })
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth',
                                    })
                                }}
                            />
                            <PaddingTop20 />
                        </React.Fragment>
                    ))}
            </Wrapper>
        </OverWrapper>
    )
}

export default QrCodeStickerJourneyFormMobile
