import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import add_plus_darker from '../../../public/assets/icons/add_plus_darker.svg'
import edit_pen_v2 from '../../../public/assets/icons/edit_pen_v2.svg'

import { motion } from 'framer-motion'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
export interface IButtonProps {
    children?: React.ReactNode
    onClick?: any
    theme: 'add-icon-primary-text' | 'edit-icon-primary-text'
    readonly divider?: any
    readonly disabled?: boolean
    alignLeft?: boolean
    width?: string
    onKeyDown?: any
    fontSize?: number
    dataCyId?: string
}

export interface IButtonStyledProps {
    $theme: ITheme
    readonly disabled?: boolean
    $alignLeft?: boolean
    $width?: string
    $fontSize?: number
    $borderRadius?: number
}
const NakedText = styled.button<IButtonStyledProps>`
    background-color: transparent;
    border: none;
    cursor: pointer !important;
    transition: all 0.1s;
    :focus {
        outline: 0;
    }
    width: ${(props) => (props.$width ? props.$width : 'auto')};

    font-family: Lato;
    text-align: ${(props) => (props.$alignLeft ? 'left !important' : 'center')};
    font-size: ${(props) =>
        props.$fontSize ? `${props.$fontSize}px` : 'inherit'};
    color: ${(props) =>
        props.disabled === true
            ? colours[props.$theme].text_muted_light
            : colours[props.$theme].primary};
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin: 0;

    @media ${device.mobile} {
    }
    :hover {
        opacity: 0.7;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
const EditAddLineBtn = (props: IButtonProps) => {
    const { theme } = useThemes()
    let renderBtn = () => {
        switch (props.theme) {
            case 'add-icon-primary-text':
                return (
                    <NakedText
                        $theme={theme}
                        onKeyDown={props.onKeyDown && props.onKeyDown}
                        $alignLeft={props.alignLeft && props.alignLeft}
                        onClick={props.onClick}
                        disabled={props.disabled}
                        $width={props.width && props.width}
                        data-attr={props.dataCyId && props.dataCyId}
                        $fontSize={props.fontSize ? props.fontSize : 16}
                        style={{ padding: 'none' }}
                    >
                        <img
                            style={{
                                height: '13px',
                            }}
                            src={add_plus_darker}
                            alt="add-btn"
                        />
                        <div style={{ paddingLeft: '10px' }} />
                        {props.children}
                    </NakedText>
                )

            case 'edit-icon-primary-text':
                return (
                    <NakedText
                        $theme={theme}
                        onKeyDown={props.onKeyDown && props.onKeyDown}
                        $alignLeft={props.alignLeft && props.alignLeft}
                        onClick={props.onClick}
                        disabled={props.disabled}
                        $width={props.width && props.width}
                        data-attr={props.dataCyId && props.dataCyId}
                        $fontSize={props.fontSize && props.fontSize}
                    >
                        <img
                            style={{
                                height: '16px',
                            }}
                            src={edit_pen_v2}
                            alt="edit-btn"
                        />{' '}
                        <div style={{ paddingLeft: '10px' }} />
                        {props.children}
                    </NakedText>
                )
        }
    }

    return <motion.div whileTap={{ scale: 1.05 }}>{renderBtn()}</motion.div>
}

export default EditAddLineBtn
