import { IDirectShareOwnedItem } from 'entityModels'
import * as React from 'react'
import { Link } from 'react-router-dom'

import styled from 'styled-components'
import { shareCardConverterFormat } from '../../../redux/conversions/time'
import { device } from '../../templates/displays/devices'

import TooltipRowBtn from '../Button/tooltipRowBtn'
import DirectShareInfo from './directShareInfo'

const TableItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 35px;
    position: relative;
`

const TableItemInfoCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
`

const TableItemBtnsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
`

const Name = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 17px;
    max-width: 300px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const DateSpan = styled.span`
    color: var(--text-default, #666666);
    font-size: 12px;
    font-style: italic;
    display: inline-block;
`

const Container = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    background-color: var(--off-bg-color, #fafafa);
    border-top: 1px solid var(--border_muted_subtle);
    border-left: 1px solid var(--border_muted_subtle);
    border-right: 1px solid var(--border_muted_subtle);
    height: 85px;
    width: 40vw;
    min-width: 730px;
    padding-right: 30px;
    @media ${device.ipad} {
        min-width: 710px;
        padding-right: 0px;
    }
`

type IQuickShareActions = {
    settings: any
    addUserToShare: any
}

type Props = {
    carid?: string
    share: IDirectShareOwnedItem
    index: number
    isLast: boolean
    actionsData: IQuickShareActions
    isGarage?: boolean
}

const DirectShareRowDesktop: React.FunctionComponent<Props> = (
    props: Props
) => {
    let { share, isLast, index, actionsData, carid, isGarage } = props

    return (
        <Container
            to={
                isGarage
                    ? `/sharing/with-others/garage/edit-share?shareid=${share.id}&active_tab_id=settings`
                    : `/sharing/with-others/car/${carid}/edit-share?shareid=${share.id}&active_tab_id=settings`
            }
            style={{
                borderTopLeftRadius: index === 0 ? '5px' : '0px',
                borderBottomLeftRadius: isLast ? '5px' : '0px',

                borderTopRightRadius: index === 0 ? '5px' : '0px',
                borderBottomRightRadius: isLast ? '5px' : '0px',
            }}
        >
            <TableItemContainer>
                <TableItemInfoCol>
                    <Name>
                        {share.title ? (
                            share.title
                        ) : (
                            <span
                                style={{
                                    color: 'grey',
                                }}
                            >
                                Pending Account
                            </span>
                        )}
                    </Name>
                    <DateSpan>
                        Shared on {shareCardConverterFormat(share.created_at)}
                    </DateSpan>

                    {/* <Email>{share.email}</Email> */}
                </TableItemInfoCol>

                <TableItemBtnsRow onClick={(e: any) => e.preventDefault()}>
                    {/* <TooltipRowBtn
                        copy="copy private share invitation"
                        iconHeight="18px"
                        iconWidth="15px"
                        icon="copy_clipboard"
                        onClick={() => actionsData.copy()}
                    />

                    <TooltipRowBtn
                        copy="send email notification"
                        iconHeight="18px"
                        iconWidth="15px"
                        icon="email_icon"
                        onClick={() => actionsData.email()}
                    /> */}
                    <DirectShareInfo
                        share={share}
                        leftSideClick={() => actionsData.addUserToShare()}
                        rightSideClick={() => actionsData.settings()}
                    />
                    <div style={{ paddingRight: '10px' }} />
                    <TooltipRowBtn
                        copy="Add a person"
                        iconHeight="17px"
                        iconWidth="auto"
                        icon="add_user_blue"
                        onClick={() => actionsData.addUserToShare()}
                    />
                    <TooltipRowBtn
                        copy="Settings"
                        iconHeight="18px"
                        iconWidth="18px"
                        icon="blue_cog"
                        onClick={() => actionsData.settings()}
                    />
                </TableItemBtnsRow>
            </TableItemContainer>
        </Container>
    )
}

export default DirectShareRowDesktop
