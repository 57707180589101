import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import Button from '../Button/ButtonAtom'
import Expander from '../expander/expander'
import NoDataRectangle from '../noData/noDataRectangle'
import './styles.css'
import parse from 'html-react-parser'

const Reveal = styled.div<IStyle>`
    position: absolute;
    width: 100%;
    height: 280px;
    top: 1;
    z-index: 1;

    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 22.3%,
        #ffffff 55.53%
    );
    opacity: ${(props) => (props.isOpen ? '0' : '1')};

    transition: all 300ms;
`
const Wrapper = styled.div<IStyle>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: start;
    justify-self: start;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: start;
    justify-self: start;
    background-color: red;
`

const ReadMore = styled.div<IStyle>`
    z-index: 2;
    padding-top: ${(props) => (props.isOpen ? '20px' : '0px')};

    @media ${device.beyond_ipad_mobile} {
        padding-left: 5px;
    }
`

interface Props {
    description: string
    onClick?: any
    readOnlyMode?: boolean
}

type State = {
    isOpen: boolean
    showBoxInfo: boolean
}

interface IStyle {
    isOpen: boolean
}

class DescriptionDesktop extends React.Component<Props, State> {
    state = {
        showBoxInfo: false,
        isOpen: false,
    }

    toggleReveal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    setTrueBoxInfoToggle = () => this.setState({ showBoxInfo: true })

    setFalseBoxInfoToggle = () => this.setState({ showBoxInfo: false })

    render() {
        const { description, readOnlyMode } = this.props
        const { isOpen } = this.state
        let isDescriptionShort: boolean =
            description.length < 1822 ? true : false

        return (
            <Container>
                {/* IF NO DESCRIPTION */}
                {(description.length < 1 || description === '<p><br></p>') &&
                    // FOR USER'S OWN CAR (NO READ ONLY) WE DISPLAY ADD BTN
                    (!readOnlyMode ? null : (
                        // <GoToBoxDesktop
                        //     onClick={onClick && onClick}
                        //     onMouseEnter={this.setTrueBoxInfoToggle}
                        //     onMouseLeave={this.setFalseBoxInfoToggle}
                        // >
                        //     {showBoxInfo === false && <Icon icon="add_plus" />}
                        //     {showBoxInfo === true && (
                        //         <AddBoxTextInfo>
                        //             Add car's description
                        //         </AddBoxTextInfo>
                        //     )}
                        // </GoToBoxDesktop>
                        // FOR EXTERNAL CAR (READ ONLY MODE) WE DISPLAY NO DATA SQUARE
                        <NoDataRectangle text="no description" height="300px" />
                    ))}

                {description.length > 1 && (
                    <Wrapper
                        isOpen={isDescriptionShort === true ? true : isOpen}
                    >
                        <Reveal
                            isOpen={isDescriptionShort === true ? true : isOpen}
                        />
                        <Expander
                            height={
                                isDescriptionShort === true
                                    ? 'auto'
                                    : isOpen
                                    ? 'auto'
                                    : 200
                            }
                        >
                            {parse(description)}
                        </Expander>

                        {!isDescriptionShort && (
                            <ReadMore isOpen={isOpen}>
                                <Button
                                    onClick={(e: any) => {
                                        e.preventDefault()
                                        this.toggleReveal()
                                    }}
                                    theme="naked-text"
                                >
                                    {isOpen ? 'read less' : 'read more'}
                                </Button>
                            </ReadMore>
                        )}
                    </Wrapper>
                )}
            </Container>
        )
    }
}

export default DescriptionDesktop
