import * as React from 'react'
import styled from 'styled-components'
import NoDataRectangle from '../noData/noDataRectangle'
import parse from 'html-react-parser'
import './styles.css'
import ShowMoreBtn from '../Button/showMoreBtn'
import Expander from '../expander/expander'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: start;
    justify-self: start;
    overflow: auto;
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: start;
    justify-self: start;
    padding-top: 24px;
`

const GoToBoxDesktop = styled.div`
    width: 100%;
    height: 200px;
`

const Reveal = styled.div<{ isOpen: boolean; $theme: ITheme }>`
    width: 100%;
    height: 30px;
    background: ${(props) =>
        `linear-gradient(180deg, rgba(255, 255, 255, 0), ${
            colours[props.$theme].background_neutral_subtle
        })`};
    transform: translateY(-30px);
    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    transition: all 300ms;
`

interface Props {
    description: string
    onClick?: any
    readOnlyMode?: boolean
}

export default function SimpleDescriptionParagraph(props: Props) {
    const [isOpen, setIsOpen] = React.useState(false)

    const toggleReveal = () => {
        setIsOpen(!isOpen)
    }

    const { description, onClick, readOnlyMode } = props
    let isDescriptionShort: boolean =
        description.length > 1 && description.length < 800 ? true : false

    let expanderHeight =
        isDescriptionShort === true ? 'auto' : isOpen ? 'auto' : 130

    const { theme } = useThemes()

    return (
        <Container>
            {/* IF NO DESCRIPTION */}
            {(description.length < 1 || description === '<p><br></p>') &&
                // FOR USER'S OWN CAR (NO READ ONLY) WE DISPLAY ADD BTN
                (!readOnlyMode ? (
                    <GoToBoxDesktop onClick={onClick && onClick}>
                        {/* skeleton below */}
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 8,
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: 24,
                                    backgroundColor:
                                        colours[theme]
                                            .base_overlays_neutral_alpha_50,
                                }}
                            />
                            <div
                                style={{
                                    width: '100%',
                                    height: 24,
                                    backgroundColor:
                                        colours[theme]
                                            .base_overlays_neutral_alpha_50,
                                }}
                            />
                            <div
                                style={{
                                    width: '100%',
                                    height: 24,
                                    backgroundColor:
                                        colours[theme]
                                            .base_overlays_neutral_alpha_50,
                                }}
                            />
                            <div
                                style={{
                                    width: '80%',
                                    height: 24,
                                    backgroundColor:
                                        colours[theme]
                                            .base_overlays_neutral_alpha_50,
                                }}
                            />
                        </div>
                    </GoToBoxDesktop>
                ) : (
                    // FOR EXTERNAL CAR (READ ONLY MODE) WE DISPLAY NO DATA SQUARE
                    <NoDataRectangle text="no description" height="300px" />
                ))}
            {description.length > 1 && (
                <Expander height={expanderHeight}>
                    <Wrapper>{parse(description)}</Wrapper>
                </Expander>
            )}
            {description.length > 1 && (
                <Reveal
                    $theme={theme}
                    isOpen={expanderHeight === 130 ? false : true}
                />
            )}
            {expanderHeight === 'auto' && (
                <div
                    style={{
                        paddingTop: '16px',
                    }}
                />
            )}
            {description.length < 1 || isDescriptionShort ? null : (
                <ShowMoreBtn showLess={isOpen} onClick={toggleReveal} />
            )}
        </Container>
    )
}
