type Props = {
    size?: string
    color?: string
}

const NavHamburgerIconV2 = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 11.9922L9 11.9922V10.4922L23 10.4922V11.9922Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 16.75L9 16.75V15.25L23 15.25V16.75Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 21.4609L9 21.4609V19.9609L23 19.9609V21.4609Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default NavHamburgerIconV2
