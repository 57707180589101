/// <reference path="./types.d.ts" />;
// create store for todos (basically reducer for the main final store)

import { IFileStorageState } from 'myModels'

import { ActionType } from 'typesafe-actions'

import * as successActions from './actions/dispatched/successActions'
import * as loadingActions from './actions/dispatched/loadingActions'
import * as errorActions from './actions/dispatched/errorActions'

type SuccessAction = ActionType<typeof successActions>
type LoadingActions = ActionType<typeof loadingActions>
type ErrorActions = ActionType<typeof errorActions>

const initialState: IFileStorageState = {
    error: null,
    loading: false,
    loadingTos3: false,
}

export const filsStorageReducerState = (
    state: IFileStorageState = initialState,
    action: LoadingActions | SuccessAction | ErrorActions
): IFileStorageState => {
    switch (action.type) {
        case loadingActions.getPresignedUrlsCarGalleryImagesRequest.type:
            return Object.assign({}, state, {
                presignedurls: null,
                loading: true,
                error: null,
            })

        case successActions.getPresignedUrlsCarGalleryImagesSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                gallerypresignedurls: state.gallerypresignedurls
                    ? { ...state.gallerypresignedurls, ...action.payload }
                    : action.payload,
                loading: false,
                error: null,
            })

        case loadingActions.getPresignedUrlsEntryAttachmentsRequest.type:
            return Object.assign({}, state, {
                presignedurls: null,
                loading: true,
                error: null,
            })

        case successActions.resetPresignedUrlsCarGalleryImagesSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                gallerypresignedurls: undefined,
                loading: false,
                error: null,
            })

        case successActions.getPresignedUrlsEntryAttachmentsSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                presignedurls: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getPresignedUrlEntryAttachmentFileStorageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getPresignedUrlCostAttachmentRequest.type:
            return Object.assign({}, state, {
                presignedurls: null,
                loading: true,
                error: null,
            })

        case successActions.getPresignedUrlCostAttachmentSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                presignedurls: [action.payload],
                loading: false,
                error: null,
            })

        case errorActions.getPresignedUrlCostAttachmentFileStorageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case errorActions.getPresignedUrlsCarGalleryImagesFileStorageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getPresignedUrlCarCoverImageRequest.type:
            return Object.assign({}, state, {
                presignedurls: null,
                loading: true,
                error: null,
            })

        case successActions.getPresignedUrlCarCoverImageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                presignedurls: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getPresignedUrlCarCoverImageFileStorageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.submitCarGalleryImagesFilestorageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.submitCarGalleryImagesFilestorageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case successActions.submitCarGalleryImagesFilestorageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                loading: false,
                error: null,
            })

        case loadingActions.submitCarCoverImageFilestorageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.submitCarCoverImageFilestorageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case successActions.submitCarCoverImageFilestorageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                loading: false,
                error: null,
            })

        case loadingActions.getPresignedUrlUserProfileImageRequest.type:
            return Object.assign({}, state, {
                presignedurls: null,
                loading: true,
                error: null,
            })

        case successActions.getPresignedUrlUserProfileImageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                presignedurls: action.payload,
                loading: false,
                error: null,
            })

        case errorActions.getPresignedUrlUserProfileImageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.getPresignedUrlEntryGalleryImagesRequest.type:
            return Object.assign({}, state, {
                presignedurls: null,
                loading: true,
                error: null,
            })

        case successActions.getPresignedUrlsEntryGalleryImagesSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                gallerypresignedurls: action.payload,
                loading: false,
                error: null,
            })
        case errorActions.getPresignedUrlsEntryGalleryImagesFileStorageError
            .type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case loadingActions.submitEntryGalleryImagesFilestorageRequest.type:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                error: null,
            })

        case errorActions.submitEntryGalleryImagesFilestorageError.type:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                error: action.payload,
            })

        case successActions.submitEntryGalleryImagesFilestorageSuccess.type:
            return Object.assign({}, state, {
                ...state,
                // need to check in Thunks if not already in state
                loading: false,
                error: null,
            })

        case loadingActions.submitFilesToS3Request.type:
            return Object.assign({}, state, {
                ...state,
                loadingTos3: true,
                error: null,
            })

        case errorActions.submitFilesToS3Error.type:
            return Object.assign({}, state, {
                ...state,
                loadingTos3: false,
                error: action.payload,
            })

        case successActions.submitFilesToS3Success.type:
            return Object.assign({}, state, {
                ...state,
                loadingTos3: false,
                error: null,
            })

        default:
            return state
    }
}

export default filsStorageReducerState

export type FileStorageState = ReturnType<typeof filsStorageReducerState>
