import * as React from 'react'
import styled from 'styled-components'
import BigRedDeletionButton from '../../atoms/Button/bigRedDeletionButton'
import Faded from '../../templates/animated/faded'
import { device } from '../../templates/displays/devices'

type IStyle = {
    height?: string
    isShare?: boolean
    isCost?: boolean
}

const OverWrapper = styled.div<IStyle>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => (props.isShare ? '0px' : ' 2%')};
    right: 0px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    z-index: 4;
    padding-top: 90px;
    padding-right: 24px;

    /* @media ${device.desktop} {
        padding-right: ${(props) => (props.isShare ? '0px' : ' 20%')};
    }
    @media ${device.large_desktop} {
        padding-right: ${(props) => (props.isShare ? '0px' : ' 22%')};
    } */

    @media ${device.ipad} {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-right: 20px;
        padding-top: 130px;
    }

    @media ${device.mobile_and_ipad} {
        display: none;
    }
`
const BubbleTop = styled.div<IStyle>`
    position: relative;
    background: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 20px;
    height: ${(props) => (props.height ? props.height : '200px')};
    :after {
        content: '';
        position: absolute;
        top: 0;
        left: 80%;
        width: 0;
        height: 0;
        border: 14px solid transparent;
        border-bottom-color: var(--modal-side-sheet-pop-over-bg, #fff);
        border-top: 0;
        margin-left: -14px;
        margin-top: -14px;
    }
`

const BubbleBottom = styled.div<IStyle>`
    position: relative;
    background: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 20px;
    height: ${(props) => (props.height ? props.height : '200px')};
    :after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 28px solid transparent;
        border-top-color: var(--modal-side-sheet-pop-over-bg, #fff);
        border-bottom: 0;
        margin-left: 0px;
        margin-bottom: -14px;
    }
`

const Wrapper = styled.div`
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    padding: 30px;
    padding-top: 20px;
    border-radius: 20px;
`
const Title = styled.div`
    font-family: Lato;
    font-size: 20px;
    color: var(--text-strong, #1a1a1a);
`

const Details = styled.div`
    font-family: Lato;
    font-size: 15px;
    color: var(--text-darker, #616161);
`

const Padding = styled.div`
    padding-top: 10px;
`

const SubWrapper = styled.div`
    bottom: 150px;
    right: 200px;
    position: absolute;
`

interface Props {
    action: any
    actionCopy: string
    titleCopy: string
    detailsCopy: string
    toggle: any
    isOpen: boolean
    height?: string
    isEditForm?: boolean
    isShare?: boolean
}

const DeletionConfirmationDesktop: React.SFC<Props> = (props) => {
    const {
        action,
        actionCopy,
        titleCopy,
        detailsCopy,
        toggle,
        isOpen,
        isEditForm,
        isShare,
    } = props

    return isOpen ? (
        <Faded>
            <OverWrapper onClick={toggle} isShare={isShare}>
                {isEditForm ? (
                    <div
                        style={{
                            position: 'relative',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <SubWrapper>
                            <BubbleBottom>
                                <Wrapper data-attr="confirm-deletion-sheet">
                                    <Title>{titleCopy}</Title>
                                    <Padding />
                                    <Details>{detailsCopy}</Details>
                                    <Padding />
                                    <Padding />
                                    <Padding />
                                    {/* <ButtonContainer> */}
                                    <BigRedDeletionButton
                                        text={actionCopy}
                                        onClick={() => {
                                            setTimeout(() => {
                                                action()
                                            }, 50)
                                            toggle()
                                        }}
                                    />
                                    {/* </ButtonContainer> */}
                                </Wrapper>
                            </BubbleBottom>
                        </SubWrapper>
                    </div>
                ) : (
                    <BubbleTop>
                        <Wrapper data-attr="confirm-deletion-sheet">
                            <Title>{titleCopy}</Title>
                            <Padding />
                            <Details>{detailsCopy}</Details>
                            <Padding />
                            <Padding />
                            <Padding />
                            {/* <ButtonContainer> */}
                            <BigRedDeletionButton
                                text={actionCopy}
                                onClick={() => {
                                    setTimeout(() => {
                                        action()
                                    }, 50)
                                    toggle()
                                }}
                            />
                            {/* </ButtonContainer> */}
                        </Wrapper>
                    </BubbleTop>
                )}
            </OverWrapper>
        </Faded>
    ) : (
        <div />
    )
}

export default DeletionConfirmationDesktop
