import { IHighlightedFact } from 'entityModels'
import { IHighlightedFactPayload } from 'IapiDataPayload'

export const HFfromAPIconversion = (
    hf: IHighlightedFactPayload
): IHighlightedFact => {
    let hf_redux: IHighlightedFact = {
        id: hf.uid,
        text: hf.body,
        order: hf.order,
    }

    return hf_redux
}

export const HFtoAPI = (hf: IHighlightedFact): IHighlightedFactPayload => {
    let hf_api: IHighlightedFactPayload = {
        uid: hf.id,
        body: hf.text,
        order: hf.order,
    }

    return hf_api
}

export const HFstoAPIconversion = (
    hf: IHighlightedFact[]
): IHighlightedFactPayload[] => {
    let hf_apis: IHighlightedFactPayload[] = hf.map(
        (item: IHighlightedFact, index: number) => {
            let hf_api: IHighlightedFactPayload = {
                uid: item.id,
                body: item.text,
                order: item.order,
            }

            return hf_api
        }
    )

    return hf_apis
}
