import { insurance_colours } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

function InsuranceMainDriverIcon(props?: any) {
    const { theme } = useThemes()
    return (
        <svg
            width={props?.width ?? 40}
            height={props?.width ?? 40}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect
                width={40}
                height={40}
                rx={4}
                fill={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_08
                }
            />
            <path
                d="M20.234 27.56V34M20.24 20.799h-.003m.004 0h.004m-.004 0c1.015 0 1.965-.135 2.828-.367l-.622 4.664c-.394.162-1.19.4-2.195.4h-.027c-1.005 0-1.801-.238-2.196-.4l-.622-4.665a10.93 10.93 0 002.834.368zm0-6.021h-.004m.004 0h.004m-.004 0c-1.489 0-2.687-.157-3.637-.376l-.929-6.964A7.868 7.868 0 0120.224 6h.03c1.695 0 3.263.532 4.549 1.438l-.93 6.965c-.95.219-2.146.375-3.632.375z"
                stroke={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_60
                }
                strokeWidth={1.5}
            />
            <path
                d="M8.22 31.365c0-3.494.921-4.574 2.572-5.118l5.374-1.55v.325c0 1.4 1.767 2.536 3.948 2.536 2.18 0 3.948-1.136 3.948-2.537v-.324l5.365 1.55C31.077 26.79 32 27.87 32 31.365"
                stroke={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_100
                }
                strokeWidth={1.5}
            />
            <path
                d="M20.224 6c-4.378 0-7.912 3.549-7.912 7.927-.003 1.146.14 4.017.736 6.33.595 2.314 3.57 4.19 4.981 4.839.395.162 1.192.4 2.196.4h.027c1.005 0 1.8-.238 2.195-.4 1.413-.65 4.387-2.525 4.982-4.839.596-2.313.739-5.184.736-6.33 0-4.378-3.532-7.927-7.91-7.927h-.031z"
                stroke={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_100
                }
                strokeWidth={1.5}
            />
            <path
                d="M24.138 24.15v.873c0 1.401-1.746 2.537-3.9 2.537s-3.9-1.136-3.9-2.537v-.874"
                stroke={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_100
                }
                strokeWidth={1.5}
            />
            <path
                d="M8.22 31.473v1.238s4.128 1.239 11.89 1.239C27.87 33.95 32 32.71 32 32.71v-1.238"
                stroke={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_100
                }
                strokeWidth={1.5}
                strokeMiterlimit={10}
                strokeLinecap="square"
            />
            <path
                d="M13.345 18.527c-.831-.571-1.018-2.792-1.007-3.83v-1.494l2.046.422 2.207.714 3.474.52 5.064-.845 1.526-1.103h1.526v4.025l-1.753 2.143-3.993 1.656-5.227-.162c-.941-.444-3.032-1.474-3.863-2.046z"
                fill={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_24
                }
            />
            <path
                d="M20.237 20.799c4.265 0 7.368-2.376 7.799-4.298.093-1.073.13-1.884.129-2.574-.071-.46-.52-1.201-1.749-.49-.878.52-2.765 1.34-6.179 1.34m.008 6.022c-4.265 0-7.368-2.376-7.8-4.298a28.868 28.868 0 01-.128-2.574c.071-.46.52-1.201 1.748-.49.879.52 2.766 1.34 6.18 1.34"
                stroke={
                    insurance_colours[theme].sections['main_policy_holder']
                        .primary_100
                }
                strokeWidth={1.5}
            />
        </svg>
    )
}

export default InsuranceMainDriverIcon
