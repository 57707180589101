import * as React from 'react'
import styled from 'styled-components'
import backgroundImage from '../../../public/assets/landingPage/fiat500_resize.jpg'
import landrover_front from '../../../public/assets/landingPage/landrover_old.jpg'
import landrover_back from '../../../public/assets/landingPage/landrover_new.jpg'
import porsche_new from '../../../public/assets/landingPage/porsche_new.jpg'
import bmw from '../../../public/assets/landingPage/bmw.jpg'
import porsche_old_reversed from '../../../public/assets/landingPage/porsche_old_flipped.jpg'
import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'
import { ITheme } from '../../../providers/theme/colours'

type IStyle = { $theme: ITheme }

const FixedGradient = styled.div<IStyle>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) =>
        props.$theme === 'dark'
            ? `linear-gradient(to bottom, rgba(18, 18, 18, 0.349), rgba(18,18,18,0.85))`
            : undefined};
`

const DefaultWrapper = styled.section`
    width: 100%;
    display: flex;
    /* align-items: center; */
    align-items: flex-start;

    min-height: 100vh;
    position: relative;
    /* height: 100vh; */

    @media (max-width: 1600px) {
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        position: fixed;
        justify-content: flex-end;
        background-image: url(${backgroundImage});
        background-repeat: no-repeat;
        background-size: 130%;
        background-position: center -370px;
        padding-right: 150px;
        /* height: 100vh; */
        display: flex;
    }

    @media (min-width: 2200px) {
        position: fixed;
        padding-right: 300px;
        background-position: center -650px;
        /* height: 100vh; */
    }
`

const LandroverFrontWrapper = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
    /* height: 100vh; */
    @media (max-width: 1600px) {
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        position: fixed;
        justify-content: flex-end;
        background-image: url(${landrover_front});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0px -800px;
        padding-right: 150px;
        /* height: 100vh; */
        display: flex;
        align-items: center;
    }

    @media (min-width: 1920px) {
        position: fixed;
        padding-right: 200px;
        background-position: 0px -1000px;
        /* height: 100vh; */
    }

    @media (min-width: 2200px) {
        position: fixed;
        background-size: 110%;
        padding-right: 300px;
        background-position: 0 -1600px;
        /* height: 100vh; */
    }

    @media (min-width: 3400px) {
        position: fixed;
        background-size: 110%;
        padding-right: 300px;
        background-position: 0 -2100px;
        /* height: 100vh; */
    }
`

const LandroverBackWrapper = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
    /* height: 100vh; */

    @media (max-width: 1600px) {
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        position: fixed;
        justify-content: flex-end;
        background-image: url(${landrover_back});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0px -900px;
        padding-right: 150px;
        /* height: 100vh; */
        display: flex;
        align-items: center;
    }

    @media (min-width: 1920px) {
        position: fixed;
        padding-right: 200px;
        background-position: 0px -1000px;
        /* height: 100vh; */
    }

    @media (min-width: 2200px) {
        position: fixed;
        background-size: 100%;
        padding-right: 300px;
        background-position: 0 -1400px;
        /* height: 100vh; */
    }
`

const PorscheNew = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    min-height: 100vh;

    /* height: 100vh; */
    @media (max-width: 1600px) {
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        position: fixed;
        justify-content: flex-end;
        background-image: url(${porsche_new});
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0px -1200px;
        padding-right: 150px;
        /* height: 100vh; */
        display: flex;
        align-items: center;
    }

    @media (min-width: 1920px) {
        position: fixed;
        padding-right: 200px;
        background-size: 110%;
        background-position: -100px -1600px;
        /* height: 100vh; */
    }

    @media (min-width: 2200px) {
        position: fixed;
        background-size: 100%;
        padding-right: 300px;
        background-position: 0 -2000px;
        /* height: 100vh; */
    }
    @media (min-width: 3400px) {
        position: fixed;
        background-size: 110%;
        padding-right: 300px;
        background-position: 0 -3300px;
        /* height: 100vh; */
    }
`

const PorscheOld = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
    /* height: 100vh; */

    @media (max-width: 1600px) {
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        position: fixed;
        justify-content: flex-end;
        background-image: url(${porsche_old_reversed});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0px 0px;
        padding-right: 150px;
        /* height: 100vh; */
        display: flex;
        align-items: center;
    }

    @media (min-width: 1920px) {
        position: fixed;
        padding-right: 200px;
        background-position: center;
        /* height: 100vh; */
    }

    @media (min-width: 2200px) {
        position: fixed;
        background-size: 110%;
        padding-right: 300px;
        background-position: -100 0px;
        /* height: 100vh; */
    }
`

const Bmw = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
    /* height: 100vh; */

    @media (max-width: 1600px) {
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        position: fixed;
        justify-content: flex-end;
        background-image: url(${bmw});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0px 0px;
        padding-right: 150px;
        /* height: 100vh; */
        display: flex;
        align-items: center;
    }

    @media (min-width: 1920px) {
        position: fixed;
        padding-right: 200px;
        background-position: center;
        /* height: 100vh; */
    }

    @media (min-width: 2200px) {
        position: fixed;
        background-size: 110%;
        padding-right: 300px;
        background-position: -100 0px;
        /* height: 100vh; */
    }
`

const ShareInvite = styled.section`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 100vh;
    /* height: 100vh; */

    @media (max-width: 1600px) {
        justify-content: flex-end;
    }

    @media ${device.mobile} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.ipad} {
        flex-direction: column;
        justify-content: flex-start;
        min-height: 100vh;
        max-height: none;
    }

    @media ${device.large_desktop} {
        position: fixed;
        justify-content: flex-end;
        background-image: url(${bmw});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0px 0px;
        padding-right: 150px;
        /* height: 100vh; */
        display: flex;
        align-items: center;
    }

    @media (min-width: 1920px) {
        position: fixed;
        padding-right: 200px;
        background-position: center;
        /* height: 100vh; */
    }

    @media (min-width: 2200px) {
        position: fixed;
        background-size: 110%;
        padding-right: 300px;
        background-position: -100 0px;
        /* height: 100vh; */
    }
`

type Props = {
    customImage?:
        | 'landrover_front'
        | 'landrover_back'
        | 'porsche_new'
        | 'porsche_old'
        | 'bmw'
        | 'share-invite'
    children: React.ReactNode
}

export default function RegistrationWrapperImage(props: Props) {
    const { theme } = useThemes()
    switch (props.customImage) {
        case 'landrover_front':
            return (
                <LandroverFrontWrapper>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </LandroverFrontWrapper>
            )
        case 'landrover_back':
            return (
                <LandroverBackWrapper>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </LandroverBackWrapper>
            )
        case 'porsche_new':
            return (
                <PorscheNew>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </PorscheNew>
            )
        case 'porsche_old':
            return (
                <PorscheOld>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </PorscheOld>
            )
        case 'bmw':
            return (
                <Bmw>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </Bmw>
            )
        case 'share-invite':
            return (
                <ShareInvite>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </ShareInvite>
            )
        default:
            return (
                <DefaultWrapper>
                    <FixedGradient $theme={theme} />
                    {props.children}
                </DefaultWrapper>
            )
    }
}
