import {
    GET_CAR_DATA_ERROR,
    WRITE_CAR_DATA_GENERAL_ERROR,
    GET_GARAGE_CARS_DATA_ERROR,
    SET_CURRENT_CAR_ERROR,
    CREATE_CAR_ERROR,
    UPDATE_CAR_BANNER_URL_ERROR,
    GET_CAR_DATA_BY_ID_ERROR,
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_ERROR,
    SET_CAR_TASK_IDS_ERROR,
    DELETE_CAR_ERROR,
    GET_PUBLIC_SHARES_CAR_ERROR,
    CREATE_CAR_PUBLIC_SHARE_ERROR,
    DELETE_CAR_PUBLIC_SHARE_ERROR,
    GET_EXTERNAL_CARS_SHARED_WITH_YOU_ERROR,
    SET_CURRENT_EXTERNAL_CAR_ERROR,
    GET_OWNED_DIRECT_SHARES_CAR_ERROR,
    GET_ALL_CAR_SHARES_ERROR,
    CREATE_CAR_DIRECT_SHARE_ERROR,
    GET_SINGLE_CAR_DIRECT_SHARE_ERROR,
    ADD_SHARE_RECIPIENT_CAR_ERROR,
    DELETE_SHARE_RECIPIENT_CAR_ERROR,
    DELETE_CAR_DIRECT_SHARE_ERROR,
    UPDATE_CAR_DIRECT_SHARE_ERROR,
    CREATE_CAR_SHARE_INVITE_ERROR,
    DELETE_CAR_SHARE_INVITE_ERROR,
    RESEND_CAR_SHARE_INVITE_ERROR,
    ACCEPT_CAR_SHARE_INVITE_ERROR,
    ARCHIVE_CAR_ERROR,
    CREATE_HANDOVER_ERROR,
    DELETE_HANDOVER_ERROR,
    GET_LAST_CAR_INSURANCE_ENTRY_ERROR,
    GET_CAR_ACTION_CHECK_ERROR,
    GET_CAR_INSIGHTS_ERROR,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

// export const getCarDataError = createAction(
//     GET_CAR_DATA_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const getCarDataError = createAction(
    GET_CAR_DATA_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getCarDataByIdError = createAction(
//     GET_CAR_DATA_BY_ID_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const getCarDataByIdError = createAction(
    GET_CAR_DATA_BY_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const writeCarDataGeneralError = createAction(
//     WRITE_CAR_DATA_GENERAL_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const writeCarDataGeneralError = createAction(
    WRITE_CAR_DATA_GENERAL_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getGarageCarsDataError = createAction(
//     GET_GARAGE_CARS_DATA_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const getGarageCarsDataError = createAction(
    GET_GARAGE_CARS_DATA_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const setCurrentCarError = createAction(
//     SET_CURRENT_CAR_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const setCurrentCarError = createAction(
    SET_CURRENT_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const createCarError = createAction(
//     CREATE_CAR_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const createCarError = createAction(
    CREATE_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const updateCarBannerUrlError = createAction(
//     UPDATE_CAR_BANNER_URL_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const updateCarBannerUrlError = createAction(
    UPDATE_CAR_BANNER_URL_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getExternalCarDataByRegistrationNumberError = createAction(
//     GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const getExternalCarDataByRegistrationNumberError = createAction(
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const setCarTaskIdsError = createAction(
//     SET_CAR_TASK_IDS_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const setCarTaskIdsError = createAction(
    SET_CAR_TASK_IDS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const deleteCarError = createAction(
//     DELETE_CAR_ERROR,
//     (resolve) => (payload: string) => resolve(error)
// )

export const deleteCarError = createAction(
    DELETE_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getPublicSharesCarError = createAction(
    GET_PUBLIC_SHARES_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getOwnedDirectSharesCarError = createAction(
    GET_OWNED_DIRECT_SHARES_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getAllCarSharesError = createAction(
    GET_ALL_CAR_SHARES_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const createCarPublicShareError = createAction(
    CREATE_CAR_PUBLIC_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteCarPublicShareError = createAction(
    DELETE_CAR_PUBLIC_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getExternalCarsSharedWithYouERROR = createAction(
    GET_EXTERNAL_CARS_SHARED_WITH_YOU_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const setCurrentExternalCarError = createAction(
    SET_CURRENT_EXTERNAL_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const createCarDirectShareError = createAction(
    CREATE_CAR_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const updateCarDirectShareError = createAction(
    UPDATE_CAR_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getSingleCarDirectShareError = createAction(
    GET_SINGLE_CAR_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addCarShareRecipientError = createAction(
    ADD_SHARE_RECIPIENT_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteCarShareRecipientError = createAction(
    DELETE_SHARE_RECIPIENT_CAR_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteDirectCarShareError = createAction(
    DELETE_CAR_DIRECT_SHARE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const createCarShareInviteError = createAction(
    CREATE_CAR_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteCarShareInviteError = createAction(
    DELETE_CAR_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const resendCarShareInviteError = createAction(
    RESEND_CAR_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const acceptCarShareInviteError = createAction(
    ACCEPT_CAR_SHARE_INVITE_ERROR,
    function prepare(payload: IReduxError | undefined) {
        return {
            payload,
        }
    }
)

export const archiveCarError = createAction(
    ARCHIVE_CAR_ERROR,
    function prepare(payload: IReduxError | undefined) {
        return {
            payload,
        }
    }
)

export const createHandoverError = createAction(
    CREATE_HANDOVER_ERROR,
    function prepare(payload: IReduxError | undefined) {
        return {
            payload,
        }
    }
)

export const deleteHandoverError = createAction(
    DELETE_HANDOVER_ERROR,
    function prepare(payload: IReduxError | undefined) {
        return {
            payload,
        }
    }
)

// GET_LAST_CAR_INSURANCE_ENTRY_ERROR

export const getLastCarInsuranceEntryError = createAction(
    GET_LAST_CAR_INSURANCE_ENTRY_ERROR,
    function prepare(payload: IReduxError | undefined) {
        return {
            payload,
        }
    }
)

export const getCarActionCheckError = createAction(
    GET_CAR_ACTION_CHECK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getCarInsightsError = createAction(
    GET_CAR_INSIGHTS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
