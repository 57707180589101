import { apiSentryErrorHandler } from '../errorHandler'
import { customHeader } from '../headers'
import * as urls from '../urls'

export const get_dropdowns_by_names_insurance_api = (
    names: string[]
): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    let queryParams = ''

    names.forEach((name, index) => {
        if (index === 0) {
            queryParams = queryParams + `?name=${name}`
        } else {
            queryParams = queryParams + `&name=${name}`
        }
    })

    return fetch(
        urls.list_insurance_dropdowns_endpoint(queryParams),
        requestOptions
    ).then((response: any) => {
        return response
            .json()
            .then((data: any) => {
                if (response.ok !== true) {
                    apiSentryErrorHandler(data, 'Get insurance dropdowns error')
                    return Promise.reject(response)
                }

                if (data) {
                    return data
                }
            })
            .catch((e: any) => {
                return Promise.reject(e)
            })
    })
}
