import { ITaskNormalisedObject } from 'entityModels'
import styled from 'styled-components'
import TaskCardDesktop from '../../../atoms/taskCard/taskCardDesktop'
import task_skeleton_desktop from '../../../atoms/skeleton/tasks/task_skeleton_desktop.png'
import task_skeleton_desktop_dark from '../../../atoms/skeleton/tasks/task_skeleton_desktop_dark.png'
import { device } from '../../../templates/displays/devices'
import { AddIcon } from '../../../atoms/statefulicons'
import useThemes from '../../../../providers/theme/hooks'

const Wrapper = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: grid;
        column-gap: 24px;
        row-gap: 24px;
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    @media ${device.wide_desktop} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

const GoToBox = styled.div`
    :hover {
        border: 1px solid var(--primary, #5ec3ca);
        background: var(--primary_16, rgba(94, 195, 202, 0.16));
        transform: scale(1.015);
    }

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 300ms;
    color: var(--primary, #5ec3ca);
    background-color: var(--primary_20);
`

const Wrap = styled.div`
    width: 100%;
    display: grid;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    @media ${device.wide_desktop} {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

interface Props {
    all_tasks: ITaskNormalisedObject
    carsData: any
    updateTask: any
    tasks_ids_to_render: string[]
    toggleCreateTaskForm: any
    garageCars?: any
}

export default function TaskCardsListDesktop(props: Props) {
    const checkCarExists = (linkedToCar?: string[], garageCars?: any) => {
        if (linkedToCar && garageCars) {
            if (linkedToCar.length === 1) {
                if (!garageCars.includes(linkedToCar[0])) {
                    return false
                } else return true
            }
        } else return true
    }

    let {
        all_tasks,
        carsData,
        updateTask,
        tasks_ids_to_render,
        toggleCreateTaskForm,
        garageCars,
    } = props

    let clean_tasks_ids_to_render: string[] = tasks_ids_to_render?.filter(
        (id) => id !== undefined
    )

    const { theme } = useThemes()

    let skeletons = () => (
        <Wrap>
            <GoToBox
                onClick={() => {
                    toggleCreateTaskForm()
                }}
            >
                <AddIcon colour="#5ec3ca" onClick={() => {}} />
            </GoToBox>
            {[1, 2, 3, 4, 5].map((item) => (
                <img
                    src={
                        theme === 'light'
                            ? task_skeleton_desktop
                            : task_skeleton_desktop_dark
                    }
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                />
            ))}
        </Wrap>
    )

    return clean_tasks_ids_to_render[0] ? (
        <Wrapper>
            {clean_tasks_ids_to_render[0] &&
                clean_tasks_ids_to_render.map(
                    (taskID: string, index: number) => {
                        let task = all_tasks[taskID]
                        if (task !== undefined && garageCars !== undefined) {
                            let doesCarExist = checkCarExists(
                                task.linkedToCar,
                                garageCars
                            )

                            if (doesCarExist) {
                                return (
                                    <TaskCardDesktop
                                        key={index}
                                        task={all_tasks[taskID]}
                                        carsData={carsData}
                                        updateTask={updateTask}
                                    />
                                )
                            } else return <div style={{ display: 'none' }} />
                        } else if (task !== undefined) {
                            return (
                                <TaskCardDesktop
                                    key={index}
                                    task={all_tasks[taskID]}
                                    carsData={carsData}
                                    updateTask={updateTask}
                                />
                            )
                        } else return <div style={{ display: 'none' }} />
                    }
                )}
        </Wrapper>
    ) : (
        skeletons()
    )
}
