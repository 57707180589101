import * as React from 'react'
import styled from 'styled-components'

import { device } from '../../templates/displays/devices'
import Icon from '../../atoms/icons/index'
import { randomCarSvgPlaceholderFunc } from '../../../helpers/random/randomPlaceholder'
import SkeletonAbsolute from '../skeleton/skeletonAbsolute'
import SimpleTag from '../tags/simpleTag'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'
import { ITheme } from '../../../providers/theme/colours'

const ImageCell = styled.div<StyleProps>`
    background-image: ${(props) => `url(${props.url})`};
    background-color: none;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    opacity: ${(props) => (props.isthingloading === false ? 1 : 0)};
    transition: all 400ms;
    padding: 20px 0;
    height: 100%;
    width: 100%;
`

const Wrapper = styled.div`
    position: relative;

    text-align: center;

    font-size: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media ${device.desktop} {
        height: 350px;
    }

    @media ${device.large_desktop} {
        height: 28vh;
        min-height: 380px;
    }
    @media ${device.smallest_laptops} {
        height: 22vh;
        min-height: 325px;
    }
`

const Text = styled.div`
    color: white;
    position: absolute;

    @media ${device.desktop} {
        top: 80px;
        left: 50px;
    }
    @media ${device.large_desktop} {
        top: 80px;
        left: 50px;
    }

    @media ${device.smallest_laptops} {
        top: 80px;
        left: 50px;
    }

    text-align: left;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
    padding-bottom: 5px;
    @media ${device.desktop} {
        width: 500px;
    }
    @media ${device.large_desktop} {
        width: 500px;
    }

    @media ${device.smallest_laptops} {
        width: 100%;
    }
`

const Title = styled.div`
    font-family: Lato;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-right: 14px;

    @media ${device.desktop} {
        transform: translateY(-8px);
        font-size: 19px;
    }

    @media ${device.smallest_laptops} {
        font-size: 14px;
        transform: translateY(-4px);
    }
`

const HoverBox = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    opacity: 0;
    width: 100%;

    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* Rules below not implemented in browsers yet */
    -o-user-select: none;
    user-select: none;

    background: linear-gradient(
        100deg,
        rgba(0, 0, 0, 0.91) 42.27%,
        rgba(0, 0, 0, 0.56) 95%,
        rgba(0, 0, 0, 0.47) 98.84%
    );
    transition: 0.2s;

    :hover {
        opacity: 1;
    }
`

type State = {
    src: any
    placeholder: any
    isLoading: boolean
    theme: ITheme
}

type Obj = {
    [key: string]: any
}
interface Props {
    object: Obj
    tag?: string
}

interface StyleProps {
    url?: string
    isthingloading: boolean
}

class DuoGridItemDesktop extends React.Component<Props, State> {
    static contextType = ThemesContext

    state = {
        src: null,
        placeholder: null,
        isLoading: true,
        theme: this.context?.theme,
    }

    _isMounted = false

    componentDidMount() {
        this._isMounted = true
        const src = this.props.object.url

        if (src !== undefined) {
            this._isMounted &&
                this.setState({ ...this.state, src: src, placeholder: null })
            this.onLoad(src)
            return
        } else {
            let i: string = randomCarSvgPlaceholderFunc(
                undefined,
                this.state.theme
            )
            this._isMounted &&
                this.setState({
                    ...this.state,
                    src: undefined,
                    placeholder: i,
                    isLoading: false,
                })
            return
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.object.url !== prevProps.object.url) {
            const src = this.props.object.url

            if (src !== undefined) {
                this.setState({
                    ...this.state,
                    src: src,
                    placeholder: null,
                    isLoading: true,
                })
                this.onLoad(src)
            } else {
                let i: string = randomCarSvgPlaceholderFunc(
                    undefined,
                    this.state.theme
                )
                return this.setState({
                    ...this.state,
                    src: undefined,
                    placeholder: i,
                    isLoading: false,
                })
            }
        }

        if (this.context !== prevState.theme) {
            this.setState({ theme: this.context })
            const src = this.props.object.url
            if (src === undefined) {
                let i: string = randomCarSvgPlaceholderFunc(
                    undefined,
                    this.context?.theme
                )
                return this.setState({
                    placeholder: i,
                })
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                ...this.state,
                isLoading: false,
            })
    }

    render() {
        const { object, tag } = this.props

        const { isLoading } = this.state

        return (
            <Wrapper>
                <SkeletonAbsolute isthingloading={isLoading} />

                <ImageCell
                    role="img"
                    aria-label="car overview banner"
                    url={
                        this.state.src
                            ? this.state.src
                            : this.state.placeholder
                            ? this.state.placeholder
                            : ''
                    }
                    isthingloading={this.state.src ? isLoading : false}
                >
                    {tag && (
                        <div
                            style={{
                                position: 'absolute',
                                right: 16,
                                bottom: 16,
                            }}
                        >
                            <SimpleTag text={tag} />
                        </div>
                    )}
                    <HoverBox>
                        <Text>
                            <Row>
                                <Title>{object.title}</Title>
                            </Row>
                            <Row>
                                <Icon icon="arrow_white" width="42px" />
                            </Row>
                        </Text>
                    </HoverBox>
                </ImageCell>
            </Wrapper>
        )
    }
}

export default DuoGridItemDesktop
