import styled from 'styled-components'
import ButtonAtom from '../../../../atoms/Button/ButtonAtom'
import { IEditShowroomModalOptions } from '../../../../pages/showroom/car/showroomCarProfileEdit'
import { device } from '../../../../templates/displays/devices'
import posthog from 'posthog-js'

const Title = styled.div`
    font-family: Lato-bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: var(--text-strong, #1a1a1a);

    @media ${device.mobile_and_ipad} {
        font-size: 24px;
        line-height: 32px;
    }
`

const Subtitle = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 5e-5em;
    color: var(--text-default, #666666);
    max-width: 400px;
`

type Props = {
    cancel: () => any
    carTitle?: string
    handleSetModalOpened: (p: IEditShowroomModalOptions) => any
}

const CarUnpublishActionModalDesktop = (props: Props) => {
    return (
        <>
            <Title>Unpublish car from the Showroom</Title>
            <div style={{ paddingTop: '20px' }} />
            <Subtitle>
                <span style={{ fontWeight: 600 }}>{props.carTitle}</span> will
                be unpublished from the Showroom. This car will be moved to
                drafts so you will be able to edit and publish it any time you
                want from your garage.
            </Subtitle>

            <div style={{ paddingTop: '50px' }} />

            <ButtonAtom
                theme="lowercase-red-background"
                width="100%"
                height="48px"
                textTransform="capitalize"
                dataCyId={'showroom-car-enquire'}
                onClick={() => {
                    // redux dispatch from here later
                    props.handleSetModalOpened('unpublished_confirmed')
                    posthog.capture('confirm unpublishing showroom car click')
                }}
                fontSize={16}
            >
                <div style={{ textTransform: 'capitalize', cursor: 'pointer' }}>
                    Unpublish
                </div>
            </ButtonAtom>
            <div style={{ paddingTop: '20px' }} />

            <ButtonAtom
                theme="naked-text"
                height="48px"
                dataCyId={'showroom-car-share'}
                icon={''}
                fontSize={16}
                onClick={() => {
                    props.cancel()
                }}
            >
                Close
            </ButtonAtom>
        </>
    )
}

export default CarUnpublishActionModalDesktop
