import { ITimeRangeEnum } from '../../components/atoms/menu/carInsights/timeMenu'

export function generateCarInsightsTimeRangeBEFormat(
    rangeType: ITimeRangeEnum
): string[] | undefined {
    const today = new Date()
    const currentYear = today.getFullYear()

    switch (rangeType) {
        case 'year':
            return [
                new Date(currentYear, 0, 1).toISOString(),
                today.toISOString(),
            ]

        case 'last_year':
            let year_start = new Date(currentYear - 1, 0, 1) // January 1st of last year
            let year_end = new Date(currentYear - 1, 11, 31) // December 31st of last year
            return [year_start.toISOString(), year_end.toISOString()]

        case 'all_time':
            let start_date = new Date(1850, 0, 1) // January 1st, 1850
            return [start_date.toISOString(), today.toISOString()]
    }

    return undefined
}
