import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { subMenuList, matchPageSubGroup } from './mainMenuData'
import Faded from '../../../templates/animated/faded'
import {
    Link,
    RouteComponentProps,
    useHistory,
    useLocation,
    withRouter,
} from 'react-router-dom'
import account_default from '../../../../public/assets/navigation/account_default_v2.svg'
import account_default_dark from '../../../../public/assets/navigation/account_default_v2_dark.svg'
import { Tooltip } from 'antd'
import { checkIfPwaStandalone } from '../../../templates/pwaCustom/checkIpadPwa'
import ButtonAtom from '../../Button/ButtonAtom'
import { useOnClickOutside } from '../../../templates/clickOutside'
import { renderAccountIcons } from '../../icons/components/account'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

import { IUser } from 'myModels'
import { logoutRequest } from '../../../../redux/user/actions/loadingActions'
import ChevronDown from '../../icons/components/chevronDown'
import useThemes from '../../../../providers/theme/hooks'
import colours, { ITheme } from '../../../../providers/theme/colours'
import ThemeSwitcherDesktop from '../../../organisms/themeSwitcher/themeSwitcherDesktop'

const MenuWrapper = styled.div<IStyle>`
    position: fixed;
    top: 0;
    right: 0;
    height: 64px;
    background-color: ${(props) => colours[props.$theme].background_default};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid ${(props) => colours[props.$theme].border_muted};
    transition: all 100ms;
    z-index: 11;

    @media ${device.desktop} {
        left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }
    @media ${device.large_desktop} {
        left: ${(props) => (props.isCollapsed ? '80px' : '280px')};
    }

    @media ${device.smallest_laptops} {
        left: ${(props) => (props.isCollapsed ? '40px' : '190px')};
    }
`

const FlexColumn = styled.div<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: column;
`
const FlexRow = styled.div<{ $spaceBetween?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
        props.$spaceBetween ? 'space-between' : 'flex-start'};
    transition: 100ms;
`

const FlexRowBtn = styled.div<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${(props) =>
        props.$spaceBetween ? 'space-between' : 'flex-start'};

    :hover {
        color: ${(props) => colours[props.$theme].primary} !important;
        transition: 100ms;
    }
`

const Chevronwrapper = styled.div<IStyle>`
    transform: rotate(90deg);
    padding-top: 30px;
    padding-right: 2px;
    cursor: pointer;
`

const CarTitle = styled.div<IStyle>`
    text-transform: capitalize;
    color: ${(props) => colours[props.$theme].text_muted};
    font-family: Lato;
    padding-bottom: 2px;
`

const LeftSide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    min-width: 400px;

    @media ${device.smallest_laptops} {
        padding-left: 2vw;
    }
    @media ${device.ipad} {
        padding-left: 2vw;
    }
`

const RightSide = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-right: 1.5vw;
`

const LeftItemText = styled.div<IStyle>`
    /* font-family: Lato-Semibold; */
    /* text-transform: uppercase; */
    text-transform: capitalize;
    font-family: Lato;
    color: ${(props) =>
        props.ispageactive
            ? colours[props.$theme].primary
            : colours[props.$theme].text_darker};
    font-size: 16px;
    transition: all 300ms;
    padding-left: ${(props) => (props.index === 0 ? '38px' : '1vw')};
    padding-right: 1vw;
    /* font-weight: 600; */
    align-items: center;
    /* letter-spacing: 0.08em; */
    letter-spacing: 0.001px;
    white-space: nowrap;
    text-align: center;

    @media ${device.smallest_laptops} {
        font-size: 15px;
        padding-left: 1vw;
        padding-right: 1vw;
    }
    @media ${device.ipad} {
        font-size: 15px;
        padding-left: ${(props) => (props.index === 0 ? '30px' : '1vw')};
        padding-right: 1vw;
    }

    :hover {
        color: ${(props) => colours[props.$theme].primary};
    }
`

// const LeftItemTextExternal = styled.div<IStyle>`
//     font-family: Lato;
//     text-transform: uppercase;
//     color: ${(props) => (props.ispageactive ? '#5ec3ca' : '#666666')};
//     font-size: 16px;
//     transition: all 300ms;
//     padding-left: ${(props) => (props.index === 0 ? '38px' : '1vw')};
//     padding-right: 0.8vw;
//     white-space: nowrap;

//     @media ${device.smallest_laptops} {
//         font-size: 15px;
//         padding-left: 0.8vw;
//         padding-right: 0.8vw;
//     }
//     @media ${device.ipad} {
//         font-size: 14px;
//         padding-left: ${(props) => (props.index === 0 ? '30px' : '1vw')};
//         padding-right: 0.8vw;
//     }

//     :hover {
//         color: #5ec3ca;
//     }
// `

const RightItem = styled.div<IStyle>`
    /* font-family: Lato-Light;
    color: black; */
    text-transform: uppercase;
    font-size: 14px;
    margin-right: 16px;
    transition: all 300ms;
    letter-spacing: 1.12px;

    font-family: Lato;
    color: ${(props) => colours[props.$theme].text_darker};
    white-space: nowrap;

    :hover {
        color: ${(props) => colours[props.$theme].primary};
    }

    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    @media ${device.ipad} {
        display: none;
    }
`

const ProfileImg = styled.img<IStyle>`
    background-color: ${(props) => colours[props.$theme].background_default};
    height: 32px;
    width: 32px;
    object-fit: cover;

    @media ${device.desktop} {
        height: 32px;
        width: 32px;
    }

    border-radius: 4px;

    @media ${device.mobile} {
        display: none;
    }
`

const StyledButtonAnim = styled.div<IStyle>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 200ms;
    .chlid_img {
        position: relative;
        border-radius: 4px;
    }

    .chlid_img:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${(props) =>
            props.$isActive ? colours[props.$theme].primary_50 : undefined};
        border-radius: 4px;
    }

    :hover {
        transition: all 200ms;
        .child_text {
            color: ${(props) => colours[props.$theme].primary};
        }
        .chlid_img {
            outline: ${(props) =>
                props.$isActive
                    ? 'none'
                    : `1px solid ${colours[props.$theme].primary}`};
            transition: all 200ms;
        }
    }
`

const SmallLine = styled.div<IStyle>`
    background-color: ${(props) => colours[props.$theme].border_muted};
    height: 24px;
    width: 1px;
`

const DropdownWrapper = styled.div`
    position: absolute;
    z-index: 7;
    right: -1.5vw;
    top: 48px;
`

const Dropdown = styled.div<IStyle>`
    display: flex;
    width: 280px;
    transform: translateX(16px);
    flex-direction: column;
    align-items: flex-start;
    background: ${(props) => colours[props.$theme].modal_side_sheet_pop_over};
    box-shadow: ${(props) =>
        `0px 4px 24px 0px ${
            colours[props.$theme].shadow_strongest_08
        }, 0px 4px 4px 0px  ${colours[props.$theme].shadow_strongest_04}`};
    padding-bottom: 8px;
`

const DropdownProfileBox = styled.div<IStyle>`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-bottom: 1px solid ${(props) => colours[props.$theme].border_muted};
    background: ${(props) => colours[props.$theme].background_neutral_soft};
    margin-bottom: 8px;
`

const DropdownOption = styled.div<IStyle>`
    display: flex;
    width: 100%;
    padding: 8px 16px;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    :hover {
        background-color: ${(props) =>
            colours[props.$theme].background_neutral_subtle};
        transition: all 200ms;
    }
`

const CercleWarning = styled.div<IStyle>`
    border-radius: 16px;
    background: ${(props) => colours[props.$theme].background_default};
    border: 5px solid ${(props) => colours[props.$theme].error};
    display: flex;
    height: 16px;
    width: 16px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -4px;
    bottom: -4px;
    z-index: 100;
`

const DropdownText = styled.p<IStyle>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].text_darker};
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const DropdownViewProfileBtn = styled.p<IStyle>`
    margin: 0px;
    padding: 0px;
    color: ${(props) => colours[props.$theme].primary};
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.001px;
    cursor: pointer;
    text-align: left;
    padding-top: 4px;
`

const AccountIconWrapper = styled.div<IStyle>`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
`

interface Props extends RouteComponentProps<any> {
    activepage: string
}

interface IStyle {
    $theme: ITheme
    ispageactive?: boolean
    isCollapsed?: boolean
    index?: number
    $spaceBetween?: boolean
    $isActive?: boolean
}

export type IAccountDropdownOption = {
    id: string
    pageName: string
    url: string
}

export const accountSubPages: IAccountDropdownOption[] = [
    { id: 'account', pageName: 'View my profile', url: '/account' },
    {
        id: 'settings',
        pageName: 'Account settings',
        url: '/account/preferences',
    },
    { id: 'faq', pageName: 'FAQ', url: '/account/faq' },
    { id: 'contact', pageName: 'Contact', url: '/account/contact' },
    { id: 'tutorials', pageName: 'Tutorials', url: '/account/tutorials' },
    { id: 'logout', pageName: 'Logout', url: 'logout' },
]

const renderOptions = (p: {
    options: IAccountDropdownOption[]
    user: IUser
    onOptionClick: (option: IAccountDropdownOption) => void
    currentPath: string
    theme: ITheme
}) => {
    const name = p.user && `${p.user.given_name}  ${p.user.family_name}`
    const profileImg =
        p.user && p.user.profile_image_url
            ? p.user.profile_image_url
            : p.theme === 'dark'
            ? account_default_dark
            : account_default

    return p.options && p.options.length > 0 ? (
        <Dropdown $theme={p.theme}>
            {p.options.map((option, i) => {
                const isActive = p.currentPath === option.url ? true : false
                return option.id === 'account' ? (
                    <DropdownProfileBox
                        $theme={p.theme}
                        key={`desktop_${option.id}`}
                    >
                        <div
                            style={{ width: 48, height: 48, borderRadius: 12 }}
                        >
                            <ProfileImg
                                $theme={p.theme}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: 12,
                                    objectFit: 'cover',
                                    margin: 0,
                                }}
                                src={profileImg}
                                alt="account_img"
                            />
                        </div>
                        <div>
                            <DropdownText
                                $theme={p.theme}
                                style={{
                                    color: colours[p.theme].text_strong,
                                    fontFamily: 'Lato-Semibold',
                                }}
                            >
                                {name}
                            </DropdownText>
                            <DropdownViewProfileBtn
                                $theme={p.theme}
                                role="button"
                                onClick={() => p.onOptionClick(option)}
                            >
                                {p.user?.family_name === p.user?.given_name
                                    ? 'Fill in missing details'
                                    : 'View my profile'}
                            </DropdownViewProfileBtn>
                        </div>
                    </DropdownProfileBox>
                ) : (
                    <DropdownOption
                        $theme={p.theme}
                        key={`desktop_${option.id}`}
                        role="button"
                        onClick={() => p.onOptionClick(option)}
                    >
                        <AccountIconWrapper
                            $theme={p.theme}
                            style={
                                isActive
                                    ? {
                                          backgroundColor:
                                              colours[p.theme].primary_underlay,
                                      }
                                    : {}
                            }
                        >
                            {renderAccountIcons(
                                option.id,
                                isActive ? colours[p.theme].primary : undefined
                            )}
                        </AccountIconWrapper>
                        <DropdownText
                            $theme={p.theme}
                            style={
                                isActive
                                    ? { color: colours[p.theme].primary }
                                    : {}
                            }
                        >
                            {option.pageName}
                        </DropdownText>
                    </DropdownOption>
                )
            })}
        </Dropdown>
    ) : null
}

const TopSubMenuDesktop: React.FC<Props> = (props) => {
    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const isCollapsed = useAppSelector(
        (state) => state.menus.desktopMainAppMenu.isCollapsed
    )
    const currentCarID = useAppSelector(
        (state) =>
            state.entities.carsData.currentCarID &&
            state.entities.carsData.currentCarID
    )
    const externalCarsData = useAppSelector(
        (state) => state.entities.carsData.directly_shared_ext_cars
    )
    const externalGaragesData = useAppSelector(
        (state) => state.entities.garagesData.directly_shared_external_garages
    )
    const currentExternalCarId = useAppSelector(
        (state) => state.entities.carsData.currentCarID_ext_directShare
    )
    const currentExternalGarageId = useAppSelector(
        (state) =>
            state.entities.garagesData
                .directly_shared_current_external_garage_id
    )
    const featureFlags = useAppSelector((state) => state.featureFlags)

    const history = useHistory()
    const location = useLocation()
    const locationState: any = location.state

    const determine_back_path = () => {
        if (history.location.pathname.includes('/shared/garage')) {
            return history.push('/sharing/with-you?entity_id=garage')
        } else if (
            history.location.pathname.includes('/shared/car') &&
            history.location.pathname !== `/shared/car/${currentExternalCarId}`
        ) {
            return history.push(`/shared/car/${currentExternalCarId}`)
        } else if (
            locationState &&
            locationState?.prevPath.includes('/shared/garage')
        ) {
            return history.push(
                locationState.search
                    ? `${locationState?.prevPath}${locationState?.search}`
                    : locationState?.prevPath
            )
        } else {
            return history.push('/sharing/with-you')
        }
    }

    const activepage = props.activepage

    // const trimmedTitle = activepage.replace(/[^\w\s]/gi, "");
    const subListItems = subMenuList[`${matchPageSubGroup(activepage)}`]

    const userName = userLoggedIn && `${userLoggedIn.display_name}`

    let checkIfActivePage = (activepage: string, item_url: string) => {
        if (item_url === '/history-files') {
            return false
        }

        if (item_url === '/insurance/overview') {
            return false
        }
        if (
            item_url === '/archives/car/technical-information' &&
            activepage === `/archives/car/${currentCarID}/technical-information`
        ) {
            return true
        }

        if (
            item_url === '/archives/car/gallery' &&
            (activepage === `/archives/car/${currentCarID}/gallery` ||
                activepage === `/archives/car/${currentCarID}/gallery/`)
        ) {
            return true
        }

        if (
            item_url === '/archives/car/history-file' &&
            activepage.match(/car/g) !== null &&
            activepage.match(/archives/g) !== null &&
            activepage.match(/history-file/g) !== null
        ) {
            return true
        }

        if (
            item_url === '/archives/car/overview' &&
            activepage === `/archives/car/${currentCarID}/overview`
        ) {
            return true
        }

        if (
            item_url === '/shared/car/technical-information' &&
            activepage ===
                `/shared/car/${currentExternalCarId}/technical-information`
        ) {
            return true
        }

        if (
            item_url === '/shared/car/gallery' &&
            (activepage === `/shared/car/${currentExternalCarId}/gallery` ||
                activepage === `/shared/car/${currentExternalCarId}/gallery/`)
        ) {
            return true
        }

        if (
            item_url === '/shared/car/history-file' &&
            activepage.match(/car/g) !== null &&
            activepage.match(/shared/g) !== null &&
            activepage.match(/history-file/g) !== null
        ) {
            return true
        }

        if (
            item_url === '/shared/car' &&
            activepage === `/shared/car/${currentExternalCarId}`
        ) {
            return true
        }

        if (
            item_url === '/car/gallery' &&
            (activepage === `/car/${currentCarID}/gallery` ||
                activepage === `/car/${currentCarID}/gallery/`)
        ) {
            return true
        }

        if (
            item_url === '/car/technical-information' &&
            activepage === `/car/${currentCarID}/technical-information`
        ) {
            return true
        }

        if (
            item_url === '/car/tasks' &&
            activepage.match(/car/g) !== null &&
            activepage.match(/tasks/g) !== null
        ) {
            return true
        }

        if (
            item_url === '/car/insights' &&
            activepage.match(/insights/g) !== null
        ) {
            return true
        }

        if (
            item_url === '/car/history-file' &&
            activepage.match(/history-file/g) !== null
        ) {
            return true
        }
        if (
            item_url === '/car/sharing' &&
            activepage.match(/car/g) !== null &&
            activepage.match(/sharing/g) !== null
        ) {
            return true
        }
        if (item_url === '/car' && activepage === `/car/${currentCarID}`) {
            return true
        }

        if (item_url === '/garage' && activepage === `/garage`) {
            return true
        }

        if (item_url === '/garage' && activepage === `/`) {
            return true
        }
        if (item_url === '/history-files' && activepage === `/history-files`) {
            return true
        }

        if (
            item_url === '/sharing/with-others' &&
            activepage.match(/sharing/g) !== null &&
            activepage.match(/with-others/g) !== null
        ) {
            return true
        }

        if (
            item_url === '/showroom/for-sale' &&
            activepage.match(/showroom/g) !== null &&
            activepage.match(/for-sale/g) !== null
        ) {
            return true
        }
        if (item_url === '/tasks') {
            return false
        }

        if (item_url === activepage) {
            return true
        } else return false
    }

    let currentPath = checkIfPwaStandalone()
        ? window.location.pathname
        : window.location.pathname

    let genPathname = (item: any) => {
        if (item.url === '/car/gallery') {
            return `/car/${currentCarID}/gallery`
        }

        if (item.url === '/car/technical-information') {
            return `/car/${currentCarID}/technical-information`
        }

        if (item.url === '/car/tasks') {
            return `/car/${currentCarID}/tasks`
        }

        if (item.url === '/car/sharing') {
            return `/car/${currentCarID}/sharing`
        }

        if (item.url === '/car/history-file') {
            return `/car/${currentCarID}/history-file`
        }

        if (item.url === '/car') {
            return `/car/${currentCarID}`
        }

        if (item.url === '/shared/car') {
            return `/shared/car/${currentExternalCarId}`
        }

        if (item.url === '/shared/car/gallery') {
            return `/shared/car/${currentExternalCarId}/gallery`
        }

        if (item.url === '/shared/car/technical-information') {
            return `/shared/car/${currentExternalCarId}/technical-information`
        }

        if (item.url === '/shared/car/history-file') {
            return `/shared/car/${currentExternalCarId}/history-file`
        }

        if (item.url === '/archives/car/overview') {
            return `/archives/car/${currentCarID}/overview`
        }

        if (item.url === '/archives/car/gallery') {
            return `/archives/car/${currentCarID}/gallery`
        }

        if (item.url === '/archives/car/technical-information') {
            return `/archives/car/${currentCarID}/technical-information`
        }

        if (item.url === '/archives/car/history-file') {
            return `/archives/car/${currentCarID}/history-file`
        }

        if (item.url === '/car/insights') {
            return `/car/${currentCarID}/insights`
        }

        return item.url
    }

    let currentExternalCar =
        currentExternalCarId &&
        externalCarsData &&
        externalCarsData[currentExternalCarId]
    let currentExternalGarage =
        currentExternalGarageId &&
        externalGaragesData &&
        externalGaragesData[currentExternalGarageId]

    let showroom_enabled =
        userLoggedIn?.role === 'Admin' || userLoggedIn?.role === 'Dealer'
            ? true
            : featureFlags.enable_showroom

    let isExternalGarage =
        history.location.pathname.match(/shared/g) !== null &&
        history.location.pathname.match(/garage/g) !== null
            ? true
            : false

    const [showDropdown, setShowDropdown] = React.useState(false)
    const closeDropdown = () => setShowDropdown(false)

    const ref = React.useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, closeDropdown)
    const dispatch = useAppDispatch()

    const onAccountOptionClick = (option: IAccountDropdownOption) => {
        if (option.id === 'logout') {
            dispatch(logoutRequest())
        } else {
            history.push(option.url)
        }
        closeDropdown()
    }

    const { theme } = useThemes()

    const userProfileImg =
        userLoggedIn && userLoggedIn.profile_image_url
            ? userLoggedIn.profile_image_url
            : theme === 'dark'
            ? account_default_dark
            : account_default

    return (
        <>
            {!showroom_enabled &&
            currentPath
                .split('/')
                .includes('showroom') ? null : currentPath.match(/shared/g) ? (
                <MenuWrapper
                    $theme={theme}
                    isCollapsed={isCollapsed}
                    style={{
                        height: isExternalGarage
                            ? 80
                            : subListItems
                            ? '120px'
                            : '50px',
                    }}
                >
                    <FlexColumn $theme={theme}>
                        <FlexRow
                            $spaceBetween
                            style={{
                                alignItems: 'center',
                                transform: isExternalGarage
                                    ? 'translateY(0px)'
                                    : subListItems
                                    ? 'translateY(-5px)'
                                    : 'translateY(1px)',
                            }}
                        >
                            <FlexRowBtn
                                $theme={theme}
                                style={{
                                    paddingLeft: '38px',
                                    height: '60px',
                                    alignItems: 'center',
                                    fontSize: '16px',
                                    textTransform: 'uppercase',
                                    width: 'auto',
                                    cursor: 'pointer',
                                }}
                                onClick={determine_back_path}
                                role="button"
                            >
                                <Chevronwrapper $theme={theme}>
                                    <ChevronDown />
                                </Chevronwrapper>
                                <div
                                    style={{
                                        textTransform: 'uppercase',
                                        color: colours[theme].text_strong,
                                    }}
                                >
                                    Shared with you
                                </div>
                                <SmallLine
                                    $theme={theme}
                                    style={{
                                        marginLeft: '15px',
                                        marginRight: '15px',
                                        textTransform: 'uppercase',
                                    }}
                                />
                                <CarTitle $theme={theme}>
                                    {history.location.pathname.includes(
                                        '/shared/garage'
                                    )
                                        ? `${
                                              currentExternalGarage &&
                                              currentExternalGarage.owner &&
                                              currentExternalGarage.owner
                                                  ? `${currentExternalGarage.owner.display_name}`
                                                  : 'unknown'
                                          }'s Garage`
                                        : currentExternalCar &&
                                          currentExternalCar.title}{' '}
                                </CarTitle>
                            </FlexRowBtn>

                            {userLoggedIn ? (
                                <RightSide>
                                    <div
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        ref={ref}
                                    >
                                        <StyledButtonAnim
                                            $theme={theme}
                                            role="button"
                                            onClick={() =>
                                                setShowDropdown(true)
                                            }
                                            $isActive={showDropdown}
                                        >
                                            <RightItem
                                                $theme={theme}
                                                className="child_text"
                                                style={
                                                    showDropdown
                                                        ? {
                                                              color: colours[
                                                                  theme
                                                              ].primary,
                                                          }
                                                        : {}
                                                }
                                            >
                                                {userName}
                                            </RightItem>

                                            <div className="chlid_img">
                                                <ProfileImg
                                                    $theme={theme}
                                                    src={userProfileImg}
                                                    alt="account"
                                                    style={
                                                        showDropdown
                                                            ? {
                                                                  border: `1px solid ${colours[theme].primary}`,
                                                              }
                                                            : {}
                                                    }
                                                />
                                            </div>
                                        </StyledButtonAnim>
                                        <div style={{ paddingLeft: 16 }} />
                                        <ThemeSwitcherDesktop />

                                        {showDropdown ? (
                                            <Faded>
                                                <DropdownWrapper>
                                                    {renderOptions({
                                                        options:
                                                            accountSubPages,
                                                        user: userLoggedIn,
                                                        onOptionClick:
                                                            onAccountOptionClick,
                                                        currentPath:
                                                            currentPath,
                                                        theme: theme,
                                                    })}
                                                </DropdownWrapper>
                                            </Faded>
                                        ) : null}
                                    </div>
                                </RightSide>
                            ) : null}
                        </FlexRow>
                        {isExternalGarage ? null : (
                            <FlexRow $spaceBetween>
                                <LeftSide>
                                    {subListItems !== undefined &&
                                        subListItems.map(
                                            (item: any, index: any) => {
                                                if (
                                                    item.pageName ===
                                                        'contact-information' ||
                                                    item.pageName ===
                                                        'security' ||
                                                    item.pageName ===
                                                        'install custodian' ||
                                                    item.pageName ===
                                                        'notifications' ||
                                                    item.pageName ===
                                                        'previously-owned'
                                                ) {
                                                    return (
                                                        <div key={index}> </div>
                                                    )
                                                } else if (
                                                    item.pageName !==
                                                        'reminders' &&
                                                    // item.pageName !== 'timeline' &&
                                                    item.pageName !== 'projects'
                                                ) {
                                                    return (
                                                        <Faded
                                                            width={'auto'}
                                                            key={`top_submenu_desktop_${index}`}
                                                        >
                                                            <Link
                                                                to={{
                                                                    pathname:
                                                                        genPathname(
                                                                            item
                                                                        ),
                                                                    state: {
                                                                        prevPath:
                                                                            currentPath,
                                                                    },
                                                                }}
                                                                replace={true}
                                                            >
                                                                <LeftItemText
                                                                    $theme={
                                                                        theme
                                                                    }
                                                                    ispageactive={checkIfActivePage(
                                                                        activepage,
                                                                        item.url
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    {
                                                                        item.pageName
                                                                    }
                                                                </LeftItemText>
                                                            </Link>
                                                        </Faded>
                                                    )
                                                } else {
                                                    return (
                                                        <div
                                                            style={{
                                                                cursor: 'not-allowed',
                                                            }}
                                                            key={index}
                                                        >
                                                            <Tooltip title="Coming Soon">
                                                                <LeftItemText
                                                                    $theme={
                                                                        theme
                                                                    }
                                                                    ispageactive={checkIfActivePage(
                                                                        activepage,
                                                                        item.url
                                                                    )}
                                                                    index={
                                                                        index
                                                                    }
                                                                >
                                                                    <span
                                                                        style={{
                                                                            color: '#8f8f8f',
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.pageName
                                                                        }
                                                                    </span>
                                                                </LeftItemText>
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                }
                                            }
                                        )}
                                </LeftSide>
                            </FlexRow>
                        )}
                    </FlexColumn>
                </MenuWrapper>
            ) : (
                <MenuWrapper $theme={theme} isCollapsed={isCollapsed}>
                    <LeftSide>
                        {subListItems !== undefined &&
                            subListItems.map((item: any, index: any) => {
                                if (
                                    item.pageName === 'contact-information' ||
                                    item.pageName === 'security' ||
                                    item.pageName === 'install custodian' ||
                                    item.pageName === 'notifications'
                                ) {
                                    return <div key={index}> </div>
                                } else if (
                                    item.pageName !== 'reminders' &&
                                    // item.pageName !== 'timeline' &&
                                    item.pageName !== 'projects'
                                ) {
                                    return (
                                        <Faded width={'auto'} key={index}>
                                            <Link
                                                to={{
                                                    pathname: genPathname(item),
                                                    state: {
                                                        prevPath: currentPath,
                                                    },
                                                }}
                                                replace={false}
                                            >
                                                <LeftItemText
                                                    $theme={theme}
                                                    ispageactive={checkIfActivePage(
                                                        activepage,
                                                        item.url
                                                    )}
                                                    index={index}
                                                >
                                                    {item.pageName}
                                                </LeftItemText>
                                            </Link>
                                        </Faded>
                                    )
                                } else {
                                    return (
                                        <div
                                            style={{
                                                cursor: 'not-allowed',
                                            }}
                                            key={index}
                                        >
                                            <Tooltip title="Coming Soon">
                                                <LeftItemText
                                                    $theme={theme}
                                                    ispageactive={checkIfActivePage(
                                                        activepage,
                                                        item.url
                                                    )}
                                                    index={index}
                                                >
                                                    <span
                                                        style={{
                                                            color: '#8f8f8f',
                                                        }}
                                                    >
                                                        {item.pageName}
                                                    </span>
                                                </LeftItemText>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            })}
                    </LeftSide>

                    <RightSide>
                        {/* <LinkWrap to="/account">
                        <Dot>0</Dot>
                        <ProfileImg src={notifications_circle} alt="account" />
                    </LinkWrap> */}
                        {/* <RedSticker
                                text={'beta'}
                                beta={true}
                                height={'22px'}
                                width={'48px'}
                            /> */}
                        <div style={{ paddingLeft: '16px' }} />

                        {userLoggedIn ? (
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                ref={ref}
                            >
                                <StyledButtonAnim
                                    $theme={theme}
                                    role="button"
                                    onClick={() => setShowDropdown(true)}
                                    $isActive={showDropdown}
                                >
                                    <RightItem
                                        $theme={theme}
                                        className="child_text"
                                        style={
                                            showDropdown
                                                ? {
                                                      color: colours[theme]
                                                          .primary,
                                                  }
                                                : {}
                                        }
                                    >
                                        {userName}
                                    </RightItem>

                                    <div className="chlid_img">
                                        <ProfileImg
                                            $theme={theme}
                                            src={userProfileImg}
                                            alt="account"
                                            style={
                                                showDropdown
                                                    ? {
                                                          border: `1px solid ${colours[theme].primary}`,
                                                      }
                                                    : {}
                                            }
                                        />
                                        {userLoggedIn.family_name ===
                                            userLoggedIn.given_name && (
                                            <CercleWarning $theme={theme} />
                                        )}
                                    </div>
                                </StyledButtonAnim>
                                <div style={{ paddingLeft: 16 }} />
                                <ThemeSwitcherDesktop />
                                {showDropdown ? (
                                    <Faded>
                                        <DropdownWrapper>
                                            {renderOptions({
                                                options: accountSubPages,
                                                user: userLoggedIn,
                                                onOptionClick:
                                                    onAccountOptionClick,
                                                currentPath: currentPath,
                                                theme: theme,
                                            })}
                                        </DropdownWrapper>
                                    </Faded>
                                ) : null}
                            </div>
                        ) : (
                            <>
                                <div style={{ paddingLeft: '10px' }} />
                                <ButtonAtom
                                    height="40px"
                                    width="100px"
                                    theme="capitalize-white-background"
                                    onClick={() => {
                                        history.push('/login')
                                    }}
                                >
                                    Login
                                </ButtonAtom>
                                <div style={{ paddingLeft: '16px' }} />
                                <ButtonAtom
                                    height="40px"
                                    width="100px"
                                    theme="secondary"
                                    textTransform="none"
                                    onClick={() => {
                                        history.push('/register')
                                    }}
                                >
                                    Sign Up
                                </ButtonAtom>
                                <div style={{ paddingLeft: '10px' }} />
                            </>
                        )}
                    </RightSide>
                </MenuWrapper>
            )}
        </>
    )
}

export default withRouter(TopSubMenuDesktop)
