import { createAction } from '@reduxjs/toolkit'
import { IReduxError } from 'entityModels'

import {
    GET_TASKS_BY_GARAGE_ID_ERROR,
    GET_TASKS_BY_CAR_ID_ERROR,
    GET_TASK_BY_ID_ERROR,
    UPDATE_TASK_ERROR,
    CREATE_TASK_ERROR,
    UNLINK_TASK_ERROR,
    LINK_TASK_ERROR,
    DELETE_TASK_ERROR,
    GET_TASK_REMINDERS_ERROR,
    ADD_REMINDER_TO_TASK_ERROR,
    DELETE_REMINDER_TASK_ERROR,
} from './constants'

// export const getTasksByGarageIdError = createAction(
//     GET_TASKS_BY_GARAGE_ID_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getTasksByGarageIdError = createAction(
    GET_TASKS_BY_GARAGE_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getTasksByCarIdError = createAction(
//     GET_TASKS_BY_CAR_ID_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getTasksByCarIdError = createAction(
    GET_TASKS_BY_CAR_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const getTaskByIdError = createAction(
//     GET_TASK_BY_ID_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const getTaskByIdError = createAction(
    GET_TASK_BY_ID_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const updateTaskError = createAction(
//     UPDATE_TASK_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const updateTaskError = createAction(
    UPDATE_TASK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const createTaskError = createAction(
//     CREATE_TASK_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const createTaskError = createAction(
    CREATE_TASK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const unlinkTaskError = createAction(
//     UNLINK_TASK_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const unlinkTaskError = createAction(
    UNLINK_TASK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const linkTaskError = createAction(
//     LINK_TASK_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const linkTaskError = createAction(
    LINK_TASK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

// export const deleteTaskError = createAction(
//     DELETE_TASK_ERROR,
//     (resolve) => (payload: IReduxError) => resolve(payload)
// )

export const deleteTaskError = createAction(
    DELETE_TASK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const getTaskRemindersError = createAction(
    GET_TASK_REMINDERS_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const addRemindersToTaskError = createAction(
    ADD_REMINDER_TO_TASK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)

export const deleteReminderTaskError = createAction(
    DELETE_REMINDER_TASK_ERROR,
    function prepare(payload: IReduxError) {
        return {
            payload,
        }
    }
)
