import { motion } from 'framer-motion'
import * as React from 'react'
import styled from 'styled-components'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'

type IStyle = {
    $isUppercase?: boolean | undefined
    $width?: string | undefined
    $height?: string | undefined
    $color?: string
    $iconWidth?: string
    $fontSize?: string
    $bgColor?: string
    $translateY?: string
    $theme: ITheme
}

const Button = styled(motion.div)<IStyle>`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: ${(props) => (props.$width ? props.$width : '100%')};
    height: ${(props) => (props.$height ? props.$height : '64px')};
    padding-left: 24px;
    gap: 24px;
    border: transparent;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    background-color: ${(props) =>
        props.$bgColor
            ? props.$bgColor
            : colours[props.$theme].background_default};
    @media (max-width: 320px) {
        height: 48px;
    }
`

const Text = styled.div<IStyle>`
    color: ${(props) =>
        props.$color ? props.$color : colours[props.$theme].text_strong};
    font-family: 'Lato';
    font-size: ${(props) => (props.$fontSize ? props.$fontSize : '15px')};
    letter-spacing: 0.7px;
    text-transform: ${(props) =>
        props.$isUppercase ? 'uppercase' : 'capitalize'};
    @media (max-width: 320px) {
        font-size: 14px;
    }
`

const IconWrapper = styled.div<IStyle>`
    height: 24px;
    min-width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const NewFlag = styled.div<IStyle>`
    display: flex;
    height: 16px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${(props) => colours[props.$theme].error};
    color: ${(props) => colours[props.$theme].background_default};
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 10px;
    font-style: normal;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    line-height: 16px;
`

type Props = {
    onClick: any
    text: string | undefined
    dataCyId?: string | undefined
    isUppercase?: boolean
    width?: string | undefined
    height?: string
    color?: string
    icon: React.ReactNode
    iconWidth?: string
    fontSize?: string
    bgColor?: string
    hasNewFlag?: boolean
}

export default function NavDrawerBtn(props: Props) {
    let {
        onClick,
        icon,
        text,
        isUppercase,
        width,
        height,
        dataCyId,
        color,
        fontSize,
        bgColor,
        hasNewFlag,
    } = props

    const { theme } = useThemes()
    return (
        <Button
            $theme={theme}
            $width={width}
            $height={height}
            onClick={onClick}
            data-attr={dataCyId ? dataCyId : undefined}
            $color={color}
            $bgColor={bgColor}
            whileTap={{ scale: 1.1 }}
        >
            <IconWrapper $theme={theme}>{icon}</IconWrapper>
            <Text
                $theme={theme}
                $isUppercase={isUppercase}
                $fontSize={fontSize}
            >
                {text}
            </Text>
            {hasNewFlag && <NewFlag $theme={theme}>NEW</NewFlag>}
        </Button>
    )
}
