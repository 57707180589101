import {
    GET_GALLERY_IMAGES_REQUEST,
    SET_GALLERY_IMAGES_REQUEST,
    UPDATE_CAR_GALLERY_IMAGE_REQUEST,
    DELETE_CAR_GALLERY_IMAGE_REQUEST,
    DELETE_ENTRY_GALLERY_IMAGE_REQUEST,
    UPDATE_ENTRY_GALLERY_IMAGE_REQUEST,
} from './constants'

import {
    IDeleteEntryGalleryImagePayload,
    IUpdateCarGalleryImagePayload,
    IUpdateEntryGalleryImagePayload,
} from 'entityModels'

import { createAction } from '@reduxjs/toolkit'

export const getGalleryImagesRequest = createAction(GET_GALLERY_IMAGES_REQUEST)

export const setGalleryImagesRequest = createAction(SET_GALLERY_IMAGES_REQUEST)

// export const updateCarGalleryImageRequest = createAction(
//     UPDATE_CAR_GALLERY_IMAGE_REQUEST,
//     (resolve) => (payload: IUpdateCarGalleryImagePayload) => resolve(payload)
// )

export const updateCarGalleryImageRequest = createAction(
    UPDATE_CAR_GALLERY_IMAGE_REQUEST,
    function prepare(payload: IUpdateCarGalleryImagePayload) {
        return {
            payload,
        }
    }
)

export const updateEntryGalleryImageRequest = createAction(
    UPDATE_ENTRY_GALLERY_IMAGE_REQUEST,
    function prepare(payload: IUpdateEntryGalleryImagePayload) {
        return {
            payload,
        }
    }
)

type Iids = {
    carid: string
    imageid: string
}
// export const deleteCarGalleryImageRequest = createAction(
//     DELETE_CAR_GALLERY_IMAGE_REQUEST,
//     (resolve) => (ids: Iids) => resolve(ids)
// )

export const deleteCarGalleryImageRequest = createAction(
    DELETE_CAR_GALLERY_IMAGE_REQUEST,
    function prepare(payload: Iids) {
        return {
            payload,
        }
    }
)

export const deleteEntryGalleryImageRequest = createAction(
    DELETE_ENTRY_GALLERY_IMAGE_REQUEST,
    function prepare(payload: IDeleteEntryGalleryImagePayload) {
        return {
            payload,
        }
    }
)
