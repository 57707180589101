import { call, fork, put, select, take } from 'redux-saga/effects'
import { api } from '../../../services'
import posthog from 'posthog-js'
import * as actions from '../actions'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import {
    ICustomErrorData,
    IGarageObject,
    IHandover,
    IReduxError,
} from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import { setUserArchivedCarsSuccess } from '../../../user/actions/actions'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'
import { ICreateHandoverAPI_req } from '../../../services/handover/createHandover'
import { removeCarFromUserGarage } from '../../garages/actions/actions'
import { IcreateHandoverSuccessArg } from '../actions/actions'
import { GetCarActionCheck } from './getCarActionCheck'

let state_select_user = (state: RootState) => state.user.userLoggedIn
let state_select_garage_data = (state: RootState) =>
    state.entities.garagesData.garages

export function* CreateHandoverSaga(p: ICreateHandoverAPI_req) {
    try {
        let new_handover: IHandover = yield call(api.handover.createHandover, p)
        yield call(GetCarActionCheck, { carid: p.carID })

        // Capture event
        posthog.capture('car_handover submit')

        let udata: IUser | null = yield select(state_select_user)
        let garage_data: IGarageObject = yield select(state_select_garage_data)
        let garageid = udata?.owns_garage.uid
        let carsInCurrentGarage =
            garageid && garage_data[garageid] && garage_data[garageid].cars
                ? garage_data[garageid].cars
                : undefined
        let sortedCarsInCurrentGarage =
            garageid &&
            garage_data[garageid] &&
            garage_data[garageid].sorted_car_ids
                ? garage_data[garageid].sorted_car_ids
                : undefined

        if (udata) {
            let arr: string[] =
                udata.archived_cars &&
                udata.archived_cars.car_ids &&
                !udata.archived_cars.car_ids.includes(p.carID)
                    ? [...udata.archived_cars.car_ids, p.carID]
                    : [p.carID]

            if (
                p.isResend !== true &&
                ((carsInCurrentGarage &&
                    carsInCurrentGarage?.includes(p.carID)) ||
                    (sortedCarsInCurrentGarage &&
                        sortedCarsInCurrentGarage?.includes(p.carID)))
            ) {
                yield put(
                    removeCarFromUserGarage({
                        carID: p.carID,
                        garageID: udata.owns_garage.uid,
                    })
                )

                if (udata.archived_cars !== undefined) {
                    // else we'll get data on the car as will be undefined
                    yield put(
                        setUserArchivedCarsSuccess({
                            car_ids: [...arr],
                            activeSort:
                                udata.archived_cars &&
                                udata.archived_cars.active_sort
                                    ? udata.archived_cars.active_sort
                                    : 'ARCHIVED_ON_MOST_RECENT',
                        })
                    )
                }
            }
        }

        let paa: IcreateHandoverSuccessArg = {
            car_id: p.carID,
            handover: new_handover,
        }
        yield put(actions.actions.createHandoverSuccess(paa))
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: CreateHandoverSaga,
                payload: p,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/garage`,
            }
            let typedError: IReduxError = ConvertToReduxError(
                error,
                customErrorData
            )
            yield put(actions.errorActions.createHandoverError(typedError))
            return
        }
    }
}

function* WatcherCreateHandover() {
    while (true) {
        let { payload } = yield take(
            actions.loadingActions.createHandoverRequest
        )
        yield call(CreateHandoverSaga, payload)
    }
}

const handover_creation_saga: any[] = [fork(WatcherCreateHandover)]

export default handover_creation_saga
