import React from 'react'
// import debounce from "lodash/debounce";
import Fuse from 'fuse.js'

import { debounce } from 'lodash'

import LabelsFilterByMultipleWSearchWFuseDesktop from './labelsFilterByMultipleWSearchUIDesktop'
import { ILabel } from 'myModels'

export type Props = {
    category_searchable_items_list?: any
    user_searchable_labels_list: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    issearchactive?: boolean | undefined
    addLabelButton?: boolean
    onSave?: any
    hasCreateNewLabel?: boolean
    entry_labels_list: ILabel[]
}

// not sure why now threw error. Removed specifications and error free.
// keeping this here in case .

// export type ItemsFuse = {
//   id: string;
//   value: string;
// };

// const options: Fuse.FuseOptions<ItemsFuse> = {
//   tokenize: true,
//   keys: ["value"]
// };

type LocalState = {
    data: any[]
    categoryData: any[]
    labelsDataRes: any[]
    name: any[]
    fetching: boolean
}

class FilterByMultipleWSearchWFuse extends React.Component<Props, LocalState> {
    constructor(props: Props) {
        super(props)
        this.lastFetchId = 0
        this.fetchItem = debounce(this.fetchItem, 180)
    }

    state = {
        data: [],
        categoryData: [],
        labelsDataRes: [],
        name: [],
        fetching: false,
    }

    lastFetchId = 0

    fetchItem = (name: any) => {
        this.lastFetchId += 1

        const fetchId = this.lastFetchId
        this.setState({ data: [], fetching: true })
        if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return
        }

        const fuseCategory = this.props.category_searchable_items_list
            ? new Fuse(this.props.category_searchable_items_list, {
                  keys: ['name'],
              })
            : undefined

        const resultsCategory =
            fuseCategory &&
            fuseCategory.search(name, {
                limit: 2,
            })

        this.setState({
            ...this.state,
            categoryData: resultsCategory ? resultsCategory : [],
            fetching: false,
        })

        const fuseLabels = new Fuse(this.props.user_searchable_labels_list, {
            keys: ['name'],
        })

        const resultsLabel = fuseLabels.search(name, { limit: 2 })

        this.setState({
            ...this.state,
            labelsDataRes: resultsLabel,
            fetching: false,
        })

        return
    }

    handleChange = (name: any) => {
        this.setState({
            ...this.state,
            name: [name],
            data: [],
            categoryData: [],
            labelsDataRes: [],
            fetching: false,
        })
        //this.props.handleSelectChange && this.props.handleSelectChange(name)
    }

    clearField = () => {
        this.setState({
            ...this.state,
            name: [],
            data: [],
            categoryData: [],
            labelsDataRes: [],
            fetching: false,
        })
    }

    render() {
        const {
            //fetching,
            name,
            labelsDataRes,
            categoryData,
            // data
        } = this.state
        const {
            //           allowAdd,
            //           isDisabled,
            //           placeholder,
            // addCategoryFilter,
            // addTagFilter,
            dataCyId,
            addLabelButton,
            user_searchable_labels_list,
            onSave,
            hasCreateNewLabel,
            entry_labels_list,
        } = this.props

        return (
            <LabelsFilterByMultipleWSearchWFuseDesktop
                dataCyId={dataCyId}
                user_searchable_labels_list={user_searchable_labels_list}
                value={name}
                handleChange={this.handleChange}
                fetchItem={this.fetchItem}
                categoryData={categoryData}
                labelsDataRes={labelsDataRes}
                addLabelButton={addLabelButton}
                onSave={onSave}
                hasCreateNewLabel={hasCreateNewLabel}
                clearField={this.clearField}
                entry_labels_list={entry_labels_list}
            />
        )
    }
}

export default FilterByMultipleWSearchWFuse
