import { INormalisedAttachmentsByID } from 'attachmentModels'
import {
    ICar,
    IDropdownItem,
    IExternalCar,
    IExternalCarsObject,
} from 'entityModels'
import { ILabel, IUser } from 'myModels'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { ITimelineDataByCarNormalised, ITimelineItem } from 'timelineModels'
import { RootState } from 'typesafe-actions'
import { setActiveEntrySuccess } from '../../../../redux/timeline/actions/successActions'
import TimelineItemReturnNav from '../../../atoms/header/timelineItemReturnNav'
import EntryOptionsMenuBar from '../../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'

import EntryPageTopHeaderDesktop from '../../../molecules/entryPageTopHeader/entryPageTopHeaderDesktop'
import SingleTimelineItemBodyDesktop from '../../../molecules/singleTimelineItemBody/singleTimelineItemBodyDesktop'
import SingleTimelineItemBodyMobile from '../../../molecules/singleTimelineItemBody/singleTimelineItemBodyMobile'
import Faded from '../../../templates/animated/faded'
import SideSlider from '../../../templates/animated/sideSlider'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../../templates/displays/pageWrappers/CenteredPageWrapper'
import WindowPageTemplate from '../../../templates/windowPageTemplate/windowPageTemplateDesktop'
import FullPagePageWrapper from '../../displays/pageWrappers/FullPagePageWrapper'
// import FabsMenusMobile from '../../../templates/menus/fabsMenuMobile'

const SmallPadding = styled.div`
    padding-top: 20px;
`

interface Props extends RouteComponentProps<any> {
    sharedCar?: IExternalCar
    prevOwned?: ICar
    userLoggedIn: IUser | null
    externalCarsData: IExternalCarsObject | undefined
    timelineData: ITimelineDataByCarNormalised | null | undefined
    attachmentsObj: INormalisedAttachmentsByID
    user_searchable_labels_list: IDropdownItem[] | null | undefined
    entry_labels_list: ILabel[] | undefined
    userCurrency: string | undefined
    setActiveEntry: (payload: ITimelineItem) => {}
    isCollapsed: boolean
}

const dispatchProps = {
    setActiveEntry: (payload: ITimelineItem) => setActiveEntrySuccess(payload),
}

type State = {
    placeholderUrl: string
}

function mapStateToProps(state: RootState) {
    return {
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
        timelineData: state.timeline.timeline_data_normalised_by_car_id,
        attachmentsObj: state.attachments.attachmentsById,
        user_searchable_labels_list:
            state.user.userLoggedIn && state.user.userLoggedIn.labels,
        entry_labels_list:
            state.timeline.activeEntry && state.timeline.activeEntry.labels,
        userCurrency:
            state.user.userLoggedIn?.preferences.data.units.data
                .default_currency.user_choice_id,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

class ReadOnlyCarHistoryFileEntry extends React.Component<Props, State> {
    navigateEntries = (direction: 'next' | 'previous') => {
        let carid = this.props.match.params.carid
        let all_entries: ITimelineItem[] | null | undefined =
            this.props.timelineData &&
            this.props.timelineData[carid] &&
            this.props.timelineData[carid].entries

        const q_params = new URLSearchParams(this.props.location.search)
        let entryid = q_params.get('entryid')

        let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
            function (entry: ITimelineItem) {
                return entry && entry.id === entryid
            }
        )

        let nextEntry =
            all_entries && (indexOFActiveEntry || indexOFActiveEntry === 0)
                ? indexOFActiveEntry < all_entries.length - 1
                    ? all_entries[indexOFActiveEntry + 1]
                        ? all_entries[indexOFActiveEntry + 1]
                        : undefined
                    : all_entries[all_entries.length - 1]
                    ? all_entries[all_entries.length - 1]
                    : undefined
                : undefined

        let previousEntry =
            all_entries && (indexOFActiveEntry || indexOFActiveEntry === 0)
                ? indexOFActiveEntry > 1
                    ? all_entries[indexOFActiveEntry - 1]
                        ? all_entries[indexOFActiveEntry - 1]
                        : undefined
                    : all_entries[0]
                    ? all_entries[0]
                    : undefined
                : undefined

        let linkPrefix = this.props.sharedCar
            ? '/shared'
            : this.props.prevOwned
            ? '/archives'
            : ''

        if (direction === 'next' && nextEntry !== undefined) {
            this.props.setActiveEntry(nextEntry)
            this.props.history.push(
                `${linkPrefix}/car/${carid}/history-file/entry?entryid=${nextEntry.id}`
            )
        } else if (previousEntry !== undefined) {
            this.props.setActiveEntry(previousEntry)
            this.props.history.push(
                `${linkPrefix}/car/${carid}/history-file/entry?entryid=${previousEntry.id}`
            )
        }
        return
    }

    render() {
        let carid = this.props.match.params.carid

        let {
            attachmentsObj,
            sharedCar,
            prevOwned,
            userCurrency,
            userLoggedIn,
            timelineData,
            isCollapsed,
        } = this.props

        const q_params = new URLSearchParams(this.props.location.search)
        let entryid = q_params.get('entryid')

        let all_entries =
            timelineData && timelineData[carid] && timelineData[carid].entries

        let indexOFActiveEntry: number | undefined = all_entries?.findIndex(
            function (entry: ITimelineItem) {
                return entry.id === entryid
            }
        )

        let activeEntry =
            all_entries &&
            indexOFActiveEntry !== undefined &&
            all_entries[indexOFActiveEntry]

        let attachmentSectionDiv = document.getElementById(
            'attachments-section'
        )

        let userDistanceUnit =
            userLoggedIn &&
            userLoggedIn.preferences &&
            userLoggedIn.preferences.data &&
            userLoggedIn.preferences.data.units &&
            userLoggedIn.preferences.data.units.data &&
            userLoggedIn.preferences.data.units.data.distance_unit &&
            userLoggedIn.preferences.data.units.data.distance_unit
                .user_choice_id

        let currentCar = sharedCar ?? prevOwned

        let linkPrefix = sharedCar ? '/shared' : prevOwned ? '/archives' : ''

        return (
            <React.Fragment>
                <Faded>
                    {activeEntry && currentCar && (
                        <>
                            <DesktopDisplayOnly>
                                <FullPagePageWrapper paddingTop="24px">
                                    <Faded>
                                        <div
                                            style={{
                                                position: 'relative',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    paddingTop: '20px',
                                                }}
                                            />
                                            <WindowPageTemplate
                                                isCollapsed={isCollapsed}
                                                isExternalCar
                                                rightSideContent={
                                                    <EntryOptionsMenuBar
                                                        activeEntry={
                                                            activeEntry
                                                        }
                                                        carid={carid}
                                                        noAddLabelButton
                                                        readOnlyMode
                                                    />
                                                }
                                                stickyHeaderContent={
                                                    <EntryPageTopHeaderDesktop
                                                        item={activeEntry}
                                                        elementToScrollTo={
                                                            attachmentSectionDiv
                                                        }
                                                        entityID={
                                                            activeEntry.id
                                                        }
                                                        carID={carid}
                                                        entityType="entry"
                                                        goBackToTimeline={
                                                            `${linkPrefix}/car/${carid}/history-file`

                                                            // this.props.history.goBack()
                                                        }
                                                        goToNextEntry={() =>
                                                            this.navigateEntries(
                                                                'next'
                                                            )
                                                        }
                                                        goToPreviousEntry={() =>
                                                            this.navigateEntries(
                                                                'previous'
                                                            )
                                                        }
                                                        readOnlyMode
                                                    />
                                                }
                                            >
                                                <SingleTimelineItemBodyDesktop
                                                    item={activeEntry}
                                                    entityType="entry"
                                                    carID={carid}
                                                    entityID={activeEntry.id}
                                                    attachmentsObj={
                                                        attachmentsObj
                                                    }
                                                    costsObj={
                                                        activeEntry.costItemsObj
                                                    }
                                                    userCurrency={userCurrency}
                                                    isCostAttachmentBeingUploaded={
                                                        false
                                                    }
                                                    readOnlyMode
                                                    sharedWithYouCar={sharedCar}
                                                    toggleAddGalleryImageToEntryForm={() => {}}
                                                />
                                            </WindowPageTemplate>
                                        </div>
                                    </Faded>
                                </FullPagePageWrapper>
                            </DesktopDisplayOnly>

                            <IpadAndMobileDisplay>
                                <CenteredPageWrapper fullwidth>
                                    <div
                                        style={{
                                            width: '100%',
                                            marginTop: '-50px',
                                        }}
                                    >
                                        <TimelineItemReturnNav
                                            chevron={true}
                                            customText={`Entries`}
                                            linkTo={`${linkPrefix}/car/${carid}/history-file`}
                                            dataCyIdThreeDots={
                                                'open-entry-slide-up-menu'
                                            }
                                            dataCyIdChevron={
                                                'go-back-to-timeline'
                                            }
                                            category={activeEntry.categoryID}
                                            currentExternalCarTitle={
                                                currentCar && currentCar.title
                                            }
                                            isReadonly
                                        />

                                        <SideSlider
                                            direction="right"
                                            initial="enter"
                                        >
                                            <SmallPadding />

                                            <SingleTimelineItemBodyMobile
                                                item={activeEntry}
                                                userDistanceUnit={
                                                    userDistanceUnit
                                                }
                                                attachmentsObj={attachmentsObj}
                                                entityType="entry"
                                                carID={carid}
                                                entityID={activeEntry.id}
                                                costsObj={
                                                    activeEntry.costItemsObj
                                                }
                                                userCurrency={userCurrency}
                                                isCostAttachmentBeingUploaded={
                                                    false
                                                }
                                                readOnlyMode
                                                sharedWithYouCar={sharedCar}
                                            />
                                        </SideSlider>
                                    </div>{' '}
                                </CenteredPageWrapper>
                            </IpadAndMobileDisplay>
                        </>
                    )}
                </Faded>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(ReadOnlyCarHistoryFileEntry)
)
