import styled from 'styled-components'
import {
    IAnyObject,
    IEntitiesObjArr,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { IInsuranceFieldType } from '../../../../redux/localdata/insuranceLocalData/fieldTypes'
import Expander from '../../../atoms/expander/expander'
import { Error } from '../../editForms/carDescriptionEditForm/carDescriptionEditFormDesktop'
import {
    InsuranceFormQuestion,
    RenderInsuranceQuoteFormFieldDesktop,
} from './renderElementDesktop'
import { InsuranceQuoteSubFormGenerator } from './subGenerator'
import { RenderInsuranceQuoteFormFieldMobile } from './renderElementMobile'
import { InsuranceQuoteSelfReplicatingSubFormGenerator } from './selfReplica/selfReplicatingSubGenerator'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import { capitalizeFirstLetter } from '../../../../helpers/strings/capitalizeFirstLetter'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { useAppSelector } from '../../../../redux/store/hooks'
import LicenseNumber from '../../../atoms/Inputfield/custom/licenseNumber'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    list: IInsuranceFieldType[]
    onAnswerChange: (id: string, answer: any) => any
    data: IAnyObject
    errors?: IAnyObject
    setError: (id: string, error: any) => any
    customLineBottomPadding?: string
    mobileSearchPickerSectionURL?: string
    // for replica
    setEntityArr?: (p: IAnyObject[], fieldID: string) => any
    entitiesObjArr?: IEntitiesObjArr
    entitiesErrNormalised?: IAnyObject
    sectionID?: IInsuranceApplicationSectionId
    isWizard?: boolean
    bg?: string
}

export const InsuranceHeader = styled.div`
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    display: flex;
    align-items: center;
    letter-spacing: 5e-5em;

    color: var(--text-darker, #616161);
    transition: all 300ms;
`

const InsuranceQuoteFormGenerator = (props: Props) => {
    let {
        list,
        onAnswerChange,
        data,
        errors,
        setError,
        customLineBottomPadding,
        mobileSearchPickerSectionURL,
        setEntityArr,
        entitiesObjArr,
        entitiesErrNormalised,
        sectionID,
        bg,
    } = props

    const size: WindowSize = useWindowSize()

    let isMobile = size && size.width && size.width < 880 ? true : false

    let application_draft = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft
    })

    const { theme } = useThemes()
    let application_submitted = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
    })

    let appliIsNotDraft =
        application_submitted?.status === 'DRAFT' ? false : true

    return data ? (
        <div style={{ width: '100%' }}>
            {list.map((item: IInsuranceFieldType, index: number) => {
                let prevItemHadDivider: boolean | undefined =
                    index !== 0 &&
                    list[index - 1] &&
                    list[index - 1].ends_section_with_divider

                let showCustomLicenseNumberField: boolean =
                    item.id === 'license_number' ? true : false

                // hide section header and divider for applications submitted before top field is_uk_resident was added
                // if application is submitted
                // and if top field is_uk_resident
                // and if that top field is undefined

                let hideSectionHeader =
                    appliIsNotDraft &&
                    item.id === 'is_uk_resident' &&
                    data['is_uk_resident'] === undefined
                        ? true
                        : false

                let hideSectionDivider =
                    appliIsNotDraft &&
                    data['is_uk_resident'] === undefined &&
                    (item.id === 'resides_in_the_uk' ||
                        item.id === 'license_date' ||
                        item.id === 'license_type')
                        ? true
                        : false

                return (
                    <div
                        style={{
                            width: '100%',
                            position: 'relative',
                            zIndex: 99 - index,
                        }}
                        key={`index-${item.id}-${index}-${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                        id={`${item.id}-${isMobile}`}
                    >
                        <Expander
                            height={
                                data &&
                                data[item.id] !== undefined &&
                                data[item.id] !== null &&
                                data[item.id] !== '' &&
                                index !== 0 &&
                                item.type !== 'yes_no' &&
                                item.type !== 'contact_medium_choice' &&
                                prevItemHadDivider !== true
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <div
                                style={{
                                    paddingTop: '8px',
                                }}
                            />
                        </Expander>
                        {hideSectionHeader
                            ? null
                            : item.starts_section_with_header && (
                                  <InsuranceHeader
                                      style={{
                                          paddingBottom:
                                              data[item.id] !== undefined
                                                  ? '30px'
                                                  : '20px',
                                      }}
                                  >
                                      {item.starts_section_with_header}
                                  </InsuranceHeader>
                              )}
                        {item.type === 'yes_no' &&
                            index !== 0 &&
                            !prevItemHadDivider && (
                                <div
                                    style={{
                                        paddingTop: '10px',
                                    }}
                                />
                            )}

                        {item.type === 'date' &&
                            index !== 0 &&
                            !prevItemHadDivider && (
                                <div
                                    style={{
                                        paddingTop: '10px',
                                    }}
                                />
                            )}
                        {showCustomLicenseNumberField ? (
                            <>
                                <InsuranceFormQuestion $theme={'light'}>
                                    {item.text}
                                </InsuranceFormQuestion>
                                <div style={{ paddingTop: '16px' }} />
                                <LicenseNumber
                                    isWizard={props.isWizard}
                                    isMobile={isMobile}
                                    onAnswerChange={onAnswerChange}
                                    isDisabled={
                                        application_draft?.status === 'DRAFT'
                                            ? false
                                            : true
                                    }
                                    licenseNumberType={
                                        application_draft?.main_driver
                                            ?.licence_address?.license_type
                                            ?.uid === 'NON_UK'
                                            ? undefined
                                            : application_draft?.main_driver
                                                  ?.licence_address
                                                  ?.license_issue_location
                                                  ?.name === 'Great Britain'
                                            ? 'great_britain'
                                            : application_draft?.main_driver
                                                  ?.licence_address
                                                  ?.license_issue_location
                                                  ?.name === 'Northern Ireland'
                                            ? 'northern_ireland'
                                            : undefined
                                    }
                                    sectionID={sectionID}
                                />
                            </>
                        ) : isMobile ? (
                            <RenderInsuranceQuoteFormFieldMobile
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={(id: string, answer: any) => {
                                    onAnswerChange(id, answer)
                                }}
                                item={item}
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                sectionID={sectionID}
                                bg={bg}
                            />
                        ) : (
                            <RenderInsuranceQuoteFormFieldDesktop
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                item={item}
                                sectionID={sectionID}
                                bg={bg}
                            />
                        )}
                        <Expander
                            height={errors && errors[item.id] ? 'auto' : 0}
                        >
                            <Error
                                $theme={theme}
                                style={{
                                    paddingTop: '8px',
                                    maxWidth: isMobile ? 'auto' : '448px',
                                }}
                            >
                                {errors && errors[item.id]
                                    ? typeof errors[item.id] === 'string'
                                        ? errors[item.id]
                                        : errors[item.id]?.status
                                        ? `${
                                              errors[
                                                  item.id
                                              ].status?.toLowerCase() !==
                                              'invalid'
                                                  ? `${capitalizeFirstLetter(
                                                        errors[item.id].status
                                                    )} - `
                                                  : ''
                                          } ${
                                              errors[item.id]?.message
                                                  ? `${
                                                        errors[item.id]?.message
                                                    }`
                                                  : ''
                                          }`
                                        : item.error_txt
                                        ? item.error_txt
                                        : ''
                                    : ''}
                            </Error>
                        </Expander>
                        <div
                            style={{
                                paddingBottom:
                                    showCustomLicenseNumberField && isMobile
                                        ? 8
                                        : showCustomLicenseNumberField
                                        ? 16
                                        : item.isMobileAnimated
                                        ? 24
                                        : hideSectionHeader
                                        ? 0
                                        : 16,
                            }}
                        />
                        <Expander
                            height={
                                (item.has_subform_if_true &&
                                    data &&
                                    (data[item.id] === true ||
                                        data[item.id]?.name === 'Yes')) ||
                                (item.has_subform_if_false &&
                                    data &&
                                    (data[item.id] === false ||
                                        data[item.id]?.name === 'False')) ||
                                (item.has_subform_if_true &&
                                    item.id === 'ncd_years' &&
                                    data &&
                                    data[item.id] > 0)
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <InsuranceQuoteSubFormGenerator
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                field_id={item.id}
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                sectionID={sectionID}
                            />
                        </Expander>

                        <Expander
                            height={
                                item.has_subform_if_values &&
                                item.subform &&
                                data &&
                                (item.has_subform_if_values.indexOf(
                                    data[item.id]
                                ) !== -1 ||
                                    item.has_subform_if_values.indexOf(
                                        data[item.id]?.name
                                    ) !== -1 ||
                                    item.has_subform_if_values.indexOf(
                                        data[item.id]?.uid
                                    ) !== -1)
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <InsuranceQuoteSubFormGenerator
                                predefined_list={item.subform}
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                field_id={item.id}
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                sectionID={sectionID}
                            />
                        </Expander>

                        <Expander
                            height={
                                item.has_self_replicating_subform_if_true ===
                                    true &&
                                data &&
                                (data[item.id] === true ||
                                    data[item.id]?.name === 'Yes')
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <InsuranceQuoteSelfReplicatingSubFormGenerator
                                data={data}
                                errors={errors}
                                setError={setError}
                                onAnswerChange={onAnswerChange}
                                field_id={item.id}
                                entity_id={item.parent_entity_id}
                                isShown={
                                    item.has_self_replicating_subform_if_true ===
                                        true &&
                                    data &&
                                    (data[item.id] === true ||
                                        data[item.id]?.name === 'Yes')
                                        ? true
                                        : false
                                }
                                mobileSearchPickerSectionURL={
                                    mobileSearchPickerSectionURL
                                }
                                setEntityArr={setEntityArr}
                                entitiesObjArr={entitiesObjArr}
                                entitiesErrNormalised={entitiesErrNormalised}
                                isMobile={isMobile}
                                sectionID={sectionID}
                            />
                        </Expander>
                        {hideSectionDivider
                            ? null
                            : item.ends_section_with_divider === true && (
                                  <div
                                      style={{
                                          marginTop: customLineBottomPadding
                                              ? customLineBottomPadding
                                              : '24px',
                                          marginBottom: customLineBottomPadding
                                              ? customLineBottomPadding
                                              : '40px',
                                          background:
                                              'var(--border-muted, #e5e5e5)',
                                          height: '1px',
                                      }}
                                  />
                              )}
                    </div>
                )
            })}
        </div>
    ) : (
        <div />
    )
}

export default InsuranceQuoteFormGenerator
