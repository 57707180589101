import React from 'react'
import styled, { keyframes } from 'styled-components'
import './style.css'
import useThemes from '../../../providers/theme/hooks'
import { ITheme } from '../../../providers/theme/colours'

export const placeholderShimmer = keyframes`
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
`
export const whiteThemeSkeletonGradient = `linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 20%,
                rgba(255, 255, 255, 0.3) 30%,
        rgba(255, 255, 255, 1) 50%,
                rgba(255, 255, 255, 0.3) 60%,
        rgba(255, 255, 255, 0) 10%
    );`

export const darkThemeSkeletonGradient = `linear-gradient(
        120deg,
        rgba(26, 26, 26, 0) 20%,
                rgba(26, 26, 26, 0.3) 30%,
        rgba(26, 26, 26, 1) 50%,
                rgba(26, 26, 26, 0.3) 60%,
        rgba(26, 26, 26, 0) 10%
    );`

export const SkeletonStyledComp = styled.div<IStyleSkeletonProps>`
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${placeholderShimmer};
    animation-timing-function: linear;
    background: ${(props) =>
        props.$customOpacity
            ? props.$theme === 'dark'
                ? `rgba(26, 26, 26, ${props.$customOpacity})`
                : `rgba(255, 255, 255, ${props.$customOpacity})`
            : props.$theme === 'dark'
            ? 'rgba(26, 26, 26, 0.65)'
            : 'rgba(255, 255, 255, 0.65)'};
    background-image: ${(props) =>
        props.$theme === 'dark'
            ? darkThemeSkeletonGradient
            : whiteThemeSkeletonGradient};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    border-radius: ${(props) => (props.$isRound ? '50%' : '')};
    opacity: ${(props) => (props.$isthingloading ? 1 : 0)};
    transition: all 500ms;
`

interface IStyleSkeletonProps {
    $isthingloading: boolean
    $mobileHeight?: string
    $isRound?: boolean
    $customOpacity?: number
    $theme: ITheme
}
interface ISkeletonProps {
    isthingloading: boolean
    mobileHeight?: string
    isRound?: boolean
    style?: React.CSSProperties | undefined
    customOpacity?: number
}
const ShimmerOverlay: React.FunctionComponent<ISkeletonProps> = (props) => {
    const { theme } = useThemes()
    return (
        <SkeletonStyledComp
            $isthingloading={props.isthingloading}
            $isRound={props.isRound}
            style={props.style}
            $customOpacity={props.customOpacity}
            $theme={theme}
        />
    )
}

export default ShimmerOverlay
