import { IRelativeTimeData, ItimeType } from 'entityModels'
import moment from 'moment'

export function getRelativeTimeData(
    dueDate: Date,
    reminderDate: Date
): IRelativeTimeData | undefined {
    let dueDateM = moment(dueDate).endOf('day').utcOffset(0)
    dueDateM.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    let reminderDateM = moment(reminderDate).endOf('day').utcOffset(0)
    reminderDateM.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    let momentFinalDate = reminderDateM.from(dueDateM, true)

    if (dueDateM.isBefore(moment())) {
        return undefined
    }

    if (reminderDateM.isBefore(moment())) {
        return undefined
    }

    let nValue: number | undefined = undefined
    let timeType: ItimeType | undefined = undefined

    if (momentFinalDate.match(/month/g) !== null) {
        timeType = 'month(s)'
    }

    if (momentFinalDate.match(/week/g) !== null) {
        timeType = 'week(s)'
    }

    if (momentFinalDate.match(/day/g) !== null) {
        timeType = 'day(s)'
    }

    let nReg = /\d+/g
    let matchedN = nReg.exec(momentFinalDate)

    if (matchedN) {
        nValue = parseInt(matchedN[0])
    }

    if (nValue && nValue % 7 === 0 && timeType === 'day(s)') {
        nValue = nValue / 7
        timeType = 'week(s)'
    }

    if (!nValue && timeType !== undefined) {
        nValue = 1
    }

    if (nValue && timeType) {
        return {
            n: nValue,
            timeType: timeType,
        }
    }
}
