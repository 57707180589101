import {
    IPrivateShares_Sort_IDS,
    ICarsObject,
    IExternalDataByRegistrationNumberObject,
    IGalleryImage,
    ISharingPermissionIDS,
    ITasksNormalisedByCar,
    IUpdateCarImagePayload,
    IUpdateCarOverviewGridInfo,
    IEntitySharesOwnedData,
    IDirectShareOwnedItem,
    IShareRecipient,
    IDirectSharesSuccessPayload_redux_arg,
    ISortShareRecipientsDisplay_change_success,
    IUpdateCarDirectShareSuccess,
    IExternalCarsObject,
    IHandover,
    IGetCarActionCheckSuccess,
    ICarInsightsResAPI_category_item,
} from 'entityModels'

import {
    GET_CAR_DATA_SUCCESS,
    WRITE_CAR_DATA_GENERAL_SUCCESS,
    GET_GARAGE_CARS_DATA_SUCCESS,
    SET_CURRENT_CAR_SUCCESS,
    CREATE_CAR_SUCCESS,
    UPDATE_CAR_BANNER_URL_SUCCESS,
    GET_CAR_DATA_BY_ID_SUCCESS,
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_SUCCESS,
    SET_CAR_TASK_IDS_SUCCESS,
    DELETE_CAR_SUCCESS,
    CHANGE_SORT_CAR_SHARE_DISPLAY_SUCCESS,
    CHANGE_FILTER_CAR_SHARE_DISPLAY_SUCCESS,
    RESET_SEARCHQUERY_CAR_SHARE_DISPLAY_SUCCESS,
    CHANGE_SEARCHQUERY_CAR_SHARE_DISPLAY_SUCCESS,
    GET_PUBLIC_SHARES_CAR_SUCCESS,
    GET_PUBLIC_SHARES_ALL_USER_GARAGE_CARS_SUCCESS,
    CREATE_CAR_PUBLIC_SHARE_SUCCESS,
    DELETE_CAR_PUBLIC_SHARE_SUCCESS,
    UPDATE_CAR_OVERVIEW_GRID_INFO,
    UPDATE_CAR_ENTRIES_COUNT,
    CHANGE_SORT_SHARE_RECIPIENTS_DISPLAY_SUCCESS,
    DELETE_FROM_CAR_IMAGES_IDS_ARRAY,
    ADD_TO_CAR_IMAGES_IDS_ARRAY,
    UPDATE_COVER_IMAGE_ID,
    UPDATE_BANNER_IMAGE_URL,
    GET_EXTERNAL_CARS_SHARED_WITH_YOU_SUCCESS,
    SET_CURRENT_EXTERNAL_CAR_SUCCESS,
    POPULATE_SINGLE_CAR_ALL_OWNED_SHARES_SUCCESS,
    GET_OWNED_DIRECT_SHARES_CAR_SUCCESS,
    GET_ALL_CAR_SHARES_SUCCESS,
    CREATE_CAR_DIRECT_SHARE_SUCCESS,
    GET_SINGLE_CAR_DIRECT_SHARE_SUCCESS,
    ADD_SHARE_RECIPIENT_CAR_SUCCESS,
    DELETE_SHARE_RECIPIENT_CAR_SUCCESS,
    DELETE_CAR_DIRECT_SHARE_SUCCESS,
    UPDATE_CAR_DIRECT_SHARE_SUCCESS,
    CLEAN_UP_CAR_DATA_ON_LOGOUT,
    CREATE_CAR_SHARE_INVITE_SUCCESS,
    DELETE_CAR_SHARE_INVITE_SUCCESS,
    RESEND_CAR_SHARE_INVITE_SUCCESS,
    ACCEPT_CAR_SHARE_INVITE_SUCCESS,
    UPDATE_CAR_WITH_SHOWROOM_DATA,
    ARCHIVE_CAR_SUCCESS,
    SET_CAR_SHOWROOM_ENTRY_DELETED,
    CREATE_HANDOVER_SUCCESS,
    DELETE_HANDOVER_SUCCESS,
    UPDATE_CAR_TASKS_ARRAY_ON_TASKS_CREATION_OR_DELETION,
    UPDATE_CAR_TITLE_ON_CREATION,
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_FOR_INSURANCE_SUCCESS,
    GET_LAST_CAR_INSURANCE_ENTRY_SUCCESS,
    REMOVE_NEW_CAR_DUPLICATE,
    GET_CAR_ACTION_CHECK_SUCCESS,
    RESET_CAR_INSIGHTS,
    GET_CAR_INSIGHTS_SUCCESS,
    GET_CATEGORY_INSIGHTS_SUCCESS,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import { IPublicShareSuccessPayloadRedux } from '../../garages/actions/actions'
import { IShareInviteDeletion } from './loadingActions'
import { ITimelineEntryTypes, ITimelineItem } from 'timelineModels'
import { ICarInsightsResAPI } from '../../../../components/organisms/carInsights/body'
import { ITimeRangeEnum } from '../../../../components/atoms/menu/carInsights/timeMenu'

export const getCarDataSuccess = createAction(
    GET_CAR_DATA_SUCCESS,
    function prepare(payload: ICarsObject) {
        return {
            payload,
        }
    }
)

// export const getCarDataByIdSuccess = createAction(
//     GET_CAR_DATA_BY_ID_SUCCESS,
//     (resolve) => (data: ICarsObject) => resolve(data)
// )

export const getCarDataByIdSuccess = createAction(
    GET_CAR_DATA_BY_ID_SUCCESS,
    function prepare(payload: ICarsObject) {
        return {
            payload,
        }
    }
)

// export const writeCarDataGeneralSuccess = createAction(
//     WRITE_CAR_DATA_GENERAL_SUCCESS,
//     (resolve) => (data: ICarsObject) => resolve(data)
// )

export const writeCarDataGeneralSuccess = createAction(
    WRITE_CAR_DATA_GENERAL_SUCCESS,
    function prepare(payload: ICarsObject) {
        return {
            payload,
        }
    }
)

// export const getGarageCarsDataSuccess = createAction(
//     GET_GARAGE_CARS_DATA_SUCCESS,
//     (resolve) => (data: ICarsObject) => resolve(data)
// )

export const getGarageCarsDataSuccess = createAction(
    GET_GARAGE_CARS_DATA_SUCCESS,
    function prepare(payload: ICarsObject) {
        return {
            payload,
        }
    }
)

// export const setCurrentCarSuccess = createAction(
//     SET_CURRENT_CAR_SUCCESS,
//     (resolve) => (carID: string) => resolve(carID)
// )

export const setCurrentCarSuccess = createAction(
    SET_CURRENT_CAR_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

// export const createCarSuccess = createAction(
//     CREATE_CAR_SUCCESS,
//     (resolve) => (data: ICarsObject) => resolve(data)
// )

export const createCarSuccess = createAction(
    CREATE_CAR_SUCCESS,
    function prepare(payload: ICarsObject) {
        return {
            payload,
        }
    }
)

// export const updateCarBannerUrlSuccess = createAction(
//     UPDATE_CAR_BANNER_URL_SUCCESS,
//     (resolve) => (payload: IUpdateCarImagePayload) => resolve(payload)
// )

export const updateCarBannerUrlSuccess = createAction(
    UPDATE_CAR_BANNER_URL_SUCCESS,
    function prepare(payload: IUpdateCarImagePayload) {
        return {
            payload,
        }
    }
)

// export const getExternalCarDataByRegistrationNumberSuccess = createAction(
//     GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_SUCCESS,
//     (resolve) => (payload: IExternalDataByRegistrationNumberObject) =>
//         resolve(payload)
// )

export const getExternalCarDataByRegistrationNumberSuccess = createAction(
    GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_SUCCESS,
    function prepare(payload: IExternalDataByRegistrationNumberObject) {
        return {
            payload,
        }
    }
)

export const getExternalCarDataByRegistrationNumberForInsuranceSuccess =
    createAction(
        GET_EXTERNAL_CAR_DATA_BY_REGISTRATION_NUMBER_FOR_INSURANCE_SUCCESS
    )

// export const setCarTaskIdsSuccess = createAction(
//     SET_CAR_TASK_IDS_SUCCESS,
//     (resolve) => (payload: ITasksNormalisedByCar) => resolve(payload)
// )

export const setCarTaskIdsSuccess = createAction(
    SET_CAR_TASK_IDS_SUCCESS,
    function prepare(payload: ITasksNormalisedByCar) {
        return {
            payload,
        }
    }
)

// export const deleteCarSuccess = createAction(
//     DELETE_CAR_SUCCESS,
//     (resolve) => (carid: string) => resolve(carid)
// )

export const deleteCarSuccess = createAction(
    DELETE_CAR_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

// CAR SHARING DISPLAY

export type ISortCarSharesDisplay_change_success = {
    carid: string
    sortID: IPrivateShares_Sort_IDS
    item_ids_display: string[]
}

export const sortCarSharesDisplay_change_success = createAction(
    CHANGE_SORT_CAR_SHARE_DISPLAY_SUCCESS,
    function prepare(payload: ISortCarSharesDisplay_change_success) {
        return {
            payload,
        }
    }
)

export type IFilterCarSharesDisplay_change_success = {
    carid: string
    filterIDs: ISharingPermissionIDS[]
    item_ids_display: string[]
}

export const filterCarSharesDisplay_change_success = createAction(
    CHANGE_FILTER_CAR_SHARE_DISPLAY_SUCCESS,
    function prepare(payload: IFilterCarSharesDisplay_change_success) {
        return {
            payload,
        }
    }
)

export type ISearchQueryCarSharesDisplay_change_success = {
    carid: string
    searchQuery: string
    item_ids_display: string[]
}

export const searchQueryCarSharesDisplay_change_success = createAction(
    CHANGE_SEARCHQUERY_CAR_SHARE_DISPLAY_SUCCESS,
    function prepare(payload: ISearchQueryCarSharesDisplay_change_success) {
        return {
            payload,
        }
    }
)

export const resetSearchQueryCarShareDisplay_success = createAction(
    RESET_SEARCHQUERY_CAR_SHARE_DISPLAY_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

// SORT SHARE RECIPIENTS

export const sortShareRecipientsDisplay_change_success = createAction(
    CHANGE_SORT_SHARE_RECIPIENTS_DISPLAY_SUCCESS,
    function prepare(payload: ISortShareRecipientsDisplay_change_success) {
        return {
            payload,
        }
    }
)

export const getPublicSharesCarSuccess = createAction(
    GET_PUBLIC_SHARES_CAR_SUCCESS,
    function prepare(payload: IPublicShareSuccessPayloadRedux) {
        return {
            payload,
        }
    }
)

export const getOwnedDirectSharesCarSuccess = createAction(
    GET_OWNED_DIRECT_SHARES_CAR_SUCCESS,
    function prepare(payload: IDirectSharesSuccessPayload_redux_arg) {
        return {
            payload,
        }
    }
)

export const getPublicSharesAllGarageCarsSuccess = createAction(
    GET_PUBLIC_SHARES_ALL_USER_GARAGE_CARS_SUCCESS
)

export const createCarPublicShareSuccess = createAction(
    CREATE_CAR_PUBLIC_SHARE_SUCCESS,
    function prepare(payload: IPublicShareSuccessPayloadRedux) {
        return {
            payload,
        }
    }
)

export type IDeleteShareReq = {
    entity_id: string
    share_id: string
}

export const deleteCarPublicShareSuccess = createAction(
    DELETE_CAR_PUBLIC_SHARE_SUCCESS,
    function prepare(payload: IDeleteShareReq) {
        return {
            payload,
        }
    }
)

export const updatCarOverviewGridInfo = createAction(
    UPDATE_CAR_OVERVIEW_GRID_INFO,
    function prepare(payload: IUpdateCarOverviewGridInfo) {
        return {
            payload,
        }
    }
)

export const updatCarTitleOnCreation = createAction(
    UPDATE_CAR_TITLE_ON_CREATION,
    function prepare(payload: { carid: string; title: string }) {
        return {
            payload,
        }
    }
)

export const updatCarEntriesCount = createAction(
    UPDATE_CAR_ENTRIES_COUNT,
    function prepare(payload: { carid: string; update: 'add' | 'delete' }) {
        return {
            payload,
        }
    }
)

export const deleteFromCarImagesIdsArray = createAction(
    DELETE_FROM_CAR_IMAGES_IDS_ARRAY,
    function prepare(payload: { carid: string; imageid: string }) {
        return {
            payload,
        }
    }
)

export const addToCarImagesIdsArray = createAction(
    ADD_TO_CAR_IMAGES_IDS_ARRAY,
    function prepare(payload: { carid: string; image_ids: string[] }) {
        return {
            payload,
        }
    }
)

export const updateCoverImgId = createAction(
    UPDATE_COVER_IMAGE_ID,
    function prepare(payload: {
        carid: string
        new_cover_id: string | undefined
    }) {
        return {
            payload,
        }
    }
)

export const updateBannerImage = createAction(
    UPDATE_BANNER_IMAGE_URL,
    function prepare(payload: { carid: string; banner_img: IGalleryImage }) {
        return {
            payload,
        }
    }
)

export const getExternalCarsSharedWithYouSuccess = createAction(
    GET_EXTERNAL_CARS_SHARED_WITH_YOU_SUCCESS,
    function prepare(payload: IExternalCarsObject) {
        return {
            payload,
        }
    }
)

export const setCurrentExternalCarSuccess = createAction(
    SET_CURRENT_EXTERNAL_CAR_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export type IEntitySharesOwnedDataArg = {
    owned_shares_obj: IEntitySharesOwnedData
    entity_id: string
}

export const populateSingleCarAllOwnedSharesSuccess = createAction(
    POPULATE_SINGLE_CAR_ALL_OWNED_SHARES_SUCCESS,
    function prepare(payload: IEntitySharesOwnedDataArg) {
        return {
            payload,
        }
    }
)

export const getAllCarSharesSuccess = createAction(GET_ALL_CAR_SHARES_SUCCESS)

export type IDirectShareSuccessPayloadRedux = {
    id: string
    direct_share: IDirectShareOwnedItem
}

export const createCarDirectShareSuccess = createAction(
    CREATE_CAR_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IDirectShareSuccessPayloadRedux) {
        return {
            payload,
        }
    }
)

export const updateCarDirectShareSuccess = createAction(
    UPDATE_CAR_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IUpdateCarDirectShareSuccess) {
        return {
            payload,
        }
    }
)

export const getSingleCarDirectShareSuccess = createAction(
    GET_SINGLE_CAR_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IDirectShareSuccessPayloadRedux) {
        return {
            payload,
        }
    }
)

export type IShareRecipientRequest = {
    share_id: string
    entity_id: string
    share_recipient: IShareRecipient
}

export const addCarShareRecipientSuccess = createAction(
    ADD_SHARE_RECIPIENT_CAR_SUCCESS
)

export type IShareRecipientRequestDeletion_success = {
    share_id: string
    entity_id: string
    recipient_id: string
}

export const deleteCarShareRecipientSuccess = createAction(
    DELETE_SHARE_RECIPIENT_CAR_SUCCESS,
    function prepare(payload: IShareRecipientRequestDeletion_success) {
        return {
            payload,
        }
    }
)

export const deleteDirectCarShareSuccess = createAction(
    DELETE_CAR_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IDeleteShareReq) {
        return {
            payload,
        }
    }
)

export const cleanUpCarDataOnLogoutSuccess = createAction(
    CLEAN_UP_CAR_DATA_ON_LOGOUT
)

export const createCarShareInviteSuccess = createAction(
    CREATE_CAR_SHARE_INVITE_SUCCESS
)

export const deleteCarShareInviteSuccess = createAction(
    DELETE_CAR_SHARE_INVITE_SUCCESS,
    function prepare(payload: IShareInviteDeletion) {
        return {
            payload,
        }
    }
)

export const resendCarShareInviteSuccess = createAction(
    RESEND_CAR_SHARE_INVITE_SUCCESS
)

export const acceptCarShareInviteSuccess = createAction(
    ACCEPT_CAR_SHARE_INVITE_SUCCESS
)

export const updateCarWithShowroomData = createAction(
    UPDATE_CAR_WITH_SHOWROOM_DATA,
    function prepare(payload: {
        carid: string
        showroom_uid: string
        type_of_sale: string
        state: string
    }) {
        return {
            payload,
        }
    }
)

export const archiveCarSuccess = createAction(ARCHIVE_CAR_SUCCESS)

export const setCarShowroomEntryDeleted = createAction(
    SET_CAR_SHOWROOM_ENTRY_DELETED,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export type IcreateHandoverSuccessArg = {
    car_id: string
    handover: IHandover
}

export const createHandoverSuccess = createAction(
    CREATE_HANDOVER_SUCCESS,
    function prepare(payload: IcreateHandoverSuccessArg) {
        return {
            payload,
        }
    }
)

export const deleteHandoverSuccess = createAction(
    DELETE_HANDOVER_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const updateCarTasksArrayOnTasksCreationOrDeletion = createAction(
    UPDATE_CAR_TASKS_ARRAY_ON_TASKS_CREATION_OR_DELETION,
    function prepare(payload: { car_id: string; tasks_ids: string[] }) {
        return {
            payload,
        }
    }
)

export type IGetLastCarInsuranceEntrySuccess = {
    carid: string
    timelineItem: ITimelineItem | null | undefined
}

export const getLastCarInsuranceEntrySuccess = createAction(
    GET_LAST_CAR_INSURANCE_ENTRY_SUCCESS,
    function prepare(payload: IGetLastCarInsuranceEntrySuccess) {
        return {
            payload,
        }
    }
)

export const removeNewCarDuplicate = createAction(REMOVE_NEW_CAR_DUPLICATE)

export const getCarActionCheckSuccess = createAction(
    GET_CAR_ACTION_CHECK_SUCCESS,
    function prepare(payload: IGetCarActionCheckSuccess) {
        return {
            payload,
        }
    }
)

export const resetCarInsights = createAction(
    RESET_CAR_INSIGHTS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export type IGetCarInsightsSuccess = {
    carid: string
    data: ICarInsightsResAPI
    timeRange: ITimeRangeEnum
}

export const getCarInsightsSuccess = createAction(
    GET_CAR_INSIGHTS_SUCCESS,
    function prepare(payload: IGetCarInsightsSuccess) {
        return {
            payload,
        }
    }
)
export type IGetCarCategoryInsightsSuccess = {
    carid: string
    data: ICarInsightsResAPI_category_item[] | undefined
    timeRange: ITimeRangeEnum
    categoryID: ITimelineEntryTypes | string
    representation: 'monthly' | 'yearly'
}

export const getCategoryInsightsSuccess = createAction(
    GET_CATEGORY_INSIGHTS_SUCCESS,
    function prepare(payload: IGetCarCategoryInsightsSuccess) {
        return {
            payload,
        }
    }
)
