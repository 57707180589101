import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
// import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
// import SelectSearchBarCarInfoMobile from './selectSearchBarCarInfoMobile'
import BlueBorderSelectInput from '../../atoms/blueBorderedInputs/blueBorderSelectInput'
import blue_cross from '../../../public/assets/icons/blue_cross.svg'
import blue_bin from '../../../public/assets/icons/blue_bin.svg'
import { motion } from 'framer-motion'

import { IdropdownObject } from '../../../redux/localdata/remindersLocalData'

const WrapperMobile = styled.section`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const InputWrapper = styled.section`
    width: 85%;
`

const RemovalIcon = styled(motion.img)<IStyle>`
    height: ${(props) => (props.variant === 'mobile' ? '15px' : '18px')};
    cursor: pointer;
`

interface IStyle {
    variant: string
}

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    selected_value: string
}

interface OtherProps {
    item?: any
    dataCyId?: string
    onCrossClick?: any
    variant: 'mobile' | 'desktop'
    optionsIDsList: string[]
    optionsData: IdropdownObject
    customHandleChange?: any
    isDisabled?: boolean
    noChangeAllowed?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,

        // data,
        // errors,
        setFieldValue,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        onCrossClick,
        optionsData,
        optionsIDsList,
        variant,
        customHandleChange,
        isDisabled,
        noChangeAllowed,
    } = props

    const handleAnswerChange = (name: any) => {
        setFieldValue('selected_value', name)
        customHandleChange && customHandleChange(name)
    }

    return (
        <WrapperMobile>
            <InputWrapper
                onClick={(e: any) => {
                    noChangeAllowed && e.preventDefault()
                }}
            >
                <BlueBorderSelectInput
                    optionsIDsList={optionsIDsList}
                    optionsData={optionsData}
                    handleChange={handleAnswerChange}
                    value={values.selected_value}
                    placeholder={'Reminder date'}
                    isDisabled={isDisabled}
                    bg={
                        variant === 'mobile'
                            ? undefined
                            : 'var(--modal-side-sheet-pop-over-bg, #fff)'
                    }
                />
            </InputWrapper>
            <RemovalIcon
                variant={variant}
                src={variant === 'mobile' ? blue_cross : blue_bin}
                onClick={onCrossClick}
                whileTap={{ scale: 1.1 }}
            />
        </WrapperMobile>
    )
}

const SelectWithRemovalIconMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        selected_value: !props.item
            ? undefined
            : props.item.value_start
            ? props.item.value_start
            : props.item.type ?? undefined,
    }),
    enableReinitialize: true,
    // validationSchema: {},
    // validationSchema: (props: OtherProps) => {
    //     return Yup.lazy(() => {
    //         return Yup.object().shape({
    //             selected_value: Yup.string()
    //                 .max(20, 'Your answer must be under 20 characters')
    //                 .min(2),
    //         })
    //     })
    // },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(SelectWithRemovalIconMobile)
