import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import VehicleItemDesktop from '../../../../atoms/list/vehicleItemDesktop'
import styled from 'styled-components'
// import IconAndTextNakedV2 from '../../../../atoms/Button/iconAndTextNakedV2'
import {
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import VehicleItemMobile from '../../../../atoms/list/vehicleItemMobile'
import { device } from '../../../../templates/displays/devices'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'
import LandingPageMainButton from '../../../../atoms/Button/landingPageMainButton'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

const Text = styled.div`
    color: var(--text-default, #666);
    /* Mobile/Text/T300 Regular */
    font-size: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.001px;
    width: 100%;
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
    }
`

const InsuranceApplicationVehiclesToInsure = () => {
    const history = useHistory()

    const dispatch = useAppDispatch()

    let carsData = useAppSelector((state) => state.entities.carsData.cars)

    let selectedVehiclesToInsure = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.draft.vehicles_to_insure_ids_list
    )

    const { id } = useParams<{ id?: string }>()

    let { getUserAndApplicationData } = useSetInitialInsurance()
    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let cleanedUpCarIds: string[] | undefined =
        carsData &&
        Object.keys(carsData).filter((carid) => {
            if (
                carsData[carid].archived_on === undefined &&
                carid !== 'new_car'
            )
                return carid
        })

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Vehicles to insure' },
                    ]}
                    sectionId="vehicles_to_insure"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '448px',
                            width: '50%',
                            maxWidth: 800,
                        }}
                    >
                        {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                            <Text>
                                Choose the cars from your garage that you would
                                like to insure. Make your selections below and
                                let's get started! You can edit or fill in the
                                details once the vehicle(s) is selected.
                            </Text>
                        ) : (
                            <Text>
                                <Text>
                                    Before insuring a vehicle, please make sure
                                    it's added to your garage. Your quote
                                    progress will be saved, and you can easily
                                    continue the quote flow afterward.
                                </Text>
                            </Text>
                        )}
                        <div style={{ paddingTop: 40 }} />
                        {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                            cleanedUpCarIds.map((car_id, i) => {
                                if (
                                    carsData[car_id].archived_on === undefined
                                ) {
                                    return (
                                        <div
                                            style={{
                                                width: '100%',
                                                paddingBottom: 16,
                                            }}
                                            key={`${car_id}_${i}_desktop`}
                                        >
                                            <VehicleItemDesktop
                                                sectionId="vehicles_to_insure"
                                                garageItem={carsData[car_id]}
                                                showGarageCarTitle
                                                car_id={car_id}
                                                variant="selectable"
                                                isSelected={
                                                    selectedVehiclesToInsure.includes(
                                                        car_id
                                                    )
                                                        ? true
                                                        : false
                                                }
                                                onSelect={(id: string) => {
                                                    let selected_ids =
                                                        selectedVehiclesToInsure
                                                            ? selectedVehiclesToInsure.includes(
                                                                  id
                                                              )
                                                                ? selectedVehiclesToInsure.filter(
                                                                      (id) =>
                                                                          car_id !==
                                                                          id
                                                                  )
                                                                : [
                                                                      ...selectedVehiclesToInsure,
                                                                      id,
                                                                  ]
                                                            : [id]
                                                    dispatch(
                                                        insuranceActions.select_vehicles_to_insure(
                                                            selected_ids
                                                        )
                                                    )
                                                }}
                                            />
                                        </div>
                                    )
                                } else return null
                            })
                        ) : (
                            <LandingPageMainButton
                                onClick={() => {
                                    history.push('/garage')
                                }}
                                normal_height="48px"
                                desktop_height="48px"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        textTransform: 'none',
                                        fontFamily: 'Lato',
                                        letterSpacing: 'normal',
                                    }}
                                >
                                    Add car to your garage
                                </div>
                            </LandingPageMainButton>
                        )}

                        {/* <Line /> */}
                        {/* <Text>
                            Don’t want to keep you everyday vehicle in your
                            Custodian garage? Add its details here for the
                            policy evaluation only.
                        </Text>
                        <div style={{ paddingTop: 16 }} /> */}
                        {/* {everydayCarIds ? (
                            <VehicleItemDesktop
                                item={carsData[everydayCarId]}
                                variant="everyday_car_editable"
                                onEdit={() =>
                                    history.push(
                                        `/insurance/application/car/${everydayCarId}`
                                    )
                                }
                                removeAsEverydayCar={() => {
                                    if (everydayCarId)
                                        dispatch(
                                            insuranceActions.set_everyday_car_id(
                                                everydayCarId
                                            )
                                        )
                                }}
                            />
                        ) : (
                            <IconAndTextNakedV2
                                onClick={() => {}}
                                text="Add everyday vehicle"
                            />
                        )} */}
                    </div>
                </div>
                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    onSave={() => {
                        dispatch(
                            insuranceActions.submit_vehiclesToInsure_request()
                        )
                        history.push(`/insurance/application/${id}`)
                    }}
                    autoFormSaveCopy
                    goBack={() => history.push(`/insurance/application/${id}`)}
                    sectionId="vehicles_to_insure"
                />
            </DesktopDisplayOnly>

            <IpadAndMobileDisplay style={{ width: '100%' }}>
                <InsuranceTopBarWithProgressionRateMobile
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Vehicles to insure' },
                    ]}
                    sectionId="vehicles_to_insure"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                        paddingLeft: 16,
                        paddingRight: 16,
                    }}
                >
                    {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                        <Text>
                            Choose the cars from your garage that you would like
                            to insure. Make your selections below and let's get
                            started! You can edit or fill in the details once
                            the vehicle(s) is selected.
                        </Text>
                    ) : (
                        <Text>
                            Before insuring a vehicle, please make sure it's
                            added to your garage. Your quote progress will be
                            saved, and you can easily continue the quote flow
                            afterward.
                        </Text>
                    )}
                    <div style={{ paddingTop: 40 }} />
                    {cleanedUpCarIds && cleanedUpCarIds.length > 0 ? (
                        cleanedUpCarIds.map((car_id, i) => (
                            <div
                                style={{ width: '100%', paddingBottom: 16 }}
                                key={`${car_id}_${i}_mobile`}
                            >
                                <VehicleItemMobile
                                    sectionId="vehicles_to_insure"
                                    garageItem={carsData[car_id]}
                                    variant="selectable"
                                    showGarageCarTitle
                                    isSelected={
                                        selectedVehiclesToInsure.includes(
                                            car_id
                                        )
                                            ? true
                                            : false
                                    }
                                    car_id={car_id}
                                    onSelect={(id: string) => {
                                        let selected_ids =
                                            selectedVehiclesToInsure
                                                ? selectedVehiclesToInsure.includes(
                                                      id
                                                  )
                                                    ? selectedVehiclesToInsure.filter(
                                                          (id) => car_id !== id
                                                      )
                                                    : [
                                                          ...selectedVehiclesToInsure,
                                                          id,
                                                      ]
                                                : [id]
                                        dispatch(
                                            insuranceActions.select_vehicles_to_insure(
                                                selected_ids
                                            )
                                        )
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <LandingPageMainButton
                            onClick={() => {
                                history.push('/garage')
                            }}
                            normal_height="48px"
                            desktop_height="48px"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textTransform: 'none',
                                    fontFamily: 'Lato',
                                    letterSpacing: 'normal',
                                }}
                            >
                                Add car to your garage
                            </div>
                        </LandingPageMainButton>
                    )}
                    {/* <Line />
                    <Text>
                        Don’t want to keep you everyday vehicle in your
                        Custodian garage? Add its details here for the policy
                        evaluation only.
                    </Text> */}
                    {/* <div style={{ paddingTop: 16, width: '100%' }}>
                        {everydayCarId ? (
                            <VehicleItemMobile
                                item={carsData[everydayCarId]}
                                variant="everyday_car_editable"
                                onEdit={() =>
                                    history.push(
                                        `/insurance/application/car/${everydayCarId}`
                                    )
                                }
                                removeAsEverydayCar={() => {
                                    if (everydayCarId)
                                        dispatch(
                                            insuranceActions.set_everyday_car_id(
                                                everydayCarId
                                            )
                                        )
                                }}
                            />
                        ) : (
                            <IconAndTextNakedV2
                                onClick={() => {}}
                                text="Add everyday vehicle"
                            />
                        )}
                    </div> */}
                </div>
                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    onSave={() => {
                        dispatch(
                            insuranceActions.submit_vehiclesToInsure_request()
                        )
                        history.push(`/insurance/application/${id}`)
                    }}
                    isMobile
                    goBack={() => history.push(`/insurance/application/${id}`)}
                    sectionId="vehicles_to_insure"
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationVehiclesToInsure
