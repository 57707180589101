// NOTE
// DO NOT USE dynamic string operations(like template string) as action type property.
// see more details: https://github.com/piotrwitek/typesafe-actions#--the-actions
// login
export const CREATE_PROPOSAL_CODE_REQUEST_REQUEST =
  "@@createProposalCodeRequest/REQUEST";
export const CREATE_PROPOSAL_CODE_REQUEST_SUCCESS =
  "@@createProposalCodeRequest/SUCCESS";
export const CREATE_PROPOSAL_CODE_REQUEST_ERROR =
  "@@createProposalCodeRequest/ERROR";
// register
export const REDEEM_CODE_REQUEST = "@@redeemCode/REQUEST";
export const REDEEM_CODE_SUCCESS = "@@redeemCode/SUCCESS";
export const REDEEM_CODE_ERROR = "@@redeemCode/ERROR";
