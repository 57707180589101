import * as React from 'react'
import styled from 'styled-components'

interface IStyled {
    someProp?: any
    $mobile?: boolean
}

const Wrapper = styled.div<IStyled>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${(props) => (props.$mobile ? 'flex-end' : 'flex-start')};
    gap: 24px;
`

const Title = styled.div<IStyled>`
    font-family: 'Lato-semibold';
    font-size: 14px;
    text-transform: capitalize;
    color: var(--text-muted);
`

const Amount = styled.div<IStyled>`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-semibold';
    font-size: 24px;
`

const HorizontalLine = styled.div<IStyled>`
    width: 100%;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

interface Props {
    amount: string
    unit?: string | undefined
    dataCyId?: string
    mobile?: boolean
}

const TotalCost: React.FC<Props> = (props) => (
    <Wrapper $mobile={props.mobile}>
        <HorizontalLine />
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
            }}
        >
            <Title $mobile={props.mobile}>Total Amount</Title>
            <Amount $mobile={props.mobile}>
                {props.unit
                    ? props.unit === 'GBP'
                        ? '£'
                        : props.unit === 'USD'
                        ? '$'
                        : props.unit === 'EUR'
                        ? '€'
                        : '£'
                    : '£'}
                {props.amount}
            </Amount>
        </div>
    </Wrapper>
)

export default TotalCost
