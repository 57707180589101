import { enumToNameFunc } from '../../../../helpers/strings/enumToName'
import {
    IAnyObject,
    IInsuranceQuoteApplication_OtherDriver,
    IInsuranceQuoteApplication_OtherDriverObj,
} from '../../../insuranceQuoteApplication/reducer'
import {
    IClaim_API,
    IInsuranceConviction_API,
    IInsuranceDriver_API,
    IInsuranceApplication_API,
    IInsuranceDisability_API,
    IInsuranceMotoringConviction_API,
    IInsuranceDriver_APIErrors,
    IInsuranceDisability_APIErrors,
    IClaim_APIErrors,
    IInsuranceConviction_APIErrors,
    IInsuranceMotoringConviction_APIErrors,
    IInsuranceApplication_APIErrors,
    InsuranceCustomer_APIErrors,
} from '../../../insuranceQuoteApplication/types'

import {
    disability_self_replicating_fields,
    IInsuranceFieldType,
    insurance_fields_other_driver_additional_details,
    insurance_fields_other_driver_basic_details,
    insurance_fields_other_driver_track_record,
    insurance_sub_fields_other_driver_track_record_conviction_replicating,
    insurance_sub_fields_other_driver_track_record_motorconviction_replicating,
    other_driver_is_uk_resident_positive_answer_subform,
    other_driver_is_uk_resident_since_birth_negative_answer_subform,
    other_driver_resides_in_the_uk_negative_answer_subform,
    self_replicating_claim,
} from '../../../localdata/insuranceLocalData/fieldTypes'
import {
    additionalDetailsSubfields,
    additionalSubFieldsClaims,
    additionalSubFieldsConvictions,
    additionalSubFieldsDisabilities,
    additionalSubFieldsMotoringConvictionsStrings,
    additionalSubFieldsTrackRecord,
    is_price_insurance_fields,
} from './customer'
import { IDriveListNormalised } from './drive_list'
import { getInsuranceFEValue } from './helpers'

const license_address = [
    'license_number',
    'license_date',
    'license_type',
    'is_uk_resident',
    'resides_in_the_uk',
    // addresses : only put uid
    'residential_address',
    'correspondence_address',
]

const other_driver_residency_additional_q: IInsuranceFieldType[] = [
    ...other_driver_is_uk_resident_positive_answer_subform,
    ...other_driver_is_uk_resident_since_birth_negative_answer_subform,
    ...other_driver_resides_in_the_uk_negative_answer_subform,
]
// frequency will be filled in the convs for main / other driver

const err_track_rec = [
    ...additionalSubFieldsTrackRecord,
    ...insurance_fields_other_driver_track_record,
]
const convertNamedDriverToOtherDriver = (
    namedDriver: IInsuranceDriver_API,
    normalisedDriveList: IDriveListNormalised
): IInsuranceQuoteApplication_OtherDriver => {
    let claims_api: IClaim_API[] = namedDriver.claims ?? []
    let disability_api: IClaim_API[] = namedDriver.disabilities ?? []
    let conviction_api: IInsuranceConviction_API[] =
        namedDriver.convictions ?? []
    let motoring_conviction_api: IInsuranceConviction_API[] =
        namedDriver.motoring_convictions ?? []

    let res: IInsuranceQuoteApplication_OtherDriver = {
        id: namedDriver.uid,
        completion: 0, // to be calculated below
        basic_details: {},
        basic_details_errors: undefined,
        licence_address: {},
        licence_address_errors: undefined,
        additional_details: {},
        additional_details_errors: undefined,
        track_record: {},
        track_record_errors: undefined,
        drive_list:
            normalisedDriveList && normalisedDriveList[`${namedDriver.uid}`]
                ? normalisedDriveList[`${namedDriver.uid}`]
                : [],
        entities: {
            memberships: [],
            claims: [],
            disabilities: [],
            convictions: [],
            motoring_convictions: [],
        },
    }

    //  basic details section
    for (
        let i = 0;
        i < insurance_fields_other_driver_basic_details.length;
        i++
    ) {
        let field_id = insurance_fields_other_driver_basic_details[i].id
        if (
            insurance_fields_other_driver_basic_details[i].id ===
            'relation_to_customer'
        ) {
            //@ts-ignore
            res.basic_details[field_id] = getInsuranceFEValue(
                insurance_fields_other_driver_basic_details[i],
                // @ts-ignore
                namedDriver[field_id]
            )
        } else {
            //@ts-ignore
            res.basic_details[field_id] = getInsuranceFEValue(
                insurance_fields_other_driver_basic_details[i],
                // @ts-ignore
                namedDriver[field_id]
            )
        }
    }

    //  licence address section
    for (let i = 0; i < license_address.length; i++) {
        if (
            license_address[i] === 'residential_address' ||
            license_address[i] === 'correspondence_address'
        ) {
            res.licence_address = {
                ...res.licence_address,
                [license_address[i]]:
                    // @ts-ignore
                    namedDriver[license_address[i]]?.uid,
            }
        } else {
            res.licence_address = {
                ...res.licence_address,
                //@ts-ignore
                [license_address[i]]: namedDriver[license_address[i]],
            }
        }
    }

    //  residency section
    for (let a = 0; a < other_driver_residency_additional_q.length; a++) {
        let field_id = other_driver_residency_additional_q[a].id
        res.licence_address = {
            ...res.licence_address,
            [field_id]: getInsuranceFEValue(
                other_driver_residency_additional_q[a],
                // @ts-ignore
                namedDriver[field_id]
            ),
        }
    }

    //  additional details section
    for (
        let i = 0;
        i < insurance_fields_other_driver_additional_details.length;
        i++
    ) {
        let field_id = insurance_fields_other_driver_additional_details[i].id

        res.additional_details = {
            ...res.additional_details,
            [field_id]: getInsuranceFEValue(
                insurance_fields_other_driver_additional_details[i],
                // @ts-ignore
                namedDriver[field_id]
            ),
        }
    }

    for (let i = 0; i < additionalDetailsSubfields.length; i++) {
        let field_id = additionalDetailsSubfields[i].id
        res.additional_details = {
            ...res.additional_details,
            [field_id]: getInsuranceFEValue(
                additionalDetailsSubfields[i],
                // @ts-ignore
                namedDriver[field_id]
            ),
        }
    }

    //  basic details section
    for (let i = 0; i < err_track_rec.length; i++) {
        let field_id = err_track_rec[i].id
        res.track_record = {
            ...res.track_record,
            // @ts-ignore
            [field_id]: namedDriver[field_id],
        }
    }

    for (let i = 0; i < additionalSubFieldsTrackRecord.length; i++) {
        let field_id = additionalSubFieldsTrackRecord[i].id
        res.track_record = {
            ...res.track_record,
            [field_id]: getInsuranceFEValue(
                additionalSubFieldsTrackRecord[i],
                // @ts-ignore
                namedDriver[field_id]
            ),
        }
    }

    if (disability_api && disability_api.length > 0) {
        let entityArr: IAnyObject[] = []

        for (let i = 0; i < disability_api.length; i++) {
            let disabilityObjApi: IInsuranceDisability_API = disability_api[i]
            let entityObj: IAnyObject = {
                uid: disability_api[i]?.uid,
            }
            disability_self_replicating_fields.forEach(
                (item: IInsuranceFieldType) => {
                    entityObj = {
                        ...entityObj,
                        [`${item.id}`]: getInsuranceFEValue(
                            item,
                            // @ts-ignore
                            disabilityObjApi[`${item.id}`]
                        ),
                    }
                }
            )

            additionalSubFieldsDisabilities.forEach(
                (item: IInsuranceFieldType) => {
                    // @ts-ignore
                    if (disabilityObjApi[item.id]) {
                        entityObj = {
                            ...entityObj,
                            [`${item.id}`]: getInsuranceFEValue(
                                item,
                                // @ts-ignore
                                disabilityObjApi[item.id]
                            ),
                        }
                    }
                }
            )
            entityArr = [...entityArr, { ...entityObj }]
        }

        res.entities.disabilities = [...entityArr]
    }

    // later this is where completion is calculated

    if (claims_api && claims_api.length > 0) {
        let entityArr: IAnyObject[] = []

        for (let i = 0; i < claims_api.length; i++) {
            let claimAPI: IClaim_API = claims_api[i]
            let entityObj: IAnyObject = {
                uid: claimAPI.uid,
            }
            self_replicating_claim.forEach((item: IInsuranceFieldType) => {
                const valuee = getInsuranceFEValue(
                    item,
                    // @ts-ignore
                    claimAPI[`${item.id}`]
                )
                entityObj = { ...entityObj, [`${item.id}`]: valuee }
            })

            additionalSubFieldsClaims.forEach((item: IInsuranceFieldType) => {
                // @ts-ignore
                if (claimAPI[item.id]) {
                    entityObj = {
                        ...entityObj,
                        // @ts-ignore
                        [`${item.id}`]: getInsuranceFEValue(
                            item,
                            // @ts-ignore
                            claimAPI[item.id]
                        ),
                    }
                }
            })

            entityArr = [...entityArr, { ...entityObj }]
        }

        res.entities.claims = [...entityArr]
    }

    if (conviction_api && conviction_api.length > 0) {
        let entityArr: IAnyObject[] = []

        for (let i = 0; i < conviction_api.length; i++) {
            let convictionApiObj: IInsuranceConviction_API = conviction_api[i]
            let entityObj: IAnyObject = { uid: conviction_api[i]?.uid }
            insurance_sub_fields_other_driver_track_record_conviction_replicating.forEach(
                (item: IInsuranceFieldType) => {
                    entityObj = {
                        ...entityObj,
                        [`${item.id}`]: getInsuranceFEValue(
                            item,
                            // @ts-ignore
                            convictionApiObj[`${item.id}`]
                        ),
                    }
                }
            )

            additionalSubFieldsConvictions.forEach((subfieldID) => {
                // @ts-ignore
                if (convictionApiObj[subfieldID]) {
                    entityObj = {
                        ...entityObj,
                        // @ts-ignore
                        [`${subfieldID}`]: convictionApiObj[`${subfieldID}`],
                    }
                }
            })
            entityArr = [...entityArr, { ...entityObj }]
        }

        res.entities.convictions = [...entityArr]
    }

    if (motoring_conviction_api && motoring_conviction_api.length > 0) {
        let entityArr: IAnyObject[] = []

        for (let i = 0; i < motoring_conviction_api.length; i++) {
            let motoringConvictionObjApi: IInsuranceMotoringConviction_API =
                motoring_conviction_api[i]

            let entityObj: IAnyObject = {
                uid: motoringConvictionObjApi.uid,
            }
            insurance_sub_fields_other_driver_track_record_motorconviction_replicating.forEach(
                (item: IInsuranceFieldType) => {
                    const valuee = getInsuranceFEValue(
                        item,
                        // @ts-ignore
                        motoringConvictionObjApi[`${item.id}`]
                    )
                    entityObj = { ...entityObj, [`${item.id}`]: valuee }
                }
            )

            additionalSubFieldsConvictions.forEach((subFieldID) => {
                // @ts-ignore
                if (motoringConvictionObjApi[subFieldID]) {
                    entityObj = {
                        ...entityObj,
                        // @ts-ignore
                        [`${subFieldID}`]: motoringConvictionObjApi[subFieldID],
                    }
                }
            })

            additionalSubFieldsMotoringConvictionsStrings.forEach(
                (subFieldID) => {
                    let is_price =
                        is_price_insurance_fields.indexOf(subFieldID) === -1
                            ? false
                            : true

                    // @ts-ignore
                    if (motoringConvictionObjApi[subFieldID]) {
                        entityObj = {
                            ...entityObj,
                            [`${subFieldID}`]: is_price
                                ? // @ts-ignore
                                  +motoringConvictionObjApi[subFieldID] / 100
                                : // @ts-ignore
                                  motoringConvictionObjApi[subFieldID],
                        }
                    }
                }
            )

            entityArr = [...entityArr, { ...entityObj }]
        }

        res.entities.motoring_convictions = [...entityArr]
    }
    return res
}

export const convertnamedDriversToReducerOtherDriversNormalised = (
    application: IInsuranceApplication_API,
    normalisedDriveList: IDriveListNormalised
): IInsuranceQuoteApplication_OtherDriverObj => {
    let other_drivers: IInsuranceDriver_API[] | undefined =
        application.customer?.named_drivers
    let res: IInsuranceQuoteApplication_OtherDriverObj = {}

    if (other_drivers) {
        other_drivers.forEach((namedDriver: IInsuranceDriver_API) => {
            res[`${namedDriver.uid}`] = {
                ...convertNamedDriverToOtherDriver(
                    namedDriver ?? {},
                    normalisedDriveList
                ),
            }
        })
    }

    return res
}

const convertNamedDriverToOtherDriverErrors = (
    namedDriver: IInsuranceDriver_APIErrors,
    normalisedDriveList: IDriveListNormalised
): IInsuranceQuoteApplication_OtherDriver => {
    let claims_api: IClaim_APIErrors[] = namedDriver.claims ?? []
    let disability_api: IInsuranceDisability_APIErrors[] =
        namedDriver.disabilities ?? []
    let conviction_api: IInsuranceConviction_APIErrors[] =
        namedDriver.convictions ?? []
    let motoring_conviction_api: IInsuranceConviction_APIErrors[] =
        namedDriver.motoring_convictions ?? []

    let res: IInsuranceQuoteApplication_OtherDriver = {
        id: namedDriver.uid as string,
        completion: 0, // to be calculated below
        basic_details: {},
        basic_details_errors: undefined,
        licence_address: {},
        licence_address_errors: undefined,
        additional_details: {},
        additional_details_errors: undefined,
        track_record: {},
        track_record_errors: undefined,

        drive_list_errors: null,

        drive_list:
            normalisedDriveList && normalisedDriveList[`${namedDriver.uid}`]
                ? normalisedDriveList[`${namedDriver.uid}`]
                : [],
        entities: {
            memberships: [],
            claims: [],
            disabilities: [],
            convictions: [],
            motoring_convictions: [],
        },

        entities_error: {},
    }

    //  basic details section
    for (
        let i = 0;
        i < insurance_fields_other_driver_basic_details.length;
        i++
    ) {
        let field_id = insurance_fields_other_driver_basic_details[i].id
        if (field_id === 'relation_to_customer') {
            res.basic_details_errors = res.basic_details_errors
                ? {
                      ...res.basic_details_errors, //@ts-ignore
                      [field_id]: namedDriver[field_id]
                          ? {
                                //@ts-ignore
                                uid: `${namedDriver[field_id]}`,

                                name: enumToNameFunc(
                                    //@ts-ignore
                                    `${namedDriver[field_id]}`
                                ),
                            }
                          : undefined,
                  }
                : {
                      //@ts-ignore
                      [basic_details[i]]: namedDriver[field_id]
                          ? {
                                //@ts-ignore
                                uid: `${namedDriver[field_id]}`,

                                name: enumToNameFunc(
                                    //@ts-ignore
                                    `${namedDriver[field_id]}`
                                ),
                            }
                          : undefined,
                  }
        } else {
            //@ts-ignore
            res.basic_details_errors = res.basic_details_errors
                ? {
                      ...res.basic_details_errors,
                      [field_id]:
                          //@ts-ignore
                          namedDriver[field_id],
                  }
                : {
                      [field_id]:
                          //@ts-ignore
                          namedDriver[field_id],
                  }
        }
    }

    //  licence address section

    let license_address_error_ids = [
        ...license_address,
        ...other_driver_residency_additional_q.map((field) => field.id),
    ]

    for (let i = 0; i < license_address_error_ids.length; i++) {
        if (
            license_address_error_ids[i] === 'residential_address' ||
            license_address_error_ids[i] === 'correspondence_address'
        ) {
            res.licence_address_errors = res.licence_address_errors
                ? {
                      ...res.licence_address_errors,
                      [license_address_error_ids[i]]:
                          // @ts-ignore
                          namedDriver[license_address_error_ids[i]]?.uid,
                  }
                : {
                      [license_address_error_ids[i]]:
                          // @ts-ignore
                          namedDriver[license_address_error_ids[i]]?.uid,
                  }
        } else {
            res.licence_address_errors = res.licence_address_errors
                ? {
                      ...res.licence_address_errors,

                      [license_address_error_ids[i]]:
                          //@ts-ignore
                          namedDriver[license_address_error_ids[i]],
                  }
                : {
                      [license_address_error_ids[i]]:
                          //@ts-ignore
                          namedDriver[license_address_error_ids[i]],
                  }
        }
    }

    // below seems like it should be deleted as additional details is already above
    // //  additional details section
    for (
        let i = 0;
        i < insurance_fields_other_driver_additional_details.length;
        i++
    ) {
        let field_id = insurance_fields_other_driver_additional_details[i].id
        res.additional_details_errors = res.additional_details_errors
            ? {
                  ...res.additional_details_errors,

                  //@ts-ignore
                  [field_id]:
                      //@ts-ignore
                      namedDriver[field_id],
              }
            : {
                  //@ts-ignore
                  [field_id]:
                      //@ts-ignore
                      namedDriver[field_id],
              }
    }

    for (let i = 0; i < additionalDetailsSubfields.length; i++) {
        let field_id = additionalDetailsSubfields[i].id
        res.additional_details_errors = res.additional_details_errors
            ? {
                  ...res.additional_details_errors,

                  //@ts-ignore
                  [field_id]:
                      //@ts-ignore
                      namedDriver[field_id],
              }
            : {
                  //@ts-ignore
                  [field_id]:
                      //@ts-ignore
                      namedDriver[field_id],
              }
    }

    // console.log('name driver', namedDriver)
    // console.log('res', res)

    //  track record details section
    for (let i = 0; i < err_track_rec?.length; i++) {
        let field_id = err_track_rec[i].id

        res.track_record_errors = res.track_record_errors
            ? {
                  ...res.track_record_errors,

                  [field_id]:
                      // @ts-ignore
                      namedDriver[field_id],
              }
            : {
                  [field_id]:
                      // @ts-ignore
                      namedDriver[field_id],
              }
    }

    for (let i = 0; i < additionalSubFieldsTrackRecord.length; i++) {
        let field_id = additionalSubFieldsTrackRecord[i].id
        res.track_record_errors = res.track_record_errors
            ? {
                  ...res.track_record_errors,

                  [field_id]:
                      // @ts-ignore
                      namedDriver[field_id],
              }
            : {
                  [field_id]:
                      // @ts-ignore
                      namedDriver[field_id],
              }
    }

    if (
        namedDriver?.has_previous_motor_insurance_cancelled ||
        namedDriver?.has_previous_motor_insurance_refused ||
        namedDriver?.has_previous_motor_insurance_voided ||
        namedDriver?.has_previous_special_terms_imposed
    ) {
        res.track_record_errors = res.track_record_errors
            ? {
                  ...res.track_record_errors,

                  has_any_previous_insurance_refusals: {
                      status: 'missing',
                      message: 'This is a required field',
                  },
              }
            : {
                  has_any_previous_insurance_refusals: {
                      status: 'missing',
                      message: 'This is a required field',
                  },
              }
    }

    if (disability_api && disability_api.length > 0) {
        let entitiesErrorsObj: IAnyObject = {}

        if (disability_api && disability_api.length > 0) {
            let entitiesErrorsObj: IAnyObject = {}

            for (let i = 0; i < disability_api.length; i++) {
                let disabilityObjApi: IInsuranceDisability_APIErrors =
                    disability_api[i]
                let entityObj: IAnyObject = {
                    uid: disability_api[i]?.uid,
                }
                disability_self_replicating_fields.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        let valuee = disabilityObjApi[`${item.id}`]

                        entityObj[`${item.id}`] = valuee
                    }
                )

                additionalSubFieldsDisabilities.forEach(
                    (item: IInsuranceFieldType) => {
                        // @ts-ignore
                        if (disabilityObjApi[item.id]) {
                            // @ts-ignore
                            let valuee = disabilityObjApi[`${item.id}`]

                            entityObj[`${item.id}`] = valuee
                        }
                    }
                )
                entitiesErrorsObj = {
                    ...entitiesErrorsObj,
                    [`"${entityObj.uid}"`]: { ...entityObj },
                }
            }

            res.entities_error = {
                ...res.entities_error,
                disabilities: { ...entitiesErrorsObj },
            }
        }

        res.entities_error = {
            ...res.entities_error,
            disabilities: { ...entitiesErrorsObj },
        }
    }

    // later this is where completion is calculated

    if (claims_api && claims_api.length > 0) {
        let entitiesErrorsObj: IAnyObject = {}

        for (let i = 0; i < claims_api.length; i++) {
            let claimObjApi: IClaim_APIErrors = claims_api[i]
            let entityObj: IAnyObject = { uid: claims_api[i]?.uid }
            self_replicating_claim.forEach((item: IInsuranceFieldType) => {
                // @ts-ignore
                let valuee = claimObjApi[`${item.id}`]

                entityObj[`${item.id}`] = valuee
            })

            additionalSubFieldsClaims.forEach((item: IInsuranceFieldType) => {
                // @ts-ignore
                if (claimObjApi[item.id]) {
                    // @ts-ignore
                    let valuee = claimObjApi[`${item.id}`]

                    entityObj[`${item.id}`] = valuee
                }
            })
            entitiesErrorsObj = {
                ...entitiesErrorsObj,
                [`"${entityObj.uid}"`]: { ...entityObj },
            }
        }

        res.entities_error = {
            ...res.entities_error,
            claims: { ...entitiesErrorsObj },
        }
    }

    if (conviction_api && conviction_api.length > 0) {
        let entitiesErrorsObj: IAnyObject = {}

        for (let i = 0; i < conviction_api.length; i++) {
            let convictionObjApi: IInsuranceConviction_APIErrors =
                conviction_api[i]
            let entityObj: IAnyObject = { uid: conviction_api[i]?.uid }
            insurance_sub_fields_other_driver_track_record_conviction_replicating.forEach(
                (item: IInsuranceFieldType) => {
                    // @ts-ignore
                    let valuee = convictionObjApi[`${item.id}`]

                    entityObj[`${item.id}`] = valuee
                }
            )

            additionalSubFieldsConvictions.forEach((subfieldID) => {
                // @ts-ignore
                if (convictionObjApi[subfieldID]) {
                    entityObj = {
                        ...entityObj,
                        // @ts-ignore
                        [`${subfieldID}`]: convictionObjApi[`${subfieldID}`],
                    }
                }
            })

            entitiesErrorsObj = {
                ...entitiesErrorsObj,
                [`"${entityObj.uid}"`]: { ...entityObj },
            }
        }

        res.entities_error = {
            ...res.entities_error,
            convictions: { ...entitiesErrorsObj },
        }
    }

    if (motoring_conviction_api && motoring_conviction_api.length > 0) {
        let entitiesErrorsObj: IAnyObject = {}

        for (let i = 0; i < motoring_conviction_api.length; i++) {
            let motoringConvictionObjApi: IInsuranceMotoringConviction_APIErrors =
                motoring_conviction_api[i]
            let entityObj: IAnyObject = {
                uid: motoring_conviction_api[i]?.uid,
            }
            insurance_sub_fields_other_driver_track_record_motorconviction_replicating.forEach(
                (item: IInsuranceFieldType) => {
                    // @ts-ignore
                    let valuee = motoringConvictionObjApi[`${item.id}`]

                    entityObj[`${item.id}`] = valuee
                }
            )

            additionalSubFieldsMotoringConvictionsStrings.forEach(
                (subFieldID) => {
                    // @ts-ignore
                    if (motoringConvictionObjApi[subFieldID]) {
                        entityObj = {
                            ...entityObj,
                            [`${subFieldID}`]:
                                // @ts-ignore
                                motoringConvictionObjApi[subFieldID],
                        }
                    }
                }
            )

            entitiesErrorsObj = {
                ...entitiesErrorsObj,
                [`"${entityObj.uid}"`]: { ...entityObj },
            }
        }

        res.entities_error = {
            ...res.entities_error,
            motoring_convictions: { ...entitiesErrorsObj },
        }
    }

    if (namedDriver.application_custodian_cars) {
        res.drive_list_errors = {
            ...namedDriver.application_custodian_cars,
            message:
                namedDriver.application_custodian_cars.message &&
                namedDriver.application_custodian_cars.message
                    ?.charAt(0)
                    .toUpperCase() +
                    namedDriver.application_custodian_cars.message?.slice(1),
        }
    }

    return res
}

export const convertnamedDriversToReducerOtherDriversErrorsNormalised = (
    validationData: IInsuranceApplication_APIErrors,
    normalisedDriveList: IDriveListNormalised
): IInsuranceQuoteApplication_OtherDriverObj => {
    let customer: InsuranceCustomer_APIErrors | undefined =
        validationData.customer

    let other_drivers: IInsuranceDriver_APIErrors[] | undefined =
        customer?.named_drivers
    let res: IInsuranceQuoteApplication_OtherDriverObj = {}

    if (other_drivers) {
        other_drivers.forEach((namedDriver: IInsuranceDriver_APIErrors) => {
            res[`${namedDriver.uid}`] = {
                ...convertNamedDriverToOtherDriverErrors(
                    namedDriver,
                    normalisedDriveList
                ),
            }
        })
    }

    return res
}
