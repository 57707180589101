import { combineReducers } from 'redux'
import carsData from './cars/reducer'
import galleriesData from './galleries/reducer'
import garagesData from './garages/reducer'
import highlightedFactsData from './highlighted_facts/reducer'
import technicalInformationData from './technical_information/reducer'

const entitiesReducer = combineReducers({
    carsData,
    galleriesData,
    garagesData,
    highlightedFactsData,
    technicalInformationData,
})

export default entitiesReducer
