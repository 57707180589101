type Props = {
    size?: string
    color?: string
}

const NavHamburgerIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 11.3505H22V12.8505H2V11.3505Z"
                fill={color ?? '#616161'}
            />
            <path
                d="M10 5.35046H22V6.85046H10V5.35046Z"
                fill={color ?? '#616161'}
            />
            <path
                d="M2 17.3505H14V18.8505H2V17.3505Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default NavHamburgerIcon
