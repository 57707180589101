import { insurance_colours } from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

function InsuranceVehiclesIcon(props?: any) {
    const { theme } = useThemes()
    return (
        <svg
            width={props?.width ?? 40}
            height={props?.width ?? 40}
            viewBox="0 0 40 40"
            fill="none"
        >
            <rect
                width={40}
                height={40}
                rx={4}
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_08
                }
            />
            <path
                d="M30.166 27.192a1.919 1.919 0 11-3.838 0 1.919 1.919 0 013.838 0z"
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_24
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.247 26.023a1.169 1.169 0 100 2.338 1.169 1.169 0 000-2.338zm-2.669 1.17a2.669 2.669 0 115.338 0 2.669 2.669 0 01-5.338 0z"
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_100
                }
            />
            <path
                d="M15.88 27.192a1.919 1.919 0 11-3.837 0 1.919 1.919 0 013.838 0z"
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_24
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.962 26.023a1.169 1.169 0 100 2.338 1.169 1.169 0 000-2.338zm-2.67 1.17a2.67 2.67 0 115.339 0 2.67 2.67 0 01-5.338 0z"
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_100
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.992 18.924h.015c1.074 0 1.845.642 2.207.944l.104.086c.382.304.847.762 1.18 1.092l.28.274a.135.135 0 00.039.006l6.575.417.01.001c-.06-.005.353-.008.686.126.41.164.769.512.923 1.09l.214.552.869.636.04.04c.143.143.345.386.477.671.106.23.288.775-.081 1.248l-.5.85a1.75 1.75 0 01-1.508.863h-1.735v-1.5h1.735a.25.25 0 00.215-.123l.465-.79a1.06 1.06 0 00-.095-.122l-1.121-.822-.409-1.05-.012-.05a.413.413 0 00-.037-.102.284.284 0 00-.07-.013 1.185 1.185 0 00-.07-.004l-.048-.002-.06-.003-6.558-.416a1.542 1.542 0 01-.972-.412c-.115-.109-.241-.234-.374-.365-.32-.316-.678-.668-.99-.917a25.12 25.12 0 01-.13-.103c-.392-.317-.74-.597-1.236-.602-2.24.088-4.09.372-5.947 1.035-1.81.647-3.663 1.668-5.913 3.273a2.13 2.13 0 01-.01.302c-.013.115-.069.478-.416.698a.815.815 0 01-.323.119 2.58 2.58 0 00.019.127c.007.012.038.06.122.125.11.083.297.179.59.217h2.5v1.5H9.053l-.042-.004c-1.099-.123-1.871-.771-2.039-1.474a3.49 3.49 0 01-.07-.517 2.634 2.634 0 01.001-.379 1.39 1.39 0 01.124-.489 1.038 1.038 0 01.64-.546c.003-.028.007-.057.012-.085.027-.158.108-.454.394-.66 2.413-1.743 4.451-2.89 6.494-3.62 2.047-.73 4.062-1.03 6.41-1.122h.014zm-12.933 6.93h.004-.004zm17.758 1.966h-9.805v-1.5h9.805v1.5z"
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_100
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.781 14.531h.015c1.009 0 1.733.605 2.064.881l.093.077c.354.281.781.703 1.087 1.004.093.093.175.174.24.236a.128.128 0 00.036.006l6.004.38.01.002c-.066-.006.33-.008.647.12.393.157.735.49.882 1.037l.189.485.785.576.04.04c.134.135.325.363.45.634.105.226.299.806-.147 1.283L30.08 20.27a.649.649 0 00-.014.015l-.04.053a.469.469 0 00-.07.242c0 .036.006.06.009.067a.96.96 0 00-.12-.175l-1.037-.76-.382-.984-.012-.05a.38.38 0 00-.017-.054.993.993 0 00-.087-.007l-.044-.001-.058-.003-5.987-.38a1.538 1.538 0 01-.972-.414c-.101-.096-.21-.204-.325-.317-.294-.29-.62-.612-.903-.837l-.117-.094c-.362-.29-.665-.534-1.095-.539-2.05.081-3.738.34-5.432.946-1.645.587-3.33 1.514-5.377 2.973.001.068 0 .163-.01.252-.014.11-.068.467-.41.685a.81.81 0 01-.243.103l-.018.004a1.538 1.538 0 00.011.067l-1.459.347a3.257 3.257 0 01-.066-.484c-.006-.104-.01-.23.001-.357.01-.115.035-.294.12-.47a1.014 1.014 0 01.585-.52l.007-.042a.987.987 0 01.386-.645c2.215-1.6 4.089-2.654 5.969-3.326 1.884-.673 3.737-.948 5.893-1.032h.015zm9.606 4.072l.001.001v-.001z"
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_60
                }
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.118 10.14h.015c.945 0 1.622.566 1.92.816l.084.07c.325.257.715.642.991.915l.202.198a.124.124 0 00.034.006l5.433.344.01.001c-.073-.007.307-.007.607.113.376.151.702.47.841.985l.164.42.701.515.041.04c.125.126.304.34.422.595.098.212.29.776-.145 1.243l-1.048-.979-.178.242a.4.4 0 00.011.126l-.001-.003a.874.874 0 00-.09-.13l-.953-.698-.357-.917-.012-.05v-.001l-.03-.001-.04-.002-.055-.003-5.416-.343a1.535 1.535 0 01-.974-.417c-.086-.083-.178-.174-.273-.268-.27-.265-.563-.555-.818-.757l-.105-.084c-.331-.266-.587-.472-.953-.476-1.858.073-3.385.308-4.916.855-1.48.529-2.998 1.362-4.843 2.674 0 .059-.001.132-.01.202-.008.07-.027.178-.08.294a.864.864 0 01-.324.378.803.803 0 01-.348.121l-1.308.31a3.025 3.025 0 01-.062-.45 2.321 2.321 0 01.002-.335c.009-.108.032-.28.115-.451a.99.99 0 01.532-.494.965.965 0 01.379-.63c2.017-1.456 3.726-2.418 5.442-3.032 1.722-.615 3.414-.865 5.379-.942h.014zm8.677 3.805v.001-.001zm1.514 1.47l.035-.035-.003-.003a.467.467 0 00-.032.039z"
                fill={
                    insurance_colours[theme].sections['vehicles_to_insure']
                        .primary_30
                }
            />
        </svg>
    )
}

export default InsuranceVehiclesIcon
