import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'
import { ICheckboxListItemProps } from '../filterBottomSheetItem/filterBottomSheetItemMobile'
import CustomAnimatedRadio from './customAnimatedRadio'

type IStyled = {
    $isDisabled?: boolean
}

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
`
const InfoTxt = styled(motion.div)<IStyled>`
    font-family: Lato;
    width: 100%;
    font-size: 16px;
    padding-right: 10px;
    color: ${(props: IStyled) => (props.$isDisabled ? '#bdbdbd' : '#666666')};

    cursor: ${(props: IStyled) =>
        props.$isDisabled ? 'not-allowed' : 'pointer'};
`

class RadioRowItem extends React.Component<ICheckboxListItemProps, {}> {
    render() {
        const { checked, name, isDisabled, onChange, text } = this.props

        return (
            <Row
                onClick={() => {
                    onChange(name)
                }}
            >
                <InfoTxt
                    style={{ transform: 'translateY(-2px)' }}
                    $isDisabled={isDisabled}
                >
                    {text}
                </InfoTxt>

                <CustomAnimatedRadio
                    small
                    width="auto"
                    hasWhiteBg
                    onChange={() => {
                        onChange(name)
                    }}
                    name={name}
                    checked={checked}
                    onClick={() => {}}
                    isDisabled={isDisabled}
                ></CustomAnimatedRadio>
            </Row>
        )
    }
}

export default RadioRowItem
