import * as constants from './constants'

import { createAction } from '@reduxjs/toolkit'

export const createProposalCodeRequestSuccess = createAction(
    constants.CREATE_PROPOSAL_CODE_REQUEST_SUCCESS
)

// export const redeemCodeSuccess = createAction(
//     constants.REDEEM_CODE_SUCCESS,
//     (resolve) => (code: string) => resolve(code)
// )

export const redeemCodeSuccess = createAction(
    constants.REDEEM_CODE_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)
