import styled from 'styled-components'
import { useAppSelector } from '../../../../../redux/store/hooks'
import { device } from '../../../displays/devices'
import { motion } from 'framer-motion'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import { useState } from 'react'
import { AtdConfWarningBox } from '../topBars/insuranceTopBarWithProgressionRate'
import DeletionConfirmationBottomSheet from '../../../../atoms/menu/bottomSheetMenu/deletionConfirmationBottomSheet'
import useThemes from '../../../../../providers/theme/hooks'
import BinIcon from '../../../../atoms/icons/components/binIcon'
import TagIcon from '../../../../atoms/icons/components/tagIcon'
import IconButton from '../../../../atoms/Button/iconButton'
import ReferralCodeModalDesktop from '../../../../organisms/insuranceQuote/modalManagers/referralCodeModalDesktop'
import CheckCircleIcon from '../../../../atoms/icons/components/checkCircleIcon'

const Container = styled.div<StyleProps>`
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${(props) => (props.$isMobile ? '72px' : '80px')};
    padding: ${(props) => (props.$isMobile ? '0.75rem 1.5rem' : '1rem 2rem')};
    z-index: 100;
    background: var(--bg-color, #fff);
    border-top: 1px solid var(--border-muted, #ececec);
    transition: all 100ms;
    z-index: 1;
`

const RightSide = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    gap: 16px;
`
const LeftSide = styled.div<StyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    height: 100%;
`

const GreyBorderBtn = styled(motion.button)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    height: 48px;
    background: var(--bg-color, #fff);
    color: var(--text-default, #666);
    border: 1px solid var(--border-muted, #ececec);
    border-radius: 4px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    transition: all 150ms;
    :hover {
        background-color: hsla(0, 0%, 10.196078431372548%, 0.04);
    }
    @media ${device.mobile_and_ipad} {
        font-size: 14px;
        height: 40px;
    }
`

const SendApplicationBtn = styled(motion.button)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    width: max-content;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    color: var(--bg-color, #fff);
    border: none;
    border-radius: 4px;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    transition: all 150ms;
    :hover {
        background-color: var(--primary, #5ec3ca);
    }

    @media ${device.mobile_and_ipad} {
        font-size: 14px;
        height: 40px;
    }

    @media (max-width: 375px) {
        padding: 12px 16px;
    }
`

const DeletionBtn = styled(SendApplicationBtn)`
    color: var(--error, #df6f6f);
    background-color: transparent;
    border: 1px solid var(--error, #df6f6f);
    :hover {
        background-color: rgba(255, 115, 120, 0.04);
    }
`

const BtnIcon = styled.div`
    @media (max-width: 375px) {
        display: none;
    }
`

type StyleProps = {
    $isMobile?: boolean
}

type Props = {
    isMobile?: boolean
    onClose: () => any
    onSendApplication: () => any
    onDeleteApplication: () => any
    isSendDisabled?: boolean
    showReferralCodeModal?: boolean
    toggleReferralCodeModal?: (v: boolean) => void
    draft_referral_code?: string
    saved_referral_code?: string
    onReferralCodeChange?: (v: string) => void
    submitReferralCode?: () => void
    isSaveRCDisabled?: boolean
}
const InsuranceCustomBottomBar = (props: Props) => {
    const hasValidationError = useAppSelector((state) => {
        return state.insuranceQuoteApplication.showValidationSheet
    })

    const application_status = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data?.status
    })

    const completion_stat: number | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data
            ?.overall_completion
    })

    let {
        isMobile,
        onClose,
        isSendDisabled,
        onDeleteApplication,
        onSendApplication,
        draft_referral_code,
        saved_referral_code,
        onReferralCodeChange,
        submitReferralCode,
        showReferralCodeModal,
        toggleReferralCodeModal,
        isSaveRCDisabled,
    } = props

    const [showConfirmation, toggleConfirmation] = useState(false)

    const { theme } = useThemes()

    return (
        <>
            {application_status === 'DRAFT' && (
                <Container $isMobile={isMobile}>
                    <LeftSide>
                        <GreyBorderBtn
                            onClick={onClose}
                            whileTap={{ scale: 1.05 }}
                        >
                            Close
                        </GreyBorderBtn>

                        {isMobile ? null : (
                            <Popconfirm
                                title={
                                    <AtdConfWarningBox
                                        title="Are you sure?"
                                        theme={theme}
                                    />
                                }
                                open={
                                    !isMobile && showConfirmation ? true : false
                                }
                                onOpenChange={() => {
                                    toggleConfirmation(!showConfirmation)
                                }}
                                onConfirm={onDeleteApplication}
                                onCancel={() => {
                                    toggleConfirmation(false)
                                }}
                                okText="Remove"
                                cancelText="Cancel"
                                okButtonProps={{
                                    style: {
                                        backgroundColor: `var(--error, #df6f6f)`,
                                        border: 'none',
                                        borderRadius: 4,
                                        height: 32,
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                    },
                                }}
                                cancelButtonProps={{
                                    style: {
                                        borderRadius: 4,
                                        height: 32,
                                        paddingLeft: 12,
                                        paddingRight: 12,
                                    },
                                }}
                                placement="topLeft"
                                style={{
                                    borderRadius: 4,
                                }}
                                icon={
                                    <ExclamationCircleFilled
                                        style={{
                                            color: 'var(--error, #df6f6f)',
                                        }}
                                    />
                                }
                            >
                                <DeletionBtn
                                    whileTap={{ scale: 1.05 }}
                                    onClick={() => toggleConfirmation(true)}
                                >
                                    <BinIcon color="var(--error, #df6f6f)" />
                                    Cancel application
                                </DeletionBtn>
                            </Popconfirm>
                        )}
                    </LeftSide>
                    <RightSide>
                        {isMobile ? (
                            <IconButton
                                icon="grey_bin"
                                borderColor="var(--border-muted, #e5e5e5)"
                                bgcolor="transparent"
                                buttonheight={isMobile ? '40px' : '48px'}
                                buttonwidth={isMobile ? '40px' : '48px'}
                                iconheight="16px"
                                iconwidth="16px"
                                onClick={() => toggleConfirmation(true)}
                            />
                        ) : (
                            <div style={{ position: 'relative' }}>
                                <GreyBorderBtn
                                    onClick={() =>
                                        toggleReferralCodeModal &&
                                        toggleReferralCodeModal(true)
                                    }
                                    whileTap={{ scale: 1.05 }}
                                    style={
                                        showReferralCodeModal
                                            ? {
                                                  backgroundColor:
                                                      'rgba(26,26,26,0.04)',
                                              }
                                            : {}
                                    }
                                >
                                    <TagIcon />{' '}
                                    {saved_referral_code
                                        ? `Referral code: ${saved_referral_code}`
                                        : 'Add referral code'}
                                </GreyBorderBtn>
                                <ReferralCodeModalDesktop
                                    isOpen={showReferralCodeModal ?? false}
                                    toggleModal={() => {
                                        toggleReferralCodeModal &&
                                            toggleReferralCodeModal(false)
                                        onReferralCodeChange &&
                                            saved_referral_code &&
                                            onReferralCodeChange(
                                                saved_referral_code
                                            )
                                    }}
                                    value={draft_referral_code}
                                    onChange={(v: string) => {
                                        onReferralCodeChange &&
                                            onReferralCodeChange(v)
                                    }}
                                    onSubmit={() => {
                                        submitReferralCode &&
                                            submitReferralCode()
                                        toggleReferralCodeModal &&
                                            toggleReferralCodeModal(false)
                                    }}
                                    isSaveDisabled={isSaveRCDisabled}
                                />
                            </div>
                        )}
                        {!isMobile && (
                            <Tooltip
                                title={
                                    completion_stat !== 100
                                        ? 'Click here to check your application and direct you to any missing information.'
                                        : ''
                                }
                                color="var(--strong_tooltip)"
                            >
                                <SendApplicationBtn
                                    whileTap={{ scale: 1.05 }}
                                    style={{
                                        backgroundColor: hasValidationError
                                            ? 'var(--warning, #F9BB4B)'
                                            : isSendDisabled
                                            ? 'var(--text-muted,#B3B3B3)'
                                            : 'var(--primary, #5ec3ca)',
                                    }}
                                    onClick={onSendApplication}
                                >
                                    <BtnIcon>
                                        <CheckCircleIcon />
                                    </BtnIcon>
                                    {completion_stat !== 100
                                        ? 'Show missing information'
                                        : `Send your application`}
                                </SendApplicationBtn>
                            </Tooltip>
                        )}
                        {isMobile && (
                            <SendApplicationBtn
                                whileTap={{ scale: 1.05 }}
                                style={{
                                    backgroundColor: hasValidationError
                                        ? 'var(--warning, #F9BB4B)'
                                        : isSendDisabled
                                        ? 'var(--text-muted,#B3B3B3)'
                                        : 'var(--primary, #5ec3ca)',
                                }}
                                onClick={onSendApplication}
                            >
                                <BtnIcon>
                                    <CheckCircleIcon />
                                </BtnIcon>
                                {completion_stat !== 100
                                    ? 'Show missing info'
                                    : `Send application`}
                            </SendApplicationBtn>
                        )}
                    </RightSide>
                </Container>
            )}

            <DeletionConfirmationBottomSheet
                customHeight="260px"
                isOpen={showConfirmation && isMobile ? true : false}
                toggle={() => toggleConfirmation(false)}
                action={onDeleteApplication}
                actionCopy="Remove"
                titleCopy="Are you sure?"
                detailsCopy="Please confirm that you want to remove this insurance application"
            />
        </>
    )
}

export default InsuranceCustomBottomBar
