import { ITask } from 'entityModels'
import * as React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import { device } from '../../templates/displays/devices'

const Title = styled.h1`
    font-family: Lato-light;
    color: var(--text-strong, #1a1a1a);
    font-size: 18px;
    padding-bottom: 0px;
    padding-top: 0px;
    margin: 0;
    /* line-height: 24px; */

    @media ${device.desktop} {
        font-size: 18px;
    }

    @media ${device.ipad} {
        font-size: 18px;
    }
`

const TitleDetail = styled.span`
    font-family: Lato;
`

const Instructions = styled.div`
    font-family: Lato-light;
    font-size: 14px;
    font-style: italic;
    padding-top: 10px;
    color: var(--text-darker, #616161);
`

interface IProps {
    task: ITask | undefined
    isCreation?: boolean
    isView?: boolean
}

const RemindersTopText: React.FunctionComponent<IProps> = (props: IProps) =>
    props.isCreation ? (
        <Title>
            Create reminder(s) to <br />{' '}
            <TitleDetail>{props.task?.title}</TitleDetail>
            {' before '}
            <TitleDetail>
                {props.task?.due &&
                    `${fromIsoStringToDDMMYYY(props.task?.due)}`}
            </TitleDetail>
            <Instructions>
                You can set the timezone and time you'd like to receive the
                reminders' emails{' '}
                <Link to="/account/preferences" style={{ color: '#5EC3CA' }}>
                    in your preferences page
                </Link>
                .
            </Instructions>
        </Title>
    ) : props.isView ? (
        <Title>
            Reminder(s) to: <TitleDetail>{props.task?.title}</TitleDetail>
            <br />
            {' Before Due Date: '}
            <TitleDetail>
                {props.task?.due &&
                    `${fromIsoStringToDDMMYYY(props.task?.due)}`}
            </TitleDetail>
            <Instructions>
                You can set the timezone and time you'd like to receive the
                reminders' emails{' '}
                <Link to="/account/preferences" style={{ color: '#5EC3CA' }}>
                    in your preferences page
                </Link>
                .
            </Instructions>
        </Title>
    ) : (
        <div style={{ display: 'none' }} />
    )

export default RemindersTopText
