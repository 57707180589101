import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const YearIcon = ({ size, strokeColor, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3 6C3 5.44772 3.44772 5 4 5H28C28.5523 5 29 5.44772 29 6V11H3V6ZM6.49963 14.5C6.49963 13.9477 6.94735 13.5 7.49963 13.5H10.4996C11.0519 13.5 11.4996 13.9477 11.4996 14.5V17.5C11.4996 18.0523 11.0519 18.5 10.4996 18.5H7.49963C6.94735 18.5 6.49963 18.0523 6.49963 17.5V14.5ZM14.5 13.5C13.9477 13.5 13.5 13.9477 13.5 14.5V17.5C13.5 18.0523 13.9477 18.5 14.5 18.5H17.5C18.0523 18.5 18.5 18.0523 18.5 17.5V14.5C18.5 13.9477 18.0523 13.5 17.5 13.5H14.5ZM20.4996 14.5C20.4996 13.9477 20.9473 13.5 21.4996 13.5H24.4996C25.0519 13.5 25.4996 13.9477 25.4996 14.5V17.5C25.4996 18.0523 25.0519 18.5 24.4996 18.5H21.4996C20.9473 18.5 20.4996 18.0523 20.4996 17.5V14.5ZM7.49963 20.5C6.94735 20.5 6.49963 20.9477 6.49963 21.5V24.5C6.49963 25.0523 6.94735 25.5 7.49963 25.5H10.4996C11.0519 25.5 11.4996 25.0523 11.4996 24.5V21.5C11.4996 20.9477 11.0519 20.5 10.4996 20.5H7.49963ZM13.5 21.5C13.5 20.9477 13.9477 20.5 14.5 20.5H17.5C18.0523 20.5 18.5 20.9477 18.5 21.5V24.5C18.5 25.0523 18.0523 25.5 17.5 25.5H14.5C13.9477 25.5 13.5 25.0523 13.5 24.5V21.5ZM21.4996 20.5C20.9473 20.5 20.4996 20.9477 20.4996 21.5V24.5C20.4996 25.0523 20.9473 25.5 21.4996 25.5H24.4996C25.0519 25.5 25.4996 25.0523 25.4996 24.5V21.5C25.4996 20.9477 25.0519 20.5 24.4996 20.5H21.4996Z"
                fill={color ?? colours[theme].icons_neutral_250}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M27.4545 6.5H4.54545C3.88369 6.5 3.5 6.98487 3.5 7.4V26.6C3.5 27.0151 3.88369 27.5 4.54545 27.5H27.4545C28.1163 27.5 28.5 27.0151 28.5 26.6V7.4C28.5 6.98487 28.1163 6.5 27.4545 6.5ZM4.54545 5H27.4545C28.8604 5 30 6.07452 30 7.4V26.6C30 27.9255 28.8604 29 27.4545 29H4.54545C3.13964 29 2 27.9255 2 26.6V7.4C2 6.07452 3.13964 5 4.54545 5Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.74951 2.24951V8.74951H8.24951V2.24951H9.74951Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.7495 2.24951V8.74951H22.2495V2.24951H23.7495Z"
                fill={strokeColor ?? colours[theme].icons_neutral_300}
            />
        </svg>
    )
}

export default YearIcon
