import styled from 'styled-components'
import UserPlainIcon from '../../../atoms/icons/components/userPlainIcon'
import { CarIcon } from '../../../atoms/icons/components'
import DocumentIcon from '../../../atoms/icons/components/document'
import DriverLicenceIcon from '../../../atoms/icons/components/driverLicenceIcon'
import DrivingObstacle from '../../../atoms/icons/components/drivingObstacleIcon'
import JailLock from '../../../atoms/icons/components/jailLockIcon'
import KeyIcon from '../../../atoms/icons/components/keyIcon'
import PoundIcon from '../../../atoms/icons/components/poundIcon'
import { InfoCircleOutlined } from '@ant-design/icons'
// import { HourglassOutlined } from '@ant-design/icons'

const Card = styled.div`
    width: 100%;
    min-height: 160px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--background-default, #fff);
`

const CardTopSection = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
`

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
`

const Title = styled.p`
    all: unset;
    font-family: Lato-bold;
    font-size: 16px;
`

const Text = styled.p`
    all: unset;
    width: 100%;
    font-family: Lato;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #666666;
    /* padding-bottom: 24px; */
`

type Props = {
    id: string
    title: string
    text: string
}

const InsuranceEligibilityCard = (props: Props) => {
    let { id, text, title } = props

    const generateIcon = (card_id: string) => {
        switch (card_id) {
            case 'driver_age':
                return <UserPlainIcon />
            case 'licence_length':
                return <DriverLicenceIcon />
            case 'all_claims':
                return <DocumentIcon />
            case 'all_motoring_convictions':
                return <DrivingObstacle />
            case 'criminal_convictions':
                return <JailLock />
            case 'everyday_car':
                return <CarIcon />
            case 'vehicle_use':
                return <KeyIcon />
            case 'payment_type':
                return <PoundIcon />
            case 'processing_time':
                return (
                    <div style={{ paddingRight: 24, transform: 'scale(1.3)' }}>
                        {/* <HourglassOutlined /> */}
                        <InfoCircleOutlined />
                    </div>
                )
            default:
                return null
        }
    }

    return (
        <Card>
            <CardTopSection>
                {generateIcon(id)} <Title>{title}</Title>
            </CardTopSection>
            <Line />
            <Text>{text}</Text>
        </Card>
    )
}

export default InsuranceEligibilityCard
