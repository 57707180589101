import {
    customEnumToNameFunc,
    enumToNameFunc,
} from '../../../../helpers/strings/enumToName'
import {
    IInsuranceFieldType,
    // inspection_frequency_options,
    inspection_frequency_options_with_deprecated_fields,
    license_type_options,
} from '../../../localdata/insuranceLocalData/fieldTypes'
import * as unitGenerator from '../../../../helpers/units/unitConversion'

export const getInsuranceFEValue = (
    fieldData: IInsuranceFieldType,
    BEAnswer: any
): any => {
    if (BEAnswer === undefined) {
        return undefined
    }

    if (fieldData.patch_as_int && fieldData.is_price) {
        return `${+BEAnswer / 100}`
    }

    if (fieldData.patch_as_int && !fieldData.is_price) {
        if (
            BEAnswer !== undefined &&
            (fieldData.id === 'odometer_reading' ||
                fieldData.id === 'expected_annual_mileage')
        ) {
            return unitGenerator.generateFrontEndValueDistanceUnit(
                'miles',
                BEAnswer
            )
        }
        return `${BEAnswer}`
    }

    if (fieldData.id === 'residential_visit_location') {
        return {
            name:
                // @ts-ignore
                BEAnswer?.location_type
                    ? enumToNameFunc(
                          `${
                              // @ts-ignore
                              BEAnswer?.location_type
                          }`
                      )
                    : undefined,
            // @ts-ignore
            uid: BEAnswer?.location_type
                ? // @ts-ignore
                  `${BEAnswer?.location_type}`
                : undefined,
        }
    }

    if (
        (fieldData.type === 'dropdown' ||
            fieldData.type === 'searchable_options') &&
        BEAnswer?.name
    ) {
        return {
            name:
                // @ts-ignore
                BEAnswer?.name
                    ? enumToNameFunc(
                          `${
                              // @ts-ignore
                              BEAnswer?.name
                          }`
                      )
                    : undefined,
            // @ts-ignore
            uid: BEAnswer?.uid
                ? // @ts-ignore
                  `${BEAnswer?.uid}`
                : undefined,
            code: BEAnswer?.code,
        }
    }

    if (
        (fieldData.type === 'dropdown' ||
            fieldData.type === 'searchable_options') &&
        typeof BEAnswer === 'string'
    ) {
        if (fieldData.id === 'inspection_frequency') {
            return {
                name: customEnumToNameFunc(
                    BEAnswer,
                    inspection_frequency_options_with_deprecated_fields
                ),
                uid: `${BEAnswer}`,
            }
        } else if (fieldData.id === 'license_type') {
            return {
                name: customEnumToNameFunc(BEAnswer, license_type_options),
                uid: `${BEAnswer}`,
            }
        } else if (fieldData.id === 'license_issue_location') {
            return {
                name: `${BEAnswer}`,
                uid: `${BEAnswer}`,
            }
        } else {
            return {
                name: enumToNameFunc(`${BEAnswer}`),
                uid: `${BEAnswer}`,
            }
        }
    }

    if (
        (fieldData.type === 'dropdown' ||
            fieldData.type === 'searchable_options') &&
        typeof BEAnswer === 'boolean'
    ) {
        return {
            name:
                // @ts-ignore
                BEAnswer === true
                    ? 'Yes'
                    : // @ts-ignore
                      'No',

            uid:
                // @ts-ignore
                BEAnswer === true
                    ? 'Yes'
                    : // @ts-ignore
                      'No',
        }
    }

    if (
        (fieldData.type === 'dropdown' ||
            fieldData.type === 'searchable_options') &&
        BEAnswer?.name
    ) {
        return {
            name: enumToNameFunc(`${BEAnswer.name}`),

            uid: `${BEAnswer?.uid}`,
        }
    }

    if (BEAnswer !== undefined) {
        return BEAnswer
    }

    return undefined
}
