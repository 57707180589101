import { IJourneyHistoryFileStepID } from 'journeyInterfaces'
import { useHistory } from 'react-router'
import { ICarsObject } from 'entityModels'
import { IUser } from 'myModels'
import { useAppSelector } from '../../../../../redux/store/hooks'
import JourneyHistoryFileWelcome from '../steps/welcome'
import HistoryFileLearnJourneyStep from '../steps/learn'
import { JourneyHistoryFileAddStep } from '../steps/add'
import { JourneyHistoryFileInsuranceStep } from '../steps/insurance'
import { ITimelineItem } from 'timelineModels'
import JourneySelectCar from '../../../../molecules/journey/selectCar'
import { JourneyAchievedStep } from '../../../../molecules/journey/objectiveAchieved'

type Props = {
    stepID: IJourneyHistoryFileStepID
    startJourney: () => void
    exitJourney: () => void
    carid?: string | null
    carsData?: ICarsObject | undefined
    garageCars?: string[]
    user: IUser | null
    onAdd: (
        entry1: ITimelineItem | undefined,
        entry2: ITimelineItem | undefined,
        entry3: ITimelineItem | undefined
    ) => any

    onAddInsurance: (entry1: ITimelineItem | undefined) => any
}
export default function HistoryFileJourneyStepsManager({
    stepID,
    startJourney,
    exitJourney,
    carid,
    carsData,
    garageCars,
    user,
    onAdd,
    onAddInsurance,
}: Props) {
    const history = useHistory()

    let locationState: any = history.location.state

    let galleryImages = useAppSelector(
        (state) => state.entities.galleriesData.images
    )

    switch (stepID) {
        case 'select_car':
            return (
                <JourneySelectCar
                    step={0}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : carid
                            ? history.push(
                                  `/journey/history-file?step=welcome&carid=${carid}`
                              )
                            : history.push(`/journey/history-file?step=welcome`)
                    }}
                    sectionID="history_file"
                    goToNextStep={(carid: string) => {
                        history.push({
                            pathname: `/journey/history-file`,
                            search: `?step=learn&carid=${carid}`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                    carsData={carsData}
                    garageCars={garageCars}
                    galleryImages={galleryImages}
                    user={user}
                />
            )

        case 'learn':
            return (
                <HistoryFileLearnJourneyStep
                    step={1}
                    totalSteps={3}
                    goToPrevStep={() => {
                        locationState?.prevPath
                            ? history.push(locationState?.prevPath)
                            : history.push(
                                  `/journey/history-file?step=welcome&carid=${carid}`
                              )
                    }}
                    goToNextStep={() => {
                        history.push({
                            pathname: `/journey/history-file`,
                            search: `?step=add&carid=${carid}`,
                            state: {
                                prevPath: `${history.location.pathname}${
                                    history.location.search ?? ''
                                }`,
                            },
                        })
                    }}
                />
            )
        case 'add':
            return <JourneyHistoryFileAddStep onAdd={onAdd} />
        case 'insurance':
            return (
                <JourneyHistoryFileInsuranceStep
                    onAddInsurance={onAddInsurance}
                />
            )
        case 'achieved':
            return <JourneyAchievedStep sectionID="history_file" />
        default:
            return (
                <JourneyHistoryFileWelcome
                    start={startJourney}
                    cancel={exitJourney}
                />
            )
    }
}
