// create store for todos (basically reducer for the main final store)

import { ICustomNavState } from 'entityModels'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ICustomNavState = {
    initial_landing_url: null,
    initial_external_landing_url: null,
}

export const customNavData = createSlice({
    name: 'customNavData',
    initialState: initialState,
    reducers: {
        setInitialLandingUrl: (
            state: ICustomNavState,
            action: PayloadAction<string | null>
        ) => {
            state.initial_landing_url = action.payload
        },

        setInitialExternalLandingUrl: (
            state: ICustomNavState,
            action: PayloadAction<string | null>
        ) => {
            state.initial_external_landing_url = action.payload
        },

        hideHandoverBanner: (
            state: ICustomNavState,
            action: PayloadAction<'FAILED' | 'PROCESSING'>
        ) => {
            if (action.payload === 'FAILED') {
                state.hide_handovers_failed = true
            }

            if (action.payload === 'PROCESSING') {
                state.hide_handovers_processing = true
            }
        },
    },
})

export const customNavDataActions = customNavData.actions

export default customNavData.reducer
