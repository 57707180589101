import { call, fork, put, select, take } from 'redux-saga/effects'

import { api } from '../../../services'
import {
    ICustomErrorData,
    IDropdownItem,
    IDropdownListState,
    IReduxError,
} from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'

import { RootState } from 'typesafe-actions'
import { ICreateMakePayload, ICreateModelPayload } from 'IapiDataPayload'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { dropDownActions } from '../reducer'

export type ISetCustomItemInSpecifiedTargettedDropdownReq = {
    dropdownTargetId: string
    name: string
    relevantOtherLinkedDataMakeMake?: string
}

let getDropdownDataState = (state: RootState): IDropdownListState =>
    state.localdata.dropdownData

let getDropdownMakesDataState = (state: RootState): IDropdownItem[] | null =>
    state.localdata.dropdownData.carMakes

let getTempMakeIDState = (state: RootState): string | undefined =>
    state.localdata.dropdownData.tempMakeID

function* setCustomItemInSpecifiedTargettedDropdownApi(
    payload: ISetCustomItemInSpecifiedTargettedDropdownReq
) {
    // console.log(
    //     'payload in setCustomItemInSpecifiedTargettedDropdownApi',
    //     payload,
    //     typeof payload
    // )
    let dropdownDataState: IDropdownListState = yield select(
        getDropdownDataState
    )

    // console.log('dropdownDataState', dropdownDataState)

    let dropdownTargetId: string =
        payload.dropdownTargetId === 'car_model'
            ? 'carModels'
            : payload.dropdownTargetId === 'car_make'
            ? 'carMakes'
            : payload.dropdownTargetId

    let listTargetted: any = dropdownDataState
        ? dropdownDataState[dropdownTargetId]
        : null

    const currentMakesState = dropdownDataState['carMakes']
    const currentModelsState = dropdownDataState['carModels']

    let newcarMakes: IDropdownItem[] | null = null
    let newcarModels: IDropdownItem[] | null = null
    let newcarYears: IDropdownItem[] | null = null

    let defaultOption = [
        {
            uid: 'nodata',
            name: '-',
        },
    ]

    let dataConverted: any = {}

    yield put(dropDownActions.addCustomFieldToApiRequest())

    if (dropdownTargetId === 'carMakes') {
        try {
            const carMakeDataReceived: ICreateMakePayload =
                yield api.dropdownsData.create_car_make_api(payload?.name)

            // console.log('carMakeDataReceived', carMakeDataReceived)

            const newUID = carMakeDataReceived?.uid

            yield put(
                dropDownActions.setTempMakeIDSuccess(carMakeDataReceived?.uid)
            )
            yield put(
                dropDownActions.setTempMakeDisplay(carMakeDataReceived?.name)
            )

            yield put(dropDownActions.addCustomFieldToApiSuccess())
            if (listTargetted) {
                // console.log('listTargetted', listTargetted)

                dataConverted = {
                    id: dropdownTargetId,
                    withDataAdded: [
                        ...listTargetted,
                        {
                            // UID returned by API
                            uid: newUID,
                            isNew: true,
                            name: payload?.name,
                        },
                    ],
                }
            } else {
                dataConverted = {
                    id: dropdownTargetId,
                    withDataAdded: [
                        {
                            uid: newUID,
                            isNew: true,
                            name: payload.name,
                        },
                    ],
                }
            }

            newcarMakes = dataConverted?.withDataAdded

            // console.log('newcarMakes', newcarMakes)
            newcarModels = defaultOption
            newcarYears = defaultOption

            const newState = {
                carMakes: newcarMakes,
                carModels: newcarModels,
                carYears: newcarYears,
            }

            yield put(
                dropDownActions.setCustomItemInSpecifiedTargettedDropdownSuccess(
                    newState
                )
            )
            yield put(dropDownActions.setIsLoadingNewMakeOrModel(false))
        } catch (error: any) {
            // console.log('error', error)
            if (error.status === 401) {
                let p: IUnauthHandlerPayload = {
                    functionToRepeat:
                        setCustomItemInSpecifiedTargettedDropdownApi,
                    payload: payload,
                }
                yield call(unauthHandlerSaga, p)
            } else if (error === Error) {
                let customErrorData: ICustomErrorData = {
                    custom_message: `Looks like your internet has stoped working`,
                    custom_message_detail: 'Check your connection and refresh',
                    custom_user_action_text: 'Refresh page',
                }

                let customError: IReduxError = ConvertToReduxError(
                    { status: '500' },
                    customErrorData
                )
                yield put(dropDownActions.addCustomFieldToApiError(customError))
            } else {
                let typedError: IReduxError = ConvertToReduxError(error)
                yield put(dropDownActions.addCustomFieldToApiError(typedError))
            }
        }
    } else if (dropdownTargetId === 'carModels') {
        let makeId: string | null = null

        let tempMakeID: string | undefined = yield select(getTempMakeIDState)

        if (payload.relevantOtherLinkedDataMakeMake) {
            let makesList: IDropdownItem[] = yield select(
                getDropdownMakesDataState
            )

            let item: IDropdownItem

            for (const itemIndex in makesList) {
                item = makesList[+itemIndex]

                if (
                    typeof payload.relevantOtherLinkedDataMakeMake ===
                        'string' &&
                    item.name?.toLowerCase() ===
                        payload.relevantOtherLinkedDataMakeMake?.toLowerCase()
                ) {
                    makeId = item.uid
                    yield put(dropDownActions.setTempMakeIDSuccess(item.uid))
                    yield put(dropDownActions.setTempMakeDisplay(item.name))
                    break
                }
            }

            // then send data accross
        }

        if (!makeId && tempMakeID) {
            makeId = tempMakeID
        }

        // console.log('@@@@@@@@@@@  makeId', makeId, payload)

        if (makeId) {
            try {
                const carModelDataReceived: ICreateModelPayload =
                    yield api.dropdownsData.create_car_model_api({
                        name: payload?.name,
                        make_id: makeId,
                    })

                const newUID = carModelDataReceived.uid

                yield put(dropDownActions.setTempModelIDSuccess(newUID))
                yield put(dropDownActions.setTempModelDisplay(payload?.name))

                yield put(dropDownActions.addCustomFieldToApiSuccess())

                if (listTargetted !== null) {
                    dataConverted = {
                        id: dropdownTargetId,
                        withDataAdded: [
                            ...listTargetted,
                            {
                                // UID returned by API
                                uid: newUID,
                                isNew: true,
                                name: payload?.name,
                            },
                        ],
                    }
                } else {
                    dataConverted = {
                        id: dropdownTargetId,
                        withDataAdded: [
                            {
                                uid: newUID,
                                isNew: true,
                                name: payload?.name,
                            },
                        ],
                    }
                }

                newcarMakes = currentMakesState
                newcarModels = dataConverted?.withDataAdded
                newcarYears = defaultOption

                const newState = {
                    carMakes: newcarMakes,
                    carModels: newcarModels,
                    carYears: newcarYears,
                }

                yield put(
                    dropDownActions.setCustomItemInSpecifiedTargettedDropdownSuccess(
                        newState
                    )
                )
                yield put(dropDownActions.setIsLoadingNewMakeOrModel(false))

                // yield put(dropDownActions.setTempMakeIDSuccess(undefined))
            } catch (error: any) {
                if (error.status === 401) {
                    let p: IUnauthHandlerPayload = {
                        functionToRepeat:
                            setCustomItemInSpecifiedTargettedDropdownApi,
                        payload: payload,
                    }
                    yield call(unauthHandlerSaga, p)
                } else if (error === Error) {
                    let customErrorData: ICustomErrorData = {
                        custom_message: `Looks like your internet has stoped working`,
                        custom_message_detail:
                            'Check your connection and refresh',
                        custom_user_action_text: 'Refresh page',
                    }

                    let customError: IReduxError = ConvertToReduxError(
                        { status: '500' },
                        customErrorData
                    )
                    yield put(
                        dropDownActions.addCustomFieldToApiError(customError)
                    )
                } else {
                    let typedError: IReduxError = ConvertToReduxError(error)

                    yield put(
                        dropDownActions.addCustomFieldToApiError(typedError)
                    )
                }
            }
        }
    } // CHECKS DROPDOWN ID
    // IF YEARS :
    else {
        if (listTargetted) {
            dataConverted = {
                id: dropdownTargetId,
                withDataAdded: [
                    ...listTargetted,
                    {
                        // UID returned by API
                        uid: 'new_entry',
                        isNew: true,
                        name: payload?.name,
                    },
                ],
            }
        } else {
            dataConverted = {
                id: dropdownTargetId,
                withDataAdded: [
                    {
                        uid: 'new_entry',
                        isNew: true,
                        name: payload?.name,
                    },
                ],
            }
        }

        if (dataConverted.id === 'carYears') {
            newcarMakes = currentMakesState
            newcarModels = currentModelsState
            newcarYears = dataConverted.withDataAdded
        }

        const newState = {
            carMakes: newcarMakes,
            carModels: newcarModels,
            carYears: newcarYears,
        }

        yield put(
            dropDownActions.setCustomItemInSpecifiedTargettedDropdownSuccess(
                newState
            )
        )
        yield put(dropDownActions.setIsLoadingNewMakeOrModel(false))
    }
}

function* watchersetCustomItemInSpecifiedTargettedDropdown() {
    while (true) {
        const { payload } = yield take(
            dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest
        )

        yield call(setCustomItemInSpecifiedTargettedDropdownApi, payload)
    }
}

const set_custom_item_in_specified_targetted_dropdown: any[] = [
    fork(watchersetCustomItemInSpecifiedTargettedDropdown),
]

export default set_custom_item_in_specified_targetted_dropdown
