export const AttachmentTypesEnumObj = {
    pdf: 'pdf',
    jpg: 'jpg',
    png: 'png',
    jpeg: 'jpeg',
    // img: 'img',
    xlsm: 'xlsm',
    xlsx: 'xlsx',
    xls: 'xls',
    docx: 'docx',
    HEIF: 'HEIF',
    HEIC: 'HEIC',
    heif: 'heif',
    heic: 'heic',
} as const

export const AttachmentTypesEnumArr: AttachmentTypesEnum[] = [
    'pdf',
    'jpg',
    'jpeg',
    'xlsm',
    'png',
    'docx',
    'xlsx',
    'xls',
    'HEIF',
    'heif',
    'HEIC',
    'heic',
]

export const AttachmentTypesEnumObjWithDot = {
    pdf: '.pdf',
    jpg: '.jpg',
    png: '.png',
    jpeg: '.jpeg',
    // img: 'img',
    xlsm: '.xlsm',
    xlsx: '.xlsx',
    xls: '.xls',
    docx: '.docx',
    HEIF: '.HEIF',
    HEIC: '.HEIC',
    heif: '.heif',
    heic: '.heic',
} as const

export const AttachmentTypesEnumWithDotArr: AttachmentTypesEnumWithDot[] = [
    '.pdf',
    '.jpg',
    '.jpeg',
    '.xlsm',
    '.png',
    '.docx',
    '.xlsx',
    '.xls',
    '.HEIF',
    '.heif',
    '.HEIC',
    '.heic',
]

export type AttachmentTypesEnum =
    (typeof AttachmentTypesEnumObj)[keyof typeof AttachmentTypesEnumObj]
export type AttachmentTypesEnumWithDot =
    (typeof AttachmentTypesEnumObjWithDot)[keyof typeof AttachmentTypesEnumObjWithDot]
