import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { useAppSelector } from '../../../../redux/store/hooks'
import { device } from '../../../templates/displays/devices'
import useThemes from '../../../../providers/theme/hooks'

const CarInsightsBtnStyle = styled(motion.div)<{ $theme: ITheme }>`
    cursor: pointer;
    display: flex;
    padding: 12px 16px 12px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: ${(props) => colours[props.$theme].primary_08};

    width: max-content;

    @media ${device.mobile_and_ipad} {
        align-self: flex-start !important;
        width: 100%;
    }

    :hover {
        transform: scale(1.01);
    }
`

const Txt = styled.div<{ $theme: ITheme }>`
    color: ${(props) => colours[props.$theme].primary};
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.001px;
`

const CarInsightsBtn = () => {
    const carID = useAppSelector((state) => {
        return state.entities.carsData.currentCarID
    })

    const { theme } = useThemes()

    return (
        <Link to={`/car/${carID}/insights`} data-ph={'car-insights-btn'}>
            <CarInsightsBtnStyle
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 1.05 }}
                $theme={theme}
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        width="24"
                        height="24"
                        rx="4"
                        fill="#5EC3CA"
                        fill-opacity="0.08"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5 16.583L11.5 8.99976L13 8.99976L13 16.583L11.5 16.583Z"
                        fill="#5EC3CA"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8 16.583L8 11.9998L9.5 11.9998L9.5 16.583L8 16.583Z"
                        fill="#5EC3CA"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15 16.583L15 6.99976L16.5 6.99976L16.5 16.583L15 16.583Z"
                        fill="#5EC3CA"
                    />
                </svg>

                <Txt $theme={theme}>Check out your car insights</Txt>
            </CarInsightsBtnStyle>
        </Link>
    )
}

export default CarInsightsBtn
