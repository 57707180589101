import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { IDirectShareOwnedItem, ISharingPermissionIDS } from 'entityModels'
import { BasicEditOrCreateModeSingleFieldDesktop } from '../../../molecules/editOrCreateModeSingleFields'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import CarSharePermissionTableCheckboxesDesktop from '../../../molecules/permissionTables/carShare/carSharePermissionTableCheckboxesDesktop'
import ButtonAtom from '../../../atoms/Button/ButtonAtom'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import { motion } from 'framer-motion'
import { CheckIcon } from '../../../atoms/icons/components'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

const Container = styled.div`
    padding-bottom: 200px;
`

const Title = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-family: 'Lato-Bold';
    font-size: 18px;
    padding-bottom: 20px;

    @media ${device.ipad} {
        font-size: 16px;
    }
`

const Row = styled.div`
    width: auto;
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
`

const Col = styled.div`
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: start;
`

const RoundedSquare = styled.div`
    border: 1px solid var(--text-default, #666666);
    border-radius: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-muted);
    width: 30px;
    height: 30px;

    @media ${device.desktop} {
    }

    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
    }
`

const InfoTxt = styled.div`
    font-family: 'Lato';
    font-size: 16px;
    padding-bottom: 40px;
    color: var(--text-darker, #616161);

    @media ${device.desktop} {
    }

    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
    }
`

const InfoTxtSmall = styled(motion.div)`
    font-family: 'Lato';
    font-size: 13px;
    padding-left: 10px;
    color: var(--text-darker, #616161);

    cursor: pointer;

    @media ${device.desktop} {
    }

    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
    }
`

const Warning = styled.div`
    font-family: 'Lato';
    font-size: 14px;
    padding-bottom: 30px;
    margin-top: -15px;
    color: var(--error, #df6f6f);
    font-style: italic;
    padding-left: 10px;

    @media ${device.desktop} {
    }

    @media ${device.large_desktop} {
    }

    @media ${device.smallest_laptops} {
    }
`

const AdditionalInfoTxt = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 17px;
    font-family: 'Lato';
`

const AdditionalInfoTxt2 = styled.div`
    color: var(--text-darker, #616161);
    font-size: 15px;
    font-family: 'Lato-Italic';
`

const AdditionalInfoTxt3 = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 16px;
    font-family: 'Lato';
`

const Padding3 = styled.div`
    padding-top: 26px;
`

const Padding4 = styled.div`
    padding-top: 60px;
`

// const Padding5 = styled.div`
//     padding-top: 100px;
// `

const HorizontalLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--border-muted, #e5e5e5);
`

const SharingDefaultOptionWrapper = styled.div`
    padding-left: 30px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
`

interface Props {
    share_being_edited: IDirectShareOwnedItem
    handlePrivateShareTitleChange?: any
    handlePermissions: (p: ISharingPermissionIDS) => any
    handleSubmit: any
    formType: 'edit' | 'create' | 'readOnly'
    hasNoPermissionDetails?: boolean
    isGarageShare?: boolean
}

export default function PrivateShareFormManagerDesktop(props: Props) {
    let {
        share_being_edited,
        handlePrivateShareTitleChange: handleTitleChange,
        handlePermissions,
        handleSubmit,
        formType,
        hasNoPermissionDetails: noPermissionDetails,
        isGarageShare,
    } = props

    let shareTitleData: IEditOrCreateModeSingleFieldsProps = {
        value_start: share_being_edited.title ? share_being_edited.title : '',
        title: 'Share title',
        id: 'desktop_title_share',
        onChangeFunc: (id: string, value: string) => {
            handleTitleChange(id, value)
        },
        charLimit: [2, 50],
    }

    const { theme } = useThemes()

    return (
        <Container>
            {formType === 'create' ? (
                <>
                    <Title
                        style={isGarageShare ? { paddingBottom: '20px' } : {}}
                    >
                        Permissions
                    </Title>

                    {isGarageShare && (
                        <Warning>
                            *All permissions will apply to all cars in you
                            garage.
                        </Warning>
                    )}

                    <InfoTxt>
                        Please select what you would like to share.
                        <br />
                        You can change this at any time.
                    </InfoTxt>

                    <div style={{ paddingTop: '10px' }} />
                    <Row>
                        <RoundedSquare>
                            <CheckIcon width="16px" />
                        </RoundedSquare>

                        <div style={{ paddingLeft: '30px' }} />
                        <Col>
                            <AdditionalInfoTxt>Car Profile *</AdditionalInfoTxt>
                            <AdditionalInfoTxt2>
                                Overview, Gallery, Technical Information
                            </AdditionalInfoTxt2>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            paddingLeft: '60px',
                            paddingTop: '5px',
                        }}
                    >
                        <CustomAnimatedCheckboxField
                            extra_small
                            onChange={() => {}}
                            name={'car_gallery'}
                            checked={
                                share_being_edited.active_permission_ids.includes(
                                    'car gallery'
                                )
                                    ? false
                                    : true
                            }
                            onClick={() => {
                                props.handlePermissions('car gallery')
                            }}
                            isDisabled={false}
                        >
                            <InfoTxtSmall
                                style={{
                                    transform: 'translateY(-2px)',
                                }}
                            >
                                Only include featured car gallery images
                            </InfoTxtSmall>
                        </CustomAnimatedCheckboxField>
                    </Row>
                    <div style={{ paddingTop: '25px' }} />
                    <HorizontalLine />

                    <div style={{ paddingTop: '40px' }} />
                    <AdditionalInfoTxt3>
                        Additional Sharing Options
                    </AdditionalInfoTxt3>
                    <Padding3 />
                    <CarSharePermissionTableCheckboxesDesktop
                        share_being_edited={share_being_edited}
                        onChange={(val: ISharingPermissionIDS) =>
                            handlePermissions(val)
                        }
                        noPermissionDetails={noPermissionDetails}
                    />
                    <Padding4 />
                    {formType === 'create' && (
                        <ButtonAtom
                            width={'200px'}
                            height="50px"
                            theme="main-cta"
                            onClick={handleSubmit}
                            disabled={false}
                            textTransform="capitalize"
                        >
                            create share
                        </ButtonAtom>
                    )}
                </>
            ) : (
                <>
                    {/* <Title>Share title</Title> */}

                    <div style={{ transform: 'translateY(-10px)' }}>
                        <BasicEditOrCreateModeSingleFieldDesktop
                            data={shareTitleData}
                            fontSize="16px"
                        />
                    </div>

                    <div style={{ paddingTop: '10px' }} />
                    <Title>
                        {isGarageShare
                            ? 'Garage sharing permissions'
                            : 'Car sharing permissions'}
                    </Title>
                    {isGarageShare && (
                        <Warning>
                            *All permissions will apply to all cars in you
                            garage.
                        </Warning>
                    )}
                    <div style={{ paddingTop: '20px' }} />
                    <Row style={{ paddingLeft: '30px' }}>
                        <RoundedSquare>
                            <CheckIcon
                                width="16px"
                                color={colours[theme].text_default}
                            />
                        </RoundedSquare>

                        <SharingDefaultOptionWrapper>
                            <Col>
                                <AdditionalInfoTxt>
                                    Car Profile *
                                </AdditionalInfoTxt>
                                <AdditionalInfoTxt2>
                                    Overview, Gallery, Technical Information
                                </AdditionalInfoTxt2>
                            </Col>
                        </SharingDefaultOptionWrapper>
                    </Row>

                    <Row
                        style={{
                            paddingLeft: '90px',
                            paddingTop: '0px',
                        }}
                    >
                        <CustomAnimatedCheckboxField
                            extra_small
                            onChange={() => {}}
                            name={'car_gallery'}
                            checked={
                                share_being_edited.active_permission_ids.includes(
                                    'car gallery'
                                )
                                    ? false
                                    : true
                            }
                            onClick={() => {
                                props.handlePermissions('car gallery')
                            }}
                            isDisabled={false}
                        >
                            <InfoTxtSmall
                                style={{
                                    transform: 'translateY(-2px)',
                                }}
                            >
                                Only include featured car gallery images
                            </InfoTxtSmall>
                        </CustomAnimatedCheckboxField>
                    </Row>
                    <div style={{ paddingTop: '50px' }} />
                    <AdditionalInfoTxt3>
                        Additional Sharing Options
                    </AdditionalInfoTxt3>
                    <Padding3 />
                    <CarSharePermissionTableCheckboxesDesktop
                        share_being_edited={share_being_edited}
                        onChange={(val: ISharingPermissionIDS) =>
                            handlePermissions(val)
                        }
                        readOnlyMode={formType === 'readOnly' ? true : false}
                        noPermissionDetails={noPermissionDetails}
                    />
                </>
            )}
        </Container>
    )
}
