import { ICar, IGalleryImagesObject } from 'entityModels'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { RootState } from 'typesafe-actions'
import { randomCarSvgPlaceholderFunc } from '../../../../helpers/random/randomPlaceholder'
import { device } from '../../../templates/displays/devices'
import SkeletonAbsolute from '../../../atoms/skeleton/skeletonAbsolute'
import { HistoryFilesIcon } from '../../../atoms/icons/components'
import LastUpdateIcon from '../../../atoms/icons/components/lastUpdatedICon'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'
import { ITheme } from '../../../../providers/theme/colours'

interface IStyle {
    hasNoEntry?: boolean
}

const ShadowBox = styled(Link)<IStyle>`
    position: relative;
    height: 112px;
    display: flex;
    flex-direction: column;
    align-items: initial;
    justify-content: center;
    font-family: Lato;
    width: 90vw;
    background-color: var(--bg-color, #ffffff);
    background-color: var(--shadow-cards-bg, #fff);

    @media ${device.mobile} {
        border-radius: 6px;
    }

    @media ${device.ipad} {
        border-radius: 6px;
    }
`

const CarTitle = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: var(--text-strong, #1a1a1a);
    text-transform: uppercase;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font: Lato;

    @media (max-width: 350px) {
        font-size: 12px;
    }
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 164px;
    padding-right: 24px;
    @media (max-width: 350px) {
        padding-left: 136px;
        padding-right: 16px;
    }
`

const Row = styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
`
type IImageStyle = {
    isthingloading: boolean
}

const CarImgWrapper = styled.div`
    position: absolute;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    min-width: 140px;
    max-width: 140px;
    height: 100%;
    object-fit: cover;

    @media (max-width: 350px) {
        min-width: 120px;
        max-width: 120px;
    }
`

const CarProfileImage = styled.img<IImageStyle>`
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    object-fit: cover;
`

const EntriesCount = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: var(--text-default, #666666);
    font-family: Lato;

    @media (max-width: 350px) {
        font-size: 11px;
        line-height: 14px;
    }
`
const LastUpdated = styled.div`
    color: var(--text-default, #666666);
    font-family: Lato;
    font-size: 12px;
    line-height: 16px;

    @media (max-width: 350px) {
        font-size: 11px;
        line-height: 14px;
    }
`

function mapStateToProps(state: RootState) {
    return {
        carid: state.entities.carsData.currentCarID,
    }
}

const dispatchProps = {}

interface Props extends RouteComponentProps<any> {
    car: ICar
    carIndex: number
    garageId?: string
    imagesData: IGalleryImagesObject | null
}

type State = {
    src: any
    srcet: any
    isLoading: boolean
    theme: ITheme
}

class GeneralTimelineCardMobile extends React.Component<Props, State> {
    static contextType = ThemesContext
    state = {
        srcet: undefined,
        src: undefined,
        isLoading: true,
        theme: this.context?.theme,
    }

    _isMounted = false

    onLoad = (src: string) => {
        let image = document.createElement('img')
        image.src = src

        image.onload = () =>
            this._isMounted &&
            this.setState({
                src: src,
                isLoading: false,
            })
    }

    componentDidMount() {
        this._isMounted = true
        const src = this.get_car_cover_url(this.props.car)

        if (src.url !== undefined) {
            this.onLoad(src.url)

            if (src.srcset)
                this.setState({
                    ...this.state,
                    srcet: src.srcset,
                })
        } else {
            return this.onLoad(
                randomCarSvgPlaceholderFunc('jpg', this.state.theme)
            )
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.context !== prevState.theme) {
            this.setState({ theme: this.context })

            let i: string = randomCarSvgPlaceholderFunc(
                undefined,
                this.context?.theme
            )
            return this.setState({
                src: i,
            })
        }
    }

    get_car_cover_url = (carObj: ICar) => {
        if (carObj && carObj.gallery) {
            let imageid =
                carObj.gallery && carObj.gallery.cover && carObj.gallery.cover
                    ? carObj.gallery.cover
                    : undefined

            if (
                imageid &&
                this.props.imagesData !== undefined &&
                this.props.imagesData !== null
            ) {
                if (
                    this.props.imagesData[imageid] !== undefined &&
                    this.props.imagesData[imageid] !== null &&
                    this.props.imagesData[imageid].medium_srcset
                ) {
                    return {
                        srcset: this.props.imagesData[imageid].medium_srcset,
                        url: this.props.imagesData[imageid].url,
                    }
                } else if (
                    this.props.imagesData[imageid] !== undefined &&
                    this.props.imagesData[imageid] !== null &&
                    this.props.imagesData[imageid].url
                ) {
                    return {
                        url: this.props.imagesData[imageid].url,
                    }
                }
            }
        }

        return {
            url: undefined,
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    render() {
        let { car, carIndex } = this.props

        let imageid =
            car.gallery && car.gallery.cover && car.gallery.cover
                ? car.gallery.cover
                : undefined

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <>
                        {car ? (
                            <ShadowBox
                                to={{
                                    pathname: `/car/${car.id}/history-file`,
                                    state: {
                                        prevPath:
                                            this.props.history.location
                                                .pathname,
                                    },
                                }}
                                hasNoEntry={
                                    !car.car_entries_count
                                        ? true
                                        : car.car_entries_count === 0
                                        ? true
                                        : false
                                }
                            >
                                <Row>
                                    <CarImgWrapper>
                                        <SkeletonAbsolute
                                            isthingloading={
                                                this.state.isLoading
                                            }
                                        />
                                        <CarProfileImage
                                            src={
                                                this.state.src
                                                    ? this.state.src
                                                    : randomCarSvgPlaceholderFunc(
                                                          'jpg',
                                                          theme
                                                      )
                                            }
                                            isthingloading={
                                                this.state.isLoading
                                            }
                                            srcSet={
                                                imageid &&
                                                this.props.imagesData &&
                                                this.props.imagesData[
                                                    imageid
                                                ] &&
                                                this.props.imagesData[imageid]
                                                    .small_srcset
                                                    ? this.props.imagesData[
                                                          imageid
                                                      ].small_srcset
                                                    : undefined
                                            }
                                            aria-label="car profile image"
                                        />
                                    </CarImgWrapper>
                                    <Col>
                                        <CarTitle>
                                            {car.title && car.title.length > 42
                                                ? `${car.title.slice(0, 42)}...`
                                                : car.title.trim() !== ''
                                                ? car.title
                                                : `Untitled #${carIndex + 1}`}
                                        </CarTitle>
                                        <div style={{ paddingTop: 8 }} />

                                        <Row>
                                            <HistoryFilesIcon
                                                size="18"
                                                color={
                                                    'var(--text-default, #666666)'
                                                }
                                            />
                                            <EntriesCount>
                                                {car.car_entries_count
                                                    ? car.car_entries_count
                                                    : 0}{' '}
                                                {car.car_entries_count &&
                                                car.car_entries_count > 1
                                                    ? 'Entries'
                                                    : 'Entry'}
                                            </EntriesCount>
                                        </Row>
                                        <div style={{ paddingTop: 4 }} />

                                        <Row>
                                            <LastUpdateIcon />
                                            <LastUpdated>
                                                Updated -{' '}
                                                {!car.car_entries_count
                                                    ? 'NA'
                                                    : car.car_entries_count ===
                                                      0
                                                    ? 'NA'
                                                    : car.last_entry_updated_at_date
                                                    ? car.last_entry_updated_at_date
                                                    : 'NA'}
                                            </LastUpdated>
                                        </Row>
                                    </Col>
                                </Row>
                            </ShadowBox>
                        ) : (
                            <div />
                        )}
                    </>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(GeneralTimelineCardMobile)
)
