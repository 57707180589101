import { IApexInterviewCategory } from 'apexModels'
import gql from 'graphql-tag'
import { get_check_cookie_name } from '../../../helpers/cookies'
import { client } from '../../../helpers/prismicUtils/prismicHelpers'

/*
 * Apex All Interviews-related specific queries
 */

/*
 * LATEST - OVERVIEW
 */

const apexlatestInterviewOverview = gql`
    query apexlatestInterviewOverview {
        allApex_interviewss(sortBy: date_ASC, last: 1) {
            edges {
                node {
                    _meta {
                        uid
                    }
                    date
                    name
                    headshot
                    title
                    quote
                    category
                    introduction
                    blurb
                }
            }
        }
    }
`

export const apexlatestInterviewOverviewQuery = async () => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexlatestInterviewOverview,
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}

/*
 * ALL BY CATEGORY - OVERVIEW
 */

const apexInterviewOverviewsByCategory = gql`
    query apexInterviewOverviewsByCategory($category: [String!]) {
        allApex_interviewss(sortBy: date_DESC, tags: $category) {
            edges {
                node {
                    _meta {
                        uid
                    }
                    name
                    date
                    headshot
                    title
                    quote
                    category
                    blurb
                }
            }
        }
    }
`

export const apexCategoryInterviewOverviewsQuery = async (
    category: IApexInterviewCategory
) => {
    const previewCookie = get_check_cookie_name('io.prismic.preview')

    const queryOptions: any = {
        query: apexInterviewOverviewsByCategory,
        variables: { category },
    }

    if (previewCookie) {
        queryOptions.context = {
            headers: {
                'Prismic-ref': previewCookie,
            },
        }
    }

    return client.query(queryOptions)
}
