import React, { useState } from 'react'
import styled from 'styled-components'
import IconAndTextNakedButton from '../../atoms/Button/iconsAndTextNakedButton'
import { device } from '../../templates/displays/devices'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

type IStyle = { $isHovered: boolean }

const SearchResultsDropdownItem = styled.div<IStyle>`
    position: relative;
    background-color: ${(props) =>
        props.$isHovered
            ? 'var(--primary_80, rgba(94, 195, 202, 0.8))'
            : 'var(--primary, #5EC3CA)'};

    border-radius: 4px;
    padding-left: 20px;

    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    cursor: pointer;

    @media ${device.ipad} {
        max-width: 300px;
    }

    @media (min-width: 881px) {
        max-width: 100%;
    }

    @media ${device.mobile} {
        width: 88vw;
        min-width: 88vw;
        height: 52px;
    }
`

interface IItemProps {
    onClickInvite: any
    inviteText?: string
}

const InviteSearchBtn: React.FunctionComponent<IItemProps> = (props) => {
    const { onClickInvite, inviteText } = props
    const [isHoveredOver, setIsHoveredOver] = useState(false)
    const { theme } = useThemes()

    return (
        <SearchResultsDropdownItem
            $isHovered={isHoveredOver}
            onClick={onClickInvite}
            onMouseEnter={() => setIsHoveredOver(true)}
            onMouseLeave={() => setIsHoveredOver(false)}
        >
            <IconAndTextNakedButton
                onClick={() => onClickInvite()}
                text={inviteText}
                iconOnLeft
                icon="themed_add"
                color={colours[theme].background_default}
            />
        </SearchResultsDropdownItem>
    )
}

export default InviteSearchBtn
