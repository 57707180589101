import * as urls from '../urls'
import { IDropdownItem } from 'entityModels'
import { ICreateMakePayload, ICreateModelPayload } from 'IapiDataPayload'
import { ICreateCarModelApi_args } from 'ApiInterfaces'
import { customHeader } from '../headers'
import { apiSentryErrorHandler } from '../errorHandler'

export const get_car_model_dropdown_api = (makeId: string): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.list_car_models_endpoint(makeId), requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: { data: IDropdownItem[] }) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get car models error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSend: IDropdownItem[] = data.data
                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const get_car_make_dropdown_api = (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.list_car_make_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get car makes error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSend: IDropdownItem[] = data.data

                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const create_car_make_api = (name: string): Promise<any> => {
    name = name?.replace(/['"?`]/g, '')

    const dataToSendToAPI = {
        name: name,
    }

    let pp: string = JSON.stringify(dataToSendToAPI)
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: pp,
        credentials: 'include',
    }

    return fetch(urls.create_make_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create car makes error',
                            pp
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSend: ICreateMakePayload = data.data
                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const create_car_model_api = (
    p: ICreateCarModelApi_args
): Promise<any> => {
    if (p.name) {
        p.name = p.name.replace(/['"?`]/g, '')
    }
    let pp: string = JSON.stringify(p)
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: { ...customHeader },
        body: pp,
        credentials: 'include',
    }

    return fetch(urls.create_model_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Create car model error',
                            pp
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        // not data.data this time !!
                        let dataToSend: ICreateModelPayload = data.data

                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const get_car_years_dropdown_api = (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.list_car_years_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get car years error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSend: IDropdownItem[] = data.data

                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const get_car_body_types_dropdown_api = (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.list_car_body_type_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get car body error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSendAndConvert: string[] = data.data

                        return dataToSendAndConvert
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const get_transmission_types_dropdown_api = (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.list_transmission_type_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(
                            data,
                            'Get transmission types error'
                        )
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSendAndConvert: string[] = data.data

                        return dataToSendAndConvert
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const get_countries_code_dropdown_api = (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.list_countries_dropdown_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get countries code error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSend: IDropdownItem[] = data.data

                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}

export const get_time_zone_dropdown_api = (): Promise<any> => {
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: { ...customHeader },
        credentials: 'include',
    }

    return fetch(urls.list_time_zones_endpoint, requestOptions).then(
        (response: any) => {
            return response
                .json()
                .then((data: any) => {
                    if (response.ok !== true) {
                        apiSentryErrorHandler(data, 'Get timezones error')
                        return Promise.reject(response)
                    }

                    if (data) {
                        let dataToSend: string[] = data.data
                        return dataToSend
                    }
                })
                .catch((e: any) => {
                    return Promise.reject(e)
                })
        }
    )
}
