type Props = {
    isActive?: boolean
    style?: React.SVGAttributes<SVGSVGElement>['style']
    height: number
    color?: string
}

const SearchIcon = ({ isActive, height, style, color }: Props) => {
    return (
        <svg
            width={height - 1}
            height={height}
            style={style}
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 14.3471L11.4191 9.72606C12.266 8.42769 12.6052 6.85852 12.371 5.32235C12.1369 3.78618 11.346 2.3923 10.1516 1.41057C8.95712 0.428847 7.44408 -0.0708892 5.90536 0.00812084C4.36665 0.0871309 2.91173 0.739266 1.82228 1.83827C0.732838 2.93727 0.0863732 4.40495 0.00805022 5.95716C-0.0702728 7.50938 0.425118 9.03569 1.39831 10.2406C2.3715 11.4455 3.75326 12.2433 5.27607 12.4795C6.79888 12.7157 8.3544 12.3736 9.64148 11.5193L14.2224 16.1404L16 14.3471ZM1.77911 6.27773C1.77911 3.8058 3.7727 1.79472 6.22314 1.79472C8.67358 1.79472 10.6672 3.8058 10.6672 6.27773C10.6672 8.74966 8.67358 10.7607 6.22314 10.7607C3.7727 10.7607 1.77911 8.74966 1.77911 6.27773Z"
                fill={color ? color : isActive === true ? '#5EC3CA' : '#B9B9B9'}
            />
        </svg>
    )
}

export default SearchIcon
