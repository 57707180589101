export type IUserInfoLicenseNumber = {
    given_name?: string
    middle_name?: string
    family_name?: string
    gender?: string
    dob?: string // format expected is: "2004-12-18T00:00:00Z"
    license_number?: string
}

export function prefillLicenceNumber(
    userInfo: IUserInfoLicenseNumber
): string[] {
    const { given_name, middle_name, family_name, gender, dob } = userInfo

    // Ensure all name parts are provided in uppercase
    const surname = family_name?.toUpperCase() || ''
    const givenNameInitial = given_name?.charAt(0).toUpperCase() || ''
    const middleNameInitial = middle_name?.charAt(0).toUpperCase() || ''
    const surnameInitial = family_name?.charAt(0).toUpperCase() || ''
    // Generate first 5 digits from surname
    const firstFive = (surname + '99999').slice(0, 5)

    // Extract date of birth components

    let dobPart = ''

    if (dob && gender) {
        const date = new Date(dob)
        const day = date.getUTCDate()
        const month = date.getUTCMonth() + 1 // getUTCMonth() returns 0-11
        const year = date.getUTCFullYear()
        const decadeDigit = Math.floor(year / 10) % 10
        const lastDigitOfYear = year % 10

        // Adjust month for females
        const adjustedMonth = gender === 'FEMALE' ? month + 50 : month

        // Generate date of birth part
        dobPart = `${decadeDigit}${adjustedMonth
            .toString()
            .padStart(2, '0')}${day
            .toString()
            .padStart(2, '0')}${lastDigitOfYear}`
    }
    // Combine initials
    const initials =
        givenNameInitial +
        (middleNameInitial ? middleNameInitial : surnameInitial)

    // Return an array with each segment of the licence number
    return [
        `${firstFive}`, // First 5 characters
        `${dobPart}`, // Next 6 characters
        `${initials}`, // Next 2 characters
    ]
}

export function deconstructLicenseNumber(licenseNumber: string): string[] {
    const firstFive = licenseNumber?.slice(0, 5)
    const nextSix = licenseNumber?.slice(5, 11)
    const nextTwo = licenseNumber?.slice(11, 13)
    const lastThree = licenseNumber?.slice(13)

    return [firstFive, nextSix, nextTwo, lastThree]
}

// // Example usage:
// const licenseNumber1 = 'PICKE910022LM'
// const licenseNumber2 = 'CURIE661077MC'

// console.log(deconstructLicenseNumber(licenseNumber1)) // Expected output: ['PICKE', '910022', 'LM']
// console.log(deconstructLicenseNumber(licenseNumber2)) // Expected output: ['CURIE', '661077', 'MC']
