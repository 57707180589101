import get_tasks from './getTasks'
import update_tasks from './updateTask'
import create_task from './createTask'
import relate_task from './relateTask'
import delete_task from './deleteTask'
import get_task_reminders from './getTaskReminders'
import set_task_reminders_edited from './setTaskReminder'
import delete_task_reminder from './deleteTaskReminder'
import add_task_reminders from './addReminderToTask'

let arr: any[] = [
    ...get_tasks,
    ...update_tasks,
    ...create_task,
    ...relate_task,
    ...delete_task,
    ...get_task_reminders,
    ...set_task_reminders_edited,
    ...delete_task_reminder,
    ...add_task_reminders,
]

export default arr
