import DragNSortEditableMobile from '../../../molecules/dragnsort/dragNSortEditableMobile'
import { IHighlightedFactsObject } from 'entityModels'

interface Props {
    values: {
        [key: string]: string
    }
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
    ) => void
    handleChange: (e: React.ChangeEvent<any>) => void
    data_object: IHighlightedFactsObject
    ids_list: string[]
    carid: string
    isMobile?: boolean
}

const CarHighlightsEditFormJourney = (props: Props) => {
    const {
        values,
        data_object,
        ids_list,
        handleChange,
        setFieldValue,
        isMobile,
    } = props

    return (
        <DragNSortEditableMobile
            data_object={data_object}
            ids_list={ids_list}
            giveNewArrayOrder={(items: any) =>
                setFieldValue('new_ordered_array', items)
            }
            formikprops={{
                values: values,
                handleChange: handleChange,
                setFieldValue: setFieldValue,
            }}
            isJourney={true}
            isJourneyMobile={isMobile}
        />
    )
}
export default CarHighlightsEditFormJourney
