import React from 'react'
import './style.css'

import { IdropdownObject } from '../../../redux/localdata/remindersLocalData'
import { Select } from '../select'

export type Props = {
    item?: any
    value?: any
    handleChange?: any
    optionsIDsList: string[]
    optionsData: IdropdownObject
    width?: string | undefined
    isDisabled?: boolean | undefined
    fontSize?: string | undefined
    placeholder?: string | undefined
    dataCyId?: string | undefined
    isLowerCase?: boolean
    noChangeAllowed?: boolean
    defaultValue?: string
    bg?: string
}

type StateSelect = {}

// const errorWarning = (errorText: string) => {
//     message.error(errorText, 2)
// }

class BlueBorderSelectInput extends React.Component<Props, StateSelect> {
    state = {}

    render() {
        const {
            value,
            item,
            handleChange,
            optionsIDsList,
            isDisabled,
            placeholder,
            // setSearchMode,
            optionsData,
            defaultValue,
            bg,
        } = this.props

        /* const mappedList = optionsIDsList.map((id: any) => {
            let opt = optionsData[id]

            return (
                <Option value={opt.uid} key={opt.uid} aria-label="UK">
                    <span
                        style={{
                            marginLeft: '10px',
                            fontSize: '14px',
                            fontFamily: 'Lato',
                            textTransform: isLowerCase ? 'lowercase' : 'none',
                        }}
                    >
                        {opt.name}
                    </span>
                </Option>
            )
        }) */

        const mappedList = optionsIDsList.map((id) => {
            let opt = optionsData[id]

            return {
                value: opt.uid,
                label: opt.name,
            }
        })

        return (
            <Select
                value={value}
                options={mappedList}
                defaultValue={
                    defaultValue
                        ? defaultValue
                        : item && item.answer
                        ? item.answer
                        : undefined
                }
                placeholder={placeholder}
                disabled={isDisabled ? isDisabled : false}
                onChange={handleChange}
                backgroundColor={bg}
            />
        )
    }
}

export default BlueBorderSelectInput
