import useThemes from '../../../../providers/theme/hooks'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import { journey_colours } from '../../../../providers/theme/colours'
import image from '../../../../public/assets/images/journey/shareYourCar/welcome.png'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeButtonsWrapper,
    JourneyWelcomeImage,
    JourneyWelcomeImageWrapper,
    JourneyWelcomeNewLineOnMobile,
    JourneyWelcomeSubWrapper,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapper,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import FadedSlower from '../../../templates/animated/FadedSlower'

type Props = {
    start: () => void
    cancel: (carid: string) => void
}

const CustomImageMobile = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;

    height: auto;
    width: 700px;

    @media (min-width: 500px) {
        max-height: 50dvh;
    }

    @media (max-width: 500px) {
        max-height: 35dvh;
    }
`

export default function JourneyShareYourCarWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()

    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapper>
                        <JourneyWelcomeSubWrapper>
                            <JourneyWelcomeTextWrapper>
                                <div
                                    style={{
                                        // not adding padding everywhere
                                        // as pattern should stay above
                                        paddingTop: '12px',
                                    }}
                                />

                                <JourneyWelcomeTitle>
                                    Car ownership is best enjoyed together
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    Learn about Custodian’s sharing tools!
                                    Useful for sharing your car’s information
                                    with friends, service providers, fellow car
                                    meet enthusiast and of course, potential
                                    buyers.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>

                            <JourneyWelcomeImageWrapper>
                                <FadedSlower>
                                    <JourneyWelcomeImage
                                        src={image}
                                        placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapper>

                            <div
                                style={{
                                    paddingTop: '10px',
                                }}
                            />

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn
                                    onClick={start}
                                    bg={
                                        journey_colours[theme].section
                                            .share_your_car.primary_500
                                    }
                                >
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: journey_colours[theme]
                                                .section.share_your_car
                                                .primary_500,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapper>
                    </JourneyWelcomeWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapper>
                    <JourneyWelcomeSubWrapper>
                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '26px',
                            }}
                        >
                            Car ownership is best{' '}
                            <JourneyWelcomeNewLineOnMobile />
                            <span
                                style={{
                                    color: journey_colours[theme].section
                                        .share_your_car.primary_500,
                                    fontSize: '28px',
                                }}
                            >
                                enjoyed together{' '}
                            </span>
                        </JourneyWelcomeTitle>

                        <div
                            style={{
                                paddingTop: '36px',
                            }}
                        />

                        <JourneyWelcomeImageWrapper>
                            <Faded width={'auto'}>
                                <CustomImageMobile
                                    src={image}
                                    placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapper>
                        <JourneyWelcomeText>
                            Learn about Custodian’s sharing tools! Useful for
                            sharing your car’s information with friends, service
                            providers, fellow car meet enthusiast and of course,
                            potential buyers.
                        </JourneyWelcomeText>
                        <JourneyWelcomeButtonsWrapper>
                            <StandardCtaBtn
                                onClick={start}
                                customWidth="100%"
                                bg={
                                    journey_colours[theme].section
                                        .share_your_car.primary_600
                                }
                            >
                                Let's get started
                            </StandardCtaBtn>
                        </JourneyWelcomeButtonsWrapper>
                    </JourneyWelcomeSubWrapper>
                </JourneyWelcomeWrapper>
            </IpadAndMobileDisplay>
        </>
    )
}
