import {
    GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_SUCCESS,
    SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_SUCCESS,
    GET_PRESIGNED_URL_CAR_COVER_IMAGE_SUCCESS,
    SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_SUCCESS,
    GET_PRESIGNED_URL_USERPROFILE_IMAGE_SUCCESS,
    GET_PRESIGNED_URL_ENTRY_ATTACHMENT_SUCCESS,
    GET_PRESIGNED_URL_COST_ATTACHMENT_SUCCESS,
    GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_SUCCESS,
    SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_SUCCESS,
    SUBMIT_IMAGES_TO_S3_FILESTORAGE_SUCCESS,
    RESET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_SUCCESS,
} from './constants'
import {
    IUploadAttachmentsPreSignedUrlsPayloadAPI,
    IUploadImagePreSignedUrlPayloadSingle,
    IUploadImagesPreSignedUrlsPayload,
} from 'IapiDataPayload'

import { createAction } from '@reduxjs/toolkit'
import { IUploadGalleryImagesPreSignedUrlsPayload } from 'myModels'

// export const getPresignedUrlsCarGalleryImagesSuccess = createAction(
//     GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_SUCCESS,
//     (resolve) => (payload: IUploadImagesPreSignedUrlsPayload) =>
//         resolve(payload)
// )

export const getPresignedUrlsCarGalleryImagesSuccess = createAction(
    GET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_SUCCESS,
    function prepare(payload: IUploadGalleryImagesPreSignedUrlsPayload) {
        return {
            payload,
        }
    }
)

export const resetPresignedUrlsCarGalleryImagesSuccess = createAction(
    RESET_PRESIGNED_URLS_CAR_GALLERY_IMAGES_SUCCESS
)

export const getPresignedUrlsEntryAttachmentsSuccess = createAction(
    GET_PRESIGNED_URL_ENTRY_ATTACHMENT_SUCCESS,
    function prepare(payload: IUploadAttachmentsPreSignedUrlsPayloadAPI) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlCostAttachmentSuccess = createAction(
    GET_PRESIGNED_URL_COST_ATTACHMENT_SUCCESS,
    function prepare(payload: IUploadImagePreSignedUrlPayloadSingle) {
        return {
            payload,
        }
    }
)

// export const getPresignedUrlCarCoverImageSuccess = createAction(
//     GET_PRESIGNED_URL_CAR_COVER_IMAGE_SUCCESS,
//     (resolve) => (payload: IUploadImagesPreSignedUrlsPayload) =>
//         resolve(payload)
// )

export const getPresignedUrlCarCoverImageSuccess = createAction(
    GET_PRESIGNED_URL_CAR_COVER_IMAGE_SUCCESS,
    function prepare(payload: IUploadImagesPreSignedUrlsPayload) {
        return {
            payload,
        }
    }
)

export const submitCarGalleryImagesFilestorageSuccess = createAction(
    SUBMIT_CAR_GALLERY_IMAGES_FILESTORAGE_SUCCESS
)

export const submitCarCoverImageFilestorageSuccess = createAction(
    SUBMIT_CAR_COVER_IMAGE_FILESTORAGE_SUCCESS
)

// export const getPresignedUrlUserProfileImageSuccess = createAction(
//     GET_PRESIGNED_URL_USERPROFILE_IMAGE_SUCCESS,
//     (resolve) => (payload: IUploadImagesPreSignedUrlsPayload) =>
//         resolve(payload)
// )

export const getPresignedUrlUserProfileImageSuccess = createAction(
    GET_PRESIGNED_URL_USERPROFILE_IMAGE_SUCCESS,
    function prepare(payload: IUploadImagesPreSignedUrlsPayload) {
        return {
            payload,
        }
    }
)

export const getPresignedUrlsEntryGalleryImagesSuccess = createAction(
    GET_PRESIGNED_URL_ENTRY_GALLERY_IMAGES_SUCCESS,
    function prepare(payload: IUploadGalleryImagesPreSignedUrlsPayload) {
        return {
            payload,
        }
    }
)

export const submitEntryGalleryImagesFilestorageSuccess = createAction(
    SUBMIT_ENTRY_GALLERY_IMAGES_FILESTORAGE_SUCCESS
)

export const submitFilesToS3Success = createAction(
    SUBMIT_IMAGES_TO_S3_FILESTORAGE_SUCCESS
)
