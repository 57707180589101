import * as React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { device } from '../devices'

function mapStateToProps(state: RootState) {
    return {
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

interface Props {
    children?: React.ReactNode
    isCollapsed: boolean
    isLoading?: boolean
}

interface Istyle {
    isCollapsed?: boolean
    isLoading?: boolean
}

const PageWrapper = styled.div<Istyle>`
    @media (min-width: 800px) {
        display: flex;
        justify-content: center;
        flex-direction: column;

        width: 100%;
        transition: all 500ms;

        padding-top: ${(props) => (props.isLoading ? '0' : '80px')};
        padding-bottom: 200px;
        padding-left: ${(props) => (props.isLoading ? '0' : '30px')};
        padding-right: ${(props) => (props.isLoading ? '0' : '30px')};
    }

    @media (min-width: 2000px) {
        padding-left: 0px;
        padding-right: 0px;
    }
    @media (max-width: 799px) {
        width: 100%;
        height: 100%;
    }
    @media (min-width: 1600px) {
        padding-top: ${(props) => (props.isLoading ? '0' : '110px')};
        max-width: ${(props) => (props.isLoading ? '100%' : '72vw')};
    }

    @media (min-width: 2999px) {
        padding-top: ${(props) => (props.isLoading ? '0' : '110px')};
        max-width: 2200px;
    }
`

const MenuAdjust = styled.div<Istyle>`
    display: flex;
    width: 100%;
    height: 100%;

    align-items: center;
    transition: all 100ms;
    flex-direction: column;

    @media (max-width: 799px) {
        display: flex;
        width: 100%;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
        align-items: start;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 10px;
    }

    @media (max-width: 500px) {
        padding-top: 0px;
    }

    @media ${device.desktop} {
        padding-left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }

    @media ${device.smallest_laptops} {
        padding-left: ${(props) => (props.isCollapsed ? '50px' : '200px')};
    }

    @media ${device.large_desktop} {
        padding-left: ${(props) => (props.isCollapsed ? '71px' : '271px')};
    }
`

const ApexPageWrapper: React.SFC<Props> = (props) => (
    <React.Fragment>
        <MenuAdjust isCollapsed={props.isCollapsed}>
            <PageWrapper isLoading={props.isLoading}>
                {props.children}
            </PageWrapper>
        </MenuAdjust>
    </React.Fragment>
)

export default connect(mapStateToProps, {})(ApexPageWrapper)
