import { IReduxError } from 'entityModels'
import { put, fork, take, call } from 'redux-saga/effects'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import * as actions from '../dispatched/index'
import { api } from '../../../services'
import { IApiUploadPresignedUrlGalleryImgsToS3_args } from 'ApiInterfaces'
import { message } from 'antd'

export function* submitToS3Saga(
    payload: IApiUploadPresignedUrlGalleryImgsToS3_args
) {
    try {
        message.loading(
            `Your ${
                payload.files.length > 1 ? 'images are' : 'image is'
            } uploading`,
            0
        )
        yield call(
            api.fileStorage.upload_presignedurl_gallery_images_to_s3,
            payload
        )
        message.destroy()
        yield put(actions.successActions.submitFilesToS3Success())
    } catch (error: any) {
        message.destroy()
        let typedError: IReduxError = ConvertToReduxError(error)
        yield put(actions.errorActions.submitFilesToS3Error(typedError))
    }
}

export function* watcherSubmitToS3() {
    while (true) {
        const { payload } = yield take(
            actions.loadingActions.submitFilesToS3Request
        )
        yield fork(submitToS3Saga, payload)
    }
}

const filestorage_sagas_submission_to_s3: any[] = [fork(watcherSubmitToS3)]

export default filestorage_sagas_submission_to_s3
