import styled from 'styled-components'

import { withFormik, FormikProps } from 'formik'

import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../atoms/Inputfield/inputField'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import './style.css'
import { IDropdownItem } from 'entityModels'
import { yupVar_NumberBasicSingleField } from '../yupVars'
import { Select } from '../../../atoms/select'
import useThemes from '../../../../providers/theme/hooks'

const WrapperMobile = styled.section`
    @media ${device.beyond_ipad_mobile} {
        display: none;
    }
    @media ${device.ipad} {
        padding-left: 25px;
    }
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: red;
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`
// const RegistrationNumberReminder = styled.div`
//     color: grey;
//     font-family: Lato;
//     font-size: 13px;
//     padding-top: 10px;
// `

const SmallPadding = styled.div`
    padding-top: 30px;
`

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 500px;
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
`

const AdjustPosition = styled.div`
    margin-left: 20px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_number_field_mobile: number
    unit?: string
}

interface OtherProps {
    data: IEditOrCreateModeSingleFieldsProps
    dataCyId?: string
    optionsFieldID?: string
    hasError?: (payload: boolean) => void
    optionsData?: any
    optionsIDArr?: string[]
    isDisabled?: boolean
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        isDisabled,
        data,
        errors,
        setFieldValue,
        optionsFieldID,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
        hasError,
    } = props

    const handleAnswerChange = (e: any) => {
        if (isNaN(e.target.value) !== true) {
            setFieldValue(
                'single_item_edited_value_number_field_mobile',
                e.target.value
            )
            if (data.isCreation) {
                data.onChangeFunc(
                    data.id,
                    e.target.value,
                    data.submitCreationConfigs?.entityType,
                    data.submitCreationConfigs?.action
                )
            } else {
                data.onChangeFunc(data.id, e.target.value)
            }
        }

        if (errors.single_item_edited_value_number_field_mobile !== undefined) {
            hasError && hasError(true)
        } else {
            hasError && hasError(false)
        }
    }

    const handleUnitChange = (value: string) => {
        setFieldValue('unit', value)
        if (optionsFieldID) {
            if (data.isCreation) {
                data.onChangeFunc(
                    optionsFieldID,
                    value,
                    data.submitCreationConfigs?.entityType,
                    data.submitCreationConfigs?.action
                )
            } else {
                data.onChangeFunc(optionsFieldID, value)
            }
        }
    }

    const { theme } = useThemes()

    return (
        <WrapperMobile>
            <Row>
                <Col>
                    <InputField
                        theme={theme}
                        id="single_item_edited_value_number_field_mobile"
                        name="single_item_edited_value_number_field_mobile"
                        placeholder={data.title}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={
                            values.single_item_edited_value_number_field_mobile
                        }
                        type="number"
                        font_family="Lato"
                        paddingLeft="0px"
                        width="100%"
                        fontSize="16px"
                        onChange={handleAnswerChange}
                        dataCyId={props.dataCyId}
                        step="0.01"
                        disabled={isDisabled}
                    />
                    <Expander
                        height={
                            values.single_item_edited_value_number_field_mobile &&
                            errors.single_item_edited_value_number_field_mobile
                                ? 'auto'
                                : 0
                        }
                    >
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {
                                    errors.single_item_edited_value_number_field_mobile
                                }
                            </Error>
                        </Faded>
                    </Expander>
                </Col>
                {optionsFieldID && (
                    <AdjustPosition>
                        <Select
                            defaultValue={props.data.optionName ?? 'GBP'}
                            onChange={handleUnitChange}
                            options={
                                props.optionsIDArr?.map((optionID) => {
                                    let option: IDropdownItem =
                                        props.optionsData[optionID]

                                    return {
                                        value: option?.name,
                                        label: option?.name,
                                    }
                                }) ?? []
                            }
                        />
                    </AdjustPosition>
                )}
            </Row>
            <SmallPadding />
        </WrapperMobile>
    )
}

const NumberEditOrCreateModeSingleFieldMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_number_field_mobile: props.data.value_start
            ? props.data.value_start
            : '',
        unit: props.data.optionName ? props.data.optionName : 'GBP',
    }),
    enableReinitialize: true,
    // validationSchema: {},
    validationSchema: () => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                single_item_edited_value_number_field_mobile:
                    yupVar_NumberBasicSingleField(
                        values.single_item_edited_value_number_field_mobile
                    ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(NumberEditOrCreateModeSingleFieldMobile)
