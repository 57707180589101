import {
    IAllApex_articless_overviews_api,
    IApexArticleOverviewNode,
    IApexArticleOverviewItemState,
} from 'apexModels'
import { convertImgPrismicWithCompression } from './apexImgPrismicCompression'

export const convertPrismicArticleOverviewsFromAPI = (
    data: IAllApex_articless_overviews_api
): IApexArticleOverviewItemState[] => {
    let items: IApexArticleOverviewItemState[] = data.edges.map(
        (articleNode: any, index: any) => {
            let intro: string[] = []
            let node: IApexArticleOverviewNode = articleNode.node

            if (node.introduction) {
                for (const bits of node.introduction) {
                    if (bits.text) {
                        bits.text !== undefined && intro.push(bits.text)
                    }
                }
            }

            let item: IApexArticleOverviewItemState = {
                date: node.date ? node.date : '',
                title:
                    node.title &&
                    node.title[0] &&
                    node.title[0] &&
                    node.title[0].text
                        ? node.title[0].text
                        : '',
                img:
                    node.cover_image &&
                    convertImgPrismicWithCompression(node.cover_image),
                introduction: intro,

                uid: node._meta && node._meta.uid ? node._meta.uid : '',
            }

            return item
        }
    )

    return items
}
