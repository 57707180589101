type Props = {
    width?: string
    height?: string
    color?: string
}

const SettingsIcon = ({ width, height, color }: Props) => {
    return (
        <svg
            width={width ?? '24'}
            height={height ?? '25'}
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.44157 1.35059H14.5584L14.9841 4.60827C15.8021 4.911 16.5662 5.33615 17.2477 5.86873L20.4119 4.59129L22.9983 8.87796L20.2496 10.8696C20.3158 11.2761 20.3495 11.6872 20.3502 12.0992V12.102C20.3495 12.514 20.3158 12.9251 20.2496 13.3316L22.9983 15.3232L20.4119 19.6099L17.2477 18.3324C16.5662 18.865 15.8021 19.2902 14.9841 19.5929L14.5584 22.8506H9.44157L9.01589 19.5929C8.19789 19.2902 7.43379 18.865 6.75234 18.3324L3.58811 19.6099L1.00167 15.3232L3.74976 13.3321C3.61613 12.5161 3.61613 11.6851 3.74976 10.8691L1.00167 8.87796L3.58811 4.59129L6.75234 5.86873C7.43379 5.33615 8.19789 4.911 9.01589 4.60827L9.44157 1.35059ZM10.7583 2.85059L10.3802 5.74447L9.91876 5.88586C8.97382 6.1754 8.10814 6.65693 7.38065 7.29525L7.03349 7.59985L4.212 6.46079L2.99833 8.4723L5.42166 10.2281L5.30889 10.7132C5.09642 11.6271 5.09642 12.5741 5.30889 13.488L5.42166 13.973L2.99833 15.7289L4.212 17.7404L7.03349 16.6013L7.38065 16.9059C8.10814 17.5442 8.97382 18.0258 9.91876 18.3153L10.3802 18.4567L10.7583 21.3506H13.2417L13.6198 18.4567L14.0812 18.3153C15.0262 18.0258 15.8919 17.5442 16.6194 16.9059L16.9665 16.6013L19.788 17.7404L21.0017 15.7289L18.5793 13.9737L18.6907 13.4896C18.7958 13.0332 18.8493 12.5675 18.8502 12.1006C18.8493 11.6336 18.7958 11.168 18.6907 10.7116L18.5793 10.2275L21.0017 8.4723L19.788 6.46079L16.9665 7.59985L16.6194 7.29525C15.8919 6.65693 15.0262 6.1754 14.0812 5.88586L13.6198 5.74447L13.2417 2.85059H10.7583Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 9.85059C10.7574 9.85059 9.75 10.8579 9.75 12.1006C9.75 13.3432 10.7574 14.3506 12 14.3506C13.2426 14.3506 14.25 13.3432 14.25 12.1006C14.25 10.8579 13.2426 9.85059 12 9.85059ZM8.25 12.1006C8.25 10.0295 9.92893 8.35059 12 8.35059C14.0711 8.35059 15.75 10.0295 15.75 12.1006C15.75 14.1717 14.0711 15.8506 12 15.8506C9.92893 15.8506 8.25 14.1717 8.25 12.1006Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

export default SettingsIcon
