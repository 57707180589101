import * as Sentry from '@sentry/browser'

export const apiSentryErrorHandler = (
    e: any,
    message: string,
    payload?: string
) => {
    let m: string = ''

    if (e.status && e.status !== 401) {
        if (
            JSON.stringify(e.text) !== 'undefined' ||
            JSON.stringify(e.error) !== 'undefined'
        ) {
            m = `${message}: ${
                e.error ? `Error: ${JSON.stringify(e.error)}` : ''
            }${
                e.text && JSON.stringify(e.text) !== 'undefined'
                    ? ` Text: ${JSON.stringify(e.text)}`
                    : ''
            } Status: ${e.status} ${payload ? `Payload: ${payload}` : ''}`
        } else {
            m = `${message}: ${
                e
                    ? `Error was not formatted or parsed: ${JSON.stringify(e)}`
                    : 'No error response body received from back end.'
            } Status: ${e.status} ${payload ? `Payload: ${payload}` : ''}`
        }
    } else if (!e.status) {
        m = `${message}: ${
            e
                ? `Error was not formatted or parsed as expected: ${JSON.stringify(
                      e
                  )}`
                : 'No error response body received from back end.'
        } Status: ${e.status} ${payload ? `Payload: ${payload}` : ''}`
    }

    if (m.length > 1) {
        Sentry.captureMessage(m)
    }

    return e
}

export const apiSentryS3ErrorHandler = (
    e: { message?: string; stack?: string } | any,
    message: string,
    payload?: string
) => {
    let m: string = ''
    m = `${message}: ${
        e
            ? e.stack
                ? `${JSON.stringify(e.stack)}`
                : e.message
                ? `${JSON.stringify(e.message)}`
                : `${JSON.stringify(e)}`
            : 'No error response body received.'
    } ${payload ? `Payload: ${payload}` : ''}`

    if (m.length > 1) {
        Sentry.captureMessage(m)
    }

    return e
}
