import * as React from 'react'
import styled from 'styled-components'
import CrossIcon from '../../icons/crossIcon'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type StyledProps = {
    isActive: boolean
    bgcolor?: string
    height?: string
    $isClickable?: boolean
    $hasBoxShadow?: boolean
    txtColor?: string
}
const Container = styled.div<StyledProps>`
    color: ${(props) =>
        props.txtColor
            ? props.txtColor
            : props.isActive
            ? 'var(--bg-color, #fff)'
            : 'var(--text-default, #666666)'};
    background-color: ${(props) =>
        props.bgcolor
            ? props.bgcolor
            : props.isActive
            ? 'var(--primary, #5ec3ca)'
            : 'var(--off-bg-color, #fafafa)'};
    height: ${(props) => (props.height ? props.height : '20px')};
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
    font-family: 'Lato-bold';
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 10px;
    letter-spacing: 0.7px;
    cursor: ${(props) => (props.$isClickable ? 'pointer' : 'not-allowed')};
    box-shadow: ${(props) =>
        props.$hasBoxShadow
            ? '0px 1px 2px var(--box-shadow, rgba(0, 0, 0, 0.08));'
            : 'none'};
    font-family: Lato-Semibold;
    font-size: 12px;
    line-height: 15px; /* 133.333% */

    :hover {
        ${(props) =>
            props.$isClickable &&
            'box-shadow: 2px 4px 7px var(--box-shadow, rgba(0, 0, 0, 0.08))'};
        transition: all 200ms;
    }
`

// interface IStyled {
//     isActive?: boolean
// }

type Props = {
    children?: React.ReactNode
    dataCyId?: string
    isActive?: boolean
    onCrossClick?: any
    bgcolor?: string
    txtColor?: string
    height?: string
    hasBoxShadow?: boolean
}

export default function CustomTagQuickFilterItemDesktop(props: Props) {
    const {
        children,
        dataCyId,
        isActive,
        onCrossClick,
        bgcolor,
        height,
        hasBoxShadow,
        txtColor,
    } = props

    const { theme } = useThemes()

    return (
        <Container
            isActive={isActive ? isActive : false}
            data-attr={dataCyId && dataCyId}
            bgcolor={bgcolor}
            height={height}
            onClick={onCrossClick ? onCrossClick : () => {}}
            $isClickable={onCrossClick ? true : false}
            $hasBoxShadow={hasBoxShadow}
            txtColor={txtColor}
        >
            {children}{' '}
            {onCrossClick && (
                <CrossIcon
                    color={
                        isActive
                            ? colours[theme].background_default
                            : colours[theme].text_muted
                    }
                />
            )}
        </Container>
    )
}
