type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const GbpIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10 1.75C8.48122 1.75 7.25001 2.98122 7.25001 4.5C7.25001 4.65624 7.2506 4.82293 7.25122 4.99869C7.25647 6.47914 7.264 8.6031 6.9398 10.5483C6.75802 11.639 6.46518 12.7219 5.98009 13.6287C5.86466 13.8445 5.73697 14.0528 5.5958 14.25H13.5V15.75H3.50001L3.23666 14.2978C3.81676 14.0802 4.2835 13.6203 4.65742 12.9213C5.03484 12.2156 5.292 11.311 5.46021 10.3017C5.76197 8.49116 5.75575 6.49954 5.75108 5.00606C5.75053 4.83009 5.75001 4.66103 5.75001 4.5C5.75001 2.15279 7.6528 0.25 10 0.25C12.3472 0.25 14.25 2.15279 14.25 4.5H12.75C12.75 2.98122 11.5188 1.75 10 1.75Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.5 8.25H10.5V9.75H2.5V8.25Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

const EurIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4349 7H0.934906V5.5H11.4349V7Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4349 10.5H0.934906V9H11.4349V10.5Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4349 1.75C7.98313 1.75 5.18491 4.54822 5.18491 8C5.18491 11.4518 7.98313 14.25 11.4349 14.25C13.0205 14.25 14.4666 13.6605 15.5688 12.6877L16.5614 13.8123C15.1957 15.0177 13.3999 15.75 11.4349 15.75C7.1547 15.75 3.68491 12.2802 3.68491 8C3.68491 3.71979 7.1547 0.25 11.4349 0.25C13.3999 0.25 15.1957 0.982267 16.5614 2.1877L15.5688 3.3123C14.4666 2.33946 13.0205 1.75 11.4349 1.75Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

const UsdIcon = ({ size, color }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.62553 3.47856C2.04416 3.91483 1.75445 4.46039 1.75445 5L1.75445 5.00155C1.75244 5.97042 1.97908 6.43253 2.33905 6.70669C2.75905 7.02657 3.54383 7.24162 5.01312 7.24162C6.5446 7.24162 7.76123 7.4622 8.59146 8.11364C9.47414 8.80623 9.74785 9.83812 9.74554 11.0007C9.74529 12.1176 9.14014 13.0718 8.27478 13.7212C7.40948 14.3705 6.2484 14.75 4.99777 14.75C3.74713 14.75 2.58605 14.3705 1.72075 13.7212C0.855209 13.0717 0.249992 12.1172 0.249992 11H1.74999C1.74999 11.5396 2.03971 12.0852 2.62107 12.5214C3.20268 12.9579 4.04049 13.25 4.99777 13.25C5.95504 13.25 6.79285 12.9579 7.37446 12.5214C7.95582 12.0852 8.24554 11.5396 8.24554 11L8.24554 10.9984C8.24754 10.049 8.0266 9.57706 7.6655 9.29372C7.25173 8.96906 6.47491 8.74162 5.01312 8.74162C3.48912 8.74162 2.26734 8.53758 1.43021 7.9C0.533241 7.21686 0.252116 6.18344 0.254447 4.99919C0.254738 3.8823 0.859872 2.92816 1.72521 2.2788C2.59051 1.62946 3.75158 1.25 5.00222 1.25C6.25286 1.25 7.41393 1.62946 8.27923 2.2788C9.14478 2.92832 9.74999 3.88276 9.74999 5H8.24999C8.24999 4.46039 7.96028 3.91483 7.37891 3.47856C6.7973 3.04211 5.95949 2.75 5.00222 2.75C4.04495 2.75 3.20714 3.04211 2.62553 3.47856ZM0.254447 4.99919C0.254448 4.99895 0.254448 4.9987 0.254449 4.99845L1.00445 5H0.254447C0.254447 4.99973 0.254447 4.99946 0.254447 4.99919ZM9.74554 11.0007C9.74554 11.001 9.74554 11.0013 9.74554 11.0016L8.99554 11H9.74554C9.74554 11.0002 9.74554 11.0005 9.74554 11.0007Z"
                fill={color ?? '#616161'}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.75 0L5.75 16L4.25 16L4.25 -6.55671e-08L5.75 0Z"
                fill={color ?? '#616161'}
            />
        </svg>
    )
}

const CurrencyIcons = { GbpIcon, EurIcon, UsdIcon }

export default CurrencyIcons
