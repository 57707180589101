import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonsStandardBottomRowMobile from '../../../buttons/buttonsStandardBottomRowMobile'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import InputField from '../../../../../atoms/Inputfield/inputField'
import { IYearsValidationCopy } from 'formValidationCopyModels'
import Expander from '../../../../../atoms/expander/expander'
import { IwriteTechnicalInformationPayloadReq } from 'entityModels'
import { ISetCustomItemInSpecifiedTargettedDropdownReq } from '../../../../../../redux/localdata/dropdownData/sagas/setCustomItemInSpecifiedTargettedDropdown'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../../providers/theme/hooks'

const Padding32 = styled.div`
    padding-top: 32px;
`
const Padding11 = styled.div`
    padding-top: 11px;
`

const WarningText = styled.div`
    color: var(--error, #df6f6f);
    font-size: 13px;
    padding-bottom: 10px;
    padding-top: 8px;
    width: 100%;
    align-items: start;
    justify-content: flex-start;
    font-style: italic;
    font-family: Lato;
`

const dispatchProps = {
    setCustomItemInSpecifiedTargettedDropdown: (
        payload: ISetCustomItemInSpecifiedTargettedDropdownReq
    ) =>
        dropDownActions.setCustomItemInSpecifiedTargettedDropdownRequest(
            payload
        ),
}

function mapStateToProps(state: RootState) {
    return {
        isLoading: state.localdata.dropdownData.loading,
        car_years_validation:
            state.localdata.form_validation_copy.years.car_year,
    }
}

interface Values {
    car_year_custom_mobile: any
}

interface OtherProps {
    toggle: any
    model_item: any
    year_item: any
    submitFunc: (p: IwriteTechnicalInformationPayloadReq) => {}
    tech_info_id?: any
    setCustomItemInSpecifiedTargettedDropdown: any
    isLoading: boolean
    carid: string
    car_years_validation?: IYearsValidationCopy
}

// const defaultOption = [
//   {
//     uid: "nodata",
//     name: "-",
//   },
// ];

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { toggle, model_item, year_item, values, errors, handleSubmit } =
        props

    let checkIfDisabled = () => {
        if (!values.car_year_custom_mobile) {
            return true
        } else if (errors.car_year_custom_mobile) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>Year</ModalTopBar>
            {year_item !== undefined && model_item !== undefined && (
                <ModalInnerContentWrapperMobile>
                    <Expander height={model_item.answer === null ? 'auto' : 0}>
                        <WarningText>Please enter the car's model.</WarningText>
                    </Expander>
                    <Padding11 />
                    <InputField
                        theme={theme}
                        id="car_year_custom_mobile"
                        name="car_year_custom_mobile"
                        placeholder={year_item.text}
                        tabindex={1}
                        aria-live="polite"
                        formikprops={props}
                        value={values.car_year_custom_mobile}
                        font_family="Lato"
                        width="150px"
                        type="number"
                        onEnterKeyPress={handleSubmit}
                        disabled={
                            values['car_year_custom_mobile'] === null &&
                            model_item.answer === null
                                ? true
                                : false
                        }
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                    <Expander
                        height={errors.car_year_custom_mobile ? 'auto' : 0}
                    >
                        <WarningText>
                            {errors.car_year_custom_mobile}
                        </WarningText>
                    </Expander>
                    <Padding32 />
                    <ButtonsStandardBottomRowMobile
                        cancel={toggle}
                        submit={handleSubmit}
                        confirmationtext="save"
                        disabled={checkIfDisabled()}
                    />
                </ModalInnerContentWrapperMobile>
            )}
        </ModalContentMobile>
    )
}

const CarYearCustomFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        car_year_custom_mobile: props.year_item ? props.year_item.answer : '-',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy((values: Values) => {
            return Yup.object().shape({
                car_year_custom_mobile:
                    values.car_year_custom_mobile &&
                    values.car_year_custom_mobile !== '-' &&
                    props.car_years_validation &&
                    Yup.number()
                        .min(
                            props.car_years_validation.min,
                            props.car_years_validation.warning_min
                        )
                        .max(
                            props.car_years_validation.max,
                            props.car_years_validation.warning_max
                        ),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        let p: IwriteTechnicalInformationPayloadReq = {
            tech_info_id: props.tech_info_id,
            car_id: props.carid,
            data: {
                id: 'year',
                text: 'year',
                answer: values.car_year_custom_mobile,
            },
        }
        props.submitFunc && props.submitFunc(p)
        props.toggle()
    },
})(InnerForm)

export default connect(mapStateToProps, dispatchProps)(CarYearCustomFormMobile)
