import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import { AbsoluteGradient } from '../../../../templates/styledcomponents/journeyStyles'
import useThemes from '../../../../../providers/theme/hooks'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import colours, {
    ITheme,
    journey_colours,
} from '../../../../../providers/theme/colours'
import StandardCtaBtn from '../../../../atoms/Button/standardCtaBtn'
import Faded from '../../../../templates/animated/faded'
import EnterIcon from '../../../../atoms/icons/components/enterIcon'
import { device } from '../../../../templates/displays/devices'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import JourneyPageWrapperMobile from '../../../../templates/wrappers/journeyPageWrapperMobile'
import JourneyWrapperLeftSideDesktop from '../../../../templates/wrappers/journeyPageWrapperLeftDesktop'
import { UserProfileJourneyUserCardMobile } from '../card/cardMobile'
import TextArea from '../../../../atoms/textarea/textarea'
import { UserProfileJourneyUserCardDesktop } from '../card/cardDesktop'
import { IUSerGeneralInfoUpdatePayload } from 'myModels'
import { updateUserGeneralInfoRequest } from '../../../../../redux/user/actions/loadingActions'

type Props = {
    step: number
    totalSteps: number
    goToPrevStep: () => void
    goToNextStep: () => void
}

type IStyle = {
    $theme: ITheme
}

const Wrapper = styled.section`
    width: 100%;
    height: 100dvh;
    display: flex;
    padding-top: 40px;
`

const RightSide = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
    height: 100%;
    overflow-y: auto;
    @media ${device.smallest_laptops} {
        padding: 24px;
    }
`

const ColCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`

const EnterTxt = styled.span<IStyle>`
    color: ${(props) => colours[props.$theme].text_muted};
    text-align: center;
    font-family: Lato-semibold;
    font-size: 12px;
    line-height: 16px;
`

const RowContent = styled.div`
    display: flex;
    align-items: center;
`

const IconWrapper = styled.div<IStyle>`
    border-radius: 999px;
    background: ${(props) =>
        colours[props.$theme].background_neutral_overlay_40};
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
`

type Props2 = {
    value: string | undefined
    onChange: any
}

const RenderText = (props2: Props2) => {
    const { theme } = useThemes()
    return (
        <div
            style={{
                height: '230px',
                width: '90%',
                border: '1px solid var(--text-muted, #B3B3B3)',
                borderRadius: '5px',
                position: 'relative',
            }}
        >
            <TextArea
                maxLength={256}
                value={props2.value}
                name={'short bio'}
                handleChange={(e) => {
                    props2.onChange(e?.target?.value)
                }}
                backgroundColor={'transparent'}
                placeholder={
                    'Passionate car enthusiast with a deep love for performance vehicles, classic cars, and motorsports. Enjoys attending car shows, restoring classics, and sharing driving tips.'
                }
            />

            <div
                style={{
                    alignSelf: 'right',
                    justifySelf: 'right',
                    position: 'absolute',
                    right: 24,
                    bottom: -30,
                    fontFamily: 'Lato',
                    color: colours[theme].text_muted,
                    fontSize: '12px',
                }}
            >
                {props2.value?.length ?? 0} / 256
            </div>
            <div
                style={{
                    alignSelf: 'right',
                    justifySelf: 'right',
                    position: 'absolute',
                    right: 10,
                    bottom: 0,
                    fontFamily: 'Lato',
                    color: colours[theme].text_muted,
                    fontSize: '12px',
                }}
            >
                <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22 16C22 15.5956 21.7564 15.2309 21.3827 15.0761C21.009 14.9214 20.5789 15.0069 20.2929 15.2929L15.2929 20.2929C15.0069 20.5789 14.9214 21.009 15.0761 21.3827C15.2309 21.7564 15.5956 22 16 22H21C21.5523 22 22 21.5523 22 21V16Z"
                        fill="#666666"
                    />
                </svg>
            </div>
        </div>
    )
}

export default function UserProfileJourneyBio({
    step,
    totalSteps,
    goToNextStep,
    goToPrevStep,
}: Props) {
    const { theme } = useThemes()

    const [bio, setBio] = useState<string | undefined>(undefined)

    const dispatch = useAppDispatch()

    const onSubmit = () => {
        if (userLoggedIn?.id && userLoggedIn.one_liner !== bio) {
            let data: IUSerGeneralInfoUpdatePayload = {
                id: userLoggedIn?.id,
                data: {
                    bio: bio,
                },
            }
            dispatch(updateUserGeneralInfoRequest(data))
        }
    }

    const submitAndGoToNextStep = () => {
        if (bio) {
            onSubmit()
        }
        goToNextStep()
    }

    const continueOnEnter = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
            submitAndGoToNextStep()
        }
    }

    useEffect(() => {
        document.addEventListener('keypress', continueOnEnter)

        return () => {
            document.removeEventListener('keypress', continueOnEnter)
        }
    }, [step, bio])

    let userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    useEffect(() => {
        if (userLoggedIn?.one_liner) {
            setBio(userLoggedIn.one_liner)
        }
    }, [])

    return (
        <div style={{ width: '100%' }}>
            <DesktopDisplayOnly>
                <Faded>
                    <Wrapper>
                        <JourneyWrapperLeftSideDesktop
                            title={
                                userLoggedIn && userLoggedIn.one_liner
                                    ? 'Update your bio'
                                    : 'Tell us a little about yourself...'
                            }
                            sectionID="your_profile"
                            currentStep={step}
                            totalSteps={totalSteps}
                            onBack={() => {
                                goToPrevStep()
                            }}
                        >
                            <RenderText value={bio} onChange={setBio} />

                            <div style={{ paddingTop: '4px' }} />

                            <RowContent style={{ gap: 32 }}>
                                <StandardCtaBtn
                                    onClick={submitAndGoToNextStep}
                                    bg={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_500
                                    }
                                    isDisabled={!userLoggedIn}
                                    customHoverColor={
                                        journey_colours[theme].section[
                                            'your_profile'
                                        ].primary_600
                                    }
                                >
                                    Continue
                                </StandardCtaBtn>

                                <RowContent style={{ gap: 8 }}>
                                    <IconWrapper $theme={theme}>
                                        <EnterIcon />
                                    </IconWrapper>{' '}
                                    <EnterTxt $theme={theme}>
                                        Or press Enter
                                    </EnterTxt>
                                </RowContent>
                            </RowContent>
                        </JourneyWrapperLeftSideDesktop>
                        <RightSide
                            style={{
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <UserProfileJourneyUserCardDesktop
                                userWithFormInfo={
                                    userLoggedIn
                                        ? { ...userLoggedIn, one_liner: bio }
                                        : undefined
                                }
                            />
                        </RightSide>
                    </Wrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyPageWrapperMobile
                    allowScroll={true}
                    title={
                        userLoggedIn && userLoggedIn.one_liner
                            ? 'Update your bio'
                            : 'Tell us a little about yourself...'
                    }
                    sectionID="your_profile"
                    subtitle={'Make sure you look your best'}
                    currentStep={step}
                    totalSteps={totalSteps}
                    onBack={() => {
                        goToPrevStep()
                    }}
                    onNext={submitAndGoToNextStep}
                >
                    <ColCenter>
                        <div style={{ paddingTop: '24px' }} />
                        <UserProfileJourneyUserCardMobile withPhoto={true} />

                        <div style={{ paddingTop: '40px' }} />

                        <RenderText value={bio} onChange={setBio} />

                        <AbsoluteGradient />
                    </ColCenter>
                </JourneyPageWrapperMobile>
            </IpadAndMobileDisplay>
        </div>
    )
}
