import * as React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import './antd.css'
import { ITask } from 'entityModels'
import { fromIsoStringToDDMMYYY } from '../../../redux/conversions/time'
import { OOtherTaskOptions } from '../../../ol/ol'
import CustomAnimatedCheckboxField from '../formField/customAnimatedCheckboxField'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'
import { useState } from 'react'
import { useAppSelector } from '../../../redux/store/hooks'
import StandardCtaBtn from '../Button/standardCtaBtn'
import ClockIcon from '../icons/components/clockIcon'
import CalendarIcon from '../icons/components/calendar'
import dayjs from 'dayjs'

const ShadowBox = styled.section<IBoxProps>`
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    background: var(--bg-color, #fff);
    box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04),
        0px 2px 8px 0px rgba(0, 0, 0, 0.04);
    transition: all 100ms;
    margin-bottom: 16px;
`

const Label = styled.div`
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    font-family: Lato-Semibold;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.001px;
`

const Row = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
`
const DatesColumn = styled.div`
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--off-bg);
`

const TaskTitle = styled.div<{ $theme: ITheme }>`
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-Semibold;
    font-size: 18px;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.001px;
`

const BottomSectionText = styled.div`
    font-family: Lato;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
`

type IBoxProps = {
    isBodyEmpty?: boolean
}

interface Props {
    task: ITask
    carsData: any
    updateTask: any
    carid?: string
}

const TaskCardMobile: React.FC<Props> = (props) => {
    let { task, carsData, updateTask } = props

    const carid = useAppSelector(
        (state) => state.entities.carsData.currentCarID
    )

    let numberOfCars: number = carsData ? Object.entries(carsData).length : 0

    let generateLinkTitle = () => {
        if (task.linkedToCar) {
            if (
                task.linkedToCar.length > 1 &&
                task.linkedToCar.length === numberOfCars
            ) {
                return 'Every Car'
            } else if (task.linkedToCar.length > 1) {
                if (
                    carsData[task.linkedToCar[0]] &&
                    carsData[task.linkedToCar[0]].title
                ) {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                minWidth: 0,
                                maxWidth: '100%',
                            }}
                        >
                            <span
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '100%',
                                    marginRight: 4,
                                }}
                            >
                                {carsData[task.linkedToCar[0]].title}
                            </span>
                            {`  +${task.linkedToCar.length - 1}`}
                        </div>
                    )
                } else return 'untitled car'
            } else if (
                task.linkedToCar.length === 1 &&
                carsData[task.linkedToCar[0]]
            ) {
                if (
                    carsData[task.linkedToCar[0]] &&
                    task.linkedToCar.length === 1
                ) {
                    if (
                        carsData[task.linkedToCar[0]] &&
                        carsData[task.linkedToCar[0]].title
                    ) {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    minWidth: 0,
                                    maxWidth: '100%',
                                }}
                            >
                                <span
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                    }}
                                >
                                    {carsData[task.linkedToCar[0]].title}
                                </span>
                            </div>
                        )
                    } else return 'untitled car'
                }
            } else return 'Car To delete'
        } else return OOtherTaskOptions.general_task.single
    }

    const [showTooltip, setShowTooltip] = useState(false)
    const [status, setStatus] = useState(false)

    React.useEffect(() => {
        setStatus(task.status)
    }, [task])

    const toggleStatus = () => setStatus(!status)

    const { theme } = useThemes()

    const history = useHistory()
    const location = useLocation()

    let today = new Date()

    const isDueDateInThePast = task?.due
        ? dayjs(task.due).isBefore(today)
            ? true
            : false
        : undefined

    return (
        <ShadowBox
            style={{
                border: status
                    ? `1px solid ${colours[theme].primary_20}`
                    : `1px solid ${colours[theme].supp_lavender_30}`,
            }}
            isBodyEmpty={task.body && task.body.length > 1 ? false : true}
            key={task.id}
            onMouseLeave={(e: any) => {
                e.preventDefault()
                setShowTooltip(false)
            }}
        >
            <Column style={{ gap: 16, width: '100%' }}>
                <Row
                    style={{
                        width: '100%',
                        gap: 8,
                    }}
                >
                    <Link
                        style={{
                            width: '100%',
                            display: 'flex',
                            minWidth: 0,
                        }}
                        to={
                            location.pathname.match(/car/g) !== null && carid
                                ? `/car/${carid}/tasks/task?taskid=${task.id}`
                                : `tasks/task?taskid=${task.id}`
                        }
                        onClick={(e: any) => e.stopPropagation()}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                                width: '100%',
                                minWidth: 0,
                            }}
                        >
                            <Label
                                style={{
                                    backgroundColor:
                                        colours[theme]
                                            .background_neutral_subtle,
                                    color: colours[theme].text_darker,
                                    minWidth: 0,
                                }}
                            >
                                {generateLinkTitle()}
                            </Label>
                            <Label
                                style={{
                                    textTransform: 'capitalize',
                                    backgroundColor: status
                                        ? colours[theme].background_primary_soft
                                        : colours[theme].supp_lavender_8,
                                    color: status
                                        ? colours[theme].primary
                                        : colours[theme].supp_Lavender_100,
                                }}
                            >
                                {status ? 'completed' : 'outstanding'}
                            </Label>
                        </div>
                    </Link>
                    <div style={{ width: 25 }}>
                        <CustomAnimatedCheckboxField
                            mobileAlignSelf="center"
                            small
                            fullColorOnTrue
                            borderColor={colours[theme].text_muted}
                            checked={status}
                            onChange={() => {
                                updateTask({
                                    taskid: task.id,
                                    dataList: [
                                        {
                                            id: 'status',
                                            answer: !status,
                                        },
                                    ],
                                })

                                toggleStatus()
                            }}
                        />
                    </div>
                </Row>
                <Link
                    to={
                        location.pathname.match(/car/g) !== null && carid
                            ? `/car/${carid}/tasks/task?taskid=${task.id}`
                            : `tasks/task?taskid=${task.id}`
                    }
                    onClick={(e: any) => e.stopPropagation()}
                >
                    <TaskTitle
                        $theme={theme}
                        style={{
                            textDecoration: status ? 'line-through' : undefined,
                        }}
                    >
                        {task.title}
                    </TaskTitle>
                </Link>
            </Column>
            <Link
                to={
                    location.pathname.match(/car/g) !== null && carid
                        ? `/car/${carid}/tasks/task?taskid=${task.id}`
                        : `tasks/task?taskid=${task.id}`
                }
                onClick={(e: any) => e.stopPropagation()}
            >
                <Column style={{ gap: 16 }}>
                    <DatesColumn>
                        <Row style={{ justifyContent: 'flex-start', gap: 8 }}>
                            <ClockIcon
                                color={
                                    status
                                        ? colours[theme].text_muted
                                        : colours[theme].text_default
                                }
                            />
                            <BottomSectionText
                                style={{
                                    color: status
                                        ? colours[theme].text_muted
                                        : colours[theme].text_default,
                                }}
                            >
                                {task.due && 'Due on '}
                                {task.due
                                    ? fromIsoStringToDDMMYYY(task.due)
                                    : 'No due date set'}
                            </BottomSectionText>
                        </Row>
                        <Row style={{ justifyContent: 'flex-start', gap: 8 }}>
                            <CalendarIcon
                                size="16"
                                color={colours[theme].text_muted}
                            />
                            <BottomSectionText
                                style={{
                                    color: colours[theme].text_muted,
                                }}
                            >
                                Created on{' '}
                                {fromIsoStringToDDMMYYY(task.created_at)}
                            </BottomSectionText>
                        </Row>
                    </DatesColumn>

                    <div
                        onClick={(e: any) => {
                            e.preventDefault()
                            !task.status && setShowTooltip(true)
                        }}
                    >
                        {(!task.due || task.due === '-') && (
                            <Tooltip
                                overlayStyle={{
                                    fontFamily: 'Lato-Light',
                                    textTransform: 'capitalize',
                                }}
                                open={showTooltip}
                                placement="topLeft"
                                title={'Please set a due date first'}
                            />
                        )}
                        {task.status || isDueDateInThePast ? null : (
                            <StandardCtaBtn
                                isSecondary
                                customHeight="40"
                                bg={'transparent'}
                                customHoverColor={
                                    colours[theme].supp_lavender_8
                                }
                                borderOnHover={`2px solid ${colours[theme].supp_Lavender_100}`}
                                boder={
                                    status
                                        ? `1px solid ${colours[theme].border_muted}`
                                        : `1px solid ${colours[theme].supp_Lavender_100}`
                                }
                                isDisabled={task.due ? false : true}
                                onClick={() =>
                                    task.status
                                        ? {}
                                        : task.due && task.due !== '-'
                                        ? task.has_reminders
                                            ? history.push(
                                                  `/tasks/task?taskid=${task.id}&reminders=true&viewid=view`
                                              )
                                            : history.push(
                                                  `/tasks/task?taskid=${task.id}&reminders=true&viewid=create`
                                              )
                                        : {}
                                }
                            >
                                <div
                                    style={{
                                        height: 16,
                                        display: 'flex',
                                        gap: 8,
                                        color: task.due
                                            ? colours[theme].supp_Lavender_100
                                            : colours[theme].text_muted,
                                        fontSize: 14,
                                    }}
                                >
                                    <ClockIcon
                                        color={
                                            task.due
                                                ? colours[theme]
                                                      .supp_Lavender_100
                                                : colours[theme].text_muted
                                        }
                                    />
                                    {task?.has_reminders
                                        ? 'View Reminders'
                                        : 'Set Reminders'}
                                </div>
                            </StandardCtaBtn>
                        )}
                    </div>
                </Column>
            </Link>
        </ShadowBox>
    )
    // }
}

export default TaskCardMobile
