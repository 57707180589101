import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'

import * as Yup from 'yup'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import ButtonsStandardBottomRowDesktop from '../../buttons/buttonsStandardBottomRowDesktop'

import TaskNotesEditorDesktop from '../../../editorSections/taskNotes/taskNotesEditorDesktop'
import {
    ModalContentDesktop,
    ModalInnerContentWrapperDesktop,
    ModalTitle,
} from '../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../providers/theme/hooks'

const SmallPadding = styled.div`
    padding-top: 20px;
`

const LargePadding = styled.div`
    padding-top: 50px;
`

const DescriptionHeader = styled.div`
    color: var(--text-strong, #1a1a1a);
    font-size: 14px;
    text-align: left;
    font-family: Lato-Light;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    notes: string
}

interface OtherProps {
    toggle: any
    submitFunc: any
    taskid: string
    item: any
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const { toggle, setFieldValue, values, errors } = props

    let checkIfDisabled = () => {
        if (!values.notes) {
            return true
        } else if (errors.notes !== undefined) {
            return true
        } else return false
    }

    const { theme } = useThemes()

    return (
        <ModalContentDesktop $theme={theme}>
            <ModalInnerContentWrapperDesktop>
                <>
                    <ModalTitle $theme={theme} style={{ marginBottom: 8 }}>
                        Task Notes
                    </ModalTitle>
                    <DescriptionHeader
                        style={
                            errors.notes === 'over20k' ? { color: 'red' } : {}
                        }
                    >
                        [Up to 20,000 characters]
                    </DescriptionHeader>
                    <SmallPadding />
                    <SmallPadding />

                    <TaskNotesEditorDesktop
                        placeholder={'+ add any notes relating to the task'}
                        valuePassed={values.notes}
                        handleChange={(text: any) =>
                            setFieldValue('notes', text)
                        }
                        idPassed="notes"
                        readOnly={false}
                    />
                </>
                <LargePadding />

                <ButtonsStandardBottomRowDesktop
                    cancel={toggle}
                    submit={props.handleSubmit}
                    isDisabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperDesktop>
        </ModalContentDesktop>
    )
}

const NotesEditorFormWrapperDesktop = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        notes: props.item.answer ? props.item.answer : '',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                notes: Yup.string().max(20000, 'over20k'),
            })
        })
    },

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)

        if (props.item.answer !== values.notes) {
            props.submitFunc({
                taskid: props.taskid,
                dataList: [
                    {
                        id: 'body',
                        answer: values.notes,
                    },
                ],
            })
        }

        props.toggle()
    },
})(InnerForm)

export default connect(
    mapStateToProps,
    dispatchProps
)(NotesEditorFormWrapperDesktop)
