import {
    IAddCarToGarageState,
    IDirectSharesSuccessPayload_redux_arg,
    IExternalGarageObject,
    IGarage,
    IPrivateShares_Sort_IDS,
    IPublicShare,
    IGarageActiveSort,
    ISharingPermissionIDS,
    ISortShareRecipientsDisplay_change_success,
    ITaskGarageIdsPayloads,
    IUpdateGarageDirectShareSuccess,
} from 'entityModels'

import {
    ACCEPT_GARAGE_SHARE_INVITE_SUCCESS,
    ADD_A_CAR_TO_GARAGE_SUCCESS,
    ADD_SHARE_RECIPIENT_GARAGE_SHARE_SUCCESS,
    CHANGE_FILTER_GARAGE_SHARE_DISPLAY_SUCCESS,
    CHANGE_SORT_GARAGE_SHARE_DISPLAY_SUCCESS,
    CHANGE_SORT_GARAGE_SHARE_RECIPIENTS_DISPLAY_SUCCESS,
    CLEAN_UP_GARAGES_DATA_ON_LOGOUT,
    CREATE_GARAGE_DIRECT_SHARE_SUCCESS,
    CREATE_GARAGE_PUBLIC_SHARE_SUCCESS,
    CREATE_GARAGE_SHARE_INVITE_SUCCESS,
    DELETE_GARAGE_DIRECT_SHARE_SUCCESS,
    DELETE_GARAGE_PUBLIC_SHARE_SUCCESS,
    DELETE_GARAGE_SHARE_INVITE_SUCCESS,
    DELETE_SHARE_RECIPIENT_GARAGE_SUCCESS,
    GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_SUCCESS,
    GET_GARAGE_BY_USER_ID_SUCCESS,
    GET_OWNED_DIRECT_SHARES_GARAGE_SUCCESS,
    GET_PUBLIC_SHARES_GARAGE_SUCCESS,
    GET_SINGLE_GARAGE_DIRECT_SHARE_SUCCESS,
    POPULATE_SINGLE_GARAGE_ALL_OWNED_SHARES_SUCCESS,
    REMOVE_CAR_FROM_USER_GARAGE,
    RESEND_GARAGE_SHARE_INVITE_SUCCESS,
    RESET_SORTED_CARS_IN_GARAGE,
    SET_CURRENT_EXTERNAL_GARAGE_SUCCESS,
    SET_CURRENT_GARAGE_PAGE_NUMBER,
    SET_GARAGE_DATA_SUCCESS,
    SET_GARAGE_TASK_IDS_SUCCESS,
    SET_TOTAL_CARS_NUMBER_FOUND,
    SORT_GARAGE_CARS_SUCCESS,
    UPDATE_GARAGE_DIRECT_SHARE_SUCCESS,
} from './constants'

import { createAction } from '@reduxjs/toolkit'
import {
    IDeleteShareReq,
    IDirectShareSuccessPayloadRedux,
    IEntitySharesOwnedDataArg,
    IShareRecipientRequestDeletion_success,
} from '../../cars/actions/actions'
import { IShareInviteDeletion } from '../../cars/actions/loadingActions'

// export const setGarageDataSuccess = createAction(
//     SET_GARAGE_DATA_SUCCESS,
//     (resolve) => (payload: IGarage) => resolve(payload)
// )

export const setGarageDataSuccess = createAction(
    SET_GARAGE_DATA_SUCCESS,
    function prepare(payload: IGarage) {
        return {
            payload,
        }
    }
)

// export const getGarageByUserIdSuccess = createAction(
//     GET_GARAGE_BY_USER_ID_SUCCESS,
//     (resolve) => (payload: IGarage) => resolve(payload)
// )

export const getGarageByUserIdSuccess = createAction(
    GET_GARAGE_BY_USER_ID_SUCCESS,
    function prepare(payload: IGarage) {
        return {
            payload,
        }
    }
)

// export const setGarageTaskIdsSuccess = createAction(
//     SET_GARAGE_TASK_IDS_SUCCESS,
//     (resolve) => (payload: ITasksNormalisedByGarage) => resolve(payload)
// )

export const setGarageTaskIdsSuccess = createAction(
    SET_GARAGE_TASK_IDS_SUCCESS,
    function prepare(payload: ITaskGarageIdsPayloads) {
        return {
            payload,
        }
    }
)

// export const addACarToGarageSuccess = createAction(
//     ADD_A_CAR_TO_GARAGE_SUCCESS,
//     (resolve) => (payload: IAddCarToGarageState) => resolve(payload)
// )

export const addACarToGarageSuccess = createAction(
    ADD_A_CAR_TO_GARAGE_SUCCESS,
    function prepare(payload: IAddCarToGarageState) {
        return {
            payload,
        }
    }
)

export type IPublicShareSuccessPayloadRedux = {
    id: string
    public_share: IPublicShare
}

export const getPublicSharesGarageSuccess = createAction(
    GET_PUBLIC_SHARES_GARAGE_SUCCESS,
    function prepare(payload: IPublicShareSuccessPayloadRedux) {
        return {
            payload,
        }
    }
)

export const createGaragePublicShareSuccess = createAction(
    CREATE_GARAGE_PUBLIC_SHARE_SUCCESS,
    function prepare(payload: IPublicShareSuccessPayloadRedux) {
        return {
            payload,
        }
    }
)

export const deleteGaragePublicShareSuccess = createAction(
    DELETE_GARAGE_PUBLIC_SHARE_SUCCESS,
    function prepare(payload: IDeleteShareReq) {
        return {
            payload,
        }
    }
)

// GARAGE SHARING DISPLAY

export type ISortGarageSharesDisplay_change_success = {
    garageid: string
    sortID: IPrivateShares_Sort_IDS
    item_ids_display: string[]
}

export const sortGarageSharesDisplay_change_success = createAction(
    CHANGE_SORT_GARAGE_SHARE_DISPLAY_SUCCESS,
    function prepare(payload: ISortGarageSharesDisplay_change_success) {
        return {
            payload,
        }
    }
)

export type IFilterGarageSharesDisplay_change_success = {
    garageid: string
    filterIDs: ISharingPermissionIDS[]
    item_ids_display: string[]
}

export const filterGarageSharesDisplay_change_success = createAction(
    CHANGE_FILTER_GARAGE_SHARE_DISPLAY_SUCCESS,
    function prepare(payload: IFilterGarageSharesDisplay_change_success) {
        return {
            payload,
        }
    }
)

export const getExternalGaragesSharedWithYouSuccess = createAction(
    GET_EXTERNAL_GARAGES_SHARED_WITH_YOU_SUCCESS,
    function prepare(payload: IExternalGarageObject) {
        return {
            payload,
        }
    }
)

export const setCurrentExternalGarageSuccess = createAction(
    SET_CURRENT_EXTERNAL_GARAGE_SUCCESS,
    function prepare(payload: string) {
        return {
            payload,
        }
    }
)

export const populateSingleGarageAllOwnedSharesSuccess = createAction(
    POPULATE_SINGLE_GARAGE_ALL_OWNED_SHARES_SUCCESS,
    function prepare(payload: IEntitySharesOwnedDataArg) {
        return {
            payload,
        }
    }
)

export const getOwnedDirectSharesGarageSuccess = createAction(
    GET_OWNED_DIRECT_SHARES_GARAGE_SUCCESS,
    function prepare(payload: IDirectSharesSuccessPayload_redux_arg) {
        return {
            payload,
        }
    }
)

export const createGarageDirectShareSuccess = createAction(
    CREATE_GARAGE_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IDirectSharesSuccessPayload_redux_arg) {
        return {
            payload,
        }
    }
)

export const updateGarageDirectShareSuccess = createAction(
    UPDATE_GARAGE_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IUpdateGarageDirectShareSuccess) {
        return {
            payload,
        }
    }
)

export const getSingleGarageDirectShareSuccess = createAction(
    GET_SINGLE_GARAGE_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IDirectShareSuccessPayloadRedux) {
        return {
            payload,
        }
    }
)

export const deleteDirectGarageShareSuccess = createAction(
    DELETE_GARAGE_DIRECT_SHARE_SUCCESS,
    function prepare(payload: IDeleteShareReq) {
        return {
            payload,
        }
    }
)

export const addGarageShareRecipientSuccess = createAction(
    ADD_SHARE_RECIPIENT_GARAGE_SHARE_SUCCESS
)

export const deleteGarageShareRecipientSuccess = createAction(
    DELETE_SHARE_RECIPIENT_GARAGE_SUCCESS,
    function prepare(payload: IShareRecipientRequestDeletion_success) {
        return {
            payload,
        }
    }
)

export const sortGarageShareRecipientsDisplay_change_success = createAction(
    CHANGE_SORT_GARAGE_SHARE_RECIPIENTS_DISPLAY_SUCCESS,
    function prepare(payload: ISortShareRecipientsDisplay_change_success) {
        return {
            payload,
        }
    }
)

export const cleanUpGaragesDataOnLogoutSuccess = createAction(
    CLEAN_UP_GARAGES_DATA_ON_LOGOUT
)

export const createGarageShareInviteSuccess = createAction(
    CREATE_GARAGE_SHARE_INVITE_SUCCESS
)

export const deleteGarageShareInviteSuccess = createAction(
    DELETE_GARAGE_SHARE_INVITE_SUCCESS,
    function prepare(payload: IShareInviteDeletion) {
        return {
            payload,
        }
    }
)

export const resendGarageShareInviteSuccess = createAction(
    RESEND_GARAGE_SHARE_INVITE_SUCCESS
)

export const acceptGarageShareInviteSuccess = createAction(
    ACCEPT_GARAGE_SHARE_INVITE_SUCCESS
)

type IremoveCarFromUserGarage_redux_arg = {
    carID: string
    garageID: string
}
export const removeCarFromUserGarage = createAction(
    REMOVE_CAR_FROM_USER_GARAGE,
    function prepare(payload: IremoveCarFromUserGarage_redux_arg) {
        return {
            payload,
        }
    }
)

export const setGarageActiveSortingSuccess = createAction(
    SORT_GARAGE_CARS_SUCCESS,
    function prepare(payload: IGarageActiveSort) {
        return { payload }
    }
)

export const setCurrentGaragePageNumber = createAction(
    SET_CURRENT_GARAGE_PAGE_NUMBER,
    function prepare(payload: number) {
        return { payload }
    }
)

export const setCurrentGarageCarsNumber = createAction(
    SET_CURRENT_GARAGE_PAGE_NUMBER,
    function prepare(payload: number) {
        return { payload }
    }
)

// SET_TOTAL_CARS_NUMBER
export const setTotalCarsNumberFound = createAction(
    SET_TOTAL_CARS_NUMBER_FOUND,
    function prepare(payload: number) {
        return { payload }
    }
)

export const resetSortedCarsInGarage = createAction(
    RESET_SORTED_CARS_IN_GARAGE,
    function prepare(payload: string) {
        return { payload }
    }
)
