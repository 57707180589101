import { IConfirmationFunctionality, IIconData } from 'expandingListPropsTypes'
import * as React from 'react'
import styled from 'styled-components'
import ExpandingListItemMobile from '../../atoms/expandingListItem/expandingListItemMobile'

const Wrap = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin: 10px 0px; */
`

interface IData {
    id: string
    text: string
    answer: string
    links?: string[]
}

interface Props {
    list: IData[]
    width?: string
    theme?: 'upvote' | 'check'
    iconData?: IIconData
    confirmationFunctionality?: IConfirmationFunctionality
}

const ExpandingItemsListMobile: React.SFC<Props> = (props) => (
    <Wrap>
        {props.list.map((item, i) => (
            <React.Fragment key={`${item.id}_${i}_mobile`}>
                <ExpandingListItemMobile
                    key={item.id}
                    id={item.id}
                    text={item.text}
                    answer={item.answer}
                    links={item.links}
                    theme={props.theme && props.theme}
                    iconData={props.iconData ? props.iconData : undefined}
                    confirmationFunctionality={
                        props.confirmationFunctionality
                            ? props.confirmationFunctionality
                            : undefined
                    }
                />
            </React.Fragment>
        ))}
    </Wrap>
)

export default ExpandingItemsListMobile
