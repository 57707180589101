import React from 'react'
import { connect } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { RootState } from 'typesafe-actions'
import Faded from '../../templates/animated/faded'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import {
    IGetTimelineItemByIdPayloadRequest,
    ITimelineDataByCarNormalised,
    ITimelineItem,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'

import { IisOpen, IUser } from 'myModels'

import {
    getTimelineItemByIDRequest,
    updateTimelineEntryRequest,
} from '../../../redux/timeline/actions/requestActions'
import { IDropdownItem, ITechnicalInformationObject } from 'entityModels'
import { setCurrentCar } from '../../../redux/entities/cars/actions/thunks'
import { getCurrentUserDataRequest } from '../../../redux/user/actions/loadingActions'
import { setActiveEntrySuccess } from '../../../redux/timeline/actions/successActions'
import Loader from '../../atoms/loader/loader'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import EntryGalleryPageTopHeaderDesktop from '../../molecules/entryPageTopHeader/entryGalleryPageTopHeaderDesktop'
import { editFormsActions } from '../../../redux/editForms/reducer'
import ModalDisplayAbsolute from '../../templates/displays/pageWrappers/modalDisplayAbsolute'
import WrapperImageFormsMobile from '../../organisms/editForms/imageFormWrapper/wrapperImageFormMobile'
import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import EntryGalleryManagerDesktop from '../../organisms/entryGalleryManager/entryGalleryManagerDesktop'
import EntryGalleryManagerMobile from '../../organisms/entryGalleryManager/entryGalleryManagerMobile'
import PortalInvisibleWrapper from '../../templates/displays/pageWrappers/portalInvisibleWrapper'
import SingleEntryGalleryImage from './singleEntryGalleryImage'
import WrapperImageFormsDesktop from '../../organisms/editForms/imageFormWrapper/wrapperImageFormsDesktop'
import ReturnNav from '../../atoms/header/returnNav'
import EntryOptionsMenuBar from '../../molecules/entryOptionsMenuBar/entryOptionsMenuBar'
import FullPagePageWrapper from '../../templates/displays/pageWrappers/FullPagePageWrapper'
import {
    EntryOptionsMenuWrapper,
    EntryTopSectionWrapper,
} from './carTimelineSingleEntry'
import styled from 'styled-components'

const GalleryDesktopContainer = styled.div`
    width: calc(100% - 296px);
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding-bottom: 48px;
    min-height: 90dvh;
`

interface Props extends RouteComponentProps<any> {
    activeEntry: ITimelineItem | undefined | null
    isActiveEntryLoading: boolean
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) => {}
    updateTimelineEntry: any
    setActiveEntry: any
    deleteTimelineEntry: any
    user_searchable_labels_list: IDropdownItem[] | null | undefined
    userLoggedIn: IUser | null
    getUserDataRequest: () => void
    isUserLoading: boolean
    setCurrentCar: any
    carsTimelineData?: ITimelineDataByCarNormalised | undefined | null
    getAllTimelineItemsByCarIDRequest: any
    setInitialLandingUrl: any
    technicalInformationData?: ITechnicalInformationObject
    isAddGalleryImageToEntryFormOpen: boolean
    toggleAddGalleryImageToEntryForm: (p: IisOpen) => void
    isCollapsed: boolean
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        carsTimelineData: state.timeline.timeline_data_normalised_by_car_id,
        activeEntry: state.timeline.activeEntry,
        isActiveEntryLoading: state.timeline.loading,
        isUserLoading: state.user.loading,
        isAddGalleryImageToEntryFormOpen:
            state.editForms.formsData.isAddGalleryImageToEntryFormOpen.isOpen,
        isCollapsed: state.menus.desktopMainAppMenu.isCollapsed,
    }
}

const dispatchProps = {
    getUserDataRequest: () => getCurrentUserDataRequest(),
    setCurrentCar: (car_id: string) => setCurrentCar(car_id),
    getEntryByIDRequest: (payload: IGetTimelineItemByIdPayloadRequest) =>
        getTimelineItemByIDRequest(payload),
    updateTimelineEntry: (payload: IUpdateTimelineItemPayloadRequest) =>
        updateTimelineEntryRequest(payload),
    setActiveEntry: (payload: ITimelineItem) => setActiveEntrySuccess(payload),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    toggleAddGalleryImageToEntryForm: (p: IisOpen) =>
        editFormsActions.toggleAddGalleryImageToEntryForm(p),
}

type State = {
    isMobileActionsSubMenuOpen: boolean
    bottomsheetTimer: number
    showConfirmDeleteEntrySheet: boolean
    newFile?: File
    progressPercent: number
}

class SingleEntryGallery extends React.PureComponent<Props, State> {
    state = {
        isMobileActionsSubMenuOpen: false,
        bottomsheetTimer: 500,
        showConfirmDeleteEntrySheet: false,
        newFile: undefined,
        progressPercent: 0,
    }

    componentDidMount() {
        let entryid = this.props.match.params.entryid
        let carid = this.props.match.params.carid

        this.props.setInitialLandingUrl(
            `/car/${carid}/history-file/entry?entryid=${entryid}`
        )

        let userLoggedIn = this.props.userLoggedIn
        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        if (userLoggedIn) {
            // get necessary args
            // CAR

            this.props.setCurrentCar(carid)
            // ENTRY
            entryid &&
                this.props.getEntryByIDRequest({
                    entryUID: entryid,
                    car_id: carid,
                })
        }
    }

    componentDidUpdate(prevProps: Props) {
        let entryid = this.props.match.params.entryid
        let carid = this.props.match.params.carid

        if (prevProps.userLoggedIn !== this.props.userLoggedIn) {
            // get necessary args
            // CAR
            this.props.setCurrentCar(carid)
            // ENTRY
            entryid &&
                this.props.getEntryByIDRequest({
                    entryUID: entryid,
                    car_id: carid,
                })
        }

        if (
            this.props.activeEntry &&
            this.props.activeEntry?.labels !== prevProps.activeEntry?.labels
        ) {
            entryid &&
                this.props.getEntryByIDRequest({
                    entryUID: entryid,
                    car_id: carid,
                })
        }
    }

    componentWillUnmount() {}

    render() {
        let {
            activeEntry,
            isActiveEntryLoading,
            isUserLoading,
            isAddGalleryImageToEntryFormOpen,
            toggleAddGalleryImageToEntryForm,
        } = this.props

        let carid: string = this.props.match.params.carid
        let entryid = this.props.match.params.entryid

        const params = new URLSearchParams(this.props.location.search)
        let image_id = params.get('id')

        return (
            <>
                <Loader isLoading={isActiveEntryLoading || isUserLoading} />
                <Faded>
                    {activeEntry && (
                        <>
                            <DesktopDisplayOnly>
                                <FullPagePageWrapper>
                                    <Faded>
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <EntryTopSectionWrapper>
                                                <div
                                                    style={{
                                                        maxWidth: 1200,
                                                        width: '100%',
                                                    }}
                                                >
                                                    <EntryGalleryPageTopHeaderDesktop
                                                        item={activeEntry}
                                                        carID={carid}
                                                        uploadImage={() =>
                                                            toggleAddGalleryImageToEntryForm(
                                                                { isOpen: true }
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </EntryTopSectionWrapper>
                                        </div>
                                        <div style={{ paddingTop: 32 }} />

                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                padding: '0px 40px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    maxWidth: 1200,
                                                    position: 'relative',
                                                    width: '100%',
                                                }}
                                            >
                                                <EntryOptionsMenuWrapper>
                                                    <EntryOptionsMenuBar
                                                        carid={carid}
                                                        activeEntry={
                                                            activeEntry
                                                        }
                                                    />
                                                </EntryOptionsMenuWrapper>
                                                <GalleryDesktopContainer>
                                                    <EntryGalleryManagerDesktop
                                                        ids_list={
                                                            activeEntry.gallery_image_ids
                                                        }
                                                        openImgForm={() =>
                                                            toggleAddGalleryImageToEntryForm(
                                                                {
                                                                    isOpen: true,
                                                                }
                                                            )
                                                        }
                                                    />
                                                </GalleryDesktopContainer>
                                            </div>
                                        </div>
                                        {isAddGalleryImageToEntryFormOpen && (
                                            <Faded>
                                                <ModalDisplayAbsolute
                                                    isOpen={
                                                        isAddGalleryImageToEntryFormOpen
                                                    }
                                                    toggle={() => {}}
                                                >
                                                    <WrapperImageFormsDesktop
                                                        close={() =>
                                                            toggleAddGalleryImageToEntryForm(
                                                                {
                                                                    isOpen: false,
                                                                }
                                                            )
                                                        }
                                                        carid={carid}
                                                        entryid={activeEntry.id}
                                                    />
                                                </ModalDisplayAbsolute>
                                            </Faded>
                                        )}
                                    </Faded>
                                </FullPagePageWrapper>
                            </DesktopDisplayOnly>

                            {!image_id && (
                                <IpadAndMobileDisplay>
                                    <CenteredPageWrapper
                                        fullwidth
                                        removePaddingsOnAllDevices
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                paddingTop: 50,
                                            }}
                                        >
                                            <Link
                                                to={{
                                                    pathname: image_id
                                                        ? `/car/${carid}/history-file/${entryid}/gallery`
                                                        : `/car/${carid}/history-file/entry`,
                                                    search: image_id
                                                        ? ''
                                                        : `?entryid=${entryid}`,
                                                    state: {
                                                        prevPath:
                                                            this.props.history
                                                                .location
                                                                .pathname,
                                                    },
                                                }}
                                            >
                                                <ReturnNav />
                                            </Link>
                                        </div>
                                        <EntryGalleryManagerMobile
                                            ids_list={
                                                activeEntry.gallery_image_ids
                                            }
                                            openImgForm={() =>
                                                toggleAddGalleryImageToEntryForm(
                                                    {
                                                        isOpen: true,
                                                    }
                                                )
                                            }
                                        />
                                        {isAddGalleryImageToEntryFormOpen && (
                                            <Faded>
                                                <ModalDisplay
                                                    isOpen={
                                                        isAddGalleryImageToEntryFormOpen
                                                    }
                                                    toggle={() =>
                                                        toggleAddGalleryImageToEntryForm(
                                                            {
                                                                isOpen: false,
                                                            }
                                                        )
                                                    }
                                                >
                                                    <WrapperImageFormsMobile
                                                        close={() =>
                                                            toggleAddGalleryImageToEntryForm(
                                                                {
                                                                    isOpen: false,
                                                                }
                                                            )
                                                        }
                                                        carid={carid}
                                                        entryid={activeEntry.id}
                                                    />
                                                </ModalDisplay>
                                            </Faded>
                                        )}{' '}
                                    </CenteredPageWrapper>
                                </IpadAndMobileDisplay>
                            )}
                        </>
                    )}
                </Faded>

                {image_id && (
                    <PortalInvisibleWrapper
                        onClick={() => {
                            this.props.history.push(
                                this.props.location.pathname
                            )
                        }}
                    >
                        <SingleEntryGalleryImage />
                    </PortalInvisibleWrapper>
                )}
            </>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(SingleEntryGallery)
)
