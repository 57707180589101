// images gallery
export const GET_GALLERY_IMAGES_REQUEST = '@@gallery_images/get/REQUEST'
export const GET_GALLERY_IMAGES_SUCCESS = '@@gallery_images/get/SUCCESS'
export const GET_GALLERY_IMAGES_ERROR = '@@gallery_images/get/ERROR'

export const SET_GALLERY_IMAGES_REQUEST = '@@gallery_images/set/REQUEST'
export const SET_GALLERY_IMAGES_SUCCESS = '@@gallery_images/set/SUCCESS'
export const SET_GALLERY_IMAGES_ERROR = '@@gallery_images/set/ERROR'

// GET A SPECIFIC IMAGE
export const GET_GALLERY_IMAGE_BY_ID_REQUEST = '@@gallery_images/get/REQUEST'
export const GET_GALLERY_IMAGE_BY_ID_SUCCESS = '@@gallery_images/get/SUCCESS'
export const GET_GALLERY_IMAGE_BY_ID_ERROR = '@@gallery_images/get/ERROR'

// Add TO GALLERY OBJECT
export const ADD_TO_GALLERY_IMAGE_SUCCESS = '@@gallery_images/add/SUCCESS'

// UPDATE A CAR GALLERY IMAGE
export const UPDATE_CAR_GALLERY_IMAGE_REQUEST =
    '@@gallery_images/update/REQUEST'
export const UPDATE_CAR_GALLERY_IMAGE_SUCCESS =
    '@@gallery_images/update/SUCCESS'
export const UPDATE_CAR_GALLERY_IMAGE_ERROR = '@@gallery_images/update/ERROR'

// DELETE
export const DELETE_CAR_GALLERY_IMAGE_REQUEST =
    '@@gallery_images/delete/REQUEST'
export const DELETE_CAR_GALLERY_IMAGE_SUCCESS =
    '@@gallery_images/delete/SUCCESS'
export const DELETE_CAR_GALLERY_IMAGE_ERROR = '@@gallery_images/delete/ERROR'

// UPDATE A ENTRY GALLERY IMAGE
export const UPDATE_ENTRY_GALLERY_IMAGE_REQUEST =
    '@@entry_gallery_images/update/REQUEST'
export const UPDATE_ENTRY_GALLERY_IMAGE_SUCCESS =
    '@@entry_gallery_images/update/SUCCESS'
export const UPDATE_ENTRY_GALLERY_IMAGE_ERROR =
    '@@entry_gallery_images/update/ERROR'

// DELETE
export const DELETE_ENTRY_GALLERY_IMAGE_REQUEST =
    '@@entry_gallery_images/delete/REQUEST'
export const DELETE_ENTRY_GALLERY_IMAGE_SUCCESS =
    '@@entry_gallery_images/delete/SUCCESS'
export const DELETE_ENTRY_GALLERY_IMAGE_ERROR =
    '@@entry_gallery_images/delete/ERROR'

export const CLEAN_UP_GALLERY_SUCCESS = '@@gallery/clean_up/SUCCESS'
