import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const PoundIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '24'}
            height={size ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.243 3.75C12.172 3.75 10.493 5.42893 10.493 7.5C10.493 7.70162 10.4938 7.91632 10.4946 8.14237C10.5013 10.0476 10.5107 12.7595 10.0971 15.2412C9.86534 16.6319 9.49393 17.9986 8.88563 19.1359C8.67245 19.5344 8.42718 19.91 8.14524 20.25H18.743V21.75H5.8859L5.62256 20.2978C6.43122 19.9945 7.06582 19.3578 7.56296 18.4284C8.06359 17.4924 8.39932 16.3038 8.61753 14.9946C9.00871 12.6475 9.00053 10.068 8.99445 8.14975C8.99373 7.92348 8.99304 7.70641 8.99304 7.5C8.99304 4.6005 11.3435 2.25 14.243 2.25C17.1425 2.25 19.493 4.6005 19.493 7.5H17.993C17.993 5.42893 16.3141 3.75 14.243 3.75Z"
                fill={color ?? colours[theme].text_darker}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.6001 12.5356H14.8858V14.0356H4.6001V12.5356Z"
                fill={color ?? colours[theme].text_darker}
            />
        </svg>
    )
}

export default PoundIcon
