import React from 'react'
import styled from 'styled-components'
// import CustomIconTagQuickFilterItemMobile from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
// import { IDropdownItem } from 'entityModels'
import CustomAnimatedCheckboxField from '../../../atoms/formField/customAnimatedCheckboxField'
import CustomIconTagQuickFilterItem from '../../../atoms/tags/customIconTagQuickFilterItem/customIconTagQuickFilterItemMobile'
import SlideDownWrapper from '../../../templates/animated/slideDownWrapper'
import FadedSlower from '../../../templates/animated/FadedSlower'
import FilterButton from '../../../atoms/Button/filterButton'
import Expander from '../../../atoms/expander/expander'
import FullWidthLightGreyBgButton from '../../../atoms/Button/fullWidthLightGreyBg'
import AddLabelButton from '../../../atoms/Button/addLabelButton'
import { IDropdownItem } from 'entityModels'
import { ILabel } from 'myModels'

export type Props = {
    items?: any
    searchFor?: any
    addTagFilter?: any
    addCategoryFilter?: any
    allowAdd?: boolean
    isDisabled?: boolean
    placeholder?: string
    dataCyId?: string | undefined
    manageissearchactive?: any
    issearchactive?: boolean | undefined
    user_searchable_labels_list?: IDropdownItem[] | null | undefined
    value?: any
    handleChange?: any
    categoryData?: any
    fetchItem?: any
    labelsDataRes?: any
    addLabelButton?: React.ReactNode
    onSave?: any
    hasCreateNewLabel?: boolean
    clearField?: any
    entry_labels_list: ILabel[]
    activeValues?: any
}

type IStyle = {
    selected?: boolean
    issearchactive?: boolean
    top?: number
    fixedwidth?: boolean
}

const SearchFieldWrapper = styled.div`
    width: 100%;
    padding-bottom: 10px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
`

const SearchField = styled.input<IStyle>`
    border: ${(props) =>
        props.issearchactive
            ? '1px solid var(--primary, #5ec3ca)'
            : '1px solid var(--text-muted)'};
    border-radius: 5px;
    font-family: Lato;
    font-size: 16px;
    color: var(--text-darker, #616161);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    /* height: ${(props) => (props.issearchactive ? '40px' : '38px')}; */
    height: 48px;
    background-color: var(--bg-color, #fff);
    padding: 12px 16px;

    ::placeholder {
        color: var(--text-muted);
    }
    :active {
        border: 1px solid var(--primary, #5ec3ca);
    }
`

const DropdownOptions = styled.ul`
    position: absolute;
    z-index: 2;
    margin-top: 10px;
    width: 280px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(--bg-color, #fff);
    border-radius: 10px;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 15px;
    box-shadow: 0px 4px 24px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`

const StyledOption = styled.li<IStyle>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: transparent;
    padding-left: 20px;
    cursor: pointer;
    /* font-family: ${(props) => (props.selected ? 'Lato-bold' : 'inherit')}; */
    /* background-color: ${(props) =>
        props.selected ? 'rgba(0, 0, 0, 0.04)' : 'inherit'}; */
    :hover {
        background-color: var(--off-bg-color, #fafafa);
    }
`

const Wrapper = styled.div<IStyle>`
    width: ${(props) => (props.fixedwidth ? 'auto' : '280px')};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const ContentRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
`

const Count = styled.div`
    padding-left: 20px;
    padding-top: 12px;
    font-style: italic;
    color: var(--text-muted);
    font-family: 'Lato-Light';
    font-size: 12px;
`

const SelectedCriteriaWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 0px;
`

type StateSelect = {
    showDropdown: boolean
    addedCriteria: string[]
    issearchactive: boolean
}

class LabelsFilterByMultipleWSearchUIDesktop extends React.Component<
    Props,
    StateSelect
> {
    state = {
        showDropdown: false,
        addedCriteria: this.props.entry_labels_list
            ? this.props.entry_labels_list.map((label: ILabel) => label.name)
            : [],
        issearchactive: false,
    }

    _isMounted = false

    private dropdowRef = React.createRef<HTMLDivElement>()
    private searchFieldRef = React.createRef<HTMLInputElement>()

    handleClickOutsideDropdown(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.setState({ showDropdown: false })
        }
    }

    handleClickOutsideSearch(e: any, ref?: any) {
        if (ref && ref.contains(e.target) === false && this._isMounted) {
            this.setState({ issearchactive: false })
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.dropdowRef.current!.focus()
        let dropdownNode = this.dropdowRef.current
        let searchNode = this.searchFieldRef.current
        document.addEventListener('mousedown', (e: any) => {
            this.handleClickOutsideDropdown(e, dropdownNode)
            this.handleClickOutsideSearch(e, searchNode)
        })
    }

    componentDidUpdate(prevProps: Props) {
        if (
            this.props.entry_labels_list !== prevProps.entry_labels_list &&
            this.props.entry_labels_list
        ) {
            this.setState({
                addedCriteria: this.props.entry_labels_list.map(
                    (label: ILabel) => label.name
                ),
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e: any) => {
            this.handleClickOutsideDropdown(e)
            this.handleClickOutsideSearch(e)
        })
        this._isMounted = false
    }

    render() {
        const {
            dataCyId,
            categoryData,
            labelsDataRes,
            handleChange,
            fetchItem,
            value,
            addLabelButton,
            user_searchable_labels_list,
            onSave,
            hasCreateNewLabel,
            clearField,
        } = this.props

        let allDropdownLabelNames =
            user_searchable_labels_list &&
            user_searchable_labels_list.map(
                (label: IDropdownItem) => label.name
            )
        let dropdownLabelNamesResults =
            labelsDataRes && labelsDataRes.map((label: any) => label.item.name)

        const handleFilterButtonClick = () => {
            if (this.state.showDropdown === true) {
                this.setState({
                    showDropdown: false,
                })
            } else {
                this.setState({
                    showDropdown: true,
                })
            }
        }
        let currentSelectedCriteria: string[] = [...this.state.addedCriteria]

        const addOrRemoveCriteria = (criteria: string) => {
            let indexOfClickedCriteria: number =
                currentSelectedCriteria.indexOf(criteria)
            if (indexOfClickedCriteria < 0) {
                this.setState({
                    addedCriteria: [...this.state.addedCriteria, criteria],
                })
            } else {
                currentSelectedCriteria.splice(indexOfClickedCriteria, 1)
                this.setState({
                    addedCriteria: currentSelectedCriteria,
                })
            }
        }

        let addedCriteria = this.state.addedCriteria.map(
            (criteria: string, i: number) => (
                <div style={{ padding: '3px' }} key={`${criteria} - ${i}`}>
                    <FadedSlower duration={0.2}>
                        <CustomIconTagQuickFilterItem
                            isActive={true}
                            onCrossClick={() => addOrRemoveCriteria(criteria)}
                            border="1px solid var(--border-muted, #e5e5e5)"
                            height="25px"
                            bgcolor="var(--bg-color, #fff)"
                            isLabel
                        >
                            {criteria}
                        </CustomIconTagQuickFilterItem>
                    </FadedSlower>
                </div>
            )
        )

        let mappedCategoryData =
            categoryData &&
            categoryData.map((item: any, index: number) => {
                let category = item.item

                if (category) {
                    return (
                        <StyledOption
                            selected={currentSelectedCriteria.includes(
                                category.name
                            )}
                            value={category.name}
                            key={`${category.name}-category-${index}`}
                            aria-label={category.name}
                        >
                            <ContentRow>
                                <CustomAnimatedCheckboxField
                                    // textchangedisabled
                                    width="100%"
                                    onClick={() =>
                                        addOrRemoveCriteria(category.name)
                                    }
                                    checked={currentSelectedCriteria.includes(
                                        category.name
                                    )}
                                >
                                    {category.name}{' '}
                                </CustomAnimatedCheckboxField>
                            </ContentRow>
                        </StyledOption>
                    )
                } else {
                    return (
                        <StyledOption key={index} style={{ display: 'none' }} />
                    )
                }
            })

        let mappedTagsData =
            labelsDataRes &&
            labelsDataRes.map((item: any, index: number) => {
                let label = item.item
                if (label && index < 3) {
                    return (
                        <StyledOption
                            selected={currentSelectedCriteria.includes(
                                label.name
                            )}
                            value={label.name}
                            key={`${label.name}-tag-${index}`}
                        >
                            <ContentRow>
                                <CustomAnimatedCheckboxField
                                    // textchangedisabled
                                    width="100%"
                                    onClick={() =>
                                        addOrRemoveCriteria(label.name)
                                    }
                                    checked={currentSelectedCriteria.includes(
                                        label.name
                                    )}
                                >
                                    {label.name}
                                </CustomAnimatedCheckboxField>
                            </ContentRow>
                        </StyledOption>
                    )
                } else {
                    return (
                        <StyledOption key={index} style={{ display: 'none' }} />
                    )
                }
            })

        const manageissearchactive = (isActive: boolean) => {
            this.setState({
                ...this.state,
                issearchactive: isActive,
            })
        }

        const checkIfShowAddLabel = () => {
            if (
                !allDropdownLabelNames ||
                (!allDropdownLabelNames.includes(value[0]) &&
                    !dropdownLabelNamesResults.includes(value[0]))
            ) {
                return (
                    <FadedSlower>
                        <FullWidthLightGreyBgButton
                            text={`+ Create new label: ${value[0]}`}
                            onClick={() => {
                                addOrRemoveCriteria(value[0])
                                clearField && clearField()
                            }}
                            nobg
                        />
                    </FadedSlower>
                )
            }
        }

        let show_all_options = () => {
            // later : truncate labels_searchable_list to 2 array, 1 made of first 3 items, the other of the rest
            // on clicking the +N, shows array #2 mapped below already mapped array #1

            return (
                <>
                    {user_searchable_labels_list &&
                        user_searchable_labels_list.map(
                            (label: any, index: number) => {
                                if (index < 6) {
                                    return (
                                        <StyledOption
                                            selected={currentSelectedCriteria.includes(
                                                label.name
                                            )}
                                            value={label.name}
                                            key={`${label.name}-label-${index}`}
                                        >
                                            <ContentRow>
                                                <CustomAnimatedCheckboxField
                                                    // textchangedisabled
                                                    width="100%"
                                                    // iconWidth="20"
                                                    onClick={() =>
                                                        addOrRemoveCriteria(
                                                            label.name
                                                        )
                                                    }
                                                    checked={currentSelectedCriteria.includes(
                                                        label.name
                                                    )}
                                                >
                                                    {label.name}
                                                </CustomAnimatedCheckboxField>
                                            </ContentRow>
                                        </StyledOption>
                                    )
                                } else {
                                    return <div style={{ display: 'none' }} />
                                }
                            }
                        )}

                    {user_searchable_labels_list &&
                        user_searchable_labels_list.length > 6 && (
                            <Count>
                                +{user_searchable_labels_list.length - 6} more
                            </Count>
                        )}
                </>
            )
        }

        const are2arraysEqualv2 = (array1: any[], array2: any[]) => {
            if (array1.length === array2.length) {
                return array1.every((element, index) => {
                    if (element === array2[index].name) {
                        return true
                    }

                    return false
                })
            }

            return false
        }

        return (
            <Wrapper
                data-attr={dataCyId}
                ref={this.dropdowRef}
                fixedwidth={addLabelButton ? true : false}
            >
                {addLabelButton ? (
                    <AddLabelButton
                        onClick={handleFilterButtonClick}
                        isactive={this.state.showDropdown}
                    />
                ) : (
                    <FilterButton
                        text={
                            currentSelectedCriteria.length > 0
                                ? `Labels (${currentSelectedCriteria.length})`
                                : 'Labels (0)'
                        }
                        onClick={handleFilterButtonClick}
                        isactive={this.state.showDropdown}
                    />
                )}

                <SlideDownWrapper
                    isOpen={this.state.showDropdown}
                    initialX={-280}
                    originX={'280px'}
                >
                    <DropdownOptions>
                        <Expander
                            height={
                                this.state.addedCriteria.length > 0 ? 'auto' : 0
                            }
                        >
                            <SelectedCriteriaWrapper>
                                {addedCriteria}
                            </SelectedCriteriaWrapper>
                        </Expander>

                        <SearchFieldWrapper
                            onClick={() =>
                                manageissearchactive &&
                                manageissearchactive(true)
                            }
                        >
                            <SearchField
                                placeholder={'Search labels'}
                                type="search"
                                onChange={(e: any) => {
                                    handleChange(e.target.value)
                                    fetchItem(e.target.value)
                                }}
                                issearchactive={this.state.issearchactive}
                                value={value[0]}
                                ref={this.searchFieldRef}
                            />
                        </SearchFieldWrapper>

                        <FadedSlower>
                            {labelsDataRes.length === 0 && show_all_options()}
                        </FadedSlower>

                        {categoryData &&
                            categoryData.length > 0 &&
                            mappedCategoryData}
                        {labelsDataRes &&
                            labelsDataRes.length > 0 &&
                            mappedTagsData}
                        <div
                            style={{
                                padding: '20px',
                                paddingTop: '10px',
                                paddingBottom: '0px',
                            }}
                        >
                            {hasCreateNewLabel &&
                                value &&
                                value[0] !== '' &&
                                value &&
                                value[0] !== undefined &&
                                value[0].trim().length > 1 &&
                                checkIfShowAddLabel()}

                            {are2arraysEqualv2(
                                currentSelectedCriteria,
                                this.props.entry_labels_list
                            ) !== true && (
                                <FadedSlower>
                                    <FullWidthLightGreyBgButton
                                        text="Save"
                                        onClick={() => {
                                            let submittedLabels: ILabel[] =
                                                currentSelectedCriteria.map(
                                                    (criteria) => {
                                                        let label = {
                                                            uid: criteria,
                                                            name: criteria,
                                                        }
                                                        return label
                                                    }
                                                )
                                            onSave(submittedLabels)
                                            this.setState({
                                                showDropdown: false,
                                            })
                                        }}
                                    />
                                </FadedSlower>
                            )}
                        </div>
                    </DropdownOptions>
                </SlideDownWrapper>
            </Wrapper>
        )
    }
}

export default LabelsFilterByMultipleWSearchUIDesktop
