import * as React from 'react'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import grey_placeholder from '../../../public/assets/placeholder/grey_placeholder.svg'

import {
    IDeleteEntryGalleryImagePayload,
    IGalleryImage,
    IUpdateEntryGalleryImagePayload,
} from 'entityModels'
import './styles.css'
import ActionsMenuDots from '../../atoms/menu/actionsMenu/actionsMenuDots'
import { Controlled as ControlledZoom } from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import SkeletonAbsolute from '../../atoms/skeleton/skeletonAbsolute'
import GallerySlideZoomIcon from '../../atoms/icons/GallerySlideZoomIcon'
import GalleryImChevron from '../../atoms/icons/GalleryImgChevron'
import { LocationIcon } from '../../atoms/icons/components'
import colours from '../../../providers/theme/colours'
import { ThemesContext } from '../../../providers/theme/customThemeProvider'

const SlideWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text-strong, #1a1a1a);
    border-radius: 2px;
    height: 100%;
    max-height: 80vh;

    @media (max-width: 1180px) {
        margin-top: 160px;
        width: 68vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1180px) and (max-width: 1295px) {
        width: 64vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1295px) and (max-width: 1400px) {
        width: 55vw;
        max-width: 1000px;
        height: 75vh;
        max-height: 530px;
    }

    @media (min-width: 1400px) and (max-width: 1550px) {
        width: 54vw;
        max-width: 1100px;
        height: 75vh;
        max-height: 550px;
    }

    @media (min-width: 1550px) and (max-width: 1600px) {
        width: 1200px;
        height: 905px;
    }

    @media (min-width: 1600px) and (max-width: 1810px) {
        width: 1300px;
        height: 1000px;
    }

    @media (min-width: 1810px) {
        width: 1492px;
        height: 1100px;
        max-height: 70vh;
    }
`

const SlideImageBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
    background: var(--bg-color, #fff);
    position: relative;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const SlideImage = styled.img`
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
`

const TextBox = styled.div`
    z-index: 6;
    width: 100%;
    height: max-content;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
`

const Row = styled.div`
    color: var(--text-strong, #1a1a1a);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    font-family: Lato;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }
`

const Col = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    padding-left: 20px;
    min-width: 230px;
    text-align: left !important;
    color: black;
    font-size: 13px;
    @media ${device.smallest_laptops} {
        font-size: 13px;
    }

    @media ${device.large_desktop} {
        font-size: 16px;
    }
`

const CaptionCol = styled.div`
    width: 70%;
    font-family: Lato;
    padding-right: 10px;
    min-width: 230px;
    padding-left: 20px;

    font-size: 12px;

    @media ${device.large_desktop} {
        font-size: 14px;
    }
`

const Line = styled.div`
    height: 68%;
    justify-self: center !important;
    width: 1px;
    background-color: var(--text-muted);
`

const NextBlock = styled(Link)`
    position: absolute;
    right: -130px;

    @media (max-width: 1450px) {
        right: -100px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        right: -100px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    outline: none !important;
    z-index: 1;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`

const PrevBlock = styled(Link)`
    position: absolute;
    left: -120px;

    @media (max-width: 1450px) {
        left: -85px;
    }

    @media (min-width: 1600px) and (max-width: 1650px) {
        left: -94px;
    }
    height: 50px;
    width: 50px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;

    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    outline: none !important;
    user-select: none !important;

    transition: all 100ms;

    :hover {
        transform: scale(1.1);
    }
`

const PaddingSpan = styled.span`
    padding-left: 4px;
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
    @media ${device.smallest_laptops} {
        font-size: 10px;
    }

    @media ${device.large_desktop} {
        font-size: 11px;
    }
`
const AbsoluteIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`
const SpanNoPadding = styled.span`
    color: var(--text-strong, #1a1a1a);
    font-size: 12px;
`

function mapStateToProps(state: RootState) {
    return {}
}

const dispatchProps = {}

interface IProps extends RouteComponentProps<any> {
    // togglePublicMobileMenu: any;
    currentImageData: IGalleryImage
    closeGallery: any
    generateRequestedSlide?: any
    carid: string
    entryid: string
    imagesData: any
    onClickEdit: any
    isSomethingLoading: boolean
    prevUrl: string
    nextUrl: string
    deleteImage: (ids: IDeleteEntryGalleryImagePayload) => void
    updateEntryGalleryImage: (payload: IUpdateEntryGalleryImagePayload) => void
    featuredImagesCount: number
    isExternalCar?: boolean
}

type IState = {
    overallMenuOpenId: string | null
    isLoading: boolean
    imgLoaded?: string
    isZoomed?: boolean
}

class EntryGallerySlide extends React.Component<IProps, IState> {
    state = {
        overallMenuOpenId: null,
        isLoading: true,
        imgLoaded: undefined,
        isZoomed: false,
    }

    _isMounted = false

    genUrl = (e?: any) => {
        if (e) {
            e = e || window.event
            if (e.keyCode === 37 && this.props.prevUrl !== '/none') {
                this.props.history.replace(this.props.prevUrl)
            } else if (e.keyCode === 39 && this.props.nextUrl !== '/none') {
                this.props.history.replace(this.props.nextUrl)
            }
        }
    }

    handleZoomChange = (shouldZoom: boolean) => {
        this.setState({
            ...this.state,
            isZoomed: shouldZoom,
        })
    }

    componentDidMount() {
        this._isMounted = true

        document.addEventListener('keydown', this.genUrl, { passive: true })
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.genUrl)
        this._isMounted = false
    }

    manageOverallMenus = (id?: string) => {
        if (id === this.state.overallMenuOpenId) {
            return this.setState({ overallMenuOpenId: null })
        } else return this.setState({ overallMenuOpenId: id ? id : null })
    }

    render() {
        const { currentImageData, nextUrl, prevUrl, isExternalCar } = this.props

        type Iids = {
            carid: string
            entryid: string
            imageid: string
        }

        const handleDeletion = () => {
            let ids: Iids = {
                carid: this.props.carid,
                entryid: this.props.entryid,
                imageid: this.props.currentImageData.id,
            }

            currentImageData && this.props.deleteImage(ids)
            this.props.closeGallery()

            return
        }

        const handleOptionClickedOn = (optionClicked: string) => {
            if (
                optionClicked === 'set as gallery feature' &&
                this.props.currentImageData &&
                this.props.currentImageData.featured !== true
            ) {
                let payload: IUpdateEntryGalleryImagePayload = {
                    carid: this.props.match.params.carid,
                    entryid: this.props.match.params.entryid,
                    imageid: currentImageData.id,
                    body: {
                        featured: true,
                    },
                }
                this.props.updateEntryGalleryImage(payload)
            } else if (
                optionClicked === 'remove feature' &&
                this.props.currentImageData &&
                this.props.currentImageData.featured === true
            ) {
                let payload: IUpdateEntryGalleryImagePayload = {
                    carid: this.props.match.params.carid,
                    entryid: this.props.match.params.entryid,
                    imageid: currentImageData.id,
                    body: {
                        featured: false,
                    },
                }
                this.props.updateEntryGalleryImage(payload)
            }
            return
        }

        let actions_items = () => {
            if (currentImageData) {
                if (currentImageData.featured === true) {
                    return [
                        {
                            text: 'Update image details',
                            actionOnClick: this.props.onClickEdit,
                            idRequired: false,
                        },

                        {
                            text: 'Delete',
                            actionOnClick: handleDeletion,
                            color: 'red',
                            idRequired: true,
                        },
                        {
                            text: 'Remove feature',
                            actionOnClick: handleOptionClickedOn,
                            idRequired: true,
                            color: 'red',
                        },
                    ]
                } else {
                    return [
                        {
                            text: 'Update image details',
                            actionOnClick: this.props.onClickEdit,
                            idRequired: false,
                        },
                        // {
                        //     text: 'Set as gallery feature',
                        //     actionOnClick: handleOptionClickedOn,
                        //     idRequired: true,
                        //     isDisabled:
                        //         this.props.featuredImagesCount >= 8
                        //             ? true
                        //             : false,
                        // },
                        {
                            text: 'Delete',
                            actionOnClick: handleDeletion,
                            color: 'red',
                            idRequired: true,
                        },
                    ]
                }
            } else return []
        }

        const { overallMenuOpenId, imgLoaded, isLoading } = this.state

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <div style={{ position: 'fixed' }}>
                        <SlideWrapper>
                            <SlideImageBox>
                                <ControlledZoom
                                    isZoomed={this.state.isZoomed}
                                    onZoomChange={this.handleZoomChange}
                                    wrapStyle={{
                                        outline: 'none',
                                        borderRadius: '0px',
                                        width: '100%',
                                        height: ' 100%',
                                    }}
                                    overlayBgColorEnd={
                                        colours[theme].background_neutral_subtle
                                    }
                                >
                                    <AbsoluteIcon
                                        style={{
                                            display: this.state.isZoomed
                                                ? 'none'
                                                : 'block',
                                        }}
                                    >
                                        <GallerySlideZoomIcon />
                                    </AbsoluteIcon>

                                    {isLoading === true && (
                                        <SkeletonAbsolute
                                            isthingloading={isLoading}
                                            darkTheme={false}
                                        />
                                    )}

                                    <div
                                        style={{
                                            width: '100%',
                                            height: ' 100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor:
                                                colours[theme]
                                                    .background_neutral_subtle,
                                        }}
                                    >
                                        <SlideImage
                                            src={
                                                imgLoaded
                                                    ? imgLoaded
                                                    : grey_placeholder
                                            }
                                            srcSet={
                                                currentImageData &&
                                                currentImageData.large_srcset
                                                    ? currentImageData.large_srcset
                                                    : undefined
                                            }
                                            onLoad={() =>
                                                this._isMounted &&
                                                this.setState({
                                                    ...this.state,
                                                    isLoading: false,
                                                })
                                            }
                                            alt="car"
                                        />
                                    </div>
                                </ControlledZoom>

                                {/* this.manageOverallMenus(); */}

                                {this.props.nextUrl !== '/none' ? (
                                    <NextBlock to={nextUrl}>
                                        <GalleryImChevron />
                                    </NextBlock>
                                ) : null}

                                {this.props.prevUrl !== '/none' ? (
                                    <PrevBlock to={prevUrl}>
                                        <GalleryImChevron direction="left" />
                                    </PrevBlock>
                                ) : null}
                            </SlideImageBox>
                            <TextBox>
                                <Row>
                                    <Row>
                                        <Col>
                                            <div
                                                style={{
                                                    paddingBottom: '3px',
                                                    color: colours[theme]
                                                        .text_strong,
                                                    fontSize: '14px',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                {currentImageData &&
                                                currentImageData.uploader
                                                    ? currentImageData.uploader
                                                    : ''}
                                            </div>

                                            <Row>
                                                ©
                                                <PaddingSpan>
                                                    {currentImageData &&
                                                    currentImageData.credits
                                                        ? currentImageData.credits
                                                        : 'Unknown'}
                                                </PaddingSpan>
                                            </Row>

                                            <Row>
                                                <div
                                                    style={{
                                                        transform:
                                                            'translate(-1px, 1px)',
                                                    }}
                                                >
                                                    <LocationIcon size="14" />
                                                </div>
                                                <SpanNoPadding>
                                                    {currentImageData &&
                                                    currentImageData.location
                                                        ? currentImageData.location
                                                        : 'Unknown'}
                                                </SpanNoPadding>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Line />
                                    <CaptionCol style={{ width: '78%' }}>
                                        {currentImageData &&
                                        currentImageData.caption
                                            ? currentImageData.caption
                                            : ''}
                                    </CaptionCol>
                                </Row>

                                {!isExternalCar && (
                                    <div
                                        data-tut="car-gallery-image-tuto-desktop-1"
                                        style={{ paddingLeft: '20px' }}
                                    >
                                        <ActionsMenuDots
                                            menuOpened={overallMenuOpenId}
                                            manageMenus={
                                                this.manageOverallMenus
                                            }
                                            menuId={
                                                currentImageData &&
                                                currentImageData.id
                                                    ? currentImageData.id
                                                    : ''
                                            }
                                            actions_items={actions_items()}
                                        />
                                    </div>
                                )}
                            </TextBox>
                        </SlideWrapper>
                    </div>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(EntryGallerySlide)
)
