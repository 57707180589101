import styled from 'styled-components'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import InputFieldNoFormikAnimated from '../../../atoms/Inputfield/inputFieldNoFormikAnimated'

const Wrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 480px;
    transform: translateY(calc(-100% - 32px));
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    transition: all 200ms;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    box-shadow: 0px 24px 32px 0px var(--box-shadow, rgba(0, 0, 0, 0.08));
`
const Title = styled.div`
    font-size: 24px;
    line-height: 34px;
    font-family: 'Lato-semibold';
    color: var(--text-strong, #1a1a1a);
    padding-bottom: 8px;
`
const Text = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-family: 'Lato';
    color: var(--text-darker, #616161);
`

const ModalHeader = styled.div`
    padding: 24px;
    padding-bottom: 16px;
`
const ModalBody = styled.div`
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    align-self: stretch;
`

const ModalCtas = styled.div`
    display: flex;
    padding: 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    align-self: stretch;
`

const BtnText = styled.p`
    padding: 10px 16px;
    margin: 0;
    text-align: center;
    font-family: Lato-Semibold;
    font-size: 14px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
    color: var(--primary, #5ec3ca);
`

type Props = {
    isOpen: boolean
    toggleModal: (p: boolean) => void
    value?: string
    onChange: (v: string) => void
    onSubmit: () => void
    isSaveDisabled?: boolean
}

const ReferralCodeModalDesktop = (props: Props) => {
    let { isOpen, toggleModal, value, onChange, onSubmit, isSaveDisabled } =
        props

    return (
        <Wrap
            style={{
                opacity: isOpen ? 1 : 0,
                display: isOpen ? undefined : 'none',
            }}
        >
            <ModalHeader>
                <Title>Add referral code</Title>
            </ModalHeader>
            <ModalBody>
                <Text>
                    If a friend or fellow car enthusiast already has insurance
                    with Custodian, they might have shared a referral code with
                    you.
                </Text>
                <InputFieldNoFormikAnimated
                    name={'Enter referral code'}
                    value={value ?? ''}
                    onChange={(e: any) => {
                        typeof e.target.value === 'string' &&
                            onChange(e.target.value.toUpperCase())
                    }}
                    placeholder=""
                    width={'100%'}
                    textTransform={'uppercase'}
                    placeholderStyle={`text-transform: none !important`}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />
            </ModalBody>
            <ModalCtas>
                <StandardCtaBtn
                    onClick={() => toggleModal(false)}
                    boder="1px solid var(--primary, #5ec3ca)"
                    bg={'transparent'}
                    customHeight="auto"
                    removePaddings
                    removeShadowOnHover
                >
                    <BtnText>Cancel</BtnText>
                </StandardCtaBtn>
                <StandardCtaBtn
                    onClick={onSubmit}
                    removePaddings
                    customHeight="auto"
                    boder={
                        isSaveDisabled
                            ? '1px solid var(--border-muted, #e5e5e5)'
                            : '1px solid var(--primary, #5ec3ca)'
                    }
                    isDisabled={isSaveDisabled}
                >
                    <BtnText style={{ color: 'var(--bg-color, #fff)' }}>
                        Apply code
                    </BtnText>
                </StandardCtaBtn>
            </ModalCtas>
        </Wrap>
    )
}

export default ReferralCodeModalDesktop
