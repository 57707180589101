import { RootState } from 'typesafe-actions'
import { IUploadImagesPreSignedUrlsPayload } from 'IapiDataPayload'
import * as React from 'react'
import AddAttachmentEditOrCreateForm from './addAttachmentEditOrCreateFormMobile'
import { connect } from 'react-redux'
import { IEditOrCreateModeSingleFieldsProps } from 'IEditModeDefinitions'
import { IAttachmentRequestPayload } from 'attachmentModels'

// let axios = require('axios')

function mapStateToProps(state: RootState) {
    return {
        presignedurls: state.fileStorage.presignedurls,
    }
}

interface Props {
    data: IEditOrCreateModeSingleFieldsProps
    close?: any
    presignedurls?: IUploadImagesPreSignedUrlsPayload
    user_id?: string
    dataCyId?: string
    entityID: string
    carID: string
    entityType: 'entry' | 'cost'
}

type State = {
    imageUploadedData: any | null
    progressPercent: number // |null
    changeInPresignedURL: boolean
    presignedUrlsState: any
    file: File | undefined
    [key: string]: any
    isSomethingBeingUploaded: boolean
    valueStart: any
}

class WrapperAttachmentFormMobile extends React.Component<Props, State> {
    state = {
        imageUploadedData: null,
        progressPercent: 0, //null
        changeInPresignedURL: false,
        presignedUrlsState: undefined,
        file: undefined,
        isSomethingBeingUploaded: true,
        valueStart: this.props.data.value_start,
    }

    // start faking file uopload percentage
    interval: any = null

    handlePercent = () => {
        this.setState({
            progressPercent: this.state.progressPercent + 10,
        })
    }
    startInterval = () => {
        if (this.interval) {
            clearInterval(this.interval)
        }
        this.interval = setInterval(this.handlePercent, 50)
    }

    // DIRECT SETSTATE

    setImageUploaded = (imgUploadedData: any[]) => {
        // this.setState({ imageUploadedData: imgUploadedData })
    }

    deleteImageUploaded = () => {
        let { data } = this.props
        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                null,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
            this.setState({
                ...this.state,
                valueStart: undefined,
                imageUploadedData: undefined,
                file: undefined,
                progressPercent: 0,
            })
        } else {
            data.onChangeFunc(data.id, null)
            this.setState({
                ...this.state,
                valueStart: undefined,
                imageUploadedData: undefined,
                file: undefined,
                progressPercent: 0,
            })
        }
    }

    // OTHER FUNCS

    handleFilesGivenInWrapperComponent = (file: any) => {
        let { data } = this.props

        let fieldValue: IAttachmentRequestPayload = {
            filename: file.name ? file.name : 'no_file_name_received',
            type: file.type ? file.type : 'no_file_type_received',
            file: file,
        }

        if (data.isCreation) {
            data.onChangeFunc(
                data.id,
                fieldValue,
                data.submitCreationConfigs?.entityType,
                data.submitCreationConfigs?.action
            )
        } else {
            data.onChangeFunc(data.id, fieldValue)
        }

        this.startInterval()
        return this.setState({ file: file })
    }

    render() {
        const { close, entityID, carID, entityType } = this.props

        const {
            // file,
            isSomethingBeingUploaded,
            // isImageCropperShown,
        } = this.state

        if (this.state.progressPercent === 100) {
            clearInterval(this.interval)
        }

        return (
            <div data-attr={this.props.dataCyId} style={{ width: '100%' }}>
                <AddAttachmentEditOrCreateForm
                    close={close}
                    deleteFileUploaded={this.deleteImageUploaded}
                    setFileUploaded={this.handleFilesGivenInWrapperComponent}
                    currentFileUploaded={this.state.file}
                    progressPercent={this.state.progressPercent}
                    isSomethingBeingUploaded={isSomethingBeingUploaded}
                    entityID={entityID}
                    carID={carID}
                    entityType={entityType}
                    initialValue={this.state.valueStart}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, {})(WrapperAttachmentFormMobile)
