import styled from 'styled-components'
import { device } from '../../../../templates/displays/devices'
import { withFormik, FormikProps } from 'formik'
import * as Yup from 'yup'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import InputField from '../../../../atoms/Inputfield/inputField'

import ButtonsStandardBottomRowMobile from '../../buttons/buttonsStandardBottomRowMobile'

import { DatePickerConverterFormat } from '../../../../../redux/conversions/time'
import warning from '../../../../../public/assets/icons/round_warning.svg'
import warning_thick from '../../../../../public/assets/icons/round_warning_thick.svg'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../../molecules/technicalInformation/modal'
import useThemes from '../../../../../providers/theme/hooks'

const Warning = styled.div`
    font-family: Lato-light;
    font-size: 14px;
    color: #ed7a7a;
    font-style: italic;
    max-width: 85%;

    @media ${device.ipad} {
        max-width: none;
        font-family: Lato;
        font-size: 14px;
        color: #f98d8d;
    }
`

const Icon = styled.div`
    margin-right: 7px;
    width: 40px;
    height: 40px;
    background: url(${warning});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    @media ${device.ipad} {
        width: 22px;
        height: 22px;
        background: url(${warning_thick});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`

const AdjustedPadding = styled.div`
    padding-top: 20px;
`

// const CurrentTimezone = styled.div`
//     font-family: Lato-light;
//     font-size: 14px;
//     font-style: italic;
//     padding-top: 10px;
//     color: #616161;
//     @media ${device.ipad} {
//         font-size: 15px;
//         max-width: 420px;
//     }
// `

const SmallPadding = styled.div`
    padding-top: 25px;
`

const LargePadding = styled.div`
    padding-top: 40px;
`

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    single_item_edited_value_datePickerMobile: string
}

interface OtherProps {
    toggle: any
    item: any
    submitFunc: any
    taskid: string
    showReminderResetWarning?: boolean
    minDate?: string
    timezone?: string
}

const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        values,
        toggle,
        item,
        errors,
        minDate,
        setFieldValue,
        // timezone,
        // touched,
        // handleChange,
        // isSubmitting,
        // inputerror,
        // resetErrorState,
    } = props

    let checkIfDisabled = () => {
        if (!values.single_item_edited_value_datePickerMobile) {
            return true
        } else if (
            errors.single_item_edited_value_datePickerMobile !== undefined
        ) {
            return true
        } else return false
    }

    const { theme } = useThemes()
    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>{item.text}</ModalTopBar>
            <ModalInnerContentWrapperMobile>
                {props.showReminderResetWarning === true && (
                    <>
                        <Row>
                            <Icon />
                            <Warning>
                                Changing the due date will cancel all existing
                                reminders for your Task.
                            </Warning>
                        </Row>
                        <AdjustedPadding />
                    </>
                )}

                <SmallPadding />
                <InputField
                    theme={theme}
                    id="single_item_edited_value_datePickerMobile"
                    name="single_item_edited_value_datePickerMobile"
                    placeholder={item.text}
                    customPlaceholderWhenLabelRaised="DD/MM/YYYY"
                    tabindex={1}
                    aria-live="polite"
                    formikprops={props}
                    value={values.single_item_edited_value_datePickerMobile}
                    font_family="Lato"
                    type="date"
                    onChange={(date: string) => {
                        setFieldValue(
                            'single_item_edited_value_datePickerMobile',
                            date
                        )
                    }}
                    onEnterKeyPress={props.handleSubmit}
                    min={minDate}
                    backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                />

                <LargePadding />
                <ButtonsStandardBottomRowMobile
                    cancel={toggle}
                    submit={props.handleSubmit}
                    confirmationtext="save"
                    disabled={checkIfDisabled()}
                />
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const DatePickerTaskMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        single_item_edited_value_datePickerMobile:
            props.item.answer && props.item.answer !== '-'
                ? DatePickerConverterFormat(props.item.answer)
                : '',
    }),
    // validationSchema: {},
    validationSchema: (props: OtherProps) => {
        return Yup.lazy(() => {
            return Yup.object().shape({
                single_item_edited_value_datePickerMobile: Yup.date(),
            })
        })
    },
    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting }) => {
        setSubmitting(false)
        if (values.single_item_edited_value_datePickerMobile) {
            // let res = dayjs(
            //     values.single_item_edited_value_datePickerMobile
            // ).toISOString()

            let res = values.single_item_edited_value_datePickerMobile

            setSubmitting(false)
            props.submitFunc &&
                props.submitFunc({
                    taskid: props.taskid,
                    dataList: [
                        {
                            id: props.item.id,
                            text: props.item.text,
                            answer: res,
                        },
                    ],
                })
            props.toggle()
        }
    },
})(InnerForm)

export default connect(mapStateToProps, dispatchProps)(DatePickerTaskMobile)
