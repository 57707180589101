import { useState, useEffect } from 'react'

export function usePlacesAutocomplete(text = '', debounceTimeout = 400) {
    const [predictions, setPredictions] = useState([])

    const placesGoogleApiAutocomplete = async (text: string) =>
        new Promise((resolve, reject) => {
            if (!text) {
                return reject('Need valid text input')
            }

            try {
                new window.google.maps.places.AutocompleteService().getPlacePredictions(
                    {
                        input: text,
                        types: ['geocode', 'establishment'],
                    },
                    resolve
                )
            } catch (e) {
                reject(e)
            }
        })

    useEffect(() => {
        const handleDebounce = setTimeout(async () => {
            try {
                if (!text) {
                    return
                }
                const nextPredictions: any = await placesGoogleApiAutocomplete(
                    text
                )
                setPredictions(nextPredictions)
            } catch (e) {
                console.error(e)
            }
        }, debounceTimeout)

        return () => {
            clearTimeout(handleDebounce)
        }
    }, [text, debounceTimeout])

    return predictions
}
