import { call, fork, put, select, take } from 'redux-saga/effects'
import * as actions from '../actions'
import * as garage_actions from '../../../entities/garages/actions'
import * as user_actions from '../../../user/actions/actions'
import { api } from '../../../services'
import { push } from 'connected-react-router'
import { removeStoreTasksSuccess } from '../../../tasks/actions/successActions'
import posthog from 'posthog-js'
import { retrieveUserGarageId } from '../../garages/sagas/garage'
import { getGarageByUserIdRequest } from '../../garages/actions/loadingActions'
import { IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { RootState } from 'typesafe-actions'
import { IUser } from 'myModels'

let getUserData = (state: RootState) => state.user.userLoggedIn

function* deleteCarApi(carid: string) {
    try {
        yield api.entities.mutateGarage.deleteACar(carid)
        yield put(actions.actions.deleteCarSuccess(carid))
        yield put(removeStoreTasksSuccess())

        // resetting sorted garage ids
        let userDataInState: IUser = yield select(getUserData)
        let garage_id = userDataInState.owns_garage.uid
        if (garage_id) {
            yield put(garage_actions.actions.resetSortedCarsInGarage(garage_id))
        }

        // if car was archived clean up archived cars array
        const archivedCars: string[] | undefined =
            userDataInState.archived_cars?.car_ids
        if (archivedCars) {
            const cleanedUpArchivedCars = archivedCars.filter(
                (id) => id !== carid
            )
            yield put(user_actions.updateArchivedCars(cleanedUpArchivedCars))
        }

        // we need to keep these calls as they also update all other entities data (cars, tech_info, gallery etc)
        yield put(getGarageByUserIdRequest())
        yield call(retrieveUserGarageId)

        yield put(push(`/garage`))
        // Capture event
        posthog.capture('DELETE CAR')
    } catch (error: any) {
        if (error.status === 401) {
            let payload: IUnauthHandlerPayload = {
                functionToRepeat: deleteCarApi,
                payload: carid,
            }
            yield call(unauthHandlerSaga, payload)
        } else {
            let typedError: IReduxError = ConvertToReduxError(error)
            yield put(actions.errorActions.deleteCarError(typedError))
            return
            // window.location.reload()
        }
    }

    yield put(push(`/garage`))
}

function* watcherDeleteCar() {
    while (true) {
        const { payload } = yield take(actions.loadingActions.deleteCarRequest)
        yield call(deleteCarApi, payload)
    }
}

const car_deletion_sagas: any[] = [fork(watcherDeleteCar)]

export default car_deletion_sagas
