import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
    strokeColor?: string
}

const JourneyPastCarsIcon = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '32'}
            height={size ?? '32'}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.917 12.875C23.917 12.4608 24.2528 12.125 24.667 12.125H26.2569C26.6711 12.125 27.0069 12.4608 27.0069 12.875C27.0069 13.2892 26.6711 13.625 26.2569 13.625H24.667C24.2528 13.625 23.917 13.2892 23.917 12.875Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.1289 15.2387C21.3285 14.6159 17.2798 14.733 14.4353 14.9817L14.3047 13.4874C17.2109 13.2333 21.3981 13.1073 25.3715 13.7585L25.1289 15.2387Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.5 20.8984C24.9142 20.8984 25.25 21.2342 25.25 21.6484V22.2656H26.1774V21.6484C26.1774 21.2342 26.5132 20.8984 26.9274 20.8984C27.3416 20.8984 27.6774 21.2342 27.6774 21.6484V23.0156C27.6774 23.4298 27.3416 23.7656 26.9274 23.7656H24.5C24.0858 23.7656 23.75 23.4298 23.75 23.0156V21.6484C23.75 21.2342 24.0858 20.8984 24.5 20.8984Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.5365 10.3882C23.7492 10.4893 24.0343 10.656 24.1923 10.9352L24.2114 10.9691L25.4335 13.7709C26.3567 13.9854 27.0676 14.4566 27.5867 15.0805C28.1702 15.782 28.4823 16.6396 28.6316 17.4596C28.7814 18.2821 28.7754 19.109 28.6883 19.7955C28.6446 20.1398 28.5791 20.4596 28.4971 20.735C28.4192 20.9968 28.3118 21.2685 28.1606 21.4808C27.9438 21.7853 27.591 21.9229 27.3723 21.994C27.1105 22.0791 26.7977 22.1408 26.4667 22.1882C25.8006 22.2834 24.9539 22.335 24.0588 22.3629C22.7377 22.4042 21.2488 22.3954 19.9881 22.388C19.5404 22.3853 19.1215 22.3829 18.749 22.3829V20.8829C19.1384 20.8829 19.5689 20.8854 20.0243 20.8881C21.2785 20.8955 22.7213 20.904 24.0119 20.8637C24.8916 20.8362 25.672 20.7866 26.2543 20.7033C26.5475 20.6613 26.7643 20.6144 26.9085 20.5675C26.9352 20.5588 26.9566 20.551 26.9736 20.5444C26.976 20.5389 26.9786 20.5332 26.9813 20.5271C27.0052 20.4722 27.0321 20.399 27.0595 20.3069C27.1144 20.1224 27.165 19.8841 27.2002 19.6066C27.2709 19.0496 27.2744 18.3789 27.1559 17.7283C27.037 17.0751 26.8031 16.4841 26.4336 16.0399C26.0768 15.611 25.5659 15.2836 24.8029 15.1786L24.3859 15.1213L22.9179 11.7554C22.9104 11.7516 22.902 11.7475 22.8929 11.7432C22.773 11.6862 22.5608 11.6093 22.2234 11.5334C21.5502 11.382 20.4498 11.25 18.749 11.25V9.75C20.5165 9.75 21.7355 9.88619 22.5526 10.07C22.9602 10.1617 23.2859 10.2692 23.5365 10.3882Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18.751 9.75C18.2853 9.75 17.8583 9.75946 17.4672 9.77667L17.5332 11.2752C17.9005 11.2591 18.3055 11.25 18.751 11.25V9.75ZM17.5094 22.388C17.9583 22.3853 18.3784 22.3829 18.751 22.3829V20.8829C18.3618 20.8829 17.9324 20.8854 17.4782 20.8881C16.5489 20.8936 15.5157 20.8997 14.5105 20.8859L14.4899 22.3858C15.5091 22.3997 16.5721 22.3935 17.5094 22.388Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M28.1891 16.5601C28.279 16.9645 28.024 17.3651 27.6197 17.455C27.4596 17.4905 27.309 17.5278 27.1557 17.5657C26.5442 17.717 25.8879 17.8794 24.4021 17.9909C24.0912 18.0142 24.0227 18.0958 24.0024 18.1225C23.9601 18.178 23.8767 18.3509 23.8914 18.8131C23.9045 19.2271 23.5795 19.5734 23.1655 19.5865C22.7514 19.5996 22.4052 19.2746 22.3921 18.8606C22.3733 18.2658 22.4581 17.6743 22.8093 17.2134C23.1824 16.7237 23.7328 16.5369 24.2899 16.4951C25.6458 16.3934 26.1944 16.2578 26.7882 16.1111C26.9481 16.0716 27.1113 16.0313 27.2943 15.9907C27.6987 15.9008 28.0993 16.1558 28.1891 16.5601Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.22 3.88163C13.615 3.46313 15.0639 3.25 16.5217 3.25C17.512 3.25 18.3152 4.05326 18.3152 5.04348V10.552C18.3152 11.325 17.8203 12.0099 17.0886 12.2538L15.3846 12.8214C15.3846 12.8214 15.3847 12.8214 15.3846 12.8214C15.2664 12.8609 15.1848 12.9731 15.1848 13.1002V26.9565C15.1848 27.9483 14.381 28.75 13.3913 28.75H13.3903C12.6104 28.75 11.8378 28.6096 11.1079 28.336L5.09012 26.08C3.98372 25.6644 3.25 24.6061 3.25 23.4233V8.68313C3.25 7.43104 4.07099 6.32562 5.27182 5.96639C5.27174 5.96641 5.2719 5.96636 5.27182 5.96639L12.22 3.88163ZM12.651 5.31837C12.651 5.31837 12.651 5.31837 12.651 5.31837L5.7025 7.40323C5.1379 7.57207 4.75 8.09252 4.75 8.68313V23.4233C4.75 23.9809 5.0955 24.4795 5.61667 24.6755M5.61667 24.6755L11.6343 26.9314C11.6343 26.9314 11.6344 26.9315 11.6343 26.9314C12.1963 27.142 12.7907 27.25 13.3903 27.25H13.3913C13.5536 27.25 13.6848 27.1189 13.6848 26.9565V13.1002C13.6848 12.3299 14.1771 11.6428 14.9103 11.3984L16.6143 10.8307C16.6143 10.8308 16.6144 10.8307 16.6143 10.8307C16.7341 10.7908 16.8152 10.6785 16.8152 10.552V5.04348C16.8152 4.88169 16.6835 4.75 16.5217 4.75C15.2102 4.75 13.9064 4.94175 12.651 5.31837"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.8145 6.08594C16.8145 5.67172 17.1502 5.33594 17.5645 5.33594H18.6079C20.1752 5.33594 21.4449 6.60564 21.4449 8.17289C21.4449 8.58711 21.1091 8.92289 20.6949 8.92289C20.2807 8.92289 19.9449 8.58711 19.9449 8.17289C19.9449 7.43406 19.3468 6.83594 18.6079 6.83594H17.5645C17.1502 6.83594 16.8145 6.50015 16.8145 6.08594Z"
                fill={color ?? colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.6954 23.0781C21.1097 23.0781 21.4454 23.4139 21.4454 23.8281C21.4454 25.3954 20.1757 26.6651 18.6085 26.6651H14.4346C14.0204 26.6651 13.6846 26.3293 13.6846 25.9151C13.6846 25.5009 14.0204 25.1651 14.4346 25.1651H18.6085C19.3473 25.1651 19.9454 24.567 19.9454 23.8281C19.9454 23.4139 20.2812 23.0781 20.6954 23.0781Z"
                fill={color ?? colours[theme].background_default}
            />
        </svg>
    )
}

export default JourneyPastCarsIcon
