import { createAction } from '@reduxjs/toolkit'
import { IAddEntryAttachmenstApiRequest } from 'ApiInterfaces'
import {
    IDeleteCostAttachmentPayloadReq,
    IDeleteEntryAttachmentPayloadReq,
} from 'ApiInterfaces'

import {
    IActionPayloadTimelineCreationRequest,
    IAddCostItemPayloadRequest,
    IAddTimelineItemPayloadRequest,
    IAddTimelineItemsWithCostPayloadRequest,
    IDeleteCostItemPayloadRequest,
    IDeleteTimelineItemPayloadRequest,
    IFilterTimelineByCategoryNLabelNTitleQCarIDRequest,
    IGetAllUserEntryLabelsRequest,
    IGetCostEntryPayloadRequest,
    IGetTimelineItemByIdPayloadRequest,
    IGetTimelineItemsByCarIDPayloadRequest,
    ISetFilterTimelineByCategoryNLabelNTitleQCarID,
    IUpdateCostItemPayloadRequest,
    IUpdateEntryLabelPayload,
    IUpdateTimelineItemPayloadRequest,
} from 'timelineModels'

import {
    GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_REQUEST,
    FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_REQUEST,
    ADD_TIMELINE_ITEM_TO_CAR_REQUEST,
    UPDATE_TIMELINE_ITEM_TO_CAR_REQUEST,
    DELETE_TIMELINE_ITEM_TO_CAR_REQUEST,
    ADD_COST_TO_TIMELINE_ITEM_REQUEST,
    UPDATE_TIMELINE_ITEM_COST_REQUEST,
    DELETE_TIMELINE_ITEM_COST_REQUEST,
    GET_TIMELINE_ITEM_BY_ID_REQUEST,
    GET_COST_ENTRY_BY_ID_REQUEST,
    GET_ALL_USER_CAR_ENTRY_LABELS_REQUEST,
    SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_REQUEST,
    MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_REQUEST,
    ADD_LABEL_TO_CAR_ENTRY_LABELS_REQUEST,
    REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_REQUEST,
    ADD_ATTACHMENT_TO_ENTRY_REQUEST,
    ADD_ATTACHMENT_TO_COST_REQUEST,
    DELETE_ENTRY_ATTACHMENT_REQUEST,
    DELETE_COST_ATTACHMENT_REQUEST,
    MANAGE_ADD_ATTACHMENTS_TO_ENTRY_REQUEST,
    ADD_TIMELINE_ENTRY_WITH_COST_REQUEST,
    UPDATE_TIMELINE_ENTRY_WITH_COST_REQUEST,
} from './constants'
import { IAddAttachmentToCostSaga } from '../sagas/addAttachmentToCost'

export const getAllTimelineItemsByCarIDRequest = createAction(
    GET_ALL_TIMELINE_ITEMS_BY_CAR_ID_REQUEST,
    function prepare(payload: IGetTimelineItemsByCarIDPayloadRequest) {
        return {
            payload,
        }
    }
)

export const getTimelineItemByIDRequest = createAction(
    GET_TIMELINE_ITEM_BY_ID_REQUEST,
    function prepare(payload: IGetTimelineItemByIdPayloadRequest) {
        return {
            payload,
        }
    }
)

export const filterTimelineItemsByCarCategoryIdsNLabelIdsRequest = createAction(
    FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_REQUEST,
    function prepare(
        payload: IFilterTimelineByCategoryNLabelNTitleQCarIDRequest
    ) {
        return {
            payload,
        }
    }
)

export const addTimelineItemToCarRequest = createAction(
    ADD_TIMELINE_ITEM_TO_CAR_REQUEST,
    function prepare(payload: IAddTimelineItemPayloadRequest) {
        return {
            payload,
        }
    }
)

export const updateTimelineEntryRequest = createAction(
    UPDATE_TIMELINE_ITEM_TO_CAR_REQUEST,
    function prepare(payload: IUpdateTimelineItemPayloadRequest) {
        return {
            payload,
        }
    }
)

export const deleteTimelineEntryRequest = createAction(
    DELETE_TIMELINE_ITEM_TO_CAR_REQUEST,
    function prepare(payload: IDeleteTimelineItemPayloadRequest) {
        return {
            payload,
        }
    }
)

export const getCostEntryByIdRequest = createAction(
    GET_COST_ENTRY_BY_ID_REQUEST,
    function prepare(payload: IGetCostEntryPayloadRequest) {
        return {
            payload,
        }
    }
)

export const addCostToTimelineItemRequest = createAction(
    ADD_COST_TO_TIMELINE_ITEM_REQUEST,
    function prepare(payload: IAddCostItemPayloadRequest) {
        return {
            payload,
        }
    }
)

export const updateTimelineItemCostRequest = createAction(
    UPDATE_TIMELINE_ITEM_COST_REQUEST,
    function prepare(payload: IUpdateCostItemPayloadRequest) {
        return {
            payload,
        }
    }
)

export const deleteTimelineItemCostRequest = createAction(
    DELETE_TIMELINE_ITEM_COST_REQUEST,
    function prepare(payload: IDeleteCostItemPayloadRequest) {
        return {
            payload,
        }
    }
)

export const getAllUserCarEntryLabelsRequest = createAction(
    GET_ALL_USER_CAR_ENTRY_LABELS_REQUEST,
    function prepare(payload: IGetAllUserEntryLabelsRequest) {
        return {
            payload,
        }
    }
)

export const setFilterTimelineItemsByCarIdCategoryIdsNLabelIdsNTitleQRequest =
    createAction(
        SET_FILTER_TIMELINE_ITEMS_BY_CAR_ID_CATEGORY_TAG_REQUEST,
        function prepare(
            payload: ISetFilterTimelineByCategoryNLabelNTitleQCarID
        ) {
            return {
                payload,
            }
        }
    )

export const manageDataToBeSubmittedTimelineCreationRequest = createAction(
    MANAGE_DATA_TO_BE_SUBMITTED_TIMELINE_CREATION_REQUEST,
    function prepare(payload: IActionPayloadTimelineCreationRequest) {
        return {
            payload,
        }
    }
)

export const addLabelToCarEntryRequest = createAction(
    ADD_LABEL_TO_CAR_ENTRY_LABELS_REQUEST,
    function prepare(payload: IUpdateEntryLabelPayload) {
        return {
            payload,
        }
    }
)

export const removeLabelFromCarEntryRequest = createAction(
    REMOVE_LABEL_FROM_CAR_ENTRY_LABELS_REQUEST,
    function prepare(payload: IUpdateEntryLabelPayload) {
        return {
            payload,
        }
    }
)

export const addAttachmentsToEntryRequest = createAction(
    ADD_ATTACHMENT_TO_ENTRY_REQUEST,
    function prepare(payload: IAddEntryAttachmenstApiRequest) {
        return {
            payload,
        }
    }
)

export type IManageAddAttachmentsToEntryRequest = {
    files: File[]
    carID: string
    entryID: string
}

export const manageAddAttachmentsToEntryRequest = createAction(
    MANAGE_ADD_ATTACHMENTS_TO_ENTRY_REQUEST,
    function prepare(payload: IManageAddAttachmentsToEntryRequest) {
        return {
            payload,
        }
    }
)

export const addAttachmentToCostRequest = createAction(
    ADD_ATTACHMENT_TO_COST_REQUEST,
    function prepare(payload: IAddAttachmentToCostSaga) {
        return {
            payload,
        }
    }
)

export const deleteEntryAttachmentRequest = createAction(
    DELETE_ENTRY_ATTACHMENT_REQUEST,
    function prepare(payload: IDeleteEntryAttachmentPayloadReq) {
        return {
            payload,
        }
    }
)

export const deleteCostAttachmentRequest = createAction(
    DELETE_COST_ATTACHMENT_REQUEST,
    function prepare(payload: IDeleteCostAttachmentPayloadReq) {
        return {
            payload,
        }
    }
)

export const addTimelineItemWithCostsToCarRequest = createAction(
    ADD_TIMELINE_ENTRY_WITH_COST_REQUEST,
    function prepare(payload: IAddTimelineItemsWithCostPayloadRequest) {
        return {
            payload,
        }
    }
)

export const updateTimelineItemWithCostRequest = createAction(
    UPDATE_TIMELINE_ENTRY_WITH_COST_REQUEST,
    function prepare(payload: IUpdateTimelineItemPayloadRequest) {
        return {
            payload,
        }
    }
)
